<template>
    <div>
        <ProjectTeamMaster :ermMasterInfo="ermMasterInfo"></ProjectTeamMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectTeamMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ProjectTeamMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Project Team Master",
                entity: 'project_team_master',
                refEntity: 'project_team',
                ermListRedirectURL: '/prtmas/list',
                erdRedirectURL: '/projectteam/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>