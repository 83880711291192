<template>
    <div>
        <!-- User Task Page (All task for a specific User) -->
        <UserTaskComponent class="top-margin" />
    </div>
</template>
<script>
import UserTaskComponent from '@/components/common/UserTaskComponent.vue';
export default {
    components: {
        UserTaskComponent
    },
}
</script>
<style>
	.top-margin {
		position: relative;
		margin: 80px 0;
	}
</style>
