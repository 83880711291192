<template>
    <div class="fixed-inner-header">
        <b-overlay :show="showMailLoading" opacity="0.4">
            <div class="container-fluid">
                <div class="row inner-header">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Jobs</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <b-overlay :show="showOverlay" opacity="0.4">
                                    <div id="scope-app" class="mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                    <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Job</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                <b-card-body class="">
                                                    <div class="row mb-2">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="job">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.job.title.$error }">
                                                                    <input type="text" class="form-control" v-model="job.title" id="job" :class="{ 'is-invalid': $v.job.title.$error }" placeholder="Enter Job">
                                                                    <div class="required-icon"></div>
                                                                </div>  
                                                                <label v-if="$v.job.title.$error && !$v.job.title.minLength" class="text-danger">Job title must have at least {{$v.job.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.job.title.$error && !$v.job.title.required" class="text-danger">Please enter job title</label>
                                                                <label v-if="$v.job.title.$error && !$v.job.title.maxLength" class="text-danger">job title must not exceed {{$v.job.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="jobCode">Job Code</label>
                                                                <input type="text" v-model="job.jobCode" maxlength="100" class="form-control" id="jobCode" placeholder="Enter Job Code">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="vacancies">Vacancies</label>
                                                                <input type="number" v-model="job.vacancies" min="0" class="form-control" id="vacancies" placeholder="Number of Vacancies">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="position">Position</label>
                                                                <input type="text" v-model="job.position" maxlength="100" class="form-control" id="position" placeholder="Enter Position">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="qualification">Qualification</label>
                                                                <input type="text" v-model="job.qualification" maxlength="100" class="form-control" id="qualification" placeholder="Enter Qualification">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label>Required Experience</label>
                                                                <b-input-group size="sm" class="mb-2">
                                                                    <DayMonthYearControl 
                                                                        :dayVisible="false"
                                                                        @vmx-value-input="vmxValueInput" 
                                                                        :monthValue="job.experienceMonths"
                                                                        :yearValue="job.experienceYears"
                                                                        ref="dayMonthYearControl" />
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="location">Job Location</label>
                                                                <input type="text" v-model="job.location" maxlength="100" class="form-control" id="location" placeholder="Enter Job Location">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="dueDate">Due Date</label>
                                                                <input v-model="job.dueDate" type="date" id="dueDate" class="form-control" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <select v-model="job.statusId" class="form-control" id="statusId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in jobStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="budget">Active</label>
                                                                <div class="input-group ">
                                                                    <b-form-checkbox class="custom-toggle" v-model="job.active" name="check-button" switch>
                                                                    </b-form-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group pr-3">
                                                                <label for="documentsRequired">Required Documents</label>
                                                                <multiselect v-model="job.documentsRequired" :options="documentTypes" name="documentsRequired" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                            </div>
                                                            <div class="col-12">
                                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="budget">Description</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="job.description" ></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveJob" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                    <template #overlay>
                                        <div class="text-center">
                                            <LoadingSpinner />
                                        </div>
                                    </template>
                                </b-overlay>
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table ref="jobTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="jobList"  @row-selected="onRowSelected" :fields="jobFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(experienceYears)="data">
                                        <span v-if="data.value">{{ data.value }} years </span>
                                        <span v-if="data.item.experienceMonths">{{ data.item.experienceMonths }} months</span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Post in Facebook" class="" @click="generateFbPostContent(data.item)"><i class="fa fa-facebook-square"></i></a>
                                        <a href="javascript:" title="Email Job" @click="getMailContent(data.item)"><i class="fa fa-envelope pl-2"></i></a>
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteJob(data.item.id)" v-b-modal.deleteJob><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Jobs found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>

                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                                <b-pagination v-if="jobList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                        <div class="card h-100">
                            <div class="card-body p-3">
                                <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                    <b-tabs v-show="!elements.visible && elements.showDetail" v-model="rightTabIndex" content-class="mt-3">
                                        <b-tab title="Details">
                                            <div class="row input-group-label">
                                                <div class="col-5">
                                                    <b-input-group prepend="Id" class="text mb-3">
                                                        <b-input-group-prepend is-text><b>{{jobDetail.jobId}}</b></b-input-group-prepend>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                    <div class="more-btns text-center">
                                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <div v-if="elements.editMode">
                                                            <button type="submit" @click="updateJob" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="title1">Job</label>
                                                        <input type="text" class="form-control" id="title1" :disabled="!elements.editMode" v-model="jobDetail.title">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="jobCodeDet">Job Code</label>
                                                        <input type="text" class="form-control" id="jobCodeDet" :disabled="!elements.editMode" v-model="jobDetail.jobCode">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="vacanciesDet">Vacancies</label>
                                                        <input type="text" class="form-control" id="vacanciesDet" :disabled="!elements.editMode" v-model="jobDetail.vacancies">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="positionDet">Position</label>
                                                        <input type="text" class="form-control" id="positionDet" :disabled="!elements.editMode" v-model="jobDetail.position">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="qualificationDet">Qualification</label>
                                                        <input type="text" class="form-control" id="qualificationDet" :disabled="!elements.editMode" v-model="jobDetail.qualification">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Required Experience</label>
                                                        <b-input-group size="sm" class="mb-2">
                                                            <DayMonthYearControl 
                                                                :dayVisible="false"
                                                                @vmx-value-input="vmxValueInputDet" 
                                                                :monthValue="jobDetail.experienceMonths"
                                                                :yearValue="jobDetail.experienceYears"
                                                                ref="dayMonthYearControlDet"
                                                                :yearDisabled="!elements.editMode"
                                                                :monthDisabled="!elements.editMode" />
                                                        </b-input-group>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="location">Job Location</label>
                                                        <input type="text" v-model="jobDetail.location" maxlength="100" class="form-control" id="location" :disabled="!elements.editMode" placeholder="Enter Job Location">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="statusId">Status</label>
                                                        <select v-model="jobDetail.statusId" class="form-control" :disabled="!elements.editMode" id="statusId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in jobStatus" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="dueDate">Due Date</label>
                                                        <input v-model="jobDetail.dueDate" type="date" id="dueDate" class="form-control" :disabled="!elements.editMode" >
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Active</label>
                                                        <b-form-checkbox class="custom-toggle" v-model="jobDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group pr-3">
                                                        <label for="documentsRequiredDet">Required Documents</label>
                                                        <multiselect v-model="jobDetail.documentsRequired" :options="documentTypes" name="documentsRequiredDet" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" :disabled="!elements.editMode" ></multiselect>
                                                    </div>
                                                    <div class="col-12">
                                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.description2>
                                                                    <label>Description</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="description2" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="job">Description</label>
                                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="jobDetail.description" :disabled="!elements.editMode"></editor>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                    <label>More Details</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="createdBy">Created By</label>
                                                                                <input type="text" class="form-control disabled" id="createdBy" v-model="jobDetail.createdBy" readonly>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label for="startDate">Created Date</label>
                                                                                <div class="input-group" >
                                                                                    <input type="text" class="form-control disabled" :value="jobDetail.createdDt | formatDate" readonly>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label for="endDate">Last Updated Date</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control disabled" :value="jobDetail.lastUpdatedDt | formatDate" readonly>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Roles & Resp.">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accord-roleResponsibility>
                                                                        <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Roles & Responsibilities</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accord-roleResponsibility" v-model="elements.roleVisible" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12 mb-3">
                                                                            <label for="role">Role</label>
                                                                            <a href="javascript:" class="float-right button-add" @click="elements.showDropdownRole=!elements.showDropdownRole;">
                                                                                <i v-if="!elements.showDropdownRole" title="Existing Roles" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                                <i v-if="elements.showDropdownRole" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                                            </a>
                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibilityDetail.role.$error }">
                                                                                <select v-if="elements.showDropdownRole" @change="getResponsibilities(roleResponsibilityDetail.roleData.id)" v-model="roleResponsibilityDetail.roleData" class="form-control">
                                                                                    <option value="">Select</option>
                                                                                    <option v-for="(info, index) in roles" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                                </select>
                                                                                <input v-if="!elements.showDropdownRole" type="text" class="form-control" id="role" v-model="roleResponsibilityDetail.role" placeholder="Enter New Roles">
                                                                                <div class="required-icon"></div>
                                                                            </div>
                                                                            <label v-if="$v.roleResponsibilityDetail.role.$error && !$v.roleResponsibilityDetail.role.required" class="text-danger">Please Enter Role</label>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="responsibility">Responsibility</label>
                                                                                <b-form-tags input-id="tags-separators" v-model="roleResponsibilityDetail.responsibilityArray" class="form-control" separator="," placeholder="Enter Responsibilities"></b-form-tags>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" @click="clearRoleResponsibility" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.roleEditMode ? 'Cancel' : 'Clear'}}</button>
                                                                    <button type="submit" @click="saveRoleResponsibility()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>{{ elements.roleEditMode ? 'Update' : 'Add'}}</button>
                                                                    <div class="clearfix"></div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <div class="card mt-1" v-for="responsibility in responsibilities">
                                                    <div class="card-body p-2" v-if="responsibility.id !== roleResponsibilityDetail.editId">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <a href="javascript:" class="float-right button-add" title="Delete Role" @click="deleteRoleResponsibility(responsibility.id)" v-b-modal.deleteRoleResponsibility><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                    <a href="javascript:" class="float-right button-add" title="Edit Role" @click="editRole(responsibility)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                    <h6>{{ responsibility.role }}</h6>
                                                                    <p> {{ responsibility.responsibility }} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="Tech & Skills">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accord-techSkill>
                                                                        <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Technology & Skills</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accord-techSkill" v-model="elements.techVisible" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12 mb-3">
                                                                            <label for="technology">Technology</label>
                                                                            <a href="javascript:" class="float-right button-add" @click="elements.showDropdownTech=!elements.showDropdownTech;">
                                                                                <i v-if="!elements.showDropdownTech" title="Existing Technologies" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                                <i v-if="elements.showDropdownTech" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                                            </a>
                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkillDetail.technology.$error }">
                                                                                <select v-if="elements.showDropdownTech" @change="getTechnologies(technologySkillDetail.technologyData.id)" v-model="technologySkillDetail.technologyData" class="form-control">
                                                                                    <option value="">Select</option>
                                                                                    <option v-for="(info, index) in technologies" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                                                </select>
                                                                                <input v-if="!elements.showDropdownTech" type="text" class="form-control" id="technology" v-model="technologySkillDetail.technology" placeholder="Enter New Technologies">
                                                                                <div class="required-icon"></div>
                                                                            </div>
                                                                            <label v-if="$v.technologySkillDetail.technology.$error && !$v.technologySkillDetail.technology.required" class="text-danger">Please Enter Technology</label>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="skills">Skills</label>
                                                                                <b-form-tags input-id="tags-separators" v-model="technologySkillDetail.skillsArray" separator="," class="form-control" placeholder="Enter skills"></b-form-tags>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" @click="clearTechnologySkill" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.techEditMode ? 'Cancel' : 'Clear'}}</button>
                                                                    <button type="submit" @click="saveTechnologySkill()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Add</button>
                                                                    <div class="clearfix"></div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <div class="card mt-1" v-for="technologySkill in technologySkills">
                                                    <div class="card-body p-2" v-if="technologySkill.id !== technologySkillDetail.editId">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <a href="javascript:" class="float-right button-add" title="Delete Technology" @click="deleteTechnologySkill(technologySkill.id)" v-b-modal.deleteTechnologySkill ><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                    <a href="javascript:" class="float-right button-add" title="Edit Technology" @click="editTech(technologySkill)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                    <h6>{{ technologySkill.technology }}</h6>
                                                                    <p>{{ technologySkill.skills }} </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                    <p class="font-weight-bold fs-18 mb-0">
                        <label class="m-0 pl-2">Generating Job Link, please wait . . .</label>
                    </p>
                </div>
            </template>
        </b-overlay>
        <SendMailPopup ref="sendMailPopupRef" id="jobShareMailPopup" :sendMailTitle="sendMailTitle" />
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteJob" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Job?" />
        <DeleteComponent id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import SendMailPopup from '@/components/popup/action/SendMailPopup.vue'
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        Editor,
        Multiselect,
        SendMailPopup,
        TypeLookup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false,
                roleEditMode: false,
                showDropdownRole: false,
                roleVisible: false,
                techEditMode: false,
                showDropdownTech: false,
                techVisible: false,
            },
            rightTabIndex: 0,
            showMailLoading: false,
            showOverlay: false,
            showDetOverlay: false,
            isBusy: false,

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'job',
            typeLookupTitle: 'Required Document Settings',
            typeEntity: 'document_type',
            sendMailTitle: 'Job Share Mail',

            job: {
                id: '',
                jobId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                vacancies: '',
                title: '',
                jobCode: '',
                position: '',
                qualification: '',
                description: '',
                experienceMonths: '',
                experienceYears: '',
                location: '',
                documentsRequired: [],
                dueDate: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            jobDetail: {
                id: '',
                jobId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                vacancies: '',
                title: '',
                jobCode: '',
                position: '',
                qualification: '',
                description: '',
                experienceMonths: '',
                experienceYears: '',
                location: '',
                documentsRequired: [],
                dueDate: '',
                statusId: '',
                status: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: 'job',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''
            },

            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'technology_skill',
                refEntity: 'job',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technology: '',
                technologyData: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },

            jobStatus: [],
            documentTypes: [],
            roles: [],
            responsibilities: [],
            technologies: [],
            technologySkills: [],

            jobList: [],
            jobFields: [
                {key: 'index',label: 'SN'},
                { key: 'jobCode', label: 'Job Code', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' },
                { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getJobs();
        this.lookupData();
    },
    validations: {
        job: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        jobDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {
            technology: {
                required
            }
        },
    },
    methods: {
        getJobs: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/job/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.jobList && result.jobList.length > 0) {
                        this.jobList = result.jobList;
                        this.$nextTick(() => {
                            this.$refs.jobTable.selectRow(0);
                        })
                        this.totalRows = this.jobList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
                typeEntity: this.typeEntity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/job/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.roles = result.roleLookup;
                    this.technologies = result.technologyLookup;
                    this.jobStatus = result.statusLookup;
                    this.documentTypes = result.customTypeLookup;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveJob: function() {
            this.job.experienceMonths = this.$refs.dayMonthYearControl.getMonths();
            this.job.experienceYears = this.$refs.dayMonthYearControl.getYears();
            this.$v.job.$touch();
            if (!this.$v.job.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/job/add', this.job)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getJobs();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateJob: function() {
            this.jobDetail.experienceMonths = this.$refs.dayMonthYearControlDet.getMonths();
            this.jobDetail.experienceYears = this.$refs.dayMonthYearControlDet.getYears();
            this.$v.jobDetail.$touch();
            if (!this.$v.jobDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/job/update', this.jobDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getJobs();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewJob: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/job/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let jobDetail = result.job;
                    if (jobDetail) {                        
                        this.jobDetail.id = jobDetail.id;
                        this.jobDetail.jobId = jobDetail.jobId;
                        this.jobDetail.vhextAccountId = jobDetail.vhextAccountId;
                        this.jobDetail.vacancies = jobDetail.vacancies;
                        this.jobDetail.title = jobDetail.title;
                        this.jobDetail.jobCode = jobDetail.jobCode;
                        this.jobDetail.position = jobDetail.position;
                        this.jobDetail.qualification = jobDetail.qualification;
                        this.jobDetail.description = jobDetail.description;
                        this.jobDetail.experienceMonths = jobDetail.experienceMonths;
                        this.jobDetail.experienceYears = jobDetail.experienceYears;
                        this.jobDetail.documentsRequired = JSON.parse(jobDetail.documentsRequired);
                        this.jobDetail.location = jobDetail.location;
                        this.jobDetail.dueDate = jobDetail.dueDate;
                        this.jobDetail.statusId = jobDetail.statusId;
                        this.jobDetail.status = jobDetail.status;
                        this.jobDetail.active = jobDetail.active;
                        this.jobDetail.createdById = jobDetail.createdById;
                        this.jobDetail.createdBy = jobDetail.createdBy;
                        this.jobDetail.createdDt = jobDetail.createdDt;
                        this.jobDetail.lastUpdatedDt = jobDetail.lastUpdatedDt;
                        this.$refs.dayMonthYearControlDet.setValueInputData(jobDetail.experienceYears, jobDetail.experienceMonths, null);
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewJob(this.jobDetail.id);
        },
        deleteJob: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/job/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getJobs();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        getRoleResponsibilities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: 'job',
                refRecordId: this.jobDetail.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result) {
                        this.responsibilities = result.cmrRoleResponsibilityList;
                    }
                })
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray = roleResponsibility.responsibility && roleResponsibility.responsibility !== null ? this.stringToArray(roleResponsibility.responsibility) : [];
                    })
            }
        },
        saveRoleResponsibility: function() {
            if (this.elements.showDropdownRole) {

                if (this.roleResponsibilityDetail.roleData.text != null) {
                    this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
                    this.validateRoleResponsibility();
                } else {
                    this.roleResponsibilityDetail.role = '';
                    this.validateRoleResponsibility();
                }
            } else {
                this.validateRoleResponsibility();
            }

        },
        validateRoleResponsibility: function() {
            this.$v.roleResponsibilityDetail.$touch();
            if (!this.$v.roleResponsibilityDetail.$invalid) {
                if (this.elements.roleEditMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.addRoleResponsibility();
                }
            }
        },
        addRoleResponsibility: function() {
            this.roleResponsibilityDetail.refRecordId = this.jobDetail.id;
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateRoleResponsibility: function() {
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        editRole: function(data) {
            this.elements.showDropdownRole = false;
            this.elements.roleEditMode = true;
            this.elements.roleVisible = true;
            this.roleResponsibilityDetail.id = data.id;
            this.roleResponsibilityDetail.editId = data.id;
            this.roleResponsibilityDetail.role = data.role;
            this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
            this.roleResponsibilityDetail.refRecordId = data.refRecordId;
            this.roleResponsibilityDetail.refEntityId = data.refEntityId;
            this.roleResponsibilityDetail.projectId = data.projectId;
            this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
            this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibilityDetail.preparedById = data.preparedById;
        },
        clearRoleResponsibility: function() {
            this.elements.roleEditMode = false;
            this.elements.roleVisible = false;
            this.roleResponsibilityDetail.editId = '';
            this.roleResponsibilityDetail.role = '';

            if (this.roleResponsibilityDetail.roleData) {
                this.roleResponsibilityDetail.roleData = '';
            }
            this.roleResponsibilityDetail.responsibility = '';
            this.roleResponsibilityDetail.responsibilityArray = [];
            this.$v.roleResponsibilityDetail.$reset();
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYesRole: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: 'job',
                refRecordId: this.jobDetail.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = technologySkill.skills && technologySkill.skills !== null ? this.stringToArray(technologySkill.skills) : [];
                    })
            }
        },
        saveTechnologySkill: function() {
            if (this.elements.showDropdownTech) {
                if (this.technologySkillDetail.technologyData.text != null) {
                    this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
                    this.validateTechnologySkill();
                } else {
                    this.technologySkillDetail.technology = '';
                    this.validateTechnologySkill();
                }
            } else {
                this.validateTechnologySkill();
            }
        },
        validateTechnologySkill: function() {
            this.$v.technologySkillDetail.$touch();
            if (!this.$v.technologySkillDetail.$invalid) {
                if (this.elements.techEditMode) {
                    this.updateTechnologySkill();
                } else {
                    this.addTechnologySkill();
                }
            }
        },
        addTechnologySkill: function() {
            this.technologySkillDetail.refRecordId = this.jobDetail.id;
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateTechnologySkill: function() {
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYesTech: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editTech: function(data) {
            this.elements.showDropdownTech = false;
            this.elements.techEditMode = true;
            this.elements.techVisible = true;
            this.technologySkillDetail.id = data.id;
            this.technologySkillDetail.editId = data.id;
            this.technologySkillDetail.technologySkillId = data.technologySkillId;
            this.technologySkillDetail.technology = data.technology;
            this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
            this.technologySkillDetail.refRecordId = data.refRecordId;
            this.technologySkillDetail.refEntityId = data.refEntityId;
            this.technologySkillDetail.projectId = data.projectId;
            this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
            this.technologySkillDetail.preparedById = data.preparedById;
        },
        clearTechnologySkill: function() {
            this.elements.techEditMode = false;
            this.elements.techVisible = false;
            this.technologySkillDetail.editId = '';
            this.technologySkillDetail.technology = '';
            if (this.technologySkillDetail.technologyData.id) {
                this.technologySkillDetail.technologyData = '';
            }
            this.technologySkillDetail.skills = '';
            this.technologySkillDetail.skillsArray = [];
            this.$v.technologySkillDetail.$reset();
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.job.id = '';
            this.job.jobId = '';
            this.job.vacancies = '';
            this.job.title = '';
            this.job.jobCode = '';
            this.job.position = '';
            this.job.qualification = '';
            this.job.description = '';
            this.job.experienceMonths = '';
            this.job.experienceYears = '';
            this.job.location = '';
            this.job.documentsRequired = [];
            this.job.dueDate = '';
            this.job.statusId = '';
            this.job.active = true;
            this.$refs.dayMonthYearControl.clearValueInputData();
            this.$v.job.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.rightTabIndex = 0;
            this.jobDetail.id = data.id;
            this.jobDetail.jobId = data.jobId;
            this.jobDetail.vhextAccountId = data.vhextAccountId;
            this.jobDetail.vacancies = data.vacancies;
            this.jobDetail.title = data.title;
            this.jobDetail.jobCode = data.jobCode;
            this.jobDetail.position = data.position;
            this.jobDetail.qualification = data.qualification;
            this.jobDetail.description = data.description;
            this.jobDetail.experienceMonths = data.experienceMonths;
            this.jobDetail.experienceYears = data.experienceYears;
            this.jobDetail.location = data.location;
            this.jobDetail.documentsRequired = JSON.parse(data.documentsRequired);
            this.jobDetail.dueDate = data.dueDate;
            this.jobDetail.statusId = data.statusId;
            this.jobDetail.status = data.status;
            this.jobDetail.active = data.active;
            this.jobDetail.createdById = data.createdById;
            this.jobDetail.createdBy = data.createdBy;
            this.jobDetail.createdDt = data.createdDt;
            this.jobDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.$refs.dayMonthYearControlDet.setValueInputData(data.experienceYears, data.experienceMonths, null);
            this.getRoleResponsibilities();
            this.getTechnologySkills();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.jobDetail.id = data.id;
                this.jobDetail.jobId = data.jobId;
                this.jobDetail.vhextAccountId = data.vhextAccountId;
                this.jobDetail.vacancies = data.vacancies;
                this.jobDetail.title = data.title;
                this.jobDetail.jobCode = data.jobCode;
                this.jobDetail.position = data.position;
                this.jobDetail.qualification = data.qualification;
                this.jobDetail.description = data.description;
                this.jobDetail.experienceMonths = data.experienceMonths;
                this.jobDetail.experienceYears = data.experienceYears;
                this.jobDetail.location = data.location;
                this.jobDetail.documentsRequired = JSON.parse(data.documentsRequired);
                this.jobDetail.dueDate = data.dueDate;
                this.jobDetail.statusId = data.statusId;
                this.jobDetail.status = data.status;
                this.jobDetail.active = data.active;
                this.jobDetail.createdById = data.createdById;
                this.jobDetail.createdBy = data.createdBy;
                this.jobDetail.createdDt = data.createdDt;
                this.jobDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.$refs.dayMonthYearControlDet.setValueInputData(data.experienceYears, data.experienceMonths, null);
                this.getRoleResponsibilities();
                this.getTechnologySkills();
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.job.entity = this.entity;
                this.job.id = item.id;
                this.job.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.job.entity = this.entity;
                this.job.id = item.id;
                this.job.active = 1;

                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.job)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.job.active = true;
                    this.getJobs();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        getMailContent(jobData) {
            let data = {
                jobId: jobData.id,
                vhextAccountId: this.vhextAccountId,
                message: jobData.title,
                referralId: this.userId,
            }
            this.showMailLoading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/job/share', data)
                .then((response) => {
                    this.showMailLoading = false;
                    let result = response.data;
                    let sendMailData = result.sendMailData;
                    let sendMail = {};
                    sendMail.body = sendMailData.message;
                    sendMail.subject = sendMailData.subject;
                    sendMail.entity = 'job';
                    this.$nextTick(() => {
                        this.$refs.sendMailPopupRef.setMailData(sendMail);
                    })
                })
                .catch(error => {
                    this.showMailLoading = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        generateFbPostContent(jobData) {
            let data = {
                jobId: jobData.id,
                vhextAccountId: this.vhextAccountId,
                message: jobData.title,
                referralId: this.userId,
            }
            this.showMailLoading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/job/sharelink', data)
                .then((response) => {
                    this.showMailLoading = false;
                    let result = response.data;
                    let jobShareLink = result.jobShareLink;
                    this.$nextTick(() => {
                        if (jobShareLink) {
                            window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(jobShareLink)+'&t=' + encodeURIComponent(jobShareLink), 'sharer', 'resizable=yes,top=200,left=500,width=500,height=400');
                        }
                    })
                })
                .catch(error => {
                    this.showMailLoading = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },

        vmxValueInput:function(data){
            this.$refs.dayMonthYearControl.setValueInputData(data.year, data.month, data.day);
        },
        vmxValueInputDet:function(data){
            this.$refs.dayMonthYearControlDet.setValueInputData(data.year, data.month, data.day);
        },
    }
}
</script>
