
import UserRequirement from '@/views/requirement/UserRequirement.vue'
import UserRequirementMaster from '@/views/requirement/UserRequirementMaster.vue'
import UserRequirementMasterList from '@/views/requirement/UserRequirementMasterList.vue'
import RequirementSpec from '@/views/requirement/RequirementSpec.vue'
import RequirementTrackingMasterList from '@/views/requirement/RequirementTrackingMasterList.vue'
import RequirementTracking from '@/views/requirement/RequirementTracking.vue'
import ReqBoard from '@/views/requirement/ReqBoard.vue'
import SystemRequirementSpecMaster from '@/views/requirement/SystemRequirementSpecMaster.vue'
import SystemRequirementSpecMasterList from '@/views/requirement/SystemRequirementSpecMasterList.vue'
import TaskRequirement from '@/views/requirement/TaskRequirement.vue'

export default [

    {
        path: '/userrequirement/:ermId',
        name: 'userrequirement',
        component: UserRequirement,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/urqmas',
        name: 'urqmas',
        component: UserRequirementMaster,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/urqmas/list',
        name: 'urqmaslist',
        component: UserRequirementMasterList,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/reqspc/:ermId',
        name: 'reqspc',
        component: RequirementSpec,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/srsmas',
        name: 'srsmas',
        component: SystemRequirementSpecMaster,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/srsmas/list',
        name: 'srsmaslist',
        component: SystemRequirementSpecMasterList,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
     {
        path: '/rqtmas/list',
        name: 'rqtmaslist',
        component: RequirementTrackingMasterList,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/requirementtracking/:ermId',
        name: 'requirementtracking',
        component: RequirementTracking,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/reqbrd',
        name: 'reqbrd',
        component: ReqBoard,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/landing/reqspc',
        name: 'lanreqspc',
        component: TaskRequirement,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
];