<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="finTxnPayRecLinkPopupRef" size="lg" modal-class="" :title="`Link ${finTxnData.amountTypeCode == 1 ? 'Receivabele' : 'Payable'}`" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <b-table show-empty small outlined stacked="md"  sort-icon-left :items="accountPayableReceivables" :fields="accPayRecFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(amount)="data">
                        <div>
                            {{ currency && currency!== 'null' ? currency : '' }} {{ data.item.amount }}
                        </div>
                    </template>
                    <template v-slot:cell(balanceAmount)="data">
                        <div>
                            {{ currency && currency!== 'null' ? currency : '' }} {{ data.item.amount + data.item.prTxnTotal }}
                        </div>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <button type="submit" title="Select Item" @click="selectPayableReceivable(data.item)" :class="{'active' : selectedTransactionId==data.item.id}" class="btn btn-outline-primary sbtn btn-xs py-1 px-2">
                            <span v-if="selectedTransactionId==data.item.id">
                                <i class="fa fa-check pr-1" aria-hidden="true"></i>Selected
                            </span>
                            <span v-else>Select</span>
                        </button>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Record found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button title="Cancel" size="sm" variant="outline-primary" @click.prevent="cancel()" class="float-right ml-1">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" :disabled="!selectedTransactionId || selectedTransactionId!=accPayableReceivable.id" v-b-modal.finTxnLinkDialog class="float-right" >Link Transaction</b-button>
                </div>
            </template>
        </b-modal>
        <DialogComponent id="finTxnLinkDialog" :onYes="onFinTxnLinkConfirmation" :returnParams="dialogBoxParam" title="Payable / Receivable Link" message="Are you sure to Link this transaction to Payable / Receivabele?" />
    </div>
</template>
<script>
export default {
    props: {
        id: { type: String, default: 'finTxnPayRecLinkPopup'},
        finTxnData: Object,
    },
    data() {
        return {
            loading: true,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            currency: localStorage.getItem('currency'),

            accPayableReceivable: {},

            selectedTransactionId: '',

            accountPayableReceivableList: [],
            accPayRecFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'accountPayableReceivableType', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'balanceAmount', label: 'Balance', sortable: true, sortDirection: 'desc' },
                // { key: 'empTimelineDt', label: 'Start Time', sortable: true, sortDirection: 'desc' },
                // { key: 'nextEmpTimelineDt', label: 'Stop Time', sortable: true, sortDirection: 'desc' },
                // { key: 'timeDiff', label: 'Time Diff (HH:MM)', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            dialogBoxParam: {
                id: 0,
            },

            totalRows: 0,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    computed: {
        accountPayableReceivables() {
            let accountPayableReceivables = this.accountPayableReceivableList.filter(e => (e.amount ? e.amount : 0) + (e.prTxnTotal ? e.prTxnTotal : 0) > 0);
            this.totalRows = accountPayableReceivables.length;
            return accountPayableReceivables;
        },
    },
    mounted() {
        this.getDetails();
    },
    methods: {
        getDetails: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                createdById: this.userId,
                accountPayableReceivableTypeId: this.finTxnData.amountTypeCode == 1 ? 2 : 1,
            }
            // this.$nextTick(() => {
            //     this.$bvModal.show(this.id);
            // });
            this.loading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/user/list', data)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    this.accountPayableReceivableList = result.accountPayableReceivableList;
                })
                .catch(error => {
                    this.loading = false;
                })
        },
        selectPayableReceivable(data) {
            this.selectedTransactionId = data.id;
            this.accPayableReceivable = data;
        },
        onFinTxnLinkConfirmation: function(id) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                financialTransactionId: this.finTxnData.id,
                accountPayableReceivableId: this.accPayableReceivable.id,
                title: this.finTxnData.id,
                transactionById: this.finTxnData.transactionById,
                amount: this.finTxnData.amount,
                transactionDt: this.finTxnData.transactionDt,
            }
            this.loading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnpayablereceivable/link', data)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDetails();
                        this.$emit('refreshData');
                        this.$bvModal.hide(this.id);
                    }
                })
                .catch(error => {
                    this.loading = false;
                })
        },
        closeDialog() {
            this.selectedTransactionId = '';
            this.$emit('closeDialog');
        }
    }
}
</script>