<template>
    <div>
        <SystemRequirementSpecMaster :ermMasterInfo="ermMasterInfo"></SystemRequirementSpecMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import SystemRequirementSpecMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        SystemRequirementSpecMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "System Requirement Spec Master Doc",
                entity: 'system_requirement_spec_master',
                descriptionLabelName: 'Scope',
                refEntity: 'requirement_spec',
                ermListRedirectURL: '/srsmas/list',
                erdRedirectURL: '/reqspc/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>