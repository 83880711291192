import Designation from '@/views/resource/Designation.vue'
import HumanResource from '@/views/resource/HumanResource.vue'
import ProjectTeam from '@/views/project/ProjectTeam.vue'
import ProjectTeamMaster from '@/views/project/ProjectTeamMaster.vue'
import ProjectTeamMasterList from '@/views/project/ProjectTeamMasterList.vue'
import MembershipManagement from '@/views/resource/ProjectMembershipMgmt.vue';
import TeamBoard from '@/views/project/TeamBoard.vue'
import Resource from '@/views/resource/Resource.vue'
import ResourcePlan from '@/views/resource/ResourcePlan.vue'
import ResourcePlanMaster from '@/views/resource/ResourcePlanMaster.vue'
import ResourcePlanMasterList from '@/views/resource/ResourcePlanMasterList.vue'
import ResourcePrivileges from '@/views/resource/ResourcePrivileges.vue'
import RoleResponsibility from '@/views/resource/RoleResponsibility.vue'
import TechnologySkill from '@/views/resource/TechnologySkill.vue'
import UserActivityLog from '@/views/resource/UserActivityLog.vue'

export default [
    {
        path: '/designation',
        name: 'designation',
        component: Designation,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/humanresource',
        name: 'humanresource',
        component: HumanResource,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/projectteam/:ermId',
        name: 'projectteam',
        component: ProjectTeam,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prtmas',
        name: 'prtmas',
        component: ProjectTeamMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prtmas/list',
        name: 'prtmaslist',
        component: ProjectTeamMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prjmbr',
        name: 'prjmbr',
        component: MembershipManagement,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tmbrd',
        name: 'tmbrd',
        component: TeamBoard,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/resource',
        name: 'resource',
        component: Resource,
        meta: { accessedby: 'PRM|EMP|TMB|CAM' }
    },
    {
        path: '/resourceplan/:ermId',
        name: 'resourceplan',
        component: ResourcePlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rplmas',
        name: 'rplmas',
        component: ResourcePlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rplmas/list',
        name: 'rplmaslist',
        component: ResourcePlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/resourceprivileges',
        name: 'resourceprivileges',
        component: ResourcePrivileges,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/roleresponsibility',
        name: 'roleresponsibility',
        component: RoleResponsibility,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/technologyskill',
        name: 'technologyskill',
        component: TechnologySkill,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/useractivitylog',
        name: 'useractivitylog',
        component: UserActivityLog,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }
];