<template>
    <div>
        <div v-if="loading" id="loader"></div>
        <div v-if="!loading">
            <div v-if="meetingMinutes.length > 0" class="row">
                <div class="col-12 col-sm-12 px-0">
                    <!-- <h6 class="contact-title mb-3 ml-2"><span>Minutes of Meeting</span></h6> -->
                    <div class="accordion" role="tablist">
                        <template v-for="meetingMinute in meetingMinutes">
                            <b-card no-body class="mb-1">
                                <b-card-header header-tag="header" class="p-1 text-left" role="tab">
                                    <b-button block v-b-toggle.accordion-1 variant="info" class="sbtn text-left">{{ meetingMinute.title }}
                                        <span class="float-right">
                                            Duration: {{ formatDurationHM(meetingMinute.estimatedHour,meetingMinute.estimatedMinute) }}
                                            <i class="fa fa-angle-down fa-lg font-weight-bold pointer ml-2"></i>
                                        </span>
                                        <!-- <span v-else class="float-right">Deadline: {{ meetingMinute.deadline | formatDate }}<i class="fa fa-angle-down fa-lg font-weight-bold pointer ml-2"></i></span> -->
                                    </b-button>
                                </b-card-header>
                                <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body class="pt-3">
                                        <div class="row">
                                            <div v-if="meetingMinute.initiatedPerson" class="col-8 col-md-6 pl-0 pr-1">
                                                <div class="row mb-0">
                                                    <div class="col-5 pr-0">
                                                        <label class="p-2 d-block title-block">Initiated By</label>
                                                    </div>
                                                    <div class="col-7 pl-0">
                                                        <label class="p-2 d-block header-details">{{ meetingMinute.initiatedPerson }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="meetingMinute.responsiblePerson" class="col-md-6 pl-1 pr-0">
                                                <div class="row mb-0">
                                                    <div class="col-5 pr-0">
                                                        <label class="p-1 pt-2 pb-2 d-block title-block">Person Responsible</label>
                                                    </div>
                                                    <div class="col-7 pl-0">
                                                        <label class="p-2 d-block header-details">{{ meetingMinute.responsiblePerson }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div v-if="meetingMinute.description" class="col-12 col-md-12 px-0">
                                                <div class="card mt-2 mb-0">
                                                    <div class="card-body py-1 px-0">
                                                        <p class="contact-title mb-2 ml-2"><span>Agenda Item</span></p>
                                                        <div class="row">
                                                            <div class="col-12 col-md-12 m-0 px-3">
                                                                <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.description"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                <p>No Meeting Minutes Found</p>
            </div>
        </div>
    </div>
</template>
<script>
import dayList from '@/json/daysofweek.json';
export default {
    props: {
        momData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
            eventParticipants: [],
            meetingMinutes: [],
            days: dayList,
        }
    },
    mounted() {
        // this.getMeetingMinute();
    },
    methods: {
        getMeetingMinute: function() {
            if (this.momData.meetingDate) {
                let data = {
                    entityRecordMasterId: this.momData.entityRecordMasterId,
                    calendarEventId: this.momData.calendarEventId,
                    meetingDate: this.momData.meetingDate,
                }
                this.loading = true;
                axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminutebydate/list', data)
                    .then((response) => {
                        this.loading = false;
                        let result = response.data;
                        this.meetingMinutes = result.meetingMinuteList;
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
            }
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>