<template>
    <div class="accountPayableReceivablePopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered :title="getPopupTitle" no-enforce-focus @hide="closeDialog" id="addAccountPayableReceivablePopup" ref="quickAccountPayableReceivablePopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Title</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.accountPayableReceivable.title.$error }">
                                <b-form-input id="title" v-model="accountPayableReceivable.title" placeholder="Enter Title" required></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.accountPayableReceivable.title.$error && !$v.accountPayableReceivable.title.minLength" class="text-danger">Title must have at least {{$v.accountPayableReceivable.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.accountPayableReceivable.title.$error && !$v.accountPayableReceivable.title.required" class="text-danger">Please enter Title</label>
                            <label v-if="$v.accountPayableReceivable.title.$error && !$v.accountPayableReceivable.title.maxLength" class="text-danger">Title must not exceed {{$v.accountPayableReceivable.title.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Account Head</b-col>
                        <b-col cols="8" class="pr-1">
                            <select v-model="accountPayableReceivable.accountHeadId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in accountHeads" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                        <a :title="accountHeadLookupTitle" class="action-link" href="javascript:" v-b-modal.accountHeadModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Txn Date</b-col>
                        <b-col>
                            <input v-model="accountPayableReceivable.transactionDt" type="Date" class="form-control">
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Due Date</b-col>
                        <b-col>
                            <input v-model="accountPayableReceivable.dueDate" type="Date" class="form-control">
                        </b-col>
                    </b-row>
                    <!-- <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Txn Type</b-col>
                        <b-col>
                            <select v-model="accountPayableReceivable.accountPayableReceivableTypeId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in payableReceivableTypes" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row> -->
                    <!-- <b-row class="mb-2">
                        <b-col cols="3" class="my-auto">PR Type</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.accountPayableReceivable.accountPayableReceivableTypeId.$error }">
                                <select v-model="accountPayableReceivable.accountPayableReceivableTypeId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in payableReceivableTypes" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.accountPayableReceivable.accountPayableReceivableTypeId.$error && !$v.accountPayableReceivable.accountPayableReceivableTypeId.required" class="text-danger">Please Select a Transaction Type</label>
                        </b-col>
                    </b-row> -->
                    <!-- <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Category</b-col>
                        <b-col cols="8" class="pr-1">
                            <select v-model="accountPayableReceivable.categoryId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in accountPayableReceivableCategories" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                        <a :title="categoryLookupTitle" class="action-link" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row> -->
                    <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Amount</b-col>
                        <b-col>
                            <VhextMoneyControl :value="Math.abs(Number(accountPayableReceivable.amount))" @money-input="moneyControlInput" />
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3" class="my-auto">Person Responsible</b-col>
                        <b-col>
                            <select v-model="accountPayableReceivable.responsiblePersonId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="mb-2">
                        <b-col cols="3" class="my-auto">Fin Txn Master</b-col>
                        <b-col cols="8 pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.accountPayableReceivable.entityRecordMasterId.$error }">
                                <select v-model="accountPayableReceivable.entityRecordMasterId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.accountPayableReceivable.entityRecordMasterId.$error && !$v.accountPayableReceivable.entityRecordMasterId.required" class="text-danger">Please Select a Financial Txn Master</label>
                        </b-col>
                        <a title="Create new Fin Txn Master" class="action-link" href="javascript:" @click="openReqPop"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row> -->
                </b-container>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="close()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ accountPayableReceivable.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ accountPayableReceivable.editMode ? 'Update Transaction' : 'Add Transaction' }}</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <AccountHeadLookup ref="accountHeadModal" :entity="this.entity" :accountHeadLookupTitle="accountHeadLookupTitle" @refresh-lookup="lookupData" />                
        <!-- <CategoryLookup ref="categoryLookupModal" :projectTypeId="this.projectTypeId" :entity="this.categoryEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" /> -->
        <!-- <ERMMasterPopup v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData" @closeDialog="showDialog=false" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AccountHeadLookup from '@/components/popup/lookup/AccountHeadLookup.vue'
// import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue'
// import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'

export default {
    props: {
        projectTypeId: [String, Number],
        accountPayableReceivableData: Object,
    },
    components: {
        AccountHeadLookup,
        // CategoryLookup,
        // ERMMasterPopup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            entity: 'account_payable_receivable',
            categoryEntity: 'account_payable_receivable_category',
            accountHeadLookupTitle: 'Account Head Settings',
            categoryLookupTitle: 'Financial Category Settings',

            entityERMLookup: [],
            accountHeads: [],
            payableReceivableTypes: [],
            amountTypes: [],
            accountPayableReceivableCategories: [],
            users: [],
            accountPayableReceivableActivity: [],
        }
    },
    mounted() {
        this.lookupData();
        console.log(this.accountPayableReceivable)
    },
    computed: {
        accountPayableReceivable() {
            return this.accountPayableReceivableData;
        },
        getPopupTitle() {
            return (this.accountPayableReceivable.editMode ? 'Edit ' : 'Add ') + (this.accountPayableReceivable.accountPayableReceivableTypeId==1 ? 'Payable' : 'Receivable');
        }
    },
    validations: {
        accountPayableReceivable: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            accountPayableReceivableTypeId: {
                required
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'account_payable_receivable',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/lookup/all', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                this.accountHeads = result.accountHeadLookup;
                this.payableReceivableTypes = result.payableReceivableTypeLookup;
                // this.accountPayableReceivableCategories = result.customCategoryLookup;
                this.users = result.userLookup;
            })
            .catch((error)=> this.showOverlay = false);
        },
        saveData: function() {
            this.$v.accountPayableReceivable.$touch();
            // this.amountTypes = this.payableReceivableTypes;
            // this.amountTypes = this.amountTypes.find( ({ id }) => id === this.accountPayableReceivable.accountPayableReceivableTypeId );
            // console.log(this.amountTypes.typeClass);
            // if (this.amountTypes.typeClass == 1) {
            //     this.accountPayableReceivable.isIncome = true;
            // } else {
            //     this.accountPayableReceivable.isIncome = false;
            // }
            this.accountPayableReceivable.amount = Math.abs(this.accountPayableReceivable.amount);
            console.log(this.accountPayableReceivable.isIncome,this.accountPayableReceivable);
            if (!this.$v.accountPayableReceivable.$invalid) {
                this.showOverlay = true;
                if (this.accountPayableReceivable.editMode) {
                    this.updateAccountPayableReceivable();
                } else {
                    this.saveAccountPayableReceivable();
                }
            }
        },
        saveAccountPayableReceivable: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/add', this.accountPayableReceivable)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        updateAccountPayableReceivable: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/update', this.accountPayableReceivable)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        moneyControlInput: function(value) {
            this.accountPayableReceivable.amount = Math.abs(value);
        },
        cancelEvent: function() {
            this.$bvModal.hide('addAccountPayableReceivablePopup');
        },
        closeDialog() {
            this.$v.accountPayableReceivable.$reset();
            this.$emit('closeDialog');
            this.$emit('refreshData');
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        }
    }
}
</script>