<script>
import { HorizontalBar, mixins } from "vue-chartjs";

export default {
    mixins: [mixins.reactiveData],
    extends: HorizontalBar,
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.renderChart(this.chartdata, this.options)
    }
};
</script>