<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" ref="taskDetailPopupRef" centered @hide="closeDialog" size="lg" modal-class="" title="Task Detail" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe">
                    <div class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12">
                                <div class="row">
                                    <div class="col-6 col-sm-6 mb-3 px-0">
                                        <div class="row mb-0">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.taskId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-lg-4">
                                        <span class="" title="Task Progression">{{ popupData.progressPercentage ? popupData.progressPercentage : 0 }}% done</span>
                                        <b-progress height="7px" :variant="taskProgressBarVariant(popupData)" v-model="popupData.progressPercentage"></b-progress>
                                    </div>
                                    <div class="col-3 col-lg-2 text-right p-0">
                                        <div class="float-right w-100">
                                            <h6 class="todo-label"><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                           <a href="javascript:" title="Play task" class="play-audio-btn action-link" v-b-modal.audioPlayModal>
                                                <i class="fa fa-file-audio-o font-s-20" aria-hidden="true"></i>
                                           </a>
                                        </div>
                                    </div>
                                </div>
<!--                                 <b-row class="mb-1">
                                    <b-col cols="3"></b-col>
                                    <b-col cols="9">
                                        <div class="close-audio">
                                            <audio v-if="popupData.audioUrl != ''" controls  :src="audioUrl"></audio>
                                            <a  v-if="audioUrl != ''" href="javascript:" title="Close Record" @click="popupData.audioUrl = ''" class="close-audio-btn"><i class="fa fa-close" aria-hidden="true"></i></a>                                         
                                        </div>
                                    </b-col>
                                </b-row>                                 -->
                                <div class="row">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mb-0">
                                            <div class="col-3 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Task Plan</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.ermTitle}}</label>
                                            </div>
                                        </div>
                                    </div>
<!--                                     <div class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mb-0">
                                            <div class="col-3 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Est Cost</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">EC {{popupData.estimatedCost}}</label>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div  v-if="popupData.process != '' && popupData.activity != ''" class="row">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mb-0">
                                            <div class="col-3 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Process</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.process}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mb-0">
                                            <div class="col-3 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.activity}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="popupData.assignedBy !== ''" class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Assigned By</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.assignedBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.assignedTo !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Assigned To</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.assignedTo}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div v-if="popupData.estimatedStartDt !== ''" class="col-4 col-sm-4">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-4 px-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Date</label>
                                            </div>
                                            <div class="col-6 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.estimatedStartDt | formatDateTimeAmPm}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.estimatedEndDt !== ''" class="col-4 col-sm-4">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Due Date</label>
                                            </div>
                                            <div class="col-6 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.estimatedEndDt | formatDateTimeAmPm}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.workHour || popupData.workMinute || popupData.workDay" class="col-2 col-md-2">
                                        <div class="row mt-0 mb-2" title="Estimated Effort">
                                            <div class="col-12 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block header-details"><b>EE : </b>{{ workDuration(popupData.workHour,popupData.workMinute,popupData.workDay) }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.workHour || popupData.workMinute || popupData.workDay" class="col-2 col-md-2">
                                        <div class="row mt-0 mb-2" title="Actual Effort">
                                            <div class="col-12 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block header-details"><b>AE : </b>{{ workDuration(popupData.workHour,popupData.workMinute,popupData.workDay) }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div v-if="popupData.estimatedCost" class="col-2 col-md-2
                                    ">
                                        <div class="row mt-0 mb-2" title="Estimated Cost">
                                            <div class="col-12 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 ml-2 pr-2 d-block header-details"><b>EC : </b>{{ popupData.estimatedCost}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12 pl-0 pr-1">
                                        <div class="card mt-2 mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Task Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.response == ''" class="col-12 col-sm-12 pl-1 pr-0">
                                        <div class="card mt-2 mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Response</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.response"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div  class="col-12 col-sm-12">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 px-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reference</label>
                                            </div>
                                            <div class="col-12 col-md-10 pr-0"> 
                                                <ReferenceView ref="viewReferenceRef" :erdId="referenceDetailId" :refEntity="entity" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="attachmentLength" class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                                            </div>
                                            <div class="col-12 col-md-10 pl-0">
                                                <div class="col-12">
                                                    <div class="mt-2">
                                                        <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentLength" :attachmentData="attachmentData" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12 py-2 px-0">
                                        <h6 class="contact-title mb-2 ml-2"><span>Task Progression</span></h6>
                                        <TimeSheetListComponent :timeSheets="timeSheets"></TimeSheetListComponent>
                                        <!-- <div v-for="timeSheet in timeSheets" class="card px-3 pt-2 pb-1 mb-2">
                                            <p class="mb-0">
                                                <label class="badge badge-info float-right" title="Status">{{ timeSheet.status }}</label>
                                                <span  class="font-weight-bold">{{ timeSheet.taskTitle }}</span>
                                            </p>
                                            <div v-if="timeSheet.description" class="row mb-1">
                                                <div class="col-auto">
                                                    <span class="font-weight-bold">Description</span>
                                                </div>
                                                <div class="col-9">
                                                    <span v-html="timeSheet.description"></span>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-sm-4 col-lg-4">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <span class="font-weight-bold">Start Time</span>
                                                        </div>
                                                        <div class="col-7">
                                                            <span v-if="timeSheet.startDate && timeSheet.startDate !== null">{{ timeSheet.startDate | formatDateTimeYearCheck }}</span>
                                                            <span v-else>-</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4 col-lg-4">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <span class="font-weight-bold">End Time</span>
                                                        </div>
                                                        <div class="col-7">
                                                            <span v-if="timeSheet.endDate && timeSheet.endDate !== null">{{ timeSheet.endDate | formatDateTimeYearCheck }}</span>
                                                            <span v-else>-</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4 col-lg-4">
                                                    <div class="row">
                                                        <div class="col-auto">
                                                            <span class="font-weight-bold">Actual Effort</span>
                                                        </div>
                                                        <div class="col-7">
                                                            <span v-if="timeSheet.workHour || timeSheet.workMinute || timeSheet.workDay">
                                                                {{ workDuration(timeSheet.workHour,timeSheet.workMinute,timeSheet.workDay) }}
                                                            </span>
                                                            <span v-else>-</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <span class="ml-3 mr-auto subtle">Status update by {{ timeSheet.preparedBy }}</span>
                                                <span class="ml-auto mr-3 subtle">Updated on {{ timeSheet.lastUpdatedDt | formatDateTimeAmPm }}</span>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <AudioControl ref="audioPlayRef" refEntity="task" :refRecordId="referenceDetailId" />
        <!-- <AudioRecord ref="audioRecordRef" :recordAudio="true" recordModalId="recordModal"  :refEntity="entity" :refRecordId="(task.id).toString()" @assign-data="assignAudioUrl" /> -->
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';        
import AudioControl  from '@/components/usercontrols/AudioControl.vue';
import ReferenceView from '@/components/common/ViewReferenceComponent.vue';
import TimeSheetListComponent from '@/components/common/TimeSheetListComponent.vue';
export default {
    props: {
        id: { type: String, default: 'taskDetailPopup' },     
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object
    },
    components: {
        AttachmentView,
        AudioControl,
        ReferenceView,
        TimeSheetListComponent,
    },
    data() {
        return {
            loading: true,
            popupData: {},
            entity: 'task',
            timeSheets: [],
            attachmentLength: 0,
            audioUrl:'',

            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            this.$bvModal.show(this.id);
            console.log(id, refEntity);
            this.$nextTick(() => this.getTimeSheets(id));
            if (refEntity === "task"  || refEntity==='TASK') {
                axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.task) {

                        this.popupData = result.task;
                        this.$refs.audioPlayRef.onDataChange(this.popupData.id,"task");
                        console.log("TSK",this.popupData);
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                    console.log("TSK",this.popupData);
                });
                this.loading = false;
            }
        },
        getTimeSheets: function(id) {
            if (id && id != '') {
                let data = {
                    taskId: id
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetbytask/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.timeSheets = result.timeSheetListByTask;
                        console.log(result);
                    })
            }
        },
        getAttachmentLength(length) {
            this.attachmentLength = length;
        },
        workDuration(workHour, workMinute, workDay) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType != null) {
                return effortType[0].name;
            }
        },
        assignAudioUrl: function(value) {
            this.audioUrl = value;
        },

        print: function() {    
            this.$htmlToPaper('printMe');
        },  
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>
<style scoped>
span >>> p {
    margin: 0;
}
</style>