<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-lg-4">
                            <h4 class="pl-2 mb-0 fs-18">Services</h4>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Project" :to="{ name: 'landing'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Services" :to="{ name: 'srvmasedit', params: { projectid: projectId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="title">Title</label>
                                            <input type="text" class="form-control" id="title" disabled v-model="project.title">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="projectType">Project Type</label>
                                            <input type="text" class="form-control" id="projectType" disabled v-model="project.type">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Description</label>
                                            <textarea class="form-control" v-model="project.description" disabled rows="5"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="status">Project Id</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{project.projectId}}</span></p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="status">Project Status</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{project.status}}</span></p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="createdDt">Created Date</label>
                                        <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(project.createdDt)">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="lastUpdatedDt">Updated Date</label>
                                        <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(project.lastUpdatedDt)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        projectId() {
            return Number(this.$route.params.projectid);
        }
    },
    data() {
        return {
            project: []
        }
    },
    mounted() {
        this.viewProject(this.projectId);
    },
    methods: {
        viewProject: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/project/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.project = result.projects;
                })

        }
    }
}
</script>