<template>
    <div>
        <DocumentFolderMasterList :ermMasterInfo="ermMasterInfo"></DocumentFolderMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DocumentFolderMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DocumentFolderMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Project Doc Folders",
                entity: 'document_folder_master',
                refEntity: 'document_folder',
                tooltip: 'New Folder',
                redirectURLName: 'projectdocument',
                newEntityMasterURL: 'pdcmas',
                redirectURL: '/pdcmas',
                actionName: 'Project Documents',
                previewURLName: 'pdcrep',
                headerTitle: 'Folder'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>