<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-lg-4">
                            <h4 class="pl-2 mb-0 fs-18">Country</h4>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Country" :to="{ name: 'addcountry'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Country" :to="{ name: 'editcountry', params: { countryid: countryId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:"><i class="fa fa-trash pr-2"></i>Detete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
          <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" disabled v-model="country.country" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" disabled v-model="country.countryId" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" disabled v-model="country.alpha2Code" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" disabled v-model="country.alpha3Code" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        countryId() {
            return Number(this.$route.params.countryid);
        }
    },
    data() {
        return {
            country: []
        }
    },
    mounted() {
        this.viewCountry(this.countrytId);
    },
    methods: {
        viewCountry: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/country/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.country = result.countrys;

                })

        }
    }
}
</script>