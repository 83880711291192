<template>
    <div>
        <GoalMaster :ermMasterInfo="ermMasterInfo"></GoalMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import GoalMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        GoalMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Goal Master",
                entity: 'goal_master',
                refEntity: 'objective', 
                ermListRedirectURL: '/goalmaster/list',
                erdRedirectURL: '/goalmaster/objective/'             
                //showTypeLookup: true,
                //typeLabelName: 'Type Name'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>