<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Human Resource</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row mb-4">
                <div class="col-lg-8 col-xl-8">
                    <div class="card">
                        <div class="card-body p-3">
                            <HrNonEmployees ref="humanResources" @getHumanResourceDetail="getHumanResourceDetail" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <HumanResourceDetail ref="humanResourceDetail" @eventHRSave="refreshHumanResources"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HrNonEmployees from '@/components/common/HrNonEmployeeComponent.vue';
import HumanResourceDetail from '@/components/common/HumanResourceDetail.vue';
export default {
    name: 'app',
    components: {
        HrNonEmployees,
        HumanResourceDetail,
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
        }
    },
    mounted() {
    },
    methods: {
         getHumanResourceDetail(humanResourceId) {
            this.$refs.humanResourceDetail.getOtherHRDetails(humanResourceId);
            // this.$emit('getHumanResourceDetail', humanResourceId);
            // this.humanResourceId = humanResourceId;
        },
        refreshHumanResources(id) {
            this.$refs.humanResources.getHumanResource();
            // alert(id);
            // this.$refs.humanResources.showSelectedHR(id);
        },
    }

}
</script>