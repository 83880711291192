<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Plan Document</h4>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="showStatusBlock" class="" title="Status Tracker"><i class="fa fa-bars action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="javascript:" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="javascript:" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div id="printMe" class="col-12 report-tables status-block-effect" :class="showStatus ? 'col-lg-8 col-xl-8' : 'col-12 col-lg-12 col-xl-12 '">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0 ">
                                                                <select v-if="review.isReviewed == 1" v-model="review.statusId"  class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <select v-if="review.isReviewed == 2" v-model="review.statusId"  class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a v-if="review.isReviewed != 0" href="javascript:" title="update status" data-toggle="modal" data-target="#confirmUpdateStatus" class="float-right p-2 action-link">
                                                                    <i class="fa fa-check ml-4"></i>
                                                                </a>
                                                                <label v-if="review.isReviewed ==0" class="p-2 mb-0 d-block header-details">{{review.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals && ermReviewApprovals.length > 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description != null && erm.description.length != 0" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label class="p-2 mb-0 d-block header-details" v-html="erm.description"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="plans != null && plans.length != 0" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="plans" :fields="planFields" responsive="sm" :current-page="plan.currentPage" :per-page="plan.perPage"  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template v-slot:cell(deliverables)="data">
                                                <span v-for="(info, index) in JSON.parse(data.item.deliverables)">                              
                                                    <span v-if="index != 0">, </span>
                                                    <span>{{ info.value }}</span>
                                                </span>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <div>
                                                  <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Reopened'" variant="warning">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                                </div>
                                            </template>
                                            

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Plans found</div>
                                            </template>                                           
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="showDiv" class="status-block-hide" :class="{'col-lg-4 col-xl-4 pl-0 d-block' : showStatus}">
                    <div class="card">
                        <StatusTracker :statusUpdate="statusUpdate" @updateParent=""></StatusTracker>
                    </div>
                </div>
            </div>
        </div>
        <!-- <Dialog id="deleteComment" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />

        <Dialog id="confirmUpdateStatus" :onYes="updateStatus" :returnParams="deleteParam" title="Update Status" message="Are you sure to update the status?" /> -->

        <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
import StatusTracker from '@/components/common/StatusTracker.vue';
import moment from 'moment';
export default {
    name: 'app',
    components: {
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
        StatusTracker,
    },
    data() {
        return {
            plan: {
                currentPage: 1,
                perPage: 5,
            },
            showStatus: false,
            showDiv: false,

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                refEntityId: '',
                refEntity: '',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],

            isBusy: true,
            plans: [],
            masterStatus: [],
            status: [],

            plans:[],
            planFields: [
                { key: 'planId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'phaseTitle', label: 'Plan Title', sortable: true, sortDirection: 'desc' },
                { key: 'process', label: 'Process', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'deliverables', label: 'Deliverables',tdClass: 'table-cw-250', sortable: true, sortDirection: 'desc' },
                { key: 'responsibility', label: 'Responsible Person', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' }
            ],

            selectMode: 'single',
            notEnabled: true,
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            // deleteParam: {
            //     id: 0
            // }
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        }
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.entityMaster = 'plan_master';
            this.entity = 'plan';

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getPlans(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getPlans(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        getPlans: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.plans = result.planList;
                })
        },
        callFunctions: function() {
            this.entityRecordMasterCollection(this.ermId);
            this.getPlans();
            this.getComments();
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.masterStatus = result.statusLookup;
                    if (this.review.isReviewed == 1) {
                        this.status = this.filterReviewApproval('Reviewed');
                    } else if (this.review.isReviewed == 2) {
                        this.status = this.filterReviewApproval('Approved');
                    }
                })
        },
        showStatusBlock: function() {
            // this.$refs.reqTrackingTable.selectRow(index);
                this.showStatus = !this.showStatus;
                this.showRightside(this.showStatus);
                this.assignStatusUpdate();
            // else if (this.statusUpdate.refRecordId == data.id && this.showStatus) {
                // this.showStatus = false;
                // this.showRightside(this.showStatus);
            // } else {
            //     this.showStatus = true;
            //     this.showRightside(this.showStatus);
            //     // this.assignStatusUpdate(data);
            // }
        },
        showRightside: function(status) {
            setTimeout(()=> {
            // dealy, to show the content after completing card animation
                this.showDiv = status;
                console.log(this.showDiv)
            },300);
        },
        assignStatusUpdate: function(data) {
            // this.$refs.statusUpdate.clearData();

            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.refRecordId = this.entityRecordMasterId;
            this.statusUpdate.refEntity = 'entity_record_master';
            // this.statusUpdate.functionalEntityId = data.functionalEntityId;
            this.statusUpdate.functionalEntity = this.entityMaster;
            this.statusUpdate.statusById = this.userId;
            // this.statusUpdate.statusId = data.statusId;
            // this.statusUpdate.status = data.status;
            // this.statusUpdate.remarks = data.remarks;
            // this.statusUpdate.workflowRequestId = data.id;
            // this.statusUpdate.isWorkflowAutomated = true;
            // this.$refs.statusUpdate.onTitleChange();
        },   
        filterReviewApproval(value) {
            return this.masterStatus.filter(function(data, key) {
                if (data.value == value || data.value == 'Reject') {
                    return data;
                }
            });
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }
    }
}
</script>