<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Functional Review & Approval Configuration</h4>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Functional Review & Approval Config</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="reviewConfig">Functionality</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.reviewConfig.entityId.$error }">
                                                                <select v-model="reviewConfig.entityId" class="form-control" @change="onChange(reviewConfig.entityId)">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in functionalityList" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.reviewConfig.entityId.$error && !$v.reviewConfig.entityId.required" class="text-danger">Please Select Functionality</label>
                                                        </div>
                                                    </div>                                           
                                                    <div class="col-1">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="reviewConfig.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="budget">Review By</label>
                                                            <multiselect v-model="reviewConfig.reviewList" :options="users" name="activity" label="value" track-by="id" placeholder="Select Users" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="budget">Approval By</label>
                                                            <multiselect v-model="reviewConfig.approvalList" :options="users" name="activity" label="value" track-by="id" placeholder="Select Users" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveReviewConfigData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="reviewConfigTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="reviewConfigList"  @row-selected="onRowSelected" :fields="reviewConfigFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" foot-clone foot-variant=light no-footer-sorting>
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deleteProcess(data.item.id)" v-b-modal.deleteProcess><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Review Configuration found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                                <template v-slot:foot()="data">
                                    <span></span>
                                </template>                                
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                            <b-pagination v-if="reviewConfigList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm" >
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{reviewConfigDetail.entityId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="saveReviewConfigData" :disabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="form-group">
                                                    <label for="titleDet">Functionality</label>
                                                    <div v-if="elements.editMode" class="required-field-block" :class="{ 'is-invalid': $v.reviewConfigDetail.entityId.$error }">
                                                        <select v-model="reviewConfigDetail.entityId" class="form-control" @change="onChange(reviewConfigDetail.entityId)">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in functionalityList" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <input v-else type="text" v-model="reviewConfigDetail.functionality" disabled class="form-control" id="serviceProvider" >
                                                    <label v-if="$v.reviewConfigDetail.entityId.$error && !$v.reviewConfigDetail.entityId.required" class="text-danger">Please Select Functionality</label>                                                    
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label for="activeDet">Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="reviewConfigDetail.active" :disabled="!elements.editMode" name="check-button" switch id="activeDet">
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="budget">Review By</label>
                                                    <multiselect v-model="reviewConfigDetail.reviewList" :disabled="!elements.editMode" :options="users" name="activity" label="value" track-by="id" placeholder="Select Users" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="budget">Approval By</label>
                                                    <multiselect v-model="reviewConfigDetail.approvalList" :disabled="!elements.editMode" :options="users" name="activity" label="value" track-by="id" placeholder="Select Users" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='reviewConfigDetail.userName' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="reviewConfigDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="reviewConfigDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteProcess" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Review Configuration ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    name: 'app',
    components: {
        Multiselect,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,

            detailViewData: {},
            referencePopupId: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'review_config',
            userId: localStorage.getItem("userId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            activityKey: 0,
            mainTableArray: [],

            reviewConfig: {
                id: '',
                reviewConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                reviewById: '',
                reviewList: [],
                approvalList: [],
                isApproval: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            reviewConfigDetail: {
                id: '',
                reviewConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                functionality: '',
                reviewById: '',
                reviewList: [],
                approvalList: [],
                isApproval: '',
                active: true,
                createdBy: '',
                createdById: localStorage.getItem("userId"),
            },
            users: [],
            functionalityList: [],
            reviewConfigList: [],

            reviewConfigFields: [
                {key:'index',label: 'SN'},
                { key: 'functionality', label: 'Functionlity', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                { key: 'reviewBy', label: 'Review By', sortable: true, sortDirection: 'desc' },
                { key: 'approvalBy', label: 'Approval By', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

        }
    },
    mounted() {
        this.getReviewConfig();
        this.lookupData();
    },
    validations: {
        reviewConfig: {
            entityId: {
                required,
            },
        },
        reviewConfigDetail: {
            entityId: {
                required,
            },
        },

    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = this.entity;
            this.userId = localStorage.getItem("userId");
            this.projectTypeId = localStorage.getItem("projectTypeId");
            console.log(this.projectTypeId)

        },

        getReviewConfig: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/reviewconfig/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.reviewConfigList && result.reviewConfigList.length > 0) {
                        this.reviewConfigList = result.reviewConfigList;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.reviewConfigDetail.id, this.mainTableArray, 'reviewConfigTable');
                        })
                        this.totalRows = this.reviewConfigList.length;
                    }
                })
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/review/reviewconfig/lookup/all' , data) 
            .then((response) => {
                let result = response.data;
                this.users = result.userLookup;
                this.functionalityList = result.entityLookup;                    
            })
        },
        onChange(entityId) {

            this.activityKey += 1;

            this.elements.showDetail = false;
            this.getReviewConfigActivities(entityId);
            this.reviewConfig.description = this.functionalityList.find(data => data.id == entityId).description;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveReviewConfigData: function() {
            if (this.elements.editMode) {
                this.updateReviewConfig();
            } else {
                this.addReviewConfig();
            }
        },

        addReviewConfig: function() {
            if (this.matchTextValExists(this.reviewConfig.entityId)) {
                iziToast.info({
                    message: 'Review Configuration already exist.',
                    position: 'topCenter'
                });
            }
            else {
                this.$v.reviewConfig.$touch();
                if (!this.$v.reviewConfig.$invalid) {
                    console.log(this.reviewConfig);
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/review/reviewconfig/bulk/add', this.reviewConfig)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Success',
                                    message: result.message,
                                    position: 'topRight'
                                });
                                this.clearData();
                                this.getReviewConfig();
                            }
                        })
                        .catch(error => {
                            this.showOverlay = false;
                            iziToast.error({
                               title: 'Error',
                               message: 'Error',
                               position: 'topRight'
                           });
                        })
                // }
                }
            }
        },
        updateReviewConfig: function() {
            // if (this.matchTextValExists(this.reviewConfigDetail.entityId, this.reviewConfigDetail.title)) {
            //     iziToast.info({
            //         message: 'Review Configuration already exist.',
            //         position: 'topCenter'
            //     });
            //     return;
            // }
            this.$v.reviewConfigDetail.$touch();
            if (!this.$v.reviewConfigDetail.$invalid) {
                console.log(this.reviewConfigDetail);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/review/reviewconfig/update', this.reviewConfigDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$v.reviewConfigDetail.$reset();
                            this.getReviewConfig();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewProcess: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/review/reviewconfig/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let reviewConfigDetail = result.reviewConfig;
                    if (reviewConfigDetail) {                        
                        this.reviewConfigDetail.id = reviewConfigDetail.id;
                        this.reviewConfigDetail.reviewConfigId = reviewConfigDetail.reviewConfigId;
                        this.reviewConfigDetail.vhextAccountId = reviewConfigDetail.vhextAccountId;
                        this.reviewConfigDetail.projectId = reviewConfigDetail.projectId;
                        this.reviewConfigDetail.entityId = reviewConfigDetail.entityId;
                        this.reviewConfigDetail.functionality = reviewConfigDetail.functionality;
                        this.reviewConfigDetail.reviewById = reviewConfigDetail.reviewById;
                        this.reviewConfigDetail.isApproval = reviewConfigDetail.isApproval;
                        this.reviewConfigDetail.active = reviewConfigDetail.active;
                        this.reviewConfigDetail.createdBy = reviewConfigDetail.createdBy;
                        this.reviewConfigDetail.createdById = reviewConfigDetail.createdById;
                        this.getReviewConfigActivities(this.reviewConfigDetail.entityId)
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        getReviewConfigActivities: function(entityId) {
            // let data = {
            //     vhextAccountId: this.vhextAccountId,
            //     projectId: this.projectId,
            //     refErdId: entityId,
            //     refEntity: 'review_config'
            // }
            // this.showDetOverlay = true;
            // axios.post(this.$store.getters.getAPIBasePath + '/review/reviewconfig/lookup/all',data)
            //     .then((response) => {
            //         this.showDetOverlay = false;
            //         let result = response.data;
            //         if (this.elements.visible && !this.elements.showDetail) {
            //             this.reviewConfig.reviewList = result.processActivityLookup;
            //         }
            //         this.reviewConfigDetail.reviewList = result.projectProcessActivityLookup;
            //     })
            //     .catch(error => {
            //         this.showDetOverlay = false;
            //         iziToast.error({
            //            title: 'Error',
            //            message: 'Error',
            //            position: 'topRight'
            //        });
            //     })
        },
        cancelEditMode: function() {
            this.viewProcess(this.reviewConfigDetail.id);
        },
        deleteProcess: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }

            let deleteUrl;
            if (data.projectId) {
               deleteUrl = '/common/erd/delete';
            }
            else{
                deleteUrl = '/common/prj/delete';
            }
            console.log(data,deleteUrl);            
            // return;
            axios.post(this.$store.getters.getAPIBasePath + deleteUrl , data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReviewConfig();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.reviewConfig.title = '';
            this.reviewConfig.entityId = '';
            this.reviewConfig.createdById = localStorage.getItem("userId");
            this.reviewConfig.description = '';
            this.reviewConfig.active = true;
            this.reviewConfig.reviewList = [];
            this.reviewConfig.approvalList = [];
            this.$v.reviewConfig.$reset();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.reviewConfigDetail.id = data.id;
                this.reviewConfigDetail.reviewConfigId = data.reviewConfigId;
                this.reviewConfigDetail.vhextAccountId = data.vhextAccountId;
                this.reviewConfigDetail.projectId = data.projectId;
                this.reviewConfigDetail.entityId = data.entityId;
                this.reviewConfigDetail.functionality = data.functionality;
                this.reviewConfigDetail.reviewById = data.reviewById;
                this.reviewConfigDetail.isApproval = data.isApproval;
                this.reviewConfigDetail.active = data.active;
                this.reviewConfigDetail.createdBy = data.createdBy;
                this.reviewConfigDetail.createdById = data.createdById;

                this.activityKey += 1;

                this.getReviewConfigActivities(this.reviewConfigDetail.entityId);
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.reviewConfig.entity = 'process_activity';
                this.reviewConfig.id = item.id;
                this.reviewConfig.active = 0;

            } else {
                this.reviewConfig.entity = 'process_activity';
                this.reviewConfig.id = item.id;
                this.reviewConfig.active = 1;
                
            }
            this.updateActivationState();
            console.log(this.reviewConfig.id, this.reviewConfig.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.reviewConfig)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.reviewConfig.active = true;
                    this.getReviewConfig();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        matchTextValExists: function(id, title) {
            let result = false;
            this.reviewConfigList.map(data => {
                if (title) {
                    if ((data.entityId != id) && (data.title == title)) {
                        result = true;
                    }
                }
                else if (data.entityId == id) {
                    result = true;
                }
            });
            return result;
        },
    }
}
</script>
