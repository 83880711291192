<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row task-top-menu">
                        <div class="col-lg-2 col-sm-2 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">Financial Transaction</h4>
                        </div>
                        <div class="col-2 text-center">
                            <div class="row no-gutters">
                                <div class="col-9">
                                    <select v-model="ermObject" @change="ermFilter(ermObject)" class="form-control" id="taskId" :title="ermObject.value" >
                                        <option :value="{'id': '','value': ''}" >All Financial Txn Group</option>
                                        <option v-for="(info, index) in ermLookup" v-bind:value="{id: info.id, value:info.value}">{{info.value}}</option>
                                    </select>
                                </div>
                                <div class="col-3 text-left">
                                    <div class="pt-2">
                                        
                                        <a class="pl-0 action-link mx-1" href="javascript:" v-b-modal.addErmMasterModal title="Add Development Plan"><i class="fa fa-plus-circle" aria-hidden="true"></i></a>
                                        <a href="javascript:" title="Manage Fin Txn Group" class="pl-0 action-link" @click="routeToMasterPage"><i class="fa fa-list" aria-hidden="true"></i></a>
                                    </div>
                                    <!-- <a class="setting-icon-custom2" href="javascript:" @click="routeToMasterPage" title="Manage Event Group"><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2 pl-0 mb-1 ml-0 text-left">
                            <div class="row mt-0 mb-0 text-center px-0">
                                <template v-if="showDateRange">
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeDateRange('prevPage',daysToShow);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeDateRange('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                    </div>
                                    <div class="col-6 px-0">
                                        <h6 class="mt-1" v-if="currentStartDate">
                                            {{ months[currentStartDate.getMonth()].slice(0,3) }}
                                            <span v-if="currentStartDate.getFullYear() !== currentEndDate.getFullYear()">
                                                - {{ currentStartDate.getFullYear() }}
                                            </span>
                                            <span v-if="months[currentStartDate.getMonth()] !== months[currentEndDate.getMonth()]">
                                                / {{ months[currentEndDate.getMonth()].slice(0,3) }}
                                            </span>
                                            - {{ currentEndDate.getFullYear() }}
                                        </h6>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeDateRange('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeDateRange('nextPage',daysToShow);"><i class="fa fa-2x fa-angle-double-right action-link mt-0"></i></a>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeMonthRange('prevPage',daysToShow);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeMonthRange('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                    </div>
                                    <div class="col-6 px-0">
                                        <h6 class="mt-0" v-if="startMonth">
                                            <!-- {{ months[startMonth.getMonth()] }} - {{ startMonth.getFullYear() }} -->
                                            {{ months[startMonth.getMonth()].slice(0,3) }}
                                            <span v-if="startMonth.getFullYear() !== endMonth.getFullYear()">
                                                - {{ startMonth.getFullYear() }}
                                            </span>
                                            <span v-if="months[startMonth.getMonth()] !== months[endMonth.getMonth()]">
                                                / {{ months[endMonth.getMonth()].slice(0,3) }}
                                            </span>
                                            - {{ endMonth.getFullYear() }}
                                        </h6>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeMonthRange('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeMonthRange('nextPage',daysToShow);"><i class="fa fa-2x fa-angle-double-right action-link mt-0"></i></a>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 pr-0 mt-1">
                            <div class="row no-gutters">
                                <div class="col-5 float-left action-item-page">
                                    <template v-if="showDateRange">
                                        <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer mr-1" title="Jump to Date">
                                            <i class="fa fa-calendar text-light"></i>
                                            <div class="dropdown-menu dropdown-primary p-0" aria-labelledby="dropdownCal">
                                                <b-calendar v-model="selectedDate" today-variant="primary" selected-variant="secondary" @context="dateList(selectedDate)">
                                                </b-calendar>
                                            </div>
                                        </span>
                                    </template>
                                    <span @click="daysToShow = 1" :class="daysToShow == 1 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="Today">1</span>
                                    <span @click="daysToShow = 3" :class="daysToShow == 3 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="3 Days">3</span> 
                                    <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">5</span>
                                    <span @click="daysToShow = 7" :class="daysToShow == 7 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer" title="7 Days">7</span>
                                </div>
                                <div class="col-7">
                                    <b-button class="addnew-quicktassk-btn" @click="openFinTxnPopup('', false, false)" variant="outline-secondary" size="xs"><i class="fa fa-plus pr-1"></i>New Transaction</b-button>
                                    <!-- <b-button class="addnew-quicktassk-btn mx-1" @click="$router.push('/landing/aprbrd')" variant="outline-secondary" size="xs" title="Payable/Receivable">P / R</b-button> -->
                                    <b-button class="addnew-quicktassk-btn mx-1" v-b-modal.finTxnMemoryListPopup variant="outline-secondary" size="xs" title="Transactions in Memory">M +</b-button>
                                    <!-- <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">Add</span> -->
                                    <!-- <div class="col-4"> -->
                                    <div class="float-right">
                                        <select v-model="showDateRange" @change="dateList()" class="form-control top-header-btn" >
                                            <option :value="true" >By Day</option>
                                            <option :value="false">By Month</option>
                                        </select>
                                        <!-- <span @click="showDateRange=true;" title="Daily" :class="showDateRange ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-primary badge-pill pointer mr-1">D</span>
                                        <span @click="showDateRange=false;dateList();" title="Monthly" :class="!showDateRange ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-primary badge-pill pointer">M</span> -->
                                    </div>
                                <!-- </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-2 mb-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right pr-1 mr-0" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="routeToMasterPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="mb-0 fs-14">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row px-1">
                    <div v-for="day,index in daysToShow" class="col p-0 ml-2 mr-1">
                        <div v-if="currentDateList.length > 0 && showDateRange" :title="(currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)) ? 'Today' : ''" class="card b-radius-5 pointer" :class="{ 'disabled-weekEnd' : currentDateList[index].getDay() == 0 || currentDateList[index].getDay() == 6, 'active-day' : currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)}" @click="dateList(currentDateList[index])">
                            <div class="card-body p-2">
                                <div class="form-group mb-0">
                                    <label v-if="currentDateList.length > 0" class="mt-1 mb-0 font-weight-semibold float-left fs-12" for="title">{{ currentDateList[index] | getMonthDay }}</label>
                                    <label class="mt-1 mb-0 font-weight-semibold float-right fs-12 pointer" :class="cumulativeProfitByDate(currentDateList[index]) ? getTextVariant(cumulativeProfitByDate(currentDateList[index]).cumulativeProfit) : ''" title="Closing Balance">{{ cumulativeProfitByDate(currentDateList[index]) ? cumulativeProfitByDate(currentDateList[index]).cumulativeProfit.toFixed(2) : '' }}</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="currentDateList.length > 0 && showDateRange == false" :title="(currentDateList[index].getMonth() == new Date().getMonth() && currentDateList[index].getFullYear() == new Date().getFullYear()) ? 'This Month' : ''" class="card b-radius-5 pointer" :class="{ 'active-day' : (currentDateList[index].getMonth() == new Date().getMonth() && currentDateList[index].getFullYear() == new Date().getFullYear())}" @click="dateList(currentDateList[index])">
                            <div class="card-body p-2">
                                <div class="form-group mb-0 text-center">
                                    <label v-if="currentDateList.length > 0" class="mt-1 mb-0 font-weight-semibold float-left" for="title">{{ months[currentDateList[index].getMonth()] }}</label>
                                    <label class="mt-1 mb-0 font-weight-semibold float-right fs-12 pointer" :class="cumulativeProfitByDate(currentDateList[index]) ? getTextVariant(cumulativeProfitByDate(currentDateList[index]).cumulativeProfit) : ''" title="Closing Balance">{{ cumulativeProfitByDate(currentDateList[index]) ? cumulativeProfitByDate(currentDateList[index]).cumulativeProfit.toFixed(2) : '' }}</label>
                                    <!-- <h6 v-if="currentDateList[index]" class="card-text font-weight-semibold mb-0 pull-right">
                                        {{ currentDateList[index].getDate() }}
                                    </h6> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="currentDateList.length > 0" class="card text-right mx-auto py-2 mt-1">
                            <div class="row px-2">                            
                                <div class="col-6 pr-0">
                                    <!-- <label class="float-left m-0">Received</label> -->
                                    <span title="DR" class="float-left text-danger"><i class="fa fa-arrow-down pr-1" aria-hidden="true"></i>{{ currency && currency!== 'null' ? currency : '' }} {{ summaryByDate(currentDateList[index], 'totalExpense') ? summaryByDate(currentDateList[index], 'totalExpense') : 0 }}</span>
                                </div>
                                <div class="col-6 pl-0">
                                    <!-- <label class="float-left m-0">Spent</label> -->
                                    <span title="CR" class="float-right text-success"><i class="fa fa-arrow-up pr-1" aria-hidden="true"></i>{{ currency && currency!== 'null' ? currency : '' }} {{ summaryByDate(currentDateList[index],'totalIncome') ? summaryByDate(currentDateList[index], 'totalIncome') : 0 }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="action-item-vl mt-2">
                            <div v-for="financialTxn in itemsByDate(currentDateList[index])" class="row">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body dropdown show p-0">
                                            <a type="button" class="float-right pt-2 mr-2 ml-1" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-ellipsis-v p-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                <a class="dropdown-item addnew-tassk py-1" @click.prevent="viewItemDetails(financialTxn)" href="javascript:"><i class="fa fa-list-alt pr-2"></i>Transaction Details</a>
                                                <a v-if="!financialTxn.isFreeze && financialTxn.transactionById==userId" class="dropdown-item addnew-tassk py-1" @click.prevent="openFinTxnPopup(financialTxn, true, false)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Transaction</a>
                                                <a v-if="!financialTxn.isFreeze && financialTxn.transactionById==userId" class="dropdown-item py-1" @click.prevent="deleteFinancialTxn(financialTxn.id)" v-b-modal.deleteFinancialTxn href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Transaction</a>
                                                <a v-if="financialTxn.transactionById==userId" class="dropdown-item py-1" @click.prevent="memorizeFinTxn(financialTxn)" href="javascript:"><span class="fs-11 pr-1">M<sup>+</sup></span>{{ financialTxn.isMemorized ? 'Remove from Memory' : 'Add To Memory' }} Transaction</a>
                                                <a v-if="!financialTxn.isFreeze && financialTxn.transactionById==userId" class="dropdown-item py-1" @click.prevent="showFinTxnPayRecLinkPopup(financialTxn)" href="javascript:"><i class="fa fa-plus pr-2"></i>Link {{ financialTxn.amountTypeCode == '1' ? ' Receivable' : ' Payable' }}</a>
                                            </div>
                                            <div class="pt-2 pb-1 px-2">
                                                <span v-if="financialTxn.amountTypeCode == '1'" class="badge badge-success pointer float-right" >
                                                    {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.amount }}
                                                </span>
                                                <span v-else class="badge badge-danger pointer float-right">
                                                    {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.amount }}
                                                </span>
                                                <div class="card-media-body-top">
                                                    <span :title="financialTxn.title" @click="viewItemDetails(financialTxn)" class="pointer">{{ financialTxn.title }}</span>
                                                </div>
                                                <div class="divider-line"></div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left" :class="{'col-sm-4' : daysToShow == 7 }">
                                                                <div v-if="financialTxn.transactionBy">
                                                                    <b-badge v-if="daysToShow > 5" class="task-badge float-left" :title="`Transaction By: ${financialTxn.transactionBy}`"> {{ financialTxn.transactionBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 5" class="task-badge float-left" :title="`Transaction By`"> {{ financialTxn.transactionBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 px-0 text-right" :class="{'col-sm-8' : daysToShow == 7 }">
                                                                <div v-if="(financialTxn.transactionDt != null)">
                                                                    <span class="fs-12" :title="`Transaction Date: ${formatDate(financialTxn.transactionDt)}`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ financialTxn.transactionDt | formatShortDate }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <AddErmMasterPopup ref="addErmMasterModal" :ermMasterInfo="ermMasterInfo" @update-parent="lookupData" />
        <FinanceTxnMemoryListPopup :key="finTxnMemoryPopupKey" :onMemoryItemSelect="openFinTxnPopup" :onMemoryItemRemove="memorizeFinTxn" />
        <FinTxnPayRecLinkPopup v-if="showDialog" :finTxnData="financialTxn" @refreshData="getAllTransactions()" @closeDialog="closeDialog" />
        <DeleteComponent id="deleteFinancialTxn" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Financial Transaction?" />
        <FinTxnDetailPopup ref="finTxnDetailRef" :detailViewData="detailViewData" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" />
        <AddFinTxnPopup v-if="showDialog" :projectTypeId="this.projectTypeId" @refreshData="getAllTransactions();getTransactionSummary();" :finTxnData="financialTxn" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import AddErmMasterPopup from '@/components/popup/action/AddErmMasterPopup.vue';
import FinTxnDetailPopup from '@/components/popup/itemdetail/FinTxnDetailPopup.vue';
import AddFinTxnPopup from '@/components/popup/action/AddFinTxnPopup.vue';
import FinanceTxnMemoryListPopup from '@/components/popup/action/FinanceTxnMemoryListPopup.vue';
import FinTxnPayRecLinkPopup from '@/components/popup/action/FinTxnPayRecLinkPopup.vue';
import moment from 'moment';
export default {
    components: {
        AddErmMasterPopup,
        FinTxnDetailPopup,
        AddFinTxnPopup,
        FinanceTxnMemoryListPopup,
        FinTxnPayRecLinkPopup,
    },
    data() {
        return {
            refPopupTitle: '',
            referencePopupId: 0,
            detailViewData: {},
            showDialog: false,
            showOverlay: false,
            finTxnMemoryPopupKey: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            currency: localStorage.getItem('currency'),
            entityRecordMasterId: '',

            showDateRange: true,
            // daysToShow: 7,
            // selectedDate: null,
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            startMonth: new Date(),
            endMonth: new Date(),
            currentDateList: [],

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            ermObject: {
                id:   '',
                value:   '',
            },

            ermMasterInfo: {
                title: "Financial Transaction Group",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
                tooltip: 'Financial Transaction Group',
                titleLabelName: 'Title',
            },

            financialTxn: {
                editMode: null,
                id: '',
                financialTransactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'financial_transaction',
                accountHeadId: '',
                transactionTypeId: '',
                categoryId: '',
                title: '',
                description: '',
                amount: '',
                transactionDt: '',
                transactionById: '',
                accountablePersonId: '',
                statusId: '',
                activityId: '',
                entityId: '',
            }, 

            attachmentData: {},

            messageBoxData: {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'xs',
                okVariant: 'primary',
                cancelTitle: 'No',
                okTitle: 'Yes',
                headerClass: 'py-2 px-3',
                bodyClass: 'fs-14 px-3',
                footerClass: 'py-2 px-3',
                centered: true,
                hideHeaderClose: false
            },

            finTransactions: [],
            finTxnBoardSummary: [],
            ermLookup: [],

            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        daysToShow: {
            get() {
                return this.$store.state.actionItemData.daysToShow;
            },
            set(value) {
                this.$store.state.actionItemData.daysToShow = value;
            }
        },
        selectedDate: {
            get() {
                return this.$store.state.actionItemData.selectedDate;
            },
            set(value) {
                this.$store.state.actionItemData.selectedDate = value;
            }
        },
    },
    mounted() {
        this.ermObject.id = localStorage.getItem("ermId");
        this.ermObject.value = localStorage.getItem("ermTitle");

        this.dateList(this.selectedDate);
        this.lookupData();
        // this.getAllTransactions();
        // this.getTransactionSummary();

        // To prevent default closing of calendar dropdown
        $('.dropdown-menu').on('click', function(e) {
            e.stopPropagation();
        });
    },
    methods: {
        getAllTransactions: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,           
                entityRecordMasterId: ermId,           
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/list/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.finTransactions = result.financialTransactionList;
                })
                .catch(error => {
                    this.showOverlay = false;
                });

        },
        getTransactionSummary: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,           
                entityRecordMasterId: ermId,           
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnboard/summary', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.finTxnBoardSummary = result.finTxnBoardSummary ? result.finTxnBoardSummary : [];
                    console.log(result);
                })
                .catch(error => {
                    this.showOverlay = false;
                });

        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: 'financial_txn_master',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;

                    let ermLookupFilter = this.ermLookup.find((ermData)=> ermData.id==this.ermObject.id);

                    if(ermLookupFilter){
                        this.ermObject.id = ermLookupFilter.id;
                        this.ermObject.value = ermLookupFilter.value;

                        this.setERMData(this.ermObject.id,this.ermObject.value);
                        this.$nextTick(() => {
                            this.getAllTransactions(this.ermObject.id);
                            this.getTransactionSummary(this.ermObject.id);
                        })
                    } else {
                        this.ermObject.id = '';
                        this.ermObject.value = '';

                        this.setERMData(this.ermObject.id,this.ermObject.value);
                        this.$nextTick(() => {
                            this.getAllTransactions(this.ermObject.id);
                            this.getTransactionSummary(this.ermObject.id);
                        })
                    }

                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        dateList(selectedDate) {
            if (selectedDate) {
                this.currentStartDate = moment(selectedDate).toDate();
                this.startMonth = new Date();
                this.daysToShow = 1;
            }
            else {

                /* to set Sunday as first day */
                // this.currentStartDate = moment().startOf('week').toDate();

                /* to set Monday as first day */
                this.currentStartDate = moment().startOf('isoweek').toDate();
                this.startMonth = new Date();

                /* to set current day as first day */
                // this.currentStartDate = new Date();
            }
            var endDate = new Date();
            var endMonth = new Date();
            endDate.setDate(this.currentStartDate.getDate() + 6);
            endMonth.setMonth(this.startMonth.getMonth() + 6);

            this.currentEndDate = endDate;
            this.endMonth = endMonth;
            if (this.showDateRange) {
                this.currentDateList = this.getDaysArray(this.currentStartDate, endDate);
            }
            else if (this.showDateRange == false) {
                this.currentDateList = this.getDaysArray(this.currentStartDate, endMonth);
            }
        },
        changeDateRange(page, number) {
            var startDate = new Date(this.currentStartDate);
            var endDate = new Date(this.currentEndDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
                endDate.setDate(this.currentEndDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
                endDate.setDate(this.currentEndDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.currentEndDate = endDate;

            this.currentDateList = this.getDaysArray(this.currentStartDate, this.currentEndDate);
        },
        changeMonthRange(page, number) {
            var startMonth = new Date(this.startMonth);
            var endMonth = new Date(this.endMonth);

            if (page === 'nextPage') {
                startMonth.setMonth(this.startMonth.getMonth() + number);
                endMonth.setMonth(this.endMonth.getMonth() + number);
            }
            if (page === 'prevPage') {
                startMonth.setMonth(this.startMonth.getMonth() - number);
                endMonth.setMonth(this.endMonth.getMonth() - number);
            }
            this.startMonth = startMonth;
            this.endMonth = endMonth;

            this.currentDateList = this.getDaysArray(this.startMonth, this.endMonth);
        },
        getDaysArray: function(s, e) {
            if (this.showDateRange) {
                for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
                    a.push(new Date(d));
                }
            }
            else if (this.showDateRange == false) {
                for (var a = [], d = new Date(s); d <= e; d.setMonth(d.getMonth() + 1)) {
                    a.push(new Date(d));
                }
            }
            return a;
        },
        itemsByDate(date) {
            if (this.showDateRange) {
                return this.finTransactions.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
            }
            else {
                return this.finTransactions.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
            }
        },
        summaryByDate(date, amount) {
            let summary;
            if (this.showDateRange) {
                let filteredArray = this.finTxnBoardSummary.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
                summary = filteredArray.reduce((accum, item) => {
                    return accum + (item[amount] ? parseFloat(item[amount]) : 0)
                }, 0.00)
            }
            else {
                let filteredArray = this.finTxnBoardSummary.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
                summary = filteredArray.reduce((accum, item) => {
                    return accum + (item[amount] ? parseFloat(item[amount]) : 0)
                }, 0.00)
            }
            return summary ? summary.toFixed(2) : null;
        },
        cumulativeProfitByDate(date) {
            let summary;
            if (this.showDateRange) {
                let filteredArray = this.finTxnBoardSummary.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
                let sortedArray = filteredArray.sort(function(a,b) {
                    return new Date(b.transactionDt) - new Date(a.transactionDt);
                });
                summary = sortedArray.length>0 ? sortedArray[0] : null;
            }
            else {
                let filteredArray = this.finTxnBoardSummary.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
                let sortedArray = filteredArray.sort(function(a,b) {
                    return new Date(b.transactionDt) - new Date(a.transactionDt);
                });
                summary = sortedArray.length>0 ? sortedArray[0] : null;
            }
            return summary ? summary : null;
        },
        getCreditSum(array) {
            array = array.filter(arr=> {
                if (arr.amountTypeCode == '1') return arr
            });
            if (array && array.length > 0) {
                return array.reduce((acc,item)=> {
                    return acc + item.amount;
                }, 0);
            } else return 0;
        },
        getDebitSum(array) {
            array = array.filter(arr=> {
                if (arr.amountTypeCode == '2') return arr;
            });
            if (array && array.length > 0) {
                return array.reduce((acc,item)=> {
                    return acc + item.amount;
                }, 0);
            } else return 0;
        },
        getTextVariant(value) {
            if (value && Math.sign(value) == -1) {
                return 'text-danger';
            } else return 'text-success';
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = 'financial_transaction';
            this.attachmentData.refRecordId = data.id;
            // this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.finTxnDetailRef.getDetails(this.referencePopupId, null);
            })
        },
        deleteFinancialTxn: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAllTransactions(this.ermObject.id);
                        this.getTransactionSummary(this.ermObject.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        openFinTxnPopup: function(data, editMode, isMemoryPopup) {
            if (editMode == true) {
                this.financialTxn.id = data.id;
                this.financialTxn.financialTransactionId = data.financialTransactionId;
                this.financialTxn.vhextAccountId = data.vhextAccountId;
                this.financialTxn.projectId = data.projectId;
                this.financialTxn.entityRecordMasterId = data.entityRecordMasterId;
                this.financialTxn.entity = data.entity;
                this.financialTxn.accountHeadId = data.accountHeadId;
                this.financialTxn.transactionTypeId = data.transactionTypeId;
                this.financialTxn.categoryId = data.categoryId;
                this.financialTxn.title = data.title;
                this.financialTxn.description = data.description;
                this.financialTxn.amount = data.amount;
                this.financialTxn.transactionDt = data.transactionDt;
                this.financialTxn.transactionById = data.transactionById;
                this.financialTxn.accountablePersonId = data.accountablePersonId;
                this.financialTxn.statusId = data.statusId;
                this.financialTxn.activityId = data.activityId;
                this.financialTxn.entityId = data.entityId;
            } else if(isMemoryPopup) {
                this.financialTxn.id = '';
                this.financialTxn.financialTransactionId = '';
                this.financialTxn.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.financialTxn.projectId = localStorage.getItem("projectId");
                this.financialTxn.entityRecordMasterId = data.entityRecordMasterId;
                this.financialTxn.entity = 'financial_transaction';
                this.financialTxn.accountHeadId = data.accountHeadId;
                this.financialTxn.transactionTypeId = data.transactionTypeId;
                this.financialTxn.categoryId = data.categoryId;
                this.financialTxn.title = data.title;
                this.financialTxn.description = data.description;
                this.financialTxn.amount = data.amount;
                this.financialTxn.transactionDt = this.getDateValue(new Date());
                this.financialTxn.transactionById = localStorage.getItem("userId");
                this.financialTxn.accountablePersonId = data.accountablePersonId;
                this.financialTxn.statusId = '';
                this.financialTxn.activityId = '';
                this.financialTxn.entityId = '';
            } else {
                this.financialTxn.id = '';
                this.financialTxn.financialTransactionId = '';
                this.financialTxn.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.financialTxn.projectId = localStorage.getItem("projectId");
                this.financialTxn.entityRecordMasterId = '';
                this.financialTxn.entity = 'financial_transaction';
                this.financialTxn.accountHeadId = '';
                this.financialTxn.transactionTypeId = '';
                this.financialTxn.categoryId = '';
                this.financialTxn.title = '';
                this.financialTxn.description = '';
                this.financialTxn.amount = '';
                this.financialTxn.transactionDt = this.getDateValue(new Date());
                this.financialTxn.transactionById = localStorage.getItem("userId");
                this.financialTxn.accountablePersonId = localStorage.getItem("userId");
                this.financialTxn.statusId = '';
                this.financialTxn.activityId = '';
                this.financialTxn.entityId = '';
            }
            this.financialTxn.editMode = editMode;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('addFinTxnPopup');
            })
        },
        showFinTxnPayRecLinkPopup(data) {
            this.showDialog = true;
            this.financialTxn = data;
            this.$nextTick(()=> {
                this.$bvModal.show('finTxnPayRecLinkPopup');
            })
        },
        memorizeFinTxn: function(itemData) {
            let data = {
                'id': itemData.id,
                'entity': 'financial_transaction',
                'columnName': 'is_memorized',
                'stateValue': itemData.isMemorized ? false : true,
            }
            var confirmMessage = '';
            if (itemData.isMemorized) {
                confirmMessage = "Are you sure to remove the Transaction from memory?"
            } else {
                confirmMessage = "Are you sure to save the Transaction in memory?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "OK") {
                                iziToast.success({
                                    title: 'Record Updated',
                                    // message: result.message,
                                    position: 'topCenter'
                                });
                                this.getAllTransactions();
                                this.finTxnMemoryPopupKey++;
                            }
                        })
                        .catch((error) => {
                            this.showOverlay = false;
                        })
                }
            })
        },
        ermFilter: function(ermData) {
            this.setERMData(ermData.id,ermData.value);
            this.$nextTick(() => {
                this.getAllTransactions(ermData.id);
                this.getTransactionSummary(ermData.id);
            })
        },
        setERMData: function(ermId,ermTitle) {
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);
        },
        routeToMasterPage() {
            let routePath = this.$route.path;
            console.log(routePath)
            if (routePath.includes('landing')) {
                this.$router.push('/landing/ftxmas/list');
            } else {
                this.$router.push('/ftxmas/list');
            }
        },
        closeDialog: function() {
            this.showDialog = false;
        },
    }
}
</script>