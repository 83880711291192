<template>
    <div class="ermCopyConfirm">
        <b-modal id="ermCopyConfirm" centered ref="ermCopyConfirmRef" size="sm" hide-footer @hide="closeDialog" header-class="py-3" title="Please Confirm" no-close-on-backdrop no-close-on-esc no-enforce-focus>
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div v-if="copyStatus=='Copied'">
                    <p>Please confirm that you want to accept the document copy.</p>
                </div>
                <div v-else-if="copyStatus=='Declined'">
                    <p>Please confirm that you want to reject the document copy.</p>
                </div>
                <div v-else>
                    <p>Please confirm that you want to cancel the document copy.</p>
                </div>
                <div class="separator"></div>
                <div class="text-right mt-3">
                    <button type="button" class="btn sbtn btn-xs btn-outline-secondary mr-1" @click="closeDialog">No</button>
                    <button type="button" class="btn sbtn btn-xs btn-primary" @click="copyDocument">Yes</button>
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            copyStatus: '',
            showOverlay: false,
            ermCopyData: {},
        }
    },
    methods: {
        getERMCopyData: function(actionId, entityRecordId, copyStatus) {
            this.copyStatus = copyStatus;
            this.actionId = actionId;
           // this.error = false;
            this.$bvModal.show('ermCopyConfirm');
            this.showOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/common/ermcopy/view/' + entityRecordId)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.ermCopyData = result.ermCopy;
                })
                .catch(error=>{
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error Occurred',
                        position: 'topRight'
                    });
                })
        },
        copyDocument: function() {
                // ERM copy Status Lookup Ids
                    // 1 - Copy Pending
                    // 2 - Copied
                    // 3 - Declined
                    // 4 - Cancelled
            if (this.copyStatus == 'Copied') {
                this.ermCopyData.statusLookupId = 2;
            } else if (this.copyStatus == 'Declined') {
                this.ermCopyData.statusLookupId = 3;
            } else if (this.copyStatus == 'Cancelled') {
                this.ermCopyData.statusLookupId = 4;
            }
            this.ermCopyData.actionItemId = this.actionId;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/ermcopy/doc/update', this.ermCopyData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.$emit('refreshData');
                        this.closeDialog();
                        iziToast.success({
                            title: 'Copied',
                            message: result.message,
                            position: 'center'
                        });
                    }
                    else if (result.status == "ALREADY_REPORTED") {
                        this.$emit('refreshData');
                        this.closeDialog();
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'center'
                        });
                    }
                })
                .catch(error=>{
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error Occurred',
                        position: 'topRight'
                    });
                })
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.$bvModal.hide('ermCopyConfirm');
        },
    }
}
</script>