<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Non Employee</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" v-b-modal.helpDeskPopup><i class="fa fa-question-circle" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <HumanResource />
        </div>
        <HelpDeskPopup :menuData="menuData" />
    </div>
</template>
<script>
import HumanResource from '@/components/landingcomponents/HumanResourceComp.vue';
import HelpDeskPopup from '@/components/popup/itemdetail/HelpDeskPopup.vue';
export default {
    name: 'app',
    components: {
        HumanResource,
        HelpDeskPopup,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            humanResourceId: null,

        }
    },
    computed: {
        menuData() {
            return this.$store.state.menuData;
        }
    },
    methods: {
        openHelpDesk() {
            axios.get(this.$store.getters.getAPIBasePath + '/menu/standardmenu/view/' + this.standardMenuId)
                .then((response) => {
                    let result = response.data;
                });
        }
    }
}
</script>