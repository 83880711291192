<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <DocShare />
            </div>
        </div>
    </div>
</template>
<script>
import DocShare from '@/views/document/EDocs.vue';
export default {
    components: {
        DocShare
    }
}
</script>