<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" ref="taskAssignmentRefPopupRef" centered @hide="closeDialog" size="lg" modal-class="" title="Task Assignment(s)" ok-only>
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div id="printMe">
                    <div v-if="taskList.length > 0" v-for="task in taskList" class="card mb-2">
                        <div class="card-body p-2">
                            <div class="row w-100 ml-0">
                                <div class="col-12 px-2">
                                    <div class="title-block px-2 py-1">
                                        <div class="form-row">
                                            <div class="col">
                                                <p class="my-1 text-left">
                                                    <span class="font-weight-bold">{{ task.title }}</span>
                                                </p>
                                            </div>
                                            <div class="col-auto">
                                                <p class="my-1 text-center">
                                                    <span class="subtle">{{ task.activity }}</span>
                                                </p>
                                            </div>
                                            <div class="col">
                                                <p class="my-1">
                                                    <label class="badge badge-info float-right m-0" title="Status">{{ task.status }}</label>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator mb-0"></div>
                                </div>
                                <div class="col-12 col-md-8 px-2">
                                    <div v-if="task.description" class="border border-2 p-1">
                                        <span :id="'description-field'+task.id" class="description-truncate-5" @click="toggleDiscriptionView(task.id)" v-html="task.description"></span>
                                        <!-- <a href="javascript:" class="action-link" :id="'see-more-btn'+task.id" @click="toggleDiscriptionView(task.id)" >see more...</a> -->
                                    </div>
                                </div>
                                <div class="col-12 col-md-4 px-2 mt-1">
                                    <div class="form-row mb-1">
                                        <div class="col">
                                            <span class="" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                            <b-progress height="7px" :variant="taskProgressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <span class="font-weight-bold">Est. Start Date</span>
                                        </div>
                                        <div class="col text-right">
                                            <span v-if="task.estimatedStartDt && task.estimatedStartDt !== null">{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <span class="font-weight-bold">Est. End Date</span>
                                        </div>
                                        <div class="col text-right">
                                            <span v-if="task.estimatedEndDt && task.estimatedEndDt !== null">{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div  class="col">
                                            <div class="form-row">
                                                <div class="col">
                                                    <span class="font-weight-bold">Effort Act./Est.</span>
                                                </div>
                                                <div class="col text-right" :class="getTextColour(task.estEffortMins, task.actEffortMins)">
                                                    <span v-if="task.actWorkHour || task.actWorkMinute || task.actWorkDay">
                                                        {{ workDuration(task.actWorkHour,task.actWorkMinute,task.actWorkDay) }}
                                                    </span>
                                                    <span v-if="task.workHour || task.workMinute || task.workDay">
                                                        / {{ workDuration(task.workHour,task.workMinute,task.workDay) }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col">
                                            <span class="font-weight-bold">Cost Act./Est.</span>
                                        </div>
                                        <div class="col text-right" :class="getTextColour(task.estimatedCost, task.actualCost)">
                                            <span v-if="task.actualCost">
                                                {{ currency!='null' ? currency : '' }}{{ task.actualCost }}
                                            </span>
                                            <span v-if="task.estimatedCost">
                                                / {{ currency!='null' ? currency : '' }}{{ task.estimatedCost }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 px-2 mt-1">
                                    <div class="row">
                                        <div class="col-12 col-md">
                                            <span class="subtle">Assigned To: </span><span class="">{{ task.assignedTo }}</span>
                                        </div>
                                        <div class="col-12 col-md text-center">
                                            <span class="subtle">Created: {{ task.createdDt | formatDateTimeAmPm }}</span>
                                        </div>
                                        <div class="col-12 col-md text-right">
                                            <span class="subtle">Last Updated: {{ task.lastUpdatedDt | formatDateTimeAmPm }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="taskList.length==0" class="card">
                        <div class="card-body">
                            <p class="m-5 text-center">No task found</p>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: { type: String, default: 'taskAssignmentRefPopup' },     
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem('vhextAccountId'),
            currency: localStorage.getItem('currency'),
            popupData: {},
            entity: 'task',
            taskList: [],
        }
    },
    methods: {
        getTasks: function(refErmId, refErdId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refErmId: refErmId,
                refErdId: refErdId,
            }
            this.$bvModal.show(this.id);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/assignment/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.taskList = result.taskAssignmentList;
                })
                .catch((error) => {
                    this.showOverlay = false;
                })
        },
        workDuration(workHour, workMinute, workDay) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },
        toggleDiscriptionView(id) {
            const descriprionId = 'description-field' + id;
            const seeMoreId = 'see-more-btn' + id;

            let element = document.getElementById(descriprionId);
            let seeMore = document.getElementById(seeMoreId);
            element.classList.toggle("description-truncate-5");
            seeMore.classList.toggle("d-none");
        },
        getTextColour(estimated, actual) {
            if (estimated && actual) {
                let result = estimated / actual;
                return result < 1 ? 'text-danger' : '';
            }
            else {
                return '';
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },  
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>
<style scoped>
span >>> p {
    margin: 0;
}
.border-2 {
    border-width: 2px !important;
}
</style>