<template>
    <div>
        <CostEsimationMasterList :ermMasterInfo="ermMasterInfo"></CostEsimationMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import CostEsimationMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        CostEsimationMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routhPath: '',
            ermMasterInfo: {
                title: "Cost Esimation List",
                entity: 'cost_estimation_master',
                refEntity: 'cost_estimation',
                tooltip: 'New Cost Esimation',
                redirectURLName: '',
                newEntityMasterURL: '',
                redirectURL: '',
                actionName: '',
                previewURLName: ''
            },
        }
    },
    mounted() {
          this.routhPath = this.$route.path;

        if (this.routhPath != null && this.routhPath.indexOf('service') == -1) {
            this.ermMasterInfo.redirectURLName = 'costestimation';
            this.ermMasterInfo.newEntityMasterURL = 'coemas';
            this.ermMasterInfo.redirectURL = '/coemas';
            this.ermMasterInfo.actionName = 'Cost Esimation';
            this.ermMasterInfo.previewURLName = 'coerep';
        } else {
            this.ermMasterInfo.redirectURLName = 'srvcostestimation';
            this.ermMasterInfo.newEntityMasterURL = 'srvcoemas';
            this.ermMasterInfo.redirectURL = '/service/coemas';
            this.ermMasterInfo.actionName = 'Cost Esimation';
            this.ermMasterInfo.previewURLName = 'srvcoerep';
        }
    },
    methods: {}
}
</script>