<template>
    <div>
        <b-input-group>
            <b-input-group-prepend class="custom-input-group-prepend">
                <span class="input-group-text">{{ currencySymbol }}
                    <!-- <i v-bind:class="[ (prependText === 'INR') ? 'fa fa-inr' : 'fa fa-usd']"></i> -->
                </span>
            </b-input-group-prepend>
            <b-form-input :id="id" type="number" onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '')" step="0.01" min="0" :value="value" @input="handleInput" :disabled="disabled" :placeholder="placeholderText">
            </b-form-input>
            <b-input-group-append class="custom-input-group-append">
                <span class="input-group-text">
                    .00
                </span>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        placeholderText: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: Number,
        id: { type: String, default: 'vhext-decimal-money-input' },
    },
    computed: {
        prependText: function() {
            return localStorage.getItem("currency") == "null" ? 'INR' : localStorage.getItem("currency")
        }
    },
    data() {
        return {
            projectId: localStorage.getItem("projectId"),
            currencySymbol: localStorage.getItem("currency"),
        }
    },
    validations: {},
    mounted() {},
    methods: {
        handleInput(e) {
            this.$emit('money-input', e)
        }
    },
    // this prevents from typing non-number text, including "e".
    isNumber(evt) {
        evt = (evt) ? evt : window.event;
        let charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
    }

}
</script>