<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">VMX Account Management</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row view-filter">
<!--                                     <div class="col-sm-12"> -->
                                        <div class="col-2 text-left">
                                            <div class="dataTables_filter">
                                                <label class="d-block p-1 accordion-title">VMX Accounts
                                                </label>
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>

<!--                                         <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div> -->
                                        <div class="col-4 float-right">
                                            <b-button class="addnew-quicktassk-btn float-right pt-1" @click="" v-b-modal.vmxRegistrationModal variant="outline-secondary" size="sm" title="Add Vhext Account"><i class="fa fa-plus-circle pr-1"></i>New Vhext Account</b-button>
                                        </div>
                                        <div class="clearfix"></div>
<!--                                     </div> -->
                                </div>
                                <b-table ref="vhextAccountTable" v-model="mainTableArray" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="vhextAccountList"  @row-selected="onRowSelected" :fields="fields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:cell(primaryUser)="data">
                                        <span v-if="data.item.primaryUserStatusId == 1" title="Active User" class="float-right pointer mr-2"><i class="fa fa-user fa-lg text-success" aria-hidden="true"></i></span>
                                        <span v-if="data.item.primaryUserStatusId == 2" title="User Inactive" class="float-right pointer mr-2"><i class="fa fa-user-times fa-lg" aria-hidden="true"></i></span>
                                        <span v-if="data.item.primaryUserStatusId == 3 || data.item.primaryUserStatusId == 4" title="Temporarily Deactivated" class="float-right pointer mr-2"><i class="fa fa-user fa-lg text-muted" aria-hidden="true"></i></span>
                                        <span v-if="data.item.primaryUserStatusId == 5" title="User Blocked" class="float-right pointer mr-2"><i class="fa fa-user fa-lg text-danger" aria-hidden="true"></i></span>
                                        {{data.item.primaryUser}}                                        
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(actions)="data">
<!--                                         <a href="javascript:" title="Edit VMXAccount" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a> -->                                        
                                        <a href="javascript:" title="Reset VMX Account" v-b-modal.vmxResetDialog @click="vmxResetDialog(data.item.id)"><i class="fa fa-refresh fa-custom fa-lg"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteVMXAccount(data.item.id)" v-b-modal.deleteVMXAccount><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No VMX Accounts found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
<!--                                 <b-pagination v-if="vhextAccountList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage"     first-number last-number align="fill" size="sm" class="my-0 pull-right">
                                </b-pagination> -->
                                <div class="float-left">
                                    <div class="dataTables_filter">
                                        <label class="font-weight-bold" >Total Items : {{vhextAccountList.length}}</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                        <div class="card h-100">
                            <div class="card-body p-3">
                                <b-overlay :show="showDetOverlay" opacity="0.4" spinner-variant="primary">
                                    <b-card no-body>
                                        <b-collapse v-model="addUserTypeVisible" class="p-1" role="tabpanel">
                                            <div class="row mb-2 p-2">
                                                <div class="col-12">
                                                    <label for="userTypeId">Add User Type</label>
                                                </div>
                                                <div class="col-7">
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.customUserTypeModule.userTypeId.$error }">
                                                        <select v-model="customUserTypeModule.userTypeId" class="form-control" :class="{ 'is-invalid': $v.customUserTypeModule.userTypeId.$error }" id="userTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id">{{info.userType}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.customUserTypeModule.userTypeId.$error && !$v.customUserTypeModule.userTypeId.required" class="text-danger">Please select a type</label>
                                                </div>
                                                <div class="col text-right">
                                                    <button type="submit" @click.prevent="saveCustomUserType" class="btn btn-primary py-1 px-3 mr-1"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearUserTypeData" class="btn btn-outline-danger py-1 px-3"><i class="fa fa-times"></i></button>
                                                </div>
                                            </div>
                                        </b-collapse>
                                    </b-card>
                                    <span v-if="activeTabIndex==1" class="position-absolute mt-2 ml-auto" style="right: 1rem;">
                                        <button v-if="!addUserTypeVisible" type="submit" @click="addUserTypeVisible=true" @click.prevent="" class="btn btn-xs btn-outline-primary py-1 px-3 float-right"><i class="fa fa-plus-circle pr-2"></i>Add User Type</button>
                                    </span>
                                    <b-tabs v-if="!elements.visible && elements.showDetail" v-model="activeTabIndex" content-class="mt-3" lazy>
                                        <b-tab title="Primary Account Info">
                                            <div class="row input-group-label">
                                                <div class="col-5">
                                                    <b-input-group prepend="Id" class="text mb-3">
                                                        <b-input-group-prepend is-text><b>{{vhextAccountDetail.vhextAccountId}}</b></b-input-group-prepend>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                    <div class="more-btns text-center">
                                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <div v-if="elements.editMode">
                                                            <button type="submit" @click="updateVMXAccount" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="profile_custom">
                                                <p class="group-header-title">
                                                    {{vhextAccountDetail.company}}
                                                    <span v-if="vhextAccountDetail.isParentCompany" class="badge badge-info float-right">
                                                        Parent Company
                                                    </span>
                                                </p>
                                                <h6 class="pl-1 mt-2 mb-3">VMX Account</h6>
                                                 <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Account Name</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.accountName}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.accountName">                                                        

                                                    </b-col>
                                                </b-row>
                                                 <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Account Type</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.vhextAccountType}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.vhextAccountType">
                                                    </b-col>
                                                </b-row>
                                                <h6 class="pl-1 mt-2 mb-3">Primary User Account</h6>
                                                <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">User Name</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.primaryUser}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.primaryUser">
                                                    </b-col>
                                                </b-row>
                                                 <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">User Login Name</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.emailId}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.emailId">
                                                    </b-col>
                                                </b-row>
                                                <h6 class="pl-1 mt-2 mb-3">Service Info</h6>
                                                <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Service Plan</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.vhextServicePlanId}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.vhextServicePlanId">
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Start Date</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.startDate}}</label>
                                                        <input v-if="elements.editMode" type="date" class="form-control" v-model="vhextAccountDetail.startDate">
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Expiry Date</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.expiryDate}}</label>
                                                        <input v-if="elements.editMode" type="date" class="form-control" v-model="vhextAccountDetail.expiryDate">
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Active</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.active}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.active">
                                                    </b-col>
                                                </b-row>
                                                <b-row class="mb-2 pl-4">
                                                    <b-col cols="4">Status</b-col>
                                                    <b-col cols="8">
                                                        <label v-if="!elements.editMode"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{vhextAccountDetail.statusId}}</label>
                                                        <input v-if="elements.editMode" type="text" class="form-control" v-model="vhextAccountDetail.statusId">
                                                    </b-col>
                                                </b-row>                                                                                                
                                            </div>
                                            <div class="divider"></div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                    <label>More Details</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="createdBy">Created By</label>
                                                                                <input type="text" class="form-control disabled" id="createdBy" v-model='vhextAccountDetail.userName' readonly>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label for="startDate">Created Date</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control disabled" :value="vhextAccountDetail.createdDt | formatDate" readonly>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label for="endDate">Last Updated Date</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control disabled" :value="vhextAccountDetail.lastUpdatedDt | formatDate" readonly>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>
                                        <b-tab title="User Types & Modules">
                                            <template v-for="userType,index in customUserTypeList">
                                                <div class="row mb-1">
                                                    <div class="col-auto py-1">
                                                        <label class="title-highlight my-1">{{ userType.userType }}</label>
                                                        <button v-if="!userType.isPrimary" type="submit" v-b-modal.removeUserType @click="removeUserType(userType.userTypeId)" class="sbtn btn btn-xs btn-outline-danger py-1 px-2 ml-2"><i class="fa fa-times pr-2"></i>Remove User Type</button>
                                                    </div>
                                                    <div class="col">
                                                        <button type="submit" @click.prevent="showAddModal(userType)" class="sbtn btn btn-xs btn-outline-primary py-1 px-2 float-right"><i class="fa fa-plus-circle pr-2"></i>Manage Modules</button>
                                                    </div>
                                                </div>
                                                <b-card no-body class="mb-2">
                                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                        <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle="'accordion-' + userType.userTypeId">
                                                            Modules
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse :id="'accordion-' + userType.userTypeId" accordion="my-accordion" class="p-1" role="tabpanel">
                                                        <b-table show-empty small outlined stacked="md" sort-icon-left :items="stdUserTypeModuleList(userType.userTypeId)" :fields="moduleFields" responsive="sm" >
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(actions)="data">
                                                                <a v-if="!data.item.isBasic" href="javascript:" title="Remove" @click="cmpCustomModuleManage(userType.userTypeId, data.item.moduleId, 0)" ><i class="fa fa-minus-circle text-danger"></i></a>
                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">Add Service Modules to show</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </b-collapse>
                                                </b-card>
                                                <div v-if="customUserTypeList.length-1 > index" class="separator my-3"></div>
                                            </template>
                                        </b-tab>
                                    </b-tabs>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <b-modal title="Manage Modules" id="addModuleModal" size="xl" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <div class="row">
                        <div class="col-6">
                            <label>Service Modules</label>
                            <b-table show-empty small outlined stacked="md" :items="filteredUserTypeModules" :fields="moduleFields" responsive="sm" >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Add" @click="cmpCustomModuleManage(userTypeId, data.item.moduleId, 1)">
                                        <i class="fa fa-plus-circle text-success"></i>
                                    </a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Record found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                        <div class="col-6">
                            <label>Added Modules</label>
                            <b-table show-empty small outlined stacked="md" :items="stdUserTypeModuleList(userTypeId)" :fields="moduleFields" responsive="sm" >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a v-if="!data.item.isBasic" href="javascript:" title="Remove" @click="cmpCustomModuleManage(userTypeId, data.item.moduleId, 0)">
                                        <i class="fa fa-minus-circle text-danger"></i>
                                    </a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Record found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </b-container>
            </b-overlay>
        </b-modal>
        <VmxAccountRegistrationPopup ref="vmxRegistrationModal" @refreshData="getVMXAccounts()" />
        <DeleteComponent id="deleteVMXAccount" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete VMX Account ?" />
        <DialogComponent id="vmxResetDialog" :onYes="vmxResetDialogConfirmation" :returnParams="dialogBoxParam" title="Company Reset Confirmation" message="Are you sure to Reset the Company's Menus & Modules to default?" />
        <DialogComponent id="removeUserType" :onYes="removeUserTypeConfirmation" :returnParams="dialogBoxParam" title="Delete Confirmation" message="Are you sure to Remove User Type?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
import VmxAccountRegistrationPopup from '@/components/popup/action/VmxAccountRegistrationPopup.vue';

export default {
    name: 'app',
    components: {
        VmxAccountRegistrationPopup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
            },
            addUserTypeVisible: false,
            isBusy: false,
            showOverlay: false,
            showDetOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            customUserRoleId: '',
            userTypeId: '',
            activeTabIndex: 0,

            mainTableArray: [],

            accountTypes: [],
            accountStatus: [],
            users: [],
            vhextAccount: {
                vhextAccountId: '',
                accountName: '',
                vhextAccountTypeId: '',
                vhextAccountType: '',
                vhextServicePlanId: '',
                parentVhextAccountId: '',
                isParentCompany: '',
                userId: '',
                primaryUser: '',
                emailId: '',
                statusId: '',
                createdById: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                active: '',
                startDate: '',
                expiryDate: '',
                isMobileActivation: 0,
            },
            vhextAccountDetail: {
                vhextAccountId: '',
                accountName: '',
                vhextAccountTypeId: '',
                vhextAccountType: '',
                vhextServicePlanId: '',
                parentVhextAccountId: '',
                isParentCompany: '',
                userId: '',
                primaryUser: '',
                emailId: '',
                statusId: '',
                statusId: '',
                createdById: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                active: '',
                startDate: '',
                expiryDate: '',
                isMobileActivation: 0,
            },

            customUserTypeModule: {
                userTypeId: '',
            },
            notEnabled: true,
            users: [],
            customModuleList: [],
            customUserTypeList: [],
            userTypeModules: [],
            vhextAccountList: [],
            vhextAccounts: [],
            vmxAccountUserTypeLookup: [],
            fields: [
                { key: 'id', label: 'PK Id', sortable: true, sortDirection: 'desc' },
                { key: 'vhextAccountId', label: 'Account Id', sortable: true, sortDirection: 'desc' },
                { key: 'accountName', label: 'Account Name', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Account Type', sortable: true, sortDirection: 'desc' },
                { key: 'primaryUser', label: 'Primary User', sortable: true, sortDirection: 'desc' },
                { key: 'loginName', label: 'Login Email', sortable: true, sortDirection: 'desc' },
                { key: 'parentVhextAccountName', label: 'Parent Account', sortable: true, sortDirection: 'desc' },
                // { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                // { key: 'expiryDate', label: 'Expiry Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                
                // { key: 'primaryUserStatus', label: 'Pri. User Status', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            moduleFields: [
                { key: 'index', label: 'SN' },
                { key: 'vmxService', label: 'VMX Service' },
                { key: 'module', label: 'Module' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20,25,50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
            dialogBoxParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredUserTypeModules() {
            if (this.customModuleList && this.customModuleList.length > 0) {
                return this.userTypeModules.filter(data => {
                  const moduleIdExists = this.customModuleList.some(mod => mod.moduleId == data.moduleId && mod.userTypeId == data.userTypeId);
                  return !moduleIdExists;
                });
            }
            else {
                return this.userTypeModules;
            }
        },
        vmxAccountUserTypes() {
            if (this.customUserTypeList && this.customUserTypeList.length > 0) {
                return this.vmxAccountUserTypeLookup.filter(data => {
                  const userTypeExists = this.customUserTypeList.some(item => item.userTypeId === data.id);
                  return !userTypeExists;
                });
            }
            else {
                return this.vmxAccountUserTypeLookup;
            }
        }
    },
    mounted() {
        this.lookupData();
        this.getVMXAccounts();
    },
    validations: {
        vhextAccount: {
            accountName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        },
        customUserTypeModule: {
            userTypeId: {
                required
            }
        },
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = 'vhext_account';
            this.userId = localStorage.getItem("userId");
        },

        getVMXAccounts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.vhextAccountlist && result.vhextAccountlist.length > 0) {
                        this.vhextAccountList = result.vhextAccountlist;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.vhextAccountDetail.id, this.mainTableArray, 'vhextAccountTable');
                        })
                        this.totalRows = this.vhextAccountList.length;
                        
                        this.$emit('updateVMXAccountCount', this.totalRows);
                    }
                    this.clearData();
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                statusMasterId: 1,
                vhextAccountId: 1,
                projectId: 1,
                categoryMasterId: 1,
                typeMasterId: 8
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.accountTypes = result.vhextAccountTypeLookup;
                    this.accountStatus = result.statusLookup;
                    this.users = result.userLookup;
                })

        },
        saveData: function() {
            this.vhextAccount.vhextAccountId=this.vhextAccountId;
            this.vhextAccount.projectId =this.projectId;
            this.vhextAccount.createdById =this.userId;

            this.$v.vhextAccount.$touch();
            if (!this.$v.vhextAccount.$invalid) {                  
                this.saveVMXAccount();
            }
        },
        saveVMXAccount: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/add', this.vhextAccount)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getVMXAccounts();
                    }
                })
        },
        updateVMXAccount: function() {
            this.vhextAccountDetail.vhextAccountId = this.vhextAccountId;
            this.vhextAccountDetail.projectId = this.projectId;

            axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/update', this.vhextAccountDetail)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getVMXAccounts();
                    }
                })
        },
        viewVMXAccount: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/vhext/account/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let vhextAccountDetail = result.vhextAccount;
                    if (vhextAccountDetail) {                        
                        this.vhextAccountDetail.id = vhextAccountDetail.id;
                        this.vhextAccountDetail.vhextAccountId = vhextAccountDetail.vhextAccountId;
                        this.vhextAccountDetail.parentVhextAccountId = vhextAccountDetail.parentVhextAccountId;
                        this.vhextAccountDetail.isParentCompany = vhextAccountDetail.isParentCompany;
                        this.vhextAccountDetail.accountName = vhextAccountDetail.accountName;
                        this.vhextAccountDetail.primaryUser = vhextAccountDetail.primaryUser;
                        this.vhextAccountDetail.emailId = vhextAccountDetail.emailId;
                        this.vhextAccountDetail.vhextAccountTypeId = vhextAccountDetail.vhextAccountTypeId;
                        this.vhextAccountDetail.vhextAccountType = vhextAccountDetail.type;
                        this.vhextAccountDetail.vhextServicePlanId = vhextAccountDetail.vhextServicePlanId;
                        this.vhextAccountDetail.statusId = vhextAccountDetail.statusId;
                        this.vhextAccountDetail.createdDt = vhextAccountDetail.createdDt;
                        this.vhextAccountDetail.lastUpdatedDt = vhextAccountDetail.lastUpdatedDt;
                        this.vhextAccountDetail.startDate = vhextAccountDetail.startDate;
                        this.vhextAccountDetail.expiryDate = vhextAccountDetail.expiryDate;
                    }
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewVMXAccount(this.vhextAccountDetail.id);
        },
        deleteVMXAccount: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/vhext/account/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getVMXAccounts();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        clearData: function() {
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.vhextServicePlanId = '';
            this.vhextAccount.startDate = '';
            this.vhextAccount.expiryDate = '';
            this.vhextAccount.statusId = '';
            this.vhextAccount.active = '';
            this.vhextAccount.id = '';
            this.$v.vhextAccount.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.vhextAccountDetail.vhextAccountId = data.vhextAccountId;
            this.vhextAccountDetail.parentVhextAccountId = data.parentVhextAccountId;
            this.vhextAccountDetail.isParentCompany = data.isParentCompany;
            this.vhextAccountDetail.accountName = data.accountName;
            this.vhextAccountDetail.vhextAccountTypeId = data.vhextAccountTypeId;
            this.vhextAccountDetail.vhextServicePlanId = data.vhextServicePlanId;
            this.vhextAccountDetail.statusId = data.statusId;
            this.vhextAccountDetail.createdDt = data.createdDt;
            this.vhextAccountDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.vhextAccountDetail.active = data.active;
            this.vhextAccountDetail.startDate = data.startDate;
            this.vhextAccountDetail.expiryDate = data.expiryDate;
            this.vhextAccountDetail.id = data.id;
        },
        vmxResetDialog: function(id) {
            this.dialogBoxParam.id = id;
        },
        vmxResetDialogConfirmation: function(id) {
            this.vhextAccountReset(this.dialogBoxParam.id);
        },
        vhextAccountReset: function(vhextAccountId) {
            let data = {
                vhextAccountId: vhextAccountId,
                createdById: this.userId,
            }
            console.log(data)
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/reset', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getVMXAccounts();
                        if (this.vhextAccountId == vhextAccountId) {
                            EventBus.$emit('onTopMenuReload');
                        }
                    }
                })
        },

        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.vhextAccountDetail.vhextAccountId = data.vhextAccountId;
                this.vhextAccountDetail.parentVhextAccountId = data.parentVhextAccountId;
                this.vhextAccountDetail.isParentCompany = data.isParentCompany;
                this.vhextAccountDetail.accountName = data.accountName;
                this.vhextAccountDetail.vhextAccountTypeId = data.vhextAccountTypeId;
                this.vhextAccountDetail.vhextAccountType = data.type;
                this.vhextAccountDetail.vhextServicePlanId = data.vhextServicePlanId;
                this.vhextAccountDetail.userId = data.userId;
                this.vhextAccountDetail.primaryUser = data.primaryUser;
                this.vhextAccountDetail.emailId = data.emailId;
                this.vhextAccountDetail.statusId = data.statusId;
                this.vhextAccountDetail.createdById = data.createdById;
                this.vhextAccountDetail.createdBy = data.createdBy;
                this.vhextAccountDetail.createdDt = data.createdDt;
                this.vhextAccountDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.vhextAccountDetail.active = data.active;
                this.vhextAccountDetail.startDate = data.startDate;
                this.vhextAccountDetail.expiryDate = data.expiryDate;

                this.vhextAccountDetail.id = data.id;

                this.getCustomUserTypeModules();
                this.getVhextUserTypes(this.vhextAccountDetail.vhextAccountTypeId)
            }
        },
        getVhextUserTypes: function(id) {
            let data = {
                vhextAccountTypeId: id
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.vmxAccountUserTypeLookup = result.vmxAccountUserTypeLookup;
                })
                .catch((error) => {
                    this.showDetOverlay = false;
                })

        },
        stdUserTypeModuleList(userTypeId) {
            return this.customModuleList.filter(data => data.userTypeId == userTypeId);
        },
        getCustomUserTypeModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountDetail.id,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customusertypemodule/list',data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.customModuleList = result.customModuleList;
                    this.customUserTypeList = result.customUserTypeList;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        getUserTypeModules: function(userTypeId) {
            let data = {
                userTypeId: userTypeId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/stdusermodule/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.userTypeModules = result.stdUserModuleList;
                })  
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        cmpCustomModuleManage(userTypeId, moduleId, actionState) {

            let data = {
                vhextAccountId: this.vhextAccountDetail.id,
                userTypeId: userTypeId,
                moduleId: moduleId,
                actionState: actionState,
                createdById: this.userId,
            }

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/custommodule/manage', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "OK") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.getCustomUserTypeModules();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        removeUserType: function(id) {
            this.dialogBoxParam.id = id;
        },
        removeUserTypeConfirmation: function(id) {
            let actionState = 0;    // 0 for delete action
            this.manageCustomUserTypeModule(this.dialogBoxParam.id, actionState);
        },
        saveCustomUserType: function() {
            let actionState = 1;    // 1 for insert action

            this.$v.customUserTypeModule.$touch();
            if (!this.$v.customUserTypeModule.$invalid) {                  
                this.manageCustomUserTypeModule(this.customUserTypeModule.userTypeId, actionState);
            }
        },
        manageCustomUserTypeModule: function(userTypeId, actionState) {
            let data = {
                vhextAccountId: this.vhextAccountDetail.id,
                userTypeId: userTypeId,
                actionState: actionState,
                createdById: this.userId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customusertypemodule/manage', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearUserTypeData()
                        this.getCustomUserTypeModules();
                        this.getVhextUserTypes(this.vhextAccountDetail.vhextAccountTypeId)
                    }
                })
        },
        clearUserTypeData() {
            this.addUserTypeVisible = false;
            this.customUserTypeModule.userTypeId = '';
            this.$v.customUserTypeModule.$reset();
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.vhextAccount.entity = this.entity;
                this.vhextAccount.id = item.id;
                this.vhextAccount.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.vhextAccount.entity = this.entity;
                this.vhextAccount.id = item.id;
                this.vhextAccount.active = 1;

                this.updateActivationState();
            }
            console.log(this.vhextAccount.id, this.vhextAccount.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.vhextAccount)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.vhextAccount.active = true;
                    this.getProcess();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        showAddModal(userTypeData) {
            this.customUserRoleId = userTypeData.customUserRoleId;
            this.userTypeId = userTypeData.userTypeId;
            this.getUserTypeModules(userTypeData.userTypeId);
            this.$bvModal.show('addModuleModal');
        },
    }
}
</script>