<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Task Financial Tracking</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-2">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <span v-if="ettTabIndex==0" class="position-absolute mt-2 ml-auto" style="right: 350px;">
                                    <b-button class="float-right addnew-quicktassk-btn mr-2" @click="quickTask()" variant="outline-info" size="sm" v-b-modal.ermAssignments><i class="fa fa-plus"></i> Quick Task</b-button>
                                </span>
                                <div class="tab-floating-text mt-2">
                                    <b-button  class="addnew-tassk-btn sbtn float-right ml-2" variant="outline-primary" v-b-toggle.accordion-ett size="xs" :class="{'active' : elements.ettVisible}" :title="(elements.ettVisible ? 'Hide' : 'Show') + ' Filter'"><i class="fa fa-filter pr-2" aria-hidden="true"></i>{{ elements.ettVisible ? 'Hide' : 'Show'}}</b-button>
                                    <span v-if="ettTabIndex==0" class="title-highlight">
                                        <span class="float-right">
                                            <label class="mr-2 my-0">Date: </label>
                                            <label class="my-0">{{ formatDate(task.filterStartDate) }} <span class="px-2">to</span> {{ formatDate(task.filterEndDate) }} </label>
                                        </span>
                                    </span>
                                </div>
                                <b-tabs content-class="mt-3" v-model="ettTabIndex" lazy>
                                    <b-tab title="Tasks">
                                        <b-card no-body class="mb-1">
                                            <b-collapse id="accordion-ett" v-model="elements.ettVisible" accordion="my-accordion"  role="tabpanel">
                                                <b-card-body class="p-2">
                                                    <div class="row view-filter">
                                                        <div class="col-3 col-md-3 float-left">
                                                            <div class="dataTables_filter">
                                                                <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                                                <input type="search" v-model="filter" class="form-control pl-4" placeholder="Search..." id="searchFilter">
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            
                                                        </div>
                                                        <div class="col-8 col-md-8 float-right pl-0 pb-1">
                                                            <div class="row">
                                                                <div class="col py-1 pr-0 text-right">
                                                                    Date Range
                                                                </div>
                                                                <div class="col-3 pr-0">
                                                                    <div class="">
                                                                        <input type="date" class="form-control" v-model="task.startDate">
                                                                    </div>
                                                                </div>
                                                                <div class="col-1 py-1 px-0 text-center">
                                                                    to
                                                                </div>
                                                                <div class="col-3 pl-0">
                                                                    <div class="">
                                                                        <input type="date" class="form-control" v-model="task.endDate">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 col-lg-3 col-xl-2 pl-0">
                                                                    <div class="">
                                                                        <div >
                                                                            <button type="submit" @click="getTaskTrackingList" class="sbtn btn btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                                            <a href="javascript:" title="Reset Filter" @click="resetFilter"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div id="scope-app" class="card p-2 mb-3">
                                            <div class="row">
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Total Task</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ totalRowsTask }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Est. Cost</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ totalEstCost }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Actual Cost</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ totalActualCost }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Financial Track</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="px-2 py-1 mb-0 d-block header-details text-center">
                                                                <span class="" title="Financial Track">{{ costPercentage }}%</span>
                                                                <b-progress height="7px" :value="costPercentage" :variant="progressBarVariant(costPercentage)"></b-progress>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="taskFinancialList" :fields="taskFields" responsive="sm" :current-page="currentPageTask" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredTask">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template> 
                                            <template v-slot:cell(title)="data">
                                                <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(estimatedCost)="data">
                                                <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                    <b-input-group>
                                                        <b-input-group-prepend class="custom-input-group-prepend">
                                                            <span class="input-group-text">{{currencySymbol}}
                                                            </span>
                                                        </b-input-group-prepend>
                                                        <b-form-input :id="data.field.key+data.item.id" type="number" step="0.01" min="0" v-model="data.value" >
                                                        </b-form-input>
                                                    </b-input-group>
                                                    <div class="text-right">
                                                        <span class="more-btns">
                                                            <button type="submit" @click="updateInlineTextValue('estimated_cost', data.item.id, data.value)" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelInlineEdit(taskFinancialList, data.field.key)" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <template v-else>
                                                    <p v-if="data.value" @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)">{{ data.value }}</p>
                                                    <div v-else @click="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link">Set Est. Cost</div>
                                                </template>
                                            </template>
                                            <template v-slot:cell(progressPercentage)="data">
                                                <label class="m-0 d-flex justify-content-between" title="Performance">
                                                    <span>{{ getPerformanceRemark(calculateCostPercentage(data.item.estimatedCost,data.item.actualCost)) }}</span>
                                                    <span class="float-right">{{ calculateCostPercentage(data.item.estimatedCost,data.item.actualCost) }}%</span>
                                                </label>
                                                <b-progress height="7px" :value="calculateCostPercentage(data.item.estimatedCost,data.item.actualCost)" :variant="progressBarVariant(calculateCostPercentage(data.item.estimatedCost,data.item.actualCost))"></b-progress>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Task found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsTask}} Tasks</span>

                                        <b-pagination v-if="taskFinancialList.length!=0" pills v-model="currentPageTask" :total-rows="totalRowsTask" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="taskData" @refresh-task="getTabDetails()" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :attachmentData="attachmentData"  @closeDialog="showDialog=false;" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    name: 'app',
    components: {
        QuickTaskPopup,
        TaskDetailPopup,
        VhextMoneyControl,
    },
    data() {
        return {
            showDetOverlay: false,
            showDialog: false,
            elements: {
                ettVisible: false,
            },
            ettTabIndex: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"), 

            attachmentData: {},

            taskData: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'task',
                title: '',
                isArchive: false,
                activityId: '',
                activity: '',
                description: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                progressPercentage: 0,
                statusId: '',
                status: '',
                entityId: '',
                refErmId: '',
                checkListTag: '',
                response: '',
                checklistList: [],
                checklistItemCount: '',
            },

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            task: {
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                filterStartDate: this.getDateValue(new Date()),
                filterEndDate: this.getDateValue(new Date()),
            },
            taskFinancialList: [],

            taskStatus: [],

            taskFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'assignedTo', label: 'Assigned To', sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Est. Cost',  sortDirection: 'desc' },
                { key: 'actualCost', label: 'Actual Cost',  sortDirection: 'desc' },
                { key: 'progressPercentage', label: 'Financial Track', sortDirection: 'desc' },
                { key: 'status', label: 'Task Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },

            ],

            selectMode: 'single',

            totalRowsTask: 1,
            currentPageTask: 1,

            perPageRes: 10,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filter: null,
            filterOn: [],
        }
    },
    watch: {
        ettTabIndex() {
            this.getTabDetails();
            this.inlineEditRowId ='';
        }
    },
    computed: {
        totalActualCost() {
            return this.taskFinancialList.reduce((accum, item) => {
                return accum + parseFloat(item.actualCost ? item.actualCost : 0)
            }, 0.00)
        },
        totalEstCost() {
            return this.taskFinancialList.reduce((accum, item) => {
                return accum + parseFloat(item.estimatedCost ? item.estimatedCost : 0)
            }, 0.00)
        },
        costPercentage() {
            return this.totalEstCost ? Math.round(( this.totalActualCost / this.totalEstCost) * 100) : 0;
        },
        taskPerformancePercent() {
            let totalEffortTaskPer = this.totalEffortTaskPer*60
            return this.totalActualEffortTaskPer ? Math.round((totalEffortTaskPer / this.totalActualEffortTaskPer) * 100) : 0;
        }
    },
    mounted() {
        this.getTaskTrackingList();
    },
    methods: {
        // lookupData: function() {
        //     let data = {
        //         entity: 'task_status'
        //     }
        //     axios.post(this.$store.getters.getAPIBasePath + '/lookup/status', data)
        //         .then((response) => {
        //             let result = response.data;
        //             this.taskStatus = result.statusLookup;

        //             this.taskPerformance.statusId = this.taskStatus[2].id;
        //             console.log(this.users);
        //         })
        // },
        getTaskTrackingList: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                startDate: this.task.startDate,
                endDate: this.task.endDate,
            }
            this.task.filterStartDate = this.task.startDate;
            this.task.filterEndDate = this.task.endDate;
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/taskfinancial/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.taskFinancialList = result.taskFinancialList;
                    this.totalRowsTask = this.taskFinancialList.length;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getTabDetails() {
            if (this.ettTabIndex == 0) {
                this.getTaskTrackingList();
            }
            // else if (this.ettTabIndex == 1) {
            //     this.getEmpUtilTaskList();
            // }
            // else if (this.ettTabIndex == 2) {
            //     this.getTimeSheetList();
            // }
            // else {
            //     this.getTaskPerformanceList();
            // }
        },
        onFilteredTask(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsTask = filteredItems.length
            this.currentPageTask = 1
        },
        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldName, fieldValue);
            // alert(cellId);
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: 'task',
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            console.log(data);
            // return;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        // this.clearInlineEdit();
                        this.getTaskTrackingList();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        cancelInlineEdit: function(items,fieldName) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.clearInlineEdit();
        },
        clearInlineEdit() {
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        }, 
        resetFilter: function() {
            this.task.startDate = this.getDateValue(new Date());
            this.task.endDate = this.getDateValue(new Date());
            this.task.filterStartDate = this.getDateValue(new Date());
            this.task.filterEndDate = this.getDateValue(new Date());
            this.getTabDetails();
        },
        quickTask: function() {
            this.taskData.statusId = statusId;
            this.taskData.entityRecordMasterId = '';
            this.taskData.assignedToId = '';
            this.$refs.ermAssignmentsRef.onRefDataChange('Quick Task');
        },
        viewItemDetails: function(item) {
            console.log(item);
            
            if (item && item.length !== 0) {
                let data;
                if (item.length && item.length == 1) {
                    data = item[0];
                } else data = item;
                this.attachmentData.refEntity = 'task';
                this.attachmentData.refRecordId = data.id;
                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$refs.taskDetailPopupRef.getDetails(data.id, 'task');
                })
            }
        },
        calculateCostPercentage(estimatedCost, actualCost) {
            return actualCost ? Math.round(( actualCost / estimatedCost) * 100) : 0;
        },
        getPerformanceRemark(percentage) {
            let output = '';
            if (percentage) {
                // if (percentage <= 80)
                //     output = 'Unacceptable';
                // else if (percentage <= 90)
                //     output = 'Needs Improvement';
                if (percentage < 100)
                    output = 'Below Estimation';
                else if (percentage > 100)
                    output = 'Exceeds Estimation';
                else
                    output = 'Meets Limit';
            }
            return output;
        },
        progressBarVariant: function(progressPercentage) {
            if (progressPercentage != null) {
                if (progressPercentage < 100) {
                    return 'success';
                } else if (progressPercentage > 100) {
                    return 'danger';
                } else {
                    return 'warning';
                }
            }
        },
    }
}
</script>