<template>
    <div>
        <CustomTypeLookupComp :entity="entity" :typeLookupTitle="typeLookupTitle"></CustomTypeLookupComp>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import CustomTypeLookupComp from '@/components/common/CustomTypeLookupComp.vue'
export default {
    name: 'app',
    components: {
        CustomTypeLookupComp,
    },
    data() {
        return {
            entity: 'task_type',
            typeLookupTitle: 'Task Type',
        }
    },
    mounted() {
    },
    methods: {}
}
</script>