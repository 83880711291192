
<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Project Standard</h4> 
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Add Project Standard " :to="{ name: ''}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-pencil pr-2"></i>Edit</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-eye pr-2"></i>View</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="projectStandard-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                            <span>New</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div class="row mb-2">                                                    
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Project Title</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectStandard.title.$error }">
                                                           <input type="text" v-model="projectStandard.title" class="form-control" id="title" placeholder="Title">
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.projectStandard.title.$error && !$v.projectStandard.title.required" class="text-danger">Please enter Project Standard Title</label>
                                                        <label v-if="$v.projectStandard.title.$error && !$v.projectStandard.title.maxLength" class="text-danger">Project Title must not exceed {{$v.projectStandard.title.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="customCategory">Category</label>
                                                        <select v-model="projectStandard.categoryId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in customCategory " :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <a :title="categoryLookupTitle" class="setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="requirementSpec">Project Standard</label>                                                            
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="projectStandard.standard"></editor>
                                                    </div>

<!--                                                     <div class="form-group">
                                                        <label>Project Standard</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectStandard.standard.$error }">
                                                           <textarea v-model="projectStandard.standard" class="form-control" rows="4" required></textarea>
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.projectStandard.standard.$error && !$v.projectStandard.standard.required" class="text-danger">Please enter Project Standard standard</label>
                                                        <label v-if="$v.projectStandard.standard.$error && !$v.projectStandard.standard.maxLength" class="text-danger">Project Standard must not exceed {{$v.projectStandard.standard.$params.maxLength.max}} letters.</label>
                                                    </div> -->
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="requirementSpec">Project Specification</label>                                                            
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="projectStandard.specification"></editor>
                                                    </div>

<!--                                                     <div class="form-group">
                                                        <label>Project Specification</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectStandard.specification.$error }">
                                                           <textarea v-model="projectStandard.specification" class="form-control" rows="4" required></textarea>
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.projectStandard.specification.$error && !$v.projectStandard.specification.required" class="text-danger">Please enter Project standard</label>
                                                        <label v-if="$v.projectStandard.specification.$error && !$v.projectStandard.specification.maxLength" class="text-danger">Project Standard specification must not exceed {{$v.projectStandard.specification.$params.maxLength.max}} letters.</label>
                                                    </div> -->
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="preparedById">Prepared By</label>
                                                        <select v-model="projectStandard.preparedById" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="active">Active</label>
                                                        <div class="input-group ">
                                                            <b-form-checkbox class="custom-toggle" v-model="projectStandard.active" name="check-button" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
 												<div class="col-4">
                                                     <div class="form-group">
                                                        <label for="preparedDt">Prepared Date</label>
                                                            <input  v-model="projectStandard.preparedDt" type="Date" class="form-control" placeholder="Prepared Date">
                                                    </div>
                                                </div>

                                            </div>
                                            <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="projectStandard.perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="projectStandard.filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="projectStandards" @row-selected="onRowSelected" :fields="projectStandardFields" responsive="sm" :current-page="projectStandard.currentPage" :per-page="projectStandard.perPage" :filter="projectStandard.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredSS">
                                <template v-slot:cell(inScope)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>

                                 <template v-slot:cell(standard)="data">
                                        <a class="tooltiptext" href="#" v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.standard)"></a>
                                </template>

                                 <template v-slot:cell(specification)="data">
                                        <a class="tooltiptext" href="#" v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.specification)"></a>
                                </template>


                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteProjectStandard(data.item.id)" data-toggle="modal" data-target="#deleteProjectStandard"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="projectStandard">
                                    <div class="text-center">No Project Standards found</div>
                                </template>
                                <template v-slot:emptyfiltered="projectStandard">
                                    <div class="text-center">{{ projectStandard.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="projectStandards.length!=0" pills v-model="projectStandard.currentPage" :total-rows="projectStandard.totalRows" :per-page="projectStandard.perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details" :active="elements.showDetail">
                                    <div class="row input-group-label">
                                        <div class="col-4">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{projectDetail.projectStandardId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-6 pl-0">
                                            <b-input-group prepend="Category" class="mb-2">
                                                <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="projectDetail.category"></b-form-input>
                                                <b-dropdown v-if="elements.editMode" :text="categoryId" class="btn-dd" v-model="projectDetail.categoryId">
                                                    <b-dropdown-item v-for="(info, index)  in customCategory" :key="info.id" :value="info.id" @click="projectDetail.categoryId = info.id;categoryId = info.value">
                                                        {{info.value}}
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateProjectStandard" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                    	 <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" v-if="!elements.editMode"  class="form-control" id="Title" :disabled="!elements.editMode" v-model="projectDetail.title">
                                                <input type="text" v-if="elements.editMode" class="form-control" id="title"  v-model="projectDetail.title">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="preparedById">Prepared By</label>
                                                <select v-if="elements.editMode" v-model="projectDetail.preparedById" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="!elements.editMode" disabled v-model="projectDetail.preparedBy" type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-6">
                                            <div class="form-group">
                                                <label for="active">Active</label>
                                                <b-form-checkbox v-if="!elements.editMode" class="custom-toggle" v-model="projectDetail.active" :disabled=notEnabled name="check-button" switch></b-form-checkbox>
                                                <b-form-checkbox v-if="elements.editMode" class="custom-toggle" v-model="projectDetail.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                       
                                        <div class="col-12  col-sm-12">
                                            <div class="form-group">
                                                <label for="requirementSpec">Standard</label>                                                            
                                                <editor  :disabled="!elements.editMode"  api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="projectDetail.standard"></editor>
                                            </div>

<!--                                             <div class="form-group">
                                                <label for="standard">Standard</label>
                                                <textarea rows="4" v-if="!elements.editMode" class="form-control" id="standard" :disabled="!elements.editMode" v-model="projectDetail.standard"></textarea>
                                                 <textarea rows="4" v-if="elements.editMode" class="form-control" id="standard"  v-model="projectDetail.standard"></textarea>
                                            </div> -->
                                        </div>
                                         <div class="col-12  col-sm-12">
                                            <div class="form-group">
                                                <label for="requirementSpec">Specification</label>                                                            
                                                <editor  :disabled="!elements.editMode"  api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="projectDetail.specification"></editor>
                                            </div>

<!--                                             <div class="form-group">
                                                <label for="specification">Specification</label>
                                                <textarea rows="4" v-if="!elements.editMode" class="form-control" id="specification" :disabled="!elements.editMode" v-model="projectDetail.specification"></textarea>
                                                <textarea rows="4" v-if="elements.editMode" class="form-control" id="specification"  v-model="projectDetail.specification"></textarea>
                                            </div> -->
                                        </div>
                                    	<div  class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="preparedDt">Prepared Date</label>
                                                <input type="text" class="form-control" id="preparedDt" disabled v-model='projectDetail.preparedDt'>
                                            </div>
                                        </div>
                                       <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="createdDt">Created Date</label>
                                                <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(projectDetail.createdDt)">
                                            </div>
                                        </div>
                                       <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="lastUpdatedDt">Updated Date</label>
                                                <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(projectDetail.lastUpdatedDt)">
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                
                                
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <CategoryLookup ref="categoryLookupModal" :entity="entity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <Dialog id="deleteProjectStandard" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete project standard statement?" />
        
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
    CategoryLookup,
    Editor,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
            },
           
            projectStandard: {
                projectStandardId: '',
                title  : '',
                standard: '',
                specification: '',
                preparedBy  : '',
                preparedById  : '',
                preparedDt  : '',
                active: '',
                entity:'project_standard',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                categoryId  : '',
                category  : '',
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
            },
            projectDetail: {
                active: '',
                createdDt: '',
                entityRecordMasterId: '',
                hasAttachment: '',
                hasReference: '',
                id: '',
                lastUpdatedDt: '',
                preparedBy: '',
                projectId: this.projectId,
                vhextAccountId: this.vhextAccountId,
                category : '',
                projectStandardId: '',
                title: '',
                standard: '',
                specification: '',
                preparedDt: '',
            },
           
            categoryLookupTitle: 'Project Standard Category Settings',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            notEnabled: true,
            entityRecordMaster: [],
            customCategory: [],
            masterStatus: [],
            users: [],
            entity: 'project_standard',
            projectStandards: [],
           // projectDetail: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            projectStandardFields: [
                { key: 'projectStandardId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'standard', label: 'Standard', sortable: true, sortDirection: 'desc' },
                { key: 'specification', label: 'Specification', sortable: true, sortDirection: 'desc' },
                 { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' }
            ],
            selectMode: 'single',
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
               /* setup: function(editor) {
                    editor.ui.registry.addMenuButton('weblinks', {
                        text: "Web URL",
                        icon: "link",
                        fetch: function(callback) {
                            var items = [];
                            self.weblinks.map(function(value, key) {
                                console.log(value);
                                items.push({
                                    type: 'menuitem',
                                    text: value.name,
                                    onAction: function() {
                                        editor.insertContent('<a href="' + value.id + '">' + value.name + '</a>');
                                    }
                                })
                            });
                            callback(items);
                        },
                        onSelect: function(e) {
                            tinyMCE.execCommand(this.value());
                            console.log(this.value());
                        },
                    });
                }*/
            }

        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getProjectStandard(this.$route.params.ermId);
        this.lookupData();
        this.loadOnce = true;
    },
    validations: {
        projectStandard: {
            title: {
                required,
                maxLength: maxLength(100)
            },
            standard: {
                required,
                maxLength: maxLength(2000)
            },
            specification: {
                required,
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        getProjectStandard: function() {
            
            axios.post(this.$store.getters.getAPIBasePath + '/quality/projectstandard/list')
                .then((response) => {
                    let result = response.data;
                    if (result != null && result.length != 0) {
                        this.projectStandards = result.projectStandardlist;
                        if (this.loadOnce) {
                            this.elements.showDetail = true;
                            this.viewProjectStandard(this.projectStandards[0].id);
                        }
                        this.elements.editMode = false;
                        this.projectStandard.totalRows = this.projectStandards.length;
                        this.statusText = this.projectStandards.status;
                        this.loadOnce = false;
                    }
                })
        },
        
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        onFilteredSS(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.projectStandard.totalRows = filteredItems.length
            this.projectStandard.currentPage = 1
        },
        lookupData: function() {
            let data = {
               vhextAccountId:this.projectStandard.vhextAccountId,
                entity:this.projectStandard.entity,
                projectId:this.projectStandard.projectId,
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/quality/projectstandard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.customCategory = result.customCategoryLookup;
                     this.users = result.userLookup;
                })

        },
         saveData: function() {
            this.$v.projectStandard.$touch();
            if (!this.$v.projectStandard.$invalid) {
                if (this.elements.editMode) {
                    this.updateProjectStandard();
                } else {
                    this.saveProjectStandard();
                }
            }
        },
        saveProjectStandard: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/quality/projectstandard/add', this.projectStandard)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectStandard();
                    }
                })
        },
        updateProjectStandard: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/quality/projectstandard/update', this.projectDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectStandard();
                        this.viewProjectStandard(this.projectDetail.id);
                    }
                })
        },
        deleteProjectStandard: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/quality/projectstandard/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectStandard();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.visible = false;
            this.projectStandard.categoryId = '';
            this.projectStandard.category = '';
            this.projectStandard.scopeStatement = '';
            this.projectStandard.title = '';
            this.projectStandard.standard = '';
            this.projectStandard.specification = '';
            this.projectStandard.preparedById = '';
            this.projectStandard.preparedBy = '';
            this.projectStandard.preparedDt = '';
            this.projectStandard.active = '';
            this.projectStandard.id = '';
            this.$v.projectStandard.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = true;
                this.projectDetail.active = data.active;
                this.projectDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.projectDetail.id = data.id;
                this.projectDetail.projectId = data.projectId;
                this.projectDetail.projectStandardId = data.projectStandardId;
                this.projectDetail.title = data.title;
                this.projectDetail.category = data.category;
                this.projectDetail.standard = data.standard;
                this.projectDetail.specification = data.specification;
                this.projectDetail.preparedBy = data.preparedBy;
                this.projectDetail.preparedDt = data.preparedDt;
            	this.projectDetail.vhextAccountId = data.vhextAccountId;
            }
        },
        showEdit: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = true;
            this.projectDetail.active = data.active;
            this.projectDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.projectDetail.id = data.id;
            this.projectDetail.projectId = data.projectId;
            this.projectDetail.projectStandardId = data.projectStandardId;
            this.projectDetail.title = data.title;
         	 this.projectDetail.category = data.category;
            this.projectDetail.standard = data.standard;
            this.projectDetail.specification = data.specification;
            this.projectDetail.preparedBy = data.preparedBy;
            this.projectDetail.preparedDt = data.preparedDt;
            this.projectDetail.vhextAccountId = data.vhextAccountId;
        },
        showView: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = false;
            this.projectDetail.active = data.active;
            this.projectDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.projectDetail.id = data.id;
            this.projectDetail.projectId = data.projectId;
            this.projectDetail.projectStandardId = data.projectStandardId;
            this.projectDetail.title = data.title;
            this.projectDetail.category = data.category;
            this.projectDetail.standard = data.standard;
            this.projectDetail.specification = data.specification;
            this.projectDetail.preparedBy = data.preparedBy;
            this.projectDetail.preparedDt = data.preparedDt;
            this.projectDetail.vhextAccountId = data.vhextAccountId;
        },
        viewProjectStandard: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/quality/projectstandard/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.projectDetail = result.projectStandard;
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewProjectStandard(this.projectDetail.id);
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },

        ToText(HTML) {
            var input = HTML;
            return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ');  
        }
       
        
    }
}
</script>