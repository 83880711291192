import ServiceMaster from '@/views/services/ServiceMaster.vue'
import ServiceMasterEdit from '@/views/services/ServiceMasterEdit.vue'

export default [{
        path: '/srvmas/:projectid',
        name: 'srvmas',
        component: ServiceMaster,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/srvmas/edit/:projectid',
        name: 'srvmasedit',
        component: ServiceMasterEdit,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }]