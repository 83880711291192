<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Effort Estimation</span></h4>
                        </div>                        
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Effort Estimation</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.effortEstimation.title.$error }">
                                                                <input type="text" class="form-control" maxlength="100" v-model="effortEstimation.title" id="title" :class="{ 'is-invalid': $v.effortEstimation.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.effortEstimation.title.$error && !$v.effortEstimation.title.minLength" class="text-danger">Title must have at least {{$v.effortEstimation.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.effortEstimation.title.$error && !$v.effortEstimation.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.effortEstimation.title.$error && !$v.effortEstimation.title.maxLength" class="text-danger">Title must not exceed {{$v.effortEstimation.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Activity</label>                                             
                                                            <b-input-group>
                                                                <select v-model="effortEstimation.activityId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <b-input-group-append class="append-0">
                                                                    <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Resource Type</label>                                             
                                                            <b-input-group>
                                                                <select v-model="effortEstimation.resourceId" class="form-control" >
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in resourceLookup" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <b-input-group-append class="append-0">
                                                                    <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Resource Count</label>
                                                            <b-form-input type="number" min="0" v-model="effortEstimation.resourceCount" @input="calcEffort">
                                                            </b-form-input>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Effort</label>
                                                            <b-input-group>
                                                                <b-form-input type="number" min="0" v-model="effortEstimation.unitEffort" @input="calcEffort" ></b-form-input>
                                                                <b-input-group-append class="append-50">
                                                                    <select v-model="effortEstimation.effortType" class="form-control" >
                                                                        <option v-for="(info, index) in effortUOM" :key="info.id" :value="info.id">{{info.name}}</option>
                                                                    </select>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-1 px-0 mt-4">
                                                        <button type="submit" title="Calculate Effort" @click="calcEffort()" class="sbtn btn btn-xs btn-outline-primary microphone-icon-alt px-2"><i class="fa fa-calculator"></i></button>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Total Effort</label>
                                                            <b-input-group>
                                                                <b-form-input type="number" min="0" v-model="effortEstimation.effort" ></b-form-input>
                                                                <b-input-group-append class="append-50">
                                                                    <select v-model="effortEstimation.effortType" class="form-control" >
                                                                        <option v-for="(info, index) in effortUOM" :key="info.id" :value="info.id">{{info.name}}</option>
                                                                    </select>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Unit Cost</label>
                                                            <VhextDecimalMoneyControl v-model="effortEstimation.unitCost" />                                                       
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Cost</label>
                                                            <VhextDecimalMoneyControl  v-model="effortEstimation.cost"/>                                                        
                                                        </div>
                                                    </div> -->
                                                    <div class="col-12">
                                                        <div class="card-body p-0 my-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-description>
                                                                        <span>Description</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accordion-description" accordion="my-accordion1" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <textarea v-model="effortEstimation.description" class="form-control" id="description" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveEffortEstimation" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="effortEstimation.filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="effortEstTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="effortEstimations" @row-selected="onRowSelected" :fields="effortEstimationFields" responsive="sm" :current-page="effortEstimation.currentPage" :per-page="perPage" :filter="effortEstimation.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(unitEffort)="data">
                                    <div>{{ data.item.unitEffort }} {{ getEffortType(effortUOM, data.item.effortType) }}</div>
                                </template>
                                <template v-slot:cell(effort)="data">
                                    <div>{{ data.item.effort }} {{ getEffortType(effortUOM, data.item.effortType) }}</div>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <!-- <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteEffortEstimation(data.item.id)"v-b-modal.deleteEffortEstimation><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Effort Estimations found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="effortEstimations.length!=0" pills v-model="effortEstimation.currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Details" :active="elements.showDetail">
                                        <div class="row input-group-label">
                                            <div class="col-6">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{effortEstimationDetail.effortEstimationId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col">
                                                
                                            </div>
                                            <div class="col-6 col-sm-2 pl-0">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateEffortEstimation" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" maxlength="100" :disabled="!elements.editMode" v-model="effortEstimationDetail.title">
                                                </div>
                                            </div>
<!--                                             <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Estimation Type</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="effortEstimationDetail.type">
                                                </div>
                                            </div> -->
                                                
                                        </div>
                                        <div class="row">
                                            <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label for="type">Activity</label>
                                                    <input v-if="!elements.editMode" type="activity" class="form-control" id="activity" disabled v-model='effortEstimationDetail.activity'>
                                                    <b-input-group v-if="elements.editMode">
                                                        <select v-model="effortEstimationDetail.activityId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <b-input-group-append class="append-0">
                                                            <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            <!-- <a :title="activityLookupTitle" class="setting-icon pr-2" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a> -->
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label>Resource Type</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="effortEstimationDetail.resourceType">
                                                </div>
                                            </div> -->
                                            <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label>Resource Type</label>                                             
                                                    <b-input-group>
                                                        <select v-model="effortEstimationDetail.resourceId" :disabled="!elements.editMode" class="form-control" >
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in resourceLookup" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <b-input-group-append class="append-0">
                                                            <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Resource Count</label>
                                                    <b-form-input type="number" min="0" :disabled="!elements.editMode" v-model="effortEstimationDetail.resourceCount" @input="calcESDetailEffort">
                                                    </b-form-input>
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="form-group">
                                                    <label>Effort</label>
                                                    <b-input-group>
                                                        <b-form-input type="number" min="0" v-model="effortEstimationDetail.unitEffort" :disabled="!elements.editMode" @input="calcESDetailEffort" ></b-form-input>
                                                        <b-input-group-append class="append-50">
                                                            <select v-model="effortEstimationDetail.effortType" class="form-control" :disabled="!elements.editMode" >
                                                                <option v-for="(info, index) in effortUOM" :key="info.id" :value="info.id">{{info.name}}</option>
                                                            </select>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>                                                                                    
                                            <!-- <div class="col-1 px-0 mt-4">
                                                <button type="submit" title="Calculate Effort" @click="calcESDetailEffort()" class="sbtn btn btn-xs btn-outline-primary microphone-icon-alt px-2"><i class="fa fa-calculator"></i></button>
                                            </div> -->
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Total Effort</label>
                                                    <b-input-group>
                                                        <b-form-input type="number" min="0" v-model="effortEstimationDetail.effort" :disabled="!elements.editMode" ></b-form-input>
                                                        <b-input-group-append>
                                                            <select v-model="effortEstimationDetail.effortType" class="form-control" :disabled="!elements.editMode" >
                                                                <option v-for="(info, index) in effortUOM" :key="info.id" :value="info.id">{{info.name}}</option>
                                                            </select>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>                                            
                                        </div>
                                        <div class="row">
<!--                                             <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label>Effort</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="effortEstimationDetail.effort">
                                                </div>
                                            </div> -->
    <!--                                         <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label for="unitCost">Unit Cost</label>
                                                    <VhextMoneyControl :disabled="!elements.editMode" v-model="effortEstimationDetail.unitCost" />                                               
                                                </div>
                                            </div>
                                            <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label for="cost">Cost</label>
                                                    <VhextMoneyControl  :disabled="!elements.editMode" v-model="effortEstimationDetail.cost" />
                                                </div>
                                            </div> -->
                                            <div class="col-12  col-sm-12">
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea class="form-control" maxlength="1000" rows="4" :disabled="!elements.editMode" v-model="effortEstimationDetail.description"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Created Date</label>
                                                    <div class="input-group  disabled-date" disabled>
                                                        <input type="text" class="form-control disabled" :value="effortEstimationDetail.createdDt | formatDate" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label>Updated Date</label>
                                                    <div class="input-group disabled-date">
                                                        <input type="text" class="form-control disabled" :value="effortEstimationDetail.lastUpdatedDt | formatDate" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" /> -->
        <AddResourceLookup :resourceLookupTitle="resourceLookupTitle" :resourceCategoryId="1" @refresh-lookup="lookupData" />
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" />
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="effortEstimation.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <DeleteComponent id="deleteEffortEstimation" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Effort Estimation?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import AddResourceLookup from '@/components/popup/lookup/AddResourceLookup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
import enummixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        TypeLookup,
        // ActivityLookup,
        AddResourceLookup,
        ProcessActivityLookup,
        VhextMoneyControl ,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
            },
            showOverlay: false,
            showDetOverlay: false,
            typeLookupTitle:'EffortEstimation Type Settings',
            resourceLookupTitle: 'Resources',

            prependText:'₹',
            statusText:'',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'effort_estimation',
            
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            // ermId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),


            masterStatusText: '',
            resourceText: 'Resource Type',
            effortText: 'Effort Type',
            resourceTypeText: 'Resource Type',
            activityLookupTitle: 'Activity Settings',
            processActivityLookupTitle: 'Process / Activity Settings',

            mainTableArray: [],

            effortEstimation: {
                title: '',
                effortEstimation: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'effort_estimation',
                effortEstimationTypeId: '',
                type: '',
                title: '',
                erdId: '',
                refEntityId: '',
                activityId: '',
                activity: '',
                resourceId: '',
                resourceTypeId: '',
                resourceType: '',
                resourceTitle: '',
                resourceCount: '',
                unitEffort: '',
                effort: '',
                effortType: 1,
                effortHour: '',
                unitCost: '',
                cost: '',
                description: '',
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',

            },
            effortEstimationDetail: {
                title: '',
                effortEstimation: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                entity: '',
                effortEstimationTypeId: '',
                type: '',
                title: '',
                erdId: '',
                refEntityId: '',
                activityId: '',
                activity: '',
                resourceId: '',
                resourceTypeId: '',
                resourceType: '',
                resourceTitle: '',
                resourceCount: '',
                unitEffort: '',
                effort: '',
                effortType: 1,
                effortHour: '',
                effortText: 'Effort Type',
                unitCost: '',
                cost: '',
                description: '',
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },
            selEffortUOM: [],

            entityRecordMaster: [],
            projectERMList: [],
            ermReferenceList: [],
            entityReviewApprovalList: [],
            masterStatus: [],
            activities: [],
            resourceLookup: [],
            effortEstimations: [],
            effortEstimationFields: [
                { key: 'effortEstimationId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                // { key: 'ermTitle', label: 'Effort Est. Sheet', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'resourceTitle', label: 'Resource', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCount', label: 'Count', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'unitEffort', label: 'Unit Effort', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },
                { key: 'effort', label: 'Effort', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [ 5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getEffortEstimation();
        this.lookupData();
    },
    validations: {
        effortEstimation: {
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            }
        }
    },
    methods: {
        getEffortEstimation: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/list', data)
                .then((response) => {
                    let result = response.data;
                    this.effortEstimations = result.effortEstimationList;
                    this.elements.editMode = false;
                    if (this.effortEstimations && this.effortEstimations.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.effortEstimationDetail.id, this.mainTableArray, 'effortEstTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                    this.totalRows = this.effortEstimations.length;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                resourceCategoryId: 1,      // 1 - human resource
                entity: this.entity,                
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.activities = result.activityLookup;
                    this.resourceLookup = result.resourceLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveEffortEstimation: function() {
            this.effortEstimation.vhextAccountId = this.vhextAccountId;
            this.effortEstimation.projectId = this.projectId;
            this.effortEstimation.entityRecordMasterId = this.entityRecordMasterId;
            this.effortEstimation.entity = this.entity;

            this.$v.effortEstimation.$touch();
            if (!this.$v.effortEstimation.$invalid) {
                console.log(this.effortEstimation);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/add', this.effortEstimation)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getEffortEstimation();
                            this.viewEffortEstimation(this.effortEstimationDetail.id);
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
                }
        },
        updateEffortEstimation: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/update', this.effortEstimationDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEffortEstimation();
                        this.viewEffortEstimation(this.effortEstimationDetail.id);
                    }
                })
                .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
        },
        deleteEffortEstimation: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/plan/effortestimation/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEffortEstimation();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.visible = false;
            this.effortEstimation.title = '';
            this.effortEstimation.effortEstimationTypeId = '';
            this.effortEstimation.erdId = '';
            this.effortEstimation.refEntityId = '';
            this.effortEstimation.activityId = '';
            this.effortEstimation.resourceId = '';
            this.effortEstimation.resourceTypeId = '';
            this.effortEstimation.resourceCount = '';
            this.effortEstimation.effortEstimationTypeId = '';
            this.effortEstimation.unitEffort = '';
            this.effortEstimation.effort = '';
            this.effortEstimation.effortHour = '';
            this.effortEstimation.description = '';
            this.effortEstimation.resourceTitle = '';
            this.effortEstimation.id = '';
            this.$v.effortEstimation.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.effortEstimationDetail.id = data.id;
            this.effortEstimationDetail.effortEstimationId = data.effortEstimationId;
            this.effortEstimationDetail.vhextAccountId = data.vhextAccountId;
            this.effortEstimationDetail.projectId = data.projectId;
            this.effortEstimationDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.effortEstimationDetail.title = data.title;
            this.effortEstimationDetail.effortEstimationTypeId = data.effortEstimationTypeId;
            this.effortEstimationDetail.effortEstimationType = data.type;
            this.effortEstimationDetail.erdId = data.erdId;
            this.effortEstimationDetail.refEntityId = data.refEntityId;
            this.effortEstimationDetail.activityId = data.activityId;
            this.effortEstimationDetail.activity = data.activity;
            this.effortEstimationDetail.resourceId = data.resourceId;
            this.effortEstimationDetail.resourceType = data.resourceType;
            this.effortEstimationDetail.resourceTitle = data.resourceTitle;
            this.effortEstimationDetail.resourceCount = data.resourceCount;
            this.effortEstimationDetail.unitEffort = data.unitEffort;
            this.effortEstimationDetail.effort = data.effort;
            this.effortEstimationDetail.unitCost = data.unitCost;
            this.effortEstimationDetail.effortHour = data.effortHour;
            this.effortEstimationDetail.effortType = data.effortType ?? 1;
            // this.effortEstimationDetail.effortText = this.getEffortType(this.effortUOM,data.effortType);
            
            this.effortEstimationDetail.cost = data.cost;
            this.effortEstimationDetail.createdDt = data.createdDt;
            this.effortEstimationDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.effortEstimationDetail.description = data.description;
        },
        // showView: function(data) {
        //     console.log(data)
        //     this.elements.visible = true;
        //     this.elements.editMode = false;
        //     this.elements.viewMode = true;
        //     this.effortEstimation.id = data.id;
        //     this.effortEstimation.effortEstimationId = data.effortEstimationId;
        //     this.effortEstimation.title = data.title;
        //     this.effortEstimation.effortEstimationTypeId = data.effortEstimationTypeId;
        //     this.effortEstimation.effortEstimationType = data.type;
        //     this.effortEstimation.activity = data.activity;
        //     this.effortEstimation.resourceType = data.resourceType;
        //     this.effortEstimation.resourceTitle = data.resourceTitle;
        //     this.effortEstimation.resourceCount = data.resourceCount;
        //     this.effortEstimation.unitEffort = data.unitEffort;
        //     this.effortEstimation.effort = data.effort;
        //     this.effortEstimation.effortType = data.effortType;
        //     this.effortEstimation.effortText = this.getEffortType(this.effortUOM,data.effortType);
        //     this.effortEstimation.unitCost = data.unitCost;
        //     this.effortEstimation.cost = data.cost;
        //     this.effortEstimation.createdDt = data.createdDt;
        //     this.effortEstimation.lastUpdatedDt = data.lastUpdatedDt;
        //     this.effortEstimation.description = data.description;
        // },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                // this.viewEffortEstimation(data.id);
                this.effortEstimationDetail.id = data.id;
                this.effortEstimationDetail.effortEstimationId = data.effortEstimationId;
                this.effortEstimationDetail.vhextAccountId = data.vhextAccountId;
                this.effortEstimationDetail.projectId = data.projectId;
                this.effortEstimationDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.effortEstimationDetail.title = data.title;
                this.effortEstimationDetail.effortEstimationTypeId = data.effortEstimationTypeId;
                this.effortEstimationDetail.effortEstimationType = data.type;
                this.effortEstimationDetail.erdId = data.erdId;
                this.effortEstimationDetail.refEntityId = data.refEntityId;
                this.effortEstimationDetail.activityId = data.activityId;
                this.effortEstimationDetail.activity = data.activity;
                this.effortEstimationDetail.resourceId = data.resourceId;
                this.effortEstimationDetail.resourceType = data.resourceType;
                this.effortEstimationDetail.resourceTitle = data.resourceTitle;
                this.effortEstimationDetail.resourceCount = data.resourceCount;
                this.effortEstimationDetail.unitEffort = data.unitEffort;
                this.effortEstimationDetail.effort = data.effort;
                this.effortEstimationDetail.effortHour = data.effortHour;
                this.effortEstimationDetail.effortType = data.effortType ?? 1;
                // this.effortEstimationDetail.effortText = this.getEffortType(this.effortUOM,data.effortType);
                this.effortEstimationDetail.unitCost = data.unitCost;
                this.effortEstimationDetail.cost = data.cost;
                this.effortEstimationDetail.createdDt = data.createdDt;
                this.effortEstimationDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.effortEstimationDetail.description = data.description;
            }
            console.log(this.effortEstimationDetail);
        },
        viewEffortEstimation: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/plan/effortestimation/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.effortEstimationDetail = result.effortEstimation;
                    // this.effortEstimationDetail.effortText = this.getEffortType(this.effortUOM,this.effortEstimationDetail.effortType);

                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewEffortEstimation(this.effortEstimationDetail.id);
        },
        calcEffort: function() {
            this.effortEstimation.effort = (this.effortEstimation.resourceCount ?? 0) * (this.effortEstimation.unitEffort ?? 0);
        },
        calcESDetailEffort: function() {
            this.effortEstimationDetail.effort = (this.effortEstimationDetail.resourceCount ?? 0) * (this.effortEstimationDetail.unitEffort ?? 0);
        },
    }
}
</script>