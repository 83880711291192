<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Category Lookup Settings</h4>
                        </div>
                        <div class="col-lg-2 col-sm-4">
                            <h5 v-if="activeDisplayName" class="pl-2 mb-0 fs-16">{{ activeDisplayName }}</h5>
                        </div>
                        <div class="col-lg-2 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a v-if="activeDisplayName" href="javascript:" class="d-block p-1 action-link" :title="'Existing ' + activeDisplayName" @click="showDialog=true;" v-b-modal.existingCustomCategoryLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing {{ activeDisplayName }}</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <h6>Custom Category Lookup</h6>
                            <div class="separator mb-4"></div>
<!--                             <div v-if="!this.projectId" class="form-row mb-0">
                                <div class="col-3 py-1 pl-2">
                                    <label for="projectTypeId">Project Type</label>
                                </div>
                                <div class="col-9 pl-0">
                                    <div class="form-group pl-0">
                                        <select v-model="customCategory.projectTypeId" class="form-control" @change="onChange($event)">
                                            <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>                                 -->
                            <ul class="typeLookup_list">
                                <li v-for = "entity in customEntities" >
                                    <a :class="{'active' : entity.id == activeLookup}" class="pointer" @click="getCustomCategoryLookups(entity.entity); activeLookup = entity.id; activeDisplayName = entity.displayName; ">{{entity.displayName}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="goalType" class="categoryLookup active">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{activeDisplayName}}
                                                </span>
                                                <span v-if="elements.editMode">Edit</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-add" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="duration">Category</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.customCategory.category.$error }">
                                                                <b-form-input id="category" v-model="customCategory.category" placeholder="Enter Category"></b-form-input>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.customCategory.category.$error && !$v.customCategory.category.required" class="text-danger">Please Enter Category</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="customCategory.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5 pull-right">
                                                        <label for="add" style="visibility: hidden;">Add</label>
                                                        <div class="input-group ">
                                                            <button type="submit" title="Save" @click="saveCustomCategoryData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3">
                                                                <i class="fa fa-plus pr-2" v-if="!elements.editMode"></i>
                                                                <i class="fa fa-floppy-o pr-2" v-else ></i>
                                                                {{ elements.editMode ? 'Save' : 'Add'}}
                                                            </button>
                                                            <button type="submit" title="Clear" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table show-empty small stacked="md" sort-icon-left :items="customCategories" :fields="fields" responsive="sm" :current-page="customCategory.currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>                                        
                                        <template v-slot:cell(customCategoryLookupId)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <input type="number" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('custom_category_lookup_id', data.item.id,data.value)" @blur="cancelInlineEdit(customCategories, data.field.key)">
                                            </div>
                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)"> {{ data.value }}</div>
                                        </template>
                                        <template v-slot:cell(category)="data">
                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('category', data.item.id, data.value)" @blur="cancelInlineEdit(customCategories, data.field.key)">
                                            </div>
                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)"> {{ data.value }}</div>
                                        </template>

                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox  v-model="data.value" @change="assignActivationState(data.item)">
                                            </b-form-checkbox>
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <a href="javascript:" title="Edit Types" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                            <a href="javascript:" title="Delete" @click="deleteCustomCategoryLookup(data.item.id)" data-toggle="modal" data-target="#deleteCustomCategoryLookup"><i class="fa fa-trash pl-2"></i></a>
                                        </template>
                                        <template v-slot:empty="">
                                            <div class="text-center">No {{ activeDisplayName ? activeDisplayName : 'Record' }} found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="">
                                            <div class="text-center">{{ }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="customCategories.length!=0" pills v-model="customCategory.currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddExistingCustomCategoryPopup v-if="showDialog" @refresh-lookup="getCustomCategoryLookups(entity)" :popupDisplayName="activeDisplayName" :entity="entity" @closeDialog="showDialog=false;" />
        <Dialog id="deleteCustomCategoryLookup" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Custom Category Lookup ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddExistingCustomCategoryPopup from '@/components/popup/lookup/AddExistingCustomCategoryPopup.vue';
import moment from 'moment';
export default {
    name: 'app',
    components: {
        AddExistingCustomCategoryPopup,
    },
    data() {
        return {
            showDialog: false,
            elements: {
                visible: false,
                editMode: false
            },
            isBusy: false,
            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: '',

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            inlineEditRowId: '',
            inlineEditFieldValue: '',
            inlineEditFieldName: '',

            entityTypeId: 4,
            activeLookup: '',
            activeDisplayName: '',
            notEnabled: true,
            customCategory: {
                id: '',
                customCategoryLookupId: '',
                entity: '',
                active: true,
                category: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                entityId: '',
                userId: '',
                createdById: localStorage.getItem("userId"),
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: '',
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            customEntityList: [],
            projectTypes: [],
            customCategories: [],
            customCategoryFields: [
                { key: 'index', label: 'SN', },
                { key: 'category', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            vsaCustomCategoryFields: [
                { key: 'index', label: 'SN', },
                { key: 'id', label: 'Pk Id', sortable: true, sortDirection: 'desc' },
                { key: 'customCategoryLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'entityId', label: 'Entity Id', sortable: true, sortDirection: 'desc' },
                { key: 'categoryName', label: 'Category Name', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'category', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            search: {
                params: {
                    title: ""
                }
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        activeModuleId() {
            let moduleId = this.$store.state.adminSetting.moduleId;
            return moduleId ? String(moduleId).padStart(2, '0') : moduleId;
        },
        customEntities() {
            let customEntityList = [];
            if (this.activeModuleId) {
                customEntityList = this.customEntityList.filter(data => (data.moduleId == this.activeModuleId));
            } else {
                customEntityList = this.customEntityList;
            }
            if (customEntityList.length > 0) {
                let entity = customEntityList[0].entity;
                this.activeLookup = customEntityList[0].id;
                this.activeDisplayName = customEntityList[0].displayName;
                this.getCustomCategoryLookups(entity);
            }

            return customEntityList;
        },
        fields(){
            if(this.userTypeCode == 'VSA'){
                return this.vsaCustomCategoryFields;
            }
            else
            {
                return this.customCategoryFields;
            }

        }

    },
    validations: {
        customCategory: {
            category: {
                required
            }
        }
    },
    // watch: {
    //     'entity': {
    //         handler: function(val1, val2) {
    //             this.getCustomCategoryLookups();
    //         },
    //         deep: true,
    //         immediate: true
    //     },
    // },
    mounted() {
        this.getEntities();
        this.lookupData();
    },
    methods: {
        getEntities: function() {
            let data = {
                entityTypeId: this.entityTypeId
            }

            axios.post(this.$store.getters.getAPIBasePath + '/general/customentity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.customEntityList = result.customEntityList;
                    if (this.customEntities.length > 0) {
                        let entity = this.customEntities[0].entity;
                        this.activeLookup = this.customEntities[0].id;
                        this.activeDisplayName = this.customEntities[0].displayName;
                        this.getCustomCategoryLookups(entity);
                    }
                    console.log(this.customEntities.length)
                    
                    this.totalRows = this.customEntities.length;
                    console.log(this.customEntities);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/customentity/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTypes = result.projectTypeLookup;                                        
                    this.users = result.userLookup;   

                    this.customCategory.projectTypeId  = this.projectTypeId;

                    // this.projectTypeId = this.projectTypes[0].id;  
                })

        },      
        onChange(event) {
            this.projectTypeId = event.target.value;

            this.getEntities();

            console.log(this.projectTypeId,event.target.value.toString());
        },        
        getCustomCategoryLookups: function(entity) {
            if (entity != '') {
                this.entity = entity;
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    projectTypeId: this.projectTypeId,
                    entity: entity
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.customCategories = result.customCategoryLookupList;         
                        if (this.customCategories != null)
                            this.totalRows = this.customCategories.length;
                    })
            }
        },
        saveCustomCategoryData: function() {
            this.$v.customCategory.$touch();
            if (!this.$v.customCategory.$invalid) {
                if (this.elements.editMode) {
                    this.updateCustomCategoryLookup();
                } else {
                    this.addCustomCategoryLookup();
                }
            }
        },
        addCustomCategoryLookup: function() {
            this.customCategory.vhextAccountId = this.vhextAccountId;
            this.customCategory.projectId = this.projectId;
            this.customCategory.projectTypeId = this.projectTypeId;
            this.customCategory.entity = this.entity;
            console.log(this.customCategory);
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/add', this.customCategory)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getCustomCategoryLookups(this.entity);
                    }
                })
        },
        updateCustomCategoryLookup: function() {
            console.log(this.customCategory)
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/update', this.customCategory)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getCustomCategoryLookups(this.entity);
                    }
                })
        },
        deleteCustomCategoryLookup: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCustomCategoryLookups(this.entity);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.customCategory.entity = 'custom_category_lookup';
                this.customCategory.id = item.id;
                this.customCategory.active = 0;

                this.updateActivationState();

            } else {
                this.customCategory.entity = 'custom_category_lookup';
                this.customCategory.id = item.id;
                this.customCategory.active = 1;

                this.updateActivationState();
            }
            console.log(this.customCategory.id, this.customCategory.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.customCategory)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.customCategory.active = true;
                    this.getCustomCategoryLookups(this.entity);
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'custom_category_lookup',
                columnName: columnName,
                textValue: fieldValue,
                id: rowId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.clearInlineEdit();
                        this.getCustomCategoryLookups(this.entity);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },
        clearData: function() {
            this.customCategory.category = '';
            this.$v.customCategory.$reset();
            this.elements.editMode = false;
            this.elements.visible = false;
            this.customCategory.title = '';
            this.customCategory.active = true;
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.customCategory.customCategoryLookupId = data.customCategoryLookupId;
            this.customCategory.active = data.active;
            this.customCategory.category = data.category;
            this.customCategory.categoryName = data.categoryName;
            this.customCategory.vhextAccountId = data.vhextAccountId;
            this.customCategory.projectId = data.projectId;
            this.customCategory.projectTypeId = data.projectTypeId;
            this.customCategory.entityId = data.entityId;
            this.customCategory.entity = data.entity;
            this.customCategory.id = data.id;
        },
        deActivate: function(item) {
            this.$nextTick(() => {
                console.log(item.active, item.id)

                if (item.active == true) {
                    this.customCategory.id = item.id;
                    this.customCategory.active = 1;
                    this.customCategory.userId = this.userId;
                    this.updateCustomCategoryLookupActiveState();
                    console.log(item.active, this.customCategory.active, item.id)
                } else {
                    this.customCategory.id = item.id;
                    this.customCategory.active = 0;
                    this.customCategory.userId = this.userId;
                    this.updateCustomCategoryLookupActiveState();
                    console.log(item.active, this.customCategory.active, item.id)
                }
            })
        },
        
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        deleteProject: function(id) {

        },
        showTab: function() {
            console.log(this);
        }
    }
}
</script>