<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">User Type</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New User Type</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="userType">User Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.userType.userType.$error }">
                                                                <input type="text" class="form-control" v-model="userType.userType" id="userType" :class="{ 'is-invalid': $v.userType.userType.$error }" placeholder="Enter User Type">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.userType.userType.$error && !$v.userType.userType.minLength" class="text-danger">User Type must have at least {{$v.userType.userType.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.userType.userType.$error && !$v.userType.userType.required" class="text-danger">Please enter User Type title</label>
                                                            <label v-if="$v.userType.userType.$error && !$v.userType.userType.maxLength" class="text-danger">User Type must not exceed {{$v.userType.userType.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="typeCode">Type Code</label>
                                                            <input type="text" class="form-control" v-model="userType.typeCode" id="typeCode" placeholder="Enter Type Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="accessCode">Access Code</label>
                                                            <input type="text" class="form-control" v-model="userType.accessCode" id="accessCode" placeholder="Enter Access Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="userType.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="budget">Is Primary</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="userType.isPrimary" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="budget">Description</label>
                                                            <textarea v-model="userType.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveuserType" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="userTypeTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="userTypeList"  @row-selected="onRowSelected" :fields="userTypeFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <!-- <template v-slot:cell(userTypeId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control" v-model="data.item.userTypeId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.userTypeId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.userTypeId"> {{ data.item.userTypeId }}</div>
                                </template> -->

                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(isPrimary)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" >
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteUserType(data.item.id)" data-toggle="modal" data-target="#deleteUserType"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No User Types found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="userTypeList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row input-group-label">
                                        <div class="col-5">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{userTypeDetail.userTypeId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateUserType" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-8">
                                            <div class="form-group">
                                                <label for="userTypeDet">User Type</label>
                                                <input type="text" class="form-control" id="userTypeDet" :disabled="!elements.editMode" v-model="userTypeDetail.userType">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="typeCodeDet">Type Code</label>
                                                <input type="text" class="form-control" v-model="userTypeDetail.typeCode" id="typeCodeDet" :disabled="!elements.editMode"  placeholder="Enter Type Code">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="accessCodeDet">Access Code</label>
                                                <input type="text" class="form-control" v-model="userTypeDetail.accessCode" id="accessCodeDet" :disabled="!elements.editMode"  placeholder="Enter Access Code">
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Is Primary Type</label>
                                                <b-form-checkbox class="custom-toggle" v-model="userTypeDetail.isPrimary" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Active</label>
                                                <b-form-checkbox class="custom-toggle" v-model="userTypeDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="createdBy">Created By</label>
                                                                        <input type="text" class="form-control disabled" id="createdBy" v-model="userTypeDetail.createdBy" readonly>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="startDate">Created Date</label>
                                                                        <div class="input-group" >
                                                                            <input type="text" class="form-control disabled" :value="userTypeDetail.createdDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteUserType" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Type?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'user_type',

            idValue: '',
            idEdit: '',

            userType: {
                id: '',
                userTypeId: '',
                userType: '',
                typeCode: '',
                accessCode: '',
                active: true,
                isPrimary: false,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },
            userTypeDetail: {
                id: '',
                userTypeId: '',
                userType: '',
                typeCode: '',
                accessCode: '',
                active: true,
                isPrimary: false,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },

            userTypeList: [],
            userTypeFields: [
                { key: 'id', label: 'PK ID', sortable: true, sortDirection: 'desc' },
                { key: 'userTypeId', label: 'User Type Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'userType', label: 'User Type', sortable: true, sortDirection: 'desc' },
                { key: 'typeCode', label: 'Type Code', sortable: true, sortDirection: 'desc' },
                { key: 'accessCode', label: 'Access Code', sortable: true, sortDirection: 'desc' },
                { key: 'isPrimary', label: 'Is Primary', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getuserTypes();
    },
    validations: {
        userType: {
            userType: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        userTypeDetail: {
            userType: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getuserTypes: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/usertype/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.userTypeList && result.userTypeList.length > 0) {
                        this.userTypeList = result.userTypeList;
                        this.$nextTick(() => {
                            this.$refs.userTypeTable.selectRow(0);
                        })
                        this.totalRows = this.userTypeList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveuserType: function() {
            this.$v.userType.$touch();
            if (!this.$v.userType.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/usertype/add', this.userType)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getuserTypes();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateUserType: function() {
            this.$v.userTypeDetail.$touch();
            if (!this.$v.userTypeDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/usertype/update', this.userTypeDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getuserTypes();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewuserType: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/user/usertype/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let userTypeDetail = result.userType;
                    if (userTypeDetail) {                        
                        this.userTypeDetail.id = userTypeDetail.id;
                        this.userTypeDetail.userTypeId = userTypeDetail.userTypeId;
                        this.userTypeDetail.userType = userTypeDetail.userType;
                        this.userTypeDetail.typeCode = userTypeDetail.typeCode;
                        this.userTypeDetail.accessCode = userTypeDetail.accessCode;
                        this.userTypeDetail.active = userTypeDetail.active;
                        this.userTypeDetail.isPrimary = userTypeDetail.isPrimary;
                        this.userTypeDetail.createdById = userTypeDetail.createdById;
                        this.userTypeDetail.createdBy = userTypeDetail.createdBy;
                        this.userTypeDetail.createdDt = userTypeDetail.createdDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewuserType(this.userTypeDetail.id);
        },
        deleteUserType: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/user/usertype/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getuserTypes();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.userType.id = '';
            this.userType.userTypeId = '';
            this.userType.userType = '';
            this.userType.typeCode = '';
            this.userType.accessCode = '';
            this.userType.active = true;
            this.userType.isPrimary = false;
            this.userType.createdById = localStorage.getItem("userId");
            this.userType.createdBy = '';
            this.userType.createdDt = '';
            this.$v.userType.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.userTypeDetail.id = data.id;
            this.userTypeDetail.userTypeId = data.userTypeId;
            this.userTypeDetail.userType = data.userType;
            this.userTypeDetail.typeCode = data.typeCode;
            this.userTypeDetail.accessCode = data.accessCode;
            this.userTypeDetail.active = data.active;
            this.userTypeDetail.isPrimary = data.isPrimary;
            this.userTypeDetail.createdById = data.createdById;
            this.userTypeDetail.createdBy = data.createdBy;
            this.userTypeDetail.createdDt = data.createdDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.userTypeDetail.id = data.id;
                this.userTypeDetail.userTypeId = data.userTypeId;
                this.userTypeDetail.userType = data.userType;
                this.userTypeDetail.typeCode = data.typeCode;
                this.userTypeDetail.accessCode = data.accessCode;
                this.userTypeDetail.active = data.active;
                this.userTypeDetail.isPrimary = data.isPrimary;
                this.userTypeDetail.createdById = data.createdById;
                this.userTypeDetail.createdBy = data.createdBy;
                this.userTypeDetail.createdDt = data.createdDt;
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.userType.entity = this.entity;
                this.userType.id = item.id;
                this.userType.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.userType.entity = this.entity;
                this.userType.id = item.id;
                this.userType.active = 1;

                this.updateActivationState();
            }
            console.log(this.userType.id, this.userType.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.userType)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.userType.active = true;
                    this.getuserTypes();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
    }
}
</script>
