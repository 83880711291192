<template>
    <div class="meetingMinutesDetailPopup">
        <b-modal id="meetingMinutesDetailPopup" ref="meetingMinutesDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Meeting Minutes" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-10 col-sm-9 mb-3 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.meetingMinuteId }}</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                            <div class="col-4 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Dead line</label>
                                            </div>
                                            <div class="col-4 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.deadline | formatDate}}</label>
                                            </div>  

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Person Responsible</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.responsiblePerson}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Initiated By</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.initiatedPerson}}</label>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold">
                                                    <span>Agenda Item</span>
                                                    <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(popupData, popupData.description)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                </p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold">
                                                    <span>Discussion</span>
                                                    <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(popupData, popupData.discussion)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                </p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.discussion"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold">
                                                    <span>Decision/Action Items</span>
                                                    <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(popupData, popupData.decision)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                </p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.decision"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold">
                                                    <span>Action Taken</span>
                                                    <a href="javascript:" title="Assign as Task" class="float-right" @click="assignTask(popupData, popupData.actionTaken)" v-b-modal.ermAssignments><i class="fa fa-user-plus px-2"></i></a>
                                                </p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.actionTaken"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
    </div>
</template>
<script>
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
export default {
    components: {
        QuickTaskPopup,
    },
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: ''
            },
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            console.log(id, refEntity);
                if (refEntity === "meeting_minutes") {
                    this.$bvModal.show('meetingMinutesDetailPopup');
                    axios.get(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.requirementSpecView;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$bvModal.show('meetingMinutesDetailPopup');
                    this.loading = false;
                }
        },
        assignTask: function(data, refErdDescription) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = 'meeting_minute';
            this.masterData.refErdTitle = data.title;
            this.masterData.refErdDescription = refErdDescription;
            this.masterData.statusId = '';
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.masterData.refErmId, this.masterData.refEntityId);
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>