<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-1 fs-16">Menu Management</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-0"></div>
                </div>
            </div>
			<div class="row">
                <div class="col-lg-12 col-sm-12 p-3 menumangement">
                    
                    <div no-body>
                        <!-- <b-tabs pills card vertical nav-wrapper-class="w-30"> -->
                        <b-tabs content-class="ml-0" lazy>
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
    <!--Standardm Menu-->
                               <b-tab title="Standard Menu">
                                    <b-card-text>
                                        <div class="card ">
                                            <div class="card-body p-3">
                                                <div class="menu_cont " id="menuIntegration">
                                                    <!-- <p class="mb-1">Standard Menu</p> -->
                                                    <div id="standardmenu-app" class="mb-3">
                                                        <b-card no-body class="">
                                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                                    <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Standard Menu</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                                <b-card-body class="px-3 pt-2 pb-3">
                                                                    <div class="row">
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="id">Id</label>
                                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.standardMenu.id.$error }">
                                                                                    <input type="text" v-model="standardMenu.id" class="form-control" id="displayName" placeholder="Enter Id">
                                                                                    <div class="required-icon"></div>
                                                                                </div>
                                                                                <label v-if="$v.standardMenu.id.$error && !$v.standardMenu.id.required" class="text-danger">Please Enter Id</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="vmxServiceId">VMX Service</label>
                                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.standardMenu.vmxServiceId.$error }">
                                                                                    <select v-model="standardMenu.vmxServiceId" @change="standardMenu.moduleId=''" class="form-control">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                    <div class="required-icon"></div>
                                                                                </div>
                                                                                <label v-if="$v.standardMenu.vmxServiceId.$error && !$v.standardMenu.vmxServiceId.required" class="text-danger">Please Select VMX Service</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="standardMenuTypeId">Standard Menu Type</label>
                                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.standardMenu.standardMenuTypeId.$error }">
                                                                                    <select v-model="standardMenu.standardMenuTypeId" class="form-control" :class="{ 'is-invalid': $v.standardMenu.standardMenuTypeId.$error }" id="standardMenuTypeId">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                                    </select>
                                                                                    <div class="required-icon"></div>
                                                                                </div>
                                                                                <label v-if="$v.standardMenu.standardMenuTypeId.$error && !$v.standardMenu.standardMenuTypeId.required" class="text-danger">Please Select Std. Menu Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="moduleId">Module</label>
                                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.standardMenu.moduleId.$error }">
                                                                                    <select v-model="standardMenu.moduleId" @change="standardMenu.subModuleId=''" class="form-control" :class="{ 'is-invalid': $v.standardMenu.moduleId.$error }" id="moduleId">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in getFilteredModules(standardMenu.vmxServiceId)" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                    <div class="required-icon"></div>
                                                                                </div>
                                                                                <label v-if="$v.standardMenu.moduleId.$error && !$v.standardMenu.moduleId.required" class="text-danger">Please Select Module</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="subModuleId">Sub Module</label>
                                                                                <select v-model="standardMenu.subModuleId" class="form-control" id="subModuleId">
                                                                                    <option value="">Select</option>
                                                                                    <option v-for="(info, index) in getFilteredSubModules(standardMenu.moduleId)" :value="info.id">{{info.value}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                         <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="title">Main Menu</label>
                                                                                <select v-model="standardMenu.mainMenuId" class="form-control">
                                                                                    <option value="">Select</option>
                                                                                    <option v-for="(info, index) in mainMenuLookup" :value="info.id">{{info.value}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="subMenuId">Sub Menu</label>
                                                                                <input type="text" v-model="standardMenu.subMenu" class="form-control" id="displayName" placeholder="Enter Sub Menu Name">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="displayName">Display Name</label>
                                                                                <input type="text" v-model="standardMenu.displayName" class="form-control" id="displayName" placeholder="Enter Display Name">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="webUrl">User Role Type</label>
                                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.standardMenu.userRoleTypeId.$error }">
                                                                                    <select v-model="standardMenu.userRoleTypeId" class="form-control" :class="{ 'is-invalid': $v.standardMenu.userRoleTypeId.$error }" id="userRoleTypeId">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in userRoleTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                                    </select>
                                                                                    <div class="required-icon"></div>
                                                                                </div>
                                                                                <label v-if="$v.standardMenu.userRoleTypeId.$error && !$v.standardMenu.userRoleTypeId.required" class="text-danger">Please Select User Role Type</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="webUrl">Web Url</label>
                                                                                <input type="text" v-model="standardMenu.webUrl" class="form-control" id="webUrl" placeholder="Enter Web Url">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-3">
                                                                            <div class="form-group">
                                                                                <label for="icon">Icon Class</label>
                                                                                <input type="text" v-model="standardMenu.icon" class="form-control" id="icon" placeholder="Enter Icon Class">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-2">
                                                                            <div class="form-group">
                                                                                <label for="icon">Is Basic</label>
                                                                                <div class="input-group ">
                                                                                    <b-form-checkbox class="custom-toggle" v-model="standardMenu.basic" name="check-button" switch>
                                                                                    </b-form-checkbox>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-2">
                                                                            <div class="form-group">
                                                                                <label for="icon">Is Add-On</label>
                                                                                <div class="input-group ">
                                                                                    <b-form-checkbox class="custom-toggle" v-model="standardMenu.isAddOn" name="check-button" switch>
                                                                                    </b-form-checkbox>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-3 col-md-2">
                                                                            <div class="form-group">
                                                                                <label for="icon">Active</label>
                                                                                <div class="input-group ">
                                                                                    <b-form-checkbox class="custom-toggle" v-model="standardMenu.active" name="check-button" switch>
                                                                                    </b-form-checkbox>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <button type="submit" @click="saveStandardMenu" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                    
                                                    <div class="form-row view-filter">
                                                        <div class="col-2 col-sm-2">
                                                            <div class="dataTables_filter">
                                                                <label>
                                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col px-0">
                                                            <div class="form-row">
                                                                <div class="col">
                                                                    <b-input-group prepend="VMX Service" id="vmxServiceId">
                                                                        <select v-model="standardMenuFilterData.vmxServiceId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>                                                     
                                                                <div class="col">
                                                                    <b-input-group prepend="Menu Type" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.standardMenuTypeId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Module" id="moduleId" >
                                                                        <select v-model="standardMenuFilterData.moduleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredModules(standardMenuFilterData.vmxServiceId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Sub Module" id="subModuleId" >
                                                                        <select v-model="standardMenuFilterData.subModuleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredSubModules(standardMenuFilterData.moduleId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Main Menu" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.mainMenuId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in mainMenuLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-sm-1">
                                                            <div class="dataTables_length">
                                                                <b-form-group label="" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filteredStandardMenus" @row-selected="onRowSelected" :fields="standardMenuFields" responsive="sm" :current-page="currentStdMenuPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onStdMenuFiltered">
                                                        <template v-slot:cell(standardMenuTypeId)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('standard_menu_type_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                                    <!-- <option value="">Select</option> -->
                                                                    <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId"
                                                                    >{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.standardMenuType ? data.item.standardMenuType : 'Select' }}</div>
                                                        </template>
                                                        <template v-slot:cell(moduleId)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('module_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                                    <!-- <option value="">Select</option> -->
                                                                    <option v-for="(info, index) in getFilteredModules(data.item.vmxServiceId)" :value="info.id"
                                                                    >{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.module ? data.item.module : 'Select' }}</div>
                                                        </template>
                                                        <template v-slot:cell(subModuleId)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <!-- <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('standard_menu_type', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)"> -->
                                                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('sub_module_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                                    <!-- <option value="">Select</option> -->
                                                                    <option v-for="(info, index) in getFilteredSubModules(data.item.moduleId)" :value="info.id"
                                                                    >{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.subModule ? data.item.subModule : 'Select' }}</div>
                                                        </template>
                                                        <template v-slot:cell(mainMenuId)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('main_menu_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                                    <!-- <option value="">Select</option> -->
                                                                    <option v-for="(info, index) in mainMenuLookup" :value="info.id"
                                                                    >{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.mainMenu ? data.item.mainMenu : 'Select' }}</div>
                                                        </template>
                                                        <template v-slot:cell(subMenu)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('sub_menu', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                                        </template>
                                                        <template v-slot:cell(displayName)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('display_name', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                                        </template>
                                                        <template v-slot:cell(menuOrder)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('menu_order', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                                        </template>
                                                        <template v-slot:cell(userRoleTypeId)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('user_role_type_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                                    <option v-for="(info, index) in userRoleTypes" :value="info.typeLookupId"
                                                                    >{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.userRoleType ? data.item.userRoleType : 'Select' }}</div>
                                                        </template>

                                                        <template v-slot:cell(basic)="data">
                                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege('basic',data.item.id,data.value)">
                                                            </b-form-checkbox>
                                                        </template>                                                    
                                                        <template v-slot:cell(active)="data">
                                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege('active',data.item.id,data.value)">
                                                            </b-form-checkbox>
                                                        </template>                                                                                                        
                                                        <template v-slot:cell(actions)="data">
                                                            <a href="javascript:" class="text-center" title="Custom Menu Reset" @click.prevent="customMenuReset(data.item.id)"><i class="fa fa-refresh pr-1" aria-hidden="true"></i></a>                                                                                                                        
                                                            <a href="javascript:" title="Delete" @click="deleteStandardMenu(data.item.id)" data-toggle="modal" data-target="#deleteScopeStatement"><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template >
                                                            <div class="text-center">No Modules found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalStdMenuRows}} Items</span>                                                
                                                    <b-pagination v-if="standardMenus.length!=0" pills v-model="currentStdMenuPage" :total-rows="totalStdMenuRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
    <!--Menu - Icon & URL-->
                                <b-tab title="Menu Entity">
                                    <b-card-text>
                                        <div class="card ">
                                            <div class="card-body p-3">
                                                <div class="menu_cont " id="subMenu">
                                                    <div class="row view-filter">
                                                        <div class="col-sm-2">
                                                            <div class="float-left">
                                                                <div class="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                        </div>                                                        
                                                        <div class="col">
                                                            <div class="form-row">
                                                                <div class="col">
                                                                    <b-input-group prepend="VMX Service" id="vmxServiceId" >
                                                                        <select v-model="standardMenuFilterData.vmxServiceId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>                                                     
                                                                <div class="col">
                                                                    <b-input-group prepend="Menu Type" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.standardMenuTypeId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Module" id="moduleId" >
                                                                        <select v-model="standardMenuFilterData.moduleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredModules(standardMenuFilterData.vmxServiceId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Sub Module" id="subModuleId" >
                                                                        <select v-model="standardMenuFilterData.subModuleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredSubModules(standardMenuFilterData.moduleId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Main Menu" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.mainMenuId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in mainMenuLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>

                                                            </div>
                                                        </div>                                                        
                                                        <div class="col-sm-1">
                                                            <div class="float-right">
                                                                <div class="dataTables_length">
                                                                    <b-form-group label="" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filteredStandardMenus" @row-selected="onRowSelected" :fields="menuEntityFields" responsive="sm" :current-page="currentMenuEntityPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onMenuEntityFiltered">

                                                        <template v-slot:cell(entity)="data">
                                                            <div v-if="data.item.id == idEdit" class="action-link">
                                                                <select :id="data.field.key+data.item.id" v-model="stdEntity" @change="updateStdEntity(data.item.id,stdEntity)" class="form-control" @blur="cancelIdEdit()">
                                                                    <option :value="{id:null,value:null}">Select</option>
                                                                    <option v-for="(info, index) in entities" :value="{id:info.id,value:info.entity}"
                                                                    >{{info.entity}}</option>
                                                                </select>                            
                                                            </div>
                                                            <div v-else class="action-link" @dblclick="setStdEntityEdit(data.field.key+data.item.id, data.item)"> 
                                                            {{ data.item.entity ? data.item.entity : 'Select' }}</div>
                                                        </template>  

                                                        <template >
                                                            <div class="text-center">No Menu found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                    
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalMenuEntityRows}} Items</span>                                                
                                                    <b-pagination v-if="standardMenus.length!=0" pills v-model="currentMenuEntityPage" :total-rows="totalMenuEntityRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>                            
    <!--Menu - Icon & URL-->
                                <b-tab title="Icon & URL">
                                    <b-card-text>
                                        <div class="card ">
                                            <div class="card-body p-3">
                                                <div class="menu_cont " id="subMenu">
                                                    <div class="row view-filter">
                                                        <div class="col-2 col-sm-2">
                                                            <div class="float-left">
                                                                <div class="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                        </div>                                                        
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <b-input-group prepend="VMX Service" id="vmxServiceId" >
                                                                        <select v-model="standardMenuFilterData.vmxServiceId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>                                                     
                                                                <div class="col">
                                                                    <b-input-group prepend="Menu Type" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.standardMenuTypeId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Module" id="moduleId" >
                                                                        <select v-model="standardMenuFilterData.moduleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredModules(standardMenuFilterData.vmxServiceId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Sub Module" id="subModuleId" >
                                                                        <select v-model="standardMenuFilterData.subModuleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredSubModules(standardMenuFilterData.moduleId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Main Menu" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.mainMenuId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in mainMenuLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-sm-1">
                                                            <div class="float-right">
                                                                <div class="dataTables_length">
                                                                    <b-form-group label="" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filteredStandardMenus" @row-selected="onRowSelected" :fields="menuUrlIconFields" responsive="sm" :current-page="currentIconPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onIconFiltered">
                                                        <template v-slot:cell(icon)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('icon', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                                        </template>       
                                                        <template v-slot:cell(webUrl)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('web_url', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>

                                                        </template> 
                                                        <template >
                                                            <div class="text-center">No Menu found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                    
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalIconRows}} Items</span>                                                
                                                    <b-pagination v-if="standardMenus.length!=0" pills v-model="currentIconPage" :total-rows="totalIconRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>
    <!--Menu Functional Info-->
                                <b-tab title="Menu Functional Info">
                                    <b-card-text>
                                        <div class="card ">
                                            <div class="card-body p-3">
                                                <div class="menu_cont " id="menuIntegration">
                                                    <div class="row view-filter">
                                                        <div class="col-2 col-sm-2">
                                                            <div class="float-left">
                                                                <div class="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                        </div>                                                        
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <b-input-group prepend="VMX Service" id="vmxServiceId" >
                                                                        <select v-model="standardMenuFilterData.vmxServiceId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>                                                     
                                                                <div class="col">
                                                                    <b-input-group prepend="Menu Type" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.standardMenuTypeId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Module" id="moduleId" >
                                                                        <select v-model="standardMenuFilterData.moduleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredModules(standardMenuFilterData.vmxServiceId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Sub Module" id="subModuleId" >
                                                                        <select v-model="standardMenuFilterData.subModuleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredSubModules(standardMenuFilterData.moduleId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Main Menu" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.mainMenuId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in mainMenuLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>

                                                            </div>
                                                        </div>                                                        

                                                        <div class="col-sm-1">
                                                            <div class="float-right">
                                                                <div class="dataTables_length">
                                                                    <b-form-group label="" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="standardMenus" @row-selected="onRowSelected" :fields="menuInfoFields" responsive="sm" :current-page="currentStdMenuPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onStdMenuFiltered">
                                                        <template v-slot:cell(mainMenu)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('main_menu', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                                        </template>       
                                                        <template v-slot:cell(subMenu)="data">
                                                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('sub_menu', data.item.id, data.value)" @blur="cancelInlineEdit(standardMenus, data.field.key)">
                                                            </div>
                                                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                                        </template>   
                                                        <template v-slot:cell(functionalInfo)="data">
                                                            <div v-if="data.value" @dblclick="setFunctionalInfoUpdate('functional_info', data.item.id, data.value);" class="action-link description-truncate" v-html="data.value"></div>
                                                            <div v-else class="action-link" @dblclick="setFunctionalInfoUpdate('functional_info', data.item.id, data.value);">Edit</div>
                                                        </template>
                                                        <template v-slot:cell(basic)="data">
                                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege('basic',data.item.id,data.value)">
                                                            </b-form-checkbox>
                                                        </template>                                                    
                                                        <template v-slot:cell(active)="data">
                                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege('active',data.item.id,data.value)">
                                                            </b-form-checkbox>
                                                        </template>                                                                                                        
                                                        <template v-slot:cell(actions)="data">
                                                            <a href="javascript:" title="Delete" @click="deleteStandardMenu(data.item.id)" data-toggle="modal" data-target="#deleteScopeStatement"><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template >
                                                            <div class="text-center">No Modules found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalStdMenuRows}} Items</span>                                                
                                                    <b-pagination v-if="standardMenus.length!=0" pills v-model="currentStdMenuPage" :total-rows="totalStdMenuRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab>

    <!--Menu List View-->
                                <b-tab title="Standard Menu List View">
                                    <b-card-text>
                                        <div class="card ">
                                            <div class="card-body p-3">
                                                <div class="menu_cont " id="menuIntegration">
                                                    <div class="row view-filter">
                                                        <div class="col-2 col-sm-2">
                                                            <div class="float-left">
                                                                <div class="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="clearfix"></div>
                                                        </div>                                                        

                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <b-input-group prepend="VMX Service" id="vmxServiceId" >
                                                                        <select v-model="standardMenuFilterData.vmxServiceId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>                                                     
                                                                <div class="col">
                                                                    <b-input-group prepend="Menu Type" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.standardMenuTypeId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Module" id="moduleId" >
                                                                        <select v-model="standardMenuFilterData.moduleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredModules(standardMenuFilterData.vmxServiceId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Sub Module" id="subModuleId" >
                                                                        <select v-model="standardMenuFilterData.subModuleId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in getFilteredSubModules(standardMenuFilterData.moduleId)" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>
                                                                <div class="col">
                                                                    <b-input-group prepend="Main Menu" id="standardMenuTypeId" >
                                                                        <select v-model="standardMenuFilterData.mainMenuId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in mainMenuLookup" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-input-group>
                                                                </div>

                                                            </div>
                                                        </div>  
                                                        <div class="col-sm-1">
                                                            <div class="float-right">
                                                                <div class="dataTables_length">
                                                                    <b-form-group label="" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </div>
                                                            </div>
                                                        </div>                                                             
                                                    </div>
                                                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filteredStandardMenus" @row-selected="onRowSelected" :fields="userTypeMenuAccessFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                        <template v-slot:cell(active)="data">

                                                            <div v-if="data.value">
                                                                <i class="fa fa fa-check"></i>
                                                            </div>
                                                            <div v-else>
                                                                <i class="fa fa fa-remove"></i>
                                                            </div>                                                            

                                                        </template>                                                                                                                
                                                        <template >
                                                            <div class="text-center">No Menu found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                                
                                                    <b-pagination v-if="standardMenus.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-text>
                                </b-tab> 

                            </b-overlay>
                        </b-tabs>
                    </div>
                </div>
			</div>
        </div>
        <DescriptionEditPopup id="functionalInfoUpdatePopup" ref="functionalInfoUpdatePopupRef" :textValue="functionalInfoValue" :onSave="updateFunctionalInfo" :onCancel="clearFunctionalInfo" :returnParams="descriptionEditParams" title="Functional Info" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import DescriptionEditPopup from '@/components/popup/dialogbox/DescriptionEditPopup.vue';

export default {
    name: 'mainmenu',
    components: {
        DescriptionEditPopup,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
                modalOpen: false,
            },

            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            entity: 'standard_menu',
            userId: localStorage.getItem("userId"), 

            standardMenuId: '',           

            idEdit: '',

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            functionalInfoValue: '',

            textValue: '',
            textEdit: '',

            numberValue: '',
            numberEdit: '',

            stdEntity:{
                id: null,
                value: null,
            },

            standardMenuFilterData: {
                vmxServiceId: '',
                standardMenuTypeId: '',
                moduleId: '',
                subModuleId: '',
                mainMenuId: '',
            },

            standardMenu: {
                id: '',
                standardMenuId: '',
                vmxServiceId: '',
                standardMenuTypeId: '',
                menuMasterEntityId: '',
                menuMasterEntity: '',
                moduleData: '',
                moduleId: '',
                module: '',
                subModuleData: '',
                subModuleId: '',
                subModule: '',
                mainMenuData: '',
                mainMenuId: '',
                mainMenu: '',
                subMenuData: '',
                subMenuId: '',
                subMenu: '',
                displayName: '',
                entityId: '',
                entity: '',
                userRoleTypeId: '',
                webUrl: '',
                icon: '',
                menuOrder: '',
                functionalInfo: '',
                basic: false,
                isAddOn: false,
                active: false,
            },

            standardMenus: [],
            entities: [],
            standardMenuTypes: [],
            userRoleTypes: [],
            vmxServiceLookup: [],
            moduleLookup: [],
            subModuleLookup: [],
            mainMenuLookup: [],
            subMenuLookup: [],

            standardMenuFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'standardMenuTypeId', label: 'Std Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'vmxService', label: 'VMX Service', sortable: true, sortDirection: 'desc' },
                { key: 'moduleId', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'subModuleId', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenuId', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'menuOrder', label: 'Order', sortable: true, sortDirection: 'desc' }, 
                { key: 'userRoleTypeId', label: 'User Role Type', sortable: true, sortDirection: 'desc' },                
                { key: 'basic', label: 'Basic', sortable: true, sortDirection: 'desc' },                
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },                
                { key: 'actions', label: 'Actions' }
            ],
            menuEntityFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'vmxService', label: 'VMX Service', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                // { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' }, 
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'entityId', label: 'Entity Id', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'entityDisplayName', label: 'Entity Name', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],                
            menuUrlIconFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'vmxService', label: 'VMX Service', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' }, 
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'icon', label: 'Icon', sortable: true, sortDirection: 'desc' },
                { key: 'iconView', label: 'Icon View', sortable: true, sortDirection: 'desc' },
                { key: 'webUrl', label: 'Url', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],
            userTypeMenuAccessFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'vmxService', label: 'VMX Service', sortable: true, sortDirection: 'desc' },
                { key: 'standardMenuType', label: 'Std Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                // { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' }, 
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'menuOrder', label: 'Menu Order', sortable: true, sortDirection: 'desc' },
                { key: 'userRoleType', label: 'User Role Type', sortable: true, sortDirection: 'desc' },
                { key: '', label: 'Icon', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
                ],                
            menuInfoFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'vmxService', label: 'VMX Service', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                // { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' }, 
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'functionalInfo', label: 'Functional Info', sortable: true, sortDirection: 'desc' },
            ],     
            
            selectMode: 'single',

            totalMainMenuRows: 1,
            totalStdMenuRows: 1,
            totalMenuEntityRows: 1,
            totalIconRows: 1,
            totalRows: 1,

            currentMainMenuPage: 1,
            currentStdMenuPage: 1,
            currentMenuEntityPage: 1,
            currentIconPage: 1,
            currentPage: 1,

            perPage: 10,
            pageOptions: [5, 10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            
            descriptionEditParams: {
                id: '',
                value: '',
                columnName: '',
            }
        }
    },
    computed: {
        filteredStandardMenus() {
            let vmxServiceId = this.standardMenuFilterData.vmxServiceId;
            let standardMenuTypeId = this.standardMenuFilterData.standardMenuTypeId;
            let moduleId = this.standardMenuFilterData.moduleId;
            let subModuleId = this.standardMenuFilterData.subModuleId;
            let mainMenuId = this.standardMenuFilterData.mainMenuId;
            let filteredStandardMenus = [];

            let filterArray1 = [];
            if (standardMenuTypeId) {
                filterArray1 = this.standardMenus.filter(data => {
                    if (data.standardMenuTypeId == standardMenuTypeId) {
                        return data;
                    }
                });
            }
            else filterArray1 = this.standardMenus;

            let filterArray2 = [];
            if (moduleId) {
                filterArray2 = filterArray1.filter(data => {
                    if (data.moduleId == moduleId) {
                        return data;
                    }
                });
            }
            else filterArray2 = filterArray1;

            let filterArray3 = [];
            if (subModuleId) {
                filterArray3 = filterArray2.filter(data => {
                    if (data.subModuleId == subModuleId) {
                        return data;
                    }
                });
            }
            else filterArray3 = filterArray2;

            let filterArray4 = [];
            if (mainMenuId) {
                filterArray4 = filterArray3.filter(data => {
                    if (data.mainMenuId == mainMenuId) {
                        return data;
                    }
                });
            }            
            else filterArray4 = filterArray3;

            let filterArray5 = [];
            if (vmxServiceId) {
                filterArray5 = filterArray4.filter(data => {
                    if (data.vmxServiceId == vmxServiceId) {
                        return data;
                    }
                });
            }            
            else filterArray5 = filterArray4;

            filteredStandardMenus = filterArray5;
            this.totalStdMenuRows = filteredStandardMenus.length;
            this.totalMenuEntityRows = filteredStandardMenus.length;
            this.totalIconRows = filteredStandardMenus.length;
            this.totalRows = filteredStandardMenus.length;
            return filteredStandardMenus;
        },
        getModule() {
            let moduleName = '';
            if (this.standardMenu.moduleId) {
                let item = this.moduleLookup.find(data => data.id == this.standardMenu.moduleId);
                moduleName = item ? item.value : '';
            }
            return moduleName;
        },
        getSubModule() {
            let subModule = '';
            if (this.standardMenu.subModuleId) {
                let item = this.subModuleLookup.find(data => data.id == this.standardMenu.subModuleId);
                subModule = item ? item.value : '';
            }
            return subModule;
        },
    },
    validations: {
        standardMenu: {
            id: {
                required,
            },
             vmxServiceId: {
                required,
            },
            standardMenuTypeId: {
                required,
            },
            moduleId: {
                required,
            },
            userRoleTypeId: {
                required,
            },
        }
    },
    mounted() {
        this.getStandardMenuList();
        this.getLookupData();
    },
    methods: {
        onMainMenuFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalMainMenuRows = filteredItems.length
            this.currentMainMenuPage = 1
        },
        onStdMenuFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalStdMenuRows = filteredItems.length
            this.currentStdMenuPage = 1
        },
        onMenuEntityFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalMenuEntityRows = filteredItems.length
            this.currentMenuEntityPage = 1
        },

        onIconFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalIconRows = filteredItems.length
            this.currentIconPage = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        // saveData: function() {
        //     this.$v.standardMenu.$touch();
        //     if (!this.$v.standardMenu.$invalid) {
        //         if (this.elements.editMode) {
        //             this.updateStandardMenu();
        //         }
        //         else {
        //             this.saveStandardMenu();
        //         }
        //     }
        // },
        saveStandardMenu: function() {
            this.standardMenu.standardMenuId = this.standardMenu.id;
            this.standardMenu.module = this.getModule;
            this.standardMenu.subModule = this.getSubModule;
            this.$v.standardMenu.$touch();
            if (!this.$v.standardMenu.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/menu/standardmenu/add', this.standardMenu)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.getStandardMenuList();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    });
            }
        },
        getStandardMenuList: function() {
            
            axios.post(this.$store.getters.getAPIBasePath + '/menu/standardmenu/list')
                .then((response) => {
                
                    let result = response.data;
                    this.standardMenus = result.standardMenuList;
                    this.totalStdMenuRows = this.standardMenus.length;
                    this.totalMenuEntityRows = this.standardMenus.length;
                    this.totalIconRows = this.standardMenus.length;
                    this.totalRows = this.standardMenus.length;
                })
        },  
        getLookupData: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/menu/standardmenu/lookup/all')
                .then((response) => {
                    let result = response.data;
                    this.standardMenuTypes = result.typeLookup;
                    this.userRoleTypes = result.userRoleTypeLookup;
                    
                    this.vmxServiceLookup = result.vmxServiceLookup;
                    this.moduleLookup = result.moduleLookup;
                    this.subModuleLookup = result.subModuleLookup;
                    this.mainMenuLookup = result.mainMenuLookup;
                    this.subMenuLookup = result.subMenuLookup;
                    this.entities = result.entityLookup;
                    this.sortEntities();
                })

        },
        updateStdEntity: function(id,stdEntity) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
                columnName: 'entity_id',
                id: id,
                numberValue: stdEntity.id,
                userId: this.userId,
            }
            this.stdEntity.id = stdEntity.id;
            this.stdEntity.value = stdEntity.value;
            
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/numbervalue', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.setEntityMasterStatus(id);                        
                        this.cancelIdEdit();
                        // this.getEntities('');
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        setStdEntityEdit: function(cellId, data) {
            this.idEdit = data.id;
            this.stdEntity.id = data.entityId;
            this.stdEntity.value = data.entity;

            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelIdEdit: function() {
            this.standardMenus = this.standardMenus.map(data => {
                if(data.id == this.idEdit) {
                    data.stdEntityId = this.stdEntity.id;
                    data.stdEntity = this.stdEntity.value;
                    
                    console.log(this.stdEntity.id,this.stdEntity.value);
                    return data;
                }
                else{
                    return data;
                }

            });

            this.idEdit='';
            this.stdEntity.id = null;
            this.stdEntity.value = null;

        },                

        updateInlineNumberValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: this.entity,
                columnName: columnName,
                numberValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/update/numbervalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getStandardMenuList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: this.entity,
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getStandardMenuList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },
        assignPrivilege: function(columnName,rowId,fieldValue) {

            fieldValue = (fieldValue)? "false" : "true";

            let data = {
                entity: this.entity,
                columnName: columnName,
                stateValue: fieldValue,
                id: rowId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getStandardMenuList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })                 
        },
        customMenuReset: function(standardMenuId) {
            let data = {
                standardMenuId: standardMenuId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/custommenu/reset', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        console.log(result.message);
                        // this.getStandardMenuList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })                 
        },        

        clearFunctionalInfo: function() {
            this.functionalInfoValue = '';
        },
        setFunctionalInfoUpdate: function(columnName, id, value) {
            this.descriptionEditParams.id = id;
            this.descriptionEditParams.value = value;
            this.descriptionEditParams.columnName = columnName;

            this.functionalInfoValue = value;
            this.$refs.functionalInfoUpdatePopupRef.setDescriptionData(value);
            this.$nextTick(() => {
                this.$bvModal.show('functionalInfoUpdatePopup');
            })
        },
        updateFunctionalInfo: function(value, returnParams) {
            this.descriptionEditParams.id = returnParams.id;
            this.descriptionEditParams.value = returnParams.value;
            this.descriptionEditParams.columnName = returnParams.columnName;

            this.updateInlineTextValue(this.descriptionEditParams.columnName, this.descriptionEditParams.id, value);
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;

            this.standardMenu.id = '';
            this.standardMenu.vmxServiceId = '';
            this.standardMenu.standardMenuId = '';
            this.standardMenu.standardMenuTypeId = '';
            this.standardMenu.menuMasterEntityId = '';
            this.standardMenu.menuMasterEntity = '';
            this.standardMenu.moduleId = '';
            this.standardMenu.module = '';
            this.standardMenu.subModuleId = '';
            this.standardMenu.subModule = '';
            this.standardMenu.mainMenuId = '';
            this.standardMenu.mainMenu = '';
            this.standardMenu.subMenuId = '';
            this.standardMenu.subMenu = '';
            this.standardMenu.displayName = '';
            this.standardMenu.entityId = '';
            this.standardMenu.entity = '';
            this.standardMenu.userRoleTypeId = '';
            this.standardMenu.webUrl = '';
            this.standardMenu.icon = '';
            this.standardMenu.menuOrder = '';
            this.standardMenu.functionalInfo = '';
            this.standardMenu.basic = false;
            this.standardMenu.isAddOn = false;
            this.standardMenu.active = false;
            this.$v.standardMenu.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                // this.elements.viewMode = true;

                // this.standardMenu.moduleId = data.moduleId.toString();
                // this.standardMenu.module = data.module;

                // this.standardMenu.mainMenuId = data.mainMenuId.toString();
                // this.standardMenu.mainMenu = data.mainMenu;
                // this.standardMenu.subMenuId = data.subMenuId.toString();
                // this.standardMenu.subMenu = data.subMenu;
            }
        },
        getFilteredModules(vmxServiceId) {
            let modules = this.moduleLookup;
            if (this.moduleLookup.length > 0 && vmxServiceId) {
                modules = this.moduleLookup.filter(data => data.vmxServiceId == vmxServiceId);
            }
            return modules;
        },
        getFilteredSubModules(moduleId) {
            let subModules = this.subModuleLookup;
            if (this.subModuleLookup.length > 0 && moduleId) {
                subModules = this.subModuleLookup.filter(data => data.moduleId == moduleId);
            }
            return subModules;
        },
        sortEntities() {
        
            this.entities = this.entities.sort((a,b) => {
                let fa = a.entity.toLowerCase(), fb = b.entity.toLowerCase();
                if (fa < fb) {
                    return -1
                }
                if (fa > fb) {
                    return 1
                }
                return 0
            })
        },


    }
}
</script>