<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <b-input-group size="sm" prepend="Cost Estimation Title" class="mb-2">
                                <select v-if="elements.showErmDropdown" v-model="ermData.entityRecordMasterId" @change="getCostEstimationList" class="form-control" :class="{ 'is-invalid': $v.ermData.entityRecordMasterId.$error }">
                                    <option value="">Select Cost Estimation</option>
                                    <option v-for="(info, index) in ermRefLookupAll" :value="info.id">{{info.value}}</option>
                                </select>
                                <b-form-input v-else type="text" v-model="ermData.ermTitle" :disabled="!elements.showErmAdd" :class="{ 'is-invalid': $v.ermData.ermTitle.$error }" placeholder="Enter Title" ></b-form-input>
                            </b-input-group>
                            <label v-if="$v.ermData.entityRecordMasterId.$error && !$v.ermData.entityRecordMasterId.required || $v.ermData.ermTitle.$error && !$v.ermData.ermTitle.required" class="text-danger">Cost Est. Title is required</label>
                        </div>
                        <div class="col-auto pl-0">
                            <a href="javascript:" v-if="!elements.showErmDropdown && !elements.showErmAdd" class="action-link py-1" @click="showEffSheetEdit" title="Add New Cost Estimation Sheet">
                                <i class="fa fa-lg fa-pencil-square-o mt-2"></i>
                            </a>
                            <a href="javascript:" v-else-if="elements.showErmDropdown" class="action-link py-1" @click="showEffSheetAdd" title="Select Existing Sheet">
                                <i class="fa fa-lg fa-plus-circle mt-2"></i>
                            </a>
                            <a href="javascript:" v-else class="action-link py-1" @click="showEffSheetDropdown" title="Select Existing Sheet">
                                <i class="fa fa-lg fa-caret-down mt-2"></i>
                            </a>
                            <div v-if="elements.showErmAdd" class="d-inline-block ml-2">
                                <button class="btn sbtn btn-primary px-2 py-1 mr-1" @click="addEstimationSheetRef" title="Create Cost Estimation Sheet">
                                    <i class="fa fa-lg fa-floppy-o mr-2"></i>Save
                                </button>
                                <button class="btn sbtn btn-secondary px-2 py-1" @click="cancelEffSheetAdd" title="Select Existing Sheet">
                                    <i class="fa fa-lg fa-times mr-2"></i>Cancel
                                </button>
                                <!-- <button v-else class="btn sbtn btn-primary px-2 py-1" @click="elements.showErmAdd=true;" title="Create New Cost Estimation Sheet">
                                    <i class="fa fa-lg fa-plus-square-o mr-2"></i>Create Cost Estimation Sheet
                                </button> -->
                            </div>
                        </div>
                        <div class="col-5">
                            <!-- <b-input-group size="sm" prepend="Plan Item Link" class="mb-2">
                                <b-form-input type="text" v-model="erdTitle" :placeholder="erdTitle ? '' : '(Unnamed Plan)'" disabled></b-form-input>
                            </b-input-group> -->
                        </div>
                    </div>
                </div>
            </div>
            <b-card v-if="!elements.showErmAdd" no-body class="mt-3">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-costEst>
                        <span>Cost Estimation</span>
                    </label>
                </b-card-header>
                <b-collapse id="accordion-costEst" visible class="p-1" role="tabpanel">
                    <button v-if="!elements.showInlineControls" class="btn sbtn btn-primary btn-md float-right mb-2" @click.prevent="setCostEstimationAdd"><i class="fa fa-plus-circle mr-2" aria-hidden="true"></i>New Cost Estimation</button>
                    <b-table show-empty small outlined stacked="md"  sort-icon-left :items="costEstimations" :fields="costEstimationFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(title)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <input type="text" v-model="costEstimation.title" placeholder="Enter Title" class="form-control" :class="{ 'is-invalid': $v.costEstimation.title.$error }" id="csTitle">
                                <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.minLength" class="text-danger">Title must have at least {{$v.costEstimation.title.$params.minLength.min}} letters.</label>
                                <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.required" class="text-danger">Title is required</label>
                                <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.maxLength" class="text-danger">Title must not exceed {{$v.costEstimation.title.$params.maxLength.max}} letters.</label>
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId"> {{ data.item.title }}</div>
                        </template>
                        <!-- <template v-if="elements.showInlineControls" v-slot:cell(ermTitle)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <div class="row no-gutters">
                                    <div class="col">
                                        <select v-if="elements.showErmDropdown" v-model="costEstimation.entityRecordMasterId" class="form-control" :class="{ 'is-invalid': $v.costEstimation.entityRecordMasterId.$error }">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in ermRefLookup" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <b-form-input v-else type="text" v-model="costEstimation.ermTitle" placeholder="Enter Est. Sheet Title"></b-form-input>
                                    </div>
                                    <div class="col-3 text-center">
                                        <button v-if="elements.showErmDropdown" class="btn sbtn btn-outline-primary px-2 py-1" @click="costEstimation.entityRecordMasterId='';elements.showErmDropdown=false;" title="Add New Effort Estimation Sheet">
                                            <i class="fa fa-lg fa-plus-square-o"></i>
                                        </button>
                                        <button v-else class="btn sbtn btn-outline-primary px-2 py-1" @click="costEstimation.ermTitle='';elements.showErmDropdown=true;" title="Select Existing Sheet">
                                            <i title="Select Existing Sheet" @click="" class="fa fa-lg fa-caret-down"></i>
                                        </button>
                                    </div>
                                </div>
                                <label v-if="$v.costEstimation.entityRecordMasterId.$error && !$v.costEstimation.entityRecordMasterId.required" class="text-danger">Cost Est. Group is required</label>
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId"> {{ data.item.ermTitle }}</div>
                        </template> -->
                        <template v-if="elements.showInlineControls" v-slot:cell(activity)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <select v-model="costEstimation.activityId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId"> {{ data.item.activity }}</div>
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(quantity)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <input type="number" v-model="costEstimation.quantity" @input="setTotalCost" class="form-control" min="0" id="csQuantity">
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId"> {{ data.item.quantity }}</div>
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(uomType)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <select v-model="costEstimation.unitOfMesurement" class="form-control">
                                    <option :value="{id: '', text:''}">Select</option>
                                    <option v-for="(info, index) in uomTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                </select>
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId"> {{ data.item.uomType }}</div>
                        </template>
                        <template v-slot:cell(unitCost)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="mx-auto">{{ currencySymbol }}</span>
                                        </div>
                                    </div>
                                    <input type="number" v-model="costEstimation.unitCost" @input="setTotalCost" class="form-control" min="0" id="csPrice">
                                </div>
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId || !elements.showInlineControls">{{ currencySymbol }}{{ data.item.unitCost }}</div>
                        </template>
                        <template v-slot:cell(cost)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <input type="number" v-model="costEstimation.cost" class="form-control" min="0" id="csPrice">
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId || !elements.showInlineControls">{{ currencySymbol }}{{ data.item.cost }}</div>
                        </template>
                        <template v-slot:cell(planTitle)="data">
                            <div v-if="data.item.id == costEstimationEditId">
                                <select v-model="costEstimation.erdId" class="form-control" >
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in planList" :value="info.id">{{info.title}}</option>
                                </select>
                            </div>
                            <div v-if="data.item.id !== costEstimationEditId || !elements.showInlineControls"> {{ data.value }}</div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div v-if="data.item.id == costEstimationEditId && elements.showInlineControls">
                                <div v-if="data.item.id == costEstimationEditId">
                                    <a href="javascript:" title="Save" @click="saveCostEstimation"><i class="fa fa-check text-success pl-2"></i></a>
                                    <a href="javascript:" title="Cancel" @click="cancelCostEstEdit"><i class="fa fa-times text-danger pl-2"></i></a>
                                </div>
                            </div>
                            <div v-else >
                                <a href="javascript:" title="Edit" class="" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                <a href="javascript:" title="Delete Cost Estimation" class="" @click="deleteCostEstimation(data.item.id)"v-b-modal.deleteCostEstimation><i class="fa fa-trash ml-2"></i></a>
                            </div>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <div>
                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                        <span class="float-right">Total Cost: <span class="font-weight-bold"><span>{{ currencySymbol }} {{ totalCost.toFixed(2)}}</span></span></span>
                    </div>
                </b-collapse>
            </b-card>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
        <DeleteComponent id="deleteCostEstimation" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Cost Estimation?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'costEstimationLinkPopup'},
        ermId: [String, Number],
        erdId: [String, Number],
        erdTitle: String,
        refEntity: String,
        refErmEntity: String,
        linkedCostEstList: Array,
    },
    components: {
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
                showInlineControls: false,
                showErmAdd: false,
                showErmDropdown: false,
            },
            isDirectAdd: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),
            entity: 'cost_estimation',

            memoryItem: {},

            selectedCostEstimationList: [],
            costEstimationEditId: '',
            refErmTitle: '',
            refErmId: '',
            ermData: {
                ermTitle: '',
                entityRecordMasterId: '',
            },

            costEstimation: {
                id: '',
                costEstimationId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'cost_estimation',
                entityId: '',
                refEntityId: '',
                activityId: '',
                activity: '',
                erdId: '',
                title: '',
                description: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                uomType: '',
                cost: '',
                unitCost: '',
                preparedById: localStorage.getItem("userId"),
                costEstimationTypeId: '',
                csCategoryId: '',
                ermTitle: '',
                refErmEntity: '',
                refErmId: '',
                isNewErmCreation: false,
            },

            activities: [],
            uomTypes: [],
            planList: [],
            costEstimationList: [],
            ermRefLookup: [],
            ermNonRefLookup: [],
            costEstimationFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Item Title' },
                // { key: 'ermTitle', label: 'Cost Est. Sheet', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'uomType', label: 'Unit' },
                { key: 'unitCost', label: 'Unit Cost' },
                { key: 'cost', label: 'Total Cost' },
                { key: 'planTitle', label: 'Plan Item Link' },
                { key: 'actions', label: 'Actions' }
            ],

            // otherCostEstimationFields: [
            //     { key: 'index', label: 'SN' },
            //     // { key: 'costEstimationId', label: 'Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
            //     { key: 'erdId', label: 'Cost Est. Sheet', sortable: true, sortDirection: 'desc' },
            //     { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
            //     { key: 'resourceTitle', label: 'Resource', sortable: true, sortDirection: 'desc' },
            //     { key: 'resourceCount', label: 'Count', sortable: true, sortDirection: 'desc' },
            //     { key: 'unitCost', label: 'Unit Cost', sortable: true, sortDirection: 'desc' },
            //     { key: 'costHour', label: 'Cost', sortable: true, sortDirection: 'desc' },
            //     { key: 'planTitle', label: 'Linked Plan Item' },
            //     // { key: 'actions', label: 'Actions' },
            // ],

            totalRows: 0,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    validations: {
        costEstimation: {
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            },
            entityRecordMasterId: {
                required,
            },
        },
        ermData: {
            entityRecordMasterId: {
                required: requiredIf(function () {
                    return this.elements.showErmDropdown;
                }),
            },
            ermTitle: {
                required: requiredIf(function () {
                    return !this.elements.showErmDropdown;
                }),
            }
        },
    },
    computed: {
        costEstimations() {
            // let items = this.costEstimationList.filter(data => data.erdId==null || data.erdId == this.erdId);
            let items = this.costEstimationList;
            this.totalRows = items.length;
            return items;
        },
        ermRefLookupAll() {
            return this.ermRefLookup.concat(this.ermNonRefLookup);
        },
        // costEstimationOthersList() {
        //     // let items = this.costEstimationList;
        //     let items = this.costEstimationList.filter(data => data.erdId!=null && data.erdId != this.erdId);
        //     this.totalRows = items.length;
        //     return items;
        // },
        totalCost() {
            if(!this.costEstimations || this.costEstimations.length==0) return 0;
            return this.costEstimations.reduce((accum, item) => {
                return accum + parseFloat(item.cost ?? 0)
            }, 0.00)
        }
    },
    mounted() {
        this.lookupData();
        this.getPlans();
        this.getCostEstimationReference();
        this.loadCostEstimationList();
    },
    methods: {
        getCostEstimationReference: function() {
            if(this.ermId) {
                let data = {
                    entityRecordMasterId: this.ermId,
                    vhextAccountId: this.vhextAccountId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimationref/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.costEstimationList = result.costEstimationList;
                        this.ermRefLookup = result.ermRefLookup;
                        this.ermNonRefLookup = result.ermNonRefLookup;
                        this.totalRows = this.costEstimationList.length;
                        if (this.ermRefLookup && this.ermRefLookup.length > 0) {
                            this.refErmId = this.ermRefLookup[0].id;
                            this.refErmTitle = this.ermRefLookup[0].value;
                            this.ermData.ermTitle = this.ermRefLookup[0].value;
                            this.ermData.entityRecordMasterId = this.ermRefLookup[0].id;
                            this.elements.showErmAdd = false;
                        } else {
                            this.elements.showErmAdd = false;
                            this.elements.showErmDropdown = true;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        getPlans: function() {
            let data = {
                entityRecordMasterId: this.ermId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.planList = result.planList;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,                
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.activities = result.activityLookup;
                    this.uomTypes = result.customTypeLookup;
                })

        },
        loadCostEstimationList() {
            if (this.erdId) {
                this.isDirectAdd = true;
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    erdId: this.erdId,
                    refEntity: this.refEntity,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/erd/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.selectedCostEstimationList = result.costEstimationList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
            else {
                this.selectedCostEstimationList = this.linkedCostEstList && this.linkedCostEstList.length>0 ? [...this.linkedCostEstList] : [];
            }
        },
        saveCostEstimation: function() {
            // if (this.ermRefLookup && this.ermRefLookup.length > 0) {
            //     this.costEstimation.entityRecordMasterId = this.ermRefLookup[0].id;
            //     // this.elements.showErmDropdown = true;
            // }
            this.costEstimation.entityRecordMasterId = this.ermData.entityRecordMasterId;
            this.$v.costEstimation.$touch();
            this.$v.ermData.$touch();
            if (!this.$v.costEstimation.$invalid) {

                // this.costEstimation.isNewErmCreation = !this.elements.showErmDropdown;
                this.costEstimation.refErmEntity = this.refErmEntity;
                this.costEstimation.refErmId = this.ermId;

                if (this.elements.editMode) {
                    this.updateCostEstimation();
                } else {
                    this.addCostEstimation();
                }
            }
        },
        addCostEstimation: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/add', this.costEstimation)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCostEstimationReference();
                        this.clearData();
                        this.costEstimationEditId = '';
                        this.elements.showInlineControls = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateCostEstimation: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/update', this.costEstimation)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCostEstimationReference();
                        this.clearData();
                        this.costEstimationEditId = '';
                        this.elements.showInlineControls = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteCostEstimation: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/costestimation/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCostEstimationReference();
                        this.clearData();
                        this.costEstimationEditId = '';
                        this.elements.showInlineControls = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        addEstimationSheetRef: function() {
            if (this.ermData.entityRecordMasterId) {
                this.ermData.ermTitle = this.getErmTitle(this.ermData.entityRecordMasterId);
            }
            this.$v.ermData.$touch();
            if (!this.$v.ermData.$invalid) {
                let ermData = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    ermTitle: this.ermData.ermTitle,
                    preparedById: this.userId,
                    refErmEntity: this.refErmEntity,
                    refErmId: this.ermId,
                    entityRecordMasterId: this.ermData.entityRecordMasterId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/ermref/add', ermData)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getCostEstimationReference();
                            this.elements.showErmAdd = false;
                            this.$v.costEstimation.$reset();
                            this.$v.ermData.$reset();
                        }
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        getCostEstimationList: function() {
            if (this.ermData.entityRecordMasterId) {
                this.cancelCostEstEdit();
                let data = {
                    entityRecordMasterId: this.ermData.entityRecordMasterId
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.costEstimationList = result.costEstimationList;
                        // this.totalRows = this.costEstimationList.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        costEstimationBulkAdd(data) {
            let isExists = this.isIdExists(data);
            if (isExists) {
                this.selectedCostEstimationList = this.selectedCostEstimationList.filter(item => item.id != data.id);
            } else {
                this.selectedCostEstimationList.push(data);
            }
        },
        updatePlanCostEstLink: function() {
            let data = {
                id: this.erdId,
                vhextAccountId: this.vhextAccountId,
                cost: this.totalCost,
                costEstimationList: this.selectedCostEstimationList,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimationlink/update', data)
                .then((response) => {
                    this.$bvModal.hide(this.id);
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-list');
                        this.getCostEstimationReference();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        setCostEstimationAdd: function() {
            this.clearData();
            this.costEstimationList.push({ id: '', title: this.costEstimation.title, activityId: this.costEstimation.activityId, quantity: this.costEstimation.quantity, uomType: this.costEstimation.uomType, unitCost: this.costEstimation.unitCost, cost: this.costEstimation.cost });
            this.costEstimationEditId = '';
            this.elements.showInlineControls = true;
            // this.elements.newTimeSheet = true;
        },
        cancelCostEstEdit() {
            this.clearData();
            if (!this.elements.editMode) {
                this.costEstimationList.pop();
            }
            this.elements.editMode = false;
            this.costEstimationEditId = '';
            this.elements.showInlineControls = false;
        },
        showEdit: function(data) {
            if (!this.elements.editMode && this.elements.showInlineControls) {
                this.cancelCostEstEdit();
            }
            this.elements.editMode = true;
            this.elements.showInlineControls = true;
            this.costEstimationEditId = data.id;
            this.costEstimation.id = data.id;
            this.costEstimation.costEstimationId = data.costEstimationId;
            this.costEstimation.vhextAccountId = data.vhextAccountId;
            this.costEstimation.projectId = data.projectId;
            this.costEstimation.entityRecordMasterId = data.entityRecordMasterId;
            this.costEstimation.entityId = data.entityId;
            this.costEstimation.refEntityId = data.refEntityId;
            this.costEstimation.erdId = data.erdId;
            this.costEstimation.activityId = data.activityId;
            this.costEstimation.title = data.title;
            this.costEstimation.description = data.description;
            this.costEstimation.quantity = data.quantity;
            this.costEstimation.unitOfMesurement.id = data.uomTypeId;
            this.costEstimation.unitOfMesurement.text = data.uomType;
            this.costEstimation.uomTypeId = data.uomTypeId;
            this.costEstimation.uomType = data.uomType;
            this.costEstimation.cost = data.cost;
            this.costEstimation.unitCost = data.unitCost;
            this.costEstimation.preparedById = data.preparedById;
            this.costEstimation.costEstimationTypeId = data.costEstimationTypeId;
            this.costEstimation.csCategoryId = data.csCategoryId;
        },
        clearData: function() {
            // this.elements.showErmDropdown = false;
            this.costEstimation.id = '';
            this.costEstimation.costEstimationId = '';
            this.costEstimation.title = '';
            this.costEstimation.description = '';
            this.costEstimation.erdId = '';
            this.costEstimation.refEntityId = '';
            this.costEstimation.entityRecordMasterId = '';
            this.costEstimation.activityId = '';
            this.costEstimation.quantity = '';
            this.costEstimation.unitOfMesurement.id = '';
            this.costEstimation.unitOfMesurement.text = '';
            this.costEstimation.uomTypeId = '';
            this.costEstimation.uomType = '';
            this.costEstimation.cost = '';
            this.costEstimation.unitCost = '';
            this.costEstimation.costEstimationTypeId = '';
            this.costEstimation.csCategoryId = '';
            this.$v.costEstimation.$reset();
            this.$v.ermData.$reset();
        },
        getErmTitle(ermId) {
            let item = this.ermNonRefLookup.find(data => data.id == ermId);
            return item && item.value ? item.value : '';
        },
        setTotalCost: function() {
            this.costEstimation.cost = (this.costEstimation.quantity ?? 0) * (this.costEstimation.unitCost ?? 0);
        },
        isIdExists(data) {
            if(!this.selectedCostEstimationList) return false;
            return this.selectedCostEstimationList.some(item => item.id == data.id);
        },
        setCostEstimationList() {
            if (this.isDirectAdd) {
                this.updatePlanCostEstLink();
            }
            else {
                const totalCost = this.selectedCostEstimationList.reduce((sum, obj) => sum + (obj.cost ?? 0), 0);
                let costEstimationList = [...this.selectedCostEstimationList];
                this.$emit('onCostEstimationAdd', costEstimationList, totalCost);
                this.$bvModal.hide(this.id);
            }
        },
        showEffSheetEdit() {
            this.ermData.ermTitle = '';
            this.ermData.entityRecordMasterId = this.refErmId;
            this.elements.showErmAdd = false;
            this.elements.showErmDropdown = true;
        },
        showEffSheetAdd() {
            this.ermData.ermTitle = '';
            this.ermData.entityRecordMasterId = '';
            this.elements.showErmDropdown = false;
            this.elements.showErmAdd = true;
            this.costEstimationList = [];
            this.cancelCostEstEdit();
        },
        showEffSheetDropdown() {
            this.ermData.ermTitle = '';
            this.elements.showErmDropdown = true;
            this.elements.showErmAdd = false;
        },
        cancelEffSheetAdd() {
            this.ermData.ermTitle = this.refErmTitle;
            this.ermData.entityRecordMasterId = this.refErmId;
            this.elements.showErmAdd = false;
            this.elements.showErmDropdown = false;
            this.getCostEstimationList();
        },
        closeDialog() {
            // this.selectedCostEstId = '';
            this.isDirectAdd = false;
            this.$emit('closeDialog');
        }
    }
}
</script>