export default {
    data() {
        return {
            projectId: localStorage.getItem("projectId"),
        }
    },
    methods: {
        getUrlNameByEntity(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case "effort_estimation_master":
                        output = 'effortestimation';
                        break;
                    case "goal_master":
                        output = 'goalobjective';
                        break;
                    case "issue_master":
                        output = 'issue';
                        break;
                    case "test_case_master":
                        output = 'testcase';
                        break;
                    case "project_team_master":
                        output = 'projectteam';
                        break;
                    case "error_report_master":
                        output = 'errorreport';
                        break;
                    case "user_requirement_master":
                        output = 'userrequirement';
                        break;
                    case "design_master":
                        output = 'designlist';
                        break;
                    case "development_plan_master":
                        output = 'task';
                        break;
                    case "feedback_form_master":
                        output = 'feedbackform';
                        break;
                    case "meeting_minute_master":
                        output = 'meetingpreparation';
                        break;
                    case "process_model_master":
                        output = 'processplan';
                        break;
                    case "document_folder_master":
                        output = 'projectdocument';
                        break;
                    case "plan_master":
                        output = 'plan';
                        break;
                    case "release_list_master":
                        output = 'releaselist';
                        break;
                    case "system_requirement_spec_master":
                        output = 'reqspc';
                        break;
                    case "resource_plan_master":
                        output = 'resourceplan';
                        break;
                    case "scope_statement_master":
                        output = 'scopestatement';
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
        getReportUrlName(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case "asset":
                        output = 'lanastdetrep';
                        break;
                    case "service_util":
                        output = 'lansrudetrep';
                        break;
                    case "service_history":
                        output = 'lansrudetrep';
                        break;
                    case "scope_statement_master":
                        output = 'sstrep';
                        break;
                    case "goal_master":
                        output = 'objrep';
                        break;
                    case "user_requirement_master":
                        output = 'urqrep';
                        output = this.projectId ? 'urqrep' : 'lanurqrep';
                        break;
                    case "system_requirement_spec_master":
                        output = this.projectId ? 'srsrep' : 'lansrsrep';
                        break;
                    case "task":
                        output = this.projectId ? 'tskbrd' : 'lantskbrd';
                        break;
                    case "mailbox":
                        output = this.projectId ? 'mailbox' : 'lanmailbox';
                        break;
                    case "calendar_event":
                        output = this.projectId ? 'meetingminute' : 'lanmeetingminute';
                        break;
                    case "meeting_minute_master":
                        output = this.projectId ? 'mmnrep' : 'lanmmnrep';
                        break;
                    case "uat_master":
                        output = '';
                        break;
                    case "effort_estimation_master":
                        output = '';
                        break;
                    case "process_model_master":
                        output = this.projectId ? '' : 'lanpmdrep';
                        break;
                    case "test_plan_master":
                        output = '';
                        break;
                    case "communication_plan_master":
                        output = '';
                        break;
                    case "resource_plan_master":
                        output = this.projectId ? 'rplrep' : 'lanrplrep';
                        break;
                    case "cost_estimation_master":
                        output = '';
                        break;
                    case "budget_plan_master":
                        output = this.projectId ? 'bplrep' : 'lanbplrep';
                        break;
                    case "document_folder_master":
                        output = this.projectId ? '' : 'lanpdcrep';
                        break;
                    case "project_plan_master":
                        output = this.projectId ? '' : 'lanplnrep';
                        break;
                    case "plan_master":
                        output = this.projectId ? 'plnrep' : 'lanplnrep';
                        break;
                    case "quality_assurance_plan_master":
                        output = '';
                        break;
                    case "development_plan_master":
                        output = this.projectId ? 'tslrep' : 'lantslrep';
                        break;
                    case "payroll_master":
                        output = this.projectId ? '' : 'lanprlrep';
                        break;
                    case "risk_plan_master":
                        output = '';
                        break;
                    case "project_team_master":
                        output = '';
                        break;
                    case "design_master":
                        output = '';
                        break;
                    case "financial_txn_master":
                        output = this.projectId ? '' : 'lanftxrep';
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
        getMasterListUrlName(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case "scope_statement_master":
                        output = 'sstmaslist';
                        break;
                    case "goal_master":
                        output = 'objmaslist';
                        break;
                    case "user_requirement_master":
                        output = this.projectId ? 'urqmaslist' : 'lanurqmaslist';
                        break;
                    case "system_requirement_spec_master":
                        output = this.projectId ? 'srsmaslist' : 'lansrsmaslist';
                        break;
                    // case "task":
                    //     output = this.projectId ? 'tskbrd' : 'lantskbrd';
                    //     break;
                    case "calendar_event":
                        output = this.projectId ? 'meetingminute' : 'lanmeetingminute';
                        break;
                    case "meeting_minute_master":
                        output = this.projectId ? 'mmnmaslist' : 'lanmmnmaslist';
                        break;
                    case "uat_master":
                        output = 'uatmaslist';
                        break;
                    case "effort_estimation_master":
                        output = 'esmasterlist';
                        break;
                    case "process_model_master":
                        output = this.projectId ? 'pmdmaslist' : 'lanpmdmaslist';
                        break;
                    case "test_plan_master":
                        output = 'tplmaslist';
                        break;
                    case "communication_plan_master":
                        output = 'cplmaslist';
                        break;
                    case "resource_plan_master":
                        output = this.projectId ? 'rplmaslist' : 'lanrplmaslist';
                        break;
                    case "cost_estimation_master":
                        output = 'coemaslist';
                        break;
                    case "budget_plan_master":
                        output = this.projectId ? 'bplmaslist' : 'lanbplmaslist';
                        break;
                    case "document_folder_master":
                        output = this.projectId ? 'pdcmaslist' : 'lanpdcmaslist';
                        break;
                    case "project_plan_master":
                        output = this.projectId ? 'plnmaslist' : 'lanplnmaslist';
                        break;
                    case "quality_assurance_plan_master":
                        output = 'qapmaslist';
                        break;
                    case "development_plan_master":
                        output = this.projectId ? 'dvpmaslist' : 'landvpmaslist';
                        break;
                    case "payroll_master":
                        output = this.projectId ? 'prlmaslist' : 'lanprlmaslist';
                        break;
                    case "risk_plan_master":
                        output = 'rspmaslist';
                        break;
                    case "project_team_master":
                        output = 'prtmaslist';
                        break;
                    case "design_master":
                        output = 'dsnmaslist';
                        break;
                    case "financial_txn_master":
                        output = this.projectId ? 'ftxmaslist' : 'lanftxmaslist';
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
    }
};