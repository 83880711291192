<template>
    <div>
        <CommunicationPlanMaster :ermMasterInfo="ermMasterInfo"></CommunicationPlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import CommunicationPlanMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        CommunicationPlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Communication Plan Master",
                entity: 'communication_plan_master',
                refEntity: 'communication_plan',
                redirectURL: '/cplmas/list',
                showTypeLookup:true
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>