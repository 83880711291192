<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Goal</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Goal" :to="{ name: 'addgoal'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Goal" :to="{ name: 'viewgoal'}"><i class="fa fa-eye pr-2"></i>View</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.goal.title.$error }">
                                            <input type="text" class="form-control" v-model="goal.title" id="title" :class="{ 'is-invalid': $v.goal.title.$error }" placeholder="Enter Title">
                                        <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.goal.title.$error && !$v.goal.title.minLength" class="text-danger">Title must have at least {{$v.goal.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.goal.title.$error && !$v.goal.title.required" class="text-danger">Please enter Title</label>
                                        <label v-if="$v.goal.title.$error && !$v.goal.title.maxLength" class="text-danger">Title must not exceed {{$v.goal.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="goalTypeId">Goal Type</label>
                                        <select v-model="goal.goalTypeId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in goalTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="timeline">Timeline</label>
                                        <input type="date" v-model="goal.timeline" class="form-control" id="timeline" placeholder="Timeline">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea v-model="goal.description" maxlength="2000" placeholder="Enter Description" class="form-control" rows="4" required></textarea>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" @click="saveGoal" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="goalId">Goal Id</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{goal.goalId}}</span></p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="statusId">Status</label>
                                        <select v-model="goal.statusId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in goalStatus" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="preparedBy">Prepared By</label>
                                        <select v-model="goal.preparedById" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="goal.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        TypeLookup,
        Select2
    },
    computed: {
        goalId() {
            return Number(this.$route.params.goalid);
        }
    },
    data() {
        return {
            goalTypes: [],
            goalStatus: [],
            users: [],
            typeLookupTitle:'Goal Type Settings',
            goal: {
                goalId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                goalTypeId: '',
                entity: 'goal',
                title: '',
                timeline: '',
                statusId: '',
                preparedById: '',
                achievementRating: '',
                description: ''
            }
        }
    },
    mounted() {
        this.lookupData();
        this.viewGoal(this.goalId);
    },
    validations: {
        goal: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                statusMasterId: 1,
                typeMasterId: 2,
                goalId: this.goalId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/goal/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.goalTypes = result.customTypeLookup;
                    this.goalStatus = result.statusLookup;
                    this.users = result.userLookup
                    console.log(result);

                })

        },
        viewGoal: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/goal/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.goal = result.goal;
                    this.goal.goalTypeId = (this.goal.goalTypeId == undefined) ? '' : this.goal.goalTypeId;
                    this.goal.statusId = (this.goal.statusId == undefined) ? '' : this.goal.statusId;
                    this.goal.preparedById = (this.goal.preparedById == undefined) ? '' : this.goal.preparedById;
                    console.log(result);
                })

        },
        saveGoal: function() {
            this.$v.goal.$touch();
            if (!this.$v.goal.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/goal/update', this.goal)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                        console.log(result);

                    })
            }

        },
        clearData: function() {
            this.goal.title = '';
            this.goal.goalTypeId = '';
            this.goal.description = '';
            this.goal.timeline = '';
            this.goal.preparedById = '';
            this.goal.statusId = '';
            this.$v.goal.$reset();
        }
    }
}
</script>