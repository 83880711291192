<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">{{ermMasterInfo.title}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div id="scope-app" class="mb-3">
                                    <b-card-body class="p-1">
                                        <div class="row mb-2">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="title">{{ermMasterInfo.titleLabelName}}</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                                        <input type="text" class="form-control" v-model="entityRecordMaster.title" id="title" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }" placeholder="Enter Title">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.minLength" class="text-danger">Title must have at least {{$v.entityRecordMaster.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger">Please enter Title</label>
                                                    <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.maxLength" class="text-danger">Title must not exceed {{$v.entityRecordMaster.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div v-if="userTypeCode !== 'CLI'" class="col-6">
                                                <div class="form-group">
                                                    <label for="client">Client</label>
                                                    <select v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showTypeLookup" :class="ermMasterInfo.showErmDate || getErmTypeCode == 'PRB' ? 'col-6' : 'col-4'">
                                                <div class="form-group">
                                                    <label for="ermTypeId">{{ermMasterInfo.typeLabelName}}</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.ermTypeId.$error }">
                                                        <select v-model="entityRecordMaster.ermTypeId" class="form-control" @change="handleErmTypeChange" id="ermTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.entityRecordMaster.ermTypeId.$error && !$v.entityRecordMaster.ermTypeId.required" class="text-danger">Please select a type</label>
                                                    <!-- <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.ermTypeId.$error }">
                                                        <select v-model="entityRecordMaster.ermTypeId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.entityRecordMaster.ermTypeId.$error && !$v.entityRecordMaster.ermTypeId.required" class="text-danger">Please Select a Type</label> -->
                                                </div>
                                            </div>
                                            <template v-if="ermMasterInfo.showDateRange">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="startDate">Start Date</label>
                                                        <input type="date" class="form-control" v-model="entityRecordMaster.startDate" id="startDate">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="endDate">End Date</label>
                                                        <input type="date" class="form-control" v-model="entityRecordMaster.endDate" id="endDate">
                                                    </div>
                                                </div>
                                            </template>
                                            <div v-if="ermMasterInfo.showErmDate" class="col-6">
                                                <div class="form-group">
                                                    <label for="startDate">Month</label>
                                                    <input type="month" class="form-control" v-model="entityRecordMaster.ermDate" id="startDate">
                                                </div>
                                            </div>
                                            <div v-if="getErmTypeCode == 'PRB'" class="col-6">
                                                <div class="form-group">
                                                    <label for="projectId">Project</label>
                                                    <input type="text" class="form-control" disabled v-model="projectName" id="startDate">
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showMultipleMasterControl" class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="title1">Link Financial Transactions</label>
                                                    <multiselect v-model="refErmList" :options="ermLookup" name="masterLink" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="description">{{ermMasterInfo.descriptionLabelName}}</label>
                                                    <textarea v-model="entityRecordMaster.description" class="form-control" id="description" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Submit</button>
                                        <button type="submit" @click="getPrevUrl()" class="sbtn btn btn-primary mb-0 mr-4 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        <a href="javascript:" title="Reset" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                        <a class="float-right">
                                            <input type="checkbox" class="align-middle mr-1" v-model="entityRecordMaster.isPublic"><span class="align-middle d-inline-block">All</span>
                                        </a>

                                    </b-card-body>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="statusId">Prepared By</label>
                                            <select v-model="entityRecordMaster.createdById" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="statusId">Status</label>
                                            <select v-model="entityRecordMaster.statusId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in entityRecordStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div>
                                                <label for="exampleInputPassword1">Attachment </label>
                                            </div>
                                            <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                <div class="attachments mb-2">
                                                    <b-form-file class="attachments-list" :class="{ 'is-invalid': error }" size="sm" v-model="entityRecordMaster.attachments[index]" placeholder="Choose a file.">
                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                        </template>
                                                    </b-form-file>
                                                    <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                        <i class="fa fa-check pr-2"></i>
                                                    </a>
                                                </div>
                                                <label v-if="error" class="text-danger">Please choose a file.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs, requiredIf } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
        ermMasterInfo: Object
    },
    components: {
        Multiselect
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            showOverlay: false,
            error: false,
            clients: [],
            refErmList: [],
            ermLookup: [],
            entityRecordStatus: [],
            entityRecordCustomType: [],
            allUsers: [],
            entityRecordMaster: {
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                createdById: '',
                statusId: '',
                ermDate: '',
                startDate: '',
                endDate: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.ermMasterInfo.entity,
                refEntityId: '',
                refEntity: this.ermMasterInfo.refEntity,
                isFreeze: false,
                isArchive: false,
                isPublic: false,
                attachments: []
            },
            addMore: [],
        }
    },
    computed: {
        users() {
            if (this.userTypeCode == 'CLI') {
                return this.allUsers.filter((user) => user.id == this.userId)
            } else return this.allUsers;
        },
        getErmTypeCode() {
            if (this.entityRecordCustomType.length>0) {
                let typeData = this.entityRecordCustomType.find((data) => data.id == this.entityRecordMaster.ermTypeId);
                return typeData ? typeData.typeCode : null;
            } else return null;
        }
    },
    mounted() {
        this.lookupData();
        this.setDefaultValue();
        this.masterLinkLookupData();
        this.addMore.push(this.addMore.length + 1);
    },
    // validations: {
    //     entityRecordMaster: {
    //         title: {
    //             required,
    //             minLength: minLength(2),
    //             maxLength: maxLength(100)
    //         },
    //         ermTypeId: {
    //             required: requiredIf((abc) => this.ermMasterInfo.showTypeLookup)
    //         }
    //     },
    // },
    validations() {
        if (this.ermMasterInfo.showTypeLookup) {
            return {
                entityRecordMaster: {
                    title: {
                        required,
                        minLength: minLength(2),
                        maxLength: maxLength(100)
                    },
                    ermTypeId: {
                        required,
                    }
                }
            }
        } else {
            return {
                entityRecordMaster: {
                    title: {
                        required,
                        minLength: minLength(2),
                        maxLength: maxLength(100)
                    },
                }
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.entityRecordMaster.vhextAccountId,
                projectId: this.entityRecordMaster.projectId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordStatus = result.statusLookup;
                    this.allUsers = result.userLookup;
                    this.clients = result.clientLookup;
                    this.entityRecordCustomType = result.typeLookup;
                    if (this.ermMasterInfo.typeCode != null && this.ermMasterInfo.typeCode == 'cli') {
                        this.entityRecordCustomType = this.filterCustomType(this.entityRecordCustomType, 'Financial Transaction');
                    }
                    this.entityRecordMaster.entityId = result.entityId;
                    this.entityRecordMaster.refEntityId = result.refEntityId;
                    this.entityRecordMaster.createdById = localStorage.getItem("userId");
                    this.entityRecordMaster.statusId = this.entityRecordStatus[0].id;
                })

        },
        masterLinkLookupData: function() {
            if (this.ermMasterInfo.masterLinkEntity) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    entity: this.ermMasterInfo.masterLinkEntity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                    .then((response) => {
                        let result = response.data;
                        this.ermLookup = result.entityERMLookup;
                        console.log(result);
                    })
            }
        },
        saveData: function() {
            if (this.userTypeCode == 'CLI') {
                this.entityRecordMaster.clientId = this.userId;
            }
            this.$v.entityRecordMaster.$touch();
            if (!this.$v.entityRecordMaster.$invalid) {
                this.saveERMMaster();
            }
        },
        saveERMMaster: function() {
            console.log(this.entityRecordMaster)
            if (this.ermMasterInfo.showDateRange) {
                this.entityRecordMaster.startDate = this.entityRecordMaster.startDate ? this.entityRecordMaster.startDate : '';
                this.entityRecordMaster.endDate = this.entityRecordMaster.endDate ? this.entityRecordMaster.endDate : '';
            }
            if (this.ermMasterInfo.showErmDate) {
                this.entityRecordMaster.ermDate = this.entityRecordMaster.ermDate + "-11";
            }
            if (this.ermMasterInfo.showMultipleMasterControl) {
                this.entityRecordMaster.refErmList = [];
                this.refErmList.forEach((item, index) => {
                    this.entityRecordMaster.refErmList.push({ refErmId: this.refErmList[index].id })
                })
            }
            else {
                this.entityRecordMaster.refErmList = [];
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/add', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        if (this.entityRecordMaster.attachments != null && this.entityRecordMaster.attachments.length > 0)
                            this.submitFiles(result.id, result.entityId);
                        else {
                            this.showMessage('Record Master Created', result.id);
                        }
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearData: function() {
            this.entityRecordMaster.title = '';
            this.entityRecordMaster.clientId = '';
            this.entityRecordMaster.ermTypeId = '';
            this.entityRecordMaster.description = '';
            this.entityRecordMaster.createdById = localStorage.getItem("userId");
            this.entityRecordMaster.statusId = '';
            this.entityRecordMaster.startDate = '';
            this.entityRecordMaster.endDate = '';
            this.entityRecordMaster.createdDt = '';
            this.entityRecordMaster.lastUpdatedDt = '';
            this.entityRecordMaster.id = '';
            this.error = false;
            this.$v.entityRecordMaster.$reset();
        },
        setDefaultValue: function() {
            if (!Object.keys(this.ermMasterInfo).includes("typeLabelName"))
                this.ermMasterInfo.typeLabelName = "Type";
            if (!Object.keys(this.ermMasterInfo).includes("titleLabelName"))
                this.ermMasterInfo.titleLabelName = "Title";
            if (!Object.keys(this.ermMasterInfo).includes("descriptionLabelName"))
                this.ermMasterInfo.descriptionLabelName = "Description";
            if (!Object.keys(this.ermMasterInfo).includes("showTypeLookup"))
                this.ermMasterInfo.showTypeLookup = false;
            if (!Object.keys(this.ermMasterInfo).includes("showDateRange"))
                this.ermMasterInfo.showDateRange = false;
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            this.entityRecordMaster.attachments.splice(index, 1);
        },
        submitFiles: function(entityRecordMasterId, entityId) {
            let formData = new FormData();

            for (const row of Object.keys(this.entityRecordMaster.attachments)) {
                formData.append('files', this.entityRecordMaster.attachments[row]);
            }
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', this.entityRecordMaster.projectId);
            formData.append('refEntity', this.ermMasterInfo.entity);
            formData.append('refRecordId', entityRecordMasterId);


            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Entity Record Master Created with attachment.', entityRecordMasterId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    console.log(error);
                    this.showMessage('Entity Record Master Created without attachment.', entityRecordMasterId);
                    console.log('Error Attachment uploaded');
                });
        },
        async showMessage(message, id) {
            this.showOverlay = false;
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });

            if (this.ermMasterInfo.redirectURL) {
                this.$router.push(this.ermMasterInfo.redirectURL);
            }
            else this.$router.push(this.ermMasterInfo.ermListRedirectURL);


            /*   // To Redirect to ERM Detail Page

            if (this.ermMasterInfo.showTypeLookup) {
                let typeName = await this.getTypeNameById(this.entityRecordMaster.ermTypeId, this.entityRecordCustomType);
                this.redirectPage(id, typeName);
                return;
            }
            this.routePath = this.$route.path;
            if (this.routePath != null && this.routePath.indexOf('service') == 1) {
                this.$router.push(this.ermMasterInfo.redirectURL);
            } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
                this.$router.push(this.ermMasterInfo.redirectURL);
            } else if (this.ermMasterInfo.erdRedirectURL) {
                this.$router.push(this.ermMasterInfo.erdRedirectURL + id);
            } else this.$router.push(this.ermMasterInfo.redirectURL);

            */

        },
        saveAttachment: function() {
            this.$v.entityRecordMaster.$touch();
            if (this.entityRecordMaster.attachments != null && this.entityRecordMaster.attachments.length == 0) {
                this.error = true;
            } else if (!this.$v.entityRecordMaster.$invalid) {
                this.error = false;
                this.saveERMMaster();
            }
        },
        getTypeNameById: function(id, list) {
            let result_name = "";
            list.map(function(value, key) {
                if (value.id == id)
                    result_name = value.value;
            });
            return result_name;
        },
        redirectPage: function(ermId, type) {
            this.routhPath = this.$route.path;

            if (this.routhPath != null && this.routhPath.indexOf('service') == -1) {
                switch (type) {
                    case "Meeting Plan": 
                        this.$router.push('/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/reportingplan/' + ermId);
                        break;
                    // case "Project Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/budgetplan/' + ermId);
                    //     break;
                    // case "Monthly Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/monthlybudgetplan/' + ermId);
                    //     break;
                    case "Project Funding":
                        this.$router.push('/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            } else {
                switch (type) {
                    case "Meeting Plan":
                        this.$router.push('/service/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/service/reportingplan/' + ermId);
                        break;
                    // case "Project Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/service/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/service/budgetplan/' + ermId);
                    //     break;
                    // case "Monthly Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/service/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/service/monthlybudgetplan/' + ermId);
                    //     break;
                    case "Project Funding":

                        this.$router.push('/service/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/service/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            }

        },
        handleErmTypeChange: function() {
            let typeData = this.entityRecordCustomType.find((data) => {
                if (data.id == this.entityRecordMaster.ermTypeId) {
                    return data;
                }
            });
            console.log(typeData)
            if (this.ermMasterInfo.entity == 'budget_plan_master') {
                if(typeData && typeData.typeCode == 'MOB') {
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showErmDate = true;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = this.getMonthYear(new Date());
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
                else if(typeData && typeData.typeCode == 'PEB') {
                    this.ermMasterInfo.showDateRange = true;
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = this.getMonthYear(new Date());
                    this.entityRecordMaster.endDate = this.getMonthYear(new Date());
                }
                else if(typeData && typeData.typeCode == 'BTI') {
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showMultipleMasterControl = true;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
                else {
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
            }
        },
    }
}
</script>