<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Financial Transaction</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <!-- <p class="contact-title"><span>Personal Information</span></p> -->
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                                <!-- <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Approved By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">CVK</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals && ermReviewApprovals.length > 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Scope</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">{{erm.description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Project Finance Summary</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                    <b-table show-empty small stacked="md" selectable  sort-icon-left :items="financialTxnSummary" :fields="financialTxnSummaryFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :current-page="erm.currentPage" :per-page="erm.perPage" :filter="erm.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>                                        

                                        <template v-slot:cell(amount)="data">
                                            <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                        </template>
                                        
                                        <template v-slot:cell(actions)="data">
                                            <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                            <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                            <a href="javascript:" title="Delete" @click="deleteScopeStatement(data.item.id)" data-toggle="modal" data-target="#deleteScopeStatement"><i class="fa fa-trash pl-2"></i></a>
                                        </template>
                                        <template v-slot:empty="erm">
                                            <div class="text-center">No Project Funding Summary Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="erm">
                                            <div class="text-center">{{ erm.emptyFilteredText }}</div>
                                        </template>

                                        <template v-slot:foot(index)="data">
                                            <span class="text-right"></span>
                                        </template>
                                        <template v-slot:foot(transactionby)="data">
                                            <span class="text-right">Total Amount:</span>
                                        </template>


                                        <template v-slot:foot(amount)="data">
                                            <div class="text-center"><span class="text-right">{{totalExpenses.toFixed(2)}}</span></div>
                                        </template>                                                                                
                                    </b-table>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card-body p-3">
                            <p class="contact-title"><span>Financial Txn Details</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable sort-icon-left :items="financialTxns" :fields="financialTxnFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :current-page="erm.currentPage" :per-page="erm.perPage" :filter="erm.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                        

                                            <template v-slot:cell(amount)="data">
                                                <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                            </template>

                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                                <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                <a href="javascript:" title="Delete" @click="deleteScopeStatement(data.item.id)" data-toggle="modal" data-target="#deleteScopeStatement"><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="erm">
                                                <div class="text-center">No Financial Txns Found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="erm">
                                                <div class="text-center">{{ erm.emptyFilteredText }}</div>
                                            </template>

                                            <template v-slot:foot(index)="data">
                                                <span ></span>
                                            </template>
                                            <template v-slot:foot(financialTransactionId)="data">
                                                <span ></span>
                                            </template>
                                            <template v-slot:foot(title)="data">
                                                <span ></span>
                                            </template>
                                            <template v-slot:foot(transactiondt)="data">
                                                <span ></span>
                                            </template>
                                            <template v-slot:foot(type)="data">
                                                <span ></span>
                                            </template>
                                            <template v-slot:foot(transactionby)="data">
                                                <span class="text-right"></span>
                                            </template>
                                            <template v-slot:foot(category)="data">
                                                <span class="text-right"></span>
                                            </template>

                                            <template v-slot:foot(accountablePerson)="data">
                                                <span class="text-right">Total Amount:</span>
                                            </template>

                                            <template v-slot:foot(amount)="data">
                                                <div class="text-right"><span class="text-right">{{totalExpenses2.toFixed(2)}}</span></div>
                                            </template>                                        
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
export default {
    name: 'app',
    components: {
        VhextMoneyControl,
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
    },    
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],
            
            prependText:'₹',
            financialTxn: {
                id: '',
                scopeTypeId: '',
                title: '',
                inScope: '',

                entity: 'financial_transaction',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
            },

            isBusy: true,
            scopeReferences: [],

            financialTxnSummary: [],
            financialTxnSummaryFields: [
                { key: 'index',label: 'SN'},
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },
                { key: 'accountHeadDesc', label: 'Account Head Detail', sortable: true, sortDirection: 'desc' },
                { key: 'amount',tdClass: 'setAmountDirectionTdClass', sortable: true, label: 'Amount',  sortDirection: 'desc', thStyle: "width: 15px" },
            ],


            financialTxns: [],
            financialTxnFields: [
                { key:'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'financialTransactionId', label: 'Txn No',  sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', tdClass: 'table-cw-200',  sortDirection: 'desc' },
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },                
                { key: 'transactionBy', label: 'Transaction By', tdClass: 'table-name-col-size',sortable: true, sortDirection: 'desc' },
                { key: 'transactionDt', label: 'Txn Date',formatter: "formatDate",sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Txn Type', sortDirection: 'desc' },
                { key: 'category', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable Person', tdClass: 'table-name-col-size',  sortDirection: 'desc' },
                { key: 'amount', label: 'Amount',  sortDirection: 'desc' },
                { key: 'amountType', label: 'CR / DR', tdClass: 'setAmountTdClass', sortable: true, sortDirection: 'desc' },
            ],

            ermReviewApprovalFields: [

                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', sortable: true, sortDirection: 'desc' }
            ],

            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        totalExpenses() {
            return this.financialTxnSummary.reduce((accum, item) => {
            return accum + parseFloat(item.amount)
        }, 0.00)
        },
        totalExpenses2() {
            return this.financialTxns.reduce((accum, item) => {
            return accum + parseFloat(item.amount)
        }, 0.00)
        },

    },
    mounted() {
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");
        this.ermId = this.$route.params.ermId;
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();

        console.log(this.ermId);

        // if(this.ermId != null){
        //     this.entityRecordMasterCollection(this.ermId);
        //     this.getERMFinancialTxns(this.ermId);
        // }
        // else{
        //     this.getProjectFinancialTxns();
        // }
        
    },
    methods: {
        initialSettings: function() {
            // <!--     content ==> 5  Start-->
            this.entityMaster = 'financial_txn_master';
            this.entity = 'financial_transaction';
            // <!--     content ==> 5  End-->

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {

                if(this.strERMId != null){
                    this.entityRecordMasterCollection(this.strERMId);
                    this.getERMFinancialTxns(this.strERMId);
                }
                else{
                    this.getProjectFinancialTxns();
                }

                // this.getProjectFinancialTxns(strERMId);

                // this.entityRecordMasterCollection(strERMId);

                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getProjectFinancialTxns(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },

        getProjectFinancialTxns: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransactionreport/list', data)
                .then((response) => {
                    let result = response.data;
                    this.financialTxns = result.financialTxnList;
                    this.financialTxnSummary = result.financialTxnSummaryList;
                    console.log(this.financialTxns);
                    console.log(this.financialTxnSummary);
                    this.totalRows = this.financialTxns.length;
                })
        },


        getERMFinancialTxns: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransactionreport/list', data)
                .then((response) => {
                    let result = response.data;
                    this.financialTxns = result.financialTxnList;
                    this.financialTxnSummary = result.financialTxnSummaryList;
                    console.log(this.financialTxns);
                    console.log(this.financialTxnSummary);
                    this.totalRows = this.financialTxns.length;
                })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        deleteEntityRecordMaster: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/project/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEntityRecordMaster();
                        this.reviewMaster();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        moneyControlInput: function(value) {
            this.financialTxn.amount = value;
        },        
        moneyControlInput2: function(value) {
            this.financialTxn.amount = value;
        },        
        setAmountTdClass(value) {
            var amountType = value;
            if(amountType === 'CR')
                return 'green';
            else if(amountType === 'DR')
                return 'red';
        },
        setAmountDirectionTdClass(value) {
            var amountType = value;
            if(amountType > 0 )
                return 'green';
            else if(amountType < 0)
                return 'red';
        },


        print: function() {    
            this.$htmlToPaper('printMe');
        },    
    }
}
</script>