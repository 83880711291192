<template>
    <div>
        <b-overlay :show="showHRDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
            <b-tabs v-if="elements.showDetail" content-class="mt-3" active-nav-item-class="font-weight-bold primary" lazy>
                <b-tab title="Prime Info">
                    <div v-if="humanResourceDetails.isEmployee" id="profile" class="pt-0">
                        <div class="profile_header text-left">
                            <div class="row">
                                <div class="col-10">
                                    <div class="my-profile-img">
                                        <img v-if="contactDetail.photo" :src="contactDetail.photo" alt="Profile picture">
                                        <b-avatar v-else size="75px"></b-avatar>
                                        <label class="upload-icon upload-icon-contacts">
                                            <i class="fa fa-camera"></i>
                                            <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="uploadPhoto">
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-2 pl-3 mr-0 ml-auto">
                                    <div class="more-btns text-center">
                                        <button v-if="!elements.primaryInfoEditMode" href="javascript:" @click="elements.primaryInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </button>
                                        <div v-if="elements.primaryInfoEditMode">
                                            <button type="submit" @click="updateHumanResource" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                            <button type="submit" @click="clearData();elements.primaryInfoEditMode=false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col-2 pr-0">
                                    <div class="form-group">
                                        <label for="name">Emp. Id</label>
                                        <b-form-input :disabled="!elements.primaryInfoEditMode" v-model="humanResourceDetails.employeeId"></b-form-input>
                                    </div>
                                </div> -->
                                <div class="col-2 pr-0">
                                    <div class="form-group">
                                        <label for="name">EID</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetails.employeeCode.$error }">
                                            <b-form-input :disabled="!elements.primaryInfoEditMode" v-model="humanResourceDetails.employeeCode" :class="{ 'is-invalid': $v.humanResourceDetails.employeeCode.$error }"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>  
                                        <label v-if="$v.humanResourceDetails.employeeCode.$error && !$v.humanResourceDetails.employeeCode.required" class="text-danger">Please enter Employee Id</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetails.name.$error }">
                                            <b-form-input :disabled="!elements.primaryInfoEditMode" v-model="humanResourceDetails.name" :class="{ 'is-invalid': $v.humanResourceDetails.name.$error }"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>  
                                        <label v-if="$v.humanResourceDetails.name.$error && !$v.humanResourceDetails.name.minLength" class="text-danger">Name must have at least {{$v.humanResourceDetails.name.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.humanResourceDetails.name.$error && !$v.humanResourceDetails.name.required" class="text-danger">Please enter Name</label>
                                        <label v-if="$v.humanResourceDetails.name.$error && !$v.humanResourceDetails.name.maxLength" class="text-danger">Name must not exceed {{$v.humanResourceDetails.name.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="projectManager">Employee Status</label>
                                        <select v-if="elements.primaryInfoEditMode" v-model="humanResourceDetails.employeeStatusId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <input v-if="!elements.primaryInfoEditMode" disabled v-model="humanResourceDetails.employeeStatus" type="text" class="form-control">
                                    </div>
                                </div>  
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="qualification">Qualification</label>
                                        <input type="text" class="form-control" id="qualification" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.qualification'>
                                    </div>
                                </div>   
                                <div class="col-3 pr-1">
                                    <div class="form-group">
                                        <label for="experienceYears">Exp Years</label>
                                        <input type="number" min="0" max="80" placeholder="Years" class="form-control" id="experienceYears" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.experienceYears'>
                                    </div>
                                </div>
                                <div class="col-3 pl-1">
                                    <div class="form-group">
                                        <label for="experienceMonths">Exp Months</label>
                                        <input type="number" min="0" max="12" placeholder="Months" class="form-control" id="experienceMonths" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.experienceMonths'>
                                    </div>
                                </div>
                                <!-- <div class="col-6">
                                    <div class="form-group">
                                        <label for="designationIdEmp">Designation</label>
                                        <select v-model="humanResourceDetails.designationId" class="form-control" id="designationIdEmp" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in designations" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="departmentIdEmp">Department</label>
                                        <select v-model="humanResourceDetails.departmentId" class="form-control" id="departmentIdEmp" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!-- <div class="col-6">
                                    <div class="form-group">
                                        <label for="jobCategoryIdEmp">Job Category</label>
                                        <select v-model="humanResourceDetails.jobCategoryId" class="form-control" id="jobCategoryIdEmp" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="projectManager">Work Type</label>
                                        <select v-if="elements.primaryInfoEditMode" v-model="humanResourceDetails.workTypeId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in workTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <input v-if="!elements.primaryInfoEditMode" disabled v-model="humanResourceDetails.workType" type="text" class="form-control">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="workShiftIdEmp">Work Shift</label>
                                        <select v-model="humanResourceDetails.workShiftId" class="form-control" id="workShiftIdEmp" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="form-group">
                                        <label for="joiningDate">Joining Date</label>
                                        <input v-if="elements.primaryInfoEditMode" type="date" v-model="humanResourceDetails.joiningDate" class="form-control" id="joiningDate">
                                        <input v-else type="text" disabled class="form-control" :value="humanResourceDetails.joiningDate | formatDate">
                                    </div>
                                </div> 

                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="recruitedBy">Recruited By</label>
                                        <select v-if="elements.primaryInfoEditMode" v-model="humanResourceDetails.recruitedById" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <input v-if="!elements.primaryInfoEditMode" disabled v-model="humanResourceDetails.recruitedBy" type="text" class="form-control">
                                    </div>
                                </div>
                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                    <label class="d-block p-1 pl-3 my-0 accordion-title" block href="javascript:">
                                        Personal Info
                                    </label>
                                    <!-- <div class="separator mx-2"></div> -->
                                </b-card-header>                                
                                <div class="col-12 col-lg-12 col-xl-12 pl-3">
                                    <!-- <h6 class="mb-2">Personal Info</h6>  -->
                                    <div class="card mb-3">
                                        <div class="card-body p-2">
                                            <div class="row px-2" >
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="dateOfBirth">Date of Birth</label>
<!--                                 <input v-if="elements.empInfoEditMode" type="date" v-model="humanResourceDetails.wefDate" class="form-control" id="wefDate">                                                         -->
                                                        <input v-if="elements.primaryInfoEditMode" type="date" id="dateOfBirth" class="form-control" v-model="humanResourceDetails.dateOfBirth">
                                                        <input v-else type="text" disabled class="form-control" :value="humanResourceDetails.dateOfBirth | formatDate">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                                                            <b-form-radio-group id="radio-group-2" v-model="humanResourceDetails.gender" :aria-describedby="ariaDescribedby" name="radio-sub-component">
                                                                <b-form-radio :disabled="!elements.primaryInfoEditMode" value="M">Male</b-form-radio>
                                                                <b-form-radio :disabled="!elements.primaryInfoEditMode" value="F">Female</b-form-radio>
                                                            </b-form-radio-group>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                      
                            </div>
                        </div>
                    </div>
                    <div v-else id="profile">
                        <div class="profile_header text-left">
                            <div class="row">
                                <div class="col-10">
                                    <div class="my-profile-img">
                                        <img :src="contactDetail.photo" alt="Profile picture">
                                        <label class="upload-icon upload-icon-contacts">
                                            <i class="fa fa-camera"></i>
                                            <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="uploadPhoto">
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-2 col-sm-2 pl-3 mr-0 float-right">
                                    <div class="more-btns text-center">
                                        <button v-if="!elements.primaryInfoEditMode" href="javascript:" @click="elements.primaryInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                        </button>
                                        <div v-if="elements.primaryInfoEditMode">
                                            <button type="submit" @click="updateHumanResource" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                            <button type="submit" @click="clearData();elements.primaryInfoEditMode=false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="name">Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetails.name.$error }">
                                            <b-form-input :disabled="!elements.primaryInfoEditMode" v-model="humanResourceDetails.name" :class="{ 'is-invalid': $v.humanResourceDetails.name.$error }"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>  
                                        <label v-if="$v.humanResourceDetails.name.$error && !$v.humanResourceDetails.name.minLength" class="text-danger">Name must have at least {{$v.humanResourceDetails.name.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.humanResourceDetails.name.$error && !$v.humanResourceDetails.name.required" class="text-danger">Please enter Name</label>
                                        <label v-if="$v.humanResourceDetails.name.$error && !$v.humanResourceDetails.name.maxLength" class="text-danger">Name must not exceed {{$v.humanResourceDetails.name.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="position">Position</label>
                                        <input type="text" class="form-control" id="position" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.position'>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="qualification">Qualification</label>
                                        <input type="text" class="form-control" id="qualification" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.qualification'>
                                    </div>
                                </div>
                                <div class="col-3 pr-1">
                                    <div class="form-group">
                                        <label for="experienceYears">Exp Years</label>
                                        <input type="text" class="form-control" id="experienceYears" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.experienceYears'>
                                    </div>
                                </div>
                                <div class="col-3 pl-1">
                                    <div class="form-group">
                                        <label for="experienceMonths">Exp Months</label>
                                        <input type="text" class="form-control" id="experienceMonths" :disabled="!elements.primaryInfoEditMode" v-model='humanResourceDetails.experienceMonths'>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="humanResourceTypeId">Human Resource Type</label>
                                        <select v-model="humanResourceDetails.humanResourceTypeId" class="form-control" id="humanResourceTypeId" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in humanResourceTypes" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="subContractId">Sub Contract</label>
                                        <select v-model="humanResourceDetails.subContractId" class="form-control" id="subContractId" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in subContracts" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="workShiftIdEmp">Work Shift</label>
                                        <select v-model="humanResourceDetails.workShiftId" class="form-control" id="workShiftIdEmp" :disabled="!elements.primaryInfoEditMode" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-12 col-xl-12">
                                    <h6 class="d-block p-1 mb-1 accordion-title">Personal Info</h6> 
                                    <div class="card mb-3">
                                        <div class="card-body p-2">
                                            <div class="row px-2" >
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="dateOfBirth">Date of Birth</label>
                                                        <input v-if="elements.primaryInfoEditMode" type="date" class="form-control" id="dateOfBirth" v-model="humanResourceDetails.dateOfBirth">
                                                        <input v-else type="text" disabled class="form-control" :value="humanResourceDetails.dateOfBirth | formatDate">
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                                                            <b-form-radio-group id="radio-group-2" v-model="humanResourceDetails.gender" :aria-describedby="ariaDescribedby" name="radio-sub-component">
                                                                <b-form-radio :disabled="!elements.primaryInfoEditMode" value="M">Male</b-form-radio>
                                                                <b-form-radio :disabled="!elements.primaryInfoEditMode" value="F">Female</b-form-radio>
                                                            </b-form-radio-group>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab v-if="humanResourceDetails.isEmployee" title="Emp. Info">
                    <div class="row">
                        <div class="col-10 text-right">
                            <!-- <button type="submit" title="Job Position Change" @click="openEmploymentHistoryPopup" class="btn btn-outline-primary sbtn btn-xs py-1 px-2">Job Position Change</button> -->
                        </div>
                        <div class="col-md-2 col-sm-2 pl-3 mr-0 ml-auto">
                            <div class="more-btns text-center">
                                <button v-if="!elements.empInfoEditMode" href="javascript:" @click="elements.empInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </button>
                                <div v-if="elements.empInfoEditMode">
                                    <button type="submit" @click="updateHumanResource" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                    <button type="submit" @click="clearData();elements.empInfoEditMode=false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="designationIdEmp">Designation</label>
                                <b-input-group>
                                    <select v-model="humanResourceDetails.designationId" class="form-control" id="designationIdEmp" :disabled="!elements.empInfoEditMode" >
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in designations" :value="info.id" :key="index">{{info.value}}</option>
                                    </select>
                                    <b-input-group-append class="append-0">
                                    <a :title="designationLookupTitle" class="action-link" href="javascript:" v-b-modal.designationModal :disabled="!elements.empInfoEditMode"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="departmentIdEmp">Department</label>
                                <b-input-group>
                                    <select v-model="humanResourceDetails.departmentId" class="form-control" id="departmentIdEmp" :disabled="!elements.empInfoEditMode" >
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                    </select>
                                    <b-input-group-append class="append-0">
                                    <a :title="departmentLookupTitle" class="action-link" href="javascript:" v-b-modal.departmentModal :disabled="!elements.empInfoEditMode"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="jobCategoryIdEmp">Job Category</label>
                                <select v-if="elements.empInfoEditMode" v-model="humanResourceDetails.jobCategoryId" class="form-control" id="jobCategoryIdEmp" >
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                </select>
                                <input v-else type="text" class="form-control" id="qualification" disabled v-model='humanResourceDetails.jobCategory'>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Annual CTC</label>
                                <VhextMoneyControl :value="Math.abs(Number(humanResourceDetails.salary))" :disabled="!elements.empInfoEditMode" @money-input="moneyControlInputHRS" />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Monthly CTC</label>
                                <VhextMoneyControl :value="Math.abs(Number(monthlySalary))" disabled />
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label>Pay (per hour)</label>
                                <VhextMoneyControl :value="Math.abs(Number(humanResourceDetails.hourlySalary))" :disabled="!elements.empInfoEditMode" @money-input="moneyControlInputHSL" />
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                                <label for="wefDate">Effective From</label>
                                <input v-if="elements.empInfoEditMode" type="date" v-model="humanResourceDetails.wefDate" class="form-control" id="wefDate">
                                <input v-else type="text" disabled class="form-control" :value="humanResourceDetails.wefDate | formatDate">
                            </div>
                        </div>
                        <div class="col-12 col-sm-3">
                            <div class="form-group">
                                <label for="endDate">End Date</label>
                                <input v-if="elements.empInfoEditMode" type="date" v-model="humanResourceDetails.endDate" class="form-control" id="endDate">
                                <input v-else type="text" disabled class="form-control" :value="humanResourceDetails.endDate | formatDate">
                            </div>
                        </div>                        
                        <div class="col-6">
                            <div class="form-group">
                                <label for="reportingToId">Reporting To</label>
                                <select v-model="humanResourceDetails.reportingToId" :disabled="!elements.empInfoEditMode" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="employmentChangeById">Emp. Position Responsibility</label>
                                <select v-if="elements.empInfoEditMode" v-model="humanResourceDetails.employmentChangeById" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                                <input v-if="!elements.empInfoEditMode" disabled v-model="humanResourceDetails.employmentChangeBy" type="text" class="form-control">
                            </div>
                        </div>                        
                    </div>
                    <!-- <div class="row">
                        <div class="col-12 text-right">
                            <button type="submit" title="Job Position Change" :disabled="elements.empInfoEditMode" @click="openEmploymentHistoryPopup" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 mb-3">Employee Position Change</button>
                        </div>
                    </div> -->
                    <!-- <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-empChange>
                                <span>
                                    <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Employee Position Change
                                </span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-empChange" accordion="my-accordion-empChange" v-model="elements.empHistoryVisible" role="tabpanel">
                            <b-card-body class="p-3 mb-2">
                                <div class="row mb-2">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="designationIdEmp">Designation</label>
                                            <select v-model="employmentHistory.designationId" class="form-control" id="designationIdEmp" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in designations" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="departmentIdEmp">Department</label>
                                            <select v-model="employmentHistory.departmentId" class="form-control" id="departmentIdEmp" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="jobCategoryIdEmp">Job Category</label>
                                            <select v-model="employmentHistory.jobCategoryId" class="form-control" id="jobCategoryIdEmp" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Salary</label>
                                            <VhextMoneyControl :value="Math.abs(Number(employmentHistory.salary))" @money-input="moneyControlInput" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="employmentChangeById">Emp. Position Responsibility</label>
                                            <select v-model="employmentHistory.employmentChangeById" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="wefDate">Date</label>
                                            <input type="date" v-model="employmentHistory.wefDate" class="form-control" id="wefDate">
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" @click="addEmploymentHistory" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="clearEmpHistory" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                            </b-card-body>
                        </b-collapse>
                    </b-card> -->
                    <div class="row mb-2">
                        <div class="col-12 col-sm-12">
                            <h6 class="fs-14 contact-title mb-2"><span>Employee Position Change History</span></h6>
                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="empHistoryList" :fields="empHistoryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(salary)="data">
                                    <div class="text-right">{{ data.value }}</div>
                                </template> 
                                <!-- <template v-slot:cell(hourlySalary)="data">
                                    <div class="text-right">{{ data.value }}</div>
                                </template>                                  -->
                                                               
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deleteEmploymentHistory(data.item.id)" v-b-modal.deleteEmploymentHistory><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No History found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="empHistoryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </b-tab>
                <b-tab v-if="displayControl.roleResp" title="Roles / Resp.">
                    <b-card no-body class="">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-roleResp>
                                <span v-if="elements.roleEditMode">Edit Role & Responsibility</span>
                                <span v-else>
                                    <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Role & Responsibility
                                </span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-roleResp" accordion="my-accordion" v-model="elements.roleVisible" role="tabpanel">
                            <b-card-body class="p-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-12 mb-3">
                                                    <label for="role">Role</label>
                                                    <a href="javascript:" class="float-right button-add" @click="elements.showDropdownRole=!elements.showDropdownRole">
                                                        <i v-if="!elements.showDropdownRole" title="Existing Roles" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                        <i v-if="elements.showDropdownRole" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                    </a>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibilityDetail.role.$error }">
                                                        <select v-if="elements.showDropdownRole" @change="getResponsibilities(roleResponsibilityDetail.roleData.id)" v-model="roleResponsibilityDetail.roleData" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in roles" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.showDropdownRole" type="text" class="form-control" id="role" v-model="roleResponsibilityDetail.role" placeholder="Enter New Roles">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.roleResponsibilityDetail.role.$error && !$v.roleResponsibilityDetail.role.required" class="text-danger">Please Enter Role</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="responsibility">Responsibility</label>
                                                        <b-form-tags input-id="tags-separators" v-model="roleResponsibilityDetail.responsibilityArray" class="form-control" separator="," placeholder="Enter Responsibilities" ></b-form-tags>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="clearRoleResponsibility" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            <button type="submit" @click="saveRoleResponsibility()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="mt-3">
                        <div class="card mt-1" v-for="responsibility in responsibilities">
                            <div class="card-body p-2" v-if="responsibility.id !== roleResponsibilityDetail.editId">
                                <div class="row" >
                                    <div class="col-12">
                                        <div class="form-group">
                                            <a href="javascript:" class="float-right button-add" title="Delete Role" @click="deleteRoleResponsibility(responsibility.id)" data-toggle="modal" v-b-modal.deleteRoleResponsibility><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                            <a href="javascript:" class="float-right button-add" title="Edit Role" @click="editRole(responsibility)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                            <h6 >{{ responsibility.role }}</h6>
                                            <p> {{ responsibility.responsibility ? (responsibility.responsibility).replaceAll(',', ', ') : '' }} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </b-tab>
                <b-tab  v-if="displayControl.techSkill" title="Tech. / Skills">
                    <b-card no-body class="">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-techSkill>
                                <span v-if="elements.techEditMode">Edit Technology & Skill</span>
                                <span v-else>
                                    <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Technology & Skill
                                </span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-techSkill" accordion="my-accordion" v-model="elements.techVisible" role="tabpanel">
                            <b-card-body class="p-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-12 mb-3">
                                                    <label for="technology">Technology</label>
                                                    <a href="javascript:" class="float-right button-add" @click="elements.showDropdownTech=!elements.showDropdownTech">
                                                        <i v-if="!elements.showDropdownTech" title="Existing Technologies" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                        <i v-if="elements.showDropdownTech" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                    </a>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkillDetail.technology.$error }">
                                                        <select v-if="elements.showDropdownTech" @change="getTechnologies(technologySkillDetail.technologyData.id)" v-model="technologySkillDetail.technologyData" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in technologies" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.showDropdownTech" type="text" class="form-control" id="technology" v-model="technologySkillDetail.technology" placeholder="Enter New Technologies">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.technologySkillDetail.technology.$error && !$v.technologySkillDetail.technology.required" class="text-danger">Please Enter Technology</label>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="skills">Skills</label>
                                                        <b-form-tags input-id="tags-separators" v-model="technologySkillDetail.skillsArray" separator="," class="form-control" placeholder="Enter skills" ></b-form-tags>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="clearTechnologySkill" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            <button type="submit" @click="saveTechnologySkill()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right">
                                                <i class="fa fa-floppy-o pr-2"></i>Save
                                            </button>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="mt-3">
                        <div class="card my-1" v-for="technologySkill in technologySkills">
                            <div class="card-body p-2" v-if="technologySkill.id !== technologySkillDetail.editId">
                                <div class="row" >
                                    <div class="col-12">
                                        <div class="form-group">
                                            <a href="javascript:" class="float-right button-add" title="Delete Technology" @click="deleteTechnologySkill(technologySkill.id)" data-toggle="modal" v-b-modal.deleteTechnologySkill><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                            <a href="javascript:" class="float-right button-add" title="Edit Technology" @click="editTech(technologySkill)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                            <h6>{{ technologySkill.technology }}</h6>
                                            <p>{{ technologySkill.skills ? (technologySkill.skills).replaceAll(',', ', ') : '' }} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Contact">
                    <div class="row">
                        <div class="col-10">
                        </div>      
                        <div class="col-md-2 col-sm-2 pl-3 float-right">
                            <div class="more-btns text-center">
                                <button v-if="!elements.contactEditMode" href="javascript:" @click="elements.contactEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                </button>
                                <div v-if="elements.contactEditMode">
                                    <button type="submit" @click="saveContact" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                    <button type="submit" @click="getContacts();elements.contactEditMode = false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="mobile">Mobile</label>
                                <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.mobile">
                            </div>
                        </div>
                        <div class="col-12  col-sm-6">
                            <div class="form-group">
                                <label for="emailId">Email Id</label>
                                <input type="email" class="form-control" id="emailId" :disabled="!elements.contactEditMode" v-model="contactDetail.emailId">
                                <span v-if="!$v.contactDetail.emailId.email" class="text-danger">Please enter a valid Email Id</span>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="mobile">Alternate Mobile</label>
                                <input type="text" class="form-control" id="altMobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.alternateMobile">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="alternateEmailId">Alternate Email Id</label>
                                <input class="form-control" type="email" id="alternateEmailId" :disabled="!elements.contactEditMode" v-model="contactDetail.alternateEmailId">
                                <span v-if="!$v.contactDetail.alternateEmailId.email" class="text-danger">Please enter a valid Email Id</span>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="phone">Phone</label>
                                <input class="form-control" type="text" id="phone" :disabled="!elements.contactEditMode" v-model="contactDetail.phone">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label for="phone">Office Phone</label>
                                <input class="form-control" type="text" id="officePhone" :disabled="!elements.contactEditMode" v-model="contactDetail.officePhone">
                            </div>
                        </div>


                        <div class="divider"></div>
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-1 pl-3 my-0 accordion-title" block href="javascript:">
                                Address Info
                            </label>
                            <!-- <div class="separator mx-2"></div> -->
                        </b-card-header>                                

                        <!-- <label class="px-3 mb-2" for="phone">Address Info</label> -->
                        <!-- <h6 class="px-3 mb-2">Address Info</h6>  -->
                        <div class="col-12 col-lg-12 col-xl-12 pl-3 text-left">
                        <div class="card mb-3">
                            <div class="card-body p-2">
                                <div class="row px-2" >
                                    <div class="col-6">
                                        <div class="form-group ">
                                            <label for="title">Building Name</label>
                                            <input type="text" class="form-control" id="Address1" :disabled="!elements.contactEditMode" v-model="contactDetail.address1" >
                                        </div>
                                    </div>
                                    <div class="col-6 ">
                                        <div class="form-group ">
                                            <label for="title">Street Name</label>
                                            <input type="text" class="form-control" id="streetName" :disabled="!elements.contactEditMode" v-model="contactDetail.streetName">
                                        </div>
                                    </div>
                                </div>
                                <div class="row px-2" >
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="city">City</label>
                                            <input type="text" class="form-control" id="city" :disabled="!elements.contactEditMode" v-model="contactDetail.city">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="State">State</label>
                                            <input type="text" class="form-control" id="State" :disabled="!elements.contactEditMode" v-model="contactDetail.state">
                                        </div>
                                    </div>
                                </div>
                                <div class="row px-2" >
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="projectManager">Country</label>
                                            <select v-if="elements.contactEditMode" v-model="contactDetail.countryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <input v-if="!elements.contactEditMode" disabled v-model="contactDetail.country" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group ">
                                            <label for="title">Pin Code</label>
                                            <input type="text" class="form-control" id="pincode" :disabled="!elements.contactEditMode" v-model="contactDetail.pincode">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="divider"></div>
                    </div>
                </b-tab>
                <b-tab v-if="humanResourceDetails.isEmployee && displayControl.accountInfo" title="Account Info">
                    <div id="profile">
                        <div class="profile_header row">
                            <div class="col-12 col-lg-12 col-xl-12 text-left">
                                <div class="card mb-2">
                                    <div class="card-body p-1">
                                        <div class="py-3">
                                        <div class="row px-3">
                                            <div class="col-8">
                                                <h6 class="my-auto">
                                                    <span class="d-block accordion-title">User Account Info</span>
                                                </h6>
                                            </div>      

                                            <div class="col-md-4 col-sm-4 pl-3 mr-0 ml-auto">
                                                <label v-if="humanResourceDetails.userName != null && humanResourceDetails.userName !=''" :title="humanResourceDetails.active==true ? 'Active User' : 'User Not Activated'" class="py-0 mr-3 pointer float-right">
                                                    <i class="fa fa-user fa-xs" :class="{ 'text-success' : humanResourceDetails.active==true }" aria-hidden="true"></i>
                                                </label>
                                                <!-- <b-button v-else class="addnew-tassk-btn sbtn float-right" v-b-modal.createUserAccountPopup variant="outline-primary" size="xs"><i class="fa fa-plus pr-1"></i>Create User Account</b-button> -->
                                            </div>
                                        </div>

<!--                                             <h6>
                                                <span class="d-block p-1 pl-3 my-0 accordion-title">User Account Info</span>
                                                <label v-if="humanResourceDetails.userName != null && humanResourceDetails.userName !=''" :title="humanResourceDetails.active==true ? 'Active User' : 'User Not Activated'" class="py-0 mr-3 pointer float-right">
                                                    <i class="fa fa-user fa-xs" :class="{ 'text-success' : humanResourceDetails.active==true }" aria-hidden="true"></i>
                                                </label>
                                                <b-button v-else class="addnew-tassk-btn sbtn float-right" v-b-modal.createUserAccountPopup variant="outline-primary" size="xs"><i class="fa fa-plus pr-1"></i>Create User Account</b-button>
                                            </h6> -->
                                            <div v-if="humanResourceDetails.userName != null && humanResourceDetails.userName !=''" class="ml-3">
                                                <p>
                                                    <span v-if="humanResourceDetails.userName!=''">Account Name : {{humanResourceDetails.userName}}</span>
                                                </p>
                                                <p>
                                                    <span v-if="humanResourceDetails.userType!=''">User Type : {{humanResourceDetails.userType}}</span>
                                                </p>
                                                <p>
                                                    <span v-if="humanResourceDetails.userRoles!=''">User Roles : {{humanResourceDetails.userRoles}}</span>
                                                </p>
                                                <p>
                                                    <span v-if="humanResourceDetails.loginName!=''">Login Email : {{humanResourceDetails.loginName}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="profile_header row">
                            <div class="col-12 col-lg-12 col-xl-12 text-left">
                                <div class="card mb-2">
                                    <div class="card-body p-1">
                                        <div class="row px-3 my-3">
                                            <div class="col-10">
                                                <h6 class="my-auto">
                                                    <span class="d-block accordion-title">Bank Account Info</span>
                                                </h6>
                                            </div>      

                                            <div class="col-md-2 col-sm-2 pl-3 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.bankAccountEditMode" @click="elements.bankAccountEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.bankAccountEditMode">
                                                        <button type="submit" @click="saveEmpBankAccount" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="getEmpBankAccountDetail();elements.bankAccountEditMode=false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row px-3">
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="bankId">Bank Name</label>
                                                    <select v-model="empBankAccountDetail.bankId" :disabled="!elements.bankAccountEditMode" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in banks" :value="info.id">{{info.value}}</option>
                                                    </select> 
                                                </div>
                                            </div>

                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="bankBranch">Bank Branch Name</label>
                                                    <input class="form-control" type="text" id="bankBranch" :disabled="!elements.bankAccountEditMode" v-model="empBankAccountDetail.bankBranch">
                                                </div>  
                                        </div>
                                        </div>
                                            <div class="row px-3">
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="bankAccountNumber">A/c No.</label>
                                                    <input class="form-control" type="text" id="bankAccountNumber" :disabled="!elements.bankAccountEditMode" v-model="empBankAccountDetail.bankAccountNumber">
                                                </div>  
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="ifscCode">IFSC Code </label>
                                                    <input class="form-control" type="text" id="ifscCode" :disabled="!elements.bankAccountEditMode" v-model="empBankAccountDetail.ifscCode">
                                                </div>  
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="profile_header row">
                            <div class="col-12 col-lg-12 col-xl-12 text-left">
                                <div class="card mb-2">
                                    <div class="card-body p-3">
                                        <div v-if="showAccInfo && humanResourceDetails.isEmployee && empPayrolls.length>0">
                                            <h6>
                                                <span class="">Payslip</span>
                                            </h6>
                                            <div class="row">
                                                <div class="col-4 col-lg-4 col-xl-4">
                                                    <div class="input-group">
                                                        <!-- <label for="mobile">Payslip</label> -->
                                                        <select v-model="payroll" class="form-control">
                                                            <option v-for="(info, index) in empPayrolls" :key="index" :value="info">{{ getMonthYearAlt(info.payrollPeriodStr) }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-lg-6 col-xl-6 pr-0">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1">Net Pay</span>
                                                        </div>
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text font-weight-bold" id="basic-addon1">
                                                                {{ currencySymbol }} {{ (Number(payroll.totalEarning) - Number(payroll.totalDeduction)).toFixed(2) }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-2 col-lg-2 col-xl-2">
                                                    <button class="btn btn-outline-primary sbtn btn-sm py-1" @click="viewItemDetails">Show</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab v-if="humanResourceDetails.isEmployee && displayControl.empPerformance" title="Performance">
                    <div class="card p-2 mb-2">
                        <div class="form-row text-right">
                            <div class="col-3">
                            </div>
                            <div class="col-3">
                                <label class="mt-1 mb-0">
                                    <p class="m-0">Review Period</p>
                                </label>
                            </div>
                            <div class="col-3">
                                <select v-model="reviewPeriodEnum" @change="onReviewPeriodChange" class="form-control" id="assetCategoryIdDet" >
                                    <!-- <option value="">Select</option> -->
                                    <option v-for="(info, index) in performanceReviewPeriod" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                            <div class="col-3">
                                <input v-if="reviewPeriodEnum == 1" type="month" class="form-control" @change="viewEmployeePerformance" v-model="reviewPeriod">
                                <select v-else v-model="reviewPeriod" class="form-control" @change="viewEmployeePerformance" >
                                    <option value="">Select Year</option>
                                    <option v-for="(info, index) in years" :value="info">{{info}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Performance By Area
                            </label>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <b-table show-empty small stacked="md" sort-icon-left :items="performanceRatingList" :fields="performanceRatingFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(rating)="data">
                                    <b-form-rating id="rating-inline" class="rating pe-none" no-border size="sm" color="#ff8800" :stars="employeePerformance.maxRating" inline :value="data.value"></b-form-rating>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Performance Ratings added</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Overall Performance
                            </label>
                            <div class="separator mb-2 mx-2"></div>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <div class="form-row">
                                <div class="col-12 col-md-8">
                                    <div class="form-group">
                                        <label>Performance level</label>
                                        <!-- <input type="text" class="form-control" disabled v-model="employeePerformance.performanceLevelAlt"> -->
                                        <div class="form-row">
                                            <div class="col-md-6">
                                                <b-form-input type="text" v-model="employeePerformance.performanceLevelAlt" disabled></b-form-input>
                                                <!-- <select v-model="employeePerformance.ratingScaleId" disabled class="form-control" id="ratingScaleId1" >
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in ratingScaleLookup" :value="info.id">{{info.performanceLevelAlt}}</option>
                                                </select> -->
                                            </div>
                                            <div class="col-md-6">
                                                <b-form-rating id="rating-inline" class="rating pe-none" size="sm" color="#ff8800" :stars="employeePerformance.maxRating" inline :value="employeePerformance.rating"></b-form-rating>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-4">
                                    <div class="form-group">
                                        <label>Evaluated By</label>
                                        <input type="text" class="form-control" disabled v-model="employeePerformance.evaluatedBy">
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="card-body p-0">
                                <b-card no-body class="mb-2">
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                            Description
                                        </label>
                                        <div class="separator mb-2 mx-2"></div>
                                    </b-card-header>
                                    <b-card-body class="py-3 px-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <div v-html="employeePerformance.description"></div>
                                                    <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" disabled :toolbar="toolbar" :init="initObj" v-model="employeePerformance.description"></editor> -->
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab v-if="humanResourceDetails.isEmployee && displayControl.empLeave" title="Leave">
                    <div class="card p-2 mb-2">
                        <div class="form-row text-left">
                            <div class="col-2">
                                <label class="mt-1 mb-0">
                                    <p class="m-0">Date Range</p>
                                </label>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-4 pr-0">
                                        <input type="date" v-model="empAttendance.startDate" class="form-control" id="wefDate">
                                    </div>
                                    <div class="col-1 px-0 text-center">
                                        <label class="my-auto py-1">to</label>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <input type="date" v-model="empAttendance.endDate" class="form-control" id="wefDate">
                                    </div>
                                    <div class="col pl-0">
                                        <button type="submit" @click="getAtendanceSummary" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3">Go</button>
                                        <!-- <input type="date" v-model="empAttendance.endDate" class="form-control" id="wefDate"> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 text-right">
                                <p class="title-highlight m-0 mt-1">{{ empAttendance.startDate | formatDate }} to {{ empAttendance.endDate | formatDate }}</p>
                            </div>
                        </div>
                    </div>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Attendance Performance
                            </label>
                            <div class="separator mx-2"></div>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <b-table show-empty small stacked="md" sort-icon-left :items="employeeAttendanceSummaryList" :fields="employeeAttendanceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template v-slot:cell(totalFirstHalfLeave)="data">
                                    {{ data.value + data.item.totalSecondHalfLeave }}
                                </template>
                                <template v-slot:cell(attendancePercentage)="data">
                                    <span title="Attendance Percentage">{{ empAttendeesPercentage(data.item) }} %</span>
                                    <b-progress class="mx-3" height="10px" :value="empAttendeesPercentage(data.item)" :variant="overallAttendanceProgressVariant(empAttendeesPercentage(data.item))"></b-progress>
                                </template>
                                <template v-slot:empty="erm">
                                    <div class="text-center">No Record Found</div>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Leave Summary
                            </label>
                            <div class="separator mb-2 mx-2"></div>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <b-table show-empty outlined small stacked="md" sort-icon-left :items="employeeLeaveSummaryByType" :fields="empLeaveSummaryFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Leaves found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-levPol>
                                Leave Policy <span> - Calendar Year {{ new Date().getFullYear() }} </span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-levPol" accordion="my-accordion" role="tabpanel">
                            <b-card-body class="p-3">
                                <b-table show-empty outlined small stacked="md" sort-icon-left :items="empLeavePolicyList" :fields="empLeavePolicyFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(leavesRemaining)="data">
                                        <div class="text-center">{{ empLeavePolicyList[data.index].daysPerYear - empLeavePolicyList[data.index].leaveTakenCount }}</div>
                                    </template>                                    
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Leaves found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-tab>
                <b-tab v-if="showAccInfo && humanResourceDetails.isEmployee && displayControl.empDoc" @click="getAttachment" title="Docs">

                    <div class="card mb-3">
                        <b-card no-body class="">
                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Document>
                                    <span v-if="!elements.documentEditMode">
                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Document
                                    </span>
                                    <span v-if="elements.documentEditMode">Edit</span>
                                </label>
                            </b-card-header>
                            <b-collapse id="accordion-Document" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                <b-card-body class="p-3">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="documentType">Document Type</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.attachmentData.documentTypeId.$error }">
                                                    <select  v-model="attachmentData.documentTypeId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in documentTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.attachmentData.documentTypeId.$error && !$v.attachmentData.documentTypeId.required" class="text-danger">Please Select Document Type</label>
                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.documentTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                            </div>  
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label class="float-left invisible">Document Type</label>
                                                <div class="attachments mb-2">
                                                    <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="attachmentData.attachments">
                                                    </b-form-file>
                                                    <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                        <i class="fa fa-check pr-2"></i>
                                                    </a>
                                                </div>
                                            </div>                                                    
                                        </div>
                                    </div>                                            
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div v-show="attachments.length != 0" class="col-12 px-0">
                                    <div class="">
                                        <div>
                                            <p class="my-1 border p-2" v-for="(attachment, index) in attachments">
                                                <span class="pr-3">{{index+1}}</span>
                                                <span class="pr-3">{{attachment.documentType}}</span>
                                                <i class="fa fa-file-image-o pr-2 ml-3 icon-doc" aria-hidden="true"></i>
                                                <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                
                                                <a href="javascript:" class="float-right button-remove px-2 attachments-remove" title="Delete Attachment" @click="deleteFile(attachment.id)" data-toggle="modal" v-b-modal.deleteAttachment>
                                                    <i class="fa fa-trash pr-2 action-link"></i>
                                                </a>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-overlay>
        <!-- <JobPositionChangePopup id="jobPositionChangePopup" ref="jobPositionChangePopupRef" :employeeId="employeeId" :key="jobPositionChangePopupKey" @refreshData="viewHumanResource" /> -->

        <CreateUserAccountPopup @refresh-data="viewHumanResource"  />
        <PayrollDetailPopup v-if="showDialog" ref="payrollDetailPopupRef" @closeDialog="showDialog=false;" />
        <TypeLookup id="documentTypeModal" ref="documentTypeModal"  entity="document_type" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupDataEmp(),getAttachment()" />
        <DeleteComponent id="deleteEmploymentHistory" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Employee Position Change History?" />
        <DeleteComponent id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
        <DeleteComponent id="deleteAttachment" :onYes="onYesAttachment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Document Attachment?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
// import JobPositionChangePopup from '@/components/popup/action/JobPositionChangePopup.vue';
import { required, email, minLength, maxLength, requiredIf, sameAs } from "vuelidate/lib/validators";

import AddDepartmentLookup from '@/components/popup/lookup/AddDepartmentLookup.vue';
import AddDesignationLookup from '@/components/popup/lookup/AddDesignationLookup.vue';
import PayrollDetailPopup from '@/components/popup/itemdetail/PayrollDetailPopup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
import CreateUserAccountPopup from '@/components/popup/action/CreateUserAccountPopup.vue'
import enummixin from "@/mixins/enummixin";
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        Editor,
        // JobPositionChangePopup,
        AddDepartmentLookup,
        AddDesignationLookup,
        CreateUserAccountPopup,
        PayrollDetailPopup,
        TypeLookup,
        VhextMoneyControl,
    },
    props: {
        // humanResourceId: Number,
        showAccInfo: Boolean,
    },
    data() {
        return {
            showDialog: false,
            showHRDetOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            defaultPhoto: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',
            entity: 'human_resource',
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),
            typeLookupTitle: 'Document Type Settings',
            jobPositionChangePopupKey: 100,
            employeeId: '',

            payroll: {},

            departmentLookupTitle: 'Departments',
            designationLookupTitle: 'Designations',
            
            monthList: month,
            reviewPeriodEnum: '',
            reviewPeriod: '',

            humanResourceId: '',

            displayControl: {
                roleResp: false,
                techSkill: false,
                accountInfo: false,
                empPerformance: false,
                empLeave: false,
                empDoc: false,
            },

            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                empHistoryVisible: false,

                primaryInfoEditMode: false,
                empInfoEditMode: false,
                roleVisible: false,
                roleEditMode: false,
                techVisible: false,
                techEditMode: false,
                contactEditMode: false,
                bankAccountEditMode: false,
                accountInfoEditMode: false,
                documentEditMode: false,
                showDropdownRole: false,
                showDropdownTech: false
            },
            humanResourceDetails: {
                id: '',
                vhextAccountId: '',
                projectId: '',
                entity: '',
                userId: '',
                userName: '',
                loginName: '',
                userType: '',
                active: '',
                humanResourceId: '',                
                humanResourceTypeId: '',
                subContractId: '',
                name: '',
                position:'',
                gender: '',
                dateOfBirth:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate:'',
                workShiftId: '',
                jobCategoryId: '',
                jobCategory: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                empId: '',
                employeeId: '',
                employeeCode: '',
                reportingToId: '',
                employmentChangeById: '',
                employmentChangeBy: '',
                wefDate: '',
                endDate: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                hourlySalary: null,
                employeeStatusId: '',
                employeeStatus: '',
                active: true,
                isEmployee: false,
            },
            attachmentData: {
                attachments:[],
                documentTypeId:''
            },
            empBankAccountDetail: {
                id: '',
                empBankAccountId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                humanResourceId: '',
                bankAccountNumber: '',
                bankId: '',
                bankBranchId: '',
                bankBranch: '',
                ifscCode: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            contactDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntity: '',
                contactId: '',
                userId: '',
                contactName: '',
                firstName: '',
                middleName: '',
                lastName: '',
                profession: '',
                company: '',
                position: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                address2: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                timeZone: '',
                website: '',
                skypeId: '',
                facebookId: '',
                linkedinId: '',
                twitterId: '',
                gender: null,
                dateOfBirth: '',
                lastUpdatedDt: '',
                active: '',
                photo:'',
            },

            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: 'human_resource',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''

            },
            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),                
                entity: 'technology_skill',
                refEntity: 'human_resource',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technologyData: '',
                technology: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },
            empAttendance: {
                startDate: this.getFirstDateOfMonth(new Date()),
                endDate: this.getLastDateOfMonth(new Date()),
            },
            employeePerformance: {},
            empPayrolls: [],
            empHistoryList: [],
            users: [],
            humanResourceTypes: [],
            banks: [],
            subContracts: [],
            technologies: [],
            roles: [],
            humanResources: [],
            responsibilities: [],
            technologySkills: [],
            contacts: [],
            countries: [],
            statuses: [],
            workTypes: [],
            documentTypes: [],
            departments: [],
            designations: [],
            jobCategories: [],
            workShifts: [],
            attachments: [],

            empHistoryFields: [
                { key:'index',label: 'SN' },
                { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },
                { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'salary', label: 'CTC(Annual)', sortable: true, sortDirection: 'desc' },
                // { key: 'hourlySalary', label: 'Hourly Salary', sortable: true, sortDirection: 'desc' },
                { key: 'wefDate', label: 'Effective Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            performanceRatingList: [],
            performanceRatingFields: [
                { key: 'index', label: 'SN' },
                { key: 'performanceArea', label: 'Performance Area', sortable: true, sortDirection: 'desc' },
                { key: 'rating', label: 'Rating', sortable: true, sortDirection: 'desc' },
                { key: 'performanceLevel', label: 'Performance Level', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],

            employeeAttendanceSummaryList: [],
            employeeAttendanceFields: [
                { key: 'workingDays', label: 'Working Days' },
                { key: 'totalPresent', label: 'Present' },
                { key: 'totalFirstHalfLeave', label: 'Half Day Leave' },
                // { key: 'totalSecondHalfLeave', label: 'Leave Second Half' },
                { key: 'totalFullDayLeave', label: 'Full Day Leave' },
                // { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'attendancePercentage', label: 'Attendance Performance'},
            ],

            employeeLeaveSummaryByType: [],
            empLeaveSummaryFields: [
                { key: 'index', label: 'SN' },
                // { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'leaveType',label: 'Leave Type', sortable: true,sortDirection: 'desc' },
                // { key: 'daysPerYear',label: 'Total Leaves / Year', sortable: true,sortDirection: 'desc' },
                { key: 'totalLeave',label: 'Leaves Taken', sortable: true,sortDirection: 'desc' },
                // { key: 'leavesRemaining',label: 'Leaves Remaining', sortable: true,sortDirection: 'desc' },
            ],

            empLeavePolicyList: [],
            empLeavePolicyFields: [
                { key: 'index', label: 'SN' },
                // { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'leaveType',label: 'Leave Type', sortable: true,sortDirection: 'desc' },
                { key: 'daysPerYear',label: 'Total Leaves / Yr', sortable: true,sortDirection: 'desc' },
                { key: 'daysPerMonth',label: 'Total Leaves / Month', sortable: true,sortDirection: 'desc' },
                { key: 'leaveTakenCount',label: 'Leaves Taken', sortable: true,sortDirection: 'desc' },
                { key: 'leavesRemaining',label: 'Leaves Remaining / Yr', sortable: true,sortDirection: 'desc' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        years () {
            const year = new Date().getFullYear() - 5
            return Array.from({length: 50}, (value, index) => year + index)
        },
        monthlySalary() {
            return this.humanResourceDetails.salary ? Math.trunc(this.humanResourceDetails.salary / 12) : 0;
        }
    },
    // watch: {
    //     humanResourceId: function (newId, oldId) {
    //         if (newId !== 0 && newId !== undefined) {
    //             this.getOtherHRDetails();
    //         }
    //     },
    // },
    mounted() {
        this.reviewPeriodEnum = this.performanceReviewPeriod[0].id;
        this.reviewPeriod = this.getMonthYear(new Date());
        this.loadOnce = true;
        this.lookupData();
        this.lookupDataEmp();
        // this.getOtherHRDetails();
    },
    validations: {
        contactDetail: {
            emailId: {
                email
            },
            alternateEmailId: {
                email
            },
        },

        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {
            technology: {
                required
            }
        },
        humanResourceDetails: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            employeeCode: {
                required: requiredIf(function (abc) {
                    return this.humanResourceDetails.isEmployee;
                })
            }
        },
        attachmentData: {
            documentTypeId: {
                required
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.roles = result.roleLookup;
                    this.technologies = result.technologyLookup;
                    this.countries = result.countryLookup;
                    this.users = result.userLookup;
                    this.humanResourceTypes = result.customTypeLookup;
                    this.subContracts = result.subContractLookup;
                    this.banks = result.bankLookup;

                })

        },
        lookupDataEmp: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: 'employee', 
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.departments = result.departmentLookup;
                    this.designations = result.designationLookup;
                    this.workShifts = result.workShiftLookup;
                    this.jobCategories = result.customCategoryLookup;
                    this.workTypes = result.workTypeLookup; 
                    this.documentTypes = result.documentTypeLookup;
                    this.statuses = result.statusLookup;
                    this.customDisplayControlLookup = result.customDisplayControlLookup;
                    this.$nextTick(() => {
                        this.setDisplayControls();
                    })
                })

        },
        getOtherHRDetails: function(humanResourceId) {
            if (humanResourceId) {
                    this.humanResourceId = humanResourceId;
                    this.viewHumanResource();
                    this.getRoleResponsibilities();
                    this.getTechnologySkills();
                    this.getContacts();
                    this.getEmpBankAccountDetail();
                    this.viewEmployeePerformance();
                    this.getAtendanceSummary();
                    this.getAttachment();
                    this.elements.editMode = false;
                    this.elements.primaryInfoEditMode = false;
                    this.elements.empInfoEditMode = false;
                    this.elements.roleEditMode = false;
                    this.elements.techEditMode = false;
                    this.elements.contactEditMode = false;
                    this.elements.bankAccountEditMode = false;
                    this.elements.documentEditMode = false;
                } else {
                    this.clearData();
                }
        },
        getRoleResponsibilities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.humanResourceId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.responsibilities = result.cmrRoleResponsibilityList;
                    }
                })
        },

        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.humanResourceId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.humanResourceId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/view', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.contact) {
                        this.contactDetail = result.contact;
                    }
                    else {
                        this.clearContact();
                    }
                })
        },
        getEmpBankAccountDetail: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                humanResourceId: this.humanResourceId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empbankaccount/view', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.empBankAccount) {
                        this.empBankAccountDetail = result.empBankAccount;
                    }
                    else {
                        this.clearEmpBankAccountDetail();
                    }
                    console.log("EBA",this.empBankAccountDetail);
                })

        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getEmpPayrollList: function() {
            if (this.employeeId) {
                let data = {
                    employeeId: this.employeeId,
                }
                axios.post(this.$store.getters.getAPIBasePath + '/finance/employeepayroll/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.empPayrolls = result.payrollList;
                        if (result.payrollList && result.payrollList.length>0) {
                            this.payroll = this.empPayrolls[0];
                        }
                    })
            }
        },
        updateHumanResource: function() {
            this.$v.humanResourceDetails.$touch();
            console.log("HR",this.humanResourceDetails);
            if (!this.$v.humanResourceDetails.$invalid) {
                this.showHRDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/update', this.humanResourceDetails)
                    .then((response) => {
                        this.showHRDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        this.elements.primaryInfoEditMode = false;
                        this.elements.empInfoEditMode = false;
                        this.elements.empHistoryVisible = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.viewHumanResource();
                            this.$emit('eventHRSave', this.humanResourceId);

                        } else if(result.status == "OK") {
                            iziToast.info({
                                title: 'Conflict',
                                message: result.message,
                                position: 'topCenter'
                            });
                        }
                    })
                    .catch(error => {
                        this.showHRDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    });
            }

        },
        deleteEmploymentHistory: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmploymentHistory();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.empHistoryVisible = false;
            this.viewHumanResource();
            // this.elements.primaryInfoEditMode = false;
            // this.humanResourceDetails.id = '';
            // this.humanResourceDetails.humanResourceId = '';
            // this.humanResourceDetails.loginName = '';
            // this.humanResourceDetails.userType = '';
            // this.humanResourceDetails.name = '';
            // this.humanResourceDetails.position = '';
            // this.humanResourceDetails.qualification = '';
            // this.humanResourceDetails.experienceYears = '';
            // this.humanResourceDetails.experienceMonths = '';
            // this.humanResourceDetails.technologySkill = '';
            // this.humanResourceDetails.recruitedBy  = '';
            // this.humanResourceDetails.joiningDate = '';
            // this.humanResourceDetails.createdBy = '';
            // this.humanResourceDetails.createdDt = '';
            // this.humanResourceDetails.lastUpdatedDt = '';
           
        },
        clearHRData: function() {
            this.humanResourceDetails.id = '';
            this.humanResourceDetails.humanResourceId = '';
            this.humanResourceDetails.vhextAccountId = '';
            this.humanResourceDetails.userId = '';
            this.humanResourceDetails.humanResourceTypeId = '';
            this.humanResourceDetails.subContractId = '';
            this.humanResourceDetails.name = '';
            this.humanResourceDetails.emailId = '';
            this.humanResourceDetails.position = '';
            this.humanResourceDetails.roleResponsibility = '';
            this.humanResourceDetails.technologySkill = '';
            this.humanResourceDetails.qualification = '';
            this.humanResourceDetails.experienceYears = '';
            this.humanResourceDetails.experienceMonths = '';
            this.humanResourceDetails.hasContactInfo = '';
            this.humanResourceDetails.recruitedById = '';
            this.humanResourceDetails.dateOfBirth = '';
            this.humanResourceDetails.joiningDate = '';
            this.humanResourceDetails.createdById = '';
            this.humanResourceDetails.departmentId = '';
            this.humanResourceDetails.designationId = '';
            this.humanResourceDetails.workTypeId = '';
            this.humanResourceDetails.jobCategoryId = '';
            this.humanResourceDetails.jobCategory = '';
            this.humanResourceDetails.employeeCode = '';
            this.humanResourceDetails.reportingToId = '';
            this.humanResourceDetails.employmentChangeById = '';
            this.humanResourceDetails.employmentChangeBy = '';
            this.humanResourceDetails.wefDate = '';
            this.humanResourceDetails.endDate = '';
            this.humanResourceDetails.workShiftId = '';
            this.humanResourceDetails.salary = '';
            this.humanResourceDetails.hourlySalary = '';
            this.humanResourceDetails.statusId = '';
            this.$v.humanResourceDetails.$reset();
        },
        viewHumanResource: function() {
            this.showHRDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/hrm/view/' + this.humanResourceId)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    let humanResourceDetails = result.humanResourceView;
                    console.log(humanResourceDetails);
                    if (humanResourceDetails) {
                        this.humanResourceDetails.id = humanResourceDetails.id;
                        this.humanResourceDetails.active = humanResourceDetails.active;
                        this.humanResourceDetails.createdBy = humanResourceDetails.createdBy;
                        this.humanResourceDetails.createdById = humanResourceDetails.createdById;
                        this.humanResourceDetails.createdDt = humanResourceDetails.createdDt;
                        this.humanResourceDetails.emailId = humanResourceDetails.emailId;
                        this.humanResourceDetails.empId = humanResourceDetails.empId;
                        this.humanResourceDetails.employeeId = humanResourceDetails.employeeId;
                        this.humanResourceDetails.workTypeId = humanResourceDetails.workTypeId;
                        this.humanResourceDetails.workType = humanResourceDetails.workType;
                        this.humanResourceDetails.departmentId = humanResourceDetails.departmentId;
                        this.humanResourceDetails.jobCategoryId = humanResourceDetails.jobCategoryId;
                        this.humanResourceDetails.jobCategory = humanResourceDetails.jobCategory;
                        this.humanResourceDetails.workShiftId = humanResourceDetails.workShiftId;
                        this.humanResourceDetails.employeeStatusId = humanResourceDetails.employeeStatusId;
                        this.humanResourceDetails.employeeCode = humanResourceDetails.employeeCode;
                        this.humanResourceDetails.reportingToId = humanResourceDetails.reportingToId;
                        this.humanResourceDetails.employmentChangeById = humanResourceDetails.employmentChangeById;
                        this.humanResourceDetails.employmentChangeBy = humanResourceDetails.employmentChangeBy;
                        this.humanResourceDetails.wefDate = humanResourceDetails.wefDate;
                        this.humanResourceDetails.endDate = humanResourceDetails.endDate;
                        this.humanResourceDetails.employeeStatus = humanResourceDetails.employeeStatus;
                        this.humanResourceDetails.designationId = humanResourceDetails.designationId;
                        this.humanResourceDetails.experienceMonths = humanResourceDetails.experienceMonths;
                        this.humanResourceDetails.experienceYears = humanResourceDetails.experienceYears;
                        this.humanResourceDetails.hasContactInfo = humanResourceDetails.hasContactInfo;
                        this.humanResourceDetails.humanResourceId = humanResourceDetails.humanResourceId;
                        this.humanResourceDetails.humanResourceType = humanResourceDetails.humanResourceType;
                        this.humanResourceDetails.humanResourceTypeId = humanResourceDetails.humanResourceTypeId;
                        this.humanResourceDetails.subContractId = humanResourceDetails.subContractId;
                        this.humanResourceDetails.joiningDate = humanResourceDetails.joiningDate;
                        this.humanResourceDetails.lastUpdatedDt = humanResourceDetails.lastUpdatedDt;
                        this.humanResourceDetails.loginName = humanResourceDetails.loginName;
                        this.humanResourceDetails.mobile = humanResourceDetails.mobile;
                        this.humanResourceDetails.name = humanResourceDetails.name;
                        this.humanResourceDetails.phone = humanResourceDetails.phone;
                        this.humanResourceDetails.gender = humanResourceDetails.gender;
                        this.humanResourceDetails.dateOfBirth = humanResourceDetails.dateOfBirth;
                        this.humanResourceDetails.position = humanResourceDetails.position;
                        this.humanResourceDetails.qualification = humanResourceDetails.qualification;
                        this.humanResourceDetails.recruitedBy = humanResourceDetails.recruitedBy;
                        this.humanResourceDetails.recruitedById = humanResourceDetails.recruitedById;
                        this.humanResourceDetails.roleResponsibility = humanResourceDetails.roleResponsibility;
                        this.humanResourceDetails.salary = humanResourceDetails.salary;
                        this.humanResourceDetails.hourlySalary = humanResourceDetails.hourlySalary;
                        this.humanResourceDetails.technologySkill = humanResourceDetails.technologySkill;
                        this.humanResourceDetails.userId = humanResourceDetails.userId;
                        this.humanResourceDetails.userName = humanResourceDetails.userName;
                        this.humanResourceDetails.userType = humanResourceDetails.userType;
                        this.humanResourceDetails.userRoles = humanResourceDetails.userRoles;
                        this.humanResourceDetails.userTypeCode = humanResourceDetails.userTypeCode;
                        this.humanResourceDetails.userTypeId = humanResourceDetails.userTypeId;
                        this.humanResourceDetails.vhextAccountId = humanResourceDetails.vhextAccountId;

                        this.humanResourceDetails.isEmployee = humanResourceDetails.employeeId ? true : false;
                        this.employeeId = humanResourceDetails.empId;
                        this.$nextTick(() => {
                            this.getEmpPayrollList();
                            this.getEmploymentHistory();
                            this.getEmpLeavePolicies();
                        })
                    }
                    else {
                        this.clearHRData();
                    }
                })
                this.elements.editMode = false;
                this.elements.humanResourceDetails = true;
        },
        getEmploymentHistory: function() {
            if (this.employeeId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    employeeId: this.employeeId,
                }
                // this.showHRDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/list', data)
                    .then((response) => {
                        // this.showHRDetOverlay = false;
                        let result = response.data;
                        this.empHistoryList = result.employmentHistoryList;
                    })
            }
        },
        getAtendanceSummary: function() {
            if (this.humanResourceId && this.humanResourceDetails.isEmployee) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    humanResourceId: this.humanResourceId,
                    startDate: this.empAttendance.startDate,
                    endDate: this.empAttendance.endDate,
                }
                this.showHRDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/attendance/summary', data)
                    .then((response) => {
                        this.showHRDetOverlay = false;
                        let result = response.data;
                        this.employeeAttendanceSummaryList = result.employeeAttendanceSummary;
                        this.employeeLeaveSummaryByType = result.employeeLeaveSummaryByType;
                    })
                    .catch(error => {
                        this.showHRDetOverlay = false;
                    })
            }
        },
        getEmpLeavePolicies: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                humanResourceId: this.humanResourceId,
                jobCategoryId: this.humanResourceDetails.jobCategoryId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empleavepolicy/list', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    this.empLeavePolicyList = result.empLeavePolicyList;
                })
                .catch(error => {
                    this.showHRDetOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        viewEmployeePerformance: function() {
            if (this.humanResourceId && this.reviewPeriodEnum && this.reviewPeriod && this.humanResourceDetails.isEmployee) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    humanResourceId: this.humanResourceId,
                    reviewPeriodEnum: this.reviewPeriodEnum,
                    reviewPeriod: this.reviewPeriodEnum==1 ? this.reviewPeriod  + "-11" : this.reviewPeriod + "-07-11",
                }
                this.showHRDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeperformance/hr/view', data)
                    .then((response) => {
                        this.showHRDetOverlay = false;
                        let result = response.data;
                        if (result.employeePerformance) {
                            this.elements.editMode = false;
                            this.employeePerformance = result.employeePerformance;
                            this.employeePerformance.reviewPeriodEnum = this.reviewPeriodEnum;
                            this.performanceRatingList = result.empPerformanceRatingList;
                            // this.employeePerformance.empPerformanceRatingList = empPerformanceRatingList;
                        }
                        else {
                            this.clearEmployeePerformanceData();
                        }
                        if (this.reviewPeriodEnum == 1) {
                            this.employeePerformance.reviewPeriod = this.getMonthYear(new Date(this.reviewPeriod));
                        } 
                        else {
                            this.employeePerformance.reviewPeriod = new Date(this.employeePerformance.reviewPeriod).getFullYear();
                        }
                    })
                    .catch(error => {
                        this.showHRDetOverlay = false;
                    })
            }
        },
        clearEmployeePerformanceData: function() {
            this.employeePerformance.id = '';
            this.employeePerformance.employeePerformanceId = '';
            this.employeePerformance.reviewPeriodEnum = this.performanceReviewPeriod[0].id;
            this.employeePerformance.reviewPeriod = this.getMonthYear(new Date());
            this.employeePerformance.ratingScaleId = '';
            this.employeePerformance.rating = '';
            this.employeePerformance.performanceLevelAlt = '';
            this.employeePerformance.description = '';
            this.employeePerformance.evaluatedById = '';
            this.employeePerformance.evaluatedBy = '';
            this.employeePerformance.createdBy = '';
            this.performanceRatingList = [];
        },
        saveEmpBankAccount: function() {
            if (this.empBankAccountDetail.id) {
                this.updateEmpBankAccount();
            } else {
                this.addEmpBankAccount();                
            }
        },
        addEmpBankAccount: function() {
            this.empBankAccountDetail.humanResourceId = this.humanResourceId;
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empbankaccount/add', this.empBankAccountDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearEmpBankAccountDetail();
                        this.getEmpBankAccountDetail();
                        
                        // this.$emit('eventHRSave', this.humanResourceId);
                    }
                })
        },
        updateEmpBankAccount: function() {
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empbankaccount/update', this.empBankAccountDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearEmpBankAccountDetail();
                        this.getEmpBankAccountDetail();
                        
                        // this.$emit('eventHRSave', this.humanResourceId);
                    }
                })
        },

        saveContact: function() {
            this.$v.contactDetail.$touch();
            if (!this.$v.contactDetail.$invalid) {
                if (this.contactDetail.refRecordId == this.humanResourceId) {
                    this.updateContact();
                } else {
                    this.addContact();
                }
            }
        },
        addContact: function() {
            this.contactDetail.refRecordId = this.humanResourceId;
            this.contactDetail.refEntity = this.entity;
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contactDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                        this.$emit('eventHRSave', this.humanResourceId);
                    }
                })
        },
        updateContact: function() {
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contactDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    this.elements.bankAccountEditMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                        this.$emit('eventHRSave', this.humanResourceId);
                    }
                })
        },
        clearEmpBankAccountDetail(){
            this.elements.bankAccountEditMode = false;
            this.empBankAccountDetail.id = '';
            this.empBankAccountDetail.empBankAccountId = '';
            this.empBankAccountDetail.humanResourceId = '';
            this.empBankAccountDetail.bankAccountNumber = '';
            this.empBankAccountDetail.bankId = '';
            this.empBankAccountDetail.bank = '';
            this.empBankAccountDetail.bankBranchId = '';
            this.empBankAccountDetail.bankBranch = '';
            this.empBankAccountDetail.ifscCode = '';
            this.empBankAccountDetail.lastUpdatedDt = '';

        },
        clearContact: function() {
            this.elements.contactEditMode = false;
            this.contactDetail.id = '';
            this.contactDetail.refRecordId = '';
            this.contactDetail.refEntity = '';
            this.contactDetail.contactId = '';
            this.contactDetail.userId = '';
            this.contactDetail.contactName = '';
            this.contactDetail.firstName = '';
            this.contactDetail.middleName = '';
            this.contactDetail.lastName = '';
            this.contactDetail.profession = '';
            this.contactDetail.company = '';
            this.contactDetail.position = '';
            this.contactDetail.phone = '';
            this.contactDetail.officePhone = '';
            this.contactDetail.mobile = '';
            this.contactDetail.alternateMobile = '';
            this.contactDetail.emailId = '';
            this.contactDetail.alternateEmailId = '';
            this.contactDetail.address1 = '';
            this.contactDetail.address2 = '';
            this.contactDetail.streetName = '';
            this.contactDetail.city = '';
            this.contactDetail.state = '';
            this.contactDetail.countryId = '';
            this.contactDetail.pincode = '';
            this.contactDetail.timeZone = '';
            this.contactDetail.website = '';
            this.contactDetail.skypeId = '';
            this.contactDetail.facebookId = '';
            this.contactDetail.linkedinId = '';
            this.contactDetail.twitterId = '';
            this.contactDetail.gender = null;
            this.contactDetail.dateOfBirth = '';
            this.contactDetail.lastUpdatedDt = '';
            this.contactDetail.active = '';
        },

        saveRoleResponsibility: function() {
            if (this.roleResponsibilityDetail.roleData.text != null) {
                this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
            }
            this.$v.roleResponsibilityDetail.$touch();
            if (!this.$v.roleResponsibilityDetail.$invalid) {
                if (this.elements.roleEditMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.addRoleResponsibility();
                }
            }
        },
        addRoleResponsibility: function() {
            this.roleResponsibilityDetail.refRecordId = this.humanResourceId;
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        updateRoleResponsibility: function() {
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        clearRoleResponsibility: function() {
            this.elements.roleEditMode = false;
            this.elements.roleVisible = false;
            this.elements.showDropdownRole = false;
            this.roleResponsibilityDetail.editId= '';
            this.roleResponsibilityDetail.role= '';
            this.roleResponsibilityDetail.roleData= '';
            this.roleResponsibilityDetail.responsibility='';
            this.roleResponsibilityDetail.responsibilityArray= [];
            this.$v.roleResponsibilityDetail.$reset();
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYesRole: function() {
            this.showHRDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveTechnologySkill: function() {
            if (this.technologySkillDetail.technologyData.text != null) {
                this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
            }
            this.$v.technologySkillDetail.$touch();
            if (!this.$v.technologySkillDetail.$invalid) {
                if (this.elements.techEditMode) {
                    this.updateTechnologySkill();
                } else {
                    this.addTechnologySkill();
                }
            }
        },
        addTechnologySkill: function() {
            this.technologySkillDetail.refRecordId = this.humanResourceId;
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        updateTechnologySkill: function() {
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        clearTechnologySkill: function() {
            this.elements.techEditMode = false;
            this.elements.techVisible = false;
            this.elements.showDropdownTech = false;
            this.technologySkillDetail.editId= '';
            this.technologySkillDetail.technologyData = '';
            this.technologySkillDetail.technology= '';
            this.technologySkillDetail.skills= '';
            this.technologySkillDetail.skillsArray= [];
            this.$v.technologySkillDetail.$reset();
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYesTech: function() {
            this.showHRDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        // this.$forceUpdate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editRole: function(data) {
            this.elements.roleEditMode = true;
            this.elements.roleVisible = true;
            this.roleResponsibilityDetail.id = data.id;
            this.roleResponsibilityDetail.editId = data.id;
            this.roleResponsibilityDetail.role = data.role;
            this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
            this.roleResponsibilityDetail.refRecordId = data.refRecordId;
            this.roleResponsibilityDetail.refEntityId = data.refEntityId;
            this.roleResponsibilityDetail.projectId = data.projectId;
            this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
            this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibilityDetail.preparedById = data.preparedById;
        },
        editTech: function(data) {
            this.elements.techEditMode = true;
            this.elements.techVisible = true;
            this.technologySkillDetail.id = data.id;
            this.technologySkillDetail.editId = data.id;
            this.technologySkillDetail.technologySkillId = data.technologySkillId;
            this.technologySkillDetail.technology = data.technology;
            this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
            this.technologySkillDetail.refRecordId = data.refRecordId;
            this.technologySkillDetail.refEntityId = data.refEntityId;
            this.technologySkillDetail.projectId = data.projectId;
            this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
            this.technologySkillDetail.preparedById = data.preparedById;
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(roleResponsibility.responsibility);
                    })
                }
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = this.stringToArray(technologySkill.skills);
                    })
                }
        },
        uploadPhoto: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: 'File format not supported',
                    position: 'topRight'
                });
                return false;
            }

            let formData = new FormData();
            formData.append('file', this.$refs.profile_photo.files[0]);
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('id', this.contactDetail.id);

            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    iziToast.success({
                        title: 'Success',
                        message: 'Profile photo uploaded Successfully',
                        position: 'topRight'
                    });
                    this.getContacts();           
                })
                .catch(error => {
                    this.showHRDetOverlay = false;
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Unable to update photo',
                        position: 'topRight'
                    });
                });
        },
        saveAttachment: function() {
            this.$v.attachmentData.$touch();
            if(!this.$v.attachmentData.$invalid){
                if (this.attachmentData.attachments.length == 0) {
                    iziToast.info({
                        message: 'Please choose file.',
                        position: 'topRight'
                    });
                    return;
                } else {
                    let formData = new FormData();    
                    formData.append('files', this.attachmentData.attachments);
                    formData.append('documentTypeIds', this.attachmentData.documentTypeId);
                    formData.append('vhextAccountId', this.vhextAccountId);
                    formData.append('attachedById', this.userId);
                    formData.append('refEntity', this.entity);
                    formData.append('refRecordId', this.humanResourceDetails.id);
                    this.showHRDetOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.showHRDetOverlay = false;
                            let result = response.data;
                            if (result.status == "OK") {
                                iziToast.success({
                                    title: 'Success',
                                    message: result.message,
                                    position: 'topRight'
                                });
                                this.attachmentData.attachments = [];
                                this.getAttachment();
                            }
                        })
                        .catch(error => {
                            this.showHRDetOverlay = false;
                        });
            
                }
            }
            
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.humanResourceId
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if(result!= null){
                        this.attachments = result.attachmentList;
                    }
                })
        },
        deleteFile: function(id) {
            this.deleteParam.id = id;
        },
        onYesAttachment: function(id) {
            this.showHRDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    this.showHRDetOverlay = false;
                    console.log(error);
                });
        },
        onReviewPeriodChange() {
            if(this.reviewPeriodEnum == 1) {
                this.reviewPeriod = (this.reviewPeriod).toString().length==4 ? this.reviewPeriod+"-07-11" : this.reviewPeriod;
                this.reviewPeriod = this.getMonthYear(new Date(this.reviewPeriod));
            } else {
                this.reviewPeriod = (this.reviewPeriod).toString().length==7 ? this.reviewPeriod+"-11" : this.reviewPeriod;
                this.reviewPeriod = new Date(this.reviewPeriod).getFullYear();
            }
            this.$nextTick(() => {
                this.viewEmployeePerformance();
            })
        },
        empAttendeesPercentage(data) {
            let totalCount = data.totalPresent + data.totalFirstHalfLeave + data.totalSecondHalfLeave + data.totalFullDayLeave;
            let empTotalPresent = data.totalPresent;
            let empTotalHalfDayLeave = data.totalFirstHalfLeave + data.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((empTotalPresent+(empTotalHalfDayLeave/2))/totalCount) * 100).toFixed(1) : 0);
        },
        // openEmploymentHistoryPopup:function(){
        //     this.employeeId = this.humanResourceDetails.empId;
        //     this.jobPositionChangePopupKey++;
        //     this.$nextTick(() => {
        //         this.$bvModal.show('jobPositionChangePopup');
        //     });
        // },
        viewItemDetails: function(data) {
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.payrollDetailPopupRef.getDetails(this.payroll.id, 'payroll');
            })
        },
        // moneyControlInputEHS: function(value) {
        //     this.employmentHistory.salary = Math.abs(value);
        // },
        moneyControlInputHRS: function(value) {
            this.humanResourceDetails.salary = Math.abs(value);
        },

        moneyControlInputHSL: function(value) {
            this.humanResourceDetails.hourlySalary = Math.abs(value);
        },
        updateShowDetail: function(value) {
            this.elements.showDetail = value;
        },
        setDisplayControls() {
            this.displayControl.roleResp = true;
            this.displayControl.techSkill = true;
            this.displayControl.accountInfo = true;
            this.displayControl.empPerformance = true;
            this.displayControl.empLeave = true;
            this.displayControl.empDoc = true;

            console.log(this.customDisplayControlLookup)
            this.customDisplayControlLookup.forEach((data) => {
                if (data.value == "roleResp") {
                    this.displayControl.roleResp = false;
                }
                else if (data.value == "techSkill") {
                    this.displayControl.techSkill = false;
                }
                else if (data.value == "accountInfo") {
                    this.displayControl.accountInfo = false;
                }
                else if (data.value == "empPerformance") {
                    this.displayControl.empPerformance = false;
                }
                else if (data.value == "empLeave") {
                    this.displayControl.empLeave = false;
                }
                else if (data.value == "empDoc") {
                    this.displayControl.empDoc = false;
                }
            })
        }
    }

}
</script>