<template>
	<div>
		
		<div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Email Account Settings</h4>
                        </div>
                        <div class="col-sm-8 col-lg-4 my-auto">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 my-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>New Email Account</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div class="row mb-2">
                                                <div class="col-6">
                                                    <div class="form-group">

                                                        <label for="fromName">Name</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.fromName.$error }">

                                                        <input type="text" class="form-control" v-model="emailAccountSetting.fromName" id="fromName" :class="{ 'is-invalid': $v.emailAccountSetting.fromName.$error }" placeholder="Enter Name">
                                                        
                                                        <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.emailAccountSetting.fromName.$error && !$v.emailAccountSetting.fromName.required" class="text-danger">Please enter Name</label>
                                                        <label v-if="$v.emailAccountSetting.fromName.$error && !$v.emailAccountSetting.fromName.maxLength" class="text-danger">Name must not exceed {{$v.emailAccountSetting.fromName.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="fromEmail">Email Address</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.fromEmail.$error }">
                                                        <input type="text" class="form-control" v-model="emailAccountSetting.fromEmail" id="fromEmail" placeholder="Enter email address">
                                                        
                                                        <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.emailAccountSetting.fromEmail.$error && !$v.emailAccountSetting.fromEmail.required" class="text-danger">Please enter email address</label>
                                                        <label v-if="$v.emailAccountSetting.fromEmail.$error && !$v.emailAccountSetting.fromEmail.email" class="text-danger">Please enter valied email address</label>
                                                        <label v-if="$v.emailAccountSetting.fromEmail.$error && !$v.emailAccountSetting.fromEmail.maxLength" class="text-danger">Email address must not exceed {{$v.emailAccountSetting.fromEmail.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="outgoingMailServer">Incoming Mail Server(POP)</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.incomingMailServer.$error }">

                                                        <input type="text" class="form-control" v-model="emailAccountSetting.incomingMailServer" id="incomingMailServer" placeholder="Enter SMTP">
                                                        
                                                        <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.emailAccountSetting.incomingMailServer.$error && !$v.emailAccountSetting.incomingMailServer.required" class="text-danger">Please enter SMTP</label>
                                                        <label v-if="$v.emailAccountSetting.incomingMailServer.$error && !$v.emailAccountSetting.incomingMailServer.maxLength" class="text-danger">SMTP must not exceed {{$v.emailAccountSetting.incomingMailServer.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="port">Incoming Port</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.incomingPort.$error }">
                                                        <input type="number" class="form-control" v-model="emailAccountSetting.incomingPort" id="incomingPort" placeholder="Enter incomingPort">
                                                        <label v-if="$v.emailAccountSetting.incomingPort.$error && !$v.emailAccountSetting.incomingPort.required" class="text-danger">Please enter Incoming Port</label>
                                                        <label v-if="$v.emailAccountSetting.incomingPort.$error && !$v.emailAccountSetting.incomingPort.maxLength" class="text-danger">Incoming Port must not exceed {{$v.emailAccountSetting.incomingPort.$params.maxLength.max}} letters.</label>
                                                        <div class="required-icon"></div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="outgoingMailServer">Outgoing Mail Server(SMTP)</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.outgoingMailServer.$error }">

                                                        <input type="text" class="form-control" v-model="emailAccountSetting.outgoingMailServer" id="outgoingMailServer" placeholder="Enter SMTP">
                                                        
                                                        <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.emailAccountSetting.outgoingMailServer.$error && !$v.emailAccountSetting.outgoingMailServer.required" class="text-danger">Please enter SMTP</label>
                                                        <label v-if="$v.emailAccountSetting.outgoingMailServer.$error && !$v.emailAccountSetting.outgoingMailServer.maxLength" class="text-danger">SMTP must not exceed {{$v.emailAccountSetting.outgoingMailServer.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <label for="port">Port</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.port.$error }">
                                                        <input type="number" class="form-control" v-model="emailAccountSetting.port" id="port" placeholder="Enter port">
                                                        <label v-if="$v.emailAccountSetting.port.$error && !$v.emailAccountSetting.port.required" class="text-danger">Please enter port</label>
                                                        <label v-if="$v.emailAccountSetting.port.$error && !$v.emailAccountSetting.port.maxLength" class="text-danger">Port must not exceed {{$v.emailAccountSetting.port.$params.maxLength.max}} letters.</label>
                                                        <div class="required-icon"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="userName">Username</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.userName.$error }">
                                                        <input type="text" class="form-control" v-model="emailAccountSetting.userName" id="userName" placeholder="Enter userName">
                                                        
                                                        <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.emailAccountSetting.userName.$error && !$v.emailAccountSetting.userName.required" class="text-danger">Please enter userName</label>
                                                        <label v-if="$v.emailAccountSetting.userName.$error && !$v.emailAccountSetting.userName.maxLength" class="text-danger">UserName must not exceed {{$v.emailAccountSetting.userName.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="password">Password</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.emailAccountSetting.password.$error }">
                                                        <input type="password" class="form-control" v-model="emailAccountSetting.password" id="password" placeholder="Enter password">
                                                        
                                                        <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.emailAccountSetting.password.$error && !$v.emailAccountSetting.password.required" class="text-danger">Please enter password</label>
                                                        <label v-if="$v.emailAccountSetting.password.$error && !$v.emailAccountSetting.password.maxLength" class="text-danger">Password must not exceed {{$v.emailAccountSetting.password.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>

                                            </div>  
                                            <div class="row mb-2">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <input type="checkbox" id="isDefault" v-model="emailAccountSetting.isDefault">
                                                        <label class="p-3" for="checkbox">Primary Account</label>
                                                    </div>
                                                </div>
                                            </div>                                          
                                            <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="emailAccountSettingList" @row-selected="onRowSelected" :fields="emailAccountSettingFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(isDefault)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" disabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteEmail(data.item.id)" data-toggle="modal" data-target="#deleteEmail"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="emailAccountSetting">
                                    <div class="text-center">Not found</div>
                                </template>
                                <template v-slot:emptyfiltered="emailAccountSetting">
                                    <div class="text-center">{{ emailAccountSetting.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="emailAccountSettingList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail && !elements.visible">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ emailAccountSettingDetail.emailAccountSettingId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateEmail" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="fromEmail">Name</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.fromName">
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="fromEmail">Email Address</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.fromEmail">
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Primary Account</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="emailAccountSettingDetail.isDefault" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="userName">Username</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.userName">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="password">Password</label>
                                                    <input type="password" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.password">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="outgoingMailServer">Mail Server</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.outgoingMailServer">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="port">Port</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.port">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="outgoingMailServer">Incoming Mail Server</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.incomingMailServer">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="port">Port</label>
                                                    <input type="text" class="form-control" :disabled="!elements.editMode" v-model="emailAccountSettingDetail.incomingPort">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="createdDt">Created Date</label>
                                                    <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(emailAccountSettingDetail.createdDt)">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="lastUpdatedDt">Updated Date</label>
                                                    <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(emailAccountSettingDetail.lastUpdatedDt)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
        <Dialog id="deleteEmail" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete email?" />
	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            emailAccountSetting: {
                emailAccountSettingId:'',
                userId: localStorage.getItem("userId"),     
                fromName: '',
                fromEmail: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'email_account_setting',
                userName:'',
                port:'',
                incomingPort:'',
                password: '',
                outgoingMailServer: '',
                incomingMailServer: '',
                isDefault: false,
                createdDt: '',
                lastUpdatedDt: '',
            },
            emailAccountSettingDetail: {
                id: '',
                emailAccountSettingId: '',
                userId: localStorage.getItem("userId"),
                fromName: '',
                fromEmail: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'email_account_setting',
                userName:'',
                port:'',
                incomingPort:'',
                password: '',
                outgoingMailServer: '',
                incomingMailServer: '',
                isDefault: false,
                createdDt: '',
                lastUpdatedDt: '',
            },
            emailAccountSettingList: [],

            emailAccountSettingFields: [
                { key: 'emailAccountSettingId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'fromName', label: 'Name ', sortable: true, sortDirection: 'desc' },
                { key: 'fromEmail', label: 'Email', sortable: true, sortDirection: 'desc' },
                { key: 'outgoingMailServer', label: 'SMTP Server', sortable: true, sortDirection: 'desc' },
                { key: 'port', label: 'Port', sortable: true, sortDirection: 'desc' },
                { key: 'userName', label: 'User Name', sortable: true, sortDirection: 'desc' },
                { key: 'isDefault', label: 'Is Default', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'emailAccountSettingId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'fromName', label: 'Name ', sortable: true, sortDirection: 'desc' },
                { key: 'fromEmail', label: 'Email', sortable: true, sortDirection: 'desc' },
                { key: 'outgoingMailServer', label: 'SMTP Server', sortable: true, sortDirection: 'desc' },
                { key: 'port', label: 'port', sortable: true, sortDirection: 'desc' },
                { key: 'userName', label: 'Username', sortable: true, sortDirection: 'desc' },
                { key: 'isDefault', label: 'Is Default', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getEmailAccountSettings();
        // this.lookupData();
    },
    validations: {
        emailAccountSetting: {
            fromName: {
                required,
                maxLength: maxLength(100)
            },
            fromEmail: {
                required,
                email,
                maxLength: maxLength(100)
            },
            outgoingMailServer: {
                required,
                maxLength: maxLength(100)
            },
            port: {
                required,
                maxLength: maxLength(100)
            },
            userName: {
                required,
                maxLength: maxLength(100)
            },
            password: {
                required,
                maxLength: maxLength(255)
            },
            incomingMailServer: {
                required,
                maxLength: maxLength(100)
            },
            incomingPort: {
                required,
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
    	
        getEmailAccountSettings: function() {
        	let data = {
                vhextAccountId: this.emailAccountSetting.vhextAccountId,
                projectId: this.emailAccountSetting.projectId  ,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/email/emailaccountsetting/list',data)
                .then((response) => {
                    let result = response.data;
                    this.emailAccountSettingList = result.emailAccountSettingList;
                    this.totalRows = this.emailAccountSettingList.length;
                    console.log(result);
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            this.$v.emailAccountSetting.$touch();
            if (!this.$v.emailAccountSetting.$invalid) {
                // if (this.elements.editMode) {
                //     this.updateEmail();
                // } else {
                    this.saveEmail();
                // }
            }
        },
        saveEmail: function() {
        	console.log(this.emailAccountSetting);
            axios.post(this.$store.getters.getAPIBasePath + '/email/emailaccountsetting/add', this.emailAccountSetting)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmailAccountSettings();
                    }
                })
        },
        updateEmail: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/email/emailaccountsetting/update', this.emailAccountSetting)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmailAccountSettings();
                    }
                })
        },
        deleteEmail: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/email/emailaccountsetting/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmailAccountSettings();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.emailAccountSetting.id = '';
            this.emailAccountSetting.emailAccountSettingId = '';
            this.emailAccountSetting.fromName = '';
            this.emailAccountSetting.fromEmail = '';
            this.emailAccountSetting.userName = '';
            this.emailAccountSetting.port = '';
            this.emailAccountSetting.incomingPort = '';
            this.emailAccountSetting.password = '';
            this.emailAccountSetting.outgoingMailServer = '';
            this.emailAccountSetting.incomingMailServer = '';
            this.emailAccountSetting.isDefault = false;
            this.emailAccountSetting.createdDt = '';
            this.emailAccountSetting.lastUpdatedDt = '';
            this.$v.emailAccountSetting.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.showDetail = true;
            this.elements.editMode = true;
            
            this.emailAccountSettingDetail.id = data.id;
            this.emailAccountSettingDetail.emailAccountSettingId = data.emailAccountSettingId;
            this.emailAccountSettingDetail.userId = data.userId;
            this.emailAccountSettingDetail.fromName = data.fromName;
            this.emailAccountSettingDetail.fromEmail = data.fromEmail;
            this.emailAccountSettingDetail.vhextAccountId = data.vhextAccountId;
            this.emailAccountSettingDetail.projectId = data.projectId;
            this.emailAccountSettingDetail.userName = data.userName;
            this.emailAccountSettingDetail.port = data.port;
            this.emailAccountSettingDetail.incomingPort = data.incomingPort;
            this.emailAccountSettingDetail.password = data.password;
            this.emailAccountSettingDetail.outgoingMailServer = data.outgoingMailServer;
            this.emailAccountSettingDetail.incomingMailServer = data.incomingMailServer;
            this.emailAccountSettingDetail.isDefault = data.isDefault;
            this.emailAccountSettingDetail.createdDt = data.createdDt;
            this.emailAccountSettingDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.visible = false;
                this.elements.editMode = false;
                this.elements.showDetail = true;
                this.emailAccountSettingDetail.id = data.id;
                this.emailAccountSettingDetail.emailAccountSettingId = data.emailAccountSettingId;
                this.emailAccountSettingDetail.userId = data.userId;
                this.emailAccountSettingDetail.fromName = data.fromName;
                this.emailAccountSettingDetail.fromEmail = data.fromEmail;
                this.emailAccountSettingDetail.vhextAccountId = data.vhextAccountId;
                this.emailAccountSettingDetail.projectId = data.projectId;
                this.emailAccountSettingDetail.userName = data.userName;
                this.emailAccountSettingDetail.port = data.port;
                this.emailAccountSettingDetail.incomingPort = data.incomingPort;
                this.emailAccountSettingDetail.password = data.password;
                this.emailAccountSettingDetail.outgoingMailServer = data.outgoingMailServer;
                this.emailAccountSettingDetail.incomingMailServer = data.incomingMailServer;
                this.emailAccountSettingDetail.isDefault = data.isDefault;
                this.emailAccountSettingDetail.createdDt = data.createdDt;
                this.emailAccountSettingDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        cancelEditMode() {
            this.elements.editMode = false;
            let data = this.emailAccountSettingList.find((item) => item.id == this.emailAccountSettingDetail.id);
            if (data) {
                this.emailAccountSettingDetail.id = data.id;
                this.emailAccountSettingDetail.emailAccountSettingId = data.emailAccountSettingId;
                this.emailAccountSettingDetail.userId = data.userId;
                this.emailAccountSettingDetail.fromName = data.fromName;
                this.emailAccountSettingDetail.fromEmail = data.fromEmail;
                this.emailAccountSettingDetail.vhextAccountId = data.vhextAccountId;
                this.emailAccountSettingDetail.projectId = data.projectId;
                this.emailAccountSettingDetail.userName = data.userName;
                this.emailAccountSettingDetail.port = data.port;
                this.emailAccountSettingDetail.incomingPort = data.incomingPort;
                this.emailAccountSettingDetail.password = data.password;
                this.emailAccountSettingDetail.outgoingMailServer = data.outgoingMailServer;
                this.emailAccountSettingDetail.incomingMailServer = data.incomingMailServer;
                this.emailAccountSettingDetail.isDefault = data.isDefault;
                this.emailAccountSettingDetail.createdDt = data.createdDt;
                this.emailAccountSettingDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        }
    }
}
</script>