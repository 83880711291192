<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="planBacklogPopupRef" size="xl" modal-class="" header-class="py-3" footer-class="py-3" title="Plan Backlogs" ok-only>
            <PlanBacklogsComponent />
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button size="sm" variant="outline-primary" @click.prevent="cancel()" class="float-right ml-1">
                        Close
                    </b-button>
                    <!-- <b-button size="sm" variant="outline-primary" @click.prevent="cancel();selectedEffortEstimationList=[];" class="float-right ml-1">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" @click="setEffortEstimationList" class="float-right" >Ok</b-button> -->
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import PlanBacklogsComponent from '@/components/common/PlanBacklogsComponent.vue';
export default {
    props: {
        id: { type: String, default: 'planBacklogPopup'},
    },
    components: {
        PlanBacklogsComponent,
    },
    data() {
        return {
        }
    },
    mounted() {},
    methods: {
        closeDialog() {
            // this.selectedEffortEstId = '';
            this.$emit('closeDialog');
        },
    },
}
</script>