<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Feedback Form</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 pr-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals != null && ermReviewApprovals.length != 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description != null && erm.description.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Meeting Agenda</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">{{erm.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="riskPlanning != null && riskPlanning.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Feedback Form</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="riskPlanning" :fields="riskPlanningFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(description)="data">
                                                    <a v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.description)"></a>
                                                </template>
                                                <template v-slot:empty="erm">
                                                    <div class="text-center">No Feedback Form Found</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
<!--         <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
 -->        <Dialog id="confirmUpdateStatus" :onYes="updateStatus" title="Update Status" message="Are you sure to update the status?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
// import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
export default {
    name: 'app',
    components: {
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
        // HTMLDetailViewPopup,
    },

    data() {
        return {

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],
            isBusy: true,

            riskPlanning: [],
            riskPlanningFields: [
                { key: 'feedbackFormId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection:'desc' },
                { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Status', sortable: true, sortDirection:'desc' },
                { key: 'clientId', label: 'Client', sortable: true, sortDirection: 'desc' },  
                { key: 'actions', label: 'Actions' }
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        }
    },

    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.entityMaster = 'feedback_form_master';
            this.entity = 'feedback_form';

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getFeedbackForm(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getFeedbackForm(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        getFeedbackForm: function(ermId) {
            let data = {
                entityRecordMasterId: ermId,
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackform/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.feedbackForms = result.feedbackFormList;
                })
        },
        getReviewStatusLookup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'review'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/review/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    console.log(this.review.isReviewed);
                    this.reviewStatus = result.statusLookup;
                    if (this.review.isReviewed == 1) {
                        this.statuss = this.filterReviewApproval(false);
                    } else if (this.review.isReviewed == 2) {
                        this.statuss = this.filterReviewApproval(true);
                    }
                })
        },
        updateStatus: function() {

            if (this.review.statusId == null || this.review.statusId == '') {
                iziToast.info({
                    message: 'Please select status.',
                    position: 'topRight'
                });
                return;
            }
            console.log(this.review);
            axios.post(this.$store.getters.getAPIBasePath + '/review/update/status', this.review)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    if (result.status == 'ALREADY_REPORTED') {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                        return;
                    }
                    if (this.review.isReviewed == 1) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Reviewed Successfully.',
                            position: 'topRight'
                        });
                    } else if (this.review.isReviewed == 2) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Approved Successfully.',
                            position: 'topRight'
                        });
                    }
                    this.entityRecordMasterCollection(this.ermId);
                })
        },
        updateStatusValue: function(statusId, id, isApproval) {
            this.review.statusId = statusId;
            this.review.id = id;
            this.review.isReviewed = (isApproval ? 2 : 1);
        },
        filterReviewApproval(value) {
            let name = '';
            if (value) {
                name = 'Approved';
            } else {
                name = 'Reviewed';
            }
            return this.reviewStatus.filter(function(data, key) {
                if (data.value == name || data.value == 'Reject') {
                    return data;
                }
            });
        },

        print: function() {
            this.$htmlToPaper('printMe');
        },
        // htmlDetailView: function(data) {
        //     this.htmlData = data;
        //     console.log(data);
        // },


    }
}
</script>