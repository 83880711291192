<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Asset</h4>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-6">
                            <!-- <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-1">
                                    <li class="breadcrumb-item">
                                        <b-button class="btn btn-xs float-right px-2" variant="outline-primary" title="Existing Asset" @click="showDialog=true;" v-b-modal.existingActivityLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing Asset</b-button>
                                    </li>
                                </ol>
                            </nav> -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7 col-xl-7">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Asset</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-3 col-md-3">
                                                        <div class="form-group">
                                                            <label for="asset">Asset</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.asset.title.$error }">
                                                                <input type="text" v-model="asset.title" maxlength="100" class="form-control" id="asset" placeholder="Enter Asset Name">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.asset.title.$error && !$v.asset.title.required" class="text-danger">Please enter Asset Name</label>
                                                            <label v-if="$v.asset.title.$error && !$v.asset.title.maxLength" class="text-danger">Asset Name must not exceed {{$v.asset.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="modelNumber">Model Number</label>
                                                            <input type="text" v-model="asset.modelNumber" maxlength="100" class="form-control" id="modelNumber" placeholder="Enter Model Number">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="brand">Brand</label>
                                                            <input type="text" v-model="asset.brand" maxlength="100" class="form-control" id="brand" placeholder="Enter Brand Name">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="itemCount">Item Count</label>
                                                            <input type="number" v-model="asset.itemCount" maxlength="1000" class="form-control" id="itemCount" placeholder="Enter Item Count">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="assetCategoryId">Category</label>
                                                            <select v-model="asset.assetCategoryId" class="form-control" id="assetCategoryId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in assetCategories" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="categoryLookupTitle" class="setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="assetTypeId">Type</label>
                                                            <select v-model="asset.assetTypeId" class="form-control" id="assetTypeId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in assetTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.assetTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="responsiblePersonId">Responsible Person</label>
                                                            <select v-model="asset.responsiblePersonId" class="form-control" id="responsiblePersonId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="assignedHrId">Assigned To</label>
                                                            <select v-model="asset.assignedHrId" class="form-control" id="assignedHrId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in humanResources" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="itemCount">Item Count</label>
                                                            <input type="number" v-model="asset.itemCount" maxlength="1000" class="form-control" id="itemCount" placeholder="Enter Item Count">
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="locationDet">Asset Location</label>
                                                            <input type="text" v-model="asset.location" maxlength="100" class="form-control" id="location" placeholder="Enter Asset Location">
                                                        </div>
                                                    </div>                                                                                                
                                                    <div class="col-12 col-md-3">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <select v-model="asset.statusId" class="form-control" id="statusId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in assetStatus" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-body p-0 my-2">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.purchaseDetail>
                                                                        <label class="my-1">Purchase Details</label>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="purchaseDetail" accordion="my-accordion2" role="tabpanel">
                                                                    <b-card-body class="p-2 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label for="purchasedById">Purchased By</label>
                                                                                    <select v-model="asset.purchasedById" class="form-control" id="purchasedById">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label for="purchaseDate">Purchase Date</label>
                                                                                    <input v-model="asset.purchaseDate" type="date" id="purchaseDate" class="form-control" placeholder="Purchase Date">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label for="cost">Cost</label>
                                                                                    <VhextMoneyControl id="cost" :value="Math.abs(Number(asset.cost))" @money-input="moneyControlInput" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label for="mfgDate">Manufacturing Date</label>
                                                                                    <input v-model="asset.mfgDate" type="date" id="mfgDate" class="form-control" placeholder="Manufacturing Date">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label for="expDate">Expiry Date</label>
                                                                                    <input v-model="asset.expDate" type="date" id="expDate" class="form-control" placeholder="Expiry Date">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <label>Warranty Period</label>
                                                                                    <b-input-group size="sm" class="mb-2">
                                                                                        <DayMonthYearControl 
                                                                                            :dayVisible="false"
                                                                                            @vmx-value-input="vmxValueInput" 
                                                                                            :monthValue="asset.warrantyMonth"
                                                                                            :yearValue="asset.warrantyYear"
                                                                                            ref="dayMonthYearControl" />
                                                                                    </b-input-group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-1">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="asset.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="asset.description"></editor>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" :diabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
<!--                                     <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div> -->
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label class="d-block pl-1 accordion-title">Assets
                                            </label>
                                        </div>
                                    </div>                                

                                    <div class="float-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="assetTable" v-model="mainTableArray" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="assetList"  @row-selected="onRowSelected" :fields="assetFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>    
                                <template #cell(title)="data">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col">
                                            <!-- <div class="mr-auto">{{ data.value }}</div> -->
                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                            <div class="mr-auto text-muted">
                                                <span>{{ data.item.brand }}</span>
                                                <span v-if="data.item.brand"> - {{ data.item.modelNumber }}</span>
                                            </div>                                            
                                            
                                        </div>
                                    </div>
                                </template>                        

<!--                                 <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template> -->
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(cost)="data">
                                    <div>{{ currencySymbol }}{{data.value}}</div>
                                </template>
                                <template v-slot:cell(description)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deleteAsset(data.item.id)" v-b-modal.deleteAsset><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Assets found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

<!--                             <b-pagination v-if="assetList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-5 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-show="!elements.visible && elements.showDetail" content-class="mt-3">
                                    <b-tab title="Primary Info">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ assetDetail.assetId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateAsset" :diabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="assetDet">Asset</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.assetDetail.title.$error }">
                                                        <input type="text" v-model="assetDetail.title" maxlength="100" class="form-control" id="assetDet" placeholder="Enter Asset Name" :disabled="!elements.editMode">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.assetDetail.title.$error && !$v.assetDetail.title.required" class="text-danger">Please enter Asset Name</label>
                                                    <label v-if="$v.assetDetail.title.$error && !$v.assetDetail.title.maxLength" class="text-danger">Asset Name must not exceed {{$v.assetDetail.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="statusId">Status</label>
                                                    <select v-if="elements.editMode" v-model="assetDetail.statusId" class="form-control" id="statusId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in assetStatus" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input v-else type="text" class="form-control disabled" :value="assetDetail.status" disabled>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="assetDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="brandDet">Brand</label>
                                                    <input type="text" v-model="assetDetail.brand" maxlength="100" class="form-control" id="brandDet" placeholder="Enter Brand Name" :disabled="!elements.editMode">
                                                </div>
                                            </div>                                            
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="modelNumberDet">Model Number</label>
                                                    <input type="text" v-model="assetDetail.modelNumber" maxlength="100" class="form-control" id="modelNumberDet" placeholder="Enter Model Number" :disabled="!elements.editMode">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="assetCategoryIdDet">Category</label>
                                                    <select v-model="assetDetail.assetCategoryId" class="form-control" id="assetCategoryIdDet" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in assetCategories" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <a :title="categoryLookupTitle" class="setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="assetTypeIdDet">Type</label>
                                                    <select v-model="assetDetail.assetTypeId" class="form-control" id="assetTypeIdDet" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in assetTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.assetTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="itemCount">Item Count</label>
                                                    <input type="number" v-model="assetDetail.itemCount" maxlength="1000" class="form-control" id="itemCount" placeholder="Enter Item Count" :disabled="!elements.editMode">
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="locationDet">Asset Location</label>
                                                    <input type="text" v-model="assetDetail.location" maxlength="100" class="form-control" id="locationDet" placeholder="Enter Asset Location" :disabled="!elements.editMode">
                                                </div>
                                            </div>                                            
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="responsiblePersonIdDet">Responsible Person</label>
                                                    <select v-model="assetDetail.responsiblePersonId" class="form-control" id="responsiblePersonIdDet" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="assignedHrIdDet">Assigned To</label>
                                                    <select v-model="assetDetail.assignedHrId" class="form-control" id="assignedHrIdDet" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in humanResources" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 mb-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.descriptionAcc2>
                                                                <label>Description</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="descriptionAcc2" accordion="my-descriptionAcc2" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label>Description</label>
                                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :disabled="!elements.editMode" :toolbar="toolbar" :init="initObj" v-model="assetDetail.description"></editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 mb-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="assetDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="assetDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="assetDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Purchase Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ assetDetail.assetId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.otherInfoEditMode" href="javascript:" @click="elements.otherInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.otherInfoEditMode">
                                                        <button type="submit" @click="updateAsset" :diabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="purchasedByIdDet">Purchased By</label>
                                                    <select v-model="assetDetail.purchasedById" class="form-control" id="purchasedByIdDet" :disabled="!elements.otherInfoEditMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="purchaseDateDet">Purchase Date</label>
                                                    <input v-if="elements.otherInfoEditMode" v-model="assetDetail.purchaseDate" type="date" id="purchaseDateDet" class="form-control" placeholder="Purchase Date">
                                                    <input v-else type="text" class="form-control disabled" :value="assetDetail.purchaseDate | formatDate" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="costDet">Cost</label>
                                                    <VhextMoneyControl id="costDet" :value="Math.abs(Number(assetDetail.cost))" @money-input="moneyControlInputDetail" :disabled="!elements.otherInfoEditMode" />
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="mfgDateDet">Manufacturing Date</label>
                                                    <input v-if="elements.otherInfoEditMode" v-model="assetDetail.mfgDate" type="date" id="mfgDateDet" class="form-control" placeholder="Manufacturing Date">
                                                    <input v-else type="text" class="form-control disabled" :value="assetDetail.mfgDate | formatDate" disabled>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label>Warranty Period</label>
                                                    <b-input-group size="sm" class="mb-2">
                                                        <DayMonthYearControl 
                                                            :dayVisible="false"
                                                            @vmx-value-input="vmxValueInputDet" 
                                                            :monthValue="assetDetail.warrantyMonth"
                                                            :yearValue="assetDetail.warrantyYear"
                                                            :yearDisabled="!elements.otherInfoEditMode"
                                                            :monthDisabled="!elements.otherInfoEditMode"
                                                            ref="dayMonthYearControlDet" />
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="expDateDet">Expiry Date</label>
                                                    <input v-if="elements.otherInfoEditMode" v-model="assetDetail.expDate" type="date" id="expDateDet" class="form-control" placeholder="Expiry Date">
                                                    <input v-else type="text" class="form-control disabled" :value="assetDetail.expDate | formatDate" disabled>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Vendor Info">
                                        <div class="row">
                                            <div class="col-10">
                                            </div>      
                                            <div class="col-md-2 col-sm-2 pl-3 float-right">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.contactEditMode" href="javascript:" @click="elements.contactEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.contactEditMode">
                                                        <button type="submit" @click="saveContact" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="getContacts();elements.contactEditMode = false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Name</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.contactName">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Company</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.company">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.mobile">
                                                </div>
                                            </div>
                                            <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label for="emailId">Email Id</label>
                                                    <input type="text" class="form-control" id="emailId" :disabled="!elements.contactEditMode" v-model="contactDetail.emailId">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="phone">Phone</label>
                                                    <input class="form-control" type="text" id="phone" :disabled="!elements.contactEditMode" v-model="contactDetail.phone">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="phone">Office Phones</label>
                                                    <input class="form-control" type="text" id="officePhone" :disabled="!elements.contactEditMode" v-model="contactDetail.officePhone">
                                                </div>
                                            </div>


                                            <div class="divider"></div>
                                            <!-- <label class="px-3 mb-2" for="phone">Address Info</label> -->
                                            <h6 class="px-2 mb-1">Address Info</h6> 
                                            <div class="card mb-3 w-100">
                                                <div class="card-body p-2">
                                                    <div class="row px-2" >
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group ">
                                                                <label for="title">Building Name</label>
                                                                <input type="text" class="form-control" id="Address1" :disabled="!elements.contactEditMode" v-model="contactDetail.address1" >
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 ">
                                                            <div class="form-group ">
                                                                <label for="title">Street Name</label>
                                                                <input type="text" class="form-control" id="streetName" :disabled="!elements.contactEditMode" v-model="contactDetail.streetName">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2" >
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="city">City</label>
                                                                <input type="text" class="form-control" id="city" :disabled="!elements.contactEditMode" v-model="contactDetail.city">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="State">State</label>
                                                                <input type="text" class="form-control" id="State" :disabled="!elements.contactEditMode" v-model="contactDetail.state">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2" >
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="projectManager">Country</label>
                                                                <select v-if="elements.contactEditMode" v-model="contactDetail.countryId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.contactEditMode" disabled v-model="contactDetail.country" type="text" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group ">
                                                                <label for="title">Pin Code</label>
                                                                <input type="text" class="form-control" id="pincode" :disabled="!elements.contactEditMode" v-model="contactDetail.pincode">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Attachments">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Document>
                                                    <span>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Document
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-Document" accordion="my-accordion" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="row">
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-row">
                                                                <div class="col-11">
                                                                    <div class="form-group">
                                                                        <!-- <label class="float-left invisible">Document Type</label> -->
                                                                        <div class="attachments mb-2">
                                                                            <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="attachmentData.attachments">
                                                                            </b-form-file>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-1">
                                                                    <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right setting-icon action-link">
                                                                        <i class="fa fa-check pr-2"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                            
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>

                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div v-show="attachments.length != 0" class="col-12 px-0">
                                                    <div class="">
                                                        <div>
                                                            <p class="my-1 border p-2" v-for="(attachment, index) in attachments">
                                                                <span class="pr-3">{{index+1}}</span>
                                                                <!-- <span class="pr-3">{{attachment.documentType}}</span> -->
                                                                <i class="fa fa-file-image-o pr-2 ml-3 icon-doc" aria-hidden="true"></i>
                                                                <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                
                                                                <a href="javascript:" class="float-right button-remove px-2 attachments-remove" title="Delete Attachment" @click="deleteFile(attachment.id)" data-toggle="modal" v-b-modal.deleteAttachment>
                                                                    <i class="fa fa-trash pr-2 action-link"></i>
                                                                </a>

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Ref. Links">
                                        <b-card no-body class="mb-3">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Document>
                                                    <span>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Reference Link
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-Document" accordion="my-accordion" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="attachments mb-3">
                                                        <div class="attachments-list d-inline-block">
                                                            <div class="form-row">
                                                                <div class="col-4">
                                                                    <input type="text" maxlength="100" class="form-control" v-model="attachmentLinkData.fileName" id="title" placeholder="Title">
                                                                </div>
                                                                <div class="col-8">
                                                                    <input type="text" maxlength="100" class="form-control" v-model="attachmentLinkData.attachmentPath" id="title" placeholder="Enter a Document Link">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <a href="javascript:" title="Save Link" @click="addDocumentLink" class="float-right  p-2 attachments-remove action-link"><i class="fa fa-check pr-2"></i></a>
                                                    </div>                                            
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div class="row">
                                            <div class="col-lg-12 col-md-12">
                                                <div v-if="attachmentLinks.length>0" class="px-2">
                                                    <p class="my-1 border p-2" v-for="(attachment, index) in attachmentLinks">
                                                        <span class="pr-3">{{index+1}}</span>
                                                        <i class="fa fa-external-link pr-2 ml-2 icon-doc" aria-hidden="true"></i>
                                                        <a :href="attachment.attachmentPath" target="_blank">
                                                            {{ attachment.fileName ? attachment.fileName : attachment.attachmentPath }}
                                                        </a>
                                                        
                                                        <a href="javascript:" class="float-right button-remove px-2 attachments-remove" title="Delete Attachment" @click="deleteFile(attachment.id)" data-toggle="modal" v-b-modal.deleteAttachment>
                                                            <i class="fa fa-trash pr-2 action-link"></i>
                                                        </a>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AssetDetailPopup v-if="showDialog" id="assetDetailPopup" ref="assetDetailPopupRef" :attachmentData="attachmentPopupData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="showDialog = false" />
        <DeleteComponent id="deleteAsset" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Asset ?" />
        <DeleteComponent id="deleteAttachment" :onYes="onYesAttachment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Document Attachment?" />
        <CategoryLookup ref="categoryLookupModal" :entity="categoryEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <TypeLookup id="assetTypeModal" ref="typeLookupModal" :entity="typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <!-- <TypeLookup id="documentTypeModal" ref="documentTypeModal"  entity="document_type" typeLookupTitle="Document Type Setting" @refresh-lookup="lookupData(),getAttachment()" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue'
import AssetDetailPopup from '@/components/popup/itemdetail/AssetDetailPopup.vue';
import Editor from '@tinymce/tinymce-vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
import { required, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        AssetDetailPopup,
        CategoryLookup,
        Editor,
        TypeLookup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                otherInfoEditMode: false,
                contactEditMode: false,
                visible:false
            },
            mainTableArray: [],
            isBusy: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            entity: 'asset',
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            typeEntity: 'asset_type',
            typeLookupTitle: 'Asset Type Settings',
            categoryEntity: 'asset_category',
            categoryLookupTitle: 'Asset Category Settings',

            detailViewData: {},
            attachmentPopupData: {},
            referencePopupId: 0,

            asset: {
                id: '',
                assetId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                assetTypeId: '',
                assetCategoryId: '',
                title: '',
                brand: '',
                modelNumber: '',
                itemCount: 1,
                description: '',
                cost: '',
                purchasedById: '',
                purchaseDate: '',
                mfgDate: '',
                expDate: '',
                warrantyYear: '',
                warrantyMonth: '',
                responsiblePersonId: '',
                assignedHrId: '',
                location: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            assetDetail: {
                id: '',
                assetId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                assetTypeId: '',
                assetType: '',
                assetCategoryId: '',
                assetCategory: '',
                title: '',
                brand: '',
                modelNumber: '',
                itemCount: '',
                description: '',
                cost: '',
                purchasedById: '',
                purchasedBy: '',
                purchaseDate: '',
                mfgDate: '',
                expDate: '',
                warrantyYear: '',
                warrantyMonth: '',
                responsiblePersonId: '',
                responsiblePerson: '',
                assignedHrId: '',
                assignedHr: '',
                location: '',
                statusId: '',
                status: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: ''
            },

            attachmentLinkData: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntityId: '',
                refRecordId: '',
                fileName: '',
                attachmentPath: '',
                typeCode: 'DSL',
                attachedById: '',
            },

            contactDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntity: '',
                contactId: '',
                userId: '',
                contactName: '',
                firstName: '',
                middleName: '',
                lastName: '',
                profession: '',
                company: '',
                position: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                address2: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                timeZone: '',
                website: '',
                skypeId: '',
                facebookId: '',
                linkedinId: '',
                twitterId: '',
                gender: null,
                dateOfBirth: '',
                lastUpdatedDt: '',
                active: '',
                photo:'',
            },

            attachmentData: {
                attachments: [],
            },
            notEnabled: true,
            assetTypes: [],
            assetStatus: [],
            assetCategories: [],
            users: [],
            humanResources: [],
            countries: [],
            attachmentList: [],

            assetList: [],
            assetFields: [
                // { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'assetId', label: 'Asset ID', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Asset', sortable: true, sortDirection: 'desc' },
                // { key: 'brand', label: 'Brand', sortable: true, sortDirection: 'desc' },
                // { key: 'modelNumber', label: 'Model Number', sortable: true, sortDirection: 'desc' },
                { key: 'assetCategory', label: 'Asset Category', sortable: true, sortDirection: 'desc' },
                { key: 'assetType', label: 'Asset Type', sortable: true, sortDirection: 'desc' },
                { key: 'itemCount', label: 'Item Count', sortable: true,tdClass: 'text-center',sortDirection: 'desc' },
                { key: 'status', label: 'Asset Status', sortable: true,tdClass: 'text-center',sortDirection: 'desc' },
                // { key: 'cost', label: 'Cost', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        attachments() {
            return this.attachmentList.filter((data) => data.typeCode !== 'DSL');
        },
        attachmentLinks() {
            return this.attachmentList.filter((data) => data.typeCode == 'DSL');
        }
    },
    mounted() {
        this.getAssets();
        this.lookupData();
    },
    validations: {
        asset: {
            title: {
                required,
                maxLength: maxLength(100)
            },
        },
        assetDetail: {
            title: {
                required,
                maxLength: maxLength(200)
            },                 
        },
    },
    methods: {
        getAssets: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/asset/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.assetList) {
                        this.assetList = result.assetList;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.assetDetail.id, this.mainTableArray, 'assetTable');
                        })
                        this.totalRows = this.assetList.length;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/asset/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.assetTypes = result.customTypeLookup;
                    this.assetCategories = result.customCategoryLookup;
                    this.assetStatus = result.statusLookup;
                    this.users = result.userLookup;
                    this.humanResources = result.humanResourceLookup
                    this.countries = result.countryLookup;
                })

        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        saveData: function() {
            this.$v.asset.$touch();
            if (!this.$v.asset.$invalid) {
                this.saveAsset();
            }
        },
        saveAsset: function() {
            this.asset.warrantyYear = this.$refs.dayMonthYearControl.getYears();
            this.asset.warrantyMonth = this.$refs.dayMonthYearControl.getMonths();
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/asset/add', this.asset)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getAssets();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateAsset: function() {
            this.showDetOverlay = true;
            this.assetDetail.warrantyYear = this.$refs.dayMonthYearControlDet.getYears();
            this.assetDetail.warrantyMonth = this.$refs.dayMonthYearControlDet.getMonths();
            this.$v.assetDetail.$touch();
            if (!this.$v.assetDetail.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/company/asset/update', this.assetDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        this.elements.otherInfoEditMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getAssets();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewAsset: function(id) {
            this.showDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/company/asset/view/' + id)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    let assetDetail = result.assetView;
                    if (assetDetail) {                        
                        this.elements.showDetail = true;
                        this.assetDetail.id = assetDetail.id;
                        this.assetDetail.assetId = assetDetail.assetId;
                        this.assetDetail.vhextAccountId = assetDetail.vhextAccountId;
                        this.assetDetail.assetTypeId = assetDetail.assetTypeId;
                        this.assetDetail.assetType = assetDetail.assetType;
                        this.assetDetail.assetCategoryId = assetDetail.assetCategoryId;
                        this.assetDetail.title = assetDetail.title;
                        this.assetDetail.modelNumber = assetDetail.modelNumber;
                        this.assetDetail.brand = assetDetail.brand;
                        this.assetDetail.itemCount = assetDetail.itemCount;
                        this.assetDetail.description = assetDetail.description;
                        this.assetDetail.cost = assetDetail.cost;
                        this.assetDetail.purchasedById = assetDetail.purchasedById;
                        this.assetDetail.purchasedBy = assetDetail.purchasedBy;
                        this.assetDetail.purchaseDate = assetDetail.purchaseDate;
                        this.assetDetail.mfgDate = assetDetail.mfgDate;
                        this.assetDetail.expDate = assetDetail.expDate;
                        this.assetDetail.warrantyYear = assetDetail.warrantyYear;
                        this.assetDetail.warrantyMonth = assetDetail.warrantyMonth;
                        this.assetDetail.responsiblePersonId = assetDetail.responsiblePersonId;
                        this.assetDetail.responsiblePerson = assetDetail.responsiblePerson;
                        this.assetDetail.assignedHrId = assetDetail.assignedHrId;
                        this.assetDetail.assignedHr = assetDetail.assignedHrId;
                        this.assetDetail.location = assetDetail.location;
                        this.assetDetail.statusId = assetDetail.statusId;
                        this.assetDetail.status = assetDetail.status;
                        this.assetDetail.active = assetDetail.active;
                        this.assetDetail.createdById = assetDetail.createdById;
                        this.assetDetail.createdBy = assetDetail.createdBy;
                        this.assetDetail.createdDt = assetDetail.createdDt;
                        this.assetDetail.lastUpdatedDt = assetDetail.lastUpdatedDt;
                        console.log(this.$refs.dayMonthYearControlDet);
                        this.$refs.dayMonthYearControlDet.setValueInputData(assetDetail.warrantyYear, assetDetail.warrantyMonth, '');
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
                this.elements.editMode = false;
                this.elements.otherInfoEditMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewAsset(this.assetDetail.id);
        },
        deleteAsset: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/company/asset/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAssets();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addDocumentLink: function() {
            this.attachmentLinkData.vhextAccountId = this.vhextAccountId;
            this.attachmentLinkData.attachedById = this.userId;
            this.attachmentLinkData.refEntity = this.entity;
            this.attachmentLinkData.refRecordId = this.assetDetail.id;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/add', this.attachmentLinkData)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        saveAttachment: function() {
            if (this.attachmentData.attachments) {
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
                return;
            } else {
                let formData = new FormData();    
                formData.append('files', this.attachmentData.attachments);
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('attachedById', this.userId);
                formData.append('refEntity', this.entity);
                formData.append('refRecordId', this.assetDetail.id);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.attachmentData.attachments = [];
                            this.getAttachment();
                        }
                        console.log('Attachment uploaded');
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        console.log('Error Attachment uploaded');
                    });
            }
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.assetDetail.id,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if(result!= null){
                        this.attachmentList = result.attachmentList;
                    }
                })
        },
        deleteFile: function(id) {
            this.deleteParam.id = id;
        },
        onYesAttachment: function(id) {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.asset.id = '';
            this.asset.assetId = '';
            this.asset.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.asset.assetTypeId = '';
            this.asset.assetCategoryId = '';
            this.asset.title = '';
            this.asset.modelNumber = '';
            this.asset.brand = '';
            this.asset.itemCount = '';
            this.asset.description = '';
            this.asset.cost = '';
            this.asset.purchasedById = '';
            this.asset.purchaseDate = '';
            this.asset.mfgDate = '';
            this.asset.expDate = '';
            this.asset.warrantyYear = '';
            this.asset.warrantyMonth = '';
            this.asset.responsiblePersonId = '';
            this.asset.assignedHrId = '';
            this.asset.assignedHr = '';
            this.asset.location = '';
            this.asset.statusId = '';
            this.asset.active = true;
            this.asset.createdById = localStorage.getItem("userId");
            this.$v.asset.$reset();
            console.log(this.$refs.dayMonthYearControlDet);
            this.$refs.dayMonthYearControlDet.clearValueInputData();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.elements.editMode = false;
                this.elements.otherInfoEditMode = false;
                this.assetDetail.id = data.id;
                this.assetDetail.assetId = data.assetId;
                this.assetDetail.vhextAccountId = data.vhextAccountId;
                this.assetDetail.assetTypeId = data.assetTypeId;
                this.assetDetail.assetType = data.assetType;
                this.assetDetail.assetCategoryId = data.assetCategoryId;
                this.assetDetail.title = data.title;
                this.assetDetail.modelNumber = data.modelNumber;
                this.assetDetail.brand = data.brand;
                this.assetDetail.itemCount = data.itemCount;
                this.assetDetail.description = data.description;
                this.assetDetail.cost = data.cost;
                this.assetDetail.purchasedById = data.purchasedById;
                this.assetDetail.purchasedBy = data.purchasedBy;
                this.assetDetail.purchaseDate = data.purchaseDate;
                this.assetDetail.mfgDate = data.mfgDate;
                this.assetDetail.expDate = data.expDate;
                this.assetDetail.warrantyYear = data.warrantyYear;
                this.assetDetail.warrantyMonth = data.warrantyMonth;
                this.assetDetail.responsiblePersonId = data.responsiblePersonId;
                this.assetDetail.responsiblePerson = data.responsiblePerson;
                this.assetDetail.assignedHrId = data.assignedHrId;
                this.assetDetail.assignedHr = data.assignedHr;
                this.assetDetail.location = data.location;
                this.assetDetail.statusId = data.statusId;
                this.assetDetail.status = data.status;
                this.assetDetail.active = data.active;
                this.assetDetail.createdById = data.createdById;
                this.assetDetail.createdBy = data.createdBy;
                this.assetDetail.createdDt = data.createdDt;
                this.assetDetail.lastUpdatedDt = data.lastUpdatedDt;
                console.log(this.$refs.dayMonthYearControlDet);
                this.$refs.dayMonthYearControlDet.setValueInputData(data.warrantyYear, data.warrantyMonth, '');
                this.getAttachment();
                this.getContacts();
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.asset.entity = this.entity;
                this.asset.id = item.id;
                this.asset.active = 0;

                this.updateActivationState();

            } else {
                this.asset.entity = this.entity;
                this.asset.id = item.id;
                this.asset.active = 1;

                this.updateActivationState();
            }
            console.log(this.asset.id, this.asset.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.asset)
                .then((response) => {
                    console.log(this.asset.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.asset.active = true;
                    this.getAssets();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.assetDetail.id,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/view', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.contact) {
                        this.contactDetail = result.contact;
                        console.log(this.contactDetail);
                    }
                    else {
                        this.clearContact();
                    }
                })
        },
        saveContact: function() {
            if (this.contactDetail.refRecordId == this.assetDetail.id) {
                this.updateContact();
            } else {
                this.addContact();
            }
        },
        addContact: function() {
            this.contactDetail.refRecordId = this.assetDetail.id;
            this.contactDetail.refEntity = this.entity;
            console.log(this.contactDetail);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contactDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        updateContact: function() {
            console.log(this.contactDetail);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contactDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        clearContact: function() {
            this.elements.contactEditMode = false;
            this.contactDetail.id = '';
            this.contactDetail.refRecordId = '';
            this.contactDetail.refEntity = '';
            this.contactDetail.contactId = '';
            this.contactDetail.userId = '';
            this.contactDetail.contactName = '';
            this.contactDetail.firstName = '';
            this.contactDetail.middleName = '';
            this.contactDetail.lastName = '';
            this.contactDetail.profession = '';
            this.contactDetail.company = '';
            this.contactDetail.position = '';
            this.contactDetail.phone = '';
            this.contactDetail.officePhone = '';
            this.contactDetail.mobile = '';
            this.contactDetail.alternateMobile = '';
            this.contactDetail.emailId = '';
            this.contactDetail.alternateEmailId = '';
            this.contactDetail.address1 = '';
            this.contactDetail.address2 = '';
            this.contactDetail.streetName = '';
            this.contactDetail.city = '';
            this.contactDetail.state = '';
            this.contactDetail.countryId = '';
            this.contactDetail.pincode = '';
            this.contactDetail.timeZone = '';
            this.contactDetail.website = '';
            this.contactDetail.skypeId = '';
            this.contactDetail.facebookId = '';
            this.contactDetail.linkedinId = '';
            this.contactDetail.twitterId = '';
            this.contactDetail.gender = null;
            this.contactDetail.dateOfBirth = '';
            this.contactDetail.lastUpdatedDt = '';
            this.contactDetail.active = '';
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentPopupData.refEntity = this.entity;
            this.attachmentPopupData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.assetDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        vmxValueInput:function(data){
            this.$refs.dayMonthYearControl.setValueInputData(data.year, data.month, data.day);
        },
        vmxValueInputDet:function(data){
            this.$refs.dayMonthYearControlDet.setValueInputData(data.year, data.month, data.day);
        },
        moneyControlInput: function(value) {
            this.asset.cost = Math.abs(value);
        },
        moneyControlInputDetail: function(value) {
            this.assetDetail.cost = Math.abs(value);
        },
    }
}
</script>
