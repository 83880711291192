<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Action Item List</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right"
                                aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2"
                                                aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a title="Board View" class="action-link pointer"
                                            @click="routeActionItemBoard()">
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click=""><i class="fa fa-share pr-2"
                                                aria-hidden="true"></i>Share</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{ this.projId }} - {{ this.projectName
                                            }}
                                        </h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div class="row">
                    <div class="col-lg-12 col-xl-12">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right"
                                                    label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm"
                                                        :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter"
                                                        class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <b-table class="white-header" show-empty small stacked="md" sort-icon-left
                                        :items="actionItems" :fields="actionItemFields" responsive="sm"
                                        :current-page="currentPage" :per-page="perPage" :filter="filter"
                                        :filterIncludedFields="filterOn" :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                                        @filtered="onFiltered">
                                        <template v-slot:cell(actionItemTitle)="data">
                                            <a href="#" class="action-link" :title="data.value"
                                                @click="viewReference(data.item, data.item.actionItemTitle)">{{
                                                    data.value }}</a>
                                        </template>
                                        <template v-slot:cell(actionItemSource)="data">
                                            <div>{{ getStatusBySource(data.item.actionItemSource) }}</div>
                                        </template>
                                        <template v-slot:cell(actionItemStartDt)="data">
                                            <div v-if="data.value">{{ data.value }}</div>
                                            <div v-else class="text-center">NA</div>
                                        </template>
                                        <template v-slot:cell(actionItemDueDt)="data">
                                            <div v-if="data.value">{{ data.value }}</div>
                                            <div v-else class="text-center">NA</div>
                                        </template>
                                        <template v-slot:cell(isApproval)="data">
                                            <div>
                                                <p v-if="data.value == false">
                                                    <span v-if="data.item.reviewDt">Reviewed</span>
                                                    <span v-else>Review Pending</span>
                                                </p>
                                                <p v-else>
                                                    <span v-if="data.item.reviewDt">Approved</span>
                                                    <span v-else>Approval Pending</span>
                                                </p>
                                            </div>
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <div v-if="data.item.actionItemSource === 'TASK'">
                                                <a type="button" class="btn btn-secondary btn-xs px-2 m-0"
                                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <i class="fa fa-ellipsis-h"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-primary"
                                                    aria-labelledby="dropdownMenuLink">
                                                    <a class="dropdown-item addnew-tassk pb-1 py-1"
                                                        @click="viewReference(data.item, data.item.actionItemTitle)"
                                                        href="javascript:">
                                                        <i class="fa fa-list-alt pr-2" aria-hidden="true"></i>
                                                        Task Details
                                                    </a>
                                                    <a v-if="data.item.actionItemById == userId"
                                                        class="dropdown-item addnew-tassk py-1"
                                                        @click="showTaskEdit(data.item)" href="javascript:">
                                                        <i class="fa fa-pencil pr-2"></i>
                                                        Edit Task
                                                    </a>
                                                    <a class="dropdown-item addnew-tassk pb-1 py-1"
                                                        @click="duplicateTask(data.item)" href="javascript:">
                                                        <i class="fa fa-copy pr-2" aria-hidden="true"></i>
                                                        Duplicate Task
                                                    </a>
                                                    <a v-if="data.item.actionItemById == userId || data.item.actionItemToId == userId"
                                                        class="dropdown-item addnew-tassk my-0 py-1"
                                                        @click="showStatusPopup(data.item, false)" href="javascript:">
                                                        <i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>
                                                        Task Status Update
                                                    </a>
                                                    <a v-if="data.item.actionItemById == userId || data.item.actionItemToId == userId"
                                                        class="dropdown-item addnew-tassk my-0 py-1"
                                                        @click="showTaskFinPopup(data.item)" href="javascript:">
                                                        <i class="fa fa-money pr-2 mt-1 mb-0" aria-hidden="true"></i>
                                                        Task Cost Update
                                                    </a>
                                                    <a v-if="data.item.actionItemById == userId"
                                                        class="dropdown-item py-1"
                                                        @click="deleteTask(data.item.entityRecordId)"
                                                        v-b-modal.deleteTask href="javascript:">
                                                        <i class="fa fa-trash pr-2"></i>
                                                        Delete Task
                                                    </a>
                                                    <a v-if="data.item.actionItemById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'"
                                                        class="dropdown-item addnew-tassk my-0 py-1"
                                                        @click="archiveTask(data.item)" href="javascript:">
                                                        <i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>
                                                        Archive
                                                    </a>
                                                    <a v-if="data.item.actionItemById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'"
                                                        class="dropdown-item addnew-tassk my-0 py-1"
                                                        @click.prevent="memorizeTask(data.item)" href="javascript:">
                                                        <span class="fs-11 pr-1">M<sup>+</sup></span>
                                                        {{ data.item.isMemorized ? 'Remove Task From Memory' : 'Add Task To Memory' }}
                                                    </a>
                                                </div>
                                            </div>
                                            <div v-else-if="data.item.actionItemSource === 'LVE'">
                                                <a type="button" class="btn btn-secondary btn-xs px-2 m-0" @click="viewReference(data.item, data.item.actionItemTitle)">
                                                    <i class="fa fa-ellipsis-h"></i>
                                                </a>
                                            </div>
                                            <div
                                                v-else-if="data.item.actionItemSource == 'COPY' && data.item.status == 'Copy Pending'">
                                                <template v-if="userId != data.item.actionItemById">
                                                    <b-button class="sbtn btn-xs p-1 mr-1" variant="success"
                                                        @click="acceptDocumentCopy(data.item.id, data.item.entityRecordId, 'Copied')"
                                                        title="Accept Document Copy">Accept</b-button>
                                                    <b-button class="sbtn btn-xs p-1" variant="outline-danger"
                                                        @click="acceptDocumentCopy(data.item.id, data.item.entityRecordId, 'Declined')"
                                                        title="Reject Document Copy">Decline</b-button>
                                                </template>
                                                <template v-else-if="userId == data.item.actionItemById">
                                                    <b-button class="sbtn btn-xs p-1" variant="outline-danger"
                                                        @click="acceptDocumentCopy(data.item.id, data.item.entityRecordId, 'Cancelled')"
                                                        title="Cancel Copy">Cancel</b-button>
                                                </template>
                                            </div>
                                            <div v-else-if="data.item.actionItemSource !== 'COPY'">
                                                <router-link v-if="userTypeCode == 'CLI'"
                                                    class="btn btn-secondary btn-xs px-2 m-0" title="Go to page"
                                                    :to="{ name: getClientReportUrl(data.item.entity), params: { ermId: data.item.ermId } }"><i
                                                        class="fa fa-angle-double-right fa-lg"></i></router-link>
                                                <router-link v-else class="btn btn-secondary btn-xs px-2 m-0"
                                                    title="Go to page"
                                                    :to="{ name: getReportUrlName(data.item.ermEntity), params: { ermId: data.item.ermId } }"><i
                                                        class="fa fa-angle-double-right fa-lg"></i></router-link>
                                                <!-- <b-button class="btn-xs px-2 m-0" variant="secondary" @click="" title="Cancel Copy Request"><i class="fa fa-angle-double-right fa-lg"></i></b-button> -->
                                            </div>
                                        </template>
                                        <template v-slot:empty="">
                                            <div class="text-center">No Document found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="">
                                            <div class="text-center">{{ }}</div>
                                        </template>
                                    </b-table>
                                    <span :class="'badge-secondary'"
                                        class="fs-11 badge badge-pill pointer text-center float-left">{{ this.totalRows
                                        }}
                                        Items</span>
                                    <b-pagination v-if="actionItems.length != 0" pills v-model="currentPage"
                                        :total-rows="totalRows" :per-page="perPage" first-number last-number
                                        align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <ERMCopyConfirmation ref="ermCopyConfirmRef" @refreshData="loadData" />
        <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId"
            :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <ReviewApprovalDetailPopup ref="reviewApprovalDetailPopupRef" :referenceDetailId="referencePopupId"
            :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <TaskDetailPopup ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle"
            :refEntity="refPopupEntity" />
        <ShareDetailPopup ref="shareDetailPopupRef" :detailViewData="detailViewData" />
        <MeetingPrepartionDetailPopup ref="meetingPrepartionDetailPopupRef" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="taskData" :projectTypeId="projectTypeId"
            @refresh-task="loadData" />
        <TaskStatusUpdatePopup v-if="showTaskStatusDialog" ref="taskSatatusUpdatePopupRef" :statusUpdate="statusUpdate"
            @updateParent="getTasks(task.entityRecordMasterId)" @closeDialog="showTaskStatusDialog = false" />
        <TaskFinancialPopup :key="taskFinancialPopupKey" ref="taskFinancialPopupRef" id="task-financial-popup"
            :taskFinancialData="taskFinancialData" @refreshData="loadData" />
        <LeaveTimeDetailPopup ref="leaveTimeDetailPopupRef" :referenceDetailId="referencePopupId" :updateLeaveStatus="updateLeaveStatus" @updateLeaveStatus="loadData" />
        <DeleteComponent id="deleteTask" :onYes="onYesTask" :returnParams="deleteParam" title="Delete Confirmation"
            message="Are you sure to delete Task?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import redirectMixin from "@/mixins/redirect";
import ERMCopyConfirmation from '@/components/popup/dialogbox/ERMCopyConfirmation.vue';
import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
import ReviewApprovalDetailPopup from '@/components/popup/itemdetail/ReviewApprovalDetailPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import ShareDetailPopup from '@/components/popup/itemdetail/ShareDetailPopup.vue';
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import TaskStatusUpdatePopup from '@/components/popup/action/TaskStatusUpdatePopup.vue';
import TaskFinancialPopup from '@/components/popup/action/TaskFinancialPopup.vue';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue'
import enumMixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [redirectMixin, enumMixin],
    props: {
        ermMasterInfo: Object
    },
    components: {
        ERMCopyConfirmation,
        ReferenceDetailPopup,
        ReviewApprovalDetailPopup,
        ShareDetailPopup,
        MeetingPrepartionDetailPopup,
        TaskDetailPopup,
        QuickTaskPopup,
        TaskStatusUpdatePopup,
        TaskFinancialPopup,
        LeaveTimeDetailPopup
    },
    data() {
        return {
            elements: {
                visible: false
            },
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},
            taskData: {},
            statusUpdate: {},
            showTaskStatusDialog: false,
            taskFinancialData: {},
            taskFinancialPopupKey: 0,
            updateLeaveStatus: false,

            actionItems: [],
            actionItemFields: [
                // { label: '' },
                { key: 'actionItemId', label: 'Id', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemSource', label: 'Action Type', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemTitle', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'entityDisplayName', label: 'Category', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemBy', label: 'Action Item By', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemTo', label: 'Action Item To', sortable: true, sortDirection: 'desc', class: '' },

                { key: 'actionItemDt', label: 'Action Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemStartDt', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemDueDt', label: 'Due Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc', class: '' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' },

                /*{ key: 'actions', label: 'Actions' }*/

            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: 'actionItemDt',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.loadData();
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        loadData: function () {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: localStorage.getItem("userId"),
                isArchive: false
            }
            this.showOverlay = true;
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/actionitem/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.actionItems = result.actionItemList;
                    this.totalRows = this.actionItems.length;
                    console.log(result);
                })

        },
        viewReference: function (data, title) {
            console.log(data);
            this.referencePopupId = data.entityRecordId;
            this.refPopupTitle = title;
            this.refPopupEntity = data.actionItemSource;
            this.detailViewData = data;

            if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'APV' || this.refPopupEntity == 'REV') {
                this.$refs.reviewApprovalDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'EMAIL') {
                this.$refs.emailDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'SHARE') {
                this.$refs.shareDetailPopupRef.getDetails();
            }
            else if (this.refPopupEntity == 'MTG') {
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.referencePopupId, data.entity, null);
            }
            else if (this.refPopupEntity == 'LVE' || this.refPopupEntity == 'PER') {
                console.log(data.actionItemToId, this.userId);
                this.updateLeaveStatus = data.actionItemToId == this.userId ? true : false;
                this.$refs.leaveTimeDetailPopupRef.getDetails(this.referencePopupId, 'leave_time');
            }
            else {
                this.$nextTick(() => {
                    this.$bvModal.show('referenceDetail');
                })
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
                console.log(this.referencePopupId, this.refPopupEntity, data);
            }
        },
        acceptDocumentCopy: function (id, entityRecordId, status) {
            this.$refs.ermCopyConfirmRef.getERMCopyData(id, entityRecordId, status);
        },
        routeActionItemBoard() {
            if (this.projectId) {
                this.$router.push('/actionitem');
            } else {
                this.$router.push('/landing/actionitem');
            }
        },
        showTaskEdit: async function (data) {
            this.showOverlay = true;
            const response = await this.getTaskDetails(data.entityRecordId);
            this.taskData = response.data.task;
            this.showOverlay = false;
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.$refs.ermAssignmentsRef.showEdit(this.taskData);
            });
        },
        duplicateTask: async function (data) {
            // this.showOverlay = true;
            // const response = await this.getTaskDetails(data.entityRecordId);
            // this.taskData = response.data.task;
            // this.showOverlay = false;
            // this.$bvModal.show('ermAssignments');
            // this.$nextTick(() => {
            //     this.$refs.ermAssignmentsRef.duplicateTask(this.taskData);
            // });
        },
        showStatusPopup: async function (data, endTimeline) {
            this.showOverlay = true;
            const response = await this.getTaskDetails(data.entityRecordId);
            this.taskData = response.data.task;
            this.showOverlay = false;
            if (this.taskData) {
                this.statusUpdate.id = this.taskData.id;
                this.statusUpdate.taskId = this.taskData.taskId;
                this.statusUpdate.title = this.taskData.title;
                this.statusUpdate.entityRecordMasterId = this.taskData.entityRecordMasterId;
                this.statusUpdate.statusId = this.taskData.statusId;
                this.statusUpdate.status = this.taskData.status;
                this.statusUpdate.activityId = this.taskData.activityId;
                this.statusUpdate.progressPercentage = this.taskData.progressPercentage ? this.taskData.progressPercentage : 0;

                if (endTimeline) {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.empWorkStatus.empTimelineDt ? this.getDateValue(this.empWorkStatus.empTimelineDt) : this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.startTimeValue = this.empWorkStatus.empTimelineDt ? this.getTimeValue(this.empWorkStatus.empTimelineDt) : null;
                    this.statusUpdate.endTimeValue = this.getTimeValue(new Date());
                }
                else {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endTimeValue = '';
                    this.statusUpdate.startTimeValue = '';
                }

                this.showTaskStatusDialog = true;
                this.$nextTick(() => {
                    this.$bvModal.show('taskSatatusUpdatePopup');
                })
            }
        },
        getTaskDetails: function (taskId) {
            return axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + taskId);
        },
        showTaskFinPopup: function (data) {
            this.taskFinancialData.vhextAccountId = data.vhextAccountId;
            this.taskFinancialData.projectId = data.projectId;
            this.taskFinancialData.taskId = data.entityRecordId;
            this.taskFinancialPopupKey++;
            this.$nextTick(() => {
                this.$bvModal.show('task-financial-popup');
            })
        },
        deleteTask: function (id) {
            this.deleteParam.id = id;
        },
        onYesTask: function () {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'task',
                id: this.deleteParam.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.loadData();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        archiveTask: function (item) {
            let data = {
                id: item.entityRecordId,
                entity: 'task',
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.loadData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        memorizeTask: function (itemData) {
            let data = {
                'id': itemData.entityRecordId,
                'entity': 'task',
                'columnName': 'is_memorized',
                'stateValue': itemData.isMemorized ? false : true,
            }
            var confirmMessage = '';
            const messageBoxData = {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'xs',
                okVariant: 'primary',
                cancelTitle: 'No',
                okTitle: 'Yes',
                headerClass: 'py-2 px-3',
                bodyClass: 'fs-14 px-3',
                footerClass: 'py-2 px-3',
                centered: true,
                hideHeaderClose: false
            }

            if (itemData.isMemorized) {
                confirmMessage = "Are you sure to remove the task from memory?"
            } else {
                confirmMessage = "Are you sure to add the task to memory?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, messageBoxData)
                .then(value => {
                    if (value) {
                        this.showOverlay = true;
                        axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                            .then((response) => {
                                this.showOverlay = false;
                                let result = response.data;
                                if (result.status == "OK") {
                                    iziToast.success({
                                        title: 'Updated',
                                        position: 'topCenter'
                                    });
                                    this.loadData();
                                }
                            })
                            .catch((error) => {
                                this.showOverlay = false;
                            })
                    }
                })
        },
    }
}
</script>