<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-md-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Project Membership Management</h4>
                        </div>
                        <div class="col-sm-4 mb-1">
                            <div v-if="!projectId" class="form-row text-right">
                                <b-col cols="my-auto mr-0 ml-auto pt-2 text-right">Choose Project</b-col>
                                <b-col cols="7" class="mr-0">
                                    <select v-model="projectMember.projectId" @change="getProjectMembers(true)" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in projects" :value="info.id">{{info.value}}</option>
                                    </select>
                                </b-col>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>                                    
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="projectteam-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Member</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="member">Member</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectMember.member.$error }">
                                                                <select v-model="projectMember.member" @change="showHRDetails(projectMember.member)" id="member" :class="{ 'is-invalid': $v.projectMember.member.$error }" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in humanResources" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <div>
                                                                <a :title="humanResourcePopupTitle" class="setting-icon p-0" href="javascript:" v-b-modal.humanResourcePopup><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                            </div>
                                                            <label v-if="$v.projectMember.member.$error && !$v.projectMember.member.required" class="text-danger">Please select Team Member</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="position">Email</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectMember.emailId.$error || error }">
                                                                <input type="text" v-model="projectMember.emailId" disabled :class="{ 'is-invalid': $v.projectMember.emailId.$error }" class="form-control" id="email" maxlength="100" placeholder="Please enter Email ID">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectMember.emailId.$error && !$v.projectMember.emailId.email" class="text-danger">Enter Valid Email Id</label>
                                                            <label v-if="$v.projectMember.emailId.$error && !$v.projectMember.emailId.required" class="text-danger">Please enter Email Id</label>
                                                            <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-4">
                                                        <div class="form-group">
                                                            <label>Project</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectMember.projectId.$error }">
                                                                <select v-model="projectMember.projectId" @change="getProjectMembers(true)" disabled :class="{ 'is-invalid': $v.projectMember.projectId.$error }" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in projects" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectMember.projectId.$error && !$v.projectMember.projectId.required" class="text-danger">Please select a Project</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-4">
                                                        <div class="form-group">
                                                            <label>User Role</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectMember.customUserRoleId.$error }">
                                                                <select v-model="projectMember.customUserRoleId" class="form-control" :class="{ 'is-invalid': $v.projectMember.customUserRoleId.$error }">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in memberRoles" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectMember.customUserRoleId.$error && !$v.projectMember.customUserRoleId.required" class="text-danger">Please select a User Role</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-4">
                                                        <div class="form-group">
                                                            <label for="position">Position</label>
                                                            <input type="text" v-model="projectMember.position" class="form-control" id="position" maxlength="100" placeholder="Position">
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData()" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <!--                                             <button type="submit" @click="saveData('invite')" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save & Invite</button> -->
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="row view-filter">

                                    <div class="col-8 text-left">
                                        <div class="dataTables_filter">
                                            <label class="d-block p-1 accordion-title">Project Members
                                            </label>
                                        </div>
                                    </div>
<!--                                     <div class="col-4 text-center">
                                        <select v-model="employeeStatusId" class="form-control" @change="onStatusFiltered">
                                            <option value="">All Status</option>
                                            <option v-for="(info, index) in employeeStatuses" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div> -->
                                    <div class="col-4 text-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="projectMemberFilter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div class="clearfix"></div> -->
                                </div>                                
<!--                                 <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="projectMemberFilter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div> -->
                                <b-table ref="projectMemberTable" v-model="mainTableArray" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable class="preview-table" :select-mode="selectMode" sort-icon-left :items="projectMembers" @row-selected="onRowSelected" :fields="projectMemberFields" responsive="sm" :filter="projectMemberFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredProjectTeam" @refreshed="changed">
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg pr-2"></i></a>
                                        <a href="javascript:" title="Send Activation Email" @click="inviteTeamMember(data.item.id, data.item.userId, data.item.emailId, data.item.memberName)"><i class="fa fa-envelope-square"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteProjectTeam(data.item.id)" v-b-modal.deleteProjectTeam><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Members found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

<!--                                 <b-pagination v-if="projectMembers.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && projectMemberDetail.projectMemberId" content-class="mt-3" lazy>
                                    <b-tab title="Details" :active="elements.showDetail">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{projectMemberDetail.projectMemberId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-5 pl-0">
                                            </div>
                                            <div class="col-2 pl-0">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateProjectTeam" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="humanResourceId">Member Name</label>
                                                    <select v-if="elements.editMode" v-model="projectMemberDetail.refRecordId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in humanResources" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input v-if="!elements.editMode" disabled v-model="projectMemberDetail.memberName" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="position">Position</label>
                                                    <input type="text" v-if="!elements.editMode" class="form-control" :disabled="!elements.editMode" v-model="projectMemberDetail.position">
                                                    <input type="text" v-if="elements.editMode" class="form-control" id="Position" v-model="projectMemberDetail.position">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="customUserRoleId">User Role</label>
                                                    <div v-if="elements.editMode" class="required-field-block" :class="{ 'is-invalid': $v.projectMemberDetail.customUserRoleId.$error }">
                                                        <select v-model="projectMemberDetail.customUserRoleId" class="form-control" :class="{ 'is-invalid': $v.projectMemberDetail.customUserRoleId.$error }">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in memberRoles" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.projectMemberDetail.customUserRoleId.$error && !$v.projectMemberDetail.customUserRoleId.required" class="text-danger">Please select a User Role</label>
                                                    <input v-if="!elements.editMode" disabled v-model="projectMemberDetail.customUserRole" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="emailId">Email</label>
                                                    <input type="text" v-if="!elements.editMode" class="form-control" :disabled="!elements.editMode" v-model="projectMemberDetail.emailId">
                                                    <input type="text" v-if="elements.editMode" class="form-control" id="EmailId" v-model="projectMemberDetail.emailId">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <div class="account-title">
                                                            <h5 class="mb-0">
                                                                <label class="my-1">User Account Info</label>
                                                                <label v-if="projectMemberDetail.loginName" :title="projectMemberDetail.isActiveUser==true ? 'Active User' : 'User Not Activated'" class="pull-right py-0 m-0 pointer">
                                                                    <i class="fa fa-user fa-xs" :class="{ 'text-success' : projectMemberDetail.isActiveUser==true }" aria-hidden="true"></i>
                                                                </label>
                                                                <button v-if="!projectMemberDetail.loginName && !elements.userActivation" @click="elements.userActivation = true" href="javascript:" class="sbtn btn btn-xs action-link p-1 pointer pull-right" title="User Account">
                                                                    <i class="fa fa-plus-circle action-link pr-1" aria-hidden="true"></i>User Account
                                                                </button>
                                                            </h5>
                                                        </div>
                                                        <div v-if="!elements.userActivation && projectMemberDetail.loginName" class="account-content">
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label>User Name</label>
                                                                        <b-form-input aria-label="First name" disabled v-model="projectMemberDetail.userName"></b-form-input>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label>Login Name</label>
                                                                        <b-form-input aria-label="First name" disabled v-model="projectMemberDetail.loginName"></b-form-input>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="position">Account Type</label>
                                                                        <input type="text" class="form-control" disabled v-model='projectMemberDetail.userType'>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="elements.userActivation" class="account-content">
                                                            <div class="row mt-1">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label>User Account Name</label>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectMemberDetail.memberName.$error, 'is-invalid': error }">
                                                                            <b-form-input aria-label="memberName" v-model="projectMemberDetail.memberName"></b-form-input>
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.projectMemberDetail.memberName.$error && !$v.projectMemberDetail.memberName.memberName" class="text-danger">Account Name must have at least {{$v.projectMemberDetail.memberName.$params.minLength.min}} letters.</label>
                                                                        <label v-if="$v.projectMemberDetail.memberName.$error && !$v.projectMemberDetail.memberName.required" class="text-danger">Please enter Account Name</label>
                                                                        <label v-if="$v.projectMemberDetail.memberName.$error && !$v.projectMemberDetail.memberName.maxLength" class="text-danger">Account Name must not exceed {{$v.projectMemberDetail.memberName.$params.maxLength.max}} letters.</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="position">Login Email</label>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectMemberDetail.emailId.$error, 'is-invalid': error }">
                                                                            <input type="text" class="form-control" v-model='projectMemberDetail.emailId'>
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.projectMemberDetail.emailId.$error && !$v.projectMemberDetail.emailId.email" class="text-danger">Enter Valid Email Id</label>
                                                                        <label v-if="$v.projectMemberDetail.emailId.$error && !$v.projectMemberDetail.emailId.required" class="text-danger">Please enter Email Id</label>
                                                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 my-2">
                                                                    <button type="submit" @click="createUserAccount" title="Save" class="sbtn btn btn-xs btn-primary py-1 px-2 mr-1"> <i class="fa fa-floppy-o pr-1"></i> Save</button>
                                                                    <button type="submit" @click="cancelAccountEdit" title="Cancel" class="sbtn btn btn-xs btn-primary py-1 px-2"><i class="fa fa-times pr-1"></i> Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.rr>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="rr" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="preparedBy">Created By</label>
                                                                            <input type="text" class="form-control" id="preparedBy" disabled v-model='projectMemberDetail.preparedBy'>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdDt">Created Date</label>
                                                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(projectMemberDetail.createdDt)">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="lastUpdatedDt">Last Updated Date</label>
                                                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(projectMemberDetail.lastUpdatedDt)">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
<!--                                     <b-tab title="Roles & Resp.">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <label for="role">Role</label>
                                                            <a href="javascript:" class="float-right button-add" @click="elements.showDropdownRole=!elements.showDropdownRole;">
                                                                <i v-if="!elements.showDropdownRole" title="Existing Roles" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                <i v-if="elements.showDropdownRole" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                            </a>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibilityDetail.role.$error }">
                                                                <select v-if="elements.showDropdownRole" @change="getResponsibilities(roleResponsibilityDetail.roleData.id)" v-model="roleResponsibilityDetail.roleData" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in roles" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.showDropdownRole" type="text" class="form-control" id="role" v-model="roleResponsibilityDetail.role" placeholder="Enter New Roles">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.roleResponsibilityDetail.role.$error && !$v.roleResponsibilityDetail.role.required" class="text-danger">Please Enter Role</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="responsibility">Responsibility</label>
                                                                <b-form-tags input-id="tags-separators" v-model="roleResponsibilityDetail.responsibilityArray" class="form-control" separator="," placeholder="Enter Responsibilities"></b-form-tags>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearRoleResponsibility" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.roleEditMode ? 'Cancel' : 'Clear'}}</button>
                                                    <button type="submit" @click="saveRoleResponsibility()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Add</button>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="card mt-1" v-for="responsibility in responsibilities">
                                                <div class="card-body p-2" v-if="responsibility.id !== roleResponsibilityDetail.editId">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Role" @click="deleteRoleResponsibility(responsibility.id)" v-b-modal.deleteRoleResponsibility><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Role" @click="editRole(responsibility)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ responsibility.role }}</h6>
                                                                <p> {{ responsibility.responsibility }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Tech & Skills">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <label for="technology">Technology</label>
                                                            <a href="javascript:" class="float-right button-add" @click="elements.showDropdownTech=!elements.showDropdownTech;">
                                                                <i v-if="!elements.showDropdownTech" title="Existing Technologies" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                <i v-if="elements.showDropdownTech" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                            </a>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkillDetail.technology.$error }">
                                                                <select v-if="elements.showDropdownTech" @change="getTechnologies(technologySkillDetail.technologyData.id)" v-model="technologySkillDetail.technologyData" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in technologies" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.showDropdownTech" type="text" class="form-control" id="technology" v-model="technologySkillDetail.technology" placeholder="Enter New Technologies">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.technologySkillDetail.technology.$error && !$v.technologySkillDetail.technology.required" class="text-danger">Please Enter Technology</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="skills">Skills</label>
                                                                <b-form-tags input-id="tags-separators" v-model="technologySkillDetail.skillsArray" separator="," class="form-control" placeholder="Enter skills"></b-form-tags>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearTechnologySkill" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.techEditMode ? 'Cancel' : 'Clear'}}</button>
                                                    <button type="submit" @click="saveTechnologySkill()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Add</button>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="card my-1" v-for="technologySkill in technologySkills">
                                                <div class="card-body p-2" v-if="technologySkill.id !== technologySkillDetail.editId">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Technology" @click="deleteTechnologySkill(technologySkill.id)" v-b-modal.deleteTechnologySkill ><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Technology" @click="editTech(technologySkill)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ technologySkill.technology }}</h6>
                                                                <p>{{ technologySkill.skills }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab> -->
                                    <b-tab title="Member Privileges">
                                        <div class="row view-filter">
                                            <div class="col-sm-12">
                                                <div class="float-right">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>

                                                <div class="float-left">
                                                    <label class="ml-1 mr-1 mt-1 title-highlight">
                                                        <span>Member Name :</span> {{projectMemberDetail.userName}}
                                                    </label>                                                    
                                                </div>
<!--                                                 <div class="col-4 float-right">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                            <b-form-select v-model="perPageUserMenu" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                        <b-table show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="userMenuList" :fields="userMenuFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUserMenu">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(displayName)="data">
                                                <span v-if="data.item.mainMenu">
                                                    {{data.item.mainMenu}} / {{data.item.displayName}}
                                                </span>
                                                <span v-else>
                                                    {{data.item.displayName}}
                                                </span>
                                            </template>
                                            <template v-slot:cell(menuExist)="data">
                                                <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege(data.item)">
                                                </b-form-checkbox>
                                            </template>

                                            <template v-slot:empty="userMenu">
                                                <div class="text-center">No UserMenu found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="userMenu">
                                                <div class="text-center">{{ userMenu.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalUserMenuRows}} Items</span>

<!--                                         <b-pagination v-if="userMenuList.length!=0" pills v-model="currentPageUserMenu" :total-rows="totalUserMenuRows" :per-page="perPageUserMenu" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->                                        
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <HumanResourcesPopup ref="humanResourcePopup" :entity="this.entity" :humanResourcePopupTitle="humanResourcePopupTitle" @refresh-lookup="lookupData" />
<!--         <DeleteComponent id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" /> -->
        <DeleteComponent id="deleteProjectTeam" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete team member?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import month from '../../json/month.json';
import HumanResourcesPopup from '@/components/popup/lookup/HumanResourcesPopup.vue';

export default {
    name: 'app',
    components: {
        HumanResourcesPopup,
    },
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        },
        filterUserTypes() {
            return this.userTypes.filter((type) => {
                if (this.userTypeCode == 'CAM' || this.userTypeCode == 'VSA') {
                    if (type.typeCode == 'CAM' || type.typeCode == 'MRP' || type.typeCode == 'HRM' || type.typeCode == 'FNM' || type.typeCode == 'PRM' || type.typeCode == 'TMB') {
                        return type
                    }
                } 
                else if (this.userTypeCode == 'PRM') {
                    if (type.typeCode == 'PRM' || type.typeCode == 'TMB') {
                        return type
                    }
                }
            })
        }
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            error: false,
            ermTitle: localStorage.getItem("ermTitle"),
            humanResourcePopupTitle: 'Project Members',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            userTypeId: localStorage.getItem("userTypeId"),
            vmxServiceCode: 'PRJ',
            standardMenuTypeId: 1,
            
            monthList: month,
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                roleEditMode: false,
                techEditMode: false,
                showDropdownRole: false,
                showDropdownTech: false,
                alreadyExists: false,
                userActivation: false
            },
            projectMember: {
                id: '',
                projectMemberId: '',
                userId: '',
                userTypeId: '',
                userType: '',
                userTypeCode: '',

                customUserRoleId: '',
                memberTypeId: '',
                memberTypeCode: '',
                memberName: '',
                active: true,
                isActiveUser: false,
                member: '',
                humanResourceId: '',
                emailId: '',
                phone: '',
                position: '',
                preparedBy: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),

                projectId: '',
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false,
                vmxServiceCode: 'PRJ',
            },
            projectMemberDetail: {
                id: '',
                projectMemberId: '',
                userId: '',
                userTypeId: '',
                userType: '',
                userTypeCode: '',
                customUserRoleId: '',
                memberTypeId: '',
                active: '',
                isActiveUser: false,
                memberTypeCode: '',
                memberName: '',
                member: '',
                refRecordId: '',
                humanResourceId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                preparedBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                vmxServiceCode: 'PRJ',
            },
            userMenu: {
                id: '',
                vhextAccountId: '',
                vmxServiceRecordId: '', //projectId/Sub Contract Id...
                userId: '',
                customMenuId: '',
                standardMenuId: '',
                vmxServiceCode: '',
                active: '',
            },            
            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: 'project_team',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''
            },
            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'technology_skill',
                refEntity: 'project_team',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technology: '',
                technologyData: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },
            entityId: '',
            entity: "project_team_master",
            projectMembers: [],
            userMenuList: [],
            projects: [],
            userTypes: [],
            customUserRoles: [],
            humanResources: [],
            technologies: [],
            roles: [],
            responsibilities: [],
            technologySkills: [],
            mainTableArray: [],

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            projectMemberFields: [
                { key: 'projectMemberId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'userName', label: 'Member Name', sortable: true, sortDirection: 'desc' },
                { key: 'customUserRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'loginName', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            userMenuFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                // { key: 'standardMenuId', label: 'Std. Id', sortable: true, sortDirection: 'desc' },
                // { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                // { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                // { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                // { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'menuExist', label: 'Assigned', sortable: true, sortDirection: 'desc' },
            ],

            selectMode: 'single',
            perPage: 10,
            pageOptions: [5,10, 15, 20],
            totalUserMenuRows: 1,
            currentPageUserMenu: 1,
            perPageUserMenu: 10,

            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            projectMemberFilter: null,
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        memberRoles() {
            // if(this.vmxServiceData.vmxServiceId) {
            // vmxServiceId = 5 for project
            return this.customUserRoles.filter(data => data.vmxServiceId == 5);
            // }
            // else return this.customUserRoles;
        },
    },
    mounted() {
        this.lookupData();
    },
    validations: {

        projectMember: {
            member: {
                required
            },
            emailId: {
                required,
                email,
            },
            projectId: {
                required
            },
            customUserRoleId: {
                required
            }
        },
        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {
            technology: {
                required
            }
        },
        projectMemberDetail: {
            memberName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            },
            customUserRoleId: {
                required
            }
        }
    },

    methods: {
        getProjectMembers: function(isFirstTimeLoad) {
            if (this.projectMember.projectId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectMember.projectId,
                }
                console.log(data);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/projectmember/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        // console.log(result);
                        if (result.projectMemberList && result.projectMemberList.length > 0) {
                            this.projectMembers = result.projectMemberList;
                            if (isFirstTimeLoad || this.projectMembers.length == 1) {
                                this.elements.showDetail = true;
                                this.projectMemberDetail.refRecordId = this.projectMembers[0].refRecordId;
                                this.projectMemberDetail.id = this.projectMembers[0].id;
                            }
                            this.totalRows = this.projectMembers.length;
                        }
                        if (this.projectMembers && this.projectMembers.length > 0) {
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.projectMemberDetail.id, this.mainTableArray, 'projectMemberTable');
                            })
                        }
                        else {
                            this.elements.showDetail = false;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                userTypeId: this.userTypeId,
            }
            // console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/membership/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projects = result.userProjectLookup;
                    this.humanResources = result.humanResourceLookup;
                    // this.userTypes = result.typeLookup;
                    this.customUserRoles = result.customUserRoleLookup;

                    let prjId = localStorage.getItem("projectId");
                    // alert(prjId);
                    this.projectMember.projectId = !prjId && this.projects.length>0 ? this.projects[0].id : prjId;

                    this.getProjectMembers(true);
                })
        },
        getUserMenu: function(userId, customUserRoleId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectMember.projectId,
                vmxServiceCode: this.vmxServiceCode,
                userId: userId,
                standardMenuTypeId: this.standardMenuTypeId,
                customUserRoleId: customUserRoleId,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/usermenu/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.userMenuList = result.userMenuList;
                    console.log(this.userMenuList);
                    this.totalUserMenuRows = this.userMenuList.length;
                })
        },

        // getRoleResponsibilities: function() {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectId: this.projectMember.projectId,
        //         refEntity: 'project_team',
        //         refRecordId: this.projectMemberDetail.refRecordId,
        //     }
        //     console.log(data);
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             if (result.length != 0) {
        //                 this.responsibilities = result.cmrRoleResponsibilityList;
        //                 console.log(result.cmrRoleResponsibilityList);
        //             }
        //         })
        // },

        // getTechnologySkills: function() {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectId: this.projectMember.projectId,
        //         refEntity: 'project_team',
        //         refRecordId: this.projectMemberDetail.refRecordId,
        //     }
        //     console.log(data);
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             if (result.length != 0) {
        //                 this.technologySkills = result.cmrTechnologySkillList;
        //                 console.log(result);
        //             }
        //         })
        // },
        onFilteredUserMenu(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalUserMenuRows = filteredItems.length
            this.currentPageUserMenu = 1
        },

        saveData: function(flag) {
            if (this.projectMember.member != null) {
                this.projectMember.memberName = this.projectMember.member.text;
                this.projectMember.refRecordId = this.projectMember.member.id;
            }
            if (flag == 'invite') {
                this.projectMember.invite = true;
            } else {
                this.projectMember.invite = false;
            }
            this.$v.projectMember.$touch();
            if (!this.$v.projectMember.$invalid && !this.checkEmailExists(this.projectMember.emailId)) {
                if (this.checkHRExists(this.projectMember.refRecordId)) {
                    iziToast.info({
                        message: 'Member already exists',
                        position: 'topRight'
                    });
                    return;
                }
                this.error = false;
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/projectmember/add', this.projectMember)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getProjectMembers(false);
                            this.clearData();
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                //this.error = true;
            }
        },
        updateProjectTeam: function() {
            // console.log(this.projectMemberDetail);
            this.$v.projectMemberDetail.$touch();
            if (!this.$v.projectMemberDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/projectmember/update', this.projectMemberDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getProjectMembers(false);
                            this.elements.editMode = false;
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        deleteProjectTeam: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/projectmember/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectMembers(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        showHRDetails: function(hrs) {
            this.showOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + hrs.id)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    // console.log(result);

                    this.projectMember.userId = result.humanResourceView.userId;
                    this.projectMember.customUserRoleId = result.humanResourceView.customUserRoleId;
                    this.projectMember.memberTypeId = result.humanResourceView.userTypeId;
                    this.projectMember.userType = result.humanResourceView.userType;
                    this.projectMember.userTypeCode = result.humanResourceView.userTypeCode;
                    this.projectMember.emailId = result.humanResourceView.loginName;
                    this.projectMember.position = result.humanResourceView.position;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            //this.getContacts();
        },
        cancelEditMode: function() {
            this.elements.editMode = false;
            this.selectTableRow(this.projectMemberDetail.id);
        },
        clearInputData: function() {
            this.projectMember.memberName = '';
            this.projectMember.refRecordId = '';
            this.projectMember.projectMemberId = '';
            this.projectMember.customUserRoleId = '';
            this.projectMember.memberTypeId = '';
            this.projectMember.emailId = '';
            this.projectMember.position = '';
            this.projectMember.id = '';
            this.projectMember.userType = '';
            this.projectMember.loginName = '';
            this.projectMember.userName = '';
            this.elements.alreadyExists = false;
            this.error = false;
        },
        clearData: function() {
            this.elements.visible = false;
            this.clearInputData();
            this.projectMember.member = '';
            this.error = false;
            this.$v.projectMember.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data);
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.elements.editMode = false;
                this.elements.userActivation = false;
                this.projectMemberDetail.id = data.id;
                this.projectMemberDetail.projectId = data.projectId;
                this.projectMemberDetail.projectMemberId = data.projectMemberId;
                this.projectMemberDetail.customUserRoleId = data.customUserRoleId;
                this.projectMemberDetail.customUserRole = data.customUserRole;
                this.projectMemberDetail.memberTypeId = data.memberTypeId;
                this.projectMemberDetail.memberType = data.memberType;
                this.projectMemberDetail.memberName = data.memberName;
                this.projectMemberDetail.refRecordId = data.refRecordId;
                this.projectMemberDetail.refEntityId = data.refEntityId;
                this.projectMemberDetail.emailId = data.emailId;
                this.projectMemberDetail.active = data.isActive;
                this.projectMemberDetail.isActiveUser = data.isActiveUser;
                this.projectMemberDetail.position = data.position;
                this.projectMemberDetail.preparedBy = data.preparedBy;
                this.projectMemberDetail.type = data.type;
                this.projectMemberDetail.userName = data.userName;
                this.projectMemberDetail.userId = data.userId;
                this.projectMemberDetail.vhextAccountId = data.vhextAccountId;
                this.projectMemberDetail.loginName = data.loginName;
                this.projectMemberDetail.userName = data.userName;
                this.projectMemberDetail.userType = data.userType;
                // this.getTechnologySkills();
                // this.getRoleResponsibilities();

                if (data.type == "Client") {
                    this.standardMenuTypeId = 2;
                }
                else {
                    this.standardMenuTypeId = 1;
                }

                // console.log(this.projectMember.id);
                this.getUserMenu(this.projectMemberDetail.userId, this.projectMemberDetail.customUserRoleId);

            }
        },
        assignPrivilege: function(item) {
            this.userMenu.vhextAccountId = this.vhextAccountId;
            this.userMenu.vmxServiceRecordId = this.projectMember.projectId;
            this.userMenu.vmxServiceCode = 'PRJ';
            this.userMenu.userId = this.projectMemberDetail.userId;
            this.userMenu.customUserRoleId = this.projectMemberDetail.customUserRoleId;
            this.userMenu.standardMenuId = item.standardMenuId;

            if (item.menuExist == "true") {
                this.userMenu.active = 0;
            } else if (item.menuExist == "false"){
                this.userMenu.active = 1;
            }
            this.assignUserMenuPrivilege();
        },
        assignUserMenuPrivilege: function() {
            console.log(this.userMenu);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/member/servicemenu/assign', this.userMenu)
                .then((response) => {console.log(this.userMenu.userId);
                    this.showDetOverlay = false;
                    let result = response.data;

                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserMenu(this.userMenu.userId, this.userMenu.customUserRoleId);
                        if (this.userMenu.userId == this.userId) {
                            EventBus.$emit('onServiceLeftMenuReload');
                            EventBus.$emit('onServiceTopMenuReload');
                        }
                    }
                })
                .catch((error) => {
                    this.showDetOverlay = false;
                })
        },

        showEdit(data) {
            this.elements.showDetail = true;
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.userActivation = false;
            this.projectMemberDetail.id = data.id;
            this.projectMemberDetail.projectId = data.projectId;
            this.projectMemberDetail.projectMemberId = data.projectMemberId;
            this.projectMemberDetail.customUserRoleId = data.customUserRoleId;
            this.projectMemberDetail.customUserRole = data.customUserRole;
            this.projectMemberDetail.memberTypeId = data.memberTypeId;
            this.projectMemberDetail.memberType = data.memberType;
            this.projectMemberDetail.memberName = data.memberName;
            this.projectMemberDetail.refRecordId = data.refRecordId;
            this.projectMemberDetail.refEntityId = data.refEntityId;
            this.projectMemberDetail.emailId = data.emailId;
            this.projectMemberDetail.active = data.isActive;
            this.projectMemberDetail.isActiveUser = data.isActiveUser;
            this.projectMemberDetail.position = data.position;
            this.projectMemberDetail.preparedBy = data.preparedBy;
            this.projectMemberDetail.type = data.type;
            this.projectMemberDetail.userId = data.userId;
            this.projectMemberDetail.vhextAccountId = data.vhextAccountId;
            this.projectMemberDetail.loginName = data.loginName;
            this.projectMemberDetail.userName = data.userName;
            this.projectMemberDetail.userType = data.userType;
            // this.getTechnologySkills();
            // this.getRoleResponsibilities();
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFilteredProjectTeam(filteredItems) {
            console.log(filteredItems, 'ffffffffffffffffffffff')
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        inviteTeamMember: function(clientId, userId, emailId, memberName) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectMember.projectId,
                entity: 'project_team',
                emailId: emailId,
                id: userId,
                userName: memberName
            }

            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/activate', data)
                .then((response) => {
                    let result = response.data;
                    if (Boolean(result.status)) {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        checkHRExists: function(hrsId) {
            let result = false;
            this.projectMembers.map(data => {
                if (data.refRecordId == hrsId) {
                    result = true;
                }
            });
            return result;
        },
        checkEmailExists: function(emailId) {
            let result = false;
            this.elements.alreadyExists = false;
            this.error = false;
            this.projectMembers.map(data => {
                if (data.emailId == emailId) {
                    this.elements.alreadyExists = true;
                    this.error = true;
                    result = true;
                }
            });
            return result;
        },
        selectTableRow(id) {
            let index = this.projectMembers.findIndex(arr => arr.id == id);
            this.$nextTick(() => {
                this.$refs.projectMemberTable.selectRow(index);
            })
        },
        // saveRoleResponsibility: function() {
        //     if (this.elements.showDropdownRole) {

        //         if (this.roleResponsibilityDetail.roleData.text != null) {
        //             this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
        //             this.validateRoleResponsibility();
        //         } else {
        //             this.roleResponsibilityDetail.role = '';
        //             this.validateRoleResponsibility();
        //         }
        //     } else {
        //         this.validateRoleResponsibility();
        //     }

        // },
        // validateRoleResponsibility: function() {
        //     this.$v.roleResponsibilityDetail.$touch();
        //     if (!this.$v.roleResponsibilityDetail.$invalid) {
        //         if (this.elements.roleEditMode) {
        //             this.updateRoleResponsibility();
        //         } else {
        //             this.addRoleResponsibility();
        //         }
        //     }
        // },
        // addRoleResponsibility: function() {
        //     this.roleResponsibilityDetail.refRecordId = this.projectMemberDetail.refRecordId;
        //     this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
        //     console.log(this.roleResponsibilityDetail);
        //     this.showDetOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
        //         .then((response) => {
        //             this.showDetOverlay = false;
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 // this.getRoleResponsibilities();
        //                 // this.clearRoleResponsibility();
        //             }
        //         })
        //         .catch(error => {
        //             this.showDetOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        // updateRoleResponsibility: function() {
        //     this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
        //     console.log(this.roleResponsibilityDetail);
        //     this.showDetOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
        //         .then((response) => {
        //             this.showDetOverlay = false;
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 // this.getRoleResponsibilities();
        //                 // this.clearRoleResponsibility();
        //             }
        //         })
        //         .catch(error => {
        //             this.showDetOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        // clearRoleResponsibility: function() {
        //     this.elements.roleEditMode = false;
        //     this.roleResponsibilityDetail.editId = '';
        //     this.roleResponsibilityDetail.role = '';

        //     if (this.roleResponsibilityDetail.roleData) {
        //         this.roleResponsibilityDetail.roleData = '';
        //     }
        //     this.roleResponsibilityDetail.responsibility = '';
        //     this.roleResponsibilityDetail.responsibilityArray = [];
        //     this.$v.roleResponsibilityDetail.$reset();
        // },
        // deleteRoleResponsibility: function(id) {
        //     this.deleteParam.id = id;
        // },
        // onYesRole: function() {
        //     console.log(this.deleteParam.id);
        //     axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
        //         .then(response => {
        //             var result = response.data;
        //             if (result.status == "OK") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 // this.getRoleResponsibilities();
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        // saveTechnologySkill: function() {
        //     console.log(this.technologySkillDetail.technology)
        //     if (this.elements.showDropdownTech) {
        //         if (this.technologySkillDetail.technologyData.text != null) {
        //             this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
        //             this.validateTechnologySkill();
        //         } else {
        //             this.technologySkillDetail.technology = '';
        //             this.validateTechnologySkill();
        //         }
        //     } else {
        //         this.validateTechnologySkill();
        //     }
        // },
        // validateTechnologySkill: function() {
        //     this.$v.technologySkillDetail.$touch();
        //     if (!this.$v.technologySkillDetail.$invalid) {
        //         if (this.elements.techEditMode) {
        //             this.updateTechnologySkill();
        //         } else {
        //             this.addTechnologySkill();
        //         }
        //     }
        // },
        // addTechnologySkill: function() {
        //     this.technologySkillDetail.refRecordId = this.projectMemberDetail.refRecordId;
        //     this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
        //     console.log(this.technologySkillDetail);
        //     this.showDetOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
        //         .then((response) => {
        //             this.showDetOverlay = false;
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.getTechnologySkills();
        //                 this.clearTechnologySkill();
        //             }
        //         })
        //         .catch(error => {
        //             this.showDetOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        // updateTechnologySkill: function() {
        //     this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
        //     console.log(this.technologySkillDetail);
        //     this.showDetOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
        //         .then((response) => {
        //             this.showDetOverlay = false;
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.getTechnologySkills();
        //                 this.clearTechnologySkill();
        //             }
        //         })
        //         .catch(error => {
        //             this.showDetOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        // clearTechnologySkill: function() {
        //     this.elements.techEditMode = false;
        //     this.technologySkillDetail.editId = '';
        //     this.technologySkillDetail.technology = '';
        //     if (this.technologySkillDetail.technologyData.id) {
        //         this.technologySkillDetail.technologyData = '';
        //     }
        //     this.technologySkillDetail.skills = '';
        //     this.technologySkillDetail.skillsArray = [];
        //     this.$v.technologySkillDetail.$reset();
        // },
        // deleteTechnologySkill: function(id) {
        //     this.deleteParam.id = id;
        // },
        // onYesTech: function() {
        //     console.log(this.deleteParam.id);
        //     axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
        //         .then(response => {
        //             var result = response.data;
        //             if (result.status == "OK") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.getTechnologySkills();
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        // editRole: function(data) {
        //     this.elements.showDropdownRole = false;
        //     this.elements.roleEditMode = true;
        //     console.log(data);
        //     this.roleResponsibilityDetail.id = data.id;
        //     this.roleResponsibilityDetail.editId = data.id;
        //     this.roleResponsibilityDetail.role = data.role;
        //     this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
        //     this.roleResponsibilityDetail.refRecordId = data.refRecordId;
        //     this.roleResponsibilityDetail.refEntityId = data.refEntityId;
        //     this.roleResponsibilityDetail.projectId = data.projectId;
        //     this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
        //     this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
        //     this.roleResponsibilityDetail.preparedById = data.preparedById;
        // },
        // editTech: function(data) {
        //     this.elements.showDropdownTech = false;
        //     this.elements.techEditMode = true;
        //     console.log(data);
        //     this.technologySkillDetail.id = data.id;
        //     this.technologySkillDetail.editId = data.id;
        //     this.technologySkillDetail.technologySkillId = data.technologySkillId;
        //     this.technologySkillDetail.technology = data.technology;
        //     console.log(data.skills.length);
        //     this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
        //     this.technologySkillDetail.refRecordId = data.refRecordId;
        //     this.technologySkillDetail.refEntityId = data.refEntityId;
        //     this.technologySkillDetail.projectId = data.projectId;
        //     this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
        //     this.technologySkillDetail.preparedById = data.preparedById;
        // },
        arrayToString: function(arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },
        stringToArray: function(arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.split(",");
            }
            return "";
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        // console.log(result);
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray = roleResponsibility.responsibility && roleResponsibility.responsibility !== null ? this.stringToArray(roleResponsibility.responsibility) : [];
                    })
            }
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        // console.log(result);
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = technologySkill.skills && technologySkill.skills !== null ? this.stringToArray(technologySkill.skills) : [];
                    })
            }
        },
        createUserAccount: function() {
            this.projectMemberDetail.userTypeCode = 'TMB';
            this.projectMemberDetail.userType = 'Team Member';
            this.projectMemberDetail.userName = this.projectMemberDetail.memberName;
            this.$v.projectMemberDetail.$touch();
            if (!this.$v.projectMemberDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.projectMemberDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            this.clearData();
                            this.cancelAccountEdit();
                            this.getProjectMembers(false);
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });

                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        cancelAccountEdit() {
            this.elements.userActivation = false;
            this.projectMembers.filter((projectMember) => {
                if (projectMember.id == this.projectMemberDetail.id) {
                    this.projectMemberDetail.memberName = projectMember.memberName;
                    this.projectMemberDetail.emailId = projectMember.emailId;
                }
            })
        },
        changed(ctx) {
            console.log(ctx, 'eeeeeeeeeeeeeeeeeeee')
        }
    }
}
</script>