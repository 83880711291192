<template>
    <div class="assignmentPopup">
        <b-modal :id="id" ref="leaveTimePopupRef" no-close-on-backdrop no-close-on-esc :title="`${isLeaveApplication ? 'Leave' : 'Permission'} Application`" no-enforce-focus @hide="closeDialog" size="lg" body-class="pb-2" ok-only>
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <!-- {{dateDiff - (leaveTimeData.startHalfDay ==3 || leaveTimeData.startHalfDay ==2 ? 0.5 : 0 ) - (leaveTimeData.endHalfDay ==3 || leaveTimeData.endHalfDay ==2 ? 0.5 : 0 )}} -->
                    <b-row v-if="isLeaveApplication" class="my-2">
                        <b-col cols="2" class="my-auto">Leave Type</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.leaveTypeId.$error }">
                                <select v-model="leaveTimeData.leaveTypeId" @change="getHumanResourceData" class="form-control" :class="{ 'is-invalid': $v.leaveTimeData.leaveTypeId.$error }">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in leaveTypes" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.leaveTimeData.leaveTypeId.$error && !$v.leaveTimeData.leaveTypeId.required" class="text-danger">Please select Leave Type</label>
                        </b-col>
                        <!-- <a :title="typeLookupTitle" class="action-link ml-auto mr-3" href="javascript:" @click="typePopupKey++" v-b-modal.typeModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a> -->
                    </b-row>
                    <b-row class="my-2">
                        <b-col cols="2" class="my-auto">Subject</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.subject.$error }">
                                <b-form-input v-model="leaveTimeData.subject" placeholder="Enter Subject" required :class="{ 'is-invalid': $v.leaveTimeData.subject.$error }"></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.leaveTimeData.subject.$error && !$v.leaveTimeData.subject.minLength" class="text-danger">Subject must have at least {{$v.leaveTimeData.subject.$params.minLength.min}} letters.</label>
                            <label v-if="$v.leaveTimeData.subject.$error && !$v.leaveTimeData.subject.required" class="text-danger">Please enter Subject</label>
                            <label v-if="$v.leaveTimeData.subject.$error && !$v.leaveTimeData.subject.maxLength" class="text-danger">Subject must not exceed {{$v.leaveTimeData.subject.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row class="my-2">
                        <b-col cols="2" class="">Reason</b-col>
                        <b-col>
                            <!-- <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.description.$error }"> -->
                            <textarea rows="2" v-model="leaveTimeData.description" placeholder="Enter Leave Reason" class="form-control" required></textarea>
                                <!-- <div class="required-icon"></div> -->
                            <!-- </div> -->
                            <!-- <label v-if="$v.leaveTimeData.description.$error && !$v.leaveTimeData.description.minLength" class="text-danger">Reason must have at least {{$v.leaveTimeData.description.$params.minLength.min}} letters.</label>
                            <label v-if="$v.leaveTimeData.description.$error && !$v.leaveTimeData.description.required" class="text-danger">Please enter Reason</label>
                            <label v-if="$v.leaveTimeData.description.$error && !$v.leaveTimeData.description.maxLength" class="text-danger">Reason must not exceed {{$v.leaveTimeData.description.$params.maxLength.max}} letters.</label> -->
                        </b-col>
                    </b-row>
                    <b-row class="my-2">
                        <b-col cols="2" class="">Work Plan</b-col>
                        <b-col>
                            <textarea rows="2" v-model="leaveTimeData.workPlan" placeholder="Enter work plan" class="form-control"></textarea>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="my-2">
                        <b-col cols="2" class="my-auto">Work Planned To</b-col>
                        <b-col cols="4" class="pr-1">
                            <select v-model="leaveTimeData.workPlannedTo" class="form-control" >
                                <option value="">Select</option>
                                <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row> -->
                    <template v-if="isLeaveApplication">
                        <b-row class="my-2">
                            <b-col cols="2" class="my-auto">Leave Period</b-col>
                            <b-col class="col pr-0">
                                <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.fromDt.$error }">
                                    <b-form-input type="date" v-model="leaveTimeData.fromDt" @input="resetEndHalfDay" required :class="{ 'is-invalid': $v.leaveTimeData.fromDt.$error }"></b-form-input>
                                    <div class="required-icon"></div>
                                </div>
                                <label v-if="$v.leaveTimeData.fromDt.$error && !$v.leaveTimeData.fromDt.required" class="text-danger">Please Select a Date</label>
                            </b-col>
                            <b-col class="col-2 px-0">
                                <select v-model="leaveTimeData.startHalfDay" @change="resetEndHalfDay" class="form-control">
                                    <option value="1">Full Day</option>
                                    <option v-if="leaveTimeData.fromDt == leaveTimeData.tillDt" value="2">1st Half</option>
                                    <option value="3">2nd Half</option>
                                    <!-- <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option> -->
                                </select>
                            </b-col>
                            <b-col class="col-1 my-auto text-center px-0">
                                to
                            </b-col>
                            <b-col class="col px-0">
                                <b-form-input type="date" v-model="leaveTimeData.tillDt" :disabled="!leaveTimeData.fromDt" @input="resetEndHalfDay" required></b-form-input>
                            </b-col>
                            <b-col class="col-2 pl-0">
                                <select v-model="leaveTimeData.endHalfDay" :disabled="disableEndDay" class="form-control">
                                    <option value="1">Full Day</option>
                                    <option value="2">1st Half</option>
                                    <option v-if="leaveTimeData.fromDt == leaveTimeData.tillDt" value="3">2nd Half</option>
                                    <!-- <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option> -->
                                </select>
                            </b-col>
                        </b-row>
                    </template>
                    <b-row v-else class="my-2">
                        <b-col cols="2" class="">Permission Date</b-col>
                        <b-col cols="4">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.fromDt.$error }">
                                <b-form-input type="date" v-model="leaveTimeData.fromDate" required :class="{ 'is-invalid': $v.leaveTimeData.fromDt.$error }"></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.leaveTimeData.fromDt.$error && !$v.leaveTimeData.fromDt.required" class="text-danger">Please Select a Date</label>
                        </b-col>
                        <b-col cols="5">
                            <b-row>
                                <b-col cols="5" class="pr-0">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.fromTime.$error }">
                                        <b-form-input type="time" v-model="leaveTimeData.fromTime" required :class="{ 'is-invalid': $v.leaveTimeData.fromTime.$error }" @input="leaveTimeData.totalHours = 0"></b-form-input>
                                        <div class="required-icon"></div>
                                    </div>
                                </b-col>
                                <b-col class="col-1 my-auto text-center px-0">
                                    to
                                </b-col>
                                <b-col cols="5" class="pl-0">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.tillTime.$error }">
                                        <b-form-input type="time" v-model="leaveTimeData.tillTime" required :class="{ 'is-invalid': $v.leaveTimeData.tillTime.$error }" @input="leaveTimeData.totalHours = 0"></b-form-input>
                                        <div class="required-icon"></div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="my-2">
                        <b-col cols="2" class="my-auto">Recommended By</b-col>
                        <b-col cols="5" class="pr-1">
                            <select v-model="leaveTimeData.recommendedById" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row> -->
                    <b-row v-if="isLeaveApplication" class="my-2 mt-1">
                        <b-col cols="2" class="my-auto">No. of Days</b-col>
                        <b-col cols="4" class="my-auto">
                            <b-form-input type="text" v-model="dateDiff" required disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row v-else class="my-2">
                        <b-col cols="2" class="my-auto">Total Hours</b-col>
                        <b-col cols="4" class="my-auto">
                            <b-form-input type="number" v-model="timeDiff" required></b-form-input>
                            <!-- <label class="mt-1 mb-0">{{ leaveTimeData.totalHours ? leaveTimeData.totalHours : timeDiff }}</label> -->
                        </b-col>
                    </b-row>
                    <b-row v-if="hasWorkflowConfig != 1" class="my-2">
                        <b-col cols="2" class="my-auto">Approval By</b-col>
                        <b-col cols="4">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.leaveTimeData.requestedToId.$error }">
                                <select v-model="leaveTimeData.requestedToId" class="form-control" required :class="{ 'is-invalid': $v.leaveTimeData.requestedToId.$error }">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.leaveTimeData.requestedToId.$error && !$v.leaveTimeData.requestedToId.required" class="text-danger">Approval By is required</label>
                        </b-col>
                        <!-- <b-col cols="2" class="my-auto pr-0">Recommended By</b-col>
                        <b-col cols="4" class="pl-1">
                            <select v-model="leaveTimeData.recommendedById" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                            </select>
                        </b-col> -->
                    </b-row>
                    <!-- <div class="row">
                        <div class="col-12 col-sm-12">
                            <div class="">
                                <div class="card-body py-1 px-0">
                                    <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div v-if="isLeaveApplication" class="row">
                        <div class="col-12 col-sm-12 text-sm-right">
                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3">
                                Submit
                            </button>
                            <button type="submit" @click="closeDialog" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                        </div>
                    </div>
                </b-container>
                <b-container v-if="isLeaveApplication">
                    <div v-if="leavePolicyData && leavePolicyData.description" class="card my-3 px-2">
                        <div class="text-muted mt-3">
                            <h6>Note:</h6>
                            <div v-html="leavePolicyData.description"></div>
                        </div>
                    </div>
                    <div class="separator my-3"></div>
                    <div class="row view-filter">
                        <div class="col">
                            <h4 class="my-1 fs-16">Leaves Available - {{ new Date().getFullYear() }}</h4>
                        </div>
                    </div>
                    <b-table show-empty outlined small stacked="md" sort-icon-left :items="empLeavePolicyList" :fields="empLeavePolicyFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Leaves found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                </b-container>
            </b-overlay>
            <template v-if="!isLeaveApplication" #modal-footer>
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="closeDialog">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>Submitting..
                        </span>
                        <span v-else>Submit</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <TypeLookup :key="typePopupKey" ref="typeLookupModal" :projectTypeId="projectTypeId" :entity="typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        AttachmentComponent,
        Editor,
        TypeLookup,
    },
    props: {
        id:{type: String, default: 'leaveTimePopup'},
        leaveTypeCode: String,
        isWorkflowInitiator: Number,
        hasWorkflowConfig: Number,
        humanResourceData: Object,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: 'leave_time',

            typeEntity: 'leave_type',
            typeLookupTitle: 'Leave Type Settings',
            typePopupKey: 0,

            isLeaveApplication: true,

            leaveTimeData: {
                id: '',
                leaveTimeId: '',
                leaveTimeCalendarId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                leaveTypeTimeId: '',
                leaveTypeCode: '',
                leaveTypeId: '',
                subject: '',
                description: '',
                workPlan: '',
                workPlannedTo: '',
                fromDt: '',
                fromDate: '',
                fromTime: null,
                tillDt: '',
                tillDate: '',
                tillTime: null,
                startHalfDay: 1,
                endHalfDay: '',
                totalDays: '',
                totalHours: '',
                statusId: '',
                statusLookupId: 1,
                requestedById: localStorage.getItem("userId"),
                requestedToId: '',
                recommendedById: '',
                createdById: localStorage.getItem("userId"),
                calendarTypeId: 2,
                categoryName: 'calendar_category',
                categoryCode: '',
                isAllDayEvent: false,
            },

            leavePolicyData: {},

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],

            users: [],
            leaveTypes: [],
            leaveTimeTypes: [],

            empLeavePolicyList: [],
            empLeavePolicyFields: [
                { key: 'index', label: 'SN' },
                // { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'leaveType',label: 'Leave Type', sortable: true,sortDirection: 'desc' },
                { key: 'daysPerYear',label: 'Total Leaves / Year', sortable: true,sortDirection: 'desc' },
                { key: 'leaveTakenCount',label: 'Leaves Taken', sortable: true,sortDirection: 'desc' },
                { key: 'leavesRemaining',label: 'Leaves Remaining', sortable: true,sortDirection: 'desc' },
            ],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools','lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            sortDesc: false,
            sortBy: '',
            sortDirection: 'asc',
        }
    },
    computed: {
        ermFiltered() {
            // Freezed items should not be shown
            return this.entityERMLookup.filter((erm)=> erm.isFreeze !== true);
        },
        dateDiff() {
            const date1 = new Date(this.leaveTimeData.fromDt);
            const date2 = new Date(this.leaveTimeData.tillDt);
            const diffTime = Math.abs(date2 - date1);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            let total = 0;
            if (this.leaveTimeData.fromDt == this.leaveTimeData.tillDt) {
                total = diffDays - (this.leaveTimeData.startHalfDay ==3 || this.leaveTimeData.startHalfDay ==2 ? 0.5 : 0 );
            } else {
                total = diffDays - (this.leaveTimeData.startHalfDay ==3 || this.leaveTimeData.startHalfDay ==2 ? 0.5 : 0 ) - (this.leaveTimeData.endHalfDay ==3 || this.leaveTimeData.endHalfDay ==2 ? 0.5 : 0 );
            }
            total = total ? total : 0;
            return total+1;
        },
        timeDiff: {
            get() {
                var timeStart = new Date("01/01/2007 " + this.leaveTimeData.fromTime);
                var timeEnd = new Date("01/01/2007 " + this.leaveTimeData.tillTime);
                if (Number(timeStart) && Number(timeEnd)) {
                    return this.leaveTimeData.totalHours = this.leaveTimeData.totalHours ? this.leaveTimeData.totalHours : ((timeEnd - timeStart)/3600000).toFixed(1);
                } else return this.leaveTimeData.totalHours = 0;
            },
            set(val) {
                this.leaveTimeData.totalHours = val;
            }
        },
        disableEndDay() {
            return !this.leaveTimeData.tillDt || this.leaveTimeData.fromDt == this.leaveTimeData.tillDt;
        }
    },
    mounted() {
        this.isLeaveApplication = this.leaveTypeCode== 'LVE' ? true : false;
        this.lookupData();
        this.getEmpLeavePolicies();
    },
    validations: {
        leaveTimeData: {
            subject: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(200)
            },
            leaveTypeId: {
                required: requiredIf(function (abc) {
                    return this.isLeaveApplication;
                }),
            },
            // description: {
            //     required,
            //     minLength: minLength(2),
            //     maxLength: maxLength(200)
            // },
            requestedToId: {
                required: requiredIf(function (abc) {
                    return this.isWorkflowInitiator != 1;
                }),
            },
            fromDt: {
                required
            },
            fromTime: {
                required: requiredIf(function (abc) {
                    return !this.isLeaveApplication;
                }),
            },
            tillTime: {
                required: requiredIf(function (abc) {
                    return !this.isLeaveApplication;
                }),
            },
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'leave_time_type',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/leavetime/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.leaveTypes = result.customTypeLookup;
                    this.leaveTimeTypes = result.typeLookup;
                    this.users = result.userLookup;
                    console.log(result);
                })
                .catch((error)=> {
                    this.showOverlay = false;
                });
        },
        getEmpLeavePolicies: function() {
            if(this.isLeaveApplication) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    humanResourceId: this.humanResourceData.id,
                    jobCategoryId: this.humanResourceData.jobCategoryId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/empleavepolicy/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.empLeavePolicyList = result.empLeavePolicyList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
                }
        },
        async saveData() {
            this.leaveTimeData.leaveTypeCode = this.leaveTypeCode;
            this.leaveTimeData.categoryCode = this.isLeaveApplication ? 'LVE' : 'PER';
            if (this.isLeaveApplication) {
                this.leaveTimeData.isAllDayEvent = true;
                // this.leaveTimeData.fromTime = null;
                // this.leaveTimeData.tillTime = null;
                this.leaveTimeData.totalDays = this.dateDiff;
            }
            else {
                this.leaveTimeData.fromDt = this.combineDateAndTime(this.leaveTimeData.fromDate, this.leaveTimeData.fromTime);
                this.leaveTimeData.tillDt = this.combineDateAndTime(this.leaveTimeData.fromDate, this.leaveTimeData.tillTime);
                this.leaveTimeData.fromTime = this.getTimeValue(this.leaveTimeData.fromDt);
                this.leaveTimeData.tillTime = this.getTimeValue(this.leaveTimeData.tillDt);
                this.leaveTimeData.startHalfDay = '';
                this.leaveTimeData.endHalfDay = '';
                this.leaveTimeData.totalDays = this.leaveTimeData.totalHours ? this.leaveTimeData.totalHours : this.timeDiff;
            }
            console.log(this.leaveTimeData)
            this.$v.leaveTimeData.$touch();
            if (!this.$v.leaveTimeData.$invalid) {
                this.showOverlay = true;
                // this.requirementSpec.hasAttachment = await this.$refs.attachmentControl.hasAttachment();
                let result;
                if (this.elements.editMode) {
                    result = await this.updateLeaveApplication();
                } else {
                    result = await this.saveLeaveApplication();
                    // await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }
                this.$emit('refresh-leavetime');

                this.showOverlay = false;
                if (result.data.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.showOverlay = false;
                    this.$bvModal.hide(this.id);
                    this.clearData();
                    this.closeDialog();
                }
            }
            // else this.focusFirstError('requirementSpec');
        },
        saveLeaveApplication: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/leavetime/add', this.leaveTimeData)
        },
        updateLeaveApplication: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/leavetime/update', this.leaveTimeData)
        },
        showEdit: function(id, leaveTimeCalendarId) {
            // called from parent
            this.showOverlay = true;
            this.elements.editMode = true;
            axios.get(this.$store.getters.getAPIBasePath + '/resource/leavetime/view/' + id)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        let leaveTimeData = result.leaveTime;
                        this.leaveTimeData.leaveTimeCalendarId = leaveTimeCalendarId;
                        
                        this.leaveTimeData.id = leaveTimeData.id;
                        this.leaveTimeData.leaveTimeId = leaveTimeData.leaveTimeId;
                        this.leaveTimeData.vhextAccountId = leaveTimeData.vhextAccountId;
                        this.leaveTimeData.leaveTimeTypeId = leaveTimeData.leaveTimeTypeId;
                        this.leaveTimeData.leaveTypeCode = leaveTimeData.leaveTypeCode;
                        this.leaveTimeData.leaveTypeId = leaveTimeData.leaveTypeId;
                        this.leaveTimeData.subject = leaveTimeData.subject;
                        this.leaveTimeData.description = leaveTimeData.description;
                        this.leaveTimeData.workPlan = leaveTimeData.workPlan;
                        this.leaveTimeData.workPlannedTo = leaveTimeData.workPlannedTo;
                        this.leaveTimeData.startHalfDay = leaveTimeData.startHalfDay;
                        this.leaveTimeData.endHalfDay = leaveTimeData.endHalfDay;
                        this.leaveTimeData.totalDays = leaveTimeData.totalDays;
                        this.leaveTimeData.statusId = leaveTimeData.statusId;
                        this.leaveTimeData.requestedById = leaveTimeData.requestedById;
                        this.leaveTimeData.requestedToId = leaveTimeData.requestedToId;
                        this.leaveTimeData.recommendedById = leaveTimeData.recommendedById;
                        this.leaveTimeData.createdById = leaveTimeData.createdById;
                        this.leaveTimeData.calendarTypeId = leaveTimeData.calendarTypeId;
                        this.leaveTimeData.categoryName = leaveTimeData.categoryName;
                        this.leaveTimeData.categoryCode = leaveTimeData.categoryCode;
                        if (leaveTimeData.leaveTypeCode == 'PER') {
                            this.leaveTimeData.fromDate = this.getDateValue(leaveTimeData.fromDt);
                            this.leaveTimeData.fromTime = this.getTimeValue(leaveTimeData.fromDt);
                            this.leaveTimeData.tillDate = this.getDateValue(leaveTimeData.tillDt);
                            this.leaveTimeData.tillTime = this.getTimeValue(leaveTimeData.tillDt);
                        }
                        else if(leaveTimeData.leaveTypeCode == 'LVE') {
                            this.leaveTimeData.fromDt = this.getDateValue(leaveTimeData.fromDt);
                            this.leaveTimeData.tillDt = this.getDateValue(leaveTimeData.tillDt);
                            this.leaveTimeData.totalHours = leaveTimeData.totalDays;
                        }
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.leaveTimeData.id = '';
            this.leaveTimeData.leaveTimeId = '';
            this.leaveTimeData.leaveTimeCalendarId = '';
            this.leaveTimeData.leaveTypeCode = '';
            this.leaveTimeData.leaveTypeId = '';
            this.leaveTimeData.subject = '';
            this.leaveTimeData.description = '';
            this.leaveTimeData.workPlan = '';
            this.leaveTimeData.workPlannedTo = '';
            this.leaveTimeData.fromDt = '';
            this.leaveTimeData.fromTime = null;
            this.leaveTimeData.tillDt = '';
            this.leaveTimeData.tillTime = null;
            this.leaveTimeData.startHalfDay = '';
            this.leaveTimeData.endHalfDay = '';
            this.leaveTimeData.totalDays = '';
            this.leaveTimeData.requestedById = localStorage.getItem("userId");
            this.leaveTimeData.requestedToId = '';
            this.leaveTimeData.recommendedById = '';
            this.leaveTimeData.createdById = localStorage.getItem("userId");
            this.$v.leaveTimeData.$reset();
        },
        getHumanResourceData: function() {
            if(this.humanResourceData.jobCategoryId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    jobCategoryId: this.humanResourceData.jobCategoryId,
                    leaveTypeId: this.leaveTimeData.leaveTypeId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/empleavepolicy/view', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.leavePolicyData = result.leavePolicy;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    })
            }
        },
        resetEndHalfDay() {
            if (this.getDateValue(this.leaveTimeData.tillDt) == this.getDateValue(this.leaveTimeData.fromDt)) {
                this.leaveTimeData.endHalfDay = this.leaveTimeData.startHalfDay;
            }
            else {
                this.leaveTimeData.endHalfDay = this.leaveTimeData.endHalfDay==3 ? 1 : this.leaveTimeData.endHalfDay;
                this.leaveTimeData.startHalfDay = this.leaveTimeData.startHalfDay==2 ? 1 : this.leaveTimeData.startHalfDay;
            }

            if (!this.leaveTimeData.tillDt) {
                this.leaveTimeData.endHalfDay = this.leaveTimeData.startHalfDay;
                this.leaveTimeData.tillDt = this.leaveTimeData.fromDt;
            }
            if (!this.leaveTimeData.fromDt) {
                this.leaveTimeData.endHalfDay = '';
                this.leaveTimeData.tillDt = '';
            }
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        },
    }
}
</script>