<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Requirement Development</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="requirementSpecs" @row-selected="onRowSelected" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(requirementSpecification)="data">
                                    <p v-html="data.value"></p>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Assign Task" @click="assignTask(data.item)" v-b-modal.ermAssignments><i class="fa fa-user-plus pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No RequirementSpec found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="requirementSpecs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <StatusTracker ref="statusUpdate" :statusUpdate="statusUpdate" @updateParent="getRequirementSpecs"></StatusTracker>
                    </div>
                </div>
            </div>
        </div>
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
        <Dialog id="deleteRequirementSpec" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Requirement Spec" />
        <ReqSpecDetailPopup v-if="showDialog" ref="reqSpecDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import StatusTracker from '@/components/common/StatusTracker.vue';
import ReqSpecDetailPopup from '@/components/popup/itemdetail/ReqSpecDetailPopup.vue';
export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        StatusTracker,
        QuickTaskPopup,
        ReqSpecDetailPopup
    },
    data() {
        return {
            showDialog: false,
            elements: {
                editMode: false,
                visible: false,
            },
            vhextAccountId: '',
            projectId: '',
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            entity: 'requirement_spec',
            userId: localStorage.getItem("userId"),

            notEnabled: true,
            requirementStatuses: [],
            requirementSpec: {
                id: '',
                requirementSpecId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                title: '',
                requirementTypeId: '',
                requirementSpecification: '',
                preparedById: '',
                preparedBy: '',
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                dueDate: '',
                statusId: '',
            },
            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                refEntityId: '',
                refEntity: 'requirement_spec',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },
            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',
                statusId: 1,
            },
            detailViewData: {},
            referencePopupId: 0,
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            //requirementSpec: [],
            requirementSpecs: [],
            fields: [
                { key: 'requirementSpecId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Requirement Type', sortable: true, sortDirection: 'desc' },
                { key: 'requirementSpecification', label: 'Requirement Specification', tdClass: 'table-col-desc-size', sortDirection: 'desc' },
                { key: 'priority', label: 'Priority', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getRequirementSpecs();
    },
    validations: {
        requirementSpec: {
            statusId: {
                required,

            }
        },
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");

            this.requirementSpec.vhextAccountId = this.vhextAccountId;
            this.requirementSpec.projectId = this.projectId;
            this.requirementSpec.entityRecordMasterId = this.entityRecordMasterId;
            this.requirementSpec.preparedById = this.userId;

            this.statusUpdate.projectId = this.projectId;
            this.statusUpdate.entityRecordMasterId = this.entityRecordMasterId;
            //            this.statusUpdate.refEntity= this.entity;
            this.statusUpdate.statusById = this.userId;


        },

        getRequirementSpecs: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/list', data)
                .then((response) => {
                    let result = response.data;
                    this.requirementSpecs = result.requirementSpecList;
                    if (this.requirementSpecs != null && this.requirementSpecs.length > 0) {
                        this.totalRows = this.requirementSpecs.length;
                        this.assignStatusUpdate(this.requirementSpecs[0]);
                        console.log(result);
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.requirementStatuses = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    this.users = result.userLookup;
                    console.log(result);
                })
        },
        saveData: function() {
            this.$v.requirementSpec.$touch();
            if (!this.$v.requirementSpec.$invalid) {
                if (this.elements.editMode) {
                    this.updateRequirementSpec();
                } else {
                    this.saveRequirementSpec();
                }
            }
        },
        saveRequirementSpec: function() {

            console.log(this.requirementSpec);
            this.$v.requirementSpec.$touch();
            if (!this.$v.requirementSpec.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/add', this.requirementSpec)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getRequirementSpecs();
                            this.clearData();
                        }
                    })
            }
        },
        viewRequirementSpec: function(id) {
            this.clearData();
            axios.get(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.requirementSpec = result.requirementSpec;
                    }
                    console.log(this.requirementSpec);

                })
            this.elements.editMode = false;
        },
        cancelEditMode: function() {
            this.viewRequirementSpec(this.requirementSpec.id);
        },
        deleteRequirementSpec: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRequirementSpecs();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.reqSpecDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.requirementSpec.requirementSpecId = '';
            this.requirementSpec.title = '';
            this.requirementSpec.requirementTypeId = '';
            this.requirementSpec.requirementSpecification = '';
            this.requirementSpec.preparedById = '';
            this.requirementSpec.preparedBy = '';
            this.requirementSpec.preparedDt = '';
            this.requirementSpec.priorityId = '';
            this.requirementSpec.dueDate = '';
            this.requirementSpec.statusId = '';
            this.requirementSpec.hasReference = '';
            this.requirementSpec.hasRefLink = '';
            this.requirementSpec.hasAttachment = '';
            this.requirementSpec.preparedDt = new Date().toISOString().substr(0, 10);
            this.$v.requirementSpec.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data.id, data.requirementSpecId);

                //this.viewRequirementSpec(data.id);

                //this.elements.editMode = false;
                this.elements.showDetail = true;
                this.requirementSpec.id = data.id;
                this.requirementSpec.requirementSpecId = data.requirementSpecId;
                this.requirementSpec.title = data.title;
                this.requirementSpec.requirementTypeId = data.requirementTypeId;
                this.requirementSpec.type = data.type;
                this.requirementSpec.requirementSpecification = data.requirementSpecification;
                this.requirementSpec.preparedById = data.preparedById;
                this.requirementSpec.preparedBy = data.preparedBy;
                this.requirementSpec.preparedDt = data.preparedDt;
                this.requirementSpec.priorityId = data.priorityId;
                this.requirementSpec.priority = data.priority;
                this.requirementSpec.dueDate = data.dueDate;
                this.requirementSpec.statusId = data.statusId;
                this.requirementSpec.status = data.status;
                this.requirementSpec.hasReference = data.hasReference;
                this.requirementSpec.hasRefLink = data.hasRefLink;
                this.requirementSpec.hasAttachment = data.hasAttachment;
                this.requirementSpec.createdDt = data.createdDt;
                this.requirementSpec.lastUpdatedDt = data.lastUpdatedDt;
                this.assignStatusUpdate(data);
            }
        },
        assignStatusUpdate: function(data) {
            this.$refs.statusUpdate.clearData();
            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.refRecordId = data.id;
            this.statusUpdate.refId = data.requirementSpecId;
            this.statusUpdate.refRecordTitle = data.title;
            this.statusUpdate.statusById = this.userId;
            this.statusUpdate.statusId = data.statusId;
            this.statusUpdate.status = data.status;
            //call child component method
            this.$refs.statusUpdate.onTitleChange();
        },
        assignTask: function(data) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = this.entity;
            this.masterData.refErdTitle = data.title;
            this.masterData.refErdDescription = data.requirementSpecification;
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.masterData.refErmId, this.masterData.refEntityId);
        }   
    }

}
</script>