<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <DocumentMasterList :ermMasterInfo="ermMasterInfo"></DocumentMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DocumentMasterList from '../../components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DocumentMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Doc Folders",
                entity: 'document_folder_master',
                refEntity: 'document_folder',
                tooltip: 'New Folder',
                redirectURLName: 'landocs',
                newEntityMasterURL: 'pdcmas',
                redirectURL: '/pdcmas',
                actionName: 'Documents',
                previewURLName: 'lanpdcrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>