<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="row">
                <div class="col-12 text-left">
                    <div class="card-body p-0 mb-1">
                        <div class="row">

<!--                             <div class="col-12 col-sm-12">
                                <div class="card mb-2">
                                    <div class="col-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Title</label>
                                    </div>
                                    <div class="col-12 px-0">
                                        <h2 class="text-center">{{ popupData.title }}</h2>
                                        <label class="py-2 pl-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                    </div>
                                </div>
                            </div> -->
<!--                             <div class="col-2 col-md-2 float-right">
                                <div class="float-right">
                                    <h6 class=""><label class="" title="Status">{{ popupData.status }}</label></h6>
                                </div>
                            </div>                             -->
                            <div class="col-12 col-sm-12">
                                <div class="card-body mb-1">
                                        <h2 class="text-center mt-4">{{ popupData.title }}</h2>
                                        <div class="row">
                                            <div class="col-12 col-md-12 m-0">
                                                <label class="p-2 mt-0" v-html="popupData.policy"></label>
                                            </div>
                                        </div>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12">
                                <div class="row mb-2">
                                    <div class="col-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Prepared By</label>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.preparedBy}}</label>
                                    </div>
                                    <div class="col-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Date</label>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.createdDt | formatDate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
// import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    name: 'policyDocumentDetailComponent',
    props: {
        detailViewData: Object,
        // attachmentData: Object
    },
    components: {
        // AttachmentView,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'policy_document',
            popupData: {},
            attachmentList: {},
            attachmentLength: 0,

        }
    },
    computed: {
        // itemDetailTitle() {
        //     if(this.popupData.leaveTypeCode)
        //         return this.popupData.leaveTypeCode=='LVE' ? 'Leave Application' : 'Permission Application';
        // }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.showOverlay = true;
            console.log(id, refEntity);
            if (refEntity === this.entity) {
            console.log(id, refEntity);
                axios.get(this.$store.getters.getAPIBasePath + '/document/policydocument/view/' + id)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.policyDocument) {
                            this.popupData = result.policyDocument;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        console.log(error);
                    });
            } else {
                this.popupData = this.detailViewData;
                this.showOverlay = false;
            }
        },
        // getAttachmentLength(length) {
        //     this.attachmentLength = length;
        // },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>