<template>
    <div class="htmlDetailViewPopup">
        <b-modal id="htmlDetailView" ref="htmlDetailViewRef" size="xl" modal-class="htmlDetailViewRef" ok-only :title="htmlDetailViewTitle">
            <div class="p-0">
                <b-card no-body class="p-2">
                    <div><span v-html="htmlData"></span></div>
                    <div class="text-center" v-if="htmlData == ''"><h6>No description found</h6></div>
                </b-card>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        htmlData: String,
        htmlDetailViewTitle: String
    },
}
</script>