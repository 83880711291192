<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-sm-4">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">Manage User Roles & Responsibilities</h4>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="form-row">
                    <div class="col-lg-12 col-xl-12">
                        <div class="card mb-1">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-lg-5 col-md-6">
                                        <div class="form-row view-filter input-group-label">
                                            <div class="col-6 col-sm-6">
                                                <b-input-group prepend="User Role" id="customUserRoleId" class="">
                                                    <select v-model="customUserRoleId" @change="onUserRoleSelected();" class="form-control">
                                                        <option v-for="(info, index) in customUserRoleLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <b-input-group prepend="User Type" id="userTypeId" class="">
                                                    <!-- <select v-model="userTypeData.userTypeId" disabled @change="onUserTypeChange;userTypeId=userTypeData.userTypeId;" class="form-control">
                                                        <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id">{{info.value}}</option>
                                                    </select> -->
                                                    <input type="text" v-model="userType" disabled class="form-control" name="">
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-3 text-md-left text-right">
                                        <b-button class="addnew-quicktassk-btn mr-1" @click="addCustomUserRoleKey++" v-b-modal.addCustomUserRoleModal variant="outline-secondary" size="sm" title="Add Custom User Role"><i class="fa fa-plus-circle pr-1"></i>Create User Role</b-button>
                                        <b-button class="addnew-quicktassk-btn" @click="manageCustomUserRoleKey++" v-b-modal.manageCustomUserRoleModal variant="outline-secondary" size="sm" title="Manage Custom User Role"><i class="fa fa-cogs pr-1"></i>Manage User Role</b-button>
                                    </div>
                                    <div class="col-lg-3 col-md-3 text-right">
                                        <b-button class="addnew-quicktassk-btn ml-2" @click="addUserRoleStandardKey++" v-b-modal.addUserRoleStandardModal variant="outline-secondary" size="sm" title="Use Standard User Role"><i class="fa fa-cog pr-1"></i>Use Standard Roles</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-5">
                        <div class="card h-100">
                            <div class="card-body px-2 pt-1">
                                <span class="position-absolute mt-2 ml-auto" style="right: 1rem;">
                                    <b-button v-if="elements.visible" class="addnew-quicktassk-btn" v-b-toggle.accordion-module variant="outline-danger" size="sm" title="Close"><i class="fa fa-remove pr-1" aria-hidden="true"></i>Close</b-button>
                                    <b-button v-else class="addnew-quicktassk-btn" v-b-toggle.accordion-module variant="outline-secondary" size="sm" title="Add Module"><i class="fa fa-plus-circle pr-1" aria-hidden="true"></i>Add Modules [{{this.totalStdUserModules}}]</b-button>
                                </span>

                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Modules">
                                        <b-card no-body class="">
                                            <b-collapse id="accordion-module" v-model="elements.visible" class="p-1" role="tabpanel">
                                                <div class="row view-filter">
                                                    <div class="text-center float-left mx-3">
                                                        <b-input-group prepend="VMX Service" id="customUserRoleId" class="">
                                                            <select v-model="vmxServiceId" class="form-control">
                                                                <option value="">All</option>
                                                                <option v-for="(info, index) in customVmxServices" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </b-input-group>
                                                    </div>

                                                    <div class="float-right">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filterRole" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <b-table class="border-bottom" outlined show-empty small head-variant=:primary sticky-header="45vh" sort-icon-left :items="filteredModules" :fields="stdUserModuleFields" responsive="sm" :filterIncludedFields="filterOn" :filter="filterRole" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUserRef">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(module)="data">
                                                        <div>{{ data.value }}</div>
                                                        <div class="text-muted">{{ data.item.vmxService }}</div>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <button type="submit" title="Add Module" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1 px-2" @click="cmpUserModuleManage(data.item.moduleId,1)">
                                                            <i class="fa fa-plus-circle pr-1"></i> Add Module
                                                        </button>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No User Roles found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                                <div class="col-4 float-left pl-0 mb-2">
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalStdUserModules}} Items</span>
                                                </div>
                                            </b-collapse>
                                        </b-card>
                                        <div :class="{'mt-3' : elements.visible}">
                                            <label class="mb-1">Added Modules [{{this.totalSelUserModules}}]</label>
                                            <b-table ref="userModuleTable" v-model="mainTableArray" class="border-bottom" outlined show-empty small head-variant=:primary sticky-header="60vh" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="userRoles" :fields="customUserModuleFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(module)="data">
                                                    <div>{{ data.value }}</div>
                                                    <div class="text-muted">{{ data.item.vmxService }}</div>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <template v-if="!isPrimary">
                                                        <button v-if="!data.item.isBasic" type="submit" title="Remove Module" class="btn btn-outline-danger sbtn btn-xs py-1 mx-1 px-2" v-b-modal.deleteUserModuleDialog  @click="deleteUserModuleDialog(data.item.moduleId)">
                                                            <i class="fa fa-remove pr-1"></i>Remove Module
                                                        </button>
                                                    </template>
                                                    <template v-else>
                                                        <button v-if="!data.item.isBasic" type="submit" title="Remove Module" class="btn btn-outline-danger sbtn btn-xs py-1 mx-1 px-2" v-b-modal.deleteUserModuleDialog  @click="deleteUserModuleDialog(data.item.moduleId)">
                                                            <i class="fa fa-remove pr-1"></i>Remove Module
                                                        </button>
                                                    </template>
                                                    <button type="submit" title="Reset Module Menus" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1 px-2" @click="cmpUserModuleManage(data.item.moduleId,2)">
                                                        <i class="fa fa-repeat pr-1"></i>Reset
                                                    </button>                                                    
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Modules found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                            <div class="col-4 float-left pl-0 mb-2">
                                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalSelUserModules}} Items</span>
                                            </div>                                            
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7">
                        <b-overlay :show="elements.visible" opacity="0.4" spinner-variant="primary">
                            <b-overlay :show="showDetOverlay" opacity="0.4" spinner-variant="primary">
                                <div class="card mb-4">
                                    <div class="card-body p-1 mx-2">
                                        <b-tabs content-class="mt-3" lazy>
                                            <b-tab :title="selectedModule + ' Module' + ' - Menus'">
                                                <div class="row view-filter">
                                                    <div class="col-sm-12">
<!--                                                         <div class="float-right">
                                                            <div class="dataTables_length">
                                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                    <b-form-select v-model="perMenuPage" id="perPageSelect" size="sm" :options="pageMenuOptions"></b-form-select>
                                                                </b-form-group>
                                                            </div>
                                                        </div> -->
                                                        <div class="float-left">
                                                            <div class="dataTables_filter">
                                                                <label>
                                                                    <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                                <b-table ref="userTable" outlined show-empty small head-variant=:primary sticky-header="60vh" selectable :select-mode="selectMode" sort-icon-left :items="companyUserModuleMenuList" :fields="moduleMenuFields" responsive="sm" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onMenuFiltered">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>      
                                                    <template v-slot:cell(mainMenu)="data">
                                                        <div v-if="data.item.subModule">
                                                            {{data.item.subModule}} / {{data.item.mainMenu}}
                                                        </div>
                                                        <div v-else>
                                                            {{data.item.mainMenu}}
                                                        </div>
                                                    </template>                                                                                          
                                                    <template v-slot:cell(menuExist)="data">
                                                        <span v-if="data.item.basic" class="text-success" title="Basic Privilege" ><i class="fa fa-check"></i></span>
                                                        <span v-else title="Change Menu Privilege" >
                                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege(data.item)">
                                                            </b-form-checkbox>
                                                        </span>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Item found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalMenuRows}} Items</span>
<!--                                                 <b-pagination v-if="companyUserModuleMenuList.length!=0" pills v-model="currentMenuPage" :total-rows="totalMenuRows" :per-page="perMenuPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </div>
                            </b-overlay>
                            <template #overlay>
                                <div class="text-center">
                                  
                                </div>
                            </template>
                        </b-overlay>
                    </div>                    

                </div>
            </b-overlay>
        </div>
        
        <CustomUserRoleAdd :key="addCustomUserRoleKey" @refresh-lookup="customUserRoleRefresh" />
        <CustomUserRoleManage :key="manageCustomUserRoleKey" @refresh-lookup="customUserRoleRefresh" />
        <AddUserRoleStandard :key="addUserRoleStandardKey" ref="addUserRoleStandardModal" :userRoleTypeId="userRoleTypeId" @refresh-lookup="getCustomUserModules();lookupData();addCustomUserRoleKey++;manageCustomUserRoleKey++;" />
        <DeleteComponent id="deleteUserModuleDialog" :onYes="onDeleteConfirmation" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to Remove Module Privilege?" />
<!--         <DialogComponent id="resetUserRoleDialog" :onYes="onResetDialogConfirmation" :returnParams="dialogBoxParam" title="Module Reset Confirmation" message="Are you sure to Reset User Roles to Default?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import CustomUserRoleAdd from '@/components/popup/lookup/CustomUserRoleAdd.vue';
import CustomUserRoleManage from '@/components/popup/lookup/CustomUserRoleManage.vue';
import AddUserRoleStandard from '@/components/popup/lookup/AddUserRoleStandard.vue';
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        Multiselect,
        CustomUserRoleAdd,
        CustomUserRoleManage,
        AddUserRoleStandard,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            loadOnce: true,
            elements: {
                visible: false,
            },

            addCustomUserRoleKey: 0,
            manageCustomUserRoleKey: 100,
            addUserRoleStandardKey: 200,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entity: 'custom_user_role',

            customUserRoleId: '',
            vmxServiceId: '',
            moduleId: '',

            userRoleTypeId: 1,

            selectedModule: '',            
            defaultCheckbox: true,

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            userTypeData:{
                userTypeId: '',
            },

            userRole: {
                id: '',
                userRoleId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userTypeId: '',
                customUserRoleId: '',
                vmxServiceList: [],
                moduleId: '',
                role: '',
                typeCode: '',
                typeClass: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            mainTableArray: [],

            customUserRoleLookup: [],
            vmxAccountUserTypes: [],
            customVmxServices: [],
            stdUserModules: [],
            customUserModules: [],
            companyUserModuleMenuList: [],
            stdUserModuleFields: [
                {key: 'index',label: 'SN'},
                // { key: 'vmxService', label: 'Service', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Standard Module', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            customUserModuleFields: [
                {key: 'index',label: 'SN'},
                // { key: 'vmxService', label: 'Service', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'User Module', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],
            moduleMenuFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Sub Module / Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Sub Menu', sortable: true, sortDirection: 'desc' , tdClass: 'text-left' },
                // { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' , tdClass: 'text-left' },
                { key: 'menuExist', label: 'Active', sortable: true, sortDirection: 'desc' },
            ],            

            deleteParam: {
                id: 0
            },
            dialogBoxParam: {
                id: 0,
                rowData:[],
            },

            selectMode: 'single',
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            totalRowsRole: 0,
            currentPageRole: 1,
            totalStdUserModules: 0,
            totalSelUserModules: 0,
            currentPageUserRole: 0,

            totalMenuRows: 0,
            currentMenuPage: 1,
            perMenuPage: 10,

            perPageRole: 5,
            filterRole: null,
            pageOptions: [5,10, 15, 20, 25],
            pageMenuOptions: [10, 15, 20, 25],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            userFilter: null,
            filterOn: [],
        }
    },
    computed: {
        filteredModules() {
            let filteredModules = [];

            let filterArray1 = [];
            filterArray1 = this.stdUserModules.filter((data) => !this.isUserRoleExists(data.moduleId));

            let filterArray2 = [];
            if (this.userTypeData.userTypeId) {
                filterArray2 = filterArray1.filter((data) => data.userTypeId == this.userTypeData.userTypeId);
            }
            else filterArray2 = [];

            let filterArray3 = [];
            if (this.vmxServiceId) {
                filterArray3 = filterArray1.filter((data) => data.vmxServiceId == this.vmxServiceId);
            }
            else filterArray3 = filterArray2;

            filteredModules = filterArray3;

            console.log(filteredModules);

            this.totalStdUserModules = filteredModules.length;
            return filteredModules;
        },
        userRoles() {
            let userRoles = [];
            if (this.customUserRoleId && this.customUserModules.length>0) {
                userRoles = this.customUserModules.filter((data) => data.customUserRoleId == this.customUserRoleId);
            }
            this.$nextTick(() => {
                this.selectBTableRowById(this.moduleId, this.mainTableArray, 'userModuleTable', 'moduleId');
            })
            this.totalSelUserModules=userRoles.length;
            return userRoles;
        },
        customUserRoles() {
            return this.customUserRoleLookup.filter(data => data.userTypeId == this.userTypeData.userTypeId);
        },
        userTypeId: {
            get() {
                let userTypeData = this.customUserRoleLookup.find(data => data.id == this.customUserRoleId);
                let userTypeId = userTypeData ? userTypeData.userTypeId : '';
                return userTypeId;
            },
            set(value) {
                this.$store.state.userManagementData.userTypeId = value;
            }
        },
        userRoleId: {
            get() {
                return this.$store.state.userManagementData.userRoleId;
            },
            set(value) {
                this.$store.state.userManagementData.userRoleId = value;
            }
        },
        customUserRole() {
            const userRole = this.customUserRoleLookup.find(item => item.id == this.userRoleId);
            return userRole ? userRole.value : '';
        },
        isPrimary() {
            const userRole = this.customUserRoleLookup.find(item => item.id == this.customUserRoleId);
            return userRole ? userRole.isPrimary : false;
        },
        userType() {
            const userTypeData = this.vmxAccountUserTypes.find(item => item.id == this.userTypeData.userTypeId);
            return userTypeData ? userTypeData.value : '';
        },
    },
    mounted() {
        this.userTypeData.userTypeId = this.$store.state.userManagementData.userTypeId;
        this.customUserRoleId = this.$store.state.userManagementData.userRoleId;
        this.lookupData();
        this.getCustomUserModules();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userRoleTypeId: 1,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserroleresp/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.customUserRoleLookup = result.customUserRoleLookup;
                    this.customVmxServices = result.customVmxServiceLookup;
                    this.vmxAccountUserTypes = result.vmxAccountUserTypeLookup;

                    this.userTypeData.userTypeId = this.userTypeId;
                    this.getStdUserModules();

                    // this.userTypeData.userTypeId = this.vmxAccountUserTypes.length > 0 ? this.vmxAccountUserTypes[0].id : '';

                    // this.userRoleData.customUserRoleId = this.customUserRoles.length > 0 ? this.customUserRoles[0].id : '';
                    // this.userRoleData.customUserRole = this.customUserRoles.length > 0 ? this.customUserRoles[0].value : '';

                    // this.$nextTick(() => {
                    //     this.onUserRoleSelected();
                    // })

                })
        },
        getStdUserModules: function() {
            if (this.userTypeData.userTypeId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    userTypeId: this.userTypeData.userTypeId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/cmpusertypemodule/list',data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.stdUserModules = result.customUserTypeModuleList;
                        this.totalRowsRole = this.filteredModules.length;
                        console.log(this.stdUserModules);
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        getCustomUserModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/cmpusermodule/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.customUserModules = result.cmpUserModules;
                    console.log(this.customUserModules);
                    // this.onUserRoleSelected();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getModuleMenuList: function(moduleId) {
            if (this.customUserRoleId && this.userTypeData.userTypeId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    customUserRoleId: this.customUserRoleId,
                    userTypeId: this.userTypeData.userTypeId,
                    moduleId: moduleId,
                    userRoleTypeId: 1,
                }
                
                console.log(data);

                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/cmpusermodulemenu/list', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        console.log(this.showOverlay, result)
                        this.companyUserModuleMenuList = result.companyUserModuleMenuList;
                        this.totalMenuRows = this.companyUserModuleMenuList.length;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        assignPrivilege(data) {

            if (data.menuExist == "true") {
                this.actionState = 0;
            }
            else if (data.menuExist == "false") {
                this.actionState = 1;
            }

            this.$nextTick(() => {
                this.managePrivilege(data.id);
            })

        },
        managePrivilege: function(standardMenuId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                customUserRoleId: this.customUserRoleId,
                standardMenuId: standardMenuId,
                actionState: this.actionState,
                createdById: this.userId,
            }

            console.log(data);

            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/cmpusermenuprivilege/change', data)
            .then((response) => {
                this.showDetOverlay = false;
                let result = response.data;
                if (result.status == "OK") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    // this.clearData();
                    this.getCustomUserModules();
                    // this.getModuleMenuList(this.moduleId);
                }
            })
            .catch(error => {
                this.showDetOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        deleteUserModuleDialog: function(id) {
            this.deleteParam.id = id;
        },
        onDeleteConfirmation: function() {
            this.cmpUserModuleManage(this.deleteParam.id,0);
        },
        cmpUserModuleManage(moduleId,actionState) {

            let data = {
                vhextAccountId: this.vhextAccountId,
                customUserRoleId: this.customUserRoleId,
                moduleId: moduleId,
                actionState: actionState,
                createdById: this.userId,
            }

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/cmpusermodule/manage', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "OK") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
            // this.$nextTick(() => {
            //     this.selectBTableRowById(this.moduleId, this.mainTableArray, 'userModuleTable', 'moduleId');
            // })
                    this.moduleId = moduleId;

                    // this.clearData();
                    this.getStdUserModules();
                    this.getCustomUserModules();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRole = filteredItems.length
            this.currentPageRole = 1
        },
        onFilteredUserRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalStdUserModules = filteredItems.length
            this.currentPageUserRole = 1
        },
        onMenuFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalMenuRows = filteredItems.length
            this.currentMenuPage = 1
        },

        onUserTypeChange: function() {
            this.customUserRoleId = this.customUserRoleLookup.length > 0 ? this.customUserRoleLookup[0].id : '';
            this.onUserRoleSelected();
        },
        onUserRoleSelected: function() {
            // this.elements.visible = false;
            // alert(this.userTypeId);
            this.userRoleId = this.customUserRoleId;
            this.userTypeData.userTypeId = this.userTypeId;
            this.getStdUserModules();
            if (this.userRoles.length>0) {
                this.$nextTick(() => {
                    this.selectBTableRowById(null, this.mainTableArray, 'userModuleTable', 'moduleId');
                })
            }
            else {
                this.selectedModule = '';
                this.moduleId = '';
                this.companyUserModuleMenuList = [];
                this.totalMenuRows = this.companyUserModuleMenuList.length;
            }
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.selectedModule = data.module;
                this.moduleId = data.moduleId;
                this.getModuleMenuList(data.moduleId);
            }
        },
        customUserRoleRefresh(customUserRoleId) {
            this.getCustomUserModules();
            this.lookupData();
            this.addUserRoleStandardKey++;
            if (customUserRoleId) {
                this.customUserRoleId = customUserRoleId;
                this.onUserRoleSelected();
            }
        },
        isUserRoleExists(moduleId) {
            let result = false;

            if (this.userRoles.length>0) {
                let data = this.userRoles.find(data => data.moduleId == moduleId);
                result = data ? true : false;
            }
            return result;
        },
    }
}
</script>