<template>
    <div>
        <b-modal ref="ermCopyModal" size="md" id="ermCopyModal" @hide="clearData" header-class="py-3" body-class="pt-3" footer-class="py-3 px-3" :title="'Document Copy - ' + (masterData.title ? masterData.title : '')" :no-close-on-backdrop="true" >
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <!-- <button type="button" @click="hide()" aria-label="Close" class="close float-right mt-2">×</button> -->
                <b-tabs v-model="tabIndex" content-class="mt-4">
                    <b-tab title="Intra Company">
                        <b-container fluid>
                            <b-row>
                                <b-col cols="6">
                                    <label for="company">Project</label>
                                    <!-- <div class="required-field-block" :class="{ 'is-invalid': $v.ermCopyData.toProjectId.$error }"> -->
                                        <select v-model="ermCopyData.toProjectId" @change="getUserLookup(ermCopyData.toVhextAccountId,ermCopyData.toProjectId)" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in projectList" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <!-- <div class="required-icon"></div> -->
                                    <!-- </div> -->
                                    <!-- <label v-if="$v.ermCopyData.toProjectId.$error && !$v.ermCopyData.toProjectId.required" class="text-danger">Please select a target project</label> -->
                                </b-col>
                                <b-col cols="6">
                                    <label for="company">User</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.ermCopyData.toUserId.$error }">
                                        <select v-model="ermCopyData.toUserId" :class="{ 'is-invalid': $v.ermCopyData.toUserId.$error }" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.ermCopyData.toUserId.$error && !$v.ermCopyData.toUserId.required" class="text-danger">Please select a target User</label>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-tab>
                    <b-tab title="Inter Company">
                        <b-container fluid>
                            <b-row>
                                <b-col cols="6">
                                    <label for="company">Vhext Account</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.ermCopyData.toVhextAccountId.$error }">
                                        <select v-model="ermCopyData.toVhextAccountId" @change="getUserLookup(ermCopyData.toVhextAccountId);getProjectLookup(ermCopyData.toVhextAccountId)" :class="{ 'is-invalid': $v.ermCopyData.toVhextAccountId.$error }" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in vhextAccountList" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.ermCopyData.toVhextAccountId.$error && !$v.ermCopyData.toVhextAccountId.required" class="text-danger">Please select a target account</label>
                                </b-col>
                                <b-col v-if="ermCopyData.toVhextAccountId" cols="6">
                                    <label for="company">Project</label>
                                    <!-- <div class="required-field-block" :class="{ 'is-invalid': $v.ermCopyData.toProjectId.$error }"> -->
                                        <select v-model="ermCopyData.toProjectId" @change="getUserLookup(ermCopyData.toVhextAccountId,ermCopyData.toProjectId)" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in projectList" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <!-- <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.ermCopyData.toProjectId.$error && !$v.ermCopyData.toProjectId.required" class="text-danger">Please select a target project</label> -->
                                </b-col>
                                <b-col v-else cols="6">
                                    <label for="company">Project</label>
                                    <select disabled class="form-control">
                                        <option value="">Select</option>
                                    </select>
                                </b-col>
                                <b-col cols="12 mt-2">
                                    <label for="company">User</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.ermCopyData.toUserId.$error }">
                                        <select v-model="ermCopyData.toUserId" :class="{ 'is-invalid': $v.ermCopyData.toUserId.$error }" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.ermCopyData.toUserId.$error && !$v.ermCopyData.toUserId.required" class="text-danger">Please select a target User</label>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-tab>
                </b-tabs>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="ermCopy" class="float-right py-1 px-3 mr-2">Copy</b-button>
                    <b-button variant="secondary" :disabled="showOverlay" size="sm" class="float-right py-1 px-3 mr-2" @click="cancel()">Cancel</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    props: {
        masterData: Object,
        // ermId: Number,
        entity: String,
        // ermCopyModalTitle: String
    },
    components: {
        Multiselect
    },
    data() {
        return {
            showOverlay: false,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            ermCopyData: {
                id: '',
                ermCopyId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                title: '',
                refEntityId: '',
                entity: '',
                userId: localStorage.getItem("userId"),
                toVhextAccountId: localStorage.getItem("vhextAccountId"),
                toProjectId: '',
                toUserId: '',
                copyDt: '',
                copyMessage: '',
                sharedUrl: '',
                active: '',
                statusLookupId: 1,
            },

            vhextAccountList: [],
            projectList: [],
            users: [],
        }
    },
    validations: {
        ermCopyData: {
            toVhextAccountId: {
                required
            },
            // toProjectId: {
            //     required
            // },
            toUserId: {
                required
            }
        }
    },
    watch: {
        tabIndex(val) {
            if (val == 0) {
                this.ermCopyData.toVhextAccountId = localStorage.getItem("vhextAccountId");
                this.ermCopyData.toProjectId = '';
                this.ermCopyData.toUserId = '';
                this.getProjectLookup(this.ermCopyData.toVhextAccountId);
            }
        }
    },
    computed: {
        isSameUser() {
            return this.ermCopyData.vhextAccountId == this.ermCopyData.toVhextAccountId && this.userId == this.ermCopyData.toUserId;
        }
    },
    mounted() {
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/ermcopy/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.vhextAccountList = result.vhextAccountLookup;
                    this.projectList = result.projectLookup;
                    this.users = result.userLookup;
                })
        },
        getProjectLookup: function(vhextAccountId) {
            let data = {
                vhextAccountId: vhextAccountId
            }
            this.ermCopyData.toProjectId = '';
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/project', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.projectList = result.projectLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getUserLookup: function(vhextAccountId, projectId) {
            let data = {
                vhextAccountId: vhextAccountId,
                projectId: projectId
            }
            this.ermCopyData.toUserId = '';
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/user', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.users = result.userLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        ermCopy: function() {
            this.$v.ermCopyData.$touch();
            if (this.$v.ermCopyData.$invalid) {
                return;
            }
            if (this.isSameUser) {
                this.ermCopyData.statusLookupId = 2;
            }
            this.showOverlay = true;
            this.ermCopyData.entityRecordMasterId = this.masterData.refErmId;
            this.ermCopyData.title = this.masterData.title;
            this.ermCopyData.entity = this.masterData.refEntity;

            console.log(this.ermCopyData);
            axios.post(this.$store.getters.getAPIBasePath + '/common/ermcopy/doc/create', this.ermCopyData)
                .then((response) => {
                    let result = response.data;
                    console.log(response.data.status);
                    this.showOverlay = false;
                    if (result.status == "CREATED") {
                        if (this.isSameUser) {
                            this.$emit('refreshErmList');
                        }
                        this.$bvModal.hide('ermCopyModal');
                        iziToast.success({
                            title: 'Success',
                            message: (this.isSameUser ? 'Document Copied' : result.message),
                            position: 'center'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearData: function() {
            this.ermCopyData.toVhextAccountId = localStorage.getItem("vhextAccountId");
            this.ermCopyData.toProjectId = '';
            this.ermCopyData.toUserId = '';
            this.$v.ermCopyData.$reset();
        },
        onRefDataChange: function() {
            this.ermCopyData.vhextAccountId = this.vhextAccountId;
            this.ermCopyData.projectId = this.projectId;
            this.ermCopyData.createdById = this.userId;
            this.ermCopyData.id = this.masterData.refErmId; 
            console.log(this.ermCopyData);
        },

    }
}
</script>