<template>
    <div class="timeSheetPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered :title="popupTitle" @hide="clearData" no-enforce-focus :id="id" @close="showOverlay ? $event.preventDefault() : $event.vueTarget.hide();" ref="timeSheetRef" size="lg" modal-class="timeSheetRef">
            <b-overlay  :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="py-0 px-2 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title mt-1 mb-3">
                            <span v-if="elements.editMode"><i class="fa fa-pencil-square-o pr-2 action-link"></i>Edit Time Sheet</span>
                            <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Time Sheet</span>
                            <span class="dataTables_filter float-right">
                                <select v-model="timeSheet.taskId" @change="getTaskDetails()" class="form-control">
                                    <option value="">Select Task</option>
                                    <option v-for="(info, index) in tasks" :value="info.id">{{info.value}}</option>
                                </select>                               
                        </span>
                        </label>
                                                
                    </b-card-header>
                    <b-card-body class="py-1 px-3">
                        
                        
                        <div class="row input-group-label">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.timeSheet.title.$error }">
                                        <b-form-input id="title" v-model="timeSheet.title" placeholder="Enter Timesheet Title" required></b-form-input>
                                        <div class="required-icon"></div>                                
                                    </div>
                                    <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.required" class="text-danger">Please Enter Timesheet Title</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="startDate">Activity</label>
                                    <b-input-group>
                                        <select v-model="timeSheet.processActivity" class="form-control" id="activityId1">
                                            <option :value="{activityId: '', processId: ''}">Select</option>
                                            <option v-for="(info, index) in processActivities" :value="{activityId: info.activityId, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.activity}}</option>
                                        </select>   
                                        <b-input-group-append class="append-0">
                                            <a class="action-link" href="javascript:" title="Use Existing Activities" @click="showDialog=true" v-b-modal.userActivityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                        </b-input-group-append> 
                                    </b-input-group>                                    

                                </div>
                            </div>                            
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="startDate">Start Date</label>
                                    <b-row class="mb-1">
                                        <b-col class="col-7 pr-0">
                                            <b-form-input v-model="timeSheet.startDT" type="date" class="form-control" required></b-form-input>
                                        </b-col>
                                        <b-col class="col-5">
                                            <b-form-input v-model="timeSheet.startTM" type="time"  @input="setEffortFromTimeDiff()" required></b-form-input>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="endDate">End Date</label>
                                    <b-row class="mb-1">
                                        <b-col class="col-7 pr-0">
                                            <b-form-input type="date" v-model="timeSheet.endDT" required></b-form-input>
                                        </b-col>
                                        <b-col class="col-5">
                                            <b-form-input type="time" v-model="timeSheet.endTM" @input="setEffortFromTimeDiff()" required></b-form-input>
                                        </b-col>
                                    </b-row>                                  
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label for="endDate">Effort</label>
                                    <b-input-group size="sm" class="mb-2">
                                        <EffortInput ref="effortControl" @vmx-effort-input="checkValidEffort()" />
                                    </b-input-group>
                                    <label v-if="!isValidEffort" class="text-danger">Please enter at least one effort value: Work Minute, Work Hour, or Work Day.</label>
                                </div>
                                
                            </div>
                            <div class="col-12 col-sm-6">
                                <div class="form-group">
                                    <label for="Process">{{timeSheet.taskId ? "Task " : ""}}Status</label>
                                    <select v-model="timeSheet.statusId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <VmxEditor v-model="timeSheet.description"></VmxEditor>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
            </b-overlay>

            <template #modal-footer>
                <div class="w-100">
                    <b-button class="float-right"  variant="outline-primary" size="sm" @click="clearData">
                        Clear
                    </b-button>
                    <b-button variant="primary" :disabled="showOverlay" size="sm" @click="saveData" class="float-right mr-1">
                        <span v-if="elements.editMode">Update</span>
                        <span v-else>Save</span>
                    </b-button>
                </div>
            </template>

        </b-modal>
        <AddUserActivityPopup v-if="showDialog" id="userActivityModal" ref="addUserActivityModal"  @refresh-lookup="lookupData" @closeDialog="closeDialog" />                 
        <DialogComponent id="deleteTimeSheet" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Time Sheet" />

	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import AddUserActivityPopup from '@/components/popup/action/AddUserActivityPopup.vue';
// import TimeSheetListComponent from '@/components/common/TimeSheetListComponent.vue';
import VmxEditor from '@/components/usercontrols/VmxEditor';
import { required, email, minLength, maxLength, sameAs, between } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
        id: { type: String, default: 'quickTimeSheet' },
        taskData: Object,
        ermLookupList: Array,
    },    
    components: {
        AddUserActivityPopup,
        // TimeSheetListComponent,
        VmxEditor
    },
    data() {
        return {
            elements: {
                visible: false,
                editMode: false,
            },
            showOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entityRecordMasterId: this.$route.params.ermId,
            userId: localStorage.getItem("userId"),

            entity:'time_sheet',

            popupTitle: '',

            showDialog: false,
            isValidEffort: true,

            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                preparedById: this.userId,
                title: '',
                taskId: '',
                task: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },
                preparedBy: '',
                startDate: '',
                startDT: this.getDateValue(new Date()),
                startTM: this.getTimeValue(new Date()),
                endDate: '',
                endDT: this.getDateValue(new Date()),
                endTM: this.getTimeValue(new Date()),
                workDay: '',
                workHour: '',
                workMinute: '',
                description: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: this.entity
            },

            timeSheets: [],
            tasks: [],
            processActivities: [],
            tsActivities: [],
            taskStatus: [],
            timeSheetFields: [
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Duration', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        
    },
    mounted() {
        this.lookupData()
    },
    validations: {
        timeSheet: {
            title: {
                required,
            },
        }
    },
    methods: {
        setInitData(title) {
            // alert(title);
            this.popupTitle = title;
            if (this.taskData?.id) {
                this.timeSheet.title = this.taskData.title;
                this.timeSheet.startDateValue = this.taskData.estimatedStartDt ? this.getDateValue(this.taskData.estimatedStartDt) : '';
                this.timeSheet.startTimeValue = this.getTimeValue(this.taskData.estimatedStartDt)
                this.timeSheet.endDateValue = this.taskData.estimatedEndDt ? this.getDateValue(this.taskData.estimatedEndDt) : '';
                this.timeSheet.endTimeValue = this.getTimeValue(this.taskData.estimatedEndDt)
                this.setEffortFromTimeDiff();
            }

        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.entityRecordMasterId,
                entity: this.entity,
                userId: Number(this.userId),
            }
            console.log("LUD",data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskLookup;
                    this.processActivities = result.userProcessActivityLookup;

                    this.taskStatus = result.statusLookup;
                    console.log("TaskList",result);
                })
        },
        saveData: function() {
            this.timeSheet.vhextAccountId = this.vhextAccountId,
            this.timeSheet.projectId = this.projectId,
            this.timeSheet.entityRecordMasterId = this.entityRecordMasterId,
            this.timeSheet.preparedById = this.userId,
            // this.timeSheet.startTM = '11:30 AM'
            // this.timeSheet.endTM = '02:30 PM'
            this.timeSheet.startDate = this.combineDateAndTime(this.timeSheet.startDT, this.timeSheet.startTM);
            this.timeSheet.endDate = this.combineDateAndTime(this.timeSheet.endDT, this.timeSheet.endTM);

            this.timeSheet.processId = this.timeSheet.processActivity.processId;
            this.timeSheet.activityId = this.timeSheet.processActivity.activityId;


            console.log(this.timeSheet);
            // this.setEffortData();

            this.$v.timeSheet.$touch();

            if (this.checkValidEffort() && !this.$v.timeSheet.$invalid) {
                
                console.log(this.timeSheet.workDay,this.timeSheet.workHour,this.timeSheet.workMinute);

                if (this.elements.editMode) {
                    this.updateTimeSheet();
                } else {
                    this.addTimeSheet();
                }            
            }
        },
        addTimeSheet: function() {
            this.showOverlay = true;
            // this.setEffortData();
            console.log(this.timeSheet);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/add', this.timeSheet)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-timesheet');
                        this.clearData();
                        this.$bvModal.hide(this.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateTimeSheet: function() {
            // this.setEffortData();
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/update', this.timeSheet)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(this.timeSheet);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-timesheet');
                        this.clearData();
                        this.$bvModal.hide(this.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteTimeSheet: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/timesheet/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        setEffortFromTimeDiff() {

            if (this.timeSheet.startTM && this.timeSheet.endTM) {
                let startDate = this.timeSheet.startDT ? this.timeSheet.startDT : this.getDateValue(new Date());
                let endDate = this.timeSheet.endDT ? this.timeSheet.endDT : this.getDateValue(new Date());

                let combStartDT = this.combineDateAndTime(startDate, this.timeSheet.startTM);
                let combEndDT = this.combineDateAndTime(endDate, this.timeSheet.endTM);

                let difference = this.getDateDifference(combStartDT, combEndDT);
                this.$refs.effortControl.setEffortInputData(difference.days, difference.hours, difference.minutes);
                this.checkValidEffort();
            }
        },
        checkValidEffort() {
            this.setEffortData();
            if((this.timeSheet.workDay && this.timeSheet.workDay > 0) ||
                (this.timeSheet.workHour && this.timeSheet.workHour > 0) ||
                (this.timeSheet.workMinute && this.timeSheet.workMinute > 0)){

                this.isValidEffort = true;
                return true;

            }else{

                this.isValidEffort = false;
                return false;

            }
        },
        clearData: function() {

            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.isValidEffort = true;
            this.timeSheet.timeSheetId = '';
            this.timeSheet.taskId = '';
            this.timeSheet.title = '';
            this.timeSheet.statusId = '';
            this.timeSheet.processActivity.activityId = '';
            this.timeSheet.processActivity.processId = '';
            this.timeSheet.status = '';
            this.timeSheet.activity = '';
            this.timeSheet.startDate = '';
            this.timeSheet.startDT = this.getDateValue(new Date());
            this.timeSheet.startTM = this.getTimeValue(new Date());

            this.timeSheet.endDate = '';
            this.timeSheet.endDT = this.getDateValue(new Date());
            this.timeSheet.endTM = this.getTimeValue(new Date());

            this.timeSheet.workDay = '';
            this.timeSheet.workHour = '';
            this.timeSheet.workMinute = '';
            this.timeSheet.description = '';
            this.$refs.effortControl.setEffortInputData('', '', '');
            this.$v.timeSheet.$reset();
        },
        showEdit: function(data) {
            console.log("TS",data);
            this.popupTitle = 'Edit Time Sheet';
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.timeSheet.id = data.id;
            this.timeSheet.timeSheetId = data.timeSheetId;
            this.timeSheet.taskId = data.taskId;
            this.timeSheet.title = data.title;
            this.timeSheet.statusId = data.statusId;
            this.timeSheet.status = data.status;
            this.timeSheet.processActivity.activityId = data.activityId;
            this.timeSheet.processActivity.processId = data.processId;
            this.timeSheet.preparedById = data.preparedById;
            this.timeSheet.preparedBy = data.preparedBy;
            this.timeSheet.startDate = '';
            this.timeSheet.startDT = this.getDateValue(data.startDate);
            this.timeSheet.startTM = this.getTimeValue(data.startDate);

            this.timeSheet.endDate = data.endDate;
            this.timeSheet.endDT = this.getDateValue(data.endDate);
            this.timeSheet.endTM = this.getTimeValue(data.endDate);

            this.timeSheet.workDay = data.workDay;
            this.timeSheet.workHour = data.workHour;
            this.timeSheet.workMinute = data.workMinute;
            this.timeSheet.description = data.description;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },
        getTaskDetails: function() {
            this.isValidEffort = true;
            if (this.timeSheet.taskId) {
                this.showOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + this.timeSheet.taskId)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        let task = result.task;
                        console.log(task);
                        this.timeSheet.title = task.title;
                        this.timeSheet.activityId = task.activityId ? task.activityId : '';

                        this.timeSheet.processActivity.processId = task.processId;
                        this.timeSheet.processActivity.activityId = task.activityId;

                        // this.timeSheet.startDT = this.getDateValue(task.estimatedStartDt);
                        this.timeSheet.startTM = this.getTimeValue(task.estimatedStartDt);

                        // this.timeSheet.endDT = this.getDateValue(task.estimatedEndDt);
                        this.timeSheet.endTM = this.getTimeValue(task.estimatedEndDt);

                        this.$refs.effortControl.setEffortInputData(task.workDay, task.workHour, task.workMinute);

                        this.timeSheet.description = task.description;

                        // this.timeSheet.workDay = task.workDay ? task.workDay : '';
                        // this.timeSheet.workHour = task.workHour ? task.workHour : '';
                        // this.timeSheet.workMinute = task.workMinute ? task.workMinute : '';


                        this.timeSheet.statusId = task.statusId;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }else{
                this.clearData();
            }
        },
        setEffortData: function() {
            this.timeSheet.workHour = this.$refs.effortControl.getHrs();
            this.timeSheet.workMinute = this.$refs.effortControl.getMins();
            this.timeSheet.workDay = this.$refs.effortControl.getDays();
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType != null) {
                return effortType[0].name;
            }
        },
        topFunction() {
            const elements = document.querySelector('#timeSheetPopup');
            elements.scrollTop = 0;
        },
        closeDialog: function() {
            console.log('closed');
            this.isValidEffort = true;
            this.showDialog = false;
        }        
    }
}
</script>