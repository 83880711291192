<template>
    <div class="manageErmMasterModal">
        <b-modal ref="manageErmMasterModal" size="lg" :id="id" centered modal-class="manageErmMasterModal" :title="ermMasterInfo.title" @hide="closeDialog" :no-close-on-backdrop="true" ok-title="Ok">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{ ermMasterInfo.title }}</span>
                                <span v-if="elements.editMode">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                            <b-card-body class="px-2">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="title">{{ ermMasterInfo.titleLabelName ? ermMasterInfo.titleLabelName : 'Title' }}</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                                <input type="text" class="form-control" v-model="entityRecordMaster.title" id="title" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }" placeholder="Enter Title">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.minLength" class="text-danger">Title must have at least {{$v.entityRecordMaster.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.maxLength" class="text-danger">Title must not exceed {{$v.entityRecordMaster.title.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                    <div v-if="ermMasterInfo.showTypeLookup" class="col-6">
                                        <div class="form-group">
                                            <label for="ermTypeId">{{ermMasterInfo.typeLabelName}}</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.ermTypeId.$error }">
                                                <select v-model="entityRecordMaster.ermTypeId" class="form-control" @change="handleErmTypeChange" :class="{ 'is-invalid': $v.entityRecordMaster.ermTypeId.$error }" id="ermTypeId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.entityRecordMaster.ermTypeId.$error && !$v.entityRecordMaster.ermTypeId.required" class="text-danger">Please select a type</label>

                                        </div>
                                    </div>
<!--                                     <div v-else class="col-6">
                                    </div>                                             -->
                                    <template v-if="ermMasterInfo.showDateRange">
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="startDate">Start Date</label>
                                                <input type="date" class="form-control" v-model="entityRecordMaster.startDate" id="startDate">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="endDate">End Date</label>
                                                <input type="date" class="form-control" v-model="entityRecordMaster.endDate" id="endDate">
                                            </div>
                                        </div>
                                    </template>

                                    <div v-if="!ermMasterInfo.hideProject && !this.projectId" class="col-6">
                                        <div v-if="this.projectLookup.length>0" class="form-group">
                                            <label for="projectId">Project</label>
                                            <select v-model="entityRecordMaster.projectId" id="projectId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectLookup" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                        
                                    </div>
                                    <div v-if="!ermMasterInfo.hideClient" class="col-6 col-sm-6">
                                        <div v-if="this.clients.length>0" class="form-group">
                                            <label for="client">Client</label>
                                            <select v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <!-- <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.clientName" type="text" class="form-control"> -->
                                        </div>
                                    </div> 
<!--                                     <div v-if="ermMasterInfo.showMultipleMasterControl" class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label for="title1">Link Financial Transaction Group</label>
                                            <multiselect v-model="refErmList" :options="ermLookup" name="masterLink" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="description">{{ ermMasterInfo.descriptionLabelName ? ermMasterInfo.descriptionLabelName : 'Description' }}</label>
                                            <textarea v-model="entityRecordMaster.description" class="form-control" id="description" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">                                    
                                            <a class="float-left">
                                                <input type="checkbox" class="align-middle mr-1" v-model="entityRecordMaster.isPublic"><span class="align-middle d-inline-block">Public</span>
                                            </a>   
                                        </div>
                                    </div>

<!--                                     <b-input-group class="form-group py-2">
                                        <div class="align-middle float-left my-2 ml-4 mr-0" cols="2">Title</div>
                                        <b-col cols="9" class="pr-1">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                                <b-form-input id="title" v-model="entityRecordMaster.title" placeholder="Enter Title"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger m-0">Please Enter Title</label>
                                        </b-col>
                                        <div class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveERMMaster" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearERMMasterData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </b-input-group> -->
                                    <div class="col-sm-12 col-lg-12">
                                        <div class="form-group mt-3">
                                            <button type="submit" @click="saveERMMaster" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>{{ elements.editMode ? 'Update' : 'Save' }}</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </div>
                                    </div>                                    
                                </div>


                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="row input-group-label">
                                <div class="col-6">
                                    <label class="mb-0 mt-2 fs-14">{{ ermMasterInfo.title }} List</label>
                                </div>
                                <div class="col-3">
                                    <div v-if="!isArchive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="'Archived Docs'" href="javascript:" @click="getERMHistory()"><i class="fa fa-history"></i> Archive</a>
                                    </div>
                                    <div v-if="isArchive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMList()"><i class="fa fa-history"></i> {{ermMasterInfo.title}} List</a>
                                    </div>                                    
                                </div>
                                <div class="col-3 dataTables_filter text-right">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <b-table show-empty small outlined head-variant=:primary sticky-header="40vh" :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="ermList" :fields="selFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(isPublic)="data">
                            <div v-if="data.item.createdById == userId" title="Editable">
                                <b-form-checkbox class="ml-3" v-model="data.value" @change="ermPublicAccess(data.item)">
                                </b-form-checkbox>                                
                            </div>
                            <div v-else>
                                <span v-if="data.item.isPublic" class="text-success ml-3" title="UnEditable" ><i class="fa fa-check"></i></span>
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div v-if="data.item.isFreeze==true">
                                <a href="javascript:" class="text-center" title="Freezed"><i class="fa fa-lock fa-custom"></i></a>
                            </div>
                            <div v-else-if="data.item.createdById == userId">
                                <div>
                                    <span v-if="!data.item.isArchive">
                                        <a href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o pr-1"></i></a>
                                        <a href="javascript:" class="text-center" title="Archive" @click.prevent="archiveERM(data.item)"><i class="fa fa-archive pr-1" aria-hidden="true"></i></a>
                                        <a href="javascript:" class="text-center" title="Delete" v-b-modal.deleteERM @click.prevent="deleteERM(data.item.id)" ><i class="fa fa-trash" aria-hidden="true"></i></a>                                        
                                    </span>
                                    <span v-if="data.item.isArchive">
<!--                                         <a href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o pr-1 action-link"></i></a> -->
                                        <a href="javascript:" class="text-center" title="Unarchive" @click.prevent="archiveERM(data.item)"><i class="fa fa-undo pr-1" aria-hidden="true"></i></a>
                                        <a href="javascript:" class="text-center" title="Delete" v-b-modal.deleteERM @click.prevent="deleteERM(data.item.id)" ><i class="fa fa-trash" aria-hidden="true"></i></a>
                                    </span>
                                </div>   
                            </div>
                            <div v-else>
                                <a href="javascript:" class="text-center" title="NA">NA</a>
                            </div>

<!--                             <a v-if="data.item.isFreeze==true" href="javascript:" class="text-center" title="Freezed"><i class="fa fa-lock fa-custom"></i></a>

                            <a v-else-if="data.item.createdById == userId" href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                            
                            <a v-else href="javascript:" class="text-center" title="NA">NA</a>

                            <a v-if="data.item.createdById == userId" href="#" title="Delete" v-b-modal.deleteERM @click.prevent="deleteERM(data.item.id)" ><i class="fa fa-trash pl-2" aria-hidden="true"></i></a>                             -->
<!--                             <a v-if="data.item.createdById == userId" href="javascript:" title="Delete" @click="" data-toggle="modal" data-target="#removeERMMaster"><i class="fa fa-trash pl-2"></i></a> -->

                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No {{ ermMasterInfo.title }} found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span> 
<!--                     <b-pagination v-if="ermList && ermList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                </div>
            </b-overlay>
        </b-modal>

        <DeleteComponent id="deleteERM" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete?"/>  
        <Dialog id="archiveERM" :onYes="onYes" :returnParams="archiveParam" title="Archive Confirmation" message="Are you sure to archive?" />
        <!--        <Dialog id="deActivate" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />  -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'manageErmMasterModal' },
        ermMasterInfo: Object
    },
    components: {

    },
    data() {
        return {
            entity: this.ermMasterInfo.entity,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            isArchive: false,

            elements: {
                visible: false,
                editMode: false
            },
            isBusy: false,
            showOverlay: false,
            entityRecordMaster: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.ermMasterInfo.entity,
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                refEntityId: '',
                refEntity: this.ermMasterInfo.refEntity,
                isArchive: false,
                attachments: [],
                isPublic: false,
                statusId: '',
                createdById: localStorage.getItem("userId"),
            },
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            archiveParam: {
                id: 0
            },
            deleteParam: {
                id: 0
            },            

            projectLookup: [],
            clients: [],
            ermLookup: [],
            ermList: [],
            selFields: '',
            fields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'projectDisplayName', label: 'Project', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'clientName', label: 'Client Name', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'isPublic', label: 'Is Public', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' }
            ],

            planFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'projectDisplayName', label: 'Project', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'isPublic', label: 'Is Public', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' }
            ],

        }
    },
    validations: {
        entityRecordMaster: {
            title: {
                required
            }
        }
    },
    watch: {
        'entity': {
            handler: function(val1, val2) {
                this.getERMMasterList();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.getERMMasterList();
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.entityRecordMaster.vhextAccountId,
                projectId: this.entityRecordMaster.projectId,
                userId: this.userId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordStatus = result.statusLookup;
                    this.allUsers = result.userLookup;
                    this.clients = result.clientLookup;
                    this.projectLookup = result.projectLookup ? result.projectLookup : [];
                    this.entityRecordCustomType = result.typeLookup;
                    if (this.ermMasterInfo.typeCode && this.ermMasterInfo.typeCode == 'cli') {
                        this.entityRecordCustomType = this.filterCustomType(this.entityRecordCustomType, 'Financial Transaction');
                    }
                    if (this.entityRecordCustomType && this.entityRecordCustomType.length == 1) {
                        this.entityRecordMaster.ermTypeId = this.entityRecordCustomType[0].id;
                        this.handleErmTypeChange();
                    }
                    this.entityRecordMaster.entityId = result.entityId;
                    this.entityRecordMaster.refEntityId = result.refEntityId;
                    this.entityRecordMaster.createdById = localStorage.getItem("userId");
                    this.entityRecordMaster.statusId = this.entityRecordStatus && this.entityRecordStatus.length > 0 ? this.entityRecordStatus[0].id : '';
                })

                // console.log("erm",this.entityRecordMaster);

                if( this.entityRecordMaster.entity == 'development_plan_master'){
                    this.selFields = this.planFields;
                }else {
                    this.selFields = this.fields;
                }


        },
        getERMList: function() {
            // this.archive = false;
            this.isArchive = false;

            this.getERMMasterList();
            // console.log(this.masterData);
        },
        getERMHistory: function() {
            // this.archive = true;
            this.isArchive = true;

            this.getERMMasterList();
            // console.log(this.masterData);
        },

        getERMMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.entity,
                isArchive: this.isArchive,
            }
            console.log(data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log("ermList",result);
                    if (result.entityRecordMasterList) {
                        this.ermList = result.entityRecordMasterList;         
                        this.totalRows = this.ermList.length;
                        this.isBusy = false;
                    }
                }).catch(error => {
                    this.isBusy = false;
                });
        },
        masterLinkLookupData: function() {
            if (this.ermMasterInfo.masterLinkEntity) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    entity: this.ermMasterInfo.masterLinkEntity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                    .then((response) => {
                        let result = response.data;
                        this.ermLookup = result.entityERMLookup;
                    })
            }
        },
        saveERMMaster: function() {
            this.$v.entityRecordMaster.$touch();
            if (!this.$v.entityRecordMaster.$invalid) {
                if (this.elements.editMode) {
                    this.updateERMMaster();
                } else {
                    this.addERMMaster();
                }
            }
        },
        addERMMaster: function() {
            console.log("ERM",this.entityRecordMaster);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/add', this.entityRecordMaster)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('update-parent');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateERMMaster: function() {
            console.log(this.entityRecordMaster);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/popupdata/update', this.entityRecordMaster)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('update-parent');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        archiveERM: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isArchive: (itemData.isArchive == true ? false : true),
            }
            console.log(data.entityRecordMasterId, data.isArchive);
            var confirmMessage = '';
            if (data.isArchive == false) {
                confirmMessage = "Are you sure to unarchive?"
            } else {
                confirmMessage = "Are you sure to archive?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/archive', data)
                        .then((response) => {
                            let result = response.data;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Record Updated',
                                    // message: result.message,
                                    position: 'topCenter'
                                });
                                this.$emit('update-parent');
                                this.clearERMMasterData();
                                this.getERMMasterList();
                            }
                        })
                }
            })
        },        
        deleteERM: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log("Del",data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/erm/delete', data)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('update-parent');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.entityRecordMaster.id = data.id;
            this.entityRecordMaster.title = data.title;
            this.entityRecordMaster.clientId = data.clientId;
            this.entityRecordMaster.startDate = data.startDate;
            this.entityRecordMaster.endDate = data.endDate;
            this.entityRecordMaster.description = data.description;
            this.entityRecordMaster.isPublic = data.isPublic;

            if(data.projectId){
                this.entityRecordMaster.projectId = data.projectId;
            }
        },
        clearData: function() {
            this.entityRecordMaster.title = '';
            this.entityRecordMaster.projectId = '';
            this.entityRecordMaster.clientId = '';
            this.entityRecordMaster.ermTypeId = '';
            this.entityRecordMaster.description = '';
            this.entityRecordMaster.createdById = localStorage.getItem("userId");
            this.entityRecordMaster.statusId = '';
            this.entityRecordMaster.startDate = '';
            this.entityRecordMaster.endDate = '';
            this.entityRecordMaster.isPublic = false;
            this.entityRecordMaster.id = '';
            this.ermMasterTitle = null;
            this.editMode = false;
            this.error = false;

            this.clearERMMasterData();
            this.$v.entityRecordMaster.$reset();
        },        
        ermPublicAccess: function(item) {
            let data = {
                entityRecordMasterId: item.id,
                isPublic: (item.isPublic == true ? false : true),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/publicaccess', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            position: 'topRight'
                        });
                        this.getERMMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearERMMasterData: function() {
            this.elements.visible = false;
            this.elements.editMode = false;

            this.entityRecordMaster.title = '';
            this.entityRecordMaster.startDate = '';
            this.entityRecordMaster.endDate = '';
            this.entityRecordMaster.description = '';
            this.entityRecordMaster.isPublic = '';

            this.$v.entityRecordMaster.$reset();
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>