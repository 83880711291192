<template>
    <div class="timesheetDetailPopup">
        <b-modal id="timesheetDetailPopup" ref="timesheetDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Time Sheet" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-3">
                                <div class="col-lg-12 col-xl-12 px-1">
<!--                                     <div class="col-2 col-sm-2 p-0 m-0 px-0 float-right">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-10 col-sm-9 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row"> 
                                        <div class="col-6 col-sm-6 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.title}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 pl-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.activity}}</label>
                                                </div>
                                            </div>
                                        </div>                                    
                                        <div class="col-2 col-sm-2 pr-0 pt-1">
                                            <div class="">
                                                <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                            </div>
                                        </div>                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.preparedBy }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pl-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Date</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.lastUpdatedDt | formatDateTimeAmPm}}</label>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div class="row">
                                        <div class="col-4 col-sm-4 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-4 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Time</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.startDate | formatDateTimeYearCheck}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">End Time</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.endDate | formatDateTimeYearCheck}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Duration</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{(popupData.dateTimeDiff && popupData.dateTimeDiff !== null) ? popupData.dateTimeDiff : '-'}} [dd:hh:mm]</label>
                                                </div>
                                            </div>                                            
                                        </div> 
                                    </div>
                                    <div class="row">     
                                        <div class="col-6 col-sm-6 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Effort (Hrs & Mins)
                                                    </label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">
                                                        {{ effortHoursMinutes(popupData.workDay,popupData.workHour,popupData.workMinute) }}
                                                    <!-- {{convertToHM(popupData.actEffortMins)}} -->
                                                    </label>
                                                </div>
                                            </div>                                            
                                        </div>                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12 pr-1">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Description</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
<!--                                     <div class="row">
                                        <div class="col-6 col-sm-6 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.activity }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pl-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-5 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Task Title</label>
                                                </div>
                                                <div class="col-7 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.taskTitle}}</label>
                                                </div>
                                            </div>
                                        </div>                                    
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            console.log(id, refEntity);
                if (refEntity === "time_sheet") {
                    this.$bvModal.show('timesheetDetailPopup');
                    axios.get(this.$store.getters.getAPIBasePath + '/task/timesheet/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.requirementSpecView;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$bvModal.show('timesheetDetailPopup');
                    this.loading = false;
                }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>