<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Task Tracking</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 col-xl-3">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs v-model="resTabIndex" content-class="mt-3">
                                <b-tab title="Employee">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
<!--                                             <div class="float-right">
                                                <div class="dataTables_length">
                                                    <label class="pt-1">
                                                        <input type="checkbox" v-model="isChecked" @change="checkboxChanged"> All Employees
                                                    </label>
                                                </div>
                                            </div> -->
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="employeeTable" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="employees" @row-selected="onRowSelected" :fields="employeeFields" responsive="sm"  :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredEmp">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template> 
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Employees found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsEmp}} Items</span>

<!--                                     <b-pagination v-if="employees.length!=0" pills v-model="currentPageEmp" :total-rows="totalRowsEmp" :per-page="perPageRes" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                </b-tab>
                                <b-tab title="Other Resources">
                           
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPageRes" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="otherResTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="otherResources" @row-selected="onRowSelected" :fields="otherResourceFields" responsive="sm" :current-page="currentPageRes" :per-page="perPageRes" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRes">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template> 
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Resources found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsRes}} Items</span>

                                    <b-pagination v-if="otherResources.length!=0" pills v-model="currentPageRes" :total-rows="totalRowsRes" :per-page="perPageRes" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </b-tab>
                            </b-tabs>

                        </div>
                    </div>
                </div>

                <div class="col-lg-9 col-xl-9 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <span v-if="ettTabIndex==0" class="position-absolute mt-2 ml-auto" style="right: 350px;">
                                    <b-button class="float-right addnew-quicktassk-btn mr-2" @click="quickTask()" variant="outline-info" size="sm" v-b-modal.ermAssignments><i class="fa fa-plus"></i> Quick Task</b-button>
                                </span>
                                <div class="tab-floating-text mt-3">
                                    <b-button  class="addnew-tassk-btn sbtn float-right ml-2" variant="outline-primary" v-b-toggle.accordion-ett size="xs" :class="{'active' : elements.ettVisible}" :title="(elements.ettVisible ? 'Hide' : 'Show') + ' Filter'"><i class="fa fa-filter pr-2" aria-hidden="true"></i>{{ elements.ettVisible ? 'Hide' : 'Filter'}}</b-button>
                                    <span v-if="ettTabIndex==0" class="">
                                        <span class="float-right title-highlight">
                                            <label class="mr-2 my-0">Date: </label>
                                            <label class="my-0">{{ formatDate(task.filterStartDate) }} <span class="px-2">to</span> {{ formatDate(task.filterEndDate) }} </label>
                                        </span>
                                    </span>
                                    <span v-if="ettTabIndex==1" class="title-highlight">
                                        <label class="mr-2 my-0">Emp. Utilization Date: </label>
                                        <label class="my-0">{{ formatDate(empUtilization.filterDate) }} </label>
                                    </span>
                                    <span v-if="ettTabIndex==2" class="title-highlight">
                                        <label class="mr-2 my-0">Date: </label>
                                        <label class="my-0">{{ formatDate(timeSheet.filterStartDate) }} <span class="px-2">to</span> {{ formatDate(timeSheet.filterEndDate) }} </label>
                                    </span>
                                    <span v-if="ettTabIndex==3" class="title-highlight">
                                        <label class="mr-2 my-0">Date: </label>
                                        <label class="my-0">{{ formatDate(taskPerformance.filterStartDate) }} <span class="px-2">to</span> {{ formatDate(taskPerformance.filterEndDate) }} </label>
                                    </span>
                                </div>
                                <b-tabs content-class="mt-3" v-model="ettTabIndex" lazy>
                                    <b-tab title="Tasks">
                                        <b-card no-body class="mb-1">
                                            <b-collapse id="accordion-ett" v-model="elements.ettVisible" accordion="my-accordion"  role="tabpanel">
                                                <b-card-body class="p-2">
                                                    <div class="row view-filter">
                                                        <div class="col-3 col-md-3 float-left">
                                                            <div class="dataTables_filter">
                                                                <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                                                <input type="search" v-model="filter" class="form-control pl-4" placeholder="Search..." id="searchFilter">
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            
                                                        </div>
                                                        <div class="col-8 col-md-8 float-right pl-0 pb-1">
                                                            <div class="row">
                                                                <div class="col py-1 pr-0 text-right">
                                                                    Date Range
                                                                </div>
                                                                <div class="col-3 pr-0">
                                                                    <div class="">
                                                                        <input type="date" class="form-control" v-model="task.startDate">
                                                                    </div>
                                                                </div>
                                                                <div class="col-1 py-1 px-0 text-center">
                                                                    to
                                                                </div>
                                                                <div class="col-3 pl-0">
                                                                    <div class="">
                                                                        <input type="date" class="form-control" v-model="task.endDate">
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="">
                                                                        <div >
                                                                            <button type="submit" @click="getTaskTrackingList" class="sbtn btn btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                                            <a href="javascript:" title="Reset Filter" @click="resetFilter"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div id="scope-app" class="card p-2 mb-3">
                                            <div class="row">
                                                <div class="col-3 pr-0">
                                                    <div class="row">
                                                        <div class="col-4 pr-0">
                                                            <label class="mb-0 p-1 py-2 d-block title-block font-weight-light">{{ resourceDetail.employeeId }}</label>
                                                        </div>
                                                        <div class="col-8 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details ">{{ resourceDetail.name }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Total Task</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ totalRowsTask }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Total Est. Effort</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ convertToHM(totalEffort) }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="row mb-0">
                                                        <div class="col-7 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Total Actual Effort</label>
                                                        </div>
                                                        <div class="col-5 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details">{{  }} hours</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="ettTaskList" :fields="taskFields" responsive="sm" :current-page="currentPageTask" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredTask">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                                                          
                                            <template v-slot:cell(title)="data">
                                                <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(effort)="data">
                                                <div v-show="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                    <b-input-group size="sm" class="w-75">
                                                        <EffortInput :id="'effort'+data.item.id"  
                                                        :daysId="data.field.key+data.item.id" 
                                                        @vmx-effort-input="vmxEffortInput" 
                                                        :daysValue="data.item.workDay" 
                                                        :hrsValue="data.item.workHour"
                                                        :minsValue="data.item.workMinute"
                                                        ref="effortControl" />
                                                    </b-input-group>
                                                    <div class="text-right w-75">
                                                        <span class="more-btns">
                                                            <button type="submit" @click="updateTaskEffort(data.item.id,'effortControl')" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelInlineEffortEdit(ettTaskList, 'effortControl')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <template v-if="!(data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName)" >
                                                    <p v-if="data.item.workDay || data.item.workHour || data.item.workMinute" class="action-link" @dblclick="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item,'effortControl')">
                                                        {{ formatDurationDHM(data.item.workHour,data.item.workMinute,data.item.workDay) }}
                                                    </p>
                                                    <div v-else @click="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item,'effortControl')" class="action-link">Set Effort</div>
                                                </template>
                                            </template>
                                            <template v-slot:cell(progressPercentage)="data">
                                                <span title="Performance">{{ data.value ? data.value : 0 }}%</span>
                                                <b-progress height="7px" :value="data.value" variant="secondary"></b-progress>
                                            </template>
                                            <template v-slot:cell(duration)="data">
                                                {{ data.value ? data.value + ' Day(s)' : '' }}
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Task found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <div class="float-left">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-right ml-3">{{this.totalRowsTask}} Items</span>

                                        <b-pagination v-if="ettTaskList.length!=0" pills v-model="currentPageTask" :total-rows="totalRowsTask" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>

                                    </b-tab>
                                    <!-- <b-tab title="Task Eval">
                                    </b-tab> -->
                                    <b-tab title="Daily Emp Utilization">
                                        <b-card no-body class="mb-1">
                                            <b-collapse id="accordion-ett" v-model="elements.ettVisible" accordion="my-accordion"  role="tabpanel">
                                                <b-card-body class="p-2">
                                                    <div class="row view-filter">
                                                        <div class="col-3 col-md-3 float-left">
                                                            <div class="dataTables_filter">
                                                                <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                                                <input type="search" v-model="filter" class="form-control pl-4" placeholder="Search..." id="searchFilter">
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            
                                                        </div>
                                                        <div class="col-8 col-md-8 float-right pl-0 pb-1">
                                                            <div class="row">
                                                                <div class="col py-1">
                                                                    
                                                                </div>
                                                                <div class="col-3 py-1 pr-0 text-right">
                                                                    <span>Emp. Utilization Date</span>
                                                                </div>
                                                                <div class="col-4 pr-0">
                                                                    <div class="">
                                                                        <input type="date" class="form-control" v-model="empUtilization.utilizationDate">
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="">
                                                                        <div >
                                                                            <button type="submit" @click="getEmpUtilTaskList" class="sbtn btn btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                                            <a href="javascript:" title="Reset Filter" @click="resetFilter"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div id="scope-app" class="card p-2 mb-3">
                                            <div class="row">
                                                <div class="col-3 pr-0">
                                                    <div class="row">
                                                        <div class="col-4 pr-0">
                                                            <label class="mb-0 p-1 py-2 d-block title-block font-weight-light">{{ resourceDetail.employeeId }}</label>
                                                        </div>
                                                        <div class="col-8 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details ">{{ resourceDetail.name }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-7 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Total Work Hour</label>
                                                        </div>
                                                        <div class="col-5 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details">{{ formatDurationDHM(estWorkHourEmpUtil,estWorkMinuteEmpUtil) }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-7 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Work Hour Util.</label>
                                                        </div>
                                                        <div class="col-5 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ formatDurationDHM(totalWorkHourEmpUtil,totalWorkMinuteEmpUtil) }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Emp. Utilization [%]</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="px-2 py-1 mb-0 d-block header-details text-center">
                                                                <span class="" title="Employee Utilization">{{ utilizationPercentage }}%</span>
                                                                <b-progress height="7px" :value="utilizationPercentage" variant="secondary"></b-progress>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="empUtilizationTaskList" :fields="empUtilizationFields" responsive="sm" :current-page="currentPageEmpUtl" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredEmpUtl">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                                                          
                                            <template v-slot:cell(title)="data">
                                                <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(effort)="data">
                                                <div v-show="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                    <b-input-group size="sm">
                                                        <EffortInput :id="'empeffort'+data.item.id"  
                                                        :daysId="data.field.key+data.item.id" 
                                                        @vmx-effort-input="vmxTotalEffortInput" 
                                                        :daysValue="data.item.workDay" 
                                                        :hrsValue="data.item.workHour"
                                                        :minsValue="data.item.workMinute"
                                                        ref="totalEffortControl" />
                                                    </b-input-group>
                                                    <div class="text-right">
                                                        <span class="more-btns">
                                                            <button type="submit" @click="updateTaskEffort(data.item.id,'totalEffortControl')" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelInlineEffortEdit(ettTaskList,'totalEffortControl')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <template v-if="!(data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName)">
                                                    <p v-if="data.item.workDay || data.item.workHour || data.item.workMinute" class="action-link" @dblclick="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item,'totalEffortControl')">
                                                        {{ formatDurationDHM(data.item.workHour,data.item.workMinute,data.item.workDay) }}
                                                    </p>
                                                    <div v-else @click="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item, 'totalEffortControl')" class="action-link">Set Effort</div>
                                                </template>
                                            </template>
                                            <template v-slot:cell(totUtilWorkMinute)="data">
                                                {{convertToHM(data.value)}}
                                            </template>    
                                            <template v-slot:cell(utilWorkHour)="data">
                                                <div v-show="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                    <b-input-group size="sm">                                                       
                                                        <EffortInput :id="'esteffort'+data.item.id" :daysVisible="false"  
                                                        :hrsId="data.field.key+data.item.id" 
                                                        @vmx-effort-input="vmxWorkEffortControl"                     
                                                        :hrsValue="data.item.utilWorkHour"
                                                        :minsValue="data.item.utilWorkMinute"
                                                        ref="workEffortControl" />
                                                    </b-input-group>
                                                    <div class="text-right">
                                                        <span class="more-btns">
                                                            <button type="submit" @click="saveEmpUtil(data.item,'workEffortControl')" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelInlineEdit(empUtilizationTaskList,'workEffortControl')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <template v-if="!(data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName)">
                                                    <p v-if="data.item.utilWorkHour || data.item.utilWorkMinute" class="action-link" @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item, true,'workEffortControl')">
                                                        {{ formatDurationDHM(data.item.utilWorkHour,data.item.utilWorkMinute) }}
                                                    </p>
                                                    <div v-else @click="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item, false,'workEffortControl')" class="action-link">Set Effort</div>
                                                </template>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Task found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <div class="float-left">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-right ml-3">{{this.totalRowsEmpUtl}} Items</span>

                                        <b-pagination v-if="empUtilizationTaskList.length!=0" pills v-model="currentPageEmpUtl" :total-rows="totalRowsEmpUtl" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>

                                    </b-tab>
                                    <b-tab title="Emp. Timesheet">
                                        <b-card no-body class="mb-1">
                                            <b-collapse id="accordion-ett" v-model="elements.ettVisible" accordion="my-accordion"  role="tabpanel">
                                                <b-card-body class="p-2">
                                                    <div class="row view-filter">
                                                        <div class="col-3 col-md-3 float-left">
                                                            <div class="dataTables_filter">
                                                                <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                                                <input type="search" v-model="filter" class="form-control pl-4" placeholder="Search..." id="searchFilter">
                                                            </div>
                                                        </div>
                                                        <div class="col-9 col-md-9 float-right pl-0 pb-1">
                                                            <div class="row">
                                                                <div class="col-3">
                                                                    
                                                                </div>
                                                                <div class="col-7">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            
                                                                        </div>
                                                                        <div class="col-2 py-1 pr-0 text-right">
                                                                            From
                                                                        </div>
                                                                        <div class="col-4 pr-0">
                                                                            <div class="">
                                                                                <input type="date" class="form-control" v-model="timeSheet.startDate">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-1 py-1 px-0 text-center">
                                                                            to
                                                                        </div>
                                                                        <div class="col-4 pl-0">
                                                                            <div class="">
                                                                                <input type="date" class="form-control" v-model="timeSheet.endDate">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="">
                                                                        <div >
                                                                            <button type="submit" @click="getTimeSheetList" class="sbtn btn btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                                            <a href="javascript:" title="Reset Filter" @click="resetFilter"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div id="scope-app" class="card p-2 mb-3">
                                            <div class="row">
                                                <div class="col-4 pr-0">
                                                    <div class="row">
                                                        <div class="col-4 pr-0">
                                                            <label class="mb-0 p-1 py-2 d-block title-block font-weight-light">{{ resourceDetail.employeeId }}</label>
                                                        </div>
                                                        <div class="col-8 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details ">{{ resourceDetail.name }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-4 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Est. Effort</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ totalEffortTaskPer }} hours</label>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-4 pr-0 float-right">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Total Time Duration</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details">{{ convertToHM(totalActualEffortTimeSheet) }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="empTimeSheetList" :fields="empTimeSheetFields" responsive="sm" :current-page="currentPageTimeSheet" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredTaskPer">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                                                                                                      
                                            <!-- <template v-slot:cell(title)="data">
                                                <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                            </template> -->
                                            <!-- <template v-slot:cell(effort)="data">
                                                <div v-show="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                    <b-input-group size="sm">
                                                        <EffortInput :daysId="data.field.key+data.item.id" ref="effortControl" />
                                                    </b-input-group>
                                                    <div class="text-right">
                                                        <span class="more-btns">
                                                            <button type="submit" @click="updateTaskEffort(data.item.id)" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelInlineEffortEdit(ettTaskList)" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <template v-if="!(data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName)">
                                                    <p v-if="data.item.workDay || data.item.workHour || data.item.workMinute" @dblclick="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item)">
                                                        {{ formatDurationDHM(data.item.workHour,data.item.workMinute,data.item.workDay) }}
                                                    </p>
                                                    <div v-else @click="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item)" class="action-link">Set Effort</div>
                                                </template>
                                            </template> -->
                                            <template v-slot:cell(dateTimeDiff)="data">
                                                {{ convertToHM(data.value) }}
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Timesheets found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <div class="float-left">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-right ml-3">{{this.totalRowsTimeSheet}} Items</span>

                                        <b-pagination v-if="empTimeSheetList.length!=0" pills v-model="currentPageTask" :total-rows="totalRowsTimeSheet" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>

                                    </b-tab>
                                    <b-tab title="Task Performance">
                                        <b-card no-body class="mb-1">
                                            <b-collapse id="accordion-ett" v-model="elements.ettVisible" accordion="my-accordion"  role="tabpanel">
                                                <b-card-body class="p-2">
                                                    <div class="row view-filter">
                                                        <div class="col-3 col-md-3 float-left">
                                                            <div class="dataTables_filter">
                                                                <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                                                <input type="search" v-model="filter" class="form-control pl-4" placeholder="Search..." id="searchFilter">
                                                            </div>
                                                        </div>
                                                        <div class="col-9 col-md-9 float-right pl-0 pb-1">
                                                            <div class="row">
                                                                <div class="col-7">
                                                                    <div class="row">
                                                                        <div class="col">
                                                                            
                                                                        </div>
                                                                        <div class="col-2 py-1 pr-0 text-right">
                                                                            From
                                                                        </div>
                                                                        <div class="col-4 pr-0">
                                                                            <div class="">
                                                                                <input type="date" class="form-control" v-model="taskPerformance.startDate">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-1 py-1 px-0 text-center">
                                                                            to
                                                                        </div>
                                                                        <div class="col-4 pl-0">
                                                                            <div class="">
                                                                                <input type="date" class="form-control" v-model="taskPerformance.endDate">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-3 pl-0">
                                                                    <div class="row">
                                                                        <div class="col-4 py-1 pr-0 text-right">
                                                                            Status
                                                                        </div>
                                                                        <div class="col-8">
                                                                            <select v-model="taskPerformance.statusId" class="form-control" id="statusId">
                                                                                <option value="">All</option>
                                                                                <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="">
                                                                        <div >
                                                                            <button type="submit" @click="getTaskPerformanceList" class="sbtn btn btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                                            <a href="javascript:" title="Reset Filter" @click="resetFilter"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div id="scope-app" class="card p-2 mb-3">
                                            <div class="row">
                                                <div class="col-3 pr-0">
                                                    <div class="row">
                                                        <div class="col-4 pr-0">
                                                            <label class="mb-0 p-1 py-2 d-block title-block font-weight-light">{{ resourceDetail.employeeId }}</label>
                                                        </div>
                                                        <div class="col-8 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details ">{{ resourceDetail.name }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Est. Effort</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details text-center">{{ convertToHM(totalEffortTaskPer) }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3 pr-0">
                                                    <div class="row mb-0">
                                                        <div class="col-6 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Actual Effort</label>
                                                        </div>
                                                        <div class="col-6 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details">{{ convertToHM(totalActualEffortTaskPer) }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="row mb-0">
                                                        <div class="col-5 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Emp. Efficiency</label>
                                                        </div>
                                                        <div class="col-7 pl-0">
                                                            <label class="pl-2 py-0 mb-0 d-block header-details">
                                                                <span class="" title="Performance">{{ getPerformanceRemark(taskPerformancePercent) }}</span>
                                                                <span class="w-70 d-inline-block">
                                                                    <b-progress height="7px" :value="taskPerformancePercent" :variant="performanceProgressVariant(taskPerformancePercent)"></b-progress>
                                                                </span> {{taskPerformancePercent}}%
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="taskPerformanceList" :fields="taskPerformanceFields" responsive="sm" :current-page="currentPageTaskPer" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredTaskPer">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                                                          
                                            <template v-slot:cell(title)="data">
                                                <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                            </template>
                                            <!-- <template v-slot:cell(effort)="data">
                                                <div v-show="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                                    <b-input-group size="sm">
                                                        <EffortInput :daysId="data.field.key+data.item.id" ref="effortControl" />
                                                    </b-input-group>
                                                    <div class="text-right">
                                                        <span class="more-btns">
                                                            <button type="submit" @click="updateTaskEffort(data.item.id)" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelInlineEffortEdit(ettTaskList)" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                                        </span>
                                                    </div>
                                                </div>
                                                <template v-if="!(data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName)">
                                                    <p v-if="data.item.workDay || data.item.workHour || data.item.workMinute" @dblclick="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item)">
                                                        {{ formatDurationDHM(data.item.workHour,data.item.workMinute,data.item.workDay) }}
                                                    </p>
                                                    <div v-else @click="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item)" class="action-link">Set Effort</div>
                                                </template>
                                            </template> -->
                                            <template v-slot:cell(estEffortMins)="data">
                                                {{convertToHM(data.value)}}
                                            </template>
                                            <template v-slot:cell(actEffortMins)="data">
                                                {{convertToHM(data.value)}}
                                            </template>
                                            <template v-slot:cell(empPerformance)="data">
                                                <label class="m-0 d-flex justify-content-between" title="Performance">
                                                    <span>{{ getPerformanceRemark(calculatePerformance(data.item.estEffortMins,data.item.actEffortMins)) }}</span>
                                                    <span class="float-right">{{ calculatePerformance(data.item.estEffortMins,data.item.actEffortMins) }}%</span>
                                                </label>
                                                <b-progress height="7px" :value="calculatePerformance(data.item.estEffortMins,data.item.actEffortMins)" :variant="performanceProgressVariant(calculatePerformance(data.item.estEffortMins,data.item.actEffortMins))"></b-progress>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Task found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <div class="float-left">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-right ml-3">{{this.totalRowsTaskPer}} Items</span>

                                        <b-pagination v-if="taskPerformanceList.length!=0" pills v-model="currentPageTask" :total-rows="totalRowsTaskPer" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="taskData" @refresh-task="getTabDetails()" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :attachmentData="attachmentData"  @closeDialog="showDialog=false;" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
export default {
    name: 'app',
    components: {
        QuickTaskPopup,
        TaskDetailPopup,
    },
    data() {
        return {
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                visible: false,
                empUtilEditMode: false,
                ettVisible: false,
            },
            resTabIndex: 0,
            ettTabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            selectedUserId: '',

            resourceDetail: {
                humanResourceId: '',
                employeeId: '',
                name: '',
                userId: '',
                workHour: '',
                workMinute: '',
            },
            empUtilData: {
                id: '',
                employeeUtilizationId: '',
                vhextAccountId: '',
                humanResourceId: '',
                taskId: '',
                utilizationDate: '',
                workHour: '',
                workMinute: '',
                createdById: '',
            },
            attachmentData: {},

            taskData: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'task',
                title: '',
                isArchive: false,
                activityId: '',
                activity: '',
                description: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                progressPercentage: 0,
                statusId: '',
                status: '',
                entityId: '',
                refErmId: '',
                checkListTag: '',
                response: '',
                checklistList: [],
                checklistItemCount: '',
            },

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',
            inlineEditFieldName2: '',
            inlineEditFieldValue2: '',
            inlineEditFieldName3: '',
            inlineEditFieldValue3: '',

            effortType: 'Man Hours',

            task: {
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                filterStartDate: this.getDateValue(new Date()),
                filterEndDate: this.getDateValue(new Date()),
            },
            taskEvaluation: {
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
            },
            empUtilization: {
                utilizationDate: this.getDateValue(new Date()),
                filterDate: this.getDateValue(new Date()),
            },
            taskPerformance: {
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                filterStartDate: this.getDateValue(new Date()),
                filterEndDate: this.getDateValue(new Date()),
                statusId: ''
            },
            timeSheet: {
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                filterStartDate: this.getDateValue(new Date()),
                filterEndDate: this.getDateValue(new Date()),
            },

            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            ettTaskList: [],
            taskEvaluationList: [],
            empUtilizationTaskList: [],
            empTimeSheetList: [],
            taskPerformanceList: [],
            taskStatus: [],

            humanResources: [],
            employeeFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                // { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'workType', label: 'Work Type', sortable: true, sortDirection: 'desc' }, 
                // { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                // { key: 'employeeStatus', label: 'Employee Status', sortable: true, sortDirection: 'desc' },
            ],

            otherResourceFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'humanResourceId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                // { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },         
                // { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
            ],
            taskFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'duration', label: 'Period', sortDirection: 'desc' },
                { key: 'effort', label: 'Est. Effort', tdClass: 'table-cw-300',  sortDirection: 'desc' },
                // { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'progressPercentage', label: 'Task Progression', sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },

            ],
            taskEvaluationFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'effort', label: 'Effort', sortDirection: 'desc' },
                { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'status', label: 'Evaluation Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },

            ],
            empUtilizationFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'effort', label: 'Total Task Effort', tdClass: 'table-cw-300', sortDirection: 'desc' },
                { key: 'totUtilWorkMinute', label: 'Task Effort Utilized', sortDirection: 'desc' },
                { key: 'utilWorkHour', label: 'Est. Effort for the day', tdClass: 'table-cw-300', sortDirection: 'desc' },
                // { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },

            ],
            empTimeSheetFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'dateTimeDiff', label: 'Time Duration', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],
            taskPerformanceFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                // { key: 'effort', label: 'Effort', sortDirection: 'desc' },
                { key: 'estEffortMins', label: 'Est. Effort', sortDirection: 'desc' },
                { key: 'actEffortMins', label: 'Actual Effort', sortDirection: 'desc' },
                { key: 'empPerformance', label: 'Emp. Efficiency', sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },

            ],
            selectMode: 'single',

            totalRowsEmp: 1,
            currentPageEmp: 1,

            totalRowsRes: 1,
            currentPageRes: 1,

            totalRowsTask: 1,
            currentPageTask: 1,

            totalRowsTaskEval: 1,
            currentPageTaskEval: 1,

            totalRowsEmpUtl: 1,
            currentPageEmpUtl: 1,

            totalRowsTimeSheet: 1,
            currentPageTimeSheet: 1,

            totalRowsTaskPer: 1,
            currentPageTaskPer: 1,

            perPageRes: 10,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filter: null,
            filterOn: [],
        }
    },
    watch: {
        resTabIndex(val) {
            if (val == 0) {
                this.$refs.employeeTable.selectRow(0);
                this.$refs.otherResTable.clearSelected();
            } else {
                this.$refs.otherResTable.selectRow(0);
                this.$refs.employeeTable.clearSelected();
            }
            this.getTabDetails();
        },
        ettTabIndex() {
            this.getTabDetails();
            this.elements.empUtilEditMode = false;
            this.inlineEditRowId = '';
        }
    },
    computed: {
        employees() {
            return this.humanResources.filter(item => item.empId);
        },
        otherResources() {
            return this.humanResources.filter(item => !(item.empId));
        },
        // filteredStatus() {
        //     return this.taskStatus.filter(status => status.value == 'Completed' || status.value == 'Closed')
        // },
        totalEffort() {
            return this.ettTaskList.reduce((accum, item) => {
                return accum + parseFloat(item.estEffortMins ? item.estEffortMins : 0)
            }, 0.00)
        },
        totalWorkHourEmpUtil() {
            return this.empUtilizationTaskList.reduce((accum, item) => {
                return accum + parseFloat(item.utilWorkHour ? item.utilWorkHour : 0)
            }, 0.00)
        },
        totalWorkMinuteEmpUtil() {
            return this.empUtilizationTaskList.reduce((accum, item) => {
                return accum + parseFloat(item.utilWorkMinute ? item.utilWorkMinute : 0)
            }, 0.00)
        },
        estWorkHourEmpUtil() {
            if(this.empUtilizationTaskList.length>0) {
                return this.empUtilizationTaskList[0].estWorkHour ? this.empUtilizationTaskList[0].estWorkHour : 0;
            }
            else return 0;
        },
        estWorkMinuteEmpUtil() {
            if(this.empUtilizationTaskList.length>0) {
                return this.empUtilizationTaskList[0].estWorkMinute ? this.empUtilizationTaskList[0].estWorkMinute : 0;
            }
            else return 0;
        },
        totalEffortTaskPer() {
            return this.taskPerformanceList.reduce((accum, item) => {
                return accum + parseFloat(item.estEffortMins ? item.estEffortMins : 0)
            }, 0.00)
        },
        totalActualEffortTaskPer() {
            return this.taskPerformanceList.reduce((accum, item) => {
                return accum + parseFloat(item.actEffortMins ? item.actEffortMins : 0)
            }, 0.00)
        },
        totalActualEffortTimeSheet() {
            return this.empTimeSheetList.reduce((accum, item) => {
                return accum + parseFloat(item.dateTimeDiff ? item.dateTimeDiff : 0)
            }, 0.00)
        },
        utilizationPercentage() {
            let empWorkHour = this.estWorkHourEmpUtil;
            let empWorkMinute = this.estWorkMinuteEmpUtil;

            let totalEmpMinutes = (empWorkHour*60) + empWorkMinute;

            let utilWorkHour = this.totalWorkHourEmpUtil;
            let utilWorkMinute = this.totalWorkMinuteEmpUtil;

            let totalUtilMinutes = (utilWorkHour*60) + utilWorkMinute;

            return totalEmpMinutes ? Math.round(( totalUtilMinutes / totalEmpMinutes) * 100) : 0;
        },
        taskPerformancePercent() {
            return this.totalActualEffortTaskPer ? Math.round((this.totalEffortTaskPer / this.totalActualEffortTaskPer) * 100) : 0;
        }
    },
    mounted() {
        this.getResourceList();
        this.lookupData();
    },
    methods: {
        getResourceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/companyhumanresource/list', data)
                .then((response) => {
                    let result = response.data;
                    this.humanResources = result.humanResourceList;
                    this.totalRowsEmp = this.employees.length;
                    this.totalRowsRes = this.otherResources.length;
                    this.$nextTick(() => {
                        if (this.resTabIndex == 0) {
                            this.$refs.employeeTable.selectRow(0);
                        } else {
                            this.$refs.otherResTable.selectRow(0);
                        }
                    })
                })
        },
        lookupData: function() {
            let data = {
                entity: 'task_status'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/status', data)
                .then((response) => {
                    let result = response.data;
                    this.taskStatus = result.statusLookup;
                })
        },
        getTaskTrackingList: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                assignedToId: this.selectedUserId,
                startDate: this.task.startDate,
                endDate: this.task.endDate,
            }
            this.task.filterStartDate = this.task.startDate;
            this.task.filterEndDate = this.task.endDate;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/etttask/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.ettTaskList = result.ettTaskList;
                    this.totalRowsTask = this.ettTaskList.length;
                    // this.taskEvaluationList = result.taskEvaluationList;
                    // this.totalRowsTaskEval = this.taskEvaluationList.length;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getEmpUtilTaskList: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                assignedToId: this.selectedUserId,
                startDate: this.empUtilization.utilizationDate,
            }
            this.empUtilization.filterDate = this.empUtilization.utilizationDate;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/emputltask/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.empUtilizationTaskList = result.empUtilizationTaskList;
                    this.totalRowsEmpUtl = this.empUtilizationTaskList.length;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getTaskPerformanceList: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                assignedToId: this.selectedUserId,
                startDate: this.taskPerformance.startDate,
                endDate: this.taskPerformance.endDate,
                statusId: this.taskPerformance.statusId,
            }
            this.taskPerformance.filterStartDate = this.taskPerformance.startDate;
            this.taskPerformance.filterEndDate = this.taskPerformance.endDate;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/taskper/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.taskPerformanceList = result.taskPerformanceList;
                    this.totalRowsTaskPer = this.taskPerformanceList.length;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getTimeSheetList: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                assignedToId: this.selectedUserId,
                startDate: this.timeSheet.startDate,
                endDate: this.timeSheet.endDate,
            }
            this.timeSheet.filterStartDate = this.timeSheet.startDate;
            this.timeSheet.filterEndDate = this.timeSheet.endDate;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/emptimesheet/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.empTimeSheetList = result.empTimeSheetList;
                    this.totalRowsTimeSheet = this.empTimeSheetList.length;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getTabDetails() {
            if (this.ettTabIndex == 0) {
                this.getTaskTrackingList();
            }
            else if (this.ettTabIndex == 1) {
                this.getEmpUtilTaskList();
            }
            else if (this.ettTabIndex == 2) {
                this.getTimeSheetList();
            }
            else {
                this.getTaskPerformanceList();
            }
        },
        onFilteredTask(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsTask = filteredItems.length
            this.currentPageTask = 1
        },
        onFilteredTaskEval(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsTaskEval = filteredItems.length
            this.currentPageTaskEval = 1
        },
        onFilteredEmpUtl(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsEmpUtl = filteredItems.length
            this.currentPageEmpUtl = 1
        },
        onFilteredTaskPer(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsTaskPer = filteredItems.length
            this.currentPageTaskPer = 1
        },
        onFilteredTimeSheet(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsTimeSheet = filteredItems.length
            this.currentPageTimeSheet = 1
        },
        onFilteredEmp(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsEmp = filteredItems.length
            this.currentPageEmp = 1
        },
        onFilteredRes(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRes = filteredItems.length
            this.currentPageRes = 1
        },
        focusEffortEdit(cellId, rowId, fieldName, data, controlRefId) {
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = data.workDay;
            this.inlineEditFieldName = fieldName;

            this.inlineEditFieldValue2 = data.workHour;
            this.inlineEditFieldName2 = 'workHour';

            this.inlineEditFieldValue3 = data.workMinute;
            this.inlineEditFieldName3 = 'workMinute';

            this.$refs[controlRefId].setEffortInputData(data.workDay,data.workHour,data.workMinute);

            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEffortEdit: function(items, controlRefId) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data['workDay'] = this.inlineEditFieldValue;
                    data['workHour'] = this.inlineEditFieldValue2;
                    data['workMinute'] = this.inlineEditFieldValue3;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.elements.empUtilEditMode = false;
            this.inlineEditRowId = '';
            this.inlineEditFieldValue = '';
            this.inlineEditFieldName = '';
            this.inlineEditFieldValue2 = '';
            this.inlineEditFieldName2 = '';
            this.inlineEditFieldValue3 = '';
            this.inlineEditFieldName3 = '';
            this.$nextTick(() => {
                this.$refs[controlRefId].setEffortInputData('', '', '');
            })
        },
        focusInlineEdit(cellId, rowId, fieldName,data,isEditMode, controlRefId) {
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = data.utilWorkHour;
            this.inlineEditFieldName = fieldName;

            this.inlineEditFieldValue2 = data.utilWorkMinute;
            this.inlineEditFieldName2 = 'utilWorkMinute';
            this.$nextTick(() => {
                this.$refs[controlRefId].setEffortInputData('', data.utilWorkHour, data.utilWorkMinute);
            })

            this.elements.empUtilEditMode = isEditMode;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items, controlRefId) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data['utilWorkHour'] = this.inlineEditFieldValue;
                    data['utilWorkMinute'] = this.inlineEditFieldValue2;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.elements.empUtilEditMode = false;
            this.inlineEditRowId = '';
            this.inlineEditFieldValue = '';
            this.inlineEditFieldName = '';
            this.inlineEditFieldValue2 = '';
            this.inlineEditFieldName2 = '';
            this.$refs[controlRefId].clearEffortInputData();
        },
        updateTaskEffort: function(rowId, controlRefId) {
            let workDay = this.$refs[controlRefId].getDays();
            let workHour = this.$refs[controlRefId].getHrs();
            let workMinute = this.$refs[controlRefId].getMins();
            let data = {
                id: rowId,  
                workDay: workDay ? workDay : '',
                workHour: workHour ? workHour : '',
                workMinute: workMinute ? workMinute : '',
            }
            if (data.workDay == '' && data.workHour == '' && data.workMinute == '') {
                iziToast.info({
                   message: 'Effort is required',
                   position: 'topCenter'
                });
                return;
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/effort/update', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId= '';
                        this.inlineEditFieldName = '';
                        this.getTabDetails();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        saveEmpUtil(data, controlRefId) {
            this.empUtilData.id = data.eutId;
            this.empUtilData.taskId = data.id;
            this.empUtilData.humanResourceId = this.resourceDetail.humanResourceId;
            this.empUtilData.vhextAccountId = data.vhextAccountId;
            this.empUtilData.utilizationDate = this.empUtilization.filterDate;
             console.log(this.$refs[controlRefId]) // <= accessing the dynamic ref
            this.empUtilData.workHour = this.$refs[controlRefId].getHrs();
            this.empUtilData.workMinute = this.$refs[controlRefId].getMins();
            this.empUtilData.createdById = localStorage.getItem("userId");

            if (Number(this.empUtilData.id) > 0) {
                this.updateEmpUtilization();
            } else {
                this.addEmpUtilization();
            }
        },
        addEmpUtilization: function() {

            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeutil/add', this.empUtilData)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.inlineEditRowId= '';
                    this.inlineEditFieldName = '';
                    this.getTabDetails();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            });
        },
        updateEmpUtilization: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeutiltask/update', this.empUtilData)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.inlineEditRowId= '';
                    this.inlineEditFieldName = '';
                    this.getTabDetails();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            });
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.selectedUserId = data.userId;
                this.resourceDetail.humanResourceId = data.id;
                this.resourceDetail.employeeId = data.employeeId;
                this.resourceDetail.name = data.name;
                this.resourceDetail.userId = data.userId;
                this.resourceDetail.workHour = data.workHour;
                this.resourceDetail.workMinute = data.workMinute;
                this.getTabDetails();
            }
        },
        resetFilter: function() {
            this.task.startDate = this.getDateValue(new Date());
            this.task.endDate = this.getDateValue(new Date());
            this.task.filterStartDate = this.getDateValue(new Date());
            this.task.filterEndDate = this.getDateValue(new Date());
            this.taskPerformance.startDate = this.getDateValue(new Date());
            this.taskPerformance.endDate = this.getDateValue(new Date());
            this.taskPerformance.filterStartDate = this.getDateValue(new Date());
            this.taskPerformance.filterEndDate = this.getDateValue(new Date());
            this.timeSheet.startDate = this.getDateValue(new Date());
            this.timeSheet.endDate = this.getDateValue(new Date());
            this.timeSheet.filterStartDate = this.getDateValue(new Date());
            this.timeSheet.filterEndDate = this.getDateValue(new Date());
            this.empUtilization.utilizationDate = this.getDateValue(new Date());
            this.empUtilization.filterDate = this.getDateValue(new Date());
            this.getTabDetails();
        },
        quickTask: function() {
            this.taskData.statusId = '';
            this.taskData.entityRecordMasterId = '';
            this.taskData.assignedToId = '';
            this.$refs.ermAssignmentsRef.onRefDataChange('Quick Task');
        },
        viewItemDetails: function(item) {
            
            if (item && item.length !== 0) {
                let data;
                if (item.length && item.length == 1) {
                    data = item[0];
                } else data = item;
                this.attachmentData.refEntity = 'task';
                this.attachmentData.refRecordId = data.id;
                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$refs.taskDetailPopupRef.getDetails(data.id, 'task');
                })
            }
        },
        timeConvert(n) {
            var num = n;
            var hours = (num / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);

            return rhours + " hrs " + rminutes + " mins";
        },
        formatDurationDHM(workHour, workMinute, workDay) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },
        calculatePerformance(estEffort, actEffort) {
            return actEffort ? Math.round((estEffort / actEffort) * 100) : 0;
        },
        getPerformanceRemark(percentage) {
            let output = '';
            if (percentage) {
                if (percentage <= 80)
                    output = 'Unacceptable';
                else if (percentage <= 90)
                    output = 'Needs Improvement';
                else if (percentage <= 100)
                    output = 'Meets Expectations';
                else if (percentage <= 130)
                    output = 'Exceeds Expectations';
                else
                    output = 'Outstanding';
            }
            return output;
        },
        vmxEffortInput:function(data){
            this.$refs.effortControl.setEffortInputData(data.day, data.hrs, data.mins);
        },
        vmxTotalEffortInput:function(data){
            this.$refs.totalEffortControl.setEffortInputData(data.day, data.hrs, data.mins);
        },
        vmxWorkEffortControl:function(data){
            this.$refs.workEffortControl.setEffortInputData(data.day, data.hrs, data.mins);
        },
    }
}
</script>