<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Corporate Group Companies List</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <!-- top to bottom -->
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title font-weight-bold mb-1"><span>Corp. Group Companies [{{corpGroupCompanyInfoSummary.length}}]</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">

                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="corpGroupCompanyInfoSummary" :fields="employeeFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" foot-clone>
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template #cell(logo)="data">
                                                        <img v-if="data.item.logo" alt="Profile Picture" class="Companylogo-img" :src="data.item.logo" />
                                                        <span v-else class="text-muted"> No Logo </span>
                                                    </template>

                                                    <template #cell(name)="data">
                                                        <div class="row no-gutters align-items-center">
                                                            <div class="col-auto">
                                                                <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                                                <b-avatar v-else class="mr-2"></b-avatar>
                                                            </div>
                                                            <div class="col">
                                                                <div class="mr-auto">{{ data.value }}</div>
                                                                <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                                            </div>
                                                        </div>
                                                    </template>   
                                                    <template #cell(department)="data">
                                                        <div>
                                                            {{ data.item.cmpShortName }}
                                                        </div>
                                                        <div class="text-muted">
                                                            {{ data.item.department }}
                                                        </div>
                                                    </template>   
                                                    <template #cell(contact)="data">
                                                        <p v-if="data.item.officePhone">
                                                            <i class="fa fa-phone ml-0 mr-2 action-link" aria-hidden="true"></i>
                                                            <span>{{data.item.officePhone}}</span>
                                                        </p>
                                                        <p v-if="data.item.alternateEmailId">
                                                            <i class="fa fa-envelope ml-0 mr-2 action-link" aria-hidden="true"></i>
                                                            <span>{{data.item.alternateEmailId}}</span>
                                                        </p>
                                                        <p v-if="data.item.website">
                                                            <i class="fa fa-globe ml-0 mr-2 action-link" aria-hidden="true"></i>
                                                            <span>{{data.item.website}}</span>
                                                        </p>
                                                    </template>                                                                                                                       
                                                    <template #cell(address)="data">
                                                        <p>
                                                            <span v-if="data.item.address1">{{data.item.address1}}, </span>                                                 
                                                            <span v-if="data.item.streetName">{{data.item.streetName}}, </span>
                                                        </p>
                                                        <p>
                                                            <span v-if="data.item.city">{{data.item.city}}, </span>
                                                            <span v-if="data.item.state">{{data.item.state}}, </span>                                               
                                                            <span v-if="data.item.country">{{data.item.country}}, </span>
                                                            <span v-if="data.item.pincode">{{data.item.pincode}}</span>
                                                        </p>
                                                    </template>

                                                    <template v-slot:foot()="data">
                                                        <span></span>
                                                    </template>

                                                    <template v-slot:foot(resCount)="data">
                                                        <strong>{{ sumColumn('resCount') }}</strong>
                                                    </template>              
                                                    <template v-slot:foot(directorCount)="data">
                                                        <strong>{{ sumColumn('directorCount') }}</strong>
                                                    </template>                                             
                                                    <template v-slot:foot(empCount)="data">
                                                        <strong>{{ sumColumn('empCount') }}</strong>
                                                    </template>                                             
                                                    <template v-slot:foot(nonEmpCount)="data">
                                                        <strong>{{ sumColumn('nonEmpCount') }}</strong>
                                                    </template>
                                                    <template v-slot:foot(userCount)="data">
                                                        <strong>{{ sumColumn('userCount') }}</strong>
                                                    </template>                                             

                                                    <template v-slot:empty="erm">
                                                        <div class="text-center">No Record Found</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),
            companyId: localStorage.getItem("vhextAccountId"),

            fromDate: this.getDateValue(new Date()),
            tillDate: this.getDateValue(new Date()),
            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),

            statusId: '',

            isBusy: false,
            employeeStatuses: [],
            corpCompanies: [],

            corpGroupCompanyInfoSummary: [],

            employeeFields: [
                { key: 'id', label: 'CID', sortable: true, class: 'text-center', sortDirection: 'desc' },
                { key: 'logo', label: 'Logo' },
                { key: 'companyName', label: 'Company', sortable: true, sortDirection: 'desc' },
                { key: 'shortName', label: 'Short Name', sortable: true, sortDirection: 'desc' },
                { key: 'contact', label: 'Contact Info' },
                { key: 'address', label: 'Address' },
                { key: 'resCount', label: 'Resources', class: 'text-center' },
                { key: 'directorCount', label: 'Directors', class: 'text-center' },
                { key: 'empCount', label: 'Employees', class: 'text-center' },
                { key: 'nonEmpCount', label: 'Non-employees', class: 'text-center' },
                { key: 'userCount', label: 'Users', class: 'text-center' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
    },

    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
        this.getEmployees();
    },
    methods: {
        getEmployees: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                statusId: this.statusId,
            }
            this.startDate = this.fromDate;
            this.endDate = this.tillDate;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/corporate/corpgroupcompanyinfo/summary', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.corpGroupCompanyInfoSummary = result.corpGroupCompanyInfoSummary;
                    this.totalRows = this.corpGroupCompanyInfoSummary.length;

                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                parentVhextAccountId: this.parentVhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/corporate/employeeattendance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.corpCompanies = result.corpGroupCompanyLookup;
                    this.employeeStatuses = result.employeeStatusLookup;
                })
        },
        sumColumn(column) {
          return this.corpGroupCompanyInfoSummary.reduce((acc, item) => acc + item[column], 0);
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.fromDate = this.getDateValue(this.startDate);
            this.tillDate = this.getDateValue(this.endDate);
        },
        clearData: function() {    
            this.startDate = this.getDateValue(new Date());
            this.endDate = this.getDateValue(new Date());
            this.fromDate = this.getDateValue(new Date());
            this.tillDate = this.getDateValue(new Date());
            this.statusId = '';
            this.getEmployees();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>