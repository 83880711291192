<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{ typeLookupTitle }} Settings</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <a v-if="typeLookupTitle" href="javascript:" class="d-block action-link" :title="'Add from Existing ' + typeLookupTitle + 's'" @click="showDialog=true;" v-b-modal.existingCustomTypeLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing {{ typeLookupTitle + '(s)' }}
                            </a>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="goalType" class="typeLookup active">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{typeLookupTitle}}
                                                </span>
                                                <span v-if="elements.editMode">Edit</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-add" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="duration">Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.customType.type.$error }">
                                                                <b-form-input id="title" v-model="customType.type" placeholder="Enter Type"></b-form-input>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.customType.type.$error && !$v.customType.type.required" class="text-danger">Please Enter Type</label>
                                                        </div>
                                                    </div>
                                                    <div v-if="entityName == 'financial_transaction_type'" class="col-2">
                                                        <div class="form-group">
                                                            <label>Amount Type</label>
                                                            <!-- <div class="required-field-block" :class="{ 'is-invalid': $v.customType.typeClass.$error }">
                                                                <select v-model="customType.typeClass"  :class="{ 'is-invalid': $v.customType.typeClass.$error }" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option value="1">CR</option>
                                                                    <option value="2">DR</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.customType.typeClass.$error && !$v.customType.typeClass.required" class="text-danger">Please select amount type</label> -->
                                                            <select v-model="customType.typeClass"  class="form-control">
<!--                                                                 <option value="">Select</option> -->
                                                                <option value="1">CR</option>
                                                                <option value="2">DR</option>
                                                            </select>
                                                        </div>                                                        
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="customType.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5 pull-right">
                                                        <label for="add" style="visibility: hidden;">Add</label>
                                                        <div class="input-group float-right ">
                                                            <button type="submit" title="Save" @click="saveCustomTypeData" class="btn btn-primary mb-0 mr-2 py-1 px-3">
                                                                <i class="fa fa-plus pr-2" v-if="!elements.editMode"></i>
                                                                <i class="fa fa-floppy-o pr-2" v-else ></i>
                                                                {{ elements.editMode ? 'Save' : 'Add'}}
                                                            </button>
                                                            <button type="submit" title="Clear" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table show-empty small stacked="md" sort-icon-left :items="customTypes" :fields="fields" responsive="sm" :current-page="customType.currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(typeClass)="data">
                                        <div v-if="entityName == 'financial_transaction_type'">
                                            {{data.item.typeClass == 1 ? 'CR' : 'DR'}} 
                                        </div>
                                        <div v-else>
                                            {{'N/A'}} 
                                        </div>

                                        
                                    </template>

                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox  v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit Types" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteCustomTypeLookup(data.item.id)" data-toggle="modal" data-target="#deleteCustomTypeLookup"><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="">
                                        <div class="text-center">No {{ typeLookupTitle ? typeLookupTitle : 'Record' }} found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="">
                                        <div class="text-center">{{ }}</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                
                                <b-pagination v-if="customTypes.length!=0" pills v-model="customType.currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddExistingCustomTypePopup v-if="showDialog" @refresh-lookup="getCustomTypeLookups" :popupDisplayName="typeLookupTitle" :entity="entity" @closeDialog="showDialog=false;" />
        <Dialog id="deleteCustomTypeLookup" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" :message="`Are you sure to delete this ${typeLookupTitle} ?`" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddExistingCustomTypePopup from '@/components/popup/lookup/AddExistingCustomTypePopup.vue';
import moment from 'moment';
export default {
    name: 'app',
    props: {
        entity: String,
        typeLookupTitle: String,
    },
    components: {
        AddExistingCustomTypePopup,
    },
    data() {
        return {
            showDialog: false,
             elements: {
                visible: false,
                editMode: false
            },
            isBusy: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            entityTypeId: 3,
            activeLookup: '',
            entityName: '',
            entityAccessTo: '',
            notEnabled: true,
            customType: {
                id: '',
                customTypeLookupId: '',
                entity: '',
                active: true,
                typeName: '',
                type: '',
                typeClass: '1',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                entityId: '',
                userId: '',
                createdById: localStorage.getItem("userId"),
            },
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            customEntityList: [],
            projectTypes: [],
            vhextProjectTypes: [],
            customTypes: [],
            customTypeFields: [
                { key: 'index', label: 'SN', },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'typeClass', label: 'Class', tdClass: 'setAmountTdClass', thStyle: "width: 20px"  },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc', class:"text-center" },
                { key: 'actions', label: 'Actions' }
            ],
            vsaCustomTypeFields: [
                { key: 'index', label: 'SN', },
                { key: 'id', label: 'Pk Id', sortable: true, sortDirection: 'desc' },
                { key: 'customTypeLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'entityId', label: 'Entity Id', sortable: true, sortDirection: 'desc' },
                // { key: 'typeName', label: 'Type Name', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'typeClass', label: '', tdClass: 'setAmountTdClass', thStyle: "width: 20px"  },
                // { key: 'typeClass', label: 'Type Class', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc', class:"text-center" },
                { key: 'actions', label: 'Actions' }
            ],

            search: {
                params: {
                    title: ""
                }
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        activeModuleId() {
            let moduleId = this.$store.state.adminSetting.moduleId;
            return moduleId ? String(moduleId).padStart(2, '0') : moduleId;
        },
        customEntities() {
            let customEntityList = [];
            if (this.activeModuleId) {
                // customEntityList = this.customEntityList.filter(data => (data.code && (this.getEntityCodeData(data.code).moduleId == this.activeModuleId)));

                customEntityList = this.customEntityList.filter(data => (data.moduleId == this.activeModuleId));
            } else {
                customEntityList = this.customEntityList;
            }
            if (customEntityList.length > 0) {
                this.activeLookup = customEntityList[0].id;
                this.typeLookupTitle = customEntityList[0].displayName;
                this.getCustomTypeLookups();
            }

            return customEntityList;
        },
        fields() {
            if(this.userTypeCode == 'VSA'){
                return this.vsaCustomTypeFields;
            }
            else
            {
                return this.customTypeFields;
            }

            
        }
    },
    validations: {
        customType: {
            type: {
                required
            },
            // typeClass: {
            //     required
            // }
        }
    },
    mounted() {
        this.getCustomTypeLookups();
        this.lookupData();
    },
    methods: {
        getCustomTypeLookups: function() {
            if (this.entity) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    projectTypeId: this.projectTypeId,
                    entity: this.entity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.customTypes = result.customTypeLookupList;         
                        if (this.customTypes != null)
                            this.totalRows = this.customTypes.length;
                    })
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/customentity/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    // this.projectTypes = result.projectTypeLookup;                                        
                    this.users = result.userLookup;
                    if (this.projectId == null || this.projectId == undefined || this.projectId == 0){
                        
                        if(result.vhextProjectTypeLookup.length > 0){
                            this.vhextProjectTypes = result.vhextProjectTypeLookup;
                            this.customType.projectTypeId = this.vhextProjectTypes[0].id;
                        }else{
                            this.customType.projectTypeId = this.projectTypes[0].id;
                        }
                    }else{
                        this.customType.projectTypeId  = this.projectTypeId;
                    }

                    // this.projectTypeId = this.projectTypes[0].id;  
                })

        },    
        onChange(event) {
            this.projectTypeId = event.target.value;

            this.getEntities();
        },        
        saveCustomTypeData: function() {
            this.$v.customType.$touch();
            if (!this.$v.customType.$invalid) {
                if (this.elements.editMode) {
                    this.updateCustomTypeLookup();
                } else {

                    this.addCustomTypeLookup();
                }
            }
        },
        addCustomTypeLookup: function() {
            if(this.entityName !== 'financial_transaction_type'){
                this.customType.typeClass = '';
            }
            this.customType.vhextAccountId = this.vhextAccountId;
            this.customType.projectId = this.entityAccessTo == 'Company' ? '' : this.projectId;
            this.customType.projectTypeId = this.entityAccessTo == 'Company' ? '' : this.projectTypeId;
            this.customType.entity = this.entity;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/add', this.customType)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: this.typeLookupTitle + ' Added',
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getCustomTypeLookups();
                    }
                })
        },
        updateCustomTypeLookup: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/update', this.customType)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: this.typeLookupTitle + ' Updated',
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getCustomTypeLookups();
                    }
                })
        },
        deleteCustomTypeLookup: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: this.typeLookupTitle + ' Deleted',
                            position: 'topRight'
                        });
                        this.getCustomTypeLookups();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.customType.entity = 'custom_type_lookup';
                this.customType.id = item.id;
                this.customType.active = 0;

                this.updateActivationState();

            } else {
                this.customType.entity = 'custom_type_lookup';
                this.customType.id = item.id;
                this.customType.active = 1;

                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.customType)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.customType.active = true;
                    this.getCustomTypeLookups();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        getEntityDetails(entity) {
            this.getCustomTypeLookups();
            this.activeLookup = entity.id;
            this.typeLookupTitle = entity.displayName;
            this.entityName = entity.entity;
            this.entityAccessTo = this.getEntityCodeData(entity.code).accessTo;
        },
        clearData: function() {
            this.customType.type = '';
            this.$v.customType.$reset();
            this.customType.typeClass = '1';
            this.elements.editMode = false;
            this.elements.visible = false;
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.customType.customTypeLookupId = data.customTypeLookupId;
            this.customType.active = data.active;
            this.customType.type = data.type;
            this.customType.typeName = data.typeName;
            this.customType.typeClass = data.typeClass;
            this.customType.vhextAccountId = data.vhextAccountId;
            this.customType.projectId = data.projectId;
            this.customType.projectTypeId = data.projectTypeId;
            this.customType.entityId = data.entityId;
            this.customType.entity = data.entity;
            this.customType.id = data.id;
        },
        deActivate: function(item) {
            this.$nextTick(() => {

                if (item.active == true) {
                    this.customType.id = item.id;
                    this.customType.active = 1;
                    this.customType.userId = this.userId;
                    this.updateCustomTypeLookupActiveState();
                } else {
                    this.customType.id = item.id;
                    this.customType.active = 0;
                    this.customType.userId = this.userId;
                    this.updateCustomTypeLookupActiveState();
                }
            })
        },
        
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        deleteProject: function(id) {

        },
        showTab: function() {
            console.log(this);
        },
        setAmountTdClass(value) {
            var amountType = value;
            if(amountType === '1')
                return 'green';
            else if(amountType === '2')
                return 'red';
        },

    }
}
</script>