<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Meeting Plan</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title pt-0" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Meeting</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                <span v-if="elements.viewMode">View</span> 
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.meetingPlan.title.$error }">
                                                                    <input type="text" maxlength="100" v-model="meetingPlan.title" class="form-control" id="title" placeholder="Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                            <label v-if="$v.meetingPlan.title.$error && !$v.meetingPlan.title.required" class="text-danger">Please enter Activity</label>
                                                        <label v-if="$v.meetingPlan.title.$error && !$v.meetingPlan.title.maxLength" class="text-danger">Activity must not exceed {{$v.meetingPlan.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                         <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="meetingTypeId">Type</label>
                                                                <select v-model="meetingPlan.meetingTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in meetingTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="purpose">Purpose</label>
                                                                <input type="text" maxlength="1000" v-model="meetingPlan.purpose" class="form-control" id="purpose" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="hostedBy">Hosted By</label>
                                                                <input type="text" maxlength="100" v-model="meetingPlan.hostedBy" class="form-control" id="hostedBy" >
                                                            </div>
                                                        </div>                                                    
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="participants">Participants</label>
                                                                <input type="text" maxlength="500" v-model="meetingPlan.participants" class="form-control" id="participants" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="frequency">Frequency</label>
                                                                <input type="text" maxlength="45" v-model="meetingPlan.frequency" class="form-control" id="frequency" >
                                                            </div>
                                                        </div>
                                                        <div class="col-1 p-0">
                                                            <div class="form-group">
                                                                <label for="startTime">Start Time</label>
                                                                <input type="time" v-model="meetingPlan.startTime" class="form-control" id="startTime" v-on:input="getDuration">
                                                            </div>
                                                        </div>
                                                        <div class="col-1 p-0">
                                                            <div class="form-group">
                                                                <label for="endTime">End Time</label>
                                                                <input type="time" v-model="meetingPlan.endTime" class="form-control" id="endTime" v-on:input="getDuration">
                                                            </div>
                                                        </div>

                                                        <div class="col-1">
                                                            <div class="form-group">
                                                                <label for="duration">Duration</label>
                                                                <input type="text" maxlength="45" v-model="meetingPlan.duration" class="form-control" id="duration" >
                                                            </div>
                                                        </div>


                                                         <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="locationLink">Location / Link</label>
                                                                <input type="text" maxlength="500" v-model="meetingPlan.locationLink" class="form-control" id="locationLink" >
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="communicationChannel">Communication Channel</label>
                                                                <input type="text" maxlength="100" v-model="meetingPlan.communicationChannel" class="form-control" id="communicationChannel" >
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="weekdays">Weekdays</label>
                                                                <input type="text" maxlength="100" v-model="meetingPlan.weekdays" class="form-control" id="weekdays" >
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewMeetingPlan">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-4">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="meetingPlan.meetingPlanId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="meetingPlan.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="type">Meeting Type</label>
                                                                <input type="text" class="form-control" id="type" disabled v-model='meetingPlan.type'>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="purpose">Purpose</label>
                                                                <input type="text" class="form-control" id="purpose" disabled v-model='meetingPlan.purpose'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="frequency">Frequency</label>
                                                                <input type="text" class="form-control" id="frequency" disabled v-model='meetingPlan.frequency'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="duration">Duration</label>
                                                                <input type="" name="" class="form-control" id="duration" disabled v-model='meetingPlan.duration' rows="4">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="hostedBy">Hosted By</label>
                                                                <input type="" name="" class="form-control" id="hostedBy" disabled v-model='meetingPlan.hostedBy' rows="4">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="participants">Participants</label>
                                                                <input type="text" class="form-control" id="participants" disabled v-model='meetingPlan.participants'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="locationLink">Location Link</label>
                                                                <input type="text" class="form-control" id="locationLink" disabled v-model='meetingPlan.locationLink'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="communicationChannel">Communication Channel</label>
                                                                <input type="" name="" class="form-control" id="communicationChannel" disabled v-model='meetingPlan.communicationChannel' rows="4">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="weekdays">Week Days</label>
                                                                <input type="" name="" class="form-control" id="weekdays" disabled v-model='meetingPlan.weekdays' rows="4">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="createdDt">Created Date</label>
                                                                <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(meetingPlan.createdDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="lastUpdatedDt">Updated Date</label>
                                                                <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(meetingPlan.lastUpdatedDt)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="meetingPlans" :fields="meetingPlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>                                
<!--                                 <template v-slot:cell(startDate)="data">
                                    <div class="text-center">{{data.value.substring(1, 4)}}</div>
                                </template>
 -->
                                <template v-slot:cell(actions)="data">
                                    <a title="Web Meeting Link" target="_blank" :href="data.item.locationLink"><i class="fa fa-external-link pl-2"></i></a>                                    
<!--                                     <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteMeetingPlan(data.item.id)" v-b-modal.deleteMeetingPlan><i class="fa fa-trash pl-2"></i></a>
                                </template>

                                <template v-slot:empty="scope">
                                    <div class="text-center">No Meeting Plan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="meetingPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
<!--                 <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                <label for="type">Type</label>
                                                <b-input-group :prepend="meetingPlan.meetingPlanId" class="mb-2">
                                                            <b-form-input aria-label="Type" disabled v-model="meetingPlan.type"></b-form-input>
                                                </b-input-group>
                                            </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group text-center">
                                                    <label for="status">Status</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{meetingPlan.value}}</span></p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="title">Title</label>
                                                    <input type="text" class="form-control" id="title" disabled v-model='meetingPlan.title'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="purpose">Purpose</label>
                                                    <input type="text" class="form-control" id="purpose" disabled v-model='meetingPlan.purpose'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="frequency">Frequency</label>
                                                    <input type="text" class="form-control" id="frequency" disabled v-model='meetingPlan.frequency'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="duration">Duration</label>
                                                    <input class="form-control" id="duration" disabled v-model='meetingPlan.duration' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="hostedBy">HostedBy</label>
                                                    <input class="form-control" id="hostedBy" disabled v-model='meetingPlan.hostedBy' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="participants">Participants</label>
                                                    <input type="text" class="form-control" id="participants" disabled v-model='meetingPlan.participants'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="locationLink">locationLink</label>
                                                    <input class="form-control" id="LocationLink" disabled v-model='meetingPlan.locationLink' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="communicationChannel">CommunicationChannel</label>
                                                    <input class="form-control" id="communicationChannel" disabled v-model='meetingPlan.communicationChannel' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="weekdays">Weekdays</label>
                                                    <input class="form-control" id="weekdays" disabled v-model='meetingPlan.weekdays' rows="4"></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="budget">Functions</label>
                                            <select id="durationDays" class="form-control">
                                                <option value="">Functions...</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="budget" style="visibility: hidden;">Search</label>
                                            <div class="input-group ">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-text input-group-append input-group-addon">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Reference Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">meetingPlan Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No meetingPlan found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteMeetingPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete meeting plan?" />
        <MeetingDetailPopup v-if="showDialog" ref="meetingDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />        
    </div>
</template>
<script>

import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import MeetingDetailPopup from '@/components/popup/itemdetail/MeetingDetailPopup.vue';

export default {
    name: 'app',
    components: {
        TypeLookup,
        MeetingDetailPopup
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            showDialog: false,
            notEnabled: true,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            typeEntity: 'meeting_type',
            
            typeLookupTitle: 'Meeting Plan Type Settings',
            meetingPlan: {
                entity: 'meeting_plan',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,               

                id: '',
                meetingTypeId: '',
                title: '',
                type: '',
                purpose: '',
                frequency: '',
                startTime: '',
                endTime: '',
                duration: '',
                hostedBy: '',
                participants: '',
                locationLink: '',
                communicationChannel: '',
                weekdays: '',
                createdDt: '',
                lastUpdatedDt: ''              
            },

            detailViewData: {},
            referencePopupId: 0,

            meetingTypes: [],
            users: [],
            status: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            meetingPlans: [],
            meetingPlanFields: [
                { key: 'meetingPlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'purpose', label: 'Purpose', sortable: true, sortDirection: 'desc' },
                { key: 'hostedBy', label: 'Hosted By',  sortable: true, sortDirection: 'desc' },
                { key: 'participants', label: 'Participants', sortable: true, sortDirection: 'desc' },
                { key: 'frequency', label: 'Frequency', sortable: true, sortDirection: 'desc' },  
                { key: 'startTime', label: 'Start Time', sortable: true, sortDirection: 'desc' },  
                { key: 'endTime', label: 'End Time', sortable: true, sortDirection: 'desc' },  
                { key: 'duration', label: 'DUR[HH:MM]', sortable: true, sortDirection: 'desc' },  
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getMeetingPlan();
        this.lookupData();
    },
    validations: {
        meetingPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
           
        }
    },
    methods: {
        getMeetingPlan: function() {
            let data = {
                entityRecordMasterId: this.meetingPlan.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/meeting/meetingplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.meetingPlans = result.meetingPlanList;
                    this.totalRows = this.meetingPlans.length;
                })
        },
       lookupData: function() {
            let data = {
                projectId: this.meetingPlan.projectId,
                vhextAccountId: this.meetingPlan.vhextAccountId,
                entity: this.meetingPlan.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/meeting/meetingplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.meetingTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                    this.status = result.statusLookup;
                    console.log(result);     
                })

        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            this.$v.meetingPlan.$touch();
            this.meetingPlan.startTime = this.meetingPlan.startTime+":00";
            this.meetingPlan.endTime = this.meetingPlan.endTime+":00";
            console.log(this.meetingPlan.startTime);
            if (!this.$v.meetingPlan.$invalid) {
                this.showOverlay = true;
                if (this.elements.editMode) {
                    this.updateMeetingPlan();
                } else {
                    this.saveMeetingPlan();
                }
            }
        },
        saveMeetingPlan: function() {
            console.log(this.meetingPlan);
            axios.post(this.$store.getters.getAPIBasePath + '/meeting/meetingplan/add', this.meetingPlan)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMeetingPlan();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateMeetingPlan: function() {
                axios.post(this.$store.getters.getAPIBasePath + '/meeting/meetingplan/update', this.meetingPlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getMeetingPlan();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteMeetingPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/meeting/meetingplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getMeetingPlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.meetingPlan.meetingPlanId = '';
            this.meetingPlan.title = '';
            this.meetingPlan.meetingTypeId = '';
            this.meetingPlan.purpose = '';
            this.meetingPlan.frequency = '';
            this.meetingPlan.duration = '';
            this.meetingPlan.startTime = '';
            this.meetingPlan.endTime = '';
            this.meetingPlan.hostedBy = '';
            this.meetingPlan.participants = '';
            this.meetingPlan.locationLink = '';
            this.meetingPlan.communicationChannel = '';
            this.meetingPlan.weekdays = '';
            this.meetingPlan.id = '';
            this.$v.meetingPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.meetingPlan.meetingPlanId = data.meetingPlanId;
            this.meetingPlan.title = data.title;
            this.meetingPlan.meetingTypeId = data.meetingTypeId;
            this.meetingPlan.purpose = data.purpose;
            this.meetingPlan.frequency = data.frequency;
            this.meetingPlan.duration = data.duration;
            this.meetingPlan.startTime = data.startTime.substring(0, 5);
            this.meetingPlan.endTime = data.endTime.substring(0, 5);
            this.meetingPlan.hostedBy = data.hostedBy;
            this.meetingPlan.participants = data.participants;
            this.meetingPlan.locationLink = data.locationLink;
            this.meetingPlan.communicationChannel = data.communicationChannel;
            this.meetingPlan.weekdays = data.weekdays;
            this.meetingPlan.id = data.id;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.showDetail = true;
            this.meetingPlan.meetingPlanId = data.meetingPlanId;
            this.meetingPlan.title = data.title;
            this.meetingPlan.meetingTypeId = data.meetingTypeId;
            this.meetingPlan.purpose = data.purpose;
            this.meetingPlan.frequency = data.frequency;
            this.meetingPlan.duration = data.duration;
            this.meetingPlan.startTime = data.startTime.substring(0, 5);
            this.meetingPlan.endTime = data.endTime.substring(0, 5);
            this.meetingPlan.hostedBy = data.hostedBy;
            this.meetingPlan.participants = data.participants;
            this.meetingPlan.locationLink = data.locationLink;
            this.meetingPlan.communicationChannel = data.communicationChannel;
            this.meetingPlan.weekdays = data.weekdays;
            this.meetingPlan.createdDt = data.createdDt;
            this.meetingPlan.lastUpdatedDt = data.lastUpdatedDt;
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.meetingDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },

        getDuration: function() {
            console.log(this.meetingPlan.endTime, this.meetingPlan.startTime);

            var timeDiff = this.timeDiff(this.meetingPlan.startTime, this.meetingPlan.endTime);

            this.meetingPlan.duration = timeDiff;
            console.log(timeDiff);
        },
        timeDiff: function(start, end) {
            start = start.split(":");
            end = end.split(":");
            var startDate = new Date(0, 0, 0, start[0], start[1], 0);
            var endDate = new Date(0, 0, 0, end[0], end[1], 0);

            console.log(startDate, endDate);

            var diff = endDate.getTime() - startDate.getTime();
            var hours = Math.floor(diff / 1000 / 60 / 60);
            diff -= hours * 1000 * 60 * 60;
            var minutes = Math.floor(diff / 1000 / 60);

            // If using time pickers with 24 hours format, add the below line get exact hours
            if (hours < 0)
               hours = hours + 24;

            return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
            }


    }
}
</script>