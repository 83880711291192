import AccountHeadSetting from '@/views/finance/AccountHead.vue';
import Activity from '@/views/process/Activity.vue';
import AssetType from '@/views/asset/AssetType.vue';
import CalendarSetting from '@/views/communication/CalendarSettings.vue';
import ClientProfile from '@/views/user/ClientProfile.vue';
import CompanyProfile from '@/views/company/CompanyProfile.vue';
import Country from '@/views/settings/country/Country.vue'
import Currency from '@/views/settings/Currency.vue'
import CustomCategoryLookup from '@/views/settings/lookup/CustomCategoryLookup.vue'
import CustomTypeLookup from '@/views/settings/lookup/CustomTypeLookup.vue'
import Deliverables from '@/views/process/Deliverable.vue';
import Department from '@/views/company/Department.vue';
import Designation from '@/views/resource/Designation.vue';
import EmailAccSettings from '@/views/settings/email/EmailAccountSettings.vue'
import Email from '@/views/tools/Email.vue'
import IndustrySetting from '@/views/company/Industry.vue';
import LeaveType from '@/views/resource/LeaveType.vue';
import ManageStandardModules from '@/views/menu/ManageStandardModules.vue';
import MembershipManagement from '@/views/resource/ProjectMembershipMgmt.vue';
import MultiUserRole from '@/views/settings/MultiUserRole.vue'
import PayslipComponents from '@/views/finance/PayslipComponents.vue';
import PayrollConfig from '@/views/finance/PayslipConfig.vue';
import PerformanceAreaSetting from '@/views/resource/EmpPerformanceArea.vue';
import PerformanceRatingScale from '@/views/resource/EmpPerformanceRatingScale.vue';
import PrimeSettings from '@/views/settings/CompanySetting.vue';
import Priority from '@/views/settings/lookup/Priority.vue'
import PRMProfile from '@/views/user/PRMProfile.vue';
import Process from '@/views/process/Process.vue';
import ProjectSettings from '@/views/settings/ProjectSettings.vue'
import Resource from '@/views/resource/Resource.vue';
import ReviewApprovalConfig from '@/views/company/ReviewConfig.vue';
import RolesandResponsibilities from '@/views/resource/RoleResponsibility.vue';
import Severity from '@/views/settings/lookup/Severity.vue'
import TaxConfig from '@/views/finance/TaxConfig.vue';
import TaxSlabSetting from '@/views/finance/TaxSlabSetting.vue';
import TechnologyandSkills from '@/views/resource/TechnologySkill.vue';
import VMXAccountUserRole from '@/views/vhextaccount/VMXAccountUserRole.vue'
import VMXAccountUserType from '@/views/vhextaccount/VMXAccountUserType.vue'
import WorkType from '@/views/resource/WorkType.vue';
import WorkflowAutomationConfig from '@/views/company/WorkflowConfig.vue';
import WorkScheduleSetting from '@/views/company/WorkShift.vue';

export default [
    {
        path: '/landing/admset/accounthead',
        name: 'admsetaccounthead',
        component: AccountHeadSetting,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/activity',
        name: 'admsetactivity',
        component: Activity,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/assettype',
        name: 'admsetassettype',
        component: AssetType,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/calset',
        name: 'admsetcalset',
        component: CalendarSetting,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/cliprofile',
        name: 'admsetcliprofile',
        component: ClientProfile,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/cmpprofile',
        name: 'admsetcmpprofile',
        component: CompanyProfile,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/country',
        name: 'admsetcountry',
        component: Country,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/currency',
        name: 'admsetcurrency',
        component: Currency,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/customcategorylookup',
        name: 'admsetcustomcategorylookup',
        component: CustomCategoryLookup,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/customtypelookup',
        name: 'admsetcustomtypelookup',
        component: CustomTypeLookup,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/deliverable',
        name: 'admsetdeliverable',
        component: Deliverables,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/department',
        name: 'admsetdepartment',
        component: Department,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/designation',
        name: 'admsetdesignation',
        component: Designation,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/emaset',
        name: 'admsetemaset',
        component: EmailAccSettings,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/email',
        name: 'admsetemail',
        component: Email,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/industry',
        name: 'admsetindustry',
        component: IndustrySetting,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/leavetype',
        name: 'admsetleavetype',
        component: LeaveType,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/mngmdl',
        name: 'admsetmngmdl',
        component: ManageStandardModules,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/membershipmanage',
        name: 'admsetmembershipmanage',
        component: MembershipManagement,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/multiuserrole',
        name: 'admsetmultiuserrole',
        component: MultiUserRole,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/payslipcomp',
        name: 'admsetpayslipcomp',
        component: PayslipComponents,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/payslipconfig',
        name: 'admsetpayslipconfig',
        component: PayrollConfig,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/empperformancearea',
        name: 'admsetempperformancearea',
        component: PerformanceAreaSetting,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/prfratingscale',
        name: 'admsetprfratingscale',
        component: PerformanceRatingScale,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/primesetting',
        name: 'admsetprimesetting',
        component: PrimeSettings,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/priority',
        name: 'admsetpriority',
        component: Priority,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/prmprofile',
        name: 'admsetprmprofile',
        component: PRMProfile,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/process',
        name: 'admsetprocess',
        component: Process,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/prjset',
        name: 'admsetprjset',
        component: ProjectSettings,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/resource',
        name: 'admsetresource',
        component: Resource,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/reviewapprovalconfig',
        name: 'admsetreviewapprovalconfig',
        component: ReviewApprovalConfig,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/rolrsp',
        name: 'admsetrolrsp',
        component: RolesandResponsibilities,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/severity',
        name: 'admsetseverity',
        component: Severity,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/taxconfig',
        name: 'taxconfig',
        component: TaxConfig,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/taxslabsetting',
        name: 'taxslabsetting',
        component: TaxSlabSetting,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/techskill',
        name: 'admsettechskill',
        component: TechnologyandSkills,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/vmxurl',
        name: 'admsetvmxurl',
        component: VMXAccountUserRole,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/vmxutp',
        name: 'admsetvmxutp',
        component: VMXAccountUserType,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/workflowautoconfig',
        name: 'admsetworkflowautoconfig',
        component: WorkflowAutomationConfig,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/workschedule',
        name: 'admsetworkschedule',
        component: WorkScheduleSetting,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/worktype',
        name: 'worktype',
        component: WorkType,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' } 
    },


];