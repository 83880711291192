import CalendarEvent from '@/views/communication/CalendarEvent.vue'
import CalendarSetting from '@/views/communication/CalendarSettings.vue';
import ChatBox from '@/views/communication/ChatBox.vue'
import CommunicationPlan from '@/views/communication/CommunicationPlan.vue'
import CommunicationPlanMaster from '@/views/communication/CommunicationPlanMaster.vue'
import CommunicationPlanMasterList from '@/views/communication/CommunicationPlanMasterList.vue'
import LeaveTimeCalendar from '@/views/communication/LeaveTimeCalendar.vue'
import Contact from '@/views/communication/Contact.vue'
import Mailbox from '@/views/communication/Mailbox.vue'
import MeetingMinute from '@/views/communication/MeetingMinute.vue'
// import MyMailbox from '@/views/communication/MyMailbox.vue'
import AddMeetingMinute from '@/views/communication/AddMeetingMinute.vue'
import MeetingMinuteMaster from '@/views/communication/MeetingMinuteMaster.vue'
import MeetingMinuteMasterList from '@/views/communication/MeetingMinuteMasterList.vue'
import MeetingPlan from '@/views/communication/MeetingPlan.vue'
import MeetingPreparation from '@/views/communication/MeetingPreparation.vue'
import MeetingPreparationMaster from '@/views/communication/MeetingPreparationMaster.vue'
import MeetingPreparationMasterList from '@/views/communication/MeetingPreparationMasterList.vue'
import NotePad from '@/views/communication/NotePad.vue'
import ManageNotification from '@/views/communication/ManageNotification.vue'
import ReportingPlan from '@/views/communication/ReportingPlan.vue'
import UserNotification from '@/views/communication/UserNotification.vue'

export default [{
        path: '/calevt',
        name: 'calevt',
        component: CalendarEvent,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/landing/calset',
        name: 'lancalset',
        component: CalendarSetting,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/chatbox',
        name: 'chatbox',
        component: ChatBox,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/communicationplan/:ermId',
        name: 'communicationplan',
        component: CommunicationPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/cplmas',
        name: 'cplmas',
        component: CommunicationPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/cplmas/list',
        name: 'cplmaslist',
        component: CommunicationPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/cal',
        name: 'cal',
        component: LeaveTimeCalendar,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {
        path: '/mailbox',
        name: 'mailbox',
        component: Mailbox,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },

    {
        path: '/meetingminute/:ermId',
        name: 'meetingminute',
        component: MeetingMinute,
        meta: { accessedby: 'PRM|EMP|TMB|CAM|VSA' }
    },
    // {
    //     path: '/mymailbox',
    //     name: 'mymailbox',
    //     component: MyMailbox,
    //     meta: { accessedby: 'PRM|EMP|TMB|CAM|VSA' }
    // },
    {
        path: '/momadd',
        name: 'momadd',
        component: AddMeetingMinute,
        meta: { accessedby: 'PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/mmnmas',
        name: 'mmnmas',
        component: MeetingMinuteMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/mmnmas/list',
        name: 'mmnmaslist',
        component: MeetingMinuteMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/meetingplan/:ermId',
        name: 'meetingplan',
        component: MeetingPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/meetingpreparation/:ermId',
        name: 'meetingpreparation',
        component: MeetingPreparation,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/mpnmas',
        name: 'mpnmas',
        component: MeetingPreparationMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/mtpmas/list',
        name: 'mtpmaslist',
        component: MeetingPreparationMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/notepad',
        name: 'notepad',
        component: NotePad,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/landing/mngntf',
        name: 'lanmngntf',
        component: ManageNotification,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/reportingplan/:ermId',
        name: 'reportingplan',
        component: ReportingPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/landing/notif',
        name: 'lannotif',
        component: UserNotification,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },

];