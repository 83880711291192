<template>
    <div class="createUserAccountPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered title="Create User Account" body-class="py-3" footer-class="py-2 px-3" header-class="py-2 px-3" no-enforce-focus @hide="closeDialog" id="createUserAccountPopup" ref="createUserAccountPopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <!-- <p class="text-center fs-16">Are you sure to activate this user?</p> -->
                    <div class="row mb-2">
                        <b-col cols="4">User Type</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userTypeData.id.$error }">
                                <select v-model="userData.userTypeData" id="userTypeData" :class="{ 'is-invalid': $v.userData.userTypeData.id.$error }" @change="clearUserFields" class="form-control">
                                    <option :value="{id: '', userType: '', userTypeCode: ''}">Select</option>
                                    <option v-for="(info, index) in vmxAccountUserTypes" :value="{id: info.id, userType: info.value, userTypeCode:info.userTypeCode}" >{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <span v-if="$v.userData.userTypeData.id.$error && !$v.userData.userTypeData.id.required" class="text-danger">Please select user type</span>
                        </b-col>
                    </div>
                    <div class="row mb-2">
                        <b-col cols="4">User Role</b-col>
                        <b-col>
                            <div class="row no-gutters">
                                <div class="col-10">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userRoleData.id.$error }">
                                        <select v-model="userData.userRoleData" id="userRoleData" :class="{ 'is-invalid': $v.userData.userRoleData.id.$error }" @change="clearUserFields" class="form-control">
                                            <option :value="{id: '', customUserRoleId: '', customUserRole: ''}">Select</option>
                                            <option v-for="(info, index) in customUserRoles" :value="{id: info.id, customUserRoleId:info.id, customUserRole:info.value}" >{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <span v-if="$v.userData.userRoleData.id.$error && !$v.userData.userRoleData.id.required" class="text-danger">Please select user role</span>
                                </div>
                                <div class="col-2 text-center form-control">
                                    <a title="Manage User Role" class="action-link d-inline-block" @click="$router.push({ name: 'admsetmngurr' })" href="javascript:" ><i class="fa fa-cogs" aria-hidden="true"></i></a>
                                    <!-- <button type="submit" title="Reset User Role - Modules and Menus" v-b-modal.userRoleResetDialog @click="" class="btn btn-outline-primary sbtn btn-md py-1 px-2">
                                        <i class="fa fa-cogs"></i>
                                    </button>  -->     
                                </div>
                            </div>
                        </b-col>
                    </div>
                    <div class="row mb-2">
                        <b-col cols="4">Name</b-col>
                        <b-col>
                            <div class="row no-gutters">
                                <div class="col-10">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userName.$error }">
                                        <select v-if="elements.showDropdownName && userData.userTypeData.userTypeCode != 'CLI'" v-model="userData.member" @change="getHumanResource(userData.member.id, 'Users');userData.userName=userData.member" :class="{ 'is-invalid': $v.userData.userName.$error }" class="form-control">
                                            <option value="">Select Resource</option>
                                            <option v-for="(info, index) in resourceLookup" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                        </select>
                                        <select v-if="elements.showDropdownName && userData.userTypeData.userTypeCode === 'CLI'" v-model="userData.member" @change="getClient(userData.member.id);userData.userName=userData.member" :class="{ 'is-invalid': $v.userData.userName.$error }" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in clientLookup" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                        </select>
                                        <input v-if="!elements.showDropdownName" type="text" class="form-control" v-model="userData.userName" :class="{ 'is-invalid': $v.userData.userName.$error }" placeholder="Enter Name">
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.userData.userName.$error && !$v.userData.userName.required" class="text-danger">Please enter user name</label>
                                </div>
                                <div class="col-2 text-center form-control">
                                    <a href="javascript:" class="button-add action-link text-center" @click="toggleUserDropdown">
                                        <i v-if="!elements.showDropdownName" title="Select existing members" class="fa fa-lg fa-caret-down"></i>
                                        <i v-if="elements.showDropdownName" title="Add New Resource" class="fa fa-lg fa-plus-square-o"></i>
                                    </a>
                                </div>
                            </div>
                        </b-col>
                    </div>
                    <div class="row mb-2">
                        <b-col cols="4">Email Id</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.userData.emailId.$error }">
                                <input type="email" autocomplete="nope" :class="{ 'is-invalid': $v.userData.emailId.$error }" class="form-control" v-model="userData.emailId" id="emailId" placeholder="User Account Login Email Id">
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.userData.emailId.$error" class="invalid-feedback">
                                <span v-if="!$v.userData.emailId.required" class="text-danger">EmailId is required</span>
                                <span v-if="!$v.userData.emailId.email">Email is not valid</span>
                            </div>
                            <label v-if="elements.alreadyExists" class="text-danger">Email already exists.</label>
                        </b-col>
                    </div>
                    <div class="row mb-2">
                        <b-col cols="4">Phone</b-col>
                        <b-col>
                            <input type="text" class="form-control" v-model="userData.phone" id="ownerName" placeholder="Phone">
                        </b-col>
                    </div>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="createUserAccount" class="float-right mr-2">
                        <b-spinner  v-if="showOverlay"  small label="Small Spinner" class="mr-2"></b-spinner>Create Account
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
    data() {
        return {
            showOverlay: false,

            elements: {
                visible: false,
                showDropdownName: true,
                alreadyExists: false,
            },
            error: false,
            userData: {
                member: '',
                userName: '',
                userTypeId: '',
                userTypeCode:'',
                userType: '',
                userTypeData: {
                    id: '',
                    userType: '',
                    userTypeCode: '',
                },
                userRoleData: {
                    id: '',
                    customUserRoleId: '',
                    customUserRole: '',
                },
                jobTitle: '',
                emailId: '',
                phone: '',
                humanResourceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                createdById: localStorage.getItem("userId"),
            },

            humanResourceData: [],
            clientData: [],

            vmxAccountUserTypes: [],
            customUserRoleLookup: [],
            humanResourcesLookup: [],
            clientLookup: [],
        }
    },
    computed: {
        resourceLookup() {
            return this.humanResourcesLookup.filter(data => data.userId == null);
        },
        customUserRoles() {
            return this.customUserRoleLookup.filter(data => data.userTypeId == this.userData.userTypeData.id);
        },
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        userData: {
            userName: {
                required
            },
            emailId: {
                required,
                email
            },
            userTypeData: {
                id: {
                    required
                }
            },
            userRoleData: {
                id: {
                    required
                }
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.humanResourcesLookup = result.humanResourceLookup;
                    this.clientLookup = result.clientLookup;
                    this.vmxAccountUserTypes = result.vmxAccountUserTypeLookup;
                    this.customUserRoleLookup = result.customUserRoleLookup;

                })
        },
        createUserAccount: function() {
            if (this.elements.showDropdownName == true) {
                this.userData.userName = this.userData.member.text;
                this.userData.humanResourceId = this.userData.member.id;
            } else{
                this.userData.humanResourceId = '';
            }      

            if(this.userData.userTypeData.id != null){
                this.userData.userTypeId = this.userData.userTypeData.id;
                this.userData.userType = this.userData.userTypeData.userType;
                // this.userData.userTypeCode = this.userData.userType == 'Client' ? 'CLI' : 'TMB';
                this.userData.userTypeCode = this.userData.userType == 'Client' ? 'CLI' : this.userData.userTypeData.userTypeCode;
            }
            this.userData.customUserRoleId = this.userData.userRoleData.id;

            // return;
            this.$v.userData.$touch();
            if (!this.$v.userData.$invalid) {
                this.showOverlay = true;
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.userData)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            this.activationMailSentToast(this.userData.emailId);
                            // this.$refs.humanResources.getHumanResource();
                            this.clearDataUser();
                            this.$bvModal.hide('createUserAccountPopup');
                            this.$emit('refresh-data');
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    })
            } else {
                this.error = true;
            }
        },
        getHumanResource: function(id) {
            if (id !== undefined && id !== '') {
                this.showOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + id)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.humanResourceData = result.humanResourceView;
                        this.userData.emailId = this.humanResourceData.emailId;
                        this.userData.jobTitle = this.humanResourceData.position;
                        this.userData.phone = this.humanResourceData.mobile;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: error.message,
                           position: 'topRight'
                       });
                    })
            }
        },
        getClient: function(id) {
            if (id !== undefined || id !== '') {
                let data = {
                    id: id
                }
                axios.post(this.$store.getters.getAPIBasePath + '/client/view', data)
                    .then((response) => {
                        let result = response.data;
                        this.clientData = result.client;
                        this.userData.emailId = this.clientData.emailId;
                        this.userData.jobTitle = this.clientData.position;
                        this.userData.phone = this.clientData.mobile;
                    })
            }
        },
        clearDataUser: function() {
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.userTypeId = '';
            this.userData.userType = '';
            this.userData.userTypeData.id = '';
            this.userData.userTypeData.userType = '';
            this.userData.userTypeData.userTypeCode = '';
            this.userData.userRoleData.id = '';
            this.userData.userRoleData.customUserRoleId = '';
            this.userData.userRoleData.customUserRole = '';
            this.userData.jobTitle = '';
            this.userData.emailId = '';
            this.userData.phone = '';
            this.error = false;
            this.elements.alreadyExistsPM = false;
            this.$v.userData.$reset();

        },
        clearUserFields: function() {
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.emailId = '';
            this.userData.phone = '';
        },
        toggleUserDropdown() {
            this.elements.showDropdownName = !this.elements.showDropdownName;
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.emailId = '';
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>