<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered :title="projectTeam.editMode ? 'Edit Team Member' : 'Add Team Member'" no-enforce-focus @hide="closeDialog" id="add-team-member" ref="addTMBRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="4">Member Name</b-col>
                        <b-col v-if="projectTeam.editMode" class="pl-0">
                            <input type="text" v-model="projectTeam.memberName" disabled class="form-control" placeholder="Position">
                        </b-col>
                        <template v-else>
                            <b-col class="pl-0">
                                <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.memberName.$error }">
                                    <b-input-group>
                                        <select v-model="projectTeam.member" @change="showHRDetails(projectTeam.member)" id="member" class="form-control">
                                            <option :value="{id: '', text: '',userId: ''}">Select</option>
                                            <option v-for="(info, index) in humanResources" :value="{id: info.id, text:info.value,userId:info.userId}">{{info.value}}</option>
                                        </select>
                                        <b-input-group-append class="append-0">
                                            <a title="Add New Member" class="cust-setting-icon action-link" href="javascript:" v-b-modal.humanResourcePopup><i class="fa fa-cog" aria-hidden="true"></i></a>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <div class="required-icon"></div>
                                </div>
                                <label v-if="$v.projectTeam.memberName.$error && !$v.projectTeam.memberName.required" class="text-danger">Please select Team Member</label>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4">Resource Type</b-col>
                        <b-col class="pl-0">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.resourceTypeId.$error }">
                                <b-input-group>
                                    <select v-model="projectTeam.resourceTypeId" id="position" class="form-control" :class="{ 'is-invalid': $v.projectTeam.resourceTypeId.$error }">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in resourceLookup" :value="info.id">{{info.value}}</option>
                                    </select>
                                    <b-input-group-append class="append-0">
                                        <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                    </b-input-group-append>
                                </b-input-group>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.projectTeam.resourceTypeId.$error && !$v.projectTeam.resourceTypeId.required" class="text-danger">Please select Resource Type</label>
                        </b-col>                        
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4">Member Role</b-col>
                        <b-col class="pl-0">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.customUserRoleId.$error }">
                                <select v-model="projectTeam.customUserRoleId" class="form-control" :disabled="projectData.projectManagerId && projectData.projectManagerId == projectTeam.member.userId">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in customUserRoles" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.projectTeam.customUserRoleId.$error && !$v.projectTeam.customUserRoleId.required" class="text-danger">Please select Member Role</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4">Email</b-col>
                        <b-col class="pl-0">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.emailId.$error, 'is-invalid': error }">
                                <input type="text" v-model="projectTeam.emailId" :disabled="projectTeam.editMode || !showEmailIdEdit" @focus="elements.alreadyExists=false;error=false" class="form-control" id="email" maxlength="100" placeholder="Please enter Email ID">
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.projectTeam.emailId.$error && !$v.projectTeam.emailId.email" class="text-danger">Enter Valid Email Id</label>
                            <label v-if="$v.projectTeam.emailId.$error && !$v.projectTeam.emailId.required" class="text-danger">Please enter Email Id</label>
                            <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4">Required From</b-col>
                        <b-col class="pl-0">
                            <input v-model="projectTeam.requiredFrom" type="Date" class="form-control" placeholder="Required From">
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="4">Required Till</b-col>
                        <b-col class="pl-0">
                            <input v-model="projectTeam.requiredTill" type="Date" class="form-control" placeholder="Required Till">
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="closeDialog">Cancel</b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ projectTeam.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ projectTeam.editMode ? 'Update Member' : 'Add Member' }}</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <AddResourceLookup :resourceLookupTitle="resourceLookupTitle" :resourceCategoryId="1" @refresh-lookup="lookupData" />
        <HumanResourcesPopup entity="project_team_master" :humanResourcePopupTitle="humanResourcePopupTitle" @refresh-lookup="lookupData" />
        <JobTitlePopup ref="typeLookupModal" entity="resource_plan_master" :jobTitlePopupTitle="jobTitlePopupTitle" @refresh-lookup="lookupData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddResourceLookup from '@/components/popup/lookup/AddResourceLookup.vue';
import HumanResourcesPopup from '@/components/popup/lookup/HumanResourcesPopup.vue';
import JobTitlePopup from '@/components/popup/lookup/JobTitlePopup.vue';
export default {
    props: {
        projectTeamData: Object,
    },
    components: {
        AddResourceLookup,
        HumanResourcesPopup,
        JobTitlePopup
    },
    data() {
        return {
            projectTeam: this.projectTeamData,
            elements: {
                alreadyExists: false
            },
            showOverlay: false,
            showEmailIdEdit: false,
            error: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: "project_member",
            projectData: {
                projectManagerId: '',
                customUserRoleId: '',
            },

            resourceLookupTitle: 'Resources',
            humanResourcePopupTitle: 'Resources',
            jobTitlePopupTitle: "Job Title",

            projectTeam: {
                id: '',
                projectMemberId: '',
                userId: '',
                userTypeId: '',
                userType: '',
                userTypeCode: '',
                customUserRoleId: '',
                memberTypeId: '',
                memberTypeCode: '',
                memberName: '',
                active: true,
                isActiveUser: false,
                member: {
                    id:'',
                    text:'',
                    userId:'',
                },
                resourceTypeId: '',
                humanResourceId: '',
                emailId: '',
                phone: '',
                position: '',
                preparedBy: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                vmxServiceCode: localStorage.getItem("vmxServiceCode"),
                projectId: '',

                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false
            },


            projectTeams: [],
            humanResources: [],
            resourceLookup: [],
            customUserRoles: [],
        }
    },
    mounted() {
        this.projectTeam = this.projectTeamData;
        console.log(this.projectTeam)
        this.lookupData();
        this.getProjectManagerId();

        if(this.projectTeam.entityRecordMasterId){

            this.getProjectTeam(this.projectTeam.entityRecordMasterId);
        }
        
    },
    validations: {
        projectTeam: {
            memberName: {
                required
            },
            customUserRoleId: {
                required,
            },
            resourceTypeId: {
                required,
            },
            emailId: {
                required,
                email,
            }
        },
    },
    methods: {
        getProjectTeam: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/list', data)
                .then((response) => {
                
                    let result = response.data;
                    if (result.projectTeamList && result.projectTeamList.length > 0) {
                        this.projectTeams = result.projectTeamList;
                    } else this.projectTeams = [];
                    if(this.projectData.projectManagerId && this.projectData.projectManagerId == this.projectTeam.member.userId) {
                        this.projectTeam.customUserRoleId = this.projectData.customUserRoleId;
                    }
                })
                .catch(error => {
                    console.log(error)}
                );
        },
        getProjectManagerId: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/project/getprmdetail', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.projectData = result.projectData;
                })
                .catch(error=> {
                     this.showOverlay = false
                });
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                resourceCategoryId: 1
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.humanResources = result.humanResourceLookup;
                    this.resourceLookup = result.resourceLookup;
                    this.customUserRoles = result.customUserRoleLookup;
                })
                .catch(error=> console.log(error));
        },
        saveData: function() {
            if (this.projectTeam.member != null && !this.projectTeam.editMode) {
                this.projectTeam.memberName = this.projectTeam.member.text;
                this.projectTeam.humanResourceId = this.projectTeam.member.id;
                this.projectTeam.userId = this.projectTeam.member.userId;
            }
            if (this.projectTeam.jobTitle != null && !jQuery.isEmptyObject(this.projectTeam.jobTitle)) {
                let resultSplit = this.projectTeam.jobTitle.text.split('/');
                this.projectTeam.resourcePlanId = this.projectTeam.jobTitle.id;
                if (resultSplit.length == 2) {                  
                    this.projectTeam.position = resultSplit[1].trim();
                }
            }
            // if (flag == 'invite') {
            //     this.projectTeam.invite = true;
            // } else {
            //     this.projectTeam.invite = false;
            // }
            this.$v.projectTeam.$touch();
            if (!this.$v.projectTeam.$invalid && !(this.checkHRExists(this.projectTeam.humanResourceId)) && !this.checkEmailExists(this.projectTeam.emailId)) {
                if (this.projectTeam.editMode) {

                    this.updateProjectTeam();
                }
                else {
                    this.saveProjectTeam();
                }
            }
        },
        saveProjectTeam: function(flag) {
            this.error = false;
            this.showOverlay = true;    
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/add', this.projectTeam)
                .then((response) => {
                    this.showOverlay = false;                        
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.getProjectTeam(this.projectTeam.entityRecordMasterId);
                        this.closeDialog();
                    } else if (result.status == 'OK') {
                        this.elements.alreadyExists = true;
                        this.error = true;
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error=>{
                    this.showOverlay = false;
                })
        },
        updateProjectTeam: function() {
            this.$v.projectTeam.$touch();
            if (!this.$v.projectTeam.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/update', this.projectTeam)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refreshData');
                            this.getProjectTeam(this.projectTeam.entityRecordMasterId);
                            this.closeDialog();
                        }
                    })
                    .catch(error=>{
                        this.showOverlay = false;
                    })
            }

        },
        showHRDetails: function(hrs) {
            if (hrs) {                
                this.showOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + hrs.id)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.projectTeam.userId = result.humanResourceView.userId;
                        this.projectTeam.userTypeId = result.humanResourceView.userTypeId;
                        this.projectTeam.userType = result.humanResourceView.userType;
                        this.projectTeam.userTypeCode = result.humanResourceView.userTypeCode;
                        this.projectTeam.emailId = result.humanResourceView.emailId;
                        this.projectTeam.position = result.humanResourceView.position;
                        this.projectTeam.qualification = result.humanResourceView.qualification;
                        this.projectTeam.experienceYears = result.humanResourceView.experienceYears;
                        this.projectTeam.experienceMonths = result.humanResourceView.experienceMonths;
                        this.showEmailIdEdit = this.projectTeam.emailId ? false : true;
                        if(this.projectData.projectManagerId == this.projectTeam.userId) {
                            this.projectTeam.customUserRoleId = this.projectData.customUserRoleId;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        console.log(error)}
                    );
            }
        },
        checkHRExists: function(hrsId) {
            if (hrsId && !this.projectTeam.editMode) {
                let result = false;
                this.projectTeams.filter(data => {
                    if (data.humanResourceId == hrsId) {
                            iziToast.info({
                            message: 'Team Member already exist.',
                            position: 'topRight'
                        });
                        result = true;
                    }
                });
                return result;
            }
        },
        checkEmailExists: function(emailId) {
            let result = false;
            this.elements.alreadyExists = false;
            this.error = false;
            if(!this.projectTeam.editMode){
                this.projectTeams.map(data => {
                    if (data.emailId == emailId) {
                        this.elements.alreadyExists = true;
                        this.error = true;
                        result = true;
                    }
                });
            }
            return result;
        },
        clearData: function() {           
            this.projectTeam.jobTitle = '';
            this.projectTeam.position = '';
            this.projectTeam.member.id = '';
            this.projectTeam.member.text = '';
            this.projectTeam.member.userId = '';
            this.projectTeam.memberName = '';
            this.projectTeam.humanResourceId = '';
            this.projectTeam.resourceTypeId = '';
            this.projectTeam.customUserRoleId = '';
            this.projectTeam.emailId = '';
            this.projectTeam.position = '';
            this.projectTeam.roleResponsibility = '';
            this.projectTeam.technologySkill = '';
            this.projectTeam.preparedById = '';
            this.projectTeam.preparedBy = '';
            this.projectTeam.qualification = '';
            this.projectTeam.experienceYears = '';
            this.projectTeam.experienceMonths = '';
            this.projectTeam.requiredFrom = '';
            this.projectTeam.requiredTill = '';
            this.projectTeam.id = '';
            this.projectTeam.userType = '';
            this.projectTeam.loginName = '';
            this.elements.alreadyExists = false;
            this.error = false;
            this.showOverlay = false;
            this.$v.projectTeam.$reset();
        },
        closeDialog() {
            this.clearData();
            this.$emit('closeDialog');
            this.$bvModal.hide('add-team-member');
        },
    }
}
</script>