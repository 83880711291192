<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">
                                {{this.ermTitle}}
                            </h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Payroll</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-7 col-xl-7">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Payroll</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Employee</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payroll.employeeId.$error }">
                                                                <select v-model="payroll.employeeId" class="form-control" :class="{ 'is-invalid': $v.payroll.employeeId.$error }">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in employeeLookup" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.payroll.employeeId.$error && !$v.payroll.employeeId.required" class="text-danger">Please select an employee</label>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-3">
                                                        <div class="form-group">
                                                            <label>EID</label>
                                                            <input type="text" disabled :value="payroll.eid" class="form-control">
                                                        </div>
                                                    </div> -->
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label>Payroll Period</label>
                                                            <input type="text" disabled :value="getMonthYearAlt(ermStartDate)" class="form-control">
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Unit Cost</label>
                                                            <VhextDecimalMoneyControl :value="Number(payroll.unitCost)" @decimal-money-input="decimalMoneyControlInput" />
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Cost</label>
                                                            <VhextDecimalMoneyControl :value="Number(payroll.cost)" @decimal-money-input="costInput" />
                                                        </div>
                                                    </div> -->
                                                    <div class="col-12">
                                                        <div class="card-body p-0 my-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-earning1>
                                                                        <span v-if="earningData.editMode">Edit Earnings</span>
                                                                        <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>Add Earnings</span>
                                                                        <span class="float-right mr-5">{{ currencySymbol }} {{empEarnings.toFixed(2)}}</span>
                                                                    </label>
<!--                                                                     <label>
                                                                        Amount
                                                                    </label> -->
                                                                </b-card-header>
                                                                <b-collapse id="accordion-earning1" accordion="my-accordion1" v-model="earningData.visible" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-6 pr-3">
                                                                                    <div class="row no-gutters">
                                                                                    <div class="col-11">
                                                                                        <div class="form-group">
                                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.earningData.payslipComponentId.$error }">
                                                                                                <select v-model="earningData.payslipComponentId" class="form-control" :class="{ 'is-invalid': $v.earningData.payslipComponentId.$error }">
                                                                                                    <option :value="{id: '', text: ''}">Select</option>
                                                                                                    <option v-for="(info, index) in earningTypeLookup" :value="{id: info.typeLookupId, text: info.value}">{{info.value}}</option>
                                                                                                </select>
                                                                                                <div class="required-icon"></div>
                                                                                            </div>
                                                                                            <label v-if="$v.earningData.payslipComponentId.$error && !$v.earningData.payslipComponentId.required" class="text-danger">Please select earning</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col">
                                                                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal @click="openTypeLookupPopup('payroll_earning_type')"><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <!-- <input type="number" name="month" v-model="earningData.amount" placeholder="Enter amount" required class="form-control"> -->
                                                                                    <VhextMoneyControl :value="Math.abs(Number(earningData.amount))" @money-input="moneyControlInputEarning" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-2">
                                                                                <div class="form-group">
                                                                                    <button type="submit" @click="addPayrollDetailData('Earning')" class="sbtn btn btn-secondary py-1 px-3"><i class="fa fa-plus pr-2"></i>Add</button>
                                                                                    <button v-if="earningData.editMode" type="submit" @click="cancelDetailEdit('Earning')" class="sbtn btn btn-secondary py-1 px-3" title="Cancel Edit"><i class="fa fa-remove"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>                                                            
                                                        <div class="data-table-rows report-tables">
                                                            <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="earningList" @row-selected="" :fields="earningFields" responsive="sm" foot-clone no-footer-sorting >
                                                                <template v-slot:cell(actions)="data">
                                                                    <a href="javascript:" title="Edit" @click="showDetailEdit(data.item, data.index, 'Earning')"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                    <a href="javascript:" title="Delete" @click="removePayrollDetail(data.item, 'Earning')" ><i class="fa fa-trash pl-2"></i></a>
                                                                </template>
                                                                <template #cell(index)="data">
                                                                    {{ data.index + 1 }}
                                                                </template>
                                                                <template v-slot:cell(amount)="data">
                                                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                                </template>

                                                                <template  v-slot:foot()="data">
                                                                    <span></span>
                                                                </template>
                                                                <template v-slot:empty="scope">
                                                                    <div class="text-center">No Earnings found</div>
                                                                </template>
                                                                <template v-slot:foot(payslipComponent)="data">
                                                                    <div class="text-right">
                                                                        <span>Total Earnings </span>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:foot(amount)="data">
                                                                    <div class="text-right font-weight-bold">
                                                                        <span class="text-right">{{ currencySymbol }} {{empEarnings.toFixed(2)}}</span>
                                                                    </div>
                                                                </template>

<!--                                                                 <template v-slot:foot(amount)="data">
                                                                    <div class="col-sm-7 col-md-7 pl-0 text-right">
                                                                        <span>{{ currencySymbol }} {{ empEarnings.toFixed(2)}}</span>
                                                                    </div>
                                                                </template> -->
                                                                <template v-slot:emptyfiltered="scope">
                                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                </template>
                                                            </b-table>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-body p-0 my-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-deduction1>
                                                                        <span><i class="fa fa-plus-circle pr-2 action-link"></i>Add Deductions</span><span class="float-right mr-5">{{ currencySymbol }} {{empDeductions.toFixed(2)}}</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accordion-deduction1" accordion="my-accordion1" v-model="deductionData.visible" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-6 pr-3">
                                                                                <div class="row no-gutters">
                                                                                    <div class="col-11">
                                                                                        <div class="form-group">
                                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.deductionData.payslipComponentId.$error }">
                                                                                                <select v-model="deductionData.payslipComponentId" class="form-control" :class="{ 'is-invalid': $v.deductionData.payslipComponentId.$error }">
                                                                                                    <option :value="{id: '', text: ''}">Select</option>
                                                                                                    <option v-for="(info, index) in deductionTypeLookup" :value="{id: info.typeLookupId, text: info.value}">{{info.value}}</option>
                                                                                                </select>
                                                                                                <div class="required-icon"></div>
                                                                                            </div>
                                                                                            <label v-if="$v.deductionData.payslipComponentId.$error && !$v.deductionData.payslipComponentId.required" class="text-danger">Please select deduction</label>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col">
                                                                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal @click="openTypeLookupPopup('payroll_deduction_type')"><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="form-group">
                                                                                    <!-- <input type="number" name="month" v-model="deductionData.amount" placeholder="Enter amount" required class="form-control"> -->
                                                                                    <VhextMoneyControl :value="Math.abs(Number(deductionData.amount))" @money-input="moneyControlInputDeduction" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-2">
                                                                                <div class="form-group">
                                                                                    <button type="submit" @click="addPayrollDetailData('Deduction')" class="sbtn btn btn-secondary py-1 px-3"><i class="fa fa-plus pr-2"></i>Add</button>
                                                                                    <button v-if="deductionData.editMode" type="submit" @click="cancelDetailEdit('Deduction')" class="sbtn btn btn-secondary py-1 px-3" title="Cancel Edit"><i class="fa fa-remove"></i></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                        <div class="data-table-rows report-tables">
                                                            <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="deductionList" @row-selected="" :fields="deductionFields" responsive="sm" foot-clone no-footer-sorting >
                                                                <template v-slot:cell(actions)="data">
                                                                    <a href="javascript:" title="Edit" @click="showDetailEdit(data.item, data.index, 'Deduction')"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                    <a href="javascript:" title="Delete" @click="removePayrollDetail(data.item.id, 'Earning')" ><i class="fa fa-trash pl-2"></i></a>
                                                                </template>
                                                                <template #cell(index)="data">
                                                                    {{ data.index + 1 }}
                                                                </template>
                                                                <template v-slot:cell(amount)="data">
                                                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                                </template>

                                                                <template  v-slot:foot()="data">
                                                                    <span></span>
                                                                </template>
                                                                <template v-slot:foot(payslipComponent)="data">
                                                                    <div class="text-right">
                                                                        <span>Total Deductions </span>
                                                                    </div>
                                                                </template>

                                                                <template v-slot:foot(amount)="data">
                                                                    <div class="text-right font-weight-bold">
                                                                        <span class="text-right">{{ currencySymbol }} {{empDeductions.toFixed(2)}}</span>
                                                                    </div>
                                                                </template>

<!--                                                                 <template v-slot:foot(amount)="data">
                                                                    <div class="col-sm-7 col-md-7 pl-0 text-right">
                                                                        <span>{{ currencySymbol }} {{ empDeductions.toFixed(2)}}</span>
                                                                    </div>
                                                                </template> -->
                                                                <template v-slot:empty="scope">
                                                                    <div class="text-center">No Deductions found</div>
                                                                </template>
                                                                <template v-slot:emptyfiltered="scope">
                                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                </template>
                                                            </b-table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="savePayroll" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="payroll.filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="payrollTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="payrollList" @row-selected="onRowSelected" :fields="payrollFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :current-page="currentPage" :per-page="perPage" :filter="payroll.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredES">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(employeeName)="data">
                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">
                                        {{ data.value }}
                                    </a>
                                </template>
                                <template v-slot:cell(payrollPeriod)="data">
                                    <div>{{ getMonthYearAlt(ermStartDate) }}</div>
                                </template>
                                <template v-slot:cell(totalEarning)="data">
                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                </template>

                                <template v-slot:cell(totalDeduction)="data">
                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                </template>
                                <template #cell(netPay)="data">
                                    <div class="text-right">{{ (data.item.totalEarning - data.item.totalDeduction).toFixed(2)}}
                                    </div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>

                                <template v-slot:foot()="data">
                                    <span></span>
                                </template>

                                <template v-slot:foot(totalEarning)="data">
                                    <div class="text-right font-weight-bold">Total Earnings : {{totalEarnings.toFixed(2)}}</div>
                                </template>
                                <template v-slot:foot(totalDeduction)="data">
                                    <div class="text-right font-weight-bold">Total Deductions : {{totalDeductions.toFixed(2)}}</div>
                                </template>

                                <template v-slot:foot(netPay)="data">
                                    <div class="text-right font-weight-bold">Total : {{(totalEarnings-totalDeductions).toFixed(2)}}</div>
                                </template>

                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deletePayroll(data.item.id)" v-b-modal.deletePayroll><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Payrolls found</div>
                                </template>
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="payrollList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-5 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-show="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details" :active="elements.showDetail">
                                        <div class="row input-group-label">
                                            <div class="col-6">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{payrollEditData.payrollId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col">
                                            </div>
                                            <div class="col-md-3 col-lg-2 pl-0 text-right">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true;elements.visible = false;" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updatePayroll" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
<!--                                             <div class="col-6">
                                                <div class="form-group">
                                                    <label for="title">Employee</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.payrollEditData.employeeId.$error }">
                                                        <select v-model="payrollEditData.employeeId" :disabled="!elements.editMode" class="form-control" :class="{ 'is-invalid': $v.payrollEditData.employeeId.$error }">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in employeeLookup" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.payrollEditData.employeeId.$error && !$v.payrollEditData.employeeId.required" class="text-danger">Please select an employee</label>
                                                </div>
                                            </div> -->
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="activity">Employee</label>
                                                    <input id="month" type="text" :value="payrollEditData.employeeName" disabled class="form-control">
                                                </div>
                                            </div>                                            
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label for="activity">EID</label>
                                                    <input id="month" type="text" :value="payrollEditData.employeeCode" disabled class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label for="activity">Payroll Period</label>
                                                    <input id="month" type="text" :value="getMonthYearAlt(ermStartDate)" disabled class="form-control">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row mb-1">
                                            <div class="col-4">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">Ear.</span>
                                                    </div>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text font-weight-bold" id="basic-addon1">{{empEarningsEdit.toFixed(2)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">Ded.</span>
                                                    </div>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text font-weight-bold" id="basic-addon1">{{empDeductionsEdit.toFixed(2)}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text" id="basic-addon1">Net Pay</span>
                                                    </div>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text font-weight-bold" id="basic-addon1">{{(empEarningsEdit-empDeductionsEdit).toFixed(2)}}</span>
                                                    </div>
                                                </div>
                                            </div>                                                                                        
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div v-if="elements.editMode" class="card-body p-0 my-3">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-earning2>
                                                                <span v-if="earningEditData.editMode">Edit Earnings</span>
                                                                <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>Add Earnings</span>
                                                                <span class="float-right mr-4 font-weight-bold">{{ currencySymbol }} {{empEarningsEdit.toFixed(2)}}</span>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accordion-earning2" v-model="earningEditData.visible" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-5 pr-0">
                                                                        <div class="form-group">
                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.earningEditData.payslipComponentId.$error }">
                                                                                <select v-model="earningEditData.payslipComponentId" class="form-control" :class="{ 'is-invalid': $v.earningEditData.payslipComponentId.$error }">
                                                                                    <option value="">Select</option>
                                                                                    <option v-for="(info, index) in earningTypeLookup" :value="info.typeLookupId">{{info.value}}</option>
                                                                                </select>
                                                                                <div class="required-icon"></div>
                                                                            </div>
                                                                            <label v-if="$v.earningEditData.payslipComponentId.$error && !$v.earningEditData.payslipComponentId.required" class="text-danger">Please select earning</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <VhextMoneyControl :value="Math.abs(Number(earningEditData.amount))" @money-input="moneyControlInputEarning" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-1 px-0">
                                                                        <div class="form-group">
                                                                            <button type="submit" @click="savePayrollDetail('Earning')" class="sbtn btn btn-secondary py-1 px-2"><i class="fa fa-plus"></i>
                                                                            </button>
                                                                            <button type="submit" @click="cancelPayrollDetailEdit('Earning')" class="sbtn btn btn-outline-primary mb-0 py-1 px-2"><i class="fa fa-times"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                                <div class="data-table-rows report-tables">
                                                    <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="earningEditList" @row-selected="" :fields="earningDetailFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                                        <template #cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(amount)="data">
                                                            <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                        </template>

                                                        <template v-slot:empty="scope">
                                                            <div class="text-center">No Earnings found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <a v-if="elements.editMode" href="javascript:" title="Edit" @click="showPayrollDetailEdit(data.item, 'Earning')"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                            <a href="javascript:" title="Delete" v-b-modal.deletePayrollDetail @click="deletePayrollDetail(data.item.id)" ><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div v-if="elements.editMode" class="card-body p-0 my-3">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-deduction2>
                                                                <span v-if="deductionEditData.editMode">Edit Deductions</span>
                                                                <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>Add Deductions</span>
                                                                <span class="float-right mr-4 font-weight-bold">{{ currencySymbol }} {{empDeductionsEdit.toFixed(2)}}</span>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accordion-deduction2" v-model="deductionEditData.visible" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.deductionEditData.payslipComponentId.$error }">
                                                                                <select v-model="deductionEditData.payslipComponentId" class="form-control" :class="{ 'is-invalid': $v.deductionEditData.payslipComponentId.$error }">
                                                                                    <option value="">Select</option>
                                                                                    <option v-for="(info, index) in deductionTypeLookup" :value="info.typeLookupId">{{info.value}}</option>
                                                                                </select>
                                                                                <div class="required-icon"></div>
                                                                            </div>
                                                                            <label v-if="$v.deductionEditData.payslipComponentId.$error && !$v.deductionEditData.payslipComponentId.required" class="text-danger">Please select deduction</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <VhextMoneyControl :value="Math.abs(Number(deductionEditData.amount))" @money-input="moneyControlInputDeduction" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-1 px-0">
                                                                        <div class="form-group">
                                                                            <button type="submit" @click="savePayrollDetail('Deduction')" class="sbtn btn btn-secondary py-1 px-2"><i class="fa fa-plus"></i></button>
                                                                            <button type="submit" @click="cancelPayrollDetailEdit('Deduction')" class="sbtn btn btn-outline-primary mb-0 py-1 px-2"><i class="fa fa-times"></i></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                                <div class="data-table-rows report-tables">
                                                    <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="deductionEditList" @row-selected="" :fields="deductionDetailFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                                        <template #cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(amount)="data">
                                                            <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <a v-if="elements.editMode" href="javascript:" title="Edit" @click="showPayrollDetailEdit(data.item, 'Deduction')"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                            <a href="javascript:" title="Delete" v-b-modal.deletePayrollDetail @click="deletePayrollDetail(data.item.id)" ><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="scope">
                                                            <div class="text-center">No Deductions found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </div>
                                        </div>

                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PayrollDetailPopup v-if="showDialog" ref="payrollDetailPopupRef" @closeDialog="showDialog=false;" />
        <TypeLookup v-if="showDialog" ref="typeLookupModalPayroll" :entity="typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false;" />
        <DeleteComponent id="deletePayroll" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Payroll?" />
        <DeleteComponent id="deletePayrollDetail" :onYes="onYesPayrollDetail" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Payroll Component?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import PayrollDetailPopup from '@/components/popup/itemdetail/PayrollDetailPopup.vue';
import PayslipDetailAutoGenPopup from '@/components/popup/itemdetail/PayslipDetailAutoGenPopup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
import VhextDecimalMoneyControl from '@/components/usercontrols/VhextDecimalMoneyControl.vue';
export default {
    name: 'app',
    components: {
        PayrollDetailPopup,
        PayslipDetailAutoGenPopup,
        TypeLookup,
        VhextMoneyControl,
        VhextDecimalMoneyControl
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
            },

            mainTableArray: [],

            editIndex: null,
            typeLookupTitle: 'Type Settings',
            typeEntity: '',
            prependText: '₹',
            statusText: '',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: localStorage.getItem("ermId"),
            ermTitle: localStorage.getItem("ermTitle"),
            ermStartDate: localStorage.getItem("ermStartDate"),
            entity: 'payroll',
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            ermId: localStorage.getItem("ermId"),

            payrollCompEditId: 0,

            payroll: {
                id: '',
                payrollId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: localStorage.getItem("ermId"),
                employeeId: '',
                employeeName: '',
                employeeCode: '',
                payrollScheduleTypeId: '',
                payrollPeriod: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
                earningDeductionList: [],
            },
            payrollEditData: {
                id: '',
                payrollId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: localStorage.getItem("ermId"),
                employeeId: '',
                employeeName: '',
                employeeCode: '',
                payrollScheduleTypeId: '',
                payrollPeriod: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
                earningDeductionList: [],
            },

            payrollDetail: {
                id: '',
                payrollDetailId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payrollId: '',
                payslipComponentTypeId: '',
                payslipComponentId: {
                    id: '',
                    text: '',
                },
                amount: '',
                createdById: localStorage.getItem("userId"),
            },
            payrollDetailEditData: {
                id: '',
                payrollDetailId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payrollId: '',
                payslipComponentTypeId: '',
                payslipComponentId: '',
                amount: '',
                createdById: localStorage.getItem("userId"),
            },

            earningData: {
                payslipComponentId: {
                    id: '',
                    text: '',
                },
                amount: '',
                editMode: false,
                visible: false,
            },
            deductionData: {
                payslipComponentId: {
                    id: '',
                    text: '',
                },
                amount: '',
                editMode: false,
                visible: false,
            },
            earningEditData: {
                payslipComponentId: '',
                amount: '',
                editMode: false,
                visible: false,
            },
            deductionEditData: {
                payslipComponentId: '',
                amount: '',
                editMode: false,
                visible: false,
            },

            earningList: [],
            deductionList: [],
            payrollDetailList: [],
            payslipComponentLookup: [],
            payslipComponentTypes: [],
            users: [],
            employeeLookup: [],
            payrollList: [],

            payrollFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'payrollId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'eid', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'employeeName', label: 'Emp. Name', sortable: true, sortDirection: 'desc' },
                // { key: 'payrollPeriod', label: 'Payroll Period', sortable: true,sortDirection: 'desc' },
                { key: 'totalEarning', label: 'Earnings', sortable: true, sortDirection: 'desc' },
                { key: 'totalDeduction', label: 'Deductions', sortable: true, sortDirection: 'desc' },
                { key: 'netPay', label: 'Net Pay', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            earningFields: [
                {key:'index',label: 'SN'},
                { key: 'payslipComponent', label: 'Earnings', },
                { key: 'amount', label: 'Amount', },
                { key: 'actions', label: 'Actions' }
            ],

            deductionFields: [
                {key:'index',label: 'SN'},
                { key: 'payslipComponent', label: 'Deduction', },
                { key: 'amount', label: 'Amount', },
                { key: 'actions', label: 'Actions' }
            ],

            earningDetailFields: [
                {key:'index',label: 'SN'},
                { key: 'payslipComponent', label: 'Earnings', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            deductionDetailFields: [
                {key:'index',label: 'SN'},
                { key: 'payslipComponent', label: 'Deduction', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 0,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getPayrollList();
        this.lookupData();
    },
    beforeDestroy() {
        localStorage.removeItem('ermId');
        localStorage.removeItem('ermStartDate');
    },
    validations: {
        payroll: {
            employeeId: {
                required,
            }
        },
        payrollEditData: {
            employeeId: {
                required,
            }
        },
        earningData: {
            payslipComponentId: {
                id: {
                    required,
                }
            },
        },
        deductionData: {
            payslipComponentId: {
                id: {
                    required,
                },
            },
        },
        earningEditData: {
            payslipComponentId: {
                required,
            },
        },
        deductionEditData: {
            payslipComponentId: {
                required,
            },
        },
    },
    watch: {
        'elements.visible': {
            handler(value) {
                if (value) {
                    this.elements.editMode = false;
                }
            }
        }
    },
    computed: {
        earningTypeLookup() {
            return this.payslipComponentLookup.filter(data => data.typeLookupId == 1);
        },
        deductionTypeLookup() {
            return this.payslipComponentLookup.filter(data => data.typeLookupId == 2);
        },
        sumTotal() {
            return this.payrollList.reduce((accum, item) => {
                return parseFloat(item.totalEarning) + parseFloat(item.totalDeduction)
            }, 0.00)
        },        

        totalEarnings() {
            return this.payrollList.reduce((accum, item) => {
                return accum + parseFloat(item.totalEarning)
            }, 0.00)
        },        
        totalDeductions() {
            return this.payrollList.reduce((accum, item) => {
                return accum + parseFloat(item.totalDeduction)
            }, 0.00)
        },

        earningEditList() {
            return this.payrollDetailList.filter((data) => data.payslipComponentTypeId == 1);
        },
        deductionEditList() {
            return this.payrollDetailList.filter((data) => data.payslipComponentTypeId == 2);
        },

        empEarnings() {
            return this.earningList.reduce((accum, item) => {
                return accum + parseFloat(item.amount)
            }, 0.00)
        },
        empDeductions() {
            return this.deductionList.reduce((accum, item) => {
                return accum + parseFloat(item.amount)
            }, 0.00)
        },

        empEarningsEdit() {
            return this.earningEditList.reduce((accum, item) => {
                return accum + parseFloat(item.amount)
            }, 0.00)
        },
        empDeductionsEdit() {
            return this.deductionEditList.reduce((accum, item) => {
                return accum + parseFloat(item.amount)
            }, 0.00)
        },

        // empEarnings() {
        //     return this.earningList.reduce((accum, item) => {
        //         return accum + parseFloat(item.ammount ? item.ammount : 0)
        //     }, 0.00)
        // },
        // empDeductions() {
        //     return this.deductionList.reduce((accum, item) => {
        //         return accum + parseFloat(item.ammount ? item.ammount : 0)
        //     }, 0.00)
        // }
    },
    methods: {
        getPayrollList: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/summary/list', data)
                .then((response) => {
                    let result = response.data;
                        this.payrollList = result.payrollSummary;
                        if (this.payrollList != null && this.payrollList.length > 0) {
                            this.elements.showDetail = true;
                            this.elements.editMode = false;
                            this.totalRows = this.payrollList.length;
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.payrollEditData.id, this.mainTableArray, 'payrollTable');
                            })
                        }
                        else {
                            this.clearPayrollDetailData();
                        }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    // this.earningTypeLookup = result.earningTypeLookup;
                    // this.deductionTypeLookup = result.deductionTypeLookup;
                    this.payslipComponentLookup = result.payslipComponentLookup;
                    this.employeeLookup = result.employeeLookup;
                    this.payslipComponentTypes = result.typeLookup;
                })

        },
        onFilteredES(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getPayrollTypeId: function(type) {
            let result = null;;
            this.payslipComponentTypes.filter((data, key) => {
                if (data.value == type) {
                    result = data.typeLookupId;
                }
            });
            return result;
        },
        addPayrollDetailData: function(type) {

            let payrollDetail = (type == 'Earning') ? 'earning' : 'deduction';

            this.$v[payrollDetail+'Data'].$touch();
            if (!this.$v[payrollDetail+'Data'].$invalid) {
                this.payrollCompEditId++;
                let payrollCompEditId = 'payrollEdit_'+ this.payrollCompEditId;
                let data = {};
                data.id = this[payrollDetail+'Data'].id ? this[payrollDetail+'Data'].id : payrollCompEditId;
                data.payrollDetailId = this.payrollDetail.payrollDetailId;
                data.vhextAccountId = this.payrollDetail.vhextAccountId;
                data.payrollId = this.payrollDetail.payrollId;
                data.payslipComponentType = type;
                data.payslipComponentTypeId = this.getPayrollTypeId(type);
                data.payslipComponent = this[payrollDetail+'Data'].payslipComponentId.text;
                data.payslipComponentId = this[payrollDetail+'Data'].payslipComponentId.id;
                data.amount = this[payrollDetail+'Data'].amount ? this[payrollDetail+'Data'].amount : 0;
                data.createdById = this.payrollDetail.createdById;

                if (!this.componentAlreadyExists(data.id, data.payslipComponentId, payrollDetail)) {
                    if (this[payrollDetail+'Data'].editMode) {
                        this[payrollDetail+'List'].splice(this.editIndex, 1);
                        this[payrollDetail+'List'].splice(this.editIndex, 0, data);
                        this.$nextTick(() => {
                            this.cancelDetailEdit(type);
                        })
                    }
                    else {
                        this[payrollDetail+'List'].push(data);
                        this.$nextTick(() => {
                            this.clearDetailEditData(type);
                        })
                    }
                }
                else {
                    iziToast.info({
                        message: 'Component already exist',
                        position: 'topCenter'
                    });
                }
            }
        },
        savePayroll: function() {
            this.payroll.earningDeductionList = this.earningList.concat(this.deductionList);
            
            this.payroll.payrollPeriod = this.ermStartDate;
            this.payroll.earningDeductionList = this.payroll.earningDeductionList.map(data => {
                data.id = '';
                return data;
            });
            this.$v.payroll.$touch();
            if (!this.$v.payroll.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/add', this.payroll)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrollList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
            }
        },
        updatePayroll: function() {
            this.payrollEditData.payrollPeriod = this.ermStartDate;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/update', this.payrollEditData)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrollList();
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        deletePayroll: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: this.entity,
                erdId: this.deleteParam.id,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/delete', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrollList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        clearData: function() {
            this.elements.visible = false;
            this.payroll.id = '';
            this.payroll.payrollId = '';
            this.payroll.employeeId = '';
            this.payroll.employeeName = '';
            this.payroll.employeeCode = '';
            this.payroll.payrollPeriod = '';
            this.earningList = [];
            this.deductionList = [];
            this.$v.payroll.$reset();

        },
        cancelEditMode: function() {
            this.getPayrollDetail(this.payrollEditData.id);
            this.cancelPayrollDetailEdit('Earning');
            this.cancelPayrollDetailEdit('Deduction');
            this.elements.editMode = false;
        },
        cancelDetailEdit: function (type) {
            let payrollDetail = (type == 'Earning') ? 'earning' : 'deduction';
            this[payrollDetail+'Data'].editMode = false;
            this[payrollDetail+'Data'].visible = false;
            this.clearDetailEditData(type);
        },
        clearDetailEditData(type) {
            let payrollDetail = (type == 'Earning') ? 'earning' : 'deduction';
            this[payrollDetail+'Data'].id = '';
            this[payrollDetail+'Data'].payslipComponentId.text = '';
            this[payrollDetail+'Data'].payslipComponentId.id = '';
            this[payrollDetail+'Data'].amount = 0;
            this.$v[payrollDetail+'Data'].$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                // this.viewPayroll(data.id);
                this.getPayrollDetail(data.id);
                this.payrollEditData.id = data.id;
                this.payrollEditData.payrollId = data.payrollId;
                this.payrollEditData.vhextAccountId = data.vhextAccountId;
                this.payrollEditData.entityRecordMasterId = data.entityRecordMasterId;
                this.payrollEditData.employeeId = data.empId;
                this.payrollEditData.employeeName = data.employeeName;
                this.payrollEditData.employeeCode = data.eid;
                this.payrollEditData.payrollScheduleTypeId = data.payrollScheduleTypeId;
                // this.payrollEditData.payrollPeriod = this.getMonthYear(this.payrollEditData.payrollPeriod);

                this.payrollEditData.payrollPeriod = this.getMonthYear(data.payrollPeriodStr);
                this.payrollEditData.statusId = data.statusId;
                this.payrollEditData.createdById = data.createdById;

                this.payrollDetailEditData.payrollId = data.id;
                this.payrollDetailEditData.vhextAccountId = data.vhextAccountId;
                this.payrollDetailEditData.createdById = data.createdById;
            }
        },
        showDetailEdit: function(data, index, type) {
            let payrollDetail = (type == 'Earning') ? 'earning' : 'deduction';

            this[payrollDetail+'Data'].editMode = true;
            this[payrollDetail+'Data'].visible = true;
            this.editIndex = index;

            this[payrollDetail+'Data'].id = data.id;
            this[payrollDetail+'Data'].payslipComponentId.text = data.payslipComponent;
            this[payrollDetail+'Data'].payslipComponentId.id = data.payslipComponentId;
            this[payrollDetail+'Data'].amount = data.amount;
        },
        getPayrollDetail: function(id) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                payrollId: id,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payrolldetail/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.payrollDetailList.length > 0) {
                        this.payrollDetailList = result.payrollDetailList;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        savePayrollDetail: function(type) {
            let payrollDetail = (type == 'Earning') ? 'earningEdit' : 'deductionEdit';

            this.payrollDetailEditData.payslipComponentTypeId = this.getPayrollTypeId(type);
            this.payrollDetailEditData.payslipComponentId = this[payrollDetail+'Data'].payslipComponentId;
            this.payrollDetailEditData.amount = this[payrollDetail+'Data'].amount;

            if(!this.componentAlreadyExists(this.payrollDetailEditData.id, this.payrollDetailEditData.payslipComponentId, payrollDetail)) {
                if (this[payrollDetail+'Data'].editMode) {
                    this.updatePayrollDetail(type);
                }
                else {
                    this.addPayrollDetail(type);
                }
            }
            else {
                iziToast.info({
                    message: 'Component already exist',
                    position: 'topCenter'
                });
            }
        },
        addPayrollDetail: function(type) {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payrolldetail/add', this.payrollDetailEditData)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrollDetail(this.payrollEditData.id);
                        this.getPayrollList();
                        this.cancelPayrollDetailEdit(type);
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        updatePayrollDetail: function(type) {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payrolldetail/update', this.payrollDetailEditData)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrollDetail(this.payrollEditData.id);
                        this.getPayrollList();
                        this.cancelPayrollDetailEdit(type);
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        deletePayrollDetail: function(id) {
            this.deleteParam.id = id;
        },
        onYesPayrollDetail: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/payrolldetail/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrollDetail(this.payrollEditData.id);
                        this.getPayrollList();
                        this.cancelPayrollDetailEdit('Earning');
                        this.cancelPayrollDetailEdit('Deduction');
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        clearPayrollDetailData() {
            this.elements.showDetail = false;
            this.payrollEditData.id = '';
            this.payrollEditData.payrollId = '';
            this.payrollEditData.vhextAccountId = '';
            this.payrollEditData.entityRecordMasterId = '';
            this.payrollEditData.employeeId = '';
            this.payrollEditData.employeeName = '';
            this.payrollEditData.employeeCode = '';
            this.payrollEditData.payrollScheduleTypeId = '';
            this.payrollEditData.payrollPeriod = '';
            this.payrollEditData.statusId = '';
            this.payrollEditData.createdById = '';
            this.payrollDetailEditData.payrollId = '';
            this.payrollDetailEditData.createdById = '';
            this.payrollDetailList = [];
        },
        showPayrollDetailEdit: function(data, type) {
            let payrollDetail = (type == 'Earning') ? 'earningEdit' : 'deductionEdit';

            this[payrollDetail+'Data'].editMode = true;
            this[payrollDetail+'Data'].visible = true;

            this.payrollDetailEditData.id = data.id;
            this.payrollDetailEditData.payrollDetailId = data.payrollDetailId;
            this.payrollDetailEditData.payslipComponentTypeId = this.getPayrollTypeId(type);
            this[payrollDetail+'Data'].payslipComponentId = data.payslipComponentId;
            this[payrollDetail+'Data'].amount = data.amount;
        },
        cancelPayrollDetailEdit: function (type) {
            let payrollDetail = (type == 'Earning') ? 'earningEdit' : 'deductionEdit';

            this[payrollDetail+'Data'].editMode = false;
            this[payrollDetail+'Data'].visible = false;

            this.payrollDetailEditData.id = '';
            this.payrollDetailEditData.payrollDetailId = '';
            this.payrollDetailEditData.payslipComponentTypeId = '';
            this[payrollDetail+'Data'].payslipComponentId = '';
            this[payrollDetail+'Data'].amount = 0;
            this.$v[payrollDetail+'Data'].$reset();
        },
        removePayrollDetail: function(data, type) {
            this.cancelDetailEdit(type);
            let payrollDetail = (type == 'Earning') ? 'earning' : 'deduction';

            this[payrollDetail+'List'] = this[payrollDetail+'List'].filter((payroll)=> payroll !== data );

        },
        decimalMoneyControlInput: function(value) {
            this.payroll.unitCost = value;
        },
        costInput: function(value) {
            this.payroll.cost = value;
        },
        openTypeLookupPopup(entity) {
            this.typeEntity = entity;
            this.showDialog = true;
        },
        moneyControlInputEarning: function(value) {
            let fieldName = this.elements.editMode ? 'earningEdit' : 'earning';
            this[fieldName + 'Data'].amount = Math.abs(value);
        },
        moneyControlInputDeduction: function(value) {
            let fieldName = this.elements.editMode ? 'deductionEdit' : 'deduction';
            this[fieldName + 'Data'].amount = Math.abs(value);
        },
        viewItemDetails: function(data) {
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.payrollDetailPopupRef.getDetails(data.id, 'payroll');
            })
        },
        componentAlreadyExists: function(id, payslipComponentId, payrollDetail) {
            let result = false;
            this[payrollDetail+'List'].forEach(data => {
                if (payslipComponentId && data.payslipComponentId && payslipComponentId == data.payslipComponentId && id != data.id) {
                    result = true;
                }
            });
            return result;
        },
    }
}
</script>