<template>
    <div>
        <div class="row addnew-tassk-details position-absolute w-100 t-0" v-bind:class="{hide: elements.isHide }">
            <div class="col-12 col-md-12 mb-1 pr-3">
                <div class="card mb-2 b-radius-5 p-2">
                    <!-- <div class="">
                        <a href="#" class="addnew-tassk-close float-right" @click="clearData();toggleDiv();"><i class="fa fa-times"></i></a>
                    </div> -->
                    <b-overlay :show="showOverlay" opacity="0.4">
                        <div class="card-body p-3">
                            <div class="form-group mb-0">
                                <div v-if="!elements.viewMode">
                                    <div class="row">
                                        <div class="col-12 col-md-3">
                                            <div class="form-group">
                                                <label for="title1">Title</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.task.title.$error }">
                                                    <b-form-input id="title1" v-model="task.title" placeholder="Enter Task Title"></b-form-input>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.task.title.$error && !$v.task.title.minLength" class="text-danger">Task Title must have at least {{$v.task.title.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.task.title.$error && !$v.task.title.required" class="text-danger">Please Enter Task Title</label>
                                                <label v-if="$v.task.title.$error && !$v.task.title.maxLength" class="text-danger">Task Title must not exceed {{$v.task.title.$params.maxLength.max}} letters.</label>
                                            </div>
                                        </div>
                                        <!-- <div v-if="!elements.clientTaskPage" class="col-12 col-md-3">
                                            <div class="form-group">
                                                <label for="taskTypeId1">Type</label>
                                                <select v-model="task.taskTypeId" class="form-control" id="taskTypeId1">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in taskTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                            </div>
                                        </div> -->
                                        <div class="col-12 col-md-3">
                                            <div class="form-group">
                                                <label for="plan1">Dev Plan / Task Group</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.task.entityRecordMasterId.$error }">
                                                    <select v-model="task.entityRecordMasterId" id="plan1" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in ermLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.task.entityRecordMasterId.$error && !$v.task.entityRecordMasterId.required" class="text-danger">Please Select a Plan</label>
                                                <b-input-group-append class="append-0">
                                                <a class="setting-icon" href="javascript:" @click="openReqPop"><i class="fa fa-cog action-link" aria-hidden="true"></i></a> 
                                                </b-input-group-append>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label for="activityId1">Process / Activity</label>
                                                <b-input-group>
                                                    <select v-model="task.processActivity" class="form-control" id="activityId1">
                                                        <option :value="{activityId: '', processId: ''}">Select</option>
                                                        <option v-for="(info, index) in processActivities" :value="{activityId: info.activityId, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.activity}}</option>
                                                    </select>
                                                    <b-input-group-append class="append-0">
                                                    <a class="action-link" href="javascript:" title="Use Existing Activities" @click="showDialog=true" v-b-modal.userActivityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                                    </b-input-group-append>  
                                                </b-input-group>                                              
<!--                                                 <a :title="processActivityLookupTitle" class="setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>                                                  -->
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label for="requirement">Requirement</label>
                                                <select v-if="!elements.viewMode" v-model="task.requirementId" class="form-control" id="requirementId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in requirements" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="task.requirement">
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2">
                                            <div class="form-group">
                                                <label for="assignedToId1">Assigned To</label>
                                                <select v-model="task.assignedToId" class="form-control" id="assignedToId1">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="estimatedStartDt1">Start Date</label>
                                                <div class="input-group">
                                                    <b-col class="col-6 pl-0 pr-0">
                                                        <b-form-input type="date" v-model="task.estimatedStartDate" :value="task.estimatedStartDate" id="estimatedStartDt1" required></b-form-input>
                                                    </b-col>
                                                    <b-col class="col-6 pl-1">
                                                        <b-form-input type="time" v-model="task.estimatedStartTime" :value="task.estimatedStartTime" required></b-form-input>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3 pr-0">
                                            <div class="form-group">
                                                <label for="estimatedEndDt1">Due Date</label>
                                                <div class="input-group ">
                                                    <b-col class="col-6 pl-0 pr-0">
                                                        <b-form-input type="date" v-model="task.estimatedEndDate" :value="task.estimatedEndDate" id="estimatedEndDt1" required></b-form-input>
                                                    </b-col>
                                                    <b-col class="col-6 pl-1">
                                                        <b-form-input type="time" v-model="task.estimatedEndTime" :value="task.estimatedEndDate" required></b-form-input>
                                                    </b-col>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 pr-0">
                                            <div class="form-group">
                                                <label>Est. Effort</label>
                                                <b-input-group size="sm" class="mb-2">
                                                    <EffortInput ref="effortControl"  />
                                                    <b-form-input v-if="false" type="number" min="0" v-model="task.effort"></b-form-input>
                                                    <template v-if="false" v-slot:append>
                                                        <b-dropdown text="Man Days" :text="effortType" class="btn-dd" v-model="task.effortType">
                                                            <b-dropdown-item v-for="(info, index)  in effortTypes" :key="info.id" :value="info.id" @click="task.effortType = info.id;effortType = info.name">{{info.name}}
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </template>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 pr-0">
                                            <div class="form-group">
                                                <label>Est. Cost</label>
                                                <VhextMoneyControl :value="Math.abs(Number(task.estimatedCost))" @money-input="moneyControlInput" />
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-2 pr-0">
                                            <div class="form-group">
                                                <label for="priorityId1">Priority</label>
                                                <select v-model="task.priorityId" class="form-control" id="priorityId1">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in taskPriorities" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="elements.editMode == true" class="col-12 col-md-2 pr-0">
                                            <div class="form-group">
                                                <label>Progress</label>
                                                    <span class="d-block"><input type="range" v-model="task.progressPercentage" class="form-range w-100" min="0" max="100" step="5">
                                                        <span class="d-block">{{ task.progressPercentage ? task.progressPercentage : 0 }}%</span>
                                                    </span>
                                                <label v-if="$v.task.progressPercentage.$error && !$v.task.progressPercentage.between" class="text-danger">Percentage must not exceed {{$v.task.progressPercentage.$params.between.max}}</label>
                                                <label v-if="$v.task.progressPercentage.$error && !$v.task.progressPercentage.numeric" class="text-danger">Percentage must be a numeric Value</label>
                                            </div>
                                        </div>
                                        <div v-bind:class="[ (elements.editMode == true) ? 'col-12 col-md-3' : 'col-12 col-md-2']">
                                            <div class="form-group">
                                                <label for="statusId">Status</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.task.statusId.$error }">
                                                    <select v-model="task.statusId" class="form-control" id="statusId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                    <label v-if="$v.task.statusId.$error && !$v.task.statusId.required" class="text-danger">Please select a Status</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-3 task-audio">
                                            <label class="v-hidden">Status</label>
                                            <div class="form-group audio-record">
                                                
                                                <a href="javascript:" title="Record task" class="microphone-link" @click="showAudioRecord"><i class="fa fa-microphone microphone-icon-alt" aria-hidden="true"></i></a>                                         
                                                <div class="close-audio">
                                                    <audio v-if="audioUrl != ''" controls  :src="audioUrl"></audio>
                                                    <a  v-if="audioUrl != ''" href="javascript:" title="Close Record" @click="closeAudio" class="close-audio-btn"><i class="fa fa-close" aria-hidden="true"></i></a>                                         
                                                </div>
                                            </div>
                                        </div>                                     
                                        <div class="col-12 col-md-12">
                                            <div class="form-group">
                                                <label for="description1">Description</label>
                                                <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="task.description"></editor> -->
                                                <VmxEditor v-model="task.description"></VmxEditor>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <div>
                                                    <label class="font-weight-bold">Attachment<i class="fa fa-lg fa-paperclip ml-2" aria-hidden="true"></i></label>
                                                    <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2"></i>Add More</a>
                                                </div>
                                                <div id="Multi-attachment" v-for="(input, index) in addMoreAttachment">
                                                    <div class="attachments mb-2">
                                                        <b-form-file class="attachments-list" size="sm" v-model="task.attachments[index]" placeholder="Choose a file.">
                                                            <template slot="file-name" slot-scope="{ names }">
                                                                <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                            </template>
                                                        </b-form-file>
                                                        <a href="javascript:" @click="removeFile(index)" class="float-right button-remove p-2 attachments-remove"><i class="fa fa-minus-circle pr-2"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="form-group">
                                                <div class="card-body p-0">
                                                    <b-card no-body>
                                                        <div class="col-12 px-0 mt-2">
                                                            <FunctionalReferencePopup ref="referenceModal" :entity="task.entity" :erdId="Number(task.id)" :ermId="Number(task.entityRecordMasterId)" />
                                                        </div>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mt-0">
                                            <div class="card-body p-0 mb-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-checklist>
                                                            <label for="description">Check List</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-checklist" v-model="elements.visibileChecklist" accordion="my-accordion2" role="tabpanel">
                                                        <b-card-body class="p-2 pb-0">
                                                            <div class="ml-1">
                                                                <div class="row">
                                                                    <div class="col-12 m-1 ml-2">
                                                                        <div class="form-group">
                                                                            <div v-for="(checkListAll, index) in taskCheckListAll" class="form-check">
                                                                                <input type="checkbox" @change="updateChecklist(checkListAll)" :value="checkListAll.state" v-model="checkListAll.state" :checked="true" class="form-check-input" id="checkbox-1">
                                                                                <label class="form-check-label" for="checkbox-1">{{ checkListAll.checklist }}</label>
                                                                            </div>
                                                                            <div v-for="(checkList, index) in task.checklistList" class="form-check">
                                                                                <input type="checkbox" :checked="false" class="form-check-input" id="checkbox-1">
                                                                                <label class="form-check-label" for="checkbox-1">{{ checkList.checklist }}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-10">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control" v-model="checklistData.checklistLocal" placeholder="Add an item..." @keyup.enter="addChecklist">
                                                                        </div>
                                                                        <label v-if="$v.checklistData.checklistLocal.$error && !$v.checklistData.checklistLocal.required" class="text-danger">Please enter check list.</label>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <button type="submit" @click="addChecklist" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="elements.viewOnlyComponent" class="row">
                                        <div class="col-12 mt-0">
                                            <div class="card-body p-0 mb-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-comments>
                                                            <label for="description">Comments</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-comments" v-model="elements.visibileCommAccordian" accordion="my-accordion3" role="tabpanel">
                                                        <b-card-body class="p-2 pb-0">
                                                            <div class="comments-list1">
                                                                <div class="row mb-3">
                                                                    <div class="col-12" v-for="loadComment in comments">
                                                                        <ul class="comments-list reply-list">
                                                                            <li>
                                                                                <div class="user-icon">
                                                                                    <span class="user-name-icon" :title="loadComment.commentedBy"> {{ loadComment.commentedBy | getAcronym }} </span>
                                                                                </div>
                                                                                <div class="comment-box">
                                                                                    <div class="comment-head">
                                                                                        <h6 class="comment-name by-author" :title="loadComment.commentedBy"><a href="">{{ loadComment.commentedBy }}</a></h6>
                                                                                        <span>{{ loadComment.commentDt | formatDateTimeAmPm }}</span>
                                                                                        <a href="javascript:" v-if="(loadComment.id != comment.editId)" title="Edit" @click="editComment(loadComment)"><i class="fa fa-pencil  action-link"></i></a>
                                                                                        <a href="javascript:" v-if="(loadComment.id == comment.editId) && elements.commentEditMode" title="Update Comment" @click="updateComment"><i class="fa fa-check action-link"></i></a>
                                                                                        <a href="javascript:" v-if="(loadComment.id == comment.editId) && elements.commentEditMode" title="Cancel Edit" @click="clearComment"><i class="fa fa-times action-link"></i></a>
                                                                                        <a href="javascript:" @click="deleteComment(loadComment.id)" v-b-modal.deleteComment><i class="fa fa-trash-o action-link"></i></a>
                                                                                    </div>
                                                                                    <div v-if="(loadComment.id != comment.editId)" class="comment-content pt-1 pb-1">{{ loadComment.comment }}</div>
                                                                                    <div v-if="(loadComment.id == comment.editId) && elements.commentEditMode">
                                                                                        <textarea rows="3" class="w-100" v-model="comment.comment"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="!elements.commentEditMode" class="comments-cox">
                                                                <div class="row">
                                                                    <div class="col-10">
                                                                        <div class="form-group">
                                                                            <div class="user-icon">
                                                                                <span v-for="user in users" v-if="user.id == task.userId" :title="user.value" class="user-name-icon"> {{ user.value | getAcronym }} </span>
                                                                            </div>
                                                                            <div class="user-comments">
                                                                                <input type="text" class="form-control" v-model="comment.comment" id="comment" placeholder="Comments" @keyup.enter="addComment">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <button type="submit" @click="addComment" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="elements.viewMode">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <h6 class="text-left font-weight-bold mb-3">{{ task.title }}</h6>
                                        </div>
                                        <div class="col-12 mb-12 mb-3">
                                            <div class="card">
                                                <div class="row mx-1 my-2">
                                                    <div class="col-12 col-md-3 mt-4 mb-0">
                                                        <div class="form-group">
                                                            <label class="mr-3 font-weight-bold">Start Date</label>
                                                            <span :class="task.estimatedStartDt && (task.status !== 'Closed' && task.status !== 'Completed' && task.status !== 'Cancelled') ? 'text-success' : ''"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeAmPm }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 mt-4 mb-0">
                                                        <div class="form-group">
                                                            <label class="mr-3 font-weight-bold">Due Date</label>
                                                            <span :class="task.estimatedEndDt && (task.status !== 'Closed' && task.status !== 'Completed' && task.status !== 'Cancelled') ? dueDateStyling(task.estimatedEndDt) : ''"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeAmPm }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 mb-2">
                                                        <div class="row mt-3">
                                                            <b-col cols="3">
                                                                <label class="mt-1">Progress</label>
                                                            </b-col>
                                                            <b-col cols="9">
                                                                <div v-if="elements.taskStatusUpdate">
                                                                    <b-input-group size="sm" class="mb-2">
                                                                        <b-form-input type="number" min="0" max="100" v-model="task.progressPercentage"></b-form-input>
                                                                        <div class="input-group-append">
                                                                            <span class="input-group-text">%</span>
                                                                        </div>
                                                                    </b-input-group>
                                                                </div>
                                                                <b-progress v-if="!elements.taskStatusUpdate" height="1.7rem" class="task-label w-90">
                                                                    <b-progress-bar v-model="task.progressPercentage" :variant="progressBarVariant(task)">
                                                                        <span><strong>{{ task.progressPercentage }}%</strong></span>
                                                                    </b-progress-bar>
                                                                </b-progress>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 mb-3 mr-0">
                                                        <div class="row mt-3">
                                                            <b-col cols="3">
                                                                <label class="mt-2 mr-0">Status</label>
                                                            </b-col>
                                                            <b-col cols="9">
                                                                <label v-if="!elements.taskStatusUpdate" class="form-control task-label mb-1 ml-0">{{ task.status }}</label>
                                                                <select v-if="elements.taskStatusUpdate && task.assignedById == userId" v-model="task.statusId" class="form-control" id="statusId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div v-if="task.assignedToId == userId && task.assignedToId !== task.assignedById">
                                                                    <select v-if="elements.taskStatusUpdate" v-model="task.statusId" class="form-control" id="statusId">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in taskStatus " v-if="info.value !== 'Closed'" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                </div>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-12 mb-3">
                                            <div class="card">
                                                <div class="row mx-1 my-2">
                                                    <div class="col-12 col-md-3 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Type</label>
                                                            <label class="form-control task-label">{{ task.type }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Activity</label>
                                                            <label class="form-control task-label">{{ task.activity }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-2 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Requirement</label>
                                                            <label class="form-control task-label">{{ task.requirement }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-2 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Priority</label>
                                                            <label class="form-control task-label">{{ task.priority }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-2 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Est. Effort</label>
                                                            <label  class="form-control task-label">
                                                                <span v-if="task.workDay"> {{task.workDay}} days </span>
                                                                <span v-if="task.workHour"> {{task.workHour}} hrs </span>
                                                                <span v-if="task.workMinute"> {{task.workMinute}} mins </span>
                                                                
                                                               <!--  {{ task.effort }} -->
                                                           
                                                                <!--<span v-if="task.effortType > 0">{{getEffortType(effortTypes, task.effortType)}} </span>-->
                                                                <!-- {{ effortTypes.filter(function(a){ return a.id == task.effortType })[0].name }} -->
                                                            </label>
                                                            <!-- <label v-else class="form-control task-label"></label> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mx-1 my-2">
                                                    <div class="col-12 col-md-3 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Assigned By</label>
                                                            <label class="form-control task-label">{{ task.assignedBy }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 mt-3">
                                                        <div class="form-group">
                                                            <label class="font-weight-bold">Assigned To</label>
                                                            <label class="form-control task-label">{{ task.assignedTo }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 ml-3">
                                            <div class="form-group">
                                                <label class="font-weight-bold">Description</label>
                                                <div class="col-12">
                                                    <span v-html="task.description"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 ml-3">
                                            <div class="form-group">
                                                <label class="font-weight-bold">Response</label>
                                                <div class="col-12">
                                                    <span v-if="!elements.taskStatusUpdate" v-html="task.response"></span>
                                                    <!-- <editor v-if="elements.taskStatusUpdate && task.assignedToId == userId" api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="task.response"></editor> -->
                                                    <VmxEditor v-if="elements.taskStatusUpdate && task.assignedToId == userId" v-model="task.response"></VmxEditor>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 ml-3">
                                            <div class="form-group">
                                                <div>
                                                    <label class="font-weight-bold">Attachment<i class="fa fa-lg fa-paperclip ml-2" aria-hidden="true"></i></label>
                                                </div>
                                                <div id="Multi-attachment" v-for="(input, index) in addMoreAttachment">
                                                    <div class="attachments mb-2">
                                                        <b-form-file class="attachments-list" disabled size="sm" v-model="task.attachments[index]" placeholder="Choose a file.">
                                                            <template slot="file-name" slot-scope="{ names }">
                                                                <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                            </template>
                                                        </b-form-file>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="form-group">
                                                <div class="col-12  px-0">
                                                    <FunctionalReferencePopup ref="referenceModal" :entity="task.entity" :erdId="Number(task.id)" :ermId="Number(task.entityRecordMasterId)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-3">
                                                <div class="ml-1 task-box-items">
                                                    <label class="font-weight-bold">Check List</label>
                                                    <div class="row">
                                                        <div class="col-12 m-1 ml-2">
                                                            <div class="form-group">
                                                                <div v-for="(checkListAll, index) in taskCheckListAll" class="form-check">
                                                                    <input type="checkbox" @change="updateChecklist(checkListAll)" :value="checkListAll.state" v-model="checkListAll.state" :checked="true" class="form-check-input" id="checkbox-1" :disabled="!elements.taskStatusUpdate">
                                                                    <label class="form-check-label" for="checkbox-1">{{ checkListAll.checklist }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="elements.taskStatusUpdate && task.assignedById == userId" class="row">
                                                        <div class="col-10">
                                                            <div class="form-group">
                                                                <input type="text" class="form-control" v-model="checklistData.checklistLocal" placeholder="Add an item..." @keyup.enter="addChecklist">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <button type="submit" @click="addChecklist" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="elements.viewOnlyComponent" class="row">
                                        <div class="col-12 mt-0">
                                            <div class="card-body p-0 mb-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-comments>
                                                            <label for="description">Comments</label>
                                                        </label>
                                                    </b-card-header>
                                                    <!--  -->
                                                    <!-- v-if="elements.taskStatusUpdate && task.assignedById == userId" -->
                                                    <b-collapse id="accordion-comments" v-model="elements.visibileCommAccordian" accordion="my-accordion3" role="tabpanel">
                                                        <b-card-body class="p-2 pb-0">
                                                            <div class="comments-list1">
                                                                <div class="row mb-3">
                                                                    <div class="col-12" v-for="loadComment in comments">
                                                                        <ul class="comments-list reply-list">
                                                                            <li>
                                                                                <div class="user-icon">
                                                                                    <span class="user-name-icon" :title="loadComment.commentedBy"> {{ loadComment.commentedBy | getAcronym }} </span>
                                                                                </div>
                                                                                <div class="comment-box">
                                                                                    <div class="comment-head">
                                                                                        <h6 class="comment-name by-author" :title="loadComment.commentedBy"><a href="">{{ loadComment.commentedBy }}</a></h6>
                                                                                        <span>{{ loadComment.commentDt | formatDateTimeAmPm }}</span>
                                                                                        <a href="#" v-if="(loadComment.id != comment.editId)" title="Edit" @click="editComment(loadComment)"><i class="fa fa-pencil  action-link"></i></a>
                                                                                        <a href="#" v-if="(loadComment.id == comment.editId) && elements.commentEditMode" title="Update Comment" @click="updateComment"><i class="fa fa-check action-link"></i></a>
                                                                                        <a href="#" v-if="(loadComment.id == comment.editId) && elements.commentEditMode" title="Cancel Edit" @click="clearComment"><i class="fa fa-times action-link"></i></a>
                                                                                        <a href="#" @click="deleteComment(loadComment.id)" v-b-modal.deleteComment><i class="fa fa-trash-o action-link"></i></a>
                                                                                    </div>
                                                                                    <div v-if="(loadComment.id != comment.editId)" class="comment-content pt-1 pb-1">{{ loadComment.comment }}</div>
                                                                                    <div v-if="(loadComment.id == comment.editId) && elements.commentEditMode">
                                                                                        <textarea rows="3" class="w-100" v-model="comment.comment"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- v-if="!elements.viewMode" -->
                                                            <div v-if="elements.taskStatusUpdate && task.assignedById == userId" class="comments-cox">
                                                                <div class="row">
                                                                    <div class="col-10">
                                                                        <div class="form-group">
                                                                            <div class="user-icon">
                                                                                <span v-for="user in users" v-if="user.id == task.userId" :title="user.value" class="user-name-icon"> {{ user.value | getAcronym }} </span>
                                                                            </div>
                                                                            <div elements.viewMode class="user-comments">
                                                                                <input type="text" class="form-control" v-model="comment.comment" id="comment" placeholder="Comments" @keyup.enter="addComment">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <button type="submit" @click="addComment" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else-if="comments.length == 0" class="row mb-3 text-center">
                                                                <div class="col-12"> No comments found </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="elements.viewOnlyComponent" class="row">
                                    <div class="col-12">
                                        <div class="card-body p-0 mb-3">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-timeSheet>
                                                        <label for="description">Time Sheet</label>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-timeSheet" accordion="my-accordion4" role="tabpanel">
                                                    <b-card-body class="p-2">
                                                        <TimeSheetComponent ref="timeSheetComponent" :taskId="task.id" :viewTimeSheet="!elements.taskStatusUpdate" />
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3 fixed-task-footer">
                                <div class="col-12 text-right">
                                    <button type="submit" v-if="!elements.viewMode || elements.taskStatusUpdate" @click="saveData" :disabled="showOverlay" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Save</button>
                                    <button v-if="!elements.viewMode" type="submit" @click="toggleDiv();clearData()" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 tassk-close-btn"><i class="fa fa-times pr-2"></i>Cancel</button>
                                    <button v-else type="submit" @click="toggleDiv();clearData()" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 tassk-close-btn"><i class="fa fa-times pr-2"></i>Close</button>
                                </div>
                            </div>
                        </div>

                        <template #overlay>
                            <div class="text-center">
                                <LoadingSpinner />
                            </div>
                        </template>
                    </b-overlay>
                </div>
            </div>
        </div>

        <AddUserActivityPopup v-if="showDialog" id="userActivityModal" ref="addUserActivityModal"  @refresh-lookup="lookupData" @closeDialog="closeDialog" />           
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <!-- <ActivityLookup v-if="showDialog" ref="typeLookupModal" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" /> -->
        <ERMMasterPopup v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData();" @closeDialog="showDialog=false" />
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <DeleteComponent id="deleteComment" :onYes="onYesComment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />
        <AudioRecord ref="audioRecordRef" recordModalId="addRecordModalId" :recordAudio="true"  :refEntity="task.entity" :refRecordId="refEntityTask" @assign-data="assignAudioUrl" />
        <DeleteComponent id="deleteAudio" :onYes="onYesAudio" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Audio?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import AddUserActivityPopup from '@/components/popup/action/AddUserActivityPopup.vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import VmxEditor from '@/components/usercontrols/VmxEditor';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import TimeSheetComponent from '@/components/common/TimeSheetComponent.vue';
import AudioRecord from '@/components/usercontrols/RecordControl.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    name: 'app',
    props: {
        elements: Object,
    },
    components: {
        TypeLookup,
        ActivityLookup,
        AddUserActivityPopup,
        ERMMasterPopup,
        Multiselect,
        VmxEditor,
        FunctionalReferencePopup,
        ProcessActivityLookup,
        TimeSheetComponent,
        AudioRecord,
        VhextMoneyControl
    },
    data() {
        return {
            showOverlay: false,
            // elements: {
            //     clientTaskPage: '',
            //     editMode: false,
            //     viewMode: false,
            //     commentEditMode: false,
            //     viewOnlyComponent: false,
            //     filterMode: false,
            //     visibileChecklist: false,
            //     visibileCommAccordian: false,
            //     taskStatusUpdate: false,
            //     isHide: true,
            //     isDisable: true
            // },
            showDialog: false,
            taskId: null,
            effortType: '',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            typeEntity: 'task_type',
            userTypeCode: localStorage.getItem("userTypeCode"),

            typeLookupTitle: 'Task Type Settings',
            processActivityLookupTitle: 'Process / Activity Settings',
            activityLookupTitle: 'Activity Settings',

            ermMasterInfo: {
                title: "Development Plan",
                entity: 'development_plan_master',
                entityId: 142,
                refEntity: 'task',
                refEntityId: 74,
            },

            filteredUserId: null,

            task: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                userId: localStorage.getItem("userId"),
                entity: 'task',
                title: '',
                isArchive: false,
                planId: '',
                requirementId: '',
                requirement: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                estimatedCost: '',
                progressPercentage: 0,
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasChecklist: '',
                entityId: '',
                refErmId: '',
                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checkListTag: '',
                response: '',
                checklistList: [],
                workHour: '',
                workMinute: '',
                workDay: ''
            },
            comment: {
                id: '',
                commentId: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: '',
                commentedById: localStorage.getItem("userId"),
                entityId: '',
                refErdId: '',
                refEntity: 'task',
                comment: '',
                commentDt: this.getDateValue(new Date()),
                editId: ''
            },
            checklistData: {
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                entity: 'task',
                preparedById: '',
                checklistReportId: '',
                checklist: '',
                checklistLocal: '',
                state: '',
                preparedDt: ''
            },
            detailViewData: {},
            referencePopupId: 0,

            entity: 'development_plan_master',

            // effortTypes: [],
            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            allTasks: [],
            tasks: [],
            taskCheckList: [],
            taskCheckListAll: [],
            comments: [],
            addMoreAttachment: [],
            users: [],
            ermLookup: [],

            requirements: [],
            processActivities: [],
            taskTypes: [],
            taskPriorities: [],
            taskStatus: [],
            entityRecordMasters: [],
            search: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            audioUrl:'',
            refEntityTask:'',
            attachments:[]
        }
    },
    mounted() {
        this.setupPage();
        this.lookupData();
        this.addMoreAttachment.push(this.addMoreAttachment.length + 1);
        let erdId = localStorage.getItem("erdId"); 
        if(erdId != null && erdId.length >0){
            this.fromActionItem(erdId);
        }
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            progressPercentage: {
                between: between(0, 100),
                numeric
            },
            entityRecordMasterId: {
                required
            }
        },
        checklistData: {
            checklistLocal: {
                required
            }
        }
    },
    methods: {
        fromActionItem(erdId){
            this.viewTask(erdId);
            this.toggleDiv();
            localStorage.setItem("erdId", null);
        },
        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        setupPage: function() {
            console.log(this.$route.name);
            if (this.$route.name == 'clitask' || this.$route.name == 'usertask') {
                this.elements.clientTaskPage = true;
            } else this.elements.clientTaskPage = false;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'task'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.requirements = result.requirementLookup;
                    this.processActivities = result.userProcessActivityLookup;
                    // this.taskActivity = result.activityLookup;
                    this.taskStatus = result.statusLookup;
                    this.taskTypes = result.customTypeLookup;
                    this.taskPriorities = result.priorityLookup;
                    this.ermLookup = result.entityERMLookup;

                    this.task.statusId = this.taskStatus[0].id;
                    console.log(this.users);
                })
        },
        saveData: function() {
            this.$v.checklistData.$reset();
            this.$v.task.$touch();
            if (!this.$v.task.$invalid) {
                this.task.processId = this.task.processActivity.processId;
                this.task.activityId = this.task.processActivity.activityId;

                if (this.task.estimatedStartDt == '' || this.task.estimatedStartDt == null) { 
                    this.task.estimatedStartDt = this.combineDateAndTime(this.task.estimatedStartDate, this.task.estimatedStartTime) 
                }
                if (this.task.estimatedEndDt == '' || this.task.estimatedEndDt == null) { 
                    this.task.estimatedEndDt = this.combineDateAndTime(this.task.estimatedEndDate, this.task.estimatedEndTime) 
                }
                console.log(this.task.estimatedEndTime, this.combineDateAndTime(this.task.estimatedEndDate, this.task.estimatedEndTime));
                if (this.elements.editMode) {
                    this.updateTask();
                } else {
                    this.saveTask();
                }
            }
        },
        saveTask: function() {
            this.showOverlay = true;
            /*
            console.log(this.$refs.effortControl.effortInHrs());
            console.log(this.$refs.effortControl.effortInMins());
            console.log( this.$refs.effortControl.effortInDays());
            console.log("==============================");
            console.log(this.$refs.effortControl.getHrs());
            console.log(this.$refs.effortControl.getMins());
            console.log(this.$refs.effortControl.getDays());
            console.log("==============================");
            this.$refs.effortControl.setHrs(1);
            this.$refs.effortControl.setMins(2);
            this.$refs.effortControl.setDays(3);
            console.log("==============================");
            console.log(this.$refs.effortControl.getHrs());
            console.log(this.$refs.effortControl.getMins());
            console.log(this.$refs.effortControl.getDays());
            console.log("==============================");  */    

              
            // this.task.taskAssignmentList = [];
            // for (var index = 0; index < this.task.taskAssignment.length; index++) {

            //     this.task.taskAssignmentList.push({ assignedToId: this.task.taskAssignment[index].id });
            // }
            // this.task.taskAssignmentList.push({ assignedToId: this.task.assignedById });
            this.setEffortData();
            console.log(this.task);
      
            axios.post(this.$store.getters.getAPIBasePath + '/task/add', this.task)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.$refs.audioRecordRef.onDataChange(result.id);
                        if (this.task.attachments != null && this.task.attachments.length > 0)
                            this.submitFiles(result.id);
                        else {
                            this.showMessage('Task Created without attachment.', result.id);
                        }
                        let erdId = result.id;
                        this.$refs.referenceModal.saveRefArray(erdId);
                        this.$emit('refresh-task', this.task.entityRecordMasterId);
                        this.toggleDiv();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        showView: function(data) {
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.elements.viewOnlyComponent = true;
            this.task.id = data.id;
            console.log(data);
            this.getComments();
            this.getChecklist(this.task.id);
            this.$nextTick(()=> {                
                this.$refs.timeSheetComponent.getTimeSheets(this.task.id);
            })
            this.task.title = data.title;
            this.task.estimatedStartDt = data.estimatedStartDt;
            this.task.estimatedStartDate = data.estimatedStartDate;
            this.task.estimatedStartTime = data.estimatedStartTime;
            this.task.estimatedEndDt = data.estimatedEndDt;
            this.task.estimatedEndDate = data.estimatedEndDate;
            this.task.estimatedEndTime = data.estimatedEndTime;
            this.task.isArchive = data.isArchive;
            this.task.taskAssignment = data.taskAssignment;
            this.task.processActivity.activityId = data.activityId;
            this.task.processActivity.processId = data.processId;
            this.task.activityId = data.activityId;
            this.task.requirementId = data.requirementId;
            this.task.requirement = data.requirement;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.taskId = data.taskId;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.startDate = data.startDate;
            this.task.endDate = data.endDate;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = data.assignedById;
            this.task.assignedTo = data.assignedTo;
            this.task.assignedToId = data.assignedToId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.createdDt = data.createdDt;
            this.task.lastUpdatedDt = data.lastUpdatedDt;
            this.task.response = data.response;
            this.task.workHour = data.workHour;
            this.task.workMinute = data.workMinute;
            this.task.workDay = data.workDay;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.elements.visibileChecklist = false;
            this.elements.visibileCommAccordian = false;
            this.elements.viewOnlyComponent = true;
            this.task.id = data.id;
            this.refEntityTask = String(data.id);
            this.getComments();
            this.getChecklist(this.task.id);
            this.getAudioUrl(this.task.id, 'task');
            this.$nextTick(()=> {                
                this.$refs.timeSheetComponent.getTimeSheets(this.task.id);
            })
            this.task.title = data.title;
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = this.getDateValue(data.estimatedStartDt);
            this.task.estimatedStartTime = this.getTimeValue(data.estimatedStartDt);
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = this.getDateValue(data.estimatedEndDt);
            this.task.estimatedEndTime = this.getTimeValue(data.estimatedEndDt);
            this.task.isArchive = data.isArchive;
            this.task.taskAssignment = data.taskAssignment;
            this.task.processActivity.activityId = data.activityId;
            this.task.processActivity.processId = data.processId;
            this.task.requirementId = data.requirementId;
            this.task.requirement = data.requirement;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.taskId = data.taskId;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.startDate = data.startDate;
            this.task.endDate = data.endDate;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = data.assignedById;
            this.task.assignedTo = data.assignedTo;
            this.task.assignedToId = data.assignedToId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.createdDt = data.createdDt;
            this.task.lastUpdatedDt = data.lastUpdatedDt;
            this.task.response = data.response;
            this.task.workHour = data.workHour;
            this.task.workMinute = data.workMinute;
            this.task.workDay = data.workDay;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },
        updateTask: function() {
            this.showOverlay = true;
            this.setEffortData();
            console.log(this.task);
            axios.post(this.$store.getters.getAPIBasePath + '/task/update', this.task)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.$refs.audioRecordRef.onDataChange(this.task.id);
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.elements.editMode = false;
                        this.elements.viewMode = true;
                        this.elements.commentEditMode = false;
                        this.elements.taskStatusUpdate = false;
                        this.$emit('refresh-task', this.task.entityRecordMasterId);
                        this.viewTask(this.task.id);
                        this.getComments();
                        this.getChecklist(this.task.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.viewMode = false;
            this.elements.visibileChecklist = false;
            this.elements.visibileCommAccordian = false;
            this.elements.taskStatusUpdate = false;
            this.task.id = null;
            this.task.title = '';
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = this.getDateValue(new Date());
            this.task.estimatedEndTime = '';
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = this.getDateValue(new Date());
            this.task.estimatedStartTime = '';
            this.task.taskAssignment = '';
            this.task.processActivity.activityId = '';
            this.task.processActivity.processId = '';
            this.task.activityId = '';
            this.task.requirementId = '';
            this.task.requirement = '';
            this.task.effort = '';
            this.task.effortType = '';
            this.task.workHour = '';
            this.task.workMinute = '';
            this.task.workDay = '';
            this.task.estimatedCost = '';
            this.task.progressPercentage = 0;
            this.task.statusId = this.taskStatus[0].id;
            this.task.assignedTo = '';
            this.task.assignedToId = '';
            this.task.taskId = '';
            this.task.planId = '';
            this.task.activity = '';
            this.task.taskTypeId = '';
            this.task.type = '';
            this.task.description = '';
            this.task.priorityId = '';
            this.task.priority = '';
            this.task.startDate = '';
            this.task.endDate = '';
            this.task.hasReference = '';
            this.task.hasAttachment = '';
            this.task.hasComment = '';
            this.task.hasChecklist = '';
            this.task.entityId = '';
            this.task.refErmId = '';
            this.task.response = '';
            this.taskCheckList = [];
            this.taskCheckListAll = [];
            this.task.attachments = [];
            this.task.checklistList = [];
            this.$refs.referenceModal.clearReference();
            this.$v.task.$reset();
            this.$v.checklistData.$reset();
            this.audioUrl = '';
            this.$refs.effortControl.setEffortInputData('', '', '');
        },
        viewTask: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let data = result.task;
                    this.elements.editMode = false;
                    this.showView(data);

                })
        },
        getChecklist: function(id) {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: this.task.id,
                refEntity: 'task'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.taskCheckListAll = result.taskChecklistReportList;

                    console.log(this.taskCheckListAll)
                    if (!this.elements.viewOnlyComponent && this.taskCheckListAll != null && this.taskCheckListAll.length > 0) {
                        for (var index = 0; index < this.taskCheckListAll.length; index++) {
                            this.task.checklistList.push({
                                checklist: this.taskCheckListAll[index].checklist,
                                entityRecordMasterId: this.task.entityRecordMasterId,
                                projectId: this.task.projectId,
                                vhextAccountId: this.task.vhextAccountId,
                                refEntity: 'task',
                                preparedById: this.userId,
                                state: false,
                            });
                        }
                        console.log(this.task.checklistList);
                        this.taskCheckListAll = [];
                    }

                    console.log(result);
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        pushChecklist: function() {
            this.$v.checklistData.checklistLocal.$touch();
            if (this.$v.checklistData.checklistLocal.$invalid) {
                return false;
            }
            this.task.checklistList.push({
                checklist: this.checklistData.checklistLocal,
                entityRecordMasterId: this.task.entityRecordMasterId,
                projectId: this.task.projectId,
                vhextAccountId: this.task.vhextAccountId,
                refRecordId: this.task.id,
                refEntity: 'task',
                preparedById: this.userId,
                state: false,
            });
            this.$v.checklistData.$reset();
            this.checklistData.checklistLocal = "";
            console.log(this.task.checklistList);
        },
        addChecklist: function() {
            if (this.task.id == null) {
                iziToast.success({
                    title: 'info',
                    message: 'Please save the task information before saving checklist.',
                    position: 'topRight'
                });
                return;
            }

            this.checklistData.entityRecordMasterId = this.task.entityRecordMasterId;
            this.checklistData.projectId = this.task.projectId;
            this.checklistData.vhextAccountId = this.task.vhextAccountId;
            this.checklistData.refRecordId = this.task.id;
            this.checklistData.refEntity = 'task';
            this.checklistData.preparedById = this.userId;
            this.checklistData.state = false;
            this.checklistData.preparedDt = this.getDateValue(new Date());
            this.checklistData.checklist = this.checklistData.checklistLocal;

            console.log(this.checklistData);
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/add', this.checklistData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.getChecklist();
                        this.checklistData.checklistLocal = '';
                    }
                })
        },
        updateChecklist: function(data) {
            console.log(data);
            this.checklistData.id = data.id;
            this.checklistData.entityRecordMasterId = data.entityRecordMasterId;
            this.checklistData.projectId = data.projectId;
            this.checklistData.vhextAccountId = data.vhextAccountId;
            this.checklistData.refEntityId = data.refEntityId;
            this.checklistData.checklistReportId = data.checklistReportId;
            this.checklistData.preparedById = data.preparedById;
            this.checklistData.preparedBy = data.preparedBy;
            this.checklistData.checklist = data.checklist;
            this.checklistData.state = data.state;
            this.checklistData.preparedDt = data.preparedDt;

            console.log(this.checklistData);
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/update', this.checklistData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.getChecklist();
                        this.checklistData.checklistLocal = '';
                    }
                })
        },
        getComments: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refErdId: this.task.id,
                refEntity: 'task'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.comments = result.taskCommentList;
                    console.log(this.comments);
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addComment: function() {
            console.log(this.comment);
            this.comment.refErdId = this.task.id;
            this.comment.entityRecordMasterId = this.task.entityRecordMasterId;
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/add', this.comment)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments();
                        this.clearComment();
                    }
                })
        },
        updateComment: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/update', this.comment)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments();
                        this.clearComment();
                        this.elements.commentEditMode = false;
                    }
                })
        },
        editComment: function(data) {
            this.elements.commentEditMode = true;
            this.comment.editId = data.id;
            this.comment.id = data.id;
            this.comment.commentId = data.commentId;
            this.comment.projectId = data.projectId;
            this.comment.vhextAccountId = data.vhextAccountId;
            this.comment.entityRecordMasterId = data.entityRecordMasterId;
            this.comment.commentedById = data.commentedById;
            this.comment.entityId = data.entityId;
            this.comment.refEntity = data.refEntity;
            this.comment.comment = data.comment;
            this.comment.commentDt = data.commentDt;
        },
        deleteComment: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYesComment: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/comment/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments(this.comment.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearComment: function() {
            this.elements.commentEditMode = false;
            this.comment.id = '';
            this.comment.editId = '';
            this.comment.commentId = '';
            this.comment.comment = '';
        },
        addInput: function() {
            this.addMoreAttachment.push(this.addMoreAttachment.length + 1)
        },
        removeFile: function(index) {
            this.task.attachments.splice(index, 1);
        },
        submitFiles: function(taskId) {
            let formData = new FormData();
            for (const row of Object.keys(this.task.attachments)) {
                formData.append('files', this.task.attachments[row]);
                formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
                formData.append('projectId', localStorage.getItem("projectId"));
                formData.append('attachedById', localStorage.getItem("userId"));
                formData.append('refRecordId', taskId);
                formData.append('refEntity', 'task');
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Task Created with attachment.', taskId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    this.showMessage('Task Created without attachment.', taskId);
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function(message, id) {
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
        },
        progressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                } else if (task.progressPercentage < 50) {
                    return 'warning';
                } else if (task.progressPercentage < 75) {
                    return 'info';
                } else if (task.progressPercentage < 100) {
                    return 'success';
                } else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        duplicateTask: function(data) {
            this.elements.viewOnlyComponent = false;
            console.log(data);
            this.task.id = data.id;
            this.getChecklist(this.task.id);
            this.task.title = data.title;
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = this.getDateValue(data.estimatedStartDt);
            this.task.estimatedStartTime = this.getTimeValue(data.estimatedStartDt);
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = this.getDateValue(data.estimatedEndDt);
            this.task.estimatedEndTime = this.getTimeValue(data.estimatedEndDt);
            this.task.isArchive = false;
            this.task.taskAssignment = data.taskAssignment;
            this.task.processActivity.activityId = data.activityId;
            this.task.processActivity.processId = data.processId;
            this.task.activityId = data.activityId;
            this.task.requirementId = data.requirementId;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = this.userId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.response = data.response;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType != null) {
                return effortType[0].name;
            }
        },
        dueDateStyling(date){
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today) && (date > today)) {        
                    return 'text-warning';
                }
                else if (date < today) {        
                    return 'text-danger';
                }
                else if (date > today) {        
                    return 'text-success';
                }
            }
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        },
        closeDialog: function() {
            this.showDialog = false;
            this.clearData();
        },
        assignAudioUrl: function(value) {
            this.audioUrl = value;
        },
        showAudioRecord:function(){
            this.$bvModal.show('addRecordModalId');
        },
        onYesAudio:function(){
            this.audioUrl = '';
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.attachments[0].id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        this.attachments = [];
                    console.log('Attachment successfully deleted')                   
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            
        },
        closeAudio:function(){
          if(this.elements.editMode && this.attachments[0] != null){
                this.$bvModal.show('deleteAudio');
          }else{
                this.audioUrl = '';
          }
        },
        getAudioUrl: function(refRecordId, refEntity) {
            let data = {
                vhextAccountId: this.task.vhextAccountId,
                projectId: this.task.projectId,
                refEntity: refEntity,
                refRecordId: refRecordId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                    if(this.attachments!= null && this.attachments.length>0){
                        this.audioUrl = this.attachments[0].attachmentPath;
                    }
                   
                })
        },
        moneyControlInput: function(value) {
            this.task.estimatedCost = Math.abs(value);
        },
        setEffortData: function() {
            this.task.workHour = this.$refs.effortControl.getHrs();
            this.task.workMinute = this.$refs.effortControl.getMins();
            this.task.workDay = this.$refs.effortControl.getDays();
        },
    
    }
}
</script>