<template>
    <div>
        <ChecklistMaster :ermMasterInfo="ermMasterInfo"></ChecklistMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ChecklistMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ChecklistMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Checklist Master",
                entity: 'checklist_master',
                refEntity: 'checklist',
                ermListRedirectURL: '/cklmas/list',
                erdRedirectURL: '/quality/checklist/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>