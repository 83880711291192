<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <GeneralDocumentMasterList :ermMasterInfo="ermMasterInfo" ></GeneralDocumentMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import GeneralDocumentMasterList from '../../components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        GeneralDocumentMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "General Documents",
                entity: 'general_document_master',
                refEntity: 'policy_document',
                tooltip: 'New General Doc',
                redirectURLName: 'lanpoldoc',
                // newEntityMasterURL: 'issmas',
                // redirectURL: '/issmas',
                showTypeLookup: true,
                hideProject: true,
                hideClient: true,
                actionName: 'Policy Document',
                previewURLName: 'langndrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>