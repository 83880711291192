<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="effortEstimationLinkPopupRef" size="xl" modal-class="" title="Estimations" ok-only>
            <!-- <div v-if="showOverlay" id="loader"></div>
            <div v-if="!showOverlay"> -->
            <b-tabs content-class="" lazy>
                <div class="card">
                    <div class="card-body p-3">
                        <b-tab title="Resource Estimation">
                        </b-tab>
                        <b-tab title="Effort Estimation">
                            <EffortEstimationLinkComp ref="effortEstLinkCompRef" :ermId="ermId" :erdId="erdId" :erdTitle="erdTitle" :refEntity="refEntity" refErmEntity="refErmEntity" :linkedEffortEstList="linkedEffortEstList" @onEffortEstimationAdd="onEffortEstimationAdd" @refresh-list="refreshList" />
                        </b-tab>
                        <b-tab title="Cost Estimation">
                            <CostEstimationLinkComp ref="costEstLinkCompRef" :ermId="ermId" :erdId="erdId" :erdTitle="erdTitle" :refEntity="refEntity" refErmEntity="refErmEntity" :linkedCostEstList="linkedCostEstList" @onCostEstimationAdd="onCostEstimationAdd" @refresh-list="refreshList" />
                        </b-tab>
                    </div>
                </div>
            </b-tabs>
            <!-- </div> -->
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button size="sm" variant="outline-primary" @click.prevent="cancel()" class="float-right ml-1">
                        Close
                    </b-button>
                    <!-- <b-button size="sm" variant="outline-primary" @click.prevent="cancel();selectedCostEstimationList=[];" class="float-right ml-1">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" @click="setCostEstimationList" class="float-right" >Ok</b-button> -->
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import EffortEstimationLinkComp from '@/components/common/EffortEstimationLinkComp.vue';
import CostEstimationLinkComp from '@/components/common/CostEstimationLinkComp.vue';
export default {
    props: {
        id: { type: String, default: 'effortEstimationLinkPopup'},
        ermId: [String, Number],
        erdId: [String, Number],
        erdTitle: String,
        refEntity: String,
        refErmEntity: String,
        linkedEffortEstList: Array,
        linkedCostEstList: Array,
    },
    components: {
        EffortEstimationLinkComp,
        CostEstimationLinkComp,
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        onEffortEstimationAdd(effortEstimationList, totalEffort) {
            this.$emit('onEffortEstimationAdd', effortEstimationList, totalEffort);
        },
        onCostEstimationAdd(costEstimationList, totalCost) {
            this.$emit('onCostEstimationAdd', costEstimationList, totalCost);
        },
        refreshList() {
            this.$emit('refresh-list');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>