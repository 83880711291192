<template>
    <div>
        <UserRequirementMasterList :ermMasterInfo="ermMasterInfo"></UserRequirementMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import UserRequirementMasterList from '@/components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        UserRequirementMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                //title: "User Requirement List",
                entity: 'user_requirement_master',
                refEntity: 'user_requirement',
                tooltip: 'New Client Requirement Doc',
                redirectURLName: 'userrequirement',
                newEntityMasterURL: 'urqmas',
                redirectURL: '/urqmas',
                actionName: 'Client Requirements',
                previewURLName: 'urqrep',
                headerTitle: 'Doc Title',
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;

        if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.title = "Client Requirement Doc List";
            this.ermMasterInfo.redirectURLName = 'cliuserrequirement';
            this.ermMasterInfo.newEntityMasterURL = 'cliurqmas';
            this.ermMasterInfo.redirectURL = '/client/urqmas';
            //this.ermMasterInfo.actionName = 'User Requirement';
            this.ermMasterInfo.previewURLName = 'cliurqrep';
        } else {
            this.ermMasterInfo.title = "Client Requirement Doc List";
            this.ermMasterInfo.redirectURLName = 'userrequirement';
            this.ermMasterInfo.newEntityMasterURL = 'urqmas';
            this.ermMasterInfo.redirectURL = '/urqmas';
            //this.ermMasterInfo.actionName = 'User Requirement';
            this.ermMasterInfo.previewURLName = 'urqrep';
        }
    },
    methods: {}
}
</script>