<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-8 col-sm-6">
                        <h4 class="pl-2 mb-0 fs-16">Admin Settings</h4>
                    </div>
                    <div class="col-lg-4 col-sm-6">
                        <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                            <ol class="breadcrumb pt-0 mb-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="separator mb-2"></div>
            </div>
        </div>
        <div class="form-row">
            <div class="col-12">
                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                    <div v-if="mainMenuByModule.length>0" class="card p-3">
                        <b-tabs content-class="mt-3" lazy>
                            <b-tab v-for="mainMenu,index in mainMenuByModule" :title="mainMenu.customMainMenu" :key="index">
                                <div class="card bg-light-grey-shade px-3">
                                    <div class="row">
                                        <div v-for="setting in fileredSubMenuByModules(mainMenu)" class="col-md-4 py-3">
                                            <a href="javascript:" :title="setting.name" class="settings-box make-it-slow bg-white" @click="$router.push(setting.webUrl)">
                                                <div class="row col-md-12 ml-0 left-bar-tab p-2">
                                                    <div class="col-md-3 p-3">
                                                        <i class="fa fa-3x icon-b" :class="setting.icon ? setting.icon : 'fa-tag'" aria-hidden="true"></i>
                                                    </div>
                                                    <div class="col-md-8 text-left align-self-center p-2">
                                                        <p><b>{{ setting.displayName }}</b></p>
                                                        <p>{{ (setting.customSubMenu).includes('Setting') ? setting.displayName : setting.customSubMenu + ' Settings' }}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                    <div v-else class="row">
                        <div v-for="setting in subMenuByModule" class="col-md-4 py-3">
                            <a href="javascript:" :title="setting.name" class="settings-box make-it-slow bg-white" @click="$router.push(setting.webUrl)">
                                <div class="row col-md-12 ml-0 left-bar-tab p-2">
                                    <div class="col-md-3 p-3">
                                        <i class="fa fa-3x icon-b" :class="setting.icon ? setting.icon : 'fa-tag'" aria-hidden="true"></i>
                                    </div>
                                    <div class="col-md-8 text-left align-self-center p-2">
                                        <p><b>{{ setting.customSubMenu }}</b></p>
                                        <p>{{ (setting.displayName).includes('Setting') ? setting.displayName : setting.displayName + ' Settings' }}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/main';
export default {
    name: 'vsasettings',
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            showOverlay: false,
            activeComponent: '',
            // showComponent: false,
            activeTab: 101,

            settingModules: [],
            settingMainMenu: [],
            settingSubMenu: [],
        }
    },
    computed: {
        activeModule: {
            get() {
                return this.$store.state.adminSetting.moduleId;
            },
            set(value) {
                this.$store.state.adminSetting.moduleId = value;
            }
        },
        // showComponent: {
        //     get() {
        //         return this.$store.state.adminSetting.showComponent;
        //     },
        //     set(value) {
        //         this.$store.state.adminSetting.showComponent = value;
        //     }
        // },
        mainMenuByModule() {
            return this.settingMainMenu.filter(menu => menu.moduleId == this.activeModule);
        },
        subMenuByModule() {
            return this.settingSubMenu.filter(menu => menu.moduleId == this.activeModule);
        },
    },
    created() {
        EventBus.$on('onAdminSettingReload', () => {
            this.onAdminSettingReload()
        });
    },
    mounted() {
        this.getAdminMainMenu();
        this.getAdminSubMenu();
    },
    beforeDestroy() {
        EventBus.$off('onAdminSettingReload');
        // this.activeModule = null;
        // this.showComponent = false;
    },

    methods: {
        getAdminMainMenu: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: localStorage.getItem("userId"),
                userTypeCode: this.userTypeCode,
            }
            // this.showComponent = false;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/adminsettingmainmenus', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.settingMainMenu = result.adminSettingMainMenuLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getAdminSubMenu: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: localStorage.getItem("userId"),
                userTypeCode: this.userTypeCode,
            }
            // this.showComponent = false;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/adminsettingsubmenus', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.settingSubMenu = result.adminSettingSubMenuLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        fileredSubMenuByModules: function (menuData) {
            console.log(menuData);
            return this.subMenuByModule.filter(data => data.customMainMenu == menuData.customMainMenu);
        },
        onAdminSettingReload() {
            this.getAdminMainMenu();
            this.getAdminSubMenu();
        }

    }
}
</script>