<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="contractPayrollPopupRef" size="lg" modal-class="" title="Payroll" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe">
                    <label class="mb-0 d-block">Summary <span class="float-right">{{ popupData.startDate | formatDateAlt }} <i class="px-2">to</i> {{ popupData.endDate | formatDateAlt }}</span></label>
                    <div class="data-table-rows report-tables">
                        <b-table head-variant="light" show-empty small stacked="md" sort-icon-left :items="payrollSummary" :fields="payrollSummaryFields" responsive="sm" foot-clone no-footer-sorting >
                            <template #cell(show_details)="row">
                                <span @click.stop="row.toggleDetails" class="ml-3" :title="`${row.detailsShowing ? 'Hide' : 'Show'} Details`">
                                    <span @click="showDetail(row.item.transactionDt);getExpenses(row.item.transactionDt);">
                                        <i class="fa fa-angle-down fa-lg font-weight-bold action-link pointer" :class="row.detailsShowing ? 'rotation_180' : 'rotation_normal'"></i>
                                    </span>
                                </span>
                            </template>
                            <template slot="row-details" slot-scope="row">
                                <div class="data-table-rows report-tables text-left px-3">
                                    <b-table show-empty small header-variant="secondary" sort-icon-left :items="companyExpenditures" :busy="isBusyDetail" :fields="companyExpenditureFields" responsive="sm">
                                        <template v-slot:cell(unitCost)="data">
                                            <div>{{ currencySymbol }}{{ data.item.unitCost }}</div>
                                        </template>
                                        <template v-slot:cell(cost)="data">
                                            <div>{{ currencySymbol }}{{ data.item.cost }}</div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Details found</div>
                                        </template>
                                        <template v-slot:table-busy>
                                            <div class="text-center text-info my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>
                                    </b-table>
                                </div>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <!-- <a href="javascript:" title="Edit" @click="showDetailEdit(data.item, data.index, 'Earning')"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" title="Delete" @click="removePayrollDetail(data.item, 'Earning')" ><i class="fa fa-trash pl-2"></i></a> -->
                            </template>
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template v-slot:cell(totalCost)="data">
                                <div class="text-right">{{ data.value.toFixed(2) }}</div>
                            </template>

                            <template  v-slot:foot()="data">
                                <span></span>
                            </template>
                            <template v-slot:empty="scope">
                                <div class="text-center">No Item found</div>
                            </template>
                            <template v-slot:foot(transactionDt)="data">
                                <div class="text-right">
                                    <span>Total </span>
                                </div>
                            </template>
                            <template v-slot:foot(totalCost)="data">
                                <div class="text-right font-weight-bold">
                                    <span class="text-right">{{ currencySymbol }} {{totalAmount.toFixed(2)}}</span>
                                </div>
                            </template>
                            <template v-slot:emptyfiltered="scope">
                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import enummixin from "@/mixins/enummixin";
export default {
    mixins: [enummixin],
    props: {
        id:{type:String, default:'contractPayrollPopup'},
    },
    data() {
        return {
            loading: true,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            contractId: localStorage.getItem("contractId"),
            currencySymbol: localStorage.getItem("currency"),

            popupData: {},

            companyExpenditures: [],
            companyExpenditureFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Item Title' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'uomType', label: 'Unit' },
                { key: 'unitCost', label: 'Unit Cost' },
                { key: 'cost', label: 'Total Cost' },
            ],
            
            payrollSummaryList: [],
            payrollSummaryFields: [
                { key: 'show_details', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'transactionDt', label: 'Txn Date', formatter: "formatDate" },
                { key: 'totalCost', label: 'Total Cost' },
                // { key: 'actions', label: 'Actions' }
            ],

            totalRows: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    computed: {
        payrollSummary() {
            return this.payrollSummaryList.map((event)=> {
                event._showDetails = false;
                return event;
            })
        },
        totalAmount() {
            if (this.payrollSummary.length>0) {            
                return this.payrollSummary.reduce((accum, item) => {
                    return accum + parseFloat(item.totalCost ? item.totalCost : 0)
                }, 0.00)
                return 0;
            } else return 0;
        },
    },
    methods: {
        getDetails: function(payrollId, startDate, endDate) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: this.contractId,
                startDate: startDate,
                endDate: endDate,
            }
            this.getPayrollSummary(startDate, endDate);
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            });
            this.loading = true;
            axios.get(this.$store.getters.getAPIBasePath + '/finance/payroll/view/' + payrollId)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.popupData = result.payroll;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getPayrollSummary: function(startDate, endDate) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: this.contractId,
                startDate: startDate,
                endDate: endDate,
            }
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            });
            this.loading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/subcontract/payrollsummary/list', data)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.payrollSummaryList = result.contractPayrollSummary;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getExpenses: function(transactionDt) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                transactionDt: transactionDt,
            }
            this.isBusyDetail = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/list', data)
                .then((response) => {
                    this.isBusyDetail = false;
                    let result = response.data;
                    this.companyExpenditures = result.companyExpenditureList;
                })
                .catch(error => {
                    this.isBusyDetail = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        showDetail(transactionDt) {
            this.payrollSummaryList = this.payrollSummaryList.map((data) => {
                if (this.getDateValue(data.transactionDt) == this.getDateValue(transactionDt)) {
                    return { ...data, _showDetails: true }
                } else {
                    return { ...data, _showDetails: false }
                }
            })
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>