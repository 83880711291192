<template>
    <div>
        <DesignMaster :ermMasterInfo="ermMasterInfo"></DesignMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DesignMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        DesignMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Design Master",
                entity: 'design_master',
                refEntity: 'design',
                ermListRedirectURL: '/dsnmas/list',
                erdRedirectURL: '/designlist/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>