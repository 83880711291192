<template>
    <div>
        <ReleaseListMaster :ermMasterInfo="ermMasterInfo"></ReleaseListMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ReleaseListMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ReleaseListMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Release List Master",
                entity: 'release_list_master',
                refEntity: 'release_list',
                ermListRedirectURL: '/rllmas/list',
                erdRedirectURL: '/releaselist/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>