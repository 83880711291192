<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Candidate</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-7 col-xl-7">
                    <div class="card h-100">
                        <div v-if="elements.showResume" class="card-body p-3">
                            <div class="w-100 tab-floating-text px-3">
                                <div class="d-flex justify-content-between">
                                    <p class="m-0">{{ resumeFileName }}</p>
                                    <b-button  class="addnew-tassk-btn sbtn" @click="elements.showResume = false" variant="outline-danger" size="xs" ><i class="fa fa-remove pr-2" aria-hidden="true"></i>Close</b-button>
                                </div>
                                <div class="separator mb-2"></div>
                            </div>
                            <div class="mt-4 pt-1 h-100">
                                <!-- <embed :src="resumeFilePath" width="100%" height="100%" /> -->
                                <!-- <iframe :src="resumeFilePath" width="100%" height="100%" frameborder="0"></iframe> -->
                                <iframe v-if="isDocFile" :src="'https://view.officeapps.live.com/op/embed.aspx?src='+resumeFilePath" width="100%" height="100%" frameborder='0'></iframe>
                                <iframe v-else :src="resumeFilePath" width="100%" height="100%" frameborder="0"></iframe>
                            </div>
                        </div>
                        <div v-else class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Candidate</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="name">Name</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.candidate.name.$error }">
                                                                <input type="text" class="form-control" v-model="candidate.name" id="name" :class="{ 'is-invalid': $v.candidate.name.$error }" placeholder="Enter Candidate Name">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.candidate.name.$error && !$v.candidate.name.minLength" class="text-danger">Candidate Name must have at least {{$v.candidate.name.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.candidate.name.$error && !$v.candidate.name.required" class="text-danger">Please enter Candidate Name</label>
                                                            <label v-if="$v.candidate.name.$error && !$v.candidate.name.maxLength" class="text-danger">Candidate Name must not exceed {{$v.candidate.name.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="candidateTypeId">Candidate Type</label>
                                                            <select v-model="candidate.candidateTypeId" class="form-control" id="candidateTypeId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in candidateTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-6 col-md-4">
                                                        <div class="form-group">
                                                            <label for="emailId">Email Id</label>
                                                            <input type="text" class="form-control" id="emailId"v-model="candidate.emailId">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6 col-md-4">
                                                        <div class="form-group">
                                                            <label for="mobile">Mobile</label>
                                                            <input type="text" class="form-control" id="mobile" v-model="candidate.mobile">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="position">Position</label>
                                                            <input type="text" v-model="candidate.position" maxlength="100" class="form-control" id="position" placeholder="Enter Position">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="qualification">Qualification</label>
                                                            <input type="text" v-model="candidate.qualification" maxlength="100" class="form-control" id="qualification" placeholder="Enter Qualification">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Experience</label>
                                                            <b-input-group size="sm" class="mb-2">
                                                                <DayMonthYearControl 
                                                                    :dayVisible="false"
                                                                    @vmx-value-input="vmxValueInput" 
                                                                    :monthValue="candidate.experienceMonths"
                                                                    :yearValue="candidate.experienceYears"
                                                                    ref="dayMonthYearControl" />
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="noticePeriodEnum">Notice Period</label>
                                                            <select v-model="candidate.noticePeriodEnum" class="form-control" id="noticePeriodEnum">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in noticePeriodList" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <select v-model="candidate.statusId" class="form-control" id="statusId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in candidateStatus" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="dueDate">Due Date</label>
                                                            <input v-model="candidate.dueDate" type="date" id="dueDate" class="form-control" >
                                                        </div>
                                                    </div> -->
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="candidate.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-body p-0 mb-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accord-objective1>
                                                                        <label>Objective</label>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accord-objective1" accordion="my-accordion1" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="candidate.objective" ></editor>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-body p-0 mb-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accord-keyStrength1>
                                                                        <label>Key Strength</label>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accord-keyStrength1" accordion="my-accordion1" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="candidate.keyStrength" ></editor>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <AttachmentComponent id="attachmentComp1" ref="attachmentControl" singleFileUpload :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveCandidate" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="candidateTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="candidateList"  @row-selected="onRowSelected" :fields="candidateFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(experienceYears)="data">
                                    <span v-if="data.value">{{ data.value }} years </span>
                                    <span v-if="data.item.experienceMonths">{{ data.item.experienceMonths }} months</span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <div class="text-right">
                                        <div v-if="data.item.attachmentCount > 0" class="dropdown dropright d-inline">
                                            <a title="Resume" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachmentsDd(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="attachmentDdFiltered.length==0"></b-dropdown-item>
                                                <div v-if="attachmentDdFiltered.length>0">
                                                    <ul class="attachmentList-ul p-0 m-0">
                                                        <li v-for="attachment,index in attachmentDdFiltered" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                            <a href="javascript:" @click="attachmentClick(attachment)" class="action-link" >{{attachment.fileName}}</a>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <a v-if="!data.item.isEmployee" href="javascript:" title="Add as Employee" v-b-modal.saveEmployeeConfirmationDialog @click="saveEmployeeConfirmation(data.item)"><i class="fa fa-user-plus pl-2"></i></a>
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteCandidate(data.item.id)" v-b-modal.deleteCandidate><i class="fa fa-trash pl-2"></i></a>
                                    </div>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Candidates found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <div class="separator mb-2"></div>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                            <b-pagination v-if="candidateList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-5 pl-0">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-show="!elements.visible && elements.showDetail" v-model="rightTabIndex" content-class="mt-3">
                                    <b-tab title="Primary Info">
                                        <div id="profile" style="padding: 0px;">
                                            <div class="profile_header_sm">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <div class="my-profile-img">
                                                            <img v-if="contactDetail.photo" :src="contactDetail.photo" alt="Profile picture">
                                                            <img v-else src="@/assets/img/defaultuser.png" alt="Profile picture">
                                                            <label class="upload-icon upload-icon-contacts">
                                                                <i class="fa fa-camera"></i>
                                                                <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="uploadPhoto">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 col-sm-2 pl-3 mr-0 ml-auto">
                                                        <div class="more-btns text-center">
                                                            <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                            <div v-if="elements.editMode">
                                                                <button type="submit" @click="updateCandidate" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                                <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{candidateDetail.candidateId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateCandidate" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name2">Name</label>
                                                    <input type="text" class="form-control" id="name2" :disabled="!elements.editMode" v-model="candidateDetail.name">
                                                </div>
                                            </div>
                                            <!-- <div class="col-6">
                                                <div class="form-group">
                                                    <label for="candidateTypeId">Candidate Type</label>
                                                    <select v-model="candidateDetail.candidateTypeId" class="form-control" id="candidateTypeId" :disabled="!elements.editMode" >
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in candidateTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="positionDet">Position</label>
                                                    <input type="text" class="form-control" id="positionDet" :disabled="!elements.editMode" v-model="candidateDetail.position">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="qualificationDet">Qualification</label>
                                                    <input type="text" class="form-control" id="qualificationDet" :disabled="!elements.editMode" v-model="candidateDetail.qualification">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="emailId2">Email Id</label>
                                                    <input type="text" class="form-control" id="emailId2" :disabled="!elements.editMode" v-model="candidateDetail.emailId">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile2">Mobile</label>
                                                    <input type="text" class="form-control" id="mobile2" :disabled="!elements.editMode" v-model="candidateDetail.mobile">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Experience</label>
                                                    <b-input-group size="sm" class="mb-2">
                                                        <DayMonthYearControl 
                                                            :dayVisible="false"
                                                            @vmx-value-input="vmxValueInputDet" 
                                                            :monthValue="candidateDetail.experienceMonths"
                                                            :yearValue="candidateDetail.experienceYears"
                                                            ref="dayMonthYearControlDet"
                                                            :yearDisabled="!elements.editMode"
                                                            :monthDisabled="!elements.editMode" />
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="noticePeriodEnum">Notice Period</label>
                                                    <select v-model="candidateDetail.noticePeriodEnum" class="form-control" id="noticePeriodEnum" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in noticePeriodList" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <div class="col-6">
                                                <div class="form-group">
                                                    <label for="statusId">Status</label>
                                                    <select v-model="candidateDetail.statusId" class="form-control" :disabled="!elements.editMode" id="statusId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in candidateStatus" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div v-show="elements.editMode" class="col-12">
                                                <div class="form-group">
                                                    <AttachmentComponent id="attachmentComp2" ref="attachmentControlDet" singleFileUpload excludeDocType :attachmentInfo="attachmentInfo" preventLinkRedirect :attachments="attachments" @attachmentClick="attachmentClick" />
                                                </div>
                                            </div>
                                            <div v-show="!elements.editMode" class="col-12">
                                                <div class="form-group">
                                                    <label>Resume</label>
                                                    <label class="p-2 mb-0 d-block header-details">
                                                        <AttachmentComponent id="attachmentComp3" ref="attachmentControlDetView" excludeDocType :attachmentInfo="attachmentInfoView" viewAttachment preventLinkRedirect :attachments="attachments" @attachmentClick="attachmentClick" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.description2>
                                                                <label>Objective</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="description2" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <div v-if="elements.editMode" class="form-group">
                                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="candidateDetail.objective" :disabled="!elements.editMode"></editor>
                                                                            </div>
                                                                            <div v-else class="form-group">
                                                                                <p class="pl-2"><span v-html="candidateDetail.objective"></span></p>
                                                                            </div>                                                

<!--                                                                             <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="candidateDetail.objective" :disabled="!elements.editMode"></editor> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accord-keyStrength>
                                                                <label>Key Strength</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accord-keyStrength" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="candidateDetail.keyStrength" :disabled="!elements.editMode"></editor>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="candidateDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="candidateDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="candidateDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Key Strength">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.otherInfoEditMode" href="javascript:" @click="elements.otherInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.otherInfoEditMode">
                                                        <button type="submit" @click="updateCandidate" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- <div class="col-12">
                                                <label for="description">Process Procedure / Description</label>
                                                <div v-if="elements.editMode" class="form-group">
                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="processDetail.description"></editor>
                                                </div>
                                                <div v-else class="form-group">
                                                    <p class="pl-2"><span v-html="processDetail.description"></span></p>
                                                </div>                                                
                                            </div> -->

                                            <div class="col-12 pl-4">
                                                <div class="form-group">
                                                    <label for="keyStrength" class="pl-2">Key Strength</label>
                                                    <div v-if="elements.otherInfoEditMode" class="form-group">
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.otherInfoEditMode" v-model="candidateDetail.keyStrength"></editor>
                                                    </div>
                                                    <div v-else class="form-group">
                                                        <p class="pl-2"><span v-html="candidateDetail.keyStrength"></span></p>
                                                    </div>                                                
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <!-- <b-tab title="Roles & Resp.">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accord-roleResponsibility>
                                                                    <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Roles & Responsibilities</span>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accord-roleResponsibility" v-model="elements.roleVisible" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12 mb-3">
                                                                        <label for="role">Role</label>
                                                                        <a href="javascript:" class="float-right button-add" @click="elements.showDropdownRole=!elements.showDropdownRole;">
                                                                            <i v-if="!elements.showDropdownRole" title="Existing Roles" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                            <i v-if="elements.showDropdownRole" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                                        </a>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibilityDetail.role.$error }">
                                                                            <select v-if="elements.showDropdownRole" @change="getResponsibilities(roleResponsibilityDetail.roleData.id)" v-model="roleResponsibilityDetail.roleData" class="form-control">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in roles" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                            </select>
                                                                            <input v-if="!elements.showDropdownRole" type="text" class="form-control" id="role" v-model="roleResponsibilityDetail.role" placeholder="Enter New Roles">
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.roleResponsibilityDetail.role.$error && !$v.roleResponsibilityDetail.role.required" class="text-danger">Please Enter Role</label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="responsibility">Responsibility</label>
                                                                            <b-form-tags input-id="tags-separators" v-model="roleResponsibilityDetail.responsibilityArray" class="form-control" separator="," placeholder="Enter Responsibilities"></b-form-tags>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" @click="clearRoleResponsibility" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.roleEditMode ? 'Cancel' : 'Clear'}}</button>
                                                                <button type="submit" @click="saveRoleResponsibility()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>{{ elements.roleEditMode ? 'Update' : 'Add'}}</button>
                                                                <div class="clearfix"></div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="card mt-1" v-for="responsibility in responsibilities">
                                                <div class="card-body p-2" v-if="responsibility.id !== roleResponsibilityDetail.editId">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Role" @click="deleteRoleResponsibility(responsibility.id)" v-b-modal.deleteRoleResponsibility><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Role" @click="editRole(responsibility)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ responsibility.role }}</h6>
                                                                <p> {{ responsibility.responsibility }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab> -->
                                    <b-tab title="Tech & Skills">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accord-techSkill>
                                                                    <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Technology & Skills</span>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accord-techSkill" v-model="elements.techVisible" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12 mb-3">
                                                                        <label for="technology">Technology</label>
                                                                        <a href="javascript:" class="float-right button-add" @click="elements.showDropdownTech=!elements.showDropdownTech;">
                                                                            <i v-if="!elements.showDropdownTech" title="Existing Technologies" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                            <i v-if="elements.showDropdownTech" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                                        </a>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkillDetail.technology.$error }">
                                                                            <select v-if="elements.showDropdownTech" @change="getTechnologies(technologySkillDetail.technologyData.id)" v-model="technologySkillDetail.technologyData" class="form-control">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in technologies" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                                            </select>
                                                                            <input v-if="!elements.showDropdownTech" type="text" class="form-control" id="technology" v-model="technologySkillDetail.technology" placeholder="Enter New Technologies">
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.technologySkillDetail.technology.$error && !$v.technologySkillDetail.technology.required" class="text-danger">Please Enter Technology</label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="skills">Skills</label>
                                                                            <b-form-tags input-id="tags-separators" v-model="technologySkillDetail.skillsArray" separator="," class="form-control" placeholder="Enter skills"></b-form-tags>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" @click="clearTechnologySkill" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.techEditMode ? 'Cancel' : 'Clear'}}</button>
                                                                <button type="submit" @click="saveTechnologySkill()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Add</button>
                                                                <div class="clearfix"></div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="card mt-1" v-for="technologySkill in technologySkills">
                                                <div class="card-body p-2" v-if="technologySkill.id !== technologySkillDetail.editId">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Technology" @click="deleteTechnologySkill(technologySkill.id)" v-b-modal.deleteTechnologySkill ><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Technology" @click="editTech(technologySkill)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ technologySkill.technology }}</h6>
                                                                <p>{{ technologySkill.skills }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Contact">
                                        <div class="row">
                                            <div class="col-10">
                                            </div>      
                                            <div class="col-md-2 col-sm-2 pl-3 float-right">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.contactEditMode" href="javascript:" @click="elements.contactEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.contactEditMode">
                                                        <button type="submit" @click="saveContact" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="getContacts();elements.contactEditMode = false;" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Name</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.contactName">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Company</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.company">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="mobile">Mobile</label>
                                                    <input type="text" class="form-control" id="mobile" :disabled="!elements.contactEditMode"  v-model="contactDetail.mobile">
                                                </div>
                                            </div>
                                            <div class="col-12  col-sm-6">
                                                <div class="form-group">
                                                    <label for="emailId">Email Id</label>
                                                    <input type="text" class="form-control" id="emailId" :disabled="!elements.contactEditMode" v-model="contactDetail.emailId">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="phone">Phone</label>
                                                    <input class="form-control" type="text" id="phone" :disabled="!elements.contactEditMode" v-model="contactDetail.phone">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="phone">Office Phones</label>
                                                    <input class="form-control" type="text" id="officePhone" :disabled="!elements.contactEditMode" v-model="contactDetail.officePhone">
                                                </div>
                                            </div>


                                            <div class="divider"></div>
                                            <!-- <label class="px-3 mb-2" for="phone">Address Info</label> -->
                                            <h6 class="px-2 mb-1">Address Info</h6> 
                                            <div class="card mb-3 w-100">
                                                <div class="card-body p-2">
                                                    <div class="row px-2" >
                                                        <div class="col-12 col-md-6">
                                                            <div class="form-group ">
                                                                <label for="title">Building Name</label>
                                                                <input type="text" class="form-control" id="Address1" :disabled="!elements.contactEditMode" v-model="contactDetail.address1" >
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 ">
                                                            <div class="form-group ">
                                                                <label for="title">Street Name</label>
                                                                <input type="text" class="form-control" id="streetName" :disabled="!elements.contactEditMode" v-model="contactDetail.streetName">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2" >
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="city">City</label>
                                                                <input type="text" class="form-control" id="city" :disabled="!elements.contactEditMode" v-model="contactDetail.city">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="State">State</label>
                                                                <input type="text" class="form-control" id="State" :disabled="!elements.contactEditMode" v-model="contactDetail.state">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row px-2" >
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="projectManager">Country</label>
                                                                <select v-if="elements.contactEditMode" v-model="contactDetail.countryId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.contactEditMode" disabled v-model="contactDetail.country" type="text" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6 col-sm-6">
                                                            <div class="form-group ">
                                                                <label for="title">Pin Code</label>
                                                                <input type="text" class="form-control" id="pincode" :disabled="!elements.contactEditMode" v-model="contactDetail.pincode">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="divider"></div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Documents">
                                        <div class="card mb-3">
                                            <b-card no-body class="">
                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                    <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Document>
                                                        <span v-if="!elements.documentEditMode">
                                                            <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Document
                                                        </span>
                                                        <span v-if="elements.documentEditMode">Edit</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-Document" accordion="my-accordion" v-model="elements.docVisible" role="tabpanel">
                                                    <b-card-body class="p-3">
                                                        <div class="row">
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label for="documentType">Document Type</label>
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.attachmentData.documentTypeId.$error }">
                                                                        <select  v-model="attachmentData.documentTypeId" class="form-control">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in documentTypes" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.attachmentData.documentTypeId.$error && !$v.attachmentData.documentTypeId.required" class="text-danger">Please Select Document Type</label>
                                                                    <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.documentTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                                </div>  
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="form-group">
                                                                    <label class="float-left invisible">Document Type</label>
                                                                    <div class="attachments mb-2">
                                                                        <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="attachmentData.attachments">
                                                                        </b-form-file>
                                                                        <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                                            <i class="fa fa-check pr-2"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>                                                    
                                                            </div>
                                                        </div>                                            
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>

                                            <div class="row">
                                                <div class="col-lg-12 col-md-12">
                                                    <div v-show="filteredAttachmentList.length != 0" class="col-12 px-0">
                                                        <div class="">
                                                            <div>
                                                                <p class="my-1 border p-2" v-for="(attachment, index) in filteredAttachmentList">
                                                                    <span class="pr-3">{{index+1}}</span>
                                                                    <span class="pr-3">{{attachment.documentType}}</span>
                                                                    <i class="fa fa-file-image-o pr-2 ml-3 icon-doc" aria-hidden="true"></i>
                                                                    <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                    
                                                                    <a href="javascript:" class="float-right button-remove px-2 attachments-remove" title="Delete Attachment" @click="deleteFile(attachment.id)" data-toggle="modal" v-b-modal.deleteAttachment>
                                                                        <i class="fa fa-trash pr-2 action-link"></i>
                                                                    </a>

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup id="documentTypeModal" ref="typeLookupModal" entity="document_type" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteCandidate" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Candidate?" />
        <DeleteComponent id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
        <DeleteComponent id="deleteAttachment" :onYes="onYesAttachment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Document Attachment?" />
        <DialogComponent id="saveEmployeeConfirmationDialog" :onYes="onYesSaveAsEmployee" :returnParams="dialogBoxParam" title="Please Confirm" message="Are you sure to save the Candidate as Employee?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        AttachmentComponent,
        Editor,
        Multiselect,
        TypeLookup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                otherInfoEditMode: false,
                visible: false,
                docVisible: false,
                roleEditMode: false,
                showDropdownRole: false,
                roleVisible: false,
                techEditMode: false,
                showDropdownTech: false,
                techVisible: false,
                contactEditMode: false,
                documentEditMode: false,
                showResume: false,
            },
            resumeFilePath: '',
            resumeFileName: '',
            rightTabIndex: 0,
            showOverlay: false,
            showDetOverlay: false,
            isBusy: false,

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'candidate',
            typeEntity: 'candidate_type',

            typeLookupTitle: 'Document Type Settings',

            candidate: {
                id: '',
                candidateId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                candidateTypeId: '',
                userId: '',
                name: '',
                emailId: '',
                mobile: '',
                position: '',
                objective: '',
                keyStrength: '',
                qualification: '',
                experienceMonths: '',
                experienceYears: '',
                workShiftId: '',
                noticePeriodEnum: '',
                isEmployee: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            candidateDetail: {
                id: '',
                candidateId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                candidateTypeId: '',
                userId: '',
                name: '',
                position: '',
                emailId: '',
                mobile: '',
                objective: '',
                keyStrength: '',
                qualification: '',
                experienceMonths: '',
                experienceYears: '',
                workShiftId: '',
                noticePeriodEnum: '',
                isEmployee: '',
                statusId: '',
                status: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            contactDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntity: '',
                contactId: '',
                userId: '',
                contactName: '',
                firstName: '',
                middleName: '',
                lastName: '',
                profession: '',
                company: '',
                position: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                address2: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                timeZone: '',
                website: '',
                skypeId: '',
                facebookId: '',
                linkedinId: '',
                twitterId: '',
                gender: null,
                dateOfBirth: '',
                lastUpdatedDt: '',
                active: '',
                photo:'',
            },

            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: 'candidate',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''
            },

            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'technology_skill',
                refEntity: 'candidate',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technology: '',
                technologyData: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },

            attachmentInfo: {
                title: 'Upload Resume'
            },
            attachmentInfoView: {
                title: 'Resume'
            },
            attachments: [],

            attachmentData: {
                documentTypeId: '',
                attachments: [],
            },
            attachmentsList: [],

            attachmentDdList: [],

            candidateStatus: [],
            documentTypes: [],
            candidateTypes: [],
            roles: [],
            responsibilities: [],
            technologies: [],
            technologySkills: [],

            mainTableArray: [],
            candidateList: [],
            candidateFields: [
                {key: 'index',label: 'SN'},
                { key: 'candidateId', label: 'Cand. Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'qualification', label: 'Qualification', sortable: true, sortDirection: 'desc' },
                { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' },
                // { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            },
            dialogBoxParam: {
                id: 0,
            },
        }
    },
    computed: {
        filteredAttachmentList() {
            return this.attachmentsList.filter(data => data.documentTypeId);
        },
        attachmentDdFiltered() {
            return this.attachmentDdList.filter(data => !data.documentTypeId);
        },
        isDocFile() {
            let fileExt = this.getFileExtension(this.resumeFileName);
            return fileExt == 'doc' || fileExt == 'docx' || fileExt == 'xlx' || fileExt == 'xlsx';
        }
    },
    mounted() {
        this.getCandidates();
        this.lookupData();
    },
    validations: {
        candidate: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        candidateDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {
            technology: {
                required
            }
        },
        attachmentData: {
            documentTypeId: {
                required
            }
        }
    },
    methods: {
        getCandidates: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/candidate/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.candidateList && result.candidateList.length > 0) {
                        this.candidateList = result.candidateList;
                        // this.$nextTick(() => {
                        //     this.$refs.candidateTable.selectRow(0);
                        // })
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.candidateDetail.id, this.mainTableArray, 'candidateTable');
                        })
                        this.totalRows = this.candidateList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
                typeEntity: this.typeEntity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/candidate/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.candidateStatus = result.statusLookup;
                    this.documentTypes = result.documentTypeLookup;
                    this.technologies = result.technologyLookup;
                    this.candidateTypes = result.customTypeLookup;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        async saveCandidate() {
            this.candidate.experienceMonths = this.$refs.dayMonthYearControl.getMonths();
            this.candidate.experienceYears = this.$refs.dayMonthYearControl.getYears();
            this.$v.candidate.$touch();
            if (!this.$v.candidate.$invalid) {
                this.showOverlay = true;
                try {
                    let result = await axios.post(this.$store.getters.getAPIBasePath + '/resource/candidate/add', this.candidate)
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);

                    this.showOverlay = false;
                    if (result.data.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.data.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getCandidates();
                    }
                }
                catch {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }
            }
        },
        async updateCandidate() {
            this.candidateDetail.experienceMonths = this.$refs.dayMonthYearControlDet.getMonths();
            this.candidateDetail.experienceYears = this.$refs.dayMonthYearControlDet.getYears();
            this.showDetOverlay = true;
            try {
                let result = await axios.post(this.$store.getters.getAPIBasePath + '/resource/candidate/update', this.candidateDetail)
                await this.$refs.attachmentControlDet.saveAttachment(this.entity, this.candidateDetail.id);

                this.showDetOverlay = false;
                this.elements.editMode = false;
                this.elements.otherInfoEditMode = false;
                if (result.data.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getCandidates();
                    this.$refs.attachmentControlDet.getAttachmentData(this.entity, this.candidateDetail.id, this.vhextAccountId, null);
                    this.$refs.attachmentControlDetView.getAttachmentData(this.entity, this.candidateDetail.id, this.vhextAccountId, null);
                }
            }
            catch {
                this.showDetOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            }
        },
        viewCandidate: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/candidate/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let candidateDetail = result.candidate;
                    if (candidateDetail) {
                        this.candidateDetail.id = candidateDetail.id;
                        this.candidateDetail.candidateId = candidateDetail.candidateId;
                        this.candidateDetail.vhextAccountId = candidateDetail.vhextAccountId;
                        this.candidateDetail.candidateTypeId = candidateDetail.candidateTypeId;
                        this.candidateDetail.userId = candidateDetail.userId;
                        this.candidateDetail.name = candidateDetail.name;
                        this.candidateDetail.position = candidateDetail.position;
                        this.candidateDetail.emailId = candidateDetail.emailId;
                        this.candidateDetail.mobile = candidateDetail.mobile;
                        this.candidateDetail.objective = candidateDetail.objective;
                        this.candidateDetail.keyStrength = candidateDetail.keyStrength;
                        this.candidateDetail.qualification = candidateDetail.qualification;
                        this.candidateDetail.experienceMonths = candidateDetail.experienceMonths;
                        this.candidateDetail.experienceYears = candidateDetail.experienceYears;
                        this.candidateDetail.workShiftId = candidateDetail.workShiftId;
                        this.candidateDetail.noticePeriodEnum = candidateDetail.noticePeriodEnum;
                        this.candidateDetail.isEmployee = candidateDetail.isEmployee;
                        this.candidateDetail.statusId = candidateDetail.statusId;
                        this.candidateDetail.status = candidateDetail.status;
                        this.candidateDetail.active = candidateDetail.active;
                        this.candidateDetail.createdById = candidateDetail.createdById;
                        this.candidateDetail.createdBy = candidateDetail.createdBy;
                        this.candidateDetail.createdDt = candidateDetail.createdDt;
                        this.candidateDetail.lastUpdatedDt = candidateDetail.lastUpdatedDt;
                        this.$refs.dayMonthYearControlDet.setValueInputData(candidateDetail.experienceYears, candidateDetail.experienceMonths, null);
                    }
                })
                this.elements.editMode = false;
                this.elements.otherInfoEditMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewCandidate(this.candidateDetail.id);
        },
        deleteCandidate: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/candidate/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCandidates();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        getAttachmentsDd: function( refRecordId) {
            let data = {
                refEntity: 'candidate',
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
            } 
            this.attachmentDdList = [];
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentDdList = result.attachmentList;
                })
        },
        getRoleResponsibilities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: 'candidate',
                refRecordId: this.candidateDetail.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result) {
                        this.responsibilities = result.cmrRoleResponsibilityList;
                    }
                })
        },
        uploadPhoto: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: 'File format not supported',
                    position: 'topRight'
                });
                return false;
            }

            let formData = new FormData();
            formData.append('file', this.$refs.profile_photo.files[0]);
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('id', this.contactDetail.id);

            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    iziToast.success({
                        title: 'Success',
                        message: 'Profile photo uploaded Successfully',
                        position: 'topRight'
                    });
                    this.getContacts();           
                })
                .catch(error => {
                    this.showHRDetOverlay = false;
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Unable to update photo',
                        position: 'topRight'
                    });
                });
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.candidateDetail.id,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/view', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.contact) {
                        this.contactDetail = result.contact;
                    }
                    else {
                        this.clearContact();
                    }
                })
        },
        saveContact: function() {
            if (this.contactDetail.refRecordId == this.candidateDetail.id) {
                this.updateContact();
            } else {
                this.addContact();
            }
        },
        addContact: function() {
            this.contactDetail.refRecordId = this.candidateDetail.id;
            this.contactDetail.refEntity = this.entity;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contactDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        updateContact: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contactDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        clearContact: function() {
            this.elements.contactEditMode = false;
            this.contactDetail.id = '';
            this.contactDetail.refRecordId = '';
            this.contactDetail.refEntity = '';
            this.contactDetail.contactId = '';
            this.contactDetail.userId = '';
            this.contactDetail.contactName = '';
            this.contactDetail.firstName = '';
            this.contactDetail.middleName = '';
            this.contactDetail.lastName = '';
            this.contactDetail.profession = '';
            this.contactDetail.company = '';
            this.contactDetail.position = '';
            this.contactDetail.phone = '';
            this.contactDetail.officePhone = '';
            this.contactDetail.mobile = '';
            this.contactDetail.alternateMobile = '';
            this.contactDetail.emailId = '';
            this.contactDetail.alternateEmailId = '';
            this.contactDetail.address1 = '';
            this.contactDetail.address2 = '';
            this.contactDetail.streetName = '';
            this.contactDetail.city = '';
            this.contactDetail.state = '';
            this.contactDetail.countryId = '';
            this.contactDetail.pincode = '';
            this.contactDetail.timeZone = '';
            this.contactDetail.website = '';
            this.contactDetail.skypeId = '';
            this.contactDetail.facebookId = '';
            this.contactDetail.linkedinId = '';
            this.contactDetail.twitterId = '';
            this.contactDetail.gender = null;
            this.contactDetail.dateOfBirth = '';
            this.contactDetail.lastUpdatedDt = '';
            this.contactDetail.active = '';
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray = roleResponsibility.responsibility && roleResponsibility.responsibility !== null ? this.stringToArray(roleResponsibility.responsibility) : [];
                    })
            }
        },
        saveRoleResponsibility: function() {
            if (this.elements.showDropdownRole) {

                if (this.roleResponsibilityDetail.roleData.text != null) {
                    this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
                    this.validateRoleResponsibility();
                } else {
                    this.roleResponsibilityDetail.role = '';
                    this.validateRoleResponsibility();
                }
            } else {
                this.validateRoleResponsibility();
            }

        },
        validateRoleResponsibility: function() {
            this.$v.roleResponsibilityDetail.$touch();
            if (!this.$v.roleResponsibilityDetail.$invalid) {
                if (this.elements.roleEditMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.addRoleResponsibility();
                }
            }
        },
        addRoleResponsibility: function() {
            this.roleResponsibilityDetail.refRecordId = this.candidateDetail.id;
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateRoleResponsibility: function() {
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        editRole: function(data) {
            this.elements.showDropdownRole = false;
            this.elements.roleEditMode = true;
            this.elements.roleVisible = true;
            this.roleResponsibilityDetail.id = data.id;
            this.roleResponsibilityDetail.editId = data.id;
            this.roleResponsibilityDetail.role = data.role;
            this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
            this.roleResponsibilityDetail.refRecordId = data.refRecordId;
            this.roleResponsibilityDetail.refEntityId = data.refEntityId;
            this.roleResponsibilityDetail.projectId = data.projectId;
            this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
            this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibilityDetail.preparedById = data.preparedById;
        },
        clearRoleResponsibility: function() {
            this.elements.roleEditMode = false;
            this.elements.roleVisible = false;
            this.roleResponsibilityDetail.editId = '';
            this.roleResponsibilityDetail.role = '';

            if (this.roleResponsibilityDetail.roleData) {
                this.roleResponsibilityDetail.roleData = '';
            }
            this.roleResponsibilityDetail.responsibility = '';
            this.roleResponsibilityDetail.responsibilityArray = [];
            this.$v.roleResponsibilityDetail.$reset();
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYesRole: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: 'candidate',
                refRecordId: this.candidateDetail.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = technologySkill.skills && technologySkill.skills !== null ? this.stringToArray(technologySkill.skills) : [];
                    })
            }
        },
        saveTechnologySkill: function() {
            if (this.elements.showDropdownTech) {
                if (this.technologySkillDetail.technologyData.text != null) {
                    this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
                    this.validateTechnologySkill();
                } else {
                    this.technologySkillDetail.technology = '';
                    this.validateTechnologySkill();
                }
            } else {
                this.validateTechnologySkill();
            }
        },
        validateTechnologySkill: function() {
            this.$v.technologySkillDetail.$touch();
            if (!this.$v.technologySkillDetail.$invalid) {
                if (this.elements.techEditMode) {
                    this.updateTechnologySkill();
                } else {
                    this.addTechnologySkill();
                }
            }
        },
        addTechnologySkill: function() {
            this.technologySkillDetail.refRecordId = this.candidateDetail.id;
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateTechnologySkill: function() {
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYesTech: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editTech: function(data) {
            this.elements.showDropdownTech = false;
            this.elements.techEditMode = true;
            this.elements.techVisible = true;
            this.technologySkillDetail.id = data.id;
            this.technologySkillDetail.editId = data.id;
            this.technologySkillDetail.technologySkillId = data.technologySkillId;
            this.technologySkillDetail.technology = data.technology;
            this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
            this.technologySkillDetail.refRecordId = data.refRecordId;
            this.technologySkillDetail.refEntityId = data.refEntityId;
            this.technologySkillDetail.projectId = data.projectId;
            this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
            this.technologySkillDetail.preparedById = data.preparedById;
        },
        clearTechnologySkill: function() {
            this.elements.techEditMode = false;
            this.elements.techVisible = false;
            this.technologySkillDetail.editId = '';
            this.technologySkillDetail.technology = '';
            if (this.technologySkillDetail.technologyData.id) {
                this.technologySkillDetail.technologyData = '';
            }
            this.technologySkillDetail.skills = '';
            this.technologySkillDetail.skillsArray = [];
            this.$v.technologySkillDetail.$reset();
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.documentEditMode = false;
            this.elements.techEditMode = false;
            this.elements.roleEditMode = false;
            this.elements.contactEditMode = false;
            this.elements.otherInfoEditMode = false;
            this.elements.visible = false;
            this.elements.showResume = false;

            this.candidate.id = '';
            this.candidate.candidateId = '';
            this.candidate.candidateTypeId = '';
            this.candidate.userId = '';
            this.candidate.name = '';
            this.candidate.position = '';
            this.candidate.emailId = '';
            this.candidate.mobile = '';
            this.candidate.objective = '';
            this.candidate.keyStrength = '';
            this.candidate.qualification = '';
            this.candidate.experienceMonths = '';
            this.candidate.experienceYears = '';
            this.candidate.workShiftId = '';
            this.candidate.noticePeriodEnum = '';
            this.candidate.isEmployee = '';
            this.candidate.statusId = '';
            this.candidate.active = true;
            this.$refs.dayMonthYearControl.clearValueInputData();
            this.attachments = [];
            this.$refs.attachmentControl.clearAttachment();
            this.$v.candidate.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.showResume = false;
            this.rightTabIndex = 0;

            this.candidateDetail.id = data.id;
            this.candidateDetail.candidateId = data.candidateId;
            this.candidateDetail.vhextAccountId = data.vhextAccountId;
            this.candidateDetail.candidateTypeId = data.candidateTypeId;
            this.candidateDetail.userId = data.userId;
            this.candidateDetail.name = data.name;
            this.candidateDetail.position = data.position;
            this.candidateDetail.emailId = data.emailId;
            this.candidateDetail.mobile = data.mobile;
            this.candidateDetail.objective = data.objective;
            this.candidateDetail.keyStrength = data.keyStrength;
            this.candidateDetail.qualification = data.qualification;
            this.candidateDetail.experienceMonths = data.experienceMonths;
            this.candidateDetail.experienceYears = data.experienceYears;
            this.candidateDetail.workShiftId = data.workShiftId;
            this.candidateDetail.noticePeriodEnum = data.noticePeriodEnum;
            this.candidateDetail.isEmployee = data.isEmployee;
            this.candidateDetail.statusId = data.statusId;
            this.candidateDetail.status = data.status;
            this.candidateDetail.active = data.active;
            this.candidateDetail.createdById = data.createdById;
            this.candidateDetail.createdBy = data.createdBy;
            this.candidateDetail.createdDt = data.createdDt;
            this.candidateDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.$refs.dayMonthYearControlDet.setValueInputData(data.experienceYears, data.experienceMonths, null);
            this.$nextTick(() => {
                this.$refs.attachmentControlDet.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
            })
            this.$nextTick(() => {
                this.$refs.attachmentControlDetView.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
            })
            this.getRoleResponsibilities();
            this.getTechnologySkills();
            this.getContacts();
            this.getAttachment();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.documentEditMode = false;
                this.elements.techEditMode = false;
                this.elements.roleEditMode = false;
                this.elements.contactEditMode = false;
                this.elements.otherInfoEditMode = false;
                this.elements.editMode = false;
                this.elements.showResume = false;

                this.candidateDetail.id = data.id;
                this.candidateDetail.candidateId = data.candidateId;
                this.candidateDetail.vhextAccountId = data.vhextAccountId;
                this.candidateDetail.candidateTypeId = data.candidateTypeId;
                this.candidateDetail.userId = data.userId;
                this.candidateDetail.name = data.name;
                this.candidateDetail.position = data.position;
                this.candidateDetail.emailId = data.emailId;
                this.candidateDetail.mobile = data.mobile;
                this.candidateDetail.objective = data.objective;
                this.candidateDetail.keyStrength = data.keyStrength;
                this.candidateDetail.qualification = data.qualification;
                this.candidateDetail.experienceMonths = data.experienceMonths;
                this.candidateDetail.experienceYears = data.experienceYears;
                this.candidateDetail.workShiftId = data.workShiftId;
                this.candidateDetail.noticePeriodEnum = data.noticePeriodEnum;
                this.candidateDetail.isEmployee = data.isEmployee;
                this.candidateDetail.statusId = data.statusId;
                this.candidateDetail.status = data.status;
                this.candidateDetail.active = data.active;
                this.candidateDetail.createdById = data.createdById;
                this.candidateDetail.createdBy = data.createdBy;
                this.candidateDetail.createdDt = data.createdDt;
                this.candidateDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.$refs.dayMonthYearControlDet.setValueInputData(data.experienceYears, data.experienceMonths, null);
                this.$nextTick(() => {
                    this.$refs.attachmentControlDet.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
                })
                this.$nextTick(() => {
                    this.$refs.attachmentControlDetView.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
                })
                this.getRoleResponsibilities();
                this.getTechnologySkills();
                this.getContacts();
                this.getAttachment();
            }
        },
        saveAttachment: function() {
            this.$v.attachmentData.$touch();
            if(!this.$v.attachmentData.$invalid){
                if (this.attachmentData.attachments.length == 0) {
                    iziToast.info({
                        message: 'Please choose file.',
                        position: 'topRight'
                    });
                    return;
                } else {
                    let formData = new FormData();    
                    formData.append('files', this.attachmentData.attachments);
                    formData.append('documentTypeIds', this.attachmentData.documentTypeId);
                    formData.append('vhextAccountId', this.vhextAccountId);
                    formData.append('attachedById', this.userId);
                    formData.append('refEntity', this.entity);
                    formData.append('refRecordId', this.candidateDetail.id);
                    this.showDetOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.showDetOverlay = false;
                            let result = response.data;
                            if (result.status == "OK") {
                                iziToast.success({
                                    title: 'Success',
                                    message: result.message,
                                    position: 'topRight'
                                });
                                this.attachmentData.attachments = [];
                                this.attachmentData.documentTypeId = '';
                                this.$v.attachmentData.$reset();
                                this.elements.docVisible = false;
                                this.getAttachment();
                            }
                            console.log('Attachment uploaded');
                        })
                        .catch(error => {
                            this.showDetOverlay = false;
                            console.log('Error Attachment uploaded');
                        });
            
                }
            }
            
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.candidateDetail.id
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if(result){
                        this.attachmentsList = result.attachmentList;
                    }
                })
        },
        deleteFile: function(id) {
            this.deleteParam.id = id;
        },
        onYesAttachment: function(id) {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    console.log(error);
                });
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.candidate.entity = this.entity;
                this.candidate.id = item.id;
                this.candidate.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.candidate.entity = this.entity;
                this.candidate.id = item.id;
                this.candidate.active = 1;

                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.candidate)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.candidate.active = true;
                    this.getCandidates();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        attachmentClick(data) {
            this.elements.showResume = true;
            this.resumeFilePath = data.attachmentPath;
            this.resumeFileName = data.fileName;
        },
        vmxValueInput:function(data){
            this.$refs.dayMonthYearControl.setValueInputData(data.year, data.month, data.day);
        },
        vmxValueInputDet:function(data){
            this.$refs.dayMonthYearControlDet.setValueInputData(data.year, data.month, data.day);
        },
        saveEmployeeConfirmation: function(data) {
            this.$nextTick(() => {
                this.selectBTableRowById(data.id, this.mainTableArray, 'candidateTable');
            })
            this.dialogBoxParam.id = data.id;
        },
        onYesSaveAsEmployee(id) {
            this.saveCandidateAsEmployee();
        },
        saveCandidateAsEmployee: function() {
            this.showOverlay = true;
            this.candidateDetail.phone = this.contactDetail.phone;
            this.candidateDetail.createdById = this.userId;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/candidate/saveasemployee', this.candidateDetail)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCandidates();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
    }
}
</script>