<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <CalendarEvent />
            </div>
        </div>
    </div>
</template>
<script>
import CalendarEvent from '@/views/communication/CalendarEvent.vue';
export default {
    components: {
        CalendarEvent
    }
}
</script>