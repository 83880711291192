<template>
    <div class="userActivationPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered title="User Activation" body-class="py-3" footer-class="py-2 px-3" header-class="py-2 px-3" no-enforce-focus @hide="closeDialog" id="userActivationPopup" ref="userActivationPopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <!-- <p class="text-center fs-16">Are you sure to activate this user?</p> -->
                    <b-row class="mb-1">
                        <b-col cols="4">User Account Name</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.userActivation.memberName.$error }">
                                <b-form-input aria-label="memberName" v-model="userActivation.memberName"></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.userActivation.memberName.$error && !$v.userActivation.memberName.memberName" class="text-danger">Account Name must have at least {{$v.userActivation.memberName.$params.minLength.min}} letters.</label>
                            <label v-if="$v.userActivation.memberName.$error && !$v.userActivation.memberName.required" class="text-danger">Please enter Account Name</label>
                            <label v-if="$v.userActivation.memberName.$error && !$v.userActivation.memberName.maxLength" class="text-danger">Account Name must not exceed {{$v.userActivation.memberName.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4">Login Email</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.userActivation.emailId.$error }">
                                <input type="text" class="form-control" v-model='userActivation.emailId'>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.userActivation.emailId.$error && !$v.userActivation.emailId.email" class="text-danger">Enter Valid Email Id</label>
                            <label v-if="$v.userActivation.emailId.$error && !$v.userActivation.emailId.required" class="text-danger">Please enter Email Id</label>
                            <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="createUserAccount" class="float-right mr-2">
                        <b-spinner  v-if="showOverlay"  small label="Small Spinner" class="mr-2"></b-spinner>Create Account
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
    props: {
        projectTeamData: Object,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                alreadyExists: false,
            },
            userActivation: {
                id: '',
                projectTeamId: '',
                userId:'',
                userTypeId:'',
                userType:'',
                userTypeCode:'',
                memberTypeId:'',
                memberTypeCode:'TMB',
                memberId:'',
                memberName: '',
                member: '',
                humanResourceId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                roleResponsibility: '',
                technologySkill: '',
                preparedBy: '',
                qualification: '',
                experienceYears: '',
                experienceMonths: '',
                requiredFrom: '',
                requiredTill: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false
            }
        }
    },
    mounted() {
        this.setUserData();
    },
    validations: {
        userActivation: {
            memberName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            }
        }
    },
    methods: {
        setUserData: function() {
            console.log(this.projectTeamData)
            this.userActivation.projectId = this.projectTeamData.projectId;
            this.userActivation.projectTeamId = this.projectTeamData.projectTeamId;
            this.userActivation.memberName = this.projectTeamData.memberName;
            this.userActivation.projectMemberId = this.projectTeamData.projectMemberId;
            this.userActivation.humanResourceId = this.projectTeamData.humanResourceId;
            this.userActivation.humanResource = this.projectTeamData.humanResource;
            this.userActivation.emailId = this.projectTeamData.emailId;
            this.userActivation.isActive = this.projectTeamData.isActive;
            this.userActivation.phone = this.projectTeamData.phone;
            this.userActivation.position = this.projectTeamData.position;
            this.userActivation.roleResponsibility = this.projectTeamData.roleResponsibility;
            this.userActivation.technologySkill = this.projectTeamData.technologySkill;
            this.userActivation.preparedBy = this.projectTeamData.preparedBy;
            this.userActivation.qualification = this.projectTeamData.qualification;
            this.userActivation.experienceYears = this.projectTeamData.experienceYears;
            this.userActivation.experienceMonths = this.projectTeamData.experienceMonths;
            this.userActivation.requiredFrom = this.projectTeamData.requiredFrom;
            this.userActivation.requiredTill = this.projectTeamData.requiredTill;
            this.userActivation.scopeTypeId = this.projectTeamData.scopeTypeId;
            this.userActivation.status = this.projectTeamData.status;
            this.userActivation.statusId = this.projectTeamData.statusId;
            this.userActivation.type = this.projectTeamData.type;
            this.userActivation.userName = this.projectTeamData.userName;
            this.userActivation.entityRecordMasterId = this.projectTeamData.entityRecordMasterId;
            this.userActivation.vhextAccountId = this.projectTeamData.vhextAccountId;
            this.userActivation.id = this.projectTeamData.id;
            this.userActivation.loginName = this.projectTeamData.loginName;
            this.userActivation.userName = this.projectTeamData.userName;
            this.userActivation.userType = this.projectTeamData.userType;
        },
        createUserAccount: function() {
            this.userActivation.userTypeCode = 'TMB';
            this.userActivation.userType = 'Team Member';
            this.userActivation.userName = this.userActivation.memberName;
            this.$v.userActivation.$touch();
            if (!this.$v.userActivation.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.userActivation)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            this.showOverlay = false;
                            this.$bvModal.hide('userActivationPopup');
                            this.activationMailSentToast(this.userActivation.emailId);
                        } else if (result.status == 'OK') {
                            this.showOverlay = false;
                            this.elements.alreadyExists = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.$emit('refreshData');
        },
    }
}
</script>