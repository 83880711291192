<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <FinTxnBoard />
            </div>
        </div>
    </div>
</template>
<script>
import FinTxnBoard from '@/views/finance/FinTxnBoard.vue';
export default {
    components: {
        FinTxnBoard
    }
}
</script>