<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-body p-1">
                            <div class="form-row">
                                <div class="col-sm-2 mb-1">
                                    <div class="card py-2 h-100">

                                        <template class="row no-gutters pt-1">
                                          <div class="">
                                            <input type="text" v-model="searchQuery" @input="filterResults" class="col-12" placeholder="Enter Employee Name">
                                            <ul v-if="filteredResults.length">
                                              <li v-for="(empData, index) in filteredResults" :key="index" @click="selectResult(empData)">
                                                <div class="row no-gutters align-items-center">
                                                    <div class="col-auto p-1">
                                                        <b-avatar v-if="empData.photo" class="mr-2" :src="empData.photo"></b-avatar>
                                                        <b-avatar v-else class="mr-2"></b-avatar>
                                                    </div>
                                                    <div class="col">
                                                        <div class="mr-auto">{{ empData.value }}</div>
                                                        <div class="mr-auto text-muted fs-11">{{ empData.designation }}</div>
                                                    </div>
                                                </div>                                                
                                              </li>
                                              <div class="separator mb-1"></div>
                                            </ul>
                                          </div>
                                        </template>                                        

                                        <div class="row no-gutters text-center pt-1">

                                            <div class="col-12" style="width: 50px; height: auto;">
                                                <img v-if="humanResourceDetails.photo" class="mr-2" :src="humanResourceDetails.photo" width="100" height="100" style="border-radius: 5px">
                                            </div>
                                            <div class="col mt-1">
                                                <div class="mr-auto">{{ humanResourceDetails.name }} , {{humanResourceDetails.qualification}}</div>
                                                <div class="mr-auto text-muted">{{ humanResourceDetails.designation }}</div>
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                <div class="col-sm-4 mb-1">
                                    <div class="card p-1 h-100">
                                        <p class="group-header-title text-left pl-1 mb-1">Employment Info  [{{ humanResourceDetails.employeeCode }}]
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Employee Status">{{ humanResourceDetails.employeeStatus }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-left">
                                            <div class="ml-2">
                                                <b-row class="mt-1">
                                                    <b-col cols="3" class="caption-title">Designation</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 table-cw-100 mb-0" :title="humanResourceDetails.designation">{{humanResourceDetails.designation}}</label>
                                                    </b-col>
                                                    <b-col cols="3" class="caption-title">Job Category</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 table-cw-100 mb-0" :title="humanResourceDetails.jobCategory">{{humanResourceDetails.jobCategory}}</label>
                                                    </b-col>
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="3" class="caption-title">Department</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 table-cw-100 mb-0">{{humanResourceDetails.department}}</label>
                                                    </b-col>                                                    
                                                    <b-col cols="3" class="caption-title">Joining Date</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.joiningDate | formatDate}}</label>
                                                    </b-col>                                                    
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="3" class="caption-title">Experience</b-col>
                                                    <b-col cols="3">
                                                        <label v-if="humanResourceDetails.experienceYears" class="fs-12 mb-0">{{humanResourceDetails.experienceYears}} years</label>
                                                        <label v-if="humanResourceDetails.experienceMonths" class="fs-12 mb-0"> {{humanResourceDetails.experienceMonths}} months</label>
                                                    </b-col>
                                                    <b-col cols="3" class="caption-title">Work Type</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.workType}}</label>
                                                    </b-col>                                                    

                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="3" class="caption-title">Salary</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.salary}}</label>
                                                    </b-col>
                                                    <b-col cols="3" class="caption-title">Work Shift</b-col>
                                                    <b-col cols="3">
                                                        <label class="fs-12 table-cw-100 mb-0" :title="humanResourceDetails.workShift">{{humanResourceDetails.workShift}}</label>
                                                    </b-col>                                                    

                                                </b-row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-2 mb-1">
                                    <div class="card p-1 h-100">
                                        <p class="group-header-title text-left pl-1 mb-1">Personal Info
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-left">
                                            <div class="ml-2">
                                                <b-row class="mt-1">
                                                    <b-col cols="6" class="caption-title">Date of Birth</b-col>
                                                    <b-col cols="6">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.dateOfBirth | formatDate}}</label>
                                                    </b-col>
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="6" class="caption-title">Gender</b-col>
                                                    <b-col cols="6">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.gender}}</label>
                                                    </b-col>                                                    
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-2 mb-1">
                                    <div class="card p-1 h-100">
                                        <p class="group-header-title text-left pl-1 mb-1">User Account
                                            <span v-if="humanResourceDetails.userName != null && humanResourceDetails.userName !=''" :title="humanResourceDetails.active==true ? 'Active User' : 'User Not Activated'" class="badge badge-light float-right pointer py-1 px-2"><i class="fa fa-user fa-xs" :class="{ 'text-success' : humanResourceDetails.active==true }" aria-hidden="true"></i></span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-left">
                                            <div class="ml-2">
                                                <b-row class="mt-1">
                                                    <b-col cols="6" class="caption-title">Account Name</b-col>
                                                    <b-col cols="6">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.userName}}</label>
                                                    </b-col>
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="6" class="caption-title">User Type</b-col>
                                                    <b-col cols="6">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.userType}}</label>
                                                    </b-col>                                                    
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="6" class="caption-title">User Role</b-col>
                                                    <b-col cols="6">
                                                        <label class="fs-12 mb-0">{{humanResourceDetails.userRoles}}</label>
                                                    </b-col>                                                    
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row>
                                                    <b-col cols="6" class="caption-title">Login Email Id</b-col>
                                                    <b-col cols="6">
                                                        <label class="fs-12 table-cw-100 mb-0" :title="humanResourceDetails.loginName">{{humanResourceDetails.loginName}}</label>
                                                    </b-col>                                                    
                                                </b-row>                                                                         
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                <div class="col-sm-2 mb-1">
                                    <div class="card p-1 h-100">
                                        <p class="group-header-title text-left pl-1 mb-1">Contact Info
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-left">
                                            <div class="ml-2">
                                                <b-row v-if="humanResourceDetails.mobile" class="mb-1 mt-1">
                                                    <!-- <b-col cols="6" class="caption-title">Mobile</b-col> -->
                                                    <b-col cols="12">
                                                        <label class="fs-12 mb-0"><i class="fa fa-mobile font-weight-bold action-link mr-2" aria-hidden="true"></i>{{humanResourceDetails.mobile}}</label>
                                                    </b-col>
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row v-if="humanResourceDetails.emailId" class="mb-1">
                                                    <b-col cols="12">
                                                        <label class="fs-12 mb-0"><i class="fa fa-envelope-o font-weight-bold action-link mr-2" aria-hidden="true"></i>{{humanResourceDetails.emailId}}</label>
                                                    </b-col>                                                    
                                                </b-row>
                                                <div class="separator mb-2"></div>
                                                <b-row class="mb-1">
                                                    <b-col cols="12" class="caption-title">Address</b-col>
                                                    <b-col cols="12">
                                                        <p v-if="contactDetails.address1 != ''" class="mb-0">
                                                            <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                                            <span v-if="contactDetails.address1 != ''">{{contactDetails.address1}}</span>
                                                            <span v-if="contactDetails.address2 != ''">, {{contactDetails.address2}}</span>
                                                            <span v-if="contactDetails.city != ''">, {{contactDetails.city}}</span>
                                                            <span v-if="contactDetails.state != ''">, {{contactDetails.state}}</span>
                                                            <span v-if="contactDetails.country != ''">, {{contactDetails.country}}</span>
                                                            <span v-if="contactDetails.pincode != ''">, {{contactDetails.pincode}}</span>
                                                        </p>
                                                    </b-col>                                                    
                                                </b-row>                                                                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="group-header-title text-left pl-1 mb-1">Roles & Responsibilities
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>

                                        <div class="card mt-1" v-for="responsibility in responsibilities">
                                            <div class="card-body p-2" v-if="responsibility.id">
                                                <div class="row" >
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <h6 class="caption-title">{{ responsibility.role }}</h6>
                                                            <p> {{ responsibility.responsibility ? (responsibility.responsibility).replaceAll(',', ', ') : '' }} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="group-header-title text-left pl-1 mb-1">Technology & Skills
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>

                                        <div class="card mt-1" v-for="technologySkill in technologySkills">
                                            <div class="card-body p-2" v-if="technologySkill.id">
                                                <div class="row" >
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <h6 class="caption-title">{{ technologySkill.technology }}</h6>
                                                            <p>{{ technologySkill.skills ? (technologySkill.skills).replaceAll(',', ', ') : '' }} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="group-header-title text-left pl-1 mb-1">Projects
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Project Count">{{ projectCount }}</span>
                                        </p>       
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-0">
                                            <HorizontalBarChart v-if="projectCount" :chartdata="userProjectData" :options="barChartOptions" class="d-block" :key="barChartKey"/>
                                            <div v-else class="my-4 py-2 text-center">
                                                No Project Found
                                            </div>     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-2">
                        <div class="col-8">
                            <div class="card">
                                <div class="card-body px-0 pb-0 pt-1" >
                                    <div class="row no-gutters">
                                        <!-- <div class="col-md-4 col-xl-4 mb-0"> -->
                                        <p class="group-header-title text-left pl-1 mb-0 pt-2">Task Summary
                                        </p>
                                    </div>
                                    <div class="separator mb-1 mx-1 pt-1"></div>
                                    <div class="col-md-12 col-xl-12">
                                        <div class=" p-1 mb-3">
                                            <div class="col-sm-12 text-center my-auto mx-auto">
                                                <div class="row">
                                                    <label class="caption-title col-2 col-md-2 my-auto pl-0 float-left">Assigned To Me</label>

                                                    <div v-for="status in userTaskByStatusData" class="pr-1 my-1 text-center float-right">
                                                        <div class="card b-radius-5">
                                                            <div class="card-body px-2 py-2">
                                                                <div class="form-group mb-0">

                                                                    <label class="caption-title my-auto" for="title">{{ status.status }} 
                                                                        <b-badge class="ml-1" title="Tasks">{{status.taskCount}}</b-badge>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator my-1 mx-1"></div>
                                    <div class="col-md-12 col-xl-12">
                                        <div class=" p-1 mb-3">
                                            <div class="col-sm-12 text-center my-auto mx-auto">
                                                <div class="row">
                                                    <label class="caption-title col-2 col-md-2 my-auto pl-0 float-left">Assigned By Me</label>            
                                                    <div v-for="status in userTaskAssignedByStatusData" class="mb-0 pr-1 text-center float-right">
                                                        <div class="card b-radius-5">
                                                            <div class="card-body px-2 py-2">
                                                                <div class="form-group mb-0">
                                                                    <label class="caption-title my-auto" for="title">{{ status.status }} 
                                                                        <b-badge class="ml-2" title="Tasks">{{status.taskCount}}</b-badge>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card">
                                <div class="card-body px-0 pb-0 pt-1" >
                                    <div class="row no-gutters">
                                        <!-- <div class="col-md-4 col-xl-4 mb-0"> -->
                                        <p class="group-header-title text-left pl-1 mb-0 pt-2">Attendance Summ.
                                        </p>
                                        <div class="col-md-4 col-xl-4 pt-2 pr-3 text-right">
                                            <input type="date" v-model="startDate" @change="getEmployeeAttendanceSummaryByDate" class="">
                                        </div>                                        
                                        <div class="col-md-4 col-xl-4 pt-2 pr-3 text-right">
                                            <input type="date" v-model="endDate" @change="getEmployeeAttendanceSummaryByDate" class="">
                                        </div>
                                    </div>
                                    <div class="separator mb-1 mx-1 pt-1"></div>
                                    <div class="col-md-12 col-xl-12">
                                        <div class=" p-1 mb-3">
                                            <div class="col-sm-12 text-center my-auto mx-auto">
                                                <div class="row">
                                                    <div class="col-3 card p-1">
                                                        <div class="text-primary mb-0">Workdays</div>
                                                        <div class="">{{ employeeAttendanceSummary.workingDays }}</div>
                                                    </div>
                                                    <div class="col-3 card p-1">
                                                        <div class="text-success mb-0">Present</div>
                                                        <div class="">{{ employeeAttendanceSummary.totalPresent }}</div>
                                                    </div>
                                                    <div class="col-3 card p-1">
                                                        <div class="text-warning mb-0">Half Day</div>
                                                        <div class="">{{ employeeAttendanceSummary.totalHalfDayLeave }}</div>
                                                    </div>
                                                    <div class="col-3 card p-1">
                                                        <div class="text-danger mb-0">Leave</div>
                                                        <div class="">{{ employeeAttendanceSummary.totalFullDayLeave }}</div>
                                                    </div>
                                                </div>
                                                <!-- <hr class="m-1"> -->
                                                <div class="mb-0 mt-1 py-1">Attendees - <span title="Performance">{{empAttendeesPercentage}} %</span></div>
                                                <b-progress class="" height="10px" :value="empAttendeesPercentage" :variant="dailyAttendanceProgressVariant(empAttendeesPercentage)"></b-progress>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="group-header-title text-left pl-1 mb-1">Employee Attendance Performance</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empAttendanceSummaryByMonth" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Utilization - Task</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="empUtilizationByDate" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Productive Time</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="empProductiveTimeByDate" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>

<!--                                         <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee Utilization By Dept.</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="employeeUtilizationData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

<!--                     <div class="form-row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Department</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="employeeByDepartmentData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>    
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Job Category</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="employeeByJobCategoryData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Employee By Joining Month</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <BarChart :chartdata="empByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>                     -->

<!--                     <div class="form-row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Salary By Month</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="salaryByMonthData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xl-3">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Asset Summary</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="assetSummaryByTypeData" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            humanResourceId: 0,
            employeeUserId: 0,

            startDate: new Date(new Date().setDate(1)).toISOString().split('T')[0],
            endDate: this.getDateValue(new Date()),

            selectedDate: this.getDateValue(new Date()),

            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            resourceCount: 0,
            employeeCount: 0,
            userCount: 0,
            clientCount: 0,
            leaveCount: 0,

            humanResourceDetails: {
                id: '',
                vhextAccountId: '',
                projectId: '',
                entity: '',
                userId: '',
                userName: '',
                loginName: '',
                userType: '',
                active: '',
                humanResourceId: '',                
                humanResourceTypeId: '',
                subContractId: '',
                name: '',
                photo: '',
                position:'',
                gender: '',
                dateOfBirth:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate:'',
                workShiftId: '',
                jobCategoryId: '',
                jobCategory: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                empId: '',
                employeeId: '',
                employeeCode: '',
                reportingToId: '',
                reportingTo: '',
                employmentChangeById: '',
                employmentChangeBy: '',
                wefDate: '',
                endDate: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                hourlySalary: null,
                employeeStatusId: '',
                employeeStatus: '',
                active: true,
                isEmployee: false,
            },            

            contactDetails: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntity: '',
                contactId: '',
                userId: '',
                contactName: '',
                firstName: '',
                middleName: '',
                lastName: '',
                profession: '',
                company: '',
                position: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                address2: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                timeZone: '',
                website: '',
                skypeId: '',
                facebookId: '',
                linkedinId: '',
                twitterId: '',
                gender: null,
                dateOfBirth: '',
                lastUpdatedDt: '',
                active: '',
                photo:'',
            },

            searchQuery: '',
            employees: [],
            // results: ['Apple', 'Banana', 'Orange', 'Mango'],
            filteredResults: [],            

            
            responsibilities: [],
            technologySkills: [],

            empByMonthData: {},
            employeeUtilizationData: {},
            salaryByMonthData: {},
            assetSummaryByTypeData: {},
            employeeByDepartmentData: {},
            employeeByJobCategoryData: {},
            resourcePoolData: {},
            employeeByDepartment: {},
            employeeAttendanceSummary: {},
            empAttendanceSummaryByMonth: {},
            empUtilizationByDate: {},
            empProductiveTimeByDate: {},
            workTypeChartData: {},
            genderDistributionData: {},
            ageDistributionData: {},
            employeeLeaveByTypeData: {},
            userProjectData: {},
            clientByTypeData: {},
            userByTypeData: {},
            userTaskByStatusData: {},
            userTaskAssignedByStatusData: {},

            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        // stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                },
                // tooltip: {
                //     callbacks: {
                //         label: function(context) {
                //             const item = result.userProject[context.dataIndex];
                //             return `Project: ${item.projectShortName}\nStatus: ${item.status}\nID: ${item.id}`;
                //         }
                //     }
                // }                
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        empAttendeesPercentage() {
            let totalCount = this.employeeAttendanceSummary.workingDays;
            let empTotalPresent = this.employeeAttendanceSummary.totalPresent;
            let empTotalFullDayLeave = this.employeeAttendanceSummary.totalFullDayLeave;
            let empTotalHalfDayLeave = this.employeeAttendanceSummary.totalFirstHalfLeave + this.employeeAttendanceSummary.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((totalCount-((empTotalHalfDayLeave/2)+empTotalFullDayLeave))/totalCount) * 100).toFixed(1) : 0);
        },        
        myStyles() {
            return {
                height: '80px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        myStyles2() {
            return {
                height: '72px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
    },
    mounted() {
        this.lookupData();
    },
    validations: {
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/employeedashboard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.employees = result.employeeLookup;
                    console.log("emp",this.employees);
                })
        },

        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                humanResourceId: this.humanResourceId,
                userId: this.employeeUserId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/empdata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log("EMP",result);

                    this.humanResourceDetails = result.humanResourceView;
                    this.responsibilities = result.empRoleResponsibilityList;
                    this.technologySkills = result.empTechnologySkillList;
                    this.contactDetails = result.contactView;

                    this.userTaskByStatusData = result.userTaskByStatus;
                    this.userTaskAssignedByStatusData = result.userTaskAssignedByStatus;

                    this.getEmployeeAttendanceSummaryByDate();

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.empAttendanceSummaryByMonth != null) {

                        // Sort combined array by date
                        result.empAttendanceSummaryByMonth.sort((a, b) => new Date(a.workingMonth) - new Date(b.workingMonth));

                        this.empAttendanceSummaryByMonth = {
                            labels: result.empAttendanceSummaryByMonth.map(item => item.workingMonth),
                            datasets: [{
                                    label: 'Attendance Performance [%] ',
                                    data: result.empAttendanceSummaryByMonth.map(item => item.performance),
                                    backgroundColor: this.getColors(result.empAttendanceSummaryByMonth.map(item => item.performance)),
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }

                    if (result.empUtilizationByDate != null) {
                        this.empUtilizationByDate = {
                            labels: result.empUtilizationByDate.map(item => item.workingDate),
                            datasets: [{
                                    label: 'Employee Utilization [%] ',
                                    data: result.empUtilizationByDate.map(item => item.performance),
                                    backgroundColor: this.getEUPColors(result.empUtilizationByDate.map(item => item.performance)),
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }

                    if (result.empProductiveTimeByDate != null) {
                        this.empProductiveTimeByDate = {
                            labels: result.empProductiveTimeByDate.map(item => item.workingDate),
                            datasets: [{
                                    label: 'Employee Productive Time [%] ',
                                    data: result.empProductiveTimeByDate.map(item => item.performance),
                                    backgroundColor: this.getEUPColors(result.empProductiveTimeByDate.map(item => item.performance)),
                                    // backgroundColor: '#2a93d5',

                                }
                            ]
                        }
                    }
                    this.employeeLeaveByTypeData = {
                        labels: result.employeeLeaveByType.map(item => item.leaveType),
                        datasets: [{
                            backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                            data: result.employeeLeaveByType.map(item => {
                                this.leaveCount += Number(item.totalLeave);
                                return item.totalLeave;
                            })
                        }]
                    }
                    if (result.userProject != null) {
                        this.userProjectData = {
                            labels: result.userProject.map(item => item.projectShortName), // Use project short names for chart labels
                            datasets: [{
                                label: 'Project In Progress',
                                data: result.userProject.map(item => item.id), // Use status values for chart data
                                backgroundColor: this.getStatusColors(result.userProject.map(item => item.id)), 

                            }]
                        };
                        this.projectCount = result.userProject.length;                        
                    }


                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getEmployeeAttendanceSummaryByDate: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                humanResourceId: this.humanResourceId,
                startDate: this.startDate,
                endDate: this.endDate,
                // dateValue: this.selectedDate,
            }
            console.log("Data",data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/attendance/summary', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.employeeAttendanceSummary.length>0) {
                        this.employeeAttendanceSummary = result.employeeAttendanceSummary[0];
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        filterResults() {
            this.filteredResults = this.employees.filter(result =>
            result.value.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        },
        selectResult(result) {
            this.searchQuery = result.value;
            this.filteredResults = [];
            this.humanResourceId = result.humanResourceId;
            this.employeeUserId = result.userId;
            this.getDashboardData();
        },     
        getColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value > 90 ? 'green' :
           (value >= 75 && value <= 90) ? 'grey' : 'red'));
        },      
        getEUPColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value >= 90 ? 'green' :
           (value >= 75 && value < 90) ? 'grey' : 'red'));
        },        
        getStatusColors(dataArray) {
          // Customize this function based on your condition
          return dataArray.map(value => (value == 1 ? 'grey' : 'green'));
        },        

        abbrevName: function (str1) {
            var split_names = str1.trim().split(" ");
            if (split_names.length > 1) {
                return (split_names[0] + " " + split_names[1].charAt(0) + ".");
            }
            return split_names[0];
        }        
    }
}
</script>