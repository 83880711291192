<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Scope List</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Scope" :to="{ name: 'addscope'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-pencil pr-2"></i>Edit</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-eye pr-2"></i>View</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="scopes" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <router-link title="View Scope" :to="{ name: 'viewscope', params: { scopeid: data.item.id }}">{{data.value}}</router-link>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'Draft'" variant="light">{{data.value}}</b-badge>
                                      <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Reopened'" variant="warning">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                    </div>
                                </template>                                
                                <template v-slot:cell(actions)="data">
                                    <router-link title="View Scope" :to="{ name: 'viewscope', params: { scopeid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                    <router-link title="Edit Scope" :to="{ name: 'editscope', params: { scopeid: data.item.id }}"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></router-link>
                                    <a href="javascript:" title="Delete" @click="deleteScope(data.item.id)" data-toggle="modal" data-target="#deleteScope"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Scopes found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="scopes.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
        <Dialog id="deleteScope" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Scope?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    data() {
        return {
            scopes: [],
            fields: [
                { key: 'scopeId', label: 'Scope Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Scope Type', sortable: true, sortDirection: 'desc' },
                { key: 'deliverables', label: 'Deliverables', sortable: true, sortDirection: 'desc' },
                { key: 'userName', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },                
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getScopes();
    },
    methods: {
        getScopes: function() {

            axios.post(this.$store.getters.getAPIBasePath + '/scope/list')
                .then((response) => {
                    let result = response.data;
                    this.scopes = result.scopelist;
                    this.totalRows = this.scopes.length;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        deleteScope: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/scope/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getScopes();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}
</script>