import Asset from '@/views/asset/Asset.vue'
import DefectDbMaster from '@/views/asset/DefectDbMaster.vue'
import DefectDbMasterList from '@/views/asset/DefectDbMasterList.vue'
import DefectDb from '@/views/asset/DefectDb.vue'
import ServiceUtil from '@/views/asset/ServiceUtil.vue'

export default [
    {
        path: '/landing/ast',
        name: 'lanast',
        component: Asset,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/ddbmas',
        name: 'ddbmas',
        component: DefectDbMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/ddbmas/list',
        name: 'ddbmaslist',
        component: DefectDbMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/defectdb/:ermId',
        name: 'defectdb',
        component: DefectDb,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/landing/sru',
        name: 'lansru',
        component: ServiceUtil,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
];