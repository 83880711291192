<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Sub Contractor</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <Client />
            </div>
        </div>
    </div>
</template>
<script>
import Client from '@/components/common/ClientComponent.vue';
export default {
    components: {
        Client
    }
}
</script>