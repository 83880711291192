<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">User Acceptance Test Case</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                            <span v-if="elements.viewMode">View</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="title">Test Case Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.uatTestCase.title.$error }">
                                                                <input type="text" class="form-control" v-model="uatTestCase.title" id="title" :class="{ 'is-invalid': $v.uatTestCase.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.uatTestCase.title.$error && !$v.uatTestCase.title.minLength" class="text-danger">Title must have at least {{$v.uatTestCase.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.uatTestCase.title.$error && !$v.uatTestCase.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.uatTestCase.title.$error && !$v.uatTestCase.title.maxLength" class="text-danger">Title must not exceed {{$v.uatTestCase.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="budgetCategoryId">Prepared By</label>
                                                            <select v-model="uatTestCase.preparedById" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="budgetCategoryId">UAT Type</label>
                                                            <select v-model="uatTestCase.uatTestTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in uatTestCaseTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="description">Test Case Description</label>
                                                            <textarea v-model="uatTestCase.description" rows="4" class="form-control" id="description" maxlength="1000" placeholder="Enter Description"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="expectedResult">Expected Result</label>
                                                            <textarea v-model="uatTestCase.expectedResult" rows="4" class="form-control" id="expectedResult" maxlength="1000" placeholder="Enter Expected Result"></textarea>
                                                        </div>
                                                    </div>
                                                    <FunctionalReferencePopup v-if="elements.editMode" ref="referenceModal" :entity="this.entity" :erdId="uatTestCase.id" />
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode" id="viewStatement">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <label for="title">Title</label>
                                                        <b-input-group :prepend="testCaseDetail.uatTestCaseId" class="mb-2">
                                                            <b-form-input aria-label="First name" disabled v-model="testCaseDetail.title"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="deliverables">Test Type</label>
                                                            <input type="text" class="form-control" id="deliverables" disabled v-model="testCaseDetail.type">
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-6">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <textarea rows="4" class="form-control" id="description" disabled v-model="testCaseDetail.description"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="expectedResult">Expected Result</label>
                                                            <textarea class="form-control" rows="4" id="expectedResult" disabled v-model="testCaseDetail.expectedResult"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="projectType">Prepared By</label>
                                                            <input type="text" class="form-control" id="userName" disabled v-model="testCaseDetail.preparedBy">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="testCaseDetail.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="endDate">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="testCaseDetail.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="uatTestCases" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteUatTestCase(data.item.id)" data-toggle="modal" data-target="#deleteUatTestCase"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Test Case found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="uatTestCases.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="uatTestCase.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <Dialog id="deleteUatTestCase" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete UAT Test Case" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
export default {
    name: 'app',
    computed: {
        ermId() {
                return Number(this.$route.params.ermId);
            }
        },
        components: {
        TypeLookup,
        FunctionalReferencePopup,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeLookupTitle:'UatTestCase Type Settings',
            entity: 'uat_test_case',
            notEnabled: true,
            users: [],
            uatTestCaseTypes: [],
            uatTestCase: {
                id:'',
                uatTestCaseId:'',
                title: '',
                uatTestTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'uat_test_case',
                description: '',
                expectedResult: '',
                preparedById: ''
            },
            testCaseDetail: {
                title: '',
                type: '',
                description: '',
                expectedResult: '',
                preparedBy: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            uatTestCases: [],
            fields: [
                { key: 'uatTestCaseId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection:'desc' },
                { key: 'type', label: 'TC Type', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Test Description', sortable: true, sortDirection: 'desc' },  
                { key: 'expectedResult', label: 'Expected Result', sortable: true, sortDirection: 'desc' },                              
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getUatTestCase();
    },
    validations: {
        uatTestCase: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'uat_test_case';
            this.userId = localStorage.getItem("userId");
        },

        getUatTestCase: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/test/uattestcase/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.uatTestCases = result.uatTestCaseList;
                    this.totalRows = this.uatTestCases.length;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId:this.uatTestCase.vhextAccountId,
                entity:this.uatTestCase.entity,
                projectId:this.uatTestCase.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/test/uattestcase/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.uatTestCaseTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                    console.log(result);
                })
        },
        saveData: function() {
            this.$v.uatTestCase.$touch();
            console.log(this.uatTestCase);
            if (!this.$v.uatTestCase.$invalid) {
                if (this.elements.editMode) {
                    this.updateUatTestCase();
                } else {
                    this.saveUatTestCase();
                }
            }
        },
        saveUatTestCase: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/test/uattestcase/add', this.uatTestCase)
            .then((response) => {
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.getUatTestCase();
                    this.clearData();
                }
            })
        },
        updateUatTestCase: function() {
                axios.post(this.$store.getters.getAPIBasePath + '/test/uattestcase/update', this.uatTestCase)
                    .then((response) => {
                        console.log(this.uatTestCase);
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getUatTestCase();
                            this.clearData();
                        }
                        console.log(result);
                    })
        },
        deleteUatTestCase: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/test/uattestcase/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUatTestCase();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.uatTestCase.title = '';
            this.uatTestCase.uatTestTypeId = '';
            this.uatTestCase.description = '';
            this.uatTestCase.expectedResult = '';
            this.uatTestCase.preparedById = '';
            this.$v.uatTestCase.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.uatTestCase.id = data.id;
            this.uatTestCase.uatTestCaseId=data.uatTestCaseId;
            this.uatTestCase.title = data.title;
            this.uatTestCase.uatTestTypeId = data.uatTestTypeId;
            this.uatTestCase.description = data.description;
            this.uatTestCase.expectedResult = data.expectedResult;
            this.uatTestCase.preparedById = data.preparedById;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.testCaseDetail.uatTestCaseId = data.uatTestCaseId.toString();
            this.testCaseDetail.title = data.title
            this.testCaseDetail.type = data.type;
            this.testCaseDetail.description = data.description;
            this.testCaseDetail.expectedResult = data.expectedResult;
            this.testCaseDetail.preparedBy = data.preparedBy;
            this.testCaseDetail.createdDt = data.createdDt;
            this.testCaseDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.testCaseDetail.id = data.id;
        }
    }
}
</script>