<template>
    <div>
        <FinancialTxnMaster :ermMasterInfo="ermMasterInfo"></FinancialTxnMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FinancialTxnMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        FinancialTxnMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Financial Transaction Master",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
                redirectURL: '/ftxmas/list/',
                showTypeLookup: true
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURL = '/service/ftxmas/list';            
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/ftxmas/list';
            this.ermMasterInfo.typeCode = 'cli';
        } else {
            this.ermMasterInfo.redirectURL = '/ftxmas/list';
        }
    },
    methods: {}
}
</script>