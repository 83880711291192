<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="finTxnMemoryListPopupRef" size="lg" modal-class="" title="Transactions in Memory" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe">
                    <b-table show-empty small outlined stacked="md"  sort-icon-left :items="financialTransactionList" :fields="finTxnFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(amount)="data">
                            <div>
                                {{ currency && currency!== 'null' ? currency : '' }} {{ data.item.amount }}
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <button type="submit" title="Select Item" @click="selectMemoryItem(data.item)" :class="{'active' : selectedTransactionId==data.item.id}" class="btn btn-outline-primary sbtn btn-xs py-1 px-2">
                                <span v-if="selectedTransactionId==data.item.id">
                                    <i class="fa fa-check pr-1" aria-hidden="true"></i>Selected
                                </span>
                                <span v-else>Select</span>
                            </button>
                            <a href="javascript:" title="Delete" @click="removeMemoryItem(data.item)"><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button title="Cancel" size="sm" variant="outline-primary" @click.prevent="cancel()" class="float-right ml-1">
                        Cancel
                    </b-button>
                    <b-button size="sm" variant="primary" :disabled="!selectedTransactionId || selectedTransactionId!=memoryItem.id" @click="setMemoryItemData" class="float-right" >Use Transaction</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: { type: String, default: 'finTxnMemoryListPopup'},
        onMemoryItemSelect: Function,
        onMemoryItemRemove: Function,
    },
    data() {
        return {
            loading: true,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            currency: localStorage.getItem('currency'),

            memoryItem: {},

            selectedTransactionId: '',

            financialTransactionList: [],
            finTxnFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Txn Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Txn Type', sortable: true, sortDirection: 'desc' },
                // { key: 'empTimelineDt', label: 'Start Time', sortable: true, sortDirection: 'desc' },
                // { key: 'nextEmpTimelineDt', label: 'Stop Time', sortable: true, sortDirection: 'desc' },
                // { key: 'timeDiff', label: 'Time Diff (HH:MM)', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            totalRows: 0,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    mounted() {
        this.getDetails();
    },
    methods: {
        getDetails: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            // this.$nextTick(() => {
            //     this.$bvModal.show(this.id);
            // });
            this.loading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/memorizedfintxn/list', data)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    this.financialTransactionList = result.financialTransactionList;
                    this.totalRows = this.financialTransactionList.length;
                })
                .catch(error => {
                    this.loading = false;
                })
        },
        setMemoryItemData() {
            console.log(this.memoryItem.id, this.selectedTransactionId);
            let selectedTransactionId = this.selectedTransactionId;
            if (this.memoryItem.id && selectedTransactionId == this.memoryItem.id) {
                this.$bvModal.hide(this.id);
                this.onMemoryItemSelect(this.memoryItem, false, true);
            }
        },
        selectMemoryItem(data) {
            this.selectedTransactionId = data.id;
            this.memoryItem = data;
        },
        removeMemoryItem(data) {
            this.onMemoryItemRemove(data);
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
        closeDialog() {
            this.selectedTransactionId = '';
            this.$emit('closeDialog');
        }
    }
}
</script>