<template>
	<div class="comments">
		<nav class="app-menu-nav chat-box-comments  scope-comment-box">
            <div class="comments-titel">
                <label class="d-block">Chat Comments <span><a href="javascript:" class="float-right close-comments"><i class="fa fa-times " aria-hidden="true"></i></a></span></label>
            </div>
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div class="comments-list mt-0 scope-comment-box-height">
                    <div class="row m-0">
                        <div v-for="loadComment in comments" class="d-block w-100">
                            <div class="col-12">
                                <ul class="comments-list reply-list">
                                    <li>
                                        <div class="user-icon">
                                            <span class="user-name-icon">{{ loadComment.commentedBy | getAcronym }}</span>
                                        </div>
                                        <div class="comment-box">
                                            <div class="comment-head">
                                                <h6 class="comment-name by-author m-0"><a href="">{{ loadComment.commentedBy  }}</a></h6>
                                                <p class="text-muted d-inline-block ml-3 my-0 fs-12">{{ loadComment.commentDt | formatDateTime }}</p>
                                                <div class="d-flex float-right">
                                                    <a href="javascript:" v-if="(loadComment.id != commentEdit.editId)" title="Edit" @click="editComment(loadComment)"><i class="fa fa-pencil"></i></a>
                                                    <a href="javascript:" v-if="(loadComment.id == commentEdit.editId) && editMode" title="Send" @click="updateComment"><i class="fa fa-check"></i></a>
                                                    <a href="javascript:" @click="deleteComment(loadComment.id)" data-toggle="modal" data-target="#deleteComment"><i class="fa fa-trash-o"></i></a>
                                                </div>
                                            </div>
                                            <div v-if="(loadComment.id != commentEdit.editId)" class="comment-content py-2">
                                                <p class="m-0">
                                                    {{ loadComment.comment }}
                                                </p>
                                            </div>
                                            <div v-if="(loadComment.id == commentEdit.editId) && editMode">
                                                <textarea rows="3" class="w-100" @keyup.esc="editMode=false;commentEdit.editId='';" @keyup.enter="updateComment" v-model="commentEdit.comment"></textarea>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comments-cox pt-2">
                    <div class="row m-0">
                        <div class="col-12">
                            <div class="form-group">
                                <div v-if="!editMode" class="user-comments w-100 position-relative">
                                    <input type="text" class="form-control" v-model="comment.comment" id="initialBudget" placeholder="Comments" @keyup.enter="addComment">
                                    <button type="submit" @click="addComment" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 chat-sent-btn"><i class="fa fa-check pr-2"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </nav>
        <Dialog id="deleteComment" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />
	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
	props: {
        entity: String,
        ermId: Number
    },
    name: 'app',
    data() {
        return {
            showOverlay: false,
            editMode: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            comment: {
                id: '',
                commentId: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refErmId: '',
                commentedById: localStorage.getItem("userId"),
                refEntityId: '',
                refEntity: '',
                comment: '',
                commentDt: '',
            },
            commentEdit: {
                id: '',
                commentId: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refErmId: '',
                commentedById: localStorage.getItem("userId"),
                refEntityId: '',
                refEntity: '',
                comment: '',
                commentDt: '',
                editId: ''
            },
            comments: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        entityRecordMasterId() {
            return this.ermId;
        }
    },
    mounted() {
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");
        // this.getComments();

    },
    methods: {
        getComments: function(ermId) {
            if (ermId) {
                let data = {
                    refErmId: ermId
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/general/comment/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.comments = result.commentList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        addComment: function() {
            if (this.comment.comment) {
                this.comment.refErmId = this.entityRecordMasterId;
                this.comment.refEntity = this.entity;
                console.log(this.comment);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/general/comment/add', this.comment)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getComments(this.entityRecordMasterId);
                            this.comment.comment = '';
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateComment: function() {
            if (this.commentEdit.comment) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/general/comment/update', this.commentEdit)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getComments(this.entityRecordMasterId);
                            this.clearComment();
                            this.editMode = false;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        editComment: function(data) {
            this.editMode = true;
            this.commentEdit.editId = data.id;
            this.commentEdit.id = data.id;
            this.commentEdit.commentId = data.commentId;
            this.commentEdit.projectId = data.projectId;
            this.commentEdit.vhextAccountId = data.vhextAccountId;
            this.commentEdit.refErmId = data.refErmId;
            this.commentEdit.commentedById = data.commentedById;
            this.commentEdit.refEntityId = data.refEntityId;
            this.commentEdit.refEntity = data.refEntity;
            this.commentEdit.comment = data.comment;
            this.commentEdit.commentDt = data.commentDt;
        },
        deleteComment: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/comment/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments(this.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearComment: function() {
            this.editMode = false;
            this.commentEdit.id = '';
            this.commentEdit.editId = '';
            this.commentEdit.commentId = '';
            this.commentEdit.comment = '';
        },
    }
}
</script>