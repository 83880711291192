<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">
                            <div class="ingov-app-header">
                                <div class="app-options">
                                    <div class="app-menu1">
                                        <i class="fa fa-bars" aria-hidden="true"></i>
                                        <!-- <i class="fa fa-times closeicon" aria-hidden="true"></i> -->
                                    </div>
                                </div>
                            </div>
                            Task</h4>
                        </div>
                        <div class="col-lg-6 col-sm-6 text-right mb-1">
                            <b-button class="addnew-task-btn" variant="outline-info" size="sm"><i class="fa fa-plus"></i> Add New Task</b-button>
                            
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12 col-xl-12 position-relative">
                    <div class="row">
                        <div class="col-12 col-md-3 mb-1">
                            <div class="card mb-2 b-radius-5 ">
                                <div class="card-body p-3">
                                    <div class="form-group mb-0">
                                        <label for="title">To Do</label>
                                        <b-button class="pull-right addnew-quicktask-btn" variant="outline-info" size="sm" v-b-modal.Quick-Task-Modal><i class="fa fa-plus"></i> Quick Task</b-button>
                                        <!-- <b-button class="addnew-task-btn  pull-right" variant="outline-info" size="sm"><i class="fa fa-plus"></i> Quick Task</b-button> -->
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="taskLists">
                                <div v-for="task in tasks">
                                    <div class="card-media mb-2">
                                        <div class="card-media-body">
                                            <a type="button" class="float-right" id="dropdownMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-primary">
                                                <a class="dropdown-item" href="#"><i class="fa fa-user-plus pr-1" aria-hidden="true"></i>Assign</a>
                                                <a class="dropdown-item" href="#"><i class="fa fa-bell-slash pr-1"></i>Disable alertss</a>
                                                <a class="dropdown-item" href="#"><i class="fa fa-envelope pr-1"></i>Check mail</a>
                                            </div>
                                            <div class="card-media-body-top">
                                                <span class="card-media-body-heading">{{ task.title }}</span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-6 mb-1">
                                                        <span class="subtle">Activity</span>
                                                    </div>
                                                    <div class="col-6 mb-1 text-center">
                                                        <span class="subtle">Priority</span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="col-6 mb-2">
                                                        <span class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.startDate | formatDateTime }}</span>
                                                        <!-- <span class="card-media-object-tag subtle">New Task</span> -->
                                                    </div>
                                                    <div class="col-6 mb-2">
                                                        <span class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.endDate | formatDateTime }}</span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="col-12">
                                                        <ul class="card-media-object-social-list">
                                                            <li>
                                                                <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class="">
                                                                <span>{{ task.assignedBy }}</span>
                                                            </li>
                                                            <li>
                                                                <img src="https://s16.postimg.cc/b0j0djh79/profile_0_f.jpg" class="">
                                                                <span>Assigned To</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-media mb-2">
                                    <div class="card-media-body">
                                        <a href="#">
                                            <div class="card-media-body-top">
                                                <span class="subtle">Mon, APR 09, 7:00 PM</span> <span class="card-media-object-tag subtle">New Task</span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <span class="card-media-body-heading">This Thing Called Life: A Celebration of Prince and His Legacy at MEZZANINE SF</span>
                                            <div class="divider-line"></div>                                        
                                            <ul class="card-media-object-social-list">
                                                <li>
                                                    <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                                <li>
                                                    <img src="https://s16.postimg.cc/b0j0djh79/profile_0_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                </div>
                                <div class="card-media mb-2">
                                    <div class="card-media-body">
                                        <a href="#">
                                            <div class="card-media-body-top">
                                                <span class="subtle">Mon, APR 09, 7:00 PM</span> <span class="card-media-object-tag subtle">New Task</span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <span class="card-media-body-heading">This Thing Called Life: A Celebration of Prince and His Legacy at MEZZANINE SF</span>
                                            <div class="divider-line"></div>                                        
                                            <ul class="card-media-object-social-list">
                                                <li>
                                                    <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                                <li>
                                                    <img src="https://s16.postimg.cc/b0j0djh79/profile_0_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-1">
                            <div class="card mb-2 b-radius-5">
                                <div class="card-body p-3">
                                    <div class="form-group mb-0">
                                        <label for="title">In Progress</label>
                                        <b-button class="pull-right addnew-quicktask-btn" variant="outline-info" size="sm" v-b-modal.Quick-Task-Modal><i class="fa fa-plus"></i> Quick Task</b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="taskLists">
                                <div class="card-media mb-2">
                                    <div class="card-media-body">
                                        <a href="#">
                                            <div class="card-media-body-top">
                                                <span class="subtle">Mon, APR 09, 7:00 PM</span> <span class="card-media-object-tag subtle">New Task</span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <span class="card-media-body-heading">This Thing Called Life: A Celebration of Prince and His Legacy at MEZZANINE SF</span>
                                            <div class="divider-line"></div>                                        
                                            <ul class="card-media-object-social-list">
                                                <li>
                                                    <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                                <li>
                                                    <img src="https://s16.postimg.cc/b0j0djh79/profile_0_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-1">
                            <div class="card mb-2 b-radius-5">
                                <div class="card-body p-3">
                                    <div class="form-group mb-0 ">
                                        <label for="title">Done</label>
                                        <b-button class="pull-right addnew-quicktask-btn" variant="outline-info" size="sm" v-b-modal.Quick-Task-Modal><i class="fa fa-plus"></i> Quick Task</b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="taskLists">
                                <div class="card-media mb-2">
                                    <div class="card-media-body">
                                        <a href="#">
                                            <div class="card-media-body-top">
                                                <span class="subtle">Mon, APR 09, 7:00 PM</span> <span class="card-media-object-tag subtle">New Task</span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <span class="card-media-body-heading">This Thing Called Life: A Celebration of Prince and His Legacy at MEZZANINE SF</span>
                                            <div class="divider-line"></div>                                        
                                            <ul class="card-media-object-social-list">
                                                <li>
                                                    <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                                <li>
                                                    <img src="https://s16.postimg.cc/b0j0djh79/profile_0_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-3 mb-1">
                            <div class="card mb-2 b-radius-5">
                                <div class="card-body p-3">
                                    <div class="form-group mb-0 ">
                                        <label for="title">Done</label>
                                        
                                        <b-button class="pull-right addnew-quicktask-btn" variant="outline-info" size="sm" v-b-modal.Quick-Task-Modal><i class="fa fa-plus"></i> Quick Task</b-button>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="taskLists">
                                <div class="card-media mb-2">
                                    <div class="card-media-body">
                                        <a href="#">
                                            <div class="card-media-body-top">
                                                <span class="subtle">Mon, APR 09, 7:00 PM</span> <span class="card-media-object-tag subtle">New Task</span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <span class="card-media-body-heading">This Thing Called Life: A Celebration of Prince and His Legacy at MEZZANINE SF</span>
                                            <div class="divider-line"></div>                                        
                                            <ul class="card-media-object-social-list">
                                                <li>
                                                    <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                                <li>
                                                    <img src="https://s16.postimg.cc/b0j0djh79/profile_0_f.jpg" class="">
                                                    <span>Sample User 1</span>
                                                </li>
                                            </ul>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav class="app-menu-nav left-side">
                        <div class="comments-titel">
                            <label class="d-block">Task  <span><a href="#" class="float-right close-comments"><i class="fa fa-times " aria-hidden="true"></i></a></span></label>
                        </div>
                        <ul>
                            <li>
                            <a href="#"><i class="fa fa-home"></i> Matrasys Developers</a>
                            </li>
                            <li>
                            <a href="#"><i class="fa fa-dashboard"></i> General</a>
                            </li>
                            <li>
                            <a href="#"><i class="fa fa-shopping-basket"></i> Kanini</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="row addnew-task-details hide position-absolute w-100 t-0">
                        <div class="col-12 col-md-12 mb-1">
                            <div class="card mb-2 b-radius-5 ">
                                <div class="addnew-task-close">
                                    <a href="#"><i class="fa fa-times"></i></a>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-group mb-0">
                                        <div class="row">
                                            <div class="col-12 col-md-4">
                                                <div class="form-group">
                                                    <label for="projectManager">Bucket</label>
                                                    <select class="form-control">
                                                        <option value="">Select</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="form-group">
                                                    <label for="projectManager">Progress</label>
                                                    <select class="form-control">
                                                        <option value="">Select</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="form-group">
                                                    <label for="projectManager">Priority</label>
                                                    <select class="form-control">
                                                        <option value="">Select</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="form-group">
                                                    <label for="projectManager">Start Date</label>
                                                    <div class="input-group ">
                                                        <input type="text" class="form-control" placeholder="Start Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4">
                                                <div class="form-group">
                                                    <label for="startDate">End Date</label>
                                                    <div class="input-group ">
                                                        <input type="text" class="form-control" placeholder="Start Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label>Notes</label>
                                                <textarea class="form-control" rows="4"></textarea>
                                            </div>
                                        </div>
                                        <div class="task-box-items  mt-3">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label>Check List</label>
                                                    <b-form-checkbox
                                                    id="checkbox-1"
                                                    name="checkbox-1"
                                                    value="accepted"
                                                    unchecked-value="not_accepted">
                                                    Add an Item
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-10">
                                                    <div class="form-group">
                                                        <input type="text" class="form-control" id="initialBudget" placeholder="Add an Items">
                                                    </div>
                                                </div>
                                                <div class="col-2">
                                                    <div class="form-group">
                                                        <button type="submit" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="task-box-items  mt-3">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label>Comments</label>
                                                </div>
                                            </div>
                                            <div class="comments-list">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <ul class="comments-list reply-list">
                                                            <li>
                                                                <div class="user-icon">
                                                                    <span class="user-name-icon"> RM </span>
                                                                    <!-- <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class=""> -->
                                                                </div>
                                                                <div class="comment-box">
                                                                    <div class="comment-head">
                                                                        <h6 class="comment-name by-author"><a href="">Agustin Ortiz</a></h6>
                                                                        <span>Mon, APR 09, 7:00 PM</span>
                                                                        <i class="fa fa-pencil"></i>
                                                                        <i class="fa fa-trash-o"></i>
                                                                    </div>
                                                                    <div class="comment-content">
                                                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Velit omnis animi et iure laudantium vitae, praesentium optio, sapiente distinctio illo?
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="comments-cox">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <div class="form-group">
                                                            <div class="user-icon">
                                                                <span class="user-name-icon"> RM </span>
                                                                <!-- <img src="https://s10.postimg.cc/3rjjbzcvd/profile_f.jpg" class=""> -->
                                                            </div>
                                                            <div class="user-comments">
                                                                <input type="text" class="form-control" id="initialBudget1" placeholder="Comments">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <button type="submit" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <button type="submit" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Save</button>
                                            <button type="submit" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 task-close-btn"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-modal id="Quick-Task-Modal" ref="modal" no-close-on-backdrop no-close-on-esc title="Add Quick Task" class="quickTask-header">
                        <b-container fluid>
                            <b-row class="mb-1">
                                <b-col cols="3">Task</b-col>
                                <b-col>
                                    <b-form-input id="title" placeholder="Enter a Task Name" v-model="task.title" required></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Due Date</b-col>
                                <b-col>
                                    <b-form-input type="date" id="endDate" v-model="task.estimatedEndDt" required></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Activity</b-col>
                                <b-col>
                                    <select v-model="task.activityId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in taskActivity" :value="info.id">{{info.value}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Assigned To</b-col>
                                <b-col>
                                    <multiselect v-model="task.assignedById" :options="users" name="assignedBy" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                </b-col>
                            </b-row>
                        </b-container>
                        <template #modal-footer>
                            <div class="w-100">
                                <b-button variant="primary" size="sm" @click="saveTask" class="float-right">
                                    Add Task
                                </b-button>
                                <button class="btn btn-outline-primary float-right mr-2" @click="clearData">
                                    Clear
                                </button>
                            </div>
                        </template>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Multiselect
    },
    data() {
        return {
            task: {
                title: '',
                estimatedEndDt: '',
                assignedById: '',
                activityId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: 1
            },
            tasks: [],
            users: [],
            taskActivity: [],
        }
    },
    computed: {

    },
    mounted() {
        this.lookupData();
        this.getTasks();
    },
    methods: {
        getTasks: function() {
            let data = {
                entityRecordMasterId: 1
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskList;
                })
                console.log(this.tasks);
        },
        saveTask: function() {
            console.log(this.task);
            axios.post(this.$store.getters.getAPIBasePath + '/task/add', this.task)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTasks();
                    }
                })
        },
        clearData: function() {
            this.task.title = '';
            this.task.endDate = '';
            this.task.assignedById = '';
            this.activityId = '';
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.taskActivity = result.activityLookup;
                })
        }
    }
}
</script>
