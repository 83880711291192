<template>
    <div ref="gantt"></div>
</template>
<script>
import moment from 'moment';
import 'dhtmlx-gantt'
export default {
    name: 'gantt',
    props: {
        tasks: {
            type: Object,
            default () {
                return { data: [], links: [] }
            }
        },
        ganttChartScales: String,
    },

    methods: {
        $_initGanttEvents: function() {
            if (!gantt.$_eventsInitialized) {
                gantt.attachEvent('onTaskSelected', (id) => {
                    let task = gantt.getTask(id);
                    this.$emit('task-selected', task);
                });

                gantt.attachEvent('onTaskIdChange', (id, new_id) => {
                    if (gantt.getSelectedId() == new_id) {
                        let task = gantt.getTask(new_id);
                        this.$emit('task-selected', task);
                    }
                });

                gantt.$_eventsInitialized = true;
            }
        },

        $_initDataProcessor: function() {
            if (!gantt.$_dataProcessorInitialized) {
                gantt.createDataProcessor((entity, action, data, id) => {
                    this.$emit(`${entity}-updated`, id, action, data);
                });

                gantt.$_dataProcessorInitialized = true;
            }
        }
    },

    mounted: function() {
        this.$_initGanttEvents();
        gantt.clearAll();
        gantt.config.xml_date = "%Y-%m-%d %H:%i";

        // alert(this.ganttChartScales);
        if (this.ganttChartScales == "month") {
            // month-scale
            gantt.config.scales = [
                {unit: "month", step: 1, format: "%F, %Y"},
            ];
        }
        else if (this.ganttChartScales == "week") {
            // week-scale
            gantt.config.scales = [
                // {unit: "month", step: 1, format: "%F, %Y"},
                {unit: "week", step: 1, format: function(date){
                    return "Week #" + getWeekOfMonthNumber(date);
                }}
            ];
        }
        else if (this.ganttChartScales == "day") {
            // day-scale
            gantt.config.scales = [
                // {unit: "month", step: 1, format: "%F, %Y"},
                {unit: "day", step: 1, format: "%j, %D"}
            ];
        }
        else if (this.ganttChartScales == "hour") {
            // day-scale
            gantt.config.scales = [
                // {unit: "month", step: 1, format: "%F, %Y"},
                {unit: "hour", step: 2, format: "%H:%i"}
            ];
        }


    // gantt.config.step = 1; // Set step to 1 hour
    // gantt.config.date_scale = "%H:%i"; // Format for date scale        

        gantt.config.sort = true; 

        gantt.config.layout = {
            css: "gantt_container",
            rows:[
              {
                cols: [
                  {view: "grid", id: "grid", scrollX:"scrollHor", scrollY:"scrollVer"},
                  {resizer: true, width: 1},
                  {view: "timeline", id: "timeline", scrollX:"scrollHor", scrollY:"scrollVer"},
                  {view: "scrollbar", scroll: "y", id:"scrollVer"}
                ]
               },
              {view: "scrollbar", scroll: "x", id:"scrollHor", height:20}
            ]
        };
         

        // gantt.init("gantt_here");

        // name:"month",
        //  scale_height: 50,
        //  min_column_width:120,
        //  scales:[
        //     {unit: "month", format: "%F, %Y"},
        //     {unit: "week", format: "Week #%W"}
        //  ]

        console.log(this.ganttChartScales);

        gantt.plugins({
            tooltip: true
        });

        gantt.config.tooltip_timeout = 50;

        gantt.init(this.$refs.gantt);

        gantt.parse(this.$props.tasks);

        this.$_initDataProcessor();
    }
}
function getWeekOfMonthNumber(date){
    let adjustedDate = date.getDate()+date.getDay();
    let prefixes = ['0', '1', '2', '3', '4', '5'];
    return (parseInt(prefixes[0 | adjustedDate / 7])+1);
} 
gantt.templates.tooltip_text = function(start, end, task) {
    // if (this.ganttChartScales == "day") {
    //     // day-scale
    //     alert(this.ganttChartScales);
    //     return "<b>Task:</b> " + task.text + "<br/>" +
    //         "<b>Assigned By:</b> " + task.assignedBy + "<br/>" +
    //         "<b>Start Date:</b> " + moment(String(task.start_date)).format('DD-MMM-YYYY') + "<br/>" +
    //         "<b>End Date:</b> " + moment(String(task.end_date)).format('DD-MMM-YYYY') + "<br/>" +
    //         "<b>Progress Percentage:</b> " + (task.progress*100) + "%<br/>" +
    //         "<b>Duration:</b> " + task.duration;
    // }
    // else {
        return "<h6 style='color:dark-grey;tab-size:16'><b>Title : " + task.text + "</b></h6>" + 
            " <b> " + " Assigned By : </b>" + task.assignedBy + "<br/>" +
            " <b> Assigned To : </b>" + task.assignedTo + "<br/>" +
            " <b> Start Date:</b> " + moment(String(task.start_date)).format('DD-MMM-YYYY hh:m') + "<br/>" +
            " <b> End Date:</b> " + moment(String(task.end_date)).format('DD-MMM-YYYY hh:mm') + "<br/>" +
            " <b> Progress Percentage:</b> " + (task.progress*100) + "%<br/>" +
            " <b> Duration:</b> " + task.duration ;
    // }

};
</script>