<template>
    <div class="reportingPlanDetailPopup">
        <b-modal id="reportingPlanDetailPopup" ref="reportingPlanDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Reporting Plan Details">
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-1">
                            <div class="col-lg-12 col-xl-12">
<!--                                 <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                    <div class="float-right">
                                        <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                    </div>
                                </div> -->
                                <!-- <div class="col-12 col-sm-9 mb-3 px-0"> -->
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.reportingPlanId }}</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                        </div>
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.type}}</label>
                                        </div>
                                                                         
<!--                                         <div class="col-1 pr-0">
                                            <label class="mb-0 p-0 pt-2 pb-2 d-block title-block"><a title="Due Date"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></a></label>
                                        </div>
                                        <div class="col-3 pl-0 pr-0">
                                            <label class="p-2 mb-0 header-details font-weight-bold">{{ popupData.dueDate | formatDate}}</label>
                                        </div> -->

                                    <!-- </div> -->
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reported By</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.reportedBy}}</label>
                                        </div>
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reported To</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.reportedTo}}</label>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Frequency</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.frequency}}</label>
                                        </div>
                                    </div>
                                </div>                                
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Description</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Channel</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.communicationChannel}}</label>
                                        </div>
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Weekdays</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.weekdays}}</label>
                                        </div>

                                    </div>
                                </div>                                
<!--                                 <div class="col-12 col-sm-12 px-0">
                                    <div class="row mt-0 mb-2">
                                        <div class="col-12 col-md-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                        </div>
                                        <div class="col-12 col-md-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.preparedBy}}</label>
                                        </div>
                                        <div class="col-12 col-md-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                        </div>
                                        <div class="col-12 col-md-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.preparedDt | formatDate}}</label>
                                        </div>                                        
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            console.log(id, refEntity);
                if (refEntity === "reporting_plan") {
                    this.$bvModal.show('reportingPlanDetailPopup');
                    axios.get(this.$store.getters.getAPIBasePath + '/communication/reportingplan/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.reportingPlanView;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$bvModal.show('reportingPlanDetailPopup');
                    this.loading = false;
                }
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>