<template>
    <div>
        <ProjectPlanMaster :ermMasterInfo="ermMasterInfo"></ProjectPlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectPlanMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ProjectPlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Project Plan Master",
                refEntity: 'plan',
                entity: 'project_plan_master',
                ermListRedirectURL: '/pplmas/list',
                erdRedirectURL: '/projectplan/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>