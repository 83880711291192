<template>
    <div>
        <!-- Client Task Page -->
        <TaskComponent />
    </div>
</template>
<script>
import TaskComponent from '@/components/common/TaskComponent.vue';
export default {
    components: {
        TaskComponent
    },
}
</script>
