<template>
    <div>
        <ScopeStatementMaster :ermMasterInfo="ermMasterInfo"></ScopeStatementMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ScopeStatementMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ScopeStatementMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Scope Statement Master",
                entity: 'scope_statement_master',
                refEntity: 'scope_statement',
                ermListRedirectURL: '/scope/list',
                erdRedirectURL: '/scope/statement/'                
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>