<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Projects 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart :styles="myStyles" :chartdata="projectByStatusData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Tasks
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Task By Status">{{ taskStatusCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body py-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="taskByStatusData" :options="chartOptions" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Leaves
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Leave By Leave Type">{{ leaveCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart :styles="myStyles" :chartdata="employeeLeaveByTypeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>                                        
                                    </div>
                                </div>

                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Users
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="userByTypeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-2">
                        <div class="col-4">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-sm-12 mb-1">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Tasks
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Task By Status">{{ taskStatusCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body py-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="taskByStatusData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-sm-12 mb-1">
                                            <div class="card p-1">
                                                <p class="chart-title text-left pl-1 mb-1">
                                                    Leaves
                                                    <span class="badge badge-light float-right pointer py-1 px-2" title="Leave By Leave Type">{{ leaveCount }}</span>
                                                </p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="employeeLeaveByTypeData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>                                        
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        <div class="col-4">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                    </div>
                                </div>
                            </div>
                        </div>                                                
                    </div>
                    <div class="form-row mb-2">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-md-12 col-xl-12">
                                            <div class="card p-3">
                                                <div class="form-row">
                                                    <div class="col-3">
                                                        <p class="chart-title text-left pl-1 mb-1 h6">My Task - {{ganttTitle}}</p>
                                                    </div>
                                                    <div class="col-9 text-right">
                                                        <template>
                                                            <b-avatar text="M" title="Month" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('m')"></b-avatar>
                                                            <b-avatar text="W" title="Week" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('w')"></b-avatar>
                                                            <b-avatar text="D" title="Day" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('d')"></b-avatar>
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <gantt class="gantt" :tasks="tasks" :ganttChartScales="ganttChartScales" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask" :key="ganttChartKey"></gantt>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-xl-12 mt-2">
                                            <div class="card p-3">
                                                <div class="form-row">
                                                    <div class="col-3">
                                                        <p class="chart-title text-left pl-1 mb-1 h6">Attendance - {{leaveTimeTitle}}</p>
                                                    </div>
                                                    <div class="col-9 text-right">
                                                        <template>
                                                            <b-avatar text="Y" title="Year" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="getPeriodicLeaveTime('y')"></b-avatar>
                                                            <b-avatar text="M" title="Month" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="getPeriodicLeaveTime('m')"></b-avatar>
                                                            <b-avatar text="W" title="Week" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="getPeriodicLeaveTime('w')"></b-avatar>
                                                            <b-avatar text="D" title="Day" size="sm" rounded="sm" variant="primary" class="align-baseline mr-1" button @click="getPeriodicLeaveTime('d')"></b-avatar>
                                                        </template>
                                                    </div>

                                                </div>
                                                <b-card-body class="py-1 px-1">
                                                    <b-table  :items="employeeLeaveTimeSummary" :fields="employeeLeaveTimeFields" responsive="sm">
                                                        <template v-slot:cell(workingDays)="data">
                                                            <!-- <button type="button" class="btn btn-primary">
                                                              Working Days <span class="badge badge-light p-2 ml-1"> {{data.item.workingDays}}</span>
                                                            </button> -->       
                                                                                                                 
                                                            <spam class="badge badge-pill badge-primary" title="Total Working Days" STYLE="font-size:14px">{{data.item.workingDays}}</spam>
                                                        </template>                                                        
                                                        <template v-slot:cell(totalPresent)="data">
                                                            <spam class="badge badge-pill badge-success" title="Total Days Present" STYLE="font-size:14px">{{data.item.totalPresent}}</spam>
                                                        </template>
                                                        <template v-slot:cell(totalFirstHalfLeave)="data">
                                                            <spam class="badge badge-pill badge-warning" title="Total 1st Half Leave Days" STYLE="font-size:14px">{{data.item.totalFirstHalfLeave}}</spam>
                                                        </template>
                                                        <template v-slot:cell(totalSecondHalfLeave)="data">
                                                            <spam class="badge badge-pill badge-warning" title="Total 2nd Half Leave Days" STYLE="font-size:14px">{{data.item.totalSecondHalfLeave}}</spam>
                                                        </template>
                                                        <template v-slot:cell(totalFullDayLeave)="data">
                                                            <spam class="badge badge-pill badge-danger" title="Total Full Day Leave" STYLE="font-size:14px">{{data.item.totalFullDayLeave}}</spam>
                                                        </template>
                                                        <template v-slot:cell(totalLop)="data">
                                                            <spam class="badge badge-pill badge-danger" title="Total Loss Of Pay Days" STYLE="font-size:14px">{{data.item.totalLop}}</spam>
                                                        </template>

                                                        <template v-slot:cell(totalOvertimeHrs)="data">
                                                            <spam class="badge badge-pill badge-success" title="Total Overtime Working Hours" STYLE="font-size:14px">{{data.item.totalOvertimeHrs}}</spam>
                                                        </template>

                                                        <template v-slot:cell(attendancePercentage)="data">
                                                            <span title="Attendance Percentage">{{ empAttendeesPercentage(data.item) }} %</span>
                                                            <b-progress class="mx-3" height="10px" :value="empAttendeesPercentage(data.item)" :variant="overallAttendanceProgressVariant(empAttendeesPercentage(data.item))"></b-progress>
                                                        </template>
                                                        <template v-slot:empty="erm">
                                                            <div class="text-center">No Record Found</div>
                                                        </template>
                                                    </b-table>
                                                </b-card-body>
                                            </div>                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import Gantt from "@/components/blocks/charts/GanttChart.vue"
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        Gantt,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            ganttTitle: 'This Week',
            leaveTimeTitle:'This Week',

            empAttendance: {
                startDate: this.getFirstDateOfMonth(new Date()),
                endDate: this.getLastDateOfMonth(new Date()),
            },



            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            resourceCount: 0,
            employeeCount: 0,
            userCount: 0,
            taskStatusCount: 0,
            leaveCount: 0,


            requirementSpecStatusSummary: {},
            userRequirementStatusSummary: {},
            projectIssueStatusSummary: {},
            projectBudgetSummary: {},
            resourcePoolData: {},
            employeeData: {},
            workTypeChartData: {},
            genderDistributionData: {},
            ageDistributionData: {},
            projectByStatusData: {},
            taskByStatusData: {},
            userByTypeData: {},

            employeeLeaveTimeSummary: [],
            employeeLeaveTimeFields: [
                { key: 'workingDays', label: 'Working Days' , tdClass: 'text-center' ,thClass: 'text-center'},
                { key: 'totalPresent', label: 'Present' },
                { key: 'totalFirstHalfLeave', label: '1st Half Leave' , tdClass: 'text-center',thClass: 'text-center'},
                { key: 'totalSecondHalfLeave', label: '2nd Half Leave' , tdClass: 'text-center',thClass: 'text-center'},
                { key: 'totalFullDayLeave', label: 'Full Day Leave' , tdClass: 'text-center',thClass: 'text-center'},
                { key: 'totalLop', label: 'LOP Days' , tdClass: 'text-center',thClass: 'text-center'},
                { key: 'totalOvertimeHrs', label: 'OT Hours' , tdClass: 'text-center',thClass: 'text-center'},
                { key: 'attendancePercentage', label: 'Attendance Performance'},
            ],


            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        // stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                    }]
                }
            },
            stackedBarChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        myStyles() {
            return {
                height: '80px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        myStyles2() {
            return {
                height: '72px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
    },
    mounted() {
        this.setGanttChartScale('w');
        this.getPeriodicLeaveTime('w')
        this.getDashboardData();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: this.userId,
                startDate: this.empAttendance.startDate,
                endDate: this.empAttendance.endDate,

            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/tmbdata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    
                    // this.employeeLeaveTimeSummary = result.employeeLeaveTime;

                    console.log(result);

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.requirementSpecStatusSummary != null && result.requirementSpecStatusSummary.length != 0) {
                        this.requirementSpecStatusSummary = {
                            labels: result.requirementSpecStatusSummary.map(item => item.title),
                            datasets: [{
                                  label: 'Draft',
                                  backgroundColor: "grey",
                                  data: result.requirementSpecStatusSummary.map(item => item.draftCount),
                                },
                                {
                                  label: 'Open',
                                  backgroundColor: "#47799a",
                                  data: result.requirementSpecStatusSummary.map(item => item.openCount),
                                },
                                {
                                  label: 'Started',
                                  backgroundColor: "#3db9d3",
                                  data: result.requirementSpecStatusSummary.map(item => item.startedCount),
                                },
                                {
                                  label: 'In Progress',
                                  backgroundColor: "violet",
                                  data: result.requirementSpecStatusSummary.map(item => item.inProgressCount),
                                },
                                {
                                  label: 'Completed',
                                  backgroundColor: "yellow",
                                  data: result.requirementSpecStatusSummary.map(item => item.completedCount),
                                },
                                {
                                  label: 'Verified',
                                  backgroundColor: "orange",
                                  data: result.requirementSpecStatusSummary.map(item => item.verifiedCount),
                                },
                                {
                                  label: 'Reopened',
                                  backgroundColor: "red",
                                  data: result.requirementSpecStatusSummary.map(item => item.reopenedCount),
                                },
                                {
                                  label: 'Closed',
                                  backgroundColor: "green",
                                  data: result.requirementSpecStatusSummary.map(item => item.closedCount),
                                },
                            ]
                        }
                    } else {
                        this.elements.noReqSpecData = true;
                    }
                    if (result.userRequirementStatusSummary != null && result.userRequirementStatusSummary.length != 0) {
                        // this.elements.noReqSpecData = false;
                        this.userRequirementStatusSummary = {
                            labels: result.userRequirementStatusSummary.map(item => item.title),
                            datasets: [{
                                  label: 'Draft',
                                  backgroundColor: "grey",
                                  data: result.userRequirementStatusSummary.map(item => item.draftCount),
                                },
                                {
                                  label: 'Open',
                                  backgroundColor: "#47799a",
                                  data: result.userRequirementStatusSummary.map(item => item.openCount),
                                },
                                {
                                  label: 'In Progress',
                                  backgroundColor: "violet",
                                  data: result.userRequirementStatusSummary.map(item => item.inProgressCount),
                                },
                                {
                                  label: 'Completed',
                                  backgroundColor: "green",
                                  data: result.userRequirementStatusSummary.map(item => item.completedCount),
                                },
                                {
                                  label: 'Verified',
                                  backgroundColor: "orange",
                                  data: result.userRequirementStatusSummary.map(item => item.verifiedCount),
                                },
                                {
                                  label: 'Closed',
                                  backgroundColor: "green",
                                  data: result.userRequirementStatusSummary.map(item => item.closedCount),
                                },
                            ]
                        }
                    }
                    if (result.projectIssueStatusSummary != null && result.projectIssueStatusSummary.length != 0) {
                        // this.elements.noReqSpecData = false;
                        this.projectIssueStatusSummary = {
                            labels: result.projectIssueStatusSummary.map(item => item.title),
                            datasets: [{
                                  label: 'Draft',
                                  backgroundColor: "grey",
                                  data: result.projectIssueStatusSummary.map(item => item.draftCount),
                                },
                                {
                                  label: 'New Issue',
                                  backgroundColor: "#47799a",
                                  data: result.projectIssueStatusSummary.map(item => item.openCount),
                                },
                                {
                                  label: 'Analyzed',
                                  backgroundColor: "violet",
                                  data: result.projectIssueStatusSummary.map(item => item.inProgressCount),
                                },
                                {
                                  label: 'Resolved',
                                  backgroundColor: "yellow",
                                  data: result.projectIssueStatusSummary.map(item => item.completedCount),
                                },
                                {
                                  label: 'Verified',
                                  backgroundColor: "orange",
                                  data: result.projectIssueStatusSummary.map(item => item.verifiedCount),
                                },
                                {
                                  label: 'Re-opened',
                                  backgroundColor: "red",
                                  data: result.projectIssueStatusSummary.map(item => item.reopenedCount),
                                },
                                {
                                  label: 'Closed',
                                  backgroundColor: "green",
                                  data: result.projectIssueStatusSummary.map(item => item.closedCount),
                                },
                            ]
                        }
                    }
                    this.projectByStatusData = {
                        labels: result.projectByStatus.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.projectByStatus.map((item) => {
                                this.projectCount += Number(item.empCount);

                                return item.empCount;
                            })
                        }]
                    }
                    this.taskByStatusData = {
                        labels: result.taskByStatus.map(item => item.status),
                        datasets: [{
                            labels: ['To Do', 'In Progress', 'Completed', 'Closed', 'On Hold', 'Cancelled' ],
                            backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                            data: result.taskByStatus.map(item => {
                                this.taskStatusCount += Number(item.taskCount);
                                return item.taskCount;
                            })
                        }]
                    }
                    this.employeeLeaveByTypeData = {
                        labels: result.employeeLeaveByType.map(item => item.leaveType),
                        datasets: [{
                            backgroundColor: ['grey','#2a93d5' ,'#90EE90', 'green','yellow','red'],
                            data: result.employeeLeaveByType.map(item => {
                                this.leaveCount += Number(item.totalLeave);
                                return item.totalLeave;
                            })
                        }]
                    }


                    if (result.taskList != null) {
                        console.log(result);
                        this.tasks = {
                            data: result.taskList,
                            links: []
                        }
                        let dateNow = new Date();
                        this.tasks.data = this.tasks.data.map((item) => {
                            item.text = item.text;
                            item.start_date = new Date(item.start_date);
                            item.end_date = new Date(item.end_date);
                            item.progress = Math.round(((item.end_date - item.start_date) / (item.start_date - dateNow)) * 100);
                            return item;
                        })
                    }
                    if (result.projectBudgetSummary != null) {
                        this.projectBudgetSummary = {
                            labels: result.projectBudgetSummary.map(item => item.displayName ? item.displayName : item.title),
                            datasets: [{
                                    label: 'Estimated',
                                    backgroundColor: '#28a745',
                                    data: result.projectBudgetSummary.map(item => item.estimatedAmount)
                                },
                                {
                                    label: 'Actual',
                                    backgroundColor: '#d85a5a',
                                    // borderColor: 'rgba(255, 26, 104, 1)',
                                    // borderWidth: 1,
                                    data: result.projectBudgetSummary.map(item => item.actualAmount)

                                }
                            ]
                        }

                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        
        getDBLeaveTimeData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: this.userId,
                startDate: this.empAttendance.startDate,
                endDate: this.empAttendance.endDate,
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/tmbdata/leavetime', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    
                    this.employeeLeaveTimeSummary = result.employeeLeaveTime;

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },


        selectTask(task) {
            console.log(task);
            this.selectedTask = task;
        },

        addMessage(message) {
            this.messages.unshift(message)
            if (this.messages.length > 40) {
                this.messages.pop()
            }
        },
        setGanttChartScale(scale) {
            if (scale == 'd') {
                // alert(scale);
                this.ganttChartScales = 'day';
                this.ganttChartKey += 1;    

                this.ganttTitle = 'Today';
                    
            }
            else if (scale == 'w') {
                this.ganttChartScales = 'week';
                this.ganttChartKey += 1;                        
                // alert(scale);
                this.ganttTitle = 'This Week';
            }
            else if (scale == 'm') {
                this.ganttChartScales = 'month';
                this.ganttChartKey += 1;                        
                // alert(scale);
                this.ganttTitle = 'This Month';
            }

        },
        getPeriodicLeaveTime(scale) {
            if (scale == 'd') {
                this.empAttendance.startDate = new Date();
                this.empAttendance.endDate = new Date();

                this.leaveTimeTitle = 'Today';

            }
            else if (scale == 'w') {
                this.empAttendance.startDate = this.getFirstDateOfWeek(new Date());
                this.empAttendance.endDate = new Date();
                console.log(this.empAttendance.startDate,this.empAttendance.endDate)
                this.leaveTimeTitle = 'This Week';
            }
            else if (scale == 'm') {
                this.empAttendance.startDate = this.getFirstDateOfMonth(new Date());
                this.empAttendance.endDate = this.getLastDateOfMonth(new Date());

                this.leaveTimeTitle = 'This Month';
            }
            else if (scale == 'y') {
                this.empAttendance.startDate = this.getFirstDateOfYear(new Date());
                this.empAttendance.endDate = new Date();

                this.leaveTimeTitle = 'This Year';
            }
            this.getDBLeaveTimeData();
        },

        logTaskUpdate(id, mode, task) {
            let text = (task && task.text ? ` (${task.text})` : '')
            let message = `Task ${mode}: ${id} ${text}`
            this.addMessage(message)
        },

        logLinkUpdate(id, mode, link) {
            let message = `Link ${mode}: ${id}`
            if (link) {
                message += ` ( source: ${link.source}, target: ${link.target} )`
            }
            this.addMessage(message)
        },
        empAttendeesPercentage(data) {
            let totalCount = data.totalPresent + data.totalFirstHalfLeave + data.totalSecondHalfLeave + data.totalFullDayLeave;
            let empTotalPresent = data.totalPresent;
            let empTotalHalfDayLeave = data.totalFirstHalfLeave + data.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((empTotalPresent+(empTotalHalfDayLeave/2))/totalCount) * 100).toFixed(1) : 0);
        },

    }
}
</script>