<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <DocumentMasterList :ermMasterInfo="ermMasterInfo" ></DocumentMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DocumentMasterList from '../../components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DocumentMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Issue Group",
                entity: 'issue_master',
                refEntity: 'issue',
                tooltip: 'New Issue Group',
                redirectURLName: 'lanissue',
                newEntityMasterURL: 'issmas',
                redirectURL: '/issmas',
                actionName: 'Issue',
                previewURLName: 'lanissrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>