<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Project Schedule</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div v-if="ermIsFreeze!='true'" id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Plan Item</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div v-if="!elements.viewMode">
                                                    <!-- <div class="row mb-2">
                                                        <div class="col-12">
                                                            <b-button class="addnew-quicktassk-btn float-right" @click="openEffortEstimationPopup(true, null)" variant="outline-secondary" size="sm" title="Add Effort Estimation"><i class="fa fa-list-ul pr-1"></i>Add Effort Estimation <span v-if="plan.effortEstimationList.length > 0">[{{ plan.effortEstimationList.length }}]</span></b-button>
                                                        </div>
                                                    </div> -->
                                                    <div class="row mb-2">
                                                        <div class="col-6">
                                                            <label for="phaseTitle">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.plan.phaseTitle.$error }">
                                                                <b-form-input v-model="plan.phaseTitle" maxlength="100" placeholder="Enter Title"></b-form-input>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.plan.phaseTitle.$error && !$v.plan.phaseTitle.required" class="text-danger">Please enter plan title</label>
                                                            <label v-if="$v.plan.phaseTitle.$error && !$v.plan.phaseTitle.maxLength" class="text-danger">Plan Title must not exceed {{$v.plan.phaseTitle.$params.maxLength.max}} letters.</label>
                                                        </div>
<!--                                                         <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="process">Process</label>
                                                                <b-input-group>
                                                                    <select v-model="plan.processId" @change="getProcessActivities(plan.processId)" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in processes" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="process">Activity</label>
                                                                <b-input-group>
                                                                    <select v-model="plan.activityId" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>Start Date</label>
                                                                <input type="date" v-model="plan.startDate" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>End Date</label>
                                                                <input type="date" v-model="plan.endDate" class="form-control">
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="deliverables">Deliverables</label>
                                                                <multiselect v-model="plan.deliverables" :options="deliverables" name="deliverables" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                            </div>
                                                            <a :title="deliverableLookupTitle" class="setting-icon" href="javascript:" v-b-modal.deliverableModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                        </div> -->
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>Effort</label>
                                                                <b-input-group>
                                                                    <b-form-input type="number" min="0" v-model="plan.effort" ></b-form-input>
                                                                    <b-input-group-append class="append-60">
                                                                        <select v-model="plan.effortType" class="form-control">
                                                                            <!-- <option value="">Select</option> -->
                                                                            <option v-for="(info, index)  in effortUOM" :key="info.id" :value="info.id">{{info.name}}</option>
                                                                        </select>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                                <!-- <div class="row no-gutters">
                                                                    <div class="col-8 col-md-9 col-lg-10 col-xl-11"> -->
                                                                        <!-- <b-input-group size="sm" append="Man Hours">
                                                                            <b-form-input type="number" min="0" v-model="plan.effort" ></b-form-input>
                                                                        </b-input-group> -->
                                                                    <!-- </div>
                                                                    <div class="col d-flex align-middle">
                                                                        <a class="action-link ml-1 pt-1" href="javascript:" @click="openEffortEstimationPopup(true, null)" title="Add Effort Estimation">
                                                                            <i class="fa fa-list-ul" aria-hidden="true"></i>
                                                                        </a>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>Estimated Cost</label>
                                                                <!-- <div class="row no-gutters">
                                                                    <div class="col-8 col-md-9 col-lg-10 col-xl-11"> -->
                                                                        <VhextMoneyControl :value="Number(plan.estimatedCost)" :key="vhextMoneyControlKey" @money-input="moneyControlInput" />
                                                                    <!-- </div>
                                                                    <div class="col d-flex align-middle">
                                                                        <a class="action-link ml-1 pt-1" href="javascript:" @click="openCostEstimationPopup(true, null)" title="Add Cost Estimation">
                                                                            <i class="fa fa-list-ul" aria-hidden="true"></i>
                                                                        </a>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="description">Description</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="plan.description"></editor>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>Responsible Person</label>
                                                                <select v-model="plan.responsibilityId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>                                                        
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="status">Status</label>
                                                                <select v-model="plan.statusId"  class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div v-if="elements.editMode" class="col-6">
                                                            <div class="form-group">
                                                                <label for="deliverables">Task Plan Link</label>
                                                                <multiselect v-model="plan.ermList" :options="ermLookup" name="ermLookup" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                            </div>                             
                                                        </div>
                                                        <div v-if="!elements.editMode && !elements.viewMode" class="col-3">
                                                            <div class="form-group">
                                                                <label class="v-hidden" for="status">Task Plan</label>
                                                                <b-form-checkbox id="checkbox-1" v-model="plan.addDevPlan" name="checkbox-1" >
                                                                  Link Task Plan
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="savePlan" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="float-right my-2 mr-2">
                                        <a class="action-link ml-1" href="javascript:" @click="openRequirementSpecLinkPopup(true, null)" title="Backlogs">
                                            <i class="fa fa-list-ul mr-1" aria-hidden="true"></i>Plan Backlogs
                                        </a>
                                    </div>
                                    <div class="float-right my-2 mr-2">
                                        <a class="action-link ml-1" href="javascript:" @click="openEffortEstimationPopup(true, null)" title="Effort Estimation">
                                            <i class="fa fa-list-ul mr-1" aria-hidden="true"></i> {{ refErmTitle }}
                                        </a>
                                    </div>
                                    <div class="float-right my-2">
                                        <download-excel :data="plans" :fields="planExcelFields" :name="this.ermTitle">
                                            <a href="javascript:" @click=""><i class="fa fa-file-excel-o pr-2 green-color" aria-hidden="true"></i></a>
                                        </download-excel>
                                    </div>                                                                                                                            
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="planTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="plans" @row-selected="onRowSelected" :fields="planFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(phaseTitle)="data">
                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(effort)="data">
                                    <div>{{ data.item.effort }} {{ getEffortType(effortUOM, data.item.effortType) }}</div>
                                </template>
                                <template v-slot:cell(estimatedCost)="data">
                                    {{ currencySymbol }} {{ data.value }}
                                </template>
                                <template v-slot:cell(description)="data">
                                    <span class="tooltiptext" href="javascript:" v-html="data.value"></span>
                                </template>
                                <template v-slot:cell(isTentative)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Reopened'" variant="warning">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                      <b-badge v-else variant="primary">{{data.value}}</b-badge>
                                    </div>
                                </template>

                                <template v-slot:cell(actions)="data">
                                    <a class="action-link ml-1" href="javascript:" @click="openRequirementSpecLinkPopup(false, data.item)" title="Backlogs">
                                        <i class="fa fa-list-ul mr-2" aria-hidden="true"></i>
                                    </a>
                                    <!-- <a href="javascript:" title="Effort Estimation" class="mr-2 action-link" @click="openEffortEstimationPopup(false, data.item)"><i class="fa fa-list-ul"></i></a>
                                    <a href="javascript:" title="Cost Estimation" class="mr-2 action-link" @click="openCostEstimationPopup(false, data.item.id)"><i class="fa fa-calculator"></i></a> -->
                                    <a href="javascript:" title="View" class="action-link" @click="viewItemDetails(data.item)"><i class="grey fa fa-eye p-0"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Edit" class="action-link" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Delete" class="action-link" @click="deletePlan(data.item.id)" v-b-modal.deletePlan><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Plans found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <div class="separator mb-2 mt-0"></div>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="plans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>

                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Plan Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{planDetail.planId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-5 pl-0">
                                                <b-input-group prepend="Status" class="mb-3">
                                                    <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="planDetail.status"></b-form-input>
                                                    <select v-else v-model="planDetail.statusId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updatePlan" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="location">Title</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.planDetail.phaseTitle.$error }">
                                                        <b-form-input v-model="planDetail.phaseTitle" :disabled="!elements.editMode"  maxlength="100" placeholder="Enter Title"></b-form-input>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.planDetail.phaseTitle.$error && !$v.planDetail.phaseTitle.required" class="text-danger">Please enter plan title</label>
                                                    <label v-if="$v.planDetail.phaseTitle.$error && !$v.planDetail.phaseTitle.maxLength" class="text-danger">Plan Title must not exceed {{$v.planDetail.phaseTitle.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
<!--                                             <div class="col-6">
                                                <div class="form-group">
                                                    <label for="process">Process</label>
                                                    <b-input-group>
                                                        <select v-model="planDetail.processId" :disabled="!elements.editMode" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in processes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <b-input-group-append class="append-0">
                                                            <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="process">Activity</label>
                                                    <b-input-group>
                                                        <select v-model="planDetail.activityId" :disabled="!elements.editMode" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <b-input-group-append class="append-0">
                                                            <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            <a :title="activityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div> -->
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Start Date</label>
                                                    <input type="date" v-model="planDetail.startDate" class="form-control" :disabled="!elements.editMode">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>End Date</label>
                                                    <input type="date" v-model="planDetail.endDate" class="form-control" :disabled="!elements.editMode">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card-body p-0 mb-3">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.description>
                                                                <label>Description</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="description" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :disabled="!elements.editMode" :toolbar="toolbar" :init="initObj" v-model="planDetail.description"></editor>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label for="deliverables">Deliverables</label>
                                                    <multiselect v-model="planDetail.deliverables" :options="deliverables" :disabled="!elements.editMode" name="deliverables" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                </div>
                                                <a :title="deliverableLookupTitle" class="setting-icon" href="javascript:" v-b-modal.deliverableModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                            </div> -->
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Effort</label>
                                                    <b-input-group>
                                                        <b-form-input type="number" min="0" v-model="planDetail.effort" :disabled="!elements.editMode"></b-form-input>
                                                        <b-input-group-append class="append-60">
                                                            <select v-model="planDetail.effortType" :disabled="!elements.editMode" class="form-control">
                                                                <!-- <option value="">Select</option> -->
                                                                <option v-for="(info, index)  in effortUOM" :key="info.id" :value="info.id">{{info.name}}</option>
                                                            </select>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <!-- <div class="row no-gutters">
                                                        <div class="col-6 col-md-9 col-lg-10 col-xl-11"> -->
                                                            <!-- <b-input-group size="sm" append="Man Hours">
                                                                <b-form-input type="number" min="0" v-model="planDetail.effort" :disabled="!elements.editMode"></b-form-input>
                                                            </b-input-group> -->
                                                        <!-- </div>
                                                        <div class="col d-flex align-middle">
                                                            <a class="action-link ml-1 pt-1" href="javascript:" @click="openEffortEstimationPopup(false, planDetail)" title="Add Effort Estimation">
                                                                <i class="fa fa-list-ul" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Estimated Cost</label>
                                                    <!-- <div class="row no-gutters">
                                                        <div class="col-6 col-md-9 col-lg-10 col-xl-11"> -->
                                                            <VhextMoneyControl :value="Number(planDetail.estimatedCost)" :disabled="!elements.editMode" :key="vhextMoneyControlKey" @money-input="moneyControlInput" />
                                                        <!-- </div>
                                                        <div class="col d-flex align-middle">
                                                            <a class="action-link ml-1 pt-1" href="javascript:" @click="openCostEstimationPopup(false, planDetail.id)" title="Add Cost Estimation">
                                                                <i class="fa fa-list-ul" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Responsible Person</label>
                                                    <select v-model="planDetail.responsibilityId" class="form-control" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="deliverables">Task Plan Link</label>
                                                    <multiselect v-model="planDetail.ermList" :disabled="!elements.editMode" :options="ermLookup" name="ermLookup" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                </div>                             
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Deliverables">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-roleResp>
                                                    <span v-if="elements.delEditMode">Edit Deliverable</span>
                                                    <span v-else>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Deliverable
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-roleResp" accordion="my-accordion" v-model="elements.delVisible" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="form-row">
                                                        <div class="col-12 col-lg-6">
                                                            <div class="form-group">
                                                                <label for="role">Deliverable</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.projectDeliverable.deliverableId.$error }">
                                                                    <select v-model="projectDeliverable.deliverableId" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in deliverables" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.projectDeliverable.deliverableId.$error && !$v.projectDeliverable.deliverableId.required" class="text-danger">Please Enter Deliverable</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-lg-6">
                                                            <div class="form-group">
                                                                <label for="role">Deliverable Type</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.projectDeliverable.refEntityId.$error }">
                                                                    <select @change="getEntityErmList" v-model="projectDeliverable.refEntityId" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in planRequirementLookup" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.projectDeliverable.refEntityId.$error && !$v.projectDeliverable.refEntityId.required" class="text-danger">Please select Deliverable Type</label>
                                                            </div>
                                                        </div>
                                                        <div v-if="projectDeliverable.refEntityId" class="col-12">
                                                            <div class="form-group">
                                                                <label for="responsibility">{{ getEntityName }}</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.projectDeliverable.refErmId.$error }">
                                                                    <select v-model="projectDeliverable.refErmId" class="form-control" :class="{ 'is-invalid': $v.projectDeliverable.refErmId.$error }">
                                                                        <option value="">Select {{ getEntityName }}</option>
                                                                        <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.projectDeliverable.refErmId.$error && !$v.projectDeliverable.refErmId.required" class="text-danger">Please select {{ getEntityName }}</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <select v-model="projectDeliverable.statusId" class="form-control" id="statusId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in prjDelStatusLookup" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="dueDate">Due Date</label>
                                                                <input type="date" v-model="projectDeliverable.dueDate" class="form-control" id="dueDate">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearProjectDeliverable" class="btn btn-sm btn-outline-primary mb-3 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                    <button type="submit" @click="saveProjectDeliverable" class="btn btn-sm btn-primary mb-3 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div class="mt-3">
                                            <div class="card mt-1" v-for="deliverable in projectDeliverableList">
                                                <div class="card-body p-2" v-if="deliverable.id !== projectDeliverable.editId">
                                                    <div class="row" >
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Deliverable" @click="deleteProjectDeliverable(deliverable.id)" data-toggle="modal" v-b-modal.deleteProjectDeliverable><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Deliverable" @click="editProjectDeliverable(deliverable)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <label >{{ deliverable.deliverable }} 
                                                                    <b-badge v-if="deliverable.status" variant="primary" class="ml-2">{{ deliverable.status }}</b-badge>
                                                                </label>
                                                                <p>Due Date: {{ deliverable.dueDate | formatDate }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Estimation Summary">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card mb-2">
                                                    <div class="card-body px-1 py-2">
                                                        <p class="card-text mb-1">
                                                            <span class="font-weight-bold">Effort Efficiency:</span>
                                                            <span class="float-right" :class="getEffortPercentageClass">{{ getEffortEfficiencyPercentage }}</span>
                                                        </p>
                                                        <p class="card-text mb-1">
                                                            <span class="float-left">Estimated: {{ planDetail.effort ? planDetail.effort : 0 }} {{ getEffortType(effortUOM, planDetail.effortType) }}</span>
                                                            <span class="float-right">Actual: {{ actualPlanSummary.actEffortMins ? (actualPlanSummary.actEffortMins/60).toFixed(1) : 0 }} hours</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card mb-2">
                                                  <div class="card-body px-1 py-2">
                                                        <p class="card-text mb-1">
                                                            <span class="font-weight-bold">Cost Efficiency:</span>
                                                            <span class="float-right" :class="getCostPercentageClass">{{ getCostEfficiencyPercentage }}</span>
                                                        </p>
                                                        <p class="card-text mb-1">
                                                            <span class="float-left">Estimated: {{ currencySymbol }}{{ planDetail.estimatedCost ? planDetail.estimatedCost : 0 }}</span>
                                                            <span class="float-right">Actual: {{ currencySymbol }}{{ actualPlanSummary.actualCost ? actualPlanSummary.actualCost : 0 }}</span>
                                                        </p>
                                                  </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="card mb-2">
                                                  <div class="card-body px-1 py-2">
                                                        <p class="card-text mb-1">
                                                            <span class="font-weight-bold">Resource:</span>
                                                            <!-- <span class="float-right" :class="getCostPercentageClass">{{ getCostEfficiencyPercentage }}</span> -->
                                                        </p>
                                                        <p class="card-text mb-1">
                                                            <b-table show-empty small stacked="md" sort-icon-left :items="planResourceList" :fields="resourceFields" responsive="sm" >
                                                                <!-- <template #cell(index)="data">
                                                                    {{ data.index + 1 }}
                                                                </template> -->
                                                                <template v-slot:cell(actEffortMins)="data">
                                                                    {{ convertToHM(data.value) }}
                                                                </template>
                                                                <template v-slot:empty="scope">
                                                                    <div class="text-center">No Resources found</div>
                                                                </template>
                                                                <template v-slot:emptyfiltered="scope">
                                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                </template>
                                                            </b-table>
                                                            <!-- <div v-for="item in planResourceList" class="d-block">
                                                                <span class="float-left">{{ item.assignedTo }}</span>
                                                                <span v-if="item.actEffortMins" class="float-right">{{ convertToHM(item.actEffortMins) }}</span>
                                                                <span v-else class="float-right">-</span>
                                                            </div> -->
                                                        </p>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" />
        <ProcessLookup ref="processLookupModal" :entity="entity" :processLookupTitle="processLookupTitle" @refresh-lookup="lookupData" />
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <DeliverableLookup ref="deliverableLookupModal" :entity="this.entity" :deliverableLookupTitle="deliverableLookupTitle" @refresh-lookup="lookupData" />
        <PlanDetailPopup v-if="showDialog" ref="planDetailRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <EffortEstimationLinkPopup ref="effortEstLinkPopupRef" :key="effortEstLinkPopupKey" :ermId="entityRecordMasterId" :erdId="erdId" :erdTitle="erdTitle" :refEntity="entity" refErmEntity="plan_master" :linkedEffortEstList="linkedEffortEstList" @onEffortEstimationAdd="onEffortEstimationAdd" @refresh-list="getPlans" />
        <CostEstimationLinkPopup ref="costEstLinkPopupRef" :key="costEstLinkPopupKey" :ermId="entityRecordMasterId" :erdId="erdId" :refEntity="entity" refErmEntity="plan_master" :linkedCostEstList="linkedCostEstList" @onCostEstimationAdd="onCostEstimationAdd" @refresh-list="getPlans" />
        <PlanBacklogPopup ref="planBacklogPopupRef" :key="planBacklogPopupKey" :planData="planData" :ermId="entityRecordMasterId" :erdId="erdId" />
        <DeleteComponent id="deletePlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Plan?" />
        <DeleteComponent id="deleteProjectDeliverable" :onYes="onYesProjectDeliverable" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Deliverable?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import DeliverableLookup from '@/components/popup/lookup/DeliverableLookup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import ProcessLookup from '@/components/popup/lookup/ProcessLookup.vue';
import PlanDetailPopup from '@/components/popup/itemdetail/PlanDetailPopup.vue';
import CostEstimationLinkPopup from '@/components/popup/action/CostEstimationLinkPopup.vue';
import EffortEstimationLinkPopup from '@/components/popup/action/EffortEstimationLinkPopup.vue';
import PlanBacklogPopup from '@/components/popup/action/PlanBacklogPopup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
import enummixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        Multiselect,
        Editor,
        ActivityLookup,
        DeliverableLookup,
        ProcessActivityLookup,
        ProcessLookup,
        PlanDetailPopup,
        CostEstimationLinkPopup,
        EffortEstimationLinkPopup,
        PlanBacklogPopup,
        VhextMoneyControl,
    },

    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false,
                delEditMode: false,
                delVisible: false,
            },
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,

            vhextMoneyControlKey: 100,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            entity: 'plan',
            entityRecordMasterId: '',
            ermTitle: localStorage.getItem("ermTitle"),

            effortEstLinkPopupKey: 100,
            costEstLinkPopupKey: 200,
            planBacklogPopupKey: 300,
            
            processLookupTitle: 'Process Settings',
            activityLookupTitle: 'Activity Settings',
            processActivityLookupTitle: 'Process / Activity Settings',
            deliverableLookupTitle: 'Deliverables',

            ermIsFreeze: localStorage.getItem("ermIsFreeze"),

            mainTableArray: [],

            erdId: null,
            erdTitle: null,
            linkedEffortEstList: null,
            linkedCostEstList: null,
            refErmTitle: '',

            actualPlanSummary: {
                actEffortMins: '',
                actualCost: '',
            },
            planResourceList: [],

            planData: {},

            effortText: '',
            effortTextDet: '',
            // effortUOM: [
            //     { name: "Man Hours", id: 1 },
            //     { name: "Man Days", id: 2 },
            //     { name: "Man Months", id: 3 },
            //     { name: "Man Years", id: 4 },
            // ],

            plan: {
                id: '',
                planId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                phaseTitle: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },
                processId: '',
                process: '',
                activityId: '',
                activity: '',
                deliverables: '',
                effort: '',
                effortType: 1,
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                estimatedStartDate: '',
                estimatedEndDate: '',
                responsibilityId: '',
                responsibility: '',
                description: '',
                isTentative: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                deliverables: [],
                addDevPlan: false,
                ermList: [],
                createdById: localStorage.getItem("userId"),
                effortEstimationList: [],
            },

            planDetail: {
                id: '',
                planId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                phaseTitle: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },
                processId: '',
                process: '',
                activityId: '',
                activity: '',
                description: '',
                deliverables: '',
                effort: '',
                effortType: 1,
                estimatedCost: '',
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                estimatedStartDate: '',
                estimatedEndDate: '',
                responsibilityId: '',
                responsibility: '',
                isTentative: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                deliverables: [],
                addDevPlan: false,
                createdById: localStorage.getItem("userId"),
                effortEstimationList: [],
                costEstimationList: [],
            },

            projectDeliverable: {
                id: '',
                projectDeliverableId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                deliverableId: '',
                refEntityId: '',
                entityRecordMasterId: '',
                erdId: '',
                refErmId: '',
                refErdId: '',
                statusId: '',
                dueDate: '',
                createdById: localStorage.getItem("userId"),
                editId: '',
            },

            phaseText: 'Phase',
            notEnabled: true,
            projectDeliverableList: [],
            planRequirementLookup: [],
            prjDelStatusLookup: [],
            entityERMLookup: [],
            ermEffortEstRefLookup: [],
            processes: [],
            activities: [],
            deliverables: [],
            ermLookup: [],
            processActivities: [],
            users: [],
            statuses: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            plans: [],
            planFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'planId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'phaseTitle', label: 'Title', sortable: true, sortDirection: 'desc' },
                // { key: 'process', label: 'Process', sortable: true, sortDirection: 'desc' },
                // { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Description', tdClass: 'w-30', sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'effort', label: 'Effort', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Cost', sortable: true, sortDirection: 'desc' },
                { key: 'responsibility', label: 'Responsible Person', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            resourceFields: [
                // { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'assignedTo', label: 'Resource', sortable: true, sortDirection: 'desc' },
                { key: 'actEffortMins', label: 'Effort', sortable: true, sortDirection: 'desc' },
            ],
            planExcelFields:{
                "Id":"planId",
                "Phase Title":"phaseTitle",
                "Process":"process",
                "Activity":"activity",
                "Start Date":"startDate",
                "End Date":"endDate",
                "Responsible Person":"responsibility",
                "Status":"status",
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            }            
        }
    },
    computed: {
        getCostPercentage() {
            const efficiency = this.planDetail.estimatedCost ? (((this.actualPlanSummary.actualCost ?? 0) / this.planDetail.estimatedCost) * 100) : 0;
            return this.actualPlanSummary.actualCost ? efficiency.toFixed(2) : 0;
        },
        getCostEfficiencyPercentage() {
            const efficiency = this.planDetail.estimatedCost ? (((this.actualPlanSummary.actualCost ?? 0) / this.planDetail.estimatedCost) * 100) : 0;
            return this.actualPlanSummary.actualCost ? efficiency.toFixed(2) + '%' : 'N/A';
        },
        getCostPercentageClass() {
            return {
                'text-success': this.getCostPercentage <= 100,
                'text-danger': this.getCostPercentage > 100,
                '': this.getCostPercentage == 0,
            };
        },
        getEffortPercentage() {
            let actEffortHrs = this.actualPlanSummary.actEffortMins ? (this.actualPlanSummary.actEffortMins/60).toFixed(1) : 0;
            const efficiency = this.planDetail.effort ? ((actEffortHrs / this.planDetail.effort) * 100) : 0;
            return actEffortHrs ? efficiency.toFixed(2) : 0;
        },
        getEffortEfficiencyPercentage() {
            let actEffortHrs = this.actualPlanSummary.actEffortMins ? (this.actualPlanSummary.actEffortMins/60).toFixed(1) : 0;
            const efficiency = this.planDetail.effort ? ((actEffortHrs / this.planDetail.effort) * 100) : 0;
            return actEffortHrs ? efficiency.toFixed(2) + '%' : 'N/A';
        },
        getEffortPercentageClass() {
            return {
                'text-success': this.getEffortPercentage <= 100,
                'text-danger': this.getEffortPercentage > 100,
                '': this.getEffortPercentage == 0,
            };
        },
        getEntityName() {
            let resultVal = '';
            if (this.projectDeliverable.refEntityId) {
                let item = this.planRequirementLookup.find(data => data.id == this.projectDeliverable.refEntityId);
                resultVal = item ? (item.value + ' Doc') : 'Document';
            }
            return resultVal;
        },
    },
    mounted() {
        this.initialSettings();
        this.getPlans();
        this.lookupData();
        this.lookupDataPrjDel();
    },
    validations: {
        plan: {
            phaseTitle: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            }
        },
        planDetail: {
            phaseTitle: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            }
        },
        projectDeliverable: {
            deliverableId: {
                required,
            },
            refEntityId: {
                required,
            },
            refErmId: {
                required,
            },
        },
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'plan';
            this.userId = localStorage.getItem("userId");
            this.effortEstLinkPopupKey++;
            this.planBacklogPopupKey++;
            this.costEstLinkPopupKey++;
        },

        getPlans: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.plans = result.planList;
                    this.totalRows = this.plans.length;
                    if (this.plans && this.plans.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.planDetail.id, this.mainTableArray, 'planTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity,
                userId: this.userId,
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.deliverables = result.deliverableLookup;
                    this.processes = result.processLookup;
                    this.activities = result.activityLookup;
                    this.allActivities = this.activities && this.activities.length>0 ? [...this.activities] : [];
                    this.users = result.userLookup;
                    this.statuses = result.statusLookup;
                    this.ermLookup = result.entityERMLookup;
                    this.plan.statusId = this.statuses[0].id;
                    this.ermEffortEstRefLookup = result.ermEffortEstRefLookup;
                    if (this.ermEffortEstRefLookup && this.ermEffortEstRefLookup.length > 0) {
                        this.refErmTitle = this.ermEffortEstRefLookup[0].value;
                    }
                })

        },
        lookupDataPrjDel: function() {
            let data = {
                // vhextAccountId: this.vhextAccountId,
                // projectId: this.projectId,
                // projectTypeId: this.projectTypeId,
                // entity: this.entity,
                // userId: this.userId,
                // entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/projectdeliverable/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.planRequirementLookup = result.planRequirementLookup;
                    this.prjDelStatusLookup = result.statusLookup;
                })

        },
        getEntityErmList: function() {
            if (this.projectDeliverable.refEntityId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    userId: this.userId,
                    entityId: this.projectDeliverable.refEntityId,
                }
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.entityERMLookup = result.entityERMLookup;
                        // this.totalRows = this.requirementSpecList.length;
                    })
                    .catch((error)=> {
                        this.showDetOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        savePlan: function() {
            this.plan.vhextAccountId = this.vhextAccountId;
            this.plan.projectId = this.projectId;
            this.plan.entityRecordMasterId = this.entityRecordMasterId;
            this.$v.plan.$touch();
            if (!this.$v.plan.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/add', this.plan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getPlans();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updatePlan: function() {
            this.$v.planDetail.$touch();
            if (!this.$v.planDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/update', this.planDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getPlans();
                            this.cancelEditMode();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        cancelEditMode() {
            this.elements.editMode = false;
        },
        deletePlan: function(id) {
            this.deleteParam.id = id;
        },

        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/plan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPlans();
                    }
                })
                .catch(error => {
                });
        },
        getProjectDeliverableList() {
            if (this.entityRecordMasterId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    entityRecordMasterId: this.entityRecordMasterId,
                    erdId: this.planDetail.id,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/projectdeliverable/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.projectDeliverableList = result.projectDeliverableList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        saveProjectDeliverable: function() {
            this.projectDeliverable.erdId = this.planDetail.id;
            this.projectDeliverable.entityRecordMasterId = this.planDetail.entityRecordMasterId;
            this.$v.projectDeliverable.$touch();
            if (!this.$v.projectDeliverable.$invalid) {
                if (this.elements.delEditMode) {
                    this.updateProjectDeliverable();
                } else {
                    this.addProjectDeliverable();
                }
            }
        },
        addProjectDeliverable: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/projectdeliverable/add', this.projectDeliverable)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectDeliverableList();
                        this.clearProjectDeliverable();
                    }
                })
        },
        updateProjectDeliverable: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/projectdeliverable/update', this.projectDeliverable)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectDeliverableList();
                        this.clearProjectDeliverable();
                    }
                })
        },
        clearProjectDeliverable: function() {
            this.elements.delEditMode = false;
            this.elements.delVisible = false;
            this.elements.showDropdownDeliverable = false;
            this.projectDeliverable.editId = '';
            this.projectDeliverable.id = '';
            this.projectDeliverable.projectDeliverableId = '';
            this.projectDeliverable.deliverableId = '';
            this.projectDeliverable.refEntityId = '';
            this.projectDeliverable.entityRecordMasterId = '';
            this.projectDeliverable.erdId = '';
            this.projectDeliverable.refErdId = '';
            this.projectDeliverable.refErmId = '';
            this.projectDeliverable.statusId = '';
            this.projectDeliverable.dueDate = '';
            this.$v.projectDeliverable.$reset();
        },
        editProjectDeliverable: function(data) {
            this.elements.delEditMode = true;
            this.elements.delVisible = true;
            this.projectDeliverable.id = data.id;
            this.projectDeliverable.projectDeliverableId = data.projectDeliverableId;
            this.projectDeliverable.deliverableId = data.deliverableId;
            this.projectDeliverable.deliverable = data.deliverable;
            this.projectDeliverable.refEntityId = data.refEntityId;
            this.projectDeliverable.entityRecordMasterId = data.entityRecordMasterId;
            this.projectDeliverable.erdId = data.erdId;
            this.projectDeliverable.refErdId = data.refErdId;
            this.projectDeliverable.refErmId = data.refErmId;
            this.projectDeliverable.statusId = data.statusId;
            this.projectDeliverable.status = data.status;
            this.projectDeliverable.dueDate = this.getDateValue(data.dueDate);
            this.getEntityErmList();
        },
        deleteProjectDeliverable: function(id) {
            this.deleteParam.id = id;
        },

        onYesProjectDeliverable: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/plan/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectDeliverableList();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.planDetailRef.getDetails(this.referencePopupId, null);
            })
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = true;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.erdId = null;
            this.erdTitle = null;
            this.plan.id = '';
            this.plan.planId = '';
            this.plan.phaseTitle = '';
            this.plan.processActivity.activityId = '';
            this.plan.processActivity.processId = '';
            this.plan.activityId = '';
            this.plan.activity = '';
            this.plan.processId = '';
            this.plan.process = '';
            this.plan.effort = '';
            this.plan.effortType = 1;
            this.plan.estimatedCost = '';
            this.plan.startDate = new Date().toISOString().substr(0, 10);
            this.plan.endDate = new Date().toISOString().substr(0, 10);
            this.plan.deliverables = [];
            this.plan.description = '';
            this.plan.statusId = this.statuses[0].id;
            this.plan.status = '';
            this.plan.responsibilityId = '';
            this.plan.responsibility = '';
            this.plan.createdDt = '';
            this.plan.lastUpdatedDt = '';
            this.plan.addDevPlan = false;
            this.plan.ermList = [];
            this.plan.effortEstimationList = [];
            this.plan.costEstimationList = [];
            this.$v.plan.$reset();
            this.resetActivityLookup();
            this.effortEstLinkPopupKey++;
            this.planBacklogPopupKey++;
            this.costEstLinkPopupKey++;
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.elements.showDetail = true;
            this.erdId = data.id;
            this.erdTitle = data.title;
            this.planDetail.id = data.id;
            this.planDetail.planId = data.planId;
            this.planDetail.phaseTitle = data.phaseTitle;
            this.planDetail.vhextAccountId = data.vhextAccountId;
            this.planDetail.projectId = data.projectId;
            this.planDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.planDetail.processActivity.activityId = data.activityId;
            this.planDetail.processActivity.processId = data.processId;
            this.planDetail.activityId = data.activityId;
            this.planDetail.activity = data.activity;
            this.planDetail.processId = data.processId;
            this.planDetail.process = data.process;
            this.planDetail.effort = data.effort;
            this.planDetail.effortType = data.effortType;
            this.planDetail.estimatedCost = data.estimatedCost;
            this.planDetail.startDate = data.startDate;
            this.planDetail.endDate = data.endDate;
            this.planDetail.deliverables = JSON.parse(data.deliverables);
            this.planDetail.description = data.description;
            this.planDetail.statusId = data.statusId;
            this.planDetail.status = data.status;
            this.planDetail.responsibilityId = data.responsibilityId;
            this.planDetail.responsibility = data.responsibility;
            this.planDetail.createdDt = data.createdDt;
            this.planDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.getRefErmList();
            this.getPlanSummary(this.planDetail.id);
            this.getProjectDeliverableList();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.elements.editMode = false;
                this.planDetail.id = data.id;
                this.planDetail.planId = data.planId.toString();
                this.planDetail.phaseTitle = data.phaseTitle;
                this.planDetail.vhextAccountId = data.vhextAccountId;
                this.planDetail.projectId = data.projectId;
                this.planDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.planDetail.processActivity.activityId = data.activityId;
                this.planDetail.processActivity.processId = data.processId;
                this.planDetail.activityId = data.activityId;
                this.planDetail.activity = data.activity;
                this.planDetail.processId = data.processId;
                this.planDetail.process = data.process;
                this.planDetail.effort = data.effort;
                this.planDetail.effortType = data.effortType;
                this.planDetail.estimatedCost = data.estimatedCost;
                this.planDetail.startDate = data.startDate;
                this.planDetail.endDate = data.endDate;
                this.planDetail.deliverables = JSON.parse(data.deliverables);
                this.planDetail.description = data.description;
                this.planDetail.statusId = data.statusId;
                this.planDetail.status = data.status;
                this.planDetail.responsibilityId = data.responsibilityId;
                this.planDetail.responsibility = data.responsibility;
                this.planDetail.createdDt = data.createdDt;
                this.planDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.getRefErmList();
                this.getPlanSummary(this.planDetail.id);
                this.getProjectDeliverableList();
            }
        },
        getProcessActivities: function(processId) {
            if (processId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    processId: processId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/activity',data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.activities = result.activityLookup;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                this.resetActivityLookup();
            }
        },
        getPlanSummary: function(planId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                planId: planId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/plansummary', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    let actualEffortData = result.actualEffortData;
                    let actualCostData = result.actualCostData;
                    this.planResourceList = result.planResourceList;

                    this.actualPlanSummary.actEffortMins = actualEffortData ? actualEffortData.actEffortMins : '';
                    this.actualPlanSummary.actualCost = actualCostData ? actualCostData.actualCost : '';
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        moneyControlInput: function(value) {
            this.plan.estimatedCost = value;
        },
        onEffortEstimationAdd(items, totalEffort) {
            if (this.elements.editMode) {
                this.planDetail.effortEstimationList = items;
                this.planDetail.effort = totalEffort;
                // this.planDetail.effortType = totalEffort;
            } else {
                this.plan.effortEstimationList = items;
                this.plan.effort = totalEffort;
                // this.plan.effortType = totalEffort;
            }
            // this.plan.activityId = data.activityId;
        },
        openEffortEstimationPopup(isNewPlan, data) {
            if(isNewPlan) {
                this.erdId = null;
                this.erdTitle = this.plan.phaseTitle;
                this.linkedEffortEstList = this.elements.editMode ? this.planDetail.effortEstimationList : this.plan.effortEstimationList;
                this.effortEstLinkPopupKey++;
                this.$nextTick(() => {
                    this.$bvModal.show('effortEstimationLinkPopup');
                })
            } else {
                this.clearData();
                this.erdId = data.id;
                this.erdTitle = data.title;
                this.linkedEffortEstList = null;
                this.effortEstLinkPopupKey++;
                console.log(data)
                this.$nextTick(() => {
                    this.$bvModal.show('effortEstimationLinkPopup');
                })
            }
        },
        onCostEstimationAdd(items, totalCost) {
            if (this.elements.editMode) {
                this.planDetail.costEstimationList = items;
                this.planDetail.estimatedCost = totalCost;
            } else {
                this.plan.costEstimationList = items;
                this.plan.estimatedCost = totalCost;
            }
            this.vhextMoneyControlKey++;
            // this.plan.activityId = data.activityId;
        },
        openCostEstimationPopup(isNewPlan, planId) {
            if(isNewPlan) {
                this.erdId = null;
                this.linkedCostEstList = this.elements.editMode ? this.planDetail.costEstimationList : this.plan.costEstimationList;
                this.costEstLinkPopupKey++;
                this.$nextTick(() => {
                    this.$bvModal.show('costEstimationLinkPopup');
                })
            } else {
                this.clearData();
                this.erdId = planId;
                this.linkedCostEstList = null;
                this.costEstLinkPopupKey++;
                this.$nextTick(() => {
                    this.$bvModal.show('costEstimationLinkPopup');
                })
            }
        },
        openRequirementSpecLinkPopup(isNewPlan, data) {
            if(isNewPlan) {
                this.erdId = null;
                this.erdTitle = this.plan.phaseTitle;
                this.linkedEffortEstList = this.elements.editMode ? this.planDetail.effortEstimationList : this.plan.effortEstimationList;
                this.planBacklogPopupKey++;
                this.$nextTick(() => {
                    this.$bvModal.show('planBacklogPopup');
                })
            } else {
                this.clearData();
                this.planData = data;
                this.erdId = data.id;
                this.erdTitle = data.title;
                this.linkedEffortEstList = null;
                this.planBacklogPopupKey++;
                console.log(data)
                this.$nextTick(() => {
                    this.$bvModal.show('planBacklogPopup');
                })
            }
        },
        getRefErmList: function() {
            let data = {
                refErmId: this.entityRecordMasterId,
                refErdId: this.planDetail.id
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/refermerd/lookup/all', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.planDetail.ermList = result.refErmLookup;
                })
        },
        resetActivityLookup() {
            this.activities = this.allActivities && this.allActivities.length>0 ? [...this.allActivities] : [];
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        routePage() {
            localStorage.setItem('entityRecordMasterId', this.entityRecordMasterId);
            this.$router.push('/prjmpl');
        },
    }
}
</script>