<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Fin Day End</h4>
                        </div>
                        <div class="col-sm-4 text-right">
                            <!-- <p class="mb-0 fs-4">{{ workingDate | formatFullDate }}</p> -->
                        </div>
                        <!-- <div class="col-sm-2 text-right">
                            <b-button class="addnew-quicktassk-btn ml-2" @click="openWorkingDay" v-b-modal.setWorkingDayPopup variant="outline-secondary" size="sm"><i class="fa fa-calendar-o pr-1"></i>Daily Work Schedule Setting</b-button>
                        </div> -->
                        <div class="col-sm-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row justify-content-md-center">
                <div class="col-12 col-md-10 col-lg-6 col-xl-6 ">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row text-center">
                                    <div class="col-4 text-right">
                                        <a href="javascript:" @click="changeDateRange('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                    </div>
                                    <div class="col-4 text-center">
                                        <h6 class="mt-1" v-if="currentStartDate">
                                            {{ currentStartDate | formatDateAlt}}
                                        </h6>
                                    </div>
                                    <div class="col-4 text-left">
                                        <a href="javascript:" @click="changeDateRange('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link float-left"></i></a>
                                        <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer float-right mr-1" title="Select Date">
                                            <i class="fa fa-calendar text-light"></i>
                                            <div class="dropdown-menu dropdown-primary p-0" aria-labelledby="dropdownCal">
                                                <b-calendar v-model="currentStartDate" today-variant="primary" selected-variant="secondary" @context="dateList(currentStartDate)">
                                                </b-calendar>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="separator mb-2"></div>
                                <!-- <div class="separator mx-2"></div> -->
                                <!-- <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                    Financial Transactions
                                </label> -->
                                <div v-if="financialTxnList.length == 0" class="action-card pointer my-0" @click="redirectPage(financialTxn)">
                                    <div class="action-card-body text-center">
                                        No record found
                                    </div>
                                </div>
                                <template v-else>
                                    <div class="row">
                                        <div class="col-12 text-left mb-2">
                                            <div class="card my-0">
                                                <div class="card-body p-3">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <label class="text-muted d-block">
                                                                Summary
                                                            </label>
                                                        </div>
                                                        <div class="col-6">
                                                            <button v-if="isFreeze" type="submit" title="Undo" v-b-modal.finDayEndConfirmDialog @click.prevent="finDayEndConfirmDialog(2)" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 float-right">
                                                                Undo
                                                            </button>
                                                            <button v-else type="submit" title="Day End" v-b-modal.finDayEndConfirmDialog @click.prevent="finDayEndConfirmDialog(1)" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 float-right">
                                                                Post Day End
                                                            </button>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-xl-3 col-md-4 col-5">
                                                                            <label>Income</label>
                                                                        </div>
                                                                        <div class="col">
                                                                            <span class="text-success mr-2">
                                                                                <i class="fa fa-arrow-up pr-1" aria-hidden="true"></i>
                                                                                {{ currency && currency!== 'null' ? currency : '' }} {{ getTxnSummary('totalIncome') ? getTxnSummary('totalIncome') : 0 }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-xl-3 col-md-4 col-5">
                                                                            <label>Expense</label>
                                                                        </div>
                                                                        <div class="col">
                                                                            <span class="text-danger">
                                                                                <i class="fa fa-arrow-down pr-1" aria-hidden="true"></i>
                                                                                {{ currency && currency!== 'null' ? currency : '' }} {{ getTxnSummary('totalExpense') ? getTxnSummary('totalExpense') : 0 }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 text-right my-auto">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <label>Balance</label>
                                                                </div>
                                                                <div class="col float-right">
                                                                    <span class="badge pointer" :class="summaryBalance > 0 ? 'badge-success' : 'badge-danger'">
                                                                        <i class="fa pr-1" :class="summaryBalance > 0 ? 'fa-arrow-up' : 'fa-arrow-down'" aria-hidden="true"></i>
                                                                        {{ currency && currency!== 'null' ? currency : '' }} {{ summaryBalance ? summaryBalance : 0 }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator mb-2"></div>
                                    <div v-for="financialTxn in financialTxnList" class="row">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body dropdown show">
                                                    <!-- <a type="button" class="float-right pt-2 mr-2 ml-1" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink"> -->
                                                        <!-- <a class="dropdown-item addnew-tassk py-1" @click.prevent="viewItemDetails(financialTxn)" href="javascript:"><i class="fa fa-list-alt pr-2"></i>Transaction Details</a> -->
                                                        <!-- <a v-if="financialTxn.transactionById==userId" class="dropdown-item addnew-tassk py-1" @click.prevent="openFinTxnPopup(financialTxn, true, false)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Transaction</a>
                                                        <a v-if="financialTxn.transactionById==userId" class="dropdown-item py-1" @click.prevent="deleteFinancialTxn(financialTxn.id)" v-b-modal.deleteFinancialTxn href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Transaction</a>
                                                        <a v-if="financialTxn.transactionById==userId" class="dropdown-item py-1" @click.prevent="memorizeFinTxn(financialTxn)" href="javascript:"><span class="fs-11 pr-1">M<sup>+</sup></span>{{ financialTxn.isMemorized ? 'Remove from Memory' : 'Add To Memory' }} Transaction</a>
                                                        <a v-if="financialTxn.transactionById==userId" class="dropdown-item py-1" @click.prevent="showFinTxnPayRecLinkPopup(financialTxn)" href="javascript:"><i class="fa fa-plus pr-2"></i>Link {{ financialTxn.amountTypeCode == '1' ? ' Receivable' : ' Payable' }}</a> -->
                                                    <!-- </div> -->
                                                    <div class="pt-2 pb-1">
                                                        <div class="card-media-body-top">
                                                            <div class="row">
                                                                <div class="col">
                                                                    <a class="tooltiptext action-link" href="javascript:" @click="redirectPage(financialTxn)">{{ financialTxn.ermTitle }}</a>
                                                                </div>
                                                                <div class="col-3 text-right">
                                                                    <span class="text-success" >
                                                                        <i class="fa fa-arrow-up pr-1" aria-hidden="true"></i>
                                                                        {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.totalIncome }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-3 text-right">
                                                                    <span class="text-danger">
                                                                        <i class="fa fa-arrow-down pr-1" aria-hidden="true"></i>
                                                                        {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.totalExpense }}
                                                                    </span>
                                                                </div>
                                                                <div class="col-3 text-right">
                                                                    <span v-if="(financialTxn.totalIncome + financialTxn.totalExpense) > 0" class="badge badge-success">
                                                                        <i class="fa fa-arrow-up pr-1" aria-hidden="true"></i>
                                                                        {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.totalIncome + financialTxn.totalExpense }}
                                                                    </span>
                                                                    <span v-else class="badge badge-danger">
                                                                        <i class="fa fa-arrow-down pr-1" aria-hidden="true"></i>
                                                                        {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.totalIncome + financialTxn.totalExpense }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <!-- <button type="submit" title="Day End" v-b-modal.finDayEndConfirmDialog @click.prevent="finDayEndConfirmDialog(financialTxn)" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 float-right">
                                                                Day End
                                                            </button> -->
                                                        </div>
                                                        <!-- <div class="divider-line"></div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="row card-media-object-social-list">
                                                                    <div class="col-6 p-0 text-left" :class="{'col-sm-4' : daysToShow == 7 }">
                                                                        <div v-if="financialTxn.transactionBy">
                                                                            <b-badge v-if="daysToShow > 5" class="task-badge float-left" :title="`Transaction By: ${financialTxn.transactionBy}`"> {{ financialTxn.transactionBy | getAcronym }} </b-badge>
                                                                            <b-badge v-if="daysToShow <= 5" class="task-badge float-left" :title="`Transaction By`"> {{ financialTxn.transactionBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 px-0 text-right" :class="{'col-sm-8' : daysToShow == 7 }">
                                                                        <div v-if="(financialTxn.transactionDt != null)">
                                                                            <span class="fs-12" :title="`Transaction Date: ${formatDate(financialTxn.transactionDt)}`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ financialTxn.transactionDt | formatShortDate }} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-3 col-xl-3">
                    <div class="card h-100">
                        <div class="card-body mx-auto pt-4">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <DialogComponent id="finDayEndConfirmDialog" :onYes="onYesDialog" :returnParams="dialogBoxParam" title="Day End Closing" :message="`Are you sure to ${dialogBoxParam.id==1 ? 'Close Day End' : 'Undo Day End Close'} ?`" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [enummixin],
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
            },

            isFreeze: false,
           
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            currency: localStorage.getItem('currency'),

            searchDate: this.getDateValue(new Date()),
            workingDate: this.getDateValue(new Date()),

            // currentStartDate: this.getDateValue(new Date()),

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            financialTxnList: [],

            dayEndClosingData: {
                id: '',
                dayEndClosingId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                totalIncome: '',
                totalExpense: '',
                netAmount: '',
                closingDate: '',
                active: 1,
                createdById: localStorage.getItem("userId"),
                actionState: 1,
                // vhextAccountId: localStorage.getItem("vhextAccountId"),
                // userId: localStorage.getItem("userId"),
                // transactionDt: this.getDateValue(new Date()),
            },

            attendanceTimelineList: [],
            attendanceTimelineFields: [
                { key: 'index', label: 'SN' },
                { key: 'empTimelineStateEnum', label: 'Timeline State', sortable: true, sortDirection: 'desc' },
                { key: 'empTimelineDt', label: 'Start Time', sortable: true, sortDirection: 'desc' },
                { key: 'nextEmpTimelineDt', label: 'Stop Time', sortable: true, sortDirection: 'desc' },
                { key: 'timeDiff', label: 'Time Diff (HH:MM)', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Action'},
            ],

            selectMode: 'single',
            totalRows: 0,
            totalRowsEmp: 0,
            currentPage: 1,
            currentPageEmp: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            dialogBoxParam: {
                id: 0,
            },
        }
    },
    computed: {
        currentStartDate: {
            get() {
                return this.$store.state.actionItemData.selectedDate ? this.$store.state.actionItemData.selectedDate : this.getDateValue(new Date());
            },
            set(value) {
                this.$store.state.actionItemData.selectedDate = value;
            }
        },
        summaryBalance() {
            return parseFloat(this.getTxnSummary('totalIncome')) + parseFloat(this.getTxnSummary('totalExpense'));
        },
    },
    mounted() {
        this.getFinancialTxns();
        this.getDayEndClosingList();
    },
    methods: {
        getFinancialTxns: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                transactionDt: this.getDateValue(this.currentStartDate),
            }
            this.workingDate = this.currentStartDate;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnsummarybygroup', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.financialTxnList = result.finTxnSummary;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getDayEndClosingList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                closingDate: this.getDateValue(this.currentStartDate),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/dayendclosing/check', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.isFreeze) {
                        this.isFreeze = true;
                    } else {
                        this.isFreeze = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        finDayEndConfirmDialog: function(actionState) {
            this.dayEndClosingData.vhextAccountId = this.vhextAccountId;
            this.dayEndClosingData.userId = this.userId;

            this.dialogBoxParam.id = actionState;
        },
        onYesDialog: function(id) {
            this.finDayEndManage(this.dialogBoxParam.id);
        },
        finDayEndManage: function(actionState) {
            this.dayEndClosingData.totalIncome = this.getTxnSummary('totalIncome') ? this.getTxnSummary('totalIncome') : 0;
            this.dayEndClosingData.totalExpense = this.getTxnSummary('totalExpense') ? this.getTxnSummary('totalExpense') : 0;
            this.dayEndClosingData.netAmount = this.dayEndClosingData.totalIncome - this.dayEndClosingData.totalExpense;
            this.dayEndClosingData.closingDate = this.getDateValue(this.currentStartDate);
            this.dayEndClosingData.actionState = actionState;

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/dayendclosing/add', this.dayEndClosingData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getFinancialTxns();
                        this.getDayEndClosingList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 

        },
        dateList(selectedDate) {
            this.currentStartDate = moment(selectedDate).toDate();
            this.getFinancialTxns();
            this.getDayEndClosingList();
        },       
        changeDateRange(page, number) {
            var startDate = new Date(this.currentStartDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.getFinancialTxns();
            this.getDayEndClosingList();
        },
        redirectPage(data) {
            localStorage.setItem("ermId", data.entityRecordMasterId);
            localStorage.setItem("ermTitle", data.ermTitle);

            this.$store.state.actionItemData.daysToShow = 1;
            this.$store.state.actionItemData.selectedDate = this.getDateValue(this.currentStartDate);
            this.$nextTick(() => {          
                this.$router.push('/landing/ftxbrd',);
            })
        },
        getTxnSummary(amount) {
            let summary;
            summary = this.financialTxnList.reduce((accum, item) => {
                return accum + (item[amount] ? parseFloat(item[amount]) : 0)
            }, 0.00)
            return summary ? summary.toFixed(2) : 0;
        },
    }
}
</script>