<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-sm-8">
                            <h4 class="pl-2 mb-0 py-1 fs-16">Employee Work Shift Management</h4>
                        </div>
<!--                         <div class="col-sm-5">
                            <div class="row text-right">
                                <div class="col-2">
                                    <label class="mb-0 mt-2"><i class="fa fa-filter action-link pr-2" aria-hidden="true"></i>Filter</label>
                                </div>
                                <div class="col-5">
                                    <select v-model="departmentId" @change="getEmployees" class="form-control" >
                                        <option value="">All Departments</option>
                                        <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                    </select>
                                </div>
                                <div class="col-5">
                                    <select v-model="workShiftId" @change="getEmployees" class="form-control" >
                                        <option value="">All Work Schedules</option>
                                        <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                    </select>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card h-100">
                        <div class="card-body px-3 pb-3 pt-1">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row view-filter mb-1">
                                    <div class="col-1">
                                        <label class="mb-0 mt-2"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter By</label>
                                    </div>                                    
                                    <div class="col-2">
                                        <select v-model="departmentId" @change="getEmployees" class="form-control" >
                                            <option value="">All Departments</option>
                                            <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-2 float-right">
                                        <select v-model="workShiftId" @change="getEmployees" class="form-control" >
                                            <option value="">All Work Schedules</option>
                                            <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-7 text-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>

<!--                                     <div class="col-7 text-right">
                                        <div class="row">
                                            <div class="col-5">
                                                <label class="mb-0 mt-1">
                                                    <span class="font-weight-bold">{{ selectedItems.length>0 ? `${selectedItems.length} Employee Selected` : '' }}</span>
                                                </label>
                                            </div>
                                            <div class="col-5">
                                                <select :disabled="selectedItems.length==0" v-model="employeeShiftId" class="form-control">
                                                    <option value="">Change Work Shift</option>
                                                    <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div class="col-2 text-left">
                                                <button type="submit" :disabled="selectedItems.length==0" @click="saveBulkData" class="sbtn btn btn-secondary btn-xs px-2 mr-1">Save</button>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
<!--                                     <b-table show-empty small outlined head-variant=:primary sticky-header="65vh" :select-mode="selectMode" sort-icon-left :items="employees" @row-selected="onRowSelected" :fields="employeeFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredEmp"> -->

                                <b-table ref="workScheduleMgmtTable" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="employeeWorkPatternList" @row-selected="onRowSelected" :fields="employeeWorkPatternFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:head(selected)="data">
                                        <div class="form-check">
                                            <label class="form-check-label pr-2" for="batch-select-checkbox">{{ batchSelectCheckBox ? 'Unselect All' : 'Select All' }}</label>
                                            <input class="" type="checkbox" v-model="batchSelectCheckBox" id="batch-select-checkbox" @click="handleBatchSelect" >
                                        </div>
                                    </template>
                                    <template #cell(selected)="data">
                                        <div class="form-check">
                                            <input class="" type="checkbox" :id="'chckbox'+data.index+1" v-model="data.rowSelected" @change="handleRowClick(data.index)">
                                            <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                        </div>
                                    </template>
<!--                                     <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>                                     -->
                                    <template v-slot:cell(workShift)="data">
                                        <div v-if="data.item.id == idEdit" class="action-link">
                                            <select v-model="workShift" @change="updateEntity(data.item.humanResourceId,workShift, 'work_shift_id')" class="form-control" id="workShiftId" @blur="cancelIdEdit()">
                                                <option :value="{id:null,value:null}">Select</option>
                                                <option v-for="(info, index) in workShifts" :value="{id:info.id,value:info.value}"
                                                >{{info.value}}</option>
                                            </select>                            
                                        </div>
                                        <div v-else class="action-link" @dblclick="idEdit=data.item.id;workShift.id=data.item.workShiftId;workShift.value=data.item.workShift">
                                            {{ data.item.workShift ? data.item.workShift : '-- Select Work Schedule --' }}
                                        </div>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Employees found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <div class="row">
                                    <div class="col-2">
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                    </div>
                                    <div class="col-5">
                                        <div class="row">
                                            <label class="col-auto my-1 pl-2">Change Work Shift</label>
                                            <div class="col">
                                                <select :disabled="selectedItems.length==0" v-model="employeeShiftId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in workShifts" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div class="col-auto text-left">
                                                <button type="submit" :disabled="selectedItems.length==0" @click="saveBulkData" class="sbtn btn btn-secondary btn-xs px-2">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <label class="mb-0 mt-1">
                                            <span class="font-weight-bold">{{ selectedItems.length>0 ? `${selectedItems.length} Employee Selected` : '' }}</span>
                                        </label>
                                    </div>
                                </div>
                            </b-overlay>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            showOverlay: false,
            isBusy: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
           
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entity: 'employee',

            workShiftId: '',
            employeeShiftId: '',
            departmentId: '',

            attachmentData: {},
            referencePopupId: 0,

            idEdit: '',
            workShift:{
                id: null,
                value: null,
            },

            employeeAttendance: {
                id: '',
                employeeAttendanceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                humanResourceId: '',
                workingDayId: '',
                leaveTypeId: '',
                leaveTimeTypeId: '',
                leaveTimeId: '',
                workingDate: '',
                workDayType: '',
                statusLookupId: '',
                createdById: localStorage.getItem("userId"),
            },

            departments: [],
            workShifts: [],
            selectedItems: [],
            batchSelectCheckBox: false,

            employeeWorkPatternList: [],
            employeeWorkPatternFields: [
                { key: 'selected', label: '', tdClass: 'table-status-col-size' },
                // { key: 'index', label: 'SN' },
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Employee Name', sortable: true, sortDirection: 'desc' },
                { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },
                { key: 'reportingTo', label: 'Reporting To', sortable: true, sortDirection: 'desc' },
                { key: 'workShift', label: 'Work Shift', sortable: true, sortDirection: 'desc', thClass: 'text-left',tdClass: 'text-left' },
            ],

            selectMode: 'multi',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    // computed: {
    //     totalPresent() {
    //         return this.employeeWorkPatternList.filter(data => data.statusLookupId == 0).length;
    //     },
    //     totalLeave() {
    //         return this.employeeWorkPatternList.filter(data => data.statusLookupId && (data.statusLookupId != 0)).length;
    //     },
    //     totalCount() {
    //         return this.employeeWorkPatternList.filter(data => data.statusLookupId != null).length;
    //     }
    // },
    mounted() {
        this.lookupData();
        this.getEmployees();
    },
    methods: {
        getEmployees: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                departmentId: this.departmentId,
                workShiftId: this.workShiftId,
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/workpattern/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    this.employeeWorkPatternList = result.employeeWorkPatternList;
                    this.totalRows = this.employeeWorkPatternList.length;

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/employeeattendance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.departments = result.departmentLookup;
                    this.workShifts = result.workShiftLookup;
                })
        },
        updateEntity: function(id, workShift, columnName) {
            let data = {
                id: id,
                entity: "human_resource",
                columnName: columnName,
                numberValue: workShift.id,
            }
            console.log(data);
            this.workShift.id = workShift.id;
            this.workShift.value = workShift.value;
            
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/numvalue/update', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.cancelIdEdit();

                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        saveBulkData: function() {
            let data={
                employeeShiftId: this.employeeShiftId,
                humanResourceData:this.selectedItems
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/bulk/update', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmployees();                       
                    }
                })
                .catch(error => {
                    console.log(error);
                });          
        },
        cancelIdEdit: function() {
            this.employeeWorkPatternList = this.employeeWorkPatternList.map(data => {
                if(data.id == this.idEdit) {
                    data.workShiftId = this.workShift.id;
                    data.workShift = this.workShift.value;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';
            this.workShift.id = null;
            this.workShift.value = null;

        }, 
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected(items) {
            this.selectedItems = items;
            this.batchSelectCheckBox = false;
        },
        handleBatchSelect() {
            if (this.batchSelectCheckBox) {
                this.$refs.workScheduleMgmtTable.clearSelected()
            } else {
                this.$refs.workScheduleMgmtTable.selectAllRows()
            }
        },
        handleRowClick(index) {
            this.batchSelectCheckBox = false;
            if (this.$refs.workScheduleMgmtTable.isRowSelected(index)) {
                this.$refs.workScheduleMgmtTable.unselectRow(index);
            }
            else {
                this.$refs.workScheduleMgmtTable.selectRow(index);
            }
        }
    }
}
</script>