<template>
    <div id="wrapper" class="h-100">
        <div id="login" class="h-100">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-sm-12 mx-auto my-auto">
                        <div class="align-middle login_div">
                            <div class="col-md-4 offset-md-4">
                                <div class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded-10 pos-rel">
                                    <a href="javascript:" class="logo text-center d-block mb-4 login-logo"><img src="@/assets/images/logo.png"></a>
                                    <h4 class="text-color text-center" style="text-transfomargin-top:10px">Reset Password</h4>
                                    <form>
                                        <div class="row pt-5">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.userData.$error }">
                                                                <input type="password" name="Password" placeholder="Enter a new password" maxlength="45" size="30"  v-model="userData.password" class="form-control box-none">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <div v-if="$v.userData.$error" class="invalid-feedback">
                                                                <span v-if="!$v.userData.password.required">Password is required</span>
                                                                <span v-if="!$v.userData.password.minLength">Password must be at least 6 characters</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.userData.confirmPassword.$error }">
                                                                <input type="password" v-model="userData.confirmPassword" maxlength="45" size="30" class="form-control" id="confirmPassword" placeholder="Confirm Password">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <div v-if="$v.userData.confirmPassword.$error" class="invalid-feedback">
                                                                <span v-if="!$v.userData.confirmPassword.required">Confirm Password is required</span>
                                                                <span v-else-if="!$v.userData.confirmPassword.sameAsPassword">Passwords must match</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="bg-slategray lt wrap-reset text-left" style="background-color:#fff !important;">
                                            <!-- <button type="button" @click="submitActivation" class="btn btn-primary pull-right">Submit</button> -->
                                            <button type="button" @click="submitPassword" class="btn btn-primary float-right mb-0 mr-2 pt-1 pb-1 pl-3 pr-3">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {

    computed: {
        activationKey() {
            return this.$route.params.key;
        }
    },
    data() {
        return {
            userData:{
                id: '',
                emailId: '',
                password:'',
                confirmPassword:''
            },
        }
    },
    validations: {
        userData: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        },
    },
    mounted() {
        this.getDecodedData();
    },
    methods: {
        getDecodedData: function() {
            let data = {
                key: String(this.activationKey)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/decode/userdata', data)
                .then((response) => {
                    let result = response.data;
                    this.userData.id = result.decodedData.userId;
                    this.userData.emailId = result.decodedData.emailId;
                })
        },
        submitPassword: function() {
            this.$v.userData.$touch();
            if (!this.$v.userData.$invalid) {
                this.changePassword();
            }
        },
        changePassword: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/user/forgotpassword/change', this.userData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.info({
                            title: 'Success',
                            message: result.message,
                            icon: "ico-success",
                            position: 'center'
                        });
                        this.$router.push('/');
                    }
                })
        },
    },

}
</script>