<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Financial Transaction</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a title="Board View" class="action-link" @click="$router.push('/landing/ftxbrd');">
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                            <div v-if="ermIsFreeze!='true'" id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Financial Txn</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit Txn</span>
                                            <span v-if="elements.viewMode">View Txn</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="title">Item Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.financialTxn.title.$error }">
                                                                <input type="text" maxlength="100" class="form-control" v-model="financialTxn.title" id="title" :class="{ 'is-invalid': $v.financialTxn.title.$error }" placeholder="Enter Item Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.minLength" class="text-danger">Item Title must have at least {{$v.financialTxn.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.required" class="text-danger">Please enter Item Title</label>
                                                            <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.maxLength" class="text-danger">Item Title must not exceed {{$v.financialTxn.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Account Head</label>
                                                            <select v-model="financialTxn.accountHeadId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in accountHeads" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="accountHeadLookupTitle" class="setting-icon" href="javascript:" v-b-modal.accountHeadModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>

                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="transactionDt">Txn Date</label>
                                                            <div>
                                                                <input v-model="financialTxn.transactionDt" type="date" class="form-control" placeholder="Transaction Date">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Txn Type</label>
                                                            <select v-model="financialTxn.transactionTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in financialTxnTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="finTxnTypeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.finTxnTypeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="financialTxnCategory">Category</label>
                                                            <select v-model="financialTxn.categoryId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in financialTxnCategories" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="categoryLookupTitle" class="setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <label>Amount</label>
                                                        <VhextMoneyControl :value="Math.abs(Number(this.financialTxn.amount))" @money-input="moneyControlInput" />
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Transaction By</label>
                                                            <select v-model="financialTxn.transactionById" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <AttachmentComponent ref="attachmentControl" @resetAttachmentStatus="resetAttachmentStatus" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                    </div>
                                                    <!--                                                     <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Accountable</label>
                                                            <select v-model="financialTxn.accountablePersonId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode" id="viewStatement">
                                                <div class="row">
                                                    <div class="col-12  col-sm-4">
                                                        <div class="form-group">
                                                            <label for="title">Item Title</label>
                                                            <input type="text" class="form-control" id="title" disabled v-model="financialTxn.title">
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-2">
                                                        <div class="form-group">
                                                            <label for="accountHead">Account Head</label>
                                                            <input type="text" class="form-control" id="accountHead" disabled v-model="financialTxn.accountHead">                                                        
                                                        </div>
                                                    </div>      
                                                    <div class="col-12  col-sm-2">
                                                        <div class="form-group">
                                                            <label for="transactionDt">Txn Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="financialTxn.transactionDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label for="type">Txn Type</label>
                                                            <input type="text" class="form-control" id="type" disabled v-model="financialTxn.type">
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-2">
                                                        <div class="form-group">
                                                            <label for="category">Category</label>
                                                            <input type="text" class="form-control" id="category" disabled v-model="financialTxn.category">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label for="amount">Amount</label>
                                                            <input type="text" class="form-control" id="amount" disabled v-model="financialTxn.amount">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <textarea class="form-control" id="description" v-model="financialTxn.description" disabled rows="5">
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="accountablePerson">Accountable</label>
                                                            <input type="text" class="form-control" id="accountablePerson" disabled v-model="financialTxn.accountablePerson">
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Attachment</label>
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" viewAttachment="true" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-3">
                                                        <div class="form-group">
                                                            <label for="createdDt">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="financialTxn.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-3">
                                                        <div class="form-group">
                                                            <label for="lastUpdatedDt">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="financialTxn.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3">
                                                    <i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>

                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="float-right my-2">
                                        <download-excel :data="financialTxns" :fields="financialTxnExcelFields" :name="this.ermTitle">
                                            <a href="javascript:" @click=""><i class="fa fa-file-excel-o pr-2 green-color" aria-hidden="true"></i></a>
                                        </download-excel>
                                    </div>                                                                                                                            
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="financialTxns" :fields="financialTxnFields" v-model="visibleRows" responsive="sm" foot-clone foot-variant=light no-footer-sorting :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(financialTransactionId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control" v-model="data.item.financialTransactionId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.financialTransactionId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.financialTransactionId" class="action-link"> {{ data.item.financialTransactionId }}</div>
                                </template>

                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(amount)="data">
                                    <div class="text-right">{{ data.value }}</div>
                                </template>
                                <!--                                 <template v-slot:foot()="data">
                                    <div class="text-right">{{totalExpenses.toFixed(2) }}</div>
                                    <span>{{ totalExpenses.toFixed(2) }}</span>
                                </template> -->
                                <!--                                 <template v-slot:custom-foot="data">
                                    <b-tr>
                                        <div class="text-right">{{totalExpenses.toFixed(2) }}</div> 
                                    </b-tr>
                                </template>                                
                                <template slot="Amount" >
                                  {{ totalExpenses.toFixed(2) }}
                                </template> -->
                                <template v-slot:cell(actions)="data">
                                    <div v-if="data.item.hasAttachment" class="dropdown dropright d-inline">
                                        <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="javascript:" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>
                                        <div class="dropdown-menu">
                                            <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                            <div v-if="attachmentList.length>0">
                                                <ul class="attachmentList-ul p-0 m-0">
                                                    <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                        <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <a href="javascript:" title="View" @click="attachmentLookup(data)">
                                        <i title="view attachment" class="fa fa-paperclip fa-lg" aria-hidden="true"></i>
                                    </a> -->
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a v-if="!data.item.isFreeze && ermIsFreeze!='true'" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="!data.item.isFreeze && ermIsFreeze!='true'" href="javascript:" title="Delete" @click="deleteFinancialTxn(data.item.id)" data-toggle="modal" v-b-modal.deleteFinancialTxn><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Financial Txn found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>


                                <template v-slot:foot()="data">
                                    <span></span>
                                </template>

                                <template v-slot:foot(financialTransactionId)="data">
                                    <span></span>
                                </template>
                                <template v-slot:foot(category)="data">
                                    <span class="text-right font-weight-bold">Sub Total :</span>
                                </template>
                                <template class="text-right" v-slot:foot(amount)="data">
                                    <div class="text-right font-weight-bold">
                                        <span class="text-right" text-align=right>{{subTotal.toFixed(2)}}</span>
                                    </div>
                                </template>
                                <template v-slot:foot(actions)="data">
                                    <div class="text-center font-weight-bold">
                                        <span class="text-right">TOT : {{totalAmount.toFixed(2)}}</span>
                                    </div>
                                </template>
                                <!--                                 <template v-slot:custom-foot="data">
                                    <div class="col-sm-12 text-right">
                                        <b-tr >
                                            <b-td>
                                                <b-input-group :prepend="subTotalPrependText" class="bg-transparent font-weight-bold">
                                                    <td>{{ subTotal.toFixed(2) }}</td>
                                                </b-input-group>
                                            </b-td>
                                            <b-td>
                                                <b-input-group :prepend="totalPrependText" class="bg-transparent font-weight-bold">
                                                    <td>{{ totalAmount.toFixed(2) }}</td>
                                                </b-input-group>
                                            </b-td>
    
                                        </b-tr>
                                    </div>
                                </template>
 -->
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                            <b-pagination v-if="financialTxns.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AccountHeadLookup ref="accountHeadModal" :entity="this.entity" :accountHeadLookupTitle="accountHeadLookupTitle" @refresh-lookup="lookupData" />        
        <FinTxnTypeLookup ref="finTxnTypeModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :finTxnTypeLookupTitle="finTxnTypeLookupTitle" @refresh-lookup="lookupData" />
        <CategoryLookup ref="categoryLookupModal" :projectTypeId="this.projectTypeId" :entity="this.categoryEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <ViewAttachmentPopup ref="attachmentModal" :entity="this.entity" :refRecordId="refRecordId" />
        
        <DeleteComponent id="deleteFinancialTxn" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Financial Txn?" />
        <FinTxnDetailPopup v-if="showDialog" ref="finTxnDetailRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AccountHeadLookup from '@/components/popup/lookup/AccountHeadLookup.vue'
import FinTxnTypeLookup from '@/components/popup/lookup/FinTxnTypeLookup.vue'
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue'
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import ViewAttachmentPopup from '@/components/popup/reference/ViewAttachmentPopup.vue';
import FinTxnDetailPopup from '@/components/popup/itemdetail/FinTxnDetailPopup.vue';
export default {
    name: 'app',

    components: {
        AccountHeadLookup,
        FinTxnTypeLookup,
        CategoryLookup,
        VhextMoneyControl,
        AttachmentComponent,
        ViewAttachmentPopup,
        FinTxnDetailPopup
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entityRecordMasterId: localStorage.getItem("ermId"),
            ermId: localStorage.getItem("ermId"),
            ermType: localStorage.getItem("ermType"),                      
            ermTitle: localStorage.getItem("ermTitle"),
            ermIsFreeze: localStorage.getItem("ermIsFreeze"),

            accountHeadLookupTitle: 'Account Head Settings',
            categoryLookupTitle: 'Financial Category Settings',
            subTotalPrependText: 'Sub Total',
            totalPrependText: 'Total Amount',

            idValue: '',
            idEdit: '',

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],
            attachmentList:[],
            attachmentData: {},
            refRecordId: 0,
            notEnabled: true,

            ermId: Number(this.$route.params.ermId),
            entity: 'financial_transaction',
            typeEntity: 'financial_transaction_type',
            categoryEntity: 'financial_transaction_category',

            accountHeads: [],
            financialTxnTypes: [],
            amountTypes: [],
            financialTxnCategories: [],
            users: [],
            financialTxnActivity: [],
            finTxnTypeLookupTitle: 'Financial Txn Type Settings',
            
            prependText: '₹',
            financialTxn: {
                id: '',
                financialTransactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'financial_transaction',
                typeEntity: 'financial_transaction_type',
                transactionTypeId: '',
                type: '',
                categoryId: '',
                category: '',
                title: '',
                accountHeadId: '',
                accountHead: '',
                description: '',
                amount: '',
                isIncome: '',
                preparedById: '',
                transactionDt: new Date().toISOString().substr(0, 10),
                transactionById: '',
                transactionBy: '',
                accountablePersonId: '',
                accountablePerson: '',
                statusId: '',
                activityId: '',
                entityId: '',
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            detailViewData: {},
            referencePopupId: 0,
            visibleRows: [],
            financialTxns: [],
            financialTxnFields: [
                { key: 'financialTransactionId', label: 'Txn Id', tdClass: 'table-cw-100'},
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },
                { key: 'transactionDt', label: 'Txn Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'transactionBy', label: 'Transaction By', sortable: true, sortDirection: 'desc' },                
                { key: 'type', label: 'Txn Type', sortable: true, sortDirection: 'desc' },
                { key: 'category', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'amountType', label: '', tdClass: 'setAmountTdClass', sortable: true, sortDirection: 'desc', thStyle: "width: 20px"  },
                { key: 'actions', label: 'Actions' }
            ],
            financialTxnExcelFields:{
                "Id":"financialTransactionId",
                "Title":"title",
                "Account Head":"accountHead",
                "Txn Date":"transactionDt",
                "Txn Type":"type",
                "Category":"category",
                "Transaction By":"transactionBy",
                "Accountable Person":"accountablePerson",
                "Amount":"amount",
                "CR/DR":"amountType",                
            },

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10,15,20,25,50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        totalAmount() {
            return this.financialTxns.reduce((accum, item) => {
                return accum + parseFloat(item.amount)
            }, 0.00)
        },
        subTotal() {
            return this.visibleRows.reduce((accum, item) => { return accum + item.amount }, 0.0)
        },

    },
    mounted() {
        this.lookupData();
        this.getFinancialTxns();
    },
    validations: {
        financialTxn: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getFinancialTxns: function() {
            let data = {
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/list', data)
                .then((response) => {
                    let result = response.data;
                    this.financialTxns = result.financialTransactionList;
                    this.totalRows = this.financialTxns.length;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: this.projectTypeId,
                entity: 'financial_transaction',
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/lookup/all', data)
                .then((response) => {
                    let result = response.data;

                    this.accountHeads = result.accountHeadLookup;
                    this.financialTxnTypes = result.customTypeLookup;
                    this.financialTxnCategories = result.customCategoryLookup;
                    this.users = result.userLookup;

                    console.log(this.financialTxnCategories);

                })

        },
        async saveData() {
            this.$v.financialTxn.$touch();

            if (!this.$v.financialTxn.$invalid) {
                this.showOverlay = true;

                this.financialTxn.hasAttachment = await this.$refs.attachmentControl.hasAttachment();

                this.amountTypes = this.financialTxnTypes;
                this.amountTypes = this.amountTypes.find( ({ id }) => id === this.financialTxn.transactionTypeId );
                console.log(this.amountTypes.typeClass);
                if (this.amountTypes.typeClass == 1) {
                    this.financialTxn.isIncome = true;
                } else {
                    this.financialTxn.isIncome = false;
                }
                this.financialTxn.amount = Math.abs(this.financialTxn.amount);
                console.log(this.financialTxn.isIncome,this.financialTxn);
                let result;
                if (this.elements.editMode) {
                    result = await this.updateFinancialTxn();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, this.financialTxn.id);
                } else {
                    result = await this.saveFinancialTxn();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }
                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {                 
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getFinancialTxns();
                    this.clearData();
                }
            }
        },
        saveFinancialTxn: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/add', this.financialTxn);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateFinancialTxn: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/update', this.financialTxn);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateId: function(id,newErdId) {
            console.log(id,newErdId);
            if (!this.matchTextValExists(id,newErdId)) {
                let data = {
                    idInitType: "PRJ",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.getFinancialTxns();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'
                });
                this.idEdit='';
                this.getFinancialTxns();
            }
            
        },
        cancelIdEdit: function() {
            this.financialTxns = this.financialTxns.map(data => {
                if(data.id == this.idEdit) {
                    data.financialTransactionId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },                
        deleteFinancialTxn: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/common/erd/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getFinancialTxns();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },        

        // deleteFinancialTxn: function(id) {
        //     this.deleteParam.id = id;
        // },
        // onYes: function() {
        //     axios.delete(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/delete/' + this.deleteParam.id)
        //         .then(response => {
        //             var result = response.data;
        //             if (result.status == "OK") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.getFinancialTxns();
        //                 this.clearData();
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.finTxnDetailRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        clearData: function() {
            this.attachmentInfo.title = "Attachment";
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.financialTxn.title = '';
            this.financialTxn.accountHeadId = '';
            this.financialTxn.transactionDt = new Date().toISOString().substr(0, 10);
            this.financialTxn.transactionTypeId = '';
            this.financialTxn.categoryId = '';
            this.financialTxn.description = '';
            this.financialTxn.amount = '';
            this.financialTxn.transactionById = '';
            this.financialTxn.accountablePersonId = '';
            this.financialTxn.activityId = '';
            this.$v.financialTxn.$reset();
            this.attachments = [];
            this.$refs.attachmentControl.clearAttachment();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.financialTxn.financialTxnId = data.financialTransactionId;
            this.financialTxn.id = data.id;
            this.financialTxn.title = data.title;
            this.financialTxn.accountHeadId = data.accountHeadId;            
            this.financialTxn.transactionDt = data.transactionDt;
            this.financialTxn.transactionTypeId = data.transactionTypeId;
            this.financialTxn.categoryId = data.categoryId;
            this.financialTxn.description = data.description;
            this.financialTxn.amount = data.amount;
            this.financialTxn.transactionById = data.transactionById;
            this.financialTxn.accountablePersonId = data.accountablePersonId;
            this.financialTxn.activityId = data.activityId;
            this.attachmentInfo.title = "Attachment";
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.financialTxn.financialTxnId = data.financialTransactionId.toString();
            this.financialTxn.title = data.title;
            this.financialTxn.accountHead = data.accountHead;            
            this.financialTxn.transactionDt = data.transactionDt;
            this.financialTxn.type = data.type;
            this.financialTxn.category = data.category;
            this.financialTxn.description = data.description;
            this.financialTxn.amount = data.amount;
            this.financialTxn.transactionById = data.transactionById;
            this.financialTxn.transactionBy = data.transactionBy;
            this.financialTxn.accountablePerson = data.accountablePerson;
            this.financialTxn.activity = data.activity;
            this.financialTxn.createdDt = data.createdDt;
            this.financialTxn.lastUpdatedDt = data.lastUpdatedDt;
            this.financialTxn.id = data.id;
            this.attachmentInfo.title = "";
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
        },
        moneyControlInput: function(value) {
            this.financialTxn.amount = Math.abs(value);
        },
        attachmentLookup(data) {
            this.refRecordId = Number(data.item.id);
            this.$root.$emit('bv::show::modal', 'attachmentModal');
        },
        setAmountTdClass(value) {
            var amountType = value;
            if(amountType === 'CR')
                return 'green';
            else if(amountType === 'DR')
                return 'red';
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        
        matchTextValExists: function(id,textVal) {
            let result = false;
            this.financialTxns.map(data => {
                if (textVal && data.financialTransactionId && data.id !== id) {
                    if (data.financialTransactionId == textVal) {
                        result = true;
                    }
                }
            });
            return result;
        },
        resetAttachmentStatus: function(refRecordId) {
            this.financialTxns = this.financialTxns.map(data => {
                if(data.id == refRecordId) {
                    data.hasAttachment = false;
                    return data;
                }
                else{
                    return data;
                }

            });
        },        

    }
}
</script>