<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered title="Add Employee to Department" no-enforce-focus @hide="closeDialog" id="empDepartmentChangePopup" ref="empDepartmentChangePopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="4" class="my-auto">Employee</b-col>
                        <b-col cols="7" class="pr-1 pl-0">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.employeeData.id.$error }">
                                <select v-model="employeeData.id" id="employeeId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in filteredEmployees" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.employeeData.id.$error && !$v.employeeData.id.required" class="text-danger">Please select Employee</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="4" class="my-auto">Department</b-col>
                        <b-col cols="7" class="pr-1 pl-0">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.employeeData.departmentId.$error }">
                                <select v-model="employeeData.departmentId" id="employeeId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in departments" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.employeeData.departmentId.$error && !$v.employeeData.departmentId.required" class="text-danger">Please select Department</label>
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="closeDialog">Cancel</b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="updateEmpDpt" class="float-right mr-2">
                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>
        <DeleteComponent id="updateEmpDpt" :onYes="updateEmpDepartment" :returnParams="employeeData" title="Department Change" :message='`Are you sure to move ${employee} to "${department}" Department?`' />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required } from "vuelidate/lib/validators";
export default {
    props: {
        departmentId: String,
    },
    data() {
        return {
            employeeData: {
                id: '',
                departmentId: '',
            },
            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),

            employees: [],
            departments: [],
            departmentEmployees: [],
        }
    },
    computed: {
        filteredEmployees(){
            return this.employees.filter(item => !this.departmentEmployees.some(item2 => item2.id == item.id && item2.departmentId));
        },
        employee() {
            let employee = this.employees.find(data => data.id == this.employeeData.id);
            return employee ? employee.value : '';
        },
        department() {
            let department = this.departments.find(data => data.id == this.employeeData.departmentId);
            return department ? department.value : '';
        },
    },
    validations: {
        employeeData: {
            id: {
                required
            },
            departmentId: {
                required,
            }
        },
    },
    mounted() {
        this.lookupData();
        this.employeeData.departmentId = this.departmentId;
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empdepartmentchange/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.employees = result.employeeLookup;
                    this.departments = result.departmentLookup;
                    this.departmentEmployees = result.departmentEmployeeList;
                })
                .catch(error=> console.log(error));
        },
        updateEmpDepartment: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/department/update', this.employeeData)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.lookupData();
                        this.$emit('refresh-lookup');
                        this.closeDialog();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        updateEmpDpt() {
            this.$v.employeeData.$touch();
            if (!this.$v.employeeData.$invalid) {
                this.$bvModal.show('updateEmpDpt');
            }
        },
        clearData: function() {
            this.employeeData.id = '';
            this.employeeData.departmentId = '';
            this.$v.employeeData.$reset();
        },
        closeDialog() {
            this.clearData();
            this.$emit('closeDialog');
            this.$bvModal.hide('empDepartmentChangePopup');
        },
    }
}
</script>