<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row py-1">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16 mt-1">Time Sheet</h4>
                            <!-- <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4> -->
                        </div>
<!--                         <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Time Sheet</span></h4>
                        </div> -->
                        <div class="col-lg-4 col-sm-4 pl-0">
                            <div class="row no-gutters">
                                <div class="col">
                                    <input type="date" v-model="fromDate" @keyup.enter="getTimeSheets" class="form-control">
                                </div>
                                <div class="col-1 my-auto text-center">to</div>
                                <div class="col">
                                    <input type="date" v-model="tillDate" @keyup.enter="getTimeSheets" class="form-control">
                                </div>
                                <div class="col-1 my-auto text-center pl-1">
                                    <button type="submit" @click="getTimeSheets" class="sbtn btn btn-primary btn-xs px-2">Go</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mt-1">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Time Sheet</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-3">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="taskId">Task</label>
                                                                <select v-model="timeSheet.taskId" @change="getTaskDetails" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in tasks" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>                                                        
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label>Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.timeSheet.title.$error }">
                                                                    <input type="text" class="form-control" maxlength="100" v-model="timeSheet.title" id="title" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.maxLength" class="text-danger">Title must not exceed {{$v.timeSheet.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="activityId">Activity</label>
                                                                <b-input-group>
                                                                    <select v-model="timeSheet.processActivity" class="form-control" id="activityId1">
                                                                        <option :value="{activityId: '', processId: ''}">Select</option>
                                                                        <option v-for="(info, index) in processActivities" :value="{activityId: info.activityId, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.activity}}</option>
                                                                    </select>   
                                                                    <b-input-group-append class="append-0">
                                                                        <a class="action-link" href="javascript:" title="Use Existing Activities" @click="showDialog=true" v-b-modal.userActivityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                                                    </b-input-group-append> 
                                                                </b-input-group>                                                  
                                                            </div>
                                                        </div>

                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="startDate">Start Date</label>
                                                                <b-row class="mb-1">
                                                                    <b-col class="col-7 pr-0">
                                                                        <b-form-input v-model="timeSheet.startDT" type="date" class="form-control" required></b-form-input>
                                                                    </b-col>
                                                                    <b-col class="col-5">
                                                                        <b-form-input v-model="timeSheet.startTM" type="time" @input="setEffortFromTimeDiff()"  required></b-form-input>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="endDate">End Date</label>
                                                                <b-row class="mb-1">
                                                                    <b-col class="col-7 pr-0">
                                                                        <b-form-input type="date" v-model="timeSheet.endDT" required></b-form-input>
                                                                    </b-col>
                                                                    <b-col class="col-5">
                                                                        <b-form-input type="time" v-model="timeSheet.endTM" @input="setEffortFromTimeDiff()" required></b-form-input>
                                                                    </b-col>
                                                                </b-row>                                  
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="effort">Effort</label>
                                                                <b-input-group size="sm">
                                                                    <EffortInput ref="effortControl" @vmx-effort-input="checkValidEffort()" />
                                                                </b-input-group>
                                                                <label v-if="!isValidEffort" class="text-danger">Please enter at least one effort value: Work Minute, Work Hour, or Work Day.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="status">{{timeSheet.taskId ? "Task " : ""}}Status</label>
                                                                <select v-model="timeSheet.statusId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 mb-2">
                                                            <div class="card-body p-0 my-0">

                                                                <b-card no-body>
                                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                        <label class="d-block p-1 pl-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-checklist>
                                                                            <label for="description">Description</label>
                                                                        </label>
                                                                    </b-card-header>

                                                                    <b-collapse id="accordion-checklist" accordion="my-accordion2" role="tabpanel">

                                                                        <div class="form-group">
                                                                            <VmxEditor v-model="timeSheet.description"></VmxEditor>
                                                                        </div>
                                                                    </b-collapse>

                                                                </b-card>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 my-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewStatement">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="timeSheet.timeSheetId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="timeSheet.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Activity</label>
                                                                <input type="text" class="form-control" disabled v-model="timeSheet.activity">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Start Date</label>
                                                                <input type="text" class="form-control" disabled v-model="timeSheet.startDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-12  col-sm-3">
                                                            <div class="form-group">
                                                                <label>End Date</label>
                                                                <input type="text" class="form-control" disabled v-model="timeSheet.endDate">
                                                            </div>
                                                        </div>

                                                        <div class="col-12  col-sm-6">
                                                            <div class="form-group">
                                                                <label>Description</label>
                                                                <VmxEditor v-model="timeSheet.description" disabled></VmxEditor>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Status</label>
                                                                <input type="text" class="form-control" disabled v-model="timeSheet.status">
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Created Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="timeSheet.createdDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Updated Date</label>
                                                                <div class="input-group date disabled-date">
                                                                    <input type="text" class="form-control disabled" :value="timeSheet.lastUpdatedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="timeSheets" :fields="timeSheetFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>                                                                                       
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(description)="data">
                                    <span v-html="data.value">{{ data.value }}</span>
                                </template>                                
                                <template v-slot:cell(workHour)="data">
                                    {{ effortHoursMinutes(data.item.workDay,data.item.workHour,data.item.workMinute) }}
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'To Do'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'On Hold'" variant="warning">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                    </div>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteTimeSheet(data.item.id)" v-b-modal.deleteTimeSheet><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Time Sheets found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="timeSheets.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-left"></b-pagination>
                            <div v-if="totalRows" class="text-right font-weight-bold mb-0 pr-3 py-1">Total Effort : 
                                <span class="text-right" text-align=right>{{calculateTotalEffort}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddUserActivityPopup v-if="showDialog" id="userActivityModal" ref="addUserActivityModal"  @refresh-lookup="lookupData" @closeDialog="closeDialog" />           
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <TimesheetDetailPopup v-if="showDialog" ref="timesheetDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="timesheetPopupId" @closeDialog="closeDialog" />
        <!-- <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" /> -->
        <DeleteComponent id="deleteTimeSheet" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Time Sheet" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import AddUserActivityPopup from '@/components/popup/action/AddUserActivityPopup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import TimesheetDetailPopup from '@/components/popup/itemdetail/TimesheetDetailPopup.vue';
import VmxEditor from '@/components/usercontrols/VmxEditor';
export default {
    name: 'app',
    components: {
        // ActivityLookup,
        AddUserActivityPopup,
        ProcessActivityLookup,
        TimesheetDetailPopup,
        VmxEditor,
    },
    data() {
        return {
            showOverlay: false,
            isValidEffort: true,

            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            tasks: [],
            processActivities: [],
            taskStatus: [],
            activityLookupTitle: 'Activity Settings',
            processActivityLookupTitle: 'Process / Activity Settings',
            entity:'time_sheet',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            userId: localStorage.getItem("userId"),

            fromDate: this.getDateValue(new Date()),
            tillDate: this.getDateValue(new Date()),

            showDialog: false,

            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                preparedById: this.userId,
                title: '',
                taskId: '',
                task: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },

                // activityId: '',
                // activity: '',
                preparedBy: '',
                startDate: '',
                startDT: this.getDateValue(new Date()),
                startTM: this.getTimeValue(new Date()),
                endDate: '',
                endDT: this.getDateValue(new Date()),
                endTM: this.getTimeValue(new Date()),
                workDay: '',
                workHour: '',
                workMinute: '',
                description: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: this.entity
            },
            users: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },

            detailViewData: {},
            timesheetPopupId: 0,

            timeSheets: [],
            timeSheetFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label:'Title',tdClass: 'table-title-col-size',sortable: true},
                { key: 'taskTitle', label:'Task Ref.',tdClass:'table-title-col-size',sortable: true},
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description',tdClass: 'w-30', sortable: true},
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Effort', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                // { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        getDuration() {

            return this.timeSheets.reduce((accum, item) => {

                return accum +
                parseInt(Math.abs(new Date(item.endDate).getTime() - new Date(item.startDate).getTime()) / (1000 * 60) % 60);
            }, 0)

        },
        calculateTotalEffort() {
            let totalWorkDay = this.timeSheets.reduce((sum, item) => sum + item.workDay, 0);
            let totalWorkHour = this.timeSheets.reduce((sum, item) => sum + item.workHour, 0);
            let totalWorkMinute = this.timeSheets.reduce((sum, item) => sum + item.workMinute, 0);

            if(totalWorkMinute > 59) {
                let hours = Math.floor(totalWorkMinute / 60); // Convert to hours
                let minutes = totalWorkMinute % 60; // Remaining minutes after converting to hours
                totalWorkHour = totalWorkHour + hours;
                totalWorkMinute = minutes;
            }
            
            return this.effortHoursMinutes(totalWorkDay,totalWorkHour,totalWorkMinute);
        },

    },        
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getTimeSheets();
    },
    validations: {
        timeSheet: {
            title: {
                required,
                maxLength: maxLength(100)
            }
        },

    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'time_sheet';
            this.userId = localStorage.getItem("userId");
        },
        getTimeSheets: function() {
            let data = {
                // entityRecordMasterId: this.entityRecordMasterId,
                vhextAccountId: this.vhextAccountId,
                preparedById: Number(this.userId),
                startDate: this.fromDate,
                endDate: this.tillDate,
            }

            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/usertimesheetbydaterange/list',data)
                .then((response) => {
                    let result = response.data;
                    this.timeSheets = result.userTimeSheetList;
                    this.totalRows = this.timeSheets.length;  
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.entityRecordMasterId,
                entity: this.entity,
                userId: Number(this.userId),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskLookup;
                    this.processActivities = result.userProcessActivityLookup;

                    this.taskStatus = result.statusLookup;
                    console.log("TaskList",result);
                })
        },
        saveData: function() {
            this.timeSheet.vhextAccountId = this.vhextAccountId,
            this.timeSheet.projectId = this.projectId,
            this.timeSheet.entityRecordMasterId = this.entityRecordMasterId,
            this.timeSheet.preparedById = this.userId,
            // this.timeSheet.startTM = '11:30 AM'
            // this.timeSheet.endTM = '02:30 PM'
            this.timeSheet.startDate = this.combineDateAndTime(this.timeSheet.startDT, this.timeSheet.startTM);
            this.timeSheet.endDate = this.combineDateAndTime(this.timeSheet.endDT, this.timeSheet.endTM);

            this.timeSheet.processId = this.timeSheet.processActivity.processId;
            this.timeSheet.activityId = this.timeSheet.processActivity.activityId;

            console.log(this.timeSheet);
            this.$v.timeSheet.$touch();
            if (this.checkValidEffort() && !this.$v.timeSheet.$invalid) {
                if (this.elements.editMode) {
                    this.updateTimeSheet();
                } else {
                    this.addTimeSheet();
                }            
            }
        },
        addTimeSheet: function() {
            this.showOverlay = true;
            console.log(this.timeSheet);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/add', this.timeSheet)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateTimeSheet: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/update', this.timeSheet)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(this.timeSheet);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteTimeSheet: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/timesheet/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        setEffortFromTimeDiff() {
            
            if (this.timeSheet.startTM && this.timeSheet.endTM) {
                let startDate = this.timeSheet.startDT ? this.timeSheet.startDT : this.getDateValue(new Date());
                let endDate = this.timeSheet.endDT ? this.timeSheet.endDT : this.getDateValue(new Date());

                let combStartDT = this.combineDateAndTime(startDate, this.timeSheet.startTM);
                let combEndDT = this.combineDateAndTime(endDate, this.timeSheet.endTM);

                let difference = this.getDateDifference(combStartDT, combEndDT);
                this.$refs.effortControl.setEffortInputData(difference.days, difference.hours, difference.minutes);
                this.checkValidEffort();
            }
        },
        checkValidEffort() {
            this.setEffortData();
            if((this.timeSheet.workDay && this.timeSheet.workDay > 0) ||
                (this.timeSheet.workHour && this.timeSheet.workHour > 0) ||
                (this.timeSheet.workMinute && this.timeSheet.workMinute > 0)){

                this.isValidEffort = true;
                return true;

            }else{

                this.isValidEffort = false;
                return false;

            }
        },

        clearData: function() {
            this.isValidEffort = true;
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.timeSheet.timeSheetId = '';
            this.timeSheet.taskId = '';
            this.timeSheet.title = '';
            this.timeSheet.statusId = '';
            this.timeSheet.processActivity.activityId = '';
            this.timeSheet.processActivity.processId = '';
            this.timeSheet.status = '';
            this.timeSheet.activity = '';
            this.timeSheet.startDate = '';
            this.timeSheet.startDT = this.getDateValue(new Date());
            this.timeSheet.startTM = this.getTimeValue(new Date());

            this.timeSheet.endDate = '';
            this.timeSheet.endDT = this.getDateValue(new Date());
            this.timeSheet.endTM = this.getTimeValue(new Date());

            this.timeSheet.workDay = '';
            this.timeSheet.workHour = '';
            this.timeSheet.workMinute = '';
            this.timeSheet.description = '';
            this.$refs.effortControl.setEffortInputData('', '', '');
            this.$v.timeSheet.$reset();
        },
        showEdit: function(data) {
            console.log("TASK",data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.timeSheet.id = data.id;
            this.timeSheet.timeSheetId = data.timeSheetId;
            this.timeSheet.taskId = data.taskId;
            this.timeSheet.title = data.title;
            this.timeSheet.statusId = data.statusId;
            this.timeSheet.status = data.status;
            this.timeSheet.processActivity.activityId = data.activityId;
            this.timeSheet.processActivity.processId = data.processId;
            this.timeSheet.preparedById = data.preparedById;
            this.timeSheet.preparedBy = data.preparedBy;
            this.timeSheet.startDate = '';
            this.timeSheet.startDT = this.getDateValue(data.startDate);
            this.timeSheet.startTM = this.getTimeValue(data.startDate);

            this.timeSheet.endDate = data.endDate;
            this.timeSheet.endDT = this.getDateValue(data.endDate);
            this.timeSheet.endTM = this.getTimeValue(data.endDate);

            this.timeSheet.workDay = data.workDay;
            this.timeSheet.workHour = data.workHour;
            this.timeSheet.workMinute = data.workMinute;
            this.timeSheet.description = data.description;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.timeSheet.id = data.id;
            if (data.timeSheetId != null) {
                this.timeSheet.timeSheetId = data.timeSheetId.toString();
            }
            else {
                this.timeSheet.timeSheetId = '';
            }
            this.timeSheet.taskId = data.taskId;
            this.timeSheet.title = data.title;
            this.timeSheet.statusId = data.statusId;
            this.timeSheet.status = data.status;
            this.timeSheet.processActivity.activityId = data.activityId;
            this.timeSheet.processActivity.processId = data.processId;
            this.timeSheet.startDate = data.startDate;
            this.timeSheet.endDate = data.endDate;
            this.timeSheet.workDay = data.workDay;
            this.timeSheet.workHour = data.workHour;
            this.timeSheet.workMinute = data.workMinute;
            this.timeSheet.description = data.description;
            this.timeSheet.createdDt = data.createdDt;
            this.timeSheet.lastUpdatedDt = data.lastUpdatedDt;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
        },
        getTaskDetails: function() {
            this.isValidEffort = true;
            if (this.timeSheet.taskId) {
                this.showOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + this.timeSheet.taskId)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        let task = result.task;
                        console.log(task);
                        this.timeSheet.title = task.title;
                        this.timeSheet.activityId = task.activityId ? task.activityId : '';

                        this.timeSheet.processActivity.processId = task.processId;
                        this.timeSheet.processActivity.activityId = task.activityId;

                        // this.timeSheet.startDT = this.getDateValue(task.estimatedStartDt);
                        this.timeSheet.startTM = this.getTimeValue(task.estimatedStartDt);

                        // this.timeSheet.endDT = this.getDateValue(task.estimatedEndDt);
                        this.timeSheet.endTM = this.getTimeValue(task.estimatedEndDt);

                        this.$refs.effortControl.setEffortInputData(task.workDay, task.workHour, task.workMinute);

                        this.timeSheet.description = task.description;

                        // this.timeSheet.workDay = task.workDay ? task.workDay : '';
                        // this.timeSheet.workHour = task.workHour ? task.workHour : '';
                        // this.timeSheet.workMinute = task.workMinute ? task.workMinute : '';


                        this.timeSheet.statusId = task.statusId;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }else{
                this.clearData(); 
            }
        },
        setEffortData: function() {
            this.timeSheet.workHour = this.$refs.effortControl.getHrs();
            this.timeSheet.workMinute = this.$refs.effortControl.getMins();
            this.timeSheet.workDay = this.$refs.effortControl.getDays();
        },
        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.timesheetPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.timesheetDetailPopupRef.getDetails(this.timesheetPopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }
    }
}
</script>