import CalendarEvent from '@/views/communication/CalendarEvent.vue'
import EMPLanding from '@/views/landing/CommonLanding';
import ActionItem from '@/views/actionitem/ActionItem.vue'
import TMBDashboard from '@/views/dashboard/TMBDashboard';
import TMBProject from '@/views/dashboard/ProjectDashboard';

export default [{
        path: '/dashboard',
        name: 'tmdashboard',
        component: TMBDashboard,
        meta: { layout: 'mainmenu', accessedby: 'TMB' }
    },
    {
        path: '/emp/landing',
        name: 'emplanding',
        component: EMPLanding,
        meta: { layout: 'landing', accessedby: 'CRP|CAM|CRM|IND|NEM|PRM|EMP|TMB|VSA|CLI' }
    },
    {
        path: '/tmb/actionitem',
        name: 'tmbactionitem',
        component: ActionItem,
        meta: { accessedby: 'TMB' }
    },
    {
        path: '/tmb/calevt',
        name: 'tmbcalevt',
        component: CalendarEvent,
        meta: { layout: 'landing', accessedby: 'TMB' }
    },
    {
        path: '/tmb/dashboard',
        name: 'tmbdashboard',
        component: TMBDashboard,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/tmb/dashboard',
        name: 'tmbproject',
        component: TMBProject,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
];