<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <FinancialTxn />
            </div>
        </div>
    </div>
</template>
<script>
import FinancialTxn from '@/views/finance/FinancialTxn.vue';
export default {
    components: {
        FinancialTxn
    }
}
</script>