<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <!-- <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4> -->
                            <h4 class="pl-2 mb-0 fs-16">Folders & Files</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Documents</span></h4>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div class="tab-floating-text">
                                    <div class="row">
                                    </div>
                                </div>
                                <b-tabs content-class="mt-3" lazy>
                                    <!-- <b-tab :title="masterDocName ? masterDocName : 'Documents'"> -->
                                    <b-tab title="Folders">
                                        <div class="row view-filter">
                                            <div class="col-sm-12">
<!--                                                 <div class="float-right">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div> -->
                                                <div class="float-left">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filterErm" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <div class="float-right">
                                                        <button type="submit" title="Add New Folder" v-b-modal.addErmMasterModal class="btn btn-outline-primary sbtn btn-xs py-1 px-2 float-left"><i class="fa fa-plus-circle pr-2"></i>New Folder</button>
                                                    </div>
                                                </div>                                                
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                        <b-table ref="ermDocTable" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="entityRecordMasterList" @row-selected="onRowSelectedErm" :fields="entityRecordMasterFields" responsive="sm" :filter="filterErm" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredErm">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(title)="data">
                                                <i class="fa fa-folder pr-1 text-muted" aria-hidden="true"></i>
                                                <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName" class="d-inline-block">
                                                    <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('title', data.item.id, data.value,'entity_record_master')" @blur="cancelInlineEdit(entityRecordMasterList, data.field.key)">
                                                </div>
                                                <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link d-inline-block">{{ data.value ? data.value : 'Edit' }}</div>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" class="mr-2" title="Delete" v-b-modal.deleteERM @click="deleteERM(data.item.id)"><i class="fa fa-trash"></i></a>
                                                <a href="javascript:" @click="showErmAddPopup(data.item)" ><i class="fa fa-ellipsis-h"></i></a>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Document found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsErm}} Items</span>

<!--                                         <b-pagination v-if="entityRecordMasterList.length!=0" pills v-model="currentPageErm" :total-rows="totalRowsErm" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                        <template #overlay>
                                            <div class="text-center">
                                                <LoadingSpinner />
                                            </div>
                                        </template>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm" >
<!--                                 <div class="tab-floating-text">
                                    <div class="row">
                                        <div v-if="elements.isSubFolderCreate" class="col-md-auto col-12 py-auto my-auto">
                                            <label class="my-0">New Sub Folder</label>
                                        </div>
                                        <div v-else class="col-auto">
                                            <button type="submit" @click="elements.isSubFolderCreate=true;" class="btn btn-primary sbtn mb-0 mr-2 py-1 px-3"><i class="fa fa-plus-circle pr-2"></i>New Sub Folder</button>
                                            <label class="btn sbtn btn-primary btn-file-upload pointer mb-0 mr-2 py-1 px-3">
                                                <i class="fa fa-upload pr-2"></i>Upload File
                                                <input type="file" style="display: none" @change="handleFileUpload" ref="fileInput" />
                                            </label>
                                        </div>
                                        <div v-if="elements.isSubFolderCreate" class="col">
                                            <div class="form-row">
                                                <div class="col">
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.documentFolder.title.$error }">
                                                        <input type="text" maxlength="100" class="form-control" v-model="documentFolder.title" id="title" :class="{ 'is-invalid': $v.documentFolder.title.$error }" placeholder="Enter Sub Folder Name">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.documentFolder.title.$error && !$v.documentFolder.title.minLength" class="text-danger">Name must have at least {{$v.documentFolder.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.documentFolder.title.$error && !$v.documentFolder.title.required" class="text-danger">Please enter Name</label>
                                                    <label v-if="$v.documentFolder.title.$error && !$v.documentFolder.title.maxLength" class="text-danger">Name must not exceed {{$v.documentFolder.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                                <div class="col-auto">
                                                    <button type="submit" @click="saveDocumentFolder" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-remove pr-2"></i>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="col">
                                        </div>
                                    </div>
                                </div> -->
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab :title="(masterDocName ? (masterDocName + ' - ') : '') +'Sub Folders & Files'">
                                        <div class="row view-filter">
                                            <div class="col-sm-12">
                                                <div class="float-left">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>                                                
                     
<!--                                                 <div class="float-right">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div> -->

                                                <div class="col-auto">

                                                    <div class="float-right">
                                                        <div class="row">
                                                            <div v-if="elements.isSubFolderCreate" class="col-md-auto col-12 py-auto my-auto">
                                                                <label class="my-0">New Sub Folder</label>
                                                            </div>
                                                            <div v-else class="col-auto">
                                                                <button type="submit" @click="elements.isSubFolderCreate=true;" class="btn btn-outline-primary sbtn mb-0 mr-2 py-1 px-3"><i class="fa fa-plus-circle pr-2"></i>New Sub Folder</button>
                                                                <label class="btn sbtn btn-outline-primary btn-file-upload pointer mb-0 mr-2 py-1 px-3">
                                                                    <i class="fa fa-upload pr-2"></i>Upload File
                                                                    <input type="file" style="display: none" @change="handleFileUpload" ref="fileInput" />
                                                                </label>
                                                            </div>
                                                            <div v-if="elements.isSubFolderCreate" class="col">
                                                                <div class="form-row">
                                                                    <div class="col">
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.documentFolder.title.$error }">
                                                                            <input type="text" maxlength="100" class="form-control" v-model="documentFolder.title" id="title" :class="{ 'is-invalid': $v.documentFolder.title.$error }" placeholder="Enter Sub Folder Name">
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.documentFolder.title.$error && !$v.documentFolder.title.minLength" class="text-danger">Name must have at least {{$v.documentFolder.title.$params.minLength.min}} letters.</label>
                                                                        <label v-if="$v.documentFolder.title.$error && !$v.documentFolder.title.required" class="text-danger">Please enter Name</label>
                                                                        <label v-if="$v.documentFolder.title.$error && !$v.documentFolder.title.maxLength" class="text-danger">Name must not exceed {{$v.documentFolder.title.$params.maxLength.max}} letters.</label>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                        <button type="submit" @click="saveDocumentFolder" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                                        <button type="submit" @click="clearData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-remove pr-2"></i>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else class="col">
                                                            </div>
                                                        </div>
                                                    </div>                                                    
<!--                                                     <div class="float-right">
                                                        <label class="btn sbtn btn-outline-primary btn-file-upload pointer mb-0 mr-2 py-1 px-3">
                                                            <i class="fa fa-upload pr-2"></i>Upload File
                                                            <input type="file" style="display: none" @change="handleFileUpload" ref="fileInput" />
                                                        </label>                                                             
                                                    </div> -->
                                                </div>                                                                                                                           
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                        <b-table show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :tbody-tr-class="rowClass" :select-mode="selectMode" sort-icon-left :items="documentFolders" @row-selected="onRowSelected" :fields="documentFolderFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template v-slot:cell(title)="data">
                                                <div v-if="data.item.isDocument">
                                                    <a :href="data.item.attachmentPath" class="action-link" target="_blank" title="Open File" ><i class="fa fa-file pr-1 text-muted" aria-hidden="true"></i>{{ data.value }}</a>
                                                </div>
                                                <div v-else>
                                                    <i class="fa fa-folder pr-1 text-muted" aria-hidden="true"></i>
                                                    <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName" class="d-inline-block">
                                                        <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('title', data.item.id, data.value,'document_folder')" @blur="cancelInlineEdit(documentFolders, data.field.key)">
                                                    </div>
                                                    <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link d-inline-block">{{ data.value ? data.value : 'Edit' }}</div>
                                                </div>
                                            </template>
                                            <template slot="row-details" slot-scope="row">
                                                <div v-if="!row.item.isDocument" class="data-table-rows report-tables text-left px-3">
                                                    <b-table thead-class="hidden-header" show-empty small header-variant="secondary" sort-icon-left :items="getFilteredAttachments(row.item.id)" :fields="attachmentFields" responsive="sm">
                                                        <template #cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(fileName)="data">
                                                            <a :href="data.item.attachmentPath" target="_blank" class="action-link" title="Open File">{{ data.value }}</a>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <!-- <a href="#" class="pr-2" @click.prevent="addMOM(row.item, data.item, true)"><i class="fa fa-plus-circle pr-1 action-link"></i>MOM</a>
                                                            <a href="#" class="action-link pointer" title="Show Minutes of Meeting" @click.prevent="viewMOMDetails(row.item, data.item)"><i class="fa fa-list pr-1 action-link"></i></a> -->
                                                            <a href="javascript:" v-b-modal.deleteAttachment @click="deleteFile(data.item.id)" title="Delete attachment" class="float-right"><i class="fa fa-trash"></i></a>
                                                        </template>
                                                        <template v-slot:empty="scope">
                                                            <div class="text-center">No document found</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </template>
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <!-- <div v-if="data.item.fileCount>0" class="dropdown dropright d-inline">
                                                    <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                                        <div v-if="attachmentList.length>0">
                                                            <ul class="attachmentList-ul p-0 m-0">
                                                                <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                                    <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <a href="javascript:" title="Delete" @click="deleteDocumentFolder(data.item.id)" v-b-modal.deleteDocumentFolder><i class="fa fa-trash pl-2"></i></a>
                                                <label v-if="!data.item.isDocument" class="btn btn-link btn-file-upload p-0 my-0" title="Upload File" @click="" >
                                                    <i class="fa fa-upload pl-2"></i>
                                                    <input type="file" style="display: none" @change="handleFileUploadEdit(data.item.id, data.item)" :ref="'fileInputEdit'+data.item.id" />
                                                </label>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Document found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

<!--                                         <b-pagination v-if="documentFolders.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddErmMasterPopup ref="addErmMasterModal" :ermMasterInfo="ermMasterInfo" @update-parent="getEntityRecordMasterList()" />
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteDocumentFolder" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete project document?" />
        <DeleteComponent id="deleteERM" :onYes="onYesErm" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the document?" />
        <DeleteComponent id="deleteAttachment" :onYes="onAttachmentYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddErmMasterPopup from '@/components/popup/action/AddErmMasterPopup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        AddErmMasterPopup,
        TypeLookup,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                isSubFolderCreate: false,
                isFileUpload: false,
                isFolderCreate: false,
            },
            addMore: [],
            addMoreDetail: [],
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeEntity: 'document_folder_type',

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'document_folder',
            userId: localStorage.getItem("userId"),

            ermId: '',
            ermTitle: localStorage.getItem("ermTitle"),
            masterDocName: '',
            
            typeLookupTitle: 'Project Document Type Settings',
            attachments: [],
            attachmentDetail: [],
            attachmentTypeCode: 'DAT',
            attachmentTypeCodeDetail: 'DAT',
            attachmentLinks: [{ fileName: 'Link1', attachmentPath: '', typeCode: 'DSL' }],

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            ermMasterInfo: {
                title: "Project Doc Folders",
                entity: 'document_folder_master',
                refEntity: 'document_folder',
                tooltip: 'New Folder',
                redirectURLName: 'projectdocument',
                newEntityMasterURL: 'pdcmas',
                redirectURL: '/pdcmas',
                actionName: 'Project Documents',
                previewURLName: 'pdcrep',
                headerTitle: 'Folder',
            },

            entityRecordMaster: {
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                statusId: '',
                startDate: '',
                endDate: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                entityId: '',
                entity: '',
                refEntityId: '',
                refEntity: '',
                createdById: localStorage.getItem("userId"),
                isFreeze: false,
                isArchive: false,
                isPublic: false,
                attachments: []
            },

            documentFolder: {
                title: '',
                documentTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                preparedById: localStorage.getItem("userId"),
                uploadedById: localStorage.getItem("userId"),
                entity: 'document_folder',
                isDocument: '',
                statusId: '',
                description: '',
                attachments: [],
                attachmentLinks: [],
            },
            documentDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                uploadedById: localStorage.getItem("userId"),
                entity: 'document_folder',
                title: '',
                documentTypeId: '',
                type: '',
                preparedById: '',
                isDocument: '',
                statusId: '',
                status: '',
                description: '',
                createdDt: '',
                lastUpdatedDt: '',
                attachments: [],
            },
            attachmentLinkData: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntityId: '',
                refRecordId: '',
                fileName: '',
                attachmentPath: '',
                typeCode: 'DSL',
                attachedById: '',
            },
            attachmentList:[],
            documentAttachmentList:[],
            notEnabled: true,
            documentTypes: [],
            users: [],
            documentStatus: [],
            attachmentTypes: [],
            entityRecordMasterList: [],
            documentFolderList: [],
            mainTableArray: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            entityRecordMasterFields: [
                // { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'title', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'projectDisplayName', label: 'Project', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Client', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            documentFolderFields: [
                // { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'documentFolderId', label: 'Doc. ID', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Name', sortable: true, sortDirection: 'desc' },
                // { key: 'type', label: 'Document Type', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'lastUpdatedDt', label: 'Last Updated', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            attachmentFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'fileName', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'attachedBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'lastUpdatedDt', label: 'Last Updated', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRowsErm: 1,
            currentPageErm: 1,
            perPageErm: 10,
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filterErm: null,
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0,
                attachmentId: 0
            }
        }
    },
    computed: {
        attachmentType() {
            if (this.attachmentTypes.length>0) {
                let typeData = this.attachmentTypes.find(data => this.attachmentTypeCodeDetail == data.typeCode);
                console.log(typeData)
                return typeData.value;
            }
        },
        documentFolders() {
            return this.documentFolderList.map((data)=> {
                data._showDetails = true;
                return data;
            })
        },
        calculateBackgroundColor() {
            return (value) => {
                // Logic to determine background color based on value
                if (value) {
                  return 'blue'; // Example: Set background color to blue for a specific value
                } else {
                  return 'white'; // Default background color
                }
            };
        }
    },
    mounted() {
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");

        this.getEntityRecordMasterList();
        // this.getDocumentFolder();
        this.lookupData();
        this.addMore.push(this.addMore.length + 1);
    },
    validations: {
        entityRecordMaster: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        documentFolder: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
                isArchive: false,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.entityRecordMasterList = result.entityRecordMasterList;
                    this.totalRows = this.entityRecordMasterList.length;
                    if (this.entityRecordMasterList && this.entityRecordMasterList.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.entityRecordMasterId, this.mainTableArray, 'ermDocTable');
                        })
                    }
                })
                .catch((error) => {
                    this.showOverlay = false;
                })
        },
        getDocumentFolder: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/document/documentfolder/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.documentFolderList = result.documentFolderList;
                    this.totalRows = this.documentFolders.length;
                })
                .catch((error) => {
                    this.showDetOverlay = false;
                })
        },
        getDocumentAttachments: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entityRecordMasterId: this.entityRecordMasterId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/document/documentfolder/attachments', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.documentAttachmentList = result.documentAttachmentList;
                    this.totalRows = this.documentAttachments.length;
                })
                .catch((error) => {
                    this.showDetOverlay = false;
                })
        },
        addAttachmentList: function() {
            let linkName = 'Link' + (this.attachmentLinks.length+1);
            this.attachmentLinks.push({ fileName: linkName, attachmentPath: '', typeCode: 'DSL' });
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFilteredErm(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsErm = filteredItems.length
            this.currentPageErm = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.documentFolder.vhextAccountId,
                projectId: this.documentFolder.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.documentFolder.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/document/documentfolder/lookup/all', data)
                .then((response) => {
                    let result = response.data;

                    this.documentTypes = result.customTypeLookup;
                    this.attachmentTypes = result.typeLookup;
                    this.users = result.userLookup;
                    this.documentStatus = result.statusLookup;
                    console.log(result);
                })

        },
        saveDocumentFolder: function() {
            if(this.attachmentTypeCode == 'DAT' && this.addMore.length==1) {
                if (this.documentFolder.attachments.length>0 && this.documentFolder.attachments[0]) {
                    this.documentFolder.title = this.documentFolder.attachments[0].name ? this.documentFolder.attachments[0].name : '';
                }
                // else {
                //     this.documentFolder.title = '';
                // }
            }
            this.documentFolder.isDocument = !this.elements.isSubFolderCreate;
            this.documentFolder.entityRecordMasterId = this.entityRecordMasterId;
            this.$v.documentFolder.$touch();
            if (!this.$v.documentFolder.$invalid) {
                if (this.attachmentTypeCode == 'DSL' && this.attachmentLinks.length>0) {
                    this.documentFolder.attachmentLinks = this.attachmentLinks;
                }
                console.log(this.documentFolder);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/document/documentfolder/add', this.documentFolder)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {

                            this.showMessage();
                            if (this.documentFolder.attachments != null && this.documentFolder.attachments.length > 0 && this.attachmentTypeCode == 'DAT')
                                this.submitFiles(result.id, this.documentFolder);
                            else {
                                this.clearData();
                            }
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
                }
        },
        updateDocumentFolder: function() {
            this.showDetOverlay = true;
            console.log(this.documentDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/document/documentfolder/update', this.documentDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDocumentFolder();
                        this.getDocumentAttachments();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteDocumentFolder: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/document/documentfolder/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDocumentFolder();
                        this.getDocumentAttachments();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        addDocumentLink: function() {
            this.attachmentLinkData.vhextAccountId = this.vhextAccountId;
            this.attachmentLinkData.projectId = this.projectId;
            this.attachmentLinkData.attachedById = this.userId;
            this.attachmentLinkData.refEntity = this.documentFolder.entity;
            this.attachmentLinkData.refRecordId = this.documentDetail.id;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/add', this.attachmentLinkData)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment(this.documentDetail.id);
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteERM: function(id) {
            this.deleteParam.id = id;
        },
        onYesErm: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entityRecordMaster.entity,
                id: this.deleteParam.id,               
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/erm/delete', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEntityRecordMasterList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },
        clearDataErm: function() {
            this.elements.visible = false;
            this.elements.isFolderCreate = false;
            this.elements.isSubFolderCreate = false;
            this.entityRecordMaster.id = '';
            this.entityRecordMaster.title = '';
            this.entityRecordMaster.projectId = '';
            this.entityRecordMaster.clientId = '';
            this.entityRecordMaster.ermTypeId = '';
            this.entityRecordMaster.description = '';
            this.entityRecordMaster.createdById = localStorage.getItem("userId");
            this.entityRecordMaster.statusId = '';
            this.entityRecordMaster.startDate = '';
            this.entityRecordMaster.endDate = '';
            this.entityRecordMaster.isPublic = false;
            this.$v.entityRecordMaster.$reset();
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.isSubFolderCreate = false;
            this.documentFolder.title = '';
            this.documentFolder.documentTypeId = '';
            this.documentFolder.preparedById = localStorage.getItem("userId");
            this.documentFolder.isDocument = '';
            this.documentFolder.status = '';
            this.documentFolder.description = '';
            this.documentFolder.id = '';
            this.documentFolder.attachments = [];
            this.addMore = [];
            this.documentFolder.attachmentLinks = [];
            this.attachmentLinks = [{ fileName: 'Link1', attachmentPath: '', typeCode: 'DSL' }];
            this.attachmentTypeCode = 'DAT';
            this.addMore.push(this.addMore.length + 1);
            this.$v.documentFolder.$reset();
        },
        showEdit: function() {
            this.clearData();
            this.elements.visible = false;
            this.elements.editMode = true;
            this.addMoreDetail = [];
            console.log(this.addMoreDetail.length)
            this.addMoreDetail.push(this.addMoreDetail.length + 1);
            if (this.attachmentTypeCodeDetail == 'DSL') {
                this.attachmentLinkData.fileName = 'Link' + (this.attachmentDetail.length + 1);
            }
        },
        cancelEditMode: function() {
            this.elements.editMode = false;
            if (this.documentFolderList.length>0 && this.documentDetail.id) {
                let documentData = this.documentFolderList.find(data => this.documentDetail.id == data.id);
                this.showView(documentData);
            }
        },
        showView: function(data) {
            console.log(data);
            this.attachments = [];
            this.elements.editMode = false;
            this.elements.visible = false;
            this.documentDetail.id = data.id;
            this.documentDetail.documentFolderId = data.documentFolderId;
            this.documentDetail.vhextAccountId = data.vhextAccountId;
            this.documentDetail.projectId = data.projectId;
            this.documentDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.documentDetail.title = data.title;
            this.documentDetail.documentTypeId = data.documentTypeId;
            this.documentDetail.type = data.type;
            this.documentDetail.preparedById = data.preparedById;
            this.documentDetail.uploadedById = data.uploadedById;
            this.documentDetail.isDocument = data.isDocument;
            this.documentDetail.statusId = data.statusId;
            this.documentDetail.status = data.status;
            this.documentDetail.description = data.description;
            this.documentDetail.createdDt = data.createdDt;
            this.documentDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.addMoreDetail = [];
            this.getAttachment(data.id);
        },
        onRowSelectedErm: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.entityRecordMasterId = data.id;
                this.masterDocName = data.title;
                this.getDocumentFolder();
                this.getDocumentAttachments();
            }
        },
        onRowSelected: function(items) {
            // if (items != null) {
            //     let data = items[0];
            //     if (data != undefined)
            //         this.showView(data);
            // }
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            this.documentFolder.attachments.splice(index, 1);
            this.addMore.splice(index, 1);

            // if (this.elements.editMode)
            //     this.addMoreDetail = this.addMoreDetail.filter((data, arrIndex) => index != arrIndex);
            // else
            //     this.addMore = this.addMore.filter((data, arrIndex) => index != arrIndex);
        },
        removeLink: function(index) {
            this.attachmentLinks = this.attachmentLinks.filter((data, arrIndex) => index != arrIndex);
        },
        submitFiles: function(documentFolderId, documentFolderData) {
            if (this.elements.editMode && documentFolderData.attachments.length == 0) {
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
                return;
            }

            let formData = new FormData();
            for (const row of Object.keys(documentFolderData.attachments)) {
                formData.append('files', documentFolderData.attachments[row]);
            }
            formData.append('vhextAccountId', documentFolderData.vhextAccountId);
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('refEntity', documentFolderData.entity);
            formData.append('refRecordId', documentFolderId);
            if (documentFolderData.projectId) {
                formData.append('projectId', documentFolderData.projectId);
            }
            this.clearData();
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showDetOverlay = false;
                    this.elements.editMode = false;
                    // this.getAttachment(documentFolderId);
                    this.getDocumentFolder();
                    this.getDocumentAttachments();
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    this.elements.editMode = false;
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function() {
            iziToast.success({
                title: 'Success',
                message: 'Project Document Created Successfully.',
                position: 'topRight'
            });
            this.getDocumentFolder();
            this.getDocumentAttachments();
        },
        getAttachment: function(id) {
            let data = {
                vhextAccountId: this.documentFolder.vhextAccountId,
                projectId: this.documentFolder.projectId,
                refEntity: this.documentFolder.entity,
                refRecordId: id
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.attachmentDetail = result.attachmentList;
                    if (this.attachmentDetail.length > 0 && this.attachmentDetail[0].typeCode == 'DSL') {
                        this.attachmentTypeCodeDetail = 'DSL';
                    } else this.attachmentTypeCodeDetail = 'DAT';
                    if (this.elements.editMode && this.attachmentTypeCodeDetail == 'DSL') {
                        this.attachmentLinkData.fileName = 'Link' + (this.attachmentDetail.length + 1);
                        this.attachmentLinkData.attachmentPath = '';
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteFile: function(id) {
            this.deleteParam.attachmentId = id;
        },
        onAttachmentYes: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.attachmentId)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDocumentFolder();
                        this.getDocumentAttachments();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    console.log(error);
                });
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        handleFileUpload() {
            const file = this.$refs.fileInput.files[0];

            if (file) {
                this.documentFolder.attachments.push(file);
                this.saveDocumentFolder();
            }
        },
        handleFileUploadEdit(documentFolderId, documentFolderData) {
            const file = this.$refs['fileInputEdit'+documentFolderId].files[0];

            if (file) {
                documentFolderData.entity = 'document_folder';
                documentFolderData.attachments = [];
                documentFolderData.attachments.push(file);
                this.submitFiles(documentFolderId, documentFolderData);
            }
        },
        updateInlineTextValue: function(columnName,rowId,fieldValue,entity) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: entity,
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        if (entity == 'entity_record_master') {
                            this.getEntityRecordMasterList();
                        }
                        else if (entity == 'document_folder') {
                            this.getDocumentFolder();
                        }
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return
            if (item.isDocument) return 'table-light'
        },
        showErmAddPopup: function(data) {
            this.$bvModal.show('addErmMasterModal');
            let ermData = {...data};
            let ermMasterTitle = 'Edit Folder';
            this.$nextTick(() => {
                this.$refs.addErmMasterModal.showEdit(ermData, ermMasterTitle);
            })
        },
        getFilteredAttachments(refRecordId) {
            return this.documentAttachmentList.filter(data => data.refRecordId == refRecordId);
        },
    }
}
</script>