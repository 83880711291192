import ActionItem from '@/views/actionitem/ActionItem.vue'
import ClientDoc from '@/views/document/EDocs.vue'
import CLILanding from '@/views/landing/CommonLanding';
import CLIDashboard from '@/views/dashboard/ProjectDashboard';
import BudgetPlan from '@/views/finance/BudgetPlan.vue'
import BudgetPlanMaster from '@/views/finance/BudgetPlanMaster.vue'
import BudgetPlanMasterList from '@/views/finance/BudgetPlanMasterList.vue'
import CalendarEvent from '@/views/communication/CalendarEvent.vue'
import DevelopmentPlanMaster from '@/views/development/DevelopmentPlanMaster.vue'
import FeedbackForm from '@/views/quality/FeedbackForm.vue'
import FeedbackFormMaster from '@/views/quality/FeedbackFormMaster.vue'
import FeedbackFormMasterList from '@/views/quality/FeedbackFormMasterList.vue'
import FinancialTxn from '@/views/finance/FinancialTxn.vue'
import FinancialTxnMaster from '@/views/finance/FinancialTxnMaster.vue'
import FinancialTxnMasterList from '@/views/finance/FinancialTxnMasterList.vue'
import FinancialTxnReport from '@/views/report/doc/FinancialTxnReport.vue'
import Issue from '@/views/project/Issue.vue'
import IssueMaster from '@/views/project/IssueMaster.vue'
import IssueMasterList from '@/views/project/IssueMasterList.vue'
import MoMReport from '@/views/report/doc/MoMReport.vue'
import ProjectView from '@/views/project/ViewProject.vue'
import Plan from '@/views/plan/Plan.vue'
import PlanMaster from '@/views/plan/PlanMaster.vue'
import PlanMasterList from '@/views/plan/PlanMasterList.vue'
import PlanReport from '@/views/report/doc/PlanReport.vue'
import RequirementSpec from '@/views/requirement/RequirementSpec.vue'
import SSReport from '@/views/report/doc/ScopeStatementReport.vue'
import ClientTask from '@/views/task/ClientTask.vue'
import DevPlanMasterList from '@/views/development/DevPlanMasterList.vue'
import UserRequirement from '@/views/requirement/UserRequirement.vue'
import UserRequirementMaster from '@/views/requirement/UserRequirementMaster.vue'
import UserRequirementMasterList from '@/views/requirement/UserRequirementMasterList.vue'
import URQReport from '@/views/report/doc/UserRequirementReport.vue'
import SRSReport from '@/views/report/doc/SystemRequirementSpecReport.vue'

export default [{
        path: '/cli/landing',
        name: 'clilanding',
        component: CLILanding,
        meta: { layout: 'landing', accessedby: 'CLI' }
    },
    {
        path: '/cli/actionitem',
        name: 'cliactionitem',
        component: ActionItem,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/calevt',
        name: 'clicalevt',
        component: CalendarEvent,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/cli/dashboard',
        name: 'clidashboard',
        component: CLIDashboard,
        meta: { layout: 'landing', accessedby: 'CLI' }
    },
    {
        path: '/client/docshr/list',
        name: 'clidoc',
        component: ClientDoc,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/project/cli/view',
        name: 'cliviewproject',
        component: ProjectView,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/budgetplan/:ermId',
        name: 'clibudgetplan',
        component: BudgetPlan,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/bplmas',
        name: 'clibplmas',
        component: BudgetPlanMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/bplmas/list',
        name: 'clibplmaslist',
        component: BudgetPlanMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/dvpmas',
        name: 'clidvpmas',
        component: DevelopmentPlanMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/dvpmas/list',
        name: 'clidvpmaslist',
        component: DevPlanMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/feedbackform/:ermId',
        name: 'clifeedbackform',
        component: FeedbackForm,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/fbfmas',
        name: 'clifbfmas',
        component: FeedbackFormMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/fbfmas/list',
        name: 'clifbfmaslist',
        component: FeedbackFormMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/financialtxn/:ermId',
        name: 'clifinancialtxn',
        component: FinancialTxn,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/ftxmas',
        name: 'cliftxmas',
        layout: 'service',
        component: FinancialTxnMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/ftxmas/list',
        name: 'cliftxmaslist',
        component: FinancialTxnMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/issue/:ermId',
        name: 'cliissue',
        component: Issue,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/issmas',
        name: 'cliissmas',
        component: IssueMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/issmas/list',
        name: 'cliissmaslist',
        component: IssueMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/mmnrep/:ermId',
        name: 'climmnrep',
        component: MoMReport,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/ftxrep/:ermId',
        name: 'cliftxrep',
        component: FinancialTxnReport,
        meta: { layout: 'service', accessedby: 'CLI' }
    },

    // {
    //     path: '/client/plnmas/list',
    //     name: 'clipftrep',
    //     component: PlanMasterList,
    //     meta: { layout: 'service', accessedby: 'CLI' }
    // },

    {
        path: '/client/plan/:ermId',
        name: 'cliplan',
        component: Plan,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/plnmas',
        name: 'cliplnmas',
        component: PlanMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/plnmas/list',
        name: 'cliplnmaslist',
        component: PlanMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/plnrep/:ermId',
        name: 'cliplnrep',
        component: PlanReport,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/reqspc/:ermId',
        name: 'clireqspc',
        component: RequirementSpec,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/sstrep/:ermId',
        name: 'clisstrep',
        component: SSReport,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/task/list',
        name: 'clitask',
        component: ClientTask,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/userrequirement/:ermId',
        name: 'cliuserrequirement',
        component: UserRequirement,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/urqmas',
        name: 'cliurqmas',
        component: UserRequirementMaster,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/urqmas/list',
        name: 'cliurqmaslist',
        component: UserRequirementMasterList,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/srsrep/:ermId',
        name: 'clisrsrep',
        component: SRSReport,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/client/urqrep/:ermId',
        name: 'cliurqrep',
        component: URQReport,
        meta: { layout: 'service', accessedby: 'CLI' }
    }

];