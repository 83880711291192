<template>
    <div class="accountHead">
        <b-modal scrollable ref="accountHeadModal" size="md" centered id="accountHeadModal" modal-class="accountHeadModal" @hide="closeDialog" :title="accountHeadLookupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.accountHeadEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Account Head</span>
                                <span v-if="elements.accountHeadEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.accountHeadVisible" role="tabpanel">
                            <b-card-body class="p-0">
                                <div class="row">
                                    <b-input-group class="form-group py-2">
                                        <div cols="2" class="align-middle float-left my-auto ml-4 mr-0 p-1" >Account Head</div>
                                        <b-col cols="5" class="pr-1">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.accountHead.accountHead.$error }">
                                                <b-form-input id="accountHead" v-model="accountHead.accountHead" placeholder="Enter Account Head"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.accountHead.accountHead.$error && !$v.accountHead.accountHead.required" class="text-danger">Please Enter Account Head</label>
                                        </b-col>
                                        <b-col cols="2">
                                            <div class="input-group p-0">
                                                <b-form-checkbox class="custom-toggle" v-model="accountHead.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>                                
                                        <div cols="2" class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveAccountHead" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearAccountHeadData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </b-input-group>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="float-right">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="accountHeads" :fields="accountHeadFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showAccountHeadEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="removeAccountHead(data.item.id)" v-b-modal.removeAccountHead><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Types found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                    <b-pagination v-if="accountHeads.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeAccountHead" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Account Head ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        entity: String,
        accountHeadLookupTitle: String
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                accountHeadVisible: false,
                accountHeadEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            accountHead: {
                id: '',
                accountHeadId: '',
                accountHead: '',
                vhextAccountId: '',
                accountHeadMasterId: '',
                projectId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
                active: true,
            },
            accountHeads: [],            
            accountHeadFields: [
                { key: 'accountHeadId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        accountHead: {
            accountHead: {
                required
            }
        }
    },
    mounted() {
        this.getAccountHeadPopups();
    },
    methods: {
        getAccountHeadPopups: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/list', data)
                .then((response) => {
                    let result = response.data;
                    this.accountHeads = result.accountHeadList;
                    this.totalRows = this.accountHeads.length;
                })
        },
        saveAccountHead: function() {
            this.$v.accountHead.$touch();
            if (!this.$v.accountHead.$invalid) {
                if (!this.matchTextValExists(this.accountHead.accountHead, this.accountHead.id)) {
                    if (this.elements.accountHeadEdit) {
                        this.updateAccountHead();
                    } else {
                        this.addAccountHead();
                    }
                }else {
                    iziToast.info({
                        message: 'Account Head already exist.',
                        position: 'topCenter'
                    });
                }

            }
        },
        addAccountHead: function() {
            this.accountHead.vhextAccountId = this.vhextAccountId;
            this.accountHead.projectId = this.projectId;
            this.accountHead.entity = this.entity;
            console.log(this.accountHead);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/add', this.accountHead)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getAccountHeadPopups();
                        this.clearAccountHeadData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateAccountHead: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/update', this.accountHead)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getAccountHeadPopups();
                        this.clearAccountHeadData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeAccountHead: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/accounthead/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getAccountHeadPopups();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.accountHead.id = item.id;
                this.accountHead.active = 0;
                this.accountHead.entity = 'account_head';
                this.updateActivationState();
            } else {
                this.accountHead.id = item.id;
                this.accountHead.active = 1;
                this.accountHead.entity = 'account_head';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.accountHead)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getAccountHeadPopups();
                        this.clearAccountHeadData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearAccountHeadData: function() {
            this.accountHead.id = '';
            this.accountHead.accountHead = '';
            this.accountHead.accountHeadId = '';
            this.accountHead.active = true;
            this.$v.accountHead.$reset();
            this.elements.accountHeadEdit = false;
        },
        showAccountHeadEdit: function(items) {
            let data = items;
            this.elements.accountHeadVisible = true;
            this.elements.accountHeadEdit = true;

            this.accountHead.id = data.id;
            this.accountHead.accountHeadId = data.accountHeadId;
            this.accountHead.accountHead = data.accountHead;
            this.accountHead.vhextAccountId = data.vhextAccountId;
            this.accountHead.projectId = data.projectId;
            // this.accountHead.entityId = data.entityId;
            this.accountHead.active = data.active;

        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.accountHeads.map(data => {
                if (textVal && data.accountHead && data.id !== id) {
                    if (data.accountHead.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>