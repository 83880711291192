<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Process Model</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal processplan">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0 pr-0 ">
                                                                <label class="p-2 mb-0 d-block header-details" v-tooltip="erm.title">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pl-0 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pl-0 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Approved By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">NULL</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals && ermReviewApprovals.length > 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Process Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">{{erm.description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Process Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="processPlans" :fields="processPlanFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template v-slot:cell(deliverables)="data">
                                                <div class="text-left">
                                                <p v-for="(info, index) in JSON.parse(data.item.deliverables)">                              
                                                    <span v-if="index != 0">, </span>
                                                    <span >{{ info.value }}</span>
                                                </p>
                                                </div>
                                            </template>

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Process Plans found</div>
                                            </template>                                           
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Process Plan Detail</span></p>
                            <div class="row">
                                <div class="col-sm-12" v-for="phase in phaseFilter">
                                    <div class="Phase-content">
                                        <div class="card mb-2">
                                            <div class="card-body p-0">
                                                <h5 class="card-title p-2 mb-0  Phase-header">Phase : {{phase}}</h5>
                                                <div class="p-3" v-for="process in processFilter(phase)">
                                                    <h6 class="card-title">Process : {{process.process}}</h6>
                                                    <template v-for="activity in activityFilter(process.process)">
                                                        <div class="card">
                                                        <div class="card-body">
                                                            <h6 class="card-title">{{activity.activity}}</h6>
                                                            <div class="table-responsive">
                                                                <table  class="table table-bordered processPlanReports-table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Input</th>
                                                                            <th>Task</th>
                                                                            <th>Verify</th>
                                                                            <th>Output</th>
                                                                        </tr>
                                                                    </thead>
                                                                    
                                                                    <tbody>
                                                                        <tr v-if="activity.input || activity.task || activity.verify || activity.output">
                                                                            <td>{{activity.input}}</td>
                                                                            <td>{{activity.task}}</td>
                                                                            <td>{{activity.verify}}</td>
                                                                            <td>{{activity.output}}</td>
                                                                        </tr>
                                                                        <tr v-else>
                                                                            <td colspan="4" class="text-center">No Data found...</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <!-- <div class="row pl-2">
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2 mb-0 ">Input</p>
                                                                </div>
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2 mb-0">Task</p>
                                                                </div>
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2 mb-0">Verify</p>
                                                                </div>
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2 mb-0">Output</p>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2 pl-2">
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2">{{activity.input}}</p>
                                                                </div>
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2">{{activity.task}}</p>
                                                                </div>
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2">{{activity.verify}}</p>
                                                                </div>
                                                                <div class="card col-sm-3" >
                                                                    <p class="p-2">{{activity.output}}</p>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                        </div>                                        
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import VTooltip from 'v-tooltip'
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
export default {
    name: 'app',
    components: {
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
    },
    computed: {
        phaseFilter: function () {

            // return this.processPlans.filter((v,i,a)=>a.indexOf(v)==i)
            const uniqueArr = [... new Set(this.processPlans.map(x=> x.phaseTitle))];
            console.log(uniqueArr);
            console.log(this.processPlans);
            return uniqueArr;
          },

    },

    data() {
        return {
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            phase: '',
            process: '',
            activity: '',
            
            processPlan: {
                currentPage: 1,
                perPage: 5,
            },
            comment: {
                comment: '',
                commentedById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: this.$route.params.ermId
            },

            isBusy: true,
            processPlans: [],
            processPlanActivities: [],
            erm: [],
            ermReviewApprovals: [],
            comments: [],

            processPlanFields: [            
                { key: 'phaseId', label: 'Phase', sortable: true, sortDirection: 'desc' },
                { key: 'phaseTitle', label: 'Phase Title', sortable: true, sortDirection: 'desc' },
                { key: 'process', label: 'Process', sortable: true, sortDirection: 'desc' },
                { key: 'activities', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'deliverables', label: 'Deliverables', sortable: true, sortDirection: 'desc' },
            ],
            ermReviewApprovalFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', sortable: true, sortDirection: 'desc' }
            ],
            selectMode: 'single',
            notEnabled: true,
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            // <!--     content ==> 5  Start-->
            this.entityMaster = 'process_model_master';
            this.entity = 'process_plan';
            // <!--     content ==> 5  End-->

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getProcessPlans(strERMId);
                this.getProcessActivities(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getProcessPlans(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        getProcessPlans: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.processPlans = result.processPlanList;
                })
        },
        getProcessActivities: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/activity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.processPlanActivities = result.processPlanActivityList;
                })
        },

        print: function() {    
            this.$htmlToPaper('printMe');
        },
        processFilter: function (phaseTitle) {
            // alert(phaseTitle);
            console.log(this.processPlans);
            return this.processPlans.filter(function (item) {
              return item.phaseTitle === phaseTitle
            })
          },

        activityFilter: function (processTitle) {
            // alert(processTitle);
            return this.processPlanActivities.filter(function (item) {
              return item.process === processTitle
            })
          },

        
    }
}
</script>