<template>
    <div>
        <FinancialPlanMasterList :ermMasterInfo="ermMasterInfo"></FinancialPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FinancialPlanMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        FinancialPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Financial Plan List",
                entity: 'budget_plan_master',
                refEntity: 'financial_plan',
                tooltip: 'New Financial Plan',
                redirectURLName: 'financialplan',
                newEntityMasterURL: 'bplmas',
                redirectURL: '/bplmas',
                actionName: 'Financial Plan',
                previewURLName: 'fplrep',
                showTypeLookup:true
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>