<template>
    <div>
        <div class="row">
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-4 col-lg-4">
                        <h4 class="pl-2 mb-0 fs-16">Client</h4>
                    </div>
                    <div class="col-lg-4 col-sm-4">
                        <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                            <ol class="breadcrumb pt-0 mb-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript:" class="d-block p-1 action-link mr-4" title="Existing Client" v-b-modal.existing-client-popup @click="getExistingClients"><i class="fa fa-plus-circle pr-1"></i></i>Existing Client</a>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-lg-4 col-sm-4">
                        <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                            <ol class="breadcrumb pt-0 mb-0">
                                <li class="breadcrumb-item">
                                    <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                </li>
                                <li class="breadcrumb-item">
                                    <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div class="separator mb-2"></div>
            </div>
        </div>
        <b-modal id="existing-client-popup" ref="existing-client-popup" size="xl" modal-class="referenceModal" title="Add Existing Client" ok-title="Ok">
            <div class="p-0">
                <div class="m-0">
                    
                    <div class="mt-2">
                        <div class="col-3 float-left pl-0 mb-2">
                            <h6>Existing Client</h6>
                        </div>
                        <div class="col-3 float-right pl-0 mb-2">
                            <div class="dataTables_length">
                                <b-form-group label="Per Page" label-cols-sm="6" label-align="right" label-size="sm" label-for="perPageSelect" class="pl-0 mb-0">
                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>

                        <b-table show-empty small stacked="md" sort-icon-left :items="existingClients" :fields="existingClientfields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
                            <template v-slot:cell(actions)="data">
                                <a href="javascript:" title="Add Client" class="text-success" @click="addProjectClient(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                            </template>
                            <template v-slot:empty="">
                                <div class="text-center">No Clients found</div>
                            </template>
                            <template v-slot:emptyfiltered="">
                                <div class="text-center">{{ }}</div>
                            </template>
                        </b-table>
                        <div class="separator mb-2"></div>
                        <div class="col-9 pull-right">
                            <b-pagination v-if="existingClients != null && existingClients.length!=0" pills v-model="currentPage" :total-rows="totalRowsEC" :per-page="perPage" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                        </div>

                    </div>
                </div>
            </div>
        </b-modal>                                    

        <Client class="col-12 px-0" ref="clientRef" :clientProps="clientProps">
        </Client>
    </div>
</template>
<script>
import Client from '@/components/common/ClientComponent.vue';
import iziToast from 'izitoast';
export default {
    name: 'app',
    components: {
        Client
    },
    data() {
        return {
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            clientProps: {
                addExistingClient: true,
            },

            client: {
                id: '',
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                userActive: '',
                clientTypeId: '',
                clientType: '',
                type: '',
                clientTypeId: '',
                clientTypeName: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            },
            projectClientMember: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                clientName: '',
                emailId: '',
                position: '',
                createdById: localStorage.getItem("userId"),
            },

            // clients: [],
            // fields: [
            //     { key: 'clientId', label: 'Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'clientName', label: 'Client Name', sortable: true, sortDirection: 'desc' },
            //     { key: 'type', label: 'Client Type', sortable: true, sortDirection: 'desc' },
            //     { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
            //     { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
            //     // { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
            //     { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
            //     { key: 'actions', label: 'Actions' }
            // ],
            existingClients: [],
            existingClientfields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'clientId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Client Name', sortable: true, sortDirection: 'desc' },
                { key: 'clientType', label: 'Client Type', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                // { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: []
        }
    },
    mounted() {
        // this.getClients();
    },
    methods: {
        // getClients: function() {
        //     let data = {
        //         vhextAccountId: localStorage.getItem("vhextAccountId")
        //     }
        //     console.log(data);
        //     axios.post(this.$store.getters.getAPIBasePath + '/client/companyclient/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             console.log(result);
        //             if (result.clientList) {
        //                 let clientList = result.clientList;
        //                 this.clients = clientList.filter((clients) => {
        //                     if (clients.projectId !== this.projectId) {
        //                         return clients;
        //                     }
        //                 })
        //                 this.totalRows = this.clients.length;
        //             }
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        getExistingClients: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                relationshipTypecodeEnum: 1,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/client/companyclient/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.existingClients = result.clientList;
                    if(this.existingClients != null){
                        this.totalRowsEC = this.existingClients.length;
                        this.$emit('updateClientCount', this.totalRowsEC);
                    }
                }).catch(error => {
                    console.log(error);
                });
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        addClient: function(data) {
            this.client.id = data.id;
            this.client.clientId = data.clientId;
            this.client.vhextAccountId = data.vhextAccountId;
            this.client.projectId = data.projectId;
            this.client.entity = data.entity;
            this.client.userId = data.userId;
            this.client.userActive = data.userActive;
            this.client.clientTypeId = data.clientTypeId;
            this.client.clientType = data.clientType;
            this.client.type = data.type;
            this.client.clientTypeId = data.clientTypeId;
            this.client.clientTypeName = data.clientTypeName;
            this.client.clientName = data.clientName;
            this.client.contactPerson = data.contactPerson;
            this.client.firstName = data.firstName;
            this.client.middleName = data.middleName;
            this.client.lastName = data.lastName;
            this.client.location = data.location;
            this.client.emailId = data.emailId;
            this.client.phone = data.phone;
            this.client.mobile = data.mobile;
            this.client.address1 = data.address1;
            this.client.address2 = data.address2;
            this.client.city = data.city;
            this.client.state = data.state;
            this.client.countryId = data.countryId;
            this.client.country = data.country;
            this.client.pincode = data.pincode;
            this.client.hasContactInfo = data.hasContactInfo;
            this.client.active = data.active;
            this.client.profession = data.profession;
            this.client.employer = data.employer;
            this.client.position = data.position;
            this.client.createdById = data.createdById;
            this.client.invite = data.invite;
            this.saveClient();
        },
        saveClient: function() {
            console.log(this.client);
            return;
            axios.post(this.$store.getters.getAPIBasePath + '/client/projectid/update', this.client)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        addProjectClient: function(data) {
            this.projectClientMember.id = data.id;
            this.projectClientMember.vhextAccountId = this.vhextAccountId;
            this.projectClientMember.projectId = this.projectId;
            this.projectClientMember.userId = data.userId;
            this.projectClientMember.entity = this.entity;
            this.projectClientMember.clientName = data.clientName;
            this.projectClientMember.emailId = data.emailId;
            this.projectClientMember.position = data.position;
            this.projectClientMember.createdById = this.userId;

            this.saveProjectMember();
        },
        saveProjectMember: function() {
            console.log(this.projectClientMember);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectclient/add', this.projectClientMember)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: "Client Added",
                            position: 'topRight'
                        });
                        this.$refs.clientRef.getClients();
                        // this.getClients();
                        // this.clearData();
                    } else if (result.status == 'OK') {
                        iziToast.info({
                            title: 'Info',
                            message: result.message,
                            position: 'topRight'
                        });

                        this.elements.alreadyExists = true;
                        this.error = true;
                    }
                })
        },

        // clearProjectClient: function(items) {
        //     this.projectClientMember.id = '';
        //     this.projectClientMember.vhextAccountId = '';
        //     this.projectClientMember.projectId = '';
        //     this.projectClientMember.userId = '';
        //     this.projectClientMember.entity = '';
        //     this.projectClientMember.clientName = '';
        //     this.projectClientMember.emailId = '';
        //     this.projectClientMember.position = '';
        //     this.projectClientMember.createdById = '';

        // },

        // existingClient() {
        //     console.log('existingClient');
        //     this.getExistingClients();
        // },
    }
}
</script>