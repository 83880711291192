<template>
    <div class="row">
        <div class="col-6 pr-2">
            <div class="card mb-4 h-100">
                <div class="card-body p-3 landing-tab">
                    <router-link class="action link float-right" title="Contract" :to="{ name: 'addcontract'}"><i class="fa fa-plus-circle pr-2 mt-3 action-link"></i>New Contract</router-link>
                    <b-tabs content-class="mt-3 p-0" lazy @input="contractTabClick">
                        <b-tab title="Contracts">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="data-table-rows  data-tables-hide-filter">
                                <b-table show-empty small stacked="md" selectable :select-mode="selectMode" @row-selected="onRowSelected" :busy="isBusy" sort-icon-left :items="contracts" :fields="contractFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:cell(title)="data">
                                        <a href="javascript:" title="View Contract" class="action-link" @click="setSessionContractId(data.item.id,data.item.contractorId, data.item.displayName, data.item.vmxServiceCode)">{{data.value}}</a>
                                    </template>
                                    <!-- <template v-slot:cell(clientName)="data">
                                        <div class="dropdown dropright">
                                            <a class="dropdown-toggle" href="#" @click="getClients(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="clients.length==0"></b-dropdown-item>
                                                <div v-if="clients.length>0">
                                                    <b-dropdown-item v-for="client,index in clients" :key="client.id" v-if="client.clientName && client.clientName !== null">
                                                        {{ client.clientName }}
                                                    </b-dropdown-item>
                                                </div>
                                            </div>
                                        </div>
                                    </template> -->

                                    <template v-slot:cell(initialBudget)="data">
                                        <div class="text-center">{{ data.item.currencySymbol }} {{ data.item.initialBudget }}</div>
                                    </template>

                                    <template v-slot:cell(status)="data">
                                        <span v-if="data.item.status == 'Started'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-warning mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                        <span v-else>{{data.value}}</span>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Contracts found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="contracts.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </b-tab>
                        <!-- <b-tab title="Contract Archive">
                            <div class="row view-filter">
                                <div class="col-sm-12  mt-0">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="data-table-rows data-tables-hide-filter">
                                <b-table show-empty small stacked="md" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="contracts" :fields="contractFields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-if="isVcaOwner =='true'" v-slot:cell(contractId)="data">
                                        <div v-if="data.item.id == idEdit">
                                            <input type="number" class="form-control action-link" v-model="data.item.contractId" placeholder="" @keyup.enter="updateContractId(data.item.id,data.item.contractId)" @blur="cancelIdEdit()">
                                        </div>
                                        <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.contractId"> {{ data.item.contractId }}
                                        </div>
                                    </template>                                        
                                    <template v-slot:cell(title)="data">
                                        <a href="javascript:" title="View Contract" @click="setSessionContractId(data.item.id,data.item.contractId,data.item.contractTypeId,data.item.displayName,data.item.currencySymbol,data.item.isStandard)">{{data.value}}</a>
                                    </template>
                                    <template v-slot:cell(clientName)="data">
                                        <div class="dropdown dropright">
                                            <a class="dropdown-toggle" href="#" @click="getClients(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="clients.length==0"></b-dropdown-item>
                                                <div v-if="clients.length>0">
                                                    <b-dropdown-item v-for="client,index in clients" :key="client.id" v-if="client.clientName && client.clientName !== null">
                                                        {{ client.clientName }}
                                                    </b-dropdown-item>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span v-if="data.item.status == 'Started'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-warning mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                        <span v-else>{{data.value}}</span>
                                    </template>
                                    <template  v-slot:empty="scope">
                                        <div class="text-center">No Contracts Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="contracts.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </b-tab> -->
                    </b-tabs>
                </div>
            </div>
        </div>
        <div class="col-6 pl-2">
            <div class="card h-100">
                <div class="card-body p-3">
                    <div class="float-right">
                        <label class="pr-2 mt-3 float-right badge-pill badge-blue mb-0">{{this.selContractTitle}}
                        </label>
                    </div>

                    <b-tabs content-class="mt-3" lazy>
                        <b-tab title="Time Line">
                            <div class="card mb-3 px-1 py-3">
                                <p class="chart-title text-left">Contract Timeline</p>
                                <gantt v-if="elements.showChart" class="gantt" :tasks="tasks" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask" :key="ganttChartKey"></gantt>
                            </div>
                        </b-tab>
                        <b-tab title="Budget">
                            <div class="card mb-3 px-1 py-3">
                                <p class="chart-title text-left">Budget</p>
                                <div class="card-body">
                                    <BarChart v-if="elements.showChart" :chartdata="barChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Requirement">
                            <div class="card mb-3 px-1 py-3">
                                <p class="chart-title text-left">Requirement Specification</p>
                                <div class="card-body">
                                    <div v-if="!elements.noReqSpecData">
                                        <PieChart v-if="elements.showChart" :chartdata="reqSpecChartData" :options="chartOptions" class="d-block w-100-h-350" :key="pieChartKey"/>
                                    </div>
                                    <div v-if="elements.noReqSpecData" class="align-middle">No Data</div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>

            </div>
        </div>
        <Dialog id="deleteContract" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Contract?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import PieChart from "@/components/blocks/charts/PieChart.vue";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import Gantt from "@/components/blocks/charts/GanttChart.vue"

export default {
    name: 'app',
    components: {
        PieChart,
        BarChart,
        Gantt,
    },
    data() {
        return {
            user: this.$store.getters.currentUser,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            contractId: localStorage.getItem("contractId"),
            contractTypeId: localStorage.getItem("contractTypeId"),
            projId: localStorage.getItem("projId"),
            contractName: localStorage.getItem("contractName"),
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            isVcaOwner:localStorage.getItem("isVcaOwner"),

            elements: {
                showChart: false,
                noReqSpecData: false,
                noScopeData: false,
                noUserReqData: false,
            },

            isBusy: false,
            selContractTitle: '',

            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,
            barChartData: {},
            reqSpecChartData: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: [],

            idValue: '',
            idEdit: '',

            clients: [],
            contracts: [],
            contractFields: [
                { key: 'contractId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'contractor', label: 'Contractor', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'budget', label: 'Budget', sortable: true, sortDirection: 'desc' },
                { key: 'project', label: 'Project', sortable: true, sortDirection: 'desc' },                
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
    },
    mounted() {
    },
    validations: {
    },
    methods: {
        getContracts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                userTypeId: this.userTypeId,
                isArchive: this.isArchive,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/subcontract/contract/list', data)
                .then((response) => {
                    let result = response.data;

                    console.log(result);
                    if (result.contractList.length != 0) {
                        this.contracts = result.contractList;
                        // this.getChartData(this.contracts[0].id);
                        this.totalRows = this.contracts.length;
                        this.contractCount = this.totalRows;
                        this.$emit('updateContractCount', this.contractCount);
                        this.onRowSelected(this.contracts); 
                    }
                }).catch(error => {
                    console.log(error);
                });
        },
        getClients(id) {
            this.clients = [];
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                contractId: id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/contractclient/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.clients = result.contractClientList;
                }).catch(error => {
                    console.log(error);
                });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        setSessionContractId: function(contractId, contractorId, contractName, vmxServiceCode) {
            localStorage.setItem("contractId", contractId);
            localStorage.setItem("projectId", contractId);
            localStorage.setItem("contractorId", contractorId);
            localStorage.setItem("contractName", contractName);
            localStorage.setItem("vmxServiceCode", vmxServiceCode);

            this.$router.push('/contractor/view');
            var container = $("#app-container");
            var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
            container.removeClass(allMenuClassNames);
        },
        onRowSelected: function(items) {
            if (items != null && items.length != 0) {
                let data = items[0];
                this.selContractTitle = data.title;
                this.getChartData(data.id);
            }
        },
        getChartData: function(contractId) {
            // let data = {
            //     vhextAccountId: localStorage.getItem("vhextAccountId"),
            //     contractId: contractId,
            // }
            // axios.post(this.$store.getters.getAPIBasePath + '/dashboard/contract/all', data)
            //     .then((response) => {
            //         let result = response.data;
            //         console.log(result);

            //         this.ganttChartKey += 1;                        
            //         this.barChartKey += 1;
            //         this.pieChartKey += 1;

            //         if (result.budgetByCategoryList != null) {
            //             this.barChartData = {
            //                 labels: result.budgetByCategoryList.map(item => item.category),
            //                 datasets: [{
            //                         label: 'Estimated Amount',
            //                         backgroundColor: '#2a93d5',
            //                         data: result.budgetByCategoryList.map(item => item.estimatedCost)
            //                     },
            //                     {
            //                         label: 'Actual Amount',
            //                         backgroundColor: '#8e9599',
            //                         data: result.budgetByCategoryList.map(item => item.actualAmount)

            //                     }
            //                 ]
            //             }
            //         }
            //         if (result.requirementSpecList != null && result.requirementSpecList.length != 0) {
            //             this.elements.noReqSpecData = false;
            //             this.reqSpecChartData = {
            //                 labels: result.requirementSpecList.map(item => item.status),
            //                 datasets: [{
            //                     backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
            //                     data: result.requirementSpecList.map(item => item.reqSpecCount)
            //                 }]
            //             }
            //         } else {
            //             this.elements.noReqSpecData = true;
            //         }
            //         this.elements.showChart = true;
            //     })
        },
        selectTask(task) {
            console.log(task);
            this.selectedTask = task
        },

        addMessage(message) {
            this.messages.unshift(message)
            if (this.messages.length > 40) {
                this.messages.pop()
            }
        },

        logTaskUpdate(id, mode, task) {
            let text = (task && task.text ? ` (${task.text})` : '')
            let message = `Task ${mode}: ${id} ${text}`
            this.addMessage(message)
        },

        logLinkUpdate(id, mode, link) {
            let message = `Link ${mode}: ${id}`
            if (link) {
                message += ` ( source: ${link.source}, target: ${link.target} )`
            }
            this.addMessage(message)
        },
        archiveContract: function(id) {
            let data = {
                contractId: id,
                isArchive: (this.archive == true ? false : true),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/contract/archive/', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContracts();
                    }
                })
        },
        updateContractId: function(id,newErdId) {
            if (!this.matchTextValExists(id,newErdId)) {
                let data = {
                    idInitType: "PRJ",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    contractId: localStorage.getItem("contractId"),
                    entity: "contract",
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.getContracts();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'

                });
            }                    
        },
        cancelIdEdit: function() {
            this.contracts = this.contracts.map(data => {
                if(data.id == this.idEdit) {
                    data.contractId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },
        deleteContract: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/contract/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContracts();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        contractTabClick: function(index) {
            if (index === 0) {

                this.archive = false;
                this.isArchive = false;

            } else if (index === 1) {

                this.archive = true;
                this.isArchive = true;
            }

            this.contracts = [];
            this.getContracts();

            console.log(this.isArchive);
        },
    }
}
</script>