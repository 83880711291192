<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Meeting Preparation</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 pr-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals && ermReviewApprovals.length > 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description != null && erm.description.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Meeting Agenda</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">{{erm.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="events != null && events.length > 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Meeting Preparation</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="events" :fields="meetingPreparationFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(link)="data">
                                                    <a v-if="" :href="data.value" class="action-link" target="_blank" title="Meeting Link">{{ getShortString(data.value,20) }}</a>
                                                </template>
                                                <template v-slot:cell(start)="data">
                                                    <div v-if="data.item.allDay && data.item.eventFrequency==1">{{ data.item.startRecur | formatDate }}</div>
                                                    <div v-else-if="data.item.eventFrequency==1">{{ combineDateAndTime(data.item.startRecur,(data.item.startTime).substr(0,5)) | formatDate }}</div>
                                                    <div v-else-if="data.item.allDay">{{ data.value | formatDate }}</div>
                                                    <div v-else>{{ data.value | formatDate }}</div>
                                                </template>
                                                <template v-slot:cell(end)="data">
                                                    <div v-if="data.item.allDay && data.item.eventFrequency==1">{{ data.item.endRecur | formatDate }}</div>
                                                    <div v-else-if="data.item.eventFrequency==1">{{ combineDateAndTime(data.item.endRecur,(data.item.endTime).substr(0,5)) | formatDate }}</div>
                                                    <div v-else-if="data.item.allDay">{{ data.value | formatDate }}</div>
                                                    <div v-else>{{ data.value | formatDate }}</div>
                                                </template>
                                                <template v-slot:cell(time)="data">
                                                    <div v-if="data.item.allDay">All Day</div>
                                                    <div v-else-if="data.item.eventFrequency==1">
                                                        <span v-if="data.item.startTime">{{ data.item.startTime | formatTimeFromHM }}</span> - <span v-if="data.item.endTime">{{ data.item.endTime | formatTimeFromHM }}</span>
                                                    </div>
                                                    <div v-else>
                                                        <span v-if="data.item.start">{{ data.item.start | formatTimeFromDt }}</span> - <span v-if="data.item.end">{{ data.item.end | formatTimeFromDt }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:cell(description)="data">
                                                    <div v-html="getShortString(data.value,20)"></div>
                                                </template>
                                                <template v-slot:cell(daysOfWeek)="data">
                                                    <span v-if="data.item.eventFrequency==0" class="text-muted">No Repeat</span>
                                                    <span v-else v-for="day,index in (data.value)"><span v-if="index!==0">,</span> {{ days[day] }}</span>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Meetings found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
export default {
    name: 'app',
    components: {
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
    },

    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],

            isBusy: true,
            comments: [],
            days: dayList,

            calendarEvents: [],
            meetingPreparationFields: [
                { key: 'calendarEventId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Agenda' },
                { key: 'start',label: 'Start Date', sortable: true,sortDirection: 'desc' },
                { key: 'end',label: 'End Date', sortable: true,sortDirection: 'desc' },
                { key: 'time',label: 'Time', tdClass: 'table-cw-300' },
                { key: 'daysOfWeek', label: 'Repeat', tdClass: 'table-cw-100' },
                { key: 'link', label: 'Meeting Link', tdClass: 'table-cw-100' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        },
        events() {
            return this.calendarEvents.map((event)=> {
                if (event.eventFrequency == 1) {
                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek ? event.daysOfWeek.split(',') : [];
                    return event;
                }
                else return event;
            })
        },
    },


    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.entityMaster = 'meeting_preparation_master';
            this.entity = 'meeting_preparation';
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getMeetingPreparations(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getMeetingPreparations(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        getMeetingPreparations: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/list', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvents = result.calendarEventList;
                    console.log(this.calendarEvents);
                })
        },
        
        
        print: function() {
            this.$htmlToPaper('printMe');
        },
        


    }
}
</script>