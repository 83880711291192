<template>
    <div class="row">
        <div class="col-6 pr-2">
            <div class="card mb-4 h-100">
                <div class="card-body p-3 landing-tab">
                    <router-link class="action link float-right" title="Project" :to="{ name: 'addproject'}"><i class="fa fa-plus-circle pr-2 mt-3 action-link"></i>New Project</router-link>
                    <b-tabs content-class="mt-3 p-0" lazy @input="projectTabClick">
                        <b-tab title="Projects">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="data-table-rows  data-tables-hide-filter">
                                <b-table show-empty small stacked="md" selectable :select-mode="selectMode" @row-selected="onRowSelected" :busy="isBusy" sort-icon-left :items="projects" :fields="projectFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-if="isVcaOwner =='true'" v-slot:cell(projectId)="data">
                                        <div v-if="data.item.id == idEdit">
                                            <input type="number" class="form-control action-link" v-model="data.item.projectId" placeholder="" @keyup.enter="updateProjectId(data.item.id,data.item.projectId)" @blur="cancelIdEdit()">
                                        </div>
                                        <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.projectId"> {{ data.item.projectId }}
                                        </div>
                                    </template>                                                                        
                                    <template v-slot:cell(title)="data">
                                        <a href="javascript:" title="View Project" class="action-link" @click="setSessionProjectId(data.item.id,data.item.projectId,data.item.projectTypeId, data.item.displayName, data.item.vmxServiceCode, data.item.currencySymbol,data.item.isStandard, data.item.effortConversionHourDay)">{{data.value}}</a>
                                    </template>
                                    <template v-slot:cell(clientName)="data">
                                        <div class="dropdown dropright">
                                            <a class="dropdown-toggle" href="#" @click="getClients(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="clients.length==0"></b-dropdown-item>
                                                <div v-if="clients.length>0">
                                                    <b-dropdown-item v-for="client,index in clients" :key="client.id" v-if="client.clientName && client.clientName !== null">
                                                        {{ client.clientName }}
                                                    </b-dropdown-item>
                                                </div>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:cell(initialBudget)="data">
                                        <div class="text-center">{{ data.item.currencySymbol }} {{ data.item.initialBudget }}</div>
                                    </template>

                                    <template v-slot:cell(status)="data">
                                        <span v-if="data.item.status == 'Started'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-warning mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                        <span v-else>{{data.value}}</span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a v-if="data.item.refRecordCount==1" href="javascript:" title="Delete Project" @click="deleteProject(data.item.id)" v-b-modal.deleteProject><i class="fa fa-trash"></i></a>
                                        <span v-else>NA</span>
                                    </template>                                                                        
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Projects found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="projects.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </b-tab>
                        <b-tab title="Project Archive">
                            <div class="row view-filter">
                                <div class="col-sm-12  mt-0">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="data-table-rows data-tables-hide-filter">
                                <b-table show-empty small stacked="md" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="projects" :fields="projectFields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-if="isVcaOwner =='true'" v-slot:cell(projectId)="data">
                                        <div v-if="data.item.id == idEdit">
                                            <input type="number" class="form-control action-link" v-model="data.item.projectId" placeholder="" @keyup.enter="updateProjectId(data.item.id,data.item.projectId)" @blur="cancelIdEdit()">
                                        </div>
                                        <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.projectId"> {{ data.item.projectId }}
                                        </div>
                                    </template>                                        
                                    <template v-slot:cell(title)="data">
                                        <a href="javascript:" title="View Project" @click="setSessionProjectId(data.item.id,data.item.projectId,data.item.projectTypeId,data.item.displayName,data.item.vmxServiceCode,data.item.currencySymbol,data.item.isStandard, data.item.effortConversionHourDay)">{{data.value}}</a>
                                    </template>
                                    <template v-slot:cell(clientName)="data">
                                        <div class="dropdown dropright">
                                            <a class="dropdown-toggle" href="#" @click="getClients(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <!-- {{data.value}} -->
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="clients.length==0"></b-dropdown-item>
                                                <div v-if="clients.length>0">
                                                    <b-dropdown-item v-for="client,index in clients" :key="client.id" v-if="client.clientName && client.clientName !== null">
                                                        {{ client.clientName }}
                                                    </b-dropdown-item>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span v-if="data.item.status == 'Started'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-warning mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                        <span v-else>{{data.value}}</span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a v-if="data.item.refRecordCount==1" href="javascript:" title="Delete Project" @click="deleteProject(data.item.id)" v-b-modal.deleteProject><i class="fa fa-trash"></i></a>
                                        <span v-else>NA</span>
                                    </template>                                    
                                    <template  v-slot:empty="scope">
                                        <div class="text-center">No Projects Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="projects.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
        <div class="col-6 pl-2">
            <div class="card h-100">
                <div class="card-body p-3">
                    <div class="float-right">
                        <label class="pr-2 mt-3 float-right badge-pill badge-blue mb-0">{{this.selProjectTitle}}
                        </label>
                    </div>

                    <b-tabs content-class="mt-3" lazy>
                        <b-tab title="Time Line">
                            <div class="card mb-3 px-1 py-3">
                                <p class="chart-title text-left">Project Timeline</p>
                                <gantt v-if="elements.showChart" class="gantt" :tasks="tasks" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask" :key="ganttChartKey"></gantt>
                            </div>
                        </b-tab>
                        <b-tab title="Budget">
                            <div class="card mb-3 px-1 py-3">
                                <p class="chart-title text-left">Budget</p>
                                <div class="card-body">
                                    <BarChart v-if="elements.showChart" :chartdata="barChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Requirement">
                            <div class="card mb-3 px-1 py-3">
                                <p class="chart-title text-left">Requirement Specification</p>
                                <div class="card-body">
                                    <div v-if="!elements.noReqSpecData">
                                        <PieChart v-if="elements.showChart" :chartdata="reqSpecChartData" :options="chartOptions" class="d-block w-100-h-350" :key="pieChartKey"/>
                                    </div>
                                    <div v-if="elements.noReqSpecData" class="align-middle">No Data</div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>

            </div>
        </div>
        <DeleteComponent id="deleteProject" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Project?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import PieChart from "@/components/blocks/charts/PieChart.vue";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import Gantt from "@/components/blocks/charts/GanttChart.vue"

export default {
    name: 'app',
    components: {
        PieChart,
        BarChart,
        Gantt,
    },
    data() {
        return {
            user: this.$store.getters.currentUser,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            isVcaOwner:localStorage.getItem("isVcaOwner"),

            elements: {
                showChart: false,
                noReqSpecData: false,
                noScopeData: false,
                noUserReqData: false,
            },

            isBusy: false,
            selProjectTitle: '',

            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,
            barChartData: {},
            reqSpecChartData: {},
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: [],

            idValue: '',
            idEdit: '',

            clients: [],
            projects: [],
            projectFields: [
                { key: 'projectId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'projectManager', label: 'Project Manager', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'initialBudget', label: 'Budget', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Client', sortable: true, sortDirection: 'desc' },                
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
    },
    mounted() {
    },
    validations: {
    },
    methods: {
        getProjects: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                userTypeId: this.userTypeId,
                isArchive: this.isArchive,
            }
            console.log(data);
            if (this.userTypeCode == 'CAM') {
                axios.post(this.$store.getters.getAPIBasePath + '/project/companyproject/list', data)
                    .then((response) => {
                        let result = response.data;

                        console.log(result);
                        if (result.projectList.length != 0) {
                            this.projects = result.projectList;
                            // this.getChartData(this.projects[0].id);
                            this.totalRows = this.projects.length;
                            this.projectCount = this.totalRows;
                            this.$emit('updateProjectCount', this.projectCount);
                            this.onRowSelected(this.projects); 
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }
            else{
                axios.post(this.$store.getters.getAPIBasePath + '/project/list', data)
                    .then((response) => {
                        let result = response.data;

                        console.log(result);
                        if (result.projectList.length != 0) {
                            this.projects = result.projectList;
                            // this.getChartData(this.projects[0].id);
                            this.totalRows = this.projects.length;
                            this.projectCount = this.totalRows;
                            this.$emit('updateProjectCount', this.projectCount);
                            this.onRowSelected(this.projects); 
                        }
                    }).catch(error => {
                        console.log(error);
                    });
            }
        },
        getClients(id) {
            this.clients = [];
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/projectclient/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.clients = result.projectClientList;
                }).catch(error => {
                    console.log(error);
                });
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        setSessionProjectId: function(projectId, projId, projectTypeId, projectName, vmxServiceCode, currency, isStandard, effortConversionHourDay) {
            console.log(projectId, projId, projectName, currency,this.user.userType);
            localStorage.setItem('projectId', projectId);
            localStorage.setItem('projId', projId);
            localStorage.setItem('projectTypeId', projectTypeId);
            localStorage.setItem('projectName', projectName);
            localStorage.setItem('vmxServiceCode', vmxServiceCode);
            localStorage.setItem('currency', currency);
            localStorage.setItem('isStandard', isStandard);
            localStorage.setItem('serviceEffortHourDay', effortConversionHourDay);
            if (this.user.userType == 'CLI'){
                this.$router.push('/client/docshr/list');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
            else {
                this.$router.push('/actionitem');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
        },
        onRowSelected: function(items) {
            if (items != null && items.length != 0) {
                let data = items[0];
                this.selProjectTitle = data.title;
                this.getChartData(data.id);
            }
        },
        getChartData: function(projectId) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/project/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.budgetByCategoryList != null) {
                        this.barChartData = {
                            labels: result.budgetByCategoryList.map(item => item.category),
                            datasets: [{
                                    label: 'Estimated Amount',
                                    backgroundColor: '#2a93d5',
                                    data: result.budgetByCategoryList.map(item => item.estimatedCost)
                                },
                                {
                                    label: 'Actual Amount',
                                    backgroundColor: '#8e9599',
                                    data: result.budgetByCategoryList.map(item => item.actualAmount)

                                }
                            ]
                        }
                    }
                    if (result.requirementSpecList != null && result.requirementSpecList.length != 0) {
                        this.elements.noReqSpecData = false;
                        this.reqSpecChartData = {
                            labels: result.requirementSpecList.map(item => item.status),
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                                data: result.requirementSpecList.map(item => item.reqSpecCount)
                            }]
                        }
                    } else {
                        this.elements.noReqSpecData = true;
                    }
                    this.elements.showChart = true;
                })
        },
        selectTask(task) {
            console.log(task);
            this.selectedTask = task
        },

        addMessage(message) {
            this.messages.unshift(message)
            if (this.messages.length > 40) {
                this.messages.pop()
            }
        },

        logTaskUpdate(id, mode, task) {
            let text = (task && task.text ? ` (${task.text})` : '')
            let message = `Task ${mode}: ${id} ${text}`
            this.addMessage(message)
        },

        logLinkUpdate(id, mode, link) {
            let message = `Link ${mode}: ${id}`
            if (link) {
                message += ` ( source: ${link.source}, target: ${link.target} )`
            }
            this.addMessage(message)
        },
        archiveProject: function(id) {
            let data = {
                projectId: id,
                isArchive: (this.archive == true ? false : true),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/archive/', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjects();
                    }
                })
        },
        updateProjectId: function(id,newErdId) {
            if (!this.matchTextValExists(id,newErdId)) {
                let data = {
                    idInitType: "PRJ",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: "project",
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.getProjects();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'

                });
            }                    
        },
        cancelIdEdit: function() {
            this.projects = this.projects.map(data => {
                if(data.id == this.idEdit) {
                    data.projectId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },
        deleteProject: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: 'project',
                id: this.deleteParam.id,
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/project/delete', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProjects();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },

        projectTabClick: function(index) {
            if (index === 0) {

                this.archive = false;
                this.isArchive = false;

            } else if (index === 1) {

                this.archive = true;
                this.isArchive = true;
            }

            this.projects = [];
            this.getProjects();

            console.log(this.isArchive);
        },
    }
}
</script>