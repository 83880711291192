<template>
    <div class="type">
        <b-modal ref="addStdUserRoleModal" size="lg" :id="id" centered modal-class="typeModal" title="Manage Standard User Role" @hide="closeDialog" :no-close-on-backdrop="true"  ok-variant="secondary" ok-only ok-title="Close">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                            <span v-if="elements.editMode"><i class="fa fa-pencil pr-2 action-link"></i>Edit Std. User Role</span>
                            <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Std. User Role</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">                    
                        <b-card-body class="p-3">
                            <div class="form-row">
                                <!-- <div class="col-sm-4 col-lg-4">
                                    <div class="form-group">
                                        <label for="endDate">VMX Service</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.userRole.vmxServiceId.$error }">
                                            <select v-model="userRole.vmxServiceId" :class="{ 'is-invalid': $v.userRole.vmxServiceId.$error }" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in vmxServices" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.userRole.vmxServiceId.$error && !$v.userRole.vmxServiceId.required" class="text-danger">Please select a Service</label>
                                    </div>
                                </div> -->
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label for="startDate">User Role</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.userRole.userRole.$error }">
                                            <b-form-input id="title" v-model="userRole.userRole" :class="{ 'is-invalid': $v.userRole.userRole.$error }" placeholder="Enter User Role"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.userRole.userRole.$error && !$v.userRole.userRole.required" class="text-danger">Please Enter User Role</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label for="endDate">User Type</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.userRole.userTypeId.$error }">
                                            <select v-model="userRole.userTypeId" :class="{ 'is-invalid': $v.userRole.userTypeId.$error }" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.userRole.userTypeId.$error && !$v.userRole.userTypeId.required" class="text-danger">Please Select User Type</label>
                                    </div>
                                </div>                            

                                <!-- <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label for="endDate">Use Standard Role</label>
                                        <select v-model="userRole.userRoleId" :disabled="elements.editMode" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in vmxUserRoles" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div> -->
                                <!-- <div v-if="userRole.userRoleId" class="col-12">
                                    <div class="form-group">
                                        <label for="">Modules</label>
                                        <multiselect v-model="userRole.moduleList" :options="stdUserModules" name="stdUserModule" label="value" track-by="id" select-label="" disabled deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                    </div>
                                </div> -->
                                <!-- <div class="col-12">
                                    <div class="form-group">
                                        <label for="">VMX Services</label>
                                        <multiselect v-model="userRole.vmxServiceList" :options="vmxServices" name="stdUserModule" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                    </div>
                                </div> -->
                                <div class="col-sm-12 col-lg-12">
                                    <div class="form-group">

                                        <button type="submit" @click="saveStdUserRoleData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                        <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div class="float-right">
                    <div class="dataTables_length">
                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <b-table outlined show-empty small stacked="md" sort-icon-left :items="userRoles" :fields="userRoleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>                        
                    <template v-slot:cell(userRole)="data">
                        <div v-if="data.value">{{ data.value }}</div>
                        <div v-else class=" text-muted">No Reference</div>
                    </template>                        
                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <a href="javascript:" title="Edit UserRoles" @click="showUserRoleEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                        <!-- <a href="javascript:" title="Delete" @click="removeStdUserRole(data.item.id)" v-b-modal.removeStdUserRole><i class="fa fa-trash pl-2"></i></a> -->
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No User Roles found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                <b-pagination v-if="userRoles && userRoles.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                <!-- </div> -->
            </b-overlay>
        </b-modal>
        <!-- <DeleteComponent id="removeStdUserRole" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Role ?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'addStdUserRoleModal' },
        userRoleTypeId: Number,
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'user_role',
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,

            stdModuleDisable: false,

            userRole: {
                id: '',
                userRoleId: '',
                userRole: '',
                userTypeId: '',
                userRoleTypeId: '',
                userRoleCode: '',
                active: true,
                isPrimary: false,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                moduleList: [],
                vmxServiceList: [],
            },
            vmxUserRoles: [],
            vmxAccountUserTypes: [],
            vmxServices: [],
            stdUserModules: [],
            stdUserModuleList: [],
            userRoles: [],
            userRoleFields: [
                { key: 'index', label: 'SN', },
                { key: 'id', label: 'PK Id', sortable: true, sortDirection: 'desc' },
                { key: 'userRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'userType', label: 'User Type', sortable: true, sortDirection: 'desc' },
                { key: 'userRoleType', label: 'User Role Type', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        userRole: {
            userRole: {
                required
            },
            userTypeId: {
                required
            },
        }
    },
    mounted() {
        this.getStdUserRoleLookups();
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    // this.vmxUserRoles = result.vmxAccountUserRoleLookup;
                    this.vmxAccountUserTypes = result.vmxAccountUserTypeLookup;
                    this.vmxServices = result.vmxServiceLookup;
                    this.stdUserModules = result.stdUserModuleLookup;
                    this.stdUserModuleList = result.stdUserModuleList;
                    
                })
        },
        getStdUserRoleLookups: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userRoleTypeId: this.userRoleTypeId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/roletype/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.userRoles = result.userRoleList;
                    this.totalRows = this.userRoles.length;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        // getStdUserModuleLookup: function(customUserRoleId) {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         customUserRoleId: customUserRoleId,
        //     }
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/user/customusermodule/list', data)
        //         .then((response) => {
        //             this.showOverlay = false;
        //             let result = response.data;
        //             this.userRole.moduleList = result.customUserModuleList;
        //         })
        //         .catch((error)=> {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                 title: 'Error',
        //                 message: 'Error',
        //                 position: 'topRight'
        //             });
        //         })
        // },
        // filterUserRoleModules(){
        //     console.log(this.userRole.userRoleId);
        //     if(this.userRole.userRoleId){
        //         this.userRole.moduleList = this.stdUserModuleList.filter(data => data.userRoleId == this.userRole.userRoleId); 
        //         this.stdModuleDisable = true;
        //     }else{
        //         this.userRole.moduleList = [];
        //         this.stdModuleDisable = false;
        //     }
            
        // },
        saveStdUserRoleData: function() {
            this.userRole.userRoleTypeId = this.userRoleTypeId;
            this.$v.userRole.$touch();
            if (!this.$v.userRole.$invalid) {
                if (this.elements.editMode) {
                    this.updateStdUserRoleLookup();
                } else {
                    this.addStdUserRoleLookup();
                }
            }
        },
        addStdUserRoleLookup: function() {
            this.showOverlay = true;
            this.userRole.actionState = 1;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/add', this.userRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getStdUserRoleLookups();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateStdUserRoleLookup: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/update', this.userRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getStdUserRoleLookups();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeStdUserRole: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/user/userrole/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getStdUserRoleLookups();
                    }
                })
                .catch(error => {
                    // console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.userRole.id = '';
            this.userRole.userRoleId = '';
            this.userRole.userRole = '';
            this.userRole.userTypeId = '';
            this.userRole.userRoleTypeId = '';
            this.userRole.userRoleCode = '';
            this.userRole.vmxServiceList = [];
            this.userRole.moduleList = [];
            this.userRole.typeCode = '';
            this.userRole.isStandard = false;
            this.userRole.active = true;
            this.$v.userRole.$reset();
            // this.$bvModal.hide(this.id);
        },
        showUserRoleEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.userRole.id = data.id;
            this.userRole.userRoleId = data.userRoleId;
            this.userRole.userRole = data.userRole;
            this.userRole.userRoleCode = data.userRoleCode;
            this.userRole.userTypeId = data.userTypeId;
            this.userRole.userRoleTypeId = data.userRoleTypeId;
            this.userRole.createdById = data.createdById;
            this.userRole.createdBy = data.createdBy;
            this.userRole.isStandard = data.isStandard;
            this.userRole.active = data.active;

            // this.getStdUserModuleLookup(this.userRole.id);
        },
        assignActivationState: function(item) {
            this.showOverlay = true;
            if (item.active == true) {
                this.userRole.id = item.id;
                this.userRole.active = 0;
                this.userRole.entity = this.entity;
                this.updateActivationState();
            } else {
                this.userRole.id = item.id;
                this.userRole.active = 1;
                this.userRole.entity = this.entity;
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.userRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getStdUserRoleLookups();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.userRoles.map(data => {
                if (textVal && data.type && data.id !== id) {
                    if (data.type.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },

        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>