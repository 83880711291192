<template>
    <div class="type">
        <b-modal ref="addCustomUserRoleModal" size="lg" :id="id" centered modal-class="typeModal" title="New User Role" @hide="closeDialog" :no-close-on-backdrop="true"  ok-variant="secondary" ok-only ok-title="Close">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="form-row">
                    <!-- <div class="col-sm-4 col-lg-4">
                        <div class="form-group">
                            <label for="endDate">VMX Service</label>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.vmxServiceId.$error }">
                                <select v-model="customUserRole.vmxServiceId" :class="{ 'is-invalid': $v.customUserRole.vmxServiceId.$error }" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in vmxServices" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.customUserRole.vmxServiceId.$error && !$v.customUserRole.vmxServiceId.required" class="text-danger">Please select a Service</label>
                        </div>
                    </div> -->
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="startDate">User Role</label>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.customUserRole.$error }">
                                <b-form-input id="title" v-model="customUserRole.customUserRole" :class="{ 'is-invalid': $v.customUserRole.customUserRole.$error }" placeholder="Enter User Role"></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.customUserRole.customUserRole.$error && !$v.customUserRole.customUserRole.required" class="text-danger">Please Enter User Role</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-6">
                        <div class="form-group">
                            <label for="endDate">User Type</label>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.userTypeId.$error }">
                                <select v-model="customUserRole.userTypeId" @change="getUserTypeModules(customUserRole.userTypeId)" :disabled="elements.editMode" :class="{ 'is-invalid': $v.customUserRole.userTypeId.$error }" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in customUserTypes" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.customUserRole.userTypeId.$error && !$v.customUserRole.userTypeId.required" class="text-danger">Please Select User Type</label>
                        </div>
                    </div>                                
                    <div v-if="customUserRole.userTypeId" class="col-12">
                        <div class="form-group">
                            <label for="">Modules[{{ customModuleList.length }}]</label>
                            <div class="d-flex flex-wrap">
                                <span v-for="module in customModuleList" :key="module.moduleId" class="badge badge-secondary mr-1 mb-2">{{ module.module }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-sm-4 col-lg-4">
                        <div class="form-group">
                            <label for="endDate">Use Standard Role</label>
                            <select v-model="customUserRole.userRoleId" @change="filterUserRoleModules" :disabled="elements.editMode" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in vmxUserRoles" :value="info.id">{{info.value}}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="customUserRole.userRoleId" class="col-12">
                        <div class="form-group">
                            <label for="">Modules</label>
                            <multiselect v-model="customUserRole.moduleList" :options="stdUserModules" name="stdUserModule" label="value" track-by="id" select-label="" disabled deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                        </div>
                    </div> -->
                </div>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <button type="submit" @click="close()" class="btn btn-secondary float-right mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                    <button type="submit" @click="saveCustomUserRoleData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>{{ elements.editMode ? 'Update' : 'Save' }}</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'addCustomUserRoleModal' },
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'custom_user_role',
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,
            userRoleTypeId: 1,

            customUserRole: {
                id: '',
                customUserRoleId: '',
                userRoleTypeId: '',
                userTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userRoleId: '',
                vmxServiceId: '',
                userType: '',
                customUserRole: '',
                typeCode: '',
                moduleList: [],
                isStandard: false,
                active: true,
                actionState: '',
                createdById: localStorage.getItem("userId"),
            },
            customUserTypes: [],
            customUserRoles: [],
            customModuleList: [],
        }
    },
    validations: {
        customUserRole: {
            customUserRole: {
                required
            },
            userTypeId: {
                required
            },
        }
    },
    computed: {
        userTypeId: {
            get() {
                return this.$store.state.userManagementData.userTypeId;
            },
            set(value) {
                this.$store.state.userManagementData.userTypeId = value;
            }
        },
    },
    mounted() {
        this.getCustomUserRoleList();
        this.lookupData();
        // this.customUserRole.userTypeId = this.$store.state.userManagementData.userTypeId;
    },
    methods: {
        getCustomUserRoleList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userRoleTypeId: this.userRoleTypeId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.customUserRoles = result.customUserRoleList;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/customusertypemodule/usertype/lookup', data)
                .then((response) => {
                    let result = response.data;
                    // this.vmxUserRoles = result.vmxAccountUserRoleLookup;
                    this.customUserTypes = result.customUserTypeLookup;
                })
        },
        saveCustomUserRoleData: function() {
            this.customUserRole.userRoleTypeId = this.userRoleTypeId;
            this.$v.customUserRole.$touch();
            if (!this.$v.customUserRole.$invalid) {
                if (!this.matchTextValExists(this.customUserRole.customUserRole, this.customUserRole.id)) {
                    if (this.elements.editMode) {
                        this.updateCustomUserRoleLookup();
                    } else {
                        this.addCustomUserRoleLookup();
                    }
                }
                else {
                    iziToast.info({
                        message: 'User Role already exists',
                        position: 'topCenter'
                    });
                }
            }
        },
        addCustomUserRoleLookup: function() {
            this.showOverlay = true;
            this.customUserRole.actionState = 1;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/add', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup', result.id);
                        this.$bvModal.hide(this.id);
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateCustomUserRoleLookup: function() {
            this.showOverlay = true;
            this.customUserRole.actionState = 2;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/update', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.$bvModal.hide(this.id);
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        customUserRoleUserTypeChange: function(customuserRoleId, userTypeId) {
            this.showOverlay = true;
            this.customUserRole.id = customuserRoleId;
            this.customUserRole.userTypeId = userTypeId;
            this.userTypeId = userTypeId;
            this.customUserRole.actionState = 1;
            console.log(this.customUserRole);
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/usertypechange', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.customUserRole.id = '';
            this.customUserRole.customUserRoleId = '';
            this.customUserRole.userRoleTypeId = '';
            this.customUserRole.userTypeId = '';
            this.customUserRole.vmxServiceId = '';
            this.customUserRole.userRoleId = '';
            this.customUserRole.userType = '';
            this.customUserRole.customUserRole = '';
            this.customUserRole.moduleList = [];
            this.customUserRole.typeCode = '';
            this.customUserRole.isStandard = false;
            this.customUserRole.active = true;
            this.customUserRole.createdById = localStorage.getItem("userId");
            this.$v.customUserRole.$reset();
            // this.$bvModal.hide(this.id);
        },
        getUserTypeModules: function(userTypeId) {
            if (userTypeId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    userTypeId: userTypeId
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/custommodule/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.customModuleList = result.customModuleList;
                    })  
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.customUserRoles.map(data => {
                if (textVal && data.customUserRole && data.id !== id) {
                    if (data.customUserRole.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        }

    }
}
</script>