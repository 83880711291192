<template>
    <div>
        <b-modal no-close-on-backdrop no-close-on-esc no-enforce-focus title="Edit Payroll Component" size="lg" @hide="closeDialog" id="editPayrollComponentSettingPopup" ref="editPayrollComponentSettingRef" body-class="mb-2" ok-only>

			<b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
            	<b-container class="mb-3 p-0">
	                <div class="row">
                        <div class="col">
                            <div class="form-group">
                                <label for="payslipComponent">Component Title</label>
                                <div class="required-field-block" :class="{ 'is-invalid': $v.payslipComponentSetting.payslipComponent.$error }">
                                    <input type="text" class="form-control" id="payslipComponent" v-model="payslipComponentSetting.payslipComponent" :class="{ 'is-invalid': $v.payslipComponentSetting.payslipComponent.$error }">
                                    <div class="required-icon"></div>
                                </div>  
                                <label v-if="$v.payslipComponentSetting.payslipComponent.$error && !$v.payslipComponentSetting.payslipComponent.required" class="text-danger">Please enter component title</label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="row"><label class="v-hidden">Taxable</label></div>
                            <div class="form-row">
                                <div class="col-auto mt-1">
                                    <label>Taxable</label>
                                </div>
                                <div class="col">
                                    <b-form-checkbox class="custom-toggle" v-model="payslipComponentSetting.isTaxable" name="check-button" switch>
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="payslipComponentTypeId">Component Type</label>
                                <div class="required-field-block" :class="{ 'is-invalid': $v.payslipComponentSetting.payslipComponentTypeId.$error }">
                                    <select disabled v-model="payslipComponentSetting.payslipComponentTypeId" @change="payslipComponentSetting.standardPayslipComponentId='';" class="form-control" :class="{ 'is-invalid': $v.payslipComponentSetting.payslipComponentTypeId.$error }" id="payslipComponentTypeId">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in payslipComponentTypes" :value="info.typeLookupId">{{info.value}}</option>
                                    </select>
                                    <div class="required-icon"></div>
                                </div>  
                                <label v-if="$v.payslipComponentSetting.payslipComponentTypeId.$error && !$v.payslipComponentSetting.payslipComponentTypeId.required" class="text-danger">Please select a type</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="standardPayslipComponentId">Std Component Map</label>
                                <select disabled v-model="payslipComponentSetting.standardPayslipComponentId" @change="onChangeRefComponent" class="form-control" id="standardPayslipComponentId">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in standardPayslipComponents" :value="info.id">{{info.payslipComponent}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <label class="mb-1">Component Calculation</label>
                    <!-- <div class="separator"></div> -->
                    <div class="card p-3 mb-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Calculation Logic</label>
                                    <div class="form-group">
                                        <select v-model="payslipComponentSetting.standardBusinessLogicId" @change="payslipComponentSetting.percentVal='';payslipComponentSetting.fixedVal='';" class="form-control" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in payrollBusinessLogics" :value="info.id">{{info.title}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div v-if="payslipComponentSetting.standardBusinessLogicId" class="col-12">
                                <div class="form-group">
                                    <div class="form-row">
                                        <div v-if="getBusinessLogicData.sblCode=='pctc' || getBusinessLogicData.sblCode=='pbas'" class="col-12">
                                            <label >Percentage Value</label>
                                            <b-input-group :append="getBusinessLogicData.title" class="">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <!-- <div v-if="getBusinessLogicData.sblCode=='pbac'" class="col-12">
                                            <label >Percentage Value</label>
                                            <b-input-group append="% of Basic" class="">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div> -->
                                        <!-- <div v-if="getBusinessLogicData.sblCode=='pbds'" class="col-12">
                                            <b-input-group append="% of Basic" class="">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div> -->
                                        <!-- <div v-if="getBusinessLogicData.sblCode=='lpc'" class="col-12">
                                            <b-input-group append="* LOP Days" class="">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div> -->
                                        <div v-if="getBusinessLogicData.sblCode=='pbda'" class="col-12">
                                            <b-input-group append="% of Basic + DA" class="mb-2">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div v-if="getBusinessLogicData.sblCode=='pgrs'" class="col-12">
                                            <b-input-group append="% of Gross" class="mb-2">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div>

                                        <div v-if="getBusinessLogicData.sblCode=='pfc'" class="col-12">
                                            <!-- <label >Percentage Value</label> -->
                                            <b-input-group append="% of Basic" class="mb-2">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                            <b-input-group prepend="Max Limit" class="">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.fixedVal" id="percentVal" placeholder="Max Limit Value"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div v-if="getBusinessLogicData.sblCode=='fix'" class="col-12">
                                            <label >Amount</label>
                                            <input type="number" class="form-control" v-model="payslipComponentSetting.fixedVal" id="payslipComponentSetting" placeholder="Enter Fixed Amount">
                                        </div>
                                        <div v-if="getBusinessLogicData.sblCode=='lopfix'" class="col-12">
                                            <b-input-group prepend="No. of Working Days" class="mb-2">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.fixedVal" id="percentVal" placeholder=""></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div v-if="getBusinessLogicData.sblCode=='otmphs'" class="col-12">
                                            <b-input-group append="% of Hourly Salary" class="mb-2">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div>
                                        <div v-if="getBusinessLogicData.sblCode=='otmfix'" class="col-12">
                                            <b-input-group append="per Hour" class="mb-2">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.fixedVal" id="percentVal" placeholder="Fixed Amount"></b-form-input>
                                            </b-input-group>
                                        </div>


                                        <!-- <div v-if="getBusinessLogicData.sblCode=='txc'" class="col-12">
                                            <b-input-group :append="'% of ' + (payslipComponentSetting.isBasic ? ' CTC' : '')" class="">
                                                <b-form-input type="number" class="form-control" v-model="payslipComponentSetting.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                            </b-input-group>
                                        </div> -->
                                        <div v-if="getBusinessLogicData.description" class="col-12 mt-3">
                                            <div class="text-muted w-100 border p-2">
                                                {{ getBusinessLogicData.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
	            </b-container>
	        </b-overlay>
            <template #modal-footer="{ cancel }">
                <b-button variant="primary" :disabled="showOverlay" size="sm" @click="updatePayslipComponent" class="float-right mr-1">Save</b-button>
                <b-button variant="outline-secondary" :disabled="showOverlay" size="sm" @click="cancel()" class="float-right mr-1">
                    Cancel
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
        	showDialog: false,
        	elements: {
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            payslipComponentSetting: {
                id: '',
                payslipComponentSettingId: '',
                payslipComponent: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payslipConfigId: '',
                payslipComponentId: '',
                payslipComponentTypeId: '',
                standardBusinessLogicId: '',
                standardPayslipComponentId: '',
                fixedVal: '',
                percentVal: '',
                isBasic: '',
                isTaxable: false,
                createdById: localStorage.getItem("userId"),
            },

            payrollBusinessLogics: [],
            payslipComponentTypes: [],
            standardPayslipComponentLookup: [],

            deleteParam: {
                id: 0
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        getBusinessLogicData() {
            let resultData = {
                id: '',
                title: '',
                sblCode: '',
                description: '',
            }
            let result = this.payrollBusinessLogics.find(data => data.id == this.payslipComponentSetting.standardBusinessLogicId);
            resultData = result ? result : resultData;
            return resultData;
        },
        standardPayslipComponents() {
            if (this.payslipComponentSetting.payslipComponentTypeId) {
                return this.standardPayslipComponentLookup.filter(data => data.payslipComponentTypeId == this.payslipComponentSetting.payslipComponentTypeId);
            }
            else {
                return this.standardPayslipComponentLookup;
            }
        },
    },
    validations: {
        payslipComponentSetting: {
            payslipComponent: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            payslipComponentTypeId: {
                required,
            }
        },
    },
    mounted() {
        this.lookupData();
    },
    methods: {
    	editPayrollComponentSetting: function(data) {
            this.payslipComponentSetting.id = data.id;
            this.payslipComponentSetting.payslipComponentSettingId = data.payslipComponentSettingId;
            this.payslipComponentSetting.payslipComponent = data.payslipComponent;
            this.payslipComponentSetting.vhextAccountId = data.vhextAccountId;
            this.payslipComponentSetting.payslipConfigId = data.payslipConfigId;
            this.payslipComponentSetting.payslipComponentId = data.payslipComponentId;
            this.payslipComponentSetting.payslipComponentTypeId = data.payslipComponentTypeId;
            this.payslipComponentSetting.standardBusinessLogicId = data.standardBusinessLogicId;
            this.payslipComponentSetting.standardPayslipComponentId = data.standardPayslipComponentId;
            this.payslipComponentSetting.fixedVal = data.fixedVal;
            this.payslipComponentSetting.percentVal = data.percentVal;
            this.payslipComponentSetting.isBasic = data.isBasic;
            this.payslipComponentSetting.isTaxable = data.isTaxable;
            this.payslipComponentSetting.createdById = data.createdById;
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/lookup/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.payslipComponentTypes = result.typeLookup;
                    this.payrollBusinessLogics = result.standardBusinessLogicLookup;
                    this.standardPayslipComponentLookup = result.standardPayslipComponentLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        updatePayslipComponent: function() {
            console.log(this.payslipComponentSetting)
            // return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/update', this.payslipComponentSetting)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.$bvModal.hide('editPayrollComponentSettingPopup');
                        // this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onChangeRefComponent() {
            if (this.payslipComponentSetting.standardPayslipComponentId) {
                let result = this.standardPayslipComponents.find(data => data.id == this.payslipComponentSetting.standardPayslipComponentId);
                if (result && result.id) {
                    this.payslipComponentSetting.standardBusinessLogicId = result.standardBusinessLogicId;
                    this.payslipComponentSetting.payslipComponentTypeId = result.payslipComponentTypeId;
                    this.payslipComponentSetting.percentVal = result.percentVal;
                    this.payslipComponentSetting.fixedVal = result.fixedVal;
                }
            }
        },
        closeDialog() {
            this.$emit('closeDialog');
            // this.$bvModal.hide('editPayrollComponentSettingPopup');
        },
    }
}
</script>