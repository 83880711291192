<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Checklist Report</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title ptl-0" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                            <span v-if="elements.viewMode">View</span>
                                             </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-0">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="checklist">Checklist</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.checklistReport.checklist.$error }">
                                                                <input type="text" v-model="checklistReport.checklist" class="form-control" id="checklist" placeholder="Checklist">
                                                               <div class="required-icon"></div>
                                                           </div>
                                                   
                                                    <label v-if="$v.checklistReport.checklist.$error && !$v.checklistReport.checklist.required" class="text-danger">Please Enter checklist</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="active">State</label>
                                                    <div class="input-group ">
                                                        <b-form-checkbox class="custom-toggle" v-model="checklistReport.state" name="check-button" switch>
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Prepared By</label>
                                                    <select v-model="checklistReport.preparedById" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            
                                                <div class="col-12 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="preparedDt">Prepared Date</label>
                                                        <div class="input-group date ">
                                                             <input type="Date" name="preparedDt" v-model="checklistReport.preparedDt" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                                <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode" id="viewChecklistReport">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="checklist">Checklist</label>
                                                            <b-input-group :prepend="checklistReportDetail.checklistReportId" class="mb-2">
                                                            <b-form-input aria-label="First name" disabled v-model="checklistReportDetail.checklist"></b-form-input>
                                                        </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="state">State</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{checklistReportDetail.state}}</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="preparedById">Prepared By</label>
                                                            <input type="text" class="form-control" id="preparedById" disabled v-model="checklistReportDetail.preparedBy">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="preparedDt">Prepared Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="checklistReportDetail.preparedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="checklistReportDetail.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="endDate">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="checklistReportDetail.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="checklistReportList" @row-selected="onRowSelected" :fields="checklistReportFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(inScope)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteChecklistReport(data.item.id)" data-toggle="modal" data-target="#deleteChecklistReport"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No ChecklistReport found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="checklistReportList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail">
                                        <div class="row">
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label for="checklistReportId">  Id</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{checklistReportDetail.checklistReportId}}</span></p>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label for="state"> State</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{checklistReportDetail.state}}</span></p>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="checklist">Checklist</label>
                                                    <input type="text" class="form-control" id="checklist" disabled v-model="checklistReportDetail.checklist">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="preparedById">Prepared By</label>
                                                    <input type="text" class="form-control" id="preparedById" disabled v-model="checklistReportDetail.preparedBy">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="preparedDt">Prepared Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="checklistReportDetail.preparedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="createdDt">Created Date</label>
                                                    <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(checklistReportDetail.createdDt)">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="lastUpdatedDt">Updated Date</label>
                                                    <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(checklistReportDetail.lastUpdatedDt)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="budget">Functions</label>
                                            <select id="durationDays" class="form-control">
                                                <option value="">Functions...</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="budget" style="visibility: hidden;">Search</label>
                                            <div class="input-group ">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-text input-group-append input-group-addon">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Reference Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Objective Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)" ><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Objective found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteChecklistReport" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete checklist report?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            checklistReport: {
                 vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                checklistReportId: '',
                checklist: '',
                state: '',
                preparedDt: ''
            },
            checklistReportDetail: {
                checklistReportId: '',
                checklist: '',
                state: '',
                preparedDt: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            users: [],
            scopeTypes: '',
            checklistReportList: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            checklistReportFields: [
                { key: 'checklistReportId', label: 'Checklist Report  Id', sortable: true, sortDirection: 'desc' },
                { key: 'checklist', label: 'Checklist', sortable: true, sortDirection: 'desc' },
                { key: 'state', label: 'State', sortable: true, sortDirection: 'desc' },
                { key: 'preparedDt', label: 'Prepared Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                preparedBy: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getChecklistReport();
        this.lookupData();
    },
    validations: {
        checklistReport: {
            checklist: {
            required
        }
        }
    },
    methods: {
        getChecklistReport: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/list')
                .then((response) => {
                    let result = response.data;
                    this.checklistReportList = result.checklistReportList;
                    this.totalRows = this.checklistReportList.length;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                typeMasterId: 5,
                statusMasterId: 1
            }
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    console.log(result.data)
                })

        },
        saveData: function() {
            this.$v.checklistReport.$touch();
            if (!this.$v.checklistReport.$invalid) {
                if (this.elements.editMode) {
                    this.updateChecklistReport();
                } else {
                    this.saveChecklistReport();
                }
            }
        },
        saveChecklistReport: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/add', this.checklistReport)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getChecklistReport();
                    }
                })
        },
        updateChecklistReport: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/update', this.checklistReport)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getChecklistReport();
                    }
                })
        },
        deleteChecklistReport: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/test/checklistreport/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getChecklistReport();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.checklistReport.checklistReportId = '';
            this.checklistReport.checklist = '';
            this.checklistReport.preparedById = '';
            this.checklistReport.preparedBy = '';
            this.checklistReport.state = '';
            this.checklistReport.preparedDt = '';
            this.checklistReport.id = '';
            this.$v.checklistReport.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.checklistReport.checklistReportId = data.checklistReportId;
            this.checklistReport.preparedById = data.preparedById;
            this.checklistReport.preparedBy = data.preparedBy;
            this.checklistReport.checklist = data.checklist;
            this.checklistReport.state = data.state;
            this.checklistReport.preparedDt = data.preparedDt;
            this.checklistReport.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.checklistReportDetail.checklistReportId = data.checklistReportId;
                this.checklistReportDetail.checklist = data.checklist;
                this.checklistReportDetail.preparedById = data.preparedById;
                this.checklistReportDetail.preparedBy = data.preparedBy;
                this.checklistReportDetail.state = data.state;
                this.checklistReportDetail.preparedDt = data.preparedDt;
                this.checklistReportDetail.createdDt = data.createdDt;
                this.checklistReportDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.checklistReportDetail.checklistReportId = data.checklistReportId;
            this.checklistReportDetail.checklist = data.checklist;
            this.checklistReportDetail.preparedById = data.preparedById;
            this.checklistReportDetail.preparedBy = data.preparedBy;
            this.checklistReportDetail.state = data.state;
            this.checklistReportDetail.preparedDt = data.preparedDt;
            this.checklistReportDetail.createdDt = data.createdDt;
            this.checklistReportDetail.lastUpdatedDt = data.lastUpdatedDt;
        }

    }
}
</script>