<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Resource Type</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Board View" class="action-link" @click="redirectPage()">
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Resource Type</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="title">Resource Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.resource.title.$error }">
                                                                <input type="text" class="form-control" v-model="resource.title" id="title" :class="{ 'is-invalid': $v.resource.title.$error }" placeholder="Enter Resource Type">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.resource.title.$error && !$v.resource.title.minLength" class="text-danger">Resource Type must have at least {{$v.resource.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.resource.title.$error && !$v.resource.title.required" class="text-danger">Please enter resource type</label>
                                                            <label v-if="$v.resource.title.$error && !$v.resource.title.maxLength" class="text-danger">resource type must not exceed {{$v.resource.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="resourceCategoryId">Resource Category</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.resource.resourceCategoryId.$error }">
                                                                <select v-model="resource.resourceCategoryId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in resourceCategories" :value="info.categoryLookupId">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.resource.resourceCategoryId.$error && !$v.resource.resourceCategoryId.required" class="text-danger">Please select resource Category</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="resource.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="budget">Description</label>
                                                            <textarea v-model="resource.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveResource" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="resourceTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="resourceList"  @row-selected="onRowSelected" :fields="resourceFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <!-- <template v-slot:cell(resourceId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control" v-model="data.item.resourceId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.resourceId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.resourceId"> {{ data.item.resourceId }}</div>
                                </template> -->

                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteResource(data.item.id)" v-b-modal.deleteResource><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Resources found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="resourceList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{resourceDetail.resourceId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateResource" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="titleDet">Resource Type</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.resourceDetail.title.$error }">
                                                        <input type="text" class="form-control" v-model="resourceDetail.title" id="titleDet" :class="{ 'is-invalid': $v.resourceDetail.title.$error }" :disabled="!elements.editMode" placeholder="Enter Resource Type">
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.resourceDetail.title.$error && !$v.resourceDetail.title.minLength" class="text-danger">Resource Type must have at least {{$v.resourceDetail.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.resourceDetail.title.$error && !$v.resourceDetail.title.required" class="text-danger">Please enter resource type</label>
                                                    <label v-if="$v.resourceDetail.title.$error && !$v.resourceDetail.title.maxLength" class="text-danger">resource type must not exceed {{$v.resourceDetail.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="resourceTypeId">Resource Category</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.resourceDetail.resourceCategoryId.$error }">
                                                        <select v-model="resourceDetail.resourceCategoryId" :disabled="!elements.editMode" :class="{ 'is-invalid': $v.resourceDetail.resourceCategoryId.$error }" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in resourceCategories" :value="info.categoryLookupId">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.resourceDetail.resourceCategoryId.$error && !$v.resourceDetail.resourceCategoryId.required" class="text-danger">Please select resource Category</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="resourceDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="resource">Description</label>
                                                    <textarea v-model="resourceDetail.description" :disabled="!elements.editMode" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='resourceDetail.createdBy' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="resourceDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="resourceDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteResource" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Resource?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            showDetOverlay: false,
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'resource',

            mainTableArray: [],

            idValue: '',
            idEdit: '',

            resource: {
                id: '',
                resourceId: '',
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                resource: '',
                resourceTypeId: '',
                resourceCategoryId: '',
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },
            resourceDetail: {
                id: '',
                resourceId: '',
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                resource: '',
                resourceTypeId: '',
                resourceCategoryId: '',
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            resourceCategories: [],
            resourceList: [],
            resourceFields: [
                {key: 'index',label: 'SN'},
                // { key: 'resourceId', label: 'Resource Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCategory', label: 'Resource Category', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getResources();
        this.lookupData();
    },
    validations: {
        resource: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            resourceCategoryId: {
                required,
            },
        },
        resourceDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            resourceCategoryId: {
                required,
            },
        }
    },
    methods: {
        getResources: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.resourceList = result.resourceList;
                    this.totalRows = this.resourceList.length;
                    if (this.resourceList && this.resourceList.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.resourceDetail.id, this.mainTableArray, 'resourceTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                entity: 'resource_category',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/category', data)
                .then((response) => {
                    let result = response.data;
                    // this.vmxUserRoles = result.vmxAccountUserRoleLookup;
                    this.resourceCategories = result.categoryLookup;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveResource: function() {
            this.$v.resource.$touch();
            if (!this.$v.resource.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/add', this.resource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getResources();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateResource: function() {
            this.$v.resourceDetail.$touch();
            if (!this.$v.resourceDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/update', this.resourceDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getResources();
                            this.$v.resourceDetail.$reset();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateId: function(id,newErdId) {
            if (!this.matchNumValExists(id,newErdId)) {
                let data = {
                    idInitType: "VHA",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,            
                }
                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.clearData();
                            this.getResources();
                        }
                    })
                    .catch(error => {
                    });

            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'
                });
                this.idEdit='';
                this.getResources();                
            }
            
        },
        cancelIdEdit: function() {
            this.resourceList = this.resourceList.map(data => {
                if(data.id == this.idEdit) {
                    data.resourceId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        }, 
        matchNumValExists: function(id,numVal) {
            let result = false;
            this.resourceList.map(data => {
                if (numVal && data.resourceId && data.id !== id) {
                    if (data.resourceId == numVal) {
                        result = true;
                    }
                }
            });
            return result;
        },        

        viewResource: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let resourceDetail = result.resource;
                    if (resourceDetail) {
                        this.resourceDetail.id = resourceDetail.id;
                        this.resourceDetail.resourceId = resourceDetail.resourceId;
                        this.resourceDetail.vhextAccountId = resourceDetail.vhextAccountId;
                        this.resourceDetail.projectId = resourceDetail.projectId;
                        this.resourceDetail.projectTypeId = resourceDetail.projectTypeId;
                        this.resourceDetail.title = resourceDetail.title;
                        this.resourceDetail.resourceTypeId = resourceDetail.resourceTypeId;
                        this.resourceDetail.resourceType = resourceDetail.resourceType;
                        this.resourceDetail.resourceCategoryId = resourceDetail.resourceCategoryId;
                        this.resourceDetail.resourceCategory = resourceDetail.resourceCategory;
                        this.resourceDetail.description = resourceDetail.description;
                        this.resourceDetail.active = resourceDetail.active;
                        this.resourceDetail.createdById = resourceDetail.createdById;
                        this.resourceDetail.createdBy = resourceDetail.createdBy;
                        this.resourceDetail.createdDt = resourceDetail.createdDt;
                        this.resourceDetail.lastUpdatedDt = resourceDetail.lastUpdatedDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewResource(this.resourceDetail.id);
            this.$v.resourceDetail.$reset();
        },
        deleteResource: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getResources();
                        this.elements.showDetail = false;
                        this.$refs.resourceTable.clearSelected();

                    } else if (result.status == "CONFLICT") {
                        iziToast.warning({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.resource.id = '';
            this.resource.resourceId = '';
            this.resource.title = '';
            this.resource.resourceTypeId = '';
            this.resource.resourceCategoryId = '';
            this.resource.description = '';
            this.resource.createdById = localStorage.getItem("userId");
            this.resource.active = true;
            this.$v.resource.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.showDetail = true;
            this.resourceDetail.id = data.id;
            this.resourceDetail.resourceId = data.resourceId;
            this.resourceDetail.vhextAccountId = data.vhextAccountId;
            this.resourceDetail.projectId = data.projectId;
            this.resourceDetail.projectTypeId = data.projectTypeId;
            this.resourceDetail.title = data.title;
            this.resourceDetail.resourceTypeId = data.resourceTypeId;
            this.resourceDetail.resourceType = data.resourceType;
            this.resourceDetail.resourceCategoryId = data.resourceCategoryId;
            this.resourceDetail.resourceCategory = data.resourceCategory;
            this.resourceDetail.description = data.description;
            this.resourceDetail.active = data.active;
            this.resourceDetail.createdById = data.createdById;
            this.resourceDetail.createdBy = data.createdBy;
            this.resourceDetail.createdDt = data.createdDt;
            this.resourceDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.elements.editMode = false;
                this.resourceDetail.id = data.id;
                this.resourceDetail.resourceId = data.resourceId;
                this.resourceDetail.vhextAccountId = data.vhextAccountId;
                this.resourceDetail.projectId = data.projectId;
                this.resourceDetail.projectTypeId = data.projectTypeId;
                this.resourceDetail.title = data.title;
                this.resourceDetail.resourceTypeId = data.resourceTypeId;
                this.resourceDetail.resourceType = data.resourceType;
                this.resourceDetail.resourceCategoryId = data.resourceCategoryId;
                this.resourceDetail.resourceCategory = data.resourceCategory;
                this.resourceDetail.description = data.description;
                this.resourceDetail.active = data.active;
                this.resourceDetail.createdById = data.createdById;
                this.resourceDetail.createdBy = data.createdBy;
                this.resourceDetail.createdDt = data.createdDt;
                this.resourceDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.resource.entity = this.entity;
                this.resource.id = item.id;
                this.resource.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.resource.entity = this.entity;
                this.resource.id = item.id;
                this.resource.active = 1;

                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.resource)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.resource.active = true;
                    this.getResources();
                })
                .catch((error)=> {
                    this.isBusy = false;
                })
        },
        redirectPage: function() {
            this.$router.push('/landing/dptbrd');
        },
    }
}
</script>
