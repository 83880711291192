<template>
    <div class="referenceDetailPopup">
        <b-modal id="employeeDetailPopup" ref="employeeDetailRef" @hide="closeDialog" size="lg" centered header-class="py-2" footer-class="py-2" title="Employee" ok-only>
            <template #modal-title>
              Employee [<code  style="color:grey;">Code:{{ popupData.employeeCode }}</code>]
            </template>            
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0">
                                <div class="col-lg-12 col-xl-12 px-2">
                                    <div class="profile-wrapper">
                                        <div class="profile-card js-profile-card row">
                                            <div class="profile-card__img">
                                                <img v-if="popupData.photo" :src="popupData.photo" alt="Profile picture">
                                                <span v-else class="profile-card__img_txt two_chars">{{ popupData.name | getAcronym }}</span>
                                            </div>


                                            <div class="left_col col">
                                                <div class="profile-card__cnt_emp js-profile-cnt">
                                                    <div class="profile-card__name_emp">{{ popupData.name }}</div>
                                                    <div title="Designation" class="profile-card__txt">{{ popupData.designation }}<br> <strong>{{ department }}</strong></div>
                                                    
                                                    <div v-if="popupData.qualification" class="profile-card-loc-emp mt-2">

                                                        <span title="Qualification" class="profile-card-loc__txt"><i class="fa fa-mortar-board mr-1" aria-hidden="true"></i>
                                                        {{ popupData.qualification }}
                                                        </span>
                                                    </div>                                                    

                                                    <div v-if="popupData.department" class="profile-card-loc-emp mt-1">

                                                        <span title="Department" class="profile-card-loc__txt"><i class="fa fa-sitemap mr-1" aria-hidden="true"></i>
                                                        {{ popupData.department }}
                                                        </span>
                                                    </div>                                                    
                                                    <div v-if="popupData.status" class="profile-card-loc-emp mt-1">
                                                        <span>
                                                            <i class="fa fa-clock-o mr-2" aria-hidden="true"></i>
                                                        </span>
                                                        <span title="Employment Status" class="profile-card-loc__txt">
                                                        {{ popupData.status }}
                                                        </span>
                                                    </div>
                                                </div>                                            

                                            </div>
                                            <div class="right_col col p-0">
                                                <div class="profile-card__cnt_emp js-profile-cnt pr-0">
                                                    <div title="Address" class="followers mt-3">
                                                        <div class="follow_count">{{ popupData.address1 }} {{ popupData.streetName }}</div>
                                                        <div class="follow_count">{{ popupData.city }} <span v-if="popupData.state">, </span>{{ popupData.state }}</span></div>
                                                    </div>
                                                    <div v-if="popupData.country" class="following">
                                                        <div class="follow_count">{{ popupData.country }} - {{ popupData.pincode }}</div>
                                                    </div>
                                                    <div v-if="popupData.mobile" class="profile-card-loc-emp mt-3">
                                                        <span title="mobile" >
                                                            <i class="fa fa-phone-square mr-1" aria-hidden="true"></i>
                                                        {{ popupData.mobile }}
                                                        </span>
                                                    </div>
                                                    <div v-if="popupData.emailId" class="profile-card-loc-emp">
                                                        <span title="Email Id">
                                                            <i class="fa fa-envelope mr-1" aria-hidden="true"></i>
                                                        {{ popupData.emailId }}
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
<!--                     <div class="card row col-12 mt-2 mx-0">
                        <div v-if="popupData.emailId" class="col-8 float-left mt-3 mb-0">
                            <span title="Email Id" class="float-left">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                            {{ popupData.emailId }}
                            </span>
                        </div>
                    </div> -->
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
            department: '',
        }
    },
    methods: {
        getDetails: function(department) {
            this.department = department;
            this.loading = true;
            this.$nextTick(() => {
                this.popupData = this.detailViewData;
            });
            this.$bvModal.show('employeeDetailPopup');
            this.loading = false;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>