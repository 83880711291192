<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">User Roles</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table ref="userTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="users" @row-selected="onUserRowSelected" :fields="userFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="text-center" v-model="data.value" disabled>
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:empty="user">
                                        <div class="text-center">No User found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="user">
                                        <div class="text-center">{{ user.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                <b-pagination v-if="users.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0">
                        <b-overlay :show="showDetOverlay" opacity="0.4" spinner-variant="primary">
                            <div class="card mb-4">
                                <div class="card-body p-3">
                                    <b-tabs content-class="mt-3" lazy>
                                        <b-tab :title="(multiUserRole.userName ? multiUserRole.userName : 'User') + ' - Roles'">
                                            <!-- <div class="row mb-2 ml-2">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div v-for="info in vhextAccountUserRoles" class="form-check">
                                                            <input v-if="selectedUserType == info.value" class="form-check-input" type="checkbox" :id="info.typeCode" disabled checked v-model="defaultCheckbox" >
                                                            <input v-else class="form-check-input" type="checkbox" :id="info.typeCode" :value="info.typeCode" v-model="hasUserRole[info.typeCode]" @change="saveUserRole(info.typeLookupId, info.typeCode, hasUserRole[info.typeCode])" >
                                                            <label class="form-check-label" :for="info.typeCode">
                                                                {{ info.value }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <b-table show-empty small stacked="md" :select-mode="selectMode" sort-icon-left :items="vhextAccountUserRoles" :fields="userRoleFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template> 
                                                <template v-slot:cell(typeCode)="data">
                                                    <div class="form-check mt-2">
                                                        <input v-if="selectedUserType == data.item.value" class="form-check-input" type="checkbox" :id="data.value" disabled checked title="Primary Role" v-model="defaultCheckbox" >
                                                        <input v-else class="form-check-input" type="checkbox" :id="data.value" :value="data.value" v-model="hasUserRole[data.value]" @change="saveUserRole(data.item.typeLookupId, data.item.typeCode, hasUserRole[data.value])" >
                                                    </div>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <a v-if="selectedUserType == data.item.value || hasUserRole[data.item.typeCode]" href="javascript:" @click="saveUserRole(data.item.typeLookupId, data.item.typeCode, true, (selectedUserType == data.item.value))" class="pointer" title="Reset"><i class="fa fa-repeat" aria-hidden="true"></i></a>
                                                </template>
                                                <template v-slot:empty="user">
                                                    <div class="text-center">No Roles found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="user">
                                                    <div class="text-center">{{ user.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            loadOnce: true,
            elements: {
                visible: false,
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),

            selectedUserType: '',
            defaultCheckbox: true,

            multiUserRole: {
                id: '',
                multiUserRoleId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                userName: '',
                userTypeId: localStorage.getItem("userTypeId"),
                isPrimaryRole: '',
                userRole: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            multiUserRoleList: [],
            vhextAccountUserRoles: [],
            users: [],

            userFields: [
                { key: 'userId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'userName', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'userType', label: 'User Type', sortable: true, sortDirection: 'desc' },
                { key: 'userRoles', label: 'User Roles', sortable: true, sortDirection: 'desc' },
                // { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
            ],
            userRoleFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'value', label: 'Role', sortable: true, sortDirection: 'desc' },
                { key: 'typeCode', label: 'Enabled', sortable: true, tdClass: 'text-center m-auto p-0', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filterOn: [],
        }
    },
    computed: {
        hasUserRole(){
            let multiUserRoleList = this.multiUserRoleList
            let vhextAccountUserRoles = this.vhextAccountUserRoles;
            let result = {}
            for(let i = 0; i < multiUserRoleList.length; i++){
                for(let j = 0; j < vhextAccountUserRoles.length; j++){
                    if(vhextAccountUserRoles[j].typeCode == multiUserRoleList[i].userRole){
                        result[vhextAccountUserRoles[j].typeCode] = true
                    }
                }
            }
            return result;
        }
    },
    mounted() {
        // this.getUserRoleList(localStorage.getItem("userId"));
        this.getUserList();
        this.lookupData();
    },
    validations: {
    },
    methods: {
        getUserList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/companyuser/list', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userList;
                    this.totalRows = this.users.length;
                    if (this.loadOnce && this.users.length > 0) {
                        this.$nextTick(() => {
                            this.$refs.userTable.selectRow(0);
                        })
                        this.loadOnce = false;
                    }
                })
        },
        getUserRoleList: function(userId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: userId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/multiuserrole/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.multiUserRoleList = result.multiUserRoleList;                    
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/multiuserrole/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.vhextAccountUserRoles = result.vhextAccountUserRoleLookup;
                })
        },
        saveUserRole: function(typeId, typeCode, value, isPrimaryRole) {
            if (value == true) {
                this.multiUserRole.userTypeId = typeId;
                this.multiUserRole.userRole = typeCode;
                this.multiUserRole.isPrimaryRole = isPrimaryRole ? isPrimaryRole : false;

                console.log(this.multiUserRole);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/multiuserrole/change', this.multiUserRole)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: "User Role Assigned",
                                position: 'topRight'
                            });
                            // this.clearData();
                            this.getUserList();
                            this.getUserRoleList(this.multiUserRole.userId);
                            if (this.multiUserRole.userId == this.userId && this.userTypeId == typeId) {
                                EventBus.$emit('onTopMenuReload');
                                EventBus.$emit('onAdminSettingReload');
                            }
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
            else {
                let id = this.getMultiUserRoleData(typeCode)[0].id;
                this.$nextTick(() => {
                    this.deleteUserRole(id);
                })
            }
        },
        deleteUserRole: function(id) {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/common/multiuserrole/delete/' + id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            // title: 'Success',
                            message: 'User Role Removed',
                            position: 'topRight'
                        });
                        this.getUserList();
                        this.getUserRoleList(this.multiUserRole.userId);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getMultiUserRoleData: function(typeCode) {
            return this.multiUserRoleList.filter(function(data, key) {
                if (data.userRole == typeCode) {
                    return data;
                }
            });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onUserRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data)
                this.selectedUserType = data.userType;
                this.multiUserRole.userId = data.id;
                this.multiUserRole.userName = data.name;
                this.getUserRoleList(data.id);
            }
        },
    }
}
</script>