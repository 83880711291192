<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <b-input-group size="sm" prepend="Effort Estimation Title" class="mb-2">
                                <select v-if="elements.showErmDropdown" v-model="ermData.entityRecordMasterId" @change="getEffortEstimationList" class="form-control" :class="{ 'is-invalid': $v.ermData.entityRecordMasterId.$error }">
                                    <option value="">Select Effort Estimation</option>
                                    <option v-for="(info, index) in ermRefLookupAll" :value="info.id">{{info.value}}</option>
                                </select>
                                <b-form-input v-else type="text" v-model="ermData.ermTitle" :disabled="!elements.showErmAdd" :class="{ 'is-invalid': $v.ermData.ermTitle.$error }" placeholder="Enter Title" ></b-form-input>
                            </b-input-group>
                            <label v-if="$v.ermData.entityRecordMasterId.$error && !$v.ermData.entityRecordMasterId.required || $v.ermData.ermTitle.$error && !$v.ermData.ermTitle.required" class="text-danger">Effort Est. Title is required</label>
                        </div>
                        <div class="col-auto pl-0">
                            <a href="javascript:" v-if="!elements.showErmDropdown && !elements.showErmAdd" class="action-link py-1" @click="showEffSheetEdit" title="Add New Effort Estimation Sheet">
                                <i class="fa fa-lg fa-pencil-square-o mt-2"></i>
                            </a>
                            <a href="javascript:" v-else-if="elements.showErmDropdown" class="action-link py-1" @click="showEffSheetAdd" title="Select Existing Sheet">
                                <i class="fa fa-lg fa-plus-circle mt-2"></i>
                            </a>
                            <a href="javascript:" v-else class="action-link py-1" @click="showEffSheetDropdown" title="Select Existing Sheet">
                                <i class="fa fa-lg fa-caret-down mt-2"></i>
                            </a>
                            <div v-if="elements.showErmAdd" class="d-inline-block ml-2">
                                <button class="btn sbtn btn-primary px-2 py-1 mr-1" @click="addEstimationSheetRef" title="Create Effort Estimation Sheet">
                                    <i class="fa fa-lg fa-floppy-o mr-2"></i>Save
                                </button>
                                <button class="btn sbtn btn-secondary px-2 py-1" @click="cancelEffSheetAdd" title="Select Existing Sheet">
                                    <i class="fa fa-lg fa-times mr-2"></i>Cancel
                                </button>
                                <!-- <button v-else class="btn sbtn btn-primary px-2 py-1" @click="elements.showErmAdd=true;" title="Create New Effort Estimation Sheet">
                                    <i class="fa fa-lg fa-plus-square-o mr-2"></i>Create Effort Estimation Sheet
                                </button> -->
                            </div>
                        </div>
                        <div class="col-5">
                            <!-- <b-input-group size="sm" prepend="Plan Item Link" class="mb-2">
                                <b-form-input type="text" v-model="erdTitle" :placeholder="erdTitle ? '' : '(Unnamed Plan)'" disabled></b-form-input>
                            </b-input-group> -->
                        </div>
                    </div>
                </div>
            </div>
            <b-card v-if="!elements.showErmAdd" no-body class="mt-3">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-effortEst>
                        <span>Effort Estimation</span>
                    </label>
                </b-card-header>
                <b-collapse id="accordion-effortEst" visible class="p-1" role="tabpanel">
                    <button v-if="!elements.showInlineControls" class="btn sbtn btn-primary btn-md float-right mb-2" @click.prevent="setEffortEstimationAdd"><i class="fa fa-plus-circle mr-2" aria-hidden="true"></i>New Effort Estimation</button>
                    <b-table show-empty small outlined stacked="md"  sort-icon-left :items="effortEstimations" :fields="effortEstimationFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(title)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <input type="text" v-model="effortEstimation.title" placeholder="Enter Title" class="form-control" :class="{ 'is-invalid': $v.effortEstimation.title.$error }" id="csTitle">
                                <label v-if="$v.effortEstimation.title.$error && !$v.effortEstimation.title.minLength" class="text-danger">Title must have at least {{$v.effortEstimation.title.$params.minLength.min}} letters.</label>
                                <label v-if="$v.effortEstimation.title.$error && !$v.effortEstimation.title.required" class="text-danger">Title is required</label>
                                <label v-if="$v.effortEstimation.title.$error && !$v.effortEstimation.title.maxLength" class="text-danger">Title must not exceed {{$v.effortEstimation.title.$params.maxLength.max}} letters.</label>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId"> {{ data.item.title }}</div>
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(ermTitle)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <div v-if="refErmTitle">{{ refErmTitle }}</div>
                                <!-- <div v-else class="row no-gutters">
                                    <div class="col">
                                        <select v-if="elements.showErmDropdown" v-model="effortEstimation.entityRecordMasterId" class="form-control" :class="{ 'is-invalid': $v.effortEstimation.entityRecordMasterId.$error }">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in ermRefLookup" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <b-form-input v-else type="text" v-model="effortEstimation.ermTitle" placeholder="Enter Est. Sheet Title"></b-form-input>
                                    </div>
                                    <div class="col-3 text-center">
                                        <button v-if="elements.showErmDropdown" class="btn sbtn btn-outline-primary px-2 py-1" @click="effortEstimation.entityRecordMasterId='';elements.showErmDropdown=false;" title="Add New Effort Estimation Sheet">
                                            <i class="fa fa-lg fa-plus-square-o"></i>
                                        </button>
                                        <button v-else class="btn sbtn btn-outline-primary px-2 py-1" @click="effortEstimation.ermTitle='';elements.showErmDropdown=true;" title="Select Existing Sheet">
                                            <i title="Select Existing Sheet" @click="" class="fa fa-lg fa-caret-down"></i>
                                        </button>
                                    </div>
                                </div>
                                <label v-if="$v.effortEstimation.entityRecordMasterId.$error && !$v.effortEstimation.entityRecordMasterId.required" class="text-danger">Effort Est. Group is required</label> -->
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId"> {{ data.item.ermTitle }}</div>
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(activity)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <select v-model="effortEstimation.activityId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId"> {{ data.item.activity }}</div>
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(resourceTitle)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <div class="row no-gutters">
                                    <div class="col-11">
                                        <b-input-group>
                                            <select v-model="effortEstimation.resourceId" class="form-control" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in resourceLookup" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <b-input-group-append class="append-0">
                                                <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                    <!-- <div class="col">
                                        <a :title="resourceLookupTitle" class="action-link" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                    </div> -->
                                </div>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId"> {{ data.item.resourceTitle }}</div>
                        </template>
                        <template v-if="elements.showInlineControls" v-slot:cell(resourceCount)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <b-form-input type="number" min="0" v-model="effortEstimation.resourceCount" @input="calcEffort">
                                </b-form-input>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId"> {{ data.item.resourceCount }}</div>
                        </template>
                        <template v-slot:cell(unitEffort)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <b-input-group size="sm" append="Man Hours" class="mb-2">
                                    <b-form-input type="number" min="0" v-model="effortEstimation.unitEffort" @input="calcEffort" ></b-form-input>
                                </b-input-group>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId || !elements.showInlineControls"> {{ data.item.unitEffort }} Man Hours</div>
                        </template>
                        <template v-slot:cell(effort)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <b-input-group size="sm" append="Man Hours" class="mb-2">
                                    <b-form-input type="number" min="0" v-model="effortEstimation.effort" ></b-form-input>
                                </b-input-group>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId || !elements.showInlineControls"> {{ data.item.effort }} Man Hours</div>
                        </template>
                        <!-- <template v-if="!elements.showInlineControls" v-slot:custom-foot="data">
                            <tr>
                                <td :colspan="12">
                                    <button class="btn sbtn btn-primary btn-block" @click="setEffortEstimationAdd"><i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Effort Estimation</button>
                                </td>
                            </tr>
                        </template> -->
                        <template v-slot:cell(planTitle)="data">
                            <div v-if="data.item.id == effortEstimationEditId">
                                <select v-model="effortEstimation.erdId" class="form-control" >
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in planList" :value="info.id">{{info.title}}</option>
                                </select>
                            </div>
                            <div v-if="data.item.id !== effortEstimationEditId || !elements.showInlineControls"> {{ data.value }}</div>
                        </template>
                        <!-- <template v-slot:cell(planItemLink)="data">
                            <div v-if="data.item.id == effortEstimationEditId && elements.showInlineControls">
                                -
                            </div>
                            <div v-else>
                                <button type="submit" @click="effortEstimationBulkAdd(data.item)" :class="isIdExists(data.item) ? 'active' : ''" class="btn btn-outline-primary sbtn btn-xs py-1 px-2 ml-2">
                                    <span v-if="isIdExists(data.item)">
                                        <i class="fa fa-check pr-1" aria-hidden="true"></i>Linked
                                    </span>
                                    <span v-else>Link to this Plan</span>
                                </button>
                            </div>
                        </template> -->
                        <template v-slot:cell(actions)="data">
                            <div v-if="data.item.id == effortEstimationEditId && elements.showInlineControls">
                                <div v-if="data.item.id == effortEstimationEditId">
                                    <a href="javascript:" title="Save" @click="saveEffortEstimation"><i class="fa fa-check text-success pl-2"></i></a>
                                    <a href="javascript:" title="Cancel" @click="cancelEffortEstEdit"><i class="fa fa-times text-danger pl-2"></i></a>
                                </div>
                            </div>
                            <div v-else >
                                <a href="javascript:" title="Edit" class="" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                <a href="javascript:" title="Delete Effort Estimation" class="" @click="deleteEffortEstimation(data.item.id)"v-b-modal.deleteEffortEstimation><i class="fa fa-trash ml-2"></i></a>
                            </div>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <div>
                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                        <span class="float-right">Total Effort: <span class="font-weight-bold">{{ totalEffort }} Man Hours</span></span>
                    </div>
                </b-collapse>
            </b-card>
            <!-- <b-card no-body class="mt-3">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-otherEff>
                        <span>Other Effort Estimation Links</span>
                    </label>
                </b-card-header>
                <b-collapse id="accordion-otherEff" class="p-1" role="tabpanel">
                    <b-table show-empty small stacked="md" sort-icon-left :items="effortEstimationOthersList" :fields="otherEffortEstimationFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Remove Reference" class="text-danger" @click=""><i class="fa fa-minus-circle pr-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No References found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                </b-collapse>
            </b-card> -->
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
        <AddResourceLookup :resourceLookupTitle="resourceLookupTitle" :resourceCategoryId="1" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteEffortEstimation" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Effort Estimation?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import AddResourceLookup from '@/components/popup/lookup/AddResourceLookup.vue';
export default {
    props: {
        id: { type: String, default: 'effortEstimationLinkPopup'},
        ermId: [String, Number],
        erdId: [String, Number],
        erdTitle: String,
        refEntity: String,
        refErmEntity: String,
        linkedEffortEstList: Array,
    },
    components: {
        AddResourceLookup,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
                showInlineControls: false,
                showErmAdd: false,
                showErmDropdown: false,
            },
            isDirectAdd: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: 'effort_estimation',

            memoryItem: {},
            resourceLookupTitle: 'Resources',

            selectedEffortEstimationList: [],
            effortEstimationEditId: '',
            refErmTitle: '',
            refErmId: '',
            ermData: {
                ermTitle: '',
                entityRecordMasterId: '',
            },

            effortEstimation: {
                title: '',
                effortEstimation: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'effort_estimation',
                effortEstimationTypeId: '',
                type: '',
                title: '',
                erdId: '',
                refEntityId: '',
                activityId: '',
                activity: '',
                resourceTypeId: '',
                resourceType: '',
                resourceTitle: '',
                resourceId: '',
                resourceCount: '',
                unitEffort: '',
                effortHour: '',
                unitCost: '',
                cost: '',
                description: '',
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                ermTitle: '',
                refErmEntity: '',
                refErmId: '',
                isNewErmCreation: false,
            },

            activities: [],
            planList: [],
            resourceLookup: [],
            effortEstimationList: [],
            ermRefLookup: [],
            ermNonRefLookup: [],
            effortEstimationFields: [
                { key: 'index', label: 'SN' },
                // { key: 'effortEstimationId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                // { key: 'ermTitle', label: 'Effort Est. Sheet', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'resourceTitle', label: 'Resource', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCount', label: 'Count', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'unitEffort', label: 'Unit Effort', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },
                { key: 'effort', label: 'Effort', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },
                { key: 'planTitle', label: 'Plan Item Link' },
                { key: 'actions', label: 'Actions' },
            ],

            // otherEffortEstimationFields: [
            //     { key: 'index', label: 'SN' },
            //     // { key: 'effortEstimationId', label: 'Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
            //     { key: 'erdId', label: 'Effort Est. Sheet', sortable: true, sortDirection: 'desc' },
            //     { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
            //     { key: 'resourceTitle', label: 'Resource', sortable: true, sortDirection: 'desc' },
            //     { key: 'resourceCount', label: 'Count', sortable: true, sortDirection: 'desc' },
            //     { key: 'unitEffort', label: 'Unit Effort', sortable: true, sortDirection: 'desc' },
            //     { key: 'effortHour', label: 'Effort', sortable: true, sortDirection: 'desc' },
            //     { key: 'planTitle', label: 'Linked Plan Item' },
            //     // { key: 'actions', label: 'Actions' },
            // ],

            totalRows: 0,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    validations: {
        effortEstimation: {
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            },
            entityRecordMasterId: {
                required,
            },
        },
        ermData: {
            entityRecordMasterId: {
                required: requiredIf(function () {
                    return this.elements.showErmDropdown;
                }),
            },
            ermTitle: {
                required: requiredIf(function () {
                    return !this.elements.showErmDropdown;
                }),
            }
        },
    },
    computed: {
        effortEstimations() {
            // let items = this.effortEstimationList.filter(data => data.erdId==null || data.erdId == this.erdId);
            let items = this.effortEstimationList;
            this.totalRows = items.length;
            return items;
        },
        ermRefLookupAll() {
            return this.ermRefLookup.concat(this.ermNonRefLookup);
        },
        // effortEstimationOthersList() {
        //     // let items = this.effortEstimationList;
        //     let items = this.effortEstimationList.filter(data => data.erdId!=null && data.erdId != this.erdId);
        //     this.totalRows = items.length;
        //     return items;
        // },
        totalEffort() {
            if(!this.effortEstimations) return 0;
            return this.effortEstimations.reduce((accum, item) => {
                return accum + (item.effort ?? 0)
            }, 0)
        },
    },
    mounted() {
        this.lookupData();
        this.getPlans();
        this.getEffortEstimationReference();
        this.loadEffortEstimationList();
    },
    methods: {
        getEffortEstimationReference: function() {
            if(this.ermId) {
                let data = {
                    entityRecordMasterId: this.ermId,
                    vhextAccountId: this.vhextAccountId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimationref/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.effortEstimationList = result.effortEstimationList;
                        this.ermRefLookup = result.ermRefLookup;
                        this.ermNonRefLookup = result.ermNonRefLookup;
                        this.totalRows = this.effortEstimationList.length;
                        if (this.ermRefLookup && this.ermRefLookup.length > 0) {
                            this.refErmId = this.ermRefLookup[0].id;
                            this.refErmTitle = this.ermRefLookup[0].value;
                            this.ermData.ermTitle = this.ermRefLookup[0].value;
                            this.ermData.entityRecordMasterId = this.ermRefLookup[0].id;
                            this.elements.showErmAdd = false;
                        } else {
                            this.elements.showErmAdd = false;
                            this.elements.showErmDropdown = true;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        getPlans: function() {
            let data = {
                entityRecordMasterId: this.ermId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.planList = result.planList;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                resourceCategoryId: 1,      // 1 - human resource
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.activities = result.activityLookup;
                    this.resourceLookup = result.resourceLookup;
                })

        },
        loadEffortEstimationList() {
            if (this.erdId) {
                this.isDirectAdd = true;
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    erdId: this.erdId,
                    refEntity: this.refEntity,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/erd/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.selectedEffortEstimationList = result.effortEstimationList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
            else {
                this.selectedEffortEstimationList = this.linkedEffortEstList && this.linkedEffortEstList.length>0 ? [...this.linkedEffortEstList] : [];
            }
        },
        saveEffortEstimation: function() {
            // if (this.ermRefLookup && this.ermRefLookup.length > 0) {
            //     this.effortEstimation.entityRecordMasterId = this.ermRefLookup[0].id;
            //     // this.elements.showErmDropdown = true;
            // }
            this.effortEstimation.entityRecordMasterId = this.ermData.entityRecordMasterId;
            this.$v.effortEstimation.$touch();
            this.$v.ermData.$touch();
            if (!this.$v.effortEstimation.$invalid) {

                // this.effortEstimation.isNewErmCreation = !this.elements.showErmDropdown;
                this.effortEstimation.refErmEntity = this.refErmEntity;
                this.effortEstimation.refErmId = this.ermId;

                if (this.elements.editMode) {
                    this.updateEffortEstimation();
                } else {
                    this.addEffortEstimation();
                }
            }
        },
        addEffortEstimation: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/add', this.effortEstimation)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEffortEstimationReference();
                        this.clearData();
                        this.effortEstimationEditId = '';
                        this.elements.showInlineControls = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateEffortEstimation: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/update', this.effortEstimation)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEffortEstimationReference();
                        this.clearData();
                        this.effortEstimationEditId = '';
                        this.elements.showInlineControls = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteEffortEstimation: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/plan/effortestimation/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEffortEstimationReference();
                        this.clearData();
                        this.effortEstimationEditId = '';
                        this.elements.showInlineControls = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        addEstimationSheetRef: function() {
            if (this.ermData.entityRecordMasterId) {
                this.ermData.ermTitle = this.getErmTitle(this.ermData.entityRecordMasterId);
            }
            this.$v.ermData.$touch();
            if (!this.$v.ermData.$invalid) {
                let ermData = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    ermTitle: this.ermData.ermTitle,
                    preparedById: this.userId,
                    refErmEntity: this.refErmEntity,
                    refErmId: this.ermId,
                    entityRecordMasterId: this.ermData.entityRecordMasterId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/ermref/add', ermData)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getEffortEstimationReference();
                            this.elements.showErmAdd = false;
                            this.$v.effortEstimation.$reset();
                            this.$v.ermData.$reset();
                        }
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        getEffortEstimationList: function() {
            if (this.ermData.entityRecordMasterId) {
                this.cancelEffortEstEdit();
                let data = {
                    entityRecordMasterId: this.ermData.entityRecordMasterId
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimation/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.effortEstimationList = result.effortEstimationList;
                        // this.totalRows = this.effortEstimationList.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        effortEstimationBulkAdd(data) {
            let isExists = this.isIdExists(data);
            if (isExists) {
                this.selectedEffortEstimationList = this.selectedEffortEstimationList.filter(item => item.id != data.id);
            } else {
                this.selectedEffortEstimationList.push(data);
            }
        },
        updatePlanEffortEstLink: function() {
            let data = {
                id: this.erdId,
                vhextAccountId: this.vhextAccountId,
                effort: this.totalEffort,
                effortEstimationList: this.selectedEffortEstimationList,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/plan/effortestimationlink/update', data)
                .then((response) => {
                    this.$bvModal.hide(this.id);
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-list');
                        this.getEffortEstimationReference();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        setEffortEstimationAdd: function() {
            this.clearData();
            this.effortEstimationList.push({ id: '', title: this.effortEstimation.title, activityId: this.effortEstimation.activityId, resourceId: this.effortEstimation.resourceId, resourceCount: this.effortEstimation.resourceCount, unitEffort: this.effortEstimation.unitEffort, effort: this.effortEstimation.effort });
            this.effortEstimationEditId = '';
            this.elements.showInlineControls = true;
            // this.elements.newTimeSheet = true;
        },
        cancelEffortEstEdit() {
            this.clearData();
            if (!this.elements.editMode) {
                this.effortEstimationList.pop();
            }
            this.elements.editMode = false;
            this.effortEstimationEditId = '';
            this.elements.showInlineControls = false;
        },
        showEdit: function(data) {
            if (!this.elements.editMode && this.elements.showInlineControls) {
                this.cancelEffortEstEdit();
            }
            this.elements.editMode = true;
            this.elements.showInlineControls = true;
            this.effortEstimationEditId = data.id;
            this.effortEstimation.id = data.id;
            this.effortEstimation.effortEstimationId = data.effortEstimationId;
            this.effortEstimation.vhextAccountId = data.vhextAccountId;
            this.effortEstimation.projectId = data.projectId;
            this.effortEstimation.entityRecordMasterId = data.entityRecordMasterId;
            this.effortEstimation.title = data.title;
            this.effortEstimation.effortEstimationTypeId = data.effortEstimationTypeId;
            this.effortEstimation.effortEstimationType = data.type;
            this.effortEstimation.erdId = data.erdId;
            this.effortEstimation.refEntityId = data.refEntityId;
            this.effortEstimation.activityId = data.activityId;
            this.effortEstimation.activity = data.activity;
            this.effortEstimation.resourceType = data.resourceType;
            this.effortEstimation.resourceTitle = data.resourceTitle;
            this.effortEstimation.resourceId = data.resourceId;
            this.effortEstimation.resourceCount = data.resourceCount;
            this.effortEstimation.unitEffort = data.unitEffort;
            this.effortEstimation.effort = data.effort;
            this.effortEstimation.unitCost = data.unitCost;
            this.effortEstimation.effortHour = data.effortHour;
            this.effortEstimation.effortType = data.effortType;            
            this.effortEstimation.cost = data.cost;
            this.effortEstimation.createdDt = data.createdDt;
            this.effortEstimation.lastUpdatedDt = data.lastUpdatedDt;
            this.effortEstimation.description = data.description;
        },
        clearData: function() {
            // this.elements.showErmDropdown = false;
            this.effortEstimation.id = '';
            this.effortEstimation.title = '';
            this.effortEstimation.effortEstimationTypeId = '';
            this.effortEstimation.activityId = '';
            this.effortEstimation.erdId = '';
            this.effortEstimation.refEntityId = '';
            this.effortEstimation.resourceTypeId = '';
            this.effortEstimation.resourceCount = '';
            this.effortEstimation.effortEstimationTypeId = '';
            this.effortEstimation.unitEffort = '';
            this.effortEstimation.effort = '';
            this.effortEstimation.effortHour = '';
            this.effortEstimation.description = '';
            this.effortEstimation.resourceTitle = '';
            this.effortEstimation.resourceId = '';
            this.$v.effortEstimation.$reset();
            this.$v.ermData.$reset();
        },
        getErmTitle(ermId) {
            let item = this.ermNonRefLookup.find(data => data.id == ermId);
            return item && item.value ? item.value : '';
        },
        calcEffort: function() {
            let resourceCount = this.effortEstimation.resourceCount ?? 0;
            let unitEffort = this.effortEstimation.unitEffort ?? 0;
            this.effortEstimation.effort = resourceCount * unitEffort;
        },
        isIdExists(data) {
            if(!this.selectedEffortEstimationList) return false;
            return this.selectedEffortEstimationList.some(item => item.id == data.id);
        },
        setEffortEstimationList() {
            if (this.isDirectAdd) {
                this.updatePlanEffortEstLink();
            }
            else {
                const totalEffort = this.selectedEffortEstimationList.reduce((sum, obj) => sum + (obj.effort ?? 0), 0);
                let effortEstimationList = [...this.selectedEffortEstimationList];
                this.$emit('onEffortEstimationAdd', effortEstimationList, totalEffort);
                this.$bvModal.hide(this.id);
            }
        },
        showEffSheetEdit() {
            this.ermData.ermTitle = '';
            this.ermData.entityRecordMasterId = this.refErmId;
            this.elements.showErmAdd = false;
            this.elements.showErmDropdown = true;
        },
        showEffSheetAdd() {
            this.ermData.ermTitle = '';
            this.ermData.entityRecordMasterId = '';
            this.elements.showErmDropdown = false;
            this.elements.showErmAdd = true;
            this.effortEstimationList = [];
            this.cancelEffortEstEdit();
        },
        showEffSheetDropdown() {
            this.ermData.ermTitle = '';
            this.elements.showErmDropdown = true;
            this.elements.showErmAdd = false;
        },
        cancelEffSheetAdd() {
            this.ermData.ermTitle = this.refErmTitle;
            this.ermData.entityRecordMasterId = this.refErmId;
            this.elements.showErmAdd = false;
            this.elements.showErmDropdown = false;
            this.getEffortEstimationList();
        },
        closeDialog() {
            // this.selectedEffortEstId = '';
            this.isDirectAdd = false;
            this.$emit('closeDialog');
        }
    }
}
</script>