<template>
    <div class="referenceDetailPopup">
        <b-modal id="processDetailPopup" @hide="closeDialog" ref="processDetailPopupRef" size="lg" modal-class="" title="Process & Activities" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="col-2 col-sm-2 p-0 m-0 px-0 float-right">
                                        <div v-if="popupData.active" class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Active">Active</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-10 col-sm-10 mb-3 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
<!--                                                 <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.processId }}</label> -->
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Process</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Process Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>            
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Activities</span></p>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-12 px-0">
                                        <!-- <div class="p-3"> -->
                                            <template v-for="activity in activities">
                                                <!-- <div class="card"> -->
                                                <div class="card-body">
                                                    <h6 class="card-title">{{activity.activity}}</h6>
                                                    <div class="table-responsive">
                                                        <table  class="table table-bordered processPlanReports-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Input</th>
                                                                    <th>Task</th>
                                                                    <th>Verify</th>
                                                                    <th>Output</th>
                                                                </tr>
                                                            </thead>
                                                            
                                                            <tbody>
                                                                <tr v-if="activity.input || activity.task || activity.verify || activity.output">
                                                                    <td>{{activity.input}}</td>
                                                                    <td>{{activity.task}}</td>
                                                                    <td>{{activity.verify}}</td>
                                                                    <td>{{activity.output}}</td>
                                                                </tr>
                                                                <tr v-else>
                                                                    <td colspan="4" class="text-center">No Data found...</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <!-- </div>                                         -->
                                            </template>
                                        <!-- </div> -->
                                        <!-- <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activities</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.activities}}</label>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created By</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.userName}}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.createdDt | formatDateTimeAmPm}}</label>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
    },
    data() {
        return {
            loading: true,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            popupData: {},
            activities: [],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.$nextTick(() => {
                this.$bvModal.show('processDetailPopup')
            });
            this.loading = true;
            console.log(id, refEntity);
                if (refEntity === "process") {
                    axios.get(this.$store.getters.getAPIBasePath + '/process/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.process;
                            console.log(this.popupData);
                            this.getProcessActivities(this.popupData.processId);
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                        this.getProcessActivities(this.popupData.processId);
                    });
                    this.loading = false;
                }
        },
        getProcessActivities: function(processId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                processId: processId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/activity/etvx/list',data)
                .then((response) => {
                    let result = response.data;
                    this.activities = result.processActivityEtvxList;
                    console.log(this.activities);
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>