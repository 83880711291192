<template>
    <div>
        <TSRMaster :ermMasterInfo="ermMasterInfo"></TSRMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import TSRMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        TSRMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Test Result Master",
                entity: 'test_result_master',
                refEntity: 'test_result',
                ermListRedirectURL: '/tsrmas/list',
                erdRedirectURL: '/testresult/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>`