<template>
    <div>
        <ul class="align-middle mt-auto px-0 mb-0">
            <li v-for="referenceList in refErdList" :title="referenceList.refErdTitle" @click="viewReference(referenceList)" class="badge badge-secondary b-form-tag d-inline-flex pointer my-1 ">
                <a href="#" class="text-white">
                    <span>
                        {{ referenceList.refErdTitle }}
                    </span>
                </a>
            </li>
        </ul>
        <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" />
        <ReqSpecReference v-if="showDialog" ref="refReqSpecRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <DocumentFolderReference v-if="showDialog" ref="refDocumentFolderRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <IssueReference v-if="showDialog" ref="refIssueRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefObjective v-if="showDialog" ref="refObjectiveRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefTestCase v-if="showDialog" ref="refTestCaseRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefUserRequirement v-if="showDialog" ref="refUserRequirementRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefScopeStatement v-if="showDialog" ref="refScopeStatementRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <ErrorReportReference v-if="showDialog" ref="refErrorReportRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
    </div>
</template>
<script>
import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import ReqSpecReference from '@/components/popup/reference/ReqSpecReference.vue';
import DocumentFolderReference from '@/components/popup/reference/DocumentFolderReference.vue';
import IssueReference from '@/components/popup/reference/IssueReference.vue';
import RefObjective from '@/components/popup/reference/RefObjective.vue';
import RefTestCase from '@/components/popup/reference/RefTestCase.vue';
import RefUserRequirement from '@/components/popup/reference/RefUserRequirement.vue';
import RefScopeStatement from '@/components/popup/reference/RefScopeStatement.vue';
import ErrorReportReference from '@/components/popup/reference/ErrorReportReference.vue';
export default {
    name: 'view-reference-component',
    props: {
        erdId: Number
    },
    components: {
        // ReqSpecDetailPopup: () => import('@/components/popup/itemdetail/ReqSpecDetailPopup.vue'),
        ReqSpecReference,
        ReferenceDetailPopup,
        TaskDetailPopup,
        DocumentFolderReference,
        IssueReference,
        RefObjective,
        RefTestCase,
        RefUserRequirement,
        RefScopeStatement,
        ErrorReportReference
    },
    data() {
        return {
            showDialog: false,

            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',

            refErdList: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
        }
    },
    watch: {
        erdId(val) {
            if (val) {
                this.getERDReferenceList();
            } 

        }
    },
    mounted() {
        this.getERDReferenceList();
    },
    methods: {
        getERDReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                erdId: this.erdId,
            }
            console.log(data)
            if (data.erdId != null && data.erdId != 0 && data.erdId != undefined) {
                axios.post(this.$store.getters.getAPIBasePath + '/common/erdreference/list', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.refErdList = result.erdReferenceList;
                        }
                    })
            }
        },
        viewReference: function(data) {
            console.log(data);
            this.referencePopupId = data.refErdId;
            this.refPopupTitle = data.refErdTitle;
            this.refPopupEntity = data.refEntity;
            console.log(this.refPopupEntity);
            console.log(this.$refs.reqSpecDetailPopupRef);
            this.showDialog = true;
            if (this.refPopupEntity == 'requirement_spec') {
                this.$nextTick(()=> {
                    this.$refs.refReqSpecRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'objective') {
                this.$nextTick(()=> {
                    this.$refs.refObjectiveRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'test_case') {
                this.$nextTick(()=> {
                    this.$refs.refTestCaseRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'user_requirement') {
                this.$nextTick(()=> {
                    this.$refs.refUserRequirementRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'scope_statement') {
                this.$nextTick(()=> {
                    this.$refs.refScopeStatementRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'document_folder') {
                this.$nextTick(()=> {
                    this.$refs.refDocumentFolderRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'issue') {
                this.$nextTick(()=> {
                    this.$refs.refIssueRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'error_report') {
                this.$nextTick(()=> {
                    this.$refs.refErrorReportRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$nextTick(()=> {
                    this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
                });
            }
            else {                
                this.$bvModal.show('referenceDetail')
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
                console.log(this.referencePopupId, this.refPopupEntity, data);
            }
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }
    }
}
</script>