<template>
    <div>
        <b-modal ref="shareModal" size="md" :id="id" @show="clearData" :title="shareModalTitle" :no-close-on-backdrop="true">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="12 mb-3 text-center fs-16">
                        <!-- <b-col cols="10"> -->
                            <b-form-group class="badge badge-light"  label="" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    id="share-group"
                                    @change="populateData"
                                    v-model="selected"
                                    :options="shareOptions"
                                    :aria-describedby="ariaDescribedby"
                                    button-variant="outline-primary"
                                    name="share-radio-options"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                        <b-col v-if="elements.showIndividual || elements.showClient" cols="12">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.ermShare.userList.$error }">
                                <multiselect v-model="ermShare.userList" :options="users" name="users" label="userPosition" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.ermShare.userList.$error" class="invalid-feedback">
                                <span v-if="!$v.ermShare.userList.checkNull" class="text-danger">Please select User.</span>
                            </div>
                        </b-col>
                        <b-col v-if="elements.showProjectTeam" cols="12">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.ermShare.projectTeamList.$error }">
                                <multiselect v-model="ermShare.projectTeamList" :options="projectTeamList" name="projectTeamList" label="title" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.ermShare.projectTeamList.$error" class="invalid-feedback">
                                <span v-if="!$v.ermShare.projectTeamList.checkNull" class="text-danger">Please select Project Team.</span>
                            </div>
                        </b-col>
                        <b-col v-if="elements.showDepartment" cols="12">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.ermShare.departmentList.$error }">
                                <multiselect v-model="ermShare.departmentList" :options="departmentList" name="departmentList" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.ermShare.departmentList.$error" class="invalid-feedback">
                                <span v-if="!$v.ermShare.departmentList.checkNull" class="text-danger">Please select Department.</span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="12">
                            <textarea v-model="ermShare.shareMessage" class="form-control" rows="4" maxlength="1000" placeholder="Enter Message"></textarea>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="7">
                            <div class="row ml-0">
                                <div class="col-4 pt-1 px-0">
                                    <span>Share Mode:</span>
                                </div>
                                <div class="col-8 pl-0">
                                    <b-form-group class="" label="" v-slot="{ ariaDescribedby }">
                                        <b-form-radio-group
                                            id="share-mode"
                                            v-model="ermShare.ermShareMode"
                                            :options="shareMode"
                                            :aria-describedby="ariaDescribedby"
                                            name="share-mode-options"                                    
                                        ></b-form-radio-group>
                                    </b-form-group>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="5 text-right">
                            <b-form-checkbox class="" v-model="ermShare.emailNotification" name="check-button" >Email Notification
                            </b-form-checkbox>                            
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveShare" class="float-right py-1 px-3 mr-2">Share</b-button>
                    <b-button variant="secondary" :disabled="showOverlay" size="sm" class="float-right py-1 px-3 mr-2" @click="cancel()">Cancel</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    props: {
        id: { type: String, default: 'shareModal' },
        masterData: Object,
        ermId: Number,
        sharedUrl: String,
        entity: String,
        shareModalTitle: {
            type: String,
            default: 'Share'
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showProjectTeam: false,
                showDepartment: false,
                showIndividual: true,
                showClient: false,
            },
            vhextAccountId: '',
            projectId: '',
            userId: '',
            isArchive: false,
            usersList: [],
            projectTeamList:[],
            departmentList:[],
            ermShare: {
                userList: [],
                sharedUrl:'',
                shareMessage:'',
                ermShareMode: 0,
                sharedById:'',
                entityRecordMasterId:'',
                refEntityId:'',
                emailNotification:'false',
                projectTeamList:[],
                departmentList:[]
            },
            selected: 1,
            shareOptions: [
                { text: 'Individual', value: 1 },
                { text: 'Department', value: 2 },  
                { text: 'Project Team', value: 3 },                
                { text: 'Client', value: 4 },                
            ],
            shareModeArray: [
                { text: 'View', value: 0 },
                { text: 'Edit', value: 1 },              
            ]
        }
    },
    validations: {
        ermShare: {
            userList: {
                checkNull: function(userList) {
                    return userList.length > 0
                },               
            },
            projectTeamList: {
                checkNull: function(projectTeamList) {
                return projectTeamList.length > 0
                }
            },
            departmentList: {
                checkNull: function(departmentList) {
                return departmentList.length > 0
                }
            }      
        }
    },
    computed: {
        users() {
            let users = this.usersList.map(user => ({ ...user, label: (user.userName + (user.position ? ' - ' + user.position : ''))}));
            users = users.filter(item => this.elements.showClient ? item.typeCode == 'CLI' : item.typeCode != 'CLI');
            return users;
        },
        shareMode() {
            return this.shareModeArray.filter(item => this.elements.showClient ? item.value==0 : item);
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.loadProjectTeam();
        this.loadDepartment();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.userId = localStorage.getItem("userId");
            this.ermShare.sharedById = localStorage.getItem("userId");
            this.ermShare.vhextAccountId = this.vhextAccountId;
            this.ermShare.projectId = this.projectId;
            this.ermShare.entity = this.entity;
            this.ermShare.sharedUrl = this.sharedUrl;
            console.log(this.ermShare.sharedUrl)
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/getuser', data)
                .then((response) => {
                    let result = response.data;
                    this.usersList = result.userLookup;
                    console.log(this.usersList);
                })
        },
        saveShare: function() {
            console.log(this.selected);
             switch (this.selected) {
                case 1:
                    this.$v.ermShare.userList.$touch();
                    if (this.$v.ermShare.userList.$invalid) {
                        return;
                    }
                    break;
                case 2:
                    this.$v.ermShare.departmentList.$touch();
                    if (this.$v.ermShare.departmentList.$invalid) {
                        return;
                    }
                    break;
                case 3:
                    this.$v.ermShare.projectTeamList.$touch();
                    if (this.$v.ermShare.projectTeamList.$invalid) {
                        return;
                    }
                    break;
                case 4:
                    this.$v.ermShare.userList.$touch();
                    if (this.$v.ermShare.userList.$invalid) {
                        return;
                    }
                    break;
            }
            this.ermShare.sharedUrl = this.sharedUrl;
            console.log(this.ermShare);     
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/review/share/doc', this.ermShare)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(response.data.status);
                    if (response.data) {
                        this.$bvModal.hide(this.id);
                        this.$emit('refreshShare', this.ermShare.entityRecordMasterId);
                        iziToast.success({
                            title: 'Success',
                            message: 'Document Shared.',
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },

        clearData: function() {
            this.ermShare.userList = [];
            this.ermShare.projectTeamList = [];
            this.ermShare.departmentList = [];
            // this.ermShare.shareMessage = '';
            this.$v.ermShare.userList.$reset();
            this.$v.ermShare.projectTeamList.$reset();
            this.$v.ermShare.departmentList.$reset();
        },
        onRefDataChange: function() {
            this.ermShare.vhextAccountId = this.vhextAccountId;
            this.ermShare.projectId = this.projectId;
            this.ermShare.sharedById = localStorage.getItem("userId");

            // this.ermId = this.masterData.refErmId;  
            this.ermShare.entityRecordMasterId = this.masterData.refErmId;                      
            this.ermShare.shareMessage = this.masterData.title;            
            this.ermShare.entity = this.masterData.refEntity;
        },
        populateData: function(value){
            this.clearData();
             switch (value) {
                case 1:
                    this.elements.showProjectTeam = false;
                    this.elements.showDepartment = false;
                    this.elements.showIndividual = true;
                    this.elements.showClient = false;
                    this.ermShare.ermShareMode = 0;
                    break;
                case 2:
                    this.elements.showProjectTeam = false;
                    this.elements.showDepartment = true;
                    this.elements.showIndividual = false;          
                    this.elements.showClient = false;      
                    this.ermShare.ermShareMode = 0;
                    break;
                case 3:
                    this.elements.showProjectTeam = true;
                    this.elements.showDepartment = false;
                    this.elements.showIndividual = false;
                    this.elements.showClient = false;
                    this.ermShare.ermShareMode = 0;
                    break;
                case 4:
                    this.elements.showProjectTeam = false;
                    this.elements.showDepartment = false;
                    this.elements.showIndividual = false;
                    this.elements.showClient = true;
                    this.ermShare.ermShareMode = 0;
                    break;
            }
        },
        loadProjectTeam: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: 'project_team_master',
                isArchive: this.isArchive,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.entityRecordMasterList && result.entityRecordMasterList.length > 0) {                        
                        this.projectTeamList = result.entityRecordMasterList;
                        console.log(this.projectTeamList)
                    }
                })
        },
        loadDepartment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: 'employee', 
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result.departmentLookup);
                    this.departmentList = result.departmentLookup;
                })

        }

    }  
}
</script>