<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Asset Report</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                                <label v-if="elements.filterVisible" class="my-2 py-1">Hide</label>
                                <label v-else class="my-2 py-1">Filter</label>
                            </div>                            
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row m-0">
                                            <!-- <div class="col-3">
                                                <div class="form-group">
                                                    <label>Purchase Date</label>
                                                    <input type="date" class="form-control" v-model="purchaseDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>Expiry Date</label>
                                                    <input type="date" class="form-control" v-model="expiryDate">
                                                </div>
                                            </div> -->
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="preparedBy">Asset Category</label>
                                                    <select v-model="assetCategoryId" class="form-control" >
                                                        <option value="">All Category</option>
                                                        <option v-for="(info, index) in assetCategories" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="preparedBy">Asset Type</label>
                                                    <select v-model="assetTypeId" class="form-control" >
                                                        <option value="">All Types</option>
                                                        <option v-for="(info, index) in assetTypes" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="reportFilter">Search Filter</label>
                                                    <input type="text" v-model="reportFilter" maxlength="100" class="form-control" id="reportFilter" placeholder="Search Text">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <button type="submit" @click="getAssetReport" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div v-if="assetTypeId" class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Asset Type</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ assetType }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="assetCategoryId" class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Asset Category</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ assetCategory }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title"><span>Assets [{{assets.length}}]</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">
                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="assets" :fields="assetFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
<!--                                                     <template v-slot:cell(assetCategory)="data">
                                                        <div v-if="data.item.isFirstOccurrence">{{data.item.isFirstOccurrence}}{{ data.item.assetCategory }}</div>                                                        
                                                    </template> -->

                                                    <template v-slot:cell(active)="data">
                                                        <b-form-checkbox class="ml-3" v-model="data.value" disabled >
                                                        </b-form-checkbox>
                                                    </template>
                                                    <template v-slot:cell(description)="data">
                                                        <span v-html="data.value"></span>
                                                    </template>
                                                    <template v-slot:empty="erm">
                                                        <div class="text-center">No Record Found</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            // parentValue: 'aa',

            purchaseDate: this.getDateValue(new Date()),
            expiryDate: this.getDateValue(new Date()),
            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),
            assetTypeId: '',
            assetCategoryId: '',
            reportFilter: '',

            isBusy: false,
            assetTypes: [],
            assetCategories: [],

            assetList: [],
            assetFields: [
                // { key: 'assetCategoryId', label: 'ID', sortable: true, sortDirection: 'desc' },                
                { key: 'assetCategory', label: 'Asset Category', sortable: true, sortDirection: 'desc' },                
                { key: 'assetType', label: 'Asset Type', sortable: true, sortDirection: 'desc' },
                { key: 'itemCount', label: 'Count', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        assets() {
            if (this.assetCategoryId) {
                if(this.assetTypeId){
                    return this.assetList.filter(item => item.assetCategoryId == this.assetCategoryId && item.assetTypeId == this.assetTypeId);
                }else{
                    return this.assetList.filter(item => item.assetCategoryId == this.assetCategoryId);
                }
                
            }else {
                if(this.assetTypeId){
                    return this.assetList.filter(item => item.assetTypeId == this.assetTypeId);
                }else{
                    return this.assetList;
                }
            }
        },

        assetType() {
            if (this.assetTypes.length>0) {
                return this.assetTypeId ? this.assetTypes.find(data => data.id == this.assetTypeId).value : '';
            }
        },
        assetCategory() {
            if (this.assetCategories.length>0) {
                return this.assetCategoryId ? this.assetCategories.find(data => data.id == this.assetCategoryId).value : '';
            }
        },

        // modifiedData() {
        //   const nameOccurrences = {};
        //   return this.assetList.map((item) => {
        //     const isFirstOccurrence = !nameOccurrences[item.assetCategory];
        //     nameOccurrences[item.assetCategory] = true;
        //     return { ...item, isFirstOccurrence };
        //   });
        // },        
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
        this.getAssetReport();
        // if (this.assets.length > 0) {
        //   this.assignValue(this.tableData[0].assetCategoryId);
        // }        
    },
    methods: {
        getAssetReport: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                // startDate: this.purchaseDate,
                // endDate: this.expiryDate,
                assetTypeId: this.assetTypeId,
                assetCategoryId: this.assetCategoryId,
            }
            this.startDate = this.purchaseDate;
            this.endDate = this.expiryDate;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/assetsummary/report', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.assetList = this.searchFilter(result.assetSummaryList, this.reportFilter);
                    this.totalRows = this.assetList.length;
                    this.totalRows = this.assets.length;
                })
                .catch(error => {
                    console.log(error)
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/asset/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.assetTypes = result.customTypeLookup;
                    this.assetCategories = result.customCategoryLookup;
                })
        },
        empAttendeesPercentage(data) {
            let totalCount = data.totalPresent + data.totalFirstHalfLeave + data.totalSecondHalfLeave + data.totalFullDayLeave;
            let empTotalPresent = data.totalPresent;
            let empTotalHalfDayLeave = data.totalFirstHalfLeave + data.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((empTotalPresent+(empTotalHalfDayLeave/2))/totalCount) * 100).toFixed(1) : 0);
        },
        // assignValue(value) {
        //   // Assign the value to the external variable

        //   this.parentValue = value;
        //   console.log(this.parentValue);
        //   // // Return the value to be displayed in the template
        //   // return value;
        // },       
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.purchaseDate = this.getDateValue(this.startDate);
            this.expiryDate = this.getDateValue(this.endDate);
        },
        clearData: function() {    
            this.startDate = this.getDateValue(new Date());
            this.endDate = this.getDateValue(new Date());
            this.purchaseDate = this.getDateValue(new Date());
            this.expiryDate = this.getDateValue(new Date());
            this.assetTypeId = '';
            this.assetCategoryId = '';
            this.getAssetReport();
        },
        searchFilter(array, keyWord) {
            let result = array.filter(obj => Object.values(obj).some(val => (String(val).toLowerCase()).includes(keyWord.toLowerCase())));
            return result;
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>