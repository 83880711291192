<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="row">
                <div class="col-12 text-left">
                    <div class="card-body p-0 mb-1">
                        <div class="row">
                            <div class="col-6 col-sm-6">
                                <div class="row">
                                    <div class="col-4 pr-0">
                                        <label class="mb-0 py-2 d-block title-block">Employee Name</label>
                                    </div>
                                    <div class="col-8 pl-0">
                                        <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.employeeName }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 col-sm-3">
                                <div class="row">
                                    <div class="col-3 pr-0">
                                        <label class="mb-0 py-2 d-block title-block">EID</label>
                                    </div>
                                    <div class="col-9 pl-0">
                                        <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.eid }} </label>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-3 col-sm-3">
                                <div class="row">
                                    <div class="col-4 pr-0">
                                        <label class="mb-0 py-2 d-block title-block">Period</label>
                                    </div>
                                    <div class="col-8 pl-0">
                                        <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ getMonthYearAlt(popupData.payrollPeriodStr) }}</label>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <h6 class="contact-title mb-2 ml-2"><span>Change Request</span></h6>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Designation</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.deignation}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="float-right">
                                    <h6 class=""><label class="badge text-white" :class=leaveStatusVariant(popupData.status)  title="Status">{{ popupData.status }}</label></h6>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Department</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.deignation}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Job Category</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.jobCategory}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Salary (per annum)</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.salary}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Designation</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.deignation}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Department</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.deignation}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Effective From</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.wefDate}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Reporting To</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.reportingTo}}</label>
                                    </div>

                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row mb-2">
                                    <div class="col-6 col-md-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Person Responsible</label>
                                    </div>
                                    <div class="col-6 col-md-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.employmentChangeBy}}</label>
                                    </div>

                                </div>
                            </div>
                            <div v-if="attachmentLength" class="col-12 col-sm-12">
                                <div class="row mt-0 mb-2">
                                    <div class="col-12 col-md-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Attachment</label>
                                    </div>
                                    <div class="col-12 col-md-10 pl-0">
                                        <div class="col-12">
                                            <div class="mt-2">
                                                <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentLength" :attachmentData="attachmentData" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <h6 class="contact-title mb-2 ml-2"><span>Position Change History</span></h6>
                            <div class="col-12 mt-2">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-left">
                                            <div class="dataTables_length">
                                                <label class="d-block accordion-title" for="statusId">Transaction History</label>
                                            </div>
                                        </div>
                                        <div class="float-right">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table show-empty small class="preview-table" outlined stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="empHistoryList" :fields="serviceHistoryFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <!-- <a href="javascript:" title="Edit" @click="showEdit(data.item)">
                                            <i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i>
                                        </a>
                                        <a href="javascript:" title="Delete" @click="deleteEmploymentHistory(data.item.id)"  v-b-modal.deleteEmploymentHistory><i class="fa fa-trash pl-2"></i></a> -->
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Transactions found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="empHistoryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    name: 'employmentChangeDetailComponent',
    props: {
        detailViewData: Object,
        attachmentData: Object
    },
    components: {
        AttachmentView,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'employment_change',
            popupData: {},
            attachmentList: {},
            attachmentLength: 0,
            itemDetailTitle: 'Employee Position Change',

            empHistoryList: [],
            empHistoryFields: [
                { key:'index',label: 'SN' },
                { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },
                { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'salary', label: 'Salary', sortable: true, sortDirection: 'desc' },
                { key: 'wefDate', label: 'Effective Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.showOverlay = true;
            console.log(id, refEntity);
            if (refEntity === "employment_change") {
            console.log(id, refEntity);
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/hrm/view/' + id)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.leaveTime) {
                            this.popupData = result.leaveTime;
                            this.$nextTick(() => {
                                this.$emit('getItemTitle', this.itemDetailTitle);
                            })
                            if (this.popupData.employeeId) {
                                this.getEmploymentHistory();
                            }
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        console.log(error);
                    });
            } else {
                this.popupData = this.detailViewData;
                if (this.popupData.employeeId) {
                    this.getEmploymentHistory();
                }
                // this.$nextTick(() => {
                //     this.$bvModal.show('leaveTimeDetailPopup');
                // });
                this.$nextTick(() => {
                    this.$emit('getItemTitle', this.itemDetailTitle);
                })
                this.showOverlay = false;
            }
        },
        getEmploymentHistory: function() {
            if (this.employeeId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    employeeId: this.popupData.id,
                }
                // this.showHRDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/list', data)
                    .then((response) => {
                        // this.showHRDetOverlay = false;
                        let result = response.data;
                        this.empHistoryList = result.employmentHistoryList;
                    })
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getHalfDayLeave(value) {
            let result;
            if (value == 2) {
                result = '1st Half Day';
            } else if (value == 3) {
                result = '2nd Half Day';
            } else {
                result = '';
            }
            return result;
        },
        leaveStatusVariant(status) {
            let className = '';
            if (status == 'Applied') {
                className = 'bg-warning';
            }
            else if (status == 'Approved') {
                className = 'bg-success';
            }
            else if (status == 'Rejected') {
                className = 'bg-danger';
            }
            else {
                className = 'bg-info';
            }
            return className;
        },
        getAttachmentLength(length) {
            this.attachmentLength = length;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>