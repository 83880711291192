<template>
    <div class="fixed-inner-header">
        <div class="container-fluid ">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Contract</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Project" :to="{ name: 'contractedit' }"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group ">
                                            <label for="title">Title</label>
                                            <input type="text" disabled class="form-control" v-model="contract.title" id="title"  placeholder="Enter Title">
                                        </div>
                                    </div>
                                    <div class="col-2 pl-0">
                                        <div class="form-group ">
                                            <label for="displayName">Short Name</label>
                                            <input type="text" disabled class="form-control" v-model="contract.displayName" id="displayName" placeholder="Enter Display Name">
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="contractTypeId">Contract Type</label>
                                            <input type="text" disabled class="form-control" v-model="contract.contractType" id="contractTypeId">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="contractManager">Contractor</label>
                                            <input type="text" disabled class="form-control" v-model="contract.contractor" id="contractManager">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group select-box-3">
                                            <label for="duration">Duration</label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <b-input-group append="years">
                                                        <select disabled v-model="contract.durationYears" class="form-control">
                                                            <option value="">Sl</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                
                                                <div class="col-4 pl-0">
                                                    <b-input-group append="months">
                                                        <select disabled v-model="contract.durationMonths" class="form-control">
                                                            <option value="">Sl</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-4 pl-0">
                                                    <b-input-group append="days">
                                                        <select disabled v-model="contract.durationDays" class="form-control">
                                                            <option value="">Sl</option>
                                                            <option v-for="(info, index) in daysList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="startDate">Start Date</label>
                                            <div>
                                                <input disabled v-model="contract.startDate" type="date" class="form-control" placeholder="Start Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="endDate">End Date</label>
                                            <div>
                                                <input disabled v-model="contract.endDate" type="date" class="form-control" placeholder="End Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Budget</label>
                                            <VhextMoneyControl disabled :value="Number(contract.budget)" :key="vmcComponentKey" disabled @money-input="moneyControlInput" />
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <Editor disabled api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="contract.description"></Editor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="accessType">Project Id</label>
                                            <multiselect disabled v-model="projectLinkList" :options="projectLookup" name="project" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="contractStatus">Contract Status</label>
                                            <input type="text" disabled class="form-control" v-model="contract.status" id="contractStatus">
                                        </div>
                                    </div>
                                    <!-- <div class="col-6">
                                        <div class="form-group">
                                            <label for="country">Country</label>
                                            <select v-model="contract.countryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Location</label>
                                            <input type="text" v-model="contract.location" class="form-control" id="location" placeholder="Location">
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="currency">Currency</label>
                                            <select v-model="contract.currencyId" class="form-control">
                                                <option v-for="(info, index) in currencies" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="timezone">Timezone</label>
                                            <Select2 v-model="contract.timeZone" :options="timeZone" :settings="{width:'100%'}" />
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="timezone">Attachment</label>
                                            <AttachmentComponent ref="attachmentControl" viewAttachment :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <!-- <TypeLookup id="contractTypeModal" ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import Select2 from 'v-select2-component';
import month from '@/json/month.json';
import days from '@/json/days.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import Editor from '@tinymce/tinymce-vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    name: 'app',
    components: {
        // CategoryLookup,
        AttachmentComponent,
        Editor,
        Multiselect,
        Select2,
        TypeLookup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            vmcComponentKey: 0,
            entity: 'contract',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            contractId: localStorage.getItem("contractId"),
            contractorId: localStorage.getItem("contractorId"),

            typeEntity: 'contract_type',
            typeLookupTitle: 'Contract Type Settings',
            monthList: month,
            daysList: days,
            contractTypes: [],
            projectLookup: [],
            contractStatus: [],
            // timeZone: [],
            contractors: [],
            // countries: [],
            // currencies: [],
            addMore: [],

            contract: {
                id: '',
                contractId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                contractTypeId: '',
                contractorId: '',
                title: '',
                description: '',
                displayName: '',
                startDate: '',
                endDate: '',
                durationDays: '',
                durationMonths: '',
                durationYears: '',
                budget: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                projectLinkList: [],
                attachments: [],
                entity: 'contract',
            },
            projectLinkList: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            attachmentInfo: {
                title: 'Attachment',
                successMessage: false
            },
            attachments: [],

        }
    },
    mounted() {
        this.viewContract();
    },
    validations: {
        contract: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            // displayName: {
            //     maxLength: maxLength(10)
            // },
            contractorId: {
                required
            }
        }
    },
    methods: {
        viewContract: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/subcontract/contract/view/' + this.contractId)
                .then((response) => {
                    let result = response.data;
                    if (result.contract) {
                        this.contract = result.contract;
                        this.vmcComponentKey += 1;
                        this.$nextTick(() => {
                            this.getProjectLink();
                            this.$refs.attachmentControl.getAttachmentData(this.entity, this.contract.id, this.vhextAccountId);
                        })
                    }
                })
        },
        getProjectLink: function() {
            let data = {
                entity: this.entity,
                refRecordId: this.contract.id,
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/subcontract/projectlink/lookup', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.projectLinkList = result.projectLinkLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })
        },
        moneyControlInput: function(value) {
            this.contract.budget = value;
        },
    }
}
</script>