import ReqDevMasterList from '@/views/development/ReqDevMasterList.vue'
import RequirementDev from '@/views/development/RequirementDev.vue'
import WBSMasterList from '@/views/development/WBSMasterList.vue'
import WBS from '@/views/development/WBS.vue'

export default [
	{
        path: '/rqdmas/list',
        name: 'rqdmaslist',
        component: ReqDevMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/reqdev/:ermId',
        name: 'reqdev',
        component: RequirementDev,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
	{
        path: '/wbsmas/list',
        name: 'wbsmaslist',
        component: WBSMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/reqwbs/:ermId',
        name: 'reqwbs',
        component: WBS,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

];    