<template>
    <div>
        <div v-if="!viewAttachment" class="row">
            <b-overlay :show="showOverlayAttach" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div class="col-12 col-sm-12">
                    <div class="form-group">
                        <label for="fromEmail">{{attachmentInfo.title}}</label>
                        <label v-if="!singleFileUpload || (singleFileUpload && attachments.concat(filteredAttachmentList).length<1)" class="pl-2 action-link pointer">
                            <i title="attach a file" class="fa fa-paperclip fa-lg" aria-hidden="true"></i>
                            <input ref="add_attachment" id="attachments" type="file" accept=".doc,.docx,.pdf,.DOCX,image/*" class="file-upload" value="Upload File" @change="uploadFile">
                        </label>
                        <div>
                            <span v-for="(info, index) in attachments">
                                {{info.name}}
                                <a class="pl-2" href="javascript:" tilte="delete" @click="popAttachment(index)">
                                    <i class="fa fa-close action-link"></i>
                                </a>
                            </span>
                            <span v-for="(attachment, index) in filteredAttachmentList" >
                                <a @click="redirectPage(attachment)" href="javascript:" class="action-link" >{{attachment.fileName}}</a>
                                <a class="pl-2" href="javascript:" @click="deleteFile(attachment)" title="Delete attachment">
                                    <i class="fa fa-close action-link"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </b-overlay>    
        </div>
        <div v-if="viewAttachment" class="row">
            <div class="col-12 col-sm-12">
                <div class="form-group my-auto">
                    <span v-for="(attachment, index) in filteredAttachmentList" class="pr-2" >
                        <a @click="redirectPage(attachment)" href="javascript:" class="action-link" >{{attachment.fileName}}</a>
                    </span>
                </div>
            </div>
        </div>
        <DeleteComponent :id="id" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    name: 'app',
    props: {
        singleFileUpload: Boolean,  // to restrict upload to a single file
        excludeDocType: Boolean,    // to add/show files that do not contains doc type
        preventLinkRedirect: Boolean,   // to prevent link from redirecting (can be used for embeded controls)
        attachmentInfo: Object,
        attachments: Array,
        viewAttachment: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: 'deleteAttachmentAttComp'
        },
    },
    components: {

    },
    data() {
        return {
            showOverlayAttach: false,
            attachmentList: [],
            deleteParam: {
                id: 0,
                refEntityId: '',
                refRecordId: ''
            },
        }
    },
    computed: {
        filteredAttachmentList() {
            if (this.excludeDocType) {
                return (this.attachmentList && this.attachmentList.length>0) ? this.attachmentList.filter(data => !data.documentTypeId) : [];
            }
            else
                return this.attachmentList;
        }
    },
    mounted() {

    },
    validations: {

    },
    methods: {
        uploadFile: function(e) {
            this.attachments.push(this.$refs.add_attachment.files[0]);
            e.target.value = null;
        },
        popAttachment(index) {
            this.$delete(this.attachments, index);
        },
        saveAttachment: function(entity, refRecordId) {
            let projectId = localStorage.getItem("projectId");

            if (this.attachments != null && this.attachments.length > 0) {
                let formData = new FormData();
                for (const row of Object.keys(this.attachments)) {
                    formData.append('files', this.attachments[row]);
                }
                if (projectId && projectId != 'null') {
                    formData.append('projectId', projectId);
                }
                formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
                formData.append('attachedById', localStorage.getItem("userId"));
                formData.append('refEntity', entity);
                formData.append('refRecordId', refRecordId);
                return axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        let result = response.data;
                        if (result.status == "OK" && this.attachmentInfo.successMessage == 'true') {                        
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearAttachment();
                        }
                    })
                    .catch(error => {
                        console.log('Error Attachment uploaded');
                    });
            }
        },
        getAttachmentData: function(entity, refRecordId, vhextAccountId, projectId) {
            let data = {
                refEntity: entity,
                refRecordId: refRecordId,
                vhextAccountId: vhextAccountId,
                projectId: projectId
            }
            console.log(data)
            this.attachmentList = {};
            this.showOverlayAttach = true;
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                    this.showOverlayAttach = false;
                })
                .catch(error => {
                    this.showOverlayAttach = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })
        },
        deleteFile: function(attachment) {
            this.$bvModal.show(this.id);
            this.$nextTick(() => {
                this.deleteParam.id = attachment.id;
                this.deleteParam.refEntityId = attachment.refEntityId;
                this.deleteParam.refRecordId = attachment.refRecordId;
            })
        },
        onYes: function() {
            this.showOverlayAttach = true;
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/delete',this.deleteParam)
                .then(response => {

                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'center'
                        });
                        this.getAttachmentByRefEntityId(this.deleteParam.refEntityId, this.deleteParam.refRecordId, localStorage.getItem("vhextAccountId"), localStorage.getItem("projectId"));
                    }
                })
                .catch(error => {
                    this.showOverlayAttach = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })
        },
        getAttachmentByRefEntityId: function(refEntityId, refRecordId, vhextAccountId, projectId) {
            let data = {
                refEntityId: refEntityId,
                refRecordId: refRecordId,
                vhextAccountId: vhextAccountId,
                projectId: projectId
            }
            console.log(data)
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/view', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                    this.showOverlayAttach = false; 
                    if(this.attachmentList.length==0){
                        this.$emit('resetAttachmentStatus',refRecordId);
                    }                   
                })
                .catch(error => {
                    this.showOverlayAttach = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })                
        },
        redirectPage(data) {
            if (this.preventLinkRedirect) {
                this.$emit("attachmentClick", data);
            }
            else {
                window.open(data.attachmentPath, '_blank');
            }
        },
        hasAttachment() {
            if (this.attachments.length>0 || this.attachmentList.length>0)
                return true;
            else return false;
        },
        clearAttachment() {
            this.attachmentList = [];
        }
    }
}
</script>