<template>
    <div>
        <FeedbackFormMasterList :ermMasterInfo="ermMasterInfo"></FeedbackFormMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FeedbackFormMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        FeedbackFormMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Feedback Form List",
                entity: 'feedback_form_master',
                refEntity: 'feedback_form',
                tooltip: 'New Feedback Form',
                redirectURLName: 'feedbackform',
                newEntityMasterURL: 'fbfmas',
                redirectURL: '/fbfmas',
                actionName: 'Feedback Form',
                previewURLName: 'fbfrep'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURLName = 'srvfeedbackform';
            this.ermMasterInfo.newEntityMasterURL = 'srvfbfmas';
            this.ermMasterInfo.redirectURL = '/service/fbfmas';
            this.ermMasterInfo.actionName = 'Feedback Form';
            this.ermMasterInfo.previewURLName = 'srvfbfrep';
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURLName = 'clifeedbackform';
            this.ermMasterInfo.newEntityMasterURL = 'clifbfmas';
            this.ermMasterInfo.redirectURL = '/client/fbfmas';
            this.ermMasterInfo.actionName = 'Feedback Form';
            this.ermMasterInfo.previewURLName = 'clifbfrep';
        } else {
            this.ermMasterInfo.redirectURLName = 'feedbackform';
            this.ermMasterInfo.newEntityMasterURL = 'fbfmas';
            this.ermMasterInfo.redirectURL = '/fbfmas';
            this.ermMasterInfo.actionName = 'Feedback Form';
            this.ermMasterInfo.previewURLName = 'fbfrep';


        }
    },
    methods: {}
}
</script>