<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">User Activity Log</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                            <span>Filter</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div class="row mb-2">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="duration">User</label>
                                                        <select v-model="userActivityLog.user" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label>Start Date</label>
                                                        <input type="date" class="form-control" v-model="userActivityLog.startDt">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label>End Date</label>
                                                        <input type="date" class="form-control" v-model="userActivityLog.endDt">
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="getActivityLogs" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Filter</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="userActivityLogs" @row-selected="onRowSelected" :fields="activityLogFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
<!--                                 <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteActivityLog(data.item.id)" data-toggle="modal" data-target="#deleteActivityLog"><i class="fa fa-trash pl-2"></i></a>
                                </template> -->
                                <template v-slot:empty="scope">
                                    <div class="text-center">No User Activity Log found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="userActivityLogs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details" :active="elements.showDetail">
                                    <div class="row input-group-label">
                                        <div class="col-4">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{userActivityLogDetail.userActivityLogId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-5 pl-0">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="typeId">Functional Entity</label>
                                                <input type="text" class="form-control" :disabled="!elements.editMode" v-model="userActivityLogDetail.functionalEntity">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="typeId">Activity</label>
                                                <input type="text" class="form-control" :disabled="!elements.editMode" v-model="userActivityLogDetail.activity">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="typeId">Activity Type</label>
                                                <select v-if="elements.editMode" v-model="userActivityLogDetail.typeId" id="typeId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in activityLogTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="!elements.editMode" disabled v-model="userActivityLogDetail.activityLogType" type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="activityById">Activity By</label>
                                                <select v-if="elements.editMode" v-model="userActivityLogDetail.activityById" id="activityById" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in activityLogTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="!elements.editMode" disabled v-model="userActivityLogDetail.activityBy" type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="activityDt">Activity Date</label>
                                                    <input type="text" class="form-control disabled" :value="userActivityLogDetail.createdDt | formatDateTimeAmPm" readonly>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="typeId">Location</label>
                                                <input type="text" class="form-control" :disabled="!elements.editMode" v-model="userActivityLogDetail.location">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="typeId">Latitude</label>
                                                <input type="text" class="form-control" :disabled="!elements.editMode" v-model="userActivityLogDetail.latitude">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="typeId">Longitude</label>
                                                <input type="text" class="form-control" :disabled="!elements.editMode" v-model="userActivityLogDetail.longitude">
                                            </div>
                                        </div>
                                        <div class="col-12  col-sm-12">
                                            <div class="form-group">
                                                <label for="description">Description</label>
                                                <textarea rows="4" class="form-control" id="description" :disabled="!elements.editMode" v-model="userActivityLogDetail.description"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Change History" :active="elements.showDetail">
                                    <div class="col-12  col-sm-12">
                                        <div class="form-group">
                                            <label for="description">Functional Entity</label>
                                            <label for="description" class="form-control">{{userActivityLogDetail.functionalEntity}}</label>
                                        </div>
                                    </div>

                                    
                                    <div class="row">
                                        <div class="card-body pt-0">
                                            <div class="col-12 col-sm-12 mx-1">
                                                <vue-json-compare :oldData="userActivityLogDetail.oldRecordData" :newData="userActivityLogDetail.newRecordData"></vue-json-compare>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteActivityLog" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Activity Log?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import vueJsonCompare from 'vue-json-compare'
export default {
    name: 'app',
    components:{
        vueJsonCompare
    },
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
                typeEdit: false,
                typeVisible: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            userActivityLog: {
                entity: 'user_activity_log',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                userActivityLogId: '',
                functionalEntity: '',
                typeId: '',
                activityLogType: '',
                activity: '',
                activityById: localStorage.getItem("userId"),
                startDt: new Date().toISOString().substr(0, 10),
                endDt: new Date().toISOString().substr(0, 10),
                location: '',
                latitude: '',
                longitude: '',
                description: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            userActivityLogDetail: {
                entity: 'user_activity_log',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                userActivityLogId: '',
                functionalEntity: '',
                typeId: '',
                activityLogType: '',
                activity: '',
                activityById: localStorage.getItem("userId"),
                startDt: new Date().toISOString().substr(0, 10),
                endDt: new Date().toISOString().substr(0, 10),
                location: '',
                latitude: '',
                longitude: '',
                description: '',
                createdDt: '',
                lastUpdatedDt: '',
                oldRecordData: [],
                newRecordData: []
            },
            userActivityLogs: [],
            users: [],
            activityLogTypes: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            activityLogFields: [
                { key: 'userActivityLogId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'functionalEntity', label: 'Functional Entity', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'activityLogType', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'activityBy', label: 'Activity By', sortable: true, sortDirection: 'desc' },
                { key: 'createdDt', label: 'Activity Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                // { key: 'createdDt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.loadOnce = true;
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");
        this.getActivityLogs(this.$route.params.ermId);
        this.lookupData();
    },
    methods: {
        getActivityLogs: function() {
            let data = {
                activityById: this.userActivityLog.user,
                endDt: this.userActivityLog.endDt,
                startDt: this.userActivityLog.startDt,
                projectId: this.projectId,
                vhextAccountId: this.vhextAccountId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/useractivitylog/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result != null && result.length != 0) {
                        this.userActivityLogs = result.userActivityLogList;
                        console.log("UAL",result.userActivityLogList);

                        if (this.loadOnce && this.userActivityLogs != null && this.userActivityLogs.length > 0) {
                            this.elements.showDetail = true;
                            this.viewActivityLog(this.userActivityLogs[0].id);
                        }
                        this.elements.editMode = false;
                        this.totalRows = this.userActivityLogs.length;
                        this.loadOnce = false;
                    }
                    console.log(this.userActivityLogs);
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/useractivitylog/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.activityLogTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                })

        },
        updateActivityLog: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/useractivitylog/update', this.userActivityLogDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getActivityLogs();
                        this.viewActivityLog(this.userActivityLogDetail.id);
                    }
                })

        },
        deleteActivityLog: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/useractivitylog/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getActivityLogs();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.userActivityLogDetail.id = data.id;
                this.userActivityLogDetail.userActivityLogId = data.userActivityLogId;
                this.userActivityLogDetail.vhextAccountId = data.vhextAccountId;
                this.userActivityLogDetail.projectId = data.projectId;
                this.userActivityLogDetail.activityById = data.activityById;
                this.userActivityLogDetail.entityId = data.entityId;
                this.userActivityLogDetail.functionalEntity = data.functionalEntity;
                this.userActivityLogDetail.activity = data.activity;
                this.userActivityLogDetail.typeId = data.typeId;
                this.userActivityLogDetail.activityLogType = data.activityLogType;
                this.userActivityLogDetail.changeRecordId = data.changeRecordId;
                this.userActivityLogDetail.oldRecordData = JSON.parse(data.oldRecordData);
                this.userActivityLogDetail.newRecordData = JSON.parse(data.newRecordData);
                this.userActivityLogDetail.description = data.description;
                this.userActivityLogDetail.startDt = data.startDt;
                this.userActivityLogDetail.endDt = data.endDt;
                this.userActivityLogDetail.location = data.location;
                this.userActivityLogDetail.latitude = data.latitude;
                this.userActivityLogDetail.longitude = data.longitude;
                this.userActivityLogDetail.createdDt = data.createdDt;
                this.userActivityLogDetail.lastUpdatedDt = data.lastUpdatedDt;                
            }
        },
        showEdit: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = true;
            this.userActivityLogDetail.id = data.id;
            this.userActivityLogDetail.userActivityLogId = data.userActivityLogId;
            this.userActivityLogDetail.vhextAccountId = data.vhextAccountId;
            this.userActivityLogDetail.projectId = data.projectId;
            this.userActivityLogDetail.activityById = data.activityById;
            this.userActivityLogDetail.entityId = data.entityId;
            this.userActivityLogDetail.functionalEntity = data.functionalEntity;
            this.userActivityLogDetail.activity = data.activity;
            this.userActivityLogDetail.typeId = data.typeId;
            this.userActivityLogDetail.changeRecordId = data.changeRecordId;
            this.userActivityLogDetail.oldRecordData = data.oldRecordData;
            this.userActivityLogDetail.newRecordData = data.newRecordData;
            this.userActivityLogDetail.description = data.description;
            this.userActivityLogDetail.startDt = data.startDt;
            this.userActivityLogDetail.endDt = data.endDt;
            this.userActivityLogDetail.location = data.location;
            this.userActivityLogDetail.latitude = data.latitude;
            this.userActivityLogDetail.longitude = data.longitude;

            this.userActivityLogDetail.createdDt = data.createdDt;
            this.userActivityLogDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        showView: function(items) {
            let data = items;
            console.log(data);
            this.elements.showDetail = true;
            this.elements.editMode = false;
            this.userActivityLogDetail.id = data.id;
            this.userActivityLogDetail.userActivityLogId = data.userActivityLogId;
            this.userActivityLogDetail.vhextAccountId = data.vhextAccountId;
            this.userActivityLogDetail.projectId = data.projectId;
            this.userActivityLogDetail.activityById = data.activityById;
            this.userActivityLogDetail.entityId = data.entityId;
            this.userActivityLogDetail.functionalEntity = data.functionalEntity;
            this.userActivityLogDetail.activity = data.activity;
            this.userActivityLogDetail.typeId = data.typeId;
            this.userActivityLogDetail.activityLogType = data.activityLogType;
            this.userActivityLogDetail.changeRecordId = data.changeRecordId;
            this.userActivityLogDetail.oldRecordData = JSON.parse(data.oldRecordData);
            this.userActivityLogDetail.newRecordData = JSON.parse(data.newRecordData);
            this.userActivityLogDetail.description = data.description;
            this.userActivityLogDetail.startDt = data.startDt;
            this.userActivityLogDetail.endDt = data.endDt;
            this.userActivityLogDetail.location = data.location;
            this.userActivityLogDetail.latitude = data.latitude;
            this.userActivityLogDetail.longitude = data.longitude;

            this.userActivityLogDetail.createdDt = data.createdDt;
            this.userActivityLogDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        viewActivityLog: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/useractivitylog/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.userActivityLogDetail = result.userActivityLog;
                    console.log("UALS",this.userActivityLogDetail);
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewActivityLog(this.userActivityLogDetail.id);
        },
    }
}
</script>