<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-8">
                            <h4 class="pl-2 mb-0 fs-16">Expenditure</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <!-- <div class="comments-titel">
                                <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                            </div> -->
                            <div class="comments-cox mt-2">
                                <div class="row mb-2">
                                    <div class="col-3">
                                        <b-input-group prepend="Contract">
                                            <b-form-input v-model="contract.title" disabled></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col-3">
                                        <b-input-group prepend="Contractor">
                                            <b-form-input v-model="contract.contractor" disabled></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col-3">
                                        <b-input-group prepend="Project">
                                            <select v-model="companyExpenditure.projectId" @change="getExpenses" class="form-control" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectLinkLookup" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </b-input-group>
                                    </div>
                                    <div class="col-3">
                                        <b-input-group prepend="Contract">
                                            <b-form-input type="date" v-model="companyExpenditure.transactionDt" @change="getExpenses"></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <!-- <div class="row m-0">
                                    <div class="col-12">
                                        <button type="submit" @click="" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                        <button type="submit" @click="" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <i class="fa fa-plus-circle pr-2 action-link"></i>New Expense
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.companyExpenditure.title.$error }">
                                                                <input type="text" class="form-control" ref="title" v-model="companyExpenditure.title" id="title" :class="{ 'is-invalid': $v.companyExpenditure.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.companyExpenditure.title.$error && !$v.companyExpenditure.title.minLength" class="text-danger">Title must have at least {{$v.companyExpenditure.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.companyExpenditure.title.$error && !$v.companyExpenditure.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.companyExpenditure.title.$error && !$v.companyExpenditure.title.maxLength" class="text-danger">Title must not exceed {{$v.companyExpenditure.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="requirementTypeId">Quantity</label>
                                                            <input type="number" v-model="companyExpenditure.quantity" @input="setTotalCost" class="form-control" min="0" id="csQuantity">
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="csQuantity">Unit</label>
                                                            <select v-model="companyExpenditure.unitOfMesurement" class="form-control">
                                                                <option :value="{id: '', text:''}">Select</option>
                                                                <option v-for="(info, index) in uomTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="csPrice">Unit Cost</label>
                                                            <div class="input-group mb-2">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text">
                                                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                                                    </div>
                                                                </div>
                                                                <input type="number" v-model="companyExpenditure.unitCost" @input="setTotalCost" class="form-control" min="0" id="csPrice">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="csPrice">Total Cost</label>
                                                            <div class="input-group mb-2">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text">
                                                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                                                    </div>
                                                                </div>
                                                                <input type="number" v-model="companyExpenditure.cost" class="form-control" min="0" id="csPrice">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-body p-0 mb-2">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.descriptionAcc1>
                                                                        <label class="my-1">Description</label>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="descriptionAcc1" accordion="my-accordion2" role="tabpanel">
                                                                    <b-card-body class="p-2 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <!-- <label for="companyExpenditure">Description</label> -->
                                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="companyExpenditure.description"></editor>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveExpenditure" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <b-overlay :show="isBusy" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table outlined show-empty small v-model="visibleRows" stacked="md" sort-icon-left :items="companyExpenditures" :fields="companyExpenditureFields" foot-clone foot-variant=light no-footer-sorting responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-if="elements.editMode" v-slot:cell(title)="data">
                                        <div v-if="data.item.id == inlineEditId">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.companyExpenditureDetail.title.$error }">
                                                <input type="text" class="form-control" ref="title" v-model="companyExpenditureDetail.title" id="title" :class="{ 'is-invalid': $v.companyExpenditureDetail.title.$error }" placeholder="Enter Title">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.companyExpenditureDetail.title.$error && !$v.companyExpenditureDetail.title.minLength" class="text-danger">Title must have at least {{$v.companyExpenditureDetail.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.companyExpenditureDetail.title.$error && !$v.companyExpenditureDetail.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.companyExpenditureDetail.title.$error && !$v.companyExpenditureDetail.title.maxLength" class="text-danger">Title must not exceed {{$v.companyExpenditure.title.$params.maxLength.max}} letters.</label>
                                        </div>
                                        <div v-if="data.item.id !== inlineEditId"> {{ data.item.title }}</div>
                                    </template>
                                    <template v-if="elements.editMode" v-slot:cell(quantity)="data">
                                        <div v-if="data.item.id == inlineEditId">
                                            <input type="number" v-model="companyExpenditureDetail.quantity" @input="setTotalCostDetail" class="form-control" min="0" id="csQuantity">
                                        </div>
                                        <div v-if="data.item.id !== inlineEditId"> {{ data.item.quantity }}</div>
                                    </template>
                                    <template v-if="elements.editMode" v-slot:cell(uomType)="data">
                                        <div v-if="data.item.id == inlineEditId">
                                            <select v-model="companyExpenditureDetail.unitOfMesurement" class="form-control">
                                                <option :value="{id: '', text:''}">Select</option>
                                                <option v-for="(info, index) in uomTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                            </select>
                                        </div>
                                        <div v-if="data.item.id !== inlineEditId"> {{ data.item.uomType }}</div>
                                    </template>
                                    <template v-slot:cell(unitCost)="data">
                                        <div v-if="elements.editMode && data.item.id == inlineEditId">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                                    </div>
                                                </div>
                                                <input type="number" v-model="companyExpenditureDetail.unitCost" @input="setTotalCostDetail" class="form-control" min="0" id="csPrice">
                                            </div>
                                        </div>
                                        <div v-else>{{ currencySymbol }}{{ data.item.unitCost }}</div>
                                    </template>
                                    <template v-slot:cell(cost)="data">
                                        <div v-if="elements.editMode && data.item.id == inlineEditId">
                                            <div class="input-group mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">
                                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                                    </div>
                                                </div>
                                                <input type="number" v-model="companyExpenditureDetail.cost" class="form-control" min="0" id="csPrice">
                                            </div>
                                        </div>
                                        <div v-else>{{ currencySymbol }}{{ data.item.cost }}</div>
                                    </template>

                                    <template v-slot:cell(actions)="data">
                                        <div v-if="data.item.id == inlineEditId">
                                            <a v-if="elements.editMode" href="javascript:" title="Save" @click="updateExpenditure"><i class="fa fa-check text-success pl-2"></i></a>
                                            <a v-if="elements.editMode" href="javascript:" title="Cancel" @click="cancelEditMode"><i class="fa fa-times text-danger pl-2"></i></a>
                                        </div>
                                        <template v-if="!elements.editMode">
                                            <a href="javascript:" title="Edit" @click="showEdit(data.item)">
                                                <i class="fa fa-pencil-square-o fa-custom fa-lg"></i>
                                            </a>
                                            <a href="javascript:" title="Delete" @click="deleteCompanyExpense(data.item.id)" v-b-modal.deleteCompanyExpense class="ml-2"><i class="fa fa-trash"></i></a>
                                        </template>
                                    </template>
                                    <template v-slot:foot()="data">
                                        <span></span>
                                    </template>
                                    <template v-slot:foot(cost)="data">
                                        <div class="font-weight-bold">
                                            Sub Total : <span class="float-right-right">{{subTotal.toFixed(2)}}</span>
                                        </div>
                                    </template>
                                    <template v-slot:foot(actions)="data">
                                        <div class="font-weight-bold">
                                            TOT : <span class="float-right">{{totalAmount.toFixed(2)}}</span>
                                        </div>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Expenses Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>

                                    <template v-slot:foot()="data">
                                        <span></span>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                <b-pagination v-if="companyExpenditures.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteCompanyExpense" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Expense" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
export default {
    name: 'app',
    components: {
        Editor,
        AttachmentComponent,
    },
    data() {
        return {
            showOverlay: false,
            isBusy: false,
            elements: {
                editMode: false,
                visible: false,
            },
            showDialog: false,
            notEnabled: true,

            // reqStatusKey: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            contractId: localStorage.getItem("contractId"),
            contractorId: localStorage.getItem("contractorId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            currencySymbol: localStorage.getItem("currency"),

            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),

            htmlData: '',
            typeLookupTitle: 'Expense Type Settings',
            functionalRefPopupTitle: 'Add Reference',
            entity: 'contract',
            typeEntity: 'contract_type',

            inlineEditId: '',

            uomTypes: [],
            projectLinkLookup: [],

            companyExpenditure: {
                id: '',
                companyExpenditureId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                refEntityId: '',
                refEntity: 'contract',
                refRecordId: '',
                costEstimationTypeId: '',
                csCategoryId: '',
                title: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                unitCost: '',
                transactionDt: this.getDateValue(new Date()),
                cost: '',
                description: '',
                preparedById: localStorage.getItem("userId"),
            },

            companyExpenditureDetail: {
                id: '',
                companyExpenditureId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                refEntityId: '',
                refEntity: 'contract',
                refRecordId: '',
                costEstimationTypeId: '',
                csCategoryId: '',
                title: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                unitCost: '',
                transactionDt: this.getDateValue(new Date()),
                cost: '',
                description: '',
                preparedById: localStorage.getItem("userId"),
            },
            contract: {},
            
            companyExpenditures: [],
            companyExpenditureFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Item Title' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'uomType', label: 'Unit' },
                { key: 'unitCost', label: 'Unit Cost' },
                { key: 'cost', label: 'Total Cost' },
                { key: 'actions', label: 'Actions' }
            ],

            visibleRows: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            menuSetting: {
                height: 300,
                menubar: false,
            },
        }
    },
    computed: {
        totalAmount() {
            return this.companyExpenditures.reduce((accum, item) => {
                return accum + parseFloat(item.cost ? item.cost : 0)
            }, 0.00)
        },
        subTotal() {
            return this.visibleRows.reduce((accum, item) => { return accum + (item.cost ? item.cost : 0) }, 0.0)
        },
    },
    mounted() {
        this.lookupData();
        this.getExpenses();
    },
    validations: {
        companyExpenditure: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        companyExpenditureDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getExpenses: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                transactionDt: this.companyExpenditure.transactionDt,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.companyExpenditures = result.companyExpenditureList;
                        this.totalRows = this.companyExpenditures.length;
                        console.log(this.companyExpenditures);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: this.contractId,
                entity: 'contract',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/contract/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.uomTypes = result.customTypeLookup;
                    this.projectLinkLookup = result.projectLinkLookup;
                    this.contract = result.contract;
                })
        },
        saveExpenditure: function() {
            this.companyExpenditure.uomType = this.companyExpenditure.unitOfMesurement.text
            this.companyExpenditure.uomTypeId = this.companyExpenditure.unitOfMesurement.id;
            this.companyExpenditure.refRecordId = this.contractId;
            console.log(this.companyExpenditure);
            this.$v.companyExpenditure.$touch();
            if (!this.$v.companyExpenditure.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/add', this.companyExpenditure)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.getExpenses();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateExpenditure: function(data) {
            this.companyExpenditureDetail.uomType = this.companyExpenditureDetail.unitOfMesurement.text
            this.companyExpenditureDetail.uomTypeId = this.companyExpenditureDetail.unitOfMesurement.id;
            this.showOverlay = true;
            this.$v.companyExpenditureDetail.$touch();
            if (!this.$v.companyExpenditureDetail.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/update', this.companyExpenditureDetail)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        this.elements.otherInfoEditMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getExpenses();
                            this.cancelEditMode();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        deleteCompanyExpense: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/delete/' + id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getExpenses();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },        
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.visible = false;
            this.companyExpenditure.id = '';
            this.companyExpenditure.companyExpenditureId = '';
            // this.companyExpenditure.vhextAccountId = '';
            // this.companyExpenditure.projectId = '';
            this.companyExpenditure.refEntityId = '';
            this.companyExpenditure.refRecordId = '';
            this.companyExpenditure.costEstimationTypeId = '';
            this.companyExpenditure.csCategoryId = '';
            this.companyExpenditure.title = '';
            this.companyExpenditure.quantity = '';
            this.companyExpenditure.unitOfMesurement.id = '';;
            this.companyExpenditure.unitOfMesurement.text = '';;
            this.companyExpenditure.uomTypeId = '';
            this.companyExpenditure.unitCost = '';
            this.companyExpenditure.cost = '';
            // this.companyExpenditure.transactionDt = '';
            this.companyExpenditure.description = '';
            this.companyExpenditure.preparedById = this.userId;
            this.companyExpenditure.preparedBy = '';
            this.companyExpenditure.createdDt = '';
            this.companyExpenditure.lastUpdatedDt = '';
            this.$v.companyExpenditure.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            // this.$refs.title.$el.focus();
            this.elements.editMode = true;
            this.elements.visible = false;
            this.inlineEditId = data.id;
            this.companyExpenditureDetail.id = data.id;
            this.companyExpenditureDetail.companyExpenditureId = data.companyExpenditureId;
            this.companyExpenditureDetail.vhextAccountId = data.vhextAccountId;
            this.companyExpenditureDetail.projectId = data.projectId;
            this.companyExpenditureDetail.refEntityId = data.refEntityId;
            this.companyExpenditureDetail.refRecordId = data.refRecordId;
            this.companyExpenditureDetail.costEstimationTypeId = data.costEstimationTypeId;
            this.companyExpenditureDetail.csCategoryId = data.csCategoryId;
            this.companyExpenditureDetail.title = data.title;
            this.companyExpenditureDetail.quantity = data.quantity;
            this.companyExpenditureDetail.unitOfMesurement.id = data.uomTypeId;
            this.companyExpenditureDetail.unitOfMesurement.text = data.uomType;
            this.companyExpenditureDetail.uomTypeId = data.uomTypeId;
            this.companyExpenditureDetail.unitCost = data.unitCost;
            this.companyExpenditureDetail.cost = data.cost;
            this.companyExpenditureDetail.transactionDt = data.transactionDt;
            this.companyExpenditureDetail.description = data.description;
            this.companyExpenditureDetail.preparedById = data.preparedById;
            this.companyExpenditureDetail.createdDt = data.createdDt;
            this.companyExpenditureDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.companyExpenditure.id = data.id;
            this.companyExpenditure.companyExpenditureId = data.companyExpenditureId;
            this.companyExpenditure.vhextAccountId = data.vhextAccountId;
            this.companyExpenditure.projectId = data.projectId;
            this.companyExpenditure.refEntityId = data.refEntityId;
            this.companyExpenditure.refRecordId = data.refRecordId;
            this.companyExpenditure.costEstimationTypeId = data.costEstimationTypeId;
            this.companyExpenditure.csCategoryId = data.csCategoryId;
            this.companyExpenditure.title = data.title;
            this.companyExpenditure.quantity = data.quantity;
            this.companyExpenditure.unitOfMesurement.id = data.uomTypeId;
            this.companyExpenditure.unitOfMesurement.text = data.uomType;
            this.companyExpenditure.uomTypeId = data.uomTypeId;
            this.companyExpenditure.unitCost = data.unitCost;
            this.companyExpenditure.cost = data.cost;
            this.companyExpenditure.transactionDt = data.transactionDt;
            this.companyExpenditure.description = data.description;
            this.companyExpenditure.preparedById = data.preparedById;
            this.companyExpenditure.createdDt = data.createdDt;
            this.companyExpenditure.lastUpdatedDt = data.lastUpdatedDt;
        },
        cancelEditMode: function() {
            this.inlineEditId = '';
            this.elements.editMode = false;
        },
        setTotalCost: function() {
            this.companyExpenditure.cost = this.companyExpenditure.quantity * this.companyExpenditure.unitCost;
        },
        setTotalCostDetail: function() {
            this.companyExpenditureDetail.cost = this.companyExpenditureDetail.quantity * this.companyExpenditureDetail.unitCost;
        },

    }
}
</script>