<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header mx-0">
                <div class="col-12 px-0">
                    <div class="row">
                        <div class="col-sm-6 col-lg-8 pr-0 mr-0 my-auto">
                            <h4 class="pl-2 mb-0 fs-16">Calendar Settings</h4>
                        </div>
                        <div class="col-sm-3 col-lg-3 mt-0 mb-1">
                            <b-button class="float-right px-2" title="Company Calendar Settings" variant="outline-primary" @click="openCalEventPopup(null, false)" size="xs"><i class="fa fa-cog pr-1"></i>Calendar Settings</b-button>
                        </div>
                        <div class="col-sm-3 col-lg-1 my-auto">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 my-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card mb-4">
                            <div class="card-body">
                                <fullcalendar ref="evtCalendar" :key="calendarSettingKey" :calendarEvents="events" @date-click="handleDateClick" @event-selected="eventSelected" @mouse-hover-event="mouseHoverEvent" />
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <CalendarSettingPopup v-if="showDialog" ref="calEventPopupRef" @refreshData="getEvents" @closeDialog="closeDialog" />
        <MeetingPrepartionDetailPopup v-if="showDialog" ref="meetingPrepartionDetailPopupRef" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import CalendarSettingPopup from '@/components/popup/action/CalendarSettingPopup.vue';
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import event from '@/json/event.json'
export default {
    name: 'app',
    components: {
        Multiselect,
        CalendarSettingPopup,
        MeetingPrepartionDetailPopup
    },
    data() {
        return {
            // elements: {
            //     childDataLoaded: false,
            // },
            showOverlay: false,
            showDialog: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            calendarSettingKey: 0,
            calendarSetting: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                eventTypeId: '',
                calendarTypeId: '',
                categoryCode: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                description: '',
                createdById: localStorage.getItem("userId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                selectedDate: '',
                isMOMRecorder: false,
            },
            days: dayList,
            allEvents: [],
            filteredEvents: [],

    // {
    //   daysOfWeek: [0,6], //Sundays and saturdays
    //   rendering:"background",
    //   color: "#ff9f89",
    //   overLap: false,
    //   allDay: true
    // }

            ermLookup: [],

            ermObject: 'all',
        }
    },
    computed: {
        events() {
            return this.allEvents.map((event,index)=> {
                if (event.categoryCode == 'WKD') {
                    event.display = "background";
                    event.color =  "#dddddd";
                    event.textColor =  "black";
                    event.classNames = 'pt-4'
                    // event.overLap =  false;
                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek.split(',');
                    this.filteredEvents = event;
                }
                else if (event.categoryCode == 'HOL') {
                     if (event.allDay) {
                        event.color = '#FF4500';
                    }
                    else {
                        event.display = 'block';
                        event.classNames = 'bg-secondary';
                    }
                }
                else {
                    if (event.allDay) {
                        event.display = 'background';
                        event.color = 'red';
                    }
                    else {
                        event.display = 'list-item';
                        event.classNames = 'bg-secondary';
                    }
                    this.filteredEvents = event;
                }
                return event;
            })
        },
    },
    mounted() {
        this.getEvents();
    },
    methods: {
        getEvents: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                calendarTypeId: 1
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/cmpcalendarsetting/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.allEvents = result.cmpCalendarSettingList;
                    this.calendarSettingKey++;

                    this.openCalEventPopup(null, false);

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        eventSelected: function(event) {
            let calendarProps = event.extendedProps;
            this.openCalEventPopup(event, true);
        },
        openCalEventPopup: function(event, flag) {
            console.log(event, flag)
            if (flag == true) {
                let calendarProps = event.extendedProps;
                this.calendarSetting.id = calendarProps.eventId;
                this.calendarSetting.title = event.title;
                this.calendarSetting.isAllDayEvent = event.allDay;
                this.calendarSetting.selectedDate = event._instance.range.start;

                if (event._def.recurringDef) {
                    let recurData = event._def.recurringDef.typeData;
                    this.calendarSetting.daysToRepeat = [];
                    recurData.daysOfWeek.forEach((day) => {
                        this.calendarSetting.daysToRepeat.push(this.days[day]);
                    })
                    this.calendarSetting.eventStartDate = this.getDateValue(recurData.startRecur);
                    this.calendarSetting.eventEndDate = this.getDateValue(recurData.endRecur);
                    this.events.forEach((event)=> {
                        if (event.eventId == calendarProps.eventId) {
                            console.log(event.startTime, event.endTime)
                            this.calendarSetting.eventStartTime = event.startTime ? event.startTime.toString().substr(0, 5) : null;
                            this.calendarSetting.eventEndTime = event.endTime ? event.endTime.toString().substr(0, 5) : null;                        
                        }
                    });
                } else {
                    this.calendarSetting.daysToRepeat = [];
                    this.calendarSetting.eventStartDate = event.startStr;
                    this.calendarSetting.eventEndDate = event.endStr;
                    this.calendarSetting.eventStartTime = this.getTimeValue(event.startStr);
                    this.calendarSetting.eventEndTime = this.getTimeValue(event.endStr);
                }
                this.calendarSetting.eventDate = calendarProps.eventDate;
                this.calendarSetting.eventFrequency = calendarProps.eventFrequency ? calendarProps.eventFrequency : 0;
                this.calendarSetting.eventTypeId = calendarProps.eventTypeId;
                this.calendarSetting.holidayTypeId = calendarProps.holidayTypeId;
                this.calendarSetting.holidayType = calendarProps.holidayType;
                this.calendarSetting.description = calendarProps.description;
                this.calendarSetting.status = calendarProps.status;
                this.calendarSetting.statusId = calendarProps.statusId;
                this.calendarSetting.vhextAccountId = calendarProps.vhextAccountId;
                this.calendarSetting.createdBy = calendarProps.createdBy;
                this.calendarSetting.createdById = calendarProps.createdById;
                this.calendarSetting.eventId = calendarProps.eventId;
                this.calendarSetting.categoryCode = calendarProps.categoryCode;
            } else {
                this.calendarSetting.title = '';
                this.calendarSetting.isAllDayEvent = false;
                this.calendarSetting.daysOfWeek = [];
                this.calendarSetting.daysToRepeat = [];
                this.calendarSetting.eventDate = this.getDateValue(new Date());
                this.calendarSetting.startDate = '';
                this.calendarSetting.endDate = '';
                this.calendarSetting.eventStartDate = this.getDateValue(new Date());
                this.calendarSetting.eventEndDate = this.getDateValue(new Date());
                this.calendarSetting.eventStartTime = this.getTimeValue(new Date());
                this.calendarSetting.eventEndTime = this.getTimeValue(new Date());
                this.calendarSetting.statusId = '';
                this.calendarSetting.description = '';
                this.calendarSetting.eventTypeId = '';
                this.calendarSetting.holidayTypeId = '';
                this.calendarSetting.eventFrequency = 0;
                this.calendarSetting.id = '';
            }
            this.calendarSetting.editMode = false;
            this.calendarSetting.showMOM = flag;
            this.calendarSetting.viewMode = flag;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('calEventSettingPopup');
            })
        },
        handleDateClick: function(info) {
            console.log('date-click');
        },
        mouseHoverEvent: function(info) {
            console.log('mouseHoverEvent', info);
        },
        closeDialog: function() {;
            this.showDialog = false;
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
    }
}
</script>