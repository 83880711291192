<template>
	<div>
		<b-modal :id="id" centered body-class="py-3" footer-class="py-2 px-3" header-class="py-2 px-3" size="md" :title="title" >
            <p class="my-1 fs-15" v-html="message"></p>
            <template #modal-footer="{ cancel }">
            	<div class="w-100 text-right">
                    <button v-if="noButton" type="button" class="btn sbtn btn-sm btn-secondary mr-1" @click="noClick()">No</button>
                    <button v-if="yesButton" type="button" class="btn sbtn btn-sm btn-primary" @click="yesClick()">Yes</button>
                </div>
            </template>
        </b-modal>
	</div>
</template>
<script>
export default {
	props: {
        id:{type:String, default:'_confirm1'},
        title:{type:String, default:'Delete Confirmation'},
        message:{type:String, default:'Are you sure to delete ?'},
        returnParams:{type:Object, default:function () { return {} }},
        yesButton:{type:String, default:'1'},
        noButton:{type:String, default:'1'},
        onYes:Function,
        onNo:{type:Function, default:function(){return false;}},
	},
	data() {
		return {

		}	
	},
	methods: {
		yesClick:function(){
            this.onYes(this.returnParams);
            this.$bvModal.hide(this.id);
        },
        noClick:function(){
            this.onNo();
            this.$bvModal.hide(this.id);
        },
	}
}
</script>