import Company from '@/views/company/Company.vue'
import CompanyProfile from '@/views/company/CompanyProfile.vue'
import AddCompany from '@/views/company/AddCompany.vue'
import EditCompany from '@/views/company/EditCompany.vue'
import ViewCompany from '@/views/company/ViewCompany.vue'
import WorkShift from '@/views/company/WorkShift.vue'
import Industry from '@/views/company/Industry.vue'
import Department from '@/views/company/Department.vue'

export default [
    {
        path: '/company',
        name: 'company',
        component: Company,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/companyprofile',
        name: 'companyprofile',
        component: CompanyProfile,
        meta: { accessedby: 'CAM|PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/company/add',
        name: 'addcompany',
        component: AddCompany,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/company/edit/:companyId',
        name: 'editcompany',
        component: EditCompany,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/company/view/:companyId',
        name: 'viewcompany',
        component: ViewCompany,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/workpattern',
        name: 'workpattern',
        component: WorkShift,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/industry',
        name: 'industry',
        component: Industry,
        meta: { accessedby: 'CAM|PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/department',
        name: 'department',
        component: Department,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
];