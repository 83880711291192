import AccountHead from '@/views/finance/AccountHead.vue'
import BudgetPlan from '@/views/finance/BudgetPlan.vue'
import BudgetPlanMaster from '@/views/finance/BudgetPlanMaster.vue'
import BudgetPlanMasterList from '@/views/finance/BudgetPlanMasterList.vue'
import CostEstimation from '@/views/finance/CostEstimation.vue'
import CostEstimationMaster from '@/views/finance/CostEstimationMaster.vue'
import CostEstimationMasterList from '@/views/finance/CostEstimationMasterList.vue'
import FinancialPlan from '@/views/finance/FinancialPlan.vue'
import FinancialPlanMasterList from '@/views/finance/FinancialPlanMasterList.vue'
import FinancialTxn from '@/views/finance/FinancialTxn.vue'
import FinancialTxnMaster from '@/views/finance/FinancialTxnMaster.vue'
import FinancialTxnMasterList from '@/views/finance/FinancialTxnMasterList.vue'
import FinTxnBoard from '@/views/finance/FinTxnBoard.vue'
// import MonthlyBudgetPlan from '@/views/finance/MonthlyBudgetPlan.vue'
import Payroll from '@/views/finance/Payroll.vue'
import ProjectFunding from '@/views/finance/ProjectFunding.vue'
import ProjectFundingMasterList from '@/views/finance/ProjectFundingMasterList.vue'


export default [
{
        path: '/accounthead',
        name: 'accounthead',
        component: AccountHead,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/budgetplan/:ermId',
        name: 'budgetplan',
        component: BudgetPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/bplmas',
        name: 'bplmas',
        component: BudgetPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/bplmas/list',
        name: 'bplmaslist',
        component: BudgetPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/costestimation/:ermId',
        name: 'costestimation',
        component: CostEstimation,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/coemas',
        name: 'coemas',
        component: CostEstimationMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/coemas/list',
        name: 'coemaslist',
        component: CostEstimationMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    
    {
        path: '/financialplan/:ermId',
        name: 'financialplan',
        component: FinancialPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fplmas/list',
        name: 'fplmaslist',
        component: FinancialPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/financialtxn/:ermId',
        name: 'financialtxn',
        component: FinancialTxn,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/ftxmas',
        name: 'ftxmas',
        component: FinancialTxnMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/ftxmas/list',
        name: 'ftxmaslist',
        component: FinancialTxnMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/ftxbrd',
        name: 'ftxbrd',
        component: FinTxnBoard,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    // {
    //     path: '/monthlybudgetplan/:ermId',
    //     name: 'monthlybudgetplan',
    //     component: MonthlyBudgetPlan,
    //     meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB' }
    // },
    {
        path: '/payroll/:ermId',
        name: 'payroll',
        component: Payroll,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB' }
    },

    {
        path: '/projectfunding/:ermId',
        name: 'projectfunding',
        component: ProjectFunding,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pfnmas/list',
        name: 'pfnmaslist',
        component: ProjectFundingMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },


    ];