<template>
    <div>
        <UATMasterList :ermMasterInfo="ermMasterInfo"></UATMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import UATMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        UATMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "UAT List",
                entity: 'uat_master',
                refEntity: 'uat_test_case',
                tooltip: 'New UAT',
                redirectURLName: 'uattestcase',
                newEntityMasterURL: 'uatmaster',
                redirectURL: '/uatmaster',
                actionName: 'User Acceptance Test Case',
                previewURLName: 'uatrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>