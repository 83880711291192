<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Company Profile</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="form-row">
                    <div class="col-lg-8 col-xl-8 mb-2">
                        <div class="card h-100">
                            <div class="card-body py-3 px-0">
                                <div id="profile" class="pb-0 pt-1">                                
                                    <div class="text-left">
                                        <div class="row mb-2 text-left">
                                            <div class="col-sm-12">
                                                <div class="row mb-2">
                                                    <div class="col-sm-12">
                                                        <div class="card">
                                                            <div class="card-body p-1">
                                                                <div class="my-company-img my-3">
                                                                    <img :src="company.logo" alt="Company Logo">
                                                                    <label class="upload-icon upload-icon-contacts">
                                                                        <i class="fa fa-upload"></i>
                                                                         <input ref="companyLogo" type="file" accept="image/*" @change="previewImage" class="uploadFile img" id="companyLogo" style="width: 0px;height: 0px;overflow: hidden;">                                                                    
                                                                    </label>
                                                                    <a href="javascript:" @click="refreshTopMenu"><i class="fa fa-refresh fa-lg pull-right action-link pr-2 pt-2 pointer"></i></a>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                                <div id="profile" class="pb-0 pt-0">                                
                                    <div class="text-left">
                                        <div class="row mb-2 text-left">
                                            <div class="col-sm-12">
                                                <div class="row mb-2">
                                                    <div class="col-sm-12">
                                                        <div class="profile_custom">
                                                            <!-- <dt class="col-sm-4">Owner</dt> -->
                                                            <h3 class="mb-2 bg-light p-2"><strong>Company Prime Info</strong>
                                                        <!-- <h6>Company Prime Info -->
                                                                <div class="float-right">
                                                                    <b-button v-if="!elements.companyInfoEditMode" href="javascript:" @click="elements.companyInfoEditMode = true" variant="outline-primary" class="sbtn btn btn-xs px-2 mr-2 mb-1" title="Edit Company Prime Info">
                                                                        <i class="fa fa-pencil fa-custom fa-sm mr-2" aria-hidden="true"></i>Edit
                                                                    </b-button>
                                                                    <div class="d-inline" v-if="elements.companyInfoEditMode">
                                                                        <b-button type="submit" @click="updateCompany" title="Save" variant="outline-primary" class="sbtn btn btn-xs px-2 mr-2"><i class="fa fa-check mr-2" aria-hidden="true"></i>Save</b-button>
                                                                        <b-button type="submit" @click="clearData();viewCompany();" title="Cancel" variant="outline-primary" class="sbtn btn btn-xs px-2 mr-2"><i class="fa fa-times mr-2" aria-hidden="true"></i>Cancel</b-button>
                                                                    </div>
                                                                </div>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="">
                                                            <div class="card p-3">
                                                                <b-row class="mb-2">
                                                                    <!-- <dt class="col-sm-4">Company Name</dt> -->
                                                                    <b-col cols="4">Company Name</b-col>
                                                                    <b-col cols="3" class="pl-0">
                                                                        <label v-if="!elements.companyInfoEditMode">{{company.companyName}}</label>                                                                                     
                                                                        <div v-if="elements.companyInfoEditMode" class="required-field-block" :class="{ 'is-invalid': $v.company.companyName.$error }">                                                                                
                                                                            <input v-if="elements.companyInfoEditMode" type="text" maxlength="100" class="form-control" v-model="company.companyName" id="companyName" :class="{ 'is-invalid': $v.company.companyName.$error }" >
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="elements.companyInfoEditMode && $v.company.companyName.$error && !$v.company.companyName.minLength" class="text-danger">Company name must have at least {{$v.company.companyName.$params.minLength.min}} letters.</label>
                                                                        <label v-if="elements.companyInfoEditMode && $v.company.companyName.$error && !$v.company.companyName.required" class="text-danger">Please enter Company name</label>
                                                                        <label v-if="elements.companyInfoEditMode && $v.company.companyName.$error && !$v.company.companyName.maxLength" class="text-danger">Company name must not exceed {{$v.company.companyName.$params.maxLength.max}} letters.</label>
                                                                    </b-col>
                                                                    <!-- <dt class="col-sm-2 pl-3 pt-1">Short Name</dt> -->
                                                                    <b-col cols="2" class="strong">Short Name</b-col>
                                                                    <b-col class="col-sm-3">
                                                                        <label v-if="!elements.companyInfoEditMode">{{company.shortName}}</label>
                                                                        <input v-if="elements.companyInfoEditMode" type="text" class="form-control" v-model="company.shortName" maxlength="15">
                                                                    </b-col>                                                     
                                                                </b-row>
                                                                <b-row class="mb-2">
                                                                    <b-col cols="4">Registration Number</b-col>
                                                                    <b-col cols="3" class="pl-0">
                                                                        <label v-if="!elements.companyInfoEditMode">{{company.registrationNumber}}</label>
                                                                        <input v-if="elements.companyInfoEditMode" type="text" class="form-control" v-model="company.registrationNumber">
                                                                    </b-col>
                                                                    <!-- <dt class="col-sm-2 pl-3 pt-1">CIN Number</dt> -->
                                                                    <b-col cols="2">CIN Number</b-col>
                                                                    <b-col class="col-sm-3">
                                                                        <label v-if="!elements.companyInfoEditMode">{{company.cinNumber}}</label>
                                                                        <input v-if="elements.companyInfoEditMode" type="text" class="form-control" v-model="company.cinNumber">
                                                                    </b-col>
                                                                </b-row>
                                                                <b-row class="mb-2">
                                                                    <b-col cols="4">Industry</b-col>
                                                                    <b-col cols="8" class="pl-0">
                                                                        <label v-if="!elements.companyInfoEditMode">{{company.industry}}</label>                                                   
                                                                        <select v-if="elements.companyInfoEditMode" v-model="company.industryId" class="form-control" id="industryId">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in industries" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-col>
                                                                </b-row>

                                                                <b-row class="mb-2">
                                                                    <b-col cols="4">Owner</b-col>
                                                                    <b-col cols="8" class="pl-0">
                                                                         <label v-if="!elements.companyInfoEditMode">{{company.ownerName}}</label>
                                                                        <input v-if="elements.companyInfoEditMode" type="text" class="form-control" v-model="company.ownerName">
                                                                    </b-col>
                                                                </b-row>  
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                
                                        </div>
                                    </div>
                                </div>
                                <div id="profile" class="pb-0 pt-0">                                
                                    <div class="text-left">
                                        <div class="row text-left mb-2">
                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-12">
                                                        <div class="profile_custom">
                                                            <h3 class="mb-2 bg-light p-2"><strong>Director(s)</strong>
                                                            <!-- <h3 class="mb-2"><strong>Director(s)</strong> -->
                                                                <b-button v-if="!elements.directorSettingEditMode && !elements.directorSettingNewMode" href="javascript:" @click="elements.directorSettingNewMode = true" variant="outline-primary" class="sbtn btn btn-xs px-2 ml-2" title="Add">
                                                                    <i class="fa fa-plus-circle pr-1" aria-hidden="true"></i>Add
                                                                </b-button>
                                                                <b-button v-if="elements.directorSettingEditMode || elements.directorSettingNewMode" href="javascript:" @click="cancelDirEdit" variant="outline-primary" class="sbtn btn btn-xs px-2 ml-2" title="Cancel">
                                                                    <i class="fa fa-times pr-1" aria-hidden="true"></i>Cancel
                                                                </b-button>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-for="(director,index) in directors" class="card p-2 mb-1">
                                                    <div v-if="dirEditId != director.id" class="form-row">
                                                        <b-col cols="3">
                                                            <div title="Name">{{director.name}}</div>
                                                        </b-col>
                                                        <b-col cols="2">
                                                            <div title="DIN"><i class="fa fa-id-badge text-muted pr-1" aria-hidden="true"></i>{{director.din}}</div>
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <div title="Position"><i class="fa fa-arrow-circle-up text-muted pr-1" aria-hidden="true"></i>{{director.position}}</div>
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <div title="Email Id"><i class="fa fa-envelope text-muted pr-1" aria-hidden="true"></i>{{director.emailId}}</div>
                                                        </b-col>
                                                        <b-col cols class="text-right">
                                                            <div>
                                                                <a href="javascript:" title="Edit" class="action-link mr-2" @click="showDirectorEdit(director)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                                                <a href="javascript:" title="Delete" class="action-link" @click="deleteDirector(director.id)" v-b-modal.deleteDirector><i class="fa fa-trash"></i></a>
                                                            </div>
                                                        </b-col>
                                                    </div>  
                                                    <div v-if="elements.directorSettingEditMode && dirEditId == director.id" class="form-row">
                                                        <b-col cols="3">
                                                            <input type="text" class="form-control" v-model="directorData.name" placeholder="Name">
                                                        </b-col>
                                                        <b-col cols="2">
                                                            <input type="text" class="form-control" v-model="directorData.din" placeholder="DIN">
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <input type="text" class="form-control" v-model="directorData.position" placeholder="Position">
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <input type="text" class="form-control" v-model="directorData.emailId" placeholder="Email Id">
                                                        </b-col>
                                                        <b-col cols>
                                                            <button type="submit" @click="saveDirector" title="Save" class="sbtn btn btn-secondary py-1 px-2"><i class="fa fa-check"></i></button>
                                                        </b-col>
                                                    </div>  
                                                </div>
                                                <div v-if="elements.directorSettingNewMode" class="card p-2 mb-1">
                                                    <div class="form-row">
                                                        <b-col cols="3">
                                                            <input type="text" class="form-control" v-model="directorData.name" placeholder="Name">
                                                        </b-col>
                                                        <b-col cols="2">
                                                            <input type="text" class="form-control" v-model="directorData.din" placeholder="DIN">
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <input type="text" class="form-control" v-model="directorData.position" placeholder="Position">
                                                        </b-col>
                                                        <b-col cols="3">
                                                            <input type="text" class="form-control" v-model="directorData.emailId" placeholder="Email Id">
                                                        </b-col>
                                                        <b-col cols>
                                                            <button type="submit" @click="saveDirector" title="Save" class="sbtn btn btn-secondary py-1 px-2"><i class="fa fa-check"></i></button>
                                                        </b-col>
                                                    </div>  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="profile" class="pb-0 pt-0">
                                    <div class="row mb-2 mt-2 text-left">
                                        <div class="col-sm-12">
                                            <div class="profile_custom">
                                                <h3 class="mb-2 bg-light p-2"><strong>Company Prime Setting</strong>
                                                </h3>
                                            </div>
                                            <b-tabs content-class="mt-3" >
                                                <b-tab title="Initial Setting">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Country</label>
                                                                    </b-col>
                                                                    <b-col v-if="editItemEntity == 'country_setting'" cols="6" class="pl-0">
                                                                        <select v-model="companySetting.countryId" class="form-control mt-1" id="countryId">
                                                                            <option value="0">Select</option>
                                                                            <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label class="m-0">{{ getCountry }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="editItemEntity == 'country_setting'">
                                                                            <a href="javascript:" @click="cancelCmpSettingEdit" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('country_setting', companySetting.countryId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('country_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Time Zone</label>
                                                                    </b-col>
                                                                    <b-col v-if="editItemEntity == 'timezone_setting'" cols="6" class="pl-0">
                                                                        <Select2 v-model="companySetting.timeZone" :options="timeZones" :settings="{width:'100%'}" />
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label class="m-0">{{ getTimeZone }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="editItemEntity == 'timezone_setting'">
                                                                            <a href="javascript:" @click="cancelCmpSettingEdit" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setTextValData('timezone_setting', companySetting.timeZone)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('timezone_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Currency</label>
                                                                    </b-col>
                                                                    <b-col v-if="editItemEntity == 'currency_setting'" cols="6" class="pl-0">
                                                                        <select v-model="companySetting.currencyId" class="form-control" id="currencyId">
                                                                            <option value="0">Select</option>
                                                                            <option v-for="(info, index) in currencies" :value="info.id">{{info.value}} ({{info.currencySymbol}})</option>
                                                                        </select>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="companySetting.currencySymbol" class="m-0">{{ getCurrency }} ({{ companySetting.currencySymbol }})</label>
                                                                        <label v-else class="m-0">{{ getCurrency }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="editItemEntity == 'currency_setting'">
                                                                            <a href="javascript:" @click="cancelCmpSettingEdit" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('currency_setting', companySetting.currencyId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('currency_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <!-- <label for="effortConversionHourDay">Effort(1 Man Day)</label>
                                                            <div class="input-group">
                                                                <input type="number" v-model="project.effortConversionHourDay" class="form-control" min="0" id="effortConversionHourDay">
                                                                <div class="input-group-append">
                                                                    <div class="input-group-text">
                                                                        <span class="mx-auto">Man Hours</span>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Effort(1 Man Day)</label>
                                                                    </b-col>
                                                                    <b-col v-if="editItemEntity == 'effort_conversion_hour_day_setting'" cols="6" class="pl-0">
                                                                        <div class="input-group">
                                                                            <input type="number" v-model="companySetting.effortConversionHourDay" class="form-control" min="0" id="effortConversionHourDay">
                                                                            <div class="input-group-append">
                                                                                <div class="input-group-text">
                                                                                    <span class="mx-auto">Man Hours</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="companySetting.effortConversionHourDay" class="m-0">{{ companySetting.effortConversionHourDay }} Man Hours</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="editItemEntity == 'effort_conversion_hour_day_setting'">
                                                                            <a href="javascript:" @click="cancelCmpSettingEdit" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setNumberValData('effort_conversion_hour_day_setting', companySetting.effortConversionHourDay)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('effort_conversion_hour_day_setting')" class="float-right mr-1 action-link" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-tab>
                                                <b-tab title="Calendar Setting">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Calendar Year</label>
                                                                    </b-col>
                                                                    <b-col v-if="editItemEntity == 'calendar_year_setting'" cols="6" class="pl-0">
                                                                        <select v-model="companySetting.calendarYear" class="form-control" id="calendarYear">
                                                                          <option v-for="year in yearArray()" :value="year">{{ year }}</option>
                                                                        </select>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label v-if="companySetting.calendarYear" class="m-0">{{ formatYear(companySetting.calendarYear) }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="editItemEntity == 'calendar_year_setting'">
                                                                            <a href="javascript:" @click="cancelCmpSettingEdit" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setDateValData('calendar_year_setting', companySetting.calendarYear)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('calendar_year_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Fiscal Year</label>
                                                                    </b-col>
                                                                    <b-col v-if="editItemEntity == 'financial_year_setting'" cols="6" class="pl-0">
                                                                        <div class="form-row">
                                                                            <div class="col-5">
                                                                                <input type="date" v-model="companySetting.financialYearStart" class="form-control" >
                                                                            </div>
                                                                            <div class="col text-center my-auto">
                                                                                <label class="my-auto">to</label>
                                                                            </div>
                                                                            <div class="col-5">
                                                                                <input type="date" v-model="companySetting.financialYearEnd" class="form-control" >
                                                                            </div>
                                                                        </div>
                                                                    </b-col>
                                                                    <b-col v-else  cols="6" class="pl-0 my-2">
                                                                        <label class="m-0">{{ companySetting.financialYearStart | formatDate }} <span class="px-2">to</span> {{ companySetting.financialYearEnd | formatDate }}</label>
                                                                    </b-col>
                                                                    <b-col cols="2" class="my-2">
                                                                        <template v-if="editItemEntity == 'financial_year_setting'">
                                                                            <a href="javascript:" @click="cancelCmpSettingEdit" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                            <a href="javascript:" @click="setStartEndDateData('financial_year_setting', companySetting.financialYearStart, companySetting.financialYearEnd)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                                        </template>
                                                                        <a v-else href="javascript:" @click="showEdit('financial_year_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-tab>
                                                <b-tab title="Activity Log">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Record Activity Log</label>
                                                                    </b-col>
                                                                    <b-col cols="6" class="pl-0">
                                                                        <b-form-checkbox class="custom-toggle mt-1" value="1" unchecked-value="0" switch v-model="companySetting.activityLog" @change="changeSwitchState('user_activity_log_setting', companySetting.activityLog)" name="check-button"></b-form-checkbox>
                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                            <div class="card px-3 py-2 mb-2">
                                                                <b-row>
                                                                    <b-col cols="4" class="my-2">
                                                                        <label class="m-0">Record Change History</label>
                                                                    </b-col>
                                                                    <b-col cols="6" class="pl-0">
                                                                        
                                                                        <b-form-checkbox class="custom-toggle mt-1" value="1" unchecked-value="0"  switch v-model="companySetting.changeHistory" @change="changeSwitchState('change_history_setting', companySetting.changeHistory)" name="check-button"></b-form-checkbox>

                                                                    </b-col>
                                                                </b-row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-tab>
                                            </b-tabs>
                                        </div>                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 mb-2">
                        <div class="card h-100">
                            <div class="card-body p-3">
                                <div id="profile" class="pb-0 pt-3 px-2">
                                    <!-- Right Side -->

                                    <div class="profile_header text-left">
                                        <div class="row mb-2 text-left">
                                            <div class="col-4">
                                                <div class="profile_custom">
                                                    <h3 class="mb-2 bg-light p-2"><strong>Company Contact</strong>
                                                    <!-- <h3 class="mb-2">Company Contact -->
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-sm-8 pl-3 float-right">
                                                <div class="text-right">
                                                    <b-button v-if="!elements.companyContactInfoEditMode" href="javascript:" @click="elements.companyContactInfoEditMode = true" variant="outline-primary" class="sbtn btn btn-xs px-2" title="Edit">
                                                        <i class="fa fa-pencil mr-2" aria-hidden="true"></i>Edit
                                                    </b-button>
                                                    <div v-if="elements.companyContactInfoEditMode">
                                                        <b-button type="submit" @click="updateCompany" title="Save" variant="outline-primary" class="sbtn btn btn-xs px-2 mr-1"><i class="fa fa-check mr-2" aria-hidden="true"></i>Save</b-button>
                                                        <b-button type="submit" @click="clearData();viewCompany();" title="Cancel" variant="outline-primary" class="sbtn btn btn-xs px-2"><i class="fa fa-times mr-2" aria-hidden="true"></i>Cancel</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ml-3">
                                            <b-row class="mb-2">
                                                <b-col cols="4" class="my-auto">
                                                    <label>Office Phone</label>
                                                </b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.companyContactInfoEditMode">{{company.officePhone}}</label>
                                                    <input v-if="elements.companyContactInfoEditMode" type="text" class="form-control" v-model="company.officePhone">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4" class="my-auto">
                                                    <label>Email Id</label>
                                                </b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.companyContactInfoEditMode">{{company.alternateEmailId}}</label>
                                                    <input v-if="elements.companyContactInfoEditMode" type="text" class="form-control" v-model="company.alternateEmailId">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4" class="my-auto">
                                                    <label>Website</label>
                                                </b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.companyContactInfoEditMode">{{company.website}}</label>
                                                    <input v-if="elements.companyContactInfoEditMode" type="text" class="form-control" v-model="company.website">
                                                </b-col>
                                            </b-row>          
                                            <b-row v-if="false" class="mb-2" >
                                                <b-col cols="4">Logo</b-col>
                                                <b-col cols="8">
                                                    <input v-if="elements.companyInfoEditMode" ref="companyLogo" type="file" accept="image/*" @change="previewImage" class="form-control-file" value="dfdf" id="companyLogo">

                                                    <div class="border p-2 mt-3" v-if="elements.companyInfoEditMode">                                                    
                                                        <template v-if="preview">
                                                        <img :src="preview" class="img-fluid" />
                                                        <!--  {{getFileName(preview)}} -->
                                                        <!-- <p class="mb-0">file name: {{ image.name }}</p> -->
                                                        <!-- <p class="mb-0">size: {{ image.size/1024 }}KB</p> -->
                                                        </template>
                                                    </div>   

                                                    <div v-if="!elements.companyInfoEditMode">
                                                        <img :src="company.logo" class="Companylogo-img" />
                                                    </div>      
                                                </b-col>
                                            </b-row>    
                                            <div class="separator mb-2 mt-3 mr-3"></div>
                                            <div class="profile_header text-left">
                                                <div class="row mb-2 text-left">
                                                    <div class="col-4">
                                                        <div class="profile_custom">
                                                            <h3 class="mb-2 bg-light p-2"><strong>Address</strong>
                                                            <!-- <h3 class="mb-2">Address -->
                                                            </h3>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-8 col-sm-8 pl-3 float-right">
                                                        <div class="text-right">
                                                            <b-button v-if="!elements.companyAddressInfoEditMode" href="javascript:" @click="elements.companyAddressInfoEditMode = true" variant="outline-primary" class="sbtn btn btn-xs px-2" title="Edit">
                                                                <i class="fa fa-pencil mr-2" aria-hidden="true"></i>Edit
                                                            </b-button>
                                                            <div v-if="elements.companyAddressInfoEditMode">
                                                                <b-button type="submit" @click="updateCompany" title="Save" variant="outline-primary" class="sbtn btn btn-xs px-2 mr-1"><i class="fa fa-check mr-2" aria-hidden="true"></i>Save</b-button>
                                                                <b-button type="submit" @click="clearData();viewCompany();" title="Cancel" variant="outline-primary" class="sbtn btn btn-xs px-2"><i class="fa fa-times mr-2" aria-hidden="true"></i>Cancel</b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="!elements.companyAddressInfoEditMode" class="pl-3">
                                                    <p v-if="company.address1 != ''">
                                                        <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                                        <span v-if="company.address1 != ''">{{company.address1}}</span>                                                 
                                                        <span v-if="company.streetName != ''">, {{company.streetName}}</span>
                                                                <span v-if="company.city != ''">, {{company.city}}</span>
                                                        <span v-if="company.state != ''">, {{company.state}}</span>                                               
                                                        <span v-if="company.country != ''">, {{company.country}}</span>
                                                        <span v-if="company.pincode != ''">, {{company.pincode}}</span>
                                                    </p>
                                                </div>
                                                <div class="ml-3">
                                                    <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                                        <b-col cols="4">Address Line 1</b-col>
                                                        <b-col cols="8">
                                                            <input  type="text" class="form-control" v-model="company.address1">
                                                        </b-col>
                                                    </b-row>                                                
                                                    <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                                        <b-col cols="4">Street Name</b-col>
                                                        <b-col cols="8">
                                                            <input  type="text" class="form-control" v-model="company.streetName">
                                                        </b-col>
                                                    </b-row>
                                                    <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                                        <b-col cols="4">City</b-col>
                                                        <b-col cols="8">
                                                            <input  type="text" class="form-control" v-model="company.city">
                                                        </b-col>
                                                    </b-row>
                                                    <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                                        <b-col cols="4">State</b-col>
                                                        <b-col cols="8">
                                                            <input  type="text" class="form-control" v-model="company.state">
                                                        </b-col>
                                                    </b-row>
                                                    <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                                        <b-col cols="4">Country</b-col>
                                                        <b-col cols="8">
                                                            <select v-if="elements.companyAddressInfoEditMode" v-model="company.countryId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </b-col>
                                                    </b-row>
                                                    <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                                        <b-col cols="4">Pin Code</b-col>
                                                        <b-col cols="8">
                                                            <input  type="text" class="form-control" v-model="company.pincode">
                                                        </b-col>
                                                    </b-row>
                                                </div>                                       
                                            </div>                                                                         
                                        </div>                                        
                                    </div>
                                    <div class="separator mb-2 mt-3"></div>
                                    <div class="profile_header text-left">
                                        <div class="row mb-2 mt-3 text-left">
                                            <div class="col-4">
                                                <div class="profile_custom">
                                                    <h3 class="mb-2 bg-light p-2"><strong>Contact Person</strong>
                                                    <!-- <h3 class="mb-2">Contact Person -->
                                                    </h3>
                                                </div>
                                            </div>
                                            <div class="col-md-8 col-sm-8 pl-3 float-right">
                                                <div class="text-right">
                                                    <b-button v-if="!elements.contactPersonEditMode" href="javascript:" @click="elements.contactPersonEditMode = true" variant="outline-primary" class="sbtn btn btn-xs px-2" title="Edit">
                                                        <i class="fa fa-pencil mr-2" aria-hidden="true"></i>Edit
                                                    </b-button>
                                                    <div v-if="elements.contactPersonEditMode">
                                                        <b-button type="submit" @click="updateCompany" title="Save" variant="outline-primary" class="sbtn btn btn-xs px-2 mr-1"><i class="fa fa-check mr-2" aria-hidden="true"></i>Save</b-button>
                                                        <b-button type="submit" @click="clearData();viewCompany();" title="Cancel" variant="outline-primary" class="sbtn btn btn-xs px-2"><i class="fa fa-times mr-2" aria-hidden="true"></i>Cancel</b-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ml-3">
                                            <b-row class="mb-2">
                                                <b-col cols="4">Contact Person</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactPersonEditMode">{{company.contactName}}</label>
                                                    <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.contactName">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">Email Id</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactPersonEditMode">{{company.emailId}}</label>
                                                    <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.emailId">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">Mobile</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactPersonEditMode">{{company.mobile}}</label>
                                                    <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.mobile">
                                                </b-col>
                                            </b-row>          
                                            <b-row class="mb-2">
                                                <b-col cols="4">Phone</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactPersonEditMode">{{company.phone}}</label>
                                                    <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.phone">
                                                </b-col>
                                            </b-row>          
                                                                                    
                                        </div>                                        
                                    </div>
                                    <div class="separator mb-2 mt-3 mr-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
        <DeleteComponent id="deleteDirector" :onYes="onYesDir" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Director?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        Select2,
    },

    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entity: 'human_resource',
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            preview: null,

            activeSettingMenuId: 1,
            activeSettingMenuTitle: '',
            editItemEntity: '',
            dirEditId: '',

            showOverlay: false,
            
            elements: {
                companyInfoEditMode: false, 
                directorSettingNewMode: false, 
                directorSettingEditMode: false, 
                companyAddressInfoEditMode: false,
                companyContactInfoEditMode: false,  
                contactPersonEditMode:false,        
            },
            directorData: {
                id: '',
                directorId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                humanResourceId: '',
                din: '',
                createdById: localStorage.getItem("userId"),
                name: '',
                position: '',
                emailId: '',
                mobile: '',
            },
            humanResource: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'human_resource',
                userId: '',
                loginName: '',
                userType: '',
                humanResourceId: '',                
                name: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            company: {
                id: '',
                companyId: '',
                vhextAccountId: '',
                industryId: '',
                industry: '',
                companyName: '',
                shortName: '',
                registrationNumber: '',
                cinNumber: '',
                ownerName: '',
                currencyId: '',
                timeZone: '',
                hasContactInfo: '',
                active: '',
                ownerId: '',
                createdById: '',
                logo: '',
                officePhone: '',
                alternateEmailId: '',
                website: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                contactName: '',
                emailId: '',
                mobile: '',
                phone: '',
            },
            activation:{
                oldPassword:'',
                newPassword: '',
                confirmPassword:''
            },

            companySetting: {
                countryId: 0,
                timeZone: '',
                currencyId: 0,
                currencySymbol: '',
                calendarYear: new Date(),
                financialYearStart: new Date(),
                financialYearEnd: new Date(),
                activityLog: 0,
                changeHistory: 0,
                effortConversionHourDay: 0,
            },
            companySettingData: {
                id: '',
                companySettingId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                dataTypeId: '',
                entityId: '',
                entity: '',
                displayName: '',
                textValue: '',
                numberValue: '',
                dateValue: '',
                startDate: '',
                endDate: '',
                description: '',
                active: true,
                createdDt: '',
                lastUpdatedDt: '',
            },
            companySettingList: [],

            settingMenu: [
                // Id is *important* - ID is used to hide/show divs
                { id: 1, name: 'Prime Setting', icon_class: 'fa-cog' },
                { id: 2, name: 'Calendar Setting', icon_class: 'fa-cog' },
                // { id: 3, name: 'Payroll Setting', icon_class: 'fa-cog' },
                { id: 4, name: 'Activity Log', icon_class: 'fa-cog' },
            ],

            humanResources: [],
            contacts: [],
            countries: [],
            currencies: [],
            timeZones: [],
            projects: [],
            industries:[],
            directors:[],

            selectMode: 'single',

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        getCountry() {
            let result = '-';
            if (this.countries.length>0 && this.companySetting.countryId) {
                result = this.countries.find(data => data.id == this.companySetting.countryId);
                result = result && result.value ? result.value : '-';
            }
            return result;
        },
        getCurrency() {
            let result = '-';
            if (this.currencies.length>0 && this.companySetting.currencyId) {
                result = this.currencies.find(data => data.id == this.companySetting.currencyId);
                result = result && result.value ? result.value : '-';
            }
            return result;
        },
        getTimeZone() {
            let result = '-';
            if (this.timeZones.length>0 && this.companySetting.timeZone) {
                result = this.timeZones.find(data => data.id == this.companySetting.timeZone);
                result = result && result.text ? result.text : '-';
            }
            return result;
        },
    },
    mounted() {
        this.loadOnce = true;
        if(this.userTypeCode != 'CLI') {
            this.viewCompany();
        }

        this.lookupData();
        this.getCompanySettings();
        this.getDirectorsList();
    },
    validations: {
        activation: {
            newPassword: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('newPassword')
            }
        },
        humanResource: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        },
        company: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getCompanySettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/companysettingdata', data)
                .then((response) => {
                    let result = response.data;
                    this.companySetting = result.companySettingData;
                    if (this.companySetting.currencySymbol) {
                        localStorage.setItem("currency", this.companySetting.currencySymbol);
                    }
                    // this.setCompanySettingData();
                })
                
        },
        lookupData: function() {
             let data = {
                projectId: this.company.projectId,
                vhextAccountId: this.vhextAccountId,
                entity: this.company.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data)
                .then((response) => {
                    let result = response.data;   
                    this.industries= result.industryLookup; 
                    this.countries = result.countryLookup;
                    this.currencies = result.currencyLookup;                    
                    this.timeZones = result.timeZoneLookup;

                })
            
        },        
        getDirectorsList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/director/list', data)
                .then((response) => {
                    let result = response.data;
                    this.directors = result.directorList;
                })
        },
        showDirectorEdit: function(data) {
            this.elements.directorSettingNewMode = false;
            this.elements.directorSettingEditMode = true;
            this.dirEditId = data.id;
            this.directorData.id = data.id;
            this.directorData.directorId = data.directorId;
            this.directorData.vhextAccountId = data.vhextAccountId;
            this.directorData.humanResourceId = data.humanResourceId;
            this.directorData.din = data.din;
            this.directorData.createdById = data.createdById;
            this.directorData.name = data.name;
            this.directorData.position = data.position;
            this.directorData.emailId = data.emailId;
            this.directorData.mobile = data.mobile;
        },
        cancelDirEdit: function() {
            this.elements.directorSettingEditMode = false;
            this.elements.directorSettingNewMode = false;
            this.dirEditId = '';
            this.directorData.id = '';
            this.directorData.directorId = '';
            this.directorData.humanResourceId = '';
            this.directorData.din = '';
            this.directorData.name = '';
            this.directorData.position = '';
            this.directorData.emailId = '';
            this.directorData.mobile = '';
        },
        changeSwitchState(entity, value) {
            // alert(value);
            // value = (value == 1) ? 0 : 1;
            this.$nextTick(() => {
                this.setNumberValData(entity, value);
            })
            
        },
        setNumberValData(entity, value) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = value;
            this.companySettingData.dateValue = '1950-01-01';
            this.companySettingData.startDate = '1950-01-01';
            this.companySettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setTextValData(entity, value) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = value;
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = '1950-01-01';
            this.companySettingData.startDate = '1950-01-01';
            this.companySettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setDateValData(entity, value) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = value + '-01-01';
            this.companySettingData.startDate = '1950-01-01';
            this.companySettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setStartEndDateData(entity, startDate, endDate) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = '1950-01-01';
            this.companySettingData.startDate = startDate;
            this.companySettingData.endDate = endDate;
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setDatePeriodData(entity, startDate, endDate, datePeriod) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = datePeriod;
            this.companySettingData.startDate = startDate;
            this.companySettingData.endDate = endDate;
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        updateCompanySetting: function() {
            console.log(this.companySettingData)
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/companysetting/updateall', this.companySettingData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCompanySettings();
                        this.clearPrimeSettingData();
                    }

                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        clearPrimeSettingData: function() {
            this.companySettingData.entity = '';
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = '';
            this.companySettingData.dateValue = '';
            this.companySettingData.startDate = '';
            this.companySettingData.endDate = '';
            this.editItemEntity = '';
        },
        showEdit: function(editItemEntity) {
            this.editItemEntity = editItemEntity;
        },
        cancelCmpSettingEdit() {
            this.editItemEntity = '';
            this.getCompanySettings();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveDirector: function() {
            if (this.elements.directorSettingEditMode) {
                this.updateDirector();
            } else {
                this.addDirector();
            }
        },
        addDirector: function() {
            console.log(this.directorData);
            // this.$v.directorData.$touch();
            // if (!this.$v.directorData.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/company/director/add', this.directorData)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                                
                            });
                            this.getDirectorsList();
                            this.cancelDirEdit();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    });
                // }
        },
        updateDirector: function() {
            console.log(this.directorData);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/director/update', this.directorData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.getDirectorsList();
                        this.cancelDirEdit();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        deleteDirector: function(id) {
            this.deleteParam.id = id;
        },
        onYesDir: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/company/director/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDirectorsList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        async viewCompany(id) {
            let data = {
                vhextAccountId : this.vhextAccountId
            }
            const response = await axios.post(this.$store.getters.getAPIBasePath + '/company/view/vhextid',  data);
            let result = response.data;
            if (result.company) {
                this.company = result.company;
            } else {
                this.clearCompanyData();
            }
            this.preview = this.company.logo;
            localStorage.setItem("logo",this.company.logo);
            await this.company.logo;
        },
        updateCompany: function() {

            console.log("CMP",this.company);
            
            this.$v.company.$touch();
            if (!this.$v.company.$invalid) {
                this.showOverlay = true;
                 axios.post(this.$store.getters.getAPIBasePath + '/company/update', this.company)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                       // this.uploadLogo();
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        } 
                        this.viewCompany();               
                        this.clearData();

                    })
                    .catch(error => {
                        this.clearData();
                        this.showOverlay = false;
                       //  iziToast.error({
                       //     title: 'Error',
                       //     message: 'Error',
                       //     position: 'topRight'
                       // });
                    })
            }
        },
        clearCompanyData() {
            this.company.id = '';
            this.company.companyId = '';
            this.company.vhextAccountId = '';
            this.company.industryId = '';
            this.company.industry = '';
            this.company.companyName = '';
            this.company.shortName = '';
            this.company.registrationNumber = '';
            this.company.cinNumber = '';
            this.company.ownerName = '';
            this.company.currencyId = '';
            this.company.timeZone = '';
            this.company.hasContactInfo = '';
            this.company.active = '';
            this.company.ownerId = '';
            this.company.createdById = '';
            this.company.logo = '';
            this.company.officePhone = '';
            this.company.alternateEmailId = '';
            this.company.website = '';
            this.company.address1 = '';
            this.company.streetName = '';
            this.company.city = '';
            this.company.state = '';
            this.company.countryId = '';
            this.company.country = '';
            this.company.pincode = '';
            this.company.contactName = '';
            this.company.emailId = '';
            this.company.mobile = '';
            this.company.phone = '';
            this.$v.company.$reset();
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.company.logo = e.target.result;
                }
                this.image=input.files[0];
                reader.readAsDataURL(input.files[0]);
                this.uploadLogo();
            }
        },
        uploadLogo: function() {
            if(this.$refs.companyLogo.files[0] != undefined){
                let formData = new FormData();
                formData.append('file', this.$refs.companyLogo.files[0]);
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('id', this.company.id);

                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/logo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.showOverlay = false;
                        var result = response.data;
              
                        iziToast.success({
                            title: 'Success',
                            message: 'Logo uploaded Successfully',
                            position: 'topRight'
                        });         
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error Occurred',
                            message: 'Unable to upload logo',
                            position: 'topRight'
                        });
                    });
            }
        },
        // getFileName:function (url)
        // {
        //     if (url)
        //     {
        //         var m = url.toString().match(/.*\/(.+?)\./);
        //         if (m && m.length > 1)
        //         {
        //             return m[1];
        //         }
        //     }
        //     return "";
        // },
        clearData: function() {
            this.elements.companyInfoEditMode = false;
            this.elements.companyContactInfoEditMode = false;
            this.elements.companyAddressInfoEditMode = false;
            this.elements.contactPersonEditMode = false;
        },
        async refreshTopMenu() {
            this.showOverlay = true;
            await this.viewCompany();
            await EventBus.$emit('onTopMenuReload');
            this.showOverlay = false;
        },
    }

}
</script>