<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">VMX Account List</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0  mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="VMX Account" :to="{ name: 'addvhextaccount'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-pencil pr-2"></i>Edit</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-eye pr-2"></i>View</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Detete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="col-8 text-left">
                                        <div class="dataTables_filter">
                                            <label class="d-block p-1 accordion-title">Vhext Account
                                            </label>
                                        </div>
                                    </div>

<!--                                     <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div> -->
                                    <div class="col-8 float-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="vhextAccounts" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <router-link title="View VhextAccount" :to="{ name: 'viewvhextaccount', params: { vhextaccountid: data.item.id }}">{{data.value}}</router-link>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <router-link title="View VMX Account" :to="{ name: 'viewvhextaccount', params: { vhextaccountid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                    <router-link title="Edit VhextAccount" :to="{ name: 'editvhextaccount', params: { vhextaccountid: data.item.id }}"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></router-link>
                                    <a href="javascript:" title="Deactivate" @click="deleteVhextAccount(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No VMX Accounts found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="vhextAccounts.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>           
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    data() {
        return {
            vhextAccounts: [],
            fields: [
                { key: 'id', label: 'PK Id', sortable: true, sortDirection: 'desc' },
                { key: 'vhextAccountId', label: 'Account Id', sortable: true, sortDirection: 'desc' },
                { key: 'accountName', label: 'Account Name', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Account Type',sortable: true, sortDirection: 'desc' },
                { key: 'vhextServicePlan', label: 'Service Plan', sortable: true, sortDirection: 'desc' },
                 { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'expiryDate', label: 'Expiry Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' }, 
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },

                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getVhextAccounts();
    },
    methods: {
        getVhextAccounts: function() {

            axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/list')
                .then((response) => {
                    let result = response.data;
                    this.vhextAccounts = result.vhextAccountlist;
                    this.totalRows = this.vhextAccounts.length;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        deleteVhextAccount: function(id) {

        }
    }
}
</script>