<template>
    <div>
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" rounded="sm">
        <div class="container-fluid mail-page">
            <div class="row mx-0">
                <div class="col-12 my-0 p-0">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 pb-0 fs-16">Mailbox</h4>
                        </div>
                        <div class="col-lg-8 col-sm-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0  mb-0">
                                    <div class="row no-gutters">
                                        <div class="col-11">
                                            <select v-model="selectedEmailId" class="form-control" id="taskId" >
                                                <option v-for="(info, index) in emailAccount" v-bind:value="info.id" :key="index">{{info.fromName}}</option>
                                            </select>
                                        </div>                                       
                                    </div>
                                    <li class="breadcrumb-item"> 
                                        <a href="javascript:" @click="sendMail()"><i class="fa fa-plus pr-2 action-link" aria-hidden="true"></i>Compose</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link :to="mailRoute" title="Email">
                                            <i class="fa fa-envelope action-link" aria-hidden="true"></i>
                                        </router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a @click.prevent="refreshMail" href="javascript:" title="Refresh"><i aria-hidden="true" class="fa fa-refresh"></i></a>
                                    </li>
                                     <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
                <div class="col-3 pr-1 pl-0">
                    <div class="dataTables_filter mb-1">
                        <input type="search" class="form-control form-control-lg" placeholder="Search...">
                    </div>
                    <div class="mail-page-col">
                        <b-tabs v-model="tabIndex" lazy>
                            <b-tab title="Mailbox">
                                <template #title>
                                    Inbox <b-badge class="mt-0" variant="primary">{{mailList.length}}</b-badge>
                                </template>
                                <div class="mail-list-scroll my-2">
                                    <template v-for="mail in mailList">
                                        <div @click="handleMailClick(mail)" :class="{'selectedMail' : mail.id == selectedMailId}" class="card-media-mail pointer my-1">
                                            <div class="card-body pl-2 py-2 row mx-0 pr-1">
                                                <div class="col-sm-12 col-md-8 mt-1 pb-1 user-icon pr-0">
                                                    <span class="pointer align-self-center user-name-icon float-left mr-1">{{ mail.sender | getAcronym }}</span>
                                                    <h6 :title="mail.mailFrom" class="fs-14">{{ mail.sender }}</h6>
                                                    <span class="row col-12 pr-0" :title="mail.subject">{{getShortString(mail.subject, 22)}}</span>
                                                    {{mail.fileAttachments }}
                                                </div>
                                                <div class="col-4 float-right px-0">
                                                    <div v-if="currentDate == new Date(mail.sentDate).toString().substr(0, 10)" class="col-12 email-time pr-0">
                                                        {{ mail.emailDate | formatTimeAmPm }} 
                                                    </div>
                                                    <div v-else class="col-12 email-time pr-0">
                                                        {{ mail.emailDate | formatDateTimeAmPm }}
                                                    </div>
                                                    <div class="col-12 text-right mail-quick-icons py-0 pr-2">
                                                        <a href="javascript:" title="Mark as read"><i class="fa fa-envelope-open pr-2" aria-hidden="true"></i></a>
                                                        <a href="javascript:" title="Archive"><i class="fa fa-archive pr-2" aria-hidden="true"></i></a>
                                                        <a href="javascript:" title="Delete"><i class="fa fa-trash pr-0" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </b-tab>
                            <b-tab title="Sent Mail">
                                <template #title>
                                    Sent Mail <b-badge class="mt-0" variant="primary">{{sentMails.length}}</b-badge>
                                </template>
                                <div class="mail-list-scroll my-2">
                                    <template v-for="mail in sentMails">
                                        <div @click="handleMailClick(mail)" :class="{'selectedMail' : mail.id == selectedMailId}" class="card-media-mail pointer my-1">
                                            <div class="card-body pl-2 py-2 row mx-0 pr-1">
                                                <div class="col-sm-12 col-md-8 mt-1 pb-1 user-icon pr-0">
                                                    <span class="pointer align-self-center user-name-icon float-left mr-1">{{ mail.sender | getAcronym}}</span>
                                                    <h6 :title="mail.mailFrom" class="fs-14">{{ mail.sender}}</h6>
                                                    <span :title="mail.subject">{{getShortString(mail.subject, 22)}}</span>
                                                </div>
                                                <div class="col-4 float-right px-0">
                                                    <div v-if="currentDate == new Date(mail.sentDate).toString().substr(0, 10)" class="col-12 email-time pr-0 pb-3">
                                                        {{ mail.emailDate | formatTimeAmPm }}
                                                    </div>
                                                    <div v-else class="col-12 email-time pr-0">
                                                        {{ mail.emailDate | formatDateTimeAmPm }}
                                                    </div>
                                                    <div class="col-12 text-right mail-quick-icons py-0 pr-2">
                                                        <a href="javascript:" title="Mark as read"><i class="fa fa-envelope-open pr-2" aria-hidden="true"></i></a>
                                                        <a href="javascript:" title="Archive"><i class="fa fa-archive pr-2" aria-hidden="true"></i></a>
                                                        <a href="javascript:" title="Delete"><i class="fa fa-trash pr-0" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
                <div v-if="!visibleMailContent" class="col-9 pl-1 pr-0">
                    <i v-if="tabIndex==0" class="fa fa-envelope-o fa-5x mail-empty-icon pl-1" aria-hidden="true">
                    </i>
                    <i v-if="tabIndex==1" class="fa fa-paper-plane fa-5x mail-empty-icon pl-2" aria-hidden="true">
                    </i>
                </div>
                <div v-if="visibleMailContent" class="col-9 pl-1 pr-0">
                    <div class="card px-2 py-0 mb-1">
                        <div class="row mx-3">
                            <div class="col-7 pl-0 float-left">
                                <h6 class="mb-0 pt-2">{{ mailbox.subject }}</h6>
                            </div>
                            <div class="col-5 my-2 float-right">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0"> 
                                        <li class="breadcrumb-item">
                                            <a href="#"><i class="fa fa-share pr-2" aria-hidden="true"></i>Forward</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#"><i class="fa fa-archive pr-2" aria-hidden="true"></i>Archive</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#"><i class="fa fa-trash pr-2" aria-hidden="true"></i>Delete</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="mail-page-col">
                        <template v-for="(inboxmail,index) in chainMailList">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1 mail-main" role="tab">     
                                <div class="row mx-0" >
                                    <div class="col-12 text-left pl-0" v-b-toggle="'mailbox-'+inboxmail.id">
                                        <div class="col-1 ml-0 pl-0 mt-1 float-left user-icon">
                                            <span class="pointer align-self-center user-name-icon float-left mr-1" variant="warning">{{ inboxmail.sender | getAcronym }}</span>
                                        </div>
                                        <ul class="mail-form-block pl-2 ml-2">
                                            <li><span class="fs-16">{{ inboxmail.sender }}</span> <!-- - <span class="subtle">{{ inboxmail.mailFrom }}</span> --> </li>
                                            <li class="subtle">{{ inboxmail.emailDate | formatDateTimeAmPm }}</li>
                                            <li>To: <span v-if="inboxmail.receiver" class="subtle">{{ inboxmail.receiver.replaceAll(',',', ') }}</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <nav class="px-0 breadcrumb-container d-sm-block d-lg-inline-block float-right mail-options" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0 float-right">
                                        <li class="mr-2">
                                            <a href="javascript:" title="Star"><i class="fa fa-star-o fa-lg px-2" aria-hidden="true"></i></a>
                                        </li>
                                        <li class="mr-2">                                            
                                            <a @click="messageId = inboxmail.messageId" v-b-modal.replyMailPopup href="javascript:" title="Reply"><i class="fa fa-reply pr-2" aria-hidden="true"></i></a>
                                        </li>
                                        <li class="mr-2">
                                            <a href="#" title="more"><i class="fa fa-ellipsis-v fa-lg pl-2" aria-hidden="true"></i></a>
                                        </li>
                                    </ol>
                                </nav>
                                <!-- <div class="row mx-0" >
                                    <div class="col-2">
                                        <div class="row float-right">
                                            
                                        </div>
                                    </div>
                                </div>         -->
                            </b-card-header>
                            <b-collapse :id="'mailbox-'+inboxmail.id" :visible="expandAccordian(chainMailList.length,index)" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="row card-mail-content mx-4">
                                        <div class="col-12">
                                            <span v-html="inboxmail.content"></span>
                                            <div v-if="inboxmail.attachment && inboxmail.attachment.length>0">
                                                <div class="separator mt-0 mb-2"></div>
                                                <h6 class="mb-2">Attachments</h6>
                                                <div class="row ml-0">                                        
                                                    <p v-for="attachment in inboxmail.attachment.split(',')" class="col px-0">
                                                        <a :href="attachment" download="proposed_file_name">Download</a>
                                                        <a :href="attachment" @click.prevent="" class="mx-0" :title="getFileName(attachment)" target="_blank">
                                                            <div v-if="imageFormats(getFileName(attachment))" :style="backgroundImage(attachment)" class="card mail-attachment-background">
                                                                <div class="show-btns-mail text-center">
                                                                    <span class="col hover-btn-mail align-bottom"><i class="fa fa-lg fa-download download-icon-mail" aria-hidden="true"></i></span>
                                                                </div>
                                                            </div>
                                                            <!-- <img v-if="imageFormats(attachment.fileName)" :src="attachment.attachmentPath" class="attachment-img" width="60px" height="50px"> -->
                                                            <div v-else class="mail-attachment-background text-center">
                                                                
                                                                <i class="fa fa-5x fa-file pt-1 text-success text-center" aria-hidden="true"></i>
                                                            </div>
                                                            <!-- <p>{{ getShortString(attachment.fileName, 12) }} {{getFileExtension(attachment.fileName)}}</p> -->
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                            </b-card>
                        </div>
                        </template>

                    </div>
                </div>
            </div>
        </div>

        <template #overlay>
                <div class="text-center text-info">
                    <LoadingSpinner />
                    <p class="font-weight-bold fs-18 mb-0">
                        <i class="fa fa-lg fa-envelope" aria-hidden="true"></i>
                        <label class="m-0 pl-2">Mail . . .</label>
                    </p>
                </div>
              </template>
        </b-overlay>
        <b-modal no-close-on-backdrop no-close-on-esc title="Reply" no-enforce-focus @hide="messageId='';replyMail.body='';" id="replyMailPopup" ref="replyMailRef" size="lg" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12 ">
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" v-model="replyMail.body" :toolbar="toolbar" :init="initObj"></editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center text-info">
                        <LoadingSpinner />
                        <p class="font-weight-bold fs-18 mb-0">
                            <i class="fa fa-lg fa-envelope" aria-hidden="true"></i>
                            <label class="m-0 pl-2">Sending Mail . . .</label>
                        </p>
                    </div>
                </template>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <button type="submit" @click="close()" class="btn btn-secondary float-right mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                    <button type="submit" @click="replyToMail" :disabled="showOverlay" class="btn btn-primary float-right mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-paper-plane pr-2"></i>Send</button>
                </div>
            </template>
        </b-modal>
        <SendMailPopup ref="sendMailPopupRef" id="jobShareMailPopup" :sendMailTitle="sendMailTitle" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import SendMailPopup from '@/components/popup/action/SendMailPopup.vue'
export default {
    name: 'app',
    components: {
        Editor,
        SendMailPopup,
    },
    data() {
        return {
            showOverlay: false,
            mailbox: {
                recipients: '',
                mailFrom: '',
                mailFromUser: '',
                body: '',
                sentDate: '',
                subject: '',
            },
            selectedEmailId:null,
            currentDate: new Date().toString().substr(0, 10),
            tabIndex: 0,
            visibleMailContent: false,
            selectedMailId: null,

            sendMailTitle: 'Send Mail',

            messageId: '',

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            mailList: [],
            sentMails: [],
            attachments: [],
            chainMailList: [],
            emailAccount: [],
            composeMail: {     
                toList: [],
                ccList: [],
                bccList: [],
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                recipients: 'femila.m@gmail.com',
                copyRecipients: 'femila@talentturbo.com',
                blindCopyRecipients: 'femila@talentturbo.com',
                subject: 'Reply mail',
                body: 'Testing Reply mail',
                attachments: [],
                entity: '',
                recipientIds: [],
                sendById: localStorage.getItem("userId"),
            },
            replyMail: {     
                toList: [],
                ccList: [],
                bccList: [],
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',             
                subject: 'Reply mail',
                body: 'Testing Reply mail',
                attachments: [],
                entity: '',
                recipientIds: [],
                sendById: localStorage.getItem("userId"),
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            editorHeight: 400,
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
          
        }
    },
    computed: {
        mailRoute() {
            let path = this.$route.path;
            return path.includes('landing') ? '/landing/email' : '/settings/email';
        }
    },
     watch: {
         'selectedEmailId': {
             handler: function(value) {             
                if(this.selectedEmailId != null)
                    this.getMailList();             
             },
             deep: true,
             immediate: true
         }, 
         tabIndex() {
            this.visibleMailContent = false;
            this.selectedMailId = null;
        }
     },
    mounted() {
        this.getData();        
    },
    methods: {
        backgroundImage(img) {
            return 'background: url(' + img + ') no-repeat center; background-size: cover;';
        },
        async getData(){
            await this.lookupData();
            await this.getMailList();
        },
        getMailList: function() {   
            let data = {
                userId: this.userId,
                vhextAccountId: this.vhextAccountId,
                emailAccountId: this.selectedEmailId,
            }
           return axios.post(this.$store.getters.getAPIBasePath + '/email/mailtracking/list',data)
                .then((response) => {
                    let result = response.data;
                    this.mailList = result.inboxMailTracking;
                    this.sentMails = result.sentMailTracking;
                   // this.sentMails = result.sentItemList;
                    console.log(result);
                }).catch(error => {
                    console.log(error);
                });
        },
        getChainMailList: function(messageId) {
            let data = {
                referenceId: messageId,
            }
            console.log(data);
            this.chainMailList =[];
            axios.post(this.$store.getters.getAPIBasePath + '/email/mailtracking/chainmail/list',data)
                .then((response) => {
                    let result = response.data;
                    this.chainMailList = result.chainMailList;
                    console.log(result);
                }).catch(error => {
                    console.log(error);
                });
        },
        handleMailClick: function(mail) {
           // this.getAttachment(mail.sendMailId);
            console.log(mail);
            console.log(mail.messageId);  
            this.visibleMailContent = true; 
            this.getChainMailList(mail.messageId);return;
            this.mailbox.sendMailId = mail.sendMailId;
            this.mailbox.mailFromUser = mail.sender;
            this.mailbox.mailFrom = mail.sender;
            this.mailbox.recipients = mail.receiver;
            this.mailbox.subject = mail.subject;
            this.mailbox.sentDate = mail.sentDate;
            this.mailbox.body = mail.content;
 
            this.selectedMailId = mail.id;
            this.attachments = [];
            if(mail.attachment != null && mail.attachment.length>0)
                this.attachments = mail.attachment.split(','); 


        },
        getFileExtension: function(name) {
            var re = /(?:\.([^.]+))?$/;

            return re.exec(name)[1];
        },
        getAttachment: function(sendMailId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: 'send_mail',
                refRecordId: sendMailId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                })
        },
        imageFormats(content) {
            var contentExtension = this.getFileExtension(content);
            if ( contentExtension == 'png' || contentExtension == 'jpg' || contentExtension == 'jpeg' || contentExtension == 'tiff' || contentExtension == 'tif' || contentExtension == 'jfif' || contentExtension == 'docx') {
                return true;
            }
            else return false;
        },
        refreshMail:function(){
            this.showOverlay = true;
              let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/fetch/email', data)
            .then((response) => {
                this.showOverlay = false;
                 this.getMailList();
            })
             .catch(error => {
                this.showOverlay = false;
            });
        },
        getFileName:function(path) {
            console.log(path);
            console.log(path.split('\\').pop().split('/').pop());
            return path.split('\\').pop().split('/').pop();
        },
        expandAccordian :function(chainMailList,index){
            let isVisible = false;
            if(index == chainMailList-1){
                isVisible = true;
            }
            return isVisible;
        },
        sendMail() {
            let sendMail = {};
            sendMail.body = '';
            sendMail.subject = '';
            sendMail.entity = 'send_mail';
            this.$nextTick(() => {
                this.$refs.sendMailPopupRef.setMailData(sendMail);
            })
        },
        // sendMail:function(){
        //     console.log(this.composeMail);

        //     let formData = new FormData();
        //     /* for (const row of Object.keys(this.attachments)) {    
        //         formData.append('files', this.attachments[row]);
        //     } */
           
        //     formData.append('email', this.composeMail.recipients);
        //     formData.append('ccEmail', this.composeMail.copyRecipients);
        //     formData.append('bccEmail', this.composeMail.blindCopyRecipients);
        //     formData.append('message', this.composeMail.body);
        //     formData.append('recipientIds', this.composeMail.recipientIds);
        //     formData.append('projectId', this.projectId);
        //     formData.append('vhextAccountId', this.vhextAccountId);
        //     formData.append('body', this.composeMail.body);
        //     formData.append('subject', this.composeMail.subject);
        //     formData.append('entity', this.composeMail.entity);
            
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/common/compose/email', formData).then(response => {
        //         this.showOverlay = false;
        //         let result = response.data;
        //         if (!response.status) {
        //             iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
        //         }
        //         iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });

        //     })
        //     .catch(error => {
        //         this.showOverlay = false;
        //         iziToast.error({ title: 'Error', message: 'Unable to send mail. Please try again', position: 'topRight' });
        //     });

        // },
        replyToMail:function(){
            console.log('reply mail');
            console.log(this.messageId);
            
             let formData = new FormData();
             /* for (const row of Object.keys(this.attachments)) {    
                 formData.append('files', this.attachments[row]);
             } */
           
      
             formData.append('message', this.replyMail.body);     
             formData.append('projectId', this.projectId);
             formData.append('vhextAccountId', this.vhextAccountId);
             formData.append('messageId', this.messageId);
            
             this.showOverlay = true;
             axios.post(this.$store.getters.getAPIBasePath + '/common/reply/email', formData).then(response => {
                 this.showOverlay = false;
                 let result = response.data;
                 if (!response.status) {
                     iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                 }
                 iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                 this.messageId = '';

             })
             .catch(error => {
                 this.showOverlay = false;
                 iziToast.error({ title: 'Error', message: 'Unable to send mail. Please try again', position: 'topRight' });
             });

        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId
            }
            console.log(data);           
            axios.post(this.$store.getters.getAPIBasePath +  '/lookup/emailaccount', data)
                .then((response) => {
                    let result = response.data;             
                    this.emailAccount = result.emailAccountLookup;
                    if(this.emailAccount != null){
                        this.selectedEmailId = this.emailAccount[0].id;
                    }
                  
                    console.log(this.emailAccount);
                })
        },
    }
    
}
</script>