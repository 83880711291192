<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Manage VMX Account User Types</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New VMX User Type</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="vhextAccountTypeId">VMX Account Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.vmxAccountUserType.vhextAccountTypeId.$error }">                                                            
                                                                <select v-model="vmxAccountUserType.vhextAccountTypeId" :class="{ 'is-invalid': $v.vmxAccountUserType.vhextAccountTypeId.$error }" class="form-control" id="vhextAccountTypeId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in vhextAccountTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.vmxAccountUserType.vhextAccountTypeId.$error && !$v.vmxAccountUserType.vhextAccountTypeId.required" class="text-danger">Please Select a VMX Account Type</label>       
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="userTypeId">User Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.vmxAccountUserType.userTypeId.$error }">                                                            
                                                                <select v-model="vmxAccountUserType.userTypeId" :class="{ 'is-invalid': $v.vmxAccountUserType.userTypeId.$error }" class="form-control" id="userTypeId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in userTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.vmxAccountUserType.userTypeId.$error && !$v.vmxAccountUserType.userTypeId.required" class="text-danger">Please Select a VMX User Type</label>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="vmxAccountUserType.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveVmxAccountUserType" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="form-row view-filter">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-8 col-sm-8">
                                                <b-input-group prepend="VMX Account Type" id="vhextAccountTypeId" >
                                                    <select v-model="standardFilterData.vhextAccountTypeId" class="form-control">
                                                        <option value="">All</option>
                                                        <option v-for="(info, index) in vhextAccountTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-3 col-sm-2">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
<!--                                     <div class="col-sm-2">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div> -->
                                    <div class="clearfix"></div>                                
                                </div>

                                <b-table ref="vmxAccountUserTypeTable" v-model="mainTableArray" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="filteredUserTypes"  @row-selected="onRowSelected" :fields="vmxAccountUserTypeFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    
                                    <template v-slot:cell(vhextAccountType)="data">
                                        <span class="badge badge-dark p-1 mr-2">{{data.item.vhextAccountTypeId}} </span>
                                        <span>{{data.item.vhextAccountType}}</span>
                                    </template>

                                    <template v-slot:cell(userType)="data">
                                        <span class="badge badge-dark p-1 mr-2">{{data.item.userTypeId}} </span>
                                        <span>{{data.item.userType}}</span>
                                    </template>

                                    <template v-slot:cell(isPrimary)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="updateInlineStateChange('is_primary', data.value)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(isBasic)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="updateInlineStateChange('is_basic', data.value)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <!--<template v-slot:cell(title)="data">
                                        <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                    </template>-->
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteVmxAccountUserType(data.item.id)" data-toggle="modal" data-target="#deleteVmxAccountUserType"><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No VMX User Types found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

<!--                                 <b-pagination v-if="vmxAccountUserTypeList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{vmxAccountUserTypeDetail.vmxAccountUserTypeId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateVmxAccountUserType" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
<!--                                             <div class="col-6">
                                                <div class="form-group">
                                                    <label for="vmxAccountUserType">VMX User Type</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.vmxAccountUserTypeDetail.userType.$error }">
                                                        <input type="text" class="form-control" v-model="vmxAccountUserTypeDetail.userType" :disabled="!elements.editMode"  id="vmxAccountUserTypeDetail" :class="{ 'is-invalid': $v.vmxAccountUserTypeDetail.userType.$error }" placeholder="Enter VMX User Type">
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.vmxAccountUserTypeDetail.userType.$error && !$v.vmxAccountUserTypeDetail.userType.minLength" class="text-danger">VMX User Type title must have at least {{$v.vmxAccountUserTypeDetail.userType.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.vmxAccountUserTypeDetail.userType.$error && !$v.vmxAccountUserTypeDetail.userType.required" class="text-danger">Please enter VMX User Type</label>
                                                    <label v-if="$v.vmxAccountUserTypeDetail.userType.$error && !$v.vmxAccountUserTypeDetail.userType.maxLength" class="text-danger">VMX User Type must not exceed {{$v.vmxAccountUserTypeDetail.userType.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div> -->
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="vhextAccountTypeId">VMX Account Type</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.vmxAccountUserTypeDetail.vhextAccountTypeId.$error }">                                                            
                                                        <select v-model="vmxAccountUserTypeDetail.vhextAccountTypeId"  :class="{ 'is-invalid': $v.vmxAccountUserTypeDetail.vhextAccountTypeId.$error }" class="form-control" id="vhextAccountTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in vhextAccountTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.vmxAccountUserTypeDetail.vhextAccountTypeId.$error && !$v.vmxAccountUserTypeDetail.vhextAccountTypeId.required" class="text-danger">Please Select a VMX Account Type</label>                                                           
<!--                                                     <label for="vhextAccountTypeId">VMX Account Type</label>
                                                    <select v-model="vmxAccountUserTypeDetail.vhextAccountTypeId" :disabled="!elements.editMode"  class="form-control" id="vhextAccountTypeId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in vhextAccountTypes" :value="info.id">{{info.value}}</option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="userTypeId">User Type</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.vmxAccountUserTypeDetail.userTypeId.$error }">                                                            
                                                        <select v-model="vmxAccountUserTypeDetail.userTypeId" :class="{ 'is-invalid': $v.vmxAccountUserTypeDetail.userTypeId.$error }" class="form-control" id="userTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in userTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.vmxAccountUserTypeDetail.userTypeId.$error && !$v.vmxAccountUserTypeDetail.userTypeId.required" class="text-danger">Please Select a VMX User Type</label>
                                                </div>                                                
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="vmxAccountUserTypeDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="vmxAccountUserTypeDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="vmxAccountUserTypeDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="vmxAccountUserTypeDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div> -->
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteVmxAccountUserType" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete VMX User Type?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            showDetOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'vmx_account_user_type',

            idValue: '',
            idEdit: '',
            mainTableArray: [],

            standardFilterData: {
                vhextAccountTypeId: '',
            },

            vmxAccountUserType: {
                id: '',
                vmxAccountUserTypeId: '',
                vhextAccountTypeId: '',
                userTypeId: '',
                userType: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },
            vmxAccountUserTypeDetail: {
                id: '',
                vmxAccountUserTypeId: '',
                vhextAccountTypeId: '',
                userTypeId: '',
                userType: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
            },

            vhextAccountTypes: [],
            userTypes: [],
            vmxAccountUserTypeList: [],

            vmxAccountUserTypeFields: [
                {key: 'index',label: 'SN'},
                { key: 'id', label: 'PK ID', sortable: true, sortDirection: 'desc' },
                // { key: 'vhextAccountTypeId', label: 'VMX Account Type Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },                
                // { key: 'userTypeId', label: 'User Type Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'vhextAccountType', label: 'VMX Account Type', sortable: true, sortDirection: 'desc' },
                { key: 'userType', label: 'User Type', sortable: true, sortDirection: 'desc' },
                { key: 'typeCode', label: 'Type Code', sortable: true, sortDirection: 'desc' },
                { key: 'isBasic', label: 'Basic', sortable: true, sortDirection: 'desc' },
                { key: 'isPrimary', label: 'Primary User Type', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],


            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredUserTypes() {
            let vhextAccountTypeId = this.standardFilterData.vhextAccountTypeId;
            let filteredUserTypes = [];

            let filterArray = [];

            if (vhextAccountTypeId) {
                filterArray = this.vmxAccountUserTypeList.filter(data => {
                    if (data.vhextAccountTypeId == vhextAccountTypeId) {
                        return data;
                    }
                });
            }
            else filterArray = this.vmxAccountUserTypeList;

            filteredUserTypes = filterArray;

            this.totalRows = filteredUserTypes.length;
            return filteredUserTypes;
        }
    },    
    mounted() {
        this.getVmxAccountUserTypes();
        this.getLookupData();
    },
    validations: {
        vmxAccountUserType: {
            vhextAccountTypeId: {
                required,
            },
            userTypeId: {
                required,
            },
        },
        vmxAccountUserTypeDetail: {
            vhextAccountTypeId: {
                required,
            },
            userTypeId: {
                required,
            },
        }
    },
    methods: {
        getVmxAccountUserTypes: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/list/all',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.vmxAccountUserTypeList && result.vmxAccountUserTypeList.length > 0) {
                        this.vmxAccountUserTypeList = result.vmxAccountUserTypeList;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.vmxAccountUserTypeDetail.id, this.mainTableArray, 'vmxAccountUserTypeTable');
                        })
                        this.totalRows = this.vmxAccountUserTypeList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLookupData: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/lookup/all')
                .then((response) => {
                    let result = response.data;
                    this.vhextAccountTypes = result.vhextAccountTypeLookup;
                    this.userTypes = result.userTypeLookup;

                    this.standardFilterData.vhextAccountTypeId = this.vhextAccountTypes.length > 0 ? this.vhextAccountTypes[0].id : '';
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveVmxAccountUserType: function() {
            this.$v.vmxAccountUserType.$touch();
            if (!this.$v.vmxAccountUserType.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/add', this.vmxAccountUserType)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getVmxAccountUserTypes();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateVmxAccountUserType: function() {
            console.log(this.vmxAccountUserTypeDetail);
            this.$v.vmxAccountUserTypeDetail.$touch();
            if (!this.$v.vmxAccountUserTypeDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/update', this.vmxAccountUserTypeDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getVmxAccountUserTypes();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewVmxAccountUserType: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let vmxAccountUserTypeDetail = result.vmxAccountUserType;
                    if (vmxAccountUserTypeDetail) {                        
                        this.vmxAccountUserTypeDetail.id = vmxAccountUserTypeDetail.id;
                        this.vmxAccountUserTypeDetail.vmxAccountUserTypeId = vmxAccountUserTypeDetail.vmxAccountUserTypeId;
                        this.vmxAccountUserTypeDetail.vhextAccountTypeId = vmxAccountUserTypeDetail.vhextAccountTypeId;
                        this.vmxAccountUserTypeDetail.userTypeId = vmxAccountUserTypeDetail.userTypeId;
                        this.vmxAccountUserTypeDetail.userType = vmxAccountUserTypeDetail.userType;
                        this.vmxAccountUserTypeDetail.active = vmxAccountUserTypeDetail.active;
                        this.vmxAccountUserTypeDetail.createdById = vmxAccountUserTypeDetail.createdById;
                        this.vmxAccountUserTypeDetail.createdBy = vmxAccountUserTypeDetail.createdBy;
                        this.vmxAccountUserTypeDetail.createdDt = vmxAccountUserTypeDetail.createdDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewVmxAccountUserType(this.vmxAccountUserTypeDetail.id);
        },
        deleteVmxAccountUserType: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getVmxAccountUserTypes();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.vmxAccountUserType.id = '';
            this.vmxAccountUserType.vmxAccountUserTypeId = '';
            this.vmxAccountUserType.vhextAccountTypeId = '';
            this.vmxAccountUserType.userTypeId = '';
            this.vmxAccountUserType.active = true;
            this.vmxAccountUserType.createdById = localStorage.getItem("userId");
            this.vmxAccountUserType.createdBy = '';
            this.vmxAccountUserType.createdDt = '';
            this.$v.vmxAccountUserType.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.vmxAccountUserTypeDetail.id = data.id;
            this.vmxAccountUserTypeDetail.vmxAccountUserTypeId = data.vmxAccountUserTypeId;
            this.vmxAccountUserTypeDetail.vhextAccountTypeId = data.vhextAccountTypeId;
            this.vmxAccountUserTypeDetail.userTypeId = data.userTypeId;
            this.vmxAccountUserTypeDetail.active = data.active;
            this.vmxAccountUserTypeDetail.createdById = data.createdById;
            this.vmxAccountUserTypeDetail.createdBy = data.createdBy;
            this.vmxAccountUserTypeDetail.createdDt = data.createdDt;
        },
        onRowSelected: function(items) {
            console.log(items);
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.vmxAccountUserTypeDetail.id = data.id;
                this.vmxAccountUserTypeDetail.vmxAccountUserTypeId = data.vmxAccountUserTypeId;
                this.vmxAccountUserTypeDetail.vhextAccountTypeId = data.vhextAccountTypeId;
                this.vmxAccountUserTypeDetail.userTypeId = data.userTypeId;
                this.vmxAccountUserTypeDetail.userType = data.userType;
                this.vmxAccountUserTypeDetail.active = data.active;
                this.vmxAccountUserTypeDetail.createdById = data.createdById;
                this.vmxAccountUserTypeDetail.createdBy = data.createdBy;
                this.vmxAccountUserTypeDetail.createdDt = data.createdDt;
            }
        },
        updateInlineStateChange: function(columnName, value) {
            let stateValue = 0;

            if (value == true) {
                stateValue = 0;
            } else if (value == false){
                stateValue = 1;
            }

            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: this.entity,
                columnName: columnName,
                stateValue: stateValue,            
                id: item.id,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    this.elements.showDetail = false;
                    this.vmxAccountUserType.active = true;
                    this.getVmxAccountUserTypes();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        assignActivationState: function(item) {

            this.showOverlay = true;
            if (item.active == true) {
                this.vmxAccountUserType.entity = this.entity;
                this.vmxAccountUserType.id = item.id;
                this.vmxAccountUserType.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.vmxAccountUserType.entity = this.entity;
                this.vmxAccountUserType.id = item.id;
                this.vmxAccountUserType.active = 1;

                this.updateActivationState();
            }
            console.log(this.vmxAccountUserType.id, this.vmxAccountUserType.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.vmxAccountUserType)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.vmxAccountUserType.active = true;
                    this.getVmxAccountUserTypes();
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    console.log(error)
                })
        },
    }
}
</script>