<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Budget Plan Document - {{ ermType }}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>                                
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Approved By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">NULL</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="ermReviewApprovals && ermReviewApprovals.length > 0" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Review / Approval</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description != ''" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Budget Plan Description</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">{{erm.description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Budget Track Chart</span></p>
                            <BudgetChart :key="budgetChartKey" :ermId="entityRecordMasterId" :ermTypeCode="ermTypeCode" />
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermTypeCode === 'MOB'" class="card-body p-3">
                            <p class="contact-title"><span>Budget Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="budgetPlans" :fields="monthlyBudgetPlanFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                            
                                            <template v-slot:cell(estimatedCost)="data">
                                                <div class="text-right">{{ data.value.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Plans found</div>
                                            </template>     

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>
                                            <template v-slot:foot(accountablePerson)="data">
                                                <span class="text-right">Total Amount :</span>
                                            </template>
                                            <template v-slot:foot(estimatedCost)="data">
                                                <div class="text-right"><span class="text-right">{{totalExpenses.toFixed(2)}}</span></div>
                                            </template>                                                                                                            
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="ermTypeCode === 'PEB'" class="card-body p-3">
                            <p class="contact-title"><span>Budget Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="budgetPlans" :fields="periodicBudgetPlanFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                            
                                            <template v-slot:cell(estimatedCost)="data">
                                                <div class="text-right">{{ data.value.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Plans found</div>
                                            </template>     

                                            <template v-slot:foot(index)="data">
                                                <span></span>
                                            </template>
                                            <template v-slot:foot(title)="data">
                                                <span></span>
                                            </template>
                                            <template v-slot:foot(activity)="data">
                                                <span></span>
                                            </template>
                                            <template v-slot:foot(category)="data">
                                                <span></span>
                                            </template>

                                            <template v-slot:foot(accountablePerson)="data">
                                                <span class="text-right">Total Amount :</span>
                                            </template>
                                            <template v-slot:foot(estimatedCost)="data">
                                                <div class="text-right"><span class="text-right">{{totalExpenses.toFixed(2)}}</span></div>
                                            </template>                                                                                                            
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="ermTypeCode === 'PRB'" class="card-body p-3">
                            <p class="contact-title"><span>Budget Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="budgetPlans" :fields="budgetPlanFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                            
                                            <template v-slot:cell(estimatedCost)="data">
                                                <div class="text-right">{{ data.value.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Plans found</div>
                                            </template>     

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>

                                            <template v-slot:foot(accountablePerson)="data">
                                                <span class="text-right">Total Amount :</span>
                                            </template>
                                            <template v-slot:foot(estimatedCost)="data">
                                                <div class="text-right"><span class="text-right">{{totalExpenses.toFixed(2)}}</span></div>
                                            </template>                                                                                                            
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="ermTypeCode === 'BTI'" class="card-body p-3">
                            <p class="contact-title"><span>Budget Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="budgetPlans" :fields="budgetPlanFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                            
                                            <template v-slot:cell(estimatedCost)="data">
                                                <div class="text-right">{{ data.value.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Plans found</div>
                                            </template>     

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>

                                            <template v-slot:foot(accountablePerson)="data">
                                                <span class="text-right">Total Amount :</span>
                                            </template>
                                            <template v-slot:foot(estimatedCost)="data">
                                                <div class="text-right"><span class="text-right">{{totalExpenses.toFixed(2)}}</span></div>
                                            </template>                                                                                                            
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Budget Cost Estimation</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" :items="budgetCostEstimations" :fields="budgetCostEstimationFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting>
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>                                            

                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Cost Estimation found</div>
                                            </template>     

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>
                                            <template v-slot:cell(cost)="data">
                                                <div class="text-right">{{ data.value.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:foot(costEstimationType)="data">
                                                <span class="text-right">Total Amount :</span>
                                            </template>
                                            <template v-slot:foot(cost)="data">
                                                <div class="text-right"><span class="text-right">{{totalCostEstimationExpenses.toFixed(2)}}</span></div>
                                            </template>                                                                                                            
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Budget Financial Plan</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" :items="budgetFinancialPlans" :fields="budgetFinancialPlanFields" responsive="sm" foot-clone foot-variant=light no-footer-sorting>
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Financial Plan found</div>
                                            </template>     
                                            <template v-slot:cell(amount)="data">
                                                <div class="text-right">{{ data.value.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>

                                            <template v-slot:foot(accountablePerson)="data">
                                                <span class="text-right">Total Amount :</span>
                                            </template>
                                            <template v-slot:foot(amount)="data">
                                                <div class="text-right"><span class="text-right">{{totalFinancialPlanExpenses.toFixed(2)}}</span></div>
                                            </template>                                                                                                            
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import BudgetChart from '@/components/charts/BudgetChart.vue';
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    name: 'app',
    components: {
        BudgetChart,
        VhextMoneyControl,
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,

    },    
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermType: localStorage.getItem('ermType'),
            ermTypeCode: localStorage.getItem('ermTypeCode'),
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            budgetChartKey: 100,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],

            budgetPlans: [],
            budgetCostEstimations: [],
            budgetFinancialPlans: [],

            budgetPlanFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },                        
                // { key: 'budgetId', label: 'Id', thStyle: "width: 10px", sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },

                { key: 'category', label: 'Budget Category', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Estimated Cost', class:"text-right", sortable: true, sortDirection: 'desc' }
            ],
            monthlyBudgetPlanFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },                        
                // { key: 'budgetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'budgetPeriodStr', label: 'Month', sortable: true, sortDirection: 'desc' },
                { key: 'category', label: 'Budget Category', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Estimated Cost', sortable: true, sortDirection: 'desc' },
            ],

            periodicBudgetPlanFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },                        
                // { key: 'budgetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'budgetStartDateStr', label: 'Start Month', sortable: true, sortDirection: 'desc' },
                { key: 'budgetPeriodStr', label: 'End Month', sortable: true, sortDirection: 'desc' },
                { key: 'category', label: 'Budget Category', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Estimated Cost', sortable: true, sortDirection: 'desc' },
            ],
            budgetCostEstimationFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },                        
                // { key: 'budgetId', label: 'Id', thStyle: "width: 10px", sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'bplTitle', label: 'Budget Title', sortable: true, sortDirection: 'desc' },
                { key: 'unitCost', label: 'Unit Price', sortable: true, sortDirection: 'desc' },
                { key: 'quantity', label: 'Quantity', sortable: true, sortDirection: 'desc' },
                { key: 'costEstimationType', label: 'Unit', sortable: true, sortDirection: 'desc' },
                { key: 'cost', label: 'Cost', class:"text-right", sortable: true, sortDirection: 'desc' }
            ],

            budgetFinancialPlanFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },                        
                // { key: 'budgetId', label: 'Id', thStyle: "width: 10px", sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'budgetPlanTitle', label: 'Budget Title', sortable: true, sortDirection: 'desc' },
                { key: 'financeSource', label: 'Finanacial Source', sortable: true, sortDirection: 'desc' },
                { key: 'targetDate', label: 'Target Date',formatter: "formatDate",sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable Person', class:"text-right", sortable: true, sortDirection: 'desc'},
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
            ],


            selectMode: 'single',
            notEnabled: true,
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    computed: {
        totalExpenses() {
            return this.budgetPlans.reduce((accum, item) => {
            return accum + parseFloat(item.estimatedCost)
            }, 0.00)
        },
        totalCostEstimationExpenses() {
            return this.budgetCostEstimations.reduce((accum, item) => {
            return accum + parseFloat(item.cost)
            }, 0.00)
        },
        totalFinancialPlanExpenses() {
            return this.budgetFinancialPlans.reduce((accum, item) => {
            return accum + parseFloat(item.amount)
            }, 0.00)
        },

    },

    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.entityMaster = 'budget_plan_master';
            this.entity = 'budget_plan';

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getBudgetPlan(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.budgetChartKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getBudgetPlan(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.budgetChartKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        getBudgetPlan: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/plan/report', data)
                .then((response) => {
                    let result = response.data;
                    this.budgetPlans = result.budgetPlanList;
                    this.budgetCostEstimations = result.budgetCostEstimationList;
                    this.budgetFinancialPlans = result.budgetFinancialPlanList;

                    console.log(this.budgetCostEstimations);
                })
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }
    }
}
</script>