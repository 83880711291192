<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Project</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group ">
                                            <label for="title">Title</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.title.$error }">
                                                <input type="text" class="form-control" v-model="project.title" id="title" :class="{ 'is-invalid': $v.project.title.$error }" placeholder="Enter Title">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.title.$error && !$v.project.title.minLength" class="text-danger">Title must have at least {{$v.project.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.maxLength" class="text-danger">Title must not exceed {{$v.project.title.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                    <div class="col-2 pl-0">
                                        <div class="form-group ">
                                            <label for="displayName">Short Name</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.displayName.$error }">
                                                <input type="text" class="form-control" v-model="project.displayName" id="displayName" placeholder="Enter Display Name">
                                            </div>
                                            <label v-if="$v.project.displayName.$error && !$v.project.displayName.maxLength" class="text-danger">Short name must not exceed {{$v.project.displayName.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>

                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="projectTypeId">Project Type</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.projectTypeId.$error }">
                                                <select v-model="project.projectTypeId" class="form-control" :class="{ 'is-invalid': $v.project.projectTypeId.$error }">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.projectTypeId.$error && !$v.project.projectTypeId.required" class="text-danger">Please select Project Type</label>                                            
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="projectManager">Project Manager</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.projectManagerId.$error }">
                                                <select v-model="project.projectManagerId" disabled class="form-control" :class="{ 'is-invalid': $v.project.projectManagerId.$error }">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.projectManagerId.$error && !$v.project.projectManagerId.required" class="text-danger">Please select Project Manager</label>
                                        </div>
                                    </div>
<!--                                     <div class="col-6">
                                        <div class="form-group">
                                            <label for="categoryId">Project Category</label>
                                            <select v-model="project.categoryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectCategory" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <a :title="categoryLookupTitle" class="setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                        </div>
                                    </div> -->
                                    <div class="col-6">
                                        <div class="form-group select-box-3">
                                            <label for="duration">Duration</label>

                                            <div class="row">
                                                <div class="col-4">
                                                    <b-input-group append="years">
                                                        <select v-model="project.durationYears" class="form-control">
                                                            <option value="">Sl</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                
                                                <div class="col-4 pl-0">
                                                    <b-input-group append="months">
                                                        <select v-model="project.durationMonths" class="form-control">
                                                            <option value="">Sl</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-4 pl-0">
                                                    <b-input-group append="days">
                                                        <select v-model="project.durationDays" class="form-control">
                                                            <option value="">Sl</option>
                                                            <option v-for="(info, index) in daysList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="startDate">Start Date</label>
                                            <div>
                                                <input v-model="project.startDate" type="date" class="form-control" placeholder="Start Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="endDate">End Date</label>
                                            <div>
                                                <input v-model="project.endDate" type="date" class="form-control" placeholder="End Date">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Budget</label>
                                            <b-input-group>
<!--                                                 <b-input-group-prepend class="custom-input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fa fa-inr" aria-hidden="true"></i>
                                                    </span>
                                                </b-input-group-prepend> -->
                                                <b-form-input type="text" v-model="project.initialBudget">
                                                </b-form-input>
                                                <b-input-group-append class="custom-input-group-append">
                                                    <span class="input-group-text">
                                                        .00
                                                    </span>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="col-12">
    <!--                                     <label>Description</label> -->
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <Editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="project.description"></Editor>
                                        </div>

    <!--                                     <textarea v-model="project.description" class="form-control" rows="4" required></textarea> -->
                                    </div>
                                </div>
                                <a href="javascript:" @click="saveProject" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                                <a href="javascript:" @click="getPrevUrl()" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                                <a href="javascript:" title="Reset" @click="clearData" class="p-3 float-right"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="accessType">Project Model</label>
                                            <select  v-model="project.projectAccessTypeId" class="form-control" >
                                                <option v-for="(info, index) in projectAccessTypes" :value="info.id">{{info.value}}</option>
                                            </select>                            
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="projectStatus">Project Status</label>
                                            <select v-model="project.statusId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="country">Country</label>
                                            <select v-model="project.countryId" class="form-control">
                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Location</label>
                                            <input type="text" v-model="project.location" class="form-control" id="location" placeholder="Location">
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="currency">Currency</label>
                                            <select v-model="project.currencyId" class="form-control">
                                                <option v-for="(info, index) in currencies" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="timezone">Timezone</label>
                                            <Select2 v-model="project.timeZone" :options="timeZone" :settings="{width:'100%'}" />
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                    </div>
                                        
                                    <div v-if="false" class="col-12">
                                        <div class="form-group">
                                            <div>
                                                <label for="exampleInputPassword1">Attachment </label>
                                                <!-- <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2"></i>Add More</a> -->
                                            </div>

                                            <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                <div class="attachments mb-2">
                                                    <b-form-file class="attachments-list" size="sm" v-model="project.attachments[index]" placeholder="Choose a file.">
                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                        </template>
                                                    </b-form-file>
                                                    <a href="javascript:" title="Save attachment" @click="saveProject()" class="float-right  p-2 attachments-remove action-link">
                                                        <i class="fa fa-check pr-2"></i>
                                                    </a>
                                                    <!-- <a href="javascript:" @click="removeFile(index)" class="float-right button-remove p-2 attachments-remove"><i class="fa fa-minus-circle pr-2"></i></a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     <!-- <a class="setting-icon" href="javascript:" v-b-modal.projectSetting><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <ProjectSettingRedirectPopup />
        <!-- <CategoryLookup ref="categoryLookupModal" :entity="entity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import month from '@/json/month.json';
import days from '@/json/days.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ProjectSettingRedirectPopup from '@/components/popup/dialogbox/ProjectSettingRedirectPopup.vue';
// import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue';
import Editor from '@tinymce/tinymce-vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
export default {
    name: 'app',
    components: {
        ProjectSettingRedirectPopup,
        // CategoryLookup,
        Editor,
        Select2,
        AttachmentComponent
    },
    data() {
        return {
            showOverlay: false,
            entity: 'project',
            categoryLookupTitle: 'Project Category Settings',
            multiselect: true,
            monthList: month,
            daysList: days,
            projectFile: null,
            files: '',
            projectTypes: [],
            projectAccessTypes: [],
            accessTypes: [],
            projectCategory: [],
            projectStatus: [],
            timeZone: [],
            users: [],
            countries: [],
            currencies: [],
            addMore: [],

            project: {
                title: '',
                displayName: '',
                description: '',
                projectTypeId: '',
                projectAccessTypeId: '',
                projectManagerId: localStorage.getItem("userId"),
                categoryId: '',
                durationDays: '',
                durationMonths: '',
                durationYears: '',
                initialBudget: '',
                startDate: '',
                endDate: '',
                statusId: '',
                timeZone: '',
                effortConversionHourDay: '',
                location: '',
                countryId: '',
                isProject: 1,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                attachments: [],
                entity: 'project',
                userTypeCode: localStorage.getItem("userTypeCode"),
                currencyId: '',
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            attachmentInfo: {
                title: 'Attachment',
                successMessage: false
            },
            attachments: [],

        }
    },
    computed: {
        setDefaultCountry() {
            let defaultCountry;
            this.countries.filter((data) => {
                if (data.value == 'India') {
                    defaultCountry = data.id;
                }
            })
            return defaultCountry;
        },

        setDefaultCurrency() {
            let defaultCurrency;
            this.currencies.filter((data) => {
                if (data.value == 'Rupee') {
                    defaultCurrency = data.id;
                }
            })
            return defaultCurrency;
        }
    },
    mounted() {
        this.lookupData();
        this.addMore.push(this.addMore.length + 1);
    },
    validations: {
        project: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            displayName: {
                maxLength: maxLength(10)
            },
            projectTypeId: {
                required
            },
            projectManagerId: {
                required
            }
        }
    },
    methods: {
        lookupData: function() {

            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }

            axios.post(this.$store.getters.getAPIBasePath + '/project/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTypes = result.typeLookup;
                    this.projectAccessTypes = result.projectAccessTypeLookup;
                    this.projectCategory = result.customCategoryLookup;
                    this.projectStatus = result.statusLookup;
                    this.timeZone = result.timeZoneLookup;
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                    this.currencies = result.currencyLookup;
                    this.getStatusId('Open');

                    this.accessTypes = this.projectAccessTypes;

                    this.accessTypes = this.accessTypes.find( ({ value }) => value === 'Standard' );
                    this.project.projectAccessTypeId = this.accessTypes.id;

                    this.project.countryId = this.setDefaultCountry;
                    this.project.currencyId = this.setDefaultCurrency;

                    console.log(this.project.projectAccessTypeId);

                })

        },
        getStatusId: function(name) {
            let self = this;
            return this.projectStatus.filter(function(data, key) {
                if (data.value == name) {
                    self.project.statusId = data.id;
                    return data.id;
                }
            });
        },
        async saveProject() {
            this.$v.project.$touch();               
            if (!this.$v.project.$invalid) {
                await this.saveProjectData();
                await this.attachDocument();
            }
        },
        saveProjectData() { 
            this.showOverlay = true;
            localStorage.setItem('projectName', this.project.title);
            return axios.post(this.$store.getters.getAPIBasePath + '/project/add', this.project)
                .then((response) => {                    
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        localStorage.setItem('projId', result.id);
                        localStorage.setItem('projectId', result.id);                           
                        localStorage.setItem('vmxServiceCode', result.vmxServiceCode);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })    

        },
        attachDocument: function() {
            if (this.attachments != null && this.attachments.length > 0){   
                this.showOverlay = false;
                this.$refs.attachmentControl.saveAttachment(this.entity, localStorage.getItem('projectId'));
                return this.showMessage('Project Created', localStorage.getItem('projectId'));
            }
            else {
                this.showOverlay = false;
                return this.showMessage('Project Created', localStorage.getItem('projectId'));
            }
        },
        clearData: function() {
            this.project.title = '';
            this.project.displayName = '';
            this.project.description = '';
            this.project.projectTypeId = '';
            // this.project.projectAccessTypeId = '';
            // this.project.projectManagerId = '';
            this.project.categoryId = '';
            this.project.durationDays = '';
            this.project.durationMonths = '';
            this.project.durationYears = '';
            this.project.initialBudget = '';
            this.project.startDate = '';
            this.project.endDate = '';
            this.project.description = '';
            // this.project.statusId = '1';
            this.project.currencyId = this.setDefaultCurrency;
            this.project.timeZone = '';
            this.project.effortConversionHourDay = '';
            this.project.location = '';
            this.project.countryId = this.setDefaultCountry;
            this.attachments = [];
            this.$v.project.$reset();
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            this.project.attachments.splice(index, 1);
        },
        submitFiles: function(projectId) {
            let formData = new FormData();
            for (const row of Object.keys(this.project.attachments)) {
                formData.append('files', this.project.attachments[row]);
            }
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', projectId);
            formData.append('refEntity', this.project.entity);
            formData.append('refRecordId', projectId);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Project Created Successfully', projectId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    this.showMessage('Project Created Successfully', projectId);
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function(message, id) {
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
            this.getProjectPrimeSettings(id);
        },
        getProjectPrimeSettings: function(id) {

            let data = {
                projectId: id,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/primesettings', data)
                .then((response) => {
                    let result = response.data.projectPrimeSettings;
                    if (result) {
                        localStorage.setItem('currency', result.currencySymbol);
                        localStorage.setItem('projectTypeId', result.projectTypeId);
                    }
                    localStorage.setItem('projectId', id);
                    // this.$router.push('/projectview');
                    this.$bvModal.show('projectSettingRedirectPopup');
                })
        },
    }
}
</script>