<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <b-table show-empty small outlined stacked="md"  sort-icon-left :items="resourcePlanList" :fields="resourcePlanFields" responsive="sm"  :current-page="currentPage" @filtered="onFiltered" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >    

                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <!-- <template v-slot:cell(timeValue)="data">
                    <span>{{ data.item.workFrequency }} {{ data.item.timeValue }} {{ data.item.timeUnit }}</span>
                </template> -->
                <template v-slot:empty="scope">
                    <div class="text-center">No Record found</div>
                </template>
                <template v-slot:emptyfiltered="scope">
                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                </template>
            </b-table>
            <b-pagination v-if="resourcePlanList.length!=0" pills v-model="currentPage" :total-rows="totalRows" first-number last-number :per-page="perPage" align="fill" size="sm" class="my-0 float-right"></b-pagination>
            <div class="float-left">
                <div class="dataTables_length">
                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            resourcePlanList: [],
            resourcePlanFields: [
                { key: 'index', label: 'SN' },
                { key: 'resourceCategory', label: 'Category', sortable: true, sortDirection: 'desc' }, 
                { key: 'resourceType', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCount', label: 'Res. Count', tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Req. From' , formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'tillDate', label: 'Req. Till' , formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'totalWorkDays', label: 'Tot. Working Days', tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
            ],


            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    methods: {
        getErdDetailList: function(ermId) {
            if (ermId) {
                let data = {
                    entityRecordMasterId: ermId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.resourcePlanList = result.resourcePlanList;
                        this.totalRows = this.resourcePlanList.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                    })
            }
            else {
                this.resourcePlanList = [];
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>