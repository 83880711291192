<template>
    <div>
        <MeetingMinuteMaster :ermMasterInfo="ermMasterInfo"></MeetingMinuteMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import MeetingMinuteMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        MeetingMinuteMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Meeting Minute Master",
                entity: 'meeting_minute_master',
                refEntity: 'meeting_minute',
                ermListRedirectURL: '/mmnmas/list',
                erdRedirectURL: '/meetingminute/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>