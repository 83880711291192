<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col-auto p-1">
                                                <b-avatar v-if="humanResourceDetails.photo" class="mr-2" :src="humanResourceDetails.photo" size="lg"></b-avatar>
                                                <b-avatar v-else class="mr-2"></b-avatar>
                                            </div>
                                            <div class="col">
                                                <div class="caption-title mr-auto">{{ humanResourceDetails.name }}</div>
                                                <div class="mr-auto text-muted fs-11">{{ humanResourceDetails.designation }}</div>
                                                <div v-if="humanResourceDetails.employeeCode" class="mr-auto">EID : {{ humanResourceDetails.employeeCode }}</div>
                                            </div>
                                        </div>                                                
                                        <div class="separator mb-1"></div>
                                    </div>
                                </div>

                                <div v-if="employeeAttendanceDetails" class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="group-header-title text-left mb-0">Attendance
                                            <span v-if="isPrimaryRole === 'true'" class="float-right pointer mr-2">
                                                <a v-if="!employeeAttendanceDetails.statusId" href="javascript:" title="No Attendance" class="pointer emp-tl-indicator px-1 grey" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o"></i>
                                                </a>
                                                <a v-else-if="employeeAttendanceDetails.statusLookupId==4" href="javascript:" title="Present" class="pointer emp-tl-indicator px-1 green" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o"></i>
                                                </a>
                                                <a v-else-if="employeeAttendanceDetails.statusLookupId==3" href="javascript:" title="Absent" class="pointer emp-tl-indicator px-1 red" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o"></i>
                                                </a>
                                                <a v-else href="javascript:" title="Half Day Leave" class="pointer emp-tl-indicator px-1 yellow" v-b-modal.empAttendanceTimelinePopup @click="">
                                                    <i class="fa fa-clock-o"></i>
                                                </a>
                                            </span>
                                        </p>
                                        <div class="separator my-1"></div>
                                        <div class="mt-2 pl-2">
                                            <p v-if="employeeAttendanceDetails.empStatus" class="row">
                                                <span class="col-5 pr-1">Emp Status :</span> 
                                                <span class="col px-0">
                                                    <i class="fa fa-check text-success pr-1" aria-hidden="true"></i>{{employeeAttendanceDetails.empStatus}}
                                                </span>
                                            </p>
                                            <p v-if="employeeAttendanceDetails.status !== null && employeeAttendanceDetails.status !== undefined" class="row">
                                                <span class="col-5 pr-1">HR Status :</span>
                                                <span class="col px-0">
                                                    <i v-if="employeeAttendanceDetails.statusLookupId==4" class="fa fa-check text-success pr-1" aria-hidden="true"></i>
                                                    <i v-else-if="employeeAttendanceDetails.statusLookupId==3" class="fa fa-times text-danger pr-1" aria-hidden="true"></i>
                                                    <i v-else class="fa fa-check text-warning pr-1" aria-hidden="true"></i>{{employeeAttendanceDetails.status}}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="employeeAttendanceDetails" class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="group-header-title text-left py-1 mb-0">Leave & Time
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <b-button class="addnew-tassk-btn" variant="outline-primary" @click="showLeaveTimePopup('LVE')" size="sm"><i class="fa fa-calendar-o pr-1"></i>Apply Leave</b-button>
                                            <b-button class="addnew-tassk-btn mt-2" variant="outline-primary" @click="showLeaveTimePopup('PER')" size="sm"><i class="fa fa-clock-o pr-1"></i>Apply Permission</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="employeeAttendanceDetails" class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="group-header-title text-left py-1 mb-0">Activities
                                            <span class="dataTables_filter ml-2 mt-1 mb-0">
                                                <input type="date" v-model="startDate" @change="getUserActivity()" class="small-input">  
                                            </span>
                                            <span class="badge badge-dark float-right pointer px-2 mb-0 mr-1" title="Refresh" @click="getUserActivity()">
                                                {{ userActivityCount }}
                                            </span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body px-1 py-1">
                                            <div class="data-tables-hide-filter">
                                            <b-table outlined show-empty small head-variant=:primary sticky-header="25vh" :items="userActivityLogs" :fields="userActivityLogFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template #cell(createdDt)="data">
                                                    <div class="text-nowrap">
                                                        {{ data.item.createdDt | formatTimeFromDt}}
                                                    </div>
                                                </template>
                                                <template v-slot:cell(activity)="data">
                                                    <a class="tooltiptext action-link" href="javascript:" @click="viewUserActivityLogDetails(data.item)">{{data.value}}</a>
                                                </template> 

<!--                                                 <template #cell(activity)="data">
                                                    <span
                                                    v-b-tooltip.hover
                                                    :title="data.item.activity">
                                                    {{ data.item.activity }}
                                                  </span>
                                                </template> -->
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No User Activity Log found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>

                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.userActivityCount}} Items</span>

                                            <!-- <b-pagination v-if="userActivityLogs.length!=0" pills v-model="currentActivityPage" :total-rows="totalActivityRows" :per-page="activityPerPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->

                                            </div>
                                            <div class="separator mb-1"></div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="form-row mb-1">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-2">
                                    <div class="form-row">
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-1">
                                                <p class="group-header-title text-left pl-1 mt-1 mb-0">
                                                    Pending Approvals
                                                    <span class="badge badge-dark float-right pointer px-2 mb-0 mr-1" title="Total Task Assigned To Me">{{ workflowRequestCount }}</span>                                                    
                                                </p>
                                                <div class="card-body px-1 py-1">
                                                    <div class="data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="25vh" :items="workflowRequests" :fields="workflowRequestFields" responsive="sm" :filter="workflowRequestfilter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(subject)="data">
                                                                <a href="javascript:" class="action-link" :title="data.value" @click="viewLeaveDetailPopup(data.item)">{{ data.value }}</a>
                                                            </template>
                                                            <template v-slot:cell(initiatedBy)="data">
                                                                <a href="javascript:" class="action-link" :title="data.value">{{ data.value }}</a>
                                                            </template>
                                                            
                                                            <template v-slot:cell(functionality)="data">
                                                                <span :title="data.item.functionality" class="action-link">{{data.item.functionality | getAcronym}}
                                                                </span>
                                                            </template>
                                                            
                                                            <template v-slot:cell(actionItemStartDt)="data">
                                                                <div v-if="data.value">{{ data.value }}</div>
                                                                <div v-else class="text-center">NA</div>
                                                            </template>
                                                            <template v-slot:cell(actionItemDueDt)="data">
                                                                <div v-if="data.value">{{ data.value }}</div>
                                                                <div v-else class="text-center">NA</div>
                                                            </template>
                                                            <template v-slot:cell(isApproval)="data">
                                                                <div>
                                                                    <p v-if="data.value == false">
                                                                        <span v-if="data.item.reviewDt">Reviewed</span>
                                                                        <span v-else>Review Pending</span>
                                                                    </p>
                                                                    <p v-else>
                                                                        <span v-if="data.item.reviewDt">Approved</span>
                                                                        <span v-else>Approval Pending</span>
                                                                    </p>
                                                                </div>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">
                                                                <a type="button" class="text-center" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                                </a>    
                                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item addnew-task pb-1 py-1" @click="viewLeaveDetailPopup(data.item)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>View Details</a>
                                                                    <a class="dropdown-item addnew-task pb-1 py-1" @click="showStatusPopup(data.item)" href="javascript:"><i class="fa fa-list-ol pr-2" aria-hidden="true"></i>Status Update</a>
                                                                </div>
                                                            </template>
                                                            <template v-slot:empty="">
                                                                <div class="text-center">No Pending Request found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="">
                                                                <div class="text-center">{{ }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                    <div class="separator mb-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-1">
                                                <p class="group-header-title text-left pl-1 mt-1 mb-0">
                                                    Meeting
                                                    <span class="badge badge-dark float-right pointer px-2 mb-0 mr-1" title="Total Task Assigned To Me">{{ calendarEventCount }}</span>
                                                    <span class="dataTables_filter ml-2 mt-1 mb-0">
                                                        <input type="date" v-model="startDate" @change="getUserCalendarEvents()" class="small-input">  
                                                    </span>
                                                    <b-button title="New Meeting" class="float-right mr-2 mb-0 small-button" @click="openCalEventPopup(null, false)" variant="outline-primary" size="sm" ><i class="fa fa-plus mr-1"></i>New</b-button>
                                                </p>
                                                <div class="card-body px-1 py-1">
                                                    <div class="data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="25vh" :items="calendarEvents" :fields="calendarEventFields" responsive="sm" :filter="calendarEventfilter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(title)="data">
                                                                <a :title="data.value" class="tooltiptext action-link" href="#" @click="openCalendarEvent(data.item)">{{data.value}}</a>
                                                            </template>
                                                            <template v-slot:cell(time)="data">
                                                                <div v-if="data.item.allDay">All Day</div>
                                                                <div v-else-if="data.item.eventFrequency==1">
                                                                    <span v-if="data.item.startTime">{{ data.item.startTime | formatTimeFromHM }}</span> - <span v-if="data.item.endTime">{{ data.item.endTime | formatTimeFromHM }}</span>
                                                                </div>
                                                                <div v-else>
                                                                    <span v-if="data.item.start">{{ data.item.start | formatTimeFromDt }}</span> - <span v-if="data.item.end">{{ data.item.end | formatTimeFromDt }}</span>
                                                                </div>
                                                            </template>
                                                            <template v-slot:cell(description)="data">
                                                                <div v-html="getShortString(data.value,20)"></div>
                                                            </template>
                                                            <template v-slot:cell(link)="data">
                                                                <span v-if="data.item.link">
                                                                    <a :href="data.item.link" target="_blank" rel="noopener noreferrer">
                                                                        <i class="fa fa-external-link"></i>
                                                                    </a>
                                                                </span>
                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Meetings found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                    <div class="separator mb-1"></div>
                                                    <!-- <div v-if="timeSheetCount" class="text-right font-weight-bold mb-0 pr-3 py-1">Total Effort : 
                                                        <span class="text-right" text-align=right>{{calculateTotalEffort}}</span>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-1">
                                                <p class="group-header-title text-left pl-1 mt-1 mb-0">New Notification
                                                    <span class="badge badge-dark float-right pointer px-2 mb-0 mr-1" title="Total Task Assigned To Me">{{ userNotificationCount }}</span>
                                                </p>
                                                <div class="card-body px-1 py-1">
                                                    <div class="data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="25vh" :items="userNotifications" :fields="userNotificationFields" responsive="sm" :filter="workflowRequestfilter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(title)="data">
                                                                <a href="javascript:" class="action-link" :title="data.value" @click="viewNotificationDetails(data.item)">{{ data.value }}</a>
                                                            </template>
                                                            <template v-slot:cell(isRead)="data">
                                                                <span v-if="data.item.isRead">
                                                                    <i class="fa fa-check text-success ml-1" title="Read"></i>
                                                                </span>
                                                            </template>
                                                            <template v-slot:empty="">
                                                                <div class="text-center">No Notifications found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="">
                                                                <div class="text-center">{{ }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                    <div class="separator mb-1"></div>
                                                </div>
                                            </div>
                                        </div>          
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-row mb-1">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body p-2">
                                    <div class="form-row">
                                        <div class="col-md-6 col-xl-6">
                                            <div class="card p-1">
                                                <p class="group-header-title text-left pl-1 mt-1 mb-0">Task List
                                                    <span class="badge badge-dark float-right pointer px-2 mb-0 mr-1" title="Total Task Assigned To Me">{{ taskAssignedToMeCount }}</span>
                                                    <span class="dataTables_filter ml-2 mt-1 mb-0">
                                                        <!-- <label> -->
                                                            <input type="search" v-model="filter" class="d-inline-block form-control small-input" placeholder="Search...">
                                                        <!-- </label> -->
                                                    </span>
                                                    <b-button class="float-right mr-2 small-button" v-bind:class="{disabled: !elements.isDisable }" @click="quickTask(1)" variant="outline-primary" size="sm" ><i class="fa fa-plus"></i> New Task</b-button>
                                                </p>
                                                <div class="card-body px-1 py-1">
                                                    <div class="data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="35vh" :items="taskAssignedToMe" :fields="myTaskFields" responsive="sm" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                            <template v-slot:cell(taskId)="data">
                                                                <div class="text-center">{{ data.item.taskId }}</div>
                                                            </template>    
                                                            <template v-slot:cell(title)="data">
                                                                <a class="tooltiptext action-link" href="javascript:" :title="data.value" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                            </template>
                                                            <template #cell(estimatedEndDt)="data">
                                                                <span class="subtle float-left" :class="(data.item.status !== 'Closed' && data.item.status !== 'Cancelled') ? dueDateStyling(data.item.estimatedEndDt) : ''" title="Due Date">{{ data.item.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                                            </template>
                                                            <template v-slot:cell(assignedTo)="data">
                                                                <span :title="data.item.assignedBy" class="action-link">{{ data.item.assignedBy | getAcronym }}</span><i class="fa fa-arrow-circle-right px-1" aria-hidden="true"></i> 
                                                                <span :title="data.item.assignedTo" class="action-link">{{ data.item.assignedTo | getAcronym }}</span>
                                                            </template>    
                                                                                                  
                                                            <template v-slot:cell(status)="data">
                                                                <span v-if="data.item.status == 'To Do'" class="badge badge-pill bg-light mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill bg-info mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-success mb-1">{{data.value}}
                                                                </span>      
                                                                <span v-else-if="data.item.status == 'Verified'" class="badge badge-pill badge-primary mb-1">{{data.value}}
                                                                </span>                                               
                                                                <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-dark mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                                                <span v-else>{{data.value}}</span>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">

                                                                <a type="button" class="text-center" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                                </a>    
                                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item addnew-tassk pb-1 py-1" @click="viewItemDetails(data.item)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                                    <a v-if="data.item.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click="showEdit(data.item)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                                    <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="showTaskStatusPopup(data.item, false)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                                    <a v-if="data.item.assignedById == userId || data.item.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(data.item)" href="javascript:"><i class="fa fa-clock-o pr-2 mt-1 mb-0" aria-hidden="true"></i>Timesheet</a>
                                                                    <a v-if="data.item.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(data.item.id)" v-b-modal.deleteTask href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                                    <a v-if="data.item.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveTask(data.item)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                                </div>

                                                            </template>                                                       
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Task found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                    <div class="separator mb-1"></div>
                                                    <div v-for="status in userAllTaskByStatusData" class="text-left font-weight-bold mb-0 pr-1 text-center float-left">
                                                        <div class="card b-radius-5">
                                                            <div class="card-body px-2 py-1">
                                                                <div class="form-group mb-0">
                                                                    <label class="caption-title my-auto" for="title">{{ status.status }} 
                                                                        <b-badge class="ml-1" variant="primary" title="Tasks">{{status.taskCount}}</b-badge>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>          
                                        <div class="col-md-6 col-xl-6">
                                            <div class="card p-1">
                                                <p class="group-header-title text-left pl-1 mt-1 mb-0">
                                                    Time Sheet
                                                    <span class="badge badge-dark float-right pointer px-2 mb-0 mr-1" title="Total Task Assigned To Me">{{ timeSheetCount }}</span>
                                                    <span class="dataTables_filter ml-2 mt-1 mb-0">
                                                        <input type="date" v-model="startDate" @change="getUserTimeSheet()" class="small-input">  
                                                    </span>
                                                    <b-button class="float-right mr-2 small-button" v-bind:class="{disabled: !elements.isDisable }" @click="quickTimeSheet()" variant="outline-primary" size="sm" ><i class="fa fa-plus"></i> New Time Sheet</b-button>
                                                </p>
                                                <div class="card-body px-1 py-1">
                                                    <div class="data-tables-hide-filter">
                                                        <b-table outlined show-empty small head-variant=:primary sticky-header="35vh" :items="myTimeSheets" :fields="timeSheetFields" responsive="sm" :filter="timeSheetFilter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>      
                                                            <template v-slot:cell(title)="data">
                                                                <a class="tooltiptext action-link" href="#" :title="data.value" @click="viewTimeSheetDetails(data.item)">{{data.value}}</a>
                                                            </template>
                                                            <template v-slot:cell(startDate)="data">{{data.item.startDate | formatShortMonthDay }}, {{data.item.startDate | formatTimeFromDt }} - {{data.item.endDate | formatTimeFromDt}}
                                                            </template>                                         
                                                            <template v-slot:cell(workHour)="data">
                                                                {{ effortHoursMinutes(data.item.workDay, data.item.workHour,data.item.workMinute) }}
                                                            </template>
                                                            <template v-slot:cell(status)="data">
                                                                <span v-if="data.item.status == 'To Do'" class="badge badge-pill bg-light mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill bg-info mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-success mb-1">{{data.value}}
                                                                </span>      
                                                                <span v-else-if="data.item.status == 'Verified'" class="badge badge-pill badge-primary mb-1">{{data.value}}
                                                                </span>                                               
                                                                <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-dark mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}
                                                                </span>
                                                                <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                                                <span v-else>{{data.value}}</span>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">
                                                                <a type="button" class="text-center" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fa fa-ellipsis-v p-1"></i>
                                                                </a>    
                                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                                    <a class="dropdown-item addnew-task pb-1 py-1" @click="viewTimeSheetDetails(data.item)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Time Sheet Details</a>

                                                                    <a v-if="data.item.preparedById == userId" class="dropdown-item addnew-task py-1" @click="showTimeSheetEdit(data.item)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Time Sheet</a>
                                                                    <a class="dropdown-item addnew-task py-1" @click="deleteTimeSheet(data.item.id)" v-b-modal.myDBDeleteTimeSheet href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Time Sheet</a>
                                                                </div>

                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Time Sheets found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                    <div class="separator mb-1"></div>
                                                    <div v-if="timeSheetCount" class="text-right font-weight-bold mb-0 pr-3 py-1">Total Effort : 
                                                        <span class="text-right" text-align=right>{{calculateTotalEffort}}</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner/>
                </div>
            </template>
        </b-overlay>
        <AddCalEventPopup v-if="showDialog" ref="addCalEventRef" @refreshData="getUserCalendarEvents" :calendarEventData="calendarEvent" @closeDialog="closeDialog" />
        <AddTaskComponent ref="addTaskRef" :elements="elements" @refresh-task="getUserTask()" />
        <DeleteComponent id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />
        <DeleteComponent id="myDBDeleteTimeSheet" :onYes="onDeleteTimeSheetYes" :returnParams="deleteTimeSheetParam" title="Delete Confirmation" message="Are you sure to delete Time Sheet" />
        <LeaveTimeDetailPopup  id="mydbLeaveTimeDetailPopup" ref="leaveTimeDetailPopupRef" :attachmentData="attachmentData" />
        <LeaveTimePopup v-if="showDialog" id="mydbLeaveTimePopup" ref="leaveTimePopupRef" @refresh-leavetime="getRequestList" :leaveTypeCode="leaveTypeCode" :isWorkflowInitiator="isWorkflowInitiator" :hasWorkflowConfig="hasWorkflowConfig" :humanResourceData="humanResourceDetails" @closeDialog="showDialog = false" />
        <MeetingPrepartionDetailPopup v-if="showDialog" ref="meetingPrepartionDetailPopupRef" @closeDialog="closeDialog" />
        <NotificationDetailPopup v-if="showDialog" ref="notificationDetailPopupRef" :detailViewData="detailViewData" @closeDialog="closeDialog" />
        <QuickTaskPopup id="mydbQuickTaskPopup" ref="ermAssignmentsRef" :projectTypeId="this.projectTypeId" @refresh-task="getUserTask()"/>
        <QuickTimeSheetPopup id="myDBQuickTimeSheetPopup" ref="timeSheetRef" :projectTypeId="this.projectTypeId" @refresh-timesheet="getUserTimeSheet()"/>
        <StatusTrackerPopup v-if="showDialog" ref="statusTrackerPopupRef" :statusUpdate="statusUpdate" @closeDialog="closeDialog" @updateParent="getRequestList" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :attachmentData="attachmentData" :referenceDetailId="taskPopupId" @closeDialog="closeDialog" />
        <TaskFinancialPopup :key="taskFinancialPopupKey" ref="taskFinancialPopupRef" id="task-financial-popup" :taskFinancialData="taskFinancialData" @refreshData="" @closeDialog="closeDialog" />        
        <TaskStatusUpdatePopup v-if="showDialog" ref="taskSatatusUpdatePopupRef" :statusUpdate="statusUpdate" @updateParent="getUserTask();addEmployeeAttendanceTimeline(4)" @closeDialog="closeDialog" />
        <TaskTimeSheetPopup ref="timeSheetPopupRef" :taskData="timeSheetTaskData"  @closeDialog="closeTimeSheetDialog" @refresh-timesheet="getUserTimeSheet()"/>
        <TimesheetDetailPopup v-if="showDialog" ref="timesheetDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="timesheetPopupId" @closeDialog="closeDialog" />
        <UserActivityLogDetailPopup v-if="showDialog" ref="userActivityLogDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />

        
    </div>
</template>
<script>

import iziToast from 'izitoast';
import moment from 'moment';

import AddCalEventPopup from '@/components/popup/action/AddCalendarEventPopup.vue';
import AddTaskComponent from '@/components/common/AddTaskComponent.vue';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue';
import LeaveTimePopup from '@/components/popup/action/LeaveTimePopup.vue';
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import NotificationDetailPopup from '@/components/popup/itemdetail/NotificationDetailPopup.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import QuickTimeSheetPopup from '@/components/popup/action/QuickTimeSheetPopup.vue';
import StatusTrackerPopup from '@/components/popup/action/StatusTrackerPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import TaskFinancialPopup from '@/components/popup/action/TaskFinancialPopup.vue';
import TaskStatusUpdatePopup from '@/components/popup/action/TaskStatusUpdatePopup.vue';
import TaskTimeSheetPopup from '@/components/popup/action/TaskTimeSheetPopup.vue';
import TimesheetDetailPopup from '@/components/popup/itemdetail/TimesheetDetailPopup.vue';
import UserActivityLogDetailPopup from '@/components/popup/itemdetail/UserActivityLogDetailPopup.vue';

import { EventBus } from '@/main';

export default {
    name: "App",
    components: {
        AddCalEventPopup,
        AddTaskComponent,
        LeaveTimeDetailPopup,
        LeaveTimePopup,
        MeetingPrepartionDetailPopup,
        NotificationDetailPopup,
        QuickTaskPopup,
        QuickTimeSheetPopup,
        StatusTrackerPopup,
        TaskDetailPopup,
        TaskFinancialPopup,
        TaskStatusUpdatePopup,
        TaskTimeSheetPopup,
        TimesheetDetailPopup,
        UserActivityLogDetailPopup,
    },   
    data() {
        return {
            showOverlay: false,
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            isPrimaryRole: localStorage.getItem("isPrimaryRole"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            userTypeId: localStorage.getItem("userTypeId"),

            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),

            elements: {
                clientTaskPage: '',
                editMode: false,
                viewMode: false,
                viewOnlyComponent: false,
                filterMode: false,
                taskStatusUpdate: false,
                isHide: true,
                isDisable: true
            },

            isWorkflowInitiator: 0,
            hasWorkflowConfig: 0,
            leaveTypeCode: 'LVE',

            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},
            attachmentData: {},

            timelineTaskTitle: '',
            empWorkState:'',
            empWorkStatus: {
                empTimelineStateEnum: 0,
                empTimelineDt: '',
                task: '',
            },

            entity: 'task',

            isArchive: false,
            showDialog: false,

            taskPopupId: 0,
            timesheetPopupId: 0,
            notificationPopupId: 0,
            taskFinancialData: {
                vhextAccountId: '',
                projectId: '',
                taskId: '',
            },
            taskFinancialPopupKey: 0,

            statusUpdate: {
                id: '',
                taskId: '',
                title: '',
                entityRecordMasterId: '',
                progressPercentage: 0,
                statusId: '',
                status: '',
                activityId: '',
                startDateValue: '',
                endDateValue: '',
                startTimeValue: '',
                endTimeValue: '',
                popupTitle: '',
            },

            humanResourceDetails: {
                id: '',
                vhextAccountId: '',
                entity: '',
                userId: '',
                userName: '',
                loginName: '',
                userType: '',
                active: '',
                humanResourceId: '',                
                humanResourceTypeId: '',
                subContractId: '',
                name: '',
                photo: '',
                position:'',
                gender: '',
                dateOfBirth:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate:'',
                workShiftId: '',
                jobCategoryId: '',
                jobCategory: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                empId: '',
                employeeId: '',
                employeeCode: '',
                reportingToId: '',
                reportingTo: '',
                employmentChangeById: '',
                employmentChangeBy: '',
                wefDate: '',
                endDate: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                hourlySalary: null,
                employeeStatusId: '',
                employeeStatus: '',
                active: true,
                isEmployee: false,

            },
            task: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'task',
                title: '',
                isArchive: false,
                planId: '',
                requirementId: '',
                requirement: '',
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                progressPercentage: 0,
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasChecklist: '',
                entityId: '',
                refErmId: '',
                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checkListTag: '',
                response: '',
                checklistList: [],
                checklistItemCount: '',
            },

            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                eventTypeId: '',
                calendarTypeId: '',
                calendarCategoryId: '',
                eventParticipantTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                participantId: localStorage.getItem("userId"),
                entityRecordMasterId: '',
                eventParticipant: [],
                selectedDate: '',
                isMOMRecorder: false,
            },
            events: [],
            employeeAttendanceDetails: {},

            userAllTaskByStatusData: {},
            userTaskByStatusData: {},
            userTaskAssignedByStatusData: {},
            attachmentData: {},

            workflowRequestCount: 0,
            taskCount: 0,
            taskAssignedToMeCount: 0,
            taskAssignedByMeCount: 0,
            calendarEventCount: 0,
            userNotificationCount: 0,

            timeSheetCount: 0,

            userActivityCount: 0,
            totalActivityRows: 1,
            currentActivityPage: 1,
            activityPerPage: 10,


            timeSheetTaskData: null,
            calendarEventData: null,

            userActivityLogs: [],
            userActivityLogFields: [
                // { key:'index',label: 'SN',thClass:'position-relative' },
                { key: 'createdDt', label: 'Timeline', thStyle: "width: 8px", sortable: true, sortDirection: 'desc'},

                // { key: 'userActivityLogId', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'functionalEntity', label: 'Functional Entity', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity',tdClass:'table-cw-100', sortable: true, sortDirection: 'desc' },
                // { key: 'activityLogType', label: 'Type', sortable: true, sortDirection: 'desc' },
                // { key: 'activityBy', label: 'Activity By', sortable: true, sortDirection: 'desc' },
                // { key: 'createdDt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: '',thClass:'position-relative' },
            ],
            

            workflowRequests: [],

            workflowRequestFields: [
                { key:'index',label: 'SN',thClass:'position-relative' },
                // { key: 'actionItemId', label: 'Id', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'subject', label: 'Title',tdClass:'table-cw-150', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'functionality', label: 'Type', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'initiatedBy', label: 'Request By', tdClass:'table-cw-100',sortable: true, sortDirection: 'desc', class: '' },
                { key: 'createdDt', label: 'Date',formatter: 'formatDateTimeYearCheck', sortable: true, sortDirection: 'desc'},
                // { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: '',thClass:'position-relative' },
            ],

            myTaskList: [],
            myTaskFields: [
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key:'title',label:'Task Title',tdClass:'table-cw-200',sortable: true,sortDirection:'desc' },
                // { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Description', tdClass: 'table-cw-400', sortDirection: 'desc' },
                // { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'Due Date', formatter: 'formatDateTimeYearCheck', sortable: true, sortDirection: 'desc' },
                // { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'assignedTo', label: 'Assigned To', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc', class: 'text-center' },
                { key: 'actions', label: '',thClass:'position-relative'}
            ],
            myTimeSheets: [],
            timeSheetFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px",thClass:'position-relative' },            
                // { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title',tdClass:'table-cw-200',sortable: true, sortDirection: 'desc' },
                // { key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                // { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Period', formatter: "formatTimeFromDt", sortable: true, sortDirection: 'desc' },
                // { key: 'endDate', label: 'End Time', formatter: "formatTimeFromDt", sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Effort (Hrs)', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                // { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: '',thClass:'position-relative' }
            ],
            calendarEvents: [],
            calendarEventFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px",thClass:'position-relative' },
                // { key: 'calendarEventId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Meeting Title',tdClass:'table-cw-200', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Agenda' },
                // { key: 'start',label: 'Date', sortable: true,sortDirection: 'desc' },
                // { key: 'end',label: 'End Date', sortable: true,sortDirection: 'desc' },
                { key: 'time',label: 'Time',sortable: true,},
                // { key: 'daysOfWeek', label: 'Repeat', tdClass: 'table-cw-100' },
                { key: 'link', label: 'Link', tdClass: 'table-cw-100' },
                // { key: 'actions', label: 'Actions' }
            ],
            userNotifications: [],
            userNotificationFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px",thClass:'position-relative' },
                // { key: 'calendarEventId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Notification Title',tdClass:'table-cw-200', sortable: true, sortDirection: 'desc' },
                { key: 'isRead',label: 'Read',sortable: true, sortDirection: 'desc'},
                { key: 'createdDt',label: 'Date',formatter: 'formatDateTimeYearCheck',sortable: true,},
                // { key: 'daysOfWeek', label: 'Repeat', tdClass: 'table-cw-100' },
                // { key: 'createdBy', label: 'Created By', },
                // { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            // perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filter2: null,
            workflowRequestfilter: null,
            calendarEventfilter: null,
            timeSheetFilter: null,
            filterOn: [],

            deleteParam: {
                id: null
            },
            deleteTimeSheetParam: {
                id: null
            },

        }
    },
    computed: {
        taskAssignedToMe() {
            let filteredTask = this.myTaskList.filter(item => item.assignedById == this.userId || (item.assignedToId == this.userId && item.statusLookupId != 5 ));

            this.taskAssignedToMeCount = filteredTask.length;

            return filteredTask;
        },
        taskAssignedByMe() {
            let filteredTask = this.myTaskList.filter(item => item.assignedById == this.userId);

            this.taskAssignedByMeCount = filteredTask.length;

            return filteredTask;
        },
        calculateTotalEffort() {
            let totalWorkDay = this.myTimeSheets.reduce((sum, item) => sum + item.workDay, 0);
            let totalWorkHour = this.myTimeSheets.reduce((sum, item) => sum + item.workHour, 0);
            let totalWorkMinute = this.myTimeSheets.reduce((sum, item) => sum + item.workMinute, 0);

            if(totalWorkMinute > 59) {
                let hours = Math.floor(totalWorkMinute / 60); // Convert to hours
                let minutes = totalWorkMinute % 60; // Remaining minutes after converting to hours
                totalWorkHour = totalWorkHour + hours;
                totalWorkMinute = minutes;
            }
            
            return this.effortHoursMinutes(totalWorkDay,totalWorkHour,totalWorkMinute);
        },

    },
    created() {
        EventBus.$on('onEmpAttTimelineUpdate', () => {
            this.getAttendanceData()
        });
    },
    beforeDestroy() {
        EventBus.$off('onEmpAttTimelineUpdate');
    },

    mounted() {
        this.getDashboardData();
        this.getEmpWorkStatus();
        // this.getUserTimeSheet();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                isArchive: false,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/mydata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.humanResourceDetails = result.humanResourceView;

                    this.employeeAttendanceDetails = result.employeeAttendance;

                    console.log("data",this.employeeAttendanceDetails);

                    this.userActivityLogs = result.userActivityLogList;
                    this.workflowRequests = result.workflowRequestList;

                    this.myTaskList = result.userTaskList;
                    this.myTimeSheets = result.userTimeSheetList;
                    this.calendarEvents = result.userCalendarEvents;
                    this.userNotifications = result.userNotificationList;

                    this.userAllTaskByStatusData = result.userAllTaskByStatus;
                    this.userTaskByStatusData = result.userTaskByStatus;
                    this.userTaskAssignedByStatusData = result.userTaskAssignedByStatus;

                    this.userActivityCount = this.userActivityLogs.length;
                    this.workflowRequestCount = this.workflowRequests.length;
                    this.taskCount = this.myTaskList.length;
                    this.timeSheetCount = this.myTimeSheets.length;
                    this.calendarEventCount = this.calendarEvents.length;
                    this.userNotificationCount = this.userNotifications.length;

                    this.updateUnreadNotificationCount();
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getUserActivity: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                startDt: this.startDate,
                endDt: this.startDate,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/useractivitylog/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.userActivityLogs = result.userActivityLogList;
                    this.userActivityCount = this.userActivityLogs.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },

        getAttendanceData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                workingDate: this.getDateValue(new Date()),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/dailyemployeeattendance/view', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.employeeAttendanceDetails = result.employeeAttendance;
                    
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },

        getUserTask: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                isArchive: false,
            }
            console.log("TSK",data); 
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/usertask/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.myTaskList = result.userTaskList;
                    this.taskCount = this.myTaskList.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getUserTimeSheet: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                preparedById: localStorage.getItem("userId"),
                isArchive: false,
                startDate: this.startDate, 
                endDate: this.startDate,
            }
            console.error(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/usertimesheetbydaterange/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;

                    this.myTimeSheets = result.userTimeSheetList;
                    this.timeSheetCount = this.myTimeSheets.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.error(error);
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getRequestList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: localStorage.getItem("userId"),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowrequest/list', data)
                .then((response) => {
                    let result = response.data;
                    this.workflowRequests = result.workflowRequestList;

                    this.workflowRequestCount = this.workflowRequests.length;

                    console.log(result);
                })

        },

        getUserCalendarEvents: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
                createdById: localStorage.getItem("userId"),
                startDate: this.startDate, 
                endDate: this.startDate,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/usercalendarevents/bydate', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvents = result.userCalendarEvents;
                    this.calendarEventCount = this.calendarEvents.length;
                    // console.log(this.calendarEvents);
                })
        },
        getNotifications: function () {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/usernotification/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.userNotifications = result.userNotificationList;
                    console.log(this.userNotifications);
                    this.userNotificationCount = this.userNotifications.length;
                    this.updateUnreadNotificationCount();
                })
        },
        getEmpWorkStatus: function() {
            let data = {
                userId: this.userId,
            }
            console.log(data)
            axios.get(this.$store.getters.getAPIBasePath + '/resource/empworkstatus/view/'  + this.userId)
                .then((response) => {
                    let result = response.data;
                    this.empWorkStatus = result.empWorkStatus;
                    this.empWorkState = this.empWorkStatus?.empTimelineStateEnum ? this.empWorkStatus.empTimelineStateEnum : 0;
                    if (this.empWorkStatus) {
                        this.$nextTick(() => {
                            this.timelineTaskTitle = this.empWorkStatus.task;
                        })
                    }
                })
        },
        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        memorizeTask: function(itemData) {
            let data = {
                'id': itemData.id,
                'entity': 'task',
                'columnName': 'is_memorized',
                'stateValue': itemData.isMemorized ? false : true,
            }
            var confirmMessage = '';
            if (itemData.isMemorized) {
                confirmMessage = "Are you sure to remove the task from memory?"
            } else {
                confirmMessage = "Are you sure to add the task to memory?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "OK") {
                                iziToast.success({
                                    title: 'Updated',
                                    position: 'topCenter'
                                });
                                this.getUserTask();
                                this.taskMemoryPopupKey++;
                            }
                        })
                        .catch((error) => {
                            this.showOverlay = false;
                        })
                }
            })
        },
        archiveTask: function(item) {
            let data = {
                id: item.id,
                entity: this.entity,
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            console.log(data)
            this.showOverlay=true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay=false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.getUserTask();
                    }
                })
                .catch(error => {
                    this.showOverlay=false;
                });
        },

        deleteTask: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/task/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserTask();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteTimeSheet: function(id) {
            // alert(id);
            this.deleteTimeSheetParam.id = id;
            console.log(this.deleteTimeSheetParam.id);
        },
        onDeleteTimeSheetYes: function() {
            console.log("onDeleteTimeSheetYes called with ID:", this.deleteTimeSheetParam.id); 
            axios.delete(this.$store.getters.getAPIBasePath + '/task/timesheet/delete/' + this.deleteTimeSheetParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserTimeSheet();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        quickTask: function(statusId) {
            this.$bvModal.show('mydbQuickTaskPopup');
            this.$nextTick(() => {
                this.task.statusId = statusId;
                // this.task.entityRecordMasterId = this.entityRecordMasterId;
                // this.task.projectId = this.projectId;
                // this.task.assignedToId = this.filteredUserId ? this.filteredUserId : '';
                this.$refs.ermAssignmentsRef.onRefDataChange('Quick Task');
            })
        },
        quickTimeSheet: function() {
            this.$bvModal.show('myDBQuickTimeSheetPopup');
            this.$nextTick(() => {
                // this.task.statusId = statusId;
                // this.task.entityRecordMasterId = this.entityRecordMasterId;
                // this.task.projectId = this.projectId;
                // this.task.assignedToId = this.filteredUserId ? this.filteredUserId : '';
                this.$refs.timeSheetRef.setInitData('Quick Time Sheet');
            })
        },
        showLeaveTimePopup(leaveTypeCode) {
            this.showDialog = true;
            this.leaveTypeCode = leaveTypeCode;
            this.$nextTick(()=> {
                this.$bvModal.show('mydbLeaveTimePopup');
            })
        },
        openCalEventPopup: function(event, flag) {
            console.log(event, flag)
            if (flag == true) {
                let calendarProps = event.extendedProps;
                // this.$refs.addCalEventRef.getParticipantList(calendarProps.eventId);
                this.calendarEvent.id = calendarProps.eventId;
                this.calendarEvent.title = event.title;
                this.calendarEvent.isAllDayEvent = event.allDay;
                this.calendarEvent.selectedDate = event._instance.range.start;

                if (event._def.recurringDef) {
                    let recurData = event._def.recurringDef.typeData;
                    console.log(recurData)
                    this.calendarEvent.daysToRepeat = [];
                    recurData.daysOfWeek.forEach((day) => {
                        this.calendarEvent.daysToRepeat.push(this.days[day]);
                    })
                    this.calendarEvent.eventStartDate = this.getDateValue(recurData.startRecur);
                    this.calendarEvent.eventEndDate = this.getDateValue(recurData.endRecur);
                    this.events.forEach((event)=> {
                        if (event.eventId == calendarProps.eventId) {
                            console.log(event.startTime, event.endTime)
                            this.calendarEvent.eventStartTime = event.startTime ? event.startTime.toString().substr(0, 5) : null;
                            this.calendarEvent.eventEndTime = event.endTime ? event.endTime.toString().substr(0, 5) : null;                        
                        }
                    });
                } else {
                    this.calendarEvent.daysToRepeat = [];
                    this.calendarEvent.eventStartDate = event.startStr;
                    this.calendarEvent.eventEndDate = event.endStr;
                    this.calendarEvent.eventStartTime = this.getTimeValue(event.startStr);
                    this.calendarEvent.eventEndTime = this.getTimeValue(event.endStr);
                }
                this.calendarEvent.eventDate = calendarProps.eventDate;
                this.calendarEvent.eventFrequency = calendarProps.eventFrequency ? calendarProps.eventFrequency : 0;
                this.calendarEvent.type = calendarProps.type;
                this.calendarEvent.link = calendarProps.link;
                this.calendarEvent.calendarTypeId = calendarProps.calendarTypeId;
                this.calendarEvent.calendarCategoryId = calendarProps.calendarCategoryId;
                this.calendarEvent.eventTypeId = calendarProps.eventTypeId;
                this.calendarEvent.description = calendarProps.description;
                this.calendarEvent.status = calendarProps.status;
                this.calendarEvent.statusId = calendarProps.statusId;
                this.calendarEvent.projectId = calendarProps.projectId;
                this.calendarEvent.vhextAccountId = calendarProps.vhextAccountId;
                this.calendarEvent.entityRecordMasterId = calendarProps.entityRecordMasterId;
                this.calendarEvent.createdBy = calendarProps.createdBy;
                this.calendarEvent.hostedBy = calendarProps.hostedBy;
                this.calendarEvent.createdById = calendarProps.createdById;
                this.calendarEvent.hostedById = calendarProps.hostedById;
                this.calendarEvent.eventId = calendarProps.eventId;
                this.calendarEvent.location = calendarProps.location;
                this.calendarEvent.eventParticipantTypeId = calendarProps.eventParticipantTypeId;
            } else {
                this.calendarEvent.title = '';
                this.calendarEvent.isAllDayEvent = false;
                this.calendarEvent.daysOfWeek = [];
                this.calendarEvent.daysToRepeat = [];
                this.calendarEvent.eventDate = this.getDateValue(new Date());
                this.calendarEvent.startDate = '';
                this.calendarEvent.endDate = '';
                this.calendarEvent.eventStartDate = this.getDateValue(new Date());
                this.calendarEvent.eventEndDate = this.getDateValue(new Date());
                this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
                this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
                this.calendarEvent.entityRecordMasterId = this.entityRecordMasterId;
                this.calendarEvent.vhextAccountId = this.vhextAccountId;
                this.calendarEvent.projectId = this.projectId;
                this.calendarEvent.statusId = '';
                this.calendarEvent.hostedById = '';
                this.calendarEvent.hostedBy = '';
                this.calendarEvent.createdById = this.userId;
                this.calendarEvent.createdBy = '';
                this.calendarEvent.link = '';
                this.calendarEvent.location = '';
                this.calendarEvent.description = '';
                this.calendarEvent.calendarTypeId = '';
                this.calendarEvent.calendarCategoryId = '';
                this.calendarEvent.eventTypeId = '';
                this.calendarEvent.eventFrequency = 0;
                this.calendarEvent.eventParticipantList = '';
                this.calendarEvent.eventParticipant = [];
                this.calendarEvent.id = '';
            }
            this.calendarEvent.editMode = false;
            this.calendarEvent.showMOM = flag;
            this.calendarEvent.viewMode = flag;
            this.showDialog = true;
            console.log(this.calendarEvent)
            if (this.calendarEvent.viewMode == true && !this.isHostOrCreator && this.calendarEvent.eventParticipantTypeId != 2) {
                let eventId = moment(String(this.calendarEvent.selectedDate)).format('YYYYMMDD');
                this.$nextTick(()=> {
                    this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.calendarEvent.id, 'calendar_event', this.calendarEvent.selectedDate);
                })
            }
            else if (this.calendarEvent.viewMode == true && !this.isHostOrCreator && this.calendarEvent.eventParticipantTypeId == 2) {
                this.calendarEvent.isMOMRecorder = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('addCalEventPopup');
                })
            }
            else {
                this.$nextTick(()=> {
                    this.$bvModal.show('addCalEventPopup');
                })
            }
        },
        viewUserActivityLogDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = "user_activity_log";
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.userActivityLogDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },

        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.taskPopupId = data.id;
            this.attachmentData.refEntity = "this.entity";
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.taskPopupId,'time_sheet');
            })
        },
        viewTimeSheetDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.timesheetPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.timesheetDetailPopupRef.getDetails(this.timesheetPopupId, null);
            })
        },
        viewNotificationDetails: function(notificationData) {
            console.log(notificationData);
            this.detailViewData = notificationData;
            this.showDialog= true;
            if(!notificationData.isRead){
                this.updateUserNotificationRead(notificationData);
            }
            this.$nextTick(()=> {
                this.$refs.notificationDetailPopupRef.getDetails(notificationData.id);
            })
        },
        updateUserNotificationRead: function(notificationData) {
            let data = {
               vhextAccountId: this.vhextAccountId,
               refRecordId: notificationData.id,
               userId: this.userId,
            }       
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notification/userread/update', data)
                .then((response) => {
                    let result = response.data;
                    notificationData.isRead = true;
                    this.updateUnreadNotificationCount();
                })
        },         


        showEdit: function(data) {
            this.$bvModal.show('mydbQuickTaskPopup');
            this.$nextTick(() => {
                this.$refs.ermAssignmentsRef.showEdit(data);
            })
        },
        showTimeSheetEdit: function(data) {
            this.$bvModal.show('myDBQuickTimeSheetPopup');
            this.$nextTick(() => {
                this.$refs.timeSheetRef.showEdit(data);
            })
        },

        duplicateTask: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.duplicateTask(data);
            });
        },
        showStatusPopup: function(data) {
            console.log(data);
            if (data && data !== null) {
                // this.$refs.statusTrackerPopupRef.onTitleChange();
                this.assignStatusUpdate(data);
                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('statusTrackerPopup');
                })
            }
        },
        assignStatusUpdate: function(data) {
            // this.$refs.statusUpdate.clearData();

            this.statusUpdate.id = '';
            this.statusUpdate.vhextAccountId = data.vhextAccountId;
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.functionalEntityId = data.functionalEntityId;
            this.statusUpdate.functionalEntity = data.functionalEntity;
            this.statusUpdate.refRecordTitle = data.subject;
            this.statusUpdate.refEntity = data.refEntity;
            this.statusUpdate.refRecordId = data.refRecordId;
            this.statusUpdate.statusById = this.userId;
            this.statusUpdate.statusId = data.statusId;
            this.statusUpdate.status = data.status;
            this.statusUpdate.remarks = data.remarks;
            this.statusUpdate.workflowRequestId = data.id;
            this.statusUpdate.workflowActivityId = data.workflowActivityId;
            this.statusUpdate.isWorkflowAutomated = true;
            // this.statusUpdate.workflowRequestId = data.id;
            //call child component method
            console.log(data);
            // this.$refs.statusUpdate.onTitleChange();
            // this.statusTrackerKey++;
        },        
        showTaskStatusPopup: function(data, endTimeline) {
            console.log("STP",data);
            if (data && data !== null) {
                this.statusUpdate.id = data.id;
                this.statusUpdate.taskId = data.taskId;
                this.statusUpdate.title = data.title;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.statusUpdate.activityId = data.activityId;
                this.statusUpdate.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;

                if (endTimeline) {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.empWorkStatus.empTimelineDt ? this.getDateValue(this.empWorkStatus.empTimelineDt) : this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.startTimeValue = this.empWorkStatus.empTimelineDt ? this.getTimeValue(this.empWorkStatus.empTimelineDt) : null;
                    this.statusUpdate.endTimeValue = this.getTimeValue(new Date());
                }
                else {
                    this.statusUpdate.popupTitle = null;
                    this.statusUpdate.startDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endDateValue = this.getDateValue(new Date());
                    this.statusUpdate.endTimeValue = '';
                    this.statusUpdate.startTimeValue = '';
                }

                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('taskSatatusUpdatePopup');
                })
            }
        },
        openTimeSheet: function(data) {
            this.$nextTick(()=> {
                this.timeSheetTaskData = data;
            });
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.timeSheetPopupRef.openDialog();
            })
        },
        openCalendarEvent: function(data) {
            this.$nextTick(()=> {
                this.calendarEventData = data;
            });
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.calendarEventData.id, 'calendar_event', null);
            })
        },
        showTaskFinPopup: function(data) {
            console.log(this.$refs)
            this.taskFinancialData.vhextAccountId = data.vhextAccountId;
            this.taskFinancialData.projectId = data.projectId;
            this.taskFinancialData.taskId = data.id;
            this.taskFinancialPopupKey++;
            this.$nextTick(()=> {
                this.$bvModal.show('task-financial-popup');
            })
        },
        updateUnreadNotificationCount() {
            const unReadNotificationCount = this.userNotifications.filter(item => !item.isRead)?.length ?? 0;
            EventBus.$emit('onNewNotification',unReadNotificationCount);
        },
        viewLeaveDetailPopup: function(data) {
            console.log(data);
            this.attachmentData.refEntity = 'leave_time';
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.leaveTimeDetailPopupRef.getDetails(data.id, 'leave_time');
            })            
            
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
            // this.clearData();
        },
        closeTimeSheetDialog: function() {

            console.log('Time Sheet Closed');
            this.getUserTimeSheet();
            this.showDialog = false;
            // this.clearData();
        },


    }
}
</script>