<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" ref="timeSheetPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Time Sheet" ok-only ok-title="Close" ok-variant="secondary">
            <TaskTimeSheetComponent ref="timeSheetComponent" :taskData="taskData"  :viewTimeSheet="false" />
        </b-modal>
    </div>
</template>
<script>
import TaskTimeSheetComponent from '@/components/common/TaskTimeSheetComponent.vue';
export default {
    components: {
        TaskTimeSheetComponent,
    },
    props: {
        taskData: Object,
        id: { type: String, default: 'timeSheetPopup' },
    },
    data() {
        return {
        }
    },
    methods: {
        openDialog: function() {
            this.$bvModal.show('timeSheetPopup');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>