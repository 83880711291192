<template>
    <div>
        <ProcessPlanMasterList :ermMasterInfo="ermMasterInfo"></ProcessPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProcessPlanMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ProcessPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Process Models",
                entity: 'process_model_master',
                refEntity: 'process_plan',
                tooltip: 'New Process Model',
                redirectURLName: 'processplan',
                newEntityMasterURL: 'pmdmas',
                redirectURL: '/pmdmas',
                actionName: 'Process Plan',
                previewURLName: 'pmdrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>