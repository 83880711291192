<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Reporting Plan</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title pt-0" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                <span v-if="elements.viewMode">View</span> 
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.reportingPlan.title.$error }">
                                                                    <input type="text" maxlength="100" v-model="reportingPlan.title" class="form-control" id="title" placeholder="Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                            <label v-if="$v.reportingPlan.title.$error && !$v.reportingPlan.title.required" class="text-danger">Please enter Activity</label>
                                                        <label v-if="$v.reportingPlan.title.$error && !$v.reportingPlan.title.maxLength" class="text-danger">Activity must not exceed {{$v.reportingPlan.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                         <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="reportingTypeId">Type</label>
                                                                <select v-model="reportingPlan.reportingTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in reportingTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="reportedBy">Reported By</label>
                                                                <input type="text" maxlength="100" v-model="reportingPlan.reportedBy" class="form-control" id="reportedBy" >
                                                            </div>
                                                        </div>                                                    
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="reportedTo">Reported To</label>
                                                                <input type="text" maxlength="100" v-model="reportingPlan.reportedTo" class="form-control" id="reportedTo" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="frequency">Frquency</label>
                                                                <input type="text" maxlength="45" v-model="reportingPlan.frequency" class="form-control" id="frequency" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="communicationChannel">Communication Channel</label>
                                                                <input type="text" maxlength="100" v-model="reportingPlan.communicationChannel" class="form-control" id="communicationChannel" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="weekdays">Weekdays</label>
                                                                <input type="text" maxlength="100" v-model="reportingPlan.weekdays" class="form-control" id="weekdays" >
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label>Description</label>
                                                                <textarea maxlength="1000" v-model="reportingPlan.description" class="form-control" rows="4" required></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewReportingPlan">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="reportingPlan.reportingPlanId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="reportingPlan.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group text-center">
                                                                <label for="entityId">Entity Id</label>
                                                                <input type="text" class="form-control" id="entityId" disabled v-model='reportingPlan.entityId'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="type">Reporting Type</label>
                                                                <input type="text" class="form-control" id="type" disabled v-model='reportingPlan.type'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="reportedTo">Reported By</label>
                                                                <input type="" name="" class="form-control" id="reportedTo" disabled v-model='reportingPlan.reportedTo' >
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="reportedBy">Reported To</label>
                                                                <input type="" name="" class="form-control" id="reportedBy" disabled v-model='reportingPlan.reportedBy' >
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="frequency">Frequency</label>
                                                                <input type="text" class="form-control" id="frequency" disabled v-model='reportingPlan.frequency'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="communicationChannel">Communication Channel</label>
                                                                <input type="" name="" class="form-control" id="communicationChannel" disabled v-model='reportingPlan.communicationChannel'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="weekdays">Week Days</label>
                                                                <input type="" name="" class="form-control" id="weekdays" disabled v-model='reportingPlan.weekdays' rows="4">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="createdDt">Created Date</label>
                                                                <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(reportingPlan.createdDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="lastUpdatedDt">Updated Date</label>
                                                                <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(reportingPlan.lastUpdatedDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label>Description</label>
                                                                <textarea type="" name="" class="form-control" id="description" disabled v-model='reportingPlan.description' rows="4"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="reportingPlans" :fields="reportingPlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template> 
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteReportingPlan(data.item.id)" v-b-modal.deleteReportingPlan><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No ReportingPlan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="reportingPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
<!--                 <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                <label for="type">Type</label>
                                                <b-input-group :prepend="reportingPlan.reportingPlanId" class="mb-2">
                                                            <b-form-input aria-label="Type" disabled v-model="reportingPlan.type"></b-form-input>
                                                </b-input-group>
                                            </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group text-center">
                                                    <label for="status">Status</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{reportingPlan.value}}</span></p>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="title">Title</label>
                                                    <input type="text" class="form-control" id="title" disabled v-model='reportingPlan.title'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="purpose">Purpose</label>
                                                    <input type="text" class="form-control" id="purpose" disabled v-model='reportingPlan.purpose'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="frequency">Frequency</label>
                                                    <input type="text" class="form-control" id="frequency" disabled v-model='reportingPlan.frequency'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="duration">Duration</label>
                                                    <input class="form-control" id="duration" disabled v-model='reportingPlan.duration' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="hostedBy">HostedBy</label>
                                                    <input class="form-control" id="hostedBy" disabled v-model='reportingPlan.hostedBy' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="participants">Participants</label>
                                                    <input type="text" class="form-control" id="participants" disabled v-model='reportingPlan.participants'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="locationLink">locationLink</label>
                                                    <input class="form-control" id="LocationLink" disabled v-model='reportingPlan.locationLink' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="communicationChannel">CommunicationChannel</label>
                                                    <input class="form-control" id="communicationChannel" disabled v-model='reportingPlan.communicationChannel' rows="4"></input>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="weekdays">Weekdays</label>
                                                    <input class="form-control" id="weekdays" disabled v-model='reportingPlan.weekdays' rows="4"></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="budget">Functions</label>
                                            <select id="durationDays" class="form-control">
                                                <option value="">Functions...</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="budget" style="visibility: hidden;">Search</label>
                                            <div class="input-group ">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-text input-group-append input-group-addon">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Reference Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">reportingPlan Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No reportingPlan found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteReportingPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete communication plan?" />
        <ReportingPlanDetailPopup v-if="showDialog" ref="reportingPlanDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import ReportingPlanDetailPopup from '@/components/popup/itemdetail/ReportingPlanDetailPopup.vue';

export default {
    name: 'app',
    components: {
        TypeLookup,
        ReportingPlanDetailPopup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            showOverlay: false,
            showDialog: false,
            notEnabled: true,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            typeEntity: 'reporting_type',

            typeLookupTitle: 'Reporting Plan Type Settings',
            reportingPlan: {
                entity: 'reporting_plan',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,               
                id: '',
                reportingTypeId: '',
                title: '',

                type: '',
                frequency: '',
                duration: '',
                communicationChannel: '',
                weekdays: '',
                createdDt: '',
                lastUpdatedDt: '',
                description:'',
                reportedBy:'',
                reportedTo:''
            },
            detailViewData: {},
            referencePopupId: 0,
            reportingTypes: [],
            users: [],
            //status: [],
            projects: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            reportingPlans: [],
            reportingPlanFields: [
                { key: 'reportingPlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'reportedBy', label: 'Reported By',  sortable: true, sortDirection: 'desc' },
                { key: 'reportedTo', label: 'Reported To',  sortable: true, sortDirection: 'desc' },
                { key: 'frequency', label: 'Ferquency', sortable: true, sortDirection: 'desc' },  
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getReportingPlan();
        this.lookupData();
    },
    validations: {
        reportingPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
           
        }
    },
    methods: {
        getReportingPlan: function() {
            let data = {
                entityRecordMasterId: this.reportingPlan.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/reportingplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.reportingPlans = result.reportingPlanList;
                    this.totalRows = this.reportingPlans.length;
                })
        },
       lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.reportingPlan.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/reportingplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.reportingTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                    //this.status = result.statusLookup;
                    console.log(result);     
                })

        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            this.$v.reportingPlan.$touch();
            if (!this.$v.reportingPlan.$invalid) {
                if (this.elements.editMode) {
                    this.updateReportingPlan();
                } else {
                    this.saveReportingPlan();
                }
            }
        },
        saveReportingPlan: function() {
            this.showOverlay = true;
            console.log(this.reportingPlan);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/reportingplan/add', this.reportingPlan)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReportingPlan();
                        this.clearData();
                    }
                })
        },
        updateReportingPlan: function() {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/communication/reportingplan/update', this.reportingPlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getReportingPlan();
                            this.clearData();
                        }
                    })
        },
        deleteReportingPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/reportingplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReportingPlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.reportingPlanDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.reportingPlan.reportingPlanId = '';
            this.reportingPlan.title = '';
            this.reportingPlan.reportingTypeId = '';
            this.reportingPlan.frequency = '';
            this.reportingPlan.communicationChannel = '';
            this.reportingPlan.description = '';
            this.reportingPlan.reportedBy = '';
            this.reportingPlan.reportedTo = '';
            this.reportingPlan.weekdays = '';
            this.reportingPlan.id = '';
            this.$v.reportingPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.reportingPlan.reportingPlanId = data.reportingPlanId;
            this.reportingPlan.title = data.title;
            this.reportingPlan.reportingTypeId = data.reportingTypeId;
            this.reportingPlan.purpose = data.purpose;
            this.reportingPlan.frequency = data.frequency;
            this.reportingPlan.reportedBy = data.reportedBy;
            this.reportingPlan.reportedTo = data.reportedTo;
            this.reportingPlan.description = data.description;
            this.reportingPlan.communicationChannel = data.communicationChannel;
            this.reportingPlan.weekdays = data.weekdays;
            this.reportingPlan.id = data.id;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.showDetail = true;
            this.reportingPlan.reportingPlanId = data.reportingPlanId;
            this.reportingPlan.title = data.title;
            this.reportingPlan.reportingTypeId = data.reportingTypeId;
            this.reportingPlan.purpose = data.purpose;
            this.reportingPlan.frequency = data.frequency;
            this.reportingPlan.reportedBy = data.reportedBy;
            this.reportingPlan.reportedTo = data.reportedTo;
            this.reportingPlan.description = data.description;
            this.reportingPlan.communicationChannel = data.communicationChannel;
            this.reportingPlan.weekdays = data.weekdays;
            this.meetingPlan.createdDt = data.createdDt;
            this.meetingPlan.lastUpdatedDt = data.lastUpdatedDt;
        }

    }
}
</script>