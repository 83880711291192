<template>
    <div>
        <SystemRequirementSpecMasterList :ermMasterInfo="ermMasterInfo"></SystemRequirementSpecMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import SystemRequirementSpecMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        SystemRequirementSpecMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "System Requirement Spec",
                entity: 'system_requirement_spec_master',
                refEntity:'requirement_spec',
                tooltip: 'New Requirement Spec',
                redirectURLName: 'reqwbs',
                newEntityMasterURL: 'srsmas',
                redirectURL: '/srsmas',
                actionName: 'Requirement Spec',
                previewURLName: 'wbsrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>