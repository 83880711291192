<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Feedback Form</span></h4>
                        </div>                        
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Feedback</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.feedbackForm.title.$error }">
                                                                    <input type="text" maxlength="100" class="form-control" v-model="feedbackForm.title" id="title" :class="{ 'is-invalid': $v.feedbackForm.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.feedbackForm.title.$error && !$v.feedbackForm.title.minLength" class="text-danger">Title must have at least {{$v.feedbackForm.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.feedbackForm.title.$error && !$v.feedbackForm.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.feedbackForm.title.$error && !$v.feedbackForm.title.maxLength" class="text-danger">Title must not exceed {{$v.feedbackForm.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <select v-model="feedbackForm.statusId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="description">Description</label>
                                                                <textarea v-model="feedbackForm.description" rows="4" class="form-control" id="description" maxlength="1000" placeholder="Enter Description"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewStatement">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="feedbackFormDetail.testCaseId" class="mb-2">
                                                                <b-form-input aria-label="title" disabled v-model="feedbackFormDetail.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <input type="text" class="form-control" id="statusId" disabled v-model="feedbackFormDetail.statusId">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label>Attachment</label>
                                                                <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" viewAttachment="true" :attachments="attachments" />
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="description">Description</label>
                                                                <textarea rows="4" class="form-control" id="description" disabled v-model="feedbackFormDetail.description"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="startDate">Created Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="feedbackFormDetail.createdDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label for="endDate">Updated Date</label>
                                                                <div class="input-group date disabled-date">
                                                                    <input type="text" class="form-control disabled" :value="feedbackFormDetail.lastUpdatedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="feedbackForms" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                 <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <div class="dropdown dropright d-inline">
                                        <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>
                                        <div class="dropdown-menu">
                                            <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                            <div v-if="attachmentList.length>0">
                                                <ul class="attachmentList-ul p-0 m-0">
                                                    <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                        <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> 
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteFeedbackForm(data.item.id)" v-b-modal.deleteFeedbackForm><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Feedback Form found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="feedbackForms.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteFeedbackForm" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete FeedbackForm" />
        <FeedbackFormDetailPopup v-if="showDialog" ref="feedbackFormDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import FeedbackFormDetailPopup from '@/components/popup/itemdetail/FeedbackFormDetailPopup.vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
export default {
    name: 'app',
    components: {        
        FeedbackFormDetailPopup,
        AttachmentComponent
    },
    computed: {
        ermId() 
            {
            return Number(this.$route.params.ermId);
            }
        },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            
            notEnabled: true,
            status: [],
            feedbackForm: {
                id:'',
                title: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'feedback_form',
                description: '',
                statusId: '',
                status: ''
            },
            feedbackFormDetail: {
                title: '',
                description: '',
                createdDt: '',
                lastUpdatedDt: '',
                description: '',
                statusId: ''
            },

            detailViewData: {},
            referencePopupId: 0,

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            feedbackForms: [],
            fields: [
                { key: 'feedbackFormId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection:'desc' },
                { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Status', sortable: true, sortDirection:'desc' },
                { key: 'clientId', label: 'Client', sortable: true, sortDirection: 'desc' },  
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            attachmentInfo:{
                title:'Attachment'
            },
            attachments: [],
            attachmentList:[],

            attachmentData: {},

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getFeedbackForm();
    },
    validations: {
        feedbackForm: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = 'feedback_form';
            this.userId = localStorage.getItem("userId");
        },

        getFeedbackForm: function() {
            let data = {
                
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackform/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.feedbackForms = result.feedbackFormList;
                    this.totalRows = this.feedbackForms.length;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId:this.feedbackForm.vhextAccountId,
                projectId:this.feedbackForm.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackform/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.status = result.statusLookup;
                    console.log(result);
                })
        },
        async saveData() {
            this.$v.feedbackForm.$touch();
            if (!this.$v.feedbackForm.$invalid) {
                this.showOverlay = true;
                let result;
                if (this.elements.editMode) {
                    result = await this.updateFeedbackForm();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, this.feedbackForm.id);
                } else {
                    result = await this.saveFeedbackForm();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }

                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {                 
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getFeedbackForm();
                    this.clearData();
                }
            }
        },
        saveFeedbackForm: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackform/add', this.feedbackForm);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateFeedbackForm: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackform/update', this.feedbackForm);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        deleteFeedbackForm: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/feedbackform/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getFeedbackForm();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.feedbackFormDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.feedbackForm.title = '';
            this.feedbackForm.description = '';
            this.feedbackForm.statusId = '';
            this.$refs.attachmentControl.clearAttachment();
            this.$v.feedbackForm.$reset();
            this.attachments = [];

        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.feedbackForm.id = data.id;
            this.feedbackForm.feedbackFormId=data.feedbackFormId;
            this.feedbackForm.title = data.title;
            this.feedbackForm.description = data.description;
            this.feedbackForm.statusId = data.statusId;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.feedbackFormDetail.feedbackFormId = data.feedbackFormId;
            this.feedbackFormDetail.title = data.title;
            this.feedbackFormDetail.description = data.description;
            this.feedbackFormDetail.createdDt = data.createdDt;
            this.feedbackFormDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.feedbackFormDetail.id = data.id;
            this.feedbackFormDetail.clientId = data.clientId;
            this.feedbackFormDetail.statusId = data.statusId;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
        }
    }
}
</script>