import Risk from '@/views/risk/Risk.vue'
import RiskPlan from '@/views/risk/RiskPlan.vue'
import RiskPlanMaster from '@/views/risk/RiskPlanMaster.vue'
import RiskPlanMasterList from '@/views/risk/RiskPlanMasterList.vue'

export default [{
        path: '/risk',
        name: 'risk',
        component: Risk,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/riskplan/:ermId',
        name: 'riskplan',
        component: RiskPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rspmas',
        name: 'rspmas',
        component: RiskPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rspmas/list',
        name: 'rspmaslist',
        component: RiskPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }
];