import AccountHead from '@/views/finance/AccountHead.vue'
import AddCountry from '@/views/settings/country/AddCountry.vue'
import AdminBlockMenu from '@/views/menu/AdminBlockMenu.vue';
import FinTxnCategory from '@/views/settings/lookup/customcategories/FinTxnCategory.vue'
import Category from '@/views/settings/lookup/Category.vue'
import Country from '@/views/settings/country/Country.vue'
import EditCountry from '@/views/settings/country/EditCountry.vue'
import ViewCountry from '@/views/settings/country/ViewCountry.vue'
import CpmMemberServiceRoleResp from '@/views/menu/CpmMemberServiceRoleResp.vue';
import Currency from '@/views/settings/Currency.vue'
import CustomCategoryLookup from '@/views/settings/lookup/CustomCategoryLookup.vue'
import CustomTypeLookup from '@/views/settings/lookup/CustomTypeLookup.vue'
import Deliverable from '@/views/process/Deliverable.vue'
import EmailAccSettings from '@/views/settings/email/EmailAccountSettings.vue'
import Email from '@/views/tools/Email.vue'
import FinTxnType from '@/views/settings/lookup/customtypes/FinTxnType.vue'
import IssueType from '@/views/settings/lookup/customtypes/IssueType.vue'
// import MenuManagement from '@/views/menu/MenuManagement.vue'
import MembershipManagement from '@/views/resource/ProjectMembershipMgmt.vue';
import Menu from '@/views/settings/Menu.vue'
import MultiUserRole from '@/views/settings/MultiUserRole.vue'
import Priority from '@/views/settings/lookup/Priority.vue'
import ProjectActivity from '@/views/process/ProjectActivity.vue'
import ProjectProcess from '@/views/process/ProjectProcess.vue'
import ProjectSettings from '@/views/settings/ProjectSettings.vue'
import RequirementType from '@/views/settings/lookup/customtypes/RequirementType.vue'
import Resource from '@/views/resource/Resource.vue'
import RoleResponsibility from '@/views/resource/RoleResponsibility.vue'
import TechnologySkill from '@/views/resource/TechnologySkill.vue'
import Settings from '@/views/settings/Settings.vue'
import Severity from '@/views/settings/lookup/Severity.vue'
import Status from '@/views/settings/lookup/Status.vue'
import TaskType from '@/views/settings/lookup/customtypes/TaskType.vue'
import Type from '@/views/settings/lookup/Type.vue'
// import UserRole from '@/views/user/UserRole.vue'

export default [
    {
        path: '/settings/accounthead',
        name: 'setaccounthead',
        component: AccountHead,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/category',
        name: 'category',
        component: Category,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/country',
        name: 'country',
        component: Country,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/country/add',
        name: 'addcountry',
        component: AddCountry,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/country/edit',
        name: 'editcountry',
        component: EditCountry,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/country/view',
        name: 'viewcountry',
        component: ViewCountry,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/cmpmemmdlmenu',
        name: 'setcmpmemmdlmenu',
        component: CpmMemberServiceRoleResp,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/currency',
        name: 'currency',
        component: Currency,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/customcategorylookup',
        name: 'customcategorylookup',
        component: CustomCategoryLookup,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/deliverable',
        name: 'setdeliverable',
        component: Deliverable,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/email',
        name: 'email',
        component: Email,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/ftxtyp',
        name: 'ftxtyp',
        component: FinTxnType,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/isstyp',
        name: 'isstyp',
        component: IssueType,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/membershipmanage',
        name: 'membershipmanage',
        component: MembershipManagement,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/landing/admset',
        name: 'lanadmset',
        component: AdminBlockMenu,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/settings/ftxcat',
        name: 'ftxcat',
        component: FinTxnCategory,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    // {
    //     path: '/settings/mainmenu',
    //     name: 'mainmenu',
    //     component: MenuManagement,
    //     meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    // },
    {
        path: '/settings/menu',
        name: 'menu',
        component: Menu,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/multiuserrole',
        name: 'multiuserrole',
        component: MultiUserRole,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/priority',
        name: 'priority',
        component: Priority,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/prjact',
        name: 'setprjact',
        component: ProjectActivity,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/process',
        name: 'setprocess',
        component: ProjectProcess,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/reqtyp',
        name: 'reqtyp',
        component: RequirementType,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/resource',
        name: 'setresource',
        component: Resource,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/roleresponsibility',
        name: 'setroleresponsibility',
        component: RoleResponsibility,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/technologyskill',
        name: 'settechnologyskill',
        component: TechnologySkill,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/projectsettings',
        name: 'prjset',
        component: ProjectSettings,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/settings/severity',
        name: 'severity',
        component: Severity,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/tsktyp',
        name: 'tsktyp',
        component: TaskType,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/status',
        name: 'status',
        component: Status,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/type',
        name: 'type',
        component: Type,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/settings/customtypelookup',
        name: 'customtypelookup',
        component: CustomTypeLookup,
        meta: { layout: 'vmx-service-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    // {
    //     path: '/settings/userrole',
    //     name: 'userrole',
    //     component: UserRole,
    //     meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    // },

];