<template>
    <div>
        <b-modal id="projectSettingRedirectPopup" ref="projectSettingRedirectPopupRef" centered @hide="$router.push('/projectview');" modal-class="" title="Project Setup">
            <div class="p-0">
                <div class="text-center">
                    <p>Do you want setup Project Settings now?</p>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100 text-center">
                    <b-button class="mr-2" variant="outline-primary" size="sm" @click="$router.push('/projectview');">Do it Later</b-button>
                    <b-button variant="primary" size="sm" @click="$router.push('/settings');">Go To Project Settings</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    name: 'projectSettingRedirectPopup',
    data() {
        return {

        }
    }
}
</script>