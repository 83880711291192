<template>
    <div>
        <div v-if="attachments.length > 0" class="doc-list">
            <span v-for="(attachment, index) in attachments" class="pr-2">
                <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>
            </span>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        attachmentData: Object,
        showDocsOnly: Boolean,
        showLinkOnly: Boolean,
    },
    data() {
        return {
            attachmentList: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
        }
    },
    computed: {
        attachments() {
            if (this.showDocsOnly) {
                return this.attachmentList.filter((data) => data.typeCode == 'DAT');
            }
            else if (this.showLinkOnly) {
                return this.attachmentList.filter((data) => data.typeCode == 'DSL');
            }
            else {
                return this.attachmentList;
            }
        }
    },
    mounted() {
        this.getAttachments();
    },
    methods: {
        getAttachments: function() {
            if (this.attachmentData.refEntity && this.attachmentData.refRecordId) {
                let data = {
                    refEntity: this.attachmentData.refEntity,
                    refRecordId: this.attachmentData.refRecordId,
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId
                }
                this.attachmentList = [];
                return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.attachmentList = result.attachmentList;
                        this.$emit('getAttachmentLength', this.attachments.length);
                    })
            }
        }
    }
}
</script>