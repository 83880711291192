import ReqTraceabilityMatrix from '@/views/control/ReqTraceabilityMatrix.vue'

export default [{

        path: '/reqtrace',
        name: 'reqtrace',
        component: ReqTraceabilityMatrix,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }

];