<template>
    <div>
        <div class="row">
            <div class="col-lg-12 col-xl-12">
                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                    <div class="card mb-3">
                        <b-card no-body class="">
                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab" @click="autoIncrementEmployeeCode">
                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Resource>
                                    <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>New Employee
                                </label>
                            </b-card-header>
                            <b-collapse id="accordion-Resource" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                <b-card-body class="p-3 mb-2">
                                    <div class="row mb-2">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <!-- <input type="text" class="form-control" id="name" v-model="humanResource.name" placeholder="Enter Name"> -->


                                                <a href="javascript:" class="float-right button-add action-link" @click="toggleUserDropdown">
                                                    <i v-if="!elements.showDropdownResource" title="Select Existing Resource" class="fa fa-lg fa-caret-down"></i>
                                                    <i v-if="elements.showDropdownResource" title="Add New Employee" class="fa fa-lg fa-plus-square-o"></i>
                                                </a>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.humanResource.name.$error }">
                                                    <select v-if="elements.showDropdownResource" v-model="humanResource.member" @change="getHumanResourceData(humanResource.member.id);humanResource.name=humanResource.member" :class="{ 'is-invalid': $v.humanResource.name.$error }" class="form-control">
                                                        <option value="">Select Existing Resource</option>
                                                        <option v-for="(info, index) in nonEmployeeResources" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                    </select>
                                                    <input v-else type="text" class="form-control" v-model="humanResource.name" :class="{ 'is-invalid': $v.humanResource.name.$error }" placeholder="Enter New Employee Name">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <span v-if="$v.humanResource.name.$error && !$v.humanResource.name.required" class="text-danger">Please enter employee name</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-md-2 pr-0">
                                            <div class="form-group">
                                                <label for="employeeCode">EID</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.humanResource.employeeCode.$error }">
                                                    <b-form-input type="text" v-model="humanResource.employeeCode" :class="{ 'is-invalid': $v.humanResource.employeeCode.$error }" placeholder="Emp. Id" id="employeeCode">
                                                    </b-form-input>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <span v-if="$v.humanResource.employeeCode.$error && !$v.humanResource.employeeCode.required" class="text-danger">Please enter Employee Id</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4">
                                            <div class="form-group">
                                                <label for="status">Employee Status</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.humanResource.employeeStatusId.$error }">
                                                    <select v-model="humanResource.employeeStatusId" class="form-control" :class="{ 'is-invalid': $v.humanResource.employeeStatusId.$error }">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in employeeStatuses" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.humanResource.employeeStatusId.$error && !$v.humanResource.employeeStatusId.required" class="text-danger">Please Select a Employee Status</label>
                                            </div>
                                        </div>                                        
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="designation">Designation</label>
                                                <select v-model="humanResource.designationData" class="form-control" id="designation">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in designations" :value="{id: info.id, text: info.value}" :key="index">{{info.value}}</option>
                                                </select>
                                                <a :title="designationLookupTitle" class="setting-icon action-link" href="javascript:" v-b-modal.designationModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="department">Department</label>
                                                <select v-model="humanResource.departmentId" class="form-control" id="department">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                                <a :title="departmentLookupTitle" class="setting-icon action-link" href="javascript:" v-b-modal.departmentModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="qualification">Qualification</label>
                                                <input type="text" class="form-control" id="qualification" v-model="humanResource.qualification" placeholder="Qualification">
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="experienceYears">Experience</label>
                                                <div class="row no-gutters">
                                                    <div class="col-6">
                                                        <b-form-input type="number" min="0" max="80" v-model="humanResource.experienceYears" placeholder="Years" id="experienceYears"></b-form-input>
                                                    </div>
                                                    <div class="col-6">
                                                        <b-form-input type="number" min="0" max="12" v-model="humanResource.experienceMonths" placeholder="Months"></b-form-input>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form-group">
                                                <label for="emailId">Email Id</label>
                                                <b-form-input type="email" v-model="humanResource.emailId" placeholder="Email Id" id="emailId">
                                                </b-form-input>
                                                <span v-if="$v.humanResource.emailId.$error && !$v.humanResource.emailId.email" class="text-danger">Please enter a valid Email Id</span>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                             <div class="form-group">
                                                <label for="mobile">Mobile</label>
                                                <b-form-input type="text" v-model="humanResource.mobile" placeholder="Mobile" id="mobile">
                                                </b-form-input>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                             <div class="form-group">
                                                <label for="recruitedById">Recruited By</label>
                                                <select v-model="humanResource.recruitedById" class="form-control" id="recruitedById">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                             <div class="form-group">
                                                <label for="reportingToId">Reporting To</label>
                                                <select v-model="humanResource.reportingToId" class="form-control" id="reportingToId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                    <div class="row view-filter">

                        <div class="col-4 text-left">
                            <div class="dataTables_filter">
                                <label class="d-block p-1 accordion-title">Employees
                                </label>
                            </div>
                        </div>
                        <div class="col-4 text-center">
                            <!-- <label for="preparedBy">Employee Status</label> -->
                            <select v-model="employeeStatusId" class="form-control" @change="onStatusFiltered">
                                <option value="">All Status</option>
                                <option v-for="(info, index) in employeeStatuses" :value="info.id" :key="index">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col-4 text-right">
                            <div class="dataTables_filter">
                                <label>
                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                </label>
                            </div>
                        </div>
                    </div>
                    <b-table ref="employeeTable" v-model="employeeTableArray" tbody-tr-class="align-items-center" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode"  sort-icon-left :items="filteredEmployees" @row-selected="onRowSelected" :fields="employeeFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onEmployeeFiltered">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <!-- A custom formatted column -->
                        <template #cell(name)="data">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                    <b-avatar v-else class="mr-2"></b-avatar>
                                </div>
                                <div class="col">
                                    <div class="mr-auto">{{ data.value }}</div>
                                    <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                </div>
                            </div>
                        </template>
                        <template #cell(experienceYears)="data">
                            <span  v-if="Number(data.item.experienceYears)!=0">{{ data.item.experienceYears}} years</span> <span v-if="Number(data.item.experienceMonths)!=0">{{ data.item.experienceMonths }} months</span>
                        </template>

                        <template v-slot:cell(actions)="data">
                            <a v-if="!data.item.userId" href="javascript:" title="Delete" @click="deleteEmployee(data.item.empId)" v-b-modal.deleteEmployee><i class="fa fa-trash pl-2"></i></a>
                            <a v-else>N/A</a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Employees found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
<!--                     <div class="float-left">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div> -->
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalEmployees}} Items</span>

<!--                     <b-pagination v-if="filteredEmployees.length!=0" pills v-model="empCurrentPage" :total-rows="this.totalEmployees" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                </b-overlay>
            </div>
        </div>
        <AddDepartmentLookup id="departmentModal" ref="departmentModal" :departmentLookupTitle="departmentLookupTitle" @refresh-lookup="lookupData" />
        <AddDesignationLookup id="designationModal" ref="designationModal" :designationLookupTitle="designationLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteEmployee" :onYes="onYesEmployeeDelete" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure you want to delete the employee? However, the individual will continue to exist as a non-employee." />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
import { required, email, minLength, maxLength, sameAs, requiredIf } from "vuelidate/lib/validators";
import AddDepartmentLookup from '@/components/popup/lookup/AddDepartmentLookup.vue';
import AddDesignationLookup from '@/components/popup/lookup/AddDesignationLookup.vue';
export default {
    name: 'app',
    components: {
        AddDepartmentLookup,
        AddDesignationLookup,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entity: 'human_resource',
            userId: localStorage.getItem("userId"),

            departmentLookupTitle: 'Departments',
            designationLookupTitle: 'Designations',

            activeHRTab: 0,
            monthList: month,
            showOverlay: false,
            elements: {
                showDetail: false,
                visible: false,
                showDropdownResource: true,
            },
            humanResourceId: null,
            humanResource: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                entity: '',
                userId: '',
                loginName: '',
                userType: '',
                humanResourceId: '',
                isEmployee: '',
                name: '',
                member: '',
                employeeCode: '',
                recruitedById: '',
                reportingToId: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                emailId: '',
                mobile: '',
                roleResponsibility:'',
                technologySkill:'',
                empId: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                employeeStatusId: '',
                employeeStatus: '',
                jobPositionChangeById: '',
                jobPositionChangeDate: '',
                active: true,
                designationData: '',
                departmentId: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            employeeStatusId: '',
            users: [],
            designations: [],
            departments: [],
            employeeStatuses: [],
            nonEmployeeResources: [],
            humanResourceData: {},

            employeeTableArray: [],

            hrEmployees: [],
            filteredEmployees: [],

            employeeFields: [
                // { key: 'index', label: 'SN' },
                // { key: 'employeeId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'employeeCode', label: 'EID', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },                
                // { key: 'designation', label: 'Designation', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'department', label: 'Department', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                // { key: 'qualification', label: 'Qualification', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                // { key: 'workType', label: 'Work Type', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' }, 
                // { key: 'mobile', label: 'Mobile', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },         
                // { key: 'emailId', label: 'Email Id', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'employeeStatus', label: 'Status', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions', tdClass: 'align-middle', }
            ],

            selectMode: 'single',

            totalRows: 1,
            totalEmployees: 1,
            totalOtherResources: 1,

            currentPage: 1,
            empCurrentPage: 1,

            perPage: 10,
            pageOptions: [5, 10, 15,25,50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {

    },
    mounted() {
        this.lookupData();
    },
    validations: {
        humanResource: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            employeeCode: {
                required,
            },
            emailId: {
                email
            },
            employeeStatusId: {
                required,
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity, 
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.designations = result.designationLookup;
                    this.departments = result.departmentLookup;
                    this.nonEmployeeResources = result.nonEmployeeResourceLookup;
                    this.employeeStatuses = result.employeeStatusLookup;

                    this.employeeStatusId = this.employeeStatuses[0].id;

                    this.getHumanResource();        

                })

        },

         getHumanResource: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/hremployee/list',data)
                .then((response) => {
                    let result = response.data;
                    this.hrEmployees = result.hrEmployeeList;

                    console.log("emp1",this.hrEmployees);

                    this.onStatusFiltered();

                });
        },

        getNonEmployeeResourceLookup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/nonemployeeresources', data)
                .then((response) => {
                    let result = response.data;
                    this.nonEmployeeResources = result.nonEmployeeResourceLookup;
                })

        },

        saveData: function() {
            // this.humanResource.isEmployee = true;
            this.humanResource.isEmployee = true;
            this.humanResource.jobPositionChangeById = this.humanResource.recruitedById;
            // this.humanResource.jobPositionChangeDate = this.humanResource.designationData.text;
            this.humanResource.designationId = this.humanResource.designationData.id;
            this.humanResource.position = this.humanResource.designationData.text;
            if (this.humanResource.member.id) {
                this.humanResource.id = this.humanResource.member.id;
                this.humanResource.name = this.humanResource.member.text;
            } else {
                this.humanResource.id = '';
            }
            this.$v.humanResource.$touch();
            if (!this.$v.humanResource.$invalid) {
                this.saveHumanResource();
            }
        },
        saveHumanResource: function() {
            console.log(this.humanResource);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/add', this.humanResource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.getHumanResource();
                        this.getNonEmployeeResourceLookup();
                        this.clearData();
                    } else if(result.status == "OK") {
                        iziToast.info({
                            title: 'Conflict',
                            message: result.message,
                            position: 'topCenter'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: error.message,
                       position: 'topRight'
                   });
                })
        },
        deleteEmployee: function(id) {
            this.deleteParam.id = id;

        },
        onYesEmployeeDelete: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/employee/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getHumanResource();
                        this.getNonEmployeeResourceLookup();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        onEmployeeFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalEmployees = filteredItems.length;

            // this.empCurrentPage = 1
        },
        onStatusFiltered() {

            console.log("empStatuId",this.employeeStatusId);

            console.log("emp",this.hrEmployees);

            if(this.employeeStatusId){
                this.filteredEmployees = this.hrEmployees.filter(item => item.employeeStatusId == this.employeeStatusId);
            }else{
                this.filteredEmployees = this.hrEmployees;
            }

            this.totalEmployees = this.filteredEmployees.length; 

            this.$emit('updateHRCount', this.totalEmployees);

            // this.employeeTableArray = this.filteredEmployees;

            if (this.filteredEmployees.length > 0) {
                this.$nextTick(() => {

                    this.selectBTableRowById(this.humanResourceId, this.employeeTableArray, 'employeeTable');
                })
            }
        },

        toggleUserDropdown() {
            this.elements.showDropdownResource = !this.elements.showDropdownResource;
            this.humanResource.member = '';
            this.humanResource.name = '';
            this.humanResource.emailId = '';
            this.humanResource.mobile = '';
            this.humanResource.position = '';
            this.humanResource.qualification = '';
            this.humanResource.experienceYears = '';
            this.humanResource.recruitedById = '';
        },
        clearData: function() {
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.humanResource.id = '';
            this.humanResource.humanResourceId = '';
            this.humanResource.loginName = '';
            this.humanResource.userType = '';
            this.humanResource.member = '';
            this.humanResource.name = '';
            this.humanResource.position = '';
            this.humanResource.qualification = '';
            this.humanResource.experienceYears = '';
            this.humanResource.experienceMonths = '';
            this.humanResource.emailId = '';
            this.humanResource.mobile = '';
            this.humanResource.employeeCode = '';
            this.humanResource.recruitedById = '';
            this.humanResource.reportingToId = '';
            this.humanResource.technologySkill = '';
            this.humanResource.createdBy = '';
            this.humanResource.createdDt = '';
            this.humanResource.lastUpdatedDt = '';
            this.humanResource.designationData = '';
            this.humanResource.departmentId = '';
            this.humanResource.employeeStatusId = '';
            this.$v.humanResource.$reset();
        },
        onRowSelected: function(items) {
            if (items && items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.humanResourceId = data.id;
                this.$emit('updateShowDetail', this.elements.showDetail);
                this.$emit('getHumanResourceDetail', data.id );
            }
        },
        getHumanResourceData: function(id) {
            console.log(id);
            this.showOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + id)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.humanResourceData = result.humanResourceView;
                    this.humanResource.emailId = this.humanResourceData.emailId;
                    this.humanResource.position = this.humanResourceData.position;
                    this.humanResource.qualification = this.humanResourceData.qualification;
                    this.humanResource.experienceYears = this.humanResourceData.experienceYears;
                    this.humanResource.mobile = this.humanResourceData.mobile;
                    this.humanResource.recruitedById = this.humanResourceData.recruitedById;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: error.message,
                       position: 'topRight'
                   });
                })
        },
        autoIncrementEmployeeCode() {
            if (this.elements.visible) {
                let employeeCodeArray = this.hrEmployees.map(data => data.employeeCode);
                this.humanResource.employeeCode = this.alphaNumericAutoIncrement(employeeCodeArray);
            }
        },
    }

}
</script>