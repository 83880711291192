<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Requirement Traceability Matrix</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
			<div class="row">
                <div class="col-lg-12 col-sm-12 px-3 mb-3 menumangement">
                    <div no-body>
                        <div class="card ">
                            <div class="card-body p-3">
                                <div class="menu_cont active" id="mainMenu">
                                    <div id="mainmenu-app" class="mb-1" v-if="elements.PageListCarousel">
                                        <b-card no-body class="mr-2">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                    <span>Search Recruitment</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                <b-card-body class="px-2 pb-2 pt-1">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-11">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-4">
                                                                    <label for="ermLookup">Entity</label>
                                                                    <select v-model="reqTraceMatrix.filterByEntity" @change="getEntityRecordMaster(reqTraceMatrix.filterByEntity);" class="form-control" id="ermLookup">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in ermLookups" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-12 col-sm-4">
                                                                    <label for="filterByReqSpec">Requirement Spec</label>
                                                                    <select v-model="reqTraceMatrix.filterByReqSpec" @change="getRequirementSpecs(reqTraceMatrix.filterByReqSpec);" class="form-control" id="filterByReqSpec">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in requirements" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                </div>
                                                                <div class="col-12 col-sm-4">
                                                                    <label for="reqId">Requirement Spec Id</label>
                                                                    <input type="text" class="form-control col-12" id="reqId" placeholder="Requirement Spec Id">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-1">
                                                            <div class="row">
                                                                <label for="icon" style="visibility: hidden;">Menu Icon</label>
                                                                <button type="submit" @click="elements.visible=false" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-search pr-2"></i>Go</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                    <div class="listingGrid" v-if="!elements.ListGridHidden">
                                        <div class="row view-filter">
                                            <div class="col-sm-12">
                                                <div class="float-right">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div class="float-left">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="reqTraceMatrices" @row-selected="onRowSelected" :fields="reqTraceMatrixFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                                <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Requrement Trace Matrices found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <b-pagination v-if="reqTraceMatrices.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                    <div class="row">
                                        <a class="CarouselClose" @click="CarouselClose"><i class='fa fa-times action-link'></i></a>
                                        <div class="col-6">
                                            <div class="card mx-0 px-0 my-2">
                                                <div class="card-body vcenter-item mx-2 px-0">
                                                    <div class="row mb-auto w-100 mx-0">
                                                        <div class="col-12 px-0 text-left">
                                                            <div class="card-body p-0 px-0 mx-0 mb-3">
                                                                <h6 class="text-left ml-3 mt-0 mr-0 mb-3">Requirement Spec</h6>
                                                                <div class="col-lg-12 col-xl-12">
                                                                    <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                                                        <div class="form-group">
                                                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ requirementSpecs.status }}</label></h6>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-9 col-sm-9 mb-3 px-0">
                                                                        <div class="row mb-2">
                                                                            <div class="col-2 pr-0">
                                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ requirementSpecs.requirementSpecId }}</label>
                                                                            </div>
                                                                            <div class="col-10 pl-0">
                                                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ requirementSpecs.title }}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 px-0">
                                                                        <div class="row mb-2">
                                                                            <div class="col-3 pr-0">
                                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Type</label>
                                                                            </div>
                                                                            <div class="col-9 pl-0">
                                                                                <label class="p-2 mb-0 d-block header-details">{{requirementSpecs.type}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 px-0">
                                                                        <div class="row mt-0 mb-2">
                                                                            <div class="col-12 col-md-3 pr-0">
                                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Prepared By</label>
                                                                            </div>
                                                                            <div class="col-12 col-md-9 pl-0">
                                                                                <label class="p-2 mb-0 d-block header-details">{{requirementSpecs.preparedBy}}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-12 px-0">
                                                                        <div class="card mt-2 mb-3">
                                                                            <div class="card-body py-1 px-0">
                                                                                <p class="contact-title mb-2"><span>Requirement Specification</span></p>
                                                                                <div class="row">
                                                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                                                        <label class="p-2 mb-0 d-block header-details" v-html="requirementSpecs.requirementSpecification"></label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-6 col-sm-6">
                                                                            <div class="row mt-0 mb-2">
                                                                                <div class="col-6 col-md-6 pr-0">
                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Prepared Date</label>
                                                                                </div>
                                                                                <div class="col-6 col-md-6 pl-0">
                                                                                    <label class="p-2 mb-0 d-block header-details">{{requirementSpecs.preparedDt | formatDate}}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-6">
                                                                            <div class="row mt-0 mb-2">
                                                                                <div class="col-6 col-md-6 pr-0">
                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Due Date</label>
                                                                                </div>
                                                                                <div class="col-6 col-md-6 pl-0">
                                                                                    <label class="p-2 mb-0 d-block header-details font-weight-light">{{requirementSpecs.dueDate | formatDate}}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-6">
                                                                            <div class="row mt-0 mb-2">
                                                                                <div class="col-6 col-md-6 pr-0">
                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Priority</label>
                                                                                </div>
                                                                                <div class="col-6 col-md-6 pl-0">
                                                                                    <label class="p-2 mb-0 d-block header-details font-weight-light">{{ requirementSpecs.priority }}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="PageListCarousel" v-if="elements.PageListCarousel">
                                               <!--  <div class="card p-4"> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        <carousel :items="1" :dots=false :nav=false>
                                                            <div class="card m-2">
                                                                <div class="card-body vcenter-item mx-0 mx-3 px-0" v-if="userRequirementList.length !== 0" @click="">
                                                                    <div class="row mb-auto w-100 mx-0">
                                                                        <div class="col-12 px-0 text-left">
                                                                            <div class="card-body p-0 px-0 mx-0 mb-3">
                                                                                <div v-if="userRequirementList.length == 1">
                                                                                    <h6 class="text-left ml-1 mt-0 mr-0 mb-3">User Requirement</h6>
                                                                                    <div v-for="userRequirement in userRequirementList" class="col-lg-12 col-xl-12 px-1">
                                                                                        <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                                                                            <div class="form-group">
                                                                                                <h6 class=""><label class="badge badge-secondary" title="Status">{{ userRequirement.status }}</label></h6>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-9 col-sm-9 mb-3 px-0">
                                                                                            <div class="row mb-2">
                                                                                                <div class="col-2 pr-0">
                                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ userRequirement.userRequirementId }}</label>
                                                                                                </div>
                                                                                                <div class="col-10 pl-0">
                                                                                                    <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ userRequirement.title }}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 col-sm-12 px-0">
                                                                                            <div class="row mb-2">
                                                                                                <div class="col-3 pr-0">
                                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Type</label>
                                                                                                </div>
                                                                                                <div class="col-9 pl-0">
                                                                                                    <label class="p-2 mb-0 d-block header-details">{{userRequirement.type}}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 col-sm-12 px-0">
                                                                                            <div class="row mt-0 mb-2">
                                                                                                <div class="col-12 col-md-3 pr-0">
                                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Requirement By</label>
                                                                                                </div>
                                                                                                <div class="col-12 col-md-9 pl-0">
                                                                                                    <label class="p-2 mb-0 d-block header-details">{{userRequirement.userRequirementBy}}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 col-sm-12 px-0">
                                                                                            <div class="card mt-2 mb-3">
                                                                                                <div class="card-body py-1 px-0">
                                                                                                    <p class="contact-title mb-2"><span>User Requirement</span></p>
                                                                                                    <div class="row">
                                                                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                                                                            <label class="p-2 mb-0 d-block header-details">{{userRequirement.userRequirement}}</label>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="row">
                                                                                            <div class="col-6 col-sm-6">
                                                                                                <div class="row mt-0 mb-2">
                                                                                                    <div class="col-6 col-md-6 pr-0">
                                                                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Prepared Date</label>
                                                                                                    </div>
                                                                                                    <div class="col-6 col-md-6 pl-0">
                                                                                                        <label class="p-2 mb-0 d-block header-details">{{userRequirement.preparedDt | formatDate}}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-6 col-sm-6">
                                                                                                <div class="row mt-0 mb-2">
                                                                                                    <div class="col-6 col-md-6 pr-0">
                                                                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Due Date</label>
                                                                                                    </div>
                                                                                                    <div class="col-6 col-md-6 pl-0">
                                                                                                        <label class="p-2 mb-0 d-block header-details font-weight-light">{{userRequirement.dueDate | formatDate}}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-6 col-sm-6">
                                                                                                <div class="row mt-0 mb-2">
                                                                                                    <div class="col-6 col-md-6 pr-0">
                                                                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Priority</label>
                                                                                                    </div>
                                                                                                    <div class="col-6 col-md-6 pl-0">
                                                                                                        <label class="p-2 mb-0 d-block header-details font-weight-light">{{ userRequirement.priority }}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div v-if="userRequirementList.length > 1">
                                                                                    <b-card no-body>
                                                                                        <b-card-header header-tag="header" class=" accordion-header py-0 px-3" role="tab">
                                                                                            <label class="d-block p-1 my-1 accordion-title" id="showTimeSheet" block href="javascript:" v-b-toggle.accordion-timeSheet>
                                                                                                <label for="description">User Requirement</label>
                                                                                            </label>
                                                                                        </b-card-header>
                                                                                        <b-collapse id="accordion-timeSheet" v-model="elements.userReqVisible" accordion="my-accordion4" role="tabpanel">
                                                                                            <b-card-body class="py-2 mx-0 px-0">
                                                                                                <div class="col-lg-12 col-xl-12">
                                                                                                    <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                                                                                        <div class="form-group">
                                                                                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ userRequirement.status }}</label></h6>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-9 col-sm-9 mb-3 px-0">
                                                                                                        <div class="row mb-2">
                                                                                                            <div class="col-2 pr-0">
                                                                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ userRequirement.userRequirementId }}</label>
                                                                                                            </div>
                                                                                                            <div class="col-10 pl-0">
                                                                                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ userRequirement.title }}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-12 col-sm-12 px-0">
                                                                                                        <div class="row mb-2">
                                                                                                            <div class="col-3 pr-0">
                                                                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Type</label>
                                                                                                            </div>
                                                                                                            <div class="col-9 pl-0">
                                                                                                                <label class="p-2 mb-0 d-block header-details">{{userRequirement.type}}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-12 col-sm-12 px-0">
                                                                                                        <div class="row mt-0 mb-2">
                                                                                                            <div class="col-12 col-md-3 pr-0">
                                                                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Requirement By</label>
                                                                                                            </div>
                                                                                                            <div class="col-12 col-md-9 pl-0">
                                                                                                                <label class="p-2 mb-0 d-block header-details">{{userRequirement.userRequirementBy}}</label>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="col-12 col-sm-12 px-0">
                                                                                                        <div class="card mt-2 mb-3">
                                                                                                            <div class="card-body py-1 px-0">
                                                                                                                <p class="contact-title mb-2"><span>User Requirement</span></p>
                                                                                                                <div class="row">
                                                                                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                                                                                        <label class="p-2 mb-0 d-block header-details">{{userRequirement.userRequirement}}</label>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="row">
                                                                                                        <div class="col-6 col-sm-6">
                                                                                                            <div class="row mt-0 mb-2">
                                                                                                                <div class="col-6 col-md-6 pr-0">
                                                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Prepared Date</label>
                                                                                                                </div>
                                                                                                                <div class="col-6 col-md-6 pl-0">
                                                                                                                    <label class="p-2 mb-0 d-block header-details">{{userRequirement.preparedDt | formatDate}}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-6 col-sm-6">
                                                                                                            <div class="row mt-0 mb-2">
                                                                                                                <div class="col-6 col-md-6 pr-0">
                                                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Due Date</label>
                                                                                                                </div>
                                                                                                                <div class="col-6 col-md-6 pl-0">
                                                                                                                    <label class="p-2 mb-0 d-block header-details font-weight-light">{{userRequirement.dueDate | formatDate}}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="col-6 col-sm-6">
                                                                                                            <div class="row mt-0 mb-2">
                                                                                                                <div class="col-6 col-md-6 pr-0">
                                                                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block font-weight-light">Priority</label>
                                                                                                                </div>
                                                                                                                <div class="col-6 col-md-6 pl-0">
                                                                                                                    <label class="p-2 mb-0 d-block header-details font-weight-light">{{ userRequirement.priority }}</label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </b-card-body>
                                                                                        </b-collapse>
                                                                                    </b-card>
                                                                                    <div class="col-12 px-0 mt-3 text-left">
                                                                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="userRequirementList" @row-selected="onRowSelectedUserRq" :fields="userRequirementFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                                                            <template v-slot:empty="scope">
                                                                                                <div class="text-center">No User Requrements found</div>
                                                                                            </template>
                                                                                            <template v-slot:emptyfiltered="scope">
                                                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                                            </template>
                                                                                        </b-table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card m-2 "> 
                                                                <h6 class="text-left ml-3 mt-2 mr-0 mb-0">Requirement Specifications</h6>
                                                                <div class="card-body vcenter-item" @click="pageClick">
                                                                    <div class="row text-left mt-2 mb-auto">
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <h6><label class="font-weight-bold" for="title1">{{ requirementSpecs.title }}</label></h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Requirement Type</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.type }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Prepared By</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.preparedBy }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Requirement</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.requirementSpec }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Prepared Date</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.preparedDt | formatDate }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Due Date</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.dueDate | formatDate }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Priority</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.priority }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Status</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.status }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-6">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Created Date</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.createdDt | formatDate }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 col-sm-6">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Updated Date</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ requirementSpecs.lastUpdatedDt | formatDate }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card m-2 "> 
                                                                <div class="card-body vcenter-item" @click="pageClick">
                                                                    <div class="row text-left mt-2 mb-auto">
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <h6><label class="font-weight-bold" for="title1">{{ uatTestCase.title }}</label></h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Test Case Type</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ uatTestCase.type }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Description</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ uatTestCase.description }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Expected Result</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ uatTestCase.expectedResult }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Prepared By</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ uatTestCase.preparedBy }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Created Date</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ uatTestCase.createdDt | formatDate }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-12">
                                                                            <div class="form-group">
                                                                                <b-row>
                                                                                    <b-col cols="6">
                                                                                        <label class="font-weight-bold">Updated Date</label>
                                                                                    </b-col>
                                                                                    <b-col cols="6">
                                                                                        <label>{{ uatTestCase.lastUpdatedDt | formatDate }}</label>
                                                                                    </b-col>
                                                                                </b-row>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">Task</h6>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">TimeSheet</h6>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">Page 6</h6>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">Page 7</h6>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">Page 8</h6>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">Page 9</h6>
                                                            </div>
                                                            <div class="card-body vcenter-item" @click="pageClick">
                                                                <h6 class="">Page 10</h6>
                                                            </div>

                                                            <template slot="prev"><span class="owl-prev"><i class='fa fa-angle-left f-size-40'></i></span></template>

                                                            <template slot="next" ><span class="owl-next"><i class='fa fa-angle-right f-size-40'></i></span></template>
                                                        </carousel>
                                                    </div>
                                                </div>
                                             <!--    </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="PageView" v-if="elements.PageView">
                                        <div class="card p-4">
                                            <a class="PageViewClose" @click="PageViewClose"><i class='fa fa-times action-link'></i></a>
                                            <h1>PageView</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        </div>
    </div>

</template>

<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
//import modal  from './settings/lookup/LookupAdd.vue';
import carousel from 'vue-owl-carousel'

export default {
    name: 'app',
    components: {
    //modal ,
    carousel 
    },
    computed: {
        entityId() {
            return Number(this.$route.params.entityid);
        }
    },
    
  
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
                modalOpen: false,
                userReqVisible: false,

                ListGridHidden:false,
                PageListCarousel:false,
                PageView:false,
                searchView:true
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            mainMenu:{
                mainMenu: '',
            },
            reqTraceMatrix: {
                id: '',
                reqTraceMatrixId: '',
                vhextAccountId: '',
                projectId: '',
                rtmTypeId: '',
                bizReq: '',
                userReq: '',
                srs: '',
                uatTc: '',
                fncTc: '',
                integTc: '',
                unitTc: '',
                task: '',
                timesheet: '',
                managedById: '',
                statusId: '',
                createdDt: '',
                lastUpdatedDt: '',
                filterByEntity: '',
                filterByReqSpec: '',
            },
            requirementSpec: {
                id: '',
                userRequirementId: '',
                title: '',
                vhextAccountId: '',
                projectId: '',
                status: '',
                type: '',
                userRequirementBy: '',
                userRequirement: '',
                preparedDt: '',
                dueDate: '',
                priority: '',
                createdDt: '',
                lastUpdatedDt: '',    
            },
            userRequirement: {
                id: '',
                userRequirementId: '',
                title: '',
                vhextAccountId: '',
                projectId: '',
                status: '',
                type: '',
                userRequirementBy: '',
                userRequirement: '',
                preparedDt: '',
                dueDate: '',
                priority: '',
                createdDt: '',
                lastUpdatedDt: '',    
            },
            ermLookups: [],
            requirements: [],
            requirementSpecs: [],
            uatTestCase: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            reqTraceMatrices: [],
            reqTraceMatrixFields: [
                { key: 'reqTraceMatrixId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'userReq', label: 'User Req', sortable: true, sortDirection: 'desc' },
                { key: 'srs', label: 'Requirement Spec', sortable: true, sortDirection: 'desc' },
                { key: 'uatTc', label: 'UAT', sortable: true, sortDirection: 'desc' },
                { key: 'fncTc', label: 'FT Case', sortable: true, sortDirection: 'desc' },
                { key: 'task', label: 'Task', sortable: true, sortDirection: 'desc' },               
                { key: 'timesheet', label: 'Timesheet', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            userRequirementList: [],
            userRequirementFields: [
                { key: 'userRequirementId', label: 'ID', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
   mounted() {
        this.getReqTraceMatrixList();
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId:this.vhextAccountId,
                projectId:this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/reqtracematrix/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.ermLookups = result.entityRecordMasterLookup;
                        this.requirements = result.requirementLookup;
                    }
                    console.log(this.ermLookups);
                })
        },
        resetInfoModal() {
            this.infoModal.title = '';
            this.infoModal.content = '';
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getReqTraceMatrixList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/reqtracematrix/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.reqTraceMatrices = result.reqTraceMatrixList;
                        console.log(result);
                    }
                })
        },  
        getEntityRecordMaster: function(id) {
            // let data = {
            //     entityRecordMasterId: id,
            // }
            // axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/view', data)
            //     .then((response) => {
            //         let result = response.data;
            //         if (result.length != 0) {
            //             this.requirementSpec = result.entityRecordMaster;
            //             console.log(result.entityRecordMaster);
            //         }
            //     })
        },
        getUserRequirement: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.userRequirementList.push(result.userRequirementView);
                        console.log(this.userRequirementList);
                    }
                })
        },
        getRequirementSpecs: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.requirementSpecs = result.requirementSpecView;
                        console.log(this.requirementSpecs);
                    }
                })
        },
        getUatTestCase: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/test/uattestcase/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.uatTestCase = result.uatTestCaseView;
                        console.log(this.uatTestCase);
                    }
                })
        },
        getFTCase: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/test/uattestcase/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.functionalTestCase = result.uatTestCaseView;
                        console.log(this.functionalTestCase);
                    }
                })
        },
        getTask: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.task = result.task;
                        console.log(this.task);
                    }
                })
        },
        getTimeSheet: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/task/timesheet/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.timeSheet = result.timeSheetView;
                        console.log(this.timeSheet);
                    }
                })
        },
        pageClick: function() {  
            this.elements.ListGridHidden = true;
            this.elements.PageListCarousel = false;
            this.elements.PageView = true;
            this.elements.searchView = false;
        },
        CarouselClose: function() {          
            this.elements.ListGridHidden = false;
            this.elements.PageListCarousel = false;
            this.elements.PageView = false;
            this.elements.searchView = true;
            this.userRequirementList = [];
        },
        PageViewClose: function() {
            this.elements.ListGridHidden = true;
            this.elements.PageListCarousel = true;
            this.elements.PageView = false;
            this.elements.searchView = false;
        },
        
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.$v.module.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
        },
        showView: function(data) {
            console.log(data)
            this.elements.visible = true;
            this.elements.viewMode = false;

            this.elements.ListGridHidden = true;
            this.elements.PageListCarousel = true;
            this.elements.PageView = false;
            this.elements.searchView = false;
            if (data.userReqId !== null && data.userReqId !== undefined ) {
                this.getUserRequirement(data.userReqId);
                }
            if (data.srsId !== null && data.srsId !== undefined) {
                this.getRequirementSpecs(data.srsId);
                }
            if (data.uatTcTd !== null && data.uatTcTd !== undefined) {
                this.getUatTestCase(data.uatTcTd);
                }
            if (data.fncTcId !== null && data.fncTcId !== undefined) {
                this.getFTCase(data.fncTcId);
                }
            if (data.taskId !== null && data.taskId !== undefined) {
                this.getTask(data.taskId);
                }
            if (data.timeSheetId !== null && data.timeSheetId !== undefined) {
                this.getTimeSheet(data.timeSheetId);
                }
        },
        showStandardMenuView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;

        },
        showStandardMenuEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;

        },
        onRowSelected: function(items) {

        },
        onRowSelectedUserRq: function(items) {
            this.elements.userReqVisible = true;
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data);
                this.elements.viewMode = true;
                this.userRequirement.id = data.id;
                this.userRequirement.userRequirementId = data.userRequirementId;
                this.userRequirement.title = data.title;
                this.userRequirement.vhextAccountId = data.vhextAccountId;
                this.userRequirement.projectId = data.projectId;
                this.userRequirement.status = data.status;
                this.userRequirement.type = data.type;
                this.userRequirement.userRequirementBy = data.userRequirementBy;
                this.userRequirement.userRequirement = data.userRequirement;
                this.userRequirement.preparedDt = data.preparedDt;
                this.userRequirement.dueDate = data.dueDate;
                this.userRequirement.priority = data.priority;
                this.userRequirement.createdDt = data.createdDt;
                this.userRequirement.lastUpdatedDt = data.lastUpdatedDt;
            }
        }

    }
}
</script>	