<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Projects 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart :styles="myStyles" :chartdata="projectByStatusData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Clients
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Clients">{{ clientCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart v-if="clientCount" :styles="myStyles" :chartdata="clientByTypeData" :options="chartOptions" class="" :key="pieChartKey"/>
                                            <div v-else class="my-4 py-2">
                                                No Client Found
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),


            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            resourceCount: 0,
            employeeCount: 0,
            userCount: 0,
            clientCount: 0,

            empByMonthData: {},
            employeeUtilizationData: {},
            salaryByMonthData: {},
            resourcePoolData: {},
            employeeByDepartment: {},
            employeeAttendanceSummary: {},
            workTypeChartData: {},
            genderDistributionData: {},
            ageDistributionData: {},
            projectByStatusData: {},
            clientByTypeData: {},
            userByTypeData: {},

            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'top',
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            // fontSize: 20,
                            beginAtZero: true,
                        },
                        // stacked: true,
                        // categoryPercentage: 0.5,
                        // barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        attendeesPercentage() {
            if(this.employeeAttendanceSummary.empCount) {
                return parseFloat((((this.employeeAttendanceSummary.totalPresent+(this.employeeAttendanceSummary.totalHalfDayLeave/2))/this.employeeAttendanceSummary.empCount) * 100).toFixed(1));
            } else return 0;
        },
        myStyles() {
            return {
                height: '80px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        myStyles2() {
            return {
                height: '72px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
    },
    mounted() {
        this.getDashboardData();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/hrmdata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.employeeAttendanceSummary.length>0) {
                        this.employeeAttendanceSummary = result.employeeAttendanceSummary[0];
                    }

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.employeeByMonth != null) {
                        this.empByMonthData = {
                            labels: result.employeeByMonth.map(item => item.joiningMonth),
                            datasets: [{
                                    label: 'Employee Count',
                                    backgroundColor: '#2a93d5',
                                    data: result.employeeByMonth.map(item => item.empCount),
                                }
                            ]
                        }
                    }
                    if (result.salaryByMonth != null) {
                        this.salaryByMonthData = {
                            labels: result.salaryByMonth.map(item => item.dateString),
                            datasets: [{
                                    label: 'Salary',
                                    backgroundColor: '#2a93d5',
                                    data: result.salaryByMonth.map(item => item.moneyValue),
                                }
                            ]
                        }

                    }
                    if (result.empUtilizationByDepartment != null) {
                        this.employeeUtilizationData = {
                            labels: result.empUtilizationByDepartment.map(item => item.department),
                            datasets: [{
                                    label: 'Average Utilization',
                                    backgroundColor: '#2a93d5',
                                    data: result.empUtilizationByDepartment.map(item => item.averageEmpUtilization),
                                }
                            ]
                        }
                    }
                    this.resourcePoolData = {
                        labels: ['Others','Employees'],
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.resourcePoolData.map(item => {
                                this.resourceCount += Number(item.resourceCount);
                                return item.resourceCount;
                            })
                        }]
                    }
                    this.employeeByDepartment = {
                        labels: result.employeeByDepartment.map(item => item.department),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.employeeByDepartment.map(item => {
                                this.employeeCount += Number(item.empCount);
                                return item.empCount;
                            })
                        }]
                    }
                    this.projectByStatusData = {
                        labels: result.projectByStatus.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.projectByStatus.map((item) => {
                                this.projectCount += Number(item.empCount);
                                return item.empCount;
                            })
                        }]
                    }
                    this.userByTypeData = {
                        labels: result.userByType.map(item => item.type),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a', '#4dcac9', '#cac04d'],
                            data: result.userByType.map(item => {
                                this.userCount += Number(item.userCount);
                                return item.userCount;
                            })
                        }]
                    }
                    this.clientByTypeData = {
                        labels: result.clientByType.map(item => item.type),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.clientByType.map(item => {
                                this.clientCount += Number(item.clientCount);
                                return item.clientCount;
                            })
                        }]
                    }
                        console.log(result.workTypeData)
                    this.workTypeChartData = {
                        labels: result.workTypeData.map(item => item.workType),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.workTypeData.map(item => item.resourceCount)
                        }]
                    }

                    this.genderDistributionData = {
                        labels: result.genderDistributionData.map(item => item.gender),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.genderDistributionData.map(item => item.empCount)
                        }]
                    }
                    this.ageDistributionData = {
                        labels: ['Under 30', '31 - 50', 'Above 50' ],
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.ageDistributionData.map(item => item.age),
                        }]
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
    }
}
</script>