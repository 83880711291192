<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <b-table show-empty small outlined stacked="md"  sort-icon-left :items="projectTeams" :fields="projectTeamFields" responsive="sm"  :current-page="currentPage" @filtered="onFiltered" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >
                <!-- <template v-slot:cell(title)="data">
                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                </template>
                <template v-slot:cell(description)="data">
                    <span class="tooltiptext" href="javascript:" v-html="data.value"></span>
                </template> -->
                <template v-slot:empty="scope">
                    <div class="text-center">No Record found</div>
                </template>
                <template v-slot:emptyfiltered="scope">
                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                </template>
            </b-table>
            <b-pagination v-if="projectTeams.length!=0" pills v-model="currentPage" :total-rows="totalRows" first-number last-number :per-page="perPage" align="fill" size="sm" class="my-0 float-right"></b-pagination>
            <div class="float-left">
                <div class="dataTables_length">
                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
        <!-- <IssueDetailPopup v-if="showDialog" ref="issueDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
// import IssueDetailPopup from '@/components/popup/itemdetail/IssueDetailPopup.vue';
export default {
    components: {
        // IssueDetailPopup,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            projectTeams: [],
            projectTeamFields: [
                { key: 'projectTeamId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'memberName', label: 'Member Name', sortable: true, sortDirection: 'desc' },
                { key: 'resourceType', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'requiredFrom', label: 'Required From', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'requiredTill', label: 'Required Till', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
            ],

            detailViewData: {},
            referencePopupId: 0,
            attachmentData: {},

            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    methods: {
        getErdDetailList: function(ermId) {
            if (ermId) {
                let data = {
                    entityRecordMasterId: ermId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/member/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.projectTeams = result.projectTeamMemberList;
                        this.totalRows = this.projectTeams.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                    })
            }
            else {
                this.projectTeams = [];
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        // viewItemDetails(data) {
        //     this.detailViewData = data;
        //     this.referencePopupId = data.id;
        //     this.attachmentData.refEntity = this.entity;
        //     this.attachmentData.refRecordId = data.id;
        //     this.showDialog= true;
        //     this.$nextTick(()=> {
        //         this.$refs.issueDetailPopupRef.getDetails(this.referencePopupId, null);
        //     })
        // },
        // closeDialog: function() {
        //     this.showDialog = false;
        // },
    }
}
</script>