<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc title="Employee Position Change" no-enforce-focus @hide="closeDialog" :id="id" ref="jobPositionChangePopupRef" size="lg" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <!-- <b-card no-body class="mb-2"> -->
                        <!-- <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-empChange>
                                <span>
                                    <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Employment Change
                                </span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-empChange" accordion="my-accordion-empChange" v-model="elements.empHistoryVisible" role="tabpanel">
                            <b-card-body class="p-3 mb-2"> -->
                                <div class="row mb-2">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="designationIdEmp">Designation</label>
                                            <select v-model="employmentHistory.designationId" class="form-control" id="designationIdEmp" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in designations" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="departmentIdEmp">Department</label>
                                            <select v-model="employmentHistory.departmentId" class="form-control" id="departmentIdEmp" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="jobCategoryIdEmp">Job Category</label>
                                            <select v-model="employmentHistory.jobCategoryId" class="form-control" id="jobCategoryIdEmp" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="wefDate">Effective From</label>
                                            <input type="date" v-model="employmentHistory.wefDate" class="form-control" id="wefDate">
                                        </div>
                                    </div>                                    
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Salary (per annum)</label>
                                            <VhextMoneyControl :value="Math.abs(Number(employmentHistory.salary))" @money-input="moneyControlInput" />
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Hourly Salary</label>
                                            <VhextMoneyControl :value="Math.abs(Number(employmentHistory.hourlySalary))" @money-input="moneyControlInput" />
                                        </div>
                                    </div>                                    

                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="reportingToId">Reporting To</label>
                                            <select v-model="employmentHistory.reportingToId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="employmentChangeById">Emp. Position Responsibility</label>
                                            <select v-model="employmentHistory.employmentChangeById" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>                                    
                                    <div class="col-12">
                                        <div class="form-group">
                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                        </div>
                                    </div>
                                </div>
                                <!-- <button type="submit" @click="addEmploymentHistory" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="clearEmpHistory" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button> -->
                            <!-- </b-card-body>
                        </b-collapse> -->
                    <!-- </b-card> -->
                </b-container>
                <!-- <b-container fluid>
                    <div class="row mt-2">
                        <div class="col-12 mt-2">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-left">
                                        <div class="dataTables_length">
                                            <label class="d-block accordion-title" for="statusId">Transaction History</label>
                                        </div>
                                    </div>
                                    <div class="float-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" outlined stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="serviceHistoryList" :fields="serviceHistoryFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)">
                                        <i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i>
                                    </a>
                                    <a href="javascript:" title="Delete" @click="deleteEmploymentHistory(data.item.id)"  v-b-modal.deleteEmploymentHistory><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Transactions found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="serviceHistoryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </b-container> -->
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <b-button variant="outline-primary" :disabled="showOverlay" @click="close()" size="sm" class="float-right" >
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        Save
                    </b-button>
                </div>
            </template>
        </b-modal>
        <!-- <DeleteComponent id="deleteEmploymentHistory" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Service History ?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    props: {
        employeeId: [String, Number],
        employeeData: Object,
        id: {type:String, default:'jobPositionChangePopup'},
    },
    components: {
        AttachmentComponent,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                visible: false,
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'employment_history',

            employmentHistory: {
                id: '',
                employmentHistoryId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeeId: '',
                departmentId: '',
                designationId: '',
                jobCategoryId: '',
                salary: '',
                hourlySalary: '',
                reportingToId: '',
                employmentChangeById: localStorage.getItem("userId"),
                wefDate: '',
                createdById: localStorage.getItem("userId"),
            },

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],

            departments: [],
            designations: [],
            jobCategories: [],
            users: [],
            
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.setEmployeeData();
        this.lookupData();
    },
    validations: {
        // employmentHistory: {
        //     title: {
        //         required,
        //         minLength: minLength(2),
        //         maxLength: maxLength(100)
        //     },
        // }
    },
    methods: {
        // getEmploymentHistory: function() {
        //     if (this.serviceUtilId) {
        //         let data = {
        //             serviceUtilId: this.serviceUtilId,
        //             vhextAccountId: this.vhextAccountId,
        //         }
        //         console.log(data);
        //         this.showOverlay = true;
        //         axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/list', data)
        //             .then((response) => {
        //                 this.showOverlay = false;
        //                 let result = response.data;
        //                 this.serviceHistoryList = result.serviceHistoryList;
        //                 this.totalRows = this.serviceHistoryList.length;
        //                 console.log(result);
        //             })
        //             .catch(error => {
        //                 this.showOverlay = false;
        //                 iziToast.error({
        //                    title: 'Error',
        //                    message: 'Error',
        //                    position: 'topRight'
        //                });
        //             })
        //     }
        // },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.departments = result.departmentLookup;
                    this.designations = result.designationLookup;
                    this.jobCategories = result.customCategoryLookup;
                    this.users = result.userLookup;

                }).catch((error)=> this.showOverlay = false);
        },
        async saveData() {
            this.employmentHistory.employeeId = this.employeeId;
            this.showOverlay = true;
            let result;
            if (this.elements.editMode) {
                result = await this.updateHistory();
                await this.$refs.attachmentControl.saveAttachment(this.entity, this.employmentHistory.id);
            } else {
                result = await this.saveHistory();
                await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
            }

            this.showOverlay = false;
            if (result.data.status == "CREATED") {
                iziToast.success({
                    title: 'Success',
                    message: result.data.message,
                    position: 'topRight'
                });
                this.showOverlay = false;
                // this.getEmploymentHistory();
                this.$emit('refreshData');
                this.$bvModal.hide(this.id);
                this.clearData();
            }
        },
        saveHistory: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/add', this.employmentHistory)
        },
        updateHistory: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/update', this.employmentHistory)
        },
        deleteEmploymentHistory: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.empHistoryVisible = true;
            this.employmentHistory.id = '';
            this.employmentHistory.employmentHistoryId = '';
            this.employmentHistory.employeeId = '';
            this.employmentHistory.departmentId = '';
            this.employmentHistory.designationId = '';
            this.employmentHistory.jobCategoryId = '';
            this.employmentHistory.salary = '';
            this.employmentHistory.hourlySalary = '';
            this.employmentHistory.reportingToId = '';
            this.employmentHistory.employmentChangeById = localStorage.getItem("userId");
            this.employmentHistory.wefDate = '';
            this.$refs.attachmentControl.clearAttachment();
            this.attachments = [];
            // this.$v.employmentHistory.$reset();
        },
        setEmployeeData: function(data) {
            if (this.employeeData) {
                this.employmentHistory.employeeId = this.employeeData.employeeId;
                this.employmentHistory.designationId = this.employeeData.designationId;
                this.employmentHistory.departmentId = this.employeeData.departmentId;
                this.employmentHistory.jobCategoryId = this.employeeData.jobCategoryId;
                this.employmentHistory.wefDate = this.employeeData.wefDate;
                this.employmentHistory.salary = this.employeeData.salary;
                this.employmentHistory.hourlySalary = this.employeeData.hourlySalary;
                this.employmentHistory.reportingToId = this.employeeData.reportingToId;
                // this.$nextTick(() => {
                //     this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
                // })
            }
        },
        // onFiltered(filteredItems) {
        //     // Trigger pagination to update the number of buttons/pages due to filtering
        //     this.totalRows = filteredItems.length
        //     this.currentPage = 1
        // },
        moneyControlInput: function(value) {
            this.employmentHistory.salary = Math.abs(value);
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        },
    }
}
</script>