<template>
    <div class="type">
        <b-modal scrollable ref="typeModal" size="md" :id="id" centered modal-class="typeModal" :title="typeLookupTitle" @hide="closeDialog" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.typeEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Type</span>
                                <span v-if="elements.typeEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.typeVisible" role="tabpanel">
                            <b-card-body class="p-0">
                                <div class="row">
                                    <b-input-group class="form-group py-2">
                                        <div class="align-middle float-left my-auto ml-4 mr-0" cols="2">Type</div>
                                        <b-col cols="9" class="pr-1">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.customType.type.$error }">
                                                <b-form-input id="title" v-model="customType.type" placeholder="Enter Type"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.customType.type.$error && !$v.customType.type.required" class="text-danger">Please Enter Type</label>
                                        </b-col>
                                        <div class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveCustomTypeData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearTypeData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </b-input-group>
                                </div>
                                <!-- <div class="col-12">
                                <div class="form-group input-group-label">
                                    <b-input-group prepend="Type" class="mb-2">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.customType.type.$error }">
                                            <b-form-input v-model="customType.type" placeholder="Enter Type"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <b-input-group-append>
                                            <div class="more-btns ml-3">
                                                <button type="submit" title="Save" @click="saveCustomTypeData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearTypeData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </b-input-group-append>
                                        <label v-if="$v.customType.type.$error && !$v.customType.type.required" class="text-danger">Please enter type</label>
                                    </b-input-group>
                                </div>
                            </div> -->
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="row input-group-label">
                                <div class="col-6">
                                    <label class="mb-0 mt-2 fs-14">Categories</label>
                                </div>
                                <div class="col-6 dataTables_filter text-right">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>                

                    <b-table show-empty small outlined head-variant=:primary sticky-header="30vh" :busy="isBusy" sort-icon-left :items="customTypes" :fields="customTypeFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>                        
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showTypeEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="removeCustomType(data.item.id)" v-b-modal.removeCustomType><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Types found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
<!--                     <b-pagination v-if="customTypes && customTypes.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeCustomType" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Type ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'typeModal' }, 
        projectId: { type: [String, Number], default: null },    
        projectTypeId: { type: [String, Number], default: null },
        entity: String,
        typeLookupTitle: String
    },
    components: {

    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            // projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            elements: {
                typeVisible: false,
                typeEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            customType: {
                id: '',
                customTypeLookupId: '',
                entity: '',
                active: true,
                type: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                entityId: '',
                userId: '',
                createdById: localStorage.getItem("userId"),
            },
            customTypes: [],
            customTypeFields: [
                { key: 'index', label: 'SN', },
                // { key: 'customTypeLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        customType: {
            type: {
                required
            }
        }
    },
    watch: {
        'entity': {
            handler: function(val1, val2) {
                this.getCustomTypeLookups();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.getCustomTypeLookups();
    },
    methods: {
        getCustomTypeLookups: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity
            }
            console.log(data);
            if (this.entity != '') {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        console.log(result);
                        this.customTypes = result.customTypeLookupList;         
                        if (this.customTypes != null)
                            this.totalRows = this.customTypes.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        // getCustomTypeLookup: function(entity) {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectId: this.projectId,
        //         projectTypeId: this.projectTypeId,
        //         entity: entity
        //     }
        //     console.log(data);
        //     if (this.entity != '') {
        //         axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/list', data)
        //             .then((response) => {
        //                 let result = response.data;
        //                 console.log(result);
        //                 this.customTypes = result.customTypeLookupList;         
        //                 if (this.customTypes != null)
        //                     this.totalRows = this.customTypes.length;
        //             })
        //     }
        // },
        saveCustomTypeData: function() {
            this.$v.customType.$touch();
            if (!this.$v.customType.$invalid) {
                if (!this.matchTextValExists(this.customType.type, this.customType.id)) {
                    if (this.elements.typeEdit) {
                        this.updateCustomTypeLookup();
                    } else {
                        this.addCustomTypeLookup();
                    }
                }else {
                    iziToast.info({
                        message: 'Type already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        addCustomTypeLookup: function() {
            this.customType.vhextAccountId = this.vhextAccountId;
            this.customType.projectId = this.projectId;
            this.customType.projectTypeId = this.projectTypeId;
            this.customType.entity = this.entity;
            console.log(this.customType);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/add', this.customType)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomTypeLookups();
                        this.clearTypeData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateCustomTypeLookup: function() {
            console.log(this.customType)
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/update', this.customType)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomTypeLookups();
                        this.clearTypeData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeCustomType: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomTypeLookups();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearTypeData: function() {
            this.customType.id = '';
            this.customType.type = '';
            this.customType.customTypeLookupId = '';
            this.customType.active = true;
            this.$v.customType.$reset();
            this.elements.typeEdit = false;
        },
        showTypeEdit: function(items) {
            let data = items;
            this.elements.typeVisible = true;
            this.elements.typeEdit = true;
            this.customType.customTypeLookupId = data.customTypeLookupId;
            this.customType.active = data.active;
            this.customType.type = data.type;
            this.customType.typeName = data.typeName;
            this.customType.vhextAccountId = data.vhextAccountId;
            this.customType.projectId = data.projectId;
            this.customType.projectTypeId = data.projectTypeId;
            this.customType.entityId = data.entityId;
            this.customType.id = data.id;
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.customType.id = item.id;
                this.customType.active = 0;
                this.customType.entity = 'custom_type_lookup';
                this.updateActivationState();
            } else {
                this.customType.id = item.id;
                this.customType.active = 1;
                this.customType.entity = 'custom_type_lookup';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.customType)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomTypeLookups();
                        this.clearTypeData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.customTypes.map(data => {
                if (textVal && data.type && data.id !== id) {
                    if (data.type.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },

        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>