<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Tax Slab Setting</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Tax Slab</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-sm-6 col-md-8">
                                                        <div class="form-group">
                                                            <label for="taxSlabSetting">Configuration Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.taxSlabSetting.title.$error }">
                                                                <input type="text" class="form-control" v-model="taxSlabSetting.title" id="taxSlabSetting" :class="{ 'is-invalid': $v.taxSlabSetting.title.$error }" placeholder="Enter Tax Slab">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.taxSlabSetting.title.$error && !$v.taxSlabSetting.title.minLength" class="text-danger">Title must have at least {{$v.taxSlabSetting.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.taxSlabSetting.title.$error && !$v.taxSlabSetting.title.required" class="text-danger">Please enter Title title</label>
                                                            <label v-if="$v.taxSlabSetting.title.$error && !$v.taxSlabSetting.title.maxLength" class="text-danger">Title must not exceed {{$v.taxSlabSetting.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-4">
                                                        <div class="form-group">
                                                            <label for="taxConfigId">Tax Config</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.taxSlabSetting.taxConfigId.$error }">                                                            
                                                                <select v-model="taxSlabSetting.taxConfigId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in taxConfigLookup" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.taxSlabSetting.taxConfigId.$error && !$v.taxSlabSetting.taxConfigId.required" class="text-danger">Please select Tax Config</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="minAmount">Minimum Amount</label>
                                                            <input type="number" class="form-control" v-model="taxSlabSetting.minAmount" id="minAmount" >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="maxAmount">Maximum Amount</label>
                                                            <input type="number" class="form-control" v-model="taxSlabSetting.maxAmount" id="maxAmount" >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="minAge">Minimum Age</label>
                                                            <input type="number" class="form-control" v-model="taxSlabSetting.minAge" id="minAge" >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-3">
                                                        <div class="form-group">
                                                            <label for="maxAge">Maximum Age</label>
                                                            <input type="number" class="form-control" v-model="taxSlabSetting.maxAge" id="maxAge" >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-4">
                                                        <div class="form-group">
                                                            <label for="taxPercentage">Tax Percentage</label>
                                                            <input type="number" class="form-control" v-model="taxSlabSetting.taxPercentage" id="taxPercentage" >
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-md-4">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="taxSlabSetting.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="budget">Description</label>
                                                            <textarea v-model="taxSlabSetting.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveTaxSlabSetting" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="taxSlabSettingTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="taxSlabSettingList"  @row-selected="onRowSelected" :fields="taxSlabSettingFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(minAmount)="data">
                                    <span v-if="!data.item.minAmount && data.item.maxAmount">
                                        upto {{currencySymbol}}{{ data.item.maxAmount }}
                                    </span>
                                    <span v-else-if="data.item.minAmount && !data.item.maxAmount">above {{currencySymbol}}{{ data.item.minAmount }}</span>
                                    <span v-else-if="data.item.minAmount && data.item.maxAmount">{{currencySymbol}}{{ data.item.minAmount }} to {{currencySymbol}}{{ data.item.maxAmount }}</span>
                                </template>
                                <template v-slot:cell(minAge)="data">
                                    <span v-if="!data.item.minAge && data.item.maxAge">
                                        upto {{ data.item.maxAge }}
                                    </span>
                                    <span v-else-if="data.item.minAge && !data.item.maxAge">above {{ data.item.minAge }}</span>
                                    <span v-else-if="data.item.minAge && data.item.maxAge">{{ data.item.minAge }} to {{ data.item.maxAge }}</span>
                                </template>
                                <template v-slot:cell(taxPercentage)="data">
                                    <span v-if="data.value">{{ data.value }}%</span>
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteTaxSlabSetting(data.item.id)" data-toggle="modal" data-target="#deleteTaxSlabSetting"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Tax Slabs found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="taxSlabSettingList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{taxSlabSettingDetail.taxSlabSettingId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateTaxSlabSetting" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="taxSlabSettingDet">Tax Slab</label>
                                                    <input type="text" class="form-control" id="taxSlabSettingDet" :disabled="!elements.editMode" v-model="taxSlabSettingDetail.title">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="taxConfigId">Tax Config</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.taxSlabSettingDetail.taxConfigId.$error }">
                                                        <select v-model="taxSlabSettingDetail.taxConfigId" class="form-control" :disabled="!elements.editMode">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in taxConfigLookup" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.taxSlabSettingDetail.taxConfigId.$error && !$v.taxSlabSettingDetail.taxConfigId.required" class="text-danger">Please select Tax Config</label>
                                                </div>
                                            </div>  
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="minAmountDet">Minimum Amount</label>
                                                    <input type="number" class="form-control" v-model="taxSlabSettingDetail.minAmount" :disabled="!elements.editMode" id="minAmountDet" >
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="maxAmountDet">Maximum Amount</label>
                                                    <input type="number" class="form-control" v-model="taxSlabSettingDetail.maxAmount" :disabled="!elements.editMode" id="maxAmountDet" >
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="minAgeDet">Minimum Age</label>
                                                    <input type="number" class="form-control" v-model="taxSlabSettingDetail.minAge" :disabled="!elements.editMode" id="minAgeDet" >
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="maxAgeDet">Maximum Age</label>
                                                    <input type="number" class="form-control" v-model="taxSlabSettingDetail.maxAge" :disabled="!elements.editMode" id="maxAgeDet" >
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="taxPercentageDet">Tax Percentage</label>
                                                    <input type="number" class="form-control" v-model="taxSlabSettingDetail.taxPercentage" :disabled="!elements.editMode" id="taxPercentageDet" >
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="taxSlabSettingDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="taxSlabSettingDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="taxSlabSettingDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteTaxSlabSetting" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Tax Slab?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            showDetOverlay: false,
            isBusy: false,

            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),
            entity: 'tax_slab_setting',

            taxSlabSetting: {
                id: '',
                taxSlabSettingId: '',
                taxConfigId: '',
                title: '',
                minAmount: '',
                maxAmount: '',
                minAge: '',
                maxAge: '',
                taxPercentage: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            taxSlabSettingDetail: {
                id: '',
                taxSlabSettingId: '',
                taxConfigId: '',
                title: '',
                minAmount: '',
                maxAmount: '',
                minAge: '',
                maxAge: '',
                taxPercentage: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },

            taxConfigLookup: [],

            taxSlabSettingList: [],
            taxSlabSettingFields: [
                { key: 'id', label: 'PK ID', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Tax Slab Title', sortable: true, sortDirection: 'desc' },
                { key: 'minAge', label: 'Age Group', sortable: true, sortDirection: 'desc' },
                { key: 'minAmount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'taxPercentage', label: 'Tax Percentage', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getTaxSlabSettings();
        this.lookupData();
    },
    validations: {
        taxSlabSetting: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            taxConfigId: {
                required,
            },
        },
        taxSlabSettingDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            taxConfigId: {
                required,
            },
        }
    },
    methods: {
        getTaxSlabSettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/taxslabsetting/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.taxSlabSettingList && result.taxSlabSettingList.length > 0) {
                        this.taxSlabSettingList = result.taxSlabSettingList;
                        this.$nextTick(() => {
                            this.$refs.taxSlabSettingTable.selectRow(0);
                        })
                        this.totalRows = this.taxSlabSettingList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/finance/taxslabsetting/lookup/all')
                .then((response) => {
                    let result = response.data;
                    this.taxConfigLookup = result.taxConfigLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveTaxSlabSetting: function() {
            this.$v.taxSlabSetting.$touch();
            if (!this.$v.taxSlabSetting.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/taxslabsetting/add', this.taxSlabSetting)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getTaxSlabSettings();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateTaxSlabSetting: function() {
            this.$v.taxSlabSettingDetail.$touch();
            if (!this.$v.taxSlabSettingDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/taxslabsetting/update', this.taxSlabSettingDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getTaxSlabSettings();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewTaxSlabSetting: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/finance/taxslabsetting/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let taxSlabSettingDetail = result.taxSlabSetting;
                    if (taxSlabSettingDetail) {
                        this.taxSlabSettingDetail.id = taxSlabSettingDetail.id;
                        this.taxSlabSettingDetail.taxSlabSettingId = taxSlabSettingDetail.taxSlabSettingId;
                        this.taxSlabSettingDetail.taxConfigId = taxSlabSettingDetail.taxConfigId;
                        this.taxSlabSettingDetail.title = taxSlabSettingDetail.title;
                        this.taxSlabSettingDetail.minAmount = taxSlabSettingDetail.minAmount;
                        this.taxSlabSettingDetail.maxAmount = taxSlabSettingDetail.maxAmount;
                        this.taxSlabSettingDetail.minAge = taxSlabSettingDetail.minAge;
                        this.taxSlabSettingDetail.maxAge = taxSlabSettingDetail.maxAge;
                        this.taxSlabSettingDetail.taxPercentage = taxSlabSettingDetail.taxPercentage;
                        this.taxSlabSettingDetail.active = taxSlabSettingDetail.active;
                        this.taxSlabSettingDetail.createdById = taxSlabSettingDetail.createdById;
                        this.taxSlabSettingDetail.createdDt = taxSlabSettingDetail.createdDt;
                        this.taxSlabSettingDetail.lastUpdatedDt = taxSlabSettingDetail.lastUpdatedDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewTaxSlabSetting(this.taxSlabSettingDetail.id);
        },
        deleteTaxSlabSetting: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/taxslabsetting/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTaxSlabSettings();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.taxSlabSetting.id = '';
            this.taxSlabSetting.taxSlabSettingId = '';
            this.taxSlabSetting.taxConfigId = '';
            this.taxSlabSetting.title = '';
            this.taxSlabSetting.minAmount = '';
            this.taxSlabSetting.maxAmount = '';
            this.taxSlabSetting.minAge = '';
            this.taxSlabSetting.maxAge = '';
            this.taxSlabSetting.taxPercentage = '';
            this.taxSlabSetting.active = true;
            this.taxSlabSetting.createdById = localStorage.getItem("userId");
            this.$v.taxSlabSetting.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.taxSlabSettingDetail.id = data.id;
            this.taxSlabSettingDetail.taxSlabSettingId = data.taxSlabSettingId;
            this.taxSlabSettingDetail.taxConfigId = data.taxConfigId;
            this.taxSlabSettingDetail.title = data.title;
            this.taxSlabSettingDetail.minAmount = data.minAmount;
            this.taxSlabSettingDetail.maxAmount = data.maxAmount;
            this.taxSlabSettingDetail.minAge = data.minAge;
            this.taxSlabSettingDetail.maxAge = data.maxAge;
            this.taxSlabSettingDetail.taxPercentage = data.taxPercentage;
            this.taxSlabSettingDetail.active = data.active;
            this.taxSlabSettingDetail.createdById = data.createdById;
            this.taxSlabSettingDetail.createdDt = data.createdDt;
            this.taxSlabSettingDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.taxSlabSettingDetail.id = data.id;
                this.taxSlabSettingDetail.taxSlabSettingId = data.taxSlabSettingId;
                this.taxSlabSettingDetail.taxConfigId = data.taxConfigId;
                this.taxSlabSettingDetail.title = data.title;
                this.taxSlabSettingDetail.minAmount = data.minAmount;
                this.taxSlabSettingDetail.maxAmount = data.maxAmount;
                this.taxSlabSettingDetail.minAge = data.minAge;
                this.taxSlabSettingDetail.maxAge = data.maxAge;
                this.taxSlabSettingDetail.taxPercentage = data.taxPercentage;
                this.taxSlabSettingDetail.active = data.active;
                this.taxSlabSettingDetail.createdById = data.createdById;
                this.taxSlabSettingDetail.createdDt = data.createdDt;
                this.taxSlabSettingDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.taxSlabSetting.entity = this.entity;
                this.taxSlabSetting.id = item.id;
                this.taxSlabSetting.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.taxSlabSetting.entity = this.entity;
                this.taxSlabSetting.id = item.id;
                this.taxSlabSetting.active = 1;

                this.updateActivationState();
            }
            console.log(this.taxSlabSetting.id, this.taxSlabSetting.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.taxSlabSetting)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.taxSlabSetting.active = true;
                    this.getTaxSlabSettings();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
    }
}
</script>
