import TestCaseMaster from '@/views/test/TestCaseMaster.vue'
import TestCaseMasterList from '@/views/test/TestCaseMasterList.vue'
import TestCase from '@/views/test/TestCase.vue'

import TestMasterList from '@/views/test/TestMasterList.vue'
import Test from '@/views/test/Test.vue'


import TestResultMaster from '@/views/test/TestResultMaster.vue'
import TestResultMasterList from '@/views/test/TestResultMasterList.vue'
import TestResult from '@/views/test/TestResult.vue'

import ErrorReport from '@/views/test/ErrorReport.vue'
import ErrorReportMaster from '@/views/test/ErrorReportMaster.vue'
import ErrorReportMasterList from '@/views/test/ErrorReportMasterList.vue'


export default [{
        path: '/tscmas',
        name: 'tscmas',
        component: TestCaseMaster,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/tscmas/list',
        name: 'tscmaslist',
        component: TestCaseMasterList,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/testcase/:ermId',
        name: 'testcase',
        component: TestCase,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },

    {
        path: '/tstmas/list',
        name: 'tstmaslist',
        component: TestMasterList,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/test/:ermId',
        name: 'test',
        component: Test,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },

    {
        path: '/tsrmas',
        name: 'tsrmas',
        component: TestResultMaster,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/tsrmas/list',
        name: 'tsrmaslist',
        component: TestResultMasterList,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/testresult/:ermId',
        name: 'testresult',
        component: TestResult,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
         path: '/errorreport/:ermId',
        name: 'errorreport',
        component: ErrorReport,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/errmas',
        name: 'errmas',
        component: ErrorReportMaster,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/errmas/list',
        name: 'errmaslist',
        component: ErrorReportMasterList,
        meta: { accessedby: 'PRM|CLI|EMP|TMB|VSA' }
    },
];