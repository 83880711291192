<template>
    <div class="fixed-inner-header">
        <div class="container-fluid action-width">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-1 col-sm-1 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">Action Items</h4>
                        </div>
                        <div class="col-lg-3 col-sm-3 mt-1 text-left">
                            <div class="row mt-0 mb-0 text-center px-0">
                                <div class="col pull-left px-0">
                                    <a href="javascript:" @click="changePage('prevPage',daysToShow);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                                </div>
                                <div class="col pull-left px-0">
                                    <a href="javascript:" @click="changePage('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                </div>
                                <div class="col-6 px-0 mt-1">
                                    <h6 class="mt-0" v-if="currentStartDate">
                                        {{ months[currentStartDate.getMonth()].slice(0, 3) }}
                                        <span v-if="currentStartDate.getFullYear() !== currentEndDate.getFullYear()">
                                            - {{ currentStartDate.getFullYear() }}
                                        </span>
                                        <span v-if="months[currentStartDate.getMonth()] !== months[currentEndDate.getMonth()]">
                                            / {{ months[currentEndDate.getMonth()].slice(0, 3) }}
                                        </span>
                                        - {{ currentEndDate.getFullYear() }}
                                    </h6>
                                </div>
                                <div class="col pull-right px-0">
                                    <a href="javascript:" @click="changePage('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                </div>
                                <div class="col pull-right px-0">
                                    <a href="javascript:" @click="changePage('nextPage',daysToShow);"><i class="fa fa-2x fa-angle-double-right action-link mt-0"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-1">
                            <div class="float-left action-item-page">
                                <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer mr-1" title="Jump to Date">
                                    <i class="fa fa-calendar text-light"></i>
                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownCal">
                                        <b-calendar v-model="selectedDate" today-variant="primary" selected-variant="secondary" @context="dateList(selectedDate)">
                                        </b-calendar>
                                    </div>
                                </span>
                                <span @click="daysToShow = 1" :class="daysToShow == 1 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="Today">1</span>
                                <span @click="daysToShow = 3" :class="daysToShow == 3 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="3 Days">3</span>
                                <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">5</span>
                                <span @click="daysToShow = 7" :class="daysToShow == 7 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer" title="7 Days">7</span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-4 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block text-center" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0  mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="actionItems = actionItemsAll; activeFilter=null;" :class="{'active-item' : !activeFilter}"><i class="fa fa-inbox pr-1 action-link"></i>All</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Task" @click="filterItems('TASK')" :class="{'active-item' : activeFilter == 'TASK'}"><i class="fa fa-tasks pr-1 action-link"></i>Task</a>
                                    </li>
 
                                    <!-- <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Employees" @click="filterItems('EMP')" :class="{'active-item' : activeFilter == 'EMP'}"><i class="fa fa-tasks pr-1 action-link"></i>EMP</a>
                                    </li> -->
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Meeting / Event" @click="filterItems('MTG')" :class="{'active-item' : activeFilter == 'MTG'}"> <i class="fa fa-calendar-check-o pr-1 action-link"></i>MTG</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Email" @click="filterItems('EMAIL')" :class="{'active-item' : activeFilter == 'EMAIL'}"><i class="fa fa-envelope pr-1 action-link"></i>Email</a>
                                    </li> -->
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Review" @click="filterItems('REV')" :class="{'active-item' : activeFilter == 'REV'}"> <i class="fa fa-comments-o pr-1 action-link"></i>REV</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Approval" @click="filterItems('APV')" :class="{'active-item' : activeFilter == 'APV'}"><i class="fa fa-check pr-1 action-link"></i>APV</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Leave" @click="filterItems('LVE')" :class="{'active-item' : activeFilter == 'LVE'}"><i class="fa fa-calendar-o pr-1 action-link"></i>LVE</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item">
                                        <a href="javascript:" title="Filter Permission" @click="filterItems('PER')" :class="{'active-item' : activeFilter == 'PER'}"><i class="fa fa-clock-o pr-1 action-link"></i>PER</a>
                                    </li>  -->
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Copy" @click="filterItems('COPY')" :class="{'active-item' : activeFilter == 'COPY'}"><i class="fa fa-copy pr-1 action-link"></i>Copy</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item">
                                        <a href="javascript:" title="Asset" @click="filterItems('ASSET')" :class="{'active-item' : activeFilter == 'ASSET'}"><i class="fa fa-copy pr-1 action-link"></i>Asset</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Service" @click="filterItems('SERVICE')" :class="{'active-item' : activeFilter == 'SERVICE'}"><i class="fa fa-copy pr-1 action-link"></i>Service</a>
                                    </li> -->
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right pr-1 mr-0" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Requests List" class="action-link" @click="redirectToRequests"><i class="fa fa-th-list px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <!--             <div class="row mt-4 mb-3 text-center">
                <div class="col pull-left">
                    <a href="javascript:" @click="changePage('prevPage',daysToShow);"><i class="fa fa-3x fa-angle-double-left action-link"></i></a>
                </div>
                <div class="col pull-left">
                    <a href="javascript:" @click="changePage('prevPage',1);"><i class="fa fa-3x fa-angle-left action-link"></i></a>
                </div>
                <div class="col-6">
                    <h5 class="mt-2" v-if="currentStartDate">
                        {{ months[currentStartDate.getMonth()] }}
                        <span v-if="currentStartDate.getFullYear() !== currentEndDate.getFullYear()">
                            - {{ currentStartDate.getFullYear() }}
                        </span>
                        <span v-if="months[currentStartDate.getMonth()] !== months[currentEndDate.getMonth()]">
                            / {{ months[currentEndDate.getMonth()] }}
                        </span>
                        - {{ currentEndDate.getFullYear() }}
                    </h5>
                </div>
                <div class="col pull-right">
                    <a href="javascript:" title="Next Day" @click="changePage('nextPage',1);"><i class="fa fa-3x fa-angle-right action-link"></i></a>
                </div>
                <div class="col pull-right">
                    <a href="javascript:" title="Next Week" @click="changePage('nextPage',daysToShow);"><i class="fa fa-3x fa-angle-double-right action-link"></i></a>
                </div>
            </div> -->
            <div class="row px-1">
                <div v-for="day,index in daysToShow" class="col p-0 ml-2 mr-1">
                    <div v-if="currentDateList.length > 0" :title="(currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)) ? 'Today' : null" class="card mb-2 b-radius-5 pointer" :class="{ 'disabled-weekEnd' : currentDateList[index].getDay() == 0 || currentDateList[index].getDay() == 6, 'active-day' : currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)}" @click="dateList(currentDateList[index])">
                        <!-- <div v-if="daysToShow !== 1" class="card w-50 text-center mx-auto" title="Today" :class="{ 'active-day' : currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)}"></div> -->
                        <div class="card-body py-2">
                            <div class="form-group mb-0">
                                <label v-if="currentDateList.length > 0" class="mt-1 mb-0 font-weight-semibold" for="title">{{ days[currentDateList[index].getDay()] }}                                    
                                </label>
                                <h6 v-if="currentDateList[index]" class="card-text font-weight-semibold mb-0 pull-right">
                                    {{ currentDateList[index].getDate() }}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <!-- <a href="javascript:" class="card">
                        <div class="card-body text-center p-2">
                            <h6 v-if="currentDateList[index]" class="card-text font-weight-semibold mb-0">
                                {{ days[currentDateList[index].getDay()] }}
                            </h6>
                            <p v-if="currentDateList[index]" class="card-text font-weight-semibold mb-0">
                                {{ currentDateList[index].getDate() }}
                            </p>
                        </div>
                    </a> -->
                    <div class="action-item-vl">
                        <div v-for="task in actionItems" v-if="new Date(task.actionItemDt).toString().substr(0, 10) == new Date(currentDateList[index]).toString().substr(0, 10)" class="row">
                            <template v-if="(task.actionItemSource == 'EMP')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" title="EMP" >
                                                <span class="action-template-title text-muted" title="EMP">{{ task.actionItemSource }}</span>
                                            </a>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Task')">{{ task.actionItemTitle }}</a></span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
<!--                                                 <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="Recruited Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="End Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Employee Added By : ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Employee Added By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Assigned To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Assigned To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="task.actionItemSource == 'ASSET' || task.actionItemSource == 'AST_EXP'">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" :title="titleToolTip(task.actionItemSource)" >
                                                <span class="action-template-title text-muted">{{ task.actionItemSource }}</span>
                                            </a>
                                            <span v-if="task.status" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task)">{{ task.actionItemTitle }}</a></span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="task.actionItemDt">
                                                    <div class="row">
                                                        <div v-if="task.actionItemDt" class="col-12 pr-0">
                                                            <label v-if="task.actionItemSource == 'ASSET'" title="Purchase Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                            <label v-else title="Asset Expiry Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                        </div>
                                                        <div v-if="task.actionItemSource == 'SERVICE' && task.actionItemDueDt" class="col-12 pr-0">
                                                            <label title="Service Expiry Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left">
                                                                <div v-if="task.actionItemBy">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Created By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Created By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'SERVICE' || task.actionItemSource == 'SRV_DUE' || task.actionItemSource == 'SRV_EXP')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" :title="titleToolTip(task.actionItemSource)" >
                                                <span class="action-template-title text-muted">{{ task.actionItemSource }}</span>
                                            </a>
                                            <span v-if="task.status" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task)">{{ task.actionItemTitle }}</a></span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="task.actionItemDt">
                                                    <div class="row">
                                                        <div v-if="task.actionItemDt" class="col-12 pr-0">
                                                            <label v-if="task.actionItemSource == 'SERVICE'" title="Service Start Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                            <label v-else-if="task.actionItemSource == 'SRV_DUE'" title="Service Due Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                            <label v-else title="Service Expiry Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                        </div>
                                                        <div v-if="task.actionItemSource == 'SERVICE' && task.actionItemDueDt" class="col-12 pr-0">
                                                            <label title="Service Expiry Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left">
                                                                <div v-if="task.actionItemBy">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Created By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Created By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'FINANCE')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" title="EMP" >
                                                <span class="action-template-title text-muted" title="EMP">{{ task.actionItemSource }}</span>
                                            </a>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Financial Transaction')">{{ task.actionItemTitle }}</a></span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <!-- <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="Recruited Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="End Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left">
                                                                <div>
                                                                    <!-- <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Transaction By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge> -->
                                                                    <b-badge class="task-badge" title="Transaction By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Assigned To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Assigned To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div> -->
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'REV')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">                                        
                                            <router-link title="View Report" :to="{ name: getReportUrlName(task.ermEntity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Review">{{ getStatusBySource(task.actionItemSource) }}</span>                                            
                                            </router-link>
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Review')">{{ getShortString(task.actionItemTitle,titleTextLength) }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <span title="Status" class="subtle">{{ task.status }}</span>                                                        
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="Review Request Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="Review Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Request By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Request By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Review By: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Review By"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 px-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'APV')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <router-link title="View Report" :to="{ name: getReportUrlName(task.ermEntity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Approval">{{ getStatusBySource(task.actionItemSource) }}</span>
                                            </router-link>
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Approval')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="task.status" class="row">
                                                    <div class="col-12">
                                                        <span title="Status" class="subtle">{{ task.status }}</span>
                                                    </div>
                                                </div>
                                                <div class="divider-line"></div>
                                                <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="Approval Request Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="Approval Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Requested By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Requested By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Approval By: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Approval By"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'SHARE')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <router-link title="View Report" :to="{ name: getMasterListUrlName(task.ermEntity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Share">{{ task.actionItemSource }}</span>
                                            </router-link>
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Share')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="(task.actionItemDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-12 pr-0">
                                                            <label title="Shared Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <!-- <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6">
                                                            <label title="End Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                            <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label>
                                                        </div> -->
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Shared By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Shared By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Shared To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Shared To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'TASK')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" title="Task" >
                                                <span class="action-template-title text-muted" title="Task">{{ task.actionItemSource }}</span>
                                            </a>
                                            <span v-if="task.status" title="Status" class="badge grey float-right">{{ task.status }}</span>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Task')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="Start Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateTimeAmPm }}</label>
                                                            <!-- <label title="Start Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatTimeAmPm }}</label> -->
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="End Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                            <!-- <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label> -->
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Assigned By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Assigned By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Assigned To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Assigned To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'MTG_P')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <router-link title="Task" :to="{ name: getReportUrlName(task.entity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Meeting Plan">MTG - P</span>
                                            </router-link>
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Meeting')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-7 pr-0">
                                                            <span title="Meeting Date" class="subtle">{{ task.actionItemDt | formatDate }}</span>
                                                        </div>
                                                        <div class="col-5 px-0">
                                                            <span title="Status" class="subtle">{Location}</span>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-6 pr-0">
                                                            <label title="Start Date" class="subtle m-0"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateYearCheck }}</label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6 pr-0">
                                                            <label title="End Date" class="subtle m-0"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateYearCheck }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div v-if="task.actionItemStartDt || task.actionItemDueDt">
                                                    <div class="row">
                                                        <div v-if="task.actionItemStartDt" class="col-6 pr-0">
                                                            <label title="Start Time" class="subtle m-0"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatTimeFromDt }}</label>
                                                        </div>
                                                        <div v-if="task.actionItemDueDt" class="col-6 pr-0">
                                                            <label title="End Time" class="subtle m-0"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeFromDt }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Organized By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Organized By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Participant: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Participant"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div> -->
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'MTG')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <router-link title="Task" :to="{ name: getReportUrlName(task.entity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Meeting">{{ task.actionItemSource }}</span>
                                            </router-link>
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Meeting')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-7 pr-0">
                                                            <span title="Meeting Date" class="subtle">{{ task.actionItemDt | formatDate }}</span>
                                                        </div>
                                                        <div class="col-5 px-0">
                                                            <span title="Status" class="subtle">{Location}</span>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <!-- <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-12 pr-0">
                                                            <label title="Start Time" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="End Time" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div> -->
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Organized By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Organized By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Participant: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Participant"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div> -->
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'EMAIL')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                             <router-link title="Task" :to="{ name: getReportUrlName(task.entity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Email">{{ task.actionItemSource }}</span>
                                            </router-link>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Email')">{{ task.actionItemTitle }}</a></span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="(task.actionItemDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-12">
                                                            <label title="Send Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                            <label title="Send Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatTimeAmPm }}</label>
                                                        </div>
                                                        <!-- <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6">
                                                            <label title="End Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                            <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label>
                                                        </div> -->
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Send By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Send By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Send To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Send To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'LVE')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <!-- <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" class="action-link" title="Task" > -->
                                                <span class="action-template-title text-muted" title="Leave Application">LEAVE</span>
                                            <!-- </a> -->
                                            <span v-if="task.status" title="Status" class="badge float-right" :class="getLeaveStatusVariant(task.status)">{{ task.status }}</span>
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'LVE')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="From Date" class="subtle">
                                                                <span>From Date: </span>
                                                                <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDate }}
                                                            </label>
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="Till Date" class="subtle">
                                                                <span>Till Date: </span>
                                                                <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Applied By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Applied By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Approval By: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Approval By"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'PER')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <!-- <a @click="setErdId(task.entityRecordId, task.entity, task.ermId)" class="action-link" title="Task" > -->
                                                <span class="action-template-title text-muted" title="PER">{{ task.actionItemSource }}</span>
                                            <!-- </a> -->
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Task')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div>
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <span title="Activity" class="subtle">{Activity}</span>
                                                        </div>
                                                        <div class="col-6">
                                                            <span title="Status" class="subtle">{{ task.status }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div v-if="(task.actionItemStartDt != null) || (task.actionItemDueDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemStartDt && task.actionItemStartDt != null)" class="col-12 pr-0">
                                                            <label title="Start Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemStartDt | formatDateTimeAmPm }}</label>
                                                            <!-- <label title="Start Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatTimeAmPm }}</label> -->
                                                        </div>
                                                        <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-12 pr-0">
                                                            <label title="End Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                            <!-- <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label> -->
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Assigned By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Assigned By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Assigned To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Assigned To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="(task.actionItemSource == 'COPY')">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0">
                                        <div class="action-card-body">
                                            <router-link title="View Report" :to="{ name: getReportUrlName(task.entity), params: { ermId: task.ermId }}">
                                                <span class="action-template-title text-muted" title="Share">{{ task.actionItemSource }}</span>
                                            </router-link>
                                            <!-- <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span> -->
                                            <div class="card-media-body-top">
                                                <span :title="task.actionItemTitle" class=""><a href="javascript:" @click="viewReference(task, 'Share')">{{ task.actionItemTitle }}</a></span>
                                                <!-- <span class="text-left"><a href="javascript:" @click="htmlData=task.description;htmlDetailViewTitle=task.title">{{ task.actionItemTitle }}</a></span> -->
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="">
                                                <div v-if="(task.actionItemDt != null)">
                                                    <div class="row">
                                                        <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-12 pr-0">
                                                            <label title="Copy Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                        </div>
                                                        <!-- <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6">
                                                            <label title="End Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                            <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label>
                                                        </div> -->
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Copied By: ${task.actionItemBy}`"> {{ task.actionItemBy | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Copied By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 p-0 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge v-if="daysToShow > 3" class="task-badge" :title="`Copied To: ${task.actionItemTo}`"> {{ task.actionItemTo | getAcronym }} </b-badge>
                                                                    <b-badge v-if="daysToShow <= 3" class="task-badge" title="Copied To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AssetDetailPopup id="assetDetailPopup" ref="assetDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" />
        <ServiceDetailPopup id="serviceDetailPopup" ref="serviceUtilDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" />
        <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <EmailDetailPopup ref="emailDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <FinTxnDetailPopup ref="finTxnDetailRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" />
        <ReviewApprovalDetailPopup ref="reviewApprovalDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <ShareDetailPopup ref="shareDetailPopupRef" :detailViewData="detailViewData" />
        <TaskDetailPopup ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <LeaveTimeDetailPopup ref="leaveTimeDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" />
        <MeetingPrepartionDetailPopup ref="meetingPrepartionDetailPopupRef" />
    </div>
</template>
<script>
import AssetDetailPopup from '@/components/popup/itemdetail/AssetDetailPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import EmailDetailPopup from '@/components/popup/itemdetail/EmailDetailPopup.vue';
import FinTxnDetailPopup from '@/components/popup/itemdetail/FinTxnDetailPopup.vue';
import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
import ReviewApprovalDetailPopup from '@/components/popup/itemdetail/ReviewApprovalDetailPopup.vue';
import ShareDetailPopup from '@/components/popup/itemdetail/ShareDetailPopup.vue';
import ServiceDetailPopup from '@/components/popup/itemdetail/ServiceDetailPopup.vue';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue';
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import moment from 'moment';
import redirectMixin from "@/mixins/redirect";
import enumMixin from "@/mixins/enummixin";
export default {
    components: {
        AssetDetailPopup,
        EmailDetailPopup,
        FinTxnDetailPopup,
        ReferenceDetailPopup,
        ReviewApprovalDetailPopup,
        ShareDetailPopup,
        ServiceDetailPopup,
        TaskDetailPopup,
        LeaveTimeDetailPopup,
        MeetingPrepartionDetailPopup
    },
    mixins: [redirectMixin, enumMixin],
    data() {
        return {
            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},
            attachmentData: {},

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            daysToShow: 7,
            selectedDate: null,
            activeFilter: null,
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            currentDateList: [],

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            actionItems: [],
        }
    },
    computed: {
        titleTextLength() {
            if (this.daysToShow>5) return 15;
            if (this.daysToShow>3) return 25;
            else return 200;
        }
    },
    mounted() {
        this.dateList();
        this.loadData();

        // To prevent default closing of calendar dropdown
        $('.dropdown-menu').click(function(e) {
            e.stopPropagation();
        });
    },
    methods: {
        loadData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                userId: localStorage.getItem("userId"),
                isArchive: false,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/actionitem/list', data)
                .then((response) => {
                    let result = response.data;
                    this.actionItemsAll = result.actionItemList;
                    this.actionItems = this.actionItemsAll;
                    console.log(result);
                })

        },
        dateList(selectedDate) {
            if (selectedDate) {
                this.currentStartDate = moment(selectedDate).toDate();
                this.daysToShow = 1;
            } else {

                /* to set Sunday as the first day */
                // this.currentStartDate = moment().startOf('week').toDate();

                /* to set Monday as the first day */
                this.currentStartDate = moment().startOf('isoweek').toDate();

                /* to set current day as the first day */
                // this.currentStartDate = new Date();
            }
            var endDate = new Date();
            endDate.setDate(this.currentStartDate.getDate() + 6);

            this.currentEndDate = endDate;
            this.currentDateList = this.getDaysArray(this.currentStartDate, endDate);
        },
        changePage(page, number) {
            var startDate = new Date(this.currentStartDate);
            var endDate = new Date(this.currentEndDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
                endDate.setDate(this.currentEndDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
                endDate.setDate(this.currentEndDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.currentEndDate = endDate;

            this.currentDateList = this.getDaysArray(this.currentStartDate, this.currentEndDate);
        },
        getDaysArray: function(s, e) {
            for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
                a.push(new Date(d));
            }
            return a;
        },
        filterItems(filter) {
            this.activeFilter = filter;
            this.actionItems = this.actionItemsAll.filter((items) => items.actionItemSource == filter);
        },
        viewReference: function(data, title) {
            console.log(data);
            this.referencePopupId = data.entityRecordId;
            this.refPopupTitle = title;
            this.refPopupEntity = data.actionItemSource;
            this.detailViewData = data;
            this.attachmentData.refEntity = data.entity;
            this.attachmentData.refRecordId = data.id;

            if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'FINANCE') {
                this.$refs.finTxnDetailRef.getDetails(this.referencePopupId, data.entity);
            }
            else if (this.refPopupEntity == 'APV' || this.refPopupEntity == 'REV') {
                this.$refs.reviewApprovalDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity, data.ermEntity, data.entityDisplayName);
            }
            else if (this.refPopupEntity == 'SHARE') {
                this.$refs.shareDetailPopupRef.getDetails();
            }
            else if (this.refPopupEntity == 'EMAIL') {
                this.$refs.emailDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'MTG_P') {
                // let eventId = moment(String(data.actionItemDt)).format('YYYYMMDD');
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.referencePopupId, data.entity, data.actionItemDt);
            }
            else if (this.refPopupEntity == 'MTG') {
                // let data.actionItemDt = moment(String(data.actionItemDt)).format('YYYYMMDD');
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.referencePopupId, data.entity, data.actionItemDt);
            }
            else if (this.refPopupEntity == 'LVE' || this.refPopupEntity == 'PER') {
                this.$nextTick(() => {
                    this.$refs.leaveTimeDetailPopupRef.getDetails(this.referencePopupId, data.entity);
                })
            }
            else if (this.refPopupEntity == 'ASSET' || this.refPopupEntity == 'AST_EXP') {
                this.$nextTick(() => {
                    this.$refs.assetDetailPopupRef.getDetails(this.referencePopupId, data.entity);
                })
            }
            else if (this.refPopupEntity == 'SERVICE' || this.refPopupEntity == 'SRV_DUE' || this.refPopupEntity == 'SRV_EXP') {
                this.$nextTick(() => {
                    this.$refs.serviceUtilDetailPopupRef.getDetails(this.referencePopupId, data.entity);
                })
            }
            else {
                this.$nextTick(() => {
                    this.$root.$emit('bv::show::modal', 'referenceDetail', '#referenceDetailRef');
                })
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
                console.log(this.referencePopupId, this.refPopupEntity, data);
            }
        },
        titleToolTip(actionItemSource) {
            if (actionItemSource == 'SRV_DUE') {
                return 'Service Due';
            }
            else if (actionItemSource == 'SRV_EXP') {
                return 'Service Expiry';
            }
            else if (actionItemSource == 'SERVICE') {
                return 'Service';
            }
            else if (actionItemSource == 'ASSET') {
                return 'Asset';
            }
            else if (actionItemSource == 'AST_EXP') {
                return 'Asset Expiry';
            }

        },
        setErdId: function(erdId, entity, ermId){
            localStorage.setItem("erdId", erdId);
            this.$store.state.erd_data.erd_id = erdId;
            this.$router.push({ name: this.getReportUrlName(entity), params: { ermId: ermId } });           
        },
        redirectPage: function() {
            if (this.projectId) {
                this.$router.push('/actionitem/list');;
            } else {
                this.$router.push('/landing/actionitem/list');
            }
        },
        redirectToRequests: function() {
            this.$router.push('/landing/wflreq');
        },
        getLeaveStatusVariant(status) {
            switch(status) {
                case 'Applied':
                    return 'badge-warning';
                    break;
                case 'Approved':
                    return 'badge-success';
                    break;
                case 'Rejected':
                    return 'badge-danger';
                    break;
                default:
                    return 'badge-secondary';
            }
        }
    }
}
</script>