<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Project Team</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="projectteam-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Team Member</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-4 pr-3">
                                                        <div class="form-group">
                                                            <label for="member">Member Name</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.memberName.$error }">
                                                                <b-input-group class="">
                                                                    <select v-model="projectTeam.member" @change="showHRDetails(projectTeam.member)" id="member" class="form-control">
                                                                        <option :value="{id: '', text:'',userId:''}">Select</option>
                                                                        <option v-for="(info, index) in humanResources" :value="{id: info.id, text:info.value,userId:info.userId}">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="humanResourcePopupTitle" class="cust-setting-icon action-link" href="javascript:" v-b-modal.humanResourcePopup><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectTeam.memberName.$error && !$v.projectTeam.memberName.required" class="text-danger">Please select Member Name</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 pr-3">
                                                        <div class="form-group">
                                                            <label for="member">Resource Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.resourceTypeId.$error }">
                                                                <b-input-group>
                                                                    <select v-model="projectTeam.resourceTypeId" id="position" class="form-control" :class="{ 'is-invalid': $v.projectTeam.resourceTypeId.$error }">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in resourceLookup" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectTeam.resourceTypeId.$error && !$v.projectTeam.resourceTypeId.required" class="text-danger">Please select Resource Type</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="member">Member Role</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.customUserRoleId.$error }">
                                                                <select v-model="projectTeam.customUserRoleId" class="form-control" :disabled="projectData.projectManagerId && projectData.projectManagerId == projectTeam.member.userId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in customUserRoles" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="position">Email</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.emailId.$error, 'is-invalid': error }">
                                                                <input type="text" v-model="projectTeam.emailId" class="form-control" :disabled="!showEmailIdEdit" @focus="elements.alreadyExists=false;error=false" id="email" maxlength="100" placeholder="Please enter Email ID">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectTeam.emailId.$error && !$v.projectTeam.emailId.email" class="text-danger">Enter Valid Email Id</label>
                                                            <label v-if="$v.projectTeam.emailId.$error && !$v.projectTeam.emailId.required" class="text-danger">Please enter Email Id</label>
                                                            <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-4 pr-3">
                                                        <div class="form-group">
                                                            <label for="position">Job Title</label>
                                                            <input v-if="false" type="text" v-model="projectTeam.position" class="form-control" id="position" maxlength="100" placeholder="Position">
                                                            <div class="row">
                                                                <div class="col-10 pr-1">
                                                                    <select v-model="projectTeam.jobTitle" id="position" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in positions" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                    </select>
                                                                </div>
                                                                <a title="Add Job Title" class="action-link" href="javascript:" v-b-modal.jobTitlePopup><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="requiredFrom">Required From</label>
                                                            <input v-model="projectTeam.requiredFrom" type="Date" class="form-control" placeholder="Required From">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="requiredTill">Required Till</label>
                                                            <input v-model="projectTeam.requiredTill" type="Date" class="form-control" placeholder="Required Till">
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData()" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <!--                                             <button type="submit" @click="saveData('invite')" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save & Invite</button> -->
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>

                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="projectTeamFilter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="projectTeamTable" v-model="mainTableArray" show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="projectTeams" @row-selected="onRowSelected" :fields="projectTeamFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="projectTeamFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <!-- <a href="javascript:" title="Send Activation Email" @click="inviteTeamMember(data.item.id, data.item.userId, data.item.emailId, data.item.memberName)"><i class="fa fa-envelope-square"></i></a> -->
                                    <a href="javascript:" title="Delete" @click="deleteProjectTeam(data.item.id)" v-b-modal.deleteProjectTeam><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Project Teams found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="projectTeams.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible" content-class="mt-3" lazy>
                                    <b-tab title="Details" :active="elements.showDetail">
                                        <div class="row input-group-label">
                                            <div class="col-4">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{projectTeamDetail.projectTeamId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-6 pl-0">
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateProjectTeam" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="humanResourceId">Member Name</label>
                                                    <select v-if="elements.editMode" v-model="projectTeamDetail.member" @change="showHRDetails(projectTeamDetail.member)" class="form-control">
                                                        <option :value="{id: '', text:'',userId:''}">Select</option>
                                                        <option v-for="(info, index) in humanResources" :value="{id: info.id, text:info.value,userId:info.userId}">{{info.value}}</option>
                                                    </select>
                                                    <input v-if="!elements.editMode" disabled v-model="projectTeamDetail.memberName" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="member">Resource Type</label>
                                                    <div v-if="elements.editMode" class="required-field-block" :class="{ 'is-invalid': $v.projectTeamDetail.resourceTypeId.$error }">
                                                        <b-input-group>
                                                            <select v-model="projectTeamDetail.resourceTypeId" id="position" class="form-control" :class="{ 'is-invalid': $v.projectTeamDetail.resourceTypeId.$error }" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in resourceLookup" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <b-input-group-append class="append-0">
                                                                <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <input v-else disabled v-model="projectTeamDetail.resourceType" type="text" class="form-control">
                                                    <label v-if="$v.projectTeamDetail.resourceTypeId.$error && !$v.projectTeamDetail.resourceTypeId.required" class="text-danger">Please select Resource Type</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="phone">Phone</label>
                                                    <input type="text" v-if="!elements.editMode" class="form-control" :disabled="!elements.editMode" v-model="projectTeamDetail.phone">
                                                    <input type="text" v-if="elements.editMode" class="form-control" id="phone" v-model="projectTeamDetail.phone">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="emailId">Email</label>
                                                    <input type="text" :disabled="elements.editMode || !showEmailIdEdit" class="form-control" id="EmailId" v-model="projectTeamDetail.emailId">
                                                </div>
                                            </div>
                                            <!--    <div v-if="humanResources.loginName!=''" class="row"> -->
                                            <!--   </div> -->
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="qualification">Qualification</label>
                                                    <input type="text" v-if="!elements.editMode" class="form-control" :disabled="!elements.editMode" v-model="projectTeamDetail.qualification">
                                                    <input type="text" v-if="elements.editMode" class="form-control" id="Qualification" v-model="projectTeamDetail.qualification">
                                                </div>
                                            </div>
                                            <div class="col-6 ">
                                                <div class="form-group">
                                                    <label for="experienceYears">Exp. Years / Months</label>
                                                    <b-input-group class="text mb-3">
                                                        <select v-if="elements.editMode" v-model="projectTeamDetail.experienceYears" class="form-control">
                                                            <option value="">Years</option>
                                                            <option v-for="n in 50" :value="n">{{n}}</option>
                                                        </select>
                                                        <!--                                                     <select v-if="elements.editMode" v-model="projectTeam.experienceYears" class="form-control">
                                                            <option value="">YY</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select> -->
                                                        <input v-if="!elements.editMode" disabled v-model="projectTeamDetail.experienceYears" type="text" class="form-control">
                                                        <select v-if="elements.editMode" v-model="projectTeamDetail.experienceMonths" class="form-control">
                                                            <option value="">Months</option>
                                                            <option v-for="n in 11" :value="n">{{n}}</option>
                                                        </select>
                                                        <!--                                                     <select v-if="elements.editMode" v-model="projectTeam.experienceMonths" class="form-control">
                                                            <option value="">Months</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select> -->
                                                        <input v-if="!elements.editMode" disabled v-model="projectTeamDetail.experienceMonths" type="text" class="form-control">
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="requiredFrom">Required From</label>
                                                    <input type="text" v-if="!elements.editMode" class="form-control" :disabled="!elements.editMode" v-model="projectTeamDetail.requiredFrom">
                                                    <input type="Date" v-if="elements.editMode" class="form-control" id="requiredFrom" v-model="projectTeamDetail.requiredFrom">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="requiredTill">Required Till</label>
                                                    <input type="text" v-if="!elements.editMode" class="form-control" :disabled="!elements.editMode" v-model="projectTeamDetail.requiredTill">
                                                    <input type="Date" v-if="elements.editMode" class="form-control" id="requiredTill" v-model="projectTeamDetail.requiredTill">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <div class="account-title">
                                                            <h5 class="mb-0">
                                                                <label class="my-1">User Account Info</label>
                                                                <label v-if="projectTeamDetail.loginName" :title="projectTeamDetail.isActive==true ? 'Active User' : 'User Not Activated'" class="pull-right py-0 m-0 pointer">
                                                                    <i class="fa fa-user fa-xs" :class="{ 'text-success' : projectTeamDetail.isActive==true }" aria-hidden="true"></i>
                                                                </label>
                                                                <!-- <button v-if="!projectTeamDetail.loginName && !elements.userActivation" @click="elements.userActivation = true" href="javascript:" class="sbtn btn btn-xs action-link p-1 pointer pull-right" title="User Account">
                                                                    <i class="fa fa-plus-circle action-link pr-1" aria-hidden="true"></i>User Account
                                                                </button> -->
                                                            </h5>
                                                        </div>
                                                        <div v-if="!elements.userActivation && projectTeamDetail.loginName" class="account-content">
                                                            <div class="row mt-1">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label>User Name</label>
                                                                        <b-form-input aria-label="First name" disabled v-model="projectTeamDetail.userName"></b-form-input>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label>Login Name</label>
                                                                        <b-form-input aria-label="First name" disabled v-model="projectTeamDetail.loginName"></b-form-input>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="position">Account Type</label>
                                                                        <input type="text" class="form-control" disabled v-model='projectTeamDetail.userType'>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div v-if="elements.userActivation" class="account-content">
                                                            <div class="row mt-1">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label>User Account Name</label>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeamDetail.memberName.$error, 'is-invalid': error }">
                                                                            <b-form-input aria-label="memberName" v-model="projectTeamDetail.memberName"></b-form-input>
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.projectTeamDetail.memberName.$error && !$v.projectTeamDetail.memberName.memberName" class="text-danger">Account Name must have at least {{$v.projectTeamDetail.memberName.$params.minLength.min}} letters.</label>
                                                                        <label v-if="$v.projectTeamDetail.memberName.$error && !$v.projectTeamDetail.memberName.required" class="text-danger">Please enter Account Name</label>
                                                                        <label v-if="$v.projectTeamDetail.memberName.$error && !$v.projectTeamDetail.memberName.maxLength" class="text-danger">Account Name must not exceed {{$v.projectTeamDetail.memberName.$params.maxLength.max}} letters.</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="position">Login Email</label>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeamDetail.emailId.$error, 'is-invalid': error }">
                                                                            <input type="text" class="form-control" v-model='projectTeamDetail.emailId'>
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.projectTeamDetail.emailId.$error && !$v.projectTeamDetail.emailId.email" class="text-danger">Enter Valid Email Id</label>
                                                                        <label v-if="$v.projectTeamDetail.emailId.$error && !$v.projectTeamDetail.emailId.required" class="text-danger">Please enter Email Id</label>
                                                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12 my-2">
                                                                    <button type="submit" @click="createUserAccount" title="Create" class="sbtn btn btn-xs btn-primary py-1 px-2 mr-1"> <i class="fa fa-floppy-o pr-1"></i> Create</button>
                                                                    <button type="submit" @click="cancelAccountEdit" title="Cancel" class="sbtn btn btn-xs btn-primary py-1 px-2"><i class="fa fa-times pr-1"></i> Cancel</button>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.rr>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="rr" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="preparedBy">Created By</label>
                                                                            <input type="text" class="form-control" id="preparedBy" disabled v-model='projectTeamDetail.preparedBy'>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdDt">Created Date</label>
                                                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(projectTeamDetail.createdDt)">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="lastUpdatedDt">Last Updated Date</label>
                                                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(projectTeamDetail.lastUpdatedDt)">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Roles & Resp.">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <label for="role">Role</label>
                                                            <a href="javascript:" class="float-right button-add" @click="elements.showDropdownRole=!elements.showDropdownRole;">
                                                                <i v-if="!elements.showDropdownRole" title="Existing Roles" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                <i v-if="elements.showDropdownRole" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                            </a>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibilityDetail.role.$error }">
                                                                <select v-if="elements.showDropdownRole" @change="getResponsibilities(roleResponsibilityDetail.roleData.id)" v-model="roleResponsibilityDetail.roleData" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in roles" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.showDropdownRole" type="text" class="form-control" id="role" v-model="roleResponsibilityDetail.role" placeholder="Enter New Roles">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.roleResponsibilityDetail.role.$error && !$v.roleResponsibilityDetail.role.required" class="text-danger">Please Enter Role</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="responsibility">Responsibility</label>
                                                                <b-form-tags input-id="tags-separators" v-model="roleResponsibilityDetail.responsibilityArray" class="form-control" separator="," placeholder="Enter Responsibilities"></b-form-tags>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearRoleResponsibility" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.roleEditMode ? 'Cancel' : 'Clear'}}</button>
                                                    <button type="submit" @click="saveRoleResponsibility()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Add</button>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="card mt-1" v-for="responsibility in responsibilities">
                                                <div class="card-body p-2" v-if="responsibility.id !== roleResponsibilityDetail.editId">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Role" @click="deleteRoleResponsibility(responsibility.id)" v-b-modal.deleteRoleResponsibility><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Role" @click="editRole(responsibility)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ responsibility.role }}</h6>
                                                                <p> {{ responsibility.responsibility }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Tech & Skills">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <div class="row">
                                                        <div class="col-12 mb-3">
                                                            <label for="technology">Technology</label>
                                                            <a href="javascript:" class="float-right button-add" @click="elements.showDropdownTech=!elements.showDropdownTech;">
                                                                <i v-if="!elements.showDropdownTech" title="Existing Technologies" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                <i v-if="elements.showDropdownTech" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                            </a>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkillDetail.technology.$error }">
                                                                <select v-if="elements.showDropdownTech" @change="getTechnologies(technologySkillDetail.technologyData.id)" v-model="technologySkillDetail.technologyData" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in technologies" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="!elements.showDropdownTech" type="text" class="form-control" id="technology" v-model="technologySkillDetail.technology" placeholder="Enter New Technologies">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.technologySkillDetail.technology.$error && !$v.technologySkillDetail.technology.required" class="text-danger">Please Enter Technology</label>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="skills">Skills</label>
                                                                <b-form-tags input-id="tags-separators" v-model="technologySkillDetail.skillsArray" separator="," class="form-control" placeholder="Enter skills"></b-form-tags>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearTechnologySkill" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>{{ elements.techEditMode ? 'Cancel' : 'Clear'}}</button>
                                                    <button type="submit" @click="saveTechnologySkill()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Add</button>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="card my-1" v-for="technologySkill in technologySkills">
                                                <div class="card-body p-2" v-if="technologySkill.id !== technologySkillDetail.editId">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Technology" @click="deleteTechnologySkill(technologySkill.id)" v-b-modal.deleteTechnologySkill ><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Technology" @click="editTech(technologySkill)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ technologySkill.technology }}</h6>
                                                                <p>{{ technologySkill.skills }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddResourceLookup :resourceLookupTitle="resourceLookupTitle" :resourceCategoryId="1" @refresh-lookup="lookupData" />
        <!-- <JobTitlePopup ref="typeLookupModal" entity="resource_plan_master" :jobTitlePopupTitle="jobTitlePopupTitle" @refresh-lookup="lookupData" /> -->
        <HumanResourcesPopup ref="humanResourcePopup" :entity="this.entity" :humanResourcePopupTitle="humanResourcePopupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
        <DeleteComponent id="deleteProjectTeam" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete team member?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import month from '../../json/month.json';
import AddResourceLookup from '@/components/popup/lookup/AddResourceLookup.vue';
import HumanResourcesPopup from '@/components/popup/lookup/HumanResourcesPopup.vue';
// import JobTitlePopup from '@/components/popup/lookup/JobTitlePopup.vue';
export default {
    name: 'app',
    components: {
        AddResourceLookup,
        HumanResourcesPopup,
        // JobTitlePopup,
    },
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showEmailIdEdit: false,
            error: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            humanResourcePopupTitle: 'Project Members',
            // jobTitlePopupTitle: "Job Title",
            resourceLookupTitle: 'Resources',

            projectData: {
                projectManagerId: '',
                customUserRoleId: '',
            },

            monthList: month,
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                roleEditMode: false,
                techEditMode: false,
                showDropdownRole: false,
                showDropdownTech: false,
                alreadyExists: false,
                userActivation: false
            },

            mainTableArray: [],

            projectTeam: {
                id: '',
                vmxServiceCode: localStorage.getItem("vmxServiceCode"),
                projectTeamId: '',
                customUserRoleId: '',
                userId:'',
                userTypeId:'',
                userType:'',
                userTypeCode:'',
                memberTypeId:'',
                memberTypeCode:'TMB',
                memberId:'',
                memberName: '',
                member: {
                    id:'',
                    text:'',
                    userId:'',
                },
                humanResourceId: '',
                resourceTypeId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                roleResponsibility: '',
                technologySkill: '',
                preparedBy: '',
                qualification: '',
                experienceYears: '',
                experienceMonths: '',
                requiredFrom: '',
                requiredTill: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false,
                jobTitle:"",
                resourcePlanId:"",
                ermTitle:""
            },
            projectTeamDetail: {
                id: '',
                vmxServiceCode: localStorage.getItem("vmxServiceCode"),
                projectTeamId: '',
                customUserRoleId: '',
                userId:'',
                userTypeId:'',
                userType:'',
                userTypeCode:'',
                memberTypeId:'',
                memberTypeCode:'TMB',
                memberId:'',
                memberName: '',
                member: {
                    id:'',
                    text:'',
                    userId:'',
                },
                humanResourceId: '',
                resourceTypeId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                roleResponsibility: '',
                technologySkill: '',
                preparedBy: '',
                qualification: '',
                experienceYears: '',
                experienceMonths: '',
                requiredFrom: '',
                requiredTill: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false,
                jobTitle:"",
                resourcePlanId:"",
                ermTitle:""
            },
            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: 'project_team',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''

            },
            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'technology_skill',
                refEntity: 'project_team',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technology: '',
                technologyData: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },
            entityId: '',
            entity: "project_team",
            resourceLookup: [],
            users: [],
            projectTeams: [],
            humanResources: [],
            customUserRoles: [],
            technologies: [],
            roles: [],
            responsibilities: [],
            technologySkills: [],
            positions:[],

            projectTeamFields: [
                { key: 'projectTeamId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'memberName', label: 'Member Name', sortable: true, sortDirection: 'desc' },
                { key: 'resourceType', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'requiredFrom', label: 'Required From', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'requiredTill', label: 'Required Till', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            projectTeamFilter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");
        this.projectTypeId = localStorage.getItem("projectTypeId");

        this.getProjectTeam();
        this.lookupData();
        this.getProjectManagerId();
    },
    validations: {
        projectTeam: {
            memberName: {
                required
            },
            customUserRoleId: {
                required,
            },
            emailId: {
                required,
                email,
            },
            resourceTypeId: {
                required,
            },
        },
        projectTeamDetail: {
            memberName: {
                required
            },
            customUserRoleId: {
                required,
            },
            emailId: {
                required,
                email,
            },
            resourceTypeId: {
                required,
            },
        },
        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {
            technology: {
                required
            }
        },
    },

    methods: {
        getProjectTeam: function() {
            let data = {
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/member/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTeams = result.projectTeamMemberList;
                    this.totalRows = this.projectTeams.length;
                    this.elements.editMode = false;
                    if (this.projectTeams && this.projectTeams.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.projectTeamDetail.id, this.mainTableArray, 'projectTeamTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                    if(this.projectData.projectManagerId && this.projectData.projectManagerId == this.projectTeam.member.userId) {
                        this.projectTeam.customUserRoleId = this.projectData.customUserRoleId;
                    }
                })
        },
        getProjectManagerId: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/project/getprmdetail', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.projectData = result.projectData;
                })
                .catch(error=> {
                     this.showOverlay = false
                });
        },
        getRoleResponsibilities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.projectTeamDetail.humanResourceId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.responsibilities = result.cmrRoleResponsibilityList;
                    }
                })
        },

        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.projectTeamDetail.humanResourceId,
            }
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                resourceCategoryId: 1
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.humanResources = result.humanResourceLookup;
                    this.customUserRoles = result.customUserRoleLookup;
                    this.positions = result.resPlanJobTitleLookup;
                    this.resourceLookup = result.resourceLookup;
                    this.roles = result.roleLookup;
                    this.technologies = result.technologyLookup;
                    console.log(result);
                })

        },
        saveData: function(flag) {
            if (this.projectTeam.member != null && !this.elements.editMode) {
                this.projectTeam.memberName = this.projectTeam.member.text;
                this.projectTeam.humanResourceId = this.projectTeam.member.id;
                this.projectTeam.userId = this.projectTeam.member.userId;
            }
            if (this.projectTeam.jobTitle != null && !jQuery.isEmptyObject(this.projectTeam.jobTitle)) {
                let resultSplit = this.projectTeam.jobTitle.text.split('/');
                this.projectTeam.resourcePlanId = this.projectTeam.jobTitle.id;
                if (resultSplit.length == 2) {                  
                    this.projectTeam.position = resultSplit[1].trim();
                }
            }

            this.$v.projectTeam.$touch();
            if (!this.$v.projectTeam.$invalid && !this.checkEmailExists(this.projectTeam.emailId)) {
                if (!(this.checkHRExists(this.projectTeam.humanResourceId))) {
                    if (this.elements.editMode) {
                        this.updateProjectMember();
                    }
                    else {
                        this.saveProjectTeam();
                    }
                }
            }
        },
        saveProjectTeam: function() {
            this.error = false;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/add', this.projectTeam)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectTeam();
                        this.clearData();
                    } else if (result.status == 'OK') {
                        this.elements.alreadyExists = true;
                        this.error = true;
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });

                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateProjectTeam: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/update', this.projectTeamDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectTeam();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        deleteProjectTeam: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/projectteam/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectTeam();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewProjectTeam: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/projectteam/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let projectTeamData = result.projectTeamView;

                    this.projectTeamDetail.id = projectTeamData.id;
                    this.projectTeamDetail.projectId = projectTeamData.projectId;
                    this.projectTeamDetail.vhextAccountId = projectTeamData.vhextAccountId;
                    this.projectTeamDetail.projectTeamId = projectTeamData.projectTeamId;
                    this.projectTeamDetail.customUserRoleId = projectTeamData.customUserRoleId;
                    this.projectTeamDetail.humanResourceId = projectTeamData.humanResourceId;
                    this.projectTeamDetail.entityRecordMasterId = projectTeamData.entityRecordMasterId;
                    this.projectTeamDetail.resourceTypeId = projectTeamData.resourceTypeId;
                    this.projectTeamDetail.resourceType = projectTeamData.resourceType;
                    this.projectTeamDetail.memberName = projectTeamData.memberName;
                    this.projectTeamDetail.member.id = projectTeamData.humanResourceId;
                    this.projectTeamDetail.member.text = projectTeamData.memberName;
                    this.projectTeamDetail.member.userId = projectTeamData.userId;
                    this.projectTeamDetail.projectMemberId = projectTeamData.projectMemberId;
                    this.projectTeamDetail.humanResource = projectTeamData.humanResource;
                    this.projectTeamDetail.emailId = projectTeamData.emailId;
                    this.projectTeamDetail.phone = projectTeamData.phone;
                    this.projectTeamDetail.position = projectTeamData.position;
                    this.projectTeamDetail.roleResponsibility = projectTeamData.roleResponsibility;
                    this.projectTeamDetail.technologySkill = projectTeamData.technologySkill;
                    this.projectTeamDetail.preparedBy = projectTeamData.preparedBy;
                    this.projectTeamDetail.preparedById = projectTeamData.preparedById;
                    this.projectTeamDetail.qualification = projectTeamData.qualification;
                    this.projectTeamDetail.experienceYears = projectTeamData.experienceYears;
                    this.projectTeamDetail.experienceMonths = projectTeamData.experienceMonths;
                    this.projectTeamDetail.requiredFrom = projectTeamData.requiredFrom;
                    this.projectTeamDetail.requiredTill = projectTeamData.requiredTill;
                    this.projectTeamDetail.scopeTypeId = projectTeamData.scopeTypeId;
                    this.projectTeamDetail.statusId = projectTeamData.statusId;
                    this.projectTeamDetail.jobTitle = projectTeamData.resourcePlanId;
                    this.projectTeamDetail.jobTitle = {id: projectTeamData.resourcePlanId, text:projectTeamData.ermTitle + ' / ' + projectTeamData.position};
                    this.projectTeamDetail.resourcePlanId = projectTeamData.resourcePlanId;
                    this.projectTeamDetail.ermTitle = projectTeamData.ermTitle;
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        showHRDetails: function(hrs) {
            console.log(hrs)
            let showOverlay = this.elements.editMode ? 'showDetOverlay' : 'showOverlay';
            if (hrs) {
                if (!this.checkHRExists(hrs.id)) {
                    this[showOverlay] = true;
                    axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + hrs.id)
                        .then((response) => {
                            this[showOverlay] = false;
                            this.clearInputData();
                            let result = response.data;
                            let humanResourceData = result.humanResourceView;
                            if (this.elements.editMode) {
                                this.projectTeamDetail.userId = humanResourceData.userId;
                                this.projectTeamDetail.userTypeId = humanResourceData.userTypeId;
                                this.projectTeamDetail.userType = humanResourceData.userType;
                                this.projectTeamDetail.userTypeCode = humanResourceData.userTypeCode;
                                this.projectTeamDetail.emailId = humanResourceData.emailId;
                                this.projectTeamDetail.position = humanResourceData.position;
                                this.projectTeamDetail.qualification = humanResourceData.qualification;
                                this.projectTeamDetail.experienceYears = humanResourceData.experienceYears;
                                this.projectTeamDetail.experienceMonths = humanResourceData.experienceMonths;
                                this.showEmailIdEdit = this.projectTeamDetail.emailId ? false : true;
                                if(this.projectData.projectManagerId == this.projectTeamDetail.userId) {
                                    this.projectTeamDetail.customUserRoleId = this.projectData.customUserRoleId;
                                }
                                else {
                                    this.projectTeamDetail.customUserRoleId = '';
                                }
                            }
                            else {
                                this.projectTeam.userId = humanResourceData.userId;
                                this.projectTeam.userTypeId = humanResourceData.userTypeId;
                                this.projectTeam.userType = humanResourceData.userType;
                                this.projectTeam.userTypeCode = humanResourceData.userTypeCode;
                                this.projectTeam.emailId = humanResourceData.emailId;
                                this.projectTeam.position = humanResourceData.position;
                                this.projectTeam.qualification = humanResourceData.qualification;
                                this.projectTeam.experienceYears = humanResourceData.experienceYears;
                                this.projectTeam.experienceMonths = humanResourceData.experienceMonths;
                                this.showEmailIdEdit = this.projectTeam.emailId ? false : true;
                                if(this.projectData.projectManagerId == this.projectTeam.userId) {
                                    this.projectTeam.customUserRoleId = this.projectData.customUserRoleId;
                                }
                                else {
                                    this.projectTeam.customUserRoleId = '';
                                }
                            }
                        })
                        .catch(error => {
                            this[showOverlay] = false;
                            console.log(error)}
                        );
                }
                else {
                    iziToast.info({
                        message: 'Team Member already exist.',
                        position: 'topRight'
                    });
                }
            }
            else {
                this.projectTeam.member.id = '';
                this.projectTeam.member.text = '';
                this.projectTeam.member.userId = '';
            }
        },
        cancelEditMode: function() {
            this.elements.visible = false;
            this.viewProjectTeam(this.projectTeamDetail.id);
            this.error = false;
            this.$v.projectTeamDetail.$reset();
            //this.clearData();
        },
        clearInputData: function() {
            this.projectTeam.memberName = '';
            this.projectTeam.humanResourceId = '';
            this.projectTeam.projectMemberId = '';
            this.projectTeam.emailId = '';
            this.projectTeam.position = '';
            this.projectTeam.roleResponsibility = '';
            this.projectTeam.technologySkill = '';
            this.projectTeam.qualification = '';
            this.projectTeam.experienceYears = '';
            this.projectTeam.experienceMonths = '';
            this.projectTeam.requiredFrom = '';
            this.projectTeam.requiredTill = '';
            this.projectTeam.id = '';
            this.projectTeam.userType = '';
            this.projectTeam.loginName = '';
            this.projectTeam.userName = '';
            this.elements.alreadyExists = false;
            this.error = false;
        },
        clearData: function() {
            this.elements.visible = false;
            this.clearInputData();
            this.projectTeam.member.id = '';
            this.projectTeam.member.text = '';
            this.projectTeam.member.userId = '';
            this.error = false;
            this.$v.projectTeam.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data);
                // alert(data.humanResourceId);
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.elements.editMode = false;
                this.elements.userActivation = false;
                this.projectTeamDetail.id = data.id;
                this.projectTeamDetail.projectId = data.projectId;
                this.projectTeamDetail.vhextAccountId = data.vhextAccountId;
                this.projectTeamDetail.projectTeamId = data.projectTeamId;
                this.projectTeamDetail.customUserRoleId = data.customUserRoleId;
                this.projectTeamDetail.humanResourceId = data.humanResourceId;
                this.projectTeamDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.projectTeamDetail.resourceTypeId = data.resourceTypeId;
                this.projectTeamDetail.resourceType = data.resourceType;
                this.projectTeamDetail.memberName = data.memberName;
                this.projectTeamDetail.member.id = data.humanResourceId;
                this.projectTeamDetail.member.text = data.memberName;
                this.projectTeamDetail.member.userId = data.userId;
                this.projectTeamDetail.projectMemberId = data.projectMemberId;
                this.projectTeamDetail.humanResource = data.humanResource;
                this.projectTeamDetail.emailId = data.emailId;
                this.projectTeamDetail.phone = data.phone;
                this.projectTeamDetail.position = data.position;
                this.projectTeamDetail.roleResponsibility = data.roleResponsibility;
                this.projectTeamDetail.technologySkill = data.technologySkill;
                this.projectTeamDetail.preparedBy = data.preparedBy;
                this.projectTeamDetail.preparedById = data.preparedById;
                this.projectTeamDetail.qualification = data.qualification;
                this.projectTeamDetail.experienceYears = data.experienceYears;
                this.projectTeamDetail.experienceMonths = data.experienceMonths;
                this.projectTeamDetail.requiredFrom = data.requiredFrom;
                this.projectTeamDetail.requiredTill = data.requiredTill;
                this.projectTeamDetail.scopeTypeId = data.scopeTypeId;
                this.projectTeamDetail.statusId = data.statusId;
                this.projectTeamDetail.jobTitle = data.resourcePlanId;
                this.projectTeamDetail.jobTitle = {id: data.resourcePlanId, text:data.ermTitle + ' / ' + data.position};
                this.projectTeamDetail.resourcePlanId = data.resourcePlanId;
                this.projectTeamDetail.ermTitle = data.ermTitle;
                this.getTechnologySkills();
                this.getRoleResponsibilities();
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        // inviteTeamMember: function(clientId, userId, emailId, memberName) {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectId: this.projectId,
        //         entity: 'project_team',
        //         emailId: emailId,
        //         id: userId,
        //         userName: memberName
        //     }

        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/activate', data)
        //         .then((response) => {
        //             let result = response.data;
        //             if (Boolean(result.status)) {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //             }
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        checkHRExists: function(hrsId) {
            if (hrsId && !this.elements.editMode) {
                let result = false;
                this.projectTeams.filter(data => {
                    if (data.humanResourceId == hrsId) {
                        result = true;
                    }
                });
                return result;
            }
        },
        checkEmailExists: function(emailId) {
            let result = false;
            this.elements.alreadyExists = false;
            this.error = false;
            if(!this.elements.editMode){
                this.projectTeams.map(data => {
                    if (data.emailId == emailId) {
                        this.elements.alreadyExists = true;
                        this.error = true;
                        result = true;
                    }
                });
            }
            return result;
        },
        saveRoleResponsibility: function() {
            if (this.roleResponsibilityDetail.roleData.text != null) {
                this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
            }
            this.$v.roleResponsibilityDetail.$touch();
            if (!this.$v.roleResponsibilityDetail.$invalid) {
                if (this.elements.roleEditMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.addRoleResponsibility();
                }
            }
        },
        addRoleResponsibility: function() {
            this.roleResponsibilityDetail.refRecordId = this.projectTeamDetail.humanResourceId;
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        updateRoleResponsibility: function() {
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        clearRoleResponsibility: function() {
            this.elements.roleEditMode = false;
            this.elements.roleVisible = false;
            this.elements.showDropdownRole = false;
            this.roleResponsibilityDetail.editId= '';
            this.roleResponsibilityDetail.role= '';
            this.roleResponsibilityDetail.roleData= '';
            this.roleResponsibilityDetail.responsibility='';
            this.roleResponsibilityDetail.responsibilityArray= [];
            this.$v.roleResponsibilityDetail.$reset();
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYesRole: function() {
            this.showHRDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveTechnologySkill: function() {
            if (this.technologySkillDetail.technologyData.text != null) {
                this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
            }
            this.$v.technologySkillDetail.$touch();
            if (!this.$v.technologySkillDetail.$invalid) {
                if (this.elements.techEditMode) {
                    this.updateTechnologySkill();
                } else {
                    this.addTechnologySkill();
                }
            }
        },
        addTechnologySkill: function() {
            this.technologySkillDetail.refRecordId = this.projectTeamDetail.humanResourceId;
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        updateTechnologySkill: function() {
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showHRDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        clearTechnologySkill: function() {
            this.elements.techEditMode = false;
            this.elements.techVisible = false;
            this.elements.showDropdownTech = false;
            this.technologySkillDetail.editId= '';
            this.technologySkillDetail.technologyData = '';
            this.technologySkillDetail.technology= '';
            this.technologySkillDetail.skills= '';
            this.technologySkillDetail.skillsArray= [];
            this.$v.technologySkillDetail.$reset();
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYesTech: function() {
            this.showHRDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showHRDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        // this.$forceUpdate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editRole: function(data) {
            this.elements.roleEditMode = true;
            this.elements.roleVisible = true;
            this.roleResponsibilityDetail.id = data.id;
            this.roleResponsibilityDetail.editId = data.id;
            this.roleResponsibilityDetail.role = data.role;
            this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
            this.roleResponsibilityDetail.refRecordId = data.refRecordId;
            this.roleResponsibilityDetail.refEntityId = data.refEntityId;
            this.roleResponsibilityDetail.projectId = data.projectId;
            this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
            this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibilityDetail.preparedById = data.preparedById;
        },
        editTech: function(data) {
            this.elements.techEditMode = true;
            this.elements.techVisible = true;
            this.technologySkillDetail.id = data.id;
            this.technologySkillDetail.editId = data.id;
            this.technologySkillDetail.technologySkillId = data.technologySkillId;
            this.technologySkillDetail.technology = data.technology;
            this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
            this.technologySkillDetail.refRecordId = data.refRecordId;
            this.technologySkillDetail.refEntityId = data.refEntityId;
            this.technologySkillDetail.projectId = data.projectId;
            this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
            this.technologySkillDetail.preparedById = data.preparedById;
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(roleResponsibility.responsibility);
                    })
                }
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = this.stringToArray(technologySkill.skills);
                    })
                }
        },
        // createUserAccount: function() {
        //     this.projectTeamDetail.userTypeCode = 'TMB';
        //     this.projectTeamDetail.userType = 'Team Member';
        //     this.projectTeamDetail.userName = this.projectTeamDetail.memberName;
        //     this.$v.projectTeamDetail.$touch();
        //     if (!this.$v.projectTeamDetail.$invalid) {
        //         this.showDetOverlay = true;
        //         axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.projectTeamDetail)
        //             .then((response) => {
        //                 this.showDetOverlay = false;
        //                 let result = response.data;
        //                 if (result.status == "CREATED") {
        //                     this.clearData();
        //                     this.cancelAccountEdit();
        //                     this.getProjectTeam(this.$route.params.ermId);
        //                     iziToast.success({
        //                         title: 'Success',
        //                         message: result.message,
        //                         position: 'topRight'
        //                     });

        //                 } else if (result.status == 'OK') {
        //                     this.elements.alreadyExists = true;
        //                     iziToast.info({
        //                         message: result.message,
        //                         position: 'topRight'
        //                     });

        //                 }
        //             })
        //             .catch(error => {
        //                 this.showDetOverlay = false;
        //                 iziToast.error({
        //                    title: 'Error',
        //                    message: 'Error',
        //                    position: 'topRight'
        //                });
        //             })
        //     }
        // },
        // cancelAccountEdit() {
        //     this.elements.userActivation = false;
        //     this.projectTeams.filter((projectTeam) => {
        //         if (projectTeam.id == this.projectTeamDetail.id) {
        //             this.projectTeamDetail.memberName = projectTeam.memberName;
        //             this.projectTeamDetail.emailId = projectTeam.emailId;
        //         }
        //     })
        // }
    }
}
</script>