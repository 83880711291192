<template>
    <div class="project">
        <b-modal ref="projectModal" size="md" centered id="projectAddPopup" modal-class="projectModal" @hide="closeDialog" :title="projectPopupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Project</span>
                                <span v-if="elements.editMode">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                            <b-card-body class="p-1">
                                <div class="form-row">
                                    <!-- <b-input-group class="form-group py-2"> -->
                                        <!-- <div cols="2" class="align-middle float-left my-auto ml-4 mr-0 p-1" >Project</div> -->
                                        <b-col cols="5">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.title.$error }">
                                                <input type="text" class="form-control" v-model="project.title" id="title" :class="{ 'is-invalid': $v.project.title.$error }" placeholder="Enter Title">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.title.$error && !$v.project.title.minLength" class="text-danger">Title must have at least {{$v.project.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.maxLength" class="text-danger">Title must not exceed {{$v.project.title.$params.maxLength.max}} letters.</label>
                                        </b-col>
                                        <b-col cols="5">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.projectManagerId.$error }">
                                                <select v-model="project.projectManagerId" disabled class="form-control" :class="{ 'is-invalid': $v.project.projectManagerId.$error }">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.projectManagerId.$error && !$v.project.projectManagerId.required" class="text-danger">Please select Project Manager</label>
                                        </b-col>
                                        <!-- <b-col cols="2">
                                            <div class="input-group p-0">
                                                <b-form-checkbox class="custom-toggle" v-model="project.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>                            -->     
                                        <div cols="2" class="text-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveProject" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    <!-- </b-input-group> -->
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="float-right">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="projects" :fields="projectFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showProjectEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <!-- <a href="javascript:" title="Delete" @click="removeProject(data.item.id)" v-b-modal.removeProject><i class="fa fa-trash pl-2"></i></a> -->
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Projects found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                                
                    <b-pagination v-if="projects.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeProject" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Project ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        projectPopupTitle: String
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            project: {
                id: '',
                projectId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                vmxServiceRegisterId: '',
                projectTypeId: '',
                categoryId: '',
                title: '',
                displayName: '',
                description: '',
                startDate: '',
                endDate: '',
                durationDays: '',
                durationMonths: '',
                durationYears: '',
                initialBudget: '',
                projectManagerId: localStorage.getItem("userId"),
                hasAttachment: '',
                isProject: 1,
                isStandard: '',
                isArchive: false,
                location: '',
                countryId: '',
                currencyId: '',
                timeZone: '',
                projectAccessTypeId: '',
                statusId: '',
                active: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: 'project',
                userTypeCode: '',
                serviceId: '',
            },
            users: [],

            projects: [],
            projectFields: [
                { key: 'index',label: 'SN'},
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'projectManager', label: 'Project Manager', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        project: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            // displayName: {
            //     maxLength: maxLength(10)
            // },
            projectManagerId: {
                required
            }
        },
    },
    mounted() {
        this.getProjects();
        this.lookupData();
    },
    methods: {
        getProjects: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                isArchive: false,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projects = result.projectList;
                    this.totalRows = this.projects.length;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/user', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                })

        },
        saveProject: function() {
            this.$v.project.$touch();
            if (!this.$v.project.$invalid) {
                if (!this.matchTextValExists(this.project.title, this.project.id)) {
                    if (this.elements.editMode) {
                        this.updateProject();
                    } else {
                        this.addProject();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Project already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        addProject: function() {
            this.$v.project.$touch();
            if (!this.$v.project.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/project/add', this.project)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        console.log(result);
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: 'Project Added',
                                position: 'topRight'
                            });
                            this.getProjects();
                            this.$emit('refresh-lookup');
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }

        },
        updateProject: function() {
            this.$v.project.$touch();
            if (!this.$v.project.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/project/update', this.project)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getProjects();
                            this.$emit('refresh-lookup');
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }

        },
        removeProject: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/project/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProjects();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.project.id = item.id;
                this.project.active = 0;
                this.project.entity = 'project';
                this.updateActivationState();
            } else {
                this.project.id = item.id;
                this.project.active = 1;
                this.project.entity = 'project';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.project)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProjects();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.editMode = false;
            this.project.id = '';
            this.project.title = '';
            this.project.displayName = '';
            this.project.description = '';
            this.project.projectTypeId = '';
            // this.project.projectAccessTypeId = '';
            this.project.projectManagerId = localStorage.getItem("userId");
            this.project.categoryId = '';
            this.project.durationDays = '';
            this.project.durationMonths = '';
            this.project.durationYears = '';
            this.project.initialBudget = '';
            this.project.startDate = '';
            this.project.endDate = '';
            this.project.description = '';
            // this.project.statusId = '1';
            this.project.currencyId = '';;
            this.project.timeZone = '';
            this.project.location = '';
            this.project.countryId = '';
            this.$v.project.$reset();
        },
        showProjectEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.project.id = data.id;
            this.project.vmxServiceRegisterId = data.vmxServiceRegisterId;
            this.project.projectTypeId = data.projectTypeId;
            this.project.categoryId = data.categoryId;
            this.project.title = data.title;
            this.project.displayName = data.displayName;
            this.project.description = data.description;
            this.project.startDate = data.startDate;
            this.project.endDate = data.endDate;
            this.project.initialBudget = data.initialBudget;
            this.project.durationYears = data.durationYears;
            this.project.durationMonths = data.durationMonths;
            this.project.durationDays = data.durationDays;
            this.project.projectManagerId = data.projectManagerId;
            this.project.hasAttachment = data.hasAttachment;
            this.project.isProject = data.isProject;
            this.project.isArchive = data.isArchive;
            this.project.location = data.location;
            this.project.countryId = data.countryId;
            this.project.currencyId = data.currencyId;
            this.project.timeZone = data.timeZone;
            this.project.projectAccessTypeId = data.projectAccessTypeId;
            this.project.statusId = data.statusId;
            this.project.active = data.active;
            console.log(this.project);
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.projects.map(data => {
                if (textVal && data.title && data.id !== id) {
                    if (data.title.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>