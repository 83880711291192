<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-8">
                            <h4 class="pl-2 mb-0 fs-16">Payroll</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <!-- <div class="comments-titel">
                                <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                            </div> -->
                            <div class="comments-cox mt-2">
                                <div class="form-row mb-2">
                                    <div class="col-6">
                                        <b-input-group prepend="Contract">
                                            <b-form-input v-model="contract.title" disabled></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <div class="col-6">
                                        <b-input-group prepend="Contractor">
                                            <b-form-input v-model="contract.contractor" disabled></b-form-input>
                                        </b-input-group>
                                    </div>
                                    <!-- <div class="col">
                                        <b-input-group prepend="Payroll Type">
                                            <select v-model="payroll.projectId" @change="getPayrolls" class="form-control" >
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectLinkLookup" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </b-input-group>
                                    </div>
                                    <div class="col">
                                        <b-input-group prepend="Start Date">
                                            <input type="date" v-model="payroll.startDate" @change="getPayrollSummary" class="form-control" id="startDt">
                                        </b-input-group>
                                    </div>
                                    <div class="col">
                                        <b-input-group prepend="End Date">
                                            <input type="date" v-model="payroll.endDate" @change="getPayrollSummary" class="form-control" id="endDt">
                                        </b-input-group>
                                    </div> -->
                                    <!-- <div class="col-3">
                                        <b-input-group prepend="Contract">
                                            <b-form-input type="date" v-model="payroll.transactionDt" @change="getPayrolls"></b-form-input>
                                        </b-input-group>
                                    </div> -->
                                </div>
                                <!-- <div class="row m-0">
                                    <div class="col-12">
                                        <button type="submit" @click="" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                        <button type="submit" @click="" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <i class="fa fa-plus-circle pr-2 action-link"></i>New Payroll
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="form-row mb-2">
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payroll.title.$error }">
                                                                <input type="text" class="form-control" ref="title" v-model="payroll.title" id="title" :class="{ 'is-invalid': $v.payroll.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.payroll.title.$error && !$v.payroll.title.minLength" class="text-danger">Title must have at least {{$v.payroll.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.payroll.title.$error && !$v.payroll.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.payroll.title.$error && !$v.payroll.title.maxLength" class="text-danger">Title must not exceed {{$v.payroll.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="csQuantity">Payroll Type</label>
                                                            <select v-model="payroll.payrollScheduleTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in payrollTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="csPrice">Start Date</label>
                                                            <input type="date" v-model="payroll.startDate" class="form-control" id="startDt">
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="csPrice">End Date</label>
                                                            <input type="date" v-model="payroll.endDate" class="form-control" id="endDt">
                                                        </div>
                                                    </div>
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label class="d-block v-hidden">Generate</label>
                                                            <button type="submit" @click="getPayrollSummary();elements.showSummary=true;" :disabled="showOverlay" class="btn btn-secondary sbtn py-1 px-3 mr-2">Generate</button>
                                                            <a href="javascript:" title="Reset" class="d-inline-block" @click="elements.showSummary=false;"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-1roll.description"></editor>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <div v-show="elements.showSummary">
                                                    <label class="mb-0 d-block">Summary <span class="float-right">{{ payroll.startDate | formatDateAlt }} <i class="px-2">to</i> {{ payroll.endDate | formatDateAlt }}</span></label>
                                                    <div class="data-table-rows report-tables">
                                                        <b-table head-variant="light" show-empty small stacked="md" sort-icon-left :items="payrollSummary" :fields="payrollSummaryFields" responsive="sm" foot-clone no-footer-sorting >
                                                            <template #cell(show_details)="row">
                                                                <span @click.stop="row.toggleDetails" class="ml-3" :title="`${row.detailsShowing ? 'Hide' : 'Show'} Details`">
                                                                    <span @click="showDetail(row.item.transactionDt);getExpenses(row.item.transactionDt);">
                                                                        <i class="fa fa-angle-down fa-lg font-weight-bold action-link pointer" :class="row.detailsShowing ? 'rotation_180' : 'rotation_normal'"></i>
                                                                    </span>
                                                                </span>
                                                            </template>
                                                            <template slot="row-details" slot-scope="row">
                                                                <div class="data-table-rows report-tables text-left px-3">
                                                                    <b-table show-empty small header-variant="secondary" sort-icon-left :items="companyExpenditures" :busy="isBusyDetail" :fields="companyExpenditureFields" responsive="sm">
                                                                        <template v-slot:cell(unitCost)="data">
                                                                            <div>{{ currencySymbol }}{{ data.item.unitCost }}</div>
                                                                        </template>
                                                                        <template v-slot:cell(cost)="data">
                                                                            <div>{{ currencySymbol }}{{ data.item.cost }}</div>
                                                                        </template>
                                                                        <template v-slot:empty="scope">
                                                                            <div class="text-center">No Details found</div>
                                                                        </template>
                                                                        <template v-slot:table-busy>
                                                                            <div class="text-center text-info my-2">
                                                                                <b-spinner class="align-middle"></b-spinner>
                                                                                <strong>Loading...</strong>
                                                                            </div>
                                                                        </template>
                                                                    </b-table>
                                                                </div>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">
                                                                <!-- <a href="javascript:" title="Edit" @click="showDetailEdit(data.item, data.index, 'Earning')"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                <a href="javascript:" title="Delete" @click="removePayrollDetail(data.item, 'Earning')" ><i class="fa fa-trash pl-2"></i></a> -->
                                                            </template>
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(totalCost)="data">
                                                                <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                            </template>

                                                            <template  v-slot:foot()="data">
                                                                <span></span>
                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Item found</div>
                                                            </template>
                                                            <template v-slot:foot(transactionDt)="data">
                                                                <div class="text-right">
                                                                    <span>Total </span>
                                                                </div>
                                                            </template>
                                                            <template v-slot:foot(totalCost)="data">
                                                                <div class="text-right font-weight-bold">
                                                                    <span class="text-right">{{ currencySymbol }} {{totalAmount.toFixed(2)}}</span>
                                                                </div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                    <button type="submit" @click="savePayroll" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <b-overlay :show="isBusy" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table outlined show-empty small v-model="visibleRows" stacked="md" sort-icon-left :items="payrolls" :fields="payrollFields" foot-clone foot-variant=light no-footer-sorting responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" >
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit" @click="viewItemDetails(data.item)">
                                            Show Detail
                                        </a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Payrolls Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>

                                    <template v-slot:foot()="data">
                                        <span></span>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                <b-pagination v-if="payrolls.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ContractPayrollDetailPopup v-if="showDialog" ref="contractPayrollPopupRef" @closeDialog="showDialog=false;" />
        <DeleteComponent id="deleteCompanyPayroll" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Payroll" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import ContractPayrollDetailPopup from '@/components/popup/itemdetail/ContractPayrollDetailPopup.vue';
export default {
    name: 'app',
    components: {
        Editor,
        AttachmentComponent,
        ContractPayrollDetailPopup,
    },
    data() {
        return {
            showOverlay: false,
            isBusy: false,
            isBusyDetail: false,
            elements: {
                editMode: false,
                visible: false,
                showSummary: false,
            },
            showDialog: false,
            notEnabled: true,

            // reqStatusKey: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            contractId: localStorage.getItem("contractId"),
            contractorId: localStorage.getItem("contractorId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            currencySymbol: localStorage.getItem("currency"),

            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),

            htmlData: '',
            entity: 'contract',
            typeLookupTitle: 'Payroll Type Settings',
            functionalRefPopupTitle: 'Add Reference',
            typeEntity: 'contract_type',

            inlineEditId: '',

            payrollTypes: [],
            projectLinkLookup: [],

            payroll: {
                id: '',
                payrollId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: '',
                employeeId: '',
                employeeName: '',
                employeeCode: '',
                refEntity: 'contract',
                refEntityId: '',
                refRecordId: '',
                payrollScheduleTypeId: '',
                payrollPeriod: '',
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                statusId: '',
                createdById: localStorage.getItem("userId"),
                earningDeductionList: [],
            },
            payrollDetail: {
                id: '',
                payrollId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                refEntityId: '',
                refEntity: 'contract',
                refRecordId: '',
                costEstimationTypeId: '',
                csCategoryId: '',
                title: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                unitCost: '',
                transactionDt: this.getDateValue(new Date()),
                cost: '',
                description: '',
                preparedById: localStorage.getItem("userId"),
            },
            contract: {},
            
            payrolls: [],
            payrollFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Item Title' },
                { key: 'payrollScheduleType', label: 'Pyroll Type' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'netSalary', label: 'Net Pay' },
                { key: 'actions', label: 'Actions' }
            ],

            companyExpenditures: [],
            companyExpenditureFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Item Title' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'uomType', label: 'Unit' },
                { key: 'unitCost', label: 'Unit Cost' },
                { key: 'cost', label: 'Total Cost' },
            ],

            payrollSummaryList: [],
            payrollSummaryFields: [
                { key: 'show_details', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'transactionDt', label: 'Txn Date', formatter: "formatDate" },
                { key: 'totalCost', label: 'Total Cost' },
                // { key: 'actions', label: 'Actions' }
            ],

            visibleRows: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            menuSetting: {
                height: 300,
                menubar: false,
            },
        }
    },
    computed: {
        payrollSummary() {
            return this.payrollSummaryList.map((event)=> {
                event._showDetails = false;
                return event;
            })
        },
        totalAmount() {
            if (this.payrollSummary.length>0) {            
                return this.payrollSummary.reduce((accum, item) => {
                    return accum + parseFloat(item.totalCost ? item.totalCost : 0)
                }, 0.00)
                return 0;
            } else return 0;
        },
        subTotal() {
            return 0;
            // return this.visibleRows.reduce((accum, item) => { return accum + (item.cost ? item.cost : 0) }, 0.0)
        },
    },
    mounted() {
        this.lookupData();
        this.getPayrolls();
        this.getPayrollSummary();
    },
    validations: {
        payroll: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        payrollDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getPayrolls: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: this.contractId,
                refEntity: this.entity,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/general/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.payrolls = result.payrollList;
                    this.totalRows = this.payrolls.length;
                    console.log(this.payrolls);
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getPayrollSummary: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: this.contractId,
                startDate: this.payroll.startDate,
                endDate: this.payroll.endDate,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/subcontract/payrollsummary/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.payrollSummaryList = result.contractPayrollSummary;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getExpenses: function(transactionDt) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                transactionDt: transactionDt,
            }
            this.isBusyDetail = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/list', data)
                .then((response) => {
                    this.isBusyDetail = false;
                    let result = response.data;
                    this.companyExpenditures = result.companyExpenditureList;
                })
                .catch(error => {
                    this.isBusyDetail = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: this.contractId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/subcontract/payroll/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.payrollTypes = result.typeLookup;
                    this.contract = result.contract;
                })
        },
        savePayroll: function() {
            this.payroll.refRecordId = this.contractId;
            this.$v.payroll.$touch();
            if (!this.$v.payroll.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/contract/add', this.payroll)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.getPayrolls();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateExpenditure: function(data) {
            this.payrollDetail.uomType = this.payrollDetail.unitOfMesurement.text
            this.payrollDetail.uomTypeId = this.payrollDetail.unitOfMesurement.id;
            this.showOverlay = true;
            this.$v.payrollDetail.$touch();
            if (!this.$v.payrollDetail.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/update', this.payrollDetail)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        this.elements.otherInfoEditMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getPayrolls();
                            this.cancelEditMode();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        deleteCompanyPayroll: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/companyexpenditure/delete/' + id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayrolls();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        showDetail(transactionDt) {
            this.payrollSummaryList = this.payrollSummaryList.map((data) => {
                if (this.getDateValue(data.transactionDt) == this.getDateValue(transactionDt)) {
                    return { ...data, _showDetails: true }
                } else {
                    return { ...data, _showDetails: false }
                }
            })
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.showSummary = false;
            this.payroll.id = '';
            this.payroll.payrollId = '';
            this.payroll.entityRecordMasterId = '';
            this.payroll.employeeId = '';
            this.payroll.employeeName = '';
            this.payroll.employeeCode = '';
            this.payroll.refEntityId = '';
            this.payroll.refRecordId = '';
            this.payroll.payrollScheduleTypeId = '';
            this.payroll.payrollPeriod = '';
            this.payroll.startDate = this.getDateValue(new Date());
            this.payroll.endDate = this.getDateValue(new Date());
            this.payroll.statusId = '';
            this.payroll.createdById = this.userId;
            this.payroll.earningDeductionList = [];
            this.$v.payroll.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            // this.$refs.title.$el.focus();
            this.elements.editMode = true;
            this.elements.visible = false;
            this.inlineEditId = data.id;
            this.payrollDetail.id = data.id;
            this.payrollDetail.payrollId = data.payrollId;
            this.payrollDetail.vhextAccountId = data.vhextAccountId;
            this.payrollDetail.projectId = data.projectId;
            this.payrollDetail.refEntityId = data.refEntityId;
            this.payrollDetail.refRecordId = data.refRecordId;
            this.payrollDetail.costEstimationTypeId = data.costEstimationTypeId;
            this.payrollDetail.csCategoryId = data.csCategoryId;
            this.payrollDetail.title = data.title;
            this.payrollDetail.quantity = data.quantity;
            this.payrollDetail.unitOfMesurement.id = data.uomTypeId;
            this.payrollDetail.unitOfMesurement.text = data.uomType;
            this.payrollDetail.uomTypeId = data.uomTypeId;
            this.payrollDetail.unitCost = data.unitCost;
            this.payrollDetail.cost = data.cost;
            this.payrollDetail.transactionDt = data.transactionDt;
            this.payrollDetail.description = data.description;
            this.payrollDetail.preparedById = data.preparedById;
            this.payrollDetail.createdDt = data.createdDt;
            this.payrollDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.payroll.id = data.id;
            this.payroll.payrollId = data.payrollId;
            this.payroll.vhextAccountId = data.vhextAccountId;
            this.payroll.projectId = data.projectId;
            this.payroll.refEntityId = data.refEntityId;
            this.payroll.refRecordId = data.refRecordId;
            this.payroll.costEstimationTypeId = data.costEstimationTypeId;
            this.payroll.csCategoryId = data.csCategoryId;
            this.payroll.title = data.title;
            this.payroll.quantity = data.quantity;
            this.payroll.unitOfMesurement.id = data.uomTypeId;
            this.payroll.unitOfMesurement.text = data.uomType;
            this.payroll.uomTypeId = data.uomTypeId;
            this.payroll.unitCost = data.unitCost;
            this.payroll.cost = data.cost;
            this.payroll.transactionDt = data.transactionDt;
            this.payroll.description = data.description;
            this.payroll.preparedById = data.preparedById;
            this.payroll.createdDt = data.createdDt;
            this.payroll.lastUpdatedDt = data.lastUpdatedDt;
        },
        viewItemDetails: function(data) {
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.contractPayrollPopupRef.getDetails(data.id, data.startDate, data.endDate);
            })
        },
        cancelEditMode: function() {
            this.inlineEditId = '';
            this.elements.editMode = false;
        },
        setTotalCost: function() {
            this.payroll.cost = this.payroll.quantity * this.payroll.unitCost;
        },
        setTotalCostDetail: function() {
            this.payrollDetail.cost = this.payrollDetail.quantity * this.payrollDetail.unitCost;
        },

    }
}
</script>