<template>
    <div class="Right-menu">
        <div class="Right-main-menu" style=" border-right: 1px solid #d3d3d3; ">
        	<ul>
                <li class="right-arrow arrows-menu"><a href="javascript:" ><i class="fa fa-angle-right" aria-hidden="true"></i></a></li>
                <li class="chatbox position-relative">
                    <a href="javascript:" @click.prevent="routePage('lannotif')" title="Notifications">
                        <i class="fa fa-bell" aria-hidden="true"></i>
                        <span v-if="newNotificationCount" class="badge badge-pill badge-danger alert-badge">
                            {{newNotificationCount > maxBadgeCount ? maxBadgeCount + "+" : newNotificationCount }}
                        </span>
                    </a>
                </li>
                <li class="chatbox">
                    <a href="javascript:" @click.prevent="routePage('contact')" title="Contacts">
                        <i class="fa fa-user-o" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="chatbox"><a href="javascript:" @click.prevent="routePage('meeting')" title="Meetings"><i class="fa fa-users" aria-hidden="true"></i></a></li>
                <li class="chatbox position-relative">
                    <a href="javascript:" @click.prevent="routePage('chatbox')" title="Chatbox">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        <span v-if="newMessageCount" class="badge badge-pill badge-danger alert-badge">
                            {{newMessageCount > maxBadgeCount ? maxBadgeCount + "+" : newMessageCount }}
                        </span>
                    </a>
                </li>
                <!-- <li class="chatbox">
                    <a href="javascript:" @click.prevent="routePage('mailbox')" title="Mailbox">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                    </a>
                </li> -->
                <li v-if="userTypeCode != 'CLI'" class="chatbox">
                    <a href="javascript:" @click.prevent="routePage('calendar')" title="Calendar">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                    </a>
                </li>
                <li class="chatbox"><a href="javascript:" @click.prevent="routePage('pdcmaslist')" title="Document Folder"><i class="fa fa-folder" aria-hidden="true"></i></a></li>
                <li class="chatbox"><a href="javascript:" @click.prevent="routePage('pbldoc')" title="E-Docs"><i class="fa fa-file-text" aria-hidden="true"></i></a></li>
                <li class="notebox"><a href="javascript:" title="Note"><i class="fa fa-lightbulb-o" aria-hidden="true"></i></a></li>
                
<!--                 <li class="chatbox"><a href="javascript:" @click.prevent="routePage('issbrd')" title="Issue Board"><i class="fa fa-bug" aria-hidden="true"></i></a></li> -->
            </ul>
        </div>
        <div class="Right-menu-content">
            <NotePad />
        </div>
    </div>
</template>
<script>
import websocketservice from "@/mixins/websocketservice";
import NotePad from '@/views/communication/NotePad';
import { EventBus } from '@/main';
import iziToast from 'izitoast';
export default {
    components: {
        NotePad
    },
    mixins: [websocketservice],
    data() {
        return {
            userTypeCode: localStorage.getItem("userTypeCode"),

            newNotificationCount: 0,
            newMessageCount: 0,

            maxBadgeCount: 100,
        }
    },
    computed: {
        unreadCount() {
            return this.$store.state.chatMessages.filter(data => !data.isRead)?.length ?? 0;
        },
    },
    created() {
        EventBus.$on('onNewNotification', (count) => {
            this.newNotificationCount = count;
        });
        EventBus.$on('onNewMessage', () => {
            this.newMessageCount = this.unreadCount;
        });
    },
    mounted() {
        this.connect(this.handleMessageReceived, this.handleNotificationReceived);
    },
    beforeDestroy() {
        this.disconnect();
        EventBus.$off('onNewNotification');
        EventBus.$off('onNewMessage');
    },
    methods: {
        routePage: function(argument) {
            let routeName = this.$route.name;
            let routePath = this.$route.path;
            if (routePath != null && routePath.indexOf('landing') != -1) {
                if (argument == 'chatbox') {
                    if (routeName == 'lanchatbox') return;
                    else this.$router.push('/landing/chatbox');
                }
                else if (argument == 'mailbox') {
                    if (routeName == 'lanmailbox') return;
                    else this.$router.push('/landing/mailbox');
                }
                else if (argument == 'contact') {
                    if (routeName == 'lancontact') return;
                    else this.$router.push('/landing/contact');
                }
                else if (argument == 'meeting') {
                    if (routeName == 'lancalevt') return;
                    else this.$router.push('/landing/calevt');
                }
                else if (argument == 'calendar') {
                    if (routeName == 'lancal') return;
                    else this.$router.push('/landing/cal');
                }
                else if (argument == 'issbrd') {
                    if (routeName == 'lanissbrd') return;
                    else this.$router.push('/landing/issbrd');
                }
                else if (argument == 'pdcmaslist') {
                    if (routeName == 'lanpdcmaslist') return;
                    else this.$router.push('/landing/pdcmas/list');
                }
                else if (argument == 'pbldoc') {
                    if (routeName == 'lanpbldoc') return;
                    else this.$router.push('/landing/pbldoc');
                }
               
                else if (argument == 'lannotif') {
                    if (routeName == 'lannotif') return;
                    else this.$router.push('/landing/notif');
                }
            }  else {
                if (argument == 'chatbox') {
                    if (routeName == 'chatbox') return;
                    else this.$router.push('/chatbox');
                }
                else if (argument == 'mailbox') {
                    if (routeName == 'mailbox') return;
                    else this.$router.push('/mailbox');
                }
                else if (argument == 'lannotif') {
                    if (routeName == 'lannotif') return;
                    else this.$router.push('/landing/notif');
                }
                else if (argument == 'contact') {
                    if (routeName == 'contact') return;
                    else this.$router.push('/contact');
                }
                else if (argument == 'meeting') {
                    if (routeName == 'calevt') return;
                    else this.$router.push('/calevt');
                }
                else if (argument == 'calendar') {
                    if (routeName == 'cal') return;
                    else this.$router.push('/cal');
                }
                else if (argument == 'issbrd') {
                    if (routeName == 'issbrd') return;
                    else this.$router.push('/issbrd');
                }
                else if (argument == 'pdcmaslist') {
                    if (routeName == 'pdcmaslist') return;
                    else this.$router.push('/pdcmas/list');
                }
                else if (argument == 'pbldoc') {
                    if (routeName == 'pbldoc') return;
                    else this.$router.push('/pbldoc');
                }
                
            }
        },
        handleMessageReceived(message) {
            this.$store.state.chatMessages.push(message);
            EventBus.$emit('onNewMessage', message);

            iziToast.show({
                theme: 'light',
                icon: 'fa fa-comments',
                class: 'chat-toast',
                timeout: 5000,
                title: message.chatByUser,
                message: message.chatBody,
                position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
                balloon: true,
                progressBar: false,
                // progressBarColor: 'rgb(0, 255, 184)',
                // onOpening: function(instance, toast){
                //     console.info('callback abriu!');
                // },
                // onClosing: function(instance, toast, closedBy){
                //     console.info('closedBy: ' + closedBy); // tells if it was closed by 'drag' or 'button'
                // }
            });
        },
        handleNotificationReceived(notification) {
            this.$store.state.notifications.push(notification);
            const notifications = this.$store.state.notifications.filter(data => !data.isRead)
            EventBus.$emit('onNewNotification', notifications.length);
        },
    }
}
</script>