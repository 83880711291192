<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-lg-4">
                            <h4 class="pl-2 mb-0 fs-18">Goal</h4>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Add Goal" :to="{ name: 'addgoal'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Goal" :to="{ name: 'editgoal', params: { goalid: goalId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="title">Title</label>
                                            <input type="text" class="form-control" id="title" disabled v-model="goal.title">
                                        </div>
                                    </div>
                                    <div class="col-12  col-sm-6">
                                        <div class="form-group">
                                            <label for="goalTypeId">Goal Type</label>
                                            <input type="text" class="form-control" id="goalTypeId" disabled v-model="goal.type">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="timeline">Timeline</label>
                                            <div class="input-group date disabled-date" disabled>
                                                <input type="text" class="form-control disabled" :value="goal.timeline | formatDate" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <label>Description</label>
                                        <textarea class="form-control" v-model="goal.description" disabled rows="5">
                                        </textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="goalId">Goal Id</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{goal.goalId}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="status">Status</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{goal.status}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="preparedBy">Prepared By</label>
                                            <input type="text" class="form-control" id="timeZone" disabled v-model="goal.userName">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        goalId() {
            return Number(this.$route.params.goalid);
        }
    },
    data() {
        return {
            goal: []
        }
    },
    mounted() {
        this.viewGoal(this.goalId);
    },
    methods: {
        viewGoal: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/goal/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.goal = result.goal;
                    console.log(this.goal);

                })

        }
    }
}
</script>