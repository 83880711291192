<template>
	<div>
		<b-modal :id="id" centered no-enforce-focus body-class="py-3" footer-class="py-2 px-3" header-class="py-2 px-3" size="lg" :title="title" >
            <div>
                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="description"></editor>
            </div>
            <template #modal-footer="{ cancel }">
            	<div class="w-100 text-right">
                    <button v-if="cancelBtn" type="button" class="btn sbtn btn-sm btn-secondary mr-1" @click="cancelClick()">Cancel</button>
                    <button v-if="saveBtn" type="button" class="btn sbtn btn-sm btn-primary" @click="saveClick()">Save</button>
                </div>
            </template>
        </b-modal>
	</div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
export default {
	props: {
        id:{type:String, default:'_description_edit'},
        title:{type:String, default:'Description'},
        returnParams:{type:Object, default:function () { return {} }},
        saveBtn:{type:String, default:'1'},
        cancelBtn:{type:String, default:'1'},
        onSave:Function,
        onCancel:{type:Function, default:function(){return false;}},

        textValue: {type: String, default: ''},
	},
    components: {
        Editor,
    },
	data() {
		return {
            description: '',

            toolbar: 'undo redo preview forecolor bullist numlist | formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent ',
            initObj: {
                height: 500,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
		}	
	},
	methods: {
        setDescriptionData:function(value){
            this.description = value;
        },
		saveClick:function(){
            this.onSave(this.description, this.returnParams);
            this.$bvModal.hide(this.id);
        },
        cancelClick:function(){
            this.onCancel();
            this.$bvModal.hide(this.id);
        },
	}
}
</script>