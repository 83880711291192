<template>
    <div>
        <nav class="navbar fixed-top">
            <div class="d-flex align-items-center navbar-left pl-3">
                <a class="navbar-logo" href="javascript:">
                    <!-- <span class="d-none d-xs-block">vhextech</span>
                    <span class="logo-mobile d-block d-xs-none">vhextech - </span> -->
                    <div v-if="logoImage && logoImage != 'null'">
                        <img alt="Profile Picture" class="Companylogo-img" :src="logoImage" />
                    </div>
                    <div v-else>
                        <img alt="Profile Picture" class="Companylogo-img" src="@/assets/images/logo.png" />
                    </div>
                </a>
            </div>
            <div class="navbar-right">
                <!-- <div v-if="elements.showSwitchUser" class="HorizontalIconMenus d-inline-block">
                    <ul>
                        <li><a href="javascript:" @click.prevent="showCompanyCalendar" title="Company Calendar"><i class="fa fa-calendar" aria-hidden="true"></i></a></li>
                        <li><a href="javascript:" title="Settings" @click.prevent="routeSettings"><i class="fa fa-cogs" aria-hidden="true"></i></a></li>
                    </ul>
                </div> -->
            </div>
        </nav>
        <div class="landing-page mr-0">
            <slot />
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    components: {
    },
    data() {
        return {
            elements: {
                showLogoBlock: false,
                showMenu: false
            },
            configloaded: false,
            usertype: '',
            login: {
                authToken: ''
            }
        }
    },
    computed: {
        logoImage() {
            let logoImage = localStorage.getItem('logo');
            if (logoImage && logoImage != 'null') {
                return logoImage;
            }
            else {
                this.$store.state.companyLogo;
            }
        }
    },
    mounted() {
    },
    methods: {
    }
}
</script>