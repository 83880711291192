<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Testing / Verification</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div style="overflow-x:auto;"">
                                <!-- style=" height: 600px;overflow: scroll; -->
                                <b-table show-empty small class="preview-table" head-variant=:primary style="width: 1200px;" selectable :select-mode="selectMode" sort-icon-left sticky-header="1200px" :no-border-collapse="noCollapse" :items="testCaseResults" @row-selected="onRowSelected" :fields="fields" responsive :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                    </template>
                                    <template v-slot:cell(description)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                    </template>
                                    <template v-slot:cell(expectedResult)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                    </template>
                                    <template v-slot:cell(remarks)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Test Case found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                            </div>
                            <b-pagination v-if="testCaseResults.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4" id="idOfDiv">
                        <!-- <div class="card mb-4" style="position:fixed;"> -->
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Test Result">
                                    <div class="row input-group-label">
                                        <div class="col-3 pr-0">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{testResult.testCaseId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-7 pl-2">
                                            <b-input-group prepend="Status" class="mb-3">
                                                <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="testResult.status"></b-form-input>
                                                <b-dropdown v-if="elements.editMode" :text="statusText" class="btn-dd" v-model="testResult.statusId">
                                                    <b-dropdown-item v-for="(info, index)  in statuses" :key="info.id" :value="info.id" @click="testResult.statusId = info.id;statusText = info.value">
                                                        {{info.value}}
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="saveTestResult" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <label for="title1">Title</label>
                                            <input type="text" id="title1" class="form-control" placeholder="Enter Title" :disabled="!elements.editMode" v-model="testResult.title">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="remarks">Tester Remarks</label>
                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="testResult.remarks"></editor>
                                            </div>
                                            <!--                                             <div class="form-group">
                                                <label for="remarks">Test Remarks</label>
                                                <textarea rows="4" class="form-control" id="remarks" :disabled="!elements.editMode" v-model="testResult.remarks"></textarea>     
                                            </div> -->
                                            <button  v-if="elements.editMode" type="submit" @click="saveTestResult" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button  v-if="elements.editMode" type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TestingDetailPopup v-if="showDialog" ref="testingDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="testingPopupId" @closeDialog="closeDialog" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <Dialog id="deleteTestCase" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Test Case" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TestingDetailPopup from '@/components/popup/itemdetail/TestingDetailPopup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        HTMLDetailViewPopup,
        TestingDetailPopup,
        Editor,
    },

    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            

            stickyHeader: true,
            noCollapse: false,

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            htmlData: '',
            htmlDetailViewTitle: 'Details',

            showDialog: false,
            
            notEnabled: true,
            statuses: [],
            statusText: '',

            testResult: {
                id: '',
                testResultId: '',
                title: '',
                tcId: '',
                testCaseId: '',
                testPlanId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                testedById: localStorage.getItem("userId"),
                testedBy: '',
                entity: 'test_result',
                description: '',
                expectedResult: '',
                executionSteps: '',
                remarks: '',
                preparedDt: '',
                statusId: '',
                status: '',
                testDate: ''
            },
            referencePopupId: 0,
            refPopupTitle: '',
            refPopupEntity: '',
            detailViewData: {
                id: '',
                testCaseId: '',
                title: '',
                description: '',
                expectedResult: '',
                status: '',
                type: '',
                testedBy: '',
                userName: '',
                preparedDt: '',
                testDate: '',
                remarks: ''
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },

            detailViewData: {},
            testingPopupId: 0,

            //testResult: [],
            testCaseResults: [],
            fields: [
                { key: 'testCaseId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', stickyColumn: true, label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Test Description', tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'expectedResult', label: 'Expected Result', tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'remarks', label: 'Tester Remarks', tdClass: 'table-cw-300', sortable: true, sortDirection: 'desc' },
                { key: 'type', stickyColumn: true, label: 'Test Type', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },

                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
/*                { key: 'actions', label: 'Actions' }*/
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            initObj: {
                height: 300,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getTestCase();
    },
    validations: {
        testResult: {
            statusId: {
                required,

            }
        },
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'test_result';
            this.userId = localStorage.getItem("userId");
        },

        getTestCase: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/test/testcaseresult/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.testCaseResults = result.testCaseResultList;
                     if (this.testCaseResults != null && this.testCaseResults.length > 0) {
                        this.totalRows = this.testCaseResults.length;
                        this.assignTestResult(this.testCaseResults[0]);              
                    }

                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'test_result',
                refEntity:'test_case'
            }

            axios.post(this.$store.getters.getAPIBasePath + '/test/testresult/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.statuses = result.statusLookup;
                    console.log(result);
                })
        },
        saveTestResult: function() {

            this.testResult.testCaseId = this.testResult.id;
            console.log(this.testResult);
            this.$v.testResult.$touch();
            if (!this.$v.testResult.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/test/testresult/add', this.testResult)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.testResult.testCaseId = this.testResult.tcId;
                            this.getTestCase();
                            this.elements.editMode = false;
                            this.elements.visible = false;
                            this.elements.viewMode = false;
                            //this.clearData();
                        }
                    })
            }
        },
        viewTestResult: function(id) {
            this.clearData();
            axios.get(this.$store.getters.getAPIBasePath + '/test/testresult/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.testResult = result.testResult;
                    }
                    console.log(this.testResult);

                })
            this.elements.editMode = false;
        },
        cancelEditMode: function() {
            this.viewTestResult(this.testResult.id);
        },
        deleteTestCase: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/test/testcase/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTestCase();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.testResult.title = '';
            this.testResult.testCaseTypeId = '';
            this.testResult.description = '';
            this.testResult.preparedUserId = '';
            this.testResult.expectedResult = '';
            this.testResult.executionSteps = '';
            this.testResult.testPlanId = '';
            this.testResult.executionSteps = '';
            this.testResult.preparedDt = '';
            this.testResult.testCaseId = '';
            this.testResult.statusId = '';
            this.testResult.remarks = '';
            this.$v.testResult.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.testResult.id = data.id;
            this.testResult.testCaseId = data.testCaseId;
            this.testResult.title = data.title;
            this.testResult.testCaseTypeId = data.testCaseTypeId;
            this.testResult.description = data.description;
            this.testResult.expectedResult = data.expectedResult;
            this.testResult.preparedUserId = data.preparedUserId;
            this.testResult.executionSteps = data.executionSteps;
            this.testResult.testPlanId = data.testPlanId;
            this.testResult.executionSteps = data.executionSteps;
            this.testResult.preparedDt = data.preparedDt;
            this.testResult.statusId = data.statusId;
            this.testResult.remarks = data.remarks;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.editMode = false;
            this.elements.viewMode = true;
            this.testResult.testCaseId = data.testCaseId;
            this.testResult.title = data.title;
            this.testResult.type = data.type;
            this.testResult.description = data.description;
            this.testResult.expectedResult = data.expectedResult;
            this.testResult.userName = data.userName;
            this.testResult.createdDt = data.createdDt;
            this.testResult.lastUpdatedDt = data.lastUpdatedDt;
            this.testResult.id = data.id;
            this.testResult.testCaseTypeId = data.testCaseTypeId;
            this.testResult.preparedUserId = data.preparedUserId;
            this.testResult.executionSteps = data.executionSteps;
            this.testResult.testPlanId = data.testPlanId;
            this.testResult.executionSteps = data.executionSteps;
            this.testResult.preparedDt = data.preparedDt;
            this.testResult.statusId = data.statusId;
            this.testResult.status = data.status;
            this.testResult.remarks = data.remarks;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data.id, data.testCaseId);
                //this.viewTestResult(data.id);

                this.elements.editMode = false;
                this.elements.showDetail = true;
                this.assignTestResult(data);
            }
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.testingPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.testingDetailPopupRef.getDetails(this.testingPopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        assignTestResult: function(data) {
           this.testResult.id = data.id;
           this.testResult.testCaseId = data.testCaseId.toString();
           this.testResult.tcId = data.testCaseId.toString();
           this.testResult.title = data.title;
           this.testResult.vhextAccountId = data.vhextAccountId;
           this.testResult.projectId = data.projectId;
           this.testResult.testPlanId = data.testPlanId;
           this.testResult.entityRecordMasterId = data.entityRecordMasterId;
           this.testResult.description = data.description;
           this.testResult.expectedResult = data.expectedResult;
           this.testResult.executionSteps = data.executionSteps;
           if (data.remarks != null) {
               this.testResult.remarks = data.remarks;
           } else {
               this.testResult.remarks = '';
           }
           this.testResult.testDate = data.testDate;
           this.testResult.statusId = data.statusId;
           this.testResult.status = data.status;
           this.statusText = data.status;
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },
        viewReference: function(data) {
            this.detailViewData.id = data.id;
            this.detailViewData.testCaseId = data.testCaseId;
            this.detailViewData.title = data.title;
            this.detailViewData.description = data.description;
            this.detailViewData.expectedResult = data.expectedResult;
            this.detailViewData.status = data.status;
            this.detailViewData.type = data.type;
            this.detailViewData.testedBy = data.testedBy;
            this.detailViewData.userName = data.userName;
            this.detailViewData.preparedDt = data.preparedDt;
            this.detailViewData.testDate = data.testDate;
            this.detailViewData.remarks = data.remarks;

            this.referencePopupId = data.id;
            this.refPopupTitle = data.title;
            this.refPopupEntity = 'test_result';
            this.$refs.referenceDetailRef.getRequirementSpecs();
            console.log(data);
        }

    }
}
</script>