<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="row">
                <div class="col-12 text-left">
                    <div class="card-body p-0 mb-1">
                        <div class="row">
                            <div class="col-6" :class="popupData.leaveTypeCode=='LVE' ? 'col-sm-6' : 'col-sm-7'">
                                <div class="row mb-2">
                                    <div class="col-6 col-sm-4 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Applied By</label>
                                    </div>
                                    <div class="col-6 col-sm-6 px-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.requestedBy}}</label>
                                    </div>

                                </div>
                            </div>
                            <div v-if="popupData.leaveTypeCode=='LVE'" title="Leave Type" class="col pr-0 text-center">
                                <label class="p-2 mb-0 d-block header-details">{{popupData.leaveType}}</label>
                            </div>
                            <div class="col-2 col-sm-2">
                                <template v-if="updateLeaveStatus">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.statusUpdate.statusId.$error }">
                                        <select v-model="statusUpdate.statusId" class="form-control" :class="{ 'is-invalid': $v.statusUpdate.statusId.$error }">
                                            <option value="" disabled>Status</option>
                                            <option v-for="(info, index) in filteredStatusLookup" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.statusUpdate.statusId.$error && !$v.statusUpdate.statusId.required" class="text-danger">Please select status</label>
                                </template>
                                <h6 v-else class="text-right"><label class="badge text-white" :class=leaveStatusVariant(popupData.status)  title="Status">{{ popupData.status }}</label></h6>
                            </div>
                            <div class="col-12 col-sm-12">
                                <div class="row mb-2">
                                    <div class="col-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Subject</label>
                                    </div>
                                    <div class="col-10 pl-0">
                                        <label class="py-2 pl-2 mb-0 d-block header-details font-weight-bold">{{ popupData.subject }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <div class="row">
                                    <div class="col-4 col-sm-2 pr-0 mb-2">
                                        <label class="mb-0 p-1 py-2 d-block title-block">
                                            {{ popupData.leaveTypeCode=='LVE' ? 'From Date' : 'Permission Date' }}
                                        </label>
                                    </div>
                                    <div class="col-8 col-sm-3 px-0 mb-2">
                                        <label class="p-2 mb-0 d-block header-details">
                                            {{ popupData.fromDt | formatDate }}
                                            <span v-if="popupData.leaveTypeCode=='LVE'" class="float-right">{{ getHalfDayLeave(popupData.startHalfDay) }}</span>
                                        </label>
                                    </div>
                                    <div class="col-4 col-sm-2 pr-0 mb-2">
                                        <label class="mb-0 p-1 py-2 d-block title-block">
                                            {{ popupData.leaveTypeCode=='LVE' ? 'Till Date' : 'Permission Time' }}
                                        </label>
                                    </div>
                                    <div class="col-6 col-sm-3 px-0 mb-2">
                                        <label v-if="popupData.leaveTypeCode=='LVE'" class="p-2 mb-0 d-block header-details">
                                            {{ popupData.tillDt | formatDate }}
                                            <span v-if="popupData.leaveTypeCode=='LVE'" class="float-right">{{ getHalfDayLeave(popupData.endHalfDay) }}</span>
                                        </label>
                                        <label v-else class="p-2 mb-0 d-block header-details">
                                            {{ popupData.fromDt | formatTimeFromDt }} to {{ popupData.tillDt | formatTimeFromDt }}
                                        </label>
                                    </div>
                                    <div class="col-2 col-sm-2 mb-2">
                                        <label class="p-2 mb-0 d-block header-details text-center">
                                            <span v-if="popupData.totalDays">
                                                {{ popupData.totalDays }} {{ popupData.leaveTypeCode=='LVE' ? 'Days' : 'Hours' }}
                                            </span>
                                            <span v-else>-</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <div class="card mb-2">
                                    <div class="card-body py-1 px-0">
                                        <p class="contact-title mb-2 ml-2"><span>Description</span></p>
                                        <div class="row">
                                            <div class="col-12 col-sm-12 m-0">
                                                <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!hasWorkflowConfig" class="col-12 col-sm-12">
                                <div class="row mb-2">
                                    <!-- <div class="col-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Recommended</label>
                                    </div>
                                    <div class="col-4 pl-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.recommendedBy}}</label>
                                    </div> -->
                                    <div class="col-4 col-sm-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Applied Dt</label>
                                    </div>
                                    <div class="col-8 col-sm-4 pl-0">
                                        <label class="p-2 mb-0 d-block header-details">{{popupData.createdDt | formatDate}}</label>
                                    </div>
                                    <div v-if="popupData.requestedTo">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 py-2 d-block title-block">Approval By</label>
                                        </div>
                                        <div class="col-4 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{popupData.requestedTo}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                
                            </div>
                            <div class="col-12 col-sm-12">
                                <div class="card mb-2">
                                    <div class="card-body py-1 px-0">
                                        <p class="contact-title mb-2 ml-2"><span>Work Plan</span></p>
                                        <div class="row">
                                            <div class="col-12 col-sm-12 m-0">
                                                <label class="p-2 mb-0 d-block header-details" v-html="popupData.workPlan"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="attachmentLength" class="col-12 col-sm-12">
                                <div class="row mt-0 mb-2">
                                    <div class="col-12 col-sm-2 pr-0">
                                        <label class="mb-0 p-1 py-2 d-block title-block">Attachment</label>
                                    </div>
                                    <div class="col-12 col-sm-10 pl-0">
                                        <div class="col-12">
                                            <div class="mt-2">
                                                <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentLength" :attachmentData="attachmentData" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="updateLeaveStatus" class="row my-3">
                <div class="col-12 text-right">
                    <button type="submit" @click="saveStatusChange" class="btn btn-primary py-1">Update Status</button>
                </div>
            </div>
            <template v-if="popupData.leaveTypeCode == 'LVE'">
                <div class="row view-filter">
                    <div class="col">
                        <h4 class="my-1 fs-16">Leaves Available</h4>
                    </div>
                </div>
                <b-table show-empty outlined small stacked="md" sort-icon-left :items="empLeavePolicyList" :fields="empLeavePolicyFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    
                    <template v-slot:cell(leavesRemaining)="data">
                        <div class="text-center">{{ empLeavePolicyList[data.index].daysPerYear - empLeavePolicyList[data.index].leaveTakenCount }}</div>
                    </template>

                    <template v-slot:empty="scope">
                        <div class="text-center">No Leaves found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
            </template>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    name: 'leaveTimeDetailComponent',
    props: {
        detailViewData: Object,
        attachmentData: Object,
        updateLeaveStatus: Boolean
    },
    components: {
        AttachmentView,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: 'leave_time',
            popupData: {},
            attachmentList: {},
            attachmentLength: 0,
            hasWorkflowConfig: 0,

            statusUpdate: {
                id: '',
                statusUpdateId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                functionalEntityId: '',
                functionalEntity: '',
                refRecordTitle: '',
                refEntity: '',
                refRecordId: '',
                statusById: localStorage.getItem("userId"),
                statusId: '',
                status: '',
                remarks: '',
                workflowRequestId: '',
                workflowActivityId: '',
                isWorkflowAutomated: '',
            },

            empLeavePolicyList: [],
            empLeavePolicyFields: [
                { key: 'index', label: 'SN' },
                // { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'leaveType',label: 'Leave Type', sortable: true,sortDirection: 'desc' },
                { key: 'daysPerYear',label: 'Total Leaves / Year', sortable: true,sortDirection: 'desc' },
                { key: 'leaveTakenCount',label: 'Leaves Taken', sortable: true,sortDirection: 'desc' },
                { key: 'leavesRemaining',label: 'Leaves Remaining', sortable: true,sortDirection: 'desc' },
            ],

            statusLookup: [],

            sortDesc: false,
            sortBy: '',
            sortDirection: 'asc',
        }
    },
    computed: {
        itemDetailTitle() {
            if(this.popupData.leaveTypeCode)
                return this.popupData.leaveTypeCode=='LVE' ? 'Leave Application' : 'Permission Application';
        },
        filteredStatusLookup() {
            if(this.statusUpdate.isWorkflowAutomated) {
                return this.statusLookup;
            }
            else {
                if(this.updateLeaveStatus) {
                    return this.statusLookup.filter(data => data.value === 'Approved')
                }
                else {
                    return [];
                }
            }
        }
    },
    validations: {
        statusUpdate: {
            statusId: {
                required
            },
        },
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.showOverlay = true;
            if (refEntity === "leave_time") {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/leavetime/view/' + id)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.leaveTime) {
                            this.popupData = result.leaveTime;
                            this.hasWorkflowConfig = result.hasWorkflowConfig;
                            this.$nextTick(() => {
                                this.$emit('getItemTitle', this.itemDetailTitle);
                            })
                            if (this.popupData.leaveTypeCode=='LVE') {
                                this.getEmpLeavePolicies();
                            }
                            if(this.updateLeaveStatus) {
                                this.getWorkflowRequest();
                            }
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    });
            } else {
                this.popupData = this.detailViewData;
                if (this.popupData.jobCategoryId && this.popupData.leaveTypeCode=='LVE') {
                    this.getEmpLeavePolicies();
                }
                this.$nextTick(() => {
                    this.$bvModal.show('leaveTimeDetailPopup');
                });
                this.$nextTick(() => {
                    this.$emit('getItemTitle', this.itemDetailTitle);
                })
                this.showOverlay = false;
            }
        },
        getWorkflowRequest: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                refRecordId: this.popupData.id,
                refEntity: 'leave_time',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowrequest/view', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.workflowRequest = result.workflowRequest;
                    this.setStatusUpdateData();
                    this.lookupData();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'leave_time',
                refRecordId: this.workflowRequest?.refRecordId,
                workflowActivityId: this.workflowRequest?.workflowActivityId,
                isWorkflowAutomated: this.workflowRequest?.workflowActivityId ? 1 : 0,
                statusId: this.popupData.statusId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/statusupdate/lookup/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.statusLookup = result.statusLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        getEmpLeavePolicies: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.popupData.requestedById,
                jobCategoryId: this.popupData.jobCategoryId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empleavepolicy/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.empLeavePolicyList = result.empLeavePolicyList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        setStatusUpdateData() {
            this.statusUpdate.functionalEntityId = this.workflowRequest?.functionalEntityId;
            this.statusUpdate.functionalEntity = 'leave_time';
            this.statusUpdate.refRecordTitle = this.popupData.subject;
            this.statusUpdate.refEntity = 'leave_time';
            this.statusUpdate.refRecordId = this.popupData?.id;
            this.statusUpdate.workflowRequestId = this.workflowRequest?.id;
            this.statusUpdate.workflowActivityId = this.workflowRequest?.workflowActivityId;
            this.statusUpdate.isWorkflowAutomated = this.workflowRequest?.workflowActivityId ? true : false;
        },
        saveStatusChange: function() {
            this.$v.statusUpdate.$touch();
            if (!this.$v.statusUpdate.$invalid) {
                this.statusUpdate.isWorkflowAutomated = this.statusUpdate.isWorkflowAutomated ? this.statusUpdate.isWorkflowAutomated : false;
                if(this.statusUpdate.isWorkflowAutomated){
                    this.statusUpdateURL = '/common/workflow/statusupdate/add';
                }else{
                    this.statusUpdateURL = '/common/statusupdate/add';
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + this.statusUpdateURL, this.statusUpdate)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getDetails();
                            this.$emit('updateParent');

                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })

            }
        },
        getHalfDayLeave(value) {
            let result;
            if (value == 2) {
                result = '1st Half Day';
            } else if (value == 3) {
                result = '2nd Half Day';
            } else {
                result = '';
            }
            return result;
        },
        leaveStatusVariant(status) {
            let className = '';
            if (status == 'Applied') {
                className = 'bg-warning';
            }
            else if (status == 'Approved') {
                className = 'bg-success';
            }
            else if (status == 'Rejected') {
                className = 'bg-danger';
            }
            else {
                className = 'bg-info';
            }
            return className;
        },
        getAttachmentLength(length) {
            this.attachmentLength = length;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>