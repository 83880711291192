<template>
    <div>
        <FeedbackDetailMasterList :ermMasterInfo="ermMasterInfo"></FeedbackDetailMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FeedbackDetailMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        FeedbackDetailMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Feedback Detail List",
                entity: 'feedback_detail_master',
                refEntity: 'feedback_detail',
                tooltip: 'New Feedback Detail',
                redirectURLName: 'clifdb',
                newEntityMasterURL: 'fbdmas',
                redirectURL: '/fbdmas',
                actionName: 'Feedback Detail'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>