<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row">
                <div class="col-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Projects 
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Projects">{{ projectCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <PieChart :styles="myStyles" :chartdata="projectByStatusData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Users
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Users">{{ userCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="userByTypeData" :options="chartOptionsNoLegend" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">
                                            Clients
                                            <span class="badge badge-light float-right pointer py-1 px-2" title="Total Clients">{{ clientCount }}</span>
                                        </p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="clientByTypeData" :options="chartOptions" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 mb-1">
                                    <div class="card p-1">
                                        <p class="chart-title text-left pl-1 mb-1">Age Distribution</p>
                                        <div class="separator mb-1"></div>
                                        <div class="card-body p-1 text-center">
                                            <DoughnutChart :styles="myStyles" :chartdata="ageDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    <div class="row">
                        <div class="col-12 mb-2">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <div class="col-4">
                                            <div class="form-row">
                                                <div class="col">
                                                    <p class="chart-title pl-1 mb-1">Finance</p>
                                                </div>
                                            </div>
                                            <div class="card p-1">
                                                <div class="form-row">
                                                    <div class="col-md-3 col-xl-3">
                                                        <div class="form-row text-center px-1 mt-4">
                                                            <div class="col-md-12 col-xl-12 pr-0">
                                                                <div class="card bg-light-grey text-center p-1">
                                                                    <div class="card-body px-0 py-2">
                                                                        <p class="chart-title fs-12 mb-0 text-dark">Today</p>
                                                                        <!-- <div class="p mb-0 text-dark">Today</div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 col-xl-12 pr-0 mt-1">
                                                                <div class="card bg-light-grey text-center p-1">
                                                                    <div class="card-body px-0 py-2">
                                                                        <p class="chart-title fs-12 mb-0 text-dark">Overall</p>
                                                                        <!-- <div class="p mb-0 text-dark">Overall</div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9 col-xl-9 text-center">
                                                        <div class="form-row">
                                                            <div class="col-md-4 col-xl-4 pl-0">
                                                                <p class="chart-title fs-12 pl-1 mb-1">Total Income</p>
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-success mb-0">{{ parseFloat(totalIncomeByDate.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <p class="chart-title fs-12 pl-1 mb-1">Total Expense</p>
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-danger mb-0">{{ parseFloat(totalExpenseByDate.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <p class="chart-title fs-12 pl-1 mb-1">Net Amount</p>
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="mb-0" :class="textColourByAmount(totalNetAmountByDate)">{{ parseFloat(totalNetAmountByDate.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row mt-1">
                                                            <div class="col-md-4 col-xl-4 pl-0">
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-success mb-0">{{ parseFloat(totalIncome.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-danger mb-0">{{ parseFloat(totalExpense.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="mb-0" :class="textColourByAmount(totalNetAmount)">{{ parseFloat(totalNetAmount.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-row">
                                                <div class="col">
                                                    <p class="chart-title pl-1 mb-1">Account Payable / Receivable</p>
                                                </div>
                                            </div>
                                            <div class="card p-1">
                                                <div class="form-row">
                                                    <div class="col-md-3 col-xl-3">
                                                        <div class="form-row text-center px-1 mt-4">
                                                            <div class="col-md-12 col-xl-12 pr-0">
                                                                <div class="card bg-light-grey text-center p-1">
                                                                    <div class="card-body px-0 py-2">
                                                                        <p class="chart-title fs-12 mb-0 text-dark">Today</p>
                                                                        <!-- <div class="p mb-0 text-dark">Today</div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 col-xl-12 pr-0 mt-1">
                                                                <div class="card bg-light-grey text-center p-1">
                                                                    <div class="card-body px-0 py-2">
                                                                        <p class="chart-title fs-12 mb-0 text-dark">Overall</p>
                                                                        <!-- <div class="p mb-0 text-dark">Overall</div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-9 col-xl-9 text-center">
                                                        <div class="form-row">
                                                            <div class="col-md-4 col-xl-4 pl-0">
                                                                <p class="chart-title fs-12 pl-1 mb-1">Payable</p>
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-danger mb-0">{{ parseFloat(payableAmount.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <p class="chart-title fs-12 pl-1 mb-1">Receivable</p>
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-success mb-0">{{ parseFloat(receivableAmount.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <p class="chart-title fs-12 pl-1 mb-1">Net Amount</p>
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="mb-0" :class="textColourByAmount(netPayRec)">{{ parseFloat(netPayRec.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-row mt-1">
                                                            <div class="col-md-4 col-xl-4 pl-0">
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-danger mb-0">{{ parseFloat(payableAmountOverall.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="text-success mb-0">{{ parseFloat(receivableAmountOverall.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 col-xl-4">
                                                                <div class="card text-center p-1">
                                                                    <div class="card-body px-1 py-2">
                                                                        <div class="mb-0" :class="textColourByAmount(netPayRecOverall)">{{ parseFloat(netPayRecOverall.toFixed(2)) }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <p class="chart-title pl-1 mb-1">Today's Transactions</p>
                                            <div class="card text-center p-1">
                                                <div class="card-body px-3 py-2">
                                                    <BarChart :styles="myStyles" :chartdata="dailyFinancialTxn" :options="barChartOptionsSmall" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mb-2">
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="form-row">
                                        <!-- <div class="col-md-4 col-xl-4">
                                            <div class="card mb-4 p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Work Type</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body py-1">
                                                    <PieChart :styles="myStyles" :chartdata="workTypeChartData" :options="chartOptions" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-md-8 col-xl-8">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Finance By Month</p>
                                                <div class="separator mb-1"></div>
                                                    <BarChart :chartdata="financialTxnByMonth" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                <div class="card-body p-0">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 col-xl-4">
                                            <div class="card p-3">
                                                <p class="chart-title text-left pl-1 mb-1">Project Finance</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-0">
                                                    <HorizontalBarChart :chartdata="financialTxnByProject" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                                                </div>
                                            </div>
                                        </div>
                                        <!--  <div class="col-md-4 col-xl-4">
                                            <div class="card mb-4 p-3">
                                                <p class="chart-title text-left">Scope</p>
                                                <div class="card-body d-flex p-0">
                                                    <PieChart :chartdata="genderDistributionData" :options="chartOptions" class="d-block w-100-h-350" />
                                                </div>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-md-4 col-xl-4">
                                            <div class="card p-1 h-100">
                                                <p class="chart-title text-left pl-1 mb-1">Users</p>
                                                <div class="separator mb-1"></div>
                                                <div class="card-body p-1 text-center">
                                                    <DoughnutChart :styles="myStyles" :chartdata="userByTypeData" :options="chartOptionsLarge" class="" :key="pieChartKey"/>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-2">
                    <div class="card">
                        <div class="card-body px-0 py-3" >
                            <b-tabs content-class="mt-3" lazy small>
                                <b-tab title="Today">
                                    <div class="col-md-12 col-xl-12">
                                        <div class="card p-1">
                                            <p class="chart-title text-left pl-1 mb-1">Age Distribution</p>
                                            <div class="separator mb-1"></div>
                                            <div class="card-body p-1 text-center">
                                                <DoughnutChart :styles="myStyles" :chartdata="ageDistributionData" :options="chartOptions" class="" :key="pieChartKey"/>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="This Week">
                                </b-tab>
                                <b-tab title="This Year">
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div> -->
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import DoughnutChart from "@/components/blocks/charts/DoughnutChart.vue";
import HorizontalBarChart from "@/components/blocks/charts/HorizontalBarChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";
export default {
    name: "App",
    components: {
        BarChart,
        DoughnutChart,
        HorizontalBarChart,
        PieChart,
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),


            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            projectCount: 0,
            userCount: 0,
            clientCount: 0,

            financialTxnSummary: [],
            financialTxnSummaryByDate: [],
            financialTxnByMonth: null,
            payableReceivableSummary: null,
            payableReceivableOverallSummary: null,
            
            financialTxnByProject: null,
            dailyFinancialTxn: null,
            resourcePoolData: null,
            workTypeChartData: null,
            genderDistributionData: null,
            ageDistributionData: null,
            projectByStatusData: null,
            clientByTypeData: null,
            userByTypeData: null,

            chartOptionsNoLegend: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'right',
                },
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/          },
            chartOptionsLarge: {
                responsive: true,
                maintainAspectRatio: true,
                legend: {
                    display: true,
                    position: 'top',
                },
            },
            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                },
                scales: {
                    xAxes: {
                        stacked: true,
                        position: 'top'
                    },
                    yAxes: {
                        beginAtZero: true,
                    },
                }
            },
            barChartOptionsSmall: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            display: false
                        }
                    }]
                }
            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []

        }
    },
    computed: {
        myStyles() {
            return {
                height: '100px',
                width: 'auto',
                // width: '230px',
                position: 'relative'
            }
        },
        totalIncome() {
            if (this.financialTxnSummary.length > 0) {
                return this.financialTxnSummary.reduce((accum, item) => {
                    return accum + parseFloat(item.incomeAmount ? item.incomeAmount : 0)
                }, 0.00)
            }
            else return 0;
        },
        totalExpense() {
            if (this.financialTxnSummary.length > 0) {
                return this.financialTxnSummary.reduce((accum, item) => {
                    return accum + parseFloat(item.expenseAmount ? item.expenseAmount : 0)
                }, 0.00)
            }
            else return 0;
        },
        totalNetAmount() {
            if (this.financialTxnSummary.length > 0) {
                return this.financialTxnSummary.reduce((accum, item) => {
                    return accum + parseFloat(item.netAmount ? item.netAmount : 0)
                }, 0.00)
            }
            else return 0;
        },
        totalIncomeByDate() {
            if (this.financialTxnSummaryByDate.length > 0) {
                return this.financialTxnSummaryByDate[0].incomeAmount;
            }
            else return 0;
        },
        totalExpenseByDate() {
            if (this.financialTxnSummaryByDate.length > 0) {
                return this.financialTxnSummaryByDate[0].expenseAmount;
            }
            else return 0;
        },
        totalNetAmountByDate() {
            if (this.financialTxnSummaryByDate.length > 0) {
                return this.financialTxnSummaryByDate[0].netAmount;
            }
            else return 0;
        },
        payableAmountOverall() {
            if (this.payableReceivableOverallSummary) {
                return this.payableReceivableOverallSummary.payableAmount;
            }
            else return 0;
        },
        receivableAmountOverall() {
            if (this.payableReceivableOverallSummary) {
                return this.payableReceivableOverallSummary.receivableAmount;
            }
            else return 0;
        },
        netPayRecOverall() {
            if (this.payableReceivableOverallSummary) {
                return this.payableReceivableOverallSummary.txnAmount;
            }
            else return 0;
        },
        payableAmount() {
            if (this.payableReceivableSummary) {
                return this.payableReceivableSummary.payableAmount;
            }
            else return 0;
        },
        receivableAmount() {
            if (this.payableReceivableSummary) {
                return this.payableReceivableSummary.receivableAmount;
            }
            else return 0;
        },
        netPayRec() {
            if (this.payableReceivableSummary) {
                return this.payableReceivableSummary.txnAmount;
            }
            else return 0;
        },
    },
    mounted() {
        this.getDashboardData();
    },
    validations: {
    },
    methods: {
        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                transactionDt: new Date(),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/fnmdata/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);

                    this.financialTxnSummary = result.financialTxnSummary;
                    this.financialTxnSummaryByDate = result.financialTxnSummaryByDate;
                    this.payableReceivableSummary = result.payableReceivableSummary;
                    this.payableReceivableOverallSummary = result.payableReceivableOverallSummary;

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.financialTxnByMonth != null) {
                        this.financialTxnByMonth = {
                            labels: result.financialTxnByMonth.map(item => item.dateString),
                            datasets: [{
                                label: 'Income',
                                data: result.financialTxnByMonth.map(item => item.incomeAmount),
                                backgroundColor: '#28a745',
                                // borderColor: 'rgba(75, 192, 192, 1)',
                                // borderWidth: 1,
                                borderSkipped: false,
                              },
                              {
                                label: 'Expense',
                                data: result.financialTxnByMonth.map(item => -item.expenseAmount),
                                backgroundColor: '#d85a5a',
                                // borderColor: 'rgba(255, 26, 104, 1)',
                                // borderWidth: 1,
                                borderSkipped: false,
                              }]
                            }
                    }
                    if (result.dailyFinancialTxn != null) {
                        this.dailyFinancialTxn = {
                            labels: result.dailyFinancialTxn.map(item => item.title),
                            datasets: [{
                                    backgroundColor: result.dailyFinancialTxn.map((item) => {
                                        if (item.typeClass == 1)
                                            return '#28a745';
                                        else return '#d85a5a'
                                    }),
                                    data: result.dailyFinancialTxn.map((item) => item.amount)
                                }
                            ]
                        }

                    }
                    if (result.financialTxnByProject != null) {
                        this.financialTxnByProject = {
                            labels: result.financialTxnByProject.map(item => item.project),
                            datasets: [{
                                    label: 'Income',
                                    backgroundColor: '#28a745',
                                    // borderColor: 'rgba(75, 192, 192, 1)',
                                    // borderWidth: 1,
                                    data: result.financialTxnByProject.map(item => item.incomeAmount)
                                },
                                {
                                    label: 'Expense',
                                    backgroundColor: '#d85a5a',
                                    // borderColor: 'rgba(255, 26, 104, 1)',
                                    // borderWidth: 1,
                                    data: result.financialTxnByProject.map(item => item.expenseAmount)

                                }
                            ]
                        }

                    }
                    // this.resourcePoolData = {
                    //     labels: ['Employees', 'Others'],
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.resourcePoolData.map(item => item.resourceCount)
                    //     }]
                    // }
                    this.projectByStatusData = {
                        labels: result.projectByStatus.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.projectByStatus.map((item) => {
                                this.projectCount += Number(item.empCount);
                                return item.empCount;
                            })
                        }]
                    }
                    this.userByTypeData = {
                        labels: result.userByType.map(item => item.type),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a', '#4dcac9', '#cac04d'],
                            data: result.userByType.map(item => {
                                this.userCount += Number(item.userCount);
                                return item.userCount;
                            })
                        }]
                    }
                    this.clientByTypeData = {
                        labels: result.clientByType.map(item => item.type),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.clientByType.map(item => {
                                this.clientCount += Number(item.clientCount);
                                return item.clientCount;
                            })
                        }]
                    }
                    //     console.log(result.workTypeData)
                    // this.workTypeChartData = {
                    //     labels: result.workTypeData.map(item => item.workType),
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.workTypeData.map(item => item.resourceCount)
                    //     }]
                    // }

                    // this.genderDistributionData = {
                    //     labels: result.genderDistributionData.map(item => item.gender),
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.genderDistributionData.map(item => item.empCount)
                    //     }]
                    // }
                    // this.ageDistributionData = {
                    //     labels: ['Under 30', '31 - 50', 'Above 50' ],
                    //     datasets: [{
                    //         backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                    //         data: result.ageDistributionData.map(item => item.age),
                    //     }]
                    // }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        textColourByAmount(amount) {
            if (Math.sign(amount) == 0) 
                return 'text-black';
            if (Math.sign(amount) == 1) 
                return 'text-success';
            if (Math.sign(amount) == -1) 
                return 'text-danger';
        }
    }
}
</script>