import AssetAndServiceReport from "@/views/landingbasedpages/lanreport/mis/LanAssetAndServiceReport.vue";
import AssetReport from "@/views/report/mis/asset/LanAssetReport.vue";
import AssetSummaryReport from "@/views/report/mis/asset/AssetSummaryReport.vue";
import AssetDetailReport from "@/views/report/mis/asset/LanAssetDetailReport.vue";
import BudgetPlanReport from "@/views/landingbasedpages/lanreport/LanBudgetPlanReport.vue";
import CGREmpEffortAndCostByCompanyReport from "@/views/report/mis/corporate-group/CGREmpEffortAndCostByCompanyReport.vue";
import CGREmpTimeSheetSubmissionStatsReport from "@/views/report/mis/corporate-group/CGREmpTimeSheetSubmissionStatsReport.vue";
import CorpGroupReport from "@/views/report/mis/corporate-group/CorpGroupReport.vue";
import CorpGrpEmpAttendanceSummaryReport from "@/views/report/mis/corporate-group/CorpGrpEmpAttendanceSummaryReport.vue";
import CorpGrpEmployeeListReport from "@/views/report/mis/corporate-group/CorpGrpEmployeeListReport.vue";
import CorpGroupCompanyInfoSummaryReport from "@/views/report/mis/corporate-group/CorpGroupCompanyInfoSummaryReport.vue";
import CorpGrpEmpUtilizationPerformanceReport from "@/views/report/mis/corporate-group/CorpGrpEmpUtilizationPerformanceReport.vue";
import EmpAttendanceDetailReport from "@/views/report/mis/resource/EmpAttendanceDetailReport.vue";
import EmpAttendanceSummaryReport from "@/views/report/mis/resource/EmpAttendanceSummaryReport.vue";
import EmployeeListReport from "@/views/report/mis/resource/EmployeeListReport.vue";
import EmployeeReport from "@/views/landingbasedpages/lanreport/mis/LanEmployeeReport.vue";
import EmpNetSalaryReport from "@/views/report/mis/resource/EmpNetSalaryReport.vue";
import EmpTaskEfficiencyReport from "@/views/report/mis/task/EmpTaskEfficiencyReport.vue";
import EmpTaskTrackingReport from "@/views/report/mis/task/EmpTaskTrackingReport.vue";
import EmpUtilizationPerformanceReport from "@/views/report/mis/resource/EmpUtilizationPerformanceReport.vue";
import FinanceReport from "@/views/landingbasedpages/lanreport/mis/LanFinanceReport.vue";
import FinancialTxnReport from "@/views/landingbasedpages/lanreport/LanFinancialTxnReport.vue";
import FinTxnReport from "@/views/report/mis/finance/FinTxnReport.vue";
import FinTxnByGroupReport from "@/views/report/mis/finance/FinTxnByGroupReport.vue";
import GeneralDocumentReport from "@/views/report/doc/GeneralDocumentReport.vue";
import IssueReport from "@/views/report/doc/IssueReport.vue";
import MOMReport from "@/views/report/doc/MoMReport.vue";
import PayrollReport from "@/views/landingbasedpages/lanreport/LanPayrollReport.vue";
import PayrollByMonthReport from "@/views/report/mis/resource/PayrollByMonthReport.vue";
import FinTxnSummaryReport from "@/views/report/mis/finance/FinTxnSummaryReport.vue";
import PlanReport from "@/views/landingbasedpages/lanreport/LanPlanReport.vue";
import ProcessPlanReport from "@/views/landingbasedpages/lanreport/LanProcessPlanReport.vue";
import DocumentFolderReport from "@/views/landingbasedpages/lanreport/LanDocumentFolderReport.vue";
import ResourcePlanReport from "@/views/landingbasedpages/lanreport/LanResourcePlanReport.vue";
import RequirementSpecReport from "@/views/report/doc/SystemRequirementSpecReport.vue";
import ServiceReport from "@/views/report/mis/asset/LanServiceReport.vue";
import ServiceDetailReport from "@/views/report/mis/asset/LanServiceDetailReport.vue";
import TaskReport from "@/views/landingbasedpages/lanreport/mis/LanTaskReport.vue";
import TaskByPlanReport from "@/views/report/mis/task/TaskByPlanReport.vue";
import TaskListMISReport from "@/views/report/mis/task/TaskListReport.vue";
import TaskListReport from "@/views/report/doc/TaskListReport.vue";
import TimeSheetReport from "@/views/report/doc/TimeSheetReport.vue";
import TimeSheetRepReport from "@/views/report/doc/TimeSheetRepReport.vue";
import UserRequirement from "@/views/report/doc/UserRequirementReport.vue";

export default [
  {
    path: "/landing/aasrep",
    name: "lanaasrep",
    component: AssetAndServiceReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/astrep",
    name: "lanastrep",
    component: AssetReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/astsumrep",
    name: "lanastsumrep",
    component: AssetSummaryReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/astdetrep",
    name: "lanastdetrep",
    component: AssetDetailReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/bplrep/:ermId",
    name: "lanbplrep",
    component: BudgetPlanReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/crgempeffcstrep",
    name: "lancrgempeffcstrep",
    component: CGREmpEffortAndCostByCompanyReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/cgremptshsbmsttrep",
    name: "lancgremptshsbmsttrep",
    component: CGREmpTimeSheetSubmissionStatsReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },

  {
    path: "/landing/crgrep",
    name: "lancrgrep",
    component: CorpGroupReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/crpattrep",
    name: "lancrpattrep",
    component: CorpGrpEmpAttendanceSummaryReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/crpemprep",
    name: "lancrpemprep",
    component: CorpGrpEmployeeListReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/crgcisrep",
    name: "lancrgcisrep",
    component: CorpGroupCompanyInfoSummaryReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/crpeuprep",
    name: "lancrpeuprep",
    component: CorpGrpEmpUtilizationPerformanceReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/empattdetrep",
    name: "lanempattdetrep",
    component: EmpAttendanceDetailReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/empattrep",
    name: "lanempattrep",
    component: EmpAttendanceSummaryReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/emplstrep",
    name: "lanemplstrep",
    component: EmployeeListReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/emprep",
    name: "lanemprep",
    component: EmployeeReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/empsalrep",
    name: "lanempsalrep",
    component: EmpNetSalaryReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/tskeffrep",
    name: "lantskeffrep",
    component: EmpTaskEfficiencyReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/ettrep",
    name: "lanettrep",
    component: EmpTaskTrackingReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/euprep",
    name: "laneuprep",
    component: EmpUtilizationPerformanceReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/finrep",
    name: "lanfinrep",
    component: FinanceReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/ftxrep/:ermId",
    name: "lanftxrep",
    component: FinancialTxnReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/ftxrep",
    name: "lanftxrep",
    component: FinTxnReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/ftxgrouprep",
    name: "lanftxgrouprep",
    component: FinTxnByGroupReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/gndrep/:ermId",
    name: "langndrep",
    component: GeneralDocumentReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/issrep/:ermId",
    name: "lanissrep",
    component: IssueReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/mmnrep/:ermId",
    name: "lanmmnrep",
    component: MOMReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/prlrep/:ermId",
    name: "lanprlrep",
    component: PayrollReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/prlmrep",
    name: "lanprlmrep",
    component: PayrollByMonthReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/plnrep/:ermId",
    name: "lanplnrep",
    component: PlanReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/fintxnsumrep",
    name: "lanfintxnsumrep",
    component: FinTxnSummaryReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/pmdrep/:ermId",
    name: "lanpmdrep",
    component: ProcessPlanReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/pdcrep/:ermId",
    name: "lanpdcrep",
    component: DocumentFolderReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/rplrep/:ermId",
    name: "lanrplrep",
    component: ResourcePlanReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/srsrep/:ermId",
    name: "lansrsrep",
    component: RequirementSpecReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/srurep",
    name: "lansrurep",
    component: ServiceReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/srudetrep",
    name: "lansrudetrep",
    component: ServiceDetailReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/tskrep",
    name: "lantskrep",
    component: TaskReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/tskplnrep",
    name: "lantskplnrep",
    component: TaskByPlanReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/tslrep",
    name: "lantslrep",
    component: TaskListReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/tsklistrep",
    name: "lantsklistrep",
    component: TaskListMISReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },

  {
    path: "/landing/tmsrep/:ermId",
    name: "lantmsrep",
    component: TimeSheetReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/tmrrep/:ermId",
    name: "lantmrrep",
    component: TimeSheetRepReport,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
  {
    path: "/landing/urqrep/:ermId",
    name: "lanurqrep",
    component: UserRequirement,
    meta: {
      layout: "landing",
      accessedby: "CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM",
    },
  },
];
