<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Client List</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Client" :to="{ name: 'addclient'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>

<!--                                     <li class="breadcrumb-item">
                                        <router-link title="Client" :to="{ name: 'viewClient'}"><i class="fa fa-eye pr-2"></i>View</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li> -->
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="clientName">Client Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.emailId.$error }">
                                                <input type="text" v-model="client.clientName" class="form-control" id="clientName" placeholder="Enter Client Name">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.minLength" class="text-danger">Client Name must have at least {{$v.client.clientName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.required" class="text-danger">Please enter Client Name</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.maxLength" class="text-danger">Client Name must not exceed {{$v.client.clientName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="emailId">Email ID</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.emailId.$error }">
                                                <input type="email" v-model="client.emailId" class="form-control" id="emailId">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.email" class="text-danger">Enter Valid EmailId</label>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.required" class="text-danger">Please enter EmailId</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="clientTypeId">Client Type</label>
                                         <select v-model="client.clientTypeId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in clientTypes" :value="info.id">{{info.value}}</option>
                                        </select>
<!--                                         <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div>
                                        <b-input-group :prepend="prependText" class="mb-2">
                                            <b-form-input aria-label="First name" v-model="client.firstName" placeholder="First Name"></b-form-input>
                                            <b-form-input aria-label="Middle name" v-model="client.middleName" placeholder="Middle Name"></b-form-input>
                                            <b-form-input aria-label="Last name" v-model="client.lastName" placeholder="Last Name"></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-if="client.clientTypeId == 4090" class="col-4">
                                    <div class="form-group">
                                        <label for="profession">Profession</label>
                                        <input type="text" v-model="client.profession" class="form-control" id="profession" >
                                    </div>
                                </div>
                                <div v-if="client.clientTypeId == 4090" class="col-4">
                                    <div class="form-group">
                                        <label for="employer">Employer</label>
                                        <input type="text" v-model="client.employer" class="form-control" id="employer" >
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="position">Position</label>
                                        <input type="text" v-model="client.position" class="form-control" id="position" >
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input type="text" v-model="client.phone" class="form-control" id="phone" >
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="mobile">Mobile</label>
                                        <input type="text" v-model="client.mobile" class="form-control" id="mobile" >
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="location">Location</label>
                                        <input type="text" v-model="client.location" class="form-control" id="location" >
                                    </div>
                                </div>
                            </div>
                            <a href="Javascript:void(0);" @click="saveClient" class="btn btn-primary mb-0 mt-3">Save</a>
                            <a href="Javascript:void(0);" @click="clearData" class="btn btn-primary mb-0 mt-3 ml-2">Cancel</a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="clientStatus">Client Id</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{clientId}}</span></p>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="form-group">
                                        <label for="addedBy">Added By</label>
                                        <select v-model="client.createdById" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <b-input-group prepend="Address 1" class="mt-3">
                                            <b-form-input type="text" v-model="client.address1" class="form-control" id="address1" >
                                            </b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <b-input-group prepend="Address 2" >
                                            <b-form-input type="text" v-model="client.address2" class="form-control" id="address2" >
                                            </b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <b-input-group prepend="City" >
                                            <b-form-input type="text" v-model="client.city" class="form-control" id="city">
                                            </b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <b-input-group prepend="State" >
                                            <b-form-input type="text" v-model="client.state" class="form-control" id="state">
                                            </b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <b-input-group prepend="Country" >
                                        <select v-model="client.countryId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                        </select>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <b-input-group prepend="Pincode" >
                                            <b-form-input type="text" v-model="client.pincode" class="form-control" id="pincode">
                                            </b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="client.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        // TypeLookup,
        Select2
    },
    computed: {
        clientId() {
            return Number(this.$route.params.clientid);
        }
    },
    data() {
        return {

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            // typeLookupTitle:'Client Type Settings',
            clientTypes:[],
            users:[],
            countries: [],
            prependText:'Full Name',
            client: {
                entity: 'client',
                clientId: '',
                vhextAccountId: '',
                clientTypeId: '',
                clientName: '',
                contactPerson: '',
                location: '',
                phone: '',
                firstName: '',
                middleName: '',
                lastName: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession:'',
                employer:'',
                position:'',
                emailId: '',
                createdById: ''
            }
        }
    },
    mounted() {
        this.lookupData();
        this.viewClient(this.clientId);
    },
    validations: {
        client: {
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId:{
                required,
                email,
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                clientId: this.clientId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clientTypes = result.typeLookup;                   
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                    console.log(result);

                })

        },
        viewClient: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/client/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.client = result.client
                    this.client.clientTypeId = (this.client.clientTypeId == undefined) ? '' : this.client.clientTypeId;
                    console.log(result);
                })

        },
        saveClient: function() {
            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/client/update', this.client)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$router.push('/client/view/' + this.clientId);
                        }
                        console.log(result);

                    })
            }

        },
         clearData: function() {
                this.client.title = '',
                this.client.clientType = '',
                this.client.clientTypeId = '',
                this.client.clientTypeName = '',
                this.client.clientName = '',
                this.client.contactPerson = '',
                this.client.firstName = '',
                this.client.middleName = '',
                this.client.lastName = '',
                this.client.emailId = '',
                this.client.phone = '',
                this.client.mobile = '',
                this.client.address1 = '',
                this.client.address2 = '',
                this.client.city = '',
                this.client.state = '',
                this.client.countryId = '',
                this.client.pincode = '',
                this.client.hasContactInfo = '',
                this.client.active = '',
                this.client.profession = '',
                this.client.employer = '',
                this.client.position = '',
                this.$v.client.$reset();

        },lientType: function(data) { 
            if (data.text != null && data.text.length > 0){
                this.client.clientTypeName = data.text.toLowerCase();
            }
            if(this.client.clientTypeName == 'company'){
                this.prependText = 'Contact Person';
            }else{
                 this.prependText = 'Full Name';
            }
        }
    }
}
</script>