<template>
    <div>
        <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
            <div class="card-body p-0">
                <div class="row">
                    <div class="col-12 col-sm-12">
                        <div class="form-row mb-1">
                            <div class="col-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Name</label>
                            </div>
                            <div class="col-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ candidateData.name }}</label>
                            </div>
                            <div class="col-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Candidate Type</label>
                            </div>
                            <div class="col-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{ candidateData.candidateType }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="form-row mb-1">
                            <div class="col-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Position</label>
                            </div>
                            <div class="col-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{candidateData.position}}</label>
                            </div>
                            <div class="col-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Qualification</label>
                            </div>
                            <div class="col-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{candidateData.qualification}}</label>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="form-row mb-1">
                            <div class="col-12 col-md-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Experience</label>
                            </div>
                            <div class="col-12 col-md-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">
                                    <span v-if="candidateData.experienceYears">{{ candidateData.experienceYears }} years </span>
                                    <span v-if="candidateData.experienceMonths">{{ candidateData.experienceMonths }} months</span>
                                </label>
                            </div>
                            <div class="col-12 col-md-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Notice Period</label>
                            </div>
                            <div class="col-12 col-md-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{ noticePeriod }}</label>
                            </div>                                        
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="form-row mb-1">
                            <div class="col-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Email Id</label>
                            </div>
                            <div class="col-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{candidateData.emailId}}</label>
                            </div>
                            <div class="col-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Mobile</label>
                            </div>
                            <div class="col-4 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{candidateData.mobile}}</label>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="card my-2">
                            <div class="card-body py-1 px-0">
                                <p class="contact-title mb-2 ml-2"><span>Objective</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12 m-0 px-3">
                                        <label class="p-2 mb-0 d-block header-details" v-html="candidateData.objective"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12">
                        <div class="card my-2">
                            <div class="card-body py-1 px-0">
                                <p class="contact-title mb-2 ml-2"><span>Key Strength</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12 m-0 px-3">
                                        <label class="p-2 mb-0 d-block header-details" v-html="candidateData.keyStrength"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="technologySkills && technologySkills.length>0" class="col-12 col-sm-12">
                        <div class="col-12 col-sm-12">
                            <div class="card my-2">
                                <div class="card-body py-1 px-0">
                                    <p class="contact-title mb-2 ml-2"><span>Technology & Skills</span></p>
                                    
                                    <div v-for="technologySkill in technologySkills" :key="technologySkill.id">
<!--                                         {{ technologySkill.id }} -->
                                        <div class="form-row mb-1">
                                            <div class="col-12 col-md-3 pr-0">
                                                <!-- <p class="m-0 font-weight-bold">{{ technologySkill.technology }}</p> -->
                                                <label class="mb-0 p-1 ml-3 pt-2 pb-2 d-block header-details">{{ technologySkill.technology }}</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <!-- <p class="m-0">{{ technologySkill.skills }} </p> -->
                                                <label class="p-2 mb-0 d-block header-details">{{ technologySkill.skills }}</label>
                                            </div>  
                                        </div> 
                                    </div>                                     
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div v-show="attachmentDocsLength > 0" class="col-12 col-sm-12">
                        <div class="form-row mb-1">
                            <div class="col-12 col-md-2 pr-0">
                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                            </div>
                            <div class="col-12 col-md-10 pl-0">
                                <div class="col-12">
                                    <div class="mt-2">
                                        <AttachmentView ref="viewAttachmentDocRef" @getAttachmentLength="getAttachmentDocsLength" :attachmentData="attachmentData" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import enummixin from "@/mixins/enummixin";
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object,
    },
    mixins: [enummixin],
    components: {
        AttachmentView
    },
    data() {
        return {
            showOverlay: false,
            candidateData: {},
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            currencySymbol: localStorage.getItem("currency"),

            attachmentDocsLength: 0,
            attachmentLinksLength: 0,

            technologySkills: [],
        }
    },
    computed: {
        noticePeriod() {
            let noticePeriodEnum = this.candidateData.noticePeriodEnum;
            return noticePeriodEnum ? this.noticePeriodList.find((data) => data.id == noticePeriodEnum).value : '';
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.showOverlay = true;
            if (refEntity === "candidate") {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/candidate/view/' + id)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.candidateData = result.candidateView;
                        this.getTechnologySkills();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.candidateData = this.detailViewData;
                    this.getTechnologySkills();
                });
                this.showOverlay = false;
            }
        },
        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: 'candidate',
                refRecordId: this.candidateData.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        getAttachmentDocsLength(length) {
            this.attachmentDocsLength = length;
        },
        getAttachmentLinksLength(length) {
            this.attachmentLinksLength = length;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>