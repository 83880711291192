<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">
                                {{this.ermTitle}}
                                <span v-if="ermStartDate"> - {{ ermStartDate | formatMonthYear }} 
                                    <span v-if="ermEndDate">to {{ ermEndDate | formatMonthYear }}</span>
                                </span>
                            </h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Budget Plan</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div v-if="ermIsFreeze!='true'" id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Budget Item</span>
                                                <span v-if="elements.editMode">Edit</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div >
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Item Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.budgetPlan.title.$error }">
                                                                    <input type="text" class="form-control" v-model="budgetPlan.title" id="title" :class="{ 'is-invalid': $v.budgetPlan.title.$error }" placeholder="Enter Item Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.minLength" class="text-danger">Item Title must have at least {{$v.budgetPlan.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.required" class="text-danger">Please enter Item Title</label>
                                                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.maxLength" class="text-danger">Item Title must not exceed {{$v.budgetPlan.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>

                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label>Activity</label>
                                                                <b-input-group>
                                                                    <select v-model="budgetPlan.activityId" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in budgetPlanActivity" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                        <!-- <a :title="activityLookupTitle" class="setting-icon" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a> -->
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="budgetCategoryId">Category</label>
                                                                <b-input-group>
                                                                    <select v-model="budgetPlan.budgetCategoryId" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in financialTxnCategories" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="categoryLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>Estimated Cost</label>
                                                                <div class="row no-gutters">
                                                                    <div class="col-11">
                                                                        <VhextMoneyControl :value="Number(this.budgetPlan.estimatedCost)" @money-input="moneyControlInput" />
                                                                    </div>
                                                                    <div class="col">
                                                                        <a class="setting-icon-custom" href="javascript:" @click="openCostEstimationPopup(null)" title="Cost Estimation"><i class="fa fa-list-ul" aria-hidden="true"></i></a>
                                                                        <a class="setting-icon-custom2" href="javascript:" @click="openFinancialSourcePopup(null)" title="Financial Source"><i class="fa fa-tasks" aria-hidden="true"></i></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 pl-0">
                                                            <div class="form-group">
                                                                <label>Accountable</label>
                                                                <select v-model="budgetPlan.accountablePersonId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="budgetPlans" :fields="fields" v-model="visibleRows" responsive="sm" foot-clone foot-variant=light no-footer-sorting :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>                                                                                                              
                                <template v-slot:cell(estimatedCost)="data">
                                    <div v-if="data.value" class="text-right">{{ data.value.toFixed(2) }}</div>
                                </template>
                                <template v-slot:cell(budgetFundingSourceAmount)="data">
                                    <div v-if="data.value" class="text-right">{{ data.value.toFixed(2) }}</div>
                                </template>               

                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Cost Estimation" @click="openCostEstimationPopup(data.item.id,data.item.title);"><i class="fa fa-calculator"></i></a>
                                    <a href="javascript:" title="Financial Source" @click="openFinancialSourcePopup(data.item.id,data.item.title);"><i class="fa fa-tasks pl-2" aria-hidden="true"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Delete" @click="deleteBudgetPlan(data.item.id)" v-b-modal.deleteBudgetPlan><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Budget Plan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>

<!--                                 <template v-slot:custom-foot="data">
                                    <div class="col-sm-12 col-md-7 pl-0 mt-1">
                                        <b-tr >
                                            <b-input-group  :prepend=prependText>
                                                <template #prepend>
                                                  <b-input-group-text >{{totalAmount.toFixed(2)}}</b-input-group-text>
                                                </template>                                                    
                                            </b-input-group>
                                        </b-tr>
                                    </div>
                                </template> -->
                                <template v-slot:foot(index)="data">
                                    <span></span>
                                </template>
                                <template v-slot:foot(title)="data">
                                    <span></span>
                                </template>
                                <template v-slot:foot(activity)="data">
                                    <span></span>
                                </template>
                                <template v-slot:foot(category)="data">
                                    <span></span>
                                </template>


                                <template v-slot:foot(accountablePerson)="data">
                                    <span></span>
                                </template>
                                <template class="text-right" v-slot:foot(estimatedCost)="data">
                                    <div class="text-right"><span class="text-right" text-align=right>Sub Total : {{subTotal.toFixed(2)}}</span></div>
                                </template>
                                <template v-slot:foot(actions)="data">
                                    <div class="text-center font-weight-bold"><span class="text-right">Total Budget : {{totalAmount.toFixed(2)}}</span></div>
                                </template>

                            </b-table>
                            <b-pagination v-if="budgetPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            <!-- <div>{{ 13 | convertNumbersToWords }} rupees only</div> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" /> -->
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <FinancialSourcePopup ref="financialSourcePopupRef" :erdId="erdId" :erdTitle="erdTitle" @refreshData="getBudgetPlans();clearData();" @saveFinancialPlanList="saveFinancialPlanList" />
        <CategoryLookup v-if="elements.showCategory" ref="categoryLookupModal" :projectTypeId="this.projectTypeId" :entity="this.categoryEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <BudgetPlanDetailPopup v-if="showDialog" ref="budgetPlanDetailRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <CostEstimationPopup ref="costEstimationPopupRef" :erdId="erdId" :refEntity="entity" @refreshData="getBudgetPlans();clearData();" @saveCostEstimationList="saveCostEstimationList" />
        <DeleteComponent id="deleteBudgetPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Budget Plan Item?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import BudgetPlanDetailPopup from '@/components/popup/itemdetail/BudgetPlanDetailPopup.vue';
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue';
import CostEstimationPopup from '@/components/popup/action/CostEstimationPopup.vue';
import FinancialSourcePopup from '@/components/popup/action/FinancialSourcePopup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    name: 'app',
    components: {
        ActivityLookup,
        BudgetPlanDetailPopup,
        CategoryLookup,
        CostEstimationPopup,
        FinancialSourcePopup,
        ProcessActivityLookup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                showCategory: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            ermStartDate: localStorage.getItem("ermStartDate"),
            ermEndDate: localStorage.getItem("ermEndDate"),

            categoryLookupTitle: 'Budget Plan Category Settings',
            activityLookupTitle: 'Activity Settings',
            processActivityLookupTitle: 'Process / Activity Settings',
            notEnabled: true,
            prependText: localStorage.getItem("currency"),
            financialTxnCategories: [],
            users: [],
            budgetPlanActivity: [],
            entity: '',
            finTxnEntity: 'financial_transaction',
            categoryEntity: 'financial_transaction_category',

            erdId: null,
            erdTitle: null,

            ermIsFreeze: localStorage.getItem("ermIsFreeze"),
            
            budgetPlan: {
                budgetId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                typeId: '',
                budgetPeriod: '',
                budgetPeriodStr: '',
                budgetCategoryId: '',
                title: '',
                description: '',
                estimatedCost: null,
                preparedById: '',
                remarks: '',
                accountablePersonId: '',
                statusId: '',
                activityId: '',
                entityId: '',
                costEstimationDataList: [],
                financialPlanDataList: [],
            },
            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            visibleRows: [],            
            budgetPlans: [],
            fields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'budgetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'category', label: 'Budget Category', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Estimated Cost', sortable: true, sortDirection: 'desc' },
                { key: 'budgetFundingSourceAmount', label: 'Budget Funding Source', sortable: true, sortDirection: 'desc' },
                
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        // totalExpenses() {
        //     return this.budgetPlans.reduce((accum, item) => {
        //     return accum + parseFloat(item.estimatedCost)
        // }, 0.00)
        // },
        totalAmount() {
            return this.budgetPlans.reduce((accum, item) => {
                return accum + parseFloat(item.estimatedCost ? item.estimatedCost : 0)
            }, 0.00)
        },
        subTotal() {
            return this.visibleRows.reduce((accum, item) => { 
                return accum + (item.estimatedCost ? item.estimatedCost : 0)}, 0.00)
        },


    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getBudgetPlans();
    },
    validations: {
        budgetPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'budget_plan';
            this.userId = localStorage.getItem("userId");

            this.budgetPlan.vhextAccountId = this.vhextAccountId;
            this.budgetPlan.projectId = this.projectId;
            this.budgetPlan.entityRecordMasterId = this.entityRecordMasterId;
            this.budgetPlan.preparedById = this.userId;
            this.elements.showCategory = true;

        },

        getBudgetPlans: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/list', data)
                .then((response) => {
                    let result = response.data;
                    this.budgetPlans = result.budgetlist;
                    this.totalRows = this.budgetPlans.length;
                    console.log(this.budgetPlans);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.financialTxnCategories = result.customCategoryLookup;
                    this.users = result.userLookup;
                    this.budgetPlanActivity = result.activityLookup;
                    console.log(result);
                })

        },

        saveData: function() {
            this.$v.budgetPlan.$touch();
            if (!this.$v.budgetPlan.$invalid) {
                if (this.elements.editMode) {
                    this.updateBudgetPlan();
                } else {
                    this.saveBudgetPlan();
                }
            }
        },
        saveBudgetPlan: function() {
            this.$v.budgetPlan.$touch();
            console.log(this.budgetPlan);
            if (!this.$v.budgetPlan.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/add', this.budgetPlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getBudgetPlans();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                   });
                })
            }
        },
        updateBudgetPlan: function() {
            
            this.$v.budgetPlan.$touch();
            if (!this.$v.budgetPlan.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/update', this.budgetPlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getBudgetPlans();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                   });
                })
            }

        },
        deleteBudgetPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/budget/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getBudgetPlans();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.budgetPlanDetailRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.erdId = null;
            this.erdTitle = null;
            this.budgetPlan.title = '';
            this.budgetPlan.budgetPeriod = '';
            this.budgetPlan.budgetCategoryId = '';
            this.budgetPlan.description = '';
            this.budgetPlan.estimatedCost = null;
            this.budgetPlan.accountablePersonId = '';
            this.budgetPlan.activityId = '';
            this.budgetPlan.costEstimationDataList = [];
            this.budgetPlan.financialPlanDataList = [];
            this.$v.budgetPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.erdId = data.id;
            this.erdTitle = data.title;
            this.budgetPlan.budgetId = data.budgetId;
            this.budgetPlan.id = data.id;
            this.budgetPlan.title = data.title;
            this.budgetPlan.budgetPeriod = data.budgetPeriodStr;
            this.budgetPlan.budgetCategoryId = data.budgetCategoryId;
            this.budgetPlan.description = data.description;
            this.budgetPlan.estimatedCost = data.estimatedCost;
            this.budgetPlan.accountablePersonId = data.accountablePersonId;
            this.budgetPlan.activityId = data.activityId;
            this.$nextTick(() => {
                this.$refs.costEstimationPopupRef.getCostEstimation(false);
                this.$refs.financialSourcePopupRef.getCostEstimation(false);
            })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        moneyControlInput: function(value) {
            this.budgetPlan.estimatedCost = value;
        },
        saveCostEstimationList(estimationList, totalCost) {
            this.budgetPlan.costEstimationDataList = estimationList;
            this.budgetPlan.estimatedCost = totalCost;
        },
        saveFinancialPlanList(estimationList, totalCost) {
            this.budgetPlan.financialPlanDataList = estimationList;
            // this.budgetPlan.estimatedCost = totalCost;
        },
        openCostEstimationPopup(id,title) {
            if (id && id !== null) {
                this.erdId = id;
                this.erdTitle = title;
                this.$nextTick(() => {
                    this.$refs.costEstimationPopupRef.getCostEstimation(true);
                })
            } else if (!this.elements.editMode) {
                this.erdId = null;
                this.erdTitle = null;
                if (this.budgetPlan.costEstimationDataList.length==0) {
                    this.$nextTick(() => {
                        this.$refs.costEstimationPopupRef.getCostEstimation(false);
                    })
                }
            }
            this.$nextTick(() => {
                this.$bvModal.show('costEstimationPopup');
            })
        },
        openFinancialSourcePopup(id,title) {
            if (id && id !== null) {
                this.erdId = id;
                this.erdTitle = title;
                console.log(this.erdId,this.erdTitle)
                this.$nextTick(() => {
                    this.$refs.financialSourcePopupRef.getCostEstimation(true);
                })
            } else if (!this.elements.editMode) {
                this.erdId = null;
                this.erdTitle = null;
                if (this.budgetPlan.financialPlanDataList.length==0) {
                    this.$nextTick(() => {
                        this.$refs.financialSourcePopupRef.getCostEstimation(false);
                    })
                }
            }
            this.$nextTick(() => {
                this.$bvModal.show('financialSourcePopup');
            })
        }
    }
}
</script>