<template>
    <div>
        <b-modal no-close-on-backdrop no-close-on-esc no-enforce-focus title="Add Payroll Component" size="lg" @hide="closeDialog" id="addPayrollComponentPopup" ref="addPayrollComponentRef" body-class="mb-2" ok-only>

			<b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
            	<b-container class="mb-3 p-0">
                    <div class="row">
                        <div class="col">
                            <label>Payroll Components</label>
                        </div>
                        <div class="col text-right">
                            <b-button class="btn sbtn btn-xs px-2" variant="outline-secondary" title="Add Payroll Components" @click="$router.push({name: 'lanpayslipcomp'})">Add Payroll Component Settings</b-button>
                        </div>
                    </div>
	                <b-table outlined show-empty class="white-header mb-0" small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="payslipComponentList"  :fields="payslipComponentFields" responsive="sm" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(payslipComponentTypeId)="data">
                            <div class="" title="'EAR' - 'Earning' || 'DED' - Deduction'">{{ data.value == 1? 'EAR' : 'DED' }}</div>
                        </template>
                        <template v-slot:cell(isTaxable)="data">
                            <i v-if="data.value" class="fa fa-check"></i>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <span v-if="componentAlreadyExists(data.item.id)" class="badge badge-pill badge-outline-primary px-2 py-1"><i class="fa fa-check pr-1"></i>Added</span>
                            <a v-else href="javascript:" title="Add Component" class="text-success" @click="addPayslipComponent(data.item)"><i class="fa fa-lg fa-plus-circle pr-2"></i></a>
                            <!-- <a href="javascript:" title="Delete" @click="deletePayslipComponentSetting(data.item.id)" v-b-modal.deletePayslipComponentSetting><i class="fa fa-trash pl-2"></i></a> -->
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Payroll Component found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
	            </b-container>
	        </b-overlay>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        existingComponentList: Array,
        payslipConfigId: Number
    },
    data() {
        return {
        	showDialog: false,
        	elements: {
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            payslipComponentSetting: {
                id: '',
                payslipComponentSettingId: '',
                payslipComponent: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payslipConfigId: '',
                payslipComponent: '',
                payslipComponentId: '',
                payslipComponentTypeId: '',
                standardBusinessLogicId: '',
                standardPayslipComponentId: '',
                fixedVal: '',
                percentVal: '',
                isBasic: false,
                isTaxable: '',
                createdById: localStorage.getItem("userId"),
            },

            payslipComponentList: [],

            payslipComponentFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'payslipComponent', label: 'Payroll Component', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentTypeId', label: 'Type', sortable: true, sortDirection: 'desc' },
                // { key: 'projectType', label: 'Project Type', sortable: true, sortDirection: 'desc' },                
                // { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'isTaxable', label: 'Taxable',tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            deleteParam: {
                id: 0
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    mounted() {
        this.getPayslipComponentList();
    },
    methods: {
    	getPayslipComponentList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.payslipComponentList = result.payslipComponentList;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        addPayslipComponent: function(data) {
            this.payslipComponentSetting.payslipConfigId = this.payslipConfigId;
            this.payslipComponentSetting.payslipComponent = data.payslipComponent;
            this.payslipComponentSetting.payslipComponentId = data.id;
            this.payslipComponentSetting.payslipComponentTypeId = data.payslipComponentTypeId;
            this.payslipComponentSetting.isBasic = data.isBasic;
            this.payslipComponentSetting.isTaxable = data.isTaxable;
            this.payslipComponentSetting.standardBusinessLogicId = data.standardBusinessLogicId;
            this.payslipComponentSetting.standardPayslipComponentId = data.standardPayslipComponentId;
            this.payslipComponentSetting.fixedVal = data.fixedVal;
            this.payslipComponentSetting.percentVal = data.percentVal;
            console.log(this.payslipComponentSetting)
            // return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/add', this.payslipComponentSetting)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        // this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        componentAlreadyExists: function(id) {
            let result = false;
            this.existingComponentList.map(data => {
                if (id && data.payslipComponentId && id == data.payslipComponentId) {
                    result = true;
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
            // this.$bvModal.hide('addPayrollComponentPopup');
        },
    }
}
</script>