<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Service Specific Modules & Sub Modules</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Sub Module</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="subModule">Sub Module</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.subModule.subModule.$error }">
                                                                <input type="text" class="form-control" v-model="subModule.subModule" id="subModule" :class="{ 'is-invalid': $v.subModule.subModule.$error }" placeholder="Enter Sub Module">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.subModule.subModule.$error && !$v.subModule.subModule.minLength" class="text-danger">Sub Module title must have at least {{$v.subModule.subModule.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.subModule.subModule.$error && !$v.subModule.subModule.required" class="text-danger">Please enter Sub Module</label>
                                                            <label v-if="$v.subModule.subModule.$error && !$v.subModule.subModule.maxLength" class="text-danger">Sub Module must not exceed {{$v.subModule.subModule.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="moduleId">Module</label>
                                                            <select v-model="subModule.moduleId" class="form-control" id="moduleId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in moduleLookup" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="shortName">Short Name</label>
                                                            <input type="text" class="form-control" v-model="subModule.shortName" id="shortName" placeholder="Enter Short Name">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="code">Code</label>
                                                            <input type="text" class="form-control" v-model="subModule.code" id="code" placeholder="Enter Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="subModule.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Basic</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="subModule.isBasic" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Standard</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="subModule.isStandard" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="budget">Description</label>
                                                            <textarea v-model="subModule.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveSubModule" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="form-row view-filter">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-8 col-sm-8">
                                                <b-input-group prepend="Module" id="moduleId" >
                                                    <select v-model="standardFilterData.moduleId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in moduleLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-3 col-sm-3">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>                                
                                </div>

                                <b-table ref="subModuleTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filteredSubModules"  @row-selected="onRowSelected" :fields="subModuleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <!-- <template v-slot:cell(subModuleId)="data">
                                        <div v-if="data.item.id == idEdit">
                                            <input type="number" class="form-control" v-model="data.item.subModuleId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.subModuleId)" @blur="cancelIdEdit()">
                                        </div>
                                        <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.subModuleId"> {{ data.item.subModuleId }}</div>
                                    </template> -->
                                    <template v-slot:cell(isBasic)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" disabled >
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(isStandard)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" >
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <!--<template v-slot:cell(title)="data">
                                        <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                    </template>-->
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteSubModule(data.item.id)" data-toggle="modal" data-target="#deleteSubModule"><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Sub Modules found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                <b-pagination v-if="subModuleList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{subModuleDetail.subModuleId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateSubModule" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="subModule">Sub Module</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.subModuleDetail.subModule.$error }">
                                                        <input type="text" class="form-control" v-model="subModuleDetail.subModule" id="subModule" :disabled="!elements.editMode" :class="{ 'is-invalid': $v.subModuleDetail.subModule.$error }" placeholder="Enter Sub Module">
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.subModuleDetail.subModule.$error && !$v.subModuleDetail.subModule.minLength" class="text-danger">Sub Module title must have at least {{$v.subModuleDetail.subModule.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.subModuleDetail.subModule.$error && !$v.subModuleDetail.subModule.required" class="text-danger">Please enter Sub Module</label>
                                                    <label v-if="$v.subModuleDetail.subModule.$error && !$v.subModuleDetail.subModule.maxLength" class="text-danger">Sub Module must not exceed {{$v.subModuleDetail.subModule.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="moduleId">Module</label>
                                                    <select v-model="subModuleDetail.moduleId" :disabled="!elements.editMode"  class="form-control" id="moduleId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in moduleLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="shortName">Short Name</label>
                                                    <input type="text" class="form-control" id="shortName" :disabled="!elements.editMode" v-model="subModuleDetail.shortName">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="code">Code</label>
                                                    <input type="text" class="form-control" v-model="subModuleDetail.code" :disabled="!elements.editMode" id="code" placeholder="Enter Code">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="subModuleDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="budget">Basic</label>
                                                    <div class="input-group ">
                                                        <b-form-checkbox class="custom-toggle" v-model="subModuleDetail.isBasic" :disabled="!elements.editMode" name="check-button" switch>
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="budget">Standard</label>
                                                    <div class="input-group ">
                                                        <b-form-checkbox class="custom-toggle" v-model="subModuleDetail.isStandard" :disabled="!elements.editMode" name="check-button" switch>
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label for="subModule">Description</label>
                                                    <textarea v-model="subModuleDetail.description" :disabled="!elements.editMode" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <!-- <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="subModuleDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div> -->
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="subModuleDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="subModuleDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteSubModule" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Sub Module?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            showDetOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'sub_module',

            idValue: '',
            idEdit: '',
            mainTableArray: [],

            standardFilterData: {
                moduleId: '',
            },

            subModule: {
                id: '',
                subModuleId: '',
                moduleId: '',
                subModule: '',
                shortName: '',
                code: '',
                active: true,
                isBasic: '',
                isStandard: '',
            },
            subModuleDetail: {
                id: '',
                subModuleId: '',
                moduleId: '',
                subModule: '',
                shortName: '',
                code: '',
                active: true,
                isBasic: '',
                isStandard: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            moduleLookup: [],
            subModuleTypes: [],
            subModuleList: [],

            subModuleFields: [
                {key: 'index',label: 'SN'},
                { key: 'id', label: 'PK ID', sortable: true, sortDirection: 'desc' },
                { key: 'subModuleId', label: 'ID', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'shortName', label: 'Short Name', sortable: true, sortDirection: 'desc' },
                // { key: 'code', label: 'Code', sortable: true, sortDirection: 'desc' },
                { key: 'isBasic', label: 'Basic', sortable: true, sortDirection: 'desc' },
                // { key: 'isStandard', label: 'Standard', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],


            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredSubModules() {
            let moduleId = this.standardFilterData.moduleId;
            let filteredSubModules = [];

            let filterArray = [];

            if (moduleId) {
                filterArray = this.subModuleList.filter(data => {
                    if (data.moduleId == moduleId) {
                        return data;
                    }
                });
            }
            else filterArray = this.subModuleList;

            filteredSubModules = filterArray;

            this.totalRows = filteredSubModules.length;
            return filteredSubModules;
        }
    },    
    mounted() {
        this.getSubModules();
        this.getLookupData();
    },
    validations: {
        subModule: {
            subModule: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        subModuleDetail: {
            subModule: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getSubModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/submodule/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.subModuleList && result.subModuleList.length > 0) {
                        this.subModuleList = result.subModuleList;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.subModuleDetail.id, this.mainTableArray, 'subModuleTable');
                        })
                        this.totalRows = this.subModuleList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLookupData: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/servicespecificmodule')
                .then((response) => {
                    let result = response.data;
                    this.moduleLookup = result.serviceSpecificModuleLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveSubModule: function() {
            this.$v.subModule.$touch();
            if (!this.$v.subModule.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/menu/submodule/add', this.subModule)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getSubModules();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateSubModule: function() {
            this.$v.subModuleDetail.$touch();
            if (!this.$v.subModuleDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/menu/submodule/update', this.subModuleDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getSubModules();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewSubModule: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/menu/submodule/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let subModuleDetail = result.subModule;
                    if (subModuleDetail) {                        
                        this.subModuleDetail.id = subModuleDetail.id;
                        this.subModuleDetail.subModuleId = subModuleDetail.subModuleId;
                        this.subModuleDetail.moduleId = subModuleDetail.moduleId;
                        this.subModuleDetail.subModule = subModuleDetail.subModule;
                        this.subModuleDetail.shortName = subModuleDetail.shortName;
                        this.subModuleDetail.code = subModuleDetail.code;
                        this.subModuleDetail.active = subModuleDetail.active;
                        this.subModuleDetail.isBasic = subModuleDetail.isBasic;
                        this.subModuleDetail.isStandard = subModuleDetail.isStandard;
                        this.subModuleDetail.createdDt = subModuleDetail.createdDt;
                        this.subModuleDetail.lastUpdatedDt = subModuleDetail.lastUpdatedDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewSubModule(this.subModuleDetail.id);
        },
        deleteSubModule: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/menu/submodule/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getSubModules();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.subModule.id = '';
            this.subModule.subModuleId = '';
            this.subModule.moduleId = '';
            this.subModule.subModule = '';
            this.subModule.shortName = '';
            this.subModule.code = '';
            this.subModule.active = true;
            this.subModule.isBasic = false;
            this.subModule.isStandard = false;
            this.$v.subModule.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.subModuleDetail.id = data.id;
            this.subModuleDetail.subModuleId = data.subModuleId;
            this.subModuleDetail.moduleId = data.moduleId;
            this.subModuleDetail.subModule = data.subModule;
            this.subModuleDetail.shortName = data.shortName;
            this.subModuleDetail.code = data.code;
            this.subModuleDetail.active = data.active;
            this.subModuleDetail.isBasic = data.isBasic;
            this.subModuleDetail.isStandard = data.isStandard;
            this.subModuleDetail.createdDt = data.createdDt;
            this.subModuleDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.subModuleDetail.id = data.id;
                this.subModuleDetail.subModuleId = data.subModuleId;
                this.subModuleDetail.moduleId = data.moduleId;
                this.subModuleDetail.subModule = data.subModule;
                this.subModuleDetail.shortName = data.shortName;
                this.subModuleDetail.code = data.code;
                this.subModuleDetail.active = data.active;
                this.subModuleDetail.isBasic = data.isBasic;
                this.subModuleDetail.isStandard = data.isStandard;
                this.subModuleDetail.createdDt = data.createdDt;
                this.subModuleDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {

            this.showOverlay = true;
            if (item.active == true) {
                this.subModule.entity = this.entity;
                this.subModule.id = item.id;
                this.subModule.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.subModule.entity = this.entity;
                this.subModule.id = item.id;
                this.subModule.active = 1;

                this.updateActivationState();
            }
            console.log(this.subModule.id, this.subModule.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.subModule)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.subModule.active = true;
                    this.getSubModules();
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    console.log(error)
                })
        },
    }
}
</script>