<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row task-top-menu">
                        <div class="col-lg-3 col-sm-3 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">Payable / Receivable</h4>
                        </div>
                        <!-- <div class="col text-right mt-1">
                            <div class="action-item-page">
                                <span @click="showDateRange=true;" title="Daily" :class="showDateRange ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-primary badge-pill pointer mr-1">D</span>
                                <span @click="showDateRange=false;dateList();" title="Monthly" :class="!showDateRange ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-primary badge-pill pointer">M</span>
                            </div>
                        </div> -->
                        <div class="col-lg-2 col-sm-2 pl-0 mb-1 ml-0 text-left">
                            <div class="row mt-0 mb-0 text-center px-0">
                                <template v-if="showDateRange">
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeDateRange('prevPage',daysToShow);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeDateRange('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                    </div>
                                    <div class="col-6 px-0">
                                        <h6 class="mt-1" v-if="currentStartDate">
                                            {{ months[currentStartDate.getMonth()].slice(0,3) }}
                                            <span v-if="currentStartDate.getFullYear() !== currentEndDate.getFullYear()">
                                                - {{ currentStartDate.getFullYear() }}
                                            </span>
                                            <span v-if="months[currentStartDate.getMonth()] !== months[currentEndDate.getMonth()]">
                                                / {{ months[currentEndDate.getMonth()].slice(0,3) }}
                                            </span>
                                            - {{ currentEndDate.getFullYear() }}
                                        </h6>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeDateRange('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeDateRange('nextPage',daysToShow);"><i class="fa fa-2x fa-angle-double-right action-link mt-0"></i></a>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeMonthRange('prevPage',daysToShow);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeMonthRange('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                    </div>
                                    <div class="col-6 px-0">
                                        <h6 class="mt-0" v-if="startMonth">
                                            <!-- {{ months[startMonth.getMonth()] }} - {{ startMonth.getFullYear() }} -->
                                            {{ months[startMonth.getMonth()].slice(0,3) }}
                                            <span v-if="startMonth.getFullYear() !== endMonth.getFullYear()">
                                                - {{ startMonth.getFullYear() }}
                                            </span>
                                            <span v-if="months[startMonth.getMonth()] !== months[endMonth.getMonth()]">
                                                / {{ months[endMonth.getMonth()].slice(0,3) }}
                                            </span>
                                            - {{ endMonth.getFullYear() }}
                                        </h6>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeMonthRange('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeMonthRange('nextPage',daysToShow);"><i class="fa fa-2x fa-angle-double-right action-link mt-0"></i></a>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-lg-5 col-sm-5 pr-0 mt-1">
                            <div class="row no-gutters">
                                <div class="col-5 float-left action-item-page">
                                    <template v-if="showDateRange">
                                        <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer mr-1" title="Jump to Date">
                                            <i class="fa fa-calendar text-light"></i>
                                            <div class="dropdown-menu dropdown-primary p-0" aria-labelledby="dropdownCal">
                                                <b-calendar v-model="selectedDate" today-variant="primary" selected-variant="secondary" @context="dateList(selectedDate)">
                                                </b-calendar>
                                            </div>
                                        </span>
                                    </template>
                                    <span @click="daysToShow = 1" :class="daysToShow == 1 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="Today">1</span>
                                    <span @click="daysToShow = 3" :class="daysToShow == 3 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="3 Days">3</span> 
                                    <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">5</span>
                                    <span @click="daysToShow = 7" :class="daysToShow == 7 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer" title="7 Days">7</span>
                                </div>
                                <div class="col-7">
                                    <b-button class="addnew-quicktassk-btn mr-1" @click="openAccountPayableReceivablePopup('', false, 1)" variant="outline-secondary" size="sm"><i class="fa fa-plus pr-1"></i>New Payable</b-button>
                                    <b-button class="addnew-quicktassk-btn" @click="openAccountPayableReceivablePopup('', false, 2)" variant="outline-secondary" size="sm"><i class="fa fa-plus pr-1"></i>New Receivable</b-button>
                                    <!-- <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">Add</span> -->
                                    <div class="float-right">
                                        <select v-model="showDateRange" @change="dateList()" class="form-control top-header-btn" >
                                            <option :value="true" >By Day</option>
                                            <option :value="false">By Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-2 mb-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right pr-1 mr-0" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="mb-0 fs-14">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row px-1">
                <div v-for="day,index in daysToShow" class="col p-0 ml-2 mr-1">
                    <div v-if="currentDateList.length > 0 && showDateRange" :title="(currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)) ? 'Today' : ''" class="card b-radius-5 pointer" :class="{ 'disabled-weekEnd' : currentDateList[index].getDay() == 0 || currentDateList[index].getDay() == 6, 'active-day' : currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)}" @click="dateList(currentDateList[index])">
                        <div class="card-body p-2">
                            <div class="form-group mb-0">
                                <label v-if="currentDateList.length > 0" class="mt-1 mb-0 font-weight-semibold float-left fs-12" for="title">{{ currentDateList[index] | getMonthDay }}</label>
                                <label class="mt-1 mb-0 font-weight-semibold float-right fs-12 pointer" :class="cumulativeProfitByDate(currentDateList[index]) ? getTextVariant(cumulativeProfitByDate(currentDateList[index]).cumulativeProfit) : ''" title="Closing Balance">{{ cumulativeProfitByDate(currentDateList[index]) ? cumulativeProfitByDate(currentDateList[index]).cumulativeProfit.toFixed(2) : '' }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="currentDateList.length > 0 && showDateRange == false" :title="(currentDateList[index].getMonth() == new Date().getMonth() && currentDateList[index].getFullYear() == new Date().getFullYear()) ? 'This Month' : ''" class="card b-radius-5 pointer" :class="{ 'active-day' : (currentDateList[index].getMonth() == new Date().getMonth() && currentDateList[index].getFullYear() == new Date().getFullYear())}" @click="dateList(currentDateList[index])">
                        <div class="card-body p-2">
                            <div class="form-group mb-0 text-center">
                                <label v-if="currentDateList.length > 0" class="mt-1 mb-0 font-weight-semibold float-left" for="title">{{ months[currentDateList[index].getMonth()] }}</label>
                                <label class="mt-1 mb-0 font-weight-semibold float-right fs-12 pointer" :class="cumulativeProfitByDate(currentDateList[index]) ? getTextVariant(cumulativeProfitByDate(currentDateList[index]).cumulativeProfit) : ''" title="Closing Balance">{{ cumulativeProfitByDate(currentDateList[index]) ? cumulativeProfitByDate(currentDateList[index]).cumulativeProfit.toFixed(2) : '' }}</label>
                                <!-- <h6 v-if="currentDateList[index]" class="card-text font-weight-semibold mb-0 pull-right">
                                    {{ currentDateList[index].getDate() }}
                                </h6> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="currentDateList.length > 0" class="card text-right mx-auto py-2 mt-1">
                        <div class="row px-2">                            
                            <div class="col-6 pr-0">
                                <!-- <label class="float-left m-0">Received</label> -->
                                <span title="DR" class="float-left text-danger"><i class="fa fa-arrow-down pr-1" aria-hidden="true"></i>{{ currency && currency!== 'null' ? currency : '' }} {{ summaryByDate(currentDateList[index], 'totalExpense') ? summaryByDate(currentDateList[index], 'totalExpense') : 0 }}</span>
                            </div>
                            <div class="col-6 pl-0">
                                <!-- <label class="float-left m-0">Spent</label> -->
                                <span title="CR" class="float-right text-success"><i class="fa fa-arrow-up pr-1" aria-hidden="true"></i>{{ currency && currency!== 'null' ? currency : '' }} {{ summaryByDate(currentDateList[index],'totalIncome') ? summaryByDate(currentDateList[index], 'totalIncome') : 0 }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="action-item-vl mt-2">
                        <div v-for="accountPayableReceivable in itemsByDate(currentDateList[index])" class="row">
                            <div class="col-12 text-left mb-2">
                                <div class="action-card my-0">
                                    <div class="action-card-body dropdown show p-0">
                                        <a type="button" class="float-right pt-2 mr-2 ml-1" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-v p-1"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                            <a class="dropdown-item addnew-tassk py-1" @click.prevent="viewItemDetails(accountPayableReceivable)" href="javascript:"><i class="fa fa-list-alt pr-2"></i>Transaction Details</a>
                                            <a class="dropdown-item addnew-tassk py-1" @click.prevent="openAccountPayableReceivablePopup(accountPayableReceivable, true, null)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Transaction</a>
                                            <a class="dropdown-item addnew-tassk py-1" @click="openFinTxnPopup(accountPayableReceivable)" href="javascript:"><i class="fa fa-plus-circle pr-2"></i>Add {{ accountPayableReceivable.accountPayableReceivableTypeId==1 ? 'Payment' : 'Receipt' }}</a>
                                            <a class="dropdown-item py-1" @click.prevent="deleteAccPayableReceivable(accountPayableReceivable.id)" v-b-modal.deleteAccPayableReceivable href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Transaction</a>
                                        </div>
                                        <div class="pt-2 pb-1 px-2">
                                            <span v-if="accountPayableReceivable.accountPayableReceivableTypeId == '1'" class="badge badge-danger pointer float-right" >
                                                {{ currency && currency!== 'null' ? currency : '' }} {{ accountPayableReceivable.amount }}
                                            </span>
                                            <span v-else class="badge badge-success pointer float-right">
                                                {{ currency && currency!== 'null' ? currency : '' }} {{ accountPayableReceivable.amount }}
                                            </span>
                                            <div class="card-media-body-top">
                                                <span :title="accountPayableReceivable.title" @click="viewItemDetails(accountPayableReceivable)" class="pointer">{{ accountPayableReceivable.title }}</span>
                                            </div>
                                            <template v-if="accountPayableReceivable.prTxnTotal || accountPayableReceivable.balanceAmount">
                                                <div class="divider-line"></div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0 text-left">
                                                                <span v-if="accountPayableReceivable.prTxnTotal" :title="`Total Amount ${accountPayableReceivable.accountPayableReceivableTypeId==1 ? 'Paid' : 'Received'}: ${currency && currency!== 'null' ? currency : '' }${ accountPayableReceivable.prTxnTotal }`">
                                                                    {{ accountPayableReceivable.accountPayableReceivableTypeId==1 ? 'Paid' : "Rec'd" }}: 
                                                                    {{ currency && currency!== 'null' ? currency : '' }}{{ accountPayableReceivable.prTxnTotal }}
                                                                </span>
                                                            </div>
                                                            <div class="col-6 px-0 text-right">
                                                                <span class="fs-12" :title="`Balance Amount: ${currency && currency!== 'null' ? currency : '' }${accountPayableReceivable.amount + accountPayableReceivable.prTxnTotal}`">Bal: 
                                                                {{ currency && currency!== 'null' ? currency : '' }}{{ accountPayableReceivable.amount + accountPayableReceivable.prTxnTotal }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <div class="divider-line"></div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row card-media-object-social-list">
                                                        <div class="col-6 p-0 text-left" :class="{'col-sm-4' : daysToShow == 7 }">
                                                            <div v-if="accountPayableReceivable.createdBy">
                                                                <b-badge v-if="daysToShow > 5" class="task-badge float-left" :title="`Transaction By: ${accountPayableReceivable.createdBy}`"> {{ accountPayableReceivable.createdBy | getAcronym }} </b-badge>
                                                                <b-badge v-if="daysToShow <= 5" class="task-badge float-left" :title="`Transaction By`"> {{ accountPayableReceivable.createdBy }} </b-badge>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 px-0 text-right" :class="{'col-sm-8' : daysToShow == 7 }">
                                                            <span v-if="(accountPayableReceivable.transactionDt != null)" class="fs-12" :title="`Transaction Date: ${formatDate(accountPayableReceivable.transactionDt)}`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ accountPayableReceivable.transactionDt | formatShortDate }} </span> | 
                                                            <span v-if="(accountPayableReceivable.dueDate != null)" class="fs-12" :title="`Due Date: ${formatDate(accountPayableReceivable.dueDate)}`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ accountPayableReceivable.dueDate | formatShortDate }} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteAccPayableReceivable" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Financial Transaction?" />
        <AddFinTxnPopup v-if="showDialog" :projectTypeId="this.projectTypeId" :popupTitle="financialTxnPopupTitle" @refreshData="getAllTransactions();getTransactionSummary();" :finTxnData="financialTxn" @closeDialog="closeDialog" />
        <AccPayableReceivableDetailPopup ref="accPayableReceivableDetailRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" />
        <AddAccountPayableReceivablePopup v-if="showDialog" :projectTypeId="this.projectTypeId" @refreshData="getAllTransactions();getTransactionSummary();" :accountPayableReceivableData="accountPayableReceivable" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import AccPayableReceivableDetailPopup from '@/components/popup/itemdetail/AccPayableReceivableDetailPopup.vue';
import AddAccountPayableReceivablePopup from '@/components/popup/action/AddAccountPayableReceivablePopup.vue';
import AddFinTxnPopup from '@/components/popup/action/AddFinTxnPopup.vue';
import moment from 'moment';
export default {
    components: {
        AccPayableReceivableDetailPopup,
        AddAccountPayableReceivablePopup,
        AddFinTxnPopup,
    },
    data() {
        return {
            refPopupTitle: '',
            referencePopupId: 0,
            detailViewData: {},
            showDialog: false,

            financialTxnPopupTitle: '',

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            currency: localStorage.getItem('currency'),

            showDateRange: true,
            daysToShow: 7,
            selectedDate: null,
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            startMonth: new Date(),
            endMonth: new Date(),
            currentDateList: [],

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            accountPayableReceivable: {
                editMode: null,
                id: '',
                accountPayableReceivableId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'account_payable_receivable',
                accountHeadId: '',
                accountPayableReceivableTypeId: '',
                title: '',
                description: '',
                amount: '',
                balanceAmount: '',
                dueDate: '',
                transactionDt: this.getDateValue(new Date()),
                responsiblePersonId: '',
                isFreeze: false,
                createdById: '',
            },

            financialTxn: {
                editMode: null,
                id: '',
                financialTransactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'financial_transaction',
                accountHeadId: '',
                transactionTypeId: '',
                categoryId: '',
                title: '',
                description: '',
                amount: '',
                preparedById: '',
                transactionDt: '',
                transactionById: '',
                accountablePersonId: '',
                statusId: '',
                activityId: '',
                entityId: '',
                isPayableReceivable: false
            },

            finTransactions: [],
            payableReceivableSummary: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
    },
    mounted() {
        this.dateList();
        this.getAllTransactions();
        this.getTransactionSummary();

        // To prevent default closing of calendar dropdown
        $('.dropdown-menu').on('click', function(e) {
            e.stopPropagation();
        });
    },
    methods: {
        getAllTransactions: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,           
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/list', data)
                .then((response) => {
                    let result = response.data;
                    this.finTransactions = result.accountPayableReceivableList;
                })
                .catch(error => {
                    console.log(error);
                });

        },
        getTransactionSummary: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,           
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/summary', data)
                .then((response) => {
                    let result = response.data;
                    this.payableReceivableSummary = result.payableReceivableSummary ? result.payableReceivableSummary : [];
                    console.log(result);
                })
                .catch(error => {
                    console.log(error);
                });

        },
        dateList(selectedDate) {
            if (selectedDate) {
                this.currentStartDate = moment(selectedDate).toDate();
                this.startMonth = new Date();
                this.daysToShow = 1;
            }
            else {

                /* to set Sunday as first day */
                // this.currentStartDate = moment().startOf('week').toDate();

                /* to set Monday as first day */
                this.currentStartDate = moment().startOf('isoweek').toDate();
                this.startMonth = new Date();

                /* to set current day as first day */
                // this.currentStartDate = new Date();
            }
            var endDate = new Date();
            var endMonth = new Date();
            endDate.setDate(this.currentStartDate.getDate() + 6);
            endMonth.setMonth(this.startMonth.getMonth() + 6);

            this.currentEndDate = endDate;
            this.endMonth = endMonth;
            if (this.showDateRange) {
                this.currentDateList = this.getDaysArray(this.currentStartDate, endDate);
            }
            else if (this.showDateRange == false) {
                this.currentDateList = this.getDaysArray(this.currentStartDate, endMonth);
            }
        },
        changeDateRange(page, number) {
            var startDate = new Date(this.currentStartDate);
            var endDate = new Date(this.currentEndDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
                endDate.setDate(this.currentEndDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
                endDate.setDate(this.currentEndDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.currentEndDate = endDate;

            this.currentDateList = this.getDaysArray(this.currentStartDate, this.currentEndDate);
        },
        changeMonthRange(page, number) {
            var startMonth = new Date(this.startMonth);
            var endMonth = new Date(this.endMonth);

            if (page === 'nextPage') {
                startMonth.setMonth(this.startMonth.getMonth() + number);
                endMonth.setMonth(this.endMonth.getMonth() + number);
            }
            if (page === 'prevPage') {
                startMonth.setMonth(this.startMonth.getMonth() - number);
                endMonth.setMonth(this.endMonth.getMonth() - number);
            }
            this.startMonth = startMonth;
            this.endMonth = endMonth;

            this.currentDateList = this.getDaysArray(this.startMonth, this.endMonth);
        },
        getDaysArray: function(s, e) {
            if (this.showDateRange) {
                for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
                    a.push(new Date(d));
                }
            }
            else if (this.showDateRange == false) {
                for (var a = [], d = new Date(s); d <= e; d.setMonth(d.getMonth() + 1)) {
                    a.push(new Date(d));
                }
            }
            return a;
        },
        itemsByDate(date) {
            if (this.showDateRange) {
                return this.finTransactions.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
            }
            else {
                return this.finTransactions.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
            }
        },
        summaryByDate(date, amount) {
            let summary;
            if (this.showDateRange) {
                let filteredArray = this.payableReceivableSummary.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
                summary = filteredArray.reduce((accum, item) => {
                    return accum + (item[amount] ? parseFloat(item[amount]) : 0)
                }, 0.00)
            }
            else {
                let filteredArray = this.payableReceivableSummary.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
                summary = filteredArray.reduce((accum, item) => {
                    return accum + (item[amount] ? parseFloat(item[amount]) : 0)
                }, 0.00)
            }
            return summary ? summary.toFixed(2) : null;
        },
        cumulativeProfitByDate(date) {
            let summary;
            if (this.showDateRange) {
                let filteredArray = this.payableReceivableSummary.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
                let sortedArray = filteredArray.sort(function(a,b) {
                    return new Date(b.transactionDt) - new Date(a.transactionDt);
                });
                summary = sortedArray.length>0 ? sortedArray[0] : null;
            }
            else {
                let filteredArray = this.payableReceivableSummary.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
                let sortedArray = filteredArray.sort(function(a,b) {
                    return new Date(b.transactionDt) - new Date(a.transactionDt);
                });
                summary = sortedArray.length>0 ? sortedArray[0] : null;
            }
            return summary ? summary : null;
        },
        getTextVariant(value) {
            if (value && Math.sign(value) == -1) {
                return 'text-danger';
            } else return 'text-success';
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            // this.showDialog= true;
            console.log(data);
            this.$nextTick(()=> {
                this.$refs.accPayableReceivableDetailRef.getDetails(this.referencePopupId, null);
            })
        },
        deleteAccPayableReceivable: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAllTransactions();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        openAccountPayableReceivablePopup: function(data, flag, accountPayableReceivableTypeId) {
            if (flag == true) {
                this.accountPayableReceivable.id = data.id;
                this.accountPayableReceivable.accountPayableReceivableId = data.accountPayableReceivableId;
                this.accountPayableReceivable.vhextAccountId = data.vhextAccountId;
                this.accountPayableReceivable.projectId = data.projectId;
                this.accountPayableReceivable.entity = data.entity;
                this.accountPayableReceivable.accountHeadId = data.accountHeadId;
                this.accountPayableReceivable.accountPayableReceivableTypeId = data.accountPayableReceivableTypeId;
                this.accountPayableReceivable.title = data.title;
                this.accountPayableReceivable.description = data.description;
                this.accountPayableReceivable.amount = data.amount;
                this.accountPayableReceivable.balanceAmount = data.balanceAmount;
                this.accountPayableReceivable.dueDate = data.dueDate;
                this.accountPayableReceivable.transactionDt = data.transactionDt;
                this.accountPayableReceivable.responsiblePersonId = data.responsiblePersonId;
                this.accountPayableReceivable.isFreeze = data.isFreeze;
                this.accountPayableReceivable.createdById = data.createdById;
            } else {
                this.accountPayableReceivable.id = '';
                this.accountPayableReceivable.accountPayableReceivableId = '';
                this.accountPayableReceivable.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.accountPayableReceivable.projectId = localStorage.getItem("projectId");
                this.accountPayableReceivable.entity = 'account_payable_receivable';
                this.accountPayableReceivable.accountHeadId = '';
                this.accountPayableReceivable.accountPayableReceivableTypeId = accountPayableReceivableTypeId;
                this.accountPayableReceivable.title = '';
                this.accountPayableReceivable.description = '';
                this.accountPayableReceivable.amount = '';
                this.accountPayableReceivable.balanceAmount = '';
                this.accountPayableReceivable.dueDate = '';
                this.accountPayableReceivable.transactionDt = this.getDateValue(new Date());
                this.accountPayableReceivable.responsiblePersonId = '';
                this.accountPayableReceivable.isFreeze = '';
                this.accountPayableReceivable.createdById = localStorage.getItem("userId");

                // this.accountPayableReceivable.transactionDt = this.getDateValue(new Date());
                // this.accountPayableReceivable.createdById = localStorage.getItem("userId");
                // this.accountPayableReceivable.accountablePersonId = localStorage.getItem("userId");
                // this.accountPayableReceivable.statusId = '';
                // this.accountPayableReceivable.activityId = '';
                // this.accountPayableReceivable.entityId = '';
            }
            this.accountPayableReceivable.editMode = flag;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('addAccountPayableReceivablePopup');
            })
        },
        openFinTxnPopup: function(data) {
            // if (flag == true) {
            //     this.financialTxn.id = data.id;
            //     this.financialTxn.financialTransactionId = data.financialTransactionId;
            //     this.financialTxn.vhextAccountId = data.vhextAccountId;
            //     this.financialTxn.projectId = data.projectId;
            //     this.financialTxn.entityRecordMasterId = data.entityRecordMasterId;
            //     this.financialTxn.entity = data.entity;
            //     this.financialTxn.accountHeadId = data.accountHeadId;
            //     this.financialTxn.transactionTypeId = data.transactionTypeId;
            //     this.financialTxn.categoryId = data.categoryId;
            //     this.financialTxn.title = data.title;
            //     this.financialTxn.description = data.description;
            //     this.financialTxn.amount = data.amount;
            //     this.financialTxn.preparedById = data.preparedById;
            //     this.financialTxn.transactionDt = data.transactionDt;
            //     this.financialTxn.transactionById = data.transactionById;
            //     this.financialTxn.accountablePersonId = data.accountablePersonId;
            //     this.financialTxn.statusId = data.statusId;
            //     this.financialTxn.activityId = data.activityId;
            //     this.financialTxn.entityId = data.entityId;
            // } else {
                this.financialTxn.id = '';
                this.financialTxn.financialTransactionId = '';
                this.financialTxn.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.financialTxn.projectId = localStorage.getItem("projectId");
                this.financialTxn.entityRecordMasterId = '';
                this.financialTxn.entity = 'financial_transaction';
                this.financialTxn.accountHeadId = data.accountHeadId;
                this.financialTxn.accountHead = data.accountHead;
                this.financialTxn.transactionTypeId = '';
                this.financialTxn.categoryId = '';
                this.financialTxn.title = '';
                this.financialTxn.description = '';
                this.financialTxn.amount = '';
                this.financialTxn.preparedById = localStorage.getItem("userId");
                this.financialTxn.transactionDt = this.getDateValue(new Date());
                this.financialTxn.transactionById = localStorage.getItem("userId");
                this.financialTxn.accountablePersonId = localStorage.getItem("userId");
                this.financialTxn.statusId = '';
                this.financialTxn.activityId = '';
                this.financialTxn.entityId = '';
                this.financialTxn.isPayableReceivable = true;
                this.financialTxn.accountPayableReceivableId = data.id;
            // }
            this.financialTxnPopupTitle = data.accountPayableReceivableTypeId == 1 ? 'Payable Transaction' : 'Receviable Transaction';
            this.financialTxn.editMode = false;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('addFinTxnPopup');
            })
        },
        redirectPage: function() {
            if (this.projectId && this.projectId != '') {
                this.$router.push('/ftxmas/list');
            } else {
                this.$router.push('/landing/ftxmas/list');
            }
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
    }
}
</script>