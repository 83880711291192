<template>
    <div>
        <b-modal no-close-on-backdrop no-close-on-esc centered title="Task Financial Transaction" no-enforce-focus @hide="closeDialog" :id="id" ref="taskFinancialPopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="3">Title</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.taskFinancialTxn.title.$error }">
                                <b-form-input id="title" v-model="taskFinancialTxn.title" placeholder="Enter Title" required></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.taskFinancialTxn.title.$error && !$v.taskFinancialTxn.title.minLength" class="text-danger">Title must have at least {{$v.taskFinancialTxn.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.taskFinancialTxn.title.$error && !$v.taskFinancialTxn.title.required" class="text-danger">Please enter Title</label>
                            <label v-if="$v.taskFinancialTxn.title.$error && !$v.taskFinancialTxn.title.maxLength" class="text-danger">Title must not exceed {{$v.taskFinancialTxn.title.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Amount</b-col>
                        <b-col>
                            <VhextMoneyControl :value="Math.abs(Number(taskFinancialTxn.amount))" @money-input="moneyControlInput" />
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Txn Date</b-col>
                        <b-col>
                            <input v-model="taskFinancialTxn.transactionDt" type="Date" class="form-control">
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="cancelEvent">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>Adding..
                        </span>
                        <span v-else>Add</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'

export default {
    props: {
        id: { type: String, default: 'taskFinancialPopup' },
        taskFinancialData: Object,
    },
    components: {
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            taskFinancialTxn: {
                id: '',
                taskFinancialId: '',
                vhextAccountId: '',
                projectId: '',
                taskId: '',
                transactionTypeId: '',
                title: '',
                accountHeadId: '',
                transactionCategoryId: '',
                description: '',
                amount: '',
                isIncome: '',
                hasAttachment: '',
                accountablePersonId: '',
                transactionDt: this.getDateValue(new Date()),
                transactionById: localStorage.getItem("userId"),
            }
        }
    },
    mounted() {
        this.setTaskFinancialData();
        console.log(this.id)
    },
    validations: {
        taskFinancialTxn: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        saveData: function() {
            this.$v.taskFinancialTxn.$touch();
            // this.amountTypes = this.taskFinancialTypes;
            // this.amountTypes = this.amountTypes.find( ({ id }) => id === this.taskFinancialTxn.transactionTypeId );
            // console.log(this.amountTypes.typeClass);
            // if (this.amountTypes.typeClass == 1) {
            //     this.taskFinancialTxn.isIncome = true;
            // } else {
            //     this.taskFinancialTxn.isIncome = false;
            // }
            this.taskFinancialTxn.amount = Math.abs(this.taskFinancialTxn.amount);
            console.log(this.taskFinancialTxn.isIncome,this.taskFinancialTxn);
            if (!this.$v.taskFinancialTxn.$invalid) {
                this.showOverlay = true;
                // if (this.taskFinancialTxn.editMode) {
                //     this.updateTaskFinTxn();
                // } else {
                    this.saveTaskFinTxn();
                // }
            }
        },
        saveTaskFinTxn: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/taskfinancialtxn/add', this.taskFinancialTxn)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        updateTaskFinTxn: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/update', this.taskFinancialTxn)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        setTaskFinancialData: function() {
            this.taskFinancialTxn.vhextAccountId = this.taskFinancialData.vhextAccountId;
            this.taskFinancialTxn.projectId = this.taskFinancialData.projectId;
            this.taskFinancialTxn.taskId = this.taskFinancialData.taskId;
            console.log(this.taskFinancialTxn.vhextAccountId, this.taskFinancialTxn.projectId, this.taskFinancialTxn.taskId)
        },
        moneyControlInput: function(value) {
            this.taskFinancialTxn.amount = Math.abs(value);
        },
        cancelEvent: function() {
            this.$v.taskFinancialTxn.$reset();
            this.$bvModal.hide(this.id ? this.id : 'taskFinancialPopup');
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.$emit('refreshData');
        },
    }
}
</script>