<template>
    <div class="">
        <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
            <div class="form-row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="form-row view-filter dataTables_filter pb-1">
                                <div class="col-3">
                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                </div>
                                <div class="col-6">
                                    <div class="form-row pb-1">
                                        <div class="col-6 col-sm-6">
                                            <select v-model="standardMenuFilterData.standardMenuTypeId" title="Filter Menu Type" class="form-control form-control-sm">
                                                <option value="">Filter Menu Type</option>
                                                <option v-for="(info, index) in standardMenuTypes" :value="info.typeLookupId">{{info.value}}</option>
                                            </select>
                                        </div>                                                    
                                        <div class="col-6 col-sm-6">
                                            <select v-model="standardMenuFilterData.moduleId" title="Filter Module" class="form-control form-control-sm">
                                                <option value="">Filter Module</option>
                                                <option v-for="(info, index) in userModuleList" :value="info.id">{{info.module}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col text-right">
                                    <button v-if="user.customUserRoleId" type="submit" title="Reset User Role - Modules and Menus" v-b-modal.userRoleResetDialog @click="userRoleResetDialog(user)" class="btn btn-outline-primary sbtn btn-xs py-1 px-2">
                                        <i class="fa fa-repeat pr-1"></i>Reset
                                    </button>  
                                </div>
                            </div>
                            <b-table outlined show-empty small head-variant=:primary sticky-header="60vh" selectable :select-mode="selectMode" sort-icon-left :items="filteredUserSubMenuList" @row-selected="onUserSubMenuRowSelected" :fields="userSubMenuFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUserSubMenu">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>                                         
                                <template v-slot:cell(menuExist)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignMenuPrivilege(data.item)">
                                    </b-form-checkbox>
                                </template>
                                
                                <template v-slot:empty="userMenu">
                                    <div class="text-center">No User Menu found</div>
                                </template>
                                <template v-slot:emptyfiltered="userMenu">
                                    <div class="text-center">{{ userMenu.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <div class="form-row">
                                <div class="col">
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalUserSubMenuRows}} Items</span>
                                </div>
<!--                                 <div class="col">
                                    <div class="dataTables_length float-right">
                                        <b-form-group label="Per page" label-cols-sm="7" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                            <b-form-select v-model="perPageUserMenu" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div> -->
                            </div>
<!--                             <div class="row">
                                <div class="col">
                                    <b-pagination v-if="filteredUserSubMenuList.length!=0" pills v-model="currentPageUserSubMenu" :total-rows="totalUserSubMenuRows" :per-page="perPageUserMenu" first-number last-number align="fill" size="sm" class="mt-2 pull-right"></b-pagination>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </b-overlay>
        <DialogComponent id="userRoleResetDialog" :onYes="onUserRoleResetConfirmation" :returnParams="dialogBoxParam" title="Reset Confirmation" message="Are you sure to Reset User Roles?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    props: {
        user: Object,
    },
    components: {
        
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
           
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            refEntity: '',

            tabTitle: 'Module Privileges',

            selUserId: '',

            switchUser: {
                userId: '',
                userTypeId: '',
                userRole: '',
            },

            userRoleData: {
                customUserRoleId: '',
                customUserRole: '',
                userTypeId: '',
            },

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',
            userTypeCode:"",

            userRoleKey: 0,

            standardMenuTypeId: 3,
            // user: {
            //     id: '',
            //     userId: '',
            //     userTypeId: '',
            //     customUserRoleId: '',
            //     customUserRole: '',
            //     userName: '',
            //     position: '',
            //     userType: '',
            // },
            userMenu: {
                id: '',
                vhextAccountId: '',
                projectId: '',
                standardMenuTypeId: '',
                moduleId: '',
                mainMenuId: '',
                userId: '',
                userTypeId: '',
                customUserMenuId: '',
                customUserRoleId: '',
                standardMenuId: '',
                active: '',
            },

            standardMenuFilterData: {
                standardMenuTypeId: '',
                moduleId: '',
            },
            moduleMenuFilterData: {
                moduleTypeId: '',
            },

            notEnabled: true,

            mainTableArray: [],
            multiUserRoles: [],
            // userRoles: [],
            userModuleList: [],
            userMainMenuList: [],
            userSubMenuList: [],
            userAdminMenuList: [],
            customModules: [],
            standardMenuTypes: [],
            moduleTypes: [],
            customUserTypes: [],

            // userList: [],
            // userFields: [
            //     { key: 'userId', label: 'UID', sortable: true, sortDirection: 'desc' },
            //     { key: 'userName', label: 'Name', sortable: true, sortDirection: 'desc' },
            //     { key: 'userType', label: 'User Type', sortable: true, sortDirection: 'desc' },
            //     { key: 'customUserRoleId', label: 'User Role', sortable: true, sortDirection: 'desc' },
            //     { key: 'actions', label: 'Actions' } 
            // ],

            userModuleFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                // { key: 'moduleId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'moduleType', label: 'Module Type', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                // { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'menuExist', label: 'Assigned', sortable: true, sortDirection: 'desc' },

            ],

            // userMainMenuFields: [
            //     { key: 'index',label: 'SN', thStyle: "width: 10px" },
            //     // { key: 'mainMenuId', label: 'Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
            //     { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
            //     // { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
            //     { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
            //     { key: 'menuExist', label: 'Assigned', sortable: true, sortDirection: 'desc' },

            // ],

            userSubMenuFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                // { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                // { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Sub Menu', sortable: true, sortDirection: 'desc' },
                { key: 'menuExist', label: 'Assigned', sortable: true, sortDirection: 'desc' },

            ],
            // userAdminMenuFields: [
            //     { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
            //     { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
            //     // { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
            //     { key: 'subMenu', label: 'Menu', sortable: true, sortDirection: 'desc' },
            //     { key: 'menuExist', label: 'Assigned', sortable: true, sortDirection: 'desc' },

            // ],

            selectMode: 'single',
            totalRows: 1,
            totalUserModule: 1,
            totalUserMainMenuRows: 1,
            totalUserSubMenuRows: 1,
            totalUserAdminMenuRows: 1,
            currentPage: 1,
            currentPageUserModule: 1,
            currentPageUserMainMenu: 1,
            currentPageUserSubMenu: 1,
            currentPageUserAdminMenu: 1,
            perPage: 10,
            perPageUserMenu: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
            dialogBoxParam: {
                id: 0,
            },
        }
    },
    computed: {
        filteredUserModuleList() {
            let moduleTypeId = this.moduleMenuFilterData.moduleTypeId;
            let filteredUserModuleList = [];

            let filterArray1 = [];
            console.log(moduleTypeId)
            if (moduleTypeId) {
                filterArray1 = this.userModuleList.filter(data => {
                    if (data.moduleTypeId == moduleTypeId) {
                        return data;
                    }
                });
            }
            else filterArray1 = this.userModuleList;

            filteredUserModuleList = filterArray1;

            this.totalUserModule = filteredUserModuleList.length;
            return filteredUserModuleList;
        },
        // filteredUserMainMenuList() {
        //     let standardMenuTypeId = this.standardMenuFilterData.standardMenuTypeId;
        //     let moduleId = this.standardMenuFilterData.moduleId;
        //     let filteredUserMainMenuList = [];

        //     let filterArray1 = [];
        //     if (standardMenuTypeId) {
        //         filterArray1 = this.userMainMenuList.filter(data => {
        //             if (data.standardMenuTypeId == standardMenuTypeId) {
        //                 return data;
        //             }
        //         });
        //     }
        //     else filterArray1 = this.userMainMenuList;

        //     let filterArray2 = [];
        //     if (moduleId) {
        //         filterArray2 = filterArray1.filter(data => {
        //             if (data.moduleId == moduleId) {
        //                 return data;
        //             }
        //         });
        //     }
        //     else filterArray2 = filterArray1;

        //     filteredUserMainMenuList = filterArray2;
        //     this.totalUserMainMenuRows = filteredUserMainMenuList.length;
        //     return filteredUserMainMenuList;
        // },
        filteredUserSubMenuList() {
            let standardMenuTypeId = this.standardMenuFilterData.standardMenuTypeId;
            let moduleId = this.standardMenuFilterData.moduleId;
            let filteredUserSubMenuList = [];

            let filterArray1 = [];
            if (standardMenuTypeId) {
                filterArray1 = this.userSubMenuList.filter(data => {
                    if (data.standardMenuTypeId == standardMenuTypeId) {
                        return data;
                    }
                });
            }
            else filterArray1 = this.userSubMenuList;

            let filterArray2 = [];
            if (moduleId) {
                filterArray2 = filterArray1.filter(data => {
                    if (data.moduleId == moduleId) {
                        return data;
                    }
                });
            }
            else filterArray2 = filterArray1;

            filteredUserSubMenuList = filterArray2;
            this.totalUserSubMenuRows = filteredUserSubMenuList.length;
            return filteredUserSubMenuList;
        }
    },
    mounted() {
        this.lookupData();
        this.setPageData(this.user);
        // this.getUserList();
    },
    methods: {
        // getUserList: function() {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //     }
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/user/companyuser/list', data)
        //         .then((response) => {
        //             this.showOverlay = false;
        //             let result = response.data;
        //             this.userList = result.userList;
        //             this.$nextTick(() => {
        //                 this.selectBTableRowById(this.selUserId, this.mainTableArray, 'userTable');
        //             })
        //             this.totalRows = this.userList.length;
        //         })
        // },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/menu/userprivilege/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.standardMenuTypes = result.typeLookup;
                    this.moduleTypes = result.moduleTypeLookup;
                    this.customUserTypes = result.customUserTypeLookup;
                    this.customUserRoles = result.customUserRoleLookup;
                    this.customModules = result.customModuleLookup;
                })

        },
        getStandardUserRoles: function() {
            if (!this.user.customUserRoleId) {
                    this.userModuleList = [];
                    this.userMainMenuList = [];
                    this.userSubMenuList = []              
                return;
            }
            // alert('a');
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.user.id,
                userTypeId: this.user.userTypeId,
                customUserRoleId: this.user.customUserRoleId,
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/userrole/list/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.userModuleList = result.cmpCustomUserRoleModuleList;
                    // this.userMainMenuList = result.cmpUserMainMenuList;
                    this.userSubMenuList = result.cmpCustomUserRoleMenuList;
                    // this.userAdminMenuList = result.userAdminMenuList;
                    
                    this.totalUserModule = this.filteredUserModuleList.length;
                    // this.totalUserMainMenuRows = this.filteredUserMainMenuList.length;
                    this.totalUserSubMenuRows = this.filteredUserSubMenuList.length;
                    // this.totalUserAdminMenuRows = this.userAdminMenuList.length;

                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredUserModule(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalUserModule = filteredItems.length
            this.currentPageUserModule = 1
        },
        
        onFilteredUserMainMenu(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalUserMainMenuRows = filteredItems.length
            this.currentPageUserMainMenu = 1
        },
        onFilteredUserSubMenu(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalUserSubMenuRows = filteredItems.length
            this.currentPageUserSubMenu = 1
        },
        onFilteredUserAdminMenu(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalUserAdminMenuRows = filteredItems.length
            this.currentPageUserAdminMenu = 1
        },
        // onUserRowSelected: function(items) {
            
        //     if (items != null && items.length > 0) {

        //         let data = items[0];
        //         this.selUserId = data.id;

        //         this.userRoleKey += 1;

        //         // alert(data.id);
        //         this.elements.showDetail = true;
        //         this.user.id = data.id;
        //         this.user.userName = data.userName;
        //         this.user.userId = data.id;
        //         this.user.userTypeId = data.userTypeId;
        //         this.user.userType = data.userType;
        //         this.user.customUserRoleId = data.customUserRoleId;
        //         this.user.customUserRole = data.customUserRole;

        //         if (data.userType == "Client") {
        //             this.standardMenuTypeId = 2;
        //         }
        //         else {
        //             this.standardMenuTypeId = 1;
        //         }

        //         console.log(this.user);
        //         this.getStandardUserRoles();
        //     }
        // },
        setPageData: function(data) {
            this.selUserId = data.id;

            this.userRoleKey += 1;

            // alert(data.id);
            this.elements.showDetail = true;
            this.user.id = data.id;
            this.user.userName = data.userName;
            this.user.userId = data.id;
            this.user.userTypeId = data.userTypeId;
            this.user.userType = data.userType;
            this.user.customUserRoleId = data.customUserRoleId;
            this.user.customUserRole = data.customUserRole;

            if (data.userType == "Client") {
                this.standardMenuTypeId = 2;
            }
            else {
                this.standardMenuTypeId = 1;
            }

            console.log(this.user);
            this.getStandardUserRoles();
        },
        selectUserRow() {
            // this.$nextTick(() => {
            //     this.selectBTableRowById(this.selUserId, this.mainTableArray, 'userTable');
            // })
            this.setPageData(this.user);
        },
        onUserModuleRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
            }
        },

        onUserMainMenuRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
            }
        },

        onUserSubMenuRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
            }
        },
        onUserAdminMenuRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
            }
        },

        // userTabClick: function(index) {
        //     if (index === 0) {
        //         this.standardMenuTypeId = 1;

        //     } else if (index === 1) {

        //         this.standardMenuTypeId = 2;
        //     }

        //     this.projects = [];
        //     this.getProjects();

        //     console.log(this.isArchive);
        // },

        assignMenuPrivilege: function(item) {
            console.log(item);
            // return
            console.log(item.menuExist, item.id);

            this.userMenu.vhextAccountId = this.vhextAccountId;
            this.userMenu.userId = this.user.id;
            this.userMenu.userTypeId = this.user.userTypeId;
            this.userMenu.customUserRoleId = this.user.customUserRoleId;
            this.userMenu.standardMenuId = item.standardMenuId;

            if (item.menuExist == "true") {
                this.userMenu.active = 0;
            } else if (item.menuExist == "false"){
                this.userMenu.active = 1;
            }
            this.assignUserSubMenuPrivilege();
        },
        assignUserModulePrivilege: function(item) {
            this.userMenu.vhextAccountId = this.vhextAccountId;
            this.userMenu.userId = this.user.id;
            this.userMenu.moduleId = item.moduleId;
            this.userMenu.customUserRoleId = this.user.customUserRoleId;
            
            if (item.menuExist == "true") {
                this.userMenu.active = 0;
            } else if (item.menuExist == "false"){
                this.userMenu.active = 1;
            }

            console.log(this.userMenu);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/module/role/assign', this.userMenu)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.selectUserRow();
                    if (this.user.id == this.userId) {
                        EventBus.$emit('onTopMenuReload');
                        EventBus.$emit('onAdminSettingReload');
                    }
                })
        },
        assignUserMainMenuPrivilege: function(item) {

            if (item.menuExist == "true") {
                this.userMenu.vhextAccountId = this.vhextAccountId;
                this.userMenu.userId = this.user.id;
                this.userMenu.userTypeId = this.user.userTypeId;
                this.userMenu.standardMenuTypeId = item.standardMenuTypeId;
                this.userMenu.mainMenuId = item.mainMenuId;
                this.userMenu.active = 0;
                console.log(this.userMenu);

            } else if (item.menuExist == "false"){
                this.userMenu.vhextAccountId = this.vhextAccountId;
                this.userMenu.userId = this.user.id;
                this.userMenu.userTypeId = this.user.userTypeId;
                this.userMenu.standardMenuTypeId = item.standardMenuTypeId;
                this.userMenu.mainMenuId = item.mainMenuId;
                this.userMenu.active = 1;
                console.log(this.userMenu);
                }

            console.log(this.userMenu);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/mainmenu/role/assign', this.userMenu)
                .then((response) => {console.log(this.userMenu.userId);
                    this.showOverlay = false;
                    let result = response.data;

                    this.selectUserRow();
                    if (this.user.id == this.userId) {
                        EventBus.$emit('onTopMenuReload');
                        EventBus.$emit('onAdminSettingReload');
                    }
                })
        },

        assignUserSubMenuPrivilege: function() {
            console.log(this.userMenu);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/submenu/role/assign', this.userMenu)
                .then((response) => {console.log(this.userMenu.userId);
                    this.showOverlay = false;
                    let result = response.data;

                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.selectUserRow();
                        if (this.user.id == this.userId) {
                            EventBus.$emit('onTopMenuReload');
                            EventBus.$emit('onAdminSettingReload');
                        }
                    }
                })
        },
        userRoleResetDialog: function(data) {
            this.dialogBoxParam.id = data.id;

            this.selUserId = data.id;
            // this.user.id = data.id;
            // this.user.userName = data.userName;
            // this.user.userId = data.id;
            // this.user.userTypeId = data.userTypeId;
            // this.user.customUserRoleId = data.customUserRoleId;
            // this.user.customUserRole = data.customUserRole;
            // this.$nextTick(() => {
            //     this.selectBTableRowById(id, this.mainTableArray, 'userTable');
            // })
        },
        onUserRoleResetConfirmation: function(id) {
            this.userRoleManage(2);
        },
        userRoleManage: function(actionState) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.user.id,
                userTypeId: this.user.userTypeId,
                customUserRoleId: this.user.customUserRoleId,
                actionState: actionState,
                createdById: this.userId,                
            }
            console.log(data)
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/role/manage', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    
                    if (this.user.id == this.userId) {
                        EventBus.$emit('onTopMenuReload');
                        EventBus.$emit('onAdminSettingReload');
                    }
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.selectUserRow();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 

        },
        // updateUserTypeAndRole: function(userId, userTypeId,customUserRoleId) {
        //     let data = {
        //         vhextAccountId: localStorage.getItem("vhextAccountId"),
        //         userId: userId,
        //         userTypeId: userTypeId,
        //         customUserRoleId: customUserRoleId,
        //         actionState: 2,
        //         createdById: this.userId,
        //     }
        //     console.log(data);
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/user/updateusertypeandrole', data)
        //         .then(response => {
        //             this.showOverlay = false;
        //             var result = response.data;
        //             if (result.status == "OK") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.inlineEditRowId='';
        //                 this.getUserList();
        //             }
        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         }) 
        // },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },
    }
}
</script>