<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Checklist</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Checklist</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body>
                                            <div class="row mb-2">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="title">Title</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.checklist.title.$error }">
                                                           <input type="text" v-model="checklist.title" class="form-control" id="title" placeholder="Enter Checklist Title">
                                                           <div class="required-icon"></div>
                                                       </div>
                                                        <label v-if="$v.checklist.title.$error && !$v.checklist.title.required" class="text-danger">Please Enter a Checklist Title</label>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div v-for="checklist,index in checklistArray" class="form-check hover-controls mx-4 py-1">
                                                            <input type="checkbox" disabled :checked="false" class="form-check-input" id="checkbox-1">
                                                            <input type="text" :id="'checklist'+(index)" :tabindex="index" class="no-border-textarea w-80" v-model="checklist.checklist" @input="addChecklist(checklistArray, index)" @keyup.enter="focusNextChecklist(checklistArray, index)" placeholder="Enter Checklist Item...">
                                                            <a v-if="!(index == (checklistArray.length-1) && checklist.checklist=='')" class="hover-btn" href="javascript:" @click="removeChecklist(index)" title="Remove"><i class="fa fa-times-circle fa-lg float-right mt-1" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="saveChecklist" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="checklistList" @row-selected="onRowSelected" :fields="checklistFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <!-- <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteChecklist(data.item.id)" data-toggle="modal" data-target="#deleteChecklist"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Checklist found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="checklistList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm" >
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div>
                                            <div class="row input-group-label">
                                                <div class="col-5">
                                                    <b-input-group prepend="Id" class="text mb-3">
                                                        <b-input-group-prepend is-text><b>{{ checklistDetail.checklistId }}</b></b-input-group-prepend>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                    <div class="more-btns text-center">
                                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                            <i class="fa fa-pencil" aria-hidden="true"></i>
                                                        </button>
                                                        <div v-if="elements.editMode">
                                                            <button type="submit" @click="updateChecklist" :disabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                            <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-9">
                                                    <div class="form-group">
                                                        <label for="titleDet">Title</label>
                                                        <input type="text" class="form-control" id="titleDet" maxlength="100" :disabled="!elements.editMode" v-model='checklistDetail.title'>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="activeDet">Active</label>
                                                        <b-form-checkbox class="custom-toggle" v-model="checklistDetail.active" :disabled="!elements.editMode" name="check-button" switch id="activeDet">
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div v-for="checklist,index in checklistDetailArray" class="form-check hover-controls mx-4 py-1">
                                                            <input type="checkbox" disabled :checked="false" class="form-check-input" id="checkbox-1">
                                                            <input v-if="elements.editMode" type="text" :id="'checklistDet'+(index)" :tabindex="index+100" class="no-border-textarea w-80" v-model="checklist.checklist" @input="addChecklist(checklistDetailArray, index)" @keyup.enter="focusNextChecklist(checklistDetailArray, index)" placeholder="Enter Checklist Item...">
                                                            <span v-else>{{ checklist.checklist }}</span>
                                                            <a v-if="!(index == (checklistDetailArray.length-1) && checklist.checklist=='') && elements.editMode" class="hover-btn" href="javascript:" @click="removeChecklist(index)" title="Remove"><i class="fa fa-times-circle fa-lg float-right mt-1" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                    <label>More Details</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="createdBy">Prepared By</label>
                                                                                <input type="text" class="form-control disabled" id="createdBy" v-model='checklistDetail.preparedBy' readonly>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label for="startDate">Created Date</label>
                                                                                <div class="input-group" >
                                                                                    <input type="text" class="form-control disabled" :value="checklistDetail.createdDt | formatDate" readonly>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12 col-sm-6">
                                                                            <div class="form-group">
                                                                                <label for="endDate">Last Updated Date</label>
                                                                                <div class="input-group">
                                                                                    <input type="text" class="form-control disabled" :value="checklistDetail.lastUpdatedDt | formatDate" readonly>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteChecklist" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete checklist?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                newMode: false,
                showDetail: false,
                editMode: false,
                visible: false,
            },
            showDetOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            typeCode: 'CHL',

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            checklist: {
                id: '',
                groupHeaderId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                typeCode: 'CHL',
                title: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                active: true,
                checklistDataList: [],
            },
            checklistArray: [{ checklist: '' }],
            checklistDetailArray: [{ checklist: '' }],
            
            checklistDetail: {
                id: '',
                groupHeaderId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                groupTypeId: '',
                typeCode: 'CHL',
                title: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                active: true,
                checklistDataList: [],
            },
            notEnabled: true,
            checklistList: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            checklistFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Checklist', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                preparedBy: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getChecklistGroup();
    },
    validations: {
        checklist: {
            title: {
                required
            }
        },
        checklistDetail: {
            title: {
                required
            }
        }
    },
    methods: {
        getChecklistGroup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                typeCode: 'CHL',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/groupheader/list', data)
                .then((response) => {
                    let result = response.data;
                    this.checklistList = result.groupHeaderList;
                    this.totalRows = this.checklistList.length;
                })
        },
        getChecklist: function(groupHeaderId) {
            let data = {
                groupHeaderId: groupHeaderId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/quality/checklist/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.checklistList.length > 0) {
                        this.checklistDetailArray = result.checklistList;
                    }
                    else this.checklistDetailArray = [{ checklist: '' }];
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addChecklist: function(checklistArray, index) {
            if (index == (checklistArray.length - 1)) {
                checklistArray.push({ checklist: '' });
            }
        },
        focusNextChecklist(checklistArray, index) {
            let elementId = (this.elements.editMode ? 'checklistDet' : 'checklist') + (index+1);
            let element = document.getElementById(elementId);

            if(element) {
                element.focus();
            }
            else this.addChecklist(checklistArray, index);
        },
        removeChecklist: function (index) {
            if (this.elements.editMode) {
                this.checklistDetailArray = this.checklistDetailArray.filter((data, arrIndex) => index != arrIndex);
            } 
            else this.checklistArray = this.checklistArray.filter((data, arrIndex) => index != arrIndex);
        },
        clearData: function() {
            this.elements.newMode = false;
            this.checklist.title = '';
            this.checklist.checklistDataList = [];
            this.checklistArray = [{ checklist: '' }];
            this.$v.checklist.$reset();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveChecklist: function() {
            this.$v.checklist.$touch();
            if (!this.$v.checklist.$invalid) {
                this.checklist.checklistDataList = this.checklistArray.filter((data,i,arr) => i !== (arr.length-1) && data.checklist != '' || data.checklist != '');
                console.log(this.checklist);
                axios.post(this.$store.getters.getAPIBasePath + '/quality/checklist/group/add', this.checklist)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getChecklistGroup();
                            this.clearData();
                        }
                    })
            }
        },
        updateChecklist: function() {
            this.$v.checklistDetail.$touch();
            if (!this.$v.checklistDetail.$invalid) {
                this.checklistDetail.checklistDataList = this.checklistDetailArray.filter((data,i,arr) => i !== (arr.length-1) && data.checklist != '' || data.checklist != '');
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/quality/checklist/group/update', this.checklistDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getChecklistGroup();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        deleteChecklist: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/groupheader/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getChecklistGroup();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        cancelEditMode: function() {
            this.$v.checklistDetail.$reset();
            this.checklistDetailArray = [{ checklist: '' }];
            this.viewChecklistGroup(this.checklistDetail.id);
        },
        viewChecklistGroup: function(id) {
            this.showDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/common/groupheader/view/' + id)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    let checklistDetail = result.groupHeader;
                    if (checklistDetail) {                        
                        this.checklistDetail.id = checklistDetail.id;
                        this.checklistDetail.groupHeaderId = checklistDetail.groupHeaderId;
                        this.checklistDetail.vhextAccountId = checklistDetail.vhextAccountId;
                        this.checklistDetail.projectId = checklistDetail.projectId;
                        this.checklistDetail.entityRecordMasterId = checklistDetail.entityRecordMasterId;
                        this.checklistDetail.groupTypeId = checklistDetail.groupTypeId;
                        this.checklistDetail.typeCode = checklistDetail.typeCode;
                        this.checklistDetail.title = checklistDetail.title;
                        this.checklistDetail.description = checklistDetail.description;
                        this.checklistDetail.createdById = checklistDetail.createdById;
                        this.checklistDetail.active = checklistDetail.active;
                        this.getChecklist(this.checklistDetail.id)
                    }
                    this.elements.editMode = false;
                    this.elements.showDetail = true;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.showDetail = true;
            this.checklistDetail.id = data.id;
            this.checklistDetail.groupHeaderId = data.groupHeaderId;
            this.checklistDetail.vhextAccountId = data.vhextAccountId;
            this.checklistDetail.projectId = data.projectId;
            this.checklistDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.checklistDetail.groupTypeId = data.groupTypeId;
            this.checklistDetail.typeCode = data.typeCode;
            this.checklistDetail.title = data.title;
            this.checklistDetail.description = data.description;
            this.checklistDetail.createdById = data.createdById;
            this.checklistDetail.active = data.active;
            this.getChecklist(data.id);
            this.clearData();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.visible = false;
                this.elements.editMode = false;
                this.elements.showDetail = true;
                this.checklistDetail.id = data.id;
                this.checklistDetail.groupHeaderId = data.groupHeaderId;
                this.checklistDetail.vhextAccountId = data.vhextAccountId;
                this.checklistDetail.projectId = data.projectId;
                this.checklistDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.checklistDetail.groupTypeId = data.groupTypeId;
                this.checklistDetail.typeCode = data.typeCode;
                this.checklistDetail.title = data.title;
                this.checklistDetail.description = data.description;
                this.checklistDetail.createdById = data.createdById;
                this.checklistDetail.active = data.active;
                this.getChecklist(data.id);
                this.clearData();
            }
        },
        // showView: function(data) {
        //     this.elements.visible = true;
        //     this.checklistDetail.checklistId = data.checklistId;
        //     this.checklistDetail.checklist = data.checklist;
        //     this.checklistDetail.preparedById = data.preparedById;
        //     this.checklistDetail.preparedBy = data.preparedBy;
        //     this.checklistDetail.active = data.active;
        //     this.checklistDetail.preparedDt = data.preparedDt;
        //     this.checklistDetail.createdDt = data.createdDt;
        //     this.checklistDetail.lastUpdatedDt = data.lastUpdatedDt;
        // }

    }
}
</script>