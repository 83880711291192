<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" ref="timeSheetPopupRef" size="xl" @hide="closeDialog" modal-class="" title="Time Sheet">
            <TimeSheetComponent ref="timeSheetComponent" :taskId="taskId" :viewTimeSheet="false" />
        </b-modal>
    </div>
</template>
<script>
import TimeSheetComponent from '@/components/common/TimeSheetComponent.vue';
export default {
    components: {
        TimeSheetComponent,
    },
    props: {
        taskId: Number,
        id: { type: String, default: 'timeSheetPopup' },
    },
    data() {
        return {
        }
    },
    methods: {
        openDialog: function() {
            this.$bvModal.show('timeSheetPopup');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>