<template>
    <div class="referenceDetailPopup">

        <b-modal id="notificationDetailPopup" centered size="lg" @hide="closeDialog" modal-class="" title="Notification" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">

                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-1 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.notificationId }}</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>

                                            <div class="col-1 pr-0">
                                                <label class="mb-0 p-0 pt-2 pb-2 d-block title-block"><a title="Created Date"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></a></label>
                                            </div>
                                            <div class="col-2 pl-0 float-right">
                                                <label class="p-2 mb-0 header-details font-weight-bold">{{ popupData.createdDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0 mb-2" v-if="popupData.description">
                                        <div class="card">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 pl-2 header-details font-weight-bold"><span>Notification</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.createdBy}}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.createdDt | formatDate}}</label>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!--<b>Custom Footer</b> -->
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="closeDialog();close();" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left mr-2">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                     <b-button title="Email Share" size="sm" variant="outline-primary"  class="float-left emailShare-icon">                        
                         <a :title="emailShareModalTitle" @click.prevent="showEmailShareDialog"  href="javascript:" >
                             <i class="fa fa-envelope action-link"></i>
                        </a>
                    </b-button>
                </div>
            </template>

        </b-modal>
        <EmailShare ref="emailShareRef" id="reqSpecShareModal"  :masterData="masterData"  :entity="entity"  />
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
import ReferenceView from '@/components/common/ViewReferenceComponent.vue';
import EmailShare from '@/components/usercontrols/EmailShareControl.vue';
import html2Canvas from 'html2canvas';
import jsPDF from 'jspdf';
export default {
    name: 'notificationDetailPopup',
    props: {
        refEntity: String,
        detailViewData: Object,
    },
    components: {
        AttachmentView,
        ReferenceView,
        EmailShare
    },
    data() {
        return {
            loading: false,
            entity: 'notification',
            emailShareModalTitle: 'Email Share',
            popupData: {},
            attachmentList: {},
            refErdList: [],
            masterData: {             
                content: '',   
                title: '',
                file:''          
            },
        }
    },
    methods: {
        getDetails: function(id) {
            this.loading = true;
            console.log(id);
            if (!(this.detailViewData?.id)) {
                this.$bvModal.show('notificationDetailPopup');
                axios.get(this.$store.getters.getAPIBasePath + '/communication/notification/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {  
                            this.popupData = result.notification;
                            this.masterData.content = this.popupData.description;
                            this.masterData.title = this.popupData.title;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
            } else {
                this.popupData = this.detailViewData;
                this.masterData.content = this.popupData.description;
                this.masterData.title = this.popupData.title;
                this.$nextTick(() => {
                    this.$bvModal.show('notificationDetailPopup');
                });
                this.loading = false;
            }
                
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },      

        closeDialog() {
            this.$emit('closeDialog');
        },
      async showEmailShareDialog(){
            await this.makePDF();
            this.$nextTick(() => {
                this.$bvModal.show('reqSpecShareModal');
            }) 
        },
        makePDF(){
            let self = this;
            window.html2Canvas = html2Canvas;
            var doc = new jsPDF("l","pt","a4");
            doc.html(document.querySelector("#printMe"),{
                callback:function(pdf){
                    var blob = pdf.output('blob');                   
                    self.masterData.file = blob;
                    self.$refs.emailShareRef.onRefDataChange();
                    return self.masterData.file;  
                }
            })
           
        }
    }
}
</script>