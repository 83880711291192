import AddGoal from '@/views/goal/AddGoal.vue'
import Deliverable from '@/views/process/Deliverable.vue'
import EditGoal from '@/views/goal/EditGoal.vue'
import Goal from '@/views/goal/Goal.vue'
import ViewGoal from '@/views/goal/ViewGoal.vue'
import Objective from '@/views/goal/Objective.vue'
import GoalMaster from '@/views/goal/GoalMaster.vue'
import GoalMasterList from '@/views/goal/GoalMasterList.vue'
import Process from '@/views/process/Process.vue'
import ProjectActivity from '@/views/process/ProjectActivity.vue'
import ProjectProcess from '@/views/process/ProjectProcess.vue'
import ProcessPlan from '@/views/process/ProcessPlan.vue'
import ProcessPlanMaster from '@/views/process/ProcessPlanMaster.vue'
import ProcessPlanMasterList from '@/views/process/ProcessPlanMasterList.vue'

export default [
    {
        path: '/deliverable',
        name: 'deliverable',
        component: Deliverable,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {
        path: '/goal',
        name: 'goal',
        component: Goal,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/goal/add',
        name: 'addgoal',
        component: AddGoal,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/goal/edit/:goalid',
        name: 'editgoal',
        component: EditGoal,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/goal/view/:goalid',
        name: 'viewgoal',
        component: ViewGoal,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/goalmaster',
        name: 'goalmaster',
        component: GoalMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/goalmaster/list',
        name: 'goalmasterlist',
        component: GoalMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/goalmaster/objective/:ermId',
        name: 'goalobjective',
        component: Objective,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
   	{
        path: '/process',
        name: 'process',
        component: Process,
        meta: { accessedby: 'VSA|CAM|PRM' }
    },
    {
        path: '/prjact',
        name: 'prjact',
        component: ProjectActivity,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/project/process',
        name: 'projectprocess',
        component: ProjectProcess,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },

    {
        path: '/processplan/:ermId',
        name: 'processplan',
        component: ProcessPlan,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/pmdmas',
        name: 'pmdmas',
        component: ProcessPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pmdmas/list',
        name: 'pmdmaslist',
        component: ProcessPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
];