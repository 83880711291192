<template>
    <div>
        <FeedbackDetailMaster :ermMasterInfo="ermMasterInfo"></FeedbackDetailMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FeedbackDetailMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        FeedbackDetailMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Feedback Detail Master",
                entity: 'feedback_detail_master',
                refEntity: 'feedback_detail',
                ermListRedirectURL: '/fbdmas/list',
                erdRedirectURL: '/clifdb/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>