<template>
    <div class="activityDetailPopup">
        <b-modal id="activityDetailPopup" ref="activityDetailPopupRef" size="xl" @hide="closeDialog" body-class="p-0" footer-class="py-2" title="Process / Activity" ok-title="Close" ok-variant="secondary" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 no-gutters">
                        <div class="col-12 text-left">
                            <div class="card-body">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="form-row">
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row mb-0">
                                                        <div class="col-3 pr-0">
                                                            <label class="p-1 pt-2 pb-2 d-block title-block">Process</label>
                                                        </div>
                                                        <div class="col-9 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details">{{ popupData.process }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="row mt-0 mb-2">
                                                        <div class="col-12 col-md-3 pr-0">
                                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                                        </div>
                                                        <div class="col-12 col-md-9 pl-0">
                                                            <label class="p-2 mb-0 d-block header-details">{{ popupData.activity }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.activityDesc" class="row">
                                        <div class="col-12 col-sm-12">
                                            <div class="card mb-2">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Activity Description</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.activityDesc"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <div class="card mt-2 mb-3">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Entry</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.input"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <div class="card mt-2 mb-3">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Task</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.task"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <div class="card mt-2 mb-3">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Verify</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.verify"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <div class="card mt-2 mb-3">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Exit</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.output"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(processId, activityId) {
            this.loading = true;
            this.$bvModal.show('activityDetailPopup');
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                processId: processId,
                activityId: activityId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/etvx/list', data)
            .then((response) => {
                let result = response.data;
                if (result.processActivityList) {
                    this.popupData = result.processActivityList[0];
                    this.loading = false;
                }
            })
            .catch(error => {
                this.loading = false;
                console.log(error);
            });
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>