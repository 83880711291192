<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Payroll Configuration</h4>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-6">
                            <!-- <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-1">
                                    <li class="breadcrumb-item">
                                        <b-button class="btn btn-xs float-right px-2" variant="outline-primary" title="Existing Payslip Configuration" @click="showDialog=true;" v-b-modal.existingPayslipConfigurationLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing Payroll Configuration</b-button>
                                    </li>
                                </ol>
                            </nav> -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="card">
                <b-card-body v-if="payslipComponentList.length === 0" class="py-5 px-2">
                    <label class="d-block p-2 my-0 accordion-title text-center" block>
                        Please Add Payroll Component settings to create Payroll Configuration
                    </label>
                    <div class="text-center mt-3">
                        <b-button class="btn sbtn btn-xs px-2" variant="outline-secondary" title="Add Payroll Components" @click="$router.push({name: 'lanpayslipcomp'})">Add Payroll Component Settings</b-button>
                    </div>
                </b-card-body>
                <div v-else class="card-body p-3">
                    <b-overlay :show="showOverlay" opacity="0.4">
                        <div class="form-row">
                            <div class="col-12">
                                <div id="scope-app" class="mb-2">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <div class="form-row">
                                                <div class="col-lg-4">
                                                    <label v-if="elements.visible" class="d-block p-2 accordion-title m-0" block>
                                                        <span v-if="elements.editMode"><i class="fa fa-pencil pr-2 action-link"></i>Edit Payroll Configuration</span>
                                                        <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Payroll Configuration</span>
                                                    </label>
                                                    <b-input-group v-else prepend="Payroll Configuration" class="my-2 ml-2">
                                                        <select v-model="payslipConfig.id" @change="getPayslipConfiguration();cancelPayslipCalcEdit();" class="form-control" >
                                                            <!-- <option value="">Select</option> -->
                                                            <option v-for="(info, index) in payslipConfigList" :value="info.id">{{info.title}} {{ info.isDefault ? "(default)" : "" }}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="px-2 my-2 accordion-title m-0 float-left">
                                                        <b-button v-if="!elements.visible" class="btn sbtn btn-xs float-right px-2" variant="outline-secondary" title="Add New Payroll Configuration" @click="elements.editMode = false;" v-b-toggle.accordion-1><i class="fa fa-plus-circle pr-1"></i></i>New</b-button>
                                                    </label>
                                                    <label v-if="payslipConfigList.length > 0" class="px-2 my-2 accordion-title float-left">
                                                        <a v-if="!elements.visible" href="javascript:" class="action-link d-inline-block pt-1" @click="showEdit" v-b-toggle.accordion-1><i class="fa fa-pencil pr-2"></i>Edit</a>
                                                    </label>
                                                </div>
                                                <div v-if="payslipConfigList.length > 0" class="col-lg-4 text-right">
                                                    <b-button v-if="!elements.visible" class="btn sbtn btn-xs px-2 ml-3 my-2" variant="outline-primary" title="Preview Payslip" @click="viewAutoGenPayslip"><i class="fa fa-list pr-1"></i></i>Preview Payslip</b-button>
                                                </div>
                                            </div>
                                        </b-card-header>
                                        <div class="separator"></div>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="payslipConfig">Payroll Configuration Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.title.$error }">
                                                                <input type="text" class="form-control" v-model="payslipConfig.title" id="payslipConfig" placeholder="Enter Configuration Title">
                                                                <!-- <select v-model="payslipConfig.id" @change="getPayslipConfiguration" class="form-control" >
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in payslipConfigList" :value="info.id">{{info.value}}</option>
                                                                </select> -->
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.payslipConfig.title.$error && !$v.payslipConfig.title.required" class="text-danger">Please enter a title</label>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-3">
                                                        <label for="percentVal">Job Category</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.jobCategoryId.$error }">
                                                            <select v-model="payslipConfig.jobCategoryId" :class="{ 'is-invalid': $v.payslipConfig.jobCategoryId.$error }" class="form-control" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in jobCategoryLookup" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <div class="required-icon"></div>
                                                        </div>  
                                                        <label v-if="$v.payslipConfig.jobCategoryId.$error && !$v.payslipConfig.jobCategoryId.required" class="text-danger">Job Category is required</label>
                                                    </div> -->
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="budget">Set as Default</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="payslipConfig.isDefault" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <template v-if="!elements.editMode">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="percentVal">Basic Pay Component</label>
                                                                <input type="text" class="form-control" disabled v-model="payslipBasicComponent.payslipComponent" id="payslipConfig" >
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="percentVal">Basic Pay Percentage</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.payslipComponentSettingData.percentVal.$error }">
                                                                    <b-input-group append="% of CTC" class="mb-3">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipConfig.payslipComponentSettingData.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                    <div class="required-icon"></div>
                                                                </div>  
                                                                <label v-if="$v.payslipConfig.payslipComponentSettingData.percentVal.$error && !$v.payslipConfig.payslipComponentSettingData.percentVal.required" class="text-danger">Percentage is required</label>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <!-- <div class="col-6">
                                                        <div class="card p-2">
                                                            <label>TDS Component</label>
                                                            <div class="separator mb-3"></div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="percentVal">TDS Component</label>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.tdsComponentData.payslipComponentId.$error }">
                                                                            <select v-model="payslipConfig.tdsComponentData.payslipComponentId" :class="{ 'is-invalid': $v.payslipConfig.tdsComponentData.payslipComponentId.$error }" class="form-control" >
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in payslipComponentLookup" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                            <div v-if="payslipConfig.tdsComponentData.businessLogicTypeId" class="required-icon"></div>
                                                                        </div>  
                                                                        <label v-if="$v.payslipConfig.tdsComponentData.payslipComponentId.$error && !$v.payslipConfig.tdsComponentData.payslipComponentId.required" class="text-danger">TDS Component is required</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="percentVal">Tax Bussiness Logic</label>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.tdsComponentData.businessLogicTypeId.$error }">
                                                                            <select v-model="payslipConfig.tdsComponentData.businessLogicTypeId" class="form-control" >
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in businessLogicTypes" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                            <div v-if="payslipConfig.tdsComponentData.payslipComponentId"  class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.payslipConfig.tdsComponentData.businessLogicTypeId.$error && !$v.payslipConfig.tdsComponentData.businessLogicTypeId.required" class="text-danger">Tax Bussiness Logic is required</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                <!-- <b-form-checkbox class="custom-toggle float-right" v-model="payslipConfig.isDefault" name="check-button" switch>
                                                    Set as Default Configuration
                                                </b-form-checkbox> -->
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                            </div>
                        </div>
                        <div v-if="!elements.visible || elements.editMode" class="form-row mb-4">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card">
                                    <div class="card-body p-3">
                                        <div class="row view-filter">
                                            <div class="col-sm-12">
                                                <div class="float-right">
                                                    <div class="dataTables_length">
                                                        <b-button v-if="elements.addCompVisible" class="btn sbtn btn-xs float-right px-2" variant="outline-danger" title="Close" v-b-toggle.accordion-addComp><i class="fa fa-times pr-1"></i></i>Close</b-button>
                                                        <b-button v-else :disabled="!payslipConfig.id" class="btn sbtn btn-xs float-right px-2" variant="outline-secondary" title="Add Payroll Component" @click="showAddPayrollComponentPopup"><i class="fa fa-plus-circle pr-1"></i></i>Add Payroll Component</b-button>
                                                    </div>
                                                </div>
                                                <div class="float-left">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                        <!-- <b-card no-body class="mb-1">
                                            <b-collapse id="accordion-addComp" v-model="elements.addCompVisible" accordion="my-accordion"  role="tabpanel">
                                                <b-card-body class="p-2">
                                                    <b-table outlined show-empty class="white-header mb-0" small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="payslipComponentList"  :fields="payslipComponentFields" responsive="sm" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                                        <template #cell(index)="data">
                                                            {{ data.index + 1 }}
                                                        </template>
                                                        <template v-slot:cell(payslipComponentTypeId)="data">
                                                            <div class="" title="'EAR' - 'Earning' || 'DED' - Deduction'">{{ data.value == 1? 'EAR' : 'DED' }}</div>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <span v-if="componentAlreadyExists(data.item.id)" class="badge badge-pill badge-outline-primary px-2 py-1"><i class="fa fa-check pr-1"></i>Added</span>
                                                            <a v-else href="javascript:" title="Add Component" class="text-success" @click="addPayslipComponent(data.item)"><i class="fa fa-lg fa-plus-circle pr-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="scope">
                                                            <div class="text-center">No Payroll Component found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="scope">
                                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                        </template>
                                                    </b-table>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card> -->
                                        <b-table ref="payslipComSetTable" outlined show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="payslipComponentSettingList"  @row-selected="onRowSelected" :fields="payslipComponentSettingFields" responsive="sm" :current-page="currentPage" :filter="filter" :filterIncludedFields="filterOn" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(isTaxable)="data">
                                                <i v-if="data.value" class="fa fa-check"></i>
                                            </template>
                                            <template v-slot:cell(active)="data">
                                            </template>
                                            <template v-slot:cell(payslipComponentTypeId)="data">
                                                <div class="" title="'EAR' - 'Earning' || 'DED' - Deduction'">{{ data.value == 1? 'EAR' : 'DED' }}</div>
                                            </template>
                                            <template v-slot:cell(sblCode)="data">
                                                <div v-if="data.item.standardBusinessLogicId && (data.item.fixedVal || data.item.percentVal)">
                                                    {{ getPayrollCalcDescription(data.item.sblCode, data.item.fixedVal, data.item.percentVal) }}
                                                </div>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="Edit" @click="editPayrollComponentSetting(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                                <a v-if="!data.item.isDefault" href="javascript:" title="Delete" @click="deletePayslipComponentSetting(data.item.id)" v-b-modal.deletePayslipComponentSetting><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Payroll Component found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-lg-8 col-xl-8">
                                <div class="card h-100">
                                    <div class="card-body p-3">
                                        <b-tabs content-class="mt-3" lazy>
                                            <b-tab :title="`${payslipComponentSetting.payslipComponent} - Calc Logic`">
                                                <b-table ref="payslipConfigTable" outlined show-empty small stacked="md" :items="payslipCalcList" :fields="payslipCalcListFields" responsive="sm" >
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <a v-if="!elements.payslipCalcEditMode && !elements.payslipCalcNewMode" href="javascript:" title="Edit" @click="showEditPayslipCalc(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                        <a v-if="!elements.payslipCalcEditMode && !elements.payslipCalcNewMode" href="javascript:" title="Delete" @click="deletePayslipCalc" v-b-modal.deletePayslipCalc><i class="fa fa-trash pl-2"></i></a>
                                                        <div v-if="data.item.id == payslipCalcEditId">
                                                            <a v-if="elements.payslipCalcEditMode || elements.payslipCalcNewMode" href="javascript:" title="Save" @click="savePayslipCalcData(data.item, data.index + 1)"><i class="fa fa-check text-success pl-2"></i></a>
                                                            <a v-if="elements.payslipCalcEditMode || elements.payslipCalcNewMode" href="javascript:" title="Cancel" @click="cancelPayslipCalcEdit"><i class="fa fa-times text-danger pl-2"></i></a>
                                                        </div>
                                                    </template>
                                                    <template v-slot:cell(mathOperationTypeId)="data">
                                                        <template v-if="data.item.id == payslipCalcEditId && (elements.payslipCalcEditMode || elements.payslipCalcNewMode)">
                                                            <select v-if="data.item.id == payslipCalcEditId" v-model="data.item.mathOperationTypeId" class="form-control" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in mathOperationTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                            </select>
                                                        </template>
                                                        <div v-else>{{ getMathOperationType(data.item.mathOperationTypeId) }}</div>
                                                    </template>
                                                    <template v-slot:cell(percentVal)="data">
                                                        <template v-if="data.item.id == payslipCalcEditId && (elements.payslipCalcEditMode || elements.payslipCalcNewMode)">
                                                            <div v-if="data.item.id == payslipCalcEditId">
                                                                <div v-if="data.item.mathOperationTypeId == 1">
                                                                    <input type="number" class="form-control" v-model="data.item.fixedVal" id="payslipComponent" placeholder="Enter Amount">
                                                                </div>
                                                                <div v-if="data.item.mathOperationTypeId == 2" class="text-center">
                                                                    <b-input-group :append="'% of ' + (data.item.isBasic ? ' CTC' : '')" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="data.item.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="data.item.mathOperationTypeId == 3" class="d-flex align-items-stretch">
                                                                    <div class="pr-1 py-2">If</div>
                                                                    <select v-model="data.item.ifExp1ValOfCompId" class="form-control" >
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in payslipComponentSettingList" :value="info.payslipComponentId">{{info.payslipComponent}}</option>
                                                                    </select>
                                                                    <select v-model="data.item.mathOperatorEnum" class="form-control w-40 py-0 pl-1 pr-0 font-weight-bold" >
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in mathOperatorEnumList" class="fs-16" :value="info.id">{{info.symbol}}</option>
                                                                    </select>
                                                                    <b-form-input type="number" v-model="data.item.ifExp2Val" id="ifExp2Val" placeholder="Enter Amount"></b-form-input>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <div v-else>
                                                            <div v-if="data.item.mathOperationTypeId == 1">
                                                                {{ data.item.fixedVal }}
                                                            </div>
                                                            <div v-if="data.item.mathOperationTypeId == 2">
                                                                {{ data.item.percentVal }} % of 
                                                            </div>
                                                            <div v-if="data.item.mathOperationTypeId == 3">
                                                                <div class="pr-1 py-2">
                                                                    If {{ getPayslipCompType(data.item.ifExp1ValOfCompId) }} {{ getMathOperatorEnum(data.item.mathOperatorEnum) }} {{ data.item.ifExp2Val }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-slot:cell(percentCalcValOfCompId)="data">
                                                        <template v-if="data.item.id == payslipCalcEditId && (elements.payslipCalcEditMode || elements.payslipCalcNewMode)">
                                                            <div v-if="data.item.id == payslipCalcEditId">
                                                                <div v-if="data.item.mathOperationTypeId == 2 && !data.item.isBasic">
                                                                    <select v-model="data.item.percentCalcValOfCompId" class="form-control" >
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in payslipComponentSettingList" :value="info.payslipComponentId">{{info.payslipComponent}}</option>
                                                                    </select>
                                                                </div>
                                                                <div v-if="data.item.mathOperationTypeId == 3" class="d-flex align-items-stretch">
                                                                    <div class="pr-1 py-2">Then</div>
                                                                        <select v-model="data.item.thenValOfCompId" class="form-control mr-1" >
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in payslipComponentSettingList" :value="info.payslipComponentId">{{info.payslipComponent}}</option>
                                                                        </select>
                                                                    <div class="pr-1 py-2">Else</div>
                                                                    <input type="number" class="form-control" v-model="data.item.elseVal" id="payslipComponent" placeholder="Enter Amount">
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <div v-else>
                                                            <div v-if="data.item.mathOperationTypeId == 2 && data.item.isBasic">
                                                                CTC
                                                            </div>
                                                            <div v-if="data.item.mathOperationTypeId == 2 && !data.item.isBasic">
                                                                {{ getPayslipCompType(data.item.percentCalcValOfCompId) }}
                                                            </div>
                                                            <div v-if="data.item.mathOperationTypeId == 3">
                                                                <div class="pr-1 py-2">
                                                                    <span class="mr-2">
                                                                        Then {{ getPayslipCompType(data.item.thenValOfCompId) }}
                                                                    </span>
                                                                    <span>
                                                                        Else {{ data.item.elseVal }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-slot:custom-foot="data">
                                                        <tr>
                                                            <td v-if="!elements.payslipCalcEditMode && !elements.payslipCalcNewMode" class="text-center bg-info text-white pointer" @click="addConditionArray" :colspan="9">
                                                                <span class="">Add Component Value Calc Logic<i class="fa fa-plus ml-3" aria-hidden="true"></i></span>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Calculation Logic found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <!-- <b-card v-else no-body class="mb-2">
                            <b-card-body class="py-3 px-2">
                                <label class="d-block p-2 my-0 accordion-title text-center" block>
                                    Please Add / Choose Existing Payroll Configuration to show
                                </label>
                            </b-card-body>
                        </b-card> -->
                        <template #overlay>
                            <div class="text-center">
                                <LoadingSpinner />
                            </div>
                        </template>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- <AddExistingActivityPopup v-if="showDialog" @refresh-lookup="getPayslipConfiguration" @closeDialog="showDialog=false;" /> -->
        <PayslipDetailAutoGenPopup :key="payslipAutoGenPopupKey" ref="payslipDetailAutoGenPopupRef" :payslipConfigId="Number(payslipConfig.id)" @closeDialog="showDialog=false;" />
        <AddPayrollComponentPopup :key="addPayrollComponentPopupKey" :existingComponentList="payslipComponentSettingList" :payslipConfigId="Number(payslipConfig.id)" @refreshData="getPayslipConfigList" />
        <EditPayrollComponentSettingPopup :key="editPayrollComponentPopupKey"  ref="editPayrollComponentPopupRef" @refreshData="getPayslipConfigList" />
        <DeleteComponent id="deletePayslipComponentSetting" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Payroll Component ?" />
        <DeleteComponent id="deletePayslipCalc" :onYes="onYesPayslipCalc" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to Payroll Calculation Logic ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import AddPayrollComponentPopup from '@/components/popup/action/AddPayrollComponentPopup.vue';
import EditPayrollComponentSettingPopup from '@/components/popup/action/EditPayrollComponentSettingPopup.vue';
import PayslipDetailAutoGenPopup from '@/components/popup/itemdetail/PayslipDetailAutoGenPopup.vue';
import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        AddPayrollComponentPopup,
        EditPayrollComponentSettingPopup,
        PayslipDetailAutoGenPopup,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false,
                addCompVisible: false,
                payslipCalcEditMode: false,
                payslipCalcNewMode: false,
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entity: 'activity',
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            payslipCalcTempId: 1,
            payslipCalcEditId: '',

            payslipAutoGenPopupKey: 0,
            addPayrollComponentPopupKey: 100,
            editPayrollComponentPopupKey: 500,

            payslipConfig: {
                id: '',
                payslipConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                jobCategoryId: '',
                title: '',
                startDate: '',
                endDate: '',
                isStandard: '',
                active: true,
                isDefault: false,
                createdById: localStorage.getItem("userId"),
                payslipComponentSettingData: {
                    payslipComponentId: '',
                    percentVal: '',
                },
                tdsComponentData: {
                    payslipComponentId: '',
                    businessLogicTypeId: '',
                },
                payslipComponentSettingList: []
            },
            payslipComponentSetting: {
                id: '',
                payslipComponentSettingId: '',
                payslipComponent: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payslipConfigId: '',
                payslipComponentId: '',
                payslipComponentTypeId: '',
                isBasic: '',
                createdById: localStorage.getItem("userId"),
            },
            payslipCalc: {
                id: '',
                payslipCalcId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntityId: '',
                payslipComponent: '',
                mathOperationTypeId: '',
                fixedVal: '',
                percentVal: '',
                percentCalcValOfCompId: '',
                ifExp1ValOfCompId: '',
                mathOperatorEnum: '',
                ifExp2Val: '',
                thenValOfCompId: '',
                elseVal: '',
                execOrder: '',
                active: 1,
                createdById: localStorage.getItem("userId"),
            },
            notEnabled: true,
            mathOperationTypes: [],
            payslipConfigList: [],
            jobCategoryLookup: [],
            payslipComponentLookup: [],
            businessLogicTypes: [],
            payslipCalcList: [],
            payslipComponentSettingList: [],
            payslipComponentList: [],

            payslipComponentFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'payslipComponent', label: 'Payroll Component', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentTypeId', label: 'Type', sortable: true, sortDirection: 'desc' },
                // { key: 'projectType', label: 'Project Type', sortable: true, sortDirection: 'desc' },                
                // { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            payslipComponentSettingFields: [
                {key: 'index',label: 'SN'},
                // { key: 'payslipComponentId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponent', label: 'Component Title', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentTypeId', label: 'Component Type', sortable: true, sortDirection: 'desc' },
                { key: 'standardBusinessLogic', label: 'Calculation Logic', sortable: true, sortDirection: 'desc' },
                { key: 'standardPayslipComponent', label: 'Std. Component Map', sortable: true, sortDirection: 'desc' },
                { key: 'sblCode', label: 'Calculation', sortable: true, sortDirection: 'desc' },
                { key: 'isTaxable', label: 'Taxable', tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            payslipCalcListFields: [
                { key: 'index',label: 'Exec Order' },
                { key: 'mathOperationTypeId', label: 'Operation' },
                { key: 'percentVal', label: 'Calculation Logic' },
                { key: 'percentCalcValOfCompId', label: '' },
                { key: 'actions', label: 'Actions' }
            ],
            
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        getpayslipConfigData() {
            if (this.payslipConfig.id) {
                return this.payslipConfig;
            }
            let payslipConfig = null;
            if (this.payslipConfigList && this.payslipConfigList.length>0) {
                payslipConfig = this.payslipConfigList.find(data => data.isDefault);
                payslipConfig = payslipConfig ? payslipConfig : this.payslipConfigList[0];
            }
            return payslipConfig
        },
        payslipBasicComponent() {
            let result = {
                id: '',
                payslipComponent: '',
                payslipComponentTypeId: '',
                percentVal: '',
                standardBusinessLogicId: '',
                standardPayslipComponentId: '',
            };
            if (this.payslipComponentLookup && this.payslipComponentLookup.length>0) {
                result = this.payslipComponentLookup.find(data => data.payslipComponentTypeId==1);
            }
            return result
        }
    },
    mounted() {
        this.getPayslipConfigList();
        this.getPayslipComponentList();
        this.lookupData();
    },
    validations: {
        payslipConfig: {
            title: {
                required,
            },
            // jobCategoryId: {
            //     required
            // },
            payslipComponentSettingData: {
                payslipComponentId: {
                    required: requiredIf(function (abc) {
                        return !this.elements.editMode;
                    }),
                },
                percentVal: {
                    required: requiredIf(function (abc) {
                        return !this.elements.editMode;
                    }),
                }
            },
        },
    },
    methods: {
        getPayslipConfigList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipconfig/list',data)
                .then((response) => {
                    let result = response.data;
                    this.payslipConfigList = result.payslipConfigList;
                    this.payslipConfig.id = this.getpayslipConfigData ? this.getpayslipConfigData.id : '';
                    this.$nextTick(() => {
                        this.getPayslipConfiguration();
                    })
                })
        },
        getPayslipComponentList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/list',data)
                .then((response) => {
                    let result = response.data;
                    this.payslipComponentList = result.payslipComponentList;
                })
        },
        getPayslipConfiguration: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                payslipConfigId: this.payslipConfig.id
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.payslipComponentSettingList = result.payslipComponentSettingList;
                    this.totalRows = this.payslipComponentSettingList.length;
                    if (result.payslipComponentSettingList && result.payslipComponentSettingList.length > 0) {
                        this.$nextTick(() => {
                            this.$refs.payslipComSetTable.selectRow(0);
                        })
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcalc/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.mathOperationTypes = result.typeLookup;
                    this.payslipComponentLookup = result.payslipComponentLookup;
                    this.businessLogicTypes = result.businessTypeLookup;
                    this.jobCategoryLookup = result.customCategoryLookup;
                    this.$nextTick(() => {
                        this.payslipConfig.payslipComponentSettingData.payslipComponentId = this.payslipBasicComponent.id;
                        this.payslipConfig.payslipComponentSettingData.percentVal = this.payslipBasicComponent.percentVal;
                    })
                })

        },
        saveData: function() {
            this.$v.payslipConfig.$touch();
            if (!this.$v.payslipConfig.$invalid) {
                if (this.elements.editMode) {
                    this.updatePayslipConfig();
                } else {
                    this.savePayslipConfig();
                }
            }
        },
        savePayslipConfig: function() {
            let payslipComponent = this.payslipComponentList.find(data => data.id == this.payslipConfig.payslipComponentSettingData.payslipComponentId);
            this.payslipConfig.payslipComponentSettingData.payslipComponent = payslipComponent?.payslipComponent ? payslipComponent.payslipComponent : '';
            this.payslipConfig.payslipComponentSettingData.isTaxable = payslipComponent?.isTaxable ? payslipComponent.isTaxable : false;
            this.payslipConfig.payslipComponentSettingData.payslipComponentTypeId = payslipComponent?.payslipComponentTypeId ? payslipComponent.payslipComponentTypeId : '';
            this.payslipConfig.payslipComponentSettingData.execOrder = 1;
            this.payslipConfig.payslipComponentSettingData.mathOperationTypeId = 2;
            this.payslipConfig.payslipComponentSettingData.standardBusinessLogicId = this.payslipBasicComponent.standardBusinessLogicId;
            this.payslipConfig.payslipComponentSettingData.standardPayslipComponentId = this.payslipBasicComponent.standardPayslipComponentId;
            this.payslipConfig.payslipComponentSettingData.isBasic = true;
            this.payslipConfig.payslipComponentSettingList.push(this.payslipConfig.payslipComponentSettingData);

            // let tdsComponent = this.payslipComponentList.find(data => data.id == this.payslipConfig.tdsComponentData.payslipComponentId);
            // this.payslipConfig.tdsComponentData.payslipComponentTypeId = tdsComponent ? tdsComponent.payslipComponentTypeId : '';
            // this.payslipConfig.tdsComponentData.execOrder = 2;
            // this.payslipConfig.payslipComponentSettingData.isTdsComponent = true;
            // this.payslipConfig.payslipComponentSettingList.push(this.payslipConfig.tdsComponentData);
            // console.log(this.payslipConfig);
            // alert('returned');
            // return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipconfig/add', this.payslipConfig)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.payslipConfig.id = '';
                        this.getPayslipConfigList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updatePayslipConfig: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipconfig/update', this.payslipConfig)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipConfigList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            // this.payslipConfig.id = this.getpayslipConfigData ? this.getpayslipConfigData.id : '';
            this.payslipConfig.payslipConfigId = '';
            this.payslipConfig.jobCategoryId = '';
            this.payslipConfig.title = '';
            this.payslipConfig.startDate = '';
            this.payslipConfig.endDate = '';
            this.payslipConfig.isStandard = false;
            this.payslipConfig.active = true;
            this.payslipConfig.isDefault = false;
            this.payslipConfig.payslipComponentSettingData.payslipComponentId = this.payslipBasicComponent.id;
            this.payslipConfig.payslipComponentSettingData.percentVal = this.payslipBasicComponent.percentVal;
            this.payslipConfig.tdsComponentData.payslipComponentId = '';
            this.payslipConfig.tdsComponentData.businessLogicTypeId = '';
            this.payslipConfig.payslipComponentSettingList = [];
            this.$v.payslipConfig.$reset();

            // this.getPayslipCalc(this.payslipConfig.id);
        },
        showEdit: function() {
            if (this.payslipConfig.id) {
                let data = this.payslipConfigList.find((item) => item.id == this.payslipConfig.id);
                this.elements.editMode = true;
                this.elements.visible = true;
                this.payslipConfig.id = data.id;
                this.payslipConfig.payslipConfigId = data.payslipConfigId;
                this.payslipConfig.vhextAccountId = data.vhextAccountId;
                this.payslipConfig.jobCategoryId = data.jobCategoryId;
                this.payslipConfig.title = data.title;
                this.payslipConfig.startDate = data.startDate;
                this.payslipConfig.endDate = data.endDate;
                this.payslipConfig.isStandard = data.isStandard;
                this.payslipConfig.isDefault = data.isDefault;
                this.payslipConfig.active = data.active;
                this.payslipConfig.createdById = data.createdById;
                // this.getPayslipCalc(data.id);
            }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.payslipComponentSetting.payslipComponent = data.payslipComponent;
                this.payslipComponentSetting.id = data.id;
                // this.payslipComponentSetting.payslipComponentSettingId = data.payslipComponentSettingId;
                // this.payslipComponentSetting.vhextAccountId = data.vhextAccountId;
                // this.payslipComponentSetting.payslipConfigId = data.payslipConfigId;
                // this.payslipComponentSetting.payslipComponentId = data.payslipComponentId;
                // this.payslipComponentSetting.payslipComponentTypeId = data.payslipComponentTypeId;
                // this.payslipComponentSetting.isBasic = data.isBasic;
                // this.getPayslipCalc(data.id);
                this.clearPayslipCalcData();
            }
        },
        addPayslipComponent: function(data) {
            this.payslipComponentSetting.payslipConfigId = this.payslipConfig.id;
            this.payslipComponentSetting.payslipComponentId = data.id;
            this.payslipComponentSetting.payslipComponentTypeId = data.payslipComponentTypeId;
            this.payslipComponentSetting.isBasic = data.isBasic;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/add', this.payslipComponentSetting)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipConfigList();
                        // this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deletePayslipComponentSetting: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipConfiguration();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getPayslipCalc: function(id) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refRecordId: id,
                // refEntityId: id,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcalc/comsetting/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.payslipCalcList = result.payslipCalcList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        savePayslipCalcData(data, execOrder) {
            this.payslipCalc.id = data.id;
            this.payslipCalc.payslipCalcId = data.payslipCalcId;
            this.payslipCalc.refRecordId = this.payslipComponentSetting.id;
            this.payslipCalc.refEntityId = data.refEntityId;
            this.payslipCalc.payslipComponent = data.payslipComponent;
            this.payslipCalc.mathOperationTypeId = data.mathOperationTypeId;
            this.payslipCalc.fixedVal = data.fixedVal;
            this.payslipCalc.percentVal = data.percentVal;
            this.payslipCalc.percentCalcValOfCompId = data.percentCalcValOfCompId;
            this.payslipCalc.ifExp1ValOfCompId = data.ifExp1ValOfCompId;
            this.payslipCalc.mathOperatorEnum = data.mathOperatorEnum;
            this.payslipCalc.ifExp2Val = data.ifExp2Val;
            this.payslipCalc.thenValOfCompId = data.thenValOfCompId;
            this.payslipCalc.elseVal = data.elseVal;
            this.payslipCalc.execOrder = execOrder;
            this.payslipCalc.isBasic = data.isBasic;
            this.payslipCalc.active = data.active;
            this.payslipCalc.createdById = data.createdById;
            console.log(this.payslipCalc);
            if (this.elements.payslipCalcEditMode && !this.elements.payslipCalcNewMode) {
                this.updatePayslipCalc();
            }
            else {
                this.addPayslipCalc();
            }
        },
        addPayslipCalc: function() {
            this.payslipCalc.id = '';
            this.payslipCalc.vhextaccountid = this.vhextaccountid;
            this.payslipCalc.active = true;
            this.payslipCalc.createdById = this.userId;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcalc/add', this.payslipCalc)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.getPayslipCalc(this.payslipComponentSetting.id);
                        this.clearPayslipCalcData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updatePayslipCalc: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcalc/update', this.payslipCalc)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.getPayslipCalc(this.payslipComponentSetting.id);
                        this.clearPayslipCalcData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addConditionArray() {
            if (this.elements.payslipCalcEditMode || this.elements.payslipCalcNewMode) {
                return;
            }
            this.payslipCalcTempId++;
            let data = {
                id: 'tempId_' + this.payslipCalcTempId,
                mathOperationTypeId: 1
            }
            this.payslipCalcList.push(data);

            this.elements.payslipCalcNewMode = true;
            this.elements.payslipCalcEditMode = false;
            this.payslipCalcEditId = data.id;
        },
        showEditPayslipCalc: function(data) {
            this.elements.payslipCalcEditMode = true;
            this.elements.payslipCalcNewMode = false;
            this.payslipCalcEditId = data.id;
        },
        cancelPayslipCalcEdit: function() {
            if (this.elements.payslipCalcEditMode) {
                // this.getPayslipCalc(this.payslipComponentSetting.id);
            }
            else if(this.elements.payslipCalcNewMode) {
                this.removePayslipCalc(this.payslipCalcEditId);
            }
            this.clearPayslipCalcData();
        },
        clearPayslipCalcData() {
            this.elements.payslipCalcEditMode = false;
            this.elements.payslipCalcNewMode = false;
            this.payslipCalcEditId = '';
            this.payslipCalc.id = '';
            this.payslipCalc.payslipCalcId = '';
            this.payslipCalc.refRecordId = '';
            this.payslipCalc.refEntityId = '';
            this.payslipCalc.payslipComponent = '';
            this.payslipCalc.mathOperationTypeId = '';
            this.payslipCalc.fixedVal = '';
            this.payslipCalc.percentVal = '';
            this.payslipCalc.percentCalcValOfCompId = '';
            this.payslipCalc.ifExp1ValOfCompId = '';
            this.payslipCalc.mathOperatorEnum = '';
            this.payslipCalc.ifExp2Val = '';
            this.payslipCalc.thenValOfCompId = '';
            this.payslipCalc.elseVal = '';
            this.payslipCalc.execOrder = '';
            this.payslipCalc.active = 1;
        },
        removePayslipCalc: function(id) {
            if (id.toString().includes('tempId')) {
                this.payslipCalcList = this.payslipCalcList.filter(data => data.id != id);
            }
        },
        deletePayslipCalc: function(id) {
            this.deleteParam.id = id;
        },
        onYesPayslipCalc() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/payslipcalc/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipConfiguration();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 0;

                this.updateActivationState();

            } else {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 1;

                this.updateActivationState();
            }
            console.log(this.activity.id, this.activity.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.activity)
                .then((response) => {
                    console.log(this.activity.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.activity.active = true;
                    this.getPayslipConfiguration();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        viewAutoGenPayslip: function(data) {
            this.payslipAutoGenPopupKey++;
            this.$nextTick(()=> {
                this.$bvModal.show('payslipDetailAutoGenPopup');
            })
        },
        showAddPayrollComponentPopup: function(data) {
            this.addPayrollComponentPopupKey++;
            this.$nextTick(()=> {
                this.$bvModal.show('addPayrollComponentPopup');
            })
        },
        editPayrollComponentSetting: function(data) {
            this.editPayrollComponentPopupKey++;
            this.$nextTick(()=> {
                this.$refs.editPayrollComponentPopupRef.editPayrollComponentSetting(data);
                this.$bvModal.show('editPayrollComponentSettingPopup');
            })
        },
        componentAlreadyExists: function(id) {
            let result = false;
            this.payslipComponentSettingList.map(data => {
                if (id && data.payslipComponentId && id == data.payslipComponentId) {
                    result = true;
                }
            });
            return result;
        },
        getMathOperationType(id) {
            let resultData = this.mathOperationTypes.find(data => data.typeLookupId == id);
            return resultData ? resultData.value : '';
        },
        getPayslipCompType(id) {
            let resultData = this.payslipComponentSettingList.find(data => data.payslipComponentId == id);
            return resultData ? resultData.payslipComponent : '';
        },
        getMathOperatorEnum(id) {
            let resultData = this.mathOperatorEnumList.find(data => data.id == id);
            return resultData ? resultData.value : '';
        },
        getPayrollCalcDescription(sblCode, fixedVal, percentVal) {
            if(sblCode=='pctc') {
                return percentVal + ' % of CTC';
            }
            else if(sblCode=='pbas') {
                return percentVal + ' % of Basic';
            }
            else if(sblCode=='pbda') {
                return percentVal + ' % of Basic + DA';
            }
            else if(sblCode=='pgrs') {
                return percentVal + ' % of Gross';
            }
            else if(sblCode=='pfc') {
                return percentVal + ' % of Basic & Max Limit currencySymbol ' + fixedVal;
            }
            else if(sblCode=='fix') {
                return 'Fixed Amount ' + this.currencySymbol + fixedVal;
            }
            else if(sblCode=='otmfix') {
                return fixedVal + ' per hour';
            }
            else if(sblCode=='otmphs') {
                return percentVal + ' % of hourly salary';
            }
            else if(sblCode=='lopfix') {
                return fixedVal + ' working days';
            }
            else if(sblCode=='lopmth') {
                return 'Total days in the payroll month';
            }
            else if(sblCode=='lopwrd') {
                return 'Actual Working days in the payroll month';
            }
        }

    }
}
</script>