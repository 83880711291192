<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Process Model</span></h4>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-1">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Process</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-3">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-6 col-md-6">
                                                            <label for="Process">Phase Title</label>
                                                            <b-input-group size="sm" class="mb-3">
                                                                <!-- <template v-slot:prepend> -->
                                                                <div class="col-2 input-group-prepend p-0">
                                                                    <b-dropdown :text="phaseText" no-flip class="btn-dd" v-model="processPlan.phaseId">
                                                                        <b-dropdown-item v-for="(info, index) in phase" :key="info.id" :value="info.id" @click="processPlan.phaseId = info.id;phaseText = info.name;findPhase(processPlan.phaseId);">
                                                                            {{info.name}}
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>
                                                                </div>  
                                                                <div class="col-10 required-field-block p-0" :class="{ 'is-invalid': $v.processPlan.phaseTitle.$error }">
                                                                    <b-form-input v-model="processPlan.phaseTitle" maxlength="100" :class="{ 'is-invalid': $v.processPlan.phaseTitle.$error }" placeholder="Enter Phase Title">
                                                                    </b-form-input>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <!-- </template> -->
                                                                <label v-if="$v.processPlan.phaseId.$error && !$v.processPlan.phaseId.required" class="text-danger">Please Select Phase</label>
                                                                <label v-if="$v.processPlan.phaseTitle.$error && !$v.processPlan.phaseTitle.minLength" class="text-danger">Phase Title must have at least {{$v.processPlan.phaseTitle.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.processPlan.phaseTitle.$error && !$v.processPlan.phaseTitle.required" class="text-danger">Please enter Phase Title</label>
                                                                <label v-if="$v.processPlan.phaseTitle.$error && !$v.processPlan.phaseTitle.maxLength" class="text-danger">Phase Title must not exceed {{$v.processPlan.phaseTitle.$params.maxLength.max}} letters.</label>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-6 col-md-6">
                                                            <div class="form-group">
                                                                <label for="Process">Process</label>
                                                                <b-input-group>
                                                                    <select v-model="processPlan.processData" @change="getProcessActivities(processPlan.processData.id)" class="form-control">
                                                                        <option :value="{id: '', text:''}">Select</option>
                                                                        <option v-for="(info, index) in process" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                            <div class="col-12">
                                                            </div>
                                                        </div>
                                                    </div >
                                                    <div class="row mb-2">
                                                        <div class="col-5 col-md-5">
                                                            <div class="form-group pr-1">
                                                                <label class="d-block" for="activityId">Activities</label>
                                                                <!-- <ul v-if="(processPlan.activities).length>0" class="align-middle mt-auto px-0">
                                                                    <li v-for="activity in (processPlan.activities)" :title="activity.value" class="badge b-form-tag d-inline-flex badge-secondary my-1">
                                                                        <a href="javascript:" class="text-white">
                                                                            <span @click="viewReference(activity)" class="pointer">
                                                                                {{ activity.value }}
                                                                            </span>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                                <ul v-else class="align-middle mt-auto px-0">
                                                                    <li class="text-muted d-inline-flex my-1">No Activities found</li>
                                                                </ul> -->
                                                                <!-- <label v-for="(info, index) in processPlan.activities">
                                                                    <span v-if="index != 0">, </span>
                                                                    <span>{{ info.value }}</span>
                                                                </label> -->
                                                                <multiselect v-model="processPlan.activities" :options="activities" name="activity" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" disabled></multiselect>
                                                                <!-- <a :title="activityLookupTitle" class="setting-icon" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a> -->
                                                            </div>
                                                        </div>
                                                        <div class="col-1 pl-2">
                                                            <label class="d-block" style="visibility: hidden;"> ETVX </label>
                                                            <button type="submit" @click="openETVX" class="btn sbtn btn-xs btn-outline-secondary px-2 my-auto">ETVX</button>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group pr-3">
                                                                <label for="deliverables">Deliverables</label>
                                                                <multiselect v-model="processPlan.deliverables" :options="deliverables" name="deliverable" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                            </div>
                                                            <div class="col-12">
                                                                <a :title="deliverableLookupTitle" class="setting-icon" href="javascript:" v-b-modal.deliverableModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row">
                                                        <div class="col-12">
                                                            <div class="card-body p-0 mb-3">
                                                                <b-card no-body class="p-1">
                                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                        <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                            <span><i class="fa fa-plus-circle pr-2 action-link"></i>ETVX</span>
                                                                        </label>
                                                                    </b-card-header>
                                                                    <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                        <b-card-body class="p-1">
                                                                            <div class="row">
                                                                                <div class="col-3">
                                                                                    <div class="form-group">
                                                                                        <label for="expectedResult">Entry</label>
                                                                                        <textarea v-model="processPlan.input" rows="4" class="form-control" id="input" maxlength="2000" placeholder="Enter Process Inputs"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-3">
                                                                                    <div class="form-group">
                                                                                        <label for="expectedResult">Task</label>
                                                                                        <textarea v-model="processPlan.task" rows="4" class="form-control" id="task" maxlength="2000" placeholder="Enter Process Activities/Tasks"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-3">
                                                                                    <div class="form-group">
                                                                                        <label for="description">Verification/Validation</label>
                                                                                        <textarea v-model="processPlan.validation" rows="4" class="form-control" id="validation" maxlength="2000" placeholder="Enter Verification/Validation/Testing Information"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-3">
                                                                                    <div class="form-group">
                                                                                        <label for="description">Exit</label>
                                                                                        <textarea v-model="processPlan.output" rows="4" class="form-control" id="output" maxlength="2000" placeholder="Enter Process Output/Deliverables"></textarea>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </b-card-body>
                                                                    </b-collapse>
                                                                </b-card>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                    <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewStatement">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-6">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="processPlan.processPlanId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="processPlan.phaseTitle"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Process</label>
                                                                <input type="text" class="form-control" disabled v-model="processPlan.process">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Activity</label>
                                                                <input type="text" class="form-control" disabled v-model="processPlan.activity">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Deliverables</label>
                                                                <label class="form-control task-label">                                
                                                                    <template  v-for="(info, index) in (processPlan.deliverables)">
                                                                        <span v-if="index != 0">, </span>
                                                                        <span>{{ info.value }}</span>
                                                                    </template>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-12  col-sm-3">
                                                            <div class="form-group">
                                                                <label>Input</label>
                                                                <textarea rows="4" class="form-control" disabled v-model="processPlan.input"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-12  col-sm-3">
                                                            <div class="form-group">
                                                                <label>Task</label>
                                                                <textarea rows="4" class="form-control" disabled v-model="processPlan.task"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Validation</label>
                                                                <textarea class="form-control" rows="4" disabled v-model="processPlan.validation"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Output</label>
                                                                <textarea class="form-control" rows="4" disabled v-model="processPlan.output"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-6">
                                                            <div class="form-group">
                                                                <label>Prepared By</label>
                                                                <input type="text" class="form-control" disabled v-model="processPlan.preparedBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Created Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="processPlan.createdDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Updated Date</label>
                                                                <div class="input-group date disabled-date">
                                                                    <input type="text" class="form-control disabled" :value="processPlan.lastUpdatedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <!-- <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div> -->
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="text-left">
                                <b-table class="custom-table-bg" show-empty small stacked="sm" sort-icon-left :items="phaseDetails" :fields="phaseFields" selectable select-mode="single" responsive="sm" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                    <template v-slot:cell(phaseTitle)="data">
                                        <a class="tooltiptext font-weight-bold fs-14" href="javascript:" @click="viewItemDetails(data.item)">
                                            <span class=" action-link">Phase {{ data.item.phaseId }} : </span>{{data.value}}</a>
                                    </template>
                                    <template #cell(show_details)="row">
                                        <span class="float-left ml-3" :title="`${row.detailsShowing ? 'Hide' : 'Show'}`">
                                            <span >
                                                <i class="fa fa-angle-down fa-lg font-weight-bold action-link pointer" :class="row.detailsShowing ? 'rotation_180' : 'rotation_normal'"></i>
                                            </span>
                                        </span>
                                        <!-- <span class="float-right action-link">Phase {{ row.item.phaseId }}</span> -->
                                    </template>
                                    <template slot="row-details" slot-scope="row">
                                        <div class="data-table-rows child-tables text-left px-3">
                                            <b-table show-empty small header-variant="secondary" sort-icon-left :items="processPlanList(row.item.phaseId)" :fields="fields" responsive="sm">
                                                <template v-slot:cell(activities)="data">
                                                    <span>{{ data.value }}</span>
                                                    <a v-if="data.value" href="javascript:" @click="getETVX(data.item)" class="action-link fs-11 pl-2">ETVX</a>
                                                </template>
                                                <template v-slot:cell(deliverables)="data">
                                                    <div v-if="data.item.deliverables">
                                                        <span v-for="(info, index) in JSON.parse(data.item.deliverables)">
                                                            <span v-if="index != 0">, </span>
                                                            <span>{{ info.value }}</span>
                                                        </span>
                                                    </div>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <!-- <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                    <a href="javascript:" title="Delete" @click="deleteProcessPlan(data.item.id)" v-b-modal.deleteProcessPlan><i class="fa fa-trash pl-2"></i></a>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Process Plan found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </template>
                                    
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Process Plan found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                            </div>
                            <!-- <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span> -->

                            <!-- <b-pagination v-if="processPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" /> -->
        <ProcessActivityLookup ref="processActivityModal" :entity="entity" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" @closeDialog="closeDialog" />
        <ActivityETVXPopup ref="activityETVXPopupRef" @updateETVXActivities="updateETVXActivities" />
        <ProcessLookup ref="processLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :processLookupTitle="processLookupTitle" @refresh-lookup="lookupData" />
        <DeliverableLookup ref="deliverableLookupModal" :entity="this.entity" :deliverableLookupTitle="deliverableLookupTitle" @refresh-lookup="lookupData" />
        <ProcessPlanDetailPopup v-if="showDialog" ref="processPlanDetailPopupRef"  :detailViewData="detailViewData" :referenceDetailId="processPlanPopupId" @closeDialog="closeDialog" />
        <DeleteComponent id="deleteProcessPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Process Plan" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import ActivityETVXPopup from '@/components/popup/action/ActivityETVXPopup.vue';
import ProcessLookup from '@/components/popup/lookup/ProcessLookup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import ProcessPlanDetailPopup from '@/components/popup/itemdetail/ProcessPlanDetailPopup.vue';
import DeliverableLookup from '@/components/popup/lookup/DeliverableLookup.vue';
export default {
    name: 'app',
    components: {
        Multiselect,
        ActivityLookup,
        ActivityETVXPopup,
        ProcessLookup,
        ProcessActivityLookup,
        DeliverableLookup,
        ProcessPlanDetailPopup,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            showOverlay: false,
            showDialog: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'process_plan',

            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            
            userId: localStorage.getItem("userId"),

            phaseText: 'Phase',

            deliverables: [],
            phase: [
                { name: "Phase 1", id: 1 },
                { name: "Phase 2", id: 2 },
                { name: "Phase 3", id: 3 },
                { name: "Phase 4", id: 4 },
                { name: "Phase 5", id: 5 },
                { name: "Phase 6", id: 6 },
                { name: "Phase 7", id: 7 },
                { name: "Phase 8", id: 8 },
                { name: "Phase 9", id: 9 },
                { name: "Phase 10", id: 10 },
                { name: "Phase 11", id: 11 },
                { name: "Phase 12", id: 12 },
            ],
            activities: [],
            process: [],
            processActivityLookupTitle: 'Process / Activity Settings',
            activityLookupTitle: 'Activity Settings',
            processLookupTitle: 'Process Settings',
            deliverableLookupTitle: 'Deliverables',

            processPlan: {
                processPlanId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedById: '',
                phaseId: '',
                phaseTitle: '',
                processData: {
                    id: '',
                    text: '',
                },
                processId: '',
                activityId: '',
                input: '',
                task: '',
                validation: '',
                output: '',
                deliverables: [],
                activities: [],
                activity: '',
                preparedBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                process: '',
                phase: '',
                title: '',
            },
            notEnabled: true,
            users: [],

            detailViewData: {},
            processPlanPopupId: 0,

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            phaseFields: [
                { key: 'phaseTitle', label: `${localStorage.getItem("ermTitle")}`, tdClass: 'text-left' },
                { key: 'show_details', label: '', tdClass: 'table-col-id-size' },
                // { key: 'phaseId', label: 'Phase Id', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],
            processPlans: [],
            fields: [
                // { key: 'phaseId', label: 'Phase Id', sortable: true, sortDirection: 'desc' },
                // { key: 'phaseTitle', label: 'Phase', sortable: true, sortDirection: 'desc' },
                { key: 'process', label: 'Process', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activities', label: 'Activity', tdClass: 'table-cw-300' },
                { key: 'deliverables', label: 'Deliverables', tdClass: 'table-cw-400'},
                { key: 'actions', label: 'Actions' , tdClass: 'table-cw-100'}
            ],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        phaseDetails() {
            let phaseDetails = [];
            this.phase.forEach((phase) => {
                let processPlan = this.processPlans.find((data) => data.phaseId == phase.id);
                if (processPlan) {
                    processPlan._showDetails = true;
                    phaseDetails.push(processPlan);
                }
            })
            return phaseDetails;
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getProcessPlan();
    },
    validations: {
        processPlan: {
            phaseId: {
                required
            },
            phaseTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'objective';
            this.userId = localStorage.getItem("userId");
        },

        getProcessPlan: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }

            axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.processPlans = result.processPlanList;
                    this.clearData();

                    console.log(result);
                })
        },
        processPlanList: function(phaseId) {
            return this.processPlans.filter((e) => e.phaseId == phaseId)
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.deliverables = result.deliverableLookup;
                    this.activities = result.activityLookup;
                    this.process = result.processLookup;
                })
        },
        saveData: function() {
            if (this.processPlan.processData != null) {
                this.processPlan.process = this.processPlan.processData.text;
                this.processPlan.processId = this.processPlan.processData.id;
            }
            this.processPlan.processActivityList = [];
            this.processPlan.activities.forEach((data) => {
                this.processPlan.processActivityList.push({
                    activityId: data.id,
                    activity: data.value,
                    input: data.input ? data.input : '',
                    output: data.output ? data.output : '',
                    task: data.task ? data.task : '',
                    verify: data.verify ? data.verify : '',
                });
            })
            this.processPlan.vhextAccountId = this.vhextAccountId,
            this.processPlan.projectId = this.projectId,
            this.processPlan.entityRecordMasterId = this.entityRecordMasterId,
            this.processPlan.preparedById = this.userId,

                console.log(this.processPlan);
            this.$v.processPlan.$touch();
            if (!this.$v.processPlan.$invalid) {
                if (this.elements.editMode) {
                    this.updateProcessPlan();
                } else {
                    this.saveProcessPlan();
                }
            }
        },
        saveProcessPlan: function() {
            this.showOverlay = true;
            console.log(this.processPlan);
            axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/add', this.processPlan)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProcessPlan();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateProcessPlan: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/update', this.processPlan)
                .then((response) => {
                    console.log(this.processPlan);
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProcessPlan();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteProcessPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/process/processplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProcessPlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getProcessActivities: function(processId) {
            if (processId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    refErdId: processId,
                    refEntity: 'process'
                }
                console.log(data);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/activity/lookup/all',data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.process.activities =[];
                        
                        console.log(result);

                        this.processPlan.activities = result.projectProcessActivityLookup;


                        console.log(result.processActivityLookup);
                        console.log(result.projectProcessActivityLookup);

                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },        
        // getProcessActivities: function(refErdId, refEntity) {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectId: this.projectId,
        //         refErdId: refErdId,
        //         refEntity: refEntity
        //     }
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/lookup/all',data)
        //         .then((response) => {
        //             this.showOverlay = false;
        //             let result = response.data;
        //             this.processPlan.activities = result.processActivityLookup;
        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.processPlan.processPlanId = '';
            this.processPlan.phaseId = '';
            this.phaseText = 'Phase';
            this.processPlan.title = '';
            this.processPlan.phaseTitle = '';
            this.processPlan.processData.id = '';
            this.processPlan.processData.text = '';
            this.processPlan.processId = '';
            this.processPlan.activityId = '';
            this.processPlan.input = '';
            this.processPlan.task = '';
            this.processPlan.output = '';
            this.processPlan.validation = '';
            this.processPlan.deliverables = [];
            this.processPlan.activities = [];
            this.$v.processPlan.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.processPlan.id = data.id;
            this.processPlan.phaseId = data.phaseId;
            if (data.phaseId) {
                let phase = this.phase.find(({ id }) => id == data.phaseId);
                this.phaseText = phase.name;
            }
            this.processPlan.processPlanId = data.processPlanId;
            this.processPlan.title = data.title;
            this.processPlan.phaseTitle = data.phaseTitle;
            this.processPlan.processId = data.processId;
            this.processPlan.process = data.process;
            this.processPlan.processData.id = data.processId;
            this.processPlan.processData.text = data.process;
            this.processPlan.activityId = data.activityId;
            this.processPlan.input = data.input;
            this.processPlan.task = data.task;
            this.processPlan.validation = data.validation;
            this.processPlan.output = data.output;
            this.processPlan.deliverables = JSON.parse(data.deliverables);
            // this.processPlan.activities = JSON.parse(data.activities);
            this.getProcessActivities(data.processId);
        },
        showView: function(data) {
            console.log(data)
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.processPlan.id = data.id;
            if (data.processPlanId != null)
                this.processPlan.processPlanId = data.processPlanId.toString();
            else
                this.processPlan.processPlanId = '';
            if (data.phaseId) {
                let phase = this.phase.find(({ id }) => id == data.phaseId);
                this.phaseText = phase.name;
            }
            this.processPlan.phaseTitle = data.phaseTitle;
            this.processPlan.title = data.title;
            this.processPlan.process = data.process;
            this.processPlan.activity = data.activity;
            this.processPlan.input = data.input;
            this.processPlan.task = data.task;
            this.processPlan.validation = data.validation;
            this.processPlan.output = data.output;
            this.processPlan.deliverables = JSON.parse(data.deliverables);
            this.processPlan.activities = JSON.parse(data.activities);
            this.processPlan.preparedBy = data.preparedBy;
            this.processPlan.createdDt = data.createdDt;
            this.processPlan.lastUpdatedDt = data.lastUpdatedDt;
        },
        viewItemDetails: function(data) {
            data.deliverableData = JSON.parse(data.deliverables);
            
            this.detailViewData = data;

            this.processPlanPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.processPlanDetailPopupRef.getDetails(this.processPlanPopupId, null);
            })
        },
        findPhase: function(phaseId) {
            let data = this.processPlans.find((e) => e.phaseId == phaseId);
            this.processPlan.phaseTitle = data ? data.phaseTitle : '';
        },
        openETVX: function() {
            this.$refs.activityETVXPopupRef.setActivity(this.processPlan.activities, false);
        },
        getETVX: function(data) {
            this.$refs.activityETVXPopupRef.getActivityList(data.processId, 'process');
        },
        updateETVXActivities: function(activities) {
            this.processPlan.activities = activities;
        },
        closeDialog: function() {
            this.showDialog = false;
        },
    }
}
</script>