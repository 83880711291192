<template>
    <div class="humanResource">
        <b-modal scrollable ref="humanResourcePopup" size="md" id="humanResourcePopup" centered modal-class="humanResourcePopup" :title="humanResourcePopupTitle" :no-close-on-backdrop="true" ok-title="Ok">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.humanResourceEdit">
                                    <i class="fa fa-plus-circle pr-2 action-link"></i>Add Resource
                                </span>
                                <span v-if="elements.humanResourceEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.humanResourceVisible" role="tabpanel">
                            <b-card-body class="p-1">
                                <div class="row">
                                    <b-input-group class="form-group py-2">
                                        <div class="align-middle float-left my-auto ml-4 mr-0" cols="2">Resource Name</div>
                                        <b-col cols="7" class="pr-1">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.humanResource.name.$error }">
                                                <b-form-input id="name" v-model="humanResource.name" placeholder="Enter Resource Name"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.humanResource.name.$error && !$v.humanResource.name.required" class="text-danger">Please Enter Resource Name</label>
                                        </b-col>
                                        <div class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveHumanResourceData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearHumanResourceData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </b-input-group>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="float-right">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="humanResources" :fields="humanResourceFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left" v-model="data.item.active" :disabled=notEnabled>
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showHumanResourceEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="removeHumanResource(data.item.id)" data-toggle="modal" data-target="#removeHumanResource"><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Types found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                    <b-pagination v-if="humanResources.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        entity: String,
        humanResourcePopupTitle: String
    },
    components: {

    },
    data() {
        return {
            elements: {
                humanResourceVisible: false,
                humanResourceEdit: false
            },
            showOverlay: false,
            notEnabled: true,
            humanResources: [],
            humanResource: {
                humanResourceId: '',
                name: '',
                vhextAccountId: '',
                projectId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            humanResourceFields: [
                { key: 'humanResourceId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active User', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    validations: {
        humanResource: {
            name: {
                required
            }
        }
    },
    mounted() {
        this.getHumanResourcesPopup();
    },
    methods: {
        getHumanResourcesPopup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                // projectId: this.projectId,
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.humanResources = result.humanResourceList;
                    this.totalRows = this.humanResources.length;
                    console.log(result);
                })
                .catch(error=> {
                     this.showOverlay = false
                });
        },
        saveHumanResourceData: function() {
            this.$v.humanResource.$touch();
            if (!this.$v.humanResource.$invalid) {
                if (this.elements.humanResourceEdit) {
                    this.updateHumanResource();
                } else {
                    this.addHumanResource();
                }
            }
        },
        addHumanResource: function() {
            this.humanResource.vhextAccountId = this.vhextAccountId;
            // this.humanResource.projectId = this.projectId;
            this.humanResource.entity = this.entity;
            console.log(this.humanResource);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/add', this.humanResource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.clearHumanResourceData();
                        this.getHumanResourcesPopup();
                    }
                })
                .catch(error=> {
                     this.showOverlay = false
                });
        },
        updateHumanResource: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/update', this.humanResource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.clearHumanResourceData();
                        this.getHumanResourcesPopup();
                    }
                })
                .catch(error=> {
                     this.showOverlay = false
                });
        },
        clearHumanResourceData: function() {
            this.humanResource.name = '';
            this.$v.humanResource.$reset();
            this.elements.humanResourceEdit = false;
        },
        showHumanResourceEdit: function(items) {
            let data = items;
            this.elements.humanResourceVisible = true;
            this.elements.humanResourceEdit = true;

            this.humanResource.id = data.id;
            this.humanResource.humanResourceId = data.humanResourceId;
            this.humanResource.name = data.name;
            this.humanResource.vhextAccountId = data.vhextAccountId;
            // this.humanResource.projectId = data.projectId;
            this.humanResource.entityId = data.entityId;
            this.humanResource.active = data.active;

        },

    }
}
</script>