<template>
    <div>
        <ERMReportList ref="ermReportComponent" :ermMasterInfo="ermMasterInfo"></ERMReportList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ERMReportList from '@/components/blocks/erm/ERMReportList.vue'
export default {
    name: 'app',
    components: {
        ERMReportList,
    },
    data() {
        return {
            ermMasterInfo: {
                title: '',
                entity: '',
                refEntity: '',
                redirectURLName: '',
                actionName: 'Report',
            }
        }
    },
    watch: {
        '$route.params': {
            handler: function(params) { 
                this.ermReport(params.entity);
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {},
    methods: {
        ermReport: function(entity) {
            switch (entity) {
                
                case "bpl":
                    this.ermMasterInfo.title = "Budget plan Report";
                    this.ermMasterInfo.entity = 'budget_plan_master';
                    this.ermMasterInfo.refEntity = 'budget_plan';
                    this.ermMasterInfo.redirectURLName = 'bplrep';
                    break;
                case "coe":
                    this.ermMasterInfo.title = "Cost Esimation Report";
                    this.ermMasterInfo.entity = 'cost_estimation_master';
                    this.ermMasterInfo.refEntity = 'cost_estimation';
                    this.ermMasterInfo.redirectURLName = 'coerep';
                    break;
                 case "ckl":
                    this.ermMasterInfo.title = "Checklist Report";
                    this.ermMasterInfo.entity = 'checklist_master';
                    this.ermMasterInfo.refEntity = 'checklist';
                    this.ermMasterInfo.redirectURLName = 'cklrep';
                    break;
                case "ddb":
                    this.ermMasterInfo.title = "Defect Prevention Plan Report";
                    this.ermMasterInfo.entity = 'defect_prevention_plan_master';
                    this.ermMasterInfo.refEntity = 'defect_prevention_plan';
                    this.ermMasterInfo.redirectURLName = 'ddbrep';
                    break;
                case "dsn":
                    this.ermMasterInfo.title = "Design Document Report";
                    this.ermMasterInfo.entity = 'system_requirement_spec_master';
                    this.ermMasterInfo.refEntity = 'requirement_spec';
                    this.ermMasterInfo.redirectURLName = 'srsrep';
                    break;
                case "dvp":
                    this.ermMasterInfo.title = "Task Plan Report";
                    this.ermMasterInfo.entity = 'development_plan_master';
                    this.ermMasterInfo.refEntity = 'task';
                    this.ermMasterInfo.redirectURLName = 'dvprep';
                    break;
                case "efe":
                    this.ermMasterInfo.title = "Effort Esimation Report";
                    this.ermMasterInfo.entity = 'effort_estimation_master';
                    this.ermMasterInfo.refEntity = 'effort_estimation';
                    this.ermMasterInfo.redirectURLName = 'eferep';
                    break;        
                case "fbd":
                    this.ermMasterInfo.title = "Feedback Detail Report";
                    this.ermMasterInfo.entity = 'feedback_detail_master';
                    this.ermMasterInfo.refEntity = 'feedback_detail';
                    this.ermMasterInfo.redirectURLName = 'fbdrep';
                    break;
                case "fbf":
                    this.ermMasterInfo.title = "Feedback Form Report";
                    this.ermMasterInfo.entity = 'feedback_form_master';
                    this.ermMasterInfo.refEntity = 'feedback_form';
                    this.ermMasterInfo.redirectURLName = 'fbfrep';
                    break;   
                case "fpl":
                    this.ermMasterInfo.title = "Financial Plan Report";
                    this.ermMasterInfo.entity = 'budget_plan_master';
                    this.ermMasterInfo.refEntity = 'financial_plan';
                    this.ermMasterInfo.redirectURLName = 'fplrep';
                    break;
                case "pft":
                    this.ermMasterInfo.title = "Financial Transaction Report";
                    this.ermMasterInfo.entity = 'financial_txn_master';
                    this.ermMasterInfo.refEntity = 'financial_transaction';
                    this.ermMasterInfo.redirectURLName = 'pftrep';
                    break;
                case "obj":
                    this.ermMasterInfo.title = "Goal List Report";
                    this.ermMasterInfo.entity = 'goal_master';
                    this.ermMasterInfo.refEntity = 'objective';
                    this.ermMasterInfo.redirectURLName = 'objrep';
                    break;
                case "iss":
                    this.ermMasterInfo.title = "issue Report";
                    this.ermMasterInfo.entity = 'issue_master';
                    this.ermMasterInfo.refEntity = 'issue';
                    this.ermMasterInfo.redirectURLName = 'issrep';
                    break;
                case "srs":
                    this.ermMasterInfo.title = "Issue Tracking Report";
                    this.ermMasterInfo.entity = 'issue_master';
                    this.ermMasterInfo.refEntity = 'issue';
                    this.ermMasterInfo.redirectURLName = 'srsrep';
                    break;        
                case "pfn":
                    this.ermMasterInfo.title = "Project Funding Report";
                    this.ermMasterInfo.entity = 'financial_txn_master';
                    this.ermMasterInfo.refEntity = 'financial_transaction';
                    this.ermMasterInfo.redirectURLName = 'pftrep';
                    break;
                case "pln":
                    this.ermMasterInfo.title = "Plan List Report";
                    this.ermMasterInfo.entity = 'plan_master';
                    this.ermMasterInfo.refEntity = 'plan';
                    this.ermMasterInfo.redirectURLName = 'plnrep';
                    break;
                case "pmd":
                    this.ermMasterInfo.title = "Process Plan Report";
                    this.ermMasterInfo.entity = 'process_model_master';
                    this.ermMasterInfo.refEntity = 'process_plan';
                    this.ermMasterInfo.redirectURLName = 'pmdrep';
                    break;
                
                case "ppl":
                    this.ermMasterInfo.title = "Project Plan Report";
                    this.ermMasterInfo.entity = 'project_plan_master';
                    this.ermMasterInfo.refEntity = 'plan';
                    this.ermMasterInfo.redirectURLName = 'pplrep';
                    break;
                case "prt":
                    this.ermMasterInfo.title = "Project Team Report";
                    this.ermMasterInfo.entity = 'project_team_master';
                    this.ermMasterInfo.refEntity = 'project_team';
                    this.ermMasterInfo.redirectURLName = 'prtrep';
                    break;
                
                case "prs":
                    this.ermMasterInfo.title = "Project Standard Report";
                    this.ermMasterInfo.entity = 'project_standard_master';
                    this.ermMasterInfo.refEntity = 'project_standard';
                    this.ermMasterInfo.redirectURLName = 'prsrep';
                    break;
                case "qap":
                    this.ermMasterInfo.title = "Quality Assurance Plan Report";
                    this.ermMasterInfo.entity = 'quality_assurance_plan_master';
                    this.ermMasterInfo.refEntity = 'quality_assurance_plan';
                    this.ermMasterInfo.redirectURLName = 'qaprep';
                    break;
                case "rll":
                    this.ermMasterInfo.title = "Release List Report";
                    this.ermMasterInfo.entity = 'release_list_master';
                    this.ermMasterInfo.refEntity = 'release_list';
                    this.ermMasterInfo.redirectURLName = 'rllrep';
                    break;
                case "rpl":
                    this.ermMasterInfo.title = "Resource Plan Report";
                    this.ermMasterInfo.entity = 'resource_plan_master';
                    this.ermMasterInfo.refEntity = 'resource_plan';
                    this.ermMasterInfo.redirectURLName = 'rplrep';
                    break;
                case "rsp":
                    this.ermMasterInfo.title = "Risk Plan Report";
                    this.ermMasterInfo.entity = 'risk_plan_master';
                    this.ermMasterInfo.refEntity = 'risk_plan';
                    this.ermMasterInfo.redirectURLName = 'rsprep';
                    break;
                case "sst":
                    this.ermMasterInfo.title = "Scope Statement Report";
                    this.ermMasterInfo.entity = 'scope_statement_master';
                    this.ermMasterInfo.refEntity = 'scope_statement';
                    this.ermMasterInfo.redirectURLName = 'sstrep';
                    break;
                case "srs":
                    this.ermMasterInfo.title = "System Requirement Spec Report";
                    this.ermMasterInfo.entity = 'system_requirement_spec_master';
                    this.ermMasterInfo.refEntity = 'requirement_spec';
                    this.ermMasterInfo.redirectURLName = 'srsrep';
                    break;
                case "urq":
                    this.ermMasterInfo.title = "User RequirementReport";
                    this.ermMasterInfo.entity = 'user_requirement_master';
                    this.ermMasterInfo.refEntity = 'user_requirement';
                    this.ermMasterInfo.redirectURLName = 'urqrep';
                    break;
                default:
                    this.ermMasterInfo.title = "System Requirement Spec Report";
                    this.ermMasterInfo.entity = 'system_requirement_spec_master';
                    this.ermMasterInfo.refEntity = 'requirement_spec';
                    this.ermMasterInfo.redirectURLName = 'srsrep';
                    break;
            }
        }
    }
}
</script>