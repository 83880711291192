<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Scope Document</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <!-- <p class="contact-title"><span>Personal Information</span></p> -->
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                                <!-- <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0 ">
                                                                <select v-if="review.isReviewed == 1" v-model="review.statusId"  class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <select v-if="review.isReviewed == 2" v-model="review.statusId"  class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a v-if="review.isReviewed != 0" href="javascript:" title="update status" data-toggle="modal" data-target="#confirmUpdateStatus" class="float-right p-2 action-link">
                                                                    <i class="fa fa-check pr-2"></i>
                                                                </a>
                                                                <label v-if="review.isReviewed ==0" class="p-2 mb-0 d-block header-details">{{review.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals != null && ermReviewApprovals.length != 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description != null && erm.description.length != 0" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Scope</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label class="p-2 mb-0 d-block header-details" v-html="erm.description"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="scopeStatements != null && scopeStatements.length != 0" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Scope Statement</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="scopeStatements" :fields="scopeStatementFields" responsive="sm" :current-page="scope.currentPage" :per-page="scope.perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                            <template v-slot:cell(inScope)="data">
                                                <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                                </b-form-checkbox>
                                            </template>
                                            <template v-slot:cell(scopeStatement)="data">
                                                <span class="tooltiptext" href="#" v-html="data.value"></span>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <div>
                                                  <b-badge v-if="data.item.status == 'Draft'" variant="light">{{data.value}}</b-badge>
                                                  <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
                                                  <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                                </div>
                                            </template>                                

                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                                <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                <a href="javascript:" title="Delete" @click="deleteScopeStatement(data.item.id)" data-toggle="modal" data-target="#deleteScopeStatement"><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Scope Statements found</div>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
        <Dialog id="deleteComment" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />
        <Dialog id="confirmUpdateStatus" :onYes="updateStatus" :returnParams="deleteParam" title="Update Status" message="Are you sure to update the status?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';
export default {
    name: 'app',
    components: {
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
    },
    data() {
        return {

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            editMode: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            
            entity: '',
            ermId:'',
            scope: {
                title: '',
                description: '',
                createdBy: '',
                createdDt: '',
                entity: 'scope_statement'
            },
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],

            isBusy: true,
            scopeMasterStatus: [],
            status: [],

            scopeStatements: [],
            scopeStatementFields: [
                { key: 'scopeStatementId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'scopeStatement', label: 'Scope Statement', sortable: true, sortDirection: 'desc' },
                { key: 'inScope', label: 'In Scope' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },                
            ],
            scopeReviewApprovalFields: [

                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', sortable: true, sortDirection: 'desc' }
            ],

            scopeReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            notEnabled: true,
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        }
    },
    mounted() {
        
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.entityMaster = 'scope_statement_master';
            this.entity = 'scope_statement';

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getScopeStatement(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },

        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                    console.log(result);
                })
        },

        getScopeStatement: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/list', data)
                .then((response) => {
                    let result = response.data;
                    this.scopeStatements = result.scopeStatementList;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        deleteEntityRecordMaster: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/project/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEntityRecordMaster();
                        this.reviewMaster();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getScopeStatement(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        callFunctions: function() {
            this.entityRecordMasterCollection(this.ermId);
            this.getScopeStatement(this.ermId);
            this.$refs.chatCommentRef.getComments(this.ermId);
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'scope_statement_master'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.scopeMasterStatus = result.statusLookup;
                    if (this.review.isReviewed == 1) {
                        this.status = this.filterReviewApproval('Reviewed');
                    } else if (this.review.isReviewed == 2) {
                        this.status = this.filterReviewApproval('Approved');
                    }
                })
        },
        updateStatus: function() {

            if(this.review.statusId == ''){
              iziToast.info({
                        message: 'Please select status.',
                        position: 'topRight'
                    });  
              return;
            }

            console.log(this.review);
            axios.post(this.$store.getters.getAPIBasePath + '/review/update/status', this.review)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    if (result.status == 'ALREADY_REPORTED') {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                        return;
                    }
                    if (this.review.isReviewed == 1) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Reviewed Successfully.',
                            position: 'topRight'
                        });
                    } else if (this.review.isReviewed == 2) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Approved Successfully.',
                            position: 'topRight'
                        });
                    }
                    this.entityRecordMasterCollection(this.ermId);
                })
        },
        // checkDocType: function() {
        //     console.log(this.key);
        //     console.log(this.$route.params.ermId);

        //     if (this.key == this.$route.params.ermId) {
        //         this.ermId = this.key;
        //         this.callFunctions();
        //      } else if (this.key != "") {
        //          this.getDecodeData();
        //      }
        // },

        filterReviewApproval(value) {
            return this.scopeMasterStatus.filter(function(data, key) {
                if (data.value == value || data.value == 'Reject') {
                    return data;
                }
            });
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },

        print: function() {
            this.$htmlToPaper('printMe');
        },


    }
}
</script>