<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Work Shift</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Work Shift</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-0">
                                                <b-card no-body class="mb-2 px-2">
                                                    <b-card-header header-tag="header" class=" p-0">
                                                        <label class="d-block px-2 pt-2 pb-1 my-0 accordion-title" block>
                                                            Work Shift
                                                        </label>
                                                        <div class="separator mx-2"></div>
                                                    </b-card-header>
                                                    <b-card-body class="pb-0 pt-3 px-3">
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label for="title">Work Shift Title</label>
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.workShift.title.$error }">
                                                                        <input type="text" class="form-control" v-model="workShift.title" id="title" :class="{ 'is-invalid': $v.workShift.title.$error }" placeholder="Enter Work Shift Title">
                                                                        <div class="required-icon"></div>
                                                                    </div>  
                                                                    <label v-if="$v.workShift.title.$error && !$v.workShift.title.minLength" class="text-danger">Title must have at least {{$v.workShift.title.$params.minLength.min}} letters.</label>
                                                                    <label v-if="$v.workShift.title.$error && !$v.workShift.title.required" class="text-danger">Please enter Title</label>
                                                                    <label v-if="$v.workShift.title.$error && !$v.workShift.title.maxLength" class="text-danger">Title must not exceed {{$v.workShift.title.$params.maxLength.max}} letters.</label>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label for="workShiftTypeId">Work Shift Type</label>
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.workShift.workShiftTypeId.$error }" @change="handleWorkShiftTypeChange">
                                                                        <select v-model="workShift.workShiftTypeId" class="form-control" id="workShiftTypeId">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in workShiftTypes" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.workShift.workShiftTypeId.$error && !$v.workShift.workShiftTypeId.required" class="text-danger">Please Work shift Type</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="active">Active</label>
                                                                    <div class="input-group ">
                                                                        <b-form-checkbox class="custom-toggle" v-model="workShift.active" name="check-button" switch id="active" >
                                                                        </b-form-checkbox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="row">
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Weekly Work Hour</label>
                                                                    <b-input-group key="sm" size="sm" class="mb-3">
                                                                        <b-form-input type="number" v-model="workShift.weeklyWorkHour" min="0" ></b-form-input>
                                                                        <b-input-group-append is-text>hours</b-input-group-append>
                                                                        <b-form-input type="number" v-model="workShift.weeklyWorkMinute" min="0"></b-form-input>
                                                                        <b-input-group-append is-text>minutes</b-input-group-append>
                                                                    </b-input-group>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group">
                                                                    <label>Monthly Work Hour</label>
                                                                    <b-input-group key="sm" size="sm" class="mb-3">
                                                                        <b-form-input type="number" v-model="workShift.monthlyWorkHour" min="0" ></b-form-input>
                                                                        <b-input-group-append is-text>hours</b-input-group-append>
                                                                        <b-form-input type="number" v-model="workShift.monthlyWorkMinute" min="0"></b-form-input>
                                                                        <b-input-group-append is-text>minutes</b-input-group-append>
                                                                    </b-input-group>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="weekdays">Working Days per Week</label>
                                                                    <input type="number" disabled class="form-control" v-model.number="workShift.weekdays" id="weekdays" >
                                                                </div>
                                                            </div> -->
                                                            <!-- <div class="col-12">
                                                                <div class="form-group">
                                                                    <label for="budget">Description</label>
                                                                    <textarea v-model="workShift.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                                </div>
                                                            </div> -->
                                                        <!-- </div> -->
                                                    </b-card-body>
                                                </b-card>
                                                <b-card v-if="getWorkShiftTypeLookupId" no-body class="mb-2 px-2">
                                                    <b-card-header header-tag="header" class="p-0">
                                                        <label class="d-block px-2 pt-2 pb-1 my-0 accordion-title" block>
                                                            Daily Work Schedule
                                                        </label>
                                                        <div class="separator mx-2"></div>
                                                    </b-card-header>
                                                    <b-card-body class="pb-0 pt-3 px-3">
                                                        <template v-if="getWorkShiftTypeLookupId==1 && workScheduleList.length == 1">
                                                            <div v-for="workPatternData, index in workScheduleList" :key="index" class="row">
                                                                <div class="col-3">
                                                                    <div class="form-group">
                                                                        <label for="startTime">Start Time</label>
                                                                        <input type="time" class="form-control" v-model="workPatternData.startTime" @change="setTimeDiff(workPatternData, workPatternData.startTime, workPatternData.endTime)" id="startTime" >
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="form-group">
                                                                        <label for="endTime">End Time</label>
                                                                        <input type="time" class="form-control" v-model="workPatternData.endTime" @change="setTimeDiff(workPatternData, workPatternData.startTime, workPatternData.endTime)" id="endTime" >
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label>Daily Work Hour</label>
                                                                        <b-input-group key="sm" size="sm" class="mb-3">
                                                                            <b-form-input type="number" v-model="workPatternData.workHour" min="0" @input="setWeeklyTime"></b-form-input>
                                                                            <b-input-group-append is-text>hrs</b-input-group-append>
                                                                            <b-form-input type="number" v-model="workPatternData.workMinute" min="0" @input="setWeeklyTime"></b-form-input>
                                                                            <b-input-group-append is-text>mins</b-input-group-append>
                                                                        </b-input-group>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <b-card no-body class="mb-2 px-2">
                                                                <b-card-body class="py-2 px-3">
                                                                    <div class="row">
                                                                        <div class="col-5">
                                                                            <div class="row align-items-center no-gutters">
                                                                                <div class="col-6">
                                                                                    Working Days/week
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <input type="number" class="form-control" v-model.number="workShift.weekdays" id="weekdays" >
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-7">
                                                                            <div class="row align-items-center no-gutters">
                                                                                <div class="col-4">Weekly Work Hour</div>
                                                                                <div class="col-8">
                                                                                    <b-input-group key="sm" size="sm">
                                                                                        <b-form-input type="number" v-model.number="workShift.weeklyWorkHour" min="0" ></b-form-input>
                                                                                        <b-input-group-append is-text>hrs</b-input-group-append>
                                                                                        <b-form-input type="number" v-model.number="workShift.weeklyWorkMinute" min="0"></b-form-input>
                                                                                        <b-input-group-append is-text>mins</b-input-group-append>
                                                                                    </b-input-group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-card>
                                                        </template>
                                                        <template v-else-if="getWorkShiftTypeLookupId==2">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <b-form-group label="Working Days" v-slot="{ ariaDescribedby }">
                                                                          <b-form-checkbox-group
                                                                            id="checkbox-group-2"
                                                                            v-model="selectedDays"
                                                                            :aria-describedby="ariaDescribedby"
                                                                            name="weekdays"
                                                                          >
                                                                            <b-form-checkbox v-for="day,index in days" :key="index" :value="index" @change="addRemoveWorkSchedule(index, $event)">{{ day }}</b-form-checkbox>
                                                                          </b-form-checkbox-group>
                                                                        </b-form-group>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="data-table-rows report-tables">
                                                                <b-table class="mb-0" head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workScheduleList" @row-selected="" :fields="workScheduleFieldsVariable" responsive="sm" foot-clone no-footer-sorting >
                                                                    <template v-slot:cell(dayIndex)="data">
                                                                        {{ data.item.dayIndex != null ? getAcronym(days[data.item.dayIndex], 3) : '' }}
                                                                    </template>
                                                                    <template v-slot:cell(startTime)="data">
                                                                        <input type="time" class="form-control" v-model="data.item.startTime" id="startTime" @change="setTimeDiff(data.item, data.item.startTime, data.item.endTime)">
                                                                    </template>
                                                                    <template v-slot:cell(endTime)="data">
                                                                        <input type="time" class="form-control" v-model="data.item.endTime" id="startTime" @change="setTimeDiff(data.item, data.item.startTime, data.item.endTime)">
                                                                    </template>
                                                                    <template v-slot:cell(workHour)="data">
                                                                        <b-input-group key="sm" size="sm">
                                                                            <b-form-input type="number" v-model.number="data.item.workHour" min="0" @change="setWeeklyTime"></b-form-input>
                                                                            <b-input-group-append is-text>hours</b-input-group-append>
                                                                            <b-form-input type="number" v-model.number="data.item.workMinute" min="0" @change="setWeeklyTime"></b-form-input>
                                                                            <b-input-group-append is-text>minutes</b-input-group-append>
                                                                        </b-input-group>
                                                                    </template>
                                                                    <!-- <template v-slot:cell(actions)="data">
                                                                        <b-input-group key="sm" size="sm" class="mb-3">
                                                                            <b-form-input type="number" :disabled="!elements.editMode" v-model="data.item.workHour" min="0" ></b-form-input>
                                                                            <b-input-group-append is-text>hours</b-input-group-append>
                                                                            <b-form-input type="number" :disabled="!elements.editMode" v-model="workPatternDetail.workMinute" min="0"></b-form-input>
                                                                            <b-input-group-append is-text>minutes</b-input-group-append>
                                                                        </b-input-group>
                                                                    </template> -->
                                                                    <template v-slot:cell(actions)="data">
                                                                        <!-- <a href="javascript:" title="Edit" @click=""><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a> -->
                                                                        <a href="javascript:" title="Delete" @click="removeWorkSchedule(data.item.dayIndex)" ><i class="fa fa-trash pl-2"></i></a>
                                                                    </template>
                                                                    <template  v-slot:foot()="data">
                                                                        <span></span>
                                                                    </template>
                                                                    <template v-slot:empty="scope">
                                                                        <div class="text-center">No data found</div>
                                                                    </template>
                                                                    <template v-slot:emptyfiltered="scope">
                                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                    </template>
                                                                </b-table>
                                                            </div>
                                                            <b-card no-body class="mb-2 px-2">
                                                                <b-card-body class="py-2 px-3">
                                                                    <div class="row">
                                                                        <div class="col-7">
                                                                            <div class="row align-items-center no-gutters">
                                                                                <div class="col-5">Weekly Work Hour</div>
                                                                                <div class="col-7">
                                                                                    <b-input-group key="sm" size="sm">
                                                                                        <b-form-input type="number" v-model.number="workShift.weeklyWorkHour" min="0" ></b-form-input>
                                                                                        <b-input-group-append is-text>hrs</b-input-group-append>
                                                                                        <b-form-input type="number" v-model.number="workShift.weeklyWorkMinute" min="0"></b-form-input>
                                                                                        <b-input-group-append is-text>mins</b-input-group-append>
                                                                                    </b-input-group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-5">
                                                                            <div class="row align-items-center no-gutters">
                                                                                <div class="col-6">
                                                                                    Working Days / week
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <input type="number" class="form-control" v-model.number="workShift.weekdays" id="weekdays" >
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-card>
                                                        </template>
                                                    </b-card-body>
                                                </b-card>
                                                <!-- <div v-if="workShift.workShiftTypeId==2" class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="startTime">Working Days</label>
                                                            <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                              <b-form-checkbox-group
                                                                id="checkbox-group-1"
                                                                v-model="workShift.daysToRepeat"
                                                                :options="days"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="weekdays"
                                                              ></b-form-checkbox-group>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <button type="submit" @click="addWorkShift" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="workPatternTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="workShiftList"  @row-selected="onRowSelected" :fields="workShiftFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(workHour)="data">
                                    {{ data.item.workHour ? data.item.workHour + ' hrs' : '' }}
                                    <span v-if="data.item.workHour && data.item.workMinute"> </span>
                                    {{ data.item.workMinute ? data.item.workMinute + ' mins' : '' }}
                                </template>
                                <template v-slot:cell(weeklyWorkHour)="data">
                                    {{ data.item.weeklyWorkHour ? data.item.weeklyWorkHour + ' hrs' : '' }}
                                    <span v-if="data.item.weeklyWorkHour && data.item.weeklyWorkMinute"> </span>
                                    {{ data.item.weeklyWorkMinute ? data.item.weeklyWorkMinute + ' mins' : '' }}
                                </template>
                                <template v-slot:cell(startTime)="data">
                                    <div v-if="data.value">
                                        {{ formatTimeFromHM(data.item.startTime) }} to {{ formatTimeFromHM(data.item.endTime) }}
                                    </div>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
<!--                                     <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a> -->
                                    <a href="javascript:" title="Delete" @click="deleteWorkShift(data.item.id)" v-b-modal.deleteWorkShift><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Work Shift found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                            <b-pagination v-if="workShiftList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <!-- <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ workPatternDetail.workPatternId }}</b></b-input-group-prepend>
                                                </b-input-group> -->
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateWorkShift" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="form-group">
                                                    <label for="title1">Work Shift Title</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.workShiftDetail.title.$error }">
                                                        <input type="text" :disabled="!elements.editMode" class="form-control" v-model="workShiftDetail.title" id="title" :class="{ 'is-invalid': $v.workShiftDetail.title.$error }" placeholder="Enter Work Shift Title">
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.workShiftDetail.title.$error && !$v.workShiftDetail.title.minLength" class="text-danger">Title must have at least {{$v.workShiftDetail.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.workShiftDetail.title.$error && !$v.workShiftDetail.title.required" class="text-danger">Please enter Title</label>
                                                    <label v-if="$v.workShiftDetail.title.$error && !$v.workShiftDetail.title.maxLength" class="text-danger">Title must not exceed {{$v.workShiftDetail.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="workShiftDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="workShiftTypeId">Work Shift Type</label>
                                                    <select v-model="workShiftDetail.workShiftTypeId" disabled class="form-control" id="workShiftTypeId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in workShiftTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label>Daily Work Hour</label>
                                                    <b-input-group key="sm" size="sm" class="mb-3">
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.workHour" min="0" ></b-form-input>
                                                        <b-input-group-append is-text>hours</b-input-group-append>
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.workMinute" min="0"></b-form-input>
                                                        <b-input-group-append is-text>minutes</b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Weekly Work Hour</label>
                                                    <b-input-group key="sm" size="sm" class="mb-3">
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.weeklyWorkHour" min="0" ></b-form-input>
                                                        <b-input-group-append is-text>hours</b-input-group-append>
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.weeklyWorkMinute" min="0"></b-form-input>
                                                        <b-input-group-append is-text>minutes</b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label>Monthly Work Hour</label>
                                                    <b-input-group key="sm" size="sm" class="mb-3">
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.monthlyWorkHour" min="0" ></b-form-input>
                                                        <b-input-group-append is-text>hours</b-input-group-append>
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.monthlyWorkMinute" min="0"></b-form-input>
                                                        <b-input-group-append is-text>minutes</b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="weekdays">Working Days per Week</label>
                                                    <input type="number" :disabled="!elements.editMode" class="form-control" v-model="workShiftDetail.weekdays" id="weekdays" >
                                                </div>
                                            </div> -->
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label for="workShift">Description</label>
                                                    <textarea v-model="workShiftDetail.description" :disabled="!elements.editMode" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="form-group">
                                                    <label>Weekly Work Hours</label>
                                                    <b-input-group key="sm" size="sm" class="mb-3">
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.weeklyWorkHour" min="0" ></b-form-input>
                                                        <b-input-group-append is-text>hrs</b-input-group-append>
                                                        <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.weeklyWorkMinute" min="0"></b-form-input>
                                                        <b-input-group-append is-text>mins</b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="weekdays">Week Days</label>
                                                    <input type="number" :disabled="!elements.editMode" class="form-control" v-model="workShiftDetail.weekdays" id="weekdays" >
                                                </div>
                                            </div>
                                        </div>                                        
                                        <b-card v-if="getWorkShiftTypeLookupIdDet" no-body class="mb-2">
                                            <b-card-header header-tag="header" class="p-0">
                                                <label class="d-block px-2 pt-2 pb-1 my-0 accordion-title" block>
                                                    Work Schedule
                                                    <span class="float-right">
                                                        <a href="javascript:" title="Edit" @click="" v-b-modal.workPatternEditPopup><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                                    </span>
                                                </label>
                                                <div class="separator mx-2"></div>
                                            </b-card-header>
                                            <b-card-body class="pb-0 pt-3 px-3">
                                                <template v-if="getWorkShiftTypeLookupIdDet==1 && workPatternDetList.length == 1">
                                                    <div v-for="workPatternData, index in workPatternDetList" :key="index" class="row">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="startTime">Work Time</label>
                                                                <div>{{ formatTimeFromHM(workPatternData.startTime) }} to {{ formatTimeFromHM(workPatternData.endTime) }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label>Daily Work Hour</label>
                                                                <div>
                                                                    {{ workPatternData.workHour ? workPatternData.workHour + ' hrs' : '' }}
                                                                    <span v-if="workPatternData.workHour && workPatternData.workMinute"> </span>
                                                                    {{ workPatternData.workMinute ? workPatternData.workMinute + ' mins' : '' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else-if="getWorkShiftTypeLookupIdDet==2">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <b-form-group label="Working Days" v-slot="{ ariaDescribedby }">
                                                                  <b-form-checkbox-group
                                                                    id="checkbox-group-2"
                                                                    v-model="selectedDaysDet"
                                                                    :aria-describedby="ariaDescribedby"
                                                                    name="weekdays" disabled
                                                                  >
                                                                    <b-form-checkbox v-for="day,index in days" :key="index" :value="index" @change="addRemoveWorkSchedule(index, $event)">{{ getAcronym(day, 3) }}</b-form-checkbox>
                                                                  </b-form-checkbox-group>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="data-table-rows report-tables">
                                                        <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workPatternDetList" @row-selected="" :fields="workScheduleDetFields" responsive="sm" foot-clone no-footer-sorting >
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(startTime)="data">
                                                                <div v-if="data.value">
                                                                    {{ formatTimeFromHM(data.item.startTime) }} to {{ formatTimeFromHM(data.item.endTime) }}
                                                                </div>
                                                            </template>
                                                            <template v-slot:cell(workHour)="data">
                                                                {{ data.item.workHour ? data.item.workHour + ' hrs' : '' }}
                                                                <span v-if="data.item.workHour && data.item.workMinute"> </span>
                                                                {{ data.item.workMinute ? data.item.workMinute + ' mins' : '' }}
                                                            </template>
                                                            <template v-slot:cell(dayIndex)="data">
                                                                {{ data.item.dayIndex != null ? getAcronym(days[data.item.dayIndex], 3) : '' }}
                                                            </template>
                                                            <!-- <template v-slot:cell(actions)="data">
                                                                <b-input-group key="sm" size="sm" class="mb-3">
                                                                    <b-form-input type="number" :disabled="!elements.editMode" v-model="data.item.workHour" min="0" ></b-form-input>
                                                                    <b-input-group-append is-text>hours</b-input-group-append>
                                                                    <b-form-input type="number" :disabled="!elements.editMode" v-model="workShiftDetail.workMinute" min="0"></b-form-input>
                                                                    <b-input-group-append is-text>minutes</b-input-group-append>
                                                                </b-input-group>
                                                            </template> -->
                                                            <!-- <template v-slot:cell(actions)="data">
                                                                <a href="javascript:" title="Edit" @click=""><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                <a href="javascript:" title="Delete" @click="deleteWorkSchedule(data.item.id)" v-b-modal.deleteWorkSchedule><i class="fa fa-trash pl-2"></i></a>
                                                            </template> -->
                                                            <template  v-slot:foot()="data">
                                                                <span></span>
                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No data found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                </template>
                                            </b-card-body>
                                        </b-card>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='workShiftDetail.createdBy' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="workShiftDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="workShiftDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="workPatternEditPopup" size="lg" modal-class="htmlDetailViewRef" ok-only title="Work Schedule">
            <b-overlay :show="showModalOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div v-if="getWorkShiftTypeLookupIdDet==2" class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <b-form-group label="Working Days" v-slot="{ ariaDescribedby }">
                              <b-form-checkbox-group
                                id="checkbox-group-2"
                                v-model="selectedDaysDet"
                                :aria-describedby="ariaDescribedby"
                                name="weekdays" 
                              >
                                <b-form-checkbox v-for="day,index in days" :key="index" :value="index" @change="addRemoveWorkScheduleDet(index, $event)">{{ day }}</b-form-checkbox>
                              </b-form-checkbox-group>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <div class="data-table-rows report-tables">
                    <b-table class="mb-0" head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workPatternDetList" @row-selected="" :fields="getWorkShiftTypeLookupIdDet==2 ? workScheduleFieldsVariable : workScheduleFieldsFixed" responsive="sm" foot-clone no-footer-sorting >
                        <template v-slot:cell(dayIndex)="data">
                            {{ data.item.dayIndex != null ? getAcronym(days[data.item.dayIndex], 3) : '' }}
                        </template>
                        <template v-slot:cell(startTime)="data">
                            <div v-if="elements.wpEditMode && editId == data.item.id" class="d-flex justify-content-between">
                                <input type="time" class="form-control" v-model="workPatternDetail.startTime" @change="setTimeDiff(workPatternDetail, workPatternDetail.startTime, workPatternDetail.endTime)">
                            </div>
                            <div v-else-if="data.value">
                                {{ formatTimeFromHM(data.item.startTime) }}
                            </div>
                        </template>
                        <template v-slot:cell(endTime)="data">
                            <div v-if="elements.wpEditMode && editId == data.item.id" class="d-flex justify-content-between">
                                <input type="time" class="form-control" v-model="workPatternDetail.endTime" @change="setTimeDiff(workPatternDetail, workPatternDetail.startTime, workPatternDetail.endTime)">
                            </div>
                            <div v-else-if="data.value">
                                {{ formatTimeFromHM(data.item.endTime) }}
                            </div>
                        </template>
                        <template v-slot:cell(workHour)="data">
                            <b-input-group v-if="elements.wpEditMode && editId == data.item.id" key="sm" size="sm">
                                <b-form-input type="number" v-model.number="workPatternDetail.workHour" min="0" ></b-form-input>
                                <b-input-group-append is-text>hours</b-input-group-append>
                                <b-form-input type="number" v-model.number="workPatternDetail.workMinute" min="0"></b-form-input>
                                <b-input-group-append is-text>minutes</b-input-group-append>
                            </b-input-group>
                            <template v-else>
                                {{ data.item.workHour ? data.item.workHour + ' hrs' : '' }}
                                <span v-if="data.item.workHour && data.item.workMinute"> </span>
                                {{ data.item.workMinute ? data.item.workMinute + ' mins' : '' }}
                            </template>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <template v-if="elements.wpEditMode && editId == data.item.id">
                                <a href="javascript:" title="Save" @click="updateWorkSchedule"><i class="fa fa-check"></i></a>
                                <a href="javascript:" title="Cancel" @click="elements.wpEditMode=false;editId='';"><i class="fa fa-times pl-2"></i></a>
                            </template>
                            <template v-else>
                                <a href="javascript:" title="Edit" @click="showWorkScheduleEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                            </template>
                        </template>
                        <template  v-slot:foot()="data">
                            <span></span>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No data found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="deleteWorkShift" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Work Shift?" />
        <DeleteComponent id="deleteWorkSchedule" :onYes="onYesWorkSchedule" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Work Schedule?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import dayList from '@/json/daysofweek.json';
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                wpEditMode: false,
                visible:false
            },
            showOverlay: false,
            showDetOverlay: false,
            showModalOverlay: false,
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'work_shift',

            editId: '',

            mainTableArray: [],
            days: dayList,
            selectedDays: [],
            selectedDaysDet: [],

            workShift: {
                id: '',
                workShiftId: '', 
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                title: '',
                workShiftTypeId: '',
                weekdays: '',
                weeklyWorkMinute: '',
                weeklyWorkHour: '',
                monthlyWorkMinute: '',
                monthlyWorkHour: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                workPatternList: [],
            },
            workShiftDetail: {
                id: '',
                workShiftId: '', 
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                title: '',
                workShiftTypeId: '',
                weekdays: '',
                weeklyWorkMinute: '',
                weeklyWorkHour: '',
                monthlyWorkMinute: '',
                monthlyWorkHour: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                workPatternList: [],
            },
            workPatternDetail: {
                id: '',
                workPatternId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                title: '',
                workTypeId: '',
                startTime: '',
                endTime: '',
                workHour: '',
                workMinute: '',
                weekdays: '',
                dayIndex: '',
                weeklyWorkMinute: '',
                weeklyWorkHour: '',
                monthlyWorkMinute: '',
                monthlyWorkHour: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },
            weekendSetting: null,
            workShiftTypes: [],

            workScheduleList: [],

            workShiftList: [],
            workShiftFields: [
                // {key: 'index',label: 'SN'},
                // { key: 'workPatternId', label: 'Schedule Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Work Shift Title', sortable: true, sortDirection: 'desc' },
                { key: 'workShiftType', label: 'Work Shift Type', sortable: true, sortDirection: 'desc' },
                // { key: 'workHour', label: 'Duration', sortable: true, sortDirection: 'desc' },
                { key: 'weeklyWorkHour', label: 'Weekly Work Hours', sortable: true, sortDirection: 'desc' },
                { key: 'weekdays', label: 'Weekdays', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            workPatternDetList: [],
            workScheduleDetFields: [
                // {key: 'index',label: 'SN'},
                { key: 'dayIndex', label: 'Day', sortable: true, sortDirection: 'desc' },
                { key: 'startTime', label: 'Work Timing', sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Work Hour', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' } 
            ],

            workScheduleFieldsVariable: [
                { key: 'dayIndex', label: 'Day', tdClass: 'table-cw-100 align-middle font-weight-bold', sortable: true, sortDirection: 'desc' },
                { key: 'startTime', label: 'Start Time', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'endTime', label: 'End Time', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Work Hour', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions', tdClass: 'align-middle', } 
            ],
            
            workScheduleFieldsFixed: [
                { key: 'startTime', label: 'Start Time', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'endTime', label: 'End Time', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Work Hour', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions', tdClass: 'align-middle', } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getWorkShifts();
        this.lookupData();
    },
    validations: {
        workShift: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            workShiftTypeId: {
                required,
            },
        },
        workShiftDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            workShiftTypeId: {
                required,
            },
        },
        workPatternDetail: {
            startTime: {
                required,
            },
            endTime: {
                required,
            },
        }
    },
    computed: {
        getWorkShiftTypeLookupId() {
            let result = null;
            if (this.workShift.workShiftTypeId) {
                const item = this.workShiftTypes.find(data => data.id == this.workShift.workShiftTypeId);
                result = item ? item.typeLookupId : null; 
            }
            return result;
        },
        getWorkShiftTypeLookupIdDet() {
            let result = null;
            if (this.workShiftDetail.workShiftTypeId) {
                const item = this.workShiftTypes.find(data => data.id == this.workShiftDetail.workShiftTypeId);
                result = item ? item.typeLookupId : null; 
            }
            return result;
        },
    },
    methods: {
        lookupData: function() {
            let data = {
                entity: 'work_shift_type',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/type' , data) 
            .then((response) => {
                let result = response.data;
                this.workShiftTypes = result.typeLookup;
            })
        },
        getWorkShifts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/workshift/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.workShiftList = result.workShiftList;
                    this.totalRows = this.workShiftList.length;
                    if (this.workShiftList && this.workShiftList.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.workShiftDetail.id, this.mainTableArray, 'workPatternTable');
                        })
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewWorkSchedule: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/workshift/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let workShiftDetail = result.workShift;
                    if (workShiftDetail) {
                        this.workShiftDetail.id = workShiftDetail.id;
                        this.workShiftDetail.workShiftId = workShiftDetail.workShiftId;
                        this.workShiftDetail.vhextAccountId = workShiftDetail.vhextAccountId;
                        this.workShiftDetail.title = workShiftDetail.title;
                        this.workShiftDetail.workShiftTypeId = workShiftDetail.workShiftTypeId;
                        this.workShiftDetail.weekdays = workShiftDetail.weekdays;
                        this.workShiftDetail.weeklyWorkMinute = workShiftDetail.weeklyWorkMinute;
                        this.workShiftDetail.weeklyWorkHour = workShiftDetail.weeklyWorkHour;
                        this.workShiftDetail.monthlyWorkMinute = workShiftDetail.monthlyWorkMinute;
                        this.workShiftDetail.monthlyWorkHour = workShiftDetail.monthlyWorkHour;
                        this.workShiftDetail.active = workShiftDetail.active;
                        this.workShiftDetail.createdById = workShiftDetail.createdById;
                        this.workShiftDetail.createdDt = workShiftDetail.createdDt;
                        this.workShiftDetail.lastUpdatedDt = workShiftDetail.lastUpdatedDt;

                        this.getWorkScheduleList();
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        getWorkScheduleList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                workShiftId: this.workShiftDetail.id,
            }
            this.showDetOverlay = true;
            this.showModalOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/workpattern/list',data)
                .then((response) => {
                    this.showDetOverlay = false;
                    this.showModalOverlay = false;
                    let result = response.data;
                    this.workPatternDetList = result.workPatternList;

                    this.selectedDaysDet = this.workPatternDetList.map(item => item.dayIndex);
                    console.log(this.selectedDaysDet);
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    this.showModalOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        addWorkShift: function() {
            this.$v.workShift.$touch();
            if (!this.$v.workShift.$invalid) {
                this.showOverlay = true;
                this.workShift.workPatternList = this.workScheduleList.map(data => {
                    data.startTime = this.changeTimeFormat(data.startTime);
                    data.endTime = this.changeTimeFormat(data.endTime)
                    return data;
                })
                // this.workShift.startTime = this.changeTimeFormat(this.workShift.startTime);
                // this.workShift.endTime = this.changeTimeFormat(this.workShift.endTime);
                axios.post(this.$store.getters.getAPIBasePath + '/resource/workshift/add', this.workShift)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getWorkShifts();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateWorkShift: function() {
            this.$v.workShiftDetail.$touch();
            if (!this.$v.workShiftDetail.$invalid) {
                // this.workShiftDetail.workPatternList = this.workPatternDetList.map(data => {
                //     data.startTime = this.changeTimeFormat(data.startTime);
                //     data.endTime = this.changeTimeFormat(data.endTime)
                //     return data;
                // })
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/workshift/update', this.workShiftDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getWorkShifts();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },

        updateWorkSchedule: function() {
            this.$v.workPatternDetail.$touch();
            if (!this.$v.workPatternDetail.$invalid) {
                this.workPatternDetail.startTime = this.changeTimeFormat(this.workPatternDetail.startTime);
                this.workPatternDetail.endTime = this.changeTimeFormat(this.workPatternDetail.endTime);
                this.showModalOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/workpattern/update', this.workPatternDetail)
                    .then((response) => {
                        this.showModalOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.elements.wpEditMode = false;
                            this.editId = '';
                            this.getWorkScheduleList();
                        }
                    })
                    .catch(error => {
                        this.showModalOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        handleWorkShiftTypeChange(index, selectedVal) {

            this.workScheduleList = [];
            if(this.getWorkShiftTypeLookupId == 1) {
                // add a default data to array
                this.addRemoveWorkSchedule(0, 0);
            }
        },
        removeWorkSchedule(index) {
            this.selectedDays = this.selectedDays.filter(data => data != index);
            this.addRemoveWorkSchedule(index, null);
        },
        addRemoveWorkSchedule(index, selectedVal) {
            if(selectedVal == null) {
                // remove
                this.workScheduleList = this.workScheduleList.filter(data => this.days[data.dayIndex] != this.days[index]);
            }
            else {
                const lastItemIndex = this.workScheduleList.length - 1;
                const lastItem = (lastItemIndex >= 0) ? this.workScheduleList[lastItemIndex] : null;
                // add
                this.workScheduleList.push({
                    startTime: lastItem ? lastItem.startTime : this.getTimeValue(new Date()),
                    endTime: lastItem ? lastItem.endTime : this.getTimeValue(new Date()),
                    workHour: lastItem ? lastItem.workHour : 0,
                    workMinute: lastItem ? lastItem.workMinute : 0,
                    // day: this.getWorkShiftTypeLookupId == 2 ? this.days[index] : '',
                    dayIndex: this.getWorkShiftTypeLookupId == 2 ? index : '',
                });
            }
            this.workShift.weekdays = this.getWorkShiftTypeLookupId == 2 ? this.workScheduleList.length : '';
            this.setWeeklyTime();
            // }
        },
        addRemoveWorkScheduleDet(index, selectedVal) {
            if(selectedVal == null) {
                // delete
                let itemId = null;
                let workPatternData = this.workPatternDetList.find(data => data.dayIndex == index);

                itemId = workPatternData?.id;

                if (workPatternData && itemId) {
                    this.$bvModal.show('deleteWorkSchedule');
                    this.deleteWorkSchedule(itemId);
                }
            }
            else {
                const lastItemIndex = this.workPatternDetList.length - 1;
                const lastItem = (lastItemIndex >= 0) ? this.workPatternDetList[lastItemIndex] : null;
                // add
                this.workPatternDetail.workShiftId = this.workShiftDetail.id;
                this.workPatternDetail.startTime = lastItem ? lastItem.startTime : this.getTimeValue(new Date());
                this.workPatternDetail.endTime = lastItem ? lastItem.endTime : this.getTimeValue(new Date());
                this.workPatternDetail.workHour = lastItem ? lastItem.workHour : 0;
                this.workPatternDetail.workMinute = lastItem ? lastItem.workMinute : 0;
                // this.workPatternDetail.day = this.getWorkShiftTypeLookupIdDet == 2 ? this.days[index] : '';
                this.workPatternDetail.dayIndex = this.getWorkShiftTypeLookupIdDet == 2 ? index : '';

                this.addWorkSchedule();
            }

            this.getWorkScheduleList();
        },
        addWorkSchedule: function() {
            this.showModalOverlay = true;

            this.workPatternDetail.startTime = this.changeTimeFormat(this.workPatternDetail.startTime);
            this.workPatternDetail.endTime = this.changeTimeFormat(this.workPatternDetail.endTime);

            axios.post(this.$store.getters.getAPIBasePath + '/finance/workpattern/add', this.workPatternDetail)
            .then((response) => {
                this.showModalOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                    this.getWorkScheduleList();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        cancelEditMode: function() {
            this.viewWorkSchedule(this.workShiftDetail.id);
        },
        deleteWorkShift: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/workshift/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getWorkShifts();
                        this.elements.showDetail = false;
                        this.$refs.workPatternTable.clearSelected();
                    }
                    else if (result.status == "CONFLICT") {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                });
        },
        deleteWorkSchedule: function(id) {
            this.deleteParam.id = id;
        },
        onYesWorkSchedule: function() {
            this.showDetOverlay = true;
            this.showModalOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/workpattern/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    this.showModalOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topCenter'
                        });
                        this.getWorkScheduleList();
                    }
                    else if (result.status == "CONFLICT") {
                        iziToast.info({
                            message: result.message,
                            position: 'topCenter'
                        });
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    this.showModalOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.workShift.id = '';
            this.workShift.workShiftId = '';
            this.workShift.title = '';
            this.workShift.workShiftTypeId = '';
            this.workShift.weekdays = '';
            this.workShift.weeklyWorkMinute = '';
            this.workShift.weeklyWorkHour = '';
            this.workShift.monthlyWorkMinute =  '';
            this.workShift.monthlyWorkHour =  '';
            this.workShift.active =  true;
            this.workShift.createdById = localStorage.getItem("userId");
            this.workShift.workPatternList = [];
            this.selectedDays = [];
            this.$v.workShift.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.showDetail = true;
            this.workShiftDetail.id = data.id;
            this.workShiftDetail.workShiftId = data.workShiftId;
            this.workShiftDetail.vhextAccountId = data.vhextAccountId;
            this.workShiftDetail.title = data.title;
            this.workShiftDetail.workShiftTypeId = data.workShiftTypeId;
            this.workShiftDetail.weekdays = data.weekdays;
            this.workShiftDetail.weeklyWorkMinute = data.weeklyWorkMinute;
            this.workShiftDetail.weeklyWorkHour = data.weeklyWorkHour;
            this.workShiftDetail.monthlyWorkMinute = data.monthlyWorkMinute;
            this.workShiftDetail.monthlyWorkHour = data.monthlyWorkHour;
            this.workShiftDetail.active = data.active;
            this.workShiftDetail.createdById = data.createdById;
            this.workShiftDetail.createdDt = data.createdDt;
            this.workShiftDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.elements.visible = false;
                this.workShiftDetail.id = data.id;
                this.workShiftDetail.workShiftId = data.workShiftId;
                this.workShiftDetail.vhextAccountId = data.vhextAccountId;
                this.workShiftDetail.title = data.title;
                this.workShiftDetail.workShiftTypeId = data.workShiftTypeId;
                this.workShiftDetail.weekdays = data.weekdays;
                this.workShiftDetail.weeklyWorkMinute = data.weeklyWorkMinute;
                this.workShiftDetail.weeklyWorkHour = data.weeklyWorkHour;
                this.workShiftDetail.monthlyWorkMinute = data.monthlyWorkMinute;
                this.workShiftDetail.monthlyWorkHour = data.monthlyWorkHour;
                this.workShiftDetail.active = data.active;
                this.workShiftDetail.createdById = data.createdById;
                this.workShiftDetail.createdDt = data.createdDt;
                this.workShiftDetail.lastUpdatedDt = data.lastUpdatedDt;

                this.getWorkScheduleList();
            }
        },
        showWorkScheduleEdit(data) {
            this.elements.wpEditMode = true;
            this.editId = data.id;
            this.workPatternDetail.id = data.id;
            this.workPatternDetail.workPatternId = data.workPatternId;
            this.workPatternDetail.vhextAccountId = data.vhextAccountId;
            this.workPatternDetail.title = data.title;
            this.workPatternDetail.workTypeId = data.workTypeId;
            this.workPatternDetail.startTime = data.startTime;
            this.workPatternDetail.endTime = data.endTime;
            this.workPatternDetail.workHour = data.workHour;
            this.workPatternDetail.workMinute = data.workMinute;
            this.workPatternDetail.weekdays = data.weekdays;
            this.workPatternDetail.dayIndex = data.dayIndex;
            this.workPatternDetail.weeklyWorkMinute = data.weeklyWorkMinute;
            this.workPatternDetail.weeklyWorkHour = data.weeklyWorkHour;
            this.workPatternDetail.monthlyWorkMinute = data.monthlyWorkMinute;
            this.workPatternDetail.monthlyWorkHour = data.monthlyWorkHour;
            this.workPatternDetail.active = data.active;
            this.workPatternDetail.createdById = data.createdById;
            this.workPatternDetail.createdDt = data.createdDt;
            this.workPatternDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.workShift.entity = this.entity;
                this.workShift.id = item.id;
                this.workShift.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.workShift.entity = this.entity;
                this.workShift.id = item.id;
                this.workShift.active = 1;

                this.updateActivationState();
            }
            console.log(this.workShift.id, this.workShift.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.workShift)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    // this.elements.showDetail = false;
                    this.workShift.active = true;
                    this.getWorkShifts();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        setTimeDiff(item, start, end) {
            if (start && end) {
                const startDate = new Date('2000-01-01T' + start);
                const endDate = new Date('2000-01-01T' + end);

                let difference = this.getDateDifference(startDate, endDate);

                item.workHour = difference.hours;
                item.workMinute = difference.minutes;
            }
            this.setWeeklyTime();
        },
        setWeeklyTime() {
            const arr = this.workScheduleList.length > 0 ? [...this.workScheduleList] : [];

            let weeklyWorkHour = arr.reduce((a, b) => {
                return a + (b.workHour ?? 0);
            }, 0);
            let weeklyWorkMinute = arr.reduce((a, b) => {
                return a + (b.workMinute ?? 0);
            }, 0);

            const totalWeeklyWorkMinutes = (weeklyWorkHour * 60) + weeklyWorkMinute;

            this.workShift.weeklyWorkHour = Math.floor(totalWeeklyWorkMinutes/60);
            this.workShift.weeklyWorkMinute = Math.round(totalWeeklyWorkMinutes % 60);
        },
    }
}
</script>
