<template>
    <div>
        <b-modal ref="empPayrollAutoGenPopupRef" size="md" id="empPayrollAutoGenPopup" @show="clearData" :title="empPayrollAutoGenPopupTitle + ' [' + payrollPeriod + ']'" :no-close-on-backdrop="true">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="12 mb-3 text-center fs-16">
                            <b-form-group class="badge badge-light"  label="" v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    id="share-group"
                                    @change="handleChange"
                                    v-model="payrollAutoGenData.isSelectedEmp"
                                    :options="payrollAutoGenOptions"
                                    :aria-describedby="ariaDescribedby"
                                    button-variant="outline-primary"
                                    name="share-radio-options"
                                ></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row v-if="elements.showEmployeeList" class="mb-2">
                        <b-col cols="3" class="pr-0 my-auto">Employees</b-col>
                        <b-col cols="9">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.payrollAutoGenData.employeeDataList.$error }">
                                <multiselect v-model="payrollAutoGenData.employeeDataList" :options="employeeLookup" name="employeeLookup" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" placeholder="Select Employees" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.payrollAutoGenData.employeeDataList.$error" class="invalid-feedback">
                                <span v-if="!$v.payrollAutoGenData.employeeDataList.checkNull" class="text-danger">Please select Employee</span>
                            </div>
                        </b-col>
                    </b-row>
                    <!-- <b-row class="mb-1">
                        <b-col cols="3" class="pr-0 my-auto">Payroll Config</b-col>
                        <b-col cols="9">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.payrollAutoGenData.payslipConfigId.$error }">
                                <select v-model="payrollAutoGenData.payslipConfigId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in payslipConfigLookup" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.payrollAutoGenData.payslipConfigId.$error" class="invalid-feedback">
                                <span v-if="$v.payrollAutoGenData.payslipConfigId.$error && !$v.payrollAutoGenData.payslipConfigId.required" class="text-danger">Please select Employee</span>
                            </div>
                        </b-col>
                    </b-row> -->
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="generatePayslip" class="float-right py-1 px-3 mr-2">Generate Payslip</b-button>
                    <b-button variant="secondary" :disabled="showOverlay" size="sm" class="float-right py-1 px-3 mr-2" @click="cancel()">Cancel</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    props: {
        masterData: Object,
        empPayrollAutoGenPopupTitle: {
            type: String,
            default: 'Payroll Auto Generation'
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showEmployeeList: true,
                showDepartment: false,
                showIndividual: true,
                showClient: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            employeeLookup: [],
            payslipConfigLookup:[],

            payrollAutoGenData: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: '',
                payrollPeriod: '',
                employeeDataList: [],
                payslipConfigId:'',
                createdById:'',
                isSelectedEmp: true,
            },
            payrollAutoGenOptions: [
                { text: 'Selected Employees', value: true },
                { text: 'All Employees', value: false },
            ],
        }
    },
    validations: {
        payrollAutoGenData: {
            employeeDataList: {
                checkNull: function(employeeDataList) {
                    return this.payrollAutoGenData.isSelectedEmp==false || employeeDataList.length > 0
                },               
            },
        }
    },
    computed: {
        getpayslipConfigId() {
            if (this.payrollAutoGenData.payslipConfigId) {
                return this.payrollAutoGenData.payslipConfigId;
            }
            let payslipConfig = null;
            if (this.payslipConfigLookup && this.payslipConfigLookup.length>0) {
                payslipConfig = this.payslipConfigLookup.find(data => data.isDefault);
                payslipConfig = payslipConfig ? payslipConfig : this.payslipConfigLookup[0];
            }
            return payslipConfig.id
        },
        payrollPeriod() {
            return this.getMonthYearAlt(this.payrollAutoGenData.payrollPeriod);
        },
    },
    mounted() {
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipautogen/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.employeeLookup = result.employeeLookup;
                    this.payslipConfigLookup = result.payslipConfigLookup;
                    // this.payrollAutoGenData.payslipConfigId = this.getpayslipConfigId ? this.getpayslipConfigId : '';
                })
        },
        generatePayslip: function() {
            this.$v.payrollAutoGenData.$touch();
            if (!this.$v.payrollAutoGenData.$invalid) {
                console.log(this.payrollAutoGenData)
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/employee/payroll/autogen', this.payrollAutoGenData)
                    .then((response) => {
                        this.showOverlay = false;
                        console.log(response.data.status);
                        if (response.data) {
                            this.$bvModal.hide('empPayrollAutoGenPopup');
                            this.$emit('refreshPayrollAutoGen', this.payrollAutoGenData.entityRecordMasterId);
                            iziToast.success({
                                title: 'Success',
                                message: 'Payroll Generated',
                                position: 'topCenter'
                            });
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topCenter'
                       });
                    })
            }
        },
        handleChange(value) {
            this.elements.showEmployeeList = !this.elements.showEmployeeList;
            // switch (value) {
            //     case true:
            //         this.elements.showEmployeeList = false;
            //         break;
            //     default:
            //         this.elements.showEmployeeList = true;
            //         break;
            // }
        },
        clearData: function() {
            this.payrollAutoGenData.employeeDataList = [];
            this.$v.payrollAutoGenData.employeeDataList.$reset();
        },
        onRefDataChange: function() {
            this.payrollAutoGenData.vhextAccountId = this.vhextAccountId;
            this.payrollAutoGenData.createdById = localStorage.getItem("userId");

            // this.ermId = this.masterData.refErmId;  
            this.payrollAutoGenData.entityRecordMasterId = this.masterData.refErmId;                      
            this.payrollAutoGenData.payrollPeriod = this.masterData.startDate;
            // this.payrollAutoGenData.shareMessage = this.masterData.title;
            // this.payrollAutoGenData.entity = this.masterData.refEntity;
        },

    }  
}
</script>