import Landing from '@/views/landing/Landing';
import Home from '@/views/home/Home.vue';

import fnmroutes from './fnm/fnmroutes';
import adminsettingroutes from './adminsettingroutes';
import assetroutes from './assetroutes';
import clientroutes from './client/clientroutes';
import communicationroutes from './communicationroutes';
import companyroutes from './companyroutes';
import cmrroutes from './company/cmrroutes';
import crproutes from './corporate/crproutes';
import designroutes from './designroutes';
import developmentroutes from './developmentroutes';
import financeroutes from './financeroutes';
import hrmroutes from './hrm/hrmroutes';
import landingroutes from './landingroutes';
import landingreportroutes from './landingreportroutes';
import manageroutes from './manageroutes';
import menuroutes from './menuroutes';
import misreportroutes from './misreportroutes';
import mrproutes from './mrp/mrproutes';
import payrollroutes from './payrollroutes';
import planroutes from './planroutes';
import processroutes from './processroutes';
import projectroutes from './projectroutes';
import prmroutes from './prm/prmroutes';
import publicroutes from './publicroutes';
import qualityroutes from './qualityroutes';
import reportroutes from './reportroutes';
import requirementroutes from './requirementroutes';
import resourceroutes from './resourceroutes';
import riskroutes from './riskroutes';
import scoperoutes from './scoperoutes';
import srvfinanceroutes from './service/financeroutes';
import serviceroutes from './serviceroutes';
import settingroutes from './settingroutes';
import shlroutes from './shl/shlroutes';
import contractroutes from './contract/contractroutes';
import testroutes from './testroutes';
import tmbroutes from './tmb/tmbroutes';
import closureroutes from './closureroutes';
import controlroutes from './controlroutes';
import vsaroutes from './vsa/vsaroutes';
import userroutes from './userroutes';

const baseroutes = [{
    path: '/',
    name: 'home',
    component: Home,
    meta: { layout: 'login' }
}, {
    path: '/landing',
    name: 'landing',
    component: Landing,
    meta: { layout: 'landing', accessedby: 'CLI|EMP|TMB' }
}];


const routes = baseroutes.concat(fnmroutes, adminsettingroutes, assetroutes, clientroutes, closureroutes, cmrroutes, crproutes, controlroutes,
    communicationroutes, companyroutes, designroutes, developmentroutes, financeroutes, hrmroutes, landingroutes, landingreportroutes, 
    manageroutes,menuroutes, misreportroutes,mrproutes,payrollroutes,planroutes,
    processroutes, projectroutes, prmroutes, publicroutes, qualityroutes, reportroutes, requirementroutes,
    resourceroutes, riskroutes, scoperoutes, srvfinanceroutes, serviceroutes, settingroutes, shlroutes, contractroutes,
    testroutes, tmbroutes, vsaroutes, userroutes);

export default routes

export const getRouteByName = (name) => {
    let route = null
    Object.keys(routes).some(path => {
        if (routes[path].name === name) {
            route = routes[path]
            return true
        }
    })
    return route
}