import ClientProfile from '@/views/user/ClientProfile.vue'
import PMRProfile from '@/views/user/PRMProfile.vue'
import UserActivityLog from '@/views/resource/UserActivityLog.vue';
import UserManagement from '@/views/user/User.vue';
import UserModuleMenu from '@/views/company/UserModuleMenu.vue';
import UserPrivileges from '@/views/user/UserPrivileges.vue';
import UserRole from '@/views/user/UserRole.vue';
import UserType from '@/views/user/UserType.vue';
import UserTypeModule from '@/views/user/UserTypeModule.vue';

import VhextAccountProfile from '@/views/user/VhextAccountProfile.vue'


export default [{
        path: '/clientprofile',
        name: 'clientprofile',
        component: ClientProfile,
        meta: { layout: 'service', accessedby: 'CLI' }
    },
    {
        path: '/prmprofile',
        name: 'prmprofile',
        component: PMRProfile,
        meta: { accessedby: 'CRM|CAM|PRM|CLI|EMP|TMB|VSA' }
    },
    {
        path: '/landing/admset/useractivitylog',
        name: 'admsetuseractivitylog',
        component: UserActivityLog,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VHA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/user',
        name: 'admsetuser',
        component: UserManagement,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VHA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/usrmdlmenu',
        name: 'admsetusrmdlmenu',
        component: UserModuleMenu,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VHA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/userprivilege',
        name: 'userprivilege',
        component: UserPrivileges,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|VHA|VSA|HRM' }
    },
    {
        path: '/landing/admset/usrrol',
        name: 'admsetusrrol',
        component: UserRole,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VHA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/usrtyp',
        name: 'admsetusrtyp',
        component: UserType,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VHA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/utymdl',
        name: 'admsetutymdl',
        component: UserTypeModule,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VHA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/vhextprofile',
        name: 'vhextprofile',
        component: VhextAccountProfile,
        meta: { accessedby: 'CRM|CAM|PRM|CLI|EMP|TMB|VSA' }
    },
];