<template>
    <div>
        <ReleaseListMasterList :ermMasterInfo="ermMasterInfo"></ReleaseListMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ReleaseListMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ReleaseListMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Release List",
                entity: 'release_list_master',
                refEntity: 'release_list',
                tooltip: 'New Release List',
                redirectURLName: 'releaselist',
                newEntityMasterURL: 'rllmas',
                redirectURL: '/rllmas',
                actionName: 'Release List',
                previewURLName: 'rllrep'

            }
        }
    },
    mounted() {},
    methods: {}
}
</script>