<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Test Result</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="exportTestResult()"><i class="fa fa-file-excel-o pr-2 green-color" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <!--<div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1> 
                                            <span>View</span>
                                        </label>
                                    </b-card-header>
                                     <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div v-if="elements.viewMode" id="viewStatement">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <label for="title">Title</label>
                                                        <b-input-group  class="mb-2">
                                                            <b-form-input aria-label="title" disabled v-model="testResult.title"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="preparedUserId">Prepared User</label>
                                                            <input type="text" class="form-control" id="preparedUserId" disabled v-model="testResult.userName">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="testCaseTypeId">Test Type</label>
                                                            <input type="text" class="form-control" id="testCaseTypeId" disabled v-model="testResult.type">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <label for="testPlanId">Test Plan</label>
                                                        <b-input-group :prepend="testResult.testPlanId" class="mb-2">
                                                            <b-form-input aria-label="First name" disabled v-model="testResult.testPlanId"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <label for="preparedDt">prepared Date</label>
                                                        <div class="input-group date disabled-date" disabled>
                                                            <input type="text" class="form-control disabled" :value="testResult.preparedDt | formatDate" readonly>
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-12">
                                                        <div class="form-group">
                                                            <label for="description">Test Description</label>
                                                            <textarea rows="4" class="form-control" id="description" disabled v-model="testResult.description"></textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="executionSteps">Execution Steps</label>
                                                            <textarea class="form-control" rows="4" id="executionSteps" disabled v-model="testResult.executionSteps"></textarea>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="expectedResult">Expected Result</label>
                                                            <textarea class="form-control" rows="4" id="expectedResult" disabled v-model="testResult.expectedResult"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="status">Status</label>
                                                            <input type="text" class="form-control" id="status" disabled v-model="testResult.status">
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="testResult.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="endDate">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="testResult.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse> 
                                </b-card>
                            </div>-->
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                            <span><i class="fa fa-search pr-2 action-link"></i>Filter</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div class="row mb-2">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Test Result Id</label>
                                                        <input type="text" class="form-control" v-model="testResult.testCaseId">
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Name</label>
                                                        <input type="text" maxlength="100" class="form-control" v-model="testResult.title">
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Type</label>
                                                        <select v-model="testResult.testCaseTypeId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in testCaseTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="duration">Status</label>
                                                        <select v-model="testResult.statusId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="getTestResult" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Filter</button>
                                            <button type="submit" @click="resetFilter" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-refresh pr-2"></i>Reset</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div style="overflow: scroll;">
                                <!-- style=" height: 600px;overflow: scroll; -->
                                <b-table show-empty small class="preview-table" head-variant=:primary style="width: 1200px;" selectable :select-mode="selectMode" sort-icon-left sticky-header="1200px" :no-border-collapse="noCollapse" :items="testResults" @row-selected="onRowSelected" :fields="fields" responsive :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                    </template>
                                    <template v-slot:cell(description)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                    </template>
                                    <template v-slot:cell(expectedResult)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                    </template>
                                    <template v-slot:cell(remarks)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Test Case found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                            </div>
                            <b-pagination v-if="testResults.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <StatusTracker ref="statusUpdate" :statusUpdate="statusUpdate" @updateParent="getTestResult"></StatusTracker>
                    </div>
                </div>
            </div>
        </div>
        <TestingDetailPopup v-if="showDialog" ref="testingDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="testingPopupId" @closeDialog="closeDialog" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import Editor from '@tinymce/tinymce-vue';
import StatusTracker from '@/components/common/StatusTracker.vue';
import TestingDetailPopup from '@/components/popup/itemdetail/TestingDetailPopup.vue';
export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        HTMLDetailViewPopup,
        Editor,
        StatusTracker,
        TestingDetailPopup
    },

    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            stickyHeader: true,
            noCollapse: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            typeEntity: 'test_case_type',
            htmlData: '',
            htmlDetailViewTitle: 'Details',

            showDialog: false,

            notEnabled: true,
            statuses: [],
            testCaseTypes: [],

            testResult: {
                testCaseId: '',
                title: '',
                statusId: '',
                entityRecordMasterId: '',
                testCaseTypeId: ''
            },
            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                refEntityId: '',
                refEntity: 'test_result',
                statusById: localStorage.getItem("userId"),
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },

            refPopupTitle: '',
            refPopupEntity: '',
            detailViewData: {
                id: '',
                testCaseId: '',
                title: '',
                description: '',
                expectedResult: '',
                status: '',
                type: '',
                testedBy: '',
                userName: '',
                preparedDt: '',
                testDate: '',
                remarks: ''
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },

            detailViewData: {},
            testingPopupId: 0,

            testResults: [],
            fields: [
                { key: 'testCaseId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Test Description', tdClass: 'table-cw-400', sortable: true, sortDirection: 'desc' },
                { key: 'expectedResult', label: 'Expected Result', tdClass: 'table-cw-400', sortable: true, sortDirection: 'desc' },
                { key: 'remarks', label: 'Tester Remarks', tdClass: 'table-cw-400', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getTestResult();
    },
    /*    validations: {
            testResult: {
                statusId: {
                    required,

                }
            },
        },*/
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'test_result';
            this.userId = localStorage.getItem("userId");
            this.testResult.entityRecordMasterId = this.$route.params.ermId;
        },

        getTestResult: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/test/testresult/list', this.testResult)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.testResults = result.testResultList;
                    if (this.testResults != null && this.testResults.length > 0) {
                        this.totalRows = this.testResults.length;
                        this.assignStatusUpdate(this.testResults[0]);
                        console.log(result);
                    }

                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: 'test_result',
                refEntity:'test_case'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/test/testresult/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.statuses = result.statusLookup;
                    this.testCaseTypes = result.customTypeLookup;

                    console.log(result);
                })
        },
        clearData: function() {
            this.elements.editMode = false;
            //this.elements.visible = false;
            this.elements.viewMode = false;

            this.testResult.title = '';
            this.testResult.testCaseId = '';
            this.testResult.statusId = '';
            this.testResult.testCaseTypeId = '';
            
        },
        showView: function(data) {
            /*  this.elements.visible = true;
              this.elements.editMode = false;
              this.elements.viewMode = true;*/
            this.assignStatusUpdate(data);
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.assignStatusUpdate(data);
                console.log(this.testResult);
            }
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.testingPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.testingDetailPopupRef.getDetails(this.testingPopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        assignStatusUpdate: function(data) {
            this.$refs.statusUpdate.clearData();
            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.refRecordId = data.id;
            this.statusUpdate.refId = data.testCaseId;
            this.statusUpdate.refRecordTitle = data.title;
            this.statusUpdate.statusById = this.userId;
            this.statusUpdate.statusId = data.statusId;
            this.statusUpdate.status = data.status;
            this.statusUpdate.remarks = data.remarks;
            //call child component method
            this.$refs.statusUpdate.onTitleChange();
        },
        resetFilter: function() {
            this.clearData();
            this.getTestResult();
        },
        exportTestResult() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/export/testresult', this.testResult, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = this.ermTitle + "_" +"TestResult" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });
                    //this.reports = [];
                });

        },
        viewReference: function(data) {
            console.log(data);
            this.referencePopupId = data.id;
            this.refPopupTitle = data.title;
            this.refPopupEntity = 'test_result';

            this.detailViewData.id = data.id;
            this.detailViewData.testCaseId = data.testCaseId;
            this.detailViewData.title = data.title;
            this.detailViewData.description = data.description;
            this.detailViewData.expectedResult = data.expectedResult;
            this.detailViewData.status = data.status;
            this.detailViewData.type = data.type;
            this.detailViewData.testedBy = data.testedBy;
            this.detailViewData.userName = data.userName;
            this.detailViewData.preparedDt = data.preparedDt;
            this.detailViewData.testDate = data.testDate;
            this.detailViewData.remarks = data.remarks;
            this.$refs.referenceDetailRef.getRequirementSpecs();
        }
    }
}
</script>