import CalendarEvent from '@/views/communication/CalendarEvent.vue'
import PRMLanding from '@/views/landing/CommonLanding';
import ProjectDashboard from '@/views/dashboard/ProjectDashboard';
import UserTask from '@/views/task/UserTask.vue';
import ActionItem from '@/views/actionitem/ActionItem.vue'

export default [{
        path: '/dashboard',
        name: 'dashboard',
        component: ProjectDashboard,
        meta: { accessedby: 'PRM' }
    },
    {
        path: '/prm/actionitem',
        name: 'prmactionitem',
        component: ActionItem,
        meta: { accessedby: 'PRM' }
    },
    {
        path: '/prm/calevt',
        name: 'prmcalevt',
        component: CalendarEvent,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/prm/dashboard',
        name: 'prmdashboard',
        component: ProjectDashboard,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/prm/landing',
        name: 'prmlanding',
        component: PRMLanding,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/user/task/list',
        name: 'usertask',
        component: UserTask,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },

];