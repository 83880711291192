<template>
    <div>
        <RiskPlanMaster :ermMasterInfo="ermMasterInfo"></RiskPlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import RiskPlanMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        RiskPlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Risk Plan Master",
                entity: 'risk_plan_master',
                refEntity: 'risk_plan',
                ermListRedirectURL: '/rspmas/list',
                erdRedirectURL: '/riskplan/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>