<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Industry</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Industry</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="industry">Industry</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.industry.industry.$error }">
                                                                    <input type="text" v-model="industry.industry" class="form-control" id="industry" placeholder="Industry">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                            <label v-if="$v.industry.industry.$error && !$v.industry.industry.required" class="text-danger">Please enter Industry</label>
                                                        	<label v-if="$v.industry.industry.$error && !$v.industry.industry.maxLength" class="text-danger">Industry must not exceed {{$v.industry.industry.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="industry.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
    				                                    <div class="form-group">
    				                                        <label for="code">Code</label>
    				                                        <input type="text" v-model="industry.code" class="form-control" id="code" placeholder="Code" maxlength="10">
    				                                    </div>
    				                                </div>
    				                                <div class="col-6">
    				                                    <div class="form-group">
    				                                        <label for="displayName">Display Name</label>
    				                                        <input type="text" v-model="industry.displayName" class="form-control" id="displayName" placeholder="Display Name" maxlength="45">
    				                                    </div>
    				                                </div>
                                                </div>
                                                <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="industryTable" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="industryList"  @row-selected="onRowSelected" :fields="industryFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            	<template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteIndustry(data.item.id)" data-toggle="modal" data-target="#deleteIndustry"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Industries found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="industryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm" >
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ industryDetail.id }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateIndustry" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="industry">Industry</label>
                                                    <input type="text" class="form-control" id="industry" :disabled="!elements.editMode" v-model='industryDetail.industry'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="industryDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="codeDetail">Code</label>
                                                    <input type="text" class="form-control" id="codeDetail" :disabled="!elements.editMode" v-model='industryDetail.code'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="displayName">Display Name</label>
                                                    <input type="text" class="form-control" id="displayName" :disabled="!elements.editMode" v-model='industryDetail.displayName'>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="industryDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="industryDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteIndustry" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Industry ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entity: 'industry',

            industry: {
                id: '',
                industry: '',
                code: '',
                displayName: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: ''
            
            },
           
            industryDetail: {
                id: '',
                industry: '',
                createdBy: '',
                createdById: '',
                active: '',
                code: '',
                displayName: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            //users: [],
            industryList: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            industryFields: [
            	{key:'index',label: 'SN'},
                { key: 'industry', label: 'Industry', sortable: true, sortDirection: 'desc' },
                { key: 'code', label: 'Code', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [ 10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getIndustry();
    },
    validations: {
        industry: {
            industry: {
                required,
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getIndustry: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/company/industry/list')
                .then((response) => {
                    let result = response.data;
                    if (result.industryList && result.industryList.length > 0) {
                        this.industryList = result.industryList;
                        this.$nextTick(() => {
                            this.$refs.industryTable.selectRow(0);
                        })
                        this.totalRows = this.industryList.length;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        
        saveData: function() {
            this.$v.industry.$touch();
            if (!this.$v.industry.$invalid) {
                this.saveIndustry();
            }
        },
        saveIndustry: function() {
        	this.$v.industry.$touch();
            if (!this.$v.industry.$invalid) {
                this.showOverlay = true;
	            axios.post(this.$store.getters.getAPIBasePath + '/company/industry/add', this.industry)
	                .then((response) => {
                        this.showOverlay = false;
	                    let result = response.data;
	                    if (result.status == "CREATED") {
	                        iziToast.success({
	                            title: 'Success',
	                            message: result.message,
	                            position: 'topRight'
	                        });
	                        this.clearData();
	                        this.getIndustry();
	                    }
	                })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
	        }
        },
        updateIndustry: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/industry/update', this.industryDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getIndustry();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewIndustry: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/company/industry/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let industryDetail = result.industry;
                    if (industryDetail) {                        
                        this.industryDetail.id = industryDetail.id;
                        this.industryDetail.industry = industryDetail.industry;
                        this.industryDetail.code = industryDetail.code;
                        this.industryDetail.displayName = industryDetail.displayName;
                        this.industryDetail.createdBy = industryDetail.createdBy;
                        this.industryDetail.active = industryDetail.active;
                        this.industryDetail.createdDt = industryDetail.createdDt;
                        this.industryDetail.lastUpdatedDt = industryDetail.lastUpdatedDt;
                    }
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewIndustry(this.industryDetail.id);
        },
        deleteIndustry: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/company/industry/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getIndustry();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.industry.industry = '';
            this.industry.active = true;
            this.industry.code = '';
            this.industry.displayName = '';
            this.industry.createdById = localStorage.getItem("userId");

            this.$v.industry.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.visible = false;
            this.elements.editMode = true;
            this.industryDetail.id = data.id;
            this.industryDetail.active = data.active;
            this.industryDetail.industry = data.industry;
            this.industryDetail.code = data.code;
            this.industryDetail.displayName = data.displayName;
            this.industryDetail.createdById = data.createdById;
            this.industryDetail.createdBy = data.createdBy;
            this.industryDetail.id = data.id;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.visible = false;
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.industryDetail.id = data.id;
                this.industryDetail.active = data.active;
                this.industryDetail.industry = data.industry;
                this.industryDetail.code = data.code;
            	this.industryDetail.displayName = data.displayName;
                this.industryDetail.createdBy = data.createdBy;
                this.industryDetail.createdById = data.createdById;
                this.industryDetail.createdDt = data.createdDt;
                this.industryDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.industry.entity = this.entity;
                this.industry.id = item.id;
                this.industry.active = 0;

                this.updateActivationState();

            } else {
                this.industry.entity = this.entity;
                this.industry.id = item.id;
                this.industry.active = 1;

                this.updateActivationState();
            }
            console.log(this.industry.id, this.industry.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.industry)
                .then((response) => {
                    console.log(this.industry.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.industry.active = true;
                    this.getIndustry();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

    }
}
</script>
