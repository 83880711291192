<template>
    <div>
        <TSCMasterList :ermMasterInfo="ermMasterInfo"></TSCMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import TSCMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        TSCMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Test Case List",
                entity: 'test_case_master',
                refEntity: 'test_case',
                tooltip: 'New Test Case',
                redirectURLName: 'testcase',
                newEntityMasterURL: 'tscmas',
                redirectURL: '/tscmas',
                actionName: 'Test Case',
                previewURLName: 'tscrep',
                showTypeLookup:true,
                showAssignment:true,
                previewURLName: 'tscrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>