<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <Task :taskBoard="taskBoard"/>
            </div>
        </div>
    </div>
</template>
<script>
import Task from '@/components/common/TaskComponent.vue';
export default {
    name: 'app',
    components: {
        Task
    },
    data() {
        return {
            taskBoard: {
                isPublic: true,
            }
        }
    },    
}
</script>