<template>
    <div>
        <DesignMasterList :ermMasterInfo="ermMasterInfo"></DesignMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DesignMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DesignMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Design Document Folders",
                entity: 'design_master',
                refEntity: 'design',
                tooltip: 'New Folder',
                redirectURLName: 'designlist',
                newEntityMasterURL: 'dsnmas',
                redirectURL: '/dsnmas',
                actionName: 'Design Doc',
                previewURLName: 'dsnrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>