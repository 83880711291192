import DesignList from '@/views/design/DesignList.vue'
import DesignMaster from '@/views/design/DesignMaster.vue'
import DesignMasterList from '@/views/design/DesignMasterList.vue'

export default [{
        path: '/designlist/:ermId',
        name: 'designlist',
        component: DesignList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/dsnmas',
        name: 'dsnmas',
        component: DesignMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/dsnmas/list',
        name: 'dsnmaslist',
        component: DesignMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }
    ];