<template>
    <div>
        <DevelopmentPlanMasterList :ermMasterInfo="ermMasterInfo"></DevelopmentPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DevelopmentPlanMasterList from '@/components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DevelopmentPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Task Plan List",
                entity: 'development_plan_master',
                refEntity: 'time_sheet',
                tooltip: 'New Task Plan',
                redirectURLName: 'timesheet',
                newEntityMasterURL: 'dvpmas',
                redirectURL: '/dvpmas',
                actionName: 'Time Sheet',
                previewURLName: 'tmsrep'

            }
        }
    },
    mounted() {},
    methods: {}
}
</script>