<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <DocumentMasterList :ermMasterInfo="ermMasterInfo" ></DocumentMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DocumentMasterList from '../../components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DocumentMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Event Groups",
                entity: 'meeting_minute_master',
                refEntity: 'calendar_event',
                tooltip: 'New Event Group',
                redirectURLName: 'lanmeetingminute',
                newEntityMasterURL: 'mmnmas',
                redirectURL: '/mmnmas',
                actionName: 'Events',
                previewURLName: 'lanmnmrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>