<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Project Details</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 pr-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Project Manager</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.userName}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row mb-2">
                                                    <div class="col-12 col-md-3 pr-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Project Type</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.type}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Category</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.category}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Project Model</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.projectAccessType}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Location</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.location}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-md-3 pr-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.startDate | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">End Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.endDate | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-3 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Duration</label>
                                                            </div>
                                                            <div class="col-12 col-md-3 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.durationYears}}</label>
                                                            </div>
                                                            <div class="col-12 col-md-3 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.durationMonths}}</label>
                                                            </div>
                                                            <div class="col-12 col-md-3 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.durationDays}}</label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Budget</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{project.currencySymbol}} {{project.initialBudget}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

<!--                     <div class="card mb-2">
                        <div v-if="ermReviewApprovals != null && ermReviewApprovals.length != 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="ermReviewApprovals" :fields="ermReviewApprovalFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(isApproval)="data">
                                                    <div>
                                                        <p v-if="data.value == false">Review By :</p>
                                                        <p v-else>Approval By :</p>
                                                    </div>
                                                </template>
                                                <template v-slot:cell(status)="data">
                                                    <span v-if="data.item.reviewedById == userId">
                                                        <select v-if="!data.item.isApproval && data.value == '' " v-model="data.item.statusId" class="form-control w-80 float-left">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in filterReviewApproval(reviewStatus,data.item.isApproval)" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <select v-if="data.item.isApproval && data.value == '' " v-model="data.item.statusId" class="form-control w-80 float-left">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in filterReviewApproval(reviewStatus,data.item.isApproval)" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <a v-if="data.value == ''" href="javascript:" title="update status" @click="updateStatusValue(data.item.statusId, data.item.id, data.item.isApproval)" data-toggle="modal" data-target="#confirmUpdateStatus" class="float-right p-2 action-link">
                                                            <i class="fa fa-check pr-2"></i>
                                                        </a>
                                                        <p v-if="data.value != ''">{{data.value}}</p>
                                                    </span>
                                                    <span v-else>
                                                        <p v-if="data.value ==''">Pending</p>
                                                        <p v-else>{{data.value}}</p>
                                                    </span>
                                                </template>
                                                <template v-slot:empty="erm">
                                                    <div class="text-center">No Reviews found</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div v-if="project.description != null && project.description.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Description</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">{{project.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="project.description != null && project.description.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Setting</span></p>
                                <div class="row mb-2">
<!--                                     <div class="col-12 col-md-3 pr-0">
                                        <div class="row">
                                            <div class="col-12 col-md-5 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Location</label>
                                            </div>
                                            <div class="col-12 col-md-7 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{project.location}}</label>
                                            </div>
                                        </div>
                                    </div>                                                     -->
                                    <div class="col-12 col-md-4 pr-0">
                                        <div class="row">
                                            <div class="col-12 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Country</label>
                                            </div>
                                            <div class="col-12 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{project.country}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4">
                                        <div class="row">
                                            <div class="col-12 col-md-5 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Timezone</label>
                                            </div>
                                            <div class="col-12 col-md-7 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{project.timeZone}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 pl-0">
                                        <div class="row">
                                            <div class="col-12 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Currency</label>
                                            </div>
                                            <div class="col-12 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{project.currency}} {{project.currencySymbol}} {{project.currencyCode}}</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="clients != null && clients.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Client</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="clients" :fields="clientFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
<!--                                                 <template v-slot:cell(description)="data">
                                                    <a v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.description)"></a>
                                                </template>
                                                <template v-slot:empty="erm">
                                                    <div class="text-center">No Meeting Minutes Found</div>
                                                </template> -->
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ChatComment :entity="this.entity" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <Dialog id="confirmUpdateStatus" :onYes="updateStatus" title="Update Status" message="Are you sure to update the status?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import ChatComment from '@/components/common/ChatComment.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
export default {
    name: 'app',
    components: {
        ChatComment,
        HTMLDetailViewPopup,
    },

    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entity: 'project',
            project: [],
            attachments: [],
            prependText: '',
            clients: '',

            htmlData: '',
            htmlDetailViewTitle: "Meeting Agenda Item",

            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            isBusy: true,
            //comments: [],
            clients: [],
            clientFields: [
                { key: 'clientId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Client Name' , tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'clientType', label: 'Client Type', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        }
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = 'project';
            this.userId = localStorage.getItem("userId");
            this.showReport();
        },
        showReport: function() {
            this.viewProject();
            this.getClients();
            this.getAttachment();
        },
        viewProject: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.get(this.$store.getters.getAPIBasePath + '/project/view/' + this.projectId)
                .then((response) => {
                    let result = response.data;

                    console.log(result);                    
                    if (result) {
                        this.project = result.projects;
                        this.project.durationDays = (this.project.durationDays == undefined) ? '' : this.project.durationDays + " Days";
                        this.project.durationMonths = (this.project.durationMonths == undefined) ? '' : this.project.durationMonths + " Months";
                        this.project.durationYears = (this.project.durationYears == undefined) ? '' : this.project.durationYears + " Years";
                        this.project.isArchive = (this.project.isArchive == undefined) ? false : this.project.isArchive;
                        this.prependText = this.project.currencyCode;
                    }
                })
        },
        getClients: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/projectclient/list', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.projectClientList;
                }).catch(error => {
                    console.log(error);
                });
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachments = result.attachmentList;
                })
        },
        getMeetingMinutes: function(ermId) {
            let data = {
                entityRecordMasterId: ermId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/list', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.meetingMinuteList;
                    this.totalRows = this.clients.length;
                    console.log(this.clients);
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;
                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;

                        console.log(this.ermId);
                        this.entityRecordMasterCollection(this.ermId);

                        this.getMeetingMinutes(this.ermId);

                        this.getReviewStatusLookup();
                    }

                })
        },
        getReviewStatusLookup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'review'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/review/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    console.log(this.review.isReviewed);
                    this.reviewStatus = result.statusLookup;
                    if (this.review.isReviewed == 1) {
                        this.statuss = this.filterReviewApproval(this.reviewStatus, false);
                    } else if (this.review.isReviewed == 2) {
                        this.statuss = this.filterReviewApproval(this.reviewStatus, true);
                    }
                })
        },
        updateStatus: function() {

            if (this.review.statusId == null || this.review.statusId == '') {
                iziToast.info({
                    message: 'Please select status.',
                    position: 'topRight'
                });
                return;
            }
            console.log(this.review);
            axios.post(this.$store.getters.getAPIBasePath + '/review/update/status', this.review)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    if (result.status == 'ALREADY_REPORTED') {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                        return;
                    }
                    if (this.review.isReviewed == 1) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Reviewed Successfully.',
                            position: 'topRight'
                        });
                    } else if (this.review.isReviewed == 2) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Approved Successfully.',
                            position: 'topRight'
                        });
                    }
                    this.entityRecordMasterCollection(this.ermId);
                })
        },
        updateStatusValue: function(statusId, id, isApproval) {
            this.review.statusId = statusId;
            this.review.id = id;
            this.review.isReviewed = (isApproval ? 2 : 1);
        },
        /*   filterReviewApproval(value) {
               let name = '';
               if (value) {
                   name = 'Approved';
               } else {
                   name = 'Reviewed';
               }
               return this.reviewStatus.filter(function(data, key) {
                   if (data.value == name || data.value == 'Reject') {
                       return data;
                   }
               });
           },*/

        print: function() {
            this.$htmlToPaper('printMe');
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },


    }
}
</script>