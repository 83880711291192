<template>
    <div>
        <DefectDbMasterList :ermMasterInfo="ermMasterInfo"></DefectDbMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DefectDbMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DefectDbMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Defect Db List",
                entity: 'defect_db_master',
                refEntity: 'defect_db',
                tooltip: 'New Defect Db',
                redirectURLName: 'defectdb',
                newEntityMasterURL: 'ddbmas',
                redirectURL: '/ddbmas',
                actionName: 'Defect Db',
                previewURLName: 'ddbrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>