<template>
    <div>
        <DocumentFolderMaster :ermMasterInfo="ermMasterInfo"></DocumentFolderMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DocumentFolderMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        DocumentFolderMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Project Folder",
                entity: 'document_folder_master',
                refEntity: 'document_folder',
                ermListRedirectURL: '/pdcmas/list',
                erdRedirectURL: '/projectdocument/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>
