<template>
    <div>
        <div class="notes-header">
            <h6>Notepad
                <ul class="notes-header-action">
                    <li class="notebox-search"><a href="javascript:" @click="$refs.notpadSearch.focus()" title="Search"><i class="fa fa-search" aria-hidden="true"></i></a></li>
                    <li class="notebox-close"><a href="javascript:" title="Close"><i class="fa fa-times" aria-hidden="true"></i></a></li>
                </ul>
            </h6>
        </div>
        <div class="notes-search">
            <div class="search-box-new">
                <span class="search-box-icon"><i class="fa fa-search" aria-hidden="true"></i></span>
                <input id="searchField" ref="notpadSearch" v-model="search" class="form-control notes-search-box" type="text" placeholder="Search" aria-label="Search">
                <span class="search-box-close-icon"><a href="javascript:" title="Close"><i class="fa fa-times" aria-hidden="true"></i></a></span>
            </div>
        </div>
        <div class="mail-page">
            <div class="mail-page-col">
                <!-- <a href="javascript:" class="notpad-add-btn action link" title="Add a note"><i class="fa fa-plus-circle pr-2 mt-3 action-link"></i>Add Note</a> -->
                <button v-show="tabIndex==0" class="btn sbtn btn-xs btn-outline-info notpad-add-btn px-2" v-b-toggle.accordion-note><i class="fa fa-plus-circle pr-1"></i>Add Note</button>
                <button v-show="tabIndex==1"  class="btn sbtn btn-xs btn-outline-info notpad-add-btn px-2" v-b-toggle.accordion-weblink><i class="fa fa-plus-circle pr-1"></i>Add Weblink</button>
                <b-tabs v-model="tabIndex" lazy>
                    <b-tab title="NotePad">
                        <template #title>
                            Notes <b-badge class="mt-0" variant="primary">{{ notes.length }}</b-badge>
                        </template>
                        <b-card no-body class="">
                            <!-- <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-note>
                                    <span><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Note</span>
                                </label> 
                            </b-card-header> -->
                            <b-collapse v-model="elements.visibleNote" id="accordion-note" accordion="my-accordion-notes"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="row no-gutters">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input  type="text" v-model="notePad.title" class="form-control" id="title1" placeholder="Title">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="false" :init="initObj" v-model="notePad.note"></editor> -->
                                                <VmxEditor v-model="notePad.note" :toolbar="false"></VmxEditor>
                                                <!-- <textarea rows="3" type="text" v-model="notePad.note" class="form-control" id="position1" placeholder="Note"></textarea> -->
                                            </div>
                                        </div>
                                        <div class="col-12 text-right">
                                            <!-- <a href="javascript:" title="Save" @click=""><i class="fa fa-check text-success pr-2" aria-hidden="true"></i></a>
                                            <a href="javascript:" title="Cancel" @click=""><i class="fa fa-times pr-2" aria-hidden="true"></i></a> -->
                                            <button type="submit" @click="saveNotepad" class="btn sbtn btn-primary btn-xs px-2 mr-1"><i class="fa fa-check pr-1"></i>Save</button>
                                            <button type="submit" @click="clearNote" v-b-toggle.accordion-note class="btn sbtn btn-secondary btn-xs px-2"><i class="fa fa-times pr-1"></i>Cancel</button>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div class="note-content-scroll my-2">
                            <template v-for="noteList in notes ">
                                <div class="note" :class="{'selectedNote' : noteList.id == selectedNoteId}" >
                                    <div class="p-2">
                                        <div class="date d-inline-block">
                                            <div v-if="currentDate == new Date(noteList.createdDate).toString().substr(0, 10)" class="email-time pr-0">
                                                {{ noteList.createdDt | formatTimeAmPm }}
                                            </div>
                                            <div v-else class="email-time pr-0">
                                                {{ noteList.lastUpdatedDt | formatDateTimeAmPm }}
                                            </div>
                                        </div>
                                        
                                        <div v-if="(noteList.id == notePadDetail.editId)" class="icons d-inline-block float-right">
                                            <a href="javascript:" title="Save" @click="updateNotePad();"><i class="fa fa-check  pr-2" aria-hidden="true"></i></a>
                                            <a href="javascript:" title="Cancel" @click="cancelUpdate();"><i class="fa fa-times pr-2" aria-hidden="true"></i></a>
                                        </div>
                                        <div v-else class="icons d-inline-block float-right" >
                                            <a href="javascript:" title="Edit" @click="handleNoteClick(noteList)"><i class="fa fa-pencil pr-2" aria-hidden="true"></i></a>
                                            <a href="javascript:void(0)" title="Delete" @click="deleteNotePad(noteList.id)" v-b-modal.deleteNotePad><i class="fa fa-trash pr-0" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                    <div class="markdown-body p-2" v-if="(noteList.id != notePadDetail.editId)">
                                        <h1 id="helloworld">{{ noteList.title }}</h1>
                                        <p v-html="noteList.note" class="html-note-content mb-1"></p>
                                    </div>
                                    <div v-if="(noteList.id == notePadDetail.editId)" class="markdown-body p-2">
                                        <input type="text"  class="form-control" v-model="notePadDetail.title" />
                                        <VmxEditor :toolbar="false" v-model="notePadDetail.note"></VmxEditor>
                                        <!-- <textarea rows="3" class="form-control mt-1"  v-model="notePadDetail.note"></textarea> -->
                                    </div>
                                </div>
                            </template>
                        </div>
                    </b-tab>
                    <b-tab title="WebLink">
                        <template #title>
                            Weblinks <b-badge class="mt-0" variant="primary">{{ webLinks.length }}</b-badge>
                        </template>
                        <b-card no-body class="">
                            <!-- <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-weblink>
                                    <span><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Weblink</span>
                                </label> 
                            </b-card-header> -->
                            <b-collapse v-model="elements.visibleWeblink" id="accordion-weblink" accordion="my-accordion-weblink"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="row no-gutters">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" v-model="notePad.title" class="form-control" id="title" placeholder="Title">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <input type="text" v-model="notePad.webLink" class="form-control" id="WEebLink" placeholder="WebLink">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <VmxEditor :toolbar="false" v-model="notePad.note"></VmxEditor>
                                                <!-- <textarea rows="3" type="text" v-model="notePad.note" class="form-control" id="position1" placeholder="Note"></textarea> -->
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <button type="submit" @click="saveNotepad" class="btn btn-primary btn-sm mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                    <button type="submit" @click="clearNote" class="btn btn-primary btn-sm mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div class="note-content-scroll my-2">
                            <template v-for="noteList in webLinks ">
                                <div :class="{'selectedNote' : noteList.id == selectedNoteId}" class="card-media-mail my-1">
                                    <div class="card-body pl-2 py-2 row mx-0 pr-1">

                                        <div v-if="(noteList.id != notePadDetail.editId)" class="col-sm-12 col-md-8 mt-1 pb-1 user-icon pr-0">
                                            <h6 title="Title" class="fs-11">{{ noteList.title }}</h6>
                                            <a :href="noteList.webLink"   title="WebLink" target="_blank" class="fs-14  action-link"><i class="fa fa-globe" aria-hidden="true"></i> {{ noteList.webLink }}</a>
                                            <p title="Note" v-html="noteList.note" class="html-note-content mb-1"></p>
                                        </div>
                                        <div  v-if="(noteList.id == notePadDetail.editId)" class="col-12 text-right mail-quick-icons py-0 pr-2">
                                            <a href="javascript:" title="Save" @click="updateNotePad"><i class="fa fa-check  pr-2" aria-hidden="true"></i></a>
                                            <a href="javascript:" title="Cancel" @click="cancelUpdate"><i class="fa fa-times pr-2" aria-hidden="true"></i></a>
                                        </div>
                                        <div v-if="(noteList.id == notePadDetail.editId)" class="col-sm-12 col-md-12 mt-1 pb-1 user-icon pr-1 pl-0">
                                            <input @change="updateNotePad" type="text" class="form-control" placeholder="Title" v-model="notePadDetail.title">
                                            <input @change="updateNotePad" type="text" class="form-control mt-1" placeholder="WebLink" v-model="notePadDetail.webLink">
                                            <VmxEditor :toolbar="false" v-model="notePadDetail.note"></VmxEditor>
                                            <!-- <textarea @change="updateNotePad" rows="3" class="form-control mt-1 "  v-model="notePadDetail.note"></textarea> -->
                                        </div>

                                        <div v-if="(noteList.id != notePadDetail.editId)" class="col-4 float-right px-0">
                                            <div class="col-12 text-right mail-quick-icons py-0 pr-2">
                                                <a href="javascript:" title="Edit" @click="handleNoteClick(noteList)"><i class="fa fa-pencil pr-2" aria-hidden="true"></i></a>
                                                <a href="javascript:" title="Delete" @click="deleteNotePad(noteList.id)" v-b-modal.deleteNotePad><i class="fa fa-trash pr-0" aria-hidden="true"></i></a>
                                            </div>
                                            <div v-if="currentDate == new Date(noteList.sentDate).toString().substr(0, 10)" class="col-12 email-time pr-0 pb-3">
                                                {{ noteList.createdDt | formatTimeAmPm }}
                                            </div>
                                            <div v-else class="col-12 email-time pr-0">
                                                {{ noteList.lastUpdatedDt | formatDateTimeAmPm }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <DeleteComponent id="deleteNotePad" :onYes="onYesNote" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Notes ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import VmxEditor from '@/components/usercontrols/VmxEditor';
export default {
    name: 'app',
    components: {
        VmxEditor,
    },
    data() {
        return {
            elements: {
                visibleNote: false,
                visibleWeblink:false,
            },
            notePad: {
                id: '',
                notePadId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                noteById: localStorage.getItem("userId"),
                noteTypeId: 1,
                title: '',
                note: '',
                webLink: '',
                isArchive: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            notePadDetail: {
                id: '',
                notePadId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                noteById: localStorage.getItem("userId"),
                title: '',
                note: '',
                webLink: '',
                isArchive: '',
                editId: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            currentDate: new Date().toString().substr(0, 10),
            tabIndex: 0,
            selectedNoteId: null,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            notePadList: [],
            sentMails: [],
            attachments: [],
            notePads: [],
            search: '',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },

            initObj: {
                // selector: 'textarea',
                min_height: 100,
                placeholder: 'Write Something',
                content_style: "body { margin: 5px 8px;font-size: 11pt; } body * { margin: 0;font-size: 11pt; }",
                branding: false,
                menubar:false,
                statusbar: false,
                max_height: 350,
                plugins: ['paste', 'autoresize'],
                paste_as_text: true,
                paste_data_images: false,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    watch: {
        tabIndex() {
            this.selectedNoteId = null;
        }
    },
    computed: {
        filteredNotes() {
            return this.notePads.filter(notePad => {
                return notePad.title.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        notes() {
            return this.filteredNotes.filter(notePad => notePad.noteTypeId == 1)
        },
        webLinks() {
            return this.filteredNotes.filter(notePad => notePad.noteTypeId == 2)
        }
    },
    mounted() {
        this.getNotePad();
    },
    methods: {
        backgroundImage(img) {
            return 'background: url(' + img + ') no-repeat center; background-size: cover;';
        },
        getNotePad: function() {
            let data = {
                noteById: this.userId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notepad/list',data)
                .then((response) => {
                    let result = response.data;
                    this.notePads = result.notePadList;
                    //this.sentMails = result.sentItemList;
                    console.log(result);
                }).catch(error => {
                    console.log(error);
                });
        },
        saveNotepad: function() {
            this.notePad.noteTypeId = this.tabIndex == 1 ? 2 : 1;
            console.log(this.notePad);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notepad/add', this.notePad)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getNotePad();
                        this.clearNote();

                    }
                })
        },
        updateNotePad: function() {
            console.log(this.notePadDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notepad/update', this.notePadDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getNotePad();
                        this.cancelUpdate();

                    }
                })
        },
        deleteNotePad: function(id) {
            this.deleteParam.id = id;
        },
        onYesNote: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/notepad/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getNotePad();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
        handleNoteClick: function(notes) {
            this.elements.visibleNote = false;
            this.elements.visibleWeblink = false;
            //this.getAttachment(notes.notePadId);
            console.log(notes);
            this.notePadDetail.editId = notes.id;
            this.notePadDetail.id = notes.id;
            this.notePadDetail.notePadId = notes.notePadId;
            this.notePadDetail.title = notes.title;
            this.notePadDetail.note = notes.note;
            this.notePadDetail.noteTypeId = notes.noteTypeId;
            this.notePadDetail.webLink = notes.webLink;
            this.notePadDetail.noteById = notes.noteById;
            this.notePadDetail.isArchive = notes.isArchive;
            this.notePadDetail.createdDt = notes.createdDt;
            this.notePadDetail.lastUpdatedDt = notes.lastUpdatedDt;
            this.selectedNoteId = notes.id;

        },
        clearNote: function() {
            this.elements.visibleNote = false;
            this.elements.visibleWeblink = false;
            this.notePad.id = '';
            this.notePad.notePadId = '';
            this.notePad.title = '';
            this.notePad.webLink = '';
            this.notePad.note = '';
            this.getNotePad();
        },
        cancelUpdate: function() {
            this.elements.visibleNote = false;
            this.elements.visibleWeblink = false;
            this.notePad.id = '';
            this.notePadDetail.editId = '';
        },
        getFileExtension: function(name) {
            var re = /(?:\.([^.]+))?$/;

            return re.exec(name)[1];
        },
        // getAttachment: function(notePadId) {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectId: this.projectId,
        //         refEntity: 'note_pad',
        //         refRecordId: notePadId
        //     }
        //     axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             console.log(result);
        //             this.attachments = result.attachmentList;
        //         })
        // },
        imageFormats(content) {
            var contentExtension = this.getFileExtension(content);
            if ( contentExtension == 'png' || contentExtension == 'jpg' || contentExtension == 'jpeg' || contentExtension == 'tiff' || contentExtension == 'tif') {
                return true;
            }
            else return false;
        },
        onInput(e) {
            console.log(e.target.innerText);
            this.notePad.title = e.target.innerText;
        },
        handleInput(e) { 
            if (this.notePad.title) {

                console.log(e);
                const sel = document.getSelection(); 
                const offset = sel.anchorOffset; 
                this.notePad.title = e.target.textContent; 
                this.$nextTick(() => { 
                    sel.collapse(sel.anchorNode, offset);
                }); 
            }
        }
    }
    
}
</script>