<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">User Role Management</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New User Roles</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="userRole">User Role</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.userRole.userRole.$error }">
                                                                <input type="text" class="form-control" v-model="userRole.userRole" id="userRole" :class="{ 'is-invalid': $v.userRole.userRole.$error }" placeholder="Enter User Role">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.userRole.userRole.$error && !$v.userRole.userRole.minLength" class="text-danger">User Role must have at least {{$v.userRole.userRole.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.userRole.userRole.$error && !$v.userRole.userRole.required" class="text-danger">Please enter User Role title</label>
                                                            <label v-if="$v.userRole.userRole.$error && !$v.userRole.userRole.maxLength" class="text-danger">User Role must not exceed {{$v.userRole.userRole.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="userRoleCode">User Role Code</label>
                                                            <input type="text" class="form-control" v-model="userRole.userRoleCode" id="userRoleCode" placeholder="Enter User Role Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="userRoleTypeId">User Role Type</label>
                                                            <select v-model="userRole.userRoleTypeId" class="form-control" id="userRoleTypeId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in userRoleTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="userRole.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
<!--                                                     <div class="col-3">
                                                        <div class="form-group">
                                                            <label for="budget">Is Primary Role</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="userRole.isPrimary" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveUserRole" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="userRoleTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="userRoleList"  @row-selected="onRowSelected" :fields="userRoleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <!-- <template v-slot:cell(userRoleId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control" v-model="data.item.userRoleId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.userRoleId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.userRoleId"> {{ data.item.userRoleId }}</div>
                                </template> -->
                                <template v-slot:cell(isPrimary)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignStateChange('is_primary',data.item.id,data.value)">
                                    </b-form-checkbox>
                                </template>                                                    
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
<!--                                 <template v-slot:cell(isPrimary)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" >
                                    </b-form-checkbox>
                                </template> -->
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteUserRole(data.item.id)" data-toggle="modal" data-target="#deleteUserRole"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No User Roles found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="userRoleList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row input-group-label">
                                        <div class="col-5">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{userRoleDetail.userRoleId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>

                                        <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateUserRole" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="userRoleDet">User Role</label>
                                                <input type="text" class="form-control" id="userRoleDet" :disabled="!elements.editMode" v-model="userRoleDetail.userRole">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="userRoleTypeId">User Role Type</label>
                                                <select v-model="userRoleDetail.userRoleTypeId" :disabled="!elements.editMode"  class="form-control" id="userRoleTypeId">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in userRoleTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="userRoleCodeDet">User Role Code</label>
                                                <input type="text" class="form-control" v-model="userRoleDetail.userRoleCode" id="userRoleCodeDet" :disabled="!elements.editMode"  placeholder="Enter User Role Code">
                                            </div>
                                        </div>
<!--                                         <div class="col-6">
                                            <div class="form-group">
                                                <label>Is Primary Role</label>
                                                <b-form-checkbox class="custom-toggle" v-model="userRoleDetail.isPrimary" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>                                         -->
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Active</label>
                                                <b-form-checkbox class="custom-toggle" v-model="userRoleDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="createdBy">Created By</label>
                                                                        <input type="text" class="form-control disabled" id="createdBy" v-model="userRoleDetail.createdBy" readonly>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="startDate">Created Date</label>
                                                                        <div class="input-group" >
                                                                            <input type="text" class="form-control disabled" :value="userRoleDetail.createdDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteUserRole" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Role?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'user_role',

            idValue: '',
            idEdit: '',

            userRole: {
                id: '',
                userRoleId: '',
                userRole: '',
                userRoleCode: '',
                userRoleTypeId: '',
                active: true,
                isPrimary: false,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },
            userRoleDetail: {
                id: '',
                userRoleId: '',
                userRole: '',
                userRoleCode: '',
                userRoleTypeId: '',
                active: true,
                isPrimary: false,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },

            userRoleTypes: [],
            userRoleList: [],
            userRoleFields: [
                {key: 'index',label: 'SN'},
                { key: 'id', label: 'PK Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'userRoleId', label: 'User Role Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'userRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'userRoleType', label: 'User Role Type', sortable: true, sortDirection: 'desc' },
                { key: 'userRoleCode', label: 'User Role Code', sortable: true, sortDirection: 'desc' },
                // { key: 'isPrimary', label: 'Is Primary Role', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getLookupData();
        this.getUserRoles();
    },
    validations: {
        userRole: {
            userRole: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        userRoleDetail: {
            userRole: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getUserRoles: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/list',data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.userRoleList && result.userRoleList.length > 0) {
                        this.userRoleList = result.userRoleList;
                        this.$nextTick(() => {
                            this.$refs.userRoleTable.selectRow(0);
                        })
                        this.totalRows = this.userRoleList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLookupData: function() {
            let data = {
                entity: 'user_role_type',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/type', data)
                .then((response) => {
                    let result = response.data;
                    this.userRoleTypes = result.typeLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveUserRole: function() {
            this.$v.userRole.$touch();
            if (!this.$v.userRole.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/add', this.userRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getUserRoles();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateUserRole: function() {
            console.log(this.userRoleDetail);
            this.$v.userRoleDetail.$touch();
            if (!this.$v.userRoleDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/update', this.userRoleDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getUserRoles();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewUserRole: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/user/userrole/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let userRoleDetail = result.userRole;
                    if (userRoleDetail) {                        
                        this.userRoleDetail.id = userRoleDetail.id;
                        this.userRoleDetail.userRoleId = userRoleDetail.userRoleId;
                        this.userRoleDetail.userRole = userRoleDetail.userRole;
                        this.userRoleDetail.userRoleCode = userRoleDetail.userRoleCode;
                        this.userRoleDetail.userRoleTypeId = userRoleDetail.userRoleTypeId;
                        this.userRoleDetail.active = userRoleDetail.active;
                        this.userRoleDetail.isPrimary = userRoleDetail.isPrimary;
                        this.userRoleDetail.createdById = userRoleDetail.createdById;
                        this.userRoleDetail.createdBy = userRoleDetail.createdBy;
                        this.userRoleDetail.createdDt = userRoleDetail.createdDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewUserRole(this.userRoleDetail.id);
        },
        deleteUserRole: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/user/userrole/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserRoles();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.userRole.id = '';
            this.userRole.userRoleId = '';
            this.userRole.userRole = '';
            this.userRole.userRoleCode = '';
            this.userRole.userRoleTypeId = '';
            this.userRole.active = true;
            this.userRole.isPrimary = false;
            this.userRole.createdById = localStorage.getItem("userId");
            this.userRole.createdBy = '';
            this.userRole.createdDt = '';
            this.$v.userRole.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.userRoleDetail.id = data.id;
            this.userRoleDetail.userRoleId = data.userRoleId;
            this.userRoleDetail.userRole = data.userRole;
            this.userRoleDetail.userRoleCode = data.userRoleCode;
            this.userRoleDetail.userRoleTypeId = data.userRoleTypeId;
            this.userRoleDetail.active = data.active;
            this.userRoleDetail.isPrimary = data.isPrimary;
            this.userRoleDetail.createdById = data.createdById;
            this.userRoleDetail.createdBy = data.createdBy;
            this.userRoleDetail.createdDt = data.createdDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.userRoleDetail.id = data.id;
                this.userRoleDetail.userRoleId = data.userRoleId;
                this.userRoleDetail.userRole = data.userRole;
                this.userRoleDetail.userRoleCode = data.userRoleCode;
                this.userRoleDetail.userRoleTypeId = data.userRoleTypeId;
                this.userRoleDetail.active = data.active;
                this.userRoleDetail.isPrimary = data.isPrimary;
                this.userRoleDetail.createdById = data.createdById;
                this.userRoleDetail.createdBy = data.createdBy;
                this.userRoleDetail.createdDt = data.createdDt;
            }
        },
        assignStateChange: function(columnName,rowId,fieldValue) {

            fieldValue = (fieldValue)? "false" : "true";

            let data = {
                entity: this.entity,
                columnName: columnName,
                stateValue: fieldValue,
                id: rowId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.elements.showDetail = false;
                        this.getUserRoles();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })                 
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.userRole.entity = this.entity;
                this.userRole.id = item.id;
                this.userRole.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.userRole.entity = this.entity;
                this.userRole.id = item.id;
                this.userRole.active = 1;

                this.updateActivationState();
            }
            console.log(this.userRole.id, this.userRole.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.userRole)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.userRole.active = true;
                    this.getUserRoles();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
    }
}
</script>
