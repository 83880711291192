<template>
    <div class="menu">
        <div class="main-menu">
            <div class="scroll">
                <ul class="list-unstyled">
                    <li v-bind:class="[ (index === selectedModuleIndex) ? 'active' : '']" v-for="(info, index) in subModules" :title="info.subModule" :data-parentid="info.id">
                        <a href="#SubMenu" @click="loadSubModule(info.id);selectedModuleIndex = index" >
                            <i class="fa fa-briefcase"></i> {{ info.subModule }}
                        </a>
                    </li>                
                </ul>
            </div>
        </div>
        <div class="sub-menu">
            <div class="scroll">
                <ul class="list-unstyled" data-link="SubMenu" id="menuTypes" >
                    <li v-for="(info, index) in subMenu" :data-parentid="info.subModuleId" @click="selectedMainMenu = info.subModuleId" :class="{'selected' : info.entityCount == 1 && selectedMainMenu == info.subModuleId}">
                        <router-link v-if="info.entityCount == 1" :to="info.webUrl">
                            <i :class="info.icon"></i>
                            <span class="d-inline-block">{{ info.customMainMenu }}</span>
                        </router-link>
                        <a v-if="info.entityCount >1" href="javascript:" data-toggle="collapse" aria-expanded="false" :data-target="targetId(info.subModuleId)" class="rotate-arrow-icon collapsed">
                            <span><i :class="info.icon" class="inner-sub-menu"></i></span> 
                            <span class="d-inline-block">{{ info.customMainMenu }}</span>
                            <i class="fa fa-angle-down float-right"></i>
                        </a>
                        <div v-if="info.entityCount >1" :id="randomId(info.subModuleId)" class="collapse" data-parent="#menuTypes">
                            <ul class="list-unstyled inner-level-menu">
                                <li v-for="(info, index) in loadEntity(info.subModuleId)" @click="selectedSubMenu = info.id" :class="{'menu-selected' : selectedSubMenu == info.id}">
                                    <router-link :to="info.webUrl">
                                        <i :class="info.icon"></i> <span class="d-inline-block">{{ info.customSubMenu }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            elements: {
                showLogoBlock: false,
            },

            userId: localStorage.getItem("userId"),

            selectedModuleIndex: 0,
            selectedMainMenu: 0,
            selectedSubMenu: null,
            mainMenu: [],
            subMenu: [],
            entity: [],
            entityMenu: [],
            subModules: []
        }
    },
    mounted() {
        this.loadMenu();
    },
    methods: {
        loadMenu() {
            let data = {
                entity: 'sub_contract',
                userId: this.userId,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/lookup/subcontractmodule', data)
                .then((response) => {
                    let result = response.data;
                    this.subModules = result.customSubContractModuleLookup;
                    console.log(this.subModules);
                    this.getMainMenu(data);
                    this.getSubMenu(data);
                })



        },
        targetId: function(id) {
            return "#collapseAuthorization" + id;
        },
        randomId: function(id) {
            return "collapseAuthorization" + id;
        },
        loadSubModule(id) {
            this.subMenu = this.mainMenu.filter(function(menu) {
                console.log(menu,menu.subModuleId, id)
                if (menu.subModuleId == id) {
                    return menu;
                }
            });
        },
        loadEntity(id) {
            return this.entity.filter(function(menu) {
                if (menu.subModuleId == id)
                    return menu;
            });
        },
        getMainMenu: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/subcontractmainmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.mainMenu = result.customSubContractMainMenuLookup; 
                    if (this.subModules != null && this.subModules.length > 0) {               
                        this.loadSubModule(this.subModules[0].id);                       
                    }

                })
        },
        getSubMenu: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/subcontractsubmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.entity = result.customSubContractSubMenuLookup;
                })
        }
    }
}
</script>