<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Transfer</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <div class="form-row">
                    <div class="col-lg-6 col-xl-6">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <label for="transferFrom" class="font-weight-bold m-0">Transfer From</label>
                                            </div>
                                            <div class="col">
                                                <select v-model="fromVhextAccountId" class="form-control" id="transferFrom" @change="handleVhextAccountChange">
                                                    <option value="">Select Company</option>
                                                    <option v-for="(info, index) in corpGroupCompanyLookup" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <label for="transferFrom" class="m-0">Employee</label>
                                            </div>
                                            <div class="col">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetail.humanResourceId.$error }">
                                                    <select v-model="humanResourceId" class="form-control" @change="handleEmployeeChange" id="transferFrom" :class="{ 'is-invalid': $v.humanResourceDetail.humanResourceId.$error }">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in filteredEmployees" :value="info.humanResourceId" :key="index">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.humanResourceDetail.humanResourceId.$error && !$v.humanResourceDetail.humanResourceId.required" class="text-danger">Please Select an Employee</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="humanResourceId">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <div v-if="humanResourceId" class="row mb-1" id="profile py-0">
                                                <div class="col-12">
                                                    <b-avatar v-if="humanResource.photo" :src="humanResource.photo" size="75px" alt="Profile picture"></b-avatar>
                                                    <b-avatar v-else size="75px"></b-avatar>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="humanResource.name" class="col-12">
                                                    <label class="mb-1">{{ humanResource.name }}</label>
                                                </div>
                                                <div v-if="humanResource.employeeCode" class="col-12">
                                                    <label class="mb-1">
                                                        <span class="text-muted">EID: </span>{{ humanResource.employeeCode }}
                                                    </label>
                                                </div>
                                                <div class="col-12">
                                                    <p v-if="humanResource.designation || humanResource.department" class="mb-1">{{ humanResource.designation }} | {{ humanResource.department }}</p>
                                                    <p v-if="humanResource.qualification" class="mb-1">
                                                        <i class="fa fa-graduation-cap pr-2" aria-hidden="true"></i>
                                                        <span class="text-muted">Qulification: </span>{{ humanResource.qualification }}
                                                    </p>
                                                    <p v-if="humanResource.experienceYears || humanResource.experienceMonths" class="mb-1">
                                                        <span class="text-muted">Experience: </span>
                                                        <span v-if="humanResource.experienceYears">{{ humanResource.experienceYears }} years </span>
                                                        <span v-if="humanResource.experienceMonths">{{ humanResource.experienceMonths }} months</span>
                                                    </p>
                                                    <p v-if="humanResource.recruitedBy" class="mb-1">
                                                        <span class="text-muted">Recruited By: </span>
                                                        <span>{{ humanResource.recruitedBy }}</span>
                                                    </p>
                                                    <p v-if="humanResource.reportingTo" class="mb-1">
                                                        <span class="text-muted">Reporting To: </span>
                                                        <span>{{ humanResource.reportingTo }}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label class="font-weight-bold">Contact Info</label>
                                                    <p v-if="humanResource.emailId" class="mb-1">
                                                        <i class="fa fa-envelope pr-2" aria-hidden="true"></i>
                                                        {{ humanResource.emailId }}
                                                    </p>
                                                    <p v-if="humanResource.mobile" class="mb-1">
                                                        <i class="fa fa-mobile pr-2" aria-hidden="true"></i>
                                                        {{ humanResource.mobile }}
                                                    </p>
                                                </div>
                                                <!-- <div v-if="humanResourceDetail.userId" class="col-12">
                                                    <label class="font-weight-bold mt-3">User Account Info</label>
                                                    <p v-if="humanResource.userName" class="mb-1">
                                                        <i class="fa fa-user pr-2" aria-hidden="true"></i>
                                                        {{ humanResource.userName }}
                                                    </p>
                                                    <p v-if="humanResource.loginName" class="mb-1">
                                                        <i class="fa fa-sign-in pr-2" aria-hidden="true"></i>
                                                        {{ humanResource.loginName }}
                                                    </p>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button> -->
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Employee Status</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetail.fromEmployeeStatusId.$error }">
                                                    <select v-model="humanResourceDetail.fromEmployeeStatusId" class="form-control" :class="{ 'is-invalid': $v.humanResourceDetail.fromEmployeeStatusId.$error }">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in filteredEmployeeStatus" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.humanResourceDetail.fromEmployeeStatusId.$error && !$v.humanResourceDetail.fromEmployeeStatusId.required" class="text-danger">Please Select a Employee Status</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="endDate">Effective From</label>
                                                <b-form-input type="date" v-model="humanResourceDetail.endDate" id="endDate">
                                                </b-form-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div v-else class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <p class="m-0">Select Employee</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-xl-6">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col">
                                        <div class="row align-items-center">
                                            <div class="col-auto">
                                                <label for="transferTo" class="font-weight-bold m-0">Transfer To</label>
                                            </div>
                                            <div class="col">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetail.vhextAccountId.$error }">
                                                    <select v-model="toVhextAccountId" @change="empLookupData();getTargetEmpLookup();" class="form-control" id="transferTo" :class="{ 'is-invalid': $v.humanResourceDetail.vhextAccountId.$error }">
                                                        <option value="">Select Company</option>
                                                        <option v-for="(info, index) in toVhextAccountLookup" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.humanResourceDetail.vhextAccountId.$error && !$v.humanResourceDetail.vhextAccountId.required" class="text-danger">Please Select a Target Company</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="toVhextAccountId" class="card">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetail.name.$error }">
                                                <input type="text" class="form-control" v-model="humanResourceDetail.name" :class="{ 'is-invalid': $v.humanResourceDetail.name.$error }" placeholder="Enter New Employee Name">
                                                <div class="required-icon"></div>
                                            </div>
                                            <span v-if="$v.humanResourceDetail.name.$error && !$v.humanResourceDetail.name.required" class="text-danger">Please enter employee name</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-2 pr-0">
                                        <div class="form-group">
                                            <label for="employeeCode">EID</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetail.employeeCode.$error }">
                                                <b-form-input type="text" v-model="humanResourceDetail.employeeCode" :class="{ 'is-invalid': $v.humanResourceDetail.employeeCode.$error }" placeholder="Emp. Id" id="employeeCode">
                                                </b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <span v-if="$v.humanResourceDetail.employeeCode.$error && !$v.humanResourceDetail.employeeCode.required" class="text-danger">Please enter Employee Id</span>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4">
                                        <div class="form-group">
                                            <label for="status">Employee Status</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.humanResourceDetail.employeeStatusId.$error }">
                                                <select v-model="humanResourceDetail.employeeStatusId" class="form-control" :class="{ 'is-invalid': $v.humanResourceDetail.employeeStatusId.$error }">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in employeeStatuses" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.humanResourceDetail.employeeStatusId.$error && !$v.humanResourceDetail.employeeStatusId.required" class="text-danger">Please Select a Employee Status</label>
                                        </div>
                                    </div>                                        
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="designation">Designation</label>
                                            <select v-model="humanResourceDetail.designationData" class="form-control" id="designation">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in designations" :value="{id: info.id, text: info.value}" :key="index">{{info.value}}</option>
                                            </select>
                                            <!-- <a :title="designationLookupTitle" class="setting-icon action-link" href="javascript:" v-b-modal.designationModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="department">Department</label>
                                            <select v-model="humanResourceDetail.departmentId" class="form-control" id="department">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in departments" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                            <!-- <a :title="departmentLookupTitle" class="setting-icon action-link" href="javascript:" v-b-modal.departmentModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="qualification">Qualification</label>
                                            <input type="text" class="form-control" id="qualification" v-model="humanResourceDetail.qualification" placeholder="Qualification">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="experienceYears">Experience</label>
                                            <div class="row no-gutters">
                                                <div class="col-6">
                                                    <b-form-input type="number" min="0" max="80" v-model="humanResourceDetail.experienceYears" placeholder="Years" id="experienceYears"></b-form-input>
                                                </div>
                                                <div class="col-6">
                                                    <b-form-input type="number" min="0" max="12" v-model="humanResourceDetail.experienceMonths" placeholder="Months"></b-form-input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <label for="emailId">Email Id</label>
                                            <b-form-input type="text" v-model="humanResourceDetail.emailId" placeholder="Email Id" id="emailId">
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                         <div class="form-group">
                                            <label for="mobile">Mobile</label>
                                            <b-form-input type="text" v-model="humanResourceDetail.mobile" placeholder="Mobile" id="mobile">
                                            </b-form-input>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                         <div class="form-group">
                                            <label for="recruitedById">Recruited By</label>
                                            <select v-model="humanResourceDetail.recruitedById" class="form-control" id="recruitedById">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                         <div class="form-group">
                                            <label for="reportingToId">Reporting To</label>
                                            <select v-model="humanResourceDetail.reportingToId" class="form-control" id="reportingToId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                            </div>
                        </div>
                        <div v-else class="card">
                            <div class="card-body d-flex justify-content-center align-items-center">
                                <p class="m-0">Select Company</p>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    name: 'app',
    components: {
        AttachmentComponent,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                visible: false,
                editMode: false,
                curEmpPositionVisible: true,
                empInfoEditMode: false,

            },
            hasWorkflowConfig: 0,
            isWorkflowInitiator: 0,
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            fromVhextAccountId: '',
            toVhextAccountId: '',
            humanResourceId: '',

            humanResource: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                entity: '',
                userId: '',
                loginName: '',
                userType: '',
                humanResourceId: '',
                isEmployee: '',
                name: '',
                member: '',
                employeeCode: '',
                recruitedById: '',
                reportingToId: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                emailId: '',
                mobile: '',
                roleResponsibility:'',
                technologySkill:'',
                empId: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                wefDate: '',
                endDate: '',
                fromEmployeeStatusId: '',
                employeeStatusId: '',
                employeeStatus: '',
                jobPositionChangeById: '',
                jobPositionChangeDate: '',
                active: true,
                designationData: '',
                departmentId: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            humanResourceDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                entity: '',
                userId: '',
                loginName: '',
                userType: '',
                humanResourceId: '',
                humanResourceTypeId: '',
                isEmployee: '',
                name: '',
                member: '',
                employeeCode: '',
                recruitedById: '',
                reportingToId: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                emailId: '',
                mobile: '',
                roleResponsibility:'',
                technologySkill:'',
                empId: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                wefDate: '',
                endDate: '',
                fromEmployeeStatusId: '',
                employeeStatusId: '',
                employeeStatus: '',
                jobPositionChangeById: '',
                jobPositionChangeDate: '',
                active: true,
                designationData: '',
                departmentId: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            employeeName: '',
            mainTableArray: [],

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],

            corpGroupCompanyLookup: [],
            corpGroupEmployeeLookup: [],
            designations: [],
            departments: [],
            employeeStatuses: [],
            users: [],
            empPositionChangeSatus: [],
            targetCompanyEmployees: [],

            employeeList: [],
            employeeFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },        
                { key: 'designation', label: 'Designation', tdClass:'', sortable: true, sortDirection: 'desc' },        
                // { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },        
            ],

            selectMode: 'single',
            totalRows: 1,
            totalEPCHRows: 1,

            currentPage: 1,
            currentEPCHPage: 1,
            
            perPage: 10,
            perEPCHPage: 5,

            pageOptions: [5,10, 15, 20],

            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredEmployees() {
            if(this.fromVhextAccountId) {
                return this.corpGroupEmployeeLookup.filter(data => data.vhextAccountId == this.fromVhextAccountId);
            }
            else {
                return this.corpGroupEmployeeLookup;
            }
        },
        toVhextAccountLookup() {
            if(this.fromVhextAccountId) {
                return this.corpGroupCompanyLookup.filter(data => data.id != this.fromVhextAccountId);
            }
            else {
                return this.corpGroupCompanyLookup;
            }
        },
        filteredEmployeeStatus() {
            return this.employeeStatuses.filter(data => data.statusCode === 'RSG' || data.statusCode === 'RET');
        },
        employeeId() {
            if (this.humanResourceId) {
                return this.corpGroupEmployeeLookup.find(data => data.humanResourceId == this.humanResourceId).id;
            }
            else {
                return '';
            }
        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        humanResourceDetail: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            vhextAccountId: {
                required,
            },
            humanResourceId: {
                required,
            },
            employeeCode: {
                required,
            },
            fromEmployeeStatusId: {
                required,
            },
            employeeStatusId: {
                required,
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/emptransfer/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.corpGroupCompanyLookup = result.corpGroupCompanyLookup;
                    this.corpGroupEmployeeLookup = result.corpGroupEmployeeLookup;
                    this.employeeStatuses = result.employeeStatusLookup;

                }).catch((error)=> this.showOverlay = false);
        },
        saveData: function() {
            // this.humanResourceDetail.isEmployee = true;
            this.humanResourceDetail.employeeId = this.employeeId;
            this.humanResourceDetail.humanResourceId = this.humanResourceId;
            this.humanResourceDetail.isEmployee = true;
            this.humanResourceDetail.vhextAccountId = this.toVhextAccountId;
            this.humanResourceDetail.jobPositionChangeById = this.humanResourceDetail.recruitedById;
            // this.humanResourceDetail.jobPositionChangeDate = this.humanResourceDetail.designationData.text;
            this.humanResourceDetail.designationId = this.humanResourceDetail.designationData.id;
            this.humanResourceDetail.position = this.humanResourceDetail.designationData.text;

            this.$v.humanResourceDetail.$touch();
            if (!this.$v.humanResourceDetail.$invalid) {
                this.saveHumanResource();
            }
        },
        saveHumanResource: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/transfer', this.humanResourceDetail)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.lookupData();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: error.message,
                       position: 'topRight'
                   });
                })
        },
        getTargetEmpLookup: function() {
            let data = {
                vhextAccountId: this.toVhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/employee', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.targetCompanyEmployees = result.employeeLookup;

                    this.autoIncrementEmployeeCode();
                })
                .catch((error) => {
                    this.showOverlay = false;
                })
        },
        empLookupData: function() {
            let data = {
                vhextAccountId: this.toVhextAccountId,
                entity: 'human_resource', 
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/lookup/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.users = result.userLookup;
                    this.designations = result.designationLookup;
                    this.departments = result.departmentLookup;
                })
                .catch((error) => {
                    this.showOverlay = false;
                })
        },
        viewHumanResource: function() {
            this.showHRDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/hrm/view/' + this.humanResourceId)
                .then((response) => {
                    this.showHRDetOverlay = false;
                    let result = response.data;
                    let humanResource = result.humanResourceView;
                    if (humanResource) {
                        this.humanResource.id = humanResource.id;
                        this.humanResource.active = humanResource.active;
                        this.humanResource.createdBy = humanResource.createdBy;
                        this.humanResource.createdById = humanResource.createdById;
                        this.humanResource.createdDt = humanResource.createdDt;
                        this.humanResource.emailId = humanResource.emailId;
                        this.humanResource.empId = humanResource.empId;
                        this.humanResource.employeeId = humanResource.employeeId;
                        this.humanResource.workTypeId = humanResource.workTypeId;
                        this.humanResource.workType = humanResource.workType;
                        this.humanResource.departmentId = humanResource.departmentId;
                        this.humanResource.department = humanResource.department;
                        this.humanResource.jobCategoryId = humanResource.jobCategoryId;
                        this.humanResource.jobCategory = humanResource.jobCategory;
                        this.humanResource.workShiftId = humanResource.workShiftId;
                        this.humanResource.employeeStatusId = humanResource.employeeStatusId;
                        this.humanResource.employeeCode = humanResource.employeeCode;
                        this.humanResource.reportingToId = humanResource.reportingToId;
                        this.humanResource.employmentChangeById = humanResource.employmentChangeById;
                        this.humanResource.employmentChangeBy = humanResource.employmentChangeBy;
                        this.humanResource.wefDate = humanResource.wefDate;
                        this.humanResource.endDate = humanResource.endDate;
                        this.humanResource.employeeStatus = humanResource.employeeStatus;
                        this.humanResource.designationId = humanResource.designationId;
                        this.humanResource.designation = humanResource.designation;
                        this.humanResource.experienceMonths = humanResource.experienceMonths;
                        this.humanResource.experienceYears = humanResource.experienceYears;
                        this.humanResource.hasContactInfo = humanResource.hasContactInfo;
                        this.humanResource.humanResourceId = humanResource.humanResourceId;
                        this.humanResource.humanResourceType = humanResource.humanResourceType;
                        this.humanResource.humanResourceTypeId = humanResource.humanResourceTypeId;
                        this.humanResource.subContractId = humanResource.subContractId;
                        this.humanResource.joiningDate = humanResource.joiningDate;
                        this.humanResource.lastUpdatedDt = humanResource.lastUpdatedDt;
                        this.humanResource.loginName = humanResource.loginName;
                        this.humanResource.mobile = humanResource.mobile;
                        this.humanResource.name = humanResource.name;
                        this.humanResource.phone = humanResource.phone;
                        this.humanResource.gender = humanResource.gender;
                        this.humanResource.dateOfBirth = humanResource.dateOfBirth;
                        this.humanResource.photo = humanResource.photo;
                        this.humanResource.position = humanResource.position;
                        this.humanResource.qualification = humanResource.qualification;
                        this.humanResource.recruitedBy = humanResource.recruitedBy;
                        this.humanResource.recruitedById = humanResource.recruitedById;
                        this.humanResource.roleResponsibility = humanResource.roleResponsibility;
                        this.humanResource.salary = humanResource.salary;
                        this.humanResource.hourlySalary = humanResource.hourlySalary;
                        this.humanResource.technologySkill = humanResource.technologySkill;
                        this.humanResource.userId = humanResource.userId;
                        this.humanResource.userName = humanResource.userName;
                        this.humanResource.userType = humanResource.userType;
                        this.humanResource.userRoles = humanResource.userRoles;
                        this.humanResource.userTypeCode = humanResource.userTypeCode;
                        this.humanResource.userTypeId = humanResource.userTypeId;
                        this.humanResource.vhextAccountId = humanResource.vhextAccountId;

                        this.humanResourceDetail.name = humanResource.name;
                        this.humanResourceDetail.humanResourceTypeId = humanResource.humanResourceTypeId;
                        this.humanResourceDetail.employeeStatusId = humanResource.employeeStatusId;
                        this.humanResourceDetail.emailId = humanResource.emailId;
                        this.humanResourceDetail.mobile = humanResource.mobile;
                        this.humanResourceDetail.experienceYears = humanResource.experienceYears;
                        this.humanResourceDetail.experienceMonths = humanResource.experienceMonths;
                        this.humanResourceDetail.qualification = humanResource.qualification;
                        this.humanResourceDetail.endDate = humanResource.endDate;
                        this.humanResourceDetail.userId = humanResource.userId;
                    }
                })
        },
        clearData: function() {
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.humanResourceDetail.id = '';
            this.humanResourceDetail.humanResourceId = '';
            this.humanResourceDetail.loginName = '';
            this.humanResourceDetail.userType = '';
            this.humanResourceDetail.member = '';
            this.humanResourceDetail.name = '';
            this.humanResourceDetail.position = '';
            this.humanResourceDetail.qualification = '';
            this.humanResourceDetail.experienceYears = '';
            this.humanResourceDetail.experienceMonths = '';
            this.humanResourceDetail.emailId = '';
            this.humanResourceDetail.mobile = '';
            this.humanResourceDetail.employeeCode = '';
            this.humanResourceDetail.recruitedById = '';
            this.humanResourceDetail.reportingToId = '';
            this.humanResourceDetail.technologySkill = '';
            this.humanResourceDetail.designationData = '';
            this.humanResourceDetail.departmentId = '';
            this.humanResourceDetail.endDate = '';
            this.humanResourceDetail.userId = '';
            this.humanResourceDetail.fromEmployeeStatusId = '';
            this.humanResourceDetail.employeeStatusId = '';
            this.humanResourceDetail.humanResourceTypeId = '';
            this.fromVhextAccountId = '';
            this.humanResourceId = '';
            this.toVhextAccountId = '';
            this.$v.humanResourceDetail.$reset();
        },
        autoIncrementEmployeeCode() {
            let employeeCodeArray = this.targetCompanyEmployees.map(data => data.employeeCode);
            this.humanResourceDetail.employeeCode = this.alphaNumericAutoIncrement(employeeCodeArray);
        },
        handleEmployeeChange() {
            if (!this.fromVhextAccountId && this.humanResourceId) {
                this.fromVhextAccountId = this.corpGroupEmployeeLookup.find(data => data.humanResourceId == this.humanResourceId)?.vhextAccountId ?? '';
            }
            this.viewHumanResource();
        },
        handleVhextAccountChange() {
            if(this.fromVhextAccountId == this.toVhextAccountId) {
                this.toVhextAccountId = '';
            }
            this.humanResourceId = '';
        }
    }
}
</script>