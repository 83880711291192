import PayslipComponents from '@/views/finance/PayslipComponents.vue';
import PayrollConfig from '@/views/finance/PayslipConfig.vue';
import Payroll from '@/views/landingbasedpages/finance/LanPayroll.vue'
import PayrollCalculation from '@/views/finance/PayrollCalculation.vue'
import PayrollMasterList from '@/views/finance/PayrollMasterList.vue'

export default [
    {
        path: '/landing/payslipcomp',
        name: 'lanpayslipcomp',
        component: PayslipComponents,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/payslipconfig',
        name: 'lanpayslipconfig',
        component: PayrollConfig,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },    
    {
        path: '/landing/prlmas/list',
        name: 'lanprlmaslist',
        component: PayrollMasterList,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/payroll/:ermId',
        name: 'lanpayroll',
        component: Payroll,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/landing/prcalc',
        name: 'lanprcalc',
        component: PayrollCalculation,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },


    ];