<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Events / Meetings</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Meeting</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion1" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div v-if="!elements.viewMode">
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.title.$error }">
                                                                        <input type="text" class="form-control" maxlength="100" v-model="calendarEvent.title" id="title" :class="{ 'is-invalid': $v.calendarEvent.title.$error }" placeholder="Enter Title">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.minLength" class="text-danger">Title must have at least {{$v.calendarEvent.title.$params.minLength.min}} letters.</label>
                                                                    <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.required" class="text-danger">Please enter Title</label>
                                                                    <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.maxLength" class="text-danger">Title must not exceed {{$v.calendarEvent.title.$params.maxLength.max}} letters.</label>
                                                                </div>
                                                                <b-input-group v-if="elements.viewMode" :prepend="calendarEvent.eventId" class="mb-2">
                                                                    <b-form-input aria-label="First name" disabled v-model="calendarEvent.title"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="eventTypeId">Event Type</label>
                                                                <select  v-if="!elements.viewMode" v-model="calendarEvent.eventTypeId" class="form-control" id="eventTypeId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in eventTypes" :value="info.id">{{info.value}}</option>
                                                                </select>                    
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="calendarEvent.type" id="title">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="eventTypeId">Occurance</label>
                                                                <select :disabled="elements.viewMode" v-model="calendarEvent.eventFrequency" class="form-control" id="eventTypeId">
                                                                    <option value="0">Single Day</option>
                                                                    <option value="1">Recurring</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <div class="form-group">
                                                                <label for="eventTypeId">All Day</label>
                                                                <b-form-checkbox class="custom-toggle pt-1" id="isAllDayEvent" :disabled="elements.viewMode" v-model="calendarEvent.isAllDayEvent" name="check-button" switch></b-form-checkbox>
                                                            </div>
                                                        </div>
                                                        <div v-if="calendarEvent.eventFrequency == 1" class="col-3">
                                                            <div class="form-group">
                                                                <label for="daysOfWeek">Repeat</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': !isRecurValid }">
                                                                    <multiselect :disabled="elements.viewMode" v-model="calendarEvent.daysToRepeat" placeholder="Days to repeat" :options="days" name="repeat"  select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="daysToRepeat"></multiselect>
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="!isRecurValid" class="text-danger">Please select recurring days</label>
                                                            </div>
                                                        </div>                                                        
                                                        <div v-if="calendarEvent.eventFrequency == 0" class="col-12 col-md-3">
                                                            <div class="form-group">
                                                                <label for="startDt">Meeting Date</label>
                                                                <div class="input-group ">
                                                                    <input type="date" class="form-control" v-if="!elements.viewMode" v-model="calendarEvent.eventStartDate" id="eventStartTime">
                                                                    <template v-if="elements.viewMode">
                                                                        <input v-if="calendarEvent.isAllDayEvent" type="text" class="form-control" disabled :value="calendarEvent.eventStartDate | formatDate">
                                                                        <input v-else type="text" class="form-control" disabled :value="calendarEvent.eventStartDate | formatDate">
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <!-- <label for="startDt">Meeting Date</label> -->
                                                                <div class="row">
                                                                    <b-col sm="6" >
                                                                        <label for="startDt">Start Date</label>
                                                                        <input type="date" class="form-control" v-if="!elements.viewMode" v-model="calendarEvent.eventStartDate" id="eventStartTime">
                                                                        <template v-if="elements.viewMode">                                            
                                                                            <input v-if="calendarEvent.isAllDayEvent" type="text" class="form-control" disabled :value="calendarEvent.eventStartDate | formatDate">
                                                                            <input v-else type="text" class="form-control" disabled :value="calendarEvent.eventStartDate | formatDate">
                                                                        </template>
                                                                    </b-col>
                                                                    <b-col sm="6" >
                                                                        <label for="startDt">End Date</label>
                                                                        <input type="date" class="form-control" v-if="!elements.viewMode" v-model="calendarEvent.eventEndDate" id="eventEndDate">
                                                                        <template v-if="elements.viewMode">
                                                                            <input v-if="calendarEvent.isAllDayEvent" type="text" class="form-control" disabled :value="calendarEvent.eventEndDate | formatDate">
                                                                            <input v-else type="text" class="form-control" disabled :value="calendarEvent.eventEndDate | formatDate">
                                                                        </template>
                                                                    </b-col>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!calendarEvent.isAllDayEvent" class="col-12 col-md-4">
                                                            <div class="form-group">
                                                                <label for="endDt">Meeting Time</label>
                                                                <div class="row">
                                                                    <b-col sm="5" class="pr-0">
                                                                        <input type="time" class="form-control" v-if="!elements.viewMode" v-model="calendarEvent.eventStartTime" id="eventStartTime">
                                                                        <input type="text" class="form-control" disabled v-if="elements.viewMode" :value="calendarEvent.eventStartTime | formatTimeFromHM">
                                                                    </b-col>
                                                                    <b-col class="px-0 my-auto text-center">
                                                                        <label class="my-auto" for="eventEndTime">to</label>
                                                                    </b-col>
                                                                    <b-col sm="5" class="pl-0">
                                                                        <input type="time" class="form-control" v-if="!elements.viewMode" v-model="calendarEvent.eventEndTime" id="eventEndTime">
                                                                        <input type="text" class="form-control" disabled v-if="elements.viewMode" :value="calendarEvent.eventEndTime | formatTimeFromHM">
                                                                    </b-col>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="location">Location</label>
                                                                <b-form-input id="location" :disabled="elements.viewMode" v-model="calendarEvent.location" maxlength="45" placeholder="Enter Meeting Location"></b-form-input>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="link">Link</label>
                                                                <b-form-input type="url" id="link" maxlength="500" :disabled="elements.viewMode" v-model="calendarEvent.link" placeholder="Enter a Redirect Link"></b-form-input>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="hostedById">Host</label>
                                                                <select v-if="!elements.viewMode" v-model="calendarEvent.hostedById" class="form-control" id="statusId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="calendarEvent.hostedBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="deliverables">Participants</label>
                                                                <multiselect :disabled="elements.viewMode" v-model="calendarEvent.eventParticipant" placeholder="Add Participants" :options="allParticipants" name="eventParticipant" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="eventParticipant"></multiselect>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <b-card no-body class="mb-3">
                                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.agenda>
                                                                        <span>Agenda</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="agenda" v-model="elements.visibleAgenda" role="tabpanel">
                                                                    <b-card-body class="p-2">
                                                                        <div class="row">
                                                                            <div class="col-12 col-sm-12">
                                                                                <div class="form-group">
                                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="elements.viewMode" v-model="calendarEvent.description"></editor>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="events" :fields="meetingPreparationFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(link)="data">
                                    <a v-if="" :href="data.value" class="action-link" target="_blank" title="Meeting Link">{{ getShortString(data.value,20) }}</a>
                                </template>
                                <template v-slot:cell(start)="data">
                                    <div v-if="data.item.allDay && data.item.eventFrequency==1">{{ data.item.startRecur | formatDate }}</div>
                                    <div v-else-if="data.item.eventFrequency==1">{{ combineDateAndTime(data.item.startRecur,(data.item.startTime).substr(0,5)) | formatDate }}</div>
                                    <div v-else-if="data.item.allDay">{{ data.value | formatDate }}</div>
                                    <div v-else>{{ data.value | formatDate }}</div>
                                </template>
                                <template v-slot:cell(end)="data">
                                    <div v-if="data.item.allDay && data.item.eventFrequency==1">{{ data.item.endRecur | formatDate }}</div>
                                    <div v-else-if="data.item.eventFrequency==1">{{ combineDateAndTime(data.item.endRecur,(data.item.endTime).substr(0,5)) | formatDate }}</div>
                                    <div v-else-if="data.item.allDay">{{ data.value | formatDate }}</div>
                                    <div v-else>{{ data.value | formatDate }}</div>
                                </template>
                                <template v-slot:cell(time)="data">
                                    <div v-if="data.item.allDay">All Day</div>
                                    <div v-else-if="data.item.eventFrequency==1">
                                        <span v-if="data.item.startTime">{{ data.item.startTime | formatTimeFromHM }}</span> - <span v-if="data.item.endTime">{{ data.item.endTime | formatTimeFromHM }}</span>
                                    </div>
                                    <div v-else>
                                        <span v-if="data.item.start">{{ data.item.start | formatTimeFromDt }}</span> - <span v-if="data.item.end">{{ data.item.end | formatTimeFromDt }}</span>
                                    </div>
                                </template>
                                <template v-slot:cell(description)="data">
                                    <div v-html="getShortString(data.value,20)"></div>
                                </template>
                                <template v-slot:cell(daysOfWeek)="data">
                                    <span v-if="data.item.eventFrequency==0" class="text-muted">No Repeat</span>
                                    <span v-else v-for="day,index in (data.value)"><span v-if="index!==0">,</span> {{ days[day] }}</span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteMeetingPreparation(data.item.id)" v-b-modal.deleteMeetingPreparation><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Meetings found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="events.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <DeleteComponent id="deleteMeetingPreparation" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Meeting Minute?" />
        <MeetingPrepartionDetailPopup v-if="showDialog" ref="meetingPrepartionDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="meetingPrepartionPopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    components: {
                Multiselect,
                MeetingPrepartionDetailPopup,
                TypeLookup,
                Editor
            },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'meeting_preparation',
            typeEntity: 'meeting_type',
            userId: localStorage.getItem("userId"),
            typeLookupTitle:'Meeting Preparation Type Settings',

            elements: {
                editMode: false,
                visible: false,
                visibleAgenda: false,
                viewMode: false,
            },
            isRecurValid: true,
            showDialog: false,

            activeTab: 1,
            days: dayList,
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            calendarEvents: [],
            calendarEvent: {
                title: '',
                entityRecordMasterId: this.$route.params.ermId,
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                calendarTypeId: '',
                calendarCategoryId: '',
                eventTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                participantId: localStorage.getItem("userId"),
                daysToRepeat: [],
                eventParticipant: []
            },
            detailViewData: {},
            meetingPrepartionPopupId: 0,

            meetingPreparations: [],
            meetingPreparationFields: [
                { key: 'calendarEventId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Agenda' },
                { key: 'start',label: 'Start Date', sortable: true,sortDirection: 'desc' },
                { key: 'end',label: 'End Date', sortable: true,sortDirection: 'desc' },
                { key: 'time',label: 'Time', tdClass: 'table-cw-300' },
                { key: 'daysOfWeek', label: 'Repeat', tdClass: 'table-cw-100' },
                { key: 'link', label: 'Meeting Link', tdClass: 'table-cw-100' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            }
        }
    },
    computed: {
        events() {
            return this.calendarEvents.map((event)=> {
                if (event.eventFrequency == 1) {
                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek ? event.daysOfWeek.split(',') : [];
                    return event;
                }
                else return event;
            })
        },
    },
    mounted() {
        this.lookupData();
        this.getEvents();
    },
    validations: {
        calendarEvent: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        // getEvents: function() {
        //     let data = {
        //         participantId: this.userId
        //     }
        //     axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             this.calendarEvents = result.eventParticipantList;
        //             this.totalRows = this.calendarEvents.length;
        //         })
        // },

        getEvents: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/list', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvents = result.calendarEventList;
                    this.totalRows = this.calendarEvents.length;
                    console.log(this.calendarEvents);
                })
        },

        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'calendar_event'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.allParticipants = result.userLookup;
                    this.eventStatus = result.statusLookup;
                    this.eventTypes = result.customTypeLookup;
                })
        },
        saveData: function() {
            // validation to check daysToRepeat
            if (!this.checkRecurValidation()) {
                this.isRecurValid = false;
                return;
            } 
            else this.isRecurValid = true;
            this.calendarEvent.eventParticipantList = [];
            for (var index = 0; index < this.calendarEvent.eventParticipant.length; index++) {

                this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.eventParticipant[index].id });
            }
            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.participantId });
            
            this.calendarEvent.startTime = null;
            this.calendarEvent.endTime = null;
            if (this.calendarEvent.isAllDayEvent) {
                this.calendarEvent.startDate = this.getDateValue(this.calendarEvent.eventStartDate);
                this.calendarEvent.endDate = this.calendarEvent.eventFrequency==0 ? null : this.getDateValue(this.calendarEvent.eventEndDate);
                if (this.calendarEvent.eventFrequency==0) {
                    this.calendarEvent.daysToRepeat = [];
                    this.calendarEvent.daysOfWeek = null;
                }
                else if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
                    this.calendarEvent.daysOfWeek = [];
                    this.calendarEvent.daysToRepeat.forEach((day) => {
                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
                    })
                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
                } else this.calendarEvent.daysOfWeek = null;
            } else {
                if (this.calendarEvent.startDate == '' || this.calendarEvent.startDate == null) { 
                    this.calendarEvent.startDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime) 
                }
                if (this.calendarEvent.endDate == '' || this.calendarEvent.endDate == null) { 
                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventEndDate, this.calendarEvent.eventEndTime) 
                }
                if (this.calendarEvent.eventFrequency==0) {
                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventEndTime);
                    this.calendarEvent.daysToRepeat = [];
                    this.calendarEvent.daysOfWeek = null;
                }
                if (this.calendarEvent.eventFrequency==1) {
                    this.calendarEvent.startTime = this.calendarEvent.eventStartTime + ":00";
                    this.calendarEvent.endTime = this.calendarEvent.eventEndTime + ":00";
                    if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
                        this.calendarEvent.daysOfWeek = [];
                        this.calendarEvent.daysToRepeat.forEach((day,index) => {
                            this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
                        })
                        this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
                    }
                } else this.calendarEvent.daysOfWeek = null;
            }

            console.log(this.calendarEvent);
            this.$v.calendarEvent.$touch();
            if (!this.$v.calendarEvent.$invalid) {
                this.showOverlay = true;
                if (this.elements.editMode) {
                    this.updateEvent();
                } else {
                    this.saveEvent();
                }
            }
        },
        saveEvent: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/add', this.calendarEvent)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                        this.clearData();
                        this.elements.visible = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateEvent: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/update', this.calendarEvent)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                        this.elements.editMode = false;
                        this.elements.visible = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getParticipantList: function(id) {
            let data = {
                eventId: id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                })
        },
        showEdit(data) {
            console.log(data)
            this.elements.visible = true;
            this.elements.editMode = true;
            this.getParticipantList(data.id);
            if (data.daysOfWeek && data.daysOfWeek !== null) {

                this.calendarEvent.daysToRepeat = [];
                data.daysOfWeek.forEach((day) => {
                    this.calendarEvent.daysToRepeat.push(this.days[day]);
                })
                this.calendarEvent.eventStartDate = this.getDateValue(data.startRecur);
                this.calendarEvent.eventEndDate = this.getDateValue(data.endRecur);
                this.events.filter((event)=> {
                    if (event.id == data.id) {
                        this.calendarEvent.eventStartTime = event.startTime ? event.startTime.toString().substr(0, 5) : null;
                        this.calendarEvent.eventEndTime = event.endTime ? event.endTime.toString().substr(0, 5) : null;                        
                    }
                });
            } 
            else {
                this.calendarEvent.daysToRepeat = [];
                this.calendarEvent.eventStartDate = this.getDateValue(data.start);
                this.calendarEvent.eventEndDate = this.getDateValue(data.end);
                this.calendarEvent.eventStartTime = this.getTimeValue(data.start);
                this.calendarEvent.eventEndTime = this.getTimeValue(data.end);
            }
            this.calendarEvent.id = data.id;
            this.calendarEvent.title = data.title;
            this.calendarEvent.isAllDayEvent = data.allDay;
            this.calendarEvent.eventDate = data.eventDate;
            this.calendarEvent.description = data.description;
            this.calendarEvent.type = data.type;
            this.calendarEvent.link = data.link;
            this.calendarEvent.eventFrequency = data.eventFrequency;
            this.calendarEvent.calendarTypeId = data.calendarTypeId;
            this.calendarEvent.calendarCategoryId = data.calendarCategoryId;
            this.calendarEvent.eventTypeId = data.eventTypeId;
            this.calendarEvent.status = data.status;
            this.calendarEvent.statusId = data.statusId;
            this.calendarEvent.projectId = data.projectId;
            this.calendarEvent.vhextAccountId = data.vhextAccountId;
            this.calendarEvent.createdBy = data.createdBy;
            this.calendarEvent.hostedBy = data.hostedBy;
            this.calendarEvent.createdById = data.createdById;
            this.calendarEvent.hostedById = data.hostedById;
            this.calendarEvent.eventId = data.id;
            this.calendarEvent.location = data.location;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        combineDateAndTime: function(date, time) {
            date = this.getDateValue(date);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        checkRecurValidation() {
            let result = false;
            if (this.calendarEvent.eventFrequency==0) {
                result = true
            }
            else if (this.calendarEvent.eventFrequency==1 && this.calendarEvent.daysToRepeat.length>0) {
                result = true;
            }
            else {
                result = false;
            }
            return result;
        },
        clearData: function() {
            this.calendarEvent.title = '';
            this.calendarEvent.isAllDayEvent = false;
            this.calendarEvent.daysToRepeat = [];
            this.calendarEvent.eventDate = this.getDateValue(new Date());
            this.calendarEvent.startDate = '';
            this.calendarEvent.endDate = '';
            this.calendarEvent.eventStartDate = this.getDateValue(new Date());
            this.calendarEvent.eventEndDate = this.getDateValue(new Date());
            this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
            this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
            this.calendarEvent.statusId = '';
            this.calendarEvent.hostedById = '';
            this.calendarEvent.link = '';
            this.calendarEvent.location = '';
            this.calendarEvent.description = '';
            this.calendarEvent.calendarTypeId = '';
            this.calendarEvent.calendarCategoryId = '';
            this.calendarEvent.eventTypeId = '';
            this.calendarEvent.eventFrequency = 0;
            this.calendarEvent.eventParticipantList = '';
            this.calendarEvent.eventParticipant = '';
            this.calendarEvent.id = '';
            this.showOverlay = false;
            this.elements.visibleAgenda = false;
            this.elements.visible = false;
            this.elements.editMode = false;
            this.elements.viewMode = false;
            this.isRecurValid = true;
            this.$v.calendarEvent.$reset();
        },
        deleteMeetingPreparation: function(id) {
            this.deleteParam.id = id;
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.meetingPrepartionPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.meetingPrepartionPopupId, null, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        onYes: function() {
            return;
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/meetingminute/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}
</script>