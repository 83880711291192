<template>
    <div class="pt-2">
        <div class="container-fluid fixed-inner-header">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row ">
                        <div class="col-lg-6">
                            <h4 class="pl-2 mb-0 fs-16">Payroll</h4>
                        </div>
                        <div class="col-lg-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
<!--                                     <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li> -->
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div v-if="logo" class="card mb-2">
                        <div class="card-body p-0 pl-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="my-company-img  my-3">
                                                <img width="150px" alt="Company Logo" :src="logo">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Payroll Period</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.ermDate | formatMonthYear}} ({{erm.startDate | formatShortDate}} to {{erm.endDate | formatShortDate}})</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
<!--                                                 <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals && ermReviewApprovals.length > 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <ReviewApprovalTable :key="reviewApprovalTableKey" :items="ermReviewApprovals" :review="review" @refreshData="entityRecordMasterCollection" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Description</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">{{erm.description}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Payroll</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="payrollList" :fields="payrollFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" foot-clone foot-variant=light no-footer-sorting>
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(totalEarning)="data">
                                                <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                            </template>

                                            <template v-slot:cell(totalDeduction)="data">
                                                <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                            </template>
                                            <template #cell(netPay)="data">
                                                <div class="text-right">{{ (data.item.totalEarning - data.item.totalDeduction).toFixed(2)}}
                                                </div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>

                                            <template v-slot:foot(totalEarning)="data">
                                                <div class="text-right font-weight-bold">Total Earnings : {{totalEarnings.toFixed(2)}}</div>
                                            </template>
                                            <template v-slot:foot(totalDeduction)="data">
                                                <div class="text-right font-weight-bold">Total Deductions : {{totalDeductions.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:foot(netPay)="data">
                                                <div class="text-right font-weight-bold">Total : {{(totalEarnings-totalDeductions).toFixed(2)}}</div>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Payrolls found</div>
                                            </template>

                                            <template v-slot:foot()="data">
                                                <span></span>
                                            </template>

                                            <template v-slot:foot(totalEarning)="data">
                                                <div class="text-right font-weight-bold">Total Earnings : {{totalEarnings.toFixed(2)}}</div>
                                            </template>
                                            <template v-slot:foot(totalDeduction)="data">
                                                <div class="text-right font-weight-bold">Total Deductions : {{totalDeductions.toFixed(2)}}</div>
                                            </template>

                                            <template v-slot:foot(netPay)="data">
                                                <div class="text-right font-weight-bold">Total : {{(totalEarnings-totalDeductions).toFixed(2)}}</div>
                                            </template>

                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div  v-if="ermReferences && ermReferences.length > 0" class="card mb-2" >
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reference</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <ReferenceTable :items="ermReferences" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChatComment ref="chatCommentRef" :ermId="entityRecordMasterId" :entity="entity" />
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import ChatComment from '@/components/common/ChatComment.vue';
import ReferenceTable from '@/components/reportcomponents/ReferenceTable.vue';
import ReviewApprovalTable from '@/components/reportcomponents/ReviewApprovalTable.vue';

export default {
    name: 'app',
    components: {
        ChatComment,
        ReferenceTable,
        ReviewApprovalTable,
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            logo: localStorage.getItem('logo'),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            entityMaster: '',
            entity: '',
            strERMId:'',
            ermId: '',
            entityRecordMasterId: null,
            reviewApprovalTableKey: 0,
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            erm: {},
            ermReviewApprovals: [],
            ermReferences: [],

            payrollList: [],
            payrollFields: [
                { key: 'index', label: 'SN'},
                // { key: 'payrollId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'employeeId', label: 'Emp. Id', sortable: true, sortDirection: 'desc' },
                { key: 'employeeName', label: 'Emp. Name', sortable: true, sortDirection: 'desc' },
                // { key: 'payrollPeriodStr', label: 'Payroll Period', sortable: true,sortDirection: 'desc' },
                { key: 'totalEarning', label: 'Earnings', sortable: true, sortDirection: 'desc' },
                { key: 'totalDeduction', label: 'Deductions', sortable: true, sortDirection: 'desc' },
                { key: 'netPay', label: 'Net Pay', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc'
        }
    },
    computed: {
        key() {
            return this.$route.params.ermId;
        },
        sumTotal() {
            return this.payrollList.reduce((accum, item) => {
                return parseFloat(item.totalEarning) + parseFloat(item.totalDeduction)
            }, 0.00)
        },        

        totalEarnings() {
            return this.payrollList.reduce((accum, item) => {
                return accum + parseFloat(item.totalEarning)
            }, 0.00)
        },        
        totalDeductions() {
            return this.payrollList.reduce((accum, item) => {
                return accum + parseFloat(item.totalDeduction)
            }, 0.00)
        },
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
    },
    methods: {
        initialSettings: function() {
            this.entityMaster = 'payroll_master';
            this.entity = 'payroll';

            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.ermId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
            this.strERMId = String(this.ermId);
            this.review.entityRecordMasterId = this.ermId;
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entityMaster;
            this.showReport(this.strERMId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.getPayrollList(strERMId);

                this.entityRecordMasterCollection(strERMId);
                this.entityRecordMasterId = Number(strERMId);
                this.reviewApprovalTableKey++;
                this.$refs.chatCommentRef.getComments(strERMId);
            }
        },
        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/cmp/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;

                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;
                        this.entityRecordMasterCollection(this.ermId);

                        this.getPayrollList(this.ermId);

                        this.entityRecordMasterId = Number(this.ermId);
                        this.reviewApprovalTableKey++;
                        this.$refs.chatCommentRef.getComments(this.ermId);
                    }

                })
        },
        getPayrollList: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/summary/list', data)
                .then((response) => {
                    let result = response.data;
                    this.payrollList = result.payrollSummary;
                })
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },        
    }
}
</script>