<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Workflow Configuration</h4>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Workflow Config</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="workflowConfig">Functionality</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.workflowConfig.functionalEntityId.$error }">
                                                                <select v-model="workflowConfig.functionalEntityId" class="form-control" @change="onChange(workflowConfig.functionalEntityId)">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in functionalityList" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.workflowConfig.functionalEntityId.$error && !$v.workflowConfig.functionalEntityId.required" class="text-danger">Please Select Functionality</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-12">
                                                        <div class="card-body p-0 my-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-earning1>
                                                                        <span v-if="workflowConfigDetail.editMode">Edit Configuration</span>
                                                                        <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>Add Configuration</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accordion-earning1" accordion="my-accordion1" v-model="workflowConfigDetail.visible" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="budget">Workflow Status</label>
                                                                                    <select v-model="workflowConfigDetail.functionalStatusId" class="form-control" >
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in workflowStatusLookup" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="budget">User Selection Type</label>
                                                                                    <select v-model="workflowConfigDetail.userSelectionTypeEnum" class="form-control" >
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in userSelectionTypeEnumList" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div v-if="workflowConfigDetail.userSelectionTypeEnum == 2" class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="budget">Designation</label>
                                                                                    <select v-model="workflowConfigDetail.userGroupId" class="form-control" >
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in designations" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else-if="workflowConfigDetail.userSelectionTypeEnum == 3" class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="budget">Departments</label>
                                                                                    <select v-model="workflowConfigDetail.userGroupId" class="form-control" >
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in departments" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else-if="workflowConfigDetail.userSelectionTypeEnum == 5" class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="budget">Employees</label>
                                                                                    <multiselect v-model="workflowConfigDetail.workflowConfigUserList" :options="users" name="activity" label="value" track-by="id" placeholder="Select Users" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                                                </div>
                                                                            </div>
                                                                            <div v-else class="col-3">
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label class="v-hidden">btns</label>
                                                                                    <div>
                                                                                        <button type="submit" @click="addWorkflowConfigDetail()" class="sbtn btn btn-secondary py-1 px-3"><i class="fa fa-plus pr-2"></i>Add</button>
                                                                                        <button v-if="workflowConfigDetail.editMode" type="submit" @click="cancelDetailEdit" class="sbtn btn btn-secondary py-1 px-3" title="Cancel Edit"><i class="fa fa-remove"></i></button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                                    <!-- <div class="separator m-2 px-1"></div> -->
                                                            <div class="data-table-rows report-tables">
                                                                <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workflowConfigDetailList" @row-selected="" :fields="workflowConfigDetailFields" responsive="sm" foot-clone no-footer-sorting >
                                                                    <template #cell(index)="data">
                                                                        {{ data.index + 1 }}
                                                                    </template>
                                                                    <template v-slot:cell(functionalStatus)="data">
                                                                        {{ getFunctionalStatus(data.item.functionalStatusId) }}
                                                                    </template>
                                                                    <template v-slot:cell(userSelectionType)="data">
                                                                        {{ getUserSelectionType(data.item.userSelectionTypeEnum) }}
                                                                    </template>
                                                                    <template v-slot:cell(userGroupId)="data">
                                                                        <div v-if="data.item.userSelectionTypeEnum == 1">
                                                                            All Employees
                                                                        </div>
                                                                        <div v-else-if="data.item.userSelectionTypeEnum == 2">
                                                                            {{ getDesignation(data.value) }}
                                                                        </div>
                                                                        <div v-else-if="data.item.userSelectionTypeEnum == 3">
                                                                            {{ getDepartment(data.value) }}
                                                                        </div>
                                                                        <div v-else-if="data.item.userSelectionTypeEnum == 4">
                                                                            Reporting To
                                                                        </div>
                                                                        <div v-else-if="data.item.userSelectionTypeEnum == 5">
                                                                            <span v-for="user,index in (data.item.workflowConfigUserList)"><span v-if="index!==0">,</span> {{ user.value }}</span>
                                                                        </div>
                                                                    </template>
                                                                    <template v-slot:cell(actions)="data">
                                                                        <a href="javascript:" title="Edit" @click="showDetailEdit(data.item, data.index)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                        <a href="javascript:" title="Delete" @click="removeWorkflowConfigDetail(data.item)" ><i class="fa fa-trash pl-2"></i></a>
                                                                    </template>
                                                                    <template  v-slot:foot()="data">
                                                                        <span></span>
                                                                    </template>
                                                                    <template v-slot:empty="scope">
                                                                        <div class="text-center">No Configurations found</div>
                                                                    </template>
                                                                    <template v-slot:emptyfiltered="scope">
                                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                    </template>
                                                                </b-table>
                                                            </div>
                                                        </div>                                                            
                                                    </div>
                                                </div>
                                                <button type="submit" @click="addWorkflowConfigData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="workflowConfigTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="workflowConfigList"  @row-selected="onRowSelected" :fields="workflowConfigFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" foot-clone foot-variant=light no-footer-sorting>
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deleteWorkflowConfig(data.item.id)" v-b-modal.deleteWorkflowConfig><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Workflow Configuration found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                                <template v-slot:foot()="data">
                                    <span></span>
                                </template>                                
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                            <b-pagination v-if="workflowConfigList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm" >
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="WFC ID" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{workflowConfigEdit.workflowConfigId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
<!--                                             <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateWorkflowConfig" :disabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="form-group">
                                                    <label for="titleDet">Functionality</label>
                                                    <!-- <div v-if="elements.editMode" class="required-field-block" :class="{ 'is-invalid': $v.workflowConfig.functionalEntityId.$error }">
                                                        <select v-model="workflowConfig.functionalEntityId" class="form-control" @change="onChangeDet(workflowConfig.functionalEntityId)">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in functionalityList" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div> -->
                                                    <input type="text" v-model="workflowConfigEdit.functionalEntity" disabled class="form-control" id="serviceProvider" >
                                                    <!-- <label v-if="$v.workflowConfig.functionalEntityId.$error && !$v.workflowConfig.functionalEntityId.required" class="text-danger">Please Select Functionality</label>                                                     -->
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label for="activeDet">Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="workflowConfigEdit.active" :disabled="!elements.editMode" name="check-button" switch id="activeDet">
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-3">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-earning1>
                                                                <span v-if="workflowConfigDetailEdit.editMode">Edit Configuration</span>
                                                                <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>Add Configuration</span>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accordion-earning1" accordion="my-accordion1" v-model="workflowConfigDetailEdit.visible" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="budget">Workflow Status</label>
                                                                            <select v-model="workflowConfigDetailEdit.functionalStatusId" class="form-control" >
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in workflowStatusLookupDet" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="budget">User Selection Type</label>
                                                                            <select v-model="workflowConfigDetailEdit.userSelectionTypeEnum" class="form-control" >
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in userSelectionTypeEnumList" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="workflowConfigDetailEdit.userSelectionTypeEnum == 2" class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="budget">Designation</label>
                                                                            <select v-model="workflowConfigDetailEdit.userGroupId" class="form-control" >
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in designations" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="workflowConfigDetailEdit.userSelectionTypeEnum == 3" class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="budget">Departments</label>
                                                                            <select v-model="workflowConfigDetailEdit.userGroupId" class="form-control" >
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in departments" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else-if="workflowConfigDetailEdit.userSelectionTypeEnum == 5" class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="budget">Employees</label>
                                                                            <multiselect v-model="workflowConfigDetailEdit.workflowConfigUserList" :options="users" name="activity" label="value" track-by="id" placeholder="Select Users" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div v-else class="col-3">
                                                                    </div> -->
                                                                    <div class="col-12 text-right">
                                                                        <div class="form-group">
                                                                            <!-- <label class="v-hidden">btns</label> -->
                                                                            <!-- <div> -->
                                                                                <button type="submit" @click="saveWorkflowConfigDetail()" class="sbtn btn btn-secondary py-1 px-3 mr-1"><i class="fa fa-plus pr-2"></i>Add</button>
                                                                                <button type="submit" @click="cancelDetailDetEdit" class="sbtn btn btn-secondary py-1 px-3" title="Cancel Edit"><i class="fa fa-remove"></i></button>
                                                                            <!-- </div> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                            <!-- <div class="separator m-2 px-1"></div> -->
                                                    <div class="data-table-rows report-tables">
                                                        <b-table head-variant="light" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workflowConfigDetailEditList" @row-selected="" :fields="workflowConfigDetailEditFields" responsive="sm" foot-clone no-footer-sorting >
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(functionalStatus)="data">
                                                                {{ getFunctionalStatusDet(data.item.functionalStatusId) }}
                                                            </template>
                                                            <template v-slot:cell(userSelectionType)="data">
                                                                {{ getUserSelectionType(data.item.userSelectionTypeEnum) }}
                                                            </template>
                                                            <template v-slot:cell(userGroupId)="data">
                                                                <div v-if="data.item.userSelectionTypeEnum == 1">
                                                                    All Employees
                                                                </div>
                                                                <div v-else-if="data.item.userSelectionTypeEnum == 2">
                                                                    {{ getDesignation(data.value) }}
                                                                </div>
                                                                <div v-else-if="data.item.userSelectionTypeEnum == 3">
                                                                    {{ getDepartment(data.value) }}
                                                                </div>
                                                                <div v-else-if="data.item.userSelectionTypeEnum == 4">
                                                                    Reporting To
                                                                </div>
                                                                <div v-else-if="data.item.userSelectionTypeEnum == 5">
                                                                    <!-- <span v-for="user,index in (data.item.workflowConfigUserList)"><span v-if="index!==0">,</span> {{ user.value }}</span> -->
                                                                    {{ data.item.workflowConfigUsers }}
                                                                </div>
                                                            </template>
                                                            <template  v-slot:foot()="data">
                                                                <span></span>
                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Configurations found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">
                                                                <a href="javascript:" title="Edit" @click="showDetailDetEdit(data.item, data.index)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                <a href="javascript:" title="Delete" @click="deleteWorkflowConfigDetail(data.item.id)" v-b-modal.deleteWorkflowConfigDetail><i class="fa fa-trash pl-2"></i></a>
                                                            </template>
                                                        </b-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='workflowConfigDetailEdit.userName' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="workflowConfigDetailEdit.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="workflowConfigDetailEdit.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteWorkflowConfig" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Workfow Configuration ?" />
        <DeleteComponent id="deleteWorkflowConfigDetail" :onYes="onYesWorkflowConfigDetail" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Workfow Configuration ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import enummixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        Multiselect,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,

            detailViewData: {},
            referencePopupId: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'workflow_config',
            userId: localStorage.getItem("userId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            activityKey: 0,
            workflowConfigEditId: 0,
            mainTableArray: [],

            workflowConfig: {
                id: '',
                workflowConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                functionalEntityId: '',
                functionalStatusId: '',
                userSelectionTypeEnum: '',
                userGroupId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                workflowConfigDetailList: [],
            },
            workflowConfigDetail: {
                editMode: false,
                visible:false,
                id: '',
                workflowConfigDetailId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                workflowConfigId: '',
                functionalEntityId: '',
                workflowActivityId: '',
                functionalStatusId: '',
                userSelectionTypeEnum: '',
                userGroupId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                workflowConfigUserList: [],
            },
            workflowConfigEdit: {
                id: '',
                workflowConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                functionalEntityId: '',
                functionalEntity: '',
                workflowActivityId: '',
                functionalStatusId: '',
                entity: '',
                userSelectionTypeEnum: '',
                userGroupId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                workflowConfigDetailEditList: [],
            },
            workflowConfigDetailEdit: {
                editMode: false,
                visible:false,
                id: '',
                workflowConfigDetailId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                workflowConfigId: '',
                functionalEntityId: '',
                workflowActivityId: '',
                functionalStatusId: '',
                userSelectionTypeEnum: '',
                userGroupId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                workflowConfigUserList: [],
            },
            users: [],
            designations: [],
            departments: [],
            functionalityList: [],
            workflowStatusLookup: [],
            workflowStatusLookupDet: [],
            statusList: [],

            workflowConfigList: [],
            workflowConfigFields: [
                {key:'index',label: 'SN'},

                { key: 'workflowConfigId', label: 'WFC ID', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                { key: 'functionalEntity', label: 'Functionlity', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                { key: 'createdBy', label: 'Created By', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                
                // { key: 'reviewBy', label: 'Review By', sortable: true, sortDirection: 'desc' },
                // { key: 'approvalBy', label: 'Approval By', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            workflowConfigDetailList: [],
            workflowConfigDetailFields: [
                {key:'index',label: 'SN'},

                { key: 'functionalStatus', label: 'Workflow Status', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                // { key: 'reviewBy', label: 'Review By', sortable: true, sortDirection: 'desc' },
                { key: 'userSelectionType', label: 'User Selection Type', sortable: true, sortDirection: 'desc' },
                // { key: 'workflowConfigUsers', label: 'Selected Users', sortable: true, sortDirection: 'desc' },
                { key: 'userGroupId', label: 'User / Group', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            workflowConfigDetailEditList: [],
            workflowConfigDetailEditFields: [
                // {key:'index',label: 'SN'},
                { key: 'workflowActivityId', label: 'Activity Id', sortable: true, sortDirection: 'desc' },                
                { key: 'functionalStatus', label: 'Workflow Status', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                // { key: 'reviewBy', label: 'Review By', sortable: true, sortDirection: 'desc' },
                // { key: 'userSelectionType', label: 'User Selection Type', sortable: true, sortDirection: 'desc' },
                // { key: 'workflowConfigUsers', label: 'Selected Users', sortable: true, sortDirection: 'desc' },
                { key: 'userGroupId', label: 'User / Group', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

        }
    },
    computed: {
        functionalEntity() {
            let entity = '';
            let entityId = this.workflowConfig.functionalEntityId;
            if (entityId) {
                console.log(entityId, this.functionalityList)
                entity = this.functionalityList.find(data => data.id == entityId);
                entity = entity.entity ? entity.entity : '';
            }
            this.$nextTick(() => {
                this.getStatusLookupsDet(entity);
            })
            return entity;
        },
        functionalEntityDet() {
            let entity = '';
            let entityId = this.workflowConfig.functionalEntityId;
            if (entityId) {
                console.log(entityId, this.functionalityList)
                entity = this.functionalityList.find(data => data.id == entityId);
                entity = entity.entity ? entity.entity : '';
            }
            this.$nextTick(() => {
                this.getStatusLookups(entity);
            })
            return entity;
        },
    },
    mounted() {
        this.getWorkflowConfigurations();
        this.lookupData();
    },
    validations: {
        workflowConfig: {
            functionalEntityId: {
                required,
            },
        },
        // workflowConfig: {
        //     functionalEntityId: {
        //         required,
        //     },
        // },

    },
    methods: {
        getWorkflowConfigurations: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfig/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.workflowConfigList && result.workflowConfigList.length > 0) {
                        this.workflowConfigList = result.workflowConfigList;
                        console.log(this.workflowConfigList);
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.workflowConfig.id, this.mainTableArray, 'workflowConfigTable');
                        })
                        this.totalRows = this.workflowConfigList.length;
                    }
                    else {
                        this.workflowConfigList = [];
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfig/lookup/all' , data) 
            .then((response) => {
                let result = response.data;
                this.users = result.userLookup;
                this.functionalityList = result.entityLookup;      
                this.designations = result.designationLookup;
                this.departments = result.departmentLookup;                    
            })
        },
        getStatusLookups: function(entity) {
            if(entity) {
                let data = {
                    entity: entity,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/status/stdworkflow' , data) 
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.workflowStatusLookup = result.workflowStatusLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
            }
        },
        getStatusLookupsDet: function(entity) {
            if(entity) {
                let data = {
                    entity: entity,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/status/stdworkflow' , data) 
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.workflowStatusLookupDet = result.workflowStatusLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
            }
        },
        onChange(entityId) {

            // this.activityKey += 1;

            this.workflowConfigDetail.visible = true;
            let entity = this.functionalEntity;
            this.$nextTick(() => {
                this.getStatusLookups(entity);
            })
            // this.getWorkflowConfigDetailList(entityId);
        },
        // onChangeDet(entityId) {

        //     // this.activityKey += 1;

        //     this.workflowConfigDetail.visible = true;
        //     let entity = this.functionalEntityDet;
        //     this.$nextTick(() => {
        //         this.getStatusLookupsDet(entity);
        //     })
        //     // this.getWorkflowConfigDetailList(entityId);
        // },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        addWorkflowConfigData: function() {
            if (this.matchTextValExists(this.workflowConfig.functionalEntityId)) {
                iziToast.info({
                    message: 'Review Configuration already exists',
                    position: 'topCenter'
                });
            }
            else {
                this.workflowConfig.workflowConfigDetailList = this.workflowConfigDetailList.map(data => {
                    data.id = '';
                    return data;
                });
                this.$v.workflowConfig.$touch();
                if (!this.$v.workflowConfig.$invalid) {
                    console.log(this.workflowConfig);
                    console.log(this.workflowConfig.workflowConfigDetailList);
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfig/add', this.workflowConfig)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Success',
                                    message: result.message,
                                    position: 'topRight'
                                });
                                this.clearData();
                                this.getWorkflowConfigurations();
                            }
                        })
                        .catch(error => {
                            this.showOverlay = false;
                            iziToast.error({
                               title: 'Error',
                               message: 'Error',
                               position: 'topRight'
                           });
                        })
                }
            }
        },
        updateWorkflowConfig: function() {
            // if (this.matchTextValExists(this.workflowConfigDetail.entityId, this.workflowConfigDetail.title)) {
            //     iziToast.info({
            //         message: 'Review Configuration already exist.',
            //         position: 'topCenter'
            //     });
            //     return;
            // }
            console.log(this.workflowConfig);
            console.log(this.workflowConfig.workflowConfigDetailList);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfig/update', this.workflowConfig)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getWorkflowConfigurations();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewWorkflowConfig: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/general/workflowconfig/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let workflowConfig = result.workflowConfig;
                    if (workflowConfig) {    

                        this.workflowConfig.id = workflowConfig.id;
                        this.workflowConfig.workflowConfigId = workflowConfig.workflowConfigId;
                        this.workflowConfig.vhextAccountId = workflowConfig.vhextAccountId;
                        this.workflowConfig.functionalEntityId = workflowConfig.functionalEntityId;
                        this.workflowConfig.functionalEntity = workflowConfig.functionalEntity;
                        this.workflowConfig.workflowActivityId = workflowConfig.workflowActivityId;
                        this.workflowConfig.functionalStatusId = workflowConfig.functionalStatusId;
                        this.workflowConfig.userSelectionTypeEnum = workflowConfig.userSelectionTypeEnum;
                        this.workflowConfig.userGroupId = workflowConfig.userGroupId;
                        this.workflowConfig.active = workflowConfig.active;
                        this.workflowConfig.createdById = workflowConfig.createdById;
                        this.workflowConfig.createdBy = workflowConfig.createdBy;
                        this.getWorkflowConfigDetailEditList(this.workflowConfig.id)
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        getWorkflowConfigDetailEditList: function(workflowConfigId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                workflowConfigId: workflowConfigId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfigdetail/list',data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.workflowConfigDetailEditList = result.workflowConfigDetailList;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        cancelEditMode: function() {
            this.viewWorkflowConfig(this.workflowConfig.id);
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.workflowConfig.id = '';
            this.workflowConfig.workflowConfigId = '';
            // this.workflowConfig.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.workflowConfig.functionalEntityId = '';
            this.workflowConfig.workflowActivityId = '';
            this.workflowConfig.functionalStatusId = '';
            this.workflowConfig.userSelectionTypeEnum = '';
            this.workflowConfig.userGroupId = '';
            this.workflowConfig.active = true;
            // this.workflowConfig.createdById = localStorage.getItem("userId");
            this.workflowConfig.workflowConfigDetailList = [];
            this.workflowConfigDetailList = [];
            this.$v.workflowConfig.$reset();
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.workflowConfig.entity = 'workflow_config';
                this.workflowConfig.id = item.id;
                this.workflowConfig.active = 0;

            } else {
                this.workflowConfig.entity = 'workflow_config';
                this.workflowConfig.id = item.id;
                this.workflowConfig.active = 1;
                
            }
            this.updateActivationState();
            console.log(this.workflowConfig.id, this.workflowConfig.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.workflowConfig)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.workflowConfig.active = true;
                    this.getWorkflowConfigurations();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        addWorkflowConfigDetail: function() {
            // this.$v.workflowConfigDetail.$touch();
            // if (!this.$v.workflowConfigDetail.$invalid) {
                this.workflowConfigEditId++;
                let workflowConfigEditId = 'config_'+ this.workflowConfigEditId;

                let data = {};
                data.id = this.workflowConfigDetail.id ? this.workflowConfigDetail.id : workflowConfigEditId;
                data.workflowConfigDetailId = this.workflowConfigDetail.workflowConfigDetailId;
                data.vhextAccountId = this.workflowConfigDetail.vhextAccountId;
                data.workflowConfigId = this.workflowConfigDetail.workflowConfigId;
                data.functionalEntityId = this.workflowConfig.functionalEntityId;
                data.workflowActivityId = this.workflowConfigDetail.workflowActivityId;
                data.functionalStatusId = this.workflowConfigDetail.functionalStatusId;
                data.userSelectionTypeEnum = this.workflowConfigDetail.userSelectionTypeEnum;
                data.userGroupId = this.workflowConfigDetail.userGroupId;
                data.active = this.workflowConfigDetail.active;
                data.createdById = this.workflowConfigDetail.createdById;
                data.workflowConfigUserList = this.workflowConfigDetail.workflowConfigUserList;

                if (!this.checkAlreadyExists(data.id, data.functionalStatusId)) {
                    if (this.workflowConfigDetail.editMode) {
                        this.workflowConfigDetailList.splice(this.editIndex, 1);
                        this.workflowConfigDetailList.splice(this.editIndex, 0, data);
                        this.$nextTick(() => {
                            this.cancelDetailEdit();
                        })
                    }
                    else {
                        this.workflowConfigDetailList.push(data);
                        this.$nextTick(() => {
                            this.clearDetailEditData();
                        })
                    }
                    console.log(this.workflowConfigDetailList)
                }
                else {
                    iziToast.info({
                        message: 'Item already exists',
                        position: 'topCenter'
                    });
                }
            // }
        },
        deleteWorkflowConfig: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                entity: this.entity,
                erdId: this.deleteParam.id,
                vhextAccountId: this.vhextAccountId,
            }
            console.log(data)
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfig/delete', data)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getWorkflowConfigurations();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        showDetailEdit: function(data, index) {

            this.workflowConfigDetail.editMode = true;
            this.workflowConfigDetail.visible = true;
            this.editIndex = index;

            this.workflowConfigDetail.id = data.id;
            this.workflowConfigDetail.workflowConfigDetailId = data.workflowConfigDetailId;
            this.workflowConfigDetail.vhextAccountId = data.vhextAccountId;
            this.workflowConfigDetail.workflowConfigId = data.workflowConfigId;
            this.workflowConfigDetail.functionalEntityId = data.functionalEntityId;
            this.workflowConfigDetail.workflowActivityId = data.workflowActivityId;
            this.workflowConfigDetail.functionalStatusId = data.functionalStatusId;
            this.workflowConfigDetail.userSelectionTypeEnum = data.userSelectionTypeEnum;
            this.workflowConfigDetail.userGroupId = data.userGroupId;
            this.workflowConfigDetail.active = data.active;
            this.workflowConfigDetail.createdById = data.createdById;
            this.workflowConfigDetail.workflowConfigUserList = data.workflowConfigUserList;
        },
        showDetailDetEdit: function(data, index) {

            this.workflowConfigDetail.editMode = true;
            this.workflowConfigDetail.visible = true;
            this.editIndex = index;

            this.workflowConfigDetail.id = data.id;
            this.workflowConfigDetail.workflowConfigDetailId = data.workflowConfigDetailId;
            this.workflowConfigDetail.vhextAccountId = data.vhextAccountId;
            this.workflowConfigDetail.workflowConfigId = data.workflowConfigId;
            this.workflowConfigDetail.functionalEntityId = data.functionalEntityId;
            this.workflowConfigDetail.workflowActivityId = data.workflowActivityId;
            this.workflowConfigDetail.functionalStatusId = data.functionalStatusId;
            this.workflowConfigDetail.userSelectionTypeEnum = data.userSelectionTypeEnum;
            this.workflowConfigDetail.userGroupId = data.userGroupId;
            this.workflowConfigDetail.active = data.active;
            this.workflowConfigDetail.createdById = data.createdById;
            // this.workflowConfigDetail.workflowConfigUserList = data.workflowConfigUserList;
            if (this.workflowConfigDetail.userSelectionTypeEnum == 5) {
                this.$nextTick(() => {
                    this.getWorkflowConfigUserList(this.workflowConfigDetail.id);
                })
            } else {
                this.workflowConfigDetail.workflowConfigUserList = [];
            }
        },
        getWorkflowConfigUserList: function(workflowConfigDetailId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                // projectId: this.projectId,
                workflowConfigDetailId: workflowConfigDetailId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfiguser/lookup/all',data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.workflowConfigDetail.workflowConfigUserList = result.workflowConfigUserLookup;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                console.log(data);
                this.workflowConfig.id = data.id;
                this.workflowConfigEdit.workflowConfigId = data.workflowConfigId;                
                this.workflowConfigEdit.functionalEntityId = data.functionalEntityId;
                this.workflowConfigEdit.functionalEntity = data.functionalEntity;


                this.workflowConfigDetailEdit.id = data.id;
                this.workflowConfigDetailEdit.workflowConfigId = data.workflowConfigId;
                this.workflowConfigDetailEdit.vhextAccountId = data.vhextAccountId;
                this.workflowConfigDetailEdit.functionalEntity = data.functionalEntity;
                this.workflowConfigDetailEdit.entity = data.entity;
                this.workflowConfigDetailEdit.functionalEntityId = data.functionalEntityId;
                this.workflowConfigDetailEdit.workflowActivityId = data.workflowActivityId;
                this.workflowConfigDetailEdit.functionalStatusId = data.functionalStatusId;
                this.workflowConfigDetailEdit.userSelectionTypeEnum = data.userSelectionTypeEnum;
                this.workflowConfigDetailEdit.userGroupId = data.userGroupId;
                this.workflowConfigDetailEdit.active = data.active;
                this.workflowConfigDetailEdit.createdById = data.createdById;
                
                console.log(this.workflowConfigDetailEdit);
                // this.activityKey += 1;
                this.$nextTick(() => {
                    // let entity = this.functionalEntity;
                    this.getStatusLookupsDet(this.workflowConfigDetailEdit.entity);
                    // this.onChangeDet(this.workflowConfig.functionalEntityId);
                    this.getWorkflowConfigDetailEditList(this.workflowConfigDetailEdit.id);
                })
            }
        },

        saveWorkflowConfigDetail: function() {

            if (!this.checkAlreadyExists(this.workflowConfigDetailEdit.id, this.workflowConfigDetailEdit.functionalStatusId)) {
                if (this.workflowConfigDetailEdit.editMode) {
                    this.updateWorkflowConfigDetailEdit();
                }
                else {
                    this.addWorkflowConfigDetailEdit();
                }
            }
            else {
                iziToast.info({
                    message: 'Item already exist',
                    position: 'topCenter'
                });
            }
        },
        addWorkflowConfigDetailEdit: function() {
            this.showDetOverlay = true;
            this.workflowConfigDetailEdit.workflowConfigId = this.workflowConfigDetailEdit.id;
            console.log(this.workflowConfigDetailEdit);
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfigdetail/add', this.workflowConfigDetailEdit)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getWorkflowConfigDetailEditList(this.workflowConfig.id);
                        this.cancelDetailDetEdit();
                        // this.getPayrollList();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        updateWorkflowConfigDetailEdit: function() {
            this.showDetOverlay = true;
            this.workflowConfigDetailEdit.workflowConfigId = this.workflowConfigDetailEdit.id;
            console.log(this.workflowConfigDetailEdit);
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfigdetail/update', this.workflowConfigDetailEdit)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getWorkflowConfigDetailEditList(this.workflowConfig.id);
                        this.cancelDetailDetEdit();
                        // this.getPayrollList();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        deleteWorkflowConfigDetail: function(id) {
            this.deleteParam.id = id;
        },
        onYesWorkflowConfigDetail: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/general/workflowconfigdetail/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.getPayrollDetail(this.payrollEditData.id);
                        // this.getPayrollList();
                        // this.cancelPayrollDetailEdit('Earning');
                        // this.cancelPayrollDetailEdit('Deduction');
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                });
        },
        getWorkflowConfigDetailList: function(workflowConfigId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                workflowConfigId: workflowConfigId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowconfigdetail/list',data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.workflowConfigDetailList = result.workflowConfigDetailList;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        removeWorkflowConfigDetail: function(data) {
            this.cancelDetailEdit();
            this.workflowConfigDetailList = this.workflowConfigDetailList.filter((payroll)=> payroll !== data );

        },
        cancelDetailEdit: function () {
            this.workflowConfigDetail.editMode = false;
            this.workflowConfigDetail.visible = false;
            this.clearDetailEditData();
        },
        clearDetailEditData: function () {
            this.workflowConfigDetail.id = '';
            this.workflowConfigDetail.workflowConfigDetailId = '';
            // this.workflowConfigDetail.vhextAccountId = '';
            this.workflowConfigDetail.workflowConfigId = '';
            this.workflowConfigDetail.functionalEntityId = '';
            this.workflowConfigDetail.workflowActivityId = '';
            this.workflowConfigDetail.functionalStatusId = '';
            this.workflowConfigDetail.userSelectionTypeEnum = '';
            this.workflowConfigDetail.userGroupId = '';
            this.workflowConfigDetail.active = true;
            this.workflowConfigDetail.workflowConfigUserList = [];
            // this.workflowConfigDetail.createdById = '';
            // this.$v.workflowConfigDetail.$reset();
        },
        cancelDetailDetEdit: function () {
            this.workflowConfigDetail.editMode = false;
            this.workflowConfigDetail.visible = false;
            this.clearDetailDetEditData();
        },
        clearDetailDetEditData: function () {
            this.workflowConfigDetail.id = '';
            this.workflowConfigDetail.workflowConfigDetailId = '';
            // this.workflowConfigDetail.vhextAccountId = '';
            // this.workflowConfigDetail.workflowConfigId = '';
            this.workflowConfigDetail.workflowActivityId = '';
            this.workflowConfigDetail.functionalStatusId = '';
            this.workflowConfigDetail.userSelectionTypeEnum = '';
            this.workflowConfigDetail.userGroupId = '';
            this.workflowConfigDetail.active = true;
            this.workflowConfigDetail.workflowConfigUserList = [];
            // this.workflowConfigDetail.createdById = '';
            // this.$v.workflowConfigDetail.$reset();
        },
        checkAlreadyExists: function(id, functionalStatusId) {
            let result = false;
            this.workflowConfigDetailList.forEach(data => {
                if (functionalStatusId == data.functionalStatusId && id != data.id) {
                    result = true;
                }
            });
            return result;
        },
        matchTextValExists: function(id, title) {
            let result = false;
            this.workflowConfigList.forEach(data => {
                if (title) {
                    if ((data.functionalEntityId != id) && (data.title == title)) {
                        result = true;
                    }
                }
                else if (data.functionalEntityId == id) {
                    result = true;
                }
            });
            return result;
        },
        getDesignation(id) {
            let resultData = this.designations.find(data => data.id == id);
            return resultData ? resultData.value : '';
        },
        getDepartment(id) {
            let resultData = this.departments.find(data => data.id == id);
            return resultData ? resultData.value : '';
        },
        getUserSelectionType(id) {
            let resultData = this.userSelectionTypeEnumList.find(data => data.id == id);
            return resultData ? resultData.value : '';
        },
        getFunctionalStatus(id) {
            let resultData = this.workflowStatusLookup.find(data => data.id == id);
            return resultData ? resultData.value : '';
        },
        getFunctionalStatusDet(id) {
            let resultData = this.workflowStatusLookupDet.find(data => data.id == id);
            return resultData ? resultData.value : '';
        },
    }
}
</script>
