<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6">
                            <h4 class="pl-2 mb-0 fs-16">Asset Detail Report</h4>
                        </div>
                        <div class="col-lg-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                        <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                            <label v-if="elements.filterVisible" class="my-2 py-1">Hide</label>
                            <label v-else class="my-2 py-1">Filter</label>
                        </div>                        
                        <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                            <b-card-body class="p-2">
                                <div class="comments-titel">
                                    <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                </div>
                                <div class="comments-cox mt-2">
                                    <div class="row m-0">
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="assetId">Assets</label>
                                                <select v-model="assetId" class="form-control" >
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in assetLookup" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-12">
                                            <button type="submit" @click="handleValueChange()" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                            <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                            <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal mb-2">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Asset</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.title }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Brand</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.brand }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dl-horizontal mb-2">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Model Number</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.modelNumber }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Category</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.assetCategory }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.assetType }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Responsible Person</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.responsiblePerson }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal mb-2">
                                                <p class="contact-title"><span>Purchase Details</span></p>
                                                <div class="form-row">
                                                    <div class="col-12 col-md-4">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Purchased By</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.purchasedBy }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Purchase Date</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.purchaseDate | formatDate }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Cost</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    {{ currencySymbol }} {{ assetData.cost }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="dl-horizontal">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-4">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Mfg. Date</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.mfgDate | formatDate }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Expiry Date</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ assetData.expDate | formatDate }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-4">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Warranty</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <span v-if="assetData.warrantyYear">{{ assetData.warrantyYear }} years </span>
                                                                    <span v-if="assetData.warrantyMonth">{{ assetData.warrantyMonth }} months</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="assetData.description" class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Description</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12" v-html="assetData.description" ></div>
                            </div>
                        </div>
                    </div>
                    <div v-show="attachmentDocsLength>0 || attachmentLinksLength>0" class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="form-row">
                                                    <div v-show="attachmentDocsLength > 0" class="col-12 col-md-6">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 col-lg-3 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachments</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 col-lg-9 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <AttachmentView :key="attachmentKey" ref="viewAttachmentRef" @getAttachmentLength="getAttachmentDocsLength" showDocsOnly :attachmentData="attachmentData" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="attachmentLinksLength > 0" class="col-12 col-md-6">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 col-lg-3 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reference Links</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 col-lg-9 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <AttachmentView :key="attachmentLinkKey" ref="viewAttachmentRefLink" @getAttachmentLength="getAttachmentLinksLength" showLinkOnly :attachmentData="attachmentData" />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="hasContactInfo || hasAddressInfo" class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div v-if="hasContactInfo" class="dl-horizontal">
                                                <p class="contact-title"><span>Vendor Details</span></p>
                                                <div class="form-row">
                                                    <div v-if="contactData.contactName" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Name</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.contactName }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.company" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Company</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.company }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.emailId" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Email</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    {{ contactData.emailId }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.mobile" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Mobile</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.mobile }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.phone" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Phone</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.phone }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.officePhone" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Office Phone</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.officePhone }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="hasAddressInfo" class="dl-horizontal">
                                                <p class="contact-title"><span>Address Info</span></p>
                                                <div class="form-row">
                                                    <div v-if="contactData.address1" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Building Name</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.address1 }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.streetName" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Street Name</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.streetName }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.city" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">City</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    {{ contactData.city }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.state" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">State</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.state }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.country" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Country</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ contactData.country }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="contactData.pincode" class="col-12 col-md-4 mb-2">
                                                        <div class="row">
                                                            <div class="col-6 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Pin Code</label>
                                                            </div>
                                                            <div class="col-6 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    {{ contactData.pincode }}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    name: 'app',
    components: {
        AttachmentView,
    },
    data() {
        return {
            elements: {
                filterVisible: true,
            },
            attachmentKey: 0,
            attachmentLinkKey: 100,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'asset',
            currencySymbol: localStorage.getItem("currency"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            attachmentDocsLength: 0,
            attachmentLinksLength: 0,

            assetData: {},
            contactData: {},
            attachmentData: {},
            assetLookup: [],
        }
    },
    computed: {
        assetId: {
            get() {
                let assetId = this.$store.state.erd_data.erd_id;
                if (Number(assetId) && assetId != '') {
                    return assetId;
                } else {
                    return this.assetLookup.length > 0 ? this.assetLookup[0].id : '';
                }
            },
            set(value) {
                this.$store.state.erd_data.erd_id = value;
            }
        },
        hasContactInfo() {
            return this.contactData.contactName || this.contactData.company || this.contactData.emailId || this.contactData.mobile || this.contactData.phone || this.contactData.officePhone;
        },
        hasAddressInfo() {
            return this.contactData.address1 || this.contactData.streetName || this.contactData.city || this.contactData.state || this.contactData.country || this.contactData.pincode;
        }
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
    },
    beforeDestroy() {
        this.assetId = null;
    },
    methods: {
        getAssetReport: function(assetId) {
            if(assetId) {
                this.getContacts();
                axios.get(this.$store.getters.getAPIBasePath + '/company/asset/view/' + assetId)
                    .then((response) => {
                        let result = response.data;
                        this.assetData = result.assetView;
                    })
            }
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: this.entity,
                refRecordId: this.assetId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/view', data)
                .then((response) => {
                    let result = response.data;
                    if (result.contact) {
                        this.contactData = result.contact;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/asset', data)
                .then((response) => {
                    let result = response.data;
                    this.assetLookup = result.assetLookup;
                    this.getAssetReport(this.assetId);
                })
        },
        handleValueChange() {
            this.$store.state.erd_data.erd_id = this.assetId;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = this.assetId;
            this.attachmentLinkKey++;
            this.attachmentKey++;
            this.getAssetReport(this.assetId);
        },
        getAttachmentDocsLength(length) {
            this.attachmentDocsLength = length;
        },
        getAttachmentLinksLength(length) {
            this.attachmentLinksLength = length;
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
        },
        clearData: function() {
            this.assetTypeId = '';
            this.assetCategoryId = '';
            this.getAssetReport(this.assetId);
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },        
    }
}
</script>