<template>
    <div class="container-fixed">
        <b-overlay :show="showOverlayBudChart" opacity="0.4">
            <div class="row">
                <div class="col-12 mb-2">
                    <div class="card">
                        <div class="card-body p-3">
                            <!-- <p class="chart-title text-left pl-1 mb-1">Finance By Month</p>
                            <div class="separator mb-1"></div> -->
                            <BarChart :chartdata="budgetByCategory" :options="barChartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import BarChart from "@/components/blocks/charts/BarChart.vue";
export default {
    name: "App",
    props: {
        ermId: Number,
        ermTypeCode: String,
    },
    components: {
        BarChart,
    },
    data() {
        return {
            showOverlayBudChart: false,

            barChartKey: 0,

            budgetByCategory: {},

            barChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                },
                scales: {
                    xAxes: {
                        stacked: true,
                        position: 'top',
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                    yAxes: {
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                }
            },

        }
    },
    mounted() {
        this.getDashboardData();
    },
    methods: {
        getDashboardData: function() {
            if (this.ermId) {
                let data = {
                    entityRecordMasterId: this.ermId,
                    typeCode: this.ermTypeCode,
                }
                this.showOverlayBudChart = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/chart', data)
                    .then((response) => {
                        this.showOverlayBudChart = false;
                        let result = response.data;

                        this.barChartKey += 1;

                        if (result.budgetTrackByCategory != null) {
                            this.budgetByCategory = {
                                labels: result.budgetTrackByCategory.map(item => item.category),
                                datasets: [{
                                    label: 'Estimated Amount',
                                    data: result.budgetTrackByCategory.map(item => item.estimatedCost),
                                    backgroundColor: '#28a745',
                                    // borderColor: 'rgba(75, 192, 192, 1)',
                                    // borderWidth: 1,
                                    borderSkipped: false,
                                  },
                                  {
                                    label: 'Actual Amount',
                                    data: result.budgetTrackByCategory.map(item => item.actualCost),
                                    backgroundColor: '#d85a5a',
                                    // borderColor: 'rgba(255, 26, 104, 1)',
                                    // borderWidth: 1,
                                    borderSkipped: false,
                                  }]
                                }
                        }
                    })
                    .catch(error => {
                        this.showOverlayBudChart = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
    }
}
</script>