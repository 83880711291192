<template>
    <div>
        <DefectPreventionPlanMaster :ermMasterInfo="ermMasterInfo"></DefectPreventionPlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DefectPreventionPlanMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        DefectPreventionPlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Defect Prevention Plan Master",
                entity: 'defect_prevention_plan_master',
                refEntity: 'defect_prevention_plan',
                ermListRedirectURL: '/dppmas/list',
                erdRedirectURL: '/quality/defectpreventionplan/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>