<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-2 well">
                            <h4 class="pl-2 mb-0 fs-16">Type Settings</h4>
                        </div>
                        <div class="col-lg-3 col-sm-2 well">
                            <h5 class="pl-2 mb-0 fs-16">{{activeDisplayName}}</h5>
                        </div>
                        <div class="col-lg-5 col-sm-3">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <h6>Type Lookup</h6>
                            <ul class="typeLookup_list">
                                <li v-for = "entity in entities" >
                                    <a :class="{'active' : entity.id == activeLookup}" @click="getLookups(entity.id,entity.entity,entity.displayName); activeLookup = entity.id; activeDisplayName = entity.displayName; ">{{entity.displayName}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="goalType" class="typeLookup active">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{activeDisplayName}}
                                                </span>
                                                <span v-if="elements.editMode">Edit</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-add" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="duration">Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.typeSetting.type.$error }">
                                                                <b-form-input id="title" v-model="typeSetting.type" placeholder="Enter Type"></b-form-input>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.typeSetting.type.$error && !$v.typeSetting.type.required" class="text-danger">Please Enter Type</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="typeSetting.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5 pull-right">
                                                        <label for="add" style="visibility: hidden;">Add</label>
                                                        <div class="input-group float-right ">
                                                            <button type="submit" title="Save" @click="saveTypeData" class="btn btn-primary mb-0 mr-2 py-1 px-3">
                                                                <i class="fa fa-plus pr-2" v-if="!elements.editMode"></i>
                                                                <i class="fa fa-floppy-o pr-2" v-else ></i>
                                                                {{ elements.editMode ? 'Save' : 'Add'}}
                                                            </button>
                                                            <button type="submit" title="Clear" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table show-empty small stacked="md" sort-icon-left :items="typeSettings" :fields="fields" responsive="sm" :current-page="typeSetting.currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox  v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit Types" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteTypeLookup(data.item.id)" data-toggle="modal" data-target="#deleteTypeLookup"><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="">
                                        <div class="text-center">No {{activeDisplayName}} found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="">
                                        <div class="text-center">{{ }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="typeSettings.length!=0" pills v-model="typeSetting.currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteTypeLookup" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Type ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import moment from 'moment';
export default {
    name: 'app',
    
    data() {
        return {
             elements: {
                visible: false,
                editMode: false
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: '',
            entityId: '',

            activeLookup: '',
            activeDisplayName: '',

            notEnabled: true,
            typeSetting: {
                id: '',
                typeLookupId: '',
                entityId: '',                
                entity: '',
                typeName: '',
                type: '',
                vhextAccountId: '',
                projectId: '',
                active: true,
                userId: '',
                createdById: localStorage.getItem("userId"),
            },
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            entities: [],
            typeSettings: [],
            fields: [
                { key: 'index', label: 'SN', },
                // { key: 'typeLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'entityId', label: 'Entity Id', sortable: true, sortDirection: 'desc' },
                { key: 'typeName', label: 'Type Name', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            search: {
                params: {
                    title: ""
                }
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    validations: {
        typeSetting: {
            type: {
                required
            }
        }
    },
    // watch: {
    //     'entity': {
    //         handler: function(val1, val2) {
    //             this.getTypeLookups();
    //         },
    //         deep: true,
    //         immediate: true
    //     },
    // },
    mounted() {
        this.getEntities();
    },
    methods: {
        getEntities: function() {
            let data = {                                                                                                    
                // 1 - Database Table
                // 2 - Master Record
                // 3 - Type
                // 4 - Category
                // 5 - Status
                // 6 - Priority
                // 7 - Severity
                // 8 - Scope
                entityTypeId: 3
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/entity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.entities = result.entityList;
                    if (this.entities.length > 0) {
                        let entityId = this.entities[0].id;
                        this.entity = this.entities[0].entity;
                        this.activeLookup = this.entities[0].id;
                        this.activeDisplayName = this.entities[0].displayName;
                        this.getTypeLookups(entityId);
                    }
                    console.log(this.entities.length)
                    
                    this.totalRows = this.entities.length;
                    console.log(this.entities);
                })
        },
        getLookups: function(entityId,entity,displayName) {
            if (entityId != '') {
                this.entityId = entityId;
                this.entity = entity;
                this.activeDisplayName = displayName;
                // this.typeSetting.typeName = displayName;
                console.log(entityId,entity,this.activeDisplayName);
                this.getTypeLookups(entityId);
            }
        },

        getTypeLookups: function(entityId) {
            if (entityId != '') {
                this.entityId = entityId;
                let data = {
                    entityId: entityId
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/type/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.typeSettings = result.typeLookupList;         
                        if (this.typeSettings != null)
                            this.totalRows = this.typeSettings.length;
                    })
            }
        },
        saveTypeData: function() {
            this.$v.typeSetting.$touch();
            if (!this.$v.typeSetting.$invalid) {
                if (this.elements.editMode) {
                    this.updateTypeLookup();
                } else {
                    this.addTypeLookup();
                }
            }
        },
        addTypeLookup: function() {
            this.typeSetting.vhextAccountId = this.vhextAccountId;
            this.typeSetting.projectId = this.projectId;
            this.typeSetting.entityId = this.entityId;
            this.typeSetting.entity = this.entity;
            this.typeSetting.typeName = this.entity;

            console.log(this.typeSetting);
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/type/add', this.typeSetting)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getTypeLookups(this.entityId);
                    }
                })
        },
        updateTypeLookup: function() {
            console.log(this.typeSetting)
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/type/update', this.typeSetting)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getTypeLookups(this.entityId);
                    }
                })
        },
        deleteTypeLookup: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            console.log(this.typeSetting)
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/type/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    console.log(result);
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getTypeLookups(this.entityId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.typeSetting.entity = 'type_lookup';
                this.typeSetting.id = item.id;
                this.typeSetting.active = 0;

                this.updateActivationState();

            } else {
                this.typeSetting.entity = 'type_lookup';
                this.typeSetting.id = item.id;
                this.typeSetting.active = 1;

                this.updateActivationState();
            }
            console.log(this.typeSetting.id, this.typeSetting.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.typeSetting)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.typeSetting.active = true;
                    this.getTypeLookups(this.entityId);
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

        clearData: function() {
            this.typeSetting.type = '';
            this.$v.typeSetting.$reset();
            this.elements.editMode = false;
            this.elements.visible = false;
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.typeSetting.id = data.id;
            this.typeSetting.typeLookupId = data.typeLookupId;
            this.typeSetting.active = data.active;
            this.typeSetting.type = data.type;
            this.typeSetting.typeName = data.typeName;
            this.typeSetting.vhextAccountId = data.vhextAccountId;
            this.typeSetting.projectId = data.projectId;
            this.typeSetting.entityId = data.entityId;
            this.typeSetting.entity = data.entity;
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        deleteProject: function(id) {

        },
        showTab: function() {
            console.log(this);
        }
    }
}
</script>