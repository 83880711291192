<template>
    <div class="checklistPopup">
        <b-modal id="checklistPopup" ref="checklistPopupRef" size="lg" modal-class="checklistPopupRef" @hide="closeDialog" hide-header centered>
            <button type="button" @click="$bvModal.hide('checklistPopup');" aria-label="Close" class="close position-absolute" style="right: 25px;">×</button>
            <b-tabs v-model="activeTab" content-class="mt-4 pt-2 px-3">
                <b-tab>
                    <template #title>
                        <h5 class="p-0 m-0">Checklist</h5>
                    </template>
                    <div class="row mb-2">
                        <div class="col-12">
                            <div class="form-group">
                                <div v-for="checklist,index in checklistArray" class="form-check hover-controls mx-4 py-1">
                                    <input type="checkbox" v-model="checklist.state" disabled :checked="false" class="form-check-input" id="checkbox-1">
                                    <input type="text" :id="'checklist'+(index)" :tabindex="index" class="no-border-textarea w-80" v-model="checklist.checklist" @input="addChecklist(index)" @keyup.enter="focusNextChecklist(index)" placeholder="Enter Checklist Item...">
                                    <a v-if="!(index == (checklistArray.length-1) && checklist.checklist=='')" class="hover-btn" href="javascript:" @click="removeChecklist(index)" title="Remove"><i class="fa fa-times-circle fa-lg float-right mt-1" aria-hidden="true"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <h5 class="p-0 m-0">Existing Checklist</h5>
                    </template>
                    <div>
                        <div class="float-right">
                            <div class="dataTables_length">
                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                        <b-table show-empty small stacked="md" sort-icon-left :items="checklistList" :fields="checklistFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            <template v-slot:cell(active)="data">
                                <b-form-checkbox class="ml-3" v-model="data.value" disabled>
                                </b-form-checkbox>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <button type="submit" v-b-modal.checklistView class="sbtn btn btn-xs btn-outline-primary px-2" @click="getChecklist(data.item.id);checklistTitle=data.item.title">Show Checklist</button>
                                <!-- <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" title="Delete" @click="deleteChecklist(data.item.id)" data-toggle="modal" data-target="#deleteChecklist"><i class="fa fa-trash pl-2"></i></a> -->
                            </template>
                            <template v-slot:empty="scope">
                                <div class="text-center">No Checklist found</div>
                            </template>
                            <template v-slot:emptyfiltered="scope">
                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                            </template>
                        </b-table>
                        <b-pagination v-if="checklistList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                    </div>
                </b-tab>
            </b-tabs>
            <b-modal id="checklistView" ref="checklistViewRef" size="md" :title="checklistTitle" ok-title="Add Checklist" button-size="xs" header-class="py-3" footer-class="py-3" @ok="selectExistingChecklist" centered>
                <b-overlay :show="showDetOverlay" spinner-variant="primary" variant="white" opacity="1" rounded="sm" >
                    <div class="row">
                        <div class="col-12">
                            <div v-for="checklistDetail in checklistDetailArray" class="form-check mx-3">
                                <input type="checkbox" disabled :checked="true" class="form-check-input" id="checkbox-1">
                                <input type="text" v-model="checklistDetail.checklist" class="no-border-textarea">
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </b-modal>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <div v-if="activeTab==0">
                        <b-button variant="primary" size="sm" @click="createChecklistArray" class="float-right mr-2">
                            <span>Save Checklist</span>
                        </b-button>
                        <b-button variant="secondary" size="sm" class="float-right mr-2" @click="clearData();">Clear</b-button>
                    </div>
                    <b-button v-else variant="primary" size="sm" class="float-right mr-2" @click="cancel();">Close</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            showDetOverlay: false,
            activeTab: 0,
            checklistTitle: 'Checklist',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            typeCode: 'CHL',

            checklist: {
                id: '',
                groupHeaderId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                typeCode: 'CHL',
                title: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                active: true,
                checklistDataList: [],
            },

            checklistList: [],
            checklistFields: [
                { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Checklist', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            checklistDetailArray: [],

            checklistArray: [{ checklist: '', state: false }],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    // validations: {
    //     checklist: {
    //         title: {
    //             required
    //         }
    //     },
    // },
    mounted() {
        this.getChecklistGroup();
    },
    methods: {
        getChecklistGroup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                typeCode: 'CHL',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/groupheader/list', data)
                .then((response) => {
                    let result = response.data;
                    this.checklistList = result.groupHeaderList;
                    this.totalRows = this.checklistList.length;
                })
        },
        loadChecklist: function (checklistArray) {
            let parsedArray = JSON.parse(checklistArray);
            if (parsedArray.length>0) {
                let emptyArray = [{ checklist: '', state: false }];
                this.checklistArray = parsedArray.concat(emptyArray);
            }
        },
        getChecklist: function(groupHeaderId) {
            let data = {
                groupHeaderId: groupHeaderId,
            }
            this.checklistDetailArray = [{ checklist: '', state: false }];
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/quality/checklist/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.checklistList.length > 0) {
                        this.checklistDetailArray = result.checklistList;
                    }
                    // else this.checklistDetailArray = [{ checklist: '' }];
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addChecklist: function(index) {
            if (index == (this.checklistArray.length - 1)) {
                this.checklistArray.push({ checklist: '', state: false });
            }
        },
        focusNextChecklist(index) {
            let elementId = 'checklist' + (index+1);
            console.log(elementId)
            let element = document.getElementById(elementId);

            if(element) {
                element.focus();
            }
            else this.addChecklist(index);
        },
        removeChecklist: function (index) {
            this.checklistArray = this.checklistArray.filter((data, arrIndex) => index != arrIndex);
        },
        clearData: function() {
            this.checklistArray = [{ checklist: '' }];
            this.activeTab = 0;
            // this.$v.checklist.$reset();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        createChecklistArray: function() {
            let checklistArray = this.checklistArray.filter((data,i,arr) => i !== (arr.length-1) && data.checklist != '');
            this.saveChecklist(checklistArray);
        },
        saveChecklist(checklistArray) {
            this.$emit('addChecklistList', JSON.stringify(checklistArray));
            this.$bvModal.hide('checklistPopup');
        },
        selectExistingChecklist: function() {
            let emptyArray = [];
            let checklistArray = this.checklistDetailArray.map(({checklist}) => ({...emptyArray, checklist, state: false}));
            this.saveChecklist(checklistArray);
        },
        closeDialog() {
            this.clearData();
            this.$emit('closeDialog');
        },
    }
}
</script>