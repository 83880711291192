<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc no-enforce-focus @hide="closeDialog" id="calEventSettingPopup" ref="addCalEventRef" title="Calendar Setting" size="xl" hide-footer header-class="pt-3 pb-2" body-class="mb-2 pb-3 pt-1">

			<b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
				<!-- <button type="button" @click="$bvModal.hide('calEventSettingPopup');" aria-label="Close" class="close position-absolute" style="right: 25px;">×</button> -->
	        	<b-tabs content-class="mt-2" v-model="activeTabIndex" lazy>
	                <b-tab title="Manage Weekend">
	                    <!-- <template #title>
	                        <h5 class="p-0 m-0">Manage Weekend</h5>
	                    </template> -->
		            	<div class="card p-3 mb-4">
			            	<p class="mb-1">Current Weekend Setting</p>
			            	<div class="separator mb-2"></div>
			                <b-row v-if="weekendStartDate" class="my-2">
			                    <b-col sm="3" md="2" class="my-auto pr-0">
			                        <label class="my-auto">Period :</label>
			                    </b-col>
			                    <b-col sm="9" md="10" lg="10">
			                        <label class="my-auto">{{ getMonthYearAlt(weekendStartDate) }} <span class="px-2">to</span> {{ getMonthYearAlt(weekendEndDate) }}</label>
			                    </b-col>
			                </b-row>
			                <b-row v-if="weekendStartDate" class="mt-3">
			                	<b-col sm="3" md="2" class="mt-0 pr-0">
			                        <label class="my-auto" for="eventTypeId">Weekend :</label>
			                    </b-col>
			                    <b-col sm="9">
			                    	<label class="my-auto" v-for="(day,index) in weekendDays" :key="index">
			                    		<span v-if="index!==0">, </span>{{ day }}
			                    	</label>
			                    </b-col>
			                </b-row>
			                <b-row v-else class="text-center">
			                	<b-col>
					                <div class="my-3">No Weekend configured for current period</div>
					            </b-col>
					        </b-row>
		            	</div>
	                    <b-card no-body class="mb-4">
                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-wkEnd>
                                    <span v-if="elements.editModeWkEnd"><i class="fa fa-pencil pr-2 action-link"></i>Edit Weekend</span>
                                    <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Weekend</span>
                                </label>
                            </b-card-header>
                            <b-collapse id="accordion-wkEnd" accordion="my-accordion-wkEnd" v-model="elements.visibleWkEnd" role="tabpanel">
                                <b-card-body class="p-3">
					                <b-row class="my-2">
					                    <b-col sm="3" md="2" class="my-auto pr-0">
					                        <label class="my-auto" for="month">Set Period</label>
					                    </b-col>
					                    <b-col sm="4" md="4" lg="3">
					                        <div class="required-field-block" :class="{ 'is-invalid': $v.weekendData.eventStartDate.$error }">
						                        <input id="month" type="month" name="month" v-model="weekendData.eventStartDate" :class="{ 'is-invalid': $v.weekendData.eventStartDate.$error }" placeholder="YYYY-MM" required pattern="[0-9]{4}-[0-9]{2}" class="form-control">
				                                <div class="required-icon"></div>
				                            </div>
				                            <label v-if="$v.weekendData.eventStartDate.$error && !$v.weekendData.eventStartDate.required" class="text-danger">Please select start period</label>
					                    </b-col>
					                    <b-col sm="1" md="auto" class="p-0 my-auto text-center">
					                        -
					                    </b-col>
					                    <b-col sm="4" md="4" lg="3">
					                    	<div class="required-field-block" :class="{ 'is-invalid': $v.weekendData.eventEndDate.$error }">
						                        <input id="month" type="month" name="month" v-model="weekendData.eventEndDate" :class="{ 'is-invalid': $v.weekendData.eventEndDate.$error }" placeholder="YYYY-MM" :min="weekendData.eventStartDate" required pattern="[0-9]{4}-[0-9]{2}" class="form-control">
				                                <div class="required-icon"></div>
				                            </div>
				                            <label v-if="$v.weekendData.eventEndDate.$error && !$v.weekendData.eventEndDate.required" class="text-danger">Please select end period</label>
					                    </b-col>
					                </b-row>
					                <b-row class="mt-3">
					                	<b-col sm="3" md="2" class="mt-0 pr-0">
					                        <label class="my-auto" for="eventTypeId">Set Weekend</label>
					                    </b-col>
					                    <b-col sm="9">
					                    	<b-form-group label="" v-slot="{ ariaDescribedby }">
										      <b-form-checkbox-group
										        id="checkbox-group-1"
										        v-model="weekendData.daysToRepeat"
										        :options="days"
										        :aria-describedby="ariaDescribedby"
										        name="flavour-1"
										      ></b-form-checkbox-group>
										    </b-form-group>
										    <label v-if="$v.weekendData.daysToRepeat.$error && !$v.weekendData.daysToRepeat.required" class="text-danger">Please select at least one day</label>
					                    </b-col>
					                </b-row>
					                <div class="text-right mb-4">
						                <button type="submit" @click="saveWeekendData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
						                <button type="submit" @click="clearDataWkEnd" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Cancel</button>
					                </div>
					            </b-card-body>
					        </b-collapse>
					    </b-card>
	                    <div class="separator mb-3"></div>
			            <div class="row view-filter pb-1">
	                    	<div class="col-sm-4">
	                    		<h4 class="pl-2 my-1 fs-16">Weekend Setting</h4>
	                    	</div>
	                        <div class="col-sm-8">
	                            <div class="float-right">
	                                <div class="dataTables_length">
	                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
	                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
	                                    </b-form-group>
	                                </div>
	                            </div>
	                            <div class="clearfix"></div>
	                        </div>
	                    </div>
	                    <b-table show-empty small stacked="md" sort-icon-left :items="weekendList" :fields="fields" responsive="sm" :current-page="currentPageWkEnd" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredWkEnd">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>       

	                        <template v-slot:cell(period)="data">
	                        	<span>{{ data.item.startRecur | formatMonthYear  }} to {{ data.item.endRecur | formatMonthYear }}</span>
	                        </template>

	                        <template v-slot:cell(daysOfWeek)="data">
	                        	<span v-for="day,index in (data.item.daysOfWeek)"><span v-if="index!==0">, </span>{{ days[day] }}</span>
	                        </template>
	                        <template v-slot:cell(actions)="data">
	                            <a href="javascript:" title="Edit" @click="showEditWkEnd(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
	                            <a href="javascript:" title="Delete" @click="deleteCalendarEvent(data.item.id)" v-b-modal.deleteCalendarEvent><i class="fa fa-trash pl-2"></i></a>
	                        </template>
	                        <template v-slot:empty="scope">
	                            <div class="text-center">No Weekend Added</div>
	                        </template>
	                        <template v-slot:emptyfiltered="scope">
	                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
	                        </template>
	                    </b-table>
                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsWkEnd}} Items</span>                            	                    
	                    <b-pagination v-if="events.length!=0" pills v-model="currentPageWkEnd" :total-rows="totalRowsWkEnd" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
	                </b-tab>
	                <b-tab title="Manage Holidays">
	                    <!-- <template #title>
	                        <h5 class="p-0 m-0">Manage Holidays</h5>
	                    </template> -->
	                	<b-card no-body class="mb-4">
                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-hol>
                                    <span v-if="elements.editMode"><i class="fa fa-pencil pr-2 action-link"></i>Edit Holiday</span>
                                    <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Holiday</span>
                                </label>
                            </b-card-header>
                            <b-collapse id="accordion-hol" accordion="my-accordion-hol" v-model="elements.visibleHol" role="tabpanel">
                                <b-card-body class="p-3">
					                <b-row class="mb-1">
		<!-- 			                	<b-col sm="8" class="">
					                	</b-col> -->
					                    <b-col sm="2" class="my-auto">
					                        <label class="my-auto" for="title">Calendar Year</label>
					                    </b-col>			                	
					                    <b-col sm="4" class="">
											<select v-model="calendarSetting.calendarYear" @change="filterData.calendarYear = calendarSetting.calendarYear;" class="form-control" id="calendarYear">
											  <option v-for="year in yearArray()" :value="year">{{ year }}</option>
											</select>			                    	
				                        </b-col>
									</b-row>	                		
					                <b-row class="mb-1">
					                    <b-col sm="2" class="my-auto">
					                        <label class="my-auto" for="title">Holiday Name</label>
					                    </b-col>
					                    <b-col sm="4">
					                        <div v-if="!calendarSetting.viewMode">
					                            <div class="required-field-block" :class="{ 'is-invalid': $v.calendarSetting.title.$error }">
					                                <b-form-input id="title" v-model="calendarSetting.title" :class="{ 'is-invalid': $v.calendarSetting.title.$error }" placeholder="Enter Holiday Name"></b-form-input>
					                                <div class="required-icon"></div>
					                            </div>
					                            <label v-if="$v.calendarSetting.title.$error && !$v.calendarSetting.title.minLength" class="text-danger">Name must have at least {{$v.calendarSetting.title.$params.minLength.min}} letters.</label>
					                            <label v-if="$v.calendarSetting.title.$error && !$v.calendarSetting.title.required" class="text-danger">Please enter Holiday Name</label>
					                            <label v-if="$v.calendarSetting.title.$error && !$v.calendarSetting.title.maxLength" class="text-danger">Name must not exceed {{$v.calendarSetting.title.$params.maxLength.max}} letters.</label>
					                        </div>
					                        <b-form-input v-if="calendarSetting.viewMode" id="title" disabled v-model="calendarSetting.title" placeholder="Enter Holiday Name"></b-form-input>
					                    </b-col>
					                    <b-col sm="2" class="my-auto text-right">
					                        <label class="my-auto" for="title">Holiday Type</label>
					                    </b-col>
					                    <b-col sm="4" class="">
					                    	<div class="row no-gutters">
					                    		<div class="col-11">
							                        <b-form-input v-if="calendarSetting.viewMode" id="holidayType" disabled v-model="calendarSetting.holidayType"></b-form-input>
							                        <div v-else>
						                                <select v-model="calendarSetting.holidayTypeId" class="form-control" id="holidayTypeId" >
				                                            <option value="">Select</option>
				                                            <option v-for="(info, index) in holidayTypes" :value="info.id" :key="index">{{info.value}}</option>
				                                        </select>
							                        </div>
					                    		</div>
					                    		<div class="col">
				                                    <a :title="holidayTypeLookupTitle" class="action-link w-100" href="javascript:" @click="holidayTypePopupKey++" v-b-modal.holidayTypeLookup><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
					                    		</div>
					                    	</div>
					                    </b-col>
					                </b-row>
					                <b-row class="my-2">
					                    <b-col sm="2" class="my-auto pr-0">
					                        <label class="my-auto" for="eventTypeId">Occurrence</label>
					                    </b-col>
					                    <b-col sm="4">
					                        <select :disabled="calendarSetting.viewMode" v-model="calendarSetting.eventFrequency" class="form-control" id="eventTypeId">
					                            <option value="0">Single Day</option>
					                            <option value="1">Multiple Days</option>
					                        </select>
					                    </b-col>
					                    <b-col sm="6" class="text-center">
					                        <b-form-checkbox class="custom-toggle pt-1" id="isAllDayEvent" :disabled="calendarSetting.viewMode" v-model="calendarSetting.isAllDayEvent" name="check-button" switch>All Day</b-form-checkbox>
					                        <!-- <label class="my-auto" for="isAllDayEvent">All Day</label> -->
					                    </b-col>
					                    <!-- <b-col sm="2" class="my-auto float-right">
					                    </b-col> -->
					                </b-row>
					                <div v-if="calendarSetting.eventFrequency == 0">
					                    <b-row class="my-2">
					                        <b-col sm="2" class="my-auto">
					                            <label class="my-auto" for="eventStartTime">Date</label>
					                        </b-col>
					                        <b-col sm="4">
					                            <input type="date" class="form-control" v-if="!calendarSetting.viewMode" v-model="calendarSetting.eventStartDate" id="eventStartTime">
					                            <template v-if="calendarSetting.viewMode">                                            
					                                <input v-if="calendarSetting.isAllDayEvent" type="text" class="form-control" disabled :value="calendarSetting.eventStartDate | formatDate">
					                                <input v-else type="text" class="form-control" disabled :value="calendarSetting.eventStartDate | formatDate">
					                            </template>
					                        </b-col>
					                    </b-row>
					                </div>
					                <div v-else>
					                    <b-row class="my-2">
					                        <b-col sm="2" class="my-auto">
					                            <label class="my-auto" for="eventStartTime">Date</label>
					                        </b-col>
					                        <b-col sm="4" class="pr-0">
					                            <input type="date" class="form-control" v-if="!calendarSetting.viewMode" v-model="calendarSetting.eventStartDate" id="eventStartTime">
					                            <template v-if="calendarSetting.viewMode">                                            
					                                <input v-if="calendarSetting.isAllDayEvent" type="text" class="form-control" disabled :value="calendarSetting.eventStartDate | formatDate">
					                                <input v-else type="text" class="form-control" disabled :value="calendarSetting.eventStartDate | formatDate">
					                            </template>
					                        </b-col>
					                        <b-col class="px-0 my-auto text-center">
						                        <label class="my-auto" for="eventEndTime">to</label>
						                    </b-col>
						                    <b-col sm="4" class="pl-0">
					                            <input type="date" class="form-control" v-if="!calendarSetting.viewMode" v-model="calendarSetting.eventEndDate" id="eventEndDate">
					                            <template v-if="calendarSetting.viewMode">                                            
					                                <input v-if="calendarSetting.isAllDayEvent" type="text" class="form-control" disabled :value="calendarSetting.eventEndDate | formatDate">
					                                <input v-else type="text" class="form-control" disabled :value="calendarSetting.eventEndDate | formatDate">
					                            </template>
					                        </b-col>
					                    </b-row>
					                </div>
					                <b-row v-if="!calendarSetting.isAllDayEvent" class="my-2">
					                    <b-col sm="2" class="my-auto">
					                        <label class="my-auto" for="eventStartTime">Time</label>
					                    </b-col>
					                    <b-col sm="4" class="pr-0">
					                        <input type="time" class="form-control" v-if="!calendarSetting.viewMode" v-model="calendarSetting.eventStartTime" id="eventStartTime">
					                        <input type="text" class="form-control" disabled v-if="calendarSetting.viewMode" :value="calendarSetting.eventStartTime | formatTimeFromHM">
					                    </b-col>
					                    <b-col class="px-0 my-auto text-center">
					                        <label class="my-auto" for="eventEndTime">to</label>
					                    </b-col>
					                    <b-col sm="4" class="pl-0">
					                        <input type="time" class="form-control" v-if="!calendarSetting.viewMode" v-model="calendarSetting.eventEndTime" id="eventEndTime">
					                        <input type="text" class="form-control" disabled v-if="calendarSetting.viewMode" :value="calendarSetting.eventEndTime | formatTimeFromHM">
					                    </b-col>
					                </b-row>
					                <b-row class="my-2">
					                    <b-col sm="2" class="">
					                        <label class="my-auto" for="description">Description</label>
					                    </b-col>
					                    <b-col sm="10">
					                        <textarea class="form-control" rows="3" v-model="calendarSetting.description" maxlength="2000" id="description"></textarea>
					                    </b-col>
					                </b-row>
						            <div class="text-right my-4">
						                <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
			                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
					                </div>
					            </b-card-body>
					        </b-collapse>
					    </b-card>
	                    <!-- <div class="separator mb-3"></div> -->
	                    <div class="row view-filter">
	                    	<div class="col-sm-4">
	                    		<h4 class="pl-2 my-1 fs-16">Holidays<span v-if="filterData.calendarYear">  {{ filterData.calendarYear }}</span></h4>
	                    	</div>
	                    	<div class="col-sm-3 pb-1">
	                    		<select v-model="filterData.calendarYear" class="form-control" id="jobCategoryId" >
                                    <option v-for="year in yearArray()" :value="year">{{ year }}</option>
                                </select>
	                    	</div>
	                        <div class="col">
	                            <div class="float-right">
	                                <div class="dataTables_length">
	                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
	                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
	                                    </b-form-group>
	                                </div>
	                            </div>
	                            <div class="clearfix"></div>
	                        </div>
	                    </div>
	                    <b-table show-empty small stacked="md" sort-icon-left :items="holidayList" :fields="calendarHolidayfields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>                            

	                        <template v-slot:cell(startDate)="data">
	                        	<template v-if="data.item.start">
	                                <div v-if="data.item.allDay && data.item.end">{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div>
	                                <div v-else-if="data.item.allDay">{{ data.item.start | formatDate }} , {{ data.item.start | formatDay }}</div>
	                                <div v-else>{{ data.item.start | formatDateTimeAmPm }} - {{ data.item.end | formatDateTimeAmPm }}</div>
	                        	</template>
	                        	<template v-else-if="data.item.startRecur">
	                                <div v-if="data.item.allDay && data.item.daysOfWeek">
	                                	<span v-for="day,index in (data.item.daysOfWeek)"><span v-if="index!==0">,</span> {{ days[day] }}</span>
	                                    ({{ data.item.startRecur | formatMonthYear }} - {{ data.item.endRecur | formatMonthYear }})
	                                </div>
	                        	</template>
	                        	<template v-else>
	                        		<div>Weekend</div>
	                        	</template>
	                        </template>
	                        <template v-slot:cell(actions)="data">
	                            <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
	                            <a href="javascript:" title="Delete" @click="deleteCalendarEvent(data.item.id)" v-b-modal.deleteCalendarEvent><i class="fa fa-trash pl-2"></i></a>
	                        </template>
	                        <template v-slot:empty="scope">
	                            <div class="text-center">No Holidays Added</div>
	                        </template>
	                        <template v-slot:emptyfiltered="scope">
	                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
	                        </template>
	                    </b-table>

                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            	                    	                    
	                    <b-pagination v-if="events.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
	                </b-tab>
	                <b-tab >
	                    <template #title>
	                        <label class="p-0 m-0">Leave Policy</label>
	                    </template>
                        <b-card no-body class="mb-4">
                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-lpc>
                                    <span v-if="elements.editModeLpc"><i class="fa fa-pencil pr-2 action-link"></i>Edit Leave Policy</span>
                                    <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Leave Policy</span>
                                </label>
                            </b-card-header>
                            <b-collapse id="accordion-lpc" accordion="my-accordion-lpc" v-model="elements.visibleLpc" role="tabpanel">
                                <b-card-body class="p-3">
				                	<!-- <b-container> -->
						                <b-row class="mb-1">
						                    <b-col sm="6" md="4" lg="2" class="my-auto pr-0">
						                        <label class="my-auto" for="leaveTypeId">Leave Type</label>
						                    </b-col>
						                    <b-col sm="6" md>
						                    	<div class="row no-gutters">
						                    		<div class="col-11">
							                            <div class="required-field-block" :class="{ 'is-invalid': $v.leavePolicy.leaveTypeId.$error }">
							                                <select v-model="leavePolicy.leaveTypeId" class="form-control" :class="{ 'is-invalid': $v.leavePolicy.leaveTypeId.$error }" id="leaveTypeId" >
					                                            <option value="">Select</option>
					                                            <option v-for="(info, index) in leaveTypes" :value="info.id" :key="index">{{info.value}}</option>
					                                        </select>
							                                <div class="required-icon"></div>
							                            </div>
							                            <label v-if="$v.leavePolicy.leaveTypeId.$error && !$v.leavePolicy.leaveTypeId.required" class="text-danger">Please select Leave Type</label>
						                    		</div>
						                    		<div class="col">
						                                <a :title="leaveTypeLookupTitle" class="action-link" href="javascript:" @click="leaveTypePopupKey++" v-b-modal.leaveTypeLookup><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
						                    		</div>
						                    	</div>
						                    </b-col>
						                    <b-col sm="6" md="4" lg="2" class="my-auto pr-0 text-right">
						                        <label class="my-auto" for="jobCategoryId">Job Category</label>
						                    </b-col>
						                    <b-col sm="6" md>
						                    	<div class="row no-gutters">
						                    		<div class="col-11">
							                            <!-- <div class="required-field-block" :class="{ 'is-invalid': $v.leavePolicy.jobCategoryId.$error }"> -->
							                                <select v-model="leavePolicy.jobCategoryId" @change="filterData.jobCategoryId=leavePolicy.jobCategoryId;" class="form-control" id="jobCategoryId" >
					                                            <option value="">Select</option>
					                                            <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
					                                        </select>
							                                <div class="required-icon"></div>
							                            <!-- </div> -->
							                            <!-- <label v-if="$v.leavePolicy.jobCategoryId.$error && !$v.leavePolicy.jobCategoryId.required" class="text-danger">Please select Job Category</label> -->
						                    		</div>
						                    		<div class="col">
						                                <a :title="categoryLookupTitle" class="action-link" href="javascript:" @click="jobCategoryPopupKey++" v-b-modal.categoryModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
						                    		</div>
						                    	</div>
						                    </b-col>
						                </b-row>
						                <b-row class="my-2">
						                    <b-col sm="6" md="4" lg="2" class="my-auto pr-0">
						                        <label class="my-auto" for="daysPerYear">Leaves Per Year</label>
						                    </b-col>
						                    <b-col sm="6" md="4">
						                        <input type="number" class="form-control" v-model="leavePolicy.daysPerYear" id="daysPerYear">
						                    </b-col>
						                    <b-col sm="6" md="4" lg="2" class="my-auto pr-0 text-right">
						                        <label class="my-auto" for="daysPerMonth">Leaves Per Month</label>
						                    </b-col>
						                    <b-col sm="6" md>
						                        <input type="number" class="form-control" v-model="leavePolicy.daysPerMonth" id="daysPerMonth">
						                    </b-col>
						                </b-row>
						                <b-row>
					                        <div class="col-12">
					                            <div class="card-body p-0 my-0">
					                                <b-card no-body>
					                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
					                                        <label class="d-block p-1 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-taskPopup>
					                                            <label class="mb-1 pt-1">Description</label>
					                                        </label>
					                                    </b-card-header>
					                                    <b-collapse id="accordion-taskPopup" v-model="elements.visibleDescription" accordion="my-accordion1" role="tabpanel">
					                                        <b-card-body class="p-0 pb-0">
					                                            <div class="row">
					                                                <div class="col-12">
					                                                    <div class="form-group">
					                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="leavePolicy.description"></editor>
					                                                    </div>
					                                                </div>
					                                            </div>
					                                        </b-card-body>
					                                    </b-collapse>
					                                </b-card>
					                            </div>
					                        </div>
					                    </b-row>
							            <div class="text-right mt-4">
							                <button type="submit" @click="saveDataLpc" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
				                            <button type="submit" @click="clearDataLeavePolicy" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
						                </div>
						            <!-- </b-container> -->
						        </b-card-body>
						    </b-collapse>
						</b-card>
	                    <!-- <div class="separator mb-3"></div> -->
	                    <div class="row view-filter pb-1">
	                    	<div class="col-sm-4">
	                    		<h4 class="pl-2 my-1 fs-16">Leave Policy <span v-if="jobCategory">- {{ jobCategory }}</span></h4>
	                    	</div>
	                    	<div class="col-sm-3">
	                    		<select v-model="filterData.jobCategoryId" class="form-control" id="jobCategoryId" >
                                    <option value="">Select Job Category</option>
                                    <option v-for="(info, index) in jobCategories" :value="info.id" :key="index">{{info.value}}</option>
                                </select>
	                    	</div>
	                        <div class="col">
	                            <div class="float-right">
	                                <div class="dataTables_length">
	                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
	                                        <b-form-select v-model="perPageLpc" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
	                                    </b-form-group>
	                                </div>
	                            </div>
	                            <div class="clearfix"></div>
	                        </div>
	                    </div>
	                    <b-table show-empty outlined small stacked="md" sort-icon-left :items="filteredLeavePolicyList" :fields="leavePolicyfields" responsive="sm" :current-page="currentPageLpc" :per-page="perPageLpc" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredLpc">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>
	                        <template v-slot:cell(actions)="data">
	                            <a href="javascript:" title="Edit" @click="showEditLeavePolicy(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
	                            <a href="javascript:" title="Delete" @click="deleteLeavePolicy(data.item.id)" v-b-modal.deleteLeavePolicy><i class="fa fa-trash pl-2"></i></a>
	                        </template>
	                        <template v-slot:empty="scope">
	                            <div class="text-center">No Leave Policies found</div>
	                        </template>
	                        <template v-slot:emptyfiltered="scope">
	                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
	                        </template>
	                    </b-table>
                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsLpc}} Items</span>
	                    <b-pagination v-if="filteredLeavePolicyList.length!=0" pills v-model="currentPageLpc" :total-rows="totalRowsLpc" :per-page="perPageLpc" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
	                </b-tab>	                            
	            </b-tabs>
	        </b-overlay>
        </b-modal>
        <TypeLookup id="holidayTypeLookup" :key="holidayTypePopupKey" ref="typeLookupModal" :entity="holidayTypeEntity" :typeLookupTitle="holidayTypeLookupTitle" @refresh-lookup="lookupData" />
        <TypeLookup id="leaveTypeLookup" :key="leaveTypePopupKey" ref="typeLookupModal" :entity="leaveTypeEntity" :typeLookupTitle="leaveTypeLookupTitle" @refresh-lookup="lookupData" />
        <CategoryLookup ref="categoryLookupModal" :entity="this.categoryEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteCalendarEvent" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Event?" />
        <DeleteComponent id="deleteLeavePolicy" :onYes="onYesLeavePolicy" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Leave Policy?" />
    </div>
</template>

<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue'
import Editor from '@tinymce/tinymce-vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'

export default {
    components: {
    	CategoryLookup,
    	Editor,
    	ERMMasterPopup,
    	TypeLookup,
    },
    data() {
        return {
        	showDialog: false,
        	elements: {
        		visibleHol: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false,
        		visibleWkEnd: false,
        		editModeWkEnd: false,
        		visibleLpc: false,
        		editModeLpc: false,
        		visibleDescription: false,
        	},
            showOverlay: false,
            activeTabIndex: 0,

            holidayTypeEntity: 'holiday_type',
            holidayTypeLookupTitle: 'Holiday Type Settings',
            holidayTypePopupKey: 0,
            leaveTypeEntity: 'leave_type',
            leaveTypeLookupTitle: 'Leave Type Settings',
            leaveTypePopupKey: 1000,

            categoryEntity: 'job_category',
            categoryLookupTitle: 'Job Category Settings',
            jobCategoryPopupKey: 2000,

            minPeriod: '',
            // calendarSetting: this.calendarSettingData,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: "calendar_event",


            filterData: {
            	jobCategoryId: '',
	            calendarYear: new Date().getFullYear(),
            },

            calendarSetting: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                calendarYear: new Date().getFullYear(),
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                calendarTypeId: 1,
                categoryCode: '',
                categoryName: 'calendar_category',
                eventTypeId: '',
                holidayTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: true,
                daysOfWeek: [],
                daysToRepeat: [],
                description: '',
                createdById: localStorage.getItem("userId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            },

            weekendData: {
            	id: '',
                title: 'Weekend',
            	isAllDayEvent: true,
            	categoryCode: 'WKD',
            	categoryName: 'calendar_category',
            	calendarTypeId: 1,
            	createdById: localStorage.getItem("userId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                holidayTypeId: '',
                eventFrequency: 0,
            	startDate: '',
                endDate: '',
            	startTime: null,
	            endTime: null,
                eventStartDate: '',
                eventEndDate: '',
            	daysOfWeek: [],
                daysToRepeat: [],
            },

            leavePolicy: {
            	id: '',
				leavePolicyId: '',
				vhextAccountId: localStorage.getItem("vhextAccountId"),
				jobCategoryId: '',
				leaveTypeId: '',
				daysPerMonth: '',
				daysPerYear: '',
				description: '',
				createdById: localStorage.getItem("userId"),
            },

            days: dayList,
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            entityERMLookup: [],
            jobCategories: [],
            leaveTypes: [],
            holidayTypes: [],

            allEvents: [],
            fields: [
            	{ key: 'index', label: 'SN' },
                // { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'period',label: 'Period', sortable: true,sortDirection: 'desc' },
                { key: 'daysOfWeek',label: 'Weekend Day(s)', sortable: true,sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            calendarHolidayfields: [
            	{ key: 'index', label: 'SN' },
            	{ key: 'calendarYear', label: 'Year', formatter: "formatYear", sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'startDate',label: 'Date', sortable: true,sortDirection: 'desc' },
                { key: 'holidayType',label: 'Holiday Type', sortable: true,sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            leavePolicyList: [],
            leavePolicyfields: [
            	{ key: 'index', label: 'SN' },
                { key: 'jobCategory', label: 'Job Category', sortable: true, sortDirection: 'desc' },
                { key: 'leaveType',label: 'Leave Type', sortable: true, sortDirection: 'desc' },
                { key: 'daysPerYear',label: 'Leaves / Year', sortable: true,sortDirection: 'desc' },
                { key: 'daysPerMonth',label: 'Leaves / Month', sortable: true,sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            totalRowsWkEnd: 1,
            currentPageWkEnd: 1,
            totalRowsLpc: 1,
            currentPageLpc: 1,
            perPageLpc: 5,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
    	events() {
            return this.allEvents.map((event,index)=> {
                if (event.daysOfWeek && event.daysOfWeek !== null) {
                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek.split(',');
                    return event;
                }
                else {
                    return event;
                }
            })
        },
        weekendList() {
        	let weekendList = this.events.filter(event => event.categoryCode == 'WKD');
        	this.totalRowsWkEnd = weekendList.length;
        	return weekendList;
        },
        holidayList() {

        	let holidayList = this.events.filter(event => event.categoryCode == 'HOL' && (this.getYear(event.calendarYear) == this.filterData.calendarYear));
        	this.totalRows = holidayList.length;
        	return holidayList;
        },
        filteredLeavePolicyList() {
        	let filteredLeavePolicyList = this.leavePolicyList.filter(data => this.filterData.jobCategoryId ? data.jobCategoryId == this.filterData.jobCategoryId : data);
        	this.totalRowsLpc = filteredLeavePolicyList.length;
        	return filteredLeavePolicyList;
        },

        jobCategory() {
        	let jobCategory = this.jobCategories.find(data => data.id == this.filterData.jobCategoryId);
        	return jobCategory ? jobCategory.value : '';
        },
 		currentYear () {
	      	const year = new Date().getFullYear()
	      	return year
	    },
	    weekendStartDate() {
	    	let weekendStartDate = '';
	    	let currentDate = this.getDateValue(new Date());

            this.weekendList.forEach((event,index)=> {
            	if (moment(currentDate).isBetween(this.getDateValue(event.startRecur), this.getDateValue(event.endRecur))) {
            		weekendStartDate = event.startRecur;
            	}
            })
            return weekendStartDate;
        },
	    weekendEndDate() {
	    	let weekendEndDate = '';
	    	let currentDate = this.getDateValue(new Date());

            this.weekendList.forEach((event,index)=> {
            	if (moment(currentDate).isBetween(this.getDateValue(event.startRecur), this.getDateValue(event.endRecur))) {
            		weekendEndDate = event.endRecur;
            	}
            })
            return weekendEndDate;
        },
	    weekendDays() {
	    	let weekendDays = [];
	    	let currentDate = this.getDateValue(new Date());

            this.weekendList.forEach((event,index)=> {
            	if (moment(currentDate).isBetween(this.getDateValue(event.startRecur), this.getDateValue(event.endRecur))) {
	                event.daysOfWeek.forEach((day) => {
	                    weekendDays.push(this.days[day]);
	                });
            	}
            })
            return weekendDays;
        },
    },
    mounted() {
        this.getEvents();
        this.getLeavePolicies();
        this.lookupData();
    },
    validations: {
        calendarSetting: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        leavePolicy: {
            // jobCategoryId: {
            //     required,
            // },
            leaveTypeId: {
                required,
            },
        },
        weekendData: {
            eventStartDate: {
                required,
            },
            eventEndDate: {
                required,
            },
            daysToRepeat: {
                required,
            },
        },
    },
    methods: {
    	getEvents: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                calendarTypeId: 1
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/cmpcalendarsetting/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.allEvents = result.cmpCalendarSettingList;
                    // this.totalRows = this.holidayList.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLeavePolicies: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/leavepolicy/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.leavePolicyList = result.leavePolicyList;
                    this.totalRowsLpc = this.filteredLeavePolicyList.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarsetting/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.jobCategories = result.customCategoryLookup;
                    this.leaveTypes = result.customTypeLookup;
                    this.holidayTypes = result.holidayTypeLookup;
                })
        },
        saveWeekendData: function() {
        	this.$v.weekendData.$touch();
        	if (this.$v.weekendData.$invalid) {
            	return;
            }
        	this.weekendData.startTime = null;
            this.weekendData.endTime = null;
        	this.weekendData.categoryCode = 'WKD';
        	this.weekendData.isAllDayEvent = true;
        	this.weekendData.startDate = this.getDateValue(this.weekendData.eventStartDate);
        	let endYear = new Date(this.weekendData.eventEndDate).getFullYear();
        	let endMonth = new Date(this.weekendData.eventEndDate).getMonth();
        	let lastDay = this.getLastDateFromMonthYear(endYear, endMonth);
        	let endDate = new Date();
        	endDate.setFullYear(endYear, endMonth, lastDay);

            this.weekendData.endDate = this.getDateValue(endDate);
        	this.weekendData.daysOfWeek = [];
            this.weekendData.daysToRepeat.forEach((day) => {
                this.weekendData.daysOfWeek.push(this.days.indexOf(day));
            })
            this.weekendData.daysOfWeek = this.weekendData.daysOfWeek.toString();
            this.weekendData.title = 'Weekend'

            if (!this.validateDateExistsBetween(this.weekendData.startDate, this.weekendData.id) && !this.validateDateExistsBetween(this.weekendData.endDate, this.weekendData.id)) {
	            this.showOverlay = true;
	            if (this.elements.editModeWkEnd) {
	                this.updateEvent(this.weekendData);
	            } else {
	                this.saveEvent(this.weekendData);
	            }
            }
            else {
                iziToast.info({
                    message: 'Weekend already configured for this period',
                    position: 'topCenter'
                });
            }
        },
        saveData: function() {
	            
	            this.calendarSetting.startTime = null;
	            this.calendarSetting.endTime = null;
	        	this.$v.calendarSetting.$touch();
	            if (this.$v.calendarSetting.$invalid) {
	            	return;
	            }
	            this.calendarSetting.categoryCode = 'HOL';
            	if (this.calendarSetting.isAllDayEvent) {
	                this.calendarSetting.startDate = this.getDateValue(this.calendarSetting.eventStartDate);
	                this.calendarSetting.endDate = this.calendarSetting.eventFrequency==0 ? null : this.getDateValue(this.calendarSetting.eventEndDate);
	                if (this.calendarSetting.eventFrequency==0) {
	                	this.calendarSetting.daysOfWeek = null;
	                }
	                else if (this.calendarSetting.daysToRepeat && this.calendarSetting.daysToRepeat.length>0) {
	                    this.calendarSetting.daysOfWeek = [];
	                    this.calendarSetting.daysToRepeat.forEach((day) => {
	                        this.calendarSetting.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarSetting.daysOfWeek = this.calendarSetting.daysOfWeek.toString();
	                }
	                else this.calendarSetting.daysOfWeek = null;
	            } else {
	                if (this.calendarSetting.startDate == '' || this.calendarSetting.startDate == null) { 
	                    this.calendarSetting.startDate = this.combineDateAndTime(this.calendarSetting.eventStartDate, this.calendarSetting.eventStartTime) 
	                }
	                if (this.calendarSetting.endDate == '' || this.calendarSetting.endDate == null) { 
	                    this.calendarSetting.endDate = this.combineDateAndTime(this.calendarSetting.eventEndDate, this.calendarSetting.eventEndTime)
	                }
	                if (this.calendarSetting.eventFrequency==0) {
	                    this.calendarSetting.endDate = this.combineDateAndTime(this.calendarSetting.eventStartDate, this.calendarSetting.eventEndTime)
	                }
	                if (this.calendarSetting.daysToRepeat && this.calendarSetting.daysToRepeat.length>0) {
	                     this.calendarSetting.daysOfWeek = [];
	                    this.calendarSetting.daysToRepeat.forEach((day) => {
	                        this.calendarSetting.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarSetting.daysOfWeek = this.calendarSetting.daysOfWeek.toString();
	                    this.calendarSetting.startTime = this.calendarSetting.eventStartTime + ":00";
	                    this.calendarSetting.endTime = this.calendarSetting.eventEndTime + ":00";
	                } else this.calendarSetting.daysOfWeek = null;
	            }
            	this.showOverlay = true;
                if (this.elements.editMode) {
                    this.updateEvent(this.calendarSetting);
                } else {
                    this.saveEvent(this.calendarSetting);
                }
        },
        saveEvent: function(eventData) {
            console.log(this.calendarSetting);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarsetting/add', eventData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.getEvents();
                        this.$emit('refreshData');
                        this.clearData();
                        this.clearDataWkEnd();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateEvent: function(eventData) {
            console.log(this.calendarSetting);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarsetting/update', eventData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.getEvents();
                        this.$emit('refreshData');
                        this.clearData();
                        this.clearDataWkEnd();
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteCalendarEvent: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
        	this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/calendarsetting/delete/' + this.deleteParam.id)
                .then(response => {
		        	this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                        this.$emit('refreshData');
                    }
                })
                .catch(error => {
		        	this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        saveDataLpc: function() {
            this.$v.leavePolicy.$touch();
            if (!this.$v.leavePolicy.$invalid) {
                if (!this.matchValExists(this.leavePolicy.jobCategoryId, this.leavePolicy.leaveTypeId, this.leavePolicy.id)) {
                    if (this.elements.editModeLpc) {
                        this.updateLeavePolicy();
                    } else {
                        this.saveLeavePolicy();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Leave Policy already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        saveLeavePolicy: function() {
        	this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/leavepolicy/add', this.leavePolicy)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.getLeavePolicies();
                        this.clearDataLeavePolicy();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        updateLeavePolicy: function() {
        	this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/leavepolicy/update', this.leavePolicy)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.getLeavePolicies();
                        this.clearDataLeavePolicy();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredWkEnd(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsWkEnd = filteredItems.length
            this.currentPageWkEnd = 1
        },
        onFilteredLpc(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsLpc = filteredItems.length
            this.currentPageLpc = 1
        },
        showEditWkEnd: function(data) {
            this.elements.editModeWkEnd = true;
            this.elements.visibleWkEnd = true;
            this.weekendData.daysToRepeat = [];
            data.daysOfWeek.forEach((day) => {
                this.weekendData.daysToRepeat.push(this.days[day]);
            })
            this.weekendData.eventStartDate = this.getMonthYear(data.startRecur);
            this.weekendData.eventEndDate = this.getMonthYear(data.endRecur);
            this.weekendData.id = data.id;
            this.weekendData.title = data.title;
            this.weekendData.isAllDayEvent = data.allDay;
            this.weekendData.eventDate = data.eventDate;
            this.weekendData.description = data.description;
            this.weekendData.holidayTypeId = data.holidayTypeId;
            this.weekendData.holidayType = data.holidayType;
            this.weekendData.eventFrequency = data.eventFrequency;
            this.weekendData.calendarTypeId = data.calendarTypeId;
            this.weekendData.calendarCategoryId = data.calendarCategoryId;
            this.weekendData.categoryCode = data.categoryCode;
            this.weekendData.eventTypeId = data.eventTypeId;
            this.weekendData.status = data.status;
            this.weekendData.statusId = data.statusId;
            this.weekendData.vhextAccountId = data.vhextAccountId;
            this.weekendData.createdBy = data.createdBy;
            this.weekendData.createdById = data.createdById;
        },
        clearDataWkEnd: function() {
            this.elements.editModeWkEnd = false;
            this.elements.visibleWkEnd = false;
            this.weekendData.id = '';
            this.weekendData.title = 'Weekend';
            this.weekendData.isAllDayEvent = true;
            this.weekendData.daysOfWeek = [];
            this.weekendData.daysToRepeat = [];
            this.weekendData.startDate = '';
            this.weekendData.endDate = '';
            this.weekendData.eventStartDate = '';
            this.weekendData.eventEndDate = '';
            this.$v.weekendData.$reset();
        },

        combineDateAndTime: function(date, time) {
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        showEdit: function(data) {
        	this.elements.visibleHol = true;
            this.elements.editMode = true;
            if (data.daysOfWeek && data.daysOfWeek !== null) {

                this.calendarSetting.daysToRepeat = [];
                data.daysOfWeek.forEach((day) => {
                    this.calendarSetting.daysToRepeat.push(this.days[day]);
                })
                this.calendarSetting.eventStartDate = this.getDateValue(data.startRecur);
                this.calendarSetting.eventEndDate = this.getDateValue(data.endRecur);
                this.events.filter((event)=> {
                    if (event.id == data.id) {
                        this.calendarSetting.eventStartTime = event.startTime ? event.startTime.toString().substr(0, 5) : null;
                        this.calendarSetting.eventEndTime = event.endTime ? event.endTime.toString().substr(0, 5) : null;
                    }
                });
            } 
            else {
                this.calendarSetting.daysToRepeat = [];
                this.calendarSetting.eventStartDate = this.getDateValue(data.start);
                this.calendarSetting.eventEndDate = this.getDateValue(data.end);
                this.calendarSetting.eventStartTime = this.getTimeValue(data.start);
                this.calendarSetting.eventEndTime = this.getTimeValue(data.end);
            }
            this.calendarSetting.id = data.id;
            this.calendarSetting.title = data.title;
            this.calendarSetting.isAllDayEvent = data.allDay;
            this.calendarSetting.eventDate = data.eventDate;
            this.calendarSetting.description = data.description;
            this.calendarSetting.holidayTypeId = data.holidayTypeId;
            this.calendarSetting.holidayType = data.holidayType;
            this.calendarSetting.eventFrequency = data.eventFrequency;
            this.calendarSetting.calendarTypeId = data.calendarTypeId;
            this.calendarSetting.calendarCategoryId = data.calendarCategoryId;
            this.calendarSetting.categoryCode = data.categoryCode;
            this.calendarSetting.eventTypeId = data.eventTypeId;
            this.calendarSetting.status = data.status;
            this.calendarSetting.statusId = data.statusId;
            this.calendarSetting.vhextAccountId = data.vhextAccountId;
            this.calendarSetting.createdBy = data.createdBy;
            this.calendarSetting.createdById = data.createdById;
        },
        clearData: function() {
            this.calendarSetting.title = '';
            this.calendarSetting.isAllDayEvent = true;
            this.calendarSetting.daysOfWeek = [];
            this.calendarSetting.daysToRepeat = [];
            this.calendarSetting.eventDate = this.getDateValue(new Date());
            this.calendarSetting.startDate = '';
            this.calendarSetting.endDate = '';
            this.calendarSetting.eventStartDate = this.getDateValue(new Date());
            this.calendarSetting.eventEndDate = this.getDateValue(new Date());
            this.calendarSetting.eventStartTime = this.getTimeValue(new Date());
            this.calendarSetting.eventEndTime = this.getTimeValue(new Date());
            this.calendarSetting.statusId = '';
            this.calendarSetting.description = '';
            this.calendarSetting.eventTypeId = '';
            this.calendarSetting.holidayTypeId = '';
            this.calendarSetting.eventFrequency = 0;
            this.showOverlay = false;
            this.elements.visibleHol = false;
            this.elements.editMode = false;
            this.$v.calendarSetting.$reset();
        },
        showEditLeavePolicy: function(data) {
        	this.elements.visibleDescription = true;
            this.elements.visibleLpc = true;
            this.elements.editModeLpc = true;
            this.leavePolicy.id = data.id;
            this.leavePolicy.leavePolicyId = data.leavePolicyId;
            this.leavePolicy.vhextAccountId = data.vhextAccountId;
            this.leavePolicy.jobCategoryId = data.jobCategoryId;
            this.leavePolicy.leaveTypeId = data.leaveTypeId;
            this.leavePolicy.daysPerMonth = data.daysPerMonth;
            this.leavePolicy.daysPerYear = data.daysPerYear;
            this.leavePolicy.description = data.description;
            this.leavePolicy.createdById = data.createdById;

            this.filterData.jobCategoryId = data.jobCategoryId;
        },
        deleteLeavePolicy: function(id) {
            this.deleteParam.id = id;
        },
        onYesLeavePolicy: function() {
        	this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/leavepolicy/delete/' + this.deleteParam.id)
                .then(response => {
		        	this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getLeavePolicies();
                    }
                })
                .catch(error => {
		        	this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearDataLeavePolicy: function() {
            this.leavePolicy.id = '';
            this.leavePolicy.leavePolicyId = '';
            this.leavePolicy.jobCategoryId = '';
            this.leavePolicy.leaveTypeId = '';
            this.leavePolicy.daysPerMonth = '';
            this.leavePolicy.daysPerYear = '';
            this.leavePolicy.description = '';
            // this.leavePolicy.createdById = '';
            this.showOverlay = false;
            this.elements.visibleLpc = false;
            this.elements.editModeLpc = false;
            this.elements.visibleDescription = false;
            this.$v.leavePolicy.$reset();
        },
        cancelEditMode() {
        	// this.setCalendarEventData();
        	this.calendarSetting.viewMode = true;
        	this.calendarSetting.editMode = false;
        },
        matchValExists: function(jobCategoryId, leaveTypeId, id) {
            let result = false;
            this.leavePolicyList.map(data => {
                if (data.jobCategoryId && data.leaveTypeId && data.id !== id) {
                    if (data.jobCategoryId == jobCategoryId && data.leaveTypeId == leaveTypeId) {
                        result = true;
                    }
                }
            });
            return result;
        },
        validateDateExistsBetween(date, id) {
        	date = this.getDateValue(date);
        	let result = false;
        	this.weekendList.forEach((data,index)=> {
        		if (data.id !== id) {
	            	result = moment(date).isBetween(this.getDateValue(data.startRecur), this.getDateValue(data.endRecur), null, '[]');
        		}
            })
            return result;
        },
        closeDialog() {
            this.clearData();
            this.clearDataWkEnd();
            this.clearDataLeavePolicy();
            this.$emit('closeDialog');
            // this.$bvModal.hide('calEventSettingPopup');
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        }
    }
}
</script>