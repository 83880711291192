<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Stakeholder</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>                            
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                            <span v-if="elements.viewMode">View</span>
                                        </label> 
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="name">Name</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.stakeholder.name.$error }">
                                                                <input type="text" class="form-control" v-model="stakeholder.name" id="name" :class="{ 'is-invalid': $v.stakeholder.name.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                        <label v-if="$v.stakeholder.name.$error && !$v.stakeholder.name.minLength" class="text-danger">Title must have at least {{$v.stakeholder.name.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.stakeholder.name.$error && !$v.stakeholder.name.required" class="text-danger">Please enter Title</label>
                                                        <label v-if="$v.stakeholder.name.$error && !$v.stakeholder.name.maxLength" class="text-danger">Title must not exceed {{$v.stakeholder.name.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                 <div class="col-6">
                                                    <div class="form-group">
                                                        <label>User Name</label>
                                                        <select v-model="stakeholder.userId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Type</label>
                                                        <select v-model="stakeholder.stakeholderTypeId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in customType" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Position</label>
                                                        <input type="text" class="form-control" id="title" v-model='stakeholder.title'>
                                                    </div>
                                                </div>
                                                   <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="emailId">Email</label>
                                                            <input type="text" class="form-control" id="emailId" v-model='stakeholder.emailId'>
                                                        </div>
                                                   </div>
                                                <div class="col-6">
                                                     <div class="form-group">
                                                        <label for="phone">Phone</label>
                                                        <input type="text" class="form-control" id="phone" v-model='stakeholder.phone'>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                     <div class="form-group">
                                                        <label for="active">Active</label>
                                                        <div class="input-group ">
                                                            <b-form-checkbox class="custom-toggle" v-model="stakeholder.active" name="check-button" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                             <div v-if="elements.viewMode" id="viewStatement">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Stakeholder Id</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{stakeholderDetail.stakeholderId}}</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="name">Name</label>
                                                            <input type="text" class="form-control" id="name" disabled v-model='stakeholderDetail.name'>
                                                        </div>
                                                    </div>
                                                     <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="userName">User Name</label>
                                                            <input type="text" class="form-control" id="userName" disabled v-model='stakeholderDetail.userName'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <b-form-checkbox class="custom-toggle" v-model="stakeholderDetail.active" :disabled=notEnabled name="check-button" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="type">Type</label>
                                                            <input type="text" class="form-control" id="stakeholderTypeId" disabled v-model='stakeholderDetail.type'>
                                                        </div>
                                                    </div>
                                                   <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="emailId">Email</label>
                                                            <input type="text" class="form-control" id="emailId" disabled v-model='stakeholderDetail.emailId'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="phone">phone</label>
                                                            <input type="text" class="form-control" id="phone" disabled v-model='stakeholderDetail.phone'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="roles">Roles</label>
                                                            <input type="text" class="form-control" id="roles" disabled v-model='stakeholderDetail.roles'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="responsibilities">Responsibilities</label>
                                                            <input type="text" class="form-control" id="responsibilities" disabled v-model='stakeholderDetail.responsibilities'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Position</label>
                                                            <input type="text" class="form-control" id="title" disabled v-model='stakeholderDetail.title'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="hasContactInfo">Has Contact Info</label>
                                                            <input type="text" class="form-control" id="hasContactInfo" disabled v-model='stakeholderDetail.hasContactInfo'>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="createdDt">Created Date</label>
                                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(stakeholderDetail.createdDt)">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="lastUpdatedDt">Updated Date</label>
                                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(stakeholderDetail.lastUpdatedDt)">
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>  
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="stakeholders" @row-selected="onRowSelected" :fields="stakeholderFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(hasAttachment)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteStakeholder(data.item.id)" data-toggle="modal" data-target="#deleteStakeholder"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Stakeholder found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="stakeholders.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label >Name</label>
                                                    <b-form-input aria-label="Name" disabled v-model="stakeholderDetail.name"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="title">Position</label>
                                                    <input type="text" class="form-control" id="title" disabled v-model='stakeholderDetail.title'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="type">Type</label>
                                                    <input type="text" class="form-control" id="type" disabled v-model='stakeholderDetail.type'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="emailId">Email</label>
                                                    <input type="text" class="form-control" id="emailId" disabled v-model='stakeholderDetail.emailId'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="phone">phone</label>
                                                    <input type="text" class="form-control" id="phone" disabled v-model='stakeholderDetail.phone'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="roles">Roles</label>
                                                    <input type="text" class="form-control" id="roles" disabled v-model='stakeholderDetail.roles'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="responsibilities">Responsibilities</label>
                                                    <input type="text" class="form-control" id="responsibilities" disabled v-model='stakeholderDetail.responsibilities'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="active">Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="stakeholderDetail.active" :disabled=notEnabled name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="userName">User Name</label>
                                                    <input type="text" class="form-control" id="userName" disabled v-model='stakeholderDetail.userName'>
                                                </div>
                                            </div>
                                               <div class="col-6">
                                                <div class="form-group">
                                                    <label for="createdDt">Created Date</label>
                                                    <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(stakeholderDetail.createdDt)">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="lastUpdatedDt">Updated Date</label>
                                                    <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(stakeholderDetail.lastUpdatedDt)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="stakeholder.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <Dialog id="deleteStakeholder" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        TypeLookup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            typeLookupTitle:'Stakeholder Type Settings',
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            stakeholder: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'stakeholder',
                userId: localStorage.getItem("userId"),
                name: '',
                title:'',
                stakeholderTypeId:'',
                type:'',
                emailId:'',
                phone:'',
                active:'' 
            },
            stakeholderDetail: {
                name: '',
                userId:'',
                title:'',
                stakeholderTypeId:'',
                type: '',
                userName:'',
                emailId:'',
                phone:'',
                roles:'',
                responsibilities:'',
                hasContactInfo:'',
                active:'',
                createdDt: '',
                lastUpdatedDt: ''
       
            },
            notEnabled: true,
            customType: [],
            users: [],
            stakeholders: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            stakeholderFields: [
                { key: 'stakeholderId', label: 'ID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email', sortable: true, sortDirection: 'desc' },
                { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getStakeholder();
        this.lookupData();
    },
    validations: {
        stakeholder: {
            name: {
                required,
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
         getStakeholder: function() {
            let data = {
                vhextAccountId: this.stakeholder.vhextAccountId,
                projectId: this.stakeholder.projectId,

            }

            axios.post(this.$store.getters.getAPIBasePath + '/stakeholder/list', data)
                .then((response) => {
                    let result = response.data;
                    this.stakeholders = result.stakeholderList;
                    this.totalRows = this.stakeholders.length;
                    this.isBusy = !this.isBusy
                }).catch(error => {
                    
                    this.isBusy = !this.isBusy
                    console.log(error);
                });
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.stakeholder.vhextAccountId,
                projectId: this.stakeholder.projectId, 
                entity: this.stakeholder.entity, 
                           }
            axios.post(this.$store.getters.getAPIBasePath + '/stakeholder/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.customType = result.customTypeLookup;
                    this.users = result.userLookup;
                    console.log(this.users);
                })

        },
        saveData: function() {
            console.log(this.stakeholder);
            this.$v.stakeholder.$touch();
            if (!this.$v.stakeholder.$invalid) {
                if (this.elements.editMode) {
                    this.updateStakeholder();
                } else {
                    this.saveStakeholder();
                }
            }
        },
        saveStakeholder: function() {
            console.log(this.stakeholder);
            axios.post(this.$store.getters.getAPIBasePath + '/stakeholder/add', this.stakeholder)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.getStakeholder();
                    }
                })
        },
        updateStakeholder: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/stakeholder/update', this.stakeholder)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getStakeholder();
                    }
                })
        },
        deleteStakeholder: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/stakeholder/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getStakeholder();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.stakeholder.stakeholderTypeId = '';
            this.stakeholder.type = '';
            this.stakeholder.name = '';
            this.stakeholder.title = '';
            this.stakeholder.emailId = '';
            this.stakeholder.userId = '';
            this.stakeholder.phone = '';
            this.stakeholder.active = '';
            this.stakeholder.userName = '';
            this.stakeholder.hasContactInfo = '';
            this.stakeholder.roles = '';
            this.stakeholder.responsibilities = '';
            this.stakeholder.id = '';
            this.$v.stakeholder.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.elements.editMode = true;
            this.stakeholder.stakeholderId = data.stakeholderId;
            this.stakeholder.stakeholderTypeId = data.stakeholderTypeId;
            this.stakeholder.type = data.type;
            this.stakeholder.title = data.title;
            this.stakeholder.emailId = data.emailId;
            this.stakeholder.name = data.name;
            this.stakeholder.userName = data.userName;
            this.stakeholder.active = data.active;
            this.stakeholder.phone = data.phone;
            this.stakeholder.id = data.id;
        },
         showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.stakeholderDetail.name = data.name;
            this.stakeholderDetail.stakeholderId = data.stakeholderId;
            this.stakeholderDetail.userId = data.userId;
            this.stakeholderDetail.userName = data.userName;
            this.stakeholderDetail.title = data.title;
            this.stakeholderDetail.type = data.type;
            this.stakeholderDetail.emailId = data.emailId;
            this.stakeholderDetail.phone = data.phone;
            this.stakeholderDetail.roles = data.roles;
            this.stakeholderDetail.responsibilities = data.responsibilities;
            this.stakeholderDetail.hasContactInfo = data.hasContactInfo;
            this.stakeholderDetail.active = data.active;
            this.stakeholderDetail.createdDt = data.createdDt;
            this.stakeholderDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
           if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.stakeholderDetail.stakeholderId = data.stakeholderId.toString();
                this.stakeholderDetail.stakeholderTypeId = data.stakeholderTypeId;
                this.stakeholderDetail.type = data.type;
                this.stakeholderDetail.userName = data.userName;
                this.stakeholderDetail.name = data.name;
                this.stakeholderDetail.title = data.title;
                this.stakeholderDetail.emailId = data.emailId;
                this.stakeholderDetail.roles = data.roles;
                this.stakeholderDetail.responsibilities = data.responsibilities;
                this.stakeholderDetail.phone = data.phone;
                this.stakeholderDetail.active = data.active;
                this.stakeholderDetail.createdDt = data.createdDt;
                this.stakeholderDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        }

    }

}
</script>