<template>
    <div class="refObjectivePopup">
        <b-modal id="refObjective" ref="refObjective" centered size="lg" @hide="closeDialog" ok-only ok-title="Close" ok-variant="secondary" header-class="pb-2 pt-3 pl-3" button-size="xs" body-class="px-2 py-0" footer-class="py-2">
            <template #modal-title>
                <h5 class="mb-0 mt-2">
                    <span class="text-muted">#{{ objective.objectiveId }}</span>
                    <span class="font-weight-bold"> {{ objective.title ? objective.title : 'Objective' }}</span></h5>
            </template>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div class="card-body p-0 mb-3">
                    <div class="col-lg-12 col-xl-12">
                        <div class="col-12 col-sm-12 px-0">
                            <div class="card mt-2 mb-3">
                                <div class="card-body py-1 px-0">
                                    <p class="contact-title mb-2"><span>Objective</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12 m-0 px-3">
                                            <label class="p-2 mb-0 d-block header-details" v-html="objective.objective"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
    },
    data() {
        return {
            showModal: false,
            loading: true,

            objective: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            console.log(id, refEntity);
            this.$nextTick(() => {
                this.$bvModal.show('refObjective');
            });
            axios.get(this.$store.getters.getAPIBasePath + '/goal/objective/view/' + id)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    if (result.objective) {
                        console.log(result);
                        this.objective = result.objective;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>