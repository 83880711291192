<template>
    <div class="fixed-inner-header">
        <div v-if="elements.showMasterList" class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">{{ermMasterInfo.title}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link to="/settings/email" title="Email">
                                            <i class="fa fa-envelope action-link" aria-hidden="true"></i>
                                        </router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="!archive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMHistory()"><i class="fa fa-history"></i> Archive</a>
                                    </div>
                                    <div v-if="archive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMList()"><i class="fa fa-history"></i> {{ermMasterInfo.title}}</a>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="data-table-rows data-tables-hide-filter">
                                <b-table show-empty small stacked="md" selectable :select-mode="selectMode" @row-selected="onRowSelected" :busy="isBusy" sort-icon-left :items="entityRecordMasters" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(show_details)="row">
                                        <i @click="row.toggleDetails" :class="row.detailsShowing ? 'fa fa-minus-circle' : 'fa fa-plus-circle'"></i>
                                    </template>
                                    <template v-slot:cell(title)="data">
                                        <span>{{data.value}} [{{data.item.recCount}}]</span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <router-link v-if="data.item.ermTypeId==null" class="action-link" title="View Entity Record Master" :to="{ name: ermMasterInfo.redirectURLName, params: { ermId: data.item.id }}" @click="setMasterData(data.item.id,data.item.title)"> {{ermMasterInfo.actionName}}
                                        </router-link>
                                        <a v-if="data.item.ermTypeId!=null" class="action-link" :title="data.item.type" @click="redirectPage(data.item.id,data.item.type)">{{data.item.type}}</a>
                                    </template>
                                    <!--                                     <template v-slot:cell(status)="data">
                                        <span v-if="data.item.status == 'In Progress'" class="badge badge-pill badge-secondary mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Closed'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                        <span v-else class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                    </template> -->
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Master Record Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                    <template v-slot:table-busy>
                                        <div class="text-center text-info my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="entityRecordMasters.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    props: {
        ermMasterInfo: Object
    },
    components: {},
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showMasterList: false,
                showColumn: 'd-none'
            },
            error: false,
            masterData: {
                refErmId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refEntity: ''
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: '',

            archive: false,
            isArchive: false,

            masterAttachments: [],
            attachments: [],
            addMore: [],
            ermId: 0,
            entityId: 0,
            entity: this.ermMasterInfo.entity,
            refEntity: this.ermMasterInfo.refEntity,

            validated: false,
            statusText: '',
            isBusy: true,
            selectMode: 'single',

            deleteParam: {
                id: 0
            },
            entityRecordMasters: [],
            fields: [
                { label: '' },
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'title', label: 'Report Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'createdBy', label: 'Prepared By', tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'clientName', label: 'Client', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'status', label: 'Status', tdClass: 'table-status-col-size' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    watch: {
        'ermMasterInfo': {
            handler: function(value) {
                console.log(value.entity);
                this.getEntityRecordMasterList(value.entity);
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        // this.getEntityRecordMasterList(this.entity);
    },
    methods: {
        getEntityRecordMasterList: function(entity) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: entity,
                isArchive: this.isArchive,
            }
            console.log(data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.entityRecordMasters = result.entityRecordMasterList;
                    if (this.entityRecordMasters != null && this.entityRecordMasters.length > 0) {
                        this.elements.showDetail = true;
                        this.elements.editMode = false;
                        if (this.ermId == '') {
                            this.ermId = this.entityRecordMasters[0].id;
                            this.getAttachment(this.ermId);
                        }
                        this.entityRecordMasterCollection(this.ermId);
                    }
                    this.totalRows = this.entityRecordMasters.length;
                    this.isBusy = !this.isBusy;
                    if (this.totalRows == 0) {
                        if (this.archive == false) {
                            this.$router.push(this.ermMasterInfo.redirectURL);
                        }
                    }
                    this.elements.showMasterList = true;
                }).catch(error => {
                    this.isBusy = !this.isBusy;
                    this.elements.showMasterList = true;
                });
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data);
                this.elements.showDetail = true;
                this.elements.editMode = false;
            }
        },
        redirectPage: function(ermId, type) {
            this.routhPath = this.$route.path;
            console.log(this.routhPath);

            if (this.routhPath != null && this.routhPath.indexOf('service') == -1) {
                switch (type) {
                    case "Meeting Plan":
                        this.$router.push('/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/reportingplan/' + ermId);
                        break;
                    case "Project Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/budgetplan/' + ermId);
                        break;
                    case "Monthly Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/monthlybudgetplan/' + ermId);
                        break;
                    case "Project Funding":
                        this.$router.push('/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            } else {
                switch (type) {
                    case "Meeting Plan":
                        this.$router.push('/service/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/service/reportingplan/' + ermId);
                        break;
                    case "Project Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/service/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/service/budgetplan/' + ermId);
                        break;
                    case "Monthly Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/service/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/service/monthlybudgetplan/' + ermId);
                        break;
                    case "Project Funding":

                        this.$router.push('/service/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/service/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            }

        },
        getERMList: function() {
            this.archive = false;
            this.isArchive = false;

            this.getEntityRecordMasterList(this.entity);
            console.log(this.masterData);
        },
        getERMHistory: function() {
            this.archive = true;
            this.isArchive = true;

            this.getEntityRecordMasterList(this.entity);
            console.log(this.masterData);
        },
        refreshData: function() {
            console.log(this.ermMasterInfo);
            this.getEntityRecordMasterList(this.entity);
        }

    }
}
</script>