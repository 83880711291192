<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <b-table show-empty small outlined stacked="md"  sort-icon-left :items="requirementSpecList" :fields="requirementSpecFields" responsive="sm"  :current-page="currentPage" @filtered="onFiltered" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >
                <template v-slot:cell(title)="data">
                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                </template>
                <template v-slot:cell(requirementSpecification)="data">
                    <span class="tooltiptext" href="javascript:" v-html="data.value"></span>
                </template>
                <template v-slot:empty="scope">
                    <div class="text-center">No Record found</div>
                </template>
                <template v-slot:emptyfiltered="scope">
                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                </template>
            </b-table>
            <b-pagination v-if="requirementSpecList.length!=0" pills v-model="currentPage" :total-rows="totalRows" first-number last-number :per-page="perPage" align="fill" size="sm" class="my-0 float-right"></b-pagination>
            <div class="float-left">
                <div class="dataTables_length">
                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
        <ReqSpecDetailPopup v-if="showDialog" ref="reqSpecDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import ReqSpecDetailPopup from '@/components/popup/itemdetail/ReqSpecDetailPopup.vue';
export default {
    components: {
        ReqSpecDetailPopup,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            requirementSpecList: [],
            requirementSpecFields: [
                { key: 'requirementSpecId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc', editable: true  },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Requirement Type', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'requirementSpecification', label: 'Requirement Specification', tdClass: 'w-40', sortDirection: 'desc' },
                { key: 'priority', label: 'Priority', sortable: true, sortDirection: 'desc' },
                { key: 'dueDate', label: 'Due date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],

            detailViewData: {},
            referencePopupId: 0,
            attachmentData: {},

            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    methods: {
        getErdDetailList: function(ermId) {
            if (ermId) {
                let data = {
                    entityRecordMasterId: ermId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.requirementSpecList = result.requirementSpecList;
                        this.totalRows = this.requirementSpecList.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                    })
            }
            else {
                this.requirementSpecList = [];
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        viewItemDetails(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.reqSpecDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            this.showDialog = false;
        },
    }
}
</script>