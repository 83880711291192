<template>
    <div>
        <FeedbackFormMaster :ermMasterInfo="ermMasterInfo"></FeedbackFormMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FeedbackFormMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        FeedbackFormMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Feedback Form Master",
                entity: 'feedback_form_master',
                refEntity: 'feedback_form',
                redirectURL: '/fbfmas/list',
                erdRedirectURL: '/quality/feedbackform/'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURL = '/service/fbfmas/list';
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/fbfmas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/fbfmas/list';
        }
    },
    methods: {}
}
</script>