<template>
    <div>
        <DevelopmentPlanMasterList :ermMasterInfo="ermMasterInfo"></DevelopmentPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DevelopmentPlanMasterList from '@/components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DevelopmentPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Task Plan",
                entity: 'development_plan_master',
                refEntity: 'task',
                tooltip: 'New Task Plan',
                redirectURLName: 'task',
                newEntityMasterURL: 'dvpmas',
                redirectURL: '/dvpmas',
                actionName: 'Task List',
                previewURLName: 'tslrep',
                sharedUrlName:'task',
                showDateRange: true,
                showPlanLinkControl: true,
                masterLinkTitle: 'Master Plan Link',
                masterLinkEntity: 'plan_master',
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURLName = 'clitask';
            this.ermMasterInfo.newEntityMasterURL = 'clidvpmas';
            this.ermMasterInfo.redirectURL = '/client/dvpmas';
            this.ermMasterInfo.actionName = 'Task';
            this.ermMasterInfo.previewURLName = 'tslrep';
        } else {
            this.ermMasterInfo.redirectURLName = 'task';
            this.ermMasterInfo.newEntityMasterURL = 'dvpmas';
            this.ermMasterInfo.redirectURL = '/dvpmas';
            this.ermMasterInfo.actionName = 'Task';
            this.ermMasterInfo.previewURLName = 'tslrep';


        }
    },
    methods: {}
}
</script>