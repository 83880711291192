<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-lg-4">
                            <h4 class="pl-2 mb-0 fs-18">Company</h4>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Company" :to="{ name: 'addcompany'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Company" :to="{ name: 'editcompany', params: { companyId: companyId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:"><i class="fa fa-trash pr-2"></i>Detete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="companyName">Company Name</label>
                                            <input type="text" class="form-control" id="companyName" disabled v-model="company.companyName">
                                        </div>
                                    </div>
                                    <div class="col-12  col-sm-6">
                                        <div class="form-group">
                                            <label for="industry">Industry</label>
                                            <input type="text" class="form-control" id="industry" disabled v-model="company.industry">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="shortName">Short Name</label>
                                            <input type="text" class="form-control" id="shortName" disabled v-model="company.shortName">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="ownerName">Owner Name</label>
                                            <input type="text" class="form-control" id="ownerName" disabled v-model="company.ownerName">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="status">Company Id</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{company.companyId}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="active">Active</label>
                                            <div class="input-group ">
                                                <b-form-checkbox class="custom-toggle" v-model="company.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="createdDt">Created Date</label>
                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(company.createdDt)">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="lastUpdatedDt">Updated Date</label>
                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(company.lastUpdatedDt)">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        companyId() {
            return Number(this.$route.params.companyId);
        }
    },
    data() {
        return {
            company: []
        }
    },
    mounted() {
        this.viewCompany(this.companyId);
    },
    methods: {
        viewCompany: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/company/view/' + id)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.company = result.company;
                })

        }
    }
}
</script>