<template>
    <div>
        <div class="row">
            <div class="col-lg-12 col-xl-12">
                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                    <div class="card mb-3">
                        <b-card no-body class="">
                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-Resource>
                                    <span v-if="!elements.editMode">
                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Non Employee
                                    </span>
                                    <span v-if="elements.editMode">Edit</span>
                                </label> 
                            </b-card-header>
                            <b-collapse id="accordion-Resource" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                <b-card-body class="p-3 mb-2">
                                    <div class="row mb-2">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="name1">Name</label>
                                                <input type="text" class="form-control" id="name1" v-model="humanResource.name" placeholder="Enter Name">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="positions1">Position</label>
                                                <input type="text" class="form-control" id="positions1" v-model="humanResource.position"placeholder="Position">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Qualification</label>
                                                <input type="text" class="form-control" id="qualifications" v-model="humanResource.qualification" placeholder="Qualification">
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="experienceYears1">Experience Years</label>
                                                <b-form-input type="number" min="0" max="80" v-model="humanResource.experienceYears" placeholder="Experience Years" id="experienceYears1">
                                                </b-form-input>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label for="experienceMonths1">Experience Months</label>
                                                <b-form-input type="number" min="0" max="12" v-model="humanResource.experienceMonths" placeholder="Experience Months" id="experienceMonths1">
                                                </b-form-input>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="emailId1">Email Id</label>
                                                <b-form-input type="text" v-model="humanResource.emailId" placeholder="Email Id" id="emailId1">
                                                </b-form-input>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                             <div class="form-group">
                                                <label for="mobile1">Mobile</label>
                                                <b-form-input type="text" v-model="humanResource.mobile" placeholder="Mobile" id="mobile1">
                                                </b-form-input>
                                            </div>
                                        </div>

                                    </div>
                                    <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="float-left">
                                <div class="dataTables_filter">
                                    <label class="d-block p-1 accordion-title">Non Employees
                                    </label>
                                </div>
                            </div>                                

                            <div class="float-right">
                                <div class="dataTables_filter">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <b-table ref="otherResTable" v-model="otherResTableArray" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="otherResources" @row-selected="onRowSelected" :fields="otherResourceFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template #cell(name)="data">
                            <div class="row no-gutters align-items-center">
                                <div class="col-auto">
                                    <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                    <b-avatar v-else class="mr-2"></b-avatar>
                                </div>
                                <div class="col">
                                    <div class="mr-auto">{{ data.value }}</div>
                                    <div class="mr-auto text-muted">{{ data.item.position }}</div>
                                </div>
                            </div>
                        </template>                        
                        <!-- A custom formatted column -->
                        <template #cell(experienceYears)="data">
                            <span  v-if="Number(data.item.experienceYears)!=0">{{ data.item.experienceYears}} years</span> <span v-if="Number(data.item.experienceMonths)!=0">{{ data.item.experienceMonths }} months</span>
                        </template>

                        <template v-slot:cell(actions)="data">
                            <a v-if="!data.item.userId" href="javascript:" title="Delete" @click="deleteHumanResource(data.item.id)" data-toggle="modal" data-target="#deleteHumanResource"><i class="fa fa-trash pl-2"></i></a>
                            <a v-else>N/A</a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Resource found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
<!--                     <div class="float-left">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>      -->
                    
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

<!--                     <b-pagination v-if="otherResources.length!=0" pills v-model="currentPage" :total-rows="this.totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                </b-overlay>
            </div>
        </div>
        <Dialog id="deleteHumanResource" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete resource?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, requiredIf } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),

            entity: 'human_resource',
            userId: localStorage.getItem("userId"),

            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
            },
            humanResourceId: null,
            humanResource: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: '',
                entity: '',
                userId: '',
                loginName: '',
                userType: '',
                humanResourceId: '',
                humanResourceTypeId: '',
                isEmployee: '',
                name: '',
                member: '',
                employeeCode: '',
                recruitedById: '',
                reportingToId: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                emailId: '',
                mobile: '',
                roleResponsibility:'',
                technologySkill:'',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
                empId: '',
                designationId: '',
                workTypeId: '',
                salary: null,
                employeeStatusId: '',
                employeeStatus: '',
                jobPositionChangeById: '',
                jobPositionChangeDate: '',
                active: true,
                isEmployee: '',
                designationData: '',
                departmentId: '',
            },

            otherResTableArray: [],

            humanResources: [],

            otherResourceFields: [
                // { key: 'index', label: 'SN' },
                // { key: 'humanResourceId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'humanResourceType', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                // { key: 'qualification', label: 'Qualification', sortable: true, sortDirection: 'desc' },
                // { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' }, 
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },         
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        otherResources() {
            return this.humanResources.filter(item => !(item.empId));
        },
    },
    mounted() {
        this.getHumanResource();
    },
    validations: {
        humanResource: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
         getHumanResource: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.humanResourceList != null && result.humanResourceList.length > 0) {
                        this.humanResources = result.humanResourceList;
                        this.$nextTick(() => {
                            if (this.otherResources.length > 0) {
                                this.$nextTick(() => {
                                    this.selectBTableRowById(this.humanResourceId, this.otherResTableArray, 'otherResTable');
                                })
                                this.elements.showDetail = true;
                                this.$emit("updateShowDetail", this.elements.showDetail);
                            }
                        })
                        if (this.humanResources != null && this.humanResources.length > 0) {
                            this.totalRows = this.otherResources.length; 
                        }
                        
                        this.$emit('updateHRCount', this.totalRows);

                        this.elements.editMode = false;
                    }
                });
        },
        saveData: function() {
            this.humanResource.isEmployee = false;
            if (this.humanResource.member.id) {
                this.humanResource.id = this.humanResource.member.id;
                this.humanResource.name = this.humanResource.member.text;
            } else {
                this.humanResource.id = '';
            }
            this.$v.humanResource.$touch();
            if (!this.$v.humanResource.$invalid) {
                this.saveHumanResource();
            }
        },
        saveHumanResource: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/add', this.humanResource)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.getHumanResource();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: error.message,
                       position: 'topRight'
                   });
                })
        },
        deleteHumanResource: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/humanresource/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getHumanResource();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length

            this.currentPage = 1
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.humanResource.id = '';
            this.humanResource.humanResourceId = '';
            this.humanResource.loginName = '';
            this.humanResource.userType = '';
            this.humanResource.member = '';
            this.humanResource.name = '';
            this.humanResource.position = '';
            this.humanResource.qualification = '';
            this.humanResource.experienceYears = '';
            this.humanResource.experienceMonths = '';
            this.humanResource.emailId = '';
            this.humanResource.mobile = '';
            this.humanResource.employeeCode = '';
            this.humanResource.recruitedById = '';
            this.humanResource.reportingToId = '';
            this.humanResource.technologySkill = '';
            this.humanResource.createdBy = '';
            this.humanResource.createdDt = '';
            this.humanResource.lastUpdatedDt = '';
            this.humanResource.designationData = '';
            this.humanResource.departmentId = '';
            this.$v.humanResource.$reset();
        },
        onRowSelected: function(items) {
            if (items && items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.humanResourceId = data.id;
                this.humanResourceTypeId = data.humanResourceTypeId;
                this.$emit('updateShowDetail', this.elements.showDetail);
                this.$emit('getHumanResourceDetail', data.id );
            }
        },
    }

}
</script>