<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Vhext Service Plan</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Vhext Service Plan</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div class="row mb-2">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="vhextServicePlan">Service Plan</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.vhextServicePlan.vhextServicePlan.$error }">
                                                                <input type="text" v-model="vhextServicePlan.vhextServicePlan" class="form-control" id="vhextServicePlan" placeholder="Please Enter Service Plan">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                        <label v-if="$v.vhextServicePlan.vhextServicePlan.$error && !$v.vhextServicePlan.vhextServicePlan.required" class="text-danger">Please enter Service Plan</label>
                                                    <label v-if="$v.vhextServicePlan.vhextServicePlan.$error && !$v.vhextServicePlan.vhextServicePlan.maxLength" class="text-danger">Service Plan must not exceed {{$v.vhextServicePlan.vhextServicePlan.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="active">Active</label>
                                                        <div class="input-group ">
                                                            <b-form-checkbox class="custom-toggle" v-model="vhextServicePlan.active" name="check-button" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="vhextServicePlanType">Type</label>
                                                        <select v-model="vhextServicePlan.vhextServicePlanType" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in customTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <!-- <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="categoryId">Category</label>
                                                        <select v-model="vhextServicePlan.categoryId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in customTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <!-- <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="period">Period</label>
                                                        <input type="number" v-model="vhextServicePlan.period" class="form-control" id="vhextServicePlan" placeholder="period">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Description</label>
                                                        <textarea v-model="vhextServicePlan.description" class="form-control" rows="4" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="vhextServicePlanTable" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="vhextServicePlans"  @row-selected="onRowSelected" :fields="vhextServicePlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteVhextServicePlan(data.item.id)" data-toggle="modal" data-target="#deleteVhextServicePlan"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No VhextServicePlan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="vhextServicePlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row input-group-label">
                                        <div class="col-5">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{ vhextServicePlanDetail.vhextServicePlanId }}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateVhextServicePlan" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="title">ServicePlan</label>
                                                <input type="text" class="form-control" id="title" :disabled="!elements.editMode" v-model='vhextServicePlanDetail.vhextServicePlan'>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label>Active</label>
                                                <b-form-checkbox class="custom-toggle" v-model="vhextServicePlanDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="vhextServicePlanType">Type</label>
                                                <input v-if="!elements.editMode" type="vhextServicePlanType" class="form-control" id="vhextServicePlanType" disabled v-model='vhextServicePlanDetail.vhextServicePlanType'>
                                                <select v-if="elements.editMode" v-model="vhextServicePlanDetail.vhextServicePlanType" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in customTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="categoryId">Category</label>
                                                <input v-if="!elements.editMode" type="categoryId" class="form-control" id="categoryId" disabled v-model='vhextServicePlanDetail.categoryId'>
                                                <select v-if="elements.editMode" v-model="vhextServicePlanDetail.categoryId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in customTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div class="col-4">
                                            <div class="form-group">
                                                <label for="period">Period</label>
                                                    <input type="number" v-model="vhextServicePlanDetail.period" class="form-control" id="period" placeholder="period">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="description1">Description</label>
                                                <textarea class="form-control" id="description1" rows="4" :disabled="!elements.editMode" v-model='vhextServicePlanDetail.description'></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="createdBy">Created By</label>
                                                                        <input type="text" class="form-control disabled" id="createdBy" v-model="vhextServicePlanDetail.createdBy" readonly>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="startDate">Created Date</label>
                                                                        <div class="input-group" >
                                                                            <input type="text" class="form-control disabled" :value="vhextServicePlanDetail.createdDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="endDate">Last Updated Date</label>
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control disabled" :value="vhextServicePlanDetail.lastUpdatedDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="budget">Functions</label>
                                            <select id="durationDays" class="form-control">
                                                <option value="">Functions...</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="budget" style="visibility: hidden;">Search</label>
                                            <div class="input-group ">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-text input-group-append input-group-addon">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Reference Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template> 
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Objective Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Objective found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteVhextServicePlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete VhextServicePlan ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,
            vhextAccountId: '',
            projectId: '',
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entity: '',
            userId: '',

            vhextServicePlan: {
                id: '',
                vhextServicePlanId: '',
                vhextAccountId: '',
                projectId: '',
                vhextServicePlan: '',
                vhextServicePlanType: '',
                categoryId: '',
                period: '',
                active: true,
                description: '',
                createdById: '',
                createdBy: '',
            
            },
            vhextServicePlanDetail: {
                vhextServicePlan: '',
                vhextServicePlanId: '',
                vhextServicePlanType: '',
                categoryId: '',
                createdBy: '',
                description: '',
                createdBy: '',
                createdById: '',
                active: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            users: [],
            vhextServicePlans: [],
            customTypes: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            vhextServicePlanFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'vhextServicePlan', label: 'Service Plan', sortable: true, sortDirection: 'desc' },
                { key: 'categoryId', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'vhextServicePlanType', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'categoryId', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'period', label: 'Period', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.getVhextServicePlan();
        this.lookupData();
    },
    validations: {
        vhextServicePlan: {
            vhextServicePlan: {
                required,
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = 'vhext_service_plan';
            this.userId = localStorage.getItem("userId");
        },

        getVhextServicePlan: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }

            axios.post(this.$store.getters.getAPIBasePath + '/vhext/serviceplan/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.vhextServicePlanList && result.vhextServicePlanList.length > 0) {
                        this.vhextServicePlans = result.vhextServicePlanList;
                        this.$nextTick(() => {
                            this.$refs.vhextServicePlanTable.selectRow(0);
                        })
                        this.totalRows = this.vhextServicePlans.length;
                    }
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/vhextServicePlan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.customTypes = result.customTypeLookup;
                    
                })

        },
        saveData: function() {
            this.vhextServicePlan.vhextAccountId = this.vhextAccountId;
            this.vhextServicePlan.projectId = this.projectId;
            this.vhextServicePlan.createdById = this.userId;

            this.$v.vhextServicePlan.$touch();
            if (!this.$v.vhextServicePlan.$invalid) {
                this.saveVhextServicePlan();
            }
        },
        saveVhextServicePlan: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/serviceplan/add', this.vhextServicePlan)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getVhextServicePlan();
                    }
                })
        },
        updateVhextServicePlan: function() {
            this.vhextServicePlanDetail.vhextAccountId = this.vhextAccountId;
            this.vhextServicePlanDetail.projectId = this.projectId;

            axios.post(this.$store.getters.getAPIBasePath + '/vhext/serviceplan/update', this.vhextServicePlanDetail)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        
                        this.getVhextServicePlan();
                    }
                })
        },
        viewVhextServicePlan: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/vhext/serviceplan/view' + id)
                .then((response) => {
                    let result = response.data;
                    let vhextServicePlanDetail = result.vhextServicePlan;
                    if (vhextServicePlanDetail) {                        
                        this.vhextServicePlanDetail.id = vhextServicePlanDetail.id;
                        this.vhextServicePlanDetail.vhextServicePlan = vhextServicePlanDetail.vhextServicePlan;
                        this.vhextServicePlanDetail.vhextServicePlanId = vhextServicePlanDetail.vhextServicePlanId;
                        this.vhextServicePlanDetail.createdBy = vhextServicePlanDetail.createdBy;
                        this.vhextServicePlanDetail.description = vhextServicePlanDetail.description;
                        this.vhextServicePlanDetail.vhextServicePlanType = vhextServicePlanDetail.vhextServicePlanType;
                        this.vhextServicePlanDetail.categoryId = vhextServicePlanDetail.categoryId;
                        this.vhextServicePlanDetail.period = vhextServicePlanDetail.period;
                        this.vhextServicePlanDetail.active = vhextServicePlanDetail.active;
                        this.vhextServicePlanDetail.createdDt = vhextServicePlanDetail.createdDt;
                        this.vhextServicePlanDetail.lastUpdatedDt = vhextServicePlanDetail.lastUpdatedDt;
                    }
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewVhextServicePlan(this.vhextServicePlanDetail.id);
        },
        deleteVhextServicePlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/vhext/serviceplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getVhextServicePlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.vhextServicePlan.vhextServicePlan = '';
            this.vhextServicePlan.active = true;
            this.vhextServicePlan.description = '';
            this.vhextServicePlan.vhextServicePlanType = '';
            this.vhextServicePlan.categoryId = '';
            this.vhextServicePlan.period = '';
            this.vhextServicePlan.createdById = localStorage.getItem("userId");

            this.$v.vhextServicePlan.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.visible = false;
            this.elements.editMode = true;
            this.vhextServicePlanDetail.active = data.active;
            this.vhextServicePlanDetail.vhextServicePlan = data.vhextServicePlan;
            this.vhextServicePlanDetail.createdById = data.createdById;
            this.vhextServicePlanDetail.createdBy = data.createdBy;
            this.vhextServicePlanDetail.description = data.description;
            this.vhextServicePlanDetail.vhextServicePlanType = data.vhextServicePlanType;
            this.vhextServicePlanDetail.categoryId = data.categoryId;
            this.vhextServicePlanDetail.period = data.period;
            this.vhextServicePlanDetail.id = data.id;
            this.vhextServicePlanDetail.vhextServicePlanId = data.vhextServicePlanId;
        },
        onRowSelected: function(items) {

            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.vhextServicePlanDetail.id = data.id;
                this.vhextServicePlanDetail.description = data.description;
                this.vhextServicePlanDetail.active = data.active;
                this.vhextServicePlanDetail.vhextServicePlan = data.vhextServicePlan;
                this.vhextServicePlanDetail.vhextServicePlanId = data.vhextServicePlanId;
                this.vhextServicePlanDetail.vhextServicePlanType = data.vhextServicePlanType;
                this.vhextServicePlanDetail.categoryId = data.categoryId;
                this.vhextServicePlanDetail.period = data.period;
                this.vhextServicePlanDetail.createdBy = data.createdBy;
                this.vhextServicePlanDetail.createdById = data.createdById;
                this.vhextServicePlanDetail.createdDt = data.createdDt;
                this.vhextServicePlanDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.vhextServicePlan.entity = this.entity;
                this.vhextServicePlan.id = item.id;
                this.vhextServicePlan.active = 0;

                this.updateActivationState();

            } else {
                this.vhextServicePlan.entity = this.entity;
                this.vhextServicePlan.id = item.id;
                this.vhextServicePlan.active = 1;

                this.updateActivationState();
            }
            console.log(this.vhextServicePlan.id, this.vhextServicePlan.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.vhextServicePlan)
                .then((response) => {
                    console.log(this.vhextServicePlan.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.vhextServicePlan.active = true;
                    this.getVhextServicePlan();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

    }
}
</script>
