<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">{{ermMasterInfo.title}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item">
                                        <router-link to="/settings/email" title="Email">
                                            <i class="fa fa-envelope action-link" aria-hidden="true"></i>
                                        </router-link>
                                    </li> -->
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div v-if="!archive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="'Archived Docs'" href="javascript:" @click="getERMHistory()"><i class="fa fa-history"></i> Archive</a>
                                    </div>
                                    <div v-if="archive" class="float-right pt-2 pr-2">
                                        <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMList()"><i class="fa fa-history"></i> {{ermMasterInfo.title}}</a>
                                    </div>
                                    <div v-if="!ermMasterInfo.newEntityMasterURL==''" class="float-right pt-2 pr-4">
                                        <router-link class="action-link" :title="'Add ' + ermMasterInfo.tooltip" :to="{ name: ermMasterInfo.newEntityMasterURL}"><i class="fa fa-plus-circle pr-2"></i>{{ermMasterInfo.tooltip}}</router-link>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <!-- <div class="data-table-rows  data-tables-hide-filter"> -->
                            <div class="data-tables-hide-filter">

                                <b-table class="ERM-report erm-table-bg" ref="ermTable" show-empty small stacked="md" :busy="isBusy" selectable :select-mode="selectMode" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" @row-selected="onRowSelected" sort-icon-left :items="entityRecordMasters" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredSS" foot-clone foot-variant=light no-footer-sorting>    
                                    <template v-slot:cell(entityRecordMasterId)="data">
                                        <div v-if="data.item.id == idEdit">
                                            <input type="number" class="form-control" v-model="data.item.entityRecordMasterId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.entityRecordMasterId)" @blur="cancelIdEdit()">
                                        </div>
                                        <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.entityRecordMasterId" class="action-link"> {{ data.item.entityRecordMasterId }}</div>
                                    </template>

                                    <template v-if="ermMasterInfo.headerTitle" #head(title)>{{ermMasterInfo.headerTitle}}</template>

                                    <template #cell(show_details)="row">
                                        <i @click="row.toggleDetails" :class="row.detailsShowing ? 'fa fa-minus-circle' : 'fa fa-plus-circle'"></i>
                                    </template>
                                    <template v-slot:cell(title)="data">
                                        <span class="">{{data.value}}</span>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <!-- <a v-if="data.item.ermTypeId==null" :title="'Add ' + ermMasterInfo.actionName" @click="routeDirect(data.item.id,data.item.title,data.item.isFreeze)">
                                            <i class="fa fa-plus-circle action-link"></i> {{ermMasterInfo.actionName}}
                                        </a>
                                        <a v-if="data.item.ermTypeId!=null" :title="data.item.type" @click="redirectPage(data.item.id,data.item.type,data.item.title,data.item.isFreeze)"> {{data.item.type}}</a> -->
                                        <div v-if="data.item.ermTypeId==null" class="btn-group float-right" role="group" aria-label="Basic example">
                                            <button type="submit" :title="'Show ' + ermMasterInfo.actionName" @click="routeDirect(data.item.id,data.item.title,data.item.isFreeze,data.item.createdById)" class="sbtn btn btn-xs btn-outline-primary px-2">{{data.item.recCount}} {{ermMasterInfo.actionName}}(s)</button>
                                            <button type="button" class="sbtn btn btn-xs btn-secondary px-2" :title="'Add ' + ermMasterInfo.actionName" @click="routeDirect(data.item.id,data.item.title,data.item.isFreeze,data.item.createdById)">Add</button>
                                        </div>
                                        <div v-if="data.item.ermTypeId!=null" class="btn-group float-right" role="group" aria-label="Basic example">
                                            <button type="submit"  :title="'Show ' + data.item.type" @click="redirectPage(data.item.id,data.item.type,data.item.typeCode,data.item.title,data.item.isFreeze,data.item.startDate,data.item.endDate,data.item.createdById)" class="sbtn btn btn-xs btn-outline-primary px-2">{{data.item.recCount}} {{ermMasterInfo.actionName ? ermMasterInfo.actionName : data.item.type}}(s)</button>
                                            <button type="button" class="sbtn btn btn-xs btn-secondary px-2" :title="'Add ' + ermMasterInfo.actionName" @click="redirectPage(data.item.id,data.item.type,data.item.typeCode,data.item.title,data.item.isFreeze,data.item.startDate,data.item.endDate,data.item.createdById)">Add</button>
                                        </div>
                                        <!-- <div class="btn-group" role="group" aria-label="Basic example">
                                          <button type="button" class="btn btn-xs btn-secondary">Left</button>
                                          <button type="button" class="btn btn-xs btn-secondary">Middle</button>
                                        </div> -->
                                    </template>

                                    <template v-slot:cell(status)="data">
                                        <span v-if="data.item.status == 'Draft'" class="badge badge-pill badge-light mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Reviewed'" class="badge badge-pill badge-warning mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Approved'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                        <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                        <span v-else class="badge badge-pill badge-secondary">{{data.value}}</span>
                                    </template>

                                    <template #row-details="row">
                                        <b-card class="expandRow row-details-bg mt-2 mb-2 py-1">
                                            <ul class="table-expand">
                                                <li>
                                                    <a href="javascript:" class="action-link" title="Report View"  @click="routeReport(row.item.id,row.item.type,row.item.typeCode,row.item.title,row.item.isFreeze,row.item.createdById)"><i class="fa fa-eye" aria-hidden="true"></i> Report</a>
                                                </li>
                                                <span v-if="row.item.createdById == userId">
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Share Document Access" @click="ermShare(row.item)" v-b-modal.shareModal><i class="fa fa-share-alt pr-1" aria-hidden="true"></i>Share</a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Share Doc for Review" @click="reviewShare(row.item)" v-b-modal.reviewModal><i class="fa fa-comments-o pr-1" aria-hidden="true"></i>Review</a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Share Doc for Approval" @click="approvalShare(row.item)" v-b-modal.approvalModal><i class="fa fa-check pr-1" aria-hidden="true"></i>Approval</a>
                                                        <router-link v-show="false" class="action-link" title="Share Doc for Approval" :to="{ name: ermMasterInfo.previewURLName, params: { ermId: row.item.id,ermTypeId: row.item.ermTypeId}}"><i class="fa fa-check pr-1" aria-hidden="true"></i>Approval</router-link>
                                                    </li>
                                                    <li v-if="ermMasterInfo.showAssignment">
                                                        <a href="javascript:" class="action-link" title="Assign" @click.prevent="taskAssignment(row.item)" v-b-modal.ermAssignments><i class="fa fa-comments-o pr-1" aria-hidden="true"></i>Assign</a>
                                                    </li>
                                                    <li>
                                                        <a v-b-modal.ermCopyModal class="action-link" title="Copy Doc" @click="ermCopy(row.item)"><i class="fa fa-copy pr-1" aria-hidden="true"></i>Copy</a>
                                                    </li>
                                                    <li>
                                                        <a class="action-link align-middle">
                                                            <input type="checkbox" class="align-middle mr-1" v-model="row.item.isPublic" @click="ermPublicAccess(row.item)" title="Access to All"><span class="align-middle d-inline-block">All</span>
                                                        </a>
                                                    </li>
                                                    <!--                                                 <li>
                                                        <a><i class="fa fa-download" title="Download" aria-hidden="true"></i></a>
                                                    </li>
                                                    <li>
                                                        <a><i class="fa fa-print" title="Print" aria-hidden="true"></i></a>
                                                    </li> -->
                                                    <li>
                                                        <div v-if="!row.item.isFreeze">
                                                            <a href="#" title="Freeze" @click.prevent="freezeERM(row.item)"><i class="fa fa-unlock-alt" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div v-if="row.item.isFreeze">
                                                            <a href="#" title="Unfreeze" @click.prevent="freezeERM(row.item)"><i class="fa fa-lock" aria-hidden="true"></i></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div v-if="!archive">
                                                            <a href="#" title="Archive" @click.prevent="archiveERM(row.item)"><i class="fa fa-archive" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div v-if="archive">
                                                            <a href="#" title="Unarchive" @click.prevent="archiveERM(row.item)"><i class="fa fa-undo" aria-hidden="true"></i></a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a href="#" title="Delete" v-b-modal.deleteERM @click.prevent="deleteERM(row.item.id)" ><i class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </li>
                                                </span>
                                            </ul>
                                        </b-card>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Master Record Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                    <template v-slot:table-busy>
                                        <div class="text-center text-info my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                    <template v-slot:foot()="data">
                                        <span></span>
                                    </template>                                    
                                </b-table>
                                <div class="float-left">
                                    <div class="dataTables_length">
                                        <b-form-group label="Per Page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="my-0">
                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div>

                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-right ml-3">{{this.totalRows}} Items</span> 

<!--                                 <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
 -->
                                <b-pagination v-if="entityRecordMasters.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="pull-right"></b-pagination>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-2">
                            <b-overlay :show="showMasDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div v-if="elements.showDetail">
                                            <div class="row input-group-label">
                                                <div class="col-4">
                                                    <b-input-group prepend="Id" class="text mb-2 ">
                                                        <b-input-group-prepend is-text><b>{{entityRecordMaster.entityRecordMasterId}}</b></b-input-group-prepend>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-6 pl-0">
                                                    <b-input-group prepend="Status" class="mb-2">
                                                        <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="statusText"></b-form-input>
                                                        <b-dropdown v-if="elements.editMode" :text="statusText" class="btn-dd" v-model="entityRecordMaster.statusId">
                                                            <b-dropdown-item v-for="(info, index)  in masterStatus" :key="info.id" :value="info.id" @click="entityRecordMaster.statusId = info.id;statusText = info.value">
                                                                {{info.value}}
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </b-input-group>
                                                </div>
                                                <div v-if="!this.isFreeze && this.userId == this.entityRecordMaster.createdById" class="col-md-2 col-sm-2 pl-0">
                                                    <div class="more-btns text-center">
                                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                            <i class="fa fa-pencil fa-sm action-link" aria-hidden="true"></i>
                                                        </button>
                                                        <div v-if="elements.editMode">
                                                            <button type="submit" @click="updateERMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                                            <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else="!this.isFreeze" class="col-md-2 col-sm-2 pl-0">
                                                    <div  class="more-btns text-center">
                                                        <button href="javascript:" class="sbtn btn btn-xs p-1 disabled" title="Doc Freezed"><i class="fa fa-lock fa-sm" aria-hidden="true"></i>
                                                        </button>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-4 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="title1">Title</label>
                                                        <input :disabled="!elements.editMode" v-model="entityRecordMaster.title" type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="ermMasterInfo.showTypeLookup" class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="title">Type</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.ermTypeId" @change="handleErmTypeChange" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.type" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <template v-if="ermMasterInfo.showDateRange">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Start Date</label>
                                                            <input v-if="elements.editMode" type="date" class="form-control" v-model="entityRecordMaster.startDate" id="startDate">
                                                            <input v-if="!elements.editMode" type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.startDate)">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="endDate">End Date</label>
                                                            <input v-if="elements.editMode" type="date" class="form-control" v-model="entityRecordMaster.endDate" id="endDate">
                                                            <input v-if="!elements.editMode" type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.endDate)">
                                                        </div>
                                                    </div>
                                                </template>
                                                <div v-if="ermMasterInfo.showErmDate" class="col-12">
                                                    <div class="form-group">
                                                        <label for="ermDate">Month</label>
                                                        <input v-if="elements.editMode" type="month" class="form-control" v-model="entityRecordMaster.ermDate" id="ermDate">
                                                        <input v-if="!elements.editMode" type="text" class="form-control" disabled :value="getMonthYearAlt(entityRecordMaster.ermDate)">
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showMultipleMasterControl" class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="title1">{{ ermMasterInfo.masterLinkTitle }}</label>
                                                        <multiselect v-model="refErmList" :options="ermLookup" :disabled="!elements.editMode" name="masterLink" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showSingleMasterControl" class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="title1">{{ ermMasterInfo.masterLinkTitle }}</label>
                                                        <select :disabled="!elements.editMode" v-model="entityRecordMaster.refErmId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in ermLookup" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showPlanLinkControl" class="row">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="title1">{{ ermMasterInfo.masterLinkTitle }}</label>
                                                        <select :disabled="!elements.editMode" v-model="refErdData" class="form-control">
                                                            <option value="{id:'',ermId:''}">Select</option>
                                                            <option v-for="(info, index) in commonErdLookup" :value="{id:info.id,ermId:info.ermId}">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div v-if="getErmTypeCode == 'PRB'" class="col-6">
                                                    <div class="form-group">
                                                        <label for="projectId">Project</label>
                                                        <input type="text" class="form-control" disabled v-model="projectName" id="startDate">
                                                    </div>
                                                </div>
                                                <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6">
                                                    <div class="form-group">
                                                        <label for="client">Client</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.clientName" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div v-else class="col-6 col-sm-12">
                                                    <div class="form-group">
                                                        <label for="client">Client</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.clientName" type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-0">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                                    <span>Description</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-1" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12 col-sm-12">
                                                                            <div v-if="elements.editMode" class="form-group">
                                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="entityRecordMaster.description"></editor>
                                                                            </div>
                                                                            <div v-else class="form-group">
                                                                                <p v-if="entityRecordMaster.description"><span v-html="entityRecordMaster.description"></span></p>
                                                                                <p v-else class="text-muted my-2 text-center">No description</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.attachment>
                                                                    <span>Attachment</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="attachment" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                                                    <div class="attachments mb-2">
                                                                                        <b-form-file ref="fileinput" class="attachments-list" size="sm" v-model="masterAttachments[index]" placeholder="Choose a file.">
                                                                                            <template slot="file-name" slot-scope="{ names }">
                                                                                                <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                                            </template>
                                                                                        </b-form-file>
                                                                                        <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                                                            <i class="fa fa-check pr-2"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                    <label v-if="error" class="text-danger">Please choose a file.</label>
                                                                                </div>
                                                                            </div>
                                                                            <div v-show="attachments.length != 0" class="col-12">
                                                                                <div class="form-group">
                                                                                    <div>
                                                                                        <p v-for="(attachment, index) in attachments">
                                                                                            <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                                                            <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                                            <a href="javascript:" data-toggle="modal" data-target="#deleteAttachment" @click="deleteFile(attachment.id)" title="Delete attachment" class="float-right button-remove p-2 attachments-remove">
                                                                                                <i class="fa fa-trash pr-2 action-link"></i>
                                                                                            </a>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="createdById">Prepared By</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.createdById" id="createdById" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.createdBy" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card-body p-0 mb-3">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.docShareAccordian>
                                                                    <span>
                                                                        <i class="fa fa-share-alt pr-1 action-link" aria-hidden="true"></i>
                                                                        Doc Share History
                                                                    </span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="docShareAccordian" accordion="docShare-accordian" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <b-table show-empty small stacked="md" sort-icon-left :items="ermShareList" :fields="ermShareFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                                        <template #cell(index)="data">
                                                                            {{ data.index + 1 }}
                                                                        </template>
                                                                        <template v-slot:cell(ermShareMode)="data">
                                                                            <span v-if="data.value == 1" class="badge badge-warning mb-1">Editor</span>
                                                                            <span v-else class="badge badge-info mb-1">Viewer</span>
                                                                        </template>
                                                                        <template v-slot:empty="">
                                                                            <div class="text-center">No Shared Users found</div>
                                                                        </template>
                                                                        <template v-slot:emptyfiltered="">
                                                                            <div class="text-center">{{ }}</div>
                                                                        </template>
                                                                    </b-table>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <b-table show-empty small stacked="md" sort-icon-left :items="entityReviewNotApprovalList" :fields="reviewFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reviews found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                                <template v-for="(info, index) in entityReviewApprovalList">
                                                    <div class="col-6" v-if="index==0">
                                                        <div class="form-group">
                                                            <label for="scopeTypeId">Approved By</label>
                                                            <select v-if="elements.editMode" v-model="entityRecordMaster.createdById" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <input v-if="!elements.editMode" disabled v-model="info.reviewedBy" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-6" v-if="index==0">
                                                        <div class="form-group">
                                                            <label for="startDate">Approved Date</label>
                                                            <input :disabled="!elements.editMode" v-model="info.reviewDt" type="date" class="form-control">
                                                        </div>
                                                    </div>
                                                </template>
                                                <div v-if="!elements.editMode" class="col-6">
                                                    <div class="form-group">
                                                        <label>Created Date</label>
                                                        <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.createdDt)">
                                                    </div>
                                                </div>
                                                <div v-if="!elements.editMode" class="col-6">
                                                    <div class="form-group">
                                                        <label>Updated Date</label>
                                                        <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.lastUpdatedDt)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Add Reference">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-2>
                                                    <span>Reference Search</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" role="tabpanel">
                                                <div class="row ">
                                                    <div class="col-sm-12">
                                                        <div class="float-right">
                                                            <div class="dataTables_length">
                                                                <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="mb-0">
                                                                    <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                        <div class="float-left">
                                                            <div class="dataTables_filter">
                                                                <label>
                                                                    <input type="search" v-model="ReferenceFilter" class="form-control" placeholder="Search Reference">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="projectERMList" :fields="referenceFields" responsive="sm" :current-page="currentPageRef" :per-page="perPageRef" :filter="ReferenceFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                        <template v-slot:cell(title)="data"><a href="javascript:" title="Add Reference" class="action-link" @click=" redirectReference(data.item.id, data.item.refEntity,data.item.title)"><span :title="data.value"> {{getShortString(data.value)}}</span></a>
                                                        </template>
                                                        <template v-slot:cell(entity)="data">
                                                            <span :title="data.value"> {{getShortString(data.value)}}</span>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reference found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                    <b-pagination v-if="projectERMList.length!=0" pills v-model="currentPageRef" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                                </div>
                                            </b-collapse>
                                        </b-card>
                                        <div class="mt-2">
                                            <label class="mb-1">References</label>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="ermReferenceList" :fields="addReferenceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(title)="data">
                                                    <a href="javascript:" title="Add Reference" class="action-link" @click=" redirectReference(data.item.refErmId, data.item.entity,data.item.title)"><span :title="data.value"> {{getShortString(data.value)}}</span></a>
                                                </template>
                                                <template v-slot:cell(refEntity)="data">
                                                    <span :title="data.value"> {{getShortString(data.value)}}</span>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" data-toggle="modal" data-target="#removeReference"><i class="fa fa-minus-circle pr-2"></i></a>
                                                </template>
                                                <template v-slot:empty="">
                                                    <div class="text-center">No References found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="">
                                                    <div class="text-center">{{ }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteAttachment" :onYes="onYesAttachment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
        <DeleteComponent id="deleteERM" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the document?" />
        <Dialog id="ermPublicAccess" :onYes="onYes" :returnParams="deleteParam" title="Access Confirmation" message="Are you sure to give public access?" />
        <!-- <Dialog id="freezeERM" :onYes="onYes" :returnParams="freezeParam" title="Freeze Confirmation" message="Are you sure to freeze?" /> -->
        <Dialog id="archiveERM" :onYes="onYes" :returnParams="archiveParam" title="Archive Confirmation" message="Are you sure to archive?" />
        <ERMCopy ref="ermCopyRef" @refreshErmList="getEntityRecordMasterList(entity)" :masterData="masterData" :key="ermCopyKey"/>
        <Share ref="ermShareRef" :key="ermShareKey" :masterData="masterData" :ermId="Number(ermId)" :entity="entity" :sharedUrl="ermMasterInfo.sharedUrlName" />
        <Review ref="reviewShareRef" @refreshReview="entityRecordMasterCollection" :key="ermReviewKey" :masterData="masterData" :ermId="Number(ermId)" :entity="entity" :previewURL="ermMasterInfo.previewURLName" />
        <Approval ref="approvalShareRef" @refreshApproval="entityRecordMasterCollection" :key="ermApprovalKey" :masterData="masterData" :ermId="Number(ermId)" :entity="entity" :previewURL="ermMasterInfo.previewURLName" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
        <Dialog id="removeReference" :onYes="onYesRef" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Reference?" />
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';    
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import Review from '@/components/usercontrols/ReviewControl.vue';
import Approval from '@/components/usercontrols/ApprovalControl.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import ERMCopy from '@/components/usercontrols/ERMCopyControl.vue';
import Share from '@/components/usercontrols/ShareControl.vue';
import redirectMixin from "@/mixins/redirect";
export default {
    name: 'app',
    props: {
        ermMasterInfo: Object
    },
    components: {
        Editor,
        Multiselect,
        Review,
        Approval,
        QuickTaskPopup,
        ERMCopy,
        Share
    },
    mixins: [redirectMixin],
    data() {
        return {
            showMasDetOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                showColumn: 'd-none'
            },
            error: false,
            ermCopyKey: 0,
            ermShareKey: 2000,
            ermReviewKey: 4000,
            ermApprovalKey: 6000,
            
            addReference: {
                refErmId: 0,
                refErdId: '',
                refEntityId: '',
                title: '',
                refEntity: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            },
            masterData: {
                refErmId: 0,
                title: '',
                refEntityId: 0,
                refErdTitle: '',
                refEntity: '',
                sharedUrl: '',
                previewURL: '',
            },
            messageBoxData: {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'xs',
                okVariant: 'primary',
                cancelTitle: 'No',
                okTitle: 'Yes',
                headerClass: 'py-2 px-3',
                bodyClass: 'fs-14 px-3',
                footerClass: 'py-2 px-3',
                centered: true,
                hideHeaderClose: false
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            entityRecordMasterId: '',
            entity: this.ermMasterInfo.entity,
            refEntity: this.ermMasterInfo.refEntity,
            vtabType: 'ERM',            

            idValue: '',
            idEdit: '',

            
            isPublic: false,
            // freeze: false,
            isFreeeze: false,
            archive: false,
            isArchive: false,

            masterAttachments: [],
            attachments: [],
            addMore: [],
            ermId: 0,
            entityId: 0,

            validated: false,
            statusText: '',
            isBusy: true,
            selectMode: 'single',

            refErdData:{
                id: '',
                ermId:'',
            },

            entityRecordMaster: [],
            projectERMList: [],
            entityReviewList: [],
            entityReviewApprovalList: [],
            entityReviewNotApprovalList: [],
            ermShareList: [],

            clients: [],
            refErmList: [],
            ermLookup: [],
            erdLookup: [],
            entityRecordCustomType: [],
            masterStatus: [],
            allUsers: [],
            entityRecordMasters: [],
            ermReferenceList: [],

            fields: [
                { key: 'entityRecordMasterId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc', class: ''},
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'clientName', label: 'Client', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'createdBy', label: 'Prepared By', tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc', class: '' },
                // { key: 'endDate', label: 'Due Date', formatter: "formatDate", tdClass: 'table-status-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', tdClass: 'table-status-col-size' },
                { key: 'actions', label: 'Actions' }
            ],
            referenceFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Doc. Type', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            addReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Doc. Type', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            reviewFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' }
            ],
            ermShareFields: [
                { key: 'index', label: 'SN', sortable: true, sortDirection: 'desc' },
                { key: 'sharedBy', label: 'Shared By', sortable: true, sortDirection: 'desc' },
                { key: 'sharedTo', label: 'Shared To', sortable: true, sortDirection: 'desc' },
                { key: 'sharedDt', label: 'Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'ermShareMode', label: 'Share Mode', sortable: true, sortDirection: 'desc' }
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],

            freezeParam: {
                id: 0
            },

            archiveParam: {
                id: 0
            },

            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            initObj: {
                height: 150,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },


        }
    },
    computed: {
        users() {
            if (this.userTypeCode == 'CLI') {
                return this.allUsers.filter((user) => user.id == this.userId)
            } else return this.allUsers;
        },
        getErmTypeCode() {
            if (this.entityRecordCustomType.length>0) {
                let typeData = this.entityRecordCustomType.find((data) => data.id == this.entityRecordMaster.ermTypeId);
                return typeData ? typeData.typeCode : null;
            } else return null;
        },
        commonErdLookup() {
            if(this.entityRecordMaster.projectId && this.entityRecordMaster.projectId != '') {
                return this.erdLookup.filter((data) => data.projectId == this.entityRecordMaster.projectId);
            } else {
                return this.erdLookup;
            }
        }

    },
    mounted() {
        this.addMore.push(this.addMore.length + 1);
        
        this.masterLookupData();
        this.masterLinkLookupData();
        this.getProjectEntityRecordMasterList();
        this.planMasterLinkLookupData();
        this.getEntityRecordMasterList(this.entity);

    },
    methods: {
        getEntityRecordMasterList: function(entity) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: entity,
                refEntity: this.refEntity,
                isArchive: this.isArchive,
            }
            console.log(data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.entityRecordMasterList && result.entityRecordMasterList.length > 0) {
                        this.entityRecordMasters = result.entityRecordMasterList;
                        this.elements.showDetail = true;
                        this.elements.editMode = false;
                        this.totalRows = this.entityRecordMasters.length;
                        if (this.ermId == '') {
                            this.ermId = this.entityRecordMasters[0].id;
                            this.entityRecordMasters[0]._showDetails = true;
                            this.$nextTick(() => {
                                this.$refs.ermTable.selectRow(0);
                            })
                            this.getAttachment(this.ermId);
                        } else {
                            this.showDetail(this.entityRecordMaster.id, false);
                        }
                        this.entityRecordMasterCollection(this.ermId);
                    } else {
                        this.totalRows = 0;
                        this.entityRecordMasters = result.entityRecordMasterList;
                    }
                    // this.totalRows = this.entityRecordMasters.length;
                    this.isBusy = !this.isBusy;
                    if (this.totalRows == 0) {
                        if (this.archive == false) {
                            this.$router.push(this.ermMasterInfo.redirectURL);
                        }
                    }
                }).catch(error => {
                    this.isBusy = !this.isBusy;
                });
        },
        masterLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
                refEntity: this.ermMasterInfo.refEntity
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.clientLookup;
                    this.entityRecordCustomType = result.typeLookup;
                    this.masterStatus = result.statusLookup;
                    this.allUsers = result.userLookup;
                    console.log(result);
                })

        },
        masterLinkLookupData: function() {
            if (this.ermMasterInfo.masterLinkEntity) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    entity: this.ermMasterInfo.masterLinkEntity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                    .then((response) => {
                        let result = response.data;
                        this.ermLookup = result.entityERMLookup;
                        console.log(result);
                    })
            }
        },
        planMasterLinkLookupData: function() {
            if (this.ermMasterInfo.masterLinkEntity && this.ermMasterInfo.showPlanLinkControl) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    entity: this.ermMasterInfo.masterLinkEntity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/plan/planmasterdetail/lookup/all', data)
                    .then((response) => {
                        let result = response.data;
                        this.erdLookup = result.entityERMLookup;
                        console.log(this.erdLookup);
                    })
            }
        },
        entityRecordMasterCollection: function(id) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: id,
                entity: this.ermMasterInfo.entity
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster = result.entityRecordMaster;
                    this.entityReviewList = result.entityReviewApprovalList;
                    console.log(this.entityReviewList);
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                    this.statusText = this.entityRecordMaster.status;
                    this.isFreeze = this.entityRecordMaster.isFreeze;
                    this.ermShareList = result.ermShareList;
                    this.entityReviewNotApprovalList = this.filterReviewApproval(false);
                    this.entityReviewApprovalList = this.filterReviewApproval(true);
                    this.handleErmTypeChange();
                    this.entityRecordMaster.refErmId = this.entityRecordMaster.refErmId ? this.entityRecordMaster.refErmId : null;
                    if(this.ermMasterInfo.showPlanLinkControl){
                        this.refErdData.ermId = this.entityRecordMaster.refErmId;
                        this.refErdData.id = this.entityRecordMaster.refErdId;
                    }            

                })
        },
        getRefErmList: function(refErmId) {
            let data = {
                refErmId: refErmId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/referm/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.refErmList = result.refErmLookup;
                    console.log(result);
                })
        },
        getProjectEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/projectentityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectERMList = result.projectEntityRecordMasterList;
                    if (this.projectERMList != null && this.projectERMList.length > 0)
                        this.totalRowsRef = this.projectERMList.length;
                })
        },
        getERMReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmasterreference/list', data)
                .then((response) => {
                    let result = response.data;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        updateERMaster: function() {
            if (this.ermMasterInfo.showDateRange) {
                this.entityRecordMaster.startDate = this.entityRecordMaster.startDate ? this.entityRecordMaster.startDate : '';
                this.entityRecordMaster.endDate = this.entityRecordMaster.endDate ? this.entityRecordMaster.endDate : '';
            }
            if (this.ermMasterInfo.showErmDate) {
                this.entityRecordMaster.ermDate = this.entityRecordMaster.ermDate + "-11";
            }
            if (this.ermMasterInfo.showMultipleMasterControl) {
                this.entityRecordMaster.refErmList = [];
                this.refErmList.forEach((item, index) => {
                    this.entityRecordMaster.refErmList.push({ refErmId: this.refErmList[index].id })
                })
            }
            else {
                this.entityRecordMaster.refErmList = [];
            }
            if(this.ermMasterInfo.showPlanLinkControl){
                this.entityRecordMaster.refErmId = this.refErdData.ermId;
                this.entityRecordMaster.refErdId = this.refErdData.id;
            }            

            this.showMasDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMaster)
                .then((response) => {
                    this.showMasDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.ermId = result.id;
                        this.getEntityRecordMasterList(this.entity);
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showMasDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateId: function(id,newErdId) {
            if (!this.matchTextValExists(id,newErdId)) {
                let data = {
                    idInitType: "PRJ",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,
                    vtabType: this.vtabType,                        
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/vtab/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.getEntityRecordMasterList(this.entity);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'

                });
            }                    
        },
        cancelIdEdit: function() {
            this.entityRecordMasters = this.entityRecordMasters.map(data => {
                if(data.id == this.idEdit) {
                    data.entityRecordMasterId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },        
        ermPublicAccess: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isPublic: (itemData.isPublic == true ? false : true),
            }
            console.log(data.entityRecordMasterId, data.isPublic);
            var confirmMessage = '';
            if (data.isPublic == true) {
                confirmMessage = "Are you sure to give public access?"
            } else {
                confirmMessage = "Are you sure to give private access?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/publicaccess', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Record Updated',
                                // message: result.message,
                                position: 'topCenter'
                            });
                            // this.getEntityRecordMasterList(this.entity);
                        }
                    })
                }
                else {
                    this.getEntityRecordMasterList(this.entity);
                }
            })
            .catch(err => {
                // An error occurred
            })
        },

        freezeERM: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isFreeze: (itemData.isFreeze == true ? false : true),
            }
            console.log(data.entityRecordMasterId, data.isFreeze);
            var confirmMessage = '';
            if (data.isFreeze == false) {
                confirmMessage = "Are you sure to unfreeze?"
            } else {
                confirmMessage = "Are you sure to freeze?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/freeze', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Record Updated',
                                // message: result.message,
                                position: 'topCenter'
                            });
                            this.getEntityRecordMasterList(this.entity);
                        }
                    })
                }
            })
        },

        archiveERM: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isArchive: (itemData.isArchive == true ? false : true),
            }
            console.log(data.entityRecordMasterId, data.isArchive);
            var confirmMessage = '';
            if (data.isArchive == false) {
                confirmMessage = "Are you sure to unarchive?"
            } else {
                confirmMessage = "Are you sure to archive?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/archive', data)
                        .then((response) => {
                            let result = response.data;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Record Updated',
                                    // message: result.message,
                                    position: 'topCenter'
                                });
                                this.getEntityRecordMasterList(this.entity);
                            }
                        })
                }
            })
        },
        deleteERM: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: this.entity,
                id: this.deleteParam.id,               
            }
            console.log(data);
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/erm/delete', data)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEntityRecordMasterList(this.entity);
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },

        cancelEdit: function() {
            this.entityRecordMasterCollection(this.entityRecordMaster.id);
            this.elements.editMode = false;
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        onFilteredSS(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data);
                this.elements.showDetail = true;
                this.elements.editMode = false;

                this.ermId = data.id;

                // this.setMasterData(data.id, data.title);
                this.entityRecordMasterCollection(data.id);
                this.masterLookupData();
                this.getAttachment(data.id);
                // alert(data.title);
                

            }
        },
        addRef: function(data) {
            this.addReference.vhextAccountId = this.vhextAccountId;
            this.addReference.projectId = this.projectId;
            this.addReference.entityId = this.entityRecordMaster.entityId;
            this.addReference.entityRecordId = data.entityRecordMasterId;
            this.addReference.ermId = this.entityRecordMaster.id;
            this.addReference.erdId = '';
            this.addReference.refEntityId = data.entityId;
            this.addReference.refErmId = data.id;
            this.addReference.refErdId = data.refErdId;
            this.addReference.title = data.title;
            this.addReference.entity = this.entity
            this.addReference.addedById = this.userId;
            this.saveRef(this.entityRecordMaster.entityId);
        },
        saveRef: function() {
            if (!this.isItemExists(this.ermReferenceList, 'title', this.addReference.title)) {
                axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Reference Added',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getERMReferenceList();
                        }
                    })
            }
            else {
                iziToast.info({
                    message: 'Reference already exists',
                    position: 'topRight'
                });
            }
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        deleteFile: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        filterReviewApproval(value) {
            return this.entityReviewList.filter(function(data, key) {
                if (data.isApproval == value) {
                    return data;
                }
            });
        },
        routeDirect: function(ermId,ermTitle,ermIsFreeze,ermCreatedById) {

            this.setMasterData(ermId,'','',ermTitle,ermIsFreeze,ermCreatedById)

            console.log(ermId, ermTitle,ermIsFreeze,ermCreatedById);

            this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })

            return;
        },

        routeReport: function(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze,ermCreatedById) {
            this.setMasterData(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze,ermCreatedById)

            console.log(ermId,ermType, ermTitle,ermIsFreeze,ermCreatedById);

            this.$router.push({ name: this.ermMasterInfo.previewURLName, params: { ermId: ermId } })

            return;
        },

        redirectPage: function(ermId, ermType,ermTypeCode, ermTitle,ermIsFreeze,ermStartDate,ermEndDate,ermCreatedById) {
            if (this.ermMasterInfo.entity == 'budget_plan_master') {
                if (ermTypeCode == 'MOB') {
                    localStorage.setItem('ermStartDate', ermStartDate);
                    localStorage.removeItem('ermEndDate');
                }
                else if (ermTypeCode == 'PEB') {
                    localStorage.setItem('ermStartDate', ermStartDate);
                    localStorage.setItem('ermEndDate', ermEndDate);
                }
                else {
                    localStorage.removeItem('ermStartDate');
                    localStorage.removeItem('ermEndDate');
                }
            }

            this.setMasterData(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze,ermCreatedById)
            console.log("ERM",ermId,ermType,ermTitle,ermIsFreeze,ermCreatedById);
            this.routhPath = this.$route.path;
            console.log(this.routhPath);

            if (this.routhPath != null && this.routhPath.indexOf('service') == -1) {
                switch (ermType) {
                    case "Meeting Plan":
                        this.$router.push('/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/reportingplan/' + ermId);
                        break;
                    // case "Project Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/budgetplan/' + ermId);
                    //     break;
                    // case "Monthly Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/monthlybudgetplan/' + ermId);
                    //     break;
                    // case "Periodic Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/periodicbudgetplan/' + ermId);
                    //     break;                        
                    case "Project Funding":
                        this.$router.push('/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            } else {
                switch (ermType) {
                    case "Meeting Plan":
                        this.$router.push('/service/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/service/reportingplan/' + ermId);
                        break;
                    // case "Project Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/service/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/service/budgetplan/' + ermId);
                    //     break;
                    // case "Monthly Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/service/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/service/monthlybudgetplan/' + ermId);
                    //     break;
                    // case "Periodic Budget":
                    //     if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                    //         this.$router.push('/service/financialplan/' + ermId);
                    //         break;
                    //     }
                    //     this.$router.push('/service/periodicbudgetplan/' + ermId);
                    //     break;

                    case "Project Funding":

                        this.$router.push('/service/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/service/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            }

        },
        setMasterData: function(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze,ermCreatedById) {

            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermType', ermType);
            localStorage.setItem('ermTypeCode', ermTypeCode);
            localStorage.setItem('ermTitle', ermTitle);
            localStorage.setItem('ermIsFreeze', ermIsFreeze);
            localStorage.setItem('ermCreatedById', ermCreatedById);
            

            console.log("ERM",ermId,ermType,ermTitle,ermIsFreeze,ermCreatedById);
        },

        onYesAttachment: function(id) {
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment(this.ermId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveAttachment: function() {
            if (this.masterAttachments != null && this.masterAttachments.length > 0) {
                this.error = false;
                let formData = new FormData();
                for (const row of Object.keys(this.masterAttachments)) {
                    formData.append('files', this.masterAttachments[row]);
                }
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('attachedById', this.userId);
                formData.append('projectId', this.projectId);
                formData.append('refEntity', this.entity);
                formData.append('refRecordId', this.ermId);
                this.showMasDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.showMasDetOverlay = false;
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.masterAttachments = [];
                            //this.$refs.fileinput.reset();
                            //this.$refs['fileinput'].reset();
                            this.getAttachment(this.ermId);
                        }
                        console.log('Attachment uploaded');
                    })
                    .catch(error => {
                        this.showMasDetOverlay = false;
                        console.log('Error Attachment uploaded');
                    });
            } else {
                this.error = true;
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
            }
        },
        getAttachment: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                })
        },
        // setERMData: function(ermId, ermTitle,ermIsFreeze) {

        //     localStorage.setItem('ermId', ermId);
        //     localStorage.setItem('ermTitle', ermTitle);
        //     localStorage.setItem('ermIsFreeze', ermIsFreeze);


        //     console.log(ermId, ermTitle);

        //     let ermData = {
        //         ermId: ermId,
        //         ermTitle: ermTitle,
        //     }
        //     console.log(ermData);

        //     this.$store.dispatch("setERMData", ermData);

        //     console.log(this.$store.getters.getERMData.erm_id, this.$store.getters.getERMData.erm_title);

        // },

        taskAssignment: function(data) {
            this.masterData.refErmId = data.id;
            this.masterData.refEntityId = data.entityId;
            this.masterData.refEntity = this.entity;
            this.masterData.refErdTitle = data.title;
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.refErmId, this.refEntityId);
        },
        ermShare: function(data) {
            console.log(data);
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.ermShareKey++;
            this.$nextTick(() => {
                this.$refs.ermShareRef.onRefDataChange();
            })
            console.log(this.masterData.refErmId, this.masterData.title);
        },
        reviewShare: function(data) {
            console.log(data);
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.ermReviewKey++;
            this.$nextTick(() => {
                this.$refs.reviewShareRef.onRefDataChange();
            });
            console.log(this.masterData.refErmId, this.masterData.title);
        },
        approvalShare: function(data) {
            console.log(data);
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.ermApprovalKey++;
            this.$nextTick(() => {
                this.$refs.approvalShareRef.onRefDataChange();
            });
            console.log(this.masterData.refErmId, this.masterData.title);
        },

        ermCopy: function(data) {
            console.log(data);

            this.masterData.refErmId = data.id;
            this.masterData.title = data.title;
            this.masterData.refEntity = this.entity;
            console.log(this.masterData.refErmId, this.masterData.title,this.entity);

            this.ermCopyKey ++;
            this.$nextTick(() => {
                this.$refs.ermCopyRef.onRefDataChange();
            });
        },

        getERMList: function() {
            this.archive = false;
            this.isArchive = false;

            this.getEntityRecordMasterList(this.entity);
            console.log(this.masterData);
        },
        getERMHistory: function() {
            this.archive = true;
            this.isArchive = true;

            this.getEntityRecordMasterList(this.entity);
            console.log(this.masterData);
        },
        showDetail: function(item, isArray) {
            let id = ''
            if (isArray) {
                id = item.id;
            } else id = item;

            this.entityRecordMasters = this.entityRecordMasters.map((data) => {
                if (data.id !== id) {
                    return { ...data, _showDetails: false }
                } else {
                    return { ...data, _showDetails: true }
                }
            })
            this.$nextTick(() => {
                this.entityRecordMasters.filter((data) => {
                    if (data._showDetails) {
                        let index = this.entityRecordMasters.indexOf(data);
                        console.log(index);
                        this.$refs.ermTable.selectRow(index);
                    }
                })
            })

        },
        matchTextValExists: function(id,textVal) {
            let result = false;
            this.entityRecordMasters.map(data => {
                if (textVal && data.entityRecordMasterId && data.id !== id) {
                    if (data.entityRecordMasterId == textVal) {
                        result = true;
                    }
                }
            });
            return result;
        },
        async redirectReference(ermId, entity,ermTitle) {
            let urlName = await this.getUrlNameByEntity(entity);
            console.log(ermId, entity,ermTitle, urlName)
            if (urlName != '')
                localStorage.setItem('ermId', ermId);
                localStorage.setItem('ermTitle', ermTitle);

                this.$router.push({ name: urlName, params: { ermId: ermId } })
        },
        handleErmTypeChange: function() {
            let typeData = this.entityRecordCustomType.find((data) => {
                if (data.id == this.entityRecordMaster.ermTypeId) {
                    return data;
                }
            });
            if (this.ermMasterInfo.entity == 'budget_plan_master') {
                let ermDate = this.entityRecordMaster.ermDate;
                let startDate = this.entityRecordMaster.startDate;
                let endDate = this.entityRecordMaster.endDate;
                if(typeData && typeData.typeCode == 'MOB') {
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showErmDate = true;
                    this.ermMasterInfo.showMultipleMasterControl = false;

                    this.entityRecordMaster.ermDate = ermDate ? this.getMonthYear(ermDate) : '';
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
                else if(typeData && typeData.typeCode == 'PEB') {
                    this.ermMasterInfo.showDateRange = true;
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = startDate ? this.getDateValue(startDate) : '';
                    this.entityRecordMaster.endDate = endDate ? this.getDateValue(endDate) : '';
                }
                else if(typeData && typeData.typeCode == 'BTI') {
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showMultipleMasterControl = true;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
                else {
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = startDate ? startDate : '';
                    this.entityRecordMaster.endDate = endDate ? endDate : '';
                }
            }
            if (this.ermMasterInfo.showMultipleMasterControl && this.entityRecordMaster.id) {
                this.getRefErmList(this.entityRecordMaster.id);
            }
        }
    }
}
</script>