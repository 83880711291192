<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12 pr-0">
                    <div class="row">
                        <div class="col-2 col-lg-2 col-sm-2 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">Issue Board</h4>
                        </div>
                        <div class="col-2 pr-0 mr-0">
                            <select v-model="ermObject" :title="ermObject.value" @change="ermFilter(ermObject)" class="form-control">
                                <option value="all">All Issue Group</option>
                                <option v-for="(info, index) in ermLookup" :value="{id: info.id, value:info.value, projectId:info.projectId, projectTypeId:info.projectTypeId}">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col-1 px-0">
                            <div class="d-flex align-middle">
                                <a class="cust-setting-icon mr-1" href="javascript:" v-b-modal.manageErmMasterPopup title="Manage Task Plan"><i class="fa fa-cog fa-lg action-link" aria-hidden="true"></i></a>
                            </div>
                        </div> 
                        <div class="col-2 col-lg-2 col-sm-2">
                            <div class="dataTables_filter">
                                <label>
                                    <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                    <input type="search" v-model="search" class="form-control search-task" placeholder="Search Title..">
                                </label>
                            </div>
                        </div>
                        <div class="col-2 col-lg-2 col-sm-2 mt-1">
                            <b-button class="addnew-tassk-btn float-right ml-5" variant="outline-info" @click="openQuickIssue('', false)" size="sm"><i class="fa fa-plus"></i> New Issue</b-button>
                        </div>
                        <div class="col-3 col-lg-3 col-sm-3 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0 pr-3">
                                    <li v-if="isArchive" class="breadcrumb-item">
                                        <a href="javascript:" class="action-link" @click="getArchiveIssue(false)"><i class="fa fa-history pr-2" ></i>Issue</a>
                                    </li>
                                    <li v-else class="breadcrumb-item">
                                        <a href="javascript:" class="action-link" @click="getArchiveIssue(true)"><i class="fa fa-history pr-2" ></i>Archive</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" class="action-link" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>                                    
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay  :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <b-table v-if="isArchive" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :items="filteredIssueList" :fields="issueFields" responsive="sm" :filter="taskFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(title)="data">
                        <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                    </template> 
                    <template v-slot:cell(reportedById)="data">    
                        {{ data.item.reportedBy }}
                    </template>
                    <template v-slot:cell(solutionById)="data">    
                        {{ data.item.solutionBy }}
                    </template>
                    <template v-slot:cell(statusId)="data">    
                        {{ data.item.status }}
                    </template>
                    <template v-slot:cell(actions)="data">
                    <!--  <span v-if="data.item.assignedById == userId">
                            <a href="#" class="mr-2" title="UnArchive" @click="archiveIssue(issue)"><i class="fa fa-undo" aria-hidden="true"></i></a>
                        </span> -->
                        <span> 
                            <a href="#" class="mr-2 action-link" title="UnArchive" @click="archiveIssue(data.item)"><i class="fa fa-undo" aria-hidden="true"></i></a>
                        </span> 
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Record found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <div v-else class="row">
                    <div class="col-12 col-lg-12 col-xl-12 pr-2 position-relative">
                        <div class="row mr-0 pl-2">
                            <div v-for="status in issueStatus" class="col-12 col-md mb-1 px-1">
                                <div class="card mb-2 b-radius-5 ">
                                    <div class="card-body px-3 py-2">
                                        <div class="form-group mb-0">
                                            <label v-if="status.value == 'New Issue' || status.value == 'Re-opened'" class="mt-1 mb-0 font-weight-bold" for="title">New Issue / Re-Opened
                                                <b-badge class="ml-2" title="Issues">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <label v-else class="mt-1 mb-0 font-weight-bold" for="title">{{ status.value }}
                                                <b-badge class="ml-2" title="Issues">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <b-button class="pull-right addnew-quicktask-btn" @click="openQuickIssue(status.id, false)" variant="outline-info" size="sm"><i class="fa fa-plus pr-1"></i>Issue</b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="taskLists">
                                    <div v-for="issue in filteredIssueList">
                                        <template v-if="status.value == 'New Issue'">
                                            <div v-if="issue.status == 'New Issue' || issue.status == 'Re-opened'" class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(issue)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Issue Details</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item  py-1" @click="openQuickIssue(issue, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Issue</a>
                                                        <a v-if="issue.reportedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(issue)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="issue.reportedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CRM' || userTypeCode == 'CAM' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="openTaskAssignmentRefPopup(issue.entityRecordMasterId, issue.id)" href="javascript:"><i class="fa fa-list pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Assignment</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(issue)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Status Update</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item py-1" @click="deleteIssue(issue.id)" data-toggle="modal" data-target="#deleteIssue" href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Issue</a>
                                                        <a v-if="issue.reportedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveIssue(issue)" title="Move to Archive" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="issue.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ issue.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(issue)">{{ issue.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div v-if="(issue.activity != null) || (issue.severity != null)">
                                                            <div class="row">
                                                                <div class="col-7 pr-0">
                                                                    <span title="Issue Type" class="subtle">{{ issue.type }}</span>
                                                                </div>
                                                                <div class="col-5 pl-0">
                                                                    <span v-if="issue.severity" title="Severity" class="subtle float-right"><i class="fa fa-exclamation-triangle pr-1 pointer" :class="severityColour(issue.severity)"></i>{{ issue.severity }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="issue.reportedBy != null">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="card-media-object-social-list">
                                                                        <b-badge class="task-badge" title="Reported By"> {{ issue.reportedBy }} </b-badge>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="issue.createdDt != null">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <span title="Created Date" class="subtle float-left"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ issue.createdDt | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="status.value !== 'New Issue'">
                                            <div v-if="issue.status == status.value" class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(issue)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Issue Details</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item  py-1" @click="openQuickIssue(issue, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Issue</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(issue)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(issue)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Status Update</a>
                                                        <a v-if="issue.reportedById == userId" class="dropdown-item py-1" @click="deleteIssue(issue.id)" data-toggle="modal" data-target="#deleteIssue" href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Issue</a>
                                                        <a v-if="issue.reportedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveIssue(issue)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="issue.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ issue.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(issue)">{{ issue.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div v-if="(issue.activity != null) || (issue.severity != null)">
                                                            <div class="row">
                                                                <div class="col-7 pr-0">
                                                                    <span title="Issue Type" class="subtle">{{ issue.type }}</span>
                                                                </div>
                                                                <div class="col-5 pl-0">
                                                                    <span v-if="issue.severity" title="Severity" class="subtle float-right"><i class="fa fa-exclamation-triangle pr-1 pointer" :class="severityColour(issue.severity)"></i>{{ issue.severity }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="issue.reportedBy != null">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="card-media-object-social-list">
                                                                        <b-badge class="task-badge" title="Reported By"> {{ issue.reportedBy }} </b-badge>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="issue.createdDt != null">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <span title="Created Date" class="subtle float-left"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ issue.createdDt | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="!getIssuesByStatusId(status.id).length" class="card-media mb-2">
                                        <div class="card-media-body">
                                            <div class="stage-card text-center">No Issues found</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </b-overlay>
        </div>

        <!-- <AddErmMasterPopup ref="addErmMasterModal" :ermMasterInfo="ermMasterInfo" @update-parent="lookupData" /> -->
        <ManageErmMasterPopup id="manageErmMasterPopup" ref="manageErmMasterPopup" :ermMasterInfo="ermMasterInfo" @update-parent="lookupData();" @closeDialog="showDialog=false"/>  
        <TaskAssignmentRefPopup v-if="showDialog" id="taskAssignmentRefPopup" ref="taskAssignmentRefPopupRef" @closeDialog="closeDialog" />
        <IssueDetailPopup v-if="showDialog" ref="issueDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
        <QuickIssuePopup v-if="showDialog" ref="quickIssuePopupRef" @refreshData="getIssues" @masterLookupRefresh="lookupData" :issueData="issue" @closeDialog="closeDialog" />
        <StatusTrackerPopup v-if="showDialog" ref="statusTrackerPopupRef" :statusUpdate="statusUpdate" @closeDialog="closeDialog" @updateParent="getIssues" />
        <Dialog id="deleteIssue" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Issue?" />

    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import ManageErmMasterPopup from '@/components/popup/action/ManageErmMasterPopup.vue';
import IssueDetailPopup from '@/components/popup/itemdetail/IssueDetailPopup.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import StatusTrackerPopup from '@/components/popup/action/StatusTrackerPopup.vue';
import TaskAssignmentRefPopup from '@/components/popup/itemdetail/TaskAssignmentRefPopup.vue';
import QuickIssuePopup from '@/components/popup/action/QuickIssuePopup.vue';
export default {
    name: 'app',
    components: {
        ManageErmMasterPopup,
        Editor,
        IssueDetailPopup,
        QuickTaskPopup,
        StatusTrackerPopup,
        TaskAssignmentRefPopup,
        QuickIssuePopup,
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            entity: 'issue',
            isArchive: false,
            showOverlay: false,
            showDialog: false,
            detailViewData: {},
            attachmentData: {},
            referencePopupId: 0,
            ermMasterInfo: {
                title: "Issue Group",
                entity: 'issue_master',
                refEntity: 'issue',
                tooltip: 'New Issue Group',
            },

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',
                statusId: 1,
            },

            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                refEntityId: '',
                functionalEntity: 'issue',
                refEntity: 'issue',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },

            issue: {
                editMode: null,
                id: '',
                issueId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId:'',
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'issue',
                entityId: '',
                entityRefId: '',
                title: '',
                isArchive: false,
                issueTypeId: '',
                type: '',
                functionalityId: '',
                functionality: '',
                activityId: '',
                activity: '',
                description: '',
                solution: '',
                reportedById: localStorage.getItem("userId"),
                reportedBy: '',
                reportedDt: new Date().toISOString().substr(0, 10),
                solutionById: '',
                solutionBy: '',
                solutionDt: '',
                actionTaken: '',
                severityId: '',
                severity: '',
                statusId: '',
                status: '',
                solutionDt: ''
            },            

            allIssues: [],
            issues: [],
            allStatus: [],
            ermLookup: [],

            issueFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Issue', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'reportedDt', label: 'Reported Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'solutionDt', label: 'Solution Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'reportedBy', label: 'Reported By', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'solutionById', label: 'Solved By', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],


            ermObject: {
                id: '',
                value: '',
                projectId: '',
                projectTypeId: '',
            },

            search: '',
            taskFilter: null, 
            selectMode: 'multi',
            filterOn: [], 
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        filteredIssueList() {
            return this.issues.filter(item => {
                return item.title.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        issueStatus() {
            return this.allStatus.filter(status => status.value !== 'Draft' && status.value !== 'Re-opened');
        }
    },
    mounted() {
        this.getIssues();
        this.lookupData();
    },
    methods: {
        getIssues: function() {
            let data = {
                vhextAccountId: this.issue.vhextAccountId,
                projectId: this.issue.projectId,
                userId: this.userId,
                isArchive: this.isArchive,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/issue/issueboard/list', data)
                .then((response) => {
                    let result = response.data;
                    this.issues = result.issueList;
                    this.allIssues = this.issues;

                    console.log("ISSUES",this.issues);

                    this.$nextTick(() => {
                        this.ermFilter(this.ermObject);
                    })
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/issue/issueboard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;
                    this.allStatus = result.statusLookup;

                    this.ermObject.id = this.ermLookup[0].id;
                    this.ermObject.value = this.ermLookup[0].value;
                    this.ermObject.projectId = this.ermLookup[0].projectId;
                    this.ermObject.projectTypeId = this.ermLookup[0].projectTypeId;

                    
                    console.log("ERM",this.ermLookup);
                    console.log("ERM",this.ermObject);
                })

        },
        ermFilter: function(ermData) {
            if (ermData.id !== null && ermData.id) {
                this.issues = this.allIssues;
                if (this.issues != null) {
                    var issues = this.issues;
                    this.issues = issues.filter(function (issue) {
                        return issue.entityRecordMasterId == ermData.id;
                    });
                }
                this.setERMData(ermData.id,ermData.value);
            }
            else if (ermData == 'all') {
                this.issues = this.allIssues;
            }
        },

        deleteIssue: function(id) {
             this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/issue/issue/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getIssue();
                    }
                })
                .catch(error => {
                });
        },
        getIssuesByStatusId(statusId) {
            return this.issues.filter(issue => issue.statusId == statusId);
        },
        getArchiveIssue: function(flag){
            this.isArchive=flag;
            this.issues= [];
            this.getIssues();
        },
        archiveIssue: function(item) {
            let data = {
                id: item.id,
                entity: 'issue',
                columnName: 'is_archive',
                stateValue: (item.isArchive ? false : true)
            }
            this.showOverlay=true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay=false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: item.isArchive ? 'Issue Unarchived' : 'Issue Archived',
                            position: 'topRight'
                        });
                        this.getIssues();
                    }
                })
                .catch(error => {
                    this.showOverlay=false;
                });
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.issueDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            this.showDialog = false;
        },
        openQuickIssue: function(data, flag) {

            if (flag == true) {
                this.issue.id = data.id;
                this.issue.issueId = data.issueId;
                this.issue.vhextAccountId = data.vhextAccountId;
                this.issue.projectId = data.projectId;
                this.issue.projectTypeId = data.projectTypeId;
                this.issue.entityRecordMasterId = data.entityRecordMasterId;
                this.issue.title = data.title;
                this.issue.isArchive = data.isArchive;
                this.issue.issueTypeId = data.issueTypeId;
                this.issue.type = data.type;
                this.issue.activityId = data.activityId;
                this.issue.activity = data.activity;
                this.issue.functionalityId = data.functionalityId;
                this.issue.functionality = data.functionality;
                this.issue.description = data.description;
                this.issue.solution = data.solution;
                this.issue.actionTaken = data.actionTaken;
                this.issue.reportedById = data.reportedById;
                this.issue.reportedBy = data.reportedBy;
                this.issue.reportedDt = data.reportedDt;
                this.issue.solutionById = data.solutionById;
                this.issue.solutionBy = data.solutionBy;
                this.issue.solutionDt = data.solutionDt;
                this.issue.reportedDt = data.reportedDt;
                this.issue.severityId = data.severityId;
                this.issue.severity = data.severity;
                this.issue.statusId = data.statusId;
                this.issue.status = data.status;
            } else {
                console.log(this.ermObject);
                this.issue.id = '';
                this.issue.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.issue.projectId = this.ermObject.projectId;
                this.issue.projectTypeId = this.ermObject.projectTypeId;
                this.issue.userId = localStorage.getItem("userId");
                this.issue.entity = 'issue';
                this.issue.title = '';
                this.issue.isArchive = false;
                this.issue.issueTypeId = '';
                this.issue.description = '';
                this.issue.severityId = '';
                this.issue.reportedById = localStorage.getItem("userId");
                this.issue.reportedDt = new Date().toISOString().substr(0, 10);
                this.issue.statusId = data;
                this.issue.entityRecordMasterId = this.ermObject.id;
            }
            console.log(this.issue);
            this.issue.editMode = flag;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('quickIssuePopup');
            })
        },
        assignTask: function(data) {
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = 'issue';
            this.masterData.refErdTitle = data.title;
            this.masterData.statusId = 1;
            this.masterData.refErdDescription = data.description;
            this.$refs.ermAssignmentsRef.onRefDataChange();
        },
        showStatusPopup: function(data) {
            if (data && data !== null) {
                this.statusUpdate.id = '';
                this.statusUpdate.statusUpdateId = '';
                this.statusUpdate.refRecordId = data.id;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.refId = data.issueId;
                this.statusUpdate.refRecordTitle = data.title;
                this.statusUpdate.statusById = this.userId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('statusTrackerPopup');
                })
            }
        },
        severityColour(severity) {
            if (severity && (severity == 'High')) {
                return 'severity-high';
            }
            if (severity && severity == 'Critical') {
                return 'severity-critical';
            }
            if (severity && severity == 'Moderate') {
                return 'text-warning';
            }
            else {
                return 'text-success';
            }
        },
        setERMData: function(ermId,ermTitle) {
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);

        },
        getItemCount: function(statusId) {

            return (this.filteredIssueList.filter((itemData)=> itemData.statusId==statusId)).length;

        },
        openTaskAssignmentRefPopup: function(refErmId, refErdId) {
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.taskAssignmentRefPopupRef.getTasks(refErmId, refErdId);
            })
        },
        routeToMasterPage() {
            let routePath = this.$route.path;
            if (routePath.includes('landing')) {
                this.$router.push('/landing/issmas/list');
            } else {
                this.$router.push('/issmas/list');
            }
        },
        redirectPage: function() {
            let routePath = this.$route.path;
            if (routePath.includes('landing')) {
                if (this.ermObject.id != null && this.ermObject.id > 0) {
                    this.$router.push('/landing/issue/' + this.ermObject.id);
                } else {
                    this.$router.push('/landing/issmas/list');
                }
            } else {
                if (this.ermObject.id != null && this.ermObject.id > 0) {
                    this.$router.push('/issue/' + this.ermObject.id);
                } else {
                    this.$router.push('/issmas/list');
                }
            }
        },

    }
}
</script>
