import ActionItem from '@/views/actionitem/ActionItem.vue'
import ActionItemList from '@/views/actionitem/ActionItemList.vue'
import PlanBacklogs from '@/views/plan/PlanBacklogs.vue'
import Plan from '@/views/plan/Plan.vue'
import PlanMaster from '@/views/plan/PlanMaster.vue'
import PlanMasterList from '@/views/plan/PlanMasterList.vue'
import ProjectManagementPlan from '@/views/plan/ProjectManagementPlan.vue'
import PublishedDocument from '@/views/actionitem/PublishedDocument.vue'
import EffortEstimation from '@/views/plan/EffortEstimation.vue'
import ESMaster from '@/views/plan/EffortEstimationMaster.vue'
import ESMasterList from '@/views/plan/EffortEstimationMasterList.vue'
import DevelopmentPlanMaster from '@/views/development/DevelopmentPlanMaster.vue'
import DevPlanMasterList from '@/views/development/DevPlanMasterList.vue'
import TaskBoard from '@/views/task/TaskBoard.vue'
import TaskGanttChart from '@/views/task/TaskGanttChart.vue'
import TaskList from '@/views/task/TaskList.vue'
import TaskPlan from '@/views/plan/PlanBacklogs.vue'
import AddTask from '@/views/task/AddTask.vue'
import TaskMenu from '@/views/task/TaskMenu.vue'
import TimeSheet from '@/views/task/TimeSheet.vue'
import TimeSheetReport from '@/views/task/TimeSheetReport.vue'
//import TimeSheetMaster from '@/views/task/DevelopmentPlanMaster.vue'
import TimeSheetMasterList from '@/views/task/TimeSheetMasterList.vue'
import TSReportMasterList from '@/views/task/TimeSheetReportMasterList.vue'

export default [{
        path: '/actionitem',
        name: 'actionitem',
        component: ActionItem,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/actionitem/list',
        name: 'actionitemlist',
        component: ActionItemList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/backlogs',
        name: 'backlogs',
        component: PlanBacklogs,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/plan/:ermId',
        name: 'plan',
        component: Plan,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/plnmas',
        name: 'plnmas',
        component: PlanMaster,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/plnmas/list',
        name: 'plnmaslist',
        component: PlanMasterList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/prjmpl',
        name: 'prjmpl',
        component: ProjectManagementPlan,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/pbldoc',
        name: 'pbldoc',
        component: PublishedDocument,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/effortestimation/:ermId',
        name: 'effortestimation',
        component: EffortEstimation,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/esmaster',
        name: 'esmaster',
        component: ESMaster,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/esmaster/list',
        name: 'esmasterlist',
        component: ESMasterList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/dvpmas',
        name: 'dvpmas',
        component: DevelopmentPlanMaster,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/dvpmas/list',
        name: 'dvpmaslist',
        component: DevPlanMasterList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/tskbrd',
        name: 'tskbrd',
        component: TaskBoard,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/task/:ermId',
        name: 'task',
        component: TaskList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/taskganttchart',
        name: 'taskganttchart',
        component: TaskGanttChart,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/task/add',
        name: 'addtask',
        component: AddTask,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/taskmenu',
        name: 'taskmenu',
        component: TaskMenu,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/landing/tskpln',
        name: 'lantskpln',
        component: TaskPlan,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM' }
    },
    {
        path: '/timesheet/:ermId',
        name: 'timesheet',
        component: TimeSheet,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/timesheetreport/:ermId',
        name: 'timesheetreport',
        component: TimeSheetReport,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },

/*        {
        path: '/tmsmas',
        name: 'tmsmas',
        component: TimeSheetMaster,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },*/
    {
        path: '/tmsmas/list',
        name: 'tmsmaslist',
        component: TimeSheetMasterList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },
    {
        path: '/tmrmas/list',
        name: 'tmrmaslist',
        component: TSReportMasterList,
        meta: { accessedby: 'CRP|CRM|PRM|EMP|TMB|CAM|VSA' }
    },

    ];