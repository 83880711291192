import Project from '@/views/project/Project.vue'
import AddProject from '@/views/project/AddProject.vue'
import EditProject from '@/views/project/EditProject.vue'
// import ProjectView from '@/views/project/ViewProject.vue'
import VhextAccount from '@/views/vhextaccount/VhextAccount.vue'
import VhextServicePlan from '@/views/vhextaccount/VhextServicePlan.vue'
import Document from '@/views/document/EDocs.vue'
import Client from '@/views/client/Client.vue'
import AddClient from '@/views/client/AddClient.vue'
import EditClient from '@/views/client/EditClient.vue'
import ViewClient from '@/views/client/ViewClient.vue'
import ReviewPlan from '@/views/reviewplan/ReviewPlan.vue'
import Issue from '@/views/project/Issue.vue'
import IssueMaster from '@/views/project/IssueMaster.vue'
import IssueMasterList from '@/views/project/IssueMasterList.vue'
import IssueBoard from '@/views/project/IssueBoard.vue'
import IssueTracking from '@/views/project/IssueTracking.vue'
import IssueTrackingMasterList from '@/views/project/IssueTrackingMasterList.vue'
import DocumentFolder from '@/views/document/DocumentFolder.vue'
import DocumentFolderMaster from '@/views/document/DocumentFolderMaster.vue'
import DocumentFolderMasterList from '@/views/document/DocumentFolderMasterList.vue'
import ProjectPlanMaster from '@/views/project/ProjectPlanMaster.vue'
import ProjectPlanMasterList from '@/views/project/ProjectPlanMasterList.vue'
import ViewProject from '@/views/project/ViewProject.vue'

export default [{
        path: '/project',
        name: 'project',
        component: Project,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/landing/project/add',
        name: 'addproject',
        component: AddProject,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/project/edit/:projectid',
        name: 'editproject',
        component: EditProject,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    // {
    //     path: '/project/view/:projectid',
    //     path: '/project/view',
    //     name: 'viewproject',
    //     component: ViewProject,
    //     meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    // },
    {
        path: '/projectview',
        name: 'projectview',
        component: ViewProject,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA' }
    },
    {
        path: '/vhextserviceplan',
        name: 'vhextserviceplan',
        component: VhextServicePlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/vhextaccount',
        name: 'vhextaccount',
        component: VhextAccount,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/client',
        name: 'client',
        component: Client,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/client/add',
        name: 'addclient',
        component: AddClient,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/client/edit/:clientid',
        name: 'editclient',
        component: EditClient,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/client/view/:clientid',
        name: 'viewclient',
        component: ViewClient,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/docshr/list',
        name: 'docshr',
        component: Document,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/review/reviewplan',
        name: 'reviewplan',
        component: ReviewPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/issue/:ermId',
        name: 'issue',
        component: Issue,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/issmas',
        name: 'issmas',
        component: IssueMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/issmas/list',
        name: 'issmaslist',
        component: IssueMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/issbrd',
        name: 'issbrd',
        component: IssueBoard,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/projectdocument',
        name: 'projectdocument',
        component: DocumentFolder,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pdcmas',
        name: 'pdcmas',
        component: DocumentFolderMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pdcmas/list',
        name: 'pdcmaslist',
        component: DocumentFolderMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pplmas',
        name: 'pplmas',
        component: ProjectPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pplmas/list',
        name: 'pplmaslist',
        component: ProjectPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/issuetracking/:ermId',
        name: 'issuetracking',
        component: IssueTracking,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/istmas/list',
        name: 'istmaslist',
        component: IssueTrackingMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

];