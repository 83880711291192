<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Payroll Components</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Payroll Component</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6 col-lg-4 col-xl-5">
                                                        <div class="form-group">
                                                            <label for="standardPayslipComponentId">Payroll Component</label>
                                                            <a href="javascript:" class="float-right button-add action-link" @click="toggleDropdown">
                                                                <i v-if="elements.showDropdownPayslipComp" title="Add New Component" class="fa fa-lg fa-plus-square-o"></i>
                                                                <i v-else title="Select Existing Component" class="fa fa-lg fa-caret-down"></i>
                                                            </a>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payslipComponent.payslipComponent.$error }">
                                                                <select v-if="elements.showDropdownPayslipComp" v-model="payslipComponent.standardPayslipComponentId" @change="onChangeRefComponent" class="form-control" id="standardPayslipComponentId">
                                                                    <option value="">Select Existing Component</option>
                                                                    <option v-for="(info, index) in standardPayslipComponents" :value="info.id">{{info.payslipComponent}}</option>
                                                                </select>
                                                                <input v-else type="text" class="form-control" v-model="payslipComponent.payslipComponent" id="payslipComponent" :class="{ 'is-invalid': $v.payslipComponent.payslipComponent.$error }" placeholder="Enter Payroll Component">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.payslipComponent.payslipComponent.$error && !$v.payslipComponent.payslipComponent.minLength" class="text-danger">Payroll Component title must have at least {{$v.payslipComponent.payslipComponent.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.payslipComponent.payslipComponent.$error && !$v.payslipComponent.payslipComponent.required" class="text-danger">
                                                                <span v-if="elements.showDropdownPayslipComp">        
                                                                    Please select Payroll Component
                                                                </span>
                                                                <span v-else>        
                                                                    Please enter Payroll Component title
                                                                </span>
                                                            </label>
                                                            <label v-if="$v.payslipComponent.payslipComponent.$error && !$v.payslipComponent.payslipComponent.maxLength" class="text-danger">Payroll Component title must not exceed {{$v.payslipComponent.payslipComponent.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <!-- <div v-if="false" class="col-6 col-lg-5 col-xl-6">
                                                        <div class="form-group">
                                                            <label for="payslipComponent">Component Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payslipComponent.payslipComponent.$error }">
                                                                <input type="text" class="form-control" v-model="payslipComponent.payslipComponent" id="payslipComponent" :class="{ 'is-invalid': $v.payslipComponent.payslipComponent.$error }" placeholder="Enter Payroll Component">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.payslipComponent.payslipComponent.$error && !$v.payslipComponent.payslipComponent.minLength" class="text-danger">Payroll Component title must have at least {{$v.payslipComponent.payslipComponent.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.payslipComponent.payslipComponent.$error && !$v.payslipComponent.payslipComponent.required" class="text-danger">Please enter Payroll Component title</label>
                                                            <label v-if="$v.payslipComponent.payslipComponent.$error && !$v.payslipComponent.payslipComponent.maxLength" class="text-danger">Payroll Component title must not exceed {{$v.payslipComponent.payslipComponent.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div> -->
                                                    <div class="col-6 col-lg-4 col-xl-4">
                                                        <div class="form-group">
                                                            <label for="payslipComponentTypeId">Component Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payslipComponent.payslipComponentTypeId.$error }">
                                                                <select v-model="payslipComponent.payslipComponentTypeId" class="form-control" :class="{ 'is-invalid': $v.payslipComponent.payslipComponentTypeId.$error }" id="payslipComponentTypeId" :disabled="payslipComponent.standardPayslipComponentId ? true : false">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in payslipComponentTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.payslipComponent.payslipComponentTypeId.$error && !$v.payslipComponent.payslipComponentTypeId.required" class="text-danger">Please select a type</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-lg col-xl-2">
                                                        <div class="form-group">
                                                            <label for="budget">Is Taxable</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="payslipComponent.isTaxable" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-lg col-xl-1">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="payslipComponent.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label>Component Calculation</label>
                                                <div class="card p-3 mb-3">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label>Calculation Logic</label>
                                                                <select v-model="payslipComponent.standardBusinessLogicId" @change="payslipComponent.percentVal='';payslipComponent.fixedVal='';" class="form-control" >
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in payrollBusinessLogics" :value="info.id">{{info.title}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div v-if="payslipComponent.standardBusinessLogicId" class="col-6">
                                                                <!-- <label>Calculation</label> -->
                                                            <div class="form-row">
                                                                <div v-if="getBusinessLogicData.sblCode=='pctc' || getBusinessLogicData.sblCode=='pbas'" class="col-12">
                                                                    <label >Percentage Value</label>
                                                                    <b-input-group :append="getBusinessLogicData.title" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <!-- <div v-if="getBusinessLogicData.sblCode=='pbac'" class="col-12">
                                                                    <b-input-group append="% of Basic" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='pbds'" class="col-12">
                                                                    <b-input-group append="% of Basic" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='lpc'" class="col-12">
                                                                    <b-input-group append="* LOP Days" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div> -->
                                                                <div v-if="getBusinessLogicData.sblCode=='pbda'" class="col-12">
                                                                    <b-input-group append="% of Basic + DA" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='pgrs'" class="col-12">
                                                                    <b-input-group append="% of Gross" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='pfc'" class="col-12">
                                                                    <b-input-group append="% of Basic" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                    <b-input-group prepend="Max Limit" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.fixedVal" id="percentVal" placeholder="Max Limit Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='fix'" class="col-12">
                                                                    <label >Amount</label>
                                                                    <input type="number" class="form-control" v-model="payslipComponent.fixedVal" id="payslipComponent" placeholder="Enter Fixed Amount">
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='lopfix'" class="col-12">
                                                                    <b-input-group prepend="No. of Working Days" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.fixedVal" id="percentVal" placeholder=""></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='otmphs'" class="col-12">
                                                                    <b-input-group append="% of Hourly Salary" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='otmfix'" class="col-12">
                                                                    <b-input-group append="per Hour" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.fixedVal" id="percentVal" placeholder="Fixed Amount"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <!-- <div v-if="getBusinessLogicData.sblCode=='lopmth'" class="col-6">
                                                                    <b-input-group append="% of Gross" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div>
                                                                <div v-if="getBusinessLogicData.sblCode=='lopwrd'" class="col-6">
                                                                    <b-input-group append="% of Gross" class="mb-2">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div> -->
                                                                <!-- <div v-if="getBusinessLogicData.sblCode=='txc'" class="col-6">
                                                                    <b-input-group :append="'% of ' + (payslipComponent.isBasic ? ' CTC' : '')" class="">
                                                                        <b-form-input type="number" class="form-control" v-model="payslipComponent.percentVal" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                    </b-input-group>
                                                                </div> -->
                                                                <div v-if="getBusinessLogicData.description" class="col-12 mt-3">
                                                                    <div class="text-muted w-100 border p-2">
                                                                        {{ getBusinessLogicData.description }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="budget">Description</label>
                                                            <textarea v-model="payslipComponent.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="savePayslipComponent" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="payslipComponentTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="payslipComponentList"  @row-selected="onRowSelected" :fields="payslipComponentFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(isTaxable)="data">
                                    <i v-if="data.value" class="fa fa-check"></i>
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="!data.item.isDefault" href="javascript:" title="Delete" @click="deletePayslipComponent(data.item.id)" v-b-modal.deletePayslipComponent><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Payroll Components found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                            <b-pagination v-if="payslipComponentList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <!-- <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{payslipComponentDetail.payslipComponentId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div> -->
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updatePayslipComponent" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="payslipComponent">Component Title</label>
                                                    <input type="text" class="form-control" id="payslipComponent" :disabled="!elements.editMode" v-model='payslipComponentDetail.payslipComponent'>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <!-- <div class="col-6">
                                                <div class="form-group">
                                                    <label for="standardPayslipComponentId">Std Component Map</label>
                                                    <select v-model="payslipComponentDetail.standardPayslipComponentId" @change="onChangeRefComponentDetail" class="form-control" id="standardPayslipComponentId" :disabled="!elements.editMode">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in standardPayslipComponentsDet" :value="info.id">{{info.payslipComponent}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div class="col-12 col-xl-6">
                                                <div class="form-group">
                                                    <label for="payslipComponentTypeId">Component Type</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.payslipComponentDetail.payslipComponentTypeId.$error }">
                                                        <select v-model="payslipComponentDetail.payslipComponentTypeId" class="form-control" :class="{ 'is-invalid': $v.payslipComponentDetail.payslipComponentTypeId.$error }" :disabled="(!elements.editMode || payslipComponentDetail.standardPayslipComponentId) ? true : false" id="payslipComponentTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in payslipComponentTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.payslipComponentDetail.payslipComponentTypeId.$error && !$v.payslipComponentDetail.payslipComponentTypeId.required" class="text-danger">Please select a type</label>
                                                </div>
                                            </div>
                                            <div class="col-6 col-xl-3">
                                                <div class="form-group">
                                                    <label>Is Taxable</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="payslipComponentDetail.isTaxable" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-6 col-xl-3">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="payslipComponentDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        <label class="mb-1">Component Calculation</label>
                                        <!-- <div class="separator"></div> -->
                                        <div class="card p-3 mb-3">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Calculation Logic</label>
                                                        <div class="form-group">
                                                            <select v-model="payslipComponentDetail.standardBusinessLogicId" :disabled="!elements.editMode" @change="payslipComponentDetail.percentVal='';payslipComponentDetail.fixedVal='';" class="form-control" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in payrollBusinessLogics" :value="info.id">{{info.title}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="payslipComponentDetail.standardBusinessLogicId" class="col-12">
                                                    <div class="form-group">
                                                        <div class="form-row">
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='per'" class="col-12">
                                                                <label >Percentage Value</label>
                                                                <b-input-group :append="getBusinessLogicDataDetail.title" class="">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='pctc' || getBusinessLogicDataDetail.sblCode=='pbas'" class="col-12">
                                                                <label >Percentage Value</label>
                                                                <b-input-group :append="getBusinessLogicDataDetail.title" class="">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='pbda'" class="col-12">
                                                                <b-input-group append="% of Basic + DA" class="mb-2">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='pgrs'" class="col-12">
                                                                <b-input-group append="% of Gross" class="mb-2">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='pfc'" class="col-12">
                                                                <!-- <label >Percentage Value</label> -->
                                                                <b-input-group append="% of Basic" class="mb-2">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                                <b-input-group prepend="Max Limit" class="">
                                                                    <b-form-input type="number" :disabled="!elements.editMode" class="form-control" v-model="payslipComponentDetail.fixedVal" id="percentVal" placeholder="Max Limit Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='fix'" class="col-12">
                                                                <label >Amount</label>
                                                                <input type="number" class="form-control" v-model="payslipComponentDetail.fixedVal" :disabled="!elements.editMode" id="payslipComponentDetail" placeholder="Enter Fixed Amount">
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='lopfix'" class="col-12">
                                                                <b-input-group prepend="No. of Working Days" class="mb-2">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.fixedVal" :disabled="!elements.editMode" id="percentVal" placeholder=""></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='otmphs'" class="col-12">
                                                                <b-input-group append="% of Hourly Salary" class="mb-2">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <div v-if="getBusinessLogicDataDetail.sblCode=='otmfix'" class="col-12">
                                                                <b-input-group append="per Hour" class="mb-2">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.fixedVal" :disabled="!elements.editMode" id="percentVal" placeholder="Fixed Amount"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                            <!-- <div v-if="getBusinessLogicDataDetail.sblCode=='txc'" class="col-12">
                                                                <b-input-group :append="'% of ' + (payslipComponentDetail.isBasic ? ' CTC' : '')" class="">
                                                                    <b-form-input type="number" class="form-control" v-model="payslipComponentDetail.percentVal" :disabled="!elements.editMode" id="percentVal" placeholder="Percentage Value"></b-form-input>
                                                                </b-input-group>
                                                            </div> -->
                                                            <div v-if="getBusinessLogicDataDetail.description" class="col-12 mt-3">
                                                                <div class="text-muted w-100 border p-2">
                                                                    {{ getBusinessLogicDataDetail.description }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="payslipComponent">Description</label>
                                                    <textarea v-model="payslipComponentDetail.description" :disabled="!elements.editMode" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="form-row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='payslipComponentDetail.createdBy' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="payslipComponentDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="payslipComponentDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deletePayslipComponent" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Payroll Component?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import enummixin from "@/mixins/enummixin";
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    mixins: [enummixin],
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                showDropdownPayslipComp: true,
            },
            showOverlay: false,
            showDetOverlay: false,
            isBusy: false,

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'payslip_component',

            mainTableArray: [],

            payslipComponent: {
                id: '',
                payslipComponentId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payslipComponent: '',
                payslipComponentTypeId: '',
                standardPayslipComponentId: '',
                standardBusinessLogicId: '',
                fixedVal: '',
                percentVal: '',
                isTaxable: false,
                isStandard: false,
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                lastUpdatedDt: '',
            },
            payslipComponentDetail: {
                id: '',
                payslipComponentId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payslipComponent: '',
                payslipComponentTypeId: '',
                standardPayslipComponentId: '',
                standardBusinessLogicId: '',
                fixedVal: '',
                percentVal: '',
                isTaxable: false,
                isStandard: false,
                isDefault: false,
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                lastUpdatedDt: '',
            },

            payslipCalcData: {
                id: '',
                payslipCalcId: '',
                standardBusinessLogicId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntityId: '',
                payslipComponent: '',
                mathOperationTypeId: '',
                fixedVal: '',
                percentVal: '',
                percentCalcValOfCompId: '',
                ifExp1ValOfCompId: '',
                mathOperatorEnum: '',
                ifExp2Val: '',
                thenValOfCompId: '',
                elseVal: '',
                execOrder: '',
                active: 1,
                createdById: localStorage.getItem("userId"),
            },

            payslipComponentTypes: [],
            payrollBusinessLogics: [],
            standardPayslipComponentLookup: [],

            payslipComponentList: [],
            payslipComponentFields: [
                {key: 'index',label: 'SN'},
                // { key: 'payslipComponentId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponent', label: 'Component Title', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentType', label: 'Component Type', sortable: true, sortDirection: 'desc' },
                { key: 'standardBusinessLogic', label: 'Calculation Logic', sortable: true, sortDirection: 'desc' },
                // { key: 'standardPayslipComponent', label: 'Std. Component Map', sortable: true, sortDirection: 'desc' },
                { key: 'isTaxable', label: 'Taxable', tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        standardPayslipComponents() {
            // if (this.payslipComponent.payslipComponentTypeId) {
            //     return this.standardPayslipComponentLookup.filter(data => data.payslipComponentTypeId == this.payslipComponent.payslipComponentTypeId);
            // }
            // else {
                return this.standardPayslipComponentLookup.filter(data => !this.payslipComponentList.some(data2 => data2.standardPayslipComponentId == data.id));
            // }
        },
        standardPayslipComponentsDet() {
            // if (this.payslipComponentDetail.payslipComponentTypeId) {
            //     return this.standardPayslipComponentLookup.filter(data => data.payslipComponentTypeId == this.payslipComponentDetail.payslipComponentTypeId);
            // }
            // else {
                return this.standardPayslipComponentLookup;
            // }
        },
        getBusinessLogicData() {
            let resultData = {
                id: '',
                title: '',
                sblCode: '',
                description: '',
            }
            let result = this.payrollBusinessLogics.find(data => data.id == this.payslipComponent.standardBusinessLogicId);
            resultData = result ? result : resultData;
            return resultData;
        },
        getBusinessLogicDataDetail() {
            let resultData = {
                id: '',
                title: '',
                sblCode: '',
                description: '',
            }
            let result = this.payrollBusinessLogics.find(data => data.id == this.payslipComponentDetail.standardBusinessLogicId);
            resultData = result ? result : resultData;
            return resultData;
        },
        payslipBasicComponent() {
            let result = {
                id: '',
                payslipComponent: '',
                payslipComponentTypeId: '',
                percentVal: '',
                standardBusinessLogicId: '',
                standardPayslipComponentId: '',
            };
            if (this.standardPayslipComponentLookup && this.standardPayslipComponentLookup.length>0) {
                result = this.standardPayslipComponentLookup.find(data => data.payslipComponentTypeId==1);
            }
            return result
        }
    },
    mounted() {
        this.getPayslipComponents();
    },
    validations: {
        payslipComponent: {
            payslipComponent: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            payslipComponentTypeId: {
                required,
            }
        },
        payslipComponentDetail: {
            payslipComponent: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            payslipComponentTypeId: {
                required,
            }
        }
    },
    methods: {
        getPayslipComponents: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/list',data)
                .then((response) => {
                    this.lookupData();
                    this.isBusy = false;
                    let result = response.data;
                    this.payslipComponentList = result.payslipComponentList;
                    if (this.payslipComponentList && this.payslipComponentList.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.payslipComponentDetail.id, this.mainTableArray, 'payslipComponentTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                        this.addDefaultPayslipComponent();
                    }
                    this.totalRows = this.payslipComponentList.length;
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/lookup/all', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.payslipComponentTypes = result.typeLookup;
                    this.payrollBusinessLogics = result.standardBusinessLogicLookup;
                    this.standardPayslipComponentLookup = result.standardPayslipComponentLookup;
                })
                .catch((error) => {
                    this.isBusy = false;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        addDefaultPayslipComponent: function() {
            // add default Payroll components(like Basic) if not already exists
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/default/add', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                    this.getPayslipComponents();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        savePayslipComponent: function() {
            this.$v.payslipComponent.$touch();
            if (!this.$v.payslipComponent.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/add', this.payslipComponent)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getPayslipComponents();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updatePayslipComponent: function() {
            this.$v.payslipComponentDetail.$touch();
            if (!this.$v.payslipComponentDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/update', this.payslipComponentDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getPayslipComponents();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewCountry: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let payslipComponentDetail = result.payslipComponent;
                    if (payslipComponentDetail) {
                        this.payslipComponentDetail.id = payslipComponentDetail.id;
                        this.payslipComponentDetail.payslipComponentId = payslipComponentDetail.payslipComponentId;
                        this.payslipComponentDetail.vhextAccountId = payslipComponentDetail.vhextAccountId;
                        this.payslipComponentDetail.payslipComponent = payslipComponentDetail.payslipComponent;
                        this.payslipComponentDetail.payslipComponentTypeId = payslipComponentDetail.payslipComponentTypeId;
                        this.payslipComponentDetail.standardPayslipComponentId = payslipComponentDetail.standardPayslipComponentId;
                        this.payslipComponentDetail.standardBusinessLogicId = payslipComponentDetail.standardBusinessLogicId;
                        this.payslipComponentDetail.fixedVal = payslipComponentDetail.fixedVal;
                        this.payslipComponentDetail.percentVal = payslipComponentDetail.percentVal;
                        this.payslipComponentDetail.isTaxable = payslipComponentDetail.isTaxable;
                        this.payslipComponentDetail.isStandard = payslipComponentDetail.isStandard;
                        this.payslipComponentDetail.isDefault = data.isDefault;
                        this.payslipComponentDetail.description = payslipComponentDetail.description;
                        this.payslipComponentDetail.active = payslipComponentDetail.active;
                        this.payslipComponentDetail.createdById = payslipComponentDetail.createdById;
                        this.payslipComponentDetail.createdBy = payslipComponentDetail.createdBy;
                        this.payslipComponentDetail.lastUpdatedDt = payslipComponentDetail.lastUpdatedDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewCountry(this.payslipComponentDetail.id);
        },
        deletePayslipComponent: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipComponents();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.payslipComponent.id = '';
            this.payslipComponent.payslipComponentId = '';
            this.payslipComponent.payslipComponent = '';
            this.payslipComponent.payslipComponentTypeId = '';
            this.payslipComponent.standardPayslipComponentId = '';
            this.payslipComponent.standardBusinessLogicId = '';
            this.payslipComponent.fixedVal = '';
            this.payslipComponent.percentVal = '';
            this.payslipComponent.isTaxable = false;
            this.payslipComponent.isStandard = false;
            this.payslipComponent.description = '';
            this.payslipComponent.active = true;
            this.payslipComponent.createdById = localStorage.getItem("userId");
            this.$v.payslipComponent.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.payslipComponentDetail.id = data.id;
            this.payslipComponentDetail.payslipComponentId = data.payslipComponentId;
            this.payslipComponentDetail.vhextAccountId = data.vhextAccountId;
            this.payslipComponentDetail.payslipComponent = data.payslipComponent;
            this.payslipComponentDetail.payslipComponentTypeId = data.payslipComponentTypeId;
            this.payslipComponentDetail.standardPayslipComponentId = data.standardPayslipComponentId;
            this.payslipComponentDetail.standardBusinessLogicId = data.standardBusinessLogicId;
            this.payslipComponentDetail.fixedVal = data.fixedVal;
            this.payslipComponentDetail.percentVal = data.percentVal;
            this.payslipComponentDetail.isTaxable = data.isTaxable;
            this.payslipComponentDetail.isStandard = data.isStandard;
            this.payslipComponentDetail.isDefault = data.isDefault;
            this.payslipComponentDetail.description = data.description;
            this.payslipComponentDetail.active = data.active;
            this.payslipComponentDetail.createdById = data.createdById;
            this.payslipComponentDetail.createdBy = data.createdBy;
            this.payslipComponentDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.visible = false;
                this.elements.editMode = false;
                this.elements.showDetail = true;
                this.payslipComponentDetail.id = data.id;
                this.payslipComponentDetail.payslipComponentId = data.payslipComponentId;
                this.payslipComponentDetail.vhextAccountId = data.vhextAccountId;
                this.payslipComponentDetail.payslipComponent = data.payslipComponent;
                this.payslipComponentDetail.payslipComponentTypeId = data.payslipComponentTypeId;
                this.payslipComponentDetail.standardPayslipComponentId = data.standardPayslipComponentId;
                this.payslipComponentDetail.standardBusinessLogicId = data.standardBusinessLogicId;
                this.payslipComponentDetail.fixedVal = data.fixedVal;
                this.payslipComponentDetail.percentVal = data.percentVal;
                this.payslipComponentDetail.isTaxable = data.isTaxable;
                this.payslipComponentDetail.isStandard = data.isStandard;
                this.payslipComponentDetail.isDefault = data.isDefault;
                this.payslipComponentDetail.description = data.description;
                this.payslipComponentDetail.active = data.active;
                this.payslipComponentDetail.createdById = data.createdById;
                this.payslipComponentDetail.createdBy = data.createdBy;
                this.payslipComponentDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        toggleDropdown() {
            this.elements.showDropdownPayslipComp = !this.elements.showDropdownPayslipComp;
            this.payslipComponent.standardPayslipComponentId = '';
            this.payslipComponent.payslipComponent = '';
            // this.payslipComponent.emailId = '';
            // this.payslipComponent.mobile = '';
            // this.payslipComponent.position = '';
            // this.payslipComponent.qualification = '';
            // this.payslipComponent.experienceYears = '';
            // this.payslipComponent.recruitedById = '';
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.payslipComponent.entity = this.entity;
                this.payslipComponent.id = item.id;
                this.payslipComponent.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.payslipComponent.entity = this.entity;
                this.payslipComponent.id = item.id;
                this.payslipComponent.active = 1;

                this.updateActivationState();
            }
            console.log(this.payslipComponent.id, this.payslipComponent.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.payslipComponent)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.payslipComponent.active = true;
                    this.getPayslipComponents();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        onChangeRefComponent() {
            console.log(this.payslipComponent.standardPayslipComponentId)
            let result = this.standardPayslipComponents.find(data => data.id == this.payslipComponent.standardPayslipComponentId);
            if (result && result.id) {
                this.payslipComponent.payslipComponent = result.payslipComponent;
                this.payslipComponent.standardBusinessLogicId = result.standardBusinessLogicId;
                this.payslipComponent.payslipComponentTypeId = result.payslipComponentTypeId;
                this.payslipComponent.percentVal = result.percentVal;
                this.payslipComponent.fixedVal = result.fixedVal;
                this.payslipComponent.description = result.description;
                this.payslipComponent.isTaxable = result.isTaxable;
            }
        },
        onChangeRefComponentDetail() {
            let result = this.standardPayslipComponents.find(data => data.id == this.payslipComponentDetail.standardPayslipComponentId);
            if (result && result.id) {
                this.payslipComponentDetail.standardBusinessLogicId = result.standardBusinessLogicId;
                this.payslipComponentDetail.payslipComponentTypeId = result.payslipComponentTypeId;
                this.payslipComponentDetail.percentVal = result.percentVal;
                this.payslipComponentDetail.fixedVal = result.fixedVal;
            }
        }

    }
}
</script>