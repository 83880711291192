<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="serviceDetailPopupRef" size="lg" modal-class="" title="Service" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 text-left">
                            <div class="card-body p-0 mx-0 mb-1">
                                <div class="row">
                                    <div class="col-9 col-sm-9">
                                        <div class="form-row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.serviceUtilId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-sm-3">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.serviceUtilType}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Service Provider</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.serviceProvider}}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Service Id</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.serviceId }}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Service Period</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.sevicePeriodCode }}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Responsible Person</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.responsiblePerson }}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created By</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.createdBy }}</label>
                                            </div>                                        
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-12">
                                        <div class="card mt-2 mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.startDate | formatDate }}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">End Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.endDate | formatDate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Service Url</label>
                                            </div>
                                            <div class="col-6 col-md-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">
                                                    <a :href="popupData.serviceUrl" target="_blank" class="action-link pointer">{{ getShortString(popupData.serviceUrl, 80) }}</a>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mt-0 mb-2">
                                            <div v-show="attachmentDocsLength > 0" class="col-12 col-md-6">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-4 pr-0">
                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                                                    </div>
                                                    <div class="col-12 col-md-8 pl-0">
                                                        <label class="p-2 mb-0 d-block header-details">
                                                            <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentDocsLength" showDocsOnly :attachmentData="attachmentData" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="attachmentLinksLength > 0" class="col-12 col-md-6">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-4 pr-0">
                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reference Links</label>
                                                    </div>
                                                    <div class="col-12 col-md-8 pl-0">
                                                        <label class="p-2 mb-0 d-block header-details">
                                                            <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentLinksLength" showLinkOnly :attachmentData="attachmentData" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 mt-2">
                                        <h6 class="contact-title mb-2"><span>Transaction History</span></h6>
                                        <!-- <div class="row mt-0 mb-2"> -->
                                            <!-- <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div> -->
                                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="serviceHistoryList" :fields="serviceHistoryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(cost)="data">
                                                    {{ currencySymbol }} {{data.value}}
                                                </template>                                                
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Transactions found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                            <b-pagination v-if="serviceHistoryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object,
        id:{type:String, default:'serviceDetailPopup'},
    },
    components: {
        AttachmentView
    },
    data() {
        return {
            loading: true,
            popupData: {},
            currencySymbol: localStorage.getItem("currency"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),

            attachmentDocsLength: 0,
            attachmentLinksLength: 0,

            serviceHistoryList: [],
            serviceHistoryFields: [
                { key:'index',label: 'SN' },
                { key: 'startDate', label: 'Start Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'Expiry Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'cost', label: 'Cost', sortable: true, sortDirection: 'desc' },
                { key: 'paymentDueDate', label: 'Payment Due Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            });
            this.loading = true;
            if (refEntity === "service_util" || refEntity === "service_history") {
                let viewUrl;
                if (refEntity === "service_util") {
                    viewUrl = '/company/serviceutil/view/';
                }
                else {
                    viewUrl = '/company/serviceutil/srvhistory/view/';
                }
                axios.get(this.$store.getters.getAPIBasePath + viewUrl + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.popupData = result.serviceUtilView;
                        this.$nextTick(() => {
                            this.getServiceHistory(this.popupData.id);
                        })
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.$nextTick(() => {
                    this.getServiceHistory(this.popupData.id);
                })
                this.loading = false;
            }
        },
        getServiceHistory: function(serviceUtilId) {
            if (serviceUtilId) {
                let data = {
                    serviceUtilId: serviceUtilId,
                    vhextAccountId: this.vhextAccountId,
                }
                this.loading = true;
                axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/list', data)
                    .then((response) => {
                        this.loading = false;
                        let result = response.data;
                        this.serviceHistoryList = result.serviceHistoryList;
                        this.totalRows = this.serviceHistoryList.length;
                    })
                    .catch(error => {
                        this.loading = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        getAttachmentDocsLength(length) {
            this.attachmentDocsLength = length;
        },
        getAttachmentLinksLength(length) {
            this.attachmentLinksLength = length;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>