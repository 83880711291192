<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" ref="leaveTimeDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" :title="popupTitle" ok-only>
            <div v-show="showItemDetail">
                <div id="printMe" class="row px-3">
                    <LeaveTimeDetailComponent ref="leaveTimeDetailCompRef" :attachmentData="attachmentData" @getItemTitle="getItemTitle" :updateLeaveStatus="updateLeaveStatus" @updateParent="updateParent" />
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
import LeaveTimeDetailComponent from '@/components/itemdetailcomponents/LeaveTimeDetailComponent.vue';
export default {
    name: 'leaveTimeDetailPopup',
    props: {
        id: {type: String, default: 'leaveTimeDetailPopup'},
        detailViewData: Object,
        attachmentData: Object,
        updateLeaveStatus: Boolean
    },
    components: {
        AttachmentView,
        LeaveTimeDetailComponent,
    },
    data() {
        return {
            showItemDetail: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'leave_time',
            popupData: {},
            popupTitle: '',
            attachmentList: {},
            attachmentLength: 0,

            sortDesc: false,
            sortBy: '',
            sortDirection: 'asc',
        }
    },
    computed: {
    },
    methods: {
        getDetails(referencePopupId, refEntity) {
            this.$bvModal.show(this.id);
            this.showItemDetail = true;
            this.$nextTick(() => {
                this.$refs.leaveTimeDetailCompRef.getDetails(referencePopupId, refEntity);
            })
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
        getItemTitle(title) {
            console.log(title)
            this.popupTitle = title;
        },
        updateParent() {
            this.$emit('updateParent');
        }
    }
}
</script>