<template>
    <div class="referenceDetailPopup">
        <b-modal id="statusTrackerPopup" centered ref="statusTrackerPopupRef" @hide="closeDialog" modal-class="" title="Status Updates" no-enforce-focus>
            <StatusTracker ref="statusUpdate" :statusUpdate="statusUpdate" :hasReferredTo="hasReferredTo" :referredToLookup="referredToLookup" @updateParent="updateParent"></StatusTracker>
        </b-modal>
    </div>
</template>
<script>
import StatusTracker from '@/components/common/StatusTracker.vue';
export default {
    components: {
        StatusTracker,
    },
    props: {
        statusUpdate: Object,
        hasReferredTo: Boolean,
        referredToLookup: Array,
    },
    data() {
        return {
        }
    },
    methods: {
        updateParent: function() {
            this.$emit('updateParent');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>