<template>
    <div class="deliverable">
        <b-modal ref="existingDeliverableLookup" size="lg" id="existingDeliverableLookup" ok-only body-class="pt-0 pb-2" footer-class="py-3" header-class="py-3" @hide="closeDialog" title="Add Deliverables">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row mb-2 px-2">
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 pb-1 mt-3">
                            <b-tabs v-model="tabIndex" content-class="mt-3" lazy>
                                <b-tab title="Existing Deliverables">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="existingDeliverables" :fields="deliverableFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByExt" :sort-desc.sync="sortDescExt" :sort-direction="sortDirection">
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!deliverableExists(data.item.deliverable)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.deliverable" v-model="selectedDeliverables">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!deliverableExists(data.item.deliverable)">
                                                <a href="javascript:" title="Add Deliverable" class="text-success" @click="addDeliverable(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!deliverableExists(data.item.deliverable)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.deliverable" v-model="selectedDeliverables">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Deliverables found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-if="isStandard == 'true'" title="Deliverables Summary">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="DeliverablesSummary" :fields="deliverableSummaryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!deliverableExists(data.item.deliverable)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.deliverable" v-model="selectedDeliverables">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!deliverableExists(data.item.deliverable)">
                                                <a href="javascript:" title="Add Deliverable" class="text-success" @click="addDeliverable(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!deliverableExists(data.item.deliverable)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.deliverable" v-model="selectedDeliverables">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Deliverables found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-else title="Standard Deliverables">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="standardDeliverables" :fields="deliverableFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!deliverableExists(data.item.deliverable)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.deliverable" v-model="selectedDeliverables">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!deliverableExists(data.item.deliverable)">
                                                <a href="javascript:" title="Add Deliverable" class="text-success" @click="addDeliverable(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!deliverableExists(data.item.deliverable)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.deliverable" v-model="selectedDeliverables">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Deliverables found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 mt-3">
                            <label class="mt-3">Deliverables</label>
                            <div class="separator mb-3"></div>
                            <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" sort-icon-left :items="Deliverables" :fields="deliverableFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Remove" @click="deleteItem(data.item.id)" v-b-modal.deleteItem class="text-danger"><i class="fa fa-minus-circle pr-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Deliverables found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <button type="submit" @click="addBulkDeliverable" :disabled="selectedDeliverables.length==0" class="btn btn-primary float-left my-0 mr-2 py-1 px-3">Add Selected</button>
                    <button v-if="selectedDeliverables.length>0" type="submit" @click="selectedDeliverables=[];" class="btn btn-outline-primary float-left my-0 mr-2 py-1 px-3">Clear Selection</button>
                    <button type="submit" @click="cancel()" class="btn btn-secondary float-right my-0 mr-2 py-1 px-3">Close</button>
                </div>
            </template>
        </b-modal>
        <DeleteComponent id="deleteItem" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Deliverable ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            showOverlay: false,
            checkboxValue: true,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            isStandard: localStorage.getItem("isStandard"),
            deliverable: {
                deliverableId: '',
                deliverable: '',
                vhextAccountId: '',
                projectId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            existingDeliverables: [],
            standardDeliverables: [],
            Deliverables: [],
            deliverableFields: [
                { key: 'selected', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'deliverable', label: 'Deliverable', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            DeliverablesSummary: [],
            deliverableSummaryFields: [
                { key: 'selected', label: '' },
                {key:'index',label: 'SN'},
                { key: 'deliverable', label: 'Deliverable', sortable: true, sortDirection: 'desc' },
                { key: 'count', label: 'Count', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            selectMode: 'single',
            sortDescExt: false,
            sortDescStd: false,
            sortDesc: false,
            sortBy: '',
            sortByExt: '',
            sortByStd: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            selectedDeliverables: [],

            deleteParam: {
                id: 0
            }
        }
    },
    watch: {
        tabIndex() {
            this.selectedDeliverables = [];
        }
    },
    mounted() {
        this.getDeliverableList();
        this.getExistingDeliverables();
        if (this.isStandard == 'true') {            
            this.getDeliverablesSummary();
        } 
        else {
            this.getStandardDeliverables();
        }
    },
    methods: {
        getDeliverableList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.selectedDeliverables = [];
                    this.Deliverables = result.deliverableList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getExistingDeliverables: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/existingdeliverable/list', data)
                .then((response) => {
                    let result = response.data;
                    this.existingDeliverables = result.existingDeliverableList;
                })
        },
        getStandardDeliverables: function() {
            let data = {
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/standarddeliverable/list', data)
                .then((response) => {
                    let result = response.data;
                    this.standardDeliverables = result.standardDeliverableList;
                })
        },
        getDeliverablesSummary: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverablesummary/list', data)
                .then((response) => {
                    let result = response.data;
                    this.DeliverablesSummary = result.deliverableSummaryList;
                })
        },
        addDeliverable: function(data) {
            this.deliverable.vhextAccountId = this.vhextAccountId;
            this.deliverable.projectId = this.projectId;
            this.deliverable.entity = data.entity;
            this.deliverable.active = true;
            this.deliverable.deliverable = data.deliverable;

            if (!this.deliverableExists(this.deliverable.deliverable)) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/add', this.deliverable)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-lookup');
                            this.getDeliverableList();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                iziToast.info({
                    message: 'Deliverable already exist.',
                    position: 'topCenter'
                });
            }
        },
        deliverableExists: function(deliverable) {
            let result = false;
            this.Deliverables.map(data => {
                if (deliverable && data.deliverable) {
                    if (data.deliverable.toLowerCase() == deliverable.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        dataExists: function(deliverable) {
            let result = false;
            this.selectedDeliverables.map(data => {
                if (data.toLowerCase() == deliverable.toLowerCase()) {
                    result = true;
                }
            });
            return result;
        },
        onRowSelected(items) {
            if (items.length > 0){
                let data = items[0];
                if (!this.deliverableExists(data.deliverable) && !this.dataExists(data.deliverable)) {
                    this.selectedDeliverables.push(data.deliverable);
                }
                else if (this.dataExists(data.deliverable)) {
                    this.selectedDeliverables = this.selectedDeliverables.filter(e=> e.toLowerCase() !== data.deliverable.toLowerCase());
                }
            }
          },
        closeDialog() {
            this.selectedDeliverables = [];
            this.$emit('closeDialog');
        },
        deliverableData: function(data) {
            this.bulkDeliverableData = [];
            for (var rowCount = 0; rowCount < this.selectedDeliverables.length; rowCount++) {
                this.bulkDeliverableData.push({
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: data.entity,
                    active: true,
                    deliverable: this.selectedDeliverables[rowCount],
                    createdById: localStorage.getItem("userId")
                });
            }
        },
        saveBulkDeliverable: function(data) {
            this.showOverlay = true;    
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/bulk/add', this.bulkDeliverableData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDeliverableList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        async addBulkDeliverable(data) {
            await this.deliverableData(data);
            await this.saveBulkDeliverable();
        },
        deleteItem: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/deliverable/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDeliverableList();
                        this.$emit('refresh-lookup');
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
    }
}
</script>