<template>
    <b-modal id="activityETVXPopup" ref="activityETVXPopupRef" size="xl" modal-class="activityETVXPopup" @hide="closeDialog" :title="etvxEditMode ? 'Add ETVX' : 'ETVX Work Flow'" no-close-on-esc no-close-on-backdrop ok-only>
        <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4">
            <div class="row mb-auto w-100 mx-0">
                <div class="col-12 text-left">
                    <div v-if="etvxEditMode">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="csQuantity">Activity - {{ activity.activity }}</label>
                            </div>
                        </div>
                        <div class="form-row mt-3">
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label for="expectedResult">Entry</label>
                                    <textarea v-model="activity.input" :disabled="!elements.editMode" rows="4" class="form-control" id="input" maxlength="2000" placeholder="Enter Process Inputs"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label for="expectedResult">Task</label>
                                    <textarea v-model="activity.task" :disabled="!elements.editMode" rows="4" class="form-control" id="task" maxlength="2000" placeholder="Enter Process Activities/Tasks"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label for="description">Verify</label>
                                    <textarea v-model="activity.verify" :disabled="!elements.editMode" rows="4" class="form-control" id="validation" maxlength="2000" placeholder="Enter Verification/Testing Information"></textarea>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="form-group">
                                    <label for="description">Exit</label>
                                    <textarea v-model="activity.output" :disabled="!elements.editMode" rows="4" class="form-control" id="output" maxlength="2000" placeholder="Enter Process Output/Deliverables"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row text-right">
                            <div class="col-12">
                                <button type="submit" :disabled="!elements.editMode" @click="addETVX" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Update</button>
                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0 px-0 my-3">
                        <b-table ref="activityETVXTable" show-empty small selectable :select-mode="selectMode" :items="activityETVXList" :fields="fields" @row-selected="onRowSelected" responsive="sm">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>                            
                            <template v-slot:cell(actions)="data">
                                <a href="javascript:" title="Edit" class="" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" title="Remove Item" class="" @click="removeActivity(data.item.id)"><i class="fa fa-trash pl-2"></i></a>
                            </template>
                            <template v-slot:empty="">
                                <div class="text-center">Add items to show</div>
                            </template>
                            <template v-slot:emptyfiltered="">
                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </b-overlay>
        <template #modal-footer>
            <div v-if="etvxEditMode" class="w-100">
<!--                 <b-button class="float-right" variant="secondary" size="sm" @click="closeDialog">
                    Cancel
                </b-button> -->
                <b-button variant="primary" size="sm" @click="updateActivities" class="float-right mr-2">
                    Close
                </b-button>
            </div>
            <div v-else class="w-100">
                <b-button class="float-right" variant="primary" size="sm" @click="closeDialog">
                    Ok
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            elements: {
                showDetail: false,
                editMode: false,
            },
            updateFromGrid: false,
            showOverlay: false,
            etvxEditMode: true,

            activity: {
                activityId: '',
                activity: '',
                input: '',
                task: '',
                verify: '',
                output: '',
            },

            activityETVXList: [],
            activityFields: [
                { key: 'index', label: 'SN', },
                { key: 'value', label: 'Activity' },
                { key: 'input', label: 'Input / Entry' },
                { key: 'task', label: 'Task' },
                { key: 'verify', label: 'Verify' },
                { key: 'output', label: 'Output / Exit' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',

        }
    },
    computed: {
        fields() {
            if(this.etvxEditMode) {
                return this.activityFields;
            }
            else return this.activityFields.filter(e => e.key !== 'actions');
        }
    },
    methods: {
        setActivity: function(activityList, flag) {
            this.etvxEditMode = flag;
            this.activityETVXList = activityList;
            this.$nextTick(() => {
                this.$bvModal.show('activityETVXPopup');
                this.onRowSelected(this.activityETVXList)

            })

        },
        getActivityList(refErdId, refEntity) {
            this.etvxEditMode = false;
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                refErdId: refErdId,
                refEntity: refEntity
            }
            this.$nextTick(() => {
                this.$bvModal.show('activityETVXPopup');
            })
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/activity/lookup/all',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if(typeof(this.projectId) !== "undefined" && this.projectId !== null){
                        this.activityETVXList = result.projectProcessActivityLookup;
                    }
                    else{
                        this.activityETVXList = result.processActivityLookup;                        
                    }
                    

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addETVX: function() {
            let data = {};
            data.input = this.activity.input;
            data.task = this.activity.task;
            data.verify = this.activity.verify;
            data.output = this.activity.output;

            this.activityETVXList = this.activityETVXList.filter((etvx) => {

                if (etvx.id == this.activity.activityId) {

                    etvx.input = data.input;
                    etvx.task = data.task;
                    etvx.verify = data.verify;
                    etvx.output = data.output;

                    return etvx;
                }              
                else{
                    return etvx;
                } 
                
            });
            this.$nextTick(() => {
                this.clearData();
            })
        },
        updateActivities: function() {
            this.$emit('updateETVXActivities',this.activityETVXList);
            this.$bvModal.hide('activityETVXPopup');
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.activity.activityId = data.id;
                this.activity.activity = data.value;
                this.activity.input = data.input;
                this.activity.output = data.output;
                this.activity.task = data.task;
                this.activity.verify = data.verify;
            }
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.activity.activityId = data.id;
            this.activity.activity = data.value;
            this.activity.input = data.input;
            this.activity.output = data.output;
            this.activity.task = data.task;
            this.activity.verify = data.verify;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.activity.activityId = '';
            this.activity.activity = '';
            this.activity.input = '';
            this.activity.output = '';
            this.activity.task = '';
            this.activity.verify = '';
        },
        removeActivity: function(id) {
            this.activityETVXList = this.activityETVXList.filter((data)=> data.id !== id);
        },
        closeDialog() {
            this.clearData();
            this.updateActivities();
            this.$emit('closeDialog');
            this.$bvModal.hide('activityETVXPopup');
        },
    }
}
</script>