<template>
    <div>
    	<div class="card">
            <div class="card-body p-0">
			    <div class="container-fluid">
			        <div class="row mx-2">
			            <div class="col-md-4 py-3">
			                <a title="" class="settings-box make-it-slow" @click="$router.push('/landing/astrep')">
			                    <div class="row col-md-12 ml-0 left-bar-tab p-2">
			                        <div class="col-md-3 p-3">
			                            <i class="fa fa-3x icon-b fa-file" aria-hidden="true"></i>
			                        </div>
			                        <div class="col-md-8 text-left align-self-center p-2">
			                            <h6 class="m-0"><b>Asset Report</b></h6>
			                        </div>
			                    </div>
			                </a>
			            </div>
			            <div class="col-md-4 py-3">
			                <a title="" class="settings-box make-it-slow" @click="$router.push('/landing/astdetrep')">
			                    <div class="row col-md-12 ml-0 left-bar-tab p-2">
			                        <div class="col-md-3 p-3">
			                            <i class="fa fa-3x icon-b fa-file" aria-hidden="true"></i>
			                        </div>
			                        <div class="col-md-8 text-left align-self-center p-2">
			                            <h6 class="m-0"><b>Asset Detail Report</b></h6>
			                        </div>
			                    </div>
			                </a>
			            </div>
			            <div class="col-md-4 py-3">
			                <a title="" class="settings-box make-it-slow" @click="$router.push('/landing/astsumrep')">
			                    <div class="row col-md-12 ml-0 left-bar-tab p-2">
			                        <div class="col-md-3 p-3">
			                            <i class="fa fa-3x icon-b fa-file" aria-hidden="true"></i>
			                        </div>
			                        <div class="col-md-8 text-left align-self-center p-2">
			                            <h6 class="m-0"><b>Asset Summary Report</b></h6>
			                        </div>
			                    </div>
			                </a>
			            </div>
			            <div class="col-md-4 py-3">
			                <a title="" class="settings-box make-it-slow" @click="$router.push('/landing/srurep')">
			                    <div class="row col-md-12 ml-0 left-bar-tab p-2">
			                        <div class="col-md-3 p-3">
			                            <i class="fa fa-3x icon-b fa-file" aria-hidden="true"></i>
			                        </div>
			                        <div class="col-md-8 text-left align-self-center p-2">
			                            <h6 class="m-0"><b>Service Report</b></h6>
			                        </div>
			                    </div>
			                </a>
			            </div>
			            <div class="col-md-4 py-3">
			                <a title="" class="settings-box make-it-slow" @click="$router.push('/landing/srudetrep')">
			                    <div class="row col-md-12 ml-0 left-bar-tab p-2">
			                        <div class="col-md-3 p-3">
			                            <i class="fa fa-3x icon-b fa-file" aria-hidden="true"></i>
			                        </div>
			                        <div class="col-md-8 text-left align-self-center p-2">
			                            <h6 class="m-0"><b>Service Detail Report</b></h6>
			                        </div>
			                    </div>
			                </a>
			            </div>
			        </div>
			    </div>
			</div>
		</div>
    </div>
</template>
<script>
export default {
}
</script>