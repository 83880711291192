<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Project Edit</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Project" :to="{ name: 'projectview'}"><i class="fa fa-eye pr-2"></i>View</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="title">Title</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.title.$error }">
                                                <input type="text" class="form-control" v-model="project.title" id="title" :class="{ 'is-invalid': $v.project.title.$error }" placeholder="Enter Title">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.title.$error && !$v.project.title.minLength" class="text-danger">Title must have at least {{$v.project.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.maxLength" class="text-danger">Title must not exceed {{$v.project.title.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                    <div class="col-2 pl-0">
                                        <div class="form-group ">
                                            <label for="shortName">Short Name</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.displayName.$error }">
                                                <input type="text" class="form-control" v-model="project.displayName" id="shortName" placeholder="Enter Display Name">
                                            </div>
                                            <label v-if="$v.project.displayName.$error && !$v.project.displayName.maxLength" class="text-danger">Short name must not exceed {{$v.project.displayName.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="projectTypeId">Project Type</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.projectTypeId.$error }">
                                                <select v-model="project.projectTypeId" class="form-control" :class="{ 'is-invalid': $v.project.projectTypeId.$error }">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.projectTypeId.$error && !$v.project.projectTypeId.required" class="text-danger">Please select Project Type</label>                                            
                                        </div>

<!--                                         <div class="form-group">
                                            <label for="projectTypeId">Project Type</label>
                                            <select v-model="project.projectTypeId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div> -->
                                    </div>

                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="projectManager">Project Manager</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.project.projectManagerId.$error }">
                                                <select v-model="project.projectManagerId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.project.projectManagerId.$error && !$v.project.projectManagerId.required" class="text-danger">Please select Project Manager</label>
                                        </div>
                                    </div>
<!--                                     <div class="col-6">
                                        <div class="form-group">
                                            <label for="categoryId">Project Category</label>
                                            <select v-model="project.categoryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectCategories" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <a :title="categoryLookupTitle" class="setting-icon" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                        </div>
                                    </div> -->
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="duration">Duration</label>
                                            <div class="row">
                                                <div class="col-4">
                                                    <b-input-group append="years">
                                                        <select v-model="project.durationYears" class="form-control">
                                                            <option value="">Years</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-4 pl-0 pr-0">
                                                    <b-input-group append="months">
                                                        <select v-model="project.durationMonths" class="form-control">
                                                            <option value="">Months</option>
                                                            <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-4">
                                                    <b-input-group append="days">
                                                        <select v-model="project.durationDays" class="form-control">
                                                            <option value="">Days</option>
                                                            <option v-for="(info, index) in daysList" :value="info.id">{{info.name}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 pr-1">
                                        <div class="form-group">
                                            <label for="startDate">Start Date</label>
                                            <div>
                                                <input v-model="project.startDate" type="date" class="form-control" placeholder="Start Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3  pl-1">
                                        <div class="form-group">
                                            <label for="endDate">End Date</label>
                                            <div>
                                                <input v-model="project.endDate" type="date" class="form-control" placeholder="End Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Budget</label>
                                            <VhextMoneyControl :value="Number(this.project.initialBudget)" :key="vhextMoneyControlKey" @money-input="moneyControlInput" />
                                            <!-- <b-input-group>
                                                <b-input-group-prepend class="custom-input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i v-bind:class="[ (prependText === 'INR') ? 'fa fa-inr' : 'fa fa-usd']"></i>
                                                    </span>
                                                </b-input-group-prepend>
                                                <b-form-input type="text" v-model="project.initialBudget">
                                                </b-form-input>
                                                <b-input-group-append class="custom-input-group-append">
                                                    <span class="input-group-text">
                                                        .00
                                                    </span>
                                                </b-input-group-append>
                                            </b-input-group>  -->                                      
                                        </div>
                                    </div>
                                    <div class="col-12">
    <!--                                     <label>Description</label>
                                        <textarea v-model="project.description" class="form-control" rows="4" required></textarea> -->
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <Editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="project.description"></Editor>
                                        </div>

                                    </div>
                                </div>
                                <button type="submit" @click="saveProject" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="getPrevUrl()" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                <a href="javascript:" title="Reset" @click="clearData" class="p-3 float-right"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="projectStatus">Project Id</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{project.projectId}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="projectStatus">Project Status</label>
                                            <select v-model="project.statusId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div v-if="project.statusId==393 || project.statusId==394" class="col-12">
                                        <div class="form-group">
                                            <label for="exampleInputPassword1">Reason</label>
                                            <textarea v-model="project.description" class="form-control" rows="3" required></textarea>
                                        </div>
                                    </div>
                                    <div class="col-11 mx-auto card mt-1">
                                        <div class="card-body px-0 pt-2 pb-0">
                                            <p class="mb-0 fs-16">Project Settings</p>
                                            <hr class="mt-0">
                                            <div class="row" >
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="accessType">Project Model</label>
                                                        <select v-model="project.projectAccessTypeId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in projectAccessTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>       
                                                <div class="col-6 pl-1">
                                                    <div class="form-group">
                                                        <label for="country">Country</label>
                                                        <select v-model="project.countryId" class="form-control">
                                                            <!-- <option value="">Select</option> -->
                                                            <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row" >
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="currency">Currency</label>
                                                        <select v-model="project.currencyId" class="form-control w-95" @change="changeCurrency($event)">
                                                            <!-- <option value="">Select</option> -->
                                                            <option v-for="(info, index) in currencies" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>                                                
                                                <div class="col-6 pl-1">
                                                    <div class="form-group">
                                                        <label for="timezone">Timezone</label>
                                                        <Select2 class="w-95" v-model="project.timeZone" :options="timeZone" :settings="{width:'100%'}" />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="effortConversionHourDay">Effort(1 Man Day)</label>
                                                        <div class="input-group">
                                                            <input type="number" v-model="project.effortConversionHourDay" class="form-control" min="0" id="effortConversionHourDay">
                                                            <div class="input-group-append">
                                                                <div class="input-group-text">
                                                                    <span class="mx-auto">Man Hours</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="exampleInputPassword1">Location</label>
                                                        <input type="text" v-model="project.location" class="form-control" id="location" placeholder="Location">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group my-3">
                                                        <a href="javascript:" @click="$router.push('/settings')"><i class="fa fa-cog action-link pr-2" aria-hidden="true"></i>Additional Settings</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-11 mx-auto card mt-2">
                                        <div class="card-body px-0 pt-2 pb-3">
                                            <p class="mb-0 fs-16">Attachment</p>
                                            <hr class="mt-0">
                                            <div class="row" >
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div>
                                                            <!-- <label for="exampleInputPassword1">Attachment </label> -->
                                                            <!-- <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2 action-link"></i>Add More</a> -->
                                                        </div>
                                                        <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                            <div class="attachments mb-2">
                                                                <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="project.attachments[index]">
                                                                    <template slot="file-name" slot-scope="{ names }">
                                                                        <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                    </template>
                                                                </b-form-file>
                                                                <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                                    <i class="fa fa-check pr-2"></i>
                                                                </a>
                                                                <!-- <a href="javascript:" @click="removeFile(index)" class="float-right button-remove p-2 attachments-remove">
                                                                    <i class="fa fa-minus-circle pr-2"></i>
                                                                </a> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="attachments.length != 0" class="col-12 px-0">
                                                        <div class="">
                                                            <div>
                                                                <p class="my-1 border p-2" v-for="(attachment, index) in attachments">
                                                                    <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                                    <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                    <a href="javascript:" data-toggle="modal" data-target="#deleteAttachment" @click="deleteFile(attachment.id)" title="Delete attachment" class="float-right button-remove px-2 attachments-remove">
                                                                        <i class="fa fa-trash pr-2 action-link"></i>
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <fieldset class="mb-3 settings">
                                        <legend class="settings">Settings</legend>
                                        <div class="row">
                                            <div class="col-6 pr-1">
                                                <div class="form-group">
                                                    <label for="currency">Currency</label>
                                                    <select v-model="project.currencyId" class="form-control w-95">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in currencies" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6 pl-1">
                                                <div class="form-group">
                                                    <label for="timezone">Timezone</label>
                                                    <Select2 class="w-95" v-model="project.timeZone" :options="timeZone" :settings="{width:'100%'}" />
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="mb-3 settings">
                                        <legend class="settings">Attachment</legend>
                                        <div class="col-12 px-0">
                                            <div class="form-group">
                                                <div>
                                                </div>
                                                <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                    <div class="attachments mb-2">
                                                        <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="project.attachments[index]">
                                                            <template slot="file-name" slot-scope="{ names }">
                                                                <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                            </template>
                                                        </b-form-file>
                                                        <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                            <i class="fa fa-check pr-2"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="attachments.length != 0" class="col-12 border">
                                                <div class="">
                                                    <div>
                                                        <p class="my-2" v-for="(attachment, index) in attachments">
                                                            <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                            <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                            <a href="javascript:" data-toggle="modal" data-target="#deleteAttachment" @click="deleteFile(attachment.id)" title="Delete attachment" class="float-right button-remove px-2 attachments-remove">
                                                                <i class="fa fa-trash pr-2 action-link"></i>
                                                            </a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset> -->
                                    <div class="col-6 mt-2">
                                        <div class="form-group p-1">
                                            <label for="inScope">Archive</label>
                                            <div class="input-group ">
                                                <b-form-checkbox class="custom-toggle" v-model="project.isArchive" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <Dialog id="deleteAttachment" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
        <CategoryLookup ref="categoryLookupModal" :entity="entity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import month from '@/json/month.json';
import days from '@/json/days.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue';
import Editor from '@tinymce/tinymce-vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    name: 'app',
    components: {
        Select2,
        CategoryLookup,
        Editor,    
        VhextMoneyControl    
    },
    // computed: {
    //     projectId() {
    //         return Number(this.$route.params.projectid);
    //     }
    // },
    data() {
        return {
            showOverlay: false,

            vhextMoneyControlKey: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entity: 'project',
            categoryLookupTitle: 'Project Category Settings',

            files: '',
            projectTypes: [],
            projectAccessTypes: [],
            projectCategories: [],
            projectStatus: [],
            timeZone: [],
            users: [],
            countries: [],
            currencies: [],
            projectPrimeSettings: [],
            addMore: [],
            monthList: month,
            daysList: days,
            attachments: [],

            project: {
                id: '',
                title: '',
                displayName: '',
                description: '',
                projectTypeId: '',
                projectAccessTypeId: '',
                vmxServiceRegisterId: '',
                projectManagerId: '',
                categoryId: '',
                durationDays: '',
                durationMonths: '',
                durationYears: '',
                initialBudget: '',
                startDate: '',
                endDate: '',
                statusId: '',
                timeZone: '',
                effortConversionHourDay: '',
                location: '',
                countryId: '',
                countryId: '',
                isArchive: '',
                projId: localStorage.getItem("projId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                attachments: [],
                userTypeCode: localStorage.getItem("userTypeCode"),
            },
            
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.addMore.push(this.addMore.length + 1);
        this.lookupData();
        this.viewProject(this.projectId);
        this.getAttachment();
    },
    validations: {
        project: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            displayName: {
                maxLength: maxLength(10)
            }, 
            projectTypeId: {
                required
            },                       
            projectManagerId: {
                required
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.$store.getters.currentUser.vhextAccountId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTypes = result.typeLookup;
                    this.projectAccessTypes = result.projectAccessTypeLookup;                    
                    this.projectCategories = result.customCategoryLookup;
                    this.projectStatus = result.statusLookup;
                    this.timeZone = result.timeZoneLookup;
                    this.users = result.userLookup
                    this.countries = result.countryLookup;
                    this.currencies = result.currencyLookup;     
                    console.log(result);

                })

        },
        viewProject: function(id) {
            this.showOverlay = true;            
            axios.get(this.$store.getters.getAPIBasePath + '/project/view/' + id)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;          
                    console.log(result);   
                    this.project = result.projects;
                    this.project.attachments = [];
                    this.project.categoryId = (this.project.categoryId == undefined) ? '' : this.project.categoryId;
                    this.project.projectTypeId = (this.project.projectTypeId == undefined) ? '' : this.project.projectTypeId;
                    this.project.statusId = (this.project.statusId == undefined) ? '' : this.project.statusId;
                    this.project.vmxServiceRegisterId = (this.project.vmxServiceRegisterId == undefined) ? '' : this.project.vmxServiceRegisterId;
                    this.project.projectManagerId = (this.project.projectManagerId == undefined) ? '' : this.project.projectManagerId;
                    this.project.isArchive = (this.project.isArchive == undefined) ? false : this.project.isArchive;
                    this.getVmxServiceSettings();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getVmxServiceSettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                vmxServiceRecordId: this.projectId,
                vmxServiceCode: this.vmxServiceCode,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/vmxservicesettingdata', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    let vmxServSettingData = result.vmxServiceSettingData;
                    // this.setCompanySettingData();
                    this.project.timeZone = vmxServSettingData.timeZone ?? '' ;
                    this.project.effortConversionHourDay = vmxServSettingData.effortConversionHourDay ?? '' ;
                    this.project.countryId = vmxServSettingData.countryId ?? '' ;
                    this.project.country = vmxServSettingData.country ?? '' ;
                    this.project.currencyId = vmxServSettingData.currencyId ?? '' ;
                    this.project.currency = vmxServSettingData.currencySymbol + ' ' + vmxServSettingData.currency ?? '' ;

                })
                .catch(error => {
                    this.showOverlay = false;
                })
                
        },
        saveProject: function() {
            this.project.isArchive = this.project.isArchive==true ? true : false;
            console.log(this.project);
            this.$v.project.$touch();
            if (!this.$v.project.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/project/update', this.project)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            /* if (this.project.attachments != null && this.project.attachments.length > 0)
                                 this.submitFiles(result.id);
                             else {*/
                            this.setProjectSetting(result.id);
                            this.showMessage('Project Updated successfully.', result.id);
                            /* }*/
                            this.$router.push('/projectview');
                        }

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }

        },
        clearData: function() {
            this.project.title = '';
            this.project.displayName = '';
            this.project.description = '';
            this.project.projectTypeId = '';
            // this.project.projectAccessTypeId = '';
            // this.project.projectManagerId = '';
            this.project.projectTypeId = '';
            this.project.categoryId = '';
            this.project.durationDays = '';
            this.project.durationMonths = '';
            this.project.durationYears = '';
            this.project.initialBudget = '';
            this.project.startDate = '';
            this.project.endDate = '';
            this.project.description = '';
            // this.project.statusId = '1';
            this.project.timeZone = '';
            this.project.countryId = '';
            this.project.currencyId = '';
            this.project.effortConversionHourDay = '';
            this.project.location = '';

            this.project.attachments = [];
            this.$v.project.$reset();
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            if (this.project.attachments != null) {
                this.project.attachments.splice(index, 1);
            }
        },
        submitFiles: function(projectId) {
            let formData = new FormData();
            for (const row of Object.keys(this.project.attachments)) {
                formData.append('files', this.project.attachments[row]);
            }
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', projectId);
            formData.append('refEntity', this.entity);
            formData.append('refRecordId', projectId);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Project Updated Successfully', projectId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    this.showMessage('Project Updated Successfully', projectId);
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function(message, id) {
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
            this.viewProject(this.projectId);
            this.getAttachment();
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.project.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachments = result.attachmentList;
                })
        },
        setProjectSetting: function(id) {
            let data = {
                projectId: id,
                vhextAccountId: this.project.vhextAccountId,
                vmxServiceRecordId: id,
                vmxServiceCode: this.vmxServiceCode,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/company/vmxservicesettingdata', data)
                .then((response) => {
                    let result = response.data;

                    let projectPrimeSettings = result.vmxServiceSettingData;
                    // this.setCompanySettingData();
                    // this.project.timeZone = vmxServSettingData.timeZone ?? '' ;
                    // this.project.effortConversionHourDay = vmxServSettingData.effortConversionHourDay ?? '' ;
                    // this.project.countryId = vmxServSettingData.countryId ?? '' ;
                    // this.project.country = vmxServSettingData.country ?? '' ;
                    // this.project.currencyId = vmxServSettingData.currencyId ?? '' ;
                    this.project.currency = projectPrimeSettings.currencySymbol ?? '' ;

                    // this.projectPrimeSettings = result.projectPrimeSettings;

                    localStorage.setItem('projectName', this.project.displayName);
                    localStorage.setItem('currency', this.project.currency);
                    // this.projectName = localStorage.getItem("projectName");
                    console.log(projectPrimeSettings.currencySymbol);

                })
        },

        deleteFile: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function(id) {
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveAttachment: function() {
            if (this.project.attachments != null && this.project.attachments.length > 0) {
                let formData = new FormData();
                for (const row of Object.keys(this.project.attachments)) {
                    formData.append('files', this.project.attachments[row]);
                }
                formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
                formData.append('attachedById', localStorage.getItem("userId"));
                formData.append('projectId', this.projectId);
                formData.append('refEntity', this.entity);
                formData.append('refRecordId', this.projectId);
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.project.attachments = [];
                            //this.$refs.fileinput.reset();
                            //this.$refs['fileinput'].reset();
                            this.getAttachment();
                        }
                        console.log('Attachment uploaded');
                    })
                    .catch(error => {
                        console.log('Error Attachment uploaded');
                    });
            } else {
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
            }
        },
        changeCurrency: function(data) {
            console.log(data.target.value);
            
            // localStorage.setItem('currency', data.target.value);
            this.vhextMoneyControlKey += 1;                        
            
            // if (data.text != null && data.text.length > 0) {
            //     this.client.clientTypeName = data.text.toLowerCase();

            //     if (this.client.clientTypeName == 'company') {
            //         this.prependText = 'Contact Person';
            //     } else {
            //         this.prependText = 'Full Name';
            //     }
            // }
        },

        moneyControlInput: function(value) {
            this.project.initialBudget = Math.abs(value);
        },

    }
}
</script>