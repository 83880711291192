<template>
    <div class="designation">
        <b-modal ref="designationModal" size="md" centered id="designationModal" modal-class="designationModal" @hide="closeDialog" :title="designationLookupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.designationEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Designation</span>
                                <span v-if="elements.designationEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.designationVisible" role="tabpanel">
                            <b-card-body class="p-1">
                                <div class="row">
                                    <!-- <b-input-group class="form-group py-2"> -->
                                        <!-- <div cols="2" class="align-middle float-left my-auto ml-4 mr-0 p-1" >Designation</div> -->
                                        <b-col cols="8">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.designation.designation.$error }">
                                                <b-form-input id="designation" v-model="designation.designation" placeholder="Enter Designation Name"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.designation.designation.$error && !$v.designation.designation.required" class="text-danger">Please Enter Designation Name</label>
                                        </b-col>
                                        <b-col cols="2">
                                            <div class="input-group p-0">
                                                <b-form-checkbox class="custom-toggle" v-model="designation.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>                                
                                        <div cols="2" class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveDesignationData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearDesignationData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    <!-- </b-input-group> -->
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="float-right">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="designations" :fields="designationFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showDesignationEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="removeDesignation(data.item.id)" v-b-modal.removeDesignation><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Designation found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                                
                    <b-pagination v-if="designations.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeDesignation" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Designation ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        designationLookupTitle: String
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                designationVisible: false,
                designationEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            designation: {
                id: '',
                designationId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                designation: '',
                shortName: '',
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },
            designations: [],
            designationFields: [
                // { key: 'index',label: 'SN'},
                { key: 'designationId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'designation', label: 'Designation', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        designation: {
            designation: {
                required
            },
        }
    },
    mounted() {
        this.getDesignationList();
    },
    methods: {
        getDesignationList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/designation/list', data)
                .then((response) => {
                    let result = response.data;
                    this.designations = result.designationList;
                    this.totalRows = this.designations.length;
                })
        },
        saveDesignationData: function() {
            this.$v.designation.$touch();
            if (!this.$v.designation.$invalid) {
                if (!this.matchTextValExists(this.designation.designation, this.designation.id)) {
                    if (this.elements.designationEdit) {
                        this.updateDesignation();
                    } else {
                        this.addDesignation();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Designation already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        addDesignation: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/designation/add', this.designation)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.$bvModal.hide('designationModal');
                        this.getDesignationList();
                        this.clearDesignationData();
                        
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateDesignation: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/designation/update', this.designation)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.$bvModal.hide('designationModal');
                        this.getDesignationList();
                        this.clearDesignationData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeDesignation: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/designation/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDesignationList();
                    }
                    else if (result.status == "CONFLICT") {
                        iziToast.warning({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.designation.id = item.id;
                this.designation.active = 0;
                this.designation.entity = 'designation';
                this.updateActivationState();
            } else {
                this.designation.id = item.id;
                this.designation.active = 1;
                this.designation.entity = 'designation';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.designation)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDesignationList();
                        this.clearDesignationData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearDesignationData: function() {
            this.designation.id = '';
            this.designation.designation = '';
            this.designation.designationId = '';
            this.designation.shortName = '';
            this.designation.description = '';
            this.designation.active = true;
            this.$v.designation.$reset();
            this.elements.designationEdit = false;
        },
        showDesignationEdit: function(items) {
            let data = items;
            this.elements.designationVisible = true;
            this.elements.designationEdit = true;

            this.designation.id = data.id;
            this.designation.designationId = data.designationId;
            this.designation.vhextAccountId = data.vhextAccountId;
            this.designation.designation = data.designation;
            this.designation.shortName = data.shortName;
            this.designation.description = data.description;
            this.designation.active = data.active;
            this.designation.createdById = data.createdById;
            this.designation.createdDt = data.createdDt;
            this.designation.lastUpdatedDt = data.lastUpdatedDt;
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.designations.map(data => {
                if (textVal && data.designation && data.id !== id) {
                    if (data.designation.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>