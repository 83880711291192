import CpmMemberRolesResp from '@/views/menu/CpmMemberRolesResp.vue';
import CpmMemberServiceRoleResp from '@/views/menu/CpmMemberServiceRoleResp.vue';
import CmpUserModuleMenu from '@/views/menu/CmpUserModuleMenu.vue';
import CmpUserRoleRespConfig from '@/views/menu/CmpUserRoleRespConfig.vue';
import MainMenuManagement from '@/views/menu/MainMenuManagement.vue';
import MenuManagement from '@/views/menu/MenuManagement.vue'
import ModuleManagement from '@/views/menu/ModuleManagement.vue';
import StandardUserModuleMenu from '@/views/menu/StandardUserModuleMenu.vue';
import StdMemberRolesResp from '@/views/menu/StdMemberRolesResp.vue';
import StdUserRoleModuleLink from '@/views/menu/StdUserRoleModuleLink.vue'
import StdUserRoleRespConfig from '@/views/menu/StdUserRoleRespConfig.vue';
import SubModuleManagement from '@/views/menu/SubModuleManagement.vue';
import SupportServiceUserRole from '@/views/menu/SupportServiceUserRole.vue';

export default [
    {
        path: '/landing/cmpmemmdlmenu',
        name: 'admsetcmpmemmdlmenu',
        component: CpmMemberRolesResp,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/cmpmemmdlmenu',
        name: 'cmpmemmdlmenu',
        component: CpmMemberServiceRoleResp,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/cmpusrmdlmenu',
        name: 'admsetcmpusrmdlmenu',
        component: CmpUserModuleMenu,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/mngurr',
        name: 'admsetmngurr',
        component: CmpUserRoleRespConfig,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },    
    {
        path: '/landing/admset/mnmmgt',
        name: 'admsetmnmmgt',
        component: MainMenuManagement,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/mainmenu',
        name: 'admsetmainmenu',
        component: MenuManagement,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/mdlmgt',
        name: 'admsetmdlmgt',
        component: ModuleManagement,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/stdusrmdlmenu',
        name: 'admsetstdusrmdlmenu',
        component: StandardUserModuleMenu,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/stdmemmdlmenu',
        name: 'admsetstdmemmdlmenu',
        component: StdMemberRolesResp,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/userrole',
        name: 'admsetuserrole',
        component: StdUserRoleModuleLink,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },    
    {
        path: '/landing/mngstdurr',
        name: 'admsetmngstdurr',
        component: StdUserRoleRespConfig,
        meta: { layout: 'landing', accessedby: 'VSA|VOW' }
    },    
    {
        path: '/landing/admset/smdmgt',
        name: 'admsetsmdmgt',
        component: SubModuleManagement,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },
    {
        path: '/landing/admset/ssrurl',
        name: 'admsetssrurl',
        component: SupportServiceUserRole,
        meta: { layout: 'admin-setting', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|CLI|FNM|HRM|VSA' }
    },

];    