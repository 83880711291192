<template>
    <div>
        <CostEsimationMaster :ermMasterInfo="ermMasterInfo"></CostEsimationMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import CostEsimationMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        CostEsimationMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routhPath: '',
            ermMasterInfo: {
                title: "Cost Esimation Sheet",
                entity: 'cost_estimation_master',
                refEntity: 'cost_estimation',
                redirectURL: '/coemas/list',
                erdRedirectURL: '/costestimation/' 
            }
        }
    },
    mounted() {
        this.routhPath = this.$route.path;
        if (this.routhPath != null && this.routhPath.indexOf('service') == -1) {
            this.ermMasterInfo.redirectURL = '/coemas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/service/coemas/list';
        }

    },
    methods: {}
}
</script>