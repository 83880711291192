<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Initial Settings</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row mb-4">
                <div class="col-lg-12 col-xl-12">
                    <div class="form-row mb-2 mt-2">
                        <div class="col-3">
                            <div class="card mb-4">
                                <div class="card-body p-3 custom_Lookup">
                                    <ul class="typeLookup_list">
                                        <li v-for="setting in settingMenu" :ke="setting">
                                            <a class="pointer" :class="{'active' : activeSettingMenuId == setting.id}" @click="activeSettingMenuId = setting.id;activeSettingMenuTitle = setting.name;" >
                                                <i class="fa fa-cog"  :class="setting.icon_class ? setting.icon_class : 'fa-cogs'" aria-hidden="true"></i>
                                                {{ setting.name }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>      
                            </div>
                        </div>
                        <div class="col-sm-9">
                            <div class="card mb-4">
                                <div class="card-body p-3">
                                    <div id="goalType" class="typeLookup active">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <h6>{{ activeSettingMenuTitle }}
                                                </h6>
                                            </div>
                                        </div>
                                        <div v-show="activeSettingMenuId == 1" class="row">
                                            <div class="col-sm-12">
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Country</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'country_setting'" cols="6" class="pl-0">
                                                            <select v-model="companySetting.countryId" class="form-control mt-1" id="countryId">
                                                                <option value="0">Select</option>
                                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ getCountry }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'country_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setNumberValData('country_setting', companySetting.countryId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('country_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Time Zone</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'timezone_setting'" cols="6" class="pl-0">
                                                            <Select2 v-model="companySetting.timeZone" :options="timeZones" :settings="{width:'100%'}" />
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ getTimeZone }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'timezone_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setTextValData('timezone_setting', companySetting.timeZone)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('timezone_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Currency</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'currency_setting'" cols="6" class="pl-0">
                                                            <select v-model="companySetting.currencyId" class="form-control" id="currencyId">
                                                                <option value="0">Select</option>
                                                                <option v-for="(info, index) in currencies" :value="info.id">{{info.value}} ({{info.currencySymbol}})</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ getCurrency }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'currency_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setNumberValData('currency_setting', companySetting.currencyId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('currency_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="activeSettingMenuId == 2" class="row">
                                            <div class="col-sm-12">
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Calendar Year</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'calendar_year_setting'" cols="6" class="pl-0">
                                                            <!-- <input type="date" v-model="companySetting.calendarYear" class="form-control" > -->
                                                            <select v-model="companySetting.calendarYear" class="form-control" id="calendarYear">
                                                              <option v-for="year in yearArray()" :value="year">{{ year }}</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label v-if="companySetting.calendarYear" class="m-0">{{ new Date(companySetting.calendarYear).getFullYear() }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'calendar_year_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setDateValData('calendar_year_setting', companySetting.calendarYear)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('calendar_year_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Fiscal Year</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'financial_year_setting'" cols="6" class="pl-0">
                                                            <div class="form-row">
                                                                <div class="col-5">
                                                                    <input type="date" v-model="companySetting.financialYearStart" class="form-control" >
                                                                </div>
                                                                <div class="col text-center my-auto">
                                                                    <label class="my-auto">to</label>
                                                                </div>
                                                                <div class="col-5">
                                                                    <input type="date" v-model="companySetting.financialYearEnd" class="form-control" >
                                                                </div>
                                                            </div>
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ companySetting.financialYearStart | formatDate }} <span class="px-2">to</span> {{ companySetting.financialYearEnd | formatDate }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'financial_year_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setStartEndDateData('financial_year_setting', companySetting.financialYearStart, companySetting.financialYearEnd)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('financial_year_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div v-show="activeSettingMenuId == 3" class="row">
                                            <div class="col-sm-12">
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Country</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'country_setting'" cols="6" class="pl-0">
                                                            <select v-model="companySetting.countryId" class="form-control mt-1" id="countryId">
                                                                <option value="0">Select</option>
                                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ getCountry }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'country_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setNumberValData('country_setting', companySetting.countryId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('country_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Time Zone</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'timezone_setting'" cols="6" class="pl-0">
                                                            <Select2 v-model="companySetting.timeZone" :options="timeZones" :settings="{width:'100%'}" />
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ getTimeZone }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'timezone_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setTextValData('timezone_setting', companySetting.timeZone)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('timezone_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Currency</label>
                                                        </b-col>
                                                        <b-col v-if="editItemEntity == 'currency_setting'" cols="6" class="pl-0">
                                                            <select v-model="companySetting.currencyId" class="form-control" id="currencyId">
                                                                <option value="0">Select</option>
                                                                <option v-for="(info, index) in currencies" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </b-col>
                                                        <b-col v-else  cols="6" class="pl-0 my-2">
                                                            <label class="m-0">{{ getCurrency }}</label>
                                                        </b-col>
                                                        <b-col cols="2" class="my-2">
                                                            <template v-if="editItemEntity == 'currency_setting'">
                                                                <a href="javascript:" @click="editItemEntity=''" class="float-right  mr-1" title="Cancel"><i class="fa fa-times"></i></a>
                                                                <a href="javascript:" @click="setNumberValData('currency_setting', companySetting.currencyId)" class="float-right  mr-2" title="Save"><i class="fa fa-check"></i></a>
                                                            </template>
                                                            <a v-else href="javascript:" @click="showEdit('currency_setting')" class="float-right mr-1" title="Edit"><i class="fa fa-pencil"></i></a>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div v-show="activeSettingMenuId == 4" class="row">
                                            <div class="col-sm-12">
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Record Activity Log</label>
                                                        </b-col>
                                                        <b-col cols="6" class="pl-0">
                                                            <b-form-checkbox class="custom-toggle" value="1" unchecked-value="0" switch v-model="companySetting.activityLog" @change="changeSwitchState('user_activity_log_setting', companySetting.activityLog)" name="check-button"></b-form-checkbox>
                                                        </b-col>
                                                    </b-row>
                                                </div>
                                                <div class="card px-3 py-3 mb-2">
                                                    <b-row>
                                                        <b-col cols="4" class="my-2">
                                                            <label class="m-0">Record Change History</label>
                                                        </b-col>
                                                        <b-col cols="6" class="pl-0">
                                                            
                                                            <b-form-checkbox class="custom-toggle" value="1" unchecked-value="0"  switch v-model="companySetting.changeHistory" @change="changeSwitchState('change_history_setting', companySetting.changeHistory)" name="check-button"></b-form-checkbox>

                                                        </b-col>
                                                    </b-row>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Select2 from 'v-select2-component';
export default {
    name: 'app',
    components: {
        Select2,
    },

    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            showOverlay: false,
            activeSettingMenuId: 1,
            activeSettingMenuTitle: '',
            editItemEntity: '',
            
            elements: {
                showPrimeSettings: false,
                showCalendarSettings: false,
                timeZoneSettingEditMode: false,
                companyPrimeSettingEditMode: false, 
            },

            companySetting: {
                countryId: 0,
                timeZone: '',
                currencyId: 0,
                calendarYear: new Date(),
                financialYearStart: new Date(),
                financialYearEnd: new Date(),
                activityLog: 0,
                changeHistory: 0,
            },
            companySettingData: {
                id: '',
                companySettingId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                dataTypeId: '',
                entityId: '',
                entity: '',
                displayName: '',
                textValue: '',
                numberValue: '',
                dateValue: '',
                startDate: '',
                endDate: '',
                description: '',
                active: true,
                createdDt: '',
                lastUpdatedDt: '',
            },
            companySettingList: [],

            settingMenu: [
                // Id is *important* - ID is used to hide/show divs
                { id: 1, name: 'Prime Setting', icon_class: 'fa-cog' },
                { id: 2, name: 'Calendar Setting', icon_class: 'fa-cog' },
                // { id: 3, name: 'Payroll Setting', icon_class: 'fa-cog' },
                { id: 4, name: 'Activity Log', icon_class: 'fa-cog' },
            ],

            countries: [],
            currencies: [],
            timeZones: [],
            projects: [],
            // industries:[],
        }
    },
    computed: {
        // years () {
        //     const year = new Date().getFullYear()
        //     let arrYears = Array.from({length: 50}, (value, index) => (year-49) + index);
        //     return arrYears.reverse()
        // },
        getCountry() {
            let result = '-';
            if (this.countries.length>0 && this.companySetting.countryId) {
                result = this.countries.find(data => data.id == this.companySetting.countryId);
                result = result && result.value ? result.value : '-';
            }
            return result;
        },
        getCurrency() {
            let result = '-';
            if (this.currencies.length>0 && this.companySetting.currencyId) {
                result = this.currencies.find(data => data.id == this.companySetting.currencyId);
                result = result && result.value ? result.value : '-';
            }
            return result;
        },
        getTimeZone() {
            let result = '-';
            if (this.timeZones.length>0 && this.companySetting.timeZone) {
                result = this.timeZones.find(data => data.id == this.companySetting.timeZone);
                result = result && result.text ? result.text : '-';
            }
            return result;
        },
    },
    mounted() {
        this.getCompanySettings();
        this.lookupData();
    },
    methods: {
        getCompanySettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/companysetting/list', data)
                .then((response) => {
                    let result = response.data;
                    this.companySettingList = result.companySettingList;
                    this.setCompanySettingData();
                    console.log(this.companySettingData)
                })
                
        },
        lookupData: function() {
             let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/companysetting/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    // this.industries= result.industryLookup;
                    this.countries = result.countryLookup;
                    this.currencies = result.currencyLookup;
                    this.timeZones = result.timeZoneLookup;
                })
        },
        changeSwitchState(entity, value) {
            value = (value == 1) ? 0 : 1;
            this.setNumberValData(entity, value);
        },
        setNumberValData(entity, value) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = value;
            this.companySettingData.dateValue = '1950-01-01';
            this.companySettingData.startDate = '1950-01-01';
            this.companySettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setTextValData(entity, value) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = value;
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = '1950-01-01';
            this.companySettingData.startDate = '1950-01-01';
            this.companySettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setDateValData(entity, value) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = value;
            this.companySettingData.startDate = '1950-01-01';
            this.companySettingData.endDate = '1950-01-01';
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setStartEndDateData(entity, startDate, endDate) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = '1950-01-01';
            this.companySettingData.startDate = startDate;
            this.companySettingData.endDate = endDate;
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        setDatePeriodData(entity, startDate, endDate, datePeriod) {
            this.companySettingData.entity = entity;
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = 0;
            this.companySettingData.dateValue = datePeriod;
            this.companySettingData.startDate = startDate;
            this.companySettingData.endDate = endDate;
            this.$nextTick(() => {
                this.updateCompanySetting();
            })
        },
        updateCompanySetting: function() {
            console.log(this.companySettingData)
            axios.post(this.$store.getters.getAPIBasePath + '/company/companysetting/updateall', this.companySettingData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$nextTick(() => {
                            if (this.companySettingData.entity == 'currency_setting' && this.companySettingData.numberValue) {
                                let currencySymbol = this.currencies.find(({id}) => this.companySettingData.numberValue == id).currencySymbol;
                                localStorage.setItem("currencySymbol", currencySymbol);
                            }
                        })
                        this.getCompanySettings();
                        this.clearData();
                    }

                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        setCompanySettingData: function() {

            let countrySetting = this.companySettingList.find(({ entity }) => entity === 'country_setting');
            this.companySetting.countryId = countrySetting ? parseInt(countrySetting.numberValue) : '';

            let currencySetting = this.companySettingList.find(({ entity }) => entity === 'currency_setting');
            this.companySetting.currencyId = currencySetting ? parseInt(currencySetting.numberValue) : '';

            let timeZoneSetting = this.companySettingList.find(({ entity }) => entity === 'timezone_setting');
            this.companySetting.timeZone = timeZoneSetting ? timeZoneSetting.textValue : '';

            let calendarYearSetting = this.companySettingList.find(({ entity }) => entity === 'calendar_year_setting');
            this.companySetting.calendarYear = calendarYearSetting ? this.getYear(calendarYearSetting.dateValue) : '';

            let financialYearSetting = this.companySettingList.find(({ entity }) => entity === 'financial_year_setting');
            this.companySetting.financialYearStart = financialYearSetting ? this.getDateValue(financialYearSetting.startDate) : '';
            this.companySetting.financialYearEnd = financialYearSetting ? this.getDateValue(financialYearSetting.endDate) : '';

            let activityLogSetting = this.companySettingList.find(({ entity }) => entity === 'user_activity_log_setting');
            this.companySetting.activityLog = activityLogSetting ? activityLogSetting.numberValue : '';

            let changeHistorySetting = this.companySettingList.find(({ entity }) => entity === 'change_history_setting');
            this.companySetting.changeHistory = changeHistorySetting ? changeHistorySetting.numberValue : '';

            console.log(this.companySetting)
        },
        clearData: function() {
            this.companySettingData.entity = '';
            this.companySettingData.textValue = '';
            this.companySettingData.numberValue = '';
            this.companySettingData.dateValue = '';
            this.companySettingData.startDate = '';
            this.companySettingData.endDate = '';
            this.editItemEntity = '';
        },
        showEdit: function(editItemEntity) {
            this.editItemEntity = editItemEntity;
        },
    }

}
</script>