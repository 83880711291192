<template>
    <div>
        <ChecklistMasterList :ermMasterInfo="ermMasterInfo"></ChecklistMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ChecklistMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ChecklistMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Checklist List",
                entity: 'checklist_master',
                refEntity: 'checklist',
                tooltip: 'New Checklist',
                redirectURLName: 'checklist',
                newEntityMasterURL: 'cklmas',
                redirectURL: '/cklmas',
                actionName: 'Checklist'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>