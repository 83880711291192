<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <IssueBoard />
            </div>
        </div>
    </div>
</template>
<script>
import IssueBoard from '@/views/project/IssueBoard.vue';
export default {
    components: {
        IssueBoard
    }
}
</script>