<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Defect Prevention Plan</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                            <span v-if="elements.viewMode">View</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div class="">
                                                <div class="row mb-2">
                                                    <div class="col-4 p-2">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div v-if="!elements.viewMode">
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.defectPreventionPlan.title.$error }">
                                                                    <input type="text" class="form-control" v-model="defectPreventionPlan.title" id="title" :class="{ 'is-invalid': $v.defectPreventionPlan.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.defectPreventionPlan.title.$error && !$v.defectPreventionPlan.title.minLength" class="text-danger">Title must have at least {{$v.defectPreventionPlan.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.defectPreventionPlan.title.$error && !$v.defectPreventionPlan.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.defectPreventionPlan.title.$error && !$v.defectPreventionPlan.title.maxLength" class="text-danger">Title must not exceed {{$v.defectPreventionPlan.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                            <b-input-group v-if="elements.viewMode" :prepend="defectPreventionPlan.defectPreventionPlanId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="defectPreventionPlan.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 p-2">
                                                        <div class="form-group">
                                                            <label for="defectTypeId">Defect Type</label>
                                                            <select  v-if="!elements.viewMode" v-model="defectPreventionPlan.defectTypeId" class="form-control" id="defectTypeId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in defectTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="defectPreventionPlan.type" id="title">
                                                        </div>
                                                    </div>
                                                    <div class="col-4 p-2 ">
                                                        <div class="form-group">
                                                            <label for="activityId">Activity</label>
                                                            <select v-if="!elements.viewMode" v-model="defectPreventionPlan.activityId" class="form-control" id="activityId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in defectActivities" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="activityLookupTitle" class="setting-icon" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>                                                      
                                                            <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="defectPreventionPlan.activity">

                                                        </div>
                                                    </div>
                                                    <div class="col-4 p-2">
                                                        <div class="form-group">
                                                            <label for="defectDescription">Defect Descriptions</label>
                                                            <textarea v-model="defectPreventionPlan.defectDescription" :disabled="elements.viewMode" class="form-control" id="defectDescription" rows="4" maxlength="2000" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 p-2">
                                                        <div class="form-group">
                                                            <label for="solution">Solution</label>
                                                            <textarea v-model="defectPreventionPlan.solution" :disabled="elements.viewMode" class="form-control" id="solution" rows="4" maxlength="2000" required></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 p-2">
                                                        <div class="form-group">
                                                            <label for="actionTaken">Action Taken</label>
                                                            <textarea v-model="defectPreventionPlan.actionTaken" :disabled="elements.viewMode" class="form-control" id="actionTaken" rows="4" maxlength="2000" required></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="defectPreventionPlans" :fields="defectPreventionPlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteDefectPreventionPlan(data.item.id)" data-toggle="modal" data-target="#deleteDefectPreventionPlan"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Defect Prevention Plan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="defectPreventionPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ActivityLookup ref="activityLookupModal" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" />

        <Dialog id="deleteDefectPreventionPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Defect Prevention Plan?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
export default {
    name: 'app',
    components: {
        ActivityLookup,
    },    
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'defect_prevention_plan',
            userId: localStorage.getItem("userId"),

            activityLookupTitle: 'Activity Settings',

            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
            },
            users: [],
            defectTypes: [],
            defectPreventionPlanActivities: [],
            defectPreventionPlan: {
                id: '',
                defectPreventionPlanId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                title: '',
                defectTypeId: '',
                type: '',
                activityId: '',
                activity: '',
                defectDescription: '',
                solution: '',
                actionTaken: ''
            },
            defectPreventionPlans: [],
            defectPreventionPlanFields: [
                { key: 'defectPreventionPlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Defect', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.lookupData();
        this.getDefectPreventionPlans();
    },
    validations: {
        defectPreventionPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getDefectPreventionPlans: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/defect/defectpreventionplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.defectPreventionPlans = result.defectPreventionPlanList;
                    this.totalRows = this.defectPreventionPlans.length;
                    console.log(this.defectPreventionPlans);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/defect/defectpreventionplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.defectPreventionPlanActivities = result.activityLookup;
                    this.defectTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                })

        },
        saveData: function() {
            this.$v.defectPreventionPlan.$touch();
            if (!this.$v.defectPreventionPlan.$invalid) {
                if (this.elements.editMode) {
                    this.updateDefectPreventionPlan();
                } else {
                    this.saveDefectPreventionPlan();
                }
            }
        },
        saveDefectPreventionPlan: function() {
            this.$v.defectPreventionPlan.$touch();
            console.log(this.defectPreventionPlan);
            if (!this.$v.defectPreventionPlan.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/defect/defectpreventionplan/add', this.defectPreventionPlan)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getDefectPreventionPlans();
                            this.clearData();
                        }
                    })
            }
        },
        updateDefectPreventionPlan: function() {
            this.$v.defectPreventionPlan.$touch();
            if (!this.$v.defectPreventionPlan.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/defect/defectpreventionplan/update', this.defectPreventionPlan)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getDefectPreventionPlans();
                            this.clearData();
                        }
                    })
            }

        },
        deleteDefectPreventionPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/defect/defectpreventionplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDefectPreventionPlans();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.defectPreventionPlan.id = '';
            this.defectPreventionPlan.defectPreventionPlanId = '';
            this.defectPreventionPlan.title = '';
            this.defectPreventionPlan.defectTypeId = '';
            this.defectPreventionPlan.type = '';
            this.defectPreventionPlan.activityId = '';
            this.defectPreventionPlan.activity = '';
            this.defectPreventionPlan.defectDescription = '';
            this.defectPreventionPlan.solution = '';
            this.defectPreventionPlan.actionTaken = '';
            this.$v.defectPreventionPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.defectPreventionPlan.id = data.id;
            this.defectPreventionPlan.defectPreventionPlanId = data.defectPreventionPlanId;
            this.defectPreventionPlan.vhextAccountId = data.vhextAccountId;
            this.defectPreventionPlan.projectId = data.projectId;
            this.defectPreventionPlan.entityRecordMasterId = data.entityRecordMasterId;
            this.defectPreventionPlan.title = data.title;
            this.defectPreventionPlan.defectTypeId = data.defectTypeId;
            this.defectPreventionPlan.type = data.type;
            this.defectPreventionPlan.activityId = data.activityId;
            this.defectPreventionPlan.activity = data.activity;
            this.defectPreventionPlan.defectDescription = data.defectDescription;
            this.defectPreventionPlan.solution = data.solution;
            this.defectPreventionPlan.actionTaken = data.actionTaken;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.defectPreventionPlan.id = data.id;
            this.defectPreventionPlan.defectPreventionPlanId = data.defectPreventionPlanId.toString();
            this.defectPreventionPlan.vhextAccountId = data.vhextAccountId;
            this.defectPreventionPlan.projectId = data.projectId;
            this.defectPreventionPlan.title = data.title;
            this.defectPreventionPlan.defectTypeId = data.defectTypeId;
            this.defectPreventionPlan.type = data.type;
            this.defectPreventionPlan.activityId = data.activityId;
            this.defectPreventionPlan.activity = data.activity;
            this.defectPreventionPlan.defectDescription = data.defectDescription;
            this.defectPreventionPlan.solution = data.solution;
            this.defectPreventionPlan.actionTaken = data.actionTaken;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        }
    }
}
</script>