<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Payroll Setting</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-4 col-xl-4">
                    <div class="card">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Employees">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="employeeTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="employeeList" @row-selected="onRowSelectedEmp" :fields="employeeFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(salary)="data">
                                            {{ currencySymbol}}{{data.value}}
                                        </template>
                                        <template v-slot:cell(monthlyCTC)="data">
                                            {{ currencySymbol}}{{ Math.round((data.item.salary || 0) / 12) }}
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Employees found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                    <b-pagination v-if="employeeList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </b-tab>
                            </b-tabs>

                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-xl-8 pl-0">
                    <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                        <div class="card">
                            <div class="card-body p-3">
                                <span class="position-absolute mt-2 ml-auto" style="right: 15px;">
                                    <b-button v-if="!elements.addExtConfig && hasConfig" class="btn sbtn btn-xs float-right px-2" variant="outline-secondary" title="Use Existing Configuration" @click="useExistingConfig" :disabled="payslipConfigList.length===0">Use Existing Configuration</b-button>
                                </span>
                                <b-tabs content-class="mt-2" lazy>
                                    <b-tab title="Employee Payroll Components">
                                        <div class="px-3">
                                            <span class="mb-0 mt-1 title-highlight float-left">{{ employeeName }}</span>
                                            <span class="mb-0 mt-1 title-highlight float-right">Monthly CTC: {{ currencySymbol}}{{ monthlySalary }}</span>
                                        </div>
                                        <div class="clearfix"></div>
                                        <b-card v-if="elements.isPayslipConfigEmpty && !hasConfig" no-body class="mb-2">
                                            <b-card-body v-if="payslipComponentList.length === 0" class="py-5 px-2">
                                                <label class="d-block p-2 my-0 accordion-title text-center" block>
                                                    Please Add Payroll Component settings to add Payroll Components
                                                </label>
                                                <div class="text-center mt-3">
                                                    <b-button class="btn sbtn btn-xs px-2" variant="outline-secondary" title="Add Payroll Components" @click="$router.push({name: 'lanpayslipcomp'})">Add Payroll Component Settings</b-button>
                                                </div>
                                            </b-card-body>
                                            <b-card-body v-else class="py-5 px-2">
                                                <label class="d-block p-2 my-0 accordion-title text-center" block>
                                                    No Configurations found
                                                </label>
                                                <div class="text-center mt-3">
                                                    <b-button class="btn sbtn btn-xs px-2 mr-2" variant="outline-secondary" title="Add New Payroll Component Setting" @click="savePayslipConfig"><i class="fa fa-plus-circle pr-2"></i>New Payroll Component</b-button>
                                                    <b-button v-if="payslipConfigList.length>0" class="btn sbtn btn-xs px-2" variant="outline-secondary" title="Use Existing Configuration" @click="useExistingConfig">Use Existing Configuration</b-button>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                        <div v-show="!elements.isPayslipConfigEmpty">
                                            <div class="form-row mb-4">
                                                <div v-if="showPayslip" class="col-12">
                                                    <b-card no-body class="mb-2 h-100 px-2">
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="p-2 my-0 accordion-title">
                                                                Payslip
                                                            </label>
                                                            <label class="p-2 my-0 accordion-title float-right">
                                                                <b-button class="addnew-tassk-btn sbtn mb-1" @click="showPayslip = false" variant="outline-danger" size="xs" ><i class="fa fa-remove pr-2" aria-hidden="true"></i>Close</b-button>
                                                            </label>
                                                            <div class="separator mb-2 mx-2"></div>
                                                        </b-card-header>
                                                        <b-card-body class="py-3 px-0">
                                                            <PayslipAutoGenComponent :key="payslipAutoGenCompKey" ref="payslipAutoGenCompRef" :payslipAutoGenData="payrollCalculationData" />
                                                        </b-card-body>
                                                    </b-card>
                                                </div>
                                                <div v-show="showPayslip == false" class="col-lg-12 col-xl-12">
                                                    <div class="form-row">
                                                        <div class="col-12">
                                                            <div id="scope-app" class="">
                                                                <b-card no-body class="px-2">
                                                                    <b-card-header v-if="elements.visible" header-tag="header" class="p-0 accordion-header" role="tab">
                                                                        <div class="form-row align-items-center">
                                                                            <div class="col-auto">
                                                                                <label v-if="elements.visible" class="d-block p-2  m-0" block>
                                                                                    <span>Use Existing Payroll Configuration</span>
                                                                                    <!-- <span v-if="!elements.addExtConfig"><i class="fa fa-plus-circle pr-2 action-link"></i>New Payroll Configuration</span> -->
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-4">
                                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.id.$error }">
                                                                                    <select v-model="payslipConfig.id" @change="getPayslipCompSettingDetList" class="form-control" >
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in payslipConfigList" :value="info.id">{{info.title}} {{ info.isDefault ? "(default)" : "" }}</option>
                                                                                    </select>
                                                                                    <div class="required-icon"></div>
                                                                                </div>  
                                                                                <label v-if="$v.payslipConfig.id.$error && !$v.payslipConfig.id.required" class="text-danger">Please select an existing configuration</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="separator"></div>
                                                                    </b-card-header>
                                                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                                        <b-card-body class="pt-3 px-2">
                                                                            <div class="row">
                                                                                <template v-if="elements.addExtConfig">
                                                                                    <!-- <div class="col-4">
                                                                                        <div class="form-group">
                                                                                            <label for="percentVal">Existing Config</label>
                                                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.payslipConfig.id.$error }">
                                                                                                <select v-model="payslipConfig.id" @change="getPayslipCompSettingDetList" class="form-control" >
                                                                                                    <option value="">Select</option>
                                                                                                    <option v-for="(info, index) in payslipConfigList" :value="info.id">{{info.title}}</option>
                                                                                                </select>
                                                                                                <div class="required-icon"></div>
                                                                                            </div>  
                                                                                            <label v-if="$v.payslipConfig.id.$error && !$v.payslipConfig.id.required" class="text-danger">Please select an existing configuration</label>
                                                                                        </div>
                                                                                    </div> -->
                                                                                    <div class="col-12">
                                                                                        <label>Payslip Components</label>
                                                                                        <b-table ref="payslipComSetTable" outlined show-empty small head-variant=:primary sticky-header="40vh" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="payslipCompSettingList"  @row-selected="onRowSelected" :fields="payslipCompSettingFields" responsive="sm" :current-page="currentPage" :filter="filter" :filterIncludedFields="filterOn" >
                                                                                            <template #cell(index)="data">
                                                                                                {{ data.index + 1 }}
                                                                                            </template>
                                                                                            <template v-slot:cell(active)="data">
                                                                                            </template>
                                                                                            <template v-slot:cell(payslipComponentTypeId)="data">
                                                                                                <div class="" title="'EAR' - 'Earning' || 'DED' - Deduction'">{{ data.value == 1? 'EAR' : 'DED' }}</div>
                                                                                            </template>
                                                                                            <template v-slot:cell(sblCode)="data">
                                                                                                <div v-if="data.item.standardBusinessLogicId">
                                                                                                    <div v-if="data.item.sblCode=='pctc' || data.item.sblCode=='pbas'">
                                                                                                        {{ data.item.percentVal }} {{ data.item.standardBusinessLogic }}
                                                                                                    </div>
                                                                                                    <div v-if="data.item.sblCode=='pbda'">
                                                                                                        {{ data.item.percentVal }} % of Basic + DA
                                                                                                    </div>
                                                                                                    <div v-if="data.item.sblCode=='pgrs'">
                                                                                                        {{ data.item.percentVal }} % of Gross
                                                                                                    </div>
                                                                                                    <div v-if="data.item.sblCode=='pfc'">
                                                                                                        {{ data.item.percentVal }} % of Basic & Max Limit {{ currencySymbol }}{{ data.item.fixedVal }}
                                                                                                    </div>
                                                                                                    <div v-if="data.item.sblCode=='fix'">
                                                                                                        Fixed Amount {{ currencySymbol }}{{ data.item.fixedVal }}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </template>
                                                                                            <template v-slot:cell(actions)="data">
                                                                                                <a href="javascript:" title="Edit" @click="editPayrollComponentSetting(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                                                                                <a href="javascript:" title="Delete" @click="deletePayslipComponentSetting(data.item.id)" v-b-modal.deletePayslipComponentSetting><i class="fa fa-trash pl-2"></i></a>
                                                                                            </template>
                                                                                            <template v-slot:empty="scope">
                                                                                                <div class="text-center">No Payroll Component found</div>
                                                                                            </template>
                                                                                            <template v-slot:emptyfiltered="scope">
                                                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                                            </template>
                                                                                        </b-table>
                                                                                    </div>
                                                                                </template>
                                                                            </div>
                                                                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                                            <!-- <b-form-checkbox class="custom-toggle float-right" v-model="payslipConfig.isDefault" name="check-button" switch>
                                                                                Set as Default Configuration
                                                                            </b-form-checkbox> -->
                                                                        </b-card-body>
                                                                    </b-collapse>
                                                                </b-card>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="hasConfig && !elements.visible" class="card">
                                                        <div class="card-body py-1 px-3">
                                                            <div class="row view-filter">
                                                                <div class="col-sm-12">
                                                                    <div class="float-right">
                                                                        <div class="dataTables_length">
                                                                            <b-button class="btn sbtn btn-xs px-2 mr-2" variant="outline-primary" title="Preview Payslip" @click="viewAutoGenPayslip"><i class="fa fa-list pr-1"></i></i>Preview Payslip</b-button>
                                                                            <b-button :disabled="!configData.id" class="btn sbtn btn-xs float-right px-2" variant="outline-secondary" title="Add Payroll Component" @click="showAddPayrollComponentPopup"><i class="fa fa-plus-circle pr-1"></i></i>Add Payroll Component</b-button>
                                                                        </div>
                                                                    </div>
                                                                    <div class="float-left">
                                                                        <div class="dataTables_filter">
                                                                            <label>
                                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="clearfix"></div>
                                                                </div>
                                                            </div>
                                                            <b-table ref="payslipComSetTable" foot-variant="light" foot-clone no-footer-sorting outlined show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="filteredPayslipComponents"  @row-selected="onRowSelected" :fields="payslipComponentSettingFields" responsive="sm" :current-page="currentPage" :filter="filter" :filterIncludedFields="filterOn" >
                                                                <template #cell(index)="data">
                                                                    {{ data.index + 1 }}
                                                                </template>
                                                                <template v-slot:cell(active)="data">
                                                                </template>
                                                                <template v-slot:cell(payslipComponentTypeId)="data">
                                                                    <div class="" title="'EAR' - 'Earning' || 'DED' - Deduction'">{{ data.value == 1? 'EAR' : 'DED' }}</div>
                                                                </template>
                                                                <template v-slot:cell(sblCode)="data">
                                                                    <div v-if="data.item.standardBusinessLogicId">
                                                                        {{ getPayrollCalcDescription(data.item.sblCode, data.item.fixedVal, data.item.percentVal) }}
                                                                    </div>
                                                                </template>
                                                                <template v-slot:cell(amount)="data">
                                                                    <div class="text-right">{{ currencySymbol }}{{ data.value ? Math.round(data.value) : 0 }}</div>
                                                                </template>
                                                                <template v-slot:cell(actions)="data">
                                                                    <a href="javascript:" title="Edit" @click="editPayrollComponentSetting(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                                                    <a v-if="!data.item.isBasic" href="javascript:" title="Delete" @click="deletePayslipComponentSetting(data.item.id)" v-b-modal.deletePayslipComponentSetting><i class="fa fa-trash pl-2"></i></a>
                                                                </template>
                                                                <template  v-slot:foot()="data">
                                                                    <span></span>
                                                                </template>
                                                                <template v-slot:empty="scope">
                                                                    <div class="text-center">No Payroll Component found</div>
                                                                </template>
                                                                <template v-slot:foot(payslipComponent)="data">
                                                                    <div class="text-right">
                                                                        <span>Net Pay </span>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:foot(amount)="data">
                                                                    <div class="text-right font-weight-bold">
                                                                        <span class="text-right">{{ currencySymbol }} {{totalAmount.toFixed(2)}}</span>
                                                                    </div>
                                                                </template>
                                                                <template v-slot:emptyfiltered="scope">
                                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                </template>
                                                            </b-table>
                                                            
                                                            <!-- <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span> -->

                                                            <!-- <b-pagination v-if="payslipComponentSettingList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                        <template #overlay>
                            <div class="text-center">
                                <LoadingSpinner />
                            </div>
                        </template>
                    </b-overlay>
                </div>
            </div>
        </div>
        <PayslipDetailAutoGenPopup :key="payslipAutoGenPopupKey" ref="payslipDetailAutoGenPopupRef" :payslipConfigId="Number(configData.id)" @closeDialog="showDialog=false;" />
        <AddPayrollComponentPopup :key="addPayrollComponentPopupKey" :existingComponentList="payslipComponentSettingList" :payslipConfigId="Number(configData.id)" @refreshData="getPayslipCompSetting" />
        <EditPayrollComponentSettingPopup :key="editPayrollComponentPopupKey"  ref="editPayrollComponentPopupRef" @refreshData="getPayslipCompSetting" />
        <DeleteComponent id="deletePayslipComponentSetting" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Payroll Component ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
import AddPayrollComponentPopup from '@/components/popup/action/AddPayrollComponentPopup.vue';
import EditPayrollComponentSettingPopup from '@/components/popup/action/EditPayrollComponentSettingPopup.vue';
import PayslipAutoGenComponent from '@/components/common/PayslipAutoGenComponent.vue'
import PayslipDetailAutoGenPopup from '@/components/popup/itemdetail/PayslipDetailAutoGenPopup.vue';
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        AddPayrollComponentPopup,
        EditPayrollComponentSettingPopup,
        PayslipAutoGenComponent,
        PayslipDetailAutoGenPopup,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            isBusy: false,
            hasConfig: false,
            elements: {
                showDetail: false,
                addExtConfig: false,
                visible:false,
                payslipCalcEditMode: false,
                payslipCalcNewMode: false,
                isPayslipConfigEmpty: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),
            // entity: 'employment_history',

            addPayrollComponentPopupKey: 100,
            editPayrollComponentPopupKey: 500,
            payslipAutoGenCompKey: 1000,
            showPayslip: false,

            employeeId: '',
            humanResourceId: '',
            employeeName: '',

            employeeData: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeeId: '',
                humanResourceId: '',
                employeeCode: '',
                departmentId: '',
                designationId: '',
                jobCategoryId: '',
                jobCategory: '',
                salary: '',
                reportingToId: '',
                recruitedById: '',
                employmentChangeById: localStorage.getItem("userId"),
                wefDate: '',
                endDate: '',
                joiningDate: '',
                workTypeId: '',
                workShiftId: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
            },

            payslipCalcTempId: 1,
            payslipCalcEditId: '',

            payslipAutoGenPopupKey: 0,
            payrollCalculationData: {
                payslipConfigId: '',
                employeeId: null,
                isEmployee: true,
                monthlyCTC: '',
            },

            configData: {
                id: '',
                isCustom: '',
            },

            payslipConfig: {
                id: '',
                payslipConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                jobCategoryId: '',
                title: '',
                startDate: '',
                endDate: '',
                isStandard: '',
                humanResourceId: '',
                isCustom: '',
                active: true,
                isDefault: false,
                createdById: localStorage.getItem("userId"),
                payslipComponentSettingData: {
                    payslipComponentId: '',
                    percentVal: '',
                },
                tdsComponentData: {
                    payslipComponentId: '',
                    businessLogicTypeId: '',
                },
                payslipComponentSettingList: []
            },
            payslipComponentSetting: {
                id: '',
                payslipComponentSettingId: '',
                payslipComponent: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                payslipConfigId: '',
                payslipComponentId: '',
                payslipComponentTypeId: '',
                isBasic: '',
                createdById: localStorage.getItem("userId"),
            },
            payslipCalc: {
                id: '',
                payslipCalcId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: '',
                refEntityId: '',
                payslipComponent: '',
                mathOperationTypeId: '',
                fixedVal: '',
                percentVal: '',
                percentCalcValOfCompId: '',
                ifExp1ValOfCompId: '',
                mathOperatorEnum: '',
                ifExp2Val: '',
                thenValOfCompId: '',
                elseVal: '',
                execOrder: '',
                active: 1,
                createdById: localStorage.getItem("userId"),
            },

            mainTableArray: [],

            mathOperationTypes: [],
            payslipConfigList: [],
            jobCategoryLookup: [],
            payslipComponentLookup: [],
            businessLogicTypes: [],
            payslipComponentSettingList: [],
            payslipComponentDetailList: [],
            payslipCompSettingList: [],
            payslipComponentList: [],

            employeeList: [],
            employeeFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },        
                { key: 'salary', label: 'Annual CTC', tdClass:'', sortable: true, sortDirection: 'desc' },        
                { key: 'monthlyCTC', label: 'Monthly CTC', tdClass:'', sortable: true, sortDirection: 'desc' },        
                // { key: 'department', label: 'Department', sortable: true, sortDirection: 'desc' },        
            ],

            payslipComponentFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'payslipComponent', label: 'Payroll Component', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentTypeId', label: 'Type', sortable: true, sortDirection: 'desc' },
                // { key: 'projectType', label: 'Project Type', sortable: true, sortDirection: 'desc' },                
                // { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            payslipComponentSettingFields: [
                {key: 'index',label: 'SN'},
                // { key: 'payslipComponentId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponent', label: 'Component Title', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentTypeId', label: 'Component Type', sortable: true, sortDirection: 'desc' },
                // { key: 'standardBusinessLogic', label: 'Calculation Logic', sortable: true, sortDirection: 'desc' },
                // { key: 'standardPayslipComponent', label: 'Std. Component Map', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'sblCode', label: 'Calculation', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            payslipCompSettingFields: [
                {key: 'index',label: 'SN'},
                { key: 'payslipComponent', label: 'Component Title', sortable: true, sortDirection: 'desc' },
                { key: 'payslipComponentTypeId', label: 'Component Type', sortable: true, sortDirection: 'desc' },
                { key: 'sblCode', label: 'Calculation', sortable: true, sortDirection: 'desc' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        monthlySalary() {
            return this.employeeData.salary ? Math.trunc(this.employeeData.salary / 12) : 0.00;
        },
        payslipBasicComponent() {
            let result = {
                id: '',
                payslipComponent: '',
                payslipComponentTypeId: '',
                percentVal: '',
                standardBusinessLogicId: '',
                standardPayslipComponentId: '',
            };
            if (this.payslipComponentLookup && this.payslipComponentLookup.length>0) {
                result = this.payslipComponentLookup.find(data => data.standardPayslipComponentId==1);
                console.log(result)
            }
            return result
        },
        filteredPayslipComponents() {
            return this.payslipComponentSettingList.map(item1 => {
              const matchingItem = this.payslipComponentDetailList.find(item2 => item2.payslipComponentId == item1.payslipComponentId);

              return { ...item1, amount: matchingItem ? (matchingItem.amount) : 0 };
            });
        },
        defaultPayslipConfig() {
            return this.payslipConfigList.length > 0 ? this.payslipConfigList.find(data => data.isDefault) : null;
        },
        totalAmount() {
            return this.filteredPayslipComponents.reduce((accum, item) => {
                return accum + parseFloat(item.amount || 0)
            }, 0.00)
        },
    },
    mounted() {
        this.getEmployeeList();
        this.lookupData();
        this.getPayslipConfigList();
        this.getPayslipComponentList();
        this.lookupData();
        // this.getUserMenu(localStorage.getItem("userId"));
    },
    validations: {
        payslipConfig: {
            // title: {
            //     required
            // },
            id: {
                required: requiredIf(function (abc) {
                    return this.elements.addExtConfig;
                }),
                // percentVal: {
                //     required: requiredIf(function (abc) {
                //         return !this.elements.addExtConfig;
                //     }),
                // }
            },
        },
    },
    methods: {
        getEmployeeList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/list', data)
                .then((response) => {
                    let result = response.data;
                    this.employeeList = result.employeeList;
                    this.totalRows = this.employeeList.length;
                    if (this.employeeList.length>0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.employeeId, this.mainTableArray, 'employeeTable');
                        })
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getPayslipConfigList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipconfig/list',data)
                .then((response) => {
                    let result = response.data;
                    this.payslipConfigList = result.payslipConfigList;
                    // this.payslipConfigId = this.getPayslipConfigData ? this.getPayslipConfigData.id : '';
                    // this.payslipConfig.id = this.payslipConfigId;
                    // this.$nextTick(() => {
                    //     this.getPayslipCompSetting();
                    // })
                })
        },
        getPayslipComponentList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponent/list',data)
                .then((response) => {
                    let result = response.data;
                    this.payslipComponentList = result.payslipComponentList;
                })
        },
        getPayslipCompSetting: function() {
            if (this.configData.id) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    payslipConfigId: this.configData.id,
                    employeeId: this.employeeId,
                    monthlyCTC: this.monthlySalary,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/list',data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.payslipComponentSettingList = result.payslipComponentSettingList;
                        this.payslipComponentDetailList = result.payslipList ?? [];
                        if (result.payslipComponentSettingList && result.payslipComponentSettingList.length > 0) {
                            this.$nextTick(() => {
                                this.$refs.payslipComSetTable.selectRow(0);
                            })
                        }
                    })
                    .catch((error) => {
                        this.showOverlay = false;
                    })
            }
            else {
                this.payslipComponentSettingList = [];
            }
        },
        getPayslipCompSettingDetList: function() {
            if (this.payslipConfig.id) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    payslipConfigId: this.payslipConfig.id
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/list',data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.payslipCompSettingList = result.payslipComponentSettingList;
                        if (result.payslipComponentSettingList && result.payslipComponentSettingList.length > 0) {
                            this.$nextTick(() => {
                                this.$refs.payslipComSetTable.selectRow(0);
                            })
                        }
                    })
                    .catch((error) => {
                        this.showOverlay = false;
                    })
            }
            else {
                this.payslipCompSettingList = [];
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcalc/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.mathOperationTypes = result.typeLookup;
                    this.payslipComponentLookup = result.payslipComponentLookup;
                    this.businessLogicTypes = result.businessTypeLookup;
                    this.jobCategoryLookup = result.customCategoryLookup;
                    this.$nextTick(() => {
                        this.payslipConfig.payslipComponentSettingData.payslipComponentId = this.payslipBasicComponent.id;
                        this.payslipConfig.payslipComponentSettingData.percentVal = this.payslipBasicComponent.percentVal;
                    })
                })

        },

        onRowSelectedEmp: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.addExtConfig = false;
                this.elements.visible = false;
                this.employeeId = data.id;
                this.humanResourceId = data.humanResourceId;
                this.employeeName = data.name;
                this.employeeData.vhextAccountId = data.vhextAccountId;
                this.employeeData.employeeId = data.employeeId;
                this.employeeData.humanResourceId = data.humanResourceId;
                this.employeeData.departmentId = data.departmentId;
                this.employeeData.designationId = data.designationId;
                this.employeeData.jobCategoryId = data.jobCategoryId;
                this.employeeData.jobCategory = data.jobCategory;
                this.employeeData.salary = data.salary;
                this.employeeData.reportingToId = data.reportingToId;
                this.employeeData.payslipConfigId = data.payslipConfigId;
                this.employeeData.employmentChangeById = data.employmentChangeById;
                this.employeeData.wefDate = data.wefDate;
                this.employeeData.endDate = data.endDate;
                this.employeeData.createdById = data.userId;

                // this.payslipConfigId = data.payslipConfigId;
                this.payslipConfig.id = data.payslipConfigId ? data.payslipConfigId : '';
                this.configData.id = data.payslipConfigId;
                // this.$nextTick(() => {
                //     this.configData.isCustom = this.getPayslipConfigData().isCustom;
                // })

                if (!data.payslipConfigId) {
                    this.hasConfig = false;
                    this.elements.isPayslipConfigEmpty = true;
                    this.elements.visible = true;
                    this.payslipConfig.title = 'Config_' + this.getAcronym(data.name).toLowerCase();
                }
                else {
                    this.hasConfig = true;
                    this.elements.isPayslipConfigEmpty = false;
                    this.elements.visible = false;
                    this.payslipConfig.title = '';
                }

                this.$nextTick(() => {
                    this.resetData();
                    // this.viewAutoGenPayslip();
                    this.getPayslipCompSetting();
                })
                // this.getEmploymentHistory();
            }
        },
        saveData: function() {
            if (this.elements.addExtConfig) {
                this.addExistingConfig();
            }
            else {
                this.$v.payslipConfig.$touch();
                if (!this.$v.payslipConfig.$invalid) {
                    this.savePayslipConfig();
                }
            }
        },
        savePayslipConfig: function() {
            let payslipComponent = this.payslipComponentList.find(data => data.id == this.payslipConfig.payslipComponentSettingData.payslipComponentId);
            this.payslipConfig.payslipComponentSettingData.payslipComponent = payslipComponent?.payslipComponent ? payslipComponent.payslipComponent : '';
            this.payslipConfig.payslipComponentSettingData.isTaxable = payslipComponent?.isTaxable ? payslipComponent.isTaxable : false;
            this.payslipConfig.payslipComponentSettingData.payslipComponentTypeId = payslipComponent?.payslipComponentTypeId ? payslipComponent.payslipComponentTypeId : '';
            this.payslipConfig.payslipComponentSettingData.execOrder = 1;
            this.payslipConfig.payslipComponentSettingData.mathOperationTypeId = 2;
            this.payslipConfig.payslipComponentSettingData.isBasic = true;
            this.payslipConfig.payslipComponentSettingData.standardBusinessLogicId = this.payslipBasicComponent.standardBusinessLogicId;
            this.payslipConfig.payslipComponentSettingData.standardPayslipComponentId = this.payslipBasicComponent.standardPayslipComponentId;
            this.payslipConfig.payslipComponentSettingList.push(this.payslipConfig.payslipComponentSettingData);

            // let tdsComponent = this.payslipComponentList.find(data => data.id == this.payslipConfig.tdsComponentData.payslipComponentId);
            // this.payslipConfig.tdsComponentData.payslipComponentTypeId = tdsComponent ? tdsComponent.payslipComponentTypeId : '';
            // this.payslipConfig.tdsComponentData.execOrder = 2;
            // this.payslipConfig.payslipComponentSettingData.isTdsComponent = true;
            // this.payslipConfig.payslipComponentSettingList.push(this.payslipConfig.tdsComponentData);
            this.payslipConfig.isCustom = true;
            this.payslipConfig.humanResourceId = this.humanResourceId;
            // console.log(this.payslipConfig, this.humanResourceId);
            // alert('returned');
            // return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipconfig/add', this.payslipConfig)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.payslipConfig.id = '';
                        this.configData.id = '';
                        this.getPayslipConfigList();
                        this.getEmployeeList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addExistingConfig: function() {
            if (this.configData.id != this.payslipConfig.id) {
                this.payslipConfig.isCustom = true;
                this.payslipConfig.humanResourceId = this.humanResourceId;
                this.$v.payslipConfig.$touch();
                if (!this.$v.payslipConfig.$invalid) {
                    // console.log(this.payslipConfig)
                    // return;
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipconfig/existing/add', this.payslipConfig)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            this.elements.addExtConfig = false;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Success',
                                    message: result.message,
                                    position: 'topRight'
                                });
                                this.getPayslipConfigList();
                                this.getEmployeeList();
                                this.clearData();
                            }
                        })
                        .catch(error => {
                            this.showOverlay = false;
                            iziToast.error({
                               title: 'Error',
                               message: 'Error',
                               position: 'topRight'
                            });
                        })
                }
            }
            else {
                iziToast.info({
                   title: 'Info',
                   message: 'No changes to save',
                   position: 'topCenter'
                });
            }
        },
        clearData: function() {
            this.elements.addExtConfig = false;
            this.elements.visible = false;
            this.hasConfig = this.configData.id ? true : false;
            this.elements.isPayslipConfigEmpty = this.configData.id ? false : true;
            this.payslipConfig.payslipConfigId = '';
            this.payslipConfig.jobCategoryId = '';
            this.payslipConfig.title = '';
            this.payslipConfig.startDate = '';
            this.payslipConfig.endDate = '';
            this.payslipConfig.isStandard = false;
            this.payslipConfig.active = true;
            this.payslipConfig.isDefault = false;
            this.payslipConfig.payslipComponentSettingData.payslipComponentId = this.payslipBasicComponent.id;
            this.payslipConfig.payslipComponentSettingData.percentVal = this.payslipBasicComponent.percentVal;
            this.payslipConfig.tdsComponentData.payslipComponentId = '';
            this.payslipConfig.tdsComponentData.businessLogicTypeId = '';
            this.payslipConfig.payslipComponentSettingList = [];
            this.payslipCompSettingList = [];
            this.$v.payslipConfig.$reset();

            // this.getPayslipCompSetting(this.payslipConfig.id);
        },
        showEdit: function() {
            if (this.configData.id) {
                let data = this.payslipConfigList.find((item) => item.id == this.configData.id);
                this.elements.editMode = true;
                this.elements.visible = true;
                this.payslipConfig.id = data.id;
                this.payslipConfig.payslipConfigId = data.payslipConfigId;
                this.payslipConfig.vhextAccountId = data.vhextAccountId;
                this.payslipConfig.jobCategoryId = data.jobCategoryId;
                this.payslipConfig.title = data.title;
                this.payslipConfig.startDate = data.startDate;
                this.payslipConfig.endDate = data.endDate;
                this.payslipConfig.isStandard = data.isStandard;
                this.payslipConfig.isDefault = data.isDefault;
                this.payslipConfig.active = data.active;
                this.payslipConfig.createdById = data.createdById;
            }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.payslipComponentSetting.payslipComponent = data.payslipComponent;
                this.payslipComponentSetting.id = data.id;
                // this.payslipComponentSetting.payslipComponentSettingId = data.payslipComponentSettingId;
                // this.payslipComponentSetting.vhextAccountId = data.vhextAccountId;
                // this.payslipComponentSetting.payslipConfigId = data.payslipConfigId;
                // this.payslipComponentSetting.payslipComponentId = data.payslipComponentId;
                // this.payslipComponentSetting.payslipComponentTypeId = data.payslipComponentTypeId;
                // this.payslipComponentSetting.isBasic = data.isBasic;
            }
        },
        addPayslipComponent: function(data) {
            this.payslipComponentSetting.payslipConfigId = this.configData.id;
            this.payslipComponentSetting.payslipComponentId = data.id;
            this.payslipComponentSetting.payslipComponentTypeId = data.payslipComponentTypeId;
            this.payslipComponentSetting.isBasic = data.isBasic;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/add', this.payslipComponentSetting)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipConfigList();
                        // this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deletePayslipComponentSetting: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/payslipcomponentsetting/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPayslipCompSetting();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 0;

                this.updateActivationState();

            } else {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 1;

                this.updateActivationState();
            }
            console.log(this.activity.id, this.activity.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.activity)
                .then((response) => {
                    console.log(this.activity.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.activity.active = true;
                    this.getPayslipCompSetting();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        showAddPayrollComponentPopup: function(data) {
            this.addPayrollComponentPopupKey++;
            this.$nextTick(()=> {
                this.$bvModal.show('addPayrollComponentPopup');
            })
        },
        editPayrollComponentSetting: function(data) {
            this.editPayrollComponentPopupKey++;
            this.$nextTick(()=> {
                this.$refs.editPayrollComponentPopupRef.editPayrollComponentSetting(data);
                this.$bvModal.show('editPayrollComponentSettingPopup');
            })
        },
        componentAlreadyExists: function(id) {
            let result = false;
            this.payslipComponentSettingList.map(data => {
                if (id && data.payslipComponentId && id == data.payslipComponentId) {
                    result = true;
                }
            });
            return result;
        },
        resetData() {
            this.showPayslip=false;
        },
        useExistingConfig() {
            this.elements.addExtConfig = true;
            this.elements.visible = true;
            this.elements.isPayslipConfigEmpty = false;
            this.payslipConfig.id = this.defaultPayslipConfig ? this.defaultPayslipConfig.id : '';
            this.payslipCompSettingList = [];
            this.resetData();
            this.getPayslipCompSettingDetList();
        },
        getPayslipConfigData() {
            // if (this.configData.id) {
            //     return this.payslipConfig;
            // }
            let payslipConfig = {
                id: '',
                isCustom: '',
            };
            if (this.payslipConfigList && this.payslipConfigList.length>0) {
                payslipConfig = this.payslipConfigList.find(data => this.configData.id == data.id);
                payslipConfig = payslipConfig ? payslipConfig : this.payslipConfigList[0];
            }
            return payslipConfig
        },
        viewAutoGenPayslip: function() {
            this.payrollCalculationData.payslipConfigId = this.configData.id;
            this.payrollCalculationData.employeeId = this.employeeId;
            this.payrollCalculationData.isEmployee = true;
            this.payrollCalculationData.monthlyCTC = this.employeeData.salary;
            console.log(this.payrollCalculationData)
            // this.$v.payrollCalculationData.$touch();
            if (this.employeeData.salary) {
                this.showPayslip = true;
                this.payslipAutoGenCompKey++;
            }
        },
        getPayrollCalcDescription(sblCode, fixedVal, percentVal) {
            if(sblCode=='pctc') {
                return percentVal + ' % of CTC';
            }
            else if(sblCode=='pbas') {
                return percentVal + ' % of Basic';
            }
            else if(sblCode=='pbda') {
                return percentVal + ' % of Basic + DA';
            }
            else if(sblCode=='pgrs') {
                return percentVal + ' % of Gross';
            }
            else if(sblCode=='pfc') {
                return percentVal + ' % of Basic & Max Limit currencySymbol ' + fixedVal;
            }
            else if(sblCode=='fix') {
                return 'Fixed Amount ' + this.currencySymbol + fixedVal;
            }
            else if(sblCode=='otmfix') {
                return fixedVal + ' per hour';
            }
            else if(sblCode=='otmphs') {
                return percentVal + ' % of hourly salary';
            }
            else if(sblCode=='lopfix') {
                return fixedVal + ' working days';
            }
            else if(sblCode=='lopmth') {
                return 'Total days in the payroll month';
            }
            else if(sblCode=='lopwrd') {
                return 'Actual Working days in the payroll month';
            }
        }

    }
}
</script>