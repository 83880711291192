<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-sm-4">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">Menu Managements</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="row">
                    <div class="col-lg-5 col-xl-5">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="form-row dataTables_filter">
                                    <div class="col-sm-2">
                                        <label class="m-0 pt-1">User Type</label>
                                    </div>
                                    <div class="col-sm-5 view-filter">
                                        <select v-model="userTypeData.userTypeId" @change="selectUserRoleFirstRow" class="form-control form-control-sm" id="standardMenuTypeId">
                                            <option value="">Select User Type</option>
                                            <option v-for="(info, index) in customUserRoles" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-3">
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="dataTables_length">
                                            <b-form-group label="" label-cols-sm="0" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>                                
                                <b-table ref="userRoleTable" v-model="mainTableArray" outlined show-empty small stacked="md" sort-icon-left :items="userRoleList" :fields="userRoleFields" selectable :select-mode="selectMode" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onMenuFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template> 
                                    <template v-slot:cell(status)="data">
                                        <div>
                                          <b-badge v-if="data.item.active" variant="success" class="fs-11">Module Included</b-badge>
                                          <b-badge v-else variant="danger" class="fs-11">Module Excluded</b-badge>
                                        </div>                                                                                    
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <!-- <span v-if="data.item.moduleTypeId==1">
                                            <button type="submit" disabled class="btn btn-outline-primary sbtn btn-xs py-1 mx-1">
                                                <span title="Prime Module">
                                                    <span>
                                                        <i class="fa fa-lock pr-2" style="color:grey"></i>Prime Module
                                                    </span>
                                                </span>
                                            </button>
                                            
                                        </span> -->
                                        <!-- <span>

                                            <button type="submit" @click="saveCompanyModuleState(data.item.id, data.item.companyModuleId, data.item.active)" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1">
                                                <span v-if="data.item.active" title="Exclude Module">
                                                    <span>
                                                        <i class="fa fa-remove pr-2" style="color:red"></i>Exclude
                                                    </span>
                                                </span>
                                                <span v-else title="Include Module">
                                                    <span>
                                                        <i class="fa fa-plus pr-2" aria-hidden="true" style="color:green"></i>Include
                                                    </span>
                                                </span>
                                            </button>

                                        </span>
                                        <span v-else="data.item.moduleTypeId==3">
                                            <button type="submit" @click="saveCompanyModuleState(data.item.id, data.item.companyModuleId, data.item.active)" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1">
                                                <span v-if="data.item.active" title="Un Subscribe Module">
                                                    <span>
                                                        <i class="fa fa-remove pr-2" style="color:red"></i>Un Subscribe
                                                    </span>
                                                </span>
                                                <span v-else title="Subscribe Module">
                                                    <span>
                                                        <i class="fa fa-check pr-2" aria-hidden="true" style="color:green"></i>Subscribe
                                                    </span>
                                                </span>
                                            </button>
                                            
                                        </span> -->
                                        <!-- <button type="submit" title="Show Module Menu List" @click="showModuleMenus(data.item)" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1">                                            
                                            <span>
                                                <i class="fa fa-hand-o-right pr-2" aria-hidden="true"></i>Select
                                            </span>
                                        </button> -->
                                    </template>
                                    <template v-slot:empty="user">
                                        <div class="text-center">No Roles found</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                <b-pagination v-if="userRoleList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7 pl-0">
                        <b-overlay :show="showDetOverlay" opacity="0.4" spinner-variant="primary">
                            <div class="card mb-4">
                                <div class="card-body p-1 mx-2">
                                    <span class="position-absolute mt-2 ml-auto" style="right: 1rem;">
                                        <b-button class="addnew-quicktassk-btn ml-2 float-right" @click="" variant="outline-secondary" size="sm" title="Reset to Default Module Setting"><i class="fa fa-repeat pr-1"></i>Reset Menu</b-button>
                                    </span>
                                    <b-tabs content-class="mt-3" lazy>
                                        <b-tab :title="selectedModule + ' Module' + ' - Menus'">
                                            <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-right">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                <b-form-select v-model="perMenuPage" id="perPageSelect" size="sm" :options="pageMenuOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <b-table ref="userTable" outlined show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="standardMenuModuleList" :fields="moduleMenuFields" responsive="sm" :current-page="currentMenuPage" :per-page="perMenuPage" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(isMenuExist)="data">
                                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege(data.item)">
                                                    </b-form-checkbox>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Item found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalMenuRows}} Items</span>
                                            <b-pagination v-if="standardMenuModuleList.length!=0" pills v-model="currentMenuPage" :total-rows="totalMenuRows" :per-page="perMenuPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        </b-tab>
                                    </b-tabs>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            loadOnce: true,
            elements: {
                visible: false,
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),

            selectedModule: '',
            defaultCheckbox: true,

            userTypeData: {
                userTypeId: '',
            },

            moduleId: '',

            companyModule: {
                id: '',
                companyModuleId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                moduleTypeId: '',
                moduleId: '',
                createdById: localStorage.getItem("userId"),
                active: '',
            },

            userRole: {
                id: '',
                userRoleId: '',
                userTypeId: '',
                moduleId: '',
                role: '',
                typeCode: '',
                typeClass: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            mainTableArray: [],
            customUserRoles: [],
            userRoles: [],
            companyModules: [],
            standardMenuModuleList: [],

            userRoleFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'role', label: 'Module',tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'status', label: 'Current Status', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Enabled', sortable: true, tdClass: 'text-center m-auto p-0', sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions'}
            ],
            moduleMenuFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'mainMenu', label: 'Sub Module / Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' , tdClass: 'text-left' },
                { key: 'isMenuExist', label: 'Active', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            totalRows: 1,
            totalMenuRows: 1,
            currentPage: 1,
            currentMenuPage: 1,
            perPage: 10,
            perMenuPage: 10,
            pageOptions: [10, 15, 20, 25],
            pageMenuOptions: [10, 15, 20, 25],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filterOn: [],
        }
    },
    computed: {
        userRoleList() {
            let userRoleList = [];
            if (this.userTypeData.userTypeId) {
                userRoleList = this.userRoles.filter(data => data.userTypeId == this.userTypeData.userTypeId);
            }
            else {
                userRoleList = this.userRoles;
            }
            this.totalRows = userRoleList.length;
            return userRoleList;
        }
    },
    mounted() {
        this.getModuleList();
        this.getLookupData();
    },
    methods: {
        getModuleList: function(userId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.userRoles = result.userRoleList; 

                    this.$nextTick(() => {
                        this.selectBTableRowById(this.moduleId, this.mainTableArray, 'userRoleTable');
                    })
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getCompanyModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/menu/custommodule/list', data)
                .then((response) => {
                this.showOverlay = true;
                    this.showOverlay = false;
                    let result = response.data;
                    this.companyModules = result.customModuleList;
                    console.log(this.companyModules);
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/customuserrole', data)
                .then((response) => {
                    let result = response.data;
                    this.customUserRoles = result.customUserRoleLookup;
                })

        },
        getModuleMenuList: function(moduleId) {
            if (this.userTypeData.userTypeId) {
                let data = {
                    moduleId: moduleId,
                    userTypeId: this.userTypeData.userTypeId,
                }
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/menu/userrolestandardmenu/list', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        console.log(this.showOverlay, result)
                        this.standardMenuModuleList = result.userRoleSubMenuList;
                        this.totalMenuRows = this.standardMenuModuleList.length;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        assignPrivilege(data) {
            if (data.isMenuExist == 1) {
                this.deleteUserRole(data.userRoleId)
            }
            else {
                this.userRole.userTypeId = this.userTypeData.userTypeId;
                this.userRole.moduleId = data.id;
                this.userRole.role = data.value;
                this.userRole.active = true;
                this.$nextTick(() => {
                    this.addUserRole();
                })
            }
        },
        addUserRole: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/add', this.userRole)
            .then((response) => {
                this.showDetOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    // this.clearData();
                    this.getModuleMenuList(this.moduleId);
                }
            })
            .catch(error => {
                this.showDetOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        deleteUserRole: function(id) {
            if (id) {
                this.showDetOverlay = true;
                axios.delete(this.$store.getters.getAPIBasePath + '/user/userrole/delete/' + id)
                    .then(response => {
                        this.showDetOverlay = false;
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getUserRoles();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        console.log(error);
                    });
            }
        },
        saveCompanyModuleState: function(moduleId, companyModuleId, active) {
            let moduleActive = (active == true) ? false : true ;
            let data = {
                vhextAccountId: this.vhextAccountId,
                moduleId: moduleId,
                userId: this.userId,
                active: moduleActive,
            }
            console.log(data);

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/company/modulestatechange', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCompanyModules();
                        this.getModuleList();

                        EventBus.$emit('onTopMenuReload');
                        EventBus.$emit('onAdminSettingReload');

                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        companyModuleReset: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/module/reset', data)
                .then((response) => {
                    
                    let result = response.data;

                    this.getCompanyModules();
                    this.getModuleList();

                    this.showDetOverlay = false;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onMenuFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalMenuRows = filteredItems.length
            this.currentMenuPage = 1
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data)
                this.selectedModule = data.role;
                this.moduleId = data.moduleId;
                // this.companyModule.userId = data.id;
                // this.companyModule.userName = data.name;
                this.getModuleMenuList(data.moduleId);
            }
        },
        showModuleMenus: function(data) {
            // alert(data.id);
            console.log(data)
            this.selectedModule = data.role;
            this.moduleId = data.moduleId;
            this.getModuleMenuList(data.moduleId);


        },
        selectUserRoleFirstRow() {
            if (this.userRoleList.length>0) {
                this.$nextTick(() => {
                    this.selectBTableRowById(null, this.mainTableArray, 'userRoleTable');
                })
            } else {
                this.standardMenuModuleList = [];
            }
        }
    }
}
</script>