<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Test Case</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="exportTestCase()"><i class="fa fa-file-excel-o pr-2 green-color" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div v-if="ermIsFreeze!='true'" id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Test Case</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div :class="isFunctionalTestCase ? 'col-3' : 'col-4'">
                                                            <div class="form-group">
                                                                <label for="title">Test Case Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.testCase.title.$error }">
                                                                    <input type="text" class="form-control" maxlength="100" ref="setFocus" v-model="testCase.title" id="title" :class="{ 'is-invalid': $v.testCase.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.testCase.title.$error && !$v.testCase.title.minLength" class="text-danger">Title must have at least {{$v.testCase.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.testCase.title.$error && !$v.testCase.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.testCase.title.$error && !$v.testCase.title.maxLength" class="text-danger">Title must not exceed {{$v.testCase.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div :class="isFunctionalTestCase ? 'col-3' : 'col-4'">
                                                            <div class="form-group">
                                                                <label for="testCaseTypeId">Test Case Type</label>
                                                                <select v-model="testCase.testCaseTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in testCaseTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal>
                                                                <i class="fa fa-cog" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                        <div v-if="isFunctionalTestCase" class="col-3">
                                                            <div class="form-group">
                                                                <label for="preparedUserId">Functionality</label>
                                                                <select v-model="testCase.requirementId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in requirements" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div :class="isFunctionalTestCase ? 'col-3' : 'col-4'">
                                                            <div class="form-group">
                                                                <label for="preparedUserId">Prepared By</label>
                                                                <select v-model="testCase.preparedUserId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="requirementSpec">Test Description / Execution Step</label>
                                                                <a href="javascript:" title="Expand" @click="editorExpand()"><i class="fas fa-angle-down fa-custom fa-lg  pl-2"></i></a>                                                            
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="testCase.description"></editor>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="requirementSpec">Expected Result</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="testCase.expectedResult"></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-1">
                                                        <div class="col-12">
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12">            
                                                            <div class="form-group">
                                                                <div class="card-body p-0">
                                                                    <b-card no-body>
                                                                        <div class="col-12 px-0 mt-2">
                                                                        <FunctionalReferencePopup ref="referenceModal" :entity="testCase.entity" :erdId="testCase.id" />
                                                                        </div>
                                                                    </b-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewStatement">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-4">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="testCaseDetail.testCaseId" class="mb-2">
                                                                <b-form-input aria-label="title" disabled v-model="testCaseDetail.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label for="testCaseTypeId">Test Type</label>
                                                                <input type="text" class="form-control" id="testCaseTypeId" disabled v-model="testCaseDetail.type">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label for="preparedUserId">Prepared By</label>
                                                                <input type="text" class="form-control" id="preparedUserId" disabled v-model="testCaseDetail.userName">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <label for="preparedDt">Prepared Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="testCaseDetail.preparedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                        <div class="col-12  col-sm-6">
                                                            <div class="form-group">
                                                                <label for="requirementSpec">Description / Execution Steps</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" toolbar="" :inline=false  :init="{menubar:false}"  :disabled="elements.viewMode" v-model="testCaseDetail.description"></editor>
                                                            </div>
                                                        </div>

                                                         <div class="col-12  col-sm-6">
                                                            <div class="form-group">
                                                                <label for="requirementSpec">Expected Result</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" toolbar="" :inline=false :init="{menubar:false}"  :disabled="elements.viewMode" v-model="testCaseDetail.expectedResult"></editor>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <label for="testPlanId">Test Plan</label>
                                                            <b-input-group :prepend="testCaseDetail.testPlanId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="testCaseDetail.testPlanId"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <input type="text" class="form-control" id="statusId" disabled v-model="testCaseDetail.statusId">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label>Attachment</label>
                                                                <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" viewAttachment="true" :attachments="attachments" />
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label for="startDate">Created Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="testCaseDetail.createdDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label for="endDate">Updated Date</label>
                                                                <div class="input-group date disabled-date">
                                                                    <input type="text" class="form-control disabled" :value="testCaseDetail.lastUpdatedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="float-right my-2">
                                        <download-excel :data="testCases">
                                            <a href="javascript:" @click=""><i class="fa fa-file-excel-o pr-2 green-color" aria-hidden="true"></i></a>
                                        </download-excel>
                                    </div>                                    
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" head-variant=:primary style="width: 100%;" sort-icon-left sticky-header="900px" :no-border-collapse="false" :items="testCases" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-if="ermIsFreeze!='true'" v-slot:cell(testCaseId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control action-link" v-model="data.item.testCaseId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.testCaseId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.testCaseId"> {{ data.item.testCaseId }}
                                    </div>
                                </template>                                                                                
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(description)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                </template>
                                 <template v-slot:cell(expectedResult)="data">
                                        <span class="tooltiptext" v-html="data.value"></span>
                                </template>

                                <template v-slot:cell(actions)="data">
                                    <div class="dropdown dropright d-inline">
                                        <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>
                                        <div class="dropdown-menu">
                                            <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                            <div v-if="attachmentList.length>0">
                                                <ul class="attachmentList-ul p-0 m-0">
                                                    <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                        <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

<!--                                     <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Delete" @click="deleteTestCase(data.item.id)" v-b-modal.deleteTestCase><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Test Case found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="testCases.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TestCaseDetailPopup v-if="showDialog" ref="testCaseDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="testCasePopupId" @closeDialog="closeDialog" />
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <DeleteComponent id="deleteTestCase" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Test Case" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import TestCaseDetailPopup from '@/components/popup/itemdetail/TestCaseDetailPopup.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        },
        isFunctionalTestCase() {
            return (localStorage.getItem('ermType') == 'Functional Test Case');
        }
    },
    components: {
        TypeLookup,
        HTMLDetailViewPopup,
        AttachmentComponent,
        FunctionalReferencePopup,
        TestCaseDetailPopup,
        Editor,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            
            showDialog: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermType: '',
            ermTitle: localStorage.getItem("ermTitle"),
            entity: 'test_case',
            typeEntity: 'test_case_type',

            ermIsFreeze: localStorage.getItem("ermIsFreeze"),

            idValue: '',
            idEdit: '',
            
            notEnabled: true,
            users: [],
            status: [],
            testCaseTypes: [],
            requirements: [],

            htmlData: '',
            htmlDetailViewTitle: 'Details',
            typeLookupTitle:'TestCase Type Settings',
            testCase: {
                id: null,
                testCaseId:'',
                title: '',
                testCaseTypeId: '',
                requirementId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                ermTitle: localStorage.getItem("ermTitle"),
                preparedUserId: localStorage.getItem("userId"),
                entity: 'test_case',
                description: '',
                expectedResult: '',
                executionSteps: '',
                testPlanId: '',
                preparedDt: '',
                statusId: '',
                status: ''
            },
            testCaseDetail: {
                title: '',
                type: '',
                description: '',
                preparedUserId: '',
                createdDt: '',
                lastUpdatedDt: '',
                description: '',
                expectedResult: '',
                executionSteps: '',
                testPlanId: '',
                executionSteps: '',
                preparedDt: '',
                statusId: ''
            },

            referencePopupId: 0,
            refPopupTitle: '',
            refPopupEntity: '',
            detailViewData: {
                id: '',
                testCaseId: '',
                title: '',
                description: '',
                expectedResult: '',
                status: '',
                type: '',
                testedBy: '',
                userName: '',
                preparedDt: '',
                testDate: '',
                remarks: ''
            },
            attachmentInfo: {
                title: 'Attachment'
            },

            attachments: [],

            transProps: {
                // Transition name
                name: 'flip-list'
            },

            attachmentData: {},
            attachmentList: [],

            detailViewData: {},
            testCasePopupId: 0,

            testCases: [],
            fields: [
                { key: 'testCaseId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection:'desc' },
                { 
                    key: localStorage.getItem('ermType') == 'Functional Test Case' ? 'requirement' : 'type',
                    label: localStorage.getItem('ermType') == 'Functional Test Case' ? 'Functionality' : 'TC Type',
                    tdClass: 'table-title-col-size',
                    sortable: true,
                    sortDirection:'desc'
                },
                { key: 'description', label: 'Test Description / Execution Steps',tdClass: 'table-cw-400', sortable: true, sortDirection: 'desc' },
                { key: 'expectedResult', label: 'Expected Result',tdClass: 'table-cw-400', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            editorHeight: 400,
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },            
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getTestCase();
    },
    validations: {
        testCase: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'test_case';
            this.userId = localStorage.getItem("userId");
            this.testCase.preparedUserId = this.userId;
            this.ermType = localStorage.getItem('ermType');
        },

        getTestCase: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/test/testcase/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.testCases = result.testCaseList;
                    this.totalRows = this.testCases.length;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.testCase.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/test/testcase/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.testCaseTypes = result.customTypeLookup;
                    this.status = result.statusLookup;
                    this.users = result.userLookup;
                    this.requirements = result.requirementLookup;
                    console.log(result);
                })
        },
        async saveData() {
            this.$v.testCase.$touch();
            
            if (!this.$v.testCase.$invalid) {
                let result;
                if (this.elements.editMode) {
                    result = await this.updateTestCase();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, this.testCase.id);
                } else {
                    result = await this.saveTestCase();
                    await this.$refs.referenceModal.saveRefArray(result.data.id);
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }
                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {                 
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getTestCase();
                    this.clearData();
                }
            }
        },
        saveTestCase: function() {
            this.showOverlay = true;
            console.log(this.testCase);
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/test/testcase/add', this.testCase);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateTestCase: function() {
            this.showOverlay = true;
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/test/testcase/update', this.testCase);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateId: function(id,newErdId) {
            console.log(id,newErdId);
            if (!this.matchTextValExists(id,newErdId)) {
                let data = {
                    idInitType: "ERD",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.getTestCase();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'
                });
                this.idEdit='';
                this.getTestCase();                
            }
            
        },
        cancelIdEdit: function() {
            this.testCases = this.testCases.map(data => {
                if(data.id == this.idEdit) {
                    data.testCaseId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },
        deleteTestCase: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/test/testcase/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTestCase();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.attachmentInfo.title = "Attachment";
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.testCase.title = '';
            this.testCase.testCaseTypeId = '';
            this.testCase.requirementId = '';
            this.testCase.description = '';
            this.testCase.expectedResult = '';
            this.testCase.executionSteps = '';
            this.testCase.testPlanId = '';
            this.testCase.executionSteps = '';
            this.testCase.preparedDt = '';
            this.testCase.preparedUserId = this.userId;
            this.testCase.statusId = '';
            this.attachments = [];
            this.$refs.referenceModal.clearReference();
            this.$refs.attachmentControl.clearAttachment();
            this.$v.testCase.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.testCase.id = data.id;
            this.testCase.testCaseId=data.testCaseId;
            this.testCase.title = data.title;
            this.testCase.testCaseTypeId = data.testCaseTypeId;
            this.testCase.requirementId = data.requirementId;
            this.testCase.description = data.description;
            this.testCase.expectedResult = data.expectedResult;
            this.testCase.preparedUserId = data.preparedUserId;
            this.testCase.executionSteps = data.executionSteps;
            this.testCase.testPlanId = data.testPlanId;
            this.testCase.executionSteps = data.executionSteps;
            this.testCase.preparedDt = data.preparedDt;
            this.testCase.statusId = data.statusId;
            this.attachmentInfo.title = "Attachment";
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })

            // this.setFocus();
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.testCaseDetail.testCaseId = data.testCaseId;
            this.testCaseDetail.title = data.title;
            this.testCaseDetail.type = data.type;
            this.testCaseDetail.description = data.description;
            this.testCaseDetail.expectedResult = data.expectedResult;
            this.testCaseDetail.userName= data.userName;
            this.testCaseDetail.createdDt = data.createdDt;
            this.testCaseDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.testCaseDetail.id = data.id;
            this.testCaseDetail.testCaseTypeId = data.testCaseTypeId;
            this.testCaseDetail.requirementId = data.requirementId;
            this.testCaseDetail.preparedUserId = data.preparedUserId;
            this.testCaseDetail.executionSteps = data.executionSteps;
            this.testCaseDetail.testPlanId = data.testPlanId;
            this.testCaseDetail.executionSteps = data.executionSteps;
            this.testCaseDetail.preparedDt = data.preparedDt;
            this.testCaseDetail.statusId = data.statusId;
            this.attachmentInfo.title = "";
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })

            // this.setFocus();
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },
        editorExpand: function() {
            this.initObj.height = 400;
/*            this.editorHeight = 300;*/
            console.log(this.initObj.height);
        },
        viewReference: function(data) {
            this.referencePopupId = data.id;
            this.refPopupTitle = data.title;
            this.refPopupEntity = 'test_case';

            this.detailViewData.id = data.id;
            this.detailViewData.testCaseId = data.testCaseId;
            this.detailViewData.title = data.title;
            this.detailViewData.description = data.description;
            this.detailViewData.expectedResult = data.expectedResult;
            this.detailViewData.status = data.status;
            this.detailViewData.type = data.type;
            this.detailViewData.testedBy = data.testedBy;
            this.detailViewData.userName = data.userName;
            this.detailViewData.preparedDt = data.preparedDt;
            this.detailViewData.testDate = data.testDate;
            this.detailViewData.remarks = data.remarks;
            console.log(data);
            this.$refs.referenceDetailRef.getRequirementSpecs();
        },
        exportTestCase() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/export/testcase', data, { responseType: 'arraybuffer' })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    let currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '');

                    let fileName = this.ermTitle + "_" + "TestCase" + "_" + currentDateWithFormat + ".xlsx";

                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;

                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.log(error.response.data);
                    iziToast.error({
                        title: 'Warning!',
                        message: error.response.data.message,
                        position: 'topRight'
                    });                
                });

        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.testCasePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.testCaseDetailPopupRef.getDetails(this.testCasePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        matchTextValExists: function(id,textVal) {
            let result = false;
            this.testCases.map(data => {
                if (textVal && data.testCaseId && data.id !== id) {
                    if (data.testCaseId == textVal) {
                        result = true;
                    }
                }
            });
            return result;
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },

        // setFocus: function()
        // {
        //     this.$refs.setFocus.focus();
        // }

    }
}
</script>