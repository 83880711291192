import BudgetPlan from '@/views/finance/BudgetPlan.vue'
import BudgetPlanMaster from '@/views/finance/BudgetPlanMaster.vue'
import BudgetPlanMasterList from '@/views/finance/BudgetPlanMasterList.vue'
import CostEstimation from '@/views/finance/CostEstimation.vue'
import CostEstimationMaster from '@/views/finance/CostEstimationMaster.vue'
import CostEstimationMasterList from '@/views/finance/CostEstimationMasterList.vue'
import FinancialPlan from '@/views/finance/FinancialPlan.vue'
import FinancialPlanMasterList from '@/views/finance/FinancialPlanMasterList.vue'
import FinancialTxn from '@/views/finance/FinancialTxn.vue'
import FinancialTxnMaster from '@/views/finance/FinancialTxnMaster.vue'
import FinancialTxnMasterList from '@/views/finance/FinancialTxnMasterList.vue'
// import MonthlyBudgetPlan from '@/views/finance/MonthlyBudgetPlan.vue'
import ProjectFunding from '@/views/finance/ProjectFunding.vue'
import ProjectFundingMasterList from '@/views/finance/ProjectFundingMasterList.vue'


export default [
    {
        path: '/service/budgetplan/:ermId',
        name: 'srvbudgetplan',
        component: BudgetPlan,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/bplmas',
        name: 'srvbplmas',
        component: BudgetPlanMaster,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/bplmas/list',
        name: 'srvbplmaslist',
        component: BudgetPlanMasterList,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/financialplan/:ermId',
        name: 'srvfinancialplan',
        component: FinancialPlan,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/fplmas/list',
        name: 'srvfplmaslist',
        component: FinancialPlanMasterList,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/financialtxn/:ermId',
        name: 'srvfinancialtxn',
        component: FinancialTxn,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/ftxmas',
        name: 'srvftxmas',
        component: FinancialTxnMaster,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/ftxmas/list',
        name: 'srvftxmaslist',
        component: FinancialTxnMasterList,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    // {
    //     path: '/service/monthlybudgetplan/:ermId',
    //     name: 'srvmonthlybudgetplan',
    //     component: MonthlyBudgetPlan,
    //     meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    // },

    {
        path: '/service/projectfunding/:ermId',
        name: 'srvprojectfunding',
        component: ProjectFunding,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/pfnmas/list',
        name: 'srvpfnmaslist',
        component: ProjectFundingMasterList,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {
        path: '/service/costestimation/:ermId',
        name: 'srvcostestimation',
        component: CostEstimation,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/coemas',
        name: 'srvcoemas',
        component: CostEstimationMaster,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/service/coemas/list',
        name: 'srvcoemaslist',
        component: CostEstimationMasterList,
        meta: { layout: 'service', accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    ];