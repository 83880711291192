<template>
    <div class="jobTitle">
        <b-modal scrollable ref="jobTitlePopup" size="md" id="jobTitlePopup" centered modal-class="jobTitlePopup" :title="jobTitlePopupTitle" :no-close-on-backdrop="true" ok-title="Ok">
            <div class="p-2">
            <b-card no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                        <span v-if="!elements.resourcePlanEdit">
                            <i class="fa fa-plus-circle pr-2 action-link"></i>Add Job Title
                        </span>
                        <span v-if="elements.resourcePlanEdit">Edit</span>
                    </label>
                </b-card-header>
                <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.resourcePlanVisible" role="tabpanel">
                    <b-card-body class="p-1">
                        <div class="row">
                            <b-input-group class="form-group mb-1">
                                <b-col class="align-middle float-left my-auto mr-0" cols="3">Resource Plan</b-col>
                                <b-col cols="7" class="pr-1">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.resourcePlan.entityRecordMasterId.$error }">
                                    <select v-model="resourcePlan.entityRecordMasterId" @change="getResourcePlan()" id="position" class="form-control ">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in entityERM" :value="info.id">{{info.value}}</option>
                                    </select> 
                                    <div class="required-icon"></div>
                                </div>
                                <label v-if="$v.resourcePlan.entityRecordMasterId.$error && !$v.resourcePlan.entityRecordMasterId.required" class="text-danger">Please select Title</label>
                                </b-col>
                                <a title="Add New Resource Plan" class="action-link" href="javascript:" @click="openReqPop"ermMasterModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                            </b-input-group>                
                        </div>
                        <div class="row">
                            <b-input-group class="form-group mb-1">
                                <b-col class="align-middle float-left my-auto  mr-0" cols="3">Job Title</b-col>
                                <b-col cols="7" class="pr-1">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.resourcePlan.resourceTitle.$error }">
                                        <b-form-input id="resourceTitle" v-model="resourcePlan.resourceTitle" placeholder="Enter Job Title"></b-form-input>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.resourcePlan.resourceTitle.$error && !$v.resourcePlan.resourceTitle.required" class="text-danger">Please Enter Job Title</label>
                                </b-col>
                                <div class="float-right">
                                    <div class="more-btns">
                                        <button type="submit" title="Save" @click="saveResourcePlanData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                        <button type="submit" title="Clear" @click="clearResourcePlanData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </b-input-group>                
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <div class="float-right">
                <div class="dataTables_length">
                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="resourcePlans" :fields="resourcePlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                <template v-slot:cell(active)="data">
                    <b-form-checkbox class="ml-3 float-left" v-model="data.item.active" :disabled=notEnabled>
                    </b-form-checkbox>
                </template>
                <template v-slot:cell(actions)="data">
                    <a href="javascript:" title="Edit job Title" @click="showResourcePlanEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>                    
                    <a><i class="fa fa-trash pl-2" title="Delete" v-b-modal.removeHumanResource @click="removeHumanResource(data.item.id)" aria-hidden="true"></i></a>                    
                </template>
                <template v-slot:empty="scope">
                    <div class="text-center">No Types found</div>
                </template>
                <template v-slot:emptyfiltered="scope">
                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                </template>
            </b-table>
            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
            <b-pagination v-if="resourcePlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
        </div>
        </b-modal>
        <ERMMasterPopup v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData();" @closeDialog="showDialog=false" />
        <DeleteComponent id="removeHumanResource" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Resource Plan?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
export default {
    props: {
        entity: String,
        jobTitlePopupTitle: String
    },
    components: {
        ERMMasterPopup
    },
    data() {
        return {
            elements: {
                resourcePlanVisible: false,
                resourcePlanEdit: false
            },
            showDialog: false,
            notEnabled: true,
            refEntity:'resource_plan',

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),            
/*             entityRecordMaster: {
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                statusId: '',
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.entity,
                refEntityId: '',
                refEntity: this.refEntity,
                isFreeze: false,
                isArchive: false,
                isPublic: false,
            }, */
            resourcePlan: {
                resourcePlanId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                entity: '',
                plannedById: '',
                resourceTitle: '',
                resourceTypeId: '',
                type: '',
                resourceCount: '',
                startDate: new Date().toISOString().substr(0, 10),
                tillDate: '',
                workTypeId: '',
                workType: '',
                workFrequency: '',
                timeValue: '',
                timeUnit: '',
                periodDays: '',
                periodMonths: '',
                periodYears: '',
                roleResponsibility: '',
                technologySkill: '',
            },
            ermMasterInfo: {
                title: "Resource Plan",
                entity: this.entity,
                refEntity: this.refEntity,
            },
            

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            resourcePlans: [],
            resourcePlanFields: [
                { key: 'resourcePlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'resourcePlanTitle', label: 'Resource Plan', sortable: true, sortDirection: 'desc' },
                { key: 'jobTitle', label: 'Resource Title', sortable: true, sortDirection: 'desc' },
                // { key: 'startDate', label: 'Req. From' , formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            entityERM:[],         
            deleteParam: {
                id: 0
            }
        }
    },
    validations: {
        resourcePlan: {
            resourceTitle: {
                required,       
            },
            entityRecordMasterId:{
                required
            }
        }
    },
    watch: {
        'entity': {
            handler: function(value) {
                this.lookupData();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.lookupData();
        this.getResourcePlan(); 
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity
            }       
            axios.post(this.$store.getters.getAPIBasePath + '/common/erm/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityERM = result.entityERMLookup;
                })
                .catch(error=> console.log(error));
        },
        getResourcePlan: function() {
            this.resourcePlan.resourceTitle = '';
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result != null && result.length != 0) {
                        this.resourcePlans = result.resourcePlanList;                     
                        this.totalRows = this.resourcePlans.length;
                    }
                })
        },
        saveResourcePlanData: function() {
            this.$v.resourcePlan.$touch();        
            if (!this.$v.resourcePlan.$invalid) {
                if (this.elements.resourcePlanEdit) {
                    this.updateResourcePlan();
                } else {
                    this.addResourcePlan();
                }
            }
        },
        addResourcePlan: function() {
            this.resourcePlan.vhextAccountId = this.vhextAccountId;
            this.resourcePlan.projectId = this.projectId;
            this.resourcePlan.plannedById = this.userId;
 
            axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/add', this.resourcePlan)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getResourcePlan();
                        this.clearResourcePlanData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateResourcePlan: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/update', this.resourcePlan)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getResourcePlan();  
                        this.clearResourcePlanData();                
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearResourcePlanData: function() {
            this.resourcePlan.resourceTitle = '';
            this.$v.resourcePlan.$reset();
            this.elements.resourcePlanEdit = false;
        },
        showResourcePlanEdit: function(items) {
            let data = items;
            this.elements.resourcePlanVisible = true;
            this.elements.resourcePlanEdit = true;
            this.resourcePlan.id = data.id;
            this.resourcePlan.resourceTitle = data.resourceTitle;
            this.resourcePlan.entityRecordMasterId = data.entityRecordMasterId;
            this.resourcePlan.vhextAccountId = data.vhextAccountId;
            this.resourcePlan.projectId = data.projectId;
            this.resourcePlan.entityId = data.entityId;
            this.resourcePlan.resourcePlanId = data.resourcePlanId;
        },
        removeHumanResource: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/resourceplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getResourcePlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        openReqPop() {     
            this.showDialog = true;      
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            }) 
        },
    }
}
</script>