<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-8">
                            <h4 class="pl-2 mb-0 fs-16">Project Management Plan</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-12 col-xl-12">
                    <!-- <div class="card mb-4"> -->
                        <div class="card-body p-0">
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-processPlan >
                                        <span>Process Model</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-processPlan" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="processPlanMasterComp" :ermMasterInfo="processPlanMasterInfo" @onErmChange="onErmChange($event, 'processPlanDetailRef')" />
                                    <ProcessPlanDetailComp ref="processPlanDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-scopeStatement >
                                        <span>Project Scope</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-scopeStatement" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="scopeStatementMasterComp" :ermMasterInfo="scopeStatementMasterInfo" @onErmChange="onErmChange($event, 'scopeStatementDetailRef')" />
                                    <ProjectScopeDetailComp ref="scopeStatementDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-requirementSpec >
                                        <span>Project Requirement</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-requirementSpec" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="requirementSpecMasterComp" :ermMasterInfo="requirementSpecMasterInfo" @onErmChange="onErmChange($event, 'requirementSpecDetailRef')" />
                                    <RequirementSpecDetailComp ref="requirementSpecDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-resourcePlan >
                                        <span>Resource Estimation</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-resourcePlan" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="resourcePlanComp" :ermMasterInfo="resourcePlanMasterInfo" @onErmChange="onErmChange($event, 'resourcePlanDetailRef')" />
                                    <ResourceEstimationDetailComp ref="resourcePlanDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-effortEstimation >
                                        <span>Effort Estimation</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-effortEstimation" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="effortEstimationComp" :ermMasterInfo="effortEstimationMasterInfo" @onErmChange="onErmChange($event, 'effortEstimationDetailRef')" />
                                    <EffortEstimationDetailComp ref="effortEstimationDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-costEstimation >
                                        <span>Cost Estimation</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-costEstimation" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="costEstimationComp" :ermMasterInfo="costEstimationMasterInfo" @onErmChange="onErmChange($event, 'costEstimationDetailRef')" />
                                    <CostEstimationDetailComp ref="costEstimationDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-plan >
                                        <span>Project Schedule</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-plan" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="planMasterComp" :ermMasterInfo="planMasterInfo" @onErmChange="onErmChange($event, 'planDetailRef')" />
                                    <ProjectScheduleDetailComp ref="planDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-projectTeam >
                                        <span>Project Resource</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-projectTeam" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="projectTeamComp" :ermMasterInfo="projectTeamMasterInfo" @onErmChange="onErmChange($event, 'projectTeamDetailRef')" />
                                    <ProjectTeamDetailComp ref="projectTeamDetailRef" />
                                </b-collapse>
                            </b-card>
                            <b-card no-body class="mt-3">
                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-issue >
                                        <span>Project Issues</span>
                                    </label>
                                </b-card-header>
                                <b-collapse id="accordion-issue" accordion="my-accordion-main" class="p-1" role="tabpanel">
                                    <PmpMasterComponent id="issueComp" :ermMasterInfo="issueMasterInfo" @onErmChange="onErmChange($event, 'issueDetailRef')" />
                                    <IssueDetailComp ref="issueDetailRef" />
                                </b-collapse>
                            </b-card>
                        </div>

                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import CostEstimationDetailComp from './pmpcomponents/CostEstimationDetailComp.vue';
import EffortEstimationDetailComp from './pmpcomponents/EffortEstimationDetailComp.vue';
import ProcessPlanDetailComp from './pmpcomponents/ProcessPlanDetailComp.vue';
import RequirementSpecDetailComp from './pmpcomponents/RequirementSpecDetailComp.vue';
import ProjectScopeDetailComp from './pmpcomponents/ProjectScopeDetailComp.vue';
import ProjectScheduleDetailComp from './pmpcomponents/ProjectScheduleDetailComp.vue';
import ResourceEstimationDetailComp from './pmpcomponents/ResourceEstimationDetailComp.vue';
import ProjectTeamDetailComp from './pmpcomponents/ProjectTeamDetailComp.vue';
import IssueDetailComp from './pmpcomponents/IssueDetailComp.vue';
import PmpMasterComponent from './pmpcomponents/PmpMasterComponent.vue';
export default {
    components: {
        CostEstimationDetailComp,
        EffortEstimationDetailComp,
        ProcessPlanDetailComp,
        RequirementSpecDetailComp,
        ProjectScopeDetailComp,
        ProjectScheduleDetailComp,
        ResourceEstimationDetailComp,
        ProjectTeamDetailComp,
        IssueDetailComp,
        PmpMasterComponent,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            ermShareKey: 2000,
            ermReviewKey: 4000,
            ermApprovalKey: 6000,

            ermData: {
                ermTitle: '',
                entityId: '',
                entityRecordMasterId: '',
            },

            processPlanMasterInfo: {
                title: "Process Models",
                entity: 'process_model_master',
                refEntity: 'process_plan',
                tooltip: 'New Process Model',
                redirectURLName: 'processplan',
                newEntityMasterURL: 'pmdmas',
                redirectURL: '/pmdmas',
                actionName: 'Process Plan',
                previewURLName: 'pmdrep',
                hideProject: true,
            },

            scopeStatementMasterInfo: {
                title: "Scope Document List",
                entity: 'scope_statement_master',
                refEntity: 'scope_statement',
                tooltip: 'New Scope Document',
                redirectURLName: 'scopestatement',
                newEntityMasterURL: 'scopestatementmaster',
                redirectURL: '/scopestatementmaster',
                actionName: 'Scope Statement',
                previewURLName: 'sstrep',
                headerTitle: 'Doc Title',
                hideProject: true,
            },

            requirementSpecMasterInfo: {
                title: "Requirement Specification Documents",
                entity: 'system_requirement_spec_master',
                refEntity:'requirement_spec',
                tooltip: 'New Requirement Spec',
                redirectURLName: 'reqspc',
                newEntityMasterURL: 'srsmas',
                redirectURL: '/srsmas',
                actionName: 'Requirements',
                previewURLName: 'srsrep',
                sharedUrlName:'reqspc',
                headerTitle: 'Doc Title',
                hideProject: true,
            },

            planMasterInfo: {
                title: "Project Schedule",
                entity: 'plan_master',
                refEntity: 'plan',
                tooltip: 'New Project Schedule',
                redirectURLName: 'plan',
                newEntityMasterURL: 'plnmas',
                redirectURL: '/plnmas',
                actionName: 'Plan Item',
                previewURLName: 'plnrep',
                // masterLinkTitle: 'Link Requirement',
                // masterLinkEntity: 'system_requirement_spec_master',
                hideProject: true,
            },

            effortEstimationMasterInfo: {
                title: "Effort Esimation List",
                entity: 'effort_estimation_master',
                refEntity: 'effort_estimation',
                tooltip: 'New Effort Esimation',
                redirectURLName: 'effortestimation',
                newEntityMasterURL: 'esmaster',
                redirectURL: '/esmaster',
                actionName: 'Effort Esimation',
                previewURLName: 'eferep',
                hideProject: true,
            },

            costEstimationMasterInfo: {
                title: "Cost Esimation List",
                entity: 'cost_estimation_master',
                refEntity: 'cost_estimation',
                tooltip: 'New Cost Esimation',
                redirectURLName: 'costestimation',
                newEntityMasterURL: 'coemas',
                redirectURL: '/coemas',
                actionName: 'Cost Esimation',
                previewURLName: 'coerep',
                hideProject: true,
            },

            resourcePlanMasterInfo: {
                title: "Resource Estimations",
                entity: 'resource_plan_master',
                refEntity: 'resource_plan',
                tooltip: 'New Resource Estimation',
                redirectURLName: 'resourceplan',
                newEntityMasterURL: 'rplmas',
                redirectURL: '/rplmas',
                actionName: 'Items',
                previewURLName: 'rplrep',
                hideProject: true,
            },

            projectTeamMasterInfo: {
                title: "Project Teams",
                entity: 'project_team_master',
                refEntity: 'project_team',
                tooltip: 'New Project Team',
                redirectURLName: 'projectteam',
                newEntityMasterURL: 'prtmas',
                redirectURL: '/prtmas',
                actionName: 'Team Member',
                previewURLName: 'prtrep',
                hideProject: true,
            },

            issueMasterInfo: {
                title: "Issue Documents",
                entity: 'issue_master',
                refEntity: 'issue',
                tooltip: 'New Issue Doc',
                redirectURLName: 'issue',
                newEntityMasterURL: 'issmas',
                redirectURL: '/issmas',
                actionName: 'Issue',
                previewURLName: 'issrep',
                hideProject: true,
            },

            pmpComponents: [
                // { id: 101, name: 'Project Scope', component: 'ProjectScopeDetailComp', ref: 'scopeStatementDetailRef' },
                // { id: 102, name: 'Project Schedule', component: 'ProjectScheduleDetailComp', ref: 'planDetailRef' },
                // { id: 103, name: 'Timezone', component: 'Timezone' },
                // { id: 104, name: 'Industry', component: 'Industry' },
            ],

            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
    },
    methods: {
        onErmChange(ermId, ref) {
            if (ref == 'processPlanDetailRef') {
                this.$refs.processPlanDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'scopeStatementDetailRef') {
                this.$refs.scopeStatementDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'requirementSpecDetailRef') {
                this.$refs.requirementSpecDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'planDetailRef') {
                this.$refs.planDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'effortEstimationDetailRef') {
                this.$refs.effortEstimationDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'costEstimationDetailRef') {
                this.$refs.costEstimationDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'resourcePlanDetailRef') {
                this.$refs.resourcePlanDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'projectTeamDetailRef') {
                this.$refs.projectTeamDetailRef.getErdDetailList(ermId);
            }
            else if (ref == 'issueDetailRef') {
                this.$refs.issueDetailRef.getErdDetailList(ermId);
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>