<template>
    <div class="referenceDetailPopup">
        <b-modal id="emailDetailPopup" ref="emailDetailPopupRef" @hide="closeDialog" size="lg" modal-class="" title="Email">
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="container-fixed mx-3">
                        <div class="row mx-0">
                            <div class="col-8 text-left pl-0">
                                <div class="col-1 ml-0 pl-0 mt-1 float-left user-icon">
                                    <span class="pointer align-self-center user-name-icon float-left mr-1" variant="warning">{{ popupData.mailFromUser | getAcronym }}</span>
                                </div>
                                <ul class="mail-form-block pl-2 ml-2">
                                    <li><span class="fs-16">{{ popupData.mailFromUser }}</span> - <span class="subtle">{{ popupData.mailFrom }}</span> </li>
                                    <li class="subtle">{{ popupData.sentDate | formatDateTimeAmPm }}</li>
                                    <li>To: <span v-if="popupData.recipients" class="subtle">{{ popupData.recipients.replaceAll(',',', ') }}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="row card-mail-content mx-4">
                            <div class="col-12">
                                <span v-html="popupData.body"></span>
                                <div v-if="attachments && attachments.length>0">
                                    <div class="separator mt-0 mb-2"></div>
                                    <h6 class="mb-2">Attachments</h6>
                                    <div class="row ml-0">                                        
                                        <p v-for="attachment in attachments" class="col px-0">
                                            <a href="#" @click.prevent="" class="mx-0" :title="attachment.fileName" target="_blank">
                                                <div v-if="imageFormats(attachment.fileName)" :style="backgroundImage(attachment.attachmentPath)" class="card attachment-background">
                                                    <div class="show-btns-mail text-center">
                                                        <span class="col hover-btn-mail align-bottom"><i class="fa fa-lg fa-download download-icon-mail" aria-hidden="true"></i></span>
                                                    </div>
                                                </div>
                                                <div v-else class="attachment-background text-center">                                                
                                                    <i class="fa fa-5x fa-file pt-1 text-success text-center" aria-hidden="true"></i>
                                                </div>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: true,
            popupData: {},
            attachments: [],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            this.$bvModal.show('emailDetailPopup');
            console.log(id, refEntity);
            if (refEntity === "EMAIL") {
                axios.get(this.$store.getters.getAPIBasePath + '/email/mailbox/view/' + id)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.length != 0) {
                        this.popupData = result.mailbox;
                        this.getAttachment(this.popupData.sendMailId);
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.loading = false;
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
        backgroundImage(img) {
            return 'background: url(' + img + ') no-repeat center; background-size: cover;';
        },
        getFileExtension: function(name) {
            var re = /(?:\.([^.]+))?$/;

            return re.exec(name)[1];
        },
        getAttachment: function(sendMailId) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntity: 'send_mail',
                refRecordId: sendMailId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                })
        },
        imageFormats(content) {
            var contentExtension = this.getFileExtension(content);
            if ( contentExtension == 'png' || contentExtension == 'jpg' || contentExtension == 'jpeg' || contentExtension == 'tiff' || contentExtension == 'tif') {
                return true;
            }
            else return false;
        }
    }
    
}
</script>
<style scoped>
.email-time {
    float: right;
    font-weight: 400;
    color: grey;
    font-size: 0.9em;
    margin: 0 4px 0px auto;
}

/*.user-name-icon {
    background-color: rgb(120 55 194);
}*/
.icon-align {
    margin: 34% auto auto 46%;
    color: #5d5d87;
}
.quick-icons {
    display: none;
}

.card-media-mail:hover .quick-icons {
    display: inline-block;
}
.attachment-background {
    height: 80px;
    width: 130px;
    display: block;
    margin: 0 10px;
    align-content: center;
}

.attachment-background > div.show-btns-mail {
    display: none;
}
.attachment-background:hover div.show-btns-mail {
    border: 1px solid #878787;
    background-color: rgb(191 191 191 / 50%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.hover-btn-mail {
    padding: 5px;
    position: absolute;
    margin: 38% auto auto -50%;
}
.download-icon-mail {
    background: #ececec;
    border-radius: 5px;
    padding: 8px 5px 4px 5px;
    color: #424242;
}
.download-icon-mail:hover {
    background: #424242;
    color: #ececec;
}
div.show-btns-mail {
    display: none;
}
</style>