import CRPLanding from '@/views/landing/CommonLanding';
import CompanyManagement from '@/views/company/CompanyManagement';
import CorpEmployeeAttendance from '@/views/corporate/CorpEmployeeAttendance.vue'
import EmpTransfer from '@/views/corporate/EmpTransfer.vue'

export default [
    {
        path: '/crp/landing',
        name: 'crplanding',
        component: CRPLanding,
        meta: { layout: 'landing', accessedby: 'CRP|CRM' }
    },
    {
        path: '/landing/company',
        name: 'lancmp',
        component: CompanyManagement,
        meta: { layout: 'landing', accessedby: 'VSA|VHO|CRP|CRM|CAM' }
    },
    {
        path: '/landing/emptrn',
        name: 'lanemptrn',
        component: EmpTransfer,
        meta: { layout: 'landing', accessedby: 'CAM|CRP|CRM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/landing/crpatt',
        name: 'lancrpatt',
        component: CorpEmployeeAttendance,
        meta: { layout: 'landing', accessedby: 'CAM|CRP|CRM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
];