<template>
    <div>
        <CustomTypeLookupComponent pageTitle="Asset Type" pageEntity="asset_type" />
    </div>

</template>
<script>
import CustomTypeLookupComponent from '@/components/common/CustomTypeLookupComponent.vue';
export default {
    name: 'app',
    components: {
        CustomTypeLookupComponent 
    }
}
</script>