<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc :title="popupTitle" no-enforce-focus @hide="closeDialog"
            :id="id" ref="sendNotificationPopupRef" size="lg" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12 ">
                            <div class="row">
                                <div class="col-6 col-sm-6 pr-0">
                                    <div class="form-group required-field-block">
                                        <label for="toVhextAccountId">Company</label>
                                        <select v-model="notification.toVhextAccountId" class="form-control"  @change="handleSelection" >
                                            <option value="">Select</option>
                                            <option value="0">All Companies</option>
                                            <option v-for="(info, index) in corpCompanies" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6">
                                    <div class="form-group" >
                                        <label for="selection">Employee Group</label>
                                        <select v-model="notification.groupTypeId" class="form-control">
                                            <!-- <option value=""></option> -->
                                            <option v-for="(info, index) in employeeSelectionType" :value="info.typeLookupId" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="form-group" v-if="notification.groupTypeId == 2">
                                        <label for="employees">Employees</label>
                                        <multiselect
                                            v-model="notification.selectedEmployees "
                                            :options="employees"
                                            :multiple="true"
                                            track-by="id"
                                            label="value"
                                            placeholder="Select Employees"
                                            select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false"  :taggable="false"
                                            >
                                        </multiselect>
                                    </div>
                                </div>    
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <label for="subject">Subject</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.notification.title.$error }">                                   
                                            <input type="text" class="form-control" v-model="notification.title">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.notification.title.$error && !$v.notification.title.required" class="text-danger">Please Enter Subject</label> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.notification.description.$error }">                                        
                                        <!-- <div class="required-field-block"> -->
                                            <VmxEditor v-model="notification.description"></VmxEditor>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.notification.description.$error && !$v.notification.description.required" class="text-danger">Please Enter Notification Content</label> 
                                        <label v-if="$v.notification.description.$error && !$v.notification.description.maxLength" class="text-danger">Notification is too long: {{ notification.description.length }} characters (maximum 2000 allowed).</label> 

                                                                               
<!--                                         <div v-if="$v.notification.description.$error">
                                            <span v-if="!$v.notification.description.required" class="text-danger">Please
                                                enter Notification Content.</span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay v-if="selectedActionId == 1">
                    <div  class="text-center text-info">
                        <LoadingSpinner />
                        <p class="font-weight-bold fs-18 mb-0">
                            <i class="fa fa-lg fa-comments" aria-hidden="true"></i>
                            <label class="m-0 pl-2">Moving Notification To Draft. . .</label>
                        </p>
                    </div>
                </template>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <b-button v-if="action==0" class="float-left"  variant="outline-primary" :disabled="showOverlay" size="sm" 
                        @click="saveData(1)">
                        <span v-if="elements.editMode">Update Draft</span>
                        <span v-else>Save Draft</span>                    
                    </b-button>                    
                    <b-button v-if="action==1" class="float-left"  variant="outline-primary" :disabled="showOverlay" size="sm" 
                        @click="saveData(1)">
                        <span v-if="elements.editMode">Update Draft</span>
                        <span v-else>Save Draft</span>                    
                    </b-button>
                    <b-button class="float-right"  variant="outline-primary" size="sm" @click="clearData();close()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" :disabled="showOverlay" size="sm" @click="saveData(2)" class="float-right mr-1">Send Notification 
                    </b-button>

                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import VmxEditor from '@/components/usercontrols/VmxEditor';
export default {
    name: 'app',
    props: {
        id: { type: String, default: 'sendNotificationPopup' },
    },
    components: {
        Multiselect,
        VmxEditor,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },

            popupTitle: 'Send Notification',
            showContactLookup: false,
            selectedValue: 0,
            selectedItemId: null,
            selectedActionId: 0,
            action: 0,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            // notification.toVhextAccountId: localStorage.getItem("vhextAccountId"),
            userLookup: [],
            contacts: [],
            corpCompanies: [],
            employeeSelectionType:[],
            employees: [],
            notification: {
                parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                toVhextAccountId: 0,
                title: '',
                description: '',
                statusLookupId: '',
                selectedEmployees:[],
                createdById: localStorage.getItem("userId"),
            },
            
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            editorHeight: 400,
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    
    mounted() {
        this.lookupData();
    },
    validations: {
        notification: {
            // toList: {
            //     checkNull: function (toList) {
            //         return toList.length > 0
            //     }
            // },
            title: {
                required,
            },
            description: {
                required,
                maxLength: maxLength(2000), 
            },
        },
    }, 
    methods: {

        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                parentVhextAccountId: this.parentVhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notification/lookup/all', data)
                .then((response) => {
                    console.log(response);
                    let result = response.data;
                    console.log(result);
                    this.corpCompanies = result.corpGroupCompanyLookup;
                    this.employeeSelectionType = result.groupTypeLookup;                    ;
                    this.employees = result.corpGroupEmployeeLookup;
                })
        },
        saveData: function(statusLookupId) {

            this.$v.notification.$touch();
            console.log(this.elements.editMode);
            // alert(this.$v.notification.$invalid);
            if (!this.$v.notification.$invalid) {
                this.notification.statusLookupId = statusLookupId;
                if (this.elements.editMode) {
                    this.updateNotification(statusLookupId);
                } else {
                    this.saveNotification(statusLookupId);
                }
            }
        },
        saveNotification: function (statusLookupId) {
            this.notification.groupTypeId = this.selectedValue;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notification/add', this.notification).then(response => {
                this.showOverlay = false;
                let result = response.data;
                if (!response.status) {
                    iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                }
                iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                this.$nextTick(() => {
                    this.$emit('refresh-notification');
                    this.$bvModal.hide(this.id);
                })
            })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({ title: 'Error', message: 'Unable to send notification. Please try again', position: 'topRight' });
                });
            this.clearData();
        },

        updateNotification: function (statusLookupId) {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/notification/update', this.notification).then(response => {
                this.showOverlay = false;
                let result = response.data;
                if (!response.status) {
                    iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                }
                iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                this.$nextTick(() => {
                    this.$emit('refresh-notification');
                    this.$bvModal.hide(this.id);
                })
            })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({ title: 'Error', message: 'Unable to send notification. Please try again', position: 'topRight' });
                });
            this.clearData();
           
        },
        handleSelection() {
        },

        employeeSelection() {
            this.selectedItemId = this.notification.groupTypeId;
        },

        setNotificationData: function () {
            this.$nextTick(() => {
                console.log("reached from compose");
                this.$bvModal.show(this.id);
            })
        }, 

        setEditNotificationData: function (dataToEdit) {
            this.$nextTick(() => {
                this.elements.editMode = true;
                this.popupTitle = "Edit Notification";
                this.sendAndEditId = dataToEdit.id;
                this.notification.id = dataToEdit.id;
                this.notification.notificationId = dataToEdit.notificationId;
                this.notification.statusId = dataToEdit.statusId;
                this.notification.toVhextAccountId = dataToEdit.toVhextAccountId;
                this.notification.groupTypeId = dataToEdit.groupTypeId;
                this.notification.title = dataToEdit.title;
                this.notification.description = dataToEdit.description;
                
                this.action = 2;
                // this.edit =1;
                this.selectedValue = this.notification.groupTypeId;
                this.$bvModal.show(this.id);
            })
        },

        setEditDraftNotificationData: function (draftDataToEdit) {
            this.$nextTick(() => {
                this.elements.editMode = true;                
                this.sendAndEditId = draftDataToEdit.id;
                console.log(this.sendAndEditId);
                this.notification.id = draftDataToEdit.id;
                console.log(this.notification.id);
                this.notification.title = draftDataToEdit.title;
                this.notification.description = draftDataToEdit.description;
                this.popupTitle = "Edit and Send Notification";
                this.action = 1;
                this.notification.toVhextAccountId = draftDataToEdit.toVhextAccountId;
                this.notification.groupTypeId = draftDataToEdit.groupTypeId;
                this.selectedValue = draftDataToEdit.groupTypeId;
                this.$bvModal.show(this.id); 
            })
        },

        clearData: function () {
            this.elements.editMode = false;
            this.notification.id = '';
            this.notification.title = '';
            this.notification.description = '';
            this.notification.statusLookupId = '';
            this.notification.selectedEmployees = [];  // This should reset selected employees
            this.selectedItemId = null;
            this.selectedValue = 0;
            this.notification.toVhextAccountId = '';
            this.selectedActionId = 0;
            this.popupTitle = "Send Notification";
            this.action = 0;
            this.$v.notification.$reset();
        },

        closeDialog() {
            console.log("hai");
            this.clearData();
            this.$emit('closeDialog');
            this.$emit('refreshData');
        },
    }
}
</script>