<template>
    <div>
        <MeetingPreparationMasterList :ermMasterInfo="ermMasterInfo"></MeetingPreparationMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import MeetingPreparationMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        MeetingPreparationMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Meeting Preparation",
                entity: 'meeting_minute_master',
                refEntity: 'calendar_event',
                tooltip: 'New Meeting Preparation',
                redirectURLName: 'meetingpreparation',
                newEntityMasterURL: 'mmnmas',
                redirectURL: '/mmnmas',
                actionName: 'Meeting Preparation',
                previewURLName: 'mtprep'
                
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>