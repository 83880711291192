<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered title="Employee Attendance" ok-only @hide="closeDialog" id="empAttendanceTimelinePopup" ref="addTMBRef" size="xl" modal-class="">
            <EmpAttendanceTimelineComponent />
        </b-modal>
    </div>
</template>
<script>
import EmpAttendanceTimelineComponent from '@/components/common/EmpAttendanceTimelineComponent.vue';
export default {
    name: 'empAttendanceTimelinePopup',
    components: {
        EmpAttendanceTimelineComponent,
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog');
            this.$bvModal.hide('empAttendanceTimelinePopup');
        },
    }
}
</script>