<template>
    <div class="referenceDetailPopup">
        <b-modal id="processPlanDetailPopup" ref="processPlanDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Process Plan">
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">
                                    <!-- <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div> -->
                                    <div class="col-12 col-sm-12 mb-3 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Phase {{ popupData.phaseId }}</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.phaseTitle }}</label>
                                            </div>
                                            <div class="col-1 pr-0">
                                                <label class="mb-0 p-0 pt-2 pb-2 d-block title-block"><a title="Due Date"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></a></label>
                                            </div>
                                            <div class="col-3 pl-0 pr-0">
                                                <label class="p-2 mb-0 header-details font-weight-bold">{{ popupData.createdDt | formatDate}}</label>
                                            </div>
                                            <div class="col-2 float-right">
                                                <label class="mt-2 header-details font-weight-bold">{{popupData.status}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Process</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.process}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.activity}}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Deliverables</label>
                                            </div>
                                            <div class="col-10 pl-2 mt-2">
                                                <!-- <label class="p-2 mb-0 d-block header-details">{{popupData.deliverableData}}</label> -->
                                                <span v-for="(info, index) in popupData.deliverableData">                              
                                                    <span v-if="index != 0">, </span>
                                                    <label >{{ info.value }}</label>
                                                </span>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Entry</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.input"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Task</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.task"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Verification/Validation</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.validation"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="mb-2 mt-2 pl-2 header-details font-weight-bold"><span>Exit</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.output"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.preparedBy}}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.createdDt | formatDate}}</label>
                                            </div>                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            console.log(id, refEntity);
            if (refEntity === "process_plan") {
                this.$bvModal.show('processPlanDetailPopup');
                axios.get(this.$store.getters.getAPIBasePath + '/process/processplan/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        let popupData = result.processPlanView;
                        popupData.deliverableData = JSON.parse(popupData.deliverables);

                        this.popupData = popupData;
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                    console.log(this.popupData)
                });
                this.$bvModal.show('processPlanDetailPopup');
                this.loading = false;
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>