<template>
    <div class="activity">
        <div class="col-auto">
            <div class="form-group">
                <label for="references1" class="">References</label>
                <ul class="align-middle mt-auto px-0">
                    <li v-for="referenceList in refErdList" :title="referenceList.refErdTitle" class="badge b-form-tag d-inline-flex badge-secondary my-1">
                        <a href="#" class="text-white">
                            <span @click="viewReference(referenceList)" class="pointer">
                                {{ referenceList.refErdTitle }}
                            </span>
                        </a>
                        <button type="button" data-toggle="modal" data-target="#removeReference" @click="removeReference(referenceList.id)" title="Remove Reference" class="close b-form-tag-remove"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </li>
                    <li v-for="referenceList in addReference.referenceList" :title="referenceList.refErdTitle" class="badge b-form-tag d-inline-flex badge-secondary my-1">
                        <a href="#" class="text-white">
                            <span @click="viewReference(referenceList)" class="pointer">
                                {{ referenceList.refErdTitle }}
                            </span>
                        </a>
                        <button type="button" @click="removeRefFromArray(referenceList)" title="Remove Reference" class="close b-form-tag-remove"><i class="fa fa-times" aria-hidden="true"></i></button>
                    </li>
                    <li class="d-inline-flex ml-1">
                        <a class="float-right" href="javascript:" v-b-modal.model-scrollable.Functional-Reference-Popup><i class="fa fa-plus-circle pr-2" aria-hidden="true"></i>Add</a>
                    </li>
                </ul>
            </div>
        </div>
        <b-modal id="Functional-Reference-Popup" ref="referenceModal" size="lg" modal-class="referenceModal" title="Add References" ok-title="Ok">
            <div class="p-0">
                <b-card no-body class="">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-ref>
                            <span>Reference Search</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-ref" visible class="p-1" role="tabpanel">
                        <div class="row">
                            <div class="col-3">
                                <div class="form-group mb-0">
                                    <label for="referencesDd">Functionality Type</label>
                                    <select v-model="functionality" @change="getERMList(functionality);" class="form-control" id="referencesDd">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in ermFunctionalities" :value="info.id">{{info.value}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group mb-0">
                                    <label for="referencesDd">Doc. References</label>
                                    <select v-model="addReference.entityRecordMasterId" @change="getEntityReferenceList(addReference.entityRecordMasterId);" class="form-control" id="referencesDd">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in ermLookups" :value="info.id">{{info.value}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-1">
                            </div>
                            <div class="col-3 pr-0">
                                <div class="form-group mb-0">
                                    <label for="search">Search</label>
                                    <div class="input-group dataTables_filter">
                                        <input type="search" v-model="ReferenceFilter" class="form-control" id="search" placeholder="Search Reference">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2">
                            <b-table show-empty small stacked="md" sort-icon-left :items="erdList" :fields="erdListFields" responsive="sm" :current-page="currentPageRef" :per-page="perPageRef" :filter="ReferenceFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                <template v-slot:cell(title)="data">
                                    <a href="javascript:" class="action-link" @click="viewReference(data.item)">{{ data.value }}</a>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No References found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <div class="col-3 float-left pl-0 mb-2">
                                <div class="dataTables_length">
                                    <b-form-group label="Per page" label-cols-sm="6" label-align="right" label-size="sm" label-for="perPageSelectRef" class="pl-0 mb-0">
                                        <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="col-9 pull-right">
                                <b-pagination v-if="erdList.length!=0" pills v-model="currentPageRef" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                            </div>
                        </div>
                    </b-collapse>
                </b-card>
                <div v-if="refErdList.length !== 0" class="mt-3">
                    <label class="mb-1 fs-14">Added References</label>
                    <b-table show-empty small stacked="md" sort-icon-left :items="refErdList" :fields="refErdFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" data-toggle="modal" data-target="#removeReference"><i class="fa fa-minus-circle pr-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No References found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                </div>
                <div v-else class="mt-3">
                    <label class="mb-1 fs-14">Added References</label>
                    <b-table show-empty small stacked="md" sort-icon-left :items="addReference.referenceList" :fields="refErdFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeRefFromArray(data.item)"><i class="fa fa-minus-circle pr-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No References found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                </div>
            </div>
        </b-modal>
        <Dialog id="removeReference" :onYes="onYesRef" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove reference?" />
        <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" />
        <ReqSpecReference v-if="showDialog" ref="refReqSpecRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefObjective v-if="showDialog" ref="refObjectiveRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefTestCase v-if="showDialog" ref="refTestCaseRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefUserRequirement v-if="showDialog" ref="refUserRequirementRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <RefScopeStatement v-if="showDialog" ref="refScopeStatementRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <DocumentFolderReference v-if="showDialog" ref="refDocumentFolderRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <IssueReference v-if="showDialog" ref="refIssueRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
        <ErrorReportReference v-if="showDialog" ref="refErrorReportRef" :referenceDetailId="referencePopupId" :refEntity="refPopupEntity" @closeDialog="closeDialog"/>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import ReqSpecReference from '@/components/popup/reference/ReqSpecReference.vue';
import RefTestCase from '@/components/popup/reference/RefTestCase.vue';
import RefUserRequirement from '@/components/popup/reference/RefUserRequirement.vue';
import RefScopeStatement from '@/components/popup/reference/RefScopeStatement.vue';
import RefObjective from '@/components/popup/reference/RefObjective.vue';
import DocumentFolderReference from '@/components/popup/reference/DocumentFolderReference.vue';
import IssueReference from '@/components/popup/reference/IssueReference.vue';
import ErrorReportReference from '@/components/popup/reference/ErrorReportReference.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    components: {
        ReferenceDetailPopup,
        ReqSpecReference,
        RefTestCase,
        RefUserRequirement,
        RefScopeStatement,
        RefObjective,
        TaskDetailPopup,
        DocumentFolderReference,
        IssueReference,
        ErrorReportReference
    },
    props: {
        erdId: Number,
        ermId: Number,
        entity: String
    },
    data() {
        return {
            addReference: {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityId: '',
                entityRecordId: '',
                ermId: '',
                erdId: '',
                refEntityId: '',
                refErmId: '',
                refErdId: '',
                refErdTitle: '',
                title: '',
                refEntity: '',
                refEntityDispName: '',
                entityRecordMasterId: this.$route.params.ermId,
                referenceList: []
            },
            showDialog: false,

            functionality: '',
            referenceArray: [],
            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            ermFunctionalities: [],
            ermLookups: [],
            refErdList: [],
            erdList: [],
            ermLookupData: [],
            erdListFields: [
                { key: 'refErdId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'refEntityDispName', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            refErdFields: [
                { key: 'refErdId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'refErdTitle', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'refEntityDispName', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            ReferenceFilter: null,
            deleteParam: {
                id: 0
            }
        }
    },
    watch: {
        erdId(newId, oldId) {
            this.erdId = newId;
            if (newId == null || newId == undefined || newId == 0) {
                this.refErdList = [];
                this.addReference.referenceList = [];
            } else this.getERDReferenceList();
        },
        ermId(newId, oldId) {
            this.ermId = newId;
            if (newId == null || newId == undefined || newId == 0) {
                this.erdList = [];
            } else this.getEntityReferenceList(this.ermId);
        }
    },
    mounted() {
        this.getEntityReferenceList(this.ermId);
        this.getERDReferenceList();
        this.lookupData();
    },
    methods: {
        getERMList: function(entityId) {
            this.addReference.entityRecordMasterId = '';
            this.erdList = [];
            this.ermLookups = this.filterReference(this.ermLookupData, entityId);
        },

        getEntityReferenceList: function(ermId) {
            if (ermId) {
                let data = {
                    entityRecordMasterId: ermId
                }
                console.log(ermId);
                axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordset/list', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.erdList = result.entityRecordset;
                            this.totalRowsRef = this.erdList.length;
                        } else {
                            this.erdList = [];
                        }
                        console.log(result);
                    })
            }
            else this.erdList = [];
        },
        getERDReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                erdId: this.erdId,
            }
            if (data.erdId != null && data.erdId != 0 && data.erdId != undefined) {
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/common/erdreference/list', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.refErdList = result.erdReferenceList;
                            console.log(result);
                        }
                    })
            }
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        addRef: function(data) {
            console.log(data);
            if (this.erdId != 0 && this.erdId !== null && this.erdId != undefined) {
                if (!this.isItemExists(this.refErdList, 'refErdTitle', data.title)) {
                    this.addReference.vhextAccountId = this.vhextAccountId;
                    this.addReference.projectId = this.projectId;
                    this.addReference.entity = this.entity;
                    this.addReference.entityId = '';
                    this.addReference.entityRecordId = '';
                    this.addReference.ermId = this.$route.params.ermId;
                    this.addReference.erdId = this.erdId;
                    this.addReference.refEntity = data.entity;
                    this.addReference.refEntityDispName = data.refEntityDispName;
                    this.addReference.refEntityId = data.refEntityId;

                    this.addReference.refErmId = data.refErmId;
                    this.addReference.refErdId = data.refErdId;
                    this.addReference.refErdTitle = data.title;
                    this.addReference.status = data.status;
                    this.addReference.title = data.title;
                    this.addReference.addedById = this.userId;
                    this.saveRefData();
                }
                else {
                    iziToast.info({
                        message: 'Reference already exists',
                        position: 'topRight'
                    });
                }
            } else {
                if (!this.isItemExists(this.addReference.referenceList, 'refErdTitle', data.title)) {
                    this.addReference.referenceList.push({
                        id: this.addReference.referenceList.length + 1,
                        vhextAccountId: this.vhextAccountId,
                        projectId: this.projectId,
                        entity: this.entity,
                        entityId: '',
                        entityRecordId: '',
                        ermId: this.$route.params.ermId,
                        erdId: this.erdId,
                        refEntity: data.refEntity,
                        refEntityDispName: data.refEntityDispName,
                        refEntityId: data.refEntityId,

                        refErmId: data.refErmId,
                        refErdId: data.refErdId,
                        refErdTitle: data.title,
                        status: data.status,
                        title: data.title,
                        addedById: this.userId
                    });
                }
                else {
                    iziToast.info({
                        message: 'Reference already exists',
                        position: 'topRight'
                    });
                }
            }
            console.log(this.addReference.referenceList);
        },
        saveRefData: function() {
            this.addReference.entity = this.entity;
            console.log(this.addReference);
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Reference Added',
                            // message: result.message,
                            position: 'topRight'
                        });
                        this.addReference.referenceList = [];
                        this.getERDReferenceList();
                    }
                })
        },
        saveRefArray: function(erdId) {
            if (this.addReference.referenceList.length > 0) {
                this.addReference.erdId = erdId;
                this.addReference.entity = this.entity;
                console.log(this.addReference, this.addReference.erdId);
                axios.post(this.$store.getters.getAPIBasePath + '/common/referencearray/add', this.addReference)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            // iziToast.success({
                            //     title: 'Reference Added',
                            //     message: result.message,
                            //     position: 'topRight'
                            // });
                            this.addReference.referenceList = [];
                            this.getERDReferenceList();
                        }
                    })
            }
        },
        clearReference: function() {
            this.refErdList = [];
            this.addReference.referenceList = [];
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            // message: result.message,
                            position: 'topRight'
                        });
                        this.getERDReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        removeRefFromArray: function(data) {
            console.log(data);
            let index = '';
            index = this.addReference.referenceList.findIndex((e) => e == data);
            if (index > -1) {
                return this.addReference.referenceList.splice(index, 1);
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.ermFunctionalities = result.ermFunctionalityLookup;
                    this.ermLookups = result.entityRecordMasterLookup;
                    this.ermLookupData = result.entityRecordMasterLookup;
                    console.log(this.ermLookups);
                })
            console.log(this.vhextAccountId, this.projectId, this.erdId);
        },
        viewReference: function(data) {
            console.log(data);
            this.referencePopupId = data.refErdId;
            this.refPopupTitle = data.refErdTitle;
            this.refPopupEntity = data.refEntity;
            console.log(this.referencePopupId, this.refPopupEntity);
            this.showDialog = true;
            if (this.refPopupEntity == 'requirement_spec') {
                this.$nextTick(()=> {
                    this.$refs.refReqSpecRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'objective') {
                this.$nextTick(()=> {
                    this.$refs.refObjectiveRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'test_case') {
                this.$nextTick(()=> {
                    this.$refs.refTestCaseRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'user_requirement') {
                this.$nextTick(()=> {
                    this.$refs.refUserRequirementRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'scope_statement') {
                this.$nextTick(()=> {
                    this.$refs.refScopeStatementRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'document_folder') {
                this.$nextTick(()=> {
                    this.$refs.refDocumentFolderRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'issue') {
                this.$nextTick(()=> {
                    this.$refs.refIssueRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'error_report') {
                this.$nextTick(()=> {
                    this.$refs.refErrorReportRef.getDetails(this.referencePopupId, this.refPopupEntity);
                })
            }
            else if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$nextTick(()=> {
                    this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
                });
            }
            else {                
                this.$bvModal.show('referenceDetail')
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
                console.log(this.referencePopupId, this.refPopupEntity, data);
            }
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }
    }
}
</script>