<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Published Documents</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row mb-4">
                <div class="col-lg-12 col-xl-12">
                    <div class="card">
                        <div class="card-body p-3">
                            <template v-if="!showItemDetail">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <b-table ref="publishedDocumentTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="publishedDocs" :fields="publishedDocumentFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>

                                        <template v-slot:cell(actions)="data">
                                            <router-link title="Go to page" :to="{ name: getReportUrlName(data.item.ermEntity), params: { ermId: data.item.ermId }}"><i class="blue fa fa-eye p-1"></i></router-link>
                                            
<!--                                             <a href="javascript:" title="View" @click="viewItemDetails(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                        </template>
                                        <template v-slot:empty="">
                                            <div class="text-center">No Documents found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="">
                                            <div class="text-center">{{ }}</div>
                                        </template>
                                    </b-table>
                                    <div class="separator mb-2"></div>
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                
                                    <b-pagination v-if="publishedDocs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row">
                                    <div class="col">
                                        <label class="mb-0 pt-2">{{ itemDetailTitle }}</label>
                                    </div>
                                    <div class="col text-right">
                                        <b-button v-if="showItemDetail" class="btn sbtn btn-xs float-right px-2" variant="outline-danger" title="Close" @click="showItemDetail = false"><i class="fa fa-times pr-1"></i></i>Close</b-button>
                                    </div>
                                </div>
                                <div class="separator mt-1 mb-3"></div>
                                <component :ref="itemDetailCompRef" :is="activeComponent"></component>
                            </template>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import redirectMixin from "@/mixins/redirect";
import EmploymentChangeDetailComponent from '@/components/itemdetailcomponents/EmploymentChangeDetailComponent.vue';
import LeaveTimeDetailComponent from '@/components/itemdetailcomponents/LeaveTimeDetailComponent.vue';
export default {
    name: 'app',
    mixins: [redirectMixin],
    props: {
        ermMasterInfo: Object
    },
    components: {
        EmploymentChangeDetailComponent,
        LeaveTimeDetailComponent,
    },
    data() {
        return {
            elements: {
                visible: false,
                showDetail: false,
            },
            showItemDetail: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),    
            userId: localStorage.getItem("userId"),        
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            mainTableArray: [],
            referencePopupId: 0,
            activeComponent: '',
            itemDetailCompRef: '',

            reviewConfig: {
                id: '',
            },

            statusTrackerKey: 0,
            itemDetailTitle: '',
            attachmentData: {},

            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                refEntityId: '',
                refEntity: '',
                statusById: localStorage.getItem("userId"),
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
                publishedDocumentId: '',
                isWorkflowAutomated: true,
            },

            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},

            publishedDocs: [],

            publishedDocumentFields: [
                { key:'index',label: 'SN' },
                // { key: 'actionItemId', label: 'Id', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'documentType', label: 'Ducument Type', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'createdBy', label: 'Prepared By', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'documentPublishDt', label: 'Published Date',formatter: "formatDate", sortable: true, sortDirection: 'desc', class: '' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    mounted() {
        this.getPublishedDocs();
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        getPublishedDocs: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: localStorage.getItem("userId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/publisheddocument/list', data)
                .then((response) => {
                    let result = response.data;
                    this.publishedDocs = result.publishedDocumentList;
                    this.totalRows = this.publishedDocs.length;
                })

        },
        viewItemDetails(data) {
            this.itemDetailTitle = data.functionality;
            let referencePopupId = parseInt(data.refRecordId);
            this.attachmentData.refEntity = data.refEntity;
            this.attachmentData.refRecordId = data.id;
            this.showItemDetail = true;
            this.$nextTick(() => {
                if (data.refEntity == 'leave_time') {
                    this.activeComponent = 'LeaveTimeDetailComponent';
                    this.itemDetailCompRef = 'leaveTimeDetailCompRef';
                    this.$nextTick(() => {
                        this.$refs[this.itemDetailCompRef].getDetails(referencePopupId, data.refEntity);
                    });
                } else if (data.refEntity == 'employment_change') {
                    this.activeComponent = 'EmploymentChangeDetailComponent';
                    this.itemDetailCompRef = 'employmentChangeDetailCompRef';
                    this.$nextTick(() => {
                        this.$refs[this.itemDetailCompRef].getDetails(referencePopupId, data.refEntity);
                    });
                }
            })
        },
    }
}
</script>