<template>
    <div>
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.5" rounded="sm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Email </h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-xl-12 ">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label for="fromEmail">To</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.sendMail.toList.$error }">
                                                <multiselect v-model="sendMail.toList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="sendMail.group.value=='others'" @tag="addToTag" tag-placeholder="Add New Emails"></multiselect>
                                                <div class="required-icon"></div>
                                            </div>
                                            <div v-if="$v.sendMail.toList.$error">
                                                <span v-if="!$v.sendMail.toList.checkNull" class="text-danger">Please select To Address.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row ">
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="fromEmail">Cc</label>
                                            <multiselect v-model="sendMail.ccList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="sendMail.group.value=='others'" @tag="addCcTag" tag-placeholder="Add New Emails"></multiselect>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="fromEmail">Bcc</label>
                                            <multiselect v-model="sendMail.bccList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="sendMail.group.value=='others'" @tag="addBccTag" tag-placeholder="Add New Emails"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label for="fromEmail">Subject</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.sendMail.subject.$error }">
                                                <input type="text" class="form-control" v-model="sendMail.subject">
                                                <div class="required-icon"></div>
                                            </div>
                                            <div v-if="$v.sendMail.subject.$error">
                                                <span v-if="!$v.sendMail.subject.required" class="text-danger">Please enter Subject.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <!--   <div class="row">
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label for="fromEmail">Attachment</label>
                                            <label class="pl-2 action-link pointer">
                                                <i title="attach a file" class="fa fa-paperclip fa-lg" aria-hidden="true"></i>
                                                <input ref="add_attachment" id="attachments" type="file" accept=".doc,.docx,.pdf,.DOCX,image/*" class="file-upload" value="Upload File" @change="uploadFile">
                                            </label>
                                            <div>
                                                <span v-for="(info, index) in attachments">
                                                    {{info.name}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                 <AttachmentComponent :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                <div class="row">
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.sendMail.body.$error }">
                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" v-model="sendMail.body" :toolbar="toolbar" :init="initObj"></editor>
                                                <div class="required-icon"></div>
                                            </div>
                                            <div v-if="$v.sendMail.body.$error">
                                                <span v-if="!$v.sendMail.body.required" class="text-danger">Please enter Email Content.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row col-md-12 mx-0">
                                    <div class="icons col-md-1 offset-md-11 pull-right">
                                        <button type="submit" @click="handleSend" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center text-info">
                    <LoadingSpinner />
                    <p class="font-weight-bold fs-18 mb-0">
                        <i class="fa fa-lg fa-envelope" aria-hidden="true"></i>
                        <label class="m-0 pl-2">Sending Mail . . .</label>
                    </p>
                </div>
              </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
export default {
    name: 'app',
    components: {
        Editor,
        Multiselect,
        AttachmentComponent
    },
    data() {
        return {
            showOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: '',
            projectId: '',
            validEmailId: '',
            users: [],
            //attachments: [],
            sendMail: {
                group: { value: "others", label: "Others" },
                toList: [],
                ccList: [],
                bccList: [],
                vhextAccountId: '',
                projectId: '',
                recipients: '',
                copyRecipients: '',
                blindCopyRecipients: '',
                subject: '',
                body: '',
                attachments: [],
                entity: '',
                recipientIds: [],
                sendById: localStorage.getItem("userId"),
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            editorHeight: 400,
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            attachmentInfo:{
                title:'Attachment'
            },
            attachments: [],

        }
    },

    mounted() {
        this.initialSettings();
        this.lookupData();
    },
    validations: {
        sendMail: {
            toList: {
                checkNull: function(toList) {
                    return toList.length > 0
                }
            },
            subject: {
                required,
            },
            body: {
                required,
            }
        },
        validEmailId: {
            email,
        }
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.sendMail.projectId = this.projectId;
            this.sendMail.vhextAccountId = this.vhextAccountId;
            this.sendMail.entity = 'send_mail';
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/getuser', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                })
        },
        addToTag: function(newEmail) {
            if (this.isValidEmail(newEmail)) {
                this.sendMail.toList.push({ id: null, userName: newEmail, emailId: newEmail, userType: "none" });
                this.validEmailId = '';
                this.$v.validEmailId.$reset();
            }
        },
        addCcTag: function(newEmail) {
            if (this.isValidEmail(newEmail)) {
                this.sendMail.ccList.push({ id: null, userName: newEmail, emailId: newEmail, userType: "none" });
                this.validEmailId = '';
                this.$v.validEmailId.$reset();
            }
        },
        addBccTag: function(newEmail) {
            if (this.isValidEmail(newEmail)) {
                this.sendMail.bccList.push({ id: null, userName: newEmail, emailId: newEmail, userType: "none" });
                this.validEmailId = '';
                this.$v.validEmailId.$reset();
            }
        },
        handleSend: function() {

            console.log(this.sendMail);
            this.$v.sendMail.$touch();
            if (this.$v.sendMail.$invalid) {
                return;
            }

            let formData = new FormData();
            for (const row of Object.keys(this.attachments)) {    
                formData.append('files', this.attachments[row]);
            }

            this.sendMail.recipients = this.getCommaSeperatedString(this.sendMail.toList);
            this.sendMail.copyRecipients = this.getCommaSeperatedString(this.sendMail.ccList);
            this.sendMail.blindCopyRecipients = this.getCommaSeperatedString(this.sendMail.bccList);
            this.sendMail.email = this.sendMail.recipients;
            this.sendMail.ccEmail = this.sendMail.copyRecipients;
            this.sendMail.bccEmail = this.sendMail.blindCopyRecipients;
            this.sendMail.message = this.sendMail.body;

            this.sendMail.recipientIds = [];
            this.sendMail.toList.map(data => {
                this.sendMail.recipientIds.push(data.id);
            });
            this.sendMail.ccList.map(data => {
                this.sendMail.recipientIds.push(data.id);
            });
            this.sendMail.bccList.map(data => {
                this.sendMail.recipientIds.push(data.id);
            });
            console.log(this.sendMail);
       
            formData.append('recipients', this.getCommaSeperatedString(this.sendMail.toList));
            formData.append('copyRecipients', this.getCommaSeperatedString(this.sendMail.ccList));
            formData.append('blindCopyRecipients', this.getCommaSeperatedString(this.sendMail.bccList));
            formData.append('email', this.sendMail.recipients);
            formData.append('ccEmail', this.sendMail.copyRecipients);
            formData.append('bccEmail', this.sendMail.blindCopyRecipients);
            formData.append('message', this.sendMail.body);
            formData.append('recipientIds', this.sendMail.recipientIds);
            formData.append('projectId', this.projectId);
            formData.append('vhextAccountId', this.vhextAccountId);
            formData.append('body', this.sendMail.body);

            formData.append('subject', this.sendMail.subject);
            formData.append('entity', this.sendMail.entity);
            formData.append('sendById', this.sendMail.sendById);
            formData.append('group', this.sendMail.group);

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/email/sendmail/add', formData).then(response => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({ title: 'Error', message: 'Unable to send mail. Please try again', position: 'topRight' });
                });
        },
        uploadFile: function() {
            this.attachments.push(this.$refs.add_attachment.files[0]);
            console.log(this.attachments);
        },
        isValidEmail(newEmail) {
            let result = true;
            this.validEmailId = newEmail;
            this.$v.validEmailId.$touch();
            if (this.$v.validEmailId.$invalid) {
                iziToast.info({
                    message: 'Please enter a valid Email Id',
                    position: 'topCenter'
                });
                result = false;
            }
            return result;
        },
        clearData: function() {},

    }
}
</script>