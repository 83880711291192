<template>
    <div>
        <CustomCategoryLookupComp :entity="entity" :categoryLookupTitle="categoryLookupTitle"></CustomCategoryLookupComp>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import CustomCategoryLookupComp from '@/components/common/CustomCategoryLookupComp.vue'
export default {
    name: 'app',
    components: {
        CustomCategoryLookupComp,
    },
    data() {
        return {
            entity: 'financial_transaction_category',
            categoryLookupTitle: 'Financial Txn Category',
        }
    },
    mounted() {
    },
    methods: {}
}
</script>