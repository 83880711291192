<template>
    <div>
        <b-modal no-close-on-backdrop no-close-on-esc no-enforce-focus title="Daily Work Shift Management" size="lg" @hide="closeDialog" id="setWorkingDayPopup" ref="addCalEventRef" body-class="mb-2" ok-only>
			<b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
            	<b-container class="mb-3 p-0">
	                <b-row class="my-2">
                        <b-col sm="3" class="my-auto">
                            <label class="my-auto" for="dateWD">Work Shift Date</label>
                        </b-col>
                        <b-col sm="8" class="pr-0">
                            <input type="date" class="form-control" v-model="shiftDate" @keyup.enter="getWorkingDayData(shiftDate)" id="dateWD" >
                        </b-col>
                        <b-col sm="1">
                        	<button type="submit" @click="getWorkingDayData(shiftDate)" class="sbtn btn btn-primary btn-xs px-2 mr-1">Go</button>
                        </b-col>
                    </b-row>
	            </b-container>
	            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workingDays"  @row-selected="onRowSelected" :fields="workingDayFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(startTime)="data">
                        <div v-if="!data.item.id || (elements.editMode && data.item.workShiftId == editId)" class="d-flex justify-content-between">
                            <input type="time" class="form-control" v-model="data.item.startTime" id="startTime" @change="setTimeDiff(data.item, data.item.startTime, data.item.endTime)">

                            <input type="time" class="form-control" v-model="data.item.endTime" id="startTime" @change="setTimeDiff(data.item, data.item.startTime, data.item.endTime)">
                        </div>
                        <template v-else>
                            <template v-if="data.value">
                                {{ formatTimeFromHM(data.item.startTime) }} to {{ formatTimeFromHM(data.item.endTime) }}
                            </template>
                            <template v-else-if="data.item.wptStartTime">
                                {{ formatTimeFromHM(data.item.wptStartTime) }} to {{ formatTimeFromHM(data.item.wptEndTime) }}
                            </template>
                        </template>
                    </template>
                    <template v-slot:cell(workHour)="data">
                        <b-input-group v-if="!data.item.id || (elements.editMode && data.item.workShiftId == editId)" key="sm" size="sm">
                            <b-form-input type="number" v-model.number="data.item.workHour" min="0" @change=""></b-form-input>
                            <b-input-group-append is-text>hrs</b-input-group-append>
                            <b-form-input type="number" v-model.number="data.item.workMinute" min="0" @change=""></b-form-input>
                            <b-input-group-append is-text>mins</b-input-group-append>
                        </b-input-group>
                        <template v-else>
                            <template v-if="data.value">
                                {{ formatDurationHM(data.item.workHour, data.item.workMinute) }}
                            </template>
                            <template v-else>
                                {{ formatDurationHM(data.item.wptWorkHour, data.item.wptWorkMinute) }}
                            </template>
                        </template>
	                </template>
                    <template v-slot:cell(actions)="data">
                        <template v-if="elements.editMode && data.item.workShiftId == editId">
                            <a href="javascript:" title="Update" @click="saveWorkSchedule(data.item)"><i class="fa fa-check"></i></a>
                            <a href="javascript:" title="Cancel" @click="clearData"><i class="fa fa-times pl-2"></i></a>
                        </template>
                        <template v-else>
                            <button v-if="!data.item.id" type="submit" @click="startWorkSchedule(data.item)" class="sbtn btn btn-primary btn-xs px-2 mr-1">Start Work Shift</button>
                            <a v-if="data.item.id" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                        </template>
                        <!-- <a href="javascript:" title="Delete" @click="deleteWorkday(data.item.id)" v-b-modal.deleteWorkday><i class="fa fa-trash pl-2"></i></a> -->
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Work Schedule Configured for {{ formatDateAlt(workingDay.workingDate) }}</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
	        </b-overlay>
        </b-modal>
        <DeleteComponent id="deleteWorkday" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Work Schedule Setting ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'

export default {
    props: {
        workShiftId: [Number],
    },
    components: {
    	ERMMasterPopup,
    },
    data() {
        return {
        	showDialog: false,
        	elements: {
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            editId: "",

            shiftDate: this.getDateValue(new Date()),

            workingDay: {
				id: '',
				workingDayId: '',
				vhextAccountId: localStorage.getItem("vhextAccountId"),
				workShiftId: '',
                workPatternId: '',
				workingDate: this.getDateValue(new Date()),
				startTime: this.getTimeValue(new Date()),
				endTime: this.getTimeValue(new Date()),
				workHour: '',
				workMinute: '',
				active: true,
				createdById: localStorage.getItem("userId"),
				createdDt: '',
				lastUpdatedDt: '',
            },

            workingDayDetail: {
                id: '',
                workingDayId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                workShiftId: '',
                workPatternId: '',
                workingDate: this.getDateValue(new Date()),
                startTime: this.getTimeValue(new Date()),
                endTime: this.getTimeValue(new Date()),
                workHour: '',
                workMinute: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },

            workPatterns: [],

            workingDays: [],
            workingDayFields: [
                {key: 'index',label: 'SN', tdClass: 'align-middle' },
                // { key: 'workingDayId', label: 'Id', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Work Shift', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'startTime', label: 'Timing', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Duration', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', tdClass: 'align-middle', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions', tdClass: 'align-middle', } 
            ],

            deleteParam: {
                id: 0
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    mounted() {
        this.lookupData();
        // this.getWorkingDayData(new Date());
    },
    validations: {
        workingDay: {
            workShiftId: {
                required,
            },
            startTime: {
                required,
            },
            endTime: {
                required,
            },
        },
        workingDayDetail: {
            workShiftId: {
                required,
            },
            startTime: {
                required,
            },
            endTime: {
                required,
            },
        },
    },
    methods: {
    	getWorkingDayData: function(workingDate) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                workingDate: this.getDateValue(workingDate),
                workShiftId: this.workShiftId,

            }

            this.shiftDate = this.getDateValue(workingDate);
            this.workingDay.workingDate = this.getDateValue(workingDate);
            this.elements.visible = false;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/workingday/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.workingDays = result.workingDayList;
                    this.totalRows = this.workingDays.length;
                    console.log(this.workingDays);
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/workingday/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.workPatterns = result.workShiftLookup;
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        startWorkSchedule(data) {
            // this.elements.editMode = false;
            this.workingDay.workingDayId = data.workingDayId;
            this.workingDay.workShiftId = data.workShiftId;
            this.workingDay.workPatternId = data.workPatternId;
            // this.workingDay.workingDate = data.workingDate;
            this.workingDay.startTime = data.startTime;
            this.workingDay.endTime = data.endTime;
            this.workingDay.workHour = data.workHour;
            this.workingDay.workMinute = data.workMinute;
            this.workingDay.active = data.active;            
            this.saveData(this.workingDay);
        },
        saveWorkSchedule(data) {
            this.workingDayDetail.startTime = data.startTime;
            this.workingDayDetail.endTime = data.endTime;
            this.workingDayDetail.workHour = data.workHour;
            this.workingDayDetail.workMinute = data.workMinute;
            this.saveData(this.workingDayDetail);
        },
        saveData: function(workingDay) {
            workingDay.createdById = this.userId;
            if (!this.matchValueExists(workingDay.workingDate, workingDay.workShiftId, workingDay.id)) {
            	workingDay.startTime = workingDay.startTime ? this.changeTimeFormat(workingDay.startTime) : '';
                workingDay.endTime = workingDay.endTime ? this.changeTimeFormat(workingDay.endTime) : '';
                if (this.elements.editMode) {
                    this.$v.workingDayDetail.$touch();
                    if (this.$v.workingDayDetail.$invalid) {
                        iziToast.info({
                            message: 'Please fill the required fields',
                            position: 'topCenter'
                        });
                        return;
                    }
                    this.updateWorkingDay(workingDay);
                } else {
                    this.$v.workingDay.$touch();
                    if (this.$v.workingDay.$invalid) {
                        iziToast.info({
                            message: 'Please fill the required fields',
                            position: 'topCenter'
                        });
                        return;
                    }
                    this.saveWorkingDay(workingDay);
                }
            }
            else {
                iziToast.info({
                    message: 'Shift Info already exist.',
                    position: 'topCenter'
                });
            }
        },
        saveWorkingDay: function(workingDay) {
            console.log(workingDay);
        	this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/workingday/add', workingDay)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.getWorkingDayData(this.workingDay.workingDate);
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateWorkingDay: function(workingDay) {
            console.log(workingDay);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/workingday/update', workingDay)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.getWorkingDayData(this.workingDayDetail.workingDate);
                        this.clearData();
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteWorkday: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
        	this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/company/workingday/delete/' + this.deleteParam.id)
                .then(response => {
		        	this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.getWorkingDayData(this.workingDay.workingDate);
                    }
                })
                .catch(error => {
		        	this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getWorkPatternData: function(id) {
            this.showOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/finance/workpattern/view/' + id)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    let workPatternData = result.workPattern;
                    this.workingDay.startTime = workPatternData.startTime;
                    this.workingDay.endTime = workPatternData.endTime;
                    this.workingDay.workHour = workPatternData.workHour;
                    this.workingDay.workMinute = workPatternData.workMinute;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: error.message,
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        showEdit: function(data) {
        	this.elements.visible = true;
            this.elements.editMode = true;
            this.editId = data.workShiftId;
            this.workingDayDetail.id = data.id;
            this.workingDayDetail.title = data.title;
            this.workingDayDetail.workingDayId = data.workingDayId;
            this.workingDayDetail.workPatternId = data.workPatternId;
            this.workingDayDetail.workShiftId = data.workShiftId;
            this.workingDayDetail.workingDate = data.workingDate;
            this.workingDayDetail.startTime = data.startTime;
            this.workingDayDetail.endTime = data.endTime;
            this.workingDayDetail.workHour = data.workHour;
            this.workingDayDetail.workMinute = data.workMinute;
            this.workingDayDetail.active = data.active;
            this.workingDayDetail.createdById = data.createdById;
            // if (!data.id) {
            //     this.workingDayDetail.startTime = data.wptStartTime;
            //     this.workingDayDetail.endTime = data.wptEndTime;
            //     this.workingDayDetail.workHour = data.wptWorkHour;
            //     this.workingDayDetail.workMinute = data.wptWorkMinute;
            // }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.editMode = false;
                this.elements.visible = false;
                // this.workingDay.id = data.id;
                // this.workingDay.workingDayId = data.workingDayId;
                // this.workingDay.workPatternId = data.workPatternId;
                // this.workingDay.workingDate = data.workingDate;
                // this.workingDay.startTime = data.startTime;
                // this.workingDay.endTime = data.endTime;
                // this.workingDay.workHour = data.workHour;
                // this.workingDay.workMinute = data.workMinute;
                // this.workingDay.active = data.active;
                // this.workingDay.createdById = data.createdById;
            }
        },
        clearData: function() {
            this.editId = '';
            this.workingDay.id = '';
            this.workingDay.workingDayId = '';
            this.workingDay.workShiftId = '';
            this.workingDay.workPatternId = '';
            // this.workingDay.workingDate = this.getDateValue(new Date());
            this.workingDay.startTime = this.getTimeValue(new Date());
            this.workingDay.endTime = this.getTimeValue(new Date());
            this.workingDay.workHour = '';
            this.workingDay.workMinute = '';
            this.workingDay.active = true;
            this.workingDay.createdById = localStorage.getItem("userId");
            this.showOverlay = false;
            this.elements.editMode = false;
            this.elements.visible = false;
            this.$v.workingDay.$reset();
        },
        cancelEditMode() {
        	// this.setCalendarEventData();
        	this.elements.viewMode = true;
        	this.elements.editMode = false;
        },
        matchValueExists: function(date,workShiftId, id) {
            let result = false;
            this.workingDays.map(data => {
                if (date && data.workingDate && data.workShiftId && data.id != id) {
                    if (this.getDateValue(data.workingDate) == this.getDateValue(date) && data.workShiftId == workShiftId) {
                        result = true;
                    }
                }
            });
            return result;
        },
        setTimeDiff(item, start, end) {
            if (start && end) {
                const startDate = new Date('2000-01-01T' + start);
                const endDate = new Date('2000-01-01T' + end);

                let difference = this.getDateDifference(startDate, endDate);

                item.workHour = difference.hours;
                item.workMinute = difference.minutes;
            }
        },
        closeDialog() {
            this.clearData();
            this.workingDay.workingDate = this.getDateValue(new Date()); // to clear the date on close only
            this.shiftDate = this.getDateValue(new Date()); // to clear the date on close only
            this.$emit('closeDialog');
            this.$bvModal.hide('setWorkingDayPopup');
        },
    }
}
</script>