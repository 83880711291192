<template>
    <div class="category">
        <b-modal scrollable ref="categoryModal" size="md" centered id="categoryModal" modal-class="categoryModal" :title="categoryLookupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                            <span v-if="!elements.categoryEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Category</span>
                            <span v-if="elements.categoryEdit">Edit</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.categoryVisible" role="tabpanel">
                        <b-card-body class="p-2">
                            <div class="row">
                                <b-input-group class="form-group py-2">
                                    <div class="align-middle float-left my-auto ml-4 mr-0" cols="2">Category</div>
                                    <b-col cols="8" class="pr-1">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.customCategory.category.$error }">
                                            <b-form-input id="name" v-model="customCategory.category" placeholder="Enter Category"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.customCategory.category.$error && !$v.customCategory.category.required" class="text-danger">Please Enter Category</label>
                                    </b-col>
                                    <div class="float-right">
                                        <div class="more-btns">
                                            <button type="submit" title="Save" @click="saveCustomCategoryData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                            <button type="submit" title="Clear" @click="clearCategoryData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </b-input-group>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div class="row view-filter">
                    <div class="col-sm-12">
                        <div class="row input-group-label">
                            <div class="col-6">
                                <label class="mb-0 mt-2 fs-14">Categories</label>
                            </div>
                            <div class="col-6 dataTables_filter text-right">
                                <label>
                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                </label>
                            </div>
                        </div>
                    </div>
                </div>                
                <b-table show-empty small outlined head-variant=:primary sticky-header="30vh" :busy="isBusy" sort-icon-left :items="customCategories" :fields="customCategoryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>                    
                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <a href="javascript:" title="Edit Category" @click="showCategoryEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                        <a href="javascript:" title="Delete" @click="removeCustomCategory(data.item.id)" v-b-modal.removeCustomCategory><i class="fa fa-trash pl-2"></i></a>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Categories found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
<!--                 <b-pagination v-if="customCategories.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeCustomCategory" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Category ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        projectTypeId: [String, Number],
        entity: String,
        categoryLookupTitle: String
    },
    components: {

    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                categoryVisible: false,
                categoryEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            customCategory: {
                customCategoryLookupId: '',
                active: '',
                category: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
            },
            customCategories: [],
            customCategoryFields: [
                { key: 'index', label: 'SN', },
                // { key: 'customCategoryLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'category', label: 'Category', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        customCategory: {
            category: {
                required
            }
        }
    },
    mounted() {
        this.getCustomCategoryLookups();
    },
    methods: {
        getCustomCategoryLookups: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/list', data)
                .then((response) => {
                    let result = response.data;
                    this.customCategories = result.customCategoryLookupList;
                    this.totalRows = this.customCategories.length;
                })
        },
        saveCustomCategoryData: function() {
            this.$v.customCategory.$touch();
            if (!this.$v.customCategory.$invalid) {
                if (!this.matchTextValExists(this.customCategory.category, this.customCategory.id)) {
                    if (this.elements.categoryEdit) {
                        this.updateCustomCategoryLookup();
                    } else {
                        this.addCustomCategoryLookup();
                    }
                }else {
                    iziToast.info({
                        message: 'Category already exist.',
                        position: 'topCenter'
                    });
                }

            }
        },
        addCustomCategoryLookup: function() {
            this.customCategory.vhextAccountId = this.vhextAccountId;
            this.customCategory.projectId = this.projectId;
            this.customCategory.projectTypeId = this.projectTypeId;
            this.customCategory.entity = this.entity;
            console.log(this.customCategory);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/add', this.customCategory)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomCategoryLookups();
                        this.clearCategoryData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateCustomCategoryLookup: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/update', this.customCategory)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomCategoryLookups();
                        this.clearCategoryData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeCustomCategory: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomCategoryLookups();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.customCategory.id = item.id;
                this.customCategory.active = 0;
                this.customCategory.entity = 'custom_category_lookup';
                this.updateActivationState();
            } else {
                this.customCategory.id = item.id;
                this.customCategory.active = 1;
                this.customCategory.entity = 'custom_category_lookup';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.customCategory)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomCategoryLookups();
                        this.clearCategoryData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearCategoryData: function() {
            this.customCategory.id = '';
            this.customCategory.category = '';
            this.customCategory.customCategoryLookupId = '';
            this.customCategory.active = true;
            this.$v.customCategory.$reset();
            this.elements.categoryEdit = false;
        },
        showCategoryEdit: function(items) {
            let data = items;
            this.elements.categoryVisible = true;
            this.elements.categoryEdit = true;
            this.customCategory.customCategoryLookupId = data.customCategoryLookupId;
            this.customCategory.active = data.active;
            this.customCategory.category = data.category;
            this.customCategory.categoryName = data.categoryName;
            this.customCategory.vhextAccountId = data.vhextAccountId;
            this.customCategory.projectId = data.projectId;
            this.customCategory.projectTypeId = data.projectTypeId;
            this.customCategory.entityId = data.entityId;
            this.customCategory.id = data.id;
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.customCategories.map(data => {
                if (textVal && data.category && data.id !== id) {
                    if (data.category.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        

    }
}
</script>