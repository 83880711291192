<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-lg-4">
                            <h4 class="pl-2 mb-0 fs-16">Scope</h4>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Scope" :to="{ name: 'addscope'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Scope" :to="{ name: 'editscope', params: { scopeid: scopeId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="title">Title</label>
                                            <input type="text" class="form-control" id="title" disabled v-model="scope.title">
                                        </div>
                                    </div>
                                    <div class="col-12  col-sm-6">
                                        <div class="form-group">
                                            <label for="scopeTypeId">Scope Type</label>
                                            <input type="text" class="form-control" id="scopeTypeId" disabled v-model="scope.type">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-group">
                                            <label>Description</label>
                                            <textarea class="form-control" v-model="scope.description" disabled rows="5">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="deliverables">Deliverables</label>
                                            <input type="text" class="form-control" id="deliverables" disabled v-model="scope.deliverables">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="scopeId">Scope Id</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{scope.scopeId}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="status">Status</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{scope.status}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="preparedBy">Prepared By</label>
                                            <input type="text" class="form-control" id="preparedBy" disabled v-model="scope.userName">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div>
                                                <label for="attachment">Attachment </label>
                                                <p><i class="fa fa-file-word-o pr-2 icon-doc" aria-hidden="true"></i>AttachmentFileName.doc</p>
                                                <p><i class="fa fa-file-pdf-o pr-2 icon-pdf" aria-hidden="true"></i>AttachmentFileName.pdf</p>
                                                <p><i class="fa fa-file-excel-o pr-2 icon-xlsx" aria-hidden="true"></i>AttachmentFileName.xlsx</p>
                                                <p class="mb-0"><i class="fa fa-file-archive-o pr-2 icon-zip" aria-hidden="true"></i>AttachmentFileName.zip</p>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="col-6">
                                        <div class="form-group">
                                            <label for="createdDt">Created Date</label>
                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(scope.createdDt)">
                                        </div>
                                    </div>
                                     <div class="col-6">
                                        <div class="form-group">
                                            <label for="lastUpdatedDt">Updated Date</label>
                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(scope.lastUpdatedDt)">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        scopeId() {
            return Number(this.$route.params.scopeid);
        }
    },
    data() {
        return {
            scope: []
        }
    },
    mounted() {
        this.viewScope(this.scopeId);
    },
    methods: {
        viewScope: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/scope/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.scope = result.scope;
                    console.log(this.scope);

                })

        }
    }
}
</script>