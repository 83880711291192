<template>
    <div>
        <TSTMasterList :ermMasterInfo="ermMasterInfo"></TSTMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import TSTMasterList from '@/components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        TSTMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showMasterList: false
            },
            ermMasterInfo: {
                title: "Test List",
                entity: 'test_case_master',
                refEntity: 'test_case',
                tooltip: 'New Test Result',
                redirectURLName: 'test',
                newEntityMasterURL: '',
                redirectURL: '/tscmas',
                actionName: 'Testing',
                previewURLName: 'tsrrep',
                showTypeLookup:true,
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>