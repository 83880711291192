<template>
    <div class="meetingPrepartionDetailPopup">
        <b-modal id="meetingPrepartionDetailPopup" ref="meetingPrepartionDetailPopupRef" size="lg" @hide="closeDialog" ok-only modal-class="" hide-header>
            <button type="button" @click="$bvModal.hide('meetingPrepartionDetailPopup');" aria-label="Close" class="close position-absolute" style="right: 25px;">×</button>
            <b-tabs content-class="mt-4 pt-2 px-3">
                <b-tab>
                    <template #title>
                        <h5 class="p-0 m-0">Event / Meeting</h5>
                    </template>
                    <div v-if="loading" id="loader"></div>
                    <div v-if="!loading">
                        <div id="printMe" class="row">
                            <div class="row mb-auto w-100 mx-0">
                                <div class="col-12 px-0 text-left">
                                    <div class="card-body p-0 px-0 mx-0 mb-1">
                                        <div class="col-lg-12 col-xl-12">
                                            <template>
                                                <div class="row">
                                                    <div class="col-12 col-sm-6 mb-3 px-0">
                                                        <div class="row mb-0">
                                                            <div class="col-12 col-md-3 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-9 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-6 pl-1 pr-0">
                                                        <div class="row mt-0 mb-2">
                                                            <div class="col-12 col-md-3 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Host</label>
                                                            </div>
                                                            <div class="col-12 col-md-9 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.hostedBy }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-6 col-sm-6 px-0">
                                                        <div class="row mt-0 mb-2">
                                                            <div class="col-12 col-md-3 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-9 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.startRecur | formatDate }} to {{ popupData.endRecur | formatDate }}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <template v-if="popupData.isAllDayEvent">
                                                        <div class="col-6 col-sm-6 pr-1">
                                                            <div class="row mt-0 mb-2">
                                                                <div class="col-12">
                                                                    <div class="text-right">
                                                                        <h6 class=""><label class="badge badge-secondary" title="All Day Event">All Day</label></h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template v-else>
                                                        <div class="col-6 col-sm-6 pl-1 pr-0">
                                                            <div class="row mt-0 mb-2">
                                                                <div class="col-12 col-md-3 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Time</label>
                                                                </div>
                                                                <div class="col-12 col-md-9 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.startTime | formatTimeFromHM }} to {{ popupData.endTime | formatTimeFromHM }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 pl-0 pr-1">
                                                        <div class="row mb-0">
                                                            <div class="col-2 pr-0">
                                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Repeat</label>
                                                            </div>
                                                            <div class="col-10 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <span v-for="day,index in (popupData.daysOfWeek)"><span v-if="index!==0">,</span> {{ days[day] }}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 pl-0 pr-1">
                                                        <div class="row mt-0 mb-2">
                                                            <div class="col-6 col-md-2 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Participants</label>
                                                            </div>
                                                            <div class="col-6 col-md-10 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <span v-for="participant,index in eventParticipants"><span v-if="index!==0">,</span> {{ participant.value }}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 pl-0 pr-1">
                                                        <div class="row mt-0 mb-2">
                                                            <div class="col-6 col-md-2 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Recorders</label>
                                                            </div>
                                                            <div class="col-6 col-md-10 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <span v-for="recorder,index in momRecorders" :key="index"><span v-if="index!==0">,</span> {{ recorder.value }}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 pl-0 pr-1">
                                                        <div class="row mb-0">
                                                            <div class="col-6 col-md-2 pr-0">
                                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Location</label>
                                                            </div>
                                                            <div class="col-6 col-md-10 pl-0">
                                                                <label v-if="popupData.location" class="p-2 mb-0 d-block header-details">{{ popupData.location }}</label>
                                                                <label v-else class="p-2 mb-0 d-block header-details">-</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="popupData.link" class="row">
                                                    <div class="col-12 col-sm-12 pl-0 pr-1">
                                                        <div class="row mt-0 mb-2">
                                                            <div class="col-6 col-md-2 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Link</label>
                                                            </div>
                                                            <div class="col-6 col-md-10 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <a :href="popupData.link" target="_blank" class="action-link">{{ popupData.link }}</a>
                                                                    <!-- <span class="float-right d-inline-block my-auto">
                                                                        <a :href="popupData.link" target="_blank" class="action-link p-1 my-auto"><i class="fa fa-external-link"></i></a>
                                                                    </span> -->
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 px-0">
                                                        <div class="card mt-2 mb-3">
                                                            <div class="card-body py-1 px-0">
                                                                <p class="contact-title mb-2 ml-2"><span>Description</span></p>
                                                                <div class="row">
                                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab v-if="showMOM">
                    <template #title>
                        <h5 class="p-0 m-0">Agenda Items</h5>
                    </template>
                    <MeetingAgendaItem :momData="momData" ref="meetingAgendaItemRef" />
                </b-tab>
                <b-tab v-if="showMOM">
                    <template #title>
                        <h5 class="p-0 m-0">MOM</h5>
                    </template>
                    <MOMReportViewComponent :momData="momData" ref="meetingMinuteViewRef" />
                </b-tab>
            </b-tabs>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import dayList from '@/json/daysofweek.json';
import MOMReportViewComponent from '@/components/common/MOMReportViewComponent.vue';
import MeetingAgendaItem from '@/components/common/MeetingAgendaItemComponent.vue';
export default {
    components: {
        MOMReportViewComponent,
        MeetingAgendaItem,
    },
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            showMOM: true,
            popupData: {},
            eventParticipants: [],
            meetingMinutes: [],
            days: dayList,
            momData: {
                calendarEventId: '',
                entityRecordMasterId: '',
                meetingDate: '',
            }
        }
    },
    computed: {
        momRecorders() {
            return this.eventParticipants.filter(data => data.typeLookupId == 2);
        }
    },
    methods: {
        getDetails: function(id, refEntity, meetingDate) {
            this.loading = true;
            if (meetingDate == null) {
                this.showMOM = false;
            }
            if (refEntity === "meeting_preparation" || refEntity === "calendar_event") {
                let viewUrl;
                if (refEntity === "meeting_preparation") {
                    viewUrl = '/communication/calendarevent/preparation/view/';
                }
                else {
                    viewUrl = '/communication/calendarevent/view/';
                }
                this.$bvModal.show('meetingPrepartionDetailPopup');
                axios.get(this.$store.getters.getAPIBasePath + viewUrl + id)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    let calendarEvent = result.calendarEvent;
                    if (calendarEvent) {
                        this.popupData = calendarEvent;
                        this.popupData.startRecur = calendarEvent.start;
                        this.popupData.endRecur = calendarEvent.end;
                        this.popupData.isAllDayEvent = calendarEvent.allDay;
                        this.popupData.daysOfWeek = calendarEvent.daysOfWeek ? calendarEvent.daysOfWeek.split(',') : [];
                        this.popupData.startTime = calendarEvent.startTime ? calendarEvent.startTime : this.getTimeValue(this.popupData.startRecur);
                        this.popupData.endTime = calendarEvent.endTime ? calendarEvent.endTime : this.getTimeValue(this.popupData.endRecur);
                        this.momData.calendarEventId = this.popupData.id;
                        this.momData.entityRecordMasterId = this.popupData.entityRecordMasterId;
                        this.momData.meetingDate = meetingDate;
                        this.$nextTick(() => {
                            this.getParticipantList(this.popupData.id);
                            if (this.showMOM) {
                                this.$refs.meetingMinuteViewRef.getMeetingMinute();
                                this.$refs.meetingAgendaItemRef.getMeetingMinute();
                            }
                        });
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.$bvModal.show('meetingPrepartionDetailPopup');
                this.loading = false;
                this.$nextTick(() => {
                    this.getParticipantList(this.popupData.id);
                });
            }
        },
        getParticipantList: function(id) {
            let data = {
                eventId: id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.eventParticipants = result.eventParticipantLookup;
                })
        },
        // getMeetingMinute: function(calendarEventId, ermId, eventId) {
        //     if (eventId) {
        //         let data = {
        //             entityRecordMasterId: ermId,
        //             calendarEventId: calendarEventId,
        //             eventId: eventId,
        //         }
        //         axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/list', data)
        //             .then((response) => {
        //                 let result = response.data;
        //                 if (result.meetingMinuteList && result.meetingMinuteList.length > 0) {
        //                     this.meetingMinutes = result.meetingMinuteList;
        //                 }
        //             })
        //     }
        // },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>