<template>
    <div>
        <div v-for="timeSheet in timeSheets" class="card px-3 pt-2 pb-1 mb-2">
            <p class="mb-0">
                <label class="badge badge-info float-right" title="Status">{{ timeSheet.status }}</label>
                <span class="float-right mx-2">
                    <slot name="actions" :item="timeSheet">
                    </slot>
                </span>
                <span class="font-weight-bold">{{ timeSheet.title }}</span>
            </p>
            <div v-if="timeSheet.description" class="row mb-1">
                <div class="col-auto">
                    <span class="font-weight-bold">Description</span>
                </div>
                <div class="col-9">
                    <span v-html="timeSheet.description"></span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-4 col-lg-4">
                    <div class="row">
                        <div class="col-auto">
                            <span class="font-weight-bold">Start Time</span>
                        </div>
                        <div class="col-7">
                            <span v-if="timeSheet.startDate && timeSheet.startDate !== null">{{ timeSheet.startDate | formatDateTimeYearCheck }}</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-4">
                    <div class="row">
                        <div class="col-auto">
                            <span class="font-weight-bold">End Time</span>
                        </div>
                        <div class="col-7">
                            <span v-if="timeSheet.endDate && timeSheet.endDate !== null">{{ timeSheet.endDate | formatDateTimeYearCheck }}</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 col-lg-4">
                    <div class="row">
                        <div class="col-auto">
                            <span class="font-weight-bold">Actual Effort</span>
                        </div>
                        <div class="col">
                            <span v-if="timeSheet.workHour || timeSheet.workMinute || timeSheet.workDay">
                                {{ workDuration(timeSheet.workHour,timeSheet.workMinute,timeSheet.workDay) }}
                            </span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <span class="ml-3 mr-auto subtle">Status update by {{ timeSheet.preparedBy }}</span>
                <span class="ml-auto mr-3 subtle">Updated on {{ timeSheet.lastUpdatedDt | formatDateTimeAmPm }}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        timeSheets: Array,
    },
    data() {
        return {
        }
    },
    methods: {
        workDuration(workHour, workMinute, workDay) {
            if (workDay || workHour || workMinute) {
                return `${workDay ? workDay+' days' : ''} ${workHour ? workHour+' hrs' : ''} ${workMinute ? workMinute+' mins' : ''}`;
            }
            else return '';
        },
    }
}
</script>