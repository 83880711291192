<template>
	<div>
        <b-table show-empty small stacked="md" sort-icon-left :items="items" :fields="ermReferenceFields" responsive="sm" :sort-by.sync="sortByRef" :sort-desc.sync="sortDescRef" :sort-direction="sortDirectionRef" >
            <template v-slot:empty="scope">
                <div class="text-center">No Reference Found</div>
            </template>                                     
        </b-table>
	</div>
</template>
<script>
import iziToast from 'izitoast';
export default {
	props: {
        items: Array,
        review: Object
    },
	data() {
		return {
            sortByRef: '',
            sortDescRef: false,
            sortDirectionRef: 'asc',

            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirectionRef: 'desc' },
                { key: 'title', label: 'Title',  sortDirectionRef: 'desc' },
                { key: 'refEntity', label: 'Doc. Type', sortDirectionRef: 'desc' },
                { key: 'status', label: 'Status' }
            ],
		}
	},
    mounted() {
    },
	methods: {
	}
}
</script>