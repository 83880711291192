<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <PayrollMasterList :ermMasterInfo="ermMasterInfo" ></PayrollMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PayrollMasterList from '@/components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        PayrollMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showPayrollMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Payroll List",
                hideProject: true,
                hideClient: true,
                entity: 'payroll_master',
                refEntity: 'payroll',
                tooltip: 'New Payroll',
                redirectURLName: 'lanpayroll',
                newEntityMasterURL: 'prlmas',
                redirectURL: '/prlmas',
                actionName: 'Payroll Item',
                multiReports:[
                    {reportMenu:'Employee Payroll',reportURLName:'lanprlrep'},
                    {reportMenu:'Payroll Bank Transfer Report',reportURLName:'prlbtrrep'},
                ],
                showTypeLookup: true,
                headerTitle: 'Payroll Title',
                showPayrollAutoGen: true,
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
    },
    methods: {}
}
</script>