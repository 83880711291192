import VSALanding from '@/views/landing/CommonLanding';
import VSAVhext from '@/views/vhextaccount/VhextAccountManage.vue';
import VSADashboard from '@/views/dashboard/VSADashboard';
import AdminBlockMenu from '@/views/menu/AdminBlockMenu.vue';


import Activity from '@/views/process/Activity.vue'
// import Category from '@/views/settings/lookup/Category.vue'
import Country from '@/views/settings/country/Country.vue'
import Currency from '@/views/settings/Currency.vue'
import CustomCategoryLookup from '@/views/settings/lookup/CustomCategoryLookup.vue'
import CustomTypeLookup from '@/views/settings/lookup/CustomTypeLookup.vue'
import Deliverable from '@/views/process/Deliverable.vue'
import EmailAccSettings from '@/views/settings/email/EmailAccountSettings.vue'
import Email from '@/views/tools/Email.vue'
import Entity from '@/views/vmxadmin/primesetting/Entity.vue'
import HelpDesk from '@/views/help/HelpDesk.vue'
// import MenuManagement from '@/views/settings/MenuManagement.vue'
import Menu from '@/views/settings/Menu.vue'
import Priority from '@/views/settings/lookup/Priority.vue'
import Process from '@/views/process/Process.vue'
import ProjectSettings from '@/views/settings/ProjectSettings.vue'
import RoleResponsibility from '@/views/resource/RoleResponsibility.vue'
import Severity from '@/views/settings/lookup/Severity.vue'

import TechnologySkill from '@/views/resource/TechnologySkill.vue'
// import Type from '@/views/settings/lookup/Type.vue'
import UserActivityLog from '@/views/resource/UserActivityLog.vue'

import VMXAccountService from '@/views/vmxadmin/primesetting/VMXAccountService.vue'
import VhextAccountType from '@/views/vmxadmin/primesetting/VhextAccountType.vue'
import VMXCategoryLookup from '@/views/vmxadmin/lookup/VMXCategoryLookup.vue';
import VmxServiceManagement from '@/views/vmxadmin/primesetting/VmxServiceManagement.vue';
import VMXStatusLookup from '@/views/vmxadmin/lookup/VMXStatusLookup.vue'
import VMXTypeLookup from '@/views/vmxadmin/lookup/VMXTypeLookup.vue';

export default [
    {
        path: '/vsa/landing',
        name: 'vsalanding',
        component: VSALanding,
        meta: { layout: 'landing', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/landing/vhext',
        name: 'vsavhext',
        component: VSAVhext,
        meta: { layout: 'landing', accessedby: 'VSA|VHO' }
    }, 
    {
        path: '/vsa/landing/dashboard',
        name: 'vsadashboard',
        component: VSADashboard,
        meta: { layout: 'landing', accessedby: 'VSA|VHO' }
    }, 
    {
        path: '/landing/vsa/settings',
        name: 'lanvsasettings',
        component: AdminBlockMenu,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/activity',
        name: 'vsaactivity',
        component: Activity,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/country',
        name: 'vsacountry',
        component: Country,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/currency',
        name: 'vsacurrency',
        component: Currency,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/customcategorylookup',
        name: 'vsacustomcategorylookup',
        component: CustomCategoryLookup,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/customtypelookup',
        name: 'vsacustomtypelookup',
        component: CustomTypeLookup,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/deliverable',
        name: 'vsadeliverable',
        component: Deliverable,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/emaset',
        name: 'vsaemaset',
        component: EmailAccSettings,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/email',
        name: 'vsaemail',
        component: Email,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/landing/admset/entity',
        name: 'vsaentity',
        component: Entity,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/helpdesk',
        name: 'vsahelpdesk',
        component: HelpDesk,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    // {
    //     path: '/vsa/settings/mainmenu',
    //     name: 'vsamainmenu',
    //     component: MenuManagement,
    //     meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    // },
    {
        path: '/vsa/settings/menu',
        name: 'vsamenu',
        component: Menu,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/priority',
        name: 'vsapriority',
        component: Priority,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/process',
        name: 'vsaprocess',
        component: Process,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/projectsettings',
        name: 'vsaprjset',
        component: ProjectSettings,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/roleresponsibility',
        name: 'vsaroleresponsibility',
        component: RoleResponsibility,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/settings/severity',
        name: 'vsaseverity',
        component: Severity,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/technologyskill',
        name: 'vsatechnologyskill',
        component: TechnologySkill,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/vsa/useractivitylog',
        name: 'vsauseractivitylog',
        component: UserActivityLog,
        meta: { layout: 'admin-setting', accessedby: 'VSA|VHO' }
    },
    {
        path: '/landing/admset/vmxaccsrv',
        name: 'admsetvmxaccsrv',
        component: VMXAccountService,
        meta: { layout: 'admin-setting', accessedby: 'VSA' }
    },
    {
        path: '/landing/admset/vhatyp',
        name: 'admsetvhatyp',
        component: VhextAccountType,
        meta: { layout: 'admin-setting', accessedby: 'VSA' }
    },
    {
        path: '/landing/admset/category',
        name: 'vsacategory',
        component: VMXCategoryLookup,
        meta: { layout: 'admin-setting',accessedby: 'VSA' }
    },
    {
        path: '/landing/admset/vmxsrv',
        name: 'admsetvmxsrv',
        component: VmxServiceManagement,
        meta: { layout: 'admin-setting', accessedby: 'VSA' }
    },    
    {
        path: '/landing/admset/status',
        name: 'vsastatus',
        component: VMXStatusLookup,
        meta: { layout: 'admin-setting',accessedby: 'VSA|VHO' }
    },
    {
        path: '/landing/admset/type',
        name: 'vsatype',
        component: VMXTypeLookup,
        meta: { layout: 'admin-setting',accessedby: 'VSA' }
    },


];