<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Release List</span></h4>
                        </div>                        
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title pt-0" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                <span v-if="elements.viewMode">View</span> 
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="itemTitle">Item Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.releaseList.itemTitle.$error }">
                                                                    <input type="text" v-model="releaseList.itemTitle" class="form-control" id="itemTitle" placeholder="itemTitle">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                            <label v-if="$v.releaseList.itemTitle.$error && !$v.releaseList.itemTitle.required" class="text-danger">Please enter Item Title</label>
                                                        <label v-if="$v.releaseList.itemTitle.$error && !$v.releaseList.itemTitle.maxLength" class="text-danger">Item Title must not exceed {{$v.releaseList.itemTitle.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="version">Version</label>
                                                                <input type="number" class="form-control" id="version" v-model="releaseList.version">
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="releaseDt">Release Date</label>
                                                                <input type="Date" class="form-control" id="releaseDt" :value="formatDate(releaseList.releaseDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="itemDescription">Item Description</label>
                                                                <textarea v-model="releaseList.itemDescription" rows="4" class="form-control" id="itemDescription" maxlength="1000" placeholder="Enter Description"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewMeetingPlan">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-4">
                                                            <label for="itemTitle">Title</label>
                                                            <b-input-group :prepend="releaseList.releaseListId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="releaseList.itemTitle"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="version">Version</label>
                                                                <input type="text" class="form-control" id="version" disabled :v-model="releaseList.version">
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="releaseDt">Release Date</label>
                                                                <input type="Date" class="form-control" id="releaseDt" disabled :value="formatDate(releaseList.releaseDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="itemDescription">Description</label>
                                                                <textarea rows="4" class="form-control" id="itemDescription" disabled v-model="releaseList.itemDescription"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="createdDt">Created Date</label>
                                                                <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(releaseList.createdDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="lastUpdatedDt">Updated Date</label>
                                                                <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(releaseList.lastUpdatedDt)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="releaseLists" :fields="releaseListFields" selectable :select-mode="selectMode" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(itemTitle)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteReleaseList(data.item.id)" data-toggle="modal" data-target="#deleteReleaseList"><i class="fa fa-trash pl-2"></i></a>
                                </template>

                                <template v-slot:empty="scope">
                                    <div class="text-center">No MeetingPlan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="releaseLists.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ReleaseDetailPopup v-if="showDialog" ref="releaseDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <Dialog id="deleteReleaseList" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete closure plan?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ReleaseDetailPopup from '@/components/popup/itemdetail/ReleaseDetailPopup.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: 'app',
    components: {

        ReleaseDetailPopup,

    },
    data() {
        return {
            showDialog: false,
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            
            notEnabled: true,
            releaseList: {
                entity: 'release_List',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,               
                id: '',
                releaseListId: '',
                itemTitle: '',
                itemDescription: '',
                responsibilityId: '',
                version: '',
                releaseDt: '',
                releaseFormId: '',
                createdDt: '',
                lastUpdatedDt: ''              
            },

            detailViewData: {},
            referencePopupId: 0,

            transProps: {
                name: 'flip-list'
            },
            releaseLists: [],
            releaseListFields: [
                { key: 'releaseListId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'itemTitle', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'itemDescription', label: 'Itemt Description', sortable: true, sortDirection: 'desc' },
                { key: 'version', label: 'version', sortable: true, sortDirection: 'desc' },
                { key: 'releaseDt', label: 'Release Dt',  sortable: true, sortDirection: 'desc' },  
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getReleaseList();
        //this.lookupData();
    },
    validations: {
        releaseList: {
            itemTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
           
        }
    },
    methods: {
        getReleaseList: function() {
            let data = {
                entityRecordMasterId: this.releaseList.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/closure/releaselist/list', data)
                .then((response) => {
                    let result = response.data;
                    this.releaseLists = result.releaseListList;
                    this.totalRows = this.releaseLists.length;
                })
        },
       /*lookupData: function() {
            let data = {
                projectId: this.releaseList.projectId,
                vhextAccountId: this.releaseList.vhextAccountId,
                entity: this.releaseList.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/closure/releaselist/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.customTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                    this.status = result.statusLookup;
                    console.log(result);     
                })

        },*/
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            this.$v.releaseList.$touch();
            this.releaseList.startTime = this.releaseList.startTime+":00";
            this.releaseList.endTime = this.releaseList.endTime+":00";
            console.log(this.releaseList.startTime);
            if (!this.$v.releaseList.$invalid) {
                if (this.elements.editMode) {
                    this.updateReleaseList();
                } else {
                    this.saveReleaseList();
                }
            }
        },
        saveReleaseList: function() {
            this.showOverlay = true;
            console.log(this.releaseList);
            axios.post(this.$store.getters.getAPIBasePath + '/closure/releaselist/add', this.releaseList)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReleaseList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateReleaseList: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/closure/releaselist/update', this.releaseList)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReleaseList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteReleaseList: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/closure/releaselist/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReleaseList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.releaseDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },

        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.releaseList.releaseListId = '';
            this.releaseList.itemTitle = '';
            this.releaseList.itemDescription = '';
            this.releaseList.responsibilityId = '';
            this.releaseList.version = '';
            this.releaseList.releaseDt = '';
            this.releaseList.releaseFormId = '';
            this.releaseList.createdDt = '';
            this.releaseList.lastUpdatedDt = '';
            this.$v.releaseList.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.releaseList.releaseListId = data.releaseListId;
            this.releaseList.itemTitle = data.itemTitle;
            this.releaseList.itemDescription = data.itemDescription;
            this.releaseList.responsibilityId = data.responsibilityId;
            this.releaseList.version = data.version;
            this.releaseList.releaseDt = data.releaseDt;
            this.releaseList.releaseFormId = data.releaseFormId;
            this.releaseList.createdDt = data.createdDt;
            this.releaseList.lastUpdatedDt = data.lastUpdatedDt;
            this.releaseList.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.releaseList.releaseListId = data.releaseListId;
                this.releaseList.itemTitle = data.itemTitle;
                this.releaseList.itemDescription = data.itemDescription;
                this.releaseList.responsibilityId = data.responsibilityId;
                this.releaseList.version = data.version;
                this.releaseList.releaseDt = data.releaseDt;
                this.releaseList.releaseFormId = data.releaseFormId;
                this.releaseList.createdDt = data.createdDt;
                this.releaseList.lastUpdatedDt = data.lastUpdatedDt;
                console.log(this.releaseList);
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.showDetail = true;
            this.releaseList.releaseListId = data.releaseListId;
            this.releaseList.itemTitle = data.itemTitle;
            this.releaseList.itemDescription = data.itemDescription;
            this.releaseList.responsibilityId = data.responsibilityId;
            this.releaseList.version = data.version;
            this.releaseList.releaseDt = data.releaseDt;
            this.releaseList.releaseFormId = data.releaseFormId;
            this.releaseList.createdDt = data.createdDt;
            this.releaseList.lastUpdatedDt = data.lastUpdatedDt;
        }

    }
}
</script>