<template>
    <div>
        <div class="container-fluid mt-80">
            <div class="row">
                <div v-if="user.userType == 'PRM' " class="col-lg-6 col-xl-6">
                    <div class="card mb-4 h-100">
                        <div class="addnew-services-details">
                            <div class="card-body d-flex align-items-center justify-content-center h-250">
                                <div class="button-effect text-center ">
                                    <router-link class="effect effect-1" title="Project" :to="{ name: 'addproject'}"><i class="fa fa-plus-circle pr-2"></i>New Project</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="addnew-services-details hide">
                            <div class="addnew-services-close">
                                <a href="javascript:" @click="clearData"><i class="fa fa-times"></i></a>
                            </div>
                            <div class="card-body h-250 py-0">
                                <div class="row m-0 p-0">
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col-lg-8 col-sm-6">
                                                <h4 class="pl-2 mb-0 fs-16">{{ service.activeService }} Services</h4>
                                            </div>
                                        </div>
                                        <div class="separator mb-0"></div>
                                    </div>
                                </div>
                                <div class="row py-0 mb-0">
                                    <div class="col-lg-12 col-xl-12 pb-0 my-0">
                                        <div class="card-body p-3 pb-0">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group ">
                                                        <label for="title">Title</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.service.title.$error }">
                                                            <input type="text" class="form-control" v-model="service.title" id="title" :class="{ 'is-invalid': $v.service.title.$error }" placeholder="Enter Title">
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.service.title.$error && !$v.service.title.required" class="text-danger">Please enter Title</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group ">
                                                        <label for="serviceTypeId">Service Type</label>
                                                        <select v-model="service.projectTypeId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group ">
                                                        <label for="projectTypeId">Status</label>
                                                        <select v-model="service.statusId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in projectStatus" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group ">
                                                        <label for="description">Description</label>
                                                        <textarea class="form-control" v-model="service.description" rows="2" id="description" required></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="javascript:" @click="saveProject" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-2"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                                            <a href="javascript:" @click="clearData" class="btn btn-primary addnew-services-close mb-0 pt-1 pb-1 pl-3 pr-3 mt-2"><i class="fa fa-times pr-2"></i>Cancel</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pl-0 position-relative">
                    <div class="card mb-4 h-100">
                        <div class="card-body p-3 landing-tab">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Existing Project">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="projects" :fields="projectFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template v-slot:cell(title)="data">
                                                <a href="javascript:" title="View Project" @click="setSessionProjectId(data.item.id,data.item.projectId,data.item.displayName,data.item.currencySymbol,data.item.isStandard)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View Project" @click="setSessionProjectId(data.item.id,data.item.currencySymbol)"><i class="fa fa-eye"></i></a>
                                                <router-link title="Edit Project" :to="{ name: 'editproject', params: { projectid: data.item.id }}"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></router-link>
                                                <a href="javascript:" title="Delete Project" @click="deleteProject(data.item.id)" data-toggle="modal" data-target="#deleteProject"><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <span v-if="data.item.statusId == 1" class="badge badge-pill badge-secondary mb-1">{{data.value}}</span>
                                                <span v-else-if="data.item.statusId == 2" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                                <span v-else-if="data.item.statusId == 3" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                                <span v-else>{{data.value}}</span>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Projects found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                            <!--                                             <template v-slot:table-busy>
                                                <div class="text-center text-info my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>Loading...</strong>
                                                </div>
                                            </template> -->
                                        </b-table>
                                        <b-pagination v-if="projects.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </b-tab>
                                <b-tab title="History">
                                    <div class="data-table-rows data-tables-hide-filter">
                                        <table id="datatableRows" class="b-table data-table responsive nowrap w-100">
                                            <thead>
                                                <tr>
                                                    <th>Project Title</th>
                                                    <th>Created Date</th>
                                                    <th>Target Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 1</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">20-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 2</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">21-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 2</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">21-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 2</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">21-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 2</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">21-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 2</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">21-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 3</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">15-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">23-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-6 col-xl-6">
                    <div class="card mb-4 h-100 p-3">
                        <div class="row icon-cards-row1 mb-4 sortable landing-btns">
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" @click="service.entity='lead_manage_service';service.activeService = 'Manage Lead'" class="card addnew-services">
                                    <div class="card-body text-center">
                                        <i class="fa fa-clock-o"></i>
                                        <p class="card-text font-weight-semibold mb-0">Manage Lead</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" @click="service.entity='budget_finance_service';service.activeService = 'Budget Finance'" class="card addnew-services">
                                    <div class="card-body text-center">
                                        <i class="fa fa-shopping-basket"></i>
                                        <p class="card-text mb-0">Budget Finance</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" @click="service.entity='resource_manage_service';service.activeService = 'Manage Resource'" class="card addnew-services">
                                    <div class="card-body text-center">
                                        <i class="fa fa-refresh"></i>
                                        <p class="card-text mb-0">Manage Resource</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" class="card">
                                    <div class="card-body text-center">
                                        <i class="fa fa-envelope-o"></i>
                                        <p class="card-text mb-0">New Comments</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" class="card">
                                    <div class="card-body text-center">
                                        <i class="fa fa-envelope-o"></i>
                                        <p class="card-text mb-0">Refund Requests</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" class="card">
                                    <div class="card-body text-center">
                                        <i class="fa fa-envelope-o"></i>
                                        <p class="card-text mb-0">New Comments</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" class="card">
                                    <div class="card-body text-center">
                                        <i class="fa fa-envelope-o"></i>
                                        <p class="card-text mb-0">New Comments</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" class="card">
                                    <div class="card-body text-center">
                                        <i class="fa fa-envelope-o"></i>
                                        <p class="card-text mb-0">Refund Requests</p>
                                    </div>
                                </a>
                            </div>
                            <div class="col-sm-4 col-6 mb-4">
                                <a href="javascript:" class="card">
                                    <div class="card-body text-center">
                                        <i class="fa fa-envelope-o"></i>
                                        <p class="card-text mb-0">New Comments</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-6 pl-0">
                    <div class="card mb-4 h-100">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Open Items">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="services" :fields="serviceFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredService">
                                            <template v-slot:cell(title)="data">
                                                <a href="javascript:" title="View Budget Services" @click="setServiceSession(data.item.id,data.item.currencySymbol)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View Budget Services" @click="setServiceSession(data.item.id,data.item.currencySymbol)"><i class="fa fa-eye"></i></a>
                                                <router-link title="Edit Budget Services" :to="{ name: 'srvmasedit', params: { projectid: data.item.id }}"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></router-link>
                                                <a href="javascript:" title="Delete Budget Service" @click="deleteProject(data.item.id)" data-toggle="modal" data-target="#deleteProject"><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <span v-if="data.item.statusId == 1" class="badge badge-pill badge-secondary mb-1">{{data.value}}</span>
                                                <span v-else-if="data.item.statusId == 2" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                                <span v-else-if="data.item.statusId == 3" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                                <span v-else>{{data.value}}</span>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Budget Services found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                            <!--                                             <template v-slot:table-busy>
                                                <div class="text-center text-info my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>Loading...</strong>
                                                </div>
                                            </template> -->
                                        </b-table>
                                        <b-pagination v-if="services.length!=0" pills v-model="currentPage" :total-rows="totalRowsService" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </b-tab>
                                <b-tab title="History">
                                    <div class="data-table-rows data-tables-hide-filter">
                                        <table id="datatableRows" class="b-table data-table responsive nowrap w-100">
                                            <thead>
                                                <tr>
                                                    <th>Project Title</th>
                                                    <th>Created Date</th>
                                                    <th>Target Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 1</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">20-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="javascript:">Project 2</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">10-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">21-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="list-item-heading"><a href="">Project 3</a></p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">15-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p class="text-muted">23-08-2020</p>
                                                    </td>
                                                    <td>
                                                        <p><span class="badge badge-pill badge-success mb-1">Completed</span></p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteProject" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Project?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            isBusy: true,

            user: this.$store.getters.currentUser,
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),
            service: {
                activeService: '',
                title: '',
                description: '',
                projectTypeId: '',
                statusId: '1',
                isProject: 0,
                projectManagerId: localStorage.getItem("userId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: '',
                userTypeCode: localStorage.getItem("userTypeCode"),
            },
            projects: [],
            services: [],
            projectTypes: [],
            projectStatus: [],
            projectFields: [
                { key: 'projectId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            serviceFields: [
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Service Type', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            totalRowsService: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getProjects();
        this.lookupData();
        this.getDecodedData();
    },
    validations: {
        service: {
            title: {
                required
            }
        }
    },
    methods: {
        getProjects: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: this.userId,
                userTypeId: this.userTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projects = result.projectList;
                    this.services = result.serviceList;
                    console.log(result);
                    this.totalRows = this.projects.length;
                    this.totalRowsService = this.services.length;
                    this.isBusy = !this.isBusy
                }).catch(error => {
                    this.isBusy = !this.isBusy
                    console.log(error);
                });
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: 'budget_finance_service',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTypes = result.typeLookup;
                    this.projectStatus = result.statusLookup;
                })
        },
        saveProject: function() {
            console.log(this.service);
            this.$v.service.$touch();
            if (!this.$v.service.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/project/add', this.service)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$router.push('/srvmas/' + result.id);
                            this.clearData();
                            this.getProjects();
                        }
                    })
            }
        },
        clearData: function() {
            this.service.title = '';
            this.service.description = '';
            this.service.projectTypeId = '';
            this.service.statusId = '1';
            this.$v.service.$reset();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredService(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsService = filteredItems.length
            this.currentPage = 1
        },
        deleteProject: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/project/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjects();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        setSessionProjectId: function(projectId, projId, projectName, currency, isStandard) {
            localStorage.setItem('projectId', projectId);
            localStorage.setItem('projId', projId);
            localStorage.setItem('projectTypeId', projectTypeId);
            localStorage.setItem('projectName', projectName);
            localStorage.setItem('currency', currency);
            localStorage.setItem('isStandard', isStandard);
            if (this.user.userType == 'CLI'){
                this.$router.push('/client/docshr/list');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
            else {
                switch (this.user.userType) {
                    case "CAM":
                        this.$router.push('/user/actionitem');
                        break;
                    case "PRM":
                        this.$router.push('/prm/actionitem');
                        break;
                    case "CLI":
                        this.$router.push('/cli/actionitem');
                        break;
                    case "TMB":
                        this.$router.push('/tmb/actionitem');
                        break;
                    default:
                        this.$router.push('/actionitem');
                        break;
                }
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
        },
        setServiceSession: function(projectId, currency) {
            localStorage.setItem('projectId', projectId);
            localStorage.setItem('projectTypeId', projectTypeId);
            localStorage.setItem('currency', currency);
            this.$router.push('/srvmas/' + projectId);
        },
        getDecodedData: function() {
            if (localStorage.getItem("pageRedirectURL") != null && localStorage.getItem("pageRedirectURL") != '') {

                let url = localStorage.getItem("pageRedirectURL");
                console.log(url.substring(url.lastIndexOf('/') + 1));
                this.key = url.substring(url.lastIndexOf('/') + 1);
            }else{
                return;
            }

            let data = {
                key: String(this.key)
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result); 
                    localStorage.setItem('projectId', result.decodedData.projectId);
                    localStorage.setItem('projId', result.decodedData.projectId);
                    localStorage.setItem('projectName', result.decodedData.projectName);
                    localStorage.setItem('currency', result.decodedData.currency);
                    this.$router.push(localStorage.getItem("pageRedirectURL"));
                })
        },
    }
}
</script>