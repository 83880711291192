<template>
    <div :id="id" class="vmx-effort">
        <b-input-group>
            <b-input-group-prepend v-if="daysVisible" class="custom-input-group-prepend day-section"  v-bind:class="getClass()">        
                <b-form-input :id="daysId" type="number" :value="days" @input="daysHandleInput" :disabled="daysDisabled" :placeholder="daysPlaceholderText">
                </b-form-input>
            </b-input-group-prepend>
            <div v-if="hrsVisible" class="input-group-hrs hrs-section" v-bind:class="getClass()">        
                <b-form-input  :id="hrsId" type="number"  :value="hrs" @input="hrsHandleInput" :disabled="hrsDisabled" :placeholder="hrsPlaceholderText">
                </b-form-input>
            </div>
            <b-input-group-append v-if="minsVisible" class="custom-input-group-append mins-section" v-bind:class="getClass()">
                <b-form-input :id="minsId" type="number" :value="mins" @input="minsHandleInput" :disabled="minsDisabled" :placeholder="minsPlaceholderText">
                </b-form-input>
            </b-input-group-append>
        </b-input-group>        
    </div>
</template>
<script>
export default {
    name: 'EffortInput',
    props: {
        daysDisabled: {type: Boolean, default: false},
        hrsDisabled: {type: Boolean, default: false},
        minsDisabled: {type: Boolean, default: false},
        daysVisible: {type: Boolean, default: true},
        hrsVisible: {type: Boolean, default: true},
        minsVisible: {type: Boolean, default: true},
        daysValue: { Number },
        hrsValue: { Number },
        minsValue: { Number },
        id: { type: String, default: 'vms-effort-input' },
        daysId: { type: String, default: 'vms-day-input' },
        hrsId: { type: String, default: 'vms-hrs-input' },
        minsId: { type: String, default: 'vms-min-input' },
        daysPlaceholderText: { type: String, default: '' },
        hrsPlaceholderText: { type: String, default: '' },
        minsPlaceholderText: { type: String, default: '' }         
    },
    watch: {
        'daysValue': {
            handler: function(value) {
                this.days = value;
            },
            deep: true,
            immediate: true
        },
        'hrsValue': {
            handler: function(value) {
                this.hrs = value;
            },
            deep: true,
            immediate: true
        },
        'minsValue': {
            handler: function(value) {           
                this.mins = value;
            },
            deep: true,
            immediate: true
        },
    },
    computed: {},
    data() {
        return {
            projectId: localStorage.getItem("projectId"),
            days: '',
            hrs: '',
            mins: '',
        }
    },
    validations: {},
    mounted() {
        //this.setEffortInputData(this.daysValue, this.hrsValue, this.minsValue);
    },
    methods: {
        setEffortInputData:function(days, hrs, mins){
            console.log(days, hrs, mins);
            this.days = days;
            this.hrs = hrs;
            this.mins = mins;
        },
        clearEffortInputData:function(){
            this.days = '';
            this.hrs = '';
            this.mins = '';
        },
        daysHandleInput(e) {
            this.days = e;
            this.$emit('vmx-effort-input', {
                day:  this.days,
                hrs:  this.hrs,
                mins: this.mins
            })
        },
        hrsHandleInput(e) {
            this.hrs = e;
            this.$emit('vmx-effort-input', {
                day:  this.days,
                hrs:  this.hrs,
                mins: this.mins
            })
        },
        minsHandleInput(e) {
            this.mins = e;
            this.$emit('vmx-effort-input', {
                day:  this.days,
                hrs:  this.hrs,
                mins: this.mins
            })
        },
        getHrs:function(){
            return this.hrs;
        },
        setHrs:function(value){
            this.hrs = value;
        },
        getMins:function(){
            return this.mins;
        },
        setMins:function(value){
            this.mins = value;
        },
        getDays:function(){
            return this.days;
        },
        setDays:function(value){
            this.days = value;
        },
        getClass:function(){
            if(this.hrsVisible  && !this.minsVisible && this.daysVisible ){
                return 'vmx-day-hrs';
            }else if(!this.hrsVisible  && this.minsVisible && this.daysVisible  ){
                    return 'vmx-day-min';
            }else if(this.hrsVisible  && this.minsVisible && !this.daysVisible  ){
                    return 'vmx-hrs-min';
            }else if((!this.hrsVisible  && this.minsVisible && !this.daysVisible  ) || 
                    (!this.hrsVisible  && !this.minsVisible && this.daysVisible ) || 
                    (this.hrsVisible  && !this.minsVisible && !this.daysVisible ) ){
                    return 'vmx-one';
            }
        },
        effortInMins:function(){
            console.log(this.mins, this.hrs, this.days);
            let minutes = 0;
            if(this.mins != undefined && this.mins > 0){
                minutes = this.mins; 
            }
                if(this.hrs != undefined && this.hrs > 0){
                minutes = Number(minutes) + Math.floor(this.hrs * 60);
            }
            if(this.days != undefined && this.days > 0 ){
                minutes = Number(minutes) + (this.days * 24 * 60);
            }
            console.log(minutes + " Minutes");
            return minutes;
        },
        effortInHrs:function(){         
            console.log(this.mins, this.hrs, this.days);
            let min = 0;
            let hours = 0;
            if(this.mins != undefined && this.mins > 0){          
                hours = Math.floor(this.mins / 60);
                min = this.mins % 60; //remaining minutes
            }
            if(this.hrs != undefined && this.hrs > 0){
                hours = Number(hours) + Number(this.hrs);
            }
            if(this.days != undefined && this.days > 0 ){
                hours = Number(hours) + (this.days * 24);
            }
            console.log(hours + " Hours and " + min + " Minutes");
            return hours; 
        },
        effortInDays:function(){      
            let days = 0;
            let totalMinutes = this.effortInMins();
            days = Math.floor(totalMinutes / (60*24)); 
            console.log(days + " Days");
            return days;
        },
    },


}
</script>