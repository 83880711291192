<template>
	<div>
		<MainMenu :key="topMenuReloadKey" />
	    <main class="default-transition" style="opacity: 1;">
        </main>
        <div class="landing-page">
        	<div class="row no-gutters">
        		<div class="col-2">    		
    		        <div class="row">
    		            <div class="col-12 pb-1" v-for="setting in settingSubModules" >
    		                <a href="javascript:" :title="setting.displayName" class="settings-box make-it-slow bg-white" @click="handleSubModuleClick(setting);" :class="activeSubModule==setting.subModuleId ? 'active' : ''" >
    		                    <div class="row col-12 ml-0 left-bar-tab py-3">
    		                        <div class="col-2">
    		                            <i class="fa fa-lg icon-b" :class="setting.icon ? setting.icon : 'fa-cogs'" aria-hidden="true"></i>
    		                        </div>
    		                        <div class="col-8 text-left align-self-center">
    		                            <p class="m-0 font-weight-bold">{{ setting.subModule }}</p>
    		                        </div>
                                    <div class="col-2" v-if="activeSubModule==setting.subModuleId">
                                        <i class="fa fa-lg fa-hand-o-right text-primary" aria-hidden="true"></i>
                                    </div>                                
    		                    </div>
    		                </a>
    		            </div>
    		        </div>
    		    </div>
    		    <div class="col-10">
    			    <slot></slot>
    		    </div>
        	</div>
        </div>
        <RightMenu />
    </div>
</template>
<script>
import MainMenu from '@/layouts/blocks/Menu';
import RightMenu from '@/layouts/blocks/RightMenu';
import iziToast from 'izitoast';
import { EventBus } from '@/main';
export default {
    name: 'vsasettings',
    components: {
        MainMenu,
        RightMenu,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            projectId: localStorage.getItem("projectId"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),

            showOverlay: false,
            activeComponent: '',
            // showComponent: false,
            activeTab: 101,
            topMenuReloadKey: 0,

            settingSubModules: [],
            // settingSubMenu: [],
        }
    },
    computed: {
        activeSubModule: {
            get() {
                return this.$store.state.adminSetting.subModuleId;
            },
            set(value) {
                this.$store.state.adminSetting.subModuleId = value;
            }
        },
        activeSubModuleName: {
            get() {
                return this.$store.state.adminSetting.subModule;
            },
            set(value) {
                this.$store.state.adminSetting.subModule = value;
            }
        },
        showComponent: {
            get() {
                return this.$store.state.adminSetting.showComponent;
            },
            set(value) {
                this.$store.state.adminSetting.showComponent = value;
            }
        },
        // subMenuByModule() {
        //     return this.settingSubMenu.filter(menu => menu.subModuleId == this.activeModule);
        // }
    },
    created() {
        // EventBus.$on('onAdminSettingReload', () => {
        //     this.onAdminSettingReload()
        // });
        // EventBus.$on('onTopMenuReload', () => {
        //     this.onTopMenuReload();
        // });
    },
    mounted() {
        this.loadMenu();
    },
    beforeDestroy() {
        // EventBus.$off('onTopMenuReload');
        // EventBus.$off('onAdminSettingReload');
        this.activeSubModule = null;
        this.activeSubModuleName = null;
        this.showComponent = false;
    },
    methods: {
        loadMenu() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                projectId: this.projectId,
                vmxServiceCode: this.vmxServiceCode,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/adminsettingsubmodule', data)
                .then((response) => {
                    let result = response.data;
                    this.settingSubModules = result.customSubModuleLookup;
                    if (this.settingSubModules.length>0) {
                        this.activeSubModule = this.activeSubModule ? this.activeSubModule : this.settingSubModules[0].subModuleId;
                        this.activeSubModuleName = this.activeSubModuleName ? this.activeSubModuleName : this.settingSubModules[0].subModule;
                    }
                })
        },
        setActiveTab: function (setting) {
            this.showComponent = true;
            this.activeComponent = (setting.customSubMenu).replace(/\s/g, "");
            this.activeComponent = this.activeComponent.replaceAll('&', '');
            console.log(this.activeComponent)
            this.activeTab = setting.subModuleId;
        },
        onAdminSettingReload() {
            this.loadMenu();
        },
        handleSubModuleClick(subModuleData) {
        	this.showComponent = false;
        	this.activeSubModule = subModuleData.subModuleId;
            this.activeSubModuleName = subModuleData.subModule;
        	if (this.$route.path !== '/settings') {	
	        	this.$router.push('/settings');
        	}
        },
        // onTopMenuReload() {
        //     this.topMenuReloadKey++;
        // }

    }
}
</script>