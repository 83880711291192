<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-4 col-sm-4">
                                <h4 class="pl-2 mb-0 fs-16">
                                    <!-- {{ activeFilter == 1 ? 'Daily' : 'Monthly' }} -->
                                    Financial Transaction Summary
                                </h4>
                            </div>
                            <div class="col-lg-4 col-sm-4 text-center">
                                <b-button-group>
                                    <b-button v-for="item in reportFilter" :key="item.id" class="addnew-tassk-btn sbtn top-header-btn" variant="outline-primary" size="xs" :class="{'active' : activeFilter==item.id}" @click="changeFilterMode(item)" >{{ item.title }}</b-button>
                                </b-button-group>
                            </div>
                            <div class="col-lg-4 col-sm-4">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <b-button  class="addnew-tassk-btn sbtn float-right top-header-btn ml-2" variant="outline-primary" v-b-toggle.accordion-ett size="xs" :class="{'active' : elements.filterVisible}" :title="(elements.filterVisible ? 'Hide' : 'Show') + ' Filter'"><i class="fa fa-filter pr-2" aria-hidden="true"></i>{{ elements.filterVisible ? 'Hide' : 'Filter'}}</b-button>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click.prevent="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row m-0">
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>{{ activeFilter==1 ? 'From Date' : 'Month From' }}</label>
                                                    <input v-if="activeFilter==1" type="date" class="form-control" v-model="finTxnReport.fromDate" id="dateWD" >
                                                    <input v-else type="month" class="form-control" v-model="finTxnReport.fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>{{ activeFilter==1 ? 'Till Date' : 'Month Till' }}</label>
                                                    <input v-if="activeFilter==1" type="date" class="form-control" v-model="finTxnReport.tillDate">
                                                    <input v-else type="month" class="form-control" v-model="finTxnReport.tillDate">
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>Project</label>
                                                    <select v-model="finTxnReport.projectId" class="form-control" >
                                                        <option value="">All Projects</option>
                                                        <option v-for="(info, index) in projects" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>Client</label>
                                                    <select v-model="finTxnReport.clientId" class="form-control" >
                                                        <option value="">All Clients</option>
                                                        <option v-for="(info, index) in clients" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <button type="submit" @click="getReportList" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div class="col-12 col-md-3">
                                                            <div class="row">
                                                                <div class="col-6 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Type</label>
                                                                </div>
                                                                <div class="col-6 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ activeFilter==1 ? 'Date Wise Report' : 'Month Wise Report' }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="projectTitle" class="col">
                                                            <div class="row">
                                                                <div class="col-6 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Project</label>
                                                                </div>
                                                                <div class="col-6 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ projectTitle }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="clientName" class="col">
                                                            <div class="row">
                                                                <div class="col-6 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Client</label>
                                                                </div>
                                                                <div class="col-6 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ clientName }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-6 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-6 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-6 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-6 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title"><span>Financial Transaction Summary</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">
                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="financialTxnList" :fields="finTxnFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(transactionDt)="data">
                                                        <span v-if="activeFilter == 1">
                                                            {{ data.value | formatDate }}
                                                        </span>
                                                        <span v-else>
                                                            {{ data.value | formatMonthYear }}
                                                        </span>
                                                    </template>
                                                    <template v-slot:cell(totalIncome)="data">
                                                        {{ data.value ? data.value : '-' }}
                                                    </template>
                                                    <template v-slot:cell(totalExpense)="data">
                                                        {{ data.value ? data.value : '-' }}
                                                    </template>
                                                    <template v-slot:empty="erm">
                                                        <div class="text-center">No Record Found</div>
                                                    </template>
                                                    <template v-slot:foot()="data">
                                                        <span></span>
                                                    </template>
                                                    <template class="text-right" v-slot:foot(totalIncome)="data">
                                                        <div class="text-right font-weight-bold">
                                                            <span class="text-right" text-align=right>
                                                            Total Credited: {{totalIncomeAmount.toFixed(2)}}</span>
                                                        </div>
                                                    </template>
                                                    <template class="text-right" v-slot:foot(totalExpense)="data">
                                                        <div class="text-right font-weight-bold">
                                                            <span class="text-right" text-align=right>Total Debited: {{totalExpenseAmount.toFixed(2)}}</span>
                                                        </div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            finTxnReport: {
                projectId: '',
                clientId: '',
                fromDate: this.getDateValue(new Date()),
                tillDate: this.getDateValue(new Date()),
            },

            finTxnReportDetail: {
                projectId: '',
                clientId: '',
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
            },

            reportFilter: [
                {id: 1, title: 'By Date'},
                {id: 2, title: 'By Month'}
            ],
            activeFilter: 1,

            projects: [],
            clients: [],

            finTxnReportList: [],
            finTxnFields: [
                { key: 'index', label: 'SN' },
                { key: 'transactionDt', label: 'Transaction Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'totalIncome', label: 'Credited(Cr)', thClass: 'text-right', tdClass: 'text-right', sortable: true, sortDirection: 'desc' },
                { key: 'totalExpense', label: 'Debited(Dr)', thClass: 'text-right', tdClass: 'text-right', sortable: true, sortDirection: 'desc' },
                { key: 'cumulativeProfit', label: 'Closing Balance', thClass: 'text-right', tdClass: 'text-right', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        totalAmount() {
            return this.financialTxnList.reduce((accum, item) => {
                return accum + parseFloat(item.amount ? item.amount : 0)
            }, 0.00)
        },
        totalIncomeAmount() {
            return this.financialTxnList.reduce((accum, item) => {
                return accum + parseFloat(item.totalIncome ? item.totalIncome : 0)
            }, 0.00)
        },
        totalExpenseAmount() {
            return this.financialTxnList.reduce((accum, item) => {
                return accum + parseFloat(item.totalExpense ? item.totalExpense : 0)
            }, 0.00)
        },
        projectTitle() {
            if (this.projects.length>0) {
                return this.finTxnReportDetail.projectId ? this.projects.find(data => data.id == this.finTxnReportDetail.projectId).value : '';
            }
        },
        clientName() {
            if (this.clients.length>0) {
                return this.finTxnReportDetail.clientId ? this.clients.find(data => data.id == this.finTxnReportDetail.clientId).value : '';
            }
        },
        financialTxnList() {
            if (this.finTxnReportList.length>0) {
                if (this.activeFilter == 1) {
                    return this.finTxnReportList.filter((data) => (this.getDateValue(data.transactionDt) >= this.getDateValue(this.finTxnReportDetail.startDate) && this.getDateValue(data.transactionDt) <= this.getDateValue(this.finTxnReportDetail.endDate)));
                } else {
                    return this.finTxnReportList.filter((data) => (this.getDateValue(data.transactionDt) >= this.getDateValue(this.finTxnReportDetail.startDate + "-01") && this.getDateValue(data.transactionDt) <= this.getLastDate(this.finTxnReportDetail.endDate + "-11")));
                }
            } else {
                return [];
            }
        },
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.getReportList();
        this.lookupData();
    },
    methods: {
        getReportList: function() {
            if (this.activeFilter == 1) {
                this.getDailyTxns();
            } else {
                this.getMonthlyTxns();
            }
        },
        getDailyTxns: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.finTxnReport.projectId,
                clientId: this.finTxnReport.clientId,
            }
            this.finTxnReportDetail.startDate = this.finTxnReport.fromDate;
            this.finTxnReportDetail.endDate = this.finTxnReport.tillDate;
            this.finTxnReportDetail.projectId = this.finTxnReport.projectId;
            this.finTxnReportDetail.clientId = this.finTxnReport.clientId;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnreport/summarybydate', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.finTxnReportList = result.finTxnSummaryReport;
                    this.totalRows = this.financialTxnList.length;

                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getMonthlyTxns: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.finTxnReport.projectId,
                clientId: this.finTxnReport.clientId,
            }
            this.finTxnReportDetail.startDate = this.finTxnReport.fromDate;
            this.finTxnReportDetail.endDate = this.finTxnReport.tillDate;
            this.finTxnReportDetail.projectId = this.finTxnReport.projectId;
            this.finTxnReportDetail.clientId = this.finTxnReport.clientId;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnreport/summarybymonth', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.finTxnReportList = result.finTxnSummaryReport;
                    this.totalRows = this.financialTxnList.length;
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnreport/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projects = result.projectLookup;
                    this.clients = result.clientLookup;
                })
        },
        getLastDate(value) {
            var date = new Date(value);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return this.getDateValue(lastDay);
        },
        getFormatByFilter(value) {
            if (this.activeFilter == 1) {
                return this.getDateValue(value);
            } else {
                return this.getMonthYear(value);
            }
        },
        changeFilterMode(item) {
            this.activeFilter = item.id;
            this.clearData();
            this.getReportList();
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.finTxnReport.fromDate = this.getFormatByFilter(this.finTxnReportDetail.startDate);
            this.finTxnReport.tillDate = this.getFormatByFilter(this.finTxnReportDetail.endDate);
            this.finTxnReport.projectId = this.finTxnReportDetail.projectId;
            this.finTxnReport.clientId = this.finTxnReportDetail.clientId;
        },
        clearData: function() {    
            this.finTxnReportDetail.startDate = this.getFormatByFilter(new Date());
            this.finTxnReportDetail.endDate = this.getFormatByFilter(new Date());
            this.finTxnReportDetail.projectId = '';
            this.finTxnReportDetail.clientId = '';
            this.finTxnReport.fromDate = this.getFormatByFilter(new Date());
            this.finTxnReport.tillDate = this.getFormatByFilter(new Date());
            this.finTxnReport.projectId = '';
            this.finTxnReport.clientId = '';
        },
        setAmountTdClass(value) {
            var amountType = value;
            if(amountType === 'CR')
                return 'green';
            else if(amountType === 'DR')
                return 'red';
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>