<template>
    <div>
        <nav class="navbar fixed-top">
            <div class="d-flex align-items-center navbar-left pl-3">
                <a href="javascript:" class="menu-button d-none d-md-block">
                    <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
                        <rect x="0.48" y="0.5" width="7" height="1" />
                        <rect x="0.48" y="7.5" width="7" height="1" />
                        <rect x="0.48" y="15.5" width="7" height="1" />
                    </svg>
                    <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
                        <rect x="1.56" y="0.5" width="16" height="1" />
                        <rect x="1.56" y="7.5" width="16" height="1" />
                        <rect x="1.56" y="15.5" width="16" height="1" />
                    </svg>
                </a>
                <a href="javascript:" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                        <rect x="0.5" y="0.5" width="25" height="1" />
                        <rect x="0.5" y="7.5" width="25" height="1" />
                        <rect x="0.5" y="15.5" width="25" height="1" />
                    </svg>
                </a>
                <a class="navbar-logo" href="javascript:">
                    <!-- <span class="d-none d-xs-block">vhextech</span>
                    <span class="logo-mobile d-block d-xs-none">vhextech - </span> -->
                    <div v-if="logoImage != 'null' && logoImage.length > 0">
                        <img alt="Profile Picture" class="Companylogo-img" :src="logoImage" />
                    </div>
                    <div v-else>
                        <img alt="Profile Picture" class="Companylogo-img" src="@/assets/images/logo.png" />
                    </div>
                </a>
            </div>
            <div class="navbar-right">
                <div v-if="elements.showMenu" class="header-icons d-inline-block align-middle mr-2">
                    <div class="position-relative d-inline-block">
                        <ol class="breadcrumb pt-0 mb-0">
                            <li class="breadcrumb-item ">
                                <a href="javascript:" title="Landing page" class="pointer action-link" @click="redirectLandingPage()">
                                    <i class="fa fa-home fs-18"></i>
                                </a>
                            </li>
                            <template v-if="showMenuItems">
                                <li class="breadcrumb-item" v-bind:class="{active: menuList['actionitem']}">
                                    <a href="javascript:" title="Action Item" id="actionItem" class="pointer  action-link top-menu" @click="routeActionItem"> Action Item</a>
                                </li>
                                <template v-if="userTypeCode !== 'CLI'">
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['resourceprivileges']}">
                                        <a href="javascript:" title="Resource Privilege" class="pointer action-link top-menu" @click="routePage('resourceprivileges')">Privileges</a>
                                    </li>
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['tmbrd']}">
                                        <a href="javascript:" title="Resource Board" class="pointer action-link top-menu" @click="routePage('tmbrd')">Teams</a>
                                    </li>
                                    <!-- 
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['reqbrd']}">
                                        <a href="javascript:" title="Requirement Board" class="pointer action-link top-menu" @click="routePage('reqbrd')">Req Board</a>
                                    </li>
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['tskbrd']}">
                                        <a href="javascript:" title="Task Board" class="pointer action-link top-menu" @click="getEntityRecordMasterList">Task Board</a>
                                    </li>
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['ftxbrd']}">
                                        <a href="javascript:" title="Financial Transaction Board" class="pointer action-link top-menu" @click="routePage('ftxbrd')">FinTxn Board</a>
                                    </li>
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['issbrd']}">
                                        <a href="javascript:" title="Issue Board" class="pointer action-link top-menu" @click="routePage('issbrd')">Issue Board</a>
                                    </li>
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['calevt']}">
                                        <a href="javascript:" title="Meetings / Events" class="pointer action-link top-menu" @click="routeCalendar"> Meetings</a>
                                    </li>
                                    <li class="breadcrumb-item" v-bind:class="{active: menuList['mailbox']}">
                                        <a href="javascript:" title="Mailbox" class="pointer action-link top-menu" @click="routePage('mailbox')">Mailbox</a>
                                    </li> -->
                                    <!-- <li class="breadcrumb-item" v-bind:class="{active: menuList['chatbox']}">
                                        <a href="javascript:" title="Mailbox" class="pointer action-link top-menu" @click="routePage('chatbox')">Chatbox</a>
                                    </li> -->
                                    <li class="breadcrumb-item">
                                        <select id="clientId" v-model="contractData" @change="changeSubContract" class="menu-form-control client-dropdown">
                                            <!-- <option value="">Contract</option> -->
                                            <option v-for="(info, index) in contracts" :value="{contractId: info.id, contractorId: info.contractorId}">{{info.title}}</option>
                                        </select>
                                    </li>
                                </template>
                            </template>
                            <!-- <li class="breadcrumb-item">
                                <router-link to="/sstrep">
                                    Report
                                </router-link>
                            </li> -->
                        </ol>
                    </div>
                </div>
                <!-- <div v-if="elements.showSwitchUser" class="HorizontalIconMenus d-inline-block">
                    <ul>
                        <li><a href="javascript:" @click.prevent="showCompanyCalendar" title="Company Calendar"><i class="fa fa-calendar" aria-hidden="true"></i></a></li>
                        <li><a href="javascript:" title="Settings" @click.prevent="routeSettings"><i class="fa fa-cogs" aria-hidden="true"></i></a></li>
                    </ul>
                </div> -->
                
                <div class="user d-inline-block">
                    <button class="btn btn-empty p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="name">{{userInfo.name}} <i class="fa fa-angle-down"></i></span>
                        <span v-if="userImage && userImage!= 'null'">
                            <img alt="Profile Picture" id="profile-img" width="25" height="25" :src="userImage" />
                        </span>
                        <span v-else>
                            <img alt="Profile Picture" id="profile-img" width="25" height="25" src="@/assets/img/profile-pic-l.jpg" />
                        </span>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right mt-3">
                        <a v-if="false" class="dropdown-item" href="javascript:">Account</a>
                        <a v-if="false" class="dropdown-item" href="javascript:">Features</a>
                        <a v-if="false" class="dropdown-item" href="javascript:">History</a>
                        <a v-if="false" class="dropdown-item" href="javascript:">Support</a>
                        <a class="dropdown-item" @click="routeMyProfile" href="javascript:">My Profile</a>
                        <a class="dropdown-item" @click="routeToAttendance('lanempatl')" href="javascript:">My Attendance</a>
                        <a v-if="!projectId && userTypeCode != 'CLI'" class="dropdown-item" @click="routeSettings" href="javascript:">Admin Settings</a>
                        <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);">Logout</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import iziToast from 'izitoast';
import defaultImage from '@/assets/images/logo.png';
import { EventBus } from '@/main';
export default {
    components: {
    },
    watch: {
        '$route.path': {
            handler: function(path) {
                let routeName = this.$route.name;
                console.log(path, routeName);

                this.isLandingPage = false;
                if (path=='/landing' || path=='/vsa/landing' || path=='/cmr/landing' || path=='/prm/landing' || path=='/cli/landing' || path=='/emp/landing' || path=='/tmb/landing' || path=='/hrm/landing' || path=='/fnm/landing') {
                    this.isLandingPage = true;
                }

                if (path != null && path.indexOf('/vsa/landing') != -1) {
                    this.elements.showMenu = false;
                    this.elements.showVSAMenu = true;
                } else if (path != null && path.indexOf('landing') != -1) {
                    this.elements.showMenu = false;
                    this.elements.showVSAMenu = false;
                    this.elements.showSwitchUser = true;
                } else {
                    this.elements.showMenu = true;
                    this.elements.showVSAMenu = false;
                }
                for (var item in this.menuVSAList) {
                    if (routeName == item) {
                        this.elements.showVSAMenu = true;
                        this.elements.showMenu = false;
                        break;
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'logoImage': {
            handler: function(val1, val2) {  
                console.log(val1, val2);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        userImage() {
            return localStorage.getItem("userImage");
        },
        showMenuItems() {
            let routeName = this.$route.name;
            return routeName !== 'addproject' && !routeName.includes('landing');
        },
        logoImage: {
            get() { 
                return localStorage.getItem('logo'); 
            }, 
            set(value) { 
                localStorage.setItem('logo', value); 
            },
        }
    },
    data() {
        return {
            isLandingPage: false,
            elements: {
                showMenu: false,
                showSwitchUser: false,
                showVSAMenu: false,
                active: false,
                action: false,
                dashboard: false,
                req: false,

            },
            showDialog: false,
            switchUser: {
                id: '',
                userTypeId: '',
                text: '',
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            contractData: {
                contractId: localStorage.getItem("contractId"),
                contractorId: localStorage.getItem("contractorId"),
            },
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            //logoImage:'',
            userInfo: this.$store.getters.currentUser,
            entityRecordMasterId: '',
            vhextUserType: '',
            userTypes: [],
            contracts: [],
            multiUserRoles: [],
            menuList: {
                actionitem: false,
                dashboard: false,
                reqbrd: false,
                tskbrd: false,
                tmbrd: false,
                ftxbrd: false,
                issbrd: false,
                calevt: false,
                mailbox: false,
                chatbox: false,
                notepad: false,
                resourceprivileges: false,
            },
            menuVSAList: {
                vsavhext: false,
                vsadashboard: false,
                vsasettings: false,
            },
            login: {
                email: '',
                password: '',
                switchAccount: true,
                userTypeId:''
            },
            topMainMenu: [],
            topSubMenu: [],
        }
    },
    mounted() {
        this.lookupData();
        this.hoverActiveMenu();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                // projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/contracts', data)
                .then((response) => {
                    let result = response.data;
                    this.contracts = result.contractLookup;
                })
        },
        getTopMainMenu: function(userTypeId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                userTypeId: userTypeId,
                standardMenuTypeId: 3
            }
            this.getTopSubMenu(data);
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/custommainmenu/landingtop', data)
                .then((response) => {
                    let result = response.data;
                    this.topMainMenu = result.landingTopMainMenuLookup;
                })
        },
        getTopSubMenu: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/customsubmenu/landingtop', data)
                .then((response) => {
                    let result = response.data;
                    this.topSubMenu = result.landingTopSubMenuLookup;
                })
        },
        loadSubMenu(id) {
            return this.topSubMenu.filter(function(menu) {
                if (menu.mainMenuId == id)
                    return menu;
            });
        },
        hoverActiveMenu: function() {
            let routeName = this.$route.name;
            for (var item in this.menuList) {
                if (routeName == item) {
                    this.menuList[item] = true;
                } else {
                    this.menuList[item] = false;
                }
            }
            for (var item in this.menuVSAList) {
                if (routeName == item) {
                    this.menuVSAList[item] = true;
                } else {
                    this.menuVSAList[item] = false;
                }
            }
        },
        performLogout() {
            this.$store.dispatch("doLogout");
            this.$router.push('/');
        },
        changeSubContract() {
            localStorage.setItem("contractId", this.contractData.contractId);
            localStorage.setItem("contractorId", this.contractData.contractorId);
            this.$nextTick(() => {
                EventBus.$emit('onContractChange');
            });
        },
        routeSettings: function() {
            if (this.$route.name == 'lanadmset') {
                return;
            }
            if (this.$route.path.includes('landing') && this.$route.name !== 'lanadmset') {
                this.$router.push('/landing/admset');
            }
            else if (this.$route.name !== 'settings') {
                this.$router.push('/settings');
            }
        },
        routeTopMenu: function(subMenu) {
            let path = this.$route.path;

            this.$store.state.menuData.standardMenuId = subMenu.id;
            this.$store.state.menuData.menuTitle = subMenu.displayName;
            this.$store.state.menuData.functionalInfo = subMenu.functionalInfo;
            
            if (path == subMenu.webUrl) {
                return;
            }
            else {
                this.$router.push(subMenu.webUrl);
            }
        },
        routeMyProfile: function() {

            if (this.userTypeCode !== 'CLI') {
                if ((this.$route.path).includes('landing')) {
                    if(this.$route.name !== 'lanmyprofile') {                 
                        this.$router.push('/landing/myprofile');
                    }
                }
                else {
                    if (this.$route.name !== 'prmprofile') {                 
                        this.$router.push('/prmprofile'); 
                    }
                }
            }
            else {
                if ((this.$route.path).includes('landing')) {
                    if(this.$route.name !== 'lanclientprofile') {                 
                        this.$router.push('/landing/clientprofile');
                    }
                }
                else {
                    if (this.$route.name !== 'clientprofile') {
                        this.$router.push('/clientprofile');
                    }
                }
            }

        },
        redirectLandingPage: function() {
            // To close right menu
            var body_container = $("body");
            var right_menu_container = $(".Right-main-menu");
            body_container.removeClass('open-note');
            right_menu_container.removeClass('active');
            $('.notebox').removeClass('active');
            
            let userTypeCode = localStorage.getItem("userTypeCode");
            // let projectId = localStorage.getItem("projectId");
            localStorage.removeItem("projectId");
            localStorage.removeItem("projectTypeId");

            const defaultredirects = this.$store.getters.getDefaultRedirects;

            this.$router.push({ path: defaultredirects.general });
        },
        routeActionItem: function(argument) {

            let routeName = this.$route.name;
            let userTypeCode = localStorage.getItem("userTypeCode");
            let projectId = localStorage.getItem("projectId");

            if (userTypeCode == 'CLI')
                this.$router.push('/cli/actionitem');
            else {
                this.$router.push('/actionitem');
            }

        },
        routeDashboard: function(argument) {

            let routeName = this.$route.name;
            let userTypeCode = localStorage.getItem("userTypeCode");
            let projectId = localStorage.getItem("projectId");

            if (projectId != null && routeName != 'prmlanding' && routeName != 'cmrlanding' && routeName != 'clilanding' && routeName != 'tmblanding' && routeName != 'usertask') {
                this.$router.push('/dashboard');
            } else {
                switch (userTypeCode) {
                    case "CAM":
                        this.$router.push('/user/dashboard');
                        break;
                    case "PRM":
                        this.$router.push('/prm/dashboard');
                        break;
                    case "CLI":
                        this.$router.push('/cli/dashboard');
                        break;
                    case "EMP":
                        this.$router.push('/emp/dashboard');
                        break;
                    case "TMB":
                        this.$router.push('/tmb/dashboard');
                        break;
                    default:
                        this.$router.push('/dashboard');
                        break;
                }
            }
        },
        routeCalendar: function(argument) {

            let routeName = this.$route.name;
            let userTypeCode = localStorage.getItem("userTypeCode");
            let projectId = localStorage.getItem("projectId");

            if (projectId != null && routeName != 'prmlanding' && routeName != 'cmrlanding' && routeName != 'clilanding' && routeName != 'emplanding' && routeName != 'tmblanding' && routeName != 'usertask') {
                this.$router.push('/calevt');
            } else {
                switch (userTypeCode) {
                    case "CAM":
                        this.$router.push('/cmr/calevt');
                        break;
                    case "PRM":
                        this.$router.push('/prm/calevt');
                        break;
                    case "CLI":
                        this.$router.push('/cli/calevt');
                        break;
                    case "EMP":
                        this.$router.push('/emp/calevt');
                        break;
                    case "TMB":
                        this.$router.push('/tmb/calevt');
                        break;
                    default:
                        this.$router.push('/calevt');
                        break;
                }
            }
        },
        routePage: function(argument) {

            let routeName = this.$route.name;
            localStorage.setItem("ermId", '');
            localStorage.setItem("ermTitle", '');

            if (argument == 'reqbrd') {
                if (routeName == 'reqbrd') return;
                else this.$router.push('/reqbrd');
            }

            if (argument == 'tmbrd') {
                if (routeName == 'tmbrd') return;
                else this.$router.push('/tmbrd');
            }

            if (argument == 'mailbox') {
                if (routeName == 'mailbox') return;
                else this.$router.push('/mailbox');
            }

            if (argument == 'ftxbrd') {
                if (routeName == 'ftxbrd') return;
                else this.$router.push('/ftxbrd');
            }

            if (argument == 'issbrd') {
                if (routeName == 'issbrd') return;
                else this.$router.push('/issbrd');
            }

            if (argument == 'chatbox') {
                if (routeName == 'chatbox') return;
                else this.$router.push('/chatbox');
            }

            if (argument == 'notepad') {
                if (routeName == 'notepad') return;
                else this.$router.push('/notepad');
            }

            if (argument == 'resourceprivileges') {
                if (routeName == 'resourceprivileges') return;
                else this.$router.push('/resourceprivileges');
            }
        },

        routeToAttendance(argument) {
            let routeName = this.$route.name;
            if (argument == 'lanempatl') {
                if (routeName == 'lanempatl') return;
                else this.$router.push('/landing/empatl');
            }
        },

        filterMultiUserRole: function(typeCode) {
            return this.multiUserRoles.filter(function(data, key) {
                if (data.typeCode == typeCode) {
                    return data;
                }
            });
        },
        filterUserType: function(typeCode) {
            return this.userTypes.filter(function(data, key) {
                if (data.typeCode == typeCode) {
                    return data;
                }
            });
        },
/*         getDefaultProject: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/default/view', data)
                .then((response) => {
                    let result = response.data;                    
                    if (result) {
                        this.defaultProject = result.defaultProject;
                        this.setSessionProjectId(this.defaultProject.id,this.defaultProject.projectId,this.defaultProject.projectTypeId,this.defaultProject.displayName,this.defaultProject.currencySymbol,this.defaultProject.isStandard)
                    }
                    console.log(this.defaultProject);
                }).catch(error => {
                    console.log(error);
                });
        }, */
    }
}
</script>