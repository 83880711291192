import Scope from '@/views/scope/Scope.vue'
import AddScope from '@/views/scope/AddScope.vue'
import EditScope from '@/views/scope/EditScope.vue'
import ViewScope from '@/views/scope/ViewScope.vue'
import ScopeStatement from '@/views/scope/ScopeStatement.vue'
import ScopeStatementMaster from '@/views/scope/ScopeStatementMaster.vue'
import ScopeStatementMasterList from '@/views/scope/ScopeStatementMasterList.vue'
import UATMaster from '@/views/test/UATMaster.vue'
import UATMasterList from '@/views/test/UATMasterList.vue'
import UatTestCase from '@/views/test/UatTestCase.vue'




export default [{
        path: '/scope',
        name: 'scope',
        component: Scope,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/scope/add',
        name: 'addscope',
        component: AddScope,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/scope/edit/:scopeid',
        name: 'editscope',
        component: EditScope,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/scope/view/:scopeid',
        name: 'viewscope',
        component: ViewScope,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/scope/statement/:ermId',
        name: 'scopestatement',
        component: ScopeStatement,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }, 
    {
        path: '/scopestatementmaster',
        name: 'scopestatementmaster',
        component: ScopeStatementMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/scope/list',
        name: 'scopelist',
        component: ScopeStatementMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/uatmaster',
        name: 'uatmaster',
        component: UATMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/uatmaster/list',
        name: 'uatmasterlist',
        component: UATMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {   
        path: '/uattestcase/:ermId',
        name: 'uattestcase',
        component: UatTestCase,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    
];