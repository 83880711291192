<template>
    <div>
        <CommunicationPlanMasterList :ermMasterInfo="ermMasterInfo"></CommunicationPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import CommunicationPlanMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        CommunicationPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Communication Plan List",
                entity: 'communication_plan_master',
                refEntity: 'communication_plan',
                tooltip: 'New Meeting Plan',
                redirectURLName: 'meetingplan',
                newEntityMasterURL: 'cplmas',
                redirectURL: '/cplmas',
                actionName: 'Plan Meeting',
                previewURLName: 'cplrep',
                showTypeLookup:true

            }
        }
    },
    mounted() {},
    methods: {}
}
</script>