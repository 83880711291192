<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <ProcessPlanReport />
            </div>
        </div>
    </div>
</template>
<script>
import ProcessPlanReport from '@/views/report/doc/ProcessPlanReport.vue';
export default {
    components: {
        ProcessPlanReport
    }
}
</script>