<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Corp. Group Companies - Employee Effort and Cost Analysis</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <!-- top to bottom -->
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                                <label v-if="elements.filterVisible" class="my-2 py-1">Hide</label>
                                <label v-else class="my-2 py-1">Filter</label>
                            </div>
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                                                        <div class="comments-cox mt-2">
                                        <div class="row m-0">
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" class="form-control" v-model="fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>Till Date</label>
                                                    <input type="date" class="form-control" v-model="tillDate">
                                                </div>
                                            </div>
                                            <!-- <div class="col-3">
                                                <div class="form-group">
                                                    <label for="employee">Employee</label>
                                                    <select v-model="employeeId" class="form-control" >
                                                        <option value="">All Employees</option>
                                                        <option v-for="(info, index) in employees" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label for="preparedBy">Company</label>
                                                    <select v-model="companyId" class="form-control" @change="onCompanyChange">
                                                        <option value="">All Companies</option>
                                                        <option v-for="(info, index) in cgrCompanies" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <button type="submit" @click="getEmpEffortAndCostReport" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div v-if="empUserId" class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Employee</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ employeeName }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <div class="row">
                                                                <div class="col-12 col-md-4 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Work Period</label>
                                                                </div>
                                                                <div class="col-12 col-md-8 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ formatDate(startDate) }} <span class="px-2">To</span> {{ formatDate(endDate) }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Company</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ company }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title"><span>Employee Effort and Cost [{{totalRows}}]</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">
                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="filteredEmpEffortAndCostList" :fields="empEffortAndCostFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <!-- <template v-slot:cell(title)="data">
                                                        <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                    </template> -->
                                                    <template v-slot:cell(estWorkMins)="data">
                                                        {{ data.value ? convertToHM(data.value) : '' }}
                                                    </template>
                                                    <template v-slot:cell(estEffortMins)="data">
                                                        {{ data.value ? convertToHM(data.value) : '' }}
                                                    </template>
                                                    <template v-slot:cell(actEffortMins)="data">
                                                        {{ data.value ? convertToHM(data.value) : '' }}
                                                    </template>
                                                    <template v-slot:cell(taskEffortMins)="data">
                                                        {{ data.value ? convertToHM(data.value) : '' }}
                                                    </template>
                                                    
                                                    <template v-slot:cell(empHourlySalary)="data">
                                                        <span class="text-right" style="display: inline-block; width: 100%;">{{ data.value }}</span>
                                                    </template>
                                                    <template v-slot:cell(empEffortToCost)="data">
                                                        <span class="text-right" style="display: inline-block; width: 100%;">{{ data.value }}</span>
                                                    </template>
                                                    <template v-slot:cell(performance)="data">
                                                        <span title="Performance">{{ data.value ? data.value : 0 }}%</span>
                                                        <!-- <b-progress height="7px" :value="data.value" variant="success"></b-progress> -->
                                                        <b-progress height="7px">
                                                            <b-progress-bar 
                                                                :value="data.value" 
                                                                variant="success"
                                                                v-if="data.value > 0"></b-progress-bar>
                                                            
                                                            <b-progress-bar 
                                                                :value="100 - data.value" 
                                                                variant="danger"
                                                                v-if="data.value < 100"></b-progress-bar>
                                                        </b-progress>
                                                    </template>
                                                    <!-- <template v-slot:cell(costEfficiency)="data">
                                                        <label class="m-0 d-flex justify-content-between" title="Performance">
                                                            <span>{{ getPerformanceRemark(calculateCostPercentage(data.item.estimatedCost,data.item.actualCost)) }}</span>
                                                            <span class="float-right">{{ calculateCostPercentage(data.item.estimatedCost,data.item.actualCost) }}%</span>
                                                        </label>
                                                        <b-progress height="7px" :value="calculateCostPercentage(data.item.estimatedCost,data.item.actualCost)" :variant="performanceProgressVariant(calculateCostPercentage(data.item.estimatedCost,data.item.actualCost))"></b-progress>
                                                    </template> -->
                                                    <!-- <template v-slot:cell(performance)="data">
                                                        <label class="m-0 d-flex justify-content-between" title="Performance">
                                                                <span>{{ getPerformanceRemark(calculatePerformance(data.item.estEffortMins,data.item.actEffortMins)) }}</span>
                                                                <span class="float-right">{{ calculatePerformance(data.item.estEffortMins,data.item.actEffortMins) }}%</span>
                                                            </label>
                                                            <b-progress height="7px" :value="calculatePerformance(data.item.estEffortMins,data.item.actEffortMins)" :variant="performanceProgressVariant(calculatePerformance(data.item.estEffortMins,data.item.actEffortMins))">
                                                                
                                                            </b-progress>
                                                    </template> -->
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Task found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :attachmentData="attachmentData"  @closeDialog="showDialog=false;" />
    </div>
</template>
<script>
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    components: {
        TaskDetailPopup
    },
    data() {
        return {
            elements: {
                filterVisible: true,
            },
            showDialog: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            fromDate: this.getDateValue(new Date()),
            tillDate: this.getDateValue(new Date()),
            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),



            

            attachmentData: {},

            isBusy: false,

            cgrCompanies: [],
            cgrProjects: [],
            cgrPlans: [],
            cgrEmployees: [],

            filteredProjects: [],
            filteredPlans: [],
            filteredEmployees: [],


            companyId: '',
            projectId: '',
            planId: '',
            empUserId: '',
            

            workPatterns: [],

            empEffortAndCostList: [],
            empEffortAndCostFields: [
                { key: 'index', label: 'SN', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Employee Name', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'cmpShortName', label: 'Company', sortable: true, sortDirection: 'desc' },
                { key: 'totalWorkingDays', label: 'Working Days', sortable: true, sortDirection: 'desc' },
                { key: 'totalEmpPresentDays', label: 'Emp Present Days', sortable: true, sortDirection: 'desc' },
                { key: 'estWorkMins', label: 'Est. Work Hours',   sortable: true,sortDirection: 'desc' },
                { key: 'actEffortMins', label: 'Act. Effort',   sortable: true,sortDirection: 'desc' },
                { key: 'empHourlySalary', label: 'Hourly Salary',  sortable: true, sortDirection: 'desc' },
                { key: 'empEffortToCost', label: 'Effort to Cost',   sortable: true,sortDirection: 'desc' },
                { key: 'performance', label: 'Efficiency', tdClass: 'table-cw-300',  sortable: true,sortDirection: 'desc' },
            ],

            totalRows: 0,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        company() {
            if (this.cgrCompanies.length>0) {
                return this.companyId ? this.cgrCompanies.find(data => data.id == this.companyId).value : 'All Companies';
            }
        },
        // employeeName() {
        //     if (this.cgrEmployees.length>0) {
        //         return this.empUserId ? this.cgrEmployees.find(data => data.userId == this.empUserId).value : '';
        //     }
        // },
        filteredEmpEffortAndCostList() {
            let filteredEmpEffortAndCostList = this.empEffortAndCostList;
            if (filteredEmpEffortAndCostList.length>0) {
                
                filteredEmpEffortAndCostList = this.companyId ? filteredEmpEffortAndCostList.filter(data => data.vhextAccountId == this.companyId) : filteredEmpEffortAndCostList;
                // filteredEmpEffortAndCostList = this.projectId ? filteredEmpEffortAndCostList.filter(data => data.projectId == this.projectId) : filteredEmpEffortAndCostList;
                // filteredEmpEffortAndCostList = this.planId ? filteredEmpEffortAndCostList.filter(data => data.planId == this.planId) : filteredEmpEffortAndCostList;
                
            }

            this.totalRows = filteredEmpEffortAndCostList.length;
            return filteredEmpEffortAndCostList;
        },
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
        this.getEmpEffortAndCostReport();
    },
    methods: {
        getEmpEffortAndCostReport: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                startDate: this.fromDate,
                endDate: this.tillDate,
                // assignedToId: this.empUserId,
            }
            this.startDate = this.fromDate;
            this.endDate = this.tillDate;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/cgr/empeffortandcost/report', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.empEffortAndCostList = result.cgrEmployeeEffortAndCostList;
                    this.totalRows = this.empEffortAndCostList.length;
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/cgr/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.cgrCompanies = result.cgrCompanyLookup;
                    // this.cgrProjects = result.cgrProjectLookup;
                    // this.cgrPlans = result.cgrPlanLookup;
                    // this.cgrEmployees = result.cgrEmployeeLookup;

                    // this.filteredProjects = this.cgrProjects;
                    // this.filteredPlans = this.cgrPlans;
                    // this.filteredEmployees = this.cgrEmployees;

                })
        },
        // viewItemDetails: function(data) {
        //     this.attachmentData.refEntity = 'task';
        //     this.attachmentData.refRecordId = data.id;
        //     this.showDialog = true;
        //     this.$nextTick(()=> {
        //         this.$refs.taskDetailPopupRef.getDetails(data.id, 'task');
        //     })
        // },
        onCompanyChange(event) {
            console.log(event.target.value);
            const selectedParentId = parseInt(event.target.value);

            // Filter child options based on selected parent
            if(event.target.value) {
                this.filteredProjects = this.cgrProjects.filter(child => child.vhextAccountId === selectedParentId);
                this.filteredPlans = this.cgrPlans.filter(child => child.vhextAccountId === selectedParentId);
                this.filteredEmployees = this.cgrEmployees.filter(child => child.vhextAccountId === selectedParentId);
            }else{
                this.filteredProjects = this.cgrProjects;
                this.filteredPlans = this.cgrPlans;
                this.filteredEmployees = this.cgrEmployees;
            }
        },
        // onProjectChange(event) {
        //     console.log(event.target.value);
        //     const selectedParentId = parseInt(event.target.value);
        //     if(event.target.value) {
        //         this.filteredPlans = this.cgrPlans.filter(child => child.projectId === selectedParentId);
        //     }else{
        //         this.filteredPlans = this.cgrPlans;
        //     }
        // },
        calculatePerformance(estEffort, actEffort) {
            return actEffort ? Math.round((estEffort / actEffort) * 100) : 0;
        },
        calculateProgression(estEffort, actEffort) {
            return estEffort ? Math.round((actEffort / estEffort) * 100) : 0;
        },
        calculateCostPercentage(estimatedCost, actualCost) {
            return actualCost ? Math.round((estimatedCost / actualCost) * 100) : 0;
        },
        getPerformanceRemark(percentage) {
            let output = '';
            if (percentage) {
                if (percentage <= 80)
                    output = 'Unacceptable';
                else if (percentage <= 90)
                    output = 'Needs Improvement';
                else if (percentage <= 100)
                    output = 'Meets Expectations';
                else if (percentage <= 130)
                    output = 'Exceeds Expectations';
                else
                    output = 'Outstanding';
            }
            return output;
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.fromDate = this.getDateValue(this.startDate);
            this.tillDate = this.getDateValue(this.endDate);
        },
        clearData: function() {    
            this.startDate = this.getDateValue(new Date());
            this.endDate = this.getDateValue(new Date());
            this.fromDate = this.getDateValue(new Date());
            this.tillDate = this.getDateValue(new Date());
            this.empUserId = '';
            this.getEmpEffortAndCostReport();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>