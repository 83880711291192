<template>
    <div class="">
        <div class="primary-nav">

            <button href="#" class="hamburger open-panel nav-toggle">
                <span class="screen-reader-text">Menu</span>
            </button>

            <nav role="navigation" class="menu">
                <div class="overflow-container">
                    <ul class="menu-dropdown">
                        <li><a href="#" class=""><span>Create Mail</span></a><span class="icon"><i class="fa fa-plus"></i></span></li>
                        <li><a href="#">Inbox</a><span class="icon"><i class="fa fa-dashboard"></i></span></li>
                        <li><a href="#">Sent</a><span class="icon"><i class="fa fa-heart"></i></span></li>
                        <li><a href="#">Trash</a><span class="icon"><i class="fa fa-envelope"></i></span></li>
                    </ul>
                </div>

            </nav>

        </div>
    </div>
</template>
<script>
/*import smenu from '../../json/submenu.json';
import emenu from '../../json/entity.json';*/
export default {

    data() {
        return {
            elements: {
                showLogoBlock: false
            },
            mainMenu: [],
            subMenu: [],
            entity: [],
            entityMenu: []
        }
    },
    mounted() {
        this.loadMenu();
    },
    methods: {
        loadMenu() {
            let data = {
                userId: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
            }
            /*this.mainMenu = smenu;
            this.loadSubMenu(1);
            this.entity = emenu;
            return;*/
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/custommainmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.mainMenu = result.customMainMenuLookup;
                    this.loadSubMenu(1);
                })

            axios.post(this.$store.getters.getAPIBasePath + '/lookup/customsubmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.entity = result.customSubMenuLookup;
                })
        },
        targetId: function(id) {
            return "#collapseAuthorization" + id;
        },
        randomId: function(id) {
            return "collapseAuthorization" + id;
        },
        loadSubMenu(id) {
            this.subMenu = this.mainMenu.filter(function(menu) {
                if (menu.moduleId == id)
                    return menu;
            });
        },
        loadEntity(id) {
            return this.entity.filter(function(menu) {
                if (menu.mainMenuId == id)
                    return menu;
            });
        }
    }
}
</script>