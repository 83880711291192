<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Document List</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a title="Board View" class="action-link pointer" @click="routeActionItemBoard()">
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click=""><i class="fa fa-share pr-2" aria-hidden="true"></i>Share</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="mt-2">
                                <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="actionItems" :fields="actionItemFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(actionItemTitle)="data">
                                        <a href="javascript:" class="action-link" :title="data.value" @click="viewReference(data.item, data.item.actionItemTitle)">
                                            {{ data.value }} 
                                            <span class="badge pointer float-right" :class="data.item.className" title="Share">{{ getStatusBySource(data.item.actionItemSource) }}</span>
                                        </a>
                                    </template>
                                    <template v-slot:cell(actionItemStartDt)="data">
                                        <div v-if="data.value">{{ data.value }}</div>
                                        <div v-else class="text-center">NA</div>
                                    </template>
                                    <template v-slot:cell(actionItemDueDt)="data">
                                        <div v-if="data.value">{{ data.value }}</div>
                                        <div v-else class="text-center">NA</div>
                                    </template>
                                    <template v-slot:cell(isApproval)="data">
                                        <div>
                                            <p v-if="data.value == false">
                                                <span v-if="data.item.reviewDt">Reviewed</span>
                                                <span v-else>Review Pending</span>
                                            </p>
                                            <p v-else>
                                                <span v-if="data.item.reviewDt">Approved</span>
                                                <span v-else>Approval Pending</span>
                                            </p>
                                        </div>
                                    </template>
                                    <template v-slot:cell(actions)="data">
                                        <div v-if="data.item.actionItemSource == 'COPY' && data.item.status=='Copy Pending'">
                                            <template v-if="userId!=data.item.actionItemById">
                                                <b-button class="sbtn btn-xs p-1 mr-1" variant="success" @click="acceptDocumentCopy(data.item.id,data.item.entityRecordId, 'Copied')" title="Accept Document Copy">Accept</b-button>
                                                <b-button class="sbtn btn-xs p-1" variant="outline-danger" @click="acceptDocumentCopy(data.item.id,data.item.entityRecordId, 'Declined')" title="Reject Document Copy">Decline</b-button>
                                            </template>
                                            <template v-else-if="userId==data.item.actionItemById">
                                                <b-button class="sbtn btn-xs p-1" variant="outline-danger" @click="acceptDocumentCopy(data.item.id,data.item.entityRecordId, 'Cancelled')" title="Cancel Copy">Cancel</b-button>
                                            </template>
                                        </div>
                                        <div v-else-if="data.item.actionItemSource !== 'COPY'">
                                            <!-- <router-link v-if="userTypeCode == 'CLI'" class="btn btn-secondary btn-xs px-2 m-0" title="Go to page" :to="{ name: getClientReportUrl(data.item.entity), params: { ermId: data.item.ermId }}"><i class="fa fa-angle-double-right fa-lg"></i></router-link> -->
                                            <router-link v-if="data.item.actionItemSource == 'SHARE'" class="btn btn-secondary btn-xs px-2 m-0" title="Go to page" :to="{ name: getMasterListUrlName(data.item.ermEntity), params: { ermId: data.item.ermId }}"><i class="fa fa-angle-double-right fa-lg"></i></router-link>
                                            <router-link v-else class="btn btn-secondary btn-xs px-2 m-0" title="Go to page" :to="{ name: getReportUrlName(data.item.ermEntity), params: { ermId: data.item.ermId }}"><i class="fa fa-angle-double-right fa-lg"></i></router-link>
                                            <!-- <b-button class="btn-xs px-2 m-0" variant="secondary" @click="" title="Cancel Copy Request"><i class="fa fa-angle-double-right fa-lg"></i></b-button> -->
                                        </div>
                                    </template>
                                    <template v-slot:empty="">
                                        <div class="text-center">No Document found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="">
                                        <div class="text-center">{{ }}</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                
                                <b-pagination v-if="actionItems.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ERMCopyConfirmation ref="ermCopyConfirmRef" @refreshData="loadData" /> -->
        <!-- <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" /> -->
        <ReviewApprovalDetailPopup id="reviewApprovalDetailPopup-doc" ref="reviewApprovalDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <!-- <TaskDetailPopup ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" /> -->
        <ShareDetailPopup id="shareDetailPopup-doc" ref="shareDetailPopupRef" :detailViewData="detailViewData" />
        <!-- <MeetingPrepartionDetailPopup ref="meetingPrepartionDetailPopupRef" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import redirectMixin from "@/mixins/redirect";
// import ERMCopyConfirmation from '@/components/popup/dialogbox/ERMCopyConfirmation.vue';
// import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
import ReviewApprovalDetailPopup from '@/components/popup/itemdetail/ReviewApprovalDetailPopup.vue';
// import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import ShareDetailPopup from '@/components/popup/itemdetail/ShareDetailPopup.vue';
// import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import enumMixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [redirectMixin, enumMixin],
    props: {
        ermMasterInfo: Object
    },
    components: {
        // ERMCopyConfirmation,
        // ReferenceDetailPopup,
        ReviewApprovalDetailPopup,
        ShareDetailPopup,
        // MeetingPrepartionDetailPopup,
        // TaskDetailPopup
    },
    data() {
        return {
            elements: {
                visible: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),    
            userId: localStorage.getItem("userId"),        
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},

            actionItems: [],
            actionItemFields: [
                { key: 'index', label: 'SN' },
                { key: 'actionItemTitle', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'ermEntityDisplayName', label: 'Document Type', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemBy', label: 'Shared By', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemTo', label: 'Shared To', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actionItemDt', label: 'Shared Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc', class: '' },
                // { key: 'actionItemStartDt', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc', class: '' },
                // { key: 'actionItemDueDt', label: 'Due Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc', class: '' },
                // { key: 'actionItemSource', label: 'Action Type', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' },
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: 'actionItemDt',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    watch: {
        totalRows(val) {
            this.$emit('updateDocCount', val);
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        loadData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: localStorage.getItem("userId"),
                isArchive: false
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/actionitem/document/list', data)
                .then((response) => {
                    let result = response.data;
                    this.actionItems = result.documentList;
                    this.totalRows = this.actionItems.length;
                    console.log(result);
                })

        },
        viewReference: function(data, title) {
            console.log(data);
            this.referencePopupId = data.entityRecordId;
            this.refPopupTitle = title;
            this.refPopupEntity = data.actionItemSource;
            this.detailViewData = data;
            
            if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            } 
            else if (this.refPopupEntity == 'APV' || this.refPopupEntity == 'REV') {
                this.$refs.reviewApprovalDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'EMAIL') {
                this.$refs.emailDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'SHARE') {
                this.$refs.shareDetailPopupRef.getDetails();
            }
            else if (this.refPopupEntity == 'MTG') {
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.referencePopupId, data.entity, null);
            }
            else {
                this.$nextTick(() => {
                    this.$bvModal.show('referenceDetail');
                })
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
                console.log(this.referencePopupId, this.refPopupEntity, data);
            }
        },
        acceptDocumentCopy: function(id, entityRecordId, status) {
            this.$refs.ermCopyConfirmRef.getERMCopyData(id, entityRecordId, status);
        },
        routeActionItemBoard() {
            if (this.projectId) {
                this.$router.push('/actionitem');
            } else {
                this.$router.push('/landing/actionitem');
            }
        },        
    }
}
</script>