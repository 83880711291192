<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">My Profile</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row px-2">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-4">
                        <div class="card-body p-3 ">
                            <div id="profile" class="pb-0">
                                <div class="profile_header">
                                    <div class="row text-left">
                                        <div class="col-8">
                                            <div class="my-profile-img">
                                                <img :src="contactDetail.photo" alt="Profile picture">
                                                <label class="upload-icon upload-icon-contacts">
                                                    <i class="fa fa-camera"></i>
                                                    <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="uploadPhoto">
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile_header text-left">
                                    <div class="row mb-2 mt-2 text-left">
                                        <div class="col-10">
                                            <h6>Primary Info</h6>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-3 float-right">
                                            <div class="text-center">
                                                <button v-if="!elements.primaryInfoEditMode" href="javascript:" @click="elements.primaryInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.primaryInfoEditMode">
                                                    <button type="submit" @click="updateClient" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="clearData" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <b-row class="mb-2">
                                            <b-col cols="2">Name</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.primaryInfoEditMode">{{client.clientName}}</label>
                                                <input v-if="elements.primaryInfoEditMode" type="text" class="form-control" v-model="client.name">
                                            </b-col>
                                        </b-row>
<!--                                         <b-row class="mb-2">
                                            <b-col cols="2">Client Type</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.primaryInfoEditMode">{{client.clientType}}</label>
                                                <input v-if="elements.primaryInfoEditMode" type="text" class="form-control" v-model="client.clientType.text">
                                            </b-col>
                                        </b-row> -->

                                        <b-row class="mb-2">
                                            <b-col cols="2">Position</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.primaryInfoEditMode">{{client.position}}</label>
                                                <input v-if="elements.primaryInfoEditMode" type="text" class="form-control" v-model="client.position">
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                            <div id="profile">
                                <div class="profile_header text-left">
                                    <div class="row mb-2 text-left">
                                        <div class="col-10">
                                            <h6>User Account Info</h6>
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <b-row class="mb-2">
                                            <b-col cols="2">Account Name</b-col>
                                            <b-col cols="8">
                                                <label>{{client.userName}}</label>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-2">
                                            <b-col cols="2">Login Name</b-col>
                                            <b-col cols="8">
                                                <label>{{client.loginName}}</label>
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-2">
                                            <b-col cols="2">Account Type</b-col>
                                            <b-col cols="8">
                                                <label>{{client.userType}}</label>
                                                
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="ml-3">
                                        <b-row class="mb-2">
                                            <b-col v-if="!elements.userInfoEditMode" cols="2">Password</b-col>
                                            <b-col v-if="elements.userInfoEditMode" cols="2">Old Password</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.userInfoEditMode">*************</label>
                                                <input v-if="elements.userInfoEditMode" type="password" class="form-control" v-model="activation.oldPassword">
                                            </b-col>
                                            <div class="col-md-2 col-sm-2  float-right">
                                                <div class="text-center">
                                                    <button v-if="!elements.userInfoEditMode" href="javascript:" @click="setEditMode('userInfoEditMode')" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.userInfoEditMode">
                                                        <button type="submit" @click="submitPassword" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="clearData()" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-row>
                                    </div>
                                    <div class="ml-3">
                                        <b-row v-if="elements.userInfoEditMode" class="mb-2">
                                            <b-col cols="2">New Password</b-col>
                                            <b-col cols="8">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.activation.$error }">
                                                    <input type="password" name="Password" placeholder="Password" maxlength="45" size="30" v-model="activation.newPassword" class="form-control box-none">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.activation.$error" class="invalid-feedback">
                                                    <span v-if="!$v.activation.newPassword.required">Password is required</span>
                                                    <span v-if="!$v.activation.newPassword.minLength">Password must be at least 6 characters</span>
                                                </div>
                                            </b-col>
                                        </b-row>
                                        <b-row v-if="elements.userInfoEditMode" class="mb-2">
                                            <b-col cols="2">Confirm Password</b-col>
                                            <b-col cols="8">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.activation.confirmPassword.$error }">
                                                    <input type="password" v-model="activation.confirmPassword" maxlength="45" size="30" class="form-control" id="confirmPassword" placeholder="Confirm Password">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.activation.confirmPassword.$error" class="invalid-feedback">
                                                    <span v-if="!$v.activation.confirmPassword.required">Confirm Password is required</span>
                                                    <span v-else-if="!$v.activation.confirmPassword.sameAsPassword">Passwords must match</span>
                                                </div>
                                            </b-col>
                                        </b-row>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 mb-4 px-2">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div id="profile" class="pb-0">
                                <div class="profile_header text-left">
                                    <div class="row mb-2 text-left">
                                        <div class="col-10">
                                            <h6>Contact Info</h6>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-3 float-right">
                                            <div class="text-center">
                                                <button v-if="!elements.contactInfoEditMode" href="javascript:" @click="elements.contactInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.contactInfoEditMode">
                                                    <button type="submit" @click="updateContact" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="clearData" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div class="ml-3">
                                            <b-row class="mb-2">
                                                <b-col cols="4">Phone</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactInfoEditMode">{{contactDetail.phone}}</label>
                                                    <input v-if="elements.contactInfoEditMode" type="text" class="form-control" v-model="contactDetail.phone">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">ALT Phone</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactInfoEditMode">{{contactDetail.officePhone}}</label>
                                                    <input v-if="elements.contactInfoEditMode" type="text" class="form-control" v-model="contactDetail.officePhone">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">Mobile</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactInfoEditMode">{{contactDetail.mobile}}</label>
                                                    <input v-if="elements.contactInfoEditMode" type="text" class="form-control" v-model="contactDetail.mobile">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">ALT Mobile</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactInfoEditMode">{{contactDetail.alternateMobile}}</label>
                                                    <input v-if="elements.contactInfoEditMode" type="text" class="form-control" v-model="contactDetail.alternateMobile">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">Email Id</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactInfoEditMode">{{contactDetail.emailId}}</label>
                                                    <input v-if="elements.contactInfoEditMode" type="text" class="form-control" v-model="contactDetail.emailId">
                                                </b-col>
                                            </b-row>
                                            <b-row class="mb-2">
                                                <b-col cols="4">ALT Email Id</b-col>
                                                <b-col cols="8">
                                                    <label v-if="!elements.contactInfoEditMode">{{contactDetail.alternateEmailId}}</label>
                                                    <input v-if="elements.contactInfoEditMode" type="text" class="form-control" v-model="contactDetail.alternateEmailId">
                                                </b-col>
                                            </b-row>
                                        </div>
                                </div>
                            </div>
                            <div id="profile" class="pb-0">
                                <div class="profile_header text-left">
                                    <div class="row mb-2 text-left">
                                        <div class="col-10">
                                            <h6>Address Info</h6>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-3 float-right">
                                            <div class="text-center">
                                                <button v-if="!elements.addressInfoEditMode" href="javascript:" @click="elements.addressInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.addressInfoEditMode">
                                                    <button type="submit" @click="updateContact" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="clearData" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!elements.addressInfoEditMode" class="pl-2 ml-3">
                                            <p v-if="contactDetail.address1 != ''">
                                                <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                                <span v-if="contactDetail.address1 != ''">{{contactDetail.address1}}</span>
                                                <span v-if="contactDetail.address2 != ''">, {{contactDetail.address2}}</span>
                                                <span v-if="contactDetail.streetName != ''">, {{contactDetail.streetName}}</span>
                                                <span v-if="contactDetail.state != ''">, {{contactDetail.state}}</span>
                                                <span v-if="contactDetail.city != ''">, {{contactDetail.city}}</span>
                                                <span v-if="contactDetail.country != ''">, {{contactDetail.country}}</span>
                                                <span v-if="contactDetail.pincode != ''">, {{contactDetail.pincode}}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Address Line 1</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="contactDetail.address1">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Address Line 2</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="contactDetail.address2">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Street Name</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="contactDetail.streetName">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">State</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="contactDetail.state">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">City</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="contactDetail.city">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Country</b-col>
                                            <b-col cols="8">
                                                <select v-if="elements.addressInfoEditMode" v-model="contactDetail.countryId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.addressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Pin Code</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="contactDetail.pincode">
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entity: 'client',
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            
            monthList: month,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                primaryInfoEditMode: false,
                userInfoEditMode: false,
                contactInfoEditMode: false,
                addressInfoEditMode: false,
                roleEditMode: false,
                techEditMode: false,
                contactEditMode: false,
                showDropdownRole: false,
                showDropdownTech: false
            },
            client: {
                id: '',
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                userType: '',
                userName: '',
                loginName: '',
                userActive: '',
                clientTypeId: '',
                clientTypeName: '',
                clientType: {
                    id: 1,
                    text: 'Individual'
                },
                type: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            },
            // client: {
            //     id: '',
            //     clientId: '',
            //     vhextAccountId: localStorage.getItem("vhextAccountId"),
            //     projectId: localStorage.getItem("projectId"),
            //     entity: 'client',
            //     userId: '',
            //     userType: '',
            //     userName: '',
            //     loginName: '',
            //     userActive: '',
            //     clientType: '',
            //     type: '',
            //     clientTypeId: '',
            //     clientTypeName: '',
            //     clientName: '',
            //     contactPerson: '',
            //     firstName: '',
            //     middleName: '',
            //     lastName: '',
            //     location: '',
            //     emailId: '',
            //     phone: '',
            //     mobile: '',
            //     address1: '',
            //     address2: '',
            //     city: '',
            //     state: '',
            //     countryId: '',
            //     country: '',
            //     pincode: '',
            //     hasContactInfo: '',
            //     active: '',
            //     profession: '',
            //     employer: '',
            //     position: '',
            //     createdById: localStorage.getItem("userId"),
            //     invite: false
            // },
            contactDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntity: 'human_resource',
                refRecordId: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                contactName: '',
                emailId:'',
                alternateEmailId:''
            },
            activation:{
                oldPassword:'',
                newPassword: '',
                confirmPassword:''
            },

            users: [],
            contacts: [],
            countries: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.loadOnce = true;
        this.viewClient();
        this.lookupData();
    },
    validations: {
        activation: {
            newPassword: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('newPassword')
            }
        },
        client: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        },
    },
    methods: {
        viewClient: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                projectId: this.projectId,
                id: this.client.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/view', data)
                .then((response) => {
                    let result = response.data;

                    console.log(result);                    
                    if (result) {
                        this.client = result.client;

                        // this.project.durationDays = (this.project.durationDays == undefined) ? '' : this.project.durationDays + " Days";
                        // this.project.durationMonths = (this.project.durationMonths == undefined) ? '' : this.project.durationMonths + " Months";
                        // this.project.durationYears = (this.project.durationYears == undefined) ? '' : this.project.durationYears + " Years";
                        // this.project.isArchive = (this.project.isArchive == undefined) ? false : this.project.isArchive;
                        // this.prependText = this.project.currencyCode;
                        this.elements.editMode = false;
                        this.elements.primaryInfoEditMode = false;
                        this.elements.userInfoEditMode = false;
                        this.elements.contactInfoEditMode = false;
                        this.elements.addressInfoEditMode = false;
                        this.loadOnce = false;
                        this.getContacts();
                        
                        console.log(this.client);                        
                    }
                })
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.client.id,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/hr/list', data)
                .then((response) => {
                    let result = response.data;
                        console.log(result);
                    if (result.hRContactList.length > 0) {
                        this.contacts = result.hRContactList[0];
                        this.contactDetail.id = (this.contacts.id == undefined) ? '' : this.contacts.id;
                        this.contactDetail.projectId = (this.contacts.projectId == undefined) ? '' : this.contacts.projectId;
                        this.contactDetail.vhextAccountId = (this.contacts.vhextAccountId == undefined) ? '' : this.contacts.vhextAccountId;
                        this.contactDetail.refEntity = (this.contacts.refEntity == undefined) ? '' : this.contacts.refEntity;
                        this.contactDetail.refRecordId = (this.contacts.refRecordId == undefined) ? '' : this.contacts.refRecordId;
                        this.contactDetail.phone = (this.contacts.phone == undefined) ? '' : this.contacts.phone;
                        this.contactDetail.mobile = (this.contacts.mobile == undefined) ? '' : this.contacts.mobile;
                        this.contactDetail.alternateMobile = (this.contacts.alternateMobile == undefined) ? '' : this.contacts.alternateMobile;
                        this.contactDetail.address1 = (this.contacts.address1 == undefined) ? '' : this.contacts.address1;
                        this.contactDetail.address2 = (this.contacts.address2 == undefined) ? '' : this.contacts.address2;
                        this.contactDetail.officePhone = (this.contacts.officePhone == undefined) ? '' : this.contacts.officePhone;
                        this.contactDetail.streetName = (this.contacts.streetName == undefined) ? '' : this.contacts.streetName;
                        this.contactDetail.city = (this.contacts.city == undefined) ? '' : this.contacts.city;
                        this.contactDetail.state = (this.contacts.state == undefined) ? '' : this.contacts.state;
                        this.contactDetail.countryId = (this.contacts.countryId == undefined) ? '' : this.contacts.countryId;
                        this.contactDetail.country = (this.contacts.country == undefined) ? '' : this.contacts.country;
                        this.contactDetail.pincode = (this.contacts.pincode == undefined) ? '' : this.contacts.pincode;
                        this.contactDetail.contactName = (this.contacts.contactName == undefined) ? '' : this.contacts.contactName;
                        this.contactDetail.emailId = (this.contacts.emailId == undefined) ? '' : this.contacts.emailId;
                        this.contactDetail.alternateEmailId = (this.contacts.alternateEmailId == undefined) ? '' : this.contacts.alternateEmailId;
                        this.contactDetail.photo = this.contacts.photo == null ? this.defaultPhoto : this.contacts.photo;
                        console.log(this.contactDetail);
                    }
                    else {
                        this.clearContact();
                    }
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity, 
                           }
            axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.countries = result.countryLookup;
                    this.users = result.userLookup;
                })

        },
        submitPassword: function() {
            this.$v.activation.$touch();
            if (!this.$v.activation.$invalid) {
                this.changePassword();
            }
        },
        changePassword: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/user/changepassword', this.activation)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                    }
                    if (result.status == "OK") {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
        },
        uploadPhoto: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: 'File format not supported',
                    position: 'topRight'
                });
                return false;
            }

            let formData = new FormData();
            formData.append('file', this.$refs.profile_photo.files[0]);
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('id', this.contactDetail.id);

            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    var result = response.data;
                    iziToast.success({
                        title: 'Success',
                        message: 'Profile photo uploaded Successfully',
                        position: 'topRight'
                    });
                    this.getContacts();           
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Unable to update photo',
                        position: 'topRight'
                    });
                });
        },
        updateClient: function() {
            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }
            console.log(this.client);
            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/client/update', this.client)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getClients();
                            this.clearData();
                        }
                        console.log(result);

                    })
            }

        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.primaryInfoEditMode = false;
            this.elements.userInfoEditMode = false;
            this.elements.contactInfoEditMode = false;
            this.elements.addressInfoEditMode = false;
            this.viewClient(this.client.id);
        },
        updateContact: function() {
            console.log(this.contactDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contactDetail)
                .then((response) => {
                    let result = response.data;
                    this.elements.contactEditMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.getContacts();
                        this.clearContact();
                    }
                })
        },
        setEditMode: function(editMode) {
            let elements = Object.keys(this.elements);

            elements.map((item) => {
                if (item == editMode) {
                    this.elements[item] = true;
                }
                else {
                    this.elements[item] = false;
                }
            })
           
        },
        clearContact: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.contactInfoEditMode = false;
            this.elements.addressInfoEditMode = false;
            this.getContacts();
            // this.viewClient(this.client.id);
        },

        clearContact: function() {
            this.elements.contactEditMode = false;
            this.contactDetail.id = '';
            this.contactDetail.refRecordId = '';
            this.contactDetail.phone = '';
            this.contactDetail.phone = '';
            this.contactDetail.mobile = '';
            this.contactDetail.alternateMobile = '';
            this.contactDetail.address1 = '';
            this.contactDetail.address2 = '';
            this.contactDetail.streetName = '';
            this.contactDetail.pincode = '';
            this.contactDetail.emailId = '';
            this.contactDetail.alternateEmailId = '';
            this.contactDetail.city = '';
            this.contactDetail.state = '';
            this.contactDetail.countryId = '';
            this.contactDetail.country = '';
        },
    }

}
</script>