<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="empAttendanceTimelinePopupRef" size="lg" modal-class="" title="Employee Work Timeline" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe">
                    <b-table show-empty small outlined stacked="md"  sort-icon-left :items="attendanceTimelineList" :fields="attendanceTimelineFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(empTimelineStateEnum)="data">
                            <span v-if="data.value">{{ getTimelineState(data.value) }}</span>
                        </template>
                        <template v-slot:cell(empTimelineDt)="data">
                            <span v-if="data.value">{{ data.value | formatDateTimeAmPm }}</span>
                        </template>
                        <template v-slot:cell(nextEmpTimelineDt)="data">
                            <span v-if="data.value">{{ data.value | formatDateTimeAmPm }}</span>
                        </template>     
                        <template v-slot:cell(timeDiff)="data">
                            <span >{{ getTimeDiff(attendanceTimelineList[data.index].empTimelineDt,attendanceTimelineList[data.index].nextEmpTimelineDt, false) }}</span>
                        </template>                        
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import enummixin from "@/mixins/enummixin";
export default {
    mixins: [enummixin],
    props: {
        id:{type:String, default:'empAttendanceTimelineDetPopup'},
    },
    data() {
        return {
            loading: true,
            vhextAccountId: localStorage.getItem("vhextAccountId"),

            attendanceTimelineList: [],
            attendanceTimelineFields: [
                { key: 'index', label: 'SN' },
                { key: 'empTimelineStateEnum', label: 'Timeline State', sortable: true, sortDirection: 'desc' },
                { key: 'empTimelineDt', label: 'From', sortable: true, sortDirection: 'desc' },
                { key: 'nextEmpTimelineDt', label: 'Till', sortable: true, sortDirection: 'desc' },
                { key: 'timeDiff', label: 'Time Diff (HH:MM)', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 0,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
        }
    },
    methods: {
        getDetails: function(employeeAttendanceId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                employeeAttendanceId: employeeAttendanceId,
            }
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            });
            this.loading = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empattendancetimeline/list', data)
                .then((response) => {
                    this.loading = false;
                    let result = response.data;
                    this.attendanceTimelineList = result.empAttendanceTimelineList;
                    this.totalRows = this.attendanceTimelineList.length;
                })
                .catch(error => {
                    this.loading = false;
                })
        },
        getTimelineState(id) {
            let result = this.empTimelineStateList.find(data => data.id == id);
            return result ? result.value : '';
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>