<template>
    <div>
        <b-modal ref="masterStatusMapModal" size="md" id="masterStatusMapModal" @hide="clearData" header-class="py-3" body-class="pt-3" footer-class="py-3 px-3" :title="'Document Copy - ' + (masterData.title ? masterData.title : '')" :no-close-on-backdrop="true" >
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <!-- <button type="button" @click="hide()" aria-label="Close" class="close float-right mt-2">×</button> -->
                <row>
                    <b-col cols="6">
                        <label for="company">Status Type</label>
                        <div class="required-field-block" :class="{ 'is-invalid': $v.masterStatusMapData.entityId.$error }">
                            <select v-model="masterStatusMapData.entityId" @change="getUserLookup(masterStatusMapData.entityId)" :class="{ 'is-invalid': $v.masterStatusMapData.entityId.$error }" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in entities" :value="info.id">{{info.value}}</option>
                            </select>
                            <div class="required-icon"></div>
                        </div>
                        <label v-if="$v.masterStatusMapData.entityId.$error && !$v.masterStatusMapData.entityId.required" class="text-danger">Please select a target project</label>
                    </b-col>
                    <b-col v-if="masterStatusMapData.entityId" cols="6">
                        <label for="company">User</label>
                        <div class="required-field-block" :class="{ 'is-invalid': $v.masterStatusMapData.masterStatusId.$error }">
                            <select v-model="masterStatusMapData.masterStatusId" :class="{ 'is-invalid': $v.masterStatusMapData.masterStatusId.$error }" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                            </select>
                            <div class="required-icon"></div>
                        </div>
                        <label v-if="$v.masterStatusMapData.masterStatusId.$error && !$v.masterStatusMapData.masterStatusId.required" class="text-danger">Please select a target User</label>
                    </b-col>
                </row>
            </b-overlay>
<!--             <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="ermCopy" class="float-right py-1 px-3 mr-2">Copy</b-button>
                    <b-button variant="secondary" :disabled="showOverlay" size="sm" class="float-right py-1 px-3 mr-2" @click="cancel()">Cancel</b-button>
                </div>
            </template> -->
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    props: {
        masterData: Object,
        // ermId: Number,
        entity: String,
        // erdErmStatusMapModalTitle: String
    },
    components: {
        Multiselect
    },
    data() {
        return {
            showOverlay: false,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            masterStatusMapData: {
                id: '',
                entity: '',
                userId: localStorage.getItem("userId"),
                toVhextAccountId: localStorage.getItem("vhextAccountId"),
                entityId: '',
                masterStatusId: '',
            },

            entities: [],
            statuses: [],
        }
    },
    validations: {
        masterStatusMapData: {
            entityId: {
                required
            },
            masterStatusId: {
                required
            }
        }
    },
    watch: {
        // tabIndex(val) {
        //     if (val == 0) {
        //         this.masterStatusMapData.toVhextAccountId = localStorage.getItem("vhextAccountId");
        //         this.masterStatusMapData.entityId = '';
        //         this.masterStatusMapData.masterStatusId = '';
        //         this.getProjectLookup(this.masterStatusMapData.toVhextAccountId);
        //     }
        // }
    },
    // computed: {
    //     isSameUser() {
    //         return this.masterStatusMapData.vhextAccountId == this.masterStatusMapData.toVhextAccountId && this.userId == this.masterStatusMapData.masterStatusId;
    //     }
    // },
    mounted() {
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/status/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entities = result.entityLookup;
                    this.statuses = result.statusLookup;
                    // this.statuses = result.userLookup;
                })
        },
        // getProjectLookup: function(vhextAccountId) {
        //     let data = {
        //         vhextAccountId: vhextAccountId
        //     }
        //     this.masterStatusMapData.entityId = '';
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/lookup/project', data)
        //         .then((response) => {
        //             this.showOverlay = false;
        //             let result = response.data;
        //             this.statuses = result.projectLookup;
        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        getUserLookup: function(entityId) {
            let data = {
                vhextAccountId: vhextAccountId,
                projectId: projectId,
                entityId: entityId,
            }
            this.masterStatusMapData.masterStatusId = '';
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/status/lookup/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.statuses = result.statusLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        // ermCopy: function() {
        //     this.$v.masterStatusMapData.$touch();
        //     if (this.$v.masterStatusMapData.$invalid) {
        //         return;
        //     }
        //     if (this.isSameUser) {
        //         this.masterStatusMapData.statusLookupId = 2;
        //     }
        //     this.showOverlay = true;
        //     this.masterStatusMapData.entityRecordMasterId = this.masterData.refErmId;
        //     this.masterStatusMapData.title = this.masterData.title;
        //     this.masterStatusMapData.entity = this.masterData.refEntity;

        //     console.log(this.masterStatusMapData);
        //     axios.post(this.$store.getters.getAPIBasePath + '/common/ermcopy/doc/create', this.masterStatusMapData)
        //         .then((response) => {
        //             let result = response.data;
        //             console.log(response.data.status);
        //             this.showOverlay = false;
        //             if (result.status == "CREATED") {
        //                 this.$bvModal.hide('erdErmStatusMapModal');
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: (this.isSameUser ? 'Document Copied' : result.message),
        //                     position: 'center'
        //                 });
        //             }
        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        clearData: function() {
            this.masterStatusMapData.entityId = '';
            this.masterStatusMapData.masterStatusId = '';
            this.$v.masterStatusMapData.$reset();
        },
        onRefDataChange: function() {
            // this.masterStatusMapData.vhextAccountId = this.vhextAccountId;
            // this.masterStatusMapData.projectId = this.projectId;
            // this.masterStatusMapData.createdById = this.userId;
            // this.masterStatusMapData.id = this.masterData.refErmId; 
            // console.log(this.masterStatusMapData);
        },

    }
}
</script>