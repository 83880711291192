<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Employee Time Sheet Submission Stats</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
                                        <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <div class="landing-menu-switch pointer" v-b-toggle.accordion-ett>
                                <label v-if="elements.filterVisible" class="my-2 py-1">Hide</label>
                                <label v-else class="my-2 py-1">Filter</label>
                                <!-- <i class="fa fa-filter my-2" aria-hidden="true"></i> -->
                            </div>
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row m-0">
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>From Date</label>
                                                    <input type="date" class="form-control" v-model="fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label>Till Date</label>
                                                    <input type="date" class="form-control" v-model="tillDate">
                                                </div>
                                            </div>
                                            <!-- <div class="col-3">
                                                <div class="form-group">
                                                    <label for="employee">Employee</label>
                                                    <select v-model="employeeId" class="form-control" >
                                                        <option value="">All Employees</option>
                                                        <option v-for="(info, index) in employees" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div class="col-3">
                                                <div class="form-group">
                                                    <label for="preparedBy">Company</label>
                                                    <select v-model="companyId" class="form-control" >
                                                        <option value="">All Companies</option>
                                                        <option v-for="(info, index) in corpCompanies" :value="info.id" :key="index">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <button type="submit" @click="getEmployees" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div class="col-12 col-md-4">
                                                            <div class="row">
                                                                <div class="col-12 col-md-4 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Period</label>
                                                                </div>
                                                                <div class="col-12 col-md-8 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ formatDate(startDate) }} <span class="px-2">To</span> {{ formatDate(endDate) }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Company</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ company }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div v-if="employeeId" class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Employee</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ employee }}</label>
                                                                </div>
                                                            </div>
                                                        </div> -->

                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title"><span>Employee Time Sheet Submission Stats</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">
                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="employeeUtilizationSummary" :fields="employeeFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(performance)="data">
                                                        <span title="Attendance Percentage">{{ data.item.performance }} %</span>

<!--                                                         <b-progress class="mx-3" height="10px" :value="data.item.performance" :variant="performanceVariant(data.item.performance)"></b-progress> -->
                                                        <b-progress class="mx-3" height="10px" :value="data.item.performance" variant="success"></b-progress>                                                        
                                                        <div>{{ getEmpTimeSheetComplianceStatus(data.item.performance) }}</div>
                                                    </template>
                                                    <template v-slot:empty="erm">
                                                        <div class="text-center">No Record Found</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            parentVhextAccountId: localStorage.getItem("parentVhextAccountId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            fromDate: this.getDateValue(new Date()),
            tillDate: this.getDateValue(new Date()),
            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),
            companyId: localStorage.getItem("vhextAccountId"),
            employeeId: '',
            statusId: '',

            isBusy: false,
            corpCompanies: [],
            employees: [],
            employeeStatuses: [],

            empTimeSheetSubmissionStatsList: [],
            employeeFields: [
                { key: 'index', label: 'SN' },
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Employee Name', sortable: true, sortDirection: 'desc' },
                { key: 'cmpShortName', label: 'Company', sortable: true, sortDirection: 'desc' },
                { key: 'totalEmpPresentDays', label: 'Employee Present (days)', sortable: true, sortDirection: 'desc' },
                { key: 'tsSubmissionEmpCount', label: 'Time Sheet Submission (days)', sortable: true, sortDirection: 'desc' },
                { key: 'tsNonSubmissionEmpCount', label: 'Time Sheet Non Submission (days)', sortable: true, sortDirection: 'desc' },
                { key: 'performance', label: 'Time Sheet Compliance (%)', sortable: true },
                // { key: 'totalSecondHalfLeave', label: 'Leave Second Half' },
                // { key: 'totalFullDayLeave', label: 'Full Day Leave' },                
                // { key: 'attendancePercentage', label: 'Attendance Performance'},
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        employeeUtilizationSummary() {
            let filteredItems = this.empTimeSheetSubmissionStatsList;
            if (this.companyId) {
                filteredItems = filteredItems.filter(data => data.vhextAccountId == this.companyId)
            }
            if(this.employeeId){
                return filteredItems.filter(item => item.empId && item.empId == this.employeeId);
            }else{
                return filteredItems.filter(item => item.empId);
            }
        },
        company() {
            if (this.corpCompanies.length>0) {
                return this.companyId ? this.corpCompanies.find(data => data.id == this.companyId).value : 'All Companies';
            }
        },
        employee() {
            if (this.employees.length>0) {
                return this.employeeId ? this.employees.find(data => data.id == this.employeeId).value : '';
            }
        },

    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
        this.getEmployees();
    },
    methods: {
        getEmployees: function() {
            let data = {
                parentVhextAccountId: this.parentVhextAccountId,
                startDate: this.fromDate,
                endDate: this.tillDate,
                statusId: this.statusId,
            }
            this.startDate = this.fromDate;
            this.endDate = this.tillDate;
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/corporate/emptimesheetsubmission/stats', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.empTimeSheetSubmissionStats != null && result.empTimeSheetSubmissionStats.length > 0) {
                        this.empTimeSheetSubmissionStatsList = result.empTimeSheetSubmissionStats;
                        this.totalRows = this.empTimeSheetSubmissionStatsList.length;
                        console.log(this.empTimeSheetSubmissionStatsList);
                    }

                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                parentVhextAccountId: this.parentVhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/corporate/employeeattendance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.corpCompanies = result.corpGroupCompanyLookup;
                    this.employeeStatus = result.employeeStatusLookup;
                })
        },
        empAttendeesPercentage(data) {
            let totalCount = data.totalPresent + data.totalFirstHalfLeave + data.totalSecondHalfLeave + data.totalFullDayLeave;
            let empTotalPresent = data.totalPresent;
            let empTotalHalfDayLeave = data.totalFirstHalfLeave + data.totalSecondHalfLeave;

            return parseFloat(totalCount ? (((empTotalPresent+(empTotalHalfDayLeave/2))/totalCount) * 100).toFixed(1) : 0);
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.fromDate = this.getDateValue(this.startDate);
            this.tillDate = this.getDateValue(this.endDate);
        },
        clearData: function() {    
            this.startDate = this.getDateValue(new Date());
            this.endDate = this.getDateValue(new Date());
            this.fromDate = this.getDateValue(new Date());
            this.tillDate = this.getDateValue(new Date());
            this.getEmployees();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },
        getEmpTimeSheetComplianceStatus(value) {
            if(value == 0) {
                return 'Non-Compliant';
            }
            else if(value < 100) {
                return 'Partially Compliant';
            }
            else {
                return 'Compliant';
            }
        }
    }
}
</script>