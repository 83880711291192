<template>
    <div>
        <FinancialTxnMasterList :ermMasterInfo="ermMasterInfo"></FinancialTxnMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FinancialTxnMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        FinancialTxnMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showFinancialTxnMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Financial Transaction Group List",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
                tooltip: 'New Financial Transaction Group',
                redirectURLName: 'financialtxn',
                newEntityMasterURL: 'ftxmas',
                redirectURL: '/ftxmas',
                actionName: '',
                previewURLName: 'ftxrep',
                showTypeLookup: true,
                showSingleMasterControl: true,
                masterLinkTitle: 'Link Budget Plan',
                masterLinkEntity: 'budget_plan_master',
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURLName = 'srvfinancialtxn';
            this.ermMasterInfo.newEntityMasterURL = 'srvftxmas';
            this.ermMasterInfo.redirectURL = '/service/ftxmas';
            this.ermMasterInfo.actionName = '';
            this.ermMasterInfo.previewURLName = 'srvpftrep';
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURLName = 'clifinancialtxn';
            this.ermMasterInfo.newEntityMasterURL = 'cliftxmas';
            this.ermMasterInfo.redirectURL = '/client/ftxmas';
            this.ermMasterInfo.actionName = '';
            this.ermMasterInfo.previewURLName = 'cliftxrep';
        } else {
            this.ermMasterInfo.redirectURLName = 'financialtxn';
            this.ermMasterInfo.newEntityMasterURL = 'ftxmas';
            this.ermMasterInfo.redirectURL = '/ftxmas';
            this.ermMasterInfo.actionName = '';
            this.ermMasterInfo.previewURLName = 'ftxrep';


        }
    },
    methods: {}
}
</script>