<template>
    <div>
        <UserRequirementMaster :ermMasterInfo="ermMasterInfo"></UserRequirementMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import UserRequirementMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        UserRequirementMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Client Requirement Doc",
                entity: 'user_requirement_master',
                refEntity: 'user_requirement',
                redirectURL: '/urqmas/list/',
                erdRedirectURL: '/userrequirement/'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/urqmas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/urqmas/list';
        }
    },
    methods: {}
}
</script>