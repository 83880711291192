<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-md-6 col-sm-8">
                                <h4 class="pl-2 pt-2 mb-0 fs-16">Leave & Time Calendar</h4>
                            </div>
                            <div class="col-md-5 col-sm-4 text-right mb-1">
                                <template v-if="isWorkflowInitiator">
                                    <b-button class="addnew-tassk-btn float-right" variant="outline-primary" @click="showLeaveTimePopup('PER')" size="sm"><i class="fa fa-clock-o pr-1"></i>Apply Permission</b-button>
                                    <b-button class="addnew-tassk-btn float-right mr-2" variant="outline-primary" @click="showLeaveTimePopup('LVE')" size="sm"><i class="fa fa-calendar-o pr-1"></i>Apply Leave</b-button>
                                </template>
                            </div>
                            <div class="col-md-1 col-sm-12">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-2 mb-0">
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-xl-8">
                        <b-overlay :show="showOverlay" opacity="0.4">
                            <div class="card mb-4">
                                <div class="card-body">
                                    <fullcalendar ref="evtCalendar" :key="calendarEventKey" :calendarEvents="calendarEvents" @event-selected="eventSelected" />
                                </div>
                            </div>
                            <template #overlay>
                                <div class="text-center">
                                    <LoadingSpinner />
                                </div>
                            </template>
                        </b-overlay>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                        <div class="card h-100">
                            <div class="card-body p-3">
                                <b-overlay :show="isBusy" spinner-variant="primary" opacity="0.4" rounded="sm">
                                    <b-tabs content-class="mt-3" lazy>
                                        <b-tab title="Leaves">
                                            <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-right">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="leaveList" :fields="leaveFields" responsive="sm" :current-page="currentPageLeave" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredLeave">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>                                                                            
                                                <template v-slot:cell(title)="data">
                                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                </template>
                                                <template v-slot:cell(start)="data">
                                                    <div>
                                                        <template v-if="data.item.end">
                                                            {{ formatDateYearCheck(data.value) }} to {{ formatDateYearCheck(data.item.end) }}
                                                        </template>
                                                        <template v-else>
                                                            {{ formatDate(data.item.start) }}
                                                        </template>
                                                    </div>
                                                    <div>
                                                        {{ data.item.totalDays ? parseFloat(data.item.totalDays) : 0 }} Day(s)
                                                    </div>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <template v-if="data.item.status == 'Approved'">
                                                        -
                                                    </template>
                                                    <template v-else>
                                                        <a href="javascript:" title="Edit" @click="showLeaveTimeEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                        <a href="javascript:" title="Delete" @click="deleteLeaveTime(data.item.id)" v-b-modal.deleteLeaveTime><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No leaves found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>

                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsLeave}} Items</span>                                                                                                 
                                            <b-pagination v-if="leaveList.length!=0" pills v-model="currentPageLeave" :total-rows="totalRowsLeave" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        </b-tab>
                                        <b-tab title="Permissions">
                                            <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-right">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="permissionList" :fields="perimissionFields" responsive="sm" :current-page="currentPagePer" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredPer">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(title)="data">
                                                    <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                                </template>
                                                <template v-slot:cell(start)="data">
                                                    <div>
                                                        {{ formatDateYearCheck(data.value) }} | {{ data.item.start | formatTimeAmPm }} to {{ data.item.end | formatTimeAmPm }}
                                                    </div>
                                                    <div>
                                                        {{ data.item.totalDays ? parseFloat(data.item.totalDays) : 0 }} hour(s)
                                                    </div>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <template v-if="data.item.status != 'Applied'">
                                                        -
                                                    </template>
                                                    <template v-else>
                                                        <a href="javascript:" title="Edit" @click="showLeaveTimeEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                        <a href="javascript:" title="Delete" @click="deleteLeaveTime(data.item.id)" v-b-modal.deleteLeaveTime><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No permissions found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>

                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsPer}} Items</span>                                                                                                 
                                            <b-pagination v-if="permissionList.length!=0" pills v-model="currentPagePer" :total-rows="totalRowsPer" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        </b-tab>
                                        <b-tab title="Holidays">
                                            <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-right">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="holidayList" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>                                                                                                                   
                                                <template v-slot:cell(startDate)="data">
                                                    <template v-if="data.item.start">
                                                        <!-- <div v-if="data.item.allDay && data.item.daysOfWeek">{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div> -->
                                                        <!-- <div v-else-if="data.item.daysOfWeek && data.item.startTime">{{ combineDateAndTime(data.item.startRecur,(data.item.startTime).substr(0,5)) | formatDate }}</div> -->
                                                        <div v-if="data.item.allDay">{{ data.item.start | formatDate }}</div>
                                                        <div v-else>{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div>
                                                    </template>
                                                    <template v-else-if="data.item.startRecur">
                                                        <div v-if="data.item.allDay && data.item.daysOfWeek">
                                                            <span v-for="day,index in (data.item.daysOfWeek)"><span v-if="index!==0">,</span> {{ days[day] }}</span>
                                                            ({{ data.item.startRecur | formatMonthYear }} - {{ data.item.endRecur | formatMonthYear }})
                                                        </div>
                                                        <!-- <div v-else-if="data.item.daysOfWeek && data.item.startTime">{{ combineDateAndTime(data.item.startRecur,(data.item.startTime).substr(0,5)) | formatDate }}</div> -->
                                                        <!-- <div v-else-if="data.item.isAllDayEvent">{{ data.item.start | formatDate }}</div>
                                                        <div v-else>{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div> -->
                                                    </template>
                                                    <template v-else>
                                                        <div>Weekend</div>
                                                    </template>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <!-- <a href="javascript:" title="Edit" @click="showLeaveTimeEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a> -->
                                                    <a href="javascript:" title="Delete" @click="deleteCalendarEvent(data.item.id)" v-b-modal.deleteCalendarEvent><i class="fa fa-trash pl-2"></i></a>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Holidays Found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>

                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                                                                                 
                                            <b-pagination v-if="holidayList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        </b-tab>
                                    </b-tabs>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <LeaveTimePopup v-if="showDialog" ref="leaveTimePopupRef" @refresh-leavetime="getEvents" :leaveTypeCode="leaveTypeCode" :isWorkflowInitiator="isWorkflowInitiator" :hasWorkflowConfig="hasWorkflowConfig" :humanResourceData="humanResourceData" @closeDialog="showDialog = false" />
        <DeleteComponent id="deleteCalendarEvent" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Holiday?" />
        <DeleteComponent id="deleteLeaveTime" :onYes="onYesLeaveTime" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Request?" />
        <LeaveTimeDetailPopup v-if="showDialog" ref="leaveTimeDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import LeaveTimePopup from '@/components/popup/action/LeaveTimePopup.vue';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue';


export default {
    components: {
    	Multiselect,
    	Editor,
    	ERMMasterPopup,
        LeaveTimeDetailPopup,
        LeaveTimePopup,
    },
    data() {
        return {
        	showDialog: false,
        	isBusy: false,
        	elements: {
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
        	showDialog: false,
            notEnabled: true,

        	calendarEventKey: 0,
            showOverlay: false,
            activeTabIndex: 0,

            minPeriod: '',

            isWorkflowInitiator: 0,
            hasWorkflowConfig: 0,
            leaveTypeCode: 'LVE',
            // calendarEvent: this.calendarEventData,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: "calendar_event",

            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                calendarTypeId: 1,
                eventTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                daysToRepeat: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: '',
                participantId: '',
                eventParticipant: []
            },

            days: dayList,
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            entityERMLookup: [],

            attachmentData: {},
            referencePopupId: 0,

            humanResourceData: {},

            leaveTimeList: [],

            perimissionFields: [
                { key: 'index', label: 'SN' },            
                { key: 'title', label: 'Subject', sortable: true, sortDirection: 'desc' },
                { key: 'start', label: 'Permission Date', sortable: true, sortDirection: 'desc' },
                { key: 'status',label: 'Status', sortable: true,sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            leaveFields: [
                { key: 'index', label: 'SN' },            
                { key: 'title', label: 'Subject', sortable: true, sortDirection: 'desc' },
                { key: 'start', label: 'Leave Date', sortable: true, sortDirection: 'desc' },
                { key: 'status',label: 'Status', sortable: true,sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            holidays: [],
            fields: [
                { key: 'index', label: 'SN' },            
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'startDate',label: 'Date', sortable: true,sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],

            deleteParam: {
                id: 0
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,

            totalRowsLeave: 1,
            currentPageLeave: 1,

            totalRowsPer: 1,
            currentPagePer: 1,

            perPage: 10,
            sortDesc: false,
            pageOptions: [10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
    	events() {
            return this.holidays.map((event,index)=> {
                if (event.categoryCode == 'WKD') {
                    event.display = "background";
                    event.color =  "#dddddd";
                    event.textColor =  "black";
                    event.classNames = 'pt-4'

                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    if (typeof event.daysOfWeek === 'string') {
                        event.daysOfWeek = event.daysOfWeek.split(',');
                    }
                }
                else if (event.categoryCode == 'HOL') {
                     if (event.allDay) {
                        // event.display = 'background';
                        // event.display = 'list-item';
                        event.color = '#FF4500';
                        // event.color = '#353f37'; 
                    }
                    else {
                        event.display = 'block';
                        // event.color = 'blue';
                        event.classNames = 'bg-secondary';
                        // event.textColor = 'black'
                    }
                    // extendedProps: {
                    //     department: 'BioChemistry'
                    //   }
                }
                else if (event.status == 'Applied') {
                    event.display = 'block';
                    // event.color = 'blue';
                    // event.backgroundColor = '#7aee9c';
                    event.classNames = 'bg-warning';
                    // event.textColor = 'black'
                }
                else if (event.status == 'Approved') {
                    event.display = 'block';
                    event.classNames = 'bg-success';
                }
                else if (event.status == 'Rejected') {
                    event.display = 'block';
                    event.classNames = 'bg-danger';
                }
                else {
                    // if (event.allDay && event.end && event.start != event.end) {
                    //     let date = new Date(event.end);
                    //     date.setDate(date.getDate() + 1);
                    //     event.end = date;
                    // }
                    event.display = 'block';
                    // event.color = 'blue';
                    event.classNames = 'bg-info';
                    // event.backgroundColor = '#93C572';
                    // event.textColor = 'black'
                }
                return event;
            })
        },
        // eventRender: function(info) {
        //     var tooltip = new Tooltip(info.el, {
        //       title: info.event.extendedProps.description,
        //       placement: 'top',
        //       trigger: 'hover',
        //       container: 'body'
        //     });
        // },
        calendarEvents() {
            if (!this.events) return [];

            let events = JSON.parse(JSON.stringify(this.events));
            return events.map((event,index)=> {
                if (event.allDay && event.start && event.end && !this.isSameDate(event.start, event.end)) {
                    event.end = new Date(event.end);
                    (event.end).setDate((event.end).getDate() + 1);
                }
                return event;
            });
        },
        holidayList() {
        	return this.events.filter(event => event.categoryCode == 'HOL')
        },
        leaveList() {
            return this.leaveTimeList.filter(item => item.leaveTypeCode == 'LVE')
        },
        permissionList() {
            return this.leaveTimeList.filter(item => item.leaveTypeCode == 'PER')
        },
    },
    mounted() {
        this.getEvents();
        // this.getLeaveTimeList();
        this.getEmpDetail();
    },
    validations: {
        calendarEvent: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
    },
    methods: {
    	getEvents: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                createdById: this.userId,
                refEntity: 'leave_time',
                calendarTypeId: 1
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendar/list', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    console.log(result);
                    this.holidays = result.leaveTimeList ? result.companyCalendarList.concat(result.leaveTimeList) : result.companyCalendarList;
                    console.log(this.holidays);
                    if (result.leaveTimeList) {
                        this.leaveTimeList = result.leaveTimeList;
                    }
                    this.isWorkflowInitiator = result.isWorkflowInitiator;
                    this.hasWorkflowConfig = result.hasWorkflowConfig;
                    this.totalRows = this.holidayList.length;
                    this.totalRowsLeave = this.leaveList.length;
                    this.totalRowsPer = this.permissionList.length;
                    this.calendarEventKey++;
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLeaveTimeList: function() {
            // let data = {
            //     vhextAccountId: this.vhextAccountId,
            //     userId: this.userId
            // }
            // this.isBusy = true;
            // axios.post(this.$store.getters.getAPIBasePath + '/resource/leavetime/list', data)
            //     .then((response) => {
            //         this.isBusy = false;
            //         let result = response.data;
            //         this.leaveTimeList = result.leaveTimeList;
            //         this.totalRowsLeave = this.leaveList.length;
            //         this.totalRowsPer = this.permissionList.length;
            //     })
            //     .catch(error => {
            //         console.log(error)
            //         this.isBusy = false;
            //         iziToast.error({
            //            title: 'Error',
            //            message: 'Error',
            //            position: 'topRight'
            //        });
            //     })
        },
        deleteCalendarEvent: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
        	this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/calendarsetting/delete/' + this.deleteParam.id)
                .then(response => {
		        	this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                        this.$emit('refreshData');
                    }
                })
                .catch(error => {
		        	this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteLeaveTime: function(id) {
            this.deleteParam.id = id;
        },
        onYesLeaveTime: function() {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/leavetime/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredLeave(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsLeave = filteredItems.length
            this.currentPageLeave = 1
        },
        onFilteredPer(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsPer = filteredItems.length
            this.currentPagePer = 1
        },
        showLeaveTimeEdit: function(data) {
            this.showDialog = true;
            this.leaveTypeCode = data.leaveTypeCode;
            this.$nextTick(()=> {
                this.$bvModal.show('leaveTimePopup');
            })
            this.$nextTick(() => {
                this.$refs.leaveTimePopupRef.showEdit(data.id, data.leaveTimeCalendarId);
            })
        },
        clearData: function() {
            this.calendarEvent.title = '';
            this.calendarEvent.isAllDayEvent = false;
            this.calendarEvent.daysOfWeek = [];
            this.calendarEvent.daysToRepeat = [];
            this.calendarEvent.eventDate = this.getDateValue(new Date());
            this.calendarEvent.startDate = '';
            this.calendarEvent.endDate = '';
            this.calendarEvent.eventStartDate = this.getDateValue(new Date());
            this.calendarEvent.eventEndDate = this.getDateValue(new Date());
            this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
            this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
            this.calendarEvent.statusId = '';
            this.calendarEvent.hostedById = '';
            this.calendarEvent.link = '';
            this.calendarEvent.location = '';
            this.calendarEvent.description = '';
            this.calendarEvent.eventTypeId = '';
            this.calendarEvent.eventFrequency = 0;
            this.calendarEvent.eventParticipantList = '';
            this.calendarEvent.eventParticipant = '';
            this.showOverlay = false;
            this.$v.calendarEvent.$reset();
        },
        getEmpDetail() {
            this.showOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/userid/' + this.userId)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.humanResourceView) {
                    this.humanResourceData = result.humanResourceView;
                }
            })
            .catch(error => {
                this.showOverlay = false;
                // console.log(error);
            });
        },
        eventSelected: function(event) {
            let calendarProps = event.extendedProps;
            // this.getParticipantList(calendarProps.eventId);
            if (calendarProps.categoryCode == 'WKD' || calendarProps.categoryCode == 'HOL') {

            }
            else {
                this.viewItemDetails(event);
            }
        },
        showLeaveTimePopup(leaveTypeCode) {
            this.showDialog = true;
            this.leaveTypeCode = leaveTypeCode;
            this.$nextTick(()=> {
                this.$bvModal.show('leaveTimePopup');
            })
        },
        viewItemDetails(data) {
            this.referencePopupId = parseInt(data.id);
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.leaveTimeDetailPopupRef.getDetails(this.referencePopupId, 'leave_time');
            })
        },
        closeDialog: function() {
            this.showDialog = false;
        },
    }
}
</script>