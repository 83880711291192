<template>
    <div class="referenceDetailPopup">
        <b-modal v-if="showModal" id="referenceDetail" ref="referenceDetailRef" size="lg" modal-class="referenceDetailRef" :title="refPopupTitle">
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div v-if="refEntity==='requirement_spec'" class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <h6 class="text-left ml-3 mt-0 mr-0 mb-3">Requirement Spec</h6>
                            <div class="col-lg-12 col-xl-12">
                                <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                    <div class="float-right">
                                        <h6 class=""><label class="badge badge-secondary" title="Status">{{ requirementSpec.status }}</label></h6>
                                    </div>
                                </div>
                                <div class="col-9 col-sm-9 mb-3 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ requirementSpec.requirementSpecId }}</label>
                                        </div>
                                        <div class="col-10 pl-0">
                                            <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ requirementSpec.title }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mb-2">
                                        <div class="col-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                        </div>
                                        <div class="col-9 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{requirementSpec.type}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mt-0 mb-2">
                                        <div class="col-12 col-md-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                        </div>
                                        <div class="col-12 col-md-9 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{requirementSpec.preparedBy}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title mb-2"><span>Requirement Specification</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="requirementSpec.requirementSpecification"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{requirementSpec.preparedDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Due Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{requirementSpec.dueDate | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Priority</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ requirementSpec.priority }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="refEntity==='user_requirement'" class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <h6 class="text-left ml-1 mt-0 mr-0 mb-3">User Requirement</h6>
                            <div class="col-lg-12 col-xl-12 px-1">
                                <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                    <div class="float-right">
                                        <h6 class=""><label class="badge badge-secondary" title="Status">{{ userRequirement.status }}</label></h6>
                                    </div>
                                </div>
                                <div class="col-9 col-sm-9 mb-3 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ userRequirement.userRequirementId }}</label>
                                        </div>
                                        <div class="col-10 pl-0">
                                            <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ userRequirement.title }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mb-2">
                                        <div class="col-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                        </div>
                                        <div class="col-9 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{userRequirement.type}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mt-0 mb-2">
                                        <div class="col-12 col-md-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Requirement By</label>
                                        </div>
                                        <div class="col-12 col-md-9 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{userRequirement.userRequirementBy}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title mb-2"><span>User Requirement</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details">{{userRequirement.userRequirement}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{userRequirement.preparedDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Due Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{userRequirement.dueDate | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Priority</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ userRequirement.priority }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="refEntity==='test_result' || refEntity==='test_case'" class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12">
                                <div class="row">
                                    <div class="col-9 col-sm-9 mb-3 px-0">
                                        <div class="row mb-0">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ testCase.testCaseId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ testCase.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-sm-3">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ testCase.status }}</label></h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mb-0">
                                            <div class="col-3 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{testCase.type}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="testCase.testedBy !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Tested By</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{testCase.testedBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="testCase.userName !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{testCase.userName}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Test Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="testCase.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Expected Result</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="testCase.expectedResult"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="testCase.remarks !== ''" class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-3">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Remarks</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="testCase.remarks"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="testCase.testDate !== ''" class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Tested Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{testCase.testDate | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="testCase.preparedDt !== ''" class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{testCase.preparedDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="refEntity==='issue'" class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12 px-1">
                                <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                    <div class="float-right">
                                        <h6 class=""><label class="badge badge-secondary" title="Status">{{ issue.status }}</label></h6>
                                    </div>
                                </div>
                                <div class="col-9 col-sm-9 mb-3 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ issue.issueId }}</label>
                                        </div>
                                        <div class="col-10 pl-0">
                                            <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ issue.title }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="issue.type" class="col-12 col-sm-12 px-0">
                                    <div class="row mb-2">
                                        <div class="col-3 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                        </div>
                                        <div class="col-9 pl-0">
                                            <label class="p-2 mb-0 d-block header-details">{{issue.type}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Functionality</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{issue.functionality}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{issue.activity}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reported By</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{issue.reportedBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Solution By</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{issue.solutionBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-0">
                                    <div class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Issue Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="issue.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Solution</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="issue.solution"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title mb-2"><span>Action Taken</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details">{{userRequirement.actionTaken}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reported Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{issue.reportedDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Solution Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{issue.solutionDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Severity</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ issue.severity }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="refEntity==='action_item'" class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <h6 class="text-left ml-3 mt-0 mr-0 mb-3">Action Item</h6>
                            <div class="col-lg-12 col-xl-12">
                                <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                    <div class="float-right">
                                        <h6 v-if="actionItem.status && actionItem.status != null"><label class="badge badge-secondary" title="Status">{{ actionItem.status }}</label></h6>
                                    </div>
                                </div>
                                <div class="col-9 col-sm-9 mb-3 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ actionItem.actionItemId }}</label>
                                        </div>
                                        <div class="col-10 pl-0">
                                            <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ actionItem.actionItemTitle }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="actionItem.actionItemBy !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Action By</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{actionItem.actionItemBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="actionItem.actionItemTo !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Action To</label>
                                            </div>
                                            <div class="col-12 col-md-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{actionItem.actionItemTo}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{actionItem.actionItemDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Due Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{actionItem.actionItemDueDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="refEntity==='MTG'" class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12">
                                <div class="row">
                                    <div class="col-9 col-sm-9 mb-3 px-0">
                                        <div class="row mb-0">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ meeting.calendarEventId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ meeting.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-sm-3">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ meeting.status }}</label></h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="meeting.createdBy !== ''" class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Organized By</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{meeting.createdBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="meeting.participant !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Participant</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{meeting.participant}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="meeting.eventDate !== ''" class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Event Date</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{meeting.eventDate | formatDate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="meeting.start !== ''" class="col-6 col-sm-6 pl-0 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Time</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{meeting.start | formatDateTimeAmPm}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="meeting.end !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">End Time</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{meeting.end | formatDateTimeAmPm}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        refPopupTitle: String,
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            showModal: false,
            loading: true,
            requirementSpec: {
                id: '',
                requirementSpecId: '',
                title: '',
                requirementSpecification: '',
                status: '',
                type: '',
                preparedBy: '',
                preparedDt: '',
                dueDate: '',
                priority: '',
            },
            userRequirement: {
                id: '',
                userRequirementId: '',
                title: '',
                userRequirement: '',
                status: '',
                type: '',
                userRequirementBy: '',
                preparedDt: '',
                dueDate: '',
                priority: '',
            },
            testCase: {
                id: '',
                testCaseId: '',
                title: '',
                description: '',
                expectedResult: '',
                status: '',
                type: '',
                testedBy: '',
                userName: '',
                preparedDt: '',
                testDate: '',
                remarks: ''
            },
            actionItem: {
                id: '',
                actionItemId: '',
                actionItemTitle: '',
                actionItemDt: '',
                actionItemDueDt: '',
                actionItemBy: '',
                actionItemTo: '',
                status: '',
            },
            meeting: {
                title: '',
                calendarEventId: '',
                eventDate: '',
                start: '',
                end: '',
                status: '',
                createdBy: '',
                participant: '',
            },
            issue: {
                id: '',
                issueId: '',
                title: '',
                type: '',
                functionality: '',
                activity: '',
                description: '',
                solution: '',
                reportedBy: '',
                reportedDt: '',
                solutionBy: '',
                solutionDt: '',
                actionTaken: '',
                severity: '',
                status: '',
            },
            uatTestCase: {
                
            },
            requirementSpecs: [],
            userRequirements: [],
            tasks: [],
            testCases: [],
            uatTestCases: [],
        }
    },
    methods: {
        getRequirementSpecs: function(id, refEntity) {
            this.loading = true;
            console.log(id, refEntity);
            this.requirementSpecs = [];
            this.uatTestCases = [];
            this.userRequirements = [];
            this.testCases = [];   
            this.tasks = [];         
            if (refEntity == 'requirement_spec') {
                axios.get(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            console.log(result);
                            this.requirementSpecs = result.requirementSpecView;

                            this.requirementSpec.id = (this.requirementSpecs.id == undefined) ? '' : this.requirementSpecs.id;
                            this.requirementSpec.requirementSpecId = (this.requirementSpecs.requirementSpecId == undefined) ? '' : this.requirementSpecs.requirementSpecId;
                            this.requirementSpec.title = (this.requirementSpecs.title == undefined) ? '' : this.requirementSpecs.title;
                            this.requirementSpec.requirementSpecification = (this.requirementSpecs.requirementSpecification == undefined) ? '' : this.requirementSpecs.requirementSpecification;
                            this.requirementSpec.status = (this.requirementSpecs.status == undefined) ? '' : this.requirementSpecs.status;
                            this.requirementSpec.type = (this.requirementSpecs.type == undefined) ? '' : this.requirementSpecs.type;
                            this.requirementSpec.preparedBy = (this.requirementSpecs.preparedBy == undefined) ? '' : this.requirementSpecs.preparedBy;
                            this.requirementSpec.preparedDt = (this.requirementSpecs.preparedDt == undefined) ? '' : this.requirementSpecs.preparedDt;
                            this.requirementSpec.dueDate = (this.requirementSpecs.dueDate == undefined) ? '' : this.requirementSpecs.dueDate;
                            this.requirementSpec.priority = (this.requirementSpecs.priority == undefined) ? '' : this.requirementSpecs.priority;
                            console.log(result);
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else if (refEntity === "user_requirement") {
                    axios.get(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.userRequirements = result.userRequirementView;

                            this.userRequirement.id = (this.userRequirements.id == undefined) ? '' : this.userRequirements.id;
                            this.userRequirement.userRequirementId = (this.userRequirements.userRequirementId == undefined) ? '' : this.userRequirements.userRequirementId;
                            this.userRequirement.title = (this.userRequirements.title == undefined) ? '' : this.userRequirements.title;
                            this.userRequirement.userRequirement = (this.userRequirements.userRequirement == undefined) ? '' : this.userRequirements.userRequirement;
                            this.userRequirement.status = (this.userRequirements.status == undefined) ? '' : this.userRequirements.status;
                            this.userRequirement.type = (this.userRequirements.type == undefined) ? '' : this.userRequirements.type;
                            this.userRequirement.userRequirementBy = (this.userRequirements.userRequirementBy == undefined) ? '' : this.userRequirements.userRequirementBy;
                            this.userRequirement.preparedDt = (this.userRequirements.preparedDt == undefined) ? '' : this.userRequirements.preparedDt;
                            this.userRequirement.dueDate = (this.userRequirements.dueDate == undefined) ? '' : this.userRequirements.dueDate;
                            this.userRequirement.priority = (this.userRequirements.priority == undefined) ? '' : this.userRequirements.priority;
                            console.log(result);
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else if (refEntity === "test_result") {
                    this.testCase.id = (this.detailViewData.id == undefined) ? '' : this.detailViewData.id;
                    this.testCase.testCaseId = (this.detailViewData.testCaseId == undefined) ? '' : this.detailViewData.testCaseId;
                    this.testCase.title = (this.detailViewData.title == undefined) ? '' : this.detailViewData.title;
                    this.testCase.description = (this.detailViewData.description == undefined) ? '' : this.detailViewData.description;
                    this.testCase.expectedResult = (this.detailViewData.expectedResult == undefined) ? '' : this.detailViewData.expectedResult;
                    this.testCase.status = (this.detailViewData.status == undefined) ? '' : this.detailViewData.status;
                    this.testCase.type = (this.detailViewData.type == undefined) ? '' : this.detailViewData.type;
                    this.testCase.userName = (this.detailViewData.userName == undefined) ? '' : this.detailViewData.userName;
                    this.testCase.testedBy = (this.detailViewData.testedBy == undefined) ? '' : this.detailViewData.testedBy;
                    this.testCase.testDate = (this.detailViewData.testDate == undefined) ? '' : this.detailViewData.testDate;
                    this.testCase.preparedDt = (this.detailViewData.preparedDt == undefined) ? '' : this.detailViewData.preparedDt;
                    this.testCase.remarks = (this.detailViewData.remarks == undefined) ? '' : this.detailViewData.remarks;
                    this.loading = false;
                }
                else if (refEntity === "test_case") {
                    this.testCase.id = (this.detailViewData.id == undefined) ? '' : this.detailViewData.id;
                    this.testCase.testCaseId = (this.detailViewData.testCaseId == undefined) ? '' : this.detailViewData.testCaseId;
                    this.testCase.title = (this.detailViewData.title == undefined) ? '' : this.detailViewData.title;
                    this.testCase.description = (this.detailViewData.description == undefined) ? '' : this.detailViewData.description;
                    this.testCase.expectedResult = (this.detailViewData.expectedResult == undefined) ? '' : this.detailViewData.expectedResult;
                    this.testCase.status = (this.detailViewData.status == undefined) ? '' : this.detailViewData.status;
                    this.testCase.type = (this.detailViewData.type == undefined) ? '' : this.detailViewData.type;
                    this.testCase.userName = (this.detailViewData.userName == undefined) ? '' : this.detailViewData.userName;
                    this.testCase.testedBy = (this.detailViewData.testedBy == undefined) ? '' : this.detailViewData.testedBy;
                    this.testCase.testDate = (this.detailViewData.testDate == undefined) ? '' : this.detailViewData.testDate;
                    this.testCase.preparedDt = (this.detailViewData.preparedDt == undefined) ? '' : this.detailViewData.preparedDt;
                    this.testCase.remarks = (this.detailViewData.remarks == undefined) ? '' : this.detailViewData.remarks;
                    this.loading = false;
                }
                else if (refEntity === "action_item") {
                    this.actionItem.id = (this.detailViewData.id == undefined) ? '' : this.detailViewData.id;
                    this.actionItem.title = (this.detailViewData.title == undefined) ? '' : this.detailViewData.title;
                    this.actionItem.actionItemId = (this.detailViewData.actionItemId == undefined) ? '' : this.detailViewData.actionItemId;
                    this.actionItem.actionItemDt = (this.detailViewData.actionItemDt == undefined) ? '' : this.detailViewData.actionItemDt;
                    this.actionItem.actionItemDueDt = (this.detailViewData.actionItemDueDt == undefined) ? '' : this.detailViewData.actionItemDueDt;
                    this.actionItem.actionItemBy = (this.detailViewData.actionItemBy == undefined) ? '' : this.detailViewData.actionItemBy;
                    this.actionItem.actionItemTo =(this.detailViewData.actionItemTo == undefined) ? '' : this.detailViewData.actionItemTo;
                    this.actionItem.status = (this.detailViewData.status == undefined) ? '' : this.detailViewData.status;
                    this.loading = false;
                }
                else if (refEntity === "MTG") {
                    axios.get(this.$store.getters.getAPIBasePath + '/communication/calendarevent/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.meetings = result.calendarEvent;
                            this.meeting.calendarEventId = (this.meetings.calendarEventId == undefined) ? '' : this.meetings.calendarEventId;
                            this.meeting.title = (this.meetings.title == undefined) ? '' : this.meetings.title;
                            this.meeting.eventDate = (this.meetings.eventDate == undefined) ? '' : this.meetings.eventDate;
                            this.meeting.start = (this.meetings.start == undefined) ? '' : this.meetings.start;
                            this.meeting.end = (this.meetings.end == undefined) ? '' : this.meetings.end;
                            this.meeting.status = (this.meetings.status == undefined) ? '' : this.meetings.status;
                            this.meeting.createdBy = (this.meetings.createdBy == undefined) ? '' : this.meetings.createdBy;
                            this.meeting.participant =(this.meetings.participant == undefined) ? '' : this.meetings.participant;
                            this.loading = false;
                            console.log(this.meetings);
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else if (refEntity === "issue") {
                    this.issue.id = (this.detailViewData.id == undefined) ? '' : this.detailViewData.id;
                    this.issue.title = (this.detailViewData.issueId == undefined) ? '' : this.detailViewData.issueId;
                    this.issue.estimatedEndDt = (this.detailViewData.title == undefined) ? '' : this.detailViewData.title;
                    this.issue.estimatedStartDt = (this.detailViewData.issueId == undefined) ? '' : this.detailViewData.issueId;
                    this.issue.taskAssignment = (this.detailViewData.type == undefined) ? '' : this.detailViewData.type;
                    this.issue.requirement = (this.detailViewData.activity == undefined) ? '' : this.detailViewData.activity;
                    this.issue.effort =(this.detailViewData.functionality == undefined) ? '' : this.detailViewData.functionality;
                    this.issue.effortType = (this.detailViewData.description == undefined) ? '' : this.detailViewData.description;
                    this.issue.progressPercentage = (this.detailViewData.solution == undefined) ? '' : this.detailViewData.solution;
                    this.issue.assignedTo = (this.detailViewData.actionTaken == undefined) ? '' : this.detailViewData.actionTaken;
                    this.issue.taskId = (this.detailViewData.reportedBy == undefined) ? '' : this.detailViewData.reportedBy;
                    this.issue.activity = (this.detailViewData.reportedDt == undefined) ? '' : this.detailViewData.reportedDt;
                    this.issue.type = (this.detailViewData.solutionBy == undefined) ? '' : this.detailViewData.solutionBy;
                    this.issue.description = (this.detailViewData.solutionDt == undefined) ? '' : this.detailViewData.solutionDt;
                    this.issue.priority = (this.detailViewData.reportedDt == undefined) ? '' : this.detailViewData.reportedDt;
                    this.issue.response = (this.detailViewData.severity == undefined) ? '' : this.detailViewData.severity;
                    this.issue.status = (this.detailViewData.status == undefined) ? '' : this.detailViewData.status;
                    this.loading = false;
                }
                else if (refEntity === 'uat_test_case') {
                    axios.get(this.$store.getters.getAPIBasePath + '/test/uattestcase/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.uatTestCases = result.uatTestCaseView;
                            console.log(result);
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else this.loading = false;
                this.showModal=true;
        },
    }
}
</script>
<style>
/*#loader {
  position: inherit;
  z-index: 1;
  width: 100px;
  height: 100px;
  margin: 50px auto;
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #2a93d570;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}*/
</style>