<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <FinancialTxnReport />
            </div>
        </div>
    </div>
</template>
<script>
import FinancialTxnReport from '@/views/report/doc/FinancialTxnReport.vue';
export default {
    components: {
        FinancialTxnReport
    }
}
</script>