<template>
    <div>
        <b-modal ref="audioPlayModal" size="md" id="audioPlayModal" title="Audio Player" ok-only centered @show="clearData" :no-close-on-backdrop="true">           
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="12" v-for="info in attachments" :key="info.id" >
                            <audio controls v-if="playAudio" :src="info.attachmentPath"></audio>
                        </b-col>
                    </b-row>
                </b-container>
        </b-modal>      
    </div>
</template>
<script>
import iziToast from 'izitoast';
export default {
    props: {
        playAudio: {
            type: Boolean,
            default: true
        },
        refEntity: String,
        refRecordId: Number,
    },
    computed: {},
    data() {
        return {
            attachments:[],
            projectId: localStorage.getItem("projectId"),   
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            //audioSrc: "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_5MG.mp3"
        }
    },
    validations: {},
    mounted() {
        this.getData(this.refRecordId, this.refEntity);
    },
    methods: {
        clearData(){ },
        getData: function(refRecordId, refEntity) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: refEntity,
                refRecordId: refRecordId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                })
        },
        onDataChange: function(refRecordId, refEntity) {  
            this.getData(refRecordId,refEntity);
        }
  },
}
</script>