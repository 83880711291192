<template>
    <div class="col-12 text-left">
        <b-overlay :show="showOverlay" opacity="0.4">
            <template v-if="employeeData && employeeData.id">
                <div class="form-row mb-1">
                    <div class="col-6 col-sm-6">
                        <div class="row">
                            <div class="col-4 pr-0">
                                <label class="mb-0 py-2 d-block title-block">Employee Name</label>
                            </div>
                            <div class="col-8 pl-0">
                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ employeeData.name }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-3 col-sm-3">
                        <div class="row">
                            <div class="col-3 pr-0">
                                <label class="mb-0 py-2 d-block title-block">EID</label>
                            </div>
                            <div class="col-9 pl-0">
                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ employeeData.employeeCode }} </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-3 col-sm-3">
                        <div class="row">
                            <div class="col-6 pr-0">
                                <label class="mb-0 py-2 d-block title-block">Monthly CTC</label>
                            </div>
                            <div class="col-6 pl-0">
                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ currencySymbol }} {{ monthlyCTC }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row mb-2">
                    <div class="col-6 col-sm-6">
                        <div class="row">
                            <div class="col-5 col-md-4 pr-0">
                                <label class="mb-0 py-2 d-block title-block">Designation</label>
                            </div>
                            <div class="col-7 col-md-8 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{ employeeData.designation }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6">
                        <div class="row">
                            <div class="col-5 col-md-4 pr-0">
                                <label class="mb-0 py-2 d-block title-block">Department</label>
                            </div>
                            <div class="col-7 col-md-8 pl-0">
                                <label class="p-2 mb-0 d-block header-details">{{ employeeData.department }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="payslipList && payslipList.length>0">
                <div class="form-row mb-2">
                    <div class="col-6 col-sm-6">
                        <div class="card h-100">
                            <div class="card-body py-1 px-0">
                                <div class="title-block p-2">
                                    <p class="contact-title mb-0">Earnings</p>
                                </div>
                                <div class="row" v-for="earning,index in earningList">
                                    <div class="col-7 col-md-8 pr-0">
                                        <label class="mb-0 p-2 d-block header-details">{{ earning.payslipComponent }}</label>
                                    </div>
                                    <div class="col-5 col-md-4 pl-0">
                                        <label class="p-2 mb-0 d-block header-details text-right">
                                            {{ currencySymbol }} {{ earning.amount ? (earning.amount).toFixed(2) : '0.00' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6">
                        <div class="card h-100">
                            <div class="card-body py-1 px-0">
                                <div class="title-block p-2">
                                    <p class="contact-title mb-0">Deductions</p>
                                </div>
                                <div class="row" v-for="deduction,index in deductionList">
                                    <div class="col-7 col-md-8 pr-0">
                                        <label class="mb-0 p-2 d-block header-details">{{ deduction.payslipComponent }}</label>
                                    </div>
                                    <div class="col-5 col-md-4 pl-0">
                                        <label class="p-2 mb-0 d-block header-details text-right">
                                            {{ currencySymbol }} {{ deduction.amount ? (deduction.amount).toFixed(2) : '0.00' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row mb-2">
                    <div class="col-6 col-sm-6">
                        <div class="row">
                            <div class="col-6 col-md-6 pr-0">
                                <label class="mb-0 py-2 d-block title-block text-left pl-2">Total Earnings</label>
                            </div>
                            <div class="col-6 col-md-6 pl-0">
                                <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">
                                    {{ currencySymbol }} {{ totalEarnings ? (totalEarnings).toFixed(2) : '0.00' }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6">
                        <div class="row">
                            <div class="col-6 col-md-6 pr-0">
                                <label class="mb-0 py-2 d-block title-block text-left pl-2">Total Deductions</label>
                            </div>
                            <div class="col-6 col-md-6 pl-0">
                                <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">
                                    {{ currencySymbol }} {{ totalDeductions ? (totalDeductions).toFixed(2) : '0.00' }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row mb-1">
                    <div class="col-12 col-sm-12">
                        <div class="row">
                            <div class="col-6 col-md-6 pr-0">
                                <label class="mb-0 py-2 d-block title-block text-right p-2">Net Pay</label>
                            </div>
                            <div class="col-6 col-md-6 pl-0">
                                <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">{{ currencySymbol }} {{ netPay.toFixed(2) }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <b-card v-else no-body class="mb-2">
                <b-card-body class="py-3 px-2">
                    <label class="d-block p-2 my-0 accordion-title text-center" block>
                        No Payslip found
                    </label>
                </b-card-body>
            </b-card>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between } from "vuelidate/lib/validators";
export default {
    props: {
        payslipAutoGenData: Object,
    },
    components: {
        // VhextMoneyControl
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            currencySymbol: localStorage.getItem("currency"),
            logo: localStorage.getItem('logo'),

            employeeData: {},
            payslipList: [],
        }
    },
    computed: {
        earningList() {
            return this.payslipList.filter((data) => data.payslipComponentTypeId == 1);
        },
        deductionList() {
            return this.payslipList.filter((data) => data.payslipComponentTypeId == 2);
        },
        netPay() {
            let earnings = this.totalEarnings ? this.totalEarnings : 0;
            let deductions = this.totalDeductions ? this.totalDeductions : 0;
            return (earnings - deductions);
        },
        totalEarnings() {
            return this.earningList.reduce((accum, item) => {
                return accum + parseFloat(item.amount ? item.amount : 0)
            }, 0.00)
        },        
        totalDeductions() {
            return this.deductionList.reduce((accum, item) => {
                return accum + parseFloat(item.amount ? item.amount : 0)
            }, 0.00)
        },
        monthlyCTC() {
            return this.employeeData.salary ? Math.trunc(this.employeeData.salary / 12) : 0;
        }
    },
    mounted() {
        this.getDetails();
    },
    methods: {
        getDetails: function() {
            if (this.payslipAutoGenData.isEmployee && this.payslipAutoGenData.employeeId) {
                this.empPayslipAutoGenarate();
            }
            else {
                this.generalPayslipAutoGenarate();
            }
        },
        empPayslipAutoGenarate: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                payslipConfigId: this.payslipAutoGenData.payslipConfigId,
                employeeId: this.payslipAutoGenData.employeeId,
                monthlyCTC: this.payslipAutoGenData.monthlyCTC,
                // payrollMonth: '2022-09-20',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/emppayslip/autogen', data)
                .then((response) => {
                    let result = response.data;
                    this.employeeData = result.employee;
                    this.payslipList = result.payslipList ? result.payslipList : [];
                    this.showOverlay = false;
                    this.$nextTick(() => {
                        if(!this.employeeData.salary || this.employeeData.salary == null) {
                            iziToast.info({
                               title: 'Info',
                               message: 'Employee CTC not available',
                               position: 'topCenter'
                           });
                        }
                    })
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        generalPayslipAutoGenarate: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                payslipConfigId: this.payslipAutoGenData.payslipConfigId,
                monthlyCTC: this.payslipAutoGenData.monthlyCTC,
                // payrollMonth: '2022-09-20',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payroll/payslip', data)
                .then((response) => {
                    let result = response.data;
                    this.payslipList = result.payslipList;
                    this.showOverlay = false;
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>