<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Resource Estimation</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Resource</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body>
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="resourceType">Resource Category</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.resourcePlan.resourceCategoryId.$error }">
                                                                <select v-model="resourcePlan.resourceCategoryId" class="form-control" :class="{ 'is-invalid': $v.resourcePlan.resourceCategoryId.$error }" @change="resourcePlan.resourceTypeId=''">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in resourceCategories" :value="info.categoryLookupId">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.resourcePlan.resourceCategoryId.$error && !$v.resourcePlan.resourceCategoryId.required" class="text-danger">Please select Resource Category</label>     
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="resourceType">Resource Type</label>
                                                            <b-input-group>
                                                                <select v-model="resourcePlan.resourceTypeId" :disabled="resourcePlan.resourceCategoryId == '' || !resourcePlan.resourceCategoryId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in filteredResourceTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <b-input-group-append v-if="resourcePlan.resourceCategoryId == '' || !resourcePlan.resourceCategoryId" class="append-0">
                                                                    <a :title="resourceLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.resourceModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            <!-- <a :title="typeLookupTitle" class="setting-icon action-link" href="javascript:"  @click="setTypeLookupEntity"><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="jobTitle">Resource Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.resourcePlan.jobTitle.$error }">
                                                                <input type="text" class="form-control" v-model="resourcePlan.jobTitle" id="jobTitle" placeholder="Enter Resource Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.resourcePlan.jobTitle.$error && !$v.resourcePlan.jobTitle.required" class="text-danger">Please enter Resource Title</label>     
                                                        </div>
                                                    </div> -->
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="resourceCount">Resource Count</label>
                                                            <input type="number" min="0" class="form-control" v-model="resourcePlan.resourceCount" id="resourceCount" placeholder="Enter Resource Count">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Req. From Date</label>
                                                            <input type="date" v-model="resourcePlan.startDate" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Req. Till Date</label>
                                                            <input type="date" v-model="resourcePlan.tillDate" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="totalWorkDays">Total Working Days</label>
                                                            <input type="number" min="0" class="form-control" v-model="resourcePlan.totalWorkDays" id="totalWorkDays" placeholder="Enter Total Working Days">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="resourceCount">Required Duration</label>
                                                            <div class="input-group mb-1">
                                                                <div class="input-group-prepend">
                                                                    <select v-model="resourcePlan.workFrequency" class="form-control ">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in duration" :value="info.name">{{info.name}}</option>
                                                                    </select>
                                                                </div>
                                                                <input id="resourceTimeValue" type="text" class="form-control" v-model="resourcePlan.timeValue" @keypress="isNumber($event)" aria-label="Text input with dropdown button">
                                                                <div class="input-group-append">
                                                                    <select v-model="resourcePlan.timeUnit" class="form-control">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in durationType" :value="info.name">{{info.name}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="roleResponsibility">Required Skills</label>
                                                            <b-form-tags input-id="roleResponsibility" separator=",;" v-model="resourcePlan.roleResponsibilityArray"></b-form-tags>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveResourcePlan" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="resourcePlan.perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="resourcePlan.filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="resourcePlanTable" v-model="mainTableArray" show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="resourcePlans" @row-selected="onRowSelected" :fields="resourcePlanFields" responsive="sm" :current-page="resourcePlan.currentPage" :per-page="resourcePlan.perPage" :filter="resourcePlan.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredResPlan">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>                                                                                      
                                <!-- <template v-slot:cell(jobTitle)="data">
                                    <span class="action-link">{{ data.item.jobTitle }}</span>
                                </template>   -->              

                                <template v-slot:cell(timeValue)="data">
                                    <span>{{ data.item.workFrequency }} {{ data.item.timeValue }} {{ data.item.timeUnit }}</span>
                                </template>                
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteResourcePlan(data.item.id)" v-b-modal.deleteResourcePlan><i class="fa fa-trash pl-2"></i></a>
                                </template>

                                <template v-slot:empty="scope">
                                    <div class="text-center">No Resource Plan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <div class="separator mb-2 mt-0"></div>
                            <b-pagination v-if="resourcePlans.length!=0" pills v-model="resourcePlan.currentPage" :total-rows="resourcePlan.totalRows" :per-page="resourcePlan.perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card  h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-6">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{resourcePlanDetail.resourcePlanId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-6 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateResourcePlan" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- <div class="col-6">
                                                <div class="form-group">
                                                    <label for="role">Job Title</label>
                                                    <input type="text" class="form-control" id="role" :disabled="!elements.editMode" v-model='resourcePlanDetail.jobTitle'>
                                                </div>
                                            </div> -->
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="resourceCategory">Resource Category</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.resourcePlanDetail.resourceCategoryId.$error }">
                                                        <select v-if="elements.editMode" v-model="resourcePlanDetail.resourceCategoryId" @change="resourcePlanDetail.resourceTypeId=''" class="form-control" :class="{ 'is-invalid': $v.resourcePlanDetail.resourceCategoryId.$error }">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in resourceCategories" :value="info.categoryLookupId">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.resourcePlan.resourceCategoryId.$error && !$v.resourcePlan.resourceCategoryId.required" class="text-danger">Please select Resource Category</label>     
                                                    <input  v-if="!elements.editMode" disabled v-model="resourcePlanDetail.resourceCategory" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="resourceType">Resource Type</label>
                                                    <select v-if="elements.editMode" v-model="resourcePlanDetail.resourceTypeId" :disabled="resourcePlanDetail.resourceCategoryId == '' || !resourcePlanDetail.resourceCategoryId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in filteredResourceTypesDet" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input  v-if="!elements.editMode" disabled v-model="resourcePlanDetail.resourceType" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="resourceCount">Resource Count</label>
                                                    <input type="number" min="0" class="form-control" id="resourceCount" :disabled="!elements.editMode" v-model='resourcePlanDetail.resourceCount'>
                                                </div>
                                            </div>
                                            <!-- <div class="col-6">
                                                <div class="form-group">
                                                    <label for="workType">Work Type</label>
                                                    <select v-if="elements.editMode" v-model="resourcePlanDetail.workTypeId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in workTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input v-if="!elements.editMode" disabled v-model="resourcePlanDetail.workType" type="text" class="form-control">
                                                </div>
                                            </div> -->

                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="startDate">Req. From</label>
                                                    <div class="input-group">
                                                        <input v-if="!elements.editMode" type="text" class="form-control" disabled :value="resourcePlanDetail.startDate | formatDate" >
                                                        <input v-if="elements.editMode" type="date" class="form-control"  v-model="resourcePlanDetail.startDate" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="tillDate">Req. Till</label>
                                                    <div class="input-group">
                                                        <input v-if="!elements.editMode" type="text" class="form-control" disabled :value="resourcePlanDetail.tillDate | formatDate" >
                                                        <input v-if="elements.editMode" type="date" class="form-control"  v-model="resourcePlanDetail.tillDate" >
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="totalWorkDays">Total Working Days</label>
                                                    <input type="number" min="0" class="form-control" id="totalWorkDays" :disabled="!elements.editMode" v-model='resourcePlanDetail.totalWorkDays' placeholder="Enter Total Working Days">
                                                </div>
                                            </div>
                                            <div class="col-12 mb-2" >
                                                <label for="resourceCount">Required Duration</label>
                                                <div class="input-group mb-1 " >
                                                    <div class="input-group-prepend">
                                                        <select v-if="elements.editMode" v-model="resourcePlanDetail.workFrequency" class="form-control disabled">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in duration" :value="info.name">{{info.name}}</option>
                                                        </select>
                                                        <input  v-if="!elements.editMode" disabled v-model="resourcePlanDetail.workFrequency" type="text" class="form-control">
                                                    </div>
                                                    <input :disabled="!elements.editMode" type="number" min="0" class="form-control" @keypress="isNumber($event)" aria-label="Text input with dropdown button" v-model='resourcePlanDetail.timeValue'>
                                                    <div class="input-group-append">
                                                        <select v-if="elements.editMode" v-model="resourcePlanDetail.timeUnit" class="form-control disabled">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in durationType" :value="info.name">{{info.name}}</option>
                                                        </select>
                                                        <input  v-if="!elements.editMode" disabled v-model="resourcePlanDetail.timeUnit" type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="roleResponsibilityDet">Required Skills</label>
                                                    <b-form-tags input-id="roleResponsibilityDet" v-model="resourcePlanDetail.roleResponsibilityArray" separator=",;" :disabled="!elements.editMode"></b-form-tags>
                                                </div>
                                            </div> -->
                                        </div>
                                         <div class="row" v-if="!elements.editMode" >
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.rr>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="rr" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdDt">Created Date</label>
                                                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(resourcePlanDetail.createdDt)">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="lastUpdatedDt">Last Updated Date</label>
                                                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(resourcePlanDetail.lastUpdatedDt)">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Roles / Resp.">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-roleResp>
                                                    <span v-if="elements.roleEditMode">Edit Role & Responsibility</span>
                                                    <span v-else>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Role & Responsibility
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-roleResp" accordion="my-accordion" v-model="elements.roleVisible" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <div class="row">
                                                                    <div class="col-12 mb-3">
                                                                        <label for="role">Role</label>
                                                                        <a href="javascript:" class="float-right button-add" @click="elements.showDropdownRole=!elements.showDropdownRole">
                                                                            <i v-if="!elements.showDropdownRole" title="Existing Roles" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                            <i v-if="elements.showDropdownRole" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                                        </a>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibilityDetail.role.$error }">
                                                                            <select v-if="elements.showDropdownRole" @change="getResponsibilities(roleResponsibilityDetail.roleData.id)" v-model="roleResponsibilityDetail.roleData" class="form-control">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in roles" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                            </select>
                                                                            <input v-if="!elements.showDropdownRole" type="text" class="form-control" id="role" v-model="roleResponsibilityDetail.role" placeholder="Enter New Roles">
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.roleResponsibilityDetail.role.$error && !$v.roleResponsibilityDetail.role.required" class="text-danger">Please Enter Role</label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="responsibility">Responsibility</label>
                                                                            <b-form-tags input-id="tags-separators" v-model="roleResponsibilityDetail.responsibilityArray" class="form-control" separator="," placeholder="Enter Responsibilities" ></b-form-tags>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" @click="clearRoleResponsibility" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                                <button type="submit" @click="saveRoleResponsibility()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                                <div class="clearfix"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div class="mt-3">
                                            <div class="card mt-1" v-for="responsibility in responsibilities">
                                                <div class="card-body p-2" v-if="responsibility.id !== roleResponsibilityDetail.editId">
                                                    <div class="row" >
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Role" @click="deleteRoleResponsibility(responsibility.id)" data-toggle="modal" v-b-modal.deleteRoleResponsibility><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Role" @click="editRole(responsibility)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6 >{{ responsibility.role }}</h6>
                                                                <p> {{ responsibility.responsibility ? (responsibility.responsibility).replaceAll(',', ', ') : '' }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>   
                                    </b-tab>
                                    <b-tab title="Tech. / Skills">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-techSkill>
                                                    <span v-if="elements.techEditMode">Edit Technology & Skill</span>
                                                    <span v-else>
                                                        <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>Add Technology & Skill
                                                    </span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-techSkill" accordion="my-accordion" v-model="elements.techVisible" role="tabpanel">
                                                <b-card-body class="p-3">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <div class="row">
                                                                    <div class="col-12 mb-3">
                                                                        <label for="technology">Technology</label>
                                                                        <a href="javascript:" class="float-right button-add" @click="elements.showDropdownTech=!elements.showDropdownTech">
                                                                            <i v-if="!elements.showDropdownTech" title="Existing Technologies" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                                                            <i v-if="elements.showDropdownTech" title="back" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                                                        </a>
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkillDetail.technology.$error }">
                                                                            <select v-if="elements.showDropdownTech" @change="getTechnologies(technologySkillDetail.technologyData.id)" v-model="technologySkillDetail.technologyData" class="form-control">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in technologies" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                                            </select>
                                                                            <input v-if="!elements.showDropdownTech" type="text" class="form-control" id="technology" v-model="technologySkillDetail.technology" placeholder="Enter New Technologies">
                                                                            <div class="required-icon"></div>
                                                                        </div>
                                                                        <label v-if="$v.technologySkillDetail.technology.$error && !$v.technologySkillDetail.technology.required" class="text-danger">Please Enter Technology</label>
                                                                    </div>
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="skills">Skills</label>
                                                                            <b-form-tags input-id="tags-separators" v-model="technologySkillDetail.skillsArray" separator="," class="form-control" placeholder="Enter skills" ></b-form-tags>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" @click="clearTechnologySkill" class="btn btn-sm btn-outline-primary mb-0 mr-2 pt-1 pb-1 px-2 float-right"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                                <button type="submit" @click="saveTechnologySkill()" class="btn btn-sm btn-primary mb-0 mr-2 pt-1 pb-1 px-3 float-right">
                                                                    <i class="fa fa-floppy-o pr-2"></i>Save
                                                                </button>
                                                                <div class="clearfix"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <div class="mt-3">
                                            <div class="card my-1" v-for="technologySkill in technologySkills">
                                                <div class="card-body p-2" v-if="technologySkill.id !== technologySkillDetail.editId">
                                                    <div class="row" >
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <a href="javascript:" class="float-right button-add" title="Delete Technology" @click="deleteTechnologySkill(technologySkill.id)" data-toggle="modal" v-b-modal.deleteTechnologySkill><i class="fa fa-trash pr-1 mr-1 float-right"></i></a>
                                                                <a href="javascript:" class="float-right button-add" title="Edit Technology" @click="editTech(technologySkill)"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
                                                                <h6>{{ technologySkill.technology }}</h6>
                                                                <p>{{ technologySkill.skills ? (technologySkill.skills).replaceAll(',', ', ') : '' }} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <TypeLookup  ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" /> -->
        <AddResourceLookup :resourceLookupTitle="resourceLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteResourcePlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Resource Plan?" />
        <DeleteComponent id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <DeleteComponent id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
import days from '../../json/days.json'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import AddResourceLookup from '@/components/popup/lookup/AddResourceLookup.vue';

export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        },
    },
    components: {
        AddResourceLookup,
        // TypeLookup,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            monthList: month,
            daysList: days,
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
                typeEdit: false,
                typeVisible: false,
                roleVisible: false,
                roleEditMode: false,
                techVisible: false,
                techEditMode: false,
                showDropdownRole: false,
                showDropdownTech: false,
            },
            duration: [
              { name: "Daily", id: 1 },
              { name: "Weekly", id: 2 },
              { name: "Monthly", id: 3 },
              { name: "Yearly", id: 4 },
            ],
            durationType: [
              { name: "minutes", id: 1 },
              { name: "hours", id: 2 },
              { name: "days", id: 3 },
              { name: "months", id: 4 },
            ],

            resourceLookupTitle: 'Resources',

            entity:'resource_plan',
            typeEntity:'resource_type',
            typeLookupTitle: '',            
            typeLookupEntity:'',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            userId: localStorage.getItem("userId"),

            mainTableArray: [],

            resourcePlan: {
                id: '',
                resourcePlanId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                entity: '',
                plannedById: '',
                jobTitle: '',
                resourceTypeId: '',
                resourceCategoryId: '',
                type: '',
                resourceCount: '',
                startDate: new Date().toISOString().substr(0, 10),
                tillDate: '',
                workTypeId: '',
                workType: '',
                workFrequency: '',
                timeValue: '',
                timeUnit: '',
                totalWorkDays: '',
                periodDays: '',
                periodMonths: '',
                periodYears: '',
                roleResponsibility: '',
                roleResponsibilityArray: [],
                technologySkill: '',

                totalRows: 1,
                currentPage: 1,
                perPage: 10,
            },

            resourcePlanDetail: {
                id: '',
                resourcePlanId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                entity: '',
                plannedById: '',
                jobTitle: '',
                resourceTitle: '',
                resourceTypeId: '',
                type: '',
                resourceCategoryId: '',
                resourceCategory: '',
                resourceCount: '',
                startDate: new Date().toISOString().substr(0, 10),
                tillDate: '',
                totalWorkDays: '',
                periodDays: '',
                workTypeId: '',
                periodMonths: '',
                periodYears: '',
                roleResponsibility: '',
                roleResponsibilityArray: [],
                technologySkill: '',

                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                
            },

            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: '',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''

            },
            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),                
                entity: 'technology_skill',
                refEntity: '',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technologyData: '',
                technology: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },
            users: [],
            resourceTypes: [],
            resourceCategories: [],
            resourcePlans: [],
            masterStatus: [],
            customTypes: [],
            workTypes: [],
            technologies: [],
            roles: [],
            responsibilities: [],
            technologySkills: [],

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            resourcePlanFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'resourcePlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCategory', label: 'Category', sortable: true, sortDirection: 'desc' }, 
                { key: 'resourceType', label: 'Resource Type', sortable: true, sortDirection: 'desc' },
                { key: 'resourceCount', label: 'Res. Count', tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Req. From' , formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'tillDate', label: 'Req. Till' , formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'totalWorkDays', label: 'Tot. Working Days', tdClass: 'text-center', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [ 5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredResourceTypes() {
            let resourceTypes = [];
            if (this.resourceTypes.length > 0 && this.resourcePlan.resourceCategoryId) {
                resourceTypes = this.resourceTypes.filter(data => data.categoryLookupId == this.resourcePlan.resourceCategoryId);
            }
            return resourceTypes;
        },
        filteredResourceTypesDet() {
            let resourceTypes = [];
            if (this.resourceTypes.length > 0 && this.resourcePlanDetail.resourceCategoryId) {
                resourceTypes = this.resourceTypes.filter(data => data.categoryLookupId == this.resourcePlanDetail.resourceCategoryId);
            }
            return resourceTypes;
        },
    },
    mounted() {        
        this.getResourcePlan();
        this.lookupData();
    },
    validations: {
        resourcePlan: {
            resourceCategoryId: {
                required
            }
        },
        resourcePlanDetail: {
            resourceCategoryId: {
                required
            }
        },
        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {
            technology: {
                required
            }
        },
    },
    methods: {
        getResourcePlan: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/list', data)
                .then((response) => {
                    let result = response.data;
                        this.resourcePlans = result.resourcePlanList;
                        this.resourcePlan.totalRows = this.resourcePlans.length;
                        this.elements.editMode = false;
                        if (this.resourcePlans && this.resourcePlans.length > 0) {
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.resourcePlanDetail.id, this.mainTableArray, 'resourcePlanTable');
                            })
                        }
                        else {
                            this.elements.showDetail = false;
                        }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity 
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.resourceTypes = result.resourceLookup;
                    this.resourceCategories = result.categoryLookup;
                    // this.workTypes = result.workTypeLookup;
                    console.log(this.users);
                })

        },
        onFilteredResPlan(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.resourcePlan.totalRows = filteredItems.length
            this.resourcePlan.currentPage = 1
        },
        saveResourcePlan: function() {
            this.resourcePlan.vhextAccountId = this.vhextAccountId;
            this.resourcePlan.projectId = this.projectId;
            this.resourcePlan.entityRecordMasterId = this.entityRecordMasterId;
            this.resourcePlan.plannedById = this.userId;
            this.resourcePlan.resourceTitle = this.resourcePlan.jobTitle;
            this.resourcePlan.roleResponsibility = this.arrayToString(this.resourcePlan.roleResponsibilityArray);
            this.$v.resourcePlan.$touch();

            if (!this.$v.resourcePlan.$invalid) {
                console.log(this.resourcePlan);                
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/add', this.resourcePlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getResourcePlan();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateResourcePlan: function() {
            this.resourcePlanDetail.resourceTitle = this.resourcePlanDetail.jobTitle;
            this.resourcePlanDetail.roleResponsibility = this.arrayToString(this.resourcePlanDetail.roleResponsibilityArray);
            if (!this.$v.resourcePlanDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/resourceplan/update', this.resourcePlanDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getResourcePlan();
                            this.viewResourcePlan(this.resourcePlanDetail.id);
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        deleteResourcePlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/resourceplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getResourcePlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.editMode = false;
            this.elements.showDetail = true;
            this.resourcePlan.resourcePlanId = '';            
            this.resourcePlan.jobTitle = '';
            this.resourcePlan.resourceTitle = '';
            this.resourcePlan.resourceTypeId = '';
            this.resourcePlan.resourceCategoryId = '';
            this.resourcePlan.startDate = new Date().toISOString().substr(0, 10);
            this.resourcePlan.tillDate = '';
            this.resourcePlan.workTypeId = '';
            this.resourcePlan.workFrequency = '';
            this.resourcePlan.timeValue = '';
            this.resourcePlan.timeUnit = '';

            this.resourcePlan.responsibility = '';
            this.resourcePlan.resourceCount = '';
            this.resourcePlan.totalWorkDays = '';
            this.resourcePlan.periodDays = '';
            this.resourcePlan.periodMonths = '';
            this.resourcePlan.periodYears = '';
            this.resourcePlan.roleResponsibility = '';
            this.resourcePlan.roleResponsibilityArray = [];
            this.resourcePlan.technologySkill = '';
            this.resourcePlan.plannedById = '';
            this.$v.resourcePlan.$reset();

        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.elements.visible = false;
                this.resourcePlanDetail.id = data.id;
                this.resourcePlanDetail.resourcePlanId = data.resourcePlanId;
                this.resourcePlanDetail.vhextAccountId = data.vhextAccountId;
                this.resourcePlanDetail.projectId = data.projectId;
                this.resourcePlanDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.resourcePlanDetail.jobTitle = data.jobTitle;
                this.resourcePlanDetail.resourceTypeId = data.resourceTypeId;
                this.resourcePlanDetail.resourceCategoryId = data.resourceCategoryId;
                this.resourcePlanDetail.resourceCategory = data.resourceCategory;
                this.resourcePlanDetail.responsibility = data.responsibility;
                this.resourcePlanDetail.resourceCount = data.resourceCount;
                this.resourcePlanDetail.startDate = data.startDate;
                this.resourcePlanDetail.tillDate = data.tillDate;
                this.resourcePlanDetail.workTypeId = data.workTypeId;
                this.resourcePlanDetail.resourceType = data.resourceType;
                this.resourcePlanDetail.workFrequency = data.workFrequency;
                this.resourcePlanDetail.timeValue = data.timeValue;
                this.resourcePlanDetail.timeUnit = data.timeUnit;

                this.resourcePlanDetail.totalWorkDays = data.totalWorkDays;
                this.resourcePlanDetail.periodDays = data.periodDays;
                this.resourcePlanDetail.periodMonths = data.periodMonths;
                this.resourcePlanDetail.periodYears = data.periodYears;
                this.resourcePlanDetail.roleResponsibilityArray = this.stringToArray(data.roleResponsibility);
                this.resourcePlanDetail.roleResponsibility = data.roleResponsibility;
                this.resourcePlanDetail.technologySkill = data.technologySkill;
                this.resourcePlanDetail.plannedById = data.plannedById;
                this.resourcePlanDetail.createdDt = data.createdDt;
                this.resourcePlanDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.getRoleResponsibilities();
                this.getTechnologySkills();
            }
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = false;
            this.elements.showDetail = true;
            this.elements.editMode = true;
            this.resourcePlanDetail.resourcePlanId = data.resourcePlanId;       
            this.resourcePlanDetail.vhextAccountId = data.vhextAccountId;
            this.resourcePlanDetail.projectId = data.projectId;
            this.resourcePlanDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.resourcePlanDetail.jobTitle = data.jobTitle;
            this.resourcePlanDetail.resourceTypeId = data.resourceTypeId;
            this.resourcePlanDetail.resourceType = data.resourceType;
            this.resourcePlanDetail.resourceCategoryId = data.resourceCategoryId;
            this.resourcePlanDetail.resourceCategory = data.resourceCategory;
            this.resourcePlanDetail.responsibility = data.responsibility;
            this.resourcePlanDetail.resourceCount = data.resourceCount;
            this.resourcePlanDetail.startDate = data.startDate;
            this.resourcePlanDetail.tillDate = data.tillDate;

            this.resourcePlanDetail.workTypeId = data.workTypeId;
            this.resourcePlanDetail.workFrequency = data.workFrequency;
            this.resourcePlanDetail.timeValue = data.timeValue;
            this.resourcePlanDetail.timeUnit = data.timeUnit;

            this.resourcePlanDetail.totalWorkDays = data.totalWorkDays;
            this.resourcePlanDetail.periodDays = data.periodDays;
            this.resourcePlanDetail.periodMonths = data.periodMonths;
            this.resourcePlanDetail.periodYears = data.periodYears;
            this.resourcePlanDetail.roleResponsibilityArray = this.stringToArray(data.roleResponsibility);
            this.resourcePlanDetail.roleResponsibility = data.roleResponsibility;
            this.resourcePlanDetail.technologySkill = data.technologySkill;
            this.resourcePlanDetail.plannedById = data.plannedById;
            this.resourcePlanDetail.id = data.id;
            this.getRoleResponsibilities();
            this.getTechnologySkills();
        },
        showView: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = false;
            this.resourcePlan.resourcePlanId = data.resourcePlanId;
            this.resourcePlan.vhextAccountId = data.vhextAccountId;
            this.resourcePlan.projectId = data.projectId;
            this.resourcePlan.entityRecordMasterId = data.entityRecordMasterId;
            this.resourcePlan.jobTitle = data.jobTitle;
            this.resourcePlan.resourceTypeId = data.resourceTypeId;
            this.resourcePlan.resourceCategoryId = data.resourceCategoryId;
            this.resourcePlan.responsibility = data.responsibility;
            this.resourcePlan.resourceCount = data.resourceCount;
            this.resourcePlan.startDate = data.startDate;
            this.resourcePlan.tillDate = data.tillDate;

            this.resourcePlan.workTypeId = data.workTypeId;
            this.resourcePlan.resourceType = data.resourceType;
            this.resourcePlan.workFrequency = data.workFrequency;
            this.resourcePlan.timeValue = data.timeValue;
            this.resourcePlan.timeUnit = data.timeUnit;

            this.resourcePlan.totalWorkDays = data.totalWorkDays;
            this.resourcePlan.periodDays = data.periodDays;
            this.resourcePlan.periodMonths = data.periodMonths;
            this.resourcePlan.periodYears = data.periodYears;
            this.resourcePlan.roleResponsibilityArray = this.stringToArray(data.roleResponsibility);
            this.resourcePlan.roleResponsibility = data.roleResponsibility;
            this.resourcePlan.technologySkill = data.technologySkill;
            this.resourcePlan.plannedById = data.plannedById;
            this.resourcePlan.id = data.id;
            this.getRoleResponsibilities();
            this.getTechnologySkills();
        },
        viewResourcePlan: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/resourceplan/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.resourcePlanDetail = result.resourcePlan;
                    this.resourcePlanDetail.roleResponsibilityArray = this.stringToArray(this.resourcePlanDetail.roleResponsibility);
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewResourcePlan(this.resourcePlanDetail.id);
        },
        getRoleResponsibilities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.resourcePlanDetail.id,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.responsibilities = result.cmrRoleResponsibilityList;
                    }
                })
        },

        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.resourcePlanDetail.id,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.length != 0) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        saveRoleResponsibility: function() {
            this.roleResponsibilityDetail.refEntity = this.entity;
            if (this.roleResponsibilityDetail.roleData.text != null) {
                this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
            }
            this.$v.roleResponsibilityDetail.$touch();
            if (!this.$v.roleResponsibilityDetail.$invalid) {
                if (this.elements.roleEditMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.addRoleResponsibility();
                }
            }
        },
        addRoleResponsibility: function() {
            this.roleResponsibilityDetail.refRecordId = this.resourcePlanDetail.id;
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        updateRoleResponsibility: function() {
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        clearRoleResponsibility: function() {
            this.elements.roleEditMode = false;
            this.elements.roleVisible = false;
            this.elements.showDropdownRole = false;
            this.roleResponsibilityDetail.editId= '';
            this.roleResponsibilityDetail.role= '';
            this.roleResponsibilityDetail.roleData= '';
            this.roleResponsibilityDetail.responsibility='';
            this.roleResponsibilityDetail.responsibilityArray= [];
            this.$v.roleResponsibilityDetail.$reset();
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYesRole: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveTechnologySkill: function() {
            this.technologySkillDetail.refEntity = this.entity;
            if (this.technologySkillDetail.technologyData.text != null) {
                this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
            }
            this.$v.technologySkillDetail.$touch();
            if (!this.$v.technologySkillDetail.$invalid) {
                if (this.elements.techEditMode) {
                    this.updateTechnologySkill();
                } else {
                    this.addTechnologySkill();
                }
            }
        },
        addTechnologySkill: function() {
            this.technologySkillDetail.refRecordId = this.resourcePlanDetail.id;
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        updateTechnologySkill: function() {
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        clearTechnologySkill: function() {
            this.elements.techEditMode = false;
            this.elements.techVisible = false;
            this.elements.showDropdownTech = false;
            this.technologySkillDetail.editId= '';
            this.technologySkillDetail.technologyData = '';
            this.technologySkillDetail.technology= '';
            this.technologySkillDetail.skills= '';
            this.technologySkillDetail.skillsArray= [];
            this.$v.technologySkillDetail.$reset();
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYesTech: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        // this.$forceUpdate();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editRole: function(data) {
            this.elements.roleEditMode = true;
            this.elements.roleVisible = true;
            this.roleResponsibilityDetail.id = data.id;
            this.roleResponsibilityDetail.editId = data.id;
            this.roleResponsibilityDetail.role = data.role;
            this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
            this.roleResponsibilityDetail.refRecordId = data.refRecordId;
            this.roleResponsibilityDetail.refEntityId = data.refEntityId;
            this.roleResponsibilityDetail.projectId = data.projectId;
            this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
            this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibilityDetail.preparedById = data.preparedById;
        },
        editTech: function(data) {
            this.elements.techEditMode = true;
            this.elements.techVisible = true;
            this.technologySkillDetail.id = data.id;
            this.technologySkillDetail.editId = data.id;
            this.technologySkillDetail.technologySkillId = data.technologySkillId;
            this.technologySkillDetail.technology = data.technology;
            this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
            this.technologySkillDetail.refRecordId = data.refRecordId;
            this.technologySkillDetail.refEntityId = data.refEntityId;
            this.technologySkillDetail.projectId = data.projectId;
            this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
            this.technologySkillDetail.preparedById = data.preparedById;
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(roleResponsibility.responsibility);
                    })
                }
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = this.stringToArray(technologySkill.skills);
                    })
                }
        },
        setTypeLookupEntity: function() {
            this.typeLookupTitle = 'Resource Type Setting';
            this.typeLookupEntity = 'resource_plan';
            this.$root.$emit('bv::show::modal', 'typeModal');
        },
        setWorkTypeLookupEntity: function() {
            this.typeLookupTitle = 'Work Type Setting';
            this.typeLookupEntity = 'work_type';
            this.$root.$emit('bv::show::modal', 'typeModal');
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },        
    }
}
</script>