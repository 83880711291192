<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Time Sheet Report</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <!-- top to bottom -->
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu2" @click="toggleDiv()" v-bind:class="{appMenuClose: elements.appMenuClose }">
                                                    <a href="javascript:" title="Filter">
                                                        <i class="fa fa-filter  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="javascript:" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="javascript:" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div id="printMe" class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 pr-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3 pl-0">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Status</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.status}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div v-if="ermReviewApprovals != null && ermReviewApprovals.length != 0" class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Review / Approval</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="ermReviewApprovals" :fields="ermReviewApprovalFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(isApproval)="data">
                                                    <div>
                                                        <p v-if="data.value == false">Review By :</p>
                                                        <p v-else>Approval By :</p>
                                                    </div>
                                                </template>
                                                <template v-slot:cell(status)="data">
                                                    <span v-if="data.item.reviewedById == userId">
                                                        <select v-if="!data.item.isApproval && data.value == '' " v-model="data.item.statusId" class="form-control w-80 float-left">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in filterReviewApproval(data.item.isApproval)" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <select v-if="data.item.isApproval && data.value == '' " v-model="data.item.statusId" class="form-control w-80 float-left">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in filterReviewApproval(data.item.isApproval)" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <a v-if="data.value == ''" href="javascript:" title="update status" @click="updateStatusValue(data.item.statusId, data.item.id, data.item.isApproval)" data-toggle="modal" data-target="#confirmUpdateStatus" class="float-right p-2 action-link">
                                                            <i class="fa fa-check pr-2"></i>
                                                        </a>
                                                        <p v-if="data.value != ''">{{data.value}}</p>
                                                    </span>
                                                    <span v-else>
                                                        <p v-if="data.value ==''">Pending</p>
                                                        <p v-else>{{data.value}}</p>
                                                    </span>
                                                </template>
                                                <template v-slot:empty="erm">
                                                    <div class="text-center">No Reviews found</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="erm.description != null && erm.description.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Description</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12" v-html="erm.description">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="timeSheet != null && timeSheet.length != 0" class="card mb-2">
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Time Sheet Report</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="timeSheets" :fields="timeSheetFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(description)="data">
                                                    <a v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.description)"></a>
                                                </template>
                                                <template v-slot:cell(status)="data">
                                                    <div>
                                                      <b-badge v-if="data.item.status == 'To Do'" variant="primary">{{data.value}}</b-badge>
                                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="info">{{data.value}}</b-badge>
                                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                                      <b-badge v-else-if="data.item.status == 'On Hold'" variant="warning">{{data.value}}</b-badge>
                                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                                    </div>
                                                </template>
                                                <template v-slot:empty="erm">
                                                    <div class="text-center">No Time sheet Report Found</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- top to bottom -->
            <nav class="app-menu-nav-filter" v-bind:class="{appMenuNavFilterOn: elements.appMenuNavFilterOn}">
                <div class="comments-titel">
                    <label class="d-block mt-2 mb-0">Filter <span><a href="javascript:" @click="toggleDiv();" class="float-right close-filter"><i class="fa fa-times " aria-hidden="true"></i></a></span></label>
                </div>
                <div class="comments-cox mt-2">
                    <div class="row m-0">
                        <div class="col-3">
                            <div class="form-group">
                                <label for="preparedBy">Prepared By</label>
                                <select v-model="timeSheet.preparedById" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="taskId">Task</label>
                                <select v-model="timeSheet.taskId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in tasks" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label for="activityId">Activity</label>
                                <select v-model="timeSheet.activityId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" class="form-control" v-model="timeSheet.startDate">
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>End Date</label>
                                <input type="date" class="form-control" v-model="timeSheet.endDate">
                            </div>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-12">
                            <button type="submit" @click="getTimeSheets" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-floppy-o pr-2"></i>Search</button>
                             <button type="submit" @click="toggleDiv();" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                            <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <ChatComment :entity="this.entity" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <Dialog id="confirmUpdateStatus" :onYes="updateStatus" title="Update Status" message="Are you sure to update the status?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import ChatComment from '@/components/common/ChatComment.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
export default {
    name: 'app',
    components: {
        ChatComment,
        HTMLDetailViewPopup,
    },

    data() {
        return {
            //top to bottom 
            elements: {
                appMenuNavFilterOn: false,
                appMenuClose: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entityMaster: 'time_sheet_master',
            entity: 'time_sheet',
            htmlData: '',
            htmlDetailViewTitle: "Meeting Agenda Item",
            ermId: '',
            review: {
                isReviewed: 0,
                id: '',
                vhextAccountId: '',
                projectId: '',
                statusId: '',
                status: ''
            },
            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                taskId: '',
                statusId: '',
                activityId: '',
                preparedById: '',
                startDate: '',
                endDate: '',
                entity: this.entity
            },
            isBusy: true,
            comments: [],
            erm: [],
            ermReviewApprovals: [],
            ermReferences: [],
            users: [],
            tasks: [],
            activities: [],
            timeSheets: [],
            timeSheetFields: [
                { key:'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },
                // { key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },                
            ],

            ermReviewApprovalFields: [
                { key: 'isApproval', label: 'Review / Approval', sortable: true, sortDirection: 'desc' },
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'receivedDt', label: 'Received Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Review / Approval Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },

        key() {
            return this.$route.params.ermId;
        }
    },

    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.initialSettings();
        this.lookupData();
    },
    methods: {
        /*top to bottom*/
        toggleDiv: function() {
            this.elements.appMenuNavFilterOn = !this.elements.appMenuNavFilterOn;
            this.elements.appMenuClose = !this.elements.appMenuClose;
        },
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.strERMId = String(this.entityRecordMasterId);
            this.entityMaster = 'time_sheet_master';
            this.entity = 'time_sheet';
            this.userId = localStorage.getItem("userId");
            this.showReport(this.strERMId);
            //console.log(this.vhextAccountId,this.projectId,this.entityRecordMasterId,this.entity,this.userId);
        },
        showReport: function(strERMId) {
            if (strERMId.length > 10) {
                this.getDecodedData();
            } else {
                this.entityRecordMasterCollection(strERMId);
                this.getTimeSheets(strERMId);
                this.getReviewStatusLookup();
            }
        },

        entityRecordMasterCollection: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                    this.ermReferences = result.entityRecordMasterReferenceList;
                    console.log(result);
                })
        },
        getTimeSheets: function(ermId) {
            /*let data = {
                entityRecordMasterId: this.entityRecordMasterId,
                preparedById: Number(this.userId)
            }*/

            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                preparedById: Number(this.timeSheet.preparedById),
                taskId: Number(this.timeSheet.taskId),
                activityId: Number(this.timeSheet.activityId),
                startDate: this.timeSheet.startDate,
                endDate: this.timeSheet.endDate,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetreport/list', data)
                .then((response) => {
                    let result = response.data;
                    this.timeSheets = result.timeSheetReportList;
                    this.totalRows = this.timeSheet.length;
                })
        },
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/decodeddata', data)
                .then((response) => {
                    let result = response.data;
                    if (result.decodedData.entityRecordMasterId != null) {
                        this.ermId = result.decodedData.entityRecordMasterId;
                        this.review.isReviewed = result.decodedData.isReviewed;
                        this.review.id = result.decodedData.id;
                        this.review.entityRecordMasterId = this.ermId;
                        this.review.vhextAccountId = this.vhextAccountId;
                        this.review.projectId = this.projectId;
                        this.review.entity = this.entityMaster;

                        console.log(this.ermId);
                        this.entityRecordMasterCollection(this.ermId);

                        this.getTimeSheets(this.ermId);

                        this.getReviewStatusLookup();
                    }

                })
        },
        getReviewStatusLookup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'review'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/review/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    console.log(this.review.isReviewed);
                    this.reviewStatus = result.statusLookup;
                    if (this.review.isReviewed == 1) {
                        this.statuss = this.filterReviewApproval(false);
                    } else if (this.review.isReviewed == 2) {
                        this.statuss = this.filterReviewApproval(true);
                    }
                })
        },
        updateStatus: function() {

            if (this.review.statusId == null || this.review.statusId == '') {
                iziToast.info({
                    message: 'Please select status.',
                    position: 'topRight'
                });
                return;
            }
            console.log(this.review);
            axios.post(this.$store.getters.getAPIBasePath + '/review/update/status', this.review)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    if (result.status == 'ALREADY_REPORTED') {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                        return;
                    }
                    if (this.review.isReviewed == 1) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Reviewed Successfully.',
                            position: 'topRight'
                        });
                    } else if (this.review.isReviewed == 2) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Approved Successfully.',
                            position: 'topRight'
                        });
                    }
                    this.entityRecordMasterCollection(this.ermId);
                })
        },
        updateStatusValue: function(statusId, id, isApproval) {
            this.review.statusId = statusId;
            this.review.id = id;
            this.review.isReviewed = (isApproval ? 2 : 1);
        },
        filterReviewApproval(value) {
            let name = '';
            if (value) {
                name = 'Approved';
            } else {
                name = 'Reviewed';
            }
            return this.reviewStatus.filter(function(data, key) {
                if (data.value == name || data.value == 'Reject') {
                    return data;
                }
            });
        },

        print: function() {
            this.$htmlToPaper('printMe');
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.entityRecordMasterId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskLookup;
                    this.activities = result.activityLookup;
                    this.users = result.userLookup;
                })
        },

        clearData: function() {    
            this.timeSheet.taskId = '';
            this.timeSheet.preparedById = '';
            this.timeSheet.activityId = '';
            this.timeSheet.startDate = '';
            this.timeSheet.endDate = '';
            this.getTimeSheets();
        },

    }
}
</script>