<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Role Responsibility</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode ">
                                                    <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>New Role Responsibility
                                                </span>
                                                <span v-if="elements.editMode">Edit</span> 
                                            </label> 
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-3 mt-0">
                                                    <div class="col-8">
                                                        <div class="form-group">
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibility.role.$error }">
                                                                <b-input-group prepend="Role" class="my-3 prepend-label">
                                                                    <b-form-input type="text" class="form-control" aria-label="First name"  v-model="roleResponsibility.role" placeholder="Enter Role"></b-form-input>
                                                                </b-input-group>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                          <label v-if="$v.roleResponsibility.role.$error && !$v.roleResponsibility.role.required" class="text-danger">Please enter Role</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                 <b-form-checkbox class="custom-toggle" v-model="roleResponsibility.active" name="check-button" switch>
                                                                 </b-form-checkbox>
                                                            </div>       
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label for="responsibilities">Responsibilities</label>
                                                        <b-form-tags input-id="tags-separators" v-model="roleResponsibility.responsibilities" separator="," placeholder="Enter responsibilities" maxlength="2000" ></b-form-tags>
                                                    </div>                                                
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="handleCancel" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>

                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="roleResponsibilityTable" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="roleResponsibilities" @row-selected="onRowSelected" :fields="roleResponsibilityFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="activation(data.item)">
                                    </b-form-checkbox>
                                </template>

                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteRoleResponsibility(data.item.id)" v-b-modal.deleteRoleResponsibility ><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Role and Responsibility found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="roleResponsibilities.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label >Role</label>
                                                <b-input-group :prepend="roleResponsibilityDetail.roleResponsibilityId" class="prepend-label mb-2">
                                                <b-form-input aria-label="First name" disabled v-model="roleResponsibilityDetail.role"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Responsibility</label>
                                                <textarea disabled v-model="roleResponsibilityDetail.responsibility" class="form-control" rows="4" required></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.rr>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="rr" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="preparedBy">Prepared By</label>
                                                                        <input type="text" class="form-control" id="preparedBy" disabled v-model='roleResponsibilityDetail.preparedBy'>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="createdDt">Created Date</label>
                                                                        <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(roleResponsibilityDetail.createdDt)">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="lastUpdatedDt">Last Updated Date</label>
                                                                        <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(roleResponsibilityDetail.lastUpdatedDt)">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteRoleResponsibility" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
            },

            isBusy: false,

            entity:'role_responsibility',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            roleResponsibility: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                roleResponsibilityId: '',
                role: '',
                responsibility: '',
                responsibilities: [],
                preparedById: '',
                preparedBy:'',
                active: true,
                refEntity: 'role_responsibility',
                refRecordId: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            roleResponsibilityDetail: {
                roleResponsibilityId: '',
                role: '',
                responsibility: '',
                responsibilities: [],
                preparedById: '',
                preparedBy:'',
                active: '',
                refEntity: 'role_responsibility',
                refRecordId: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            notEnabled: true,
            
            users: [],
            projects: [],
            roleResponsibilities: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            roleResponsibilityFields: [
                { key: 'roleResponsibilityId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'role', label: 'Role', sortable: true, sortDirection: 'desc' }, 
                { key: 'responsibility', label: 'Responsibility', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getRoleResponsibility();
        this.lookupData();

        // this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
        //     if (collapseId == 'accordion-1') {
        //         if (isJustShown) {
        //             if (!(this.elements.editMode)) {
        //                 this.elements.showDetail = false;
        //             }
        //         }
        //     }
        // })
    },
    validations: {
        roleResponsibility: {
            role: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(100)

            }
        }
    },
    methods: {
        getRoleResponsibility: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/list',data)
                .then((response) => {
                    let result = response.data;
                    this.roleResponsibilities = result.roleResponsibilityList;
                    this.totalRows = this.roleResponsibilities.length;
                    console.log(result);
                    this.$nextTick(() => {
                            this.$refs.roleResponsibilityTable.selectRow(0);
                        })
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/lookup/all', data)
                .then((response) => {
                    let result = response.data;

                    this.users = result.userLookup;
                    
                })

        },
        saveData: function() {
            this.roleResponsibility.vhextAccountId = this.vhextAccountId;
            this.roleResponsibility.projectId = this.projectId;
            this.roleResponsibility.preparedById = this.userId;

            this.roleResponsibility.responsibility = this.arrayToString(this.roleResponsibility.responsibilities);
            console.log(this.roleResponsibility);
            this.$v.roleResponsibility.$touch();
            if (!this.$v.roleResponsibility.$invalid) {
                if (this.elements.editMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.saveRoleResponsibility();

                }
            }
        },
        saveRoleResponsibility: function() {
            this.showOverlay = true;
            console.log(this.roleResponsibility);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibility)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                            
                        });
                        this.getRoleResponsibility();
                        this.clearData();
                    }
                })
                .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })

        },
        updateRoleResponsibility: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibility)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibility();
                        this.handleCancel();
                        this.clearData();
                    }
                })
                .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
                
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibility();
                    }
                })
                .catch(error => {
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.editMode = false;
            this.roleResponsibility.roleResponsibilityId = '';
            this.roleResponsibility.role = '';
            this.roleResponsibility.preparedById = '';
            this.roleResponsibility.responsibility = '';
            this.roleResponsibility.responsibilities = [];
            this.roleResponsibility.active = '';
            this.roleResponsibility.id = '';
            this.$v.roleResponsibility.$reset();
        },
        handleCancel: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.clearData();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.roleResponsibility.id = data.id;
            this.roleResponsibility.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibility.role = data.role;
            this.roleResponsibility.preparedById = data.preparedById;
            this.roleResponsibility.responsibilities = data.responsibility.split(',');
            this.roleResponsibility.active = data.active;
            
        },
        onRowSelected: function(items) {
            if (items != null){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;
                this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId.toString();
                this.roleResponsibilityDetail.role = data.role;
                this.roleResponsibilityDetail.responsibility = data.responsibility;
                this.roleResponsibilityDetail.preparedBy = data.preparedBy;
                this.roleResponsibilityDetail.createdDt = data.createdDt;
                this.roleResponsibilityDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        arrayToString: function (arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },
        activation: function(item) {
            console.log(item.active,item.id,item.preparedById);
            this.isBusy = true;
            if (item.active == true) {

                this.roleResponsibility.id = item.id;
                this.roleResponsibility.entity = this.entity;
                this.roleResponsibility.active = 0;
                this.updateActivationState();

            } else {
                this.roleResponsibility.id = item.id;
                this.roleResponsibility.entity = this.entity;
                this.roleResponsibility.active = 1;
                this.updateActivationState();
                }

        },

        updateActivationState: function() {
            
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.roleResponsibility)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.roleResponsibility.active = true;
                    this.getRoleResponsibility();
                })
                .catch(error => {
                        this.isBusy = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
        },
        

    }
}
</script>