<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <FinancialTxnMasterList :ermMasterInfo="ermMasterInfo"></FinancialTxnMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import FinancialTxnMasterList from '@/components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        FinancialTxnMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showFinancialTxnMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Financial Transaction Group List",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
                tooltip: 'New Financial Transaction Group',
                redirectURLName: 'lanftx',
                newEntityMasterURL: 'ftxmas',
                redirectURL: '/ftxmas',
                actionName: '',
                previewURLName: 'lanftxrep',
                showTypeLookup: true,
                showSingleMasterControl: true,
                masterLinkTitle: 'Link Budget Plan',
                masterLinkEntity: 'budget_plan_master',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>