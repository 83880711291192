<template>
    <div>
        <b-modal ref="reviewModal" size="md" :id="id" @show="clearData" :title="reviewModalTitle" :no-close-on-backdrop="true">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.review.userList.$error }">
                                <multiselect v-model="review.userList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.review.userList.$error" class="invalid-feedback">
                                <span v-if="!$v.review.userList.checkNull" class="text-danger">Please select User.</span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="12">
                            <textarea v-model="review.message" class="form-control" rows="4" maxlength="1000" placeholder="Enter Message"></textarea>
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveReview" class="float-right py-1 px-3 mr-2">Share</b-button>
                    <b-button variant="secondary" :disabled="showOverlay" size="sm" class="float-right py-1 px-3 mr-2" @click="cancel()">Cancel</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    props: {
        id: { type: String, default: 'reviewModal' },
        masterData: Object,
        ermId: Number,
        previewURL: String,
        entity: String,
        reviewModalTitle: {
            type: String,
            default: 'Review Share'
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                typeVisible: false,
                typeEdit: false
            },
            vhextAccountId: '',
            projectId: '',
            users: [],
            review: {
                userList: [],
                message: '',
                isReviewed: 1,
                // ERM Review Status Lookup Ids
                    // 1 - Pending
                    // 2 - Reviewed
                statusLookupId: 1,
            }
        }
    },
    validations: {
        review: {
            userList: {
                checkNull: function(userList) {
                    return userList.length > 0
                }
            }
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.review.requestedById = localStorage.getItem("userId");
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.entity = this.entity;
            this.review.previewURL = this.previewURL;
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/getuser', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                })
        },
        saveReview: function() {
            this.$v.review.userList.$touch();
            if (this.$v.review.userList.$invalid) {
                return;
            }
            this.review.entityRecordMasterId = this.ermId;
            console.log(this.review);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/review/doc', this.review)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(response.data.status);
                    if (response.data.status == true) {
                        this.$bvModal.hide(this.id);
                        iziToast.success({
                            title: 'Success',
                            message: 'Document Sent for Review.',
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearData: function() {
            this.review.userList = [];
            // this.review.message = '';
            this.$v.review.userList.$reset();
        },
        onRefDataChange: function() {
            this.review.vhextAccountId = this.vhextAccountId;
            this.review.projectId = this.projectId;
            this.review.requestedById = localStorage.getItem("userId");

            // this.ermId = this.masterData.refErmId;  
            this.review.entityRecordMasterId = this.masterData.refErmId;                      
            this.review.message = this.masterData.title;            
            this.review.entity = this.masterData.refEntity;
        }

    }
}
</script>