import Activation from '@/views/home/Activation';
import ForgotPassword from '@/views/user/ForgotPassword.vue'
import JobView from '@/views/resource/JobView.vue'
import EmailUpdateConfirmation from '@/views/user/EmailUpdateConfirmation.vue'

export default [{
        path: '/vmx/activation/:key',
        name: 'vmxactivation',
        component: Activation,
        meta: { layout: 'login' }
    },
    {
        path: '/resetpassword/:key',
        name: 'resetpassword',
        component: ForgotPassword,
        meta: { layout: 'login' }
    },
    {
        path: '/jobview/:key',
        name: 'jobview',
        component: JobView,
        meta: { layout: 'public' }
    },
    {
        path: '/emailchange/:key',
        name: 'emailupdateconfirmation',
        component: EmailUpdateConfirmation,
        meta: { layout: 'public' }
    },
];