<template>
    <div class="type">
        <b-modal scrollable ref="finTxnTypeModal" size="md" id="finTxnTypeModal" centered modal-class="typeModal" :title="finTxnTypeLookupTitle" @hide="closeDialog" :no-close-on-backdrop="true" ok-title="Ok">
            <div class="py-2 px-0">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                            <span v-if="!elements.typeEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Type</span>
                            <span v-if="elements.typeEdit">Edit</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.typeVisible" role="tabpanel">
                        <b-card-body class="py-0 px-1">
                            <b-row class="mb-1">
                                <div class="col-8">
                                    <div class="form-group">
                                        <label>Transaction Type</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.customType.type.$error }">
                                            <b-form-input id="title" v-model="customType.type" :class="{ 'is-invalid': $v.customType.type.$error }" placeholder="Enter Type"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.customType.type.$error && !$v.customType.type.required" class="text-danger">Please Enter Type</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label>Amount Type</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.customType.typeClass.$error }">
                                            <select v-model="customType.typeClass"  :class="{ 'is-invalid': $v.customType.typeClass.$error }" class="form-control">
                                                <option value="">Select</option>
                                                <option value="1">CR</option>
                                                <option value="2">DR</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.customType.typeClass.$error && !$v.customType.typeClass.required" class="text-danger">Please select amount type</label>
                                    </div>
                                </div>
                            </b-row>
                            <button type="submit" @click="clearTypeData" class="btn btn-secondary btn-xs float-right mb-3 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                            <button type="submit" @click="saveCustomTypeData" class="btn btn-primary btn-xs float-right mb-3 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div class="float-right">
                    <div class="dataTables_length">
                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="customTypes" :fields="customTypeFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template v-slot:cell(typeClass)="data">
                            <div v-if="data.item.typeClass === '1'">CR</div>
                            <div v-if="data.item.typeClass === '2'">DR</div>
                    </template>
                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3 float-left" v-model="data.item.active" @change="deActivate(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <a href="javascript:" title="Edit Types" @click="showTypeEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                        <!-- <a href="javascript:" title="Delete" @click="removeCustomType(data.item.id)" data-toggle="modal" data-target="#removeCustomType"><i class="fa fa-trash pl-2"></i></a> -->
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Types found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                <b-pagination v-if="customTypes && customTypes.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
            </div>
        </b-modal>
        <!--        <Dialog id="deActivate" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />  -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        projectTypeId: [String, Number],
        entity: String,
        finTxnTypeLookupTitle: String
    },
    components: {

    },
    data() {
        return {
            elements: {
                typeVisible: true,
                typeEdit: false
            },
            notEnabled: true,
            customType: {
                id: '',
                customTypeLookupId: '',
                entity: 'custom_type_lookup',
                active: '',
                type: '',
                typeClass: '',
                vhextAccountId: '',
                projectId: '',
                entityId: '',
                userId: '',
                createdById: localStorage.getItem("userId"),
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            customTypes: [],
            customTypeFields: [
                { key: 'customTypeLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'typeClass', label: 'Amount Type', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    validations: {
        customType: {
            type: {
                required
            },
            typeClass: {
                required
            }
        }
    },
    watch: {
        'entity': {
            handler: function(val1, val2) {
                this.getCustomTypeLookups();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.getCustomTypeLookups();
    },
    methods: {
        getCustomTypeLookups: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity
            }
            console.log(data);
            if (this.entity != '') {
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.customTypes = result.customTypeLookupList;         
                        if (this.customTypes != null)
                            this.totalRows = this.customTypes.length;
                    })
            }
        },
        saveCustomTypeData: function() {
            this.$v.customType.$touch();
            if (!this.$v.customType.$invalid) {
                if (this.elements.typeEdit) {
                    this.updateCustomTypeLookup();
                } else {
                    this.addCustomTypeLookup();
                }
            }
        },
        addCustomTypeLookup: function() {
            this.customType.vhextAccountId = this.vhextAccountId;
            this.customType.projectId = this.projectId;
            this.customType.projectTypeId = this.projectTypeId;
            this.customType.entity = this.entity;
            console.log(this.customType);
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/add', this.customType)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.clearTypeData();
                        this.getCustomTypeLookups();
                    }
                })
        },
        updateCustomTypeLookup: function() {
            console.log(this.customType)
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/update', this.customType)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.clearTypeData();
                        this.getCustomTypeLookups();
                    }
                })
        },
        updateCustomTypeLookupActiveState: function() {
            console.log(this.customType)
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/lookupstate/update', this.customType)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    
                    this.$emit('refresh-lookup');
                    this.getCustomTypeLookups();

                })
        },

        clearTypeData: function() {
            this.customType.customTypeLookupId = '';
            this.customType.active = true;
            this.customType.type = '';
            this.customType.typeName = '';
            this.customType.typeClass = '';
            this.customType.id = '';
            this.$v.customType.$reset();
            this.elements.typeEdit = false;
            this.elements.typeVisible = false;
        },
        showTypeEdit: function(items) {
            let data = items;
            console.log(data.typeClass)
            this.elements.typeVisible = true;
            this.elements.typeEdit = true;
            this.customType.customTypeLookupId = data.customTypeLookupId;
            this.customType.active = data.active;
            this.customType.type = data.type;
            this.customType.typeName = data.typeName;
            this.customType.typeClass = data.typeClass;
            this.customType.vhextAccountId = data.vhextAccountId;
            this.customType.projectId = data.projectId;
            this.customType.projectTypeId = data.projectTypeId;
            this.customType.entityId = data.entityId;
            this.customType.id = data.id;
        },
        deActivate: function(item) {
            this.$nextTick(() => {
                console.log(item.active, item.id)

                if (item.active == true) {
                    this.customType.id = item.id;
                    this.customType.active = 1;
                    this.customType.userId = this.userId;
                    this.updateCustomTypeLookupActiveState();
                    console.log(item.active, this.customType.active, item.id)
                } else {
                    this.customType.id = item.id;
                    this.customType.active = 0;
                    this.customType.userId = this.userId;
                    this.updateCustomTypeLookupActiveState();
                    console.log(item.active, this.customType.active, item.id)
                }
            })
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>