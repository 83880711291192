<template>
	<div>
		<b-overlay :show="showOverlay" opacity="0.4">
			<div class="form-row input-group-label px-2">
	            <div class="col-4">
	                <b-input-group :prepend="ermMasterInfo.actionName" class="pb-2">
	                    <select v-model="ermData.entityRecordMasterId" @change="onErmChange" class="form-control">
	                        <option value="">Select</option>
	                        <option v-for="(info, index) in entityRecordMasterList" :value="info.id">{{info.title}}</option>
	                    </select>
	                </b-input-group>
	            </div>
	            <div class="col-auto px-0">
	                <div class="d-flex align-middle pb-2">
	                    <a class="cust-setting-icon mr-1" href="javascript:" v-b-modal="'prjScheduleAddErmMaster'+id"><i class="fa fa-plus-square fa-lg action-link" aria-hidden="true"></i></a>
	                    <!-- <a v-if="ermData.entityRecordMasterId" class="cust-setting-icon" href="javascript:"><i class="fa fa-cog fa-lg action-link" aria-hidden="true"></i></a> -->
	                </div>
	            </div>
	            <div class="col text-right">
                    <ul v-if="ermData.entityRecordMasterId" class="table-expand text-right py-2">
                        <li class="px-2">
                            <a href="javascript:" class="action-link" title="Report View"  @click="routeReport(entityRecordMasterData.id,entityRecordMasterData.type,entityRecordMasterData.typeCode,entityRecordMasterData.title,entityRecordMasterData.isFreeze)"><i class="fa fa-eye" aria-hidden="true"></i> Report</a>
                        </li>
                        <li class="px-2">
                            <a href="javascript:" class="action-link" title="Share Document Access" @click="ermShare(entityRecordMasterData)" v-b-modal="'prjScheduleShareModal'+id"><i class="fa fa-share-alt pr-1" aria-hidden="true"></i>Share</a>
                        </li>
                        <li class="px-2">
                            <a href="javascript:" class="action-link" title="Share Doc for Review" @click="reviewShare(entityRecordMasterData)" v-b-modal="'prjScheduleReviewModal'+id"><i class="fa fa-comments-o pr-1" aria-hidden="true"></i>Review</a>
                        </li>
                        <li class="px-2">
                            <a href="javascript:" class="action-link" title="Share Doc for Approval" @click="approvalShare(entityRecordMasterData)" v-b-modal="'prjScheduleApprovalModal'+id"><i class="fa fa-check pr-1" aria-hidden="true"></i>Approval</a>
                        </li>
                        <li class="px-2">
                            <a href="javascript:" class="action-link" title="Assign" @click.prevent="taskAssignment(entityRecordMasterData)" ><i class="fa fa-user-plus pr-1" aria-hidden="true"></i>Assign</a>
                        </li>
                        <li class="px-2">
                            <div v-if="!entityRecordMasterData.isFreeze">
                                <a href="javascript:" class="action-link" title="Freeze" @click.prevent="freezeERM(entityRecordMasterData)"><i class="fa fa-unlock-alt mr-1" aria-hidden="true"></i>Freeze</a>
                            </div>
                            <div v-if="entityRecordMasterData.isFreeze">
                                <a href="javascript:" class="action-link" title="Unfreeze" @click.prevent="freezeERM(entityRecordMasterData)"><i class="fa fa-lock mr-1" aria-hidden="true"></i>Unfreeze</a>
                            </div>
                        </li>
                        <li v-if="entityRecordMasterData.status" class="px-2">
                            <span v-if="entityRecordMasterData.status == 'Draft'" class="badge badge-pill badge-light mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'Open'" class="badge badge-pill badge-info mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'In Progress'" class="badge badge-pill badge-info mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'Reviewed'" class="badge badge-pill badge-warning mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'Approved'" class="badge badge-pill badge-success mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{ entityRecordMasterData.status }}</span>
			                <span v-else-if="entityRecordMasterData.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{ entityRecordMasterData.status }}</span>
                            <span v-else class="badge badge-pill badge-light mb-1">{{ entityRecordMasterData.status }}</span>
                        </li>
                    </ul>
                </div>
	        </div>
            <b-card v-if="ermData.entityRecordMasterId" no-body class="mb-3 mt-2">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle="'accordion-det'+id" >
                        <span>Header</span>
                    </label>
                </b-card-header>
                <b-collapse :id="'accordion-det'+id" class="py-1 px-2" role="tabpanel">
                	<div class="row">
                        <!-- <div class="col-12 col-md-2">
                            <div class="row no-gutters text-center">
                            	<div class="col-12">
	                                <img v-if="entityRecordMasterData.photo" :src="entityRecordMasterData.photo" height="50" alt="Profile picture">
	                                <div v-else class=""><img src="@/assets/img/defaultuser.png" height="50" alt="" /></div>
                            	</div>
                            	<div class="col-12">
	                                <label class="mb-0 mt-1">{{ entityRecordMasterData.createdBy }}</label>
                            	</div>
                            	<div class="col-12">
                            	</div>
                            </div>
                        </div> -->
                        <div v-if="!elements.editMode" class="col-7 pr-0">
                            <div class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Title</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{entityRecordMasterData.title}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Status</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{entityRecordMasterData.status}}</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col text-left">
                                    <div class="mt-1">
                                        <span v-if="entityRecordMasterData.status == 'Draft'" class="badge badge-pill badge-light mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'Open'" class="badge badge-pill badge-info mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'In Progress'" class="badge badge-pill badge-info mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'Reviewed'" class="badge badge-pill badge-warning mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'Approved'" class="badge badge-pill badge-success mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{ entityRecordMasterData.status }}</span>
                                        <span v-else-if="entityRecordMasterData.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{ entityRecordMasterData.status }}</span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row">
                                <div v-if="ermMasterInfo.showTypeLookup" class="col-12 col-sm-12">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Type</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{entityRecordMasterData.type}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="ermMasterInfo.showDateRange" class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Start Date</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{formatDate(entityRecordMasterData.startDate)}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">End Date</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{formatDate(entityRecordMasterData.endDate)}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="ermMasterInfo.showErmDate" class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Month</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{ getMonthYearAlt(entityRecordMasterData.ermDate) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div v-if="ermMasterInfo.showMultipleMasterControl" class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{ refErmList && refErmList.length>0 ? refErmList.toString() : '' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="ermMasterInfo.showMultipleMasterControl" class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{ entityRecordMasterData.refErmId }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="ermMasterInfo.showPlanLinkControl" class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{ entityRecordMasterData.refErdData }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-row">
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Client</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{ entityRecordMasterData.clientName }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Created By</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-2 mb-0 d-block header-details form-control">{{ entityRecordMasterData.createdBy }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12">
                                    <div class="card-body p-0 my-0">
                                        <b-card no-body>
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle="'accordion-desc'+id">
                                                    <span>Description</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-desc'+id" accordion="my-accordion1" role="tabpanel">
                                                <b-card-body class="p-1 pb-0">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12">
                                                            <div class="form-group">
                                                                <p v-if="entityRecordMasterData.description"><span v-html="entityRecordMasterData.description"></span></p>
                                                                <p v-else class="text-muted my-2 text-center">No description</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="elements.editMode" class="col-11 col-md-7 pr-0">
                            <div class="form-row">
                                <div class="col-6 col-sm-6">
                                    <!-- <div class="form-group">
                                        <label for="title1">Title</label>
                                        <input :disabled="!elements.editMode" v-model="entityRecordMasterData.title" type="text" class="form-control">
                                    </div> -->
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Title</label>
                                        </div>
                                        <div class="col-8">
                                            <input :disabled="!elements.editMode" v-model="entityRecordMasterData.title" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 col-md-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Status</label>
                                        </div>
                                        <div class="col-8">
                                            <select v-if="elements.editMode" v-model="entityRecordMasterData.statusId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in masterStatus" :key="info.id" :value="info.id" @click="entityRecordMasterData.statusId = info.id;entityRecordMasterData.status = info.value">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-1">
                                    <div class="more-btns text-center">
                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                            <i class="fa fa-pencil fa-sm action-link" aria-hidden="true"></i>
                                        </button>
                                        <div v-if="elements.editMode">
                                            <button type="submit" @click="updateERMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                            <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <div class="form-row">
                                <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Type</label>
                                        </div>
                                        <div class="col-8">
                                            <select v-if="elements.editMode" v-model="entityRecordMasterData.ermTypeId" @change="handleErmTypeChange" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="ermMasterInfo.showDateRange">
                                    <div class="col-6">
                                        <div class="row no-gutters">
                                            <div class="col-3">
                                                <label class="mb-1 d-block title-block form-control">Start Date</label>
                                            </div>
                                            <div class="col-8">
                                                <input v-if="elements.editMode" type="date" class="form-control" v-model="entityRecordMasterData.startDate" id="startDate">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row no-gutters">
                                            <div class="col-3">
                                                <label class="mb-1 d-block title-block form-control">End Date</label>
                                            </div>
                                            <div class="col-8">
                                                <input v-if="elements.editMode" type="date" class="form-control" v-model="entityRecordMasterData.endDate" id="endDate">
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div v-if="ermMasterInfo.showErmDate" class="col-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Month</label>
                                        </div>
                                        <div class="col-8">
                                            <input v-if="elements.editMode" type="month" class="form-control" v-model="entityRecordMasterData.ermDate" id="ermDate">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showMultipleMasterControl" class="col-12 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                        </div>
                                        <div class="col-8">
                                            <multiselect v-model="refErmList" :options="ermLookup" :disabled="!elements.editMode" name="masterLink" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showSingleMasterControl" class="col-12 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                        </div>
                                        <div class="col-8">
                                            <select :disabled="!elements.editMode" v-model="entityRecordMasterData.refErmId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in ermLookup" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showPlanLinkControl" class="col-12 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                        </div>
                                        <div class="col-8">
                                            <select :disabled="!elements.editMode" v-model="refErdData" class="form-control">
                                                <option value="{id:'',ermId:''}">Select</option>
                                                <option v-for="(info, index) in commonErdLookup" :value="{id:info.id,ermId:info.ermId}">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="getErmTypeCode == 'PRB'" class="col-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Project</label>
                                        </div>
                                        <div class="col-8">
                                            <input type="text" class="form-control" disabled v-model="projectName" id="startDate">
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Client</label>
                                        </div>
                                        <div class="col-8">
                                            <select v-if="elements.editMode" v-model="entityRecordMasterData.clientId" id="clientId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="col-6 col-sm-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Client</label>
                                        </div>
                                        <div class="col-8">
                                            <select v-if="elements.editMode" v-model="entityRecordMasterData.clientId" id="clientId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row no-gutters">
                                        <div class="col-4">
                                            <label class="mb-1 d-block title-block form-control">Created By</label>
                                        </div>
                                        <div class="col-8">
                                            <select v-if="elements.editMode" v-model="entityRecordMasterData.createdById" id="createdById" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-2">
                                    <div class="card-body p-0 my-0">
                                        <b-card no-body>
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle="'accordion-desc'+id">
                                                    <span>Description</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse :id="'accordion-desc'+id" accordion="my-accordion1" role="tabpanel">
                                                <b-card-body class="p-1 pb-0">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12">
                                                            <div v-if="elements.editMode" class="form-group">
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="entityRecordMasterData.description"></editor>
                                                            </div>
                                                            <div v-else class="form-group">
                                                                <p v-if="entityRecordMasterData.description"><span v-html="entityRecordMasterData.description"></span></p>
                                                                <p v-else class="text-muted my-2 text-center">No description</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                		</div>
                        <div class="col-1">
                            <div class="more-btns text-center">
                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                    <i class="fa fa-pencil fa-sm action-link" aria-hidden="true"></i>
                                </button>
                                <div v-if="elements.editMode">
                                    <button type="submit" @click="updateERMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                    <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                		<div class="col-11 col-md-4">
                            <div class="card-body p-0 mb-1">
                                <b-card no-body>
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.attachment-accordion>
                                            <span>
                                                <i class="fa fa-paperclip pr-1 action-link" aria-hidden="true"></i>
                                                Attachment
                                            </span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="attachment-accordion" accordion="docShare-accordion" role="tabpanel">
                                        <b-card-body class="p-1 pb-0">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                            <div class="attachments mb-2">
                                                                <b-form-file ref="fileinput" class="attachments-list" size="sm" v-model="masterAttachments[index]" placeholder="Choose a file.">
                                                                    <template slot="file-name" slot-scope="{ names }">
                                                                        <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                    </template>
                                                                </b-form-file>
                                                                <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                                    <i class="fa fa-check pr-2"></i>
                                                                </a>
                                                            </div>
                                                            <label v-if="error" class="text-danger">Please choose a file.</label>
                                                        </div>
                                                    </div>
                                                    <div v-show="attachments.length != 0" class="col-12">
                                                        <div class="form-group">
                                                            <div>
                                                                <p v-for="(attachment, index) in attachments">
                                                                    <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                                    <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                    <a href="javascript:" v-b-modal="'deleteAttachment'+id" @click="deleteAttachment(attachment.id)" title="Delete attachment" class="float-right button-remove p-2 attachments-remove">
                                                                        <i class="fa fa-trash pr-2 action-link"></i>
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="card-body p-0 mb-1">
                                <b-card no-body>
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.reviewApprovalAccordion>
                                            <span>
                                                <i class="fa fa-check-square-o pr-1 action-link" aria-hidden="true"></i>
                                                Review & Approvals
                                            </span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="reviewApprovalAccordion" accordion="docShare-accordion" role="tabpanel">
                                        <b-card-body class="p-1 pb-0">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="entityReviewNotApprovalList" :fields="reviewFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:empty="">
                                                    <div class="text-center">No Reviews found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="">
                                                    <div class="text-center">{{ }}</div>
                                                </template>
                                            </b-table>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="card-body p-0 mb-1">
                                <b-card no-body>
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.docShareAccordion>
                                            <span>
                                                <i class="fa fa-share-alt pr-1 action-link" aria-hidden="true"></i>
                                                Doc Share History
                                            </span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="docShareAccordion" accordion="docShare-accordion" role="tabpanel">
                                        <b-card-body class="p-1 pb-0">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="ermShareList" :fields="ermShareFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(ermShareMode)="data">
                                                    <span v-if="data.value == 1" class="badge badge-warning mb-1">Editor</span>
                                                    <span v-else class="badge badge-info mb-1">Viewer</span>
                                                </template>
                                                <template v-slot:empty="">
                                                    <div class="text-center">No Shared Users found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="">
                                                    <div class="text-center">{{ }}</div>
                                                </template>
                                            </b-table>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                		</div>
                	</div>
                </b-collapse>
            </b-card>
		    <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
        <DeleteComponent :id="'deleteAttachment'+id" :onYes="onYesAttachment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
        <AddErmMasterPopup :id="'prjScheduleAddErmMaster'+id" ref="prjScheduleAddErmMaster" :ermMasterInfo="ermMasterInfo" @update-parent="getEntityErmList" />
        <QuickTaskPopup :id="'prjScheduleTaskAssignment'+id" ref="ermAssignmentsRef" :masterData="taskAssignMasterData" />
        <Share :id="'prjScheduleShareModal'+id" ref="ermShareRef" :key="ermShareKey" :masterData="masterData" :ermId="Number(entityRecordMasterData.id)" :entity="ermMasterInfo.entity" :sharedUrl="ermMasterInfo.sharedUrlName" />
        <Review :id="'prjScheduleReviewModal'+id" ref="reviewShareRef" :key="ermReviewKey" :masterData="masterData" :ermId="Number(entityRecordMasterData.id)" :entity="ermMasterInfo.entity" :previewURL="ermMasterInfo.previewURLName" />
        <Approval :id="'prjScheduleApprovalModal'+id" ref="approvalShareRef" :key="ermApprovalKey" :masterData="masterData" :ermId="Number(entityRecordMasterData.id)" :entity="ermMasterInfo.entity" :previewURL="ermMasterInfo.previewURLName" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import AddErmMasterPopup from '@/components/popup/action/AddErmMasterPopup.vue';
import Review from '@/components/usercontrols/ReviewControl.vue';
import Approval from '@/components/usercontrols/ApprovalControl.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import Share from '@/components/usercontrols/ShareControl.vue';
export default {
	props: {
		id: String,
		ermMasterInfo: Object,
	},
    components: {
        AddErmMasterPopup,
        Editor,
        Review,
        Approval,
        QuickTaskPopup,
        Share,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            error: false,

            ermShareKey: 2000,
            ermReviewKey: 4000,
            ermApprovalKey: 6000,

            ermData: {
                ermTitle: '',
                entityId: '',
                entityRecordMasterId: '',
            },

            // ermMasterInfo: {
            //     title: "Project Schedule",
            //     entity: 'plan_master',
            //     refEntity: 'plan',
            //     tooltip: 'New Project Schedule',
            //     redirectURLName: 'plan',
            //     newEntityMasterURL: 'plnmas',
            //     redirectURL: '/plnmas',
            //     actionName: 'Plan Item',
            //     previewURLName: 'plnrep',
            //     // masterLinkTitle: 'Link Requirement',
            //     // masterLinkEntity: 'system_requirement_spec_master',
            //     hideProject: true,
            // },

            entityRecordMasterData: {},

            refErdData:{
                id: '',
                ermId:'',
            },

            masterData: {
                refErmId: 0,
                title: '',
                refEntityId: 0,
                refErdTitle: '',
                refEntity: '',
                sharedUrl: '',
                previewURL: '',
            },
            taskAssignMasterData: {
                refErmId: 0,
                title: '',
                refEntityId: 0,
                refErdTitle: '',
                refEntity: '',
                sharedUrl: '',
                previewURL: '',
                refErdDescription: '',
                statusId: '',
            },

            messageBoxData: {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'xs',
                okVariant: 'primary',
                cancelTitle: 'No',
                okTitle: 'Yes',
                headerClass: 'py-2 px-3',
                bodyClass: 'fs-14 px-3',
                footerClass: 'py-2 px-3',
                centered: true,
                hideHeaderClose: false
            },

            entityRecordMasterList: [],

            entityReviewList: [],
            entityReviewNotApprovalList: [],
            entityReviewApprovalList: [],
            clients: [],
            refErmList: [],
            // ermLookup: [],
            // erdLookup: [],
            entityRecordCustomType: [],
            masterStatus: [],
            allUsers: [],
            ermShareList: [],
            // ermReferenceList: [],

            masterAttachments: [],
            attachments: [],
            addMore: [],

            plans: [],
            planFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'planId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'phaseTitle', label: 'Title', sortable: true, sortDirection: 'desc' },
                // { key: 'process', label: 'Process', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Description', tdClass: 'w-30', sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                // { key: 'effort', label: 'Effort', sortable: true, sortDirection: 'desc' },
                // { key: 'estimatedCost', label: 'Cost', sortable: true, sortDirection: 'desc' },
                { key: 'responsibility', label: 'Responsible Person', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],

            reviewFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' }
            ],

            ermShareFields: [
                { key: 'index', label: 'SN', sortable: true, sortDirection: 'desc' },
                { key: 'sharedBy', label: 'Shared By', sortable: true, sortDirection: 'desc' },
                { key: 'sharedTo', label: 'Shared To', sortable: true, sortDirection: 'desc' },
                { key: 'sharedDt', label: 'Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'ermShareMode', label: 'Share Mode', sortable: true, sortDirection: 'desc' }
            ],

            toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            initObj: {
                height: 150,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        // entityRecordMasterData() {
        //     let entityRecordMasterData = {
        //         id: '',
        //         type: '',
        //         typeCode: '',
        //         title: '',
        //         status: '',
        //         isFreeze: false,
        //     };
        //     if (this.entityRecordMasterList.length > 0 && this.ermData.entityRecordMasterId) {
        //         let ermData = this.entityRecordMasterList.find(data => data.id == this.ermData.entityRecordMasterId);
        //         entityRecordMasterData = ermData ?? entityRecordMasterData; 
        //     }
        //     return entityRecordMasterData;
        // },
        users() {
            if (this.userTypeCode == 'CLI') {
                return this.allUsers.filter((user) => user.id == this.userId)
            } else return this.allUsers;
        },
        getErmTypeCode() {
            if (this.entityRecordCustomType.length>0) {
                let typeData = this.entityRecordCustomType.find((data) => data.id == this.entityRecordMasterData.ermTypeId);
                return typeData ? typeData.typeCode : null;
            } else return null;
        },
        commonErdLookup() {
            if(this.entityRecordMasterData.projectId && this.entityRecordMasterData.projectId != '') {
                return this.erdLookup.filter((data) => data.projectId == this.entityRecordMasterData.projectId);
            } else {
                return this.erdLookup;
            }
        },
    },
    mounted() {
        this.addMore.push(this.addMore.length + 1);
        this.getEntityErmList();
        this.masterLookupData();
    },
    methods: {
        getEntityErmList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
                isArchive: false,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.entityRecordMasterList = result.entityRecordMasterList;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        masterLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.clientLookup;
                    this.entityRecordCustomType = result.typeLookup;
                    this.masterStatus = result.statusLookup;
                    this.allUsers = result.userLookup;
                    console.log(result);
                })

        },
        getErmReviewApprovalList: function() {
        	if(this.ermData.entityRecordMasterId) {
	            let data = {
	                entityRecordMasterId: this.ermData.entityRecordMasterId,
	            }
	            this.showOverlay = true;
	            axios.post(this.$store.getters.getAPIBasePath + '/common/ermshare/list', data)
	                .then((response) => {
	                    this.showOverlay = false;
	                    let result = response.data;
	                    this.ermShareList = result.ermShareList;
	                })
	                .catch((error)=> {
	                    this.showOverlay = false;
	                    iziToast.error({
	                        title: 'Error',
	                        message: 'Error',
	                        position: 'topRight'
	                    });
	                })
            }
        },
        getErmShareList: function() {
            if(this.ermData.entityRecordMasterId) {
                let data = {
                    entityRecordMasterId: this.ermData.entityRecordMasterId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/ermreviewapproval/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.entityReviewList = result.entityReviewApprovalList;
                        this.entityReviewNotApprovalList = this.filterReviewApproval(false);
                        this.entityReviewApprovalList = this.filterReviewApproval(true);
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        saveAttachment: function() {
            if (this.masterAttachments != null && this.masterAttachments.length > 0) {
                this.error = false;
                let formData = new FormData();
                for (const row of Object.keys(this.masterAttachments)) {
                    formData.append('files', this.masterAttachments[row]);
                }
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('attachedById', this.userId);
                formData.append('projectId', this.projectId);
                formData.append('refEntity', this.ermMasterInfo.entity);
                formData.append('refRecordId', this.ermData.entityRecordMasterId);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.masterAttachments = [];
                            //this.$refs.fileinput.reset();
                            //this.$refs['fileinput'].reset();
                            this.getAttachment(this.ermData.entityRecordMasterId);
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    });
            } else {
                this.error = true;
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
            }
        },
        getAttachment: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.ermMasterInfo.entity,
                refRecordId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.attachments = result.attachmentList;
                })
        },
        deleteAttachment: function(id) {
            this.deleteParam.id = id;
        },
        onYesAttachment: function(id) {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment(this.ermData.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        filterReviewApproval(value) {
            return this.entityReviewList.filter(function(data, key) {
                if (data.isApproval == value) {
                    return data;
                }
            });
        },
        viewEntityRecordMaster: function() {
            if(this.ermData.entityRecordMasterId) {
            	let data = {
                    entityRecordMasterId: this.ermData.entityRecordMasterId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/view', data)
                    .then((response) => {
		                this.showOverlay = false;
                        let result = response.data;
                        this.entityRecordMasterData = result.entityRecordMaster;
                        this.handleErmTypeChange();
                        this.entityRecordMasterData.refErmId = this.entityRecordMasterData.refErmId ? this.entityRecordMasterData.refErmId : null;
                        if(this.ermMasterInfo.showPlanLinkControl){
                            this.refErdData.ermId = this.entityRecordMasterData.refErmId;
                            this.refErdData.id = this.entityRecordMasterData.refErdId;
                        }            
    
                    })
                    .catch((error) => this.showOverlay = false)
            }
        },
        handleErmTypeChange: function() {
            let typeData = this.entityRecordCustomType.find((data) => {
                if (data.id == this.entityRecordMasterData.ermTypeId) {
                    return data;
                }
            });
            if (this.ermMasterInfo.entity == 'budget_plan_master') {
                let ermDate = this.entityRecordMasterData.ermDate;
                let startDate = this.entityRecordMasterData.startDate;
                let endDate = this.entityRecordMasterData.endDate;
                if(typeData && typeData.typeCode == 'MOB') {
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showErmDate = true;
                    this.ermMasterInfo.showMultipleMasterControl = false;

                    this.entityRecordMasterData.ermDate = ermDate ? this.getMonthYear(ermDate) : '';
                    this.entityRecordMasterData.startDate = '';
                    this.entityRecordMasterData.endDate = '';
                }
                else if(typeData && typeData.typeCode == 'PEB') {
                    this.ermMasterInfo.showDateRange = true;
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMasterData.ermDate = '';
                    this.entityRecordMasterData.startDate = startDate ? this.getDateValue(startDate) : '';
                    this.entityRecordMasterData.endDate = endDate ? this.getDateValue(endDate) : '';
                }
                else if(typeData && typeData.typeCode == 'BTI') {
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showMultipleMasterControl = true;
                    this.entityRecordMasterData.ermDate = '';
                    this.entityRecordMasterData.startDate = '';
                    this.entityRecordMasterData.endDate = '';
                }
                else {
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMasterData.ermDate = '';
                    this.entityRecordMasterData.startDate = startDate ? startDate : '';
                    this.entityRecordMasterData.endDate = endDate ? endDate : '';
                }
            }
            if (this.ermMasterInfo.showMultipleMasterControl && this.entityRecordMasterData.id) {
                this.getRefErmList(this.entityRecordMasterData.id);
            }
        },
        updateERMaster: function() {
            if (this.ermMasterInfo.showDateRange) {
                this.entityRecordMasterData.startDate = this.entityRecordMasterData.startDate ? this.entityRecordMasterData.startDate : '';
                this.entityRecordMasterData.endDate = this.entityRecordMasterData.endDate ? this.entityRecordMasterData.endDate : '';
            }
            if (this.ermMasterInfo.showErmDate) {
                this.entityRecordMasterData.ermDate = this.entityRecordMasterData.ermDate + "-11";
            }
            if (this.ermMasterInfo.showMultipleMasterControl) {
                this.entityRecordMasterData.refErmList = [];
                this.refErmList.forEach((item, index) => {
                    this.entityRecordMasterData.refErmList.push({ refErmId: this.refErmList[index].id })
                })
            }
            else {
                this.entityRecordMasterData.refErmList = [];
            }
            if(this.ermMasterInfo.showPlanLinkControl){
                this.entityRecordMasterData.refErmId = this.refErdData.ermId;
                this.entityRecordMasterData.refErdId = this.refErdData.id;
            }            
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMasterData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.cancelEdit();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        cancelEdit: function() {
            this.viewEntityRecordMaster();
            this.elements.editMode = false;
        },
        onErmChange() {
        	this.viewEntityRecordMaster();
        	this.getErmReviewApprovalList();
            this.getErmShareList();
            this.getAttachment(this.ermData.entityRecordMasterId);
        	this.elements.editMode = false;
        	this.$emit("onErmChange", this.ermData.entityRecordMasterId);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        routeReport: function(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze) {
            this.setMasterData(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze)

            this.$router.push({ name: this.ermMasterInfo.previewURLName, params: { ermId: ermId } })

            return;
        },
        ermShare: function(data) {
            console.log(data);
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.ermMasterInfo.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.ermShareKey++;
            this.$nextTick(() => {
                this.$refs.ermShareRef.onRefDataChange();
            })
        },
        reviewShare: function(data) {
            console.log(data);
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.ermMasterInfo.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.ermReviewKey++;
            this.$nextTick(() => {
                this.$refs.reviewShareRef.onRefDataChange();
            });
        },
        approvalShare: function(data) {
            console.log(data);
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.ermMasterInfo.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.ermApprovalKey++;
            this.$nextTick(() => {
                this.$refs.approvalShareRef.onRefDataChange();
            });
        },
        taskAssignment: function(data) {
            this.$bvModal.show('prjScheduleTaskAssignment'+this.id);
            this.$nextTick(() => {
                this.taskAssignMasterData.refErmId = data.id;
                this.taskAssignMasterData.refEntityId = data.entityId;
                this.taskAssignMasterData.refEntity = this.ermMasterInfo.entity;
                this.taskAssignMasterData.refErdTitle = data.title;
                this.taskAssignMasterData.refErdDescription = data.description;
                this.taskAssignMasterData.statusId = 1;
                this.$refs.ermAssignmentsRef.onRefDataChange();
            })
        },
        setMasterData: function(ermId,ermType,ermTypeCode,ermTitle,ermIsFreeze) {

            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermType', ermType);
            localStorage.setItem('ermTypeCode', ermTypeCode);
            localStorage.setItem('ermTitle', ermTitle);
            localStorage.setItem('ermIsFreeze', ermIsFreeze);

        },
        freezeERM: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isFreeze: (itemData.isFreeze == true ? false : true),
            }
            console.log(data.entityRecordMasterId, data.isFreeze);
            var confirmMessage = '';
            if (data.isFreeze == false) {
                confirmMessage = "Are you sure to unfreeze?"
            } else {
                confirmMessage = "Are you sure to freeze?"
            }
            this.$bvModal.msgBoxConfirm(confirmMessage, this.messageBoxData)
            .then(value => {
                if (value) {
                    axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/freeze', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Record Updated',
                                // message: result.message,
                                position: 'topCenter'
                            });
                            this.cancelEdit();
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>