<template>
    <div>
        <UATMaster :ermMasterInfo="ermMasterInfo"></UATMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import UATMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        UATMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "UAT Master",
                entity: 'uat_master',
                refentity: 'uat_test_case',
                ermListRedirectURL: '/uatmaster/list',
                erdRedirectURL: '/uattestcase/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>`