<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <LanPlan />
            </div>
        </div>
    </div>
</template>
<script>
import LanPlan from '@/views/plan/Plan.vue';
export default {
    components: {
        LanPlan
    }
}
</script>