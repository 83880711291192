<template>
    <div>
        <!-- Main Task Page -->
<!--         <Task :taskBoard="taskBoard"/> -->
        <TaskComponent :taskBoard="taskBoard"/>
    </div>
</template>
<script>
import TaskComponent from '@/components/common/TaskComponent.vue';
export default {
    components: {
        TaskComponent
    },
    data() {
        return {
            taskBoard: {
                isPublic: false,
            }
        }
    },        
}
</script>
