<template>
    <div class="customCategory">
        <b-modal ref="existingCustomCategoryLookup" size="lg" id="existingCustomCategoryLookup" ok-only body-class="pt-0 pb-2" footer-class="py-3" header-class="py-3" @hide="closeDialog" :title="'Add ' + popupDisplayName">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row mb-2 px-2">
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 pb-1 mt-3">
                            <b-tabs v-model="tabIndex" content-class="mt-3" lazy>
                                <b-tab title="Existing Categories">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="existingCustomCategory" :fields="customCategoryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByExt" :sort-desc.sync="sortDescExt" :sort-direction="sortDirection">
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!customCategoryExists(data.item.category)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.category" v-model="selectedCustomCategory">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!customCategoryExists(data.item.category)">
                                                <a href="javascript:" title="Add CustomCategory" class="text-success" @click="addCustomCategory(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!customCategoryExists(data.item.category)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.category" v-model="selectedCustomCategory">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Custom Category found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-if="isStandard == 'true'" title="Custom Category Summary">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="customCategorySummary" :fields="customCategorySummaryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!customCategoryExists(data.item.category)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.category" v-model="selectedCustomCategory">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!customCategoryExists(data.item.category)">
                                                <a href="javascript:" title="Add Custom Category" class="text-success" @click="addCustomCategory(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!customCategoryExists(data.item.category)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.category" v-model="selectedCustomCategory">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Custom Category found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-else title="Standard Categories">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="standardCustomCategory" :fields="customCategoryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!customCategoryExists(data.item.category)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.category" v-model="selectedCustomCategory">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!customCategoryExists(data.item.category)">
                                                <a href="javascript:" title="Add Custom Category" class="text-success" @click="addCustomCategory(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!customCategoryExists(data.item.category)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.category" v-model="selectedCustomCategory">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Custom Category found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 mt-3">
                            <label class="mt-3">{{ popupDisplayName }}</label>
                            <div class="separator mb-3"></div>
                            <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" sort-icon-left :items="customCategory" :fields="customCategoryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Remove" @click="deleteItem(data.item.id)" v-b-modal.deleteItem class="text-danger"><i class="fa fa-minus-circle pr-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Custom Category found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <button type="submit" @click="addBulkCustomCategory" :disabled="selectedCustomCategory.length==0" class="btn btn-primary float-left my-0 mr-2 py-1 px-3">Add Selected</button>
                    <button v-if="selectedCustomCategory.length>0" type="submit" @click="selectedCustomCategory=[];" class="btn btn-outline-primary float-left my-0 mr-2 py-1 px-3">Clear Selection</button>
                    <button type="submit" @click="cancel()" class="btn btn-secondary float-right my-0 mr-2 py-1 px-3">Close</button>
                </div>
            </template>
        </b-modal>
        <DeleteComponent id="deleteItem" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Custom Category ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        entity: String,
        popupDisplayName: String
    },
    data() {
        return {
            showOverlay: false,
            checkboxValue: true,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            isStandard: localStorage.getItem("isStandard"),
            customCategory: {
                customCategoryId: '',
                category: '',
                vhextAccountId: '',
                projectId: '',
                entity: this.entity,
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            existingCustomCategory: [],
            standardCustomCategory: [],
            customCategory: [],
            customCategoryFields: [
                { key: 'selected', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'category', label: 'Custom Category', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            customCategorySummary: [],
            customCategorySummaryFields: [
                { key: 'selected', label: '' },
                { key:'index',label: 'SN'},
                { key: 'category', label: 'Custom Category', sortable: true, sortDirection: 'desc' },
                { key: 'count', label: 'Count', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            selectMode: 'single',
            sortDescExt: false,
            sortDescStd: false,
            sortDesc: false,
            sortBy: '',
            sortByExt: '',
            sortByStd: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            selectedCustomCategory: [],

            deleteParam: {
                id: 0
            }
        }
    },
    watch: {
        tabIndex() {
            this.selectedCustomCategory = [];
        }
    },
    mounted() {
        this.getCustomCategoryList();
        this.getExistingCustomCategory();
        if (this.isStandard == 'true') {            
            this.getCustomCategorySummary();
        } 
        else {
            this.getStandardCustomCategory();
        }
    },
    methods: {
        getCustomCategoryList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.selectedCustomCategory = [];
                    this.customCategory = result.customCategoryLookupList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getExistingCustomCategory: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/existingcustomcategory/list', data)
                .then((response) => {
                    let result = response.data;
                    this.existingCustomCategory = result.existingCustomCategoryList;
                })
        },
        getStandardCustomCategory: function() {
            let data = {
                projectTypeId: this.projectTypeId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/standardcustomcategory/list', data)
                .then((response) => {
                    let result = response.data;
                    this.standardCustomCategory = result.standardCustomCategoryList;
                })
        },
        getCustomCategorySummary: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
                entity: this.entity
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorysummary/list', data)
                .then((response) => {
                    let result = response.data;
                    this.customCategorySummary = result.customCategorySummaryList;
                })
        },
        addCustomCategory: function(data) {
            this.customCategory.vhextAccountId = this.vhextAccountId;
            this.customCategory.projectId = this.projectId;
            this.customCategory.entity = this.entity;
            this.customCategory.active = true;
            this.customCategory.category = data.category;

            if (!this.customCategoryExists(this.customCategory.category)) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/add', this.customCategory)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-lookup');
                            this.getCustomCategoryList();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                iziToast.info({
                    message: 'Custom Category already exist.',
                    position: 'topCenter'
                });
            }
        },
        customCategoryExists: function(category) {
            let result = false;
            this.customCategory.map(data => {
                if (category && data.category) {
                    if (data.category.toLowerCase() == category.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        dataExists: function(category) {
            let result = false;
            this.selectedCustomCategory.map(data => {
                if (data.toLowerCase() == category.toLowerCase()) {
                    result = true;
                }
            });
            return result;
        },
        onRowSelected(items) {
            if (items.length > 0){
                let data = items[0];
                if (!this.customCategoryExists(data.category) && !this.dataExists(data.category)) {
                    this.selectedCustomCategory.push(data.category);
                }
                else if (this.dataExists(data.category)) {
                    this.selectedCustomCategory = this.selectedCustomCategory.filter(e=> e.toLowerCase() !== data.category.toLowerCase());
                }
            }
          },
        closeDialog() {
            this.selectedCustomCategory = [];
            this.$emit('closeDialog');
        },
        customCategoryData: function(data) {
            this.bulkCustomCategoryData = [];
            for (var rowCount = 0; rowCount < this.selectedCustomCategory.length; rowCount++) {
                this.bulkCustomCategoryData.push({
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: this.entity,
                    active: true,
                    category: this.selectedCustomCategory[rowCount],
                    createdById: localStorage.getItem("userId")
                });
            }
        },
        saveBulkCustomCategory: function(data) {
            this.showOverlay = true;    
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategory/bulk/add', this.bulkCustomCategoryData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomCategoryList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        async addBulkCustomCategory(data) {
            await this.customCategoryData(data);
            await this.saveBulkCustomCategory();
        },
        deleteItem: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/customcategorylookup/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCustomCategoryList();
                        this.$emit('refresh-lookup');
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
    }
}
</script>