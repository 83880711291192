<template>
    <div class="client">
        <b-modal ref="contractModal" size="md" centered id="contractorAddPopup" modal-class="contractorModal" @hide="closeDialog" :title="contractorPopupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.contractEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Contractor</span>
                                <span v-if="elements.contractEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.contractVisible" role="tabpanel">
                            <b-card-body class="p-1">
                                <div class="form-row">
                                    <!-- <b-input-group class="form-group py-2"> -->
                                        <!-- <div cols="2" class="align-middle float-left my-auto ml-4 mr-0 p-1" >Contractor</div> -->
                                        <b-col cols="5">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientType.id.$error }">
                                                <select v-model="client.clientType" @change="changeClientType(client.clientType)" :class="{ 'is-invalid': $v.client.clientType.id.$error }" class="form-control">
                                                    <option :value="{id: '', text: ''}">Select</option>
                                                    <option v-for="(info, index) in filteredClientTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                </select>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.client.clientType.$error && !$v.client.clientType.required" class="text-danger">Please Enter Contractor Name</label>
                                        </b-col>
                                        <b-col cols="5">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientName.$error }">
                                                <input type="text" maxlength="100" v-model="client.clientName" :class="{ 'is-invalid': $v.client.clientName.$error }" class="form-control" id="clientName" placeholder="Enter Contractor Name">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.client.clientName.$error && !$v.client.clientName.minLength" class="text-danger">Contractor Name must have at least {{$v.client.clientName.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.client.clientName.$error && !$v.client.clientName.required" class="text-danger">Please enter Contractor Name</label>
                                            <label v-if="$v.client.clientName.$error && !$v.client.clientName.maxLength" class="text-danger">Contractor Name must not exceed {{$v.client.clientName.$params.maxLength.max}} letters.</label>
                                        </b-col>
                                        <!-- <b-col cols="2">
                                            <div class="input-group p-0">
                                                <b-form-checkbox class="custom-toggle" v-model="client.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>                            -->     
                                        <div cols="2" class="text-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveClient" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    <!-- </b-input-group> -->
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="float-right">
                        <div class="dataTables_length">
                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="clients" :fields="contractFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(active)="data">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                            </b-form-checkbox>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <a href="javascript:" title="Edit Types" @click="showContractorEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="removeContractor(data.item.id)" v-b-modal.removeContractor><i class="fa fa-trash pl-2"></i></a>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Contractor found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                                
                    <b-pagination v-if="clients.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeContractor" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Contractor ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        contractorPopupTitle: String
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                contractVisible: false,
                contractEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            client: {
                id: '',
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                userType: '',
                userName: '',
                loginName: '',
                userActive: '',
                clientTypeId: '',
                relationshipTypecodeEnum: '',
                clientTypeName: '',
                clientType: {
                    id: '',
                    text: ''
                },
                type: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            },
            clientTypes: [],

            clients: [],
            contractFields: [
                { key: 'index',label: 'SN'},
                // { key: 'contractId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Sub Contractor Name', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        client: {
            clientType: {
                id: {
                    required
                }
            },
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
        },
    },
    computed: {
        filteredClientTypes() {
            return this.clientTypes.filter((type) => type.value !=='Corporate');
        },
    },
    mounted() {
        this.getClients();
        this.lookupData();
    },
    methods: {
        getClients: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                relationshipTypecodeEnum: 2,        // 2 - Sub Countract
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/client/companyclient/list', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.clientList;
                    this.totalRows = this.clients.length;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clientTypes = result.typeLookup;
                })

        },
        saveClient: function() {
            console.log(this.client);
            if (this.elements.contractEdit) {
                this.updateClient();
            } else {
                this.addClient();
            }
        },
        addClient: function(flag) {

            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }
            this.client.relationshipTypecodeEnum = 2;   // relationshipTypecodeEnum: 1 - client, 2 - Sub Countract
            // if (flag == 'invite') {
            //     this.client.invite = true;
            // } else {
            //     this.client.invite = false;
            // }
            console.log(this.client.clientTypeId, this.client);

            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                this.showOverlay = true;
                this.error = false;
                if (this.client.clientTypeName == 'company') {
                    this.client.contactPerson = this.client.firstName + " " + this.client.middleName + " " + this.client.lastName
                } else {
                    this.client.contactPerson = '';
                }
                console.log(this.client.clientTypeId, this.client);
                axios.post(this.$store.getters.getAPIBasePath + '/client/add', this.client)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        console.log(result);
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: 'Client info loaded successfully',
                                position: 'topRight'
                            });
                            this.getClients();
                            this.clearData();
                            this.$emit('refresh-lookup');
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            this.error = true;
                        }

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                //this.error = true;
            }

        },
        updateClient: function() {
            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }
            this.client.relationshipTypecodeEnum = 2;   // relationshipTypecodeEnum: 1 - client, 2 - Sub Countract
            console.log(this.client);
            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/client/update', this.client)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getClients();
                            this.clearData();
                            this.$emit('refresh-lookup');
                        }
                        console.log(result);

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }

        },
        removeContractor: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/client/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getClients();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.client.id = item.id;
                this.client.active = 0;
                this.client.entity = 'client';
                this.updateActivationState();
            } else {
                this.client.id = item.id;
                this.client.active = 1;
                this.client.entity = 'client';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.client)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getClients();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearData: function() {
            this.elements.contractVisible = false;
            this.elements.contractEdit = false;
            this.client.id = '';
            this.client.title = '';
            this.client.clientType.id = '';
            this.client.clientType.text = '';
            this.client.relationshipTypecodeEnum = '';
            this.client.clientTypeId = '';
            this.client.clientTypeName = '';
            this.client.clientName = '';
            this.client.contactPerson = '';
            this.client.firstName = '';
            this.client.middleName = '';
            this.client.lastName = '';
            this.client.emailId = '';
            this.client.phone = '';
            this.client.mobile = '';
            this.client.address1 = '';
            this.client.address2 = '';
            this.client.city = '';
            this.client.state = '';
            this.client.countryId = '';
            this.client.country = '';
            this.client.pincode = '';
            this.client.hasContactInfo = '';
            this.client.active = '';
            this.client.profession = '';
            this.client.employer = '';
            this.client.position = '';
            this.client.location = '';
            this.elements.alreadyExists = false;
            this.error = false;
            this.$v.client.$reset();
        },
        showContractorEdit: function(items) {
            let data = items;
            this.elements.contractVisible = true;
            this.elements.contractEdit = true;
            this.client.id = data.id;
            this.client.clientId = data.clientId;
            this.client.vhextAccountId = data.vhextAccountId;
            this.client.projectId = data.projectId;
            this.client.userId = data.userId;
            this.client.userType = data.userType;
            this.client.userName = data.userName;
            this.client.loginName = data.loginName;
            this.client.userActive = data.userActive;
            this.client.type = data.type;
            this.client.title = data.title;
            this.client.relationshipTypecodeEnum = data.relationshipTypecodeEnum;
            this.client.clientTypeId = data.clientTypeId;
            this.client.clientTypeName = data.clientTypeName;

            if (data.clientTypeId) {     
                this.selClientType = this.clientTypes.find(({ id }) => id == data.clientTypeId);
                this.client.clientType.text = this.selClientType ? this.selClientType.value : null;
            }
            this.client.clientType.id = data.clientTypeId;

            this.changeClientType(this.client.clientType);
            this.client.clientName = data.clientName;
            this.client.contactPerson = data.contactPerson;
            this.client.firstName = data.firstName;
            this.client.middleName = data.middleName;
            this.client.lastName = data.lastName;
            this.client.emailId = data.emailId;
            this.client.phone = data.phone;
            this.client.mobile = data.mobile;
            this.client.location = data.location;
            this.client.address1 = data.address1;
            this.client.address2 = data.address2;
            this.client.city = data.city;
            this.client.state = data.state;
            this.client.countryId = data.countryId;
            this.client.country = data.country;
            this.client.pincode = data.pincode;
            this.client.hasContactInfo = data.hasContactInfo;
            this.client.active = data.active;
            this.client.profession = data.profession;
            this.client.employer = data.employer;
            this.client.position = data.position;
            this.client.createdDt = data.createdDt;
            this.client.lastUpdatedDt = data.lastUpdatedDt;

            console.log(this.client);
        },
        changeClientType: function(data) {
            if (data.text != null && data.text.length > 0) {
                this.client.clientTypeName = data.text.toLowerCase();
            }
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.clients.map(data => {
                if (textVal && data.client && data.id !== id) {
                    if (data.client.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>