<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Scope</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Scope" :to="{ name: 'addscope'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Scope" :to="{ name: 'viewscope'}"><i class="fa fa-eye pr-2"></i>View</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Title</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.scope.title.$error }">
                                            <input type="text" class="form-control" v-model="scope.title" id="title" :class="{ 'is-invalid': $v.scope.title.$error }" placeholder="Enter Title">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.scope.title.$error && !$v.scope.title.minLength" class="text-danger">Title must have at least {{$v.scope.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.scope.title.$error && !$v.scope.title.required" class="text-danger">Please enter Title</label>
                                        <label v-if="$v.scope.title.$error && !$v.scope.title.maxLength" class="text-danger">Title must not exceed {{$v.scope.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="scopeType">Scope Type</label>
                                        <select v-model="scope.scopeTypeId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in scopeTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea v-model="scope.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="deliverables">Deliverables</label>
                                        <input type="text" v-model="scope.deliverables" maxlength="1000" mb-0 class="form-control" id="deliverables" placeholder="Deliverables">
                                    </div>
                                </div>
                            </div>
                            <button type="submit" @click="saveScope" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="scopeId">Scope Id</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{scope.scopeId}}</span></p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="statusId">Status</label>
                                        <select v-model="scope.statusId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in scopeStatus" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="preparedBy">Prepared By</label>
                                        <select v-model="scope.preparedById" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <div>
                                            <label for="exampleInputPassword1">Attachment </label>
                                            <a href="#" class="float-right button-add"><i class="fa fa-plus-circle pr-2"></i>Add More</a>
                                        </div>
                                        <div id="Multi-attachment">
                                            <div class="attachments mb-2">
                                                <input type="file" class="form-control" id="exampleInputPassword1">
                                                <a href="#" class="float-right button-remove pt-2 pb-2"><i class="fa fa-trash-o pr-2"></i>Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="scope.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        TypeLookup,
        Select2
    },
    computed: {
        scopeId() {
            return Number(this.$route.params.scopeid);
        }
    },
    data() {
        return {
            scopeTypes: [],
            scopeStatus: [],
            users: [],
            typeLookupTitle:'Scope Type Settings',
            scope: {
                scopeId: '',
                entity: 'scope',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                scopeTypeId: '',
                title: '',
                description: '',
                statusId: '',
                preparedById: '',
                deliverables: '',
                hasAttachment: '',
                hasReference: ''
            }
        }
    },
    mounted() {
        this.lookupData();
        this.viewScope(this.scopeId);
    },
    validations: {
        scope: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                entityId: 1,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                statusMasterId: 1,
                typeMasterId: 2,
                projectId: localStorage.getItem("projectId"),
                scopeId: this.scopeId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/scope/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.scopeTypes = result.customTypeLookup;
                    this.scopeStatus = result.statusLookup;
                    this.users = result.userLookup
                    console.log(result);

                })

        },
        viewScope: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/scope/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.scope = result.scope;
                    this.scope.scopeTypeId = (this.scope.scopeTypeId == undefined) ? '' : this.scope.scopeTypeId;
                    this.scope.statusId = (this.scope.statusId == undefined) ? '' : this.scope.statusId;
                    this.scope.preparedById = (this.scope.preparedById == undefined) ? '' : this.scope.preparedById;
                    console.log(result);
                })

        },
        saveScope: function() {
            this.$v.scope.$touch();
            if (!this.$v.scope.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/scope/update', this.scope)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                        console.log(result);

                    })
            }

        },
        clearData: function() {
            this.scope.title = '';
            this.scope.scopeTypeId = '';
            this.scope.description = '';
            this.scope.deliverables = '';
            this.scope.statusId = '';
            this.scope.preparedById = '';
            this.$v.scope.$reset();
        }
    }
}
</script>