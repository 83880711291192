<template>
    <div class="financialTxnPopup">
        <b-modal no-close-on-backdrop no-close-on-esc centered :title="(financialTxn.editMode ? 'Edit ' : 'Add ') + finPopupTitle" no-enforce-focus @hide="closeDialog" id="addFinTxnPopup" ref="quickFinTxnPopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="3">Title</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.financialTxn.title.$error }">
                                <b-form-input id="title" v-model="financialTxn.title" placeholder="Enter Title" required></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.minLength" class="text-danger">Title must have at least {{$v.financialTxn.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.required" class="text-danger">Please enter Title</label>
                            <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.maxLength" class="text-danger">Title must not exceed {{$v.financialTxn.title.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row v-if="financialTxn.isPayableReceivable" class="mb-1">
                        <b-col cols="3">Account Head</b-col>
                        <b-col>
                            <b-form-input id="title" v-model="financialTxn.accountHead" disabled placeholder="Enter Title" required></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row v-else class="mb-1">
                        <b-col cols="3">Account Head</b-col>
                        <b-col cols="8" class="pr-1">
                            <select v-model="financialTxn.accountHeadId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in accountHeads" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                        <a :title="accountHeadLookupTitle" class="action-link" href="javascript:" v-b-modal.accountHeadModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Txn Date</b-col>
                        <b-col>
                            <input v-model="financialTxn.transactionDt" type="Date" class="form-control">
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Txn Type</b-col>
                        <b-col cols="8" class="pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.financialTxn.transactionTypeId.$error }">
                                <select v-model="financialTxn.transactionTypeId" :class="{ 'is-invalid': $v.financialTxn.transactionTypeId.$error }" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in financialTxnTypes" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.financialTxn.transactionTypeId.$error && !$v.financialTxn.transactionTypeId.required" class="text-danger">Please Select a Transaction Type</label>
                        </b-col>
                        <a :title="finTxnTypeLookupTitle" class="action-link" href="javascript:" v-b-modal.finTxnTypeModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Category</b-col>
                        <b-col cols="8" class="pr-1">
                            <select v-model="financialTxn.categoryId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in financialTxnCategories" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                        <a :title="categoryLookupTitle" class="action-link" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Amount</b-col>
                        <b-col>
                            <VhextMoneyControl :value="Math.abs(Number(financialTxn.amount))" @money-input="moneyControlInput" />
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Transaction By</b-col>
                        <b-col>
                            <select v-model="financialTxn.transactionById" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="3">Fin Txn Master</b-col>
                        <b-col cols="8 pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.financialTxn.entityRecordMasterId.$error }">
                                <select v-model="financialTxn.entityRecordMasterId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.financialTxn.entityRecordMasterId.$error && !$v.financialTxn.entityRecordMasterId.required" class="text-danger">Please Select a Financial Txn Master</label>
                        </b-col>
                        <a title="Create new Fin Txn Master" class="action-link" href="javascript:" @click="openReqPop"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="close()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ financialTxn.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ financialTxn.editMode ? 'Update Transaction' : 'Add Transaction' }}</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <AccountHeadLookup ref="accountHeadModal" :entity="this.entity" :accountHeadLookupTitle="accountHeadLookupTitle" @refresh-lookup="lookupData" />                
        <CategoryLookup ref="categoryLookupModal" :projectTypeId="this.projectTypeId" :entity="this.categoryEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <FinTxnTypeLookup ref="finTxnTypeModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :finTxnTypeLookupTitle="finTxnTypeLookupTitle" @refresh-lookup="lookupData" />
        <ERMMasterPopup v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AccountHeadLookup from '@/components/popup/lookup/AccountHeadLookup.vue'
import CategoryLookup from '@/components/popup/lookup/CategoryLookup.vue'
import FinTxnTypeLookup from '@/components/popup/lookup/FinTxnTypeLookup.vue'
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'

export default {
    props: {
        projectTypeId: [String, Number],
        finTxnData: Object,
        popupTitle: String,
    },
    components: {
        AccountHeadLookup,
        CategoryLookup,
        FinTxnTypeLookup,
        ERMMasterPopup,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),

            entity: 'financial_transaction',
            typeEntity: 'financial_transaction_type',
            categoryEntity: 'financial_transaction_category',
            accountHeadLookupTitle: 'Account Head Settings',
            categoryLookupTitle: 'Financial Category Settings',
            finTxnTypeLookupTitle: 'Financial Type Settings',

            ermMasterInfo: {
                title: "Financial Txn Master",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
            },

            entityERMLookup: [],
            accountHeads: [],
            financialTxnTypes: [],
            amountTypes: [],
            financialTxnCategories: [],
            users: [],
            financialTxnActivity: [],
        }
    },
    mounted() {
        this.lookupData();
        console.log(this.financialTxn)
    },
    computed: {
        financialTxn() {
            return this.finTxnData;
        },
        finPopupTitle() {
            return this.popupTitle ? this.popupTitle : 'Financial Transaction';
        }
    },
    validations: {
        financialTxn: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            transactionTypeId: {
                required
            },
            entityRecordMasterId: {
                required
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'financial_transaction',
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnboard/lookup/all', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                this.accountHeads = result.accountHeadLookup;
                this.financialTxnTypes = result.customTypeLookup;
                this.financialTxnCategories = result.customCategoryLookup;
                this.users = result.userLookup;
                this.entityERMLookup = result.entityERMLookup;
            })
            .catch((error)=> this.showOverlay = false);
        },
        saveData: function() {
            this.$v.financialTxn.$touch();
            // this.amountTypes = this.financialTxnTypes;
            // this.amountTypes = this.amountTypes.find( ({ id }) => id === this.financialTxn.transactionTypeId );
            // console.log(this.amountTypes.typeClass);
            // if (this.amountTypes.typeClass == 1) {
            //     this.financialTxn.isIncome = true;
            // } else {
            //     this.financialTxn.isIncome = false;
            // }
            this.financialTxn.amount = Math.abs(this.financialTxn.amount);
            console.log(this.financialTxn.isIncome,this.financialTxn);
            if (!this.$v.financialTxn.$invalid) {
                this.showOverlay = true;
                if (this.financialTxn.editMode) {
                    this.updateFinTxn();
                } else {
                    this.saveFinTxn();
                }
            }
        },
        saveFinTxn: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/add', this.financialTxn)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                    if (result.status == "ALREADY_REPORTED") {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        updateFinTxn: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/update', this.financialTxn)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$emit('refreshData');
                        this.cancelEvent();
                    }
                })
                .catch((error)=> this.showOverlay = false);
        },
        moneyControlInput: function(value) {
            this.financialTxn.amount = Math.abs(value);
        },
        cancelEvent: function() {
            this.$bvModal.hide('addFinTxnPopup');
        },
        closeDialog() {
            this.$v.financialTxn.$reset();
            this.$emit('closeDialog');
            this.$emit('refreshData');
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        }
    }
}
</script>