<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Menu</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <b-tabs class="mt-3" card vertical nav-wrapper-class="w-100">
                            <b-tab title="Main Menu" @click="clickedMainMenu"></b-tab>
                            <b-tab title="Sub Menu" @click="clickedSubMenu"></b-tab>
                            <b-tab title="Menu List" @click="clickedMenuList"></b-tab>
                        </b-tabs>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <div v-if="elements.showMainMenu">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-lg-8 col-sm-6">
                                                    <h4 class="pl-2 mb-0 fs-16">Main Menu</h4>
                                                </div>
                                            </div>
                                            <div class="separator mb-2"></div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <b-card no-body class="">
                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                    <label class="d-block p-2 accordion-title pt-0" block href="#" v-b-toggle.accordion-1>
                                                        <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                                        <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                        <span v-if="elements.viewMode">View</span> 
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                    <b-card-body class="">
                                                        <div v-if="!elements.viewMode">
                                                            <div class="row mb-2">
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="title">Main Menu</label>
                                                                        <input type="text" class="form-control" v-model="objective.title" id="title" :class="{ 'is-invalid': $v.objective.title.$error }" placeholder="Enter Title">
                                                                        <label v-if="$v.objective.title.$error && !$v.objective.title.minLength" class="text-danger">Title must have at least {{$v.objective.title.$params.minLength.min}} letters.</label>
                                                                        <label v-if="$v.objective.title.$error && !$v.objective.title.required" class="text-danger">Please enter Title</label>
                                                                        <label v-if="$v.objective.title.$error && !$v.objective.title.maxLength" class="text-danger">Title must not exceed {{$v.objective.title.$params.maxLength.max}} letters.</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="inscope">Icon</label>
                                                                        <input type="text" class="form-control" v-model="objective.title" id="title" placeholder="Enter Icon Name">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6">
                                                                    <div class="form-group">
                                                                        <label for="timeline">URL</label>
                                                                        <input v-model="objective.timeline" type="text" class="form-control" placeholder="Enter URL">
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="inscope">Is Active</label>
                                                                        <b-form-checkbox class="custom-toggle" v-model="objective.inScope" :disabled=notEnabled name="check-button" switch>
                                                                        </b-form-checkbox>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table show-empty small stacked="md" sort-icon-left :items="objectives" :fields="mainMenuFields" selectable :select-mode="selectMode" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template v-slot:cell(actions)="data">
                                            <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                            <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                            <a href="javascript:" title="Delete" @click="deleteObjective(data.item.id)" data-toggle="modal" data-target="#deleteObjective"><i class="fa fa-trash pl-2"></i></a>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Objectives found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="objectives.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>  
                                </div>
                                <div v-if="elements.showSubMenu">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-lg-8 col-sm-6">
                                                    <h4 class="pl-2 mb-0 fs-16">Sub Menu</h4>
                                                </div>
                                                <div class="col-lg-4 col-sm-6">
                                                </div>
                                            </div>
                                            <div class="separator mb-2"></div>
                                        </div>
                                    </div>
                                    <div id="scope-app" class="mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title pt-0" block href="#" v-b-toggle.accordion-2>
                                                    <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                                    <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                                    <span v-if="elements.viewMode">View</span> 
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                <b-card-body class="">
                                                    <div v-if="!elements.viewMode">
                                                        <div class="row mb-2">
                                                            <div class="col-12">
                                                                <b-input-group size="sm" class="mb-2">
                                                                    <template v-slot:prepend>
                                                                        <b-dropdown :text="phaseText" class="btn-dd" v-model="objective.phaseId">
                                                                            <b-dropdown-item v-for="(info, index)  in phase" :key="info.id" :value="info.id" @click="objective.phaseId = info.id;phaseText = info.title">
                                                                                {{info.value}}
                                                                            </b-dropdown-item>
                                                                        </b-dropdown>
                                                                    </template>
                                                                    <label v-if="$v.objective.phaseId.$error && !$v.objective.phaseId.required" class="text-danger">Please Select Phase</label>
                                                                    <b-form-input v-model="objective.phaseTitle" :class="{ 'is-invalid': $v.objective.phaseTitle.$error }"></b-form-input>
                                                                    <label v-if="$v.objective.phaseTitle.$error && !$v.objective.phaseTitle.minLength" class="text-danger">Phase Title must have at least {{$v.objective.phaseTitle.$params.minLength.min}} letters.</label>
                                                                    <label v-if="$v.objective.phaseTitle.$error && !$v.objective.phaseTitle.required" class="text-danger">Please enter Phase Title</label>
                                                                    <label v-if="$v.objective.phaseTitle.$error && !$v.objective.phaseTitle.maxLength" class="text-danger">Phase Title must not exceed {{$v.objective.phaseTitle.$params.maxLength.max}} letters.</label>
                                                                </b-input-group>
                                                            </div>
                                                            <div class="col-6 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="inscope">Sub Menu</label>
                                                                    <input type="text" class="form-control" v-model="objective.title" id="title" placeholder="Enter Sub Menu">
                                                                </div>
                                                            </div>
                                                            <div class="col-6 col-sm-6">
                                                                <div class="form-group">
                                                                    <label for="inscope">Icon</label>
                                                                    <input type="text" class="form-control" v-model="objective.title" id="title" placeholder="Enter Icon Name">
                                                                </div>
                                                            </div>
                                                            <div class="col-6">
                                                                <div class="form-group">
                                                                    <label for="timeline">URL</label>
                                                                    <input v-model="objective.timeline" type="text" class="form-control" placeholder="Enter URL">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                        <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table show-empty small stacked="md" sort-icon-left :items="objectives" :fields="subMenuFields" selectable :select-mode="selectMode" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template v-slot:cell(actions)="data">
                                            <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                            <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                            <a href="javascript:" title="Delete" @click="deleteObjective(data.item.id)" data-toggle="modal" data-target="#deleteObjective"><i class="fa fa-trash pl-2"></i></a>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Objectives found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="objectives.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </div>
                                <div title="Add Reference" v-if="elements.showMenuList">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-lg-8 col-sm-6">
                                                    <h4 class="pl-2 mb-0 fs-16">Menu List</h4>
                                                </div>
                                                <div class="col-lg-4 col-sm-6">
                                                </div>
                                            </div>
                                            <div class="separator mb-2"></div>
                                        </div>
                                    </div>
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table show-empty small stacked="md" sort-icon-left :items="objectives" :fields="objectiveFields" selectable :select-mode="selectMode" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template v-slot:cell(actions)="data">
                                            <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                            <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                            <a href="javascript:" title="Delete" @click="deleteObjective(data.item.id)" data-toggle="modal" data-target="#deleteObjective"><i class="fa fa-trash pl-2"></i></a>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Objectives found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="objectives.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteObjective" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Objective?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showMainMenu: true,
                showSubMenu: false,
                showMainMenu: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            phaseText: 'MainMenu',
            notEnabled: true,
            objectiveTypes: [],
            objectiveStatus: [],
            users: [],
            objective: {
                vhextAccountId: '',
                projectId: '',
                goalId: '',
                title: '',
                objectiveTypeId: '',
                objective: '',
                timeline: '',
                preparedById: '',
                achievementRating: '',
                deliverables: '',
                accountablePersonId: '',
                projectEntityId: ''
            },
            objectiveDetail: {
                title: '',
                objective: '',
                objectiveId: '',
                status: '',
                type: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            objectives: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            mainMenuFields: [
                { key: 'objectiveId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Icon', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'URL', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            subMenuFields: [
                { key: 'objectiveId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Icon', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'URL', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            objectiveFields: [
                { key: 'objectiveId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Icon', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'URL', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getObjective();
        this.lookupData();
        this.clickedMainMenu();
    },
    validations: {
        objective: {
            phaseTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            phaseId:{
                required
            },
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            objectiveTypeId: {
                required
            },
            accountablePersonId: {
                required
            },
            statusId: {
                required,
            }
        }
    },
    methods: {
        getObjective: function() {

            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/list')
                .then((response) => {
                    let result = response.data;
                    this.objectives = result.objectivelist;
                    this.totalRows = this.objectives.length;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: 1,
                vhextAccountId: 1,
                statusMasterId: 1,
                typeMasterId: 2
            }
            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.objectiveStatus = result.statusLookup;
                    this.objectiveTypes = result.customTypeLookup;
                    this.users = result.userLookup;  
                    console.log(this.users);     
                })

        },
        saveData: function() {
            this.$v.objective.$touch();
            if (!this.$v.objective.$invalid) {
                if (this.elements.editMode) {
                    this.updateObjective();
                } else {
                    this.saveObjective();
                }
            }
        },
        saveObjective: function() {
            console.log(this.objective);
            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/add', this.objective)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getObjective();
                    }
                })
        },
        updateObjective: function() {
                axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/update', this.objective)
                    .then((response) => {
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getObjective();
                        }
                    })
        },
        deleteObjective: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/goal/objective/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getObjective();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.objective.title = '';
            this.objective.objectiveTypeId = '';
            this.objective.accountablePersonId = '';
            this.objective.timeline = '';
            this.objective.statusId = '';
            this.objective.objective = '';
            this.objective.deliverables = '';
            this.objective.id = '';
            this.$v.objective.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.objective.title = data.title;
            this.objective.objectiveTypeId = data.objectiveTypeId;
            this.objective.accountablePersonId = data.accountablePersonId;
            this.objective.statusId = data.statusId;
            this.objective.timeline = data.timeline;
            this.objective.objective = data.objective;
            this.objective.deliverables = data.deliverables;
            this.objective.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.objectiveDetail.title = data.titles;
                this.objectiveDetail.objectiveId = data.objectiveId;
                this.objectiveDetail.type = data.type;
                this.objectiveDetail.status = data.status;
                this.objectiveDetail.accountablePersonId = data.accountablePersonId;
                this.objectiveDetail.timeline = data.timeline;
                this.objectiveDetail.objective = data.objective;
                this.objectiveDetail.deliverables = data.deliverables;
                this.objectiveDetail.createdDt = data.createdDt;
                this.objectiveDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.objectiveDetail.title = data.title;
            this.objectiveDetail.objectiveId = data.objectiveId.toString();
            this.objectiveDetail.type = data.type;
            this.objectiveDetail.status = data.status;
            this.objectiveDetail.accountablePersonId = data.accountablePersonId;
            this.objectiveDetail.timeline = data.timeline;
            this.objectiveDetail.objective = data.objective;
            this.objectiveDetail.deliverables = data.deliverables;
            this.objectiveDetail.createdDt = data.createdDt;
            this.objectiveDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        clickedMainMenu: function() {
            this.elements.showMainMenu = true;
            this.elements.showSubMenu = false;
            this.elements.showMenuList = false;
        },
        clickedSubMenu: function() {
            this.elements.showMainMenu = false;
            this.elements.showSubMenu = true;
            this.elements.showMenuList = false;
        },
        clickedMenuList: function() {
            this.elements.showMainMenu = false;
            this.elements.showSubMenu = false;
            this.elements.showMenuList = true
        }
    }
}
</script>