<template>
    <div class="accountHead">
        <b-modal ref="existingAccountHeadLookup" size="lg" id="existingAccountHeadLookup" ok-only body-class="pt-0 pb-2" footer-class="py-3" header-class="py-3" @hide="closeDialog" title="Add Account Head">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row mb-2 px-2">
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 pb-1 mt-3">
                            <b-tabs v-model="tabIndex" content-class="mt-3" lazy>
                                <b-tab title="Existing Account Head">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="existingAccountHead" :fields="accountHeadFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByExt" :sort-desc.sync="sortDescExt" :sort-direction="sortDirection">
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!accountHeadExists(data.item.accountHead)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.accountHead" v-model="selectedAccountHead">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!accountHeadExists(data.item.accountHead)">
                                                <a href="javascript:" title="Add Account Head" class="text-success" @click="addAccountHead(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!accountHeadExists(data.item.accountHead)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.accountHead" v-model="selectedAccountHead">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Account Head found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-if="isStandard == 'true'" title="Account Head Summary">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="accountHeadsSummary" :fields="accountHeadSummaryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!accountHeadExists(data.item.accountHead)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.accountHead" v-model="selectedAccountHead">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!accountHeadExists(data.item.accountHead)">
                                                <a href="javascript:" title="Add Account Head" class="text-success" @click="addAccountHead(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!accountHeadExists(data.item.accountHead)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.accountHead" v-model="selectedAccountHead">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Account Head found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-else title="Standard Account Head">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="standardAccountHead" :fields="accountHeadFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!accountHeadExists(data.item.accountHead)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.accountHead" v-model="selectedAccountHead">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!accountHeadExists(data.item.accountHead)">
                                                <a href="javascript:" title="Add Account Head" class="text-success" @click="addAccountHead(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!accountHeadExists(data.item.accountHead)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.accountHead" v-model="selectedAccountHead">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Account Head found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 mt-3">
                            <label class="mt-3">Account Head</label>
                            <div class="separator mb-3"></div>
                            <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" sort-icon-left :items="accountHeads" :fields="accountHeadFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Remove" @click="deleteItem(data.item.id)" v-b-modal.deleteItem class="text-danger"><i class="fa fa-minus-circle pr-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Account Head found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <button type="submit" @click="addBulkAccountHead" :disabled="selectedAccountHead.length==0" class="btn btn-primary float-left my-0 mr-2 py-1 px-3">Add Selected</button>
                    <button v-if="selectedAccountHead.length>0" type="submit" @click="selectedAccountHead=[];" class="btn btn-outline-primary float-left my-0 mr-2 py-1 px-3">Clear Selection</button>
                    <button type="submit" @click="cancel()" class="btn btn-secondary float-right my-0 mr-2 py-1 px-3">Close</button>
                </div>
            </template>
        </b-modal>
        <DeleteComponent id="deleteItem" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Account Head ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            showOverlay: false,
            checkboxValue: true,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            isStandard: localStorage.getItem("isStandard"),
            accountHead: {
                accountHeadId: '',
                accountHead: '',
                vhextAccountId: '',
                projectId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            existingAccountHead: [],
            standardAccountHead: [],
            accountHeads: [],
            accountHeadFields: [
                { key: 'selected', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            accountHeadsSummary: [],
            accountHeadSummaryFields: [
                { key: 'selected', label: '' },
                {key:'index',label: 'SN'},
                { key: 'accountHead', label: 'Account Head', sortable: true, sortDirection: 'desc' },
                { key: 'count', label: 'Count', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            selectMode: 'single',
            sortDescExt: false,
            sortDescStd: false,
            sortDesc: false,
            sortBy: '',
            sortByExt: '',
            sortByStd: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            selectedAccountHead: [],

            deleteParam: {
                id: 0
            }
        }
    },
    watch: {
        tabIndex() {
            this.selectedAccountHead = [];
        }
    },
    mounted() {
        this.getAccountHeadList();
        this.getExistingAccountHead();
        if (this.isStandard == 'true') {            
            this.getAccountHeadSummary();
        } 
        else {
            this.getStandardAccountHead();
        }
    },
    methods: {
        getAccountHeadList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.selectedAccountHead = [];
                    this.accountHeads = result.accountHeadList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getExistingAccountHead: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/existingaccounthead/list', data)
                .then((response) => {
                    let result = response.data;
                    this.existingAccountHead = result.existingAccountHeadList;
                })
        },
        getStandardAccountHead: function() {
            let data = {
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/standardaccounthead/list', data)
                .then((response) => {
                    let result = response.data;
                    this.standardAccountHead = result.standardAccountHeadList;
                })
        },
        getAccountHeadSummary: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/accountheadsummary/list', data)
                .then((response) => {
                    let result = response.data;
                    this.accountHeadsSummary = result.accountHeadSummaryList;
                })
        },
        addAccountHead: function(data) {
            this.accountHead.vhextAccountId = this.vhextAccountId;
            this.accountHead.projectId = this.projectId;
            this.accountHead.entity = data.entity;
            this.accountHead.active = true;
            this.accountHead.accountHead = data.accountHead;

            if (!this.accountHeadExists(this.accountHead.accountHead)) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/add', this.accountHead)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-lookup');
                            this.getAccountHeadList();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                iziToast.info({
                    message: 'Account Head already exist.',
                    position: 'topCenter'
                });
            }
        },
        accountHeadExists: function(accountHead) {
            let result = false;
            this.accountHeads.map(data => {
                if (accountHead && data.accountHead) {
                    if (data.accountHead.toLowerCase() == accountHead.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        dataExists: function(accountHead) {
            let result = false;
            this.selectedAccountHead.map(data => {
                if (data.toLowerCase() == accountHead.toLowerCase()) {
                    result = true;
                }
            });
            return result;
        },
        onRowSelected(items) {
            if (items.length > 0){
                let data = items[0];
                if (!this.accountHeadExists(data.accountHead) && !this.dataExists(data.accountHead)) {
                    this.selectedAccountHead.push(data.accountHead);
                }
                else if (this.dataExists(data.accountHead)) {
                    this.selectedAccountHead = this.selectedAccountHead.filter(e=> e.toLowerCase() !== data.accountHead.toLowerCase());
                }
            }
          },
        closeDialog() {
            this.selectedAccountHead = [];
            this.$emit('closeDialog');
        },
        accountHeadData: function(data) {
            this.bulkAccountHeadData = [];
            for (var rowCount = 0; rowCount < this.selectedAccountHead.length; rowCount++) {
                this.bulkAccountHeadData.push({
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: data.entity,
                    active: true,
                    accountHead: this.selectedAccountHead[rowCount],
                    createdById: localStorage.getItem("userId")
                });
            }
        },
        saveBulkAccountHead: function(data) {
            this.showOverlay = true;    
            axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/bulk/add', this.bulkAccountHeadData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getAccountHeadList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        async addBulkAccountHead(data) {
            await this.accountHeadData(data);
            await this.saveBulkAccountHead();
        },
        deleteItem: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/accounthead/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAccountHeadList();
                        this.$emit('refresh-lookup');
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
    }
}
</script>