<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <DocumentMasterList :ermMasterInfo="ermMasterInfo" ></DocumentMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DocumentMasterList from '../../components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DocumentMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Process Models",
                entity: 'process_model_master',
                refEntity: 'process_plan',
                tooltip: 'New Process Model',
                redirectURLName: 'lanpmd',
                newEntityMasterURL: 'pmdmas',
                redirectURL: '/pmdmas',
                actionName: 'Process Plan',
                previewURLName: 'lanpmdrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>