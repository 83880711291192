<template>
    <div class="process">
        <b-modal scrollable ref="processModal" size="md" centered id="processModal" modal-class="processModal" @hide="closeDialog" :title="processLookupTitle" ok-only ok-title="Close" ok-variant="secondary" :no-close-on-backdrop="true">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="p-2">
                    <b-card no-body class="mb-3">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                <span v-if="!elements.processEdit"><i class="fa fa-plus-circle pr-2 action-link"></i>New Process</span>
                                <span v-if="elements.processEdit">Edit</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.processVisible" role="tabpanel">
                            <b-card-body class="p-0">
                                <div class="row">
                                    <b-input-group class="form-group py-2">
                                        <div cols="2" class="align-middle float-left my-auto ml-4 mr-0 p-1" >Title</div>
                                        <b-col cols="6" class="pr-1">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.process.title.$error }">
                                                <b-form-input id="title" v-model="process.title" placeholder="Enter Title"></b-form-input>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.process.title.$error && !$v.process.title.required" class="text-danger">Please Enter Title</label>
                                        </b-col>
                                        <b-col cols="2">
                                            <div class="input-group p-0">
                                                <b-form-checkbox class="custom-toggle" v-model="process.active" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </b-col>                                
                                        <div cols="2" class="float-right">
                                            <div class="more-btns">
                                                <button type="submit" title="Save" @click="saveProcessData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                <button type="submit" title="Clear" @click="clearProcessData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </b-input-group>
                                </div>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                    <div class="row view-filter">
                        <div class="col-sm-12">
                            <div class="row input-group-label">
                                <div class="col-6">
                                    <label class="mb-0 mt-2 fs-14">Process</label>
                                </div>
                                <div class="col-6 dataTables_filter text-right">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>                

                    <b-table show-empty small outlined head-variant=:primary sticky-header="30vh" :busy="isBusy" sort-icon-left :items="processes" :fields="processFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>                        
                        <template v-slot:cell(active)="data">
                            <div v-if="data.item.createdById == userId">
                                <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                                </b-form-checkbox>
                            </div>
                            <div v-else>
                                <span v-if="data.item.active" class="text-success ml-3" title="UnEditable" ><i class="fa fa-check"></i></span>
                            </div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <div v-if="data.item.createdById == userId">
                                <a v-if="" href="javascript:" title="Edit Types" @click="showProcessEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" title="Delete" @click="deleteProcess(data.item.id)" v-b-modal.deleteProcess><i class="fa fa-trash pl-2"></i></a>
                            </div>
                            <div v-else>
                                <a href="javascript:" class="text-center" title="NA">NA</a>
                            </div>

                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Process found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
<!--                     <b-pagination v-if="processes.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                </div>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="deleteProcess" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Process ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        projectTypeId: [String, Number],
        entity: String,
        processLookupTitle: String
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            elements: {
                processVisible: false,
                processEdit: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            process: {
                processId: '',
                title: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            processes: [],
            processFields: [
                { key: 'index', label: 'SN', },
                // { key: 'processId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'title', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        process: {
            title: {
                required
            }
        }
    },
    mounted() {
        this.getProcessList();
    },
    methods: {
        getProcessList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                // projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/process/list', data)
                .then((response) => {
                    let result = response.data;
                    this.processes = result.processList;
                    this.totalRows = this.processes.length;
                })
        },
        saveProcessData: function() {
            this.$v.process.$touch();
            if (!this.$v.process.$invalid) {
                if (!this.matchTextValExists(this.process.title, this.process.id)) {
                    if (this.elements.processEdit) {
                        this.updateProcess();
                    } else {
                        this.addProcess();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Process already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        addProcess: function() {
            this.process.vhextAccountId = this.vhextAccountId;
            this.process.projectId = this.projectId;
            this.process.projectTypeId = this.projectTypeId;
            
            this.process.entity = this.entity;
            console.log(this.process);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/add', this.process)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessList();
                        this.clearProcessData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateProcess: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/update', this.process)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessList();
                        this.clearProcessData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        deleteProcess: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/process/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topCenter'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessList();
                    }
                    else if(result.status == "BAD_REQUEST"){
                        iziToast.info({
                            title: 'Info',
                            message: result.message,
                            position: 'topCenter'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.process.id = item.id;
                this.process.active = 0;
                this.process.entity = 'process';
                this.updateActivationState();
            } else {
                this.process.id = item.id;
                this.process.active = 1;
                this.process.entity = 'process';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.process)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getProcessList();
                        this.clearProcessData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearProcessData: function() {
            this.process.id = '';
            this.process.title = '';
            this.process.processId = '';
            this.process.active = true;
            this.$v.process.$reset();
            this.elements.processEdit = false;
        },
        showProcessEdit: function(items) {
            let data = items;
            this.elements.processVisible = true;
            this.elements.processEdit = true;

            this.process.id = data.id;
            this.process.processId = data.processId;
            this.process.title = data.title;
            this.process.vhextAccountId = data.vhextAccountId;
            this.process.projectId = data.projectId;
            this.process.projectTypeId = data.projectTypeId;
            
            this.process.entityId = data.entityId;
            this.process.active = data.active;

        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.processes.map(data => {
                if (textVal && data.title && data.id !== id) {
                    if (data.title.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>