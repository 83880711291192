<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc :title="sendMailTitle ? sendMailTitle : 'Send Mail'" no-enforce-focus @hide="closeDialog" :id="id" ref="sendMailPopupRef" size="lg" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12 col-xl-12 ">
                            <div class="row">
                                <div class="col-10 col-sm-10 pr-0">
                                    <div class="form-group">
                                        <label for="fromEmail">To</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.sendMail.toList.$error }">
                                            <multiselect v-model="sendMail.toList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="sendMail.group.value=='others'" @tag="addToTag" tag-placeholder="Add New Emails"></multiselect>
                                            <div class="required-icon"></div>
                                        </div>
                                        <div v-if="$v.sendMail.toList.$error">
                                            <span v-if="!$v.sendMail.toList.checkNull" class="text-danger">Please select To Address.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 text-right">
                                    <div class="form-group">
                                        <label class="d-block" style="visibility: hidden;"> Btn </label>
                                        <button type="submit" :class="{'active' : !showContactLookup}" title="Show Users" @click="showContactLookup = false" class="btn sbtn btn-xs btn-outline-secondary px-2 my-auto mr-1"><i class="fa fa-lg fa-user"></i></button>
                                        <button type="submit" :class="{'active' : showContactLookup}" title="Show Contacts" @click="showContactLookup = true" class="btn sbtn btn-xs btn-outline-secondary px-2 my-auto"><i class="fa fa-lg fa-address-book"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-6 col-sm-6">
                                    <div class="form-group">
                                        <label for="fromEmail">Cc</label>
                                        <multiselect v-model="sendMail.ccList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="sendMail.group.value=='others'" @tag="addCcTag" tag-placeholder="Add New Emails"></multiselect>
                                    </div>
                                </div>
                                <div class="col-6 col-sm-6">
                                    <div class="form-group">
                                        <label for="fromEmail">Bcc</label>
                                        <multiselect v-model="sendMail.bccList" :options="users" name="users" label="userName" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="sendMail.group.value=='others'" @tag="addBccTag" tag-placeholder="Add New Emails"></multiselect>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <label for="fromEmail">Subject</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.sendMail.subject.$error }">
                                            <input type="text" class="form-control" v-model="sendMail.subject">
                                            <div class="required-icon"></div>
                                        </div>
                                        <div v-if="$v.sendMail.subject.$error">
                                            <span v-if="!$v.sendMail.subject.required" class="text-danger">Please enter Subject.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <AttachmentComponent :attachmentInfo="attachmentInfo" :attachments="attachments" />
                            <div class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.sendMail.body.$error }">
                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" v-model="sendMail.body" :toolbar="toolbar" :init="initObj"></editor>
                                            <div class="required-icon"></div>
                                        </div>
                                        <div v-if="$v.sendMail.body.$error">
                                            <span v-if="!$v.sendMail.body.required" class="text-danger">Please enter Email Content.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center text-info">
                        <LoadingSpinner />
                        <p class="font-weight-bold fs-18 mb-0">
                            <i class="fa fa-lg fa-envelope" aria-hidden="true"></i>
                            <label class="m-0 pl-2">Sending Mail . . .</label>
                        </p>
                    </div>
                </template>
            </b-overlay>
            <template #modal-footer="{ close }">
                <div class="w-100">
                    <button type="submit" @click="close()" class="btn btn-secondary float-right mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                    <button type="submit" @click="handleSend" :disabled="showOverlay" class="btn btn-primary float-right mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-paper-plane pr-2"></i>Send</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import Multiselect from 'vue-multiselect';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
export default {
    name: 'app',
    props: {
        sendMailTitle: String,
        id: {type:String, default:'sendMailPopup'},
    },
    components: {
        Editor,
        Multiselect,
        AttachmentComponent
    },
    data() {
        return {
            showOverlay: false,
            showContactLookup: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userLookup: [],
            contacts: [],
            //attachments: [],
            sendMail: {
                group: { value: "others", label: "Others" },
                toList: [],
                ccList: [],
                bccList: [],
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                recipients: '',
                copyRecipients: '',
                blindCopyRecipients: '',
                subject: '',
                body: '',
                attachments: [],
                entity: '',
                recipientIds: [],
                sendById: localStorage.getItem("userId"),
            },
            validEmailId: '',
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            editorHeight: 400,
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            attachmentInfo:{
                title:'Attachment'
            },
            attachments: [],

        }
    },
    computed: {
        users() {
            return this.showContactLookup ? this.contacts : this.userLookup;
        }
    },
    mounted() {
        // this.getMailData();
        this.lookupData();
    },
    validations: {
        sendMail: {
            toList: {
                checkNull: function(toList) {
                    return toList.length > 0
                }
            },
            subject: {
                required,
            },
            body: {
                required,
            },
        },
        validEmailId: {
            email,
        }
    },
    methods: {
        setMailData: function(mailData) {
            // Called from parent
            this.sendMail.entity = mailData.entity ? mailData.entity : 'send_mail';
            this.sendMail.body = mailData.body ? mailData.body : '';
            this.sendMail.subject = mailData.subject ? mailData.subject : '';
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/email/sendmail/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.userLookup = result.userLookup;
                    this.contacts = result.contactLookup;
                })
        },
        addToTag: function(newEmail) {
            if (this.isValidEmail(newEmail)) {
                this.sendMail.toList.push({ id: null, userName: newEmail, emailId: newEmail, userType: "none" });
                this.validEmailId = '';
                this.$v.validEmailId.$reset();
            }
        },
        addCcTag: function(newEmail) {
            if (this.isValidEmail(newEmail)) {
                this.sendMail.ccList.push({ id: null, userName: newEmail, emailId: newEmail, userType: "none" });
                this.validEmailId = '';
                this.$v.validEmailId.$reset();
            }
        },
        addBccTag: function(newEmail) {
            if (this.isValidEmail(newEmail)) {
                this.sendMail.bccList.push({ id: null, userName: newEmail, emailId: newEmail, userType: "none" });
                this.validEmailId = '';
                this.$v.validEmailId.$reset();
            }
        },
        // handleSend: function() {

        //     console.log(this.sendMail);
        //     this.$v.sendMail.$touch();
        //     if (this.$v.sendMail.$invalid) {
        //         return;
        //     }

        //     let formData = new FormData();
        //     for (const row of Object.keys(this.attachments)) {    
        //         formData.append('files', this.attachments[row]);
        //     }

        //     this.sendMail.recipients = this.getCommaSeperatedString(this.sendMail.toList);
        //     this.sendMail.copyRecipients = this.getCommaSeperatedString(this.sendMail.ccList);
        //     this.sendMail.blindCopyRecipients = this.getCommaSeperatedString(this.sendMail.bccList);
        //     this.sendMail.email = this.sendMail.recipients;
        //     this.sendMail.ccEmail = this.sendMail.copyRecipients;
        //     this.sendMail.bccEmail = this.sendMail.blindCopyRecipients;
        //     this.sendMail.message = this.sendMail.body;

        //     this.sendMail.recipientIds = [];
        //     this.sendMail.toList.map(data => {
        //         this.sendMail.recipientIds.push(data.id);
        //     });
        //     this.sendMail.ccList.map(data => {
        //         this.sendMail.recipientIds.push(data.id);
        //     });
        //     this.sendMail.bccList.map(data => {
        //         this.sendMail.recipientIds.push(data.id);
        //     });
        //     console.log(this.sendMail);

        //     let projectId = localStorage.getItem("projectId");
        //     if (projectId && projectId != 'null') {
        //         formData.append('projectId', projectId);
        //     }
       
        //     formData.append('recipients', this.getCommaSeperatedString(this.sendMail.toList));
        //     formData.append('copyRecipients', this.getCommaSeperatedString(this.sendMail.ccList));
        //     formData.append('blindCopyRecipients', this.getCommaSeperatedString(this.sendMail.bccList));
        //     formData.append('email', this.sendMail.recipients);
        //     formData.append('ccEmail', this.sendMail.copyRecipients);
        //     formData.append('bccEmail', this.sendMail.blindCopyRecipients);
        //     formData.append('message', this.sendMail.body);
        //     formData.append('recipientIds', this.sendMail.recipientIds);
        //     formData.append('vhextAccountId', this.vhextAccountId);
        //     formData.append('body', this.sendMail.body);

        //     formData.append('subject', this.sendMail.subject);
        //     formData.append('entity', this.sendMail.entity);
        //     formData.append('sendById', this.sendMail.sendById);
        //     formData.append('group', this.sendMail.group);

        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/email/sendmail/add', formData).then(response => {
        //             this.showOverlay = false;
        //             let result = response.data;
        //             if (!response.status) {
        //                 iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
        //             }
        //             iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
        //             this.$nextTick(() => {
        //                 this.$bvModal.hide(this.id);
        //             })

        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({ title: 'Error', message: 'Unable to send mail. Please try again', position: 'topRight' });
        //         });
        // },
        handleSend: function() {

            console.log(this.sendMail);
            this.$v.sendMail.$touch();
            if (this.$v.sendMail.$invalid) {
                return;
            }

            let formData = new FormData();
            for (const row of Object.keys(this.attachments)) {    
                formData.append('files', this.attachments[row]);
            }

            this.sendMail.recipients = this.getCommaSeperatedString(this.sendMail.toList);
            this.sendMail.copyRecipients = this.getCommaSeperatedString(this.sendMail.ccList);
            this.sendMail.blindCopyRecipients = this.getCommaSeperatedString(this.sendMail.bccList);
            this.sendMail.email = this.sendMail.recipients;
            this.sendMail.ccEmail = this.sendMail.copyRecipients;
            this.sendMail.bccEmail = this.sendMail.blindCopyRecipients;
            this.sendMail.message = this.sendMail.body;

            this.sendMail.recipientIds = [];
            this.sendMail.toList.map(data => {
                this.sendMail.recipientIds.push(data.id);
            });
            this.sendMail.ccList.map(data => {
                this.sendMail.recipientIds.push(data.id);
            });
            this.sendMail.bccList.map(data => {
                this.sendMail.recipientIds.push(data.id);
            });
            console.log(this.sendMail);

            let projectId = localStorage.getItem("projectId");
            if (projectId && projectId != 'null') {
                formData.append('projectId', projectId);
            }
       
            formData.append('recipients', this.getCommaSeperatedString(this.sendMail.toList));
            formData.append('copyRecipients', this.getCommaSeperatedString(this.sendMail.ccList));
            formData.append('blindCopyRecipients', this.getCommaSeperatedString(this.sendMail.bccList));
            formData.append('email', this.sendMail.recipients);
            formData.append('ccEmail', this.sendMail.copyRecipients);
            formData.append('bccEmail', this.sendMail.blindCopyRecipients);
            formData.append('message', this.sendMail.body);
            formData.append('recipientIds', this.sendMail.recipientIds);
            formData.append('vhextAccountId', this.vhextAccountId);
            formData.append('body', this.sendMail.body);

            formData.append('subject', this.sendMail.subject);
            formData.append('entity', this.sendMail.entity);
            formData.append('sendById', this.sendMail.sendById);
            formData.append('group', this.sendMail.group);

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/compose/email', formData).then(response => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (!response.status) {
                        iziToast.error({ title: 'Error', message: result.message, position: 'topRight' });
                    }
                    iziToast.success({ title: 'Success', message: result.message, position: 'topRight' });
                    this.$nextTick(() => {
                        this.$bvModal.hide(this.id);
                    })

                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({ title: 'Error', message: 'Unable to send mail. Please try again', position: 'topRight' });
                });
        },
        uploadFile: function() {
            this.attachments.push(this.$refs.add_attachment.files[0]);
            console.log(this.attachments);
        },
        isValidEmail(newEmail) {
            let result = true;
            this.validEmailId = newEmail;
            this.$v.validEmailId.$touch();
            if (this.$v.validEmailId.$invalid) {
                iziToast.info({
                    message: 'Please enter a valid Email Id',
                    position: 'topCenter'
                });
                result = false;
            }
            return result;
        },
        clearData: function() {
            this.sendMail.group = { value: "others", label: "Others" };
            this.sendMail.toList = [];
            this.sendMail.ccList = [];
            this.sendMail.bccList = [];
            this.sendMail.recipients = '';
            this.sendMail.copyRecipients = '';
            this.sendMail.blindCopyRecipients = '';
            this.sendMail.subject = '';
            this.sendMail.body = '';
            this.sendMail.attachments = [];
            this.sendMail.entity = '';
            this.sendMail.recipientIds = [];
            this.$v.sendMail.$reset();
            this.$v.validEmailId.$reset();
        },
        closeDialog() {
            this.clearData();
            this.$emit('closeDialog');
            this.$emit('refreshData');
        },
    }
}
</script>