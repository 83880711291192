<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Cost Estimation</span></h4>
                        </div>                        
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Cost Estimation</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Item Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.costEstimation.title.$error }">
                                                                <input type="text" class="form-control" maxlength="100" v-model="costEstimation.title" id="title" :class="{ 'is-invalid': $v.costEstimation.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.minLength" class="text-danger">Title must have at least {{$v.costEstimation.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.maxLength" class="text-danger">Title must not exceed {{$v.costEstimation.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label>Activity</label>                                             
                                                            <b-input-group>
                                                                <select v-model="costEstimation.activityId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <b-input-group-append class="append-0">
                                                                    <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label>Quantity</label>                                             
                                                            <b-input-group>
                                                                <b-form-input type="number" v-model="costEstimation.quantity" @input="setTotalCost" class="form-control" min="0" id="csQuantity" ></b-form-input>
                                                                <b-input-group-append class="append-50">
                                                                    <select v-model="costEstimation.unitOfMesurement" class="form-control">
                                                                        <option :value="{id: '', text:''}">Select</option>
                                                                        <option v-for="(info, index) in uomTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                                    </select>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="csPrice">Unit Cost</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text">
                                                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                                                    </div>
                                                                </div>
                                                                <input type="number" v-model="costEstimation.unitCost" @input="setTotalCost" class="form-control" min="0" id="csPrice">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="csPrice">Total Cost</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="input-group-text">
                                                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                                                    </div>
                                                                </div>
                                                                <input type="number" v-model="costEstimation.cost" class="form-control" min="0" id="csPrice">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="card-body p-0 my-3">
                                                            <b-card no-body>
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-description>
                                                                        <span>Description</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accordion-description" accordion="my-accordion1" role="tabpanel">
                                                                    <b-card-body class="p-1 pb-0">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group">
                                                                                    <textarea v-model="costEstimation.description" class="form-control" id="description" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveCostEstimation" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="costEstTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="costEstimations" @row-selected="onRowSelected" :fields="costEstimationFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(total)="data">
                                    {{ currencySymbol }} {{ data.item.cost }}
                                </template>
                                <template v-slot:cell(quantity)="data">
                                    {{ data.value }} {{ data.item.uomType }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <!-- <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteCostEstimation(data.item.id)"v-b-modal.deleteCostEstimation><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Cost Estimations found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="costEstimations.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details" :active="elements.showDetail">
                                        <div class="row input-group-label">
                                            <div class="col-4">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{costEstimationDetail.costEstimationId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col">
                                                
                                            </div>
                                            <div class="col-6 col-sm-2 pl-0">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateCostEstimation" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label>Title</label>
                                                    <input type="text" class="form-control" maxlength="100" :disabled="!elements.editMode" v-model="costEstimationDetail.title">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Activity</label>                                             
                                                    <b-input-group>
                                                        <select v-model="costEstimationDetail.activityId" :disabled="!elements.editMode" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <b-input-group-append class="append-0">
                                                            <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Quantity</label>                                             

                                                    <b-input-group>
                                                        <b-form-input type="number" v-model="costEstimationDetail.quantity" :disabled="!elements.editMode" @input="setTotalCostDet" class="form-control" min="0" id="csQuantity" ></b-form-input>
                                                        <b-input-group-append class="append-50">
                                                            <select v-if="elements.editMode" v-model="costEstimationDetail.unitOfMesurement" class="form-control">
                                                                <option :value="{id: '', text:''}">Select</option>
                                                                <option v-for="(info, index) in uomTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                            </select>
                                                            <input v-else type="text" class="form-control" disabled v-model="costEstimationDetail.unitOfMesurement.text">
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="csPrice">Unit Cost</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <span class="mx-auto">{{ currencySymbol }}</span>
                                                            </div>
                                                        </div>
                                                        <input type="number" v-model="costEstimationDetail.unitCost" :disabled="!elements.editMode" @input="setTotalCostDet" class="form-control" min="0" id="csPrice">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="csPrice">Total Cost</label>
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="input-group-text">
                                                                <span class="mx-auto">{{ currencySymbol }}</span>
                                                            </div>
                                                        </div>
                                                        <input type="number" v-model="costEstimationDetail.cost" :disabled="!elements.editMode" class="form-control" min="0" id="csPrice">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label>Description</label>
                                                    <textarea class="form-control" maxlength="1000" rows="4" :disabled="!elements.editMode" v-model="costEstimationDetail.description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="form-row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='costEstimationDetail.preparedBy' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="costEstimationDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="costEstimationDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" /> -->
        <AddResourceLookup :resourceLookupTitle="resourceLookupTitle" :resourceCategoryId="1" @refresh-lookup="lookupData" />
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" />
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="costEstimation.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <DeleteComponent id="deleteCostEstimation" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Cost Estimation?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
import AddResourceLookup from '@/components/popup/lookup/AddResourceLookup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
import enummixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        TypeLookup,
        // ActivityLookup,
        AddResourceLookup,
        ProcessActivityLookup,
        VhextMoneyControl ,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
            },
            showOverlay: false,
            showDetOverlay: false,
            typeLookupTitle:'CostEstimation Type Settings',
            resourceLookupTitle: 'Resources',

            prependText:'₹',
            statusText:'',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: this.$route.params.ermId,
            currencySymbol: localStorage.getItem("currency"),
            entity: 'cost_estimation',
            
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            // ermId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),


            masterStatusText: '',
            resourceText: 'Resource Type',
            effortText: 'Cost Type',
            resourceTypeText: 'Resource Type',
            activityLookupTitle: 'Activity Settings',
            processActivityLookupTitle: 'Process / Activity Settings',

            mainTableArray: [],

            costEstimation: {
                id: '',
                costEstimationId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'cost_estimation',
                entityId: '',
                refEntityId: '',
                activityId: '',
                activity: '',
                erdId: '',
                title: '',
                description: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                uomType: '',
                cost: '',
                unitCost: '',
                preparedById: localStorage.getItem("userId"),
                costEstimationTypeId: '',
                csCategoryId: '',
                ermTitle: '',
                refErmEntity: '',
                refErmId: '',
            },

            costEstimationDetail: {
                id: '',
                costEstimationId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'cost_estimation',
                entityId: '',
                refEntityId: '',
                activityId: '',
                activity: '',
                erdId: '',
                title: '',
                description: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                uomType: '',
                cost: '',
                unitCost: '',
                preparedById: localStorage.getItem("userId"),
                costEstimationTypeId: '',
                csCategoryId: '',
                ermTitle: '',
                refErmEntity: '',
                refErmId: '',
            },
            selCostUOM: [],

            activities: [],
            uomTypes: [],
            costEstimations: [],
            ermRefLookup: [],
            costEstimationFields: [
                { key: 'costEstimationId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Item Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'quantity', label: 'Quantity', sortable: true, sortDirection: 'desc' },
                // { key: 'uomType', label: 'Unit' },
                { key: 'unitCost', label: 'Unit Cost', sortable: true, sortDirection: 'desc' },
                { key: 'cost', label: 'Total Cost', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [ 5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getCostEstimation();
        this.lookupData();
    },
    validations: {
        costEstimation: {
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            }
        },
        costEstimationDetail: {
            title: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(1000)
            }
        },
    },
    methods: {
        getCostEstimation: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/list', data)
                .then((response) => {
                    let result = response.data;
                    this.costEstimations = result.costEstimationList;
                    this.elements.editMode = false;
                    if (this.costEstimations && this.costEstimations.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.costEstimationDetail.id, this.mainTableArray, 'costEstTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                    this.totalRows = this.costEstimations.length;
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,                
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.activities = result.activityLookup;
                    this.uomTypes = result.customTypeLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveCostEstimation: function() {
            this.costEstimation.uomType = this.costEstimation.unitOfMesurement.text
            this.costEstimation.uomTypeId = this.costEstimation.unitOfMesurement.id;

            this.$v.costEstimation.$touch();
            if (!this.$v.costEstimation.$invalid) {
                console.log(this.costEstimation);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/add', this.costEstimation)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getCostEstimation();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
                }
        },
        updateCostEstimation: function() {
            this.costEstimationDetail.uomType = this.costEstimationDetail.unitOfMesurement.text
            this.costEstimationDetail.uomTypeId = this.costEstimationDetail.unitOfMesurement.id;

            this.$v.costEstimationDetail.$touch();
            if (!this.$v.costEstimationDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/update', this.costEstimationDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getCostEstimation();
                        }
                    })
                    .catch(error => {
                            this.showDetOverlay = false;
                            iziToast.error({
                               title: 'Error',
                               message: 'Error',
                               position: 'topRight'
                           });
                        })
            }
        },
        deleteCostEstimation: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/costestimation/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCostEstimation();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.visible = false;
            this.costEstimation.id = '';
            this.costEstimation.costEstimationId = '';
            this.costEstimation.title = '';
            this.costEstimation.description = '';
            this.costEstimation.quantity = '';
            this.costEstimation.unitOfMesurement.id = '';
            this.costEstimation.unitOfMesurement.text = '';
            this.costEstimation.uomTypeId = '';
            this.costEstimation.uomType = '';
            this.costEstimation.cost = '';
            this.costEstimation.unitCost = '';
            this.costEstimation.costEstimationTypeId = '';
            this.costEstimation.csCategoryId = '';
            this.$v.costEstimation.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.costEstimationDetail.id = data.id;
            this.costEstimationDetail.costEstimationId = data.costEstimationId;
            this.costEstimationDetail.vhextAccountId = data.vhextAccountId;
            this.costEstimationDetail.projectId = data.projectId;
            this.costEstimationDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.costEstimationDetail.entityId = data.entityId;
            this.costEstimationDetail.refEntityId = data.refEntityId;
            this.costEstimationDetail.erdId = data.erdId;
            this.costEstimationDetail.costEstimationTypeId = data.costEstimationTypeId;
            this.costEstimationDetail.title = data.title;
            this.costEstimationDetail.csCategoryId = data.csCategoryId;
            this.costEstimationDetail.description = data.description;
            this.costEstimationDetail.activityId = data.activityId;
            this.costEstimationDetail.unitCost = data.unitCost;
            this.costEstimationDetail.uomTypeId = data.uomTypeId;
            this.costEstimationDetail.unitOfMesurement.id = data.uomTypeId;
            this.costEstimationDetail.unitOfMesurement.text = data.uomType;
            this.costEstimationDetail.quantity = data.quantity;
            this.costEstimationDetail.cost = data.cost;
            this.costEstimationDetail.preparedById = data.preparedById;
            this.costEstimationDetail.preparedBy = data.preparedBy;
            this.costEstimationDetail.createdDt = data.createdDt;
            this.costEstimationDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.costEstimationDetail.id = data.id;
                this.costEstimationDetail.costEstimationId = data.costEstimationId;
                this.costEstimationDetail.vhextAccountId = data.vhextAccountId;
                this.costEstimationDetail.projectId = data.projectId;
                this.costEstimationDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.costEstimationDetail.entityId = data.entityId;
                this.costEstimationDetail.refEntityId = data.refEntityId;
                this.costEstimationDetail.erdId = data.erdId;
                this.costEstimationDetail.costEstimationTypeId = data.costEstimationTypeId;
                this.costEstimationDetail.title = data.title;
                this.costEstimationDetail.csCategoryId = data.csCategoryId;
                this.costEstimationDetail.description = data.description;
                this.costEstimationDetail.activityId = data.activityId;
                this.costEstimationDetail.unitCost = data.unitCost;
                this.costEstimationDetail.uomTypeId = data.uomTypeId;
                this.costEstimationDetail.unitOfMesurement.id = data.uomTypeId;
                this.costEstimationDetail.unitOfMesurement.text = data.uomType;
                this.costEstimationDetail.quantity = data.quantity;
                this.costEstimationDetail.cost = data.cost;
                this.costEstimationDetail.preparedById = data.preparedById;
                this.costEstimationDetail.preparedBy = data.preparedBy;
                this.costEstimationDetail.createdDt = data.createdDt;
                this.costEstimationDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        viewCostEstimation: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/finance/costestimation/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.costEstimationDetail = result.costEstimation;
                    // this.costEstimationDetail.effortText = this.getCostType(this.effortUOM,this.costEstimationDetail.effortType);

                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewCostEstimation(this.costEstimationDetail.id);
        },
        setTotalCost: function() {
            this.costEstimation.cost = this.costEstimation.quantity * this.costEstimation.unitCost;
        },
        setTotalCostDet: function() {
            this.costEstimationDetail.cost = this.costEstimationDetail.quantity * this.costEstimationDetail.unitCost;
        },
    }
}
</script>