<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="form-row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Employee Performance Evaluation</h4>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Employees">
                           
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="empFilter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="employeeTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="employeeList" @row-selected="onRowSelected" :fields="employeeFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="empFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>                                                                               <template v-slot:empty="user">
                                            <div class="text-center">No Employees found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="user">
                                            <div class="text-center">{{ user.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                    <b-pagination v-if="employeeList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="row">
                                    <div class="col-lg-6 col-6">
                                        <label class="d-block py-2 px-0 my-0 accordion-title" block href="javascript:">
                                            Employee Performance Rating
                                        </label>
                                    </div>
                                    <div class="col-lg-6 col-6">
                                        <div class="form-row">
                                            <div class="col-4 text-right">
                                                <label class="mt-1 mb-0">
                                                    <p class="m-0">Review Period</p>
                                                </label>
                                            </div>
                                            <div class="col-4">
                                                <select v-model="reviewPeriodEnum" @change="onReviewPeriodChange();" :class="{ 'is-invalid': $v.employeePerformance.reviewPeriodEnum.$error }" class="form-control" id="assetCategoryIdDet" >
                                                    <!-- <option value="">Select</option> -->
                                                    <option v-for="(info, index) in performanceReviewPeriod" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                            <div class="col-4">
                                                <input v-if="reviewPeriodEnum == 1" type="month" :class="{ 'is-invalid': $v.employeePerformance.reviewPeriod.$error }" class="form-control" @change="getEmployeeList();" v-model="reviewPeriod">
                                                <select v-else v-model="reviewPeriod" :class="{ 'is-invalid': $v.employeePerformance.reviewPeriod.$error }" class="form-control" @change="getEmployeeList();" >
                                                    <option value="">Select Year</option>
                                                    <option v-for="(info, index) in years" :value="info">{{info}}</option>
                                                </select>
                                            </div>
                                            <div v-if="$v.employeePerformance.reviewPeriodEnum.$error || $v.employeePerformance.reviewPeriod.$error" class="col-lg-12 col-sm-12 text-right">
                                                <label class="text-danger">Please Select Review Period</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator mb-2"></div>
                                <b-card no-body class="mb-2">
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                            Performance By Area
                                        </label>
                                        <div class="separator mx-2"></div>
                                    </b-card-header>
                                    <b-card-body class="py-3 px-2">
                                        <div class="card-body p-0 mb-2">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.newPerformance>
                                                        <span v-if="elements.perAreaEditMode"><i class="fa fa-pencil pr-2 action-link"></i>Edit Performance</span>
                                                        <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Performance</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="newPerformance" accordion="my-accordion" role="tabpanel" v-model="elements.visible" >
                                                    <b-card-body class="p-2" :key="dataRefreshKey">
                                                        <div class="form-row">
                                                            <div class="col-12 col-md-5">
                                                                <div class="form-group">
                                                                    <label for="empPerformanceAreaId">Performance Area</label>
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.performanceRating.empPerformanceAreaId.$error }">
                                                                        <select v-model="performanceRating.empPerformanceAreaId" :class="{ 'is-invalid': $v.performanceRating.empPerformanceAreaId.$error }" class="form-control" id="empPerformanceAreaId" >
                                                                            <option :value="{id: '', text: ''}">Select</option>
                                                                            <option v-for="(info, index) in performanceAreaLookup" :value="{id: info.id, text: info.value}">{{info.value}}</option>
                                                                        </select>
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.performanceRating.empPerformanceAreaId.$error && !$v.performanceRating.empPerformanceAreaId.required" class="text-danger">Please select Performance Area</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-4">
                                                                <div class="form-group">
                                                                    <label for="ratingScaleId">Performance Rating</label>
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.performanceRating.ratingScaleId.$error }">
                                                                        <select v-model="performanceRating.ratingScaleId" :class="{ 'is-invalid': $v.performanceRating.ratingScaleId.$error }" class="form-control" id="ratingScaleId" >
                                                                            <option :value="ratingScaleValue(null)">Select</option>
                                                                            <option v-for="(info, index) in ratingScaleLookup" :value="ratingScaleValue(info)">{{info.performanceLevelAlt}}</option>
                                                                        </select>
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.performanceRating.ratingScaleId.$error && !$v.performanceRating.ratingScaleId.required" class="text-danger">Please select Rating</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-3">
                                                                <div class="form-group">
                                                                    <label class="v-hidden d-block">Add</label>
                                                                    <button type="submit" @click="savePerformanceRating" class="btn sbtn btn-sm btn-primary py-1 px-2 mr-1">
                                                                        <span v-if="elements.perAreaEditMode">Update</span>
                                                                        <span v-else>
                                                                            <i class="fa fa-plus pr-1"></i>Add
                                                                        </span>
                                                                    </button>
                                                                    <button type="submit" @click="clearPerformanceRating" class="btn sbtn btn-sm btn-secondary py-1 px-2">
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="performanceRatingList" :fields="performanceRatingFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(rating)="data">
                                                <b-form-rating id="rating-inline" class="rating" @change="updateStarRating(data.item.id,data.value, 'emp_performance_rating', 'rating_scale_id')" no-border size="sm" color="#ff8800" :stars="employeePerformance.maxRating" inline v-model="data.value"></b-form-rating>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                <a href="javascript:" title="Delete" @click="deleteCandidate(data.item.id)" v-b-modal.deleteCandidate><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Performance Ratings added</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                    </b-card-body>
                                </b-card>
                                <b-card no-body class="mb-2">
                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                            Overall Performance
                                            <span class="float-right mr-2">
                                                <a v-if="!elements.editMode && !elements.newMode" href="javascript:" class="action-link" title="Edit" @click="elements.editMode=true;"><i class="fa fa-pencil-square-o fa-lg pr-1"></i>Edit</a>
                                            </span>
                                        </label>
                                        <div class="separator mb-2 mx-2"></div>
                                    </b-card-header>
                                    <b-card-body class="py-3 px-2">
                                        <div class="form-row">
                                            <div class="col-12 col-md-7">
                                                <div class="form-group">
                                                    <label for="ratingScaleId1">Performance Level</label>
                                                    <div v-if="!elements.newMode && !elements.editMode" class="form-row">
                                                        <div class="col-md-6">
                                                            <b-form-input type="text" v-model="employeePerformance.performanceLevelAlt" disabled></b-form-input>
                                                            <!-- <select v-model="employeePerformance.ratingScaleId" disabled class="form-control" id="ratingScaleId1" >
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in ratingScaleLookup" :value="info.id">{{info.performanceLevelAlt}}</option>
                                                            </select> -->
                                                        </div>
                                                        <div class="col-md-6">
                                                            <b-form-rating id="rating-inline" class="rating" @change="updateStarRating(employeePerformance.id,employeePerformance.rating, 'employee_performance', 'rating_scale_id')" size="sm" color="#ff8800" :stars="employeePerformance.maxRating" inline v-model="employeePerformance.rating"></b-form-rating>
                                                        </div>
                                                    </div>
                                                    <select v-else v-model="employeePerformance.ratingScaleId" class="form-control" id="ratingScaleId1" >
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in ratingScaleLookup" :value="info.id">{{info.performanceLevelAlt}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-5">
                                                <div class="form-group">
                                                    <label for="evaluatedById1">Evaluated By</label>
                                                    <select v-model="employeePerformance.evaluatedById" :disabled="!elements.newMode && !elements.editMode" class="form-control" id="evaluatedById1" >
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="card-body p-0">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.descriptionAcc2>
                                                        <label>Reviwer Comment</label>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="descriptionAcc2" accordion="my-descriptionAcc2" v-model="elements.descVisible" role="tabpanel">
                                                    <b-card-body class="p-1 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div v-if="elements.editMode" class="form-group">
                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="employeePerformance.description"></editor>
                                                                </div>
                                                                <div v-else class="form-group">
                                                                    <p class="pl-2"><span v-html="employeePerformance.description"></span></p>
                                                                </div>                                                                                                                
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                                <template v-if="elements.newMode || elements.editMode">                               
                                    <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 py-1 px-3">
                                        <span v-if="elements.editMode">Update</span>
                                        <span v-else>
                                            <i class="fa fa-floppy-o pr-2"></i>Save
                                        </span>
                                    </button>
                                    <button type="submit" @click="viewEmployeePerformance(employeePerformance.humanResourceId);" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        Editor,
    },
    data() {
        return {
            showDetOverlay: false,
            elements: {
                newMode: true,
                editMode: false,
                visible: false,
                perAreaEditMode: false,
                descVisible: false,
            },

            dataRefreshKey: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),

            employeeId: '',
            reviewPeriodEnum: '',
            reviewPeriod: '',

            employeePerformance: {
                id: '',
                employeePerformanceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                humanResourceId: '',
                reviewPeriodEnum: '',
                reviewPeriod: '',
                ratingScaleId: '',
                description: '',
                evaluatedById: '',
                evaluatedBy: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                empPerformanceRatingList: []
            },
            performanceRating: {
                id: '',
                empPerformanceRatingId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeePerformanceId: '',
                empPerformanceAreaId: {
                    id: '',
                    text: ''
                },
                ratingScaleId: {
                    id: '',
                    performanceLevelAlt: '',
                    rating: '',
                    performanceLevel: ''
                },
                description: '',
                createdById: localStorage.getItem("userId"),
            },

            mainTableArray: [],
            users: [],
            ratingScaleLookup: [],
            performanceAreaLookup: [],

            employeeList: [],
            employeeFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'employeeCode', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },        
                { key: 'designation', label: 'Designation', tdClass:'', sortable: true, sortDirection: 'desc' },        
                { key: 'performanceLevel', label: 'Performance', sortable: true, sortDirection: 'desc' },        
            ],

            performanceRatingList: [],
            performanceRatingFields: [
                { key: 'index', label: 'SN' },
                { key: 'performanceArea', label: 'Performance Area', sortable: true, sortDirection: 'desc' },
                { key: 'rating', label: 'Rating', sortable: true, sortDirection: 'desc' },
                { key: 'performanceLevel', label: 'Performance Level', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            empFilter: null,
            filter: null,
            filterOn: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        years () {
            const year = new Date().getFullYear() - 5
            return Array.from({length: 50}, (value, index) => year + index)
        },
    },
    mounted() {
        this.reviewPeriodEnum = this.performanceReviewPeriod[0].id;
        this.reviewPeriod = this.getMonthYear(new Date());
        this.getEmployeeList();
        this.lookupData();
    },
    validations: {
        employeePerformance: {
            reviewPeriodEnum: {
                required,
            },
            reviewPeriod: {
                required,
            },
        },
        performanceRating: {
            empPerformanceAreaId: {
                id: {
                    required,
                }
            },
            ratingScaleId: {
                id: {
                    required,
                }
            },
        },
    },
    methods: {
        getEmployeeList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                reviewPeriodEnum: this.reviewPeriodEnum,
                reviewPeriod: this.reviewPeriodEnum==1 ? this.reviewPeriod  + "-11" : this.reviewPeriod + "-07-11",
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/performance/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.employeeList = result.employeeList;
                    this.totalRows = this.employeeList.length;
                    if (this.employeeList.length>0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.employeeId, this.mainTableArray, 'employeeTable');
                        })
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeperformance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.ratingScaleLookup = result.ratingScaleLookup;
                    this.users = result.userLookup;
                    this.performanceAreaLookup = result.empPerformanceAreaLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            if(this.reviewPeriodEnum == 1) {
                this.employeePerformance.reviewPeriod = this.reviewPeriod + "-11";
            }
            else {
                this.employeePerformance.reviewPeriod = this.reviewPeriod + "-07-11";
            }
            this.employeePerformance.reviewPeriodEnum = this.reviewPeriodEnum;
            this.$v.employeePerformance.$touch();
            if (!this.$v.employeePerformance.$invalid) {
                if (this.elements.editMode) {
                    this.updateEmployeePerformance();
                }
                else {
                    this.saveEmployeePerformance();
                }
            }
        },
        saveEmployeePerformance: function() {
            this.employeePerformance.empPerformanceRatingList = this.performanceRatingList;
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeperformance/add', this.employeePerformance)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.clearData();
                        this.getEmployeeList();
                        this.viewEmployeePerformance(this.employeePerformance.humanResourceId);
                        this.elements.newMode = false;
                        this.elements.visible = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateEmployeePerformance: function() {
            // this.employeePerformance.empPerformanceRatingList = this.performanceRatingList;
            console.log(this.employeePerformance);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeperformance/update', this.employeePerformance)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.clearData();
                        this.getEmployeeList();
                        this.elements.newMode = false;
                        this.elements.visible = false;
                        this.elements.editMode = false;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        savePerformanceRating: function() {
            this.$v.performanceRating.$touch();
            if (!this.$v.performanceRating.$invalid) {
                let performanceRating = {};
                performanceRating.id = this.performanceRating.id;
                performanceRating.empPerformanceRatingId = this.performanceRating.empPerformanceRatingId;
                performanceRating.vhextAccountId = this.performanceRating.vhextAccountId;
                performanceRating.employeePerformanceId = this.employeePerformance.id;
                performanceRating.empPerformanceAreaId = this.performanceRating.empPerformanceAreaId.id;
                performanceRating.performanceArea = this.performanceRating.empPerformanceAreaId.text;
                performanceRating.ratingScaleId = this.performanceRating.ratingScaleId.id;
                performanceRating.performanceLevelAlt = this.performanceRating.ratingScaleId.performanceLevelAlt;
                performanceRating.rating = this.performanceRating.ratingScaleId.rating;
                performanceRating.performanceLevel = this.performanceRating.ratingScaleId.performanceLevel;
                performanceRating.description = this.performanceRating.description;
                performanceRating.createdById = this.performanceRating.createdById;

                if (!this.matchIdValExists(performanceRating.empPerformanceAreaId, performanceRating.id)) {
                    if (this.elements.newMode) {   
                        this.performanceRatingList.push(performanceRating);
                        this.clearPerformanceRating();
                    }
                    else if (this.elements.perAreaEditMode) {
                        this.updatePerformanceRating(performanceRating);
                    }
                    else {
                        this.addPerformanceRating(performanceRating);
                    }
                } else {
                    iziToast.info({
                        message: 'Performance Area already exists',
                        position: 'topCenter'
                    });
                }
            }
        },
        addPerformanceRating: function(performanceRating) {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empperformancerating/add', performanceRating)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearPerformanceRating();
                        this.viewEmployeePerformance(this.employeePerformance.humanResourceId);
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                })
        },
        updatePerformanceRating: function(performanceRating) {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/empperformancerating/update', performanceRating)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearPerformanceRating();
                        this.viewEmployeePerformance(this.employeePerformance.humanResourceId);
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                })
        },
        viewEmployeePerformance: function(humanResourceId) {
            if (humanResourceId && this.reviewPeriodEnum && this.reviewPeriod) {
                console.log(this.reviewPeriod)
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    humanResourceId: humanResourceId,
                    reviewPeriodEnum: this.reviewPeriodEnum,
                    reviewPeriod: this.reviewPeriodEnum==1 ? this.reviewPeriod  + "-11" : this.reviewPeriod + "-07-11",
                }
                console.log(data)
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/resource/employeeperformance/hr/view', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.employeePerformance) {
                            this.elements.newMode = false;
                            this.elements.visible = false;
                            this.elements.editMode = false;
                            this.employeePerformance = result.employeePerformance;
                            this.employeePerformance.reviewPeriodEnum = this.reviewPeriodEnum;
                            this.performanceRatingList = result.empPerformanceRatingList;
                            // this.employeePerformance.empPerformanceRatingList = empPerformanceRatingList;
                        }
                        else {
                            this.clearData();
                        }
                        if (this.reviewPeriodEnum == 1) {
                            this.employeePerformance.reviewPeriod = this.getMonthYear(new Date(this.reviewPeriod));
                        } 
                        else {
                            this.employeePerformance.reviewPeriod = new Date(this.employeePerformance.reviewPeriod).getFullYear();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                    })
            }
        },
        updateStarRating: function(id, rating, entity, columnName) {
            let ratingScaleData = this.ratingScaleLookup.find(item => item.rating == rating);
            if (ratingScaleData.id) {
                let data = {
                    id: id,
                    entity: entity,
                    columnName: columnName,
                    numberValue: ratingScaleData.id,
                }
                console.log(data)
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/numvalue/update', data)
                    .then(response => {
                        this.showDetOverlay = false;
                        var result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.viewEmployeePerformance(this.employeePerformance.humanResourceId);
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        // console.log(error);
                    });
            }

        },
        onRowSelected: function(items) {
            if (items && items.length > 0) {
                let data = items[0];
                this.employeeId = data.id;
                this.employeePerformance.humanResourceId = data.humanResourceId;
                this.viewEmployeePerformance(data.humanResourceId);
            }
        },
        onReviewPeriodChange() {
            if(this.reviewPeriodEnum == 1) {
                this.reviewPeriod = (this.reviewPeriod).toString().length==4 ? this.reviewPeriod+"-07-11" : this.reviewPeriod;
                this.reviewPeriod = this.getMonthYear(new Date(this.reviewPeriod));
            } else {
                this.reviewPeriod = (this.reviewPeriod).toString().length==7 ? this.reviewPeriod+"-11" : this.reviewPeriod;
                this.reviewPeriod = new Date(this.reviewPeriod).getFullYear();
            }
            this.$nextTick(() => {
                this.viewEmployeePerformance(this.employeePerformance.humanResourceId);
            })
        },
        clearData: function() {
            this.elements.newMode = true;
            this.elements.editMode = false;
            this.elements.descVisible = false;
            this.employeePerformance.id = '';
            this.employeePerformance.employeePerformanceId = '';
            // this.employeePerformance.humanResourceId = '';
            this.employeePerformance.reviewPeriodEnum = this.performanceReviewPeriod[0].id;
            this.employeePerformance.reviewPeriod = this.getMonthYear(new Date());
            this.employeePerformance.ratingScaleId = '';
            this.employeePerformance.rating = '';
            this.employeePerformance.description = '';
            this.employeePerformance.evaluatedById = '';
            this.employeePerformance.evaluatedBy = '';
            this.employeePerformance.createdBy = '';
            this.$v.employeePerformance.$reset();
            this.performanceRatingList = [];
            this.employeePerformance.empPerformanceRatingList = [];

            // this.reviewPeriodEnum = this.performanceReviewPeriod[0].id;
            // this.reviewPeriod = this.getMonthYear(new Date());
            this.clearPerformanceRating();
        },
        clearPerformanceRating: function() {
            this.elements.visible = false;
            this.elements.perAreaEditMode = false;
            this.performanceRating.id = '';
            this.performanceRating.empPerformanceRatingId = '';
            this.performanceRating.employeePerformanceId = '';
            this.performanceRating.empPerformanceAreaId.id = '';
            this.performanceRating.empPerformanceAreaId.text = '';
            this.performanceRating.ratingScaleId.id = '';
            this.performanceRating.ratingScaleId.performanceLevelAlt = '';
            this.performanceRating.ratingScaleId.rating = '';
            this.performanceRating.ratingScaleId.performanceLevel = '';
            this.performanceRating.description = '';
            this.$v.performanceRating.$reset();
        },
        showEdit(data) {
            this.elements.visible = true;
            this.elements.perAreaEditMode = true;
            this.performanceRating.id = data.id;
            this.performanceRating.empPerformanceRatingId = data.empPerformanceRatingId;
            this.performanceRating.vhextAccountId = data.vhextAccountId;
            this.performanceRating.employeePerformanceId = data.employeePerformanceId;
            this.performanceRating.empPerformanceAreaId.id = data.empPerformanceAreaId;
            this.performanceRating.empPerformanceAreaId.text = data.performanceArea;
            this.performanceRating.ratingScaleId.id = data.ratingScaleId;
            this.performanceRating.ratingScaleId.performanceLevelAlt = data.performanceLevelAlt;
            this.performanceRating.ratingScaleId.rating = data.rating;
            this.performanceRating.ratingScaleId.performanceLevel = data.performanceLevel;
            this.performanceRating.description = data.description;
            this.performanceRating.createdById = data.createdById;
            this.dataRefreshKey++;
        },
        ratingScaleValue(data) {
            let value = {};
            // let data = this.performanceRating.ratingScaleId;
            value.id = data ? data.id : '';
            value.performanceLevelAlt = data ? data.performanceLevelAlt : '';
            value.rating = data ? data.rating : '';
            value.performanceLevel = data ? data.performanceLevel : '';
            return value;
        },
        matchIdValExists: function(value, id) {
            let result = false;
            this.performanceRatingList.map(data => {
                if (value && data.empPerformanceAreaId && (data.id !== id || !id)) {
                    if (data.empPerformanceAreaId == value) {
                        result = true;
                    }
                }
            });
            return result;
        },
    }
}
</script>