<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Recruitment</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-12 col-md-4 col-lg-5">
                    <!-- <b-card no-body class="">
                        <b-card-body class="p-2 text-center">
                            <p class="mb-0">Jobs</p>
                        </b-card-body>
                    </b-card> -->
                    <div class="card">
                        <div class="card-body px-3 pb-3 pt-0">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div class="tab-floating-text mt-3">
                                    <b-button  class="addnew-tassk-btn sbtn float-right ml-2" @click="elements.showJobs = true" variant="outline-info" size="xs" :class="{'active' : elements.showJobs}" ><i class="fa fa-list pr-2" aria-hidden="true"></i>Job List</b-button>
                                </div>
                                <b-tabs v-if="!elements.isJobSelected || elements.showJobs" content-class="mt-3" v-model="candidateTabIndex" lazy>
                                    <b-tab title="Job List">
                                        <div class="row view-filter">
                                            <div class="col-sm-12">
                                                <div class="float-right">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                                <div class="float-left">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </div>
                                        <b-table show-empty small outlined stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="jobs" :fields="jobFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(experienceYears)="data">
                                                <span v-if="data.value">{{ data.value }} years </span>
                                                <span v-if="data.item.experienceMonths">{{ data.item.experienceMonths }} months</span>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <button type="submit" title="Start Recruiting" @click="showCandidates(data.item)" :class="{'active' : selectedJobId==data.item.id}" class="btn btn-outline-primary sbtn btn-xs py-1 px-2">
                                                    <span v-if="selectedJobId==data.item.id">
                                                        <i class="fa fa-hand-o-right pr-1" aria-hidden="true"></i>Selected
                                                    </span>
                                                    <span v-else>Select Job</span>
                                                </button>
                                            </template>
                                            <template v-slot:empty="user">
                                                <div class="text-center">No Jobs found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="user">
                                                <div class="text-center">{{ user.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
            
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                        <b-pagination v-if="jobs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        
                                    </b-tab>
                                </b-tabs>
                                <b-tabs v-if="elements.isJobSelected && !elements.showJobs" content-class="mt-3" v-model="candidateTabIndex" lazy>
                                    <b-tab title="Job">
                                        <div class="form-row mb-1">
                                            <div v-if="jobDetail.jobCode" class="col-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ jobDetail.jobCode }}</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ jobDetail.title }}</label>
                                            </div>
                                            <div v-if="jobDetail.status" class="col-3 pl-0 text-center">
                                                <label class="badge badge-secondary" title="Status">{{ jobDetail.status }}</label>
                                            </div>
                                        </div>
                                        <div class="form-row mb-1">
                                            <template v-if="jobDetail.position">
                                                <div class="col-3 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Position</label>
                                                </div>
                                                <div class="col-6 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{jobDetail.position}}</label>
                                                </div>
                                            </template>
                                            <div v-if="jobDetail.vacancies" class="col-3 pl-0 text-center">
                                                <label class="p-2 mb-0 d-block header-details">Openings: {{ jobDetail.vacancies }}</label>
                                            </div>
                                        </div>
                                        <div v-if="jobDetail.priority" class="form-row mb-1">
                                            <div class="col-3 pr-0">
                                                <label class="mb-0 p-0 pt-2 pb-2 d-block title-block">Qualification</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{jobDetail.priority}}</label>
                                            </div>
                                        </div>
                                        <div v-if="jobDetail.experienceYears || jobDetail.experienceMonths" class="form-row mb-1">
                                            <div class="col-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Req. Experience</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">
                                                    <span v-if="jobDetail.experienceYears">{{ jobDetail.experienceYears }} years </span>
                                                    <span v-if="jobDetail.experienceMonths">{{ jobDetail.experienceMonths }} months</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="jobDetail.location" class="form-row mb-1">
                                            <div class="col-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Location</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{jobDetail.location}}</label>
                                            </div>
                                        </div>
                                        <div v-if="jobDetail.dueDate" class="form-row mb-1">
                                            <div class="col-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Due Date</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{jobDetail.dueDate | formatDate }}</label>
                                            </div>
                                        </div>
                                        <div v-if="jobDetail.documentsRequired && jobDetail.documentsRequired.length>0" class="form-row mb-2">
                                            <div class="col-3 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Req. Documents</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">
                                                    <span v-for="document,index in jobDetail.documentsRequired" :key="document.id">{{ document.value }}<span v-if="index != jobDetail.documentsRequired.length-1">, </span></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-if="jobDetail.description" class="form-row mb-2">
                                            <div class="col-12 col-sm-12">
                                                <div class="card">
                                                    <div class="card-body py-1 px-0">
                                                        <p class="contact-title mb-2 ml-2"><span>Description</span></p>
                                                        <div class="row">
                                                            <div class="col-12 col-md-12 m-0 px-3">
                                                                <label class="p-2 mb-0 d-block header-details" v-html="jobDetail.description"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="technologySkills && technologySkills.length>0" class="form-row mb-2">
                                            <div class="col-12 col-sm-12">
                                                <div class="card">
                                                    <div class="card-body py-1 px-0">
                                                        <p class="contact-title mb-2 ml-2"><span>Technology & Skills</span></p>
                                                        <div class="row">
                                                            <div class="col-12 col-md-12">
                                                                <label class="p-2 mb-0 d-block header-details">
                                                                    <div v-for="technologySkill in technologySkills" :key="technologySkill.id">
                                                                        <p class="m-0 font-weight-bold">{{ technologySkill.technology }}</p>
                                                                        <p class="m-0">{{ technologySkill.skills }} </p>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-8 col-lg-7 pl-0">
                    <b-overlay :show="showOverlay" class="h-100" spinner-variant="primary" opacity="0.4" rounded="sm">
                        <div v-if="!elements.isJobSelected" class="card h-100">
                            <div class="card-body text-center">
                                <span>
                                    <i class="fa fa-hand-o-left pr-1" aria-hidden="true"></i>Please Select a Job
                                </span>
                            </div>
                        </div>
                        <div v-else class="card h-100">
                            <div v-if="showCanDetails" class="card-body px-3 pb-3 pt-0 h-100">
                                <div class="row pt-3">
                                    <p class="col-8 m-0">Candidate Details</p>
                                    <span class="col-4 text-right">
                                        <b-button  class="addnew-tassk-btn sbtn mb-1" @click="showCanDetails = false" variant="outline-danger" size="xs" ><i class="fa fa-remove pr-2" aria-hidden="true"></i>Close</b-button>
                                    </span>
                                </div>
                                <div class="separator mb-2"></div>
                                <CandidateViewComponent  class="py-2" ref="candidateViewRef" :attachmentData="attachmentPopupData" :referenceDetailId="referencePopupId" />
                            </div>
                            <div v-else-if="elements.showResume" class="card-body px-3 pt-3 pb-3 h-100">
                                <div class="w-100 tab-floating-text px-3">
                                    <div class="d-flex justify-content-between">
                                        <p class="m-0">Resume - {{ candidateName }}</p>
                                        <b-button  class="addnew-tassk-btn sbtn" @click="elements.showResume = false" variant="outline-danger" size="xs" ><i class="fa fa-remove pr-2" aria-hidden="true"></i>Close</b-button>
                                    </div>
                                    <div class="separator mb-2"></div>
                                </div>
                                <div class="pt-4 mt-2 h-100">
                                    <iframe v-if="isDocFile" :src="'https://view.officeapps.live.com/op/embed.aspx?src='+resumeFilePath" width="100%" height="100%" frameborder='0'></iframe>
                                    <iframe v-else :src="resumeFilePath" width="100%" height="100%" frameborder="0"></iframe>
                                </div>
                            </div>
                            <div v-else class="card-body px-3 pb-3 pt-0 h-100">
                                <div class="tab-floating-text mt-3">
                                    <div class="float-right">
                                        <span v-if="jobCode">{{ jobCode }} - </span>
                                        <span class="font-weight-bold">{{ jobTitle }}</span>
                                    </div>
                                </div>
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Candidate Search">
                                        <div id="scope-app" class="mb-3">
                                            <b-card no-body class="">
                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                    <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                        <span><i class="fa fa-search pr-2 action-link"></i>Search Candidates</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                    <b-card-body class="px-1 pt-1">
                                                        <div class="row view-filter">
                                                            <div class="col-4 float-left">
                                                                <div class="dataTables_filter">
                                                                    <label>
                                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-8 text-right">
                                                                <b-button  class="addnew-tassk-btn sbtn float-right ml-2" variant="outline-primary" v-b-toggle.accordion-searchfliter size="xs" :class="{'active' : elements.filterVisible}" :title="(elements.filterVisible ? 'Hide' : 'Show') + ' Filter'"><i class="fa fa-filter pr-2" aria-hidden="true"></i>{{ elements.filterVisible ? 'Hide' : 'Filter'}}</b-button>
                                                            </div>
                                                            <!-- <div class="col-3 float-right">
                                                                <div class="dataTables_length">
                                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                        <b-form-select v-model="perPageCandidate" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                    </b-form-group>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                        <b-card no-body class="">
                                                            <b-collapse id="accordion-searchfliter" v-model="elements.filterVisible" accordion="my-accordion-filter"  role="tabpanel">
                                                                <b-card-body class="p-2">
                                                                    <div class="row view-filter">
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <label for="positionDet">Position</label>
                                                                                <input type="text" class="form-control" id="positionDet" >
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <label for="qualificationDet">Qualification</label>
                                                                                <input type="text" class="form-control" id="qualificationDet" >
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="text-right">
                                                                        <button type="submit" @click="" class="sbtn btn btn-xs btn-primary mb-0 mr-3 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                                        <a href="javascript:" title="Reset Filter" @click=""><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                        <b-table show-empty outlined small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="candidateList" :fields="candidateFields" responsive="sm" :current-page="currentPageCandidate" :per-page="perPageCandidate" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredCandidate">
                                                            <template #cell(index)="data">
                                                                {{ data.index + 1 }}
                                                            </template>
                                                            <template v-slot:cell(name)="data">
                                                                <a class="tooltiptext action-link" href="javascript:" @click="showCandidateDetails(data.item.id)">{{data.value}}</a>
                                                            </template>
                                                            <template v-slot:cell(actions)="data">
                                                                <div v-if="data.item.attachmentCount > 0" class="dropdown dropright d-inline">
                                                                    <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachmentsDd(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    </a>
                                                                    <div class="dropdown-menu">
                                                                        <b-dropdown-item v-if="attachmentDdFiltered.length==0"></b-dropdown-item>
                                                                        <div v-if="attachmentDdFiltered.length>0">
                                                                            <ul class="attachmentList-ul p-0 m-0">
                                                                                <li v-for="attachment,index in attachmentDdFiltered" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                                                    <a href="javascript:" @click="attachmentClick(attachment, data.item.name)" class="action-link" >{{attachment.fileName}}</a>

                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- <button v-if="!selectedJobId" type="submit" disabled title="Selected" class="btn btn-danger active sbtn btn-xs py-1 px-2">Select a Job</button> -->
                                                                <button v-if="candidateExists(data.item.id)" type="submit" disabled title="Selected" class="btn btn-success active sbtn btn-xs py-1 px-2"><i class="fa fa-check pr-1"></i>Selected</button>
                                                                <button v-else type="submit" title="Select Members" @click="addCandidate(data.item.id)" class="btn btn-outline-success sbtn btn-xs py-1 px-2">Select</button>
                                                            </template>
                                                            <template v-slot:empty="scope">
                                                                <div class="text-center">No Candidates found</div>
                                                            </template>
                                                            <template v-slot:emptyfiltered="scope">
                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                            </template>
                                                        </b-table>
                                                        <div class="pb-2">
                                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalCandidateRows}} Items</span>

                                                            <b-pagination v-if="candidateList.length!=0" pills v-model="currentPageCandidate" :total-rows="totalCandidateRows" :per-page="perPageCandidate" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                        <p class="mb-0">Selected Candidates</p>
                                        <b-table show-empty outlined small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="recruitmentList" :fields="recruitmentFields" responsive="sm" :current-page="currentPageRecruitment" :per-page="perPageRecruitment" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRecruitment">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(name)="data">
                                                <a class="tooltiptext action-link" href="javascript:" @click="showCandidateDetails(data.item.candidateId)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <div v-if="data.item.id == idEdit" class="action-link">
                                                    <select v-model="statusData" @change="updateEntity(data.item.id,statusData, 'status_id')" class="form-control" id="statusId" @blur="cancelIdEdit()">
                                                        <option :value="{id:null,value:null}">Select</option>
                                                        <option v-for="(info, index) in recruitmentStatus" :value="{id:info.id,value:info.value}"
                                                        >{{info.value}}</option>
                                                    </select>                            
                                                </div>
                                                <div v-else class="action-link" @click="idEdit=data.item.id;statusData.id=data.item.statusId;statusData.value=data.item.status">
                                                    {{ data.item.status ? data.item.status : '-- Update Status --' }}
                                                </div>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <div v-if="data.item.attachmentCount > 0" class="dropdown dropright d-inline pr-2">
                                                    <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachmentsDd(data.item.candidateId)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <b-dropdown-item v-if="attachmentDdFiltered.length==0"></b-dropdown-item>
                                                        <div v-if="attachmentDdFiltered.length>0">
                                                            <ul class="attachmentList-ul p-0 m-0">
                                                                <li v-for="attachment,index in attachmentDdFiltered" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                                    <a href="javascript:" @click="attachmentClick(attachment, data.item.name)" class="action-link" >{{attachment.fileName}}</a>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href="javascript:" title="Delete" @click="deleteRecruitment(data.item.id)" v-b-modal.deleteRecruitment><i class="fa fa-trash"></i></a>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Candidates found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRecruitmentRows}} Items</span>
                                    </b-tab>
                                    <b-tab v-for="status,index in recruitmentStatus" :key="status.id" :title="status.value">
                                       <!--  <div class="row view-filter">
                                            <div class="col-4 float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filterShortListed[index]" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-5">
                                            </div>
                                            <div class="col-3 float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPageShortListed" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div> -->
                                        <b-table show-empty outlined small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filterCandidateByStatus(status.id)" :fields="candidateFields" responsive="sm" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(name)="data">
                                                <a class="tooltiptext action-link" href="javascript:" @click="showCandidateDetails(data.item.candidateId)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <div v-if="data.item.id == idEdit" class="action-link">
                                                    <select v-model="statusData" @change="updateEntity(data.item.id,statusData, 'status_id')" class="form-control" id="statusId" @blur="cancelIdEdit()">
                                                        <option :value="{id:null,value:null}">Select</option>
                                                        <option v-for="(info, index) in recruitmentStatus" :value="{id:info.id,value:info.value}"
                                                        >{{info.value}}</option>
                                                    </select>                            
                                                </div>
                                                <div v-else class="action-link" @dblclick="idEdit=data.item.id;statusData.id=data.item.statusId;statusData.value=data.item.status">
                                                    {{ data.item.status ? data.item.status : '-- Update Status --' }}
                                                </div>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <div v-if="data.item.attachmentCount > 0" class="dropdown dropright d-inline">
                                                    <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachmentsDd(data.item.candidateId)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    </a>
                                                    <div class="dropdown-menu">
                                                        <b-dropdown-item v-if="attachmentDdFiltered.length==0"></b-dropdown-item>
                                                        <div v-if="attachmentDdFiltered.length>0">
                                                            <ul class="attachmentList-ul p-0 m-0">
                                                                <li v-for="attachment,index in attachmentDdFiltered" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                                    <a href="javascript:" @click="attachmentClick(attachment, data.item.name)" class="action-link" >{{attachment.fileName}}</a>

                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <button v-if="candidateExists(data.item.id)" type="submit" disabled title="Selected" class="btn btn-success active sbtn btn-xs py-1 px-2"><i class="fa fa-check pr-1"></i>Selected</button>
                                                <button v-else type="submit" title="Select Members" @click="addCandidate(data.item.id)" class="btn btn-outline-success sbtn btn-xs py-1 px-2">Select</button> -->
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Candidates found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                    </b-tab>
                                    <!-- <b-tab title="Hired">
                                        <div class="row view-filter">
                                            <div class="col-4 float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filterHired" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>

                                            <div class="col-5">
                                            </div>
                                            <div class="col-3 float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPageHired" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table show-empty outlined small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="hiredCandidates" :fields="candidateFields" responsive="sm" :current-page="currentPageHired" :per-page="perPageHired" :filter="filterHired" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredHired">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(name)="data">
                                                <a class="tooltiptext action-link" href="javascript:" @click="showCandidateDetails(data.item.candidateId)">{{data.value}}</a>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Candidates found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalHiredRows}} Items</span>

                                        <b-pagination v-if="candidateList.length!=0" pills v-model="currentPageHired" :total-rows="totalHiredRows" :per-page="perPageHired" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </b-tab> -->
                                </b-tabs>
                            </div>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteRecruitment" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Candidate?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import CandidateViewComponent from '@/components/common/CandidateViewComponent.vue';
export default {
    name: 'app',
    components: {
        CandidateViewComponent,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showCanDetails: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false,
                isJobSelected: false,
                showJobs: true,
                filterVisible: false,
                showResume: false,
            },
           
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'recruitment',

            selectedJobId: '',
            jobTitle: '',
            jobCode: '',
            candidateTabIndex: 0,

            resumeFilePath: '',
            resumeFileName: '',
            candidateName: '',

            attachmentPopupData: {},
            referencePopupId: 0,

            recruitment: {
                id: '',
                recruitmentId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                jobId: '',
                candidateId: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
            },

            idEdit: '',
            statusData:{
                id: null,
                value: null,
            },

            attachmentDdList: [],
            jobDetail: {},
            technologySkills: [],
            recruitmentStatus: [],

            jobs: [],
            jobFields: [
                { key: 'index',label: 'SN' },
                { key: 'jobCode', label: 'Job Code', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                // { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                // { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            candidateList: [],
            candidateFields: [
                { key: 'index',label: 'SN' },
                { key: 'candidateId', label: 'Cand. Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'qualification', label: 'Qualification', sortable: true, sortDirection: 'desc' },
                { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            recruitmentList: [],
            recruitmentFields: [
                { key: 'index',label: 'SN' },
                // { key: 'recruitmentId', label: 'Recruitment Id', sortable: true, sortDirection: 'desc' },
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                { key: 'qualification', label: 'Qualification', sortable: true, sortDirection: 'desc' },
                { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            totalCandidateRows: 1,
            totalRecruitmentRows: 1,
            totalShortListedRows: [],
            totalHiredRows: 1,
            currentPage: 1,
            currentPageCandidate: 1,
            currentPageRecruitment: 1,
            currentPageShortListed: [],
            currentPageHired: 1,
            perPage: 10,
            perPageCandidate: 5,
            perPageRecruitment: 10,
            perPageShortListed: 10,
            perPageHired: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filter: null,
            filterShortListed: [],
            filterHired: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        hiredCandidates() {
            return this.recruitmentList;
        },
        attachmentDdFiltered() {
            return this.attachmentDdList.filter(data => !data.documentTypeId);
        },
        isDocFile() {
            let fileExt = this.getFileExtension(this.resumeFileName);
            return fileExt == 'doc' || fileExt == 'docx' || fileExt == 'xlx' || fileExt == 'xlsx';
        }
    },
    mounted() {
        this.getJobList();
        this.getCandidates();
        this.lookupData();
    },
    validations: {
    },
    methods: {
        getJobList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/job/list', data)
                .then((response) => {
                    let result = response.data;
                    this.jobs = result.jobList;
                    this.totalRows = this.jobs.length;
                })
        },
        getCandidates: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/candidate/list', data)
                .then((response) => {
                    let result = response.data;
                    this.candidateList = result.candidateList;
                    this.totalCandidateRows = this.candidateList.length;
                })
        },
        getRecruitmentList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                jobId: this.selectedJobId,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/recruitment/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.recruitmentList = result.recruitmentList;
                    this.totalRecruitmentRows = this.recruitmentList.length;
                    // this.totalShortListedRows = this.filterCandidateByStatus.length;
                    this.totalHiredRows = this.hiredCandidates.length;
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/recruitment/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.recruitmentStatus = result.statusLookup;
                })
        },
        updateEntity: function(id, statusData, columnName) {
            let data = {
                id: id,
                entity: "recruitment",
                columnName: columnName,
                numberValue: statusData.id,
            }
            console.log(data);
            this.statusData.id = statusData.id;
            this.statusData.value = statusData.value;
            
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/numvalue/update', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.cancelIdEdit();

                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        cancelIdEdit: function() {
            this.recruitmentList = this.recruitmentList.map(data => {
                if(data.id == this.idEdit) {
                    data.statusId = this.statusData.id;
                    data.status = this.statusData.value;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';
            this.statusData.id = null;
            this.statusData.value = null;

        }, 
        viewJob: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/job/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.jobDetail = result.job;
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addCandidate: function(candidateId) {
            if (!this.selectedJobId) {
                iziToast.info({
                    message: 'Please select a Job.',
                    position: 'topCenter'
                });
                return;
            }
            this.recruitment.jobId = this.selectedJobId;
            this.recruitment.candidateId = candidateId;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/recruitment/add', this.recruitment)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.getRecruitmentList();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        deleteRecruitment: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showDetOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/recruitment/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showDetOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRecruitmentList();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    console.log(error);
                });
        },
        getAttachmentsDd: function( refRecordId) {
            let data = {
                refEntity: 'candidate',
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
            } 
            this.attachmentDdList = [];
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentDdList = result.attachmentList;
                })
        },
        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                refEntity: 'job',
                refRecordId: this.selectedJobId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result) {
                        this.technologySkills = result.cmrTechnologySkillList;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredCandidate(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalCandidateRows = filteredItems.length
            this.currentPageCandidate = 1
        },
        onFilteredRecruitment(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRecruitmentRows = filteredItems.length
            this.currentPageRecruitment = 1
        },
        onFilteredShortListed(filteredItems, index) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalShortListedRows[index] = filteredItems.length
            this.currentPageShortListed[index] = 1
        },
        onFilteredHired(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalHiredRows = filteredItems.length
            this.currentPageHired = 1
        },
        showCandidates: function(data) {
            this.selectedJobId = data.id;
            this.jobTitle = data.title;
            this.jobCode = data.jobCode;
            this.elements.isJobSelected = true;
            this.elements.showJobs = false;

            this.jobDetail.id = data.id;
            this.jobDetail.jobId = data.jobId;
            this.jobDetail.vhextAccountId = data.vhextAccountId;
            this.jobDetail.vacancies = data.vacancies;
            this.jobDetail.title = data.title;
            this.jobDetail.jobCode = data.jobCode;
            this.jobDetail.position = data.position;
            this.jobDetail.qualification = data.qualification;
            this.jobDetail.description = data.description;
            this.jobDetail.experienceMonths = data.experienceMonths;
            this.jobDetail.experienceYears = data.experienceYears;
            this.jobDetail.location = data.location;
            this.jobDetail.documentsRequired = JSON.parse(data.documentsRequired);
            this.jobDetail.dueDate = data.dueDate;
            this.jobDetail.statusId = data.statusId;
            this.jobDetail.status = data.status;
            this.jobDetail.active = data.active;
            this.jobDetail.createdById = data.createdById;
            this.jobDetail.createdBy = data.createdBy;
            this.jobDetail.createdDt = data.createdDt;
            this.jobDetail.lastUpdatedDt = data.lastUpdatedDt;
            // this.$refs.dayMonthYearControlDet.setValueInputData(data.experienceYears, data.experienceMonths, null);
            // this.getRoleResponsibilities();
            this.getTechnologySkills();
            this.getRecruitmentList(this.selectedJobId);
            this.$nextTick(() => {
                this.candidateTabIndex = 1;
            })
        },
        filterCandidateByStatus(statusId) {
            return this.recruitmentList.filter(data => data.statusId == statusId);
        },
        attachmentClick(data, candidateName) {
            this.elements.showResume = true;
            this.resumeFilePath = data.attachmentPath;
            this.resumeFileName = data.fileName;
            this.candidateName = candidateName;
        },
        showCandidateDetails: function(candidateId) {
            this.referencePopupId = candidateId;
            this.attachmentPopupData.refEntity = 'candidate';
            this.attachmentPopupData.refRecordId = candidateId;
            this.showCanDetails = true;
            this.$nextTick(()=> {
                this.$refs.candidateViewRef.getDetails(this.referencePopupId, 'candidate');
            })
        },
        candidateExists: function(candidateId) {
            let result = false;
            this.recruitmentList.map(data => {
                if (candidateId && data.candidateId) {
                    if (data.candidateId == candidateId) {
                        result = true;
                    }
                }
            });
            return result;
        },
    }
}
</script>