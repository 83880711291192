<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Project</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="userTypeCode !== 'CLI'" class="breadcrumb-item">
                                        <router-link title="Edit Project" :to="{ name: 'editproject', params: { projectid: projectId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                     <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="title">Title</label>
                                            <input type="text" class="form-control" id="title" disabled v-model="project.title">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-2">
                                        <div class="form-group">
                                            <label for="title">Short Name</label>
                                            <input type="text" class="form-control" id="shortName" disabled v-model="project.displayName">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="form-group">
                                            <label for="projectType">Project Type</label>
                                            <input type="text" class="form-control" id="projectType" disabled v-model="project.type">
                                        </div>
                                    </div>

                                    <div class="col-12  col-sm-6">
                                        <div class="form-group">
                                            <label for="projectManager">Project Manager</label>
                                            <input type="text" class="form-control" id="projectManager" disabled v-model="project.userName">
                                        </div>
                                    </div>
<!--                                     <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="projectType">Project Category</label>
                                            <input type="text" class="form-control" id="projectCategory" disabled v-model="project.category">
                                        </div>
                                    </div> -->
                                    <div class="col-12 col-sm-3">
                                        <div class="form-group">
                                            <label for="startDate">Start Date</label>
                                            <div class="disabled-date" disabled>
                                                <input type="text" class="form-control disabled" :value="project.startDate | formatDate" readonly>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <div class="form-group">
                                            <label for="endDate">End Date</label>
                                            <div class="disabled-date">
                                                <input type="text" class="form-control disabled" :value="project.endDate | formatDate" readonly>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-3 col-sm-3">
                                        <div class="form-group">
                                            <label for="duration">Duration</label>
                                            <div class="row">
                                                <div class="col-12">
                                                    <input type="text" class="form-control" id="durationYears" disabled v-model="project.durationYears + ' ' + project.durationMonths + ' ' + project.durationDays">
                                                </div>
<!--                                                 <div v-if="project.durationMonths != ''" class="col-4 pl-0 pr-0">
                                                    <input type="text" class="form-control" id="durationMonths" disabled v-model="project.durationMonths">
                                                </div>
                                                <div v-if="project.durationDays != ''" class="col-4">
                                                    <input type="text" class="form-control" id="durationDays" disabled v-model="project.durationDays">
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-sm-3">
                                        <div class="form-group">
                                            <label>Budget</label>
                                            <b-input-group>
                                                <VhextMoneyControl  :value="Number(this.project.initialBudget)" :key="vmcComponentKey" disabled @money-input="moneyControlInput" />
                                                <!-- <b-input-group-prepend class="custom-input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i v-bind:class="[ (prependText === 'INR') ? 'fa fa-inr' : 'fa fa-usd']"></i>
                                                    </span>
                                                </b-input-group-prepend>
                                                <b-form-input type="text" disabled v-model="project.initialBudget">
                                                </b-form-input>
                                                <b-input-group-append class="custom-input-group-append">
                                                    <span class="input-group-text">
                                                        .00
                                                    </span>
                                                </b-input-group-append> -->
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="location">Location</label>
                                            <input type="text" class="form-control" id="location" disabled v-model="project.location">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <label>Description</label>                                        
                                        <div class="col-12 col-md-12 m-0 px-0">
                                            <label class="p-2 mb-0 d-block header-details" v-html="project.description"></label>
                                        </div>

<!--                                         <label>Description</label>
                                        <textarea class="form-control" v-model="project.description" disabled rows="5">
                                        </textarea> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-2">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="status">Project Id</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{project.projectId}}</span></p>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="form-group">
                                        <label for="status">Project Status</label>
                                        <p><span class="badge badge-pill badge-blue mb-1">{{project.status}}</span></p>
                                    </div>
                                </div>
                                <div class="col-3">
                                    <div class="form-group">
                                        <label for="inScope">Archive</label>
                                        <div class="input-group ">
                                            <b-form-checkbox class="custom-toggle" disabled v-model="project.isArchive" name="check-button" switch>
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="projectAccessType">Project Model</label>
                                        <input type="email" class="form-control" id="projectAccessTypeId" disabled v-model="project.projectAccessType">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="country">Country</label>
                                        <input type="email" class="form-control" id="country" disabled v-model="project.country">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="currency">Currency</label>
                                        <input type="text" class="form-control" id="currency" disabled v-model="project.currency">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="timeZone">Timezone</label>
                                        <input type="email" class="form-control" id="timeZone" disabled v-model="project.timeZone">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="effortConversionHourDay">Effort(1 Man Day)</label>
                                        <div class="input-group">
                                            <input type="text" disabled v-model="project.effortConversionHourDay" class="form-control" min="0" :key="effortConversionHourDayKey" id="effortConversionHourDay">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    <span class="mx-auto">Man Hours</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="attachments.length != 0" class="col-12">
                                    <div class="form-group">
                                        <div>
                                            <label for="attachment">Attachment </label>
                                            <p v-for="(attachment, index) in attachments">
                                                <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="createdDt">Created Date</label>
                                        <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(project.createdDt)">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="lastUpdatedDt">Last Updated Date</label>
                                        <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(project.lastUpdatedDt)">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mt-3 mb-0">
                                        <a href="javascript:" @click="$router.push('/settings')"><i class="fa fa-cog action-link pr-2" aria-hidden="true"></i>Additional Settings</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <h6 class="mb-0">Clients</h6>
                            <!-- <div class="separator mb-2"></div> -->
                            <div v-for="client in clients" class="card client-card mt-1">
                                <div class="card-body p-2">
                                    <div class="row" >
                                        <div class="col-12">
                                            <div class="form-group mb-0">
                                                <span class="float-right">{{ client.clientType }}</span>
                                                <h6 class="m-0 py-0 ml-2">
                                                    <i v-if="client.clientType=='Company'" class="fa fa-users text-primary pr-1"></i>
                                                    <i v-else class="fa fa-user text-primary pr-1"></i>
                                                    {{ client.clientName }}
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
export default {
    name: 'app',
    components: {
        VhextMoneyControl
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            entity: 'project',
            project: [],
            attachments: [],
            clients: '',
            vmcComponentKey: 0,
            effortConversionHourDayKey: 100,

        }
    },
    mounted() {
        this.viewProject();
        this.getClients();
        this.getAttachment();
    },
    methods: {
        viewProject: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/project/view/' + this.projectId)
                .then((response) => {
                    let result = response.data;

                    console.log(result);                    
                    if (result) {
                        this.project = result.projects;
                        this.project.durationDays = (this.project.durationDays == undefined) ? '' : this.project.durationDays + " Days";
                        this.project.durationMonths = (this.project.durationMonths == undefined) ? '' : this.project.durationMonths + " Months";
                        this.project.durationYears = (this.project.durationYears == undefined) ? '' : this.project.durationYears + " Years";
                        this.project.isArchive = (this.project.isArchive == undefined) ? false : this.project.isArchive;

                        this.vmcComponentKey += 1;

                        this.getVmxServiceSettings();
                    }
                })
        },
        getVmxServiceSettings: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                vmxServiceRecordId: this.projectId,
                vmxServiceCode: this.vmxServiceCode,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/vmxservicesettingdata', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    let vmxServSettingData = result.vmxServiceSettingData;
                    // this.setCompanySettingData();
                    this.project.timeZone = vmxServSettingData.timeZone ?? '' ;
                    this.project.effortConversionHourDay = vmxServSettingData.effortConversionHourDay ?? '' ;

                    this.project.countryId = vmxServSettingData.countryId ?? '' ;
                    this.project.country = vmxServSettingData.country ?? '' ;
                    this.project.currencyId = vmxServSettingData.currencyId ?? '' ;
                    this.project.currency = vmxServSettingData.currencySymbol + ' ' + vmxServSettingData.currency ?? '' ;
                    localStorage.setItem("currency",vmxServSettingData.currencySymbol);
                    this.effortConversionHourDayKey++;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
                
        },
        getClients: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/projectclient/list', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.projectClientList;
                }).catch(error => {
                    console.log(error);
                });
        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachments = result.attachmentList;
                })
        },
        moneyControlInput: function(value) {
            this.project.initialBudget = value;
        },

    }
}
</script>