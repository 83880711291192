<template>
    <div>
        <ProjectStandardMaster :ermMasterInfo="ermMasterInfo"></ProjectStandardMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectStandardMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ProjectStandardMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Project Standard Master",
                entity: 'project_standard_master',
                refEntity: 'project_standard',
                ermListRedirectURL: '/prsmas/list',
                erdRedirectURL: '/quality/projectstandard/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>