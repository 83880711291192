<template>
    <div class="activity">
        <b-modal ref="existingActivityLookup" size="lg" id="existingActivityLookup" ok-only body-class="pt-0 pb-2" footer-class="py-3" header-class="py-3" @hide="closeDialog" title="Add Activities">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row mb-2 px-2">
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 pb-1 mt-3">
                            <b-tabs v-model="tabIndex" content-class="mt-3" lazy>
                                <b-tab title="Existing Activities">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="existingActivities" :fields="activityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByExt" :sort-desc.sync="sortDescExt" :sort-direction="sortDirection">
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!activityExists(data.item.activity)">
                                                <a href="javascript:" title="Add Activity" class="text-success" @click="addActivity(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Activities found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-if="isStandard == 'true'" title="Activities Summary">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="activitiesSummary" :fields="activitySummaryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!activityExists(data.item.activity)">
                                                <a href="javascript:" title="Add Activity" class="text-success" @click="addActivity(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Activities found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                                <b-tab v-else title="Standard Activities">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="standardActivities" :fields="activityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!activityExists(data.item.activity)">
                                                <a href="javascript:" title="Add Activity" class="text-success" @click="addActivity(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Activities found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 mt-3">
                            <label class="mt-3">Activities</label>
                            <div class="separator mb-3"></div>
                            <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" sort-icon-left :items="activities" :fields="activityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Remove" @click="deleteItem(data.item.id)" v-b-modal.deleteItem class="text-danger"><i class="fa fa-minus-circle pr-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Activities found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <button type="submit" @click="addBulkActivity" :disabled="selectedActivities.length==0" class="btn btn-primary float-left my-0 mr-2 py-1 px-3">Add Selected</button>
                    <button v-if="selectedActivities.length>0" type="submit" @click="selectedActivities=[];" class="btn btn-outline-primary float-left my-0 mr-2 py-1 px-3">Clear Selection</button>
                    <button type="submit" @click="cancel()" class="btn btn-secondary float-right my-0 mr-2 py-1 px-3">Close</button>
                </div>
            </template>
        </b-modal>
        <DeleteComponent id="deleteItem" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Activity ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            showOverlay: false,
            checkboxValue: true,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            isStandard: localStorage.getItem("isStandard"),
            activity: {
                activityId: '',
                activity: '',
                vhextAccountId: '',
                projectId: '',
                entityId: '',
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            existingActivities: [],
            standardActivities: [],
            activities: [],
            activityFields: [
                { key: 'selected', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            activitiesSummary: [],
            activitySummaryFields: [
                { key: 'selected', label: '' },
                {key:'index',label: 'SN'},
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'count', label: 'Count', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            selectMode: 'single',
            sortDescExt: false,
            sortDescStd: false,
            sortDesc: false,
            sortBy: '',
            sortByExt: '',
            sortByStd: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            selectedActivities: [],

            deleteParam: {
                id: 0
            }
        }
    },
    watch: {
        tabIndex() {
            this.selectedActivities = [];
        }
    },
    mounted() {
        this.getActivityList();
        this.getExistingActivities();
        if (this.isStandard == 'true') {            
            this.getActivitiesSummary();
        } 
        else {
            this.getStandardActivities();
        }
    },
    methods: {
        getActivityList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/activity/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    this.selectedActivities = [];
                    let result = response.data;
                    this.activities = result.activityList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getExistingActivities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/existingactivity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.existingActivities = result.existingActivityList;
                })
        },
        getStandardActivities: function() {
            let data = {
                projectTypeId: this.projectTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/standardactivity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.standardActivities = result.standardActivityList;
                })
        },
        getActivitiesSummary: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/general/activitysummary/list', data)
                .then((response) => {
                    let result = response.data;
                    this.activitiesSummary = result.activitySummaryList;
                })
        },
        addActivity: function(data) {
            this.activity.vhextAccountId = this.vhextAccountId;
            this.activity.projectId = this.projectId;
            this.activity.entity = data.entity;
            this.activity.active = true;
            this.activity.activity = data.activity;

            if (!this.activityExists(this.activity.activity)) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/general/activity/add', this.activity)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-lookup');
                            this.getActivityList();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                iziToast.info({
                    message: 'Activity already exist.',
                    position: 'topCenter'
                });
            }
        },
        activityExists: function(activity) {
            let result = false;
            this.activities.map(data => {
                if (activity && data.activity) {
                    if (data.activity.toLowerCase() == activity.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        dataExists: function(activity) {
            let result = false;
            this.selectedActivities.map(data => {
                if (data.toLowerCase() == activity.toLowerCase()) {
                    result = true;
                }
            });
            return result;
        },
        onRowSelected(items) {
            if (items.length > 0){
                let data = items[0];
                if (!this.activityExists(data.activity) && !this.dataExists(data.activity)) {
                    this.selectedActivities.push(data.activity);
                }
                else if (this.dataExists(data.activity)) {
                    this.selectedActivities = this.selectedActivities.filter(e=> e.toLowerCase() !== data.activity.toLowerCase());
                }
            }
          },
        closeDialog() {
            this.selectedActivities = [];
            this.$emit('closeDialog');
        },
        activityData: function(data) {
            this.bulkActivityData = [];
            for (var rowCount = 0; rowCount < this.selectedActivities.length; rowCount++) {
                this.bulkActivityData.push({
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: data.entity,
                    active: true,
                    activity: this.selectedActivities[rowCount],
                    createdById: localStorage.getItem("userId")
                });
            }
        },
        saveBulkActivity: function(data) {
            this.showOverlay = true;    
            axios.post(this.$store.getters.getAPIBasePath + '/general/activity/bulk/add', this.bulkActivityData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getActivityList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        async addBulkActivity(data) {
            await this.activityData(data);
            await this.saveBulkActivity();
        },
        deleteItem: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/activity/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getActivityList();
                        this.$emit('refresh-lookup');
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        matchTextValExists: function(textVal) {
            let result = false;
            this.customTypes.map(data => {
                if (textVal && data.type) {
                    if (data.type.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        
    }
}
</script>