<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Company</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
<!--                                     <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-pencil pr-2"></i>Edit</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-eye pr-2"></i>View</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Detete</a>
                                    </li> -->
                                     <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                    
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">                    
                    <div class="col-lg-8 col-xl-8">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="goalId">Company Id</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{company.companyId}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="companyName">Company Name</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.company.companyName.$error }">
                                                                    
                                            <input type="text" maxlength="100" class="form-control" v-model="company.companyName" id="companyName" :class="{ 'is-invalid': $v.company.companyName.$error }" >
                                            <div class="required-icon"></div>
                                            </div>
                                           <label v-if="$v.company.companyName.$error && !$v.company.companyName.minLength" class="text-danger">Company name must have at least {{$v.company.companyName.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.company.companyName.$error && !$v.company.companyName.required" class="text-danger">Please enter Company name</label>
                                            <label v-if="$v.company.companyName.$error && !$v.company.companyName.maxLength" class="text-danger">Company name must not exceed {{$v.company.companyName.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                     <div class="col-6">
                                        <div class="form-group">
                                            <label for="industryId">Industry</label>
                                            <select v-model="company.industryId" class="form-control" id="industryId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in industries" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="shortName">Short Name</label>
                                            <input type="text" maxlength="20" class="form-control" v-model="company.shortName" id="shortName" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="ownerName">Director</label>
                                            <input type="text" maxlength="100" class="form-control" v-model="company.ownerName" id="ownerName" >
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="contactPerson">Contact Person</label>
                                            <input type="text" maxlength="100" class="form-control" v-model="company.contactName" id="contactPerson" >
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="email">Email</label>
                                            <input type="text" maxlength="100" class="form-control" v-model="company.emailId" id="email" >
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="mobile">Mobile</label>
                                            <input type="text" maxlength="20" class="form-control" v-model="company.mobile" id="mobile">
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="phone">Phone</label>
                                            <input type="text" maxlength="20" class="form-control" v-model="company.phone" id="phone">
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" @click="saveCompany" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="getPrevUrl()" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xl-4 pl-0">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Contact Info">
                                        <div class="row m-0">
                                            <div class="col-12 mt-3">
                                                <div class="form-group">
                                                    <b-input-group prepend="Office Phone">
                                                        <b-form-input type="text" maxlength="20" v-model="company.officePhone"  class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="Email Id">
                                                        <b-form-input type="text" maxlength="100" v-model="company.alternateEmailId"  class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="Website">
                                                        <b-form-input type="text" maxlength="500" v-model="company.website" class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Address">
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="Address" class="mt-3">
                                                        <b-form-input type="text" maxlength="45" v-model="company.address1" class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="Street Name">
                                                        <b-form-input type="text" maxlength="45" v-model="company.streetName" class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="City">
                                                        <b-form-input type="text" maxlength="45" v-model="company.city" class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="State">
                                                        <b-form-input type="text" maxlength="45" v-model="company.state"  class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="Country">
                                                        <select v-model="company.countryId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <b-input-group prepend="Pincode">
                                                        <b-form-input type="text" maxlength="20" v-model="company.pincode"  class="form-control"></b-form-input>
                                                    </b-input-group>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay> 
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Select2
    },
    computed: {
        companyId() {
            return Number(this.$route.params.companyId);
        }
    },
    data() {
        return {
            showOverlay: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            industries: [],
            companyType: [],
            users: [],
            countries: [],
            company: {
                id: '',
                companyId: '',
                companyName: '',
                industryId: '',
                shortName: '',
                ownerName: '',
                contactId: '',
                contactName: '',
                phone: '',
                officePhone: '',
                mobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                website: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: 'company',
                hasContactInfo: '',
                active: '',
                industry: ''
            }
        }
    },
    mounted() {
        this.lookupData();
        this.viewCompany(this.companyId);
    },
    validations: {
        company: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                projectId: this.company.projectId,
                vhextAccountId: this.company.vhextAccountId,
                entity: this.company.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                   console.log(result)
                    this.industries= result.industryLookup;                   
                    this.companyType = result.customTypeLookup;
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                })

        },
        viewCompany: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/company/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.company = result.company;
                        console.log(this.company)
                        this.company.id = (this.company.id == undefined) ? '' : this.company.id;
                        this.company.companyId = (this.company.companyId == undefined) ? '' : this.company.companyId;
                        this.company.entityId = (this.company.entityId == undefined) ? '' : this.company.entityId;
                        this.company.entityReferenceId = (this.company.entityReferenceId == undefined) ? '' : this.company.entityReferenceId;
                        this.company.companyName = (this.company.companyName == undefined) ? '' : this.company.companyName;
                        this.company.industryId = (this.company.industryId == undefined) ? '' : this.company.industryId;
                        this.company.shortName = (this.company.shortName == undefined) ? '' : this.company.shortName;
                        this.company.ownerName = (this.company.ownerName == undefined) ? '' : this.company.ownerName;
                        this.company.contactId = (this.company.contactId == undefined) ? '' : this.company.contactId;
                        this.company.contactName = (this.company.contactName == undefined) ? '' : this.company.contactName;
                        this.company.phone = (this.company.phone == undefined) ? '' : this.company.phone;
                        this.company.officePhone = (this.company.officePhone == undefined) ? '' : this.company.officePhone;
                        this.company.mobile = (this.company.mobile == undefined) ? '' : this.company.mobile;
                        this.company.emailId = (this.company.emailId == undefined) ? '' : this.company.emailId;
                        this.company.alternateEmailId = (this.company.alternateEmailId == undefined) ? '' : this.company.alternateEmailId;
                        this.company.address1 = (this.company.address1 == undefined) ? '' : this.company.address1;
                        this.company.streetName = (this.company.streetName == undefined) ? '' : this.company.streetName;
                        this.company.city = (this.company.city == undefined) ? '' : this.company.city;
                        this.company.state = (this.company.state == undefined) ? '' : this.company.state;
                        this.company.country = (this.company.country == undefined) ? '' : this.company.country;
                        this.company.countryId = (this.company.countryId == undefined) ? '' : this.company.countryId;
                        this.company.pincode = (this.company.pincode == undefined) ? '' : this.company.pincode;
                        this.company.website = (this.company.website == undefined) ? '' : this.company.website;
                        this.company.createdById = (this.company.createdById == undefined) ? '' : this.company.createdById;
                        this.company.projectId = (this.company.projectId == undefined) ? '' : this.company.projectId;
                        this.company.vhextAccountId = (this.company.vhextAccountId == undefined) ? '' : this.company.vhextAccountId;
                        this.company.hasContactInfo = (this.company.hasContactInfo == undefined) ? '' : this.company.hasContactInfo;
                        this.company.active = (this.company.active == undefined) ? '' : this.company.active;
                        this.company.industry = (this.company.industry == undefined) ? '' : this.company.industry;
                    }
                })

        },
        saveCompany: function() {
            console.log(this.company);
            this.$v.company.$touch();
            if (!this.$v.company.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/company/update', this.company)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }                

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }

        }
    }
}
</script>