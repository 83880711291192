<template>
    <div>
        <ProcessPlanMaster :ermMasterInfo="ermMasterInfo"></ProcessPlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProcessPlanMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ProcessPlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Process Plan Master Doc",
                entity: 'process_model_master',
                refEntity: 'process_plan',
                ermListRedirectURL: '/pmdmas/list',
                erdRedirectURL: '/processplan/' 
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>