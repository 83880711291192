<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Client</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                     <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                    
<!--                                     <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-pencil pr-2"></i>Edit</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-eye pr-2"></i>View</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li> -->
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="clientName">Client Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientName.$error }">
                                            <input type="text" v-model="client.clientName" :class="{ 'is-invalid': $v.client.clientName.$error }" class="form-control" id="clientName" placeholder="Enter Client Name">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.minLength" class="text-danger">Client Name must have at least {{$v.client.clientName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.required" class="text-danger">Please enter Client Name</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.maxLength" class="text-danger">Client Name must not exceed {{$v.client.clientName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="emailId">Email ID</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.emailId.$error, 'is-invalid': error }">
                                            <input type="email" v-model="client.emailId" placeholder="Enter Email Id" class="form-control" :class="{ 'is-invalid': $v.client.emailId.$error, 'is-invalid': error }" id="emailId">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.email" class="text-danger">Enter Valid EmailId</label>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.required" class="text-danger">Please enter EmailId</label>
                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="clientTypeId">Client Type</label>
                                        <select v-model="client.clientType" @change="changeClientType(client.clientType)" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in clientTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                        </select>
<!--                                         <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div>
                                        <b-input-group :prepend="prependText" class="text mb-2">
                                            <b-form-input aria-label="First name" v-model="client.firstName" placeholder="First Name"></b-form-input>
                                            <b-form-input aria-label="Middle name" v-model="client.middleName" placeholder="Middle Name"></b-form-input>
                                            <b-form-input aria-label="Last name" v-model="client.lastName" placeholder="Last Name"></b-form-input>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-if="client.clientTypeName != 'company'" class="col-4">
                                    <div class="form-group">
                                        <label for="profession">Profession</label>
                                        <input type="text" v-model="client.profession" class="form-control" id="profession">
                                    </div>
                                </div>
                                <div v-if="client.clientTypeName != 'company'" class="col-4">
                                    <div class="form-group">
                                        <label for="employer">Employer</label>
                                        <input type="text" v-model="client.employer" class="form-control" id="employer">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="position">Job Title</label>
                                        <input type="text" v-model="client.position" class="form-control" id="position">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input type="text" v-model="client.phone" class="form-control">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="mobile">Mobile</label>
                                        <input type="text" v-model="client.mobile" class="form-control">
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group">
                                        <label for="location">Location</label>
                                        <input type="text" v-model="client.location" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <button type="submit" @click="saveClient()" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
<!--                             <button type="submit" @click="saveClient('invite')" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save & Invite</button> -->
                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-2" lazy>
                                <b-tab title="Address">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <b-input-group prepend="Address 1" class="mt-4">
                                                    <b-form-input type="text" v-model="client.address1" class="form-control" id="address1"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <b-input-group prepend="Address 2">
                                                    <b-form-input type="text" v-model="client.address2" class="form-control" id="address2"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <b-input-group prepend="City">
                                                    <b-form-input type="text" v-model="client.city" class="form-control" id="city"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <b-input-group prepend="State">
                                                    <b-form-input type="text" v-model="client.state" class="form-control" id="state"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <b-input-group prepend="Country">
                                                    <select v-model="client.countryId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <b-input-group prepend="Pincode">
                                                    <b-form-input type="text" v-model="client.pincode" class="form-control" id="pincode"></b-form-input>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="client.entity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';

import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'

export default {
    name: 'app',
    components: {
        // TypeLookup,
        Select2
    },
    data() {
        return {
            elements: {
                alreadyExists: false
            },

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            error: false,
            clientTypes: [],
            users: [],
            countries: [],
            // typeLookupTitle: 'Client Type Settings',
            prependText: 'Full Name',
            client: {
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                clientType: '',
                clientTypeId: '',
                clientTypeName: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            }
        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        client: {
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clientTypes = result.typeLookup;
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                    console.log(result.customTypeLookup);
                    console.log(localStorage.getItem("projectId"));
                    console.log(localStorage.getItem("vhextAccountId"));
                })

        },
        saveClient: function(flag) {
            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }

            if (flag == 'invite') {
                this.client.invite = true;
            } else {
                this.client.invite = false;
            }
            console.log(this.client);

            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                this.error = false;
                if (this.client.clientTypeName == 'company') {
                    this.client.contactPerson = this.client.firstName + " " + this.client.middleName + " " + this.client.lastName
                }else{
                    this.client.contactPerson = '';
                }
                axios.post(this.$store.getters.getAPIBasePath + '/client/add', this.client)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: 'Client info loaded successfully',
                                position: 'topRight'
                            });
                            this.$router.push('/client/view/' + result.id);
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            this.error = true;

                        }

                    }).catch(error => {
                        console.log(error);
                    });
            } else {
                this.error = true;
            }

        },
        clearData: function() {
            this.client.title = '';
            this.client.clientType = '';
            this.client.clientTypeId = '';
            this.client.clientTypeName = '';
            this.client.clientName = '';
            this.client.contactPerson = '';
            this.client.firstName = '';
            this.client.middleName = '';
            this.client.lastName = '';
            this.client.emailId = '';
            this.client.phone = '';
            this.client.mobile = '';
            this.client.address1 = '';
            this.client.address2 = '';
            this.client.city = '';
            this.client.state = '';
            this.client.countryId = '';
            this.client.pincode = '';
            this.client.hasContactInfo = '';
            this.client.active = '';
            this.client.profession = '';
            this.client.employer = '';
            this.client.position = '';
            this.elements.alreadyExists = false;
            this.error = false;
            this.$v.client.$reset();
        },
        changeClientType: function(data) {
            if (data.text != null && data.text.length > 0) {
                this.client.clientTypeName = data.text.toLowerCase();
            }
            if (this.client.clientTypeName == 'company') {
                this.prependText = 'Contact Person';
            } else {
                this.prependText = 'Full Name';
            }
        }

    }
}
</script>