<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Test Plan</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                            <span>New</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div class="row mb-2">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="title">Title</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.testPlan.title.$error }">
                                                            <input type="text" class="form-control" v-model="testPlan.title" id="title" :class="{ 'is-invalid': $v.testPlan.title.$error }" placeholder="Enter Title">
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.testPlan.title.$error && !$v.testPlan.title.required" class="text-danger">Please enter Title</label>
                                                        <label v-if="$v.testPlan.title.$error && !$v.testPlan.title.maxLength" class="text-danger">Title must not exceed {{$v.testPlan.title.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="testPlanTypeId">Test Type</label>
                                                        <select v-model="testPlan.testPlanTypeId" class="form-control" id="testPlanTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in testPlanTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="preparedDt">Due Date</label>
                                                        <input type="date" class="form-control" v-model="testPlan.preparedDt" id="preparedDt">
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="preparedById">Responsibility</label>
                                                        <select v-model="testPlan.preparedById" class="form-control" id="preparedById">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>

<!--                                                 <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="statusId">Status</label>
                                                        <select v-model="testPlan.statusId" class="form-control" id="statusId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in testPlanStatus" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div> -->
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-3">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-1 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-master>
                                                                    <label for="description">Description</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-master" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <textarea v-model="testPlan.description" class="form-control" id="description" rows="4" maxlength="2000" placeholder="Enter Description"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="saveTestPlan" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="testPlan.perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="testPlan.filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="testPlans" @row-selected="onRowSelected" :fields="testPlanFields" responsive="sm" :current-page="testPlan.currentPage" :per-page="testPlan.perPage" :filter="testPlan.filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredTestPlan">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View Details" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteTestPlan(data.item.id)" data-toggle="modal" data-target="#deleteTestPlan"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Test Plans found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="testPlans.length!=0" pills v-model="testPlan.currentPage" :total-rows="testPlan.totalRows" :per-page="testPlan.perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details" :active="elements.showDetail">
                                    <div class="row input-group-label">
                                        <div class="col-4">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{testPlan.testPlanId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-6 pl-0">
                                            <b-input-group prepend="Status" class="mb-3">
                                                <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="testPlan.status"></b-form-input>
                                                <b-dropdown v-if="elements.editMode" :text="statusText" class="btn-dd" v-model="testPlan.statusId">
                                                    <b-dropdown-item v-for="(info, index)  in testPlanStatus" :key="info.id" :value="info.id" @click="testPlan.statusId = info.id;statusText = info.value">
                                                        {{info.value}}
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateTestPlan" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="title2">Title</label>
                                                <input type="text" class="form-control" :disabled="!elements.editMode" v-model="testPlan.title" id="title2" placeholder="Enter Title">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="testPlanTypeId2">Test Type</label>
                                                <select v-if="elements.editMode" v-model="testPlan.testPlanTypeId" class="form-control" id="testPlanTypeId2">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in testPlanTypes" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="!elements.editMode" disabled v-model="testPlan.type" type="text" class="form-control">
                                            </div>
                                        </div>

                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="preparedById2">Responsibility</label>
                                                <select v-if="elements.editMode" v-model="testPlan.preparedById" class="form-control" id="preparedById2">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="!elements.editMode" disabled v-model="testPlan.preparedBy" type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6">
                                            <div class="form-group">
                                                <label for="preparedDt2">Due Date</label>
                                                <input type="date" v-if="elements.editMode" class="form-control" v-model="testPlan.preparedDt" id="preparedDt">
                                                <input type="text" v-if="!elements.editMode" class="form-control" id="preparedDt2" :disabled="!elements.editMode" :value="testPlan.preparedDt | formatDate">

                                            </div>
                                        </div>
                                        <div class="col-12  col-sm-12">
                                            <div class="form-group">
                                                <label for="description2">Description</label>
                                                <textarea rows="4" class="form-control" id="description2" :disabled="!elements.editMode" v-model="testPlan.description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="startDate">Created Date</label>
                                                <div class="input-group" disabled>
                                                    <input type="text" class="form-control disabled" :value="testPlan.createdDt | formatDate" readonly>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="form-group">
                                                <label for="endDate">Updated Date</label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control disabled" :value="testPlan.lastUpdatedDt | formatDate" readonly>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference" @click="elements.showDetail=false">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-ref>
                                                <span>Reference Search</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-ref" role="tabpanel">
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="input-group dataTables_filter">
                                                        <input type="search" v-model="ReferenceFilter" class="form-control" placeholder="Search Reference">
                                                    </div>
                                                </div>
                                                <div class="float-left ml-5 pl-4">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="mb-0">
                                                            <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="projectERMList" :fields="projectERMListFields" responsive="sm" :current-page="currentPageRef" :per-page="perPageRef" :filter="ReferenceFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                    <template v-slot:cell(actions)="data">
                                                        <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                                <b-pagination v-if="projectERMList.length!=0" pills v-model="currentPageRef" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                            </div>
                                        </b-collapse>
                                    </b-card>
                                    <div class="mt-2">
                                        <label class="mb-1">References</label>
                                        <b-table show-empty small stacked="md" sort-icon-left :items="ermReferenceList" :fields="ermReferenceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" data-toggle="modal" data-target="#removeReference"><i class="fa fa-minus-circle pr-2"></i></a>
                                            </template>
                                            <template v-slot:empty="">
                                                <div class="text-center">No References Found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="">
                                                <div class="text-center">{{ }}</div>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-tab>
                                <b-tab title="Master" @click="elements.showDetail=false">
                                    <div class="row input-group-label">
                                        <div class="col-4">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{entityRecordMaster.entityRecordMasterId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-6 pl-0">
                                            <b-input-group prepend="Status" class="mb-3">
                                                <b-dropdown :disabled="elements.viewMasterMode" :text="masterStatusText" class="btn-dd" v-model="entityRecordMaster.statusId">
                                                    <b-dropdown-item v-for="(info, index)  in masterStatus" :key="info.id" :value="info.id" @click="entityRecordMaster.statusId = info.id;masterStatusText = info.value">
                                                        {{info.value}}
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0">
                                            <div class="more-btns text-center">
                                                <button v-if="elements.viewMasterMode" href="javascript:" @click="elements.viewMasterMode = false" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="!elements.viewMasterMode">
                                                    <button type="submit" @click="updateRecordMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                                    <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs action-link p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 col-sm-12">
                                            <label for="title1">Title</label>
                                            <input type="text" id="title1" class="form-control" placeholder="Enter Title" :disabled="elements.viewMasterMode" v-model="entityRecordMaster.title">
                                        </div>
                                        <div class="col-12">
                                            <div class="card-body p-0 my-3">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-master>
                                                            <span>Description</span>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="accordion-master" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <textarea :disabled="elements.viewMasterMode" v-model="entityRecordMaster.description" class="form-control" id="descriptionDetail" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="createdById">Prepared By</label>
                                                <select :disabled="elements.viewMasterMode" v-model="entityRecordMaster.createdById" id="createdById" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in usersMaster" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <b-table show-empty small stacked="md" sort-icon-left :items="entityReviewApprovalList" :fields="reviewFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:empty="">
                                                    <div class="text-center">No Reviews found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="">
                                                    <div class="text-center">{{ }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="scopeTypeId">Approved By</label>
                                                <select v-if="!elements.viewMasterMode" v-model="entityRecordMaster.createdById" id="scopeTypeId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in usersMaster" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="elements.viewMasterMode" disabled v-model="entityRecordMaster.createdBy" type="text" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="startDate">Approved Date</label>
                                                <input :disabled="elements.viewMasterMode" v-model="entityRecordMaster.startDate" type="date" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Created Date</label>
                                                <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.createdDt)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Updated Date</label>
                                                <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.lastUpdatedDt)">
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <Dialog id="deleteTestPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Test Plan?" />
        <Dialog id="removeReference" :onYes="onYesRef" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Reference?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'

export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        TypeLookup,
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
            },
            typeLookupTitle: 'Test Plan Type Settings',
            entity: 'test_plan',
            typeEntity: 'test_plan_type',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            entityRecordMasterId: this.$route.params.ermId,
            userId: localStorage.getItem("userId"),

            testPlan: {
                id:'',
                testPlanId: '',
                entity: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                testPlanTypeId: '',
                type: '',
                title: '',
                description: '',
                preparedById: '',
                preparedBy: '',
                preparedDt: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasReview: '',
                createdDt: '',
                lastUpdatedDt: '',

                totalRows: 1,
                currentPage: 1,
                perPage: 5,
            },
            addReference: {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityId: '',
                entityRecordId: '',
                ermId: '',
                erdId: '',
                refEntityId: '',
                refErmId: '',
                refErdId: '',
                title: '',
                refEntity: ''
            },
            masterEntity: "test_plan_master",
            masterStatusText: '',
            entityRecordMaster: [],
            projectERMList: [],
            ermReferenceList: [],
            entityReviewApprovalList: [],
            masterStatus: [],
            usersMaster: [],

            statusText: '',
            testPlanTypes: [],
            testPlanStatus: [],
            users: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,

            testPlans: [],

            testPlanFields: [
                { key: 'testPlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'preparedDt', label: 'Due Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Responsibility', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            projectERMListFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            reviewFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' }
            ],
            selectMode: 'single',
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.loadOnce = true;
        this.getTestPlans(this.$route.params.ermId);
        this.lookupData();
        this.entityRecordMasterCollection(this.$route.params.ermId);
        this.masterLookupData();
        this.getProjectEntityRecordMasterList();
    },
    validations: {
        testPlan: {
            title: {
                required,
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getTestPlans: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/test/testplan/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result != null && result.length != 0) {
                        this.testPlans = result.testPlanList;

                        console.log(this.testPlans);

                        if (this.loadOnce && this.testPlans != null && this.testPlans.length > 0) {
                            this.elements.showDetail = true;
                            console.log(this.testPlans[0].id);
                            this.viewTestPlan(this.testPlans[0].id);
                        }
                        this.elements.editMode = false;
                        this.testPlan.totalRows = this.testPlans.length;
                        this.statusText = this.testPlans.status;
                        this.loadOnce = false;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/test/testplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.testPlanTypes = result.customTypeLookup;
                    this.testPlanStatus = result.statusLookup;
                    this.users = result.userLookup;
                })
        },
        entityRecordMasterCollection: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster = result.entityRecordMaster;
                    this.entityReviewApprovalList = result.entityReviewApprovalList;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                    this.masterStatusText = this.entityRecordMaster.status;
                })
        },
        getProjectEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/projectentityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectERMList = result.projectEntityRecordMasterList;
                    this.totalRowsRef = this.projectERMList.length;
                })
        },
        getERMReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmasterreference/list', data)
                .then((response) => {
                    let result = response.data;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        masterLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.masterEntity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.masterStatus = result.statusLookup;
                    this.usersMaster = result.userLookup;
                })

        },
        updateRecordMaster: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.elements.viewMasterMode = true;
                        this.entityRecordMasterCollection();
                    }
                })
        },
        cancelEdit: function() {
            this.entityRecordMasterCollection();
            this.elements.viewMasterMode = true;
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        onFilteredTestPlan(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.testPlan.totalRows = filteredItems.length
            this.testPlan.currentPage = 1
        },
        saveTestPlan: function() {
            this.testPlan.vhextAccountId =this.vhextAccountId;
            this.testPlan.projectId = this.projectId;
            this.testPlan.entityRecordMasterId = this.entityRecordMasterId;
            this.testPlan.preparedById = this.userId;

            console.log(this.testPlan);
            this.$v.testPlan.$touch();
            if (!this.$v.testPlan.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/test/testplan/add', this.testPlan)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getTestPlans();
                        }
                    })
            }
        },
        updateTestPlan: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/test/testplan/update', this.testPlan)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTestPlans();
                        this.viewTestPlan(this.testPlan.id);
                    }
                })
        },
        deleteTestPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/test/testplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTestPlans();
                        this.clearDetail();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearDetail: function() {
            this.testPlan.id = '';
            this.testPlan.testPlanId = '';            
            this.testPlan.testPlanTypeId = '';
            this.testPlan.title = '';
            this.testPlan.description = '';
            this.testPlan.preparedById = '';
            this.testPlan.preparedBy = '';
            this.testPlan.preparedDt = '';
            this.testPlan.statusId = '';
            this.testPlan.status = '';
            this.testPlan.type = '';
            this.testPlan.hasReference = '';
            this.testPlan.hasAttachment = '';
            this.testPlan.hasComment = '';
            this.testPlan.hasReview = '';
            this.testPlan.createdDt = '';
            this.testPlan.lastUpdatedDt = '';
            this.testPlan.projectId = '';
            this.testPlan.vhextAccountId = '';
            this.testPlan.entityRecordMasterId = '';
        },
        clearData: function() {
            this.elements.visible = false;
            this.testPlan.id = '';
            this.testPlan.testPlanId = '';
            this.testPlan.testPlanTypeId = '';
            this.testPlan.title = '';
            this.testPlan.description = '';
            this.testPlan.preparedById = '';
            this.testPlan.preparedDt = '';
            this.testPlan.statusId = '';
            this.$v.testPlan.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.editMode = true;
                this.testPlan.id = data.id;
                this.testPlan.testPlanId = data.testPlanId;
                this.testPlan.testPlanTypeId = data.testPlanTypeId;
                this.testPlan.title = data.title;
                this.testPlan.description = data.description;
                this.testPlan.preparedById = data.preparedById;
                this.testPlan.preparedBy = data.preparedBy;
                this.testPlan.preparedDt = data.preparedDt;
                this.testPlan.statusId = data.statusId;
                this.testPlan.status = data.status;
                this.testPlan.type = data.type;
                this.testPlan.hasReference = data.hasReference;
                this.testPlan.hasAttachment = data.hasAttachment;
                this.testPlan.hasComment = data.hasComment;
                this.testPlan.hasReview = data.hasReview;
                this.testPlan.createdDt = data.createdDt;
                this.testPlan.lastUpdatedDt = data.lastUpdatedDt;
                this.testPlan.projectId = data.projectId;
                this.testPlan.vhextAccountId = data.vhextAccountId;
                this.testPlan.entityRecordMasterId = data.entityRecordMasterId;
            }
        },
        showEdit: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = true;
            this.testPlan.id = data.id;
            this.testPlan.testPlanId = data.testPlanId;
            this.testPlan.testPlanTypeId = data.testPlanTypeId;
            this.testPlan.title = data.title;
            this.testPlan.description = data.description;
            this.testPlan.preparedById = data.preparedById;
            this.testPlan.preparedBy = data.preparedBy;
            this.testPlan.preparedDt = data.preparedDt;
            this.testPlan.statusId = data.statusId;
            this.testPlan.status = data.status;
            this.testPlan.type = data.type;
            this.testPlan.hasReference = data.hasReference;
            this.testPlan.hasAttachment = data.hasAttachment;
            this.testPlan.hasComment = data.hasComment;
            this.testPlan.hasReview = data.hasReview;
            this.testPlan.createdDt = data.createdDt;
            this.testPlan.lastUpdatedDt = data.lastUpdatedDt;
            this.testPlan.projectId = data.projectId;
            this.testPlan.vhextAccountId = data.vhextAccountId;
            this.testPlan.entityRecordMasterId = data.entityRecordMasterId;
        },
        showView: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = false;
            this.testPlan.id = data.id;
            this.testPlan.testPlanId = data.testPlanId;
            this.testPlan.testPlanTypeId = data.testPlanTypeId;
            this.testPlan.title = data.title;
            this.testPlan.description = data.description;
            this.testPlan.preparedById = data.preparedById;
            this.testPlan.preparedBy = data.preparedBy;
            this.testPlan.preparedDt = data.preparedDt;
            this.testPlan.statusId = data.statusId;
            this.testPlan.status = data.status;
            this.testPlan.type = data.type;
            this.testPlan.hasReference = data.hasReference;
            this.testPlan.hasAttachment = data.hasAttachment;
            this.testPlan.hasComment = data.hasComment;
            this.testPlan.hasReview = data.hasReview;
            this.testPlan.createdDt = data.createdDt;
            this.testPlan.lastUpdatedDt = data.lastUpdatedDt;
            this.testPlan.projectId = data.projectId;
            this.testPlan.vhextAccountId = data.vhextAccountId;
            this.testPlan.entityRecordMasterId = data.entityRecordMasterId;
        },
        viewTestPlan: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/test/testplan/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.testPlan = result.testPlanView;
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewTestPlan(this.testPlan.id);
        },
        addRef: function(data) {
            this.addReference.vhextAccountId = this.vhextAccountId;
            this.addReference.projectId = this.projectId;
            this.addReference.entityId = this.entityRecordMaster.entityId;
            this.addReference.entityRecordId = data.entityRecordMasterId;
            this.addReference.ermId = this.entityRecordMaster.id;
            this.addReference.erdId = '';
            this.addReference.refEntityId = data.entityId;
            this.addReference.refErmId = data.id;
            this.addReference.refErdId = data.refErdId;
            this.addReference.title = data.title;
            this.saveRef();
        },
        saveRef: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Reference Added',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
}
</script>