<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">Member Privileges</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Project Members">
                           
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="userTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="userList" @row-selected="onUserRowSelected" :fields="userFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                        <template v-slot:cell(actions)="data">
                                            <button type="submit" title="Reset User Privileges" @click="userProjectRoleReset(data.item.id, data.item.customUserRoleId)" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1 px-2">
                                                <i class="fa fa-repeat pr-1"></i>Reset
                                            </button>
                                        </template>
                                        <template v-slot:empty="user">
                                            <div class="text-center">No Member found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="user">
                                            <div class="text-center">{{ user.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                    <b-pagination v-if="userList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </b-tab>
                            </b-tabs>

                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-xl-8 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Member Access Privileges">

                                        <div class="row view-filter">
                                            <div class="col-sm-12">
                                                <div class="float-right">
                                                    <div class="dataTables_filter">
                                                        <label>
                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="float-left">
                                                    <label class="ml-1 mr-1 mt-1 title-highlight">
                                                        <span>User Name :</span> {{this.user.userName}}
                                                    </label>                                                    
                                                </div>
<!--                                                 <div class="col-3 float-right">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                            <b-form-select v-model="perPageUserMenu" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div> -->
<!--                                                 <div class="col-2 float-right">
                                                    <b-button class="addnew-quicktassk-btn" @click="userProjectRoleReset()" variant="outline-secondary" size="sm"><i class="fa fa-repeat pr-1"></i>Reset</b-button>
                                                </div> -->
                                            </div>
                                        </div>
                                        <b-table show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left 
                                        :items="userMenuList" :fields="userMenuFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUserMenu">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(menuExist)="data">
                                                <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege(data.item)">
                                                </b-form-checkbox>
                                            </template>
                                            <template v-slot:empty="userMenu">
                                                <div class="text-center">No UserMenu found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="userMenu">
                                                <div class="text-center">{{ userMenu.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>

                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalUserMenuRows}} Items</span>

                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        
    },
    data() {
        return {
            showDetOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
           
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            userId: localStorage.getItem("userId"),

            refEntity: '',

            standardMenuTypeId: 1,
            user: {
                id: '',
                userId: '',
                userName: '',
                position: '',
                userTypeId: '',
                customUserRoleId: '',
                type: '',
            },
            userMenu: {
                id: '',
                vhextAccountId: '',
                projectId: '',
                vmxServiceRecordId: '',
                userId: '',
                customMenuId: '',
                standardMenuId: '',
                vmxServiceCode: '',
                active: '',
            },
            notEnabled: true,

            mainTableArray: [],
            userMenuList: [],
            riskType: [],
            severityLookup: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            userList: [],
            userFields: [
                { key: 'userId', label: 'User Id', sortable: true, sortDirection: 'desc' },
                // { key: 'userTypeId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'userName', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'customUserRole', label: 'Member Role', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' },
        
            ],
            userMenuFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                // { key: 'standardMenuId', label: 'Std. Id', sortable: true, sortDirection: 'desc' },
                // { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                // { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'menuExist', label: 'Assigned', sortable: true, sortDirection: 'desc' },

            ],
            selectMode: 'single',
            totalRows: 1,
            totalUserMenuRows: 1,
            currentPage: 1,
            currentPageUserMenu: 1,
            perPage: 10,
            perPageUserMenu: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            userFilter: null,
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getUserList();
    },
    validations: {
    },
    methods: {
        getUserList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId

            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/projectprivilege/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.userList = result.userList;
                    this.totalRows = this.userList.length;
                    this.$nextTick(() => {
                        this.selectBTableRowById(this.user.id, this.mainTableArray, 'userTable');
                    })
                })
        },
        getUserMenu: function(userId, customUserRoleId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                vmxServiceCode: this.vmxServiceCode,
                userId: userId,
                standardMenuTypeId: this.standardMenuTypeId,
                customUserRoleId: customUserRoleId,
            }
            console.log(data);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/usermenu/list', data)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.userMenuList = result.userMenuList;
                    this.totalUserMenuRows = this.userMenuList.length;
                })
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredUserMenu(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalUserMenuRows = filteredItems.length
            this.currentPageUserMenu = 1
        },
        onUserRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.user.id = data.id;
                this.user.userId = data.userId;
                this.user.userName = data.userName;
                this.user.userTypeId = data.userTypeId;
                this.user.customUserRoleId = data.customUserRoleId;

                if (data.type == "Client") {
                    this.standardMenuTypeId = 2;
                }
                else {
                    this.standardMenuTypeId = 1;
                }

                console.log(this.user.id);
                this.getUserMenu(this.user.id, this.user.customUserRoleId);
            }
        },
        assignPrivilege: function(item) {
            this.userMenu.vhextAccountId = this.vhextAccountId;
            this.userMenu.vmxServiceRecordId = this.projectId;
            this.userMenu.vmxServiceCode = 'PRJ';
            this.userMenu.userId = this.user.id;
            this.userMenu.customUserRoleId = this.user.customUserRoleId;
            this.userMenu.standardMenuId = item.standardMenuId;

            if (item.menuExist == "true") {
                this.userMenu.active = 0;
            } else if (item.menuExist == "false"){
                this.userMenu.active = 1;
            }
            this.assignUserMenuPrivilege();
        },
        assignUserMenuPrivilege: function() {
            console.log(this.userMenu);
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/member/servicemenu/assign', this.userMenu)
                .then((response) => {console.log(this.userMenu.userId);
                    this.showDetOverlay = false;
                    let result = response.data;

                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserMenu(this.userMenu.userId, this.userMenu.customUserRoleId);
                        if (this.userMenu.userId == this.userId) {
                            EventBus.$emit('onServiceLeftMenuReload');
                            EventBus.$emit('onServiceTopMenuReload');
                        }
                    }
                })
                .catch((error) => {
                    this.showDetOverlay = false;
                })
        },
        userProjectRoleReset: function(userId, customUserRoleId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                customUserRoleId: customUserRoleId,
                userId: userId,
                actionState: 1,
                createdById: this.userId,
            }

            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/projectrole/reset', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: "User Role Reset",
                            position: 'topRight'
                        });
                        // this.clearData();
                        this.getUserMenu(userId, customUserRoleId);
                        this.showDetOverlay = false;
                        if (userId == this.userId) {
                            EventBus.$emit('onServiceLeftMenuReload');
                            EventBus.$emit('onServiceTopMenuReload');
                        }

                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },




    }
}
</script>