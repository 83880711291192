<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row task-top-menu">
                        <div class="col-lg-2 col-sm-2 mt-1 pr-0 mr-0">
                            <h4 class="pl-0 mb-0 fs-16">Requirement Board</h4>
                        </div>
                        <div class="col-lg-2 col-sm-2 pl-0 mb-1 ml-0 text-left">
                            <select v-model="ermObject" :title="ermObject.value" @change="ermFilter(ermObject)" class="form-control" id="requirementId" >
                                <option value="all" >All</option>
                                <option v-for="(info, index) in ermLookup" :value="{id: info.id, value:info.value}" :title="info.value">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col-lg-2 col-sm-3 pr-0 " >
                            <div class="dataTables_filter" >
                                <label>
                                    <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                    <input type="search" v-model="search" class="form-control search-task" placeholder="Search Title..">
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2 mt-0 pt-0 mb-1" >
                            <b-button class="addnew-tassk-btn float-right ml-5" variant="outline-info" @click="openQuickRequirement('', false)" size="sm"><i class="fa fa-plus"></i> New Requirement</b-button>
                        </div>
                        <div class="col-lg-4 col-sm-3 mt-2 ">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                    <li v-if="isArchive" class="breadcrumb-item">
                                        <a href="javascript:" title=""  @click="getArchiveRequirements(false)"><i class="fa fa-undo action-link pr-2" aria-hidden="true"></i>Requirement</a>
                                    </li>
                                    <li v-else class="breadcrumb-item">
                                        <a href="javascript:" title="Archive"  @click="getArchiveRequirements(true) "><i class="fa fa-undo action-link pr-2" aria-hidden="true"></i>Archive</a>
                                    </li> 
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay  :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                <b-table v-if="isArchive" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :items="filteredReqList" :fields="requirementFields" responsive="sm" :filter="requirementFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(title)="data">
                        <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                    </template>
                    <template v-slot:cell(workHour)="data">
                        {{convertToHM(data.item.estEffortMins)}}
                    </template>
                    <template v-slot:cell(estimatedCost)="data">
                        <span v-if="data.value">{{ currencySymbol }}{{ data.value }}</span>
                    </template> 
                    <template v-slot:cell(assignedToId)="data">    
                        {{ data.item.assignedTo }}
                    </template>
                    <template v-slot:cell(statusId)="data">    
                        {{ data.item.status }}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <span> 
                            <a href="#" class="mr-2 action-link" title="UnArchive" @click="archiveRequirements(data.item)"><i class="fa fa-undo" aria-hidden="true"></i></a>
                        </span> 
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Record found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <div v-else class="row" >
                    <div class="col-12 col-lg-12 col-xl-12 pr-2 position-relative">
                        <div class="row mr-0 pl-2">
                            <div v-for="status in mainStatus" class="col-12 col-md mb-1 px-1">

                                <div class="card mb-2 b-radius-5">
                                    <div class="card-body px-3 py-2">
                                        <div class="form-group mb-0">
<!--                                             <label v-if="status.value == 'Draft'" class="mt-1 mb-0 font-weight-bold" for="title">{{status.value}}
                                                <b-badge class="ml-2" title="Requirements">{{ getItemCount(status.id) }}</b-badge>
                                            </label> -->
                                            <label v-if="status.value == 'New Requirement'" class="mt-1 mb-0 font-weight-bold" for="title">{{status.value}}
                                                <b-badge class="ml-2" title="Requirements">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <label v-if="status.value == 'In Progress'" class="mt-1 mb-0 font-weight-bold" for="title">{{status.value}}
                                                <b-badge class="ml-2" title="Requirements">{{ getItemCount(status.id) }}</b-badge>
                                            </label> 
                                            <label v-if="status.value == 'Completed'" class="mt-1 mb-0 font-weight-bold" for="title">{{status.value}}
                                                <b-badge class="ml-2" title="Requirements">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <label v-if="status.value == 'Verified'" class="mt-1 mb-0 font-weight-bold" for="title">{{status.value}}
                                                <b-badge class="ml-2" title="Requirements">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <label v-if="status.value == 'Closed'" class="mt-1 mb-0 font-weight-bold" for="title">{{status.value}}
                                                <b-badge class="ml-2" title="Requirements">{{ getItemCount(status.id) }}</b-badge>
                                            </label>
                                            <div v-if="status.value == 'New Requirement' || status.value == 'In Progress' || status.value == 'Completed' || status.value == 'Verified' || status.value == 'Closed'">
                                            
                                                <b-button class="pull-right addnew-quicktask-btn mt-1" @click="openQuickRequirement(status.id, false)" variant="outline-info" size="sm"><i class="fa fa-plus"></i> Quick Req</b-button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="taskLists">
                                    <div v-for="requirement in filteredReqList">
                                        <template v-if="status.value == 'New Requirement'">
                                            <div v-if="requirement.status == 'New Requirement' || (requirement.status == 'Draft' || requirement.status == 'Reopen'|| requirement.status == 'Deferred' && requirement.preparedById == userId) || requirement.status == 'Reopen' || requirement.status == 'On Hold'" class="card-media mb-2">
                                                <div class="card-media-body dropdown show" :style="requirement.status == 'Draft' ? 'background-color: #eeeeee' : ''">
                                                    <a v-if="!checkFreeze(requirement.entityRecordMasterId)" type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <a v-else type="button" title="Requirement Freezed" class="float-right">
                                                        <i class="fa fa-lock text-muted py-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(requirement)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Requirement Details</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  py-1" @click="openQuickRequirement(requirement, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Requirement</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(requirement)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="openTaskAssignmentRefPopup(requirement.entityRecordMasterId, requirement.id)" href="javascript:"><i class="fa fa-list pr-2 mt-1 mb-0" aria-hidden="true"></i>Requirement Assignment</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(requirement)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Update Status</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item py-1" @click="deleteRequirement(requirement.id)" v-b-modal.deleteRequirement href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Requirement</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveRequirements(requirement)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="requirement.status!=null" title="Status" :class="{'bg-grey' : requirement.status == 'Draft'}" class="card-media-object-tag subtle float-right mr-1">{{ requirement.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(requirement)">{{ requirement.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="container-fluid">
                                                        <div v-if="(requirement.type != null) || (requirement.priority != null)">
                                                            <div class="row">
                                                                <div class="col-8 pr-0">
                                                                    <span v-if="requirement.type" title="Requirement Type" class="subtle">{{ requirement.type }}</span>
                                                                </div>
                                                                <div class="col-4 pl-0">
                                                                    <span v-if="requirement.priority" title="Priority" class="subtle float-right"><i class="fa fa-exclamation-circle pr-1 pointer" :class="priorityColour(requirement.priority)"></i>{{ requirement.priority }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(requirement.dueDate != null) || (requirement.preparedBy != null)">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="card-media-object-social-list">
                                                                        <div v-if="(requirement.preparedBy != null)">
                                                                            <b-badge class="task-badge" title="Prepared By"> {{ requirement.preparedBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-12 col-xl-6">
                                                                    <span v-if="(requirement.dueDate != null)" :class="(status.value !== 'Draft' && status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Verified') ? dueDateStyling(requirement.dueDate) : ''" title="Due Date" class="subtle float-right"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ requirement.dueDate | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="status.value == 'In Progress'">
                                            <div v-if="requirement.status == 'In Progress'" class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a v-if="!checkFreeze(requirement.entityRecordMasterId)" type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <a v-else type="button" title="Requirement Freezed" class="float-right">
                                                        <i class="fa fa-lock text-muted py-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(requirement)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Requirement Details</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  py-1" @click="openQuickRequirement(requirement, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Requirement</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(requirement)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="openTaskAssignmentRefPopup(requirement.entityRecordMasterId, requirement.id)" href="javascript:"><i class="fa fa-list pr-2 mt-1 mb-0" aria-hidden="true"></i>Requirement Assignment</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(requirement)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Update Status</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item py-1" @click="deleteRequirement(requirement.id)" v-b-modal.deleteRequirement href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Requirement</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveRequirements(requirement)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="requirement.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ requirement.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(requirement)">{{ requirement.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="container-fluid">
                                                        <div v-if="(requirement.type != null) || (requirement.priority != null)">
                                                            <div class="row">
                                                                <div class="col-8 pr-0">
                                                                    <span v-if="requirement.type" title="Requirement Type" class="subtle">{{ requirement.type }}</span>
                                                                </div>
                                                                <div class="col-4 pl-0">
                                                                    <span v-if="requirement.priority" title="Priority" class="subtle float-right"><i class="fa fa-exclamation-circle pr-1 pointer" :class="priorityColour(requirement.priority)"></i>{{ requirement.priority }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(requirement.dueDate != null) || (requirement.preparedBy != null)">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="card-media-object-social-list">
                                                                        <div v-if="(requirement.preparedBy != null)">
                                                                            <b-badge class="task-badge" title="Prepared By"> {{ requirement.preparedBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-12 col-xl-6">
                                                                    <span v-if="(requirement.dueDate != null)" :class="(status.value !== 'Draft' && status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Verified') ? dueDateStyling(requirement.dueDate) : ''" title="Due Date" class="subtle float-right"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ requirement.dueDate | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="status.value == 'Completed'">
                                            <div v-if="requirement.status == 'Completed'" class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a v-if="!checkFreeze(requirement.entityRecordMasterId)" type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <a v-else type="button" title="Requirement Freezed" class="float-right">
                                                        <i class="fa fa-lock text-muted py-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(requirement)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Requirement Details</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  py-1" @click="openQuickRequirement(requirement, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Requirement</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(requirement)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="openTaskAssignmentRefPopup(requirement.entityRecordMasterId, requirement.id)" href="javascript:"><i class="fa fa-list pr-2 mt-1 mb-0" aria-hidden="true"></i>Requirement Assignment</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(requirement)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Update Status</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item py-1" @click="deleteRequirement(requirement.id)" v-b-modal.deleteRequirement href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Requirement</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveRequirements(requirement)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="requirement.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ requirement.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(requirement)">{{ requirement.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="container-fluid">
                                                        <div v-if="(requirement.type != null) || (requirement.priority != null)">
                                                            <div class="row">
                                                                <div class="col-8 pr-0">
                                                                    <span v-if="requirement.type" title="Requirement Type" class="subtle">{{ requirement.type }}</span>
                                                                </div>
                                                                <div class="col-4 pl-0">
                                                                    <span v-if="requirement.priority" title="Priority" class="subtle float-right"><i class="fa fa-exclamation-circle pr-1 pointer" :class="priorityColour(requirement.priority)"></i>{{ requirement.priority }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(requirement.dueDate != null) || (requirement.preparedBy != null)">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="card-media-object-social-list">
                                                                        <div v-if="(requirement.preparedBy != null)">
                                                                            <b-badge class="task-badge" title="Prepared By"> {{ requirement.preparedBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-12 col-xl-6">
                                                                    <span v-if="(requirement.dueDate != null)" :class="(status.value !== 'Draft' && status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Verified') ? dueDateStyling(requirement.dueDate) : ''" title="Due Date" class="subtle float-right"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ requirement.dueDate | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="status.value == 'Verified'">
                                            <div v-if="requirement.status == 'Verified'" class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a v-if="!checkFreeze(requirement.entityRecordMasterId)" type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <a v-else type="button" title="Requirement Freezed" class="float-right">
                                                        <i class="fa fa-lock text-muted py-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(requirement)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Requirement Details</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  py-1" @click="openQuickRequirement(requirement, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Requirement</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(requirement)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="openTaskAssignmentRefPopup(requirement.entityRecordMasterId, requirement.id)" href="javascript:"><i class="fa fa-list pr-2 mt-1 mb-0" aria-hidden="true"></i>Requirement Assignment</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(requirement)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Update Status</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item py-1" @click="deleteRequirement(requirement.id)" v-b-modal.deleteRequirement href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Requirement</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveRequirements(requirement)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="requirement.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ requirement.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(requirement)">{{ requirement.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="container-fluid">
                                                        <div v-if="(requirement.type != null) || (requirement.priority != null)">
                                                            <div class="row">
                                                                <div class="col-8 pr-0">
                                                                    <span v-if="requirement.type" title="Requirement Type" class="subtle">{{ requirement.type }}</span>
                                                                </div>
                                                                <div class="col-4 pl-0">
                                                                    <span v-if="requirement.priority" title="Priority" class="subtle float-right"><i class="fa fa-exclamation-circle pr-1 pointer" :class="priorityColour(requirement.priority)"></i>{{ requirement.priority }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(requirement.dueDate != null) || (requirement.preparedBy != null)">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="card-media-object-social-list">
                                                                        <div v-if="(requirement.preparedBy != null)">
                                                                            <b-badge class="task-badge" title="Prepared By"> {{ requirement.preparedBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-12 col-xl-6">
                                                                    <span v-if="(requirement.dueDate != null)" :class="(status.value !== 'Draft' && status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Verified') ? dueDateStyling(requirement.dueDate) : ''" title="Due Date" class="subtle float-right"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ requirement.dueDate | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="status.value == 'Closed'">
                                            <div v-if="requirement.status == 'Closed'" class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a v-if="!checkFreeze(requirement.entityRecordMasterId)" type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <a v-else type="button" title="Requirement Freezed" class="float-right">
                                                        <i class="fa fa-lock text-muted py-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  pb-1 py-1" @click="viewItemDetails(requirement)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Requirement Details</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  py-1" @click="openQuickRequirement(requirement, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Requirement</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click.prevent="assignTask(requirement)" v-b-modal.ermAssignments href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Assign Task</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="openTaskAssignmentRefPopup(requirement.entityRecordMasterId, requirement.id)" href="javascript:"><i class="fa fa-list pr-2 mt-1 mb-0" aria-hidden="true"></i>Requirement Assignment</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item  my-0 py-1" @click="showStatusPopup(requirement)" href="javascript:"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Update Status</a>
                                                        <a v-if="requirement.preparedById == userId" class="dropdown-item py-1" @click="deleteRequirement(requirement.id)" v-b-modal.deleteRequirement href="javascript:"><i class="fa fa-trash pr-2"></i>Delete Requirement</a>
                                                        <a v-if="requirement.preparedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click="archiveRequirements(requirement)" href="javascript:"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                    </div>
                                                    <span v-if="requirement.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ requirement.status }}</span>
                                                    <div class="card-media-body-top">
                                                        <span class="">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(requirement)">{{ requirement.title }}</a>
                                                        </span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="container-fluid">
                                                        <div v-if="(requirement.type != null) || (requirement.priority != null)">
                                                            <div class="row">
                                                                <div class="col-8 pr-0">
                                                                    <span v-if="requirement.type" title="Requirement Type" class="subtle">{{ requirement.type }}</span>
                                                                </div>
                                                                <div class="col-4 pl-0">
                                                                    <span v-if="requirement.priority" title="Priority" class="subtle float-right"><i class="fa fa-exclamation-circle pr-1 pointer" :class="priorityColour(requirement.priority)"></i>{{ requirement.priority }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(requirement.dueDate != null) || (requirement.preparedBy != null)">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <div class="card-media-object-social-list">
                                                                        <div v-if="(requirement.preparedBy != null)">
                                                                            <b-badge class="task-badge" title="Prepared By"> {{ requirement.preparedBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-sm-12 col-xl-6">
                                                                    <span v-if="(requirement.dueDate != null)" :class="(status.value !== 'Draft' && status.value !== 'Closed' && status.value !== 'Completed' && status.value !== 'Verified') ? dueDateStyling(requirement.dueDate) : ''" title="Due Date" class="subtle float-right"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ requirement.dueDate | formatDate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-if="!getReqByStatusId(status.id).length" class="card-media mb-2">
                                        <div class="card-media-body">
                                            <div class="stage-card text-center">No requirement found</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </b-overlay>  
        </div>
        <TaskAssignmentRefPopup v-if="showDialog" id="taskAssignmentRefPopup" ref="taskAssignmentRefPopupRef" @closeDialog="showDialog=false" />
        <ReqSpecDetailPopup v-if="showDialog" ref="reqSpecDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
        <QuickReqPopup v-if="showDialog" ref="quickReqPopupRef" :projectTypeId="this.projectTypeId" @refreshData="getRequirements"  @masterLookupRefresh="lookupData" :requirementData="requirementSpec" @closeDialog="closeDialog" />
        <StatusTrackerPopup v-if="showDialog" ref="statusTrackerPopupRef" :statusUpdate="statusUpdate" @closeDialog="closeDialog" @updateParent="getRequirements" />
        <DeleteComponent id="deleteRequirement" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Requirement?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TaskAssignmentRefPopup from '@/components/popup/itemdetail/TaskAssignmentRefPopup.vue';
import ReqSpecDetailPopup from '@/components/popup/itemdetail/ReqSpecDetailPopup.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import StatusTrackerPopup from '@/components/popup/action/StatusTrackerPopup.vue';
import QuickReqPopup from '@/components/popup/action/QuickReqPopup.vue';
export default {
    name: 'app',
    props: {
        ermId: Number
    },

    components: {
        TaskAssignmentRefPopup,
        ReqSpecDetailPopup,
        QuickTaskPopup,
        StatusTrackerPopup,
        QuickReqPopup,
    },
    data() {
        return {
            elements: {
                editMode: false
            },
            showTaskAssignmentRefPopup: false,
            isArchive: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            showDialog: false,
            showOverlay: false,
            detailViewData: {},
            referencePopupId: 0,
            typeLookupTitle: 'Task Type Settings',

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',
                statusId: 1,
            },

            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                refEntityId: '',
                refEntity: 'requirement_spec',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },

            requirementSpec: {
                editMode: null,
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'requirement_spec',
                title: '',
                requirementTypeId: '',
                isArchive: false,
                requirementSpecification: '',
                priorityId: '',
                dueDate: new Date().toISOString().substr(0, 10),
                preparedById: localStorage.getItem("userId"),
                preparedDt: new Date().toISOString().substr(0, 10),
                statusId: this.statusId,
            },

            allRequirements: [],
            requirements: [],
            reqStatus: [],
            ermLookup: [],
            search: '',
            ermObject: {
                id:   '',
                value:   '',
            },

            requirementFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Requirement', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'preparedDt', label: 'Prepared Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'dueDate', label: 'Due Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' }, 
                { key: 'preparedBy', label: 'Prepared By', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'multi',
            requirementFilter: null,
            filterOn: [], 
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        filteredReqList() {
            return this.requirements.filter(requirements => {
                return requirements.title.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        mainStatus() {
            return this.reqStatus.filter(status => status.value !== 'Draft' && status.value !== 'Approved' && status.value !== 'Deferred' && status.value !== 'On Hold');
        },
    },
    mounted() {
        this.ermObject.id = localStorage.getItem("ermId");
        this.ermObject.value = localStorage.getItem("ermTitle");

        // this.ermObject.id = this.$store.getters.getERMData.erm_id;
        // this.ermObject.value = this.$store.getters.getERMData.erm_title;

        console.log(localStorage.getItem("ermId"),localStorage.getItem("ermTitle"));
        this.getRequirements();
        this.lookupData();


    },
    validations: {
        requirementSpec: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            }
        }
    },
    methods: {
        getRequirements: function() {
            let data = {
                vhextAccountId: this.requirementSpec.vhextAccountId,
                projectId: this.requirementSpec.projectId,
                userId: this.userId,
                isArchive: this.isArchive
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/reqboard/list', data)
                .then((response) => {
                    let result = response.data;
                    this.requirements = result.requirementSpecList;
                    this.allRequirements = this.requirements;
                    this.$nextTick(() => {
                        this.ermFilter(this.ermObject);
                    })
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'requirement_spec'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/reqboard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;
                    this.reqStatus = result.statusLookup;
                    console.log("RES",result);
                })
        },
        ermFilter: function(ermData) {
            console.log(ermData);
            if (ermData.id !== null && ermData.id) {
                this.requirements = this.allRequirements;
                if (this.requirements != null) {
                    var requirement = this.requirements;
                    this.requirements = requirement.filter(function(req) {
                        return req.entityRecordMasterId == ermData.id;
                    });
                }
                this.setERMData(ermData.id,ermData.value);
            } else if (ermData == 'all') {

                this.requirements = this.allRequirements;
            }
            console.log(ermData);
        },

        deleteRequirement: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRequirements();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getReqByStatusId(statusId) {
            return this.requirements.filter(requirementSpec => requirementSpec.statusId == statusId);
        },
        getArchiveRequirements: function(flag){
            this.isArchive=flag; 
            this.allRequirements= [];
            this.requirements= [];
            this.getRequirements();
        },
        archiveRequirements: function(item) {
            let data = {
                id: item.id,
                entity: 'requirement_spec',
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            this.showOverlay=true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    this.showOverlay=false;
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: item.isArchive ? 'Requirement Unarchived' : 'Requirement Archived',
                            position: 'topRight'
                        });
                        this.getRequirements();
                    }
                })
                .catch(error => {
                    this.showOverlay=false;
                });
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.reqSpecDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        openQuickRequirement: function(data, flag) {
            if (flag == true) {
                this.requirementSpec.id = data.id;
                this.requirementSpec.requirementSpecId = data.requirementSpecId;
                this.requirementSpec.title = data.title;
                this.requirementSpec.requirementTypeId = data.requirementTypeId;
                this.requirementSpec.requirementSpecification = data.requirementSpecification;
                this.requirementSpec.entityRecordMasterId = data.entityRecordMasterId;
                this.requirementSpec.isArchive = data.isArchive;
                this.requirementSpec.preparedById = data.preparedById;
                this.requirementSpec.preparedDt = data.preparedDt;
                this.requirementSpec.priorityId = data.priorityId;
                this.requirementSpec.dueDate = data.dueDate;
                this.requirementSpec.statusId = data.statusId;
            } else {
                this.requirementSpec.id = '';
                this.requirementSpec.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.requirementSpec.projectId = localStorage.getItem("projectId");
                this.requirementSpec.userId = localStorage.getItem("userId");
                this.requirementSpec.entity = 'requirement_spec';
                this.requirementSpec.title = '';
                this.requirementSpec.requirementTypeId = '';
                this.requirementSpec.requirementSpecification = '';
                this.requirementSpec.isArchive = false;
                this.requirementSpec.priorityId = '';
                this.requirementSpec.dueDate = new Date().toISOString().substr(0, 10);
                this.requirementSpec.preparedById = localStorage.getItem("userId");
                this.requirementSpec.preparedDt = new Date().toISOString().substr(0, 10);
                this.requirementSpec.statusId = data;
                this.requirementSpec.entityRecordMasterId = this.checkFreeze(this.ermObject.id) ? '' : this.ermObject;
            }
            this.requirementSpec.editMode = flag;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$bvModal.show('quickReqPopup');
            })
        },
        assignTask: function(data) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = 'requirement_spec';
            this.masterData.refErdTitle = data.title;
            this.masterData.statusId = 1;
            this.masterData.refErdDescription = data.requirementSpecification;
            this.$refs.ermAssignmentsRef.onRefDataChange();
            console.log(this.masterData.refErmId, this.masterData.refEntityId);
        },
        showStatusPopup: function(data) {
            console.log(data);
            if (data && data !== null) {
                this.statusUpdate.id = '';
                this.statusUpdate.statusUpdateId = '';
                this.statusUpdate.refRecordId = data.id;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.refId = data.requirementSpecId;
                this.statusUpdate.refRecordTitle = data.title;
                this.statusUpdate.statusById = this.userId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.showDialog = true;
                this.$nextTick(() => {
                    this.$bvModal.show('statusTrackerPopup');
                })
            }
        },
        dueDateStyling(date) {
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today)) {
                    return 'text-warning';
                } else if (date < today) {
                    return 'text-danger';
                } else if (date > today) {
                    return 'text-success';
                }
            }
        },
        priorityColour(priority) {
            if (priority && priority == 'High') {
                return 'text-danger';
            }
            if (priority && priority == 'Medium') {
                return 'text-warning';
            } else {
                return 'text-success';
            }
        },
        setERMData: function(ermId,ermTitle) {
            
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);

            // let ermData = {
            //     ermId: ermId,
            //     ermTitle: ermTitle,
            // }
            // console.log(ermData);

            // this.$store.dispatch("setERMData", ermData);

        },
        checkFreeze(reqId) {
            let isFreeze = false;
            this.ermLookup.filter((erm) => {
                if (erm.id == reqId) {
                    isFreeze = erm.isFreeze ? erm.isFreeze : false;
                }
            })
            return isFreeze;
        },
        openTaskAssignmentRefPopup: function(refErmId, refErdId) {
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.taskAssignmentRefPopupRef.getTasks(refErmId, refErdId);
            })
        },
        getItemCount: function(statusId) {

            return (this.filteredReqList.filter((itemData)=> itemData.statusId==statusId)).length;

        },
        redirectPage: function() {
            if (this.ermObject.id != null && this.ermObject.id > 0) {
                this.$router.push('/reqspc/' + this.ermObject.id);
            } else {
                this.$router.push('/srsmas/list');
            }
        },


    }
}
</script>