<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">{{ pageTitle }}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right"
                                aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i
                                                class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{ this.projId }} - {{ this.projectName }}
                                        </h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#"
                                                v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New
                                                    {{ pageTitle }}</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible"
                                            role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-8">
                                                        <div class="form-group">
                                                            <label for="customTypeLookup">{{ pageTitle }}</label>
                                                            <div class="required-field-block"
                                                                :class="{ 'is-invalid': $v.customTypeLookup.type.$error }">
                                                                <input type="text" class="form-control"
                                                                    v-model="customTypeLookup.type"
                                                                    id="customTypeLookup"
                                                                    :class="{ 'is-invalid': $v.customTypeLookup.type.$error }"
                                                                    :placeholder="`Enter ${pageTitle}`">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label
                                                                v-if="$v.customTypeLookup.type.$error && !$v.customTypeLookup.type.minLength"
                                                                class="text-danger">{{ pageTitle }} title must have at
                                                                least {{ $v.customTypeLookup.type.$params.minLength.min }}
                                                                letters.</label>
                                                            <label
                                                                v-if="$v.customTypeLookup.type.$error && !$v.customTypeLookup.type.required"
                                                                class="text-danger">Please enter {{ pageTitle }}
                                                                title</label>
                                                            <label
                                                                v-if="$v.customTypeLookup.type.$error && !$v.customTypeLookup.type.maxLength"
                                                                class="text-danger">{{ pageTitle }} title must not exceed
                                                                {{ $v.customTypeLookup.type.$params.maxLength.max }}
                                                                letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle"
                                                                    v-model="customTypeLookup.active"
                                                                    name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <button type="submit" @click="saveCustomTypeLookup"
                                                    class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i
                                                        class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData"
                                                    class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i
                                                        class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right"
                                                label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm"
                                                    :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter"
                                                    class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="customTypeLookupTable" v-model="mainTableArray" show-empty small stacked="md"
                                selectable :select-mode="selectMode" sort-icon-left :busy="isBusy"
                                :items="customTypeLookupList" @row-selected="onRowSelected"
                                :fields="customTypeLookupFields" responsive="sm" :current-page="currentPage"
                                :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn"
                                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                                @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value"
                                        @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i
                                            class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteCustomTypeLookup(data.item.id)"
                                        v-b-modal.deleteCustomTypeLookup><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No {{ pageTitle }} found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>

                            <span :class="'badge-secondary'"
                                class="fs-11 badge badge-pill pointer text-center float-left">{{ this.totalRows }}
                                Items</span>
                            <b-pagination v-if="customTypeLookupList.length != 0" pills v-model="currentPage"
                                :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill"
                                size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend
                                                        is-text><b>{{ customTypeLookupDetail.customTypeLookupId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:"
                                                        @click="elements.editMode = true"
                                                        class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateCustomTypeLookup"
                                                            title="Save" class="sbtn btn btn-xs action-link p-1"><i
                                                                class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel"
                                                            class="sbtn btn btn-xs action-link p-1"><i
                                                                class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="customTypeLookup">{{ pageTitle }}</label>
                                                    <input type="text" class="form-control" id="customTypeLookup"
                                                        :disabled="!elements.editMode"
                                                        v-model="customTypeLookupDetail.type">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle"
                                                        v-model="customTypeLookupDetail.active"
                                                        :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0"
                                                            role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block
                                                                href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1"
                                                            role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text"
                                                                                class="form-control disabled"
                                                                                id="createdBy"
                                                                                v-model="customTypeLookupDetail.createdBy"
                                                                                readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text"
                                                                                    class="form-control disabled"
                                                                                    :value="customTypeLookupDetail.createdDt | formatDate"
                                                                                    readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated
                                                                                Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text"
                                                                                    class="form-control disabled"
                                                                                    :value="customTypeLookupDetail.lastUpdatedDt | formatDate"
                                                                                    readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteCustomTypeLookup" :onYes="onYes" :returnParams="deleteParam"
            title="Delete Confirmation" :message="`Are you sure to delete ${pageTitle} ?`" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
        pageTitle: String,
        pageEntity: String
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false
            },
            showOverlay: false,
            showDetOverlay: false,
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: 'custom_type_lookup',

            mainTableArray: [],

            customTypeLookup: {
                id: '',
                customTypeLookupId: '',
                customTypeLookupId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: this.pageEntity,
                type: '',
                typeName: '',
                typeClass: '',
                shortName: '',
                description: '',
                active: true,
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                userId: localStorage.getItem("userId"),
                createdById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
            },
            customTypeLookupDetail: {
                id: '',
                customTypeLookupId: '',
                entity: '',
                customTypeLookupId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                typeName: '',
                typeClass: '',
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                entityId: '',
                userId: localStorage.getItem("userId"),
                type: '',
                shortName: '',
                description: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            customTypeLookupList: [],
            customTypeLookupFields: [
                { key: 'index', label: 'SN' },
                { key: 'type', label: this.pageTitle, sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getCustomTypeLookups();
    },
    validations: {
        customTypeLookup: {
            type: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        customTypeLookupDetail: {
            type: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getCustomTypeLookups: function () {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.pageEntity
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/list', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.customTypeLookupList = result.customTypeLookupList;
                    this.totalRows = this.customTypeLookupList.length;
                    if (this.customTypeLookupList && this.customTypeLookupList.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.customTypeLookupDetail.id, this.mainTableArray, 'customTypeLookupTable');
                        })
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveCustomTypeLookup: function () {
            this.$v.customTypeLookup.$touch();
            if (!this.$v.customTypeLookup.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/add', this.customTypeLookup)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.getCustomTypeLookups();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        updateCustomTypeLookup: function () {
            this.$v.customTypeLookupDetail.$touch();
            if (!this.$v.customTypeLookupDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/update', this.customTypeLookupDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getCustomTypeLookups();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        viewCountry: function (id) {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/customTypeLookup/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let customTypeLookupDetail = result.customTypeLookup;
                    if (customTypeLookupDetail) {
                        this.customTypeLookupDetail.id = customTypeLookupDetail.id;
                        this.customTypeLookupDetail.customTypeLookupId = customTypeLookupDetail.customTypeLookupId;
                        this.customTypeLookupDetail.entity = customTypeLookupDetail.entity;
                        this.customTypeLookupDetail.entityId = customTypeLookupDetail.entityId;
                        this.customTypeLookupDetail.typeName = customTypeLookupDetail.typeName;
                        this.customTypeLookupDetail.typeClass = customTypeLookupDetail.typeClass;
                        this.customTypeLookupDetail.projectId = customTypeLookupDetail.projectId;
                        this.customTypeLookupDetail.projectTypeId = customTypeLookupDetail.projectTypeId;
                        this.customTypeLookupDetail.userId = customTypeLookupDetail.userId;
                        this.customTypeLookupDetail.customTypeLookupId = customTypeLookupDetail.customTypeLookupId;
                        this.customTypeLookupDetail.vhextAccountId = customTypeLookupDetail.vhextAccountId;
                        this.customTypeLookupDetail.type = customTypeLookupDetail.type;
                        this.customTypeLookupDetail.shortName = customTypeLookupDetail.shortName;
                        this.customTypeLookupDetail.description = customTypeLookupDetail.description;
                        this.customTypeLookupDetail.active = customTypeLookupDetail.active;
                        this.customTypeLookupDetail.createdById = customTypeLookupDetail.createdById;
                        this.customTypeLookupDetail.createdBy = customTypeLookupDetail.createdBy;
                        this.customTypeLookupDetail.createdDt = customTypeLookupDetail.createdDt;
                        this.customTypeLookupDetail.lastUpdatedDt = customTypeLookupDetail.lastUpdatedDt;
                    }
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function () {
            this.viewCountry(this.customTypeLookupDetail.id);
        },
        deleteCustomTypeLookup: function (id) {
            this.deleteParam.id = id;
        },
        onYes: function () {
            this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/customtypelookup/delete/' + this.deleteParam.id)
                .then(response => {
                    this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCustomTypeLookups();
                        this.elements.showDetail = false;
                        this.$refs.customTypeLookupTable.clearSelected();
                    }
                    else if (result.status == "CONFLICT") {
                        iziToast.warning({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                });
        },
        clearData: function () {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.customTypeLookup.id = '';
            this.customTypeLookup.customTypeLookupId = '';
            this.customTypeLookup.customTypeLookupId = '';
            this.customTypeLookup.type = '';
            this.customTypeLookup.typeName = '';
            this.customTypeLookup.typeClass = '';
            this.customTypeLookup.shortName = '';
            this.customTypeLookup.description = '';
            this.customTypeLookup.userId = localStorage.getItem("userId");
            this.customTypeLookup.createdById = localStorage.getItem("userId");
            this.customTypeLookup.active = true;
            this.$v.customTypeLookup.$reset();
        },
        showEdit: function (data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.elements.showDetail = true;
            this.customTypeLookupDetail.id = data.id;
            this.customTypeLookupDetail.customTypeLookupId = data.customTypeLookupId;
            this.customTypeLookupDetail.entity = data.entity;
            this.customTypeLookupDetail.entityId = data.entityId;
            this.customTypeLookupDetail.typeName = data.typeName;
            this.customTypeLookupDetail.typeClass = data.typeClass;
            this.customTypeLookupDetail.projectId = data.projectId;
            this.customTypeLookupDetail.projectTypeId = data.projectTypeId;
            this.customTypeLookupDetail.customTypeLookupId = data.customTypeLookupId;
            this.customTypeLookupDetail.vhextAccountId = data.vhextAccountId;
            this.customTypeLookupDetail.type = data.type;
            this.customTypeLookupDetail.shortName = data.shortName;
            this.customTypeLookupDetail.description = data.description;
            this.customTypeLookupDetail.active = data.active;
            this.customTypeLookupDetail.createdById = data.createdById;
            this.customTypeLookupDetail.createdBy = data.createdBy;
            this.customTypeLookupDetail.createdDt = data.createdDt;
            this.customTypeLookupDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function (items) {
            if (items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.customTypeLookupDetail.id = data.id;
                this.customTypeLookupDetail.customTypeLookupId = data.customTypeLookupId;
                this.customTypeLookupDetail.entity = data.entity;
                this.customTypeLookupDetail.entityId = data.entityId;
                this.customTypeLookupDetail.userId = data.userId;
                this.customTypeLookupDetail.projectId = data.projectId;
                this.customTypeLookupDetail.projectTypeId = data.projectTypeId;
                this.customTypeLookupDetail.typeName = data.typeName;
                this.customTypeLookupDetail.typeClass = data.typeClass;
                this.customTypeLookupDetail.customTypeLookupId = data.customTypeLookupId;
                this.customTypeLookupDetail.vhextAccountId = data.vhextAccountId;
                this.customTypeLookupDetail.type = data.type;
                this.customTypeLookupDetail.shortName = data.shortName;
                this.customTypeLookupDetail.description = data.description;
                this.customTypeLookupDetail.active = data.active;
                this.customTypeLookupDetail.createdById = data.createdById;
                this.customTypeLookupDetail.createdBy = data.createdBy;
                this.customTypeLookupDetail.createdDt = data.createdDt;
                this.customTypeLookupDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function (item) {

            this.isBusy = true;
            let data = {
                id: item.id,
                entity: this.entity,
            }
            if (item.active == true) {
                data.active = 0;

                this.updateActivationState(data);

            } else if (item.active == false) {
                data.active = 1;

                this.updateActivationState(data);
            }

        },
        updateActivationState: function (data) {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.customTypeLookup.active = true;
                    this.getCustomTypeLookups();
                })
                .catch((error) => {
                    this.isBusy = false;
                })
        },


    }
}
</script>
