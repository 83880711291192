export default {
    data() {
        return {
            noticePeriodList: [
                {id: 1, value: 'No Notice Period'},
                {id: 2, value: 'One Week'},
                {id: 3, value: '15 Days'},
                {id: 4, value: '30+ Days'},
            ],
            performanceReviewPeriod: [
                {id: 1, value: 'Monthly'},
                {id: 2, value: 'Yearly'},
            ],
            empTimelineStateList: [
                {id: 1, value: 'Working', iconClass: 'fa-play', btnDisabled: false },
                {id: 2, value: 'Work Break', iconClass: 'fa-pause', btnDisabled: false },
                {id: 3, value: 'Working', iconClass: 'fa-play', btnDisabled: true },
                {id: 4, value: 'Work Stopped', iconClass: 'fa-stop', btnDisabled: false },
            ],
            mathOperatorEnumList: [
                // { id: 1, value: 'Plus' },
                // { id: 2, value: 'Minus' },
                // { id: 3, value: 'Multiply' },
                // { id: 4, value: 'Divide' },
                // { id: 5, value: 'Equals' },
                { id: 6, value: 'Lesser Than', symbol: '<' },
                { id: 7, value: 'Greater Than', symbol: '>' },
                { id: 8, value: 'Less Than Or Eqaul To', symbol: '≤' },
                { id: 9, value: 'Greater Than Or Equal To', symbol: '≥' },
            ],
            userSelectionTypeEnumList: [
                {id: 1, value: 'All Employees'},
                // {id: 2, value: 'Designation'},
                // {id: 3, value: 'Department'},
                {id: 4, value: 'Reporting To'},
                {id: 5, value: 'Selected Employees'},
            ],
            effortUOM: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],
        }
    },
    methods: {
        getStatusBySource(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case 'EMP':
                        output = value;
                        break;
                    case 'AST_EXP':
                        output = value;
                        break;
                    case 'ASSET':
                        output = value;
                        break;
                    case 'SERVICE':
                        output = value;
                        break;
                    case 'SRV_DUE':
                        output = value;
                        break;
                    case 'SRV_EXP':
                        output = value;
                        break;
                    case 'FINANCE':
                        output = value;
                        break;
                    case 'REV':
                        output = 'For Review';
                        break;
                    case 'APV':
                        output = 'For Approval';
                        break;
                    case 'SHARE':
                        output = value;
                        break;
                    case 'TASK':
                        output = value;
                        break;
                    case 'MTG_P':
                        output = value;
                        break;
                    case 'MTG':
                        output = value;
                        break;
                    case 'EMAIL':
                        output = value;
                        break;
                    case 'LVE':
                        output = value;
                        break;
                    case 'PER':
                        output = value;
                        break;
                    case 'COPY':
                        output = value;
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
    }
};