<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Deliverable</h4>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" class="d-block p-1 action-link" title="Existing Deliverable" @click="showDialog=true;" v-b-modal.existingDeliverableLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing Deliverable</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Deliverable</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="deliverable">Deliverable</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.deliverable.deliverable.$error }">
                                                                    <input type="text" v-model="deliverable.deliverable" maxlength="100" class="form-control" id="deliverable" placeholder="deliverable">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                            <label v-if="$v.deliverable.deliverable.$error && !$v.deliverable.deliverable.required" class="text-danger">Please Enter Deliverable</label>
                                                        <label v-if="$v.deliverable.deliverable.$error && !$v.deliverable.deliverable.maxLength" class="text-danger">Deliverable must not exceed {{$v.deliverable.deliverable.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
<!--                                                     <div v-if="!projectTypeId" class="col-5">
                                                        <div class="form-group">
                                                            <label for="projectTypeId">Project Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.activity.projectTypeId.$error }">                                                            
                                                                <select v-model="activity.projectTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.activity.projectTypeId.$error && !$v.activity.projectTypeId.required" class="text-danger">Please select Project Type</label>                                
                                                        </div>
                                                    </div>                                                                         -->                                                    
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="deliverableTypeId">Type</label>
                                                            <select v-model="deliverable.deliverableTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in deliverableTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="preparedById">Accountable Person</label>
                                                            <select v-model="deliverable.accountablePersonId" id="accountablePersonId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <select v-model="deliverable.statusId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="deliverable.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                        <textarea v-model="deliverable.description" class="form-control" maxlength="2000" rows="4" required></textarea>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small ref="deliverableTable" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="deliverableList" :busy="isBusy" @row-selected="onRowSelected" :fields="deliverableFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deleteDeliverable(data.item.id)" v-b-modal.deleteDeliverable><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Deliverable found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="deliverableList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-4">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ deliverableDetail.deliverableId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <b-input-group prepend="Status" class="mb-3">
                                                    <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="deliverableDetail.status"></b-form-input>
                                                    <b-dropdown v-if="elements.editMode" class="btn-dd" :text="deliverableDetail.status" v-model="deliverableDetail.statusId">
                                                        <b-dropdown-item v-for="(info, index)  in status" :key="info.id" :value="info.id">
                                                            {{info.value}}
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateDeliverable" :disabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-8">
                                                <div class="form-group">
                                                    <label for="deliverable">Deliverable</label>
                                                    <input type="text" class="form-control" id="deliverable" maxlength="100" :disabled="!elements.editMode" v-model='deliverableDetail.deliverable'>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="deliverableDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="description1">Description</label>
                                                    <textarea class="form-control" id="description1" rows="4" maxlength="2000" :disabled="!elements.editMode" v-model='deliverableDetail.description'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="type">Type</label>
                                                    <input v-if="!elements.editMode" type="type" class="form-control" id="type" disabled v-model='deliverableDetail.type'>
                                                    <select v-if="elements.editMode" v-model="deliverableDetail.deliverableTypeId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in deliverableTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div> 
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Accountable Person</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="deliverableDetail.accountablePerson" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="deliverableDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="deliverableDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Add Reference">
                                        <div class="row">
                                            <div class="col-6">
                                                <label for="budget">Functions</label>
                                                <select id="durationDays" class="form-control">
                                                    <option value="">Functions...</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                            <div class="col-6">
                                                <label for="budget" style="visibility: hidden;">Search</label>
                                                <div class="input-group ">
                                                    <input type="text" class="form-control" placeholder="Search">
                                                    <span class="input-group-text input-group-append input-group-addon">
                                                        <i class="fa fa-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label for="budget" class="mb-1">Reference Details</label>
                                                    <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                        <template v-slot:cell(title)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                            <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reference found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <label for="budget" class="mb-1">Objective Details</label>
                                                    <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                        <template v-slot:cell(title)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                            <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Objective found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddExistingDeliverablePopup v-if="showDialog" @refresh-lookup="getDeliverable" @closeDialog="showDialog=false;" />
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <DeleteComponent id="deleteDeliverable" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Deliverable ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import AddExistingDeliverablePopup from '@/components/popup/lookup/AddExistingDeliverablePopup.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        AddExistingDeliverablePopup,
        TypeLookup,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },

            isBusy: false,
            typeLookupTitle:'Deliverable Type Settings',

            vhextAccountId: '',
            projectId: '',
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: '',
            typeEntity: 'deliverable_type',
            userId: '',

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),


            deliverable: {
                id: '',
                deliverableId: '',
                entity: '' ,
                vhextAccountId: '',
                projectId: '' ,
                accountablePersonId: localStorage.getItem("userId"),
                deliverable: '',
                deliverableTypeId: '',
                type: '',
                description: '',
                active: true,
                statusId: '',
            
            },

            deliverableDetail: {
                id: '',
                deliverableId: '',
                deliverableTypeId: '',
                deliverable: '',
                type: '',
                status: '',
                hasReview: '',
                accountablePersonId: '',
                description: '',
                accountablePerson: '',
                active: '',
                statusId: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            projectTypes: [],
            users: [],
            status: [],
            deliverableTypes: [],
            deliverableList: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            deliverableFields: [
                { key: 'deliverableId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'deliverable', label: 'Deliverable', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Deliverable Type', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable Person', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.getDeliverable();
        this.lookupData();
    },
    validations: {
        deliverable: {
            deliverable: {
                required,
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = 'deliverable';
            this.userId = localStorage.getItem("userId");

            this.deliverable.vhextAccountId = this.vhextAccountId;
            this.deliverable.projectId = this.projectId;
            this.deliverable.entity = this.entity;
            this.deliverable.preparedById = this.userId;

        },

        getDeliverable: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }

            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.deliverableList && result.deliverableList.length > 0) {
                        this.deliverableList = result.deliverableList;
                        this.$nextTick(() => {
                            this.$refs.deliverableTable.selectRow(0);
                        })
                        this.totalRows = this.deliverableList.length;
                    }
                    console.log(result);
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.status = result.statusLookup; 
                    this.users = result.userLookup;                    
                    this.deliverableTypes = result.customTypeLookup;
                })

        },
        saveData: function() {
            this.$v.deliverable.$touch();
            console.log(this.deliverable);
            if (!this.$v.deliverable.$invalid) {
                this.saveDeliverable();
            }
        },
        saveDeliverable: function() {
            this.showOverlay = true;
            this.deliverable.vhextAccountId = this.vhextAccountId;
            this.deliverable.projectId = this.projectId;

            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/add', this.deliverable)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getDeliverable();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateDeliverable: function() {
            this.showDetOverlay = true;
            this.deliverableDetail.vhextAccountId = this.vhextAccountId;
            this.deliverableDetail.projectId = this.projectId;
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/update', this.deliverableDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDeliverable();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewDeliverable: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/common/deliverable/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let deliverableDetail = result.deliverable;
                    if (deliverableDetail) {                        
                        this.deliverableDetail.id = deliverableDetail.id;
                        this.deliverableDetail.deliverableId = deliverableDetail.deliverableId;
                        this.deliverableDetail.type = deliverableDetail.type;
                        this.deliverableDetail.description = deliverableDetail.description;
                        this.deliverableDetail.active = deliverableDetail.active;
                        this.deliverableDetail.status = deliverableDetail.status;
                        this.deliverableDetail.accountablePerson = deliverableDetail.accountablePerson;
                        this.deliverableDetail.createdDt = deliverableDetail.createdDt;
                        this.deliverableDetail.lastUpdatedDt = deliverableDetail.lastUpdatedDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewDeliverable(this.deliverableDetail.id);
        },
        deleteDeliverable: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/deliverable/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDeliverable();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.deliverable.deliverable = '';
            this.deliverable.deliverableTypeId = '';
            this.deliverable.statusId = '';
            this.deliverable.accountablePersonId = localStorage.getItem("userId");
            this.deliverable.active = true;
            this.deliverable.description = '';
            this.$v.deliverable.$reset();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.deliverableDetail.id = data.id;
                this.deliverableDetail.deliverable = data.deliverable;
                this.deliverableDetail.deliverableId = data.deliverableId;
                this.deliverableDetail.deliverableTypeId = data.deliverableTypeId;
                this.deliverableDetail.type = data.type;
                this.deliverableDetail.description = data.description;
                this.deliverableDetail.active = data.active;
                this.deliverableDetail.status = data.status;
                this.deliverableDetail.statusId = data.statusId;
                this.deliverableDetail.hasReview = data.hasReview;
                this.deliverableDetail.accountablePersonId = data.accountablePersonId;
                this.deliverableDetail.accountablePerson = data.accountablePerson;
                this.deliverableDetail.createdDt = data.createdDt;
                this.deliverableDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.deliverable.entity = this.entity;
                this.deliverable.id = item.id;
                this.deliverable.active = 0;

                this.updateActivationState();

            } else {
                this.deliverable.entity = this.entity;
                this.deliverable.id = item.id;
                this.deliverable.active = 1;

                this.updateActivationState();
            }
            console.log(this.deliverable.id, this.deliverable.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.deliverable)
                .then((response) => {
                    console.log(this.deliverable.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.deliverable.active = true;
                    this.getDeliverable();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error);
                    this.getDeliverable();
                })
        },
    }
}
</script>
