<template>
    <div class="planDetailPopup">
        <b-modal id="planDetailPopup" ref="planDetailRef" @hide="closeDialog" size="lg" modal-class="" title="Plan Details" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0">
                                    <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-10 col-sm-9 px-0">
                                        <div class="row mb-2">
                                            <div class="col-1 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.planId }}</label>
                                            </div>
                                            <div class="col-7 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.phaseTitle }}</label>
                                            </div>

                                            <div class="col-1 pr-0">
                                                <label class="mb-0 p-0 pt-2 pb-2 d-block title-block"><a title="Due Date"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></a></label>
                                            </div>
                                            <div class="col-3 pl-0 pr-0">
                                                <label class="p-2 mb-0 header-details font-weight-bold">{{ popupData.endDate | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Process</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.process}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.activity}}</label>
                                            </div>                                        
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Date</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.startDate}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">End Date</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.endDate}}</label>
                                            </div>                                        
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Deliverables</label>
                                            </div>
                                            <div class="col-4 pl-2">
                                                <span v-for="(info, index) in JSON.parse(popupData.deliverables)">
                                                    <span v-if="index != 0">, </span>
                                                    <span>{{ info.value }}</span>
                                                </span>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Resp. Person</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.responsibility}}</label>
                                            </div>                                        
                                        </div>
                                    </div>


                                <div class="col-12 col-sm-12 px-0 mb-2" v-if="popupData.description">
                                    <div class="card">
                                        <div class="card-body py-1 px-0">
                                            <p class="mb-2 pl-2 header-details font-weight-bold"><span>Plan Description</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: true,
            popupData: {},
            // timeSheets: [],

            // effortTypes: [
            //     { name: "Man Hours", id: 1 },
            //     { name: "Man Days", id: 2 },
            //     { name: "Man Months", id: 3 },
            //     { name: "Man Years", id: 4 },
            // ],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            try {                
                this.$bvModal.show('planDetailPopup');
                console.log(this.detailViewData);
                if (refEntity === "plan") {
                    axios.get(this.$store.getters.getAPIBasePath + '/plan/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.planView;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.loading = false;
                } 
            }
            catch(err) {
                console.log(err);
                this.loading = false;
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>
<style scoped>
span >>> p {
    margin: 0;
}
</style>