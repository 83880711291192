<template>
    <div class="type">
        <b-modal ref="addUserRoleStandardModal" size="lg" :id="id" centered modal-class="typeModal" title="Standard User Role" @hide="closeDialog" :no-close-on-backdrop="true"  ok-variant="secondary" ok-only ok-title="Close">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-card no-body class="mb-3">
                    <b-card-body class="p-3">
                        <div class="form-row">
                            <!-- <div class="col-sm-4 col-lg-4">
                                <div class="form-group">
                                    <label for="endDate">VMX Service</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.vmxServiceId.$error }">
                                        <select v-model="customUserRole.vmxServiceId" :class="{ 'is-invalid': $v.customUserRole.vmxServiceId.$error }" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in vmxServices" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.customUserRole.vmxServiceId.$error && !$v.customUserRole.vmxServiceId.required" class="text-danger">Please select a Service</label>
                                </div>
                            </div>
                            <div class="col-sm-4 col-lg-4">
                                <div class="form-group">
                                    <label for="startDate">User Role</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.customUserRole.$error }">
                                        <b-form-input id="title" v-model="customUserRole.customUserRole" :class="{ 'is-invalid': $v.customUserRole.customUserRole.$error }" placeholder="Enter User Role"></b-form-input>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.customUserRole.customUserRole.$error && !$v.customUserRole.customUserRole.required" class="text-danger">Please Enter User Role</label>
                                </div>
                            </div> -->
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label for="endDate">Standard User Role</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.userRoleData.userRoleId.$error }">
                                        <select v-model="customUserRole.userRoleData" @change="filterUserRoleModules" :class="{ 'is-invalid': $v.customUserRole.userRoleData.userRoleId.$error }" class="form-control">
                                            <option :value="{userRoleId: '', userRole: '', userTypeId: '', userType: ''}">Select</option>
                                            <option v-for="(info, index) in vmxUserRoles" :value="{userRoleId: info.id, userRole: info.value, userTypeId: info.userTypeId, userType: info.userType}">{{info.value}}</option>
                                        </select>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.customUserRole.userRoleData.userRoleId.$error && !$v.customUserRole.userRoleData.userRoleId.required" class="text-danger">Please Select Standard User Role</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6">
                                <div class="form-group">
                                    <label>User Type</label>
                                    <b-form-input disabled v-model="customUserRole.userRoleData.userType" ></b-form-input>
                                </div>
                            </div>
                            <div v-if="customUserRole.userRoleData.userRoleId" class="col-12">
                                <div class="form-group">
                                    <label for="">Modules</label>
                                    <multiselect v-model="customUserRole.moduleList" :options="stdUserModules" name="stdUserModule" label="value" track-by="id" select-label="" disabled deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                </div>
                            </div>                                    
                            <div class="col-sm-12 col-lg-12">
                                <div class="form-group">

                                    <button type="submit" @click="saveCustomUserRoleData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </b-card-body>
                </b-card>
                <!-- <div class="float-right">
                    <div class="dataTables_length">
                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </div>
                </div> -->
                <!-- <b-table outlined show-empty small stacked="md" sort-icon-left :items="customUserRoles" :fields="customUserRoleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>                        
                    <template v-slot:cell(userRole)="data">
                        <div v-if="data.value">{{ data.value }}</div>
                        <div v-else class=" text-muted">No Reference</div>
                    </template>                        
                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="data">
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No User Roles found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                <b-pagination v-if="customUserRoles && customUserRoles.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                <!-- </div> -->
            </b-overlay>
        </b-modal>
        <!-- <DeleteComponent id="removeCustomUserRole" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Role ?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'addUserRoleStandardModal' },
        userRoleTypeId: Number,
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'custom_user_role',
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,

            stdModuleDisable: false,

            customUserRole: {
                id: '',
                customUserRoleId: '',
                userRoleTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userRoleId: '',
                userRoleData: {
                    userRole: '',
                    userRoleId: '',
                    userTypeId: '',
                    userType: '',
                },
                vmxServiceId: '',
                userType: '',
                customUserRole: '',
                typeCode: '',
                moduleList: [],
                isStandard: false,
                active: true,
                actionState: '',
                createdById: localStorage.getItem("userId"),
            },
            vmxUserRoles: [],
            vmxServices: [],
            stdUserModules: [],
            stdUserModuleList: [],
            customUserRoles: [],
            customUserRoleFields: [
                { key: 'index', label: 'SN', },
                // { key: 'customUserRoleId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'customUserRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'userRole', label: 'Std. User Role Reference', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        customUserRoleList() {
            return this.customUserRoles;
        }
    },
    validations: {
        customUserRole: {
            userRoleData: {
                userRoleId: {
                    required
                },
            },
        }
    },
    mounted() {
        this.getCustomUserRoleList();
        this.lookupData();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userRoleTypeId: this.userRoleTypeId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.vmxUserRoles = result.vmxAccountUserRoleLookup;
                    this.stdUserModules = result.stdUserModuleLookup;
                    this.stdUserModuleList = result.stdUserModuleList;
                    
                })
        },
        getCustomUserRoleList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userRoleTypeId: this.userRoleTypeId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.customUserRoles = result.customUserRoleList;
                    this.totalRows = this.customUserRoles.length;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        getCustomUserModuleLookup: function(customUserRoleId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                customUserRoleId: customUserRoleId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customusermodule/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.customUserRole.moduleList = result.customUserModuleList;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        filterUserRoleModules(){
            console.log(this.customUserRole.userRoleData.userRoleId);
            if(this.customUserRole.userRoleData.userRoleId){
                this.customUserRole.moduleList = this.stdUserModuleList.filter(data => data.userRoleId == this.customUserRole.userRoleData.userRoleId); 
                this.stdModuleDisable = true;
            }else{
                this.customUserRole.moduleList = [];
                this.stdModuleDisable = false;
            }
            
        },
        saveCustomUserRoleData: function() {
            this.customUserRole.userRoleTypeId = this.userRoleTypeId;
            this.$v.customUserRole.$touch();
            if (!this.$v.customUserRole.$invalid) {
                this.customUserRole.customUserRole = this.customUserRole.userRoleData.userRole;
                this.customUserRole.userRoleId = this.customUserRole.userRoleData.userRoleId;
                this.customUserRole.userTypeId = this.customUserRole.userRoleData.userTypeId;
                if (!this.matchTextValExists(this.customUserRole.customUserRole, this.customUserRole.id)) {
                    if (this.elements.editMode) {
                        this.updateCustomUserRoleLookup();
                    } else {
                        this.addCustomUserRoleLookup();
                    }
                }
                else {
                    iziToast.info({
                        message: 'User Role already exists',
                        position: 'topCenter'
                    });
                }
            }
        },
        addCustomUserRoleLookup: function() {
            this.showOverlay = true;
            this.customUserRole.actionState = 1;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/add', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateCustomUserRoleLookup: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/update', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        // this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeCustomUserRole: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/user/customuserrole/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        // this.getCustomUserRoleList();
                    }
                })
                .catch(error => {
                    // console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.customUserRole.id = '';
            this.customUserRole.customUserRoleId = '';
            this.customUserRole.userRoleTypeId = '';
            this.customUserRole.vmxServiceId = '';
            this.customUserRole.userRoleId = '';
            this.customUserRole.userRoleData.userRole = '';
            this.customUserRole.userRoleData.userRoleId = '';
            this.customUserRole.userRoleData.userTypeId = '';
            this.customUserRole.userRoleData.userType = '';
            this.customUserRole.userType = '';
            this.customUserRole.customUserRole = '';
            this.customUserRole.moduleList = [];
            this.customUserRole.typeCode = '';
            this.customUserRole.isStandard = false;
            this.customUserRole.active = true;
            this.$v.customUserRole.$reset();
            // this.$bvModal.hide(this.id);
        },
        // showUserRoleEdit: function(items) {
        //     let data = items;
        //     this.elements.visible = true;
        //     this.elements.editMode = true;
        //     this.customUserRole.id = data.id;
        //     this.customUserRole.customUserRoleId = data.customUserRoleId;
        //     this.customUserRole.vmxServiceId = data.vmxServiceId;
        //     this.customUserRole.vhextAccountId = data.vhextAccountId;
        //     this.customUserRole.userRoleId = data.userRoleId;
        //     this.customUserRole.userType = data.userType;
        //     this.customUserRole.customUserRole = data.customUserRole;
        //     this.customUserRole.typeCode = data.typeCode;
        //     this.customUserRole.isStandard = data.isStandard;
        //     this.customUserRole.active = data.active;
        //     this.customUserRole.createdById = data.createdById;

        //     this.getCustomUserModuleLookup(this.customUserRole.id);
        // },
        assignActivationState: function(item) {
            this.showOverlay = true;
            if (item.active == true) {
                this.customUserRole.id = item.id;
                this.customUserRole.active = 0;
                this.customUserRole.entity = this.entity;
                this.updateActivationState();
            } else {
                this.customUserRole.id = item.id;
                this.customUserRole.active = 1;
                this.customUserRole.entity = this.entity;
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        // this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.customUserRoleList.map(data => {
                if (textVal && data.customUserRole && data.id !== id) {
                    if (data.customUserRole.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },

        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        }

    }
}
</script>