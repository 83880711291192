import Checklist from '@/views/quality/Checklist.vue'
import ChecklistMaster from '@/views/quality/ChecklistMaster.vue'
import ChecklistMasterList from '@/views/quality/ChecklistMasterList.vue'
import ChecklistReport from '@/views/quality/ChecklistReport.vue'
import FeedbackForm from '@/views/quality/FeedbackForm.vue'
import FeedbackFormMaster from '@/views/quality/FeedbackFormMaster.vue'
import FeedbackFormMasterList from '@/views/quality/FeedbackFormMasterList.vue'
import FeedbackDetail from '@/views/quality/FeedbackDetail.vue'
import FeedbackDetailMaster from '@/views/quality/FeedbackDetailMaster.vue'
import FeedbackDetailMasterList from '@/views/quality/FeedbackDetailMasterList.vue'
import DefectPreventionPlan from '@/views/quality/DefectPreventionPlan.vue'
import DefectPreventionPlanMaster from '@/views/quality/DefectPreventionPlanMaster.vue'
import DefectPreventionPlanMasterList from '@/views/quality/DefectPreventionPlanMasterList.vue'
import ProjectStandard from '@/views/quality/ProjectStandard.vue'
import ProjectStandardMaster from '@/views/quality/ProjectStandardMaster.vue'
import ProjectStandardMasterList from '@/views/quality/ProjectStandardMasterList.vue'
import ReviewPlan from '@/views/review/ReviewPlan.vue'
import QualityAssurancePlan from '@/views/quality/QualityAssurancePlan.vue'
import QualityAssurancePlanMaster from '@/views/quality/QualityAssurancePlanMaster.vue'
import QualityAssurancePlanMasterList from '@/views/quality/QualityAssurancePlanMasterList.vue'
import TestPlanMaster from '@/views/test/TestPlanMaster.vue'
import TestPlanMasterList from '@/views/test/TestPlanMasterList.vue'
import TestPlan from '@/views/test/TestPlan.vue'

export default [
    {
         path: '/quality/checklist',
        name: 'checklist',
        component: Checklist,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/cklmas',
        name: 'cklmas',
        component: ChecklistMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/cklmas/list',
        name: 'cklmaslist',
        component: ChecklistMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/review/checklistreport',
        name: 'checklistreport',
        component: ChecklistReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {
        path: '/quality/defectpreventionplan/:ermId',
        name: 'defectpreventionplan',
        component: DefectPreventionPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/dppmas',
        name: 'dppmas',
        component: DefectPreventionPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/dppmas/list',
        name: 'dppmaslist',
        component: DefectPreventionPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fbdmas',
        name: 'fbdmas',
        component: FeedbackDetailMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fbdmas/list',
        name: 'fbdmaslist',
        component: FeedbackDetailMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/clifdb/:ermId',
        name: 'clifdb',
        component: FeedbackDetail,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
         path: '/quality/feedbackform/:ermId',
        name: 'feedbackform',
        component: FeedbackForm,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fbfmas',
        name: 'fbfmas',
        component: FeedbackFormMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fbfmas/list',
        name: 'fbfmaslist',
        component: FeedbackFormMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {
        path: '/quality/projectstandard/:ermId',
        name: 'projectstandard',
        component: ProjectStandard,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prsmas',
        name: 'prsmas',
        component: ProjectStandardMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prsmas/list',
        name: 'prsmaslist',
        component: ProjectStandardMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/qualityassuranceplan/:ermId',
        name: 'qualityassuranceplan',
        component: QualityAssurancePlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/qapmas',
        name: 'qapmas',
        component: QualityAssurancePlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/qapmas/list',
        name: 'qapmaslist',
        component: QualityAssurancePlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/reviewplan/:ermId',
        name: 'reviewplan',
        component: ReviewPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {   
        path: '/testplan/:ermId',
        name: 'testplan',
        component: TestPlan,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tplmas',
        name: 'tplmas',
        component: TestPlanMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tplmas/list',
        name: 'tplmaslist',
        component: TestPlanMasterList,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },


];