import ReleaseList from '@/views/releaselist/ReleaseList.vue'
import ReleaseListMaster from '@/views/releaselist/ReleaseListMaster.vue'
import ReleaseListMasterList from '@/views/releaselist/ReleaseListMasterList.vue'


export default [{

        path: '/releaselist/:ermId',
        name: 'releaselist',
        component: ReleaseList,
        meta: { accessedby: 'CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rllmas',
        name: 'rllmas',
        component: ReleaseListMaster,
        meta: { accessedby: 'CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rllmas/list',
        name: 'rllmaslist',
        component: ReleaseListMasterList,
        meta: { accessedby: 'CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

];