<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-2 col-sm-2 pr-0 mr-0 mt-2">
                            <h4 class="pl-2 mb-0 fs-16">{{showTreeView ? 'Employee Hierarchy' : 'Departments'}}</h4>
                        </div>
                        <div class="col-sm-7 col-md-7 col-lg-7">
                            <b-button v-if="!isEmpty" class="addnew-tassk-btn float-right top-header-btn" :variant="showTreeView ? 'outline-danger' : 'outline-info'" :class="{'active' : showTreeView}" @click="showTreeView=!showTreeView" size="sm"><i class="fa pr-1" :class="showTreeView ? 'fa-close' : 'fa-sitemap'"></i>{{showTreeView ? 'Hide' : 'Show'}} Employee Hierarchy</b-button>
                            <b-button v-if="!isEmpty" class="addnew-tassk-btn float-right top-header-btn mr-2" variant="outline-info" :title="departmentLookupTitle" v-b-modal.departmentModal size="sm"><i class="fa fa-plus pr-1"></i>Create Department</b-button>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="redirectPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div v-if="!showTreeView" class="row">
                    <div v-if="isEmpty" class="col-12">
                        <div class="taskLists">                        
                            <div class="card-media">
                                <div class="card-media-body text-center w-100">
                                    <h6>No Departments found</h6>
                                    <b-button class="addnew-tassk-btn" variant="outline-info" :title="departmentLookupTitle" v-b-modal.departmentModal size="sm"><i class="fa fa-plus pr-1"></i>Create Department</b-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-12 col-xl-12 pr-2 position-relative">
                        <div class="row mr-0 pl-2">
                            <div v-for="departmentData in departmentList" class="col-12 mb-1 px-2" :class="depListCols">
                                <div class="card mb-2 b-radius-5">
                                    <div class="card-body px-3 py-2">
                                        <div class="form-group mb-0">
                                            <label class="mt-1 mb-0 font-weight-bold" for="title">
                                                {{ departmentData.value }}
                                                <b-badge v-if="getEmployeesByDepartmentId(departmentData.id).length>0" class="ml-2" title="Employees">{{ getEmployeesByDepartmentId(departmentData.id).length }}</b-badge>
                                            </label>
                                            <b-button class="pull-right addnew-quicktask-btn" @click="openDptChangePop(departmentData)" variant="outline-info" size="sm">
                                                <i class="fa fa-user-plus pr-1"></i>Employee
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="taskLists">
                                    <div class="row">
                                        <div :class="empCols" v-for="employee in getEmployeesByDepartmentId(departmentData.id)">
                                            <div class="card-media mb-2">
                                                <div class="card-media-body dropdown show">
                                                    <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v px-1 pb-1 pt-0"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary p-0" aria-labelledby="dropdownMenuLink">
                                                        <a class="dropdown-item  my-0 py-2" @click="viewItemDetails(employee, departmentData.title)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>View Details</a>
                                                        <a class="dropdown-item p-0" @click="" href="javascript:">
                                                            <b-dropdown dropright variant="link" toggle-class="text-decoration-none" no-caret>
                                                                <template #button-content>
                                                                  <i class="fa fa-exchange pr-2"></i>Change Department
                                                                </template>
                                                                <b-dropdown-item v-for="department in departmentList" :key="department.id" href="javascript:" :disabled="department.value == departmentData.value" :class="{'dd-active' : department.value == departmentData.value}" @click="setEmpDepartment(employee.id, department.id, employee.name)" v-b-modal.updateEmpDepartment>{{ department.value }}</b-dropdown-item>
                                                            </b-dropdown>
                                                        </a>
                                                        <a class="dropdown-item py-2" @click="setEmpDepartment(employee.id, null, employee.name)" v-b-modal.removeEmployee href="javascript:"><i class="fa fa-trash pr-2"></i>Remove</a>
                                                    </div>
                                                    <div class="card-media-body-top row d-flex align-items-center">
                                                        <div class="col-auto pl-0 pr-1 mx-0">
                                                            <b-avatar variant="info" :src="employee.photo" size="lg" class="d-flex mx-0 px-0"></b-avatar>
                                                            <!-- <b-avatar v-else-if="employee.name" variant="info" :text="employee.name | getAcronym" class="d-flex mx-0 px-0"></b-avatar> -->
                                                        </div>
                                                        <div class="col-auto pl-0">
                                                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(employee, departmentData.title)">{{ employee.name }}</a>
                                                            <div title="Position" class="subtle">{{ employee.designation }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div v-if="!getEmployeesByDepartmentId(departmentData.id).length" class="card-media mb-2">
                                        <div class="card-media-body">
                                            <div class="stage-card text-center">No Employees found</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-12">
                        <div class="tree card tree-view-card">
                            <ul class="h-100">
                                <li>
                                    <a href="javascript:">
                                        <span v-if="companyName">{{ companyName }}</span>
                                        <span v-else><i class="fa fa-user-circle-o fa-2x action-link"></i></span>
                                    </a>
                                    <ul v-if="parentList.length>0">
                                        <li v-for="child1 in parentList" :key="child1.id">
                                            <a href="javascript:">
                                                <span>
                                                    <b-avatar variant="info" size="lg" :src="child1.photo" class="mx-0 px-0"></b-avatar>
                                                    <!-- <b-avatar v-else-if="child1.name" variant="info" :text="child1.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                </span>
                                                <span class="d-block">{{ child1.name }}</span>
                                                <span class="d-block text-muted">{{ child1.department }}<span v-if="child1.department && child1.designation">, </span>{{ child1.designation }}</span>
                                            </a>
                                            <ul v-if="reportingToChildList(child1.userId).length>0">
                                                <li v-for="child2 in reportingToChildList(child1.userId)" :key="child2.id">
                                                    <a href="javascript:">
                                                        <span>
                                                            <b-avatar variant="info" size="lg" :src="child2.photo" class="mx-0 px-0"></b-avatar>
                                                            <!-- <b-avatar v-else-if="child2.name" variant="info" :text="child2.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                        </span>
                                                        <span class="d-block">{{ child2.name }}</span>
                                                        <span class="d-block text-muted">{{ child2.department }}<span v-if="child2.department && child2.designation">, </span>{{ child2.designation }}</span>
                                                    </a>
                                                    <ul v-if="reportingToChildList(child2.userId).length>0">
                                                        <li v-for="child3 in reportingToChildList(child2.userId)" :key="child3.id">
                                                            <a href="javascript:">
                                                                <span>
                                                                    <b-avatar variant="info" size="lg" :src="child3.photo" class="mx-0 px-0"></b-avatar>
                                                                    <!-- <b-avatar v-else-if="child3.name" variant="info" :text="child3.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                </span>
                                                                <span class="d-block">{{ child3.name }}</span>
                                                                <span class="d-block text-muted">{{ child3.department }}<span v-if="child3.department && child3.designation">, </span>{{ child3.designation }}</span>
                                                            </a>
                                                            <ul v-if="reportingToChildList(child3.userId).length>0">
                                                                <li v-for="child4 in reportingToChildList(child3.userId)" :key="child4.id">
                                                                    <a href="javascript:">
                                                                        <span>
                                                                            <b-avatar variant="info"size="lg" :src="child4.photo" class="mx-0 px-0"></b-avatar>
                                                                            <!-- <b-avatar v-else-if="child4.name" variant="info" :text="child4.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                        </span>
                                                                        <span class="d-block">{{ child4.name }}</span>
                                                                        <span class="d-block text-muted">{{ child4.department }}<span v-if="child4.department && child4.designation">, </span>{{ child4.designation }}</span>
                                                                    </a>
                                                                    <ul v-if="reportingToChildList(child4.userId).length>0">
                                                                        <li v-for="child5 in reportingToChildList(child4.userId)" :key="child5.id">
                                                                            <a href="javascript:">
                                                                                <span>
                                                                                    <b-avatar variant="info" size="lg" :src="child5.photo" class="mx-0 px-0"></b-avatar>
                                                                                    <!-- <b-avatar v-else-if="child5.name" variant="info" :text="child5.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                </span>
                                                                                <span class="d-block">{{ child5.name }}</span>
                                                                                    <span class="d-block text-muted">{{ child5.department }}<span v-if="child5.department && child5.designation">, </span>{{ child5.designation }}</span>
                                                                                </a>
                                                                            <ul v-if="reportingToChildList(child5.userId).length>0">
                                                                                <li v-for="child6 in reportingToChildList(child5.userId)" :key="child6.id">
                                                                                    <a href="javascript:">
                                                                                        <span>
                                                                                            <b-avatar variant="info" size="lg" :src="child6.photo" class="mx-0 px-0"></b-avatar>
                                                                                            <!-- <b-avatar v-else-if="child6.name" variant="info" :text="child6.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                        </span>
                                                                                        <span class="d-block">{{ child6.name }}</span>
                                                                                        <span class="d-block text-muted">{{ child6.department }}<span v-if="child6.department && child6.designation">, </span>{{ child6.designation }}</span>
                                                                                    </a>
                                                                                    <ul v-if="reportingToChildList(child6.userId).length>0">
                                                                                        <li v-for="child7 in reportingToChildList(child6.userId)" :key="child7.id">
                                                                                            <a href="javascript:">
                                                                                                <span>
                                                                                                    <b-avatar variant="info" size="lg" :src="child7.photo" class="mx-0 px-0"></b-avatar>
                                                                                                    <!-- <b-avatar v-else-if="child7.name" variant="info" :text="child7.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                </span>
                                                                                                <span class="d-block">{{ child7.name }}</span>
                                                                                                <span class="d-block text-muted">{{ child7.department }}<span v-if="child7.department && child7.designation">, </span>{{ child7.designation }}</span>
                                                                                            </a>
                                                                                            <ul v-if="reportingToChildList(child7.userId).length>0">
                                                                                                <li v-for="child8 in reportingToChildList(child7.userId)" :key="child8.id">
                                                                                                    <a href="javascript:">
                                                                                                        <span>
                                                                                                            <b-avatar variant="info" size="lg" :src="child8.photo" class="mx-0 px-0"></b-avatar>
                                                                                                            <!-- <b-avatar v-else-if="child8.name" variant="info" :text="child8.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                        </span>
                                                                                                        <span class="d-block">{{ child8.name }}</span>
                                                                                                        <span class="d-block text-muted">{{ child8.department }}<span v-if="child8.department && child8.designation">, </span>{{ child8.designation }}</span>
                                                                                                    </a>
                                                                                                    <ul v-if="reportingToChildList(child8.userId).length>0">
                                                                                                        <li v-for="child9 in reportingToChildList(child8.userId)" :key="child9.id">
                                                                                                            <a href="javascript:">
                                                                                                                <span>
                                                                                                                    <b-avatar variant="info" size="lg" :src="child9.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                    <!-- <b-avatar v-else-if="child9.name" variant="info" :text="child9.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                </span>
                                                                                                                <span class="d-block">{{ child9.name }}</span>
                                                                                                                <span class="d-block text-muted">{{ child9.department }}<span v-if="child9.department && child9.designation">, </span>{{ child9.designation }}</span>
                                                                                                            </a>
                                                                                                            <ul v-if="reportingToChildList(child9.userId).length>0">
                                                                                                                <li v-for="child10 in reportingToChildList(child9.userId)" :key="child10.id">
                                                                                                                    <a href="javascript:">
                                                                                                                        <span>
                                                                                                                            <b-avatar variant="info" :src="child10.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                            <!-- <b-avatar v-else-if="child10.name" variant="info" :text="child10.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                        </span>
                                                                                                                        <span class="d-block">{{ child10.name }}</span>
                                                                                                                        <span class="d-block text-muted">{{ child10.department }}<span v-if="child10.department && child10.designation">, </span>{{ child10.designation }}</span>
                                                                                                                    </a>
                                                                                                                    <ul v-if="reportingToChildList(child10.userId).length>0">
                                                                                                                        <li v-for="child11 in reportingToChildList(child10.userId)" :key="child11.id">
                                                                                                                            <a href="javascript:">
                                                                                                                                <span>
                                                                                                                                    <b-avatar variant="info" :src="child11.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                    <!-- <b-avatar v-else-if="child11.name" variant="info" :text="child11.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                </span>
                                                                                                                                <span class="d-block">{{ child11.name }}</span>
                                                                                                                                <span class="d-block text-muted">{{ child11.department }}<span v-if="child11.department && child11.designation">, </span>{{ child11.designation }}</span>
                                                                                                                            </a>
                                                                                                                            <ul v-if="reportingToChildList(child11.userId).length>0">
                                                                                                                                <li v-for="child12 in reportingToChildList(child11.userId)" :key="child12.id">
                                                                                                                                    <a href="javascript:">
                                                                                                                                        <span>
                                                                                                                                            <b-avatar variant="info" :src="child12.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                            <!-- <b-avatar v-else-if="child12.name" variant="info" :text="child12.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                        </span>
                                                                                                                                        <span class="d-block">{{ child12.name }}</span>
                                                                                                                                        <span class="d-block text-muted">{{ child12.department }}<span v-if="child12.department && child12.designation">, </span>{{ child12.designation }}</span>
                                                                                                                                    </a>
                                                                                                                                    <ul v-if="reportingToChildList(child12.userId).length>0">
                                                                                                                                        <li v-for="child13 in reportingToChildList(child12.userId)" :key="child13.id">
                                                                                                                                            <a href="javascript:">
                                                                                                                                                <span>
                                                                                                                                                    <b-avatar variant="info" :src="child13.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                                    <!-- <b-avatar v-else-if="child13.name" variant="info" :text="child13.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                                </span>
                                                                                                                                                <span class="d-block">{{ child13.name }}</span>
                                                                                                                                                <span class="d-block text-muted">{{ child13.department }}<span v-if="child13.department && child13.designation">, </span>{{ child13.designation }}</span>
                                                                                                                                            </a>
                                                                                                                                            <ul v-if="reportingToChildList(child13.userId).length>0">
                                                                                                                                                <li v-for="child14 in reportingToChildList(child13.userId)" :key="child14.id">
                                                                                                                                                    <a href="javascript:">
                                                                                                                                                        <span>
                                                                                                                                                            <b-avatar v-if="child14.photo" variant="info" :src="child14.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                                            <!-- <b-avatar v-else-if="child14.name" variant="info" :text="child14.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                                        </span>
                                                                                                                                                        <span class="d-block">{{ child14.name }}</span>
                                                                                                                                                        <span class="d-block text-muted">{{ child14.department }}<span v-if="child14.department && child14.designation">, </span>{{ child14.designation }}</span>
                                                                                                                                                    </a>
                                                                                                                                                    <ul v-if="reportingToChildList(child14.userId).length>0">
                                                                                                                                                        <li v-for="child15 in reportingToChildList(child14.userId)" :key="child15.id">
                                                                                                                                                            <a href="javascript:">
                                                                                                                                                                <span>
                                                                                                                                                                    <b-avatar variant="info" :src="child15.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                                                    <!-- <b-avatar v-else-if="child15.name" variant="info" :text="child15.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                                                </span>
                                                                                                                                                                <span class="d-block">{{ child15.name }}</span>
                                                                                                                                                                <span class="d-block text-muted">{{ child15.department }}<span v-if="child15.department && child15.designation">, </span>{{ child15.designation }}</span>
                                                                                                                                                            </a>
                                                                                                                                                            <ul v-if="reportingToChildList(child15.userId).length>0">
                                                                                                                                                                <li v-for="child16 in reportingToChildList(child15.userId)" :key="child16.id">
                                                                                                                                                                    <a href="javascript:">
                                                                                                                                                                        <span>
                                                                                                                                                                            <b-avatar v-if="child16.photo" variant="info" :src="child16.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                                                            <!-- <b-avatar v-else-if="child16.name" variant="info" :text="child16.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                                                        </span>
                                                                                                                                                                        <span class="d-block">{{ child16.name }}</span>
                                                                                                                                                                        <span class="d-block text-muted">{{ child16.department }}<span v-if="child16.department && child16.designation">, </span>{{ child16.designation }}</span>
                                                                                                                                                                    </a>
                                                                                                                                                                    <ul v-if="reportingToChildList(child16.userId).length>0">
                                                                                                                                                                        <li v-for="child17 in reportingToChildList(child16.userId)" :key="child17.id">
                                                                                                                                                                            <a href="javascript:">
                                                                                                                                                                                <span>
                                                                                                                                                                                    <b-avatar v-if="child17.photo" variant="info" :src="child17.photo" class="mx-0 px-0"></b-avatar>
                                                                                                                                                                                    <!-- <b-avatar v-else-if="child17.name" variant="info" :text="child17.name | getAcronym" class="mx-0 px-0"></b-avatar> -->
                                                                                                                                                                                </span>
                                                                                                                                                                                <span class="d-block">{{ child17.name }}</span>
                                                                                                                                                                                <span class="d-block text-muted">{{ child17.department }}<span v-if="child17.department && child17.designation">, </span>{{ child17.designation }}</span>
                                                                                                                                                                            </a>
                                                                                                                                                                        </li>
                                                                                                                                                                    </ul>
                                                                                                                                                                </li>
                                                                                                                                                            </ul>
                                                                                                                                                        </li>
                                                                                                                                                    </ul>
                                                                                                                                                </li>
                                                                                                                                            </ul>
                                                                                                                                        </li>
                                                                                                                                    </ul>
                                                                                                                                </li>
                                                                                                                            </ul>
                                                                                                                        </li>
                                                                                                                    </ul>
                                                                                                                </li>
                                                                                                            </ul>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </li>
                                                                                    </ul>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <DeleteComponent id="removeEmployee" :onYes="updateEmpDepartment" :returnParams="updateParam" title="Remove Employee" :message="`Are you sure to remove ${empToDelete} from this Department?`" />
        <DeleteComponent id="updateEmpDepartment" :onYes="updateEmpDepartment" :returnParams="updateParam" title="Department Change" :message="`Are you sure to change Department?`" />
        <AddDepartmentLookup id="departmentModal" ref="departmentModal" :departmentLookupTitle="departmentLookupTitle" @refresh-lookup="getDepartments" />
        <EmpDepartmentChangePopup :key="empDptChangePopupKey" :departmentId="departmentId" @refresh-lookup="getEmployeeList" />
        <EmployeeDetailPopup ref="employeeDetailRef" :detailViewData="detailViewData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import EmployeeDetailPopup from '@/components/popup/itemdetail/EmployeeDetailPopup.vue';
import AddDepartmentLookup from '@/components/popup/lookup/AddDepartmentLookup.vue';
import EmpDepartmentChangePopup from '@/components/popup/action/EmpDepartmentChangePopup.vue';

export default {
    name: 'app',
    components: {
        EmployeeDetailPopup,
        AddDepartmentLookup,
        EmpDepartmentChangePopup,
    },
    data() {
        return {
            isEmpty: false,
            showTreeView: false,
            userId: localStorage.getItem("userId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),

            showOverlay: false,
            showDialog: false,
            detailViewData: {},

            departmentLookupTitle: 'Departments',
            empDptChangePopupKey: 0,
            departmentId: '',

            employee: {
                editMode: null,
                id: '',
                projectTeamId: '',
                userId:'',
                userTypeId:'',
                userType:'',
                userTypeCode:'',
                memberTypeId:'',
                memberTypeCode:'TMB',
                memberId:'',
                memberName: '',
                member: '',
                employeeCode: '',
                humanResourceId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                qualification: '',
                roleResponsibility: '',
                technologySkill: '',
                preparedBy: '',
                qualification: '',
                experienceYears: '',
                experienceMonths: '',
                requiredFrom: '',
                requiredTill: '',
                status: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false,
                jobTitle:"",
                resourcePlanId:"",
                ermTitle:""
            },

            departmentEmployees: [],
            departmentList: [],

            empToDelete: '',
            updateParam: {
                id: 0,
                departmentId: null,
            },



            // parentList: [
            //     {id: 1, name: 'Parent 1',},
            //     {id: 2, name: 'Parent 2',},
            // ],

            childList: [
                {id: 1, name: 'Parent 1',},
                {id: 2, name: 'Parent 2',},
            ],
        }
    },
    computed: {
        depListCols() {
            if (this.departmentList && this.departmentList.length) {

                if (this.departmentList.length > 4 && this.departmentList.length < 6) {
                    return 'col-lg col-sm-6'
                }
                if (this.departmentList.length > 6) {
                    return 'col-lg-2 col-sm-6'
                }
                if (this.departmentList.length == 4) {
                    return 'col-lg-3 col-sm-6'
                }
                if (this.departmentList.length == 3) {
                    return 'col-md-4'
                }
                if (this.departmentList.length == 2) {
                    return 'col-sm-6'
                }
                if (this.departmentList.length == 1) {
                    return 'col-md-12'
                }
            }
        },
        empCols() {
            if (this.departmentList && this.departmentList.length) {

                if (this.departmentList.length > 2) {
                    return 'col-xl-12'
                }
                if (this.departmentList.length == 2) {
                    return 'col-xl-6'
                }
                if (this.departmentList.length == 1) {
                    return 'col-xl-3 col-lg-4 col-sm-6'
                }
            }
        },
        companyName() {
            return this.departmentEmployees.length>0 ? this.departmentEmployees[0].companyName : null;
        },
        parentList() {
            return this.departmentEmployees.filter(data => data.reportingToId == null || data.reportingToId == '');
        }
    },
    mounted() {
        this.getEmployeeList();
        this.getDepartments();
    },
    methods: {
        getEmployeeList: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/hremployee/hierarchy/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.departmentEmployees = result.hrEmployeeHierarchyList;
                })
        },
        getDepartments: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/department', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.isEmpty = result.departmentLookup.length == 0 ? true : false;                      
                    this.departmentList = result.departmentLookup;
                })
        },
        setEmpDepartment: function(id, departmentId, name) {
            this.empToDelete = name;
            this.updateParam.id = id;
            this.updateParam.departmentId = departmentId;
        },
        updateEmpDepartment: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employee/department/update', this.updateParam)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEmployeeList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        reportingToChildList(reportingToId) {
            if (reportingToId) {
                return this.departmentEmployees.filter(data => data.reportingToId == reportingToId);
            }
            else {
                return [];
            }
        },
        getEmployeesByDepartmentId(departmentId) {
            return this.departmentEmployees.filter(data => data.departmentId == departmentId);
        },
        viewItemDetails: function(data, department) {
            this.detailViewData = data;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.employeeDetailRef.getDetails(department);
            })
        },
        closeDialog: function() {
            this.showDialog = false;
        },
        openDptChangePop(data) {
            this.empDptChangePopupKey++;
            this.departmentId = data.id;
            this.$nextTick(()=> {
                this.$bvModal.show('empDepartmentChangePopup');
            })
        },
        redirectPage: function() {
            this.$router.push('/landing/dept');
        },
    }
}
</script>