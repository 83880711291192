<template>
    <div>
        <b-modal scrollable ref="attachmentModal" size="md" id="attachmentModal" modal-class="userModal" title="View Attachment" :no-close-on-backdrop="true" ok-title="Ok">
            <b-container fluid>
                <b-row class="mb-2">
                    <b-col v-show="attachments.length != 0" cols="12 border">
                        <p class="my-2" v-for="(attachment, index) in attachments">
                            <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                            <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                            <!-- <a href="javascript:" data-toggle="modal" data-target="#deleteAttachment" @click="deleteFile(attachment.id)" title="Delete attachment" class="float-right button-remove px-2 attachments-remove">
                                <i class="fa fa-trash pr-2 action-link"></i>
                            </a> -->
                        </p>
                    </b-col>
                    <b-col v-show="attachments.length == 0" cols="12 border">
                        No Attachments found
                    </b-col>
                </b-row>
            </b-container>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        refRecordId: Number,
        entity: String
    },
    components: {

    },
    data() {
        return {
            attachments: []
        }
    },
    watch: {
        'refRecordId': {
            handler: function() {
                this.getAttachment();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.initialSettings();
        this.getAttachment();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");

        },
        getAttachment: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.refRecordId
            }
            if (this.refRecordId > 0) {
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result)
                        this.attachments = result.attachmentList;
                    })
            }
        },


    }
}
</script>