<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <LanProcessPlan />
            </div>
        </div>
    </div>
</template>
<script>
import LanProcessPlan from '@/views/process/ProcessPlan.vue';
export default {
    components: {
        LanProcessPlan
    }
}
</script>