<template>
	<div>
        <b-table show-empty small stacked="md" sort-icon-left :items="items" :fields="ermReviewApprovalFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
            <template v-slot:cell(isApproval)="data">
                <div>
                    <p v-if="data.value == false">Review</p>
                    <p v-else>Approval</p>
                </div>
            </template>
            <template v-slot:cell(status)="data">
                <span v-if="data.item.reviewedById == userId">
                    <select v-if="data.value == 'Pending'" v-model="data.item.statusId" class="form-control w-80 float-left">
                        <option value="">Select</option>
                        <option v-for="(info, index) in filterReviewApproval(reviewStatus,data.item.isApproval)" :value="info.id">{{info.value}}</option>
                    </select>
                    <!-- <select v-if="data.item.isApproval && data.value == 'Pending' " v-model="data.item.statusId" class="form-control w-80 float-left">
                        <option value="">Select</option>
                        <option v-for="(info, index) in filterReviewApproval(reviewStatus,data.item.isApproval)" :value="info.id">{{info.value}}</option>
                    </select> -->
                    <a v-if="data.value == 'Pending'" href="javascript:" title="update status" @click="updateStatusValue(data.item.statusId, data.item.id, data.item.isApproval)" v-b-modal.confirmUpdateStatus class="float-right p-2 action-link">
                        <i class="fa fa-check pr-2"></i>
                    </a>
                    <p v-if="data.value != 'Pending'">{{data.value}}</p>
                </span>
                <span v-else>
                    <p>{{ data.value }}</p>
                </span>
            </template>
            <template v-slot:empty="erm">
                <div class="text-center">No Reviews found</div>
            </template>
        </b-table>
        <DeleteComponent id="confirmUpdateStatus" :onYes="updateStatus" title="Update Status" message="Are you sure to update the status?" />
	</div>
</template>
<script>
import iziToast from 'izitoast';
export default {
	props: {
        items: Array,
        fields: Array,
        review: Object
    },
	data() {
		return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            reviewStatus: [],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            ermReviewApprovalFields: [
                { key: 'isApproval', label: 'Review / Approval', sortable: true, sortDirection: 'desc' },
                { key: 'receivedDt', label: 'Received Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'reviewedBy', label: 'Review / Approval By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Review / Approval Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],
		}
	},
    mounted() {
        this.getReviewStatusLookup();
    },
	methods: {
        getReviewStatusLookup: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'review'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/review/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    console.log(this.review.isReviewed);
                    this.reviewStatus = result.statusLookup;
                    if (this.review.isReviewed == 1) {
                        this.statuss = this.filterReviewApproval(this.reviewStatus,false);
                    } else if (this.review.isReviewed == 2) {
                        this.statuss = this.filterReviewApproval(this.reviewStatus,true);
                    }
                })
        },
        updateStatus: function() {

            if (this.review.statusId == null || this.review.statusId == '') {
                iziToast.info({
                    message: 'Please select status.',
                    position: 'topRight'
                });
                return;
            }
            console.log(this.review);
            // this.review.statusLookupId = 4;
            axios.post(this.$store.getters.getAPIBasePath + '/review/update/status', this.review)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    if (result.status == 'ALREADY_REPORTED') {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                        return;
                    }
                    if (this.review.isReviewed == 1) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Document Reviewed.',
                            position: 'topRight'
                        });
                    } else if (this.review.isReviewed == 2) {
                        iziToast.success({
                            title: 'Success',
                            message: 'Document Approved.',
                            position: 'topRight'
                        });
                    }
                    this.$emit('refreshData',this.review.entityRecordMasterId);
                })
        },
        updateStatusValue: function(statusId, id, isApproval) {
            this.review.statusId = statusId;
            this.review.id = id;
            this.review.isReviewed = (isApproval ? 2 : 1);
        },
	}
}
</script>