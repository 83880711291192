<template>
    <div>
        <ScopeStatementMasterList :ermMasterInfo="ermMasterInfo"></ScopeStatementMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ScopeStatementMasterList from '@/components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ScopeStatementMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Scope Document List",
                entity: 'scope_statement_master',
                refEntity: 'scope_statement',
                tooltip: 'New Scope Document',
                redirectURLName: 'scopestatement',
                newEntityMasterURL: 'scopestatementmaster',
                redirectURL: '/scopestatementmaster',
                actionName: 'Scope Statement',
                previewURLName: 'sstrep',
                headerTitle: 'Doc Title',
                hideProject: true,
            },
        }
    },
    mounted() {},
    methods: {}
}
</script>