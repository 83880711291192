<template>
    <div id="wrapper" class="h-100 pt-2">
        <div id="login" class="h-100">
            <div class="container-fluid h-100">
                <div class="form-row h-100">
                    <div class="col-md-5">
                        <div class="box-wrapper shadow-sm px-5 pb-5 pt-4 mb-4 bg-white rounded-10 pos-rel">
                            <div class="row mb-3">
                                <div class="col-sm-12 px-2">
                                    <div class="card p-2">
                                        <h4 class="h5" style="text-transfomargin-top:10px">{{ jobData.title }} <span class="text-muted">
                                        - {{ jobData.jobCode }}</span></h4>
                                        <p class="my-1"><i class="fa fa-suitcase pr-2" aria-hidden="true"></i>{{ jobData.position }}
                                            <span class="text-muted"> - {{ jobData.vacancies }} Vacancies</span>
                                        </p>
                                        <!-- <p class="text-muted my-1">{{ jobData.vacancies }} Vacancies</p> -->
                                        <p class="text-muted my-1"><i class="fa fa-map-marker pr-2" aria-hidden="true"></i>{{ jobData.location }}</p>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-2">
                                    <b-button class="addnew-quicktassk-btn sbtn float-sm-right" @click="" variant="secondary" size="lg">Apply</b-button>
                                </div> -->
                            </div>
                            <!-- <div class="separator my-2"></div> -->
                            <div id="profile" class="px-0 py-2">
                                <div class="profile_custom">
                                    <h3 class="h5 mb-3" style="text-transfomargin-top:12px">Job Details</h3>
                                    <!-- <h2 class="mb-3 contact-title"><span>Job Details</span></h2> -->

                                    <h3 class="mb-3 contact-title"><span>Qualification & Experience</span></h3>
                                    <p class="my-1">
                                        <i class="fa fa-graduation-cap pr-2" aria-hidden="true"></i>{{ jobData.qualification }}
                                    </p>
                                    <p class="my-1">
                                        <span class="text-muted">Min. Experience - </span>
                                        <span v-if="jobData.experienceYears">{{ jobData.experienceYears }} years </span>
                                        <span v-if="jobData.experienceMonths">{{ jobData.experienceMonths }} months</span>
                                    </p>
                                </div>
                            </div>
                            <div id="profile" class="px-0 py-2">
                                <div class="profile_custom">
                                    <h3 class="mb-3 contact-title"><span>Description</span></h3>
                                    <div v-html="jobData.description"></div>
                                </div>
                            </div>
                            <!-- <div id="profile" class="px-0 py-2">
                                <div class="profile_custom">
                                    <h3 class="mb-3 contact-title"><span>Roles & Responsibilities</span></h3>
                                    <div v-html="jobData.description"></div>
                                </div>
                            </div> -->
                            <div id="profile" class="px-0 py-2">
                                <div class="profile_custom">
                                    <h3 class="mb-3 contact-title"><span>Technology & Skills</span></h3>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div v-for="technologySkill in technologySkills" :key="technologySkill.id" class="mb-2">
                                                <p class="m-0 font-weight-bold">{{ technologySkill.technology }}</p>
                                                <p class="m-0">{{ technologySkill.skills }} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="box-wrapper shadow-sm px-5 pb-5 pt-4 mb-4 bg-white rounded-10 pos-rel">
                            <h4 class="h5 mb-3 card p-2">Job Application</h4>
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div class="row mb-2 px-2">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.jobApplication.name.$error }">
                                                <input type="text" class="form-control" v-model="jobApplication.name" id="name" :class="{ 'is-invalid': $v.jobApplication.name.$error }" placeholder="Enter Your Name">
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.jobApplication.name.$error && !$v.jobApplication.name.minLength" class="text-danger">Candidate Name must have at least {{$v.jobApplication.name.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.jobApplication.name.$error && !$v.jobApplication.name.required" class="text-danger">Please enter Candidate Name</label>
                                            <label v-if="$v.jobApplication.name.$error && !$v.jobApplication.name.maxLength" class="text-danger">Candidate Name must not exceed {{$v.jobApplication.name.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <AttachmentComponent id="attachmentComp1" ref="attachmentControl" singleFileUpload :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                        </div>
                                    </div>
                                    <!-- <div class="col-6">
                                        <div class="form-group">
                                            <label for="candidateTypeId">Candidate Type</label>
                                            <select v-model="jobApplication.candidateTypeId" class="form-control" id="candidateTypeId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in candidateTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div> -->
                                    
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="emailId">Email Id</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.jobApplication.emailId.$error }">
                                                <input type="email" autocomplete="nope" :class="{ 'is-invalid': $v.jobApplication.emailId.$error }" class="form-control" v-model="jobApplication.emailId" id="emailId" placeholder="User Account Login Email Id" >
                                                <div class="required-icon"></div>
                                            </div>
                                            <div v-if="$v.jobApplication.emailId.$error" class="invalid-feedback">
                                                <span v-if="!$v.jobApplication.emailId.required" class="text-danger">Email Id (or) Mobile is required</span>
                                                <span v-if="!$v.jobApplication.emailId.email">Email Id is not valid</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="mobile">Mobile</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.jobApplication.mobile.$error }">
                                                <input type="text" class="form-control" v-model="jobApplication.mobile" id="mobile" :class="{ 'is-invalid': $v.jobApplication.mobile.$error }" placeholder="Enter Your Name" >
                                                <div class="required-icon"></div>
                                            </div>
                                            <div v-if="$v.jobApplication.mobile.$error" class="invalid-feedback">
                                                <span v-if="!$v.jobApplication.mobile.required" class="text-danger">Email Id (or) Mobile is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="position">Current Position</label>
                                            <input type="text" v-model="jobApplication.position" maxlength="100" class="form-control" id="position" placeholder="Enter Position">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="qualification">Qualification</label>
                                            <input type="text" v-model="jobApplication.qualification" maxlength="100" class="form-control" id="qualification" placeholder="Enter Qualification">
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Experience</label>
                                            <b-input-group size="sm" class="mb-2">
                                                <DayMonthYearControl 
                                                    :dayVisible="false"
                                                    @vmx-value-input="vmxValueInput" 
                                                    :monthValue="jobApplication.experienceMonths"
                                                    :yearValue="jobApplication.experienceYears"
                                                    ref="dayMonthYearControl" />
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label for="noticePeriodEnum">Notice Period</label>
                                            <select v-model="jobApplication.noticePeriodEnum" class="form-control" id="noticePeriodEnum">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in noticePeriodList" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-3">
                                        <div class="form-group">
                                            <label for="statusId">Status</label>
                                            <select v-model="jobApplication.statusId" class="form-control" id="statusId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in candidateStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label for="dueDate">Due Date</label>
                                            <input v-model="jobApplication.dueDate" type="date" id="dueDate" class="form-control" >
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="card-body p-0 mb-3">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accord-objective1>
                                                        <label>Objective</label>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accord-objective1" accordion="my-accordion1" role="tabpanel">
                                                    <b-card-body class="p-1 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="jobApplication.objective" ></editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="card-body p-0 mb-3">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accord-keyStrength1>
                                                        <label>Key Strength</label>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accord-keyStrength1" accordion="my-accordion1" role="tabpanel">
                                                    <b-card-body class="p-1 pb-0">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="jobApplication.keyStrength" ></editor>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" @click="saveCandidate" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3">Submit</button>
                                <button type="submit" @click="clearData" class="btn btn-outline-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-refresh pr-2"></i>Reset</button>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
import enummixin from "@/mixins/enummixin";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    mixins: [enummixin],
    components: {
        AttachmentComponent,
        Editor,
    },
    data() {
        return {
            showOverlay: false,
            jobData:{},
            technologySkills: [],

            jobApplication: {
                id: '',
                jobApplicationId: '',
                vhextAccountId: '',
                jobId: '',
                candidateId: '',
                applicationDate: this.getDateValue(new Date()),
                referralId: '',
                candidateTypeId: '',
                userId: '',
                name: '',
                emailId: '',
                mobile: '',
                position: '',
                objective: '',
                keyStrength: '',
                qualification: '',
                experienceMonths: '',
                experienceYears: '',
                workShiftId: '',
                noticePeriodEnum: '',
                statusId: '',
                active: true,
                createdById: localStorage.getItem("userId"),
            },
            companyData: {},
            companyLogo: localStorage.getItem("logo"),

            attachmentInfo: {
                title: 'Upload Resume'
            },
            attachments: [],

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    metaInfo() {
        return {
            title: `Job - ${this.jobData.title}`,
            meta: [
                { name: 'description', content: this.jobData.description},
                { property: 'og:title', content: 'Job ' + this.jobData.title},
                { property: 'og:site_name', content: process.env.VUE_APP_TITLE},
                { property: 'og:description', content: 'Job Description: ' + this.jobData.description},
                { property: 'og:type', content: 'website'},
                // {property: 'og:url', content: '' + this.jobData.title},
                { property: 'og:image', content: this.companyData.logo }
            ]
        }
    },

    computed: {
        key() {
            return this.$route.params.key;
        }
    },
    validations: {
        jobApplication: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            // either emailId or mobile required validation done here
            emailId: {
                email,
                required (v) {
                    return this.jobApplication.mobile || required(v)
                }
            },
            mobile: {
                required (v) {
                    return this.jobApplication.emailId || required(v)
                }
            }
        },
    },
    mounted() {
        this.getDecodedData();
    },
    methods: {
        getDecodedData: function() {
            let data = {
                key: String(this.key)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/job/view/decode', data)
                .then((response) => {
                    let result = response.data;
                    this.jobData = result.jobView;
                    this.companyData = result.companyData;
                    this.jobData.referralId = this.companyData.referralId;
                    this.$store.state.companyLogo = this.companyData.logo;
                    localStorage.setItem('logo', this.companyData.logo);
                    this.technologySkills = result.publicTechnologySkills;
                })
        },
        async saveCandidate() {
            this.jobApplication.jobId = this.jobData.id;
            this.jobApplication.vhextAccountId = this.jobData.vhextAccountId;
            this.jobApplication.referralId = this.jobData.referralId;
            this.jobApplication.experienceMonths = this.$refs.dayMonthYearControl.getMonths();
            this.jobApplication.experienceYears = this.$refs.dayMonthYearControl.getYears();

            this.$v.jobApplication.$touch();
            if (!this.$v.jobApplication.$invalid) {
                this.showOverlay = true;
                try {
                    let result = await axios.post(this.$store.getters.getAPIBasePath + '/resource/jobapplication/add', this.jobApplication)
                    await this.$refs.attachmentControl.saveAttachment('candidate', result.data.id);

                    this.showOverlay = false;
                    if (result.data.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.data.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        // this.getCandidates();
                    }
                }
                catch {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }
            }
        },
        clearData: function() {
            // this.elements.editMode = false;
            // this.elements.documentEditMode = false;
            // this.elements.techEditMode = false;
            // this.elements.roleEditMode = false;
            // this.elements.contactEditMode = false;
            // this.elements.otherInfoEditMode = false;
            // this.elements.visible = false;
            // this.elements.showResume = false;


            this.jobApplication.id = '';
            this.jobApplication.jobApplicationId = '';
            this.jobApplication.vhextAccountId = '';
            this.jobApplication.jobId = '';
            this.jobApplication.referralId = '';
            this.jobApplication.candidateId = '';
            this.jobApplication.candidateTypeId = '';
            this.jobApplication.userId = '';
            this.jobApplication.name = '';
            this.jobApplication.position = '';
            this.jobApplication.emailId = '';
            this.jobApplication.mobile = '';
            this.jobApplication.objective = '';
            this.jobApplication.keyStrength = '';
            this.jobApplication.qualification = '';
            this.jobApplication.experienceMonths = '';
            this.jobApplication.experienceYears = '';
            this.jobApplication.workShiftId = '';
            this.jobApplication.noticePeriodEnum = '';
            this.jobApplication.statusId = '';
            this.jobApplication.active = true;
            this.$refs.dayMonthYearControl.clearValueInputData();
            this.attachments = [];
            this.$refs.attachmentControl.clearAttachment();
            this.$v.jobApplication.$reset();
        },
        vmxValueInput:function(data){
            this.$refs.dayMonthYearControl.setValueInputData(data.year, data.month, data.day);
        },
    },

}
</script>