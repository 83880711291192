<template>
    <div class="helpDeskPopup">
        <b-modal :id="id" ref="helpDeskPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Help" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe">
                    <b-card v-if="menuData.functionalInfo" no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Functional Info <span> - {{ menuData.menuTitle }}</span>
                            </label>
                            <div class="separator mx-2"></div>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <div v-html="menuData.functionalInfo"></div>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                FAQ
                            </label>
                            <div class="separator mx-2"></div>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <!-- <div v-html="menuData.functionalInfo"></div> -->
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="mb-2">
                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:">
                                Demo
                            </label>
                            <div class="separator mx-2"></div>
                        </b-card-header>
                        <b-card-body class="py-3 px-2">
                            <!-- <div v-html="menuData.functionalInfo"></div> -->
                        </b-card-body>
                    </b-card>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        id: { type: String, default: 'helpDeskPopup' },
        menuData: Object,
        // detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        // getDetails: function(id, refEntity) {
        //     this.loading = true;
        //         if (refEntity === "issue") {
        //             this.$bvModal.show(this.id);
        //             axios.get(this.$store.getters.getAPIBasePath + '/project/issue/view/' + id)
        //             .then((response) => {
        //                 let result = response.data;
        //                 if (result.length != 0) {
        //                     this.popupData = result.issueView;
        //                     this.loading = false;
        //                 }
        //             })
        //             .catch(error => {
        //                 this.loading = false;
        //                 console.log(error);
        //             });
        //         }
        //         else {
        //             this.$nextTick(() => {
        //                 this.popupData = this.detailViewData;
        //             });
        //             this.$bvModal.show(this.id);
        //             this.loading = false;
        //         }
        // },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>