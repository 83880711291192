<template>
    <div>
        <b-modal ok-only no-enforce-focus @hide="closeDialog" id="companyCalendarListPopup" ref="addCalEventRef" size="xl" modal-class="" hide-header>
        	<button type="button" @click="$bvModal.hide('companyCalendarListPopup');" aria-label="Close" class="close position-absolute" style="right: 25px;">×</button>
        	<b-tabs content-class="mt-4 pt-2 px-3">
                <b-tab>
                    <template #title>
                        <h5 class="p-0 m-0">Weekend</h5>
                    </template>
		        	<div class="row">
		                <div class="col-lg-12 col-sm-12 p-0 menumangement">
		                    <div class="card-body px-3 py-0">
			                    <div no-body>
						            <b-overlay :show="showOverlay" opacity="0.4">
						                <div class="row">
						                    <div class="col-xl-12">
						                        <div class="card mb-4">
						                            <div class="card-body">
						                                <fullcalendar ref="evtCalendar" :key="calendarEventKey" :calendarEvents="events" @event-selected="eventSelected" />
						                            </div>
						                        </div>
						                    </div>
						                </div>
						                <template #overlay>
						                    <div class="text-center">
						                        <LoadingSpinner />
						                    </div>
						                </template>
						            </b-overlay>
			                    </div>
			                </div>
		                </div>
		            </div>
		        </b-tab>
		        <b-tab>
		        	<template #title>
                        <h5 class="p-0 m-0">Holidays</h5>
                    </template>
                    <div class="row">
		                <div class="col-lg-12 col-sm-12 p-0 menumangement">
		                    <div class="card-body px-3 py-0">
			                    <div no-body>
						            <b-overlay :show="isBusy" spinner-variant="primary" opacity="0.4" rounded="sm">
		                                <div class="row view-filter">
		                                    <div class="col-sm-12">
		                                        <div class="float-right">
		                                            <div class="dataTables_length">
		                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
		                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
		                                                </b-form-group>
		                                            </div>
		                                        </div>
		                                        <div class="float-left">
		                                            <div class="dataTables_filter">
		                                                <label>
		                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
		                                                </label>
		                                            </div>
		                                        </div>
		                                        <div class="clearfix"></div>
		                                    </div>
		                                </div>
		                                <b-table show-empty small stacked="md" sort-icon-left :items="holidayList" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
			                                <template v-slot:cell(startDate)="data">
			                                	<template v-if="data.item.start">
				                                    <!-- <div v-if="data.item.allDay && data.item.daysOfWeek">{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div> -->
				                                    <!-- <div v-else-if="data.item.daysOfWeek && data.item.startTime">{{ combineDateAndTime(data.item.startRecur,(data.item.startTime).substr(0,5)) | formatDate }}</div> -->
				                                    <div v-if="data.item.allDay">{{ data.item.start | formatDate }}</div>
				                                    <div v-else>{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div>
			                                	</template>
			                                	<template v-else-if="data.item.startRecur">
				                                    <div v-if="data.item.allDay && data.item.daysOfWeek">
				                                    	<span v-for="day,index in (data.item.daysOfWeek)"><span v-if="index!==0">,</span> {{ days[day] }}</span>
					                                    ({{ data.item.startRecur | formatMonthYear }} - {{ data.item.endRecur | formatMonthYear }})
					                                </div>
				                                    <!-- <div v-else-if="data.item.daysOfWeek && data.item.startTime">{{ combineDateAndTime(data.item.startRecur,(data.item.startTime).substr(0,5)) | formatDate }}</div> -->
				                                    <!-- <div v-else-if="data.item.isAllDayEvent">{{ data.item.start | formatDate }}</div>
				                                    <div v-else>{{ data.item.start | formatDate }} - {{ data.item.end | formatDate }}</div> -->
			                                	</template>
			                                	<template v-else>
			                                		<div>Weekend</div>
			                                	</template>
			                                </template>
			                                <template v-slot:cell(actions)="data">
			                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
			                                    <a href="javascript:" title="Delete" @click="deleteCalendarEvent(data.item.id)" v-b-modal.deleteCalendarEvent><i class="fa fa-trash pl-2"></i></a>
			                                </template>
			                                <template v-slot:empty="scope">
			                                    <div class="text-center">No Holidays Found</div>
			                                </template>
			                                <template v-slot:emptyfiltered="scope">
			                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
			                                </template>
		                                </b-table>
		                                <b-pagination v-if="holidayList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
		                            </b-overlay>
			                    </div>
			                </div>
		                </div>
		            </div>
		        </b-tab>
		    </b-tabs>
            <!-- <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button v-if="!calendarEvent.viewMode" variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ calendarEvent.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ calendarEvent.editMode ? 'Update Event' : 'Add Event' }}</span>
                    </b-button>
                    <b-button v-if="calendarEvent.editMode" variant="secondary" size="sm" class="float-right mr-2" @click="cancelEditMode">Cancel</b-button>
                    <b-button v-else variant="secondary" size="sm" class="float-right mr-2" @click="closeDialog();cancel();">Close</b-button>
                </div>
            </template> -->
        </b-modal>
        <DeleteComponent id="deleteCalendarEvent" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete the Event?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import dayList from '@/json/daysofweek.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'

export default {
    components: {
    	Multiselect,
    	Editor,
    	ERMMasterPopup,
    },
    data() {
        return {
        	showDialog: false,
        	isBusy: false,
        	elements: {
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
        	
        	calendarEventKey: 0,
            showOverlay: false,
            activeTabIndex: 0,

            minPeriod: '',
            // calendarEvent: this.calendarEventData,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: "calendar_event",

            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                calendarTypeId: 1,
                eventTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                daysToRepeat: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: '',
                participantId: '',
                eventParticipant: []
            },

            days: dayList,
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            entityERMLookup: [],

            allEvents: [],
            fields: [
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'startDate',label: 'Date', sortable: true,sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],

            deleteParam: {
                id: 0
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            totalRowsWkEnd: 1,
            currentPageWkEnd: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
    	events() {
            return this.allEvents.map((event,index)=> {
                if (event.calendarCategoryId == 1) {
                    event.display = "background";
                    event.color =  "#dddddd";
                    event.textColor =  "black";
                    event.classNames = 'pt-4'

                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek.split(',');
                    console.log(event)
                    return event;
                }
                else {
                     if (event.allDay) {
                        event.display = 'background';
                        event.color = 'red';
                    }
                    else {
                        event.display = 'list-item';
                        // event.color = 'blue';
                        event.classNames = 'bg-secondary';
                        // event.textColor = 'black'
                    }
                    return event;
                }
            })
        },
        holidayList() {
        	return this.events.filter(event => event.calendarCategoryId == 2)
        },
    },
    mounted() {
        this.getEvents();
        // this.setCalendarEventData();
    },
    validations: {
        calendarEvent: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
    },
    methods: {
    	getEvents: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                calendarTypeId: 1
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendar/list', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.allEvents = result.calendarEventList;
                    this.totalRows = this.holidayList.length;
                    this.calendarEventKey++;
                    console.log(this.allEvents);
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        // saveData: function() {
	            
	       //      this.calendarEvent.startTime = null;
	       //      this.calendarEvent.endTime = null;
	       //      if (this.activeTabIndex == 0) {
	       //      	this.calendarEvent.isAllDayEvent = true;
	       //      	this.calendarEvent.startDate = this.getDateValue(this.calendarEvent.eventStartDate);
	       //      	let endYear = new Date(this.calendarEvent.eventEndDate).getFullYear();
	       //      	let endMonth = new Date(this.calendarEvent.eventEndDate).getMonth();
	       //      	let lastDay = this.getLastDateOfMonth(endYear, endMonth);
	       //          this.calendarEvent.endDate = `${endYear}-${endMonth+1}-${lastDay}`
	       //      	this.calendarEvent.daysOfWeek = [];
        //             this.calendarEvent.daysToRepeat.forEach((day) => {
        //                 this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
        //             })
        //             this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
        //             // this.calendarEvent.title = 'Weekend'
        //             console.log(this.calendarEvent.startDate, this.calendarEvent.endDate);
	       //      }
	       //      else {
		      //   	this.$v.calendarEvent.$touch();
		      //       if (this.$v.calendarEvent.$invalid) {
		      //       	return;
		      //       }
	       //      	if (this.calendarEvent.isAllDayEvent) {
		      //           this.calendarEvent.startDate = this.getDateValue(this.calendarEvent.eventStartDate);
		      //           this.calendarEvent.endDate = this.calendarEvent.eventFrequency==0 ? null : this.getDateValue(this.calendarEvent.eventEndDate);
		      //           if (this.calendarEvent.eventFrequency==0) {
		      //           	this.calendarEvent.daysOfWeek = null;
		      //           }
		      //           else if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
		      //               this.calendarEvent.daysOfWeek = [];
		      //               this.calendarEvent.daysToRepeat.forEach((day) => {
		      //                   this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
		      //               })
		      //               this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
		      //           }
		      //           else this.calendarEvent.daysOfWeek = null;
		      //       } else {
		      //           if (this.calendarEvent.startDate == '' || this.calendarEvent.startDate == null) { 
		      //               this.calendarEvent.startDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime) 
		      //           }
		      //           if (this.calendarEvent.endDate == '' || this.calendarEvent.endDate == null) { 
		      //               this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventEndDate, this.calendarEvent.eventEndTime)
		      //           }
		      //           if (this.calendarEvent.eventFrequency==0) {
		      //               this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventEndTime)
		      //           }
		      //           if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
		      //                this.calendarEvent.daysOfWeek = [];
		      //               this.calendarEvent.daysToRepeat.forEach((day) => {
		      //                   this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
		      //               })
		      //               this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
		      //               this.calendarEvent.startTime = this.calendarEvent.eventStartTime + ":00";
		      //               this.calendarEvent.endTime = this.calendarEvent.eventEndTime + ":00";
		      //           } else this.calendarEvent.daysOfWeek = null;
		      //       }
	       //      }
        //     	this.showOverlay = true;
        //         if (this.calendarEvent.editMode) {
        //             this.updateEvent();
        //         } else {
        //             this.saveEvent();
        //         }
        // },
        // saveEvent: function() {
        //     console.log(this.calendarEvent);
        //     axios.post(this.$store.getters.getAPIBasePath + '/communication/companyevent/add', this.calendarEvent)
        //         .then((response) => {
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.showOverlay = false;
        //                 this.getEvents();
        //                 this.$emit('refreshData');
        //                 this.clearData();
        //             }
        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        // updateEvent: function() {
        //     console.log(this.calendarEvent);
        //     axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/update', this.calendarEvent)
        //         .then((response) => {
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.showOverlay = false;
        //                 this.getEvents();
        //                 this.$emit('refreshData');
        //                 this.closeDialog();
        //                 this.calendarEvent.editMode = false;
        //                 this.calendarEvent.viewMode = true;
        //             }
        //         })
        //         .catch(error => {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                title: 'Error',
        //                message: 'Error',
        //                position: 'topRight'
        //            });
        //         })
        // },
        deleteCalendarEvent: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
        	this.isBusy = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/communication/calendarevent/delete/' + this.deleteParam.id)
                .then(response => {
		        	this.isBusy = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEvents();
                        this.$emit('refreshData');
                    }
                })
                .catch(error => {
		        	this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getParticipantList: function(eventId) {
            let data = {
                eventId: eventId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                    console.log(result);
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredEvt(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsWkEnd = filteredItems.length
            this.currentPageWkEnd = 1
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        clearData: function() {
            this.calendarEvent.title = '';
            this.calendarEvent.isAllDayEvent = false;
            this.calendarEvent.daysOfWeek = [];
            this.calendarEvent.daysToRepeat = [];
            this.calendarEvent.eventDate = this.getDateValue(new Date());
            this.calendarEvent.startDate = '';
            this.calendarEvent.endDate = '';
            this.calendarEvent.eventStartDate = this.getDateValue(new Date());
            this.calendarEvent.eventEndDate = this.getDateValue(new Date());
            this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
            this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
            this.calendarEvent.statusId = '';
            this.calendarEvent.hostedById = '';
            this.calendarEvent.link = '';
            this.calendarEvent.location = '';
            this.calendarEvent.description = '';
            this.calendarEvent.eventTypeId = '';
            this.calendarEvent.eventFrequency = 0;
            this.calendarEvent.eventParticipantList = '';
            this.calendarEvent.eventParticipant = '';
            this.showOverlay = false;
            this.$v.calendarEvent.$reset();
        },
        cancelEditMode() {
        	// this.setCalendarEventData();
        	this.calendarEvent.viewMode = true;
        	this.calendarEvent.editMode = false;
        },
        closeDialog() {
            this.clearData();
            this.$emit('closeDialog');
            this.$bvModal.hide('companyCalendarListPopup');
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        },
        eventSelected: function(event) {
            let calendarProps = event.extendedProps;
            // this.getParticipantList(calendarProps.eventId);
            // this.openCalEventPopup(event, true);
        },
    }
}
</script>