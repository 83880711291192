<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <b-table class="custom-table-bg" show-empty small stacked="sm" sort-icon-left :items="phaseDetails" :fields="phaseFields" selectable select-mode="single" responsive="sm" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                <template v-slot:cell(phaseTitle)="data">
                    <a class="tooltiptext font-weight-bold fs-14" href="javascript:" @click="viewItemDetails(data.item)">
                        <span class=" action-link">Phase {{ data.item.phaseId }} : </span>{{data.value}}</a>
                </template>
                <template #cell(show_details)="row">
                    <span class="float-left ml-3" :title="`${row.detailsShowing ? 'Hide' : 'Show'}`">
                        <span >
                            <i class="fa fa-angle-down fa-lg font-weight-bold action-link pointer" :class="row.detailsShowing ? 'rotation_180' : 'rotation_normal'"></i>
                        </span>
                    </span>
                    <!-- <span class="float-right action-link">Phase {{ row.item.phaseId }}</span> -->
                </template>
                <template slot="row-details" slot-scope="row">
                    <div class="data-table-rows child-tables text-left px-3">
                        <b-table show-empty small header-variant="secondary" sort-icon-left :items="processPlanList(row.item.phaseId)" :fields="fields" responsive="sm">
                            <template v-slot:cell(activities)="data">
                                <span>{{ data.value }}</span>
                                <a v-if="data.value" href="javascript:" @click="getETVX(data.item)" class="action-link fs-11 pl-2">ETVX</a>
                            </template>
                            <template v-slot:cell(deliverables)="data">
                                <div v-if="data.item.deliverables">
                                    <span v-for="(info, index) in JSON.parse(data.item.deliverables)">
                                        <span v-if="index != 0">, </span>
                                        <span>{{ info.value }}</span>
                                    </span>
                                </div>
                            </template>
                            <!-- <template v-slot:cell(actions)="data">
                                <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" title="Delete" @click="deleteProcessPlan(data.item.id)" v-b-modal.deleteProcessPlan><i class="fa fa-trash pl-2"></i></a>
                            </template> -->
                            <template v-slot:empty="scope">
                                <div class="text-center">No Process Plan found</div>
                            </template>
                            <template v-slot:emptyfiltered="scope">
                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                            </template>
                        </b-table>
                    </div>
                </template>
                
                <template v-slot:empty="scope">
                    <div class="text-center">No Process Plan found</div>
                </template>
                <template v-slot:emptyfiltered="scope">
                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                </template>
            </b-table>
            <b-pagination v-if="processPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" first-number last-number :per-page="perPage" align="fill" size="sm" class="my-0 float-right"></b-pagination>
            <div class="float-left">
                <div class="dataTables_length">
                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                    </b-form-group>
                </div>
            </div>
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
        <ActivityETVXPopup ref="activityETVXPopupRef" />
        <ProcessPlanDetailPopup v-if="showDialog" ref="processPlanDetailPopupRef"  :detailViewData="detailViewData" :referenceDetailId="processPlanPopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
import ActivityETVXPopup from '@/components/popup/action/ActivityETVXPopup.vue';
import ProcessPlanDetailPopup from '@/components/popup/itemdetail/ProcessPlanDetailPopup.vue';
export default {
    components: {
        ActivityETVXPopup,
        ProcessPlanDetailPopup,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
            },
            showDialog: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            detailViewData: {},
            processPlanPopupId: 0,

            phase: [
                { name: "Phase 1", id: 1 },
                { name: "Phase 2", id: 2 },
                { name: "Phase 3", id: 3 },
                { name: "Phase 4", id: 4 },
                { name: "Phase 5", id: 5 },
                { name: "Phase 6", id: 6 },
                { name: "Phase 7", id: 7 },
                { name: "Phase 8", id: 8 },
                { name: "Phase 9", id: 9 },
                { name: "Phase 10", id: 10 },
                { name: "Phase 11", id: 11 },
                { name: "Phase 12", id: 12 },
            ],
            processPlans: [],
            phaseFields: [
                { key: 'phaseTitle', label: 'Phase', tdClass: 'text-left' },
                { key: 'show_details', label: '', tdClass: 'table-col-id-size' },
                // { key: 'phaseId', label: 'Phase Id', sortable: true, sortDirection: 'desc' },
                // { key: 'actions', label: 'Actions' }
            ],
            fields: [
                // { key: 'phaseId', label: 'Phase Id', sortable: true, sortDirection: 'desc' },
                // { key: 'phaseTitle', label: 'Phase', sortable: true, sortDirection: 'desc' },
                { key: 'process', label: 'Process', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activities', label: 'Activity', tdClass: 'table-cw-300' },
                { key: 'deliverables', label: 'Deliverables', tdClass: 'table-cw-400'},
                // { key: 'actions', label: 'Actions' , tdClass: 'table-cw-100'}
            ],


            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            filterOn: [],
            filter: null,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        phaseDetails() {
            let phaseDetails = [];
            this.phase.forEach((phase) => {
                if (this.processPlans && this.processPlans.length>0) {
                    let processPlan = this.processPlans.find((data) => data.phaseId == phase.id);
                    if (processPlan) {
                        processPlan._showDetails = true;
                        phaseDetails.push(processPlan);
                    }
                }
            })
            return phaseDetails;
        }
    },
    methods: {
        getErdDetailList: function(ermId) {
            if (ermId) {
                let data = {
                    entityRecordMasterId: ermId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/processplan/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.processPlans = result.processPlanList;
                        this.totalRows = this.processPlans.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                    })
            }
            else {
                this.processPlans = [];
            }
        },
        processPlanList: function(phaseId) {
            return this.processPlans.filter((e) => e.phaseId == phaseId)
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        viewItemDetails: function(data) {
            data.deliverableData = JSON.parse(data.deliverables);
            
            this.detailViewData = data;

            this.processPlanPopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.processPlanDetailPopupRef.getDetails(this.processPlanPopupId, null);
            })
        },
        getETVX: function(data) {
            this.$refs.activityETVXPopupRef.getActivityList(data.processId, 'process');
        },
        closeDialog: function() {
            this.showDialog = false;
        },
    }
}
</script>