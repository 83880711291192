<template>
    <div class="type">
        <b-modal ref="menuCustomizationPopup" centered size="lg" :id="id" modal-class="typeModal" title="Landing Top Menu - Show / Hide" @hide="closeDialog" :no-close-on-backdrop="true" hide-footer header-class="pt-4 pb-2" body-class="p-2" >
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-card-body class="p-3">
                    <b-table class="custom-table-bg mb-0" outlined show-empty small sticky-header="500px" head-variant=:primary sort-icon-left :items="landingTopMenus" :fields="menuFields" selectable select-mode="single" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" responsive="sm"  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(show_details)="row">
                            <!-- <span @click.stop="row.toggleDetails" class="ml-3" :title="`${row.detailsShowing ? 'Hide' : 'Show'} Sub Menu`">
                                <span @click="showDetail(row.item.id);">
                                    <i class="fa fa-angle-down fa-lg font-weight-bold action-link pointer" :class="row.detailsShowing ? 'rotation_180' : 'rotation_normal'"></i>
                                </span>
                            </span> -->
                            <span v-if="row.item.entityCount>1" class="float-left ml-3" :title="`${row.detailsShowing ? 'Hide' : 'Show'}`">
                                <span @click="showDetail(row.item.id);">
                                    <i class="fa fa-angle-down fa-lg font-weight-bold action-link pointer" :class="row.detailsShowing ? 'rotation_180' : 'rotation_normal'"></i>
                                </span>
                            </span>
                        </template>
                        <template v-if="row.item.entityCount>1" slot="row-details" slot-scope="row">
                            <div class="data-table-rows child-tables text-left px-5">
                                <b-table show-empty small header-variant="secondary" sort-icon-left :items="landingSubMenus(row.item.id)" :fields="subMenuFields" responsive="sm">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(displayName)="data">
                                        <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                            <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('display_name', data.item.id, data.value, row.item.id)" @blur="cancelInlineEdit(landingSubMenuList, data.field.key)">
                                        </div>
                                        <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                    </template>
                                    <template v-slot:cell(hide)="data">
                                        <span v-if="data.item.hide" title="Show Menu" class="border bg-light text-danger p-1" >Hidden</span>
                                        <span v-else title="Hide Menu" class="border bg-light text-success p-1">Showing</span>
                                    </template>

                                    <template v-slot:cell(actions)="data">
                                        <button v-if="data.item.hide" type="submit" title="Show Menu" class="btn btn-outline-success sbtn btn-xs py-1 px-2" @click="assignMenuState(data.item, row.item.id)">Show</button>
                                        <button v-else type="submit" title="Hide Menu" class="btn btn-outline-danger sbtn btn-xs py-1 px-2" @click="assignMenuState(data.item, row.item.id)">Hide</button>
                                        <!-- <b-form-group v-slot="{ ariaDescribedby }">
                                            <b-form-radio-group id="radio-group-2" v-model="data.value" @change="assignMainMenuState(data.item)" :aria-describedby="ariaDescribedby" name="radio-sub-component" class="d-flex justify-content-between sbtn py-0" buttons size="sm">
                                                <b-form-radio class="sbtn" button-variant="outline-danger" value="true">Hide</b-form-radio>
                                                <b-form-radio class="sbtn" button-variant="outline-success" value="false">Show</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group> -->
                                        <!-- <b-form-checkbox class="ml-3 float-left pointer" v-model="data.value" @change="">
                                        </b-form-checkbox> -->
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Sub Menu found</div>
                                    </template>
                                </b-table>
                            </div>
                        </template>
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(customMainMenu)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="mainMenuNameChange(data.item, data.value)" @blur="cancelInlineEdit(landingTopMenus, data.field.key)">
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                        </template>
                        <!-- <template v-slot:cell(displayName)="data">
                            <div v-if="data.value">{{ data.value }}</div>
                            <div velse>{{ data.item.module }}</div>
                        </template> -->
                        <template v-slot:cell(hide)="data">
                            <span v-if="data.item.menuHideCount>0" title="Show Menu" class="border bg-light text-danger p-1" >Hidden</span>
                            <span v-else title="Hide Menu" class="border bg-light text-success p-1">Showing</span>
                        </template>

                        <template v-slot:cell(actions)="data">
                            <button v-if="data.item.menuHideCount>0" type="submit" title="Show Menu" class="btn btn-outline-success sbtn btn-xs py-1 px-2" @click="assignMainMenuState(data.item)">Show All</button>
                            <button v-else type="submit" title="Hide Menu" class="btn btn-outline-danger sbtn btn-xs py-1 px-2" @click="assignMainMenuState(data.item)">Hide All</button>
                            <!-- <b-form-group v-slot="{ ariaDescribedby }">
                                <b-form-radio-group id="radio-group-2" v-model="data.value" @change="assignMainMenuState(data.item)" :aria-describedby="ariaDescribedby" name="radio-sub-component" class="d-flex justify-content-between sbtn py-0" buttons size="sm">
                                    <b-form-radio class="sbtn" button-variant="outline-danger" value="true">Hide</b-form-radio>
                                    <b-form-radio class="sbtn" button-variant="outline-success" value="false">Show</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group> -->
                            <!-- <b-form-checkbox class="ml-3 float-left pointer" v-model="data.value" @change="">
                            </b-form-checkbox> -->
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Landing Top Menu found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <div class="separator"></div>
                </b-card-body>
            </b-overlay>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'menuCustomizationPopup' },
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'custom_user_menu',
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,
            stdModuleDisable: false,

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            mainMenuData: {
                vhextAccountId: '',
                userId: '',
                mainMenuId: '',
                actionState: '',
                customMainMenu: '',
            },
            customMenuData: {
                id: '',
                columnName: '',
                stateValue: '',
                entity: '',
            },
            standardMenuTypes: [],
            menuListItem: [],
            adminSettingMenuList: [],
            landingTopMenuList: [],
            landingSubMenuList: [],
            rightVerticalMenuList: [],
            supportServiceTopMenuList: [],
            supportServiceLeftMenuList: [],
            clientTopMenuList: [],
            clientLeftMenuList: [],

            menuFields: [
                { key: 'show_details', label: '' },
                { key: 'index', label: 'SN', },
                // { key: 'customUserRoleId', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'customUserRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'customMainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                // { key: 'displayName', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'hide', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            subMenuFields: [
                { key: 'index', label: 'SN', },
                // { key: 'customUserRoleId', label: 'Id', sortable: true, sortDirection: 'desc' },
                // { key: 'customUserRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Menu', sortable: true, sortDirection: 'desc' },
                { key: 'hide', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        landingTopMenus() {
            return this.landingTopMenuList.map((event)=> {
                if (this.mainMenuData.mainMenuId == event.id) {
                    event._showDetails = true;
                } else {
                    event._showDetails = false;
                }
                return event;
            })
        },
    },
    mounted() {
        this.getCustomUserMenuList();
        // this.lookupData();
    },
    methods: {
        // lookupData: function() {
        //     let data = {
        //         entity: 'standard_menu_type',
        //     }
        //     this.showOverlay = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/lookup/type', data)
        //         .then((response) => {
        //             this.showOverlay = false;
        //             let result = response.data;
        //             this.standardMenuTypes = result.typeLookup;
        //         })
        //         .catch((error)=> {
        //             this.showOverlay = false;
        //             iziToast.error({
        //                 title: 'Error',
        //                 message: 'Error',
        //                 position: 'topRight'
        //             });
        //         })
        // },
        getCustomUserMenuList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/landingtopmenu/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.landingTopMenuList = result.landingTopMainMenuLookup;
                    this.landingSubMenuList = result.landingSubMenuLookup;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        setActiveItem: function(typeLookupId) {
            if (typeLookupId == 1) {
                this.menuListItem = this.adminSettingMenuList;
            } else if (typeLookupId == 2) {
                this.menuListItem = this.landingTopMenuList;
            } else if (typeLookupId == 3) {
                this.menuListItem = this.landingCenterMenuList;
            } else if (typeLookupId == 4) {
                this.menuListItem = this.rightVerticalMenuList;
            } else if (typeLookupId == 5) {
                this.menuListItem = this.supportServiceTopMenuList;
            } else if (typeLookupId == 6) {
                this.menuListItem = this.supportServiceLeftMenuList;
            } else if (typeLookupId == 7) {
                this.menuListItem = this.clientTopMenuList;
            } else if (typeLookupId == 7) {
                this.menuListItem = this.clientLeftMenuList;
            }
        },
        assignMainMenuState: function(item) {
            console.log(item)
            if (item.menuHideCount>0) {
                this.mainMenuData.actionState = 0;
            } else {
                this.mainMenuData.actionState = 1;
            }

            this.mainMenuData.vhextAccountId = this.vhextAccountId;
            this.mainMenuData.mainMenuId = item.id;
            this.mainMenuData.userId = this.userId;
            this.updateMainMenuState();
        },
        updateMainMenuState: function() {
            console.log(this.mainMenuData);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/mainmenu/hideshow/update', this.mainMenuData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-page');
                        this.getCustomUserMenuList();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        mainMenuNameChange: function(item, fieldValue) {
            this.mainMenuData.vhextAccountId = this.vhextAccountId;
            this.mainMenuData.mainMenuId = item.id;
            this.mainMenuData.userId = this.userId;
            this.mainMenuData.customMainMenu = fieldValue;
            this.updateMainMenuName();
        },
        updateMainMenuName: function() {
            console.log(this.mainMenuData);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/custommainmenuname/update', this.mainMenuData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-page');
                        this.getCustomUserMenuList();
                        this.inlineEditRowId='';
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        assignMenuState: function(item, mainMenuId) {
            this.showOverlay = true;
            this.mainMenuData.mainMenuId = mainMenuId;
            if (item.hide == true) {
                this.customMenuData.id = item.id;
                this.customMenuData.stateValue = false;
                this.customMenuData.entity = this.entity;
                this.customMenuData.columnName = 'hide';
                this.updateMenuState();
            } else {
                this.customMenuData.id = item.id;
                this.customMenuData.stateValue = true;
                this.customMenuData.entity = this.entity;
                this.customMenuData.columnName = 'hide';
                this.updateMenuState();
            }
        },
        updateMenuState: function() {
            console.log(this.customMenuData);
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', this.customMenuData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.$emit('refresh-page');
                        this.getCustomUserMenuList();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateInlineTextValue: function(columnName,rowId,fieldValue, mainMenuId) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: this.entity,
                columnName: columnName,
                textValue: fieldValue,
                id: rowId,
            }
            this.mainMenuData.mainMenuId = mainMenuId;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.$emit('refresh-page');
                        this.getCustomUserMenuList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },
        landingSubMenus(mainMenuId) {
            return this.landingSubMenuList.filter((data) => data.mainMenuId == mainMenuId);
        },
        showDetail(id) {
            this.landingTopMenuList = this.landingTopMenuList.map((data) => {
                if (data.id == id) {
                    return { ...data, _showDetails: true }
                } else {
                    return { ...data, _showDetails: false }
                }
            })
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>