<template>
    <div>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-8">
                <div class="card mb-4">
                    <div class="card-body p-3">
<!--                         <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-lg-8 col-sm-6">
                                        <h4 class="pl-2 mb-0 fs-16">Contact</h4>
                                    </div>
                                </div>
                                <div class="separator mb-3"></div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-lg-12 col-xl-12">
                                <div class="card mb-3">
                                    <b-overlay :show="showOverlay" opacity="0.4">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                    <span v-if="!elements.editMode"><i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>New Contact</span>
                                                    <span v-if="elements.editMode">Edit Contact</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                                <b-card-body class="">
                                                    <div>
                                                        <div class="row mb-2">
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="contactName">Contact Name</label>
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.contact.contactName.$error }">
                                                                        <input type="text" maxlength="100" class="form-control" id="contactName" v-model="contact.contactName">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <!-- </select> -->
                                                                    <label v-if="$v.contact.contactName.$error && !$v.contact.contactName.required" class="text-danger">Please Fill Contact Name</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="firstName">First Name</label>
                                                                    <input type="text" maxlength="20" class="form-control" id="firstName" v-model="contact.firstName">
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="middleName">Middle Name</label>
                                                                    <input type="text" maxlength="20" class="form-control" id="middleName" v-model="contact.middleName">
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="lastName">Last Name</label>
                                                                    <input type="text" maxlength="20" class="form-control" id="lastName" v-model="contact.lastName">
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="emailId">Email</label>
                                                                    <input type="text" maxlength="100" class="form-control" id="emailId" v-model="contact.emailId">
                                                                </div>
                                                            </div>
                                                            <div class="col-3">
                                                                <div class="form-group">
                                                                    <label for="mobile">Mobile</label>
                                                                    <input type="text" maxlength="20" class="form-control" id="mobile" v-model="contact.mobile">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="card-body p-0 mb-3">
                                                                    <b-card no-body class="">
                                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                                <span>More Contact Information</span>
                                                                            </label>
                                                                        </b-card-header>
                                                                        <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                            <b-card-body class="p-1">
                                                                                <div class="row">
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="phone">Phone</label>
                                                                                            <input type="text" maxlength="20" class="form-control" id="phone" v-model="contact.phone">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="streetName">Office Phone</label>
                                                                                            <input type="text" maxlength="20" class="form-control" id="streetName" v-model="contact.officePhone">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="alternateEmailId">Alternate Email</label>
                                                                                            <input type="text" maxlength="100" class="form-control" id="alternateEmailId" v-model="contact.alternateEmailId">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="alternateMobile">Alternate Mobile</label>
                                                                                            <input type="text" maxlength="20" class="form-control" id="alternateMobile" v-model="contact.alternateMobile">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </b-card-body>
                                                                        </b-collapse>
                                                                    </b-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="card-body p-0 mb-3">
                                                                    <b-card no-body class="">
                                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                                <span>Work Details</span>
                                                                            </label>
                                                                        </b-card-header>
                                                                        <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                            <b-card-body class="p-1">
                                                                                <div class="row">
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="position">Job Title</label>
                                                                                            <input type="text" maxlength="100" class="form-control" id="position" v-model="contact.position">
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="company">Employer</label>
                                                                                            <input type="text" maxlength="100" class="form-control" id="company" v-model="contact.company">
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </b-card-body>
                                                                        </b-collapse>
                                                                    </b-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="card-body p-0 mb-3">
                                                                    <b-card no-body class="">
                                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                                <span>Address</span>
                                                                            </label>
                                                                        </b-card-header>
                                                                        <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                            <b-card-body class="p-1">
                                                                                <div class="row">
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="address1">Building Name</label>
                                                                                            <input type="text" maxlength="45" class="form-control" id="address1" v-model="contact.address1">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="streetName">Street Name</label>
                                                                                            <input type="text" maxlength="45" class="form-control" id="streetName" v-model="contact.streetName">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="city">City</label>
                                                                                            <input type="text" maxlength="45" class="form-control" id="city" v-model="contact.city">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="state">State</label>
                                                                                            <input type="text" maxlength="45" class="form-control" id="state" v-model="contact.state">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="countryId">Country</label>
                                                                                            <select v-model="contact.countryId" class="form-control">
                                                                                                <option value="">Select</option>
                                                                                                <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="pincode">Postal Code</label>
                                                                                            <input type="text" maxlength="10" class="form-control" id="pincode" v-model="contact.pincode">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="timezone">Timezone</label>
                                                                                            <!-- <input type="text" maxlength="45" class="form-control" id="timezone" v-model="contact.timezone"> -->
                                                                                            <Select2 v-model="contact.timeZone" :options="timeZones" :settings="{width:'100%'}" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </b-card-body>
                                                                        </b-collapse>
                                                                    </b-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="card-body p-0 mb-3">
                                                                    <b-card no-body class="">
                                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                                <span>Social Connect</span>
                                                                            </label>
                                                                        </b-card-header>
                                                                        <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                            <b-card-body class="p-1">
                                                                                <div class="row">
                                                                                    <div class="col-4">
                                                                                        <div class="form-group">
                                                                                            <label for="website">Web Address</label>
                                                                                            <input type="text" maxlength="500" class="form-control" id="website" v-model="contact.website">
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-4">
                                                                                        <div class="form-group">
                                                                                            <label for="facebookId">Facebook</label>
                                                                                            <input type="text" maxlength="500" class="form-control" id="facebookId" v-model="contact.facebookId">
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </b-card-body>
                                                                        </b-collapse>
                                                                    </b-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="card-body p-0 mb-3">
                                                                    <b-card no-body class="">
                                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                            <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                                <span>Personal Information</span>
                                                                            </label>
                                                                        </b-card-header>
                                                                        <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                            <b-card-body class="p-1">
                                                                                <div class="row">
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="dateOfBirth">Birthday</label>
                                                                                            <input type="date" class="form-control" id="dateOfBirth" v-model="contact.dateOfBirth">
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <label for="gender">Gender</label>
                                                                                            <input type="text" class="form-control" id="anniversary" v-model="contact.gender">
                                                                                        </div>
                                                                                    </div> -->
                                                                                    <div class="col-3">
                                                                                        <div class="form-group">
                                                                                            <!-- <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                                                                                              <b-form-radio-group id="btn-radios-1" v-model="contact.gender" :options="genderOptions" class="pt-1" :aria-describedby="ariaDescribedby" button-variant="outline-secondary" size="xs" name="radios-btn-default" buttons></b-form-radio-group>
                                                                                            </b-form-group> -->
                                                                                            <b-form-group label="Gender" v-slot="{ ariaDescribedby }">
                                                                                                <b-form-radio-group id="radio-group-2" v-model="contact.gender" :aria-describedby="ariaDescribedby" name="radio-sub-component">
                                                                                                    <b-form-radio value="M">Male</b-form-radio>
                                                                                                    <b-form-radio value="F">Female</b-form-radio>
                                                                                                </b-form-radio-group>
                                                                                            </b-form-group>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </b-card-body>
                                                                        </b-collapse>
                                                                    </b-card>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                        <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                        <template #overlay>
                                            <div class="text-center">
                                                <LoadingSpinner />
                                            </div>
                                    </template>
                                </b-overlay>
                                </div>
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label class="d-block p-1 accordion-title">Contacts
                                                </label>
                                            </div>
                                        </div>                                

                                        <div class="float-right">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :items="contacts" @row-selected="onRowSelected" :fields="fields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
<!--                                     <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template> -->
                                    <template #cell(contactName)="data">
                                        <div class="row no-gutters align-items-center">
                                            <div class="col-auto">
                                                <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                                                <b-avatar v-else class="mr-2"></b-avatar>
                                            </div>
                                            <div class="col">
                                                <div class="mr-auto">{{ data.value }}</div>
                                                <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteContact(data.item.id)" v-b-modal.deleteContact><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Contacts Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
<!--                                 <div class="float-left">
                                    <div class="dataTables_length">
                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                        </b-form-group>
                                    </div>
                                </div> -->

                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left ml-3">{{this.totalRows}} Items</span>                                

<!--                                 <b-pagination v-if="contacts!= null && contacts.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-xl-4 pl-0">
                <div id="profile" class=" h-100">
                    <div class="profile_header">
                        <div class="row">
                            <div class="col-6 col-lg-6 col-xl-6 pl-4 float-left text-left mb-2">
                                <div class="my-profile-img">
                                    <img :src="contactDetail.photo" alt="Profile picture">
                                    <label class="upload-icon upload-icon-contacts">
                                        <i class="fa fa-camera"></i>
                                        <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="uploadPhoto">
                                    </label>
                                </div>
                            </div>
                            <div class="col-6 col-lg-6 col-xl-6 pl-0 text-left">
                                <div>
                                    <h6 class="mb-0">{{contactDetail.contactName}}</h6>
                                    <p class="m-0">
                                        <span v-if="contactDetail.company!=null && contactDetail.company!='' && contactDetail.position!=null && contactDetail.position!='' ">{{contactDetail.company}} / {{contactDetail.position}}</span>
                                        <span v-else>{{contactDetail.company}}{{contactDetail.position}}</span>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-left pl-4">
                                <label v-if="contactDetail != null && contactDetail.emailId != null && contactDetail.emailId.length != 0"><i class="fa fa-envelope-o ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.emailId}}</label>
                                
                            </div>
                        </div>
                        <div v-if="contactDetail.phone != null && contactDetail.phone != '' && contactDetail.mobile != 0" class="row">
                            <div >
                                <label v-if="contactDetail != null && contactDetail.phone != null && contactDetail.phone.length != 0" class="text-left pl-4"><i class="fa fa-phone ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.phone}}</label>

                                <label v-if="contactDetail != null && contactDetail.mobile != null && contactDetail.mobile.length != 0" class="text-right pr-2"><i class="fa fa-mobile ml-4 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.mobile}}</label>
                            </div>
                        </div>
                        <div v-else class="row">
                            <div>
                                <label v-if="contactDetail.phone != null && contactDetail.phone.length != 0" class="text-left"><i class="fa fa-phone ml-4 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.phone}}</label>

                                <label v-if="contactDetail.mobile != null && contactDetail.mobile.length != 0" class="text-left"><i class="fa fa-mobile ml-4 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.mobile}}</label>
                                
                            </div>
                        </div>

                    </div>
                    <div class="divider"></div>
                    <div class="profile_custom">
                        <h3 class="mt-3 mb-3">Address Information</h3>
                        <div class="pl-2">
                            <p>
                                <i class="fa fa-map-marker ml-0 mr-1 action-link" aria-hidden="true"></i>
                                <span v-if="contactDetail.address1 != ''">{{contactDetail.address1}}</span>
                                <span v-if="contactDetail.streetName != ''">, {{contactDetail.streetName}}</span>
                                <span v-if="contactDetail.city != ''">, {{contactDetail.city}}</span>
                                <span v-if="contactDetail.state != ''">, {{contactDetail.state}}</span>
                                <span v-if="contactDetail.country != ''">, {{contactDetail.country}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="profile_custom" v-if="contactDetail.alternateEmailId.length != 0 || contactDetail.officePhone.length != 0 || contactDetail.alternateMobile.length != 0">
                        <h3 class="mt-3 mb-3">Alt Contact Information</h3>
                        <div class="pl-2">
                            <p v-if="contactDetail.alternateEmailId != null && contactDetail.alternateEmailId !=''"><i class="fa fa-envelope-o ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.alternateEmailId}}</p>
                            <div class="row pl-2">
                                <div class="text-left pl-2">
                                    <label v-if="contactDetail != null && contactDetail.officePhone != null && contactDetail.officePhone.length != 0" ><i class="fa fa-phone ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.officePhone}}</label>
                                    
                                </div>
                                <div class="text-right pl-4 pr-2">
                                    <label v-if="contactDetail != null && contactDetail.alternateMobile != null && contactDetail.alternateMobile.length != 0"><i class="fa fa-mobile ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.alternateMobile}}</label>
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="divider"></div>
                    <div class="profile_custom" v-if="contactDetail.website != null && contactDetail.facebookId != null">
                        <h3 class="mt-3 mb-3">Social Connect</h3>
                        <div class="pl-2 mb-2">
                            <a v-if="contactDetail.website != null && contactDetail.website !=''" target="_blank" :href="contactDetail.website"><i class="fa fa-globe ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{contactDetail.website}}</a>

                            <p class="ml-0 mt-2 mr-4" v-if="contactDetail.facebookId != null && contactDetail.facebookId !=''"><i class="fa fa-facebook-square ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.facebookId}}</p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="profile_custom" v-if="contactDetail.dateOfBirth != null || contactDetail.gender !=''">
                        <h3 class="mt-3 mb-3">Personal Information</h3>
                            <div class="row pl-2">
                                <div class="text-left pl-3">
                                    <label v-if="contactDetail != null && contactDetail.dateOfBirth != null && contactDetail.dateOfBirth.length != 0" ><i class="fa fa-birthday-cake ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.dateOfBirth | formatDate}}</label>
                                    
                                </div>
                                <div class="text-right pl-4 pr-2">
                                    <label v-if="contactDetail != null && contactDetail.gender != null && contactDetail.gender.length != 0" class="ml-0 mr-2"><i class="fa fa-mobile ml-0 mr-1 font-weight-bold action-link" aria-hidden="true"></i> {{contactDetail.gender}}</label>
                                    
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteContact" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Contact" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Select2
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            defaultPhoto: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/avatar.png',

            elements: {
                editMode: false,
                visible: false,
                showProgress: false,
            },
            contact: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                userId: localStorage.getItem("userId"),
                addedBy: '',
                contactName: '',
                position: '',
                emailId: '',
                mobile: '',
                phone: '',
                firstName: '',
                middleName: '',
                lastName: '',
                alternateEmailId: '',
                alternateMobile: '',
                officePhone: '',
                photo: '',
                countryId: '',
                country: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                pincode: '',
                website: '',
                facebookId: '',
                gender: '',
                dateOfBirth: '',
                refEntity: 'contact',


            },
            notEnabled: true,
            countries: [],
            timeZones: [],
            // contact: {
            //     addedBy: ''
            // },
            contactDetail: {
                contactId: '',
                contactName: '',
                addedBy: '',
                firstName: '',
                middleName: '',
                lastName: '',
                company: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                timeZone: '',
                website: '',
                facebookId: '',
                gender: '',
                dateOfBirth: '',
                photo: '',
                active: '',
                createdDt: '',
                lastUpdatedDt: '',
                photo: this.defaultPhoto,
                refEntity: 'contact',

            },
            genderOptions: [
              { text: 'Male', value: 'M' },
              { text: 'Female', value: 'F' },
              { text: 'Transgender', value: 'T' }
            ],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            contacts: [],
            fields: [
                // A virtual column that doesn't exist in items
                // { key: 'index', label: 'SN' },
                { key: 'contactName', label: 'Contact Name', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Job Title', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email', sortable: true, sortDirection: 'desc' },
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                // { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'city', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions'}
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.lookupData();
        this.getContact();
    },
    validations: {
        contact: {
            contactName: {
                required
            }
        }
    },
    methods: {
        getContact: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/user/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result != null && result.length != 0) {
                        this.contacts = result.userContactList;
                        if (this.contacts != null && this.contacts.length > 0) {
                            this.bindSelectedData(this.contactDetail, this.contacts[0]);
                            this.totalRows = this.contacts.length;
                            this.$emit('updateContactCount', this.totalRows);
                        }
                        console.log(this.contacts);
                    }
                    this.elements.showProgress = true;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.countries = result.countryLookup;
                    this.timeZones = result.timeZoneLookup;
                })
        },
        saveData: function() {
            console.log(this.contact);
            this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {
                this.showOverlay = true;
                if (this.elements.editMode) {
                    this.updateContact();
                } else {
                    this.saveContact();
                }
            }
        },
        saveContact: function() {
            console.log(this.contact);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contact)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateContact: function() {
            console.log(this.contact);
            // return;
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contact)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(this.contact);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();                        
                        this.viewContact(this.contact.id);
                        this.clearData();


                    }
                    console.log(result);
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewContact: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/contact/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.bindSelectedData(this.contactDetail, result.contactView);
                })

        },
        deleteContact: function(id) {
            this.deleteParam.id = id;
            console.log(this.deleteParam.id);
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/contact/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        clearData: function() {

            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.contact.id = '';
            this.contact.contactName = '';
            this.contact.active = '';
            this.contact.position = '';
            this.contact.emailId = '';
            this.contact.mobile = '';
            this.contact.firstName = '';
            this.contact.middleName = '';
            this.contact.lastName = '';
            this.contact.photo = '';
            this.contact.countryId = '';
            this.contact.country = '';
            this.contact.buildingName = '';
            this.contact.streetName = '';
            this.contact.city = '';
            this.contact.state = '';
            this.contact.pincode = '';
            this.contact.website = '';
            this.contact.addedBy = '';
            this.contact.address1 = '';
            this.contact.streetName = '';
            this.contact.alternateEmailId = '';
            this.contact.alternateMobile = '';
            this.contact.company = '';
            this.contact.contactId = '';
            this.contact.dateOfBirth = '';
            this.contact.facebookId = '';
            this.contact.skypeId = '';
            this.contact.gender = '';
            this.contact.linkedinId = '';
            this.contact.officePhone = '';
            this.contact.phone = '';
            this.contact.timeZone = '';
            this.contact.twitterId = '';
            this.$v.contact.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.bindSelectedData(this.contact, data);
            /*  this.contact.id = data.id;
              this.contact.contactName = data.contactName;
              this.contact.active = data.active;
              this.contact.position = data.position;
              this.contact.emailId = data.emailId;
              this.contact.mobile = data.mobile;
              this.contact.firstName = data.firstName;
              this.contact.middleName = data.middleName;
              this.contact.lastName = data.lastName;
              this.contact.photo = data.photo;
              this.contact.countryId = data.countryId;
              this.contact.country = data.country;
              this.contact.buildingName = data.buildingName;
              this.contact.streetName = data.streetName;
              this.contact.city = data.city;
              this.contact.state = data.state;
              this.contact.pincode = data.pincode;
              this.contact.website = data.website;
              this.contact.addedBy = data.addedBy;
              this.contact.address1 = data.address1;
              this.contact.address2 = data.address2;
              this.contact.alternateEmailId = data.alternateEmailId;
              this.contact.alternateMobile = data.alternateMobile;
              this.contact.company = data.company;
              this.contact.contactId = data.contactId;
              this.contact.dateOfBirth = data.dateOfBirth;
              this.contact.facebookId = data.facebookId;
              this.contact.skypeId = data.skypeId;
              this.contact.gender = data.gender;
              this.contact.linkedinId = data.linkedinId;
              this.contact.officePhone = data.officePhone;
              this.contact.phone = data.phone;
              this.contact.refEntityId = data.refEntityId;
              this.contact.refRecordId = data.refRecordId;
              this.contact.timeZone = data.timeZone;
              this.contact.twitterId = data.twitterId;
              this.contact.userId = data.userId;
              this.contact.vhextAccountId = data.vhextAccountId;*/
        },
        showView: function(data) {
            this.elements.visible = false;
            this.elements.viewMode = true;
            this.bindSelectedData(this.contactDetail, data);
        },
        onRowSelected: function(items) {
            console.log(items);
            // alert('a');
            if (items !== null && items.length !== 0) {
                let data = items[0];
                console.log(data);
                this.elements.showDetail = true;
                this.bindSelectedData(this.contactDetail, data);
            }
        },
        bindSelectedData: function(object, data) {
            // this.contactDetail.alternateEmailId=!data.alternateEmailId?data.alternateEmailId : '' ;
            // this.contactDetail = data;

            object.id = data.id;
            object.contactName = data.contactName;
            object.active = data.active;
            object.position = data.position;
            object.emailId = data.emailId;
            object.mobile = data.mobile;
            object.firstName = data.firstName;
            object.middleName = data.middleName;
            object.lastName = data.lastName;
            object.photo = data.photo;
            object.countryId = data.countryId;
            object.country = data.country;
            object.buildingName = data.buildingName;
            object.streetName = data.streetName;
            object.city = data.city;
            object.state = data.state;
            object.pincode = data.pincode;
            object.website = data.website;
            object.addedBy = data.addedBy;
            object.address1 = data.address1;
            object.streetName = data.streetName;
            object.alternateEmailId = data.alternateEmailId;
            object.alternateMobile = data.alternateMobile;
            object.company = data.company;
            object.contactId = data.contactId;
            object.dateOfBirth = data.dateOfBirth;
            object.facebookId = data.facebookId;
            object.skypeId = data.skypeId;
            object.gender = data.gender;
            object.linkedinId = data.linkedinId;
            object.officePhone = data.officePhone;
            object.phone = data.phone;
            object.refEntityId = data.refEntityId;
            object.refRecordId = data.refRecordId;
            object.timeZone = data.timeZone;
            object.twitterId = data.twitterId;
            object.userId = data.userId;
            object.vhextAccountId = data.vhextAccountId;
            object.createdDt = data.createdDt;
            object.lastUpdatedDt = data.lastUpdatedDt;
            object.photo = data.photo == null ? this.defaultPhoto : data.photo;
        },
        uploadPhoto: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: 'File format not supported',
                    position: 'topRight'
                });
                return false;
            }

            let formData = new FormData();
            formData.append('file', this.$refs.profile_photo.files[0]);
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('id', this.contactDetail.id);

            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    var result = response.data;
                    iziToast.success({
                        title: 'Success',
                        message: 'Profile photo uploaded Successfully',
                        position: 'topRight'
                    });

                    this.viewContact(this.contactDetail.id);
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Unable to update photo',
                        position: 'topRight'
                    });
                });
        }
    }
}
</script>