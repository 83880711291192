<template>
	<div>
		<b-overlay :show="showOverlay" opacity="0.4">
	        <b-table show-empty small outlined stacked="md"  sort-icon-left :items="plans" :fields="planFields" responsive="sm"  :current-page="currentPage" @filtered="onFiltered" :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >                               
	            <template #cell(index)="data">
	                {{ data.index + 1 }}
	            </template>
	            <template v-slot:cell(phaseTitle)="data">
	                <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
	            </template>
	            <template v-slot:cell(description)="data">
	                <span class="tooltiptext" href="javascript:" v-html="data.value"></span>
	            </template>
	            <template v-slot:cell(status)="data">
	                <div>
	                  <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
	                  <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
	                  <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
	                  <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
	                  <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
	                  <b-badge v-else-if="data.item.status == 'Reopened'" variant="warning">{{data.value}}</b-badge>
	                  <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
	                  <b-badge v-else variant="primary">{{data.value}}</b-badge>
	                </div>
	            </template>
	            <template v-slot:empty="scope">
	                <div class="text-center">No Record found</div>
	            </template>
	            <template v-slot:emptyfiltered="scope">
	                <div class="text-center">{{ scope.emptyFilteredText }}</div>
	            </template>
	        </b-table>
	        <b-pagination v-if="plans.length!=0" pills v-model="currentPage" :total-rows="totalRows" first-number last-number :per-page="perPage" align="fill" size="sm" class="my-0 float-right"></b-pagination>
	        <div class="float-left">
	            <div class="dataTables_length">
	                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
	                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
	                </b-form-group>
	            </div>
	        </div>
		    <template #overlay>
                <div class="text-center">
                    <LoadingSpinner />
                </div>
            </template>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            plans: [],
            planFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                // { key: 'planId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'phaseTitle', label: 'Title', sortable: true, sortDirection: 'desc' },
                // { key: 'process', label: 'Process', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Description', tdClass: 'w-30', sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                // { key: 'effort', label: 'Effort', sortable: true, sortDirection: 'desc' },
                // { key: 'estimatedCost', label: 'Cost', sortable: true, sortDirection: 'desc' },
                { key: 'responsibility', label: 'Responsible Person', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],


            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    methods: {
        getErdDetailList: function(ermId) {
            if (ermId) {
                let data = {
                    entityRecordMasterId: ermId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.plans = result.planList;
                        this.totalRows = this.plans.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                    })
            }
            else {
            	this.plans = [];
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>