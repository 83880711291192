<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Workflow Requests</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row mb-4">
                <div class="col-lg-8 col-xl-8">
                    <div class="card">
                        <div class="card-body p-3">
                            <b-tabs v-model="tabIndex" v-if="!showItemDetail" content-class="mt-3" lazy>
                                <b-tab title="Request Pending">
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <b-table ref="workflowRequestTable" v-model="mainTableArray1" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workflowRequests" :fields="workflowRequestFields" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <!-- <template v-slot:cell(functionalEntity)="data">
                                                <a href="javascript:" class="action-link" :title="data.value" @click="viewReference(data.item, data.item.actionItemTitle)">{{ data.value }}</a>
                                            </template> -->
                                            <template v-slot:cell(actionItemStartDt)="data">
                                                <div v-if="data.value">{{ data.value }}</div>
                                                <div v-else class="text-center">NA</div>
                                            </template>
                                            <template v-slot:cell(actionItemDueDt)="data">
                                                <div v-if="data.value">{{ data.value }}</div>
                                                <div v-else class="text-center">NA</div>
                                            </template>
                                            <template v-slot:cell(isApproval)="data">
                                                <div>
                                                    <p v-if="data.value == false">
                                                        <span v-if="data.item.reviewDt">Reviewed</span>
                                                        <span v-else>Review Pending</span>
                                                    </p>
                                                    <p v-else>
                                                        <span v-if="data.item.reviewDt">Approved</span>
                                                        <span v-else>Approval Pending</span>
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View" @click="viewItemDetails(data.item)"><i class="blue fa fa-eye p-1"></i></a>
                                            </template>
                                            <template v-slot:empty="">
                                                <div class="text-center">No Document found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="">
                                                <div class="text-center">{{ }}</div>
                                            </template>
                                        </b-table>
                                        <div class="separator mb-2"></div>
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                                
                                        <b-pagination v-if="workflowRequests.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                    </div>
                                </b-tab>
                                <b-tab title="Request History">

                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <b-table ref="workflowRequestHistoryTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="workflowRequestHistory" :fields="workflowRequestFields" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template #cell(index)="data">
                                                {{ data.index + 1 }}
                                            </template>
                                            <template v-slot:cell(actionItemTitle)="data">
                                                <a href="#" class="action-link" :title="data.value" @click="viewReference(data.item, data.item.functionality)">{{ data.value }}</a>
                                            </template>
                                            <template v-slot:cell(initiatedBy)="data">
                                                <div v-if="data.item.initiatedBy">{{ data.item.initiatedBy }}</div>
                                                <div v-else>{{data.item.createdBy}}</div>
                                            </template>
                                            <template v-slot:cell(actionItemStartDt)="data">
                                                <div v-if="data.value">{{ data.value }}</div>
                                                <div v-else class="text-center">NA</div>
                                            </template>
                                            <template v-slot:cell(actionItemDueDt)="data">
                                                <div v-if="data.value">{{ data.value }}</div>
                                                <div v-else class="text-center">NA</div>
                                            </template>
                                            <template v-slot:cell(isApproval)="data">
                                                <div>
                                                    <p v-if="data.value == false">
                                                        <span v-if="data.item.reviewDt">Reviewed</span>
                                                        <span v-else>Review Pending</span>
                                                    </p>
                                                    <p v-else>
                                                        <span v-if="data.item.reviewDt">Approved</span>
                                                        <span v-else>Approval Pending</span>
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View" @click="viewItemDetails(data.item)"><i class="blue fa fa-eye p-1"></i></a>
                                            </template>

                                            <!-- <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View" @click="viewReference(data.item, data.item.actionItemTitle)"><i class="grey fa fa-eye p-0" ></i></a>
                                            </template> -->
                                            <template v-slot:empty="">
                                                <div class="text-center">No Document found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="">
                                                <div class="text-center">{{ }}</div>
                                            </template>
                                        </b-table>
                                        <div class="separator mb-2"></div>    
                                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalHistoryRows}} Items</span>                                
                                        <b-pagination v-if="workflowRequestHistory.length!=0" pills v-model="currentPage" :total-rows="totalHistoryRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                    </div>
                                </b-tab>

                            </b-tabs>
                            <template v-else>
                                <div class="row">
                                    <div class="col">
                                        <label class="mb-0 pt-2">{{ itemDetailTitle }}</label>
                                    </div>
                                    <div class="col text-right">
                                        <b-button v-if="showItemDetail" class="btn sbtn btn-xs float-right px-2" variant="outline-danger" title="Close" @click="showItemDetail = false"><i class="fa fa-times pr-1"></i>Close</b-button>
                                    </div>
                                </div>
                                <div class="separator mt-1 mb-3"></div>
                                <component :ref="itemDetailCompRef" :is="activeComponent"></component>
                                <!-- <LeaveTimeDetailComponent ref="leaveTimeDetailCompRef" :attachmentData="attachmentData" /> -->
                            </template>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <StatusTracker v-show="elements.showDetail" ref="statusUpdate" :key="statusTrackerKey" :statusUpdate="statusUpdate" @updateParent="getRequestList"></StatusTracker>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ERMCopyConfirmation ref="ermCopyConfirmRef" @refreshData="getRequestList" />
        <LeaveTimeDetailPopup ref="leaveTimeDetailPopupRef" :referenceDetailId="referencePopupId" />
        <MeetingPrepartionDetailPopup ref="meetingPrepartionDetailPopupRef" />
        <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <ReviewApprovalDetailPopup ref="reviewApprovalDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <TaskDetailPopup ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <ShareDetailPopup ref="shareDetailPopupRef" :detailViewData="detailViewData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import redirectMixin from "@/mixins/redirect";
import ERMCopyConfirmation from '@/components/popup/dialogbox/ERMCopyConfirmation.vue';
import EmploymentChangeDetailComponent from '@/components/itemdetailcomponents/EmploymentChangeDetailComponent.vue';
import LeaveTimeDetailComponent from '@/components/itemdetailcomponents/LeaveTimeDetailComponent.vue';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue';
import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
import ReviewApprovalDetailPopup from '@/components/popup/itemdetail/ReviewApprovalDetailPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import ShareDetailPopup from '@/components/popup/itemdetail/ShareDetailPopup.vue';
import StatusTracker from '@/components/common/StatusTracker.vue';
export default {
    name: 'app',
    mixins: [redirectMixin],
    props: {
        ermMasterInfo: Object
    },
    components: {
        ERMCopyConfirmation,
        EmploymentChangeDetailComponent,
        LeaveTimeDetailComponent,
        LeaveTimeDetailPopup,
        MeetingPrepartionDetailPopup,
        ReferenceDetailPopup,
        ReviewApprovalDetailPopup,
        ShareDetailPopup,
        StatusTracker,
        TaskDetailPopup,
    },
    data() {
        return {
            elements: {
                visible: false,
                showDetail: false,
            },
            showItemDetail: false,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),    
            userId: localStorage.getItem("userId"),        
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            mainTableArray1: [],
            mainTableArray: [],
            referencePopupId: 0,
            activeComponent: '',
            itemDetailCompRef: '',

            reviewConfig: {
                id: '',
            },

            statusTrackerKey: 0,
            itemDetailTitle: '',
            attachmentData: {},

            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                refEntityId: '',
                refEntity: '',
                statusById: localStorage.getItem("userId"),
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
                workflowRequestId: '',
                workflowActivityId: '',
                isWorkflowAutomated: true,
            },

            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},

            workflowRequests: [],
            workflowRequestHistory: [],

            workflowRequestFields: [
                { key:'index',label: 'SN' },
                // { key: 'actionItemId', label: 'Id', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'subject', label: 'Title', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'functionality', label: 'Functionality', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'initiatedBy', label: 'Requested By', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'createdDt', label: 'Request Date',formatter: 'formatDateTimeYearCheck', sortable: true, sortDirection: 'desc', class: '' },
                
                // { key: 'statusBy', label: 'Status By', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' },
            ],

            selectMode: 'single',
            totalRows: 1,
            totalHistoryRows: 1,

            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: true,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    mounted() {
        this.getRequestList();
    },

    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onHistoryFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalHistoryRows = filteredItems.length
            this.currentPage = 1
        },

        getRequestList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: localStorage.getItem("userId"),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/general/workflowrequest/list', data)
                .then((response) => {
                    let result = response.data;
                    this.workflowRequests = result.workflowRequestList;
                    this.workflowRequestHistory = result.workflowRequestHistoryList;

                    this.totalRows = this.workflowRequests.length;
                    this.totalHistoryRows = this.workflowRequestHistory.length;

                    if (this.workflowRequests.length > 0) {
                        if (this.tabIndex == 0) {
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.statusUpdate.id, this.mainTableArray1, 'workflowRequestTable');
                            })
                        }
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                    if (this.workflowRequestHistory.length > 0) {
                        if (this.tabIndex == 1) {
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.statusUpdate.id, this.mainTableArray, 'workflowRequestHistoryTable');
                            })
                        }
                    }
                    console.log(result);
                })

        },
        viewReference: function(data, title) {
            console.log(data);
            this.referencePopupId = data.refRecordId;
            this.refPopupTitle = title;
            this.refPopupEntity = data.refEntity;
            this.detailViewData = data;
            
            if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            } 
            else if (this.refPopupEntity == 'APV' || this.refPopupEntity == 'REV') {
                this.$refs.reviewApprovalDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'EMAIL') {
                this.$refs.emailDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'SHARE') {
                this.$refs.shareDetailPopupRef.getDetails();
            }
            else if (this.refPopupEntity == 'MTG') {
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.referencePopupId, data.entity, null);
            }
            else if (this.refPopupEntity == 'leave_time') {
                this.$nextTick(() => {
                    this.$refs.leaveTimeDetailPopupRef.getDetails(this.referencePopupId, data.refEntity);
                })
            }
            else {
                this.$nextTick(() => {
                    this.$bvModal.show('referenceDetail');
                })
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
                console.log(this.referencePopupId, this.refPopupEntity, data);
            }
        },
        acceptDocumentCopy: function(id, entityRecordId, status) {
            this.$refs.ermCopyConfirmRef.getERMCopyData(id, entityRecordId, status);
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.showItemDetail = false;
                this.assignStatusUpdate(data);
            }
        },
        viewItemDetails(data) {
            this.itemDetailTitle = data.functionality;
            let referencePopupId = parseInt(data.refRecordId);
            this.attachmentData.refEntity = data.refEntity;
            this.attachmentData.refRecordId = data.id;
            this.showItemDetail = true;
            this.$nextTick(() => {
                if (data.refEntity == 'leave_time') {
                    this.activeComponent = 'LeaveTimeDetailComponent';
                    this.itemDetailCompRef = 'leaveTimeDetailCompRef';
                    this.$nextTick(() => {
                        this.$refs[this.itemDetailCompRef].getDetails(referencePopupId, data.refEntity);
                    });
                } else if (data.refEntity == 'employment_change') {
                    this.activeComponent = 'EmploymentChangeDetailComponent';
                    this.itemDetailCompRef = 'employmentChangeDetailCompRef';
                    this.$nextTick(() => {
                        this.$refs[this.itemDetailCompRef].getDetails(referencePopupId, data.refEntity);
                    });
                }
            })
        },
        assignStatusUpdate: function(data) {
            this.$refs.statusUpdate.clearData();
            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.functionalEntityId = data.functionalEntityId;
            this.statusUpdate.functionalEntity = data.functionalEntity;
            this.statusUpdate.refRecordTitle = data.subject;
            this.statusUpdate.refEntity = data.refEntity;
            this.statusUpdate.refRecordId = data.refRecordId;
            this.statusUpdate.statusById = this.userId;
            this.statusUpdate.statusId = data.statusId;
            this.statusUpdate.status = data.status;
            this.statusUpdate.remarks = data.remarks;
            this.statusUpdate.workflowRequestId = data.id;
            this.statusUpdate.workflowActivityId = data.workflowActivityId;
            this.statusUpdate.isWorkflowAutomated = true;
            // this.statusUpdate.workflowRequestId = data.id;
            //call child component method
            console.log(data);
            this.$refs.statusUpdate.onTitleChange();
            this.statusTrackerKey++;
        },
    }
}
</script>