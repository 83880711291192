<template>
    <div class="referenceDetailPopup">
        <b-modal id="payslipDetailAutoGenPopup" ref="payslipDetailAutoGenPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Payslip" ok-only>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div id="printMe" class="row">
                    <div class="col-12 mb-3">
                        <div class="form-row">
                            <div class="col-2">
                                <label class="m-0 py-1">Monthly CTC</label>
                            </div>
                            <div class="col-4 pl-0">
                                <VhextMoneyControl :value="Math.abs(Number(payslipAutoGenData.monthlyCTC))" @money-input="moneyControlInput" />
                                <label v-if="$v.payslipAutoGenData.monthlyCTC.$error && !$v.payslipAutoGenData.monthlyCTC.required" class="text-danger">Please enter monthly CTC</label>
                            </div>
                            <div class="col-5">
                                <b-button class="btn sbtn btn-xs px-2 mr-1" variant="primary" @click="viewAutoGenPayslip">Go</b-button>
                                <b-button v-if="showPayslip" class="btn sbtn btn-xs px-2" variant="outline-primary" @click="clearData">Reset</b-button>
                            </div>
                        </div>
                    </div>
                    <PayslipAutoGenComponent v-if="showPayslip" :key="payslipAutoGenCompKey" ref="payslipAutoGenCompRef" :payslipAutoGenData="payslipAutoGenData" />
                    <!-- <div v-if="showPayslip" class="col-12 text-left">
                        <div class="form-row mb-2">
                            <div class="col-6 col-sm-6">
                                <div class="card">
                                    <div class="card-body py-1 px-0">
                                        <div class="title-block p-2">
                                            <p class="contact-title mb-0">Earnings</p>
                                        </div>
                                        <div class="row" v-for="earning,index in earningList">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-2 d-block header-details">{{ earning.payslipComponent }}</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right">
                                                    {{ currencySymbol }} {{ earning.amount ? (earning.amount).toFixed(2) : '-' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6">
                                <div class="card">
                                    <div class="card-body py-1 px-0">
                                        <div class="title-block p-2">
                                            <p class="contact-title mb-0">Deductions</p>
                                        </div>
                                        <div class="row" v-for="deduction,index in deductionList">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 p-2 d-block header-details">{{ deduction.payslipComponent }}</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right">
                                                    {{ currencySymbol }} {{ deduction.amount ? (deduction.amount).toFixed(2) : '-' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="col-6 col-sm-6">
                                <div class="row">
                                    <div class="col-6 col-md-6 pr-0">
                                        <label class="mb-0 py-2 d-block title-block">Total Earnings</label>
                                    </div>
                                    <div class="col-6 col-md-6 pl-0">
                                        <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">
                                            {{ currencySymbol }} {{ totalEarnings ? (totalEarnings).toFixed(2) : '-' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-sm-6">
                                <div class="row">
                                    <div class="col-6 col-md-6 pr-0">
                                        <label class="mb-0 py-2 d-block title-block">Total Deductions</label>
                                    </div>
                                    <div class="col-6 col-md-6 pl-0">
                                        <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">
                                            {{ currencySymbol }} {{ totalDeductions ? (totalDeductions).toFixed(2) : '-' }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row mb-1">
                            <div class="col-12 col-sm-12">
                                <div class="row">
                                    <div class="col-6 col-md-6 pr-0">
                                        <label class="mb-0 py-2 d-block title-block">Net Pay</label>
                                    </div>
                                    <div class="col-6 col-md-6 pl-0">
                                        <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">{{ currencySymbol }} {{ netPay.toFixed(2) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import PayslipAutoGenComponent from '@/components/common/PayslipAutoGenComponent.vue'
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    props: {
        payslipConfigId: Number,
    },
    components: {
        PayslipAutoGenComponent,
        VhextMoneyControl
    },
    data() {
        return {
            showOverlay: false,
            showPayslip: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            currencySymbol: localStorage.getItem("currency"),
            logo: localStorage.getItem('logo'),

            payslipAutoGenCompKey: 1000,

            payslipAutoGenData: {
                monthlyCTC: null,
                payslipConfigId: this.payslipConfigId,
            },
            payslipList: [],
        }
    },
    validations: {
        payslipAutoGenData: {
            monthlyCTC: {
                required,
            }
        }
    },
    methods: {
        viewAutoGenPayslip: function(data) {
            this.$bvModal.show('payslipDetailAutoGenPopup');
            this.payslipAutoGenCompKey++;
            this.showPayslip = true;
        },
        clearData() {
            this.showPayslip = false;
            this.payslipList = [];
            this.payslipAutoGenData.monthlyCTC = null;
        },
        moneyControlInput: function(value) {
            this.payslipAutoGenData.monthlyCTC = Math.abs(value);
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>