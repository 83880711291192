<template>
    <div>
        <DevelopmentPlanMaster :ermMasterInfo="ermMasterInfo"></DevelopmentPlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DevelopmentPlanMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        DevelopmentPlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Task Plan Master",
                entity: 'development_plan_master',
                refEntity: 'task',
                redirectURL: '/dvpmas/list',
                erdRedirectURL: '/task/',
                showDateRange: true
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
      if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/dvpmas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/dvpmas/list';
        }
    },
    methods: {}
}
</script>