<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4 mt-2">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-2 col-sm-2  mt-2">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Task List</span></h4>
                        </div>
                        <div class="col-lg-2 col-sm-2 my-1">
                            <b-button class="addnew-tassk-btn float-right ml-5" variant="outline-info" v-bind:class="{disabled: !elements.isDisable }" @click="elements.viewOnlyComponent=false; taskList.id=null; erdId=null;toggleDiv();" size="sm"><i class="fa fa-plus pr-1"></i>New Task</b-button>
                        </div>                        
                        <div class="col-lg-4 col-sm-4 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Task Plan" class="action-link" @click="routeTaskPlan()" >
                                            <i class="fa fa-tasks px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Board View" class="action-link" @click="routeTaskBoard()" >
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Gantt View" class="action-link" @click="routeTaskGantt()" >
                                            <i class="fa fa-sliders px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>                                    
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-1"></div>
                </div>
            </div>
            <div class="row">
                <div v-show="elements.isHide" class="col-12 mb-4">
                    <div class="card">
                        <div class="card-body p-3">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="reqTrackingTable" show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="taskLists" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'To Do'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'On Hold'" variant="warning">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                    </div>
                                </template>                                

                                <template v-slot:cell(actions)="data">
                                    <span v-if="data.item.createdById == userId">
                                        <div v-if="attachmentList.length>0" class="dropdown dropright d-inline">
                                            <a title="Attachment" class="dropdown-toggle pr-2 fa fa-paperclip" href="#" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            </a>
                                            <div class="dropdown-menu">
                                                <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                                <div v-if="attachmentList.length>0">
                                                    <ul class="attachmentList-ul p-0 m-0">
                                                        <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                            <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> 
                                        <a href="javascript:" title="View" @click="showView(data.item);toggleDiv()"><i class="fa fa-eye pr-2"></i></a>
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item);toggleDiv()"><i class="fa fa-pencil-square-o fa-custom fa-lg  pr-2"></i></a>
                                        <a href="javascript:" title="Duplicate Task" @click="duplicateTask(data.item);toggleDiv()"><i class="fa fa-copy pr-2" aria-hidden="true"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteTaskList(data.item.id)" v-b-modal.deleteTaskList><i class="fa fa-trash"></i></a>
                                    </span>
                                    <!-- <span v-else>NA</span> -->
                                    <a href="javascript:" @click="showStatusPopup(data.item)" title="Status Tracker"><i class="fa fa-list-ol pr-2"></i></a>
                                    
                                </template>
                                <template v-slot:cell(description)="data">
                                    <p v-html="data.value"></p>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Task found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="taskLists.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div v-show="!elements.isHide" class="col-12 col-lg-12 col-xl-12 position-relative">
                    <AddTaskComponent ref="addTaskRef" :elements="elements" @refresh-task="getTaskLists" />
                </div>
            </div>
        </div>
        <StatusTrackerPopup v-if="showDialog" ref="statusTrackerPopupRef" :statusUpdate="statusUpdate" @closeDialog="closeDialog" @updateParent="getTaskLists" />
        <DeleteComponent id="deleteTaskList" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :attachmentData="attachmentData" @closeDialog="closeDialog" :referenceDetailId="referencePopupId" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddTaskComponent from '@/components/common/AddTaskComponent.vue';
import StatusTracker from '@/components/common/StatusTracker.vue';
import StatusTrackerPopup from '@/components/popup/action/StatusTrackerPopup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
export default {
    name: 'app',
    computed: {
        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        AddTaskComponent,
        StatusTracker,
        TaskDetailPopup,
        StatusTrackerPopup
    },
    data() {
        return {
            elements: {
                clientTaskPage: '',
                editMode: false,
                viewMode: false,
                viewOnlyComponent: false,
                filterMode: false,
                taskStatusUpdate: false,
                isHide: true,
                isDisable: true
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),

            entity: 'task',
            userId: localStorage.getItem("userId"),
            isArchive: false,

            notEnabled: true,
            requirementStatuses: [],
            taskList: {
                id: '',
                taskId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                title: '',
                requirementTypeId: '',
                requirementSpecification: '',
                preparedById: '',
                preparedBy: '',
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                dueDate: '',
                statusId: '',
                isArchive: false,
            },
            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                refEntityId: '',
                refEntity: 'task',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },
            attachmentData: {},
            attachmentList:[],

            detailViewData: {},
            referencePopupId: 0,
            showDialog: false,

            transProps: {
                // Transition name
                name: 'flip-list'
            },
            //taskList: [],
            taskLists: [],
            fields: [
                { key: 'taskId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'End Time', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-cw-400', sortDirection: 'desc' },
                { key: 'assignedTo', label: 'Assigned To', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions'}
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20,25,50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getTaskLists();
    },
    validations: {
        taskList: {
            statusId: {
                required,

            }
        },
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            if (localStorage.getItem("ermId") != '')
                this.entityRecordMasterId = localStorage.getItem("ermId");
            else
                this.entityRecordMasterId = this.$route.params.ermId;


            this.taskList.vhextAccountId = this.vhextAccountId;
            this.taskList.projectId = this.projectId;
            this.taskList.entityRecordMasterId =  this.entityRecordMasterId;
            this.taskList.preparedById = this.userId;

            this.statusUpdate.projectId = this.projectId;
            this.statusUpdate.entityRecordMasterId = this.entityRecordMasterId;
            this.statusUpdate.statusById = this.userId;


        },
        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        getTaskLists: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
                isArchive: this.isArchive,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.taskLists = result.taskList;
                    if (this.taskLists != null && this.taskLists.length > 0) {
                        this.totalRows = this.taskLists.length;
                        this.$nextTick(() => {
                            this.$refs.reqTrackingTable.selectRow(0);
                        })
                        // this.assignStatusUpdate(this.taskLists[0]);
                        console.log(result);
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId, 
                userId: this.userId,               
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.requirementStatuses = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    this.users = result.userLookup;
                    console.log(result);
                })
        },
        saveData: function() {
            this.$v.taskList.$touch();
            if (!this.$v.taskList.$invalid) {
                if (this.elements.editMode) {
                    this.updateRequirementSpec();
                } else {
                    this.saveTaskList();
                }
            }
        },
        saveTaskList: function() {

            console.log(this.taskList);
            this.$v.taskList.$touch();
            if (!this.$v.taskList.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/task/add', this.taskList)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getTaskLists();
                            this.clearData();
                        }
                    })
            }
        },
        viewTaskList: function(id) {
            this.clearData();
            axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.taskList = result.taskList;
                    }
                    console.log(this.taskList);

                })
            this.elements.editMode = false;
        },
        cancelEditMode: function() {
            this.viewTaskList(this.taskList.id);
        },
        deleteTaskList: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTaskLists();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.taskList.taskId = '';
            this.taskList.title = '';
            this.taskList.requirementTypeId = '';
            this.taskList.requirementSpecification = '';
            this.taskList.preparedById = '';
            this.taskList.preparedBy = '';
            this.taskList.preparedDt = '';
            this.taskList.priorityId = '';
            this.taskList.dueDate = '';
            this.taskList.statusId = '';
            this.taskList.hasReference = '';
            this.taskList.hasRefLink = '';
            this.taskList.hasAttachment = '';
            this.taskList.preparedDt = new Date().toISOString().substr(0, 10);
            this.$v.taskList.$reset();
        },
        showView: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.showView(data);
            })
        },
        showEdit: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.showEdit(data);
            })
        },
        duplicateTask: function(data) {
            this.$nextTick(()=> {                
                this.$refs.addTaskRef.duplicateTask(data);
            });
        },
        assignStatusUpdate: function(data) {
            // this.$refs.statusUpdate.clearData();

            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.refRecordId = data.id;
            this.statusUpdate.refId = data.taskId;
            this.statusUpdate.refRecordTitle = data.title;
            this.statusUpdate.statusById = this.userId;
            this.statusUpdate.statusId = data.statusId;
            this.statusUpdate.status = data.status;
            //call child component method
            // this.$refs.statusUpdate.onTitleChange();
        },   
        viewItemDetails: function(item) {
            console.log(item);
            
            if (item && item.length !== 0) {
                let data;
                if (item.length && item.length == 1) {
                    data = item[0];
                } else data = item;
                this.detailViewData = data;
                this.referencePopupId = data.id;
                this.attachmentData.refEntity = this.entity;
                this.attachmentData.refRecordId = data.id;
                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
                })
            }
        },
        showStatusPopup: function(data) {
            console.log(data);
            if (data && data !== null) {
                // this.$refs.statusTrackerPopupRef.onTitleChange();
                this.assignStatusUpdate(data);
                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('statusTrackerPopup');
                })
            }
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        // setErmData: function() {
        //     let ermData = {
        //         ermId: this.$store.getters.getERMData.erm_id,
        //         ermTitle: this.$store.getters.getERMData.erm_title,
        //     }

        //     console.log(ermData);
        //     this.$store.dispatch("setERMData", ermData);

        // },
        routeTaskPlan: function() {
            let routePath = this.$route.path;
            if(routePath.includes('landing')){
                this.$router.push('/landing/tskpln');
            }
            else{
                this.$router.push('/backlogs');
            }

        },
        routeTaskBoard() {
            let routePath = this.$route.path;
            if (routePath.includes('landing')) {
                this.$router.push('/landing/tskbrd');
            } else {
                this.$router.push('/tskbrd');
            }
        },
        routeTaskGantt: function() {
            let routePath = this.$route.path;
            if(routePath.includes('landing')){
                this.$router.push('/landing/taskganttchart');
            }
            else{
                this.$router.push('/taskganttchart');
            }

        },

        
    }

}
</script>