<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Country</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Country</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div class="row mb-2">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="country">Country</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.country.country.$error }">
                                                                <input type="text" class="form-control" v-model="country.country" id="country" :class="{ 'is-invalid': $v.country.country.$error }" placeholder="Enter Country Name">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                        <label v-if="$v.country.country.$error && !$v.country.country.minLength" class="text-danger">Country must have at least {{$v.country.country.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.country.country.$error && !$v.country.country.required" class="text-danger">Please enter country name</label>
                                                        <label v-if="$v.country.country.$error && !$v.country.country.maxLength" class="text-danger">country must not exceed {{$v.country.country.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="budget">Active</label>
                                                        <div class="input-group ">
                                                            <b-form-checkbox class="custom-toggle" v-model="country.active" name="check-button" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="alpha2Code">Alpha2Code</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.country.alpha2Code.$error }">
                                                                <input type="text" class="form-control" v-model="country.alpha2Code" id="alpha2Code" :class="{ 'is-invalid': $v.country.alpha2Code.$error }" placeholder="Enter Alpha2Code">
                                                            </div>  
                                                        <label v-if="$v.country.alpha2Code.$error && !$v.country.alpha2Code.maxLength" class="text-danger">Alpha2Code must not exceed {{$v.country.alpha2Code.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="alpha3Code">Alpha3Code</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.country.alpha3Code.$error }">
                                                                <input type="text" class="form-control" v-model="country.alpha3Code" id="alpha3Code" :class="{ 'is-invalid': $v.country.alpha3Code.$error }" placeholder="Enter Alpha3Code">
                                                            </div>  
                                                        <label v-if="$v.country.alpha3Code.$error && !$v.country.alpha3Code.maxLength" class="text-danger">Alpha3Code must not exceed {{$v.country.alpha3Code.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="callingCode">Calling Code</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.country.callingCode.$error }">
                                                                <input type="text" class="form-control" v-model="country.callingCode" id="callingCode" :class="{ 'is-invalid': $v.country.callingCode.$error }" placeholder="Enter Calling Code">
                                                            </div>  
                                                        <label v-if="$v.country.callingCode.$error && !$v.country.callingCode.maxLength" class="text-danger">Calling Code must not exceed {{$v.country.callingCode.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="internetCode">Internet Code</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.country.internetCode.$error }">
                                                                <input type="text" class="form-control" v-model="country.internetCode" id="internetCode" :class="{ 'is-invalid': $v.country.internetCode.$error }" placeholder="Enter Internet Code">
                                                            </div>  
                                                        <label v-if="$v.country.internetCode.$error && !$v.country.internetCode.maxLength" class="text-danger">Internet Code must not exceed {{$v.country.internetCode.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="countryTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="countryList"  @row-selected="onRowSelected" :fields="countryFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteCountry(data.item.id)" data-toggle="modal" data-target="#deleteCountry"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Country found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="countryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row input-group-label">
                                        <div class="col-5">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{countryDetail.countryId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateCountry" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="country">Country</label>
                                                <input type="text" class="form-control" id="country" :disabled="!elements.editMode" v-model='countryDetail.country'>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Active</label>
                                                <b-form-checkbox class="custom-toggle" v-model="countryDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="alpha2Code">Alpha2Code</label>
                                                <input type="text" class="form-control" id="alpha2Code" :disabled="!elements.editMode" v-model='countryDetail.alpha2Code'>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="alpha3Code">Alpha3Code</label>
                                                <input type="text" class="form-control" id="alpha3Code" :disabled="!elements.editMode" v-model='countryDetail.alpha3Code'>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="callingCode">Calling Code</label>
                                                <input type="text" class="form-control" id="callingCode" :disabled="!elements.editMode" v-model='countryDetail.callingCode'>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="internetCode">Internet Code</label>
                                                <input type="text" class="form-control" id="internetCode" :disabled="!elements.editMode" v-model='countryDetail.internetCode'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="createdBy">Created By</label>
                                                                        <input type="text" class="form-control disabled" id="createdBy" v-model='countryDetail.userName' readonly>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="startDate">Created Date</label>
                                                                        <div class="input-group" >
                                                                            <input type="text" class="form-control disabled" :value="countryDetail.createdDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="endDate">Last Updated Date</label>
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control disabled" :value="countryDetail.lastUpdatedDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteCountry" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Country ?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entity: 'country',

            country: {
                id: '',
                countryId: '',
                country: '',
                alpha2Code: '',
                alpha3Code: '',
                callingCode: '',
                internetCode: '',
                active: true,
                createdById: localStorage.getItem("userId"),            
            },
            countryDetail: {
                country: '',
                alpha2Code: '',
                alpha3Code: '',
                callingCode: '',
                internetCode: '',
                active: '',
                createdById: '',
                countryId: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            users: [],
            countryList: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            countryFields: [
                {key: 'index',label: 'SN'},
                // { key: 'countryId', label: 'Country Id', sortable: true, sortDirection: 'desc' },
                { key: 'country', label: 'Country', sortable: true, sortDirection: 'desc' },
                { key: 'alpha2Code', label: 'Alpha2 Code', sortable: true, sortDirection: 'desc' },
                { key: 'alpha3Code', label: 'Alpha3 Code',  sortable: true, sortDirection: 'desc' },
                { key: 'callingCode', label: 'Calling Code', sortable: true, sortDirection: 'desc' },
                { key: 'internetCode', label: 'Internet Code',  sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            projectERMListFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getCountry();
    },
    validations: {
        country: {
            country: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            alpha2Code: {
                maxLength: maxLength(2)
            },
            alpha3Code: {
                maxLength: maxLength(3)
            },
            callingCode: {
                maxLength: maxLength(10)
            },
            internetCode: {
                maxLength: maxLength(10)
            },
        }
    },
    methods: {
        getCountry: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/country/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.countryList && result.countryList.length > 0) {
                        this.countryList = result.countryList;
                        this.$nextTick(() => {
                            this.$refs.countryTable.selectRow(0);
                        })
                        this.totalRows = this.countryList.length;
                    }
                    this.clearData();
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        saveData: function() {
            this.country.vhextAccountId=this.vhextAccountId;
            this.country.projectId =this.projectId;
            this.country.createdById =this.userId;

            this.$v.country.$touch();
            if (!this.$v.country.$invalid) {                  
                this.saveCountry();
            }
        },
        saveCountry: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/general/country/add', this.country)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getCountry();
                    }
                })
        },
        updateCountry: function() {
            this.countryDetail.vhextAccountId = this.vhextAccountId;
            this.countryDetail.projectId = this.projectId;

            axios.post(this.$store.getters.getAPIBasePath + '/general/country/update', this.countryDetail)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCountry();
                    }
                })
        },
        viewCountry: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/general/country/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let countryDetail = result.country;
                    if (countryDetail) {                        
                        this.countryDetail.id = countryDetail.id;
                        this.countryDetail.country = countryDetail.country;
                        this.countryDetail.alpha2Code = countryDetail.alpha2Code;
                        this.countryDetail.alpha3Code = countryDetail.alpha3Code;
                        this.countryDetail.callingCode = countryDetail.callingCode;
                        this.countryDetail.internetCode = countryDetail.internetCode;
                        this.countryDetail.countryId = countryDetail.countryId;
                        this.countryDetail.active = countryDetail.active;
                        this.countryDetail.createdDt = countryDetail.createdDt;
                        this.countryDetail.lastUpdatedDt = countryDetail.lastUpdatedDt;
                    }
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewCountry(this.countryDetail.id);
        },
        deleteCountry: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/country/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCountry();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.country.country = '';
            this.country.alpha2Code = '';
            this.country.alpha3Code = '';
            this.country.callingCode = '';
            this.country.internetCode = '';
            this.country.createdById = localStorage.getItem("userId");
            this.country.active = true;
            this.$v.country.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.countryDetail.country = data.country;
            this.countryDetail.alpha2Code = data.alpha2Code;
            this.countryDetail.alpha3Code = data.alpha3Code;
            this.countryDetail.callingCode = data.callingCode;
            this.countryDetail.internetCode = data.internetCode;
            this.countryDetail.active = data.active;
            this.countryDetail.createdById = data.createdById;
            this.countryDetail.id = data.id;
            this.countryDetail.countryId = data.countryId;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.countryDetail.id = data.id;
                this.countryDetail.country = data.country;
                this.countryDetail.alpha2Code = data.alpha2Code;
                this.countryDetail.alpha3Code = data.alpha3Code;
                this.countryDetail.callingCode = data.callingCode;
                this.countryDetail.internetCode = data.internetCode;
                this.countryDetail.countryId = data.countryId;
                this.countryDetail.createdById = data.createdById;
                this.countryDetail.active = data.active;
                this.countryDetail.createdDt = data.createdDt;
                this.countryDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.country.entity = this.entity;
                this.country.id = item.id;
                this.country.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.country.entity = this.entity;
                this.country.id = item.id;
                this.country.active = 1;

                this.updateActivationState();
            }
            console.log(this.country.id, this.country.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.country)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.country.active = true;
                    this.getCountry();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },


    }
}
</script>
