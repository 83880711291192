<template>
    <div>
        <GoalMasterList :ermMasterInfo="ermMasterInfo"></GoalMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import GoalMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        GoalMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Goals & Objectives",
                entity: 'goal_master',
                refEntity: 'objective',
                tooltip: 'New Goal',
                redirectURLName: 'goalobjective',
                newEntityMasterURL: 'goalmaster',
                redirectURL: '/goalmaster',
                actionName: 'Objectives',
                previewURLName: 'objrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>