<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Financial Plan</span></h4>
                        </div>                        
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>Financial Source</span>
                                                <span v-if="elements.editMode">Edit</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.financialPlan.title.$error }">
                                                                <input type="text" class="form-control" v-model="financialPlan.title" id="title" :class="{ 'is-invalid': $v.financialPlan.title.$error }" placeholder="Enter Item Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.financialPlan.title.$error && !$v.financialPlan.title.minLength" class="text-danger">Title must have at least {{$v.financialPlan.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.financialPlan.title.$error && !$v.financialPlan.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.financialPlan.title.$error && !$v.financialPlan.title.maxLength" class="text-danger">Title must not exceed {{$v.financialPlan.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budgetPlan">Budget Item</label>
                                                            <select v-model="financialPlan.budgetPlanId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in budgetPlans" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Finance Source</label>
                                                            <input type="text" v-model="financialPlan.financeSource" class="form-control" id="financeSource" maxlength="1000" >
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Ammount</label>
                                                            <VhextMoneyControl :value="Number(this.financialPlan.amount)" @money-input="moneyControlInput" />
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="targetDate">Target Date</label>
                                                            <input type="date" class="form-control" v-model="financialPlan.targetDate" id="targetDate">
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="accountablePersonId">Accountable Person</label>
                                                            <select v-model="financialPlan.accountablePersonId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>

                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="financialPlans" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(amount)="data">
                                    <div class="text-right">{{ data.value }}</div>
                                </template>

                                <template v-slot:cell(actions)="data">
                                    <!-- <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteFinancialPlan(data.item.id)" v-b-modal.deleteFinancialPlan ><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Financial Plans found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                                <template v-slot:custom-foot="data">
                                    <div class="col-sm-12 col-md-7 pl-0">
                                        <b-tr >
                                            <b-input-group  :prepend=prependText>
                                                <template #prepend>
                                                  <b-input-group-text >{{ totalExpenses.toFixed(2)}}</b-input-group-text>
                                                </template>                                                    
                                            </b-input-group>
                                        </b-tr>
                                    </div>
                                </template>
                            </b-table>
                            <b-pagination v-if="financialPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup v-if="elements.showTypes" ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="financialPlan.entity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteFinancialPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Budget Plan?" />
        <FinancialPlanDetailPopup v-if="showDialog" ref="financialPlanDetailRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue';
import FinancialPlanDetailPopup from '@/components/popup/itemdetail/FinancialPlanDetailPopup.vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
        VhextMoneyControl,
        FinancialPlanDetailPopup
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                showTypes: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
                        
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),

            typeLookupTitle: 'Financial Plan Type Settings',
            prependText: localStorage.getItem("currency"),
            budgetPlans: [],
            users: [],
            entity: '',
            financialPlan: {
                id: '',
                financialPlanId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: 'financial_plan',
                entityRecordMasterId: this.entityRecordMasterId,
                budgetPlanId: '',
                title: '',
                financeSource: '',
                amount: null,
                targetDate: '',
                receivedDate: '',
                responsiblePerson: '',
                accountablePersonId: '',
                preparedById: this.userId,
                remarks: '',
                statusId: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            financialPlanView: {
                id: '',
                financialPlanId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
                entityRecordMasterId: this.entityRecordMasterId,
                budgetPlanId: '',
                title: '',
                financeSource: '',
                amount: null,
                targetDate: '',
                receivedDate: '',
                responsiblePerson: '',
                accountablePersonId: '',
                preparedById: this.userId,
                remarks: '',
                statusId: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            financialPlans: [],
            fields: [
                { key: 'financialPlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'budgetPlanTitle', label: 'Budget Item', sortable: true, sortDirection: 'desc' },
                { key: 'financeSource', label: 'Finance Source', sortable: true, sortDirection: 'desc' },
                { key: 'targetDate', label: 'Target Date',formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Accountable', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Ammount', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20,25],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        totalExpenses() {
            return this.financialPlans.reduce((accum, item) => {
            return accum + parseFloat(item.amount)
            }, 0.00)
        }

    },
    mounted() {
        this.initialSettings();
        this.getFinancialPlans();
        this.lookupData();
    },
    validations: {
        financialPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'financial_plan';
            this.userId = localStorage.getItem("userId");

            this.financialPlan.vhextAccountId = this.vhextAccountId;
            this.financialPlan.projectId = this.projectId;
            this.financialPlan.entityRecordMasterId = this.entityRecordMasterId;
            this.financialPlan.preparedById = this.userId;
            this.elements.showTypes = true;

        },
        getFinancialPlans: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.financialPlans = result.financialPlanList;
                    this.totalRows = this.financialPlans.length;
                    console.log(this.financialPlans);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.budgetPlans = result.budgetPlanLookup;
                    this.users = result.userLookup;
                })
        },

        saveData: function() {
            this.$v.financialPlan.$touch();
            if (!this.$v.financialPlan.$invalid) {
                if (this.elements.editMode) {
                    this.updateFinancialPlan();
                } else {
                    this.saveFinancialPlan();
                }
            }
        },
        saveFinancialPlan: function() {
            this.$v.financialPlan.$touch();
            console.log(this.financialPlan);
            if (!this.$v.financialPlan.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/add', this.financialPlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getFinancialPlans();
                            this.clearData();
                        }
                    })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
            }
        },
        updateFinancialPlan: function() {
            this.$v.financialPlan.$touch();
            if (!this.$v.financialPlan.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/financialplan/update', this.financialPlan)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getFinancialPlans();
                            this.clearData();
                        }
                    })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
            }

        },
        deleteFinancialPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/financialplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getFinancialPlans();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        viewItemDetails: function(data) {
            console.log(data);
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.financialPlanDetailRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.financialPlan.title = '';
            this.financialPlan.budgetPlanId = '';
            this.financialPlan.amount = '';
            this.financialPlan.financeSource = '';
            this.financialPlan.targetDate = '';
            this.financialPlan.accountablePersonId = '';
            this.$v.financialPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.financialPlan.id = data.id;
            this.financialPlan.title = data.title;
            this.financialPlan.financialPlanId = data.financialPlanId;
            this.financialPlan.vhextAccountId = data.vhextAccountId;
            this.financialPlan.projectId = data.projectId;
            this.financialPlan.entityRecordMasterId = data.entityRecordMasterId;
            this.financialPlan.budgetPlanId = data.budgetPlanId;
            this.financialPlan.financeSource = data.financeSource;
            this.financialPlan.amount = data.amount;
            this.financialPlan.targetDate = data.targetDate;
            this.financialPlan.receivedDate = data.receivedDate;
            this.financialPlan.responsiblePerson = data.responsiblePerson;
            this.financialPlan.accountablePersonId = data.accountablePersonId;
            this.financialPlan.preparedById = data.preparedById;
            this.financialPlan.remarks = data.remarks;
            this.financialPlan.statusId = data.statusId;
            this.financialPlan.createdDt = data.createdDt;
            this.financialPlan.lastUpdatedDt = data.lastUpdatedDt;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        moneyControlInput: function(value) {
            this.financialPlan.amount = value;
        }
    }
}
</script>