<template>
    <div>
        <ErrorReportMaster :ermMasterInfo="ermMasterInfo"></ErrorReportMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ErrorReportMaster from '@/components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ErrorReportMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Bug Report Master",
                entity: 'error_report_master',
                refEntity: 'error_report',
                ermListRedirectURL: '/errmas/list',
                erdRedirectURL: '/errorreport/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>