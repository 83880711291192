<template>
    <div>
        <EffortEsimationMasterList :ermMasterInfo="ermMasterInfo"></EffortEsimationMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import EffortEsimationMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        EffortEsimationMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Effort Esimation Sheet",
                entity: 'effort_estimation_master',
                refEntity: 'effort_estimation',
                tooltip: 'New Effort Esimation',
                redirectURLName: 'effortestimation',
                newEntityMasterURL: 'esmaster',
                redirectURL: '/esmaster',
                actionName: 'Effort Esimation',
                previewURLName: 'eferep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>