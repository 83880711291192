<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Objective</span></h4>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title pt-0" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Objective</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.objective.title.$error }">
                                                                    <input type="text" class="form-control" maxlength="100" v-model="objective.title" id="title" :class="{ 'is-invalid': $v.objective.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.objective.title.$error && !$v.objective.title.minLength" class="text-danger">Title must have at least {{$v.objective.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.objective.title.$error && !$v.objective.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.objective.title.$error && !$v.objective.title.maxLength" class="text-danger">Title must not exceed {{$v.objective.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group position-relative pr-3">
                                                                <label for="duration">Type</label>
                                                                <select v-model="objective.objectiveTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in objectiveTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-12">
                                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label>Objective</label>
                                                                <textarea v-model="objective.objective" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="timeline">Timeline</label>
                                                                <input v-model="objective.timeline" type="date" class="form-control" placeholder="Timeline">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group pr-3">
                                                                <label for="deliverables">Deliverables</label>
                                                                <multiselect v-model="objective.deliverables" :options="deliverables" name="deliverable" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                            </div>
                                                            <div class="col-12">
                                                                <a :title="deliverableLookupTitle" class="setting-icon" href="javascript:" v-b-modal.deliverableModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            </div>                                                                                                     
                                                        </div>

                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="duration">Responsible Person</label>
                                                                <select v-model="objective.accountablePersonId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="duration">Status</label>
                                                                <select v-model="objective.statusId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in objectiveStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode" id="viewStatement">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <b-input-group :prepend="objectiveDetail.objectiveId" class="mb-2">
                                                                    <b-form-input type="text" class="form-control" aria-label="First name" disabled v-model='objectiveDetail.title'></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group text-center">
                                                                <label for="status"> Status</label>
                                                                <p><span class="badge badge-pill badge-blue mb-1">{{objectiveDetail.value}}</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <input type="text" class="form-control" id="title" disabled v-model='objectiveDetail.title'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="type">Type</label>
                                                                <input type="text" class="form-control" id="type" disabled v-model='objectiveDetail.type'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="timeline">Timeline</label>
                                                                <input type="text" class="form-control" id="timeline" disabled v-model='objectiveDetail.timeline'>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="userName">Responsible Person</label>
                                                                <input type="text" class="form-control" id="userName" disabled v-model='objectiveDetail.accountablePerson'>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="objective">Objective</label>
                                                                <textarea class="form-control" id="objective" disabled v-model='objectiveDetail.objective' rows="4"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="objective">Deliverables</label>
                                                                <div class="disabled_div">
                                                                    <span v-for="(info, index) in JSON.parse(objectiveDetail.deliverables)">
                                                                        <span v-if="index != 0">, </span>
                                                                        <span>{{ info.value }}</span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="createdDt">Created Date</label>
                                                                <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(objectiveDetail.createdDt)">
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="lastUpdatedDt">Updated Date</label>
                                                                <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(objectiveDetail.lastUpdatedDt)">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="objectiveTable" show-empty small stacked="md" sort-icon-left :items="objectives" :fields="objectiveFields" selectable :select-mode="selectMode" @row-selected="onRowSelected" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteObjective(data.item.id)" data-toggle="modal" data-target="#deleteObjective"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Objectives found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                            
                            <b-pagination v-if="objectives.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="elements.viewMode || elements.editMode || elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
<!--                                         <div class="row">
                                        <div class="col-12">
                                            <b-input-group>
                                                <template #prepend>
                                                    <b-input-group-text>{{objectiveDetail.objectiveId}}</b-input-group-text>
                                                </template>
                                                <input type="text" class="form-control" is-text=true id="title" disabled v-model='objectiveDetail.title'>
                                                <template #append>
                                                    <b-input-group-text>{{objectiveDetail.status}}</b-input-group-text>
                                                </template>
                                            </b-input-group>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group text-center">
                                                <label for="objectiveId">Objective Id</label>
                                                <p><span class="badge badge-pill badge-blue mb-1">{{objectiveDetail.objectiveId}}</span></p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group text-center">
                                                <label for="status"> Status</label>
                                                <p><span class="badge badge-pill badge-blue mb-1">{{objectiveDetail.status}}</span></p>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control" maxlength="100" id="title" disabled v-model='objectiveDetail.title'>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="form-group">
                                                <label for="type">Type</label>
                                                <input type="text" class="form-control" id="type" disabled v-model='objectiveDetail.type'>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="form-group">
                                                <label for="timeline">Timeline</label>
                                                <input type="text" class="form-control" id="timeline" disabled :value="formatDate(objectiveDetail.timeline)">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="objective">Objective</label>
                                                <textarea class="form-control" id="objective" maxlength="2000" disabled v-model='objectiveDetail.objective' rows="4"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="objective">Deliverables</label>
                                                <div class="disabled_div">
                                                    <span v-for="(info, index) in JSON.parse(objectiveDetail.deliverables)">
                                                        <span v-if="index != 0">, </span>
                                                        <span>{{ info.value }}</span>
                                                    </span>
                                                </div>                                                    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="createdBy">Accountable Person</label>
                                                                        <input type="text" class="form-control disabled" id="createdBy" v-model="objectiveDetail.accountablePerson" readonly>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="startDate">Created Date</label>
                                                                        <div class="input-group" >
                                                                            <input type="text" class="form-control disabled" :value="objectiveDetail.createdDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="endDate">Last Updated Date</label>
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control disabled" :value="objectiveDetail.lastUpdatedDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-ref>
                                                <span>Reference Search</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-ref" role="tabpanel">
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="input-group dataTables_filter">
                                                        <input type="search" v-model="refFilter" class="form-control" placeholder="Search Reference">
                                                    </div>
                                                </div>
                                                <div class="float-left ml-5 pl-4">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="mb-0">
                                                            <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="projectERMList" :fields="projectERMListFields" responsive="sm" :current-page="currentPageRef" :per-page="perPageRef" :filter="refFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                    <template v-slot:cell(actions)="data">
                                                        <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                                <b-pagination v-if="projectERMList.length!=0" pills v-model="currentPageRef" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                            </div>
                                        </b-collapse>
                                    </b-card>
                                    <div class="mt-2">
                                        <label class="mb-1">References</label>
                                        <b-table show-empty small stacked="md" sort-icon-left :items="ermReferenceList" :fields="ermReferenceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" v-b-modal.removeReference><i class="fa fa-minus-circle pr-2"></i></a>
                                            </template>
                                            <template v-slot:empty="">
                                                <div class="text-center">No References Found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="">
                                                <div class="text-center">{{ }}</div>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeliverableLookup ref="deliverableLookupModal" :entity="objective.entity" :deliverableLookupTitle="deliverableLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="removeReference" :onYes="onYesRef" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Reference?" />
        <Dialog id="deleteObjective" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Objective?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import DeliverableLookup from '@/components/popup/lookup/DeliverableLookup.vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
        DeliverableLookup,
        Multiselect
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeEntity: 'objective_type',
            typeLookupTitle: 'Objective Type Settings',
            deliverableLookupTitle: 'Deliverables',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            ermId: Number(this.$route.params.ermId),
            ermTitle: localStorage.getItem("ermTitle"),
            
            notEnabled: true,
            objectiveTypes: [],
            objectiveStatus: [],
            users: [],
            deliverables: [],
            objective: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'objective',
                preparedById: localStorage.getItem("userId"),
                goalId: '',
                objectiveId: '',
                title: '',
                objectiveTypeId: '',
                objective: '',
                timeline: '',
                accountablePerson: '',
                achievementRating: '',
                deliverables: [],
                accountablePersonId: '',
                projectEntityId: ''
            },
            objectiveDetail: {
                title: '',
                objective: '',
                objectiveId: '',
                status: '',
                type: '',
                deliverables: [],
                accountablePerson: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            addReference: {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityId: '',
                entityRecordId: '',
                ermId: '',
                erdId: '',
                refEntityId: '',
                refErmId: '',
                refErdId: '',
                title: '',
                refEntity: ''
            },

            objectives: [],
            entityRecordMaster: [],
            projectERMList: [],
            ermReferenceList: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            objectiveFields: [
                { key: 'objectiveId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'accountablePerson', label: 'Responsible Person', sortable: true, sortDirection: 'desc' },
                { key: 'timeline', label: 'Timeline', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            projectERMListFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            totalRowsRef: 1,
            perPageRef: 5,
            refFilter: null,
            currentPageRef: 1,


            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getObjective();
        this.lookupData();
        this.entityRecordMasterCollection(this.$route.params.ermId);
        this.getProjectEntityRecordMasterList();
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if (collapseId == 'accordion-1') {
                if (isJustShown) {
                    if (!(this.elements.editMode || this.elements.viewMode)) {
                        this.elements.showDetail = false;
                    }
                }
            }
        })
    },
    validations: {
        objective: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getObjective: function() {
            let data = {
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.objectiveList && result.objectiveList.length > 0) {
                        this.objectives = result.objectiveList;
                        this.$nextTick(() => {
                            this.$refs.objectiveTable.selectRow(0);
                        })
                        this.totalRows = this.objectives.length;
                        this.clearData();

                    }
                })
        },
        entityRecordMasterCollection: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster = result.entityRecordMaster;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        getProjectEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/projectentityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectERMList = result.projectEntityRecordMasterList;
                    this.totalRowsRef = this.projectERMList.length;
                })
        },
        getERMReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmasterreference/list', data)
                .then((response) => {
                    let result = response.data;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.objective.vhextAccountId,
                projectId: this.objective.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.objective.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.objectiveStatus = result.statusLookup;
                    this.objectiveTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                    this.deliverables = result.deliverableLookup;
                    console.log(this.users);
                })

        },
        saveData: function() {
            this.$v.objective.$touch();
            if (!this.$v.objective.$invalid) {
                if (this.elements.editMode) {
                    this.updateObjective();
                } else {
                    this.saveObjective();
                }
            }
        },
        saveObjective: function() {
            this.showOverlay = true;
            console.log(this.objective);
            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/add', this.objective)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getObjective();
                    }
                })
                .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
        },
        updateObjective: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/update', this.objective)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getObjective();
                    }
                })
                .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
        },
        deleteObjective: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/goal/objective/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getObjective();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.objective.title = '';
            this.objective.objectiveTypeId = '';
            this.objective.accountablePersonId = '';
            this.objective.timeline = '';
            this.objective.statusId = '';
            this.objective.objective = '';
            this.objective.deliverables = [];
            this.objective.id = '';
            this.$v.objective.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.objective.id = data.id;
            this.objective.objectiveId = data.objectiveId;
            this.objective.title = data.title;
            this.objective.objectiveTypeId = data.objectiveTypeId;
            this.objective.accountablePersonId = data.accountablePersonId;
            this.objective.statusId = data.statusId;
            this.objective.timeline = data.timeline;
            this.objective.objective = data.objective;
            this.objective.deliverables = JSON.parse(data.deliverables);
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.objectiveDetail.title = data.title;
                this.objectiveDetail.objectiveId = data.objectiveId;
                this.objectiveDetail.type = data.type;
                this.objectiveDetail.status = data.status;
                this.objectiveDetail.accountablePersonId = data.accountablePersonId;
                this.objectiveDetail.accountablePerson = data.accountablePerson;
                this.objectiveDetail.timeline = data.timeline;
                this.objectiveDetail.objective = data.objective;
                this.objectiveDetail.deliverables = data.deliverables;
                this.objectiveDetail.createdDt = data.createdDt;
                this.objectiveDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.objectiveDetail.title = data.title;
            this.objectiveDetail.objectiveId = data.objectiveId.toString();
            this.objectiveDetail.type = data.type;
            this.objectiveDetail.status = data.status;
            this.objectiveDetail.accountablePersonId = data.accountablePersonId;
            this.objectiveDetail.accountablePerson = data.accountablePerson;
            this.objectiveDetail.timeline = data.timeline;
            this.objectiveDetail.objective = data.objective;
            this.objectiveDetail.deliverables = data.deliverables;
            this.objectiveDetail.createdDt = data.createdDt;
            this.objectiveDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        addRef: function(data) {             
            this.addReference.vhextAccountId = this.vhextAccountId;
            this.addReference.projectId = this.projectId;
            this.addReference.entityId = this.entityRecordMaster.entityId;
            this.addReference.entityRecordId = data.entityRecordMasterId;
            this.addReference.ermId = this.entityRecordMaster.id;
            this.addReference.erdId = '';
            this.addReference.refEntityId = data.entityId;
            this.addReference.refErmId = data.id;
            this.addReference.refErdId = data.refErdId;
            this.addReference.title = data.title;
            this.saveRef();
        },
        saveRef: function() {
            console.log(this.addReference);
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Reference Added',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }
}
</script>