<template>
    <div class="menu">
        <div class="main-menu">
            <div class="scroll">
                <ul class="list-unstyled">
                    <li v-bind:class="[ (index === selectedModuleIndex) ? 'active' : '']" v-for="(info, index) in subModules" :title="info.subModule" :data-parentid="info.subModuleId">
                        <a href="#SubMenu" @click="loadMainMenu(info.subModuleId);selectedModuleIndex = index" >
                            <i class="fa fa-briefcase"></i> {{ info.shortName }}
                        </a>
                    </li>                
                </ul>
<!--                 <ul class="list-unstyled fixed-bottom-menu">
                    <li>
                        <a href="#Assets">
                            <i class="fa fa-question-circle" aria-hidden="true"></i> Help
                        </a>
                    </li>
                </ul> -->
            </div>
        </div>
        <div class="sub-menu">
            <div class="scroll">
                <ul class="list-unstyled" data-link="SubMenu" id="menuTypes" >
                    <li v-for="(info, index) in subModuleMainMenu" :data-parentid="info.subModuleId" @click="selectedMainMenu = info.id" :class="{'selected' : info.entityCount == 1 && selectedMainMenu == info.id}">
                        <router-link v-if="info.entityCount == 1" v-for="(item, index) in loadSubMenu(info.subModuleId, info.id)" :key="index" :to="info.webUrl">
                            <i :class="info.icon"></i>
                            <span class="d-inline-block">{{ item.displayName }}</span>
                        </router-link>
                        <a v-if="info.entityCount >1" href="javascript:" data-toggle="collapse" aria-expanded="false" :data-target="targetId(info.id)" class="rotate-arrow-icon collapsed">
                            <span><i :class="info.icon" class="inner-sub-menu"></i></span> 
                            <span class="d-inline-block">{{ info.customMainMenu }}</span>
                            <i class="fa fa-angle-down float-right"></i>
                        </a>
                        <div v-if="info.entityCount >1" :id="randomId(info.id)" class="collapse" data-parent="#menuTypes">
                            <ul class="list-unstyled inner-level-menu">
                                <li v-for="(item, index) in loadSubMenu(info.subModuleId, info.id)" :key="index" @click="selectedSubMenu = item.id" :class="{'menu-selected' : selectedSubMenu == item.id}">
                                    <router-link :to="item.webUrl">
                                        <i :class="item.icon"></i> <span class="d-inline-block">{{ item.displayName }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/main';
export default {
    data() {
        return {
            elements: {
                showLogoBlock: false,
            },
            selectedModuleIndex: 0,
            selectedMainMenu: 0,
            selectedSubMenu: null,
            mainMenu: [],
            subModuleMainMenu: [],
            subMenu: [],
            entityMenu: [],
            subModules: []
        }
    },
    mounted() {
        this.loadMenu();
    },
    created() {
        EventBus.$on('onServiceLeftMenuReload', () => {
            this.onServiceLeftMenuReload();
        });
    },
    beforeDestroy() {
        EventBus.$off('onServiceLeftMenuReload');
    },
    methods: {
        loadMenu() {
            let data = {
                userId: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            }
            console.log(data);
            // return;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/customsubmodule', data)
                .then((response) => {
                    let result = response.data;
                    this.subModules = result.customSubModuleLookup;
                    console.log(this.subModules);
                    this.getMainMenu(data);
                    this.getSubMenu(data);
                })



        },
        targetId: function(id) {
            return "#collapseAuthorization" + id;
        },
        randomId: function(id) {
            return "collapseAuthorization" + id;
        },
        loadMainMenu(subModuleId) {
            this.subModuleMainMenu = this.mainMenu.filter(function(menu) {
                if (menu.subModuleId == subModuleId) {
                    return menu;
                }
            });
        },
        loadSubMenu(subModuleId, mainMenuId) {
            return this.subMenu.filter(function(menu) {
                if (menu.subModuleId == subModuleId && menu.mainMenuId == mainMenuId)
                    return menu;
            });
        },
        getMainMenu: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/custommainmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.mainMenu = result.customMainMenuLookup; 
                    if (this.subModules != null && this.subModules.length > 0) {               
                        this.loadMainMenu(this.subModules[0].subModuleId);                       
                    }

                })
        },
        getSubMenu: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/customsubmenu', data)
                .then((response) => {
                    let result = response.data;
                    this.subMenu = result.customSubMenuLookup;
                })
        },
        onServiceLeftMenuReload() {
            this.loadMenu();
        }
    }
}
</script>