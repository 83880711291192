<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-md-6 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Activity</h4>
                        </div>
                        <!-- <div class="col-lg-5 col-md-3 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-1">
                                    <li class="breadcrumb-item">
                                        <b-button class="btn btn-xs float-right px-2" variant="outline-primary" title="Existing Activity" @click="showDialog=true;" v-b-modal.existingActivityLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing Activity</b-button>
                                    </li>
                                </ol>
                            </nav>
                        </div> -->
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Activity</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="activity">Activity</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectActivity.activityId.$error }">                                                            
                                                                <select v-model="projectActivity.activityId" @change="viewActivity(projectActivity.activityId)" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.projectActivity.activityId.$error && !$v.projectActivity.activityId.required" class="text-danger">Please select Activity</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="projectActivity.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                        <label>Description</label>
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="projectActivity.description"></editor>
                                                    </div> -->
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="card-body p-0 mb-3">
                                                            <b-card no-body class="p-1">
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                        <span><i class="fa fa-plus-circle pr-2 action-link"></i>ETVX</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                    <b-card-body class="p-1">
                                                                        <div class="form-row">
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="expectedResult">Entry</label>
                                                                                    <textarea v-model="projectActivity.input" rows="4" class="form-control" id="input" maxlength="2000" placeholder="Enter Process Inputs"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="expectedResult">Task</label>
                                                                                    <textarea v-model="projectActivity.task" rows="4" class="form-control" id="task" maxlength="2000" placeholder="Enter Process Activities/Tasks"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="description">Verification/Validation</label>
                                                                                    <textarea v-model="projectActivity.validation" rows="4" class="form-control" id="validation" maxlength="2000" placeholder="Enter Verification/Validation/Testing Information"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="description">Exit</label>
                                                                                    <textarea v-model="projectActivity.output" rows="4" class="form-control" id="output" maxlength="2000" placeholder="Enter Process Output/Deliverables"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" :diabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table ref="projectActivityTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="projectActivityList"  @row-selected="onRowSelected" :fields="activityFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <!-- <template v-slot:cell(description)="data">
                                        <span v-html="data.value"></span>
                                    </template> -->
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Delete" @click="deleteProjectActivity(data.item.id)" v-b-modal.deleteProjectActivity><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Activities found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                                <b-pagination v-if="projectActivityList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ projectActivityDetail.activityId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateActivity" :diabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="form-group">
                                                    <label for="title">Activity</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.projectActivityDetail.activityId.$error }">
                                                        <select v-model="projectActivityDetail.activityId" @change="viewActivityDet(projectActivityDetail.activityId)" class="form-control" :disabled="!elements.editMode">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.projectActivityDetail.activityId.$error && !$v.projectActivityDetail.activityId.required" class="text-danger">Please select Activity</label>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="projectActivityDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label for="description1">Description</label>
                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="projectActivityDetail.description"></editor>
                                                </div>
                                            </div> -->
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Entry</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='projectActivityDetail.input'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Task</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='projectActivityDetail.task'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Verification/Validation</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='projectActivityDetail.validation'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Exit</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='projectActivityDetail.output'></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="projectActivityDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="projectActivityDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="projectActivityDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddExistingActivityPopup v-if="showDialog" @refresh-lookup="getProjectActivity" @closeDialog="showDialog=false;" />
        <DeleteComponent id="deleteProjectActivity" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Activity ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import AddExistingActivityPopup from '@/components/popup/lookup/AddExistingActivityPopup.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        AddExistingActivityPopup,
        Editor,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'project_activity',

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectActivity: {
                id: '',
                projectActivityId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                activityId: '',
                activity: '',
                input: '',
                task: '',
                validation: '',
                output: '',
                active: true,
                description: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },
            projectActivityDetail: {
                id: '',
                projectActivityId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                activityId: '',
                activity: '',
                input: '',
                task: '',
                validation: '',
                output: '',
                active: true,
                description: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            activities: [],
            projectActivityList: [],
            activityFields: [
                { key: 'projectActivityId', label: 'Activity ID', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'input', label: 'Input', sortable: true, sortDirection: 'desc' },
                { key: 'task', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'validation', label: 'Validation', sortable: true, sortDirection: 'desc' },
                { key: 'output', label: 'Output', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    mounted() {
        this.getProjectActivity();
        this.lookupData();
    },
    validations: {
        projectActivity: {
            activityId: {
                required,
            },                    
        },
        projectActivityDetail: {
            activityId: {
                required,
            },
        }

    },
    methods: {
        getProjectActivity: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/projectactivity/list',data)
                .then((response) => {
                    let result = response.data;
                    this.projectActivityList = result.projectActivityList;
                    this.totalRows = this.projectActivityList.length;
                    if (this.projectActivityList && this.projectActivityList.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.projectActivityDetail.id, this.mainTableArray, 'projectActivityTable');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/activity', data)
                .then((response) => {
                    let result = response.data;
                    this.activities = result.activityLookup;                    
                })
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            this.$v.projectActivity.$touch();
            if (!this.$v.projectActivity.$invalid) {
                this.saveActivity();
            }
        },
        saveActivity: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/projectactivity/add', this.projectActivity)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getProjectActivity();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateActivity: function() {
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/projectactivity/update', this.projectActivityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectActivity();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewProjectActivity: function(id) {
            this.showDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/general/projectactivity/view/' + id)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    let projectActivityDetail = result.activity;
                    if (projectActivityDetail) {                        
                        this.projectActivityDetail.id = projectActivityDetail.id;
                        this.projectActivityDetail.projectActivityId = projectActivityDetail.projectActivityId;
                        this.projectActivityDetail.vhextAccountId = projectActivityDetail.vhextAccountId;
                        this.projectActivityDetail.projectId = projectActivityDetail.projectId;
                        this.projectActivityDetail.projectTypeId = projectActivityDetail.projectTypeId;
                        this.projectActivityDetail.activityId = projectActivityDetail.activityId;
                        this.projectActivityDetail.activity = projectActivityDetail.activity;
                        this.projectActivityDetail.description = projectActivityDetail.description;
                        this.projectActivityDetail.input = projectActivityDetail.input;
                        this.projectActivityDetail.task = projectActivityDetail.task;
                        this.projectActivityDetail.validation = projectActivityDetail.validation;
                        this.projectActivityDetail.output = projectActivityDetail.output;
                        this.projectActivityDetail.createdById = projectActivityDetail.createdById;
                        this.projectActivityDetail.createdBy = projectActivityDetail.createdBy;
                        this.projectActivityDetail.active = projectActivityDetail.active;
                        this.projectActivityDetail.createdDt = projectActivityDetail.createdDt;
                        this.projectActivityDetail.lastUpdatedDt = projectActivityDetail.lastUpdatedDt;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewProjectActivity(this.projectActivityDetail.id);
        },
        deleteProjectActivity: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/general/projectactivity/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectActivity();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.projectActivity.id = '';
            this.projectActivity.projectActivityId = '';
            this.projectActivity.activityId = '';
            this.projectActivity.activity = '';
            this.projectActivity.input = '';
            this.projectActivity.task = '';
            this.projectActivity.validation = '';
            this.projectActivity.output = '';
            this.projectActivity.active = true;
            this.projectActivity.description = '';
            this.projectActivity.createdById = localStorage.getItem("userId");
            this.projectActivity.createdBy = '';
            this.$v.projectActivity.$reset();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.projectActivityDetail.id = data.id;
                this.projectActivityDetail.projectActivityId = data.projectActivityId;
                this.projectActivityDetail.vhextAccountId = data.vhextAccountId;
                this.projectActivityDetail.projectId = data.projectId;
                this.projectActivityDetail.projectTypeId = data.projectTypeId;
                this.projectActivityDetail.activityId = data.activityId;
                this.projectActivityDetail.activity = data.activity;
                this.projectActivityDetail.description = data.description;
                this.projectActivityDetail.input = data.input;
                this.projectActivityDetail.task = data.task;
                this.projectActivityDetail.validation = data.validation;
                this.projectActivityDetail.output = data.output;
                this.projectActivityDetail.createdById = data.createdById;
                this.projectActivityDetail.createdBy = data.createdBy;
                this.projectActivityDetail.active = data.active;
                this.projectActivityDetail.createdDt = data.createdDt;
                this.projectActivityDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.projectActivity.entity = this.entity;
                this.projectActivity.id = item.id;
                this.projectActivity.active = 0;

                this.updateActivationState();

            } else {
                this.projectActivity.entity = this.entity;
                this.projectActivity.id = item.id;
                this.projectActivity.active = 1;

                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.projectActivity)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.projectActivity.active = true;
                    this.getProjectActivity();
                })
                .catch((error)=> {
                    this.isBusy = false;
                })
        },
        viewActivity: function(id) {
            if (id) {
                this.showOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/general/activity/view/' + id)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        let projectActivity = result.activity;
                        if (projectActivity) {
                            this.projectActivity.input = projectActivity.input;
                            this.projectActivity.task = projectActivity.task;
                            this.projectActivity.validation = projectActivity.validation;
                            this.projectActivity.output = projectActivity.output;
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewActivityDet: function(id) {
            if (id) {
                this.showDetOverlay = true;
                axios.get(this.$store.getters.getAPIBasePath + '/general/activity/view/' + id)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        let projectActivityDetail = result.activity;
                        if (projectActivityDetail) {
                            this.projectActivityDetail.input = projectActivityDetail.input;
                            this.projectActivityDetail.task = projectActivityDetail.task;
                            this.projectActivityDetail.validation = projectActivityDetail.validation;
                            this.projectActivityDetail.output = projectActivityDetail.output;
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
    }
}
</script>
