<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Process / Activities</h4>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-6">
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Process / Activities</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <!-- <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Process</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.process.title.$error }">
                                                            <input type="text" class="form-control" maxlength="100" v-model="process.title" id="title" :class="{ 'is-invalid': $v.process.title.$error }" placeholder="Enter Process">
                                                            <div class="required-icon"></div>
                                                                </div>  
                                                            <label v-if="$v.process.title.$error && !$v.process.title.minLength" class="text-danger">Process must have at least {{$v.process.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.process.title.$error && !$v.process.title.required" class="text-danger">Please enter Process</label>
                                                            <label v-if="$v.process.title.$error && !$v.process.title.maxLength" class="text-danger">Process must not exceed {{$v.process.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>    -->   
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label for="process">Process</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.process.processId.$error }"  @change="onProcessChange(process.processId)">
                                                                <select v-model="process.processId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in filteredProcess" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.process.processId.$error && !$v.process.processId.required" class="text-danger">Please Select Process</label>
                                                            <a :title="processLookupTitle" class="setting-icon pl-0" href="javascript:" v-b-modal.processModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>                                           
                                                    <div class="col-1">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="process.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-11 pr-0">
                                                        <div class="form-group pr-3">
                                                            <label for="deliverables">Activities</label>
                                                            <multiselect v-model="process.activities" :options="filteredActivities" name="activity" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                        </div>
                                                        <a :title="activityLookupTitle" class="setting-icon" style="z-index: 1;" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                    </div>
                                                    <div class="col-1 pl-1">
                                                        <label style="visibility: hidden;">ETVX</label>
                                                        <button type="submit" @click="openETVX(false)" class="btn sbtn btn-xs btn-outline-secondary px-2 my-auto">ETVX</button>
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Process Procedure / Description</label>
                                                        <div class="form-group">
                                                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="process.description"></editor>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveProcessData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
<!--                                     <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div> -->
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="processTable" v-model="mainTableArray" show-empty small outlined head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" sort-icon-left :busy="isBusy" :items="processListAll"  @row-selected="onRowSelected" :fields="processFields" responsive="sm"  :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" foot-clone foot-variant=light no-footer-sorting>
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>                                
                                <template v-slot:cell(processId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control" v-model="data.item.processId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.processId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.processId"> {{ data.item.processId }}</div>
                                </template>

                                <template v-slot:cell(description)="data">
                                    <span class="tooltiptext" href="#" v-html="data.value"></span>
                                </template>

                                <template v-slot:cell(active)="data">
                                    <div v-if="data.item.createdById == userId">
                                        <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </div>
                                    <div v-else>
                                        <span v-if="data.item.active" class="text-success ml-3" title="UnEditable" ><i class="fa fa-check"></i></span>
                                    </div>                                    
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <div v-if="data.item.createdById == userId || userTypeCode == 'CAM' || userTypeCode == 'CRM' || userTypeCode == 'VSA'">
                                        <a href="javascript:" title="Delete" @click="deleteProcessActivity(data.item.processId)" v-b-modal.deleteProcessActivity><i class="fa fa-trash pl-2"></i></a>
                                    </div>
                                    <div v-else>
                                        <a href="javascript:" class="text-center" title="NA">NA</a>
                                    </div>                                    
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Process found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                                <template v-slot:foot()="data">
                                    <span></span>
                                </template>                                
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
<!--                             <b-pagination v-if="processListAll.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm" >
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{processDetail.processId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div v-if="processDetail.createdById == userId || userTypeCode == 'CAM' || userTypeCode == 'CRM' || userTypeCode == 'VSA'" class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="saveProcessData" :disabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                                <!-- <div v-else><i class="fa fa-ban" aria-hidden="true"></i></div> -->
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="form-group">
                                                    <label for="titleDet">Process</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.processDetail.title.$error }">

                                                        <input type="text" class="form-control" id="titleDet" maxlength="100" :disabled="!elements.editMode" v-model='processDetail.title'>
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.processDetail.title.$error && !$v.processDetail.title.minLength" class="text-danger">Process must have at least {{$v.processDetail.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.processDetail.title.$error && !$v.processDetail.title.required" class="text-danger">Please enter Process</label>
                                                    <label v-if="$v.processDetail.title.$error && !$v.processDetail.title.maxLength" class="text-danger">Process must not exceed {{$v.processDetail.title.$params.maxLength.max}} letters.</label>                                                    
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label for="activeDet">Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="processDetail.active" :disabled="!elements.editMode" name="check-button" switch id="activeDet">
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <b-row class="mb-1">
                                                    <b-col class="d-block mb-0 pl-3">Activities
                                                        <button type="submit" @click="openETVX(true)" title="Entry,Task,Verify,Exit"class="btn sbtn btn-xs btn-outline-secondary px-2 pb-0 float-right">ETVX</button>
                                                    </b-col>
                                                </b-row>

<!--                                                 <div class="form-group">
                                                    
                                                    <div class="d-block">Activities
                                                        <button type="submit" @click="openETVX(true)" title="Entry,Task,Verify,Exit" class="btn sbtn btn-xs btn-outline-secondary px-2 float-right">ETVX</button>
                                                    </div>
                                                </div> -->
                                                <b-row class="mb-3">
                                                    <b-col v-if="elements.editMode" cols="11 pr-1">
                                                        <multiselect v-model="processDetail.activities" :options="filteredActivities" name="activity" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                    </b-col>
                                                    <a v-if="elements.editMode" :title="activityLookupTitle" class="action-link" href="javascript:"  v-b-modal.activityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                                    <b-col v-else cols="12 mr-2">
                                                        <multiselect v-model="processDetail.activities" :options="filteredActivities" :disabled="true" name="activity" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>                                                       
                                                    </b-col>
                                                </b-row>                                                                                                
                                            </div>
                                            <div class="col-12">
                                                <label for="description">Process Procedure / Description</label>
                                                <div v-if="elements.editMode" class="form-group">
                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="processDetail.description"></editor>
                                                </div>
                                                <div v-else class="form-group">
                                                    <p class="pl-2"><span v-html="processDetail.description"></span></p>
                                                </div>                                                
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model='processDetail.userName' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="processDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="processDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProcessDetailPopup v-if="showDialog" ref="processDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <ActivityLookup ref="activityLookupModal" :projectTypeId="this.projectTypeId" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" :key="activityKey" />
<!--         <AddExistingProcessPopup v-if="showDialog" :etvxEditMode="etvxEditMode" @refresh-lookup="getProcessActivityList" @closeDialog="showDialog=false;" /> -->
        <ActivityETVXPopup ref="activityETVXPopupRef" @updateETVXActivities="updateETVXActivities" />
        <ProcessLookup ref="processLookupModal" :entity="this.entity" :processLookupTitle="processLookupTitle" @refresh-lookup="lookupData();getProcessActivityList();" />
        <DeleteComponent id="deleteProcessActivity" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Process ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
import ActivityLookup from '@/components/popup/lookup/ActivityLookup.vue';
// import AddExistingProcessPopup from '@/components/popup/lookup/AddExistingProcessPopup.vue';
import ActivityETVXPopup from '@/components/popup/action/ActivityETVXPopup.vue';
import ProcessLookup from '@/components/popup/lookup/ProcessLookup.vue';
import ProcessDetailPopup from '@/components/popup/itemdetail/ProcessDetailPopup.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Editor,
        ProcessDetailPopup,
        // AddExistingProcessPopup,
        ActivityLookup,
        ActivityETVXPopup,
        Multiselect,
        ProcessLookup,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,

            detailViewData: {},
            referencePopupId: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'process_activity',
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            selProcessId: '',

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            erdId: '',
            processLookupTitle: 'Process Settings',
            activityLookupTitle: 'Activity Settings',
            etvxEditMode: true,

            mainTableArray: [],

            idValue: '',
            idEdit: '',

            activityKey: 0,

            process: {
                id: '',
                processId: '',
                title: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                projectType: '',
                active: true,
                description: '',
                activities: [],
                createdById: localStorage.getItem("userId"),            
            },
            processDetail: {
                id: '',
                title: '',
                description: '',
                userName: '',
                active: '',
                createdById: '',
                processId: '',
                projectTypeId: '',
                projectType: '',
                activities: [],
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            projectTypes: [],
            users: [],

            processListAll: [],
            processes: [],
            activities: [],
            processActivities: [],

            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            processFields: [
                {key:'index',label: 'SN'},
                // { key: 'processId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc'},
                { key: 'title', label: 'Process', sortable: true, sortDirection: 'desc' },
                // { key: 'description', label: 'Process Procedure / Description', sortable: true, sortDirection: 'desc' },
                { key: 'activities', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'projectType', label: 'Project Type', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            projectERMListFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

        }
    },
    computed: {
        filteredProcess(){
            return this.processes.filter(item => !this.processListAll.some(item2 => item2.processId == item.id));
        },
        filteredActivities(){
            console.log("PA",this.processActivities);
            console.log("ACT",this.activities);
            console.log("PID",this.processDetail.processId);
            return this.activities.filter(item => !this.processActivities.some(item2 => item2.id == item.id 
                && item2.processId != this.selProcessId));
        }

    },
    mounted() {
        this.initialSettings();
        this.getProcessActivityList();
        this.lookupData();
    },
    validations: {
        process: {
            processId: {
                required,
            },            
            description: {
                maxLength: maxLength(2000)
            }
        },

        processDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            // projectTypeId: {
            //     required
            // },                        
            description: {
                maxLength: maxLength(2000)
            }
        },

    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = this.entity;
            this.userId = localStorage.getItem("userId");
            this.projectTypeId = localStorage.getItem("projectTypeId");
            console.log(this.projectTypeId)

        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/process/lookup/all' , data) 
            .then((response) => {
                let result = response.data;
                this.projectTypes = result.projectTypeLookup;                    
                this.users = result.userLookup;
                this.processes = result.processLookup;                    
                this.activities = result.activityLookup;
            })
        },

        getProcessActivityList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/list',data)
                .then((response) => {
                    let result = response.data;
                    this.processListAll = result.processActivityList;
                    this.totalRows = this.processListAll.length;
                    if (this.processListAll && this.processListAll.length > 0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.processDetail.processId, this.mainTableArray, 'processTable', 'processId');
                        })
                    }
                    else {
                        this.elements.showDetail = false;
                    }
                })
        },
        getProcessActivities: function(processId) {
            if (processId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    processId: processId,
                    refEntity: 'process',
                }
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/activity',data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        // if (this.elements.visible && !this.elements.showDetail) {
                        //     this.process.activities = result.processActivityLookup;
                        // }
                        this.processActivities = result.processActivityLookupAll;
                        this.processDetail.activities = result.processActivityLookup;
                        console.log("PA",this.processDetail.activities);
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },

        onProcessChange(processId) {

            this.activityKey += 1;

            this.elements.showDetail = false;

            this.selProcessId = processId;
            // this.getProcessActivities(processId);
            this.process.description = this.processes.find(data => data.id == processId).description;
        },          
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },

        saveProcessData: function() {
            if (this.elements.editMode) {
                this.updateProcess();
            } else {
                this.addProcess();
            }
        },

        addProcess: function() {
            if (this.matchTextValExists(this.process.processId)) {
                iziToast.info({
                    message: 'Process already exist.',
                    position: 'topCenter'
                });
            }
            else {
                this.$v.process.$touch();
                // alert('a');
                if (!this.$v.process.$invalid) {
                    this.process.vhextAccountId=this.vhextAccountId;
                    this.process.projectId = null;
                    this.process.createdById = this.userId;
                    this.process.processActivityList = [];

                    console.log("ACT_A",this.process.activities);

                    this.process.activities.forEach((data) => {
                        this.process.processActivityList.push({ 
                            activityId: data.id, 
                            activity: data.value,
                            input: data.input ? data.input : '',
                            output: data.output ? data.output : '',
                            task: data.task ? data.task : '',
                            verify: data.verify ? data.verify : '',
                        });
                    })
                    // this.$v.process.$touch();
                    // if (!this.$v.process.$invalid) {
                    console.log("ACT_A",this.process);
                    // return;                    
                    this.showOverlay = true;
                    axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/add', this.process)
                        .then((response) => {
                            this.showOverlay = false;
                            let result = response.data;
                            if (result.status == "CREATED") {
                                iziToast.success({
                                    title: 'Success',
                                    message: result.message,
                                    position: 'topRight'
                                });
                                this.clearData();
                                this.getProcessActivityList();
                            }
                        })
                        .catch(error => {
                            this.showOverlay = false;
                            iziToast.error({
                               title: 'Error',
                               message: 'Error',
                               position: 'topRight'
                           });
                        })
                // }
                }
            }
        },
        updateProcess: function() {
            if (this.matchTextValExists(this.processDetail.processId, this.processDetail.title)) {
                iziToast.info({
                    message: 'Process already exist.',
                    position: 'topCenter'
                });
                return;
            }
            this.$v.processDetail.$touch();
            if (!this.$v.processDetail.$invalid) {

                this.processDetail.vhextAccountId = this.vhextAccountId;
                this.processDetail.projectId = null;
                this.processDetail.processActivityList = [];

                console.log("ACT_U",this.processDetail.activities);

                this.processDetail.activities.forEach((data) => {
                    this.processDetail.processActivityList.push({
                        activityId: data.id, 
                        activity: data.value,
                        input: data.input ? data.input : '',
                        output: data.output ? data.output : '',
                        task: data.task ? data.task : '',
                        verify: data.verify ? data.verify : '',
                    });
                })
                console.log("ACT_U",this.processDetail.processActivityList);
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/update', this.processDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$v.processDetail.$reset();
                            this.getProcessActivityList();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateId: function(id,newErdId) {
            console.log(id,newErdId);
            if (!this.matchNumValExists(id,newErdId)) {
                let data = {
                    idInitType: "VHA",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            this.clearData();
                            this.getProcessActivityList();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'
                });
                this.idEdit='';
                this.getProcessActivityList();                
            }
            
        },
        cancelIdEdit: function() {
            this.processListAll = this.processListAll.map(data => {
                if(data.id == this.idEdit) {
                    data.processId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },                        
        viewProcess: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/process/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let processDetail = result.process;
                    if (processDetail) {                        
                        this.processDetail.id = processDetail.id;
                        this.processDetail.title = processDetail.title;
                        this.processDetail.processId = processDetail.processId;
                        this.processDetail.userName = processDetail.userName;
                        this.processDetail.description = processDetail.description;
                        this.processDetail.active = processDetail.active;
                        // this.processDetail.processActivityList = JSON.parse(data.activities);
                        this.processDetail.createdDt = processDetail.createdDt;
                        this.processDetail.lastUpdatedDt = processDetail.lastUpdatedDt;
                        this.getProcessActivities(this.processDetail.id)
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewProcess(this.processDetail.id);
        },
        deleteProcessActivity: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                processId: this.deleteParam.id,               
            }
            console.log(data);
            // return;
            axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/delete' , data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProcessActivityList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },                

        // deleteProcessActivity: function(id) {
        //     this.deleteParam.id = id;
        // },
        // onYes: function() {
        //     let data = {
        //         vhextAccountId: localStorage.getItem("vhextAccountId"),
        //         projectId: localStorage.getItem("projectId"),
        //         entity: this.entity,
        //         erdId: this.deleteParam.id,               
        //     }
        //     console.log(data);

        //     axios.post(this.$store.getters.getAPIBasePath + '/process/activity/delete/',data)
        //         .then(response => {
        //             var result = response.data;
        //             if (result.status == "OK") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //                 this.getProcessActivityList();
        //             }
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.process.title = '';
            this.process.processId = '';
            this.process.createdById = localStorage.getItem("userId");
            this.process.description = '';
            this.process.active = true;
            this.process.activities = [];
            this.$v.process.$reset();
        },
        onRowSelected: function(items) {
            this.processDetail.activities = [];
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.elements.visible = false;

                this.selProcessId = data.processId;

                this.processDetail.id = data.id;
                this.processDetail.title = data.title;
                this.processDetail.processId = data.processId;
                this.processDetail.userName = data.userName;
                this.processDetail.createdById = data.createdById;
                // this.processDetail.description = data.description;
                if (data.description != null) {
                    this.processDetail.description = data.description;
                } else {
                    this.processDetail.description = '';
                }
                // this.processDetail.activities = data.activities;
                this.processDetail.active = data.active;
                this.processDetail.createdDt = data.createdDt;
                this.processDetail.lastUpdatedDt = data.lastUpdatedDt;

                this.activityKey += 1;

                this.getProcessActivities(this.processDetail.processId);
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.process.entity = 'process_activity';
                this.process.id = item.id;
                this.process.active = 0;

            } else {
                this.process.entity = 'process_activity';
                this.process.id = item.id;
                this.process.active = 1;
                
            }
            this.updateActivationState();
            console.log(this.process.id, this.process.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.process)
                .then((response) => {
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.process.active = true;
                    this.getProcessActivityList();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },
        openETVX: function(isEditMode) {
            this.etvxEditMode = this.elements.editMode ? true : false;
            if (isEditMode) {
                this.$nextTick(() => {
                    this.$refs.activityETVXPopupRef.setActivity(this.processDetail.activities, this.etvxEditMode);
                })
            }
            else {
                this.$nextTick(() => {
                    this.$refs.activityETVXPopupRef.setActivity(this.process.activities, this.etvxEditMode);
                })
            }
        },
        updateETVXActivities: function(activities) {
            console.log(activities);
            console.log(this.processDetail.activities);
            if (this.elements.visible) {
                this.process.activities= [];
                this.process.activities = activities;
            }
            else 
                this.processDetail.activities= [];
                this.processDetail.activities = activities;
        },
        matchTextValExists: function(id, title) {
            let result = false;
            this.processListAll.map(data => {
                if (title) {
                    if ((data.processId != id) && (data.title == title)) {
                        result = true;
                    }
                }
                else if (data.processId == id) {
                    result = true;
                }
            });
            return result;
        },
        matchNumValExists: function(id,numVal) {
            let result = false;
            this.processListAll.map(data => {
                if (numVal && data.processId && data.id !== id) {
                    if (data.processId == numVal) {
                        result = true;
                    }
                }
            });
            return result;
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.processDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }
    }
}
</script>
