<template>
    <div>
        <b-overlay v-if="appLoading" :show="appLoading" blur="2px" no-wrap spinner-variant="primary" variant="white" opacity="0.5">
            <template #overlay>
                <div class="text-center">
                    <LoadingSpinner></LoadingSpinner>
                    <p v-if="appLoadingContent">{{ appLoadingContent }}</p>
                    <p v-else>Please wait...</p>
                </div>
            </template>
        </b-overlay>
        <div v-show="!appLoading" :key="appReloadKey" id="app" v-bind:class="[(layout === 'login-layout') ? 'login-layout' : '']">
            <component :is="layout">
                <router-view class="position-relative" />
            </component>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
const default_layout = "default";
export default {
    computed: {
        ...mapState(['appReloadKey']),
        layout() {
            return (this.$route.meta.layout || default_layout) + "-layout";
        },
        appLoading: {
            get() {
                return this.$store.state.appLoading;
            },
            set(value) {
                this.$store.state.appLoading = value;
            }
        },
        appLoadingContent: {
            get() {
                return this.$store.state.appLoadingContent;
            },
        },
    },
    data() {
        return {
        }
    },
    mounted() {
        if (this.layout == "login-layout" || this.layout == "home-layout") {
            $('#globalapploader').fadeOut('slow');
        }
    },
    name: 'App'
}
</script>
