<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Company Info</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row px-2">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-4">
                        <div class="card-body p-3 ">
                            <div id="profile" class="pb-0 pt-0">                                
                                <div class="text-left">
                                    <div class="row mb-2 mt-2 text-left">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="card mb-3">
                                                        <div class="card-body p-1">
                                                            <div class="my-company-img my-3">
                                                                <img :src="company.logo" alt="Company Logo">
                                                                <label class="upload-icon upload-icon-contacts">
                                                                    <i class="fa fa-upload"></i>
                                                                     <input ref="companyLogo" type="file" accept="image/*" @change="previewImage" class="uploadFile img" id="companyLogo" style="width: 0px;height: 0px;overflow: hidden;">                                                                    
                                                                </label>
                                                                <a href="javascript:" @click="this.$router.go()"><i class="fa fa-refresh fa-lg pull-right action-link pr-2 pt-2 pointer"></i></a>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12">
                                                    <h6>Company Info
                                                        <div class="d-inline">
                                                            <button v-if="!elements.companyInfoEditMode" href="javascript:" @click="elements.companyInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                            <div class="d-inline" v-if="elements.companyInfoEditMode">
                                                                <button type="submit" @click="updateCompany" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                                <button type="submit" @click="clearData('Details')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-6">
                                                    <div class="ml-3">
                                                        <b-row class="mb-2">
                                                            <b-col cols="5">Company Name</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                <label v-if="!elements.companyInfoEditMode">{{company.companyName}}</label>                                                                                     
                                                                <div v-if="elements.companyInfoEditMode" class="required-field-block" :class="{ 'is-invalid': $v.company.companyName.$error }">                                                                                
                                                                    <input v-if="elements.companyInfoEditMode" type="text" maxlength="100" class="form-control" v-model="company.companyName" id="companyName" :class="{ 'is-invalid': $v.company.companyName.$error }" >
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="elements.companyInfoEditMode && $v.company.companyName.$error && !$v.company.companyName.minLength" class="text-danger">Company name must have at least {{$v.company.companyName.$params.minLength.min}} letters.</label>
                                                                <label v-if="elements.companyInfoEditMode && $v.company.companyName.$error && !$v.company.companyName.required" class="text-danger">Please enter Company name</label>
                                                                <label v-if="elements.companyInfoEditMode && $v.company.companyName.$error && !$v.company.companyName.maxLength" class="text-danger">Company name must not exceed {{$v.company.companyName.$params.maxLength.max}} letters.</label>

                                                            </b-col>
                                                        </b-row>
                                                        <b-row class="mb-2">
                                                            <b-col cols="5">Industry</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                <label v-if="!elements.companyInfoEditMode">{{company.industry}}</label>                                                   
                                                                <select v-if="elements.companyInfoEditMode" v-model="company.industryId" class="form-control" id="industryId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in industries" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </b-col>
                                                        </b-row>
                                                         <b-row class="mb-2">
                                                            <b-col cols="5">Short Name</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                <label v-if="!elements.companyInfoEditMode">{{company.shortName}}</label>
                                                                <input v-if="elements.companyInfoEditMode" type="text" class="form-control" v-model="company.shortName">
                                                            </b-col>
                                                        </b-row>
                                                         <b-row class="mb-2">
                                                            <b-col cols="5">Director</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                 <label v-if="!elements.companyInfoEditMode">{{company.ownerName}}</label>
                                                                <input v-if="elements.companyInfoEditMode" type="text" class="form-control" v-model="company.ownerName">
                                                            </b-col>
                                                        </b-row>               
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>

                            <div id="profile" class="pb-0 pt-0">                                
                                <div class="text-left">
                                    <div class="row mb-2 mt-2 text-left">
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <h6>Contact Person
                                                        <div class="d-inline">
                                                            <button v-if="!elements.contactPersonEditMode" href="javascript:" @click="elements.contactPersonEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                                            </button>
                                                            <div class="d-inline" v-if="elements.contactPersonEditMode">
                                                                <button type="submit" @click="updateCompany" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                                <button type="submit" @click="clearData('Details')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                            </div>
                                                        </div>
                                                    </h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="ml-3">
                                                        <b-row class="mb-2">
                                                            <b-col cols="5">Contact Person</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                 <label v-if="!elements.contactPersonEditMode">{{company.contactName}}</label>
                                                                <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.contactName">                                                                
                                                            </b-col>
                                                        </b-row>
                                                        <b-row class="mb-2">
                                                            <b-col cols="5">EmailId</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                 <label v-if="!elements.contactPersonEditMode">{{company.emailId}}</label>
                                                                <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.emailId">                                                                
                                                            </b-col>
                                                        </b-row>
                                                        <b-row class="mb-2">
                                                            <b-col cols="5">Mobile</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                 <label v-if="!elements.contactPersonEditMode">{{company.mobile}}</label>
                                                                <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.mobile">                                                                
                                                            </b-col>
                                                        </b-row>
                                                        <b-row class="mb-2">
                                                            <b-col cols="5">Phone</b-col>
                                                            <b-col cols="7" class="pl-0">
                                                                 <label v-if="!elements.contactPersonEditMode">{{company.phone}}</label>
                                                                <input v-if="elements.contactPersonEditMode" type="text" class="form-control" v-model="company.phone">                                                                
                                                            </b-col>
                                                        </b-row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 mb-4 px-2">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <div id="profile" class="pb-0 pl-0 pr-0">
                                <!-- Right Side -->

                                <div class="profile_header text-left">
                                    <div class="row mb-2 text-left">
                                        <div class="col-8">
                                            <h6>Contact Info</h6>
                                        </div>
                                        <div class="col-md-4 col-sm-4 pl-3 float-right">
                                            <div class="text-right">
                                                <button v-if="!elements.companyContactInfoEditMode" href="javascript:" @click="elements.companyContactInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.companyContactInfoEditMode">
                                                    <button type="submit" @click="updateCompany" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="clearData('Details')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ml-3">
                                            <b-row class="mb-2">
                                            <b-col cols="4">Office Phone</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.companyContactInfoEditMode">{{company.officePhone}}</label>
                                                <input v-if="elements.companyContactInfoEditMode" type="text" class="form-control" v-model="company.officePhone">
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-2">
                                            <b-col cols="4">Email Id</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.companyContactInfoEditMode">{{company.alternateEmailId}}</label>
                                                <input v-if="elements.companyContactInfoEditMode" type="text" class="form-control" v-model="company.alternateEmailId">
                                            </b-col>
                                        </b-row>
                                        <b-row class="mb-2">
                                            <b-col cols="4">Website</b-col>
                                            <b-col cols="8">
                                                <label v-if="!elements.companyContactInfoEditMode">{{company.website}}</label>
                                                <input v-if="elements.companyContactInfoEditMode" type="text" class="form-control" v-model="company.website">
                                            </b-col>
                                        </b-row>          
                                        <b-row v-if="false" class="mb-2" >
                                            <b-col cols="4">Logo</b-col>
                                            <b-col cols="8">
                                                <input v-if="elements.companyInfoEditMode" ref="companyLogo" type="file" accept="image/*" @change="previewImage" class="form-control-file" value="dfdf" id="companyLogo">

                                                <div class="border p-2 mt-3" v-if="elements.companyInfoEditMode">                                                    
                                                    <template v-if="preview">
                                                    <img :src="preview" class="img-fluid" />
                                                    <!--  {{getFileName(preview)}} -->
                                                    <!-- <p class="mb-0">file name: {{ image.name }}</p> -->
                                                    <!-- <p class="mb-0">size: {{ image.size/1024 }}KB</p> -->
                                                    </template>
                                                </div>   

                                                <div v-if="!elements.companyInfoEditMode">
                                                    <img :src="company.logo" class="Companylogo-img" />
                                                </div>      
                                            </b-col>
                                        </b-row>    
                                        
                                                                                
                                    </div>                                        
                                </div>
                                <div class="profile_header text-left">
                                    <div class="row mb-2 text-left">
                                        <div class="col-8">
                                            <h6>Address Info</h6>
                                        </div>
                                        <div class="col-md-4 col-sm-4 pl-3 float-right">
                                            <div class="text-right">
                                                <button v-if="!elements.companyAddressInfoEditMode" href="javascript:" @click="elements.companyAddressInfoEditMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.companyAddressInfoEditMode">
                                                    <button type="submit" @click="updateCompany" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="clearData('Details')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!elements.companyAddressInfoEditMode" class="pl-2 ml-3">
                                        <p v-if="company.address1 != ''">
                                            <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                            <span v-if="company.address1 != ''">{{company.address1}}</span>                                                 
                                            <span v-if="company.streetName != ''">, {{company.streetName}}</span>
                                                    <span v-if="company.city != ''">, {{company.city}}</span>
                                            <span v-if="company.state != ''">, {{company.state}}</span>                                               
                                            <span v-if="company.country != ''">, {{company.country}}</span>
                                            <span v-if="company.pincode != ''">, {{company.pincode}}</span>
                                        </p>
                                    </div>
                                    <div class="ml-3">
                                        <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Address Line 1</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="company.address1">
                                            </b-col>
                                        </b-row>                                                
                                        <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Street Name</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="company.streetName">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                            <b-col cols="4">City</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="company.city">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                            <b-col cols="4">State</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="company.state">
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Country</b-col>
                                            <b-col cols="8">
                                                <select v-if="elements.companyAddressInfoEditMode" v-model="company.countryId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </b-col>
                                        </b-row>
                                        <b-row  v-if="elements.companyAddressInfoEditMode" class="mb-2">
                                            <b-col cols="4">Pin Code</b-col>
                                            <b-col cols="8">
                                                <input  type="text" class="form-control" v-model="company.pincode">
                                            </b-col>
                                        </b-row>
                                    </div>                                       
                                </div>

                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteHumanResource" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete resource?" />
        <Dialog id="deleteRoleResponsibility" :onYes="onYesRole" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Role Responsibility?" />
        <Dialog id="deleteTechnologySkill" :onYes="onYesTech" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Technology Skill?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import month from '../../json/month.json'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entity: 'human_resource',
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            preview: null,
            
            monthList: month,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                primaryInfoEditMode: false,
                userInfoEditMode: false,
                userDefaultProjectEditMode: false,
                contactInfoEditMode: false,
                companyInfoEditMode: false,  
                companyAddressInfoEditMode: false,
                companyContactInfoEditMode: false,  
                contactPersonEditMode:false,        
                addressInfoEditMode: false,
                roleEditMode: false,
                techEditMode: false,
                contactEditMode: false,
                showDropdownRole: false,
                showDropdownTech: false,
            },
            user: {
                id: '',
                userId: '',
                name: '',
                loginName: '',
                type: '',
                email_id: '',
                phone: '',
                mobile: '',
                defaultProjectId: '',
                active: '',
            },
            humanResource: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'human_resource',
                userId: '',
                loginName: '',
                userType: '',
                humanResourceId: '',                
                name: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            humanResourceDetails: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'human_resource',
                userId: '',
                loginName: '',
                humanResourceId: '', 
                humanResourceTypeId: '',             
                name: '',
                emailId: '',
                mobile: '',
                phone: '',
                position:'',
                qualification: '',
                experienceYears:'',
                experienceMonths:'',
                roleResponsibility:'',
                technologySkill:'',
                recruitedById:'',
                recruitedBy:'',
                joiningDate: '',
                createdBy: '',
                createdById: '',
            },
            contactDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntity: 'human_resource',
                refRecordId: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                contactName: '',
                emailId:'',
                alternateEmailId:''
            },
            roleResponsibilityDetail: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entity: 'role_responsibility',
                refEntity: 'human_resource',
                refRecordId: '',
                role: '',
                roleData: '',
                responsibility: '',
                responsibilityArray: [],
                editId: ''

            },
            technologySkillDetail: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),                
                entity: 'technology_skill',
                refEntity: 'human_resource',
                refRecordId: '',
                technologySkillId: '',
                preparedById: localStorage.getItem("userId"),
                technology: '',
                technologyData: '',
                skills: '',
                skillsArray: [],
                editId: ''
            },
            company:[],
            activation:{
                oldPassword:'',
                newPassword: '',
                confirmPassword:''
            },

            humanResources: [],
            responsibilities: [],
            technologySkills: [],
            contacts: [],
            countries: [],
            projects: [],
            industries:[],
            transProps: {
                // Transition name
                name: 'flip-list'
            },

            selectMode: 'single',

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.loadOnce = true;
        if(this.userTypeCode === 'CLI')
        {
            // this.viewHumanResource();
        }
        else
        {
            this.viewUser();
        }

        this.lookupData();
    },
    validations: {
        activation: {
            newPassword: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('newPassword')
            }
        },
        humanResource: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        },
        roleResponsibilityDetail: {
            role: {
                required
            }
        },
        technologySkillDetail: {

            technology: {
                required
            }
        },
        company: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        viewUser: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/user/view', data)
                .then((response) => {
                    let result = response.data;
                    this.user = result.user;
                    console.log(this.user);
                })

                this.viewHumanResource();
                this.viewCompany();

                this.elements.editMode = false;
                this.elements.primaryInfoEditMode = false;
                this.elements.userInfoEditMode = false;
                this.elements.userDefaultProjectEditMode = false;
                this.elements.contactInfoEditMode = false;
                this.clearCompany();  
                this.elements.addressInfoEditMode = false;
        },

        viewHumanResource: function() {
            axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/userid/' + this.userId)
                .then((response) => {
                    let result = response.data;
                    this.humanResourceDetails = result.humanResourceView;
                    this.getContacts(this.humanResourceDetails.id);
                    console.log(this.humanResourceDetails)
                })
                
        },
        getContacts: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.humanResourceDetails.id,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/hr/list', data)
                .then((response) => {
                    let result = response.data;
                        console.log(result);
                    if (result.hRContactList.length > 0) {
                        this.contacts = result.hRContactList[0];
                        this.contactDetail.id = (this.contacts.id == undefined) ? '' : this.contacts.id;
                        this.contactDetail.projectId = (this.contacts.projectId == undefined) ? '' : this.contacts.projectId;
                        this.contactDetail.vhextAccountId = (this.contacts.vhextAccountId == undefined) ? '' : this.contacts.vhextAccountId;
                        this.contactDetail.refEntity = (this.contacts.refEntity == undefined) ? '' : this.contacts.refEntity;
                        this.contactDetail.refRecordId = (this.contacts.refRecordId == undefined) ? '' : this.contacts.refRecordId;
                        this.contactDetail.phone = (this.contacts.phone == undefined) ? '' : this.contacts.phone;
                        this.contactDetail.mobile = (this.contacts.mobile == undefined) ? '' : this.contacts.mobile;
                        this.contactDetail.alternateMobile = (this.contacts.alternateMobile == undefined) ? '' : this.contacts.alternateMobile;
                        this.contactDetail.address1 = (this.contacts.address1 == undefined) ? '' : this.contacts.address1;
                        this.contactDetail.address2 = (this.contacts.address2 == undefined) ? '' : this.contacts.address2;
                        this.contactDetail.officePhone = (this.contacts.officePhone == undefined) ? '' : this.contacts.officePhone;
                        this.contactDetail.streetName = (this.contacts.streetName == undefined) ? '' : this.contacts.streetName;
                        this.contactDetail.city = (this.contacts.city == undefined) ? '' : this.contacts.city;
                        this.contactDetail.state = (this.contacts.state == undefined) ? '' : this.contacts.state;
                        this.contactDetail.countryId = (this.contacts.countryId == undefined) ? '' : this.contacts.countryId;
                        this.contactDetail.country = (this.contacts.country == undefined) ? '' : this.contacts.country;
                        this.contactDetail.pincode = (this.contacts.pincode == undefined) ? '' : this.contacts.pincode;
                        this.contactDetail.contactName = (this.contacts.contactName == undefined) ? '' : this.contacts.contactName;
                        this.contactDetail.emailId = (this.contacts.emailId == undefined) ? '' : this.contacts.emailId;
                        this.contactDetail.alternateEmailId = (this.contacts.alternateEmailId == undefined) ? '' : this.contacts.alternateEmailId;
                        this.contactDetail.photo = this.contacts.photo == null ? this.defaultPhoto : this.contacts.photo;
                        console.log(this.contactDetail);
                    }
                    else {
                        this.clearContact();
                    }
                })
        },
        setDefaultProject: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: (this.user.defaultProjectId == '' ? 0 : this.user.defaultProjectId),
                userId: this.userId,
            }
            console.log( data);
            axios.post(this.$store.getters.getAPIBasePath + '/user/setdefaultproject', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.viewUser();
                    }
                })
        },

         getHumanResource: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result != null && result.length != 0) {
                        this.humanResources = result.humanResourceList;
                        this.totalRows = this.humanResources.length;
                        if (this.loadOnce && this.humanResources != null && this.humanResources.length > 0) {
                            this.elements.showDetail = true;
                            this.humanResourceDetails.id = 32;
                            this.viewHumanResource();
                        }
                        this.elements.editMode = false;
                        this.elements.primaryInfoEditMode = false;
                        this.elements.userInfoEditMode = false;
                        this.elements.userDefaultProjectEditMode = false;
                        this.elements.contactInfoEditMode = false;
                        this.clearCompany(); 
                        this.elements.addressInfoEditMode = false;
                        this.humanResource.totalRows = this.humanResources.length;
                        this.statusText = this.humanResources.status;
                        this.loadOnce = false;
                        this.getOtherHRDetails();
                        
                        console.log(this.humanResources.totalRows);
                    }
                });
        },
        // getContacts: function(humanResourceId) {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         refEntity: this.entity,
        //         refRecordId: humanResourceId,
        //     }
        //     console.log(data);
        //     axios.post(this.$store.getters.getAPIBasePath + '/contact/cmr/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             console.log(result.cmrContactList);
        //             if (result.cmrContactList.length > 0) {
        //                 this.contacts = result.cmrContactList[0];
        //                 this.contactDetail.id = (this.contacts.id == undefined) ? '' : this.contacts.id;
        //                 this.contactDetail.projectId = (this.contacts.projectId == undefined) ? '' : this.contacts.projectId;
        //                 this.contactDetail.vhextAccountId = (this.contacts.vhextAccountId == undefined) ? '' : this.contacts.vhextAccountId;
        //                 this.contactDetail.refEntity = (this.contacts.refEntity == undefined) ? '' : this.contacts.refEntity;
        //                 this.contactDetail.refRecordId = (this.contacts.refRecordId == undefined) ? '' : this.contacts.refRecordId;
        //                 this.contactDetail.phone = (this.contacts.phone == undefined) ? '' : this.contacts.phone;
        //                 this.contactDetail.mobile = (this.contacts.mobile == undefined) ? '' : this.contacts.mobile;
        //                 this.contactDetail.alternateMobile = (this.contacts.alternateMobile == undefined) ? '' : this.contacts.alternateMobile;
        //                 this.contactDetail.officePhone = (this.contacts.officePhone == undefined) ? '' : this.contacts.officePhone;
        //                 this.contactDetail.address1 = (this.contacts.address1 == undefined) ? '' : this.contacts.address1;
        //                 this.contactDetail.streetName = (this.contacts.streetName == undefined) ? '' : this.contacts.streetName;
        //                 this.contactDetail.city = (this.contacts.city == undefined) ? '' : this.contacts.city;
        //                 this.contactDetail.state = (this.contacts.state == undefined) ? '' : this.contacts.state;
        //                 this.contactDetail.countryId = (this.contacts.countryId == undefined) ? '' : this.contacts.countryId;
        //                 this.contactDetail.country = (this.contacts.country == undefined) ? '' : this.contacts.country;
        //                 this.contactDetail.pincode = (this.contacts.pincode == undefined) ? '' : this.contacts.pincode;
        //                 this.contactDetail.contactName = (this.contacts.contactName == undefined) ? '' : this.contacts.contactName;
        //                 this.contactDetail.emailId = (this.contacts.emailId == undefined) ? '' : this.contacts.emailId;
        //                 this.contactDetail.alternateEmailId = (this.contacts.alternateEmailId == undefined) ? '' : this.contacts.alternateEmailId;   
        //                this.contactDetail.photo = this.contacts.photo == null ? this.defaultPhoto : this.contacts.photo;
        //                 console.log(this.contactDetail);
        //             }
        //             else {
        //                 this.clearContact();
        //             }
        //         })
        // },
        getOtherHRDetails: function() {
            console.log((this.humanResourceDetails.id))
            if (typeof this.humanResourceDetails.id !== 'undefined' && this.humanResourceDetails.id) {
                    this.getRoleResponsibilities();
                    this.getTechnologySkills();
                    this.getContacts();
                }
        },
        getRoleResponsibilities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.humanResourceDetails.id,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrroleresponsibility/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.responsibilities = result.cmrRoleResponsibilityList;
                        console.log(result.cmrRoleResponsibilityList);
                    }
                })
        },

        getTechnologySkills: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: this.humanResourceDetails.id,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/cmrtechnologyskill/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.technologySkills = result.cmrTechnologySkillList;
                        console.log(result);
                    }
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
             let data = {
                projectId: this.company.projectId,
                vhextAccountId: this.company.vhextAccountId,
                entity: this.company.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data)
                .then((response) => {
                    let result = response.data;   
                    this.industries= result.industryLookup; 
                    this.countries = result.countryLookup;
                })
            
        },
        submitPassword: function() {
            this.$v.activation.$touch();
            if (!this.$v.activation.$invalid) {
                this.changePassword();
            }
        },
        changePassword: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/user/changepassword', this.activation)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                    }
                    if (result.status == "OK") {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
        },
        
        // saveData: function() {
        //     console.log(this.humanResource);
        //     this.$v.humanResource.$touch();
        //     if (!this.$v.humanResource.$invalid) {
        //         if (this.elements.editMode || this.elements.primaryInfoEditMode) {
        //             this.updateHumanResource();
        //         } else {
        //             this.saveHumanResource();
        //         }
        //     }
        // },
        uploadPhoto: function() {
            if (!/\.(jpg|svg|jpeg|png|bmp|gif|jfif)$/i.test(this.$refs.profile_photo.files[0].name)) {
                iziToast.error({
                    title: 'Photo update',
                    message: 'File format not supported',
                    position: 'topRight'
                });
                return false;
            }

            let formData = new FormData();
            formData.append('file', this.$refs.profile_photo.files[0]);
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('id', this.contactDetail.id);

            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/profileimage', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    var result = response.data;
                    iziToast.success({
                        title: 'Success',
                        message: 'Profile photo uploaded Successfully',
                        position: 'topRight'
                    });
                    this.getContacts();           
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Unable to update photo',
                        position: 'topRight'
                    });
                });
        },
        saveHumanResource: function() {
            console.log(this.humanResource);
            this.$v.humanResource.$touch();
            if (!this.$v.humanResource.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/add', this.humanResource)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                                
                            });
                            this.getHumanResource();
                        }
                    })
                }
        },
        updateHumanResource: function() {
            console.log(this.humanResourceDetails);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/update', this.humanResourceDetails)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    this.elements.primaryInfoEditMode = false;
                    this.elements.userDefaultProjectEditMode = false;
                    this.elements.userInfoEditMode = false;
                    this.elements.contactInfoEditMode = false;
                    this.clearCompany();
                    this.elements.addressInfoEditMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getHumanResource();
                    }
                })
        },
        deleteHumanResource: function(id) {
            this.deleteParam.id = id;
        },

        onYes: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getHumanResource();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.primaryInfoEditMode = false;
            this.elements.userDefaultProjectEditMode = false;
            this.elements.userInfoEditMode = false;
            this.elements.contactInfoEditMode = false;
            this.clearCompany();
            this.elements.addressInfoEditMode = false;
            this.viewHumanResource(this.humanResourceDetails.id);
           
        },
        showView: function(items) {
            let data = items;
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.elements.primaryInfoEditMode = false;
                this.elements.userDefaultProjectEditMode = false;
                this.elements.userInfoEditMode = false;
                this.elements.contactInfoEditMode = false;
                this.clearCompany();           
                this.elements.addressInfoEditMode = false;
                this.humanResourceDetails.id = data.id;
                this.humanResourceDetails.humanResourceId = data.humanResourceId;
                this.humanResourceDetails.position = data.position;
                this.humanResourceDetails.userId = data.userId;
                this.humanResourceDetails.loginName = data.loginName;
                this.humanResourceDetails.userType = data.userType;
                this.humanResourceDetails.qualification = data.qualification;
                this.humanResourceDetails.experienceYears = data.experienceYears;
                this.humanResourceDetails.experienceMonths = data.experienceMonths;
                this.humanResourceDetails.name = data.name;
                this.humanResourceDetails.active = data.active;
                this.humanResourceDetails.technologySkill = data.technologySkill;
                this.humanResourceDetails.recruitedBy = data.recruitedBy;
                this.humanResourceDetails.joiningDate = data.joiningDate;
                this.humanResourceDetails.createdBy = data.createdBy;
                this.humanResourceDetails.createdDt = data.createdDt;
                this.humanResourceDetails.lastUpdatedDt = data.lastUpdatedDt;

        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.humanResourceDetails.id = data.id;
            this.humanResourceDetails.humanResourceId = data.humanResourceId;
            this.humanResourceDetails.name = data.name;
            this.humanResourceDetails.position = data.position;
            this.humanResourceDetails.userId = data.userId;
            this.humanResourceDetails.loginName = data.loginName;
            this.humanResourceDetails.userType = data.userType;
            this.humanResourceDetails.qualification = data.qualification;
            this.humanResourceDetails.experienceYears = data.experienceYears;
            this.humanResourceDetails.experienceMonths = data.experienceMonths;
            this.humanResourceDetails.active = data.active;
            this.humanResourceDetails.technologySkill = data.technologySkill;
            this.humanResourceDetails.recruitedBy = data.recruitedBy;
            this.humanResourceDetails.joiningDate = data.joiningDate;
            this.humanResourceDetails.createdBy = data.createdBy;
            this.humanResourceDetails.createdDt = data.createdDt;
            this.humanResourceDetails.lastUpdatedDt = data.lastUpdatedDt;

        },
        onRowSelected: function(items) {
            if (items != null && items.length != 0){
                let data = items[0];
                console.log(data);
                this.elements.showDetail = true;
                this.elements.primaryInfoEditMode = false;
                this.elements.userDefaultProjectEditMode = false;
                this.elements.userInfoEditMode = false;
                this.elements.contactInfoEditMode = false;
                this.elements.addressInfoEditMode = false;
                this.clearCompany();
                this.humanResourceDetails.id = data.id;
                this.humanResourceDetails.humanResourceId = data.humanResourceId;
                this.humanResourceDetails.name = data.name;
                this.humanResourceDetails.position = data.position;
                this.humanResourceDetails.userId = data.userId;
                this.humanResourceDetails.loginName = data.loginName;
                this.humanResourceDetails.userType = data.userType;
                this.humanResourceDetails.qualification = data.qualification;
                this.humanResourceDetails.experienceYears = data.experienceYears;
                this.humanResourceDetails.experienceMonths = data.experienceMonths;
                this.humanResourceDetails.recruitedBy = data.recruitedBy;
                this.humanResourceDetails.joiningDate = data.joiningDate;
                this.humanResourceDetails.active = data.active;
                this.humanResourceDetails.createdBy = data.createdBy;
                this.humanResourceDetails.createdDt = data.createdDt;
                this.humanResourceDetails.lastUpdatedDt = data.lastUpdatedDt;
                this.contactDetail.refRecordId = data.id;

                this.getContacts();
                this.getTechnologySkills();
                this.getRoleResponsibilities();
            }
        },


        cancelEditMode: function() {
            this.viewHumanResource(this.humanResourceDetails.id);
        },
        saveContact: function() {
            console.log(this.contactDetail.refRecordId,this.humanResourceDetails.id);
            if (this.contactDetail.refRecordId == this.humanResourceDetails.id) {
                this.updateContact();
            } else {
                this.addContact();
            }
        },
        addContact: function() {
            this.contactDetail.projectId = this.projectId;
            this.contactDetail.refRecordId = this.humanResourceDetails.id;
            this.contactDetail.refEntity = this.entity;
            console.log(this.contactDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contactDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        this.clearContact();
                    }
                })
        },
        updateContact: function() {
            console.log(this.contactDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contactDetail)
                .then((response) => {
                    let result = response.data;
                    this.elements.contactEditMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContacts();
                        // this.clearContact();
                    }
                })
        },
        clearContact: function() {
            this.elements.contactEditMode = false;
            this.contactDetail.id = '';
            this.contactDetail.refRecordId = '';
            this.contactDetail.phone = '';
            this.contactDetail.phone = '';
            this.contactDetail.mobile = '';
            this.contactDetail.alternateMobile = '';
            this.contactDetail.address1 = '';
            this.contactDetail.address2 = '';
            this.contactDetail.streetName = '';
            this.contactDetail.pincode = '';
            this.contactDetail.emailId = '';
            this.contactDetail.alternateEmailId = '';
            this.contactDetail.city = '';
            this.contactDetail.state = '';
            this.contactDetail.countryId = '';
            this.contactDetail.country = '';
        },

        saveRoleResponsibility: function() {
            if (this.elements.showDropdownRole) {

                if (this.roleResponsibilityDetail.roleData.text != null) {
                    this.roleResponsibilityDetail.role = this.roleResponsibilityDetail.roleData.text;
                    this.validateRoleResponsibility();
                }
                else {
                    this.roleResponsibilityDetail.role = '';
                    this.validateRoleResponsibility();
                }
            }
            else if (!this.elements.showDropdownTech) {
                this.validateRoleResponsibility();
            }
            
        },
        validateRoleResponsibility: function() {
            this.$v.roleResponsibilityDetail.$touch();
            if (!this.$v.roleResponsibilityDetail.$invalid) {
                if (this.elements.roleEditMode) {
                    this.updateRoleResponsibility();
                } else {
                    this.addRoleResponsibility();
                }
            }
        },
        addRoleResponsibility: function() {
            this.roleResponsibilityDetail.refRecordId = this.humanResourceDetails.id;
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            console.log(this.roleResponsibilityDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', this.roleResponsibilityDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        updateRoleResponsibility: function() {
            this.roleResponsibilityDetail.responsibility = this.arrayToString(this.roleResponsibilityDetail.responsibilityArray);
            console.log(this.roleResponsibilityDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', this.roleResponsibilityDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                        this.clearRoleResponsibility();
                    }
                })
        },
        clearRoleResponsibility: function() {
            this.elements.roleEditMode = false;
            this.roleResponsibilityDetail.editId = '';
            this.roleResponsibilityDetail.role = '';

            if (this.roleResponsibilityDetail.roleData) {
                this.roleResponsibilityDetail.roleData = '';
            }
            this.roleResponsibilityDetail.responsibility ='';
            this.roleResponsibilityDetail.responsibilityArray = [];
            this.$v.roleResponsibilityDetail.$reset();
        },
        deleteRoleResponsibility: function(id) {
            this.deleteParam.id = id;
        },
        onYesRole: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRoleResponsibilities();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveTechnologySkill: function() {
            console.log(this.technologySkillDetail.technology)
            if (this.elements.showDropdownTech) {
                if (this.technologySkillDetail.technologyData.text != null) {
                    this.technologySkillDetail.technology = this.technologySkillDetail.technologyData.text;
                    this.validateTechnologySkill();
                }
                else {
                    this.technologySkillDetail.technology = '';
                    this.validateTechnologySkill();
                }
            }
            else if (!this.elements.showDropdownTech) {
                this.validateTechnologySkill();
            }
        },
        validateTechnologySkill: function() {
            this.$v.technologySkillDetail.$touch();
            if (!this.$v.technologySkillDetail.$invalid) {
                if (this.elements.techEditMode) {
                    this.updateTechnologySkill();
                } else {
                    this.addTechnologySkill();
                }
            }
        },
        addTechnologySkill: function() {
            this.technologySkillDetail.refRecordId = this.humanResourceDetails.id;
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            console.log(this.technologySkillDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', this.technologySkillDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        updateTechnologySkill: function() {
            this.technologySkillDetail.skills = this.arrayToString(this.technologySkillDetail.skillsArray);
            console.log(this.technologySkillDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', this.technologySkillDetail)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                        this.clearTechnologySkill();
                    }
                })
        },
        clearTechnologySkill: function() {
            this.elements.techEditMode = false;
            this.technologySkillDetail.editId= '';
            this.technologySkillDetail.technology= '';
            if (this.technologySkillDetail.technologyData.id) {
                this.technologySkillDetail.technologyData = '';
            }
            this.technologySkillDetail.skills= '';
            this.technologySkillDetail.skillsArray= [];
            this.$v.technologySkillDetail.$reset();
        },
        deleteTechnologySkill: function(id) {
            this.deleteParam.id = id;
        },
        onYesTech: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/technologyskill/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTechnologySkills();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        editRole: function(data) {
            this.elements.showDropdownRole = false;
            this.elements.roleEditMode = true;
            console.log(data);
            this.roleResponsibilityDetail.id = data.id;
            this.roleResponsibilityDetail.editId = data.id;
            this.roleResponsibilityDetail.role = data.role;
            this.roleResponsibilityDetail.responsibilityArray = this.stringToArray(data.responsibility);
            this.roleResponsibilityDetail.refRecordId = data.refRecordId;
            this.roleResponsibilityDetail.refEntityId = data.refEntityId;
            this.roleResponsibilityDetail.projectId = data.projectId;
            this.roleResponsibilityDetail.vhextAccountId = data.vhextAccountId;
            this.roleResponsibilityDetail.roleResponsibilityId = data.roleResponsibilityId;
            this.roleResponsibilityDetail.preparedById = data.preparedById;
        },
        editTech: function(data) {
            this.elements.showDropdownTech = false;
            this.elements.techEditMode = true;
            console.log(data);
            this.technologySkillDetail.id = data.id;
            this.technologySkillDetail.editId = data.id;
            this.technologySkillDetail.technologySkillId = data.technologySkillId;
            this.technologySkillDetail.technology = data.technology;
            console.log(data.skills.length);
            this.technologySkillDetail.skillsArray = this.stringToArray(data.skills);
            this.technologySkillDetail.refRecordId = data.refRecordId;
            this.technologySkillDetail.refEntityId = data.refEntityId;
            this.technologySkillDetail.projectId = data.projectId;
            this.technologySkillDetail.vhextAccountId = data.vhextAccountId;
            this.technologySkillDetail.preparedById = data.preparedById;
        },
        arrayToString: function (arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },
        stringToArray: function (arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.split(",");
            }
            return "";
        },
        getResponsibilities: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        let roleResponsibility = result.roleResponsibility;
                        this.roleResponsibilityDetail.responsibilityArray =  roleResponsibility.responsibility && roleResponsibility.responsibility !== null  ? this.stringToArray(roleResponsibility.responsibility) : [];
                    })
                }
        },
        getTechnologies: function(id) {
            if (id != null && id != undefined) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/technologyskill/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        let technologySkill = result.technologySkill;
                        this.technologySkillDetail.skillsArray = technologySkill.skills && technologySkill.skills !== null  ? this.stringToArray(technologySkill.skills) : [];
                    })
                }
        },
        viewCompany: function(id) {
            let data = {
                vhextAccountId : this.vhextAccountId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/company/view/vhextid',  data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.company = result.company;
                    this.preview = this.company.logo;
                    localStorage.setItem("logo",this.company.logo);
                })

        },
        updateCompany: function() {
            this.$v.company.$touch();
            if (!this.$v.company.$invalid) {
                 axios.post(this.$store.getters.getAPIBasePath + '/company/update', this.company)
                    .then((response) => {
                        this.clearCompany();
                        let result = response.data;
                       // this.uploadLogo();
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        } 
                        this.viewCompany();               

                    })
                    .catch(error => {
                        this.clearCompany();
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.company.logo = e.target.result;
                }
                this.image=input.files[0];
                reader.readAsDataURL(input.files[0]);
                this.uploadLogo();
            }
        },
        uploadLogo: function() {
            if(this.$refs.companyLogo.files[0] != undefined){
                let formData = new FormData();
                formData.append('file', this.$refs.companyLogo.files[0]);
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('id', this.company.id);

                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/logo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        var result = response.data;
              
                        iziToast.success({
                            title: 'Success',
                            message: 'Logo uploaded Successfully',
                            position: 'topRight'
                        });         
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error Occurred',
                            message: 'Unable to upload logo',
                            position: 'topRight'
                        });
                    });
            }
        },
        getFileName:function (url)
        {
            if (url)
            {
                var m = url.toString().match(/.*\/(.+?)\./);
                if (m && m.length > 1)
                {
                    return m[1];
                }
            }
            return "";
        },
        clearCompany: function() {
            this.elements.companyInfoEditMode = false;
            this.elements.companyContactInfoEditMode = false;
            this.elements.companyAddressInfoEditMode = false;
            this.elements.contactPersonEditMode = false;
        }
    }

}
</script>