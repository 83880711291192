<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-18">Country List</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Country" :to="{ name: 'addcountry'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Country" :to="{ name: 'viewcountry'}"><i class="fa fa-eye pr-2"></i>View</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Delete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.country" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.countryId" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.alpha2Code" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.alpha3Code" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                            </div>
                            <a href="Javascript:void(0);" @click="saveCountry" class="btn btn-primary mb-0 mt-3">Submit</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Select2
    },
    computed: {
        countryId() {
            return Number(this.$route.params.countryid);
        }
    },
    data() {
        return {
            project: {
                countryId: '',
                alpha2Code: '',
                alpha3Code: '',
                callingCode: '',
                NumericCode: '',
                currency: '',
                internetCode: '',
                timeZones: '',
                capital: '',
                dateFormat: '',
                languages: '',
                hasAttachment: '',
                active: '',
            }
        }
    },
    mounted() {
        this.viewCountry(this.countryId);
    },
    validations: {
       country : {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        viewCountry: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/country/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.country = result.countrys;
                    this.country.categoryId = (this.country.categoryId == undefined) ? '' : this.country.categoryId;
                    this.country.countryTypeId = (this.country.countryTypeId == undefined) ? '' : this.country.countryTypeId;
                    this.country.statusId = (this.project.statusId == undefined) ? '' : this.project.statusId;
                    this.country.timeZoneId = (this.project.timeZoneId == undefined) ? '' : this.project.timeZoneId;
                    this.country.projectManagerId = (this.project.projectManagerId == undefined) ? '' : this.project.projectManagerId;
                    this.country.countryId = (this.project.countryId == undefined) ? '' : this.project.countryId;
                    console.log(result);
                })

        },
        saveProject: function() {
            this.$v.country.$touch();
            if (!this.$v.country.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/country/update', this.country)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                        console.log(result);

                    })
            }

        }
    }
}
</script>