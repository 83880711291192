<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Risk</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title ptl-0" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                            <span v-if="elements.viewMode">View</span> 
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-0">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="duration">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.risk.title.$error }">

                                                            <input type="text" class="form-control" v-model="risk.title" id="title" :class="{ 'is-invalid': $v.risk.title.$error }" placeholder="Enter Title">
                                                            <div class="required-icon"></div>
                                                            </div>
                                                            <!--<label v-if="$v.risk.title.$error && !$v.risk.title.minLength" class="text-danger">Title must have at least {{$v.risk.title.$params.minLength.min}} letters.</label>-->
                                                            <label v-if="$v.risk.title.$error && !$v.risk.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.risk.title.$error && !$v.risk.title.maxLength" class="text-danger">Title must not exceed {{$v.risk.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="duration">Type</label>
                                                           <select v-model="risk.riskTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in riskTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="duration">Severity</label>
                                                            <select v-model="risk.riskSeverityId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in severityLookup" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="duration">Priority</label>
                                                            <input type="number" class="form-control" v-model="risk.priority" id="priority" placeholder="Enter priority">
                                                            <!--<select v-model="risk.priority" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in scopeTypes" :value="info.id">{{info.type}}</option>
                                                            </select>-->
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                       <textarea v-model="risk.description" class="form-control" rows="4" required></textarea>
                                                        <label v-if="$v.risk.description.$error && !$v.risk.description.required" class="text-danger">Please enter Description</label>
                                                        <label v-if="$v.risk.description.$error && !$v.scope.description.maxLength" class="text-danger">Description must not exceed {{$v.risk.description.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode" id="viewRisk">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <label for="title">Title</label>
                                                        <b-input-group :prepend="riskDetail.riskId" class="mb-2">
                                                            <b-form-input aria-label="First name" disabled v-model="riskDetail.title"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="projectType">Status</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{riskDetail.status}}</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="deliverables">Type</label>
                                                            <input type="text" class="form-control" disabled v-model="riskDetail.riskType">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="severity">Severity</label>
                                                            <input type="text" class="form-control" disabled v-model="riskDetail.severity">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="priority">Priority</label>
                                                            <input type="text" class="form-control" disabled v-model="riskDetail.priority">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="preparedBy">PreparedBy</label>
                                                            <input type="text" class="form-control" disabled v-model="riskDetail.preparedBy">
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-12">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <textarea rows="4" class="form-control" id="description" disabled v-model="riskDetail.description"></textarea>
                                                        </div>
                                                    </div>
                                                   
                                                    
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="riskDetail.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="endDate">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="riskDetail.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="riskList" @row-selected="onRowSelected" :fields="riskFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteRisk(data.item.id)" data-toggle="modal" data-target="#deleteRisk"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="risk">
                                    <div class="text-center">No Risk found</div>
                                </template>
                                <template v-slot:emptyfiltered="risk">
                                    <div class="text-center">{{ risk.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="riskList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail">
                                        <!--<div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="scopeStatementId">Statement Id</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{scopeDetail.scopeStatementId}}</span></p>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="status"> In Scope</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="scopeDetail.inScope" :disabled=notEnabled name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="status"> Status</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{scopeDetail.status}}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="location">Type</label>
                                                    <input type="text" class="form-control" id="location" disabled v-model='scopeDetail.type'>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="location">Scope Statement</label>
                                                    <div for="location">{{scopeDetail.scopeStatement}}</div>
                                                    <input v-if="false" type="text" class="form-control" id="location" disabled v-model='scopeDetail.scopeStatement'>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="createdDt">Created Date</label>
                                                    <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(scopeDetail.createdDt)">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="lastUpdatedDt">Updated Date</label>
                                                    <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(scopeDetail.lastUpdatedDt)">
                                                </div>
                                            </div>
                                        </div>-->
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="budget">Functions</label>
                                            <select id="durationDays" class="form-control">
                                                <option value="">Functions...</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="budget" style="visibility: hidden;">Search</label>
                                            <div class="input-group ">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-text input-group-append input-group-addon">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Reference Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Objective Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)" ><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Objective found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <Dialog id="deleteRisk" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete risk?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        TypeLookup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeEntity: 'risk_type',
            
            typeLookupTitle:'Risk Type Settings',
            risk: {
                riskId: '',
                title: '',
                riskTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'risk',
                type:'',
                priority: '',
                riskSeverityId: '',
                severity: '',
                description: ''


            },
            riskDetail: {
                riskId: '',
                title: '',
                riskType: '',
                severity: '',
                priority: '',
                description: '',
                status: '',
                preparedBy: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            riskList: [],
            riskTypes: [],
            severityLookup: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            riskFields: [
                { key: 'riskId', label: 'Risk Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'severity', label: 'Severity', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'risk_id', label: 'Risk Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'severity', label: 'Severity', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getRisk();
        this.lookupData();
    },
    validations: {
        risk: {
            title: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        getRisk: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/risk/list')
                .then((response) => {
                    let result = response.data;
                    this.riskList = result.risklist;
                    this.totalRows = this.riskList.length;
                    this.clearData();

                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: 1,
                vhextAccountId: 1,
                typeMasterId: 8,
                statusMasterId: 1,
                priorityMasterId: 3,
                severityMasterId: 3,
                categoryMasterId: 1
            }
            axios.post(this.$store.getters.getAPIBasePath + '/risk/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.riskTypes = result.customTypeLookup;
                    this.severityLookup = result.severityLookup;
                })

        },
        saveData: function() {
            this.$v.risk.$touch();
            if (!this.$v.risk.$invalid) {
                if (this.elements.editMode) {
                    this.updateRisk();
                } else {
                    this.saveRisk();
                }
            }
        },
        saveRisk: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/risk/add', this.risk)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRisk();
                    }
                })
        },
        updateRisk: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/risk/update', this.risk)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRisk();
                    }
                })
        },
        deleteRisk: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/risk/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRisk();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.risk.riskId = '';
            this.risk.title = '';
            this.risk.riskTypeId = '';
            this.risk.riskSeverityId = '';
            this.risk.description = '';
            this.risk.priority = '';
            this.risk.id = '';
            this.$v.risk.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.risk.riskId = data.riskId;
            this.risk.title = data.title;
            this.risk.riskTypeId = data.riskTypeId;
            this.risk.riskSeverityId = data.riskSeverityId;
            this.risk.priority = data.priority;
            this.risk.description = data.description;
            this.risk.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
              /*  this.scopeDetail.scopeTypeId = data.scopeTypeId;
                this.scopeDetail.scopeStatement = data.scopeStatement;
                this.scopeDetail.inScope = data.inScope;
                this.scopeDetail.scopeStatementId = data.scopeStatementId;
                this.scopeDetail.status = data.status;
                this.scopeDetail.type = data.type;
                this.scopeDetail.createdDt = data.createdDt;
                this.scopeDetail.lastUpdatedDt = data.lastUpdatedDt;
                this.scopeDetail.scopeStatement = data.scopeStatement;*/
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.riskDetail.riskId = data.riskId;
            this.riskDetail.title = data.title;
            this.riskDetail.riskType = data.type;
            this.riskDetail.severity = data.severity;
            this.riskDetail.priority = data.priority;
            this.riskDetail.description = data.description;
            this.riskDetail.status = data.status;
            this.riskDetail.preparedBy = data.preparedBy;
            this.riskDetail.createdDt = data.createdDt;
            this.riskDetail.lastUpdatedDt = data.lastUpdatedDt;
        }

    }
}
</script>