<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Review Plan</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="reviewPlan-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title ptl-0" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span> 
                                            <span v-if="elements.viewMode">View</span> 
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-0">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <input type="text" v-model="reviewPlan.title" class="form-control" id="title" placeholder="Title">
                                                        </div> 
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="reviewTypeId">Review Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.reviewPlan.reviewTypeId.$error }">
                                                                <select v-model="reviewPlan.reviewTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in customType" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                               <div class="required-icon"></div>
                                                           </div> 
                                                            <label v-if="$v.reviewPlan.reviewTypeId.$error && !$v.reviewPlan.reviewTypeId.required" class="text-danger">Please select review type</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="reviewEvent">Review Event</label>
                                                            <input type="text" v-model="reviewPlan.reviewEvent" class="form-control" id="reviewEvent" placeholder="Review Event">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="reviewWorkProduct">Review Work Product</label>
                                                            <input type="text" v-model="reviewPlan.reviewWorkProduct" class="form-control" id="reviewWorkProduct" placeholder="Review Work Product">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="reviewBy">Review By</label>
                                                            <input type="text" v-model="reviewPlan.reviewBy" class="form-control" id="reviewBy" placeholder="Review By">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="reviewDt">Review Date</label>
                                                            <div class="input-group date ">
                                                                 <input type="Date" name="reviewDt" v-model="reviewPlan.reviewDt" class="form-control">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="reviewDuration">Review Duration</label>
                                                            <input type="text" v-model="reviewPlan.reviewDuration" class="form-control" id="reviewDuration" placeholder="Review Duration">
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <select v-model="reviewPlan.statusId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in reviewPlanStatus" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.reviewPlan.description.$error }">
                                                            <textarea v-model="reviewPlan.description" class="form-control" rows="4" required></textarea>
                                                               <div class="required-icon"></div>
                                                           </div>                                                        
                                                        <label v-if="$v.reviewPlan.description.$error && !$v.reviewPlan.description.required" class="text-danger">Please enter Description</label>
                                                        <label v-if="$v.reviewPlan.description.$error && !$v.reviewPlan.description.maxLength" class="text-danger">Description must not exceed {{$v.reviewPlan.description.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode" id="viewReviewPlan">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <b-input-group :prepend="reviewPlanDetail.reviewPlanId" class="mb-2">
                                                            <b-form-input aria-label="First name" disabled v-model="reviewPlanDetail.title"></b-form-input>
                                                        </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <label for="reviewTypeId">Review Type</label>
                                                        <input type="text" class="form-control" id="title" disabled v-model="reviewPlanDetail.type">
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="reviewEvent">Review Event</label>
                                                            <input type="text" class="form-control" id="reviewEvent" disabled v-model="reviewPlanDetail.reviewEvent">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="reviewWorkProduct">Review Work Product</label>
                                                            <input type="text" class="form-control" id="reviewWorkProduct" disabled v-model="reviewPlanDetail.reviewWorkProduct">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="reviewBy">Review By</label>
                                                            <input type="text" class="form-control" id="reviewBy" disabled v-model="reviewPlanDetail.reviewBy">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="reviewDate">Review Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="reviewPlanDetail.reviewDate | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                     <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="reviewDuration">Review Duration</label>
                                                            <input type="text" class="form-control" id="reviewDuration" disabled v-model="reviewPlanDetail.reviewDuration">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{reviewPlanDetail.status}}</span></p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-12  col-sm-12">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <textarea rows="4" class="form-control" id="description" disabled v-model="reviewPlanDetail.description"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="createdDt">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="reviewPlanDetail.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="lastUpdatedDt">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="reviewPlanDetail.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="reviewPlanList" @row-selected="onRowSelected" :fields="reviewPlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(inScope)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteReviewPlan(data.item.id)" data-toggle="modal" data-target="#deleteReviewPlan"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Review Plan found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="reviewPlanList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div v-if="elements.showDetail">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="reviewPlanId">Review Plan Id</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{reviewPlanDetail.reviewPlanId}}</span></p>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="title">Title</label>
                                                    <input type="text" class="form-control" id="title" disabled v-model="reviewPlanDetail.title">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="reviewTypeId">Review Type</label>
                                                    <input type="text" class="form-control" id="reviewTypeId" disabled v-model='reviewPlanDetail.type'>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="reviewEvent">Review Event</label>
                                                    <input type="text" class="form-control" id="reviewEvent" disabled v-model="reviewPlanDetail.reviewEvent">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="reviewWorkProduct">Review Work Product</label>
                                                    <input type="text" class="form-control" id="reviewWorkProduct" disabled v-model="reviewPlanDetail.reviewWorkProduct">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="reviewBy">Review By</label>
                                                    <input type="text" class="form-control" id="reviewBy" disabled v-model="reviewPlanDetail.reviewBy">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="reviewDate">Review Date</label>
                                                    <div class="input-group date disabled-date" disabled>
                                                        <input type="text" class="form-control disabled" :value="reviewPlanDetail.reviewDate | formatDate" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="reviewDuration">Review Duration</label>
                                                    <input type="text" class="form-control" id="reviewDuration" disabled v-model="reviewPlanDetail.reviewDuration">
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="statusId">Status</label>
                                                    <p><span class="badge badge-pill badge-blue mb-1">{{reviewPlanDetail.status}}</span></p>
                                                </div>
                                            </div>
                                            
                                            <div class="col-12  col-sm-12">
                                                <div class="form-group">
                                                    <label for="description">Description</label>
                                                    <textarea rows="4" class="form-control" id="description" disabled v-model="reviewPlanDetail.description"></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="createdDt">Created Date</label>
                                                    <div class="input-group date disabled-date" disabled>
                                                        <input type="text" class="form-control disabled" :value="reviewPlanDetail.createdDt | formatDate" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div class="form-group">
                                                    <label for="lastUpdatedDt">Updated Date</label>
                                                    <div class="input-group date disabled-date">
                                                        <input type="text" class="form-control disabled" :value="reviewPlanDetail.lastUpdatedDt | formatDate" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="budget">Functions</label>
                                            <select id="durationDays" class="form-control">
                                                <option value="">Functions...</option>
                                                <option>1</option>
                                                <option>2</option>
                                            </select>
                                        </div>
                                        <div class="col-6">
                                            <label for="budget" style="visibility: hidden;">Search</label>
                                            <div class="input-group ">
                                                <input type="text" class="form-control" placeholder="Search">
                                                <span class="input-group-text input-group-append input-group-addon">
                                                    <i class="fa fa-search"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Reference Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <label for="budget" class="mb-1">Objective Details</label>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                    <template v-slot:cell(title)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                        <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)" ><i class="fa fa-trash pl-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Objective found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="reviewPlan.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <Dialog id="deleteReviewPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete reviewPlan?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        TypeLookup,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            typeLookupTitle:'ReviewPlan Type Settings',
            reviewPlan: {
                 vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                entity: 'review_plan',
                title: '',
                type: '',
                entityRecordMasterId: '',
                reviewEvent: '',
                reviewWorkProduct: '',
                reviewBy: '',
                plannedBy: '',
                reviewDt: '',
                reviewDuration: '',
                status: '',
                description: ''
            },
            reviewPlanDetail: {
                title: '',
                type: '',
                entityRecordMasterId: '',
                reviewEvent: '',
                reviewWorkProduct: '',
                reviewBy: '',
                plannedBy: '',
                reviewDate: '',
                reviewDuration: '',
                status: '',
                description: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            reviewPlanList: [],
            projects: [],
            users: [],
            customType: [],
            reviewPlanStatus: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            reviewPlanFields: [
                { key: 'reviewPlanId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'reviewWorkProduct', label: 'WorkProduct', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                status: '',
                plannedBy: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.reviewPlanFields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getReviewPlan();
        this.lookupData();
    },
    validations: {
        reviewPlan: {
            reviewTypeId: {
                required
            },
            description: {
                required,
                maxLength: maxLength(1000)
            }
        }
    },
    methods: {
        getReviewPlan: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/review/reviewplan/list')
                .then((response) => {
                    let result = response.data;
                    this.reviewPlanList = result.reviewPlanList;
                    this.totalRows = this.reviewPlanList.length;
                })
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                typeMasterId: 5,
                statusMasterId: 1
            }
            axios.post(this.$store.getters.getAPIBasePath + '/review/reviewplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    
                    this.users = result.userLookup;
                    this.customType = result.customTypeLookup;
                    this.reviewPlanStatus = result.statusLookup;
                    console.log(result.data)
                })

        },
        saveData: function() {
            this.$v.reviewPlan.$touch();
            if (!this.$v.reviewPlan.$invalid) {
                if (this.elements.editMode) {
                    this.updateReviewPlan();
                } else {
                    this.saveReviewPlan();
                }
            }
        },
        saveReviewPlan: function() {
            console.log(this.reviewPlan);
            axios.post(this.$store.getters.getAPIBasePath + '/review/reviewplan/add', this.reviewPlan)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReviewPlan();
                    }
                })
        },
        updateReviewPlan: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/review/reviewplan/update', this.reviewPlan)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReviewPlan();
                    }
                })
        },
        deleteReviewPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/review/reviewplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getReviewPlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.reviewPlan.title = '';
            this.reviewPlan.reviewTypeId = '';
            this.reviewPlan.entityRecordMasterId = '';
            this.reviewPlan.reviewEvent = '';
            this.reviewPlan.reviewWorkProduct = '';
            this.reviewPlan.reviewBy = '';
            this.reviewPlan.plannedBy = '';
            this.reviewPlan.reviewDt = '';
            this.reviewPlan.reviewDuration = '';
            this.reviewPlan.statusId = '';
            this.reviewPlan.description = '';
            this.reviewPlan.id = '';
            this.$v.reviewPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.reviewPlan.title = data.title;
            this.reviewPlan.type = data.type;
            this.reviewPlan.entityRecordMasterId = data.entityRecordMasterId;
            this.reviewPlan.reviewEvent = data.reviewEvent;
            this.reviewPlan.reviewWorkProduct = data.reviewWorkProduct;
            this.reviewPlan.reviewBy = data.reviewBy;
            this.reviewPlan.plannedBy = data.plannedBy;
            this.reviewPlan.reviewDt = data.reviewDt;
            this.reviewPlan.reviewDuration = data.reviewDuration;
            this.reviewPlan.status = data.status;
            this.reviewPlan.description = data.description;
            this.reviewPlan.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.reviewPlanDetail.title = data.title;
                this.reviewPlanDetail.type = data.type;
                this.reviewPlanDetail.reviewPlanId = data.reviewPlanId;
                this.reviewPlanDetail.status = data.status;
                this.reviewPlanDetail.type = data.type;
                this.reviewPlanDetail.reviewEvent = data.reviewEvent;
                this.reviewPlanDetail.reviewWorkProduct = data.reviewWorkProduct;
                this.reviewPlanDetail.reviewBy = data.reviewBy;
                this.reviewPlanDetail.plannedBy = data.plannedBy;
                this.reviewPlanDetail.reviewDate = data.reviewDate;
                this.reviewPlanDetail.reviewDuration = data.reviewDuration;
                this.reviewPlanDetail.status = data.status;
                this.reviewPlanDetail.description = data.description;
                this.reviewPlanDetail.createdDt = data.createdDt;
                this.reviewPlanDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.reviewPlanDetail.type = data.type.toString();
            this.reviewPlanDetail.reviewPlanId = data.reviewPlanId;
            this.reviewPlanDetail.title = data.title;
            this.reviewPlanDetail.reviewEvent = data.reviewEvent;
            this.reviewPlanDetail.reviewWorkProduct = data.reviewWorkProduct;
            this.reviewPlanDetail.reviewBy = data.reviewBy;
            this.reviewPlanDetail.plannedBy = data.plannedBy;
            this.reviewPlanDetail.reviewDate = data.reviewDate;
            this.reviewPlanDetail.reviewDuration = data.reviewDuration;
            this.reviewPlanDetail.status = data.status;
            this.reviewPlanDetail.description = data.description;
            this.reviewPlanDetail.createdDt = data.createdDt;
            this.reviewPlanDetail.lastUpdatedDt = data.lastUpdatedDt;
        }

    }
}
</script>