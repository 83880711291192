<template>
    <div class="reviewApprovalDetailPopup">
        <b-modal :id="id" ref="reviewApprovalDetailPopupRef" ok-only header-class="py-3" body-class="px-3" footer-class="py-3" size="lg" @hide="closeDialog" modal-class="">
            <template #modal-header="{ close }">
                <h5>{{ popupTitle }}<span class="text-muted pl-1">- {{ documentType }}</span></h5>
                <button type="button" @click="close()" aria-label="Close" class="close">×</button>
            </template>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                        <div class="float-right ml-1">
                                            <router-link v-if="userTypeCode == 'CLI'" class="btn btn-secondary btn-xs px-2 m-0" title="Go to page" :to="{ name: getClientReportUrl(ermEntity), params: { ermId: popupData.entityRecordMasterId }}"><i class="fa fa-angle-double-right fa-lg"></i></router-link>
                                            <router-link v-else class="btn btn-secondary btn-xs px-2 m-0" title="Go to page" :to="{ name: getReportUrlName(ermEntity), params: { ermId: popupData.entityRecordMasterId }}"><i class="fa fa-angle-double-right fa-lg"></i></router-link>
                                        </div>
                                        <div class="float-right">
                                            <h6 v-if="popupData.status" class="mb-0"><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-9 col-sm-9 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.reviewId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 col-sm-6">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-6 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Requested By</label>
                                                </div>
                                                <div class="col-6 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.requestedBy }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-6 col-md-4 pr-0">
                                                    <label v-if="popupData.isApproval" class="mb-0 p-1 pt-2 pb-2 d-block title-block">Approval By</label>
                                                    <label v-else class="mb-0 p-1 pt-2 pb-2 d-block title-block">Review By</label>
                                                </div>
                                                <div class="col-6 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.reviewedBy }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <div class="col-12 col-sm-6">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-6 col-md-4 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Received Date</label>
                                                </div>
                                                <div class="col-6 col-md-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.receivedDt | formatDateTimeAmPm }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-6 col-md-4 pr-0">
                                                    <label v-if="popupData.isApproval" class="mb-0 p-1 pt-2 pb-2 d-block title-block">Approval Date</label>
                                                    <label v-else class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reviewed Date</label>
                                                </div>
                                                <div class="col-6 col-md-8 pl-0">
                                                    <label v-if="popupData.reviewDt" class="p-2 mb-0 d-block header-details">{{ popupData.reviewDt | formatDateTimeAmPm }}</label>
                                                    <label v-else class="p-2 mb-0 d-block header-details">-</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import redirectMixin from "@/mixins/redirect";
export default {
    mixins: [redirectMixin],
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        id:{type:String, default:'reviewApprovalDetailPopup'},
    },
    data() {
        return {
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            loading: false,
            popupTitle: '',
            documentType: null,
            ermEntity: null,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity, ermEntity, docType) {
            this.loading = true;
            this.ermEntity = ermEntity;
            this.documentType = docType;
            this.popupTitle = refEntity == 'REV' ? 'Review' : 'Approval';
            console.log(id, refEntity);
            if (refEntity === "REV" || refEntity === "APV") {
                this.$bvModal.show(this.id);
                axios.get(this.$store.getters.getAPIBasePath + '/review/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.popupData = result.reviewView;
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.$bvModal.show(this.id);
                this.loading = false;
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>