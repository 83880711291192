<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Contact</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}} 1</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <Contact class="col-12" />
            </div>      
        </div>
        <Dialog id="deleteContact" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Contact" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Contact from '@/components/common/ContactComponent.vue';
export default {
    name: 'app',
    components: {
        Contact,
        Multiselect,
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            elements: {
                editMode: false,
                visible: false,
                // viewMode: false
            },
            contact: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                contactName: '',
                position: '',
                emailId: '',
                mobile: '',
                phone: '',
                officePhone: '',
                firstName: '',
                middleName: '',
                lastName: '',
                photo: '',
                country: '',
                buildingName: '',
                streetName: '',
                city: '',
                state: '',
                pincode: '',
                website: '',
                refEntity: 'contact',
                refEntityId: '',
                refRecordId: '',
                
            },
            notEnabled: true,
            users: [],
            contact: {
                addedBy: ''
            },
            contactDetail: {
                contactId: '',
                contactName: '',
                addedBy: '',
                firstName: '',
                middleName: '',
                lastName: '',
                company: '',
                phone: '',
                officePhone: '',
                mobile: '',
                alternateMobile: '',
                emailId: '',
                alternateEmailId: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                timeZone: '',
                website: '',
                photo: '',
                active: '',
                createdDt: '',
                lastUpdatedDt: '',
                refEntity: 'contact',
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            contacts: [],
            fields: [
                // A virtual column that doesn't exist in items
                {key:'index',label: 'SN'},
                { key: 'contactName', label: 'Contact Name', sortable: true, sortDirection: 'desc' },
                { key: 'position', label: 'Job Title', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email', sortable: true, sortDirection: 'desc' },
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'city', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.lookupData();
        this.getContact();
    },
    validations: {
        contact: {
        contactName: {
                required
            },
            
        }
    },
    methods: {
        getContact: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/list',data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.length != 0){
                        this.contacts = result.contactList;
                        this.totalRows = this.contacts.length;
                        console.log(this.contacts);
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                typeMasterId: 5,
                statusMasterId: 1
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    console.log(result);
                })
        },
        saveData: function() {
            console.log(this.contact);
            this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {
                if (this.elements.editMode) {
                    this.updateContact();
                } else {
                    this.saveContact();
                }
            }
        },
        saveContact: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contact)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                        this.clearData();
                    }
                })
        },
        updateContact: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contact)
                .then((response) => {
                    console.log(this.contact);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                        this.clearData();
                    }
                    console.log(result);
                })
        },
        deleteContact: function(id) {
            this.deleteParam.id = id;
            console.log(this.deleteParam.id);
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/contact/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getContact();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.contact.contactName = '';
            this.contact.position = '';
            this.contact.emailId = '';
            this.contact.mobile = '';
            this.contact.firstName = '';
            this.contact.middleName = '';
            this.contact.lastName = '';
            this.contact.photo = '';
            this.contact.country = '';
            this.contact.buildingName = '';
            this.contact.streetName = '';
            this.contact.city = '';
            this.contact.state = '';
            this.contact.pincode = '';
            this.contact.website = '';
            this.contact.refEntityId = '';
            this.contact.refRecordId = '';
            
            this.$v.contact.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.contact.contactName = data.contactName;
            this.contact.position = data.position;
            this.contact.emailId = data.emailId;
            this.contact.mobile = data.mobile;
            this.contact.firstName = data.firstName;
            this.contact.middleName = data.middleName;
            this.contact.lastName = data.lastName;
            this.contact.photo = data.photo;
            this.contact.country = data.country;
            this.contact.buildingName = data.buildingName;
            this.contact.streetName = data.streetName;
            this.contact.city = data.city;
            this.contact.state = data.state;
            this.contact.pincode = data.pincode;
            this.contact.website = data.website;

        },
        showView: function(data) {
            this.elements.visible = false;
            this.elements.viewMode = true;
            this.contactDetail.contactId = data.contactId.toString();
            this.contactDetail.addedBy = data.addedBy
            this.contactDetail.contactName = data.contactName
            this.contactDetail.address1 = data.address1;
            this.contactDetail.address2 = data.address2;
            this.contactDetail.firstName = data.firstName;
            this.contactDetail.middleName = data.middleName;
            this.contactDetail.lastName = data.lastName;
            this.contactDetail.position = data.position;
            this.contactDetail.company = data.company;
            this.contactDetail.phone = data.phone;
            this.contactDetail.officePhone = data.officePhone;
            this.contactDetail.mobile = data.mobile
            this.contactDetail.alternateMobile = data.alternateMobile;
            this.contactDetail.emailId = data.emailId;
            this.contactDetail.alternateEmailId = data.alternateEmailId;
            this.contactDetail.streetName = data.streetName;
            this.contactDetail.city = data.city;
            this.contactDetail.state = data.state;
            this.contactDetail.country = data.country;
            this.contactDetail.pincode = data.pincode;
            this.contactDetail.timeZone = data.timeZone;
            this.contactDetail.website = data.website
            this.contactDetail.photo = data.photo;
            this.contactDetail.active = data.active;
            this.contact.refEntityId = data.refEntityId;
            this.contact.refRecordId = data.refRecordId;

            this.contactDetail.createdDt = data.createdDt;
            this.contactDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items != null && items.length != 0){
                let data = items[0];
                console.log(data);
                this.elements.showDetail = true;

                this.contactDetail.contactId = data.contactId.toString();
                this.contactDetail.addedBy = data.addedBy
                this.contactDetail.contactName = data.contactName
                this.contactDetail.address1 = data.address1;
                this.contactDetail.address2 = data.address2;
                this.contactDetail.firstName = data.firstName;
                this.contactDetail.middleName = data.middleName;
                this.contactDetail.lastName = data.lastName;
                this.contactDetail.position = data.position;
                this.contactDetail.company = data.company;
                this.contactDetail.phone = data.phone;
                this.contactDetail.officePhone = data.officePhone;
                this.contactDetail.mobile = data.mobile
                this.contactDetail.alternateMobile = data.alternateMobile;
                this.contactDetail.emailId = data.emailId;
                this.contactDetail.alternateEmailId = data.alternateEmailId;
                this.contactDetail.streetName = data.streetName;
                this.contactDetail.city = data.city;
                this.contactDetail.state = data.state;
                this.contactDetail.country = data.country;
                this.contactDetail.pincode = data.pincode;
                this.contactDetail.timeZone = data.timeZone;
                this.contactDetail.website = data.website
                this.contactDetail.photo = data.photo;
                this.contactDetail.active = data.active;
                this.contact.refEntityId = data.refEntityId;
                this.contact.refRecordId = data.refRecordId;
                this.contactDetail.createdDt = data.createdDt;
                this.contactDetail.lastUpdatedDt = data.lastUpdatedDt;

            }
        },

    }
}
</script>