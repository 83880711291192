<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Scope Statement</span></h4>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div v-if="ermIsFreeze!='true'" id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-main>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Statement</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-main" accordion="my-accordion" v-model="elements.visible"  role="tabpanel">
                                            <b-card-body class="p-2">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div v-if="!elements.viewMode">
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.scopeStatement.title.$error }">
                                                                    <input type="text" class="form-control" v-model="scopeStatement.title" id="title" :class="{ 'is-invalid': $v.scopeStatement.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.scopeStatement.title.$error && !$v.scopeStatement.title.minLength" class="text-danger">Title must have at least {{$v.scopeStatement.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.scopeStatement.title.$error && !$v.scopeStatement.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.scopeStatement.title.$error && !$v.scopeStatement.title.maxLength" class="text-danger">Title must not exceed {{$v.scopeStatement.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                            <b-input-group v-if="elements.viewMode" :prepend="scopeStatement.scopeStatementId" class="mb-2">
                                                                <b-form-input aria-label="First name" disabled v-model="scopeStatement.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="duration">Type</label>
                                                            <select v-model="scopeStatement.scopeTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in scopeStatementTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group p-1">
                                                            <label for="inScope">In Scope</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="scopeStatement.inScope" name="check-button">
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Scope Statement</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.scopeStatement.scopeStatement.$error }">

                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="scopeStatement.scopeStatement" required></editor>                                                                
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.scopeStatement.scopeStatement.$error && !$v.scopeStatement.scopeStatement.required" class="text-danger">Please enter Scope Statement</label>
                                                            <label v-if="$v.scopeStatement.scopeStatement.$error && !$v.scopeStatement.scopeStatement.maxLength" class="text-danger">Scope Statement must not exceed {{$v.m.scopeStatement.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <button type="submit" @click="saveStatement" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay> 
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small ref="scopeTable" v-model="mainTableArray" class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="scopeStatements" @row-selected="onRowSelected" :fields="statementFields" responsive="sm" :current-page="scopeStatement.currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredSS" foot-clone foot-variant=light no-footer-sorting>
                                <template v-slot:cell(scopeStatementId)="data">
                                    <div v-if="data.item.id == idEdit">
                                        <input type="number" class="form-control" v-model="data.item.scopeStatementId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.scopeStatementId)" @blur="cancelIdEdit()">
                                    </div>
                                    <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.scopeStatementId"> {{ data.item.scopeStatementId }}</div>
                                </template>                                
                                <template v-slot:cell(inScope)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                    </b-form-checkbox>
                                </template>
                                <template v-slot:cell(scopeStatement)="data">
                                    <span class="tooltiptext" href="#" v-html="data.value"></span>
                                </template>
                                                                
<!--                                 <template v-slot:cell(scopeStatement)="data">
                                        <a class="tooltiptext" href="#" data-toggle="tooltip">{{data.value}}</a>
                                </template> -->
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'Draft'" variant="light">{{data.value}}</b-badge>
                                      <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                    </div>
                                </template>                                

                                <template v-slot:cell(actions)="data">
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Edit Details" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Delete" @click="deleteScopeStatement(data.item.id)" data-toggle="modal" v-b-modal.deleteScopeStatement><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Scope Statement found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                                <template v-slot:foot()="data">
                                    <span></span>
                                </template>                                
                            </b-table>
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                            
                            <b-pagination v-if="scopeStatements.length!=0" pills v-model="scopeStatement.currentPage" :total-rows="totalRows" first-number last-number :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible" content-class="mt-3" lazy>
                                    <b-tab title="Details" :active="elements.showDetail">
                                        <div class="row input-group-label">
                                            <div class="col-4">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{scopeDetail.scopeStatementId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <b-input-group prepend="Status" class="mb-2">
                                                    <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="scopeDetail.status"></b-form-input>
                                                    <select v-if="elements.editMode" v-model="scopeDetail.statusId" id="statusId" class="form-control">
                                                        <option v-for="(info, index) in scopeStatementStatus" :value="info.id">{{info.value}}</option>
                                                    </select>                                                    

                                                </b-input-group>

                                            </div>
                                            <div v-if="ermIsFreeze!='true'"  class="col-md-2 col-sm-2 pl-0">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateStatement" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="title">Title</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.scopeDetail.title.$error }">
                                                        <input type="text" maxlength="100" class="form-control" ref="setFocus" id="title" :disabled="!elements.editMode" v-model="scopeDetail.title" :class="{ 'is-invalid': $v.scopeDetail.title.$error }" placeholder="Enter Title">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.scopeDetail.title.$error && !$v.scopeDetail.title.minLength" class="text-danger">Title must have at least {{$v.scopeDetail.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.scopeDetail.title.$error && !$v.scopeDetail.title.required" class="text-danger">Please enter Title</label>
                                                    <label v-if="$v.scopeDetail.title.$error && !$v.scopeDetail.title.maxLength" class="text-danger">Title must not exceed {{$v.scopeDetail.title.$params.maxLength.max}} letters.</label>                                                    
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="duration">Type</label>
                                                    <select v-if="elements.editMode" v-model="scopeDetail.scopeTypeId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in scopeStatementTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input v-if="!elements.editMode" disabled v-model="scopeDetail.type" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-4 col-sm-6">
                                                <div class="form-group">
                                                    <label for="inscope">In Scope</label>
                                                    <b-form-checkbox v-if="!elements.editMode" class="custom-toggle" v-model="scopeDetail.inScope" :disabled=notEnabled name="check-button" switch></b-form-checkbox>
                                                    <b-form-checkbox v-if="elements.editMode" class="custom-toggle" v-model="scopeDetail.inScope" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>

                                            <div class="col-12  col-sm-12">
                                                <div class="form-group">
                                                    <label for="scopeStatement">Scope Statement</label>
                                                    <div v-if="!elements.editMode" class="p-2 bg-light text-dark  scrollable-div">
                                                        <span v-html="scopeDetail.scopeStatement"></span>
                                                    </div>

                                                    <div v-if="elements.editMode" class="required-field-block" :class="{ 'is-invalid': $v.scopeDetail.scopeStatement.$error }">
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="scopeDetail.scopeStatement" required></editor>                                                                  
                                                    </div>
                                                    <label v-if="$v.scopeDetail.scopeStatement.$error && !$v.scopeDetail.scopeStatement.required" class="text-danger">Please enter Scope Statement</label>
                                                    <label v-if="$v.scopeDetail.scopeStatement.$error && !$v.scopeDetail.scopeStatement.maxLength" class="text-danger">Scope Statement must not exceed {{$v.m.scopeStatement.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.rr>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="rr" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="preparedBy">Prepared By</label>
                                                                            <input type="text" class="form-control disabled" id="preparedBy" v-model='scopeDetail.userName' readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="scopeDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="scopeDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Add Reference" @click="elements.showDetail=false">
                                        <b-card no-body class="">
                                            <div class="col-12 col-sm-12">
                                            </div>  
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-ref>
                                                    
                                                    <span>Reference Search</span>

                                                </label>
                                            </b-card-header>

                                            <b-collapse id="accordion-ref" accordion="my-accordion" role="tabpanel">
                                                <div class="row">
                                                    <div class="col-6">
                                                        <div class="input-group dataTables_filter">
                                                            <input type="search" v-model="refFilter" class="form-control" placeholder="Search Reference">
                                                        </div>
                                                    </div>
                                                    <div class="float-left ml-5 pl-4">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="mb-0">
                                                                <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="projectERMList" :fields="projectERMListFields" responsive="sm" :current-page="currentPage" :per-page="perPageRef" :filter="refFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                        <template v-slot:cell(actions)="data">
                                                            <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                        </template>

                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reference found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                    <b-pagination v-if="projectERMList.length!=0" pills v-model="currentPage" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                                </div>
                                            </b-collapse>
                                        </b-card>
                                        <!-- </div> -->
                                        <div class="mt-2">
                                            <label class="mb-1">References</label>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="ermReferenceList" :fields="ermReferenceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(actions)="data">
                                                    <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" data-toggle="modal" data-target="#removeReference"><i class="fa fa-minus-circle pr-2"></i></a>
                                                </template>
                                                <template v-slot:empty="">
                                                    <div class="text-center">No References Found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="">
                                                    <div class="text-center">{{ }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-tab>
                                    <!-- <b-tab title="Master" @click="elements.showDetail=false">
                                        <div class="row input-group-label">
                                            <div class="col-4">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{entityRecordMaster.entityRecordMasterId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <b-input-group prepend="Status" class="mb-3">
                                                    <b-dropdown :disabled="elements.viewMasterMode" :text="masterStatusText" class="btn-dd" v-model="entityRecordMaster.statusId">
                                                        <b-dropdown-item v-for="(info, index)  in masterStatus" :key="info.id" :value="info.id" @click="entityRecordMaster.statusId = info.id;masterStatusText = info.value">
                                                            {{info.value}}
                                                        </b-dropdown-item>
                                                    </b-dropdown>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0">
                                                <div class="more-btns text-center">
                                                    <button v-if="elements.viewMasterMode" href="javascript:" @click="elements.viewMasterMode = false" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="!elements.viewMasterMode">
                                                        <button type="submit" @click="updateRecordMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                                        <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs action-link p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-12">
                                                <label for="title1">Title</label>
                                                <input type="text" id="title1" class="form-control" placeholder="Enter Title" :disabled="elements.viewMasterMode" v-model="entityRecordMaster.title">
                                            </div>
                                            <div class="col-12">
                                                <div class="card-body p-0 my-3">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-master>
                                                                <span>Description</span>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accordion-master" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <textarea :disabled="elements.viewMasterMode" v-model="entityRecordMaster.description" class="form-control" id="descriptionDetail" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="createdById">Prepared By</label>
                                                    <select :disabled="elements.viewMasterMode" v-model="entityRecordMaster.createdById" id="createdById" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <b-table show-empty small stacked="md" sort-icon-left :items="entityReviewApprovalList" :fields="reviewFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reviews found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="scopeTypeId">Approved By</label>
                                                    <select v-if="!elements.viewMasterMode" v-model="entityRecordMaster.createdById" id="scopeTypeId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <input v-if="elements.viewMasterMode" disabled v-model="entityRecordMaster.createdBy" type="text" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="startDate">Approved Date</label>
                                                    <input :disabled="elements.viewMasterMode" v-model="entityRecordMaster.startDate" type="date" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Created Date</label>
                                                    <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.createdDt)">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Updated Date</label>
                                                    <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.lastUpdatedDt)">
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab> -->
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <DeleteComponent id="deleteScopeStatement" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete scope statement?" /> 
        <Dialog id="removeReference" :onYes="onYesRef" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Reference?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Editor from '@tinymce/tinymce-vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';

export default {
    name: 'app',
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },

        ermId() {
            return Number(this.$route.params.ermId);
        }
    },
    components: {
        Editor,
        TypeLookup,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                showDetail: false,
                viewMasterMode: true,
                typeEdit: false,
                typeVisible: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entity: 'scope_statement',

            // ermId: this.$route.params.ermId,  
            ermTitle: localStorage.getItem("ermTitle"),

            ermIsFreeze: localStorage.getItem("ermIsFreeze"),

            typeEntity: 'scope_statement_type',
            
            typeLookupTitle: 'Scope Statement Type Settings',

            idValue: '',
            idEdit: '',

            statusKey: 0,

            mainTableArray: [],

            scopeStatement: {
                scopeStatementId: '',
                title: '',
                scopeTypeId: '',
                scopeStatement: '',
                inScope: true,
                status: '',
                statusId: '',
                entity: 'scope_statement',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
            },
            scopeDetail: {
                active: '',
                createdDt: '',
                deliverables: '',
                entityRecordMasterId: '',
                hasAttachment: '',
                hasReference: '',
                id: '',
                inScope: '',
                lastUpdatedDt: '',
                preparedById: '',
                preparedBy: '',
                projectId: this.projectId,
                vhextAccountId: this.vhextAccountId,
                scopeStatement: '',
                scopeStatementId: '',
                title: '',                
                scopeTypeId: '',
                status: '',
                statusId: '',
                type: '',
                userName: ''
            },
            addReference: {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityId: '',
                entityRecordId: '',
                ermId: '',
                erdId: '',
                refEntityId: '',
                refErmId: '',
                refErdId: '',
                title: '',
                refEntity: ''
            },
            entityId: '',
            // entity: "scope_statement_master",
            statusText: '',
            masterStatusText: '',
            notEnabled: true,
            entityRecordMaster: [],
            projectERMList: [],
            ermReferenceList: [],
            entityReviewApprovalList: [],
            scopeStatementTypes: [],
            scopeStatementStatus: [],
            masterStatus: [],
            users: [],
            scopeStatements: [],
            // scopeDetail: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            statementFields: [
                { key: 'scopeStatementId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title',tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'scopeStatement', label: 'Statement',tdClass: 'table-cw-400', sortable: true, sortDirection: 'desc' ,_showDetails: true},
                { key: 'inScope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            projectERMListFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            reviewFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            totalRowsRef: 1,
            perPageRef: 5,
            refFilter: null,

            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 250,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },            
        }
    },
    mounted() {
        this.vhextAccountId = localStorage.getItem("vhextAccountId");
        this.projectId = localStorage.getItem("projectId");
        this.getScopeStatement(this.$route.params.ermId);
        this.lookupData();
        this.entityRecordMasterCollection(this.$route.params.ermId);
        this.masterLookupData();
        this.getProjectEntityRecordMasterList();
    },
    validations: {
        scopeStatement: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            scopeStatement: {
                required,
                maxLength: maxLength(1000)
            }
        },
        scopeDetail: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            scopeStatement: {
                required,
                maxLength: maxLength(1000)
            }
        },

    },
    methods: {
        getScopeStatement: function() {
            let data = {
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result != null && result.length != 0) {
                        this.scopeStatements = result.scopeStatementList;
                        if (this.scopeStatements != null && this.scopeStatements.length > 0) {
                            this.elements.showDetail = true;
                            // this.viewScopeStatement(this.scopeStatements[0].id);
                            this.$nextTick(() => {
                                this.selectBTableRowById(this.scopeDetail.id, this.mainTableArray, 'scopeTable');
                            })
                        } else {
                            this.elements.showDetail = false;
                        }
                        this.elements.editMode = false;
                        this.totalRows = this.scopeStatements.length;
                        this.statusText = this.scopeStatements.status;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    
                    this.scopeStatementTypes = result.customTypeLookup;
                    this.scopeStatementStatus = result.statusLookup;

                    this.scopeStatement.statusId = this.scopeStatementStatus[0].id;
                })

        },

        entityRecordMasterCollection: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster = result.entityRecordMaster;
                    this.entityReviewApprovalList = result.entityReviewApprovalList;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                    this.masterStatusText = this.entityRecordMaster.status;
                })
        },
        getProjectEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/projectentityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectERMList = result.projectEntityRecordMasterList;
                    this.totalRowsRef = this.projectERMList.length;
                })
        },
        getERMReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmasterreference/list', data)
                .then((response) => {
                    let result = response.data;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        masterLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.masterStatus = result.statusLookup;
                    this.users = result.userLookup;
                })

        },
        updateRecordMaster: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.elements.viewMasterMode = true;
                        this.entityRecordMasterCollection();
                    }
                })
        },
        cancelEdit: function() {
            this.entityRecordMasterCollection();
            this.elements.viewMasterMode = true;
        },

        updateId: function(id,newErdId) {
            console.log(id,newErdId);
            if (!this.matchTextValExists(id,newErdId)) {
                let data = {
                    idInitType: "ERD",
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    entity: this.entity,
                    id: id,  
                    newErdId: newErdId,            
                }
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/common/update/id', data)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.idEdit='';
                            // this.getRequirementSpecs();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
            else {
                iziToast.info({
                    message: 'Id already exist.',
                    position: 'topCenter'
                });
                this.idEdit='';
                this.getRequirementSpecs();                
            }
            
        },
        cancelIdEdit: function() {
            this.scopeStatements = this.scopeStatements.map(data => {
                if(data.id == this.idEdit) {
                    data.scopeStatementId = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';

        },                

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPage = 1
        },
        onFilteredSS(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveStatement: function() {
            this.$v.scopeStatement.$touch();
            if (!this.$v.scopeStatement.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/add', this.scopeStatement)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getScopeStatement();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        updateStatement: function() {
            this.$v.scopeDetail.$touch();
            if (!this.$v.scopeDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/update', this.scopeDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getScopeStatement();
                            this.viewScopeStatement(this.scopeDetail.id);
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        deleteScopeStatement: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/scope/scopestatement/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getScopeStatement();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.visible = false;
            this.scopeStatement.title = '';
            this.scopeStatement.scopeTypeId = '';
            this.scopeStatement.scopeStatement = '';
            this.scopeStatement.statusId = this.scopeStatementStatus[0].id;
            this.scopeStatement.id = '';
            this.$v.scopeStatement.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.visible = false;
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.scopeDetail.active = data.active;
                this.scopeDetail.deliverables = data.deliverables;
                this.scopeDetail.entityRecordMasterId = data.entityRecordMasterId;
                this.scopeDetail.hasAttachment = data.hasAttachment;
                this.scopeDetail.hasReference = data.hasReference;
                this.scopeDetail.id = data.id;
                this.scopeDetail.inScope = data.inScope;
                this.scopeDetail.preparedById = data.preparedById;
                this.scopeDetail.preparedBy = data.preparedBy;
                this.scopeDetail.projectId = data.projectId;
                this.scopeDetail.scopeStatementId = data.scopeStatementId;
                this.scopeDetail.title = data.title;
                this.scopeDetail.scopeTypeId = data.scopeTypeId;
                this.scopeDetail.scopeStatement = data.scopeStatement;
                this.scopeDetail.status = data.status;
                this.scopeDetail.statusId = data.statusId;
                this.scopeDetail.type = data.type;
                this.scopeDetail.userName = data.userName;
                this.scopeDetail.vhextAccountId = data.vhextAccountId;

                this.statusKey += 1;
            }
            console.log(this.scopeDetail.statusId,this.scopeDetail.status,this.statusKey);
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = false;
            this.elements.showDetail = true;
            this.elements.editMode = true;
            this.scopeDetail.active = data.active;
            this.scopeDetail.deliverables = data.deliverables;
            this.scopeDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.scopeDetail.hasAttachment = data.hasAttachment;
            this.scopeDetail.hasReference = data.hasReference;
            this.scopeDetail.id = data.id;
            this.scopeDetail.inScope = data.inScope;
            this.scopeDetail.preparedById = data.preparedById;
            this.scopeDetail.projectId = data.projectId;
            this.scopeDetail.scopeStatementId = data.scopeStatementId;
            this.scopeDetail.title = data.title;
            this.scopeDetail.scopeStatement = data.scopeStatement;
            this.scopeDetail.scopeTypeId = data.scopeTypeId;
            this.scopeDetail.status = data.status;
            this.scopeDetail.statusId = data.statusId;
            this.scopeDetail.type = data.type;
            this.scopeDetail.userName = data.userName;
            this.scopeDetail.vhextAccountId = data.vhextAccountId;

            console.log(this.scopeDetail.statusId,this.scopeDetail.status);
        },
        showView: function(items) {
            let data = items;
            this.elements.showDetail = true;
            this.elements.editMode = false;
            this.scopeDetail.active = data.active;
            this.scopeDetail.deliverables = data.deliverables;
            this.scopeDetail.entityRecordMasterId = data.entityRecordMasterId;
            this.scopeDetail.hasAttachment = data.hasAttachment;
            this.scopeDetail.hasReference = data.hasReference;
            this.scopeDetail.id = data.id;
            this.scopeDetail.inScope = data.inScope;
            this.scopeDetail.preparedById = data.preparedById;
            this.scopeDetail.preparedBy = data.preparedBy;
            this.scopeDetail.projectId = data.projectId;
            this.scopeDetail.scopeStatementId = data.scopeStatementId;
            this.scopeDetail.title = data.title;
            this.scopeDetail.scopeStatement = data.scopeStatement;
            this.scopeDetail.scopeTypeId = data.scopeTypeId;
            this.scopeDetail.status = data.status;
            this.scopeDetail.statusId = data.statusId;
            this.scopeDetail.type = data.type;
            this.scopeDetail.userName = data.userName;
            this.scopeDetail.vhextAccountId = data.vhextAccountId;
        },
        viewScopeStatement: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/scope/scopestatement/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.scopeDetail = result.scopeStatement;
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.$v.scopeDetail.$reset();
            this.viewScopeStatement(this.scopeDetail.id);
        },
        addRef: function(data) {
            this.addReference.vhextAccountId = this.vhextAccountId;
            this.addReference.projectId = this.projectId;
            this.addReference.entityId = this.entityRecordMaster.entityId;
            this.addReference.entityRecordId = data.entityRecordMasterId;
            this.addReference.ermId = this.entityRecordMaster.id;
            this.addReference.erdId = '';
            this.addReference.refEntityId = data.entityId;
            this.addReference.refErmId = data.id;
            this.addReference.refErdId = data.refErdId;
            this.addReference.title = data.title;
            console.log(this.addReference);
            this.saveRef();
        },
        saveRef: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Reference Added',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        matchTextValExists: function(id,textVal) {
            let result = false;
            this.scopeStatements.map(data => {
                if (textVal && data.scopeStatementId && data.id !== id) {
                    if (data.scopeStatementId == textVal) {
                        result = true;
                    }
                }
            });
            return result;
        },        
    }
}
</script>