<template>
	<div>
        <b-overlay  :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
            <b-card no-body class="mb-3">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 accordion-title my-1" block href="#" v-b-toggle.accordion-1>
                        <span v-if="elements.editTimeSheet"><i class="fa fa-pencil-square-o pr-2 action-link"></i>Edit TimeSheet</span>
                        <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>Add TimeSheet</span>
                    </label>
                </b-card-header>
                <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                    <b-card-body class="py-3">
                        <div class="row input-group-label">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="title">Title</label>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.timeSheet.title.$error }">
                                        <b-form-input id="title" v-model="timeSheet.title" placeholder="Enter Timesheet Title" required></b-form-input>
                                        <div class="required-icon"></div>                                
                                    </div>
                                    <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.required" class="text-danger">Please Enter Timesheet Title</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="startDate">Activity</label>
                                    <b-input-group>
                                        <select v-model="timeSheet.processActivity" class="form-control" id="activityId1">
                                            <option :value="{activityId: '', processId: ''}">Select</option>
                                            <option v-for="(info, index) in processActivities" :value="{activityId: info.activityId, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.activity}}</option>
                                        </select>   
                                        <b-input-group-append class="append-0">
                                            <a class="action-link" href="javascript:" title="Use Existing Activities" @click="showDialog=true" v-b-modal.userActivityModal><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                                        </b-input-group-append> 
                                    </b-input-group>                                    
                                </div>
                            </div>                            
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="startDate">Start Time</label>
                                    <div class="input-group">
                                        <b-col class="col-6 pl-0 pr-0">
                                            <b-form-input type="date" v-model="timeSheet.startDateValue" :value="timeSheet.startDateValue" id="estimatedStartDt1" required></b-form-input>
                                        </b-col>
                                        <b-col class="col-6 pl-1 pr-0">
                                            <b-form-input type="time" v-model="timeSheet.startTimeValue" :value="timeSheet.startTimeValue" @input="setEffortFromTimeDiff" required></b-form-input>
                                        </b-col>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <label for="endDate">End Time</label>
                                    <div class="input-group">
                                        <b-col class="col-6 pl-0 pr-0">
                                            <b-form-input type="date" v-model="timeSheet.endDateValue" :value="timeSheet.endDateValue" id="estimatedStartDt1" required></b-form-input>
                                        </b-col>
                                        <b-col class="col-6 pl-1 pr-0">
                                            <b-form-input type="time" v-model="timeSheet.endTimeValue" :value="timeSheet.endTimeValue" @input="setEffortFromTimeDiff" required></b-form-input>
                                        </b-col>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <div class="form-group">
                                    <label for="endDate">Effort</label>
                                    <b-input-group size="sm" class="mb-2">
                                        <EffortInput ref="effortControl" @vmx-effort-input="checkValidEffort()" />
                                    </b-input-group>
                                    <label v-if="!isValidEffort" class="text-danger">Please enter at least one effort value: Work Minute, Work Hour, or Work Day.</label>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12">
                                <div class="form-group">
                                    <label>Description</label>
                                    <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="timeSheet.description"></editor> -->
                                    <VmxEditor v-model="timeSheet.description"></VmxEditor>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 text-right">
                                <button type="submit" @click="saveData" class="btn btn-primary py-1 px-3 mr-2"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                <button type="submit" @click="clearTimeSheet" class="btn btn-primary py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                            </div>
                        </div>
                    </b-card-body>
                </b-collapse>
            </b-card>
            <div class="row">
                <div class="col-12">
                    <h6 class="contact-title mb-2 ml-2"><span>Timesheets</span></h6>
                    <TimeSheetListComponent :timeSheets="timeSheets">
                        <template v-slot:actions="{item}">
                            <a href="javascript:" title="Edit" @click="showEditTimeSheet(item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                            <a href="javascript:" title="Delete" @click="deleteTimeSheet(item.id)" v-b-modal.deleteTimeSheet><i class="fa fa-trash pl-2"></i></a>
                        </template>
                    </TimeSheetListComponent>
                </div>
            </div>
        </b-overlay>
        <AddUserActivityPopup v-if="showDialog" id="userActivityModal" ref="addUserActivityModal"  @refresh-lookup="lookupData" @closeDialog="closeDialog" />                 
        <DialogComponent id="deleteTimeSheet" :onYes="onYesTimeSheet" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Time Sheet" />

	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import AddUserActivityPopup from '@/components/popup/action/AddUserActivityPopup.vue';
import TimeSheetListComponent from '@/components/common/TimeSheetListComponent.vue';
import VmxEditor from '@/components/usercontrols/VmxEditor';
import { required, email, minLength, maxLength, sameAs, between } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
    	taskData: Object,
    },
    components: {
        AddUserActivityPopup,
        TimeSheetListComponent,
        VmxEditor
    },
    data() {
        return {

            isValidEffort: true,
            elements: {
                visible: false,
                editTimeSheet: false,
            },
            showOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entityRecordMasterId: this.$route.params.ermId,
            userId: localStorage.getItem("userId"),

            showDialog: false,

            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedById: '',
                title: '',
                taskId: '',
                task: '',
                activityId: '',
                activity: '',
                processActivity: {
                    activityId: '',
                    processId: '',
                },                
                preparedBy: '',
                startDate: '',
                startDateValue: '',
                startTimeValue: '',
                endDate: '',
                endDateValue: '',
                endTimeValue: '',
                workDay: '',
                workHour: '',
                workMinute: '',
                description: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: 'time_sheet'
            },

            timeSheets: [],
            tsTasks: [],
            processActivities: [],
            tsActivities: [],
            taskStatus: [],
            timeSheetFields: [
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Duration', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        timeSheet: {
            title: {
                required,
            },
        }
    },
    mounted() {
        this.lookupData()
        this.setInitData();
        this.getTimeSheets(this.taskData.id);
    },
    methods: {
        setInitData() {
            this.timeSheet.title = this.taskData.title;
            this.timeSheet.processActivity.activityId = this.taskData.activityId;
            this.timeSheet.processActivity.processId = this.taskData.processId;
            this.timeSheet.startDateValue = this.taskData.estimatedStartDt ? this.getDateValue(this.taskData.estimatedStartDt) : '';
            this.timeSheet.startTimeValue = this.getTimeValue(this.taskData.estimatedStartDt)
            this.timeSheet.endDateValue = this.taskData.estimatedEndDt ? this.getDateValue(this.taskData.estimatedEndDt) : '';
            this.timeSheet.endTimeValue = this.getTimeValue(this.taskData.estimatedEndDt)
            this.setEffortFromTimeDiff();
        },
        getTimeSheets: function(id) {
        	if (id && id != '') {
	            let data = {
	                taskId: id
	            }
	            console.log(data);
                this.showOverlay = true;
	            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetbytask/list', data)
	                .then((response) => {
                        this.showOverlay = false;
	                    let result = response.data;
	                    this.timeSheets = result.timeSheetListByTask;
	                    this.totalRows = this.timeSheets.length;
	                    console.log(result);
	                })
                    .catch(() => {
                        this.showOverlay = false;
                    })
        	}
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.entityRecordMasterId,
                userId: this.userId,
                entity: 'time_sheet'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tsTasks = result.taskLookup ? result.taskLookup : [];
                    this.processActivities = result.userProcessActivityLookup;
                    this.tsActivities = result.activityLookup;
                    this.taskStatus = result.statusLookup;
                })
        },
        saveData: function() {
            console.log(this.timeSheet);
            this.timeSheet.statusId = this.taskData.statusId;
            if (this.timeSheet.startDate == '' || this.timeSheet.startDate == null) { 
            	this.timeSheet.startDate = this.combineDateAndTime(this.timeSheet.startDateValue, this.timeSheet.startTimeValue) 
            };
            if (this.timeSheet.endDate == '' || this.timeSheet.endDate == null) { 
            	this.timeSheet.endDate = this.combineDateAndTime(this.timeSheet.endDateValue, this.timeSheet.endTimeValue) 
            };
            this.timeSheet.processId = this.timeSheet.processActivity.processId;
            this.timeSheet.activityId = this.timeSheet.processActivity.activityId; 

            console.log(this.timeSheet.workDay,this.timeSheet.workHour,this.timeSheet.workMinute);

            this.$v.timeSheet.$touch();
            
            if (this.checkValidEffort() && !this.$v.timeSheet.$invalid) {

                if (this.elements.editTimeSheet) {
                    this.updateTimeSheet();
                } else {
                    this.saveTimeSheet();
                }   

            }                     
        },
        saveTimeSheet: function() {
            this.timeSheet.vhextAccountId = this.vhextAccountId;
            this.timeSheet.projectId = this.projectId;
            this.timeSheet.entityRecordMasterId = this.entityRecordMasterId;
            this.timeSheet.preparedById = this.userId;
            this.timeSheet.taskId = this.taskData.id;
            console.log(this.timeSheet);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/add', this.timeSheet)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.taskData.id);
                        this.clearTimeSheet();
                        this.elements.editTimeSheet = false;
                    }
                })
                .catch(() => {
                    this.showOverlay = false;
                })
        },
        updateTimeSheet: function() {
            console.log(this.timeSheet);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/update', this.timeSheet)
                .then((response) => {
                    this.showOverlay = false;
                    console.log(this.timeSheet);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.taskData.id);
                        this.clearTimeSheet();
                        this.elements.editTimeSheet = false;
                    }
                })
                .catch(() => {
                    this.showOverlay = false;
                })
        },
        clearTimeSheet: function() {
            // this.getTimeSheets(this.taskData.id);
            this.elements.visible = false;
            this.elements.editTimeSheet = false;
            
            this.isValidEffort = true;

            this.timeSheet.taskId = null;
            this.timeSheet.title = null;
            this.timeSheet.statusId = null;
            this.timeSheet.activityId = null;
            this.timeSheet.activity = null;
            this.timeSheet.processActivity.processId = '';
            this.timeSheet.processActivity.activityId = '';            
            this.timeSheet.status = null;
            this.timeSheet.activity = null;
            this.timeSheet.startDate = null;
            this.timeSheet.endDate = null;
            this.timeSheet.startDateValue = this.taskData.estimatedStartDt ? this.getDateValue(this.taskData.estimatedStartDt) : '';
            this.timeSheet.startTimeValue = this.getTimeValue(this.taskData.estimatedStartDt)
            this.timeSheet.endDateValue = this.taskData.estimatedEndDt ? this.getDateValue(this.taskData.estimatedEndDt) : '';
            this.timeSheet.endTimeValue = this.getTimeValue(this.taskData.estimatedEndDt)
            this.timeSheet.workDay = '';
            this.timeSheet.workHour = '';
            this.timeSheet.workMinute = '';
        },
        deleteTimeSheet: function(id) {
            this.deleteParam.id = id;
        },
        onYesTimeSheet: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/task/timesheet/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.taskData.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        showEditTimeSheet: function(data) {
            this.topFunction();
            this.elements.visible = true;
            this.elements.editTimeSheet = true;
            this.timeSheet.id = data.id;
            this.timeSheet.projectId = data.projectId;
            this.timeSheet.vhextAccountId = data.vhextAccountId;
            this.timeSheet.entityRecordMasterId = data.entityRecordMasterId;
            this.timeSheet.entity = data.entity;
            this.timeSheet.timeSheetId = data.timeSheetId;
            this.timeSheet.taskId = data.taskId;
            this.timeSheet.taskTitle = data.taskTitle;
            this.timeSheet.title = data.title;
            this.timeSheet.statusId = data.statusId;
            this.timeSheet.status = data.status;
            this.timeSheet.activityId = data.activityId;
            this.timeSheet.activity = data.activity;
            this.timeSheet.preparedById = data.preparedById;
            this.timeSheet.preparedBy = data.preparedBy;
            this.timeSheet.startDate = '';
            this.timeSheet.startDateValue = data.startDate ? this.getDateValue(data.startDate) : '';
            this.timeSheet.startTimeValue = this.getTimeValue(data.startDate)
            this.timeSheet.endDate = '';
            this.timeSheet.endDateValue = data.endDate ? this.getDateValue(data.endDate) : '';
            this.timeSheet.endTimeValue = this.getTimeValue(data.endDate)
            this.timeSheet.workDay = data.workDay;
            this.timeSheet.workHour = data.workHour;
            this.timeSheet.workMinute = data.workMinute;
            this.timeSheet.description = data.description;
            this.$refs.effortControl.setEffortInputData(data.workDay, data.workHour, data.workMinute);
            console.log(this.timeSheet);
        },
        checkValidEffort() {
            this.setEffortData();
            if((this.timeSheet.workDay && this.timeSheet.workDay > 0) ||
                (this.timeSheet.workHour && this.timeSheet.workHour > 0) ||
                (this.timeSheet.workMinute && this.timeSheet.workMinute > 0)){

                this.isValidEffort = true;
                return true;

            }else{

                this.isValidEffort = false;
                return false;

            }
        },

        setEffortFromTimeDiff() {
            if (this.timeSheet.startTimeValue && this.timeSheet.endTimeValue) {
                let startDate = this.timeSheet.startDateValue ? this.timeSheet.startDateValue : this.getDateValue(new Date());
                let endDate = this.timeSheet.endDateValue ? this.timeSheet.endDateValue : this.getDateValue(new Date());

                let combStartDT = this.combineDateAndTime(startDate, this.timeSheet.startTimeValue);
                let combEndDT = this.combineDateAndTime(endDate, this.timeSheet.endTimeValue);

                let difference = this.getDateDifference(combStartDT, combEndDT);
                this.$refs.effortControl.setEffortInputData(difference.days, difference.hours, difference.minutes);
                this.checkValidEffort();
            }
        },
        setEffortData: function() {
            this.timeSheet.workHour = this.$refs.effortControl.getHrs();
            this.timeSheet.workMinute = this.$refs.effortControl.getMins();
            this.timeSheet.workDay = this.$refs.effortControl.getDays();
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType != null) {
                return effortType[0].name;
            }
        },
        topFunction() {
            const elements = document.querySelector('#timeSheetPopup');
            elements.scrollTop = 0;
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }        
    }
}
</script>