<template>
    <div class="type">
        <b-modal ref="manageCustomUserRoleModal" size="lg" :id="id" centered modal-class="typeModal" title="User Roles" @hide="closeDialog" :no-close-on-backdrop="true"  ok-variant="secondary" ok-only ok-title="Close">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                            <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New User Role</span>
                            <span v-if="elements.editMode"><i class="fa fa-pencil pr-2 action-link"></i>Edit</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                        <b-card-body class="p-3">
                            <div class="form-row">
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label for="startDate">User Role</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.customUserRole.$error }">
                                            <b-form-input id="title" v-model="customUserRole.customUserRole" :class="{ 'is-invalid': $v.customUserRole.customUserRole.$error }" placeholder="Enter User Role"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.customUserRole.customUserRole.$error && !$v.customUserRole.customUserRole.required" class="text-danger">Please Enter User Role</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 col-lg-6">
                                    <div class="form-group">
                                        <label for="endDate">User Type</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.customUserRole.userTypeId.$error }">
                                            <select v-model="customUserRole.userTypeId" :disabled="elements.editMode" :class="{ 'is-invalid': $v.customUserRole.userTypeId.$error }" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in vmxAccountUserTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.customUserRole.userTypeId.$error && !$v.customUserRole.userTypeId.required" class="text-danger">Please Select User Type</label>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div class="form-group">
                                        <button type="submit" @click="saveCustomUserRoleData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>{{ elements.editMode ? 'Update' : 'Save' }}</button>
                                        <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div class="float-right">
                    <div class="dataTables_length">
                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <b-table outlined show-empty small stacked="md" sort-icon-left :items="customUserRoles" :fields="customUserRoleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template v-slot:cell(customUserRole)="data">
                        <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                            <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('custom_user_role', data.item.id, data.value)" @blur="cancelInlineEdit(customUserRoles, data.field.key)">
                        </div>
                        <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                    </template>
                    <template v-slot:cell(userRole)="data">
                        <div v-if="data.value">{{ data.value }}</div>
                        <div v-else class=" text-muted">No Reference</div>
                    </template>
                    <template v-slot:cell(isPrimary)="data">
                        <div v-if="data.item.isPrimary">
                            <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.isPrimary">
                            </b-form-checkbox>                            
                        </div>

                    </template>

                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <!-- <a href="javascript:" title="Edit UserRoles" @click="showUserRoleEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a> -->
                        <a v-if="!data.item.isPrimary" href="javascript:" title="Delete" @click="removeCustomUserRole(data.item.id)" v-b-modal.removeCustomUserRole><i class="fa fa-trash pl-2"></i></a>
                        <span v-else>NA</span>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No User Roles found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                <b-pagination v-if="customUserRoles && customUserRoles.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                <!-- </div> -->
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeCustomUserRole" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Role ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'manageCustomUserRoleModal' },
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'custom_user_role',
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,
            stdModuleDisable: false,
            userRoleTypeId: 1,

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            customUserRole: {
                id: '',
                customUserRoleId: '',
                userRoleTypeId: '',
                userTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userRoleId: '',
                vmxServiceId: '',
                userType: '',
                customUserRole: '',
                typeCode: '',
                moduleList: [],
                isStandard: false,
                active: true,
                actionState: '',
                createdById: localStorage.getItem("userId"),
            },
            vmxAccountUserTypes: [],
            vmxUserRoles: [],
            vmxServices: [],
            stdUserModules: [],
            stdUserModuleList: [],
            customUserRoles: [],
            customUserRoleFields: [
                { key: 'index', label: 'SN', },
                // { key: 'customUserRoleId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'customUserRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'userType', label: 'User Type', sortable: true, sortDirection: 'desc' },
                
                { key: 'userRole', label: 'Std. User Role Reference', sortable: true, sortDirection: 'desc' },
                { key: 'isPrimary', label: 'Is Primary', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        customUserRole: {
            customUserRole: {
                required
            },
            userTypeId: {
                required
            },
        }
    },
    computed: {
        userTypeId: {
            get() {
                return this.$store.state.userManagementData.userTypeId;
            },
            set(value) {
                this.$store.state.userManagementData.userTypeId = value;
            }
        },
    },
    mounted() {
        this.getCustomUserRoleList();
        this.lookupData();
        // this.customUserRole.userTypeId = this.$store.state.userManagementData.userTypeId;
    },
    methods: {
        getCustomUserRoleList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userRoleTypeId: this.userRoleTypeId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.customUserRoles = result.customUserRoleList;
                    this.totalRows = this.customUserRoles.length;
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    // this.vmxUserRoles = result.vmxAccountUserRoleLookup;
                    this.vmxAccountUserTypes = result.customUserTypeLookup;

                    console.log(this.vmxAccountUserTypes);
                })
        },
        saveCustomUserRoleData: function() {
            this.customUserRole.userRoleTypeId = this.userRoleTypeId;
            this.$v.customUserRole.$touch();
            if (!this.$v.customUserRole.$invalid) {
                if (!this.matchTextValExists(this.customUserRole.customUserRole, this.customUserRole.id)) {
                    if (this.elements.editMode) {
                        this.updateCustomUserRoleLookup();
                    } else {
                        this.addCustomUserRoleLookup();
                    }
                }
                else {
                    iziToast.info({
                        message: 'User Role already exists',
                        position: 'topCenter'
                    });
                }
            }
        },
        addCustomUserRoleLookup: function() {
            this.showOverlay = true;
            this.customUserRole.actionState = 1;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/add', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateCustomUserRoleLookup: function() {
            this.showOverlay = true;
            this.customUserRole.actionState = 2;
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/update', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        customUserRoleUserTypeChange: function(customuserRoleId, userTypeId) {
            this.showOverlay = true;
            this.customUserRole.id = customuserRoleId;
            this.customUserRole.userTypeId = userTypeId;
            this.userTypeId = userTypeId;
            this.customUserRole.actionState = 1;
            console.log(this.customUserRole);
            axios.post(this.$store.getters.getAPIBasePath + '/user/customuserrole/usertypechange', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeCustomUserRole: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: 'custom_user_role',
                id: this.deleteParam.id,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/erd/delete', data)
            // axios.delete(this.$store.getters.getAPIBasePath + '/user/customuserrole/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.customUserRole.id = '';
            this.customUserRole.customUserRoleId = '';
            this.customUserRole.userRoleTypeId = '';
            this.customUserRole.userTypeId = '';
            this.customUserRole.vmxServiceId = '';
            this.customUserRole.userRoleId = '';
            this.customUserRole.userType = '';
            this.customUserRole.customUserRole = '';
            this.customUserRole.moduleList = [];
            this.customUserRole.typeCode = '';
            this.customUserRole.isStandard = false;
            this.customUserRole.active = true;
            this.customUserRole.createdById = localStorage.getItem("userId");
            this.$v.customUserRole.$reset();
            // this.$bvModal.hide(this.id);
        },
        showUserRoleEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.customUserRole.id = data.id;
            this.customUserRole.customUserRoleId = data.customUserRoleId;
            this.customUserRole.userRoleTypeId = data.userRoleTypeId;
            this.customUserRole.userTypeId = data.userTypeId;
            this.customUserRole.vmxServiceId = data.vmxServiceId;
            this.customUserRole.vhextAccountId = data.vhextAccountId;
            this.customUserRole.userRoleId = data.userRoleId;
            this.customUserRole.userType = data.userType;
            this.customUserRole.customUserRole = data.customUserRole;
            this.customUserRole.typeCode = data.typeCode;
            this.customUserRole.isStandard = data.isStandard;
            this.customUserRole.active = data.active;
            this.customUserRole.createdById = data.createdById;
        },
        assignActivationState: function(item) {
            this.showOverlay = true;
            if (item.active == true) {
                this.customUserRole.id = item.id;
                this.customUserRole.active = 0;
                this.customUserRole.entity = this.entity;
                this.updateActivationState();
            } else {
                this.customUserRole.id = item.id;
                this.customUserRole.active = 1;
                this.customUserRole.entity = this.entity;
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.customUserRole)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                        this.clearData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.customUserRoles.map(data => {
                if (textVal && data.customUserRole && data.id !== id) {
                    if (data.customUserRole.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: this.entity,
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.$emit('refresh-lookup');
                        this.getCustomUserRoleList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        }

    }
}
</script>