// import DSNReportList from '@/views/misreport/DSNReportList.vue'
import FinTxnSummaryReport from '@/views/report/mis/finance/FinTxnSummaryReport.vue'
import EmpAttendanceSummaryReport from '@/views/report/mis/resource/EmpAttendanceSummaryReport.vue'
import EmpNetSalaryReport from '@/views/report/mis/resource/EmpNetSalaryReport.vue'
import EmpTaskEfficiencyReport from '@/views/report/mis/task/EmpTaskEfficiencyReport.vue'
import EmpTaskTrackingReport from '@/views/report/mis/task/EmpTaskTrackingReport.vue'
import ERMReportMaster from '@/views/misreport/ERMReportMaster.vue'
import FinTxnReport from '@/views/report/mis/finance/FinTxnReport.vue'
import FinTxnByGroupReport from '@/views/report/mis/finance/FinTxnByGroupReport.vue'
import PayrollByMonthReport from '@/views/report/mis/resource/PayrollByMonthReport.vue'
import ProjectFinancialTxn from '@/views/report/mis/ProjectFinancialTxns.vue'
import TaskByPlanReport from '@/views/report/mis/task/TaskByPlanReport.vue'
import TaskListReport from '@/views/report/mis/task/TaskListReport.vue'
// import SSTReportList from '@/views/misreport/SSTReportList.vue'
// import URQReportList from '@/views/misreport/URQReportList.vue'



export default [
    /*    {
            path: '/dsnrep/list',
            name: 'dsnreplist',
            component: DSNReportList,
            meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
        },
    */
    {
        path: '/ftxnsumrep',
        name: 'ftxnsumrep',
        component: FinTxnSummaryReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }

    },
    {
        path: '/empattrep',
        name: 'empattrep',
        component: EmpAttendanceSummaryReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }

    },
    {
        path: '/empsalrep',
        name: 'empsalrep',
        component: EmpNetSalaryReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }

    },
    {
        path: '/tskeffrep',
        name: 'tskeffrep',
        component: EmpTaskEfficiencyReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }

    },
    {
        path: '/ettrep',
        name: 'ettrep',
        component: EmpTaskTrackingReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }

    },
    {
        path: '/ermrepmas/:entity',
        name: 'ermrepmas',
        component: ERMReportMaster,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }

    },
    {
        path: '/fintxnrep',
        name: 'fintxnrep',
        component: FinTxnReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fintxngrouprep',
        name: 'fintxngrouprep',
        component: FinTxnByGroupReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prlmrep',
        name: 'prlmrep',
        component: PayrollByMonthReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pftrep',
        name: 'pftrep',
        component: ProjectFinancialTxn,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tskplnrep',
        name: 'tskplnrep',
        component: TaskByPlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tsklistrep',
        name: 'tsklistrep',
        component: TaskListReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    // {
    //     path: '/srsrep/list',
    //     name: 'srsreplist',
    //     component: SRSMasReportList,
    //     meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    // },
    /*    {
            path: '/sstrep/list',
            name: 'sstreplist',
            component: SSTReportList,
            meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
        },
        {
            path: '/urqrep/list',
            name: 'urqreplist',
            component: URQReportList,
            meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
        },
    */
];