<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Standard User Roles & Modules Settings</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="form-row">
                    <div class="col-lg-7 col-xl-7">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table ref="userRoleTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="userRoles"  @row-selected="onRowSelected" :fields="userRoleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>

                                    <template v-slot:cell(actions)="data">
                                        <button v-if="selectedUserRoleId==data.item.id" type="submit" title="Selected" @click="handleUserTypeSelect(data.item)" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1 active">
                                            Selected
                                        </button>
                                        <button v-else type="submit" title="Select User Type" @click="handleUserTypeSelect(data.item)" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1">
                                            <i class="fa fa-hand-o-right pr-2" aria-hidden="true"></i>Select
                                        </button>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No User Modules found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                <b-pagination v-if="userRoles.length>0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-5 mb-4">
                        <div class="card h-100">
                            <div class="card-body p-3">
                                <span class="position-absolute mt-2 ml-auto" style="right: 1rem;">
                                    <b-button class="addnew-quicktassk-btn" v-b-toggle.accordion-module variant="outline-secondary" size="sm" :class="{'active' : elements.visible}" title="Add Module"><i class="fa fa-plus-circle pr-1" aria-hidden="true"></i>Add Module</b-button>
                                </span>
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab :title="(selectedUserRole ? selectedUserRole : '') + ' - Modules'">
                                        <b-card no-body class="">
                                            <b-collapse id="accordion-module" v-model="elements.visible" class="p-1" role="tabpanel">
                                                <div class="row view-filter">
                                                    <div class="col-sm-12">
                                                        <div class="float-right">
                                                            <div class="dataTables_length">
                                                                <b-form-group label="Per page" label-cols-sm="6" label-align="right" label-size="sm" label-for="perPageSelectRef" class="pl-0 mb-0">
                                                                    <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                        <div class="float-left">
                                                            <div class="dataTables_filter">
                                                                <label>
                                                                    <input type="search" v-model="filterRef" class="form-control form-control-sm" placeholder="Search...">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                                <b-table show-empty small stacked="md" sort-icon-left :items="filteredModules" :fields="stdModuleFields" responsive="sm" :current-page="currentPageRef" :per-page="perPageRef" :filterIncludedFields="filterOn" :filter="filterRef" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(actions)="data">

                                                        <a v-if="data.item.menuCount>0" href="javascript:" title="Add Module" class="text-success" @click="stdUserModuleManage(data.item.id, 1)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                        <span v-else class="text-muted">No Menu</span>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No User Modules found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                                <div class="col-4 float-left pl-0 mb-2">
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsRef}} Items</span>
                                                </div>
                                                <div class="col-7 pull-right">
                                                    <b-pagination v-if="filteredModules.length>0" pills v-model="currentPageRef" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                                </div>
                                            </b-collapse>
                                        </b-card>
                                        <div :class="{'mt-3' : elements.visible}">
                                            <label class="mb-1">User Modules</label>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="filteredUserModules" :fields="addedModuleFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <a href="javascript:" title="Remove Module" class="text-danger" @click="deleteUserRole(data.item.moduleId)" v-b-modal.deleteUserRole><i class="fa fa-minus-circle pr-2"></i></a>

                                                    <a href="javascript:" title="Reset Module Menus" class="text-primary" @click="stdUserModuleManage(data.item.moduleId,2)" v-b-modal.resetUserRole><i class="fa fa-repeat pr-2"></i></a>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No User Modules found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <DeleteComponent id="deleteUserRole" :onYes="onYesUserRoleDelete" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Module?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'user_role',

            idValue: '',
            idEdit: '',

            selectedUserRoleId: '',
            selectedUserRole: '',

            mainTableArray: [],

            userRole: {
                id: '',
                userRoleId: '',
                userTypeId: '',
                moduleId: '',
                role: '',
                typeCode: '',
                typeClass: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            userRoleDetail: {
                id: '',
                userRoleId: '',
                userTypeId: '',
                moduleId: '',
                role: '',
                typeCode: '',
                typeClass: '',
                active: true,
                createdById: localStorage.getItem("userId"),
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            userRoles: [],
            stdModules: [],
            userRoleModules: [],

            userRoles: [],
            userRoleFields: [
                {key: 'index',label: 'SNo'},
                { key: 'id', label: 'User Role Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'userRole', label: 'User Role', sortable: true, sortDirection: 'desc' },
                { key: 'moduleCount', label: 'Menus', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],
            stdModuleFields: [
                {key: 'index',label: 'SN'},
                // { key: 'userRoleId', label: 'User Role Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'menuCount', label: 'Menus', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],
            addedModuleFields: [
                {key: 'index',label: 'SN'},
                // { key: 'userRoleId', label: 'User Role Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },

                { key: 'menuCount', label: ' Total Menus', sortable: true, sortDirection: 'desc' },
                
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 10,
            filterRef: null,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.userRoleModuleSummary();
        this.getUserRoles();
    },
    validations: {
        userRole: {
            role: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            moduleId: {
                required,
            },
            userRoleId: {
                required,
            },
        },
        userRoleDetail: {
            role: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            moduleId: {
                required,
            },
            userRoleId: {
                required,
            },
        }
    },
    computed: {
        filteredModules() {
            let filteredModules = this.stdModules.filter((data) => !this.isUserRoleExists(data.id));
            this.totalRowsRef = filteredModules.length;
            return filteredModules;
        },
        filteredUserModules() {
            console.log(this.userRoleModules.length)
            if (this.selectedUserRoleId && this.userRoleModules.length>0) {
                return this.userRoleModules.filter((data) => data.userRoleId == this.selectedUserRoleId);
            }
            else return [];
        }
    },
    methods: {
        userRoleModuleSummary: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrolemodule/summary')
                .then((response) => {
                    let result = response.data;
                    
                    this.userRoles = result.userRoleSummary;
                    this.stdModules = result.stdModuleMenuSummary;

                    this.totalRows = this.userRoles.length;
                    this.totalRowsRef = this.filteredModules.length;
                    this.$nextTick(() => {
                        this.selectBTableRowById(this.selectedUserRoleId, this.mainTableArray, 'userRoleTable');
                    })
                })

        },        
        getUserRoles: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/module/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.userRoleModules = result.userRoleModuleList;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },

        deleteUserRole: function(id) {
            this.deleteParam.id = id;
        },
        onYesUserRoleDelete: function() {
            this.stdUserModuleManage(this.deleteParam.id, 0);
        },
        stdUserModuleManage: function(moduleId, actionState) {
            let data = {
                userRoleId: this.selectedUserRoleId,
                moduleId: moduleId,
                actionState: actionState,
                createdById: this.userId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/stdusermodule/manage', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "OK") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                this.userRoleModuleSummary();
                this.getUserRoles();

                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        updateUserRole: function() {
            this.$v.userRoleDetail.$touch();
            if (!this.$v.userRoleDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/userrole/update', this.userRoleDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.userRoleModuleSummary();
                            this.getUserRoles();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        onRowSelected: function(items) {
            console.log(items)
            if (items.length > 0){
                let data = items[0];
                this.elements.visible = false;
                this.elements.showDetail = true;
                this.selectedUserRole = data.userRole;
                this.selectedUserRoleId = data.id;
            }
        },
        handleUserTypeSelect(data) {
            this.elements.visible = false;
            this.selectedUserRole = data.userRole;
            this.selectedUserRoleId = data.id;
            this.$nextTick(() => {
                this.selectBTableRowById(this.selectedUserRoleId, this.mainTableArray, 'userRoleTable');
            })
        },
        isUserRoleExists(moduleId) {
            let result = false;
            if (this.filteredUserModules.length>0) {
                let data = this.filteredUserModules.find(data => data.moduleId == moduleId);
                result = data ? true : false;
            }
            return result;
        }
    }
}
</script>
