<template>
    <div>
        <b-overlay :show="showOverlay" opacity="0.4">
            <div class="form-row input-group-label px-2 mb-2">
                <div class="col-4">
                    <!-- <b-input-group :prepend="ermMasterInfo.actionName"> -->
                    <div class="required-field-block" :class="{ 'is-invalid': $v.ermTaskPlanData.entityRecordMasterId.$error }">                                                
                        <b-input-group :prepend="this.taskPlanTitle">
                            <select v-model="ermTaskPlanData.entityRecordMasterId" @change="onTaskPlanErmChange" class="form-control" :class="{ 'is-invalid': $v.ermTaskPlanData.entityRecordMasterId.$error }">
                                <!-- <option value="">Select</option> -->
                                <option v-for="(info, index) in entityRecordMasterList" :value="info.id">{{info.title}}</option>
                            </select>
                            <div class="required-icon"></div>
                        </b-input-group>
                        <div class="required-icon"></div>
                    </div>
                    <label v-if="$v.ermTaskPlanData.entityRecordMasterId.$error && !$v.ermTaskPlanData.entityRecordMasterId.required || $v.ermTaskPlanData.ermTitle.$error && !$v.ermTaskPlanData.ermTitle.required" class="text-danger">Task Plan is required</label>                                        
                </div>
                <div class="col-auto px-0">
                    <div class="d-flex align-middle">
                        <a class="cust-setting-icon mr-1" href="javascript:" v-b-modal.manageErmMasterPopup title="Manage Task Plan">
                            <i class="fa fa-cog fa-lg action-link" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
                <div class="col text-right">
                </div>
            </div>
            <b-card v-if="ermTaskPlanData.entityRecordMasterId" no-body class="my-2">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-det >
                        <span>Task Plan Header</span>
                    </label>
                </b-card-header>
                <b-collapse id="accordion-det" class="py-1 px-2" role="tabpanel">
                    <div class="row">
                        <div class="col-9 col-md-10 col-lg-11 pr-0">
                            <template v-if="!elements.editMode">
                                <div class="form-row">
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Title</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{entityRecordMasterData.title}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Status</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{entityRecordMasterData.status}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showTypeLookup" class="form-row">
                                    <div class="col-12 col-sm-12">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Type</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{entityRecordMasterData.type}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showDateRange" class="form-row">
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Start Date</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{formatDate(entityRecordMasterData.startDate)}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">End Date</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{formatDate(entityRecordMasterData.endDate)}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="ermMasterInfo.showErmDate" class="form-row">
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Month</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{ getMonthYearAlt(entityRecordMasterData.ermDate) }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Client</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{ entityRecordMasterData.clientName }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 p-1 pt-2 pb-2 d-block title-block form-control">Created By</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details form-control">{{ entityRecordMasterData.createdBy }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="col-12">
                                        <div class="card-body p-0 my-0">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-desc>
                                                        <span>Description</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-desc" accordion="my-accordion1" role="tabpanel">
                                                    <b-card-body class="p-1 pb-0">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12">
                                                                <div class="form-group">
                                                                    <p v-if="entityRecordMasterData.description"><span v-html="entityRecordMasterData.description"></span></p>
                                                                    <p v-else class="text-muted my-2 text-center">No description</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="elements.editMode">
                                <div class="form-row">
                                    <div class="col-6 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Title</label>
                                            </div>
                                            <div class="col-8">
                                                <input v-model="entityRecordMasterData.title" type="text" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Status</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-if="elements.editMode" v-model="entityRecordMasterData.statusId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in masterStatus" :key="info.id" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Type</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-if="elements.editMode" v-model="entityRecordMasterData.ermTypeId" @change="handleErmTypeChange" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="ermMasterInfo.showDateRange">
                                        <div class="col-6">
                                            <div class="row no-gutters">
                                                <div class="col-4">
                                                    <label class="mb-1 d-block title-block form-control">Start Date</label>
                                                </div>
                                                <div class="col-8">
                                                    <input v-if="elements.editMode" type="date" class="form-control" v-model="entityRecordMasterData.startDate" id="startDate">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row no-gutters">
                                                <div class="col-4">
                                                    <label class="mb-1 d-block title-block form-control">End Date</label>
                                                </div>
                                                <div class="col-8">
                                                    <input v-if="elements.editMode" type="date" class="form-control" v-model="entityRecordMasterData.endDate" id="endDate">
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div v-if="ermMasterInfo.showErmDate" class="col-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Month</label>
                                            </div>
                                            <div class="col-8">
                                                <input v-if="elements.editMode" type="month" class="form-control" v-model="entityRecordMasterData.ermDate" id="ermDate">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="ermMasterInfo.showMultipleMasterControl" class="col-12 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                            </div>
                                            <div class="col-8">
                                                <multiselect v-model="refErmList" :options="ermLookup" name="masterLink" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="ermMasterInfo.showSingleMasterControl" class="col-12 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-model="entityRecordMasterData.refErmId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in ermLookup" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="ermMasterInfo.showPlanLinkControl" class="col-12 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">{{ ermMasterInfo.masterLinkTitle }}</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-model="refErdData" class="form-control">
                                                    <option value="{id:'',ermId:''}">Select</option>
                                                    <option v-for="(info, index) in commonErdLookup" :value="{id:info.id,ermId:info.ermId}">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="getErmTypeCode == 'PRB'" class="col-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Project</label>
                                            </div>
                                            <div class="col-8">
                                                <input type="text" class="form-control" disabled v-model="projectName" id="startDate">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Client</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-if="elements.editMode" v-model="entityRecordMasterData.clientId" id="clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="col-6 col-sm-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Client</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-if="elements.editMode" v-model="entityRecordMasterData.clientId" id="clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row no-gutters">
                                            <div class="col-4">
                                                <label class="mb-1 d-block title-block form-control">Created By</label>
                                            </div>
                                            <div class="col-8">
                                                <select v-if="elements.editMode" v-model="entityRecordMasterData.createdById" id="createdById" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-2">
                                        <div class="card-body p-0 my-0">
                                            <b-card no-body>
                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                    <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-desc>
                                                        <span>Description</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-desc" accordion="my-accordion1" role="tabpanel">
                                                    <b-card-body class="p-1 pb-0">
                                                        <div class="row">
                                                            <div class="col-12 col-sm-12">
                                                                <div v-if="elements.editMode" class="form-group">
                                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="entityRecordMasterData.description"></editor>
                                                                </div>
                                                                <div v-else class="form-group">
                                                                    <p v-if="entityRecordMasterData.description"><span v-html="entityRecordMasterData.description"></span></p>
                                                                    <p v-else class="text-muted my-2 text-center">No description</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="col">
                            <div class="more-btns text-center">
                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                    <i class="fa fa-pencil fa-sm action-link" aria-hidden="true"></i>
                                </button>
                                <div v-if="elements.editMode">
                                    <button type="submit" @click="updateERMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                    <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </b-card>
            <b-card v-if="!elements.showErmAdd" no-body class="mb-2">
                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                    <label v-if="this.projectId" class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-effortEst>Project Requirements</label>
                    <label v-else class="d-block p-2 mb-2 accordion-title" block href="javascript:" v-b-toggle.accordion-effortEst>Work Requirements</label>                    
                </b-card-header>
                <b-collapse id="accordion-effortEst" class="p-1" role="tabpanel">
                    <div class="row input-group-label my-2">
                        <div class="col-6">
                            <b-input-group size="sm" prepend="Requirement Type" class="mb-2">
                                <select v-model="ermData.entityId" @change="getEntityErmLookup" class="form-control" >
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in planRequirementLookup" :value="info.id">{{info.value}}</option>
                                </select>
                            </b-input-group>
                        </div>
                        <div v-if="getEntity" class="col">
                            <b-input-group :prepend="getEntityName" class="mb-2">
                                <select v-model="ermData.entityRecordMasterId" @change="getErdPlanRequirements" class="form-control" :class="{ 'is-invalid': $v.ermData.entityRecordMasterId.$error }">
                                    <option value="">Select {{ getEntityName }}</option>
                                    <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
                                </select>
                                <b-input-group-append class="append-0">
                                    <a title="Create new requirement master" class="cust-setting-icon action-link" href="javascript:" @click="openBacklogErmAddPop"><i class="fa fa-cog" aria-hidden="true"></i></a>
                                </b-input-group-append>
                            </b-input-group>
                            <label v-if="$v.ermData.entityRecordMasterId.$error && !$v.ermData.entityRecordMasterId.required || $v.ermData.ermTitle.$error && !$v.ermData.ermTitle.required" class="text-danger">Effort Est. Title is required</label>
                        </div>
                    </div>
                    <div v-if="getEntity" class="row view-filter">
                        <div class="col-sm-12">
                            <div class="row input-group-label">
                                <div class="col-3  mb-0 mt-1">
                                    <label class="mb-0 mt-2 ml-1 fs-14 title-highlight">{{ getEntityName }} - {{ getReqDocumentName }}</label>
                                </div>
                                <div class="col-2  mb-0 mt-1 dataTables_filter text-left">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>          
                                <div class="col-5  mt-2 dataTables_filter text-left checkbox-container">
                                    <label v-for="(option, index) in options" :key="index" class="checkbox-label">
                                        <input type="radio" :value="option.value" v-model="selectedOptions" @change="handleCheckboxChange(option)">  {{ option.label }} 
                                    </label>
                                </div>
                                <div class="col-2  mb-0 mt-1 text-right">
                                    <button class="btn sbtn btn-primary btn-md float-right mb-2 px-2 py-1" @click.prevent="openNewDocumentAddPopup(null, false)"><i class="fa fa-plus-circle mr-2" aria-hidden="true"></i>New {{ getEntityName }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <b-table ref="planBacklogTable" outlined show-empty small head-variant=:primary sticky-header="40vh" selectable :select-mode="selectMode" :items="requirementSpecs" :fields="requirementSpecFields" @row-selected="onRowSelected" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" sort-icon-left :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"  >
                        <template v-if="requirementSpecs.length>0" v-slot:head(selected)="data">
                            <div class="form-check">
                                <input class="" type="checkbox" v-model="batchSelectCheckBox" id="batch-select-checkbox" @click="handleBatchSelect" >
                            </div>
                        </template>
                        <template #cell(selected)="data">
                            <div class="form-check">
                                <input class="" type="checkbox" :id="'chckbox'+data.index+1" v-model="data.rowSelected" @change="handleRowClick(data.index)">
                                <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                            </div>
                        </template>
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(title)="data">
                            <a class="tooltiptext action-link" href="javascript:" @click="viewBacklogDetails(data.item)">
                                {{ data.value }}
                            </a>
                        </template>
                        <template v-slot:cell(effort)="data">
                            <div v-show="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                <b-input-group size="sm">
                                    <EffortInput :id="'empeffort'+data.item.id"  
                                    :daysId="data.field.key+data.item.id" 
                                    @vmx-effort-input="vmxTotalEffortInput" 
                                    :daysValue="data.item.workDay" 
                                    :hrsValue="data.item.workHour"
                                    :minsValue="data.item.workMinute"
                                    ref="totalEffortControl" />
                                </b-input-group>
                                <div class="text-right">
                                    <span class="more-btns">
                                        <button type="submit" @click="updateEffort(data.item.id,'totalEffortControl')" title="Update" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                        <button type="submit" @click="cancelInlineEffortEdit(requirementSpecList,'totalEffortControl')" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-remove" aria-hidden="true"></i></button>
                                    </span>
                                </div>
                            </div>
                            <template v-if="!(data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName)">
                                <p v-if="data.item.workDay || data.item.workHour || data.item.workMinute" class="action-link" @dblclick="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item,'totalEffortControl')">
                                    {{ formatDurationDHM(data.item.workHour,data.item.workMinute,data.item.workDay) }}
                                </p>
                                <div v-else @click="focusEffortEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.item, 'totalEffortControl')" class="action-link">Set Effort</div>
                            </template>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <span v-if="data.item.preparedById == userId">
                                <a href="javascript:" class="" title="Edit" @click="openNewDocumentAddPopup(data.item, true)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                            </span>

                            <a v-if="data.item.refRecordCount" href="javascript:" class="" title="Assignments" @click=""><i class="fa fa-tasks fa-custom fa-lg pl-2"><div class="badge-success badge-pill pointer text-center float-right fs-12">{{ data.item.refRecordCount }}</div></i></a>

                            <button type="submit" @click.prevent="assignTask(data.item)" class="sbtn btn btn-outline-primary btn-xs px-2 ml-2"><i class="fa fa-user-plus mr-1"></i>Assign Task</button>                            
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <div class="row mb-2">
                        <div class="col">
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>
                        </div>
                        <div v-if="selectedItems.length>0 && ermTaskPlanData.entityRecordMasterId" class="col-auto">
                            <div class="row input-group-label">
                                <div class="col-auto">
                                    <b-input-group size="sm" prepend="Assign To" class="">
                                        <select v-model="taskData.assignedToId" class="form-control" >
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </b-input-group>
                                </div>
                                <div class="col-auto">
                                    <button type="submit" @click.prevent="assignBulkTask" class="sbtn btn btn-primary px-2 py-1 mr-1"><i class="fa fa-user-plus mr-1"></i>Assign Tasks [{{ selectedItems.length }}]</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </b-card>
            <b-card no-body>
                <div class="card-body p-1">
                    <div class="row pt-1">
                        <div class="col-2 mb-0 mt-1">
                            <label class=" ml-1 fs-14 title-highlight">
                                Task Assignments
                            </label>
                        </div>
                        <div v-if="ermTaskPlanData.entityRecordMasterId" class="col-2 mb-0 mt-1 dataTables_filter text-left">
                            <label>
                                <input type="search" v-model="taskFilter" class="form-control form-control-sm" placeholder="Search...">
                            </label>
                        </div>                                

                        <div class="col-5  mt-2 dataTables_filter text-left checkbox-container">
                            <label v-for="(option, index1) in taskOptions" :key="index1" class="checkbox-label">
                                <input type="radio" :value="option.value" v-model="selectedTaskOptions">  {{ option.label }} 
                            </label>
                        </div>  
                        <div v-if="ermTaskPlanData.entityRecordMasterId && isArchive== false "  class="col-1 text-right">
                            <a class="action-link d-inline-block pt-2 " title="Archived Task" href="javascript:" @click="isArchive=true;getBacklogTaskList()"><i class="fa fa-history"></i> Archive</a>
                        </div>
                        <div v-if=" isArchive== true" class="col-1 text-right">
                            <a class="action-link d-inline-block pt-2" title="Task List" href="javascript:" @click="isArchive=false;getBacklogTaskList()"><i class="fa fa-history"></i> Task List</a>
                        </div>
                        <div v-if="ermTaskPlanData.entityRecordMasterId" class="col-2 pr-4 mb-0 mt-1 text-right">
                            <button type="submit" @click.prevent="quickTask()" class="btn sbtn btn-primary btn-md float-right mb-2 px-2 py-1"><i class="fa fa-user-plus mr-1"></i>Quick Task</button>
                        </div>
                    </div>
                    <b-table v-if="isArchive" outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :items="filteredTaskList" :fields="taskFields" responsive="sm" :filter="taskFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(title)="data">
                            <a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a>
                        </template>
                        <template v-slot:cell(workHour)="data">
                            {{convertToHM(data.item.estEffortMins)}}
                        </template>
                        <template v-slot:cell(estimatedCost)="data">
                            <span v-if="data.value">{{ currencySymbol }}{{ data.value }}</span>
                        </template> 
                        <template v-slot:cell(assignedToId)="data">    
                            {{ data.item.assignedTo }}
                        </template>
                        <template v-slot:cell(requirement)="data">
                            <div class="action-link"><a class="tooltiptext action-link" href="javascript:" @click="viewRequirementDetails(data.item)">{{data.value}}</a></div>
                        </template>
                        <template v-slot:cell(statusId)="data">    
                            {{ data.item.status }}
                        </template>
                        <template v-slot:cell(actions)="data">
                            <span v-if="data.item.assignedById == userId">
                                <a href="#" class="mr-2" title="UnArchive" @click="archiveTask(data.item)"><i class="fa fa-undo" aria-hidden="true"></i></a>
                            </span>
                            <span v-else title="No Action">NA</span>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <b-table v-else outlined show-empty small head-variant=:primary sticky-header="65vh" selectable :select-mode="selectMode" :items="filteredTaskList" :fields="taskFields" responsive="sm" :filter="taskFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                        <template #cell(index)="data">
                            {{ data.index + 1 }}
                        </template>
                        <template v-slot:cell(title)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName  && data.item.assignedById == userId">
                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('title', data.item.id, data.value)" @blur="cancelInlineEdit(filteredTaskList, data.field.key)">
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value, false)" class="action-link"><a class="tooltiptext action-link" href="javascript:" @click="viewItemDetails(data.item)">{{data.value}}</a></div>
                        </template> 
                        <template v-slot:cell(taskTypeId)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName  && data.item.assignedById == userId">
                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('task_type_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(filteredTaskList, data.field.key)">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in taskTypes" :value="info.id"
                                    >{{info.value}}</option>
                                </select>
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value, false)" class="action-link"> {{ data.item.type ? data.item.type : 'Select' }}</div>
                        </template> 
                        <template v-slot:cell(estimatedEndDt)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName  && data.item.assignedById == userId" class="row no-gutters">
                                <b-col class="col-6">
                                    <input type="date" class="form-control" :id="data.field.key+data.item.id" v-model="inlineEditData.dateValue" placeholder="" @keyup.enter="updateInlineDateValue('estimated_end_dt', data.item.id, data.value, true)" @keyup.esc="cancelInlineEdit(filteredTaskList, data.field.key)">
                                </b-col>
                                <b-col class="col-6 pl-1">
                                    <input type="time" class="form-control" v-model="inlineEditData.timeValue" placeholder="" @keyup.enter="updateInlineDateValue('estimated_end_dt', data.item.id, data.value, true)" @blur="cancelInlineEdit(filteredTaskList, data.field.key)">
                                </b-col>
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value, false)" class="action-link">
                                <span v-if="data.value">{{ data.value | formatDateTimeAmPm }}</span>
                                <span v-else>Edit</span>
                            </div>
                        </template>
                        <template v-slot:cell(workHour)="data">
                            <div>{{convertToHM(data.item.estEffortMins)}}</div>
                        </template>
                        <template v-slot:cell(estimatedCost)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName  && data.item.assignedById == userId">
                                <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineDecimalValue('estimated_cost', data.item.id, data.value)" @blur="cancelInlineEdit(filteredTaskList, data.field.key)">
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value, false)" class="action-link">
                                <span v-if="data.value">{{ currencySymbol }}{{ data.value }}</span>
                                <span v-else>Edit</span>
                            </div>
                        </template>
                        <template v-slot:cell(assignedToId)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName  && data.item.assignedById == userId">
                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('assigned_to_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(filteredTaskList, data.field.key)">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in users" :value="info.id"
                                    >{{info.value}}</option>
                                </select>
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value, false)" class="action-link"> {{ data.item.assignedTo ? data.item.assignedTo : 'Select' }}</div>
                        </template>
                        <template v-slot:cell(requirement)="data">
                            <div class="action-link"><a class="tooltiptext action-link" href="javascript:" @click="viewRequirementDetails(data.item)">{{data.value}}</a></div>
                        </template>
                        <template v-slot:cell(statusId)="data">
                            <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName && data.item.assignedById == userId">
                                <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('status_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(filteredTaskList, data.field.key)">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in taskStatus" :value="info.id"
                                    >{{info.value}}</option>
                                </select>
                            </div>
                            <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value, false)" class="action-link"> {{ data.item.status ? data.item.status : 'Select' }}</div>
                        </template>
                        <template v-slot:cell(actions)="data">
                            <span v-if="data.item.assignedById == userId">
                                <a href="javascript:" class="mr-2" title="Edit" @click="showEditTask(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                                <a href="javascript:" class="mr-2" title="Delete" @click="deleteTaskList(data.item.id)" v-b-modal.deleteTaskList><i class="fa fa-trash"></i></a>
                                <a href="#" class="mr-2" title="Archive" @click="archiveTask(data.item)"><i class="fa fa-archive" aria-hidden="true"></i></a>
                            </span>
                            <span v-else title="No Action">NA</span>
                        </template>
                        <template v-slot:empty="scope">
                            <div class="text-center">No Record found</div>
                        </template>
                        <template v-slot:emptyfiltered="scope">
                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                        </template>
                    </b-table>
                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalAssignments}} Items</span>                    
                </div>
            </b-card>
        </b-overlay>
<!--         <AddErmMasterPopup id="addErmMasterPopup" ref="addErmMasterPopup" :ermMasterInfo="ermMasterInfo" @update-parent="getEntityErmList();lookupData();" /> -->
        <ManageErmMasterPopup id="manageErmMasterPopup" ref="manageErmMasterPopup" :ermMasterInfo="ermMasterInfo" @update-parent="getEntityErmList();lookupData();" @closeDialog="showDialog=false"/>     
        <ERMMasterPopup id="planBacklogErmPopup" v-if="showDialog" :ermMasterInfo="ermBacklogMasterInfo" @refresh-lookup="getEntityErmLookup" @closeDialog="showDialog=false" />

        <DeleteComponent id="deleteTaskList" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to the delete Task?" />
        <ReqSpecDetailPopup v-if="showDialog" ref="reqSpecDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <IssueDetailPopup v-if="showDialog" ref="issueDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="showDialog=false;" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="taskAssignMasterData" :projectTypeId="this.projectTypeId" :ermLookupList="quickTaskErmList" @refresh-task="getBacklogTaskList"  />
<!--         <QuickTaskPopup ref="ermAssignmentsRef" :masterData="taskAssignMasterData" :ermLookupList="quickTaskErmList" @refresh-task="getBacklogTaskList"  />
 -->        
        <QuickReqPopup v-if="showDialog" ref="quickReqPopupRef" :projectTypeId="this.projectTypeId" @refreshData="getErdPlanRequirements"  @masterLookupRefresh="getEntityErmLookup" :requirementData="requirementSpecPopupData" @closeDialog="closeDialog" />
        <QuickIssuePopup v-if="showDialog" ref="quickIssuePopupRef" @refreshData="getErdPlanRequirements" @masterLookupRefresh="getEntityErmLookup" :issueData="issuePopupData" @closeDialog="closeDialog" />
        <!-- <ReferenceView ref="viewReferenceRef" :erdId="referencePopupId" :refEntity="entity" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
// import AddErmMasterPopup from '@/components/popup/action/AddErmMasterPopup.vue';
import ManageErmMasterPopup from '@/components/popup/action/ManageErmMasterPopup.vue';

import Editor from '@tinymce/tinymce-vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import QuickReqPopup from '@/components/popup/action/QuickReqPopup.vue';
import QuickIssuePopup from '@/components/popup/action/QuickIssuePopup.vue';
// import ReferenceView from '@/components/common/ViewReferenceComponent.vue';
import ReqSpecDetailPopup from '@/components/popup/itemdetail/ReqSpecDetailPopup.vue';
import IssueDetailPopup from '@/components/popup/itemdetail/IssueDetailPopup.vue';
export default {
    props: {
        ermId: [String, Number],
        erdId: [String, Number],
        planData: Object,
    },
    components: {
        // AddErmMasterPopup,
        ManageErmMasterPopup,
        Editor,
        ERMMasterPopup,
        TaskDetailPopup,
        QuickTaskPopup,
        QuickReqPopup,
        QuickIssuePopup,
        // ReferenceView,
        ReqSpecDetailPopup,
        IssueDetailPopup,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entityRecordMasterId: localStorage.getItem("ermId"),
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),
            entity: 'requirement_spec',

            taskPlanTitle: 'Task Plan',

            showOverlay: false,
            elements: {
                editMode: false,
                showInlineControls: false,
                showErmAdd: false,
                showErmDropdown: false,
            },
            showDialog: false,
            isArchive: false,
            batchSelectCheckBox: false,

            options: [
                { label: 'All', value: '1' },
                { label: 'To Do', value: '2' },
                { label: 'In Progress', value: '3' },
                { label: 'Completed', value: '4' },
                { label: 'Verified', value: '5' },
                { label: 'Closed', value: '6' },
                { label: 'Others', value: '7' },
                // { label: 'Archived', value: '5' },
            ], // Your options array
            // options: ['All', 'Open', 'Completed', 'Closed','Archived'], // Your options array
            selectedOptions: [], // Array to hold selected options            

            taskOptions: [
                { label: 'All', value: '1' },
                { label: 'To Do', value: '2' },
                { label: 'In Progress', value: '3' },
                { label: 'Completed', value: '4' },
                { label: 'Verified', value: '5' },
                { label: 'Closed', value: '6' },
                { label: 'Others', value: '7' },
            ], // Your options array
            selectedTaskOptions: [], // Array to hold selected options            

            documentAddData: {},

            ermMasterInfo: {
                title: "Task Plan",
                entity: 'development_plan_master',
                refEntity: 'task',
                tooltip: 'New Task Plan',
                redirectURLName: 'task',
                newEntityMasterURL: 'dvpmas',
                redirectURL: '/dvpmas',
                actionName: 'Task List',
                previewURLName: 'tslrep',
                sharedUrlName:'task',
                showDateRange: true,
                showPlanLinkControl: true,
                masterLinkTitle: 'Master Plan Link',
                masterLinkEntity: 'plan_master',
                hideProject: true,
                showMultipleMasterControl: true,
            },

            ermBacklogMasterInfo: {
                title: "Task Plan",
                entity: 'development_plan_master',
                refEntity: 'task',
            },
            entityRecordMasterList: [],
            quickTaskErmList: [],
            selectedEffortEstimationList: [],
            requirementSpecEditId: '',
            refErmTitle: '',
            refErmId: '',
            planId: '',

            ermTaskPlanData: {
                ermTitle: '',
                entityId: '',
                entityRecordMasterId: '',
            },

            ermData: {
                ermTitle: '',
                entityId: '',
                entityRecordMasterId: '',
            },
            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',
            inlineEditFieldName2: '',
            inlineEditFieldValue2: '',
            inlineEditFieldName3: '',
            inlineEditFieldValue3: '',
            inlineEditData: {
                dateValue: '',
                timeValue: '',
            },

            detailViewData: {},
            referencePopupId: 0,
            attachmentData: {},

            entityRecordMasterData: {},

            refErdData:{
                id: '',
                ermId:'',
            },

            requirementSpecPopupData: {
                editMode: null,
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'requirement_spec',
                title: '',
                requirementTypeId: '',
                isArchive: false,
                requirementSpecification: '',
                priorityId: '',
                dueDate: new Date().toISOString().substr(0, 10),
                preparedById: localStorage.getItem("userId"),
                preparedDt: new Date().toISOString().substr(0, 10),
                statusId: this.statusId,
            },
            issuePopupData: {
                editMode: null,
                id: '',
                issueId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: localStorage.getItem("projectTypeId"),
                entityRecordMasterId: '',
                entity: 'issue',
                entityId: '',
                entityRefId: '',
                title: '',
                isArchive: false,
                issueTypeId: '',
                type: '',
                functionalityId: '',
                functionality: '',
                activityId: '',
                activity: '',
                description: '',
                solution: '',
                reportedById: localStorage.getItem("userId"),
                reportedBy: '',
                reportedDt: new Date().toISOString().substr(0, 10),
                solutionById: '',
                solutionBy: '',
                solutionDt: '',
                actionTaken: '',
                severityId: '',
                severity: '',
                statusId: '',
                status: '',
                solutionDt: ''
            },

            planRequirement: {
                id: '',
                planRequirementId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                planId: '',
                refEntityId: '',
                refErmId: '',
                refErdId: '',
                createdById: localStorage.getItem("userId"),
                createdBy: '',
            },

            taskData: {
                id: '',
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                userId: localStorage.getItem("userId"),
                entity: 'task',
                refEntity: '',
                title: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: this.getTimeValue(new Date()),
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: this.getTimeValue(new Date()),
                assignedById: localStorage.getItem("userId"),
                assignedToId: '',
            },

            taskAssignMasterData: {
                entityRecordMasterId: 0,
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',
                statusId: 1,
                assignedToId: '',
                activityId: '',
                estimatedCost: '',
                workHour: '',
                estimatedStartDate: '',
                estimatedEndDate: '',
            },

            selectedItems: [],
            erdLookup: [],
            users: [],
            taskStatus: [],
            taskTypes: [],
            taskList: [],
            planRequirementLookup: [],
            developmentPlanLookup: [],
            clients: [],
            refErmList: [],
            entityRecordCustomType: [],
            masterStatus: [],
            entityERMLookup: [],
            // planList: [],
            requirementSpecList: [],
            requirementSpecFields: [
                { key: 'selected', label: '', tdClass: 'table-status-col-size' },
                { key: 'index', label: 'SN' },
                // { key: 'requirementSpecId', label: 'Id', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc', editable: true  },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'requirementSpecification', label: 'Requirement Specification', tdClass: 'w-40', sortDirection: 'desc' },
                // { key: 'priority', label: 'Priority', sortable: true, sortDirection: 'desc' },
                { key: 'dueDate', label: 'Due Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'priority', label: 'Priority', sortable: true, sortDirection: 'desc' },
                // { key: 'effort', label: 'Est. Effort', tdClass: 'table-cw-300',  sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                // { key: 'planTitle', label: 'Plan Item Link' },
                { key: 'actions', label: 'Actions' }
            ],

            taskFields: [
                { key: 'index', label: 'SN',thClass:'position-relative' },
                { key: 'title', label: 'Task Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'planTitle', label: 'Plan Item', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'taskTypeId', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedStartDt', label: 'Start Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedEndDt', label: 'Due Date', formatter: 'formatDateTimeAmPm', sortable: true, sortDirection: 'desc' },
                { key: 'workHour', label: 'Effort', tdClass: 'table-cw-400', sortDirection: 'desc',thClass:'position-relative' },
                { key: 'estimatedCost', label: 'Cost', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'assignedBy', label: 'Assigned By', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'assignedToId', label: 'Assigned To', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'requirement', label: 'Ref Requirement', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                
                { key: 'statusId', label: 'Status', sortable: true, tdClass: 'table-cw-100', sortDirection: 'desc' },
                { key: 'actions', label: 'Actions',thClass:'position-relative' }
            ],

            toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            initObj: {
                height: 150,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            selectMode: 'multi',
            totalRows: 0,
            totalAssignments: 0,
            filter: null,
            filterOn: [], 
            taskFilter: null,                         
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            deleteParam: {
                id: 0
            }
        }
    },
    validations: {
        ermTaskPlanData: {
            entityRecordMasterId: {
                required,
            },
            ermTitle: {
                required: requiredIf(function () {
                    return !this.elements.showErmDropdown;
                }),
            }
        },

        ermData: {
            entityRecordMasterId: {
                required: requiredIf(function () {
                    return this.elements.showErmDropdown;
                }),
            },
            ermTitle: {
                required: requiredIf(function () {
                    return !this.elements.showErmDropdown;
                }),
            }
        },
        taskData: {
            entityRecordMasterId: {
                required,
            },
        },
        
    },
    computed: {
        requirementSpecs() {
            
            let items = [];            

            if(this.selectedOptions==''){
                items = this.requirementSpecList;
            }else{

                // alert(this.selectedOptions);
                // const numbersArray = this.selectedOptions.split(',');
                const numbersArray = this.selectedOptions;
                let numValue=0;


                for (let i = 0; i < numbersArray.length; i++) {
                    let numValue=numbersArray[i];
                    if (numValue==2) {
                        items = this.requirementSpecList.filter(data => data.statusLookupId == 1 || data.statusLookupId == 2 || data.statusLookupId == 3);
                    }
                    else if (numValue==3) {
                        items = this.requirementSpecList.filter(data => data.statusLookupId == 4);
                    }
                    else if (numValue==4) {
                        items = this.requirementSpecList.filter(data => data.statusLookupId == 5);
                    }
                    else if (numValue==5) {
                        items = this.requirementSpecList.filter(data => data.statusLookupId == 6);
                    }
                    else if (numValue==6) {
                        items = this.requirementSpecList.filter(data => data.statusLookupId == 7);
                    }
                    else if (numValue==7) {
                        items = this.requirementSpecList.filter(data => data.statusLookupId == 8 || data.statusLookupId == 9);
                    }
                    else {
                        items = this.requirementSpecList;
                    }

                }
            }

            this.totalRows = items.length;
            return items;
        },

        filteredTaskList() {

            // alert(this.selectedTaskOptions);
            let items = [];
            if (this.planId) {
                items = this.taskList.filter(data => data.refErdId == this.planId);
            }
            else {
                items = this.taskList;
            }

            // let items = [];            
            console.log("ITM",items);

            if(this.selectedTaskOptions==''){
                items = items;
            }else{
                const numbersArray = this.selectedTaskOptions;
                let numValue=0;


                for (let i = 0; i < numbersArray.length; i++) {
                    let numValue=numbersArray[i];
                    if (numValue==2) {
                        items = items.filter(data => data.statusLookupId == 1);
                    }
                    else if (numValue==3) {
                        items = items.filter(data => data.statusLookupId == 2);
                    }
                    else if (numValue==4) {
                        items = items.filter(data => data.statusLookupId == 3);
                    }
                    else if (numValue==5) {
                        items = items.filter(data => data.statusLookupId == 4);
                    }
                    else if (numValue==6) {
                        items = items.filter(data => data.statusLookupId == 5);
                    }
                    else if (numValue==7) {
                        items = items.filter(data => data.statusLookupId == 6 || data.statusLookupId == 7);
                    }
                    else {
                        items = items;
                    }

                }
            }

            this.totalAssignments = items.length;
            return items;
        },
        totalEffort() {
            if(!this.selectedEffortEstimationList) return 0;
            return this.selectedEffortEstimationList.reduce((accum, item) => {
                return accum + (item.effortHour ?? 0)
            }, 0)
        },
        getEntity() {
            let resultVal = '';
            if (this.ermData.entityId) {
                let item = this.planRequirementLookup.find(data => data.id == this.ermData.entityId);
                resultVal = item ? item.entity : '';
            }
            return resultVal;
        },
        getEntityName() {
            let resultVal = '';
            if (this.ermData.entityId) {
                let item = this.planRequirementLookup.find(data => data.id == this.ermData.entityId);
                resultVal = item ? item.value : 'Document';
            }
            return resultVal;
        },
        getReqDocumentName() {
            let resultVal = '';
            if (this.ermData.entityId) {
                let item = this.entityERMLookup.find(data => data.id == this.ermData.entityRecordMasterId);
                resultVal = item ? item.value : '';
            }
            return resultVal;
        },
        commonErdLookup() {
            if(this.entityRecordMasterData.projectId && this.entityRecordMasterData.projectId != '') {
                return this.erdLookup.filter((data) => data.projectId == this.entityRecordMasterData.projectId);
            } else {
                return this.erdLookup;
            }
        },
        getErmTypeCode() {
            if (this.entityRecordCustomType.length>0) {
                let typeData = this.entityRecordCustomType.find((data) => data.id == this.entityRecordMasterData.ermTypeId);
                return typeData ? typeData.typeCode : null;
            } else return null;
        },
    },
    mounted() {
        this.lookupData();
        this.masterLookupData();
        this.planMasterLinkLookupData();
        this.getEntityErmList();
        // this.getPlans();
        this.getBacklogTaskList();
        this.planId = (this.planData && this.planData.id) ? this.planData.id : '';
    },
    methods: {
        getEntityErmList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
                isArchive: false,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.entityRecordMasterList = result.entityRecordMasterList;

                    if(!this.entityRecordMasterId){
                        this.entityRecordMasterId = this.entityRecordMasterList && this.entityRecordMasterList.length>0 ? this.entityRecordMasterList[0].id : '';
                    }

                    this.ermTaskPlanData.entityRecordMasterId = this.entityRecordMasterId;

                    this.onTaskPlanErmChange()

                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                projectTypeId: this.projectTypeId,
                refErmId: this.ermId,
                refErdId: this.erdId,
                entity: 'task',
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/planbacklog/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.taskTypes = result.customTypeLookup;
                    this.planRequirementLookup = result.planRequirementLookup;
                    this.users = result.userLookup;
                    this.taskStatus = result.statusLookup;

                })
        },
        archiveTask: function(item) {
            let data = {
                id: item.id,
                entity: 'task',
                columnName: 'is_archive',
                stateValue: (item.isArchive == true ? false : true)
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.getBacklogTaskList();
                    }
                })
        },
        masterLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.clientLookup;
                    this.entityRecordCustomType = result.typeLookup;
                    this.masterStatus = result.statusLookup;
                    console.log(result);
                })

        },
        onTaskPlanErmChange() {
            if(this.ermTaskPlanData.entityRecordMasterId){
                console.log(this.ermTaskPlanData.entityRecordMasterId)
                this.viewEntityRecordMaster();
                this.setERMData();
                this.elements.editMode = false;

            }
        },
        setERMData: function() {
            console.log("selERMID",this.ermTaskPlanData.entityRecordMasterId);
            localStorage.setItem('ermId', this.ermTaskPlanData.entityRecordMasterId);
            localStorage.setItem('ermTitle', this.ermTaskPlanData.title);
        },
        viewEntityRecordMaster: function() {
            if(this.ermTaskPlanData.entityRecordMasterId) {
                let data = {
                    entityRecordMasterId: this.ermTaskPlanData.entityRecordMasterId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/view', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.entityRecordMasterData = result.entityRecordMaster;
                        // this.handleErmTypeChange();
                        this.entityRecordMasterData.refErmId = this.entityRecordMasterData.refErmId ? this.entityRecordMasterData.refErmId : null;

                        if(this.ermMasterInfo.showPlanLinkControl){
                            this.refErdData.ermId = this.entityRecordMasterData.refErmId;
                            this.refErdData.id = this.entityRecordMasterData.refErdId;
                            this.getBacklogTaskList();
                        }else{
                            this.refErdData.ermId = this.entityRecordMasterData.refErmId;
                            this.refErdData.id = this.entityRecordMasterData.refErdId;
                            this.getBacklogTaskList();
                        }     

                        console.log(this.entityRecordMasterData);
                        
    
                    })
                    .catch((error) => this.showOverlay = false)
            }
        },        
        getBacklogTaskList() {
            if (this.ermTaskPlanData.entityRecordMasterId) {
                let data = {
                    entityRecordMasterId: this.ermTaskPlanData.entityRecordMasterId,
                    isArchive: this.isArchive,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/task/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.taskList = result.taskList;
                        this.totalAssignments = this.taskList.length;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        planMasterLinkLookupData: function() {
            if (this.ermMasterInfo.masterLinkEntity && this.ermMasterInfo.showPlanLinkControl) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    entity: this.ermMasterInfo.masterLinkEntity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/plan/planmasterdetail/lookup/all', data)
                    .then((response) => {
                        let result = response.data;
                        this.erdLookup = result.entityERMLookup;
                    })
            }
        },
        deleteTaskList: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'task',
                id: this.deleteParam.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/delete', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: result.message,
                            // message: result.message,
                            position: 'topRight'
                        });
                        this.getBacklogTaskList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getEntityErmLookup: function() {
            if (this.ermData.entityId) {
                this.ermData.entityRecordMasterId = '';
                this.requirementSpecList = [];

                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    userId: this.userId,
                    entityId: this.ermData.entityId,
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.entityERMLookup = result.entityERMLookup;
                        // this.totalRows = this.requirementSpecList.length;
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
        },
        getErdPlanRequirements: function() {
            if (this.getEntity && this.ermData.entityRecordMasterId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    userId: this.userId,
                    entity: this.getEntity,
                    entityRecordMasterId: this.ermData.entityRecordMasterId,
                }

                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/plan/erdrequirements/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        
                        

                        if (this.getEntity == "system_requirement_spec_master") {
                            this.requirementSpecList = result.requirementAssignmentSummary;

                        } else if (this.getEntity == "issue_master") {
                            this.requirementSpecList = result.issueAssignmentSummary;
                        }
                        console.log("ITEMS",this.requirementSpecList);
                    })
                    .catch((error)=> {
                        this.showOverlay = false;
                    })
            }
        },
        updateInlineNumberValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("vhextAccountId"),
                entity: 'task',
                columnName: columnName,
                numberValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/update/numbervalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getBacklogTaskList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity:  'task',
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/decimalnumvalue/update', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getBacklogTaskList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        updateInlineDateValue: function(columnName, rowId, fieldValue, isDateTime) {
            if (isDateTime) {
                if (!this.inlineEditData.dateValue) return;
                fieldValue = this.combineDateAndTime(this.inlineEditData.dateValue, this.inlineEditData.timeValue);
            }
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity:  'task',
                columnName: columnName,
                dateValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/datevalue/update', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getBacklogTaskList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        updateInlineDecimalValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity:  'task',
                columnName: columnName,
                numberValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/decimalnumvalue/update', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getBacklogTaskList();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue, isDateTime) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            if (isDateTime) {
                this.inlineEditData.dateValue = fieldValue ? this.getDateValue(fieldValue) : '';
                this.inlineEditData.timeValue = fieldValue ? this.getTimeValue(fieldValue) : '';
            }
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId = '';
            this.inlineEditFieldValue = '';
            this.inlineEditFieldName = '';
            this.inlineEditData.dateValue = '';
            this.inlineEditData.timeValue = '';
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        viewRequirementDetails: function(data) {
            this.referencePopupId = data.requirementId;
            this.entity = 'requirement_spec';
            this.showDialog = true;
            this.$nextTick(() => {
                    this.$refs.reqSpecDetailPopupRef.getDetails(this.referencePopupId, this.entity);
                })
        },

        assignTask: function(data) {
            this.quickTaskErmList = null;
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.taskAssignMasterData.entityRecordMasterId = this.ermTaskPlanData.entityRecordMasterId;
                this.taskAssignMasterData.refErmId = data.entityRecordMasterId;
                this.taskAssignMasterData.refErdId = data.id;
                this.taskAssignMasterData.refEntityId = '';
                this.taskAssignMasterData.refEntity = data.entity;
                this.taskAssignMasterData.refErdTitle = data.title;
                this.taskAssignMasterData.statusId = 1;
                this.taskAssignMasterData.refErdDescription = data.description;
                if (this.planData && this.planData.id) {
                    this.taskAssignMasterData.activityId = this.planData.activityId;
                    this.taskAssignMasterData.estimatedCost = this.planData.estimatedCost;
                    this.taskAssignMasterData.workHour = this.planData.effortHour;
                    this.taskAssignMasterData.estimatedStartDate = this.planData.startDate;
                    this.taskAssignMasterData.estimatedEndDate = this.planData.endDate;
                }
                this.$refs.ermAssignmentsRef.onRefDataChange();
                this.getErdPlanRequirements();
            })
        },
        assignBulkTask() {
            const taskList = [];
            this.selectedItems.forEach(data => {
                taskList.push({
                    title: data.title,
                    statusId: this.getStatusId(1),
                    description: data.description,
                    entityId: '',
                    refEntity: data.entity,
                    refErdTitle: data.title,
                    refErmId: data.entityRecordMasterId,
                    refErdId: data.id,
                });
            });
            // alert(data.entity);
            // console.log(data.entity);

            this.taskData.entityRecordMasterId= this.ermTaskPlanData.entityRecordMasterId;
            this.taskData.activityId = this.entityRecordMasterData.activityId;
            this.taskData.estimatedCost = this.entityRecordMasterData.estimatedCost;
            this.taskData.workHour = this.entityRecordMasterData.effortHour;
            this.taskData.estimatedStartDt = this.entityRecordMasterData.startDate;
            this.taskData.estimatedEndDt = this.entityRecordMasterData.endDate;
            this.taskData.taskList = taskList;
            console.log(this.taskData);
            console.log(this.taskAssignMasterData);
            // return;
            this.$v.taskData.$touch();
            if (!this.$v.taskData.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/task/bulktask/add', this.taskData)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getBacklogTaskList();
                            this.$v.taskData.$reset();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        quickTask: function() {
            this.quickTaskErmList = null;
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.taskAssignMasterData.id = '';
                this.taskAssignMasterData.statusId = 1;
                this.taskAssignMasterData.entityRecordMasterId = this.ermTaskPlanData.entityRecordMasterId;
                this.taskAssignMasterData.projectId = this.projectId;
                this.$refs.ermAssignmentsRef.onRefDataChange('Quick Task');
            })
        },
        showEditTask: function(data) {
            this.quickTaskErmList = null;
            data.projectId = this.projectId;
            this.$bvModal.show('ermAssignments');
            this.$nextTick(() => {
                this.$refs.ermAssignmentsRef.showEdit(data);
            })
        },

        getStatusId(statusLookupId) {
            if (this.taskStatus.length > 0) {
                let status = this.taskStatus.find(status => status.statusLookupId == statusLookupId);
                return status ? status.id : this.taskStatus[0].id;
            }
        },
        onRowSelected(items) {
            console.log(items);
            this.selectedItems = items;
            this.batchSelectCheckBox = false;
        },
        handleBatchSelect() {
            if (this.batchSelectCheckBox) {
                this.$refs.planBacklogTable.clearSelected()
            } else {
                this.$refs.planBacklogTable.selectAllRows()
            }
        },
        handleRowClick(index) {
            this.batchSelectCheckBox = false;
            if (this.$refs.planBacklogTable.isRowSelected(index)) {
                this.$refs.planBacklogTable.unselectRow(index);
            }
            else {
                this.$refs.planBacklogTable.selectRow(index);
            }
        },
        openNewDocumentAddPopup: function(data, flag) {
            if (this.getEntity == 'system_requirement_spec_master') {
                this.openRequirementAddEditPopup(data, flag);
            }
            else if (this.getEntity == 'issue_master') {
                this.openIssueAddEditPopup(data, flag);
            }
        },
        openRequirementAddEditPopup(data, flag) {
            if (flag == true) {
                this.requirementSpecPopupData.id = data.id;
                this.requirementSpecPopupData.requirementSpecId = data.requirementSpecId;
                this.requirementSpecPopupData.title = data.title;
                this.requirementSpecPopupData.requirementTypeId = data.requirementTypeId;
                this.requirementSpecPopupData.requirementSpecification = data.requirementSpecification;
                this.requirementSpecPopupData.entityRecordMasterId = data.entityRecordMasterId;
                this.requirementSpecPopupData.isArchive = data.isArchive;
                this.requirementSpecPopupData.preparedById = data.preparedById;
                this.requirementSpecPopupData.preparedDt = data.preparedDt;
                this.requirementSpecPopupData.priorityId = data.priorityId;
                this.requirementSpecPopupData.dueDate = data.dueDate;
                this.requirementSpecPopupData.statusId = data.statusId;
            } else {
                this.requirementSpecPopupData.id = '';
                this.requirementSpecPopupData.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.requirementSpecPopupData.projectId = localStorage.getItem("projectId");
                this.requirementSpecPopupData.userId = localStorage.getItem("userId");
                this.requirementSpecPopupData.entity = 'requirement_spec';
                this.requirementSpecPopupData.title = '';
                this.requirementSpecPopupData.requirementTypeId = '';
                this.requirementSpecPopupData.requirementSpecification = '';
                this.requirementSpecPopupData.isArchive = false;
                this.requirementSpecPopupData.priorityId = '';
                this.requirementSpecPopupData.dueDate = new Date().toISOString().substr(0, 10);
                this.requirementSpecPopupData.preparedById = localStorage.getItem("userId");
                this.requirementSpecPopupData.preparedDt = new Date().toISOString().substr(0, 10);
                this.requirementSpecPopupData.statusId = '';
                this.requirementSpecPopupData.entityRecordMasterId = '';
            }
            this.requirementSpecPopupData.editMode = flag;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$bvModal.show('quickReqPopup');
            })
        },
        openIssueAddEditPopup(data, flag) {
            if (flag == true) {
                this.issuePopupData.id = data.id;
                this.issuePopupData.issueId = data.issueId;
                this.issuePopupData.vhextAccountId = data.vhextAccountId;
                this.issuePopupData.projectId = data.projectId;
                this.issuePopupData.entityRecordMasterId = data.entityRecordMasterId;
                this.issuePopupData.title = data.title;
                this.issuePopupData.isArchive = data.isArchive;
                this.issuePopupData.issueTypeId = data.issueTypeId;
                this.issuePopupData.type = data.type;
                this.issuePopupData.activityId = data.activityId;
                this.issuePopupData.activity = data.activity;
                this.issuePopupData.functionalityId = data.functionalityId;
                this.issuePopupData.functionality = data.functionality;
                this.issuePopupData.description = data.description;
                this.issuePopupData.solution = data.solution;
                this.issuePopupData.actionTaken = data.actionTaken;
                this.issuePopupData.reportedById = data.reportedById;
                this.issuePopupData.reportedBy = data.reportedBy;
                this.issuePopupData.reportedDt = data.reportedDt;
                this.issuePopupData.solutionById = data.solutionById;
                this.issuePopupData.solutionBy = data.solutionBy;
                this.issuePopupData.solutionDt = data.solutionDt;
                this.issuePopupData.reportedDt = data.reportedDt;
                this.issuePopupData.severityId = data.severityId;
                this.issuePopupData.severity = data.severity;
                this.issuePopupData.statusId = data.statusId;
                this.issuePopupData.status = data.status;
            } else {
                this.issuePopupData.id = '';
                this.issuePopupData.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.issuePopupData.projectId = localStorage.getItem("projectId");
                this.issuePopupData.userId = localStorage.getItem("userId");
                this.issuePopupData.entity = 'issue';
                this.issuePopupData.title = '';
                this.issuePopupData.isArchive = false;
                this.issuePopupData.issueTypeId = '';
                this.issuePopupData.description = '';
                this.issuePopupData.severityId = '';
                this.issuePopupData.reportedById = localStorage.getItem("userId");
                this.issuePopupData.reportedDt = new Date().toISOString().substr(0, 10);
                this.issuePopupData.statusId = '';
                this.issuePopupData.entityRecordMasterId = '';
            }
            this.issuePopupData.editMode = flag;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('quickIssuePopup');
            })
        },
        viewBacklogDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            if (this.getEntity == 'system_requirement_spec_master') {
                this.attachmentData.refEntity = 'requirement_spec';
                this.$nextTick(() => {
                    this.$refs.reqSpecDetailPopupRef.getDetails(this.referencePopupId, null);
                })
            }
            else if (this.getEntity == 'issue_master') {
                this.attachmentData.refEntity = 'issue'
                this.$nextTick(()=> {
                    this.$refs.issueDetailPopupRef.getDetails(this.referencePopupId, null);
                })
            }
        },
        openBacklogErmAddPop() {
            this.ermBacklogMasterInfo.title = this.getEntityName;
            this.ermBacklogMasterInfo.entity = this.getEntity;
            if (this.getEntity == 'system_requirement_spec_master') {
                this.ermBacklogMasterInfo.refEntity = 'requirement_spec';
            }
            else if (this.getEntity == 'issue_master') {
                this.ermBacklogMasterInfo.refEntity = 'issue'
            }
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('planBacklogErmPopup');
            })
        },
        closeDialog() {
            // this.selectedEffortEstId = '';
            this.$emit('closeDialog');
        },
        updateERMaster: function() {
            if (this.ermMasterInfo.showDateRange) {
                this.entityRecordMasterData.startDate = this.entityRecordMasterData.startDate ? this.entityRecordMasterData.startDate : '';
                this.entityRecordMasterData.endDate = this.entityRecordMasterData.endDate ? this.entityRecordMasterData.endDate : '';
            }
            if (this.ermMasterInfo.showErmDate) {
                this.entityRecordMasterData.ermDate = this.entityRecordMasterData.ermDate + "-11";
            }
            if (this.ermMasterInfo.showMultipleMasterControl) {
                this.entityRecordMasterData.refErmList = [];
                this.refErmList.forEach((item, index) => {
                    this.entityRecordMasterData.refErmList.push({ refErmId: this.refErmList[index].id })
                })
            }
            else {
                this.entityRecordMasterData.refErmList = [];
            }
            if(this.ermMasterInfo.showPlanLinkControl){
                this.entityRecordMasterData.refErmId = this.refErdData.ermId;
                this.entityRecordMasterData.refErdId = this.refErdData.id;
            }            
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMasterData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.cancelEdit();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateEffort: function(rowId, controlRefId) {
            let workDay = this.$refs[controlRefId].getDays();
            let workHour = this.$refs[controlRefId].getHrs();
            let workMinute = this.$refs[controlRefId].getMins();
            let data = {
                id: rowId,  
                workDay: workDay ? workDay : '',
                workHour: workHour ? workHour : '',
                workMinute: workMinute ? workMinute : '',
            }
            if (data.workDay == '' && data.workHour == '' && data.workMinute == '') {
                iziToast.info({
                   message: 'Effort is required',
                   position: 'topCenter'
                });
                return;
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/issue/effort/update', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId= '';
                        this.inlineEditFieldName = '';
                        this.getErdPlanRequirements();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        focusEffortEdit(cellId, rowId, fieldName, data, controlRefId) {
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = data.workDay;
            this.inlineEditFieldName = fieldName;

            this.inlineEditFieldValue2 = data.workHour;
            this.inlineEditFieldName2 = 'workHour';

            this.inlineEditFieldValue3 = data.workMinute;
            this.inlineEditFieldName3 = 'workMinute';

            this.$refs[controlRefId].setEffortInputData(data.workDay,data.workHour,data.workMinute);

            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEffortEdit: function(items, controlRefId) {
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data['workDay'] = this.inlineEditFieldValue;
                    data['workHour'] = this.inlineEditFieldValue2;
                    data['workMinute'] = this.inlineEditFieldValue3;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.elements.empUtilEditMode = false;
            this.inlineEditRowId = '';
            this.inlineEditFieldValue = '';
            this.inlineEditFieldName = '';
            this.inlineEditFieldValue2 = '';
            this.inlineEditFieldName2 = '';
            this.inlineEditFieldValue3 = '';
            this.inlineEditFieldName3 = '';
            this.$nextTick(() => {
                this.$refs[controlRefId].setEffortInputData('', '', '');
            })
        },
        handleCheckboxChange(selOption) {
        },        
        // handleTaskCheckboxChange(selOption) {
        // },        

        vmxTotalEffortInput:function(data){
            this.$refs.totalEffortControl.setEffortInputData(data.day, data.hrs, data.mins);
        },
        cancelEdit: function() {
            this.viewEntityRecordMaster();
            this.elements.editMode = false;
        },
    }
}
</script>