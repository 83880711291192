<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="assetDetailPopupRef" size="lg" modal-class="" title="Asset" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0 mb-1">
                                <div class="col-lg-12 col-xl-12">
                                    <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-10 col-sm-9 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.assetId }}</label>
                                            </div>
                                            <div class="col-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                            <div class="col-1 pr-0">
                                                <label class="mb-0 p-0 pt-2 pb-2 d-block title-block"><a title="Cost"><i class="fa fa-calendar-check-o" aria-hidden="true"></i></a></label>
                                            </div>
                                            <div class="col-3 pl-0 pr-0">
                                                <label class="p-2 mb-0 header-details font-weight-bold" title="Cost">{{ currencySymbol }} {{ popupData.cost }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Model Number</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.modelNumber}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Brand</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.brand}}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Category</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.assetCategory}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.assetType}}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Purchased By</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.purchasedBy}}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Purchase Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.purchaseDate | formatDate}}</label>
                                            </div>                                        
                                        </div>
                                    </div>

                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="card mt-2 mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Mfg Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.mfgDate }}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Expiry Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.expDate | formatDate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Responsible Person</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.responsiblePerson }}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Warranty Period</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">
                                                    <span v-if="popupData.warrantyYear">{{ popupData.warrantyYear }} years</span>
                                                    <span v-if="popupData.warrantyMonth">{{ popupData.warrantyMonth }} months</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="attachmentDocsLength > 0" class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                                            </div>
                                            <div class="col-12 col-md-10 pl-0">
                                                <div class="col-12">
                                                    <div class="mt-2">
                                                        <AttachmentView ref="viewAttachmentDocRef" @getAttachmentLength="getAttachmentDocsLength" showDocsOnly :attachmentData="attachmentData" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="attachmentLinksLength > 0" class="col-12 col-sm-12 px-0">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reference Links</label>
                                            </div>
                                            <div class="col-12 col-md-10 pl-0">
                                                <div class="col-12">
                                                    <div class="mt-2">
                                                        <AttachmentView ref="viewAttachmentLinkRef" @getAttachmentLength="getAttachmentLinksLength" showLinkOnly :attachmentData="attachmentData" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object,
        id:{type:String, default:'assetDetailPopup'},
    },
    components: {
        AttachmentView
    },
    data() {
        return {
            loading: true,
            popupData: {},
            currencySymbol: localStorage.getItem("currency"),

            attachmentDocsLength: 0,
            attachmentLinksLength: 0,
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            });
            this.loading = true;
            console.log(id, refEntity);
            if (refEntity === "asset") {
                axios.get(this.$store.getters.getAPIBasePath + '/company/asset/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.popupData = result.assetView;
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.loading = false;
            }
        },
        getAttachmentDocsLength(length) {
            this.attachmentDocsLength = length;
        },
        getAttachmentLinksLength(length) {
            this.attachmentLinksLength = length;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>