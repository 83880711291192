<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <PlanReport />
            </div>
        </div>
    </div>
</template>
<script>
import PlanReport from '@/views/report/doc/PlanReport.vue';
export default {
    components: {
        PlanReport
    }
}
</script>