<template>
    <div>
        <b-modal ref="emailShareModal" size="md" :id="id" centered  @show="clearData" :title="emailShareModalTitle" no-enforce-focus :no-close-on-backdrop="true">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.emailShare.userList.$error }">
                                <multiselect v-model="emailShare.userList" :options="users" name="users" label="userPosition" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="$v.emailShare.userList.$error" class="invalid-feedback">
                                <span v-if="!$v.emailShare.userList.checkNull" class="text-danger">Please select User.</span>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="12">   
                            <div class="required-field-block" :class="{ 'is-invalid': $v.emailShare.title.$error }">                         
                            <input type="text" v-model="emailShare.title" class="form-control" maxlength="45" placeholder="Enter Title" :class="{ 'is-invalid': $v.emailShare.userList.$title }" >
                            <div class="required-icon"></div>
                            </div>
                             <div v-if="$v.emailShare.title.$error" class="invalid-feedback">
                                <span v-if="!$v.emailShare.title.required" class="text-danger">Please enter Title.</span>
                            </div>                            
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="12">
                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="emailShare.content"></editor>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="12">
                            <b-form-checkbox class="" v-model="emailShare.attachment" name="check-button" >Attachment
                            </b-form-checkbox>                            
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveShare" class="float-right py-1 px-3 mr-2">Share</b-button>
                    <b-button variant="secondary" :disabled="showOverlay" size="sm" class="float-right py-1 px-3 mr-2" @click="cancel()">Cancel</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import Editor from '@tinymce/tinymce-vue';
export default {
    props: {
        id: { type: String, default: 'emailShareModal' },
        masterData: Object,
        entity: String,
        emailShareModalTitle: {
            type: String,
            default: 'Email Share'
        }
    },
    components: {
        Multiselect,
        Editor
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                typeVisible: false,
                typeEdit: false
            },
            vhextAccountId: '',
            projectId: '',
            usersList: [],
            emailShare: {
                userList:[],
                attachment:'',
                title:'',
                content:'',
                sharedById:'',
                entityRecordMasterId:'',
                refEntityId:'',
                file:''
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,                
            },
        }
    },
    validations: {
        emailShare: {
            userList: {
                checkNull: function(userList) {
                    return userList.length > 0
                }
            },
             title: {
                required
            }
        }
    },
    computed: {
        users() {
            return this.usersList.map(user => ({ ...user, label: (user.userName + (user.position ? ' - ' + user.position : ''))}));
        }
    },
    watch: {
        'masterData.content': {
            handler: function(val1, val2) {  
                this.onRefDataChange();
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.emailShare.sharedById = localStorage.getItem("userId");
            this.emailShare.vhextAccountId = this.vhextAccountId;
            this.emailShare.projectId = this.projectId;
            this.emailShare.entity = this.entity;
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/getuser', data)
                .then((response) => {
                    let result = response.data;
                    this.usersList = result.userLookup;
                })
        },
        saveShare: function() {
            this.$v.emailShare.$touch();
            if (this.$v.emailShare.$invalid) {
                return;
            }
            console.log(this.emailShare); 
            //return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/email/share/document', this.getParameter())
                .then((response) => {
                    this.showOverlay = false;
                    console.log(response.data.status);
                    if (response.data) {
                        this.$bvModal.hide(this.id);
                        iziToast.success({
                            title: 'Success',
                            message: 'Document Shared.',
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },

        clearData: function() {
            this.emailShare.userList = [];
            //this.emailShare.content = '';
            //this.emailShare.title = '';
            this.$v.emailShare.userList.$reset();
        },
        onRefDataChange: function() {
            this.emailShare.vhextAccountId = this.vhextAccountId;
            this.emailShare.projectId = this.projectId;
            this.emailShare.sharedById = localStorage.getItem("userId");

            // this.ermId = this.masterData.refErmId;  
            //this.emailShare.entityRecordMasterId = this.masterData.refErmId;                      
            this.emailShare.content = this.masterData.content;            
            this.emailShare.title = this.masterData.title; 
            this.emailShare.file = this.masterData.file; 
            //this.emailShare.entity = this.masterData.refEntity;
            console.log(this.emailShare);
        },
        getParameter: function(){
            console.log(this.getCommaSeperatedString(this.emailShare.userList));
            let formData = new FormData();
            formData.append('content', this.emailShare.content);
            formData.append('title', this.emailShare.title);
            if(this.emailShare.attachment){
                formData.append('file', this.emailShare.file, 'requirementspec.pdf');
            }
            formData.append('userList', this.getCommaSeperatedString(this.emailShare.userList));   
            return formData;    
        }

    }
}
</script>