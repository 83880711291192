<template>
    <div>
        <b-table show-empty small outlined head-variant="primary" sticky-header="65vh" tbody-tr-class="align-items-center" :select-mode="selectMode" sort-icon-left :items="items" @row-selected="onRowSelected" :fields="fields" responsive="sm" :filter="filterModel" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>
            <template #cell(name)="data">
                <div class="row no-gutters align-items-center">
                    <div class="col-auto">
                        <b-avatar v-if="data.item.photo" class="mr-2" :src="data.item.photo"></b-avatar>
                        <b-avatar v-else class="mr-2"></b-avatar>
                    </div>
                    <div class="col">
                        <div class="mr-auto">{{ data.value }}</div>
                        <div class="mr-auto text-muted">{{ data.item.designation }}</div>
                    </div>
                </div>
            </template>
            <template v-slot:cell(workShiftId)="data">
                <div v-if="data.item.workingDayId" >{{ data.item.workShift }}
                </div>
                <div v-else class="" >
                    <span v-if="data.item.workShiftId">
                        <a href="javascript:" @click.prevent="openWorkingDay(data.item.workShiftId)" class="action-link" v-b-modal.setWorkingDayPopup variant="outline-secondary">Daily Work Shift not Started for "{{ data.item.workShift }}"</a>
                    </span>
                    <span v-else class="text-warning">
                        Work Shift not assigned for "{{ data.item.name }}"
                    </span>
                </div>   
            </template>
            <template v-slot:cell(present)="data">
                <b-form-checkbox v-if="data.item.workingDayId" class="ml-3" v-model="data.item.statusLookupId" value="4" unchecked-value="null" @change="editEmpLeaveType(data.item, $event)">
                </b-form-checkbox>
            </template>
            <template v-slot:cell(leaveFirstHalf)="data">
                <b-form-checkbox v-if="data.item.workingDayId" label="Leave First Half" class="ml-3" v-model="data.item.statusLookupId" value="1" unchecked-value="null" @change="editEmpLeaveType(data.item, $event)">
                </b-form-checkbox>
                <div v-else></div>
            </template>
            <template v-slot:cell(leaveSecondHalf)="data">
                <b-form-checkbox v-if="data.item.workingDayId" title="Leave Second Half" class="ml-3" v-model="data.item.statusLookupId" value="2" unchecked-value="null" @change="editEmpLeaveType(data.item, $event)">
                </b-form-checkbox>
                <div v-else></div>
            </template>
            <template v-slot:cell(leave)="data">
                <b-form-checkbox v-if="data.item.workingDayId" class="ml-3" v-model="data.item.statusLookupId" value="3" unchecked-value="null" @change="editEmpLeaveType(data.item, $event)">
                </b-form-checkbox>
                <div v-else></div>
            </template>
            <template v-slot:cell(empOvertime)="data">
                <template v-if="editId==data.item.humanResourceId">
                    <b-input-group size="sm" append="hrs">
                        <b-form-input type="number" v-model="data.value"></b-form-input>
                    </b-input-group>
                    <div class="text-right">
                        <a href="javascript:" @click="saveEmpOvertimeData(data.value)" class="text-success" ><i class="fa fa-check pr-2"></i></a>
                        <a href="javascript:" @click="clearData" class="text-danger" ><i class="fa fa-times pr-2"></i></a>
                    </div>
                </template>
                <div v-else class="text-center">
                    <a v-if="data.value" href="javascript:" title="Overtime" @click="editEmpOvertime(data.item, data.item.statusLookupId)" class="action-link" >{{ data.value }} hrs</a>
                    <a v-else href="javascript:" title="Add Overtime" @click="editEmpOvertime(data.item, data.item.statusLookupId)" class="action-link" ><i class="fa fa-plus-circle pr-1"></i>OT</a>
                </div>
            </template>
            <template v-slot:cell(status)="data">
                <div class="text-center">
                    <span v-if="data.item.statusLookupId == 4" class="badge badge-success">{{ data.value }}</span>
                    <span v-else-if="data.item.statusLookupId == 3" class="badge badge-danger">{{ data.value }}</span>
                    <span v-else-if="data.item.statusLookupId" class="badge badge-warning">{{ data.value }}</span>
                    <span v-if="data.item.empLop == 0.5" class="badge badge-info ml-1">LOP Half Day</span>
                    <span v-if="data.item.empLop == 1" class="badge badge-info ml-1">LOP Full Day</span>
                </div>
            </template>
            <template v-slot:cell(empStatus)="data">
                <div class="text-center">
                    <span v-if="data.item.empStatusLookupId == 4" class="badge badge-success">{{ data.value }}</span>
                </div>
            </template>
            <template v-slot:cell(actions)="data">
                <button v-if="data.item.statusLookupId == 1 || data.item.statusLookupId == 2 || data.item.statusLookupId == 3" type="submit" class="sbtn btn btn-xs btn-outline-primary px-2 mr-1" title="Edit Leave Type" @click="editEmpLeaveType(data.item, data.item.statusLookupId)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                <button v-if="data.item.leaveTypeId" type="submit" class="sbtn btn btn-xs btn-outline-primary px-2 mr-1" title="Employee Leave Application" @click="viewItemDetails(data.item.leaveTimeId)"><i class="fa fa-sign-out" aria-hidden="true"></i></button>
                <button v-if="data.item.workingDayId && data.item.employeeAttendanceId" type="submit" class="sbtn btn btn-xs btn-outline-primary px-2" title="Employee Work Timeline" @click="showTimelineListPopup(data.item.employeeAttendanceId)">
                    <i class="fa fa-list-ol" aria-hidden="true"></i>
                </button>
            </template>
            <template v-slot:empty="scope">
                <div class="text-center">No Employees found</div>
            </template>
            <template v-slot:emptyfiltered="scope">
                <div class="text-center">{{ scope.emptyFilteredText }}</div>
            </template>
        </b-table>
        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{ totalRows }} Items</span>
    </div>
</template>
<script>
export default {
    name: 'app',
    props: {
        items: Array,
        fields: Array,
        totalRows: Number,
        filterModel: String,
        editId: Boolean,
    },
    data() {
        return {
            selectMode: 'single',
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filterOn: [],
        }
    },
    methods: {
        editEmpOvertime(data, statusLookupId) {
            this.$emit('editEmpOvertime', data, statusLookupId);
        },
        editEmpLeaveType(data, statusLookupId) {
            this.$emit('editEmpLeaveType', data, statusLookupId);
        },
        saveEmpOvertimeData(empOvertime) {
            this.$emit('saveEmpOvertimeData', empOvertime);
        },
        onFiltered(filteredItems) {
            this.$emit('onFiltered', filteredItems);
        },
        onRowSelected: function(items) {
            this.$emit('onRowSelected', items);
        },
        openWorkingDay(workShiftId) {
            this.$emit('openWorkingDay', workShiftId);
        },
        clearData() {
            this.$emit('clearData');
        },
        showTimelineListPopup(employeeAttendanceId) {
            this.$emit('showTimelineListPopup', employeeAttendanceId);
        },
        viewItemDetails(leaveTimeId) {
            this.$emit('viewItemDetails', leaveTimeId);
        },
    }
}
</script>