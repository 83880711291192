<template>
    <div>
        <QualityAssurancePlanMaster :ermMasterInfo="ermMasterInfo"></QualityAssurancePlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import QualityAssurancePlanMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        QualityAssurancePlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Quality Assurance Plan Master",
                entity: 'quality_assurance_plan_master',
                ermListRedirectURL: '/qapmas/list',
                erdRedirectURL: '/reviewplan/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>