<template>
    <div class="referenceDetailPopup">
        <b-modal id="payrollDetailPopup" ref="payrollDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Payslip" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe">
                    <div class="card p-1 mb-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <div v-if="logo">
                                <div class="panel panel-default">
                                    <div class="panel-body">
                                        <div class="my-company-img">
                                            <img width="150px" alt="Company Logo" :src="companyData.logo">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="">
                                <!-- <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i> -->
                                <div class="font-weight-bold">{{ companyData.companyName }}</div>
                                <span v-if="companyData.address1">{{companyData.address1}}, </span>
                                <span v-if="companyData.streetName">{{companyData.streetName}}, </span>
                                <span v-if="companyData.city">{{companyData.city}}</span>
                                <div>
                                    <span v-if="companyData.state">{{companyData.state}}, </span>
                                    <span v-if="companyData.country">{{companyData.country}}</span>
                                    <span v-if="companyData.pincode"> - {{companyData.pincode}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card border-0">
                        <div class="row">
                            <div class="col-12 text-left">
                                <!-- <h5 class="text-center">Pay slip for {{ popupData.payrollPeriod }}</h5> -->
                                <div class="form-row mb-1">
                                    <div class="col-6 col-sm-6">
                                        <div class="row border no-gutters">
                                            <div class="col-4 pr-0">
                                                <label class="mb-0 p-2 d-block title-block text-left">Employee Name</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.employeeName }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-sm-3">
                                        <div class="row border no-gutters">
                                            <div class="col-3 pr-0">
                                                <label class="mb-0 p-2 d-block title-block text-left">EID</label>
                                            </div>
                                            <div class="col-9 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.eid }} </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-3 col-sm-3">
                                        <div class="row border no-gutters">
                                            <div class="col-4 pr-0">
                                                <label class="mb-0 p-2 d-block title-block text-left">Month</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ getMonthYearAlt(popupData.payrollPeriodStr) }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mb-2">
                                    <div class="col-6 col-sm-6">
                                        <div class="row border no-gutters">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-0 p-2 d-block title-block text-left">Designation</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.designation }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="popupData.department" class="col-6 col-sm-6">
                                        <div class="row border no-gutters">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-0 p-2 d-block title-block text-left">Department</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.department }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mb-2">
                                    <div class="col-6 col-sm-6">
                                        <div class="card">
                                            <div class="card-body py-1 px-0">
                                                <div class="title-block p-2">
                                                    <p class="contact-title mb-0">Earnings</p>
                                                </div>
                                                <div class="row border no-gutters" v-for="earning,index in earningList">
                                                    <div class="col-8 col-md-8 pr-0">
                                                        <label class="mb-0 p-2 d-block header-details">{{ earning.payslipComponent }}</label>
                                                    </div>
                                                    <div class="col-4 col-md-4 pl-0">
                                                        <label class="p-2 mb-0 d-block header-details text-right">
                                                            {{ currencySymbol }} {{ earning.amount ? (earning.amount).toFixed(2) : '0.00' }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="card">
                                            <div class="card-body py-1 px-0">
                                                <div class="title-block p-2">
                                                    <p class="contact-title mb-0">Deductions</p>
                                                </div>
                                                <div class="row border no-gutters" v-for="deduction,index in deductionList">
                                                    <div class="col-8 col-md-8 pr-0">
                                                        <label class="mb-0 p-2 d-block header-details">{{ deduction.payslipComponent }}</label>
                                                    </div>
                                                    <div class="col-4 col-md-4 pl-0">
                                                        <label class="p-2 mb-0 d-block header-details text-right">
                                                            {{ currencySymbol }} {{ deduction.amount ? (deduction.amount).toFixed(2) : '0.00' }}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mb-2">
                                    <div class="col-6 col-sm-6">
                                        <div class="row border no-gutters">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 py-2 d-block title-block">Total Earnings</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">
                                                    {{ currencySymbol }} {{ popupData.totalEarning ? (popupData.totalEarning).toFixed(2) : '0.00' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="row border no-gutters">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 py-2 d-block title-block">Total Deductions</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">
                                                    {{ currencySymbol }} {{ popupData.totalDeduction ? (popupData.totalDeduction).toFixed(2) : '0.00' }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row mb-1">
                                    <div class="col-12 col-sm-12">
                                        <div class="row border no-gutters">
                                            <div class="col-6 col-md-6 pr-0">
                                                <label class="mb-0 py-2 d-block title-block">Net Pay</label>
                                            </div>
                                            <div class="col-6 col-md-6 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right font-weight-bold">{{ currencySymbol }} {{ netPay.toFixed(2) }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';    
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object
    },
    components: {
        AttachmentView
    },
    data() {
        return {
            loading: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            currencySymbol: localStorage.getItem("currency"),
            logo: localStorage.getItem('logo'),
            companyData: {},
            popupData: {},
            payrollDetailList: [],
        }
    },
    computed: {
        earningList() {
            return this.payrollDetailList.filter((data) => data.payslipComponentTypeId == 1);
        },
        deductionList() {
            return this.payrollDetailList.filter((data) => data.payslipComponentTypeId == 2);
        },
        netPay() {
            return Number(this.popupData.totalEarning) - Number(this.popupData.totalDeduction);
        },
        totalEarnings() {
            return this.payrollList.reduce((accum, item) => {
                return accum + parseFloat(item.totalEarning)
            }, 0.00)
        },        
        totalDeductions() {
            return this.payrollList.reduce((accum, item) => {
                return accum + parseFloat(item.totalDeduction)
            }, 0.00)
        },
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            if (refEntity === "payroll") {
                this.$bvModal.show('payrollDetailPopup');
                axios.get(this.$store.getters.getAPIBasePath + '/finance/payroll/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.payroll) {
                        this.popupData = result.payroll;
                        this.companyData = result.company;
                        // this.popupData = popupData[0];
                        this.loading = false;
                        this.$nextTick(() => {
                            this.getPayrollDetail(id);
                        })
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.$bvModal.show('payrollDetailPopup');
                this.loading = false;
            }
        },
        getPayrollDetail: function(id) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                payrollId: id,
            }
            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payrolldetail/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.payrollDetailList.length > 0) {
                        this.payrollDetailList = result.payrollDetailList;
                        this.showDetOverlay = false;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    console.log(error);
                });
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>