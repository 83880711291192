<template>
    <div>
        <div class="row">
            <div class="col-xl-8 col-xl-8">
                <div class="card mb-4">
                    <div class="card-body p-3">
                        <b-overlay :show="showOverlay" opacity="0.4">
                            <div class="card mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-client>
                                            <span v-if="!elements.editMode">
                                                <i class="fa fa-plus-circle action-link pr-2" aria-hidden="true"></i>New {{ dyanmicPageName }}
                                            </span>
                                            <span v-if="elements.editMode">Edit</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-client" accordion="my-accordion" v-model="elements.visible" collapsed role="tabpanel">
                                        <b-card-body class="p-3 mb-3">
                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="clientTypeId">{{ dyanmicPageName }} Type</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientType.id.$error }">
                                                            <select v-model="client.clientType" @change="changeClientType(client.clientType)" class="form-control">
                                                                <option v-for="(info, index) in filteredClientTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                                            </select>
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.client.clientType.id.$error && !$v.client.clientType.id.required" class="text-danger">Please select a {{ dyanmicPageName }} type</label>
                                                        <!--                                                     <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a> -->
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="clientName">{{ dyanmicPageName }} Name</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientName.$error }">
                                                            <input type="text" maxlength="100" v-model="client.clientName" :class="{ 'is-invalid': $v.client.clientName.$error }" class="form-control" id="clientName" :placeholder="'Enter ' + (dyanmicPageName) + ' Name'">
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.minLength" class="text-danger">{{ dyanmicPageName }} Name must have at least {{$v.client.clientName.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.required" class="text-danger">Please enter {{ dyanmicPageName }} Name</label>
                                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.maxLength" class="text-danger">{{ dyanmicPageName }} Name must not exceed {{$v.client.clientName.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="emailId">Email ID</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.emailId.$error, 'is-invalid': error }">
                                                            <input type="email" maxlength="100" v-model="client.emailId" placeholder="Enter Email Id" class="form-control" :class="{ 'is-invalid': $v.client.emailId.$error, 'is-invalid': error }" id="emailId">
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.email" class="text-danger">Enter Valid EmailId</label>
                                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.required" class="text-danger">Please enter EmailId</label>
                                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-12">
                                                    <div>
                                                        <b-input-group :prepend="prependText" class="mb-2">
                                                            <b-form-input aria-label="First name" v-model="client.firstName" placeholder="First Name"></b-form-input>
                                                            <b-form-input aria-label="Middle name" v-model="client.middleName" placeholder="Middle Name"></b-form-input>
                                                            <b-form-input aria-label="Last name" v-model="client.lastName" placeholder="Last Name"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="phone">First Name</label>
                                                        <input type="text" v-model="client.firstName" placeholder="First Name" class="form-control">
                                                    </div>
                                                </div>
                                                <div v-if="client.clientTypeName != 'company'" class="col-4">
                                                    <div class="form-group">
                                                        <label for="phone">Middle Name</label>
                                                        <input type="text" v-model="client.middleName" placeholder="Middle Name" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="phone">Last Name</label>
                                                        <input type="text" v-model="client.lastName" placeholder="Last Name" class="form-control">
                                                    </div>
                                                </div>
                                                <div v-if="client.clientTypeName == 'company'" class="col-4">
                                                    <div class="form-group">
                                                        <label for="position">Job Title</label>
                                                        <input type="text" v-model="client.position" class="form-control" id="position">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="phone">Phone</label>
                                                        <input type="text" v-model="client.phone" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="mobile">Mobile</label>
                                                        <input type="text" v-model="client.mobile" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label for="location">Location</label>
                                                        <input type="text" v-model="client.location" class="form-control">
                                                    </div>
                                                </div> -->
                                                <div v-if="client.clientTypeName != 'company'" class="col-4">
                                                    <div class="form-group">
                                                        <label for="profession">Profession</label>
                                                        <input type="text" maxlength="100" v-model="client.profession" class="form-control" placeholder="Profession" id="profession">
                                                    </div>
                                                </div>
                                                <div v-if="client.clientTypeName != 'company'" class="col-4">
                                                    <div class="form-group">
                                                        <label for="employer">Employer</label>
                                                        <input type="text" maxlength="100" v-model="client.employer" class="form-control" placeholder="Employer" id="employer">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 mb-3">
                                                        <b-card no-body class="p-1">
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block px-2 py-1 accordion-title" block href="javascript:" v-b-toggle.accordion-cp>
                                                                    <span v-if="client.clientTypeName == 'company'">Contact Person</span>
                                                                    <span v-if="client.clientTypeName != 'company'">Contact Info</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-cp" accordion="my-accordion-cp" role="tabpanel" class="p-0">
                                                                <b-card-body class="p-1">
                                                                    <div class="row">
                                                                        <div class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="First Name" class="mt-2">
                                                                                    <b-form-input type="text" maxlength="20" v-model="client.firstName" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="client.clientTypeName != 'company'" class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Middle Name" class="mt-2">
                                                                                    <b-form-input type="text" maxlength="20" v-model="client.middleName" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Last Name" class="mt-2">
                                                                                    <b-form-input type="text" maxlength="20" v-model="client.lastName" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div v-if="client.clientTypeName == 'company'" class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Job Title" class="mt-2">
                                                                                    <b-form-input type="text" maxlength="100" v-model="client.position" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Phone">
                                                                                    <b-form-input type="text" maxlength="20" v-model="client.phone" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Mobile">
                                                                                    <b-form-input type="text" maxlength="20" v-model="client.mobile" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Location">
                                                                                    <b-form-input type="text" maxlength="100" v-model="client.location" class="form-control"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 mb-3">
                                                        <b-card no-body class="p-1">
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block px-2 py-1 accordion-title" block href="javascript:" v-b-toggle.accordion-add>
                                                                    <span>Address</span>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-add" accordion="my-accordion-add" role="tabpanel" class="p-0">
                                                                <b-card-body class="p-1">
                                                                    <div class="row">
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Building Name" class="mt-2">
                                                                                    <b-form-input type="text" maxlength="45" v-model="client.address1" class="form-control" id="address1"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Street Name" class="mt-2">
                                                                                    <b-form-input type="text" maxlength="45" v-model="client.address2" class="form-control" id="address2"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="City">
                                                                                    <b-form-input type="text" maxlength="45" v-model="client.city" class="form-control" id="city"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="State">
                                                                                    <b-form-input type="text" maxlength="45" v-model="client.state" class="form-control" id="state"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Country">
                                                                                    <select v-model="client.countryId" class="form-control">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6">
                                                                            <div class="form-group">
                                                                                <b-input-group prepend="Pincode">
                                                                                    <b-form-input type="text" maxlength="`10" v-model="client.pincode" class="form-control" id="pincode"></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="submit" @click="saveClient()" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-4"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <!--                                         <button type="submit" @click="saveClient('invite')" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-4"><i class="fa fa-floppy-o pr-2"></i>Save & Invite</button> -->
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mt-4"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <template #overlay>
                                <div class="text-center">
                                    <LoadingSpinner />
                                </div>
                            </template>
                        </b-overlay>                        
                        <div class="row view-filter">
                            <div class="col-sm-12">
                                <div class="float-left">
                                    <div class="dataTables_filter">
                                        <label class="d-block p-1 accordion-title">{{ dyanmicPageName + 's' }}
                                        </label>
                                    </div>
                                </div>
                                <div class="float-right">
                                    <div class="dataTables_filter">
                                        <label>
                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                        </label>
                                    </div>
                                </div>
                                <!--                                 <div class="float-right">
                                    <div v-if="clientProps.addExistingClient">
                                        <a href="javascript:" class="d-block p-1 action-link mr-4" title="Existing Client" v-b-modal.existing-client-popup @click="existingClient"><i class="fa fa-plus-circle pr-1"></i></i>Existing Client</a>
                                    </div>
                                    <b-modal id="existing-client-popup" ref="existing-client-popup" size="lg" modal-class="referenceModal" title="Add Existing Client" ok-title="Ok">
                                        <div class="p-0">
                                            <div class="m-0">
                                                
                                                <div class="mt-2">
                                                    <div class="col-3 float-left pl-0 mb-2">
                                                        <h6>Existing Clients</h6>
                                                    </div>
                                                    <div class="col-3 float-right pl-0 mb-2">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per Page" label-cols-sm="6" label-align="right" label-size="sm" label-for="perPageSelect" class="pl-0 mb-0">
                                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>

                                                    <b-table show-empty small stacked="md" sort-icon-left :items="existingClients" :fields="existingClientfields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                        <template v-slot:cell(actions)="data">
                                                            <a href="javascript:" title="Add Client" class="text-success" @click="addProjectClient(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Clients found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                    <div class="col-9 pull-right">
                                                        <b-pagination v-if="existingClients != null && existingClients.length!=0" pills v-model="currentPage" :total-rows="totalRowsEC" :per-page="perPage" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </b-modal>                                    
                                </div> -->
                                <div class="clearfix"></div>
                            </div>
                        </div>
                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="clients" :fields="fields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            <template #cell(index)="data">
                                {{ data.index + 1 }}
                            </template>                                                                                          
                            <template v-slot:cell(title)="data">
                                <router-link :title="'View ' + dyanmicPageName" :to="{ name: 'viewclient', params: { clientid: data.item.id }}">{{data.value}}</router-link>
                            </template>
                            <template v-slot:cell(actions)="data">
                                <!--                                 <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                <a href="javascript:" :title="'Delete ' + dyanmicPageName" @click="deleteClient(data.item.id)" v-b-modal.deleteClient><i class="fa fa-trash pl-2"></i></a>
                            </template>
                            <template v-slot:empty="scope">
                                <div class="text-center">No {{ dyanmicPageName + 's' }} found</div>
                            </template>
                            <template v-slot:emptyfiltered="scope">
                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                            </template>
                            <template v-slot:table-busy>
                                <div class="text-center text-info my-2">
                                    <b-spinner class="align-middle"></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </template>
                        </b-table>
                        <div class="separator mb-2"></div>
                        <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-right ml-3">{{this.totalRows}} Items</span>                        
                        <b-pagination v-if="clients != null && clients.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        <div class="float-left">
                            <div class="dataTables_length">
                                <b-form-group label="Per Page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-4 pl-0 mb-4">
                <div id="profile" class=" h-100">
                    <div class="profile_header">
                        <div class="row">
                            <div class="col-12 col-lg-6 col-xl-6 pl-0 ">
                                <div class="my-profile-img">
                                    <img src="https://lh3.googleusercontent.com/-P_cpGe3NM1Q/AAAAAAAAAAI/AAAAAAAAAAA/gYOuFmMJ28g/photo.jpg" alt="Profile picture">
                                    <label class="upload-icon upload-icon-contacts">
                                        <i class="fa fa-camera"></i>
                                        <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;">
                                    </label>
                                </div>
                                <!--                                 <div class="my-profile-img">
                                    <img :src="clientDetail.photo" alt="Profile picture">
                                    <label class="upload-icon upload-icon-contacts">
                                        <i class="fa fa-camera"></i>
                                        <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;" @change="uploadPhoto">
                                    </label>
                                </div> -->
                            </div>
                            <div class="col-12 col-lg-6 col-xl-6 pl-0 text-left">
                                <div>
                                    <h6 class="mb-0" v-if="clientDetail.clientName!=''">{{clientDetail.clientName}} <span v-if="clientDetail.clientType">[{{clientDetail.clientType}}]</span></h6>
                                    <p class="m-0">
                                        <span v-if="clientDetail.profession!=''">{{clientDetail.profession}}</span>
                                    </p>
                                    <p class="m-0">
                                        <span v-if="clientDetail.employer!=''">{{clientDetail.employer}}</span>
                                        <span v-if="clientDetail.clientType != 'Individual' && clientDetail.firstName != null && clientDetail.firstName !=''">{{clientDetail.firstName}}</span>
                                        <span v-if="clientDetail.position"> / {{clientDetail.position}}</span>
                                    </p>
                                    <p class="m-0">
                                        <span v-if="clientDetail.location!=''">{{clientDetail.location}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-left pl-4">
                                <label v-if="clientDetail != null && clientDetail.emailId != null && clientDetail.emailId.length != 0" class="ml-0 mr-2"><i class="fa fa-envelope-o font-weight-bold action-link" aria-hidden="true"></i> {{clientDetail.emailId}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-center pl-4">
                                <label v-if="clientDetail != null && clientDetail.phone != null && clientDetail.phone.length != 0" class="ml-0 mr-2"><i class="fa fa-phone font-weight-bold action-link" aria-hidden="true"></i> {{clientDetail.phone}}</label>
                            </div>
                            <div class="text-right pl-2">
                                <label v-if="clientDetail != null && clientDetail.mobile != null && clientDetail.mobile.length != 0" class="ml-0 mr-2"><i class="fa fa-mobile font-weight-bold action-link" aria-hidden="true"></i> {{clientDetail.mobile}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="profile_custom">
                        <h3 class="mt-3 mb-3">Full Name</h3>
                        <div class="pl-2">
                            <p v-if="clientDetail.firstName != null && clientDetail.firstName !=''"><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{clientDetail.firstName}} {{clientDetail.middleName}} {{clientDetail.lastName}}</p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="profile_custom">
                        <h3 class="mt-3 mb-3">Address Info</h3>
                        <div class="pl-2">
                            <p v-if="clientDetail.address1 != ''">
                                <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                <span v-if="clientDetail.address1 != ''">{{clientDetail.address1}}</span>
                                <span v-if="clientDetail.address2 != ''">, {{clientDetail.address2}}</span>
                                <span v-if="clientDetail.city != ''">, {{clientDetail.city}}</span>
                                <span v-if="clientDetail.state != ''">, {{clientDetail.state}}</span>
                                <span v-if="clientDetail.country != ''">, {{clientDetail.country}}</span>
                                <span v-if="clientDetail.pincode != ''">, {{clientDetail.pincode}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="profile_custom">
                        <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                            <div class="row text-left">
                                <div class="col-12 mt-3 mb-2">
                                    <h3 class="d-block m-0">
                                        <span>User Account Info</span>
                                        <label v-if="clientDetail.loginName" :title="clientDetail.userActive==true ? 'Active User' : 'User Not Activated'" class="pull-right py-0 m-0 pointer">
                                            <i class="fa fa-user fa-xs" :class="{ 'text-success' : clientDetail.userActive==true }" aria-hidden="true"></i>
                                        </label>
                                        <button v-if="!clientDetail.loginName && !elements.userActivation" @click="elements.userActivation = true" href="javascript:" class="sbtn btn btn-xs action-link py-0 px-0 pointer pull-right" title="User Account">
                                            <i class="fa fa-plus-circle action-link pr-1" aria-hidden="true"></i>User Account
                                        </button>
                                    </h3>
                                </div>
                            </div>
                            <div v-if="clientDetail.loginName && !elements.userActivation" class="pl-2">
                                <p class="ml-0 mr-4 my-2"><i class="font-weight-bold action-link" aria-hidden="true"></i>
                                    Account Name : <label class="m-0">{{clientDetail.userName}}</label>
                                </p>
                                <p class="ml-0 mr-4 my-2" v-if="clientDetail.userType"><i class="font-weight-bold action-link" aria-hidden="true"></i>User Type : {{clientDetail.userType}}</p>
                                <p class="ml-0 mr-4 my-2"><i class="font-weight-bold action-link" aria-hidden="true"></i>
                                    Login Email : <label class="m-0">{{clientDetail.loginName}}</label>
                                </p>
                            </div>
                            <div v-if="elements.userActivation" class="pl-2">
                                <div class="row my-2">
                                    <div class="col-5 my-auto">
                                        <p class="my-auto"><i class="font-weight-bold action-link" aria-hidden="true"></i>
                                        Account Name</p>
                                    </div>
                                    <div class="col-7 pl-0">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.clientDetail.clientName.$error, 'is-invalid': error }">
                                            <input v-if="elements.userActivation" type="text" v-model="clientDetail.clientName" placeholder="Enter Account Name" class="form-control" :class="{ 'is-invalid': $v.clientDetail.clientName.$error, 'is-invalid': error }" id="clientName">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.clientDetail.clientName.$error && !$v.clientDetail.clientName.minLength" class="text-danger">Account Name must have at least {{$v.clientDetail.clientName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.clientDetail.clientName.$error && !$v.clientDetail.clientName.required" class="text-danger">Please enter Account Name</label>
                                        <label v-if="$v.clientDetail.clientName.$error && !$v.clientDetail.clientName.maxLength" class="text-danger">Account Name must not exceed {{$v.clientDetail.clientName.$params.maxLength.max}} letters.</label>
                                    </div>                                
                                </div>
                                <div class="row my-2">
                                    <div class="col-5 my-auto">
                                        <p class="my-auto"><i class="font-weight-bold action-link" aria-hidden="true"></i>
                                        Login Email</p>
                                    </div>
                                    <div class="col-7 pl-0"> 
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.clientDetail.emailId.$error, 'is-invalid': error }">
                                            <input v-if="elements.userActivation" type="email" v-model="clientDetail.emailId" placeholder="Enter Email Id" class="form-control" :class="{ 'is-invalid': $v.clientDetail.emailId.$error, 'is-invalid': error }" id="emailId">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.clientDetail.emailId.$error && !$v.clientDetail.emailId.email" class="text-danger">Enter Valid EmailId</label>
                                        <label v-if="$v.clientDetail.emailId.$error && !$v.clientDetail.emailId.required" class="text-danger">Please enter EmailId</label>
                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                    </div>
                                </div>
                                <!-- <div v-if="clientDetail.userType" class="row my-2">
                                    <div class="col-5 my-auto">
                                        <p class="my-auto"><i class="font-weight-bold action-link" aria-hidden="true"></i>
                                        User Type</p>
                                    </div>
                                    <div class="col-7 pl-0">
                                        <label class="my-auto">{{ clientDetail.userType }}</label>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-12 my-2">
                                        <button type="submit" @click="createUserAccount" title="Save" class="sbtn btn btn-xs btn-primary py-1 px-2 mr-1"> <i class="fa fa-floppy-o pr-1"></i> Save</button>
                                        <button type="submit" @click="cancelAccountEdit" title="Cancel" class="sbtn btn btn-xs btn-primary py-1 px-2"><i class="fa fa-times pr-1"></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </b-overlay>
                    </div>
                    <!-- <div class="profile_custom" v-if="clientDetail.website != null && clientDetail.facebookId !=''">
                    <div class="divider"></div>
                        <h3 class="mt-3 mb-3">Social Connect</h3>
                        <div class="pl-2">
                            <p class="ml-0 mr-4" v-if="clientDetail.website != null && clientDetail.website !=''"><i class="fa fa-globe font-weight-bold action-link" aria-hidden="true"></i> {{clientDetail.website}}</p>
                            <p class="ml-0 mr-4" v-if="clientDetail.facebookId != null && clientDetail.facebookId !=''"><i class="fa fa-facebook-square font-weight-bold action-link" aria-hidden="true"></i> {{clientDetail.facebookId}}</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteClient" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="`Are you sure to delete ${dyanmicPageName}?`" />
    </div>
</template>
<script>
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    props: {
        clientProps: Object
    },
    components: {
        // TypeLookup,
        Select2
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            elements: {
                alreadyExists: false,
                editMode: false,
                visible: false,
                userActivation: false,
            },
            isBusy: true,

            entity: 'client',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            error: false,
            clientTypes: [],
            selClientType: [],
            users: [],
            countries: [],
            typeLookupTitle: 'Client Type Settings',
            prependText: 'Full Name',
            client: {
                id: '',
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                userType: '',
                userName: '',
                loginName: '',
                userActive: '',
                clientTypeId: '',
                relationshipTypecodeEnum: '',
                clientTypeName: '',
                clientType: {
                    id: 1,
                    text: 'Individual'
                },
                type: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            },
            clientDetail: {
                id: '',
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                userType: '',
                userName: '',
                loginName: '',
                userActive: '',
                clientType: '',
                type: '',
                relationshipTypecodeEnum: '',
                clientTypeId: '',
                clientTypeName: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                country: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            },
            projectClientMember: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'client',
                userId: '',
                clientName: '',
                emailId: '',
                position: '',
                createdById: localStorage.getItem("userId"),
            },


            clients: [],
            clientFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'clientId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Client Name', sortable: true, sortDirection: 'desc' },
                { key: 'clientType', label: 'Client Type', sortable: true, sortDirection: 'desc' },
                // { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                // { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            subContractFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },            
                { key: 'clientId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'clientName', label: 'Sub Contractor Name', sortable: true, sortDirection: 'desc' },
                { key: 'clientType', label: 'Sub Contractor Type', sortable: true, sortDirection: 'desc' },
                // { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
                // { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
                { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
                { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            // existingClients: [],
            // existingClientfields: [
            //     { key: 'clientId', label: 'Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'clientName', label: 'Client Name', sortable: true, sortDirection: 'desc' },
            //     { key: 'clientType', label: 'Client Type', sortable: true, sortDirection: 'desc' },
            //     { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
            //     { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
            //     { key: 'phone', label: 'Phone', sortable: true, sortDirection: 'desc' },
            //     // { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },
            //     { key: 'location', label: 'Location', sortable: true, sortDirection: 'desc' },
            //     { key: 'actions', label: 'Actions' }
            // ],

            selectMode: 'single',
            totalRows: 1,
            totalRowsEC: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredClientTypes() {
            return this.clientTypes.filter((type) => type.value !=='Corporate');
        },
        isClientPage() {
            return this.$route.path.includes('client');
        },
        dyanmicPageName() {
            return this.isClientPage ? 'Client' : 'Sub Contractor';
        },
        fields() {
            return this.isClientPage ? this.clientFields : this.subContractFields;
        }
    },
    validations: {
        client: {
            clientType: {
                id: {
                    required
                }
            },
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            }
        },
        clientDetail: {
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            }
        }
    },
    mounted() {
        this.lookupData();
        this.getClients();
    },
    methods: {
        getClients: function() {
            if (this.projectId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    relationshipTypecodeEnum: this.isClientPage ? 1 : 2   // relationshipTypecodeEnum: 1 - client, 2 - Sub Countract
                }
                axios.post(this.$store.getters.getAPIBasePath + '/client/projectclient/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.clients = result.projectClientList;
                        if (this.clients != null) {
                            this.totalRows = this.clients.length;
                            this.$emit('updateClientCount', this.totalRows);
                            this.onRowSelected(this.clients);
                            // alert(this.projectId);
                        }
                        this.isBusy = !this.isBusy
                    }).catch(error => {
                        this.isBusy = !this.isBusy
                        console.log(error);
                    });
            } else {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    relationshipTypecodeEnum: this.isClientPage ? 1 : 2   // relationshipTypecodeEnum: 1 - client, 2 - Sub Countract
                }
                axios.post(this.$store.getters.getAPIBasePath + '/client/companyclient/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.clients = result.clientList;
                        if (this.clients != null && this.clients.length > 0) {
                            this.totalRows = this.clients.length;
                            this.$emit('updateClientCount', this.totalRows);
                            this.onRowSelected(this.clients);
                            // alert('b'); 
                        }
                        this.isBusy = !this.isBusy
                    }).catch(error => {
                        this.isBusy = !this.isBusy
                        console.log(error);
                    });
            }
        },
        // getExistingClients: function() {
        //     let data = {
        //         vhextAccountId: localStorage.getItem("vhextAccountId")
        //     }
        //     console.log(data);
        //     axios.post(this.$store.getters.getAPIBasePath + '/client/companyclient/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             console.log(result);
        //             this.existingClients = result.clientList;
        //             if(this.existingClients != null){
        //                 this.totalRowsEC = this.existingClients.length;
        //                 this.$emit('updateClientCount', this.totalRowsEC);
        //             }
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clientTypes = result.typeLookup;
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                    // console.log(result.customTypeLookup);
                    console.log(localStorage.getItem("projectId"));
                    console.log(localStorage.getItem("vhextAccountId"));
                })

        },
        saveClient: function() {
            console.log(this.client);
            if (this.elements.editMode) {
                this.updateClient();
            } else {
                this.addClient();
            }
        },
        addClient: function(flag) {

            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }
            this.client.relationshipTypecodeEnum = this.isClientPage ? 1 : 2;   // relationshipTypecodeEnum: 1 - client, 2 - Sub Countract
            // if (flag == 'invite') {
            //     this.client.invite = true;
            // } else {
            //     this.client.invite = false;
            // }
            console.log(this.client.clientTypeId, this.client);

            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                this.showOverlay = true;
                this.error = false;
                if (this.client.clientTypeName == 'company') {
                    this.client.contactPerson = this.client.firstName + " " + this.client.middleName + " " + this.client.lastName
                } else {
                    this.client.contactPerson = '';
                }
                console.log(this.client.clientTypeId, this.client);
                axios.post(this.$store.getters.getAPIBasePath + '/client/add', this.client)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        console.log(result);
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: 'Client info loaded successfully',
                                position: 'topRight'
                            });
                            this.getClients();
                            this.clearData();
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            this.error = true;

                        }

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                //this.error = true;
            }

        },
        updateClient: function() {
            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }
            this.client.relationshipTypecodeEnum = this.isClientPage ? 1 : 2;   // relationshipTypecodeEnum: 1 - client, 2 - Sub Countract
            console.log(this.client);
            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/client/update', this.client)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getClients();
                            this.clearData();
                        }
                        console.log(result);

                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }

        },
        // saveProjectMember: function() {
        //     console.log(this.projectClientMember);
        //     axios.post(this.$store.getters.getAPIBasePath + '/resource/projectclient/add', this.projectClientMember)
        //         .then((response) => {
        //             let result = response.data;
        //             if (result.status == "CREATED") {
        //                 iziToast.success({
        //                     title: 'Success',
        //                     message: "Client Added",
        //                     position: 'topRight'
        //                 });
        //                     this.getClients();
        //                     this.clearData();
        //             } else if (result.status == 'OK') {
        //                 iziToast.info({
        //                     title: 'Info',
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });

        //                 this.elements.alreadyExists = true;
        //                 this.error = true;
        //             }
        //         })
        // },

        deleteClient: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                projectId: this.projectId,
                id: this.deleteParam.id,
                entity: this.entity,
            }
            if (this.projectId) {
                console.log(data);

                axios.post(this.$store.getters.getAPIBasePath + '/resource/projectclient/delete',data)
                    .then(response => {
                        let result = response.data;
                        console.log(result);
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: 'result.message',
                                position: 'topRight'
                            });
                            this.getClients();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            } else {
                console.log(data);
                // alert(this.projectId);
                // return;
                axios.delete(this.$store.getters.getAPIBasePath + '/client/delete/' + this.deleteParam.id)
                    .then(response => {
                        var result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getClients();
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });

            }
        },

        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.elements.userActivation = false;
            this.client.title = '';
            this.client.clientType.id = 1;
            this.client.clientType.text = 'Individual';
            this.client.relationshipTypecodeEnum = '';
            this.client.clientTypeId = '';
            this.client.clientTypeName = '';
            this.client.clientName = '';
            this.client.contactPerson = '';
            this.client.firstName = '';
            this.client.middleName = '';
            this.client.lastName = '';
            this.client.emailId = '';
            this.client.phone = '';
            this.client.mobile = '';
            this.client.address1 = '';
            this.client.address2 = '';
            this.client.city = '';
            this.client.state = '';
            this.client.countryId = '';
            this.client.country = '';
            this.client.pincode = '';
            this.client.hasContactInfo = '';
            this.client.active = '';
            this.client.profession = '';
            this.client.employer = '';
            this.client.position = '';
            this.client.location = '';
            this.elements.alreadyExists = false;
            this.error = false;
            this.$v.client.$reset();
            this.$v.clientDetail.$reset();
        },
        changeClientType: function(data) {
            if (data.text != null && data.text.length > 0) {
                this.client.clientTypeName = data.text.toLowerCase();

                if (this.client.clientTypeName == 'company') {
                    this.prependText = 'Contact Person';
                } else {
                    this.prependText = 'Full Name';
                }
            }
        },
        onRowSelected: function(items) {
            if (items != null && items.length != 0) {

                let data = items[0];
                this.elements.userActivation = false;
                this.clientDetail.id = data.id;
                this.clientDetail.clientId = data.clientId;
                this.clientDetail.vhextAccountId = data.vhextAccountId;
                this.clientDetail.projectId = data.projectId;
                this.clientDetail.userId = data.userId;
                this.clientDetail.userType = data.userType;
                this.clientDetail.userName = data.userName;
                this.clientDetail.loginName = data.loginName;
                this.clientDetail.userActive = data.userActive;
                this.clientDetail.type = data.type;
                this.clientDetail.title = data.title;
                this.clientDetail.relationshipTypecodeEnum = data.relationshipTypecodeEnum;
                this.clientDetail.clientTypeId = data.clientTypeId;
                this.clientDetail.clientType = data.clientType;
                this.clientDetail.clientTypeName = data.clientTypeName;
                this.clientDetail.clientName = data.clientName;
                this.clientDetail.contactPerson = data.contactPerson;
                this.clientDetail.firstName = data.firstName;
                this.clientDetail.middleName = data.middleName;
                this.clientDetail.lastName = data.lastName;
                this.clientDetail.emailId = data.emailId;
                this.clientDetail.phone = data.phone;
                this.clientDetail.mobile = data.mobile;
                this.clientDetail.address1 = data.address1;
                this.clientDetail.address2 = data.address2;
                this.clientDetail.city = data.city;
                this.clientDetail.state = data.state;
                this.clientDetail.countryId = data.countryId;
                this.clientDetail.country = data.country;
                this.clientDetail.pincode = data.pincode;
                this.clientDetail.hasContactInfo = data.hasContactInfo;
                this.clientDetail.active = data.active;
                this.clientDetail.profession = data.profession;
                this.clientDetail.employer = data.employer;
                this.clientDetail.position = data.position;
                this.clientDetail.location = data.location;
                this.clientDetail.createdDt = data.createdDt;
                this.clientDetail.lastUpdatedDt = data.lastUpdatedDt;
                console.log(this.clientDetail);
            }
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.elements.userActivation = false;
            this.client.id = data.id;
            this.client.clientId = data.clientId;
            this.client.vhextAccountId = data.vhextAccountId;
            this.client.projectId = data.projectId;
            this.client.userId = data.userId;
            this.client.userType = data.userType;
            this.client.userName = data.userName;
            this.client.loginName = data.loginName;
            this.client.userActive = data.userActive;
            this.client.type = data.type;
            this.client.title = data.title;
            this.client.relationshipTypecodeEnum = data.relationshipTypecodeEnum;
            this.client.clientTypeId = data.clientTypeId;
            this.client.clientTypeName = data.clientTypeName;

            if (data.clientTypeId) {     
                this.selClientType = this.clientTypes.find(({ id }) => id == data.clientTypeId);
                this.client.clientType.text = this.selClientType ? this.selClientType.value : null;
            }
            this.client.clientType.id = data.clientTypeId;

            this.changeClientType(this.client.clientType);
            this.client.clientName = data.clientName;
            this.client.contactPerson = data.contactPerson;
            this.client.firstName = data.firstName;
            this.client.middleName = data.middleName;
            this.client.lastName = data.lastName;
            this.client.emailId = data.emailId;
            this.client.phone = data.phone;
            this.client.mobile = data.mobile;
            this.client.location = data.location;
            this.client.address1 = data.address1;
            this.client.address2 = data.address2;
            this.client.city = data.city;
            this.client.state = data.state;
            this.client.countryId = data.countryId;
            this.client.country = data.country;
            this.client.pincode = data.pincode;
            this.client.hasContactInfo = data.hasContactInfo;
            this.client.active = data.active;
            this.client.profession = data.profession;
            this.client.employer = data.employer;
            this.client.position = data.position;
            this.client.createdDt = data.createdDt;
            this.client.lastUpdatedDt = data.lastUpdatedDt;

            console.log(this.client);
        },
        showView: function(data) {
            this.elements.visible = false;
            this.elements.editMode = false;
            this.clientDetail.id = data.id;
            this.clientDetail.clientId = data.clientId;
            this.clientDetail.vhextAccountId = data.vhextAccountId;
            this.clientDetail.projectId = data.projectId;
            this.clientDetail.userId = data.userId;
            this.clientDetail.userType = data.userType;
            this.clientDetail.userName = data.userName;
            this.clientDetail.loginName = data.loginName;
            this.clientDetail.userActive = data.userActive;
            this.clientDetail.type = data.type;
            this.clientDetail.title = data.title;
            this.clientDetail.clientType = data.clientType;
            this.clientDetail.relationshipTypecodeEnum = data.relationshipTypecodeEnum;
            this.clientDetail.clientTypeId = data.clientTypeId;
            this.clientDetail.clientTypeName = data.clientTypeName;
            this.clientDetail.clientName = data.clientName;
            this.clientDetail.contactPerson = data.contactPerson;
            this.clientDetail.firstName = data.firstName;
            this.clientDetail.middleName = data.middleName;
            this.clientDetail.lastName = data.lastName;
            this.clientDetail.emailId = data.emailId;
            this.clientDetail.phone = data.phone;
            this.clientDetail.mobile = data.mobile;
            this.clientDetail.address1 = data.address1;
            this.clientDetail.address2 = data.address2;
            this.clientDetail.city = data.city;
            this.clientDetail.state = data.state;
            this.clientDetail.countryId = data.countryId;
            this.clientDetail.country = data.country;
            this.clientDetail.pincode = data.pincode;
            this.clientDetail.hasContactInfo = data.hasContactInfo;
            this.clientDetail.active = data.active;
            this.clientDetail.profession = data.profession;
            this.clientDetail.employer = data.employer;
            this.clientDetail.position = data.position;
            this.clientDetail.createdDt = data.createdDt;
            this.clientDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        createUserAccount: function() {

            this.clientDetail.userTypeCode = 'CLI';
            this.clientDetail.userType = 'Client';
            this.clientDetail.humanResourceId = this.clientDetail.id;
            this.clientDetail.userName = this.clientDetail.clientName;
            console.log(this.clientDetail);
            this.$v.clientDetail.$touch();
            if (!this.$v.clientDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.clientDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            this.clearData();
                            this.getClients();
                            this.elements.userActivation = false;
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        } else if (result.status == 'OK') {
                            this.elements.alreadyExists = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        cancelAccountEdit() {
            this.elements.userActivation = false;
            this.clients.filter(client=> {
                if (client.id == this.clientDetail.id) {
                    this.clientDetail.clientName = client.clientName;
                    this.clientDetail.emailId = client.emailId;
                }
            })
        }
    }
}
</script>