<template>
    <div id="goToTop" class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Policy Document</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body>
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Document</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="">
                                                    <div class="row mb-2">
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div v-if="!elements.viewMode">
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.policyDocument.title.$error }">
                                                                        <input type="text" maxlength="100" ref="setFocus" class="form-control" v-model="policyDocument.title" id="title" :class="{ 'is-invalid': $v.policyDocument.title.$error }" placeholder="Enter Title">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.policyDocument.title.$error && !$v.policyDocument.title.minLength" class="text-danger">Title must have at least {{$v.policyDocument.title.$params.minLength.min}} letters.</label>
                                                                    <label v-if="$v.policyDocument.title.$error && !$v.policyDocument.title.required" class="text-danger">Please enter Title</label>
                                                                    <label v-if="$v.policyDocument.title.$error && !$v.policyDocument.title.maxLength" class="text-danger">Title must not exceed {{$v.policyDocument.title.$params.maxLength.max}} letters.</label>
                                                                </div>
                                                                <b-input-group v-if="elements.viewMode" :prepend="policyDocument.defectDbId" class="mb-2">
                                                                    <b-form-input aria-label="First name" disabled v-model="policyDocument.title"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label for="policyDocumentTypeId">Document Type</label>
                                                                <select v-if="!elements.viewMode" v-model="policyDocument.policyDocumentTypeId" class="form-control" id="policyDocumentTypeId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in policyDocumentTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a :title="typeLookupTitle" v-if="!elements.viewMode" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="policyDocument.type" id="title">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="policyDocumentStatusId">Document Status</label>
                                                                <select v-if="!elements.viewMode" v-model="policyDocument.statusId" class="form-control" id="policyDocumentStatusId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="policyDocument.status" id="title">
                                                            </div>
                                                        </div>
                                                        <div class="col-1">
                                                            <div class="form-group">
                                                                <label for="budget">Active</label>
                                                                <div class="input-group ">
                                                                    <b-form-checkbox class="custom-toggle" v-model="policyDocument.active" name="check-button" switch>
                                                                    </b-form-checkbox>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="policy">Policy</label>
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="policyDocument.policy" :disabled="elements.viewMode"></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row">
                                <div class="col-lg-4 col-xl-4">
                                    <div class="card">
                                        <div class="card-body p-1">
                                            <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-right">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="policyDocuments" :fields="policyDocumentFields" @row-selected="onRowSelected" selectable :select-mode="selectMode" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>                                                                        
                                                <template v-slot:cell(title)="data">
                                                    <a class="tooltiptext action-link" href="#" v-b-modal.referenceDetail @click="viewItemDetails(data.item)">
                                                        {{ data.value }}
                                                    </a>
                                                </template>
                                                <template v-slot:cell(policy)="data">
                                                    <!-- <span v-html="data.value">{{ data.value }}</span> -->
                                                    <a class="tooltiptext" href="#" v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.value)"><!-- {{ToText(data.value)}} --></a>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <a href="#goToTop" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                    <a href="javascript:" title="Delete" @click="deletePolicyDocument(data.item.id)" v-b-modal.deletePolicyDocument><i class="fa fa-trash pl-2"></i></a>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No PolicyDocument found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                            <div class="separator mb-2"></div>
                                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left ml-0">{{this.totalRows}} Items</span>                                             
                                            <b-pagination v-if="policyDocument.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>  
                                        </div>
                                    </div>                                  
                                </div>
                                <div class="col-lg-8 col-xl-8">
                                    <div class="card">
                                        <div class="card-body p-1">
                                            <div class="row">
                                                <div class="col">
                                                    <label class="mb-0 pt-2 pl-2">{{ itemDetailTitle }}</label>
                                                </div>
                                                <div class="col text-center pt-2">
                                                    <a href="#" >{{ itemDetailType }}</a>
                                                    <!-- <label class="mb-0 pt-2">{{ itemDetailStatus }}</label> -->
                                                </div>

                                                <div class="col text-right pt-2 mr-2 fs-14">
                                                    <a href="#" class="badge badge-primary">{{ itemDetailStatus }}</a>
                                                    <!-- <label class="mb-0 pt-2">{{ itemDetailStatus }}</label> -->
                                                </div>

                                            </div>
                                            <div class="separator mt-1 mb-0 mx-2"></div>
                                            <PolicyDocumentDetailComponent ref="policyDocumentDetailCompRef" :attachmentData="attachmentData" />                                            
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <PolicyDocumentDetailPopup v-if="showDialog" ref="policyDocumentDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" /> -->
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <DeleteComponent id="deletePolicyDocument" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Policy Document?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import PolicyDocumentDetailComponent from '@/components/itemdetailcomponents/PolicyDocumentDetailComponent.vue';
// import PolicyDocumentDetailPopup from '@/components/popup/itemdetail/PolicyDocumentDetailPopup.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
        HTMLDetailViewPopup,
        PolicyDocumentDetailComponent,
        Editor,
        // PolicyDocumentDetailPopup
    },
    data() {
        return {
            showOverlay: false,
            showItemDetail: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),

            entity: 'policy_document',
            typeEntity: 'policy_document_type',
            userId: localStorage.getItem("userId"),
            htmlData: '',
            htmlDetailViewTitle: 'Policy Detail',
            typeLookupTitle: 'Policy Document Type Settings',

            attachmentList:[],

            showDialog: false,

            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
            },

            itemDetailTitle: '',
            itemDetailType: '',
            itemDetailStatus: '',
            selPolicyDocument: {},
            policyDocumentStatus: [],
            users: [],
            policyDocumentTypes: [],
            statuses: [],
            severities: [],
            functionalities: [],
            policyDocumentActivities: [],
            policyDocument: {
                id: '',
                policyDocumentId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'policy_document',
                policyDocumentTypeId: '',
                title: '',
                policy: '',
                preparedById: localStorage.getItem("userId"),
                active: true,
            },

            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,

            policyDocuments: [],
            policyDocumentFields: [
                {key:'index',label: 'SN'},
                // { key: 'policyDocumentId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                // { key: 'functionality', label: 'Functionality', sortable: true, sortDirection: 'desc' },
                { key: 'policyDocumentType', label: 'Type', sortable: true, sortDirection: 'desc' },
                // { key: 'policy', label: 'Description', tdClass: 'table-cw-250', sortable: true, sortDirection: 'desc' },
                // { key: 'reportedDt', label: 'PolicyDocument Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                // { key: 'solutionDt', label: 'Solution Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                // { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                // { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',            
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            attachmentInfo: {
                title: 'Attachment'
            },
            attachmentData: {},

            attachments: [],

            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            }
        }
    },
    mounted() {
        if (localStorage.getItem("ermId") != '')
            this.entityRecordMasterId = localStorage.getItem("ermId");
        else
            this.entityRecordMasterId = this.$route.params.ermId;
        this.lookupData();
        this.getPolicyDocument();
    },
    validations: {
        policyDocument: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getPolicyDocument: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/document/policydocument/list', data)
                .then((response) => {
                    let result = response.data;
                    this.policyDocuments = result.policyDocumentList;
                    this.totalRows = this.policyDocuments.length;
                    console.log(this.policyDocuments);
                    this.selPolicyDocument=this.policyDocuments[0];
                    this.viewItemDetails(this.selPolicyDocument); 
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/document/policydocument/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.policyDocumentTypes = result.customTypeLookup;
                    this.statuses = result.statusLookup;
                })

        },
        async saveData() {
            this.$v.policyDocument.$touch();
            if (!this.$v.policyDocument.$invalid) {
                this.showOverlay = true;   
                let result;
                if (this.elements.editMode) {
                    result = await this.updatePolicyDocument();
                    // await this.$refs.attachmentControl.saveAttachment(this.entity, this.policyDocument.id);
                } else {
                    result = await this.savePolicyDocument();
                    // await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }

                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getPolicyDocument();
                    this.clearData();
                }
            }
        },
        savePolicyDocument: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/document/policydocument/add', this.policyDocument)

            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updatePolicyDocument: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/document/policydocument/update', this.policyDocument)
            }
            catch(error) {
                this.showOverlay = false;
                if (error.response) {
                   // Request made and server responded  
                   console.log(error.response.data.headers.message);
                } else if (error.request) {
                   // The request was made but no response was received
                   console.log(error.request);
                } else {
                   // Something happened in setting up the request that triggered an Error
                   console.log('Error', error.message);
                }
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }

        },
        deletePolicyDocument: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/document/policydocument/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getPolicyDocument();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.attachmentInfo.title = "Attachment";
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.policyDocument.id = '';
            this.policyDocument.policyDocumentId = '';
            this.policyDocument.policyDocumentTypeId = '';
            this.policyDocument.title = '';
            this.policyDocument.policy = '';
            // this.policyDocument.preparedById = '';
            this.policyDocument.active = true;
            // this.$refs.attachmentControl.clearAttachment();
            this.$v.policyDocument.$reset();

            // this.setFocus();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.policyDocument.id = data.id;
            this.policyDocument.policyDocumentId = data.policyDocumentId;
            this.policyDocument.vhextAccountId = data.vhextAccountId;
            this.policyDocument.entityRecordMasterId = data.entityRecordMasterId;
            this.policyDocument.policyDocumentTypeId = data.policyDocumentTypeId;
            this.policyDocument.policyDocumentType = data.policyDocumentType;
            this.policyDocument.title = data.title;
            this.policyDocument.policy = data.policy;
            this.policyDocument.preparedById = data.preparedById;
            this.policyDocument.preparedBy = data.preparedBy;
            this.policyDocument.active = data.active;

            // this.$nextTick(() => {
            //     this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            // })

            // this.setFocus();
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.policyDocument.id = data.id;
            this.policyDocument.policyDocumentId = data.policyDocumentId;
            this.policyDocument.vhextAccountId = data.vhextAccountId;
            this.policyDocument.entityRecordMasterId = data.entityRecordMasterId;
            this.policyDocument.policyDocumentTypeId = data.policyDocumentTypeId;
            this.policyDocument.policyDocumentType = data.policyDocumentType;
            this.policyDocument.title = data.title;
            this.policyDocument.policy = data.policy;
            this.policyDocument.preparedById = data.preparedById;
            this.policyDocument.preparedBy = data.preparedBy;
            this.policyDocument.active = data.active;
            // this.$nextTick(() => {
            //     this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            // })

            // this.setFocus();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                console.log(data.id);
                
                this.viewItemDetails(data); 

                this.elements.showDetail = true;
                this.showItemDetail = false;
            }
        },

        viewItemDetails(data) {
            this.itemDetailTitle = data.title;
            this.itemDetailType = data.policyDocumentType;
            this.itemDetailStatus = data.status;

            let referencePopupId = parseInt(data.id);
            // this.attachmentData.refEntity = this.entity;
            // this.attachmentData.refRecordId = data.id;
            this.showItemDetail = true;
            this.$nextTick(() => {
                this.$refs.policyDocumentDetailCompRef.getDetails(referencePopupId, this.entity);
            })
        },

        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },


    }
}
</script>