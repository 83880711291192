<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-2 well">
                            <h4 class="pl-2 mb-0 fs-16">VMX Status Settings</h4>
                        </div>
                        <div class="col-lg-3 col-sm-2 well">
                            <h5 class="pl-2 mb-0 fs-16">{{activeDisplayName}}</h5>
                        </div>
                        <div class="col-lg-5 col-sm-3">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <h6>Status Lookup</h6>        
                                </div>
                                <div class="col-6">
                                    <div class="dataTables_filter">
                                        <label>
                                            <i class="fa fa-search icon-search" aria-hidden="true"></i>
                                            <input type="search" v-model="lookupSearch" class="form-control search-task" placeholder="Search Status..">
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <ul class="typeLookup_list scroll-container">
                                <li v-for = "entity in filterLookup" >
                                    <a :class="{'active' : entity.id == activeLookup}" @click="getLookups(entity.id,entity.entity,entity.displayName); activeLookup = entity.id; activeDisplayName = entity.displayName; ">{{entity.displayName}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-xl-8 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="goalStatus" class="typeLookup active">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{activeDisplayName}}
                                                </span>
                                                <span v-if="elements.editMode">Edit</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-add" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row">
                                                    <div class="col-2 col-sm-2 col-md-2">
                                                        <div class="form-group">
                                                            <label for="id">Id</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.statusLookup.id.$error }">
                                                                <input v-if="!elements.editMode" type="text" v-model="statusLookup.id" class="form-control" id="displayName" placeholder="Enter Id">
                                                                <input v-if="elements.editMode" type="text" v-model="statusLookup.id" class="form-control" disabled id="displayName" placeholder="Enter Id">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.statusLookup.id.$error && !$v.statusLookup.id.required" class="text-danger">Please Enter Id</label>
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="duration">Status</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.statusLookup.status.$error }">
                                                                <b-form-input id="title" v-model="statusLookup.status" placeholder="Enter Status"></b-form-input>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.statusLookup.status.$error && !$v.statusLookup.status.required" class="text-danger">Please Enter Status</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="statusLookup.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-5 pull-right">
                                                        <label for="add" style="visibility: hidden;">Add</label>
                                                        <div class="input-group float-right ">
                                                            <button type="submit" title="Save" @click="saveStatusData" class="btn btn-primary mb-0 mr-2 py-1 px-3">
                                                                <i class="fa fa-plus pr-2" v-if="!elements.editMode"></i>
                                                                <i class="fa fa-floppy-o pr-2" v-else ></i>
                                                                {{ elements.editMode ? 'Save' : 'Add'}}
                                                            </button>
                                                            <button type="submit" title="Clear" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="row view-filter">
                                    <div class="col-sm-12">
                                        <div class="float-right">
                                            <div class="dataTables_length">
                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                </b-form-group>
                                            </div>
                                        </div>
                                        <div class="float-left">
                                            <div class="dataTables_filter">
                                                <label>
                                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                </label>
                                            </div>
                                        </div>
                                        <div class="clearfix"></div>
                                    </div>
                                </div>
                                <b-table show-empty small stacked="md" sort-icon-left :items="statusLookups" :fields="fields" responsive="sm" :current-page="statusLookup.currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template v-slot:cell(statusLookupId)="data">
                                        <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                                            <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('status_lookup_id', data.item.id, data.value)" @blur="cancelInlineEdit(statusLookups, data.field.key)">
                                        </div>
                                        <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value ? data.value : 'Edit' }}</div>
                                    </template>                                    
                                    <template v-slot:cell(masterStatus)="data">
                                        <div v-if="data.item.id == idEdit" class="action-link">
                                            <select v-model="statusLookup.masterStatusId" @change="updateMasterStatus(data.item.id,statusLookup.masterStatusId)" class="form-control" id="masterStatusId" @blur="cancelIdEdit()">
                                                <option value=null>Select</option>
                                                <option v-for="(info, index) in masterStatusLookup" :value="info.id">{{info.status}}</option>
                                            </select>                            
                                        </div>
                                        <div v-else class="action-link" @dblclick="idEdit=data.item.id;idValue=data.item.masterStatus;statusLookup.masterStatusId=data.item.masterStatusId;getMaterStatusLookups(data.item.masterEntityId)"> {{ data.item.masterStatus ? data.item.masterStatus : 'Select' }}</div>
                                    </template>                                    
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox  v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(actions)="data">
<!--                                         <a v-b-modal.masterStatusMapModal href="javascript:" title="Master Status Map" @click="masterStatusMap(data.item)"><i class="fa fa-copy pl-2"></i></a> -->
                                        <a href="javascript:" title="Edit Statuses" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteStatusLookup(data.item.id)" data-toggle="modal" data-target="#deleteStatusLookup"><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="">
                                        <div class="text-center">No {{activeDisplayName}} found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="">
                                        <div class="text-center">{{ }}</div>
                                    </template>
                                </b-table>
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                <b-pagination v-if="statusLookups.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>                                
                                
<!--                                 <b-pagination v-if="statusLookups.length!=0" pills v-model="statusLookup.currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MasterStatusMap ref="masterStatusMapRef" :masterData="masterData" :key="masterStatusMapKey"/>
        <Dialog id="deleteStatusLookup" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Status ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import moment from 'moment';
import MasterStatusMap from '@/components/popup/lookup/MasterStatusMap.vue';
export default {
    name: 'app',
    components: {
        MasterStatusMap,
    },
    
    data() {
        return {
             elements: {
                visible: false,
                editMode: false
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            lookupSearch: '',
            activeLookup: '',
            activeDisplayName: '',

            idEdit: '',
            idValue: '',

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            masterStatusMapKey: 0,

            notEnabled: true,
            statusLookup: {
                id: '',
                statusLookupId: '',
                entity: '',
                active: true,
                statusType: '',
                status: '',
                statusCode: '',
                masterStatusId: '',
                vhextAccountId: '',
                projectId: '',
                entityId: '',
                userId: '',
                createdById: localStorage.getItem("userId"),
            },
            masterData: {
                // refErmId: 0,
                // title: '',
                // refEntityId: 0,
                // refErdTitle: '',
                // refEntity: '',
                // sharedUrl: '',
                // previewURL: '',
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: '',
            entityId: '',
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortDesc: false,
            pageOptions: [5, 10, 15, 20, 25],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            entities: [],
            masterStatusLookup: [],
            statusLookups: [],
            fields: [
                { key: 'index', label: 'SN', },
                { key: 'id', label: 'PK Id', sortable: true, sortDirection: 'desc' },
                { key: 'statusLookupId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'entityId', label: 'Entity Id', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'statusTypeName', label: 'Status Name', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'statusCode', label: 'Code', sortable: true, sortDirection: 'desc' },
                { key: 'masterStatus', label: 'Master Status', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            search: {
                params: {
                    title: ""
                }
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filterLookup() {
            return this.entities.filter(data => {
                return data.displayName.toLowerCase().includes(this.lookupSearch.toLowerCase())
            })
        },        
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    validations: {
        statusLookup: {
            id: {
                required
            },
            status: {
                required
            }
        }
    },
    // watch: {
    //     'entity': {
    //         handler: function(val1, val2) {
    //             this.getStatusLookups();
    //         },
    //         deep: true,
    //         immediate: true
    //     },
    // },
    mounted() {
        this.getEntities();
    },
    methods: {
        getEntities: function() {
            let data = {                                                                                                    
                // 1 - Database Table
                // 2 - Master Record
                // 3 - Type
                // 4 - Category
                // 5 - Status
                // 6 - Priority
                // 7 - Severity
                // 8 - Scope
                entityTypeId: 5
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/entity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.entities = result.entityList;
                    if (this.filterLookup.length > 0) {
                        let entityId = this.filterLookup[0].id;
                        this.entity = this.filterLookup[0].entity;
                        this.activeLookup = this.filterLookup[0].id;
                        this.activeDisplayName = this.filterLookup[0].displayName;
                        this.getStatusLookups(entityId);
                    }
                    console.log(this.filterLookup.length)
                    
                    this.totalRows = this.filterLookup.length;
                    console.log(this.filterLookup);
                })
        },
        getLookups: function(entityId,entity,displayName) {
            if (entityId != '') {
                this.entityId = entityId;
                this.entity = entity;
                this.activeDisplayName = displayName;
                // this.statusLookup.statusType = displayName;
                console.log(entityId,entity,this.activeDisplayName);
                this.getStatusLookups(entityId);
            }
        },
        getStatusLookups: function(entityId) {
            if (entityId != '') {
                this.entityId = entityId;
                let data = {
                    entityId: entityId
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/status/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.statusLookups = result.statusLookupList;         
                        if (this.statusLookups != null)
                            this.totalRows = this.statusLookups.length;
                    })
            }
        },
        getMaterStatusLookups: function(entityId) {
            if (entityId != '') {
                this.entityId = entityId;
                let data = {
                    entityId: entityId
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/status/list', data)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.masterStatusLookup = result.statusLookupList;         
                        if (this.masterStatusLookup != null)
                            this.totalRows = this.masterStatusLookup.length;
                    })
            }
        },

        saveStatusData: function() {
            this.$v.statusLookup.$touch();
            if (!this.$v.statusLookup.$invalid) {
                if (this.elements.editMode) {
                    this.updateStatusLookup();
                } else {
                    this.addStatusLookup();
                }
            }
        },
        addStatusLookup: function() {
            this.statusLookup.statusLookupId = this.statusLookup.id;
            this.statusLookup.vhextAccountId = this.vhextAccountId;
            this.statusLookup.projectId = this.projectId;
            this.statusLookup.entityId = this.entityId;
            this.statusLookup.entity = this.entity;
            this.statusLookup.statusType = this.entity;

            console.log(this.statusLookup);
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/status/add', this.statusLookup)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getStatusLookups(this.entityId);
                    }
                })
        },
        updateStatusLookup: function() {
            console.log(this.statusLookup)
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/status/update', this.statusLookup)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getStatusLookups(this.entityId);
                    }
                })
        },
        updateMasterStatus: function(id,masterStatusId) {
            let data = {
                id: id,
                masterStatusId: masterStatusId,
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/lookup/manage/master/status/update', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.idEdit='';
                        this.statusLookup.masterStatusId ='';
                        this.clearData();
                        this.getStatusLookups(this.entityId);                        
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: 'status_lookup',
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getStatusLookups(this.entityId);                        
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },

        cancelIdEdit: function() {
            this.entities = this.entities.map(data => {
                if(data.id == this.idEdit) {
                    data.masterEntity = this.idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';
            this.entity.masterEntityId='';

        },                        
        deleteStatusLookup: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            console.log(this.statusLookup)
            axios.delete(this.$store.getters.getAPIBasePath + '/lookup/manage/status/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    console.log(result);
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getStatusLookups(this.entityId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.statusLookup.entity = 'status_lookup';
                this.statusLookup.id = item.id;
                this.statusLookup.active = 0;

                this.updateActivationState();

            } else {
                this.statusLookup.entity = 'status_lookup';
                this.statusLookup.id = item.id;
                this.statusLookup.active = 1;

                this.updateActivationState();
            }
            console.log(this.statusLookup.id, this.statusLookup.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.statusLookup)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.statusLookup.active = true;
                    this.getStatusLookups(this.entityId);
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

        clearData: function() {
            this.statusLookup.id = '';
            this.statusLookup.status = '';
            this.$v.statusLookup.$reset();
            this.elements.editMode = false;
            this.elements.visible = false;
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.statusLookup.id = data.id;            
            this.statusLookup.statusLookupId = data.statusLookupId;
            this.statusLookup.statusType = data.statusType;
            this.statusLookup.status = data.status;
            this.statusLookup.statusCode = data.statusCode;
            this.statusLookup.active = data.active;
            this.statusLookup.vhextAccountId = data.vhextAccountId;
            this.statusLookup.projectId = data.projectId;
            this.statusLookup.entityId = data.entityId;
            this.statusLookup.entity = data.entity;

        },
        // masterStatusMap: function(data) {
        //     console.log(data);

        //     // this.masterData.refErmId = data.id;
        //     // this.masterData.title = data.title;
        //     // this.masterData.refEntity = this.entity;
        //     // console.log(this.masterData.refErmId, this.masterData.title,this.entity);

        //     this.masterStatusMapKey ++;
        //     this.$nextTick(() => {
        //         this.$refs.masterStatusMapRef.onRefDataChange();
        //     });
        // },

        deActivate: function(item) {
            this.$nextTick(() => {
                console.log(item.active, item.id)

                if (item.active == true) {
                    this.statusLookup.id = item.id;
                    this.statusLookup.active = 1;
                    this.statusLookup.userId = this.userId;
                    this.updateStatusLookupActiveState();
                    console.log(item.active, this.statusLookup.active, item.id)
                } else {
                    this.statusLookup.id = item.id;
                    this.statusLookup.active = 0;
                    this.statusLookup.userId = this.userId;
                    this.updateStatusLookupActiveState();
                    console.log(item.active, this.statusLookup.active, item.id)
                }
            })
        },
        
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        deleteProject: function(id) {

        },
        showTab: function() {
            console.log(this);
        }
    }
}
</script>