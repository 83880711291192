<template>
	<div>
		<MainMenu :key="topMenuReloadKey" />
	    <main class="default-transition" style="opacity: 1;">
        </main>
        <div class="landing-page">
        	<div class="row no-gutters">
        		<div class="col-2">    		
    		        <div class="row">
    		            <div class="col-12 pb-1" v-for="setting in settingModules" >
    		                <a href="javascript:" :title="setting.displayName" class="settings-box make-it-slow bg-white" @click="handleModuleClick(setting.id, setting.webUrl);" :class="activeModule==setting.id ? 'active' : ''" >
    		                    <div class="row col-12 ml-0 left-bar-tab py-3">
    		                        <div class="col-2">
    		                            <i class="fa fa-lg icon-b" :class="setting.icon ? setting.icon : 'fa-cogs'" aria-hidden="true"></i>
    		                        </div>
    		                        <div class="col-8 text-left align-self-center">
    		                            <p class="m-0 font-weight-bold">{{ setting.module }}</p>
    		                        </div>
                                    <div class="col-2" v-if="activeModule==setting.id">
                                        <i class="fa fa-lg fa-hand-o-right text-primary" aria-hidden="true"></i>
                                    </div>                                
    		                    </div>
    		                </a>
    		            </div>
    		        </div>
    		    </div>
    		    <div class="col-10">
    			    <slot></slot>
    		    </div>
        	</div>
        </div>
        <RightMenu />
    </div>
</template>
<script>
import MainMenu from '@/layouts/blocks/Menu';
import RightMenu from '@/layouts/blocks/RightMenu';
import iziToast from 'izitoast';
import { EventBus } from '@/main';
export default {
    name: 'vsasettings',
    components: {
        MainMenu,
        RightMenu,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            showOverlay: false,
            activeComponent: '',
            // showComponent: false,
            activeTab: 101,
            topMenuReloadKey: 0,

            settingModules: [],
            // settingSubMenu: [],
        }
    },
    computed: {
        activeModule: {
            get() {
                return this.$store.state.adminSetting.moduleId;
            },
            set(value) {
                this.$store.state.adminSetting.moduleId = value;
            }
        },
        showComponent: {
            get() {
                return this.$store.state.adminSetting.showComponent;
            },
            set(value) {
                this.$store.state.adminSetting.showComponent = value;
            }
        },
        // subMenuByModule() {
        //     return this.settingSubMenu.filter(menu => menu.moduleId == this.activeModule);
        // }
    },
    created() {
        EventBus.$on('onAdminSettingReload', () => {
            this.onAdminSettingReload()
        });
        EventBus.$on('onTopMenuReload', () => {
            this.onTopMenuReload();
        });
    },
    mounted() {
        this.loadMenu();
    },
    beforeDestroy() {
        EventBus.$off('onTopMenuReload');
        EventBus.$off('onAdminSettingReload');
        this.activeModule = null;
        this.showComponent = false;
    },
    methods: {
        loadMenu() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: localStorage.getItem("userId"),
                userTypeCode: this.userTypeCode,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/menu/adminsettingmodules', data)
                .then((response) => {
                    let result = response.data;
                    this.settingModules = result.adminSettingModuleLookup;
                    if (this.settingModules.length>0) {
                        this.activeModule = this.activeModule ? this.activeModule : this.settingModules[0].id;
                    }
                })
        },
        setActiveTab: function (setting) {
            this.showComponent = true;
            this.activeComponent = (setting.customSubMenu).replace(/\s/g, "");
            this.activeComponent = this.activeComponent.replaceAll('&', '');
            console.log(this.activeComponent)
            this.activeTab = setting.id;
        },
        onAdminSettingReload() {
            this.loadMenu();
        },
        handleModuleClick(moduleId, webUrl) {
        	this.showComponent = false;
        	this.activeModule = moduleId;
        	if (this.userTypeCode == 'VSA') {
	        	if (this.$route.path !== '/landing/vsa/settings') {	
		        	this.$router.push('/landing/vsa/settings');
	        	}
        	}
        	else {
	        	if (this.$route.path !== '/landing/admset') {	
		        	this.$router.push('/landing/admset');
	        	}
        	}
        },
        onTopMenuReload() {
            this.topMenuReloadKey++;
        }

    }
}
</script>