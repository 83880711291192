<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc :title="requirementData.editMode ? 'Edit Requirement' : 'Quick Requirement'" no-enforce-focus @hide="closeDialog" id="quickReqPopup" ref="quickReqPopupRef" size="md" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="3">Title</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.requirementData.title.$error }">
                                <b-form-input id="title" v-model="requirementData.title" placeholder="Enter Requirement Title" required></b-form-input>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.requirementData.title.$error && !$v.requirementData.title.minLength" class="text-danger">Title must have at least {{$v.requirementData.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.requirementData.title.$error && !$v.requirementData.title.required" class="text-danger">Please enter Title</label>
                            <label v-if="$v.requirementData.title.$error && !$v.requirementData.title.maxLength" class="text-danger">Title must not exceed {{$v.requirementData.title.$params.maxLength.max}} letters.</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Due Date</b-col>
                        <b-col>
                            <input type="date" class="form-control" v-model="requirementData.dueDate" id="dueDate">
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Type</b-col>
                        <b-col cols="8" class="pr-1">
                            <select v-model="requirementData.requirementTypeId" id="requirementTypeId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in requirementTypes" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                        <a :title="typeLookupTitle" class="action-link" href="javascript:" @click="typePopupKey++" v-b-modal.quickReqTypeLookupPopup><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Priority</b-col>
                        <b-col>
                            <select v-model="requirementData.priorityId" id="priorityId" class="form-control">
                                <option value="">Select</option>
                                <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                            </select>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="3">Status</b-col>
                        <b-col>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.requirementData.statusId.$error }">
                                <select v-model="requirementData.statusId" id="statusId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.requirementData.statusId.$error && !$v.requirementData.statusId.required" class="text-danger">Please Select a Status</label>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="3">Requirement Master</b-col>
                        <b-col cols="8 pr-1">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.requirementData.entityRecordMasterId.$error }">
                                <select v-model="requirementData.entityRecordMasterId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in ermFiltered" :value="info.id">{{info.value}}</option>
                                </select>
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.requirementData.entityRecordMasterId.$error && !$v.requirementData.entityRecordMasterId.required" class="text-danger">Please Select a Requirement</label>
                        </b-col>
                        <a title="Create new requirement master" class="action-link" href="javascript:" @click="openReqPop"><i class="fa fa-cog setting-icon-alt" aria-hidden="true"></i></a>
                    </b-row>
                    <div class="row">
                        <div class="col-12 col-sm-12">
                            <div class=" mt-2 mb-3">
                                <div class="card-body py-1 px-0">
                                    <p class="mb-2 mt-2 pl-2"><span>Requirement</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12 m-0 px-3">
<!--                                             <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="requirementData.requirementSpecification"></editor> -->
                                            <VmxEditor v-model="requirementData.requirementSpecification"></VmxEditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <b-row class="mb-2">
                        <b-col cols="3">Requirement</b-col>
                        <b-col>
                            <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="requirementData.requirementSpecification"></editor>
                        </b-col>
                    </b-row> -->
                </b-container>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>{{ requirementData.editMode ? 'Updating..' : 'Adding..' }}
                        </span>
                        <span v-else>{{ requirementData.editMode ? 'Update Requirement' : 'Add Requirement' }}</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
        <TypeLookup id="quickReqTypeLookupPopup" :key="typePopupKey" ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <ERMMasterPopup id="quickReqErmPopup" v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="lookupData();masterLookupRefresh();" @closeDialog="showDialog=false" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

import Editor from '@tinymce/tinymce-vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'
import VmxEditor from '@/components/usercontrols/VmxEditor';

export default {
    props: {
        projectTypeId: [String, Number],
        requirementData: Object,
    },
    components: {
        Editor,
        ERMMasterPopup,
        TypeLookup,
        VmxEditor,
    },
    data() {
        return {
            showOverlay: false,
            entityERMLookup: [],
            requirementStatus: [],
            requirementTypes: [],
            requirementPriorities: [],

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: 'requirement_spec',

            ermMasterInfo: {
                title: "Requirement Master",
                entity: 'system_requirement_spec_master',
                refEntity: 'requirement_spec',
            },

            showDialog: false,
            entity: 'requirement_spec',
            typeEntity: 'requirement_spec_type',
            typeLookupTitle: 'Requirement Type Settings',
            typePopupKey: 0,

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools','lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        ermFiltered() {
            // Freezed items should not be shown
            return this.entityERMLookup.filter((erm)=> erm.isFreeze !== true);
        }
    },
    mounted() {
        this.lookupData();
        console.log(this.requirementData)
    },
    validations: {
        requirementData: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            entityRecordMasterId: {
                required
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/reqboard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.requirementTypes = result.customTypeLookup;
                    this.requirementStatus = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    this.entityERMLookup = result.entityERMLookup;
                    console.log(result);
                }).catch((error)=> this.showOverlay = false);
        },
        saveData: function() {
            console.log(this.requirementData)
            this.$v.requirementData.$touch();
            if (!this.$v.requirementData.$invalid) {
                this.showOverlay = true;                
                if (this.requirementData.editMode) {
                    this.updateRequirement();
                } else {
                    this.saveRequirement();
                }
            }
        },
        saveRequirement: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/add', this.requirementData)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$bvModal.hide('quickReqPopup');
                        this.$emit('refreshData');
                        this.clearData();
                    }
                }).catch((error)=> this.showOverlay = false);
        },
        updateRequirement: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/update', this.requirementData)
                .then((response) => {
                    console.log(this.requirementSpec);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$bvModal.hide('quickReqPopup');
                        this.$emit('refreshData');
                        this.clearData();
                    }
                }).catch((error)=> this.showOverlay = false);
        },
        clearData: function() {
            this.requirementData.title = '';
            this.requirementData.dueDate = new Date().toISOString().substr(0, 10);
            this.requirementData.statusId = '';
            this.requirementData.requirementSpecification = '';
            this.requirementData.priorityId = '';
            this.requirementData.entityRecordMasterId = '';
            this.requirementData.editMode = false;
            this.$v.requirementData.$reset();
        },
        masterLookupRefresh() {
            this.$emit('masterLookupRefresh');
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('quickReqErmPopup');
            })
        }
    }
}
</script>