<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Task List Document</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <div class="ingov-app-header">
                                            <div class="app-options">
                                                <div class="app-menu1">
                                                    <a href="#" title="Comment">
                                                        <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print pr-2  action-link"></i></a>
                                    </li>
                                    <li class="breadcrumb-item" title="Download">
                                        <a href="#" @click.prevent="print"><i class="fa fa-download pr-2  action-link"></i></a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12 col-xl-12 report-tables">
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="dl-horizontal">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Title</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.title}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdBy}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-4 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                                            </div>
                                                            <div class="col-12 col-md-8 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">{{erm.createdDt | formatDate}}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <div class="row">
                                                            <div class="col-12 col-md-5 pr-0">
                                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Approved By</label>
                                                            </div>
                                                            <div class="col-12 col-md-7 pl-0">
                                                                <label class="p-2 mb-0 d-block header-details">NULL</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Reviews</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="ermReviewApprovals" :fields="ermReviewApprovalFields" responsive="sm" :current-page="currentPage" :per-page="perPage"  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Reviews found</div>
                                            </template>                                     
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Task List</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <label class="p-2 mb-0 d-block header-details" v-html="erm.description"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2">
                        <div class="card-body p-3">
                            <p class="contact-title"><span>Task List</span></p>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <div class="data-table-rows  data-tables-hide-filter">
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="taskLists" :fields="taskListFields" responsive="sm" :current-page="erm.currentPage" :per-page="erm.perPage"  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Task List  found</div>
                                            </template>                                           
                                        </b-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <nav class="app-menu-nav chat-box-comments">
                    <div class="comments-titel">
                        <label class="d-block">Chat Comments <span><a href="#" class="float-right close-comments"><i class="fa fa-times " aria-hidden="true"></i></a></span></label>
                    </div>
                    <div class="comments-list mt-0">
                        <div class="row">
                            <div v-for="loadComment in comments">
                                <div class="col-12">
                                    <ul class="comments-list reply-list">
                                        <li>
                                            <div class="user-icon">
                                                <span class="user-name-icon"> RM </span>
                                            </div>
                                            <div class="comment-box">
                                                <div class="comment-head">
                                                    <h6 class="comment-name by-author"><a href="">{{ loadComment.commentedBy }}</a></h6>
                                                    <span>{{ loadComment.commentDt | formatDateTime }}</span>
                                                </div>
                                                <div class="comment-content">{{ loadComment.comment }}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="comments-cox pt-2">
                        <div class="row m-0">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="user-comments w-100 position-relative">
                                        <input type="text" class="form-control" v-model="comment.comment" id="initialBudget" placeholder="Comments">
                                        <button type="submit" @click="addComment" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 chat-sent-btn"><i class="fa fa-check pr-2"></i>Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            erm: {
                currentPage: 1,
                perPage: 5,
            },
            comment: {
                comment: '',
                commentedById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: this.$route.params.ermId
            },

            isBusy: true,
            taskLists: [],
            erms: [],
            ermReviewApprovals: [],
            comments: [],

            taskListFields: [
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                //{ key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],

            ermReviewApprovalFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', sortable: true, sortDirection: 'desc' }
            ],
            selectMode: 'single',
            notEnabled: true,
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.initialSettings();
        this.entityRecordMasterCollection();
        this.getTasks();
        this.getComments();
    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.userId = localStorage.getItem("userId");
        },
        entityRecordMasterCollection: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.erm = result.entityRecordMaster;
                    this.ermReviewApprovals = result.entityReviewApprovalList;
                })
        },
        getTasks: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskList;
                    this.allTasks = this.tasks;
                    console.log(this.tasks);
                    this.timeSheetLookupData();
                })
        },
        getComments: function() {
            let data = {
                entityRecordMasterId: this.$route.params.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.comments = result.commentList;
                })
        },
        addComment: function() {
            console.log(this.comment);
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/add', this.comment)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments();
                    }
                })
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },    
    }
}
</script>