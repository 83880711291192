<template>
    <div>
        <b-modal ref="addErmMasterModal" size="lg" :id="id" modal-class="addErmMasterModal" :title="ermMasterTitle ? ermMasterTitle : ermMasterInfo.tooltip" @hide="closeDialog" :no-close-on-backdrop="true" ok-title="Ok">
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row">
                    <div class="col-12">
                        <div class="card mb-4">
                            <div class="card-body p-3">
                                <div id="scope-app" class="mb-3">
                                    <b-card-body class="p-1">
                                        <div class="row mb-2">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="title">{{ ermMasterInfo.titleLabelName ? ermMasterInfo.titleLabelName : 'Title' }}</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                                        <input type="text" class="form-control" v-model="entityRecordMaster.title" id="title" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }" placeholder="Enter Title">
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.minLength" class="text-danger">Title must have at least {{$v.entityRecordMaster.title.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger">Please enter Title</label>
                                                    <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.maxLength" class="text-danger">Title must not exceed {{$v.entityRecordMaster.title.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showTypeLookup" class="col-6">
                                                <div class="form-group">
                                                    <label for="ermTypeId">{{ermMasterInfo.typeLabelName}}</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.ermTypeId.$error }">
                                                        <select v-model="entityRecordMaster.ermTypeId" class="form-control" @change="handleErmTypeChange" :class="{ 'is-invalid': $v.entityRecordMaster.ermTypeId.$error }" id="ermTypeId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.entityRecordMaster.ermTypeId.$error && !$v.entityRecordMaster.ermTypeId.required" class="text-danger">Please select a type</label>

                                                </div>
                                            </div>
<!--                                             <div v-else class="col-6">
                                            </div>                                             -->
                                            <template v-if="ermMasterInfo.showErmDate" class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="ermDate">Month</label>
                                                        <input type="month" class="form-control" v-model="entityRecordMaster.ermDate" id="ermDate" @change="setPeriod">
                                                    </div>
                                                </div>
                                            </template>  

                                            <template v-if="ermMasterInfo.showDateRange">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="startDate">Start Date</label>
                                                        <input type="date" class="form-control" v-model="entityRecordMaster.startDate" id="startDate">
                                                    </div>
                                                </div>
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label for="endDate">End Date</label>
                                                        <input type="date" class="form-control" v-model="entityRecordMaster.endDate" id="endDate">
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                        <div class="row">
                                            <div v-if="!ermMasterInfo.hideProject && !this.projectId" class="col-6">
                                                <div class="form-group">
                                                    <label for="projectId">Project</label>
                                                    <select v-model="entityRecordMaster.projectId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in projectLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                                
                                            </div>
<!--                                             <div v-else>
                                                <label>{{this.projectName}}</label>
                                            </div>
 -->                                            
                                            <div v-if="userTypeCode !== 'CLI' && !ermMasterInfo.hideClient" class="col-6">
                                                <div class="form-group">
                                                    <label for="client">Client</label>
                                                    <select v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div v-if="ermMasterInfo.showMultipleMasterControl" class="col-12 col-sm-12">
                                                <div class="form-group">
                                                    <label for="title1">Link Financial Transaction Group</label>
                                                    <multiselect v-model="refErmList" :options="ermLookup" name="masterLink" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                                </div>
                                            </div>
                                            <!-- <div v-if="ermMasterInfo.showSingleMasterControl"  class="col-6 col-sm-6">
                                                <div class="form-group">
                                                    <label for="title1">Link Budget Plan</label>
                                                    <select v-model="entityRecordMaster.refErmId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in ermLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div> -->
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="description">{{ ermMasterInfo.descriptionLabelName ? ermMasterInfo.descriptionLabelName : 'Description' }}</label>
                                                    <textarea v-model="entityRecordMaster.description" class="form-control" id="description" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <a class="float-right">
                                            <input type="checkbox" class="align-middle mr-1" v-model="entityRecordMaster.isPublic"><span class="align-middle d-inline-block">Public</span>
                                        </a>
                                    </b-card-body>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
            <template #modal-footer>
                <b-button variant="primary" :disabled="showOverlay" size="sm" @click="saveData" class="float-right mr-1">
                    <span v-if="showOverlay"><b-spinner small label="Small Spinner" class="mr-2"></b-spinner>Adding..</span>
                    <span v-else>Save</span>
                </b-button>
                <b-button variant="outline-secondary" :disabled="showOverlay" size="sm" @click="closeDialog" class="float-right mr-1">
                    Cancel
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
        id: { type: String, default: 'addErmMasterModal' },
        ermMasterInfo: Object,
    },
    components: {
        Multiselect
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            
            showOverlay: false,
            error: false,
            editMode: false,
            clients: [],
            refErmList: [],
            ermLookup: [],
            entityRecordStatus: [],
            entityRecordCustomType: [],
            allUsers: [],
            projectLookup: [],
            ermMasterTitle: null,
            entityRecordMaster: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.ermMasterInfo.entity,
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                ermDate: this.ermMasterInfo.showErmDate ? this.getMonthYear(new Date()) : '',
                startDate: this.getDateValue(new Date()),
                endDate: this.getDateValue(new Date()),
                refEntityId: '',
                refEntity: this.ermMasterInfo.refEntity,
                isFreeze: false,
                isArchive: false,
                isPublic: false,
                statusId: '',
                createdById: '',
                attachments: []
            },
        }
    },
    computed: {
        users() {
            if (this.userTypeCode == 'CLI') {
                return this.allUsers.filter((user) => user.id == this.userId)
            } else return this.allUsers;
        },
    },
    mounted() {
        this.lookupData();
        this.setDefaultValue();
        this.masterLinkLookupData();
    },
    validations() {
        if (this.ermMasterInfo.showTypeLookup) {
            return {
                entityRecordMaster: {
                    title: {
                        required,
                        minLength: minLength(2),
                        maxLength: maxLength(100)
                    },
                    ermTypeId: {
                        required,
                    }
                }
            }
        } else {
            return {
                entityRecordMaster: {
                    title: {
                        required,
                        minLength: minLength(2),
                        maxLength: maxLength(100)
                    },
                }
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.entityRecordMaster.vhextAccountId,
                projectId: this.entityRecordMaster.projectId,
                userId: this.userId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
            }
            console.log(data);

            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordStatus = result.statusLookup;
                    this.allUsers = result.userLookup;
                    this.clients = result.clientLookup;
                    this.projectLookup = result.projectLookup ? result.projectLookup : [];
                    this.entityRecordCustomType = result.typeLookup;
                    if (this.ermMasterInfo.typeCode && this.ermMasterInfo.typeCode == 'cli') {
                        this.entityRecordCustomType = this.filterCustomType(this.entityRecordCustomType, 'Financial Transaction');
                    }
                    if (this.entityRecordCustomType && this.entityRecordCustomType.length == 1) {
                        this.entityRecordMaster.ermTypeId = this.entityRecordCustomType[0].id;
                        this.handleErmTypeChange();
                    }
                    this.entityRecordMaster.entityId = result.entityId;
                    this.entityRecordMaster.refEntityId = result.refEntityId;
                    this.entityRecordMaster.createdById = localStorage.getItem("userId");
                    this.entityRecordMaster.statusId = this.entityRecordStatus && this.entityRecordStatus.length > 0 ? this.entityRecordStatus[0].id : '';
                })

        },
        masterLinkLookupData: function() {
            if (this.ermMasterInfo.masterLinkEntity) {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId"),
                    userId: localStorage.getItem("userId"),
                    entity: this.ermMasterInfo.masterLinkEntity
                }
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                    .then((response) => {
                        let result = response.data;
                        this.ermLookup = result.entityERMLookup;
                    })
            }
        },
        saveData: function() {
            if (this.userTypeCode == 'CLI') {
                this.entityRecordMaster.clientId = this.userId;
            }
            this.$v.entityRecordMaster.$touch();
            if (!this.$v.entityRecordMaster.$invalid) {
                if (this.editMode) {
                    this.updateERMaster();
                } else {
                    this.saveERMMaster();
                }
            }
        },
        saveERMMaster: function() {
            if (this.ermMasterInfo.showDateRange) {
                this.entityRecordMaster.startDate = this.entityRecordMaster.startDate ? this.entityRecordMaster.startDate : '';
                this.entityRecordMaster.endDate = this.entityRecordMaster.endDate ? this.entityRecordMaster.endDate : '';
            }
            if (this.ermMasterInfo.showErmDate) {
                this.entityRecordMaster.ermDate = this.entityRecordMaster.ermDate + "-11";
            }
            if (this.ermMasterInfo.showMultipleMasterControl) {
                this.entityRecordMaster.refErmList = [];
                this.refErmList.forEach((item, index) => {
                    this.entityRecordMaster.refErmList.push({ refErmId: this.refErmList[index].id })
                })
            }
            else {
                this.entityRecordMaster.refErmList = [];
            }
            console.log("ERM",this.entityRecordMaster);
            // return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/add', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.showMessage('Record Master Created', result.id);
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateERMaster: function() {
            if (this.ermMasterInfo.showDateRange) {
                this.entityRecordMaster.startDate = this.entityRecordMaster.startDate ? this.entityRecordMaster.startDate : '';
                this.entityRecordMaster.endDate = this.entityRecordMaster.endDate ? this.entityRecordMaster.endDate : '';
            }
            if (this.ermMasterInfo.showErmDate) {
                this.entityRecordMaster.ermDate = this.entityRecordMaster.ermDate + "-11";
            }
            if (this.ermMasterInfo.showMultipleMasterControl) {
                this.entityRecordMaster.refErmList = [];
                this.refErmList.forEach((item, index) => {
                    this.entityRecordMaster.refErmList.push({ refErmId: this.refErmList[index].id })
                })
            }
            else {
                this.entityRecordMaster.refErmList = [];
            }
            if(this.ermMasterInfo.showPlanLinkControl){
                this.entityRecordMaster.refErmId = this.refErdData.ermId;
                this.entityRecordMaster.refErdId = this.refErdData.id;
            }            
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMaster)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('update-parent');
                        this.closeDialog();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        clearData: function() {
            this.entityRecordMaster.title = '';
            // this.entityRecordMaster.projectId = '';
            this.entityRecordMaster.clientId = '';
            this.entityRecordMaster.ermTypeId = '';
            this.entityRecordMaster.description = '';
            this.entityRecordMaster.createdById = localStorage.getItem("userId");
            // this.entityRecordMaster.statusId = '';
            this.entityRecordMaster.startDate = '';
            this.entityRecordMaster.endDate = '';
            this.entityRecordMaster.isPublic = false;
            this.entityRecordMaster.id = '';
            this.ermMasterTitle = null;
            this.editMode = false;
            this.error = false;
            this.$v.entityRecordMaster.$reset();
        },
        setDefaultValue: function() {
            if (!Object.keys(this.ermMasterInfo).includes("typeLabelName"))
                this.ermMasterInfo.typeLabelName = "Type";
            if (!Object.keys(this.ermMasterInfo).includes("titleLabelName"))
                this.ermMasterInfo.titleLabelName = "Title";
            if (!Object.keys(this.ermMasterInfo).includes("descriptionLabelName"))
                this.ermMasterInfo.descriptionLabelName = "Description";
            if (!Object.keys(this.ermMasterInfo).includes("showTypeLookup"))
                this.ermMasterInfo.showTypeLookup = false;
        },
        async showMessage(message, id) {
            this.showOverlay = false;
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
            this.$emit('update-parent');
            this.closeDialog();
        },
        handleErmTypeChange: function() {
            let typeData = this.entityRecordCustomType.find((data) => {
                if (data.id == this.entityRecordMaster.ermTypeId) {
                    return data;
                }
            });
            if (this.ermMasterInfo.entity == 'payroll_master') {
                if(typeData && typeData.typeCode == 'PRLMON') {
                    this.ermMasterInfo.showDateRange = true;
                    this.ermMasterInfo.showErmDate = true;
                    this.entityRecordMaster.ermDate = this.getMonthYear(new Date());
                    // this.entityRecordMaster.startDate = this.getDateValue(new Date());
                    // this.entityRecordMaster.endDate = this.getDateValue(new Date());

                    this.setPeriod();
                }
            }
            if (this.ermMasterInfo.entity == 'budget_plan_master') {
                if(typeData && typeData.typeCode == 'MOB') {
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showErmDate = true;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = this.getMonthYear(new Date());
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
                else if(typeData && typeData.typeCode == 'PEB') {
                    this.ermMasterInfo.showDateRange = true;
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = this.getDateValue(new Date());
                    this.entityRecordMaster.endDate = this.getDateValue(new Date());
                }
                else if(typeData && typeData.typeCode == 'BTI') {
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showMultipleMasterControl = true;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
                else {
                    this.ermMasterInfo.showErmDate = false;
                    this.ermMasterInfo.showDateRange = false;
                    this.ermMasterInfo.showMultipleMasterControl = false;
                    this.entityRecordMaster.ermDate = '';
                    this.entityRecordMaster.startDate = '';
                    this.entityRecordMaster.endDate = '';
                }
            }
        },
            
        setPeriod: function() {
            const year = new Date(this.entityRecordMaster.ermDate).getFullYear();
            const month = new Date(this.entityRecordMaster.ermDate).getMonth() + 1;

            const firstDayOfMonth = new Date(year, month - 1, 2);
            const lastDayOfMonth = new Date(year, month, 1);

            this.entityRecordMaster.startDate = firstDayOfMonth.toISOString().slice(0, 10);
            this.entityRecordMaster.endDate = lastDayOfMonth.toISOString().slice(0, 10);            
        },

        showEdit: function(data, ermMasterTitle) {
            this.editMode = true;
            this.ermMasterTitle = ermMasterTitle;
            this.entityRecordMaster = data;
            this.handleErmTypeChange();
            this.entityRecordMaster.refErmId = this.entityRecordMaster.refErmId ? this.entityRecordMaster.refErmId : null;
            if(this.ermMasterInfo.showPlanLinkControl){
                this.refErdData.ermId = this.entityRecordMaster.refErmId;
                this.refErdData.id = this.entityRecordMaster.refErdId;
            }
        },
        closeDialog() {
            this.clearData();
            this.$bvModal.hide(this.id);
            this.$emit('closeDialog');
        }
    }
}
</script>