<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-6 col-sm-4">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">Manage Member Roles & Responsibilities</h4>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4" spinner-variant="primary">
                <div class="form-row">
                    <div class="col-lg-12 col-xl-12">
                        <div class="card mb-1">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-lg-5 col-md-6">
                                        <div class="form-row view-filter">
                                            <div class="col-6 col-sm-6">
                                                <b-input-group prepend="VMX Service" id="customUserRoleId" class="">
                                                    <select v-model="vmxServiceData.vmxServiceId" @change="onVmxServiceChange" class="form-control">
                                                        <!-- <option value="">All</option> -->
                                                        <option v-for="(info, index) in vmxServices" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>                                            
                                            <div class="col-6 col-sm-6 input-group-label">
                                                <b-input-group prepend="VMX Service Role" id="customUserRoleId" class="">
                                                    <select v-model="userRoleData" @change="onUserRoleSelected" class="form-control">
                                                        <option v-for="(info, index) in memberRoles" :value="{customUserRoleId:info.id,customUserRole:info.value,vmxServiceId:info.vmxServiceId}">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-7 col-md-6 text-right">
                                        <b-button class="addnew-quicktassk-btn" @click="addCustomMemberRolePopupKey++;" v-b-modal.addCustomMemberRoleModal variant="outline-secondary" size="sm" title="Manage Custom Member Role"><i class="fa fa-cog pr-1"></i>Manage Member Roles</b-button>
                                        <b-button class="addnew-quicktassk-btn ml-2" @click="" v-b-modal.addMemberRoleStandardModal variant="outline-secondary" size="sm" title="Use Standard Member Role"><i class="fa fa-cog pr-1"></i>Use Standard Roles</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-5">
                        <div class="card h-100">
                            <div class="card-body p-3">
                                <span class="position-absolute mt-2 ml-auto" style="right: 1rem;">
                                    <b-button v-if="elements.visible" class="addnew-quicktassk-btn" v-b-toggle.accordion-module variant="outline-danger" size="sm" title="Close"><i class="fa fa-remove pr-1" aria-hidden="true"></i>Close</b-button>
                                    <b-button v-else class="addnew-quicktassk-btn" v-b-toggle.accordion-module variant="outline-secondary" size="sm" title="Add Module"><i class="fa fa-plus-circle pr-1" aria-hidden="true"></i>Add Module</b-button>
                                </span>

                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab :title="(userRoleData.customUserRole ? this.userRoleData.customUserRole : '') + ' - Modules'">
                                        <b-card no-body class="">
                                            <b-collapse id="accordion-module" v-model="elements.visible" class="p-1" role="tabpanel">
                                                <div class="row view-filter">
                                                    <div class="col-3 col-sm-3">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filterRole" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6 col-sm-6 text-center">
                                                        <!-- <b-input-group prepend="VMX Service" id="customUserRoleId" class="">
                                                            <select v-model="vmxServiceId" class="form-control">
                                                                <option value="">All</option>
                                                                <option v-for="(info, index) in vmxServices" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </b-input-group> -->
                                                    </div>
                                                    <div class="col-3 col-sm-3">
                                                        <div class="dataTables_length">
                                                            <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="pl-0 mb-0">
                                                                <b-form-select v-model="perPageRole" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                            </b-form-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <b-table class="border-bottom" show-empty small stacked="md" sort-icon-left :items="filteredModules" :fields="stdUserModuleFields" responsive="sm" :current-page="currentPageUserRole" :per-page="perPageRole" :filterIncludedFields="filterOn" :filter="filterRole" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredUserRef">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(actions)="data">
                                                        <button type="submit" title="Add Module" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1 px-2" @click="cmpServiceSubModuleManage(data.item.subModuleId,1)" :disabled="!userRoleData.customUserRoleId">
                                                            <i class="fa fa-plus-circle pr-1"></i> Add Module
                                                        </button>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Member Roles found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                                <div class="col-4 float-left pl-0 mb-2">
                                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRowsUserRole}} Items</span>
                                                </div>
                                                <div class="col-7 pull-right">
                                                    <b-pagination v-if="filteredModules.length>0" pills v-model="currentPageUserRole" :total-rows="totalRowsUserRole" :per-page="perPageRole" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                                </div>
                                            </b-collapse>
                                        </b-card>
                                        <div :class="{'mt-3' : elements.visible}">
                                            <label class="mb-1">Selected User Modules</label>
                                            <b-table ref="userModuleTable" v-model="mainTableArray" class="border-bottom" show-empty small stacked="md" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="userRoles" :fields="customUserModuleFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <button v-if="data.item.menuCount==0" type="submit" title="Remove Module" class="btn btn-outline-danger sbtn btn-xs py-1 mx-1 px-2" v-b-modal.deleteUserModuleDialog  @click="deleteUserModuleDialog(data.item.subModuleId)">
                                                        <i class="fa fa-remove pr-1"></i>Remove Module
                                                    </button>
                                                    <button type="submit" title="Reset Module Menus" class="btn btn-outline-primary sbtn btn-xs py-1 mx-1 px-2" @click="cmpServiceSubModuleManage(data.item.subModuleId,2)">
                                                        <i class="fa fa-repeat pr-1"></i>Reset Module
                                                    </button>
                                                </template>
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Member Roles found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-xl-7">
                        <b-overlay :show="elements.visible" opacity="0.4" spinner-variant="primary">
                            <b-overlay :show="showDetOverlay" opacity="0.4" spinner-variant="primary">
                                <div class="card mb-4">
                                    <div class="card-body p-1 mx-2">
                                        <b-tabs content-class="mt-3" lazy>
                                            <b-tab :title="selectedModule + ' Module' + ' - Menus'">
                                                <div class="row view-filter">
                                                    <div class="col-sm-12">
                                                        <div class="float-right">
                                                            <div class="dataTables_length">
                                                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                    <b-form-select v-model="perMenuPage" id="perPageSelect" size="sm" :options="pageMenuOptions"></b-form-select>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                        <div class="float-left">
                                                            <div class="dataTables_filter">
                                                                <label>
                                                                    <input type="search" v-model="userFilter" class="form-control form-control-sm" placeholder="Search...">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                                <b-table ref="userTable" outlined show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="companyUserModuleMenuList" :fields="moduleMenuFields" responsive="sm" :current-page="currentMenuPage" :per-page="perMenuPage" :filter="userFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onMenuFiltered">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>      
                                                    <!-- <template v-slot:cell(mainMenu)="data">
                                                        <div v-if="data.item.subModule">
                                                            {{data.item.mainMenu}}
                                                        </div>
                                                        <div v-else>
                                                            {{data.item.mainMenu}}
                                                        </div>
                                                    </template>  -->                                                                                         
                                                    <template v-slot:cell(menuExist)="data">
                                                        <span v-if="data.item.basic" class="text-success" title="Basic Privilege" ><i class="fa fa-check"></i></span>
                                                        <span v-else title="Change Menu Privilege" >
                                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignPrivilege(data.item)">
                                                            </b-form-checkbox>
                                                        </span>
                                                    </template>
                                                    <template v-slot:empty="scope">
                                                        <div class="text-center">No Item found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>
                                                </b-table>
                                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalMenuRows}} Items</span>
                                                <b-pagination v-if="companyUserModuleMenuList.length!=0" pills v-model="currentMenuPage" :total-rows="totalMenuRows" :per-page="perMenuPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                            </b-tab>
                                        </b-tabs>
                                    </div>
                                </div>
                            </b-overlay>
                            <template #overlay>
                                <div class="text-center">
                                  
                                </div>
                            </template>
                        </b-overlay>
                    </div>                    

                </div>
            </b-overlay>
        </div>
        
        <AddCustomMemberRole :key="addCustomMemberRolePopupKey" :vmxServiceId="Number(vmxServiceData.vmxServiceId)" @refresh-lookup="getCustomUserModules();lookupData();addMemberRoleStandardKey++;" />
        <AddMemberRoleStandard :key="addMemberRoleStandardKey" ref="addMemberRoleStandardModal" :userRoleTypeId="userRoleTypeId" @refresh-lookup="getCustomUserModules();lookupData();addCustomMemberRolePopupKey++;" />
        <DeleteComponent id="deleteUserModuleDialog" :onYes="onDeleteConfirmation" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to Remove Module Privilege?" />
<!--         <DialogComponent id="resetUserRoleDialog" :onYes="onResetDialogConfirmation" :returnParams="dialogBoxParam" title="Module Reset Confirmation" message="Are you sure to Reset Member Roles to Default?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AddCustomMemberRole from '@/components/popup/lookup/AddCustomMemberRole.vue';
import AddMemberRoleStandard from '@/components/popup/lookup/AddMemberRoleStandard.vue';
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        Multiselect,
        AddCustomMemberRole,
        AddMemberRoleStandard,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            loadOnce: true,
            elements: {
                visible: false,
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeId: localStorage.getItem("userTypeId"),
            entity: 'custom_user_role',

            addCustomMemberRolePopupKey: 0,
            addMemberRoleStandardKey: 100,

            customUserRoleId: '',
            vmxServiceId: '',
            subModuleId: '',

            userRoleTypeId: 2,

            selectedModule: '',            
            defaultCheckbox: true,

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            vmxServiceData:{
                vmxServiceId: '',
            },

            userRoleData:{
                customUserRoleId: '',
                customUserRole: '',
                vmxServiceId: '',
            },

            mainTableArray: [],

            customUserRoles: [],
            vmxServices: [],
            stdUserModules: [],
            customMemberSubModules: [],
            companyUserModuleMenuList: [],
            stdUserModuleFields: [
                {key: 'index',label: 'SN'},
                // { key: 'vmxService', label: 'Service', sortable: true, sortDirection: 'desc' },
                { key: 'subModule', label: 'Standard Service Module', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],

            customUserModuleFields: [
                {key: 'index',label: 'SN'},
                // { key: 'vmxService', label: 'Service', sortable: true, sortDirection: 'desc' },
                { key: 'subModule', label: 'Service Module', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],
            moduleMenuFields: [
                { key: 'index',label: 'SN', thStyle: "width: 10px" },
                { key: 'standardMenuType', label: 'Menu Type', sortable: true, sortDirection: 'desc' },
                { key: 'subModule', label: 'Sub Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'displayName', label: 'Sub Menu', sortable: true, sortDirection: 'desc' , tdClass: 'text-left' },
                // { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' , tdClass: 'text-left' },
                { key: 'menuExist', label: 'Active', sortable: true, sortDirection: 'desc' },
            ],            

            deleteParam: {
                id: 0
            },
            dialogBoxParam: {
                id: 0,
                rowData:[],
            },

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            totalRowsRole: 1,
            currentPageRole: 1,
            totalRowsUserRole: 1,
            currentPageUserRole: 1,

            totalMenuRows: 1,
            currentMenuPage: 1,
            perMenuPage: 10,

            perPageRole: 5,
            filterRole: null,
            pageOptions: [5,10, 15, 20, 25],
            pageMenuOptions: [10, 15, 20, 25],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            userFilter: null,
            filterOn: [],
        }
    },
    computed: {
        filteredModules() {
            let filteredModules = [];

            let filterArray1 = [];
            filterArray1 = this.stdUserModules.filter((data) => !this.isUserRoleExists(data.subModuleId));

            // let filterArray2 = [];
            // if (this.vmxServiceId) {
            //     filterArray2 = filterArray1.filter((data) => data.vmxServiceId == this.vmxServiceId);
            // }
            // else filterArray2 = filterArray1;

            // filteredModules = filterArray2;

            let filterArray2 = [];
            if (this.vmxServiceData.vmxServiceId) {
                filterArray2 = filterArray1.filter((data) => data.vmxServiceId == this.vmxServiceData.vmxServiceId);
            }

            // let filterArray2 = [];
            // if (this.userRoleData.userTypeId) {
            //     filterArray2 = filterArray1.filter((data) => data.userTypeId == this.userRoleData.userTypeId);
            // }
            else filterArray2 = [];

            filteredModules = filterArray2;

            this.totalRowsUserRole = filteredModules.length;
            return filteredModules;
        },
        userRoles() {
            let userRoles = [];
            if (this.userRoleData.customUserRoleId && this.customMemberSubModules.length>0) {
                userRoles = this.customMemberSubModules.filter((data) => data.customUserRoleId == this.userRoleData.customUserRoleId);
            }
            this.$nextTick(() => {
                this.selectBTableRowById(this.subModuleId, this.mainTableArray, 'userModuleTable', 'subModuleId');
            })

            return userRoles;
        },
        memberRoles() {
            if(this.vmxServiceData.vmxServiceId) {
                return this.customUserRoles.filter(data => data.vmxServiceId == this.vmxServiceData.vmxServiceId);
            }
            else return this.customUserRoles;
        },
    },
    mounted() {
        this.lookupData();
        this.getStdUserModules();
        this.getCustomUserModules();
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userRoleTypeId: 2,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/custommemberroleresp/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.customUserRoles = result.customUserRoleLookup;
                    this.vmxServices = result.vmxServiceLookup;

                    this.vmxServiceData.vmxServiceId = this.vmxServices.length > 0 ? this.vmxServices[0].id : '';

                    this.userRoleData.customUserRoleId = this.memberRoles.length > 0 ? this.memberRoles[0].id : '';
                    this.userRoleData.customUserRole = this.memberRoles.length > 0 ? this.memberRoles[0].value : '';
                    this.userRoleData.vmxServiceId = this.memberRoles.length > 0 ? this.memberRoles[0].vmxServiceId : '';

                    // this.$nextTick(() => {
                    //     this.onUserRoleSelected();
                    // })

                })
        },
        getStdUserModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/stdmembermodule/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.stdUserModules = result.stdMemberModuleList;
                    this.totalRowsRole = this.filteredModules.length;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getCustomUserModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/cmpmembersubmodule/list',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.customMemberSubModules = result.cmpMemberSubModules;
                    // this.onUserRoleSelected();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getModuleMenuList: function(subModuleId) {
            if (this.userRoleData.customUserRoleId) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    vmxServiceId: this.vmxServiceData.vmxServiceId,
                    customUserRoleId: this.userRoleData.customUserRoleId,
                    subModuleId: subModuleId,
                    userRoleTypeId: 2,
                }
                
                console.log(data);

                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/cmpmembersubmodulemenu/list', data)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        console.log(this.showOverlay, result)
                        this.companyUserModuleMenuList = result.companyMemberSubModuleMenuList;
                        this.totalMenuRows = this.companyUserModuleMenuList.length;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    })
            }
        },
        assignPrivilege(data) {

            if (data.menuExist == "true") {
                this.actionState = 0;
            }
            else if (data.menuExist == "false") {
                this.actionState = 1;
            }

            this.$nextTick(() => {
                this.managePrivilege(data.id);
            })

        },
        managePrivilege: function(standardMenuId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                customUserRoleId: this.userRoleData.customUserRoleId,
                standardMenuId: standardMenuId,
                actionState: this.actionState,
                createdById: this.userId,
            }

            console.log(data);

            this.showDetOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/cmpusermenuprivilege/change', data)
            .then((response) => {
                this.showDetOverlay = false;
                let result = response.data;
                if (result.status == "OK") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    // this.clearData();
                    this.getCustomUserModules();
                    // this.getModuleMenuList(this.moduleId);
                }
            })
            .catch(error => {
                this.showDetOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        deleteUserModuleDialog: function(id) {
            this.deleteParam.id = id;
        },
        onDeleteConfirmation: function() {
            this.cmpServiceSubModuleManage(this.deleteParam.id,0);
        },
        cmpServiceSubModuleManage(subModuleId,actionState) {

            let data = {
                vhextAccountId: this.vhextAccountId,
                customUserRoleId: this.userRoleData.customUserRoleId,
                subModuleId: subModuleId,
                actionState: actionState,
                createdById: this.userId,
            }

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/cmpservicesubmodule/manage', data)
            .then((response) => {
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "OK") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
            // this.$nextTick(() => {
            //     this.selectBTableRowById(this.moduleId, this.mainTableArray, 'userModuleTable', 'moduleId');
            // })
                    this.subModuleId = subModuleId;

                    // this.clearData();
                    this.getStdUserModules();
                    this.getCustomUserModules();
                }
            })
            .catch(error => {
                this.showOverlay = false;
                iziToast.error({
                   title: 'Error',
                   message: 'Error',
                   position: 'topRight'
               });
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRole = filteredItems.length
            this.currentPageRole = 1
        },
        onFilteredUserRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsUserRole = filteredItems.length
            this.currentPageUserRole = 1
        },
        onMenuFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalMenuRows = filteredItems.length
            this.currentMenuPage = 1
        },

        onVmxServiceChange: function() {
            this.userRoleData.customUserRoleId = this.memberRoles.length > 0 ? this.memberRoles[0].id : '';
            this.userRoleData.customUserRole = this.memberRoles.length > 0 ? this.memberRoles[0].value : '';
            this.userRoleData.vmxServiceId = this.memberRoles.length > 0 ? this.memberRoles[0].vmxServiceId : '';
            this.onUserRoleSelected();
        },
        onUserRoleSelected: function() {
            // this.elements.visible = false;
            if (this.userRoles.length>0) {
                this.$nextTick(() => {
                    this.selectBTableRowById(null, this.mainTableArray, 'userModuleTable', 'subModuleId');
                })
            }
            else {
                this.selectedModule = '';
                this.subModuleId = '';
                this.companyUserModuleMenuList = [];
                this.totalMenuRows = this.companyUserModuleMenuList.length;
            }
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.selectedModule = data.module;
                this.subModuleId = data.subModuleId;
                this.getModuleMenuList(data.subModuleId);
            }
        },
        isUserRoleExists(subModuleId) {
            let result = false;
            if (this.userRoles.length>0) {
                let data = this.userRoles.find(data => data.subModuleId == subModuleId);
                result = data ? true : false;
            }
            return result;
        },
    }
}
</script>