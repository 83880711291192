<template>
    <div class="referenceDetailPopup">
        <b-modal :id="id" @hide="closeDialog" ref="serviceHistoryDetailPopupRef" size="lg" modal-class="" title="Service Transaction" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 text-left">
                            <div class="card-body p-0 mx-0 mb-1">
                                <div class="row">
                                    <div class="col-9 col-sm-9">
                                        <div class="form-row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.serviceHistoryId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 col-sm-3">
                                        <div class="float-right">
                                            <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Transaction Id</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.transactionId}}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Amount</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{currencySymbol}} {{popupData.cost}}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Start Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.startDate | formatDate }}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">End Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.endDate | formatDate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mb-2">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Payment Due</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.paymentDueDate | formatDate }}</label>
                                            </div>
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Transaction Date</label>
                                            </div>
                                            <div class="col-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.paymentDate | formatDate }}</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mt-0 mb-2">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created By</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.createdBy }}</label>
                                            </div>
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                            </div>
                                            <div class="col-12 col-md-4 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.createdDt | formatDate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-12">
                                        <div class="form-row mt-0 mb-2">
                                            <div v-show="attachmentDocsLength > 0" class="col-12 col-md-6">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-4 pr-0">
                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                                                    </div>
                                                    <div class="col-12 col-md-8 pl-0">
                                                        <label class="p-2 mb-0 d-block header-details">
                                                            <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentDocsLength" showDocsOnly :attachmentData="attachmentData" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-show="attachmentLinksLength > 0" class="col-12 col-md-6">
                                                <div class="form-row">
                                                    <div class="col-12 col-md-4 pr-0">
                                                        <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reference Links</label>
                                                    </div>
                                                    <div class="col-12 col-md-8 pl-0">
                                                        <label class="p-2 mb-0 d-block header-details">
                                                            <AttachmentView ref="viewAttachmentRef" @getAttachmentLength="getAttachmentLinksLength" showLinkOnly :attachmentData="attachmentData" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object,
        id:{type:String, default:'serviceHistoryDetailPopup'},
    },
    components: {
        AttachmentView
    },
    data() {
        return {
            loading: true,
            popupData: {},
            currencySymbol: localStorage.getItem("currency"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),

            attachmentDocsLength: 0,
            attachmentLinksLength: 0,
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.$nextTick(() => {
                this.$bvModal.show(this.id);
            });
            this.loading = true;
            if (refEntity === "service_history") {
                axios.get(this.$store.getters.getAPIBasePath + '/company/servicehistory/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.popupData = result.serviceUtilView;
                        this.loading = false;
                    }
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error);
                });
            }
            else {
                this.$nextTick(() => {
                    this.popupData = this.detailViewData;
                });
                this.loading = false;
            }
        },
        getAttachmentDocsLength(length) {
            this.attachmentDocsLength = length;
        },
        getAttachmentLinksLength(length) {
            this.attachmentLinksLength = length;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
         closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>