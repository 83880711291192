<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-18">Country</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-pencil pr-2"></i>Edit</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-eye pr-2"></i>View</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="#"><i class="fa fa-trash pr-2"></i>Detete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.country" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.countryId" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.alpha2Code" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="title">Country</label>
                                        <input type="text" class="form-control" v-model="country.alpha3Code" id="title" placeholder="Enter Title">
                                    </div>
                                </div>
                            </div>
                            <button type="submit" @click="saveCountry" class="btn btn-primary mb-0 mt-3">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Select2 from 'v-select2-component';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        Select2
    },
    data() {
        return {
            country: {
                countryId: '',
                alpha2Code: '',
                alpha3Code: '',
                callingCode: '',
                NumericCode: '',
                currency: '',
                internetCode: '',
                timeZones: '',
                capital: '',
                dateFormat: '',
                languages: '',
                hasAttachment: '',
                active: '',
            }
        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        country: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        saveProject: function() {
            this.$v.country.$touch();
            if (!this.$v.country.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/country/add', this.country)
                    .then((response) => {
                        let result = response.data;
                        iziToast.success({
                            title: 'Success',
                            message: 'Country info loaded successfully',
                            position: 'topRight'
                        });
                    })
            }

        }
    }
}
</script>