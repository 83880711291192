<template>
    <div class="budgetPlanDetailPopup">
        <b-modal id="budgetPlanDetailPopup" ref="budgetPlanDetailRef" @hide="closeDialog" size="lg" modal-class="" title="Budget Plan Details" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0">
                                <div class="col-lg-12 col-xl-12 px-0">
                                    <div class="row">
                                        <div class="col-6 col-sm-6 mb-3 pr-1">
                                            <div class="row mb-0">
                                                <div class="col-2 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.budgetId }}</label>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pl-1">
                                            <div class="row mb-0">
                                                <div class="col-4 pr-0">
                                                    <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Estimated Cost</label>
                                                </div>
                                                <div class="col-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details text-right">{{ popupData.estimatedCost.toFixed(2) }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6 mb-2 pr-1">
                                            <div class="row mb-0">
                                                <div class="col-4 pr-0">
                                                    <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                                </div>
                                                <div class="col-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.activity }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pl-1">
                                            <div class="row mb-0">
                                                <div class="col-4 pr-0">
                                                    <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Category</label>
                                                </div>
                                                <div class="col-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.category }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pr-1">
                                            <div class="row mb-0">
                                                <div class="col-4 pr-0">
                                                    <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Accountable</label>
                                                </div>
                                                <div class="col-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.accountablePerson }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pl-1">
                                            <div class="row mb-0">
                                                <div class="col-4 pr-0">
                                                    <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Created By</label>
                                                </div>
                                                <div class="col-8 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{ popupData.userName }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: true,
            popupData: {},
            timeSheets: [],

            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            try {                
                this.$bvModal.show('budgetPlanDetailPopup');
                console.log(this.detailViewData);
                if (refEntity === "budget_plan") {
                    axios.get(this.$store.getters.getAPIBasePath + '/finance/budget/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.budgetPlan;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.loading = false;
                } 
            }
            catch(err) {
                console.log(err);
                this.loading = false;
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>
<style scoped>
span >>> p {
    margin: 0;
}
</style>