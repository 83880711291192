<template>
    <div>
        <b-modal ref="audioRecordModal" size="md" :id="recordModalId" title="Audio Recorder" ok-only centered @show="clearData" :no-close-on-backdrop="true">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-2">
                        <b-col cols="12">
                            <!-- <div  class="text-center mb-2">Click the micro phone for recording</div>
                            <div   class="text-center">
                                <vue-record-audio v-if="recordAudio" :mode="recMode" @stream="onStream" @result="onResult" /> 
                               <a class="pointer" href="#" @click="stop"> <i class="pl-2 fa fa-lg fa-stop text-danger" aria-hidden="true"></i> </a>                  
                            </div> 
                                       show-download-button="false"
                                    show-upload-button="false"
                                    -->
                            <div class="audio-recorder-popup">
                                <audio-recorder
                                    upload-url="YOUR_API_URL"
                                    :attempts="1"
                                    :time="2"
                                    :before-recording="beforeRecord"
                                    :pause-recording="pauseRecord"
                                    :after-recording="afterRecord"
                                    :select-record="selectRecord"
                                    :before-upload="beforeUpload"
                                    :successful-upload="successUpload"
                                    :failed-upload="failedUpload"
                                />
                            </div>

                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
        </b-modal>
    </div>
</template>
<script>

export default {
    props: {
        recMode: {
            type: String,
            default: 'press'
        },
        recordAudio: {
            type: Boolean,
            default: true
        },
        refEntity: String,
        refRecordId: String,
        value: Number,
        recordModalId: String
    },

    computed: {},
    data() {
        return {
            showOverlay: false,
            audioData: '',
            audioUrl: '',
            referenceRecordId: this.refRecordId,
            projectId: localStorage.getItem("projectId"),   
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            audioSrc: "https://file-examples-com.github.io/uploads/2017/11/file_example_MP3_5MG.mp3"
        }
    },
    validations: {},
    mounted() {},
    methods: {
        clearData: function() {

        },        
        onResult (data) {   
            console.log(data)
            this.audioData = data;
            //this.audioData.push(data);    
            //this.saveAudioFile(data);
        },
        onStream(data){
            //console.log('The stream data:', data);
        },
        saveAudioFile(blob){
            if(blob){
                let formData = new FormData();
                let randomh = Math.random();
                let fileName = "recording_" + this.referenceRecordId + "_" + randomh + ".mp3";
                let file = new File([blob], fileName, {type: "text/json;charset=utf-8"});

                formData.append('file', file);
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('attachedById', this.userId);
                formData.append('projectId', this.projectId);
                formData.append('refEntity', this.refEntity);
                formData.append('refRecordId', this.referenceRecordId);
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/audiofile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        let result = response.data;
                        if (result.status == "OK") {
                            console.log(result.message);
                        }
                    })
                    .catch(error => {
                        console.log('Error audio file uploaded');
                    });
            }
        },
        onDataChange: function(refRecordId) {
            this.referenceRecordId = refRecordId;
            this.saveAudioFile(this.audioData);
        },        
        onUpdateChange: function(refRecordId) {
           
        },
        stop:function(){
            this.recMode='stop';
        },
        beforeRecord (data) {
            console.info('beforeRecord: ', data)
        },
        pauseRecord (data) {
            console.info('pauseRecord: ', data)
        },
        afterRecord (data) {
            this.audioData = data.blob;
            this.audioUrl = data.url;
            this.$emit('assign-data', data.url)
            console.info('afterRecord: ', data.url)
        },
        selectRecord (data) {
            console.info('selectRecord: ', data)
        },
        beforeUpload (data) {
            console.info('beforeUpload: ', data)
        },
        successUpload (data) {
            console.info('successUpload: ', data)
        },
        failedUpload (data) {
            console.info('failedUpload: ', data)
        },
    }
}
</script>