<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Monthly Employees Payroll Report</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <!-- top to bottom -->
                                        <li class="breadcrumb-item">
                                            <b-button  class="addnew-tassk-btn sbtn float-right ml-2 top-header-btn" variant="outline-primary" v-b-toggle.accordion-ett size="xs" :class="{'active' : elements.filterVisible}" :title="(elements.filterVisible ? 'Hide' : 'Show') + ' Filter'"><i class="fa fa-filter pr-2" aria-hidden="true"></i>{{ elements.filterVisible ? 'Hide' : 'Filter'}}</b-button>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <!-- <li class="breadcrumb-item">
                                            <div class="ingov-app-header">
                                                <div class="app-options">
                                                    <div class="app-menu1">
                                                        <a href="#" title="Comment">
                                                            <i class="fa fa-commenting  action-link" aria-hidden="true"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li> -->
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
<!--                                         <li class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li> -->
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Payroll Month</label>
                                                    <input type="month" class="form-control" v-model="fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <button type="submit" @click="getPayrollList" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <!-- <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-4 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Department</label>
                                                                </div>
                                                                <div class="col-12 col-md-8 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ department }}</label>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Payroll Month</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ getMonthYearAlt(startDate) }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card-body p-3">
                                <p class="contact-title"><span>Monthly Employees Payroll</span></p>
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="data-table-rows  data-tables-hide-filter">
                                            <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="payrollSummary" :fields="payrollFields" foot-clone foot-variant=light no-footer-sorting responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <!-- <template v-slot:cell(employeeName)="data">
                                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">
                                                        {{ data.value }}
                                                    </a>
                                                </template> -->
                                                <template v-slot:cell(monthlyCTC)="data">
                                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                </template>                                                
                                                <template v-slot:cell(totalEarning)="data">
                                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                </template>

                                                <template v-slot:cell(totalDeduction)="data">
                                                    <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                </template>
                                                <template #cell(netPay)="data">
                                                    <div class="text-right">{{ (data.item.totalEarning - data.item.totalDeduction).toFixed(2)}}
                                                    </div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>

                                                <template v-slot:foot()="data">
                                                    <span></span>
                                                </template>

                                                <template v-slot:foot(monthlyCTC)="data">
                                                    <div class="text-right font-weight-bold">Total Monthly CTC : {{totalMonthlyCTC.toFixed(2)}}</div>
                                                </template>

                                                <template v-slot:foot(totalEarning)="data">
                                                    <div class="text-right font-weight-bold">Total Earnings : {{totalEarnings.toFixed(2)}}</div>
                                                </template>
                                                <template v-slot:foot(totalDeduction)="data">
                                                    <div class="text-right font-weight-bold">Total Deductions : {{totalDeductions.toFixed(2)}}</div>
                                                </template>

                                                <template v-slot:foot(netPay)="data">
                                                    <div class="text-right font-weight-bold">Total Net Pay: {{(totalEarnings-totalDeductions).toFixed(2)}}</div>
                                                </template>
                                                <template v-slot:empty="erm">
                                                    <div class="text-center">No Record Found</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),

            fromDate: this.getMonthYear(new Date()),
            startDate: this.getMonthYear(new Date()),

            isBusy: false,
            departments: [],
            workPatterns: [],

            payrollSummary: [],
            payrollFields: [
                { key: 'payrollId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'eid', label: 'EID', sortable: true, sortDirection: 'desc' },
                { key: 'employeeName', label: 'Emp. Name', sortable: true, sortDirection: 'desc' },
                { key: 'payrollPeriodStr', label: 'Payroll Period', sortable: true,sortDirection: 'desc' },
                { key: 'monthlyCTC', label: 'Monthly CTC', sortable: true, sortDirection: 'desc' },
                { key: 'totalEarning', label: 'Earnings', sortable: true, sortDirection: 'desc' },
                { key: 'totalDeduction', label: 'Deductions', sortable: true, sortDirection: 'desc' },
                { key: 'netPay', label: 'Net Pay', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        totalMonthlyCTC() {
            return this.payrollSummary.reduce((accum, item) => {
                return accum + parseFloat(item.monthlyCTC ? item.monthlyCTC : 0)
            }, 0.00)
        },        
        totalEarnings() {
            return this.payrollSummary.reduce((accum, item) => {
                return accum + parseFloat(item.totalEarning ? item.totalEarning : 0)
            }, 0.00)
        },        
        totalDeductions() {
            return this.payrollSummary.reduce((accum, item) => {
                return accum + parseFloat(item.totalDeduction ? item.totalDeduction : 0)
            }, 0.00)
        },
    },

    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
        this.getPayrollList();
    },
    methods: {
        getPayrollList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                startDate: this.fromDate + "-11",
            }
            this.isBusy = true;
            this.startDate = this.fromDate + "-11";
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payrollbymonth/summary', data)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    this.payrollSummary = result.payrollSummaryByMonth;

                    this.startDate = this.getMonthYear(this.startDate);
                    this.fromDate = this.getMonthYear(this.fromDate);
                })
                .catch(error => {
                    this.isBusy = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/employeeattendance/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.departments = result.departmentLookup;
                    this.workPatterns = result.workShiftLookup;
                })
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.fromDate = this.getMonthYear(this.startDate);
        },
        clearData: function() {    
            this.startDate = this.getMonthYear(new Date());
            this.fromDate = this.getMonthYear(new Date());
            this.getPayrollList();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>