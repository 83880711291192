<template>
    <div>
        <ResourcePlanMaster :ermMasterInfo="ermMasterInfo"></ResourcePlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ResourcePlanMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ResourcePlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Resource Plan Master",
                entity: 'resource_plan_master',
                refEntity: 'resource_plan',
                ermListRedirectURL: '/rplmas/list',
                erdRedirectURL: '/resourceplan/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>