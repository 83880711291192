<template>
    <div class="card pb-4">
        <div class="container-fluid mb-5">
            <div class="row mt-1">
                <div class="col-lg-3 col-sm-3 mt-2 pr-0 mr-0">
                    <h4 class="pl-2 mb-0 fs-16">Leave & Time</h4>
                </div>
                <div class="col-lg-4 col-sm-4 pl-0 mb-1 text-left">
                    <div class="row mt-0 mb-0 text-center px-0">
                        <div class="col pull-left px-0">
                            <a href="javascript:" @click="changeDateRange('prevPage',daysToShow);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                        </div>
                        <div class="col pull-left px-0">
                            <a href="javascript:" @click="changeDateRange('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                        </div>
                        <div class="col-6 px-0 mt-1">
                            <h6 class="mt-0" v-if="currentStartDate">
                                {{ months[currentStartDate.getMonth()].slice(0,3) }}
                                <span v-if="currentStartDate.getFullYear() !== currentEndDate.getFullYear()">
                                    - {{ currentStartDate.getFullYear() }}
                                </span>
                                <span v-if="months[currentStartDate.getMonth()] !== months[currentEndDate.getMonth()]">
                                    / {{ months[currentEndDate.getMonth()].slice(0,3) }}
                                </span>
                                - {{ currentEndDate.getFullYear() }}
                            </h6>
                        </div>
                        <div class="col pull-right px-0">
                            <a href="javascript:" @click="changeDateRange('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                        </div>
                        <div class="col pull-right px-0">
                            <a href="javascript:" @click="changeDateRange('nextPage',daysToShow);"><i class="fa fa-2x fa-angle-double-right action-link mt-0"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-4 pr-0 mt-1">
                    <div class="float-left action-item-page">
                        <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer mr-1" title="Jump to Date">
                            <i class="fa fa-calendar text-light"></i>
                            <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownCal">
                                <b-calendar v-model="selectedDate" today-variant="primary" selected-variant="secondary" @context="dateList(selectedDate)">
                                </b-calendar>
                            </div>
                        </span>
                        <span @click="daysToShow = 1" :class="daysToShow == 1 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="Today">1</span>
                        <span @click="daysToShow = 3" :class="daysToShow == 3 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="3 Days">3</span> 
                        <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">5</span>
                        <span @click="daysToShow = 7" :class="daysToShow == 7 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer" title="7 Days">7</span>
                    </div>
                    <div class="float-right">
                        <!-- <b-button v-if="showAllRequests" class="addnew-quicktassk-btn" @click="getLeaveTimeList" variant="outline-secondary" size="sm"><i class="fa fa-user-o pr-1"></i>My Requests</b-button> -->
                        <!-- <b-button v-else class="addnew-quicktassk-btn" @click="getAllLeaveTimeList" variant="outline-secondary" size="sm"><i class="fa fa-users pr-1"></i>All Requests</b-button> -->
                        <!-- <b-button class="addnew-quicktassk-btn ml-2" v-b-modal.setWorkingDayPopup variant="outline-secondary" size="sm"><i class="fa fa-calendar-o pr-1"></i>Daily Work Schedule Setting</b-button> -->
                        <!-- <span @click="daysToShow = 5" :class="daysToShow == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">Add</span> -->
                    </div>
                </div>
                <div class="col-lg-1 col-sm-1 mt-2">
                    <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right pr-1 mr-0" aria-label="breadcrumb">
                        <ol class="breadcrumb pt-0 mb-0">
                            <li class="breadcrumb-item">
                                <a href="javascript:" class="action-link" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                            </li>
                            <li v-if="projId" class="breadcrumb-item">
                                <h4 class="mb-0 fs-14">#{{this.projId}} - {{this.projectName}}</h4>
                            </li>
                        </ol>
                    </nav>
                </div>

            </div>
            <div class="separator mb-2"></div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row px-1">
                    <div v-for="day,index in daysToShow" class="col p-0 ml-2 mr-1">
                        <div v-if="currentDateList.length > 0" :title="(currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)) ? 'Today' : null" class="card b-radius-5 pointer" :class="{ 'disabled-weekEnd' : currentDateList[index].getDay() == 0 || currentDateList[index].getDay() == 6, 'active-day' : currentDateList[index].toString().substr(0, 10) == new Date().toString().substr(0, 10)}" @click="dateList(currentDateList[index])">
                            <div class="card-body py-2">
                                <div class="form-group mb-0">
                                    <label v-if="currentDateList.length > 0" class="mt-1 mb-0 font-weight-semibold" for="title">{{ days[currentDateList[index].getDay()] }}</label>
                                    <h6 v-if="currentDateList[index]" class="card-text font-weight-semibold mb-0 pull-right">
                                        {{ currentDateList[index].getDate() }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        <div class="action-item-vl mt-2">
                            <div v-if="!itemsByDate(currentDateList[index]).length" class="py-3 my-0 text-center">
                                <!-- <div class="action-card-body py-2 text-center"> -->
                                    No absentees
                                <!-- </div> -->
                            </div>
                            <div v-for="employeeData in itemsByDate(currentDateList[index])" class="row">
                                <div class="col-12 text-left mb-2">
                                    <div class="action-card my-0" >
                                        <div class="action-card-body dropdown show p-0">
                                            <a v-if="employeeData.leaveTypeId" type="button" @click="viewLeaveApplication(employeeData.leaveTimeId)" class="float-right pt-2 mr-2 ml-1" title="Show Leave / Permission Application">
                                                <i class="fa fa-ellipsis-v p-1"></i>
                                            </a>
                                            <div class="pt-2 pb-1 px-2 position-relative">
                                                <div class="card-media-body-top">
                                                    <div class="row no-gutters">
                                                        <div class="col-auto">
                                                            <b-avatar v-if="employeeData.photo" :src="employeeData.photo" :title="employeeData.name"></b-avatar>
                                                            <b-avatar v-else :title="employeeData.name"></b-avatar>
                                                        </div>
                                                        <div class="col ml-2">
                                                            <div :title="employeeData.name">{{ getDynamicWidthText(employeeData.name) }}</div>
                                                            <div class="subtle" :title="employeeData.designation">{{ getDynamicWidthText(employeeData.designation) }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span v-if="employeeData.status" class="badge position-absolute" style="bottom: 0px;left: 0px;font-size: 7px;" :class="leaveStatusBadge(employeeData.statusLookupId)" >
                                                    {{ getCustomStatusText(employeeData.statusLookupId) }}
                                                </span>
                                                <!-- <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-9 p-0 text-left">
                                                                <div v-if="employeeData.requestedBy" class="">
                                                                    <b-badge class="task-badge float-left" :title="`Applied By: ${employeeData.requestedBy}`">{{ employeeData.requestedBy }}</b-badge>
                                                                    <template v-if="showAllRequests && employeeData.requestedTo">
                                                                        <i class="fa fa-arrow-right fs-11 px-1" aria-hidden="true"></i>
                                                                        <b-badge v-if="daysToShow > 5" class="task-badge" :title="`Approval By: ${employeeData.requestedTo}`"> {{ employeeData.requestedTo | getAcronym }} </b-badge>
                                                                        <b-badge v-if="daysToShow <= 5" class="task-badge" :title="`Approval By`"> {{ employeeData.requestedTo }} </b-badge>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                            <div v-if="employeeData.leaveTypeCode == 'LVE' && employeeData.totalDays" class="col-3 px-0 text-right">
                                                                <div>
                                                                    <span class="fs-12" :title="`Total Days: ${employeeData.totalDays}`">{{ employeeData.totalDays }} Days </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <StatusTrackerPopup v-if="showDialog" ref="statusTrackerPopupRef" :statusUpdate="statusUpdate" :hasReferredTo="true" :referredToLookup="users" @closeDialog="closeDialog" @updateParent="updateParent" />
        <LeaveTimeDetailPopup v-if="showDialog" ref="leaveTimeDetailPopupRef" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <!-- <SetWorkingDayPopup /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import LeaveTimeDetailPopup from '@/components/popup/itemdetail/LeaveTimeDetailPopup.vue'
// import SetWorkingDayPopup from '@/components/popup/action/SetWorkingDayPopup.vue';
import StatusTrackerPopup from '@/components/popup/action/StatusTrackerPopup.vue';
import moment from 'moment';
export default {
    components: {
        LeaveTimeDetailPopup,
        // SetWorkingDayPopup,
        StatusTrackerPopup,
    },
    data() {
        return {
            refPopupTitle: '',
            referencePopupId: 0,
            detailViewData: {},
            showDialog: false,
            showOverlay: false,
            showAllRequests: false,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            daysToShow: 7,
            selectedDate: null,
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            currentDateList: [],

            attachmentData: {},
            referencePopupId: 0,

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            leaveTime: {
                id: '',
                leaveTimeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                leaveTypeCode: '',
                leaveTypeId: '',
                subject: '',
                description: '',
                workPlan: '',
                fromDt: '',
                fromDate: '',
                fromTime: null,
                tillDt: '',
                tillDate: '',
                tillTime: null,
                startHalfDay: 1,
                endHalfDay: 1,
                totalDays: '',
                totalHours: '',
                statusId: 1,
                requestedById: localStorage.getItem("userId"),
                requestedToId: '',
                createdById: localStorage.getItem("userId"),
                calendarTypeId: 2,
                categoryName: 'calendar_category',
                categoryCode: '',
                isAllDayEvent: false,
            },

            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                refEntityId: '',
                functionalEntity: 'leave_time',
                refEntity: 'leave_time',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },

            leaveTimeList: [],
            users: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
    },
    mounted() {
        this.dateList();
        this.getLeaveTimeList();
        this.lookupData();
    },
    methods: {
        getLeaveTimeList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                // userId: this.userId
            }
            console.log(data);
            this.showAllRequests = false;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/leavetimeboard/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.leaveTimeList = result.leaveTimeList;
                    console.log(result);
                })
                .catch((error) => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                });

        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/companyuser', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                })
        },
        dateList(selectedDate) {
            if (selectedDate) {
                this.currentStartDate = moment(selectedDate).toDate();
                this.daysToShow = 1;
            }
            else {

                /* to set Sunday as first day */
                // this.currentStartDate = moment().startOf('week').toDate();

                /* to set Monday as first day */
                this.currentStartDate = moment().startOf('isoweek').toDate();

                /* to set current day as first day */
                // this.currentStartDate = new Date();
            }
            var endDate = new Date();
            var endMonth = new Date();
            endDate.setDate(this.currentStartDate.getDate() + 6);

            this.currentEndDate = endDate;
            this.currentDateList = this.getDaysArray(this.currentStartDate, endDate);
        },
        changeDateRange(page, number) {
            var startDate = new Date(this.currentStartDate);
            var endDate = new Date(this.currentEndDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
                endDate.setDate(this.currentEndDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
                endDate.setDate(this.currentEndDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.currentEndDate = endDate;

            this.currentDateList = this.getDaysArray(this.currentStartDate, this.currentEndDate);
        },
        getDaysArray: function(s, e) {
            for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
                a.push(new Date(d));
            }
            return a;
        },
        itemsByDate(date) {
            return this.leaveTimeList.filter(item=> this.getDateValue(item.workingDate) == this.getDateValue(date));
        },
        viewLeaveApplication(data) {
            this.referencePopupId = data.id;
            this.showDialog = true;
            this.$nextTick(() => {
                this.$refs.leaveTimeDetailPopupRef.getDetails(this.referencePopupId, 'leave_time');
            })
        },
        openFinTxnPopup: function(data, flag) {
            if (flag == true) {
                this.leaveTime.id = data.id;
                this.leaveTime.financialTransactionId = data.financialTransactionId;
                this.leaveTime.vhextAccountId = data.vhextAccountId;
                this.leaveTime.projectId = data.projectId;
                this.leaveTime.entityRecordMasterId = data.entityRecordMasterId;
                this.leaveTime.entity = data.entity;
                this.leaveTime.accountHeadId = data.accountHeadId;
                this.leaveTime.transactionTypeId = data.transactionTypeId;
                this.leaveTime.categoryId = data.categoryId;
                this.leaveTime.title = data.title;
                this.leaveTime.description = data.description;
                this.leaveTime.amount = data.amount;
                this.leaveTime.preparedById = data.preparedById;
                this.leaveTime.transactionDt = data.transactionDt;
                this.leaveTime.transactionById = data.transactionById;
                this.leaveTime.accountablePersonId = data.accountablePersonId;
                this.leaveTime.statusId = data.statusId;
                this.leaveTime.activityId = data.activityId;
                this.leaveTime.entityId = data.entityId;
            } else {
                this.leaveTime.id = '';
                this.leaveTime.financialTransactionId = '';
                this.leaveTime.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.leaveTime.projectId = localStorage.getItem("projectId");
                this.leaveTime.entityRecordMasterId = '';
                this.leaveTime.entity = 'financial_transaction';
                this.leaveTime.accountHeadId = '';
                this.leaveTime.transactionTypeId = '';
                this.leaveTime.categoryId = '';
                this.leaveTime.title = '';
                this.leaveTime.description = '';
                this.leaveTime.amount = '';
                this.leaveTime.preparedById = localStorage.getItem("userId");
                this.leaveTime.transactionDt = this.getDateValue(new Date());
                this.leaveTime.transactionById = localStorage.getItem("userId");
                this.leaveTime.accountablePersonId = localStorage.getItem("userId");
                this.leaveTime.statusId = '';
                this.leaveTime.activityId = '';
                this.leaveTime.entityId = '';
            }
            this.leaveTime.editMode = flag;
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('addFinTxnPopup');
            })
        },
        showStatusPopup: function(data) {
            console.log(data);
            if (data && data !== null) {
                this.statusUpdate.id = '';
                this.statusUpdate.statusUpdateId = '';
                this.statusUpdate.refRecordId = data.id;
                this.statusUpdate.refId = data.leaveTimeId;
                this.statusUpdate.refRecordTitle = data.subject;
                this.statusUpdate.statusById = this.userId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.showDialog = true;
                this.$nextTick(() => {
                    this.$bvModal.show('statusTrackerPopup');
                })
            }
        },
        updateParent: function(status) {
            this.getLeaveTimeList();
        },
        leaveStatusBadge: function(statusLookupId) {
            if (statusLookupId == 1 || statusLookupId == 2) {
                return 'badge-warning'
            }
            else {
                return 'badge-danger'
            }
        },
        getCustomStatusText: function(statusLookupId) {
            switch(statusLookupId) {
                case 1:
                    return '1st Half';
                    break;
                case 2:
                    return '2nd Half';
                    break;
                default:
                    return 'Full Day';
            }
        },
        getDynamicWidthText(text) {
            if (this.daysToShow > 5) {
                return this.getShortString(text, 18);
            }
            return text;
        },
        closeDialog: function() {
            this.showDialog = false;
        },
    }
}
</script>