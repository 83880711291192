<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <PlanMasterList :ermMasterInfo="ermMasterInfo"></PlanMasterList>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PlanMasterList from '@/components/blocks/erm/CompanyERMMasterList.vue'
export default {
    name: 'app',
    components: {
        PlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Plan Documents",
                entity: 'plan_master',
                refEntity: 'plan',
                tooltip: 'New HL Plan',
                redirectURLName: 'lanpln',
                newEntityMasterURL: 'plnmas',
                redirectURL: '/plnmas',
                actionName: 'Plan',
                previewURLName: 'lanplnrep',
                showDateRange: true,
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>