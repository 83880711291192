<template>
    <div :id="id" class="vmx-effort">
        <b-input-group>
            <b-input-group-prepend v-if="yearVisible" class="custom-input-group-prepend year-section"  v-bind:class="getClass()">        
                <b-form-input :id="yearId" type="number" :value="year" @input="yearHandleInput" :disabled="yearDisabled" :placeholder="yearPlaceholderText">
                </b-form-input>
            </b-input-group-prepend>
            <div v-if="monthVisible" class="input-group-hrs month-section" v-bind:class="getClass()">        
                <b-form-input  :id="monthId" type="number"  :value="month" @input="monthHandleInput" :disabled="monthDisabled" :placeholder="monthPlaceholderText">
                </b-form-input>
            </div>
            <b-input-group-append v-if="dayVisible" class="custom-input-group-append day-section" v-bind:class="getClass()">
                <b-form-input :id="dayId" type="number" :value="day" @input="dayHandleInput" :disabled="dayDisabled" :placeholder="dayPlaceholderText">
                </b-form-input>
            </b-input-group-append>
        </b-input-group>        
    </div>
</template>
<script>
export default {
    name: 'EffortInput',
    props: {
        yearDisabled: {type: Boolean, default: false},
        monthDisabled: {type: Boolean, default: false},
        dayDisabled: {type: Boolean, default: false},
        yearVisible: {type: Boolean, default: true},
        monthVisible: {type: Boolean, default: true},
        dayVisible: {type: Boolean, default: true},
        yearValue: { Number },
        monthValue: { Number },
        dayValue: { Number },
        id: { type: String, default: 'vms-effort-input' },
        yearId: { type: String, default: 'vms-day-input' },
        monthId: { type: String, default: 'vms-hrs-input' },
        dayId: { type: String, default: 'vms-min-input' },
        yearPlaceholderText: { type: String, default: '' },
        monthPlaceholderText: { type: String, default: '' },
        dayPlaceholderText: { type: String, default: '' }         
    },
    watch: {
        'yearValue': {
            handler: function(value) {
                this.year = value;
            },
            deep: true,
            immediate: true
        },
        'monthValue': {
            handler: function(value) {
                this.month = value;
            },
            deep: true,
            immediate: true
        },
        'dayValue': {
            handler: function(value) {           
                this.day = value;
            },
            deep: true,
            immediate: true
        },
    },
    computed: {},
    data() {
        return {
            projectId: localStorage.getItem("projectId"),
            year: '',
            month: '',
            day: '',
        }
    },
    validations: {},
    mounted() {
        //this.setEffortInputData(this.yearValue, this.monthValue, this.dayValue);
    },
    methods: {
        setValueInputData:function(year, month, day){
            console.log(year, month, day);
            this.year = year;
            this.month = month;
            this.day = day;
        },
        clearValueInputData:function(){
            this.year = '';
            this.month = '';
            this.day = '';
        },
        yearHandleInput(e) {
            this.year = e;
            this.$emit('vmx-value-input', {
                year:  this.year,
                month:  this.month,
                day: this.day
            })
        },
        monthHandleInput(e) {
            this.month = e;
            this.$emit('vmx-value-input', {
                year:  this.year,
                month:  this.month,
                day: this.day
            })
        },
        dayHandleInput(e) {
            this.day = e;
            this.$emit('vmx-value-input', {
                year:  this.year,
                month:  this.month,
                day: this.day
            })
        },
        getMonths:function(){
            return this.month;
        },
        setMonths:function(value){
            this.month = value;
        },
        getDays:function(){
            return this.day;
        },
        setDays:function(value){
            this.day = value;
        },
        getYears:function(){
            return this.year;
        },
        setYears:function(value){
            this.year = value;
        },
        getClass:function(){
            if(this.monthVisible  && !this.dayVisible && this.yearVisible ){
                return 'vmx-year-month';
            }else if(!this.monthVisible  && this.dayVisible && this.yearVisible  ){
                    return 'vmx-year-day';
            }else if(this.monthVisible  && this.dayVisible && !this.yearVisible  ){
                    return 'vmx-month-day';
            }else if((!this.monthVisible  && this.dayVisible && !this.yearVisible  ) || 
                    (!this.monthVisible  && !this.dayVisible && this.yearVisible ) || 
                    (this.monthVisible  && !this.dayVisible && !this.yearVisible ) ){
                    return 'vmx-one';
            }
        },
    },


}
</script>