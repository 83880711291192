<template>
    <div class="assignmentPopup">
        <b-modal no-close-on-backdrop no-close-on-esc :title="'Service Transaction' + (serviceUtilTitle ? (' - ' + serviceUtilTitle) : '')" no-enforce-focus @hide="closeDialog" :id="id" ref="serviceHistoryPopupRef" size="lg" modal-class="">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-card no-body class="">
                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-srvTxn>
                                <span v-if="elements.editMode"><i class="fa fa-pencil pr-2 action-link"></i>Edit Transaction</span>
                                <span v-else><i class="fa fa-plus-circle pr-2 action-link"></i>New Transaction</span>
                            </label>
                        </b-card-header>
                        <b-collapse id="accordion-srvTxn" accordion="my-accordion-srvTxn" v-model="elements.visible" role="tabpanel">
                            <b-card-body class="">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="serviceUtil">Transaction Title</label>
                                            <input type="text" v-model="serviceHistory.title" maxlength="100" class="form-control" id="serviceUtil" placeholder="Enter Title">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="transactionId">Transaction Id</label>
                                            <input type="text" v-model="serviceHistory.transactionId" maxlength="100" class="form-control" id="transactionId" placeholder="Enter Transaction Id">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="cost">Amount</label>
                                            <VhextMoneyControl id="cost" :value="Math.abs(Number(serviceHistory.cost))" @money-input="moneyControlInput" />
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="startDate">Service Start Date</label>
                                            <input v-model="serviceHistory.startDate" type="date" id="startDate" class="form-control" >
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="endDate">Service End / Expiry Date</label>
                                            <input v-model="serviceHistory.endDate" type="date" id="endDate" class="form-control" >
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="paymentDueDate">Due Date</label>
                                            <input v-model="serviceHistory.paymentDueDate" type="date" id="paymentDueDate" class="form-control" >
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="paymentDate">Payment Date</label>
                                            <input v-model="serviceHistory.paymentDate" type="date" id="paymentDate" class="form-control" >
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="statusId">Sevice Status</label>
                                            <select v-model="serviceHistory.statusId" class="form-control" id="statusId">
                                                <option v-for="(info, index) in serviceHistoryStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                        </div>
                                    </div>
                                </div>
                                <b-row>
                                    <div class="col-12 text-right">
                                        <div class="text-right">
                                            <button type="submit" @click="saveData" class="sbtn btn btn-primary btn-xs px-2 mr-1"><i v-if="!elements.editMode" class="fa fa-plus-circle pr-1"></i>{{ elements.editMode ? 'Update' : 'Add' }}</button>
                                            <button type="submit" @click="clearData()" class="sbtn btn btn-outline-secondary btn-xs px-2"><i class="fa fa-times pr-1"></i>Cancel</button>
                                        </div>
                                    </div>
                                </b-row>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </b-container>
                <b-container fluid>
                    <div class="row mt-2">
                        <div class="col-12 mt-2">
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-left">
                                        <div class="dataTables_length">
                                            <label class="d-block accordion-title" for="statusId">Transaction History</label>
                                        </div>
                                    </div>
                                    <div class="float-right">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" outlined stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="serviceHistoryList" :fields="serviceHistoryFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(cost)="data">
                                    {{ currencySymbol }} {{data.value}}
                                </template>                                
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)">
                                        <i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i>
                                    </a>
                                    <a href="javascript:" title="Delete" @click="deleteServiceHistory(data.item.id)"  v-b-modal.deleteServiceHistory><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Transactions found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="serviceHistoryList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </b-container>
            </b-overlay>
            <!-- <template #modal-footer>
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" class="float-right" @click="closeDialog">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="saveData" class="float-right mr-2">
                        <span>{{ elements.editMode ? 'Update' : 'Add' }}</span>
                    </b-button>
                </div>
            </template> -->
        </b-modal>
        <DeleteComponent id="deleteServiceHistory" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Service History ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
export default {
    props: {
        serviceUtilId: [String, Number],
        serviceUtilTitle: String,
        id: {type:String, default:'serviceHistoryPopup'},
    },
    components: {
        AttachmentComponent,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                visible: false,
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            currencySymbol: localStorage.getItem("currency"),
            userId: localStorage.getItem("userId"),
            entity: 'service_history',

            serviceHistory: {
                id: '',
                serviceHistoryId: '',
                transactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                serviceUtilId: '',
                title: '',
                cost: '',
                startDate: '',
                endDate: '',
                servicePeriodTypeId: '',
                paymentDueDate: '',
                paymentDate: '',
                transactionById: '',
                statusId: '',
                createdById: localStorage.getItem("userId"),
            },

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],

            serviceHistoryStatus: [],
            serviceHistoryTypes: [],

            serviceHistoryList: [],
            serviceHistoryFields: [
                { key:'index',label: 'SN' },
                // { key: 'id', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Transaction Title', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'endDate', label: 'End / Expiry Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'paymentDueDate', label: 'Due Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'paymentDate', label: 'Txn Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
                { key: 'cost', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }

            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.getServiceHistory();
        this.lookupData();
    },
    validations: {
        // serviceHistory: {
        //     title: {
        //         required,
        //         minLength: minLength(2),
        //         maxLength: maxLength(100)
        //     },
        // }
    },
    methods: {
        getServiceHistory: function() {
            if (this.serviceUtilId) {
                let data = {
                    serviceUtilId: this.serviceUtilId,
                    vhextAccountId: this.vhextAccountId,
                }
                console.log(data);
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.serviceHistoryList = result.serviceHistoryList;
                        this.totalRows = this.serviceHistoryList.length;
                        console.log(result);
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
                entity: this.entity,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.serviceHistoryTypes = result.typeLookup;
                    this.serviceHistoryStatus = result.statusLookup;

                    this.serviceHistory.statusId = this.serviceHistoryStatus[0].id;
                }).catch((error)=> this.showOverlay = false);
        },
        async saveData() {
            this.serviceHistory.serviceUtilId = this.serviceUtilId;
            this.serviceHistory.title = this.serviceHistory.title ? this.serviceHistory.title : this.serviceUtilTitle;

            this.showOverlay = true;
            let result;
            if (this.elements.editMode) {
                result = await this.updateHistory();
                await this.$refs.attachmentControl.saveAttachment(this.entity, this.serviceHistory.id);
            } else {
                result = await this.saveHistory();
                await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
            }

            this.showOverlay = false;
            if (result.data.status == "CREATED") {
                iziToast.success({
                    title: 'Success',
                    message: result.data.message,
                    position: 'topRight'
                });
                this.showOverlay = false;
                this.getServiceHistory();
                this.$emit('refreshData');
                this.clearData();
            }
        },
        saveHistory: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/add', this.serviceHistory)
        },
        updateHistory: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/company/servicehistory/update', this.serviceHistory)
        },
        deleteServiceHistory: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: this.entity,
                id: this.deleteParam.id,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/erd/delete' , data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getServiceHistory();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },  
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.serviceHistory.id = '';
            this.serviceHistory.serviceHistoryId = '';
            this.serviceHistory.transactionId = '';
            this.serviceHistory.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.serviceHistory.serviceUtilId = '';
            this.serviceHistory.title = '';
            this.serviceHistory.cost = '';
            this.serviceHistory.startDate = '';
            this.serviceHistory.endDate = '';
            this.serviceHistory.servicePeriodTypeId = '';
            this.serviceHistory.paymentDueDate = '';
            this.serviceHistory.paymentDate = '';
            this.serviceHistory.transactionById = '';
            this.serviceHistory.statusId = this.serviceHistoryStatus[0].id;
            this.serviceHistory.createdById = localStorage.getItem("userId");
            this.$refs.attachmentControl.clearAttachment();
            this.attachments = [];
            // this.$v.serviceHistory.$reset();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.serviceHistory.id = data.id;
            this.serviceHistory.serviceHistoryId = data.serviceHistoryId;
            this.serviceHistory.transactionId = data.transactionId;
            this.serviceHistory.vhextAccountId = data.vhextAccountId;
            this.serviceHistory.serviceUtilId = data.serviceUtilId;
            this.serviceHistory.title = data.title;
            this.serviceHistory.cost = data.cost;
            this.serviceHistory.startDate = data.startDate;
            this.serviceHistory.endDate = data.endDate;
            this.serviceHistory.servicePeriodTypeId = data.servicePeriodTypeId;
            this.serviceHistory.paymentDueDate = data.paymentDueDate;
            this.serviceHistory.paymentDate = data.paymentDate;
            this.serviceHistory.transactionById = data.transactionById;
            this.serviceHistory.statusId = data.statusId;
            this.serviceHistory.createdById = data.createdById;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        moneyControlInput: function(value) {
            this.serviceHistory.cost = Math.abs(value);
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.clearData();
        },
    }
}
</script>