<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Plan Task</h4>
                        </div>
                        <div class="col-lg-8 col-sm-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Board View" class="action-link" @click="routeTaskBoard()" >
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>                                    
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" title="Gantt View" @click="routeTaskGantt"><i class="fa fa-sliders action-link px-1" aria-hidden="true"></i></a>
                                    </li>

                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="mb-0 fs-14">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <PlanBacklogsComponent class="col-12" />
            </div>      
        </div>
    </div>
</template>
<script>
import PlanBacklogsComponent from '@/components/common/PlanBacklogsComponent.vue';
export default {
    name: 'planBacklogs',
    components: {
        PlanBacklogsComponent,
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
        }
    },
    methods: {

        routeTaskBoard() {
            let routePath = this.$route.path;
            if (routePath.includes('landing')) {
                this.$router.push('/landing/tskbrd');
            } else {
                this.$router.push('/tskbrd');
            }
        },
        routeTaskGantt: function() {
            let routePath = this.$route.path;
            if(routePath.includes('landing')){
                this.$router.push('/landing/taskganttchart');
            }
            else{
                this.$router.push('/taskganttchart');
            }

        },

    }

}
</script>