<template>
    <div>
        <ContractTopMenu v-if="elements.showMenu && vmxServiceCode == 'SCN'" />
        <MainMenu v-else-if="elements.showMenu" />
        <LeftMenu />
        <main class="default-transition" style="opacity: 1;">
            <slot v-if="configloaded" />
        </main>
        <RightMenu />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ContractTopMenu from '@/layouts/blocks/ContractTopMenu';
import MainMenu from '@/layouts/blocks/Menu';
import LeftMenu from '@/layouts/blocks/LeftMenu';
import RightMenu from '@/layouts/blocks/RightMenu';
export default {
    components: {
        ContractTopMenu,
        MainMenu,
        LeftMenu,
        RightMenu
    },
    data() {
        return {
            elements: {
                showLogoBlock: false,
                showMenu: false
            },
            configloaded: false,
            usertype: '',
            login: {
                authToken: '',
                vmxServiceCode: '',
                vmxServiceRecordId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
        }
    },
    computed: {
        vmxServiceCode() {
            return localStorage.getItem("vmxServiceCode");
        },
        projectId() {
            return localStorage.getItem("projectId");
        }
    },
    mounted() {
        let authtoken = this.$store.getters.getAuthToken;
        let userinfo = this.$store.getters.currentUser;
        if (authtoken) {
            let accessedbBy = this.$route.meta.accessedby;
            if (jQuery.isEmptyObject(userinfo)) {
                this.login.authToken = authtoken;
                this.login.vmxServiceCode = this.vmxServiceCode;
                this.login.vmxServiceRecordId = this.projectId;
                axios.post(this.$store.getters.getAPIBasePath + '/user/refresh', this.login)
                    .then(response => {
                        let result = response.data;
                        if (result.result) {
                            this.$store.dispatch("setupLogin", result).then(() => {                     
                                if (accessedbBy!= null && accessedbBy!= undefined && accessedbBy.indexOf(response.data.data.userType) == -1) {
                                    this.redirectBacktoLogin();
                                }
                                if (response.data.data.token != authtoken) {
                                    localStorage.setItem(this.$store.getters.getLocalStorageName, response.data.data.token);
                                    window.axios.defaults.headers.common['Authorization'] = response.data.data.token;
                                } else {
                                    window.axios.defaults.headers.common['Authorization'] = authtoken;
                                }
                                this.usertype = response.data.data.userType;
                                this.elements.showMenu = true;
                                this.configloaded = true;
                            });
                        } else {
                            this.redirectBacktoLogin();
                        }
                    })
                    .catch(error => {
                        this.redirectBacktoLogin();
                        return false;
                        if (error.response.status === 401) {
                            this.redirectBacktoLogin();
                        } else {

                            iziToast.error({
                                title: 'Error Occurred',
                                message: 'Please try again',
                                position: 'topRight'
                            });
                        }
                    });

            } else {
                if (accessedbBy.indexOf(userinfo.userType) == -1) {
                    this.redirectBacktoLogin();
                }
                if (userinfo.token != authtoken) {
                    localStorage.setItem(this.$store.getters.getLocalStorageName, userinfo.token);
                    window.axios.defaults.headers.common['Authorization'] = userinfo.token;
                } else {
                    window.axios.defaults.headers.common['Authorization'] = authtoken;
                }
                this.usertype = userinfo.userType;
                this.elements.showMenu = true;
                this.configloaded = true;
            }
        } else {
            this.redirectBacktoLogin();
        }
    },
    methods: {
        redirectBacktoLogin() {
            this.$store.dispatch("doLogout");
            this.$router.push('/');
        },
        loadMasterInfo() {

        }
    }
}
</script>