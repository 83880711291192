import BudgetPlanReport from '@/views/report/doc/BudgetPlanReport.vue'
import CostEstimationReport from '@/views/report/doc/CostEstimationReport.vue'
import DefectDbReport from '@/views/report/doc/DefectDbReport.vue'
import DesignReport from '@/views/report/doc/DesignReport.vue'
import DevelopmentPlanReport from '@/views/report/doc/DevelopmentPlanReport.vue'
import EffortEstimationReport from '@/views/report/doc/EffortEstimationReport.vue'
import ErrorReport from '@/views/report/doc/ErrorReport.vue'
import FeedbackFormReport from '@/views/report/doc/FeedbackFormReport.vue'
import FinancialPlanReport from '@/views/report/doc/FinancialPlanReport.vue'
import FinancialTxnReport from '@/views/report/doc/FinancialTxnReport.vue'
import IssueReport from '@/views/report/doc/IssueReport.vue'
import MeetingPlanReport from '@/views/report/doc/MeetingPlanReport.vue'
import MeetingPreparationReport from '@/views/report/doc/MeetingPreparationReport.vue'
import MoMReport from '@/views/report/doc/MoMReport.vue'
import ObjectiveReport from '@/views/report/doc/ObjectiveReport.vue'
import PayrollBankTransferReport from '@/views/report/doc/PayrollBankTransferReport.vue'
import PayrollReport from '@/views/report/doc/PayrollReport.vue'
import PlanReport from '@/views/report/doc/PlanReport.vue'
import ProcessPlanReport from '@/views/report/doc/ProcessPlanReport.vue'
import ProjectDetailsReport from '@/views/report/doc/ProjectDetailsReport.vue'
import DocumentFolderReport from '@/views/report/doc/DocumentFolderReport.vue'
import ProjectStandardReport from '@/views/report/doc/ProjectStandardReport.vue'
import ProjectTeamReport from '@/views/report/doc/ProjectTeamReport.vue'
import ReleaseListReport from '@/views/report/doc/ReleaseListReport.vue'
import ReportingPlanReport from '@/views/report/doc/ReportingPlanReport.vue'
import ResourcePlanReport from '@/views/report/doc/ResourcePlanReport.vue'
import RiskPlanningReport from '@/views/report/doc/RiskPlanningReport.vue'
import SSReport from '@/views/report/doc/ScopeStatementReport.vue'
import SystemRequirementSpecReport from '@/views/report/doc/SystemRequirementSpecReport.vue'
import TaskListReport from '@/views/report/doc/TaskListReport.vue'
import TestCaseReport from '@/views/report/doc/TestCaseReport.vue'
import TestPlanReport from '@/views/report/doc/TestPlanReport.vue'
import TestResultReport from '@/views/report/doc/TestResultReport.vue'
import TimeSheetReport from '@/views/report/doc/TimeSheetReport.vue'
import URQReport from '@/views/report/doc/UserRequirementReport.vue'
import UATReport from '@/views/report/doc/UatTestCaseReport.vue'





export default [
    {
        path: '/bplrep/:ermId',
        name: 'bplrep',
        component: BudgetPlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/coerep/:ermId',
        name: 'coerep',
        component: CostEstimationReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/cplrep/:ermId',
        name: 'cplrep',
        component: MeetingPlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/eferep/:ermId',
        name: 'eferep',
        component: EffortEstimationReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fplrep/:ermId',
        name: 'fplrep',
        component: FinancialPlanReport,
        meta: { accessedby: 'PRM|EMP|TMB|CLI' }
    },
    {
        path: '/ftxrep/:ermId',
        name: 'ftxrep',
        component: FinancialTxnReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB' }
    },    
    {
        path: '/issrep/:ermId',
        name: 'issrep',
        component: IssueReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/mmnrep/:ermId',
        name: 'mmnrep',
        component: MoMReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/objrep/:ermId',
        name: 'objrep',
        component: ObjectiveReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },

    {
        path: '/prdrep',
        name: 'prdrep',
        component: ProjectDetailsReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB' }
    },
    
    {
        path: '/pdcrep/:ermId',
        name: 'pdcrep',
        component: DocumentFolderReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    
    {
        path: '/landing/prlbtrrep/:ermId',
        name: 'prlbtrrep',
        component: PayrollBankTransferReport,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|VSA|FNM|HRM' }
    },
    {
        path: '/landing/prlrep/:ermId',
        name: 'prlrep',
        component: PayrollReport,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|CAM|PRM|VSA|FNM|HRM' }
    },

    {
        path: '/plnrep/:ermId',
        name: 'plnrep',
        component: PlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/pmdrep/:ermId',
        name: 'pmdrep',
        component: ProcessPlanReport,
        meta: { accessedby: 'VSA|CAM|PRM|EMP|TMB' }
    },
    {
        path: '/prtrep/:ermId',
        name: 'prtrep',
        component: ProjectTeamReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rplrep/:ermId',
        name: 'rplrep',
        component: ResourcePlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/sstrep/:ermId',
        name: 'sstrep',
        component: SSReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/srsrep/:ermId',
        name: 'srsrep',
        component: SystemRequirementSpecReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tplrep/:ermId',
        name: 'tplrep',
        component: TestPlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tslrep/:ermId',
        name: 'tslrep',
        component: TaskListReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tsrrep/:ermId',
        name: 'tsrrep',
        component: TestResultReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/uatrep/:ermId',
        name: 'uatrep',
        component: UATReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/urqrep/:ermId',
        name: 'urqrep',
        component: URQReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/mtprep/:ermId',
        name: 'mtprep',
        component: MeetingPreparationReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rsprep/:ermId',
        name: 'rsprep',
        component: RiskPlanningReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/prsrep/:ermId',
        name: 'prsrep',
        component: ProjectStandardReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/fbfrep/:ermId',
        name: 'fbfrep',
        component: FeedbackFormReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/ddbrep/:ermId',
        name: 'ddbrep',
        component: DefectDbReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/dsnrep/:ermId',
        name: 'dsnrep',
        component: DesignReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/rllrep/:ermId',
        name: 'rllrep',
        component: ReleaseListReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/reprep/:ermId',
        name: 'reprep',
        component: ReportingPlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tmsrep/:ermId',
        name: 'tmsrep',
        component: TimeSheetReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/dvprep/:ermId',
        name: 'dvprep',
        component: DevelopmentPlanReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/tscrep/:ermId',
        name: 'tscrep',
        component: TestCaseReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    },
    {
        path: '/errrep/:ermId',
        name: 'errrep',
        component: ErrorReport,
        meta: { accessedby: 'CRP|CRM|CAM|PRM|EMP|TMB|VSA|FNM|HRM' }
    }

];