<template>
    <div>
        <ProjectFundingMasterList :ermMasterInfo="ermMasterInfo"></ProjectFundingMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectFundingMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ProjectFundingMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showProjectFundingMasterList: false
            },
            ermMasterInfo: {
                title: "Project Funding",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
                tooltip: 'New Project Funding',
                redirectURLName: 'projectfunding',
                newEntityMasterURL: 'ftxmas',
                redirectURL: '/ftxmas',
                actionName: 'Project Funding',
                previewURLName: 'ftxrep',
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>