<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Risk Plan</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-7 col-xl-7">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title ptl-2" block href="#" v-b-toggle.accordion-1>
                                            <span v-if="!elements.editMode && !elements.viewMode">New Risk</span>
                                            <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                            <span v-if="elements.viewMode">View</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="p-2">
                                            <div v-if="!elements.viewMode">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="duration">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.risk.title.$error }">
                                                                <input type="text" class="form-control" v-model="risk.title" id="title" :class="{ 'is-invalid': $v.risk.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.risk.title.$error && !$v.risk.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.risk.title.$error && !$v.risk.title.maxLength" class="text-danger">Title must not exceed {{$v.risk.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <label for="duration">Type</label>
                                                            <select v-model="risk.riskTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in riskTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-13 pl-2">
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                </div>                                                        
                                                <div class="row mb-2">                                                        
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                        <textarea v-model="risk.description" class="form-control" rows="4" required></textarea>
                                                        <label v-if="$v.risk.description.$error && !$v.risk.description.required" class="text-danger">Please enter Description</label>
                                                        <label v-if="$v.risk.description.$error && !$v.scope.description.maxLength" class="text-danger">Description must not exceed {{$v.risk.description.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="severity">Severity</label>
                                                            <select v-model="risk.riskSeverityId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in severities" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="priority">Priority</label>
                                                            <select v-model="risk.priorityId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in priorities" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="status">Status</label>
                                                            <select v-model="risk.statusId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="saveData" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                            <div v-if="elements.viewMode" id="viewRisk">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <label for="title">Title</label>
                                                        <b-input-group :prepend="risk.riskId" class="mb-2">
                                                            <b-form-input aria-label="First name" disabled v-model="risk.title"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label for="status">Status</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{risk.status}}</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label for="severity">Severity</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{risk.severity}}</span></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-2">
                                                        <div class="form-group">
                                                            <label for="priority">Priority</label>
                                                            <p><span class="badge badge-pill badge-blue mb-1">{{risk.priority}}</span></p>
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="deliverables">Type</label>
                                                            <input type="text" class="form-control" disabled v-model="risk.riskType">
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="probability">Probability</label>
                                                            <input type="text" class="form-control" disabled v-model="risk.probability">
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-12">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <textarea rows="4" class="form-control" id="description" disabled v-model="risk.description"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="risk.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="endDate">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="risk.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="riskList" @row-selected="onRowSelected" :fields="riskFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteRisk(data.item.id)" data-toggle="modal" data-target="#deleteRisk"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="risk">
                                    <div class="text-center">No Risk Plan found</div>
                                </template>
                                <template v-slot:emptyfiltered="risk">
                                    <div class="text-center">{{ risk.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="riskList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right">
                            </b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-xl-5 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Risk Plan">
                                    <div class="card-body p-3">
                                        <div class="input-group mb-3"> 
                                            <div class="prepend">
                                                <span class="input-group-text" id="riskId" >{{risk.riskId}}</span>
                                            </div>
                                            <input type="text" class="form-control" id="riskTitle" v-model="risk.title"  :disabled="!elements.editMode" >
                                            <div class="input-group-append">
                                                <span class="input-group-text" id="riskId" >{{risk.status}}</span>
                                            </div>                                    
                                        </div>
                                        <div id="scope-app" class="mb-3">
                                            <b-card no-body class="">
                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                    <label class="d-block p-2 accordion-title ptl-2" block href="#" v-b-toggle.accordion-2>
                                                        <!--mpl-Mitigation Plan-->
                                                        <span v-if="!elements.mplEditMode && !elements.mplViewMode">Risk Mitigation</span>
                                                        <span v-if="elements.mplEditMode && !elements.mplViewMode">Edit</span>
                                                        <span v-if="elements.mplViewMode">View</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-2" accordion="my-accordion" v-model="elements.mplVisible" role="tabpanel">
                                                    <b-card-body class="p-2">
                                                        <div v-if="!elements.mplViewMode">
                                                            <div class="row mb-2">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="duration">Mitigation Plan</label>
                                                                        <textarea v-model="riskPlan.riskPlan" class="form-control" rows="4" required></textarea>
                                                                        <!--<label v-if="$v.riskPlan.riskPlan.$error && !$v.riskPlan.riskPlan.required" class="text-danger">Please enter Description</label>
                                                        <label v-if="$v.riskPlan.description.$error && !$v.scope.description.maxLength" class="text-danger">Description must not exceed {{$v.riskPlan.description.$params.maxLength.max}} letters.</label>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" @click="saveRiskPlanData(1)" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                        <div v-if="elements.mplViewMode" id="viewMitigationPlan">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="Strategy">Strategy</label>
                                                                        <textarea type="text" class="form-control" disabled v-model="riskPlan.type"></textarea>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="MitigationPlan">Mitigation Plan</label>
                                                                        <textarea type="text" class="form-control" disabled v-model="riskPlan.riskPlan"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="mitigationPlanList" @row-selected="onRowSelectedMitigationPlan" :fields="mitigationPlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View" @click="showMitigationPlanView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                                <a href="javascript:" title="Edit" @click="showMitigationPlanEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                <a href="javascript:" title="Delete" @click="deleteRiskPlan(data.item.id)" data-toggle="modal" data-target="#deleteRiskPlan"><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="MitigationPlan">
                                                <div class="text-center">No Mitigation Plan found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="MitigationPlan">
                                                <div class="text-center">{{ riskPlan.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <b-pagination v-if="mitigationPlanList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                    <!-- Contigency -->
                                    <div class="card-body p-3">
                                        <div id="scope-app" class="mb-3">
                                            <b-card no-body class="">
                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                    <label class="d-block p-2 accordion-title ptl-2" block href="#" v-b-toggle.accordion-3>
                                                        <!--cpl-Contingency Plan-->
                                                        <span v-if="!elements.cplEditMode && !elements.cplViewMode">Risk Contingency</span>
                                                        <span v-if="elements.cplEditMode && !elements.cplViewMode">Edit</span>
                                                        <span v-if="elements.cplViewMode">View</span>
                                                    </label>
                                                </b-card-header>
                                                <b-collapse id="accordion-3" accordion="my-accordion" v-model="elements.cplVisible" role="tabpanel">
                                                    <b-card-body class="p-2">
                                                        <div v-if="!elements.cplViewMode">
                                                            <div class="row mb-2">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="duration">Contingency Plan</label>
                                                                        <textarea v-model="riskPlan.riskPlan" class="form-control" rows="4" required></textarea>
                                                                        <!--<label v-if="$v.riskPlan.riskPlan.$error && !$v.riskPlan.riskPlan.required" class="text-danger">Please enter Description</label>
                                                        <label v-if="$v.riskPlan.description.$error && !$v.scope.description.maxLength" class="text-danger">Description must not exceed {{$v.riskPlan.description.$params.maxLength.max}} letters.</label>-->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" @click="saveRiskPlanData(2)" class="sbtn btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                        <div v-if="elements.cplViewMode" id="viewContingencyPlan">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="form-group">
                                                                        <label for="ContingencyPlan">Contingency Plan</label>
                                                                        <textarea type="text" class="form-control" disabled v-model="riskPlan.riskPlan"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                        </div>
                                                    </b-card-body>
                                                </b-collapse>
                                            </b-card>
                                        </div>
                                        <b-table show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="contingencyPlanList" @row-selected="onRowSelectedContingencyPlan" :fields="contingencyPlanFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="View" @click="showContingencyPlanView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                                <a href="javascript:" title="Edit" @click="showContingencyPlanEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                <a href="javascript:" title="Delete" @click="deleteRiskPlan(data.item.id)" data-toggle="modal" data-target="#deleteRiskPlan"><i class="fa fa-trash pl-2"></i></a>
                                            </template>
                                            <template v-slot:empty="ContingencyPlan">
                                                <div class="text-center">No Contingency Plan found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="ContingencyPlan">
                                                <div class="text-center">{{ riskPlan.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <b-pagination v-if="contingencyPlanList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>

        <Dialog id="deleteRisk" :onYes="onYesRisk" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete risk?" />
        <Dialog id="deleteRiskPlan" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete risk plan?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
    },

    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false,
                viewMode: false,
                mplEditMode: false,
                mplVisible: false,
                mplViewMode: false,
                cplEditMode: false,
                cplVisible: false,
                cplViewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeEntity: 'risk_type',
            
            typeLookupTitle:'Risk Type Settings',
            risk: {
                id: '',
                riskId: '',
                title: '',
                riskTypeId: '',
                type: '',
                priorityId: '',
                priority: '',
                riskSeverityId: '',
                severity: '',
                description: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'risk',
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            riskPlan: {
                id: '',
                riskPlanId: '',
                riskId: '',
                riskPlanTypeId: '',
                type: '',
                riskPlan: '',
                probability: '',
                riskSeverityId: '',
                severity: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'risk_plan',
                preapredById: localStorage.getItem("userId"),
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            riskList: [],
            mitigationPlanList: [],
            contingencyPlanList: [],
            riskTypes: [],
            riskPlanType: [],
            severities: [],
            priorities: [],
            statuses: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            riskFields: [
                { key: 'riskId', label: 'Risk Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'severity', label: 'Severity', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            mitigationPlanFields: [
                { key: 'riskPlanId', label: 'SNo.', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Strategy', sortable: true, sortDirection: 'desc' },
                { key: 'riskPlan', label: 'Mitigation Plan', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            contingencyPlanFields: [
                { key: 'riskPlanId', label: 'SNo.', sortable: true, sortDirection: 'desc' },
                { key: 'riskPlan', label: 'Contingency Plan', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getRisk();
        this.lookupData();
        this.risklookupData();
    },
    validations: {
        risk: {
            title: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                maxLength: maxLength(2000)
            }
        },
        riskPlan: {
            title: {
                required,
                maxLength: maxLength(100)
            },
            description: {
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        getRisk: function() {
            let data = {
                entityRecordMasterId: this.risk.entityRecordMasterId,
            }

            axios.post(this.$store.getters.getAPIBasePath + '/risk/list', data)
                .then((response) => {
                    let result = response.data;
                    this.riskList = result.risklist;
                    this.totalRows = this.riskList.length;
                    this.clearData();

                    //console.log(result);
                })
        },

        getRiskPlan: function(riskPlanTypeId) {
            this.getRiskMitigationPlan(1);
            this.getRiskContingencyPlan(2)
        },

        getRiskMitigationPlan: function(riskPlanTypeId) {
            let data = {
                riskId: this.riskPlan.riskId,
                riskPlanTypeId: riskPlanTypeId

            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/risk/riskplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.mitigationPlanList = result.riskPlanlist;
                    this.totalRows = this.mitigationPlanList.length;
                    console.log(result);
                })
        },
        getRiskContingencyPlan: function(riskPlanTypeId) {
            let data = {
                riskId: this.riskPlan.riskId,
                riskPlanTypeId: riskPlanTypeId

            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/risk/riskplan/list', data)
                .then((response) => {
                    let result = response.data;
                    this.contingencyPlanList = result.riskPlanlist;
                    this.totalRows = this.contingencyPlanList.length;
                    console.log(result);
                })
        },

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        risklookupData: function() {
            let data = {
                projectId: this.risk.projectId,
                vhextAccountId: this.risk.vhextAccountId,
                entity: this.risk.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/risk/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.riskTypes = result.customTypeLookup;
                    this.severities = result.severityLookup;
                    this.priorities = result.priorityLookup;
                    this.statuses = result.statusLookup;

                })

        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.risk.vhextAccountId,
                projectId: this.risk.projectId,
                entity: this.riskPlan.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/risk/riskplan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.riskPlanType = result.customTypeLookup;
                    this.riskPlanSeverityLookup = result.severityLookup;
                    
                })

        },
        saveData: function() {
            this.$v.risk.$touch();
            if (!this.$v.risk.$invalid) {
                if (this.elements.editMode) {
                    this.updateRisk();
                } else {
                    this.saveRisk();
                }
            }
        },

        saveRisk: function() {
            console.log(this.risk);
            axios.post(this.$store.getters.getAPIBasePath + '/risk/add', this.risk)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRisk();
                    }
                })
        },
        saveRiskPlanData: function(riskPlanTypeId) {
            /*this.$v.riskPlan.$touch();
            if (!this.$v.riskPlan.$invalid) {*/
            if (this.elements.mplEditMode || this.elements.cplEditMode) {
                this.updateRiskPlan(riskPlanTypeId);
            } else if (!this.elements.mplEditMode && !this.elements.cplEditMode) {
                this.saveRiskPlan(riskPlanTypeId);
            }
            /*}*/
        },
        saveRiskPlan: function(riskPlanTypeId) {

            this.riskPlan.riskPlanTypeId = riskPlanTypeId;
            console.log(this.riskPlan);
            axios.post(this.$store.getters.getAPIBasePath + '/risk/riskplan/add', this.riskPlan)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        if (riskPlanTypeId == 1) {
                            this.getRiskMitigationPlan(riskPlanTypeId);
                        } else {
                            this.getRiskContingencyPlan(riskPlanTypeId);
                        }


                        this.getRiskPlan(riskPlanTypeId);
                    }
                })
        },
        updateRisk: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/risk/update', this.risk)
                .then((response) => {
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRisk();
                    }
                })
        },
        updateRiskPlan: function() {
            console.log(this.riskPlan)
            axios.post(this.$store.getters.getAPIBasePath + '/risk/riskplan/update', this.riskPlan)
                .then((response) => {
                    let result = response.data;
                    this.elements.mplEditMode = false;
                    this.elements.cplEditMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRiskPlan();
                    }
                })
        },
        deleteRisk: function(id) {
            this.deleteParam.id = id;
        },
        onYesRisk: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/risk/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRisk();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        deleteRiskPlan: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/risk/riskplan/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getRiskPlan();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.elements.mplEditMode = false;
            this.elements.mplVisible = false;
            this.elements.mplViewMode = false;
            this.elements.cplEditMode = false;
            this.elements.cplVisible = false;
            this.elements.cplViewMode = false;
            this.risk.id = '';
            this.risk.riskId = '';
            this.risk.title = '';
            this.risk.riskTypeId = '';
            this.risk.riskSeverityId = '';
            this.risk.description = '';
            this.risk.priority = '';
            this.riskPlan.riskId = '';
            this.riskPlan.title = '';
            this.riskPlan.riskPlanTypeId = '';
            this.riskPlan.riskSeverityId = '';
            this.riskPlan.description = '';
            this.riskPlan.probability = '';
            this.riskPlan.riskPlan = '';
            this.riskPlan.id = '';
            this.$v.risk.$reset();
            this.$v.riskPlan.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = true;
            this.elements.editMode = true;
            this.elements.viewMode = false;

            this.risk.id = data.id;
            this.risk.riskId = data.riskId;
            this.risk.title = data.title;
            this.risk.riskTypeId = data.riskTypeId;
            this.risk.riskSeverityId = data.riskSeverityId;
            this.risk.priorityId = data.priorityId;
            this.risk.description = data.description;
            this.risk.statusId = data.statusId;
        },
        showMitigationPlanEdit: function(data) {
            console.log(data)
            this.elements.mplVisible = true;
            this.elements.mplEditMode = true;
            this.elements.mplViewMode = false;
            this.riskPlan.riskPlan = data.riskPlan;
            this.riskPlan.riskPlanId = data.riskPlanId;
            this.riskPlan.id = data.id;
            this.riskPlan.riskPlanTypeId = data.riskPlanTypeId;

        },
        showContingencyPlanEdit: function(data) {
            this.elements.cplVisible = true;
            this.elements.cplEditMode = true;
            this.elements.cplViewMode = false;
            this.riskPlan.riskPlan = data.riskPlan;
            this.riskPlan.riskPlanId = data.riskPlanId;
            this.riskPlan.id = data.id;
            this.riskPlan.riskPlanTypeId = data.riskPlanTypeId;
        },

        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.elements.showDetail = true;
                this.risk.id = data.id;
                this.risk.riskId = data.riskId;
                this.risk.title = data.title;
                this.risk.riskType = data.type;
                this.risk.severity = data.severity;
                this.risk.priorityId = data.priorityId;
                this.risk.priority = data.priority;
                this.risk.description = data.description;
                this.risk.statusId = data.statusId;                
                this.risk.status = data.status;                
                this.risk.createdDt = data.createdDt;
                this.risk.lastUpdatedDt = data.lastUpdatedDt;
                this.riskPlan.riskId = data.id;

                this.getRiskPlan(1);
            }
        },
        onRowSelectedMitigationPlan: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.showMitigationPlanView(data)

            }
        },
        onRowSelectedContingencyPlan: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.showContingencyPlanView(data)

            }
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.risk.riskId = data.riskId;
            this.risk.title = data.title;
            this.risk.riskType = data.type;
            this.risk.severity = data.severity;
            this.risk.priority = data.priority;
            this.risk.description = data.description;
            this.risk.status = data.status;
            this.risk.preparedBy = data.preparedBy;
            this.risk.createdDt = data.createdDt;
            this.risk.lastUpdatedDt = data.lastUpdatedDt;
        },
        showMitigationPlanView: function(data) {
            this.elements.mplVisible = true;
            this.elements.mplViewMode = true;
            this.riskPlan.riskPlan = data.riskPlan;
            this.riskPlan.type = data.type;
        },
        showContingencyPlanView: function(data) {
            this.elements.cplVisible = true;
            this.elements.cplViewMode = true;
            this.riskPlan.riskPlan = data.riskPlan;
        }

    }
}
</script>