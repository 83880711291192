<template>
    <div id="goToTop" class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Issues</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a title="Board View" class="action-link" @click="routeIssueBoard()" >
                                            <i class="fa fa-sitemap px-1" aria-hidden="true"></i>
                                        </a>
                                    </li>                                    
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body>
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Issue</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div v-if="!elements.viewMode">
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.issue.title.$error }">
                                                                        <input type="text" maxlength="100" ref="setFocus" class="form-control" v-model="issue.title" id="title" :class="{ 'is-invalid': $v.issue.title.$error }" placeholder="Enter Title">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.issue.title.$error && !$v.issue.title.minLength" class="text-danger">Title must have at least {{$v.issue.title.$params.minLength.min}} letters.</label>
                                                                    <label v-if="$v.issue.title.$error && !$v.issue.title.required" class="text-danger">Please enter Title</label>
                                                                    <label v-if="$v.issue.title.$error && !$v.issue.title.maxLength" class="text-danger">Title must not exceed {{$v.issue.title.$params.maxLength.max}} letters.</label>
                                                                </div>
                                                                <b-input-group v-if="elements.viewMode" :prepend="issue.defectDbId" class="mb-2">
                                                                    <b-form-input aria-label="First name" disabled v-model="issue.title"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="issueTypeId">Issue Type</label>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.type" id="title">
                                                                <b-input-group v-else>
                                                                    <select v-model="issue.issueTypeId" class="form-control" id="issueTypeId">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in issueTypes" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="typeLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="functionality">Functionality</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.functionalityId" class="form-control" id="functionalityId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in functionalities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.functionality">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="activityId">Activity</label>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.activity">
                                                                <b-input-group v-else>
                                                                    <select v-model="issue.activityId" class="form-control" id="activityId">
                                                                        <option value="">Select</option>
                                                                        <option v-for="(info, index) in issueActivities" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                    <b-input-group-append class="append-0">
                                                                        <a :title="processActivityLookupTitle" class="cust-setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                                    </b-input-group-append>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="description">Description</label>
                                                                <!-- <textarea v-model="issue.description" :disabled="elements.viewMode" class="form-control" id="description" rows="4" maxlength="2000" required></textarea> -->
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="issue.description" :disabled="elements.viewMode"></editor>
                                                            </div>
                                                        </div>
                                                        <div v-if="!elements.viewMode" class="col-12">
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                        <div v-if="elements.viewMode" class="col-12">
                                                            <div class="form-group">
                                                                <label>Attachment</label>
                                                                <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :viewAttachment="true" :attachments="attachments" />
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="solution">Solution</label>
                                                                <textarea v-model="issue.solution" :disabled="elements.viewMode" class="form-control" id="solution" rows="4" maxlength="2000" required></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="actionTaken">Action Taken</label>
                                                                <textarea v-model="issue.actionTaken" :disabled="elements.viewMode" class="form-control" id="actionTaken" rows="4" maxlength="1000" required></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="reportedBy">Reported By</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.reportedById" class="form-control" id="reportedBy">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.reportedBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="solutionBy">Assigned To</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.solutionById" class="form-control" id="solutionBy">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.solutionBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="reportedDt">Reported Date</label>
                                                                <input v-if="!elements.viewMode" type="date" v-model="issue.reportedDt" class="form-control" id="reportedDt">
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" :value="issue.reportedDt | formatDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="solutionDt">Solution Date</label>
                                                                <input v-if="!elements.viewMode" type="date" v-model="issue.solutionDt" class="form-control" id="solutionDt">
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" :value="issue.solutionDt | formatDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="severityId">Severity</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.severityId" class="form-control" id="severityId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in severities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.severity">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.statusId" class="form-control" id="statusId">
                                                                    <option v-for="(info, index) in issueStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.status">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="issues" :fields="issueFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" v-b-modal.referenceDetail @click="viewItemDetails(data.item)">
                                        {{ data.value }}
                                    </a>
                                </template>
                                <template v-slot:cell(description)="data">
                                    <span v-html="data.value">{{ data.value }}</span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <div class="dropdown dropright d-inline">
                                        <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>
                                        <div class="dropdown-menu">
                                            <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                            <div v-if="attachmentList.length>0">
                                                <ul class="attachmentList-ul p-0 m-0">
                                                    <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                        <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="javascript:" title="Assign Task" @click="assignTask(data.item)"><i class="fa fa-user-plus pl-2"></i></a>
<!--                                     <a href="#goToTop" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a> -->
                                    <span v-if="data.item.reportedById == userId || userId == ermCreatedById">
                                        <a href="#goToTop" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteIssue(data.item.id)" v-b-modal.deleteIssue><i class="fa fa-trash pl-2"></i></a>
                                    </span>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Issue found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="issue.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <IssueDetailPopup v-if="showDialog" ref="issueDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" @closeDialog="showDialog=false" />
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />
        <QuickTaskPopup ref="ermAssignmentsRef" :masterData="masterData" />
        <DeleteComponent id="deleteIssue" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Issue?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import IssueDetailPopup from '@/components/popup/itemdetail/IssueDetailPopup.vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import QuickTaskPopup from '@/components/popup/action/QuickTaskPopup.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
        ProcessActivityLookup,
        HTMLDetailViewPopup,
        QuickTaskPopup,
        Editor,
        AttachmentComponent,
        IssueDetailPopup
    },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entityRecordMasterId: this.$route.params.ermId,
            ermCreatedById: localStorage.getItem("ermCreatedById"),
            ermTitle: localStorage.getItem("ermTitle"),

            entity: 'issue',
            typeEntity: 'issue_type',
            userId: localStorage.getItem("userId"),
            htmlData: '',
            htmlDetailViewTitle: 'Issue Detail',
            typeLookupTitle: 'Issue Type Settings',
            processActivityLookupTitle: 'Process / Activity Settings',

            attachmentList:[],

            showDialog: false,

            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
            },
            issueStatus: [],
            users: [],
            issueTypes: [],
            severities: [],
            functionalities: [],
            issueActivities: [],
            issue: {
                id: '',
                issueId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'issue',
                entityId: '',
                entityRefId: '',
                title: '',
                isArchive: false,
                issueTypeId: '',
                type: '',
                functionalityId: '',
                functionality: '',
                activityId: '',
                activity: '',
                description: '',
                solution: '',
                reportedById: localStorage.getItem("userId"),
                reportedBy: '',
                reportedDt: new Date().toISOString().substr(0, 10),
                solutionById: '',
                solutionBy: '',
                solutionDt: '',
                actionTaken: '',
                severityId: '',
                severity: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasRefLink: '',
                hasComment: '',
                hasReview: '',
                solutionDt: ''
            },

            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,

            masterData: {
                refErmId: 0,
                refErdId: 0,
                refEntityId: 0,
                refErdTitle: '',
                refErdDescription: '',
                refEntity: ''
            },


            issues: [],
            issueFields: [
                { key: 'issueId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'functionality', label: 'Functionality', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'description', label: 'Description', tdClass: 'table-cw-250', sortable: true, sortDirection: 'desc' },
                { key: 'reportedDt', label: 'Issue Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'reportedBy', label: 'Reported By', sortable: true, sortDirection: 'desc' },
                { key: 'solutionDt', label: 'Solution Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            attachmentInfo: {
                title: 'Attachment'
            },
            attachmentData: {},

            attachments: [],

            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            }
        }
    },
    mounted() {
        if (localStorage.getItem("ermId") != '')
            this.entityRecordMasterId = localStorage.getItem("ermId");
        else
            this.entityRecordMasterId = this.$route.params.ermId;
        this.lookupData();
        this.getIssue();
    },
    validations: {
        issue: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        getIssue: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/issue/list', data)
                .then((response) => {
                    let result = response.data;
                    this.issues = result.issueList;
                    this.totalRows = this.issues.length;
                    console.log("ISSUES",this.issues);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/issue/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.functionalities = result.requirementLookup;
                    this.issueActivities = result.activityLookup;
                    this.issueTypes = result.customTypeLookup;
                    this.issueStatus = result.statusLookup;
                    this.severities = result.severityLookup;
                    this.users = result.userLookup;

                    this.issue.statusId = this.issueStatus[0].id;
                })

        },
        async saveData() {
            this.$v.issue.$touch();
            if (!this.$v.issue.$invalid) {
                this.showOverlay = true;   
                let result;
                if (this.elements.editMode) {
                    result = await this.updateIssue();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, this.issue.id);
                } else {
                    result = await this.saveIssue();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }

                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {                 
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getIssue();
                    this.clearData();
                }
            }
        },
        saveIssue: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/project/issue/add', this.issue)

            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateIssue: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/project/issue/update', this.issue)
            }
            catch(error) {
                this.showOverlay = false;
                if (error.response) {
                   // Request made and server responded  
                   console.log(error.response.data.headers.message);
                } else if (error.request) {
                   // The request was made but no response was received
                   console.log(error.request);
                } else {
                   // Something happened in setting up the request that triggered an Error
                   console.log('Error', error.message);
                }
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }

        },
        deleteIssue: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/project/issue/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getIssue();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.attachmentInfo.title = "Attachment";
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.issue.id = '';
            this.issue.issueId = '';
            this.issue.title = '';
            this.issue.issueTypeId = '';
            this.issue.type = '';
            this.issue.activityId = '';
            this.issue.activity = '';
            this.issue.functionalityId = '';
            this.issue.functionality = '';
            this.issue.description = '';
            this.issue.solution = '';
            this.issue.actionTaken = '';
            this.issue.reportedById = localStorage.getItem("userId");
            this.issue.reportedBy = '';
            this.issue.reportedDt = new Date().toISOString().substr(0, 10);
            this.issue.solutionById = '';
            this.issue.solutionBy = '';
            this.issue.solutionDt = '';
            this.issue.severityId = '';
            this.issue.severity = '';
            this.issue.statusId = this.issueStatus[0].id;
            this.issue.status = '';
            this.$refs.attachmentControl.clearAttachment();
            this.$v.issue.$reset();

            // this.setFocus();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.issue.id = data.id;
            this.issue.issueId = data.issueId;
            this.issue.vhextAccountId = data.vhextAccountId;
            this.issue.projectId = data.projectId;
            this.issue.entityRecordMasterId = data.entityRecordMasterId;
            this.issue.title = data.title;
            this.issue.issueTypeId = data.issueTypeId;
            this.issue.isArchive = data.isArchive;
            this.issue.type = data.type;
            this.issue.activityId = data.activityId;
            this.issue.activity = data.activity;
            this.issue.functionalityId = data.functionalityId;
            this.issue.functionality = data.functionality;
            this.issue.description = data.description;
            this.issue.solution = data.solution;
            this.issue.actionTaken = data.actionTaken;
            this.issue.reportedById = data.reportedById;
            this.issue.reportedBy = data.reportedBy;
            this.issue.reportedDt = data.reportedDt;
            this.issue.solutionById = data.solutionById;
            this.issue.solutionBy = data.solutionBy;
            this.issue.solutionDt = data.solutionDt;
            this.issue.reportedDt = data.reportedDt;
            this.issue.severityId = data.severityId;
            this.issue.severity = data.severity;
            this.issue.statusId = data.statusId;
            this.issue.status = data.status;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })

            // this.setFocus();
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.issue.id = data.id;
            this.issue.issueId = data.issueId.toString();
            this.issue.vhextAccountId = data.vhextAccountId;
            this.issue.projectId = data.projectId;
            this.issue.entityRecordMasterId = data.entityRecordMasterId;
            this.issue.title = data.title;
            this.issue.isArchive = data.isArchive;
            this.issue.issueId = data.issueId;
            this.issue.type = data.type;
            this.issue.activityId = data.activityId;
            this.issue.activity = data.activity;
            this.issue.functionalityId = data.functionalityId;
            this.issue.functionality = data.functionality;
            this.issue.description = data.description;
            this.issue.solution = data.solution;
            this.issue.actionTaken = data.actionTaken;
            this.issue.reportedById = data.reportedById;
            this.issue.reportedBy = data.reportedBy;
            this.issue.reportedDt = data.reportedDt;
            this.issue.solutionById = data.solutionById;
            this.issue.solutionBy = data.solutionBy;
            this.issue.solutionDt = data.solutionDt;
            this.issue.reportedDt = data.reportedDt;
            this.issue.severityId = data.severityId;
            this.issue.severity = data.severity;
            this.issue.statusId = data.statusId;
            this.issue.status = data.status;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })

            // this.setFocus();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        assignTask: function(data) {
            console.log(data);
            this.masterData.refErmId = data.entityRecordMasterId;
            this.masterData.refErdId = data.id;
            this.masterData.refEntityId = '';
            this.masterData.refEntity = this.entity;
            this.masterData.refErdTitle = data.title;
            this.masterData.refErdDescription = data.description;
            this.$root.$emit('bv::show::modal', 'ermAssignments');
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.issueDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        // setErmData: function() {
        //     let ermData = {
        //         ermId: this.$store.getters.getERMData.erm_id,
        //         ermTitle: this.$store.getters.getERMData.erm_title,
        //     }

        //     console.log(ermData);
        //     this.$store.dispatch("setERMData", ermData);

        // },
        routeIssueBoard() {

            let routePath = this.$route.path;
            if (routePath.includes('landing')) {
                this.$router.push('/landing/issbrd');
            } else {
                this.$router.push('/issbrd');
            }

        }


    }
}
</script>