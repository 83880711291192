<template>
    <div class="card pb-4">
        <div class="container-fluid mb-5">
            <div class="row">
                <div class="col-12">
                    <div class="row task-top-menu align-items-center mt-1">
                        <div class="col-lg-2 col-sm-4 mt-2 pr-0 mr-0">
                            <h4 v-if="isTask" class="pl-2 mb-0 fs-16">Task Board</h4>
                            <h4 v-else class="pl-2 mb-0 fs-16">Time Board</h4>

                        </div>
                        <div class="col-lg-3 col-sm-3 pl-0">
                            <div class="row no-gutters">
                                <div class="col">
                                    <input type="date" v-model="startDate" @keyup.enter="getReportingUserTimesheets" class="form-control">
                                </div>
                                <div class="col-1 my-auto text-center">to</div>
                                <div class="col">
                                    <input type="date" v-model="endDate" @keyup.enter="getReportingUserTimesheets" class="form-control">
                                </div>
                                <div class="col-1 my-auto text-center pl-1">
                                    <button type="submit" @click="getReportingUserTimesheets" class="sbtn btn btn-primary btn-xs px-2">Go</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg col-sm-2 pl-0 mb-1 ml-0 text-left">
                            <div class="row mb-0 text-center mt-2">
                                <!-- <template v-if="showDateRange"> -->
                                    <!-- <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changePage('prevPage',itemsPerPage);"><i class="fa fa-2x fa-angle-double-left action-link mt-0"></i></a>
                                    </div> -->
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" :class="{'disabled' : currentPage==1}" @click="changePage('prevPage',1);" title="Previous Page"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                    </div>
                                    <div class="col px-0">
                                        <h6 class="mt-0">
                                           Page {{ currentPage }}
                                        </h6>
                                        <!-- <h6 class="mt-1" v-if="currentStartDate">
                                            {{ months[currentStartDate.getMonth()].slice(0,3) }}
                                            <span v-if="currentStartDate.getFullYear() !== currentEndDate.getFullYear()">
                                                - {{ currentStartDate.getFullYear() }}
                                            </span>
                                            <span v-if="months[currentStartDate.getMonth()] !== months[currentEndDate.getMonth()]">
                                                / {{ months[currentEndDate.getMonth()].slice(0,3) }}
                                            </span>
                                            - {{ currentEndDate.getFullYear() }}
                                        </h6> -->
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" :class="{'disabled' : currentPage >= totalPages}" @click="changePage('nextPage',1);" title="Next Page"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                    </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6 pr-0">
                            <div class="row no-gutters align-items-center">
                                <div class="col float-left">
                                    <span @click="itemsPerPage = 1" :class="itemsPerPage == 1 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="Today">1</span>
                                    <span @click="itemsPerPage = 3" :class="itemsPerPage == 3 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="3 Days">3</span> 
                                    <span @click="itemsPerPage = 5" :class="itemsPerPage == 5 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer mr-1" title="5 Days">5</span>
                                    <span @click="itemsPerPage = 7" :class="itemsPerPage == 7 ? 'active-filter-icon' : 'badge-secondary'" class="badge badge-pill pointer" title="7 Days">7</span>
                                </div>
                                <!-- <div class="col-6">
                                    <div class="float-right">
                                        <select v-model="showDateRange" class="form-control top-header-btn" >
                                            <option :value="true" >By Day</option>
                                            <option :value="false">By Month</option>
                                        </select>
                                    </div>
                                </div> -->
                                <div v-if="breadcrumbItems.length > 0" class="col">
                                    <b-breadcrumb class="breadcrumb-default m-0">
                                        <b-breadcrumb-item href="javascript:" @click="changeBreadcrumbItem(userId, null)">
                                            {{ userName }}
                                        </b-breadcrumb-item>
                                        <b-breadcrumb-item v-for="item,index in breadcrumbItems" :key="index" :active="item.active" href="javascript:" @click="changeBreadcrumbItem(item.id, index)">
                                            {{ item.text }}
                                        </b-breadcrumb-item>
                                    </b-breadcrumb>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg col-sm-2 mt-2 mb-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right pr-1 mr-0" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a v-if="isTask" class="action-link" :title="'Switch to Time Board'" href="javascript:" @click="getTaskTimeList()"><i class="fa fa-mail-forward pr-2" aria-hidden="true"></i>Time Board</a>
                                        <a v-if="!isTask" class="action-link" :title="'Switch to Task Board'" href="javascript:" @click="getTimeTaskList()"><i class="fa fa-mail-forward pr-2" aria-hidden="true"></i>Task Board</a>
                                    </li>

                                    <li class="breadcrumb-item">
                                        <a class="action-link" href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item">
                                        <a href="javascript:" title="List View" class="action-link" @click="routeToMasterPage"><i class="fa fa-list px-1" aria-hidden="true"></i></a>
                                    </li> -->
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="mb-0 fs-14">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row px-1">
                    <div v-for="employeeData, index in employeesReportingList" class="col p-0 ml-2 mr-1">
                        <div class="card b-radius-5 pointer">
                            <div class="card-body p-2">
                                <div class="form-group mb-0">
                                    <label class="row align-items-center no-gutters mb-0 font-weight-semibold fs-14" for="title">
                                        <span class="col"> </span>
                                        <span class="col-auto text-center">
                                            <b-avatar v-if="employeeData.photo" class="mr-2" size="sm" :src="employeeData.photo"></b-avatar>
                                            <b-avatar v-else class="mr-2" size="sm"></b-avatar>
                                            {{ employeeData.value }}
                                        </span>
                                        <span class="col font-weight-semibold text-left fs-16 pointer ml-3" >
                                            <button v-if="employeeData.recordCount > 0" href="javascript:" class="btn sbtn btn-xs btn-outline-primary px-1 text-center font-weight-bold" title="Show Next Level Employees" @click.prevent="showBreadCrumb(employeeData)">{{employeeData.recordCount}}<i class="fa fa-arrow-down pl-1" aria-hidden="true"></i></button>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="action-item-vl mt-2">
                            <template v-if="timeSheetListByUser(employeeData) && timeSheetListByUser(employeeData).length == 0">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="action-card p-2 my-1 text-center">
                                            <span>No Records Found</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-for="(timeSheetData,tsDataIndex) in timeSheetListByUser(employeeData)">
                                <div v-if="showDateHeader(timeSheetData, tsDataIndex, timeSheetListByUser(employeeData))" class="row">
                                    <div class="col-12">
                                        <div class="action-card p-2 my-1">
                                            <div class="row no-gutters align-items-center">
                                                <div class="col pr-1">
                                                    <span class="font-weight-bold">{{ timeSheetData.startDate | formatDate }}</span>
                                                </div>
                                                <div v-if="summaryByDate(timeSheetListByUser(employeeData),timeSheetData.startDate)" class="col-auto text-right fs-11" style="line-height: 0;">
                                                    
                                                    <span class="font-weight-bold" :class="getEffortVariance(summaryByDate(timeSheetListByUser(employeeData),timeSheetData.startDate), timeSheetData.estWorkMins)" title="Actual Effort">AE: {{ convertToHM(summaryByDate(timeSheetListByUser(employeeData),timeSheetData.startDate)) }}</span>

                                                    <span v-if="isTask" class="font-weight-bold" title="Estimated Effort"> / {{convertToHM(timeSheetData.estEffortMins)}} </span>
                                                    <span v-if="!isTask" class="font-weight-bold" title="Estimated Effort"> / {{convertToHM(timeSheetData.estWorkMins)}} </span>                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-left mb-2">
                                        <div class="action-card my-0">
                                            <div class="action-card-body dropdown show p-0">
                                                <div class="pt-2 pb-1 px-2">
                                                    <span v-if="timeSheetData.status" class="badge badge-secondary pointer float-right" >
                                                        {{ timeSheetData.status }}
                                                    </span>                                                    
                                                    <div class="card-media-body-top">
                                                        <a href="javascript:" :title="timeSheetData.title" @click="viewItemDetails(timeSheetData)" class="fs-12 font-weight-bold pointer action-link">{{ timeSheetData.title }}</a>
                                                    </div>                                                    
                                                    <div class="divider-line"></div>
                                                    <div v-if="timeSheetData.description">
                                                        <div v-html="timeSheetData.description" class="card-media-body-top">
                                                        </div>
                                                        <div class="divider-line"></div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="row card-media-object-social-list">
                                                                <div class="col-12 p-0 text-left">
                                                                    <div v-if="timeSheetData.startDate">
                                                                        <span class="fs-12" :title="`Start Time | End Time | Actual Effort`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ timeSheetData.startDate | formatShortDateTime }} to {{ timeSheetData.endDate | formatShortDateTime }} </span>
                                                                        <span class="fs-12 float-right">
                                                                            {{convertToHM(timeSheetData.actEffortMins)}}
                                                                        </span>
                                                                    </div>
                                                                </div>

<!--                                                                 <div class="col-6 px-0 text-right" :class="{'col-sm-8' : itemsPerPage == 7 }">
                                                                    <div v-if="timeSheetData.endDate">
                                                                        <span class="fs-12" :title="`End Time: ${formatDate(timeSheetData.endDate)}`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ timeSheetData.endDate | formatDateTime }} </span>
                                                                    </div>
                                                                </div> -->
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <template #overlay>
                    <div class="text-center">
                        <LoadingSpinner />
                    </div>
                </template>
            </b-overlay>
        </div>
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" :attachmentData="attachmentData" :referenceDetailId="referencePopupId" @closeDialog="showDialog=false;" />
        <TimesheetDetailPopup v-if="showDialog" ref="timesheetDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import TimesheetDetailPopup from '@/components/popup/itemdetail/TimesheetDetailPopup.vue';
export default {
    components: {
        TaskDetailPopup,
        TimesheetDetailPopup,        
    },
    data() {
        return {

            showDialog: false,
            showOverlay: false,
            finTxnMemoryPopupKey: 0,

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userName: localStorage.getItem("userName"),

            selUserId: localStorage.getItem("userId"),

            currency: localStorage.getItem('currency'),
            entityRecordMasterId: '',

            isTask: false,
            showDateRange: true,

            currentPage: 1,
            itemsPerPage: 5,

            breadcrumbItems: [],

            startDate: this.getDateValue(new Date()),
            endDate: this.getDateValue(new Date()),
            selectedDate: new Date(),
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            startMonth: new Date(),
            endMonth: new Date(),
            currentDateList: [],

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            ermObject: {
                id:   '',
                value:   '',
            },

            ermMasterInfo: {
                title: "Financial Transaction Group",
                entity: 'financial_txn_master',
                refEntity: 'financial_transaction',
                tooltip: 'Financial Transaction Group',
                titleLabelName: 'Title',
            },

            financialTxn: {
                editMode: null,
                id: '',
                financialTransactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                entity: 'financial_transaction',
                accountHeadId: '',
                transactionTypeId: '',
                categoryId: '',
                title: '',
                description: '',
                amount: '',
                transactionDt: '',
                transactionById: '',
                accountablePersonId: '',
                statusId: '',
                activityId: '',
                entityId: '',
            }, 

            attachmentData: {},

            messageBoxData: {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'xs',
                okVariant: 'primary',
                cancelTitle: 'No',
                okTitle: 'Yes',
                headerClass: 'py-2 px-3',
                bodyClass: 'fs-14 px-3',
                footerClass: 'py-2 px-3',
                centered: true,
                hideHeaderClose: false
            },
            employeesReporting: [],
            employeeTaskTimeList: [],
            finTxnBoardSummary: [],
            ermLookup: [],

            detailViewData: {},
            referencePopupId: 0,

            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        // itemsPerPage: {
        //     get() {
        //         return this.$store.state.actionItemData.itemsPerPage;
        //     },
        //     set(value) {
        //         this.$store.state.actionItemData.itemsPerPage = value;
        //     }
        // },
        employeesReportingList() {
            // handle pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            const itemsToDisplay = this.employeesReporting.slice(startIndex, endIndex);

            return itemsToDisplay;
        },

        // selectedDate: {
        //     get() {
        //         return this.$store.state.actionItemData.selectedDate;
        //     },
        //     set(value) {
        //         this.$store.state.actionItemData.selectedDate = value;
        //     }
        // },
        totalPages() {
            return Math.ceil(this.employeesReporting.length / this.itemsPerPage);
        },
    },
    mounted() {
        this.lookupData();
        this.getReportingUserTimesheets();

        // To prevent default closing of calendar dropdown
        $('.dropdown-menu').on('click', function(e) {
            e.stopPropagation();
        });
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.selUserId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/emptasktime/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.employeesReporting = result.employeesReportingLookup;
                    console.log("Employees Reporting",result);
                })
                console.log("Data",data);
                
        },
        getTaskTimeList: function() {
            this.isTask = false;
            this.userId = localStorage.getItem("userId");
            this.employeesReporting = [];
            this.employeeTaskTimeList = [];
            // this.breadcrumbItems = [];

            this.lookupData();
            this.getReportingUserTimesheets();
            // this.employeesReportingList();
            // console.log(this.masterData);
        },
        getTimeTaskList: function() {

            this.isTask = true;
            this.userId = localStorage.getItem("userId");
            this.employeesReporting = [];
            this.employeeTaskTimeList = [];
            // this.breadcrumbItems = [];

            this.lookupData();
            this.getReportingUserTimesheets();
            // this.employeesReportingList();
            // console.log(this.masterData);
        },

        getReportingUserTimesheets: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.selUserId,
                startDate: this.startDate,
                endDate: this.endDate,
                isTask: this.isTask,
            }
            console.log("Data",data);
            this.showOverlay = true;
            if (this.isTask){
                axios.post(this.$store.getters.getAPIBasePath + '/task/emptasktime/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.employeeTaskTimeList = result.employeeTaskTimeList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    });

            }else{
                axios.post(this.$store.getters.getAPIBasePath + '/task/emptimetask/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.employeeTaskTimeList = result.employeeTimeTaskList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                    });

            }


        },
        showNextLevelReporting: function(userId) { 
            this.selUserId = userId; 
            this.lookupData();
            this.getReportingUserTimesheets();
        },
        showBreadCrumb(itemData) {
            this.showNextLevelReporting(itemData.userId);

            // change active as false for previous breadcrumbs
            this.breadcrumbItems.forEach(data => {
                data.active = false;
            })
            // add new breadcrumb
            this.breadcrumbItems.push({id: itemData.userId, text: itemData.value, active: true});
        },
        changeBreadcrumbItem(userId, index) {
            console.log(userId, index)
            const sliceIndex = index != null ? index+1 : 0;
            if (this.breadcrumbItems.length > 0 && this.selUserId != userId) {
                this.showNextLevelReporting(userId);
                this.breadcrumbItems = this.breadcrumbItems.slice(0, sliceIndex);
            }
            if (this.breadcrumbItems.length > 0) {
                this.breadcrumbItems[this.breadcrumbItems.length-1].active = true;
            }
        },
        changePage(page, number) {
            if (page === 'nextPage') {
                if (this.currentPage < this.totalPages) {
                    this.currentPage+=number;
                }
            }
            if (page === 'prevPage') {
                if (this.currentPage != 1) {
                    this.currentPage-=number;
                }
            }
        },
        changeMonthRange(page, number) {
            var startMonth = new Date(this.startMonth);
            var endMonth = new Date(this.endMonth);

            if (page === 'nextPage') {
                startMonth.setMonth(this.startMonth.getMonth() + number);
                endMonth.setMonth(this.endMonth.getMonth() + number);
            }
            if (page === 'prevPage') {
                startMonth.setMonth(this.startMonth.getMonth() - number);
                endMonth.setMonth(this.endMonth.getMonth() - number);
            }
            this.startMonth = startMonth;
            this.endMonth = endMonth;

            this.currentDateList = this.getDaysArray(this.startMonth, this.endMonth);
        },
        getDaysArray: function(s, e) {
            // if (this.showDateRange) {
                for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
                    a.push(new Date(d));
                }
            // }
            // else if (this.showDateRange == false) {
            //     for (var a = [], d = new Date(s); d <= e; d.setMonth(d.getMonth() + 1)) {
            //         a.push(new Date(d));
            //     }
            // }
            return a;
        },
        // timeSheetByReportingUser(data) {
            // if (this.showDateRange) {
                // return this.employeeTaskTimeList.filter(item=> item.preparedById == data.preparedById);
            // }
            // else {
            //     return this.employeeTaskTimeList.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
            // }
        // },
        timeSheetListByUser(data) {
            return this.employeeTaskTimeList.filter(item => item.preparedById == data.userId);
        },

        // timeSheetListByUserDate(data) {
        //     return this.employeeTaskTimeList.filter(item => item.preparedById == data.userId && 
        //         item.preparedById == data.preparedById);
        // },
        summaryByDate(array, date) {

            const filteredArray = array.filter(item => this.getDateValue(item.startDate) == this.getDateValue(date));

            const summary = filteredArray.reduce((accum, item) => {
                return accum + (item.actEffortMins ? parseFloat(item.actEffortMins) : 0) 
            }, 0) 
            return summary ?? null;
        },

        getEffortVariance(actEffort,estEffort){
            return actEffort < estEffort ? 'text-danger' : '';
        },
        // summaryByDate(array, data) {
        //     let summary = array.reduce((accum, item) => {
        //         return accum + (item.estEffortMins ? parseFloat(item.estEffortMins) : 0) 
        //     }, 0) && item.startDate == data;
        //     // }
        //     // else {
        //     //     let filteredArray = this.finTxnBoardSummary.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
        //     //     summary = filteredArray.reduce((accum, item) => {
        //     //         return accum + (item[amount] ? parseFloat(item[amount]) : 0)
        //     //     }, 0.00)
        //     // }
        //     return summary ?? null;
        // },
        cumulativeProfitByDate(date) {
            let summary;
            // if (this.showDateRange) {
                let filteredArray = this.finTxnBoardSummary.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
                let sortedArray = filteredArray.sort(function(a,b) {
                    return new Date(b.transactionDt) - new Date(a.transactionDt);
                });
                summary = sortedArray.length>0 ? sortedArray[0] : null;
            // }
            // else {
            //     let filteredArray = this.finTxnBoardSummary.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(date).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(date).getMonth());
            //     let sortedArray = filteredArray.sort(function(a,b) {
            //         return new Date(b.transactionDt) - new Date(a.transactionDt);
            //     });
            //     summary = sortedArray.length>0 ? sortedArray[0] : null;
            // }
            return summary ? summary : null;
        },
        getCreditSum(array) {
            array = array.filter(arr=> {
                if (arr.amountTypeCode == '1') return arr
            });
            if (array && array.length > 0) {
                return array.reduce((acc,item)=> {
                    return acc + item.amount;
                }, 0);
            } else return 0;
        },
        getDebitSum(array) {
            array = array.filter(arr=> {
                if (arr.amountTypeCode == '2') return arr;
            });
            if (array && array.length > 0) {
                return array.reduce((acc,item)=> {
                    return acc + item.amount;
                }, 0);
            } else return 0;
        },
        getTextVariant(value) {
            if (value && Math.sign(value) == -1) {
                return 'text-danger';
            } else return 'text-success';
        },
        showDateHeader(data, index, items) {
            return index == 0 || (items[index-1]?.startDate && this.getDateValue(data.startDate) != this.getDateValue(items[index-1].startDate));
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = 'time_sheet';
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            if(this.isTask){
                this.$nextTick(()=> {
                    this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
                })

            }else{
                this.$nextTick(()=> {
                    this.$refs.timesheetDetailPopupRef.getDetails(this.referencePopupId, null);
                })

            }
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }

        // routeToMasterPage() {
        //     let routePath = this.$route.path;
        //     console.log(routePath)
        //     if (routePath.includes('landing')) {
        //         this.$router.push('/landing/ftxmas/list');
        //     } else {
        //         this.$router.push('/ftxmas/list');
        //     }
        // },
    }
}
</script>