<template>
    <div class="type">
        <b-modal ref="vmxRegistrationModal" size="md" :id="id" centered modal-class="typeModal" title="VMX Registration" @hide="closeDialog" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="form-row">
                    <div class="col-12">
                        <b-card no-body class="mb-3">
                            <form autocomplete="off">
                                <div class="card-body p-1">
                                    <div class="row mb-2">
                                        <div class="col-12">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="accountNameReg">Account Name</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.vhextAccount.accountName.$error }">
                                                    <input type="text" class="form-control" v-model="vhextAccount.accountName" name="username" id="accountNameReg" autocomplete="false" :class="{ 'is-invalid': $v.vhextAccount.accountName.$error }" placeholder="Account Name">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.vhextAccount.accountName.$error" class="invalid-feedback">
                                                    <span v-if="!$v.vhextAccount.accountName.required">Account Name is required</span>
                                                    <span v-if="!$v.vhextAccount.accountName.minLength">Account Name must have at least {{$v.vhextAccount.accountName.$params.minLength.min}} letters.</span>
                                                    <span v-if="!$v.vhextAccount.accountName.maxLength">Account Name must not exceed {{$v.vhextAccount.accountName.$params.maxLength.max}} letters.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="emailIdReg">Email Id</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.vhextAccount.emailId.$error }">
                                                    <input type="email" v-model="vhextAccount.emailId" name="email" autocomplete="false" id="emailIdReg" class="form-control" placeholder="Email Address">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.vhextAccount.emailId.$error" class="invalid-feedback">
                                                    <span v-if="!$v.vhextAccount.emailId.required" class="text-danger">EmailId is required</span>
                                                    <span v-if=" !$v.vhextAccount.emailId.email">Email is not valid</span>
                                                </div>
                                                <label v-if="error" class="text-danger">EmailId already exists.</label>
                                            </div>
                                        </div>
                                        <!--  <div class="col-6 pr-1">
                                            <div class="form-group mb-2">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.vhextAccount.password.$error, 'is-valid': !$v.vhextAccount.password.$invalid }">
                                                    <input type="password" v-model="vhextAccount.password" class="form-control" ref="password" placeholder="Password">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.vhextAccount.password.$error" class="invalid-feedback">
                                                    <span v-if="!$v.vhextAccount.password.required">Password is required</span>
                                                    <span v-if="!$v.vhextAccount.password.minLength">Password must be at least 6 characters</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-1">
                                            <div class="form-group mb-2">
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.vhextAccount.confirmationPassword.$error }">
                                                    <input type="password" v-model="vhextAccount.confirmationPassword" class="form-control" placeholder="Confirm Password">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.vhextAccount.confirmationPassword.$error" class="invalid-feedback">
                                                    <span v-if="!$v.vhextAccount.confirmationPassword.required">Confirm Password is required</span>
                                                    <span v-else-if="!$v.vhextAccount.confirmationPassword.sameAsPassword">Passwords must match</span>
                                                </div>
                                            </div>
                                        </div> -->
                                        <div class="col-6 pr-1">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="vhextAccountTypeIdReg">Account Type</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.vhextAccount.vhextAccountTypeId.$error }">
                                                    <select v-model="vhextAccount.vhextAccountTypeId" @change="getVhextUserTypes(vhextAccount.vhextAccountTypeId)" class="form-control" id="vhextAccountTypeIdReg" autocomplete="false">
                                                        <option value="">Select Account Type</option>
                                                        <option v-for="(info, index) in accountTypes" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.vhextAccount.vhextAccountTypeId.$error" class="invalid-feedback">
                                                    <span v-if="!$v.vhextAccount.vhextAccountTypeId.required">Account Type is required</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-1">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="phoneReg">Phone</label>
                                                <input type="text" v-model="vhextAccount.phone" class="form-control" name="mobile" autocomplete="false" placeholder="Phone number" id="phoneReg">
                                            </div>
                                        </div>
                                        <div class="col-6 pr-1">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="companyReg">Company Name</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.vhextAccount.company.$error }">                                                
                                                    <input type="text" v-model="vhextAccount.company" name="organization" autocomplete="false" class="form-control" :class="{ 'is-invalid': $v.vhextAccount.company.$error }" placeholder="Company Name" id="companyReg">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <div v-if="$v.vhextAccount.company.$error" class="invalid-feedback">
                                                    <span v-if="!$v.vhextAccount.company.required">Company Name is required</span>
                                                    <span v-if="!$v.vhextAccount.company.minLength">Company Name must have at least {{$v.vhextAccount.company.$params.minLength.min}} letters.</span>
                                                    <span v-if="!$v.vhextAccount.company.maxLength">Company Name must not exceed {{$v.vhextAccount.company.$params.maxLength.max}} letters.</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-1">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="jobTitle">Job Title</label>
                                                <input type="text" v-model="vhextAccount.jobTitle" class="form-control" name="organization-title" autocomplete="false" placeholder="Job Title" id="jobTitle">
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group mb-2">
                                                <label class="sr-only" for="countryId">Country</label>
                                                <select v-model="vhextAccount.countryId" class="form-control" id="countryId" autocomplete="false">
                                                    <option value="">Select Country</option>
                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="vhextAccount.vhextAccountTypeId" class="col-12">
                                            <div class="form-group mb-2">
                                                <multiselect v-model="vhextAccount.userTypeList" :options="userTypeLookup" name="activity" label="userType" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                            <div class="form-group mb-2">
                                                <textarea v-model="vhextAccount.activityId" class="form-control" rows="3" placeholder="CAPTCHA"></textarea>
                                            </div>
                                        </div> -->
                                    <div class="form-group mb-2">
                                        <b-form-checkbox id="checkbox-1" v-model="vhextAccount.terms">
                                            I accept the <a href="#" class="action-link">Terms of Service</a>
                                        </b-form-checkbox>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" @click.prevent="saveAccount" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Submit</button>
                                        <button type="button" @click.prevent="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                    </div>
                                </div>
                             </form>
                        </b-card>
                    </div>
                    <!-- <div v-if="isExtendedModal" class="col-6">
                        <template v-for="userType,index in (vhextAccount.userTypeList)">
                            <div class="row">
                                <div class="col">
                                    <label class="my-1">{{ userType.userType }}</label>
                                </div>
                                <div class="col">
                                    <button type="submit" @click.prevent="showAddModal(userType, index)" class="btn btn-primary py-1 px-3 float-right"><i class="fa fa-plus-circle pr-2"></i>Add Module</button>
                                </div>
                            </div>
                            <b-table v-if="userType.moduleList" show-empty small outlined stacked="md" sort-icon-left :items="userType.moduleList" :fields="moduleFields" responsive="sm" >
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Remove" @click="removeModule(index, data.item.id)" ><i class="fa fa-minus-circle text-danger"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">Add Service Modules to show</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </template>
                    </div> -->
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
export default {
    props: {
        id: { type: String, default: 'vmxRegistrationModal' },
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            elements: {
                showRegister: false,
            },
            showOverlay: false,
            disableLogin: false,
            error: false,
            userId: localStorage.getItem("userId"),
            entity: 'vhext_account',

            selectedModuleIndex: 0,
            accountTypes: [],
            countries: [],
            userTypeLookup: [],
            vhextAccount: {
                accountName: '',
                vhextAccountTypeId: '',
                emailId: '',
                phone: '',
                password: '',
                confirmationPassword: '',
                terms: false,
                company: '',
                jobTitle: '',
                countryId: '',
                userTypeList: [],
                isMobileActivation: 0,
                isParentCompany: true,
                createdById: localStorage.getItem("userId"),
            },
            login: {
                loginName: '',
                password: '',
                key: this.$route.params.key,
                userType: this.$route.params.userType
            },
            userTypeModules: [],
            moduleList: [],
            moduleFields: [
                { key: 'index', label: 'SN' },
                { key: 'module', label: 'Module' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    computed: {
        filteredUserTypeModules() {
            const userTypeData = this.vhextAccount.userTypeList[this.selectedModuleIndex];
            const moduleList = userTypeData ? this.vhextAccount.userTypeList[this.selectedModuleIndex].moduleList : [];
            if (moduleList && moduleList.length > 0) {
                return this.userTypeModules.filter(data => {
                  const moduleIdExists = moduleList.some(mod => mod.id === data.moduleId);
                  return !moduleIdExists;  // Include data if moduleId doesn't exist in moduleList
                });
            }
            else {
                return this.userTypeModules;
            }
        }
    },
    mounted() {
        this.lookupData();
        console.log(localStorage.getItem("pageRedirectURL"));
    },
    validations: {
        vhextAccount: {
            accountName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            company: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(100)
            },
            emailId: {
                required,
                email
            },
            /*  password: {
                  required,
                  minLength: minLength(6)
              },
              confirmationPassword: {
                  required,
                  sameAsPassword: sameAs('password')
              },*/
            // terms: {
            //     checked(val) {
            //         return val
            //     }
            //     //sameAs: sameAs( () => true )
            // },
            vhextAccountTypeId: {
                required,
            }
        }
    },
    methods: {
        // performLogin() {
        //     var container = $("#app-container");
        //     var body_container = $("body");
        //     var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile";
        //     container.removeClass(allMenuClassNames);
        //     body_container.removeClass('open-note');
        //     this.disableLogin = true;
        //     axios.post(this.$store.getters.getAPIBasePath + '/user/authenticate', this.login)
        //         .then(response => {
        //             let result = response.data;
        //             if (result.result) {
        //                 const defaultredirects = this.$store.getters.getDefaultRedirects;
        //                 this.$store.dispatch("setupLogin", result).then(() => {

        //                     if(result.data.defaultProjectId > 0){
        //                         this.getDefaultProject();
        //                     }
        //                         else{
        //                         if (result.data.userType == "VHO") {
        //                             this.$router.push({ path: defaultredirects.vsa });
        //                         } else if (result.data.userType == "CAM") {
        //                             this.$router.push({ path: defaultredirects.cmr });
        //                         } else if (result.data.userType == "PRM") {
        //                             this.$router.push({ path: defaultredirects.prm });
        //                         } else if (result.data.userType == "CLI") {
        //                             this.$router.push({ path: defaultredirects.cli });
        //                         } else if (result.data.userType == "EMP") {
        //                             this.$router.push({ path: defaultredirects.emp });                                    
        //                         } else if (result.data.userType == "TMB") {
        //                             this.$router.push({ path: defaultredirects.tmb });
        //                         } else if (result.data.userType == "VSA") {
        //                             this.$router.push({ path: defaultredirects.vsa });
        //                         } else if (result.data.userType == "SHL") {
        //                             this.$router.push({ path: defaultredirects.shl });
        //                         } else if (result.data.userType == "MRP") {
        //                             this.$router.push({ path: defaultredirects.mrp });
        //                         } else if (result.data.userType == "HRM") {
        //                             this.$router.push({ path: defaultredirects.hrm });
        //                         } else if (result.data.userType == "FNM") {
        //                             this.$router.push({ path: defaultredirects.fnm });
        //                         } else {
        //                             this.$router.push({ path: defaultredirects.general });
        //                         }
        //                     }
        //                 });
        //                 this.disableLogin = false;
        //             } else {
        //                 this.disableLogin = false;
        //                 iziToast.info({
        //                     message: result.message,
        //                     position: 'topRight'
        //                 });
        //             }
        //         })
        //         .catch(error => {
        //             this.disableLogin = false;
        //             iziToast.error({
        //                 title: 'Error Occurred',
        //                 message: 'Please try again',
        //                 position: 'topRight'
        //             });
        //         });
        // },
        lookupData: function() {
            let data = {
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.accountTypes = result.vhextAccountTypeLookup;
                    // this.accountTypes = this.filterAccountType("Individual", "Company");
                    this.countries = result.countryLookup;
                })

        },
        getVhextUserTypes: function(id) {
            let data = {
                vhextAccountTypeId: id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/vmxaccountusertype/list', data)
                .then((response) => {
                    let result = response.data;
                    this.userTypeLookup = result.vmxAccountUserTypeLookup.map(data=> {
                        return {...data, moduleList: [] };
                    });
                    this.vhextAccount.userTypeList = this.userTypeLookup.filter(data => data.isPrimary);
                })

        },
        getUserTypeModules: function(userTypeId) {
            let data = {
                userTypeId: userTypeId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/stdusermodule/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.userTypeModules = result.stdUserModuleList;
                })
                .catch(error => {
                    this.showOverlay = false;
                })
        },
        saveAccount: function() {
            console.log(this.vhextAccount);
            this.$v.vhextAccount.$touch();
            if (!this.$v.vhextAccount.$invalid) {
                if (!this.vhextAccount.terms) {
                    iziToast.info({
                        title: 'Please accept the Terms',
                        position: 'topRight'
                    });
                    return;
                }
                this.showOverlay = true;
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/add', this.vhextAccount)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Check Email',
                                message: `Activation mail has been sent to "${this.vhextAccount.emailId}"`,
                                drag: true,
                                timeout: 8000,
                                icon: 'fa fa-envelope',
                                position: 'center',
                                titleSize: '15px',
                                backgroundColor: 'white',
                                progressBar: false,
                                titleLineHeight: '40px',
                                messageSize: '15px',
                                messageLineHeight: '40px',
                            });
                            this.clearData();
                            this.$emit('refreshData');
                            this.$bvModal.hide(this.id);
                            this.elements.showRegister = false;
                        } else if (result.status == 'OK') {
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        getDefaultProject: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                userId: localStorage.getItem("userId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/default/view', data)
                .then((response) => {
                    let result = response.data;

                    if (result) {
                        this.defaultProject = result.defaultProject;

                            this.setSessionProjectId(this.defaultProject.id,this.defaultProject.projectId,this.defaultProject.projectTypeId,this.defaultProject.displayName,this.defaultProject.currencySymbol,this.defaultProject.isStandard)

                    }
                }).catch(error => {
                    console.log(error);
                });
        },

        clearData: function() {
            this.elements.showRegister = false;
            this.error = false;
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.emailId = '';
            this.vhextAccount.phone = '';
            this.vhextAccount.password = '';
            this.vhextAccount.terms = false;
            this.vhextAccount.confirmationPassword = '';
            this.vhextAccount.company = '';
            this.vhextAccount.title = '';
            this.vhextAccount.countryId = '';
            this.vhextAccount.userTypeList = [];
            this.$v.vhextAccount.$reset();
        },
        setSessionProjectId: function(projectId, projId, projectTypeId, projectName, currency, isStandard) {
            localStorage.setItem('projectId', projectId);
            localStorage.setItem('projId', projId);
            localStorage.setItem('projectTypeId', projectTypeId);
            localStorage.setItem('projectName', projectName);
            localStorage.setItem('currency', currency);
            localStorage.setItem('isStandard', isStandard);
            if (this.login.userType == 'CLI'){
                this.$router.push('/client/docshr/list');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
            else {
                this.$router.push('/actionitem');
                var container = $("#app-container");
                var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile open-note";
                container.removeClass(allMenuClassNames);
            }
        },
        showAddModal(userTypeData, index) {
            this.selectedModuleIndex = index;
            this.getUserTypeModules(userTypeData.id);
            this.$bvModal.show('addModuleModal');
        },
        addModule(data) {
            const moduleData = { id: data.moduleId, module: data.module };
            const moduleList = this.vhextAccount.userTypeList[this.selectedModuleIndex].moduleList;
            moduleList.push(moduleData);
        },
        removeModule(selectedIndex, moduleId) {
            let moduleList = this.vhextAccount.userTypeList[selectedIndex].moduleList;
            this.vhextAccount.userTypeList[selectedIndex].moduleList = moduleList.filter(data => data.id != moduleId);
        },
        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        },
        closeDialog() {
            this.$emit('closeDialog');
        },

    }
}
</script>