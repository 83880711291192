<template>
    <div id="app" >
        <div class="container-fluid mt-80">
            <div class="row-inner-header">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4 p-3">
                        <div class="row pl-2">
                            <div class="col-3">
                                <p class="chart-title text-left">Project Timeline</p>
                            </div>
                            <div class="col-9 text-right">
                                <label class="mr-2">Choose Scale</label>
                                <template>
                                    <b-avatar text="H" rounded="sm" title="Hour Scale" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('h')"></b-avatar>
                                    <b-avatar text="D" rounded="sm" title="Day Scale" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('d')"></b-avatar>
                                    <b-avatar text="W" rounded="sm" title="Week Scale" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('w')"></b-avatar>
                                    <b-avatar text="M" rounded="sm" title="Month Scale" variant="primary" class="align-baseline mr-1" button @click="setGanttChartScale('m')"></b-avatar>
                                </template>
                                
                            </div>
                        </div>
                        <gantt v-if="elements.showChart" class="gantt" :tasks="tasks" :ganttChartScales="ganttChartScales" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask" :key="ganttChartKey"></gantt>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--  <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left">Time management</p>
                        <div class="card-body d-flex p-0">
                            <AreaChart class="d-block w-100-h-350" />
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left">Count management</p>
                        <div class="card-body d-flex p-0">
                            <LineChart class="d-block w-100-h-350" />
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left pl-1 mb-1">Client Requirement</p>
                        <div class="separator mb-1"></div>
                        <div class="card-body py-1">
                            <PieChart v-if="elements.showChart" :chartdata="userReqChartData" :options="chartOptions" class="d-block w-100-h-350" :key="pieChartKey"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left pl-1 mb-1">Budget</p>
                        <div class="separator mb-1"></div>
                        <div class="card-body py-1">
                            <BarChart v-if="elements.showChart" :chartdata="barChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                        </div>
                    </div>
                </div>
                <!--  <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left">Scope</p>
                        <div class="card-body d-flex p-0">
                            <PieChart v-if="elements.showChart" :chartdata="scopeChartData" :options="chartOptions" class="d-block w-100-h-350" />
                        </div>
                    </div>
                </div> -->
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left pl-1 mb-1">Requirement Specification</p>
                        <div class="separator mb-1"></div>
                        <div class="card-body py-1">
                            <div v-if="!elements.noReqSpecData">
                                <BarChart v-if="elements.showChart" :chartdata="reqSpecChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                            </div>
                            <div v-if="elements.noReqSpecData" class="align-middle">No Data</div>

                            <!-- <BarChart v-if="elements.showChart" :chartdata="barChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left pl-1 mb-1">Issues</p>
                        <div class="separator mb-1"></div>
                        <div class="card-body py-1">
                            <div v-if="!elements.noIssueData">
                                <BarChart v-if="elements.showChart" :chartdata="issueChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/>
                            </div>
                            <div v-if="elements.noIssueData" class="align-middle">No Data</div>

                            <!-- <BarChart v-if="elements.showChart" :chartdata="barChartData" :options="chartOptions" class="d-block w-100-h-350" :key="barChartKey"/> -->
                        </div>
                    </div>
                </div>

<!--                 <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4 p-3">
                        <p class="chart-title text-left pl-1 mb-1">Requirement Specification</p>
                        <div class="separator mb-1"></div>
                        <div class="card-body py-1">
                            <div v-if="!elements.noReqSpecData">
                                <PieChart v-if="elements.showChart" :chartdata="reqSpecChartData" :options="chartOptions" class="d-block w-100-h-350" />
                            </div>
                            <div v-if="elements.noReqSpecData" class="align-middle">No Data</div>
                        </div>
                    </div>
                </div> -->
            </div>
<!--              <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <gantt class="gantt" :tasks="tasks" @task-updated="logTaskUpdate" @link-updated="logLinkUpdate" @task-selected="selectTask"></gantt>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import AreaChart from "@/components/blocks/charts/AreaChart.vue";
import BarChart from "@/components/blocks/charts/BarChart.vue";
import Gantt from "@/components/blocks/charts/GanttChart.vue"
import LineChart from "@/components/blocks/charts/LineChart.vue";
import PieChart from "@/components/blocks/charts/PieChart.vue";

export default {
    name: "App",
    components: {
        AreaChart,
        PieChart,
        BarChart,
        LineChart,
        Gantt
    },
    data() {
        return {
            elements: {
                showChart: false,
                noReqSpecData: false,
                noIssueData: false,
                noScopeData: false,
                noUserReqData: false
            },


            ganttChartKey: 0,
            barChartKey: 0,
            pieChartKey: 0,

            ganttChartScales:'day',

            barChartData: [],
            userReqChartData: '',
            scopeChartData: '',
            reqSpecChartData: '',
            issueChartData: '',
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
/*                scales: {
                    xAxes: [{
                        stacked: false,
                        categoryPercentage: 0.5,
                        barPercentage: 1
                    }],
                    yAxes: [{
                        stacked: false
                    }]
                }
*/            },
            tasks: {
                data: [],
                links: []
            },
            selectedTask: null,
            messages: []
        };
    },
    mounted() {
        this.setGanttChartScale('h');
    },
    created() {
        this.getDashboardData();
    },
    methods: {

        getDashboardData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/dashboard/project/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);

                    this.ganttChartKey += 1;                        
                    this.barChartKey += 1;
                    this.pieChartKey += 1;

                    if (result.budgetByCategoryList != null) {
                        this.barChartData = {
                            labels: result.budgetByCategoryList.map(item => item.category),
                            datasets: [{
                                    label: 'Estimated Amount',
                                    backgroundColor: '#2a93d5',
                                    data: result.budgetByCategoryList.map(item => item.estimatedCost)
                                },
                                {
                                    label: 'Actual Amount',
                                    backgroundColor: '#8e9599',
                                    data: result.budgetByCategoryList.map(item => item.actualAmount)

                                }
                            ]
                        }

                    }

                    this.userReqChartData = {
                        labels: result.userRequirementList.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.userRequirementList.map(item => item.userReqCount)
                        }]
                    }

                    this.scopeChartData = {
                        labels: result.userRequirementList.map(item => item.status),
                        datasets: [{
                            backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                            data: result.userRequirementList.map(item => item.userReqCount)
                        }]
                    }
                    if (result.requirementSpecList != null && result.requirementSpecList.length != 0) {
                        this.elements.noReqSpecData = false;
                        this.reqSpecChartData = {
                            labels: result.requirementSpecList.map(item => item.status),
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                                data: result.requirementSpecList.map(item => item.reqSpecCount)
                            }]
                        }
                    } else {
                        this.elements.noReqSpecData = true;
                    }

                    if (result.issuesByStatus != null && result.issuesByStatus.length != 0) {
                        this.elements.noIssueData = false;
                        this.issueChartData = {
                            labels: result.issuesByStatus.map(item => item.status),
                            datasets: [{
                                backgroundColor: ['#2a93d5', '#8e9599', '#47799a'],
                                data: result.issuesByStatus.map(item => item.issueCount)
                            }]
                        }
                    } else {
                        this.elements.noIssueData = true;
                    }

                    if (result.projectTimelineList != null) {
                        this.tasks = {
                            data: result.projectTimelineList,
                            links: []
                        }
                    }
                    console.log("Tasks",result.projectTimelineList);
                    this.elements.showChart = true;
                })
        },
        selectTask(task) {
            console.log(task);
            this.selectedTask = task;
        },

        addMessage(message) {
            this.messages.unshift(message)
            if (this.messages.length > 40) {
                this.messages.pop()
            }
        },
        setGanttChartScale(scale) {
            if (scale == 'h') {
                // alert(scale);
                this.ganttChartScales = 'hour';
                this.ganttChartKey += 1;                        
            }
            else if (scale == 'd') {
                // alert(scale);
                this.ganttChartScales = 'day';
                this.ganttChartKey += 1;                        
            }
            else if (scale == 'w') {
                this.ganttChartScales = 'week';
                this.ganttChartKey += 1;                        
                // alert(scale);
            }
            else if (scale == 'm') {
                this.ganttChartScales = 'month';
                this.ganttChartKey += 1;                        
                // alert(scale);
            }

        },

        logTaskUpdate(id, mode, task) {
            let text = (task && task.text ? ` (${task.text})` : '')
            let message = `Task ${mode}: ${id} ${text}`
            this.addMessage(message)
        },

        logLinkUpdate(id, mode, link) {
            let message = `Link ${mode}: ${id}`
            if (link) {
                message += ` ( source: ${link.source}, target: ${link.target} )`
            }
            this.addMessage(message)
        }
    }
};
</script>
<style>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 60px;
}


/*Gantt Chart Styles*/

.gantt .gantt_layout_cell {
    min-height: 150px !important;
}

/*Gantt Chart Styles*/
</style>