<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row task-top-menu">
                        <div class="col-lg-2 col-sm-2">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-1 col-sm-1 mt-1 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16"><a href="javascript:" @click="$router.push('/taskganttchart');">Task Gantt</a></h4>
                        </div>
                        <div class="col-lg-3 col-sm-3 mb-1 ml-0 text-left">
                            <div class="row">
                                <label class="col-3 mt-2" for="requirement">Requirement</label>
                                <div class="col-7">
                                        <select v-if="!elements.viewMode" v-model="requirementFilter" @change="requirementTaskFilter(requirementFilter.id)" class="form-control" id="requirementId">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in filteredRequirements" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                        </select>
                                </div>
                                <div class="col-1">
                                    <b-button v-if="elements.filterMode" class="btn btn-xs px-2 mx-0" variant="outline-success" @click="clearFilter" size="sm">All</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-2">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">WBS</span></h4>
                        </div>

                        <!-- <div class="col-lg-3 col-sm-3 mb-1 ml-0 text-left">
                            <div v-for="user in users" @click="teamMemberFilter(user.id);" class="my-0 py-0 mr-3 text-right user-icon" id="taskFilter">
                                <span class="pointer mr-4 align-self-center user-name-icon" :title="user.value" variant="warning">{{ user.value | getAcronym }}</span>
                            </div>
                            <b-button v-if="elements.filterMode" class="btn btn-xs text-right px-2 mx-3" variant="outline-success" @click="clearFilter" size="sm">All</b-button>
                        </div> -->
                        <div class="col-lg-3 col-sm-3 mb-1">
                            <b-button class="addnew-task-btn float-right ml-5" variant="outline-info" @click="elements.viewOnlyComponet=false" size="sm"><i class="fa fa-plus"></i> Add New Task</b-button>
                        </div>
                        <div class="col-lg-3 col-sm-3 mt-1">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12 col-xl-12 position-relative">
                    <div class="row mr-0">
                        <div v-for="status in taskStatus" class="col-12 col-md-3 mb-1 pr-1">
                            <div class="card mb-2 b-radius-5 ">
                                <div class="card-body px-3 py-2">
                                    <div class="form-group mb-0">
                                        <label class="mt-1 mb-0 font-weight-bold" for="title">{{ status.value }}</label>
                                        <b-button class="pull-right addnew-quicktask-btn" @click="task.statusId = status.id;task.estimatedStartDt = new Date().toISOString().substr(0, 10);task.estimatedEndDt = new Date().toISOString().substr(0, 10);" variant="outline-info" size="sm" v-b-modal.Quick-Task-Modal><i class="fa fa-plus"></i> Quick Task</b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="taskLists">
                                <div v-for="task in tasks">
                                    <div v-if="task.status == status.value" class="card-media mb-2">
                                        <div class="card-media-body dropdown show">
                                            <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fa fa-ellipsis-v p-1"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                <a class="dropdown-item addnew-task pb-1 py-1" @click="showView(task);elements.editMode = false;" href="#"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Details</a>
                                                <a v-if="task.assignedById == userId" class="dropdown-item addnew-task py-1" @click="showEdit(task);elements.taskStatusUpdate = true;" href="#"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                <a class="dropdown-item addnew-task pb-1 py-1" @click="duplicateTask(task)" href="#"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a>
                                                <a v-if="task.assignedById == userId" class="dropdown-item py-1" @click="deleteTask(task.id)" data-toggle="modal" data-target="#deleteTask" href="#"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-task my-0 py-1" @click="showView(task);elements.taskStatusUpdate = true;elements.editMode=true" href="#"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-task my-0 py-1" @click="showView(task);elements.taskStatusUpdate = true;elements.editMode = true;elements.visibileTimeSheet=true;" href="#showTimeSheet"><i class="fa fa-history pr-2 mt-1 mb-0" aria-hidden="true"></i>Time Sheet</a>
                                            </div>
                                            <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1">{{ task.status }}</span>
                                            <div class="card-media-body-top">
                                                <span class=""><a href="#" @click="viewItemDetails(task)">{{ task.title }}</a></span>
                                            </div>
                                            <div class="divider-line"></div>
                                            <div class="container-fluid">
                                                <div v-if="(task.activity != null) || (task.priority != null)">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <span class="subtle">{{ task.activity }}</span>
                                                        </div>
                                                        <div class="col-6">
                                                            <span class="subtle">{{ task.priority }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div v-if="(task.estimatedStartDt != null) || (task.estimatedEndDt != null)">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <span class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeAmPm }}</span>
                                                        </div>
                                                        <div class="col-6">
                                                            <span class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeAmPm }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row card-media-object-social-list">
                                                            <div class="col-6 p-0">
                                                                <div v-if="(task.assignedBy != null)">
                                                                    <b-badge class="task-badge" title="Assigned By"> {{ task.assignedBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 p-0 text-right">
                                                                <div v-if="(task.assignedTo != null)">
                                                                    <b-badge class="task-badge" title="Assigned To"> {{ task.assignedTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!getTaskByStatusId(status.id).length" class="card-media mb-2">
                                    <div class="card-media-body">
                                        <div class="stage-card text-center">No Task Found</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav class="app-menu-nav task-nav-menu left-side">
                        <div class="task-menu-title">
                            <label class="d-block pt-2"><i class="fa fa-home px-1"></i>Task Plan<span><a href="#" class="float-right close-comments" id="close-task-menu"><i class="fa fa-times " aria-hidden="true"></i></a></span></label>
                        </div>
                        <ul v-for="entityRecordMaster in entityRecordMasters" :class="{ 'task-active-tab': (entityRecordMaster.id == task.entityRecordMasterId) }" class="task-menu">
                            <li>
                            <a href="#" class="task task-nav-submenu" @click="task.entityRecordMasterId = entityRecordMaster.id;getTasks(entityRecordMaster.id)">{{ entityRecordMaster.title }}</a>
                            </li>
                        </ul>
                    </nav>
                    <div class="row addnew-task-details hide position-absolute w-100 t-0">
                        <div class="col-12 col-md-12 mb-1 pr-3">
                            <div class="card mb-2 b-radius-5 p-2">
                                <div class="">
                                    <a href="#" class="addnew-task-close float-right" @click="clearData"><i class="fa fa-times"></i></a>
                                </div>
                                <div class="card-body p-3">
                                    <div class="form-group mb-0">
                                        <div v-if="!elements.viewMode">
                                            <div class="row">
                                                <div class="col-12 col-md-3">
                                                    <div class="form-group">
                                                        <label for="title1">Title</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.task.title.$error }">
                                                            <b-form-input id="title1" v-model="task.title" placeholder="Enter Task Title"></b-form-input>
                                                            <div class="required-icon"></div>
                                                        </div>
                                                        <label v-if="$v.task.title.$error && !$v.task.title.minLength" class="text-danger">Task Title must have at least {{$v.task.title.$params.minLength.min}} letters.</label>
                                                        <label v-if="$v.task.title.$error && !$v.task.title.required" class="text-danger">Please Enter Task Title</label>
                                                        <label v-if="$v.task.title.$error && !$v.task.title.maxLength" class="text-danger">Task Title must not exceed {{$v.task.title.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3">
                                                    <div class="form-group">
                                                        <label for="taskTypeId1">Type</label>
                                                        <select v-model="task.taskTypeId" class="form-control" id="taskTypeId1">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in taskTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-2">
                                                    <div class="form-group">
                                                        <label for="activityId1">Activity</label>
                                                        <select v-model="task.activityId" class="form-control" id="activityId1">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in taskActivity" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-2">
                                                    <div class="form-group">
                                                        <label for="requirement">Requirement</label>
                                                        <select v-if="!elements.viewMode" v-model="task.requirementId" class="form-control" id="requirementId">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in requirements" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="task.requirement">
                                                    </div>
                                                </div>        
                                                <div class="col-12 col-md-2">
                                                    <div class="form-group">
                                                        <label for="assignedToId1">Assigned To</label>
                                                        <select v-model="task.assignedToId" class="form-control" id="assignedToId1">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <!-- <multiselect v-model="task.taskAssignment" :options="users" name="assignedBy" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect> -->
                                                    </div>
                                                </div>                                                                                        
                                                <div class="col-12 col-md-3 pr-0">
                                                    <div class="form-group">
                                                        <label for="estimatedStartDt1">Start Date</label>
                                                        <div class="input-group">
                                                            <!-- <input type="date" v-model="task.estimatedStartDt" class="form-control" id="estimatedStartDt1"> -->
                                                            <b-col class="col-6 pl-0 pr-0">
                                                                <b-form-input type="date" v-model="task.estimatedStartDate" :value="task.estimatedStartDate" id="estimatedStartDt1" required></b-form-input>
                                                            </b-col>
                                                            <b-col class="col-6 pl-1">
                                                                <b-form-input type="time" v-model="task.estimatedStartTime" :value="task.estimatedStartTime" required></b-form-input>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 pr-0">
                                                    <div class="form-group">
                                                        <label for="estimatedEndDt1">Due Date</label>
                                                        <div class="input-group ">
                                                            <!-- <input type="date" v-model="task.estimatedEndDt" class="form-control" id="estimatedEndDt1"> -->
                                                            <b-col class="col-6 pl-0 pr-0">
                                                                <b-form-input type="date" v-model="task.estimatedEndDate" :value="task.estimatedEndDate" id="estimatedEndDt1" required></b-form-input>
                                                            </b-col>
                                                            <b-col class="col-6 pl-1">
                                                                <b-form-input type="time" v-model="task.estimatedEndTime" :value="task.estimatedEndDate" required></b-form-input>
                                                            </b-col>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-2 pr-0">
                                                    <div class="form-group">
                                                        <label>Effort</label>
                                                        <b-input-group size="sm" class="mb-2">
                                                            <b-form-input type="number" min="0" v-model="task.effort" ></b-form-input>

                                                            <template v-slot:append>
                                                                <b-dropdown text="Man Days" class="btn-dd" v-model="task.effortType">
                                                                    <b-dropdown-item v-for="(info, index)  in effortTypes" :key="info.id" :value="info.id" @click="task.effortType = info.id;effortType = info.name">{{info.name}}
                                                                    </b-dropdown-item>
                                                                </b-dropdown>
                                                            </template>
                                                        </b-input-group>
                                                    </div>
                                                </div>                                                                                                
<!--                                                 <div class="col-12 col-md-3">
                                                    <div class="form-group">
                                                        <label for="assignedBy1">Assigned By</label>
                                                        <select v-model="task.assignedById" class="form-control" id="assignedById1">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
 -->
                                                <div class="col-12 col-md-2 pr-0">
                                                    <div class="form-group">
                                                        <label for="priorityId1">Priority</label>
                                                        <select v-model="task.priorityId" class="form-control" id="priorityId1">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in taskPriorities" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div v-if="elements.editMode == true" class="col-12 col-md-2 pr-0">
                                                    <div  class="form-group">
                                                        <label>Progress</label>
                                                        <b-input-group size="sm" class="mb-2">
                                                            <b-form-input type="number" min="0" max="100" v-model="task.progressPercentage" ></b-form-input>
                                                            <div class="input-group-append">
                                                                <span class="input-group-text">%</span>
                                                              </div>
                                                        </b-input-group>
                                                    </div>

                                                </div>                                                          
                                                <div class="col-12 col-md-2">
                                                    <div class="form-group">
                                                        <label for="statusId">Status</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.task.statusId.$error }">
                                                            <select v-model="task.statusId" class="form-control" id="statusId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <div class="required-icon"></div>
                                                            <label v-if="$v.task.statusId.$error && !$v.task.statusId.required" class="text-danger">Please select a Status</label>
                                                        </div>
                                                    </div>
                                                </div>
<!--                                                 <div class="col-12 col-md-3">
                                                    <div class="form-group">
                                                        <label>Progress</label>
                                                        <b-progress height="1.5rem" >
                                                            <b-progress-bar v-model="task.progressPercentage" :variant="progressBarVariant(task)">
                                                                <span><strong>{{ task.progressPercentage }}%</strong></span>
                                                            </b-progress-bar>
                                                        </b-progress>
                                                    </div>
                                                </div> -->
                                                <div class="col-12 col-md-12">
                                                    <div class="form-group">
                                                        <label for="description1">Description</label>                                                        
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="task.description"></editor>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div>
                                                            <label class="font-weight-bold">Attachment<i class="fa fa-lg fa-paperclip ml-2" aria-hidden="true"></i></label>
                                                            <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2"></i>Add More</a>
                                                        </div>
                                                        <div id="Multi-attachment" v-for="(input, index) in addMoreAttachment">
                                                            <div class="attachments mb-2">
                                                                <b-form-file class="attachments-list" size="sm" v-model="task.attachments[index]" placeholder="Choose a file.">
                                                                    <template slot="file-name" slot-scope="{ names }">
                                                                        <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                    </template>
                                                                </b-form-file>
                                                                <a href="javascript:" @click="removeFile(index)" class="float-right button-remove p-2 attachments-remove"><i class="fa fa-minus-circle pr-2"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-12">            
                                                    <div class="form-group">
                                                        <div class="card-body p-0">
                                                            <b-card no-body>
                                                                <div class="col-12 px-0 mt-">
                                                                <FunctionalReferencePopup v-if="elements.editMode" ref="referenceModal" :entity="task.entity" :erdId="task.id" />
                                                                </div>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 mt-0">
                                                    <div class="card-body p-0 mb-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-checklist>
                                                                    <label for="description">Check List</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-checklist" v-model="elements.visibileChecklist" accordion="my-accordion2" role="tabpanel">
                                                                <b-card-body class="p-2 pb-0">
                                                                    <div class="ml-1">
                                                                        <div class="row">
                                                                            <div class="col-12 m-1 ml-2">
                                                                                <div class="form-group">
                                                                                    <div v-for="(checkListAll, index) in taskCheckListAll" class="form-check">
                                                                                        <input type="checkbox" @change="updateChecklist(checkListAll)" :value="checkListAll.state" v-model="checkListAll.state" :checked="true" class="form-check-input" id="checkbox-1">
                                                                                        <label class="form-check-label" for="checkbox-1">{{ checkListAll.checklist }}</label>
                                                                                    </div>
                                                                                    <div v-for="(checkList, index) in taskCheckList" class="form-check">
                                                                                        <input type="checkbox" :checked="false" class="form-check-input" id="checkbox-1">
                                                                                        <label class="form-check-label" for="checkbox-1">{{ checkList.checklist }}</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-10">
                                                                                <div class="form-group">
                                                                                    <input type="text" class="form-control" v-model="checklistData.checklistLocal" placeholder="Add an item..." @keyup.enter="addChecklist">
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-2">
                                                                                <div class="form-group">
                                                                                    <button type="submit" @click="addChecklist" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="elements.viewOnlyComponet" class="row">
                                                <div class="col-12 mt-0">
                                                    <div class="card-body p-0 mb-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-comments>
                                                                    <label for="description">Comments</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-comments" v-model="elements.visibileCommAccordian" accordion="my-accordion3" role="tabpanel">
                                                                <b-card-body class="p-2 pb-0">
                                                                    <div class="comments-list">
                                                                        <div class="row mb-3">
                                                                            <div class="col-12" v-for="loadComment in comments">
                                                                                <ul class="comments-list reply-list">
                                                                                    <li>
                                                                                        <div class="user-icon">
                                                                                            <span class="user-name-icon" :title="loadComment.commentedBy"> {{ loadComment.commentedBy | getAcronym }} </span>
                                                                                        </div>
                                                                                        <div class="comment-box">
                                                                                            <div class="comment-head">
                                                                                                <h6 class="comment-name by-author" :title="loadComment.commentedBy"><a href="">{{ loadComment.commentedBy }}</a></h6>
                                                                                                <span>{{ loadComment.commentDt | formatDateTimeAmPm }}</span>
                                                                                                <a href="#" v-if="(loadComment.id != comment.editId)" title="Edit" @click="editComment(loadComment)"><i class="fa fa-pencil"></i></a>
                                                                                                <a href="#" v-if="(loadComment.id == comment.editId) && elements.commentEditMode" title="Update Comment" @click="updateComment"><i class="fa fa-check"></i></a>
                                                                                                <a href="#" v-if="(loadComment.id == comment.editId) && elements.commentEditMode" title="Cancel Edit" @click="clearComment"><i class="fa fa-times"></i></a>
                                                                                                <a href="#" @click="deleteComment(loadComment.id)" data-toggle="modal" data-target="#deleteComment"><i class="fa fa-trash-o"></i></a>
                                                                                            </div>
                                                                                            <div v-if="(loadComment.id != comment.editId)" class="comment-content">{{ loadComment.comment }}</div>
                                                                                            <div v-if="(loadComment.id == comment.editId) && elements.commentEditMode">
                                                                                                <textarea rows="3" class="w-100" v-model="comment.comment"></textarea>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div v-if="!elements.commentEditMode" class="comments-cox">
                                                                        <div class="row">
                                                                            <div class="col-10">
                                                                                <div class="form-group">
                                                                                    <div class="user-icon">
                                                                                        <span v-for="user in users" v-if="user.id == task.userId" :title="user.value" class="user-name-icon"> {{ user.value | getAcronym }} </span>
                                                                                    </div>
                                                                                    <div class="user-comments">
                                                                                        <input type="text" class="form-control" v-model="comment.comment" id="comment" placeholder="Comments" @keyup.enter="addComment">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-2">
                                                                                <div class="form-group">
                                                                                    <button type="submit" @click="addComment" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Task View Mode -->
                                        <div v-if="elements.viewMode">
                                            <div class="row">
                                                <div class="col-12 mb-3">
                                                    <h6 class="text-left font-weight-bold mb-3">{{ task.title }}</h6>
                                                </div>
                                                <div class="col-12 mb-12 mb-3">
                                                    <div class="card">
                                                        <div class="row mx-1 my-2">
                                                            <div class="col-12 col-md-3 mt-4 mb-0">
                                                                <div class="form-group">
                                                                    <label class="mr-3 font-weight-bold">Start Date</label>
                                                                    <span class="text-success"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeAmPm }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-3 mt-4 mb-0">
                                                                <div class="form-group">
                                                                    <label class="mr-3 font-weight-bold">Due Date</label>
                                                                    <span class="text-success" :class="{ 'text-danger': (task.estimatedEndDt < new Date().toISOString())}"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeAmPm }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-3 mb-2">
                                                                <div class="row mt-3">
                                                                    <b-col cols="3">
                                                                        <label class="mt-1">Progress</label>
                                                                    </b-col>
                                                                    <b-col cols="9">
                                                                        <div v-if="elements.taskStatusUpdate">
                                                                            <b-input-group size="sm" class="mb-2">
                                                                                <b-form-input type="number" min="0" max="100" v-model="task.progressPercentage" ></b-form-input>
                                                                                <div class="input-group-append">
                                                                                    <span class="input-group-text">%</span>
                                                                                  </div>
                                                                            </b-input-group>
                                                                        </div>
                                                                        <b-progress v-if="!elements.taskStatusUpdate" height="1.7rem" class="task-label w-90">
                                                                            <b-progress-bar v-model="task.progressPercentage" :variant="progressBarVariant(task)">
                                                                                <span><strong>{{ task.progressPercentage }}%</strong></span>
                                                                            </b-progress-bar>
                                                                        </b-progress>
                                                                    </b-col>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-3 mb-3 mr-0">
                                                                <div class="row mt-3">
                                                                    <b-col cols="3">
                                                                        <label class="mt-2 mr-0">Status</label>
                                                                    </b-col>
                                                                    <b-col cols="9">
                                                                        <label v-if="!elements.taskStatusUpdate" class="form-control task-label mb-1 ml-0">{{ task.status }}</label>
                                                                        <select v-if="elements.taskStatusUpdate && task.assignedById == userId" v-model="task.statusId" class="form-control" id="statusId">
                                                                            <option value="">Select</option>
                                                                            <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                                                        </select>
                                                                        <div v-if="task.assignedToId == userId && task.assignedToId !== task.assignedById">
                                                                            <select v-if="elements.taskStatusUpdate" v-model="task.statusId" class="form-control" id="statusId">
                                                                                <option value="">Select</option>
                                                                                <option v-for="(info, index) in taskStatus " v-if="info.value !== 'Closed'" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                        </div>
                                                                    </b-col>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-12 mb-3">
                                                    <div class="card">
                                                        <div class="row mx-1 my-2">
                                                            <div class="col-12 col-md-3 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Type</label>
                                                                    <label class="form-control task-label">{{ task.type }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-3 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Activity</label>
                                                                    <label class="form-control task-label">{{ task.activity }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-2 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Requirement</label>
                                                                    <label class="form-control task-label">{{ task.requirement }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-2 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Priority</label>
                                                                    <label class="form-control task-label">{{ task.priority }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-2 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Effort</label>
                                                                    <label v-if="task.effort==''" class="form-control task-label">{{ task.effort }} {{ effortTypes.filter(function(a){ return a.id == task.effortType })[0].name }}</label>
                                                                    <label else class="form-control task-label"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mx-1 my-2">                                                            
                                                            <div class="col-12 col-md-3 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Assigned By</label>
                                                                    <label class="form-control task-label">{{ task.assignedBy }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 col-md-3 mt-3">
                                                                <div class="form-group">
                                                                    <label class="font-weight-bold">Assigned To</label>
                                                                    <label class="form-control task-label">{{ task.assignedTo }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 ml-3">
                                                    <div class="form-group">
                                                        <label class="font-weight-bold">Description</label>
                                                        <div class="col-12">
                                                            <span v-html="task.description"></span>
                                                            <!-- <p v-html="task.description"></p> -->                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 ml-3">
                                                    <div class="form-group">
                                                        <div>
                                                            <label class="font-weight-bold">Attachment<i class="fa fa-lg fa-paperclip ml-2" aria-hidden="true"></i></label>
                                                        </div>
                                                        <div id="Multi-attachment" v-for="(input, index) in addMoreAttachment">
                                                            <div class="attachments mb-2">
                                                                <b-form-file class="attachments-list" disabled size="sm" v-model="task.attachments[index]" placeholder="Choose a file.">
                                                                    <template slot="file-name" slot-scope="{ names }">
                                                                        <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                    </template>
                                                                </b-form-file>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-12">            
                                                    <div class="form-group">
                                                        <div class="col-12  px-0">
                                                            <FunctionalReferencePopup ref="referenceModal" :entity="task.entity" :erdId="task.id" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-3">
                                                        <div class="ml-1 task-box-items">
                                                            <label class="font-weight-bold">Check List</label>
                                                            <div class="row">
                                                                <div class="col-12 m-1 ml-2">
                                                                    <div class="form-group">
                                                                        <div v-for="(checkListAll, index) in taskCheckListAll" class="form-check">
                                                                            <input type="checkbox" @change="updateChecklist(checkListAll)" :value="checkListAll.state" v-model="checkListAll.state" :checked="true" class="form-check-input" id="checkbox-1" :disabled="!elements.taskStatusUpdate">
                                                                            <label class="form-check-label" for="checkbox-1">{{ checkListAll.checklist }}</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-if="elements.taskStatusUpdate && task.assignedById == userId" class="row">
                                                                <div class="col-10">
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control" v-model="checklistData.checklistLocal" placeholder="Add an item..." @keyup.enter="addChecklist">
                                                                    </div>
                                                                </div>
                                                                <div class="col-2">
                                                                    <div class="form-group">
                                                                        <button type="submit" @click="addChecklist" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Add</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="elements.viewOnlyComponet" class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 mb-3">
                                                        <div class="ml-1 task-box-items">
                                                            <label class="font-weight-bold">Comments</label>
                                                            <div class="row mt-3">
                                                                <div class="col-12" v-for="loadComment in comments">
                                                                    <ul class="comments-list reply-list">
                                                                        <li>
                                                                            <div class="user-icon">
                                                                                <span class="user-name-icon" :title="loadComment.commentedBy"> {{ loadComment.commentedBy | getAcronym }} </span>
                                                                            </div>
                                                                            <div class="comment-box">
                                                                                <div class="comment-head">
                                                                                    <h6 class="comment-name by-author" :title="loadComment.commentedBy"><a href="">{{ loadComment.commentedBy }}</a></h6>
                                                                                    <span>{{ loadComment.commentDt | formatDateTimeAmPm }}</span>
                                                                                </div>
                                                                                <div class="comment-content">{{ loadComment.comment }}</div>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="elements.viewOnlyComponet" class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 mb-3">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-1 pl-2 my-1 accordion-title" block href="javascript:" v-b-toggle.accordion-timeSheet>
                                                                <label for="description">Time Sheet</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accordion-timeSheet" v-model="elements.visibileTimeSheet" accordion="my-accordion4" role="tabpanel">
                                                            <b-card-body class="p-2">
                                                                <div class="col-lg-12 col-xl-12">
                                                                    <div class="card-body px-0 py-2">
                                                                        <div class="row view-filter">
                                                                            <div class="col-sm-12">
                                                                                <div class="float-right">
                                                                                    <div class="dataTables_length">
                                                                                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                                                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                                                        </b-form-group>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="float-left">
                                                                                    <div class="dataTables_filter">
                                                                                        <label>
                                                                                            <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="clearfix"></div>
                                                                            </div>
                                                                        </div>
                                                                        <b-table show-empty small stacked="md" sort-icon-left :items="timeSheets" :fields="timeSheetFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                                            <template v-slot:cell(actions)="data">
                                                                                <a v-if="!elements.editTimeSheet" href="javascript:" title="Edit" @click="showEditTimeSheet(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                                                                <a v-if="!elements.editTimeSheet" href="javascript:" title="Delete" @click="deleteTimeSheet(data.item.id)" data-toggle="modal" data-target="#deleteTimeSheet"><i class="fa fa-trash pl-2"></i></a>
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <a v-if="elements.editTimeSheet" href="javascript:" title="Save" @click="saveTimeSheetData"><i class="fa fa-check text-success pl-2"></i></a>
                                                                                    <a v-if="elements.editTimeSheet" href="javascript:" title="Cancel" @click="cancelTimeSheetEdit"><i class="fa fa-times text-danger pl-2"></i></a>
                                                                                </div>
                                                                            </template>
                                                                            <template v-if="elements.editTimeSheet" v-slot:cell(title)="data">
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <input type="text" class="form-control" v-model="timeSheet.title" placeholder="Title">
                                                                                </div>
                                                                                <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.title }}</div>
                                                                            </template>
                                                                            <template v-if="elements.editTimeSheet" v-slot:cell(taskTitle)="data">
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <select v-model="timeSheet.taskId" class="form-control">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in tsTasks" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.taskTitle }}</div>
                                                                            </template>
                                                                            <template v-if="elements.editTimeSheet" v-slot:cell(activity)="data">
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <select v-model="timeSheet.activityId" class="form-control">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in tsActivities" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.activity }}</div>
                                                                            </template>
                                                                            <template v-if="elements.editTimeSheet" v-slot:cell(startDate)="data">
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <input v-model="timeSheet.startDate" type="datetime-local" class="form-control" placeholder="Start Date">
                                                                                </div>
                                                                                <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.startDate | formatDateTimeAmPm }}</div>
                                                                            </template>
                                                                            <template v-if="elements.editTimeSheet" v-slot:cell(endDate)="data">
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <input v-model="timeSheet.endDate" type="datetime-local" class="form-control" placeholder="Due Date">
                                                                                </div>
                                                                                <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.endDate | formatDateTimeAmPm }}</div>
                                                                            </template>
                                                                            <template v-if="elements.editTimeSheet" v-slot:cell(status)="data">
                                                                                <div v-if="data.item.id == timeSheetEditId">
                                                                                    <select v-model="timeSheet.statusId" class="form-control">
                                                                                        <option value="">Select</option>
                                                                                        <option v-for="(info, index) in timeSheetStatus" :value="info.id">{{info.value}}</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div v-if="data.item.id !== timeSheetEditId"> {{ data.item.status }}</div>
                                                                            </template>
                                                                            <template v-if="!elements.newTimeSheet && elements.taskStatusUpdate" v-slot:custom-foot="data">
                                                                                <tr>
                                                                                    <td class="text-center bg-info text-white pointer" @click="addTimeSheet" :colspan="9">
                                                                                        <span class="">ADD TIME SHEET<i class="fa fa-plus ml-3" aria-hidden="true"></i></span>
                                                                                    </td>
                                                                                </tr>
                                                                            </template>
                                                                            <template v-slot:empty="scope">
                                                                                <div class="text-center">No Time Sheets found</div>
                                                                            </template>
                                                                            <template v-slot:emptyfiltered="scope">
                                                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                                            </template>
                                                                        </b-table>
                                                                        <b-pagination v-if="timeSheets.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="mt-0 mb-3 pull-right"></b-pagination>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-12 text-right">
                                            <button type="submit" v-if="!elements.viewMode || elements.taskStatusUpdate" @click="saveData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 mr-3"><i class="fa fa-check pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3 task-close-btn"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <b-modal id="Quick-Task-Modal" ref="modal" @hidden="clearData" no-close-on-backdrop no-close-on-esc title="Add Quick Task" class="task-header">
                        <b-container fluid>
                            <b-row class="mb-1">
                                <b-col cols="3">Task</b-col>
                                <b-col>
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.task.title.$error }">
                                        <b-form-input id="title" v-model="task.title" placeholder="Enter Task Title" required></b-form-input>
                                        <div class="required-icon"></div>
                                    </div>
                                    <label v-if="$v.task.title.$error && !$v.task.title.minLength" class="text-danger">Task Title must have at least {{$v.task.title.$params.minLength.min}} letters.</label>
                                    <label v-if="$v.task.title.$error && !$v.task.title.required" class="text-danger">Please Enter Task Title</label>
                                    <label v-if="$v.task.title.$error && !$v.task.title.maxLength" class="text-danger">Task Title must not exceed {{$v.task.title.$params.maxLength.max}} letters.</label>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Start Date</b-col>
                                <b-col class="col-5 pr-0">
                                    <b-form-input type="date" v-model="task.estimatedStartDate" required></b-form-input>
                                </b-col>
                                <b-col class="col-4">
                                    <b-form-input type="time" v-model="task.estimatedStartTime" required></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Due Date</b-col>
                                <b-col class="col-5 pr-0">
                                    <b-form-input type="date" v-model="task.estimatedEndDate" required></b-form-input>
                                </b-col>
                                <b-col class="col-4">
                                    <b-form-input type="time" v-model="task.estimatedEndTime" required></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Activity</b-col>
                                <b-col>
                                    <select v-model="task.activityId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in taskActivity" :value="info.id">{{info.value}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Assigned To</b-col>
                                <b-col>
                                    <select v-model="task.assignedToId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                    </select>
                                    <!-- <multiselect v-model="task.taskAssignment" :options="users" name="assignedBy" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect> -->
                                </b-col>
                            </b-row>
                            <b-row class="mb-1">
                                <b-col cols="3">Status</b-col>
                                <b-col>
                                    <select v-model="task.statusId" class="form-control">
                                        <option value="">Select</option>
                                        <option v-for="(info, index) in taskStatus" :value="info.id">{{info.value}}</option>
                                    </select>
                                </b-col>
                            </b-row>
                        </b-container>
                        <template #modal-footer>
                            <div class="w-100">
                                <b-button variant="primary" size="sm" @click="saveData" class="float-right">
                                    Add Task
                                </b-button>
                                <button class="btn btn-outline-primary float-right mr-2" @click="clearData">
                                    Clear
                                </button>
                            </div>
                        </template>
                    </b-modal>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />

        <Dialog id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />
        <Dialog id="deleteComment" :onYes="onYesComment" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Comment?" />
        <Dialog id="deleteTimeSheet" :onYes="onYesTimeSheet" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Time Sheet" />
        <TaskDetailPopup v-if="showDialog" ref="taskDetailPopupRef" :detailViewData="detailViewData" @closeDialog="closeDialog" :referenceDetailId="referencePopupId" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import Editor from '@tinymce/tinymce-vue';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
export default {
    name: 'app',
    components: {
        TypeLookup,
        HTMLDetailViewPopup,
        TaskDetailPopup,
        Multiselect,
        Editor,
        FunctionalReferencePopup
    },
    data() {
        return {
            elements: {
                editMode: false,
                viewMode: false,
                commentEditMode: false,
                viewOnlyComponet: false,
                filterMode: false,
                visibileChecklist: false,
                visibileCommAccordian: false,
                visibileTimeSheet: false,
                newTimeSheet: false,
                editTimeSheet: false,
                taskStatusUpdate: false
            },
            showDialog: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            ermTitle: localStorage.getItem("ermTitle"),
            userId: localStorage.getItem("userId"),
            typeEntity: 'task_type',

            typeLookupTitle:'Task Type Settings',
            htmlData: '',
            htmlDetailViewTitle: '',

            task: {
                id: '',
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                userId: localStorage.getItem("userId"),
                entity: 'task',
                title: '',
                planId: '',
                requirementId: '',
                requirement: '',
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: new Date().toISOString().substr(0, 10),
                estimatedStartTime: '',
                estimatedEndDt: '',
                estimatedEndDate: new Date().toISOString().substr(0, 10),
                estimatedEndTime: '',
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '',
                estimatedCost: '',
                progressPercentage: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasChecklist: '',
                entityId: '',
                refErmId: '',
                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checkListTag: '',
            },
            comment: {
                id: '',
                commentId: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: this.$route.params.ermId,
                commentedById: localStorage.getItem("userId"),
                entityId: '',
                entityReferenceId: '',
                entity: 'task',
                comment: '',
                commentDt: new Date().toISOString().substr(0, 10),
                editId: ''
            },
            checklistData: {
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                entity: 'task',
                preparedById: '',
                checklistReportId: '',
                checklist: '',
                checklistLocal: '',
                state: '',
                preparedDt: ''
            },
            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedById: '',
                title: '',
                taskId: '',
                task: '',
                activityId: '',
                activity: '',
                preparedBy: '',
                startDate: '',
                endDate: '',
                description: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: 'time_sheet'
            },
            entity: 'development_plan_master',
            timeSheetEditId: '',

            effortTypes: [],
            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],
            detailViewData: {},
            referencePopupId: 0,
            
            allTasks: [],
            tasks: [],
            taskCheckList: [],
            taskCheckListAll: [],
            comments: [],
            addMoreAttachment:[],
            users: [],
            requirements: [],
            taskActivity: [],
            taskTypes: [],
            taskPriorities: [],
            taskStatus: [],
            filteredRequirements: [],
            entityRecordMasters: [],
            timeSheets: [],
            tsTasks: [],
            tsActivities: [],
            timeSheetStatus: [],
            timeSheetFields: [
                { key: 'timeSheetId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                //{ key: 'taskTitle', label: 'Task', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'startDate', label: 'Start Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'endDate', label: 'End Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            requirementFilter: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    mounted() {
        this.getTasks(this.$route.params.ermId);
        this.getEntityRecordMasterList(this.entity);
        this.lookupData();
        this.reqSpecLookupData();
        this.addMoreAttachment.push(this.addMoreAttachment.length + 1);
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            }
        }
    },
    methods: {
        getTasks: function(ermId) {
            let data = {
                entityRecordMasterId: ermId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/wbs/list', data)
                .then((response) => {
                    let result = response.data;
                    this.tasks = result.taskWBSList;
                    this.allTasks = this.tasks;
                    console.log(this.tasks);
                    this.timeSheetLookupData();
                })
        },
        getTimeSheets: function(id) {
            let data = {
                taskId: id
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetbytask/list',data)
                .then((response) => {
                    let result = response.data;
                    this.timeSheets = result.timeSheetListByTask;
                    this.totalRows = this.timeSheets.length;  
                    console.log(result);                  
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'task'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.requirements = result.requirementLookup;
                    this.taskActivity = result.activityLookup;
                    this.taskStatus = result.statusLookup;
                    this.taskTypes = result.customTypeLookup;
                    this.taskPriorities = result.priorityLookup;
                    console.log(this.users);
                })
        },
        reqSpecLookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'task'
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/reqlookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.filteredRequirements = result.requirementLookup;
                    console.log(this.users);
                })
        },
        timeSheetLookupData: function() {
            let data = {
                vhextAccountId: this.task.vhextAccountId,
                projectId: this.task.projectId,
                projectTypeId: this.projectTypeId,
                entityRecordMasterId: this.task.entityRecordMasterId,
                entity: 'time_sheet'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.tsTasks = result.taskLookup;
                    this.tsActivities = result.activityLookup;
                    this.timeSheetStatus = result.statusLookup;
                    console.log(result);
                })
        },
        saveData: function() {
            this.$v.task.$touch();
            if (!this.$v.task.$invalid) {
                if(this.task.estimatedStartDt == '') {this.task.estimatedStartDt = this.combineDateAndTime(this.task.estimatedStartDate, this.task.estimatedStartTime)};
                if(this.task.estimatedEndDt == '') {this.task.estimatedEndDt = this.combineDateAndTime(this.task.estimatedEndDate, this.task.estimatedEndTime)};
                if (this.elements.editMode) {
                    this.updateTask();
                } else {
                    this.saveTask();
                }
            }
        },
        saveTask: function() {
            // this.task.taskAssignmentList = [];
            // for (var index = 0; index < this.task.taskAssignment.length; index++) {
   
            //     this.task.taskAssignmentList.push({ assignedToId: this.task.taskAssignment[index].id });
            // }
            // this.task.taskAssignmentList.push({ assignedToId: this.task.assignedById });
            console.log(this.task);
            axios.post(this.$store.getters.getAPIBasePath + '/task/add', this.task)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        if (this.task.attachments != null && this.task.attachments.length > 0)
                                this.submitFiles(result.id);
                            else {
                                this.showMessage('Task Created without attachment.', result.id);
                            }
                        this.getTasks(this.task.entityRecordMasterId);
                        this.clearData();
                    }
                })
        },
        
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },
        showView: function(data) {
            this.elements.viewMode = true;
            this.elements.viewOnlyComponet=true;
            this.task.id = data.id;
            console.log(data);
            this.getComments();
            this.getChecklist(this.task.id);
            this.getTimeSheets(this.task.id);
            this.task.title = data.title;
            this.task.estimatedStartDt = data.estimatedStartDt;
            this.task.estimatedStartDate = data.estimatedStartDate;
            this.task.estimatedStartTime = data.estimatedStartTime;
            this.task.estimatedEndDt = data.estimatedEndDt;
            this.task.estimatedEndDate = data.estimatedEndDate;
            this.task.estimatedEndTime = data.estimatedEndTime;
            this.task.taskAssignment = data.taskAssignment;
            this.task.activityId = data.activityId;
            this.task.requirementId = data.requirementId;
            this.task.requirement = data.requirement;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.progressPercentage = data.progressPercentage;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.taskId = data.taskId;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.startDate = data.startDate;
            this.task.endDate = data.endDate;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = data.assignedById;
            this.task.assignedTo = data.assignedTo;
            this.task.assignedToId = data.assignedToId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.createdDt = data.createdDt;
            this.task.lastUpdatedDt = data.lastUpdatedDt;
        },
        showEdit: function(data) {
            console.log(this.formatTimeFromDt((new Date(data.estimatedStartDt)).toISOString()));
            this.elements.editMode = true;
            this.elements.viewMode = false;
            this.elements.visibileChecklist = false;
            this.elements.visibileCommAccordian = false;
            this.elements.visibileTimeSheet = false;
            this.elements.viewOnlyComponet=true;
            this.task.id = data.id;
            this.getComments();
            this.getChecklist(this.task.id);
            this.getTimeSheets(this.task.id);
            this.task.title = data.title;
            console.log(data.estimatedStartDt);
            console.log(data.estimatedEndDt);
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = data.estimatedStartDt.substr(0, 10);
            this.task.estimatedStartTime = this.formatTimeFromDt((new Date(data.estimatedStartDt)).toISOString());
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = data.estimatedEndDt.substr(0, 10);
            this.task.estimatedEndTime =  this.formatTimeFromDt((new Date(data.estimatedEndDt)).toISOString());
            this.task.taskAssignment = data.taskAssignment;
            this.task.activityId = data.activityId;
            this.task.requirementId = data.requirementId;
            this.task.requirement = data.requirement;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.progressPercentage = data.progressPercentage;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.taskId = data.taskId;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.startDate = data.startDate;
            this.task.endDate = data.endDate;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = data.assignedById;
            this.task.assignedTo = data.assignedTo;
            this.task.assignedToId = data.assignedToId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.createdDt = data.createdDt;
            this.task.lastUpdatedDt = data.lastUpdatedDt;
        },
        updateTask: function() {
            console.log(this.task);
            axios.post(this.$store.getters.getAPIBasePath + '/task/update', this.task)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.elements.editMode = false;
                        this.elements.viewMode = true;
                        this.elements.commentEditMode = false;
                        this.elements.taskStatusUpdate = false;
                        this.getTasks(this.task.entityRecordMasterId);
                        this.getComments();
                        this.getChecklist(this.task.id);
                        this.getTimeSheets(this.task.id);
                    }
                })
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.viewMode = false;
            this.elements.visibileChecklist = false;
            this.elements.visibileCommAccordian = false;
            this.elements.visibileTimeSheet = false;
            this.elements.taskStatusUpdate = false;
            this.task.title = '';
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = new Date().toISOString().substr(0, 10);
            this.task.estimatedEndTime = '';
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = new Date().toISOString().substr(0, 10);
            this.task.estimatedStartTime = '';
            this.task.taskAssignment = '';
            this.task.activityId = '';
            this.task.requirementId = '';
            this.task.requirement = '';
            this.task.effort = '';
            this.task.effortType = '';
            this.task.estimatedCost = '';
            this.task.progressPercentage = '';
            this.task.statusId = '';
            this.task.assignedTo = '';
            this.task.assignedToId = '';
            this.task.taskId = '';
            this.task.planId = '';
            this.task.activity = '';
            this.task.taskTypeId = '';
            this.task.type = '';
            this.task.description = '';
            this.task.priorityId = '';
            this.task.priority = '';
            this.task.startDate = '';
            this.task.endDate = '';
            this.task.hasReference = '';
            this.task.hasAttachment = '';
            this.task.hasComment = '';
            this.task.hasChecklist = '';
            this.task.entityId = '';
            this.task.refErmId = '';
            this.taskCheckList = [];
            this.taskCheckListAll = [];
            this.task.attachments = [];
            this.$v.task.$reset();
        },
        viewTask: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let data = result.task;
            this.task.id = data.id;
            console.log(data);
            this.task.title = data.title;
            this.task.estimatedStartDt = data.estimatedStartDt;
            this.task.estimatedStartDate = data.estimatedStartDate;
            this.task.estimatedStartTime = data.estimatedStartTime;
            this.task.estimatedEndDt = data.estimatedEndDt;
            this.task.estimatedEndDate = data.estimatedEndDate;
            this.task.estimatedEndTime = data.estimatedEndTime;
            this.task.taskAssignment = data.taskAssignment;
            this.task.activityId = data.activityId;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.progressPercentage = data.progressPercentage;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.taskId = data.taskId;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.startDate = data.startDate;
            this.task.endDate = data.endDate;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = data.assignedById;
            this.task.assignedTo = data.assignedTo;
            this.task.assignedToId = data.assignedToId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
            this.task.createdDt = data.createdDt;
            this.task.lastUpdatedDt = data.lastUpdatedDt;
            })
        },
        deleteTask: function(id) {
            console.log(id)
             this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTasks(this.task.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveTimeSheetData: function() {
            console.log(this.timeSheet);
            if (this.elements.editTimeSheet && !this.elements.newTimeSheet) {
                this.updateTimeSheet();
            } 
            if (this.elements.newTimeSheet) {
                this.saveTimeSheet();
            }
        },
        addTimeSheet: function() {
            //this.clearTimeSheet();
            console.log(this.timeSheets);
            this.timeSheets.push({id: this.timeSheet.id, title: this.timeSheet.title, activityId: this.timeSheet.activityId, startDate: this.timeSheet.startDate, endDate: this.timeSheet.endDate, statusId: this.timeSheet.statusId});
            this.timeSheetEditId = '';
            this.elements.editTimeSheet = true;
            this.elements.newTimeSheet = true;
        },
        saveTimeSheet: function() {
            this.timeSheet.vhextAccountId = this.task.vhextAccountId;
            this.timeSheet.projectId = this.task.projectId;
            this.timeSheet.entityRecordMasterId = this.task.entityRecordMasterId;
            this.timeSheet.preparedById = this.task.userId;
            this.timeSheet.taskId = this.task.id;
            console.log(this.timeSheet);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/add', this.timeSheet)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        console.log(this.task.id);
                        this.getTimeSheets(this.task.id);
                        this.clearTimeSheet();
                        this.elements.editTimeSheet = false;
                        this.elements.newTimeSheet = false;
                    }
                })
        },
        updateTimeSheet: function() {
            console.log(this.timeSheet);
            axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/update', this.timeSheet)
                .then((response) => {
                    console.log(this.timeSheet);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.task.id);
                        this.clearTimeSheet();
                        this.elements.editTimeSheet = false;
                        this.elements.newTimeSheet = false;
                    }
                })
        },
        cancelTimeSheetEdit: function() {
            this.getTimeSheets(this.task.id);
            this.clearTimeSheet();
            this.elements.editTimeSheet=false;
            this.elements.newTimeSheet=false;
        },
        clearTimeSheet: function() {
            this.timeSheet.taskId = '';
            this.timeSheet.title = '';
            this.timeSheet.statusId = '';
            this.timeSheet.activityId = '';
            this.timeSheet.activity = '';
            this.timeSheet.status = '';
            this.timeSheet.activity = '';
            this.timeSheet.startDate = '';
            this.timeSheet.endDate = '';
        },
        deleteTimeSheet: function(id) {
            this.deleteParam.id = id;
        },
        onYesTimeSheet: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/timesheet/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTimeSheets(this.task.id);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getEntityRecordMasterList: function(entity) {
            let data = {
                vhextAccountId: this.task.vhextAccountId,
                projectId: this.task.projectId,
                userId: this.userId,
                entity: entity,
                isArchive: false

            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMasters = result.entityRecordMasterList;
                    console.log(this.entityRecordMasters[0]);
            })
        },
        getTaskByStatusId(statusId) {
            return this.tasks.filter(task => task.statusId == statusId);
        },
        getChecklist: function(id) {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: this.task.id,
                entity: 'task'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.taskCheckListAll = result.taskChecklistReportList;
                    console.log(result);
                })
        },
        addChecklist: function() {
            this.checklistData.entityRecordMasterId = this.task.entityRecordMasterId;
            this.checklistData.projectId = this.task.projectId;
            this.checklistData.vhextAccountId = this.task.vhextAccountId;
            this.checklistData.refRecordId = this.task.id;
            this.checklistData.refEntity = 'task';
            this.checklistData.preparedById = this.userId;
            this.checklistData.state = false;
            this.checklistData.preparedDt = new Date().toISOString().substr(0, 10);
            this.checklistData.checklist = this.checklistData.checklistLocal;

            console.log(this.checklistData);
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/add', this.checklistData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.getChecklist();
                        this.checklistData.checklistLocal = '';
                    }
                })
        },
        updateChecklist: function(data) {
            console.log(data);
            this.checklistData.id = data.id;
            this.checklistData.entityRecordMasterId = data.entityRecordMasterId;
            this.checklistData.projectId = data.projectId;
            this.checklistData.vhextAccountId = data.vhextAccountId;
            this.checklistData.refEntityId = data.refEntityId;
            this.checklistData.checklistReportId = data.checklistReportId;
            this.checklistData.preparedById = data.preparedById;
            this.checklistData.preparedBy = data.preparedBy;
            this.checklistData.checklist = data.checklist;
            this.checklistData.state = data.state;
            this.checklistData.preparedDt = data.preparedDt;

            console.log(this.checklistData);
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/update', this.checklistData)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        this.getChecklist();
                        this.checklistData.checklistLocal = '';
                    }
                })
        },
        getComments: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityReferenceId: this.task.id,
                entity: 'task'
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/task/list', data)
                .then((response) => {
                    let result = response.data;
                    this.comments = result.taskCommentList;
                    console.log(this.comments);
                })
        },
        addComment: function() {
            console.log(this.comment);
            this.comment.entityReferenceId = this.task.id;
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/add', this.comment)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments();
                        this.clearComment();
                    }
                })
        },
        updateComment: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/general/comment/update', this.comment)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments();
                        this.clearComment();
                        this.elements.commentEditMode = false;
                    }
                })
        },
        editComment: function(data) {
            this.elements.commentEditMode = true;
            this.comment.editId = data.id;
            this.comment.id = data.id;
            this.comment.commentId = data.commentId;
            this.comment.projectId = data.projectId;
            this.comment.vhextAccountId = data.vhextAccountId;
            this.comment.entityRecordMasterId = data.entityRecordMasterId;
            this.comment.commentedById = data.commentedById;
            this.comment.entityId = data.entityId;
            this.comment.entity = data.entity;
            this.comment.comment = data.comment;
            this.comment.commentDt = data.commentDt;
        },
        deleteComment: function(id) {
            console.log(id)
             this.deleteParam.id = id;
        },
        onYesComment: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/comment/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getComments(this.comment.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearComment: function() {
            this.elements.commentEditMode = false;
            this.comment.id = '';
            this.comment.editId = '';
            this.comment.commentId = '';
            this.comment.comment = '';
        },
        addInput: function() {
            this.addMoreAttachment.push(this.addMoreAttachment.length + 1)
        },
        removeFile: function(index) {
            this.task.attachments.splice(index, 1);
        },
        submitFiles: function(taskId) {
            let formData = new FormData();
            for (const row of Object.keys(this.task.attachments)) {
                formData.append('files', this.task.attachments[row]);
                formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
                formData.append('projectId', localStorage.getItem("projectId"));
                formData.append('attachedById', localStorage.getItem("userId"));
                formData.append('refRecordId', taskId);
                formData.append('refEntity', 'task');
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Task Created with attachment.', taskId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    this.showMessage('Task Created without attachment.', taskId);
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function(message, id) {
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
        },
        teamMemberFilter: function(userId) {
            this.elements.filterMode = true;
            this.tasks = this.allTasks;
            if (this.tasks != null) {
                var userTask = this.tasks;
                this.tasks = userTask.filter(function (e) {
                    return e.assignedToId == userId;
                });
            }
            console.log(this.tasks);
        },
        requirementTaskFilter: function(erdId) {
            if (erdId != null && erdId != undefined) {
                console.log(erdId)
                this.elements.filterMode = true;
                this.tasks = this.allTasks;
                if (this.tasks != null) {
                    var reqTask = this.tasks;
                    this.tasks = reqTask.filter(function (e) {
                        console.log(e.erdId)
                        return e.erdId == erdId;
                    });
                }
                console.log(this.tasks);
            }
        },
        clearFilter: function() {
            this.requirementFilter = '';
            this.tasks = this.allTasks;
            this.elements.filterMode = false;
        },
        showEditTimeSheet: function(data) {
            this.elements.editTimeSheet = true;
            this.timeSheetEditId = data.id;
            console.log(this.timeSheetEditId);
            this.timeSheet.id = data.id;
            this.timeSheet.projectId = data.projectId;
            this.timeSheet.vhextAccountId = data.vhextAccountId;
            this.timeSheet.entityRecordMasterId = data.entityRecordMasterId;
            this.timeSheet.entity = data.entity;
            this.timeSheet.timeSheetId = data.timeSheetId;
            this.timeSheet.taskId = data.taskId;
            this.timeSheet.taskTitle = data.taskTitle;
            this.timeSheet.title = data.title;
            this.timeSheet.statusId = data.statusId;
            this.timeSheet.status = data.status;
            this.timeSheet.activityId = data.activityId;
            this.timeSheet.activity = data.activity;
            this.timeSheet.preparedById = data.preparedById;
            this.timeSheet.preparedBy = data.preparedBy;
            this.timeSheet.startDate = data.startDate;
            this.timeSheet.endDate = data.endDate;
            this.timeSheet.description = data.description;
            console.log(this.timeSheet);
        },
        progressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                }
                else if (task.progressPercentage < 50) {
                    return 'warning';
                }
                else if (task.progressPercentage < 75) {
                    return 'info';
                }
                else if (task.progressPercentage < 100) {
                    return 'success';
                }
                else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        gatGanttChart: function() {
            if (this.task.entityRecordMasterId !== null) {
                this.$router.push('/taskgantt/' + this.task.entityRecordMasterId);
            }
        },
        duplicateTask: function(data) {
            this.elements.viewOnlyComponet = false;
            console.log(data);
            this.task.title = data.title;
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = data.estimatedStartDt.substr(0, 10);
            this.task.estimatedStartTime = this.formatTimeFromDt((new Date(data.estimatedStartDt)).toISOString());
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = data.estimatedEndDt.substr(0, 10);
            this.task.estimatedEndTime =  this.formatTimeFromDt((new Date(data.estimatedEndDt)).toISOString());
            this.task.taskAssignment = data.taskAssignment;
            this.task.activityId = data.activityId;
            this.task.requirementId = data.requirementId;
            this.task.effort = data.effort;
            this.task.effortType = data.effortType;
            this.task.estimatedCost = data.estimatedCost;
            this.task.statusId = data.statusId;
            this.task.status = data.status;
            this.task.vhextAccountId = data.vhextAccountId;
            this.task.projectId = data.projectId;
            this.task.entityRecordMasterId = data.entityRecordMasterId;
            this.task.planId = data.planId;
            this.task.activity = data.activity;
            this.task.taskTypeId = data.taskTypeId;
            this.task.type = data.type;
            this.task.description = data.description;
            this.task.priorityId = data.priorityId;
            this.task.priority = data.priority;
            this.task.assignedBy = data.assignedBy;
            this.task.assignedById = this.userId;
            this.task.hasReference = data.hasReference;
            this.task.hasAttachment = data.hasAttachment;
            this.task.hasComment = data.hasComment;
            this.task.hasChecklist = data.hasChecklist;
            this.task.entityId = data.entityId;
            this.task.refErmId = data.refErmId;
        },
        combineDateAndTime: function(date, time) {
            console.log(date,time);
            if (time !== '') {
                return moment(date.toString() + 'T' + time.toString());
            }
            else return moment(date.toString() + 'T' + '00:00');

        },
    }
}
</script>
