<template>
    <div class="testingDetailPopup">
        <b-modal id="testingDetailPopup" ref="testingDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Testing" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="col-lg-12 col-xl-12">
                                    <div class="row">
                                        <div class="col-9 col-sm-9 mb-3 px-0">
                                            <div class="row mb-0">
                                                <div class="col-2 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.testCaseId }}</label>
                                                </div>
                                                <div class="col-10 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 col-sm-3">
                                            <div class="float-right">
                                                <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6 pl-0 pr-1">
                                            <div class="row mb-0">
                                                <div class="col-3 pr-0">
                                                    <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                                </div>
                                                <div class="col-9 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.type}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="popupData.testedBy !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-12 col-md-3 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Tested By</label>
                                                </div>
                                                <div class="col-12 col-md-9 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.testedBy}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="popupData.userName !== ''" class="col-6 col-sm-6 pl-1 pr-0">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-12 col-md-3 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared By</label>
                                                </div>
                                                <div class="col-12 col-md-9 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.userName}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-sm-6 pl-0 pr-1">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Test Description</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-6 pl-1 pr-0">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Expected Result</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.expectedResult"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div v-if="popupData.remarks !== ''" class="col-12 col-sm-12 px-0">
                                            <div class="card mt-2 mb-3">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Remarks</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="popupData.remarks"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="popupData.testDate !== ''" class="col-6 col-sm-6">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-6 col-md-6 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Tested Date</label>
                                                </div>
                                                <div class="col-6 col-md-6 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.testDate | formatDate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="popupData.preparedDt !== ''" class="col-6 col-sm-6">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-6 col-md-6 pr-0">
                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Prepared Date</label>
                                                </div>
                                                <div class="col-6 col-md-6 pl-0">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.preparedDt | formatDate}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
                if (refEntity === "issue") {
                    this.$bvModal.show('testingDetailPopup');
                    axios.get(this.$store.getters.getAPIBasePath + '/project/issue/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.issueView;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$bvModal.show('testingDetailPopup');
                    this.loading = false;
                }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>