<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-3 col-md-3 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">Activity</h4>
                        </div>
                        <div class="col-lg-5 col-md-3 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-1">
                                    <li class="breadcrumb-item">
                                        <!-- <a href="javascript:" class="d-block p-1 action-link" title="Existing Activity" @click="showDialog=true;" v-b-modal.existingActivityLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing Activity</a> -->
                                        <b-button class="btn btn-xs float-right px-2" variant="outline-primary" title="Existing Activity" @click="showDialog=true;" v-b-modal.existingActivityLookup><i class="fa fa-plus-circle pr-1"></i></i>Existing Activity</b-button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li v-if="projId" class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Activity</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="activity">Activity</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.activity.activity.$error }">
                                                                    <input type="text" v-model="activity.activity" maxlength="100" class="form-control" id="activity" placeholder="Enter Activity">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                            <label v-if="$v.activity.activity.$error && !$v.activity.activity.required" class="text-danger">Please enter Activity</label>
                                                        <label v-if="$v.activity.activity.$error && !$v.activity.activity.maxLength" class="text-danger">Activity must not exceed {{$v.activity.activity.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div v-if="!projectTypeId" class="col-5">
                                                        <div class="form-group">
                                                            <label for="projectTypeId">Project Type</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.activity.projectTypeId.$error }">                                                            
                                                                <select v-model="activity.projectTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.activity.projectTypeId.$error && !$v.activity.projectTypeId.required" class="text-danger">Please select Project Type</label>                                                            
                                                        </div>
                                                    </div>                                                                                                        
                                                    <div class="col-1">
                                                        <div class="form-group">
                                                            <label for="active">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="activity.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Description</label>
                                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="activity.description"></editor>
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-12">
                                                        <div class="card-body p-0 mb-3">
                                                            <b-card no-body class="p-1">
                                                                <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                    <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                                        <span><i class="fa fa-plus-circle pr-2 action-link"></i>ETVX</span>
                                                                    </label>
                                                                </b-card-header>
                                                                <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                                    <b-card-body class="p-1">
                                                                        <div class="form-row">
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="expectedResult">Entry</label>
                                                                                    <textarea v-model="activity.input" rows="4" class="form-control" id="input" maxlength="2000" placeholder="Enter Process Inputs"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="expectedResult">Task</label>
                                                                                    <textarea v-model="activity.task" rows="4" class="form-control" id="task" maxlength="2000" placeholder="Enter Process Activities/Tasks"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="description">Verification/Validation</label>
                                                                                    <textarea v-model="activity.validation" rows="4" class="form-control" id="validation" maxlength="2000" placeholder="Enter Verification/Validation/Testing Information"></textarea>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-3">
                                                                                <div class="form-group">
                                                                                    <label for="description">Exit</label>
                                                                                    <textarea v-model="activity.output" rows="4" class="form-control" id="output" maxlength="2000" placeholder="Enter Process Output/Deliverables"></textarea>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </b-card-body>
                                                                </b-collapse>
                                                            </b-card>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <button type="submit" @click="saveData" :diabled="showOverlay" class="btn btn-primary mb-0 mr-2 py-1 px-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 py-1 px-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="activityTable" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="activityListAll"  @row-selected="onRowSelected" :fields="activityFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(description)="data">
                                    <span v-html="data.value"></span>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Delete" @click="deleteActivity(data.item.id)" v-b-modal.deleteActivity><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Activities found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            
                            <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                            <b-pagination v-if="activityListAll.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{ activityDetail.activityId }}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateActivity" :diabled="showDetOverlay" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="form-group">
                                                    <label for="title">Activity</label>
                                                    <input type="text" class="form-control" maxlength="100" id="title" :disabled="!elements.editMode" v-model='activityDetail.activity'>
                                                </div>
                                            </div>
                                            <div class="col-2">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="activityDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div v-if="!projectTypeId" class="col-12">
                                                <div class="form-group">
                                                    <label for="projectTypeId">Project Type</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.activityDetail.projectTypeId.$error }">                                                            
                                                        <select v-model="activityDetail.projectTypeId" class="form-control" :disabled="!elements.editMode">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.activityDetail.projectTypeId.$error && !$v.activityDetail.projectTypeId.required" class="text-danger">Please select Project Type</label>                                                            
                                                </div>
                                            </div>                                                                                                                      
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="description1">Description</label>
                                                    <!-- <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='activityDetail.description'></textarea> -->
                                                    <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" :disabled="!elements.editMode" v-model="activityDetail.description"></editor>
                                                </div>
                                            </div>
<!--                                             <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Entry</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='activityDetail.input'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Task</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='activityDetail.task'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Verification/Validation</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='activityDetail.validation'></textarea>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <div class="form-group">
                                                    <label for="description1">Exit</label>
                                                    <textarea class="form-control" maxlength="1000" id="description1" rows="4" :disabled="!elements.editMode" v-model='activityDetail.output'></textarea>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <div class="col-12">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="activityDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="activityDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="activityDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Add Reference">
                                        <div class="row">
                                            <div class="col-6">
                                                <label for="budget">Functions</label>
                                                <select id="durationDays" class="form-control">
                                                    <option value="">Functions...</option>
                                                    <option>1</option>
                                                    <option>2</option>
                                                </select>
                                            </div>
                                            <div class="col-6">
                                                <label for="budget" style="visibility: hidden;">Search</label>
                                                <div class="input-group ">
                                                    <input type="text" class="form-control" placeholder="Search">
                                                    <span class="input-group-text input-group-append input-group-addon">
                                                        <i class="fa fa-search"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <div class="row">
                                                <div class="col-12">
                                                    <label for="budget" class="mb-1">Reference Details</label>
                                                    <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                        <template v-slot:cell(title)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                            <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reference found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                    
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <label for="budget" class="mb-1">Objective Details</label>
                                                    <b-table show-empty small stacked="md" sort-icon-left :items="projects" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                                        <template v-slot:cell(title)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}">{{data.value}}</router-link>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <router-link title="View Project" :to="{ name: 'viewproject', params: { projectid: data.item.id }}"><i class="fa fa-eye"></i></router-link>
                                                            <a href="javascript:" title="Deactivate" @click="deleteProject(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                                                        </template>
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Objective found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddExistingActivityPopup v-if="showDialog" @refresh-lookup="getActivity" @closeDialog="showDialog=false;" />
        <DeleteComponent id="deleteActivity" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Activity ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import Editor from '@tinymce/tinymce-vue';
import AddExistingActivityPopup from '@/components/popup/lookup/AddExistingActivityPopup.vue';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
        AddExistingActivityPopup,
        Editor,
    },
    data() {
        return {
            showOverlay: false,
            showDetOverlay: false,
            showDialog: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,
            vhextAccountId: '',
            projectId: '',
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: '',
            userId: '',

            activity: {
                id: '',
                activityId: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                projectType: '',
                activity: '',
                input: '',
                task: '',
                validation: '',
                output: '',
                active: true,
                description: '',
                createdById: '',
                createdBy: '',
            
            },
            activityDetail: {
                activity: '',
                createdBy: '',
                description: '',
                input: '',
                task: '',
                validation: '',
                output: '',
                createdBy: '',
                createdById: '',
                active: '',
                activityId: '',
                projectTypeId: '',
                projectType: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            projectTypes: [],
            users: [],
            activityListAll: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            activityFields: [
                { key: 'activityId', label: 'Activity ID', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'projectType', label: 'Project Type', sortable: true, sortDirection: 'desc' },                
                { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            fields: [
                { key: 'scope_statement_id', label: 'Statement Id', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'in_scope', label: 'In Scope', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'created_dt', label: 'Created Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },

            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 200,
                branding: false,
                menubar: false,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.getActivity();
        this.lookupData();
    },
    validations: {
        activity: {
            activity: {
                required,
                maxLength: maxLength(2000)
            },
            projectTypeId: {
                required
            },                        
        },
        activityDetail: {
            activity: {
                required,
                maxLength: maxLength(2000)
            },
            projectTypeId: {
                required
            },                        
        }

    },
    methods: {
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.projectTypeId = localStorage.getItem("projectTypeId");
            this.entity = 'activity';
            this.userId = localStorage.getItem("userId");
            if (this.projectTypeId){
                this.activity.projectTypeId = this.projectTypeId;
                this.activityDetail.projectTypeId = this.projectTypeId;
            }
        },

        getActivity: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }

            axios.post(this.$store.getters.getAPIBasePath + '/general/activity/all/list',data)
                .then((response) => {
                    let result = response.data;
                    if (result.activityListAll && result.activityListAll.length > 0) {
                        this.activityListAll = result.activityListAll;
                        this.$nextTick(() => {
                            this.$refs.activityTable.selectRow(0);
                        })
                        this.totalRows = this.activityListAll.length;
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/activity/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTypes = result.projectTypeLookup;                                        
                    this.users = result.userLookup;                    
                })

        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        saveData: function() {
            this.activity.vhextAccountId = this.vhextAccountId;
            this.activity.projectId = this.projectId;
            this.activity.createdById = this.userId;

            this.$v.activity.$touch();
            if (!this.$v.activity.$invalid) {
                this.saveActivity();
            }
        },
        saveActivity: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/general/activity/add', this.activity)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getActivity();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        updateActivity: function() {
            this.activityDetail.vhextAccountId = this.vhextAccountId;
            this.activityDetail.projectId = this.projectId;
            this.showDetOverlay = true;

            axios.post(this.$store.getters.getAPIBasePath + '/general/activity/update', this.activityDetail)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        
                        this.getActivity();
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        viewActivity: function(id) {
            this.showDetOverlay = true;
            axios.get(this.$store.getters.getAPIBasePath + '/general/activity/view/' + id)
                .then((response) => {
                    this.showDetOverlay = false;
                    let result = response.data;
                    let activityDetail = result.activity;
                    if (activityDetail) {                        
                        this.activityDetail.id = activityDetail.id;
                        this.activityDetail.activity = activityDetail.activity;
                        this.activityDetail.activityId = activityDetail.activityId;
                        this.activityDetail.projectTypeId = activityDetail.projectTypeId;
                        this.activityDetail.createdBy = activityDetail.createdBy;
                        this.activityDetail.description = activityDetail.description;
                        this.activityDetail.input = activityDetail.input;
                        this.activityDetail.task = activityDetail.task;
                        this.activityDetail.validation = activityDetail.validation;
                        this.activityDetail.output = activityDetail.output;
                        this.activityDetail.active = activityDetail.active;
                        this.activityDetail.createdDt = activityDetail.createdDt;
                        this.activityDetail.lastUpdatedDt = activityDetail.lastUpdatedDt;
                    }
                })
                .catch(error => {
                    this.showDetOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewActivity(this.activityDetail.id);
        },
        deleteActivity: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/activity/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getActivity();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.activity.activity = '';
            this.activity.active = true;
            this.activity.description = '';
            this.activity.createdById = localStorage.getItem("userId");

            this.$v.activity.$reset();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.activityDetail.id = data.id;
                this.activityDetail.projectTypeId = data.projectTypeId;
                this.activityDetail.description = data.description;
                this.activityDetail.active = data.active;
                this.activityDetail.activity = data.activity;
                this.activityDetail.input = data.input;
                this.activityDetail.task = data.task;
                this.activityDetail.validation = data.validation;
                this.activityDetail.output = data.output;
                this.activityDetail.activityId = data.activityId;
                this.activityDetail.createdBy = data.createdBy;
                this.activityDetail.createdById = data.createdById;
                this.activityDetail.createdDt = data.createdDt;
                this.activityDetail.lastUpdatedDt = data.lastUpdatedDt;
            }

            console.log(items);
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 0;

                this.updateActivationState();

            } else {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 1;

                this.updateActivationState();
            }
            console.log(this.activity.id, this.activity.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.activity)
                .then((response) => {
                    console.log(this.activity.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.activity.active = true;
                    this.getActivity();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

    }
}
</script>
