<template>
    <div>
        <DefectPreventionPlanMasterList :ermMasterInfo="ermMasterInfo"></DefectPreventionPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import DefectPreventionPlanMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        DefectPreventionPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Defect Prevention Plan List",
                entity: 'defect_prevention_plan_master',
                refEntity: 'defect_prevention_plan',
                tooltip: 'New Defect Prevention Plan',
                redirectURLName: 'defectpreventionplan',
                newEntityMasterURL: 'dppmas',
                redirectURL: '/dppmas',
                actionName: 'Defect Prevention Plan',
                previewURLName: 'dpprep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>