<template>
    <div class="meetingMinutesReportListPopup">
        <b-modal id="meetingMinutesReportListPopup" ref="meetingMinutesReportListPopupRef" size="xl" @hide="closeDialog" modal-class="" title="Minutes of Meeting">
            <MOMReportViewComponent :momData="momData" ref="meetingMinuteViewRef" />
        </b-modal>
    </div>
</template>
<script>
import MOMReportViewComponent from '@/components/common/MOMReportViewComponent.vue';
export default {
    components: {
        MOMReportViewComponent
    },
    props: {
        momData: Object
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        getDetails: function(showAllMOM) {
            // this.loading = true;
            this.$bvModal.show('meetingMinutesReportListPopup');
            this.$nextTick(() => {
                console.log(this.momData)
                this.$refs.meetingMinuteViewRef.getMeetingMinute(showAllMOM);
            })
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>