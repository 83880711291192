<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Currency</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                            <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Currency</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div class="row mb-2">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="currency">Currency</label>
                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.currency.currency.$error }">
                                                                <input type="text" v-model="currency.currency" class="form-control" id="currency" placeholder="Currency">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                        <label v-if="$v.currency.currency.$error && !$v.currency.currency.required" class="text-danger">Please enter Currency</label>
                                                    	<label v-if="$v.currency.currency.$error && !$v.currency.currency.maxLength" class="text-danger">Currency must not exceed {{$v.currency.currency.$params.maxLength.max}} letters.</label>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="active">Active</label>
                                                        <div class="input-group ">
                                                            <b-form-checkbox class="custom-toggle" v-model="currency.active" name="check-button" switch>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6">
				                                    <div class="form-group">
				                                        <label for="code">Code</label>
				                                        <input type="text" v-model="currency.code" class="form-control" id="code" placeholder="Code">
				                                    </div>
				                                </div>
				                                <div class="col-6">
				                                    <div class="form-group">
				                                        <label for="symbol">Symbol</label>
				                                        <input type="text" v-model="currency.symbol" class="form-control" id="symbol" placeholder="Symbol">
				                                    </div>
				                                </div>
				                                <!-- <div class="col-6">
				                                    <div class="form-group">
				                                        <label for="hasAttachment">HasAttachment</label>
				                                        <input type="text" v-model="currency.hasAttachment" class="form-control" id="hasAttachment" placeholder="HasAttachment">
				                                    </div>
				                                </div> -->
                                            </div>
                                            <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="currencyTable" show-empty small stacked="md" selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="currencys"  @row-selected="onRowSelected" :fields="currencyFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                            	<template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>

                                <template v-slot:cell(active)="data">
                                    <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                    </b-form-checkbox>
                                </template>
                                <!--<template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>-->
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteCurrency(data.item.id)" data-toggle="modal" data-target="#deleteCurrency"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Currencies found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="currencys.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <div class="row input-group-label">
                                        <div class="col-5">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{ currencyDetail.currencyId }}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="updateCurrency" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="currency">Currency</label>
                                                <input type="text" class="form-control" id="currency" :disabled="!elements.editMode" v-model='currencyDetail.currency'>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label>Active</label>
                                                <b-form-checkbox class="custom-toggle" v-model="currencyDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="codeDetail">Code</label>
                                                <input type="text" class="form-control" id="codeDetail" :disabled="!elements.editMode" v-model='currencyDetail.code'>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="symbol">Symbol</label>
                                                <input type="text" class="form-control" id="symbol" :disabled="!elements.editMode" v-model='currencyDetail.symbol'>
                                            </div>
                                        </div>
                                        <!-- <div class="col-8">
                                            <div class="form-group">
                                                <label for="hasAttachment">HasAttachment</label>
                                                <input type="text" class="form-control" id="hasAttachment" :disabled="!elements.editMode" v-model='currencyDetail.hasAttachment'>
                                            </div>
                                        </div> -->
                                        <!-- <div class="col-12">
                                            <div class="form-group">
                                                <label for="description1">Description</label>
                                                <textarea class="form-control" id="description1" rows="4" :disabled="!elements.editMode" v-model='currencyDetail.description'></textarea>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="card-body p-0 my-2">
                                                <b-card no-body>
                                                    <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                        <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                            <label>More Details</label>
                                                        </label>
                                                    </b-card-header>
                                                    <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                        <b-card-body class="p-1 pb-0">
                                                            <div class="row">
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="startDate">Created Date</label>
                                                                        <div class="input-group" >
                                                                            <input type="text" class="form-control disabled" :value="currencyDetail.createdDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 col-sm-6">
                                                                    <div class="form-group">
                                                                        <label for="endDate">Last Updated Date</label>
                                                                        <div class="input-group">
                                                                            <input type="text" class="form-control disabled" :value="currencyDetail.lastUpdatedDt | formatDate" readonly>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </b-card-body>
                                                    </b-collapse>
                                                </b-card>
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteCurrency" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Activity ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entity: 'currency',

            currency: {
                id: '',
                currencyId: '',
                currency: '',
                code: '',
                symbol: '',
                hasAttachment: '',
                active: true,
                createdById: '',
                createdBy: '',
                createdDt: '',
                lastUpdatedDt: ''
            
            },
           
            currencyDetail: {
                currency: '',
                createdBy: '',
                createdById: '',
                active: '',
                currencyId: '',
                code: '',
                symbol: '',
                hasAttachment: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            notEnabled: true,
            //users: [],
            currencys: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            currencyFields: [
            	{key:'index',label: 'SN'},
                //{ key: 'currencyId', label: 'Currency ID', sortable: true, sortDirection: 'desc' },
                { key: 'currency', label: 'Currency', sortable: true, sortDirection: 'desc' },
                { key: 'code', label: 'Code', sortable: true, sortDirection: 'desc' },
                { key: 'symbol', label: 'Symbol', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 20,
            pageOptions: [ 10, 15, 20, 25],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getCurrency();
    },
    validations: {
        currency: {
            currency: {
                required,
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        getCurrency: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/general/currency/list')
                .then((response) => {
                    let result = response.data;
                    if (result.currencys && result.currencys.length > 0) {
                        this.currencys = result.currencys;
                        this.$nextTick(() => {
                            this.$refs.currencyTable.selectRow(0);
                        })
                        this.totalRows = this.currencys.length;
                    }
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        
        saveData: function() {
            this.$v.currency.$touch();
            if (!this.$v.currency.$invalid) {
                this.saveCurrency();
            }
        },
        saveCurrency: function() {
        	this.$v.currency.$touch();
            if (!this.$v.currency.$invalid) {
	            axios.post(this.$store.getters.getAPIBasePath + '/general/currency/add', this.currency)
	                .then((response) => {
	                    let result = response.data;
	                    if (result.status == "CREATED") {
	                        iziToast.success({
	                            title: 'Success',
	                            message: result.message,
	                            position: 'topRight'
	                        });
	                        this.clearData();
	                        this.getCurrency();
	                    }
	                })
	        }
        },
        updateCurrency: function() {
        	this.$v.currency.$touch();
            if (!this.$v.currency.$invalid) {
	            axios.post(this.$store.getters.getAPIBasePath + '/general/currency/update', this.currencyDetail)
	                .then((response) => {
	                    let result = response.data;
	                    this.elements.editMode = false;
	                    if (result.status == "CREATED") {
	                        iziToast.success({
	                            title: 'Success',
	                            message: result.message,
	                            position: 'topRight'
	                        });
	                        
	                        this.getCurrency();
	                    }
	                })
	        }
        },
        viewCurrency: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/general/currency/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let currencyDetail = result.currency;
                    if (currencyDetail) {                        
                        this.currencyDetail.id = currencyDetail.id;
                        this.currencyDetail.currency = currencyDetail.currency;
                        this.currencyDetail.currencyId = currencyDetail.currencyId;
                        this.currencyDetail.code = currencyDetail.code;
                        this.currencyDetail.symbol = currencyDetail.symbol;
                        this.currencyDetail.hasAttachment = currencyDetail.hasAttachment;
                        this.currencyDetail.createdBy = currencyDetail.createdBy;
                        this.currencyDetail.active = currencyDetail.active;
                        this.currencyDetail.createdDt = currencyDetail.createdDt;
                        this.currencyDetail.lastUpdatedDt = currencyDetail.lastUpdatedDt;
                    }
                })
            this.elements.editMode = false;
            this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewCurrency(this.currencyDetail.id);
        },
        deleteCurrency: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/currency/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getCurrency();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.currency.currency = '';
            this.currency.active = true;
            this.currency.createdById = localStorage.getItem("userId");

            this.$v.currency.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.visible = false;
            this.elements.editMode = true;
            this.currencyDetail.active = data.active;
            this.currencyDetail.currency = data.currency;
            this.currencyDetail.code = data.code;
            this.currencyDetail.symbol = data.symbol;
            this.currencyDetail.hasAttachment = data.hasAttachment;
            this.currencyDetail.createdById = data.createdById;
            this.currencyDetail.createdBy = data.createdBy;
            this.currencyDetail.id = data.id;
            this.currencyDetail.currencyId = data.currencyId;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.visible = false;
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.currencyDetail.id = data.id;
                this.currencyDetail.active = data.active;
                this.currencyDetail.currency = data.currency;
                this.currencyDetail.currencyId = data.currencyId;
                this.currencyDetail.code = data.code;
            	this.currencyDetail.symbol = data.symbol;
            	this.currencyDetail.hasAttachment = data.hasAttachment;
                this.currencyDetail.createdBy = data.createdBy;
                this.currencyDetail.createdById = data.createdById;
                this.currencyDetail.createdDt = data.createdDt;
                this.currencyDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.currency.entity = this.entity;
                this.currency.id = item.id;
                this.currency.active = 0;

                this.updateActivationState();

            } else {
                this.currency.entity = this.entity;
                this.currency.id = item.id;
                this.currency.active = 1;

                this.updateActivationState();
            }
            console.log(this.currency.id, this.currency.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.currency)
                .then((response) => {
                    console.log(this.currency.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.currency.active = true;
                    this.getCurrency();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },

    }
}
</script>
