<template>
    <div>
        <TSCMaster :ermMasterInfo="ermMasterInfo"></TSCMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import TSCMaster from '../../components/blocks/erm/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        TSCMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Test Case Master",
                entity: 'test_case_master',
                refEntity: 'test_case',
                redirectURL: '/tscmas/list',
                showTypeLookup:true
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>`