import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

export default {
    data() {
        return {
            userId: localStorage.getItem("userId"),

            stompClient: null,
        }
    },
    methods: {
        connect(onMessageCallback, onNotificationCallback) {
            // TODO: Implement autentication for Websocket connection
            const token = localStorage.getItem('userAuthData');
            if (token) {
                const socket = new SockJS(this.$store.getters.getAPIBasePath + '/chat');
                this.stompClient = Stomp.over(socket);

                const headers = {
                    Authorization: `Bearer ${token}`
                };
                
                this.stompClient.connect(headers, (frame) => {
                    console.log('Websocket Connected!!!');
                    
                    // Subscribe to the group chat topic to receive chat messages
                    this.stompClient.subscribe('/user/' + this.userId + '/topic/messages', message => {
                        if (onMessageCallback) {
                            onMessageCallback(JSON.parse(message.body));
                        }
                    });
            
                    // Subscribe to the notifications topic to receive notifications
                    this.stompClient.subscribe('/user/' + this.userId + '/topic/notifications', (notification) => {
                        if (onNotificationCallback) {
                            onNotificationCallback(JSON.parse(notification.body));
                        }
                    });
                }, 
                error => {
                    console.error('Websocket Connection Error: ', error);
                    // Reconnect after 5 seconds
                    setTimeout(() => {
                        this.connect();
                    }, this.reconnectInterval);
                });
            } else {
                console.error('No token found, unable to connect to WebSocket.');
            }
        },
        disconnect() {
            if (this.stompClient !== null) {
                this.stompClient.disconnect(() => {
                    console.log('Disconnected');
                });
            }
        },
        sendMessage(message) {
            this.stompClient.send('/app/sendMessage', {}, JSON.stringify(message));
        },
        // sendNotification(notification) {
        //     this.stompClient.send('/app/notify', {}, JSON.stringify(notification));
        // }
    }
};
