<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Issue Tracking</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode">New</span>
                                                <span v-if="elements.editMode && !elements.viewMode">Edit</span>
                                                <span v-if="elements.viewMode">View</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div v-if="!elements.viewMode">
                                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.issue.title.$error }">
                                                                        <input type="text" maxlength="100" class="form-control" v-model="issue.title" id="title" :class="{ 'is-invalid': $v.issue.title.$error }" placeholder="Enter Title">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <label v-if="$v.issue.title.$error && !$v.issue.title.minLength" class="text-danger">Title must have at least {{$v.issue.title.$params.minLength.min}} letters.</label>
                                                                    <label v-if="$v.issue.title.$error && !$v.issue.title.required" class="text-danger">Please enter Title</label>
                                                                    <label v-if="$v.issue.title.$error && !$v.issue.title.maxLength" class="text-danger">Title must not exceed {{$v.issue.title.$params.maxLength.max}} letters.</label>
                                                                </div>
                                                                <b-input-group v-if="elements.viewMode" :prepend="issue.defectDbId" class="mb-2">
                                                                    <b-form-input aria-label="First name" disabled v-model="issue.title"></b-form-input>
                                                                </b-input-group>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="issueTypeId">Issue Type</label>
                                                                <select  v-if="!elements.viewMode" v-model="issue.issueTypeId" class="form-control" id="issueTypeId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in issueTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <a :title="typeLookupTitle" v-if="!elements.viewMode" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>                                                            
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.type" id="title">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="functionality">Functionality</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.functionalityId" class="form-control" id="functionalityId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in functionalities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.functionality">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="activityId">Activity</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.activityId" class="form-control" id="activityId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in issueActivities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.activity">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="description">Description</label>
                                                                <!-- <textarea v-model="issue.description" :disabled="elements.viewMode" class="form-control" id="description" rows="4" maxlength="2000" required></textarea> -->
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="issue.description" :disabled="elements.viewMode"></editor>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="solution">Solution</label>
                                                                <textarea v-model="issue.solution" :disabled="elements.viewMode" class="form-control" id="solution" rows="4" maxlength="2000" required></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="actionTaken">Action Taken</label>
                                                                <textarea v-model="issue.actionTaken" :disabled="elements.viewMode" class="form-control" id="actionTaken" rows="4" maxlength="1000" required></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="reportedBy">Reported By</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.reportedById" class="form-control" id="reportedBy">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.reportedBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="solutionBy">Assigned To</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.solutionById" class="form-control" id="solutionBy">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.solutionBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="reportedDt">Reported Date</label>
                                                                <input v-if="!elements.viewMode" type="date" v-model="issue.reportedDt" class="form-control" id="reportedDt">
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" :value="issue.reportedDt | formatDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="solutionDt">Solution Date</label>
                                                                <input v-if="!elements.viewMode" type="date" v-model="issue.solutionDt" class="form-control" id="solutionDt">
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" :value="issue.solutionDt | formatDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="severityId">Severity</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.severityId" class="form-control" id="severityId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in severities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.severity">
                                                            </div>
                                                        </div>

                                                        <div class="col-2">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <select v-if="!elements.viewMode" v-model="issue.statusId" class="form-control" id="statusId">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in issueStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                                <input v-if="elements.viewMode" type="text" disabled class="form-control" v-model="issue.status">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" stacked="md" sort-icon-left :items="issues" :fields="issueFields"  responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" v-b-modal.referenceDetail @click="viewItemDetails(data.item)">
                                        {{ data.value }}
                                    </a>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteIssue(data.item.id)" v-b-modal.deleteIssue><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Issues found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="issue.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <StatusTracker ref="statusUpdate" :statusUpdate="statusUpdate" @updateParent="this.getIssue"></StatusTracker>
                    </div>
                </div>                
            </div>
        </div>
        <IssueDetailPopup v-if="showDialog" ref="issueDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />
        <TypeLookup ref="typeLookupModal" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>
        <DeleteComponent id="deleteIssue" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Issue?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import IssueDetailPopup from '@/components/popup/itemdetail/IssueDetailPopup.vue';
import StatusTracker from '@/components/common/StatusTracker.vue';
import Editor from '@tinymce/tinymce-vue';
export default {
    name: 'app',
    components: {
                StatusTracker,
                TypeLookup,
                Editor,
                IssueDetailPopup
            },
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            entity: 'issue',
            typeEntity: 'issue_type',
            userId: localStorage.getItem("userId"),

            typeLookupTitle:'Issue Type Settings',

            showDialog: false,

            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
            },
            issueStatus: [],
            users: [],
            issueTypes: [],
            severities: [],
            functionalities: [],
            issueActivities: [],
            issue: {
                id: '',
                issueId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'issue',
                entityId: '',
                entityRefId: '',
                title: '',
                issueTypeId: '',
                type: '',
                functionalityId: '',
                functionality: '',
                activityId: '',
                activity: '',
                description: '',
                solution: '',
                reportedById: localStorage.getItem("userId"),
                reportedBy: '',
                reportedDt: '',
                solutionById: '',
                solutionBy: '',
                solutionDt: '',
                actionTaken: '',
                severityId: '',
                severity: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasRefLink: '',
                hasComment: '',
                hasReview: '',
                reportedDt: '',
                solutionDt: ''
            },
            statusUpdate: {
                id: '',
                statusUpdateId: '',
                refId: '',
                refRecordId: '',
                refRecordTitle: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                refEntityId: '',
                refEntity: 'issue',
                statusById: '',
                statusBy: '',
                statusId: '',
                status: '',
                statusDt: new Date().toISOString().substr(0, 10),
                remarks: '',
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },

            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,

            issues: [],
            issueFields: [
                { key: 'issueId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'functionality', label: 'Functionality', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'reportedDt',label: 'Reported Date',formatter: "formatDate",sortable: true,sortDirection: 'desc' },
                { key: 'solutionBy', label: 'Assigned To', sortable: true, sortDirection: 'desc' },
                { key: 'solutionDt',label: 'Solution Date',formatter: "formatDate",sortable: true,sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
          
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20,25,50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            }
        }
    },
    mounted() {
        this.lookupData();
        this.getIssue();
    },
    validations: {
        issue: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.statusUpdate.projectId = this.projectId;
            this.statusUpdate.entityRecordMasterId = this.entityRecordMasterId;
            this.statusUpdate.statusById = this.userId;
        },
        getIssue: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/issue/list', data)
                .then((response) => {
                    let result = response.data;
                    this.issues = result.issueList;
                    this.totalRows = this.issues.length;
                    this.assignStatusUpdate(this.issues[0]);                    
                    console.log(this.issues);
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/issue/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.functionalities = result.requirementLookup;
                    this.issueActivities = result.activityLookup;
                    this.issueTypes = result.customTypeLookup;
                    this.issueStatus = result.statusLookup;
                    this.severities = result.severityLookup;
                    this.users = result.userLookup;
                })

        },
        saveData: function() {
            this.$v.issue.$touch();
            if (!this.$v.issue.$invalid) {
                if (this.elements.editMode) {
                    this.updateIssue();
                } else {
                    this.saveIssue();
                }
            }
        },
        saveIssue: function() {
            this.showOverlay = true;
            this.$v.issue.$touch();
            console.log(this.issue);
            if (!this.$v.issue.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/project/issue/add', this.issue)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getIssue();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateIssue: function() {
            this.showOverlay = true;
            this.$v.issue.$touch();
            if (!this.$v.issue.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/project/issue/update', this.issue)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getIssue();
                            this.clearData();
                        }
                    })
                    .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }

        },
        deleteIssue: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/project/issue/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getIssue();
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.issue.id = '';
            this.issue.issueId = '';
            this.issue.title = '';
            this.issue.issueTypeId = '';
            this.issue.type = '';
            this.issue.activityId = '';
            this.issue.activity = '';
            this.issue.functionalityId = '';
            this.issue.functionality = '';
            this.issue.description = '';
            this.issue.solution = '';
            this.issue.actionTaken = '';
            this.issue.reportedById = '';
            this.issue.reportedBy = '';
            this.issue.reportedDt = '';
            this.issue.solutionById = '';
            this.issue.solutionBy = '';
            this.issue.solutionDt = '';
            this.issue.reportedDt = '';
            this.issue.severityId = '';
            this.issue.severity = '';
            this.issue.statusId = '';
            this.issue.status = '';
            this.$v.issue.$reset();
        },
        showEdit: function(data) {
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.issue.id = data.id;
            this.issue.issueId = data.issueId;
            this.issue.vhextAccountId = data.vhextAccountId;
            this.issue.projectId = data.projectId;
            this.issue.entityRecordMasterId = data.entityRecordMasterId;
            this.issue.title = data.title;
            this.issue.issueTypeId = data.issueTypeId;
            this.issue.type = data.type;
            this.issue.activityId = data.activityId;
            this.issue.activity = data.activity;
            this.issue.functionalityId =data.functionalityId;
            this.issue.functionality =data.functionality;
            this.issue.description = data.description;
            this.issue.solution = data.solution;
            this.issue.actionTaken = data.actionTaken;
            this.issue.reportedById = data.reportedById;
            this.issue.reportedBy = data.reportedBy;
            this.issue.reportedDt = data.reportedDt;
            this.issue.solutionById = data.solutionById;
            this.issue.solutionBy = data.solutionBy;
            this.issue.solutionDt = data.solutionDt;
            this.issue.reportedDt = data.reportedDt;
            this.issue.severityId = data.severityId;
            this.issue.severity = data.severity;
            this.issue.statusId = data.statusId;
            this.issue.status = data.status;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.elements.editMode = false;
            this.issue.id = data.id;
            this.issue.issueId = data.issueId.toString();
            this.issue.vhextAccountId = data.vhextAccountId;
            this.issue.projectId = data.projectId;
            this.issue.entityRecordMasterId = data.entityRecordMasterId;
            this.issue.title = data.title;
            this.issue.issueId = data.issueId;
            this.issue.type = data.type;
            this.issue.activityId = data.activityId;
            this.issue.activity = data.activity;
            this.issue.functionalityId =data.functionalityId;
            this.issue.functionality =data.functionality;
            this.issue.description = data.description;
            this.issue.solution = data.solution;
            this.issue.actionTaken = data.actionTaken;
            this.issue.reportedById = data.reportedById;
            this.issue.reportedBy = data.reportedBy;
            this.issue.reportedDt = data.reportedDt;
            this.issue.solutionById = data.solutionById;
            this.issue.solutionBy = data.solutionBy;
            this.issue.solutionDt = data.solutionDt;
            this.issue.reportedDt = data.reportedDt;
            this.issue.severityId = data.severityId;
            this.issue.severity = data.severity;
            this.issue.statusId = data.statusId;
            this.issue.status = data.status;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.issueDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        },

        assignStatusUpdate: function(data) {
            this.$refs.statusUpdate.clearData();

            this.statusUpdate.id = '';
            this.statusUpdate.statusUpdateId = '';
            this.statusUpdate.refRecordId = data.id;
            this.statusUpdate.refId = data.issueId;
            this.statusUpdate.refRecordTitle = data.title;
            this.statusUpdate.statusById = this.userId;
            this.statusUpdate.statusId = data.statusId;
            this.statusUpdate.status = data.status;

            //call child component method
            this.$refs.statusUpdate.onTitleChange();
        },      

    }
}
</script>