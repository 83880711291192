<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Module Management</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span><i class="fa fa-plus-circle pr-2 action-link"></i>New Module</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="module">Module</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.module.module.$error }">
                                                                <input type="text" class="form-control" v-model="module.module" id="module" :class="{ 'is-invalid': $v.module.module.$error }" placeholder="Enter Module">
                                                                <div class="required-icon"></div>
                                                            </div>  
                                                            <label v-if="$v.module.module.$error && !$v.module.module.minLength" class="text-danger">Module title must have at least {{$v.module.module.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.module.module.$error && !$v.module.module.required" class="text-danger">Please enter Module</label>
                                                            <label v-if="$v.module.module.$error && !$v.module.module.maxLength" class="text-danger">Module must not exceed {{$v.module.module.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="vmxServiceId">VMX Service</label>
                                                            <select v-model="module.vmxServiceId" class="form-control" id="vmxServiceId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="moduleTypeId">Module Type</label>
                                                            <select v-model="module.moduleTypeId" class="form-control" id="moduleTypeId">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in moduleTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="form-group">
                                                            <label for="code">Code</label>
                                                            <input type="text" class="form-control" v-model="module.code" id="code" placeholder="Enter Code">
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Active</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="module.active" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Basic</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="module.isBasic" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label for="budget">Standard</label>
                                                            <div class="input-group ">
                                                                <b-form-checkbox class="custom-toggle" v-model="module.isStandard" name="check-button" switch>
                                                                </b-form-checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="budget">Description</label>
                                                            <textarea v-model="module.description" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                        </div>
                                                    </div> -->
                                                </div>
                                                <button type="submit" @click="saveModule" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <div class="form-row view-filter">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-9 col-sm-9">
                                                <b-input-group prepend="VMX Service" id="vmxServiceId" >
                                                    <select v-model="standardFilterData.vmxServiceId" class="form-control">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </b-input-group>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-3 col-sm-3">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>                                
                                </div>

                                <b-table ref="moduleTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="filteredModules"  @row-selected="onRowSelected" :fields="moduleFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <!-- <template v-slot:cell(moduleId)="data">
                                        <div v-if="data.item.id == idEdit">
                                            <input type="number" class="form-control" v-model="data.item.moduleId" placeholder="" @keyup.enter="updateId(data.item.id,data.item.moduleId)" @blur="cancelIdEdit()">
                                        </div>
                                        <div v-if="data.item.id !== idEdit" @dblclick="idEdit=data.item.id;idValue=data.item.moduleId"> {{ data.item.moduleId }}</div>
                                    </template> -->
                                    <template v-slot:cell(isBasic)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" >
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(isStandard)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" >
                                        </b-form-checkbox>
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>
                                    <!--<template v-slot:cell(title)="data">
                                        <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                    </template>-->
                                    <template v-slot:cell(actions)="data">
                                        <a href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                        <a href="javascript:" title="Delete" @click="deleteModule(data.item.id)" data-toggle="modal" data-target="#deleteModule"><i class="fa fa-trash pl-2"></i></a>
                                    </template>
                                    <template v-slot:empty="scope">
                                        <div class="text-center">No Modules found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                
                                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                <b-pagination v-if="moduleList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-overlay :show="showDetOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                                <b-tabs v-if="!elements.visible && elements.showDetail" content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div class="row input-group-label">
                                            <div class="col-5">
                                                <b-input-group prepend="Id" class="text mb-3">
                                                    <b-input-group-prepend is-text><b>{{moduleDetail.moduleId}}</b></b-input-group-prepend>
                                                </b-input-group>
                                            </div>
                                            <div class="col-md-2 col-sm-2 pl-0 mr-0 ml-auto">
                                                <div class="more-btns text-center">
                                                    <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                        <i class="fa fa-pencil" aria-hidden="true"></i>
                                                    </button>
                                                    <div v-if="elements.editMode">
                                                        <button type="submit" @click="updateModule" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                        <button type="submit" @click="cancelEditMode" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="module">Module</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.moduleDetail.module.$error }">
                                                        <input type="text" class="form-control" v-model="moduleDetail.module" :disabled="!elements.editMode" id="mainMenu" :class="{ 'is-invalid': $v.moduleDetail.module.$error }" placeholder="Enter Module Title">
                                                        <div class="required-icon"></div>
                                                    </div>  
                                                    <label v-if="$v.moduleDetail.module.$error && !$v.moduleDetail.module.minLength" class="text-danger">Module title must have at least {{$v.moduleDetail.module.$params.minLength.min}} letters.</label>
                                                    <label v-if="$v.moduleDetail.module.$error && !$v.moduleDetail.module.required" class="text-danger">Please enter Module title</label>
                                                    <label v-if="$v.moduleDetail.module.$error && !$v.moduleDetail.module.maxLength" class="text-danger">Module title must not exceed {{$v.moduleDetail.module.$params.maxLength.max}} letters.</label>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="vmxServiceId">VMX Service</label>
                                                    <select v-model="moduleDetail.vmxServiceId" :disabled="!elements.editMode" class="form-control" id="vmxServiceId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in vmxServiceLookup" :value="info.id">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="moduleTypeId">Module Type</label>
                                                    <select v-model="moduleDetail.moduleTypeId" :disabled="!elements.editMode"  class="form-control" id="moduleTypeId">
                                                        <option value="">Select</option>
                                                        <option v-for="(info, index) in moduleTypes" :value="info.typeLookupId">{{info.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="code">Code</label>
                                                    <input type="text" class="form-control" v-model="moduleDetail.code" :disabled="!elements.editMode" id="code" placeholder="Enter Code">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label>Active</label>
                                                    <b-form-checkbox class="custom-toggle" v-model="moduleDetail.active" :disabled="!elements.editMode" name="check-button" switch>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="budget">Basic</label>
                                                    <div class="input-group ">
                                                        <b-form-checkbox class="custom-toggle" v-model="moduleDetail.isBasic" :disabled="!elements.editMode" name="check-button" switch>
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="budget">Standard</label>
                                                    <div class="input-group ">
                                                        <b-form-checkbox class="custom-toggle" v-model="moduleDetail.isStandard" :disabled="!elements.editMode" name="check-button" switch>
                                                        </b-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-12">
                                                <div class="form-group">
                                                    <label for="module">Description</label>
                                                    <textarea v-model="moduleDetail.description" :disabled="!elements.editMode" maxlength="2000" class="form-control" rows="4" required></textarea>
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="card-body p-0 my-2">
                                                    <b-card no-body>
                                                        <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                            <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.moreDetail>
                                                                <label>More Details</label>
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="moreDetail" accordion="my-accordion1" role="tabpanel">
                                                            <b-card-body class="p-1 pb-0">
                                                                <div class="row">
                                                                    <!-- <div class="col-6">
                                                                        <div class="form-group">
                                                                            <label for="createdBy">Created By</label>
                                                                            <input type="text" class="form-control disabled" id="createdBy" v-model="moduleDetail.createdBy" readonly>
                                                                        </div>
                                                                    </div> -->
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="startDate">Created Date</label>
                                                                            <div class="input-group" >
                                                                                <input type="text" class="form-control disabled" :value="moduleDetail.createdDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 col-sm-6">
                                                                        <div class="form-group">
                                                                            <label for="endDate">Last Updated Date</label>
                                                                            <div class="input-group">
                                                                                <input type="text" class="form-control disabled" :value="moduleDetail.lastUpdatedDt | formatDate" readonly>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteModule" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Module?" />
    </div>

</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible:false
            },
            showOverlay: false,
            showDetOverlay: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entity: 'module',

            idValue: '',
            idEdit: '',
            mainTableArray: [],

            standardFilterData: {
                vmxServiceId: '',
            },

            module: {
                id: '',
                moduleId: '',
                vmxServiceId: '',
                moduleTypeId: '',
                module: '',
                code: '',
                active: true,
                isBasic: '',
                isStandard: '',
            },
            moduleDetail: {
                id: '',
                moduleId: '',
                vmxServiceId: '',
                moduleTypeId: '',
                module: '',
                code: '',
                active: true,
                isBasic: '',
                isStandard: '',
                createdDt: '',
                lastUpdatedDt: '',
            },

            vmxServiceLookup: [],
            moduleTypes: [],
            moduleList: [],

            moduleFields: [
                {key: 'index',label: 'SN'},
                { key: 'id', label: 'Pk ID', tdClass: 'table-cw-100', sortable: true, sortDirection: 'desc' },
                { key: 'vmxService', label: 'VMX Service', sortable: true, sortDirection: 'desc' },
                { key: 'moduleType', label: 'Module Type', sortable: true, sortDirection: 'desc' },
                { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'code', label: 'Code', sortable: true, sortDirection: 'desc' },
                // { key: 'isBasic', label: 'Basic', sortable: true, sortDirection: 'desc' },
                { key: 'isStandard', label: 'Standard', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                // { key: 'createdBy', label: 'Created By', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],


            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        filteredModules() {
            let vmxServiceId = this.standardFilterData.vmxServiceId;
            let filteredModules = [];

            let filterArray = [];

            if (vmxServiceId) {
                filterArray = this.moduleList.filter(data => {
                    if (data.vmxServiceId == vmxServiceId) {
                        return data;
                    }
                });
            }
            else filterArray = this.moduleList;

            filteredModules = filterArray;

            this.totalRows = filteredModules.length;
            return filteredModules;
        }
    },    
    mounted() {
        this.getModules();
        this.getLookupData();
    },
    validations: {
        module: {
            module: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        },
        moduleDetail: {
            module: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        getModules: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/module/list/all',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.moduleList && result.moduleList.length > 0) {
                        this.moduleList = result.moduleList;
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.moduleDetail.id, this.mainTableArray, 'moduleTable');
                        })
                        this.totalRows = this.moduleList.length;
                    }
                    this.clearData();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getLookupData: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/menu/module/lookup/all')
                .then((response) => {
                    let result = response.data;
                    this.vmxServiceLookup = result.vmxServiceLookup;
                    this.moduleTypes = result.typeLookup;
                })

        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveModule: function() {
            this.$v.module.$touch();
            if (!this.$v.module.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/menu/module/add', this.module)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getModules();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
            }
        },
        updateModule: function() {
            this.$v.moduleDetail.$touch();
            if (!this.$v.moduleDetail.$invalid) {
                this.showDetOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/menu/module/update', this.moduleDetail)
                    .then((response) => {
                        this.showDetOverlay = false;
                        let result = response.data;
                        this.elements.editMode = false;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.getModules();
                        }
                    })
                    .catch(error => {
                        this.showDetOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        viewModule: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/menu/module/view/' + id)
                .then((response) => {
                    let result = response.data;
                    let moduleDetail = result.module;
                    if (moduleDetail) {                        
                        this.moduleDetail.id = moduleDetail.id;
                        this.moduleDetail.moduleId = moduleDetail.moduleId;
                        this.moduleDetail.vmxServiceId = moduleDetail.vmxServiceId;
                        this.moduleDetail.moduleTypeId = moduleDetail.moduleTypeId;
                        this.moduleDetail.module = moduleDetail.module;
                        this.moduleDetail.code = moduleDetail.code;
                        this.moduleDetail.active = moduleDetail.active;
                        this.moduleDetail.isBasic = moduleDetail.isBasic;
                        this.moduleDetail.isStandard = moduleDetail.isStandard;
                        this.moduleDetail.createdDt = moduleDetail.createdDt;
                        this.moduleDetail.lastUpdatedDt = moduleDetail.lastUpdatedDt;
                    }
                })
                this.elements.editMode = false;
                this.elements.showDetail = true;
        },
        cancelEditMode: function() {
            this.viewModule(this.moduleDetail.id);
        },
        deleteModule: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            this.showOverlay = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/menu/module/delete/' + this.deleteParam.id)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getModules();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.module.id = '';
            this.module.moduleId = '';
            this.module.vmxServiceId = '';
            this.module.moduleTypeId = '';
            this.module.module = '';
            this.module.code = '';
            this.module.active = true;
            this.module.isBasic = false;
            this.module.isStandard = false;
            this.$v.module.$reset();
        },
        showEdit: function(data) {
            this.elements.visible = false;
            this.elements.editMode = true;
            this.moduleDetail.id = data.id;
            this.moduleDetail.moduleId = data.moduleId;
            this.moduleDetail.vmxServiceId = data.vmxServiceId;
            this.moduleDetail.moduleTypeId = data.moduleTypeId;
            this.moduleDetail.module = data.module;
            this.moduleDetail.code = data.code;
            this.moduleDetail.active = data.active;
            this.moduleDetail.isBasic = data.isBasic;
            this.moduleDetail.isStandard = data.isStandard;
            this.moduleDetail.createdDt = data.createdDt;
            this.moduleDetail.lastUpdatedDt = data.lastUpdatedDt;
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                this.elements.showDetail = true;
                this.moduleDetail.id = data.id;
                this.moduleDetail.moduleId = data.moduleId;
                this.moduleDetail.vmxServiceId = data.vmxServiceId;
                this.moduleDetail.moduleTypeId = data.moduleTypeId;
                this.moduleDetail.module = data.module;
                this.moduleDetail.code = data.code;
                this.moduleDetail.active = data.active;
                this.moduleDetail.isBasic = data.isBasic;
                this.moduleDetail.isStandard = data.isStandard;
                this.moduleDetail.createdDt = data.createdDt;
                this.moduleDetail.lastUpdatedDt = data.lastUpdatedDt;
            }
        },
        assignActivationState: function(item) {

            this.showOverlay = true;
            if (item.active == true) {
                this.module.entity = this.entity;
                this.module.id = item.id;
                this.module.active = 0;

                this.updateActivationState();

            } else if (item.active == false){
                this.module.entity = this.entity;
                this.module.id = item.id;
                this.module.active = 1;

                this.updateActivationState();
            }
            console.log(this.module.id, this.module.active);
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.module)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response;
                    console.log(result);
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.elements.showDetail = false;
                    this.module.active = true;
                    this.getModules();
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    console.log(error)
                })
        },
    }
}
</script>