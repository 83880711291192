<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Design</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">   
                                <div v-if="ermIsFreeze!='true'" id="design-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Design Document</span><span v-if="elements.editMode">Edit</span> </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div class="row mb-2">
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="title">Title</label>
                                                            <div class="required-field-block" :class="{ 'is-invalid': $v.design.title.$error }">
                                                                <input type="text" class="form-control" v-model="design.title" id="title" :class="{ 'is-invalid': $v.design.title.$error }" placeholder="Enter Title">
                                                                <div class="required-icon"></div>
                                                            </div>
                                                            <label v-if="$v.design.title.$error && !$v.design.title.minLength" class="text-danger">Title must have at least {{$v.design.title.$params.minLength.min}} letters.</label>
                                                            <label v-if="$v.design.title.$error && !$v.design.title.required" class="text-danger">Please enter Title</label>
                                                            <label v-if="$v.design.title.$error && !$v.design.title.maxLength" class="text-danger">Title must not exceed {{$v.design.title.$params.maxLength.max}} letters.</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="form-group">
                                                            <label for="designTypeId">Type</label>
                                                            <select v-model="design.designTypeId" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in designTypes" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                                        </div>
                                                    </div>
                                                    <div v-show="!elements.editMode" class="col-12">
                                                        <div class="form-group">
                                                            <div>
                                                                <label>Attachment </label>
                                                                <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2"></i>Add More</a>
                                                            </div>
                                                            <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                                <div class="attachments mb-2">
                                                                    <b-form-file class="attachments-list" size="sm" v-model="design.attachments[index]" placeholder="Choose a file.">
                                                                        <template slot="file-name" slot-scope="{ names }">
                                                                            <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                        </template>
                                                                    </b-form-file>
                                                                    <a href="javascript:" @click="removeFile(index)" class="float-right button-remove p-2 attachments-remove"><i class="fa fa-minus-circle pr-2"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="elements.editMode" class="col-12">
                                                        <div class="form-group">
                                                            <div>
                                                                <label for="exampleInputPassword1">Attachment </label>
                                                            </div>
                                                            <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                                <div class="attachments mb-2">
                                                                    <b-form-file ref="fileinput" class="attachments-list" size="sm" placeholder="Choose a file." v-model="design.attachments[index]">
                                                                        <template slot="file-name" slot-scope="{ names }">
                                                                            <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                        </template>
                                                                    </b-form-file>
                                                                    <a href="javascript:" title="Save attachment" @click="submitFiles(design.id)" class="float-right  p-2 attachments-remove action-link">
                                                                        <i class="fa fa-check pr-2"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-show="attachments.length != 0" class="col-12">
                                                            <div class="form-group">
                                                                <div>
                                                                    <p v-for="(attachment, index) in attachments">
                                                                        <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                                        <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                        <a href="javascript:" data-toggle="modal" data-target="#deleteAttachment" @click="deleteFile(attachment.id)" title="Delete attachment" class="float-right button-remove p-2 attachments-remove">
                                                                            <i class="fa fa-trash pr-2 action-link"></i>
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 pr-0">
                                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                            <label class="d-block  accordion-title" block href="#" v-b-toggle.accordion-2>
                                                                Description
                                                            </label>
                                                        </b-card-header>
                                                        <b-collapse id="accordion-2" accordion="desc-accordion" role="tabpanel">
                                                            <textarea v-model="design.description" class="form-control " rows="4" required></textarea>
                                                        </b-collapse>
                                                    </div>
                                                    <FunctionalReferencePopup ref="referenceModal" :entity="this.entity" :erdId="Number(design.id)" />
                                                </div>
                                                <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table ref="designTable" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="designs" @row-selected="onRowSelected" :fields="designFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(title)="data">
                                    <a href="javascript:" @click="bindDetail(data.item)">{{data.value}}</a>
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="bindDetail(data.item)"><i class="fa fa-eye fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Delete" @click="deleteDesign(data.item.id)" data-toggle="modal" data-target="#deleteDesign"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="design">
                                    <div class="text-center">No Design documents found</div>
                                </template>
                                <template v-slot:emptyfiltered="design">
                                    <div class="text-center">{{ design.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="designs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0 mb-4">
                    <div class="card h-100">
                        <div class="card-body p-3">
                            <b-tabs v-if="elements.viewMode || elements.editMode || elements.showDetail" content-class="mt-3" lazy>
                                <b-tab title="Details">
                                    <!--    <b-alert v-if="!showDetail" show>I'm Details Sections!</b-alert> -->
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="designId"> Id</label>
                                                <p><span class="badge badge-pill badge-blue mb-1">{{designDetail.designId}}</span></p>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="status"> Status</label>
                                                <p><span class="badge badge-pill badge-blue mb-1">{{designDetail.status}}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="title">Title</label>
                                                <input type="text" class="form-control" id="title" disabled v-model='designDetail.title'>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="type">Type</label>
                                                <input type="text" class="form-control" id="type" disabled v-model='designDetail.type'>
                                            </div>
                                        </div>
                                        <div v-show="attachments.length != 0" class="col-12">
                                            <div class="form-group">
                                                <div>
                                                    <label for="attachment">Attachment </label>
                                                    <p v-for="(attachment, index) in attachments">
                                                        <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                        <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="description">Description </label>
                                                <input type="text" class="form-control" id="description" disabled v-model='designDetail.description'>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="description">Reference</label>
                                                <ReferenceView ref="viewReferenceRef" :erdId="Number(designDetail.id)" :refEntity="entity" />
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="createdDt">Created Date</label>
                                                <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(designDetail.createdDt)">
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-group">
                                                <label for="lastUpdatedDt">Updated Date</label>
                                                <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(designDetail.lastUpdatedDt)">
                                            </div>
                                        </div>
                                    </div>
                                </b-tab>
                                <b-tab title="Add Reference">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-ref>
                                                <span>Reference Search</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-ref" role="tabpanel">
                                            <div class="row">
                                                <div class="col-5">
                                                    <div class="input-group dataTables_filter">
                                                        <input type="search" v-model="refFilter" class="form-control" placeholder="Search Reference">
                                                    </div>
                                                </div>
                                                <div class="float-left ml-5 pl-4">
                                                    <div class="dataTables_length">
                                                        <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="mb-0">
                                                            <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                        </b-form-group>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2">
                                                <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="projectERMList" :fields="projectERMListFields" responsive="sm" :current-page="currentPage" :per-page="perPageRef" :filter="refFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                    <template v-slot:cell(actions)="data">
                                                        <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                    </template>
                                                    <template v-slot:empty="">
                                                        <div class="text-center">No Reference found</div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="">
                                                        <div class="text-center">{{ }}</div>
                                                    </template>
                                                </b-table>
                                                <b-pagination v-if="projectERMList.length!=0" pills v-model="currentPage" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                            </div>
                                        </b-collapse>
                                    </b-card>
                                    <div class="mt-2">
                                        <label class="mb-1">References</label>
                                        <b-table show-empty small stacked="md" sort-icon-left :items="ermReferenceList" :fields="ermReferenceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                            <template v-slot:cell(actions)="data">
                                                <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" data-toggle="modal" data-target="#removeReference"><i class="fa fa-minus-circle pr-2"></i></a>
                                            </template>
                                            <template v-slot:empty="">
                                                <div class="text-center">No References Found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="">
                                                <div class="text-center">{{ }}</div>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle" @refresh-lookup="lookupData" />
        <Dialog id="deleteDesign" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete design?" />
        <Dialog id="deleteAttachment" :onYes="onAttachmentYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import ReferenceView from '@/components/common/ViewReferenceComponent.vue';
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue'
export default {
    name: 'app',
    components: {
        TypeLookup,
        FunctionalReferencePopup,
        ReferenceView,
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible: false
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeEntity: 'design_type',

            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),

            typeLookupTitle: 'Design Type Settings',

            ermIsFreeze: localStorage.getItem("ermIsFreeze"),
            
            addMore: [],
            attachments: [],
            entity: 'design',
            design: {
                entity: 'design',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entityRecordMasterId: this.$route.params.ermId,
                id: '',
                designId: '',
                title: '',
                designTypeId: '',
                type: '',
                description: '',
                preparedBy: '',
                uploadedDt: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                attachments: [],

            },
            designDetail: {
                id: '',
                designId: '',
                title: '',
                designTypeId: '',
                type: '',
                description: '',
                preparedById: '',
                preparedBy: '',
                uploadedDt: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: ''
            },
            addReference: {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityId: '',
                entityRecordId: '',
                ermId: '',
                erdId: '',
                refEntityId: '',
                refErmId: '',
                refErdId: '',
                title: '',
                refEntity: ''
            },
            ermId: this.$route.params.ermId,
            entityRecordMaster: [],
            projectERMList: [],
            ermReferenceList: [],
            notEnabled: true,
            designTypes: [],
            userLookup: [],
            statusLookup: [],
            designs: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            designFields: [
                { key: 'designId', label: 'Design Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Design Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Design Type', sortable: true, sortDirection: 'desc' },
                { key: 'createdDt', label: 'Uploaded Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }

            ],
            projectERMListFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            ermReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            totalRowsRef: 1,
            perPageRef: 5,
            refFilter: null,

            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0,
                attachmentId: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.getDesignList();
        this.lookupData();
        this.entityRecordMasterCollection(this.$route.params.ermId);
        this.getProjectEntityRecordMasterList();
        this.addMore.push(this.addMore.length + 1);
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if (collapseId == 'accordion-1') {
                if (isJustShown) {
                    if (!(this.elements.editMode || this.elements.viewMode)) {
                        this.elements.showDetail = false;
                    }
                }
            }
        })
    },
    validations: {
        design: {
            //designTypeId: { required },
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }

        }
    },
    methods: {
        getDesignList: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/design/list',data)
                .then((response) => {
                    let result = response.data;   
                    if (result.designList && result.designList.length > 0) {
                        this.designs = result.designList;
                        this.$nextTick(() => {
                            this.$refs.designTable.selectRow(0);
                        })
                        this.totalRows = this.designs.length;
                    }   
                })
        },
        entityRecordMasterCollection: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster = result.entityRecordMaster;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        getProjectEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/projectentityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectERMList = result.projectEntityRecordMasterList;
                    this.totalRowsRef = this.projectERMList.length;
                })
        },
        getERMReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmasterreference/list', data)
                .then((response) => {
                    let result = response.data;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPage = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        dateFilter: function(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/design/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.designTypes = result.customTypeLookup;
                    this.userLookup = result.userLookup;
                    this.statusLookup = result.statusLookup;

                })

        },
        saveData: function() {
            this.$v.design.$touch();
            if (!this.$v.design.$invalid) {
                if (this.elements.editMode) {
                    this.update();
                } else {
                    this.save();
                }
            }
        },
        save: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/design/add', this.design)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        let erdId = result.id;
                        console.log(this.$refs);
                        this.$refs.referenceModal.saveRefArray(erdId);
                        this.getDesignList();
                        this.clearData();
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        update: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/design/update', this.design)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    this.getDesignList();
                    this.clearData();
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        bindDetail: function(data) {
            this.attachments = [];
            this.getAttachment(data.id);
            this.elements.showDetail = true;
            this.designDetail.id = data.id;
            this.designDetail.designId = data.designId;
            this.designDetail.title = data.title;
            this.designDetail.designTypeId = data.designTypeId;
            this.designDetail.type = data.type;
            this.designDetail.description = data.description;
            this.designDetail.preparedById = data.preparedById;
            this.designDetail.preparedBy = data.preparedBy;
            this.designDetail.uploadedDt = data.createdDt;
            this.designDetail.statusId = data.statusId;
            this.designDetail.status = data.status;
            this.designDetail.createdDt = data.createdDt;
            this.designDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.$refs.viewReferenceRef.getERDReferenceList();


        },
        clearData: function() {
            this.elements.editMode = false;
            // this.elements.showDetail = false;
            this.elements.visible = false;
            this.design.designTypeId = '';
            this.design.description = '';
            this.design.title = '';
            this.design.id = '';
            this.$refs.referenceModal.clearReference();
            this.design.attachments = [];
            this.$v.design.$reset();
        },
        showEdit: function(data) {   
            this.getAttachment(data.id);
            this.elements.visible = true;
            this.elements.editMode = true;
            this.design.designTypeId = data.designTypeId;
            this.design.description = data.description;
            this.design.title = data.title;
            this.design.id = data.id;
        },
        onRowSelected: function(items) {
            if (items != null) {
                let data = items[0];      
                if (data != undefined)
                    this.bindDetail(data);
            }
        },
        deleteDesign: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/design/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getDesignList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            this.design.attachments.splice(index, 1);
        },
        submitFiles: function(designId) {
            if (this.elements.editMode && this.design.attachments.length == 0) {
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
                return;
            }

            let formData = new FormData();
            for (const row of Object.keys(this.design.attachments)) {
                formData.append('files', this.design.attachments[row]);
            }
            formData.append('vhextAccountId', this.design.vhextAccountId);
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', this.design.projectId);
            formData.append('refEntity', this.design.entity);
            formData.append('refRecordId', designId);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.design.attachments = [];
                    this.getAttachment(designId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function() {
            iziToast.success({
                title: 'Success',
                message: 'Design Created Successfully.',
                position: 'topRight'
            });
            this.getDesignList();
        },
        getAttachment: function(id) {
            let data = {
                vhextAccountId: this.design.vhextAccountId,
                projectId: this.design.projectId,
                refEntity: this.design.entity,
                refRecordId: id
            }    
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachments = result.attachmentList;
                })
        },
        deleteFile: function(id) {      
            this.deleteParam.attachmentId = id;
        },
        onAttachmentYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.attachmentId)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment(this.design.id);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        addRef: function(data) {             
            this.addReference.vhextAccountId = this.vhextAccountId;
            this.addReference.projectId = this.projectId;
            this.addReference.entityId = this.entityRecordMaster.entityId;
            this.addReference.entityRecordId = data.entityRecordMasterId;
            this.addReference.ermId = this.entityRecordMaster.id;
            this.addReference.erdId = '';
            this.addReference.refEntityId = data.entityId;
            this.addReference.refErmId = data.id;
            this.addReference.refErdId = data.refErdId;
            this.addReference.title = data.title;
            this.saveRef();
        },
        saveRef: function() {
            console.log(this.addReference);
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Reference Added',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

    }
}
</script>