<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h5 class="pl-2 mb-0 fs-16">Manage Entity</h5>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0  mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                <div class="row">
                    <div class="col-12">
                        <div class="row task-top-menu">
                            <div class="col pr-0">
                                <div class="dataTables_filter">
                                    <label>
                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                    </label>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-3 pl-0 mb-1 ml-0 text-left" >
                                <b-form-group label="Filter By" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">                                
                                    <select v-model="entity.entityTypeId" @change="getEntities()" class="form-control" id="entityTypeId">
                                        <option value="">All</option>
                                        <option v-for="(info, index) in entityTypes" :value="info.typeLookupId">{{info.value}}</option>
                                    </select>
                                </b-form-group>
                            </div>
                            <div class="col-lg-2 col-sm-2 mb-1">
                                <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                    <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                </b-form-group>                                                                       
                            </div>
                        </div>
                        <div class="separator mb-2">
                            
                        </div>
                    </div>
                </div>

                <b-table 
                show-empty small stacked="md" :items="entities" sort-icon-left :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                    <template v-slot:cell(displayName)="data">
                        <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                            <input type="text" class="form-control" :id="data.field.key+data.item.id" v-model="data.value" placeholder="" @keyup.enter="updateInlineTextValue('display_name', data.item.id, data.value)" @blur="cancelInlineEdit(entities, data.field.key)">
                        </div>
                        <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.value }}</div>
                    </template>       
                    <template v-slot:cell(title)="data">                            
                        <router-link title="View Entity" :to="{ name: 'viewentity', params: { entityid: data.item.id }}">{{data.value}}</router-link>
                    </template>
                    <template v-slot:cell(moduleId)="data">
                        <div v-if="data.item.id == inlineEditRowId && data.field.key == inlineEditFieldName">
                            <select :id="data.field.key+data.item.id" v-model="data.value" @change="updateInlineNumberValue('module_id', data.item.id, data.value)" class="form-control" @blur="cancelInlineEdit(entities, data.field.key)">
                                <!-- <option value="">Select</option> -->
                                <option v-for="(info, index) in modules" :value="info.id"
                                >{{info.value}}</option>
                            </select>
                        </div>
                        <div v-else @dblclick="focusInlineEdit(data.field.key+data.item.id, data.item.id, data.field.key, data.value)" class="action-link"> {{ data.item.module ? data.item.module : 'Select' }}</div>
                    </template>
                    <template v-slot:cell(masterEntity)="data">
                        <div v-if="data.item.id == idEdit" class="action-link">
                            <select v-model="masterEntity" @change="updateMasterEntity(data.item.id,masterEntity)" class="form-control" id="masterEntityId" @blur="cancelIdEdit()">
                                <option :value="{id:null,value:null}">Select</option>
                                <option v-for="(info, index) in masterEntities" :value="{id:info.id,value:info.entity}"
                                >{{info.entity}}</option>
                            </select>                            
                        </div>
                        <div v-else class="action-link" @dblclick="idEdit=data.item.id;masterEntity.id=data.item.masterEntityId;masterEntity.value=data.item.masterEntity"> 
                        {{ data.item.masterEntity ? data.item.masterEntity : 'Select' }}</div>
                    </template>
                    <template v-slot:cell(isCustom)="data">
                        <b-form-checkbox class="ml-3" v-model="data.value" @change="setCustom(data.item)">
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3" v-model="data.value" @change="setActive(data.item)">
                        </b-form-checkbox>
                    </template>

                    <template v-slot:cell(actions)="data">
                        <router-link title="View Entity" :to="{ name: 'viewentity', params: { entityid: data.item.id }}"><i class="fa fa-eye"></i></router-link>                                
                        <a href="javascript:" title="Deactivate" @click="deleteEntity(data.value.id)"><i class="fa fa-trash pl-2"></i></a>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Entities found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                
                <b-pagination v-if="entities.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>                    
                        </div>
                    </div>
                </div>
            </div>
            </b-overlay>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
export default {
    name: 'app',
    data() {
        return {

            idEdit: '',

            showOverlay: false,

            inlineEditRowId: '',
            inlineEditFieldName: '',
            inlineEditFieldValue: '',

            textValue: '',
            numberValue: '',

            masterEntity:{
                id: null,
                value: null,
            },
            entity: {
                id: '',
                entityId: '',
                displayName: '',
                code: '',
                entity: '',
                entityTypeId: '',
                type: '',
                shortName: '',
                entityDbName: '',
                masterEntityId: '',
                masterEntity: '',
                isCustom: '',
                active: '',
            },
            modules: [],
            masterEntities: [],
            entities: [],
            fields: [  
                { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc' },
                { key: 'moduleId', label: 'Module', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },                
                { key: 'displayName', label: 'Display Name', sortable: true, sortDirection: 'desc' },
                { key: 'code', label: 'Code', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Type', sortable: true, sortDirection: 'desc' },
                { key: 'shortName', label: 'Short Name', sortable: true, sortDirection: 'desc' },
                { key: 'entityDbName', label: 'DB Name', sortable: true, sortDirection: 'desc' },
                { key: 'masterEntity', label: 'Master Entity', tdClass: 'table-cw-200', sortable: true, sortDirection: 'desc' },
                { key: 'isCustom', label: 'Custom', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' } 
            ],
            entityTypes: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20, 25, 50],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            }
        }
    },
    computed: {
        sortOptions() {
        // Create an options list from our fields
            return this.fields
            .filter(f => f.sortable)
            .map(f => {
                return { text: f.label, value: f.key }
            })
        },

    },
    mounted() {
        this.getEntities();
        this.lookupData();        
    },
    methods: {
        getEntities: function() {
            let data = {
                entityTypeId: this.entity.entityTypeId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/general/entity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.entities = result.entityList;
                    this.totalRows = this.entities.length;
                    
                    this.masterEntities = result.entityList;
                    this.sortMasterEntities();
                    console.log(this.entities);
                })
        },
        updateMasterEntity: function(id,masterEntity) {
            let data = {
                id: id,
                masterEntityId: masterEntity.id,
            }
            console.log(data);
            this.masterEntity.id = masterEntity.id;
            this.masterEntity.value = masterEntity.value;
            
            axios.post(this.$store.getters.getAPIBasePath + '/general/entity/master/update', data)
                .then(response => {
                    var result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.setEntityMasterStatus(id);                        
                        this.cancelIdEdit();
                        // this.getEntities('');
                    }
                })
                .catch(error => {
                    console.log(error);
                });

        },
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/entity/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.modules = result.moduleLookup;
                    this.entityTypes = result.typeLookup;

                })
        },
        cancelIdEdit: function() {
            this.entities = this.entities.map(data => {
                if(data.id == this.idEdit) {
                    data.masterEntityId = this.masterEntity.id;
                    data.masterEntity = this.masterEntity.value;
                    
                    console.log(data,this.masterEntity.id,this.masterEntity.value);
                    return data;
                }
                else{
                    return data;
                }

            });
            this.idEdit='';
            this.masterEntity.id = null;
            this.masterEntity.value = null;

        },                

        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        updateInlineTextValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: 'entity',
                columnName: columnName,
                textValue: fieldValue,            
                id: rowId,  
            }
            console.log(data);
            // return;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/menu/update/textvalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        // this.clearInlineEdit();
                        this.getEntities();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },
        updateInlineNumberValue: function(columnName,rowId,fieldValue) {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: 0,
                entity: 'entity',
                columnName: columnName,
                numberValue: fieldValue,            
                id: rowId,  
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/update/numbervalue', data)
                .then(response => {
                    this.showOverlay = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.inlineEditRowId='';
                        this.getEntities();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                }) 
        },

        focusInlineEdit(cellId, rowId, fieldName, fieldValue) {
            console.log(cellId, rowId, fieldValue)
            this.inlineEditRowId = rowId;
            this.inlineEditFieldValue = fieldValue;
            this.inlineEditFieldName = fieldName;
            this.$nextTick(() => {
                document.getElementById(cellId).focus();
            })
        },
        cancelInlineEdit: function(items,fieldName) {
            console.log(items,fieldName);
            items = items.map(data => {
                if(data.id == this.inlineEditRowId) {
                    data[fieldName] = this.inlineEditFieldValue;
                    return data;
                }
                else {
                    return data;
                }
            });
            this.inlineEditRowId ='';
            this.inlineEditFieldValue ='';
            this.inlineEditFieldName ='';
        },        
        setActive: function(item) {
            this.isBusy = true;

            this.entity.entity = 'entity';
            this.entity.id = item.id;

            if (item.active == true) {
                this.entity.active = 0;
            } else {
                this.entity.active = 1;
            }

            this.updateActivationState();

        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.entity)
                .then((response) => {
                    console.log(this.entity.id);
                    this.isBusy = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    // this.elements.showDetail = false;
                    // this.activity.active = true;
                    this.getEntities();
                })
                .catch((error)=> {
                    this.isBusy = false;
                    console.log(error)
                })
        },


        deleteEntity : function(id){
          
        },
        setEntityMasterStatus: function(refRecordId) {
            this.entities = this.entities.map(data => {
                if(data.id == refRecordId) {
                    data.masterEntity = idValue;
                    return data;
                }
                else{
                    return data;
                }

            });
        },        

        sortMasterEntities() {
        
            this.masterEntities = this.masterEntities.sort((a,b) => {
                let fa = a.entity.toLowerCase(), fb = b.entity.toLowerCase();
                if (fa < fb) {
                    return -1
                }
                if (fa > fb) {
                    return 1
                }
                return 0
            })
        },
    }
}
</script>