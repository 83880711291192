<template>
    <div class="form-row">
        <div class="col-5">
            <div class="card">
                <div class="card-body p-3">
                    <HrEmployees ref="humanResources" @updateShowDetail="updateShowDetail" @updateHRCount="updateHRCount" @getHumanResourceDetail="getHumanResourceDetail" />
                </div>
            </div>
        </div>
        <div class="col-7">
            <div class="card h-100">
                <div class="card-body p-3">
                    <HumanResourceDetail ref="humanResourceDetail" @eventHRSave="refreshHumanResources" :showAccInfo="true"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import HrEmployees from '@/components/common/HrEmployeeComponent.vue';
import HumanResourceDetail from '@/components/common/HumanResourceDetail.vue';

export default {
    name: 'app',
    components: {
        HrEmployees,
        HumanResourceDetail,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            humanResourceId: null,
        }
    },
    computed: {
    },
    mounted() {
    },
    validations: {
    },
    methods: {
        getHumanResourceDetail(humanResourceId) {
            this.$refs.humanResourceDetail.getOtherHRDetails(humanResourceId);
            // this.$emit('getHumanResourceDetail', humanResourceId);
            // this.humanResourceId = humanResourceId;
        },
        updateHRCount: function(value) {
            this.$emit('updateHRCount', value);
        },
        refreshHumanResources(id) {
            this.$refs.humanResources.getHumanResource();
            // alert(id);
            // this.$refs.humanResources.showSelectedHR(id);
        },
        updateShowDetail: function(value) {
            this.$refs.humanResourceDetail.updateShowDetail(value);
        },

    }
}
</script>