<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Feedback Detail</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <div id="scope-app" class="mb-3">
                                <b-card no-body class="">
                                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1> 
                                            <span>View</span>
                                        </label>
                                    </b-card-header>
                                    <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                        <b-card-body class="">
                                            <div v-if="elements.viewMode" id="viewStatement">
                                                <div class="row">
                                                    <div class="col-12 col-sm-6">
                                                        <label for="title">Title</label>
                                                        <b-input-group :prepend="feedbackDetail.title" class="mb-2">
                                                            <b-form-input aria-label="title" disabled v-model="feedbackDetail.title"></b-form-input>
                                                        </b-input-group>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="statusId">Status</label>
                                                            <input type="text" class="form-control" id="statusId" disabled v-model="feedbackDetail.statusId">
                                                        </div>
                                                    </div>
                                                    <div class="col-12  col-sm-12">
                                                        <div class="form-group">
                                                            <label for="description">Description</label>
                                                            <textarea rows="4" class="form-control" id="description" disabled v-model="feedbackDetail.description"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="startDate">Created Date</label>
                                                            <div class="input-group date disabled-date" disabled>
                                                                <input type="text" class="form-control disabled" :value="feedbackDetail.createdDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-6">
                                                        <div class="form-group">
                                                            <label for="endDate">Updated Date</label>
                                                            <div class="input-group date disabled-date">
                                                                <input type="text" class="form-control disabled" :value="feedbackDetail.lastUpdatedDt | formatDate" readonly>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                            </div>
                                        </b-card-body>
                                    </b-collapse>
                                </b-card>
                            </div>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="feedbackForms" @row-selected="onRowSelected" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a href="javascript:" title="Delete" @click="deleteFeedbackForm(data.item.id)" data-toggle="modal" data-target="#deleteFeedbackForm"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Feedback Form found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="feedbackForms.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4 pl-0">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Feedback Detail" :active="elements.showDetail">
                                    <div class="row input-group-label">
                                        <div class="col-4">
                                            <b-input-group prepend="Id" class="text mb-3">
                                                <b-input-group-prepend is-text><b>{{feedbackDetail.feedbackFormId}}</b></b-input-group-prepend>
                                            </b-input-group>
                                        </div>
                                        <div class="col-6">
                                        </div>    
                                        <div class="col-md-2 col-sm-2 pl-0">
                                            <div class="more-btns text-center">
                                                <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </button>
                                                <div v-if="elements.editMode">
                                                    <button type="submit" @click="saveFeedbackDetail" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                                    <button type="submit" @click="clearData" title="Cancel" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-6">
                                            <div class="form-group">
                                                <label for="statusId">Feedback Status</label>
                                                <select v-if="elements.editMode" v-model="feedbackDetail.statusId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                                </select>
                                                <input v-if="!elements.editMode" class="form-control" type="text" v-model="feedbackDetail.status" disabled>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-12">
                                            <div class="form-group">
                                                <label for="feedback">Feedback</label>
                                                <textarea rows="4" class="form-control" id="feedback" v-model="feedbackDetail.feedback" :disabled="!elements.editMode"></textarea>     
                                            </div>
                                            <button type="submit" @click="saveFeedbackDetail" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                            <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                        </div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteFeedbackForm" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Feedback Form" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        ermId() 
            {
            return Number(this.$route.params.ermId);
            }
        },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            
            notEnabled: true,
            status: [],
            feedbackDetail:[],
            feedbackDetail: {
                id:'',
                title:'',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entity: 'feedback_detail',
                description: '',
                statusId: '',
                isPositive:'',
                feedback:'',
                clientId:'',
                qualityRating:'',
                feedbackDetailId:'',
                feedbackFormId:'',
                severityId:''

            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            feedbackForms: [],
            fields: [
                { key: 'feedbackFormId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection:'desc' },
                { key: 'description', label: 'Description', sortable: true, sortDirection: 'desc' },
                { key: 'statusId', label: 'Status', sortable: true, sortDirection:'desc' },
                { key: 'clientId', label: 'Client', sortable: true, sortDirection: 'desc' },  
                { key: 'actions', label: 'Actions' }

            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getFeedbackForm();
    },
    validations: {
        feedbackDetail: {
            feedback: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entity = 'feedback_detail';
        },

        getFeedbackForm: function() {
            let data = {
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackform/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.feedbackForms = result.feedbackFormList;
                    this.totalRows = this.feedbackForms.length;
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId:this.feedbackDetail.vhextAccountId,
                entity:this.feedbackDetail.entity,
                projectId:this.feedbackDetail.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackdetail/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.status = result.statusLookup;
                    console.log(result);
                })
        },
        saveFeedbackDetail: function() {
            console.log(this.feedbackDetail);
            axios.post(this.$store.getters.getAPIBasePath + '/general/feedbackdetail/add', this.feedbackDetail)
            .then((response) => {
                let result = response.data;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.getFeedbackForm();
                    this.clearData();
                }
            })
        },
        deleteFeedbackForm: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/general/feedbackform/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getFeedbackForm();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.feedbackDetail.title = '';
            this.feedbackDetail.feedback = '';
            this.feedbackDetail.description = '';
            this.feedbackDetail.statusId = '';
            this.feedbackDetail.createdDt = '';
            this.feedbackDetail.lastUpdatedDt = '';
            this.$v.feedbackDetail.$reset();
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.feedbackDetail.id = data.id;
            this.feedbackDetail.feedbackFormId=data.feedbackFormId;
            this.feedbackDetail.feedbackDetailId=data.feedbackDetailId;
            this.feedbackDetail.title = data.title;
            this.feedbackDetail.description = data.description;
            this.feedbackDetail.clientId = data.clientId;
            this.feedbackDetail.feedback = data.feedback;
            this.feedbackDetail.isPositive = data.isPositive;
            this.feedbackDetail.severityId = data.severityId;
            this.feedbackDetail.qualityRating = data.qualityRating;
            this.feedbackDetail.statusId = data.statusId;
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.feedbackDetail.feedbackDetailId=data.feedbackDetailId;
            this.feedbackDetail.feedbackFormId=data.feedbackFormId;
            this.feedbackDetail.title = data.title;
            this.feedbackDetail.description = data.description;
            this.feedbackDetail.createdDt = data.createdDt;
            this.feedbackDetail.lastUpdatedDt = data.lastUpdatedDt;
            this.feedbackDetail.statusId = data.statusId;
            this.feedbackDetail.feedback = data.feedback;

        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
                this.viewFeedbackDetail(data.feedbackFormId);
                
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.feedbackDetail.id = '';
                this.feedbackDetail.feedbackDetailId=data.feedbackDetailId;
                this.feedbackDetail.feedbackFormId=data.feedbackFormId;
                this.feedbackDetail.title = data.title;
                this.feedbackDetail.description = data.description;
                this.feedbackDetail.clientId = data.clientId;
                this.feedbackDetail.feedback = data.feedback;
                this.feedbackDetail.isPositive = data.isPositive;
                this.feedbackDetail.severityId = data.severityId;
                this.feedbackDetail.qualityRating = data.qualityRating;
                console.log(this.feedbackDetail.feedbackFormId);
            }
        },
        viewFeedbackDetail: function(id) {
            console.log(id);
            axios.get(this.$store.getters.getAPIBasePath + '/general/feedbackdetail/view/' + id)
                .then((response) => {
                    let result = response.data;
                    if (result.length != 0) {
                        this.feedbackDetail = result.feedbackDetail;
                        console.log(result);
                    }
                })

        }
    }
}
</script>