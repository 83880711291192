<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid p-0">
                <BudgetPlanReport />
            </div>
        </div>
    </div>
</template>
<script>
import BudgetPlanReport from '@/views/report/doc/BudgetPlanReport.vue';
export default {
    components: {
        BudgetPlanReport
    }
}
</script>