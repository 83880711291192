<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-2 col-sm-2 mt-2 pr-0 mr-0">
                            <h4 class="pl-2 mb-0 fs-16">Project Teams</h4>
                        </div>
                        <div class="col-sm-7 col-md-7 col-lg-7 mt-1 mb-1">
                            <b-button v-if="!isEmpty" class="addnew-tassk-btn float-right" variant="outline-info" @click="openReqPop" size="sm"><i class="fa fa-plus pr-1"></i>Create Team</b-button>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3 mt-2">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div v-if="isEmpty" class="col-12">
                    <div class="taskLists">                        
                        <div class="card-media">
                            <div class="card-media-body text-center w-100">
                                <h6>No Teams found</h6>
                                <b-button class="addnew-tassk-btn" variant="outline-info" @click="openReqPop" size="sm"><i class="fa fa-plus pr-1"></i>Create New Team</b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-12 col-xl-12 pr-2 position-relative">
                    <div class="row mr-0 pl-2">
                        <div v-for="ermData in entityRecordMasterList" class="col-12 mb-1 px-2" :class="teamListCols">
                            <div class="card mb-2 b-radius-5">
                                <div class="card-body px-3 py-2">
                                    <div class="form-group mb-0">
                                        <label class="mt-1 mb-0 font-weight-bold" for="title">
                                            {{ ermData.title }}
                                            <b-badge v-if="getTeamsByErmId(ermData.id).length>0" class="ml-2" title="Team Members">{{ getTeamsByErmId(ermData.id).length }}</b-badge>
                                        </label>
                                        <b-button class="pull-right addnew-quicktask-btn" @click="openAddTMBPopup(ermData.id, false)" variant="outline-info" size="sm">
                                            <i class="fa fa-plus pr-1"></i>New Member
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="taskLists">
                                <div class="row">
                                    <div :class="teamMemberCols" v-for="projectTeam in getTeamsByErmId(ermData.id)">
                                        <div class="card-media mb-1">
                                            <div class="card-media-body dropdown show">
                                                <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fa fa-ellipsis-v px-1 pb-1 pt-0"></i>
                                                </a>
                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                    <a v-if="projectTeam.isActive!==true && projectTeam.isActive!==false" class="dropdown-item  pb-1 py-1" @click="openUserActivationPop(projectTeam)" href="javascript:"><i class="fa fa-user-plus pr-2 mt-1 mb-0" aria-hidden="true"></i>Create User Account</a>
                                                    <a class="dropdown-item  py-1" @click="openAddTMBPopup(projectTeam, true)" href="javascript:"><i class="fa fa-pencil pr-2"></i>Edit Member</a>
                                                    <a class="dropdown-item  my-0 py-1" @click="viewItemDetails(projectTeam, ermData.title)" href="javascript:"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>View Details</a>
                                                    <a class="dropdown-item py-1" @click="deleteTeamMember(projectTeam.id, projectTeam.memberName)" data-toggle="modal" data-target="#deleteTeamMember" href="javascript:"><i class="fa fa-trash pr-2"></i>Remove Member</a>
                                                </div>
                                                <span v-if="projectTeam.isActive==true" title="Active User" class="subtle float-right mr-1"><i class="fa fa-user text-success" aria-hidden="true"></i></span>
                                                <span v-if="projectTeam.isActive==false" title="User not activated" class="subtle float-right mr-1"><i class="fa fa-user-times" aria-hidden="true"></i></span>
                                                <div class="card-media-body-top row d-flex">
                                                    <div class="col-auto pl-0 pr-1 mx-0">                                                        
                                                        <b-avatar v-if="projectTeam.memberName" variant="info" :text="projectTeam.memberName | getAcronym" class="d-flex mx-0 px-0"></b-avatar>
                                                    </div>
                                                    <div class="col-auto pl-0">
                                                        <a class="tooltiptext" href="javascript:" @click="viewItemDetails(projectTeam, ermData.title)">{{ projectTeam.memberName }}</a>
                                                        <div title="Position" class="subtle">{{ projectTeam.resourceType ? projectTeam.resourceType : '' }}</div>
                                                    </div>
                                                </div>
                                                <div class="divider-line"></div>
                                                <div class="container-fluid">
                                                    <div v-if="(projectTeam.emailId) || (projectTeam.phone != null)">
                                                        <div class="row">
                                                            <div v-if="projectTeam.phone"class="card-media-object-social-list col-12">
                                                                <span title="Phone" class="subtle"><i class="fa fa-phone action-link pl-3 p-1" aria-hidden="true"></i>{{ projectTeam.phone }}</span>
                                                            </div>
                                                            <div v-if="projectTeam.emailId" class="card-media-object-social-list col-12">
                                                                <span title="Email Id" class="subtle"><i class="fa fa-envelope action-link pl-3 p-1" aria-hidden="true"></i>{{ projectTeam.emailId }}</span>
                                                            </div>

                                                        </div>
                                                    </div>

<!--                                                     <div v-if="(projectTeam.emailId) || (projectTeam.phone != null)">
                                                        <div class="row">
                                                            <div v-if="projectTeam.phone" class="col-12">
                                                                <div class="card-media-object-social-list">
                                                                    <span title="Phone" class="subtle float-left"><i class="fa fa-phone action-link pr-1" aria-hidden="true"></i>{{ projectTeam.phone }}</span>
                                                                </div>
                                                            </div>
                                                            <div v-else class="col-12">
                                                                <div class="card-media-object-social-list">
                                                                    <span title="Email Id" class="subtle float-left"><i class="fa fa-envelope action-link pr-1" aria-hidden="true"></i>{{ projectTeam.emailId }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!getTeamsByErmId(ermData.id).length" class="card-media mb-2">
                                    <div class="card-media-body">
                                        <div class="stage-card text-center">No Members found</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddTeamMemberPopup ref="addTMBRef" @refreshData="getTeamMembers" :projectTeamData="projectTeam" />
        <UserActivationPopup v-if="showDialog" ref="userActivationPopupRef" :projectTeamData="projectTeam" @refreshData="getTeamMembers" @closeDialog="closeDialog" />
        <ProjectTeamDetailPopup ref="projectTeamDetailRef" :detailViewData="detailViewData" />
        <ERMMasterPopup v-if="showDialog" :ermMasterInfo="ermMasterInfo" @refresh-lookup="getProjectTeams" @closeDialog="closeDialog" />
        <Dialog id="deleteTeamMember" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" :message="`Are you sure to remove ${memberToDelete} from this Team?`" />        
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import ProjectTeamDetailPopup from '@/components/popup/itemdetail/ProjectTeamDetailPopup.vue';
import AddTeamMemberPopup from '@/components/popup/action/AddTeamMemberPopup.vue';
import UserActivationPopup from '@/components/popup/action/UserActivationPopup.vue';
import ERMMasterPopup from '@/components/popup/lookup/ERMMasterPopup.vue'

export default {
    name: 'app',
    components: {
        ProjectTeamDetailPopup,
        AddTeamMemberPopup,
        UserActivationPopup,
        ERMMasterPopup
    },
    data() {
        return {

            isEmpty: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            isArchive: false,
            entity: 'project_team_master',
            ermId: '',

            ermMasterInfo: {
                title: "Team",
                entity: 'project_team_master',
                refEntity: 'project_team',
            },

            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,
            typeLookupTitle:'Task Type Settings',

            projectTeam: {
                editMode: null,
                id: '',
                vmxServiceCode: localStorage.getItem("vmxServiceCode"),
                projectTeamId: '',
                customUserRoleId: '',
                userId:'',
                userTypeId:'',
                userType:'',
                userTypeCode:'',
                memberTypeId:'',
                memberTypeCode:'TMB',
                memberId:'',
                memberName: '',
                member: {
                    id:'',
                    text:'',
                    userId:'',
                },
                humanResourceId: '',
                resourceTypeId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                roleResponsibility: '',
                technologySkill: '',
                preparedBy: '',
                qualification: '',
                experienceYears: '',
                experienceMonths: '',
                requiredFrom: '',
                requiredTill: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false,
                jobTitle:"",
                resourcePlanId:"",
                ermTitle:""
            },
            timeSheetEditId: '',

            allTeamMembers: [],
            teamMembers: [],
            entityRecordMasterList: [],
            search: '',

            memberToDelete: '',
            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        filteredReqList() {
            return this.teamMembers.filter(teamMembers => {
                return teamMembers.title.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        teamListCols() {
            if (this.entityRecordMasterList && this.entityRecordMasterList.length) {

                if (this.entityRecordMasterList.length > 3) {
                    return 'col-lg-3 col-sm-6'
                }
                if (this.entityRecordMasterList.length == 3) {
                    return 'col-md-4'
                }
                if (this.entityRecordMasterList.length == 2) {
                    return 'col-sm-6'
                }
                if (this.entityRecordMasterList.length == 1) {
                    return 'col-md-12'
                }
            }
        },
        teamMemberCols() {
            if (this.entityRecordMasterList && this.entityRecordMasterList.length) {

                if (this.entityRecordMasterList.length > 2) {
                    return 'col-xl-12'
                }
                if (this.entityRecordMasterList.length == 2) {
                    return 'col-xl-6'
                }
                if (this.entityRecordMasterList.length == 1) {
                    return 'col-xl-3 col-lg-4 col-sm-6'
                }
            }
        }
    },
    mounted() {
        this.getTeamMembers();
        this.getProjectTeams();
    },
    methods: {
        getTeamMembers: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/list/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.teamMembers = result.projectTeamList;
                    this.allTeamMembers = this.teamMembers;
                    // this.$nextTick(() => {
                    //     this.requirementMasterFilter(this.requirementFilterId);
                    // })
                })
        },
        getProjectTeams: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.entity,
                isArchive: this.isArchive,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.isEmpty = result.entityRecordMasterList.length == 0 ? true : false;
                    if (result.entityRecordMasterList && result.entityRecordMasterList.length > 0) {                        
                        this.entityRecordMasterList = result.entityRecordMasterList;
                        this.ermId = this.entityRecordMasterList[0].id;
                        console.log(this.entityRecordMasterList.length)
                    }
                })
        },
        deleteTeamMember: function(id, member) {
            this.memberToDelete = member;
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/projectteam/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTeamMembers();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTeamsByErmId(ermId) {
            return this.teamMembers.filter(member => member.entityRecordMasterId == ermId);
        },
        requirementMasterFilter: function(ermId) {
            if (ermId !== null && ermId) {
                this.teamMembers = this.allTeamMembers;
                if (this.teamMembers != null) {
                    var requirement = this.teamMembers;
                    this.teamMembers = requirement.filter(function (req) {
                        return req.entityRecordMasterId == ermId;
                    });
                }
            }
            else if (ermId == '') {
                this.teamMembers = this.allTeamMembers;
            }
        },
        viewItemDetails: function(data, teamName) {
            this.detailViewData = data;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.projectTeamDetailRef.getDetails(teamName);
            })
        },
        closeDialog: function() {
            this.showDialog = false;
        },
        openAddTMBPopup: function(data, flag) {
            console.log(data)
            if (flag == true) {
                this.projectTeam.id = data.id;
                this.projectTeam.projectId = data.projectId;
                this.projectTeam.projectTeamId = data.projectTeamId;
                this.projectTeam.customUserRoleId = data.customUserRoleId;
                this.projectTeam.memberName = data.memberName;
                this.projectTeam.member.id = data.humanResourceId;
                this.projectTeam.member.text = data.memberName;
                this.projectTeam.member.userId = data.userId;
                this.projectTeam.projectMemberId = data.projectMemberId;
                this.projectTeam.humanResource = data.humanResource;
                this.projectTeam.humanResourceId = data.humanResourceId;
                this.projectTeam.emailId = data.emailId;
                this.projectTeam.phone = data.phone;
                this.projectTeam.position = data.position;
                this.projectTeam.roleResponsibility = data.roleResponsibility;
                this.projectTeam.technologySkill = data.technologySkill;
                this.projectTeam.preparedBy = data.preparedBy;
                this.projectTeam.preparedById = data.preparedById;
                this.projectTeam.qualification = data.qualification;
                this.projectTeam.experienceYears = data.experienceYears;
                this.projectTeam.experienceMonths = data.experienceMonths;
                this.projectTeam.requiredFrom = data.requiredFrom;
                this.projectTeam.requiredTill = data.requiredTill;
                this.projectTeam.scopeTypeId = data.scopeTypeId;
                this.projectTeam.statusId = data.statusId;
                this.projectTeam.entityRecordMasterId = data.entityRecordMasterId;
                this.projectTeam.vhextAccountId = data.vhextAccountId;
                this.projectTeam.resourceTypeId = data.resourceTypeId;
                this.projectTeam.jobTitle = data.resourcePlanId;
                this.projectTeam.jobTitle = {id: data.resourcePlanId, text:data.ermTitle + ' / ' + data.position};
                this.projectTeam.resourcePlanId = data.resourcePlanId;
                this.projectTeam.ermTitle = data.ermTitle;
                this.$refs.addTMBRef.getProjectTeam(data.entityRecordMasterId);
            } else {
                this.projectTeam.id = '';
                this.projectTeam.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.projectTeam.projectId = localStorage.getItem("projectId");
                this.projectTeam.entity = 'project_team';
                this.projectTeam.memberTypeCode = 'TMB';
                this.projectTeam.member.id = '';
                this.projectTeam.member.text = '';
                this.projectTeam.member.userId = '';
                this.projectTeam.resourceTypeId = '';
                this.projectTeam.jobTitle = '';
                this.projectTeam.emailId = '';
                this.projectTeam.position = '';
                this.projectTeam.requiredFrom = '';
                this.projectTeam.requiredTill = '';
                this.projectTeam.entityRecordMasterId = data;
                console.log(data)
                this.projectTeam.preparedById = localStorage.getItem("userId");
                this.$refs.addTMBRef.getProjectTeam(data);
            }
            console.log(this.projectTeam)
            this.projectTeam.editMode = flag;
            this.$nextTick(()=> {
                this.$bvModal.show('add-team-member');
            })
        },
        dueDateStyling(date){
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today)) {        
                    return 'text-warning';
                }
                else if (date < today) {        
                    return 'text-danger';
                }
                else if (date > today) {        
                    return 'text-success';
                }
            }
        },
        priorityColour(priority) {
            if (priority && priority == 'High') {
                return 'text-danger';
            }
            if (priority && priority == 'Medium') {
                return 'text-warning';
            }
            else {
                return 'text-success';
            }
        },
        openReqPop() {
            this.showDialog = true;
            this.$nextTick(()=> {
                this.$bvModal.show('ermMasterModal');
            })
        },
        openUserActivationPop(data) {
            this.showDialog = true;
            this.projectTeam = data;
            this.$nextTick(()=> {
                this.$bvModal.show('userActivationPopup');
            })
        },
    }
}
</script>
