<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-16">{{this.ermTitle}}</h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <h4 class="pl-2 mb-0 fs-14 text-center"><span class="text-muted">Client Requirement</span></h4>
                        </div>
                        <div class="col-lg-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div v-if="ermIsFreeze!='true'" id="scope-app" class="mb-3">
                                    <b-card no-body class="">
                                        <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                            <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-1>
                                                <span v-if="!elements.editMode && !elements.viewMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Requirements</span>
                                                <span v-if="elements.editMode && !elements.viewMode"> Edit Requirement</span> 
                                                <span v-if="elements.viewMode"> View Requirement</span>
                                            </label>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" accordion="my-accordion" v-model="elements.visible" role="tabpanel">
                                            <b-card-body class="">
                                                <div v-if="!elements.viewMode">
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="title">Title</label>
                                                                <div class="required-field-block" :class="{ 'is-invalid': $v.userRequirement.title.$error }">
                                                                    <input type="text" maxlength="100" class="form-control" ref="setFocus" v-model="userRequirement.title" id="title" :class="{ 'is-invalid': $v.userRequirement.title.$error }" placeholder="Enter Title">
                                                                    <div class="required-icon"></div>
                                                                </div>
                                                                <label v-if="$v.userRequirement.title.$error && !$v.userRequirement.title.minLength" class="text-danger">Title must have at least {{$v.userRequirement.title.$params.minLength.min}} letters.</label>
                                                                <label v-if="$v.userRequirement.title.$error && !$v.userRequirement.title.required" class="text-danger">Please enter Title</label>
                                                                <label v-if="$v.userRequirement.title.$error && !$v.userRequirement.title.maxLength" class="text-danger">Title must not exceed {{$v.userRequirement.title.$params.maxLength.max}} letters.</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="userRequirementTypeId">Requirement Type</label>
                                                                <select v-model="userRequirement.userRequirementTypeId" id="userRequirementTypeId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in userRequirementTypes" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-13">
                                                                <a :title="typeLookupTitle" class="setting-icon" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="userRequirementById">Requirement By</label>
                                                                <select v-model="userRequirement.userRequirementById" id="userRequirementById" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="preparedDt">Requirement Date</label>
                                                                <input v-model="userRequirement.preparedDt" id="preparedDt" type="date" class="form-control">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="userRequirement">Requirement</label>                                                            
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" v-model="userRequirement.userRequirement"></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-1">
                                                        <div class="col-12">
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                    <div class="row mb-2">
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="priorityId">Priority</label>
                                                                <select v-model="userRequirement.priorityId" id="priorityId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="dueDate">Due Date</label>
                                                                <input type="date" class="form-control" v-model="userRequirement.dueDate" id="dueDate">
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label for="statusId">Status</label>
                                                                <select v-model="userRequirement.statusId" id="statusId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        <FunctionalReferencePopup v-if="elements.editMode" ref="referenceModal" :entity="this.entity" :erdId="userRequirement.id" />
                                                    </div>
                                                    <button type="submit" @click="saveData" :disabled="showOverlay" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                                                    <button type="submit" @click="clearData" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                                <div v-if="elements.viewMode">
                                                    <div class="row">
                                                        <div class="col-12 col-sm-3">
                                                            <label for="title1">Title</label>
                                                            <b-input-group :prepend="userRequirementView.userRequirementId" class="mb-2">
                                                                <b-form-input aria-label="First name" id="title1" disabled v-model="userRequirementView.title"></b-form-input>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Requirement Type</label>
                                                                <input type="text" class="form-control" disabled v-model="userRequirementView.type">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Requirement By</label>
                                                                <input type="text" class="form-control" disabled v-model="userRequirementView.userRequirementBy">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Prepared Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control" disabled :value="userRequirementView.preparedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-sm-12">
                                                            <div class="form-group">
                                                                <label for="userRequirement">User Requirement</label>                                                            
                                                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :toolbar="toolbar" :init="initObj" disabled v-model="userRequirementView.userRequirement"></editor>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Priority</label>
                                                                <input type="text" class="form-control" disabled v-model="userRequirementView.priority">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <div class="form-group">
                                                                <label for="dueDate">Due Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="userRequirementView.dueDate | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-12 col-sm-3">
                                                            <div class="form-group">
                                                                <label>Status</label>
                                                                <input type="text" class="form-control" disabled v-model="userRequirementView.status">
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <div class="form-group">
                                                                <label for="startDate">Created Date</label>
                                                                <div class="input-group date disabled-date" disabled>
                                                                    <input type="text" class="form-control disabled" :value="userRequirementView.createdDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-sm-2">
                                                            <div class="form-group">
                                                                <label>Updated Date</label>
                                                                <div class="input-group date disabled-date">
                                                                    <input type="text" class="form-control disabled" :value="userRequirementView.lastUpdatedDt | formatDate" readonly>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Attachment</label>
                                                            <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" viewAttachment="true" :attachments="attachments" />
                                                        </div>
                                                    </div>
                                                    <button type="submit" @click="clearData" class="sbtn btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                                </div>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                            <div class="row view-filter">
                                <div class="col-sm-12">
                                    <div class="float-right">
                                        <div class="dataTables_length">
                                            <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                            </b-form-group>
                                        </div>
                                    </div>
                                    <div class="float-left">
                                        <div class="dataTables_filter">
                                            <label>
                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                            </label>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <b-table show-empty small stacked="md" sort-icon-left :items="userRequirements" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">

                                <template v-slot:cell(title)="data">
                                    <a class="tooltiptext action-link" href="#" @click="viewItemDetails(data.item)">{{data.value}}</a>
                                </template>

                                <template v-slot:cell(userRequirement)="data">
                                    <a class="tooltiptext" href="#" v-html="data.value" v-b-modal.htmlDetailView @click="htmlDetailView(data.item.userRequirement)"><!-- {{ToText(data.value)}} --></a>
                                </template>
                                <template v-slot:cell(status)="data">
                                    <div>
                                      <b-badge v-if="data.item.status == 'Draft'" variant="light">{{data.value}}</b-badge>
                                      <b-badge v-if="data.item.status == 'Open'" variant="primary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'In Progress'" variant="secondary">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Completed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Verified'" variant="info">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Closed'" variant="success">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Reopened'" variant="warning">{{data.value}}</b-badge>
                                      <b-badge v-else-if="data.item.status == 'Cancelled'" variant="danger">{{data.value}}</b-badge>
                                    </div>
                                </template>                                

                                <template v-slot:cell(actions)="data">
                                    <div class="dropdown dropright d-inline">
                                        <a title="Attachment" class="dropdown-toggle pl-2 fa fa-paperclip" href="#" @click="getAttachments(data.item.id)" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        </a>
                                        <div class="dropdown-menu">
                                            <b-dropdown-item v-if="attachmentList.length==0"></b-dropdown-item>
                                            <div v-if="attachmentList.length>0">
                                                <ul class="attachmentList-ul p-0 m-0">
                                                    <li v-for="attachment,index in attachmentList" :key="attachment.id" v-if="attachment.fileName && attachment.fileName !== null">
                                                        <a :href="attachment.attachmentPath" class="action-link" target="_blank">{{attachment.fileName}}</a>

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="javascript:" title="View" @click="showView(data.item)"><i class="fa fa-eye pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a v-if="ermIsFreeze!='true'" href="javascript:" title="Delete" @click="deleteUserRequirement(data.item.id)" v-b-modal.deleteUserRequirement><i class="fa fa-trash pl-2"></i></a>
                                </template>


<!--                                 <template v-slot:cell(userRequirement)="data">

                                    <a href="javascript:alert(data.value)" v-b-modal.htmlDetailView :htmlData="data.value" ><span class="tooltiptext" v-html="data.value" v-b-tooltip.hover :title="data.value" ></span></a>

                                </template>
 -->

                                <template v-slot:empty="scope">
                                    <div class="text-center">No Client Requirement Found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <b-pagination v-if="userRequirements.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ClientReqDetailPopup v-if="showDialog" ref="clientReqDetailPopupRef" :attachmentData="attachmentData" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />

        <TypeLookup ref="typeLookupModal" :projectTypeId="this.projectTypeId" :entity="this.typeEntity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>

        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />

        <DeleteComponent id="deleteUserRequirement" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Requirement" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import TypeLookup from '@/components/popup/lookup/TypeLookup.vue';
import FunctionalReferencePopup from '@/components/popup/reference/FunctionalReferencePopup.vue';
import HTMLDetailViewPopup from '@/components/popup/itemdetail/HTMLDetailViewPopup.vue';
import Editor from '@tinymce/tinymce-vue';
import AttachmentComponent from '@/components/common/AttachmentComponent.vue';
import ClientReqDetailPopup from '@/components/popup/itemdetail/ClientReqDetailPopup.vue';

export default {
    name: 'app',
    components: {
        ClientReqDetailPopup,
        TypeLookup,
        FunctionalReferencePopup,
        HTMLDetailViewPopup,        
        Editor,
        AttachmentComponent
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            htmlData: '',
            typeLookupTitle:'Client Requirement Type Settings',
            htmlDetailViewTitle: 'Client Requirement',  
                     
            showDialog: false,
            detailViewData: {},
            referencePopupId: 0,

            notEnabled: true,
            allUsers: [],
            userRequirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            requirementActivities: [],

            entity:'user_requirement',
            typeEntity: 'user_requirement_type',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            ermTitle: localStorage.getItem("ermTitle"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),

            ermIsFreeze: false,


            userRequirement: {
                id: '',
                title: '',
                userRequirementTypeId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedById: '',
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                hasReference: '',
                hasRefLink: '',
                hasAttachment: '',
                dueDate: '',
                statusId: '',
                userRequirement: '',
                userRequirementById: '',
            },
            masterRecordData: {
                id: '',
                title: '',
                type: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedBy: '',
                dueDate: '',
                status: '',
                htmlData: '',
            },

            userRequirementView: {
                userRequirementId: '',
                title: '',
                type: '',
                preparedBy: '',
                preparedDt: '',
                priority: '',
                dueDate: '',
                status: '',
                hasReference: '',
                hasRefLink: '',
                hasAttachment: '',
                userRequirement: '',
                userRequirementBy: ''
            },
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            userRequirements: [],
            fields: [
                { key: 'userRequirementId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true ,tdClass: 'table-title-col-size', sortDirection: 'desc' },
                { key: 'type', label: 'Requirement Type', sortable: true, sortDirection: 'desc' },
                { key: 'userRequirement', label: 'Client Requirement' ,tdClass: 'w-30', sortable: true, sortDirection: 'desc' },
                { key: 'priority', label: 'Priority', sortable: true, sortDirection: 'desc' },
                { key: 'preparedBy', label: 'Req. By', sortable: true ,tdClass: 'table-name-col-size', sortDirection: 'desc' },
                { key: 'preparedDt', label: 'Req. Date',formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'dueDate', label: 'Due Date', formatter: "formatDate", sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
            attachmentInfo:{
                title:'Attachment'
            },
            attachments: [], 
             
            attachmentList:[],
          
            menuSetting: {
                height: 300,
                menubar: false,
            },
            attachmentData: {},
        }
    },
    computed: {
        users() {
            if (this.userTypeCode == 'CLI') {
                return this.allUsers.filter((user) => user.id == this.userId)
            } else return this.allUsers;
        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
        this.getUserRequirements();
    },

    validations: {
        userRequirement: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.userId = localStorage.getItem("userId");
            this.userRequirement.userRequirementById = this.userId;
            this.ermIsFreeze = localStorage.getItem("ermIsFreeze");
        },

        getUserRequirements: function() {
             let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/list',data)
                .then((response) => {
                    let result = response.data;
                    this.userRequirements = result.userRequirementList;
                    this.totalRows = this.userRequirements.length;
                    console.log(this.userRequirements);
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.userRequirementTypes = result.customTypeLookup;
                    this.requirementStatus = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    this.requirementActivities = result.activityLookup;
                    this.allUsers = result.userLookup;
                    this.userRequirement.statusId = this.requirementStatus[0].id;
                })
        },
        async saveData() {
            this.userRequirement.vhextAccountId = this.vhextAccountId,
            this.userRequirement.projectId = this.projectId,
            this.userRequirement.entityRecordMasterId = this.entityRecordMasterId,
            this.userRequirement.preparedById = this.userId,
            this.$v.userRequirement.$touch();
            if (!this.$v.userRequirement.$invalid) {
                this.showOverlay = true;
                let result;
                if (this.elements.editMode) {
                    result = await this.updateUserRequirement();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, this.userRequirement.id);
                } else {
                    result = await this.saveUserRequirement();
                    await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
                }

                console.log('result',result);
                this.showOverlay = false;
                if (result.data.status == "CREATED") {                 
                    iziToast.success({
                        title: 'Success',
                        message: result.data.message,
                        position: 'topRight'
                    });
                    this.getUserRequirements();
                    this.clearData();
                }
            }
        },
        saveUserRequirement() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/add', this.userRequirement);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        updateUserRequirement: function() {
            try {
                return axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/update', this.userRequirement);
            }
            catch(error) {
                this.showOverlay = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        deleteUserRequirement: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserRequirements();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.userRequirement.userRequirementId = '';
            this.userRequirement.title = '';
            this.userRequirement.userRequirementTypeId = '';

            this.userRequirement.userRequirementById = this.userId;
            this.userRequirement.preparedById = '';            
            this.userRequirement.preparedDt = new Date().toISOString().substr(0, 10);
            this.userRequirement.priorityId = '';
            this.userRequirement.dueDate = '';
            this.userRequirement.statusId = this.requirementStatus[0].id;
            this.userRequirement.hasReference = '';
            this.userRequirement.hasRefLink = '';
            this.userRequirement.hasAttachment = '';
            this.userRequirement.userRequirement = '';
            this.$v.userRequirement.$reset();
            this.$refs.attachmentControl.clearAttachment();
            this.attachments = [];
        },
        showEdit: function(data) {
            console.log(data);
            this.elements.editMode = true;
            this.elements.visible = true;
            this.elements.viewMode = false;
            this.userRequirement.id = data.id;
            this.userRequirement.userRequirementId = data.userRequirementId;
            this.userRequirement.title = data.title;
            this.userRequirement.userRequirementTypeId = data.userRequirementTypeId;
            this.userRequirement.preparedById = data.preparedById;
            this.userRequirement.preparedDt = data.preparedDt;
            this.userRequirement.priorityId = data.priorityId;
            this.userRequirement.dueDate = data.dueDate;            
            this.userRequirement.statusId = data.statusId;
            this.userRequirement.hasReference = data.hasReference;
            this.userRequirement.hasRefLink = data.hasRefLink;
            this.userRequirement.hasAttachment = data.hasAttachment;
            this.userRequirement.userRequirement = data.userRequirement;
            this.userRequirement.userRequirementById = data.userRequirementById;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
            this.setFocus();
        },
        showView: function(data) {
            this.elements.visible = true;
            this.elements.viewMode = true;
            this.userRequirementView.id = data.id;
            this.userRequirementView.title = data.title;
            this.userRequirementView.userRequirementId = data.userRequirementId.toString();
            this.userRequirementView.userRequirement = data.userRequirement;
            this.userRequirementView.type = data.type;
            this.userRequirementView.preparedBy = data.preparedBy;
            this.userRequirementView.preparedDt = data.preparedDt;
            this.userRequirementView.priority = data.priority;
            this.userRequirementView.dueDate = data.dueDate;            
            this.userRequirementView.status = data.status;
            this.userRequirementView.hasReference = data.hasReference;
            this.userRequirementView.hasRefLink = data.hasRefLink;
            this.userRequirementView.hasAttachment = data.hasAttachment;
            this.userRequirementView.userRequirementBy = data.userRequirementBy;
            this.userRequirementView.createdDt = data.createdDt;
            this.userRequirementView.lastUpdatedDt = data.lastUpdatedDt;
            this.$nextTick(() => {
                this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, this.projectId);
            })
            this.setFocus();
        },
        htmlDetailView: function(data) {
            this.htmlData = data;
            console.log(data);
        },
        getAttachments: function( refRecordId) {
            let data = {
                refEntity: this.entity,
                refRecordId: refRecordId,
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            } 
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
        },
        viewItemDetails: function(data) {
            this.detailViewData = data;
            this.referencePopupId = data.id;
            this.attachmentData.refEntity = this.entity;
            this.attachmentData.refRecordId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.clientReqDetailPopupRef.getDetails(this.referencePopupId, null);
            })
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }

    }
}
</script>