import AddContract from '@/views/contract/AddContract.vue'
import ContractEdit from '@/views/contract/ContractEdit.vue'
import ContractView from '@/views/contract/ContractView.vue'
import ContractorView from '@/views/contract/ContractorView.vue'
import Expenditure from '@/views/contract/Expenditure.vue'
import ContractPayroll from '@/views/contract/ContractPayroll.vue'
import TaskBoard from '@/views/task/TaskBoard.vue'

export default [
    {
        path: '/landing/contract/add',
        name: 'addcontract',
        component: AddContract,
        meta: { layout: 'landing', accessedby: 'CRP|CRM|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/contract/edit',
        name: 'contractedit',
        component: ContractEdit,
        meta: { accessedby: 'CRP|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/contract/view',
        name: 'contractview',
        component: ContractView,
        meta: { accessedby: 'CRP|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/contractor/view',
        name: 'contractorview',
        component: ContractorView,
        meta: { accessedby: 'CRP|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/contract/expense',
        name: 'conexpend',
        component: Expenditure,
        meta: { accessedby: 'CRP|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/contract/payroll',
        name: 'conpayroll',
        component: ContractPayroll,
        meta: { accessedby: 'CRP|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
    {
        path: '/contract/tskbrd',
        name: 'contskbrd',
        component: TaskBoard,
        meta: { accessedby: 'CRP|PRM|EMP|TMB|CLI|CAM|VSA|HRM|FNM|SHL' }
    },
];