<template>
    <div>
        <div class="fixed-inner-header">
            <div class="container-fluid">
                <div class="row inner-header pt-2">
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-8 col-sm-6">
                                <h4 class="pl-2 mb-0 fs-16">Periodic Employee Salary Report</h4>
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                    <ol class="breadcrumb pt-0 mb-0">
                                        <li class="breadcrumb-item">
                                            <b-button  class="addnew-tassk-btn sbtn float-right ml-2 top-header-btn" variant="outline-primary" v-b-toggle.accordion-ett size="xs" :class="{'active' : elements.filterVisible}" :title="(elements.filterVisible ? 'Hide' : 'Show') + ' Filter'"><i class="fa fa-filter pr-2" aria-hidden="true"></i>{{ elements.filterVisible ? 'Hide' : 'Filter'}}</b-button>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2 action-link" aria-hidden="true"></i>Back</a>
                                        </li>
                                        <li class="breadcrumb-item">
                                            <a href="#" @click.prevent="print" title="Print"><i class="fa fa-print action-link"></i></a>
                                        </li>
                                        <li class="breadcrumb-item" title="Download">
                                            <a href="#" @click.prevent="print"><i class="fa fa-download action-link"></i></a>
                                        </li>
<!--                                         <li v-if="projId" class="breadcrumb-item">
                                            <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                        </li> -->
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div class="separator mb-2"></div>
                    </div>
                </div>
                <div id="printMe" class="row">
                    <div class="col-12 col-lg-12 col-xl-12 report-tables">
                        <b-card no-body :class="{'mb-2' : elements.filterVisible}">
                            <b-collapse id="accordion-ett" v-model="elements.filterVisible" accordion="my-accordion"  role="tabpanel">
                                <b-card-body class="p-2">
                                    <div class="comments-titel">
                                        <label class="d-block mt-0 mb-0"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</label>
                                    </div>
                                    <div class="comments-cox mt-2">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label for="preparedBy">Employee</label>
                                                    <div class="required-field-block" :class="{ 'is-invalid': $v.empId.$error }">
                                                        <select v-model="empId" class="form-control" :class="{ 'is-invalid': $v.empId.$error }">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in employees" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <div class="required-icon"></div>
                                                    </div>
                                                    <label v-if="$v.empId.$error && !$v.empId.required" class="text-danger">Please select an employee</label>
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Period From</label>
                                                    <input type="month" class="form-control" v-model="fromDate" id="dateWD" >
                                                </div>
                                            </div>
                                            <div class="col-4">
                                                <div class="form-group">
                                                    <label>Period Till</label>
                                                    <input type="month" class="form-control" v-model="tillDate">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-12">
                                                <button type="submit" @click="getPayrollList" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1"><i class="fa fa-filter pr-2" aria-hidden="true"></i>Filter</button>
                                                <button type="submit" @click="closeFilter" class="sbtn btn btn-primary mb-3 mr-2 pt-1 pb-1 pl-3 pr-3 mt-1 task-close-btn"><i class="fa fa-times pr-2"></i>Close </button>
                                                <a href="javascript:" title="Reset Filter" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <div v-if="!elements.filterVisible" class="card mb-2">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12 col-md-12">
                                        <div class="panel panel-default">
                                            <div class="panel-body">
                                                <div class="dl-horizontal">
                                                    <div class="form-row">
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-4 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Employee</label>
                                                                </div>
                                                                <div class="col-12 col-md-8 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">#{{ employeeId }} {{ employeeName }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-4 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Department</label>
                                                                </div>
                                                                <div class="col-12 col-md-8 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ department }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="row">
                                                                <div class="col-12 col-md-5 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Payroll Period</label>
                                                                </div>
                                                                <div class="col-12 col-md-7 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ getMonthYearAlt(startDate) }} <span class="px-1 font-weight-bold">To</span> {{ getMonthYearAlt(endDate) }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="row">
                                                                <div class="col-12 col-md-6 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report By</label>
                                                                </div>
                                                                <div class="col-12 col-md-6 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedBy }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-2">
                                                            <div class="row">
                                                                <div class="col-12 col-md-6 pr-0">
                                                                    <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Report Date</label>
                                                                </div>
                                                                <div class="col-12 col-md-6 pl-0">
                                                                    <label class="p-2 mb-0 d-block header-details">{{ preparedDate | formatDate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card mb-2">
                            <div class="card mb-2">
                                <div class="card-body p-3">
                                    <p class="contact-title"><span>Employee Net Salary Report</span></p>
                                    <div class="row">
                                        <div class="col-12 col-md-12">
                                            <div class="data-table-rows  data-tables-hide-filter">
                                                <b-table show-empty small stacked="md" sort-icon-left :busy="isBusy" :items="payrollSummary" :fields="payrollFields" foot-clone foot-variant=light no-footer-sorting responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                    <template #cell(index)="data">
                                                        {{ data.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(payrollPeriodStr)="data">
                                                        <div>{{ getMonthYearAlt(data.value) }}</div>
                                                    </template>
                                                    <template v-slot:cell(totalEarning)="data">
                                                        <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                    </template>

                                                    <template v-slot:cell(totalDeduction)="data">
                                                        <div class="text-right">{{ data.value.toFixed(2) }}</div>
                                                    </template>
                                                    <template #cell(netPay)="data">
                                                        <div class="text-right">{{ (data.item.totalEarning - data.item.totalDeduction).toFixed(2)}}
                                                        </div>
                                                    </template>
                                                    <template v-slot:emptyfiltered="scope">
                                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                    </template>

                                                    <template v-slot:foot()="data">
                                                        <span></span>
                                                    </template>

                                                    <template v-slot:foot(totalEarning)="data">
                                                        <div class="text-right font-weight-bold">Total Earnings : {{totalEarnings.toFixed(2)}}</div>
                                                    </template>
                                                    <template v-slot:foot(totalDeduction)="data">
                                                        <div class="text-right font-weight-bold">Total Deductions : {{totalDeductions.toFixed(2)}}</div>
                                                    </template>

                                                    <template v-slot:foot(netPay)="data">
                                                        <div class="text-right font-weight-bold">Total : {{(totalEarnings-totalDeductions).toFixed(2)}}</div>
                                                    </template>
                                                    <template v-slot:empty="erm">
                                                        <div class="text-center">No Record Found</div>
                                                    </template>
                                                </b-table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required } from "vuelidate/lib/validators";
export default {
    name: 'app',

    data() {
        return {
            elements: {
                filterVisible: true,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            preparedBy: this.$store.getters.currentUser.name,
            preparedDate: this.getDateValue(new Date()),
            empId: '',

            fromDate: this.getMonthYear(new Date()),
            tillDate: this.getMonthYear(new Date()),
            startDate: this.getMonthYear(new Date()),
            endDate: this.getMonthYear(new Date()),

            isBusy: false,
            employees: [],

            payrollSummary: [],
            payrollFields: [
                { key: 'index', label: 'SN' },
                { key: 'payrollPeriodStr', label: 'Payroll Period', sortable: true,sortDirection: 'desc' },
                { key: 'totalEarning', label: 'Earnings', sortable: true, sortDirection: 'desc' },
                { key: 'totalDeduction', label: 'Deductions', sortable: true, sortDirection: 'desc' },
                { key: 'netPay', label: 'Net Pay', sortable: true, sortDirection: 'desc' },
                { key: 'createdBy', label: 'Prepared By', sortable: true, sortDirection: 'desc' },
            ],

            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    computed: {
        totalEarnings() {
            return this.payrollSummary.reduce((accum, item) => {
                return accum + parseFloat(item.totalEarning ? item.totalEarning : 0)
            }, 0.00)
        },        
        totalDeductions() {
            return this.payrollSummary.reduce((accum, item) => {
                return accum + parseFloat(item.totalDeduction ? item.totalDeduction : 0)
            }, 0.00)
        },
        employeeId() {
            return this.payrollSummary.length > 0 ? this.payrollSummary[0].employeeId : '';
        },
        employeeName() {
            return this.payrollSummary.length > 0 ? this.payrollSummary[0].employeeName : '';
        },
        department() {
            return this.payrollSummary.length > 0 ? this.payrollSummary[0].department : '';
        },
    },
    validations: {
        empId: {
            required,
        }
    },
    mounted() {
        localStorage.removeItem("pageRedirectURL");
        this.lookupData();
    },
    methods: {
        getPayrollList: function() {
            this.$v.empId.$touch();
            if (!this.$v.empId.$invalid) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    employeeId: this.empId,
                    startDate: this.fromDate + "-01",
                    endDate: this.getLastDate(this.tillDate + "-11"),
                }
                this.isBusy = true;
                this.startDate = this.fromDate + "-11";
                this.endDate = this.getLastDate(this.tillDate + "-11");
                axios.post(this.$store.getters.getAPIBasePath + '/finance/netsalary/summary', data)
                    .then((response) => {
                        this.isBusy = false;
                        let result = response.data;
                        this.payrollSummary = result.netSalarySummary;

                        this.startDate = this.getMonthYear(this.startDate);
                        this.endDate = this.getMonthYear(this.endDate);
                        this.fromDate = this.getMonthYear(this.fromDate);
                        this.tillDate = this.getMonthYear(this.tillDate);
                    })
                    .catch(error => {
                        this.isBusy = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/employee', data)
                .then((response) => {
                    let result = response.data;
                    this.employees = result.employeeLookup;
                })
        },
        getLastDate(value) {
            var date = new Date(value);
            let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            return this.getDateValue(lastDay);
        },
        closeFilter: function() {    
            this.elements.filterVisible = false;
            this.fromDate = this.getMonthYear(this.startDate);
            this.tillDate = this.getMonthYear(this.endDate);
        },
        clearData: function() {    
            this.startDate = this.getMonthYear(new Date());
            this.endDate = this.getMonthYear(new Date());
            this.fromDate = this.getMonthYear(new Date());
            this.tillDate = this.getMonthYear(new Date());
            this.empId = '';
            this.$v.empId.$reset();
        },
        print: function() {
            this.$htmlToPaper('printMe');
        },

    }
}
</script>