<template>
    <div id="wrapper" class="h-100">
        <div id="login" class="h-100">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-sm-12 mx-auto my-auto">
                        <div class="align-middle login_div">
                            <div class="col-md-4 offset-md-4">
                                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="1" rounded="sm">
                                    <div v-if="showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-sm-12 clientinfo">
                                                    <p class="text-center">Thank You..!</p>
                                                    <p class="text-center">
                                                        <router-link :to="{ name: 'home' }" class="action-link">Click here to login</router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {

    computed: {
        emailUpdateKey() {
            return this.$route.params.key;
        }
    },
    data() {
        return {
            showForm: false,
            showOverlay: true,
            elements: {
                submitted: false
            },
            activation: {
                id: "",
                password: "",
                userTypeId: "",
            },
            userInfo: [],
            login: {
                loginName: '',
                password: ''
            },

        }
    },
    validations: {
        activation: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
        this.loadCheckAccessToken();
    },
    methods: {
        loadCheckAccessToken() {

            let data = {
                textValue: this.emailUpdateKey
            };
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/emailupdate/confirmation', data)
                .then(response => {
                    this.showOverlay = false;
                    let result = response.data;
                    iziToast.info({
                        // title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.showForm = true;
                })
                .catch(error => {
                    this.showOverlay = false;
                    // console.log(error);
                });
        },
        submitActivation: function() {
            this.elements.submitted = true;
            this.$v.activation.$touch();
            if (!this.$v.activation.$invalid) {
                this.updatePassword();
            }
        },
        updatePassword() {
            this.showOverlay = true;
            let data = {
                id: this.userInfo.userId,
                password: this.activation.password,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/update/password', data)
                .then(response => {
                    this.showOverlay = false;
                    this.performLogin();
                })
                .catch(error => {
                    this.showOverlay = false;
                    // console.log(error);
                });
        },
        performLogin() {
            var container = $("#app-container");
            var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile";
            container.removeClass(allMenuClassNames);
            this.login.loginName = this.userInfo.emailId;
            this.login.password = this.activation.password;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/authenticate', this.login)
                .then(response => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.result) {
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            this.$router.push({ path: defaultredirects.general });
                        });
                    } else {
                        iziToast.warning({
                            // title: 'Error Occurred',
                            // message: 'Invalid credentials provided',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Please try again',
                        position: 'topRight'
                    });
                });
        }
    },

}
</script>