<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 pb-1 mb-0 fs-16">Help Desk</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-lg-5 col-xl-5">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-tabs content-class="mt-3" lazy>
                                <b-tab title="Menu List">
                           
                                    <div class="row view-filter">
                                        <div class="col-sm-12">
                                            <div class="float-right">
                                                <div class="dataTables_length">
                                                    <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                                                        <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="float-left">
                                                <div class="dataTables_filter">
                                                    <label>
                                                        <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="clearfix"></div>
                                        </div>
                                    </div>
                                    <b-table ref="standardMenuTable" v-model="mainTableArray" show-empty small stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="standardMenuList" @row-selected="onRowSelected" :fields="standardMenuFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered">

                                        
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Menu found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
        
                                    <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>

                                    <b-pagination v-if="standardMenuList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </b-tab>
                            </b-tabs>

                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-xl-7">
                    <div class="card mb-4">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4" rounded="sm">
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Functional Info Setting">
                                    </b-tab>
                                </b-tabs>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DeleteComponent id="deleteEmploymentHistory" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Employment History?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    components: {
    },
    data() {
        return {
            showOverlay: false,
            elements: {
                visible: false,
                editMode: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            // entity: 'employment_history',

            employmentHistory: {
                id: '',
                employmentHistoryId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                employeeId: '',
                departmentId: '',
                designationId: '',
                jobCategoryId: '',
                salary: '',
                reportingToId: '',
                employmentChangeById: localStorage.getItem("userId"),
                wefDate: '',
                createdById: localStorage.getItem("userId"),
            },

            employeeId: '',
            employeeName: '',
            mainTableArray: [],

            attachmentInfo: {
                title: 'Attachment'
            },
            attachments: [],

            departments: [],
            designations: [],
            jobCategories: [],
            users: [],

            standardMenuList: [],
            standardMenuFields: [
                { key: 'subMenuId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'standardMenuType', label: 'Std Menu Type', sortable: true, sortDirection: 'desc' },
                // { key: 'module', label: 'Module', sortable: true, sortDirection: 'desc' },
                { key: 'mainMenu', label: 'Main Menu', sortable: true, sortDirection: 'desc' },
                { key: 'subMenu', label: 'Sub Menu', sortable: true, sortDirection: 'desc' },
                // { key: 'menuOrder', label: 'Menu Order', sortable: true, sortDirection: 'desc' },                
                // { key: 'basic', label: 'Basic', sortable: true, sortDirection: 'desc' },                
                // { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.getMenuList();
        // this.lookupData();
        // this.getUserMenu(localStorage.getItem("userId"));
    },
    validations: {
    },
    methods: {
        getMenuList: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/menu/standardmenu/list')
                .then((response) => {
                    let result = response.data;
                    this.standardMenuList = result.standardMenuList;
                    this.totalRows = this.standardMenuList.length;
                    if (this.standardMenuList.length>0) {
                        this.$nextTick(() => {
                            this.selectBTableRowById(this.employeeId, this.mainTableArray, 'standardMenuTable');
                        })
                    }
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.showOverlay = false;
                    this.departments = result.departmentLookup;
                    this.designations = result.designationLookup;
                    this.jobCategories = result.customCategoryLookup;
                    this.users = result.userLookup;

                }).catch((error)=> this.showOverlay = false);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        async saveData() {
            this.employmentHistory.employeeId = this.employeeId;
            this.showOverlay = true;
            let result;
            if (this.elements.editMode) {
                result = await this.updateHistory();
                await this.$refs.attachmentControl.saveAttachment(this.entity, this.employmentHistory.id);
            } else {
                result = await this.saveHistory();
                await this.$refs.attachmentControl.saveAttachment(this.entity, result.data.id);
            }

            this.showOverlay = false;
            if (result.data.status == "CREATED") {
                iziToast.success({
                    title: 'Success',
                    message: result.data.message,
                    position: 'topRight'
                });
                this.showOverlay = false;
                this.$emit('refreshData');
                this.$bvModal.show(this.id);
                this.clearData();
            }
        },
        saveHistory: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/add', this.employmentHistory)
        },
        updateHistory: function() {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/update', this.employmentHistory)
        },
        deleteEmploymentHistory: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            console.log(this.deleteParam.id);
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/employmenthistory/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            // this.elements.empHistoryVisible = true;
            this.employmentHistory.id = '';
            this.employmentHistory.employmentHistoryId = '';
            this.employmentHistory.employeeId = '';
            this.employmentHistory.departmentId = '';
            this.employmentHistory.designationId = '';
            this.employmentHistory.jobCategoryId = '';
            this.employmentHistory.salary = '';
            this.employmentHistory.reportingToId = '';
            this.employmentHistory.employmentChangeById = localStorage.getItem("userId");
            this.employmentHistory.wefDate = '';
            this.$refs.attachmentControl.clearAttachment();
            this.attachments = [];
            // this.$v.employmentHistory.$reset();
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items[0];
            }
        },
        // showEdit: function(data) {
        //     this.elements.editMode = true;
        //     this.elements.visible = true;
        //     this.employmentHistory.id = data.id;
        //     this.employmentHistory.serviceHistoryId = data.serviceHistoryId;
        //     this.employmentHistory.transactionId = data.transactionId;
        //     this.employmentHistory.vhextAccountId = data.vhextAccountId;
        //     this.employmentHistory.serviceUtilId = data.serviceUtilId;
        //     this.employmentHistory.title = data.title;
        //     this.employmentHistory.cost = data.cost;
        //     this.employmentHistory.startDate = data.startDate;
        //     this.employmentHistory.endDate = data.endDate;
        //     this.employmentHistory.servicePeriodTypeId = data.servicePeriodTypeId;
        //     this.employmentHistory.paymentDueDate = data.paymentDueDate;
        //     this.employmentHistory.paymentDate = data.paymentDate;
        //     this.employmentHistory.transactionById = data.transactionById;
        //     this.employmentHistory.statusId = data.statusId;
        //     this.employmentHistory.createdById = data.createdById;
        //     this.$nextTick(() => {
        //         this.$refs.attachmentControl.getAttachmentData(this.entity, data.id, this.vhextAccountId, null);
        //     })
        // },
        // onFiltered(filteredItems) {
        //     // Trigger pagination to update the number of buttons/pages due to filtering
        //     this.totalRows = filteredItems.length
        //     this.currentPage = 1
        // },
        moneyControlInput: function(value) {
            this.employmentHistory.salary = Math.abs(value);
        },
    }
}
</script>