<template>
    <div class="referenceDetailPopup">
        <b-modal id="issueDetailPopup" ref="issueDetailPopupRef" size="lg" @hide="closeDialog" modal-class="" title="Issue" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                    <div class="col-12 px-0 text-left">
                        <div class="card-body p-0 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12 px-1">
                                <div class="col-3 col-sm-3 p-0 m-0 px-0 float-right">
                                    <div class="float-right">
                                        <h6 class=""><label class="badge badge-secondary" title="Status">{{ popupData.status }}</label></h6>
                                    </div>
                                </div>
                                <div class="col-9 col-sm-9 mb-3 px-0">
                                    <div class="row mb-2">
                                        <div class="col-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.issueId }}</label>
                                        </div>
                                        <div class="col-10 pl-0">
                                            <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="popupData.type && popupData.severity" class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.type }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Severity</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.severity }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="popupData.functionality && popupData.activity" class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Functionality</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.functionality}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Activity</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.activity}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reported By</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.reportedBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Reported Date</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.reportedDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Issue Description</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="card mt-2 mb-0">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 ml-2"><span>Solution</span></p>
                                                <div class="row">
                                                    <div class="col-12 col-md-12 m-0 px-3">
                                                        <label class="p-2 mb-0 d-block header-details" v-html="popupData.solution"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="card mt-2 mb-3">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title mb-2"><span>Action Taken</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details">{{popupData.actionTaken}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 px-0">
                                    <div class="row mt-0 mb-2">
                                        <div class="col-12 col-md-2 pr-0">
                                            <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                                        </div>
                                        <div class="col-12 col-md-10 pl-0">
                                            <div class="col-12">
                                                <div class="mt-2">
                                                    <AttachmentView ref="viewAttachmentRef" :attachmentData="attachmentData" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 pr-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Solution By</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.solutionBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 pl-1">
                                        <div class="row mt-0 mb-2">
                                            <div class="col-5 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Solution Date</label>
                                            </div>
                                            <div class="col-7 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{popupData.solutionDt | formatDate}}</label>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';    
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object
    },
    components: {
        AttachmentView
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
                if (refEntity === "issue") {
                    this.$bvModal.show('issueDetailPopup');
                    axios.get(this.$store.getters.getAPIBasePath + '/project/issue/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result.length != 0) {
                            this.popupData = result.issueView;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$bvModal.show('issueDetailPopup');
                    this.loading = false;
                }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        }, 
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>