<template>
    <div id="wrapper" class="h-100">
        <div id="login" class="h-100">
            <div class="container-fluid h-100">
                <div class="row h-100">
                    <div class="col-sm-12 mx-auto my-auto">
                        <div class="align-middle login_div">
                            <div class="col-md-4 offset-md-4">
                                <b-overlay :show="showOverlay" spinner-variant="primary" opacity="1" rounded="sm">
                                    <div v-if="showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded-10 pos-rel">
                                        <a href="javascript:" class="logo text-center d-block mb-4 login-logo"><img src="@/assets/images/logo.png"></a>
                                        <div class="card p-3">
                                            <h4 class="text-color text-center" style="text-transfomargin-top:10px">Activation</h4>
                                            <form>
                                                <div class="row pt-4">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="required-field-block" :class="{ 'is-invalid': elements.submitted && $v.activation.$error }">
                                                                        <input type="password" name="Password" placeholder="Enter a new password" maxlength="45" size="30" v-model="activation.password" class="form-control box-none">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <div v-if="elements.submitted && $v.activation.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.activation.password.required">Password is required</span>
                                                                        <span v-if="!$v.activation.password.minLength">Password must be at least 6 characters</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="required-field-block" :class="{ 'is-invalid': elements.submitted && $v.activation.confirmPassword.$error }">
                                                                        <input type="password" v-model="activation.confirmPassword" maxlength="45" size="30" class="form-control" id="confirmPassword" placeholder="Confirm Password">
                                                                        <div class="required-icon"></div>
                                                                    </div>
                                                                    <div v-if="elements.submitted && $v.activation.confirmPassword.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.activation.confirmPassword.required">Confirm Password is required</span>
                                                                        <span v-else-if="!$v.activation.confirmPassword.sameAsPassword">Passwords must match</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bg-slategray lt wrap-reset text-left" style="background-color:#fff !important;">
                                                    <!-- <button type="button" @click="submitActivation" class="btn btn-primary pull-right">Submit</button> -->
                                                    <button type="button" @click="submitActivation" class="btn btn-primary float-right mb-0 mr-2 pt-1 pb-1 pl-3 pr-3">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div v-else class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                        <div class="card-body p-2">
                                            <div class="row">
                                                <div class="col-sm-12 clientinfo">
                                                    <p class="text-center">It looks like your account is already activated.</p>
                                                    <p class="text-center">
                                                        <router-link :to="{ name: 'home' }" class="action-link">Click here to login</router-link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {

    computed: {
        activationKey() {
            return this.$route.params.key;
        }
    },
    data() {
        return {
            showForm: false,
            showOverlay: true,
            elements: {
                submitted: false
            },
            activation: {
                id: "",
                password: "",
                userTypeId: "",
            },
            userInfo: [],
            login: {
                loginName: '',
                password: ''
            },

        }
    },
    validations: {
        activation: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
        this.loadCheckAccessToken(this.activationKey);
    },
    methods: {
        loadCheckAccessToken(activationKey) {

            let data = { "activationKey": this.activationKey };
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/checkaccesstoken', data)
                .then(response => {
                    this.showOverlay = false;
                    let result = response.data;

                    if (result.status) {
                        this.userInfo = result.user;
                        this.showForm = true;
                    } else {
                        iziToast.info({
                            // title: 'Success',
                            message: 'User already activated',
                            position: 'topRight'
                        });
                        this.showForm = false;
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    // console.log(error);
                });
        },
        submitActivation: function() {
            this.elements.submitted = true;
            this.$v.activation.$touch();
            if (!this.$v.activation.$invalid) {
                this.updatePassword();
            }
        },
        updatePassword() {
            this.showOverlay = true;
            let data = {
                id: this.userInfo.userId,
                password: this.activation.password,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/user/update/password', data)
                .then(response => {
                    this.showOverlay = false;
                    this.performLogin();
                })
                .catch(error => {
                    this.showOverlay = false;
                    // console.log(error);
                });
        },
        performLogin() {
            var container = $("#app-container");
            var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile";
            container.removeClass(allMenuClassNames);
            this.login.loginName = this.userInfo.emailId;
            this.login.password = this.activation.password;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/authenticate', this.login)
                .then(response => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.result) {
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            this.$router.push({ path: defaultredirects.general });
                        });
                    } else {
                        iziToast.warning({
                            // title: 'Error Occurred',
                            // message: 'Invalid credentials provided',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Please try again',
                        position: 'topRight'
                    });
                });
        }
    },

}
</script>