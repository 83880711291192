<template>
    <div>
        <ProjectStandardMasterList :ermMasterInfo="ermMasterInfo"></ProjectStandardMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectStandardMasterList from '../../components/blocks/erm/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ProjectStandardMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Project Standard List",
                entity: 'project_standard_master',
                refEntity: 'project_standard',
                tooltip: 'New Project Standard',
                redirectURLName: 'projectstandard',
                newEntityMasterURL: 'prsmas',
                redirectURL: '/prsmas',
                actionName: 'Project Standard',
                previewURLName: 'prsrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>