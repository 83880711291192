<template>
    <div class="referenceDetailPopup">
        <b-modal id="projectTeamDetailPopup" ref="projectTeamDetailRef" @hide="closeDialog" modal-class="" title="Team Member" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body p-0 px-0 mx-0">
                                <div class="col-lg-12 col-xl-12 px-2">
                                    <div class="profile-wrapper">
                                        <div class="profile-card js-profile-card row">
                                            <div class="profile-card__img">
                                                <span class="profile-card__img_txt two_chars">{{ popupData.memberName }}</span>
                                            </div>
                                            <div class="left_col col">
                                                <div class="profile-card__cnt js-profile-cnt">
                                                    <div class="profile-card__name">{{ popupData.memberName }}</div>
                                                    <div class="profile-card__txt">{{ popupData.position }},<br> <strong>{{ teamName }}</strong></div>
                                                    <div class="card-body pt-1">
                                                        <div v-if="popupData.phone != null && popupData.phone.length > 0" class="text-left">
                                                            <span class="profile-card-loc__icon">
                                                                <i class="fa fa-phone-square" aria-hidden="true"></i>
                                                            </span>
                                                            <span class="profile-card-loc__txt">
                                                            {{ popupData.phone }}
                                                            </span>
                                                        </div>
                                                        <div v-if="popupData.emailId != null && popupData.emailId.length > 0"  class="text-left text-break">
                                                            <span class="profile-card-loc__icon">
                                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                                            </span>
                                                            <span class="profile-card-loc__txt">
                                                            {{ popupData.emailId }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>                                            

                                            </div>
                                            <div class="right_col col">
                                                <div class="profile-card__cnt js-profile-cnt">
                                                    <div  v-if="popupData.requiredFrom != null && popupData.requiredFrom.length > 0"  class="followers mt-4">
                                                        <div class="follow_count col-10">Required From<i class="fa fa-calendar pl-1" aria-hidden="true"></i> {{ popupData.requiredFrom | formatDate }}</div>
                                                    </div>
                                                    <div v-if="popupData.requiredTill != null && popupData.requiredTill.length > 0"  class="following mt-2">
                                                        <div class="follow_count col-10">Required Till<i class="fa fa-calendar pl-2" aria-hidden="true"></i> {{ popupData.requiredTill | formatDate }}</div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
<!--                                         <div class="profile-card js-profile-card row">
                                        </div>
 -->
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
            teamName: '',
        }
    },
    methods: {
        getDetails: function(teamName) {
            this.teamName = teamName;
            this.loading = true;
            this.$nextTick(() => {
                this.popupData = this.detailViewData;
            });
            this.$bvModal.show('projectTeamDetailPopup');
            this.loading = false;
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>