<template>
    <b-modal id="costEstimationPopup" ref="costEstimationPopupRef" size="xl" modal-class="costEstimationPopup" @hide="closeDialog" title="Cost Estimation Sheet" no-close-on-esc no-close-on-backdrop>
        <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0.4">
            <div class="row mb-auto w-100 mx-0">
                <div class="col-12 px-0 text-left">
                    <div class="form-row">
                        <div class="col-md-3">
                            <label for="csTitle">Item Title</label>
                            <div class="required-field-block" :class="{ 'is-invalid': $v.costEstimation.title.$error }">
                                <input type="text" v-model="costEstimation.title" class="form-control" :class="{ 'is-invalid': $v.costEstimation.title.$error }" id="csTitle">
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.minLength" class="text-danger">Item Title must have at least {{$v.costEstimation.title.$params.minLength.min}} letters.</label>
                            <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.required" class="text-danger">Please enter Item Title</label>
                            <label v-if="$v.costEstimation.title.$error && !$v.costEstimation.title.maxLength" class="text-danger">Item Title must not exceed {{$v.costEstimation.title.$params.maxLength.max}} letters.</label>
                        </div>
                        <div class="col-md-2">
                            <label for="csQuantity">Quantity</label>
                            <div class="mb-2">
                                <input type="number" v-model="costEstimation.quantity" @input="setTotalCost" class="form-control" min="0" id="csQuantity">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <label for="csQuantity">Unit</label>
                            <select v-model="costEstimation.unitOfMesurement" class="form-control">
                                <option :value="{id: '', text:''}">Select</option>
                                <option v-for="(info, index) in uomTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <label for="csPrice">Unit Cost</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                    </div>
                                </div>
                                <input type="number" v-model="costEstimation.unitCost" @input="setTotalCost" class="form-control" min="0" id="csPrice">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label for="csPrice">Total Cost</label>
                            <div class="input-group mb-2">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="mx-auto">{{ currencySymbol }}</span>
                                    </div>
                                </div>
                                <input type="number" v-model="costEstimation.cost" class="form-control" min="0" id="csPrice">
                            </div>
                        </div>
                        <!-- <div class="col-md-3 mr-0 text-right mt-1">
                            <label style="visibility: hidden;">Total</label>
                            <div class="font-weight-bold">
                                <span class="pr-2">Total</span>{{ currencySymbol }} {{ costEstimation.quantity * costEstimation.unitCost }}
                            </div>
                        </div> -->
                        <div class="col-12 text-right mt-2">
                            <div class="text-right">
                                <button type="submit" @click="addCostEstimationBulk" class="sbtn btn btn-primary btn-xs px-2 mr-1"><i v-if="!elements.editMode" class="fa fa-plus-circle pr-1"></i>{{ elements.editMode ? 'Update' : 'Add' }}</button>
                                <button type="submit" @click="clearData" class="sbtn btn btn-outline-primary btn-xs px-2"><i class="fa fa-times pr-1"></i>{{ elements.editMode ? 'Cancel' : 'Clear' }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body p-0 px-0 my-3">
                        <b-overlay :show="tableBusy" opacity="0.4">
                            <b-table show-empty small :items="costEstimationItems" :fields="costEstimationFields" foot-clone no-footer-sorting responsive="sm">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(total)="data">
                                    {{ currencySymbol }} {{ data.item.cost }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Edit" class="" @click="showEdit(data.item,data.index)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                                    <a href="javascript:" title="Remove Item" class="" @click="removeCostEstimation(data.item)"><i class="fa fa-trash pl-2"></i></a>
                                </template>
                                <template  v-slot:foot()="data">
                                    <span></span>
                                </template>
                                <template v-slot:foot(unitCost)="data">
                                    <div class="text-right">
                                        <span>Total </span>
                                    </div>
                                </template>
                                <template v-slot:foot(cost)="data">
                                    <div class="col-sm-7 col-md-7 pl-0 text-right">
                                        <span>{{ currencySymbol }} {{ totalExpenses.toFixed(2)}}</span>
                                    </div>
                                </template>
                                <template v-slot:empty="">
                                    <div class="text-center">Add items to show</div>
                                </template>
                                <template v-slot:emptyfiltered="">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                            <template #overlay>
                                <div class="text-center">
                                </div>
                            </template>
                        </b-overlay>
                    </div>
                </div>
            </div>
        </b-overlay>
        <template #modal-footer>
            <div class="w-100">
                <b-button class="float-right" variant="secondary" size="sm" @click="closeDialog">
                    Cancel
                </b-button>
                <b-button v-if="updateFromGrid" :disabled="showOverlay" variant="primary" size="sm" @click="saveCostEstimation" class="float-right mr-2">
                    Save
                </b-button>
                <b-button v-else variant="primary" :disabled="showOverlay" size="sm" @click="saveBulkData" class="float-right mr-2">
                    Ok
                </b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        erdId: [Number, String],
        refEntity: String,
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            userId: localStorage.getItem("userId"),
            currencySymbol: localStorage.getItem("currency"),

            elements: {
                editMode: false,
            },
            editIndex: null,
            tableBusy: false,
            updateFromGrid: false,
            showOverlay: false,

            costEstimation: {
                id: '',
                costEstimationId: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.entityRecordMasterId,
                entityId: '',
                erdId: this.erdId,
                title: '',
                description: '',
                quantity: '',
                unitOfMesurement: {
                    id: '',
                    text: '',
                },
                uomTypeId: '',
                uomType: '',
                cost: '',
                unitCost: '',
                preparedById: this.userId,
                costEstimationTypeId: '',
                csCategoryId: '',
            },

            budgetPlanData: {
                id: '',
                estimatedCost: '',
                costEstimationDataList: []
            },

            uomTypes: [],
            costEstimationsBulk: [],

            costEstimations: [],
            costEstimationFields: [
                { key: 'index', label: 'SN' },
                { key: 'title', label: 'Item Title' },
                { key: 'quantity', label: 'Quantity' },
                { key: 'uomType', label: 'Unit' },
                { key: 'unitCost', label: 'Unit Cost' },
                { key: 'cost', label: 'Total Cost' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    validations: {
        costEstimation: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    computed: {
        costEstimationItems() {
                return this.costEstimationsBulk;
        },
        totalExpenses() {
            return this.costEstimationItems.reduce((accum, item) => {
                return accum + parseFloat(item.cost ? item.cost : 0)
            }, 0.00)
        }
    },
    mounted() {
        // this.getCostEstimation();
        this.lookupData();
    },
    methods: {
        getCostEstimation: function(updateFromGrid) {
            this.updateFromGrid = updateFromGrid;

            if (this.erdId && this.erdId !== null) {
                let data = {
                    entityRecordMasterId: this.entityRecordMasterId,
                    erdId: this.erdId
                }
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/list', data)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        this.costEstimationsBulk = result.costEstimationList;
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }
            else this.costEstimationsBulk = [];
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.uomTypes = result.customTypeLookup;
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })

        },
        saveCostEstimation: function() {
            this.budgetPlanData.costEstimationDataList = this.costEstimationsBulk;
            this.budgetPlanData.id = this.erdId;
            this.budgetPlanData.estimatedCost = this.totalExpenses;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/costestimation/bulk/modify', this.budgetPlanData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refreshData');
                        this.clearData();
                        this.clearBudgetData();
                        this.closeDialog();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        addCostEstimationBulk: function() {
            console.log(this.costEstimation);
            this.$v.costEstimation.$touch();
            if (!this.$v.costEstimation.$invalid) {
                let data = {};
                data.id = this.costEstimation.id;
                data.costEstimationId = this.costEstimation.costEstimationId;
                data.title = this.costEstimation.title;
                data.quantity = this.costEstimation.quantity;
                data.uomType = this.costEstimation.unitOfMesurement.text
                data.uomTypeId = this.costEstimation.unitOfMesurement.id;
                data.unitCost = this.costEstimation.unitCost;
                data.cost = this.costEstimation.cost;
                data.description = this.costEstimation.description;
                data.preparedById = this.costEstimation.preparedById;
                data.costEstimationTypeId = this.costEstimation.costEstimationTypeId;
                data.csCategoryId = this.costEstimation.csCategoryId;
                data.vhextAccountId = this.vhextAccountId;
                data.projectId = this.projectId;
                data.entityRecordMasterId = this.entityRecordMasterId;
                data.entityId = this.costEstimation.entityId;
                data.erdId = this.erdId;
                
                if (this.elements.editMode) {                    
                    this.costEstimationsBulk.splice(this.editIndex, 1);
                    this.costEstimationsBulk.splice(this.editIndex, 0, data);
                    this.$nextTick(() => {
                        this.clearData();
                    })
                }
                else {
                    this.costEstimationsBulk.push(data);
                    this.$nextTick(() => {
                        this.clearData();
                    })
                }
            }
        },
        saveBulkData: function() {
            this.$emit('saveCostEstimationList',this.costEstimationsBulk, this.totalExpenses);
            this.$bvModal.hide('costEstimationPopup');
        },
        showEdit: function(data, index) {
            this.elements.editMode = true;
            this.tableBusy = true;
            this.editIndex = index;
            console.log(data,index);
            this.costEstimation.id = data.id;
            this.costEstimation.costEstimationId = data.costEstimationId;
            this.costEstimation.vhextAccountId = data.vhextAccountId;
            this.costEstimation.projectId = data.projectId;
            this.costEstimation.entityRecordMasterId = data.entityRecordMasterId;
            this.costEstimation.entityId = data.entityId;
            this.costEstimation.erdId = data.erdId;
            this.costEstimation.title = data.title;
            this.costEstimation.description = data.description;
            this.costEstimation.quantity = data.quantity;
            this.costEstimation.unitOfMesurement.id = data.uomTypeId;
            this.costEstimation.unitOfMesurement.text = data.uomType;
            this.costEstimation.uomTypeId = data.uomTypeId;
            this.costEstimation.uomType = data.uomType;
            this.costEstimation.cost = data.cost;
            this.costEstimation.unitCost = data.unitCost;
            this.costEstimation.preparedById = data.preparedById;
            this.costEstimation.costEstimationTypeId = data.costEstimationTypeId;
            this.costEstimation.csCategoryId = data.csCategoryId;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.tableBusy = false;
            this.editIndex = null;
            this.costEstimation.id = '';
            this.costEstimation.costEstimationId = '';
            this.costEstimation.title = '';
            this.costEstimation.description = '';
            this.costEstimation.quantity = '';
            this.costEstimation.unitOfMesurement.id = '';
            this.costEstimation.unitOfMesurement.text = '';
            this.costEstimation.uomTypeId = '';
            this.costEstimation.uomType = '';
            this.costEstimation.cost = '';
            this.costEstimation.unitCost = '';
            this.costEstimation.costEstimationTypeId = '';
            this.costEstimation.csCategoryId = '';
            this.$v.costEstimation.$reset();
        },
        clearBudgetData() {
            this.budgetPlanData.costEstimationDataList = [];
            this.budgetPlanData.id = null;
            this.budgetPlanData.estimatedCost = '';
            this.costEstimationsBulk = [];
            this.updateFromGrid = false;
        },
        removeCostEstimation: function(data) {
            this.costEstimationsBulk = this.costEstimationsBulk.filter((estimationData)=> estimationData !== data );
            if (data==this.costEstimation) {
                this.tableBusy = true;
            }
        },
        setTotalCost: function() {
            this.costEstimation.cost = this.costEstimation.quantity * this.costEstimation.unitCost;
        },
        closeDialog() {
            if (this.updateFromGrid) {
                this.clearBudgetData();
            }
            this.clearData();
            this.$emit('closeDialog');
            this.$bvModal.hide('costEstimationPopup');
        },
    }
}
</script>