<template>
    <div class="accPayableReceivableDetailPopup">
        <b-modal id="accPayableReceivableDetailPopup" ref="accPayableReceivableDetailRef" @hide="closeDialog" size="lg" modal-class="" title="Transaction Details" ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div id="printMe" class="row">
                    <div class="row mb-auto w-100 mx-0">
                        <div class="col-12 px-0 text-left">
                            <div class="card-body py-0">
                                <div class="row">
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">{{ popupData.accountPayableReceivableId }}</label>
                                            </div>
                                            <div class="col-10 pl-0">
                                                <label class="p-2 mb-0 d-block header-details font-weight-bold">{{ popupData.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Amount</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right">{{ currency!='null' ? currency : '' }}{{ popupData.amount ? popupData.amount.toFixed(2) : '' }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">{{ popupData.accountPayableReceivableTypeId==1 ? 'Paid' : 'Received' }}</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right">{{ currency!='null' ? currency : '' }}{{ popupData.prTxnTotal ? popupData.prTxnTotal : '-' }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Balance</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details text-right">{{ currency!='null' ? currency : '' }}{{ (popupData.amount + popupData.prTxnTotal) }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Type</label>
                                            </div>
                                            <div class="col-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.accountPayableReceivableTypeId == 1 ? 'Payable' : 'Receivable' }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Account Head</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.accountHead }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-2 p-1 pt-2 pb-2 d-block title-block">Transaction Date</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.transactionDt | formatDateTimeAmPm }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Due Date</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.dueDate | formatDateTimeAmPm }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Responsible Person</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.responsiblePerson }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6 px-1">
                                        <div class="row">
                                            <div class="col-4 col-md-4 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Created Date</label>
                                            </div>
                                            <div class="col-8 col-md-8 pl-0">
                                                <label class="p-2 mb-0 d-block header-details">{{ popupData.createdDt | formatDateTimeAmPm }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-12 col-md-2 pr-0">
                                                <label class="mb-0 p-1 pt-2 pb-2 d-block title-block">Attachment</label>
                                            </div>
                                            <div class="col-12 col-md-10 pl-0">
                                                <div class="col-12">
                                                    <div class="mt-2">
                                                        <AttachmentView ref="viewAttachmentRef" :attachmentData="attachmentData" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-12 col-sm-12 mt-2 px-1">
                                        <h6 class="contact-title mb-2"><span>Transaction History</span></h6>
                                        <!-- <div class="row mt-0 mb-2"> -->
                                            <!-- <div class="row view-filter">
                                                <div class="col-sm-12">
                                                    <div class="float-left">
                                                        <div class="dataTables_filter">
                                                            <label>
                                                                <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div> -->
                                            <b-table show-empty small class="preview-table" stacked="md" selectable :select-mode="selectMode" sort-icon-left :items="accountPrTransactionList" :fields="accountPrTransactionFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" >
                                                <template #cell(index)="data">
                                                    {{ data.index + 1 }}
                                                </template>
                                                <template v-slot:cell(amount)="data">
                                                    {{ currency!='null' ? currency : '' }} {{data.value}} {{ data.item.amountType }}
                                                </template>                                                
                                                <template v-slot:empty="scope">
                                                    <div class="text-center">No Transactions found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="scope">
                                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                                </template>
                                            </b-table>
                                            <b-pagination v-if="accountPrTransactionList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                        <!-- </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <b-button size="sm" variant="primary" @click="ok()" class="float-right" >OK</b-button>
                    <b-button title="Download" size="sm" variant="outline-primary" @click.prevent="print" class="float-left">
                        <i class="fa fa-download fa-lg"></i>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import AttachmentView from '@/components/common/ViewAttachmentComponent.vue';    
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object,
        attachmentData: Object
    },
    components: {
        AttachmentView
    },
    data() {
        return {
            loading: true,
            popupData: {},
            timeSheets: [],

            currency: localStorage.getItem('currency'),
            vhextAccountId: localStorage.getItem('vhextAccountId'),

            accountPrTransactionList: [],
            accountPrTransactionFields: [
                { key:'index',label: 'SN' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'amount', label: 'Amount', sortable: true, sortDirection: 'desc' },
                { key: 'transactionDt', label: 'Transaction Date', sortable: true, formatter: "formatDate", sortDirection: 'desc' },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
        }
    },
    methods: {
        getDetails: function(id, refEntity) {
            this.loading = true;
            try {                
                this.$bvModal.show('accPayableReceivableDetailPopup');
                console.log(this.detailViewData);
                if (refEntity === "account_payable_receivable") {
                    axios.get(this.$store.getters.getAPIBasePath + '/finance/accountpayablereceivable/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        if (result) {
                            this.popupData = result.financialTransaction;
                            this.$nextTick(() => {
                                this.getAccountPrList(this.popupData.id);
                            })
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
                }
                else {
                    this.$nextTick(() => {
                        this.popupData = this.detailViewData;
                    });
                    this.$nextTick(() => {
                        this.getAccountPrList(this.popupData.id);
                    })
                    this.loading = false;
                } 
            }
            catch(err) {
                this.loading = false;
            }
        },
        getAccountPrList: function(accountPayableReceivableId) {
            if (accountPayableReceivableId) {
                let data = {
                    accountPayableReceivableId: accountPayableReceivableId,
                    vhextAccountId: this.vhextAccountId,
                }
                this.loading = true;
                axios.post(this.$store.getters.getAPIBasePath + '/finance/accountprtransaction/list', data)
                    .then((response) => {
                        this.loading = false;
                        let result = response.data;
                        this.accountPrTransactionList = result.accountPrTransactionList;
                        this.totalRows = this.accountPrTransactionList.length;
                    })
                    .catch(error => {
                        this.loading = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
        print: function() {    
            this.$htmlToPaper('printMe');
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>
<style scoped>
span >>> p {
    margin: 0;
}
</style>