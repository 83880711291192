<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-md-8 col-sm-8">
                            <h4 class="pl-2 mb-0 fs-16">Payroll Calculation</h4>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="form-row justify-content-md-center">
                <div class="col-lg-9 col-xl-9 ">
                    <div class="card">
                        <div class="card-body p-3">
                            <b-overlay :show="showOverlay" opacity="0.4">
                                <div class="form-row mb-4">
                                    <div class="col-12">
                                        <div id="scope-app" class="mb-2">
                                            <b-card no-body class="">
                                                <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                    <div class="form-row">
                                                        <div class="col-md-4">
                                                            <b-input-group prepend="Payroll Config" class=" my-2 ml-2">
                                                                <select v-model="payrollCalculationData.payslipConfigId" class="form-control" >
                                                                    <!-- <option value="">Select</option> -->
                                                                    <option v-for="(info, index) in payslipConfigLookup" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </b-input-group>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <div class="form-row border mx-2 mt-1 p-1">
                                                                <div class="col-5 text-right">
                                                                    <b-form-group v-slot="{ ariaDescribedby }" class="d-inline-block my-auto" >
                                                                      <b-form-radio-group v-model="payrollCalculationData.isEmployee" @change="resetData" :options="empCheckboxArray" :aria-describedby="ariaDescribedby" name="radio-inline" ></b-form-radio-group>
                                                                    </b-form-group>
                                                                </div>
                                                                <div class="col-md-7">
                                                                    <template v-if="payrollCalculationData.isEmployee">
                                                                        <div class="required-field-block" :class="{ 'is-invalid': $v.payrollCalculationData.employeeId.$error }">
                                                                            <select v-model="payrollCalculationData.employeeId" @change="resetData" :class="{ 'is-invalid': $v.payrollCalculationData.employeeId.$error }" class="form-control" >
                                                                                <option :value="null">Select Employee</option>
                                                                                <option v-for="(info, index) in employeeLookup" :value="info.id">{{info.value}}</option>
                                                                            </select>
                                                                            <div class="required-icon"></div>
                                                                        </div>  
                                                                        <label v-if="$v.payrollCalculationData.employeeId.$error && !$v.payrollCalculationData.employeeId.required" class="text-danger">Please select Employee</label>
                                                                    </template>
                                                                    <template v-else>
                                                                        <div class="row no-gutters">
                                                                            <div class="col-auto">
                                                                                <b-input-group prepend="Monthly CTC" class="">
                                                                                </b-input-group>
                                                                            </div>
                                                                            <div class="col">
                                                                                <VhextMoneyControl :value="Math.abs(Number(payrollCalculationData.monthlyCTC))" class="" @money-input="moneyControlInput" />
                                                                            </div>
                                                                        </div>
                                                                        <label v-if="$v.payrollCalculationData.monthlyCTC.$error && !$v.payrollCalculationData.monthlyCTC.required" class="text-danger">Please enter monthly CTC</label>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md mr-2">
                                                            <div class="pt-1">
                                                                <b-button class="btn sbtn btn-xs px-2 mt-2" variant="primary" @click="viewAutoGenPayslip" >Go</b-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-header>
                                            </b-card>
                                        </div>
                                    </div>
                                    <div v-if="showPayslip" class="col-lg-12 col-xl-12">
                                        <b-card no-body class="mb-2">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="p-2 my-0 accordion-title">
                                                    Payslip
                                                </label>
                                                <label class="p-2 my-0 accordion-title float-right">
                                                    <b-button class="addnew-tassk-btn sbtn mb-1" @click="showPayslip = false" variant="outline-danger" size="xs" ><i class="fa fa-remove pr-2" aria-hidden="true"></i>Close</b-button>
                                                </label>
                                                <div class="separator mb-2 mx-2"></div>
                                            </b-card-header>
                                            <b-card-body class="py-3 px-2">
                                                <PayslipAutoGenComponent :key="payslipAutoGenCompKey" ref="payslipAutoGenCompRef" :payslipAutoGenData="payrollCalculationData" />
                                            </b-card-body>
                                        </b-card>
                                    </div>
                                    <div v-else class="col-lg-12 col-xl-12">
                                        <b-card no-body class="mb-2">
                                            <b-card-body class="py-3 px-2">
                                                <label class="d-block p-2 my-0 accordion-title text-center" block>
                                                    No Payslip found
                                                </label>
                                            </b-card-body>
                                        </b-card>
                                    </div>
                                </div>
                                <template #overlay>
                                    <div class="text-center">
                                        <LoadingSpinner />
                                    </div>
                                </template>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <AddExistingActivityPopup v-if="showDialog" @refresh-lookup="getPayslipConfiguration" @closeDialog="showDialog=false;" /> -->
        <PayslipDetailAutoGenPopup :key="payslipAutoGenPopupKey" ref="payslipDetailAutoGenPopupRef" :payslipConfigId="Number(payrollCalculationData.payslipConfigId)" @closeDialog="showDialog=false;" />
        <!-- <DeleteComponent id="deletePayslipComponentSetting" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Payroll Component ?" /> -->
        <!-- <DeleteComponent id="deletePayslipCalc" :onYes="onYesPayslipCalc" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to Payroll Calculation Logic ?" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
// import AddExistingActivityPopup from '@/components/popup/lookup/AddExistingActivityPopup.vue';
import PayslipDetailAutoGenPopup from '@/components/popup/itemdetail/PayslipDetailAutoGenPopup.vue';
import PayslipAutoGenComponent from '@/components/common/PayslipAutoGenComponent.vue'
import VhextMoneyControl from '@/components/usercontrols/VhextMoneyControl.vue'
import { required, requiredIf } from "vuelidate/lib/validators";
import enummixin from "@/mixins/enummixin";
export default {
    name: 'app',
    mixins: [enummixin],
    components: {
        // AddExistingActivityPopup,
        PayslipDetailAutoGenPopup,
        PayslipAutoGenComponent,
        VhextMoneyControl,
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            showPayslip: false,
            elements: {
                showDetail: false,
                editMode: false,
                visible:false,
                addCompVisible: false,
            },
            isBusy: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entity: 'activity',
            userId: localStorage.getItem("userId"),

            payslipAutoGenPopupKey: 0,
            payslipAutoGenCompKey: 1000,

            payslipConfig: {
                id: '',
                payslipConfigId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                jobCategoryId: '',
                title: '',
                startDate: '',
                endDate: '',
                isStandard: '',
                active: true,
                isDefault: false,
                createdById: localStorage.getItem("userId"),
                payslipComponentSettingData: {
                    payslipComponentId: '',
                    percentVal: '',
                },
                payslipComponentSettingList: []
            },
            payrollCalculationData: {
                payslipConfigId: '',
                employeeId: null,
                isEmployee: true,
                monthlyCTC: '',
            },
            payslipConfigLookup: [],
            employeeLookup: [],

            empCheckboxArray: [
              { text: 'Employee', value: true },
              { text: 'Non Employee', value: false },
            ],

            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],

            deleteParam: {
                id: 0
            },
        }
    },
    computed: {
        getpayslipConfigData() {
            if (this.payrollCalculationData.payslipConfigId) {
                return this.payslipConfig;
            }
            let payslipConfig = null;
            if (this.payslipConfigLookup && this.payslipConfigLookup.length>0) {
                payslipConfig = this.payslipConfigLookup.find(data => data.isDefault);
                payslipConfig = payslipConfig ? payslipConfig : this.payslipConfigLookup[0];
            }
            return payslipConfig
        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        payrollCalculationData: {
            employeeId: {
                required: requiredIf(function (abc) {
                    return this.payrollCalculationData.isEmployee;
                }),
            },
            monthlyCTC: {
                required: requiredIf(function (abc) {
                    return !this.payrollCalculationData.isEmployee;
                }),
            },
        },
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/payslipautogen/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.employeeLookup = result.employeeLookup;
                    this.payslipConfigLookup = result.payslipConfigLookup;
                    this.payrollCalculationData.payslipConfigId = this.getpayslipConfigData ? this.getpayslipConfigData.id : '';
                })

        },
        moneyControlInput: function(value) {
            this.payrollCalculationData.monthlyCTC = Math.abs(value);
            this.resetData();
        },
        resetData() {
            this.showPayslip=false;
            this.$v.payrollCalculationData.$reset();
        },
        viewAutoGenPayslip: function() {
            console.log(this.payrollCalculationData)
            this.$v.payrollCalculationData.$touch();
            if (!this.$v.payrollCalculationData.$invalid) {
                this.showPayslip = true;
                this.payslipAutoGenCompKey++;
            }
        },
    }
}
</script>