<template>
    <div class="deliverable" >
        <b-modal scrollable  ref="deliverableModal" size="md" centered id="deliverableModal" modal-class="deliverableModal" :title="deliverableLookupTitle" :no-close-on-backdrop="true" ok-only ok-title="Close" ok-variant="secondary">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                            <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New Deliverable</span>
                            <span v-if="elements.editMode">Edit</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                        <b-card-body class="p-2">
                            <div class="row">
                                <b-input-group class="form-group py-2">
                                    <div cols="2" class="align-middle float-left my-auto ml-4 mr-0" >Deliverable</div>
                                    <b-col cols="6" class="pr-1">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.deliverable.deliverable.$error }">
                                            <b-form-input id="name" v-model="deliverable.deliverable" placeholder="Enter Resource Name"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.deliverable.deliverable.$error && !$v.deliverable.deliverable.required" class="text-danger">Please Enter Deliverable</label>
                                    </b-col>
                                    <b-col cols="2">
                                        <div class="input-group p-0">
                                            <b-form-checkbox class="custom-toggle" v-model="deliverable.active" name="check-button" switch>
                                            </b-form-checkbox>
                                        </div>
                                    </b-col>                                                                
                                    <div cols="2" class="float-right">
                                        <div class="more-btns">
                                            <button type="submit" title="Save" @click="saveDeliverable" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                            <button type="submit" title="Clear" @click="clearDeliverableData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </b-input-group>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <div class="float-right">
                    <div class="dataTables_length">
                        <b-form-group label="Per page" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="perPageSelect" class="mb-0">
                            <b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <b-table show-empty small stacked="md" :busy="isBusy" sort-icon-left :items="deliverables" :fields="deliverableFields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template v-slot:cell(active)="data">
                        <b-form-checkbox class="ml-3 float-left pointer" v-model="data.item.active" @change="assignActivationState(data.item)">
                        </b-form-checkbox>
                    </template>
                    <template v-slot:cell(actions)="data">
                        <a href="javascript:" title="Edit Deliverable" @click="showDeliverableEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg  pl-2"></i></a>
                        <a href="javascript:" title="Delete" @click="removeDeliverable(data.item.id)" v-b-modal.removeDeliverable><i class="fa fa-trash pl-2"></i></a>
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No Deliverable found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span>                
                <b-pagination v-if="deliverables.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
            </b-overlay>
        </b-modal>
        <DeleteComponent id="removeDeliverable" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Deliverable ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        entity: String,
        deliverableLookupTitle: String
    },
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            elements: {
                visible: false,
                editMode: false
            },
            showOverlay: false,
            isBusy: false,
            notEnabled: true,
            deliverable: {
                id: '',
                deliverableId: '',
                deliverable: '',
                active: true,
                vhextAccountId: '',
                projectId: '',
                preparedById: localStorage.getItem("userId"),
            },
            deliverables: [],
            deliverableFields: [
                { key: 'deliverableId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'deliverable', label: 'Deliverable', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            totalRows: 1,
            currentPage: 1,
            sortDesc: false,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
        }
    },
    validations: {
        deliverable: {
            deliverable: {
                required
            }
        }
    },
    mounted() {
        this.getDeliverables();
    },
    methods: {
        getDeliverables: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/list', data)
                .then((response) => {
                    let result = response.data;
                    this.deliverables = result.deliverableList;
                    this.totalRows = this.deliverables.length;
                })
        },
        saveDeliverable: function() {
            this.$v.deliverable.$touch();
            if (!this.$v.deliverable.$invalid) {
                if (!this.matchTextValExists(this.deliverable.deliverable, this.deliverable.id)) {
                    if (this.elements.editMode) {
                        this.updateDeliverableData();
                    } else {
                        this.addDeliverableData();
                    }
                }else {
                    iziToast.info({
                        message: 'Deliverable already exist.',
                        position: 'topCenter'
                    });
                }                
            }
        },
        addDeliverableData: function() {
            this.deliverable.vhextAccountId = this.vhextAccountId;
            this.deliverable.projectId = this.projectId;
            this.deliverable.entity = this.entity;
            console.log(this.deliverable);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/add', this.deliverable)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDeliverables();
                        this.clearDeliverableData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        updateDeliverableData: function() {
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/update', this.deliverable)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDeliverables();
                        this.clearDeliverableData();
                    }
                })
                .catch((error)=> {
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        removeDeliverable: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/deliverable/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDeliverables();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.deliverable.id = item.id;
                this.deliverable.active = 0;
                this.deliverable.entity = 'deliverable';
                this.updateActivationState();
            } else {
                this.deliverable.id = item.id;
                this.deliverable.active = 1;
                this.deliverable.entity = 'deliverable';
                this.updateActivationState();
            }
        },
        updateActivationState: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', this.deliverable)
                .then((response) => {
                    this.isBusy = false;
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getDeliverables();
                        this.clearDeliverableData();
                    }
                })
                .catch((error)=> {
                    this.isBusy = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                })
        },
        clearDeliverableData: function() {
            this.deliverable.id = '';
            this.deliverable.deliverable = '';
            this.deliverable.deliverableId = '';
            this.deliverable.active = true;
            this.$v.deliverable.$reset();
            this.elements.editMode = false;
        },
        showDeliverableEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.deliverable.id = data.id;
            this.deliverable.deliverableId = data.deliverableId;
            this.deliverable.deliverable = data.deliverable;
            this.deliverable.active = data.active;            
            this.deliverable.vhextAccountId = data.vhextAccountId;
            this.deliverable.projectId = data.projectId;
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.deliverables.map(data => {
                if (textVal && data.deliverable && data.id !== id) {
                    if (data.deliverable.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },


    }
}
</script>