<template>
    <div class="activity">
        <b-modal scrollable ref="addUserActivityModal" centered size="lg" :id="id" modal-class="addUserActivityModal" title="User Activities - Add / Remove" ok-only body-class="pt-0 pb-2" footer-class="py-3" header-class="py-3" @hide="closeDialog" >

<!--         <b-modal scrollable ref="ermMasterModal" size="lg" :id="id" centered modal-class="ermMasterModal" :title="ermMasterInfo.title" @hide="closeDialog" :no-close-on-backdrop="true" ok-title="Ok">
 -->
        
<!--         <b-modal ref="menuCustomizationPopup" centered size="lg" :id="id" modal-class="typeModal" title="Landing Top Menu - Show / Hide" @hide="closeDialog" :no-close-on-backdrop="true" hide-footer header-class="pt-4 pb-2" body-class="p-2" > -->            
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row mb-2 px-2">
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 pb-1 mt-3">
                            <b-tabs v-model="tabIndex" content-class="mt-3" lazy>
                                <b-tab title="Existing Process / Activities">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="cmpProcessActivities" :fields="activityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByExt" :sort-desc.sync="sortDescExt" :sort-direction="sortDirection">
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>

                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!activityExists(data.item.activity)">
                                                <a href="javascript:" title="Add Activity" class="text-success" @click="addUserProcessActivity(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Activities found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab>
<!--                                 <b-tab v-if="isStandard == 'true'" title="Activities Summary">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="activitiesSummary" :fields="activitySummaryFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!activityExists(data.item.activity)">
                                                <a href="javascript:" title="Add Activity" class="text-success" @click="addUserProcessActivity(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Activities found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab> -->
<!--                                 <b-tab v-else title="Standard Activities">
                                    <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" @row-selected="onRowSelected" sort-icon-left :items="standardActivities" :fields="activityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortByStd" :sort-desc.sync="sortDescStd" :sort-direction="sortDirection">                                        
                                        <template #cell(selected)="data">
                                            <div class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                                <span v-else>
                                                    <input class="form-check-input" disabled type="checkbox" title="Already Exists" :id="'chckbox'+data.index+1" v-model="checkboxValue">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template #cell(index)="data">
                                            {{ data.index + 1 }}
                                        </template>
                                        <template v-slot:cell(actions)="data">
                                            <span v-if="!activityExists(data.item.activity)">
                                                <a href="javascript:" title="Add Activity" class="text-success" @click="addUserProcessActivity(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                            </span>
                                            <div v-else class="form-check form-check-inline">
                                                <span v-if="!activityExists(data.item.activity)">
                                                    <input class="form-check-input" type="checkbox" :id="'chckbox'+data.index+1" :value="data.item.activity" v-model="selectedActivities">
                                                    <label class="form-check-label" :for="'chckbox'+data.index+1"></label>
                                                </span>
                                            </div>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Activities found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                </b-tab> -->
                            </b-tabs>
                        </div>
                    </div>
                    <div class="col-sm-6 px-1">
                        <div class="card px-3 mt-3">
                            <label class="mt-3">User Process / Activities</label>
                            <div class="separator mb-3"></div>
                            <b-table show-empty small sticky-header="55vh" head-variant="primary" selectable :select-mode="selectMode" sort-icon-left :items="userActivities" :fields="activityFields" responsive="sm" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                <template #cell(index)="data">
                                    {{ data.index + 1 }}
                                </template>
                                <template v-slot:cell(actions)="data">
                                    <a href="javascript:" title="Remove" @click="deleteItem(data.item.id)" v-b-modal.deleteItem class="text-danger"><i class="fa fa-minus-circle pr-2"></i></a>
                                </template>
                                <template v-slot:empty="scope">
                                    <div class="text-center">No Activities found</div>
                                </template>
                                <template v-slot:emptyfiltered="scope">
                                    <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <button type="submit" @click="addBulkActivity" :disabled="selectedActivities.length==0" class="btn btn-primary float-left my-0 mr-2 py-1 px-3">Add Selected</button>
                    <button v-if="selectedActivities.length>0" type="submit" @click="selectedActivities=[];" class="btn btn-outline-primary float-left my-0 mr-2 py-1 px-3">Clear Selection</button>
                    <button type="submit" @click="cancel()" class="btn btn-secondary float-right my-0 mr-2 py-1 px-3">Close</button>
                </div>
            </template>
        </b-modal>
        <DeleteComponent id="deleteItem" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Activity ?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        id: { type: String, default: 'userActivityModal' },
    },

    data() {
        return {
            showOverlay: false,
            checkboxValue: true,
            tabIndex: 0,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            isStandard: localStorage.getItem("isStandard"),

            userProcessActivity: {
                vhextAccountId: '',
                projectId: '',
                entity: 'user_process_activity',
                userId: '',
                activityId: '',
                activity: '',
                processId: '',
                process: '',
                createdById: localStorage.getItem("userId"),
                active: true,
            },

            activity: {
                activityId: '',
                activity: '',
                vhextAccountId: '',
                projectId: '',
                entity: 'activity',
                createdById: localStorage.getItem("userId"),
                active: true,

            },
            cmpProcessActivities: [],
            // standardActivities: [],
            userActivities: [],
            activityFields: [
                { key: 'selected', label: '' },
                { key: 'index', label: 'SN' },
                { key: 'process', label: 'Process', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            activitiesSummary: [],
            activitySummaryFields: [
                { key: 'selected', label: '' },
                {key:'index',label: 'SN'},
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'count', label: 'Count', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Action' }
            ],
            selectMode: 'single',
            sortDescExt: false,
            sortDescStd: false,
            sortDesc: false,
            sortBy: '',
            sortByExt: '',
            sortByStd: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            selectedActivities: [],

            deleteParam: {
                id: 0
            }
        }
    },
    watch: {
        tabIndex() {
            this.selectedActivities = [];
        }
    },
    mounted() {
        this.getCompanyProcessActivities();
        this.getUserProcessActivities();

        // if (this.isStandard == 'true') {            
        //     this.getActivitiesSummary();
        // } 
        // else {
        //     this.getStandardActivities();
        // }
    },
    methods: {
        getUserProcessActivities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/user/processactivity/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    this.selectedActivities = [];
                    let result = response.data;
                    this.userActivities = result.userProcessActivityList;
                    console.log("ACT",this.userActivities);
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getCompanyProcessActivities: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/company/processactivity/list', data)
                .then((response) => {
                    let result = response.data;
                    this.cmpProcessActivities = result.cmpProcessActivityList;
                    console.log("EACT",this.cmpProcessActivityList);
                })
        },
        // getStandardActivities: function() {
        //     let data = {
        //         projectTypeId: this.projectTypeId,
        //     }
        //     axios.post(this.$store.getters.getAPIBasePath + '/general/standardactivity/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             this.standardActivities = result.standardActivityList;
        //         })
        // },
        // getActivitiesSummary: function() {
        //     let data = {
        //         vhextAccountId: this.vhextAccountId,
        //         projectTypeId: this.projectTypeId,
        //     }
        //     console.log(data);
        //     axios.post(this.$store.getters.getAPIBasePath + '/general/activitysummary/list', data)
        //         .then((response) => {
        //             let result = response.data;
        //             this.activitiesSummary = result.activitySummaryList;
        //         })
        // },
        addUserProcessActivity: function(data) {

            this.userProcessActivity.vhextAccountId = this.vhextAccountId;
            this.userProcessActivity.projectId = this.projectId;
            this.userProcessActivity.userId = this.userId;
            this.userProcessActivity.processId = data.processId;
            this.userProcessActivity.activityId = data.activityId;
            this.userProcessActivity.createdById = localStorage.getItem("userId");
            this.userProcessActivity.active = true;
            this.userProcessActivity.activity = data.activity;

            console.log("UPA",this.userProcessActivity);

            console.log(this.activityExists(this.userProcessActivity.activity));

            if (!this.activityExists(this.userProcessActivity.activity)) {

                
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/process/user/processactivity/add', this.userProcessActivity)
                    .then((response) => {
                        this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('refresh-lookup');
                            this.getUserProcessActivities();
                        }
                    })
                    .catch(error => {
                        this.showOverlay = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            } else {
                iziToast.info({
                    message: 'Activity already exist.',
                    position: 'topCenter'
                });
            }
        },
        activityExists: function(activity) {
            let result = false;
            this.userActivities.map(data => {
                if (activity && data.activity) {
                    if (data.activity.toLowerCase() == activity.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        dataExists: function(activity) {
            let result = false;
            this.selectedActivities.map(data => {
                if (data.activity.toLowerCase() == activity.toLowerCase()) {
                    result = true;
                }
            });
            return result;
        },
        onRowSelected(items) {
            if (items.length > 0){
                let data = items[0];
                if (!this.activityExists(data.activity) && !this.dataExists(data.activity)) {
                    this.selectedActivities.push(data);
                }
                else if (this.dataExists(data.activity)) {
                    this.selectedActivities = this.selectedActivities.filter(e=> e.activity.toLowerCase() !== data.activity.toLowerCase());
                }
            }
          },
        closeDialog() {
            this.selectedActivities = [];
            this.$emit('closeDialog');
        },
        async addBulkActivity() {
            await this.activityData();
            await this.saveBulkActivity();
        },
        activityData: function(data) {
            this.bulkActivityData = [];
            for (var rowCount = 0; rowCount < this.selectedActivities.length; rowCount++) {
                this.bulkActivityData.push({
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    userId: this.userId,
                    processId: this.selectedActivities[rowCount].processId,
                    activityId: this.selectedActivities[rowCount].activityId,
                    active: true,
                    activity: this.selectedActivities[rowCount].activity,
                    createdById: localStorage.getItem("userId"),
                });

            }

        },
        saveBulkActivity: function(data) {

            console.log("UPA",this.bulkActivityData);

            this.showOverlay = true;    
            axios.post(this.$store.getters.getAPIBasePath + '/process/user/processactivity/bulk/add', this.bulkActivityData)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$emit('refresh-lookup');
                        this.getUserProcessActivities();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        deleteItem: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/process/user/processactivity/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getUserProcessActivities();
                        this.$emit('refresh-lookup');
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        matchTextValExists: function(textVal) {
            let result = false;
            this.customTypes.map(data => {
                if (textVal && data.type) {
                    if (data.type.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        
    }
}
</script>