<template>
	<div>
		<div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-lg-8 col-sm-6">
                            <h4 class="pl-2 mb-0 fs-16">Primary Settings</h4>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                        	<nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply action-link pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-0"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-sm-12 p-0 menumangement">
                    <div class="card-body px-3 py-2">
	                    <div no-body>
	                        <b-tabs pills card vertical nav-wrapper-class="w-30" class="h-100">
	                            <b-tab title="Primary Setting" active  >
	                                <b-card-text>
	                                    <div class="card ">
	                                        <div class="card-body p-3">
	                                            <div class="menu_cont active " id="mainMenu">
	                                                <p class="mb-1">Project Setting</p>
	                                                <div class="mt-3">
	                                                    <div class="row">
	                                                    	<div class="col-12 hover-btns">
	                                                            <div class="form-group">
	                                                                <label for="timezone">Project Model</label>
	                                                                <div class="row">
	                                                                    <div class="col-9">
	                                                                        <input disabled v-model="projectSetting.projectAccessType" class="form-control" id="timezone">
	                                                                    </div>
	                                                                </div>
	                                                            </div>
	                                                        </div>
	                                                        <div class="col-12 hover-btns">
	                                                            <div class="form-group">
	                                                                <label for="country">Country</label>
	                                                                <div class="row">
	                                                                    <div class="col-9">
	                                                                        <select :disabled="disableCountry" @change="saveSetting('country')" @blur="disableCountry=true" v-model="projectSetting.country" class="form-control" id="country">
	                                                                            <option value=''>Select</option>
	                                                                            <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
	                                                                        </select>
	                                                                    </div>
	                                                                    <div class="col-3">
	                                                                        <a href="javascript:" v-if="disableCountry" @click.prevent="disableCountry=false;disableCurrency=true;disableTimezone=true" class="float-right button-edit align-items-center mt-2" title="Edit Role"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
	                                                                    </div>
	                                                                </div>
	                                                            </div>
	                                                        </div>
	                                                        <div class="col-12 hover-btns">
	                                                            <div class="form-group">
	                                                                <label for="currency">Currency</label>
	                                                                <div class="row">
	                                                                    <div class="col-9">
	                                                                        <select :disabled="disableCurrency" @change="saveSetting('currency')" @blur="disableCurrency=true" v-model="projectSetting.currency" class="form-control" id="currency">
	                                                                            <option value=''>Select</option>
	                                                                            <option v-for="(info, index) in currencies" :value="info.id">{{info.value}}</option>
	                                                                        </select>
	                                                                    </div>
	                                                                    <div class="col-3">
	                                                                        <a href="javascript:" v-if="disableCurrency" @click.prevent="disableCurrency=false;disableCountry=true;disableTimezone=true;" class="float-right button-edit align-items-center mt-2" title="Edit Role"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
	                                                                    </div>
	                                                                </div>
	                                                            </div>
	                                                        </div>
	                                                        <div class="col-12 hover-btns">
	                                                            <div class="form-group">
	                                                                <label for="timezone">Time zone</label>
	                                                                <div class="row">
	                                                                    <div class="col-9">
	                                                                        <select :disabled="disableTimezone" @change="saveSetting('timeZone')" @blur="disableTimezone=true" v-model="projectSetting.timeZone" class="form-control" id="timezone">
	                                                                            <option value=''>Select</option>
	                                                                            <option v-for="(info, index) in timeZone" :value="info.id">{{info.text}}</option>
	                                                                        </select>
	                                                                    </div>
	                                                                    <div class="col-3">
	                                                                        <a href="javascript:" v-if="disableTimezone" @click.prevent="disableTimezone=false;disableCountry=true;disableCurrency=true;" class="float-right button-edit align-items-center mt-2" title="Edit Role"><i class="fa fa-pencil pr-1 mr-1 float-right"></i></a>
	                                                                    </div>
	                                                                </div>
	                                                            </div>
	                                                        </div>
<!-- 	                                                        <div class="col-12 hover-btns">
				                                                <div class="form-group">
				                                            		<div class="col-12 p-0">
					                                                    <b-form-checkbox class="custom-toggle" @change="setDefaultProject()" switch v-model="projectSetting.isDefault" name="check-button">Default Project</b-form-checkbox>
				                                                	</div>
				                                                </div>	                   
			                                                </div>                                      -->
				                                            <div v-if="this.userTypeCode === 'VSA'" class="col-12">
				                                                <div class="form-group">
				                                            		<div class="col-12">
					                                                    <label for="title">Standard Project</label>
					                                                    <b-form-checkbox class="custom-toggle" @change="setProjectStandard()" switch v-model="projectSetting.isStandard" name="check-button"></b-form-checkbox>
				                                                	</div>
				                                                </div>
				                                            </div>

	                                                    </div>
	                                                </div>
	                                            </div>
	                                        </div>
	                                    </div>
	                                </b-card-text>
	                            </b-tab>
<!-- 	                            <b-tab title="User Account">
	                                <b-card no-body class="p-2">
	                                    <div class="row">
	                                        <div v-if="elements.editMode" class="col-6 col-sm-6">
	                                            <label for="password">New Password</label>
	                                            <div class="required-field-block" :class="{ 'is-invalid': elements.submitted && $v.activation.$error }">
	                                                <input type="password" name="Password" placeholder="Password" maxlength="45" size="30" v-model="activation.password" class="form-control box-none">
	                                                <div class="required-icon"></div>
	                                            </div>
	                                            <div v-if="elements.submitted && $v.activation.$error" class="invalid-feedback">
	                                                <span v-if="!$v.activation.password.required">Password is required</span>
	                                                <span v-if="!$v.activation.password.minLength">Password must be at least 6 characters</span>
	                                            </div>
	                                        </div>
	                                        <div v-if="elements.editMode" class="col-6 col-sm-6">
	                                            <div class="form-group">
		                                            <label for="confirmPassword">Confirm Password</label>
			                                            <div class="required-field-block" :class="{ 'is-invalid': elements.submitted && $v.activation.confirmPassword.$error }">
			                                                <input type="password" v-model="activation.confirmPassword" maxlength="45" size="30" class="form-control" id="confirmPassword" placeholder="Confirm Password">
			                                                <div class="required-icon"></div>
			                                            </div>
		                                            <div v-if="elements.submitted && $v.activation.confirmPassword.$error" class="invalid-feedback">
		                                                <span v-if="!$v.activation.confirmPassword.required">Confirm Password is required</span>
		                                                <span v-else-if="!$v.activation.confirmPassword.sameAsPassword">Passwords must match</span>
		                                            </div>
		                                        </div>
	                                     	</div> 
	                                    </div>
	                                    <div class="col-12 col-sm-6">
	                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="change password">
	                                           Change Password
	                                        </button>
	                                    </div>
	                                    <div class="row">
	                                        <button v-if="elements.editMode" type="submit" @click="submitPassword" class="btn btn-primary ml-3 mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
	                                        <button v-if="elements.editMode" type="submit" @click="elements.editMode = false" class="btn btn-primary mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
	                                    </div>
	                                </b-card>
	                            </b-tab> -->
	                            <b-tab title="Project Log">
	                                <b-card no-body class="p-2">
	                                    <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                	<div class="col-12 mt-2">
	                                                    <label for="title">Activity Log</label>
	                                                    <b-form-checkbox class="custom-toggle" @change="saveActivityLogSetting('record_user_activity')" switch v-model="projectSetting.activityLog" name="check-button"></b-form-checkbox>
                                                	</div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                            		<div class="col-12 mt-4">
	                                                    <label for="title">Record Changes</label>
	                                                    <b-form-checkbox class="custom-toggle" @change="saveActivityLogSetting('record_change_history')" switch v-model="projectSetting.changeHistory" name="check-button"></b-form-checkbox>
                                                	</div>
                                                </div>
                                            </div>

	                                    </div>
	                                </b-card>
	                            </b-tab>	                            
	                        </b-tabs>
	                        
	                    </div>
	                </div>
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: 'app',
    data() {
        return {
            elements: {
                editMode: false,
                submitted: false
            },
            //showModal: false,
            disableCountry: true,
            disableCurrency: true,
            disableTimezone: true,

            userId:localStorage.getItem("userId"),
            userTypeCode:localStorage.getItem("userTypeCode"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            

            projectSetting: {
            	projectAccessType: '',
                country: '',
                currency: '',
                currencySymbol: '',
                timeZone: '',
                isDefault: false,
                isStandard: false,
                changeHistory: false,
                activityLog: false,
            },

            activation:{
                password:'',
                confirmPassword:''
            },
            projectPrimeSettings: [],
            projectSettings: [],
            selProjectSetting: [],
            accessTypes: [],
            countries: [],
            currencies: [],
            timeZone: [],
        }
    },
    validations: {
        activation: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
        this.getProjectPrimeSettings();
        this.lookupData();
        this.getProjectSettingList();
    },
    methods: {

        getProjectPrimeSettings: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/primesettings', data)
                .then((response) => {
                    let result = response.data;
                    this.projectPrimeSettings = result.projectPrimeSettings;
                    this.projectSetting.projectAccessType = (this.projectPrimeSettings.projectAccessType == undefined) ? '' : this.projectPrimeSettings.projectAccessType;
                    this.projectSetting.country = (this.projectPrimeSettings.countryId == undefined) ? '' : this.projectPrimeSettings.countryId;
                    this.projectSetting.currency = (this.projectPrimeSettings.currencyId == undefined) ? '' : this.projectPrimeSettings.currencyId;
                    this.projectSetting.timeZone = (this.projectPrimeSettings.timeZone == undefined) ? '' : this.projectPrimeSettings.timeZone;
                    this.projectSetting.isStandard = (this.projectPrimeSettings.isStandard == undefined) ? '' : this.projectPrimeSettings.isStandard;

                    localStorage.setItem('currency', this.projectPrimeSettings.currencySymbol);

                    console.log(this.projectPrimeSettings);
                })
        },
        getProjectSettingList: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/setting/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectSettings = result.projectSettingList;
                    console.log(result);

                    this.setProjectSettings();
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.$store.getters.currentUser.vhextAccountId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.accountTypes = result.projectAccessTypeLookup;                
                    this.timeZone = result.timeZoneLookup;
                    this.countries = result.countryLookup;
                    this.currencies = result.currencyLookup;                

                })

        },
        submitPassword: function() {
            this.elements.submitted = true;
            this.$v.activation.$touch();
            if (!this.$v.activation.$invalid) {
                this.savePassword();
            }
        },
        savePassword: function() {
            let data = { "id":this.userId, "password": this.activation.password };
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/user/update/password',data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
        },
        saveSetting(setting) {
            console.log(this.projectSetting[setting])
            this.disableCountry = true;
            this.disableCurrency = true;
            this.disableTimezone = true;

            if (this.projectSetting[setting] && this.projectSetting[setting] !== null) {
                let data = {
                    projectId: localStorage.getItem("projectId")
                }
                if (setting !== 'timeZone') {
                    data[setting + 'Id'] = this.projectSetting[setting];
                }
                if (setting == 'timeZone') {
                    data.timeZone = this.projectSetting[setting];
                }
                axios.post(this.$store.getters.getAPIBasePath + '/project/update/' + setting.toLowerCase(), data)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        } 
                        this.getProjectPrimeSettings();

                    })
                    .catch(error => {
                        console.log(error);
                        this.getProjectPrimeSettings();
                    });
            } else this.getProjectPrimeSettings();
        },
        setProjectSettings: function() {

        	this.selProjectSetting = this.projectSettings.find(({ type }) => type === 'record_user_activity');

        	this.projectSetting.activityLog = this.selProjectSetting.value;

        	this.selProjectSetting = this.projectSettings.find(({ type }) => type === 'record_change_history');

        	this.projectSetting.changeHistory = this.selProjectSetting.value;

        	console.log(this.projectSetting.activityLog,this.projectSetting.changeHistory);


            // console.log(this.selClientType);

            // this.client.clientType.id = data.clientTypeId;
            // this.client.clientType.text = this.selClientType.value;
		},

        setProjectStandard: function() {
            let data = {
                projectId: this.projectId,
                isStandard: (this.projectSetting.isStandard == true ? false : true),
            }
            console.log( data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/setstandard', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getProjectPrimeSettings();
                    }
                })
        },

        saveActivityLogSetting: function(type) {
        	console.log(this.projectSetting.changeHistory,type)
        	let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                type: type,
            }
            if (type == 'record_user_activity') {

            	data.value = (this.projectSetting.activityLog === true) ? 'false' : 'true';
            }
            else if (type == 'record_change_history') {
            	data.value = (this.projectSetting.changeHistory === true) ? 'false' : 'true';
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/project/setting/activitylog', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    } 
                })

        },

    }
}
</script>
<style scoped>

.form-control:hover {
    border-color: #807c7c;
    border-width: 2px;
    transition: border-color 0s ease-in-out, box-shadow 0s ease-in-out;
}

.form-control {
    border-width: 2px !important;
    border-radius: 0px;
}
.button-edit {
    display: none;
}
.hover-btns:hover .button-edit {
    display: inline-block;
}
</style>