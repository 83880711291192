<template>
    <div>
        <nav class="navbar fixed-top">
            <div class="d-flex align-items-center navbar-left pl-3">
                <a href="javascript:" class="menu-button d-none d-md-block">
                    <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
                        <rect x="0.48" y="0.5" width="7" height="1" />
                        <rect x="0.48" y="7.5" width="7" height="1" />
                        <rect x="0.48" y="15.5" width="7" height="1" />
                    </svg>
                    <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
                        <rect x="1.56" y="0.5" width="16" height="1" />
                        <rect x="1.56" y="7.5" width="16" height="1" />
                        <rect x="1.56" y="15.5" width="16" height="1" />
                    </svg>
                </a>
                <a href="javascript:" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                        <rect x="0.5" y="0.5" width="25" height="1" />
                        <rect x="0.5" y="7.5" width="25" height="1" />
                        <rect x="0.5" y="15.5" width="25" height="1" />
                    </svg>
                </a>
                <a class="navbar-logo" href="javascript:">
                    <!-- <span class="d-none d-xs-block">vhextech</span>
                    <span class="logo-mobile d-block d-xs-none">vhextech - </span> -->
                    <template v-if="logoImage != 'null' && logoImage.length > 0">
                        <img alt="Profile Picture" class="Companylogo-img" :src="logoImage" />
                    </template>
                    <template v-else>
                        <img alt="Profile Picture" class="Companylogo-img" src="@/assets/images/logo.png" />
                    </template>
                </a>
            </div>
            <div class="navbar-right">
                <div class="header-icons d-inline-block align-middle mr-2">
                    <div class="position-relative d-inline-block">
                        <ol class="breadcrumb pt-0 mb-0">
                            <li v-if="!isLandingPage" class="breadcrumb-item">
                                <a href="javascript:" title="Landing page" class="pointer action-link" @click="redirectLandingPage()">
                                    <i class="fa fa-home fs-18"></i>
                                </a>
                            </li>
                            <li  class="breadcrumb-item">
                                <template v-if="isPrimaryRole === 'true'">
                                    <a v-if="this.empWorkState == 0 || this.empWorkState == 4" href="javascript:" title="My Attendance : Work Not Started" class="pointer emp-tl-indicator px-1 red" v-b-modal.empAttendanceTimelinePopup @click="">
                                        <i class="fa fa-clock-o pt-1"></i>
                                    </a>
                                    <a v-if="this.empWorkState == 2 " href="javascript:" title="My Attendance : Work Break" class="pointer emp-tl-indicator px-1 grey" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" v-b-modal.empAttendanceTimelinePopup @click="">
                                        <i class="fa fa-clock-o pr-1 pt-1"></i>{{ timerDuration }} {{ timelineTaskTitle }}
                                    </a> 
                                   <a v-if="this.empWorkState == 1 || this.empWorkState == 3 " href="javascript:" title="My Attendance : Work In Progress" class="pointer emp-tl-indicator px-1 green" style="max-width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" v-b-modal.empAttendanceTimelinePopup @click="">
                                        <i class="fa fa-clock-o pr-1 pt-1"></i>{{ timerDuration }} {{ timelineTaskTitle }}
                                    </a>
                                </template>
                            </li>
                            <!-- <li class="breadcrumb-item ">
                                <a href="javascript:" title="Landing page" class="pointer action-link" @click="">
                                    <i class="fa fa-clock fs-18"></i>
                                </a>
                            </li>      -->                       
                            <template v-if="elements.showMenu && showMenuItems">
                                <li v-for="(menuItem, index) in serviceTopMenu" :key="menuItem.id" class="breadcrumb-item" v-bind:class="{active: $route.path == menuItem.webUrl}">
                                    <a href="javascript:" :title="menuItem.displayName" id="actionItem" class="pointer action-link top-menu" @click="routeServiceTopMenu(menuItem.webUrl)"> {{ menuItem.displayName }}</a>
                                </li>
                                <!-- <li class="breadcrumb-item" v-bind:class="{active: menuList['actionitem']}">
                                    <a href="javascript:" title="Action Item" id="actionItem" class="pointer  action-link top-menu" @click="routeActionItem"> Action Item</a>
                                </li>
                                <li class="breadcrumb-item " >
                                    <a href="javascript:" title="Member Service Sub Modules" class="pointer action-link top-menu" @click="$router.push('/cmpmemmdlmenu')"> Member Service Roles</a>
                                </li>
                                <li class="breadcrumb-item " v-bind:class="{active: menuList['dashboard']}">
                                    <a href="javascript:" title="Dashboard" class="pointer action-link top-menu" @click="routeDashboard"> Dashboard</a>
                                </li>
                                <li class="breadcrumb-item" v-bind:class="{active: menuList['reqbrd']}">
                                    <a href="javascript:" title="Requirement Board" class="pointer action-link top-menu" @click="routePage('reqbrd')">Req Board</a>
                                </li>
                                <li class="breadcrumb-item" v-bind:class="{active: menuList['tskbrd']}">
                                    <a href="javascript:" title="Task Board" class="pointer action-link top-menu" @click="getEntityRecordMasterList">Task Board</a>
                                </li>
                                <li class="breadcrumb-item" v-bind:class="{active: menuList['tmbrd']}">
                                    <a href="javascript:" title="Resource Board" class="pointer action-link top-menu" @click="routePage('tmbrd')">Teams</a>
                                </li>
                                <li class="breadcrumb-item" v-bind:class="{active: menuList['ftxbrd']}">
                                    <a href="javascript:" title="Financial Transaction Board" class="pointer action-link top-menu" @click="routePage('ftxbrd')">FinTxn Board</a>
                                </li> -->
                                <!-- <li class="breadcrumb-item" v-bind:class="{active: menuList['issbrd']}">
                                    <a href="javascript:" title="Issue Board" class="pointer action-link top-menu" @click="routePage('issbrd')">Issue Board</a>
                                </li> -->
                                <!-- <li class="breadcrumb-item" v-bind:class="{active: menuList['calevt']}">
                                    <a href="javascript:" title="Meetings / Events" class="pointer action-link top-menu" @click="routeCalendar"> Meetings</a>
                                </li>
                                <li class="breadcrumb-item" v-bind:class="{active: menuList['mailbox']}">
                                    <a href="javascript:" title="Mailbox" class="pointer action-link top-menu" @click="routePage('mailbox')">Mailbox</a>
                                </li> -->
                                <!-- <li class="breadcrumb-item" v-bind:class="{active: menuList['chatbox']}">
                                    <a href="javascript:" title="Mailbox" class="pointer action-link top-menu" @click="routePage('chatbox')">Chatbox</a>
                                </li> -->
                                <!-- <li class="breadcrumb-item " >
                                    <a href="javascript:" title="Member Service Sub Modules" class="pointer action-link top-menu" @click="$router.push('/cmpmemmdlmenu')"> Member Service Roles</a>
                                </li> -->
                                <li class="breadcrumb-item">
                                    <select id="clientId" class="menu-form-control client-dropdown">
                                        <option value="">Client</option>
                                        <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                    </select>
                                </li>
                                <li class="breadcrumb-item">
                                    <a href="javascript:" title="Project Settings" class="pointer action-link" @click="routeSettings"><i class="fa fa-cog" aria-hidden="true"></i></a>
                                </li>
                            </template>
                            <template v-if="elements.showLandingTopMenu">
                                <li v-if="topMainMenu.length>0" class="mx-2">
                                    <a v-if="leftSliderBtn" href="javascript:" id="slideLeft" title="" class="pointer action-link" @click="scrollMenu('minus')">
                                        <i class="fa fa-angle-double-left fa-lg font-weight-bold py-1"></i>
                                    </a>
                                </li>
                                <template v-for="(menuItem, index) in topMainMenuLimitedItems()">
                                    <li v-if="menuItem.entityCount == 1" class="breadcrumb-item " :key="index">
                                        <b-button v-for="(submenu, index) in loadSubMenu(menuItem.id)" :key="index" class="addnew-tassk-btn sbtn" :class="{'active' : submenu.webUrl == $route.path}" variant="primary" @click="routeTopMenu(submenu)" size="xs" :title="submenu.customSubMenu">{{ submenu.displayName }}</b-button>
                                    </li>
                                    <li v-if="menuItem.entityCount > 1" class="breadcrumb-item">
                                        <div class="dropdown">
                                            <button class="btn sbtn btn-primary btn-xs p-custom dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span class="d-inline-block">{{ menuItem.customMainMenu }}</span>
                                            </button>
                                            <div class="dropdown-menu p-0" aria-labelledby="dropdownMenuButton" >
                                                <template v-for="(submenu, index) in loadSubMenu(menuItem.id)">
                                                    <a class="dropdown-item" @click.prevent="routeTopMenu(submenu)" :class="{'active' : submenu.webUrl == $route.path}" :title="submenu.customSubMenu" href="#">{{ submenu.displayName }}</a>
                                                    <div class="dropdown-divider m-0"></div>
                                                </template>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                                <li v-if="topMainMenu.length>0" class="mx-2">
                                    <a v-if="rightSliderBtn" href="javascript:" id="slideRight" title="" class="pointer action-link" @click="scrollMenu('plus')">
                                        <i class="fa fa-angle-double-right fa-lg font-weight-bold py-1"></i>
                                    </a>
                                </li>
                                <li class="breadcrumb-item">
                                    <b-button class="addnew-tassk-btn sbtn" variant="primary" size="xs" v-b-modal.menuCustomizationPopup><i class="fa fa-ellipsis-v "></i></b-button>
                                </li>
                                <li  class="breadcrumb-item">
                                </li>
                            </template>
                        </ol>
                    </div>
                </div>
                <div class="user d-inline-block">
                    <button class="btn btn-empty p-0" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="name d-flex justify-content-between align-items-baseline">
                                <div class="">
                                    <div>
                                        {{userName}}
                                    </div>
                                    <div v-if="multiUserRoles.length" class="fs-11">{{ companyName }}</div>
                                </div>
                                <i class="fa fa-angle-down ml-2"></i>
                            </span>
                            <span v-if="userImage && userImage!= 'null'">
                                <img alt="Profile Picture" id="profile-img" width="25" height="25" :src="userImage" />
                            </span>
                            <span v-else>
                                <img alt="Profile Picture" id="profile-img" width="25" height="25" src="@/assets/img/profile-pic-l.jpg" />
                            </span>
                        </div>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right mt-3">
                        <a v-if="false" class="dropdown-item" href="javascript:">Account</a>
                        <a v-if="false" class="dropdown-item" href="javascript:">Features</a>
                        <a v-if="false" class="dropdown-item" href="javascript:">History</a>
                        <a v-if="false" class="dropdown-item" href="javascript:">Support</a>
                        <a class="dropdown-item" @click="routeMyProfile" href="javascript:">My Profile</a>
                        <a v-if="filterMultiUserRoles.length" class="dropdown-item" href="javascript:">
                            <div class="dropdown dropright">
                                <span class="dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Switch User
                                </span>
                                <div class="dropdown-menu inner-dropdown">
                                    <b-dropdown-item v-for="item in filterMultiUserRoles" :key="item.id" @click="switchUserAccount(item.vhextAccountId)">{{item.value}}</b-dropdown-item>
                                </div>
                            </div>
                        </a>
                        <a class="dropdown-item" @click="routeToAttendance('lanempatl')" href="javascript:">My Attendance</a>
                        <a v-if="!projectId && userTypeCode != 'CLI'" class="dropdown-item" @click="routeSettings" href="javascript:">Admin Settings</a>
                        <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);">Logout</a>
                    </div>
                </div>
            </div>
        </nav>
        <CompanyCalendarPopup v-if="showDialog" ref="calEventPopupRef" @closeDialog="showDialog = false" />
        <EmpAttendanceTimelinePopup />
        <MenuCustomizationPopup ref="menuCustomizationPopupRef" @refresh-page="getTopMainMenu" />
    </div>
</template>
<script>
import { mapState } from 'vuex';
import iziToast from 'izitoast';
import defaultImage from '@/assets/images/logo.png';
import CompanyCalendarPopup from '@/components/popup/action/CompanyCalendarPopup.vue';
import EmpAttendanceTimelinePopup from '@/components/popup/action/EmpAttendanceTimelinePopup.vue';
import MenuCustomizationPopup from '@/components/popup/action/MenuCustomizationPopup.vue';
import { EventBus } from '@/main';
import carousel from 'vue-owl-carousel';
export default {
    components: {
        CompanyCalendarPopup,
        EmpAttendanceTimelinePopup,
        MenuCustomizationPopup,
        carousel,
    },
    watch: {
        '$route.path': {
            handler: function(path) {
                let routeName = this.$route.name;
                console.log(path, routeName);

                this.isLandingPage = false;
                if (path=='/landing' || path=='/vsa/landing' || path=='/cmr/landing' || path=='/prm/landing' || path=='/cli/landing' || path=='/emp/landing' || path=='/tmb/landing' || path=='/hrm/landing' || path=='/fnm/landing') {
                    this.isLandingPage = true;
                }

                if (path != null && path.indexOf('/vsa/landing') != -1) {
                    this.elements.showMenu = false;
                    this.elements.showVSAMenu = true;
                    this.elements.showLandingTopMenu = true;
                } else if (path != null && path.indexOf('landing') != -1) {
                    this.elements.showMenu = false;
                    this.elements.showVSAMenu = false;
                    this.elements.showLandingTopMenu = true;
                } else {
                    this.elements.showMenu = true;
                    this.elements.showVSAMenu = false;
                }
                for (var item in this.menuVSAList) {
                    if (routeName == item) {
                        this.elements.showVSAMenu = true;
                        this.elements.showMenu = false;
                        break;
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'logoImage': {
            handler: function(val1, val2) {  
                console.log(val1, val2);
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        appLoading: {
            get() {
                return this.$store.state.appLoading;
            },
            set(value) {
                this.$store.state.appLoading = value;
            }
        },
        appLoadingContent: {
            get() {
                return this.$store.state.appLoadingContent;
            },
            set(value) {
                this.$store.state.appLoadingContent = value;
            }
        },
        userImage() {
            return localStorage.getItem("userImage");
        },
        showMenuItems() {
            let routeName = this.$route.name;
            return routeName !== 'addproject' && !routeName.includes('landing');
        },
        logoImage: {
            get() { 
                return localStorage.getItem('logo'); 
            }, 
            set(value) { 
                localStorage.setItem('logo', value); 
            },
        },
        rightSliderBtn() {
            let maxSetCount = Math.floor(this.topMainMenu.length / this.topMenuMaxItems);
            let reminder = this.topMainMenu.length % this.topMenuMaxItems;
            console.log(this.topMenuItemSet, maxSetCount-1)
            if(reminder == 0) {
                return this.topMenuItemSet < maxSetCount-1;
            }
            else {
                return this.topMenuItemSet < maxSetCount;
            }
            // return (this.topMenuItemSet < maxSetCount) || (this.topMenuItemSet < maxSetCount-1 && reminder == 0);
        },
        leftSliderBtn() {
            let maxSetCount = Math.floor(this.topMainMenu.length / this.topMenuMaxItems);
            return this.topMenuItemSet > 0;
        },
        filterMultiUserRoles() {
            return this.multiUserRoles.filter(data => data.vhextAccountId != this.vhextAccountId);
        },
        companyName() {
            let company = this.multiUserRoles.find(data => data.vhextAccountId == this.vhextAccountId);
            return company?.value ? company.value : '';
        },
    },
    data() {
        return {
            isLandingPage: false,
            carouselKey: 100,
            elements: {
                showMenu: false,
                showLandingTopMenu: false,
                showVSAMenu: false,
                active: false,
                action: false,
                dashboard: false,
                req: false,

            },
            startTime: '',
            timelineTaskTitle: '',
            timerDuration: '00:00:00',
            timerValue: 0,
            empWorkState:'',
            topMenuMaxItems: 8,
            topMenuItemSet: 0,
            showDialog: false,
            switchUser: {
                id: '',
                userTypeId: '',
                text: '',
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            isPrimaryRole: localStorage.getItem("isPrimaryRole"),
            userTypeId: localStorage.getItem("userTypeId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            userName: localStorage.getItem("userName"),
            vmxServiceCode: localStorage.getItem("vmxServiceCode"),
            //logoImage:'',
            userInfo: this.$store.getters.currentUser,
            entityRecordMasterId: '',
            vhextUserType: '',
            empWorkStatus: {
                empTimelineStateEnum: 0,
                empTimelineDt: '',
                task: '',
            },
            userTypes: [],
            clients: [],
            menuList: {
                actionitem: false,
                dashboard: false,
                reqbrd: false,
                tskbrd: false,
                tmbrd: false,
                ftxbrd: false,
                issbrd: false,
                calevt: false,
                mailbox: false,
                chatbox: false,
                notepad: false,
            },
            menuVSAList: {
                vsavhext: false,
                vsadashboard: false,
                lanvsasettings: false,
            },
            login: {
                email: '',
                password: '',
                switchAccount: true,
                userTypeId:''
            },
            topMainMenu: [],
            topSubMenu: [],
            serviceTopMenu: [],
            multiUserRoles: [],
        }
    },
    created() {
        EventBus.$on('onEmpAttTimelineUpdate', () => {
            this.getEmpWorkStatus()
        });
        EventBus.$on('onServiceTopMenuReload', () => {
            this.getServiceTopMenu();
        });
    },
    beforeDestroy() {
        EventBus.$off('onEmpAttTimelineUpdate');
        EventBus.$off('onServiceTopMenuReload');
    },
    mounted() {
        this.hoverActiveMenu();
        this.lookupData();
        this.getTopMainMenu();
        this.getServiceTopMenu();
        this.getEmpWorkStatus();
        this.getMultiUserLookupData();
    },
    methods: {
        getTopMainMenu: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            this.getTopSubMenu(data);
            axios.post(this.$store.getters.getAPIBasePath + '/menu/custommainmenu/landingtop', data)
                .then((response) => {
                    let result = response.data;
                    this.topMainMenu = result.landingTopMainMenuLookup;
                })
        },
        getTopSubMenu: function(data) {
            axios.post(this.$store.getters.getAPIBasePath + '/menu/customsubmenu/landingtop', data)
                .then((response) => {
                    let result = response.data;
                    this.topSubMenu = result.landingTopSubMenuLookup;
                    this.$nextTick(() => {
                        this.carouselKey++;
                    });
                })
        },
        getServiceTopMenu: function() {
            if(this.projectId && this.vmxServiceCode) {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    userId: this.userId,
                    projectId: this.projectId,
                    vmxServiceCode: this.vmxServiceCode,
                }
                axios.post(this.$store.getters.getAPIBasePath + '/menu/vmxservice/topmenu', data)
                    .then((response) => {
                        let result = response.data;
                        this.serviceTopMenu = result.customSubMenu;
                    })
            }
        },
        loadSubMenu(id) {
            return this.topSubMenu.filter(function(menu) {
                if (menu.mainMenuId == id)
                    return menu;
            });
        },
        getEmpWorkStatus: function() {
            let data = {
                userId: this.userId,
            }
            console.log(data)
            axios.get(this.$store.getters.getAPIBasePath + '/resource/empworkstatus/view/'  + this.userId)
                .then((response) => {
                    let result = response.data;
                    this.empWorkStatus = result.empWorkStatus;
                    this.empWorkState = this.empWorkStatus?.empTimelineStateEnum ? this.empWorkStatus.empTimelineStateEnum : 0;
                    if (this.empWorkStatus) {
                        this.$nextTick(() => {
                            if (this.empWorkStatus.empTimelineStateEnum == 1) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.timelineTaskTitle = this.empWorkStatus.task;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 2) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.timelineTaskTitle = this.empWorkStatus.task;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 3) {
                                this.startTime = this.empWorkStatus.empTimelineDt;
                                this.timelineTaskTitle = this.empWorkStatus.task;
                                this.pageTitleClock();
                            }
                            else if (this.empWorkStatus.empTimelineStateEnum == 4) {
                                this.startTime = '';
                                this.timerDuration = '00:00:00';
                            }
                        })
                    }
                })
        },
        userLogout: function() {

            let data = this.userInfo;

            axios.post(this.$store.getters.getAPIBasePath + '/user/logout', data)
                .then((response) => {
                    let result = response.data;
                })
        },

        pageTitleClock: function() {
            // console.log(this.timerDuration, this.timerValue)
            this.timerDuration = this.startTime ? this.getTimeDiff(new Date(), this.startTime, true) : '00:00:00';
            this.timerValue = setTimeout(this.pageTitleClock, 1000);
        },
        clearTimerVal() {
            clearTimeout(this.timerValue);
            this.startTime = '';
            this.timerDuration = '00:00:00';
        },

        hoverActiveMenu: function() {
            let routeName = this.$route.name;
            for (var item in this.menuList) {
                if (routeName == item) {
                    this.menuList[item] = true;
                } else {
                    this.menuList[item] = false;
                }
            }
            for (var item in this.menuVSAList) {
                if (routeName == item) {
                    this.menuVSAList[item] = true;
                } else {
                    this.menuVSAList[item] = false;
                }
            }
        },
        performLogout() {
            // alert('logout');
            console.log("USER",this.userInfo);
            this.$store.dispatch("doLogout");
            this.$router.push('/');
            this.userLogout();
        },
        // getEntityRecordMasterList: function() {
        //     if (this.userTypeCode == 'CLI') {
        //         this.$router.push('/client/task/list')
        //     }
        //     else if (this.projectId && !(this.$route.name == 'prmlanding')) {
        //         let data = {
        //             vhextAccountId: localStorage.getItem("vhextAccountId"),
        //             projectId: localStorage.getItem("projectId"),
        //             userId: this.userId,
        //             entity: 'development_plan_master',
        //             isArchive: false
        //         }
        //         axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
        //             .then((response) => {
        //                 let result = response.data;
        //                 if (this.$route.path !== '/tskbrd/' + this.entityRecordMasterId) {
        //                     if (result.entityRecordMasterList[0]) {
        //                         this.entityRecordMasterId = result.entityRecordMasterList[0].id;
        //                         if (this.entityRecordMasterId !== null) {
        //                             localStorage.setItem("ermId", '');
        //                             localStorage.setItem("ermTitle", '');
        //                             this.$router.push('/tskbrd/' + this.entityRecordMasterId);
        //                         }
        //                     } else {
        //                         iziToast.info({
        //                             title: 'No task found',
        //                             message: 'Create a Development Plan',
        //                             position: 'topCenter'
        //                         });
        //                         this.$router.push('/dvpmas/list');
        //                     }
        //                 }
        //             })
        //             .catch(error => {
        //                 // console.log(error);
        //             });
        //     } else this.$route.name !== 'usertask' ? this.$router.push('/user/task/list') : null;
        // },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            // return;
            if (this.projectId !== null) {
                axios.post(this.$store.getters.getAPIBasePath + '/lookup/project/client', data)
                    .then((response) => {
                        let result = response.data;
                        this.clients = result.projectClientLookup;
                    })
            }

        },
        getMultiUserLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                userId: this.userId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/multiuserroleaccount', data)
                .then((response) => {
                    let result = response.data;
                    this.multiUserRoles = result.multiUserAccountLookup;
                })

        },
        routeSettings: function() {
            if (this.userTypeCode == 'VSA') {
                if (this.$route.name == 'lanvsasettings') {
                    return;
                }
                if (this.$route.path.includes('landing') && this.$route.name !== 'lanvsasettings') {
                    this.$router.push('/landing/vsa/settings');
                }
                else if (this.$route.name !== 'settings') {
                    this.$router.push('/settings');
                }
                return;
            }
            if (this.$route.name == 'lanadmset') {
                return;
            }
            if (this.$route.path.includes('landing') && this.$route.name !== 'lanadmset') {
                this.$router.push('/landing/admset');
            }
            else if (this.$route.name !== 'settings') {
                this.$router.push('/settings');
            }
        },
        routeTopMenu: function(subMenu) {
            let path = this.$route.path;

            this.$store.state.menuData.standardMenuId = subMenu.id;
            this.$store.state.menuData.menuTitle = subMenu.displayName;
            this.$store.state.menuData.functionalInfo = subMenu.functionalInfo;
            
            if (path == subMenu.webUrl) {
                return;
            }
            else {
                this.$router.push(subMenu.webUrl);
            }
        },
        routeMyProfile: function() {

            if (this.userTypeCode !== 'CLI') {
                if ((this.$route.path).includes('landing')) {
                    if(this.$route.name !== 'lanmyprofile') {                 
                        this.$router.push('/landing/myprofile');
                    }
                }
                else {
                    if (this.$route.name !== 'prmprofile') {                 
                        this.$router.push('/prmprofile'); 
                    }
                }
            }
            else {
                if ((this.$route.path).includes('landing')) {
                    if(this.$route.name !== 'lanclientprofile') {                 
                        this.$router.push('/landing/clientprofile');
                    }
                }
                else {
                    if (this.$route.name !== 'clientprofile') {
                        this.$router.push('/clientprofile');
                    }
                }
            }

        },
        redirectLandingPage: function() {
            // To close right menu
            var body_container = $("body");
            var right_menu_container = $(".Right-main-menu");
            body_container.removeClass('open-note');
            right_menu_container.removeClass('active');
            $('.notebox').removeClass('active');

            let userTypeCode = localStorage.getItem("userTypeCode");
            // let projectId = localStorage.getItem("projectId");
            localStorage.removeItem("projectId");
            localStorage.removeItem("projectTypeId");
            localStorage.removeItem("projId");
            localStorage.removeItem("projectName");

            const defaultredirects = this.$store.getters.getDefaultRedirects;

            this.$router.push({ path: defaultredirects.general });

            // switch (userTypeCode) {
            //     case "VHO":
            //         this.$router.push('/vsa/landing');
            //         break;
            //     case "VSA":
            //         this.$router.push('/vsa/landing');
            //         break;
            //     case "CRP":
            //         this.$router.push('/crp/landing');
            //         break;
            //     case "CAM":
            //         this.$router.push('/cmr/landing');
            //         break;
            //     case "PRM":
            //         this.$router.push('/prm/landing');
            //         break;
            //     case "CLI":
            //         this.$router.push('/cli/landing');
            //         break;
            //     case "EMP":
            //         this.$router.push('/emp/landing');
            //         break;                    
            //     case "TMB":
            //         this.$router.push('/tmb/landing');
            //         break;
            //     case "HRM":
            //         this.$router.push('/hrm/landing');
            //         break;
            //     case "FNM":
            //         this.$router.push('/fnm/landing');
            //         break;
            //     default:
            //         this.$router.push(this.$route.path).catch(() => {});
            //         break;
            // }
        },
        routeToAttendance(argument) {
            let routeName = this.$route.name;
            if (argument == 'lanempatl') {
                if (routeName == 'lanempatl') return;
                else this.$router.push('/landing/empatl');
            }
        },
        routeServiceTopMenu: function(path) {
            let currentPath = this.$route.path;

            if (currentPath != path) {
                this.$router.push(path);
            }
        },
        routeActionItem: function(argument) {

            let routeName = this.$route.name;
            let userTypeCode = localStorage.getItem("userTypeCode");
            let projectId = localStorage.getItem("projectId");

            if (userTypeCode == 'CLI')
                this.$router.push('/cli/actionitem');
            else {
                this.$router.push('/actionitem');
            }

        },
        routeDashboard: function(argument) {

            let routeName = this.$route.name;
            let userTypeCode = localStorage.getItem("userTypeCode");
            let projectId = localStorage.getItem("projectId");

            if (projectId != null && routeName != 'prmlanding' && routeName != 'cmrlanding' && routeName != 'clilanding' && routeName != 'emplanding' && routeName != 'tmblanding' && routeName != 'usertask') {
                this.$router.push('/dashboard');
            } else {
                switch (userTypeCode) {
                    case "CAM":
                        this.$router.push('/user/dashboard');
                        break;
                    case "PRM":
                        this.$router.push('/prm/dashboard');
                        break;
                    case "CLI":
                        this.$router.push('/cli/dashboard');
                        break;
                    case "EMP":
                        this.$router.push('/emp/dashboard');
                        break;
                    case "TMB":
                        this.$router.push('/tmb/dashboard');
                        break;
                    default:
                        this.$router.push('/dashboard');
                        break;
                }
            }
        },
        routeCalendar: function(argument) {

            let routeName = this.$route.name;
            let userTypeCode = localStorage.getItem("userTypeCode");
            let projectId = localStorage.getItem("projectId");

            if (projectId != null && routeName != 'prmlanding' && routeName != 'cmrlanding' && routeName != 'clilanding' && routeName != 'emplanding' && routeName != 'tmblanding' && routeName != 'usertask') {
                this.$router.push('/calevt');
            } else {
                switch (userTypeCode) {
                    case "CAM":
                        this.$router.push('/cmr/calevt');
                        break;
                    case "PRM":
                        this.$router.push('/prm/calevt');
                        break;
                    case "CLI":
                        this.$router.push('/cli/calevt');
                        break;
                    case "EMP":
                        this.$router.push('/emp/calevt');
                        break;
                    case "TMB":
                        this.$router.push('/tmb/calevt');
                        break;
                    default:
                        this.$router.push('/calevt');
                        break;
                }
            }
        },
        routePage: function(argument) {

            let routeName = this.$route.name;
            localStorage.setItem("ermId", '');
            localStorage.setItem("ermTitle", '');

            if (argument == 'reqbrd') {
                if (routeName == 'reqbrd') return;
                else this.$router.push('/reqbrd');
            }

            if (argument == 'tmbrd') {
                if (routeName == 'tmbrd') return;
                else this.$router.push('/tmbrd');
            }

            if (argument == 'mailbox') {
                if (routeName == 'mailbox') return;
                else this.$router.push('/mailbox');
            }

            if (argument == 'ftxbrd') {
                if (routeName == 'ftxbrd') return;
                else this.$router.push('/ftxbrd');
            }

            if (argument == 'issbrd') {
                if (routeName == 'issbrd') return;
                else this.$router.push('/issbrd');
            }

            if (argument == 'chatbox') {
                if (routeName == 'chatbox') return;
                else this.$router.push('/chatbox');
            }

            if (argument == 'notepad') {
                if (routeName == 'notepad') return;
                else this.$router.push('/notepad');
            }
        },
        filterUserType: function(typeCode) {
            return this.userTypes.filter(function(data, key) {
                if (data.typeCode == typeCode) {
                    return data;
                }
            });
        },
        // slideRight: function () {
        //     const buttonRight = document.getElementById('slideRight');
        //     document.getElementById('menu-container').scrollLeft += 50;
        // },
        // slideLeft: function () {
        //     const buttonLeft = document.getElementById('slideLeft');
        //     document.getElementById('menu-container').scrollLeft -= 50;
        // },
        switchUserAccount(vhextAccountId) {
            this.appLoading = true;
            this.appLoadingContent = 'Switching User...';
            // var container = $("#app-container");
            // var body_container = $("body");
            // var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile";
            // container.removeClass(allMenuClassNames);
            // body_container.removeClass('open-note');

            const localStorageName = this.$store.state.local_storage_name;
            let authToken = localStorage.getItem(localStorageName);
            let data = {
                vhextAccountId: vhextAccountId,
                userId: this.userId,
                authToken: authToken,
            }
            this.$store.dispatch("doLogout");
            axios.post(this.$store.getters.getAPIBasePath + '/user/refresh', data)
                .then(response => {
                    let result = response.data;
                    if (result.result) {
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if(result.data.defaultProjectId > 0){
                                this.getDefaultProject();
                            }
                            else{
                                // Use $router.push to navigate to the determined redirect path and reload the page
                                if (this.$route.path !== defaultredirects.general) {
                                    this.$router.push({ path: defaultredirects.general })
                                        .then(() => {
                                            this.$store.dispatch('reloadApp'); // Reloads the entire app
                                        });
                                } else {
                                    this.$store.dispatch('reloadApp'); // Reloads the entire app
                                }
                            }
                            this.appLoading = false;
                            this.appLoadingContent = '';
                        });
                    } else {
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                        this.appLoading = false;
                        this.appLoadingContent = '';
                    }
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occurred',
                        message: 'Please try again',
                        position: 'topRight'
                    });
                    this.appLoading = false;
                    this.appLoadingContent = '';
                });
        },
        scrollMenu: function (value) {
            let maxSetCount = Math.floor(this.topMainMenu.length / this.topMenuMaxItems);
            if (value == 'plus' && this.topMenuItemSet < maxSetCount) {
                this.topMenuItemSet++;
            } else if(value == 'minus' && this.topMenuItemSet > 0) {
                this.topMenuItemSet--;
            }
        },
        topMainMenuLimitedItems() {
            // return this.topMainMenu;
            return this.slice2(this.topMainMenu, this.topMenuMaxItems, this.topMenuItemSet * this.topMenuMaxItems);
        },
        slice2(array, chunk, offset) {
            var subarray = [];
            let maxSetCount = Math.floor(array.length / this.topMenuMaxItems);
            // let reminder = array.length % this.topMenuMaxItems;
            // console.log(this.topMenuItemSet , maxSetCount,this.topMenuItemSet == maxSetCount)
            if (this.topMenuItemSet == maxSetCount) {
                subarray = array.slice(-chunk);
            } else {
                for (var i = 0; i<chunk; i++) {
                    var ind = (offset + i) % array.length;
                    subarray.push(array[ind]);
                }
            }

            return subarray;
        },
        slideRight: function () {
            var container = document.getElementById('menu-container');
            this.sideScroll(container,'right',10,200,10);
        },
        slideLeft: function () {
            var container = document.getElementById('menu-container');
            this.sideScroll(container,'left',10,200,10);
        },

        sideScroll(element,direction,speed,distance,step) {
            var scrollAmount = 0;
            var slideTimer = setInterval(function() {
                if(direction == 'left'){
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if(scrollAmount >= distance){
                    window.clearInterval(slideTimer);
                }
            }, speed);
        },
    }
}
</script>
<style>
#menu-container {
    width: 70%;
    overflow-x: scroll;
    display: flex;
    transition: all 1s linear 0s;
}

#menu-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#menu-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.dropdown-item:hover .dropdown-menu,
.inner-dropdown:hover {
    display: block;
    position: absolute;
    transform-origin: left;
    transform: translateX(-188%);
}
</style>