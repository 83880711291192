<template>
    <div class="fixed-inner-header">
        <div class="container-fluid">
            <div class="row inner-header">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-sm-4 col-lg-4">
                            <h4 class="pl-2 mb-0 fs-18">Client</h4>
                        </div>
                        <div class="col-12 col-sm-8 col-lg-8">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Client" :to="{ name: 'addclient'}"><i class="fa fa-plus-circle pr-2"></i>Add</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <router-link title="Edit Client" :to="{ name: 'editclient', params: { clientid: clientId }}"><i class="fa fa-pencil pr-2"></i>Edit</router-link>
                                    </li>
                                    <li class="breadcrumb-item">
                                        <a href="javascript:"><i class="fa fa-trash pr-2"></i>Detete</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="separator mb-2"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-8 col-xl-8">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-4 col-sm-4">
                                        <div class="form-group">
                                            <label for="clientName">Client Name</label>
                                            <input type="text" class="form-control" id="clientName" disabled v-model="client.clientName">
                                        </div>
                                    </div>
                                    <div class="col-4 col-sm-4">
                                        <div class="form-group">
                                            <label for="emailId">Email Id</label>
                                            <input type="text" class="form-control" id="emailId" disabled v-model="client.emailId">
                                        </div>
                                    </div>
                                    <div class="col-4  col-sm-4">
                                        <div class="form-group">
                                            <label for="clientTypeId">Client Type</label>
                                            <input type="text" class="form-control" id="clientTypeId" disabled v-model="client.type">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div>
                                            <b-input-group :prepend="prependText" class="mb-2">
                                                <b-form-input disabled v-model="client.firstName" aria-label="First name" placeholder="First Name"></b-form-input>
                                                <b-form-input disabled v-model="client.middleName" aria-label="Middle name" placeholder="Middle Name"></b-form-input>
                                                <b-form-input disabled v-model="client.lastName" aria-label="Last name" placeholder="Last Name"></b-form-input>
                                            </b-input-group>
                                        </div>
                                    </div>
                                    <div v-if="client.clientTypeId == 4090" class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="profession">Profession</label>
                                            <input type="text" class="form-control" id="profession" disabled v-model="client.profession">
                                        </div>
                                    </div>
                                    <div v-if="client.clientTypeId == 4090" class="col-6">
                                        <div class="form-group">
                                            <label for="employer">Employer</label>
                                            <input type="text" class="form-control" id="position" disabled v-model="client.employer">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="position">Position</label>
                                            <input type="text" class="form-control" id="position" disabled v-model="client.position">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="phone">Phone</label>
                                            <input type="text" class="form-control" id="phone" disabled v-model="client.phone">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="mobile">Mobile</label>
                                            <input type="text" class="form-control" id="mobile" disabled v-model="client.mobile">
                                        </div>
                                    </div>
                                    <div class="col-6 col-sm-6">
                                        <div class="form-group">
                                            <label for="location">Location</label>
                                            <input type="text" class="form-control" id="location" disabled v-model="client.location">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-4">
                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="status">Client Id</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{client.clientId}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="status">Added By</label>
                                            <p><span class="badge badge-pill badge-blue mb-1">{{client.userName}}</span></p>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group mx-0 row border rounded">
                                            <label class="col-sm-5 col-form-label bg-light border-right border-bottom text-truncate">Address 1</label>
                                            <div class="col-sm-7 border-bottom px-1">
                                                <input type="text" v-model="client.address1" class="form-control border-0" id="address1" disabled>
                                            </div>
                                            <label class="col-sm-5 col-form-label bg-light border-right border-bottom text-truncate">Address 2</label>
                                            <div class="col-sm-7 border-bottom px-1">
                                                <input type="text" v-model="client.address2" class="form-control border-0" id="address2" disabled>
                                            </div>
                                            <label class="col-sm-5 col-form-label bg-light border-right border-bottom text-truncate">City</label>
                                            <div class="col-sm-7 border-bottom px-1">
                                                <input type="text" v-model="client.city" class="form-control border-0" id="city" disabled>
                                            </div>
                                            <label class="col-sm-5 col-form-label bg-light border-right border-bottom text-truncate">State</label>
                                            <div class="col-sm-7 border-bottom px-1">
                                                <input type="text" v-model="client.state" class="form-control border-0" id="state" disabled>
                                            </div>
                                            <label class="col-sm-5 col-form-label bg-light border-right border-bottom text-truncate">Country</label>
                                            <div class="col-sm-7 border-bottom px-1">
                                                <input type="text" v-model="client.country" class="form-control border-0" id="country" disabled>
                                            </div>
                                            <label class="col-sm-5 col-form-label bg-light border-right border-bottom text-truncate">Pincode</label>
                                            <div class="col-sm-7 border-bottom px-1">
                                                <input type="text" v-model="client.pincode" class="form-control border-0" id="pincode" disabled>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="createdDt">Created Date</label>
                                            <input type="text" class="form-control" id="createdDt" disabled :value="formatDate(client.createdDt)">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="lastUpdatedDt">Updated Date</label>
                                            <input type="text" class="form-control" id="lastUpdatedDt" disabled :value="formatDate(client.lastUpdatedDt)">
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    computed: {
        clientId() {
            return Number(this.$route.params.clientid);
        }
    },
    data() {
        return {
            client: [],
            prependText:'Full Name',
        }
    },
    mounted() {
        this.viewClient(this.clientId);
    },
    methods: {
        viewClient: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/client/view/' + id)
                .then((response) => {
                    let result = response.data;
                    this.client = result.client;
                    console.log(this.client);
                    
           
                })

        }
    },
    changeClientType: function(data) { 
            if (data.text != null && data.text.length > 0){
                this.client.clientTypeName = data.text.toLowerCase();
            }
            if(this.client.clientTypeName == 'company'){
                this.prependText = 'Contact Person';
            }else{
                 this.prependText = 'Full Name';
            }
        }
}
</script>